import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';

import paymentConditionsRepository from 'repositories/PaymentConditions';
import { useAuth } from 'contexts/auth';

import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

import {
  SelectContainer,
  ViewConditionsHyperlink,
} from './SelectPaymentCondition.styles';

import HomePaymentConditions from 'client/views/PaymentConditions/PaymentCondition';
import { percentage } from 'client/components/ToNormalize/ToNormalize';

export function SelectPaymentCondition({ value, onChange }) {
  const { companyId } = useAuth();

  const [loading, setLoading] = useState(true);
  const [isPaymentConditionsModalOpen, setIsPaymentConditionsModalOpen] =
    useState(false);

  const [paymentConditions, setPaymentConditions] = useState([]);

  const handleOpenPaymentConditionsModal = () => {
    setIsPaymentConditionsModalOpen(true);
  };

  const handleClosePaymentConditionsModal = () => {
    setIsPaymentConditionsModalOpen(false);
  };

  const loadPaymentConditions = async () => {
    setLoading(true);
    try {
      const paymentConditions =
        await paymentConditionsRepository.getAllByCompanyActiveSortedByCondition(
          companyId
        );

      setPaymentConditions(
        paymentConditions.map((condition) => ({
          value: condition,
          label: condition.Description,
        }))
      );
    } catch (err) {
      console.log(err);
      toastr.warning(
        currentBrandingName,
        'Ocorreu um erro ao carregar as condições de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPaymentConditions();
  }, []);

  return (
    <>
      <SelectContainer>
        <InputContainer variant="row">
          <InputLabel style={{ position: 'relative', top: '4px' }}>
            Condição de Pagamento:
          </InputLabel>
          <InputSelectDefault
            width="150px"
            height="35px"
            value={value}
            onChange={(e) => onChange(e)}
            options={paymentConditions}
          />
          {value?.value.isFeeActive && (
            <span className="text-red">
              {percentage(value?.value.feePercent)} juros
            </span>
          )}
          <ViewConditionsHyperlink>
            <button onClick={handleOpenPaymentConditionsModal}>
              Visualizar Condições
            </button>
          </ViewConditionsHyperlink>
        </InputContainer>
      </SelectContainer>

      <Modal
        size="lg"
        dialogClassName="modal-payment-conditions"
        animation
        show={isPaymentConditionsModalOpen}
        onHide={handleClosePaymentConditionsModal}
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Condições de Pagamento</strong>
          </Modal.Title>
        </Modal.Header>
        <HomePaymentConditions />
      </Modal>
    </>
  );
}
