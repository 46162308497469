import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import TransactionsStoneInfo from './TransactionsStoneInfo'

const TransactionsStone = () => {
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatorios', 'Financeiro', 'Transações Stone']}
            path={['home', null, null, null]}
          />
          <p
            style={{
              fontSize: '12px',
              color: '#d9534f',
              textAlign: 'end',
              marginRight: '180px',
            }}
          >
            Os valores podem demorar em até 3 horas para serem atualizados
          </p>
          <TransactionsStoneInfo />
        </Row>
      </Grid>
    </div>
  )
}

export default TransactionsStone
