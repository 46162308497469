import React from 'react'
import CardForm from 'client/components/CardForm'
import Toggle from 'react-toggle'
import Button from 'client/components/CustomButton/CustomButton.jsx'

import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'
import { maxLength } from 'client/components/ToNormalize/ToNormalize'

const NewKitBasicData = ({
  additionalInfoLength,
  register,
  isBasicDataOpen,
  setIsBasicDataOpen,
  isActive,
  setIsItemsOpen,
  kitId,
  setValue,
}) => {
  return (
    <>
      <CardForm
        title="Dados do Kit"
        show={isBasicDataOpen}
        onClick={() => setIsBasicDataOpen(!isBasicDataOpen)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <span>
                Descrição: <span style={{ color: 'red' }}>*</span>
              </span>
              <input
                type="text"
                className="form-control foco-input"
                maxLength="50"
                {...register('description')}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span>Código de Barras (EAN):</span>
              <input
                type="number"
                className="form-control foco-input"
                onInput={(e) =>
                  (e.target.value = maxLength(onlyNumbers(e.target.value), 13))
                }
                {...register('barCode')}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span>Status:</span>
              <Toggle
                id="isActive"
                checked={isActive}
                disabled={!kitId}
                style={{ position: 'fixed' }}
                onChange={() => setValue('isActive', !isActive)}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <span>Informações Complementares:</span>
                <span style={{ color: '#00a4d3' }}>
                  {additionalInfoLength} caracteres
                </span>
              </div>
              <textarea
                className="form-control foco-input"
                maxLength="650"
                {...register('additionalInfo')}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="info"
              fill
              onClick={() => {
                setIsBasicDataOpen(false)
                setIsItemsOpen(true)
              }}
            >
              Prosseguir
            </Button>
          </div>
        </div>
      </CardForm>
    </>
  )
}

export default NewKitBasicData
