import React from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";

import FormTextForInvoices from './NewTextForInvoices'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewTextForInvoices = ({ match, history }) => {
  localStorage.setItem('selectedPath', 'client/NovoTextoParaNotaFiscal')

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb 
            data={['Início', 'Cadastros', 'Fiscal', 'Texto Para Notas Fiscais', !!match.params.id ? 'Editar Texto' : 'Novo Texto']} 
            path={[constants.ROUTES.HOME, null, null, constants.ROUTES.TEXTS_FOR_INVOICES, null ]} />
          <FormTextForInvoices textForInvoicesId={match.params.id} onCancel={() => history.push(constants.ROUTES.TEXTS_FOR_INVOICES)} onSubmit={() => history.push(constants.ROUTES.TEXTS_FOR_INVOICES)}/>
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(NewTextForInvoices)
