import React from 'react';
import { withRouter } from 'react-router-dom';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

const WhatsappQRCodeComponent = ({
  qrCodeBase64 = null,
  width = 400,
  height = 400,
}) => {
  const { LoadingComponent } = useLoading();

  return (
    <div style={{flexDirection: 'column'}} className="flex center flex-column">
      {qrCodeBase64 ? (
        <>
          <img
          src={`data:image/png;base64,${qrCodeBase64}`}
          alt="QR Code do WhatsApp"
          width={width}
          height={height}
        />
         <small style={{ color: '#d9534F'}}>Para evitar o banimento do seu número, utilize apenas o, <strong>WhatsApp Business</strong></small>
        </>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};
export default withRouter(WhatsappQRCodeComponent);
