import api from '../services/api';

export const createCorrection = async (body) => {
  const res = await api.post('/corrections', body);
  return res.data;
};

export const deleteCorrection = async (id) => {
  try {
    await api.delete('/corrections/' + id);
  } catch (err) {
    throw err;
  }
};

export const findCorrectionById = async (id, params) => {
  try {
    const res = await api.get('/corrections/' + id, {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listCorrections = async (params) => {
  const res = await api.get('corrections', {
    params,
  });
  return res.data;
};

export const updateCorrection = async (id, data) => {
  try {
    await api.put('/corrections/' + id, data);
  } catch (err) {
    throw err;
  }
};

export const CorrectionRepository = {
  createCorrection,
  deleteCorrection,
  findCorrectionById,
  listCorrections,
};
