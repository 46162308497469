import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import constants from '../../../../../utils/constants'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import BundleAprovedDisaprovedItens from './AprovedDisaprovedItens'

const AprovedDisaprovedItens = () => {
  return (
    <>
      <div className='content'>
        <Grid fluid>
          <Row>
            <BreadCrumb
              data={[
                'Início',
                'Relatórios',
                'Compras',
                'Itens Aprovados/Não Aprovados',
              ]}
              path={[
                'Home',
                null,
                null,
                constants.ROUTES.APROVES_DISAPROVED_ITENS,
              ]}
            />
          </Row>
        </Grid>
      </div>
      <BundleAprovedDisaprovedItens />
    </>
  )
}
export default AprovedDisaprovedItens
