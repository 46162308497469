import React, { useState, useEffect, memo } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import DownloadXlsButton from 'components/DownloadXlsButton'
import {
  faCloudDownloadAlt,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import brandsRepository from '../../../../../repositories/Brands'
import familiesRepository from '../../../../../repositories/Families'
import productsRepository from '../../../../../repositories/Products'
import useFilters from '../../../../../hooks/useFilters'
import { useSelector } from 'react-redux'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'
import { useHistory } from 'react-router'

let Brands = ({ brands }) =>
  brands.map((brand) => (
    <option key={brand.id} value={brand.id}>
      {brand.Description}
    </option>
  ))

Brands = memo(Brands)

export const MinMaxStockReport = ({ location }) => {
  const [products, setProducts] = useState([])
  const [brands, setBrands] = useState([])
  const [families, setFamilies] = useState([])

  const [brandId, setBrandId] = useState('')
  const [familyId, setFamilyId] = useState('')
  const [showNegativeStock, setShowNegativeStock] = useState('1')
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()
  const minStockAlert = useSelector((state) => state.minStockAlert)

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const history = useHistory()

  useEffect(() => {
    if (!!companyId) {
      loadFamilies()
      loadBrands()
    }
  }, [companyId])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams && !!minStockAlert.products.length)
      return loadQuery(searchParams)
  }, [location.search, minStockAlert.products])

  const loadQuery = (searchParams) => {
    const loadProductsInAlert = searchParams.get('loadProductsInAlert')
    if (loadProductsInAlert) setProducts(minStockAlert.products)
  }

  const loadFamilies = async () => {
    setLoading(true)
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        )
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        Description: family.Description,
      }))

      setFamilies(serializedFamilies)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const loadBrands = async () => {
    setLoading(true)
    try {
      const brands = await brandsRepository.getAllByCompany(companyId)
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        Description: brand.Description,
      }))

      setBrands(serializedBrands)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleProcess = async () => {
    setLoading(true)
    try {
      const data = await productsRepository.minMaxStockReport(companyId, {
        familyId,
        brandId,
        showNegativeStock,
      })
      setProducts(data)
    } catch (err) {
      toastr.warning(
        err?.response?.data?.message ||
          'Ocorreu um erro ao gerar o relatório. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleFilters = (product) => {
    const querySearch = [
      product['Code'],
      product['Description'],
      product['Stock_Quantity'],
    ]
    return filterByQuery(querySearch)
  }

  const productsFiltered = products.filter(handleFilters)

  const handleProcessPurchaseSuggestion = async () => {
    setLoading(true)
    try {
      handlePrint()
    } catch (err) {
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao gerar o Relatório de Sugestão de Compra. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    const params = `familyId, brandId, showNegativeStock`
    window.open(
      window.location.origin +
        `/report/purchase-suggestion?companyId=${companyIdHash}`,
      '_blank'
    )
  }

  return (
    <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '180px' }}
        >
          <label>Família:</label>
          <select
            value={familyId}
            onChange={(e) => setFamilyId(e.target.value)}
            className='form-control foco-input'
          >
            <option value=''>Todas</option>
            <option value='null'>Sem Família</option>
            {families.map((family) => (
              <option key={family.id} value={family.id}>
                {family.Description}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '180px',
            marginLeft: 10,
          }}
        >
          <label>Marcas:</label>
          <select
            value={brandId}
            onChange={(e) => setBrandId(e.target.value)}
            className='form-control foco-input'
          >
            <option value=''>Todas</option>
            <option value='null'>Sem Marca</option>
            <Brands brands={brands} />
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '180px',
            marginLeft: 10,
          }}
        >
          <label>Exibir estoque negativo?</label>
          <select
            value={showNegativeStock}
            onChange={(e) => setShowNegativeStock(e.target.value)}
            className='form-control foco-input'
          >
            <option value='1'>Sim</option>
            <option value='0'>Não</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: 10,
          }}
        >
          <button
            className='btn btn-sucesso'
            style={{
              padding: '7px 20px',
            }}
            disabled={loading}
            onClick={handleProcess}
          >
            Gerar Relatório
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: 10,
          }}
        >
          <DownloadXlsButton
            archiveName={`Rel_MinMax-${format(new Date(), 'dd/MM/yyyy')}`}
            data={productsFiltered}
            className='btn btn-export'
            style={{
              padding: '7px 5px',
            }}
            disabled={loading || !!!productsFiltered.length}
            columns={[
              {
                name: 'Código',
                acessor: 'Code',
              },
              {
                name: 'Descrição',
                acessor: 'Description',
              },
              {
                name: 'Família',
                acessor: 'familyDescription',
              },
              {
                name: 'Marca',
                acessor: 'brandDescription',
              },
              {
                name: 'Estoque',
                acessor: 'Stock_Quantity',
              },
              {
                name: 'Estoque Min.',
                acessor: 'minStock',
              },
              {
                name: 'Estoque Max.',
                acessor: 'maxStock',
              },
            ]}
          >
            <FontAwesomeIcon
              color='white'
              icon={faCloudDownloadAlt}
              size='lg'
            />
            <span style={{ padding: '0px 5px', marginLeft: 7 }}>
              Exportar .xls
            </span>
          </DownloadXlsButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: 10,
          }}
        >
          <button
            className='btn btn-export-suggestion'
            style={{
              padding: '7px 10px',
              backgroundColor: '#5bc0de',
            }}
            disabled={loading || products.length < 1}
            onClick={handleProcessPurchaseSuggestion}
          >
            <FontAwesomeIcon color='white' icon={faExternalLinkAlt} size='lg' />
            <span style={{ padding: '0px 5px', marginLeft: 7 }}>
              Gerar Sugestão de Compra
            </span>
          </button>
        </div>
      </div>

      <div style={{ width: '100%', marginTop: 20 }}>
        <div style={{ width: '500px' }} id='review-search'>
          <i id='iconepesquisar' className='fa fa-search'></i>
          <input
            type='text'
            style={{ paddingLeft: '30px', width: '100%' }}
            className='form-control foco-input'
            placeholder='Pesquisa por Código, Descrição ou Estoque'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>

      <div style={{ width: '100%', marginTop: 10 }}>
        <ReactTable
          style={{ fontWeight: 'bold', textAlign: 'center' }}
          data={productsFiltered}
          columns={[
            {
              Header: 'Código',
              accessor: 'Code',
              width: 100,
            },
            {
              Header: 'Descrição',
              accessor: 'Description',
            },
            {
              Header: 'Família',
              accessor: 'familyDescription',
              width: 180,
            },
            {
              Header: 'Marca',
              accessor: 'brandDescription',
              width: 180,
            },
            {
              Header: 'Estoque',
              accessor: 'Stock_Quantity',
              width: 100,
              Cell: ({ value, original }) =>
                !!original.minStock ? (
                  value < original.minStock ? (
                    <span style={{ color: 'red' }}>{value}</span>
                  ) : (
                    value
                  )
                ) : (
                  value
                ),
            },
            {
              Header: 'Estoque Min.',
              accessor: 'minStock',
              width: 100,
            },
            {
              Header: 'Estoque Max.',
              accessor: 'maxStock',
              width: 100,
            },
          ]}
          defaultPageSize={10}
          loading={loading}
          showPagination
          sortable
          showPaginationTop={false}
          showPaginationBottom
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText='Anterior'
          nextText='Próximo'
          loadingText='Carregando...'
          noDataText='Nenhum produto encontrado'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
        />
      </div>
    </div>
  )
}
