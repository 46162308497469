import React from 'react'
import { format } from 'date-fns'
import { withRouter } from 'react-router'

import { currency } from 'client/components/ToNormalize/ToNormalize'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import { isSignatureOnEnd } from '../../../utils/signatureHelpers'
import constants from '../../../utils/constants'

const UniqueSignatureDetails = ({ signature, signaturePayment, history }) => {
  const creditCardValidity = format(
    new Date(
      new Date(new Date().setFullYear(signaturePayment.Year_Validity)).setMonth(
        signaturePayment.Month_Validity - 1
      )
    ),
    'MM/yyyy'
  )
  const signatureEndDate = !signature.End_Date
    ? ''
    : format(new Date(signature.End_Date), 'dd/MM/yyyy')

  return (
    <div>
      <p id='bdPlanoBody'>
        Você pode utilizar o sistema até <strong>{signatureEndDate}</strong>
      </p>
      <p id='bdPlanoBody'>
        A sua assinatura foi no valor de {currency(signaturePayment.Price)} em{' '}
        {signaturePayment.Qtd_Parcels}x de{' '}
        {currency(signaturePayment.Value_Parcel)} no{' '}
        {signaturePayment.typePayment
          ? signaturePayment.typePayment
          : 'Cartão de Crédito'}
        .
      </p>
      {!!signaturePayment.Card_Number && (
        <>
          <p>
            Cartão que termina com{' '}
            {signaturePayment.Card_Number.toString().substring(
              signaturePayment.Card_Number.length - 4,
              signaturePayment.Card_Number.length
            )}
          </p>
          <p>
            <span id='bdPlanoSpan'>Vencimento: {creditCardValidity}</span>
          </p>
        </>
      )}
    </div>
  )
}

export default withRouter(UniqueSignatureDetails)
