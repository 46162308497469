import config from '../config'
import api from '../services/api'

const restorePassword = async (email) => {
  try {
    await api.post(config.endpoint + `email/restorePassword`, email)
  } catch (err) {
    throw err
  }
}

const cancelSignature = async (data) => {
  await api.post(config.endpoint + 'email/cancelSignature', data)
}

const updateCpfToCnpj = async (data) => {
  try {
    await api.post(config.endpoint + `email/updateCpfToCnpj`, data)
  } catch (err) {
    throw err
  }
}

const sendSchedullingEmail = async ({
  companyId,
  customerId,
  vehicleId,
  schedullingDate,
}) => {
  try {
    await api.post(config.endpoint + `email/schedulling`, {
      companyId,
      customerId,
      vehicleId,
      schedullingDate,
    })
  } catch (err) {
    throw err
  }
}

const sendCompletedServiceEmail = async ({
  companyId,
  customerId,
  vehicleId,
  saleCode,
}) => {
  const { data } = await api.post(
    `email/completedService?companyId=${companyId}&customerId=${customerId}&vehicleId=${vehicleId}&saleCode=${saleCode}`
  )

  return data
}

export default {
  restorePassword,
  cancelSignature,
  updateCpfToCnpj,
  sendSchedullingEmail,
  sendCompletedServiceEmail,
}
