import React, { useState } from 'react';
import { isDirty } from 'redux-form';
import { connect } from 'react-redux';

import Button from '../../components/CustomButton/CustomButton';
import BundleFinancialForm from './FinancialForm';
import BundlePSalesConfigForm from './SalesConfigForm';
import BundlePurchaseForm from './PurchaseForm';
import { usePlanSignatureContext } from './../../../contexts/plan-signature';
import AlertModal from '../../../components/AlertModal';

import './styles.css';

const Configurations = ({ onCancel, isAnyFormDirty }) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);

  const { isPlanFree, isPlanWorkmotorFree, isPlanStart } =
    usePlanSignatureContext();

  return (
    <>
      <div id="configurations-form">
        {!isPlanFree && !isPlanStart && !isPlanWorkmotorFree && (
          <BundlePurchaseForm />
        )}
        {!isPlanFree && !isPlanStart && <BundleFinancialForm />}
        <BundlePSalesConfigForm />
        <footer>
          <Button
            bsStyle="danger"
            fill
            onClick={() =>
              isAnyFormDirty ? setIsConfirmCancelModalOpen(true) : onCancel()
            }
          >
            Voltar
          </Button>
        </footer>
      </div>

      <AlertModal
        show={isConfirmCancelModalOpen}
        message="Deseja voltar para tela de Minha Empresa sem salvar as configurações? Ao sair, as alterações não salvas serão perdidas"
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAnyFormDirty:
    isDirty('financialConfiguration')(state) ||
    isDirty('printingSheets')(state),
});

export default connect(mapStateToProps)(Configurations);
