import React from 'react'
import CurrencyInput from 'react-currency-input'

const OurCurrencyInput = React.forwardRef((props, ref) => {
  const formatValue = (e) => {
    let obj = e
    let value = obj.target.value.toString().replace('R$', '')

    while (value.includes('.')) {
      value = value.replace('.', '')
    }
    while (value.includes(',')) {
      value = value.replace(',', '.')
    }

    if (!e.target.value) {
      value = 0
    }

    obj.target.value = value

    props.onChangeEvent(obj)
  }

  return (
    <CurrencyInput
      {...props}
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      inputType="text"
      onChangeEvent={formatValue}
      allowNegative
      ref={ref}
    />
  )
})

export default OurCurrencyInput
