import { currency } from 'client/components/ToNormalize/ToNormalize';

export const xlsColumns = [
  {
    name: 'Status',
    acessor: 'purchaseStatusDescription',
  },
  {
    name: 'Nº Entrada',
    acessor: 'code',
  },
  {
    name: 'Nº Nota',
    acessor: 'invoice',
  },
  {
    name: 'Fornecedor',
    acessor: 'providerCompanyName',
  },
  {
    name: 'Valor',
    acessor: 'total',
  },
];

export const getPurchasesInfoToExport = (purchases) => {
  return purchases.map((purchase) => ({
    purchaseStatusDescription: purchase.PurchaseStatus?.description,
    code: purchase.code,
    invoice: purchase.invoice,
    providerCompanyName: purchase.Provider?.companyName,
    total: currency(purchase.total),
  }));
};
