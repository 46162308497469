import React from 'react';

import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import fontRobotoBoldItalic from 'assets/fonts/Roboto-BoldItalic.ttf';

export const HangtagShelf = ({ hangtags }) => (
  <Document>
    {hangtags.map((hangtagPage, index) => (
      <Page key={index} style={styles.body} wrap={true} size={'LETTER'}>
        <View style={styles.hangtagContainer}>
          {hangtagPage.map((hangtagRow, indexRow) => (
            <View style={styles.hangtagRow} key={indexRow}>
              <HangTag data={hangtagRow[0]} />
              {hangtagRow[1] ? <HangTag data={hangtagRow[1]} /> : <View />}
            </View>
          ))}
        </View>
      </Page>
    ))}
  </Document>
);

const HangTag = ({ data }) => {
  return (
    <View style={styles.hangtag}>
      <View style={styles.hangtagHeader}>
        <Text style={styles.hangtagProductCode}>{`Código: ${data.code}`}</Text>
      </View>
      <View style={styles.hangtagContent}>
        <Text style={styles.text}>{data.description}</Text>
        <View style={styles.hangtagSpaceBetween}>
          <Text style={styles.text}>{data.family}</Text>
          <Text style={styles.text}>{data.brand}</Text>
        </View>
        <Text style={styles.text}>{data.location}</Text>
      </View>
    </View>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  body: {
    marginVertical: '1.27cm',
    paddingLeft: '.40cm',
    paddingRight: '0.40cm',
  },
  hangtag: {
    width: '10.16cm',
    height: '2.54cm',
    padding: '6px',
    fontSize: '8px',
    fontFamily: 'Roboto',
  },
  hangtagContainer: {
    display: 'flex',
  },
  hangtagProductCode: {
    fontFamily: 'Roboto',
    fontSize: '12px',
  },
  hangtagSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  hangtagRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hangtagContent: {
    marginTop: '4px',
  },
  text: {
    textAlign: 'justify',
  },
});
