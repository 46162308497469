import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BankConciliationButtonsActions } from '../BankConciliationDetails/components/BankConciliationButtonsActions';
import { useBankConciliationStore } from 'v2/store/bankConciliation';

export function BankConciliationFileUploadFooterActions({
  onCancel,
  isSelectedBank
}) {
  const { setFileAndData } = useBankConciliationStore((store) => {
    return {
      setFileAndData: store.setFileAndData,
    };
  })
  const { watch, reset } = useFormContext();
  const isFile = watch('informationFile.file');
  const isFileValid = watch('informationFile.data.isValid');
  const data = watch('informationFile.data');
  const history = useHistory()

  const getDataOFX = () => {
    const dataFile = {
    ...data,
      headerFile: {
        ...data.headerFile,
        cashierBankId: isSelectedBank,
      }
    }

    setFileAndData(isFile, dataFile, isSelectedBank)
    history.push('bank-conciliation/new-conciliation')
  }


  const handlePreviousButton = () => {
    reset()
    onCancel()
  }

  return (
    <BankConciliationButtonsActions
      onNoClick={() => handlePreviousButton()}
      onYesClick={() => getDataOFX()}
      noLabel="Voltar"
      yesLabel="Importar"
      isYesDisabled={
        isSelectedBank === '' ||
        isSelectedBank === undefined ||
        !isFileValid ||
        (!isFile && isFile === null)
      }
    />
  );
}
