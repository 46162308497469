import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import InventoryMain from './components/InventoryMain'

import './index.css'

export default function VehicleHistory () {

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Produtos',
              'Inventário'
            ]}
            path={['home', null, null, null]}
          />
          <InventoryMain />
        </Row>
      </Grid>
    </div>
  )
}
