import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import Familie from './FormFamilie';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const FormFamilie = ({ match }) => {
  localStorage.setItem('selectedPath', 'client/family');

  const { isPlanFree, isPlanStart, isPlanBasic, isPlanWorkmotorFree } =
    usePlanSignatureContext();
  const isPlanPrimeWithoutPlansFree =
    !isPlanFree && !isPlanStart && !isPlanBasic && !isPlanWorkmotorFree;

  localStorage.setItem(
    'isPlanPrimeWithoutPlansFree',
    isPlanPrimeWithoutPlansFree
  );

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <span id="required-field-label">* Campos Obrigatórios</span>
            <BreadCrumb
              data={[
                'Início',
                'Cadastros',
                'Produtos/Serviços',
                'Famílias',
                !!match.params.id ? 'Editar Família' : 'Nova Família',
              ]}
              path={['home', null, null, 'families', null]}
            />
            <Familie
              newFamily={match.params.id ? false : true}
              FamilyId={match.params.id}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default FormFamilie;
