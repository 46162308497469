import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faClone
} from '@fortawesome/free-solid-svg-icons'

import './styles.css'

const Captions = ({ importType }) => {
  const iconStyle = { width: 20, height: 20 }

  return (
    <main className='captions-wrapper'>
      <section className='captions'>
        <div className='caption-item'>
          <span>Todos os campos preenchidos</span>
          <FontAwesomeIcon
            color='#5cb85c'
            style={iconStyle}
            icon={faCheckCircle}
          />
        </div>
        <div className='caption-item'>
          <span>Apenas os campos obrigatórios preenchidos</span>
          <FontAwesomeIcon
            color='#eea236'
            style={iconStyle}
            icon={faExclamationCircle}
          />
        </div>
        <div className='caption-item'>
          <span>Campo obrigatório não preenchido</span>
          <FontAwesomeIcon
            color='#d9534f'
            style={iconStyle}
            icon={faTimesCircle}
          />
        </div>
        <div className='caption-item'>
          <span>Campo duplicado</span>
          <FontAwesomeIcon
            color='#7a43b6'
            style={iconStyle}
            icon={faClone}
          />
        </div>
      </section>
    </main>
  )
}

export default Captions
