import React from 'react'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import { createNumberMask } from 'redux-form-input-masks'
import RenderField from 'components/RenderField'

const MainData = ({ 
  citiesOptions,
  statesOptions,
  companyType, 
  companyUsersOptions,
  validateSelectedUser,
  setIsTaxPixChanged,
  isEdit 
}) => {  

  const percentMask = createNumberMask({
    suffix: ' %',
    decimalPlaces: 2,
  })

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label='Tipo de pessoa:'
            name='type'
            component={RenderField}
            disabled
          />
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label='CPF/CNPJ:'
            name='cpfCnpj'
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <Field
            label='Razão Social:'
            name='companyName'
            component={RenderField}
            disabled
          />
        </Col>        
        <Col xs={12} sm={6} md={4} lg={4}>
          <Field
            label='Nome Fantasia:'
            name='fantasyName'
            component={RenderField}
            disabled
          />
        </Col>                   
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        {companyType !== 'Física' && (
          <Col xs={12} sm={6} md={3} lg={3}>
            <Field
              label='Email:'
              name='email'
              component={RenderField}
              disabled
            />
          </Col>
        )}
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label='Telefone:'
            name='phone'
            component={RenderField}
            disabled
          />
        </Col>
        {companyType !== 'Física' && (
          <Col xs={12} sm={6} md={3} lg={3}>
            <Field
              label='Responsável:'
              name='contact'
              component={RenderField}
              disabled
            />
          </Col>
        )}
        <Col xs={12} sm={6} md={3} lg={3}>
          <label>Usuário:</label>
          <Field 
            component="select" 
            className="form-control foco-input" 
            name="representative"
            onChange={(e) => { validateSelectedUser(e.target.value) }}
            disabled={isEdit ? 'disabled' : ''}
          >
            <option value="">Selecione</option>
            {
              companyUsersOptions.map(user => {
                return (
                  <option key={user.id} value={user.id}>{user.Name}</option>
                );
              })
            }
          </Field>
        </Col>
      </Col>      
      <Col xs={12} sm={12} md={12} lg={12}>        
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label='CEP:'
            name='zipCode'
            component={RenderField}
            disabled
          />
        </Col>        
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label='Endereço:'
            name='street'
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={1} lg={1}>
          <Field
            label='Número:'
            name='addressNumber'
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label='Bairro:'
            name='neighborhood'
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={2} lg={3}>
          <Field
            label='Complemento:'
            name='complement'
            component={RenderField}
            disabled
          />
        </Col>        
      </Col>      
      <Col xs={12} sm={12} md={12} lg={12}>        
        <Col xs={12} sm={6} md={7} lg={8}>
          <Field
            label='Ponto de Referência:'
            name='referencePoint'
            component={RenderField}
            disabled
          />
        </Col>        
        <Col xs={12} sm={6} md={2} lg={2}>
          <label>Estado:</label>
          <Field component="select" className="form-control foco-input" disabled={true} name="state">
            <option value="">Selecione</option>
            {
              statesOptions.map(e => {
                return (
                  <option key={e.id} value={e.Initials}>{e.Name}</option>
                );
              })
            }
          </Field>
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <label>Cidade:</label>
          <Field component="select" className="form-control foco-input" disabled={true} name="city">
            <option value="">Selecione</option>
            {
              citiesOptions.map(c => {
                return (
                  <option key={c.id} value={c.Name}>{c.Name}</option>
                );
              })
            }
          </Field>
        </Col>                    
      </Col> 
      <Col xs={12} sm={12} md={12} lg={12}>        
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label='Taxa Pix (Work):'
            name='taxPixWork'
            component={RenderField} 
            {...percentMask} 
            onChange={(e) => setIsTaxPixChanged(true)}
            maxLength='7'         
          />
        </Col>        
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label='Taxa Pix (Matera):'
            name='taxPixMatera'
            component={RenderField}
            {...percentMask}
            disabled
            maxLength='7'
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <label>Tipo de Conta:</label>
          <Field component="select" className="form-control foco-input" name="accountType" disabled={isEdit ? 'disabled' : ''}>
            <option value="">Selecione</option>
            <option value="ORDINARY">Conta Comum (Faturamento mensal até R$ 5.000,00)</option>
            <option value="UNLIMITED_ORDINARY">Conta Completa (Faturamento mensal ilimitado)</option>
          </Field>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label='ID Conta:'
            name='materaAccountId'
            component={RenderField}
            disabled
          />
        </Col> 
        <Col xs={12} sm={6} md={3} lg={2}>
          <Field
            label='Nº Conta:'
            name='materaAccountNumber'
            component={RenderField}
            disabled
          />
        </Col> 
      </Col> 
    </>
  )
}

export default MainData
