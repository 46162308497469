export const xlsColumns = [
  {
    name: 'Código',
    acessor: 'Code',
  },
  {
    name: 'Ref. Fabricante',
    acessor: 'Manufacturer_Ref',
  },
  {
    name: 'Descrição',
    acessor: 'Description',
  },
  {
    name: 'Vlr. Unit.',
    acessor: 'Unit_Value',
  },
  {
    name: 'Qtd. Vendida',
    acessor: 'TotalQuantity',
  },
  {
    name: 'Valor Total',
    acessor: 'TotalAmount',
  },
  {
    name: 'Participação(%)',
    acessor: 'participation',
  },
  {
    name: 'Curva',
    acessor: 'curve',
  },
]


export default xlsColumns;
