import React from 'react';
import {BankConciliationHeaderCardContainer, BankConciliationHeaderCardContent, BankConciliationHeaderCardType} from '../../BankConciliationDetails.styles'
export function BankConciliationHeaderCard({ props, index }) {

  const { title, info, porcentagem } = props;

  return (
    <BankConciliationHeaderCardContainer>
      <BankConciliationHeaderCardContent>
        <p>{title}</p>

        {porcentagem ? (
          <BankConciliationHeaderCardType>
            <strong style={{ fontSize: index === 0 || index === 1 ? '12px' : '20px' }}>
              {info}
            </strong>
            <small
              style={{
                color:
                  title === 'Transações de Crédito'
                    ? 'green'
                    : title === 'Transações de Débito'
                    ? 'red'
                    : '',
                fontSize: '12px',
              }}
            >
              {porcentagem}%
            </small>
            das transações
          </BankConciliationHeaderCardType>
        ) : title === 'Conciliação Realizada' ? (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', gap: '5px' }}>
            <span>{info.trueCount} de </span>
            <strong style={{fontSize: '20px' }}>{info.totalCount}</strong>
          </div>
        ) : (
          <strong style={{ fontSize: index === 0 || index === 1 ? '12px' : '20px' }}>
            {info}
          </strong>
        )}

      </BankConciliationHeaderCardContent>
    </BankConciliationHeaderCardContainer>
  );
}
