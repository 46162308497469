import config from '../config'
import api from '../services/api'

const index = async (query) => {
  const response = await api.get(config.endpoint + 'purchase-itens', {
    params: query,
  })

  return response
}

export default {
  index,
}
