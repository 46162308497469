import api from '../services/api';

export const printTags = async (products) => {
  const { data } = await api.post(`hangtag`, {
    products: products.formatedItems,
  });

  return data;
};

export const getHangtags = async (id) => {
  const { data } = await api.get(`hangtag/${id}`);
  return data;
};

export const deleteHangtags = async (id) => {
  return await api.delete(`hangtag/${id}`);
};

const HangtagRepository = {
  printTags,
  getHangtags,
  deleteHangtags,
};

export default HangtagRepository;
