import React from "react";
import { Grid, Row } from "react-bootstrap";
import './styles.css';
import HomeServices from './Services'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Services = () => {
  localStorage.setItem('selectedPath', 'client/services')
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Serviços']} path={['home', null]} />
          <HomeServices />
        </Row>
      </Grid>
    </div>
  );
}
export default Services