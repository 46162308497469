import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import AlertModal from 'components/AlertModal/AlertModal';
import Form from './Form';
import PlansRepository from '../../../../../v2/repositories/Plans';
import SignaturePaymentRepository from 'repositories/SignaturePayments';
import SignatureRepository from 'repositories/Signatures';
import { format } from 'date-fns';

export default function NewSignatureModal({
  company,
  onHide,
  onCancel,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [values, setValues] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const { plan, period, brand, startDate } = values;

    if (!plan) return toastr.warning('Necessário selecionar plano');
    if (!brand) return toastr.warning('Necessário selecionar produto');
    if (!period) return toastr.warning('Necessário selecionar período');
    if (!startDate)
      return toastr.warning('Necessário selecionar data de início da vigência');

    setValues(values);
    setModal(true);
  };

  const submit = async () => {
    const { plan, period, plans, qtdUsers } = values;

    setLoading(true);

    const serializedPlans = plans.map((plan) => {
      return {
        ...plan,
        quarterly: {
          monthly_value:
            plan.price * (1 - plan.Quarterly_Discount_Percentage / 100),
          amount:
            plan.price * (1 - plan.Quarterly_Discount_Percentage / 100) * 3,
        },
        semester: {
          monthly_value:
            plan.price * (1 - plan.Semester_Discount_Percentage / 100),
          amount:
            plan.price * (1 - plan.Semester_Discount_Percentage / 100) * 3,
        },
        annual: {
          monthly_value:
            plan.price * (1 - plan.Annual_Discount_Percentage / 100),
          amount: plan.price * (1 - plan.Annual_Discount_Percentage / 100) * 3,
        },
      };
    });

    let price = 0;
    let valueParcel = 0;
    let parcels = 0;
    let planId;
    for (var i in serializedPlans) {
      if (serializedPlans[i].id === parseInt(plan)) {
        if (period === 'Mensal') {
          price = serializedPlans[i].price;
          valueParcel = serializedPlans[i].price;
          parcels = 1;
          planId = serializedPlans[i].id;
        } else if (period === 'Trimestral') {
          price = serializedPlans[i].quarterly.amount;
          valueParcel = serializedPlans[i].quarterly.monthly_value;
          parcels = 3;
          planId = serializedPlans[i].id;
        } else {
          price = serializedPlans[i].annual.amount;
          valueParcel = serializedPlans[i].annual.monthly_value;
          parcels = 12;
          planId = serializedPlans[i].id;
        }
      }
    }

    const signaturePayment = {
      Price: price,
      Qtd_Parcels: parcels,
      Value_Parcel: valueParcel,
      typePayment: 'Boleto',
    };

    try {
      const resSignaturePayment = await SignaturePaymentRepository.create(
        signaturePayment
      );

      if (resSignaturePayment) {
        const signature = {
          usersQuantity: qtdUsers,
          User_id: company.Users[0].id,
          Company_id: company.id,
          Months: parcels,
          Plan_id: planId,
          signaturePeriod: period,
          SignatureStatus_id: 2,
          SignaturePayment_id: resSignaturePayment.id,
        };
        await SignatureRepository.create(signature);
      } else {
        return toastr.error('Ocorreu algum erro ao tentar salvar assinatura');
      }

      toastr.success('Assinatura realizada com sucesso');
      onSuccess();
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu algum erro ao tentar salvar assinatura');
      setModal(false);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    plans: [],
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: '',
    period: '',
    qtdUsers: 1,
  };

  return (
    <Modal
      show
      animation
      dialogClassName="signature-modal-wrapper"
      onHide={onHide}
    >
      <Form
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        company={company}
      />

      {modal && (
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message="Deseja mesmo assinar o plano para esse cliente?"
          onSubmit={submit}
          loading={loading}
        />
      )}
    </Modal>
  );
}
