import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { useSelector } from 'react-redux';
import { createNumberMask } from 'redux-form-input-masks';
import './style.css';
import Toggle from 'react-toggle';
import InputNumber from 'client/components/InputNumberCustom';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
});

const marginMask = createNumberMask({
  decimalPlaces: 2,
  suffix: '%',
});

const kgMask = createNumberMask({
  decimalPlaces: 3,
});
export default function DadosEstoque({ dispatch, loaded, setLoaded }) {
  const [editSalePrice, setEditSalePrice] = useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const {
    formProduto: {
      values: {
        Purchase_Price,
        Profit_Margin,
        Sale_Price,
        Multiplier_Base,
        minStock,
        maxStock,
        updateDescriptionOnSale,
        hasDiscountLimit,
        weight,
      },
    },
  } = useSelector((state) => state.form);

  useEffect(() => {
    if (!hasDiscountLimit) {
      dispatch(change('formProduto', 'discountLimit', 0));
    }
  }, [hasDiscountLimit]);

  useEffect(() => {
    if (!editSalePrice && loaded) {
      if (Purchase_Price && Profit_Margin >= 0) {
        calculateSalePrice();
      } else {
        dispatch(change('formProduto', 'Sale_Price', 0));
      }
    } else {
      handleChangeSalePrice(Sale_Price);
      setLoaded(true);
    }
  }, [Purchase_Price, Profit_Margin, editSalePrice, Sale_Price, weight]);

  const calculateSalePrice = async () => {
    let percent = Profit_Margin / 100;
    let salePrice = Purchase_Price + Purchase_Price * percent;
    await dispatch(change('formProduto', 'Sale_Price', salePrice));
    // document.getElementById('input-margin').focus()
  };

  const handleChangeMultiplierBase = (value) => {
    dispatch(change('formProduto', 'Multiplier_Base', value));
    document.getElementById('input-number').focus();
  };

  const handleChangeSalePrice = async (salePrice) => {
    await setEditSalePrice(true);

    let percentage;
    let profitMargin;

    if (
      (salePrice === 0 && Purchase_Price === 0) ||
      salePrice < Purchase_Price
    ) {
      percentage = 0;
    } else if (salePrice > 0 && Purchase_Price > 0) {
      profitMargin = salePrice / Purchase_Price - 1;
      percentage = profitMargin * 100;
    } else {
      percentage = 100;
    }
    await dispatch(change('formProduto', 'Profit_Margin', percentage));
  };

  const handleChangeMargin = async () => {
    setEditSalePrice(false);
    // document.getElementById('input-margin').focus()
  };

  const handleEditDescription = async (value) => {
    dispatch(change('formProduto', 'updateDescriptionOnSale', value));
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '170px' }}>
          <Field
            label="Preço de Compra"
            required
            name="Purchase_Price"
            component={Input}
            {...currencyMask}
            maxLength="18"
          />
        </Col>

        <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '170px' }}>
          <Field
            id="input-margin"
            label="Margem de Lucro"
            required
            name="Profit_Margin"
            component={Input}
            {...marginMask}
            // maxLength="6"
            onChange={() =>
              editSalePrice ? handleChangeMargin() : calculateSalePrice()
            }
          />
        </Col>

        <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '170px' }}>
          <Field
            label="Preço de Venda"
            required
            name="Sale_Price"
            component={Input}
            {...currencyMask}
            maxLength="18"
            onChange={(e) => handleChangeSalePrice(e.target.value)}
          />
        </Col>

        <div
          style={{
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <label>Base Multiplicadora</label>
          <InputNumber
            className="form-control foco-input"
            style={{ display: 'flex' }}
            onChange={handleChangeMultiplierBase}
            value={Multiplier_Base}
            name="Multiplier_Base"
          />
        </div>

        <Col xs={12} sm={2} md={2} lg={2} className="toggle-column">
          <div>
            <label>Altera Descrição na Venda?</label>
          </div>
          <Toggle
            checked={updateDescriptionOnSale}
            onChange={() =>
              dispatch(
                change(
                  'formProduto',
                  'updateDescriptionOnSale',
                  !updateDescriptionOnSale
                )
              )
            }
          />
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} style={{ maxWidth: '140px' }}>
          <Field
            label="Estoque Mín.:"
            name="minStock"
            disabled={updateDescriptionOnSale}
            component={Input}
            normalize={onlyNumbers}
          />
        </Col>
      </div>

      <Col xs={12} sm={6} md={6} lg={3} style={{ maxWidth: '140px' }}>
        <Field
          label="Estoque Máx.:"
          name="maxStock"
          disabled={updateDescriptionOnSale}
          component={Input}
          normalize={onlyNumbers}
          maxWidth="200px"
        />
      </Col>

      <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '140px' }}>
        <Field
          label="% Comissão"
          name="Commission_Rate"
          component={Input}
          {...marginMask}
          maxLength="6"
        />
      </Col>

      <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '180px' }}>
        <label>
          Tipo de Produto
          <span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          label="Tipo de Produto"
          name="product_type"
          component="select"
          className="form-control foco-input"
        >
          <option value="Produto Final">Produto Final</option>
          <option value="Uso e Consumo">Uso e Consumo</option>
          <option value="Matéria Prima">Matéria Prima</option>
        </Field>
      </Col>

      <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '140px' }}>
        <Field label="Peso (KG):" name="weight" component={Input} {...kgMask} />
      </Col>

      {!isPlanFree && !isPlanStart && (
        <>
          <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '150px' }}>
            <label>Produção</label>
            <Field
              name="production"
              component="select"
              className="form-control foco-input"
            >
              <option value="Terceiros">Terceiros</option>
              <option value="Própria">Própria</option>
            </Field>
          </Col>

          <Col xs={12} sm={2} md={2} lg={2} style={{ maxWidth: '150px' }}>
            <label>Monofásico</label>
            <Field
              label="Tipo de Produto"
              name="singlePhase"
              component="select"
              className="form-control foco-input"
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </Field>
          </Col>

          <Col xs={12} sm={2} md={2} lg={2}>
            <label>Limite de Desconto</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                alignItems: 'center',
                width: '150px',
              }}
            >
              <Toggle
                checked={hasDiscountLimit}
                onChange={() =>
                  dispatch(
                    change('formProduto', 'hasDiscountLimit', !hasDiscountLimit)
                  )
                }
              />
              <Field
                name="discountLimit"
                component={Input}
                disabled={!hasDiscountLimit}
                {...marginMask}
                maxLength="6"
              />
            </div>
          </Col>
        </>
      )}
    </>
  );
}
