import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';
import { Table } from 'v2/components/Table';
import { toastr } from 'react-redux-toastr';
import { TableActions } from './TableActions';
import { listGroupItems } from 'v2/repositories/GroupItemsRepository';
import SearchInput from 'components/SearchInput';

export function GroupItems() {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 1000);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleTriggerFetch = () => {
    setTriggerFetch(true);

    setTimeout(() => {
      setTriggerFetch(false);
    }, [500]);
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const res = await listGroupItems({
        page: page + 1,
        limit,
        query: queryInput,
        companyId,
      });

      setCount(res.count);
      setRows(res.rows);
      setPages(Math.ceil(res.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        return (
          <TableActions {...props.original} triggerFetch={handleTriggerFetch} />
        );
      },
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [queryInput, companyId, page]);

  useEffect(() => {
    if (triggerFetch) {
      handleFetch();
    }
  }, [triggerFetch]);

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            padding: 0,
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'flex-end',
            gap: '10px',
            marginTop: '30px',
          }}
        >
          <button
            className="btn btn-sucesso button-h35"
            onClick={() => navigateToClientPage('group_items', 'form_new')}
            style={{ fontSize: '12px' }}
          >
            <span>+ Novo Grupo</span>
          </button>
          <div>
            <SearchInput
              placeholder="Pesquisa por Descrição"
              value={queryInput}
              onChange={(e) => {
                if (e.target.value.startsWith(' ')) {
                  e.preventDefault();
                  e.target.value = e.target.value.slice(1);
                }
                if (
                  e.nativeEvent.dataTransfer &&
                  e.nativeEvent.dataTransfer.getData('text')
                ) {
                  e.preventDefault();
                }
                setQueryInput(e.target.value);
                debounceQueryInput(e.target.value);
              }}
              style={{
                fontSize: '12px',
                height: '35px',
                width: '312px',
                marginLeft: 0,
                marginRight: 0,
              }}
            />
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            data={rows}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            manual
            onPageSizeChange={setLimit}
            pages={pages}
            loading={isFetching}
          />
        </div>
      </div>
    </DefaultContentContainer>
  );
}
