import React from 'react'

import { cnpjMask, phoneMask, cpfMask } from '../../../client/components/ToNormalize/ToNormalize'

const Header = ({ company }) => {
  const isEntity = company.Type === 'Jurídica'
  const companyAddress = company.Address[0]
  const companyPhones = company.Phones[0]

  function Corporation() {
    return (
      company.IE ?
        <div className='corporation'>
          <span>CNPJ: {cnpjMask(company.Cpf_Cnpj)}</span>
          <span>IE: {company.IE}</span>
        </div>
        :
        <span>CNPJ: {cnpjMask(company.Cpf_Cnpj)}</span>
    )
  }

  function Person() {
    return <span>CPF: {cpfMask(company.Cpf_Cnpj)}</span>
  }

  return (
    <header>
      <div className='main-content'>
        <span><strong>{company.Trading_Name.toUpperCase()}</strong></span>
        {
          isEntity
            ? <Corporation />
            : <Person />
        }

      </div>
      <div className='address-content'>
        <span>{companyAddress.Address}, nº{companyAddress.Address_Number}</span>
        <span>{companyAddress.Neighborhood}, {companyAddress.City} / {companyAddress.State}</span>
        <span>Tel: {phoneMask(companyPhones.Number_Phone1)}</span>
        <span>Email: {company.Email}</span>
      </div>
    </header>
  )
}

export default Header