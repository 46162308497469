import React from 'react';

import './index.css';

export const ActiveStatusLabel = ({ isActive, ...props }) => {
  return (
    <button
      className={`active-status-label flex center ${
        isActive ? 'active' : 'inactive'
      }`}
      {...props}
    >
      <span className="h-100 flex center">
        {isActive ? 'Ativo' : 'Inativo'}
      </span>
    </button>
  );
};
