import React from 'react'
import SelectSearchInput from 'client/components/SelectSearchInput'
import '../styles.css'

const NewFinancingBankSlipSelect = ({
  setPartner,
  partner,
  formattedOptions,
  setValue,
  value,
  isLoading,
  noOptionsMessage,
  disabled,
  placeholder,
}) => {
  return (
    <>
      <div
        style={{
          marginTop: 40,
        }}
        className="new-financing-bank-slip__row"
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <SelectSearchInput
            formattedOptions={formattedOptions}
            setValue={setValue}
            value={value}
            isLoading={isLoading}
            noOptionsMessage={noOptionsMessage}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <span>Parceiro: </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setPartner(parseInt(e.target.value))}
            disabled={disabled}
            value={partner}
          >
            <option value={1}>Koin</option>
            <option value={2}>Boleto Flex</option>
          </select>
        </div>
      </div>
    </>
  )
}

export default NewFinancingBankSlipSelect
