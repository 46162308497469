import React, { useState, useEffect } from "react";

import { reduxForm } from "redux-form";
import CardForm from "client/components/CardForm";
import MainData from "./MainData";

import AlertModal from "../../../../../components/AlertModal/AlertModal";

import FormSubmitButtons from "client/components/FormSubmitButtons";
import { useSelector } from "react-redux";
import constants from "utils/constants";
import { useHistory } from "react-router";

const NewCostCenter = ({ handleSubmit, loading, costCenterId }) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const { description, observations, isActive } = useSelector(
    (state) => state.form.formCostCenter.values
  );

  const handleCancelModal = () => {
    if (description || observations) {
      return setModal(true);
    }
    cancel();
  };

  const cancel = () => {
    history.push(constants.ROUTES.COST_CENTERS);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}
    >
      <CardForm show title="Dados do Centro de Custo" onClick={() => {}}>
        <MainData />
      </CardForm>
      <FormSubmitButtons
        onCancel={handleCancelModal}
        onSubmit={handleSubmit}
        loading={loading}
        hasId={!!costCenterId}
      />
      {modal && (
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message={`Deseja cancelar ${
            costCenterId ? "a edição" : "o cadastro"
          } do centro de custo? Todos os campos preenchidos não serão salvos e ${
            costCenterId ? "a edição" : "o cadastro"
          } poderá ser realizad${costCenterId ? "a" : "o"} posteriormente`}
          onSubmit={cancel}
        />
      )}
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: "formCostCenter",
})(NewCostCenter);
