import React, { memo } from 'react';
import Chart from 'react-google-charts';

const ChartByStatus = ({ data }) => {
  const chartColors = [];

  data.forEach((info) => {
    switch (info[0]) {
      case 'Finalizado':
        chartColors.push('#5cb85c');
        break;
      case 'Aberto':
        chartColors.push('#ffcc00');
        break;
      case 'Cancelado':
        chartColors.push('#ff0000');
        break;
      case 'Aberto (Com Atraso)':
        chartColors.push('#3366cc');
        break;
    }
  });

  return (
    <>
      <h4>Pagamentos por Status (%)</h4>
      <div className="chart-pagamento" style={{ opacity: '100%' }}>
        <Chart
          width="500px"
          height="300px"
          chartType="PieChart"
          data={[
            ['Status', 'Valor', { role: 'tooltip', type: 'string' }],
            ...data,
          ]}
          chartLanguage="pt-br"
          options={{
            chartArea: {
              left: 0,
            },
            colors: chartColors,
          }}
        />
      </div>
    </>
  );
};

export default memo(ChartByStatus);
