import React, { useState, useEffect } from 'react'
import { change } from 'redux-form'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useParams, withRouter } from 'react-router'

import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import FormNewDiscountGroupTable from './FormNewDiscountGroupTable'

import constants from 'utils/constants'
import { useAuth } from 'contexts/auth'
import discountGroupRepository from 'repositories/DiscountGroups'

import './styles.css'

function BundleNewDiscountGroup() {
  const [loading, setLoading] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [formValues, setFormValues] = useState({})

  const history = useHistory()

  const { id: discountGroupId } = useParams()

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  useEffect(() => {
    if (discountGroupId) loadDiscountGroup(discountGroupId)
  }, [discountGroupId])

  async function loadDiscountGroup(id) {
    try {
      setLoading(true)

      const { name, margin } = await discountGroupRepository.findById(id)

      dispatch([
        change('discountGroup', 'name', name),
        change('discountGroup', 'margin', margin),
      ])
    } catch (err) {
      toastr.error(
        'Erro ao carregar dados da tabela',
        'Ocorreu um erro ao carregar os dados da tabela de preços diferenciados, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }
  async function handleValidate(values) {
    const { name, margin } = values
    if (!name)
      return toastr.warning('O preenchimento da descrição é obrigatório')

    setShowOptionsModal(true)
  }

  async function handleSubmit(nextPath) {
    discountGroupId
      ? update(discountGroupId, formValues, nextPath)
      : create(formValues, nextPath)
  }

  async function create(values, nextPath) {
    try {
      const { name, margin } = values

      const response = await discountGroupRepository.create({
        name,
        margin,
        companyId,
      })

      if (response.status === 400 && response.data.duplicated === true) {
        toastr.warning(
          'Alerta de duplicação',
          'Essa descrição ja foi cadastrada, altere-a e tente novamente'
        )
      } else {
        toastr.success('Tabela de preços diferenciados cadastrada com sucesso')

        // Delay no redirecionamento para conseguir carregar o novo item na tabela
        setTimeout(() => {
          if (nextPath === 'LIST') {
            history.push(constants.ROUTES.DISCOUNT_GROUPS)
          } else if (nextPath === 'PRODUCTS') {
            history.push(`/client/discount-group-products#${response.data.id}`)
          } else if (nextPath === 'SERVICES') {
            history.push(`/client/discount-group-services#${response.data.id}`)
          }
        }, 1000)
      }
    } catch (err) {
      toastr.error(
        'Erro no cadastro',
        'Ocorreu um erro ao cadastrar uma tabela de preços diferenciados, tente novamente'
      )
    }
  }

  async function update(discountGroupId, values, nextPath) {
    try {
      const { name, margin } = values

      const response = await discountGroupRepository.update(discountGroupId, {
        name,
        margin,
        companyId,
      })

      if (response.status === 400 && response.data.duplicated === true) {
        return toastr.warning(
          'Alerta de duplicação',
          'Essa descrição já foi cadastrada, altere-a e tente novamente'
        )
      } else {
        toastr.success('Tabela de preços diferenciados atualizada com sucesso')

        // Delay no redirecionamento para conseguir carregar o novo item na tabela
        setTimeout(() => {
          if (nextPath === 'LIST') {
            history.push(constants.ROUTES.DISCOUNT_GROUPS)
          } else if (nextPath === 'PRODUCTS') {
            history.push(`/client/discount-group-products#${discountGroupId}`)
          } else if (nextPath === 'SERVICES') {
            history.push(`/client/discount-group-services#${discountGroupId}`)
          }
        }, 400)
      }
    } catch (err) {
      toastr.error(
        'Erro na atualização',
        'Ocorreu um erro ao atualizar a tabela de preços diferenciados, tente novamente'
      )
    }
  }

  return (
    <>
      <FormNewDiscountGroupTable
        onSubmit={(values) => {
          setFormValues(values)
          handleValidate(values)
        }}
        initialValues={{
          name: '',
          margin: 0,
        }}
        loading={loading}
        discountGroupId={discountGroupId}
        onCancel={() => setShowCancelModal(true)}
      />

      <Modal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>
            Você tem certeza que deseja cancelar o{' '}
            {discountGroupId ? 'edição' : 'cadastro'} da tabela de preços
            diferenciados?
          </strong>
          <br />
          <p>
            Todos os dados inseridos{' '}
            {discountGroupId ? 'nesta edição' : 'neste cadastro'} não serão
            salvos
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              disabled={loading}
              name="Não"
              onClick={() => setShowCancelModal(false)}
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              disabled={loading}
              name="Sim"
              fill
              onClick={() => history.push(constants.ROUTES.DISCOUNT_GROUPS)}
            >
              Sim{' '}
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Escolha abaixo a opção desejada:</strong>
          <br />
          <br />
          <p>
            Você pode salvar o cadastro da tabela e realizar as adiçãoes de
            produtos e serviços posteriormente
          </p>
          <br />
          <br />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '12px',
            }}
          >
            <button
              className="btn btn-next-step"
              style={{
                backgroundColor: '#428BCA',
                borderColor: '#428BCA',
              }}
              disabled={loading}
              onClick={() => {
                setShowOptionsModal(false)
                handleSubmit('PRODUCTS')
              }}
              fill
            >
              Salvar e Adicionar Produtos
            </button>
            <button
              className="btn btn-next-step"
              style={{
                backgroundColor: '#5BC0DE',
                borderColor: '#5BC0DE',
              }}
              disabled={loading}
              fill
              onClick={() => {
                setShowOptionsModal(false)
                handleSubmit('SERVICES')
              }}
            >
              Salvar e Adicionar Serviços
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </button>
            <button
              className="btn btn-next-step"
              style={{
                backgroundColor: '#5CB85C',
                borderColor: '#5CB85C',
              }}
              disabled={loading}
              fill
              onClick={() => {
                setShowOptionsModal(false)
                handleSubmit('LIST')
              }}
            >
              Salvar e Voltar para consulta
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default withRouter(BundleNewDiscountGroup)
