import React, { createContext, useState, useEffect, useContext } from 'react';
import adminsRepository from '../repositories/Admins';

import api from 'services/api';

const AdminContext = createContext({
  isSigned: false,
  adminUser: null,
  adminName: '',
  adminRoleId: null,
  isAdmisRoleAdministrator: false,
  loading: false,
  signIn: () => {},
  signOut: () => {},
  setAdminUser: () => {},
});

export default function AdminAuthProvider({ children }) {
  const [admin, setAdmin] = useState(null);
  const [isSigned, setIsSigned] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [adminRoleId, setAdminRoleId] = useState(null);
  const [loading, setLoading] = useState(true);

  const isRoleAdministrator = adminRoleId === 1;

  useEffect(() => {
    signIn();
  }, []);

  async function signIn() {
    const token = localStorage.getItem('jwt');
    api.defaults.headers.common['x-access-token'] = token;

    const adminId = localStorage.getItem('ADMIN_ID');

    if (!!adminId) {
      try {
        const admin = await adminsRepository.show(adminId);
        setAdminUser(admin);
      } catch (err) {
        signOut();
      }
    }

    setLoading(false);
  }

  function setAdminUser(admin) {
    setLoading(true);
    const token = localStorage.getItem('jwt');
    api.defaults.headers.common['x-access-token'] = token;
    const adminName = admin.Name?.split(' ')[0];
    setAdmin(admin);
    setAdminName(adminName);
    setAdminRoleId(admin.roleId);
    setIsSigned(true);
    localStorage.setItem('ADMIN_ID', admin.id);
    localStorage.setItem('ADMIN_NAME', adminName);
    setLoading(false);
  }

  function signOut() {
    localStorage.clear();
    api.defaults.headers.common['x-access-token'] = null;
    setAdmin(null);
    setIsSigned(false);
    setLoading(false);
  }

  return (
    <AdminContext.Provider
      value={{
        isSigned,
        admin,
        adminName,
        adminRoleId,
        loading,
        signIn,
        signOut,
        setAdminUser,
        isRoleAdministrator,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export function useAdminAuth() {
  const context = useContext(AdminContext);

  return context;
}
