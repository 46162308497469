import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

const ClientActionButton = ({ title, icon, backgroundColor, onClick, loading }) => {
  return (
    <button
      class="client-action-button"
      style={{ backgroundColor }}
      onClick={onClick}
    >
      <span>{title}</span>
      <FontAwesomeIcon icon={icon} />
      <span className={loading ? 'fa fa-spinner fa-pulse fa-1x update-spinner' : 'update-spinner-disabled'} />
    </button>
  )
}

export default ClientActionButton
