import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';

import {
  onlyNumbers,
  chaveDeAcessoMask,
  cnpjMask,
  cpfMask,
} from '../../../../components/ToNormalize/ToNormalize';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import FormModalClient from 'client/components/FormModals/Client';
import { toastr } from 'react-redux-toastr';

const MainData = ({ isMultiInvoiceModal, loadNFCe }) => {
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const formName = isMultiInvoiceModal ? 'invoiceForm' : 'cadastroNFCe';
  const {
    customerId,
    cnpjCpf,
    isInvoiceIssued,
    isInvoiceOpenedWithErrors,
    cliente,
  } = useSelector((state) => state.form[formName].values);

  const validateClientType = () => {
    if (cliente === 'Cliente Consumidor') {
      return toastr.warning(
        'Não foi possível abrir cadastro de cliente',
        'Cliente consumidor não possui cadastro de cliente'
      );
    } else {
      setIsCustomerModalOpen(true);
    }
  };

  function handleUpdateCustomer() {
    loadNFCe();
    setIsCustomerModalOpen(false);
  }

  return (
    <div>
      <Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
        <Field
          name="nfc"
          label="NFC"
          component={Input}
          normalize={onlyNumbers}
          disabled
        />
      </Col>
      <Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
        <Field
          name="serie"
          label="Série"
          component={Input}
          normalize={onlyNumbers}
          disabled
        />
      </Col>
      <Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
        <Field
          name="venda"
          label="Venda"
          component={Input}
          normalize={onlyNumbers}
          disabled
        />
      </Col>
      <Col lg={9} md={9} sm={9} xs={12} style={{ padding: '1px 1px' }}>
        <Field
          name="statusNFCe"
          label="Status da NFC-e"
          component={Input}
          disabled
          style={{
            color: isInvoiceIssued
              ? 'green'
              : isInvoiceOpenedWithErrors
              ? 'red'
              : 'unset',
          }}
        />
      </Col>
      <Col
        lg={2}
        md={2}
        sm={2}
        xs={12}
        style={{ padding: '1px 1px', width: '14%' }}
      >
        <Field
          name="dataCriacao"
          label="Data de Criação"
          type="date"
          component={Input}
          disabled
        />
      </Col>
      <Col
        lg={2}
        md={2}
        sm={2}
        xs={12}
        style={{ padding: '1px 1px', width: '17%' }}
      >
        <Field
          name="dataEmissao"
          label="Data de Emissão"
          type="datetime-local"
          component={Input}
          disabled
          placeholder="AAAAAAA"
        />
      </Col>
      <Col
        lg={5}
        md={5}
        sm={5}
        xs={12}
        style={{ padding: '1px 1px', width: '33%' }}
      >
        <Field
          name="chaveDeAcesso"
          label="Chave de Acesso"
          component={Input}
          normalize={chaveDeAcessoMask}
          disabled
        />
      </Col>
      <Col
        lg={3}
        md={3}
        sm={3}
        xs={12}
        style={{ padding: '1px 1px', width: '19%' }}
      >
        <div className={'div-a-modal'}>
          <a onClick={() => validateClientType()}>Visualizar Cliente </a>
        </div>
        <Field name="cliente" label="Cliente" component={Input} disabled />
      </Col>
      <Col lg={2} md={2} sm={2} xs={12} style={{ padding: '1px 1px' }}>
        <Field
          name="cnpjCpf"
          label="CNPJ/CPF"
          component={Input}
          maxLength={18}
          normalize={cnpjCpf?.length <= 11 ? cpfMask : cnpjMask}
          disabled
        />
      </Col>

      {isCustomerModalOpen && (
        <FormModalClient
          customerId={customerId}
          onCancel={() => setIsCustomerModalOpen(false)}
          onSubmit={handleUpdateCustomer}
        />
      )}
    </div>
  );
};

export default MainData;
