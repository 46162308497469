import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.css';

const Card = (props) => {
  const [expanded, setExpanded] = useState(true);

  const styles = {
    line: {
      border: '1px solid black',
    },
    gridCompany: {
      display: 'grid',
      gridTemplateRows: '15% 15% 15% 15%',
      marginTop: '3%',
    },
    fontConteudo: {
      fontSize: '14px',
      color: '#444',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '350px 350px',
    },
    grid3: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      padding: '10px 20px',
    },
    borderRigth: {
      marginRight: '10px',
      borderRight: '1px solid #d6d6d6',
    },
    borderBottom: {
      borderBottom: '1px solid #d6d6d6',
      width: '90%',
    },
    borderBottom2: {
      borderBottom: '1px solid #d6d6d6',
      width: '90%',
    },
    borderBottom1: {
      borderBottom: '1px solid #d6d6d6',
      width: '42%',
    },
    value: {
      marginTop: '90px',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '500px',
      fontSize: '16px',
    },
    marginBottom: {
      marginBottom: '10px',
    },
    imgCompany: {
      width: '140px',
      height: '115px',
      marginLeft: '-10px',
      marginRight: '-15px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const handleCardExpansionChange = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <div className="sale-card-main">
      <div className="sale-card-container">
        <div className="sale-card-header">
          <span style={{ color: '#1d7391', fontSize: '17px' }}>
            <FontAwesomeIcon color="#1d7391" icon={props.icon} />
            <strong style={{ marginLeft: '5px' }}>{props.title}</strong>
          </span>
          <a
            href="#"
            className="sale-card-action"
            onClick={() => handleCardExpansionChange()}
          >
            {expanded ? 'Ocultar' : 'Exibir'}
          </a>
        </div>

        <div
          className={`sale-card-content ${!expanded ? 'hidden' : ''} ${
            props.className
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Card;
