import React, { useEffect, useState } from 'react';
import CollapsibleSection from 'components/CollapsibleSection';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import Toggle from 'react-toggle';
import { DefaultContentContainer } from 'v2/components/layout';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { InputContainer } from 'v2/components/Input';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer, RequiredAsterisk } from './FormDefect.styles';
import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { formSchema, getBreadcrumbData, optionsOrderBySelect } from './utils';
import { toastr } from 'react-redux-toastr';
import { Table } from 'v2/components/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlusSquare,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { InputSelectDefault } from 'v2/components/Input';
import { SelectGroupItems } from 'v2/client/components/SelectGroupItems';
import { SelectDefects } from 'v2/client/components/SelectDefect';
import {
  getChecklistById,
  createChecklist,
  updateChecklist,
} from 'v2/repositories/ChecklistRepository';
import { useRecoilState } from 'recoil';
import { companySegments as companySegmentsAtom } from 'storage/companyDetailsStorage';
import { SelectCorrections } from 'v2/client/components/SelectCorrection';

export function FormChecklist({
  hideBreadCrumb,
  hideActionButtons,
  checklistIdProp,
  onSubmitSuccess,
  onSubmitFail,
  triggerSubmit,
}) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { id } = useParams();
  const isEditing = !!id;
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistItemsExpanded, setIsChecklistItemsExpanded] =
    useState(true);
  const [isBasicDataExpanded, setIsBasicDataExpanded] = useState(true);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      id: id ? id : null,
      description: '',
      defaultDesign: {
        label: 'Não Exibir',
        value: 'Não Exibir',
      },
      orderBy: {
        label: 'Ordem Alfabética',
        value: 'Alphabetical',
      },
      isActive: true,
    },
  });

  const [rows, setRows] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [isCorrectionsLoaded, setIsCorrectionsLoaded] = useState(false);
  const [optionsCorrections, setOptionsCorrections] = useState([]);
  const [selectedGroupItems, setSelectedGroupItems] = useState(null);
  const [selectedDefect, setSelectedDefect] = useState(null);
  const [selectedCorrection, setSelectedCorrection] = useState(null);
  const [itemToCheckInput, setItemToCheckInput] = useState('');
  const [editItem, setEditItem] = useState(null);

  const { isActive, orderBy, defaultDesign } = useWatch({
    control,
  });
  const [companySegments] = useRecoilState(companySegmentsAtom);

  const numberToStringSegments = {
    1: 'Carros',
    2: 'Motos',
    3: 'Pesados',
    7: 'Van',
    8: 'Bicicleta',
  };
  const availableSegments = companySegments
    .filter((number) => numberToStringSegments[number])
    .map((number) => numberToStringSegments[number]);

  const optionsDefaultDesign = [
    {
      label: 'Não Exibir',
      value: 'Não Exibir',
    },
  ].concat(
    availableSegments.map((option) => ({
      label: option,
      value: option,
    }))
  );

  const handleChecklistItemsSectionState = () => {
    setIsChecklistItemsExpanded(!isChecklistItemsExpanded);
  };
  const handleBasicDataSectionState = () => {
    setIsBasicDataExpanded(!isBasicDataExpanded);
  };

  const handleChangeChecklistInput = (e) => {
    const value = e.target.value;

    if (value.length > 50) return;
    setValue('description', value);
  };

  const handleErrors = () => {
    if (
      errors.description &&
      errors.description.message === 'description is a required field'
    ) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
    }
  };

  const handleCreate = async (data) => {
    setIsLoading(true);
    try {
      const { description, defaultDesign, orderBy, isActive } = data;
      const request = {
        description,
        defaultDesign: defaultDesign.value,
        orderBy: orderBy.value,
        status: isActive,
        companyId,
        checklistItems: rows.map(({ index, ...rest }) => rest),
      };
      const res = await createChecklist(request);

      if (onSubmitSuccess) {
        return onSubmitSuccess(res);
      }

      navigateToClientPage('checklist', 'base');
    } catch (err) {
      if (onSubmitFail) {
        onSubmitFail();
      }
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const { id, description, defaultDesign, orderBy, isActive } = data;
      const request = {
        description,
        defaultDesign: defaultDesign.value,
        orderBy: orderBy.value,
        status: isActive,
        companyId,
        checklistItems: rows.map(({ index, ...rest }) => rest),
      };
      await updateChecklist(id, request);
      toastr.success('Edição realizada', 'Checklist alterado com sucesso');
      navigateToClientPage('checklist', 'base');
    } catch (err) {
      if (onSubmitFail) {
        onSubmitFail();
      }
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    return isEditing ? handleUpdate(data) : handleCreate(data);
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const response = await getChecklistById(id, companyId);
      const { description, defaultDesign, status, orderBy, ChecklistItems } =
        response;

      setValue('description', description);
      setValue('defaultDesign', { label: defaultDesign, value: defaultDesign });
      setValue('orderBy', {
        label:
          orderBy.toUpperCase() === 'ALPHABETICAL'
            ? 'Ordem Alfabética'
            : 'Lançamento na tabela itens',
        value: orderBy,
      });
      setValue('isActive', status);

      const itemsWithIndex = ChecklistItems.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setRows(itemsWithIndex);
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const handleSelectDefect = (item) => {
    const formattedCorrections = item.value.correctionsAssociated.map(
      (correction) => {
        return {
          label: correction.description,
          value: {
            id: correction.id,
            description: correction.description,
          },
        };
      }
    );
    setOptionsCorrections(formattedCorrections);
    setSelectedCorrection(null);
    setSelectedDefect(item);
  };

  const handleEditItem = (item) => {
    setItemToCheckInput(item.itemToCheck);
    setSelectedGroupItems(
      item.GroupItems
        ? {
            label: item.GroupItems.description,
            value: {
              id: item.GroupItems.id,
              description: item.GroupItems.description,
            },
          }
        : null
    );
    setSelectedDefect(
      item.Defects
        ? {
            label: item.Defects.description,
            value: {
              id: item.Defects.id,
              description: item.Defects.description,
            },
          }
        : null
    );
    setSelectedCorrection(
      item.Corrections
        ? {
            label: item.Corrections.description,
            value: {
              id: item.Corrections.id,
              description: item.Corrections.description,
            },
          }
        : null
    );

    const newRows = rows.filter(
      (checklistItem) => checklistItem.itemToCheck !== item.itemToCheck
    );

    if (editItem !== null) {
      const oldEditItem = editItem;
      setRows([...newRows, oldEditItem]);
      setEditItem(item);
      return;
    }

    setEditItem(item);
    setRows(newRows);
  };

  const handleAddItem = () => {
    if (itemToCheckInput === '' || !selectedGroupItems) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
      return;
    }

    const itemExists = rows.some(
      (row) => row.itemToCheck === itemToCheckInput.trim()
    );

    if (itemExists) {
      toastr.warning(
        'Item duplicado',
        'Este item já existe na lista. Por favor, escolha um item diferente.'
      );
      return;
    }

    let newRow = {
      itemToCheck: itemToCheckInput.trim(),
      defectId: selectedDefect ? selectedDefect.value.id : null,
      groupItemsId: selectedGroupItems ? selectedGroupItems.value.id : null,
      correctionId: selectedCorrection ? selectedCorrection.value.id : null,
      Corrections: selectedCorrection
        ? {
            id: selectedCorrection.value.id,
            description: selectedCorrection.value.description,
          }
        : null,
      Defects: selectedDefect
        ? {
            id: selectedDefect.value.id,
            description: selectedDefect.value.description,
          }
        : null,
      GroupItems: selectedGroupItems
        ? {
            id: selectedGroupItems.value.id,
            description: selectedGroupItems.value.description,
          }
        : null,
      index: rows.length > 0 ? rows[rows.length - 1].index + 1 : 1,
    };
    setItemToCheckInput('');
    setSelectedGroupItems(null);
    setSelectedDefect(null);
    setSelectedCorrection(null);
    setOptionsCorrections([]);

    if (editItem !== null) {
      newRow = {
        ...newRow,
        ...(editItem.id ? { id: editItem.id } : {}),
        index: editItem.index,
      };
      setEditItem(null);
    }
    setRows([...rows, newRow].sort((a, b) => a.index - b.index));
    toastr.success('Atualização efetuada', 'Item adicionado com sucesso');
  };

  const handleDeleteItem = async (item) => {
    const newRows = rows.filter(
      (checklistItem) => checklistItem.itemToCheck !== item.itemToCheck
    );
    toastr.success('Atualização efetuada', 'Item removido com sucesso');
    setRows(newRows);
  };

  const columns = [
    {
      Header: 'Itens a serem verificados',
      accessor: 'itemToCheck',
      width: 300,
      Cell: (props) => {
        return <div>{props.original.itemToCheck}</div>;
      },
    },
    {
      Header: 'Grupo',
      accessor: 'groupItem',
      Cell: (props) => {
        return (
          <div>
            {props.original.GroupItems
              ? props.original.GroupItems.description
              : null}
          </div>
        );
      },
    },
    {
      Header: 'Defeito',
      accessor: 'defectItem',
      width: 120,
      Cell: (props) => {
        return (
          <div>
            {props.original.Defects ? props.original.Defects.description : null}
          </div>
        );
      },
    },
    {
      Header: 'Correção',
      accessor: 'correctionItem',
      width: 120,
      Cell: (props) => {
        return (
          <div>
            {props.original.Corrections
              ? props.original.Corrections.description
              : null}
          </div>
        );
      },
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        return (
          <div className="flex center gap-050">
            <button
              title="Editar"
              className="button-icon"
              onClick={() => handleEditItem(props.original)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              title="Excluir"
              className="button-icon"
              onClick={() => handleDeleteItem(props.original)}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    handleErrors();
  }, [errors]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit(submitForm)();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (isEditing) {
      handleFetch();
    }
  }, [companyId]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel={!hideBreadCrumb}>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados do Checklist"
          isExpanded={isBasicDataExpanded}
          handleExpand={() => handleBasicDataSectionState()}
        >
          <form method="POST">
            <FormContainer>
              <div className="flex gap-1">
                <InputContainer>
                  <InputLabel required>Descrição:</InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={50}
                    width="400"
                    height={'35px'}
                    {...register('description')}
                    onChange={handleChangeChecklistInput}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Desenho Padrão:</InputLabel>
                  <InputSelectDefault
                    width={'130px'}
                    height={'35px'}
                    options={optionsDefaultDesign}
                    value={defaultDesign}
                    onChange={(value) => setValue('defaultDesign', value)}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Ordenação de Exibição de Grupo:</InputLabel>
                  <InputSelectDefault
                    width={'230px'}
                    height={'35px'}
                    value={orderBy}
                    options={optionsOrderBySelect}
                    onChange={(value) => setValue('orderBy', value)}
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel htmlFor="input__is-active">Status:</InputLabel>
                  <div className="flex center" style={{ height: '35px' }}>
                    <Toggle
                      id="input__is-active"
                      checked={isActive}
                      onChange={() => setValue('isActive', !isActive)}
                    />
                  </div>
                </InputContainer>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>
        <br />
        <CollapsibleSection
          title="Itens do Checklist"
          isExpanded={isChecklistItemsExpanded}
          handleExpand={() => handleChecklistItemsSectionState()}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <span
              style={{ fontSize: '12px', fontWeight: 'bold', color: '#D43F3A' }}
            >
              Defeitos e correções não são obrigatórios o preenchimento. A
              informação poderá ser alterada na tela da venda.
            </span>
          </div>
          <div className="flex gap-1" style={{ width: '100%' }}>
            <InputContainer>
              <InputLabel htmlFor="input__is-active" required>
                Item a ser verificado:
              </InputLabel>
              <InputText
                maxLength={50}
                value={itemToCheckInput}
                onChange={(e) => setItemToCheckInput(e.target.value)}
                width="300"
                height={'35px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>
                Grupo: <RequiredAsterisk>*</RequiredAsterisk>
              </InputLabel>

              <SelectGroupItems
                value={selectedGroupItems}
                haveExitButton
                height={'35px'}
                onChange={(item) => setSelectedGroupItems(item)}
                width={'180px'}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Defeito:</InputLabel>
              <SelectDefects
                width={'240px'}
                height={'35px'}
                value={selectedDefect}
                onChange={handleSelectDefect}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Correção:</InputLabel>
              <SelectCorrections
                width={'340px'}
                height={'35px'}
                disabled={selectedDefect === null}
                value={selectedCorrection}
                options={optionsCorrections}
                onChange={(item) => setSelectedCorrection(item)}
              />
            </InputContainer>
            <button
              title="Adicionar"
              className="button-icon"
              onClick={handleAddItem}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faPlusSquare}
                size="2x"
                className="text-green"
              />
            </button>
          </div>
          <div className="mt-2" style={{ width: '100%' }}>
            <Table columns={columns} data={rows} loading={isFetching} />
          </div>
        </CollapsibleSection>
      </div>
      {!hideActionButtons && (
        <div className="flex align-center end gap-075 mt-2">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={() => navigateToClientPage('defects', 'base')}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            type="submit"
            style={{ width: '100px' }}
            onClick={handleSubmit(submitForm)}
            disabled={isLoading}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      )}
    </DefaultContentContainer>
  );
}
