import React from 'react'
import ReactTable from 'react-table'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CompanyTaxErrorsTable = ({ errors }) => {
  return (
    <ReactTable
      className='company-tax-errors-table'
      data={errors}
      columns={[
        {
          Header: '',
          width: 50,
          Cell: () => (
            <FontAwesomeIcon icon={faTimesCircle} color='red' size={30} />
          ),
        },
        {
          Header: 'Item',
          accessor: 'item',
          width: 250,
        },
        {
          Header: 'Erro',
          accessor: 'error',
          width: 250,
        },
        {
          Header: 'Correção',
          accessor: 'correction',
        },
      ]}
      defaultPageSize={5}
      sortable={false}
      previousText='Anterior'
      nextText='Próximo'
      loadingText='Carregando...'
      noDataText=''
      pageText='Página'
      ofText='de'
      rowsText='linhas'
    />
  )
}

export default CompanyTaxErrorsTable
