import React from 'react'
import Bundle from './Bundle'
import './styles.css'

function PurchaseXmlImport({ onSubmit }) {
  return <Bundle initialValues={{
    page: 1,
    loading: false,
    loadingMessage: ''
  }}
    onSubmit={onSubmit}
  />
}

export default PurchaseXmlImport