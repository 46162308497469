import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';
import { PaymentAuthorizationCodeReviewManySalesTable } from './PaymentAuthorizationCodeReviewManySalesTable';
import { useAuth } from 'contexts/auth';
import { saveAuthorizationCode } from 'v2/repositories/BillsToReceiveRepository';
export function ModalPaymentAuthorizationCodeReviewManySales({
  isOpen,
  handleCloseModal,
  sales,
  handleManySale,
}) {
  const { companyId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const handleSabeAuthorizationCode = async () => {
    try {
      setIsLoading(true);

      const data = sales.map((sale) => {
        return {
          id: sale.titleId,
          cAuth: sale.authorizationCode,
        };
      });

      await saveAuthorizationCode(data, companyId);
      handleManySale(data);
      toastr.success('Código de autorização salvo com sucesso');
    } catch (err) {
      toastr.error('Erro ao salvar o código de autorização', err.message);
    } finally {
      setIsLoading(false);

      handleCloseModal();
    }
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => handleCloseModal()}
      animation
      dialogClassName="modal-60w"
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 1rem',
          }}
        >
          <strong>Pagamento das Vendas</strong>
          <span className="text-red fontSize-12">
            Os pagamentos abaixo são um espelho dos títulos das OS/Vendas/PDV
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentAuthorizationCodeReviewManySalesTable sales={sales} />
      </Modal.Body>
      <Modal.Footer>
        <div className=" ">
          <CustomButton
            disabled={isLoading}
            bsStyle="danger"
            name="Cancelar"
            style={{
              fontSize: '12px',
            }}
            onClick={() => {
              handleCloseModal();
            }}
            fill
          >
            Cancelar
          </CustomButton>

          <CustomButton
            bsStyle="info"
            name="Editar"
            style={{
              backgroundColor: '#5cb85c',
              borderColor: '#5cb85c',
              fontSize: '12px',
            }}
            onClick={() => handleSabeAuthorizationCode()}
            fill
            disabled={isLoading}
            isLoading={isLoading}
          >
            Salvar
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
