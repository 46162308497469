import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import PureLoader from '../../../components/PureLoader'
import { encrypt } from '../../components/ToNormalize/ToNormalize'
import AlertModal from 'components/AlertModal/AlertModal'
import NFCesRepository from '../../../repositories/NFCes'
import SATCFesRepository from '../../../repositories/SATCFe'
import NFesRepository from '../../../repositories/NFes'
import NFSesRepository from '../../../repositories/NFSes'
import companiesRepository from '../../../repositories/Companies'
import constants from '../../../utils/constants'
import { isDefaultCustomer } from '../../../utils/isDefaultCustomer'
import FiscalModuleInactiveModal from '../../components/FiscalModuleInactiveModal'

import saleInvoiceGenerationErrors from './errors/saleInvoiceGenerationErrors'

import { useAuth } from '../../../contexts/auth'

import './styles.css'

const SaleInvoice = ({ sale, history, onHide }) => {
  const [loading, setLoading] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isModuleInactiveModalOpen, setIsModuleInactiveModalOpen] =
    useState(false)
  const [inactiveModule, setInactiveModule] = useState({})
  const [type, setType] = useState('')
  const [fiscalDocumentType, setFiscalDocumentType] = useState('')

  const { company } = useAuth()

  useEffect(() => {
    if (!!company.id) {
      loadFiscalInformations()
    }
  }, [company])

  async function loadFiscalInformations() {
    try {
      const NFCeModule = company?.Modules[1]
      const NFCeModuleStatus = NFCeModule.CompanyModules?.status

      if (NFCeModuleStatus === 3) {
        const companyTaxData = await companiesRepository.getTaxData(company.id)

        setFiscalDocumentType(companyTaxData.SettingsNFCe?.fiscalDocumentType)
      }
    } catch (err) {
      toastr.error('Falha ao carregar os dados fiscais. Tente novamente!')
      console.error(err)
      onHide()
    }
  }

  async function generateNFCe() {
    setLoading(true)
    try {
      const nfce = await NFCesRepository.generateBySale(sale.id)
      handleOpenNFCe(nfce.id)
    } catch (err) {
      toastr.error(
        'Falha ao gerar NFC-e',
        `${
          err.response?.data?.message ?? 'Por favor, tente novamente mais tarde'
        }`
      )
      console.error(err)
    }
    setLoading(false)
  }

  async function handleOpenNFCe(id) {
    const hash = await encrypt(String(id), '@OS-dig:NfceId')
    onHide()
    history.push(`${constants.ROUTES.EDIT_NFCE}?id=${hash}`)
  }

  async function generateSAT() {
    setLoading(true)
    try {
      const satcfe = await SATCFesRepository.generateBySale(sale.id)
      handleOpenSAT(satcfe.id)
    } catch (err) {
      toastr.error(
        err.response?.data?.message ??
          'Falha na emissão do SAT, verifique o motivo do erro na tela do SAT, no menu Notas Fiscais'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleOpenSAT(id) {
    const hash = encrypt(String(id), '@OS-dig:SATCFeId')
    onHide()
    history.push(`${constants.ROUTES.EDIT_SATCFE}?id=${hash}`)
  }

  async function generateNFe() {
    setLoading(true)
    try {
      const nfe = await NFesRepository.generateBySale(sale.id)
      handleOpenNFe(nfe.id)
    } catch (err) {
      toastr.error(
        'Falha ao gerar NF-e',
        `${
          err.response?.data?.message ?? 'Por favor, tente novamente mais tarde'
        }`
      )
      console.log(err.response?.data)
    }
    setLoading(false)
  }

  async function handleOpenNFe(id) {
    const hash = await encrypt(String(id), '@OS-dig:NfeId')
    onHide()
    history.push(`${constants.ROUTES.EDIT_NFE}?id=${hash}`)
  }

  async function generateNFSe() {
    setLoading(true)
    try {
      const { data } = await NFSesRepository.generate(sale.id)
      handleOpenNFSe(data.id)
    } catch (err) {
      const errorMessage =
        saleInvoiceGenerationErrors[err.response.data.code] ||
        saleInvoiceGenerationErrors.defaultMessage

      toastr.error(errorMessage ?? 'Ocorreu algum problema ao gerar NFS-e')
    } finally {
      setLoading(false)
    }
  }

  async function handleOpenNFSe(id) {
    const hash = await encrypt(String(id), '@OS-dig:NfseId')
    onHide()
    history.push(`${constants.ROUTES.NFSE}?id=${hash}`)
  }

  function handleSubmit() {
    setIsConfirmModalOpen(false)

    if (type === 'NFC-e') return generateNFCe()
    if (type === 'NF-e') return generateNFe()
    if (type === 'NFS-e') return generateNFSe()
    if (type === 'SAT CF-e') return generateSAT()

    toastr.error('Erro inesperado!', 'Entre em contato com o suporte.')
  }

  function handleOpenConfirmModal(type) {
    setType(type)

    const modulesIndex = {
      'NF-e': 0,
      'NFC-e': 1,
      'SAT CF-e': 1,
      'NFS-e': 2,
    }

    const module = company?.Modules[modulesIndex[type]]
    const modulesStatus = module.CompanyModules?.status

    if (modulesStatus !== 3) {
      setInactiveModule(module)
      return setIsModuleInactiveModalOpen(true)
    }

    setIsConfirmModalOpen(true)
  }

  const hasAnyInvoiceNotCreated =
    !sale.nfe_id || !sale.nfce_id || !sale.sat_id || !sale.nfse_id
  const hasAnyInvoiceCreated =
    !!sale.nfe_id || !!sale.nfce_id || !!sale.sat_id || !!sale.nfse_id

  const hasNFCe = !!sale.nfce_id
  const hasSAT = !!sale.sat_id
  const hasNFe = !!sale.nfe_id
  const hasNFSe = !!sale.nfse_id

  const isDefaultCustomerPDV =
    isDefaultCustomer(sale.Customer) &&
    sale.SalesType.Description === constants.SALE_TYPE_DESCRIPTION[4]
  const isOS = sale.SalesType.id === constants.SALES_TYPE_ID.ORDER_OF_SERVICE
  const items = sale.SalesItems.length ? sale.SalesItems : []
  const onlyServicesInSale =
    isOS && !!!items.filter((item) => item.Type === 'Produto').length

  return (
    <>
      <Modal
        dialogClassName="modalNotas modal-25w"
        show
        onHide={onHide}
        animation
        style={{
          margin: 'auto',
        }}
      >
        <Modal.Header
          closeButton
          style={{
            padding: '8px 15px',
          }}
        >
          <Modal.Title>
            <div className="survey-modal-header">
              <h3>
                <strong>Nota Fiscal</strong>
              </h3>
              <h6>
                <strong>{sale.Type}: </strong>
                {sale.Code}
              </h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: '8px 5px',
          }}
        >
          {hasAnyInvoiceNotCreated && (
            <>
              <label
                style={{
                  display: 'flex',
                  marginRight: 'auto',
                }}
              >
                Deseja emitir qual Nota Fiscal?
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div id="botao-nota">
                  {!hasNFe && !isDefaultCustomerPDV && !onlyServicesInSale && (
                    <button
                      className="btn btn-sucesso"
                      onClick={() => handleOpenConfirmModal('NF-e')}
                      disabled={sale.Type === 'PDV'}
                      title={
                        sale.Type === 'PDV'
                          ? 'Vendas de PDV não podem emitir NF-e'
                          : ''
                      }
                    >
                      NF-e - Nota Fiscal Eletrônica
                    </button>
                  )}
                  {!hasNFCe &&
                    !onlyServicesInSale &&
                    fiscalDocumentType !== 'SAT' && (
                      <button
                        className="btn btn-export"
                        onClick={() => handleOpenConfirmModal('NFC-e')}
                      >
                        NFC-e - Nota Fiscal do Consumidor
                      </button>
                    )}
                  {!hasSAT &&
                    !onlyServicesInSale &&
                    fiscalDocumentType === 'SAT' && (
                      <button
                        className="btn btn-export"
                        onClick={() => handleOpenConfirmModal('SAT CF-e')}
                      >
                        SAT CF-e - Nota Fiscal do Consumidor
                      </button>
                    )}
                  {!hasNFSe && isOS && (
                    <button
                      className="btn btn-warning"
                      onClick={() => handleOpenConfirmModal('NFS-e')}
                    >
                      NFS-e - Nota Fiscal de Serviço
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
          {hasAnyInvoiceCreated && (
            <>
              <label
                style={{
                  marginTop: '10px',
                }}
              >
                Deseja consultar qual Nota Fiscal?
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {hasNFe && (
                  <button
                    className="btn-link"
                    onClick={() => handleOpenNFe(sale.nfe_id)}
                  >
                    Consultar NF-e {sale.NFe?.Code || sale.nfe_id}
                  </button>
                )}
                {hasNFCe && (
                  <button
                    className="btn-link"
                    onClick={() => handleOpenNFCe(sale.nfce_id)}
                  >
                    Consultar NFC-e {sale.NFCe?.Code || sale.nfce_id}
                  </button>
                )}
                {hasSAT && (
                  <button
                    className="btn-link"
                    onClick={() => handleOpenSAT(sale.sat_id)}
                  >
                    Consultar SAT CF-e {sale.SATSale?.Code}
                  </button>
                )}
                {hasNFSe && (
                  <button
                    className="btn-link"
                    onClick={() => handleOpenNFSe(sale.nfse_id)}
                  >
                    Consultar NFS-e {sale.NFSe?.Code || sale.nfse_id}
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <AlertModal
        show={isConfirmModalOpen}
        message={`Deseja emitir ${
          type === 'SAT' ? 'o' : 'a'
        } ${type} para a venda ${sale.Code}?`}
        onHide={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onSubmit={handleSubmit}
      />

      <FiscalModuleInactiveModal
        show={isModuleInactiveModalOpen}
        onHide={() => setIsModuleInactiveModalOpen(false)}
        module={inactiveModule}
      />

      {loading && <PureLoader message={`Aguarde, gerando ${type}`} />}
    </>
  )
}

export default withRouter(SaleInvoice)
