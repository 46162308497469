import React, { useEffect  } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { BankConciliationFileUpload } from '../components/BankConciliationFileUpload/BankConciliationFileUpload';
import { BankConciliationFileUploadFooterActions } from '../components/BankConciliationFileUpload/BankConciliationFileUploadFooterActions';

const BankConciliationModal = ({ show, onCancel }) => {

  const { watch} = useFormContext()

  const isSelectedBank = watch('informationFile.bank');

  useEffect(() => {

  }, [isSelectedBank]);


  return (
    <Modal show={show} animation dialogClassName="modal-70w">
        <Modal.Header>
          <Modal.Title>
            <strong>Conciliação Bancária - Importação de arquivo OFX</strong>
          </Modal.Title>
        </Modal.Header>
      <Modal.Body><BankConciliationFileUpload /></Modal.Body>
      <Modal.Footer>
        <div>
          <BankConciliationFileUploadFooterActions
            onCancel={onCancel}
            isSelectedBank={isSelectedBank}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(BankConciliationModal);
