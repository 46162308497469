import React from 'react'

import { faAddressCard, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Step } from 'react-step-progress-bar'

import FinancingProgressBar from './ProgressBar'

import FinancingStep from './Step'

const FinancingStepWizardDenied = ({ percent }) => (
  <FinancingProgressBar percent={percent}>
    <Step transition="scale">
      {({ accomplished }) => (
        <FinancingStep accomplished={accomplished} icon={faAddressCard} />
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <FinancingStep
          accomplished={accomplished}
          icon={faXmark}
          denied={true}
        />
      )}
    </Step>
  </FinancingProgressBar>
)

export default FinancingStepWizardDenied
