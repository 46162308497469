import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import constants from 'utils/constants';
import HomeSalesPerDay from './components/HomeSalesPerDay';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const SalesPerDayReport = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Vendas', 'Vendas Por Dia']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <HomeSalesPerDay />
        </Row>
      </Grid>
    </div>
  );
};
export default SalesPerDayReport;
