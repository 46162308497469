import React, { useState } from 'react'
import { reduxForm } from 'redux-form'

import CardForm from 'client/components/CardForm'
import SuivConfigForm from './SuivConfigForm'


const SuivForm = ({ handleSubmit, pristine, loading }) => {
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(true)

  return (
    <>
      <section>
        <CardForm
          title='Configuração'
          show={isConfigFormOpen}
          onClick={() => setIsConfigFormOpen(!isConfigFormOpen)}
        >
          <SuivConfigForm
            onSubmit={handleSubmit}
            pristine={pristine}
            loading={loading}
          />
        </CardForm>
        
      </section>
      
    </>
  )
}

export default reduxForm({
  form: 'suiv',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: true,
})(SuivForm)
