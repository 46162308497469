import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import ProducstWithoutMovementMain from './components/ProductsWithoutMovementMain'

import './index.css'

export default function ProductWithoutMovement ({ location }) {

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Produtos',
              'Produtos sem Movimento'
            ]}
            path={['home', null, null, null]}
          />
          <ProducstWithoutMovementMain />
        </Row>
      </Grid>
    </div>
  )
}
