import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { useAdminAuth } from 'contexts/adminAuth';
import NpsRegisterForm from './NpsRegisterForm';

import pollsRepository from '../../../../../repositories/Polls';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { getbrandingNameById } from 'v2/helpers/brandingHelpers';

function NpsRegisterMain({ pollsId }) {
  const { admin } = useAdminAuth();

  useEffect(() => {
    if (pollsId) {
      loadNpsToUpdate(pollsId);
    }
  }, []);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      description: '',
      initialDate: new Date(),
      finalDate: new Date(),
      adminId: admin.id,
      typeId: '1',
      brandingId: { value: 1, label: 'OS Digital' },
    },
  });

  const watchedInitialDate = useWatch({
    name: 'initialDate',
    control,
  });

  const watchedFinalDate = useWatch({
    name: 'finalDate',
    control,
  });

  const validations = (values) => {
    const { description, initialDate, finalDate, brandingId } = values;

    if (!brandingId) {
      toastr.warning('Atenção', 'Selecione um produto');
    }

    if (!description) {
      toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos com dados válidos e tente novamente'
      );
      return false;
    }

    const checkBlankSpace = (string) => string.trim().length === 0;

    if (
      checkBlankSpace(description) ||
      checkBlankSpace(initialDate) ||
      checkBlankSpace(finalDate)
    ) {
      toastr.warning(
        'Insira uma descrição e uma data válido para cadastrar a sua pesquisa e tente novamente.'
      );
      return false;
    }

    return true;
  };

  const history = useHistory();

  const submit = (values) => {
    if (!validations(values)) {
      return;
    }

    console.log(values.typeId);

    values.description = values.description.trim();
    values.initialDate = values.initialDate.trim();
    values.finalDate = values.finalDate.trim();
    values.typeId = parseInt(values.typeId);

    !!pollsId ? update(values) : create(values);
  };

  const create = async (values) => {
    try {
      await pollsRepository.create({
        ...values,
        brandingId: values.brandingId.value,
      });

      toastr.success(
        'Pesquisa cadastrada com sucesso.',
        'A pesquisa foi cadastrada. Para ativar, utilize o switch na consulta.'
      );
      history.push('/admin/polls');
    } catch (err) {
      console.log(err);
      return toastr.warning(
        err.response?.data?.message ??
          'Ocorreu um erro ao registrar a pesquisa. Por favor, tente novamente'
      );
    }
  };

  const loadNpsToUpdate = async (pollsId) => {
    try {
      const npsToUpdate = await pollsRepository.getById(pollsId);
      setValue('typeId', npsToUpdate.typeId);
      setValue('brandingId', {
        label: getbrandingNameById(npsToUpdate.brandingId),
        value: npsToUpdate.brandingId,
      });
      setValue('description', npsToUpdate.description);
      setValue(
        'initialDate',
        format(
          getDateOnlyFromDate(new Date(npsToUpdate.initialDate)),
          'yyyy-MM-dd'
        )
      );
      setValue(
        'finalDate',
        format(
          getDateOnlyFromDate(new Date(npsToUpdate.finalDate)),
          'yyyy-MM-dd'
        )
      );
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar a pesquisa. Por favor, tente novamente'
      );
    }
  };

  const update = async (values) => {
    try {
      await pollsRepository.update(pollsId, {
        ...values,
        brandingId: values.brandingId.value,
      });

      toastr.success(
        'Pesquisa atualizada com sucesso',
        'Para ativar, utilize o switch na consulta.'
      );
      history.push('/admin/polls');
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao atualizar a pesquisa. Por favor, tente novamente'
      );
    }
  };

  return (
    <NpsRegisterForm
      onSubmit={submit}
      pollsId={pollsId}
      register={register}
      handleSubmit={handleSubmit}
      onCancel={() => history.push('/admin/polls')}
      getValues={getValues}
      initialDate={watchedInitialDate}
      finalDate={watchedFinalDate}
      control={control}
    />
  );
}

export default NpsRegisterMain;
