import { createNumberMask, createTextMask } from "redux-form-input-masks";

const defaultMaskDefinitions = {
  A: {
    regExp: /[A-Za-z]/,
    transform: char => char.toUpperCase(),
  },
  N: {
    regExp: /[A-Za-z0-9]/,
    transform: char => char.toUpperCase(),
  },
  9: {
    regExp: /[0-9]/,
  },
};

export const cnpjMask = createTextMask({
	pattern: '99.999.999/9999-99'
})

export const cpfMask = createTextMask({
	pattern: '999.999.999-99'
})

export const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
})

export const licensePlateMask = createTextMask({
  pattern: 'AAA-9N99',
  maskDefinitions: defaultMaskDefinitions,
  placeholder: ' '
})

export const KGMask = createNumberMask({
  suffix: ' Kg',
  decimalPlaces: 2
})

export const percentMask = createNumberMask({
  suffix: ' %',
  decimalPlaces: 2
})

export const CFOPsMask = createTextMask({
  pattern: '9.999',
  guide: false
})

export const NFeAccessKeyMask = createTextMask({
  pattern: '9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999',
  guide: false
})

export const CESTMask = createTextMask({
  pattern: '99.99.999',
  guide: false
})

export const ISSMask = createNumberMask({
  suffix: ' %',
  allowNegative: false,
  decimalPlaces: 5
})

export const PISCOFINSMask = createNumberMask({
  suffix: '%',
  decimalPlaces: 2,
})

export const yearMask = createTextMask({
  pattern: '9999'
})

export const quantityMask = createNumberMask({
  decimalPlaces: 2,
});