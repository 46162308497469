import api from '../services/api'
import config from '../config'

const index = (queryParams) =>
  api.get(config.endpoint + 'profile', {
    params: queryParams,
  })

const create = async (data) => {
  return api.post(config.endpoint + 'profile', data)
}

const getAllByCompany = (companyId, queryParams) => {
  return api.get(config.endpoint + `profile/by-company/${companyId}`, {
    params: queryParams,
  })
}

const getById = (id) => {
  return api.get(config.endpoint + `profile/${id}`)
}

const update = (data) => {
  return api.put(config.endpoint + `profile/${data.id}`, data)
}

const getAdminProfile = () => api.get(config.endpoint + `profile/admin`)

export default {
  index,
  create,
  getAllByCompany,
  getById,
  update,
  getAdminProfile,
}
