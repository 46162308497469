import React from "react";
import { Grid, Row } from "react-bootstrap";
import HomeSchedules from "./Schedules";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";

const Sales = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={["Início", "Vendas", "Agenda"]}
            path={["home", null, null]}
          />
          <HomeSchedules />
        </Row>
      </Grid>
    </div>
  );
};

export default Sales;
