import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import FormCNAE from './NewCnae'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewCnae = ({ match, history }) => {

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb 
              data={['Início', 'Cadastros', 'Fiscal', 'CNAE', !!match.params.id ? 'Editar CNAE' : 'Novo CNAE']}
              path={[constants.ROUTES.HOME, null, null, constants.ROUTES.CNAES, !!match.params.id ? 'EditarCNAE' : 'NovoCNAE']} 
            />
            <FormCNAE 
              cnaeId={match.params.id} 
              onCancel={() => history.push(constants.ROUTES.CNAES)} 
              onSubmit={() => history.push(constants.ROUTES.CNAES)} 
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default NewCnae
