import api from '../services/api'

const getAll = async (params) => {
  try {
    const response = await api.get('polls/answer', { params })
    return response.data
  } catch (err) {
    throw err
  }
}

const create = async (values) => {
  try {
    await api.post('polls/answer', values)
  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  create,
}

