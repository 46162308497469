import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import Button from "../../../../components/CustomButton/CustomButton";
import { FiscalTable, ClientTable, ProductTable } from "./tables";
import CardForm from "../../../../components/CardForm";

function ErrorsModal({ show, hideModalValidations, retry }) {
  const [fiscalCard, setFiscalCard] = useState(true);
  const [clientCard, setClientCard] = useState(true);
  const [productCard, setProductCard] = useState(true);
  const [providerCard, setProviderCard] = useState(true);

  const {
    NFe: {
      values: {
        customerId,
        providerId,
        NFeItems,
        errorsFiscal,
        errorsClient,
        errorsProduct,
        errorsProvider,
      },
    },
  } = useSelector((state) => state.form);

  const totalErros =
    errorsFiscal?.length + errorsClient?.length + errorsProduct?.length;

  return (
    <Modal dialogClassName="modal-erros" show={show} animation={true}>
      <Modal.Header>
        <Modal.Title>
          <strong>Validação de Estrutura</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        id="modal-edit-customer"
        style={{ maxHeight: "calc(89vh - 120px)", overflowY: "auto" }}
      >
        <div style={{ marginLeft: "-10px" }}>
          <label>
            {totalErros > 1
              ? `Identificamos ${totalErros} erros cadastrais que impedem o envio da nota fiscal. Realize as correções dos itens abaixo: `
              : "Identificamos 1 erro cadastral que impede o envio da nota fiscal, realize a correção do item abaixo:"}
          </label>
        </div>
        {errorsFiscal?.length ? (
          <CardForm
            title="Configurações Fiscais"
            show={fiscalCard}
            onClick={() => setFiscalCard(!fiscalCard)}
          >
            <FiscalTable data={errorsFiscal || []} retry={retry} />
          </CardForm>
        ) : null}
        {errorsClient?.length ? (
          <CardForm
            title="Cliente"
            show={clientCard}
            onClick={() => setClientCard(!clientCard)}
          >
            <ClientTable
              data={errorsClient || []}
              retry={retry}
              customerId={customerId}
            />
          </CardForm>
        ) : null}
        {errorsProvider?.length ? (
          <CardForm
            title="Fornecedor"
            show={providerCard}
            onClick={() => setProviderCard(!providerCard)}
          >
            <ProductTable
              data={errorsProvider || []}
              retry={retry}
              providerId={providerId}
            />
          </CardForm>
        ) : null}
        {errorsProduct?.length ? (
          <CardForm
            title="Produtos"
            show={productCard}
            onClick={() => setProductCard(!productCard)}
          >
            <ProductTable
              data={errorsProduct || []}
              retry={retry}
              items={NFeItems}
            />
          </CardForm>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          name="Voltar"
          style={{ float: "left" }}
          bsStyle="danger"
          fill
          onClick={hideModalValidations}
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorsModal;
