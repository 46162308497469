import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusConfirmationCampaignModal = ({
  isOpen,
  handleClose,
  handleSaleTrigger,
  campaign,
}) => {
  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Deseja pontuar utilizando a campanha {campaign.name} de{' '}
          <strong>{currency(campaign.value)}</strong> para a próxima compra?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-75px" onClick={handleClose}>
            Não
          </button>
          <button
            className="button button-green w-75px"
            onClick={handleSaleTrigger}
          >
            Sim
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
