import moment from 'moment';
import { NOT_DIGITAL_PAYMENT_FORMS } from 'v2/utils/constants';

const INITIAL_STATE = {
  companyId: null,
  companyConfig: {},
  userId: null,
  saleId: null,
  quickQuoteId: null,
  saleCode: null,
  saleTypeId: null,
  saleStatusId: null,
  defaultCashierBankId: null,
  userIdWhoClosedSaleId: null,
  employeeId: null,
  showRecloseModal: false,

  BoletoFlexTransaction: {},
  keepOnScreenAfterSave: false,
  readyForSimulateFinancing: false,

  clients: [],
  forecastReturn: '',
  loading: false,
  selectedClient: {},
  isDisabledButtonsSale: false,
  selectedVehicle: {},
  vehicleLabel: '',
  clientVehicles: [],
  observations: '',
  defects: '',
  malfunctions: '',
  showOnPanel: true,
  currentKilometers: 0,
  customerRequest: '',
  prismaBoxId: null,
  salesSubtypeId: 1,
  salesSubtypeDescription: '',
  generateTitleAfterCloseSale: true,
  sellerId: null,
  tradeRepresentativeId: null,
  attendanceTypeId: 1,
  salesEmployeesIds: [],
  installmentFees: 0,

  isAppointmentSave: false,
  isClosingAgain: false,

  openClientDataModal: false,
  openItemsModal: false,
  eraseItemModal: false,
  openPartsConsultationModal: false,
  openStonePaymentProgressModal: false,
  openSaveModal: false,
  openSchedulingModal: false,
  openShareModal: false,
  openCashierBankModal: false,
  cancelModal: false,
  loadingSpin: false,

  items: [],
  itemToRemove: {},
  itemToEdit: {},

  subTotal: 0,
  total: 0,
  totalReceived: 0,
  returnedValue: 0,
  change: 0,
  opened: 0,
  discountType: '%',
  generalDiscountCash: 0,
  generalDiscountPercentage: 0,
  stonePreTransactionId: null,
  freightValue: 0,

  productsData: {
    total: 0,
    totalWithoutDiscount: 0,
    discount: {
      value: 0,
      percent: 0,
    },
  },

  servicesData: {
    total: 0,
    totalWithoutDiscount: 0,
    discount: {
      value: 0,
      percent: 0,
    },
  },

  installments: [],
  formOfPayments: [],
  condOfPayments: [],
  cashierBanks: [],
  selectedCondOfPayment: {},

  link: '',

  lastPayment: null,
  lastItems: [],

  photosToDelete: [],
  isPhotosModalOpen: false,
  isAddPhotosModalOpen: false,
  photos: [],
  videos: [],
  photoToImport: {},
  videoToImport: {},
  photosUploaded: [],
  videosUploaded: [],
  fileDescription: '',
  emitBankSlipNow: false,

  redeemedBonus: 0,
  usedCRMBonus: false,
  bonusId: '',
  orderId: '',
  continueWithoutBonus: false,
  neededToCreateCustomerCredit: false,
  triggerOpenAppointmentsModal: false,
  triggerOpenChecklistModal: false,
  shouldShowAppointmentModal: false,
  shouldShowChecklistModal: false,
  isNewSale: false,
};

let clearVehicle = {
  Kilometers: '',
  License_Plate: '',
  Model: '',
  index: '',
  id: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        companyId: action.payload.companyId,
        companyConfig: action.payload.companyConfig,
        userId: action.payload.userId,
        defaultCashierBankId: action.payload.defaultCashierBankId,
      };

    // case 'COMPONENT_DID_MOUNT':
    //   state = INITIAL_STATE
    //   return { ...state, items: [], selectedClient: {} }

    case 'COMPONENT_UNMOUNT':
      state = INITIAL_STATE;
      return { ...state, items: [], selectedClient: {} };

    case 'HANDLE_CHANGE':
      return { ...state, [action.payloadType]: action.payload };

    case 'LOAD_ALL_CLIENTS_STARTED':
      return { ...state, loading: true };

    case 'LOAD_ALL_CLIENTS_FAILURE':
      return { ...state, loading: false };

    case 'LOAD_ALL_CLIENTS_SUCCESS':
      return { ...state, loading: false, clients: action.payload };

    case 'LOAD_COMPANY_CONFIGURATIONS_SUCCESS':
      return { ...state, companyConfig: action.payload };

    case 'SET_CURRENT_CLIENT':
      let vehicles = [];
      if (action.payload.Vehicle.length) {
        vehicles = action.payload.Vehicle.map((c, i) => {
          let obj = c;
          obj.index = i;

          return obj;
        });
      }

      return {
        ...state,
        selectedClient: action.payload,
        vehicleLabel: '',
        selectedVehicle: clearVehicle,
        currentKilometers: 0,
        clientVehicles: vehicles,
        openClientDataModal: false,
      };
    case 'RESET_CLIENT_DATA':
      return {
        ...state,
        selectedClient: {},
        selectedVehicle: clearVehicle,
        clientVehicles: [],
        currentKilometers: 0,
        previousKilometers: null,
        vehicleLabel: '',
        isDisabledButtonsSale: true,
      };

    case 'SET_CLIENT_VEHICLE':
      return {
        ...state,
        selectedVehicle: action.payload,
        openClientDataModal: false,
      };

    case 'SET_VEHICLE_LABEL':
      return {
        ...state,
        vehicleLabel: action.payload,
      };

    case 'CLEAR_SELECTED_VEHICLE':
      return {
        ...state,
        selectedVehicle: clearVehicle,
        previousKilometers: null,
        currentKilometers: 0,
        vehicleLabel: '',
      };

    case 'HANDLE_ITEMS':
      return {
        ...state,
        items: action.payload,
        openItemsModal: false,
        itemToEdit: {},
      };

    case 'SET_ITEM_TO_REMOVE':
      return { ...state, itemToRemove: action.payload, eraseItemModal: true };

    case 'CONFIRM_REMOVE_ITEM':
      return {
        ...state,
        items: action.payload,
        generalDiscountCash: 0,
        generalDiscountPercentage: 0,
        eraseItemModal: false,
      };

    case 'START_EDIT_ITEM':
      return { ...state, itemToEdit: action.payload };

    case 'FINISH_EDIT_ITEM':
      return {
        ...state,
        itemToEdit: {},
        items: action.payload,
        generalDiscountCash: 0,
        generalDiscountPercentage: 0,
      };

    case 'FINISH_CALCULATE_VALUES':
      const total =
        state.discountType === '%'
          ? Number(
              (
                action.payload.subTotal *
                  ((100 - state.generalDiscountPercentage) / 100) +
                state.freightValue -
                state.redeemedBonus
              ).toFixed(2)
            )
          : Number(
              (
                action.payload.subTotal -
                state.generalDiscountCash +
                state.freightValue -
                state.redeemedBonus
              ).toFixed(2)
            );

      const totalWithInstallmentFees =
        +total + Number((total * (state.installmentFees / 100)).toFixed(2));

      return {
        ...state,
        productsData: action.payload.productsData,
        servicesData: action.payload.servicesData,
        subTotal: action.payload.subTotal,
        total: totalWithInstallmentFees,
        opened: totalWithInstallmentFees,
      };

    case 'CHANGE_DISCOUNT_TYPE':
      return {
        ...state,
        generalDiscountCash: 0,
        discountType: action.payload,
        generalDiscountPercentage: 0,
        total: state.subTotal,
      };

    case 'HANDLE_GENERAL_DISCOUNTS':
      if (action.payloadType === 'generalDiscountCash') {
        state.generalDiscountPercentage = Number(
          (action.payload / state.subTotal) * 100
        ).toFixed(2);
      } else {
        state.generalDiscountCash = Number(
          (
            state.subTotal -
            state.subTotal * ((100 - action.payload) / 100)
          ).toFixed(2)
        );
      }

      return { ...state, [action.payloadType]: action.payload };

    case 'LOAD_COND_OF_PAYMENTS_SUCCESS':
      return { ...state, condOfPayments: action.payload };

    case 'LOAD_FORM_OF_PAYMENTS_SUCCESS':
      return { ...state, formOfPayments: action.payload };

    case 'LOAD_CASHIER_BANKS_SUCCESS':
      return { ...state, cashierBanks: action.payload };

    case 'HANDLE_COND_OF_PAYMENT':
      return { ...state, selectedCondOfPayment: action.payload };

    case 'HANDLE_CONFIRM_CHANGES_IN_CASHIER_BANK_MODAL':
      return {
        ...state,
        installments: action.payload.installments,
        openCashierBankModal: false,
      };

    case 'HANDLE_INSTALLMENTS':
      let date = moment().format('YYYY-MM-DD');
      let installments = action.payload;

      /// DIVIDI O TOTAL NAS PARCELAS
      let installmentValue = Number(
        (state.total / installments.length).toFixed(2)
      );

      /// CRIA O OBJ NAS PARCELAS
      const convertDays = parseInt(
        state.selectedCondOfPayment.daysBetweenInstallment
      );
      // CASO OS DIAS ENTRE A PROXIMA PARCELA SEJA 30 ADICIONA 1 MêS
      if (convertDays == 30) {
        installments.forEach((c, i) => {
          if (state.selectedCondOfPayment.hasEntry) {
            if (i === 0) {
              c.date = moment().format('YYYY-MM-DD');
            } else {
              c.date = moment(date).add(i, 'months').format('YYYY-MM-DD');
            }
            c.value = installmentValue;
            c.formOfPayment = { id: '' };
          } else {
            c.date = moment(date)
              .add(i + 1, 'months')
              .format('YYYY-MM-DD');
            c.value = installmentValue;
            c.formOfPayment = { id: '' };
          }
        });
      } else {
        // CASO A PROXIMA PARCELA SEJA ENTRE DIAS
        installments.forEach((c, i) => {
          const hasEntry = state.selectedCondOfPayment.hasEntry;
          const daysBetweenInstallment = (i + (hasEntry ? 0 : 1)) * convertDays;
          c.date = moment(date)
            .add(daysBetweenInstallment, 'd')
            .format('YYYY-MM-DD');
          c.value = installmentValue;
          c.formOfPayment = { id: '' };
        });
      }

      /// INSERE A DATA ATUAL CASO SEJA À VISTA
      if (state.selectedCondOfPayment.Description === 'À vista') {
        installments[0].date = moment().format('YYYY-MM-DD');
      }

      if (installments.length > 0) {
        /// SE TIVER MAIS DE UMA PARCELA, RECALCULA PRA VER SE POSSUI RESTO
        let rest = state.total - installmentValue * installments.length;

        installments[installments.length - 1].value = Number(
          (installments[installments.length - 1].value + rest).toFixed(2)
        );
      }

      let totalReceivedCaclInHandleInstallments = 0;

      installments.map((installment) => {
        totalReceivedCaclInHandleInstallments += Number(
          installment.value.toFixed(2)
        );
      });

      state.totalReceived = Number(
        totalReceivedCaclInHandleInstallments.toFixed(2)
      );
      if (totalReceivedCaclInHandleInstallments > state.total) {
        state.change = totalReceivedCaclInHandleInstallments - state.total;
        state.opened = 0;
      } else if (totalReceivedCaclInHandleInstallments < state.total) {
        state.change = 0;
        state.opened = state.total - totalReceivedCaclInHandleInstallments;
      } else {
        state.change = 0;
        state.opened = 0;
      }

      return { ...state, installments: installments };

    case 'HANDLE_INSTALLMENT_CHANGE_AMOUNT_RECEIVED':
      const newInstallments = state.installments.map((installment, index) => {
        if (index === action.payload.index) {
          return {
            ...installment,
            amountReceived: action.payload.value,
          };
        }
        return installment;
      });

      return { ...state, installments: newInstallments };

    case 'HANDLE_FIRST_INSTALLMENT_VALUE':
      let value = action.payload.value;
      let index = action.payload.index;

      state.installments[index].value = value;

      let valuesPrevious = 0;
      for (let i = 0; i < state.installments.length; i++) {
        if (i < index) {
          valuesPrevious += state.installments[i].value;
        }
      }

      let calculoRestante = state.total - value - valuesPrevious;
      let restValues =
        calculoRestante / (state.installments.length - index - 1);
      let convertRestValues = parseFloat(restValues).toFixed(2);
      let provaReal =
        convertRestValues * (state.installments.length - index - 1);
      let resto = calculoRestante - provaReal;
      let totalReceivedCacl = 0;

      state.installments.forEach((c, i) => {
        if (i === index) c.value = value;
        let dividido = parseFloat(convertRestValues);
        let restante = parseFloat(resto);
        if (index < i) {
          c.value = dividido;
          if (state.installments.length - 1 === i) {
            let valorQuebrado = (dividido += restante);
            c.value = valorQuebrado;
          }
        }
        totalReceivedCacl += c.value;
      });

      state.totalReceived = Number(totalReceivedCacl.toFixed(2));
      if (totalReceivedCacl > state.total) {
        state.change = totalReceivedCacl - state.total;
        state.opened = 0;
      } else if (totalReceivedCacl < state.total) {
        state.change = 0;
        state.opened = state.total - totalReceivedCacl;
      } else {
        state.change = 0;
        state.opened = 0;
      }

      return { ...state };

    case 'HANDLE_FIRST_INSTALLMENT_DATE':
      state.installments[action.payload.index].date = moment(
        action.payload.value
      ).format('YYYY-MM-DD');

      return { ...state };

    case 'HANDLE_INSTALLMENT_DATES':
      /// BUSCA TODAS AS OUTRAS PARCELAS E AUMENTA UM MÊS
      state.installments
        .filter((c, i) => i > [action.payload.index])
        .forEach((c, i) => {
          // PEGANDO A QUANTIDADE DE DIAS ENTRE AS PARCELAS
          const convertDays = parseInt(
            state.selectedCondOfPayment.daysBetweenInstallment
          );
          let tdate = '';
          if (convertDays == 30) {
            // SE FOR 30 DIAS ADICIONA 1 MÊS A DATA SELECIONADA
            tdate = moment(action.payload.value)
              .add(i + 1, 'months')
              .format('YYYY-MM-DD');
          } else {
            // SE FOR DIAS ESPECIFICOS ADICIONO DOS DIAS
            const daysBetweenInstallment = (i + 1) * convertDays;
            tdate = moment(action.payload.value)
              .add(daysBetweenInstallment, 'd')
              .format('YYYY-MM-DD');
          }
          c.date = tdate;
        });

      return { ...state };

    case 'HANDLE_INSTALLMENT_FORM_OF_PAYMENT':
      let formOfPayment = state.formOfPayments.filter(
        (c) => c.id.toString() === action.payload.value.toString()
      )[0];

      if (formOfPayment) {
        const isNotElectronicPaymentForm = NOT_DIGITAL_PAYMENT_FORMS.includes(
          formOfPayment.TypeOfPayment
        );

        state.installments[action.payload.index].formOfPayment = formOfPayment;
        state.installments[action.payload.index].amountReceived =
          formOfPayment.automaticDownload === 1;

        const hasAuthorizationCode =
          !!state.installments[action.payload.index].authorizationCode;

        if (hasAuthorizationCode && isNotElectronicPaymentForm) {
          state.installments[action.payload.index].authorizationCode = '';
        }
      } else {
        state.installments[action.payload.index].formOfPayment = { id: '' };
        state.installments[action.payload.index].amountReceived = false;
      }

      return { ...state };

    case 'HANDLE_AUTHORIZATION_CODE':
      state.installments[action.payload.index].authorizationCode =
        action.payload.value.replace(/\s/g, '');

      return { ...state };

    case 'REPLICATE_FIRST_FORM_OF_PAYMENT':
      state.installments.forEach((c) => {
        c.formOfPayment = state.installments[0].formOfPayment;
        c.amountReceived =
          state.installments[0].formOfPayment.automaticDownload === 1;
      });

      return {
        ...state,
      };

    case 'VALIDATIONS_FAILED':
      return { ...state, openSaveModal: false };

    case 'STONE_PAYMENT_STARTED':
      return {
        ...state,
        openStonePaymentProgressModal: true,
        saleId: action.payload,
      };

    case 'STONE_PAYMENT_SUCCESS':
      return { ...state };

    case 'VALIDATIONS_SUCCESS':
      return { ...state, openSaveModal: true };

    case 'HANDLE_SALE_TYPE_AND_STATUS':
      return { ...state, ...action.payload, openSaveModal: false };

    case 'CREATE_SALE_STARTED':
      return { ...state, loadingSpin: true };

    case 'CREATE_SALE_SUCCESS':
      return {
        ...state,
        allowedToFinishBonus: true,
        saleId: action.payload,
        openSaveModal: false,
        loadingSpin: false,
        readyForSimulateFinancing: true,
        isNewSale: true,
        openSchedulingModal: state.keepOnScreenAfterSave ? false : true,
        triggerOpenAppointmentsModal:
          state.isAppointmentSave && state.shouldShowAppointmentModal
            ? true
            : false,
        triggerOpenChecklistModal:
          state.isAppointmentSave && state.shouldShowChecklistModal
            ? true
            : false,
      };

    case 'CREATE_SALE_FAILURE':
      return { ...state, loadingSpin: false, saleStatusId: null };

    case 'LOAD_SALE_STARTED':
      return { ...state };

    case 'LOAD_SALE_SUCCESS':
      return { ...state, ...action.payload };

    case 'CANCEL_SALE_STARTED':
      return { ...state, cancelModal: false, loadingSpin: true };

    case 'CANCEL_SALE_SUCCESS':
      return { ...state, loadingSpin: false };

    case 'CANCEL_SALE_FAILURE':
      return {
        ...state,
        loadingSpin: false,
        readyForSimulateFinancing: false,
        saleStatusId: null,
      };

    case 'UPDATE_SALE_STARTED':
      return { ...state, loadingSpin: true };

    case 'UPDATE_SALE_SUCCESS':
      return {
        ...state,
        loadingSpin: false,
        readyForSimulateFinancing: true,
        keepOnScreenAfterSave: false,
        openSchedulingModal: state.keepOnScreenAfterSave ? false : true,
        triggerOpenAppointmentsModal:
          state.isAppointmentSave && state.shouldShowAppointmentModal
            ? true
            : false,
        triggerOpenChecklistModal:
          state.isAppointmentSave && state.shouldShowChecklistModal
            ? true
            : false,
      };

    case 'UPDATE_SALE_FAILURE':
      return {
        ...state,
        loadingSpin: false,
        readyForSimulateFinancing: false,
        keepOnScreenAfterSave: false,
        saleStatusId: null,
      };

    case 'EMIT_BANK_SLIP':
      return {
        ...state,
        emitBankSlipNow: action.userResponse,
      };

    default:
      return state;
  }
};
