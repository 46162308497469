import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import {
  cpfOrCnpjMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { percentage } from '../../../client/components/ToNormalize/ToNormalize';

export const SalesByTypeDocument = ({
  initialDate,
  finalDate,
  totalsByType,
  salesList,
  showItems,
  saleStatus,
  saleType,
}) => {
  return (
    <Document title={`impresso-recibo-${format(new Date(), 'dd/MM/yyyy')}`}>
      <Page
        size="A4"
        orientation={showItems === false ? 'landscape' : 'portrait'}
        style={styles.page}
      >
        <View style={{ marginBottom: 10 }}>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                width: '40%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.title}>Vendas por Tipo/Status</Text>
            </View>
            <View style={{ width: '60%', flexDirection: 'row' }}>
              <View
                style={{
                  flexDirection: 'column',
                  width: '30%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={{ fontWeight: 'bold', fontSize: '10px' }}>
                      Tipo:{' '}
                    </Text>
                  </View>
                  <View>
                    <Text>
                      {saleType === '1'
                        ? 'Orçamento'
                        : saleType === '2'
                        ? 'Venda'
                        : saleType === '3'
                        ? 'Ordem de Serviço'
                        : saleType === '4'
                        ? 'PDV'
                        : 'Todos'}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={{ fontWeight: 'bold', fontSize: '10px' }}>
                      Status:{' '}
                    </Text>
                  </View>
                  <View>
                    <Text>
                      {saleStatus === '5'
                        ? 'Finalizado(a)'
                        : saleStatus === '3'
                        ? 'Em Aberto'
                        : saleStatus === '2'
                        ? 'Cancelada'
                        : 'Todos'}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '70%',
                  marginBottom: '5px',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={{ fontWeight: 'bold', fontSize: '10px' }}>
                      Data Início:{' '}
                    </Text>
                  </View>
                  <View style={{ marginRight: '5px' }}>
                    <Text>
                      {initialDate
                        ? format(
                            new Date(getDateOnlyFromDate(initialDate)),
                            'dd/MM/yyyy'
                          )
                        : ''}
                    </Text>
                  </View>

                  <View>
                    <Text style={{ fontWeight: 'bold', fontSize: '10px' }}>
                      Data Fim:{' '}
                    </Text>
                  </View>
                  <View>
                    <Text>
                      {finalDate
                        ? format(
                            new Date(getDateOnlyFromDate(finalDate)),
                            'dd/MM/yyyy'
                          )
                        : ''}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={{ fontWeight: 'bold' }}>
                      Exibir itens nos detalhes:{' '}
                    </Text>
                  </View>
                  <View style={{ marginRight: '5px' }}>
                    <Text>{showItems === true ? 'Sim' : 'Não'}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <TotalsByType totalsByType={totalsByType} />

          <SalesList salesList={salesList} showItems={showItems} />
        </View>
      </Page>
    </Document>
  );
};

//SALES LIST

const SalesList = ({ salesList, showItems }) => {
  return (
    <View>
      <Text style={[styles.centralizedTitle]}>
        Listagem de Vendas/Orçamento/OS
      </Text>
      {showItems === false ? (
        <View>
          <View style={styles.line}></View>
          <SalesListWithoutItems salesList={salesList} />
        </View>
      ) : (
        <View>
          {salesList?.filter((item) => item.SalesStatus.id === 3).length > 0 ? (
            <View>
              <View style={styles.line}></View>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Em Aberto(a)
              </Text>
              <View style={styles.line}></View>
              {salesList
                ?.filter((item) => item.SalesStatus.id === 3)
                .map((sale) => {
                  return (
                    <View>
                      <SalesListItemsDetailed
                        item={sale}
                        showPaymentDetails={false}
                      />
                    </View>
                  );
                })}
              {
                <Text
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Total:{' '}
                  {currency(
                    salesList?.reduce((acc, item) => {
                      if (item.SalesStatus.id === 3) {
                        return acc + item.Final_Value;
                      } else {
                        return acc;
                      }
                    }, 0)
                  )}
                </Text>
              }
            </View>
          ) : (
            <View></View>
          )}

          {salesList?.filter((item) => item.SalesStatus.id === 5).length > 0 ? (
            <>
              <View style={styles.line}></View>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Finalizadas
              </Text>
              <View style={styles.line}></View>
              {salesList
                ?.filter((item) => item.SalesStatus.id === 5)
                .map((sale) => {
                  return (
                    <View>
                      <SalesListItemsDetailed
                        item={sale}
                        showPaymentDetails={false}
                      />
                    </View>
                  );
                })}
              {
                <Text
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Total:{' '}
                  {currency(
                    salesList?.reduce((acc, item) => {
                      if (item.SalesStatus.id === 5) {
                        return acc + item.Final_Value;
                      } else {
                        return acc;
                      }
                    }, 0)
                  )}
                </Text>
              }
            </>
          ) : (
            <View></View>
          )}

          {salesList?.length &&
          salesList?.filter((item) => item.SalesStatus.id === 2).length > 0 ? (
            <>
              <View style={styles.line}></View>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Canceladas
              </Text>
              <View style={styles.line}></View>
              {salesList
                ?.filter((item) => item.SalesStatus.id === 2)
                .map((sale) => {
                  return (
                    <View>
                      <SalesListItemsDetailed
                        item={sale}
                        showPaymentDetails={false}
                      />
                    </View>
                  );
                })}
              {
                <Text
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Total:{' '}
                  {currency(
                    salesList?.reduce((acc, item) => {
                      if (item.SalesStatus.id === 2) {
                        return acc + item.Final_Value;
                      } else {
                        return acc;
                      }
                    }, 0)
                  )}
                </Text>
              }
            </>
          ) : (
            <View></View>
          )}
        </View>
      )}
    </View>
  );
};

const SalesListWithoutItems = ({ salesList }) => {
  const totalCanceled = salesList?.['Cancelado(a)']?.reduce(
    (acc, curr) => acc + curr.value,
    0
  );

  const totalFinished = salesList?.['Finalizado(a)']?.reduce(
    (acc, curr) => acc + curr.value,
    0
  );

  const totalOpen = salesList?.['Em Aberto(a)']?.reduce(
    (acc, curr) => acc + curr.value,
    0
  );
  return (
    <>
      {salesList?.['Cancelado(a)']?.length > 0 && (
        <SalesListItems
          title="Canceladas"
          salesList={salesList?.['Cancelado(a)']}
          total={totalCanceled}
        />
      )}

      {salesList?.['Finalizado(a)']?.length > 0 && (
        <SalesListItems
          title="Finalizadas"
          salesList={salesList?.['Finalizado(a)']}
          total={totalFinished}
        />
      )}

      {salesList?.['Em Aberto(a)']?.length > 0 && (
        <SalesListItems
          title="Em Aberto"
          salesList={salesList?.['Em Aberto(a)']}
          total={totalOpen}
        />
      )}
    </>
  );
};

const SalesListItems = ({ title, salesList, total }) => {
  return (
    <View>
      <Text style={styles.centralizedSubtitle}>{title}</Text>
      <View style={styles.line}></View>

      <SalesListItemsHeader />
      {salesList.map((item, i) => (
        <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
          <View
            style={{
              width: '10%',
              textAlign: 'left',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {item?.createdAt
                ? format(new Date(item.createdAt), 'dd/MM/yyyy')
                : ''}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {item?.closedAt
                ? format(new Date(item.closedAt), 'dd/MM/yyyy')
                : ''}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {item?.type
                ? item.type === 'Ordem de Serviço'
                  ? 'OS'
                  : item.type
                : ''}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>{item?.code}</Text>
          </View>
          <View
            style={{
              width: '15%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>{item?.customer}</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {cpfOrCnpjMask(item?.cpfCnpjCustomer)}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>{item?.plate ? item?.plate : ''}</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {item?.value ? currency(item.value) : ''}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>
              {item?.employee ? item?.employee : 'Não informado'}
            </Text>
          </View>
        </View>
      ))}

      <View style={styles.line}></View>
      <Text
        style={{
          marginTop: '3px',
          marginBottom: '3px',
          marginLeft: 'auto',
          fontWeight: 'bold',
          fontSize: '9px',
        }}
      >
        Total: {total ? currency(total) : ''}
      </Text>
      <View style={styles.line}></View>
    </View>
  );
};

const SalesListItemsHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.headerTitle}>Data Criação</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Data Fech.</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Tipo</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Número</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Cliente</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>CPF/CNPJ</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Placa</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Valor</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Vendedor</Text>
        </View>
      </View>
      <View style={styles.line}></View>
    </>
  );
};

const SalesItemsDetailedList = ({ item }) => {
  return (
    <View wrap={false}>
      <View
        style={{
          flexDirection: 'row',
          height: 12,
          width: '100%',
        }}
      >
        <View
          style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            {item?.Products !== null
              ? item?.Products?.Code
              : item?.Services?.Code}
          </Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Text style={{ ...styles.text }}>{item?.Description}</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
            marginLeft: '4px',
          }}
        >
          <Text style={styles.text}>{item.Quantity}</Text>
        </View>
        <View
          style={{
            width: '12%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>{currency(item.Unit_Value)}</Text>
        </View>
        <View
          style={{ width: '8%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            {item?.Unit_Value === 0 && item.Value_Cost === 0
              ? percentage(0)
              : item.Unit_Value > 0 && item.Value_Cost > 0
              ? percentage((item.Unit_Value / item.Value_Cost - 1) * 100)
              : percentage(100)}
          </Text>
        </View>
        <View
          style={{
            width: '12.5%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>
            {percentage(item.Unit_Discount_Value)}
          </Text>
        </View>
        <View
          style={{
            width: '12.5%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>{currency(item?.Amount)}</Text>
        </View>
      </View>
    </View>
  );
};

const SalesListItemsDetailed = ({ item, showPaymentDetails }) => {
  return (
    <View wrap={false}>
      <View wrap={false}>
        <View
          style={{
            flexDirection: 'row',
            paddingTop: '3px',
            paddingBottom: '3px',
            width: '100%',
          }}
        >
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Data Criação: </Text>
              </Text>
              {format(new Date(item.Date), 'dd/MM/yyyy')}
            </Text>
          </View>
          <View style={{ width: '25%', marginLeft: '5px' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Data do Fechamento: </Text>
              </Text>
              {format(new Date(item.updatedAt), 'dd/MM/yyyy')}
            </Text>
          </View>
          <View style={{ width: '15%', marginLeft: '17px' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Tipo: </Text>
              </Text>
              {item?.SalesType?.Description === 'Ordem de Serviço'
                ? 'OS'
                : item?.SalesType?.Description}{' '}
            </Text>
          </View>
          <View style={{ width: '12%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Número: </Text>
              </Text>
              {item.code}
            </Text>
          </View>
          <View style={{ width: '15%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Valor: </Text>
              </Text>
              {currency(item.Final_Value)}
            </Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Frete: </Text>
              </Text>
              {currency(item.freightValue)}
            </Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '30%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>Cliente: </Text>
              </Text>
              {item.Customer.Company_Name}
            </Text>
          </View>
          <View style={{ width: '30%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>
                <Text style={{ fontWeight: 'bold' }}>CPF/CNPJ: </Text>
              </Text>
              {cpfOrCnpjMask(item.Customer.Cpf_Cnpj)}
            </Text>
          </View>
          <View style={{ width: '40%' }}>
            <Text style={styles.text}>
              <Text style={{ fontWeight: 'bold' }}>Veículo: </Text>
              {item.Vehicle
                ? `${item.Vehicle.License_Plate} - ${item.Vehicle.Model}`
                : '--'}
            </Text>
          </View>
        </View>
      </View>

      <SalesItemsTableHeader />
      {item.SalesItems.map((saleItem) => {
        return <SalesItemsDetailedList item={saleItem} key={saleItem.id} />;
      })}

      {item?.BillsToReceive.length > 0 ? (
        <View
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <View style={[styles.line, { width: '81%' }]} />
          <View style={{ width: '80%', marginTop: '2px' }}>
            <View style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
              <View
                style={{ display: 'flex', flexDirection: 'row', width: '35%' }}
              >
                <Text style={[styles.strong, { fontSize: '8px' }]}>
                  <Text style={{ fontWeight: 'bold' }}>
                    Condição de Pagamento:{' '}
                  </Text>
                </Text>
                <Text style={{ fontSize: '8px' }}>
                  {item.Payments?.CondPayment?.Description}
                </Text>
              </View>
              <View style={{ width: '16%', alignItems: 'center' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  <Text style={{ fontWeight: 'bold' }}>Vencimento</Text>
                </Text>
              </View>
              <View style={{ width: '23%', alignItems: 'center' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  <Text style={{ fontWeight: 'bold' }}>Forma de Pagamento</Text>
                </Text>
              </View>
              <View
                style={{
                  width: '18%',
                  alignItems: 'flex-start',
                  paddingLeft: '10px',
                }}
              >
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  <Text style={{ fontWeight: 'bold' }}>Valor</Text>
                </Text>
              </View>
              <View style={{ width: '3%', alignItems: 'right' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  <Text style={{ fontWeight: 'bold' }}>Pago?</Text>
                </Text>
              </View>
            </View>

            {item?.BillsToReceive?.map((bill) => {
              return (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  key={bill.id}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '35%',
                    }}
                  />
                  <View style={{ width: '16%', alignItems: 'center' }}>
                    <Text style={{ fontSize: '8px' }}>
                      {format(
                        getDateOnlyFromDate(new Date(bill?.dueDate)),
                        'dd/MM/yyyy'
                      )}
                    </Text>
                  </View>
                  <View style={{ width: '23%', alignItems: 'center' }}>
                    <Text style={[{ fontSize: '8px', textAlign: 'center' }]}>
                      {bill?.BillsToReceiveParcels
                        ? bill?.BillsToReceiveParcels[0]?.FormOfPayment
                            ?.TypeOfPayment
                        : '-'}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '18%',
                      alignItems: 'flex-start',
                      paddingLeft: '10px',
                    }}
                  >
                    <Text style={[{ fontSize: '8px' }]}>
                      {bill?.liquidValue !== null
                        ? currency(bill.liquidValue.toFixed(2))
                        : '-'}
                    </Text>
                  </View>
                  <View style={{ width: '3%', alignItems: 'right' }}>
                    <Text style={{ fontSize: '8px', textAlign: 'right' }}>
                      {bill?.BillStatus?.id === 2 ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      ) : (
        <>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <View style={[styles.line, { width: '81%' }]} />
          </View>
          <Text
            style={{
              marginLeft: '18%',
              fontWeight: 'bold',
              fontSize: '9px',
              marginTop: '3px',
              fontSize: '8px',
            }}
          >
            Não há pagamentos informados
          </Text>
        </>
      )}

      <View style={{ marginBottom: '10px' }} />
      <View style={styles.line}></View>
    </View>
  );
};

const SalesItemsTableHeader = () => (
  <View style={{ paddingTop: 2 }}>
    <View style={[styles.line, { marginTop: 1 }]} />
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Código</Text>
      </View>
      <View
        style={{
          width: '20%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Descrição</Text>
      </View>
      <View
        style={{
          width: '10.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Quantidade</Text>
      </View>
      <View
        style={{ width: '12%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Vlr Unit.</Text>
      </View>
      <View
        style={{ width: '8%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Margem</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Desc. Unit</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Total Venda</Text>
      </View>
    </View>

    <View style={[styles.line, { marginTop: 1 }]} />
  </View>
);

const TotalsByType = ({ totalsByType }) => {
  return (
    <View>
      <View style={styles.line}></View>
      <Text style={styles.centralizedTitle}>Totais por Tipo/Status</Text>

      <View style={styles.line}></View>
      <TotalsHeader />
      {totalsByType?.map((item, i) => (
        <TotalsItems key={i} item={item} />
      ))}
      <View style={styles.line}></View>
    </View>
  );
};

const TotalsHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.subtitle}>Tipo</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.subtitle}>Status</Text>
        </View>
        <View
          style={{
            width: '17%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.subtitle}>Quantidade</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'left',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.subtitle}>Total</Text>
        </View>
      </View>
      <View style={styles.line} />
    </>
  );
};

const TotalsItems = ({ item }) => {
  return (
    <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{item?.type}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{item?.status}</Text>
      </View>
      <View
        style={{ width: '17%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{item?.quantity}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>
          {item?.total ? currency(item?.total) : ''}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  centralizedTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  centralizedSubtitle: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  headerTitle: {
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 8,
  },
});
