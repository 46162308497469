import React from 'react';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { CrmBonusHome } from './CrmBonusHome';

export const CRMBonusIndex = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Integrações', 'CRM Bônus']}
        path={['home', null, null]}
      />
      <CrmBonusHome />
    </>
  );
};
