/* React & Libs */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm, change, reset } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import Select from 'react-select'

/* Global Components */
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from 'components/AlertModal'
import SearchIcon from 'components/SelectIcons/SearchIcon'

import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize'

/* Matera Components */
import ChooseMateraModeModal from './ChooseModeModal'
import MainData from './MainData'
import './styles.css'

/* Repositories */
import companyRepository from 'repositories/Companies'
import materaCompanyRepository from 'repositories/MateraCompany'
import addressRepository from 'repositories/Addresses'
import employeeRepository from 'repositories/Employees'

const MateraAccountForm = ({
  onSubmit,
  handleSubmit,
  dirty,
  saveLoading,
  onCancel,
  pristine,
  materaId,
  setShowChooseModeModal,
  showChooseModeModal,
}) => {
  const [taxes, setTaxes] = useState([])
  const [companyUsersOptions, setCompanyUsersOptions] = useState([])

  const [citiesOptions, setCitiesOptions] = useState([])
  const [statesOptions, setStatesOptions] = useState([])

  const [companiesOptions, setCompaniesOptions] = useState([])

  const defaultCompanySelectPlaceholder =
    'Pesquisar por CNPJ, Razão Social ou Nome'
  const [companySelectPlaceholder, setCompanySelectPlaceholder] = useState(
    defaultCompanySelectPlaceholder
  )

  const [company, setCompany] = useState(null)
  const [accountStatus, setAccountStatus] = useState(null)
  const [isTaxPixChanged, setIsTaxPixChanged] = useState(false)

  const [companies, setCompanies] = useState([])

  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false)
  const [isConfirmTaxChangeModalOpen, setIsConfirmTaxChangeModalOpen] =
    useState(false)

  const [hasError, setHasError] = useState(false)
  const [validUser, setValidUser] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    loadCompanies()
    loadTaxes()
    loadCities()
    loadStates()
  }, [])

  useEffect(() => {
    if (!company) return
    setCompanySelectPlaceholder(
      `${company?.Company_Name} - ${cpfOrCnpjMask(company?.Cpf_Cnpj)}`
    )
    fillFormWithCompanyData()
  }, [company])

  useEffect(() => {
    if (materaId && companies) loadMateraInformation(materaId)
  }, [materaId, companies])

  const loadCompanies = async () => {
    try {
      const { data: allCompanies } =
        await companyRepository.getCompaniesWithPaidPlan()

      const pjCompanies = allCompanies.filter(
        (company) => company.Cpf_Cnpj.length === 14
      )

      setCompanies(pjCompanies)

      const { data: companiesWithAccount } =
        await materaCompanyRepository.index()

      const companiesWithAccountIds = companiesWithAccount.map(
        (company) => company.companyId
      )

      const companiesWithoutAccount = pjCompanies.filter(
        (company) => !companiesWithAccountIds.includes(company.id)
      )

      const companiesOptions = companiesWithoutAccount.map((company) => ({
        label: `${company.Company_Name} - ${cpfOrCnpjMask(company.Cpf_Cnpj)}`,
        value: company,
      }))

      setCompaniesOptions(companiesOptions)
    } catch (err) {
      console.log(err)
      setHasError(true)
      return toastr.error(
        'Ocorreu um erro ao carregar as informações das empresas. Tente novamente!'
      )
    }
  }

  const loadMateraInformation = async (materaId) => {
    const { data } = await materaCompanyRepository.show(materaId)

    const companyData = companies.find(
      (company) => company.id === data.companyId
    )

    const companyUsers = await companyRepository.getAllUsers(data.companyId)
    const representativeUser = companyUsers.find(
      (user) => user.employeeId === data.representativeId
    )

    setCompany(companyData)
    setAccountStatus(data.status)

    dispatch([
      change('matera', 'materaAccountId', data.accountId),
      change('matera', 'materaAccountNumber', data.accountNumber),
      change('matera', 'accountType', data.accountType),
      change('matera', 'representative', representativeUser.id),
      change('matera', 'taxPixWork', data.taxPixWork),
      change('matera', 'taxPixMatera', data.taxPixMatera),
    ])

    setValidUser(true)
  }

  async function getCompanyAddress(companyId) {
    try {
      const { data: companyAddress } =
        await addressRepository.getCompanyAddress(companyId)
      return companyAddress.data[0]
    } catch (err) {
      setHasError(true)
      toastr.error(
        'Ocorreu um erro ao buscar o endereço da empresa. Tente novamente!'
      )
    }
  }

  async function getActiveCompanyUsers(companyId) {
    try {
      const allCompanyUsers = await companyRepository.getAllUsers(companyId)
      const activeUsers = allCompanyUsers.filter((user) => user.IsActive)
      return activeUsers
    } catch (err) {
      setHasError(true)
      toastr.error(
        'Ocorreu um erro ao buscar o endereço da empresa. Tente novamente!'
      )
    }
  }

  async function fillFormWithCompanyData() {
    try {
      const { Type, Cpf_Cnpj, Company_Name, Trading_Name, Email, Phones } =
        company
      const { Number_Phone1, Contact } = Phones[0]

      const address = await getCompanyAddress(company.id)
      const activeUsers = await getActiveCompanyUsers(company.id)

      setCompanyUsersOptions(activeUsers)

      dispatch([
        change('matera', 'type', Type),
        change('matera', 'cpfCnpj', cpfOrCnpjMask(Cpf_Cnpj)),
        change('matera', 'companyName', Company_Name),
        change('matera', 'fantasyName', Trading_Name),
        change('matera', 'email', Email),
        change('matera', 'phone', phoneMask(Number_Phone1)),
        change('matera', 'contact', Contact),
        change('matera', 'companyId', company.id),
        change('matera', 'status', 0),
        change('matera', 'zipCode', address.Zipcode),
        change('matera', 'street', address.Address),
        change('matera', 'neighborhood', address.Neighborhood),
        change('matera', 'addressNumber', address.Address_Number),
        change('matera', 'complement', address.Complement),
        change('matera', 'referencePoint', address.Reference_Point),
        change('matera', 'state', address.State),
        change('matera', 'city', address.City),
      ])
    } catch (err) {
      setHasError(true)
      toastr.error(
        'Ocorreu um erro ao inserir os dados da empresa no formulário. Tente novamente!'
      )
    }
  }

  function resetCompanySelect() {
    setCompany(null)
    dispatch(reset('matera'))
    dispatch([
      change('matera', 'taxPixWork', taxes[0].tax),
      change('matera', 'taxPixMatera', taxes[1].tax),
    ])
    setCompanySelectPlaceholder(defaultCompanySelectPlaceholder)
    return
  }

  function handleCompanySelect(item) {
    setCompany(item.value)
    setCompanySelectPlaceholder(item.label)
    return
  }

  async function validateSelectedUser(userId) {
    setValidUser(false)

    if (!userId) return

    const user = companyUsersOptions.find(
      (user) => user.id === parseInt(userId)
    )

    if (!user?.employeeId) {
      return toastr.warning(`Por favor, realize o cadastro de todos os dados 
      funcionário para o usuário ${user.Email} para prosseguir.`)
    }

    try {
      const { data: employee } = await employeeRepository.getById(
        user?.employeeId
      )
      if (!employee.motherName) {
        return toastr.warning(
          `Por favor, realize o preenchimento do nome completo da mãe para o usuário ${user.Email} para prosseguir.`
        )
      }
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao buscar os dados do usuário selecionado. Tente novamente!'
      )
      return
    }

    setValidUser(true)
  }

  /* OK */

  async function loadTaxes() {
    try {
      const defaultTaxes = await materaCompanyRepository.getDefaultTaxes()
      setTaxes(defaultTaxes.data)
    } catch (err) {
      setHasError(true)
      toastr.error(
        'Ocorreu um erro ao carregar as taxas padrão. Tente novamente!'
      )
    }
  }

  async function loadCities() {
    try {
      const cities = await addressRepository.getAllCities()
      setCitiesOptions(cities)
    } catch (err) {
      setHasError(true)
      toastr.error('Ocorreu um erro ao carregar as cidades. Tente novamente!')
    }
  }

  async function loadStates() {
    try {
      const states = await addressRepository.getAllStates()
      setStatesOptions(states)
    } catch (err) {
      setHasError(true)
      toastr.error('Ocorreu um erro ao carregar os estados. Tente novamente!')
    }
  }

  return (
    <main className='matera-link-form'>
      <section className='search-bar'>
        <Select
          isSearchable
          options={companiesOptions}
          placeholder={companySelectPlaceholder}
          onChange={handleCompanySelect}
          onFocus={resetCompanySelect}
          value={null}
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          components={{
            DropdownIndicator: SearchIcon,
          }}
          isDisabled={!!materaId}
          styles={{
            control: (base) => ({
              ...base,
              height: '40px',
              minHeight: '40px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (base) => ({
              ...base,
              height: '40px',
              padding: '0 0 0 10px',
              marginTop: '-4px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              marginTop: '-4px',
            }),
          }}
        />
      </section>

      <section className='form'>
        {materaId && (
          <span
            style={{
              color: 'red',
              fontSize: 13,
              marginBottom: 5,
              alignSelf: 'flex-end',
            }}
          >
            * Campos Obrigatórios
          </span>
        )}
        <CardForm show={!!company} title='Dados Principais'>
          <MainData
            companyType={company?.Type}
            logo={{ preview: company?.Url_Logo }}
            isEdit={!!materaId}
            citiesOptions={citiesOptions}
            statesOptions={statesOptions}
            companyUsersOptions={companyUsersOptions}
            validateSelectedUser={validateSelectedUser}
            setIsTaxPixChanged={setIsTaxPixChanged}
          />
        </CardForm>

        <section className='submit-buttons'>
          <FormSubmitButtons
            loading={saveLoading}
            onCancel={() =>
              (!materaId && dirty && company) || (!!materaId && isTaxPixChanged)
                ? setIsConfirmCancelModalOpen(true)
                : onCancel()
            }
            onSubmit={() =>
              accountStatus !== 'FINALIZADO'
                ? setShowChooseModeModal(true)
                : setIsConfirmTaxChangeModalOpen(true)
            }
            hasId={!!materaId}
            disabledSubmit={
              !materaId
                ? !hasError && validUser
                  ? ''
                  : 'disabled'
                : (accountStatus === 'FINALIZADO' && isTaxPixChanged) ||
                  accountStatus !== 'FINALIZADO'
                ? ''
                : 'disabled'
            }
          />
        </section>

        <AlertModal
          show={isConfirmCancelModalOpen}
          title='OS Digital'
          onHide={() => setIsConfirmCancelModalOpen(false)}
          onCancel={() => setIsConfirmCancelModalOpen(false)}
          message={
            <>
              <p>
                <strong>
                  Você tem certeza que deseja sair e não cadastrar a empresa?
                </strong>
              </p>
              <p>O cadastro poderá ser feito novamente mais tarde.</p>
            </>
          }
          onSubmit={onCancel}
        />

        <AlertModal
          show={isConfirmTaxChangeModalOpen}
          title='OS Digital'
          onHide={() => setIsConfirmTaxChangeModalOpen(false)}
          onCancel={() => setIsConfirmTaxChangeModalOpen(false)}
          message={
            <>
              <p>
                <strong>
                  Você tem certeza que deseja alterar o valor da taxa de PIX
                  dessa empresa?
                </strong>
              </p>
              <p>
                A partir desse momento, o valor da taxa a ser cobrada será
                alterada e, uma vez cobrada, não poderá ser estornada.
              </p>
            </>
          }
          onSubmit={handleSubmit}
        />

        <ChooseMateraModeModal
          show={showChooseModeModal}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          onCancel={() => setShowChooseModeModal(false)}
        />
      </section>
    </main>
  )
}

export default reduxForm({
  form: 'matera',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: true,
})(MateraAccountForm)
