import React from 'react';
import { Grid, Row } from "react-bootstrap";

import constants from '../../../utils/constants'
import QuotationsMain from './components/QuotationsMain';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

function Quotations() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos','Cotação de Peças Online']}
            path={[constants.ROUTES.HOME, null, null]}
          />
          <QuotationsMain />
        </Row>
      </Grid>
    </div>
  )
}

export default Quotations;