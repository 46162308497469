import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import BundleFormOfPayment from './BundleFormOfPayment'

function NewFormOfPayment({
  match,
  id,
  closeModal,
  modal,
  reloadInfo,
  nativeModal,
}) {
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastros',
              'Vendas',
              'Forma de Pagamento',
              !!match?.params.id || id
                ? 'Editar Forma de Pagamento'
                : 'Nova Forma de Pagamento',
            ]}
            path={['home', null, null, 'payment-forms', null]}
          />
          <BundleFormOfPayment
            formOfPaymentId={match ? match.params.id : id}
            closeModal={closeModal}
            modal={modal}
            reloadInfo={reloadInfo}
            nativeModal={nativeModal}
          />
        </Row>
      </Grid>
    </div>
  )
}

export default NewFormOfPayment
