import React from 'react';
import SearchInput from 'components/SearchInput';
import constants from 'utils/constants';
import '../styles.css';
import { addDays, format } from 'date-fns';

export function PromotionalPriceTableFilter({
  history,
  setStatus,
  setDateType,
  handleChangeQuery,
  handleChangeInitialDate,
  handleChangeFinalDate,
  initialDate,
  finalDate,
}) {
  return (
    <div
      style={{
        padding: 0,
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'flex-end',
        gap: '10px',
        marginTop: '20px',
      }}
    >
      <div>
        <button
          className="btn btn-sucesso button-h35"
          onClick={() =>
            history.push(constants.ROUTES.NEW_PROMOTIONAL_PRICE_TABLE)
          }
          style={{ fontSize: '12px' }}
        >
          + Nova Tabela
        </button>
      </div>
      <div>
        <SearchInput
          placeholder="Pesquisa por Descrição"
          onChange={(e) => handleChangeQuery(e.target.value)}
          background={'#FFFFFF'}
          style={{
            fontSize: '12px',
            height: '35px',
            width: '312px',
            marginLeft: 0,
            marginRight: 0,
          }}
        />
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>
          <strong>Status:</strong>
        </span>
        <select
          className="foco-input form-control"
          onChange={(e) => setStatus(e.target.value)}
          style={{ fontSize: '12px' }}
        >
          <option value={''}>Ambos</option>
          <option value={true}>Ativo</option>
          <option value={false}>Inativo</option>
        </select>
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>
          <strong>Tipo de Data:</strong>
        </span>
        <select
          className="foco-input form-control"
          onChange={(e) => setDateType(e.target.value)}
          style={{ fontSize: '12px' }}
        >
          <option value="initialDate">Inicial</option>
          <option value="finalDate">Final</option>
        </select>
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          type="date"
          className="foco-input form-control"
          onChange={(e) => handleChangeInitialDate(e.target.value)}
          min={format(new Date(), 'yyyy-MM-dd')}
          max={
            finalDate
              ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
              : null
          }
          style={{ fontSize: '12px' }}
        />
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>
          <strong>Data Final:</strong>
        </span>
        <input
          type="date"
          className="foco-input form-control"
          onChange={(e) => handleChangeFinalDate(e.target.value)}
          min={
            initialDate
              ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd')
          }
          style={{ fontSize: '12px' }}
        />
      </div>
    </div>
  );
}
