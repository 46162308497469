import React, { useEffect } from 'react';
import { Grid, Row } from 'react-bootstrap';

import HomePaymentCondition from './PaymentCondition';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const PaymentCondition = () => {
  const pathName = localStorage.getItem('selectedPath');
  useEffect(() => {
    if (pathName === 'client/NovaVenda') {
      localStorage.setItem('selectedPath', 'client/NovaVenda');
    } else {
      localStorage.setItem('selectedPath', 'client/payment-conditions');
    }
  }, [pathName]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Vendas', 'Condições de Pagamento']}
            path={['home', null, null, null]}
          />
          <HomePaymentCondition />
        </Row>
      </Grid>
    </div>
  );
};

export default PaymentCondition;
