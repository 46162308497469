import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import renderField from '../../../../../components/RenderField'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useAuth } from '../../../../../contexts/auth'
import Datalist from 'components/Datalist'
import useFilters from '../../../../../hooks/useFilters'
import lodash from 'lodash'

import servicesRepository from '../../../../../repositories/Services'
import linkedServiceRepository from '../../../../../repositories/LinkedService'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const LinkedServiceMainData = ({ productId }) => {
  const [services, setServices] = useState([])
  const [servicesSuggestions, setServicesSuggestions] = useState([])
  const [query, setQuery] = useState()

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  useEffect(() => {
    if (!!companyId) {
      loadServices()
    }
  }, [companyId])

  useEffect(() => {
    handleServicesSuggestions()
  }, [services])

  async function handleServicesSuggestions() {
    const suggestions = services.map((service) => ({
      primary: service.Code,
      secondary: service.Description,
    }))
    setServicesSuggestions(suggestions)
  }

  async function loadServices() {
    try {
      const services = await servicesRepository.getAllByCompany(companyId)

      const filterServices = services.filter(
        (service) => service.IsActive === 1 && service.Price > 0
      )

      const sortedServices = filterServices.sort((a, b) => {
        if (Number(a.Code) < Number(b.Code)) return -1
        if (Number(a.Code) > Number(b.Code)) return 1
      })
      setServices(sortedServices)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o produto. Por favor, tente novamente'
      )
    }
  }

  const handleChangeSearch = async (value) => {
    const selectedService = services.find((service) => service.Code === value)

    if (selectedService) {
      dispatch([
        change('formLinkedService', 'serviceId', selectedService.id),
        change('formLinkedService', 'code', selectedService.Code),
        change('formLinkedService', 'description', selectedService.Description),
        change('formLinkedService', 'price', selectedService.Price),
        change(
          'formLinkedService',
          'hoursExpected',
          selectedService.Hours_Expected
        ),
      ])
    }
  }

  return (
    <>
      <Col xs={12} sm={5} md={5} lg={5}>
        <i id='iconepesquisar' className='fa fa-search'></i>
        <input
          list='searchProduct'
          type='text'
          style={{ paddingLeft: '13%' }}
          className='form-control foco-input'
          placeholder='Pesquisar por Código e Descrição'
          value={query}
          onChange={(e) => handleChangeSearch(e.target.value)}
        />
        <Datalist suggestions={servicesSuggestions} id='searchProduct' />
      </Col>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Col xs={6} sm={3} md={2} lg={2}>
          <Field label='Código:' name='code' component={renderField} disabled />
        </Col>
        <Col xs={12} sm={9} md={9} lg={4}>
          <Field
            label='Descrição:'
            name='description'
            component={renderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Field label='Valor:' name='price' component={renderField} disabled />
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Field
            label='Horas:'
            name='hoursExpected'
            component={renderField}
            disabled
          />
        </Col>
      </div>
    </>
  )
}

export default LinkedServiceMainData
