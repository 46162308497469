import React from 'react';

import './styles.css';

export default function Loader({ message }) {
  return (
    <div className="osdig-loader-container">
      <label>{message} <span className="fa fa-spinner fa-pulse fa-1x" /></label>
    </div>
  )
}