import api from '../services/api'
import config from '../config'

const create = async (sale) => {
  try {
    const response = await api.post(config.endpoint + 'pdv', sale)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const update = async (id, sale) => {
  await api.put(config.endpoint + `pdv/${id}`, sale)
}

const getOrcamentsByCompanyWithOnlyProducts = async (companyId) => {
  const response = await api.get(
    config.endpoint + `pdv/open-orcaments?companyId=${companyId}`
  )
  return response.data
}

const getReceipts = async (companyId, params) => {
  const { data } = await api.get(`pdv/receipts/${companyId}`, { params })
  return data
}

const getProducts = async (companyId, params) => {
  const { data } = await api.get(`products/${companyId}/vehicle/application`, {
    params,
  })
  return data
}

export default {
  create,
  update,
  getOrcamentsByCompanyWithOnlyProducts,
  getReceipts,
  getProducts,
}
