const getDateOnlyFromDate = value => {
  const date = new Date(value)
  const dateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
  return dateOnly
}

const getDDMMYYYYDateFromString = stringDate => {
  let date, time

  const dateTimeSeparatingChar = stringDate.includes('T') ? 'T' : stringDate.includes(' ') ? ' ' : null
  const dateSeparatingChar = stringDate.includes('/') ? '/' : '-'

  if (dateTimeSeparatingChar) {
    const dateTime = stringDate.split(dateTimeSeparatingChar)
    date = dateTime[0]
    time = dateTime[1]
  } else {
    date = stringDate
    time = null
  }

  const formatedDate = `${date.split(dateSeparatingChar).reverse().join('-')} ${time ? time : ''}`
  
  return new Date(formatedDate)
}

export {
  getDateOnlyFromDate,
  getDDMMYYYYDateFromString,
}