import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import Input from './components/Input'
import Select from './components/Select'
import {
  cpfMask,
  RgMask,
  name,
  email,
  phoneMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize'

const PF = ({ edit }) => {
  const { providerId, cpfCnpj } = useSelector(
    (state) => state.form.formProvider.values
  )

  return (
    <>
      <Col lg={2} md={2} sm={1} xs={12}>
        <Field
          label="CPF:"
          name="cpfCnpj"
          component={Input}
          normalize={cpfMask}
          maxLength="14"
          disabled={edit && cpfCnpj?.length === 14}
        />
      </Col>
      <Col lg={2} md={2} sm={1} xs={12}>
        <Field
          label="RG:"
          name="RG"
          component={Input}
          normalize={RgMask}
          maxLength="10"
        />
      </Col>
      <Col lg={2} md={2} sm={4} xs={12}>
        <Field
          label="Nome Completo:"
          required
          name="companyName"
          component={Input}
          normalize={name}
          placeholder="Insira o nome do seu cliente"
          maxLength={60}
        />
      </Col>
      <Col lg={2} md={2} sm={2} xs={12}>
        <Field
          label="E-mail:"
          name="email"
          type="text"
          required
          placeholder="seucliente@gmail.com"
          component={Input}
          normalize={email}
        />
      </Col>
      <Col lg={2} md={2} sm={2} xs={12}>
        <Field
          label="Telefone Residencial:"
          name="commercialPhone"
          placeholder="(21)2872-2294"
          component={Input}
          normalize={phoneMask}
        />
      </Col>
      <Col lg={2} md={2} sm={2} xs={12}>
        <Field
          label="Telefone Celular:"
          required
          name="cellPhone"
          placeholder="(21)98473-6441"
          component={Input}
          normalize={phoneMask}
        />
      </Col>
    </>
  )
}

export default PF
