import React, { useState, useEffect } from 'react'
import { Col, Modal } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { reduxForm, change, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import CardForm from '../../../../components/CardForm'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import Input from '../../../Clients/NewClient/FormClient/components/Input'
import MainData from './MainData'
import Items from './Items'
import Totals from './Totals'
import { cpfMask } from 'client/components/ToNormalize/ToNormalize'
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'

const SATCFe = ({ handleSubmit, disabled, onCancel }) => {
  const [dadosItens, setDadosItens] = useState(true)
  const [dadosNota, setDadosNota] = useState(true)
  const [totaisNota, setTotaisNota] = useState(true)
  const [isIncludeCpfCnpjModalOpen, setIsIncludeCpfCnpjModalOpen] =
    useState(false)
  const [msgBtn, setMsgBtn] = useState('')
  const [isSATConfigurationComplete, setIsSATConfigurationComplete] =
    useState(false)

  const {
    isDefaultCustomer,
    statusSATCFe,
    isInvoiceOpen,
    status,
    isInvoiceIssued,
    isInvoiceCanceled,
    isInvoiceOpenedWithErrors,
    settingsNFCe,
    cnpjCpf,
    customerHasCpfCnpj,
  } = useSelector((state) => state.form.cadastroSATCFe.values)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!settingsNFCe) return

    const isSATConfigurationComplete = validateSATSettings(settingsNFCe)
    setIsSATConfigurationComplete(isSATConfigurationComplete)

    if (!isSATConfigurationComplete) {
      return toastr.error(
        'Realize todo o preenchimento das configurações do SAT para emitir o SAT'
      )
    }

    if (statusSATCFe === 'Aberta') {
      setIsIncludeCpfCnpjModalOpen(true)
    }
  }, [statusSATCFe, settingsNFCe])

  useEffect(() => {
    if (!isInvoiceOpen || !isInvoiceOpenedWithErrors) {
      msgButton()
    }
  }, [status])

  async function submit({ includeCpfCnpj }) {
    if (includeCpfCnpj) {
      const isDocumentValid = validateDocument(cnpjCpf)

      if (includeCpfCnpj && !isDocumentValid) {
        return toastr.warning('Insira um CPF ou CNPJ válido e tente novamente')
      }
    }

    setIsIncludeCpfCnpjModalOpen(false)
    dispatch(change('cadastroSATCFe', 'includeCpfCnpj', includeCpfCnpj))
    setTimeout(() => handleSubmit(), 1000)
  }

  function msgButton() {
    switch (status) {
      case 'Emitida':
        setMsgBtn('SAT Emitido')
        break
      case 'Cancelada':
        setMsgBtn('SAT Cancelado')
        break
      case 'Denegada':
        setMsgBtn('SAT Denegado')
        break
      case 'Contingência':
        setMsgBtn('SAT em Contingência')
        break
      default:
        break
    }
  }

  const validateDocument = (cpfCnpj) => {
    const onlyNumbersCpfCnpj = onlyNumbers(cpfCnpj)

    if (onlyNumbersCpfCnpj.length !== 11 && onlyNumbersCpfCnpj.length !== 14) {
      return false
    }

    if (onlyNumbersCpfCnpj.split('').every((number) => number === '0')) {
      return false
    }

    return true
  }

  const validateSATSettings = (settingsNFCe) =>
    settingsNFCe.CSC &&
    settingsNFCe.CSCId &&
    settingsNFCe.isSatCertificateImported &&
    settingsNFCe.satActivationCode &&
    settingsNFCe.serie

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show={dadosNota}
          title="Dados da Nota"
          onClick={() => setDadosNota(!dadosNota)}
        >
          <MainData disabled={disabled} />
        </CardForm>
        <CardForm
          show={dadosItens}
          title="Itens"
          onClick={() => setDadosItens(!dadosItens)}
        >
          <Items disabled={disabled} />
        </CardForm>
        {(isInvoiceIssued || isInvoiceCanceled) && (
          <CardForm
            show={totaisNota}
            title="Totais"
            onClick={() => setTotaisNota(!totaisNota)}
          >
            <Totals disabled={disabled} />
          </CardForm>
        )}

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            bsStyle="danger"
            onClick={onCancel}
            style={{ marginRight: '12px' }}
            name="Cancelar"
            fill
          >
            Voltar
          </Button>
          {isInvoiceOpen || isInvoiceOpenedWithErrors ? (
            <Button
              bsStyle="info"
              disabled={disabled || !isSATConfigurationComplete}
              name="Salvar"
              fill
              onClick={() => setIsIncludeCpfCnpjModalOpen(true)}
            >
              Emitir SAT
            </Button>
          ) : (
            <Button
              bsStyle="info"
              className="color-button"
              disabled={disabled}
              name="Salvar"
              fill
            >
              {msgBtn}
            </Button>
          )}
        </Col>
      </div>

      <Modal
        dialogClassName="modal-cpfnota"
        show={isIncludeCpfCnpjModalOpen}
        animation
        onHide={() => setIsIncludeCpfCnpjModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Emissão de SAT</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modal-edit-customer"
          style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}
        >
          Deseja emitir com CPF/CNPJ?
          <div id="center-cpf-input">
            <Field
              name="cnpjCpf"
              component={Input}
              disabled={!isDefaultCustomer && customerHasCpfCnpj}
              normalize={(value) =>
                onlyNumbers(value).length <= 11
                  ? cpfMask(value)
                  : cnpjMask(value)
              }
              maxLength={18}
            />
            <p style={{ color: '#d43f3a', fontSize: '12px' }}>
              A emissão do SAT só poderá ser feita no computador que ele está
              instalado
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-identify-in-nfce-footer">
            <Button
              type="button"
              name="Voltar"
              style={{ float: 'left' }}
              bsStyle="danger"
              fill
              onClick={() => submit({ includeCpfCnpj: false })}
            >
              Não
            </Button>
            <Button
              type="button"
              name="Sim"
              bsStyle="info"
              fill
              onClick={() => submit({ includeCpfCnpj: true })}
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroSATCFe',
})(withRouter(SATCFe))
