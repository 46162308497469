import api from '../services/api';

async function getSalesInvoices({
  companyId,
  customerId,
  typeDate,
  initialDate,
  finalDate,
  page,
  limit,
}) {
  try {
    const response = await api.get(`/sales/without-nfe/${companyId}`, {
      params: {
        customerId,
        typeDate,
        initialDate,
        finalDate,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching sales invoices without NFE:', error);
    throw error;
  }
}

async function sendGroupedInvoices(companyId, saleIds) {
  try {
    const response = await api.post(`/nfe/grouped-sales`, {
      saleIds,
      companyId,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching sales invoices without NFE:', error);
    throw error;
  }
}
const GroupedInvoiceEmissionRepository = {
  getSalesInvoices,
  sendGroupedInvoices,
};

export default GroupedInvoiceEmissionRepository;
