import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router'

import Button from '../CustomButton/CustomButton'
import constants from '../../../utils/constants'
import { useAuth } from '../../../contexts/auth'
import ModalAlertCompany from '../ModalAlertCompany/ModalAlertCompany'

import './styles.css'

const UpgradeFreePlanModal = ({ history, show, onCancel, subTitle }) => {
  const [isNoCompanyModalOpen, setIsNoCompanyModalOpen] = useState(false)

  const { companyId } = useAuth()

  function handleOpenWhatsAppTab() {
    window.open(constants.WHATSAPP_API_SEND_MESSAGE_ENDPOINT + '?phone=5519982440071&text=Ol%C3%A1,%20utilizo%20o%20plano%20free%20e%20gostaria%20de%20assinar%20o%20OS%20Digital', '_blank')
  }

  function handleNavigateToSignPlanPage() {
    onCancel()
    history.push('AssinarPlano')
  }

  return (
    <>
      <Modal
        show={show}
        animation
        dialogClassName='upgrade-free-plan-modal-wrapper'
        onHide={onCancel}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>
              OS Digital
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <strong>
              {
                subTitle || 'Para visualizar, será necessário efetuar assinatura.'
              }
              <br />
            </strong>
          </span>
          <p>
            Você também terá a oportunidade de utilizar todos os recursos disponíveis por plano, sem limitação de data, para o total gestão de seu negócio.
          </p>

          <div>
            <Button
              bsStyle='primary'
              fill
              onClick={() => !companyId ? setIsNoCompanyModalOpen(true) : handleNavigateToSignPlanPage()}
            >
              Assinar via Cartão de Crédito
            </Button>
            <Button
              bsStyle='info'
              fill
              onClick={handleOpenWhatsAppTab}
            >
              Assinar via Boleto Bancário*
            </Button>
          </div>
          <center>
            <p className='observation-label'>
              *A assinatura via Boleto é realizada junto ao Departamento Comercial
            </p>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle='danger'
            fill
            onClick={onCancel}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalAlertCompany
        show={isNoCompanyModalOpen}
        onHide={() => setIsNoCompanyModalOpen(false)}
      />
    </>
  )
}

export default withRouter(UpgradeFreePlanModal)