import config from '../config'
import api from "../services/api"

const getAllByCompanyAndDefaults = async companyId => {
  try {

    const response = await api.get(config.endpoint + `natureoftransactions/by-company/${companyId}`)
    return response.data

  } catch(err) {
    throw err
  }
}

const getAllByCompany = async companyId => {
  try {

    const response = await api.get(config.endpoint + `natureoftransactions?companyId=${companyId}`)
    return response.data

  } catch(err) {
    throw err
  }
}


export default {
  getAllByCompanyAndDefaults,
  getAllByCompany
}