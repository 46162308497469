import React from 'react';
import './styles.css';

const InputNumber = ({ value, id, onChange }) => {
  const handleChange = (e) => {
    if (!e.target.value) {
      e.target.value = 1;
    }

    onChange(e.target.value);
  };

  return (
    <div id="input-number-wrapper">
      <input
        className="form-control foco-input"
        id={id}
        type="text"
        value={value}
        onChange={handleChange}
      />
      <section>
        <button type="button" onClick={() => onChange(parseInt(value) + 1)}>
          +
        </button>
        <button
          type="button"
          onClick={() => value > 1 && onChange(parseInt(value) - 1)}
        >
          -
        </button>
      </section>
    </div>
  );
};

export default InputNumber;
