import api from '../services/api';

export const listChecklists = async (params) => {
  try {
    const res = await api.get('/checklists', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateChecklist = async (id, data) => {
  try {
    await api.put('/checklists/' + id, data);
  } catch (err) {
    throw err;
  }
};

export const createChecklist = async (body) => {
  const res = await api.post('/checklists', body);
  return res.data;
};

export const deleteChecklist = async (id) => {
  try {
    await api.delete('/checklists/' + id);
  } catch (err) {
    throw err;
  }
};

export const getChecklistById = async (id, companyId) => {
  try {
    const res = await api.get(`/checklists/${id}?companyId=${companyId}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const DefectRepository = {
  listChecklists,
  deleteChecklist,
  getChecklistById,
  createChecklist,
  updateChecklist,
};
