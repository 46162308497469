import Button from 'client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap'
import React, { useState } from 'react';
import { ShowChartRounded } from '@material-ui/icons';

// import { Container } from './styles';

function ValidationModal({ validationModal, setValidationModal, link }) {

  console.log(link)
  return (
    <Modal
      show={validationModal}
      onHide={() => setValidationModal(false)}
      animation
    >
      <Modal.Header closeButton>
        <h1>Validação de Link</h1>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <iframe width="550" height="500" frameborder="0" marginheight="0" marginwidth="0" src={link} title="OSD News" sandbox='allow-scripts'></iframe>
      </Modal.Body>
      <Modal.Footer>
        <div className='upgrade-modal-footer'>
          <Button bsStyle='danger' fill onClick={() => setValidationModal(false)}>
            Cancelar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ValidationModal;