import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../../client/components/CustomButton/CustomButton';

const ConfirmPurchaseModal = ({ onCancel, onSubmit, isFirstPriceChange }) => {
  return (
    <div>
      <Modal
        show
        animation
        onHide={onCancel}
        dialogClassName="modal-40w modal-70h"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Seleção de Compra</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isFirstPriceChange === null || false ? (
            <strong style={{ fontSize: '16px' }}>
              Essa é a primeira alteração de preço para essa compra.
            </strong>
          ) : (
            <strong style={{ fontSize: '16px' }}>
              Essa compra já possui 1 ou mais alterações de preço vinculadas.
            </strong>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexFlow: 'column wrap',
              lineHeight: '5px',
            }}
          >
            {isFirstPriceChange === null || false ? (
              <p>
                Você deseja selecionar essa compra para iniciar o processo de
                alteração de preço?
              </p>
            ) : (
              <p>
                Você deseja selecionar essa compra para iniciar o processo de
                alteração de preço? Em caso de alteração, será substituído o
                valor atual para o valor aplicado.
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button bsStyle="danger" name="Calcelar" onClick={onCancel} fill>
              Não
            </Button>
            <Button bsStyle="info" name="Salvar" fill onClick={onSubmit}>
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmPurchaseModal;
