import { toastr } from 'react-redux-toastr';
import dateFns from 'date-fns';
import { isLegalOrPhysicalPerson } from 'v2/helpers/normalizeString';

const providersColumns = require('./ModelsColumns/providers');
const providersData = require('./ModelsData/providers');

const modelsColumns = {
  providers: providersColumns,
  products: [
    {
      name: 'Descrição',
      acessor: 'description',
    },
    {
      name: 'Unidade de Medida',
      acessor: 'measureUnit',
    },
    {
      name: 'Estoque',
      acessor: 'inventory',
    },
    {
      name: 'Localização',
      acessor: 'location',
    },
    {
      name: 'Ref. Fabricante',
      acessor: 'manufacturerRef',
    },
    {
      name: 'Código de Barras (EAN)',
      acessor: 'barCode',
    },
    {
      name: 'Código de Barras (EAN - Tributável)',
      acessor: 'tributeBarCode',
    },
    {
      name: 'Preço de Compra',
      acessor: 'purchasePrice',
    },
    {
      name: 'Margem',
      acessor: 'margin',
    },
    {
      name: 'Preço de venda',
      acessor: 'salePrice',
    },
    {
      name: 'Estoque Mín.',
      acessor: 'minStock',
    },
    {
      name: 'Estoque Máx.',
      acessor: 'maxStock',
    },
    {
      name: '%Comissão',
      acessor: 'comission',
    },
    {
      name: 'Origem da mercadoria',
      acessor: 'origin',
    },
    {
      name: 'CSOSN/CST',
      acessor: 'csosn',
    },
    {
      name: 'CFOP Dentro do Estado',
      acessor: 'cfop',
    },
    {
      name: 'NCM',
      acessor: 'ncm',
    },
    {
      name: 'CEST',
      acessor: 'cest',
    },
    {
      name: 'ST PIS (Saída)',
      acessor: 'pisExit',
    },
    {
      name: 'ST COFINS (Saída)',
      acessor: 'cofinsExit',
    },
    {
      name: 'ST PIS (Entrada)',
      acessor: 'pisEntry',
    },
    {
      name: 'ST COFINS (Entrada)',
      acessor: 'cofinsEntry',
    },
    {
      name: '%IPI',
      acessor: 'ipi',
    },
    {
      name: 'Código ANP',
      acessor: 'anp',
    },
    {
      name: 'Informações Complementares',
      acessor: 'additionalInformation',
    },
  ],
  customers: [
    {
      name: 'CPF/CNPJ',
      acessor: 'Cpf_Cnpj',
    },
    {
      name: 'Tipo',
      acessor: 'Type',
    },
    {
      name: 'Nome Completo / Razão Social',
      acessor: 'Company_Name',
    },
    {
      name: 'Nome Fantasia',
      acessor: 'Trading_Name',
    },
    {
      name: 'Telefone Residencial',
      acessor: 'Number_Phone1',
    },
    {
      name: 'Celular',
      acessor: 'Number_Phone2',
    },
    {
      name: 'Data de Nascimento',
      acessor: 'Date_Birth',
    },
    {
      name: 'Insc. Estadual',
      acessor: 'IE',
    },
    {
      name: 'Insc. Municipal',
      acessor: 'IM',
    },
    {
      name: 'Indicador de IE',
      acessor: 'Status_IE',
    },
    {
      name: 'CEP (Principal)',
      acessor: 'ZipcodeP',
    },
    {
      name: 'Rua (Principal)',
      acessor: 'AddressP',
    },
    {
      name: 'Número (Principal)',
      acessor: 'Address_NumberP',
    },
    {
      name: 'Complemento (Principal)',
      acessor: 'ComplementP',
    },
    {
      name: 'Ponto de Referência (Principal)',
      acessor: 'Reference_PointP',
    },
    {
      name: 'Bairro (Principal)',
      acessor: 'NeighborhoodP',
    },
    {
      name: 'Cidade (Principal)',
      acessor: 'CityP',
    },
    {
      name: 'Estado (Principal)',
      acessor: 'StateP',
    },
    {
      name: 'País (Principal)',
      acessor: 'CountryP',
    },
    {
      name: 'CEP (Entrega)',
      acessor: 'ZipcodeE',
    },
    {
      name: 'Rua (Entrega)',
      acessor: 'AddressE',
    },
    {
      name: 'Número (Entrega)',
      acessor: 'Address_NumberE',
    },
    {
      name: 'Complemento (Entrega)',
      acessor: 'ComplementE',
    },
    {
      name: 'Ponto de Referência (Entrega)',
      acessor: 'Reference_PointE',
    },
    {
      name: 'Bairro (Entrega)',
      acessor: 'NeighborhoodE',
    },
    {
      name: 'Cidade (Entrega)',
      acessor: 'CityE',
    },
    {
      name: 'Estado (Entrega)',
      acessor: 'StateE',
    },
    {
      name: 'País (Entrega)',
      acessor: 'CountryE',
    },
  ],
  services: [
    {
      name: 'Descrição',
      acessor: 'Description',
    },
    {
      name: 'Preço',
      acessor: 'Price',
    },
    {
      name: 'Horas Previstas',
      acessor: 'Hours_Expected',
    },
    {
      name: '% Comissão',
      acessor: 'Commission_Rate',
    },
    {
      name: 'Informações Complementares',
      acessor: 'Additional_Information',
    },
  ],
};

const modelsData = {
  providers: providersData,
  products: [
    {
      description: 'Peça para motor',
      measureUnit: 'PC',
      inventory: 20,
      location: 'Motor',
      manufacturerRef: 'AA11111',
      barCode: '123456789123',
      tributeBarCode: '123456789123',
      purchasePrice: 50.5,
      margin: 100,
      salePrice: 101,
      minStock: 0,
      maxStock: 70,
      comission: 10,
      origin: 0,
      csosn: 100,
      cfop: 0,
      ncm: 87089990,
      cest: '0000000',
      pisExit: '04',
      cofinsExit: '04',
      pisEntry: '70',
      cofinsEntry: '70',
      ipi: 55.55,
      anp: 1234,
      additionalInformation: 'Produto comprado em X loja.',
    },
    {
      description: 'Filtro de óleo',
      measureUnit: 'UNID',
      inventory: 30,
      location: 'Filtro',
      manufacturerRef: 'AA11111',
      barCode: '123456789123',
      tributeBarCode: '123456789123',
      purchasePrice: 31.3,
      margin: 100,
      salePrice: 62.6,
      minStock: 10,
      maxStock: 100,
      comission: 5,
      origin: 0,
      csosn: 100,
      cfop: 0,
      ncm: 87089990,
      cest: '0000000',
      pisExit: '04',
      cofinsExit: '04',
      pisEntry: '70',
      cofinsEntry: '70',
      ipi: 55.55,
      anp: 1234,
      additionalInformation: 'Produto se encontra em falta no mercado.',
    },
  ],
  customers: [
    {
      Cpf_Cnpj: '12123456000170',
      Type: 'Juridica',
      Company_Name: 'Empresa X LTDA',
      Trading_Name: 'Empresa XXX',
      Number_Phone1: '1125252525',
      Number_Phone2: '11925252525',
      Date_Birth: '',
      IE: '0',
      IM: '0',
      Status_IE: 'Contribuinte de ICMS',
      ZipcodeP: '01212547',
      AddressP: 'Rua Etc. e Tal',
      Address_NumberP: '32',
      ComplementP: '8º Andar',
      Reference_PointP: 'Em frente à Ponte',
      NeighborhoodP: 'Bela Vista',
      CityP: 'São Paulo',
      StateP: 'SP',
      CountryP: 'Brasil',
      ZipcodeE: '01212547',
      AddressE: 'Rua Etc. e Tal',
      Address_NumberE: '32',
      ComplementE: '8º Andar',
      Reference_PointE: 'Em frente à Ponte',
      NeighborhoodE: 'Bela Vista',
      CityE: 'São Paulo',
      StateE: 'SP',
      CountryE: 'Brasil',
    },
    {
      Cpf_Cnpj: '12312312113',
      Type: 'Fisica',
      Company_Name: 'Fulano de Tal',
      Trading_Name: '',
      Number_Phone1: '1156565656',
      Number_Phone2: '11998989898',
      Date_Birth: '01/05/1996',
      IE: '0',
      IM: '0',
      Status_IE: 'Não Contribuinte',
      ZipcodeP: '01212547',
      AddressP: 'Rua Etc. e Tal',
      Address_NumberP: '10',
      ComplementP: '',
      Reference_PointP: '',
      NeighborhoodP: 'Bela Vista',
      CityP: 'São Paulo',
      StateP: 'SP',
      CountryP: 'Brasil',
      ZipcodeE: '01212547',
      AddressE: 'Rua Etc. e Tal',
      Address_NumberE: '10',
      ComplementE: '',
      Reference_PointE: '',
      NeighborhoodE: 'Bela Vista',
      CityE: 'São Paulo',
      StateE: 'SP',
      CountryE: 'Brasil',
    },
  ],
  services: [
    {
      Description: 'Troca de Mola Dianteira (PAR)',
      Price: 150,
      Hours_Expected: '05:00',
      Commission_Rate: 10,
      Additional_Information: 'Informações adicionais inseridas aqui',
    },
  ],
};

export const getModelsColumnsByImportType = (importType) =>
  modelsColumns[importType];

export const getModelsDataByImportType = (importType) => modelsData[importType];

export const jsonFromXlsxArrayOfRows = (array) => {
  const jsonItems = [];
  let item = {};

  const columnNames = array.shift();

  array.map((row) => {
    row.forEach((cell, i) => {
      item[columnNames[i]] = cell;
    });

    const itemValues = Object.values(item);
    const isValidItem = itemValues.some((item) => !!item);

    if (isValidItem) jsonItems.push(item);
    item = {};
  });

  return jsonItems;
};

const validateItems = (items, importType, itemsWithoutSerialize) => {
  let isValid = true;
  const structureErrorMessage =
    'Não foi possível realizar a importação pois a planilha não possui o mesmo layout definido:';

  const itemsWithoutSerializeLength = Object.keys(
    itemsWithoutSerialize[0]
  ).length;

  const itemsLength = Object.keys(items[0]).length;

  if (importType === 'providers') {
    items.map((provider) => {
      if (provider.statusIE) {
        const statusIE = [
          'Contribuinte de ICMS',
          'Contribuinte Isento',
          'Não Contribuinte',
        ];

        if (provider.statusIE) {
          if (!statusIE.includes(provider.statusIE)) {
            toastr.error(
              `${structureErrorMessage} indicador IE Inválido. O indicador deve ser 'Contribuinte de ICMS', 'Contribuinte Isento' ou 'Não Contribuinte'.`
            );
            return (isValid = false);
          }
        }
      }

      for (const columns in itemsWithoutSerialize[0]) {
        if (columns == '' || itemsWithoutSerializeLength !== itemsLength) {
          toastr.error(
            `Não foi possível realizar a importação pois a planilha não possui o mesmo layout definido. Verifique o layout e tente novamente.`
          );
          return (isValid = false);
        }
      }
    });
  }

  if (importType === 'services') {
    items.map((service) => {
      if (service.Price) {
        if (!(Number(service.Price) >= 0)) {
          toastr.error(`
          ${structureErrorMessage} preço do serviço inválido. O preço do serviço deve ser um número maior ou igual a 0.
        `);
          return (isValid = false);
        }
      }

      if (service.Commission_Rate) {
        if (!(Number(service.Commission_Rate) >= 0)) {
          toastr.error(`
          ${structureErrorMessage} preço do comissão inválido. O preço da comissão deve ser um número maior ou igual a 0.
        `);
          return (isValid = false);
        }
      }

      for (const columns in itemsWithoutSerialize[0]) {
        if (columns == '' || itemsWithoutSerializeLength !== itemsLength) {
          toastr.error(
            `Não foi possível realizar a importação pois a planilha não possui o mesmo layout definido. Verifique o layout e tente novamente.`
          );
          return (isValid = false);
        }
      }
    });
  }

  if (importType === 'products') {
    items.map((product) => {
      if (product.measureUnit) {
        const measureUnits = [
          'UNID',
          'PC',
          'M',
          'LITRO',
          'KG',
          'GRAMA',
          'CX',
          'CM',
          'KIT',
          'JG',
          'PAR',
        ];

        if (!measureUnits.includes(product.measureUnit)) {
          toastr.error(
            `${structureErrorMessage} unidade de medida inválida. Unidades válidas: ${measureUnits.join(
              ', '
            )}.`
          );
          return (isValid = false);
        }
      }

      if (product.inventory) {
        if (!(Number(product.inventory) >= 0)) {
          toastr.error(
            `${structureErrorMessage} estoque inválido. O estoque deve ser um número maior ou igual a 0.`
          );
          return (isValid = false);
        }
      }

      if (product.purchasePrice) {
        if (!(Number(product.purchasePrice) >= 0)) {
          toastr.error(
            `${structureErrorMessage} preço de compra inválido. O preço de compra deve ser um número maior ou igual a 0.`
          );
          return (isValid = false);
        }
      }

      if (product.margin) {
        if (!Number(product.margin) && Number(product.margin) !== 0) {
          toastr.error(
            `${structureErrorMessage} margem inválida. A margem deve ser um número.`
          );
          return (isValid = false);
        }
      }

      if (product.salePrice) {
        if (!(Number(product.salePrice) >= 0)) {
          toastr.error(
            `${structureErrorMessage} preço de venda inválido. O preço de venda deve ser um número maior que 0.`
          );
          return (isValid = false);
        }
      }

      if (product.comission) {
        if (!(Number(product.comission) >= 0)) {
          toastr.error(
            `${structureErrorMessage} comissão inválida. A comissão deve ser um número igual ou maior que 0.`
          );
          return (isValid = false);
        }
      }

      if (product.origin) {
        if (!(Number(product.origin) >= 0)) {
          toastr.error(
            `${structureErrorMessage} origem da mercadoria inválida. A origem da mercadoria deve ser um número maior ou igual a 0.`
          );
          return (isValid = false);
        }
      }

      if (product.ipi) {
        if (!(Number(product.ipi) >= 0)) {
          toastr.error(
            `${structureErrorMessage} %IPI inválido. O %IPI deve ser um número maior ou igual a 0.`
          );
          return (isValid = false);
        }
      }
    });
  }

  if (importType === 'customers') {
    items.map((customer) => {
      if (customer.Status_IE) {
        const statusIE = [
          'Contribuinte de ICMS',
          'Contribuinte Isento',
          'Não Contribuinte',
        ];

        if (customer.Date_Birth) {
          const originalDate = customer.Date_Birth.replaceAll('/', '');
          const year = originalDate.slice(4);
          const month = originalDate.slice(2, 4);
          const day = originalDate.slice(0, 2);

          const formatedDate = new Date(`${year}-${month}-${day}`);

          if (isNaN(formatedDate.getTime())) {
            toastr.error(
              `${structureErrorMessage} data de nascimento inválida. Utilize o formato DD/MM/AAAA`
            );
            return (isValid = false);
          }
        }

        if (customer.Status_IE) {
          if (!statusIE.includes(customer.Status_IE)) {
            toastr.error(
              `${structureErrorMessage} indicador IE Inválido. O indicador deve ser 'Contribuinte de ICMS', 'Contribuinte Isento' ou 'Não Contribuinte'.`
            );
            return (isValid = false);
          }
        }
      }
    });
  }

  return isValid;
};

const cathegorizeDataFilled = (items, importType) => {
  if (importType === 'providers') {
    const providersAux = [];

    return items.map((provider) => {
      if (
        provider.cpfCnpj &&
        provider.companyName &&
        provider.tradingName &&
        provider.email &&
        provider.Number_Phone1 &&
        provider.Number_Phone2 &&
        provider.IE &&
        provider.statusIE &&
        provider.Zipcode &&
        provider.Address &&
        provider.Address_Number &&
        provider.Neighborhood &&
        provider.Complement &&
        provider.Reference_Point &&
        provider.State &&
        provider.City
      ) {
        if (
          providersAux.filter(
            (c) => c.cpfCnpj === provider.cpfCnpj && !!c.cpfCnpj
          ).length !== 0
        ) {
          providersAux.push(provider);
          return { ...provider, dataFilled: 'duplicated' };
        }

        providersAux.push(provider);
        return { ...provider, dataFilled: 'all' };
      }

      if (provider.companyName && provider.Number_Phone2) {
        if (
          providersAux.filter(
            (c) => c.cpfCnpj === provider.cpfCnpj && !!c.cpfCnpj
          ).length !== 0
        ) {
          providersAux.push(provider);
          return { ...provider, dataFilled: 'duplicated' };
        }

        providersAux.push(provider);
        return { ...provider, dataFilled: 'required' };
      }

      providersAux.push(provider);
      return { ...provider, dataFilled: 'doesntHaveRequired' };
    });
  }

  if (importType === 'services') {
    return items.map((service) => {
      if (
        service.Description &&
        service.Price &&
        service.Hours_Expected &&
        service.Commission_Rate &&
        service.Additional_Information
      ) {
        return { ...service, dataFilled: 'all' };
      }

      if (service.Description && service.Price) {
        return { ...service, dataFilled: 'required' };
      }

      return { ...service, dataFilled: 'doesntHaveRequired' };
    });
  }

  if (importType === 'products') {
    return items.map((product) => {
      if (
        product.description &&
        product.measureUnit &&
        product.inventory &&
        product.location &&
        product.manufacturerRef &&
        product.barCode &&
        product.tributeBarCode &&
        product.purchasePrice &&
        product.margin &&
        product.salePrice &&
        product.minStock &&
        product.maxStock &&
        product.comission &&
        product.origin &&
        product.csosn &&
        product.cfop &&
        product.ncm &&
        product.cest &&
        product.pisExit &&
        product.cofinsExit &&
        product.pisEntry &&
        product.cofinsEntry &&
        product.ipi &&
        product.anp &&
        product.additionalInformation
      ) {
        return { ...product, dataFilled: 'all' };
      }

      if (
        product.description &&
        product.measureUnit &&
        product.purchasePrice &&
        product.margin &&
        product.salePrice
      ) {
        return { ...product, dataFilled: 'required' };
      }

      return { ...product, dataFilled: 'doesntHaveRequired' };
    });
  }

  if (importType === 'customers') {
    const customersAux = [];

    return items.map((customer) => {
      if (
        customer.Cpf_Cnpj &&
        customer.Company_Name &&
        customer.Trading_Name &&
        customer.Number_Phone1 &&
        customer.Number_Phone2 &&
        customer.Date_Birth &&
        customer.IE &&
        customer.IM &&
        customer.Status_IE &&
        customer.ZipcodeP &&
        customer.AddressP &&
        customer.Address_NumberP &&
        customer.ComplementP &&
        customer.Reference_PointP &&
        customer.NeighborhoodP &&
        customer.CityP &&
        customer.StateP &&
        customer.CountryP &&
        customer.ZipcodeE &&
        customer.AddressE &&
        customer.Address_NumberE &&
        customer.ComplementE &&
        customer.Reference_PointE &&
        customer.NeighborhoodE &&
        customer.CityE &&
        customer.StateE &&
        customer.CountryE
      ) {
        if (
          customersAux.filter(
            (c) => c.Cpf_Cnpj === customer.Cpf_Cnpj && !!c.Cpf_Cnpj
          ).length !== 0
        ) {
          customersAux.push(customer);
          return { ...customer, dataFilled: 'duplicated' };
        }

        customersAux.push(customer);
        return { ...customer, dataFilled: 'all' };
      }

      if (customer.Company_Name && customer.Number_Phone2) {
        if (
          customersAux.filter(
            (c) => c.Cpf_Cnpj === customer.Cpf_Cnpj && !!c.Cpf_Cnpj
          ).length !== 0
        ) {
          customersAux.push(customer);
          return { ...customer, dataFilled: 'duplicated' };
        }

        customersAux.push(customer);
        return { ...customer, dataFilled: 'required' };
      }

      customersAux.push(customer);
      return { ...customer, dataFilled: 'doesntHaveRequired' };
    });
  }
};

const changePropertiesName = (items, importType) => {
  if (importType === 'providers') {
    return items.map((provider) => ({
      cpfCnpj: provider['CPF / CNPJ'],
      type: provider['Tipo'],
      companyName: provider['Nome Completo / Razão Social'],
      tradingName: provider['Nome Fantasia'],
      email: provider['Email'],
      Number_Phone1: provider['Telefone Residencial / Comercial'],
      Number_Phone2: provider['Celular'],
      IE: provider['IE'],
      statusIE: provider['Indicador de IE'],
      Zipcode: provider['CEP'],
      Address: provider['Endereço'],
      Address_Number: provider['Número'],
      Neighborhood: provider['Bairro'],
      Complement: provider['Complemento'],
      Reference_Point: provider['Ponto de Referência'],
      State: provider['Estado'],
      City: provider['Cidade'],
    }));
  }

  if (importType === 'services') {
    return items.map((service) => ({
      Description: service['Descrição'],
      Price: service['Preço'],
      Hours_Expected: service['Horas Previstas'],
      Commission_Rate: service['% Comissão'],
      Additional_Information: service['Informações Complementares'],
    }));
  }

  if (importType === 'products') {
    return items.map((product) => ({
      description: product['Descrição'],
      measureUnit: product['Unidade de Medida'],
      inventory: product['Estoque'],
      location: product['Localização'],
      manufacturerRef: product['Ref. Fabricante'],
      barCode: product['Código de Barras (EAN)'],
      tributeBarCode: product['Código de Barras (EAN - Tributável)'],
      purchasePrice: product['Preço de Compra'],
      margin: product['Margem'],
      salePrice: product['Preço de venda'],
      minStock: product['Estoque Mín.'],
      maxStock: product['Estoque Máx.'],
      comission: product['%Comissão'],
      origin: product['Origem da mercadoria'],
      csosn: product['CSOSN/CST'],
      cfop: product['CFOP Dentro do Estado'],
      ncm: product['NCM'],
      cest: product['CEST'],
      pisExit: product['ST PIS (Saída)'],
      cofinsExit: product['ST COFINS (Saída)'],
      pisEntry: product['ST PIS (Entrada)'],
      cofinsEntry: product['ST COFINS (Entrada)'],
      ipi: product['%IPI'],
      anp: product['Código ANP'],
      additionalInformation: product['Informações Complementares'],
    }));
  }
  if (importType === 'customers') {
    return items.map((customer) => ({
      Cpf_Cnpj: customer['CPF/CNPJ'],
      Type: customer['Tipo'],
      Company_Name: customer['Nome Completo / Razão Social'],
      Trading_Name: customer['Nome Fantasia'],
      Number_Phone1: customer['Telefone Residencial'],
      Number_Phone2: customer['Celular'],
      Date_Birth: customer['Data de Nascimento'],
      IE: customer['Insc. Estadual'],
      IM: customer['Insc. Municipal'],
      Status_IE: customer['Indicador de IE'],
      ZipcodeP: customer['CEP (Principal)'],
      AddressP: customer['Rua (Principal)'],
      Address_NumberP: customer['Número (Principal)'],
      ComplementP: customer['Complemento (Principal)'],
      Reference_PointP: customer['Ponto de Referência (Principal)'],
      NeighborhoodP: customer['Bairro (Principal)'],
      CityP: customer['Cidade (Principal)'],
      StateP: customer['Estado (Principal)'],
      CountryP: customer['País (Principal)'],
      ZipcodeE: customer['CEP (Entrega)'],
      AddressE: customer['Rua (Entrega)'],
      Address_NumberE: customer['Número (Entrega)'],
      ComplementE: customer['Complemento (Entrega)'],
      Reference_PointE: customer['Ponto de Referência (Entrega)'],
      NeighborhoodE: customer['Bairro (Entrega)'],
      CityE: customer['Cidade (Entrega)'],
      StateE: customer['Estado (Entrega)'],
      CountryE: customer['País (Entrega)'],
    }));
  }
};

export const serializeItems = (items, importType) => {
  const serializedItems = changePropertiesName(items, importType);
  const validation = validateItems(serializedItems, importType, items);

  if (!validation) return false;

  const cathegorizedItems = cathegorizeDataFilled(serializedItems, importType);

  if (importType === 'services') {
    const cathegorizedItemsUpper = cathegorizedItems.map((p) =>
      p.Description.toUpperCase()
    );
    cathegorizedItems[0].Description = cathegorizedItemsUpper.toString();
    return cathegorizedItems;
  }

  return cathegorizedItems;
};
