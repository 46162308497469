import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import AlertModal from 'components/AlertModal/AlertModal'
import { change, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import Input from '../../../Clients/NewClient/FormClient/components/Input'
import RenderField from '../../../../../components/RenderField'
import FormModalProduct from 'client/components/FormModals/Product'

import { CFOPMask } from 'client/components/ToNormalize/ToNormalize'
import { PRODUCT_CSOSN, PRODUCT_CST } from '../../../../../utils/constants'
import { useAuth } from '../../../../../contexts/auth'
import { PISCOFINSMask, currencyMask } from '../../../../../utils/masks'

import productsRepository from '../../../../../repositories/Products'
import NFCesRepository from '../../../../../repositories/NFCes'

const ProductTaxModal = ({ index, CFOPs, onCancel }) => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isConfirmUpdateModalOpen, setIsConfirmUpdateModalOpen] =
    useState(false)
  const [productId, setProductId] = useState(0)
  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false)

  const {
    statusNFCe,
    editItem,
    pdvOnline,
    isTaxRegimeSimpleNational,
    companyPIS,
    companyCOFINS,
    status,
  } = useSelector((state) => state.form.cadastroNFCe.values)
  const { companyId } = useAuth()
  const dispatch = useDispatch()

  const getProduct = async () => {
    if (!editItem.productId) {
      const product = await productsRepository.search({
        code: editItem.code,
        companyId,
      })
      return product
    } else {
      const product = await productsRepository.getById(editItem.productId)
      return product
    }
  }

  const handleOpenProductModal = async () => {
    try {
      const product = await getProduct()
      await setProductId(product.id)
      setIsProductModalOpen(true)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar o Produto. Por favor, tente novamente'
      )
    }
  }

  const handlePullProductInfo = () => {
    setIsProductModalOpen(false)
    handleImportProductData()
  }

  function getPIS() {
    const { unitValue, stPIS, quantity } = editItem

    if (stPIS === '01' || stPIS === '02') {
      const value = quantity * unitValue * (companyPIS / 100)
      return value
    }

    return 0
  }

  function getCOFINS() {
    const { unitValue, stCOFINS, quantity } = editItem

    if (stCOFINS === '01' || stCOFINS === '02') {
      const value = quantity * unitValue * (companyCOFINS / 100)
      return value
    }

    return 0
  }

  const handleImportProductData = async () => {
    setLoading(true)
    try {
      const product = await getProduct()
      const {
        PIS_Exit,
        COFINS_Exit,
        Origin,
        NCM_Code,
        CEST_Code,
        Bar_Code,
        Tribute_BarCode,
        Unit_Type,
        CFOP_InState_Code,
        CSOSN,
        CST,
        NcmNbs,
      } = product

      const valuePIS = getPIS()
      const valuesCOFINS = getCOFINS()

      const item = {
        ...editItem,
        unitType: Unit_Type,
        NCM: NcmNbs ? NcmNbs.code : NCM_Code,
        CEST:
          NcmNbs?.CestCompany[0] && NcmNbs?.CestCompany[0]?.CEST
            ? NcmNbs.CestCompany[0].CEST
            : CEST_Code,
        barCode: Bar_Code,
        tributeBarCode: Tribute_BarCode,
        origin: Origin,
        stPIS: PIS_Exit,
        stCOFINS: COFINS_Exit,
        valuePIS,
        valuesCOFINS,
        CSOSN,
        CST,
        CFOP: CFOP_InState_Code,
      }

      dispatch(change('cadastroNFCe', 'editItem', item))
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar o Produto. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  const handleConfirmUpdate = () => {
    setIsConfirmUpdateModalOpen(false)
    return validationsForUpdate()
  }

  const validationsForUpdate = () => {
    const { CFOP, CSOSN } = editItem

    if (!CFOP || !CSOSN) {
      return toastr.warning(
        `Defina uma ${
          isTaxRegimeSimpleNational ? 'CSOSN' : 'CST'
        } e CFOP para salvar.`
      )
    }

    updateData()
  }

  const updateData = async () => {
    setLoading(true)
    try {
      await NFCesRepository.updateItem(editItem.id, {
        CFOP: editItem.CFOP,
        CSOSN: editItem.CSOSN,
        FCP: editItem.FCP,
        ICMS: editItem.ICMS,
        NCM: editItem.NCM,
        valuePIS: editItem.valuePIS,
        valuesCOFINS: editItem.valuesCOFINS,
        barCode: editItem.barCode,
        tributeBarCode: editItem.tributeBarCode,
        CEST: editItem.CEST,
        unitType: editItem.unitType,
        origin: editItem.origin,
        stPIS: editItem.stPIS,
        stCOFINS: editItem.stCOFINS,
      })

      await dispatch(change('cadastroNFCe', `NFCeItems[${index}]`, editItem))
      onCancel()
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao salvar as informações. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        dialogClassName="product-tax-modal-wrapper"
        show
        onHide={onCancel}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Tributos do produto <strong>{editItem?.description}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!pdvOnline && (
            <span onClick={handleOpenProductModal} className="link">
              Visualizar Cadastro do Produto
            </span>
          )}
          <main>
            <Field
              name={`editItem.code`}
              label="Código Interno"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.unitType`}
              label="Unidade de Medida"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.NCM`}
              label="NCM"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.CEST`}
              label="CEST"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.barCode`}
              label="Código de Barras (EAN)"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.tributeBarCode`}
              label="Código de Barras (EAN - Tributável)"
              component={Input}
              disabled
            />
            <Field
              name={`editItem.origin`}
              label="Origem da Mercadoria"
              component={Input}
              disabled
            />
            <Field
              name="editItem.CSOSN"
              label={isTaxRegimeSimpleNational ? 'CSOSN' : 'CST'}
              component={RenderField}
              as="select"
            >
              <option value="">Selecione</option>
              {isTaxRegimeSimpleNational
                ? PRODUCT_CSOSN.map((CSOSN) => (
                    <option key={CSOSN} value={CSOSN}>
                      {CSOSN}
                    </option>
                  ))
                : PRODUCT_CST.map((CST) => (
                    <option key={CST} value={CST}>
                      {CST}
                    </option>
                  ))}
            </Field>
            <Field
              name={`editItem.CFOP`}
              label="CFOP"
              component={Input}
              normalize={CFOPMask}
              list="CFOP_list"
              disabled={
                statusNFCe === 'Aberta' || status === 'Aberta c/ Erro'
                  ? ''
                  : 'disabled'
              }
            />
            <datalist id="CFOP_list">
              {CFOPs.map((c) => {
                if (
                  String(c.CFOP).charAt([0]) === '5' &&
                  c.type.toLowerCase().includes('saída')
                ) {
                  return (
                    <option key={c.id} value={c.CFOP}>
                      {c.CFOP}
                    </option>
                  )
                }
              })}
            </datalist>
            <section>
              <Field
                name={`editItem.ICMS`}
                label="% ICMS"
                component={Input}
                disabled={
                  statusNFCe === 'Aberta' || status === 'Aberta c/ Erro'
                    ? ''
                    : 'disabled'
                }
                {...PISCOFINSMask}
              />
              <Field
                name={`editItem.FCP`}
                label="% FCP"
                component={Input}
                disabled={
                  statusNFCe === 'Aberta' || status === 'Aberta c/ Erro'
                    ? ''
                    : 'disabled'
                }
                {...PISCOFINSMask}
              />
            </section>
            <section>
              <Field
                name={`editItem.stPIS`}
                label="ST PIS"
                component={Input}
                disabled
              />
              <Field
                name={`editItem.valuePIS`}
                label="Valor de PIS"
                component={Input}
                disabled
                {...currencyMask}
              />
            </section>
            <section>
              <Field
                name={`editItem.stCOFINS`}
                label="ST COFINS"
                component={Input}
                disabled
              />
              <Field
                name={`editItem.valuesCOFINS`}
                label="Valor de COFINS"
                component={Input}
                disabled
                {...currencyMask}
              />
            </section>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            bsStyle="primary"
            onClick={handleImportProductData}
            fill
          >
            Importar dados do cadastro
          </Button>
          <Button
            disabled={loading}
            bsStyle="danger"
            onClick={onCancel}
            name="Cancelar"
            fill
          >
            Voltar
          </Button>
          <Button
            disabled={loading}
            bsStyle="info"
            name="Salvar"
            onClick={() => setIsConfirmUpdateModalOpen(true)}
            fill
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmCloseModalOpen}
        message="Deseja voltar para a tela da nota fiscal? Qualquer alteração realizada não será salva."
        onCancel={() => setIsConfirmCloseModalOpen(false)}
        onSubmit={onCancel}
        onHide={() => setIsConfirmCloseModalOpen(false)}
      />

      <AlertModal
        show={isConfirmUpdateModalOpen}
        message="Deseja salvar as alterações realizadas?"
        onCancel={() => setIsConfirmUpdateModalOpen(false)}
        onSubmit={handleConfirmUpdate}
        onHide={() => setIsConfirmUpdateModalOpen(false)}
      />

      {isProductModalOpen && (
        <FormModalProduct
          productId={productId}
          onCancel={() => setIsProductModalOpen(false)}
          onSubmit={handlePullProductInfo}
        />
      )}
    </>
  )
}

export default ProductTaxModal
