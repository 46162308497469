import React, { memo } from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { currency } from 'client/components/ToNormalize/ToNormalize';

const TableByFormPayment = ({ data, loading }) => {
  return (
    <>
      <h4><strong>Vendas por Forma de Pagamento (R$)</strong></h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: "Descrição",
            accessor: "description"
          },
          {
            Header: "Valor",
            accessor: "amount",
            Cell: props => currency(props.value),
            width: 100
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Nenhuma informação encontrada'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </>
  )
}

export default memo(TableByFormPayment)
