import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import HomeWarranty from './Warranty';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Warranty = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Produtos/Serviços', 'Garantias']}
            path={['home', null, null, 'Garantia']}
          />
          <HomeWarranty />
        </Row>
      </Grid>
    </div>
  );
};

export default Warranty;
