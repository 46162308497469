import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import PendingCustomersSuppliersPDF from './PendingCustomersSuppliersPDF'
import pendingCustomersSuppliersRepository from 'repositories/PendingCustomersSuppliers'

const PendingCustomersSuppliers = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])
  const [dateType, setDateType] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [type, setType] = useState('')
  const [totalsPerPerson, setTotalsPerPerson] = useState({})
  const [totalizers, setTotalizers] = useState({})
  const [personId, setPersonId] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId')
      if (!companyId) {
        setHasError(true)
        setLoading(false)
      }
      const dateType = searchParams.get('dateType')
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const type = searchParams.get('type')
      const personId = searchParams.get('personId')
      setPersonId(personId)

      const data = await loadReport(
        companyId,
        dateType,
        initialDate,
        finalDate,
        type,
        personId
      )

      setType(type)
      setDateType(dateType)
      setInitialDate(initialDate)
      setFinalDate(finalDate)
      setDataReport(data.reportData)
      setTotalsPerPerson(data.totalsPerPerson)
      setTotalizers(data.totalizers)
      setLoading(false)
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (
    companyId,
    dateType,
    initialDate,
    finalDate,
    type,
    personId
  ) => {
    try {
      const params = {
        companyId,
        dateType,
        initialDate,
        finalDate,
        type,
        personId,
      }

      const data =
        await pendingCustomersSuppliersRepository.getPendingCustomersSuppliers(
          companyId,
          params
        )

      return data
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <PendingCustomersSuppliersPDF
            totalsPerPerson={totalsPerPerson}
            responseData={dataReport}
            dateType={dateType}
            initialDate={initialDate}
            finalDate={finalDate}
            type={type}
            totalizers={totalizers}
            personId={personId}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default PendingCustomersSuppliers
