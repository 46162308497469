import React from 'react'
import { Col } from 'react-bootstrap'
import { reduxForm, change, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import Toggle from 'react-toggle'

import Select from '../../../Clients/NewClient/FormClient/components/Select'
import TextArea from '../../../Clients/NewClient/FormClient/components/TextArea'

import PF from './PF'
import PJ from './PJ'

import { descriptionWithAltSign } from '../../../../components/ToNormalize/ToNormalize'

function DadosCadastrais({ onSubmit }) {
  const {
    cadastroTransportadora: {
      values: { Status, Type, id },
    },
  } = useSelector((state) => state.form)
  const dispatch = useDispatch()

  function handleType() {
    dispatch([
      change('cadastroTransportadora', 'Cpf_Cnpj', ''),
      change('cadastroTransportadora', 'Name', ''),
      change('cadastroTransportadora', 'Email', ''),
      change('cadastroTransportadora', 'Number_Phone1', ''),
      change('cadastroTransportadora', 'Number_Phone2', ''),
      change('cadastroTransportadora', 'Contact', ''),
      change('cadastroTransportadora', 'IE', ''),
    ])
  }

  return (
    <div>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={2} md={2} sm={3} xs={12}>
          <label>Tipo de Pessoa</label>
          <Field
            name="Type"
            component={Select}
            value={Type}
            onChange={handleType}
            disabled={!!id}
          >
            <option value="Fisica">Física</option>
            <option value="Juridica">Jurídica</option>
          </Field>
        </Col>
        <Col lg={1} md={2} sm={1} xs={12}>
          <label>
            Status<span style={{ color: 'red' }}>*</span>
          </label>
          <div id="toggle-status">
            <Toggle
              checked={Status}
              name="Status"
              onChange={() =>
                dispatch(change('cadastroTransportadora', 'Status', !Status))
              }
            />
          </div>
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        {Type === 'Fisica' ? <PF edit={!!id} /> : <PJ edit={!!id} />}
      </Col>

      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="form-client-dadosb-footer"
      >
        <Col lg={6} md={6} sm={4} xs={12}>
          <label>Observações (máximo de 500 caracteres)</label>
          <Field
            name="Observation"
            component={TextArea}
            normalize={descriptionWithAltSign}
            maxLength="500"
          />
        </Col>
        <Col>
          <button className="btn-green" type="submit" onClick={onSubmit}>
            Prosseguir
          </button>
        </Col>
      </Col>
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'cadastroTransportadora',
})(DadosCadastrais)
