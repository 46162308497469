import React, { useEffect, useState } from 'react'
import { reduxForm, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import CustomButton from 'client/components/CustomButton/CustomButton'

import MainData from './MainData'
import { isValid, differenceInDays } from 'date-fns'
import AlertModal from 'components/AlertModal/AlertModal'

const GenerateCurrentBillForm = ({ handleSubmit, onCancel, pristine }) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false)

  const dispatch = useDispatch()
  const {
    initialDate,
    finalDate,
    daysBetweenInstallments,
    installmentQuantity,
    valueByInstallment
  } = useSelector(state => state.form.generateCurrentBill.values)

  useEffect(() => {
    if (isValid(new Date(initialDate)) && isValid(new Date(finalDate))) handleCalculateInstallmentQuantity()
  }, [initialDate, finalDate, daysBetweenInstallments])

  useEffect(() => {
    if (!!installmentQuantity && !!valueByInstallment) handleCalculateTotal()
  }, [installmentQuantity, valueByInstallment])

  const handleCalculateInstallmentQuantity = () => {
    const days = differenceInDays(new Date(finalDate), new Date(initialDate)) + 1
    const quantity = parseInt(Math.ceil(days / (daysBetweenInstallments)))
    dispatch(change('generateCurrentBill', 'installmentQuantity', quantity))
  }

  const handleCalculateTotal = () => {
    const value = installmentQuantity * valueByInstallment
    dispatch(change('generateCurrentBill', 'total', value))
  }

  const handleCancel = () => {
    if (pristine) return onCancel()
    setIsConfirmCancelModalOpen(true)
  }

  return (
    <>
      <Modal
        show
        animation
        dialogClassName="modal-70w modal-generate-current-bill"
      >
        <Modal.Header>
          <Modal.Title><strong>Conta Recorrente</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MainData />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            bsStyle="danger"
            fill
            onClick={handleCancel}>Cancelar</CustomButton>
          <CustomButton
            bsStyle="info"
            fill
            onClick={handleSubmit}>Gerar</CustomButton>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmCancelModalOpen}
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        message="Deseja cancelar o pagamento recorrente?"
        onSubmit={onCancel}
      />
    </>
  )
}
export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'generateCurrentBill'
})(GenerateCurrentBillForm)