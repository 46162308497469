import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col } from "react-bootstrap";
import Toggle from "react-toggle";

import CardForm from '../../../../components/CardForm';
import AlertModal from '../../../../../components/AlertModal/AlertModal';
import FormSubmitButtons from '../../../../components/FormSubmitButtons';

import lossReasonsRepository from "../../../../../repositories/LossReasons";

export default function FormNewLossReason({
  lossReasonId,
  onSubmit,
  edit,
  loading,
  onCancel,
  register,
  watch,
  setValue,
  handleSubmit,
  isDirty
}) {
  const [basicData, setBasicData] = useState(true)
  const [cancelModal, setCancelModal] = useState(false)

  const history = useHistory()

  function handleToggle() {
    const currStatus = watch('isActive')
    setValue('isActive', !currStatus)
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <CardForm
        show={basicData}
        title='Dados do Motivo'
        onClick={() => setBasicData(!basicData)}
      >

        <Col lg={4} md={7} sm={8} xs={12}>
          <label htmlFor="description">Descrição<span style={{ color: "red" }}>*</span></label>
          <input
            type="text"
            {...register('description')}
            className="form-control"
            name="description"
            id="description"
            maxLength="70"
          />
        </Col>

        <Col lg={1} md={2} sm={1} xs={12}>
          <label>
            Status:
          </label>
          <div id="toggle-status">
            <Toggle
              id="isActive"
              name="isActive"
              checked={watch('isActive')}
              onChange={() => handleToggle()}
            />
          </div>
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <label>Observações:</label>
          <textarea
            type="text"
            {...register('observation')}
            className="form-control"
            name="observation"
            id="observation"
            maxLength="250"
          />
        </Col>
      </CardForm>

      <FormSubmitButtons
        loading={loading}
        onCancel={() => {
          if (isDirty) return setCancelModal(true)
          history.push('/client/loss-reasons')
        }}
        backLabel={lossReasonId}
        hasId={lossReasonId}
        onSubmit={handleSubmit(onSubmit)}
      />

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          subtitle={!lossReasonId ? 'Você tem certeza que deseja cancelar o cadastro de motivo de perda?' : null}
          message={!lossReasonId ? `O processo é irreversível e sem esse cadastro, você não poderá utilizá-lo ao cancelar algum orçamento. O cadastro poderá ser feito novamente mais tarde.` :
          `Deseja cancelar a edição? Nenhuma alteração será salva.`}
          onSubmit={onCancel}
        />
      )}
    </form>
  )
}