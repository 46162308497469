import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import profilesRepository from '../../../../../repositories/Profiles';
import constants from '../../../../../utils/constants';
import { useHistory } from 'react-router-dom';
import FormProfile from './FormProfile';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useRouter } from 'v2/hooks/useRouter';

const initialValues = {
  isActive: true,
};

const BundleFormProfile = ({ profileId, modal, handleModal, hideModal }) => {
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const { navigateToClientPage } = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileId) loadProfile(profileId);
  }, [profileId]);

  const loadProfile = async (id) => {
    setLoading(true);
    try {
      const { data } = await profilesRepository.getById(id);
      dispatch(initialize('formProfile', data));
    } catch (err) {
      toastr.error(
        err.response?.data?.message ||
          'Ocorreu algum problema ao carregar o perfil.',
        'Por favor, tente novamente mais tarde.'
      );
    } finally {
      setLoading(false);
    }
  };

  const submit = (values) => {
    if (!values.description)
      return toastr.warning(
        'Preenche todos os campos obrigatórios para salvar o seu cadastro de perfil.'
      );

    if (profileId) {
      return update(values);
    }

    create(values);
  };

  const create = async (values) => {
    setLoading(true);
    try {
      const { data } = await profilesRepository.create({
        ...values,
        companyId,
      });
      if (modal) {
        return handleModal(data.id);
      }

      const profileAccessPermissions = encrypt(data.id, '@OS-dig:profileId');
      navigateToClientPage(
        'ACCESS_PROFILE',
        'FORM_EDIT_RAW',
        profileAccessPermissions
      );
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao registrar perfil. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    setLoading(true);
    try {
      await profilesRepository.update(values);
      history.push(constants.ROUTES.PROFILES);
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao atualizar perfil. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProfile
      modal={modal}
      hideModal={hideModal}
      onSubmit={submit}
      initialValues={initialValues}
      loading={loading}
      profileId={profileId}
    />
  );
};

export default BundleFormProfile;
