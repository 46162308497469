import api from '../services/api'
import config from '../config'

const show = () => {
  return api.get(config.endpoint + 'permissions')
}

const savePermissions = (profileId, permissionsToAdd, permissionsToDelete) => {
  return api.post(config.endpoint + 'profile-access-permissions', { profileId, permissionsToAdd, permissionsToDelete })
}

export default {
  show,
  savePermissions
}