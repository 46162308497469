import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #d4d1cd;

  display: flex;
  align-items: center;
  flex-flow: column wrap;

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
`;

export const OSDigLogoContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px;

  img {
    width: '200px';
    height: '74px';
    borderradius: '10px';
    padding: '5px';
    max-height: 74px;
    max-width: 200px;

    src: ${({ src }) => src};
    alt: ${({ alt }) => alt};
  }
`;

export const BlueBar = styled.div`
  position: relative;
  background-color: #1d7391;
  height: 15px;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 5px;
  width: 80%;
  padding: 5px;

  box-shadow: 5px 10px 18px #d6d6d6;
  background-color: #fff;
`;

export const SectionTitle = styled.h3`
  color: #1d7391;
  font-weight: bold;
  font-size: 18px;
`;

export const HeaderContainer = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: 'logo company company company';

  img {
    grid-area: logo;
    max-width: 80%;
    max-height: 80%;
  }

  .company-info {
    grid-area: company;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProviderContainer = styled.div`
  display: grid;
  width: 100%;
  font-size: 12px !important;

  box-sizing: border-box;
  margin: 5px 10px 5px 10px;

  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'header header'
    'data address';

  .provider-title {
    margin: 0px 0;

    font-size: 14px;
  }

  .provider-address {
    grid-area: address;
  }

  .provider-header {
    grid-area: header;
  }

  .provider-data {
    grid-area: data;
    margin-right: 10px;
    border-right: 1px solid #d6d6d6;
  }
`;

export const QuotationDataContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  margin: 5px 10px 5px 10px;

  .quotation-products {
    margin: 20px 0;
  }
`;

export const Title = styled.h5`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  margin: 10px 0;
  justify-content: flex-end;
  gap: 10px;
`;

export const Footer = styled.div`
  width: 100%;
`;
