import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import trayPlansRepository from 'repositories/TrayPlans'
import RenderField from 'components/RenderField'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import TokenModal from './TokenModal'

import './styles.css'

const TrayConfigForm = ({ onSubmit, pristine, loading }) => {
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)
  const [trayPlans, setTrayPlans] = useState([])

  useEffect(() => {
    loadTrayPlans()
  }, [])

  const loadTrayPlans = async () => {
    try {
      const { data: trayPlans } = await trayPlansRepository.index()
      setTrayPlans(trayPlans)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Col xs={12} sm={12} md={12} lg={12} className='no-w-padding'>
        <Col xs={12} sm={6} md={5} lg={5} className='token-area no-w-padding'>
          <TrayTokenStatusMessage />

          <Field
            label='Token de Integração:'
            name='code'
            required
            component={RenderField}
          />
          <span
            className='token-information-hiperlink'
            onClick={() => setIsTokenModalOpen(true)}
          >
            Onde encontro o token?
          </span>
          <Field
            label='URL da Loja:'
            name='trayUrl'
            required
            component={RenderField}
          />
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            name='trayPlanId'
            label='Plano Tray:'
            as='select'
            required
            component={RenderField}
          >
            <option value=''>Selecione</option>
            {trayPlans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Button
            bsStyle='info'
            name='Salvar'
            fill
            style={{ marginTop: 36 }}
            disabled={pristine}
            onClick={onSubmit}
          >
            <span
              className={
                loading && 'fa fa-spinner fa-pulse fa-1x update-spinner'
              }
            ></span>
            Salvar
          </Button>
        </Col>
      </Col>
      <TokenModal
        show={isTokenModalOpen}
        onCancel={() => setIsTokenModalOpen(false)}
      />
    </div>
  )
}

const TrayTokenStatusMessage = () => {
  const { trayTokenStatus } = useSelector((state) => state.form.tray.values)

  const trayTokenStatusMessages = {
    0: (
      <div className='tray-token-status-message'>
        <strong>Token inválido</strong>
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: '#d9534f', width: 20, height: 20 }}
        />
      </div>
    ),
    1: (
      <div className='tray-token-status-message'>
        <strong>Token verificado e aprovado</strong>
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: '#72BB53', width: 20, height: 20 }}
        />
      </div>
    ),
    2: (
      <div className='tray-token-status-message'>
        <strong>Token não pertence a empresa</strong>
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: '#d9534f', width: 20, height: 20 }}
        />
      </div>
    ),
  }

  return trayTokenStatusMessages[trayTokenStatus] || null
}

export default TrayConfigForm
