import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { format } from 'date-fns';

import SearchInput from 'components/SearchInput';
import { SelectBranding } from 'v2/components/Select';
import { getInvoiceByClientInfoToExport, xlsColumns } from './excelHelpers';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import adminsRepository from '../../../repositories/Admins';
import { toastr } from 'react-redux-toastr';
import useFilters from '../../../hooks/useFilters';
import { getDateOnlyFromDate } from '../../../utils/dateHelpers';

import DashboardCard from '../home/DashboardCard';

export default function InvoiceByCustomer() {
  const { dateFilter } = useFilters();
  const [query, setQuery] = useState('');
  const { initialDate, setInitialDate, finalDate, setFinalDate } = dateFilter;
  const [isProcessEnabled, setIsProcessEnabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [clientsFiltered, setClientsFiltered] = useState([]);
  const [clientsInfoToExport, setClientsInfoToExport] = useState([]);
  const [clientsTotals, setClientsTotals] = useState({
    clients: 0,
    nfce: 0,
    nfe: 0,
    nfse: 0,
    sat: 0,
  });

  const [clientsGeneralIndicators, setClientsGeneralIndicators] = useState({
    nfce: 0,
    nfe: 0,
    nfse: 0,
    sat: 0,
  });

  const [brandingId, setBrandingId] = useState({
    value: '',
    label: 'Todos',
  });

  useEffect(() => {
    loadClients();
  }, []);

  useEffect(() => {
    const clientsInfoToExport = getInvoiceByClientInfoToExport(clients);
    setClientsInfoToExport(clientsInfoToExport);
    handleClientsTotals();
  }, [clients]);

  const loadClients = async () => {
    try {
      const clients = await adminsRepository.getAllTaxByClientes();
      await setClientsTotals({
        clients: clients.clients,
        nfce: clients.nfce,
        nfe: clients.nfe,
        nfse: clients.nfSe,
        sat: clients.sat,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os clientes. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    if (!isProcessEnabled) {
      setIsProcessEnabled(true);
    }
  }, [initialDate, finalDate, brandingId]);

  async function handleLoadClients() {
    setLoading(true);
    setQuery('');
    setBrandingId({
      value: '',
      label: 'Todos',
    });

    try {
      const clients = await adminsRepository.getInvoiceClientsReport({
        initialDate,
        finalDate,
      });

      setClients(clients);
      setClientsFiltered(clients);
      setLoading(false);
      setIsProcessEnabled(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os clientes. Por favor, tente novamente'
      );
    }
  }

  function handleClientsTotals() {
    const clientTotals = {
      nfce: 0,
      nfe: 0,
      nfse: 0,
      sat: 0,
    };
    for (let client of clients) {
      clientTotals.nfce += client.nfce;
      clientTotals.nfe += client.nfe;
      clientTotals.nfse += client.nfse;
      clientTotals.sat += client.sat;
    }
    setClientsGeneralIndicators(clientTotals);
  }
  useEffect(() => {
    if (query === '' && brandingId.value === '') {
      setClientsFiltered(clients);
      return;
    }
    const clientsFilteredSerializer = clients.filter((client) => {
      const lowerCaseQuery = query.toLowerCase();
      const formattedCpfCnpj = client.Cpf_Cnpj.replace(/\D/g, '');
      const matchesQuery =
        client.Company_Name.toLowerCase().includes(lowerCaseQuery) ||
        formattedCpfCnpj.includes(lowerCaseQuery);
      const formattedBrandingId = client.product === 'OSDigital' ? 1 : 2;

      const matchesBrandingId =
        brandingId.value === '' ||
        formattedBrandingId === parseInt(brandingId.value);

      return matchesQuery && matchesBrandingId;
    });

    setClientsFiltered(clientsFilteredSerializer);
  }, [query, brandingId, clients]);

  return (
    <div id="invoices-by-client-report">
      <header>
        <DashboardCard
          title={
            <span style={{ color: '#009688' }}>Total de cliente (PJ)</span>
          }
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.clients.OSD}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.clients.WM}
                </strong>
              </strong>
            </div>
          }
          value={clientsTotals.clients.total}
          color="#009688"
        />
        <DashboardCard
          title={<span style={{ color: '#ffa834' }}>Possui NF-e</span>}
          value={clientsTotals.nfe.total}
          color="#ffa834"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfe.OSD}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfe.WM}
                </strong>
              </strong>
            </div>
          }
        />
        <DashboardCard
          title={<span style={{ color: '#337ab7' }}>Possui NFS-e</span>}
          value={clientsTotals.nfse.total}
          color="#337ab7"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfse.OSD}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfse.WM}
                </strong>
              </strong>
            </div>
          }
        />
        <DashboardCard
          title={<span style={{ color: '#d43f3a' }}>Possui NFC-e</span>}
          value={clientsTotals.nfce.total}
          color="#d43f3a"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfce.OSD}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.nfce.WM}
                </strong>
              </strong>
            </div>
          }
        />
        <DashboardCard
          title={<span style={{ color: '#7e2199' }}>Possui SAT</span>}
          value={clientsTotals.sat.total}
          color="#7e2199"
          handleModal={null}
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.sat.OSD}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsTotals.sat.WM}
                </strong>
              </strong>
            </div>
          }
        />
      </header>
      <main>
        <header>
          <fieldset className="input">
            <SearchInput
              placeholder="Pesquisa por CNPJ ou Razão Social"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="initialDate">Emissão Inicial:</label>
            <input
              className="form-control foco-input"
              id="initialDate"
              type="date"
              max={
                finalDate
                  ? format(
                      new Date(getDateOnlyFromDate(finalDate)),
                      'yyyy-MM-dd'
                    )
                  : format(new Date(), 'yyyy-MM-dd')
              }
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="finalDate">Emissão Final:</label>
            <input
              className="form-control foco-input"
              id="finalDate"
              type="date"
              min={
                initialDate
                  ? format(
                      new Date(getDateOnlyFromDate(initialDate)),
                      'yyyy-MM-dd'
                    )
                  : undefined
              }
              max={format(new Date(), 'yyyy-MM-dd')}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <SelectBranding
              fontSize={14}
              variant="column"
              height={35}
              value={brandingId}
              onChange={(e) => setBrandingId(e)}
              showAllOption
            />
          </fieldset>

          <fieldset>
            <button
              disabled={
                loading || !isProcessEnabled || !initialDate || !finalDate
              }
              className="btn btn-export"
              onClick={handleLoadClients}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              {loading ? 'Carregando' : 'Processar'}
            </button>
          </fieldset>
          <fieldset>
            <DownloadXlsButton
              archiveName={`Notas-Fiscais-por-cliente-${format(
                new Date(),
                'dd-MM-yyyy'
              )}`}
              data={clientsInfoToExport}
              className="btn btn-export"
              disabled={loading || !clientsInfoToExport.length}
              columns={xlsColumns}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </fieldset>
        </header>
        <div>
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            data={clientsFiltered}
            columns={[
              {
                Header: 'CNPJ',
                accessor: 'Cpf_Cnpj',
                width: 140,
                Cell: (props) =>
                  props.value.length === 11
                    ? cpfMask(props.value)
                    : cnpjMask(props.value),
              },
              {
                Header: 'Razão Social',
                accessor: 'Company_Name',
                minWidth: 300,
              },
              {
                Header: 'NF-e',
                accessor: 'nfe',
                width: 80,
              },
              {
                Header: 'NFC-e',
                accessor: 'nfce',
                width: 80,
              },
              {
                Header: 'NFS-e',
                accessor: 'nfse',
                width: 80,
              },
              {
                Header: 'SAT',
                accessor: 'sat',
                width: 80,
              },
            ]}
            defaultPageSize={10}
            loading={loading}
            showPagination={true}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            defaultSorted={[
              {
                id: 'Company_Name',
                desc: false,
              },
            ]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Nenhum cliente encontrado"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
          <aside>
            <DashboardCard
              className="card-aside"
              title={<span style={{ color: '#337ab7' }}>NFS-Emitidas</span>}
              value={clientsGeneralIndicators.nfse}
              color="#337ab7"
            />
            <DashboardCard
              className="card-aside"
              title={<span style={{ color: '#ffa834' }}>NF-e Emitidas</span>}
              value={clientsGeneralIndicators.nfe}
              color="#ffa834"
            />
            <DashboardCard
              className="card-aside"
              title={<span style={{ color: '#d43f3a' }}>NFC-e Emitidas</span>}
              value={clientsGeneralIndicators.nfce}
              color="#d43f3a"
            />
            <DashboardCard
              className="card-aside"
              title={<span style={{ color: '#7e2199' }}>SAT Emitidos</span>}
              value={clientsGeneralIndicators.sat}
              color="#7e2199"
            />
          </aside>
        </div>
      </main>
    </div>
  );
}
