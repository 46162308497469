import React from 'react';
import Dropzone from 'react-dropzone';
import UploadContainer from './UploadContainer';
import { toastr } from 'react-redux-toastr';

export default function VideoUploadDropzone(props) {
  const handleUpload = (files) => {
    if (props.totalVideos) {
      toastr.warning(
        'Importação não realizada',
        'Você atingiu o limite de vídeos permitidos.'
      );
      return false;
    }
    return props.onUpload(files);
  };
  return (
    <Dropzone
      accept="video/*"
      onDropAccepted={handleUpload}
      disabled={props.disabled}
    >
      {(dropzoneProps) => (
        <UploadContainer
          {...dropzoneProps}
          {...props}
          onClick={props.onClick}
          disabled={props.disabled}
        />
      )}
    </Dropzone>
  );
}
