import styled from 'styled-components';
import CurrencyInput from 'react-currency-input';

export const InputCurrencyWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '40px')};
  display: flex;
  flex-direction: column;
`;

export const InputCurrencyStyled = styled(CurrencyInput)`
  width: 100%;
  height: 100%;
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};
  padding: 0 8px;

  &:focus {
    outline: 1px solid
      ${(props) =>
        props.borderColorFocus
          ? props.borderColorFocus
          : props.theme.colors.branding1000} !important;
  }
`;
