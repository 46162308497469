import {
  faCirclePlus,
  faEquals,
  faImages,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from 'components/SearchInput';
import React, { useState } from 'react';
import ReactTable from 'react-table';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { Text } from 'v2/components/Text';
import { SelectMulti } from 'v2/components/Select/Multi';
import { toastr } from 'react-redux-toastr';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import SearchSimilarityModal from './SearchSimilarityModal';

const SearchModalTableSimilarity = ({
  loading,
  data,
  totalPages,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  brandId,
  setBrandId,
  productQuery,
  vehicleModel,
  handleChangeProductQuery,
  handleChangeVehicleQuery,
  handleAddItem,
  allowPromotionalPrices,
  searchType,
  setSearchType,
  kitSearchQuery,
  handleChangeQuerySearchKit,
  pagesKits,
  kits,
  brands,
  yearVehicle,
  handleChangeYear,
  handleOpenProductImageModal,
  hideSimilarityIcon,
  hideSearchTypeSelect,
}) => {
  const { isWorkmotor, isPlanPrime } = usePlanSignatureContext();

  return (
    <div id="search-modal-content">
      <div className="pdv__search-modal-input">
        {searchType === 'Produtos' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <SearchInput
              style={{ width: 500 }}
              placeholder="Pesquisa por Descrição, Código, Ref. Fabricante, Cód. de Barras e Apelido"
              value={productQuery}
              onChange={(e) => handleChangeProductQuery(e.target.value)}
            />

            <SearchInput
              style={{ width: 200 }}
              placeholder="Veículo (Modelo)"
              value={vehicleModel}
              onChange={(e) => handleChangeVehicleQuery(e.target.value)}
            />

            {searchType === 'Produtos' && (
              <SelectMulti
                value={brandId}
                options={brands}
                onChange={(e) => setBrandId(e)}
                hasAllOption
                customAllLabel="Marca"
                customVariousLabel="Várias"
                height={'35px'}
              />
            )}

            {searchType === 'Produtos' && (
              <input
                className="form-control foco-input"
                placeholder="Ano"
                value={yearVehicle}
                onChange={(e) => handleChangeYear(e.target.value)}
                onBlur={() => {
                  if (yearVehicle !== '' && yearVehicle.length !== 4) {
                    handleChangeYear('');
                    return toastr.warning(
                      'Ano inválido',
                      'Insira um ano válido para aplicação e tente novamente'
                    );
                  }
                }}
                maxLength={4}
              />
            )}
          </div>
        )}

        {searchType === 'Kit' && (
          <SearchInput
            style={{ width: 500 }}
            placeholder="Pesquisa por Descrição ou Código de Barras"
            value={kitSearchQuery}
            onChange={(e) => handleChangeQuerySearchKit(e.target.value)}
          />
        )}

        {!hideSearchTypeSelect && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
            }}
          >
            <select
              className="form-control foco-input"
              type="text"
              style={{
                width: 120,
              }}
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              <option value="Produtos" selected>
                Produtos
              </option>
              <option value="Kit">Kit</option>
            </select>
          </div>
        )}
      </div>

      <ReactTable
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={searchType === 'Produtos' ? data : kits}
        columns={[
          {
            Header: '',
            width: 80,
            Cell: (props) => {
              const [isSimilarityModalOpen, setIsSimilarityModalOpen] =
                useState(false);

              return (
                <>
                  <div className="flex center gap-050">
                    <FontAwesomeIcon
                      style={{
                        width: 18,
                        height: 18,
                        color: 'green',
                        cursor: 'pointer',
                      }}
                      icon={faCirclePlus}
                      onClick={() => handleAddItem(props.original)}
                    />
                    {!hideSimilarityIcon && (
                      <FontAwesomeIcon
                        style={{
                          width: 18,
                          height: 18,
                          cursor: 'pointer',
                        }}
                        icon={faEquals}
                        onClick={() => setIsSimilarityModalOpen(true)}
                      />
                    )}
                    {(isPlanPrime || isWorkmotor) && (
                      <FontAwesomeIcon
                        style={{
                          width: 18,
                          height: 18,
                          cursor: 'pointer',
                        }}
                        icon={faImages}
                        onClick={() =>
                          handleOpenProductImageModal(props.original)
                        }
                      />
                    )}
                  </div>

                  {isSimilarityModalOpen && (
                    <SearchSimilarityModal
                      handleClose={() => setIsSimilarityModalOpen(false)}
                      product={props.original}
                      handleAddItemCallback={handleAddItem}
                      allowPromotionalPrices={allowPromotionalPrices}
                    />
                  )}
                </>
              );
            },
          },
          {
            Header: 'Código',
            accessor: 'productCode',
            minWidth: 60,
          },
          {
            Header: 'Descrição',
            accessor: 'productDescription',
            minWidth: 175,
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'productManufacturerRef',
            minWidth: 100,
            resizable: false,
          },
          {
            Header: 'Código de Barras',
            accessor: 'productBarCode',
            minWidth: 150,
          },
          {
            Header: 'Estoque',
            accessor: 'productQuantity',
            minWidth: 75,
          },
          {
            Header: 'Marca',
            accessor: 'brandDescription',
            minWidth: 200,
          },
          {
            Header: 'Veículo',
            accessor: 'vehicleModel',
            minWidth: 200,
            Cell: (props) => {
              if (!props.value) return 'Não possui veículo';

              return (
                <div className="flex center column">
                  {props.value}
                  {props.original.initialYear ? (
                    <div className="flex center">
                      <Text fontWeight="bold" size="sm">
                        Ano: {props.original.initialYear} -{' '}
                        {props.original.finalYear}
                      </Text>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            },
          },
          {
            Header: 'R$ Venda',
            accessor: 'productSalePrice',
            width: Math.round((window.innerWidth - 55) * 0.09),
            minWidth: 200,
            Cell: (props) =>
              allowPromotionalPrices && props.original.pricePromotional ? (
                <span style={{ color: '#629c44' }}>
                  {currency(props.original.pricePromotional)}
                </span>
              ) : (
                <span>{currency(props.value)}</span>
              ),
          },
          {
            Header: 'Localização',
            accessor: 'productLocation',
            minWidth: 100,
            resizable: false,
          },
        ]}
        defaultPageSize={10}
        pages={searchType === 'Produtos' ? totalPages : pagesKits}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        getTdProps={(base) => ({
          display: 'flex',
          alignItems: 'center',
          height: '45px',
        })}
        manual
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        sortable={false}
        noDataText="Nenhum produto encontrado"
        loading={loading}
        loadingText="Carregando..."
      />
    </div>
  );
};

export default SearchModalTableSimilarity;
