import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import './styles.css';
import api from 'services/api';
import config from 'config';
import CustomerDetails from './CustomerDetails';
import DashboardCard from './DashboardCard';
import constants from '../../../utils/constants';
import {
  faUserFriends,
  faUserCheck,
  faUserAltSlash,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { addDays, format } from 'date-fns';

export default function Home() {
  const [clients, setClients] = useState({});
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState('');
  const [currentValues, setCurrentValues] = useState({});

  const getClients = async () => {
    try {
      const res = await api.get('admins/counters');
      setClients(res.data);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao carregar os dados',
        'Por favor, tente novamente'
      );
      console.log(err);
    }
  };

  const handleModal = (title, values) => {
    setCurrentValues(values);
    setTitle(title);
    setModal(true);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div className="dashboard-admin-container">
        <BreadCrumb
          data={[
            <span style={{ fontFamily: 'Roboto', fontSize: '15px' }}>
              Início
            </span>,
          ]}
          path={[null]}
        />

        <div className="dashboard-admin-grid-container">
          <DashboardCard
            link={`clients?signatureStatusId=${constants.SIGNATURE_STATUS.ACTIVE}`}
            title={<span style={{ color: '#009688' }}>Total Ativos</span>}
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.activeClients.value
            }
            icon={faUserFriends}
            color="#009688"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'1,2,3,5,6'}`}
            title={<span style={{ color: '#5cb85c' }}>Total Pagantes</span>}
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.payingClientsValue.value
            }
            icon={faUserFriends}
            color="#5cb85c"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'4'}`}
            title={<span style={{ color: '#929292' }}>Total Free</span>}
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.payingClientsValue.value
                ? clients.generalPanel.activeClients.value -
                  clients.generalPanel.payingClientsValue.value
                : 0
            }
            icon={faUserFriends}
            color="#929292"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'1,2,3,5,6'}&dateType=${'lastSignatureEndDate'}&initialDate=${format(
              new Date(),
              'yyyy-MM-dd'
            )}&finalDate=${format(addDays(new Date(), 60), 'yyyy-MM-dd')}`}
            title={
              <span style={{ color: '#d43f3a' }}>Em final de vigência</span>
            }
            subtitle={
              <span>
                <strong>
                  {clients &&
                  clients.generalPanel &&
                  clients.generalPanel.endOfTermClients.percent
                    ? clients.generalPanel.endOfTermClients.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.endOfTermClients.value
            }
            icon={faUserCheck}
            color="#d43f3a"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${constants.SIGNATURE_STATUS.BLOCKED}`}
            title={<span style={{ color: '#ffa834' }}>Bloqueados</span>}
            subtitle={
              <span>
                <strong>
                  {clients &&
                  clients.generalPanel &&
                  clients.generalPanel.blockedClients.percent
                    ? clients.generalPanel.blockedClients.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.blockedClients.value
            }
            icon={faUserAltSlash}
            color="#ffa834"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${constants.SIGNATURE_STATUS.CANCELED}`}
            title={<span style={{ color: '#DE6561' }}>Cancelados</span>}
            value={
              clients &&
              clients.generalPanel &&
              clients.generalPanel.canceledClients.value
            }
            icon={faUserTimes}
            color="#DE6561"
            handleModal={handleModal}
          />
        </div>

        <Modal
          show={modal}
          onHide={() => setModal(false)}
          animation={true}
          dialogClassName="modal-detalhesClientes"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{title}</strong>
              <p>Quantidade: {currentValues.length}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerDetails dados={currentValues} />
          </Modal.Body>
        </Modal>
      </div>
      <div className="dashboard-admin-container">
        <BreadCrumb
          data={[
            <>
              Clientes <span style={{ color: '#006e8c' }}>OSD</span>
            </>,
          ]}
          path={[null]}
        />

        <div className="dashboard-admin-grid-container">
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'2,9'}&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#337ab7' }}>Plano Prime</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            value={clients && clients.os && clients.os.prime.value}
            icon={faUserFriends}
            color="#337ab7"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'1,8'}&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#ffa834' }}>Plano Essencial</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            value={clients && clients.os && clients.os.essencial.value}
            icon={faUserFriends}
            color="#ffa834"
            handleModal={handleModal}
          />

          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'3'}&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#5cb85c' }}>Plano Start</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            value={clients && clients.os && clients.os.start.value}
            icon={faUserFriends}
            color="#5cb85c"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'4'}&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#929292' }}>Free</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            value={clients && clients.os && clients.os.free.value}
            icon={faUserCheck}
            color="#929292"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'1,2,3,5,6'}&dateType=${'lastSignatureEndDate'}&initialDate=${format(
              new Date(),
              'yyyy-MM-dd'
            )}&finalDate=${format(
              addDays(new Date(), 60),
              'yyyy-MM-dd'
            )}&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#d43f3a' }}>Em final de vigência</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            subtitle={
              <span>
                <strong>
                  {clients && clients.os && clients.os.endOfTerm.percent
                    ? clients.os.endOfTerm.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={clients && clients.os && clients.os.endOfTerm.value}
            icon={faUserAltSlash}
            color="#d43f3a"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.BLOCKED
            }&brandingsId=${'1'}`}
            title={
              <>
                <span style={{ color: '#ffa834' }}>Bloqueados</span> -{' '}
                <span style={{ color: '#006e8c' }}>OSD</span>
              </>
            }
            subtitle={
              <span>
                <strong>
                  {clients && clients.os && clients.os.blocked.percent
                    ? clients.os.blocked.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={clients && clients.os && clients.os.blocked.value}
            icon={faUserTimes}
            color="#ffa834"
            handleModal={handleModal}
          />
        </div>

        <Modal
          show={modal}
          onHide={() => setModal(false)}
          animation={true}
          dialogClassName="modal-detalhesClientes"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{title}</strong>
              <p>Quantidade: {currentValues.length}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerDetails dados={currentValues} />
          </Modal.Body>
        </Modal>
      </div>
      <div className="dashboard-admin-container">
        <BreadCrumb
          data={[
            <>
              Clientes <span style={{ color: '#444444' }}>WM</span>
            </>,
          ]}
          path={[null]}
        />

        <div className="dashboard-admin-grid-container">
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'6,7'}&brandingsId=${'2'}`}
            title={
              <>
                <span style={{ color: '#337ab7' }}>Plano Workmotor</span> -{' '}
                <span style={{ color: '#444444' }}>WM</span>
              </>
            }
            value={clients && clients.wm && clients.wm.workmotor.value}
            icon={faUserFriends}
            color="#337ab7"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'5'}&brandingsId=${'2'}`}
            title={
              <>
                <span style={{ color: '#929292' }}>Free</span> -{' '}
                <span style={{ color: '#444444' }}>WM</span>
              </>
            }
            value={clients && clients.wm && clients.wm.free.value}
            icon={faUserFriends}
            color="#929292"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.ACTIVE
            }&plansId=${'1,2,3,4,6'}&dateType=${'lastSignatureEndDate'}&initialDate=${format(
              new Date(),
              'yyyy-MM-dd'
            )}&finalDate=${format(
              addDays(new Date(), 60),
              'yyyy-MM-dd'
            )}&brandingsId=${'2'}`}
            title={
              <>
                <span style={{ color: '#d43f3a' }}>Em final de vigência</span> -{' '}
                <span style={{ color: '#444444' }}>WM</span>
              </>
            }
            subtitle={
              <span>
                <strong>
                  {clients && clients.wm && clients.wm.endOfTerm.percent
                    ? clients.wm.endOfTerm.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={clients && clients.wm && clients.wm.endOfTerm.value}
            icon={faUserFriends}
            color="#d43f3a"
            handleModal={handleModal}
          />
          <DashboardCard
            link={`clients?signatureStatusId=${
              constants.SIGNATURE_STATUS.BLOCKED
            }&brandingsId=${'2'}`}
            title={
              <>
                <span style={{ color: '#ffa834cd' }}>Bloqueados</span> -{' '}
                <span style={{ color: '#444444' }}>WM</span>
              </>
            }
            subtitle={
              <span>
                <strong>
                  {clients && clients.wm && clients.wm.blocked.percent
                    ? clients.wm.blocked.percent
                    : 0}
                  %
                </strong>{' '}
                em relação a pagantes
              </span>
            }
            value={clients && clients.wm && clients.wm.blocked.value}
            icon={faUserFriends}
            color="#ffa834"
            handleModal={handleModal}
          />
        </div>
        <Modal
          show={modal}
          onHide={() => setModal(false)}
          animation={true}
          dialogClassName="modal-detalhesClientes"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{title}</strong>
              <p>Quantidade: {currentValues.length}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerDetails dados={currentValues} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
