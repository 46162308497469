import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import ProductsServicesTable from '../../../../components/ProductsServicesTable';
import SaleSheet from '../../../../components/ProductsServicesTable/Sheets/SaleSheet';
import Button from '../../../../components/CustomButton/CustomButton';
import AlertModal from 'components/AlertModal/AlertModal';
import { useAuth } from 'contexts/auth';
import constants from 'utils/constants';
import { useEffect } from 'react';

const ItemsModal = ({
  onCancel,
  onSubmit,
  clientName,
  customerId,
  discountGroupProducts,
  discountGroupServices,
  alreadyAddedItems,
  allowPromotionalPrices,
  hasKitSearch,
  hideDiscountColumn,
}) => {
  const [items, setItems] = useState([]);
  const [isClearSheetModalOpen, setIsClearSheetModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isBlockAddItemModalOpen, setIsBlockAddItemModalOpen] = useState(false);

  const { company } = useAuth();

  const handleAdd = (item) => {
    const {
      id,
      Sale_Price,
      Code,
      Description,
      Manufacturer_Ref,
      Price,
      priceType,
      Type,
      Brands,
      Warranty,
      Stock_Quantity,
      Commission_Rate,
      Services,
      Review_id,
      updateDescriptionOnSale,
      Purchase_Price,
      hasDiscountLimit,
      discountLimit,
      Families,
      salePriceWholesale,
      promocionalPriceWholesale,
      originalPrices,
    } = item;

    if (
      company.saleWithZeroStockPermissionId ===
        constants.STOCK_PERMISSIONS_ID.BLOCK &&
      Stock_Quantity <= 0
    )
      return setIsBlockAddItemModalOpen(true);

    const newItems = [...items];

    const index = newItems.findIndex((item) => item.Product_id === id);

    if (index !== -1) {
      newItems[index].Quantity++;
    } else if (Type === 'Kit') {
      item.Products.map((product) => {
        const findAlreadyAdded = newItems.findIndex(
          (item) =>
            item.Product_id === product.id || item.id === product.productId
        );

        if (findAlreadyAdded !== -1) {
          newItems[findAlreadyAdded].Quantity++;
        } else
          newItems.push({
            id: product.productId,
            Code: product.code,
            Manufacturer_Ref: product.manufacturerRef,
            Product_id: product.productId || product.id,
            Description: product.description,
            Unit_Value: product.kitPrice,
            Value_Cost: product.kitPrice,
            Brand: product.brand,
            Quantity: 1,
            Type: 'Produto',
            Discount_Value: 0,
            edit: false,
            Commission_Rate,
            reviewId: product.Review_id,
            updateDescriptionOnSale,
            promotionalPrice: Sale_Price,
            hasDiscountLimit,
            discountLimit,
            Family: product.family,
            salePriceWholesale: product.priceWholesale,
            promocionalPriceWholesale: product.promocionalPriceWholesale,
          });
      });
    } else {
      const product = {
        id,
        Code,
        Manufacturer_Ref,
        Product_id: id,
        Description,
        Brand: Type === 'Produto' && Brands ? Brands.Description : null,
        Value_Cost:
          Type === 'Produto'
            ? Purchase_Price
            : Services
            ? Services[0].Price
            : Price,
        Unit_Value:
          Type === 'Produto'
            ? Sale_Price
            : Services
            ? Services[0].Price
            : Price,
        Unit_ValueF:
          Type === 'Produto'
            ? Sale_Price
            : Services
            ? Services[0].Price
            : Price,
        kitPrice:
          Type === 'Produto'
            ? Sale_Price
            : Services
            ? Services[0].Price
            : Price,
        Quantity: 1,
        Type,
        priceType,
        WarrantyCode: Warranty ? Warranty.Code : 0,
        WarrantyDescription: Warranty
          ? Warranty.Description +
            ' ' +
            (Warranty.Months === '1'
              ? Warranty.Months + ' mês'
              : Warranty.Months + ' meses')
          : null,
        Discount_Value: 0,
        edit: false,
        Commission_Rate,
        reviewId: Review_id,
        updateDescriptionOnSale,
        promotionalPrice: Sale_Price,
        hasDiscountLimit,
        discountLimit,
        Family: Families ? Families.Description : null,
        salePriceWholesale,
        promocionalPriceWholesale,
        originalPrices,
      };

      newItems.push(product);
    }

    if (Services?.length > 0) {
      Services.forEach((service) => {
        const index = newItems.findIndex(
          (item) => item.id === service.id || item.id === service.serviceId
        );

        if (index !== -1) {
          newItems[index].Quantity++;
        } else {
          newItems.push({
            id: Type !== 'Kit' ? service.id : service.serviceId,
            Code: service.Code || service.code,
            Manufacturer_Ref: null,
            Product_id: service.id,
            Service_id: service.id,
            promotionalPrice: service.Price || service.kitPrice,
            Description: service.Description || service.description,
            Brand: null,
            Value_Cost: service.Price || service.kitPrice,
            Unit_Value: service.Price || service.kitPrice,
            Quantity: 1,
            Type: 'Serviço',
            priceType: 'RETAIL', //TODO: Adjust promotionalPrice of service to correct priceType value
            WarrantyCode: 0,
            WarrantyDescription: null,
            Discount_Value: 0,
            edit: false,
            Commission_Rate: service.Comission_Rate,
            reviewId: service.Review_id,
            updateDescriptionOnSale,
            hasDiscountLimit,
            discountLimit,
            salePriceWholesale: service.Price || service.kitPrice,
            promocionalPriceWholesale: service.Price || service.kitPrice,
          });
        }
      });
    }
    setItems(newItems);
  };

  const handleClearSheet = () => {
    setItems([]);
    setIsClearSheetModalOpen(false);
  };

  return (
    <>
      <Modal
        show
        onHide={() => (!items.length ? onCancel() : setIsCancelModalOpen(true))}
        animation
        dialogClassName="sale-items-modal-wrapper modal-95w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ position: 'relative' }}>
              <strong>Adicionar Itens</strong>
              <span
                style={{
                  fontSize: '12px',
                  marginTop: '15px',
                  right: '0',
                }}
                id="required-field-label"
              >
                * Serão exibidos apenas os produtos e serviços ativos e com
                preço
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductsServicesTable
            productsList
            servicesList
            hideConsumption
            onInclude={handleAdd}
            discountGroupProducts={discountGroupProducts}
            discountGroupServices={discountGroupServices}
            allowPromotionalPrices={allowPromotionalPrices}
            alreadyAddedItems={alreadyAddedItems}
            hasKitSearch={hasKitSearch}
            hasWholesalePriceSelect
            customerId={customerId}
          />
          <SaleSheet
            clientName={clientName}
            items={items}
            setItems={setItems}
            allowPromotionalPrices={allowPromotionalPrices}
            hideDiscountColumn={hideDiscountColumn}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end align-center">
            <Button
              style={{ height: '35px' }}
              bsStyle="danger"
              pullRight
              fill
              onClick={() =>
                !items.length ? onCancel() : setIsCancelModalOpen(true)
              }
            >
              Cancelar
            </Button>
            <Button
              style={{ height: '35px' }}
              disabled={!items.length}
              pullRight
              bsStyle="primary"
              onClick={() => setIsClearSheetModalOpen(true)}
              fill
            >
              Limpar Itens
            </Button>
            <Button
              style={{ height: '35px' }}
              bsStyle="info"
              pullRight
              fill
              onClick={() => onSubmit(items)}
            >
              Salvar Itens
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        message="Deseja limpar todos os itens? Os itens não foram adicionados na venda."
        show={isClearSheetModalOpen}
        onHide={() => setIsClearSheetModalOpen(false)}
        onCancel={() => setIsClearSheetModalOpen(false)}
        onSubmit={handleClearSheet}
      />

      <AlertModal
        message="Deseja realmente desconsiderar os itens informados e retornar para a venda?"
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <Modal
        show={isBlockAddItemModalOpen}
        onHide={() => setIsBlockAddItemModalOpen(false)}
        animation
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Produto sem estoque</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <strong>
              Não é possível adicionar o produto na venda pois ele não tem
              estoque.
            </strong>
          </span>
          <br />
          <br />
          <span>Selecione outro produto ou seu similar.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="danger"
            pullRight
            fill
            onClick={() => setIsBlockAddItemModalOpen(false)}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemsModal;
