import React from 'react'
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import routes from '../../../routes'
import PurchasesByStatus from './PurchasesByStatus';
import PurchasesByProvider from './PurchasesByProvider'

const PurchasesReports = () => {
  const route = routes.filter(r => `/client/${r.path}` === window.location.pathname)

  const renderComponent = name => {
    switch (name) {
      case 'Compras por Status':
        return <PurchasesByStatus />
      case 'Compras por Fornecedor':
        return <PurchasesByProvider />
      default:
        return <div />
    }
  }


  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Relatórios', 'Compras', route[0].name]}
            path={['Home', null, null, null]} />
          {renderComponent(route[0].name)}
        </Row>
      </Grid>
    </div>
  )
}

export default PurchasesReports