import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import ListEmployeesPDF from './ListEmployeesPDF'
import EmployeesRepository from '../../../../repositories/Employees'

const ListEmployeesDocument = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const companyIdHash = searchParams.get('companyId')
      let companyId

      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId')
      }

      if (!companyId) {
        setHasError(true)
        setLoading(false)

      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const dateType = searchParams.get('dateType')
      const displayOff = searchParams.get('displayOff')
      const positionsType = searchParams.get('positionsType')

      const params = {
        initialDate,
        finalDate,
        dateType,
        displayOff,
        positionsType
      }

      return loadReport(companyId, params)
    } catch (err) {
      setHasError(true)
      setLoading(false)

    }

  }


  const loadReport = async (companyId, params) => {
    try {
      const data = await EmployeesRepository.generateListEmployee(
        companyId,
        params,
      )

      setDataReport(data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />


  return (

    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}>
          <ListEmployeesPDF
            responseData={dataReport}
          />
        </PDFViewer>
      </main>
    </div>
  )

}




export default ListEmployeesDocument 