import React, { useState } from 'react';
import { FragaModal } from 'v2/client/components/FragaModal';

export const Fraga = ({ history }) => {
  const [showFragaModalOpen, setShowFragaModalOpen] = useState(true);

  return (
    <>
      <FragaModal
        show={showFragaModalOpen}
        setShowFragaModalOpen={setShowFragaModalOpen}
        history={history}
        isLocal={true}
      />
    </>
  );
};
