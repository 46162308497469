import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux'

import FormSubmitButtons from "client/components/FormSubmitButtons";
import AlertModal from "components/AlertModal"
import Button from 'client/components/CustomButton/CustomButton.jsx'
import CardForm from '../../../../components/CardForm';
import Provider from './Provider'
import Items from './Items'

import './styles.css'

function FormNewStock({ handleSubmit, onCancel, loading, disabled, id }) {
	const [provider, setProvider] = useState(true)
	const [items, setItems] = useState(true)
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

	const { status } = useSelector(state => state.form.cadastroStock.values)

	return (
		<div id='inventory-movement-stock'>
			<CardForm
				show={provider}
				title="Fornecedor (Opcional)"
				onClick={() => setProvider(!provider)}
			>
				<Provider disabled={disabled} />
			</CardForm>
			<CardForm
				show={items}
				title="Itens"
				onClick={() => setItems(!items)}
			>
				<Items disabled={disabled} />
			</CardForm>

			{
				status === "Aberta" ?
					<FormSubmitButtons loading={loading} hasId={!!id} onCancel={() => setIsCancelModalOpen(true)} onSubmit={handleSubmit} />
					:
					<div className='footer'>
						<Button bsStyle="danger" fill onClick={onCancel}>Voltar</Button>
						<Button className={status === "Finalizada" ? "btn btn-sucesso" : "btn btn-cancelado"} disabled={disabled}>
							{status === "Finalizada" ? 'Finalizada' : 'Cancelada'}
						</Button>
					</div>
			}

			<AlertModal
				show={isCancelModalOpen}
				onHide={() => setIsCancelModalOpen(false)}
				onCancel={() => setIsCancelModalOpen(false)}
				message='Deseja cancelar a criação dessa movimentação? Tudo que foi feito será descartado.'
				onSubmit={onCancel}
			/>

		</div>
	);
};


export default reduxForm({
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	form: 'cadastroStock'
})(FormNewStock)