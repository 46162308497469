const handleExports = () => {
  const optionsOrderBySelect = [
    {
      label: 'Ordem Alfabética',
      value: 'ALPHABETICAL',
    },
    {
      label: 'Lançamento na tabela itens',
      value: 'ITENS_INDEX',
    },
  ];

  const defectAndCorrectionInPrintOptions = [
    {
      value: false,
      label: 'Não Exibir',
    },
    {
      value: true,
      label: 'Exibir',
    },
  ];

  const itensStatus = [
    {
      value: null,
      label: 'Vazio',
    },
    {
      value: true,
      label: 'OK',
    },
    {
      value: false,
      label: 'NOK',
    },
  ];

  const getStatusDictionary = (value) => {
    return itensStatus.reduce((acc, curr) => {
      if (curr.value === value) {
        return { label: curr.label, value: curr.value };
      }
      return acc;
    }, {});
  };

  return {
    optionsOrderBySelect,
    defectAndCorrectionInPrintOptions,
    itensStatus,
    getStatusDictionary,
  };
};

export default handleExports;
