import React from 'react';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';

import { toastr } from 'react-redux-toastr';

import '../index.css';
import { useState } from 'react';
import MaintenanceRepository from 'repositories/Maintenance';
import { useWorkshopPanel } from 'contexts/workshopPanel';

const StopReasonModal = ({ maintenance, show, onCancel }) => {
  const [stopReasonInput, setStopReasonInput] = useState('');
  const [loading, setLoading] = useState(false);

  const { setReloadPanelInformation } = useWorkshopPanel();

  const handleStopService = async () => {
    setLoading(true);
    try {
      await MaintenanceRepository.updateMaintenance(maintenance?.id, {
        status: 'Parado',
        stopReason: stopReasonInput,
      });
      toastr.success('Serviço parado com sucesso!');
      setLoading(false);
      setReloadPanelInformation(true);
      onCancel();
    } catch (err) {
      setLoading(false);
      toastr.error(
        'Erro ao carregar parar o serviço',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    }
  };

  return (
    <>
      <Modal show={true} onHide={onCancel} backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Painel da Oficina</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Você tem certeza de que deseja parar o serviço?</strong>
          <p style={{ fontSize: '14px', marginTop: '20px' }}>
            Ao parar o serviço, os mecânicos serão liberados para executar outro
            serviço. <br /> É obrigatório inserir o motivo da pausa do serviço
            abaixo:
          </p>

          <textarea
            style={{ marginTop: '20px', maxWidth: '100%' }}
            type="text"
            className="form-control"
            name="description"
            id="description"
            maxLength="500"
            rows="5"
            placeholder="Insira aqui o motivo da parada"
            value={stopReasonInput}
            onChange={(e) => setStopReasonInput(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="voltar"
              onClick={onCancel}
              fill
            >
              Voltar
            </CustomButton>
            <CustomButton
              bsStyle="info"
              name="stop-service"
              onClick={handleStopService}
              disabled={stopReasonInput.trim().length === 0 || loading}
              fill
            >
              {loading && (
                <span
                  className={
                    loading && 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  }
                />
              )}
              Parar Serviço
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StopReasonModal;
