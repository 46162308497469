import React from 'react';
import { change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ProgressBarComponent from './ProgressBarComponent';

import Import from './pages/Import';
import Provider from './pages/Provider';
import Products from './pages/Products';
import Loader from '../../../components/Loader';
import Prices from './pages/Prices';
import { useAuth } from 'contexts/auth';
import ProgressBarComponentWithPrices from './ProgressBarComponentWithPrices';

function Bundle({ onSubmit }) {
  const { page, loading, loadingMessage } = useSelector(
    (state) => state.form.PurchaseXmlImport.values
  );
  const { company } = useAuth();
  const dispatch = useDispatch();

  function nextPage() {
    dispatch(change('PurchaseXmlImport', 'page', page + 1));
  }

  function previousPage() {
    dispatch(change('PurchaseXmlImport', 'page', page - 1));
  }

  return (
    <div className="xml-import-container">
      {loading && <Loader message={loadingMessage} />}
      <div className="progress-bar-xml-import">
        {company.companyConfig.advancedPricing ? (
          <>
            <ProgressBarComponentWithPrices
              percent={
                company.companyConfig.advancedPricing
                  ? page === 1
                    ? 15
                    : page === 2
                    ? 50
                    : page === 3
                    ? 85
                    : 100
                  : page === 1
                  ? 15
                  : page === 2
                  ? 50
                  : page === 3
                  ? 85
                  : 100
              }
              haveStepPrices={company.companyConfig.advancedPricing}
            />
          </>
        ) : (
          <>
            <ProgressBarComponent
              percent={
                company.companyConfig.advancedPricing
                  ? page === 1
                    ? 25
                    : page === 2
                    ? 75
                    : 100
                  : page === 1
                  ? 25
                  : page === 2
                  ? 75
                  : 100
              }
            />
          </>
        )}
      </div>
      {page === 1 && <Import nextPage={nextPage} />}
      {page === 2 && (
        <Provider nextPage={nextPage} previousPage={previousPage} />
      )}
      {page === 3 && (
        <Products
          nextPage={nextPage}
          previousPage={previousPage}
          onSubmit={onSubmit}
        />
      )}
      {company.companyConfig.advancedPricing && page === 4 && (
        <Prices
          nextPage={nextPage}
          previousPage={previousPage}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
}

export default reduxForm({
  form: 'PurchaseXmlImport',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Bundle);
