import api from '../services/api';
import config from '../config';

const getAllByCompany = async (params) => {
  const { data } = await api.get('promotional-price-table', { params });

  return data;
};

const updateStatus = async (id, companyId) => {
  const { data } = await api.put(
    `promotional-price-table/status/${id}?companyId=${companyId}`
  );

  return data;
};

const deleteTable = async (id) => {
  const { data } = await api.delete(`promotional-price-table/${id}`);

  return data;
};

const create = async (values) => {
  const { data } = await api.post('promotional-price-table', values);

  return data;
};

const getById = async (id) => {
  const { data } = await api.get(`promotional-price-table/${id}`);

  return data;
};

const updateTable = async (id, values) => {
  const { data } = await api.put(`promotional-price-table/${id}`, values);

  return data;
};

const getActiveByCompanyId = async (companyId) => {
  const { data } = await api.get(
    `promotional-price-table/find-active/${companyId}`
  );

  return data;
};

export default {
  getAllByCompany,
  updateStatus,
  deleteTable,
  create,
  getById,
  updateTable,
  getActiveByCompanyId,
};
