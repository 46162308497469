import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Button from "../../../components/CustomButton/CustomButton.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import printType1 from '../../../../assets/img/impressos/sale-printing-sheet-model-1.png'
import printType2 from '../../../../assets/img/impressos/sale-printing-sheet-model-2.png'
import printType3 from '../../../../assets/img/impressos/sale-printing-sheet-model-3.png'

import './styles.css'

const PrintingSheetsViewModal = ({ sheetId, printingSheets, onCancel }) => {
  const [printImage, setPrintImage] = useState(null)
  const [printName, setPrintName] = useState(null)
  const [sheets, setSheets] = useState([])
  const [isImageOpened, setIsImageOpened] = useState(false)

  useEffect(() => {
    if (printingSheets && sheetId) {
      handleSheets(sheetId)
    }
  }, [printingSheets, sheetId])

  const handlePrintImage = id => {
    switch (id) {
      case 1:
        setPrintImage(printType1)
        break;
      case 2:
        setPrintImage(printType2)
        break;
      case 3:
        setPrintImage(printType3)
        break;
      default:
        break;
    }
  }

  const handleSheets = id => {
    setSheets(printingSheets.map(sheet => {
      if (sheet.id === id) {
        handlePrintImage(sheet.id)
        setPrintName(sheet.description)
        return {
          ...sheet,
          checked: true
        }
      } else {
        return {
          ...sheet,
          checked: false
        }
      }
    }))
  }

  const handleCheck = useCallback(id => handleSheets(id))

  return (
    <>
      <Modal
        show
        animation
        onHide={onCancel}
      >
        <Modal.Header style={{}} closeButton>
          <Modal.Title>
            <strong> Visualização de Impressos </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '100%', paddingLeft: '20px' }}>
              <span>Selecione o tipo de impresso que deseja visualizar:</span>
              <div>
                {sheets.map(sheet => (
                  <div style={{ display: 'flex' }} key={sheet.id}>
                    <input type='radio' name='printing-sheet-type' checked={sheet.checked} onChange={() => handleCheck(sheet.id)}></input>
                    <span style={{ paddingTop: '12px', paddingLeft: '10px', cursor: 'pointer' }} onClick={() => handleCheck(sheet.id)}>{sheet.description}</span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div id="image-in-modal">
                <img src={printImage} alt={printName} onClick={() => setIsImageOpened(true)} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button bsStyle="danger" name="Voltar" onClick={onCancel} fill>Voltar</Button>
          </div>
        </Modal.Footer>
      </Modal >
      {isImageOpened &&
        <div id="image-in-modal-opened">
          <center>
            <FontAwesomeIcon icon={faTimes} onClick={() => setIsImageOpened(false)} />
            <img src={printImage} alt={printName} />
          </center>
        </div>
      }
    </>
  )
}

export default PrintingSheetsViewModal