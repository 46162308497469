import React, { useCallback, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PercentInput from 'client/components/Percent';
import Button from '../../../components/CustomButton/CustomButton';
import Input from '../../Clients/NewClient/FormClient/components/Input';
import Select from '../../Clients/NewClient/FormClient/components/Select';
import StoneModal from '../../../components/StoneModal';

import { FORM_PAYMENT_TYPES } from '../../../../utils/constants';
import bankBilletAccountRepository from 'repositories/BankBilletAccount';
import cardFlagsRepository from '../../../../repositories/CardFlags';
import providersRepository from '../../../../repositories/Providers';
import stoneLinkRepository from '../../../../repositories/StoneCompany';
import materaCompanyRepository from '../../../../repositories/MateraCompany';
import { usePlanSignatureContext } from '../../../../contexts/plan-signature';
import { useModulesContext } from '../../../../contexts/modules';
import { useAuth } from 'contexts/auth';
import CurrencyInput from 'react-currency-input';

const DadosFormaPagamento = () => {
  const [providers, setProviders] = useState([]);
  const [cardFlags, setCardFlags] = useState([]);
  const [isCreditOrDebit, setIsCreditOrDebit] = useState(false);
  const [isStoneModalOpen, setIsStoneModalOpen] = useState(false);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  const [isBankSlipModalOpen, setIsBankSlipModalOpen] = useState(false);

  const [isMateraIntegrationConcluded, setIsMateraIntegrationConcluded] =
    useState(false);
  const [companyBankBilletAccount, setCompanyBankBilletAccount] = useState({});

  const { isBankSlipModuleActive } = useModulesContext();

  const { companyId } = useAuth();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const validateIsStatusConcluded = useCallback(async () => {
    try {
      const { data } = await stoneLinkRepository.getAllDataByCompany(companyId);

      const integrationStatus = data?.integrationStatus;

      if (integrationStatus === true) {
        return;
      } else if (integrationStatus === false) {
        setIsModalAlertOpen(true);
        dispatch(change('formOfPaymentRegister', 'isStoneIntegration', false));
      } else {
        setIsModalAlertOpen(true);
        dispatch(change('formOfPaymentRegister', 'isStoneIntegration', false));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const dispatch = useDispatch();
  const {
    formOfPaymentRegister: {
      values: {
        IsActive,
        TypeOfPayment,
        automaticDownload,
        fineType,
        feeType,
        daysToStartFine,
        daysToStartFee,
        fineValue,
        feeValue,
        operatorFee,
        cardOperator,
        isStoneIntegration,
        isMateraIntegration,
        isBankSlipIntegration,
        bankBilletAccountId,
      },
    },
  } = useSelector((state) => state.form);

  const isNotPlanFreeOrStart = !isPlanFree && !isPlanStart;
  const isCardOptionForm =
    TypeOfPayment === 'Cartão de Crédito' ||
    TypeOfPayment === 'Cartão de Débito';

  if (isStoneIntegration) {
    validateIsStatusConcluded();
  }

  function description(value) {
    if (!value) return value;
    if (value.match(/[$%^&*+?|;:~@#%`"!¨()=´\\'{}\._[\]<>]/)) {
      value = value.replace(value.charAt([value.length - 1]), '');
      return value;
    }
    return value;
  }

  const verifyForm = (TypeOfPayment) => {
    if (
      TypeOfPayment !== 'Cartão de Crédito' ||
      TypeOfPayment !== 'Cartão de Débito'
    ) {
      dispatch(change('formOfPaymentRegister', 'cardOperator', ''));
      dispatch(change('formOfPaymentRegister', 'operatorFee', '0.00'));
    }
    dispatch(change('formOfPaymentRegister', 'isStoneIntegration', false));

    if (TypeOfPayment !== 'Boleto Bancário') {
      dispatch(change('formOfPaymentRegister', 'isBankSlipIntegration', false));
      dispatch(change('formOfPaymentRegister', 'bankBilletAccountId', ''));
    }

    if (
      TypeOfPayment !== 'Pagamento Instantâneo (PIX)' ||
      !isMateraIntegrationConcluded
    ) {
      dispatch(change('formOfPaymentRegister', 'isMateraIntegration', false));
    }
  };

  const handleSubmit = (type) => {
    comercialWhatsappContact(type);
    setIsModalAlertOpen(false);
  };

  function validateBankSlipStatus() {
    if (isBankSlipModuleActive) {
      dispatch(
        change(
          'formOfPaymentRegister',
          'isBankSlipIntegration',
          !isBankSlipIntegration
        )
      );
    } else {
      setIsBankSlipModalOpen(true);
      dispatch(change('formOfPaymentRegister', 'isBankSlipIntegration', false));
    }
  }

  const getProvider = async () => {
    const providers = await providersRepository.getAllActiveByCompany(
      companyId
    );
    setProviders(providers);
  };

  const getCardFlags = useCallback(async () => {
    const { data: cardFlags } = await cardFlagsRepository.index();
    setCardFlags(cardFlags);
  }, []);

  const getBankBilletAccount = useCallback(async () => {
    const { data: companyBankBillet } =
      await bankBilletAccountRepository.showByCompanyId(companyId);

    if (companyBankBillet) {
      const bankBilletFiltered = companyBankBillet.filter(
        (bankBillet) => bankBillet.status
      );
      setCompanyBankBilletAccount(bankBilletFiltered);
    }
  }, []);

  const comercialWhatsappContact = useCallback((type) => {
    let WHATSAPP;

    if (type === 'upgrade') {
      WHATSAPP =
        'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20sou%20cliente%20e%20quero%20saber%20mais%20sobre%20a%20integra%C3%A7%C3%A3o%20do%20OS%20Digital%20com%20a%20Stone.';
    } else if (type === 'bankSlipModule') {
      WHATSAPP =
        'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20sou%20cliente%20e%20quero%20saber%20mais%20sobre%20a%20integra%C3%A7%C3%A3o%20do%20OS%20Digital%20com%20emissão%20de%20boletos.';
    }

    return window.open(WHATSAPP, '_blank');
  }, []);

  const validateMateraStatus = useCallback(async () => {
    try {
      const { data } = await materaCompanyRepository.index();
      const materaCompany = data.find(
        (data) => data.companyId === parseInt(companyId)
      );
      if (materaCompany?.status !== 'FINALIZADO') {
        setIsMateraIntegrationConcluded(false);
      } else {
        setIsMateraIntegrationConcluded(true);
      }
    } catch (err) {
      if (err.response.data.message === 'Company not found') {
        setIsMateraIntegrationConcluded(false);
      } else {
        toastr.error(
          'Ocorreu um erro ao buscar o status da conta digital. Tente novamente!'
        );
      }
    }
  }, []);

  useEffect(() => {
    getCardFlags();
    getProvider();
    validateMateraStatus();
    getBankBilletAccount();
  }, []);

  useEffect(() => {
    const isCreditOrDebit =
      TypeOfPayment === 'Cartão de Crédito' ||
      TypeOfPayment === 'Cartão de Débito';
    setIsCreditOrDebit(isCreditOrDebit);

    if (!isCreditOrDebit) {
      dispatch(change('formOfPaymentRegister', 'cardFlagTBand', ''));
    }
  }, [TypeOfPayment]);

  useEffect(() => {
    if (cardOperator) {
      var cnpj = cardOperator.substr(0, cardOperator.indexOf('-'));
      var provider = providers.find(
        (providers) => providers.cpfCnpj === cnpj.trim()
      );
      if (provider) {
        dispatch(change('formOfPaymentRegister', 'providerId', provider.id));
        dispatch(
          change('formOfPaymentRegister', 'providerName', provider.tradingName)
        );
      }
    }
  }, [cardOperator]);

  function handleOpenStoneModal() {
    setIsStoneModalOpen(true);
  }

  return (
    <>
      <Col lg={4} md={4} sm={4} xs={12}>
        <Field
          label="Descrição"
          required
          name="Desciption"
          component={Input}
          normalize={description}
          maxLength="50"
        />
      </Col>
      <Col lg={3} md={5} sm={6} xs={12}>
        <label>
          Tipo de Forma de Pagamento<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          name="TypeOfPayment"
          required
          component={Select}
          value={TypeOfPayment}
          onChange={(e) => verifyForm(e.target.value)}
        >
          <option value="">Selecione</option>
          {FORM_PAYMENT_TYPES.map((t, i) => (
            <option key={i} value={t}>
              {t}
            </option>
          ))}
        </Field>
      </Col>
      <Col lg={2} md={4} sm={8} xs={12}>
        <label>
          Baixa Automática? <span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          name="automaticDownload"
          required
          component={Select}
          value={automaticDownload}
        >
          <option value="0">Não</option>
          <option value="1">Sim</option>
        </Field>
      </Col>
      <Col lg={1} md={2} sm={1} xs={12}>
        <label>Status</label>
        <div id="toggle-status">
          <Toggle
            checked={!!IsActive}
            name="IsActive"
            onChange={() =>
              dispatch(change('formOfPaymentRegister', 'IsActive', !IsActive))
            }
          />
        </div>
      </Col>
      <Col lg={4} md={5} sm={5} xs={12}>
        <Field
          label="Operadora do Cartão"
          name="cardOperator"
          type="text"
          component={Input}
          list="CardOperatorList"
          disabled={!isCreditOrDebit}
        />
        <datalist id="CardOperatorList">
          {providers.map((c) => (
            <option key={c.id} value={c.cpfCnpj + ' - ' + c.companyName}>
              {c.cpfCnpj + ' - ' + c.companyName}
            </option>
          ))}
        </datalist>
      </Col>
      <Col lg={2} md={2} sm={6} xs={12}>
        <label>Taxa da Operadora</label>
        <PercentInput
          suffix="%"
          className="form-control foco-input"
          name="operatorFee"
          decimalSeparator=","
          inputType="text"
          onChangeEvent={(e) =>
            dispatch(
              change('formOfPaymentRegister', 'operatorFee', e.target.value)
            )
          }
          value={operatorFee}
          disabled={!isCreditOrDebit}
        />
      </Col>
      <Col lg={2} md={2} sm={6} xs={12}>
        <label>Bandeira do Cartão</label>
        <Field
          name="cardFlagTBand"
          required
          component={Select}
          disabled={!isCreditOrDebit}
        >
          <option value="">Selecione</option>
          {cardFlags.map(({ flag, tBand }) => (
            <option key={tBand} value={tBand}>
              {flag}
            </option>
          ))}
        </Field>
      </Col>
      {TypeOfPayment === 'Pagamento Instantâneo (PIX)' && (
        <Col
          lg={3}
          md={3}
          sm={1}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <label>Integração Conta Digital</label>
          <div id="toggle-matera">
            <Toggle
              checked={!!isMateraIntegration}
              name="isMateraIntegration"
              disabled={!isMateraIntegrationConcluded}
              onChange={() =>
                dispatch(
                  change(
                    'formOfPaymentRegister',
                    'isMateraIntegration',
                    !isMateraIntegration
                  )
                )
              }
            />
          </div>
        </Col>
      )}
      {TypeOfPayment === 'Boleto Bancário' && (
        <>
          <Col
            lg={2}
            md={2}
            sm={1}
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <label>Ativar Emissão de Boleto</label>
            <div id="toggle-matera">
              <Toggle
                checked={isBankSlipIntegration}
                name="isBankSlipIntegration"
                onChange={() => validateBankSlipStatus()}
              />
            </div>
          </Col>
          <Col
            lg={2}
            md={2}
            sm={1}
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <label>Carteira para Emissão de Boleto</label>
            <Field
              name="bankBilletAccountId"
              required
              component={Select}
              disabled={!isBankSlipIntegration ? 'disabled' : ''}
            >
              <option value="">Selecione</option>
              {companyBankBilletAccount &&
                companyBankBilletAccount.length > 0 &&
                companyBankBilletAccount.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item?.TicketIssuanceBank?.name}
                  </option>
                ))}
            </Field>
          </Col>
          {isBankSlipIntegration && (
            <>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Tipos de Multa<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="fineType"
                  required
                  component={Select}
                  value={fineType}
                >
                  <option selected value="unexistent">
                    Sem Multa
                  </option>
                  <option value="percentage">Percentual %</option>
                  <option value="value">Valor Fixo</option>
                </Field>
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Valor da Multa<span style={{ color: 'red' }}>*</span>
                </label>
                {fineType === 'unexistent' && (
                  <CurrencyInput
                    prefix="R$ "
                    className="form-control foco-input"
                    name="fineValue"
                    value={fineValue}
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'fineValue',
                          e.target.value
                        )
                      )
                    }
                    disabled
                  />
                )}
                {fineType === 'percentage' && (
                  <PercentInput
                    suffix="%"
                    className="form-control foco-input"
                    name="fineValue"
                    decimalSeparator=","
                    inputType="text"
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'fineValue',
                          e.target.value
                        )
                      )
                    }
                    value={fineValue}
                  />
                )}
                {fineType === 'value' && (
                  <CurrencyInput
                    prefix="R$ "
                    className="form-control foco-input"
                    name="fineValue"
                    value={fineValue}
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'fineValue',
                          e.target.value
                        )
                      )
                    }
                  />
                )}
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Dias p/ Iniciar Multa<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  required
                  name="daysToStartFine"
                  component={Input}
                  value={daysToStartFine}
                  type="number"
                  disabled={fineType === 'unexistent'}
                />
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Tipo de Juros<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  name="feeType"
                  required
                  component={Select}
                  value={feeType}
                >
                  <option value="unexistent">Sem Juros</option>
                  <option value="percentage">Percentual %</option>
                  <option value="value">Valor Diário</option>
                </Field>
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Valor da Juros<span style={{ color: 'red' }}>*</span>
                </label>
                {feeType === 'unexistent' && (
                  <CurrencyInput
                    prefix="R$ "
                    className="form-control foco-input"
                    name="feeValue"
                    value={feeValue}
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'feeValue',
                          e.target.value
                        )
                      )
                    }
                    disabled
                  />
                )}
                {feeType === 'percentage' && (
                  <PercentInput
                    suffix="%"
                    className="form-control foco-input"
                    name="feeValue"
                    decimalSeparator=","
                    inputType="text"
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'feeValue',
                          e.target.value
                        )
                      )
                    }
                    value={feeValue}
                  />
                )}
                {feeType === 'value' && (
                  <CurrencyInput
                    prefix="R$ "
                    className="form-control foco-input"
                    name="feeValue"
                    value={feeValue}
                    onChangeEvent={(e) =>
                      dispatch(
                        change(
                          'formOfPaymentRegister',
                          'feeValue',
                          e.target.value
                        )
                      )
                    }
                  />
                )}
              </Col>
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>
                  Dias p/ Desconto<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  required
                  name="daysToStartFee"
                  component={Input}
                  value={daysToStartFee}
                  type="number"
                  disabled={feeType === 'unexistent'}
                />
              </Col>
            </>
          )}
        </>
      )}
      <Modal
        show={isModalAlertOpen}
        onHide={() => setIsModalAlertOpen(false)}
        animation
        dialogClassName="modal-info-company"
        style={{ width: '50%', margin: '0 auto' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'left' }}>
          <span>
            <strong>
              A opção poderá ser utilizada apenas para empresas que utilizam
              operadora Stone.
            </strong>
          </span>
          <br />
          <span>
            Se desejar utilizar a Stone em sua loja ou se já a utiliza, solicite
            a funcionalidade através do nosso atendimento abaixo.
          </span>
          <br />

          <div
            style={{
              textAlign: 'center',
            }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleOpenStoneModal(true);
              }}
            >
              <u>Veja quais são as máquinas que funcionam no OS Digital</u>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="upgrade-modal-footer">
            <Button
              bsStyle="danger"
              fill
              onClick={() => setIsModalAlertOpen(false)}
            >
              Voltar
            </Button>
            <Button bsStyle="info" fill onClick={() => handleSubmit('upgrade')}>
              <div>
                <FontAwesomeIcon icon={faWhatsapp} />
                <span>Solicitar troca de plano</span>
              </div>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isBankSlipModalOpen}
        onHide={() => setIsBankSlipModalOpen(false)}
        animation
        dialogClassName="modal-info-company"
        style={{ width: '50%', margin: '0 auto' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'left' }}>
          <span>
            <strong>Emissão de boleto não ativo para sua conta.</strong>
          </span>
          <br />
          <br />
          <span>
            Se desejar emitir boletos pelo OS Digital, solicite o módulo junto
            ao nosso departamento comercial, clicando no botão abaixo.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <div className="upgrade-modal-footer">
            <Button
              bsStyle="danger"
              fill
              onClick={() => setIsBankSlipModalOpen(false)}
            >
              Voltar
            </Button>
            <Button
              bsStyle="info"
              fill
              onClick={() => handleSubmit('bankSlipModule')}
            >
              <div>
                <FontAwesomeIcon icon={faWhatsapp} />
                <span>Contratar Emissão de Boletos</span>
              </div>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <StoneModal show={isStoneModalOpen} setShow={setIsStoneModalOpen} />
    </>
  );
};

export default DadosFormaPagamento;
