import React, { useCallback, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import positionsRepository from '../../../repositories/Positions';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useHistory } from 'react-router-dom';
import SearchInput from 'components/SearchInput';

const Positions = () => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const history = useHistory();

  const { queryFilter, statusFilter, typeFilter } = useFilters();
  const { type, setType, filterByType } = typeFilter;
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (companyId) loadPositions();
  }, [companyId]);

  const loadPositions = async () => {
    setLoading(true);
    try {
      const { data } = await positionsRepository.getAllByCompany(companyId);
      setPositions(data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os cargos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (position) => {
    const searchQuery = [position['description']];
    return (
      filterByQuery(searchQuery) &&
      filterByStatus('isActive', position) &&
      filterByType('performServices', position)
    );
  };

  const handleEditItem = useCallback((id) => {
    const positionIdHash = encrypt(id, '@OS-dig:positionId');
    history.push(`/client/${constants.ROUTES.POSITION}?id=${positionIdHash}`);
  }, []);

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <button
                className="btn btn-sucesso button-h35"
                onClick={() => history.push(constants.ROUTES.POSITION)}
                style={{ fontSize: '12px' }}
              >
                + Novo Cargo
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Executa Serviço: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="performServices"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>
          </div>

          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={positions.filter(handleFilters)}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Executa Serviço?',
                  accessor: 'performServices',
                  headerClassName: 'text-left',
                  Cell: (props) => (props.value ? 'Sim' : 'Não'),
                  width: 180,
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value ? 'green' : 'red',
                      }}
                    >
                      {props.value ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 90,
                  filterable: false,
                  className: 'texto',
                  Cell: (props) => (
                    <FontAwesomeIcon
                      title="Editar Cargo"
                      cursor="pointer"
                      icon={faEdit}
                      style={{ width: '1.5em', height: '1.5em' }}
                      onClick={() => handleEditItem(props.value)}
                    />
                  ),
                },
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={false}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default Positions;
