import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import ListCanceledItemsMain from './components/ListCanceledItemsMain';

export default function ListCanceledItems() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Listagem de Itens Cancelados',
            ]}
            path={['home', null, null, null]}
          />
          <ListCanceledItemsMain />
        </Row>
      </Grid>
    </div>
  );
}
