const INITIAL_STATE = {
  Carros: false,
  Motos: false,
  Pesados: false,
  Outros: false,
  Trator: false,
  Escavadeira: false,
  Van: false,
  Bicicleta: false,
  Colheitadeira: false,
  segmentDescription: '',

  companySegments: [],
  currentSegments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HANDLE_CHANGE':
      return { ...state, [action.payloadType]: action.payload };
    case 'LOAD_SEGMENT':
      return {
        ...state,
        currentSegments: action.payloadCurrentySegments,
        companySegments: action.payloadCompanySegments,
        Carros: action.Carros,
        Motos: action.Motos,
        Pesados: action.Pesados,
        Outros: action.Outros,
        Trator: action.Trator,
        Escavadeira: action.Escavadeira,
        Van: action.Van,
        Bicicleta: action.Bicicleta,
        Colheitadeira: action.Colheitadeira,
      };
    default:
      return state;
  }
};
