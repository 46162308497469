import CollapsibleSection from 'components/CollapsibleSection'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import InputMask from 'react-input-mask'
import { Controller, useForm } from 'react-hook-form'
import { toastr } from 'react-redux-toastr'
import ServicesRepository from 'repositories/Services'

export default function NewServiceModal({ handleClose, companyId, onInclude }) {
  const [loading, setLoading] = useState()

  const onSubmit = (values) => {
    if (!values.Description || !values.Price) {
      toastr.warning(
        'Não foi possível cadastrar o serviço!',
        'Preencha todos os campos obrigatórios para cadastrar o serviço.'
      )
    }

    create(values)
  }

  const create = async (values) => {
    setLoading(true)

    const formatedPrice = values.Price.replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.')

    try {
      const companyLastCode = await ServicesRepository.getCompanyLastCode(
        companyId
      )
      const lastCode = companyLastCode + 1

      const data = await ServicesRepository.create({
        Description: values.Description.toUpperCase(),
        Price: parseFloat(formatedPrice),
        Hours_Expected: values.Hours_Expected,
        Company_id: companyId,
        IsActive: 1,
        Code: lastCode,
      })

      toastr.success('Serviço cadastrado com sucesso!')
      handleClose()
      onInclude({ ...data, Type: 'Serviço' })
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao cadastrar o serviço.',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm()

  return (
    <Modal show={true} onHide={handleClose} dialogClassName="modal-60w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
          <span
            style={{
              color: 'red',
              position: 'absolute',
              right: 10,
              fontSize: '14px',
              marginTop: '15px',
            }}
          >
            * Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollapsibleSection title="Dados do Serviço" isExpanded={true}>
          <div className="div-products-form-row">
            <div className="div-products-form-inputs">
              <span>
                Descrição:<span style={{ color: 'red' }}>*</span>
              </span>
              <input
                required
                style={{
                  width: '430px',
                }}
                className="form-control foco-input"
                type="text"
                {...register('Description')}
              />
            </div>

            <div className="div-products-form-inputs">
              <span>
                Preço de Venda:<span style={{ color: 'red' }}>*</span>
              </span>
              <Controller
                name="Price"
                control={control}
                render={({ field }) => {
                  return (
                    <CurrencyInput
                      style={{
                        width: '120px',
                      }}
                      prefix="R$ "
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control foco-input"
                      {...field}
                    />
                  )
                }}
              />
            </div>

            <div className="div-products-form-inputs">
              <span>Horas Previstas:</span>
              <Controller
                name="Hours_Expected"
                control={control}
                render={({ field }) => {
                  return (
                    <InputMask
                      mask={'999:59'}
                      style={{
                        width: 120,
                      }}
                      formatChars={{
                        9: '[0-9]',
                        5: '[0-5]',
                      }}
                      maskChar={'0'}
                      className="form-control foco-input"
                      {...field}
                    />
                  )
                }}
              />
            </div>
          </div>
          <center>
            <span
              style={{
                fontSize: '11px',
                color: '#e61610',
              }}
            >
              O cadastro rápido de serviço agiliza na venda mas está incompleto
              para emitir nota.
              <br />
              Antes de qualquer emissão, acesse o cadastro do serviço e ajuste
              os dados fiscais necessários.
            </span>
          </center>
        </CollapsibleSection>

        <div
          style={{
            marginTop: '10px',
          }}
        >
          <FormSubmitButtons
            loading={loading}
            onCancel={handleClose}
            onSubmit={handleSubmit(onSubmit)}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
