import api from '../services/api';

const index = (query) =>
  api.get('card-flags', {
    params: query,
  });

export default {
  index,
};
