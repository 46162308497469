import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { isValid, format } from 'date-fns';
import {
  currency,
  phoneMask,
  cnpjMask,
} from 'client/components/ToNormalize/ToNormalize';

import AccountsPayableFilter from './AccountsPayableFilter';
import providersRepository from 'repositories/Providers';
import cashierBanksRepository from 'repositories/CashierBank';
import billsToPayRepository from 'repositories/BillsToPay';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

const AccountsPayableMain = () => {
  const [providersSuggestions, setProvidersSuggestions] = useState([]);
  const [cashierBanks, setCashierBanks] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [providers, setProviders] = useState([]);

  const [sortByCustomer, setSortByCustomer] = useState('false');
  const [cashierBankId, setCashierBanksValue] = useState('');
  const [titleStatus, setTitleStatus] = useState('open');
  const [reportType, setReportType] = useState('synthetic');
  const [displayNotes, setDisplayNotes] = useState('false');
  const [dateType, setDateType] = useState('dueDate');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [validateExcel, setValidateExcel] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [providerId, setProviderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { companyId } = useAuth();

  useEffect(() => {
    setProviderId(null);
    loadProviders();
  }, []);

  useEffect(() => {
    const suggestions = providers.map((provider) => {
      const providerName =
        provider.type === 'Fisica'
          ? `Nome: ${provider.companyName}`
          : `Razão Social: ${provider.companyName}, Nome Fantasia: ${provider.tradingName}`;

      const providerDocument = provider.cpfCnpj
        ? provider.type === 'Fisica'
          ? `CPF: ${provider.cpfCnpj}`
          : `CNPJ: ${provider.cpfCnpj}`
        : '';

      return {
        value: provider.id,
        label: `${providerName} ${providerDocument && `, ${providerDocument}`}`,
      };
    });

    setProvidersSuggestions(suggestions);
  }, [providers]);

  const loadProviders = async () => {
    setIsLoading(true);
    try {
      const providers = await providersRepository.getAllByCompany(companyId);

      setProviders(providers);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os clientes. Por favor, tente novamente'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadActiveCashierBanks();
  }, []);

  async function loadActiveCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      setCashierBanks(cashierBanks);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os caixa/bancos. Por favor, tente novamente'
      );
    }
  }

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate) || !providerId)
    ) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setIsLoading(true);
    setValidateExcel(false);
    try {
      let data;
      let excelData;
      if (reportType === 'synthetic') {
        data = await billsToPayRepository.generateBillsPayableTitleSynthetic({
          companyId,
          initialDate,
          finalDate,
          providerId: providerId ? providerId?.value : '',
          dateType,
          titleStatus,
          cashierBankId,
        });

        if (!data.titleToPay.length) {
          setIsLoading(false);
          toastr.warning(
            'Não foi possível gerar o relatório.',
            'Não há dados no período e filtros informado.'
          );
          return;
        }

        excelData = data.titleToPay.map((item) => ({
          payDate: format(new Date(item.Vencimento), 'dd/MM/yyyy'),
          amountsTitles: item.QtdeTítulos,
          amountPaid: currency(item.ValorPago),
          balanceReceive: currency(item.SaldoPagar),
        }));
      } else {
        data = await billsToPayRepository.generateBillsPayableTitleAnalytical({
          companyId,
          initialDate,
          finalDate,
          providerId: providerId ? providerId?.value : '',
          dateType,
          titleStatus,
          cashierBankId,
          sortByCustomer: sortByCustomer === 'false' ? '' : 1,
        });

        if (data.titlesQuantity === 0 || !data || data.length === 0) {
          toastr.warning(
            'Não foi possível gerar o relatório.',
            'Não há dados no período e filtros informado.'
          );
          return;
        }

        if (sortByCustomer === 'false') {
          excelData = Object.keys(data.titles)
            .reduce((prev, curr) => {
              return [...prev, ...data.titles[curr].rows];
            }, [])
            .map((item) => ({
              dueDate: format(new Date(item.dueDate), 'dd/MM/yyyy'),
              titleCode: item.titleCode,
              purchaseCode: item.purchaseCode,
              nfCode: item.nfCode,
              daysOverdue: item.daysOverdue,
              providerName: item.provider,
              providerPhone: phoneMask(item.providerPhone),
              providerCpfCnpj: cnpjMask(item.providerCpfCnpj),
              dischargeDate: format(new Date(item.dischargeDate), 'dd/MM/yyyy'),
              cashierBank: item.cashierBank,
              amount: currency(item.amount),
              accountPlan: item.accountPlan,
              observations: item.observations,
            }));
        } else {
          excelData = Object.keys(data)
            .reduce((prev, curr) => {
              return [
                ...prev,
                ...Object.keys(data[curr].rows).reduce((prev2, curr2) => {
                  return [...prev2, ...data[curr].rows[curr2].rows];
                }, []),
              ];
            }, [])
            .map((item) => ({
              dueDate: format(new Date(item.dueDate), 'dd/MM/yyyy'),
              titleCode: item.titleCode,
              purchaseCode: item.purchaseCode,
              nfCode: item.nfCode,
              daysOverdue: item.daysOverdue,
              providerName: item.provider,
              providerPhone: phoneMask(item.providerPhone),
              providerCpfCnpj: cnpjMask(item.providerCpfCnpj),
              dischargeDate: format(new Date(item.dischargeDate), 'dd/MM/yyyy'),
              cashierBank: item.cashierBank,
              amount: currency(item.amount),
              accountPlan: item.accountPlan,
              observations: item.observations,
            }));
        }
      }

      handlePrint();

      setExcelData(excelData);
      setValidateExcel(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    if (reportType === 'synthetic') {
      window.open(
        window.location.origin +
          `/report/accounts-payable-synthetic?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&providerId=${
            providerId === null ? '' : providerId?.value
          }&dateType=${dateType}&titleStatus=${titleStatus}&cashierBanksValue=${cashierBankId}&reportType=${reportType}`,
        '_blank'
      );
    } else {
      window.open(
        window.location.origin +
          `/report/accounts-payable-analytical?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&providerId=${
            providerId === null ? '' : providerId?.value
          }&dateType=${dateType}&titleStatus=${titleStatus}&cashierBanksValue=${cashierBankId}&displayNotes=${displayNotes}&sortByCustomer=${sortByCustomer}&reportType=${reportType}`,
        '_blank'
      );
    }
  };

  return (
    <div>
      <AccountsPayableFilter
        initialDate={initialDate}
        finalDate={finalDate}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        providersSuggestions={providersSuggestions}
        isLoading={isLoading}
        showSecond={showSecond}
        setShowSecond={setShowSecond}
        excelData={excelData}
        cashierBanks={cashierBanks}
        dateType={dateType}
        setDateType={setDateType}
        reportType={reportType}
        setReportType={setReportType}
        titleStatus={titleStatus}
        setTitleStatus={setTitleStatus}
        cashierBanksValue={cashierBankId}
        setCashierBanksValue={setCashierBanksValue}
        displayNotes={displayNotes}
        setDisplayNotes={setDisplayNotes}
        sortByCustomer={sortByCustomer}
        setSortByCustomer={setSortByCustomer}
        providerId={providerId}
        setProviderId={setProviderId}
        validateExcel={validateExcel}
        handlePrint={handleSubmit}
      />
    </div>
  );
};

export default AccountsPayableMain;
