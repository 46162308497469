import React from "react";
import { Grid, Row } from "react-bootstrap";

import FormClient from './FormClient'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const NewClient = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Clientes', !!match.params.id ? 'Editar Cliente' : 'Novo Cliente']}
            path={['home', 'customers', null]}
          />
          <FormClient
            newClient={!match.params.id}
            ClientId={match.params.id}
          />
        </Row>
      </Grid>
    </div>
  );
}

export default NewClient