import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { change, initialize } from 'redux-form'

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../../../components/CustomButton/CustomButton.jsx'
import FormCompanyTaxData from '../../../../MyCompanyTaxData/FormCompanyTaxData/TaxData'
import companiesRepository from '../../../../../../repositories/Companies'
import { useAuth } from '../../../../../../contexts/auth'
import { cpfMask } from 'client/components/ToNormalize/ToNormalize.js'
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize.js'

const CompanyTaxErrorsTable = ({ errors, onCorrection }) => {
  const [companyTaxErrors, setCompanyTaxErrors] = useState([])
  const [indexToEdit, setIndexToEdit] = useState(null)
  const [isCompanyTaxModalOpen, setIsCompanyTaxModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  useEffect(() => {
    setCompanyTaxErrors(errors)
  }, [errors])

  const loadCompanyTaxData = async () => {
    try {
      const companyTaxData = await companiesRepository.getTaxData(companyId)
      loadTaxData(companyTaxData)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar as informações fiscais. Por favor, tente novamente'
      )
    }
  }

  const loadTaxData = (values) => {
    const {
      TaxRegime,
      PIS,
      COFINS,
      accountingCpfCnpj,
      accountingEmail,
      tokenIBPT,
      isDigitalCertificateImported,
      digitalCertificatePassword,
      digitalCertificateExpireDate,
      IE,
      SettingsNFCe,
    } = values

    const data = {
      taxRegime: TaxRegime,
      PIS,
      IE,
      COFINS,
      accountingCpfCnpj: !accountingCpfCnpj
        ? ''
        : accountingCpfCnpj.length <= 11
        ? cpfMask(accountingCpfCnpj)
        : cnpjMask(accountingCpfCnpj),
      accountingEmail,
      tokenIBPT,
      isDigitalCertificateImported,
      digitalCertificatePassword,
      digitalCertificateExpireDate:
        digitalCertificateExpireDate === '0000-00-00'
          ? null
          : digitalCertificateExpireDate,
      NFCePrintingTemplate: SettingsNFCe?.printingTemplate,
    }
    dispatch(initialize('settingsTaxData', data))
  }

  async function handleOpenCompanyTaxModal(index) {
    await loadCompanyTaxData()
    setIndexToEdit(index)
    setIsCompanyTaxModalOpen(true)
  }

  function handleSubmitCompanyTaxModal() {
    const newErrors = [...companyTaxErrors]
    newErrors[indexToEdit].isResolved = true

    setIsCompanyTaxModalOpen(false)
    onCorrection(newErrors)
    // loadNFSeSettings()
  }

  return (
    <>
      <ReactTable
        className='company-tax-errors-table'
        data={errors}
        columns={[
          {
            Header: '',
            accessor: 'isResolved',
            headerClassName: 'text-left',
            width: 50,
            Cell: (props) => (
              <FontAwesomeIcon
                icon={props.value ? faCheckCircle : faTimesCircle}
                color={props.value ? 'green' : 'red'}
                size={30}
              />
            ),
          },
          {
            Header: 'Item',
            accessor: 'item',
            headerClassName: 'text-left',
            width: 250,
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 250,
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
            Cell: (props) => (
              <span
                className='hiperlink'
                onClick={() => handleOpenCompanyTaxModal(props.index)}
              >
                {props.value}
              </span>
            ),
          },
        ]}
        defaultPageSize={5}
        sortable={false}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText=''
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      <Modal
        dialogClassName='edit-company-tax-modal-wrapper'
        show={isCompanyTaxModalOpen}
        onHide={() => setIsCompanyTaxModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Configurações Fiscais</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-company-tax-content'>
          <FormCompanyTaxData onSubmit={handleSubmitCompanyTaxModal} />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            bsStyle='danger'
            fill
            onClick={() => setIsCompanyTaxModalOpen(false)}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CompanyTaxErrorsTable
