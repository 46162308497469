import React from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusConfirmationSaleWithoutBonusModal = ({
  isOpen,
  handleCancel,
  handleProceed,
}) => {
  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Deseja realizar o fechamento da venda{' '}
          <strong>sem a possibilidade</strong> de{' '}
          <u>consultar e lançar bônus para a próxima compra?</u>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-75px" onClick={handleCancel}>
            Voltar
          </button>
          <button
            className="button button-green w-75px"
            onClick={handleProceed}
          >
            Sim
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
