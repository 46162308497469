import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import HeaderFooter from '../../../components/PdvOnline'
import Payment from './Payment'
import SelectProducts from './SelectProducts'
import PureLoader from 'components/PureLoader'

const FormPDV = ({
  handleSubmit,
  onOpenOrcaments,
  onOpenCashierBankHistoric,
  onOpenListNFCe,
  hasSettingsNFCe,
  hasCashierControl,
  loading,
}) => {
  const { step } = useSelector((state) => state.form.pdvOnline.values)

  return (
    <HeaderFooter
      onSubmit={handleSubmit}
      onOpenOrcaments={onOpenOrcaments}
      onOpenListNFCe={onOpenListNFCe}
      hasSettingsNFCe={hasSettingsNFCe}
      hasCashierControl={hasCashierControl}
      onOpenCashierBankHistoric={onOpenCashierBankHistoric}
      loading={loading}
    >
      <div id="pdv-content">
        {step === 1 ? <SelectProducts /> : <Payment />}
      </div>
      {loading && <PureLoader message="Aguarde" />}
    </HeaderFooter>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'pdvOnline',
})(FormPDV)
