import React, { useState, useEffect } from 'react';
import {
  BirthdayRow,
  BirthdayRowFieldset,
  ClientsMain,
} from './Clients.styles';
import { useAuth } from 'contexts/auth';
import HeaderMenu from './HeaderMenu';
import Card from 'components/Card/Card';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { format, isBefore } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { toastr } from 'react-redux-toastr';
import { CRMCampaignRepository } from 'v2/repositories/CRMCampaignRepository';
import { validadeCellphone } from 'client/components/Validations/Validations';
import { Table } from 'v2/components/Table';
import { useFormContext } from 'react-hook-form';
import PhoneUpdateModal from '../../modals/PhoneUpdateModal';
import { ButtonWrapper, InlineWrapper } from '../../CampaignForm.styles';
import { Button } from 'v2/components/Button/DefaultButton';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { formatToBRL, formatToCPFOrCNPJ } from 'brazilian-values';
import useCRMStore from 'v2/views/client/CRM/store/crm-store';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

const Clients = () => {
  const { companyId } = useAuth();
  const { isLoading, fetchLoading } = useLoading();
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState('');
  const [newPhone, setNewPhone] = useState('');

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { setValue, watch } = useFormContext();
  const { selectedCard, setSelectedCard } = useCRMStore();
  const Customer = watch('Customer');
  const isReview = watch('isReview');
  const {
    customSubtype,
    initialDate,
    finalDate,
    phoneFilter,
    customersIds,
    tableData,
    totalPages,
    cards,
    totalValueCustomers,
  } = Customer;
  const type = watch('type');

  const handleFiltersComponent = () => {
    switch (type.label) {
      case 'Personalizado':
        return (
          <>
            <InputContainer>
              <InputLabel>Tipo de cliente:</InputLabel>
              <InputSelectDefault
                width={'200px'}
                height={'35px'}
                options={[
                  { label: 'Todos os clientes', value: '' },
                  { label: 'Data de Cadastro', value: 'createPeriod' },
                  { label: 'Última visita até...', value: 'returnSince' },
                ]}
                defaultValue={{ label: 'Todos os clientes', value: '' }}
                value={customSubtype}
                disabled={isReview}
                isLoading={isLoading}
                onChange={(option) => {
                  setValue('Customer.customSubtype', option);
                  setValue('Customer.initialDate', '');
                  setValue('Customer.finalDate', '');
                }}
              />
            </InputContainer>

            {customSubtype.label !== 'Todos os clientes' && (
              <>
                <InputContainer>
                  <InputLabel required>Data inicial:</InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={50}
                    height={'35px'}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                    isDisabled={isReview}
                    isLoading={isLoading}
                    type="date"
                    value={initialDate}
                    onChange={(e) =>
                      setValue('Customer.initialDate', e.target.value)
                    }
                  />
                </InputContainer>

                {customSubtype.label !== 'Última visita até...' && (
                  <InputContainer>
                    <InputLabel required>Data final:</InputLabel>
                    <InputText
                      id="input__description"
                      maxLength={50}
                      height={'35px'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                      type="date"
                      disabled={isReview}
                      isLoading={isLoading}
                      value={finalDate}
                      onChange={(e) =>
                        setValue('Customer.finalDate', e.target.value)
                      }
                    />
                  </InputContainer>
                )}
              </>
            )}
          </>
        );
      case 'Retorno/Revisão':
        return (
          <>
            <InputContainer>
              <InputLabel required>Data inicial:</InputLabel>
              <InputText
                id="input__description"
                maxLength={50}
                height={'35px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                type="date"
                value={initialDate}
                isDisabled={isReview}
                isLoading={isLoading}
                onChange={(e) =>
                  setValue('Customer.initialDate', e.target.value)
                }
              />
            </InputContainer>
            <InputContainer>
              <InputLabel required>Data final:</InputLabel>
              <InputText
                id="input__description"
                maxLength={50}
                height={'35px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                type="date"
                value={finalDate}
                isDisabled={isReview}
                isLoading={isLoading}
                onChange={(e) => setValue('Customer.finalDate', e.target.value)}
              />
            </InputContainer>
          </>
        );
      case 'Aniversário':
        return (
          <>
            <InputContainer>
              <InputLabel required>Data inicial:</InputLabel>
              <InputText
                id="input__description"
                maxLength={50}
                height={'35px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                type="date"
                value={initialDate}
                isDisabled={isReview}
                isLoading={isLoading}
                onChange={(e) =>
                  setValue('Customer.initialDate', e.target.value)
                }
              />
            </InputContainer>
            <InputContainer>
              <InputLabel required>Data final:</InputLabel>
              <InputText
                id="input__description"
                maxLength={50}
                height={'35px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                type="date"
                value={finalDate}
                isDisabled={isReview}
                isLoading={isLoading}
                onChange={(e) => setValue('Customer.finalDate', e.target.value)}
              />
            </InputContainer>
          </>
        );
    }
  };
  const handleSelectAll = async (e) => {
    e.preventDefault();

    if (customersIds.length >= cards.correctPhoneTotalCount) {
      setValue('Customer.totalValueCustomers', 0);
      return setValue('Customer.customersIds', []);
    }

    await handleLoadAllCustomers();
  };

  const handleLoadAllCustomers = async () => {
    const loadData = async () => {
      try {
        const response = await CRMCampaignRepository.getAllCorrectCustomers({
          filterType: type.label,
          initialDate,
          finalDate,
          companyId,
          customSubtype: customSubtype?.value ?? '',
        });
        setValue('Customer.customersIds', response.customerIds);
        setValue('Customer.totalValueCustomers', response.totalValuesSales);
      } catch (error) {
        console.log(error);
      }
    };
    await fetchLoading(loadData);
  };

  const validateDates = () => {
    if (
      type.label === 'Personalizado' &&
      customSubtype.label === 'Todos os clientes'
    )
      return true;

    if (
      type.label === 'Personalizado' &&
      customSubtype.label === 'Última visita até...' &&
      initialDate
    )
      return true;

    if (!initialDate || !finalDate) {
      toastr.warning(
        'Data não informada',
        'Preencha a data para carregar os clientes aniversariantes'
      );
      return false;
    }

    if (
      isBefore(getDateOnlyFromDate(finalDate), getDateOnlyFromDate(initialDate))
    ) {
      toastr.warning('Selecione um período válido');
      return false;
    }

    return true;
  };

  const handleValidateSubmit = async () => {
    if (!validateDates()) return;

    setCurrentPage(0);
    await handleLoadCustomerHeader();
  };

  const handleValidatePhone = (phone) => {
    if (!phone) {
      return 'Adicionar Celular';
    }
    if (validadeCellphone(phone || '')) {
      return phoneMask(phone);
    }
    return 'Corrigir Celular';
  };

  const handleLoadCustomerData = async () => {
    const loadData = async () => {
      try {
        const response = await CRMCampaignRepository.getCustomers({
          filterType: type.label,
          initialDate,
          finalDate,
          companyId,
          phoneFilter: phoneFilter?.value ?? '',
          limit: pageLimit,
          page: currentPage + 1,
          customSubtype: customSubtype?.value ?? '',
        });

        setValue('Customer.tableData', response.data);
        setValue('Customer.totalPages', Math.ceil(response.count / pageLimit));
      } catch (err) {
        console.log(err);
        toastr.warning(
          'OS Digital',
          'Ocorreu um erro ao buscar os aniversariantes. Por favor, tente novamente!'
        );
      }
    };

    await fetchLoading(loadData);
  };

  const handleLoadCustomerHeader = async () => {
    const loadData = async () => {
      try {
        const response = await CRMCampaignRepository.getCustomerHeader({
          filterType: type.label,
          initialDate,
          finalDate,
          companyId,
          customSubtype: customSubtype?.value ?? '',
        });
        setValue('Customer.cards', response);

        if (customSubtype.label == 'Celular Incompleto') {
          setValue('Customer.customersIds', []);
          setValue('Customer.totalValueCustomers', 0);
        } else {
          setValue('Customer.customersIds', response.customerIds);
          setValue(
            'Customer.totalValueCustomers',
            response.correctPhoneTotalValue || 0
          );
        }

        await handleLoadCustomerData();
      } catch (err) {
        console.log(err);
      }
    };

    await fetchLoading(loadData);
  };

  const handlePhoneClick = (props) => {
    if (isReview) {
      return;
    }
    setSelectedPhone(props.numberPhone);
    setNewPhone(props.numberPhone || '');
    setSelectedCustomerId(props.id);
    setIsPhoneModalOpen(true);
  };

  const getColumnByType = () => {
    switch (type.label) {
      case 'Aniversário':
        return {
          Header: 'Aniversário',
          width: 150,
          accessor: 'dateBirth',
          Cell: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM') : '';
          },
          headerClassName: 'text-left',
        };
      case 'Personalizado':
        return {
          Header: 'Última Visita',
          width: 150,
          accessor: 'lastSale',
          Cell: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM/yyyy') : '';
          },
          headerClassName: 'text-left',
        };
      case 'Retorno/Revisão':
        return {
          Header: 'Dt Retorno',
          width: 150,
          accessor: 'forecastReturn',
          Cell: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM/yyyy') : '';
          },
          headerClassName: 'text-left',
        };
      default:
        return '';
    }
  };

  useEffect(() => {
    if (cards !== null && !isReview) {
      handleLoadCustomerData();
    }
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if (selectedCard) {
      handleLoadCustomerHeader();
      setSelectedCard(null);
    }
  }, []);

  const columns = [
    {
      Header: '',
      accessor: 'checkbox',
      width: 40,
      Cell: (props) => (
        <input
          type="checkbox"
          checked={
            isReview
              ? true
              : customersIds
              ? customersIds.includes(tableData[props.index].id)
              : false
          }
          disabled={
            isReview
              ? true
              : !validadeCellphone(props.original.numberPhone || '-')
          }
          onChange={() => {
            const client = tableData[props.index];
            const isSelected = customersIds.includes(tableData[props.index].id);
            if (isSelected) {
              setValue(
                'Customer.customersIds',
                customersIds.filter((id) => id !== client.id)
              );
              setValue(
                'Customer.totalValueCustomers',
                totalValueCustomers - (client.totalValueSales || 0)
              );
            } else {
              setValue('Customer.customersIds', [...customersIds, client.id]);
              setValue(
                'Customer.totalValueCustomers',
                totalValueCustomers + (client.totalValueSales || 0)
              );
            }
          }}
        />
      ),
    },
    {
      Header: 'Nome do Cliente',
      accessor: 'name',
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 160,
      Cell: ({ value }) => {
        return (
          <a style={{ textAlign: 'center' }}>
            {formatToCPFOrCNPJ(value || '')}
          </a>
        );
      },
      headerClassName: 'text-center',
    },
    {
      Header: 'Celular',
      accessor: 'numberPhone',
      width: 130,
      headerClassName: 'text-left',
      Cell: (props) => {
        return (
          <span
            style={{
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline',
            }}
            disabled={isReview}
            onClick={() => handlePhoneClick(props.original)}
          >
            {handleValidatePhone(props.value)}
          </span>
        );
      },
    },
    { ...getColumnByType() },
    {
      Header: 'R$ Total Gasto',
      accessor: 'totalValueSales',
      width: 140,
      Cell: ({ value }) => {
        return formatToBRL(value || 0);
      },
      headerClassName: 'text-left',
    },
    {
      Header: 'Ticket Médio',
      accessor: 'averageTicket',
      width: 140,
      Cell: ({ value }) => {
        return formatToBRL(value);
      },
      headerClassName: 'text-left',
    },
    {
      Header: 'Qtd Venda',
      accessor: 'quantitySales',
      width: 90,
      headerClassName: 'text-left',
    },
    {
      Header: 'Qtd OS',
      accessor: 'quantityOS',
      width: 60,
      headerClassName: 'text-left',
    },
  ];

  return (
    <ClientsMain>
      <Card
        content={
          <main>
            <InlineWrapper>
              {handleFiltersComponent()}
              <InputContainer>
                <InputLabel required>Cliente:</InputLabel>
                <InputSelectDefault
                  width={'150px'}
                  height={'35px'}
                  options={[
                    { label: 'Todos', value: '' },
                    { label: 'Celular Completo', value: 'correctPhone' },
                    { label: 'Celular Incompleto', value: 'incorrectPhone' },
                  ]}
                  defaultValue={{ label: 'Todos', value: '' }}
                  value={phoneFilter}
                  disabled={isReview}
                  isLoading={isLoading}
                  onChange={(option) => {
                    setValue('Customer.phoneFilter', option);
                  }}
                />
              </InputContainer>
              <ButtonWrapper>
                <Button
                  height="35px"
                  color="green300"
                  gap="5px"
                  disabled={isReview}
                  loading={isLoading}
                  onClick={handleValidateSubmit}
                >
                  Processar Clientes
                </Button>
              </ButtonWrapper>
            </InlineWrapper>
            <InlineWrapper
              style={{ justifyContent: 'space-between', paddingBottom: '5px' }}
            >
              <HeaderMenu headerData={cards} />
            </InlineWrapper>
            {!isReview &&
              cards &&
              tableData?.length > 0 &&
              phoneFilter.label !== 'Celular Incompleto' && (
                <a
                  href="#"
                  onClick={handleSelectAll}
                  style={{ fontSize: '12px', marginTop: '15px' }}
                >
                  {customersIds.length >= cards.correctPhoneTotalCount
                    ? `Remover Todos (${
                        customersIds.length ? customersIds.length : 'x'
                      })`
                    : `Selecionar Todos (${
                        cards.correctPhoneTotalCount
                          ? cards.correctPhoneTotalCount
                          : 'x'
                      })`}
                </a>
              )}
            {isReview ? (
              <Table columns={columns} data={tableData} loading={isLoading} />
            ) : (
              <Table
                columns={columns}
                data={tableData}
                pageSize={pageLimit}
                page={currentPage}
                onPageChange={setCurrentPage}
                manual
                onPageSizeChange={setPageLimit}
                pages={totalPages}
                loading={isLoading}
              />
            )}
            <PhoneUpdateModal
              show={isPhoneModalOpen}
              onHide={() => setIsPhoneModalOpen(false)}
              currentPhone={selectedPhone}
              customerId={selectedCustomerId}
              newPhone={newPhone}
              setNewPhone={setNewPhone}
              refreshCards={handleLoadCustomerHeader}
              handleLoadCustomerData={handleLoadCustomerData}
            />
          </main>
        }
      />
    </ClientsMain>
  );
};

export default Clients;
