import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import constants from 'utils/constants';
import HomeInvoices from './HomeInvoicesReport/HomeInvoices';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const InvoicesReport = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Nota Fiscal',
              'Listagem de Notas Fiscais',
            ]}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <HomeInvoices />
        </Row>
      </Grid>
    </div>
  );
};
export default InvoicesReport;
