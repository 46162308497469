import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { addDays, format } from 'date-fns'
import BillsToReceiveRepository from 'repositories/BillsToReceive'
import ValuesByStatusPDF from './ValuesByStatusPDF'

const ValuesByStatusReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [origin, setOrigin] = useState('billsToReceive')
  const [typeOfDate, setTypeOfDate] = useState('creation')
  const [result, setResult] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const companyId = searchParams.get('companyId')
      const origin = searchParams.get('origin')
      const typeOfDate = searchParams.get('typeOfDate')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))
      setOrigin(origin)
      setTypeOfDate(typeOfDate)

      return loadReport({
        origin,
        typeOfDate,
        companyId,
        initialDate,
        finalDate,
      })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (params) => {
    try {
      const data = await BillsToReceiveRepository.generateValuesByStatus({
        ...params
      })

      setResult(data)
      setLoading(false)
      return data
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <ValuesByStatusPDF
            result={result}
            initialDate={initialDate}
            finalDate={finalDate}
            typeOfDate={typeOfDate}
            origin={origin}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default ValuesByStatusReport
