import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import accountPlansRepository from 'repositories/AccountPlans';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { SearchFinancialTitleContainer } from './BankConciliationDetailsSearch.styles';
import { useBankConciliation } from 'v2/views/client/BankConciliation/hooks/useBankConciliation';

export function SearchFinancialTitle({ prefix, typeSearch }) {
  const { companyId } = useAuth();
  const { register, getValues, setValue, watch } = useFormContext();
  const isViewing = watch('isViewing');
  const [accountPlans, setAccountPlans] = React.useState([]);
  const [selectedAccountPlan, setSelectedAccountPlan] = useState('');

  const [selectedType, setSelectedType] = React.useState('');
  const [selectedStatus, setSelectedStatus] = React.useState('');
  const [optionsTransactionsType, setOptionsTransactionsType] = React.useState(
    []
  );

  const [defaultInitialDate, setDefaultInitialDate] = useState('');
  const [defaultFinishDate, setDefaultFinishDate] = useState('');

  const { typeOptions, typeOptionsMovement, statusOptions } =
    useBankConciliation(getValues, setValue);

  async function loadActiveAccountPlans() {
    if (optionsTransactionsType.length === 0) {
      try {
        const { data } = await accountPlansRepository.index({ companyId });

        const accountPlansCredit = data.filter(
          (accountPlan) => accountPlan.type === 'Crédito'
        );
        setAccountPlans(accountPlansCredit);
      } catch (err) {
        toastr.warning(
          'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente.'
        );
      }
    }
  }

  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  React.useEffect(() => {
    setValue(`search.${prefix}.transactionType`, {
      id: 0,
      label: 'Ambos',
    });
    setValue(`search.${prefix}.transactionStatus`, {
      id: 0,
      label: 'Ambos',
    });

    setSelectedType({
      id: 0,
      label: 'Ambos',
    });
    setSelectedStatus({
      id: 0,
      label: 'Ambos',
    });

    setDefaultInitialDate(formatDate(lastWeek));
    setDefaultFinishDate(formatDate(today));
    if (isViewing) return;
    loadActiveAccountPlans();
  }, [typeSearch]);

  const resetTypeAndStatus = (value) => {
    setSelectedType('');
    setSelectedStatus('');
    setValue(`search.${prefix}.transactionType`, {
      id: 0,
      label: 'Ambos',
    });
    setValue(`search.${prefix}.transactionStatus`, {
      id: 0,
      label: 'Ambos',
    });
    setValue(`search.${prefix}.TransactionInitialDate`, formatDate(lastWeek));
    setValue(`search.${prefix}.TransactionFinishDate`, formatDate(today));
    setValue(`search.${prefix}.typeFinancialRegister`, value);
    setDefaultInitialDate(formatDate(lastWeek));
    setDefaultFinishDate(formatDate(today));
  };

  return (
    <SearchFinancialTitleContainer>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
          }}
        >
          <input
            style={{ margin: '0px' }}
            type="radio"
            name="options"
            value="titulo"
            defaultChecked
            {...register(`search.${prefix}.typeFinancialRegister`)}
            onChange={(e) => resetTypeAndStatus(e.target.value)}
          />
          <label htmlFor="Titulo">Título</label>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
          }}
          onChange={(e) => resetTypeAndStatus(e.target.value)}
        >
          <input
            style={{ margin: '0px' }}
            type="radio"
            name="options"
            value="movimentação"
            {...register(`search.${prefix}.typeFinancialRegister`)}
          />
          <label htmlFor="Movimentação">Movimentação/Transação</label>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <div className="box-input search" style={{ marginLeft: '0px' }}>
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <input
            type="text"
            style={{
              paddingLeft: '9%',
              minWidth: typeSearch === 'titulo' ? '370px' : '350px',
            }}
            disabled={isViewing}
            className="form-control"
            placeholder={
              typeSearch === 'titulo'
                ? 'Nº do Título, Venda/Compra ou Cliente/Fornecedor'
                : 'Código, Descrição ou Valor'
            }
            {...register(`search.${prefix}.description`)}
          />
        </div>

        <div className="box-input label-input">
          <strong>
            Tipo:
          </strong>
          <select
            id={`${prefix}.transactionType`}
            name={`${prefix}.transactionType`}
            className="form-control foco-input"
            value={selectedType?.id}
            disabled={isViewing}
            onChange={(e) => {
              const selectedId = parseInt(e.target.value, 10);
              const selectedOption = (
                typeSearch === 'titulo' ? typeOptions : typeOptionsMovement
              ).find((option) => option.id === selectedId);

              setSelectedType(selectedOption);
              setValue(`search.${prefix}.transactionType`, selectedOption);
            }}
          >
            {(typeSearch === 'titulo' ? typeOptions : typeOptionsMovement)?.map(
              ({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div className="box-input">
          <strong style={{ width: '100%' }}>
            Status:
          </strong>
          <select
            id="status"
            name="status"
            className="form-control foco-input"
            value={selectedStatus?.id}
            disabled={isViewing}
            onChange={(e) => {
              const selectedOption = statusOptions.find(
                (option) => option.id === parseInt(e.target.value, 10)
              );
              setSelectedStatus(selectedOption);
              setValue(`search.${prefix}.transactionStatus`, selectedOption);
            }}
          >
            {statusOptions?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="box-input">
          <strong style={{ width: '100%' }}>
            Data Inicial:
          </strong>
          <input
            defaultValue={defaultInitialDate}
            type="date"
            id="dataInicial"
            name="dataInicial"
            disabled={isViewing}
            className="form-control foco-input"
            {...register(`search.${prefix}.TransactionInitialDate`)}
          />
        </div>
        <div className="box-input">
          <strong style={{ width: '100%' }}>
            Data Final:
          </strong>
          <input
            defaultValue={defaultFinishDate}
            type="date"
            id="dataFinal"
            name="dataFinal"
            disabled={isViewing}
            className="form-control foco-input"
            {...register(`search.${prefix}.TransactionFinishDate`)}
          />
        </div>
      </div>

      {typeSearch === 'titulo' && (
        <div
          className="box-input"
          style={{ display: 'flex', maxWidth: '310px' }}
        >
          <strong style={{ width: 180 }}>Plano de Contas:</strong>
          <select
            className="form-control foco-input"
            value={selectedAccountPlan}
            disabled={isViewing}
            onChange={(e) => {
              setSelectedAccountPlan(e.target.value);
            }}
          >
            <option value="">Todas</option>
            {accountPlans.length > 0 &&
              accountPlans?.map((accountPlan) => (
                <option key={accountPlan.Id} value={accountPlan.Id}>
                  {accountPlan.description}
                </option>
              ))}
          </select>
          <span style={{ color: 'red', fontSize: '12px' }}>
            Apenas títulos com forma de pagamento serão exibidos*
          </span>
        </div>
      )}
    </SearchFinancialTitleContainer>
  );
}
