import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import NewTaxByState from './FormNewTaxByState/FormNewTaxByState';

export default function ViewNewTaxByState({ match }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'Tributo por Estado', !!match.params.id ? 'Editar Tributo por Estado' : 'Novo Tributo por Estado']}
              path={['home', null, null, 'taxes-by-state', null]} />
            <span style={{color: 'red', position: 'absolute', right: 10}}>* Campos Obrigatórios</span>
          </div>
          <NewTaxByState id={match.params.id}/>
        </Row>
      </Grid>
    </div>
  );
};