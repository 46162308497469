import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import HomeStock from './Stock'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Stock = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Movimentação de Estoque']}
            path={['home', 'products', null]}
          />
          <HomeStock />
        </Row>
      </Grid>
    </div>
  )
}
export default Stock
