import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import ProductsServicesTable from '../../../../components/ProductsServicesTable';
import StockSheet from '../../../../components/ProductsServicesTable/Sheets/StockSheet';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import AlertModal from 'components/AlertModal/AlertModal';

const ItemsModal = ({ onCancel, onSubmit }) => {
  const [items, setItems] = useState([]);
  const [isClearSheetModalOpen, setIsClearSheetModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleAdd = async (item) => {
    const { id, Code, Description, Sale_Price } = item;

    const newItems = [...items];

    const index = newItems.findIndex((child) => child.productId === id);

    if (index !== -1) {
      newItems[index].quantity++;
    } else {
      newItems.push({
        productId: id,
        code: Code,
        description: Description,
        quantity: 1,
        unitValue: Sale_Price,
      });
    }

    setItems(newItems);
  };

  const handleClearSheet = () => {
    setItems([]);
    setIsClearSheetModalOpen(false);
  };

  return (
    <>
      <Modal
        show
        onHide={() => (!items.length ? onCancel() : setIsCancelModalOpen(true))}
        animation
        dialogClassName="NFe-items-modal-wrapper modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Adicionar Itens</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="NFe-items-modal">
            <ProductsServicesTable productsList onInclude={handleAdd} />
            <StockSheet items={items} setItems={setItems} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end align-center">
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
              type="button"
              bsStyle="danger"
              pullRight
              fill
              onClick={() =>
                !items.length ? onCancel() : setIsCancelModalOpen(true)
              }
            >
              Cancelar
            </Button>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
              disabled={items.length === 0}
              pullRight
              type="button"
              bsStyle="primary"
              onClick={() => setIsClearSheetModalOpen(true)}
              fill
            >
              Limpar Itens
            </Button>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
              type="button"
              bsStyle="info"
              pullRight
              fill
              onClick={() => onSubmit(items)}
            >
              Adicionar Itens
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        message="Deseja limpar todos os itens ?"
        show={isClearSheetModalOpen}
        onHide={() => setIsClearSheetModalOpen(false)}
        onCancel={() => setIsClearSheetModalOpen(false)}
        onSubmit={handleClearSheet}
      />

      <AlertModal
        message="Deseja fechar o modal e não adicionar os itens selecionados ?"
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </>
  );
};

export default ItemsModal;
