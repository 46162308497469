import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  cpfMask,
  cnpjMask,
  cepMask,
  phoneMask,
  currency,
  kmMask,
} from '../../client/components/ToNormalize/ToNormalize'
import { format, isPast, addHours } from 'date-fns'
import constants from '../../utils/constants'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const ItemsHeaderVehicle = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Placa</Text>
    </View>
    <View style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Tipo</Text>
    </View>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowVehicle = ({ vehicle, salesTypeId, amount }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{vehicle?.License_Plate || 'Sem Placa'}</Text>
    </View>
    <View style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>
        {constants.SALE_TYPE_DESCRIPTION[salesTypeId]}
      </Text>
    </View>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(amount)}</Text>
    </View>
  </View>
)

const TitlesHeader = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Título</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Vencimento</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Venda</Text>
    </View>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const TotalHeader = ({ totalAmount }) => (
  <View
    style={{
      flexDirection: 'row',
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '50%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={{ fontSize: 14 }}>Total</Text>
    </View>
    <View style={{ width: '50%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={{ fontSize: 14 }}>{currency(totalAmount)}</Text>
    </View>
  </View>
)

const TitlesRow = ({ code, dueDate, saleCode, amount }) => (
  <View
    style={{ flexDirection: 'row', height: 12, width: '100%' }}
    wrap={false}
  >
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{code}</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>
        {format(addHours(new Date(dueDate), 3), 'dd/MM/yyyy')}
      </Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{saleCode}</Text>
    </View>
    <View style={{ width: '50%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(amount)}</Text>
    </View>
  </View>
)

const SalesHeader = ({ Code, createdAt, updatedAt, vehicle, kilometers }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 15,
    }}
  >
    <ItemLabel label="Venda/OS" value={Code} />
    <ItemLabel
      label="Data de Criação"
      value={format(new Date(createdAt), 'dd/MM/yyyy')}
    />
    <ItemLabel
      label="Data de Fechamento"
      value={format(new Date(updatedAt), 'dd/MM/yyyy')}
    />
    <ItemLabel label="Placa" value={vehicle?.License_Plate || ''} />
    <ItemLabel
      label="KM Atual"
      value={kilometers ? kmMask(String(kilometers)) : ''}
    />
  </View>
)

const ItemsHeaderSales = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descrição</Text>
    </View>
    <View style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Tipo</Text>
    </View>
    <View style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Qtd</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>R$ Unit</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Garantia</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Revisão</Text>
    </View>
  </View>
)

const ItemRowSales = ({ item }) => {
  const type = item.Type?.substring(0, 1)
  const serviceOrProduct =
    type === 'P' ? item.Products || {} : item.Services || {}
  const warranty = serviceOrProduct?.Warranty
  const review = serviceOrProduct?.Review

  return (
    <View
      style={{ flexDirection: 'row', height: 12, width: '100%' }}
      wrap={false}
    >
      <View
        style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {serviceOrProduct.Code} - {item.Description}
        </Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{type}</Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Quantity}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {currency(item.Unit_Value - item.Unit_Discount_Value)}
        </Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Amount)}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {!!warranty
            ? `${warranty.Kilometers} km ou ${warranty.Months} meses`
            : ''}
        </Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {!!review ? `${review.Km} km ou ${review.Months} meses` : ''}
        </Text>
      </View>
    </View>
  )
}

export const SheetsCustomerHistoryPDF = ({
  sales = [],
  customer = {},
  valuesByVehicle = [],
  initialDate,
  finalDate,
  titles = [],
  saleId,
}) => {
  const customerAddress = customer.Address.length ? customer.Address[0] : {}
  const customerPhone = customer.Phones.length
    ? customer.Phones[0].Number_Phone2
    : ''

  return (
    <Document
      title={`impresso-historico-cliente-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>Histórico do Cliente</Text>
          <Text>
            {initialDate} - {finalDate}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: 10,
          }}
        >
          <View>
            <ItemLabel label="Cliente" value={customer.Company_Name} />
            <ItemLabel
              label="Endereço"
              value={
                !!customerAddress.Address
                  ? `${customerAddress.Address}, ${customerAddress.Address_Number}`
                  : ''
              }
            />
            <ItemLabel
              label="Cidade"
              value={
                !!customerAddress.Address
                  ? `${customerAddress.City} / ${customerAddress.State}`
                  : ''
              }
            />
            <ItemLabel label="Email" value={customer.Email} />
          </View>
          <View style={{ marginRight: 80 }}>
            <ItemLabel
              label="CPF/CNPJ"
              value={
                customer.Cpf_Cnpj.length > 11
                  ? cnpjMask(customer.Cpf_Cnpj)
                  : cpfMask(customer.Cpf_Cnpj)
              }
            />
            <ItemLabel label="Bairro" value={customerAddress.Neighborhood} />
            <ItemLabel label="CEP" value={cepMask(customerAddress.Zipcode)} />
            <ItemLabel label="Telefone" value={phoneMask(customerPhone)} />
          </View>
        </View>
        <View style={styles.line} />

        {!saleId ? (
          <>
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 15,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Títulos Vencidos
            </Text>
            <View style={styles.line} />
            <TitlesHeader />
            <View style={styles.line} />
            {titles
              .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
              .filter(
                (title) =>
                  title.billStatusId === 1 && isPast(new Date(title.dueDate))
              )
              .map((values, index) => (
                <TitlesRow
                  key={index}
                  code={values.Code}
                  dueDate={values.dueDate}
                  saleCode={values.Sales?.Code}
                  amount={values.liquidValue}
                />
              ))}
            <View style={[styles.line, { marginTop: 5, marginBottom: 2 }]} />
            <TotalHeader
              totalAmount={titles
                .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
                .filter(
                  (title) =>
                    title.billStatusId === 1 && isPast(new Date(title.dueDate))
                )
                .map((item) => item.liquidValue)
                .reduce((prev, curr) => prev + curr, 0)}
            />
            <View style={[styles.line, { marginTop: 2 }]} />
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 15,
                marginTop: 15,
                marginBottom: 5,
              }}
            >
              Títulos Abertos
            </Text>
            <View style={styles.line} />
            <TitlesHeader />
            <View style={styles.line} />
            {titles
              .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
              .filter(
                (title) =>
                  title.billStatusId === 1 && !isPast(new Date(title.dueDate))
              )
              .map((values, index) => (
                <TitlesRow
                  key={index}
                  code={values.Code}
                  dueDate={values.dueDate}
                  saleCode={values.Sales?.Code}
                  amount={values.liquidValue}
                />
              ))}
            <View style={[styles.line, { marginTop: 5, marginBottom: 2 }]} />
            <TotalHeader
              totalAmount={titles
                .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
                .filter(
                  (title) =>
                    title.billStatusId === 1 && !isPast(new Date(title.dueDate))
                )
                .map((item) => item.liquidValue)
                .reduce((prev, curr) => prev + curr, 0)}
            />
            <View style={[styles.line, { marginTop: 2 }]} />
          </>
        ) : (
          <View />
        )}

        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 15,
            marginTop: 15,
            marginBottom: 5,
          }}
        >
          Total de Valores por Placa
        </Text>
        <View style={styles.line} />
        <ItemsHeaderVehicle />
        <View style={styles.line} />
        {valuesByVehicle.map((values, index) => (
          <ItemRowVehicle
            key={index}
            vehicle={values.vehicle}
            salesTypeId={values.salesTypeId}
            amount={values.amount}
          />
        ))}
        <View style={[styles.line, { marginTop: 5 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 15,
            marginTop: 15,
          }}
        >
          Itens Por Venda
        </Text>
        {sales.map((sale) => (
          <View key={sale.id}>
            <SalesHeader
              Code={sale.Code}
              createdAt={sale.createdAt}
              updatedAt={sale.updatedAt}
              vehicle={sale.Vehicle || {}}
              kilometers={sale.Kilometers || ''}
            />
            <View style={styles.line} />
            <ItemsHeaderSales />
            <View style={styles.line} />
            {sale.SalesItems.map((item, index) => (
              <ItemRowSales item={item} key={index} />
            ))}
            <View style={styles.line} />
          </View>
        ))}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
