import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useAuth } from 'contexts/auth';
import { InputReactSelectPaginate, InputWrapper } from './styles';
import { listDefects } from 'v2/repositories/DefectsRepository';

const defaultPlaceholder = 'Selecione...';

const defaultStyles = (theme, height, width) => {
  return {
    container: (base) => ({
      ...base,
      width: width || 'auto',
      height: height,
      minHeight: height,
    }),
    control: (base) => ({
      ...base,
      boxSizing: 'border-box',
      height: height,
      border: 'none',
      boxShadow: 'none',
      minHeight: '35px',
      background: 'transparent',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      height: height,
      paddingTop: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    input: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      height: height,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      height: height,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.branding1000 : 'inherit',
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.colors.branding900
          : 'rgb(222, 235, 255)',
      },
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
  };
};

export function SelectDefects({
  onChange,
  value,
  styles,
  placeholder = defaultPlaceholder,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  isActive = true,
  height = '35px',
  width,
  ...rest
}) {
  const { companyId } = useAuth();
  const [currentInputKey, setCurrentInputKey] = useState(1);

  const theme = useTheme();

  useEffect(() => {
    if (reloadTrigger) {
      setReloadTrigger(false);
      setCurrentInputKey(currentInputKey + 1);
    }
  }, [reloadTrigger]);

  async function handleFetch(search, loadedOptions, { page }) {
    try {
      const res = await listDefects({
        companyId,
        page,
        limit: 10,
        query: search,
        status: isActive,
      });

      const options = res.rows.map((defect) => ({
        label: defect.description,
        value: {
          id: defect.id,
          description: defect.description,
          correctionsAssociated: defect.correctionsAssociated,
        },
      }));

      return {
        options,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  return (
    <InputWrapper>
      <InputReactSelectPaginate
        isDisabled={disabled}
        key={currentInputKey}
        debounceTimeout={500}
        loadOptions={handleFetch}
        cacheOptions
        defaultValue={null}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        additional={{
          page: 1,
        }}
        noOptionsMessage={() => 'Sem opções'}
        loadingMessage={() => 'Carregando...'}
        styles={styles || defaultStyles(theme, height, width)}
        {...rest}
      />
    </InputWrapper>
  );
}
