import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { isValid, format } from 'date-fns';
import { currency, phoneMask } from 'client/components/ToNormalize/ToNormalize';
import AccountsReceivableFilter from './AccountsReceivableFilter';
import customersRepository from 'repositories/Customers';
import cashierBanksRepository from 'repositories/CashierBank';
import billsToReceiveRepository from 'repositories/BillsToReceive';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

const AccountsReceivableMain = () => {
  const [clientsSuggestions, setClientsSuggestions] = useState([]);
  const [cashierBanks, setCashierBanks] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [clients, setClients] = useState([]);
  const [sortByCustomer, setSortByCustomer] = useState('false');
  const [includeCardFee, setIncludeCardFee] = useState('true');
  const [cashierBankId, setCashierBanksValue] = useState('');
  const [titleStatus, setTitleStatus] = useState('open');
  const [reportType, setReportType] = useState('synthetic');
  const [displayNotes, setDisplayNotes] = useState('false');
  const [dateType, setDateType] = useState('dueDate');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [validateExcel, setValidateExcel] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { companyId } = useAuth();

  useEffect(() => {
    setCustomerId(null);
    loadClients();
  }, []);

  useEffect(() => {
    const suggestions = clients.map((client) => {
      const clientName =
        client.Type === 'Fisica'
          ? `Nome: ${client.Company_Name}`
          : `Razão Social: ${client.Company_Name}, Nome Fantasia: ${client.Trading_Name}`;

      const clientDocument = client.Cpf_Cnpj
        ? client.Type === 'Fisica'
          ? `CPF: ${client.Cpf_Cnpj}`
          : `CNPJ: ${client.Cpf_Cnpj}`
        : '';

      return {
        value: client.id,
        label: `${clientName} ${clientDocument && `, ${clientDocument}`}`,
      };
    });

    setClientsSuggestions(suggestions);
  }, [clients]);

  const loadClients = async () => {
    setIsLoading(true);
    try {
      const clients = await customersRepository.getAllByCompany(companyId);

      setClients(clients);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os clientes. Por favor, tente novamente'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadActiveCashierBanks();
  }, []);

  async function loadActiveCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      setCashierBanks(cashierBanks);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os caixa/bancos. Por favor, tente novamente'
      );
    }
  }

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate) || !customerId)
    ) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setIsLoading(true);
    setValidateExcel(false);
    try {
      let data;
      let excelData;
      if (reportType === 'synthetic') {
        data =
          await billsToReceiveRepository.generateBillsReceivedsTitleSynthetic({
            companyId,
            initialDate,
            finalDate,
            customerId: customerId ? customerId?.value : '',
            dateType,
            titleStatus,
            cashierBankId,
            includeCardFee,
          });

        if (!data.titleReceive.length) {
          setIsLoading(false);
          toastr.warning(
            'Não foi possível gerar o relatório.',
            'Não há dados no período e filtros informado.'
          );
          return;
        }

        excelData = data.titleReceive.map((item) => ({
          payDate: format(new Date(item.Vencimento), 'dd/MM/yyyy'),
          amountsTitles: item.QtdeTítulos,
          expectedInterest: currency(item.JurosPrevisto),
          amountPaid: currency(item.ValorPago),
          balanceReceive: item.SaldoReceber,
        }));
      } else {
        data =
          await billsToReceiveRepository.generateBillsReceivedsTitleAnalytical({
            companyId,
            initialDate,
            finalDate,
            customerId: customerId ? customerId?.value : '',
            dateType,
            titleStatus,
            cashierBankId,
            sortByCustomer: sortByCustomer === 'false' ? '' : 1,
          });

        if (data.titlesQuantity === 0 || !data || data.length === 0) {
          toastr.warning(
            'Não foi possível gerar o relatório.',
            'Não há dados no período e filtros informado.'
          );
          return;
        }

        if (sortByCustomer === 'false') {
          excelData = Object.keys(data.titles)
            .reduce((prev, curr) => {
              return [...prev, ...data.titles[curr].rows];
            }, [])
            .map((item) => ({
              dueDate: format(new Date(item.dueDate), 'dd/MM/yyyy'),
              titleCode: item.titleCode,
              saleCode: item.saleCode,
              nfCode: item.nfCode,
              daysOverdue: item.daysOverdue,
              customer: item.customer,
              customerPhone: phoneMask(item.customerPhone),
              customerCpfCnpj: item.customerCpfCnpj,
              dischargeDate: format(new Date(item.dischargeDate), 'dd/MM/yyyy'),
              cashierBank: item.cashierBank,
              amount: currency(item.amount),
              accountPlan: item.accountPlan,
              observations: item.observations,
            }));
        } else {
          excelData = data
            .map((customerData) => {
              return Object.values(customerData.rows || {}).flatMap(
                (dayData) => {
                  return dayData.rows.map((item) => ({
                    customerName: customerData.customerName,
                    customerCpfCnpj: customerData.customerCpfCnpj,
                    customerPhone: phoneMask(customerData.customerPhone),
                    dueDate: format(new Date(item.dueDate), 'dd/MM/yyyy'),
                    titleCode: item.titleCode,
                    saleCode: item.saleCode,
                    nfCode: item.nfCode,
                    daysOverdue: item.daysOverdue,
                    cashierBank: item['CashierBanks.id']
                      ? item.cashierBank
                      : null,
                    amount: currency(item.liquidValue),
                    accountPlan: item['AccountPlans.id']
                      ? item.accountPlan
                      : null,
                    observations: item.observations,
                  }));
                }
              );
            })
            .flat();
        }
      }

      handlePrint();

      setExcelData(excelData);
      setValidateExcel(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    if (reportType === 'synthetic') {
      window.open(
        window.location.origin +
          `/report/accounts-receivable-synthetic?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&costumerIdAux=${
            customerId === null ? '' : customerId?.value
          }&dateType=${dateType}&titleStatus=${titleStatus}&cashierBanksValue=${cashierBankId}&includeCardFee=${includeCardFee}&reportType=${reportType}`,
        '_blank'
      );
    } else {
      window.open(
        window.location.origin +
          `/report/accounts-receivable-analytical?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&costumerIdAux=${
            customerId === null ? '' : customerId?.value
          }&dateType=${dateType}&titleStatus=${titleStatus}&cashierBanksValue=${cashierBankId}&displayNotes=${displayNotes}&sortByCustomer=${sortByCustomer}&includeCardFee=${includeCardFee}&reportType=${reportType}`,
        '_blank'
      );
    }
  };

  return (
    <div>
      <AccountsReceivableFilter
        initialDate={initialDate}
        finalDate={finalDate}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        clientsSuggestions={clientsSuggestions}
        isLoading={isLoading}
        showSecond={showSecond}
        setShowSecond={setShowSecond}
        excelData={excelData}
        cashierBanks={cashierBanks}
        dateType={dateType}
        setDateType={setDateType}
        reportType={reportType}
        setReportType={setReportType}
        titleStatus={titleStatus}
        setTitleStatus={setTitleStatus}
        cashierBanksValue={cashierBankId}
        setCashierBanksValue={setCashierBanksValue}
        displayNotes={displayNotes}
        setDisplayNotes={setDisplayNotes}
        sortByCustomer={sortByCustomer}
        setSortByCustomer={setSortByCustomer}
        includeCardFee={includeCardFee}
        setIncludeCardFee={setIncludeCardFee}
        costumerIdAux={customerId}
        setCustomerId={setCustomerId}
        validateExcel={validateExcel}
        handlePrint={handleSubmit}
      />
    </div>
  );
};

export default AccountsReceivableMain;
