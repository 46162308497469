import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router';
import { format } from 'date-fns';

import AlertModal from 'components/AlertModal/AlertModal';

import SavingModal from './SavingModal';
import InventoryCountData from './InventoryCountData';
import SearchInventoryModal from './SearchInventoryModal';

import { useAuth } from 'contexts/auth';

import inventoryCountRepository from 'repositories/InventoryCount';
import pdvRepository from 'repositories/PDVs';

import '../styles.css';

const InventoryCountRegisterMain = ({ inventoryCountId }) => {
  const { companyId } = useAuth();
  const [loadingSubmission, setLoadingSubmission] = useState(false);

  const history = useHistory();

  const [productQueryA, setProductQueryA] = useState('');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const [productsOfInventory, setProductsOfInventory] = useState([]);
  const [loadingInventory, setLoadingInventory] = useState(false);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const [isFinished, setIsFinished] = useState(false);

  const formMethods = useForm({
    defaultValues: {
      description: '',
      finishDate: null,
      createdAt: new Date(),
      status: null,
      observations: '',
      inventoryProducts: [],
    },
  });

  async function handleSearchProducts() {
    if (!productQueryA) {
      return toastr.warning(
        'Busca não realizada',
        'Insira a informação no campo ao lado para realizar a busca'
      );
    }
    setLoadingProducts(true);
    try {
      const response = await pdvRepository.getProducts(companyId, {
        limit: 10,
        page: 1,
        productQueryA: productQueryA,
        groupBy: true,
      });

      if (response.count === 0) {
        setLoadingProducts(false);

        return toastr.warning(
          'Produto não localizado',
          'Não foi localizado nenhum produto com essa informação. Refaça a pesquisa e tente novamente'
        );
      }

      if (response.count === 1) {
        setLoadingProducts(false);

        return handleAddProduct(response.products[0]);
      }

      setLoadingProducts(false);
      return setIsSearchModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Erro ao buscar os produtos. Tente novamente!'
      );
      setLoadingProducts(false);
    }
  }

  function getProductIndexById(id) {
    return productsOfInventory.findIndex((product) => product.productId === id);
  }

  function handleChangeProductQuantity(productIndex, value) {
    const productsCopy = [...productsOfInventory];
    productsCopy[productIndex] = {
      ...productsOfInventory[productIndex],
      inventoryQuantity: `${value}`,
    };
    setProductsOfInventory(productsCopy);
    setTimeout(() => {
      document.getElementById(`productQtd-input-${productIndex}`).focus();
    }, 200);
  }

  function handleAddProduct(product) {
    setIsSearchModalOpen(false);
    setProductQueryA('');

    const productAlreadyAddedIndex = getProductIndexById(product.productId);

    if (productAlreadyAddedIndex >= 0) {
      const productsCopy = [...productsOfInventory];
      productsCopy[productAlreadyAddedIndex] = {
        ...product,
        inventoryQuantity: `${
          +productsCopy[productAlreadyAddedIndex].inventoryQuantity + 1
        }`,
        id: productsCopy[productAlreadyAddedIndex].id,
      };
      setProductsOfInventory(productsCopy);
      return toastr.success(
        'Quantidade adicionada',
        `O produto ${product.productCode} teve sua quantidade aumentada`
      );
    }

    setProductsOfInventory([
      ...productsOfInventory,
      { ...product, inventoryQuantity: '1' },
    ]);

    return toastr.success(
      'Produto adicionado com sucesso',
      `O produto ${product.productCode} foi adicionado na listagem`
    );
  }

  function handleRemoveProduct(productId) {
    setProductsOfInventory(
      productsOfInventory.filter((product) => product.productId !== productId)
    );
    return toastr.success(
      'Produto excluído com sucesso',
      'O produto foi excluído do inventário'
    );
  }

  function handleValidateCancel() {
    if (productsOfInventory.length > 0 && !isFinished) {
      return setIsCancelModalOpen(true);
    }

    handleCancel();
  }

  function handleCancel() {
    history.push('inventories-counts');
  }

  function handleSerializeProducts() {
    return productsOfInventory.map((product) => {
      return {
        manufacturerReference: product.productManufacturerRef,
        description: product.productDescription,
        purchasePrice: product.productPurchasePrice,
        stockQuantity: product.productQuantity,
        quantity: product.inventoryQuantity
          .replaceAll('.', '')
          .replace(',', '.'),
        productId: product.productId,
      };
    });
  }

  async function handleValidateSubmit() {
    const { description } = formMethods.getValues();

    if (!description || productsOfInventory.length === 0) {
      return toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos e certifique-se que há ao menos 1 item para salvar.'
      );
    }

    setIsSaveModalOpen(true);
  }

  async function handleSubmit(inventoryStatus) {
    const status = inventoryStatus === 'FINISHED' ? 'Finalizada' : 'Em Aberto';
    const isFinished = inventoryStatus === 'FINISHED';

    if (loadingSubmission) return;

    setLoadingSubmission(true);
    setIsSaveModalOpen(false);
    try {
      const data = formMethods.getValues();

      const body = {
        companyId,
        inventory: {
          description: data.description,
          finishDate:
            inventoryStatus === 'FINISHED'
              ? `${format(new Date(), 'yyyy-MM-dd')} 10:00:00`
              : null,
          status,
          observations: data.observations,
        },
        inventoryProducts: handleSerializeProducts(),
      };

      if (inventoryCountId) {
        await inventoryCountRepository.update(inventoryCountId, body);
      } else {
        await inventoryCountRepository.create(body);
      }

      if (isFinished) {
        toastr.success(
          'Inventário finalizado com sucesso!',
          'O estoque foi alterado conforme os valores lançados'
        );
      } else {
        toastr.success(
          'Inventário salvo com sucesso!',
          'O estoque foi salvo mas não houve movimentação de estoque'
        );
      }

      history.push('inventories-counts');
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Erro ao salvar contagem de inventário. Tente novamente!'
      );
      setLoadingSubmission(false);
    }
  }

  function handleSetItems(inventoryItems) {
    const serializedItems = inventoryItems.map((item) => ({
      id: item.id,
      productCode: item.Products.code,
      productId: item.Products.id,
      productDescription: item.description,
      productManufacturerRef: item.manufacturerReference,
      productPurchasePrice: item.purchasePrice,
      inventoryQuantity: `${item.quantity}`.replace('.', ','),
      productQuantity: item.stockQuantity,
    }));

    setProductsOfInventory(serializedItems);
  }

  async function loadInventoryCount() {
    try {
      const data = await inventoryCountRepository.getById(inventoryCountId);
      formMethods.setValue('description', data.inventory.inventory.description);
      formMethods.setValue(
        'observations',
        data.inventory.inventory.observations
      );
      formMethods.setValue(
        'finishDate',
        data.inventory.inventory.finishDate
          ? format(new Date(data.inventory.inventory.finishDate), 'yyyy-MM-dd')
          : null
      );
      formMethods.setValue(
        'createdAt',
        format(new Date(data.inventory.inventory.createdAt), 'yyyy-MM-dd')
      );
      setIsFinished(data.inventory.inventory.status === 'Finalizada');

      handleSetItems(data.inventory.inventoryItems);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar inventário/contagem.',
        'Por favor, tente novamente'
      );
    }
  }

  useEffect(() => {
    if (inventoryCountId) {
      loadInventoryCount();
    }
  }, []);

  return (
    <div className="client__inventory-count-register-main">
      <InventoryCountData
        formMethods={formMethods}
        productQueryA={productQueryA}
        setProductQueryA={setProductQueryA}
        handleSearchProducts={handleSearchProducts}
        handleSubmit={null}
        loadingSubmission={loadingSubmission}
        loadingProducts={loadingProducts}
        productsOfInventory={productsOfInventory}
        loadingInventory={loadingInventory}
        handleRemoveProduct={handleRemoveProduct}
        handleValidateCancel={handleValidateCancel}
        handleValidateSubmit={handleValidateSubmit}
        inventoryCountId={inventoryCountId}
        isFinished={isFinished}
        handleChangeProductQuantity={handleChangeProductQuantity}
      />

      {isSearchModalOpen && (
        <SearchInventoryModal
          isSearchModalOpen={isSearchModalOpen}
          setIsSearchModalOpen={setIsSearchModalOpen}
          productQueryA={productQueryA}
          handleAddProduct={handleAddProduct}
        />
      )}

      {isCancelModalOpen && (
        <AlertModal
          show={isCancelModalOpen}
          message={
            <>
              <span>
                <strong>
                  Você tem certeza que deseja cancelar essa contagem/inventário?
                </strong>
              </span>
              <br />
              <br />
              <span>
                O processo é irreversível. Ao cancelar, será necessário lançar
                novamente todos os itens em uma nova contagem/inventário.
              </span>
            </>
          }
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={() => handleCancel()}
          onHide={() => setIsCancelModalOpen(false)}
        />
      )}

      {isSaveModalOpen && (
        <SavingModal
          onCancel={() => setIsSaveModalOpen(false)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default InventoryCountRegisterMain;
