import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from '../../../../../components/AlertModal/AlertModal'

import ProfileMainData from './MainData'
import { useHistory } from 'react-router-dom'
import constants from '../../../../../utils/constants'

const FormProfile = ({ handleSubmit, loading, profileId, pristine, modal, hideModal }) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)

  const history = useHistory()

  const handleCancel = () => {
    if (pristine) return cancel()
    setIsAlertModalOpen(true)
  }

  const cancel = () => {
    if (modal) return hideModal()
    history.push(constants.ROUTES.PROFILES)
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show
          title="Dados do Perfil"
          onClick={() => { }}
        >
          <ProfileMainData />
        </CardForm>
        <FormSubmitButtons loading={loading} hasId={!!profileId} onCancel={handleCancel} onSubmit={handleSubmit} />
      </form>
      
      <AlertModal
        show={isAlertModalOpen}
        onHide={() => setIsAlertModalOpen(false)}
        onCancel={() => setIsAlertModalOpen(false)}
        message={`Deseja cancelar ${profileId ? 'a edição' : 'o cadastro'} do perfil de acesso? Todos os campos preenchidos não serão salvos e ${profileId ? 'a edição' : 'o cadastro'} poderá ser realizad${profileId ? 'a' : 'o'} posteriormente`}
        onSubmit={cancel}
      />
    </>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formProfile'
})(FormProfile)