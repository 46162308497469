const INITIAL_STATE = {
  Segments: [],
  StartTime: '',
  EndTime: '',
  Site: '',
  showCompanyInSobreRodas: false,
  Branch_id: '',
  segmentDescription: '',
  companyServices: [], // serviços da empresa

  currentSegments: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HANDLE_CHANGE':
      return { ...state, [action.payloadType]: action.payload };

    case 'FETCH_COMPANY_DETAILS_SUCCESS':
      return {
        ...state,
        StartTime: action.payload.StartTime,
        EndTime: action.payload.EndTime,
        Site: action.payload.Site,
        showCompanyInSobreRodas: action.payload.showCompanyInSobreRodas,
        Branch_id: action.payload.Branch_id,
      };

    case 'LOAD_COMPANY_DETAILS_SERVICES':
      return {
        ...state,
        currentServices: action.payload,
        companyServices: action.payload,
      };

    default:
      return state;
  }
};
