import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { toastr } from "react-redux-toastr";

import Button from '../CustomButton/CustomButton'
import AlertModal from '../../../components/AlertModal/AlertModal'
import FormTextForInvoices from '../../views/TextForInvoices/NewTextForInvoices/NewTextForInvoices'
import textForInvoicesRepository from '../../../repositories/TextsForInvoices'
import TextsTable from './TextsTable'

import { useAuth } from '../../../contexts/auth'

const TextsForInvoiceModal = ({ onCancel, onSubmit, textsIds = [] }) => {
  const [texts, setTexts] = useState([])
  const [textId, setTextId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isNewTextModalOpen, setIsNewTextModalOpen] = useState(false)
  const [isEditTextModalOpen, setIsEditTextModalOpen] = useState(false)

  const { companyId } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    loadTexts()
  }, [])

  async function loadTexts() {
    setLoading(true)
    try {
      const textsForInvoices = await textForInvoicesRepository.getAllByCompany(companyId)
      const serializedTexts = textsForInvoices.map(item => {
        if(textsIds.includes(item.id)) {
          return {
            ...item,
            checked: true
          }
        }

        return {
          ...item,
          checked: false
        }
      })
      
      setTexts(serializedTexts)

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao buscar os textos para notas fiscais. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  function handleCloseTextModal() {
    const hasAnyCheckedTexts = texts.some(t => t.checked)
    if (hasAnyCheckedTexts) {
      setIsCancelModalOpen(true)
    } else {
      onCancel()
    }
  };

  function handleIncludeText() {
    const textsId = texts.filter(t => t.checked).map(t => t.id)
    onSubmit(textsId)
  }

  function handleOpenNewTextModal() {
    setIsNewTextModalOpen(true)
  }

  function handleOpenEditTextModal(textId) {
    setTextId(textId)
    setIsEditTextModalOpen(true)
  }

  function handleModal() {
    setIsNewTextModalOpen(false)
    setIsEditTextModalOpen(false)
    loadTexts()
  }

  function handleIncludeText(index){
    const newTexts = [...texts]
    newTexts[index].checked = !newTexts[index].checked
    setTexts(newTexts)
  }

  function handleSubmitIncludes() {
    const newTexts = texts.filter(text => text.checked).map(text => text.id)
    onSubmit(newTexts)
  }

  return (
    <>
      <Modal show animation dialogClassName="modal-textForInvoices">
        <Modal.Header closeButton onHide={handleCloseTextModal}>
          <Modal.Title>
            <strong>Textos para Notas Fiscais</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextsTable 
            data={texts} 
            loading={loading} 
            onNewText={handleOpenNewTextModal}
            onEditText={handleOpenEditTextModal}
            onInclude={handleIncludeText}
          />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button className="btn-danger btn btn-fill" fill onClick={handleCloseTextModal}>Cancelar</Button>
            <Button className="btn-fill btn btn-info" fill onClick={handleSubmitIncludes}>Incluir Texto</Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        message='Deseja fechar o modal e não adicionar os textos selecionados?'
        onSubmit={onCancel}
      />

      <Modal 
        dialogClassName="modal-formProduto" 
        show={isNewTextModalOpen} 
        onHide={() => setIsNewTextModalOpen(false)} 
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Novo Texto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body" style={{ marginLeft: '5px', marginTop: 0, display: 'flex' }}>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <FormTextForInvoices 
            Calling='NFSe' 
            modal 
            handleModal={handleModal} 
            dispatch={dispatch} 
            readOnly 
          />
        </Modal.Body>
      </Modal>

      <Modal 
        dialogClassName="modal-formProduto" 
        show={isEditTextModalOpen} 
        onHide={() => setIsEditTextModalOpen(false)} 
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Editar Texto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body" style={{ marginLeft: '5px', marginTop: 0, display: 'flex' }}>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <FormTextForInvoices 
            Calling='NFe' 
            textForInvoicesId={textId} 
            modal
            handleModal={handleModal} 
            dispatch={dispatch} 
            readOnly 
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TextsForInvoiceModal