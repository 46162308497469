import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import './styles.css';
import renderField from 'client/components/Input/RenderField';
import {
  onlyLettersAndSpace,
  maxLength,
  email,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';
import { reduxForm, formValueSelector, change, Field } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validateExpGrts';
import renderFieldSpan from 'client/components/Input/renderInputSpan';
import api from 'services/api';
import config from 'config';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { useBranding } from 'hooks/useBranding';

class DadosPessoaisExpGrts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: 'password',
      emailDuplicado: false,
      showModal: false,
      ramos: [],
      ramo: '',
      day: 0,
      inputVazio: [],
    };
  }

  inputSpan = () => {
    const renderFieldSpan = ({
      input,
      type = this.state.inputType,
      meta: { touched, error },
    }) => (
      <div className="password-div">
        <input
          id="input-pwd"
          maxLength="18"
          className="form-control foco-input"
          autoComplete="new-password"
          {...input}
          type={type}
        />
        <span
          style={{
            position: 'absolute',
            top: '8px',
            right: '5px',
            transition: 'right 0.2s',
          }}
          className={type === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}
          onClick={() => {
            this.setState({
              inputType: type === 'password' ? 'text' : 'password',
            });
          }}
        />
        {touched && error && (
          <span
            style={{
              color: 'red',
              textAlign: 'left',
              display: 'block',
              paddingLeft: '10px',
            }}
          >
            {error}
          </span>
        )}
      </div>
    );
    return renderFieldSpan;
  };

  componentDidMount = async () => {
    await this.props.dispatch(change('ExpGrtsForm', 'emailVazio', false));
    await this.loadBranch();
  };

  loadBranch = async () => {
    const branchs = await api.get(config.endpoint + 'branch');
    this.setState({ ramos: branchs.data });
  };

  verificacao = async (e) => {
    e.preventDefault();
    try {
      await api.get(
        config.endpoint + 'users/search/byEmail/' + this.props.Email
      );

      await this.props.dispatch(change('ExpGrtsForm', 'emailDuplicado', true));
      await this.props.dispatch(change('ExpGrtsForm', 'emailVazio', false));
    } catch (err) {
      await this.props.dispatch(change('ExpGrtsForm', 'emailDuplicado', false));
      await this.props.dispatch(change('ExpGrtsForm', 'emailVazio', false));
      if (this.props.Email) {
        this.setState({ showModal: true });
        await this.props.dispatch(change('ExpGrtsForm', 'emailVazio', false));
      } else {
        await this.props.dispatch(change('ExpGrtsForm', 'emailVazio', true));
      }
    }
  };

  verificaInput = async (e) => {
    let objInput = [];
    let name,
      email,
      phone,
      senha,
      confirmsenha,
      branch = false;
    if (!this.props.Name) {
      name = true;
    } else {
      name = false;
    }
    if (!this.props.Email) {
      email = true;
    } else {
      email = false;
    }
    if (!this.props.Phone) {
      phone = true;
    } else {
      phone = false;
    }
    if (onlyNumbers(this.props.Phone || '').length < 8) {
      phone = true;
    }
    if (!this.props.Password) {
      senha = true;
    } else {
      senha = false;
    }
    if (!this.props.ConfirmPassword) {
      confirmsenha = true;
    } else {
      confirmsenha = false;
    }
    if (!this.props.branchId) {
      branch = true;
    } else {
      branch = false;
    }

    objInput = {
      name,
      email,
      phone,
      senha,
      confirmsenha,
      branch,
    };

    this.setState({ inputVazio: objInput });

    if (!name && !email && !phone && !senha && !confirmsenha && !branch) {
      await this.props.handleSubmit();
    }
  };

  verificaEmail = () => {
    const values = {
      Email: this.props.Email,
    };

    const emailError = validate(values).Email;

    if (emailError === '' || emailError === 'E-mail inválido') return false;
    else return true;
  };

  render() {
    const { handleSubmit, loading } = this.props;
    const { isWorkmotor, isBarros, isPO360, isTecnomotor } = useBranding();

    if (this.props.emailDuplicado == true && !this.props.Email) {
      this.props.dispatch(change('ExpGrtsForm', 'emailDuplicado', false));
    }

    return (
      <form onSubmit={handleSubmit}>
        <Col>
          <h3
            id="txt-titulo"
            className={`
              ${isWorkmotor ? 'wm-text-primary' : ''}
              ${isTecnomotor ? 'tecnomotor-text-primary' : ''}
              ${isBarros ? 'barros-text-primary' : ''}
              ${isPO360 ? 'po360-text-primary' : ''}
            `}
          >
            PARA COMEÇAR A USAR, PREENCHA O CAMPO ABAIXO E PRONTO!
          </h3>
        </Col>
        <Col id="cardExp">
          <Col id="col">
            <label id="label">Insira seu e-mail abaixo</label>
            <Field
              name="Email"
              type="text"
              placeholder="O e-mail que você usa diariamente"
              component={renderField}
              normalize={(value) => email(value)}
            />
            {this.props.emailVazio == true && (
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  display: 'block',
                  paddingLeft: '10px',
                }}
              >
                Informe seu e-mail para prosseguir.
              </span>
            )}
            {this.props.emailDuplicado == true && this.props.Email && (
              <span
                style={{
                  color: 'red',
                  textAlign: 'left',
                  display: 'block',
                  paddingLeft: '10px',
                }}
              >
                Existe uma conta cadastrada com esse e-mail. Faça o{' '}
                <a
                  href="#"
                  onClick={() =>
                    (window.location.href = `client-login?email=${this.props.Email}`)
                  }
                >
                  login
                </a>{' '}
                ou{' '}
                <a
                  href="#"
                  onClick={() =>
                    (window.location.href = `EsqueciMinhaSenha?email=${this.props.Email}`)
                  }
                >
                  recupere a senha
                </a>
                .
              </span>
            )}
          </Col>
          <Col style={{ paddingTop: '10px' }}>
            <Button
              style={{ width: '100%' }}
              id="btn-prosseguir"
              type="submit"
              bsStyle="info"
              disabled={!this.verificaEmail()}
              onClick={(e) => this.verificacao(e)}
            >
              Avançar
            </Button>
          </Col>
        </Col>

        <Modal show={this.state.showModal} animation={true}>
          <Modal.Header>
            <Modal.Title>
              <FontAwesomeIcon
                id="iconClose"
                icon={faTimes}
                aria-hidden="false"
                onClick={() => this.setState({ showModal: false })}
              />
              <strong
                id="titulo-modal"
                className={`${isWorkmotor ? 'wm-text-primary' : ''} ${
                  isTecnomotor ? 'tecnomotor-text-primary' : ''
                }`}
              >
                Queremos conhecer você
              </strong>
              <p id="subt-modal">Preencha os dados abaixo para se cadastrar:</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: '#f8fafc' }}>
            <Col>
              <label id="label">
                Seu Nome<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="Name"
                type="text"
                placeholder="Seu Nome"
                component={renderField}
                normalize={(value) => maxLength(onlyLettersAndSpace(value), 30)}
                id={this.state.inputVazio.name ? 'inputRed' : ''}
              />
              {this.state.inputVazio.name && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Insira seu nome
                </span>
              )}
            </Col>
            <Col>
              <label id="label">
                E-mail<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="Email"
                type="text"
                placeholder="seu@gmail.com"
                component={renderField}
                normalize={(value) => email(value)}
                id={this.state.inputVazio.email ? 'inputRed' : ''}
              />
              {this.state.inputVazio.email && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Insira um e-mail válido
                </span>
              )}
            </Col>
            <Col>
              <label id="label">
                Telefone<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="Phone"
                type="text"
                component={renderField}
                id={this.state.inputVazio.phone ? 'inputRed' : ''}
                normalize={phoneMask}
              />
              {this.state.inputVazio.phone && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Insira um telefone válido
                </span>
              )}
            </Col>
            <Col>
              <label id="label">
                Senha<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="Password"
                component={renderFieldSpan}
                normalize={(value) => maxLength(value, 18)}
                id={this.state.inputVazio.senha ? 'inputRed' : ''}
              />
              {this.state.inputVazio.senha && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Insira a senha de sua conta
                </span>
              )}
            </Col>
            <Col>
              <label id="label">
                Confirme a senha<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                name="ConfirmPassword"
                component={renderFieldSpan}
                normalize={(value) => maxLength(value, 18)}
                id={this.state.inputVazio.confirmsenha ? 'inputRed' : ''}
              />
              {this.state.inputVazio.confirmsenha && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Insira novamente a senha de sua conta
                </span>
              )}
            </Col>
            <Col>
              <label id="label">
                Ramo de atuação<span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className="dropdown form-control foco-input"
                id={this.state.inputVazio.branch ? 'inputRed' : ''}
                value={this.props.branchId}
                onChange={(e) =>
                  this.props.dispatch(
                    change('ExpGrtsForm', 'branchId', e.target.value)
                  )
                }
              >
                <option name="" value="">
                  Selecione
                </option>
                {this.state.ramos.map((r) => {
                  return (
                    <option key={r.id} value={r.id}>
                      {r.Description}
                    </option>
                  );
                })}
              </select>
              {this.state.inputVazio.branch && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    display: 'block',
                    paddingLeft: '10px',
                  }}
                >
                  Selecione um ramo de atuação
                </span>
              )}
            </Col>
            <Col style={{ paddingTop: '10px' }}>
              <Button
                style={{ width: '100%' }}
                id="btn-prosseguir"
                type="submit"
                bsStyle="info"
                disabled={loading}
                onClick={() => this.verificaInput()}
              >
                <span
                  className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                  style={{ marginRight: loading ? '5px' : '' }}
                />
                Criar Conta
              </Button>
            </Col>
          </Modal.Body>
        </Modal>
      </form>
    );
  }
}

DadosPessoaisExpGrts = reduxForm({
  form: 'ExpGrtsForm', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(DadosPessoaisExpGrts);

const selector = formValueSelector('ExpGrtsForm');

const mapStateToProps = (state) => ({
  Name: selector(state, 'Name'),
  Email: selector(state, 'Email'),
  Phone: selector(state, 'Phone'),
  Password: selector(state, 'Password'),
  branchId: selector(state, 'branchId'),
  ConfirmPassword: selector(state, 'ConfirmPassword'),
  emailVazio: selector(state, 'emailVazio'),
  emailDuplicado: selector(state, 'emailDuplicado'),
});

export default connect(mapStateToProps)(DadosPessoaisExpGrts);
