import { atom } from 'recoil';

export const OPEN_INCOMPLETE_CUSTOMER_MODAL_ATOM = atom({
  key: 'openIncompleteCustomerModal',
  default: false,
});

export const OPEN_CONFIRMATION_SALE_WITHOUT_BONUS_MODAL_ATOM = atom({
  key: 'openConfirmationSaleWithoutBonusModal',
  default: false,
});

export const OPEN_SELECT_CAMPAIGN_MODAL = atom({
  key: 'openSelectCampaignModal',
  default: false,
});

export const OPEN_CAMPAIGN_CONFIRMATION_MODAL = atom({
  key: 'openCampaignConfirmationModal',
  default: false,
});

export const OPEN_CANCEL_UPDATE_MODAL_ATOM = atom({
  key: 'openCancelUpdateCustomerModal',
  default: false,
});

export const OPEN_FIRST_PURSHASE_MODAL_ATOM = atom({
  key: 'openFirstPurchaseModal',
  default: false,
});

export const OPEN_PIN_VALIDATION_MODAL_ATOM = atom({
  key: 'openPINValidationModal',
  default: false,
});

export const OPEN_BONUS_AVAILABLE_MODAL_ATOM = atom({
  key: 'openBonusAvailableModal',
  default: false,
});

export const OPEN_BONUS_RESCUED_MODAL_ATOM = atom({
  key: 'openBonusRescuedModal',
  default: false,
});

export const OPEN_UPDATE_CUSTOMER_MODAL_ATOM = atom({
  key: 'openUpdateCustomerModal',
  default: false,
});

export const COMPANY_HAS_CRM_BONUS_ATOM = atom({
  key: 'companyHasCRMBonus',
  default: null,
});

export const COMPANY_CRM_BONUS_STORE_CODE_ATOM = atom({
  key: 'companyCRMBonusStoreCode',
  default: null,
});

export const CRM_BONUS_CUSTOMER_ID_ATOM = atom({
  key: 'crmBonusCustomerId',
  default: null,
});

export const CRM_BONUS_CURRENT_CUSTOMER_ATOM = atom({
  key: 'crmBonusCurrentCustomer',
  default: {},
});

export const CRM_BONUS_CURRENT_SALE_VALUE_ATOM = atom({
  key: 'crmBonusCurrentSaleValue',
  default: 0,
});

export const CRM_CUSTOMER_PIN_ATOM = atom({
  key: 'crmCustomerPIN',
  default: 0,
});

export const CRM_IDS_BONUS_ATOM = atom({
  key: 'crmIdsBonus',
  default: null,
});

export const CRM_BONUS_AVAILABLE_VALUE_ATOM = atom({
  key: 'crmBonusAvailableValue',
  default: 0,
});

export const CRM_APPLY_BONUS_TRIGGER_ATOM = atom({
  key: 'crmApplyBonusTrigger',
  default: false,
});

export const CONTINUE_WITHOUT_BONUS_TRIGGER_ATOM = atom({
  key: 'continueWithoutBonusTrigger',
  default: false,
});

export const CAMPAINGS_ATOM = atom({
  key: 'campaigns',
  default: [],
});

export const SAVE_SALE_ATOM = atom({
  key: 'saveSale',
  default: false,
});

export const SELECTED_CAMPAIGN_ATOM = atom({
  key: 'selectedCampaign',
  default: {},
});

export const RESET_SAVE_MODAL_TRIGGER_ATOM = atom({
  key: 'resetSaveModalTrigger',
  default: false,
});

export const SAVE_ORCAMENT_TRIGGER_ATOM = atom({
  key: 'saveOrcamentTrigger',
  default: false,
});

export const OPEN_PDV_CONFIRM_CPF_MODAL_ATOM = atom({
  key: 'openPDVConfirmCPFModal',
  default: false,
});

export const OPEN_PDV_INPUT_CPF_MODAL_ATOM = atom({
  key: 'openPDVInputCPFModal',
  default: false,
});

export const PDV_SALE_ATOM = atom({
  key: 'pdvSale',
  default: {},
});

export const IS_PDV_ATOM = atom({
  key: 'isPDV',
  default: false,
});

export const SAVE_PDV_ATOM = atom({
  key: 'savePDVTrigger',
  default: false,
});

export const BONUS_ID_ATOM = atom({
  key: 'bonusId',
  default: null,
});

export const ORDER_ID_ATOM = atom({
  key: 'orderId',
  default: null,
});

export const REDEEMED_BONUS_ATOM = atom({
  key: 'redeemedBonus',
  default: null,
});

export const GROSS_VALUE_ATOM = atom({
  key: 'grossValue',
  default: null,
});

export const LIQUID_VALUE_ATOM = atom({
  key: 'liquidValue',
  default: null,
});

export const CURRENT_CUSTOMER_CPF_ATOM = atom({
  key: 'currentCustomerCPF',
  default: null,
});

export const OPEN_NOT_FOUND_CUSTOMER_MODAL_ATOM = atom({
  key: 'openNotFoundCustomerModal',
  default: false,
});

export const OPEN_CREATE_CUSTOMER_MODAL_ATOM = atom({
  key: 'openCreateCustomerModal',
  default: false,
});

export const ALREADY_STARTED_CRM_CUSTOMER_ATOM = atom({
  key: 'alreadyStartedCRMCustomer',
  default: false,
});

export const PDV_VALIDATED_CUSTOMER_ATOM = atom({
  key: 'pdvValidatedCustomer',
  default: false,
});

export const USED_CRMBONUS = atom({
  key: 'usedCRMBonus',
  default: null,
});

export const CONSULTED_BONUS = atom({
  key: 'consultedBonus',
  default: false,
});

export const IS_FIRST_PURCHASE = atom({
  key: 'isFirstPurchase',
  default: false,
});
