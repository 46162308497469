import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { subHours, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { ServiceStatusLabel } from '../../PanelAppointmentModal.styles';
import { getStatusIndicatorColor } from '../../../../utils';
import { usePanelAppointmentStore } from '../../../../store';

import { MaintenanceServicesRepository } from 'v2/repositories/MaintenanceServicesRepository';
import { withSeriesType } from 'react-jsx-highcharts';

const tableStyles = {
  fontWeight: 'bold',
  textAlign: 'center',
  height: '203px',
  fontSize: '14px',
  width: '100%',
  overflowY: 'scroll',
};

export function ServiceHistoryTab({ maintenance, mode }) {
  const { tempAppointments } = usePanelAppointmentStore();
  const [loadingHistory, setLoadingHistory] = useState(true);

  const [services, setServices] = useState([]);

  const servicesColumns = [
    {
      Header: 'Funcionário',
      accessor: 'employeeName',
      className: 'texto',
      resizable: true,
      width: 170,
    },
    {
      Header: 'Serviço',
      accessor: 'description',
      className: 'texto',
      resizable: true,
      width: 400,
    },
    {
      Header: 'Qtd',
      accessor: 'quantity',
      width: 70,
      className: 'texto',
      resizable: true,
    },
    {
      Header: 'Data/Hora Inicial',
      accessor: 'start',
      className: 'texto',
      width: 150,
      resizable: true,
      Cell: (props) => {
        return props.value
          ? format(subHours(utcToZonedTime(props.value), 3), 'dd/MM/yyyy HH:mm')
          : '-';
      },
    },
    {
      Header: 'Data/Hora Final',
      accessor: 'stop',
      className: 'texto',
      width: 150,
      resizable: true,
      Cell: (props) => {
        return props.value
          ? format(subHours(utcToZonedTime(props.value), 3), 'dd/MM/yyyy HH:mm')
          : '-';
      },
    },
    {
      Header: 'Total',
      accessor: 'totalHours',
      className: 'texto',
      resizable: true,
      Cell: (props) => {
        return props.value ? props.value : '-';
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 150,
      resizable: true,
      Cell: (props) => {
        return (
          <ServiceStatusLabel
            bg={getStatusIndicatorColor(props.original.status)}
          >
            {props.value}
          </ServiceStatusLabel>
        );
      },
    },
    {
      Header: 'Observação',
      accessor: 'observation',
      width: 500,
      resizable: true,
    },
  ];

  async function loadServicesHistory() {
    setLoadingHistory(true);
    try {
      const res = await MaintenanceServicesRepository.getHistory(
        maintenance.maintenanceId
      );

      const serializedTempAppointments =
        mode === 'start-service'
          ? tempAppointments.map((appointment) => ({
              employeeName: appointment.employee.name,
              description: appointment.service.description,
              quantity: appointment.service.quantity,
              status: 'Não Iniciado',
              stopReason: null,
              shortStatus: 'NI',
              start: null,
              stop: null,
              totalHours: null,
            }))
          : [];

      setServices([...serializedTempAppointments, ...res.data]);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar o histórico de serviços',
        'Por favor, tente novamente.'
      );
    }
    setLoadingHistory(false);
  }

  useEffect(() => {
    loadServicesHistory();
  }, []);

  return (
    <div>
      <ReactTable
        data={services}
        style={tableStyles}
        columns={servicesColumns}
        showPagination={false}
        noDataText="Nenhum histórico de serviço encontrado"
        loading={loadingHistory}
        loadingText="Carregando..."
        getTbodyProps={() => ({
          style: {
            height: '100%',
          },
        })}
      />
    </div>
  );
}
