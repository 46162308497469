import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import SearchInventoryModalTable from './SearchInventoryModalTable';

import useDebounce from 'hooks/useDebounce';
import { useAuth } from 'contexts/auth';

import productsRepository from 'repositories/Products';
import pdvRepository from 'repositories/PDVs';

const SearchInventoryModal = ({
  productQueryA,
  isSearchModalOpen,
  setIsSearchModalOpen,
  handleAddProduct,
}) => {
  const { companyId } = useAuth();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [products, setProducts] = useState([]);

  const [productQueryInput, setProductQueryInput] = useState('');
  const [productQueryDebounced, setProductQueryDebounced] = useState(null);
  const debouncedSaveQuery = useDebounce(setProductQueryDebounced, 600);

  const [brandId, setBrandId] = useState(null);
  const [familyId, setFamilyId] = useState(null);

  const [loading, setLoading] = useState(true);

  function handleChangeProductQuery(value) {
    setProductQueryInput(value);
    debouncedSaveQuery(value);
  }

  async function handleSearchProducts() {
    setLoading(true);
    try {
      const response = await pdvRepository.getProducts(companyId, {
        limit: pageLimit,
        page: currentPage + 1,
        productQueryA: productQueryA,
        productQueryB: productQueryInput,
        familyId,
        brandId,
        groupBy: true,
      });

      const itemsWithoutPromotionalPrice = response.products.map((product) => {
        return {
          ...product,
          productPurchasePrice: product.productPurchasePrice,
        };
      });

      setTotalProducts(response.count);
      setTotalPages(Math.ceil(response.count / pageLimit));
      setProducts(itemsWithoutPromotionalPrice);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível realizar a busca de produtos.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSearchProducts();
  }, [pageLimit, currentPage, productQueryDebounced, brandId, familyId]);

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        show={isSearchModalOpen}
        backdrop={false}
        onHide={() => setIsSearchModalOpen(false)}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setIsSearchModalOpen(false);
          }
        }}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Refinamento de Pesquisa</strong>
          </Modal.Title>
          <span className="small-text-search-modal">
            Essa pesquisa irá filtrar apenas os itens já encontrados.
            <br />
            Para buscar outro item, feche essa janela e pesquise novamente.
          </span>
        </Modal.Header>
        <Modal.Body>
          <SearchInventoryModalTable
            loading={loading}
            data={products}
            totalProducts={totalProducts}
            totalPages={totalPages}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            brandId={brandId}
            setBrandId={setBrandId}
            productQuery={productQueryInput}
            handleChangeProductQuery={handleChangeProductQuery}
            handleAddProduct={handleAddProduct}
            familyId={familyId}
            setFamilyId={setFamilyId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchInventoryModal;
