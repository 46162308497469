import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { usePlanSignatureContext } from '../../../../contexts/plan-signature';
import { useModulesContext } from 'contexts/modules';

import UnavailableFeatureModal from '../../../components/UnavailableFeatureModal';
import CustomizedTabs from 'v2/components/Tabs/Tabs';
import PDV from './components/PDV';
import OtherConfigurations from './components/OtherConfigurations';
import Commission from './components/Commission';
import Printed from './components/Printed';
import Sales from './components/Sales';
import WorkshopPanel from './components/WorkshopPanel';

const MainData = () => {
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const { isWorkshopPanelModuleActive } = useModulesContext();

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const isPlanFreeOrStart = isPlanFree || isPlanStart;

  function handleTabChange(event, newValue) {
    setSelectedTab(newValue);
  }

  function renderTabContent(index) {
    switch (index) {
      case 0:
        return <Sales />;
      case 1:
        return <Printed />;
      case 2:
        return <Commission />;
      case 3:
        return <OtherConfigurations />;
      case 4:
        return <PDV />;
      case 5:
        return <WorkshopPanel />;
      default:
        return null;
    }
  }

  const renderTabs = () => {
    let tabLabels = isPlanFreeOrStart
      ? ['Tela de Venda', 'Impressos']
      : [
          'Tela de Venda',
          'Impressos',
          'Comissão',
          'Outras Configurações',
          'PDV',
        ];

    if (isWorkshopPanelModuleActive) {
      tabLabels = [...tabLabels, 'Painel da Oficina'];
    }

    return (
      <CustomizedTabs
        tabLabels={tabLabels}
        value={selectedTab}
        onChange={handleTabChange}
      />
    );
  };

  return (
    <>
      <div className="printing-sheets-form-main-data">
        {renderTabs()}
        {renderTabContent(selectedTab)}
        <UnavailableFeatureModal
          show={isOpenFeatureNotAvailableModal}
          onHide={() => setIsOpenFeatureNotAvailableModal(false)}
        />
      </div>
    </>
  );
};

export default MainData;
