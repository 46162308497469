import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';

import {
  faEdit,
  faFileInvoiceDollar,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertModal from 'components/AlertModal/AlertModal';
import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import useFilters from 'hooks/useFilters';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { useAuth } from 'contexts/auth';

import VoucherPaymentRepository from 'repositories/VoucherPayment';
import useDebounce from 'hooks/useDebounce';
import SearchInput from 'components/SearchInput';

const CancelPaymentBtn = ({ original, handleReload }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleCancelPayment() {
    setLoading(true);
    try {
      await VoucherPaymentRepository.update(original.id, {
        ...original,
        status: 'Cancelada',
      });

      toastr.success('Cancelamento realizado com sucesso!');

      handleReload();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Pagamentos',
        'Tente novamente. Se persistir, contate o suporte!'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FontAwesomeIcon
        onClick={() => setIsCancelModalOpen(true)}
        style={{ width: '1.5em', height: '1.5em', cursor: 'pointer' }}
        title={'Cancelar Pagamento'}
        icon={faWindowClose}
        aria-hidden="false"
        color="red"
      />
      {isCancelModalOpen && (
        <AlertModal
          show={true}
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={handleCancelPayment}
          loading={loading}
          message={
            <>
              <span>
                <strong>
                  Você tem certeza que deseja cancelar o pagamento?
                </strong>
              </span>
              <br />
              <br />
              <span>
                O cancelamento é irreversível. Ao cancelar, se pago, o valor
                será removido do relatório e será necessário ser feito um novo
                lançamento para que conste o pagamento.
              </span>
            </>
          }
        />
      )}
    </>
  );
};

export default function VoucherPaymentMain() {
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const history = useHistory();

  const [voucherPayments, setVoucherPayments] = useState([]);
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  function getStatusBackgroundColor(status) {
    const colors = {
      'Em Aberto': '#f0ad4e',
      Cancelada: '#bd362f',
      Finalizada: '#46a546',
    };

    return colors[status];
  }

  async function loadVoucherPayments() {
    setLoading(true);
    try {
      const data = await VoucherPaymentRepository.findAllByCompanyId({
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
        query: queryDebounced,
        status: status,
        initialDate,
        finalDate,
      });
      setVoucherPayments(data.vouchers);
      setTotalPages(Math.ceil(data.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Pagamentos',
        'Tente novamente. Se persistir, contate o suporte!'
      );
    } finally {
      setLoading(false);
    }
  }

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    loadVoucherPayments();
  }, [currentPage, pageLimit, queryDebounced, status, initialDate, finalDate]);

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <button
                className="btn btn-sucesso button-h35"
                onClick={() => history.push('/client/voucher-payment')}
                style={{ fontSize: '12px' }}
              >
                + Novo Pagamento
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Código, Funcionário ou Valor"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="Finalizada">Finalizada</option>
                <option value="Cancelada">Cancelada</option>
                <option value="Em Aberto">Em Aberto</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="initialDate"
                value={initialDate}
                max={format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
            <div>
              <span>
                <strong style={{ fontSize: '12px' }}>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="finalDate"
                value={finalDate}
                min={
                  initialDate
                    ? format(
                        new Date(getDateOnlyFromDate(initialDate)),
                        'yyyy-MM-dd'
                      )
                    : null
                }
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={voucherPayments}
              columns={[
                {
                  Header: 'Dt. Criação',
                  accessor: 'createdAt',
                  width: 150,
                  headerClassName: 'text-left',
                  Cell: (props) =>
                    !!props.value
                      ? format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')
                      : '',
                },
                {
                  Header: 'Código',
                  accessor: 'code',
                  width: 180,
                },
                {
                  Header: 'Funcionário',
                  accessor: 'Employee.name',
                },
                {
                  Header: 'Valor',
                  accessor: 'value',
                  width: 150,
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  headerClassName: 'text-left',
                  width: 110,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        height: '100%',
                        backgroundColor: getStatusBackgroundColor(props.value),
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 90,
                  filterable: false,
                  className: 'texto',
                  Cell: (props) =>
                    props.original.quickQuoteStatusId === 2 ? (
                      <>
                        <FontAwesomeIcon
                          title={`Acessar Venda/OS`}
                          cursor="pointer"
                          icon={faFileInvoiceDollar}
                          style={{ width: '1.5em', height: '1.5em' }}
                          onClick={() => null}
                        />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          title="Editar Orçamento Rápido"
                          cursor="pointer"
                          icon={faEdit}
                          style={{ width: '1.5em', height: '1.5em' }}
                          onClick={() =>
                            history.push(
                              `/client/voucher-payment#${props.value}`
                            )
                          }
                        />
                        &nbsp;
                        {props.row.status !== 'Cancelada' && (
                          <CancelPaymentBtn
                            original={props.original}
                            handleReload={loadVoucherPayments}
                          />
                        )}
                      </>
                    ),
                },
              ]}
              manual
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              onPageChange={(value) => setCurrentPage(value)}
              pageSize={pageLimit}
              pages={totalPages}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={false}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'date',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
}
