export const headerNavMenuItemsFeatures = {
  105: {
    title: 'Meus Usuários',
    subFeatureId: 105,
  },
  106: {
    title: 'Minha Empresa',
    subFeatureId: 106,
  },
  107: {
    title: 'Meu Plano',
    subFeatureId: 107,
  },
  108: {
    title: 'Meus Cargos',
    subFeatureId: 108,
    plansNotAllowed: ['Free', 'Start'],
  },
  109: {
    title: 'Meus Funcionários',
    subFeatureId: 109,
    plansNotAllowed: ['Free', 'Start'],
  },
  110: {
    title: 'Meus Perfis de Acesso',

    subFeatureId: 110,
    plansNotAllowed: ['Free', 'Start', 'Essencial'],
  },
  111: {
    title: 'Minha Área Financeira',

    subFeatureId: 111,
    plansNotAllowed: ['Free'],
  },
  112: {
    title: 'Integrações',
    subFeatureId: 112,
    plansNotAllowed: ['Free', 'Start'],
  },
  113: {
    title: 'Conta Digital',
    subFeatureId: 113,
    plansNotAllowed: ['Free', 'Start'],
  },
};
