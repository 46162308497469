import React from 'react';

import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import './index.css';

const circleIcons = {
  plus: faCirclePlus,
  minus: faCircleMinus,
};

export default function ArrowRoundedButton({
  action,
  type,
  width,
  height,
  color,
}) {
  const theme = useTheme();
  return (
    <button className="arrow-rounded-button" onClick={action}>
      <FontAwesomeIcon
        style={{
          width: width ? width : null,
          height: height ? height : null,
          color: color || theme.colors.branding1000,
        }}
        className={'arrow-rounded-button-icon'}
        icon={circleIcons[type]}
      />
    </button>
  );
}
