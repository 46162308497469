import { currency } from 'client/components/ToNormalize/ToNormalize'
import React from 'react'

const Totals = ({ sale }) => {
  console.log(sale)
  return (
    <article className='totals-content'>
      <div>
        <span>Subtotal: </span>
        <span>{currency(sale.SubTotal)}</span>
      </div>
      <div>
        <span>Desconto: </span>
        <span>{currency(sale.Discount_Value)}</span>
      </div>
      <br />
      <div>
        <span>Total: </span>
        <span>{currency(sale.Final_Value)}</span>
      </div>
      <div>
        <span>Total Pago: </span>
        <span>{currency(sale.totalPaid)}</span>
      </div>
      {
        sale.cashBack ? (
          <div>
            <span>Troco: </span>
            <span>{currency(sale.cashBack)}</span>
          </div>
        ) : null
      }
    </article>
  )
}

export default Totals