import React, { useState, useEffect } from 'react'

import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import QRCode from 'react-qr-code'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import LoaderSpinner from 'client/components/LoaderSpinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { currency } from '../ToNormalize/ToNormalize'

import './styles.css'

const MateraQRCodeModal = ({
  show,
  setShow,
  pixValue,
  pixCode,
  setPixCode,
  setIsShareQRCodeModalOpen,
}) => {
  const [loading, setLoading] = useState(false)

  function handleClose() {
    setShow(false)
    setPixCode('')
  }

  function generateQRCode() {
    try {
      setLoading(true)
      setTimeout(() => setLoading(false), 2000)
    } catch (err) {
      toastr.error('Ocorreu um erro ao gerar o QR Code. Tente novamente!')
      handleClose()
    }
  }

  const QRCodeComponent = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '5px',
          }}
        >
          <p
            style={{
              fontSize: '16px',
            }}
          >
            Valor de Pagamento: <strong>{currency(pixValue)}</strong>
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
          }}
        >
          <QRCode value={pixCode} />
        </div>
      </>
    )
  }

  const LoadingComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderSpinner
          style={{
            fontSize: '5px',
            margin: '10px',
          }}
        />

        <p
          style={{
            fontSize: '14px',
            fontWeight: 400,
          }}
        >
          Gerando QR Code, aguarde...
        </p>
      </div>
    )
  }

  const ModalBodyContent = () => {
    return <>{loading ? <LoadingComponent /> : <QRCodeComponent />}</>
  }

  const ModalFooterContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '60%',
          }}
        >
          <Button
            bsStyle='danger'
            name='Voltar'
            onClick={() => handleClose()}
            fill
            disabled={loading ? 'disabled' : ''}
            style={{
              width: '100px',
            }}
          >
            Voltar
          </Button>
          <Button
            bsStyle='info'
            name='Compartilhar via WhatsApp'
            onClick={() => setIsShareQRCodeModalOpen(true)}
            disabled={loading ? 'disabled' : ''}
            fill
          >
            <div>
              <span>Compartilhar via WhatsApp</span>
              &nbsp;
              <FontAwesomeIcon icon={faWhatsapp} aria-hidden='false' />
            </div>
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (show && pixValue) {
      generateQRCode()
    }
  }, [])

  return (
    <Modal show={show} onHide={() => null}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBodyContent />
      </Modal.Body>
      <Modal.Footer>
        <ModalFooterContent />
      </Modal.Footer>
    </Modal>
  )
}

export default MateraQRCodeModal
