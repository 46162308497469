import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'

import SalesRepository from 'repositories/Sales'
import FamiliesRepository from 'repositories/Families'
import { format } from 'date-fns'

export default function SalesPerFamilyProductMain() {
  const { companyId } = useAuth()

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [reportType, setReportType] = useState('synthetic')
  const [selectedFamilyId, setSelectedFamilyId] = useState('')
  const [familiesOptions, setFamiliesOptions] = useState([])
  const [result, setResult] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const familyId = selectedFamilyId === '' ? '' : selectedFamilyId

      const data = await SalesRepository.getAllSalesByFamily({
        initialDate,
        finalDate,
        companyId,
        familyId,
      })

      setResult(data)

      if (selectedFamilyId === '') {
        toastr.warning(
          'Selecione ao menos uma família para processar o relatório'
        )
        return
      }

      if (initialDate && finalDate && data.products.length) {
        window.open(
          window.location.origin +
            `/report/sales-family-product?familyId=${familyId}&initialDate=${initialDate}&finalDate=${finalDate}&reportType=${reportType}&companyId=${companyId}`,
          '_blank'
        )
      } else {
        return toastr.warning(
          'Não foi possível gerar o relatório.',
          'Nenhum item foi localizado. Verifique os filtros e tente novamente.'
        )
      }
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const loadFamiliesOptions = async () => {
    try {
      const families =
        await FamiliesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        )
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        description: family.Description,
      }))
      setFamiliesOptions(serializedFamilies)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      )
    }
  }

  useEffect(() => {
    loadFamiliesOptions()
  }, [])

  return (
    <div className="voucher-issued-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
          max={finalDate ? finalDate : format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
          min={initialDate}
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Família: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={selectedFamilyId}
          onChange={(e) => setSelectedFamilyId(e.target.value)}
        >
          <option value="">Selecione</option>
          {familiesOptions.map((family) => (
            <option key={family.id} value={family.id}>
              {family.description}
            </option>
          ))}
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Tipo: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
        >
          <option value="synthetic">Sintético</option>
          <option value="analytical">Analítico</option>
        </select>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <button
          className="btn btn-sucesso"
          type="submit"
          onClick={handleSubmit}
        >
          <span
            className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />{' '}
          Processar
        </button>
      </div>
    </div>
  )
}
