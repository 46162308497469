import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { differenceInMinutes } from 'date-fns'

import SATCFeRepository from 'repositories/SATCFe'

import Button from 'client/components/CustomButton/CustomButton.jsx'

const { Header, Title, Body, Footer } = Modal

const CancelSATModal = ({
  show,
  onCancel,
  reloadInvoices,
  satId,
  onSucess,
}) => {
  const [loading, setLoading] = useState(false)

  const cancelSAT = async () => {
    setLoading(true)

    try {
      await SATCFeRepository.cancel(satId)
      toastr.success('SAT cancelado com sucesso.')

      reloadInvoices()
      onSucess && onSucess()
    } catch (err) {
      console.error(err)
      toastr.error(
        'Ocorreu um erro ao cancelar o SAT. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
      onCancel()
    }
  }

  const handleTimeToCancelSAT = async () => {
    setLoading(true)

    try {
      const sat = await SATCFeRepository.show(satId)

      const minutesSinceIssued = differenceInMinutes(
        new Date(),
        new Date(sat.issuedAt)
      )

      if (minutesSinceIssued > 30)
        return toastr.error(
          'Não é possível efetuar cancelamento de SAT depois de 30 minutos da emissão.'
        )

      await cancelSAT()
    } catch (err) {
      console.error(err)
      toastr.error(
        'Ocorreu um erro ao cancelar o SAT. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Header>
        <Title>
          <strong>OS Digital</strong>
        </Title>
      </Header>
      <Body>
        <strong>Você tem certeza que deseja cancelar esse SAT?</strong>
        <br />
        <br />
        <span>
          O cancelamento do SAT é irreversível. Caso não possua nenhuma outra
          nota vinculada a venda será cancelada, os itens serão devolvidos para
          o estoque e os títulos cancelados.
        </span>
        <br />
        <br />
        <p style={{ color: '#d43f3a', textAlign: 'center', fontSize: 12 }}>
          O SAT possui um prazo de 30 minutos de cancelamento após a emissão.
          Após essse prazo não poderá ser cancelado.
        </p>
      </Body>
      <Footer>
        <Button bsStyle='danger' name='Não' onClick={onCancel} fill>
          Não
        </Button>
        <Button
          bsStyle='info'
          fill
          onClick={handleTimeToCancelSAT}
          loading={loading}
        >
          Cancelar SAT
        </Button>
      </Footer>
    </Modal>
  )
}

export default CancelSATModal
