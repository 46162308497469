const { default: styled } = require('styled-components');

function getRadiusVariant(radius) {
  switch (radius) {
    case 'top':
      return '4px 4px 0 0';
    case 'bottom':
      return '0 0 4px 4px';
    case 'full':
      return '4px';
    default:
      return '0';
  }
}

function getBorderTopVariant(borderTop) {
  switch (borderTop) {
    case 'none':
      return 'border-top: none';
    default:
      return '';
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width || '100%'};
  height: 58px;
  border: 1px solid #c0c0c0;
  padding: 5px;
  background-color: '#567811';
  border-radius: ${(props) => getRadiusVariant(props.radius)};

  ${(props) => getBorderTopVariant(props.borderTop)}
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.subTitle ? 'space-between' : 'center')};
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 5%;
  height: 100%;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  color: #000000;
`;
