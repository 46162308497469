import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import { SalesPassingDocument } from './SalesPassingDocument';

import { addDays, format } from 'date-fns';
import customersRepository from 'repositories/Customers';

const SalesPassingReport = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [data, setData] = useState([]);

  const [params, setParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId');
      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'));
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));

      const searchBy = searchParams.get('searchBy');
      const dateType = searchParams.get('dateType');
      const reportType = searchParams.get('reportType');
      const salesSubtypeId = searchParams.get('salesSubtypeId');
      const showObservations = Number(searchParams.get('showObservations'));
      const customerId =
        searchParams.get('customerId') === 'null'
          ? null
          : searchParams.get('customerId');
      const sellerId =
        searchParams.get('sellerId') === 'null'
          ? null
          : searchParams.get('sellerId');
      const salesStatus = Number(searchParams.get('salesStatus'));
      const vehicleId =
        searchParams.get('vehicleId') === 'null'
          ? null
          : searchParams.get('vehicleId');

      setHasError(false);

      return loadReport(companyId, {
        initialDate,
        finalDate,
        reportType,
        searchBy,
        dateType,
        showObservations,
        sellerId,
        customerId,
        salesStatus,
        salesSubtypeId,
        vehicleId,
      });
    } catch (err) {
      console.log(err);
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (companyId, params) => {
    setLoading(true);
    try {
      const data = await customersRepository.generateReportOfSalesByPassages(
        companyId,
        params
      );

      setParams(params);
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <SalesPassingDocument
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={params.dateType}
            searchBy={params.searchBy}
            reportType={params.reportType}
            showObservations={params.showObservations}
            salesStatus={params.salesStatus}
            customerId={params.customerId}
            vehicleId={params.vehicleId}
            data={data}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default SalesPassingReport;
