import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import salesRepository from '../../../repositories/Sales'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import { addDays, format } from 'date-fns'

import { CanceledBudgetsDocument } from './CanceledBudgetsDocument'

const CanceledBudgetsReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [initialDateQuery, setInitialDateQuery] = useState('')
  const [finalDateQuery, setFinalDateQuery] = useState('')
  const [formatedLossReasons, setFormatedLossReasons] = useState([])
  const [result, setResult] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [])

  const loadQuery = async searchParams => {
    try {
      const lossReasonIdHash = searchParams.get('lossReasonId')

      let lossReasonId
      if (lossReasonIdHash) {
        lossReasonId = await decrypt(lossReasonIdHash, '@OS-dig:lossReasonId')
      }

      const companyIdHash = searchParams.get('companyId')

      let companyId
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId')
      }

      if (!companyId || !lossReasonId) {
        setHasError(true)
        setLoading(false)
      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')

      setInitialDateQuery(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDateQuery(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      return loadReport({ lossReasonId, companyId, initialDate, finalDate })
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const handleFormatLossReasons = (data) => {
    const lossReasonsFormated = data.map(item => {
      return {
        id: item.LossReason.id,
        description: item.LossReason.description,
        count: 1,
        totalValue: item.Final_Value
      }
    }).reduce((acc, curr) => {
      const item = acc.find(item => item.id === curr.id)
      if (item) {
        item.count += 1
        item.totalValue += curr.totalValue
      }
      else {
        acc.push(curr)
      }

      return acc
    }, [])

    setFormatedLossReasons(lossReasonsFormated)
  }

  const loadReport = async ({ lossReasonId, companyId, initialDate, finalDate }) => {
    try {
      const { data } = await salesRepository.getCanceledBudgetsToPrint({ companyId, initialDate, finalDate, lossReasonId })
      setResult(data)
      handleFormatLossReasons(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <CanceledBudgetsDocument formatedLossReasons={formatedLossReasons} result={result} initialDate={initialDateQuery} finalDate={finalDateQuery} />
        </PDFViewer>
      </main>
    </div>
  )
}

export default CanceledBudgetsReport