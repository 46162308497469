import api from '../services/api'
import config from '../config'

const updatePhone = (phoneId, data) =>
  api.put(config.endpoint + `phones/customer/${phoneId}`, data)

const phonesRepository = {
  updatePhone,
}

export default phonesRepository
