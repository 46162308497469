import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import constants from '../../../../../../utils/constants'
import Button from '../../../../../components/CustomButton/CustomButton'

import './styles.css'

const CloseFinancialMovementModal = ({ show, onHide, isTransfer }) => {

  const dispatch = useDispatch()
  function handleSubmit(financialMovementStatusId) {
    dispatch(change('FinancialMovement', 'financialMovementStatusId', financialMovementStatusId))
  }

  return (
    <Modal
      show={show}
      animation
      dialogClassName='close-financial-movement-modal-wrapper'
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>
            Criação de Movimentação
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Clique na opção desejada:</span>
        <div>
          <Button
            bsStyle='primary'
            fill
            onClick={() => handleSubmit(constants.FINANCIAL_MOVEMENT_STATUS_ID.CLOSED)}
            style={{fontSize: !isTransfer ? '13px' : '14px'}}
          >
            Finalizar e {isTransfer ? 'transferir' : 'movimentar'} saldo
          </Button>
          <Button
            bsStyle='info'
            fill
            onClick={() => handleSubmit(constants.FINANCIAL_MOVEMENT_STATUS_ID.OPEN)}
          >
            Salvar em aberto
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CloseFinancialMovementModal