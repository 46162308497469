import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import HomeProducts from './Products';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import constants from '../../../utils/constants';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const Products = () => {
  const { isPlanFree, isPlanStart, isPlanBasic, isPlanWorkmotorFree } =
    usePlanSignatureContext();
  const isPlanPrimeWithoutPlansFree =
    !isPlanFree && !isPlanStart && !isPlanBasic && !isPlanWorkmotorFree;

  localStorage.setItem(
    'isPlanPrimeWithoutPlansFree',
    isPlanPrimeWithoutPlansFree
  );

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos']}
            path={[constants.ROUTES.HOME, null]}
          />
          <HomeProducts />
        </Row>
      </Grid>
    </div>
  );
};
export default Products;
