import { useState } from 'react';
import React from 'react';
import { useFormContext , Controller} from 'react-hook-form';
import { BankConciliationDetailsSearchContainer, BankConciliationDetailsSearchContent } from './BankConciliationDetailsSearch.styles';
import { SearchDetailsFile } from './SearchDetailsFile';
import { SearchFinancialTitle } from './SearchFinancialTitle';
import { useBankConciliationStore } from 'v2/store/bankConciliation';


export function BankConciliationDetailsSearch({isFinancialRegister, prefix}) {

  const [optionsTransactionsType, setOptionsTransactionsType] = useState([]);
  const { watch } = useFormContext()

  const { dataOFX } = useBankConciliationStore((store) => ({
    dataOFX: store.bankConciliation.dataOFX,
  }));
  const transactions = watch('informationFile.data.transactions') || [];
  const typeSearch = watch(`search.${prefix}.typeFinancialRegister`)
  React.useEffect(() => {
    const optionsTransactionsType = dataOFX?.transactions?.reduce((acc, transactionType, index) => {
      if (!acc.some(option => option.label === transactionType.type)) {
        acc.push({
          id: index,
          label: transactionType.type,
        });
      }
      return acc;
    }, []);

    setOptionsTransactionsType(optionsTransactionsType);

  }, [typeSearch])

  return (
    <BankConciliationDetailsSearchContainer >
      {!isFinancialRegister ? (
        <SearchDetailsFile optionsTransactionsType={optionsTransactionsType} prefix={prefix} />
      ) : (
          <SearchFinancialTitle prefix={prefix} typeSearch={typeSearch} />
      )}
    </BankConciliationDetailsSearchContainer>
  );
}
