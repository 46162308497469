import styled from 'styled-components';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;

  position: relative;
  top: 10px;
`;

export const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${({ filled, theme }) => (filled ? theme.colors.branding1000 : '#c0c0c0')} !important;
  border-radius: 100% !important;
  background-color: ${({ filled, theme }) =>
    filled ? theme.colors.branding1000 : '#c0c0c0'} !important;
  width: 45px;
  height: 45px;
`;

export const StepTitle = styled.span`
  font-weight: bold;
`;
