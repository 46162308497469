import React from 'react'

import PlanSignatureProvider from './plan-signature'
import ModulesContextProvider from './modules'
import AdminAuthProvider from './adminAuth'
import AuthProvider from './auth'
import BannerProvider from './bannerContext'
import WorkshopPanelProvider from './workshopPanel'

const ContextProviders = ({ children }) => {
  return (
    <PlanSignatureProvider>
      <ModulesContextProvider>
        <BannerProvider>
          <WorkshopPanelProvider>
            <AuthProvider>
              <AdminAuthProvider>{children}</AdminAuthProvider>
            </AuthProvider>
          </WorkshopPanelProvider>
        </BannerProvider>
      </ModulesContextProvider>
    </PlanSignatureProvider>
  )
}

export default ContextProviders
