import api from '../services/api';

const getVehicleLines = async () => {
  const { data } = await api.get('/fraga/vehicle-lines');
  return data;
};

const getManufacturers = async ({ classification }) => {
  const { data } = await api.get('/fraga/manufacturers', {
    params: {
      classification,
    },
  });
  return data;
};

const getVehicleModels = async ({ classification, brand, name }) => {
  const response = await api.get('/fraga/vehicle-models', {
    params: {
      classification,
      brand,
      name,
    },
  });
  return response.data.data;
};

const getVehicleNames = async ({ classification, brand }) => {
  const { data } = await api.get('/fraga/vehicle-names', {
    params: {
      classification,
      brand,
    },
  });
  return data;
};

const getYearsByModel = async ({ classification, brand, name, model }) => {
  const { data } = await api.get('/fraga/years-by-model', {
    params: {
      classification,
      brand,
      name,
      model,
    },
  });
  return data;
};

const getProductsByVehicle = async ({
  searchInfoProduct,
  skip,
  classification,
  brand,
  name,
  year,
  model,
}) => {
  const { data } = await api.get('/fraga/products-by-vehicle', {
    params: {
      searchInfoProduct,
      skip,
      classification,
      brand,
      name,
      year,
      model,
    },
  });
  return data;
};

export default {
  getVehicleLines,
  getManufacturers,
  getVehicleModels,
  getVehicleNames,
  getYearsByModel,
  getProductsByVehicle,
};
