import SearchInput from 'components/SearchInput'
import React from 'react'
import '../styles.css'

const CitiesListFilter = ({
  query,
  state,
  setState,
  patternsList,
  pattern,
  setPattern,
  handleChangeQuery,
  setIsNewCityModalOpen,
}) => {
  return (
    <>
      <main className="admin__nfse-citieslist-page">
        <section className="admin__nfse-citieslist-page__filter-row">
          <button
            className="btn btn-sucesso"
            onClick={() => setIsNewCityModalOpen(true)}
          >
            + Nova Cidade
          </button>
          <div className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por Código de Cidade ou Nome"
              value={query}
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>
          <fieldset>
            <label style={{ width: '40px' }}>UF:</label>
            <select
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="form-control foco-input"
            >
              <option value="">Todas</option>
              <option value="AC">AC</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="SC">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </fieldset>
          <fieldset>
            <label style={{ width: '60px' }}>Padrão:</label>
            <select
              value={pattern}
              onChange={(e) => setPattern(e.target.value)}
              className="form-control foco-input"
            >
              <option value="">Ambos</option>
              {patternsList?.map((pattern) => (
                <option key={pattern.id} value={pattern.id}>
                  {pattern.pattern}
                </option>
              ))}
            </select>
          </fieldset>
        </section>
      </main>
    </>
  )
}

export default CitiesListFilter
