import React, { useEffect, useState } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { reduxForm, change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/CustomButton/CustomButton';
import Select from '../../Clients/NewClient/FormClient/components/Select';
import TextArea from '../../Clients/NewClient/FormClient/components/TextArea';
import Input from '../../../components/Input/Input';

import { onlyNumbers } from '../../../components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../contexts/auth';
import companiesRepository from '../../../../repositories/Companies';

const FormDisableInvoiceModal = ({ onCancel, handleSubmit, loading }) => {
  const [disabledNFCe, setDisabledNFCe] = useState(false);

  const { companyId } = useAuth();
  const {
    disableInvoice: {
      values: { nfType, environment, justification, nfeSerie, nfceSerie },
    },
  } = useSelector((state) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    getCompanyInfo();
    if (nfType === 'nfce') {
      setDisabledNFCe(true);
    }
  }, []);

  async function getCompanyInfo() {
    try {
      const companyTaxData = await companiesRepository.getTaxData(companyId);
      const { SettingsNFe, SettingsNFCe } = companyTaxData;

      dispatch([
        change('disableInvoice', 'nfeSerie', SettingsNFe?.serie),
        change('disableInvoice', 'nfceSerie', SettingsNFCe?.serie),
        change('disableInvoice', 'serie', SettingsNFe?.serie),
        change('disableInvoice', 'environment', SettingsNFCe?.environment),
        change('disableInvoice', 'environment', SettingsNFe?.environment),
      ]);
    } catch (err) {
      console.log(err?.response?.data);
      toastr.error('Erro ao carregar campo "Série"');
    }
  }

  function handleChangeTypeNf({ target: { value } }) {
    if (value === 'nfe') {
      setDisabledNFCe(false);
      dispatch(change('disableInvoice', 'serie', nfeSerie));
    } else {
      setDisabledNFCe(true);
      dispatch(change('disableInvoice', 'serie', nfceSerie));
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>
          <strong>Inutilização de Numeração</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <label>Selecione o tipo de nota:</label>
          <Col style={{ width: '100%', display: 'flex' }}>
            <Col style={{ width: '90px' }}>
              <Field
                component={Select}
                name="nfType"
                value={nfType}
                onChange={handleChangeTypeNf}
              >
                <option value="nfe">NF-e</option>
                <option value="nfce">NFC-e</option>
              </Field>
            </Col>
            <Col style={{ width: '140px', marginLeft: 10 }}>
              <Field
                component={Select}
                name="environment"
                value={environment}
                disabled={disabledNFCe}
              >
                <option value="Produção">Produção</option>
                <option value="Homologação">Homologação</option>
              </Field>
            </Col>
          </Col>
        </Col>

        <Col style={{ width: '100%', display: 'flex' }}>
          <Col>
            <label>Informe o intervalo de numerações</label>
            <Col style={{ width: '100%', display: 'flex' }}>
              <Col style={{ width: '90px' }}>
                <Field
                  name="firstInvoiceNumber"
                  component={Input}
                  normalize={onlyNumbers}
                />
              </Col>
              <Col
                style={{
                  marginLeft: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <span>{'-'}</span>
              </Col>
              <Col style={{ width: '90px', marginLeft: 10 }}>
                <Field
                  name="lastInvoiceNumber"
                  component={Input}
                  normalize={onlyNumbers}
                />
              </Col>
            </Col>
          </Col>

          <Col style={{ width: '60px', marginLeft: 10 }}>
            <label>Série</label>
            <Field name="serie" component={Input} normalize={onlyNumbers} />
          </Col>
        </Col>

        <Col style={{ marginBottom: 5 }}>
          <label>
            Informe abaixo a justificativa pela inutilização das numerações
          </label>
          <span
            style={{
              position: 'absolute',
              right: 15,
              bottom: 0,
              color: justification?.length >= 15 ? 'green' : 'red',
            }}
          >
            {justification?.length || 0} caracteres
          </span>
          <Field
            name="justification"
            component={TextArea}
            placeholder="O motivo deverá ter pelo menos 15 caracteres"
          />
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button bsStyle="danger" disabled={loading} onClick={onCancel} fill>
            Voltar
          </Button>
          <Button bsStyle="info" disabled={loading} fill type="submit">
            Inutilizar{' '}
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'disableInvoice',
})(FormDisableInvoiceModal);
