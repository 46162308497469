import config from '../config';
import api from '../services/api';

const generateByCompanyId = async ({
  companyId,
  initialDate,
  finalDate,
  employeeId,
  xls,
  showClientData,
  showTypeItem,
}) => {
  try {
    const response = await api.get(
      config.endpoint + 'report/commission/' + companyId,
      {
        params: {
          initialDate,
          finalDate,
          employeeId,
          xls,
          showClientData,
          showTypeItem,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateByCompanyIdTitle = async ({
  companyId,
  initialDate,
  finalDate,
  employeeId,
  xls,
  showClientData,
  showTypeItem,
}) => {
  try {
    const response = await api.get(
      config.endpoint + 'report/commission/bills/' + companyId,
      {
        params: {
          initialDate,
          finalDate,
          employeeId,
          xls,
          showClientData,
          showTypeItem,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const CommissionsReportRepository = {
  generateByCompanyId,
  generateByCompanyIdTitle,
};

export default CommissionsReportRepository;
