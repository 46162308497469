import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const SyntheticDocument = ({ responseData }) => {
  return (
    <Document
      title={`impresso-synthetic-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '35%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Listagem de Notas Fiscais</Text>
          </View>
          <Header data={responseData.header} />
        </View>
        <TotalizatorsHeader data={responseData.totals} />
        <InvoicesListing data={responseData} />
      </Page>
    </Document>
  );
};

const FormatType = (invoices) => {
  return invoices
    .replace(/Contingencia/g, 'Contingência')
    .replace(/NFCeSAT/g, 'NFC-e/SAT')
    .replace(/NFe/g, 'NF-e')
    .replace(/NFSe/g, 'NFS-e');
};

const Header = ({ data }) => {
  return (
    <View style={{ width: '65%', flexDirection: 'row' }}>
      <View
        style={{
          flexDirection: 'column',
          width: '35%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Relatório: </Text>
          </View>
          <View>
            <Text>{data.reportType}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Nota: </Text>
          </View>
          <View>
            <Text>{FormatType(data.invoiceType)}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'column',
          width: '65%',
          marginBottom: '5px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={{ marginRight: '5px' }}>
            <Text>{data.initialDate}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text>{data.finalDate}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Status: </Text>
          </View>
          <View>
            <Text>{FormatType(data.invoiceStatus)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TotalizatorsHeader = ({ data }) => {
  return (
    <>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Tipo/Status
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '8%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>QTD</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>
      <View style={styles.line} />

      {data &&
        data.byInvoiceType.map((element, index) => (
          <TotalizatorsByInvoiceType key={index} element={element} />
        ))}

      <View style={[styles.line, { width: '70%' }]} />

      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '18%' }]}>Total</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>
          {data.totalizator.quantity}
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>
          {currency(data.totalizator.amount)}
        </Text>
      </View>

      <View style={[styles.line, { width: '40%' }]} />

      {data.byStatus.map((element, index) => (
        <TotalizatorsByInvoiceStatus key={index} element={element} />
      ))}
    </>
  );
};

const TotalizatorsByInvoiceType = ({ key, element }) => {
  return (
    <View
      key={key}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'left',
      }}
    >
      <View style={{ width: '8%' }}>
        <Text style={styles.text}>{FormatType(element.invoiceType)}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{element.status}</Text>
      </View>
      <View style={{ width: '5%' }}>
        <Text style={styles.text}>{element.quantity}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{currency(element.total)}</Text>
      </View>
    </View>
  );
};

const TotalizatorsByInvoiceStatus = ({ key, element }) => {
  return (
    <View
      key={key}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'left',
      }}
    >
      <View style={{ width: '18%' }}>
        <Text style={styles.text}>{element.status}</Text>
      </View>
      <View style={{ width: '5%' }}>
        <Text style={styles.text}>{element.quantity}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{currency(element.total)}</Text>
      </View>
    </View>
  );
};

const InvoicesListing = ({ data }) => {
  return (
    <>
      <NFCeSatListing NFCeSatData={data.report.nfceSat} />
      <NFeListing NFeData={data.report.nfe} />
      <NFSeListing NFSeData={data.report.nfse} />
    </>
  );
};

const NFCeSatListing = ({ NFCeSatData }) => {
  const serializedNFCeSatData =
    NFCeSatData?.length > 0
      ? NFCeSatData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                ''
              ).slice(0, 20) +
              ((item.customerCompanyName || item.customerTradingName || '')
                ?.length > 20
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    serializedNFCeSatData?.length > 0 && (
      <>
        <View style={styles.line} />
        <NFCeSatListingHeader />
        <View style={styles.line} />
        <NFCeListingItems NFCeItems={serializedNFCeSatData} />
        <View style={styles.line} />
      </>
    )
  );
};

const NFeListing = ({ NFeData }) => {
  const serializedNFeData =
    NFeData?.length > 0
      ? NFeData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                item.providerCompanyName ||
                item.providerTradingName ||
                ''
              ).slice(0, 20) +
              ((
                item.customerCompanyName ||
                item.customerTradingName ||
                item.providerCompanyName ||
                item.providerTradingName ||
                ''
              )?.length > 20
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    serializedNFeData?.length > 0 && (
      <>
        <NFeListingHeader />
        <View style={styles.line} />
        <NFeListingItems NFeItems={serializedNFeData} />
        <View style={styles.line} />
      </>
    )
  );
};

const NFSeListing = ({ NFSeData }) => {
  const serializedNFSeData =
    NFSeData?.length > 0
      ? NFSeData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                ''
              ).slice(0, 20) +
              ((item.customerCompanyName || item.customerTradingName || '')
                ?.length > 20
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    serializedNFSeData?.length > 0 && (
      <>
        <NFSeListingHeader />
        <View style={styles.line} />
        <NFSeListingItems NFSeItems={serializedNFSeData} />
        <View style={styles.line} />
      </>
    )
  );
};

const NFCeSatListingHeader = () => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de NFC-e/SAT
        </Text>
      </View>

      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'left',
        }}
      >
        <Text style={[styles.boldText, { width: '9%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Emissão</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Número</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Série</Text>
        <Text style={[styles.boldText, { width: '23%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '12%', right: '6px' }]}>
          Venda/OS
        </Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Status</Text>
      </View>
    </>
  );
};

const NFeListingHeader = () => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de NF-e
        </Text>
      </View>

      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'left',
        }}
      >
        <Text style={[styles.boldText, { width: '12%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Data Emissão</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Nat. Op.</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Número</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Série</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '9%', right: '3px' }]}>
          Venda/OS
        </Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Frete</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Out. Desp</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>BC ICMS</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>ICMS</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>IPI</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Desc</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Status</Text>
      </View>
    </>
  );
};

const NFSeListingHeader = () => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de NFS-e
        </Text>
      </View>

      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'left',
        }}
      >
        <Text style={[styles.boldText, { width: '9%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Emissão</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>CNAE</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Número</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Série</Text>
        <Text style={[styles.boldText, { width: '23%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '12%', right: '6px' }]}>
          Venda/OS
        </Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Desc</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Status</Text>
      </View>
    </>
  );
};

const NFCeListingItems = ({ NFCeItems }) => {
  return (
    <>
      {NFCeItems.map((item, index) => (
        <View key={index}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
            }}
          >
            <Text style={[styles.text, { width: '9%' }]}>
              {format(new Date(item.createdAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.issuedAt &&
                (item.status === 'Emitida' || item.status === 'Contingencia') &&
                format(new Date(item.issuedAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '5%' }]}>{item.type}</Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.code}</Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.serie}</Text>
            <Text style={[styles.text, { width: '23%' }]}>
              {item.companyNameOrTradingName}
            </Text>
            <Text style={[styles.text, { width: '12%', right: '6px' }]}>
              {item.saleCode}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {currency(item.total)}
            </Text>
            <Text style={[styles.text, { width: '10%' }]}>
              {FormatType(item.status)}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
};

const NFeListingItems = ({ NFeItems }) => {
  return (
    <>
      {NFeItems.map((item, index) => (
        <View key={index}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
            }}
          >
            <Text style={[styles.text, { width: '12%' }]}>
              {format(new Date(item.createdAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {item.issuedAt &&
                (item.status === 'Emitida' || item.status === 'Contingencia') &&
                format(new Date(item.issuedAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.CFOP}</Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.code}</Text>
            <Text style={[styles.text, { width: '7%' }]}>{item.serie}</Text>
            <Text style={[styles.text, { width: '20%' }]}>
              {item.companyNameOrTradingName}
            </Text>
            <Text style={[styles.text, { width: '9%', right: '3px' }]}>
              {item.saleCode}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.total ? currency(item.total) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.freightValue ? currency(item.freightValue) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.otherExpenses ? currency(item.otherExpenses) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.BCICMS ? currency(item.BCICMS) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.ICMSValue ? currency(item.ICMSValue) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.IPIValue ? currency(item.IPIValue) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {item.discountValue ? currency(item.discountValue) : currency(0)}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {FormatType(item.status)}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
};

const NFSeListingItems = ({ NFSeItems }) => {
  return (
    <>
      {NFSeItems.map((item, index) => (
        <View key={index}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
            }}
          >
            <Text style={[styles.text, { width: '9%' }]}>
              {format(new Date(item.createdAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {(item.status === 'Emitida' || item.status === 'Contingencia') &&
                format(new Date(item.updatedAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>{item.CNAE}</Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.code}</Text>
            <Text style={[styles.text, { width: '8%' }]}>{item.serie}</Text>
            <Text style={[styles.text, { width: '23%' }]}>
              {item.companyNameOrTradingName}
            </Text>
            <Text style={[styles.text, { width: '12%', right: '6px' }]}>
              {item.saleCode}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {currency(item.total)}
            </Text>
            <Text style={[styles.text, { width: '10%' }]}>
              {item.discountValue}
            </Text>
            <Text style={[styles.text, { width: '10%' }]}>
              {FormatType(item.status)}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
};

export default SyntheticDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
