import React, { useState, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faImages } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'
import ImagesModal from './ImagesModal'

import { usePlanSignatureContext } from '../../../contexts/plan-signature'
import UnavailableFeatureModal from '../UnavailableFeatureModal'

export default function TableActionsSimilarities({ row, onInclude }) {
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false)
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false)

  const { isPlanFree, isPlanStart } = usePlanSignatureContext()

  const handleImages = useCallback(
    (product) => {
      if (product.ProductsPhotos?.length === 0 || !product?.ProductsPhotos)
        return toastr.warning('O produto não tem foto para ser visualizada.')

      if (isPlanFree || isPlanStart)
        return setIsOpenFeatureNotAvailableModal(true)

      setIsImagesModalOpen(true)
    },
    [row]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={faImages}
            style={{ color: '#444', cursor: 'pointer' }}
            aria-hidden='false'
            onClick={() => handleImages(row.original.similarProduct)}
            size='12px'
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faPlusSquare}
            style={{ color: 'green', cursor: 'pointer' }}
            aria-hidden='false'
            onClick={() => onInclude(row.original.similarProduct)}
            size='12px'
          />
        </div>
      </div>

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={row.original.similarProduct.Description}
          productPhotos={row.original.similarProduct.ProductsPhotos}
        />
      )}
    </>
  )
}
