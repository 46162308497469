import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import RenderSaleWeb from './RenderSaleWeb';
import RenderSaleMobile from './RenderSaleMobile';
import salesRepository from '../../../repositories/Sales';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import Button from 'client/components/CustomButton/CustomButton';

import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import CompanyConfig from 'repositories/CompanyConfig';

const SalePrint = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sale, setSale] = useState(null);
  const [width, setWindowWidth] = useState(0);
  const [isApproveBudgetModalOpen, setIsApproveBudgetModalOpen] =
    useState(false);
  const [showApproveBudgetButton, setShowApproveBudgetButton] = useState(false);
  const [isBudgetApproved, setIsBudgetApproved] = useState(false);
  const { getBrandingLogo, setDocumentTitle } = useThemeContext();
  const [showPaymentSuggestion, setShowPaymentSuggestion] = useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const isPlanFreeOrStart = isPlanFree || isPlanStart;
  const isInvalidSalesStatusAndType =
    sale?.SalesType_id === 2 ||
    sale?.SalesType_id === 3 ||
    sale?.SalesType_id === 4 ||
    (sale?.SalesType_id === 1 && sale?.SalesStatus_id === 2);
  const isDigitalBudgetApproval =
    sale?.Company.companyConfig.digitalBudgetApproval;

  const getSaleIdFromPathName = () =>
    location.pathname
      .split('Vend=v723dha5tHSSpS83711')[1]
      ?.split('5422hsGsdAQLk8423oPL31')[0];

  useEffect(() => {
    const saleId = getSaleIdFromPathName();
    if (!!saleId) {
      loadSale(saleId);
    }
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (sale) {
      const hasServices =
        sale.SalesItems.filter((item) => item.Type === 'Serviço').length > 0;
      const isOpenBudget = sale.SalesType_id === 1 && sale.SalesStatus_id === 3;
      if (hasServices && isOpenBudget) return setShowApproveBudgetButton(true);
    }
  }, [sale]);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const responsive = {
    showWebVersion: width > 1023,
  };

  const loadSale = async (saleId) => {
    try {
      const sale = await salesRepository.getSaleToPrint(saleId);

      const { budgetPaymentSuggestions } = await CompanyConfig.show(
        sale.Company_id
      );

      setShowPaymentSuggestion(budgetPaymentSuggestions);
      setSale(sale);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    setDocumentTitle(`${sale?.SalesType.Description} #${sale?.Code}`);
    window.print();
  };

  if (loading) {
    return (
      <LoadingOverlay
        active={true}
        spinner
        text="Aguarde..."
        classNamePrefix="MyLoader_"
        fadeSpeed={100}
      />
    );
  }

  if (!loading && error) {
    return <div id="impLoad">Falha ao carregar dados</div>;
  }

  const handleOpenApproveBudgetModal = () => {
    setIsApproveBudgetModalOpen(true);
  };

  const handleCloseApproveBudgetModal = () => {
    setIsApproveBudgetModalOpen(false);
  };

  async function handleApproveBudget() {
    try {
      handleCloseApproveBudgetModal();
      const saleId = getSaleIdFromPathName();

      const currentSaleInfo = await salesRepository.getSaleToPrint(saleId);
      const isOpenBudget =
        currentSaleInfo.SalesType_id === 1 && sale.SalesStatus_id === 3;

      if (!isOpenBudget)
        return toastr.warning(
          'O orçamento já foi aprovado anteriormente. Em caso de dúvidas, pedimos que entre em contato com o estabelecimento.'
        );

      await salesRepository.approveBugdet(saleId);

      toastr.success('Orçamento aprovado com sucesso!');
      setShowApproveBudgetButton(false);
      setIsBudgetApproved(true);
    } catch (err) {
      return toastr.error(
        'Ocorreu um erro ao aprovar o orçamento. Tente novamente!'
      );
    }
  }

  return (
    <div id="content">
      <div className="blueBar bg-primary" />
      <div id="toprint">
        {responsive.showWebVersion ? (
          <RenderSaleWeb
            sale={sale}
            isPlanFreeOrStart={isPlanFreeOrStart}
            isInvalidSalesStatusAndType={isInvalidSalesStatusAndType}
            isDigitalBudgetApproval={isDigitalBudgetApproval}
            handlePrint={handlePrint}
            handleOpenApproveBudgetModal={handleOpenApproveBudgetModal}
            showApproveBudgetButton={showApproveBudgetButton}
            isBudgetApproved={isBudgetApproved}
            showPaymentSuggestion={showPaymentSuggestion}
          />
        ) : (
          <RenderSaleMobile
            sale={sale}
            handlePrint={handlePrint}
            handleOpenApproveBudgetModal={handleOpenApproveBudgetModal}
            showApproveBudgetButton={showApproveBudgetButton}
          />
        )}
      </div>
      <div id="impFooter" style={{ marginTop: sale.Vehicle ? '' : '99px' }}>
        <img
          style={{
            width: '200px',
            height: 'auto',
            borderRadius: '10px',
            padding: '5px',
          }}
          src={getBrandingLogo()}
          alt={getBrandingLogo('alt')}
        />
      </div>
      <div className="blueBar bg-primary" />

      <AlertModal
        show={isApproveBudgetModalOpen}
        title={'Aprovação de Orçamento'}
        onCancelLabel={'Voltar'}
        onSubmitLabel={'Aprovar Orçamento'}
        customFooter={
          !responsive.showWebVersion && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '10px',
              }}
            >
              <Button bsStyle="info" onClick={() => handleApproveBudget()} fill>
                Aprovar Orçamento
              </Button>
              <Button
                bsStyle="danger"
                style={{ marginLeft: 0 }}
                onClick={() => handleCloseApproveBudgetModal()}
                fill
              >
                Voltar
              </Button>
            </div>
          )
        }
        message={
          <div
            style={{
              textAlign: !responsive.showWebVersion ? 'center' : 'left',
            }}
          >
            <span>
              <strong>Você tem certeza que deseja aprovar o orçamento?</strong>
            </span>
            <br />
            <br />
            Ao aprovar o orçamento, a empresa prosseguirá na separação das peças
            e execução dos serviços, se houver.
            <br />
            <br />
            Em caso de dúvidas, pedimos que entre em contato com o
            estabelecimento.
            <br />
            <br />
          </div>
        }
        onCancel={handleCloseApproveBudgetModal}
        onSubmit={handleApproveBudget}
        onHide={handleCloseApproveBudgetModal}
      ></AlertModal>
    </div>
  );
};

export default SalePrint;
