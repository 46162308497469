import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import Carousel from 'react-elastic-carousel';

const MyCarousel = ({ images, activeIndex, setActiveIndex }) => (
  <Carousel
    itemsToShow={1}
    className="modal-add-items-carousel"
    enableMouseSwipe
    onChange={setActiveIndex}
    initialFirstItem={activeIndex}
  >
    {images.map((image, index) => (
      <img key={index} src={image.imageUrl} />
    ))}
  </Carousel>
);

export const ImageSliderModal = ({
  productDescription = '',
  productPhotos = [],
  onHide,
  onCancel,
  show,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Modal dialogClassName="modal-60w" show={show} animation onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <strong>{productDescription}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MyCarousel
          images={productPhotos}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="danger"
          onClick={() => {
            onCancel();
            setActiveIndex(0);
          }}
          fill
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
