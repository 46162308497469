import React from "react";
import { Modal } from 'react-bootstrap';
import { BankConciliationButtonsActions } from "../BankConciliationButtonsActions";
import { useHistory } from 'react-router-dom'

export function ConfirmationModalFooterActions({
  isOpen,
  onClose,
  isLoading,
  modalType,
  onConfirm,
  reset,
  LoadingComponent,
  removeVinculoTransaction
}){
  const isCancelModal = modalType === 'cancel';
  const history = useHistory()


  const handleCloseModal = () =>{
    removeVinculoTransaction()
    onClose()
  }

  return (
    <Modal
      show={isOpen}
      animation
      dialogClassName="modal-40w"
      onHide={isLoading ? () => {} : onClose}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>{isCancelModal ? 'Cancelar Conciliação' : 'Confirmar Conciliação'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <h2 style={{ fontSize: '14px', fontWeight: 'bold', margin: '0px' }}>
              {isCancelModal
                ? 'Você tem certeza que deseja cancelar a conciliação?'
                : 'Você tem certeza que deseja confirmar a conciliação?'}
            </h2>
            <p style={{margin: '0px', fontSize: '14px' }}>
              {isCancelModal
                ? 'O processo é irreversível. Ao cancelar, será necessário reimportar o arquivo novamente para reiniciar o processo.'
                : 'O processo é irreversível. A confirmação irá realizar a baixa dos títulos em aberto e registrará todos os títulos como conciliados.'}
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BankConciliationButtonsActions
            onNoClick={handleCloseModal}
            noLabel="Voltar"
            yesLabel="Sim"
            onYesClick={isCancelModal ? () => { reset();  history.push('/client/bank-conciliation')} : onConfirm}
            isYesDisabled={isLoading}
            isNoDisabled={isLoading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
