import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import HomeBatchPriceChange from './HomeBatchPriceChange';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const BatchPriceChange = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Alteração de Preço em Lote']}
            path={['home', 'products', null]}
          />
          <HomeBatchPriceChange />
        </Row>
      </Grid>
    </div>
  );
};

export default BatchPriceChange;
