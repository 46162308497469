import styled from 'styled-components';
import CurrencyInput from 'react-currency-input';

export const InputRoundWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '35px')};
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputRoundStyled = styled(CurrencyInput)`
  width: 100%;
  height: ${(props) =>
    props.height ? `${props.height}px !important` : '100% !important'};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};
  box-sizing: border-box;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.branding1000} !important;
  }
`;
