
import React from 'react'
import { format } from 'date-fns'
import constants from '../../../utils/constants'

import Header from './Header'
import Customer from './Customer'
import Items from './Items'
import Totals from './Totals'
import Footer from './Footer'
import Vehicle from './Vehicle'

const LineBreak = () => <span>-----------------------------------------------------------------</span>

const RenderPDV = ({ sale }) => {
  const company = sale.Company
  const customer = sale.Customer
  const saleItems = sale.SalesItems

  return (
    <div id='print-pdv'>
      <Header company={company} />
      <LineBreak />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <span>{sale.SalesType.Description}: {sale.Code}</span>
        <span>Data: {format(new Date(sale.Date), 'dd/MM/yyyy hh:mm')}</span>
      </div>
      <LineBreak />
      <Customer customer={customer} />
      <LineBreak />
      {
        sale.Vehicle_id &&
        <>
          <Vehicle vehicle={sale.Vehicle} />
          <LineBreak />
        </>
      }
      {
        sale.SalesType.Description !== constants.SALE_TYPE.ORCAMENT ?
          sale.SalesType.Description === constants.SALE_TYPE.ORDER_OF_SERVICE &&
            sale.SalesStatus_id === constants.SALE_STATUS_ID.OPEN ? null :
            <>
              <span>Data da Venda: {format(new Date(sale.updatedAt), 'dd/MM/yyyy hh:mm')}</span>
              <LineBreak />
            </>
          : null
      }
      <Items items={saleItems} />
      <LineBreak />
      <Totals sale={sale} />
      <Footer attendant={sale.Users} />
      <LineBreak />
    </div>
  )
}

export default RenderPDV