import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

// import fornecedorReducer from '../components/TabelaFornecedores/fornecedorReducer';
// import TabelaOsReducer from '../components/TabelaOrdemServico/TabelaOsReducer';
// import FormsOs from '../components/TabelaOrdemServico/FormsOs/FormsOsReducer';
// import saleReducer from '../components/TabelaVenda/FormSale/redux/saleReducer'
import saleReducer from 'client/views/Sales/NewSale/FormSale/redux/saleReducer'
// import clienteReducer from '../components/Cadastros/Clientes/ReduxClientes/clienteReducer';
// import meusDadosReducer from '../components/MeusDados/meusDadosRedux/meusDadosReducer';
import cartaoCredito from 'client/components/CartaoDeCredito/CartaoCreditoReducer';
// import meusDetalhesReducer from '../components/MeusDetalhes/ReduxMeusDetalhes/meusDetalhesReducer';
import meusDetalhesReducer from 'client/views/MyCompanyDetails/ReduxMeusDetalhes/meusDetalhesReducer';
import segmentoReducer from 'client/components/Segmentos/ReduxSegmento/segmentoReducer';
import blockSalesModalsReducer from 'client/components/BlockSalesModals/reducer'
import minStockAlert from '../client/components/MinStockAlertButton/redux/reducer'

const rootReducer = combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    // fornecedor: fornecedorReducer,
    // TabelaOs: TabelaOsReducer,
    // FormsOs: FormsOs,
    // clienteReducer: clienteReducer,
    // meusDadosReducer: meusDadosReducer,
    cartaoCredito: cartaoCredito,
    saleReducer: saleReducer,
    meusDetalhesReducer: meusDetalhesReducer,
    segmentoReducer: segmentoReducer,
    blockSalesModalsReducer,
    minStockAlert
})

export default rootReducer;
