import React from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency } from 'client/components/ToNormalize/ToNormalize'


const ReturnListingPDF = (responseData) => {
  const totals = responseData.responseData.totals
  const period = responseData.responseData.period
  const invoices = responseData.responseData.invoices

  return (
    <Document
      title={`impresso-return-report-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Relatório de Devolução
          </Text>
        </View>


        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Data Inicio:
            </Text>
            <Text style={[styles.text]}> {format(new Date(`${period.start} 03:00:01`), 'dd/MM/yyyy')}</Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                Data Fim:
              </Text>
              <Text style={[styles.text]}>{format(new Date(`${period.end} 23:59:59`), 'dd/MM/yyyy')}</Text>
            </View>
          </View>

        </View>


        <TableHeader
          totals={totals} invoices={invoices}
        />

      </Page>
    </Document>
  )

}

const TableHeader = ({ totals, invoices }) => {
  return (
    <>
      <View style={[styles.line, { marginTop: '20px' }]} />
      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Tipo/Status
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '14%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Quantidade</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>

      <View style={styles.line} />


      {
        totals.map((tot, index) => (
          <TotalsByTypeStatusItems
            key={index}
            tot={tot}
          />
        ))
      }


      <View style={styles.line} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>

          Listagem de Devolução
        </Text>
      </View>
      <View style={{ padding: '5px' }} />


      {
        invoices.map((invoice, index) => (<InvoiceDetails invoice={invoice} key={index} />))
      }


      <View style={[styles.line, { marginTop: '50px' }]} />
    </>
  )
}



const TotalsByTypeStatusItems = ({ key, tot }) => {
  return (

    <View style={styles.containerRow}>
      <View style={{ width: '16%' }}>
        <Text style={styles.text}>{tot.type}</Text>
      </View>
      <View style={{ width: '9%' }}>
        <Text style={styles.text}>{tot.qtde}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{currency(tot.total)}</Text>
      </View>
    </View>
  )
}




const InvoiceDetails = ({ invoice }) => {

  return (
    <View style={{ marginTop: '10px' }}>
      <InvoiceDetailsHeader invoice={invoice} />
      <InvoiceDetailsTableHeader />

      {
        invoice.SalesItems.map((item, index) => (
          <InvoiceDetailsTableItem item={item} key={index} invoice={invoice} />
        ))
      }
      <View style={styles.line} />
    </View>
  )
}

const InvoiceDetailsHeader = ({ invoice, key }) => {
  return (
    <View key={key}>
      <View style={{ marginTop: '6px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '7%' }]}>Data Venda: </Text>
        <Text style={{ width: '7%', right: '4px' }}>{format(new Date(invoice.Date), 'dd/MM/yyyy')}</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Devolução: </Text>
        <Text style={{ width: '7%', right: '4px' }}>{format(new Date(invoice.SalesReturns[0].createdAt), 'dd/MM/yyyy')}</Text>
        <Text style={[styles.boldText, { width: '3%' }]}>Tipo: </Text>
        <Text style={{ width: '11%' }}>{invoice.SalesType.Description}</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Número: </Text>
        <Text style={{ width: '5%', right: '2px' }}>{invoice.code}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '4%' }]}>Cliente: </Text>
        <Text style={[{ width: '18%', margin: '3px' }]}>{invoice.Customer.Company_Name}</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor Venda/OS:  </Text>
        <Text style={[{ width: '6%' }]}>{invoice.code}</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Devolução:  </Text>
        <Text style={[{ width: '10%', right: '4px' }]}>{currency(invoice.returnedValue)}</Text>
      </View>

    </View>
  )
}



const InvoiceDetailsTableHeader = ({ }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Codigo</Text>
        <Text style={[styles.boldText, { width: '26%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Qtd Devol.</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Qtd Original</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Preço Unit</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}


const InvoiceDetailsTableItem = ({ item, key, invoice }) => {
  let quantityItemReturn = 0

  const saleReturn = (invoice.SalesReturns.filter((sale) => sale.saleId === invoice.id))

  saleReturn.map((sale, index) => {
    const returnitem = (sale.SalesReturnsItems.filter((sale) => sale.saleId === item.saleId))

    const itemFiltered = (returnitem.filter((sale) => sale.salesItemId === item.id))

    quantityItemReturn = itemFiltered[0]?.originalQuantity
  })

  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={{ width: '9%' }}>{item.Products.Code}</Text>
        <Text style={{ width: '28%' }}>{item.Description}</Text>
        <Text style={{ width: '10%' }}>{item.QuantityItemsReturned}</Text>
        <Text style={{ width: '7%' }}>{quantityItemReturn}</Text>
        <Text style={{ width: '8%' }}>{currency(item.Unit_Value)}</Text>
        <Text style={{ width: '7%' }}>{currency(item.Unit_Value * item.QuantityItemsReturned)}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>

  )
}

export default ReturnListingPDF



const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },

})