import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';

import Form from './FormNewShippingCompany';

import api from '../../../../../services/api';
import config from '../../../../../config';

import { onlyNumbers, cepMask, cnpjMask, cpfMask } from '../../../../components/ToNormalize/ToNormalize';
import { validadeEmail, validateCpf, validateCnpj, validadePhone } from '../../../../components/Validations/Validations';

function FormNewShippingCompany({ history, onCancel, onSubmit, shippingCompanyId }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!shippingCompanyId) {
      loadCarrier()
    }
  }, []);

  async function loadCarrier() {
    const response = await api.get(config.endpoint + `shipping?id=${shippingCompanyId}`);

    if (response.data.length) {
      const Cpf_Cnpj = response.data[0].Type === 'Fisica' ? cpfMask(response.data[0].Cpf_Cnpj) : cnpjMask(response.data[0].Cpf_Cnpj)

      dispatch([
        change('cadastroTransportadora', 'Type', response.data[0].Type),
        change('cadastroTransportadora', 'Cpf_Cnpj', Cpf_Cnpj),
        change('cadastroTransportadora', 'Name', response.data[0].Name),
        change('cadastroTransportadora', 'IE', response.data[0].IE),
        change('cadastroTransportadora', 'Email', response.data[0].Email),
        change('cadastroTransportadora', 'Number_Phone1', response.data[0].Number_Phone1),
        change('cadastroTransportadora', 'Number_Phone2', response.data[0].Number_Phone2),
        change('cadastroTransportadora', 'Contact', response.data[0].Contact),
        change('cadastroTransportadora', 'Observation', response.data[0].Observation),
        change('cadastroTransportadora', 'Zipcode', cepMask(response.data[0].Zipcode)),
        change('cadastroTransportadora', 'Address', response.data[0].Address),
        change('cadastroTransportadora', 'Address_Number', response.data[0].Address_Number),
        change('cadastroTransportadora', 'Neighborhood', response.data[0].Neighborhood),
        change('cadastroTransportadora', 'Complement', response.data[0].Complement),
        change('cadastroTransportadora', 'Reference_Point', response.data[0].Reference_Point),
        change('cadastroTransportadora', 'State', response.data[0].State),
        change('cadastroTransportadora', 'City', response.data[0].City),
        change('cadastroTransportadora', 'Country', response.data[0].Country),
        change('cadastroTransportadora', 'Company_id', response.data[0].Company_id),
        change('cadastroTransportadora', 'Status', response.data[0].IsActive === 1 ? true : false),
        change('cadastroTransportadora', 'id', response.data[0].id),
      ]);
    };
  };

  async function formValidation(values) {

    const {
      Type,
      Cpf_Cnpj,
      Name,
      Email,
      Number_Phone1,
      Number_Phone2,
      Zipcode,
      Address,
      Address_Number,
      Neighborhood,
      City,
      State,
      Company_id,
      id
    } = values;

    if (
      !Name ||
      !Cpf_Cnpj ||
      !Zipcode ||
      !Address ||
      !Address_Number ||
      !Neighborhood ||
      !City ||
      !State
    ) {
      toastr.warning("Por favor, preencha todos os dados para realizar o cadastro.")
      return false;
    };

    if (Type === 'Fisica' && !validateCpf(onlyNumbers(Cpf_Cnpj))) {
      toastr.warning("CPF inválido");
      return false;
    };

    if (Type === 'Juridica' && !validateCnpj(onlyNumbers(Cpf_Cnpj))) {
      toastr.warning("CNPJ inválido");
      return false;
    };

    if (Email && !validadeEmail(Email)) {
      toastr.warning("E-mail inválido");
      return false;
    };

    if (Number_Phone1 && !validadePhone(Number_Phone1)) {
      if (Type === 'Fisica') {
        toastr.warning("Telefone inválido");
      } else {
        toastr.warning("Telefone Comercial inválido");
      };
      return false;
    };

    if (Type === 'Juridica' && Number_Phone2 && !validadePhone(Number_Phone2)) {
      toastr.warning("Telefone Celular inválido");
      return false;
    };

    const response = await api.get(config.endpoint + `shipping?Company_id=${Company_id}`)

    let checkCarrier;
    response.data.map(c => {
      if (c.Cpf_Cnpj === onlyNumbers(Cpf_Cnpj)) {
        checkCarrier = true;
        if (id && c.id === id) checkCarrier = false;
      };
    });

    if (checkCarrier) {
      toastr.warning("Transportadora já cadastrada.")
      return false;
    }

    return true;
  };

  async function handleSubmit(values) {
    if (! await formValidation(values)) return;
    setLoading(true);
    values.id ? await update(values) : await create(values);
    setLoading(false);
  };

  async function create(values) {

    const {
      Type,
      Status,
      Cpf_Cnpj,
      Name,
      Email,
      IE,
      Number_Phone1,
      Number_Phone2,
      Contact,
      Observation,
      Zipcode,
      Address,
      Address_Number,
      Neighborhood,
      Complement,
      Reference_Point,
      City,
      State,
      Company_id
    } = values;

    try {
      const shippingCompany = await api.post(config.endpoint + "shipping", {
        Type,
        IsActive: Status,
        Cpf_Cnpj: onlyNumbers(Cpf_Cnpj),
        Name,
        Email,
        IE,
        Number_Phone1: Number_Phone1 ? onlyNumbers(Number_Phone1) : Number_Phone1,
        Number_Phone2: Number_Phone2 ? onlyNumbers(Number_Phone2) : Number_Phone2,
        Contact,
        Observation,
        Zipcode: onlyNumbers(Zipcode),
        Address,
        Address_Number,
        Neighborhood,
        Complement,
        Reference_Point,
        City,
        State,
        Country: "Brasil",
        Company_id
      });

      toastr.success("Sucesso!", "Transportadora cadastrada com sucesso.")
      onSubmit(shippingCompany)
    } catch (err) {
      toastr.error("Falha na autenticação", err?.response?.data?.message)
    };

  };

  async function update(values) {

    const {
      Type,
      Status,
      Cpf_Cnpj,
      Name,
      Email,
      IE,
      Number_Phone1,
      Number_Phone2,
      Contact,
      Observation,
      Zipcode,
      Address,
      Address_Number,
      Neighborhood,
      Complement,
      Reference_Point,
      City,
      State,
      Company_id,
      id
    } = values;

    try {
      await api.put(config.endpoint + `shipping/${id}`, {
        Type,
        IsActive: Status,
        Cpf_Cnpj: onlyNumbers(Cpf_Cnpj),
        Name,
        Email,
        IE,
        Number_Phone1: Number_Phone1 ? onlyNumbers(Number_Phone1) : Number_Phone1,
        Number_Phone2: Number_Phone2 ? onlyNumbers(Number_Phone2) : Number_Phone2,
        Contact,
        Observation,
        Zipcode: onlyNumbers(Zipcode),
        Address,
        Address_Number,
        Neighborhood,
        Complement,
        Reference_Point,
        City,
        State,
        Country: "Brasil",
        Company_id
      });

      toastr.success("Sucesso!", "Transportadora atualizada com sucesso.")
      onSubmit()
    } catch (err) {
      toastr.error("Falha na autenticação", err?.response?.data?.message)
    };
  }

  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          Type: 'Juridica',
          Status: true,
          Company_id: localStorage.getItem('ID_EMPRESA')
        }}
        loading={loading}
        onCancel={onCancel}
      />
    </div>
  );
};

export default withRouter(FormNewShippingCompany);