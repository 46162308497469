import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import {
  faCloudDownloadAlt,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SalesRepository from '../../../../../repositories/Sales'
import GraphicByFormPayment from './GraphicByFormPayment'
import TableByFormPayment from './TableByFormPayment'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import UpgradeFreePlanModal from 'client/components/UpgradeFreePlanModal'
import { format, isValid, subMonths } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import './styles.css'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'
const PorFormPagamento = () => {
  const [isUpgradeFreePlanModalOpen, setIsUpgradeFreePlanModalOpen] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [displayItens, setDisplayItens] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [groupBy, setGroupBy] = useState('paymentMethod')
  const [salesInfoToExport, setSalesInfoToExport] = useState([])
  const [formsOfPaymentChartData, setFormsOfPaymentChartData] = useState([])
  const [formatTableData, setFormatTableData] = useState([])
  const isPlanFree = localStorage.getItem('ID_EMPRESA')
  const Company_id = localStorage.getItem('ID_EMPRESA')

  const handleSubmit = async () => {
    if (validations()) handleProcess()
  }

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      )
      return false
    }

    return true
  }

  const handleProcess = async () => {
    setLoading(true)
    setDisabled(false)
    try {
      const data = await SalesRepository.generateSalesByFormPayment({
        Company_id,
        initialDate,
        finalDate,
      })

      if (!data?.totalizatorsByFormOfPayment.length)
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente'
        )

      const formatedSalesTitle = data.totalizatorsByFormOfPayment.map(
        (item) => ({
          description: item.description,
          amount: item.total,
        })
      )
      const dataXLS = data.formOfPayments
        .map((item) =>
          item.rows.map((row) => ({
            ...row,
            formOfPayment: item.formOfPayment,
          }))
        )
        .reduce((prev, curr) => [...prev, ...curr], [])
        .map((item) => {
          return {
            type: item.isReceived ? 'Recebido' : 'A Receber',
            saleDate: item.saleCreationDate
              ? format(new Date(item.saleCreationDate), 'dd/MM/yyyy')
              : '',
            saleCloseDate: item.saleCloseDate
              ? format(new Date(item.saleCloseDate), 'dd/MM/yyyy')
              : '',
            saleCode: item.saleCode,
            saleType: item.saleType,
            customer: item.customer,
            billValue: currency(item.amount),
            dueDate: item.dueDate
              ? format(new Date(item.dueDate), 'dd/MM/yyyy')
              : '',
            paymentDate: item.paymentDate
              ? format(new Date(item.paymentDate), 'dd/MM/yyyy')
              : '',
            formOfPayment: item.formOfPayment,
          }
        })

      setFormsOfPaymentChartData(
        data?.formOfPayments.map((item) => {
          return [
            item.formOfPayment,
            Number(item.total),
            currency(Number(item.total)),
          ]
        })
      )

      setFormatTableData(formatedSalesTitle)
      setSalesInfoToExport(dataXLS)
      setDisabled(true)
    } catch (err) {
      console.log(err)
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/sales-by-form-payment?companyId=${Company_id}&initialDate=${initialDate}&finalDate=${finalDate}&groupBy=${groupBy}&displayItens=${displayItens}`,
      '_blank'
    )
  }

  return (
    <div className="relatorio-por-pagamento-container">
      <header>
        <div>
          <span>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="initialDate"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>

        <div>
          <span>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="finalDate"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>

        <div>
          <span>
            <strong>Agrupar por:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="groupBy"
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
          >
            <option value={'paymentMethod'} selected>
              Forma de Pagamento
            </option>
            <option value={'sales'}>Vendas</option>
          </select>
        </div>

        {groupBy === 'sales' && (
          <div>
            <span>
              <strong>Exibir Itens:</strong>
            </span>
            <select
              className="form-control foco-input"
              name="displayItens"
              value={displayItens}
              onChange={(e) => setDisplayItens(e.target.value)}
            >
              <option value={true}>Sim</option>
              <option value={false} selected>
                Não
              </option>
            </select>
          </div>
        )}

        <div style={{ marginTop: '33.2px' }}>
          <button
            className="btn btn-sucesso"
            disabled={loading}
            onClick={handleSubmit}
            style={{ height: '35px' }}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </div>

        <div style={{ marginTop: '33.2px' }}>
          <button
            className="component__pdf-button"
            onClick={handlePrint}
            disabled={loading || !salesInfoToExport.length}
            style={{ display: 'flex', alignItems: 'center', height: '35px' }}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              style={{ marginRight: '5px' }}
            />
            Exportar .pdf
          </button>
        </div>

        <div style={{ marginTop: '33.2px' }}>
          <DownloadXlsButton
            archiveName="VendasPorFormaDePagamento"
            data={salesInfoToExport}
            className="btn btn-export"
            disabled={loading || !salesInfoToExport.length}
            style={{ height: '35px' }}
            columns={[
              {
                name: 'Tipo',
                acessor: 'type',
              },
              {
                name: 'Dt Criação Venda',
                acessor: 'saleDate',
              },
              {
                name: 'Dt Fechamento Venda',
                acessor: 'saleCloseDate',
              },
              {
                name: 'Nº Venda/OS',
                acessor: 'saleCode',
              },
              {
                name: 'Tipo de Venda',
                acessor: 'saleType',
              },
              {
                name: 'Cliente',
                acessor: 'customer',
              },
              {
                name: 'FormaPagamento',
                acessor: 'formOfPayment',
              },
              {
                name: 'Valor',
                acessor: 'billValue',
              },
              {
                name: 'Dt Vencimento',
                acessor: 'dueDate',
              },
              {
                name: 'Dt Pagamento',
                acessor: 'paymentDate',
              },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>
      {isPlanFree === 'true' && (
        <div className="unlock-badge-container">
          <span
            className="sales-report-unlock-badge"
            onClick={() => setIsUpgradeFreePlanModalOpen(true)}
          >
            Desbloquear dados antes de{' '}
            {format(subMonths(new Date(), 1), "dd 'de' MMMM", {
              locale: pt,
            })}
          </span>
        </div>
      )}

      <section>
        <div style={{ padding: 5 }}>
          <TableByFormPayment data={formatTableData} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '5%',
          }}
        >
          <GraphicByFormPayment data={formsOfPaymentChartData} />
        </div>
      </section>

      <UpgradeFreePlanModal
        show={isUpgradeFreePlanModalOpen}
        onCancel={() => setIsUpgradeFreePlanModalOpen(false)}
      />
    </div>
  )
}

export default PorFormPagamento
