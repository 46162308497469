import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../../../components/AlertModal'
import { change } from 'redux-form'
import './style.css';
import ImageUploadDropzone from '../../../../components/ImagesUploadDropzone'
import { toastr } from 'react-redux-toastr';
import crypto from "crypto";


const ProductPhotos = () => {
  const dispatch = useDispatch();
  const { photos = [], photosToDelete = [] } = useSelector(state => state.form.formProduto.values)
  const [isRemovePhotoModalOpen, setIsRemovePhotoModalOpen] = useState(false)
  const [excludePhoto, setExcludePhoto] = useState({})


  const createFileObject = (file, index) => ({
    id: index,
    file: file,
    name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
    preview: URL.createObjectURL(file),
    uploaded: false
  })

  const handleValidateFiles = async (files, quantity) => {

    if (files.length > quantity) {
      toastr.warning(`Selecione somente ${quantity} ${quantity === 1 ? 'imagem' : 'imagens'}`)
      return false
    }

    for (let i = 0; i < quantity; i++) {

      if (files[i].size > 5000000) {
        toastr.warning('Por favor, adicione uma imagem menor, o limite máximo é de 5MB.')

        return false
      }

      URL.revokeObjectURL(files[i])

      return true
    }
  }

  const handleUploadPhoto = async (files, index) => {

    let validateFiles = await handleValidateFiles(files, 1)

    if (!validateFiles) return

    const file = createFileObject(files[0], index)

    const newArray = [...photos]

    if (index === photos.length) {
      newArray.push(file)
    } else {
      const objectToModify = newArray[index]

      if (objectToModify.uploaded) {
        handlePhotosToDelete(objectToModify)
      }

      newArray[index] = file
    }

    dispatch(change('formProduto', 'photos', newArray))
  }

  const handlePhotosToDelete = (photo) => {
    const arrayToModify = [...photosToDelete]

    arrayToModify.push(photo)

    dispatch(change('formProduto', 'photosToDelete', arrayToModify))
  }

  const handleAlertRemoveButton = (photo) => {
    setExcludePhoto(photo)
    setIsRemovePhotoModalOpen(true)
  }

  const handleAlertCancelButton = () => {
    setExcludePhoto({})
    setIsRemovePhotoModalOpen(false)
  }

  const handleRemovePhoto = () => {
    if (excludePhoto.uploaded) {
      handlePhotosToDelete(excludePhoto)
    }

    const newPhotosArray = photos.filter(photo => photo != excludePhoto)

    dispatch(change('formProduto', 'photos', newPhotosArray))

    setIsRemovePhotoModalOpen(false)
  }

  const missingSpots = new Array(5 - photos.length).fill()

  return (
    <>
      <span style={{ display: 'flex', justifyContent: 'flex-end' }}><strong>Tamanho máx. de 5 MB</strong></span>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {
          photos.map((photo, index) => (
            <ImageUploadDropzone
              image={photo}
              onRemove={() => handleAlertRemoveButton(photo)}
              onUpload={files => handleUploadPhoto(files, index)}
              label="Adicionar Imagem"
            />
          ))
        }
        {
          missingSpots.map((spot, index) => (
            <ImageUploadDropzone
              image={spot}
              onRemove={() => { }}
              onUpload={files => handleUploadPhoto(files, photos.length)}
              label="Adicionar Imagem"
            />
          ))
        }
      </div>

      <AlertModal
        message='Deseja realmente excluir a foto?'
        show={isRemovePhotoModalOpen}
        onCancel={handleAlertCancelButton}
        onHide={handleAlertCancelButton}
        onSubmit={handleRemovePhoto}
      />
    </>
  );
}

export default ProductPhotos;