import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useIBPT } from './hooks/useIBPT';
import { Modal } from 'react-bootstrap';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

export const IBPTDropZone = ({ companyId, isDateValidityIBPTFile }) => {
  const { validationIBPTFile, importIBPTFile, getFinalValidityIBPTFile } =
    useIBPT();
  const { fetchLoading, isLoading, LoadingComponent } = useLoading();
  const [isOpenImportIBPTModal, setIsOpenImportIBPTModal] =
    React.useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    await fetchLoading(async () => {
      const validationStatus = await validationIBPTFile(acceptedFiles[0]);
      if (validationStatus === 204) {
        setIsOpenImportIBPTModal(true);
        await importIBPTFile(companyId, acceptedFiles[0]);
        setIsOpenImportIBPTModal(false);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const labelButton = () => {
    switch (true) {
      case !isDateValidityIBPTFile && !isLoading:
        return 'Importar Tabela IBPT';
      case isLoading:
        return 'Importando Tabela...';
      case !isOpenImportIBPTModal && isDateValidityIBPTFile:
        return (
          <span>
            Tabela importada <FontAwesomeIcon icon={faCheck} color="green" />
          </span>
        );
      default:
        return 'Aguardando...';
    }
  };

  return (
    <>
      <div className="certificate-dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="flex center gap-025">
          <p>{labelButton()}</p>
          {isLoading && <span className={'fa fa-spinner fa-pulse fa-1x'} />}
        </div>
      </div>
      <Modal show={isOpenImportIBPTModal}>
        <Modal.Body>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: '20px' }}>
              Aguarde a validação e importação ....
            </p>
            <LoadingComponent />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
