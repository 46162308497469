import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { format, isValid, subDays } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import billsToPayRepository from '../../../../../repositories/BillsToPay';
import TableByStatus from './TableByStatus';
import ChartByStatus from './ChartByStatus';
import { useAuth } from 'contexts/auth';
import './styles.css';

const PaymentsByStatus = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeOfDate, setTypeOfDate] = useState('issuedAt');
  const [formsOfPaymentChartData, setFormsOfPaymentChartData] = useState([]);
  const [formatTableData, setFormatTableData] = useState([]);

  useEffect(() => {
    setInitialDate('');
    setFinalDate('');
    setFormsOfPaymentChartData([]);
    setFormatTableData([]);
  }, [period]);

  const handleProcess = async () => {
    setLoading(true);

    if (
      (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) &&
      !period
    ) {
      {
        !period
          ? toastr.warning(
              'Não foi possível gerar o relatório.',
              'Selecione o período para carregar os dados.'
            )
          : toastr.warning(
              'Não foi possível gerar o relatório.',
              'Preencha todos os campos e tente novamente'
            );
      }
      setLoading(false);
      return;
    }

    try {
      let calculatedInitialDate = initialDate;
      let calculatedFinalDate = finalDate;

      if (period === '15') {
        calculatedInitialDate = format(subDays(new Date(), 15), 'yyyy-MM-dd');
        calculatedFinalDate = format(new Date(), 'yyyy-MM-dd');
      } else if (period === '30') {
        calculatedInitialDate = format(subDays(new Date(), 30), 'yyyy-MM-dd');
        calculatedFinalDate = format(new Date(), 'yyyy-MM-dd');
      } else if (period === '60') {
        calculatedInitialDate = format(subDays(new Date(), 60), 'yyyy-MM-dd');
        calculatedFinalDate = format(new Date(), 'yyyy-MM-dd');
      }

      const data = await billsToPayRepository.generateBillsToPay({
        companyId,
        initialDate: calculatedInitialDate,
        finalDate: calculatedFinalDate,
        typeOfDate,
      });

      if (!data?.billsToPayByGroups.length)
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente.'
        );

      const formatedBillsToPay = data.billsToPayByGroups.map((item) => ({
        description: item.description,
        amount: item.totalLiquidValue,
      }));

      const formatedBillsToPayChart = data.billsToPayByGroups.map((item) => {
        return [
          item.description,
          Number(item.totalLiquidValue),
          currency(Number(item.totalLiquidValue)),
        ];
      });

      setFormsOfPaymentChartData(formatedBillsToPayChart);
      setFormatTableData(formatedBillsToPay);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relatorio-pagamento-por-status-container">
      <header>
        <div>
          <span>Período:</span>
          <select
            className="form-control foco-input"
            name="period"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value={''} selected>
              Selecione
            </option>
            <option value={'15'}>Últimos 15 dias</option>
            <option value={'30'}>Últimos 30 dias</option>
            <option value={'60'}>Últimos 60 dias</option>
            <option value={'0'}>Personalizar</option>
          </select>
        </div>
        {period !== '0' && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '4px',
                height: '35px',
                marginTop: '40px',
              }}
            >
              <button
                style={{ width: '130px' }}
                className="btn btn-sucesso"
                type="submit"
                onClick={handleProcess}
                disabled={period === ''}
              >
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />{' '}
                Processar
              </button>
            </div>
          </>
        )}

        {period === '0' && (
          <>
            <div>
              <span>Tipo de Data:</span>
              <select
                className="form-control foco-input"
                name="typeOfDate"
                value={typeOfDate}
                onChange={(e) => setTypeOfDate(e.target.value)}
              >
                <option value={'issuedAt'} selected>
                  Criação
                </option>
                <option value={'dueDate'}>Vencimento</option>
              </select>
            </div>
            <div>
              <span>Data Inicial:</span>
              <input
                className="form-control foco-input"
                type="date"
                name="initialDate"
                value={initialDate}
                max={
                  finalDate
                    ? format(
                        new Date(getDateOnlyFromDate(finalDate)),
                        'yyyy-MM-dd'
                      )
                    : format(new Date(), 'yyyy-MM-dd')
                }
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div>
              <span>Data Final:</span>
              <input
                className="form-control foco-input"
                type="date"
                name="finalDate"
                value={finalDate}
                min={
                  initialDate
                    ? format(
                        new Date(getDateOnlyFromDate(initialDate)),
                        'yyyy-MM-dd'
                      )
                    : undefined
                }
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '4px',
                height: '35px',
                marginTop: '40px',
              }}
            >
              <button
                style={{ width: '130px' }}
                className="btn btn-sucesso"
                type="submit"
                onClick={handleProcess}
                disabled={period === ''}
              >
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />{' '}
                Processar
              </button>
            </div>
          </>
        )}
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByStatus
            data={formatTableData}
            loading={loading}
            period={period}
          />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByStatus data={formsOfPaymentChartData} />
        </div>
      </section>
    </div>
  );
};

export default PaymentsByStatus;
