import { encrypt } from 'client/components/ToNormalize/ToNormalize'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import FormCashierBank from '../../../views/CashierBank/NewCashierBank/FormCashierBank'

import './styles.css'

const FormModalCashierBank = ({ cashierBankId, onCancel, onSubmit, ...rest }) => {
  const [cashierBankIdEncrypted, setCashierBankIdEncrypted] = useState(null)

  useEffect(() => {
    if (cashierBankId) {
      const hash = encrypt(String(cashierBankId), '@OS-dig:CashierBankId')
      setCashierBankIdEncrypted(hash)
    }
  }, [cashierBankId])
  return (
    <Modal
      show
      animation
      dialogClassName='form-cashier-bank-modal'
      onHide={onCancel}
      {...rest}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>{!cashierBankIdEncrypted ? 'Cadastrar Caixa/Banco' : 'Dados do Caixa/Banco'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormCashierBank
          cashierBankId={cashierBankIdEncrypted}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FormModalCashierBank