import React, { useState, useEffect } from 'react'
import { Grid } from 'react-bootstrap'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import SATCFe from './FormNewSATCFe'

import { decrypt } from '../../../components/ToNormalize/ToNormalize'

export default function ViewNewSATCe({ location }) {
  const [id, setId] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get('id')

    if (idParams) {
      readHash(idParams)
    }
  }

  async function readHash(hash) {
    const hashDecrypted = await decrypt(hash, '@OS-dig:SATCFeId')
    setId(hashDecrypted)
  }

  if (!id) {
    return <div />
  }

  return (
    <div className='content'>
      <Grid fluid>
        <BreadCrumb
          data={['Início', 'Notas Fiscais', `SAT ${code}`]}
          path={['home', 'invoices', null]}
        />
        <SATCFe SATCFeId={id} setCode={setCode} />
      </Grid>
    </div>
  )
}
