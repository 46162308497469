import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import routes from '../../../routes'
import PorFamilia from './PorFamilia'
import PorFormaPgto from './PorFormaPgto'
import PorProdutoServico from './PorProdutoServico'
import PorTipo from './PorTipo'
import ReturnForecast from './ReturnForecast'
import RevenuePerPeriod from './RevenuePerPeriod'
import { CommissionsReport } from './Commissions'
import ByAttendance from './ByAttendance'

const SalesReports = () => {
  const route = routes.find(
    (route) => `/client/${route.path}` === window.location.pathname
  )

  const renderComponent = (name) => {
    switch (name) {
      case 'Vendas por Família':
        return <PorFamilia />
      case 'Vendas por Formas de Pagamento':
        return <PorFormaPgto />
      case 'Vendas por Produto/Serviço':
        return <PorProdutoServico />
      case 'Vendas por Tipo/Status':
        return <PorTipo />
      case 'Comissões/Rendimentos do Mecânico':
        return <CommissionsReport />
      case 'Previsão de Retorno':
        return <ReturnForecast />
      case 'Faturamento por Período':
        return <RevenuePerPeriod />
      case 'Vendas por Atendimento':
        return <ByAttendance />
      default:
        return <div />
    }
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Vendas', route.name]}
            path={['Home', null, null, null]}
          />
          {renderComponent(route.name)}
        </Row>
      </Grid>
    </div>
  )
}

export default SalesReports
