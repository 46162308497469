import styled from 'styled-components';

export const ContainerFinancialScreen = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ContainerHeaderCardMonth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ContainerContentCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 0 4rem;
`;

export const ContainerGraph = styled.div`
  width: 100%;
  height: 325px;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
`;

export const ContainerGraphHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const ContainerGraphContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextHeaderCardTitle = styled.text`
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  text-transform: uppercase;
`;

export const ButtonPreviousOrNext = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    background: transparent;
    border: 0;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    filter: brightness(85%);
  }
`;
