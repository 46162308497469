import React, { useState, useEffect } from 'react';
import {
  ButtonLink,
  ButtonWrapper,
  CampaignWrapper,
  FlexEndWrapper,
  InlineWrapper,
  RichTextWrapper,
} from './Campaign.styles';
import { InputContainer } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { Button } from 'v2/components/Button/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faImage } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import { InputLabel } from 'v2/components/Input';
import { CRMCampaignRepository } from 'v2/repositories/CRMCampaignRepository';
import RichText from './RichText';
import { toastr } from 'react-redux-toastr';

export function Campaign() {
  const [headersOptions, setHeadersOptions] = useState([]);
  const [suggestionsOptions, setSuggestionsOptions] = useState([]);
  const [cnpjCasesOptions, setCnpjCasesOptions] = useState([]);
  const { setValue, watch } = useFormContext();
  const suggestion = watch('suggestion');
  const photo = watch('photo');
  const header = watch('header');
  const cnpjCase = watch('cnpjCase');

  const message = watch('message');
  const isReview = watch('isReview');

  const loadOptions = async () => {
    try {
      const suggestions = await CRMCampaignRepository.getSuggestions();
      setSuggestionsOptions(
        suggestions.map((type) => ({
          value: { id: type.id, text: type.text },
          label: type.description,
        }))
      );
      const headers = await CRMCampaignRepository.getHeaders();
      setHeadersOptions(
        headers.map((type) => ({ value: type.id, label: type.description }))
      );

      const cnpjCases = await CRMCampaignRepository.getCnpjCases();
      setCnpjCasesOptions(
        cnpjCases.map((type) => ({ value: type.id, label: type.description }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  function handleImageUpload(event) {
    const image = event.target.files[0];

    if (!image) {
      return;
    }

    if (!image.type.startsWith('image/')) {
      toastr.error('Formato inválido.', 'Permitido apenas arquivo de imagem');
      return;
    }

    if (image.size > 5000000) {
      toastr.error(
        'Tamanho inválido.',
        'O tamanho máximo permitido para anexar a imagem é 5mb'
      );
      return;
    }

    const newImage = {
      file: image,
      preview: URL.createObjectURL(image),
      name: image.name,
      size: image.size,
      description: image.description || '',
      type: image.type,
      webkitRelativePath: image.webkitRelativePath,
      lastModified: image.lastModified,
      lastModifiedDate: image.lastModifiedDate,
    };
    setValue('photo', newImage);
  }
  const [defaultMessage, setDefaultMessage] = useState([]);
  useEffect(() => {
    loadOptions();
  }, []);
  return (
    <CampaignWrapper>
      <InlineWrapper>
        <InputContainer style={{ gap: '0px' }}>
          <InputLabel required>Sugestão de Campanha:</InputLabel>
          <InputSelectDefault
            disabled={isReview}
            width={'200px'}
            height={'35px'}
            options={suggestionsOptions}
            value={suggestion}
            onChange={(option) => {
              setValue('suggestion', option);
              setValue('message', JSON.parse(option.value.text + '\n'));
              setDefaultMessage(JSON.parse(option.value.text + '\n'));
            }}
          />
        </InputContainer>
        <ButtonWrapper>
          <input
            style={{ display: 'none' }}
            id="imageFileInput"
            type="file"
            accept="image/*"
            onChange={(event) => handleImageUpload(event)}
            disabled={isReview}
          />
          {photo && (
            <ButtonLink
              onClick={() => setValue('photo', null)}
              style={{
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
              disabled={isReview}
            >
              Remover imagem
            </ButtonLink>
          )}
          <Button
            height="35px"
            color="buttonEmitNFSe"
            gap="10px"
            disabled={isReview}
            onClick={() => {
              if (!photo) document.getElementById('imageFileInput').click();
            }}
          >
            <FontAwesomeIcon icon={photo ? faCheck : faImage} size="lg" />
            {photo ? 'Imagem Importada' : 'Importar Imagem'}
          </Button>
        </ButtonWrapper>
      </InlineWrapper>
      <InlineWrapper style={{ marginTop: '20px' }}>
        <InputContainer style={{ gap: '0px' }}>
          <InputLabel required>Cabeçalho da Campanha:</InputLabel>
          <InputSelectDefault
            disabled={isReview}
            width={'200px'}
            height={'35px'}
            options={headersOptions}
            value={header}
            defaultValue={{
              label: 'Olá, Nome do Cliente',
              value: 1,
            }}
            onChange={(option) => {
              setValue('header', option);
              if (option.label === 'Não Enviar') {
                setValue('cnpjCase', null);
              } else {
                setValue('cnpjCase', {
                  label: 'Nome do Responsável',
                  value: 1,
                });
              }
            }}
          />
        </InputContainer>

        {header?.label !== 'Não Enviar' && (
          <InputContainer style={{ gap: '0px' }}>
            <InputLabel required>Se o cliente for CNPJ:</InputLabel>
            <InputSelectDefault
              disabled={isReview}
              width={'200px'}
              height={'35px'}
              options={cnpjCasesOptions}
              value={cnpjCase}
              onChange={(option) => {
                setValue('cnpjCase', option);
              }}
            />
          </InputContainer>
        )}
      </InlineWrapper>
      <RichTextWrapper>
        <RichText
          key={JSON.stringify(defaultMessage)}
          value={message}
          isDisabled={isReview}
          onChange={(value) => {
            setValue('message', value);
          }}
        />
      </RichTextWrapper>
    </CampaignWrapper>
  );
}
