import Button from 'client/components/CustomButton/CustomButton'
import AlertModal from 'components/AlertModal/AlertModal'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import MaskedInput from 'react-text-mask'
import vehiclesRepository from '../../../../repositories/Vehicles'

import './styles.css'

function PlateChangeModal({
  isPlateChangeModalOpen,
  setIsPlateChangeModalOpen,
  loadCustomers,
  companyId,
}) {
  const [oldVehiclePlate, setOldVehiclePlate] = useState('')
  const [newVehiclePlate, setNewVehiclePlate] = useState('')
  const [loadingPlateChange, setLoadingPlateChange] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)

  const handlePlateChange = async () => {
    setLoadingPlateChange(true)

    const oldVehiclePlateFormat = oldVehiclePlate
      ?.replace(/-/g, '')
      .replace(/_/g, '')
    const newVehiclePlateFormat = newVehiclePlate
      ?.replace(/-/g, '')
      .replace(/_/g, '')

    if (oldVehiclePlateFormat.length < 7 || newVehiclePlateFormat.length < 7) {
      toastr.warning(
        'Preencha os campos de placa adequadamente para que a troca seja realizada e tente novamente.'
      )
      return setLoadingPlateChange(false)
    }

    try {
      const oldPlateValidation = {
        plate: oldVehiclePlateFormat,
        companyId,
      }

      const newPlateValidation = {
        plate: newVehiclePlateFormat,
        companyId,
      }

      const oldPlateExists = await vehiclesRepository.vehicleValidateByPlate(
        oldPlateValidation
      )

      const newPlateExists = await vehiclesRepository.vehicleValidateByPlate(
        newPlateValidation
      )

      if (oldPlateExists && !newPlateExists) {
        const plates = {
          oldPlate: oldVehiclePlateFormat,
          newPlate: newVehiclePlateFormat,
          companyId,
        }

        await vehiclesRepository.updatePlate(plates)
        loadCustomers()
        toastr.success('Placa atualizada!')
        onCancelSubmit()
      } else if (!oldPlateExists) {
        toastr.warning('A placa a ser atualizada não existe!')
      } else if (newPlateExists) {
        toastr.warning('A placa Mercosul já existe!')
      }
    } catch (err) {
      console.log(err)
      toastr.error('Não foi possível atualizar a placa. Tente novamente.')
    } finally {
      setLoadingPlateChange(false)
    }
  }

  const onCancelSubmit = () => {
    setOldVehiclePlate('')
    setNewVehiclePlate('')
    setCancelModal(false)
    setIsPlateChangeModalOpen(false)
  }

  return (
    <>
      <Modal
        show={isPlateChangeModalOpen}
        onHide={() => {
          if (
            oldVehiclePlate.replace(/-/g, '').replace(/_/g, '').length > 0 ||
            newVehiclePlate.replace(/-/g, '').replace(/_/g, '').length > 0
          ) {
            setCancelModal(true)
          } else {
            onCancelSubmit()
          }
        }}
        animation
      >
        <Modal.Header closeButton>
          <h1 className="h1-plate-header">Troca Placa (Mercosul)</h1>
        </Modal.Header>

        <Modal.Body>
          <p>Insira a placa que deseja trocar:</p>
          <center>
            <div className="form-field-plate">
              Placa antiga:
              <MaskedInput
                name="oldVehiclePlate"
                mask={[
                  /[A-Za-z]/,
                  /[A-Za-z]/,
                  /[A-Za-z]/,
                  '-',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                value={oldVehiclePlate}
                onChange={(e) =>
                  setOldVehiclePlate(e.target.value.toUpperCase())
                }
              />
            </div>
            <div className="form-field-plate">
              Nova placa (Mercosul):
              <MaskedInput
                name="newVehiclePlate"
                mask={[
                  /[A-Za-z]/,
                  /[A-Za-z]/,
                  /[A-Za-z]/,
                  '-',
                  /[0-9]/,
                  /[A-Za-z]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                value={newVehiclePlate}
                onChange={(e) =>
                  setNewVehiclePlate(e.target.value.toUpperCase())
                }
              />
            </div>
            <small className="small-text">
              A troca de placa é disponível apenas de placas antigas para o
              modelo Mercosul
            </small>
          </center>
        </Modal.Body>

        <Modal.Footer>
          <div className="upgrade-modal-footer">
            <Button
              bsStyle="danger"
              fill
              onClick={() =>
                newVehiclePlate || oldVehiclePlate
                  ? setCancelModal(true)
                  : setIsPlateChangeModalOpen(false)
              }
            >
              Cancelar
            </Button>
            <Button
              bsStyle="info"
              fill
              onClick={handlePlateChange}
              disabled={loadingPlateChange}
            >
              <span
                className={`${
                  loadingPlateChange ? 'fa fa-spinner fa-pulse fa-1x' : ''
                }`}
              />
              <span>Atualizar Placa</span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={cancelModal}
        title="OS Digital"
        onHide={() => setCancelModal(false)}
        onCancel={() => setCancelModal(false)}
        subtitle="Você tem certeza que deseja cancelar a troca da placa do veículo?"
        message="Ao cancelar a troca da placa, o veículo terá sua placa e histórico mantido na placa antiga."
        onSubmit={onCancelSubmit}
      />
    </>
  )
}

export default PlateChangeModal
