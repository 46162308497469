import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'

import stoneLinkRepository from 'repositories/StoneCompany'
import StoneLinkForm from './StoneLinkForm'

const BundleStoneLinkForm = ({ stoneLinkId, goToHomepage }) => {
  const [saveLoading, setSaveLoading] = useState(false)
  const [integrationLoading, setIntegrationLoading] = useState(false)

  const handleSubmit = (values, integration) => {
    const { companyId, stoneCode } = values

    if (!companyId)
      return toastr.warning('Selecione uma empresa para salvar o vínculo.')

    if (integration && !stoneCode) {
      return toastr.warning(
        'Por favor, informe o Stonecode para prosseguir com a integração.'
      )
    }

    if (stoneLinkId) return update(values, integration)
    return create(values, integration)
  }

  const create = async (values, integration) => {
    const { companyId, stoneCode } = values

    if (integration) setIntegrationLoading(true)
    else setSaveLoading(true)

    try {
      const { data, status } = await stoneLinkRepository.create({
        companyId,
        stoneCode,
        integration,
      })

      if (status !== 201) throw new Error(JSON.stringify(data))

      if (integration)
        toastr.success('Vínculo salvo e transmitido com sucesso.')
      else toastr.success('Vínculo salvo com sucesso.')

      return goToHomepage()
    } catch (err) {
      const { statusCode, message } = JSON.parse(err.message)

      if (!statusCode || !message) {
        return toastr.error(
          'Ocorreu um erro ao criar vínculo. Tente novamente mais tarde.'
        )
      }

      return toastr.error(`Código ${statusCode}`, message)
    } finally {
      if (integration) setIntegrationLoading(false)
      else setSaveLoading(false)
    }
  }

  const update = async (values, integration) => {
    const { stoneCode } = values

    if (integration) setIntegrationLoading(true)
    else setSaveLoading(true)

    try {
      const { data, status } = await stoneLinkRepository.update(stoneLinkId, {
        stoneCode,
        integration,
      })

      if (!201) throw new Error(JSON.stringify(data))

      if (integration)
        toastr.success('Vínculo atualizado e transmitido com sucesso.')
      else toastr.success('Vínculo atualizado com sucesso.')

      return goToHomepage()
    } catch (err) {
      const { statusCode, message } = JSON.parse(err.message)

      if (!statusCode || !message) {
        return toastr.error(
          'Ocorreu um erro ao atualizar vínculo. Tente novamente mais tarde.'
        )
      }

      return toastr.error(`Código ${statusCode}`, message)
    } finally {
      if (integration) setIntegrationLoading(false)
      else setSaveLoading(false)
    }
  }

  return (
    <StoneLinkForm
      onSubmit={handleSubmit}
      onCancel={goToHomepage}
      saveLoading={saveLoading}
      integrationLoading={integrationLoading}
      stoneLinkId={stoneLinkId}
    />
  )
}

export default BundleStoneLinkForm
