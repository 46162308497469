import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import billsToReceiveRepository from '../../../repositories/BillsToReceive'
import companyConfigRepository from '../../../repositories/CompanyConfig'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

import BillsToReceiveReceiptDocumentA4 from './BillsToReceiveReceiptDocumentA4'
import { useAuth } from 'contexts/auth'
import BillsToReceiveReceiptDocumentA5 from './BillsToReceiveReceiptDocumentA5'

const BillsToReceiveReceiptReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [receipt, setReceipt] = useState([])

//   const { companyId } = useAuth()

  const [printConfig, setPrintConfig] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [])

  const loadPrintConfig = async (companyId) => {
    const data = await companyConfigRepository.show(companyId)
    setPrintConfig(data.billModel)
  }

  const loadQuery = async searchParams => {
    try {
      const billIdHash = searchParams.get('billId')

      let billId
      if (billIdHash) {
        billId = await decrypt(billIdHash, '@OS-dig:billId')
      }

      const companyIdHash = searchParams.get('companyId')

      let companyId
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId')
      }

      if (!companyId || !billId) {
        setHasError(true)
        setLoading(false)
      }

      loadPrintConfig(companyId)

      return loadReport({ billId, companyId })
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async ({ billId, companyId }) => {
    try {
      const data = await billsToReceiveRepository.generateReceiptReport({ billId, companyId })
      setReceipt(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >   
            {printConfig === 'A4' && (
                <BillsToReceiveReceiptDocumentA4
                    receipt={receipt} 
                />
            )}
            {printConfig !== 'A4' && (
                <BillsToReceiveReceiptDocumentA5 
                    receipt={receipt}
                />
            )}
        </PDFViewer>
      </main>
    </div>
  )
}

export default BillsToReceiveReceiptReport