import React from 'react'

import FinancingStepWizardFull from './components/Full'
import FinancingStepWizardDenied from './components/Denied'

const FinancingStepWizard = ({ percent, variant }) => (
  <div className="step-wizard-wrapper">
    {variant === 'full' ? (
      <FinancingStepWizardFull percent={percent} />
    ) : (
      <FinancingStepWizardDenied percent={100} />
    )}
  </div>
)

export default FinancingStepWizard
