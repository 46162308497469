import api from '../services/api';
import config from '../config';

const index = (params) =>
  api.get(config.endpoint + 'bank-billet-account', {
    params,
  });

const create = (data) =>
  api.post(config.endpoint + 'bank-billet-account', data);

const update = (id, data) =>
  api.put(config.endpoint + `bank-billet-account/${id}`, data);

const show = (id) => api.get(config.endpoint + `bank-billet-account/${id}`);

const showByCompanyId = (id) => api.get('bank-billet-account/by-company/' + id);

export default {
  index,
  create,
  update,
  show,
  showByCompanyId,
};
