import React, { useMemo, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { usePanelAppointmentStore } from '../../store';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import { ModalSharePanelAccess } from '../ModalSharePanelAccess/ModalSharePanelAccess';
import companyRepository from 'repositories/Companies';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import ReactInputMask from 'react-input-mask';

const confirmationMessages = {
  'start-service': {
    'all-services-with-appointments': (
      <div className="flex gap-075 column">
        <span>
          <strong>Você deseja iniciar o(s) serviço(s)?</strong>
        </span>
        <span>
          Todos os serviços foram apontados e os mecânicos serão identificados
          no painel durante a execução.
        </span>
      </div>
    ),
    'some-services-without-appointments': (
      <div className="flex gap-075 column">
        <span>
          <strong>
            Existem serviços que não foram apontados para execução
          </strong>
        </span>
        <span>
          Você deseja iniciar sem que sejam apontados todos os serviços? Os
          serviços não apontados não serão exibidos no painel, mas poderão ser
          apontados futuramente.
        </span>
      </div>
    ),
  },
  'finish-service': (
    <div className="flex gap-075 column">
      <span>
        <strong>Você deseja finalizar o(s) serviço(s)?</strong>
      </span>
      <span>
        Todos os serviços selecionados serão finalizados. Um serviço finalizado
        não poderá ser reaberto, o processo é irreversível.
      </span>
    </div>
  ),
};

export function ModalFooter({ maintenance, mode, handleClose }) {
  const {
    tempAppointments,
    handleStartServices,
    handleStopServices,
    handleFinishServices,
  } = usePanelAppointmentStore();
  const { setReloadPanelInformation } = useWorkshopPanel();
  const { companyId } = useAuth();

  const services = useMemo(() => {
    switch (mode) {
      case 'start-service':
        return maintenance.services.filter(
          (service) => service.shortStatus === 'NI'
        );
      default:
        return [];
    }
  }, [mode]);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openSharePanelAccessModal, setOpenSharePanelAccessModal] =
    useState(false);
  const [isProcessingConfirmation, setIsProcessingConfirmation] =
    useState(false);
  const [openWhatsAppConfirmationModal, setOpenWhatsAppConfirmationModal] =
    useState(false);
  const [clientNumber, setClientNumber] = useState(
    maintenance?.customer.Phones[0].Number_Phone2
  );

  const disabledConfirmationButton = useMemo(() => {
    return tempAppointments.length === 0 || isProcessingConfirmation;
  }, [mode, tempAppointments, isProcessingConfirmation]);

  const confirmButtonLabel = useMemo(() => {
    switch (mode) {
      case 'start-service':
        return 'Iniciar Serviço';
      case 'stop-service':
        return 'Parar Serviço';
      case 'finish-service':
        return 'Finalizar Serviço';
      default:
        return '';
    }
  }, [mode]);

  const confirmationTitle = useMemo(() => {
    switch (mode) {
      case 'start-service':
        return 'Início da Execução';
      case 'stop-service':
        return 'Parar Execução';
      case 'finish-service':
        return 'Finalizar Execução';
      default:
        return '';
    }
  }, [mode]);

  const confirmationMessage = () => {
    switch (mode) {
      case 'start-service':
        const allTempAppointmentsServicesIds = tempAppointments?.map(
          (appointment) => appointment.service.id
        );

        const allServicesHaveAppointments = services.every((service) =>
          allTempAppointmentsServicesIds.includes(service.id)
        );

        return allServicesHaveAppointments
          ? confirmationMessages['start-service'][
              'all-services-with-appointments'
            ]
          : confirmationMessages['start-service'][
              'some-services-without-appointments'
            ];
      case 'finish-service':
        return confirmationMessages['finish-service'];
      default:
        return '';
    }
  };

  const handleConfirmation = async () => {
    if (mode === 'start-service' || mode === 'finish-service')
      return setOpenConfirmationModal(true);
    if (mode === 'stop-service') {
      const servicesWithoutStopReason = tempAppointments.some(
        (appointment) => !appointment.stopReason
      );
      if (servicesWithoutStopReason) {
        toastr.warning(
          'Motivo não preenchido',
          'Preencha o motivo da pausa para parar o serviço'
        );
        return;
      }
      onSubmitConfirmation();
    }
  };

  const getExternalPanelLink = () => {
    const companyIdHash = encrypt(companyId.toString(), '@OS-dig:companyId');

    const saleIdHash = encrypt(
      maintenance?.saleId.toString(),
      '@OS-dig:saleId'
    );

    const domain = window.location.host;

    return `${domain}/external-workshop-panel/by-appointment/${companyIdHash}/${saleIdHash}`;
  };

  const loadCompanyInfo = async () => {
    try {
      const data = await companyRepository.getWithAddressAndPhones(companyId);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartService = async (openWhatsApp) => {
    try {
      setOpenSharePanelAccessModal(false);
      await handleStartServices(maintenance.maintenanceId);

      if (openWhatsApp) {
        const panelLink = getExternalPanelLink();

        const companyInfo = await loadCompanyInfo();
        const companyPhone =
          companyInfo?.Phones[0].Number_Phone1 ||
          companyInfo?.Phones[0].Number_Phone2 ||
          '';
        const text = `Prezado(a) ${
          maintenance?.customer.Company_Name || ''
        }%0a%0aFoi iniciado o serviço em seu veículo. Você poderá acompanhar o andamento dele através do link: ${encodeURIComponent(
          panelLink
        )} %0a%0aEm caso de dúvidas, pedimos que entre em contato conosco através do telefone ${
          companyPhone ? phoneMask(companyPhone) : ''
        }%0a%0aAtenciosamente, %0a%0a${
          companyInfo?.Trading_Name || companyInfo?.Company_Name || ''
        }`;
        window.open(
          `https://api.whatsapp.com/send?phone=55${clientNumber?.replace(
            /\D/g,
            ''
          )}&text=${text}`,
          '_blank'
        );
      }

      setIsProcessingConfirmation(false);
      setReloadPanelInformation(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitConfirmation = async () => {
    try {
      setIsProcessingConfirmation(true);

      switch (mode) {
        case 'start-service':
          setOpenSharePanelAccessModal(true);
          return;
        case 'stop-service':
          await handleStopServices(maintenance.maintenanceId);

          break;
        case 'finish-service':
          await handleFinishServices(maintenance.maintenanceId);
          break;
        default:
          return;
      }

      setIsProcessingConfirmation(false);
      setReloadPanelInformation(true);
    } catch (error) {
      setIsProcessingConfirmation(false);
      toastr.error('Falha no processamento', 'Por favor, tente novamente.');
      console.error(error);
    }
  };

  return (
    <div className="flex end gap-075">
      <button
        className="button button-red button-h35"
        onClick={handleClose}
        disabled={isProcessingConfirmation}
      >
        Voltar
      </button>

      {mode !== 'service-history' && (
        <button
          className="button button-green button-h35"
          onClick={handleConfirmation}
          disabled={disabledConfirmationButton}
        >
          {confirmButtonLabel}
        </button>
      )}

      {openConfirmationModal && (
        <AlertModal
          title={confirmationTitle}
          message={confirmationMessage()}
          show={openConfirmationModal}
          onCancel={() => setOpenConfirmationModal(false)}
          onHide={() => setOpenConfirmationModal(false)}
          onSubmit={onSubmitConfirmation}
          loading={isProcessingConfirmation}
        />
      )}

      {openSharePanelAccessModal && (
        <ModalSharePanelAccess
          show={openSharePanelAccessModal}
          onCancel={() => handleStartService(false)}
          onSubmit={() => setOpenWhatsAppConfirmationModal(true)}
          setOpenWhatsAppConfirmationModal={setOpenWhatsAppConfirmationModal}
        />
      )}

      {openWhatsAppConfirmationModal && (
        <AlertModal
          title="Compartilhar via WhatsApp"
          message={
            <div>
              <div>
                <label>Nome</label>
                <input
                  className="form-control foco-input"
                  value={
                    maintenance?.customer.Company_Name ||
                    'Cliente não encontrado'
                  }
                  disabled={true}
                />
              </div>
              <div>
                <label htmlFor="homePhoneNumber">Celular</label>
                <ReactInputMask
                  onChange={(e) => {
                    setClientNumber(e.target.value);
                  }}
                  value={clientNumber}
                  onKeyDown={(e) => (e.key === 'Enter' ? null : null)}
                  mask={'(99) 99999-9999'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'homePhoneNumber'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              </div>
            </div>
          }
          show={openWhatsAppConfirmationModal}
          onSubmit={() => handleStartService(true)}
          onCancel={() => setOpenWhatsAppConfirmationModal(false)}
          onHide={() => setOpenWhatsAppConfirmationModal(false)}
        />
      )}
    </div>
  );
}
