import React, { useEffect } from 'react';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';

import {
  description,
  name,
  email,
  phoneMask,
  cnpjMask,
  onlyNumbers,
  cepMask,
} from '../../../../components/ToNormalize/ToNormalize';
import Select from './components/Select';
import { BrasilAPIService } from 'v2/services/brasilApi';

const PJ = ({ edit }) => {
  const { providerId, statusIE, IE, cpfCnpj } = useSelector(
    (state) => state.form.formProvider.values
  );
  const dispatch = useDispatch();

  const fillDataByCNPJ = async () => {
    try {
      const { data } = await BrasilAPIService.consultCnpj(onlyNumbers(cpfCnpj));

      const cellphone =
        data.ddd_telefone_1?.length === 11
          ? data.ddd_telefone_1
          : data.ddd_telefone_2?.length === 11
          ? data.ddd_telefone_2
          : '';

      const commercialPhone =
        data.ddd_telefone_1.length === 10
          ? data.ddd_telefone_1
          : data.ddd_telefone_2?.length === 10
          ? data.ddd_telefone_2
          : '';

      dispatch(change('formProvider', 'companyName', data.razao_social || ''));
      dispatch(change('formProvider', 'tradingName', data.nome_fantasia || ''));
      dispatch(change('formProvider', 'email', data.email || ''));
      dispatch(change('formProvider', 'Zipcode', cepMask(data.cep) || ''));
      dispatch(change('formProvider', 'Complement', data.Complement || ''));
      dispatch(change('formProvider', 'Address_Number', data.numero || ''));
      dispatch(
        change(
          'formProvider',
          'cellPhone',
          cellphone ? phoneMask(cellphone) : ''
        )
      );
      dispatch(
        change(
          'formProvider',
          'commercialPhone',
          commercialPhone ? phoneMask(commercialPhone) : ''
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleChangeStatusIE();
  }, [statusIE]);

  useEffect(() => {
    if (cpfCnpj?.length === 18 && !providerId) {
      fillDataByCNPJ();
    }
  }, [cpfCnpj]);

  const handleChangeStatusIE = () => {
    if (statusIE === '1' && !IE) {
      toastr.warning(
        'Opção válida apenas quando há Inscrição Estadual. Preencha o campo'
      );
      return dispatch(change('formProvider', 'statusIE', null));
    }

    if (statusIE === '2') {
      return dispatch(change('formProvider', 'IE', 'ISENTO'));
    } else if (IE === 'ISENTO') {
      return dispatch(change('formProvider', 'IE', ''));
    }
  };

  return (
    <>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="CNPJ:"
          name="cpfCnpj"
          component={Input}
          normalize={cnpjMask}
          placeholder=""
          maxLength="18"
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Inscrição Estadual:"
          name="IE"
          component={Input}
          maxLength="14"
          value={statusIE === 'Contribuinte Isento' ? 'ISENTO' : IE}
          disabled={statusIE === '2'}
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Razão Social:"
          required
          name="companyName"
          component={Input}
          normalize={description}
          maxLength={60}
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Nome Fantasia:"
          required
          name="tradingName"
          component={Input}
          normalize={description}
          maxLength={60}
        />
      </Col>
      <Col lg={2} md={5} sm={3} xs={12}>
        <Field
          label="E-mail:"
          name="email"
          type="text"
          required
          component={Input}
          normalize={email}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Comercial:"
          name="commercialPhone"
          component={Input}
          normalize={phoneMask}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Celular:"
          name="cellPhone"
          required
          component={Input}
          normalize={phoneMask}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Responsável:"
          required
          name="contact"
          component={Input}
          normalize={name}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <label>Indicador de IE:</label>
        <Field name="statusIE" component={Select}>
          <option value="">Selecione</option>
          <option value="1">Contribuinte de ICMS</option>
          <option value="2">Contribuinte Isento</option>
          <option value="9">Não Contribuinte</option>
        </Field>
      </Col>
    </>
  );
};

export default PJ;
