import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button';
import SearchInput from 'components/SearchInput';
import AlertModal from 'components/AlertModal';

import { getDateOnlyFromDate } from 'utils/dateHelpers';

import advertisementsRepository from '../../../repositories/Advertisements';
import AdvertisingActions from './AdvertisingActions';

import { BrandingLabel } from 'v2/components/BrandingLabel';
import { SelectBranding } from 'v2/components/Select';
import useDebounce from 'hooks/useDebounce';

const Advertisements = ({ history }) => {
  const [advertisements, setAdvertisements] = useState([]);
  const [advertisingId, setAdvertisingId] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [
    isConfirmActivateAdvertisingModalOpen,
    setIsConfirmActivateAdvertisingModalOpen,
  ] = useState(false);

  const [branding, setBranding] = useState({ label: 'Todos', value: '' });
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  useEffect(() => {
    loadAdvertisements();
  }, [
    currentPage,
    pageLimit,
    branding,
    queryDebounced,
    status,
    initialDate,
    finalDate,
  ]);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  async function loadAdvertisements() {
    setLoading(true);
    try {
      const advertisements = await advertisementsRepository.getAll({
        activeOnly: null,
        exclusiveOnly: null,
        brandingId: branding.value,
        query: queryDebounced,
        status,
        initialDate,
        finalDate,
        limit: pageLimit,
        page: currentPage + 1,
      });
      setAdvertisements(advertisements.data);
      setTotalPages(Math.ceil(advertisements.count[0].numberRows / pageLimit));
    } catch (err) {
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar as propagandas. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleDisableAdvertising(advertisingId) {
    setLoading(true);

    try {
      await advertisementsRepository.disable(advertisingId);
      const newAdvertisements = [...advertisements];
      const index = newAdvertisements.findIndex(
        (child) => child.id === advertisingId
      );
      if (index !== -1) {
        newAdvertisements[index].isActive = false;
      }
      setAdvertisements(newAdvertisements);
    } catch (err) {
      console.log(err);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning('Ocorreu um erro inesperado.');
      }
    }
    setLoading(false);
  }

  async function handleActivateAdvertising() {
    setLoading(true);

    try {
      await advertisementsRepository.activate(advertisingId);
      const newAdvertisements = [...advertisements];
      const index = newAdvertisements.findIndex(
        (child) => child.id === advertisingId
      );
      if (index !== -1) {
        newAdvertisements[index].isActive = true;
      }
      setAdvertisements(newAdvertisements);
      setIsConfirmActivateAdvertisingModalOpen(false);
    } catch (err) {
      if (err.response) {
        toastr.error(err.response.data.message);
      } else {
        toastr.error('Ocorreu um erro inesperado.');
      }
    }
    setLoading(false);
  }

  function handleOpenConfirmActivateAdvertisingModal(advertisingId) {
    setAdvertisingId(advertisingId);
    setIsConfirmActivateAdvertisingModalOpen(true);
  }

  return (
    <>
      <Card
        content={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="advertisements-row">
              <button
                className="btn btn-sucesso button-h35 fontSize-12"
                onClick={() => history.push('advertising')}
              >
                + Nova Propaganda
              </button>
              <SearchInput
                containerStyle={{ width: 370 }}
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                style={{ fontSize: '12px' }}
              />
              <fieldset>
                <span className="typography__text-2">Status:</span>
                <select
                  className="form-control foco-input fontSize-12"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="">Ambos</option>
                  <option value="true">Ativa</option>
                  <option value="false">Inativa</option>
                </select>
              </fieldset>
              <fieldset>
                <span className="typography__text-2">Produto:</span>
                <SelectBranding
                  showAllOption
                  variant={'column'}
                  height={'35px'}
                  value={branding}
                  onChange={(e) => setBranding(e)}
                  hideLabel={true}
                  fontSizeInput={'12px'}
                />
              </fieldset>
            </div>
            <div className="advertisements-row">
              <fieldset>
                <span className="typography__text-2">Data Inicial:</span>
                <input
                  className="form-control foco-input fontSize-12"
                  type="date"
                  name="dataInicial"
                  max={
                    finalDate
                      ? format(
                          new Date(getDateOnlyFromDate(finalDate)),
                          'yyyy-MM-dd'
                        )
                      : format(new Date(), 'yyyy-MM-dd')
                  }
                  onChange={(e) => setInitialDate(e.target.value)}
                  value={initialDate}
                />
              </fieldset>
              <fieldset>
                <span className="typography__text-2">Data Final:</span>
                <input
                  className="form-control foco-input fontSize-12"
                  type="date"
                  name="dataFinal"
                  min={
                    initialDate
                      ? format(
                          new Date(getDateOnlyFromDate(initialDate)),
                          'yyyy-MM-dd'
                        )
                      : undefined
                  }
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </fieldset>
            </div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              data={advertisements}
              columns={[
                {
                  Header: 'Dt. Criação',
                  accessor: 'createdAt',
                  width: 100,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Produto',
                  accessor: 'brandingId',
                  width: 90,
                  Cell: (props) => <BrandingLabel brandingId={props.value} />,
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'GLOBAL' ? '#428bca' : '#5cb85c',
                      }}
                    >
                      {props.value === 'GLOBAL' ? 'Global' : 'Exclusivo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                },
                {
                  Header: 'Possui link?',
                  accessor: 'redirectUrl',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: props.value ? 'green' : 'red',
                      }}
                    >
                      {props.value ? 'Sim' : 'Não'}
                    </Button>
                  ),
                },
                {
                  Header: 'Clicks',
                  accessor: 'clicks',
                  width: 150,
                  Cell: (props) =>
                    !props.original.redirectUrl ? '----' : props.value,
                },
                {
                  Header: 'Status',
                  width: 100,
                  accessor: 'isActive',
                  Cell: (props) => (
                    <div>
                      <Toggle
                        checked={props.value}
                        name="Status"
                        style={{ position: 'fixed' }}
                        readOnly
                        onClick={() =>
                          props.value
                            ? handleDisableAdvertising(props.original.id)
                            : handleOpenConfirmActivateAdvertisingModal(
                                props.original.id
                              )
                        }
                      />
                    </div>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  filterable: false,
                  width: 100,
                  Cell: (props) => (
                    <AdvertisingActions
                      id={props.original.id}
                      redirectUrl={props.original.redirectUrl}
                      description={props.original.description}
                    />
                  ),
                },
              ]}
              defaultPageSize={10}
              pageSize={pageLimit}
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              manual
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        }
      />

      <AlertModal
        show={isConfirmActivateAdvertisingModalOpen}
        loading={loading}
        title="Ativar Propaganda"
        message="Deseja ativar a propaganda? Se ativa, a mesma será exibida no dashboard de todos os clientes dos segmentos e planos selecionados."
        onCancel={() => setIsConfirmActivateAdvertisingModalOpen(false)}
        onHide={() => setIsConfirmActivateAdvertisingModalOpen(false)}
        onSubmit={handleActivateAdvertising}
      />
    </>
  );
};

export default withRouter(Advertisements);
