import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useRouter } from 'v2/hooks/useRouter';
import { deleteSalesSubtype } from 'v2/repositories/SalesSubtypeRepository';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir o subtipo? Ao excluir, não poderá
        vincular a nenhuma venda e deverá cadastrar um novo.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluído, não poderá ser recuperado.
      </span>
    </div>
  );
};

export function TableActions({ id, triggerFetch, isDefault }) {
  const { navigateToClientPage } = useRouter();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteSalesSubtype(id);
      toastr.success(
        'Exclusão realizada com sucesso',
        'O Subtipo de Venda foi excluído'
      );
      triggerFetch();
    } catch (err) {
      console.log(err);

      const alreadyInUse =
        err.response?.data?.message === 'This salesSubtype is already in use.';

      const title = alreadyInUse
        ? 'Subtipo de Venda vinculado a venda'
        : 'Erro ao excluir';

      const message = alreadyInUse
        ? 'O Subtipo de Venda está vinculada a uma venda e não poderá ser excluído. Inative-o caso não queira mais utilizá-lo'
        : 'Por favor, tente novamente. Caso persista, contate o suporte.';

      toastr.error(title, message);
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex center gap-050">
      <button
        className="button-without-default-cursor"
        onClick={() =>
          isDefault
            ? toastr.warning(
                'Não autorizado',
                'Não é possível editar subtipos padrões.'
              )
            : navigateToClientPage('sale_subtype', 'form_edit_raw', id)
        }
        title={
          isDefault
            ? 'Subtipos padrões não podem ser editados e/ou excluídos'
            : 'Editar'
        }
      >
        <FontAwesomeIcon
          icon={faEdit}
          cursor={isDefault ? 'not-allowed' : 'pointer'}
        />
      </button>
      <button
        title={
          isDefault
            ? 'Subtipos padrões não podem ser editados e/ou excluídos'
            : 'Excluir'
        }
        className="button-without-default-cursor"
        onClick={() =>
          isDefault
            ? toastr.warning(
                'Não autorizado',
                'Não é possível excluir subtipos padrões.'
              )
            : setIsDeleteModalOpen(true)
        }
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="text-red"
          cursor={isDefault ? 'not-allowed' : 'pointer'}
        />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
