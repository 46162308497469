import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeCNAES from './HomeCNAES'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const CNAES = () => {
    localStorage.setItem('selectedPath', 'client/cnaes')
    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'CNAES']}
                        path={['home', null, null, 'cnaes']} />
                    <HomeCNAES
                    />
                </Row>
            </Grid>
        </div>
    );
}
export default CNAES