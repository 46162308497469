import React, { Component, useState, useEffect } from 'react';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import './styles.css';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleChange } from './ReduxMeusDetalhes/meusDetalhesAction';
import { toastr } from 'react-redux-toastr';
import api from '../../../services/api';
import config from '../../../config';
import { useAuth } from '../../../contexts/auth';

const MyServices = ({ branchId }) => {
  const [loading, setLoading] = useState(false);
  const [companyServices, setCompanyServices] = useState([]);
  const [currentServices, setCurrentServices] = useState([]);
  const [allServicesTypes, setAllServicesTypes] = useState([]);
  const [servicesFiltered, setServicesFiltered] = useState([]);
  const [serviceId, setServiceId] = useState(null);

  const { companyId } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      loadServiceTypes();
    }
  }, [companyId]);

  useEffect(() => {
    filterServices();
  }, [allServicesTypes, companyServices]);

  async function loadServiceTypes() {
    setLoading(true);

    try {
      const response = await api.get(
        config.endpoint + `companyServiceType/Search/ByCompany/${companyId}`
      );

      const serializedResponse = response.data.data.map((serv) => {
        const obj = {
          relation_Id: serv.id,
          Company_id: serv.Company_id,
          User_id: serv.User_id,
          ServiceType_id: serv.ServiceType_id,
          id: serv.ServiceType.id,
          Description: serv.ServiceType.Description,
        };
        return obj;
      });

      setCompanyServices(serializedResponse);
      setCurrentServices(serializedResponse);

      dispatch([
        handleChange(serializedResponse, 'companyServices'),
        handleChange(serializedResponse, 'currentServices'),
      ]);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os serviços prestados. Por favor, tente novamente'
      );
    }

    const defaultServiceTypes = await api.get(config.endpoint + `servicetype`);
    const serializedServiceTypes = defaultServiceTypes.data.map((s) => {
      const obj = {
        relation_Id: null,
        Company_id: null,
        User_id: null,
        ServiceType_id: s.id,
        id: s.id,
        Description: s.Description,
      };
      return obj;
    });
    setAllServicesTypes(serializedServiceTypes);
    setLoading(false);
  }

  function filterServices() {
    let filters = allServicesTypes.filter((n) => {
      let exist = false;
      for (let i = 0; i < companyServices.length; i++) {
        if (n.id === companyServices[i].id) exist = true;
      }
      if (!exist) return n;
    });

    filters.sort((a, b) => {
      var textA = a.Description.toUpperCase();
      var textB = b.Description.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setServicesFiltered(filters);
  }

  function handleAddService() {
    if (!serviceId) {
      return toastr.warning('Selecione um serviço para ser adicionado.');
    }
    const service = servicesFiltered.find(
      (service) => String(service.id) === String(serviceId)
    );
    if (service) {
      setCompanyServices([...companyServices, service]);
      dispatch(handleChange([...companyServices, service], 'companyServices'));
    }
  }

  function handleRemove({ id }) {
    const companyServicesFiltered = companyServices.filter(
      (service) => service.id !== id
    );
    setCompanyServices(companyServicesFiltered);
    setServiceId(null);
    dispatch(handleChange(companyServicesFiltered, 'companyServices'));
  }

  return (
    <div>
      <Col xs={12} sm={12} md={8} lg={8} id="column-servicosPrestados">
        <label>
          Selecione abaixo os serviços prestados pela sua empresa:
          {parseInt(branchId) !== 4 ||
            (parseInt(branchId) !== 5 && (
              <span style={{ color: 'red' }}>*</span>
            ))}
        </label>
        <div id="row-servicosPrestados">
          <Col
            xs={8}
            sm={8}
            md={5}
            lg={5}
            style={{ padding: '9px 0px 0px 0px' }}
          >
            <select
              className="form-control foco-input"
              id="select-services"
              onChange={(e) => setServiceId(e.target.value)}
            >
              <option value="" hidden>
                Selecione
              </option>
              {servicesFiltered.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.Description}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={4} sm={4} md={2} lg={3} style={{ marginTop: '6px' }}>
            <Button
              onClick={handleAddService}
              type="button"
              bsStyle="info"
              pullRight
              fill
            >
              Adicionar
            </Button>
          </Col>
        </div>
      </Col>

      {companyServices?.length > 0 && (
        <Col xs={12} sm={12} md={8} lg={8}>
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '3%',
              width: '420px',
            }}
            data={companyServices}
            columns={[
              {
                Header: 'Serviços Realizados',
                accessor: 'Description',
                headerClassName: 'text-left',
                width: 370,
              },
              {
                accessor: 'Actions',
                headerClassName: 'text-left',
                filterable: false,
                width: 50,
                Cell: (props) => (
                  <i
                    onClick={() => handleRemove(props.original)}
                    className="fa fa-trash-o"
                    style={{ color: 'red', cursor: 'pointer' }}
                    aria-hidden="true"
                  />
                ),
              },
            ]}
            defaultPageSize={5}
            loading={loading}
            showPagination={true}
            sortable={false}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5]}
            defaultSorted={[
              {
                id: 'Description',
                desc: false,
              },
            ]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Não há informação"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </Col>
      )}
    </div>
  );
};

export default MyServices;
