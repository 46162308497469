import produce from 'immer'
import { toastr } from 'react-redux-toastr'
import ActionTypes from './types'

const INITIAL_STATE = {
  products: []
}

const minStockAlertButtonReducer = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.handleChange:
        draft[action.payload.type] = action.payload.value
        break
      case ActionTypes.failureGetProductsUnderStock:
        draft.products = []
        toastr.error(action.payload.message)
        break
      default: {
        return draft
      }
    }
  })
}

export default minStockAlertButtonReducer