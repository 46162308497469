import React from 'react'

import {
  faCircleArrowRight,
  faCircleArrowLeft,
  faCircleArrowUp,
  faCircleArrowDown,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.css'

const arrowsIcons = {
  left: faCircleArrowLeft,
  right: faCircleArrowRight,
  up: faCircleArrowUp,
  down: faCircleArrowDown,
}

export default function ArrowRoundedButton({ action, direction, width, height }) {
  return (
    <button className="arrow-rounded-button" onClick={action}>
      <FontAwesomeIcon
        style={{
          width: width ? width : null,
          height: height ? height : null,
        }}
        className={'arrow-rounded-button-icon'}
        icon={arrowsIcons[direction]}
      />
    </button>
  )
}
