const purchaseAccessKey = accessKey => {
  if(!accessKey) return ''

  const parsedAccessKey = accessKey.substring(0, 4) + ' ' 
  + accessKey.substring(4, 9) + ' ' 
  + accessKey.substring(9, 14) + ' ' 
  + accessKey.substring(14, 19) + ' ' 
  + accessKey.substring(19, 24) + ' ' 
  + accessKey.substring(24, 30) + ' ' 
  + accessKey.substring(30, 35) + ' ' 
  + accessKey.substring(35, 40) + ' ' 
  + accessKey.substring(40, 45) + ' ' 
  + accessKey.substring(45, 50) + ' ' 
  + accessKey.substring(50, 55)

  return parsedAccessKey
}

export {
  purchaseAccessKey
}