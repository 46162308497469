import React from 'react'

const renderField = ({ input, label, required, meta: { touched, error }, ...rest }) => (
    <div>
        {label ?
            <label>{label}{required && <span style={{ color: 'red' }}>*</span>}</label>
            : null}
        {touched && error && <span style={{
            color: 'red',
            textAlign: 'left',
            fontSize: '10px',
            opacity: '0.9'
        }}>{error}</span>}
        <input className="form-control foco-input" autoComplete="new-password" {...rest} {...input} />
    </div>
)

export default renderField