import React from 'react';

import {
  SidebarSubMenuContainerWithDegree,
  SidebarSubMenuItem,
  SidebarSubMenuItemsContainer,
} from './SidebarSubMenuDegree.styles';

import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';

export const SidebarSubMenuDegree = ({
  subMenuTitle,
  subMenuDegreeTitle,
  items,
}) => {
  const { handleMenuItemClick, validateFeatureAvailability } = useSidebar();

  return (
    <SidebarSubMenuContainerWithDegree
      subMenuTitle={subMenuTitle}
      subMenuDegreeTitle={subMenuDegreeTitle}
    >
      <SidebarSubMenuItemsContainer>
        {items.map((item) => {
          const isAvailable = validateFeatureAvailability(item, false);

          return (
            <SidebarSubMenuItem
              key={item.title}
              onClick={() =>
                item.path ? handleMenuItemClick(item.path, item) : null
              }
              disabledStyle={!isAvailable}
            >
              {item.title}
            </SidebarSubMenuItem>
          );
        })}
      </SidebarSubMenuItemsContainer>
    </SidebarSubMenuContainerWithDegree>
  );
};
