import api from '../services/api';
import config from '../config';

const getClientSignature = (id) =>
  api.get(config.endpoint + 'integration/superlogica/client-signature', {
    params: {
      id,
    },
  });

const getSuperlogicaLoginToken = (email) =>
  api.get(config.endpoint + 'integration/superlogica/login-token', {
    params: {
      email,
    },
  });

const getClientBySacadoId = (sacadoId) =>
  api.get(config.endpoint + 'integration/superlogica/client/' + sacadoId);

const createClientSuperlogica = (companyId, brandingId) =>
  api.put(
    config.endpoint +
      `integration/superlogica/client/create/${companyId}/${brandingId}`
  );

const updateClientSuperlogica = (companyId) =>
  api.put(
    config.endpoint + `integration/superlogica/client/update/${companyId}`
  );

const getBankSlipSuperLogica = (companyId) =>
  api.get(config.endpoint + `integration/superlogica/bank-slip/${companyId}`);

const superlogicaRepository = {
  getClientSignature,
  getSuperlogicaLoginToken,
  createClientSuperlogica,
  updateClientSuperlogica,
  getClientBySacadoId,
  getBankSlipSuperLogica,
};

export default superlogicaRepository;
