import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { toastr } from 'react-redux-toastr'

import Toggle from 'react-toggle'
import Select from 'react-select'

import SearchIcon from 'components/SelectIcons/SearchIcon'
import CollapsibleSection from 'components/CollapsibleSection'
import Button from 'client/components/CustomButton/CustomButton'

import boletoFlexConfigurationsRepository from 'repositories/BoletoFlexConfigurations'
import companyRepository from 'repositories/Companies'

import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'

const BoletoFlexConfigurationsMain = ({
  goToHomePage,
  boletoFlexConfigurationId,
}) => {
  const [loading, setLoading] = useState(false)
  const [loadingClientsOptions, setLoadingClientsOptions] = useState(false)

  const [clientsOptions, setClientsOptions] = useState([])

  const { register, control, setValue, handleSubmit } = useForm({
    defaultValues: {
      cpfCnpj: '',
      companyName: '',
      tradingName: '',
      sellerName: '',
      isActive: true,
      selectedClient: null,
      companyId: null,
    },
  })

  const isActive = useWatch({
    control,
    name: 'isActive',
  })

  const selectedClient = useWatch({
    control,
    name: 'selectedClient',
  })

  async function loadClientsOptions() {
    setLoadingClientsOptions(true)
    try {
      const clientsWithoutConfiguration =
        await boletoFlexConfigurationsRepository.findClientsWithoutConfiguration()

      const { data: clientsWithPaidPlan } =
        await companyRepository.getCompaniesWithPaidPlan()

      const clientsWithPaidPlanIds = clientsWithPaidPlan.map(
        (client) => client.id
      )

      const clientsWithoutConfigurationWithPaidPlan =
        clientsWithoutConfiguration.filter((client) =>
          clientsWithPaidPlanIds.includes(client.id)
        )

      setClientsOptions(
        clientsWithoutConfigurationWithPaidPlan.map((client) => ({
          value: client,
          label: `${cpfOrCnpjMask(client.Cpf_Cnpj)} - ${
            client.Company_Name || client.Trading_Name
          }`,
        }))
      )
      setLoadingClientsOptions(false)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os clientes. Tente novamente!'
      )
      setLoadingClientsOptions(false)
    }
  }

  async function loadConfiguration() {
    setLoading(true)
    try {
      const boletoFlexConfiguration =
        await boletoFlexConfigurationsRepository.show(boletoFlexConfigurationId)

      setValue('companyId', boletoFlexConfiguration.companyId)
      setValue(
        'cpfCnpj',
        cpfOrCnpjMask(boletoFlexConfiguration.Company.Cpf_Cnpj)
      )
      setValue('tradingName', boletoFlexConfiguration.Company.Trading_Name)
      setValue('sellerName', boletoFlexConfiguration.sellerName)
      setValue('companyName', boletoFlexConfiguration.Company.Company_Name)
      setValue('isActive', boletoFlexConfiguration.isActive)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar a configuração. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit(data) {
    if (!data.sellerName) {
      return toastr.warning(
        'Campo obrigatório ausente',
        'Preencha o nome do seller conforme cadastro da Boleto Flex'
      )
    }

    setLoading(true)
    try {
      const formattedData = {
        isActive: data.isActive,
        companyId: data.companyId,
        sellerName: data.sellerName,
      }

      if (!boletoFlexConfigurationId) {
        await boletoFlexConfigurationsRepository.create(formattedData)
      } else {
        await boletoFlexConfigurationsRepository.update(
          boletoFlexConfigurationId,
          formattedData
        )
      }

      toastr.success(
        `Cliente ${
          boletoFlexConfigurationId ? 'salvo' : 'cadastrado'
        } com sucesso`,
        'A partir desse momento, o cliente poderá utilizar o financiamento em seu sistema'
      )

      goToHomePage()
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao efetuar a operação. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!boletoFlexConfigurationId) {
      loadClientsOptions()
    } else {
      loadConfiguration()
    }
  }, [])

  return (
    <div className="boleto-flex-config-wrapper">
      <div style={{ marginBottom: '10px', width: '50%' }}>
        <Select
          isSearchable
          filterOption={(client, input) => {
            return (
              client.data.value.Cpf_Cnpj.includes(input) ||
              client.data.value.Trading_Name?.includes(input) ||
              client.data.label.includes(input)
            )
          }}
          options={clientsOptions}
          placeholder={
            loadingClientsOptions
              ? 'Carregando Clientes...'
              : 'Pesquisa por CNPJ, Razão Social ou Nome Fantasia'
          }
          onChange={(e) => {
            setValue('selectedClient', e.value)
            setValue('cpfCnpj', cpfOrCnpjMask(e.value.Cpf_Cnpj))
            setValue('tradingName', e.value.Trading_Name)
            setValue('companyName', e.value.Company_Name)
            setValue('companyId', e.value.id)
          }}
          value={selectedClient}
          loading
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          components={{
            DropdownIndicator: SearchIcon,
          }}
          isDisabled={loadingClientsOptions || boletoFlexConfigurationId}
          styles={{
            control: (base) => ({
              ...base,
              height: '40px',
              minHeight: '40px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (base) => ({
              ...base,
              height: '40px',
              padding: '0 0 0 10px',
              marginTop: '-4px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              marginTop: '-4px',
            }),
          }}
        />
      </div>
      <CollapsibleSection
        title="Dados do Cliente"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div className="boleto-flex-config-row">
          <div>
            <label htmlFor="cpfCnpj">CNPJ:</label>
            <input
              type="text"
              id="cpfCnpj"
              className="form-control foco-input"
              {...register('cpfCnpj')}
              onChange={null}
              disabled
            />
          </div>
          <div>
            <label htmlFor="companyName">Razão Social:</label>
            <input
              type="text"
              id="companyName"
              className="form-control foco-input"
              {...register('companyName')}
              onChange={null}
              disabled
            />
          </div>
          <div>
            <label htmlFor="tradingName">Nome Fantasia:</label>
            <input
              type="text"
              id="tradingName"
              className="form-control foco-input"
              {...register('tradingName')}
              onChange={null}
              disabled
            />
          </div>
          <div>
            <label htmlFor="sellerName">Nome do Seller:</label>
            <input
              type="text"
              id="sellerName"
              className="form-control foco-input"
              {...register('sellerName')}
            />
          </div>
          <div className="toggle-wrapper">
            <label htmlFor="isActive">Status:</label>
            <Toggle
              id="isActive"
              style={{ position: 'fixed' }}
              checked={isActive}
              onChange={() => setValue('isActive', !isActive)}
            />
          </div>
        </div>
      </CollapsibleSection>
      <div className="boleto-flex-config-buttons">
        <Button
          bsStyle="danger"
          disabled={loading}
          onClick={() => goToHomePage()}
          fill
        >
          Cancelar
        </Button>

        <Button
          bsStyle="info"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          fill
        >
          Salvar
        </Button>
      </div>
    </div>
  )
}

export default BoletoFlexConfigurationsMain
