import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faFileInvoiceDollar,
  faHistory,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import SendWhatsAppModal from './SendWhatsAppModal';
import { CustomerHistoryModal } from './CustomerHistoryModal';
import constants from '../../../utils/constants';
import { toastr } from 'react-redux-toastr';

import AlertModal from 'components/AlertModal/AlertModal';
import customersRepository from 'repositories/Customers';
import { useAuth } from 'contexts/auth';
import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';
import { useDispatch } from 'react-redux';
import BlockSalesModals from 'client/components/BlockSalesModals';

const ClientActions = ({
  customer,
  history,
  loadCustomers,
  selectedClientId,
  setSelectedClientId,
}) => {
  const [isWhatsAppModalOpen, setIsWhatsAppModalOpen] = useState(false);
  const [isCustomerHistoryModalOpen, setIsCustomerHistoryModalOpen] =
    useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const { company, companyId } = useAuth();
  const dispatch = useDispatch();

  const [loadingExclusion, setLoadingExclusion] = useState(false);

  async function handleRedirectToSale() {
    await localStorage.setItem('CUSTOMER', JSON.stringify(customer));
    history.push(constants.ROUTES.SALE);
  }

  async function handleGenerateSale() {
    setSelectedClientId(customer.id);
    if (company?.hasCashierControl) {
      const isValid = await dispatch(cashierBankHistoricValidations(companyId));
      if (isValid.payload) {
        return handleRedirectToSale();
      }
    } else {
      return handleRedirectToSale();
    }
  }

  async function verifyCustomerExclusionEligibility() {
    try {
      const { isCustomerEligibleForExclusion } =
        await customersRepository.verifyCustomerExclusionEligibility({
          customerId: customer.id,
          companyId: customer.Company_id,
        });

      if (isCustomerEligibleForExclusion) {
        setIsDeleteConfirmationModalOpen(true);
      } else {
        toastr.warning(
          'OS Digital',
          'O cliente possui vínculo com venda e/ou com financeiro. Enquanto houver vínculos, não será possível apagar o cadastro.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao verificar eligibilidade de exclusão',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  }

  async function handleDeleteCustomer() {
    setLoadingExclusion(true);
    try {
      await customersRepository.deleteCustomer({
        customerId: customer.id,
        companyId: customer.Company_id,
      });

      toastr.success(
        'Cliente excluído com sucesso',
        'O cadastro de cliente foi excluído com sucesso!'
      );
      loadCustomers();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao excluir o cliente',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    } finally {
      setIsDeleteConfirmationModalOpen(false);
      setLoadingExclusion(false);
    }
  }

  return (
    <>
      <div>
        <FontAwesomeIcon
          cursor="pointer"
          icon={faEdit}
          style={{
            width: '1.5em',
            height: '1.5em',
          }}
          onClick={() =>
            history.push({
              pathname: `customer#${customer.id}`,
            })
          }
        />
        <FontAwesomeIcon
          onClick={handleGenerateSale}
          title="Iniciar Venda"
          cursor="pointer"
          style={{
            marginLeft: 5,
            height: '1.5em',
            width: '1.5em',
          }}
          icon={faFileInvoiceDollar}
        />
        <FontAwesomeIcon
          color="#82c91e"
          cursor="pointer"
          onClick={() => setIsWhatsAppModalOpen(true)}
          title="Enviar Mensagem"
          style={{
            marginLeft: 5,
            height: '1.5em',
            width: '1.5em',
          }}
          icon={faWhatsappSquare}
        />
        <FontAwesomeIcon
          cursor="pointer"
          onClick={() => setIsCustomerHistoryModalOpen(true)}
          title="Histórico do Cliente"
          style={{
            marginLeft: 5,
            height: '1.5em',
            width: '1.5em',
          }}
          icon={faHistory}
        />
        <FontAwesomeIcon
          cursor="pointer"
          onClick={() => verifyCustomerExclusionEligibility()}
          title="Excluir Cliente"
          style={{
            marginLeft: 5,
            height: '1.5em',
            width: '1.5em',
            color: '#bd362f',
          }}
          icon={faTrashAlt}
        />
      </div>

      {isWhatsAppModalOpen && (
        <SendWhatsAppModal
          customer={customer}
          onCancel={() => setIsWhatsAppModalOpen(false)}
          onSubmit={() => setIsWhatsAppModalOpen(false)}
        />
      )}
      {isCustomerHistoryModalOpen && (
        <CustomerHistoryModal
          customer={customer}
          onHide={() => setIsCustomerHistoryModalOpen(false)}
          onCancel={() => setIsCustomerHistoryModalOpen(false)}
        />
      )}
      {isDeleteConfirmationModalOpen && (
        <AlertModal
          show={isDeleteConfirmationModalOpen}
          loading={loadingExclusion}
          subtitle={'Você tem certeza que deseja excluir esse cadastro?'}
          message={
            'O processo é irreversível. Ao apagar o cadastro de cliente, será necessário cadastrar novamente para utilizá-lo.'
          }
          onHide={() => setIsDeleteConfirmationModalOpen(false)}
          onCancel={() => setIsDeleteConfirmationModalOpen(false)}
          onSubmit={() => handleDeleteCustomer(customer.id)}
        />
      )}

      {selectedClientId === customer.id && (
        <BlockSalesModals onSubmit={() => handleGenerateSale()} />
      )}
    </>
  );
};

export default withRouter(ClientActions);
