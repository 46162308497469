import config from '../config';
import api from '../services/api';

const create = async (NFe) => {
  try {
    const response = await api.post(config.endpoint + 'nfe', NFe);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `nfe?companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, NFe) => {
  try {
    await api.put(config.endpoint + `nfe/${id}`, NFe);
  } catch (err) {
    throw err;
  }
};

const emit = async (id, { issuedAt }) => {
  try {
    const response = await api.post(
      config.endpoint + 'nfe/emit',
      {
        NFeId: id,
        issuedAt,
      },
      { timeout: 240000 }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const show = async (id, includeSalesItems = 'true') => {
  try {
    const response = await api.get(config.endpoint + `nfe/${id}`, {
      params: { includeSalesItems },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const search = async (params) => {
  try {
    const response = await api.post(config.endpoint + `nfe/search`, params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const loadNFe = async (NFeId) => {
  try {
    const response = await api.get(config.endpoint + `nfe/load/by-id/${NFeId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const cancel = async (params) => {
  try {
    const response = await api.post(config.endpoint + 'nfe/cancel', params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const consult = async (NFeId) => {
  try {
    const response = await api.post(config.endpoint + 'nfe/consult', {
      NFeId: NFeId,
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

const consultContingency = async (NFeId) => {
  try {
    const response = await api.post(
      config.endpoint + 'nfe/consult-contingency',
      {
        NFeId: NFeId,
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteNFe = async (id) => {
  try {
    const response = await api.delete(config.endpoint + `nfe/delete/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const disabledNFe = async (params) => {
  try {
    const response = await api.post(config.endpoint + 'nfe/disable', params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const correctionNfe = async (params) => {
  try {
    const response = await api.post(config.endpoint + 'nfe/correction', params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const updateNfeItems = async (nfeId, params) => {
  try {
    const response = await api.post(
      config.endpoint + `nfe/update-items?id=${nfeId}`,
      params
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBySale = async (saleId) => {
  const response = await api.post(config.endpoint + 'nfe/generate-by-sale', {
    saleId,
  });
  return response.data;
};

const validateStructure = (id, type) =>
  api.get(config.endpoint + `nfe/validate/structure`, {
    params: { id, type },
  });

export default {
  show,
  create,
  emit,
  update,
  getAllByCompany,
  loadNFe,
  search,
  cancel,
  consult,
  consultContingency,
  deleteNFe,
  disabledNFe,
  correctionNfe,
  updateNfeItems,
  generateBySale,
  validateStructure,
};
