import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router'

import Button from '../../../../components/CustomButton/Button'
import CustomButton from '../../../../components/CustomButton/CustomButton'

import { useAuth } from 'contexts/auth'

import './styles.css'

import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { format, subDays } from 'date-fns'

import salesRepository from '../../../../../repositories/Sales'
import useFilters from '../../../../../hooks/useFilters'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const BudgetModal = ({ show, onCancel }) => {
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  const [budgetsServices, setBudgetsServices] = useState([])

  const dispatch = useDispatch()

  const { queryFilter, typeFilter, dateFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter
  const { type, setType, filterByType } = typeFilter
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter

  useEffect(() => {
    const before15Days = subDays(new Date(), 15)
    const initialDate = format(new Date(before15Days), 'yyyy-MM-dd')
    const finalDate = format(new Date(), 'yyyy-MM-dd')
    setInitialDate(initialDate)
    setFinalDate(finalDate)
    if (companyId) loadBudgetsServices(initialDate, finalDate)
  }, [companyId])

  const loadBudgetsServices = async (initialDate, finalDate) => {
    setLoading(true)
    try {
      const { data } = await salesRepository.getBudgetsAndServiceOrders(
        companyId,
        initialDate,
        finalDate
      )
      setBudgetsServices(data)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar os orçamentos e serviços. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleFilters = (budgetsServices) => {
    const searchQuery = [
      budgetsServices['Code'],
      budgetsServices['License_Plate'],
      budgetsServices['Customer']?.['Company_Name'],
    ]
    return filterByQuery(searchQuery)
  }

  const onInclude = (sale) => {
    dispatch([
      change('formNewScheduling', 'number', sale.Code),
      change('formNewScheduling', 'saleId', sale.id),
      change('formNewScheduling', 'customer', sale.Customer.Company_Name),
      change('formNewScheduling', 'licensePlate', sale.License_Plate),
      change('formNewScheduling', 'vehicle', sale.Vehicle.Model),
      change('formNewScheduling', 'vehicleId', sale.Vehicle.id),
      change('formNewScheduling', 'saleId', sale.id),
      change('formNewScheduling', 'customerId', sale.Customer.id),
    ])
    onCancel()
  }

  return (
    <>
      <Modal
        show={show}
        dialogClassName="consult-prices-modal-wrapper"
        onHide={onCancel}
        animation
      >
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <strong>Listagem de Orçamentos e Ordens de Serviço</strong>

              <span
                style={{
                  color: 'red',
                  fontSize: 14,
                  alignSelf: 'flex-end',
                }}
              >
                Apenas Orçamentos e OS em aberto poderão ser agendadas
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100vw',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '20px',
              }}
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px' }}
                className="form-control foco-input"
                placeholder="Pesquisar por Nº Orçamento/OS, Cliente ou Placa"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div
                style={{
                  flexDirection: 'column',
                  marginLeft: '10px',
                  justifyContent: 'space-between',
                  marginTop: '-40px',
                  width: '400px',
                }}
              >
                <label>
                  <strong>Data de Criação Inicial:</strong>
                </label>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="initialDate"
                  value={initialDate}
                  max={
                    finalDate
                      ? format(
                          new Date(getDateOnlyFromDate(finalDate)),
                          'yyyy-MM-dd'
                        )
                      : format(new Date(), 'yyyy-MM-dd')
                  }
                  onChange={(e) => setInitialDate(e.target.value)}
                />
              </div>
              <div
                style={{
                  flexDirection: 'column',
                  marginLeft: '10px',
                  marginTop: '-40px',
                  width: '400px',
                }}
              >
                <label>
                  <strong>Data de Criação Final:</strong>
                </label>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="finalDate"
                  value={finalDate}
                  min={
                    initialDate
                      ? format(
                          new Date(getDateOnlyFromDate(initialDate)),
                          'yyyy-MM-dd'
                        )
                      : undefined
                  }
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </div>
              <CustomButton
                bsStyle="success"
                fill
                style={{
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '10px',
                  marginTop: '-1.5px',
                  backgroundColor: '#62c462',
                  border: 'none',
                  marginRight: '20px',
                  width: '100px',
                }}
                onClick={() => loadBudgetsServices(initialDate, finalDate)}
              >
                Processar
              </CustomButton>
            </div>

            <div>
              <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                data={budgetsServices.filter(handleFilters)}
                columns={[
                  {
                    Header: 'Data',
                    accessor: 'Date',
                    width: 150,
                    headerClassName: 'text-left',
                    Cell: (props) =>
                      !!props.value
                        ? format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')
                        : '',
                  },
                  {
                    Header: 'Nº Orçamento/OS',
                    accessor: 'Code',
                    width: 150,
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Cliente',
                    accessor: 'Customer.Company_Name',
                    headerClassName: 'text-left',
                    width: 300,
                  },
                  {
                    Header: 'Placa',
                    accessor: 'License_Plate',
                    width: 150,
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Valor',
                    accessor: 'Final_Value',
                    width: 150,
                    headerClassName: 'text-left',
                    Cell: (props) => <a>{currency(props.value)}</a>,
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    headerClassName: 'text-left',
                    width: 100,
                    filterable: false,
                    className: 'texto',
                    Cell: (props) => (
                      <>
                        <FontAwesomeIcon
                          title="Adicionar"
                          cursor="pointer"
                          icon={faPlusCircle}
                          style={{
                            width: '1.5em',
                            height: '1.5em',
                            color: '#62c462',
                          }}
                          onClick={() => onInclude(props.original)}
                        />
                      </>
                    ),
                  },
                ]}
                defaultPageSize={10}
                className=""
                loading={loading}
                showPagination={true}
                sortable={false}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'date',
                    desc: true,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Não há informação"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            bsStyle="danger"
            pullRight
            fill
            onClick={onCancel}
            style={{
              backgroundColor: '#d9534f',
              border: 'none',
              marginRight: '20px',
              width: '100px',
            }}
          >
            Voltar
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default withRouter(BudgetModal)
