import React from 'react';
import { Container } from './TimeLeft.styles';
import { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';

export function TimeLeft({ endTime, onEnd, hideOnEnd }) {
  const theme = useTheme();

  const [isFinished, setIsFinished] = useState(false);

  const calculateTimeLeft = () => {
    if (isFinished) return;

    const difference = +new Date(endTime) - +new Date();
    let timeLeft = {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };

    if (difference > 0) {
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24) || 0;
      const minutes = Math.floor((difference / 1000 / 60) % 60) || 0;
      const seconds = Math.floor((difference / 1000) % 60) || 0;

      timeLeft = {
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      };

      return timeLeft;
    }

    if (onEnd) onEnd();
    setIsFinished(true);
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (isFinished && hideOnEnd) return <div />;

  return (
    <Container>
      <strong>{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</strong>{' '}
      restante{' '}
      <FontAwesomeIcon icon={faClock} color={theme.colors.branding1000} />
    </Container>
  );
}
