import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { phoneMask } from '../../components/ToNormalize/ToNormalize';

import usersRepository from '../../../repositories/Users';
import companiesRepository from '../../../repositories/Companies';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import constants from '../../../utils/constants';
import SearchInput from 'components/SearchInput';

const Users = ({ history }) => {
  const { companyId, userId } = useAuth();
  const { signature } = usePlanSignatureContext();

  const [users, setUsers] = useState([]);
  const [signatureUserQuantity, setSignatureUserQuantity] = useState(0);
  const [activeUsersQuantity, setActiveUsersQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (!companyId) {
      loadUser();
    } else {
      loadUsers();
    }
  }, []);

  useEffect(() => {
    setSignatureUserQuantity(signature?.usersQuantity);
    setActiveUsersQuantity(users.filter((user) => !!user.IsActive).length);
  }, [users]);

  const loadUser = async () => {
    setLoading(true);
    try {
      const user = await usersRepository.getById(userId);
      setUsers([user]);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os usuários. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    setLoading(true);
    try {
      const users = await companiesRepository.getAllUsers(companyId);
      setUsers(users);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os usuários. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (row) => {
    const user = row.original;

    if (userId === String(user.id)) {
      return toastr.warning('Usuário em uso');
    }

    if (activeUsersQuantity === 1 && user.IsActive) {
      return toastr.warning('É obrigatório ao menos 1 usuário ativo.');
    }

    if (!user.IsActive && activeUsersQuantity >= signatureUserQuantity) {
      return toastr.warning('Quantidade total de usuários ativos atingida.');
    }

    setLoading(true);
    try {
      await usersRepository.update(user.id, {
        IsActive: !user.IsActive,
      });
      loadUsers();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar o usuário. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (user) => {
    const searchQuery = [user['Name'], user['Email']];
    return filterByQuery(searchQuery);
  };

  return (
    <Card
      content={
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            padding: '15px',
          }}
        >
          <ModalAlertCompany
            show={noCompanyModal}
            onHide={() => setNoCompanyModal(false)}
          />
          <div
            id="cbTitulo"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <span id="cbTituloTagP" style={{ marginLeft: '10px' }}>
              Meus Usuários
            </span>
          </div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <button
              className="btn btn-sucesso button-h35"
              onClick={() =>
                !companyId
                  ? setNoCompanyModal(true)
                  : history.push(constants.ROUTES.USER)
              }
              disabled={activeUsersQuantity >= signatureUserQuantity || loading}
              style={{ fontSize: '12px' }}
            >
              + Novo Usuário
            </button>
            <div>
              <SearchInput
                placeholder="Pesquisa por Nome ou E-Mail"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={users.filter(handleFilters)}
              columns={[
                {
                  Header: 'Nome',
                  accessor: 'Name',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'E-Mail',
                  accessor: 'Email',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Telefone',
                  accessor: 'Phone',
                  headerClassName: 'text-center',
                  width: 140,
                  filterable: false,
                  Cell: (props) => phoneMask(props.value),
                },
                {
                  Header: 'Status',
                  accessor: 'IsActive',
                  headerClassName: 'text-left',
                  width: 90,
                  Cell: (props) => (
                    <div>
                      <Toggle
                        checked={!!props.original.IsActive}
                        name="ativo"
                        style={{ position: 'fixed' }}
                        value=""
                        onChange={() => handleChange(props)}
                      />
                    </div>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 100,
                  filterable: false,
                  className: 'texto',
                  Cell: (props) => (
                    <Link to={{ pathname: `user#${props.value}` }}>
                      <FontAwesomeIcon
                        cursor="pointer"
                        icon={faEdit}
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                    </Link>
                  ),
                },
              ]}
              defaultPageSize={5}
              className=""
              loading={loading}
              showPagination={true}
              sortable={false}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Name',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(Users);
