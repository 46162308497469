import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import AlertModal from 'components/AlertModal/AlertModal'

function SavingModal({ onCancel, handleSubmit }) {
  const [
    isSaveFinishedStatusConfirmationModalOpen,
    setIsSaveFinishedStatusConfirmationModalOpen,
  ] = useState(false)
  const [
    isSaveOpenStatusConfirmationModalOpen,
    setIsSaveOpenStatusConfirmationModalOpen,
  ] = useState(false)

  return (
    <div>
      <Modal show animation onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Selecione a opção desejada:</strong>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                width: '232px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Button
                bsStyle="info"
                style={{
                  width: '232px',
                }}
                fill
                onClick={() =>
                  setIsSaveFinishedStatusConfirmationModalOpen(true)
                }
              >
                Finalizar e movimentar estoque
              </Button>
              <Button
                bsStyle="primary"
                style={{
                  width: '232px',
                }}
                fill
                onClick={() => setIsSaveOpenStatusConfirmationModalOpen(true)}
              >
                Salvar inventário em aberto
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" fill onClick={onCancel}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="OS Digital"
        subtitle="Você tem certeza que deseja finalizar essa contagem/inventário?"
        show={isSaveFinishedStatusConfirmationModalOpen}
        onHide={() => setIsSaveFinishedStatusConfirmationModalOpen(false)}
        onCancel={() => setIsSaveFinishedStatusConfirmationModalOpen(false)}
        message="O processo é irreversível. Ao finalizar, todas as peças lançadas terão seu estoque ajustado através de movimentações que não poderão ser canceladas."
        onSubmit={() => handleSubmit('FINISHED')}
      />

      <AlertModal
        title="OS Digital"
        subtitle="Você tem certeza que deseja salvar esse inventário/contagem em aberto?"
        show={isSaveOpenStatusConfirmationModalOpen}
        onHide={() => setIsSaveOpenStatusConfirmationModalOpen(false)}
        onCancel={() => setIsSaveOpenStatusConfirmationModalOpen(false)}
        message="Inventários/contagens em aberto permitem adições de mais peças, porém não movimentará estoque até que seja finalizada."
        onSubmit={() => handleSubmit('OPENED')}
      />
    </div>
  )
}

export default SavingModal
