import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../../../components/CustomButton/CustomButton.jsx'
import CardForm from '../../../../components/CardForm'
import SettingsNFSeErrorsTable from './SettingsNFSeErrorsTable'
import ClientErrorsTable from './ClientErrorsTable'

const ErrorsModal = ({ onCancel, onRetry, errors, setErrors }) => {
  const settingsNFSeErrors = errors.filter(error => error.card === 'Configurações Fiscais')
  const clientErrors = errors.filter(error => error.card === 'Cliente')
  const isAnyErrorCorrected = errors.some(error => error.isResolved)

  useEffect(() => {
    const isAllErrorsCorrected = errors.every(error => error.isResolved)
    if(isAllErrorsCorrected) {
      onRetry()
    }
  }, [errors])

  function handleChangeClientErrors(clientErrors) {
    const newErrors = [...settingsNFSeErrors, ...clientErrors]
    setErrors(newErrors)
  }

  function handleChangeSettingsNFSeErrors(settingsNFSeErrors) {
    const newErrors = [...clientErrors, ...settingsNFSeErrors]
    setErrors(newErrors)
  }

  return (
    <>
      <Modal show animation dialogClassName='NFSe-errors-modal-wrapper'>
        <Modal.Header>
          <Modal.Title>
            <strong>Validação de Estrutura</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='NFSe-errors-modal-body'>
          <span>Identificamos {errors.length} {errors.length === 1 ? 'erro' : 'erros'} cadastrais que impedem o envio da nota fiscal. Realize as correções dos itens abaixo: </span>
          {
            !!settingsNFSeErrors.length && (
              <CardForm show title="Configurações Fiscais">
                <SettingsNFSeErrorsTable errors={settingsNFSeErrors} onCorrection={handleChangeSettingsNFSeErrors}/>
              </CardForm>
            )
          }
          {
            !!clientErrors.length && (
              <CardForm show title="Cliente">
                <ClientErrorsTable errors={clientErrors} onCorrection={handleChangeClientErrors} />
              </CardForm>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <div className='NFSe-errors-modal-footer'>
            <Button bsStyle="danger" fill onClick={onCancel}>Voltar</Button>
            {
              isAnyErrorCorrected && (
                <Button bsStyle="info" fill onClick={onRetry}>
                  Reenviar NFS-e
                </Button>
              )
            }
          </div>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ErrorsModal