import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import Toggle from 'react-toggle';

import CollapsibleSection from 'components/CollapsibleSection';
import Button from 'client/components/CustomButton/CustomButton';

import NFSePatternRepository from 'repositories/NFSePatterns';
import AlertModal from 'components/AlertModal/AlertModal';

const NFSePatternMain = ({ goToHomePage, patternId }) => {
  const [loading, setLoading] = useState(false);

  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);

  const [previousAllowCancel, setPreviousAllowCancel] = useState(null);

  const [
    isAllowCancelChangedModalConfirmationOpen,
    setIsAllowCancelChangedModalConfirmationOpen,
  ] = useState(false);

  const {
    register,
    control,
    formState: { isDirty },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      pattern: '',
      offline: false,
      isHomologated: false,
      allowCancel: '',
      internalUrl: '',
      externalUrl: null,
      homologationDate: null,
      concatAdditionalInformation: false,
    },
  });

  const isHomologated = useWatch({
    control,
    name: 'isHomologated',
  });

  const offline = useWatch({
    control,
    name: 'offline',
  });

  const allowCancel = useWatch({
    control,
    name: 'allowCancel',
  });

  const concatAdditionalInformation = useWatch({
    control,
    name: 'concatAdditionalInformation',
  });

  async function loadPattern() {
    setLoading(true);
    try {
      const { data } = await NFSePatternRepository.show(patternId);

      setValue('pattern', data.pattern);
      setValue('offline', data.offline);
      setValue('isHomologated', data.isHomologated);
      setValue('homologationDate', data.homologationDate);
      setValue('externalUrl', data.externalUrl);
      setValue('internalUrl', data.internalUrl);
      setValue('allowCancel', data.allowCancel);
      setValue('concatAdditionalInformation', data.concatAdditionalInformation);

      setPreviousAllowCancel(data.allowCancel);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar o padrão. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  async function validatePatternNameUse(name) {
    try {
      const { patterns } = await NFSePatternRepository.index({
        query: name,
        page: 1,
      });

      const patternWithExactlySameName = patterns?.find(
        (pattern) => pattern.pattern === name
      );
      if (
        patternWithExactlySameName &&
        patternWithExactlySameName.id != patternId
      ) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Não foi possível validar a utilização do nome do padrão. Tente novamente!'
      );
    }
  }

  async function onSubmit(data) {
    setLoading(true);

    if (!data.pattern) {
      setLoading(false);
      return toastr.warning(
        'Não foi possível salvar o padrão',
        'Insira um nome válido e tente novamente'
      );
    }

    if (!(await validatePatternNameUse(data.pattern))) {
      setLoading(false);

      return toastr.warning(
        'Não foi possível salvar o padrão',
        'Já existe um padrão com esse nome. Verifique os padrões cadastrados e tente novamente.'
      );
    }

    try {
      const formattedData = {
        pattern: data.pattern,
        offline: data.offline === 'true' ? true : false,
        isHomologated: data.isHomologated,
        homologationDate: data.isHomologated ? data.homologationDate : null,
        allowCancel: data.allowCancel,
        externalUrl: data.externalUrl?.trim(),
        internalUrl: data.internalUrl?.trim(),
        concatAdditionalInformation:
          data.concatAdditionalInformation === 'true' ? true : false,
      };

      if (!patternId) {
        await NFSePatternRepository.create(formattedData);
      } else {
        await NFSePatternRepository.update(patternId, formattedData);
      }

      toastr.success(
        `Padrão ${patternId ? 'salvo' : 'criado'} com sucesso`,
        'O padrão foi salvo e pode ser usado em homologação'
      );

      if (patternId && previousAllowCancel !== allowCancel) {
        setIsAllowCancelChangedModalConfirmationOpen(true);
      } else {
        goToHomePage();
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao efetuar a operação. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleChangeAllowCancelForAllCities() {
    try {
      goToHomePage();
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        "Ocorreu um erro ao atualizar a propridade 'Permite Cancelamento' para as cidades vinculadas ao padrão. Tente novamente!"
      );
    }
  }

  function getCancelWarning() {
    if (patternId) {
      return (
        <>
          <span>Você tem certeza que deseja cancelar a edição do padrão?</span>
          <br />
          <br />
          <span>
            Ao cancelar a edição do padrão, as alterações realizadas não serão
            aplicadas, sendo necessário realizar posteriormente.
          </span>
        </>
      );
    } else {
      return (
        <>
          <span>
            Você tem certeza que deseja cancelar o cadastro do padrão?
          </span>
          <br />
          <br />
          <span>
            Ao cancelar o cadastro do padrão, não será possível vincular
            qualquer cidade a ele e nem utilziar para emissão de nota.
          </span>
        </>
      );
    }
  }

  useEffect(() => {
    if (patternId) {
      loadPattern();
    }
  }, [patternId]);

  useEffect(() => {
    if (offline) {
      setValue('isHomologated', false);
      setValue('homologationDate', null);
    }
  }, [offline]);

  useEffect(() => {
    if (!isHomologated) {
      setValue('homologationDate', null);
    }
  }, [isHomologated]);

  return (
    <div className="pattern-form__wraper">
      <CollapsibleSection
        title="Dados do Padrão"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div className="pattern-form__row-1">
          <div>
            <label htmlFor="pattern">
              Nome do Padrão:
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="pattern"
              className="form-control foco-input"
              {...register('pattern')}
            />
          </div>
          <div>
            <label htmlFor="pattern">
              Tipo:
              <span style={{ color: 'red' }}>*</span>
            </label>
            <select
              type="text"
              id="offline"
              className="form-control foco-input"
              {...register('offline')}
            >
              <option value={false}>Online</option>
              <option value={true}>Offline</option>
            </select>
          </div>
          <div>
            <label htmlFor="homologationDate">Data de Homologação:</label>
            <input
              type="date"
              id="homologationDate"
              disabled={offline === 'true' || !isHomologated ? true : false}
              className="form-control foco-input"
              {...register('homologationDate')}
            />
          </div>
          <div className="toggle__wrapper">
            <label htmlFor="isActive">Padrão homologado?</label>
            <Toggle
              id="isHomologated"
              style={{ position: 'fixed' }}
              checked={isHomologated}
              disabled={offline === 'true' ? true : false}
              onChange={() => setValue('isHomologated', !isHomologated)}
            />
          </div>
          <div className="toggle__wrapper">
            <label htmlFor="allowCancel">Permite Cancelamento de NFS</label>
            <Toggle
              id="allowCancel"
              style={{ position: 'fixed' }}
              checked={allowCancel}
              onChange={() => setValue('allowCancel', !allowCancel)}
            />
          </div>
        </div>
        <div>
          <label htmlFor="pattern">Tipo de Texto Adicional:</label>
          <select
            type="text"
            id="concatAdditionalInformation"
            className="form-control foco-input"
            {...register('concatAdditionalInformation')}
            style={{ width: '320px' }}
          >
            <option value={false}>Campo Próprio</option>
            <option value={true}>Concatenado</option>
          </select>
        </div>
        <div className="pattern-form__row-2">
          <div>
            <label htmlFor="externalUrl">
              Link para documentação Migrate:
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="externalUrl"
              className="form-control foco-input"
              {...register('externalUrl')}
            />
          </div>
          <div>
            <label htmlFor="internalUrl">
              Link para documentação interna:
              <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="internalUrl"
              className="form-control foco-input"
              {...register('internalUrl')}
            />
          </div>
        </div>
      </CollapsibleSection>
      <div className="pattern-form__action-buttons">
        <Button
          bsStyle="danger"
          disabled={loading}
          onClick={() =>
            isDirty ? setIsCancelConfirmationModalOpen(true) : goToHomePage()
          }
          fill
        >
          Cancelar
        </Button>

        <Button
          bsStyle="info"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          fill
        >
          Salvar
        </Button>
      </div>

      {isCancelConfirmationModalOpen && (
        <AlertModal
          show={isCancelConfirmationModalOpen}
          title="OS Digital"
          message={getCancelWarning()}
          onHide={() => setIsCancelConfirmationModalOpen(false)}
          onCancel={() => setIsCancelConfirmationModalOpen(false)}
          onSubmit={() => goToHomePage()}
        />
      )}

      {isAllowCancelChangedModalConfirmationOpen && (
        <AlertModal
          show={isAllowCancelChangedModalConfirmationOpen}
          title="OS Digital"
          message={
            <>
              <span>
                Você deseja replicar o status de emissão de cancelamento para
                todas as cidades do padrão?
              </span>
              <br />
              <br />
              <span>
                <strong>Importante:</strong>&nbsp; Em maioria, as cidades
                respeitam a decisão do padrão, porém, poderá haver alguma cidade
                que não respeita, sendo necessário habilitar a função no
                cadastro da cidade.
              </span>
            </>
          }
          onHide={() => false}
          onCancel={() => goToHomePage()}
          onSubmit={() => handleChangeAllowCancelForAllCities()}
        />
      )}
    </div>
  );
};

export default NFSePatternMain;
