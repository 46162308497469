import api from '../services/api'
import config from '../config'

const create = async values => {
  const response = await api.post(config.endpoint + 'settings-nfe', values)
  return response.data
}

const update = async (id, values) => {
  await api.put(config.endpoint + `settings-nfe/${id}`, values)
}

const search = async (companyId) => {
  return await api.post(config.endpoint + `settings-nfe/search`, companyId)
}

const settingsNFeRepository = {
  create,
  update,
  search
}

export default settingsNFeRepository