import React from 'react'
import { reduxForm } from 'redux-form'

import CardForm from '../../../../client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import ServiceData from './ServiceData'

import './styles.css'

const FormService = ({ handleSubmit, onCancel, loading, serviceId }) => {

  return (
    <form onSubmit={handleSubmit} onCancel={onCancel} style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }} >
      <CardForm title='Dados do Serviço'>
        <ServiceData  serviceId={serviceId}/>
      </CardForm>

      <FormSubmitButtons loading={loading} onCancel={onCancel} hasId={!!serviceId} onSubmit={handleSubmit} />
    </form>
  )
}

export default reduxForm({
  form: 'service',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(FormService)