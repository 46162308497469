
import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import brandsRepository from '../../../../../../repositories/Brands'
import familiesRepository from '../../../../../../repositories/Families'
import productsRepository from '../../../../../../repositories/Products'
import './styles.css'
import ReactTable from 'react-table';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const InventoryMovementListingMain = () => {
  const [loading, setLoading] = useState()

  const [totalPages, setTotalPages] = useState(1)
  const [totalProducts, setTotalProducts] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageLimit, setPageLimit] = useState(10)

  const [products, setProducts] = useState([])
  const [product_type, setProductType] = useState('Produto Final')
  const [brands, setBrands] = useState([])
  const [brandId, setBrandId] = useState(null)
  const [families, setFamilies] = useState([])
  const [familyId, setFamilyId] = useState([])
  const [searchByType, setSearchByType] = useState('Família')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [locationOfProduct, setLocationOfProduct] = useState()


  const { companyId } = useAuth()

  useEffect(() => {
    loadBrands()
    loadFamilies()

    if (products?.length > 0) setProducts([])

  }, [searchByType])

  useEffect(() => {
    if (isSubmitted) {
      loadProducts()
    }
  }, [
    currentPage,
    pageLimit,
  ])


  const searchByTypevalidations = () => {

    if (!locationOfProduct || locationOfProduct === '') {
      toastr.warning('Atenção',
        ' Insira uma localização para fazer a busca do produto')
      return false
    }

    return true
  }

  const loadProducts = async () => {


    setLoading(true)
    try {

      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        brandId: searchByType === 'Família' ? brandId : '',
        familyId: searchByType === 'Família' ? familyId : '',
        product_type: searchByType === 'Família' ? product_type : '',
        locationOfProduct: searchByType === 'Família' ? '' : locationOfProduct,
      }

      const paginatedProducts = await productsRepository.getProductListingLocation(companyId, params)

      const products = paginatedProducts.data.map((product) => {
        let item = {}
        item = product
        item.DescriptionProduct = product.Description
        item.DescriptionFamily = product.Families?.Description
        item.DescriptionBrand = product.Brands?.Description
        item.Sale_Price = currency(product.Sale_Price)

        return item
      })

      setProducts(products)
      setTotalPages(Math.ceil(paginatedProducts.count / pageLimit))
      setTotalProducts(paginatedProducts.count)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível gerar o relatório.',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const loadBrands = async () => {
    setLoading(true)
    try {
      const brands = await brandsRepository.getAllByCompany(companyId)
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        Description: brand.Description,
      }))

      setBrands(serializedBrands)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      )
    }
  }

  async function loadFamilies() {
    setLoading(true)
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        )
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        Description: family.Description,
      }))

      setFamilies(serializedFamilies)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='content-listing-location'>
      <section >
        <div style={{ width: '200px' }}>
          <label>Pesquisar por:</label>
          <select
            value={searchByType}
            onChange={(e) => setSearchByType(e.target.value)}
            className="form-control foco-input">
            <option value="Família">Família</option>
            <option value="Localizacao">Localização</option>
          </select>
        </div>

        {searchByType === 'Família' ?
          <div className='teste-data-teste'>
            <div style={{ width: '200px' }}>
              <label>Tipo de Produto:</label>
              <select
                className="form-control foco-input"
                value={product_type}
                onChange={(e) => setProductType(e.target.value)}
              >
                <option value="Produto Final" selected>Produto Final</option>
                <option value="Uso e Consumo">Uso e Consumo</option>
                <option value="Matéria Prima">Matéria Prima</option>
              </select>
            </div>
            <div style={{ width: '200px' }}>
              <label>Família:</label>
              <select
                value={familyId}
                onChange={(e) => setFamilyId(e.target.value)}
                className="form-control foco-input">
                <option value="">Todos</option>
                {families.map((family) => (
                  <option key={family.id} value={family.id}>
                    {family.Description}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: '200px' }}>
              <label>Marcas:</label>
              <select
                value={brandId}
                onChange={(e) => setBrandId(e.target.value)}
                className="form-control foco-input"
              >
                <option value="">Todos</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.Description}
                  </option>
                ))}
              </select>
            </div>
            <div className='listing-location-button' style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
              <button className="btn btn-sucesso" onClick={() => {
                setIsSubmitted(true)
                loadProducts()
              }
              }>
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />
                Processar
              </button>
            </div>

          </div> :
          <div className='teste-data-teste'>
            <div style={{ width: '100%', marginTop: 20, marginBottom: 10, display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '500px' }} id='review-search'>
                <i id='iconepesquisar' className='fa fa-search'></i>
                <input
                  type='text'
                  style={{ paddingLeft: '30px', width: '100%' }}
                  className='form-control foco-input'
                  placeholder='Pesquisa por Localização do Produto '
                  value={locationOfProduct}
                  onChange={(e) => setLocationOfProduct(e.target.value)}
                />
              </div>
              <div className='listing-location-button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className="btn btn-sucesso" onClick={() => {
                  setIsSubmitted(true)
                  if (searchByTypevalidations()) loadProducts()
                }
                }>
                  <span
                    className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                  />
                  Processar
                </button>
              </div>
            </div>
          </div>}

      </section>
      <div style={{ width: '100%', marginTop: 10 }}>
        <ReactTable
          style={{ fontWeight: 'bold', textAlign: 'center' }}
          data={products}
          columns={[
            {
              Header: 'Código',
              accessor: 'Code',
              width: 120,
            },
            {
              Header: 'Ref.Fabricante',
              accessor: 'Manufacturer_Ref',
              width: 180,
            },
            {
              Header: 'Descrição',
              accessor: 'DescriptionProduct',
            },
            {
              Header: 'Familia',
              accessor: 'DescriptionFamily',
              width: 180,
            },
            {
              Header: 'Marca',
              accessor: 'DescriptionBrand',
              width: 120,
            },
            {
              Header: 'Estoque',
              accessor: 'Stock_Quantity',
              width: 100,
            },
            {
              Header: 'R$ Venda',
              accessor: 'Sale_Price',
              width: 180,
            },
            {
              Header: 'Localização',
              accessor: 'Location',
            },
          ]}
          defaultPageSize={10}
          pageSize={pageLimit}
          page={currentPage}
          onPageChange={setCurrentPage}
          manual
          onPageSizeChange={(value) => {
            setPageLimit(value)
          }}
          pages={totalPages}
          loading={loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'Company_Name',
              desc: false,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Selecione os filtros que deseja para fazer a pesquisa."
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    </div>
  )
}
export default InventoryMovementListingMain