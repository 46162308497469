import React, { useState } from 'react';

import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUser } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import MaintenanceStatus from 'client/views/WorkshopPanel/components/MaintenanceStatus';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { OperationModal } from '../OperationModal';
import { getStatusIndicatorColor } from '../../utils';

const EmployeeTableCard = ({ name }) => {
  const splitedName = name.split(' ');

  return (
    <div className="employee-table-card">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{`${splitedName[0]}`}</span>
    </div>
  );
};

const MaintenanceOperationModalButton = ({ maintenance }) => {
  const [isOperationModalOpen, setIsOperationModalOpen] = useState(false);
  return (
    <>
      <button
        className="service-order-code"
        onClick={() => setIsOperationModalOpen(true)}
      >
        {maintenance?.Sales?.Code || maintenance?.saleCode}
      </button>

      {isOperationModalOpen && (
        <OperationModal
          maintenance={maintenance}
          onCancel={() => setIsOperationModalOpen(false)}
        />
      )}
    </>
  );
};

const MaintenanceTableRow = ({ maintenance }) => {
  return (
    <tr>
      <td style={{ width: '5%' }}>
        <MaintenanceOperationModalButton maintenance={maintenance} />
      </td>
      <td style={{ width: '20%', textAlign: 'left' }}>
        <p>{maintenance.customer.Company_Name}</p>
        <p>{maintenance.vehicle}</p>
      </td>
      <td style={{ width: '20%', textAlign: 'center' }}>
        <div className="flex column gap-050">
          {maintenance.services?.map((item) => (
            <p
              key={item.id}
              style={{ textAlign: 'left' }}
              className="flex gap-075 align-center"
            >
              <span
                title={item.status}
                style={{
                  textAlign: 'center',
                  display: 'block',
                  fontSize: '11px',
                  width: '14px',
                  height: '14px',
                  borderRadius: '3px',
                  flexShrink: 0,
                  color: '#fff',
                  boxSizing: 'border-box',
                  boxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                  WebkitBoxShadow: '0px 1px 19px 1px rgba(0, 0, 0, 0.2)',
                  backgroundColor: getStatusIndicatorColor(item.shortStatus),
                }}
              >
                {item.shortStatus}
              </span>{' '}
              {item.description}
            </p>
          ))}
        </div>
      </td>
      <td style={{ width: '25%' }}>
        <div className="flex column">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '10px',
              marginBottom: '15px',
            }}
          >
            {maintenance?.employees.map((item) => (
              <div className="flex column gap-050">
                <EmployeeTableCard key={item.id} name={item?.name} />
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  {item?.services?.map((item) => (
                    <li
                      style={{ textAlign: 'left', fontSize: '10px' }}
                      key={item.description}
                    >
                      {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </td>
      <td style={{ width: '10%' }}>
        <p style={{ fontSize: '11px', textAlign: 'center' }}>
          {maintenance.startDate &&
            format(new Date(maintenance.startDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </td>
      <td style={{ width: '20%', textAlign: 'center' }}>
        <MaintenanceStatus
          status={maintenance.status}
          elapsedTime={maintenance.elapsedTime}
        />
        <br />
      </td>
    </tr>
  );
};

export function MaintenanceTable() {
  const {
    maintenances,
    isLoadingMaintenances,
    isLoadingPanelInformation,
    lastRefresh,
  } = useWorkshopPanel();

  return (
    <div className="workshop-panel-frame">
      <p className="workshop-panel-frame-title">
        <FontAwesomeIcon icon={faScrewdriverWrench} size={'lg'} />
        &nbsp; Manutenções
      </p>
      <div className="workshop-panel-frame-content">
        <div className="workshop-panel-maintenances">
          {isLoadingMaintenances || isLoadingPanelInformation ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>OS</th>
                  <th style={{ width: '20%' }}>Cliente</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>
                    Serviços
                  </th>
                  <th style={{ width: '25%', textAlign: 'center' }}>
                    Mecânicos / Reparos
                  </th>
                  <th style={{ width: '10%', textAlign: 'center' }}>Início</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {maintenances?.map((maintenance) => (
                  <MaintenanceTableRow
                    key={maintenance.maintenanceId}
                    maintenance={maintenance}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="workshop-panel-last-update">
        Atualizado em {format(new Date(lastRefresh), 'dd/MM/yyyy')} -{' '}
        {format(new Date(lastRefresh), 'HH:mm')}
      </div>
    </div>
  );
}
