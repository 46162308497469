import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../../../../client/components/CustomButton/CustomButton'

const ConfirmModal = ({
  onCancel,
  onSubmit,
  cars,
  carsInsert,
  motos,
  motosInsert,
  heavys,
  heavyInsert,
  totalProducts,
  clientProducts,
}) => {
  return (
    <div>
      <Modal show animation>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS DIGITAL</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>
            Você tem certeza que deseja inserir a base de produto no cliente? O
            processo é irreversível
          </strong>
          <div style={{ margin: '10px 0 10px 0' }}>
            <p>Será inserido:</p>
          </div>
          {!!carsInsert && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input checked readOnly type='checkbox' value={carsInsert} />
              <p style={{ padding: '13px 0 0 5px' }}>
                Base de Carros ({cars} peças)
              </p>
            </div>
          )}
          {!!motosInsert && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type='checkbox' readOnly checked value={motosInsert} />{' '}
              <p style={{ padding: '13px 0 0 5px' }}>
                Base de Motos: ({motos} peças)
              </p>
            </div>
          )}
          {!!heavyInsert && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type='checkbox' checked readOnly value={heavyInsert} />
              <p style={{ padding: '13px 0 0 5px' }}>
                Base de Pesados-Caminhões ({heavys} peças)
              </p>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexFlow: 'column wrap',
              marginTop: '20px',
            }}
          >
            <p>
              <strong>Total de produtos cadastrados:</strong> {clientProducts}
            </p>
            <p>
              <strong>Total de produtos previstos:</strong> {totalProducts}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button bsStyle='danger' name='Calcelar' onClick={onCancel} fill>
              Não
            </Button>
            <Button bsStyle='info' name='Salvar' fill onClick={onSubmit}>
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ConfirmModal
