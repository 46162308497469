import api from '../services/api';

const getAll = async (params) => {
  const { data } = await api.get('polls', { params });
  return data;
};

const updateStatus = async (npsId, status) => {
  return await api.put(`polls/${npsId}/status`, {
    status,
  });
};

const getActivePoll = async (brandingId) => {
  try {
    const response = await api.get('polls/active', {
      params: {
        brandingId,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getById = async (npsId) => {
  try {
    const response = await api.get(`polls/single/${npsId}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const exportXlsById = async (npsId) => {
  try {
    const response = await api.get(`polls/export-xls/${npsId}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const create = (values) => api.post('polls', values);

const update = (npsId, values) => api.put(`polls/${npsId}`, values);

const PollsRepository = {
  getAll,
  updateStatus,
  getActivePoll,
  getById,
  exportXlsById,
  create,
  update,
};

export default PollsRepository;
