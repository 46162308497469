import CollapsibleSection from 'components/CollapsibleSection';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { InputTextArea } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { InputText } from 'v2/components/Input';
import { FormContainer } from './FormDefect.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './utils';
import { createCorrection } from 'v2/repositories/CorrectionsRepository';

export default function CreateNewCorrectionModal({
  handleClose,
  companyId,
  onInclude,
}) {
  const [loading, setLoading] = useState();

  const onSubmit = async (values) => {
    if (!values.description || !values.details) {
      toastr.warning(
        'Não foi possível cadastrar a correção!',
        'Preencha todos os campos obrigatórios para cadastrar a correção.'
      );
      return;
    }

    try {
      const newCorrection = await create(values);
      onInclude(newCorrection);
      handleClose();
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao cadastrar a correção.',
        'Por favor, tente novamente.'
      );
    }
  };

  const handleChangeDefectInput = (e) => {
    const value = e.target.value;

    if (value.length > 50) return;
    setValue('description', value);
  };

  const create = async (values) => {
    setLoading(true);

    try {
      const data = await createCorrection({
        description: values.description,
        details: values.details,
        status: true,
        companyId,
      });

      toastr.success('Correção cadastrada com sucesso!');
      return data;
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao cadastrar a correção.',
        'Por favor, tente novamente.'
      );
      throw err;
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      id: null,
      description: '',
      details: '',
      isActive: true,
    },
  });

  return (
    <Modal
      show={true}
      onHide={handleClose}
      dialogClassName="modal-60w backdrop='static'"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Nova Correção</strong>
          <span
            style={{
              color: 'red',
              position: 'absolute',
              right: 10,
              fontSize: '14px',
              marginTop: '15px',
            }}
          >
            * Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollapsibleSection
          title="Dados da Correção"
          isExpanded={true}
          handleExpand={() => null}
        >
          <form method="POST">
            <FormContainer>
              <div className="row-1">
                <InputContainer>
                  <InputLabel htmlFor="input__description" required>
                    Descrição:
                  </InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={50}
                    width="400"
                    style={{ fontSize: '12px' }}
                    {...register('description')}
                    onChange={handleChangeDefectInput}
                  />
                </InputContainer>
              </div>

              <div className="row-2">
                <InputContainer className="input__container">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <InputLabel htmlFor="input__observations" required>
                      Detalhes da Correção:
                    </InputLabel>
                    <span
                      style={{
                        color: '#D9534F',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      }}
                    >
                      Essa informação poderá ser exibida no impresso de
                      checklist-manual
                    </span>
                  </div>
                  <InputTextArea
                    id="input__observations"
                    rows={4}
                    maxLength={500}
                    height="70px"
                    style={{ fontSize: '12px' }}
                    {...register('details')}
                  />
                  <p className="flex end" style={{ fontSize: '12px' }}>
                    {watch('details')?.length}/500
                  </p>
                </InputContainer>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>

        <div
          style={{
            marginTop: '10px',
          }}
        >
          <FormSubmitButtons
            loading={loading}
            onCancel={handleClose}
            onSubmit={handleSubmit(onSubmit)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
