import React from 'react';

const CustomLabel = ({ text, backgroundColor = 'black', ...rest }) => (
  <button
    className="admin-custom-label"
    style={{
      backgroundColor,
    }}
    {...rest}
  >
    {text}
  </button>
)

export default CustomLabel
