import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import QuotesSupplierMain from './components/QuotesSupplierMain'

import './index.css'

export default function QuotesSupplier({ location }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Compras',
              'Listagem de Cotação por Fornecedor',
            ]}
            path={['home', null, null, null]}
          />
          <QuotesSupplierMain />
        </Row>
      </Grid>
    </div>
  )
}
