import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import CardForm from '../../../components/CardForm';
import MainData from './MainData';

const PurchaseConfigurationForm = ({ handleSubmit, pristine, submitting }) => {
  const [basicData, setBasicData] = useState(true);

  return (
    <CardForm
      show={basicData}
      title="Configurações de Compra"
      onClick={() => setBasicData(!basicData)}
    >
      <MainData />
      <button
        className="btn btn-sucesso save-btn"
        id="btn-save-sales-configuration"
        disabled={pristine || submitting}
        onClick={handleSubmit}
      >
        Salvar
      </button>
    </CardForm>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'purchaseConfiguration',
})(PurchaseConfigurationForm);
