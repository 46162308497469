import React, { useEffect, useState } from 'react';
import Button from 'client/components/CustomButton/CustomButton';
import CollapsibleSection from 'components/CollapsibleSection';
import { Modal } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import customersRepository from 'repositories/Customers';
import { useAuth } from 'contexts/auth';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import CurrencyInput from 'react-currency-input';
import customerCreditRepository from 'repositories/CustomerCredit';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import '../styles.css';
import SelectCustomers from 'v2/client/components/SelectCustomers';

const CustomerCreditModal = ({
  newCustomerCredit,
  customerCreditId,
  handleExit,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { companyId } = useAuth();

  const { register, setValue, handleSubmit, control } = useForm({
    defaultValues: {
      reason: 'Manual',
    },
  });

  const creditValue = useWatch({
    control,
    name: 'value',
  });

  const loadCreditInfo = async () => {
    setIsLoading(true);
    try {
      const response = await customerCreditRepository.show(customerCreditId);
      const customer = await customersRepository.getCustomer(
        response.customerId
      );

      const formattedCustomers = {
        value: customer.id,
        label: `${cpfOrCnpjMask(customer.Cpf_Cnpj)} - ${customer.Company_Name}`,
      };

      setSelectedCustomer(formattedCustomers);
      setValue('value', response.value);
      setValue('type', response.type);
      setValue('description', response.description);
      setValue('observations', response.observations);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar o crédito do cliente',
        'Por favor, tente novamente'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (customerCreditId) {
      loadCreditInfo();
    }
  }, [customerCreditId]);

  const handleCustomerChange = (item) => {
    setSelectedCustomer(item);
    setValue('customerId', item.value.customer_id);
  };

  const getCustomerAvailableCredit = async (customerId) => {
    try {
      const customer = await customersRepository.getCustomer(customerId);
      return customer.availableCredit;
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Não foi possível encontrar as informações do cliente. Tente novamente!'
      );

      return 0;
    }
  };

  const validateCustomerCredit = async (customerId, creditToRemove) => {
    try {
      const currentCustomerAvaliableCredit = await getCustomerAvailableCredit(
        customerId
      );

      const isNegativeDifference =
        Number(currentCustomerAvaliableCredit) - Number(creditToRemove) < 0;

      if (isNegativeDifference) {
        toastr.warning(
          'Processo não permitido',
          `Crédito do cliente ficará negativo após lançamento. Crédito atual do cliente ${currency(
            currentCustomerAvaliableCredit
          )}`
        );

        return false;
      }

      return true;
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Não foi possível validar o crédito do cliente. Tente novamente!'
      );
      return false;
    }
  };

  const validations = async (data) => {
    if (!data.type || !data.customerId || !data.value) {
      toastr.warning(
        'Não foi possível salvar o lançamento.',
        'Preencha todos os campos para fazer o lançamento'
      );
      return false;
    }

    const isDebitOperation = data.type === 'Saída';

    if (isDebitOperation) {
      const allowOperation = await validateCustomerCredit(
        data.customerId,
        data.value
      );
      if (!allowOperation) return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    const isDataValid = await validations(data);

    if (!isDataValid) return;
    setIsLoading(true);
    try {
      await customerCreditRepository.create(data);

      handleExit();
      return toastr.success(
        'Lançamento criado!',
        'O lançamento de crédito foi realizado com sucesso!'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao salvar o lançamento.',
        'Por favor, tente novamente.'
      );
    }
    setIsLoading(false);
  };

  return (
    <Modal
      dialogClassName="modal-60w"
      show={newCustomerCredit}
      onHide={() => handleExit()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Lançamento de Crédito</strong>
          <span
            style={{
              color: 'red',
              position: 'absolute',
              right: 10,
              marginTop: '25px',
              fontSize: '14px',
            }}
          >
            * Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollapsibleSection
          title="Lançamento de Saldo"
          isExpanded={isExpanded}
          handleExpand={() => setIsExpanded(!isExpanded)}
        >
          <div className="customer-credit__filters-rows">
            <fieldset>
              <span>
                Lançamento:<span style={{ color: 'red' }}>*</span>
              </span>
              <select
                className="form-control foco-input"
                {...register('type')}
                disabled={customerCreditId}
              >
                <option value="Entrada">Entrada</option>
                <option value="Saída">Saída</option>
              </select>
            </fieldset>

            <fieldset>
              <span>
                Cliente:<span style={{ color: 'red' }}>*</span>
              </span>
              <SelectCustomers
                onChange={handleCustomerChange}
                disabled={customerCreditId}
                value={selectedCustomer}
                showDefaultLabel={false}
                showVehicles={false}
                placeholder="Pesquisa por CPF/CNPJ ou Nome, Razão Social ou Nome Fantasia"
                status="ATIVO"
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '35px',
                    minHeight: '35px',
                    width: '50rem',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '35px',
                    padding: '0 0 0 10px',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                  }),
                }}
              />
            </fieldset>

            <fieldset style={{ width: 120 }}>
              <span>
                Valor (R$):<span style={{ color: 'red' }}>*</span>
              </span>
              <CurrencyInput
                className="form-control foco-input"
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                value={creditValue}
                onChangeEvent={(e) => {
                  setValue(
                    'value',
                    Number(
                      `${e.target.value}`
                        .replace('R$ ', '')
                        ?.replace('.', '')
                        ?.replace(',', '.')
                    )
                  );
                }}
                disabled={customerCreditId}
              />
            </fieldset>
          </div>

          <div className="customer-credit__filters-row">
            <fieldset style={{ width: '100%', marginTop: 10 }}>
              <div>Observações:</div>
              <textarea
                className="form-control foco-input"
                {...register('observations')}
                disabled={customerCreditId}
                maxlength="500"
              />
            </fieldset>
          </div>
        </CollapsibleSection>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            bsStyle="danger"
            name="Cancelar"
            onClick={() => handleExit()}
            fill
          >
            Cancelar
          </Button>
          {!customerCreditId && (
            <Button
              bsStyle="info"
              name="Salvar"
              onClick={handleSubmit(onSubmit)}
              fill
            >
              Salvar{' '}
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x" />
              ) : (
                ''
              )}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerCreditModal;
