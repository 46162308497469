import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { format, isValid } from 'date-fns';
import PDFButton from 'client/components/PDFButton';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import constants from 'utils/constants';
import ListCanceledItemsReportRepository from 'v2/repositories/ListCanceledItemsReportRepository';

export default function ListCanceledItemsMain() {
  const { companyId } = useAuth();

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [typeOfDate, setTypeOfDate] = useState('byCreation');
  const [typeOfSale, setTypeOfSale] = useState(
    `${constants.SALES_TYPE_ID.SALE},${constants.SALES_TYPE_ID.PDV},${constants.SALES_TYPE_ID.ORDER_OF_SERVICE}`
  );
  const [rankingBy, setRankingBy] = useState('quantity');
  const [loading, setLoading] = useState(false);
  const [isReportReady, setIsReportReady] = useState(false);
  const [report, setReport] = useState(false);

  useEffect(() => {
    if (initialDate && finalDate && typeOfDate && typeOfSale) {
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [initialDate, finalDate, typeOfDate, typeOfSale]);

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate)) ||
      !typeOfDate ||
      !typeOfSale
    ) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setIsReportReady(false);
    try {
      const data =
        await ListCanceledItemsReportRepository.generateListCanceledItemsReport(
          {
            companyId,
            initialDate,
            finalDate,
            typeOfDate,
            typeOfSale,
            rankingBy,
          }
        );

      if (data?.report.length) {
        handlePrint();
      } else {
        return toastr.warning(
          'Nenhum item foi localizado',
          'Nenhum resultado foi encontrado com os filtros informados. Verifique os filtros e tente novamente.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a busca. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/list-canceled-items?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&typeOfDate=${typeOfDate}&typeOfSale=${typeOfSale}&rankingBy=${rankingBy}`,
      '_blank'
    );
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Data:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={typeOfDate}
              onChange={(e) => setTypeOfDate(e.target.value)}
            >
              <option value="byCreation">Criação da Venda</option>
              <option value="byClosing">Finalização da Venda</option>
            </select>
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Venda:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={typeOfSale}
              onChange={(e) => setTypeOfSale(e.target.value)}
            >
              <option
                value={`${constants.SALES_TYPE_ID.SALE},${constants.SALES_TYPE_ID.PDV}`}
              >
                Venda/PDV
              </option>
              <option value={constants.SALES_TYPE_ID.ORDER_OF_SERVICE}>
                Ordem de Serviço
              </option>
              <option
                value={`${constants.SALES_TYPE_ID.SALE},${constants.SALES_TYPE_ID.PDV},${constants.SALES_TYPE_ID.ORDER_OF_SERVICE}`}
              >
                Todos
              </option>
            </select>
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Exibir Ranking de Itens:</InputLabel>
            <select
              className="form-control foco-input select-families"
              value={rankingBy}
              onChange={(e) => setRankingBy(e.target.value)}
            >
              <option value="quantity">Por Quantidade</option>
              <option value="value">Por Valor</option>
              <option value="">Não Exibir</option>
            </select>
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <PDFButton
            isLoading={loading}
            disabled={!isReportReady}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <span style={{ color: 'red' }}>
          * Apenas itens cancelados de Venda/OS em aberto ou finalizado serão
          exibidos.
        </span>
      </div>
    </div>
  );
}
