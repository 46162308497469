import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import FormProduto from '../../../../Products/NewProduct/FormProduto'
import { toastr } from 'react-redux-toastr'
import productsRepository from '../../../../../../repositories/Products'
import SATCFeRepository from '../../../../../../repositories/SATCFe'
import { change } from 'redux-form'

const ProductErrorsTable = ({ errors, onCorrection }) => {
  const [indexToEdit, setIndexToEdit] = useState(null)
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [productId, setProductId] = useState(null)
  const [satcfeItemId, setSATCFeItemId] = useState(null)
  const [productErrors, setProductErrors] = useState([])

  const { SATItems } = useSelector((state) => state.form.cadastroSATCFe.values)
  const dispatch = useDispatch()

  useEffect(() => {
    setProductErrors(errors)
  }, [errors])

  function handleOpenProductModal(index, productId, satcfeItemId) {
    setProductId(productId)
    setSATCFeItemId(satcfeItemId)
    setIndexToEdit(index)
    setIsProductModalOpen(true)
  }

  async function handleSubmitProductModal() {
    setIsProductModalOpen(false)
    dispatch(change('cadastroSATCFe', 'loadingUpdateErrorsModal', true))
    await importProductData()
    dispatch(change('cadastroSATCFe', 'loadingUpdateErrorsModal', false))
    const newErrors = [...productErrors]
    newErrors[indexToEdit].isResolved = true

    onCorrection(newErrors)
  }

  async function importProductData() {
    try {
      const product = await productsRepository.getById(productId)
      const {
        PIS_Exit,
        COFINS_Exit,
        Origin,
        NCM_Code,
        CEST_Code,
        Bar_Code,
        Tribute_BarCode,
        Unit_Type,
        CFOP_InState_Code,
        CSOSN,
        CST,
        NcmNbs,
      } = product

      const item = {
        unitType: Unit_Type,
        NCM: NcmNbs ? NcmNbs.code : NCM_Code,
        CEST: CEST_Code,
        barCode: Bar_Code,
        tributeBarCode: Tribute_BarCode,
        origin: Origin,
        stPIS: PIS_Exit,
        stCOFINS: COFINS_Exit,
        CFOP: CFOP_InState_Code,
        CSOSN: CSOSN || CST,
      }

      const newSATCFeItems = SATItems.map((satcfeItem) => {
        if (satcfeItem.productId === productId) {
          return {
            ...satcfeItem,
            ...item,
          }
        }
        return satcfeItem
      })

      dispatch(change('cadastroSATCFe', 'SATItems', newSATCFeItems))

      await SATCFeRepository.updateItem(satcfeItemId, item)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao atualizar as informações do Item. Por favor, tente novamente'
      )
    }
  }

  return (
    <>
      <ReactTable
        className='product-errors-table'
        data={productErrors}
        columns={[
          {
            Header: '',
            accessor: 'isResolved',
            headerClassName: 'text-left',
            width: 50,
            Cell: (props) => (
              <FontAwesomeIcon
                icon={props.value ? faCheckCircle : faTimesCircle}
                color={props.value ? 'green' : 'red'}
                size={30}
              />
            ),
          },
          {
            Header: 'Item',
            accessor: 'item',
            headerClassName: 'text-left',
            width: 250,
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 250,
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
            Cell: (props) => (
              <span
                className='hiperlink'
                onClick={() =>
                  handleOpenProductModal(
                    props.index,
                    props.original.productId,
                    props.original.id
                  )
                }
              >
                {props.value}
              </span>
            ),
          },
        ]}
        defaultPageSize={5}
        sortable={false}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText=''
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      <Modal
        dialogClassName='edit-product-modal-wrapper'
        show={isProductModalOpen}
        onHide={() => setIsProductModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Editar Produto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-product-content'>
          <FormProduto
            ProductId={productId}
            onSuccess={handleSubmitProductModal}
            onCancel={() => setIsProductModalOpen(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProductErrorsTable
