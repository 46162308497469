import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import { format } from 'date-fns'
import {
  currency,
  phoneMask,
  cpfOrCnpjMask,
} from 'client/components/ToNormalize/ToNormalize'

const Header = ({ code }) => {
  return (
    <View>
      <Text style={styles.title}>Cotação Online {code}</Text>
      <View style={styles.line} />
    </View>
  )
}

const SectionTitle = ({ children }) => {
  return (
    <View>
      <Text style={[styles.subtitle, styles.uppercase, styles.center]}>
        {children}
      </Text>
      <View style={styles.line} />
    </View>
  )
}

const ProvidersResumeHeader = () => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[styles.boldText, { width: '20%' }]}>
          <Text>Identificador</Text>
        </View>
        <View style={[styles.boldText, styles.center, { width: '20%' }]}>
          <Text>CNPJ/CPF</Text>
        </View>
        <View style={[styles.boldText, { width: '20%' }]}>
          <Text>Razão Social/Nome</Text>
        </View>
        <View style={[styles.boldText, { width: '20%' }]}>
          <Text>Celular</Text>
        </View>
        <View style={[styles.boldText, { width: '20%' }]}>
          <Text>Status</Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  )
}

const QuotationItemsHeader = () => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[styles.boldText, { width: '4%' }]}>
          <Text>Código</Text>
        </View>
        <View style={[styles.boldText, styles.center, { width: '30%' }]}>
          <Text>Descrição</Text>
        </View>
        <View style={[styles.boldText, styles.center, { width: '20%' }]}>
          <Text>Ref. Fabricante</Text>
        </View>
        <View style={[styles.boldText, styles.center, { width: '20%' }]}>
          <Text>Marca</Text>
        </View>
        <View style={[styles.boldText, styles.center, { width: '15%' }]}>
          <Text>QTD</Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  )
}

const ObservationsHeader = () => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[styles.boldText, { width: '15%' }]}>
          <Text>Identificador</Text>
        </View>
        <View style={[styles.boldText, styles.left, { width: '85%' }]}>
          <Text>Observações</Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  )
}

const ObservationsRow = ({ item, showBottomLine }) => {
  return (
    <View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          margin: '5px 0 5px 0',
        }}
      >
        <View style={[styles.text, { width: '15%' }]}>
          <Text>{item.identifier}</Text>
        </View>
        <View style={[styles.text, styles.left, { width: '85%' }]}>
          <Text wrap={true} style={{ flexWrap: 'wrap' }}>
            {item.observations}
          </Text>
          {showBottomLine && (
            <View style={[styles.line, { marginTop: '5px' }]} />
          )}
        </View>
      </View>
    </View>
  )
}

const QuotationResponseItemHeader = () => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[styles.smallBoldText, styles.center, { width: '25%' }]}>
          <Text>Fornecedor</Text>
        </View>
        <View style={[styles.smallBoldText, styles.center, { width: '25%' }]}>
          <Text>Quantidade</Text>
        </View>
        <View style={[styles.smallBoldText, styles.center, { width: '25%' }]}>
          <Text>Valor Unitário</Text>
        </View>
        <View style={[styles.smallBoldText, styles.center, { width: '25%' }]}>
          <Text>Total</Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  )
}

const QuotationResponseItemRow = ({ response }) => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[styles.smallText, styles.center, { width: '25%' }]}>
          <Text>{response.identifier}</Text>
        </View>
        <View style={[styles.smallText, styles.center, { width: '25%' }]}>
          <Text>
            {response.avaliableQuantity !== null
              ? response.avaliableQuantity
              : '-'}
          </Text>
        </View>
        <View style={[styles.smallText, styles.center, { width: '25%' }]}>
          <Text>
            {response.unitValue !== null ? currency(response.unitValue) : '-'}
          </Text>
        </View>
        <View style={[styles.smallText, styles.center, { width: '25%' }]}>
          <Text>
            {response.total !== null ? currency(response.total) : '-'}
          </Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  )
}

const QuotationItemsRow = ({ item }) => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={[{ width: '4%' }]}>
          <Text>{item.code}</Text>
        </View>
        <View style={[styles.center, { width: '30%' }]}>
          <Text>{item.description}</Text>
        </View>
        <View style={[{ width: '20%' }, styles.center]}>
          <Text>{item.manufacturer_ref}</Text>
        </View>
        <View style={[{ width: '20%' }, styles.center]}>
          <Text>{item.brand}</Text>
        </View>
        <View style={[{ width: '15%' }, styles.center]}>
          <Text>{item.quantity}</Text>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '80px',
          width: '50%',
          marginTop: '10px',
          marginBottom: '5px',
        }}
      >
        <QuotationResponseItemHeader />
        {item.responses.map((res, index) => {
          return <QuotationResponseItemRow key={index} response={res} />
        })}
      </View>
      <View style={[styles.line, { marginTop: '10px' }]} />
    </View>
  )
}

const ProvidersResumeRow = ({ provider }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <View style={[{ width: '20%' }]}>
        <Text>{provider.identifier}</Text>
      </View>
      <View style={[styles.center, { width: '20%' }]}>
        <Text>{cpfOrCnpjMask(provider.cpfCnpj)}</Text>
      </View>
      <View style={[{ width: '20%' }]}>
        <Text>{provider.name}</Text>
      </View>
      <View style={[{ width: '20%' }]}>
        <Text>{phoneMask(provider.phone)}</Text>
      </View>
      <View style={[{ width: '20%' }]}>
        <Text>{provider.status}</Text>
      </View>
    </View>
  )
}

const GeneralQuotationReportDocument = ({
  quotationItemsWithResponses,
  quotationResume,
  quotationInfo,
  quotationObservations,
}) => {
  return (
    <Document title={`relatorio--${format(new Date(), 'dd/MM/yyyy')}`}>
      <Page style={styles.page} wrap={false} orientation="landscape">
        <View>
          <Header code={quotationInfo.code} />

          <SectionTitle>Resumo dos Fornecedores</SectionTitle>
          <ProvidersResumeHeader />
          {quotationResume?.map((item) => (
            <ProvidersResumeRow
              key={item.id}
              provider={{
                identifier: item.identifier,
                name: item.name,
                cpfCnpj: item.cpfCnpj,
                phone: item.phone,
                status: item.status,
              }}
            />
          ))}

          <View style={styles.line} />

          <SectionTitle>Cotação Solicitada</SectionTitle>
          <QuotationItemsHeader />
          {quotationItemsWithResponses.map((item) => {
            return <QuotationItemsRow key={item.id} item={item} />
          })}

          <SectionTitle>OBSERVAÇÕES</SectionTitle>
          <ObservationsHeader />
          {quotationObservations.map((item, index) => {
            let showBottomLine = true
            if (index + 1 === quotationObservations.length) {
              showBottomLine = false
            }

            return (
              <ObservationsRow
                key={item.identifier}
                item={item}
                showBottomLine={showBottomLine}
              />
            )
          })}

          <View style={[styles.line, { marginTop: '10px' }]} />
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  center: {
    textAlign: 'center',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  smallBoldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  smallText: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})

export default GeneralQuotationReportDocument
