import React, { useEffect, useState } from 'react'
import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import { PDFViewer } from '@react-pdf/renderer'
import RepresentativeComissionsRepository from 'repositories/RepresentativeComissions'

import RepresentativeComissionsPDF from './RepresentativeComissionsPDF'
import { addDays, format } from 'date-fns'

export const RepresentativeComissions = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [reportType, setReportType] = useState('')
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)

  const [reportData, setReportData] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const companyId = searchParams.get('companyId')
      const tradeRepresentativeId = searchParams.get('representativeId')
      const showBudgets = searchParams.get('showBudgets')
      const reportType = searchParams.get('reportType')

      const params = {
        initialDate,
        finalDate,
        tradeRepresentativeId,
        showBudgets: parseInt(showBudgets),
        reportType,
      }

      const representativeComissions =
        await RepresentativeComissionsRepository.getRepresentativeComissionsReport(
          companyId,
          params
        )

      setReportData(representativeComissions)

      setReportType(reportType)
      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      setHasError(false)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <RepresentativeComissionsPDF
            reportData={reportData}
            reportType={reportType}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
