import React from 'react';
import Button from '../../../../../client/components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';

const ModalMoreInfoSAT = ({ show, onHide }) => {
  return (
    <Modal dialogClassName="modal-30w" show={show}>
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '0 15px' }}>
          <p style={{ listStyleType: 'disc' }}>
            Para utilizar a emissão do SAT, deverá seguir as seguintes regras:
          </p>
          <ul style={{ listStyleType: 'disc' }}>
            <li>O equipamento deverá estar ativo e vinculado a empresa</li>
            <li>
              A emissão ocorrerá <strong>apenas</strong> no equipamento que o
              SAT está instalado
            </li>
            <li>
              Embora utilize o SAT, é necessário configurar a NFC-e e para sua
              contingência no caso de falha do equipamento
            </li>
            <li>
              A emissão do SAT ocorrerá apenas em <strong>produção</strong>(Com
              validade jurídica)
            </li>
          </ul>
          <p style={{ color: '#d43f3a', fontSize: '12px', marginLeft: '30px' }}>
            Caso tenha alguma dúvida que não esteja acima, abra um ticket ou
            acione um suporte online
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} bsStyle="danger" fill>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMoreInfoSAT;
