import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import NumberFormat from 'react-number-format'

import CustomButton from 'client/components/CustomButton/CustomButton'
import YearInput from 'components/YearInput'

import { useAuth } from 'contexts/auth'

import cashierBankRepository from 'repositories/CashierBank'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import { encrypt } from '../../../components/ToNormalize/ToNormalize'
import format from 'date-fns/format/index'

export default function ConsultPayableAmountModal({
  isOpen,
  handleClose,
  cashierBankId,
}) {
  const [year, setYear] = useState('')
  const [payables, setPayables] = useState([])
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  async function handleGetPayables() {
    setLoading(true)
    try {
      const data = await cashierBankRepository.getBalanceAmountByYearPerMonth({
        year,
        companyId,
        cashierBankId,
      })
      setPayables(data)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar o balanço.',
        'Tente novamente! Caso persista, contate o suporte.'
      )
    } finally {
      setLoading(false)
    }
  }

  async function returnLinkReport(index) {
    const initialDate = format(new Date(year, index, 1), 'yyyy-MM-dd')
    const finalDate = format(new Date(year, index + 1, 0), 'yyyy-MM-dd')

    const cashierBanksToReportHash = await encrypt(
      JSON.stringify([cashierBankId]),
      '@OS-dig:cashierBanks'
    )

    window.open(
      window.location.origin +
        `/report/cashier-bank-flow?cashierBanks=${cashierBanksToReportHash}&initialDate=${initialDate}&finalDate=${finalDate}&showTotalPaymentMethod=true&showSaleDetail=false`,
      '_blank'
    )
  }

  useEffect(() => {
    if (year.length === 4) {
      handleGetPayables()
    }
  }, [year])

  useEffect(() => {
    setYear(`${new Date().getFullYear()}`)
  }, [])

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-25w">
      <Modal.Header>
        <Modal.Title>
          <strong>Saldo do Caixa/Banco</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Veja abaixo o saldo:</span>
        <br />
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <YearInput value={year} setValue={setYear} showArrows />
        </div>

        <center>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
              width: '250px',
            }}
          >
            {loading ? (
              'Carregando...'
            ) : (
              <table className="balance-modal-table">
                <thead>
                  <tr>
                    <th className="month-balance">Mês</th>
                    <th className="amount-balance">Saldo</th>
                    <th className="actions-balance"></th>
                  </tr>
                </thead>
                <tbody>
                  {payables.map((payable, index) => (
                    <tr>
                      <td className="month-balance">{payable.month}</td>
                      <td className="amount-balance">
                        <NumberFormat
                          displayType="text"
                          value={payable.amount}
                          decimalScale="2"
                          fixedDecimalScale={true}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </td>
                      <td className="actions-balance">
                        <FontAwesomeIcon
                          icon={faPrint}
                          style={{
                            color: '#444444',
                          }}
                          onClick={() => returnLinkReport(index)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr></tr>
                </tbody>
              </table>
            )}
          </div>
        </center>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ textAlign: 'right' }}>
          <CustomButton bsStyle="danger" fill onClick={() => handleClose()}>
            Voltar
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
