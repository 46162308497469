import React, { useEffect, useState } from 'react'
import FormNewAccountPlan from './FormNewAccountPlan'

import accountPlansRepository from 'repositories/AccountPlans'
import Companies from 'repositories/Companies'

import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import { change, initialize } from 'redux-form'
import { useHistory } from 'react-router'

import constants from 'utils/constants'


const BundleFormNewAccountPlan = ({ accountPlanId, onCancel, newAccountModalType, handleModalSubmit }) => {
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (accountPlanId) {
      loadAccountPlan()
    }
  }, [])

  const loadAccountPlan = async () => {
    const { data } = await accountPlansRepository.getAccountPlanById(accountPlanId)

    const { description, categoryId, isActive, type, observations } = data

    const initialValues = {
      description,
      categoryId,
      isActive,
      type,
      observations
    }

    dispatch(initialize('accountPlan', initialValues))
  }

  const create = async values => {
    try {
      const { description, type, categoryId, isActive, observations } = values

      const { data } = await accountPlansRepository.index({ companyId })
      const accountPlans = data

      const identicalAccountPlan = accountPlans.filter(accountPlan =>
        accountPlan.description === description &&
        accountPlan.type === type &&
        accountPlan.categoryId == categoryId &&
        accountPlan.isActive === isActive &&
        !!accountPlan.observations === !!observations
      )

      const hasIdenticalAccountPlan = !!identicalAccountPlan.length
      if (hasIdenticalAccountPlan) {
        return toastr.error('Já existe uma conta com a mesma descrição e categoria, por favor, verifique para prosseguir.')
      }

      const newAccountPlan = {
        companyId,
        description,
        type,
        categoryId,
        isActive,
        observations
      }

      const response = await accountPlansRepository.createAccountPlan(newAccountPlan)

      toastr.success(response.data.message)

      if (handleModalSubmit) {
        return handleModalSubmit(response.data)
      }

      //Se não for um modal, volta pra consulta de Plano de Conta
      history.push(constants.ROUTES.ACCOUNT_PLANS)
    } catch (err) {
      toastr.error(err.response.data.message)
    }
  }

  const update = async values => {
    try {
      const { type, categoryId, isActive, observations } = values

      if (!isActive) {
        const company = await Companies.getById(companyId)

        const {
          defaultSaleAccountPlanId,
          defaultPurchaseAccountPlanId,
          defaultChangeAccountPlanId
        } = company

        if (
          accountPlanId == defaultSaleAccountPlanId ||
          accountPlanId == defaultPurchaseAccountPlanId ||
          accountPlanId == defaultChangeAccountPlanId
        ) {
          return toastr.error('Essa conta está configurada como conta padrão. Altere a conta nas configurações para inativar e tente novamente.')
        }
      }

      await accountPlansRepository.updateAccountPlan(accountPlanId, {
        type, categoryId, isActive, observations
      })

      toastr.success('Plano de Conta editado com sucesso.')

      //Se não for um modal, volta pra consulta de Plano de Conta
      if (!newAccountModalType) history.push(constants.ROUTES.ACCOUNT_PLANS)
    } catch (err) {
      console.log(err)
      toastr.error('Não foi possível editar o Plano de Conta. Tente mais tarde.')
    }
  }

  const handleSubmit = async values => {
    setLoading(true)
    if (!accountPlanId) {
      await create(values)
    } else {
      await update(values)
    }
    setLoading(false)
  }

  return (
    <FormNewAccountPlan
      initialValues={{
        isActive: true,
      }}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      newAccountModalType={newAccountModalType}
      accountPlanId={accountPlanId}
      loading={loading}
    />
  )
}

export default BundleFormNewAccountPlan
