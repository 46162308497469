import React, { memo } from 'react'
import Chart from "react-google-charts";

function GraphicByFamily(props) {
    const { data, opacity } = props

    return (
        <>
            <h4><strong>Vendas por Família (%)</strong></h4>
            <div className="chart-familia" style={{ opacity: opacity }}>
                <Chart
                    width={'500px'}
                    height={'300px'}
                    chartType="PieChart"
                    data={data}
                    chartLanguage="pt-br"
                    options={{
                        chartArea: {
                            left: 0
                        }
                    }}
                />
            </div>
        </>
    )
}

export default memo(GraphicByFamily)