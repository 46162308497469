import React, { useEffect, useState } from 'react'

import { withRouter } from 'react-router'

import SuccessReportFilter from '../SuccessReportFilter'
import SuccessReportTable from '../SuccessReportTable'

import companyRepository from '../../../../repositories/Companies'

import Card from 'components/Card/Card'

import { toastr } from 'react-redux-toastr'

function SuccessReportMain() {
  const [loading, setLoading] = useState(false)
  const [companySuggestions, setCompanySuggestions] = useState([])
  const [reportData, setReportData] = useState([])

  useEffect(() => {
    loadCompanies()
  }, [])

  const loadCompanies = async () => {
    try {
      setLoading(true)
      const data = await companyRepository.getCompaniesForSuccessReport()
      setCompanySuggestions(data)
    } catch (err) {
      toastr.error('Erro', 'Não foi possível carregar as empresas')
    } finally {
      setLoading(false)
    }
  }

  const loadReportData = async (companyId) => {
    try {
      setLoading(true)
      const data = await companyRepository.getSuccessPoints(companyId)

      setReportData(data)
    } catch (err) {
      toastr.error('Erro', 'Não foi possível carregar os dados do relatório')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card
      content={
        <div>
          <SuccessReportFilter
            companySuggestions={companySuggestions}
            setCompanySuggestions={setCompanySuggestions}
            loadReportData={loadReportData}
            loading={loading}
            reportData={reportData}
            setReportData={setReportData}
          />
          <SuccessReportTable loading={loading} reportData={reportData} />
        </div>
      }
    />
  )
}

export default SuccessReportMain
