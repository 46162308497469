import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import routes from '../../../routes'
import StockList from './StockList'
import NoBrandAndFamily from './NoBrandAndFamily'
import { MinMaxStockReport } from './MinMaxStock'
import ProductHistory from './ProductHistory'

const ProductsReports = ({ location }) => {
  const route = routes.filter(
    (r) => `/client/${r.path}` === window.location.pathname
  )

  const renderComponent = (name) => {
    switch (name) {
      case 'Listagem de Estoque Atual':
        return <StockList />
      case 'Produtos Sem Marca e Família':
        return <NoBrandAndFamily />
      case 'Estoque Mínimo e Máximo':
        return <MinMaxStockReport location={location} />
      case 'Histórico do Produto':
        return <ProductHistory location={location} />
      default:
        return <div />
    }
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Produtos', route[0].name]}
            path={['Home', null, null, null]}
          />
          {renderComponent(route[0].name)}
        </Row>
      </Grid>
    </div>
  )
}

export default ProductsReports
