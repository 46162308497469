import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useSelector, useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import RenderField from 'components/RenderField';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import lossReasonsRepository from '../../../../../repositories/LossReasons';
import AlertModal from 'components/AlertModal/AlertModal';
export default function OtherConfigurations() {
  const [lossReasons, setLossReasons] = useState([]);
  const [isStockWriteOffModalOpen, setIsStockWriteOffModalOpen] =
    useState(false);
  const [stockWriteOffInfo, setStockWriteOffInfo] = useState(
    'Fechamento da Venda'
  );
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const { companyId } = useAuth();
  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isPlanPrime,
    isPlanWorkmotorFree,
    isPaidWorkmotor,
    isWorkmotor: isPlanWorkmotor,
  } = usePlanSignatureContext();
  const isPlanFreeOrStart = isPlanFree || isPlanStart;
  const isPlanFreeStartOrEssencial = isPlanFree || isPlanStart || isPlanBasic;
  const isPlanWmFree = isPlanWorkmotorFree;
  const dispatch = useDispatch();

  const {
    closeBudgetAutomatically,
    daysToCloseBudgetAutomatically,
    kitRegistration,
    stockWriteOff,
    saleItemApprovalCancellation,
    partsRequisitionForOpenOS,
  } = useSelector((state) => state.form.salesConfiguration.values);

  useEffect(() => {
    loadLossReasons();
  }, []);

  function handleChangeCloseBudgetAutomaticallyToggle() {
    if (isPlanFree || isPlanStart) {
      return setIsOpenFeatureNotAvailableModal(true);
    }

    const newValue = !closeBudgetAutomatically;

    if (newValue === false) {
      dispatch(
        change('salesConfiguration', 'daysToCloseBudgetAutomatically', 0)
      );
      dispatch(change('salesConfiguration', 'lossReasonId', null));
    } else {
      dispatch(
        change('salesConfiguration', 'daysToCloseBudgetAutomatically', 5)
      );
    }

    dispatch(
      change('salesConfiguration', 'closeBudgetAutomatically', newValue)
    );
  }

  function handleKitRegistration() {
    dispatch(change('salesConfiguration', 'kitRegistration', !kitRegistration));
  }

  function validateIsPlanPrime() {
    if (isPlanFree || isPlanStart || isPlanBasic) {
      setIsOpenFeatureNotAvailableModal(true);
    }
  }

  function handleSaleItemApprovalCancellation() {
    if (isPlanFreeOrStart || isPlanWmFree) {
      setIsOpenFeatureNotAvailableModal(true);
    } else {
      dispatch(
        change(
          'salesConfiguration',
          'saleItemApprovalCancellation',
          !saleItemApprovalCancellation
        )
      );
    }
  }

  function handleStockWriteOffChange(value) {
    if (value === true) {
      dispatch(
        change('salesConfiguration', 'stockWriteOff', stockWriteOffInfo)
      );
      if (stockWriteOffInfo === 'Fechamento da Venda')
        dispatch(
          change('salesConfiguration', 'partsRequisitionForOpenOS', false)
        );
    }

    setIsStockWriteOffModalOpen(false);
  }

  async function loadLossReasons() {
    try {
      const companyLossReasons = await lossReasonsRepository.getAllByCompany({
        companyId,
        isActive: 1,
      });
      setLossReasons(companyLossReasons.data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os motivos de perda. Por favor, tente novamente'
      );
    }
  }

  return (
    <div className="flex column gap-050">
      <div className="flex row gap-050">
        <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
          <div>
            <label>Encerramento automático de orçamento</label>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              width: '100%',
            }}
          >
            <Toggle
              checked={closeBudgetAutomatically}
              onChange={handleChangeCloseBudgetAutomaticallyToggle}
            />

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Field
                name="daysToCloseBudgetAutomatically"
                component={RenderField}
                style={{ width: '120px' }}
                disabled={
                  isPlanFree || isPlanStart || !closeBudgetAutomatically
                }
                maxLength={2}
                normalize={onlyNumbers}
              />
              &nbsp; dias
            </div>
          </div>
        </Col>
        {!isPlanFree && (
          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name="lossReasonId"
              component={RenderField}
              label="Motivo pad. de Encerramento"
              as="select"
              disabled={!closeBudgetAutomatically}
            >
              <option value={''}>Selecione</option>
              {lossReasons.map((lossReason) => (
                <option value={lossReason.id} key={lossReason.id}>
                  {lossReason.description}
                </option>
              ))}
            </Field>
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name="averageTicketPeriodInMonths"
              component={RenderField}
              label="Período de Ticket Médio:"
              disabled={isPlanFree || isPlanStart}
              onChange={() =>
                toastr.warning(
                  'O valor do ticket médio é calculado uma vez ao dia, devido ao tamanho do volume dos dados.'
                )
              }
              as="select"
            >
              <option value={3}>
                {isPlanFree || isPlanStart ? 'Não disponível' : '3 Meses'}
              </option>
              <option value={6}>6 Meses</option>
              <option value={9}>9 Meses</option>
              <option value={12}>12 Meses</option>
            </Field>
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
            <div>
              <label>Habilitar Cadastro de Kit</label>
            </div>

            <Toggle
              checked={kitRegistration}
              onChange={() => handleKitRegistration()}
            />
          </Col>
        )}
      </div>

      {(!!isPaidWorkmotor || !!isPlanPrime) && (
        <div className="flex row gap-050">
          <Col xs={12} sm={12} md={4} lg={4} style={{ width: '250px' }}>
            <Field
              name="monthRangeOfCustomerReturnCalc"
              component={RenderField}
              label="Período de Cálculo de Retorno:"
              as="select"
            >
              <option value="3">3 meses</option>
              <option value="6">6 meses</option>
              <option value="9">9 meses</option>
              <option value="12">12 meses</option>
            </Field>
          </Col>

          <Col xs={12} sm={12} md={3} lg={3} style={{ width: '210px' }}>
            <Field
              name="advancedSearchType"
              component={RenderField}
              label="Tipo de Busca Avançada:"
              as="select"
            >
              <option value="Busca Geral">Busca Geral</option>
              <option value="Por Aplicação">Por Aplicação</option>
            </Field>
          </Col>

          {!!isPaidWorkmotor && (
            <Col xs={12} sm={12} md={3} lg={3} style={{ width: '230px' }}>
              <Field
                name="sellingPriceType"
                component={RenderField}
                label="Tipo de Preço de Venda:"
                as="select"
              >
                <option value="Preço Único">Preço Único</option>
                <option value="Preço Atacado e Varejo">
                  Preço Atacado e Varejo
                </option>
              </Field>
            </Col>
          )}

          <Col xs={12} sm={12} md={3} lg={3} style={{ width: '230px' }}>
            <label>Baixa de Estoque via:</label>
            <select
              className="form-control foco-input"
              onChange={(e) => {
                setIsStockWriteOffModalOpen(true);
                setStockWriteOffInfo(e.target.value);
              }}
              value={stockWriteOff}
              disabled={!isPaidWorkmotor}
            >
              <option value="Fechamento da Venda">Fechamento da Venda</option>
              <option value="Requisição de Peças">Requisição de Peças</option>
            </select>
          </Col>
        </div>
      )}
      <div className="flex row gap-050">
        {!!isPaidWorkmotor && (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="toggle-column"
            style={{ width: '230px', marginLeft: '-15px' }}
          >
            <div>
              <label>Requisição para OS Aberta</label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Toggle
                checked={partsRequisitionForOpenOS}
                onChange={() => {
                  dispatch(
                    change(
                      'salesConfiguration',
                      'partsRequisitionForOpenOS',
                      !partsRequisitionForOpenOS
                    )
                  );
                }}
                disabled={stockWriteOff !== 'Requisição de Peças'}
              />
            </div>
          </Col>
        )}
      </div>
      <AlertModal
        show={isStockWriteOffModalOpen}
        message={
          <>
            <p>
              <strong>
                Você tem certeza que deseja mudar a forma de baixar estoque na
                venda?
              </strong>
            </p>
            <p>
              A partir do momento que for alterado e salvo, as próximas vendas
              de peças terão a forma de baixa de estoque diferente, podendo
              impactar no controle de seu estoque.
              <center>
                <small style={{ color: 'red' }}>
                  Vendas já finalizadas não poderão ter o modo de baixa
                  alteradas
                </small>
              </center>
            </p>
          </>
        }
        onCancel={() => handleStockWriteOffChange(false)}
        onSubmit={() => handleStockWriteOffChange(true)}
        onHide={() => handleStockWriteOffChange(false)}
      />

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
    </div>
  );
}
