import api from '../services/api';

const getAllBirthdays = async (params) => {
  try {
    const response = await api.get(`birthdays`, { params });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default { getAllBirthdays };
