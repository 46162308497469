import React from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

const SearchInput = ({ containerStyle = {}, children, ...rest}) => {
  return (
    <div className='search-input-wrapper' style={containerStyle}>
      <FontAwesomeIcon icon={faSearch} color="#808080"/>
      <input
        {...rest}
        className='form-control foco-input'
        type="text"
      />
      {children}
    </div>
  )
}

export default SearchInput