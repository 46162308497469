import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import CardForm from "client/components/CardForm";
import FormSubmitButtons from "client/components/FormSubmitButtons";
import AlertModal from "../../../../../components/AlertModal/AlertModal";
import { useDispatch, useSelector } from "react-redux";

import LinkedServiceMainData from "./MainData";
import { useHistory } from "react-router-dom";
import constants from "../../../../../utils/constants";

const FormNewLinkedService = ({
  handleSubmit,
  loading,
  product,
  pristine,
  modal,
  hideModal,
}) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const { values } = useSelector((state) => state).form.formLinkedService;
  const { productId, linkedServiceProductId } = values;

  const history = useHistory();

  const handleCancel = () => {
    if (pristine) return cancel();
    setIsAlertModalOpen(true);
  };

  const cancel = () => {
    if (modal) return hideModal();
    history.push(constants.ROUTES.LINKED_SERVICES);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}
      >
        <LinkedServiceMainData
          productInfo={product}
          style={{ marginBottom: "20px" }}
        />
        <div style={{ paddingTop: "20px", paddingRight: "10px" }}>
          <FormSubmitButtons
            loading={loading}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        </div>
      </form>

      <AlertModal
        show={isAlertModalOpen}
        onHide={() => setIsAlertModalOpen(false)}
        onCancel={() => setIsAlertModalOpen(false)}
        message={`Deseja cancelar o cadastro do serviço vinculado? Todos os campos preenchidos não serão salvos e o cadastro poderá ser realizado posteriormente`}
        onSubmit={cancel}
      />
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: "formLinkedService",
})(FormNewLinkedService);
