import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  calculate,
  createInstallments,
  handleChange,
  handleSaleStatusAndType,
  handleSaleStatusAndTypeWithoutInstallments,
} from './redux/actions';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { withRouter } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import customerCreditRepository from 'repositories/CustomerCredit';
import BankSlipConfirmationModal from '../BankSlipConfirmationModal';
import { DebtLimitModalBlock } from 'client/components/DebtLimitModalBlock';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import billsToReceiveRepository from 'repositories/BillsToReceive';
import ChangeModal from './ChangeModal';
import { toastr } from 'react-redux-toastr';
import { useEffect } from 'react';
import { useBonus } from 'hooks/useBonus';
import { CRMBonusRepository } from 'repositories/CRMBonus';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import AlertModal from 'components/AlertModal/AlertModal';
import { RecloseModal } from './components/RecloseModal';

function SaveModal({ isOpen, history }) {
  const [allowShowSaveModal, setAllowShowSaveModal] = useState(true);

  const { company, userId, userName } = useAuth();

  const [recloseSaleTrigger, setRecloseSaleTrigger] = useState(false);

  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isWorkmotor,
    isPlanWorkmotorFree,
  } = usePlanSignatureContext();
  const isPaidWorkmotor = isWorkmotor && !isPlanWorkmotorFree;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [isGenerateTitleModal, setIsGenerateTitleModal] = useState(false);

  const [neededToCreateCustomerCredit, setNeededToCreateCustomerCredit] =
    useState(false);

  const [targetName, setTargetName] = useState('');

  const [debtLimitInfo, setDebtLimitInfo] = useState({});
  const [isDebtLimitBlockModalOpen, setIsDebtLimitBlockModalOpen] =
    useState(false);

  const [saleStatus, setSaleStatus] = useState('');

  const {
    crmSaveSaleTrigger,
    handleOpenSelectCampaignModal,
    handleCloseSelectCampaignModal,
    handleOpenSaleWithoutBonusModal,
    handleOpenBonusRescuedModal,
    companyHasCRMBonus,
    crmBonusCurrentCustomer,
    companyCRMBonusStoreCode,
    crmBonusCustomerId,
    selectedCampaign,
    crmIdsBonus,
    crmCustomerPIN,
    resetSaveModalTrigger,
    saveOrcamentTrigger,
    handleFinishSale,
    isFirstPurchase,
  } = useBonus();

  const { saleReducer } = useSelector((state) => state);
  const {
    items,
    installments,
    saleId,
    openSaveModal,
    saleTypeId,
    saleStatusId,
    selectedClient,
    change,
    generateTitleAfterCloseSale,
    salesSubtypeDescription,
  } = saleReducer;

  const hasService = items.filter((c) => c.Type === 'Serviço').length > 0;

  const GenerateTitleModalMessage = () => {
    return (
      <div className="flex column gap-075">
        <span className="typography__text-1">
          Você tem certeza que deseja realizar o fechamento desse venda/os com o
          tipo {salesSubtypeDescription}?
        </span>
        <span className="typography__text-1">
          Ao fechar, o estoque será movimentado caso haja itens, mas não será
          gerado nenhum título.
        </span>

        <span className="typography__caption text-red text-center">
          O processo é irreversível. Uma vez fechada, não poderá ser reaberta
          posteriormente.
        </span>
      </div>
    );
  };

  async function handleSubmit(e, bankSlipConfirmed, skipBonus) {
    dispatch(handleChange(false, 'isAppointmentSave'));

    if (!generateTitleAfterCloseSale) {
      setIsLoading(false);
      dispatch(
        handleSaleStatusAndTypeWithoutInstallments(
          saleReducer,
          !name ? e.target.name : name,
          history,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } else {
      if (
        neededToCreateCustomerCredit &&
        company?.companyConfig?.manageInternalCredit
      ) {
        dispatch(
          handleChange(
            neededToCreateCustomerCredit,
            'neededToCreateCustomerCredit'
          )
        );
      }

      const isValid = await isDebtLimitExceeded();
      if (isValid) {
        setIsDebtLimitBlockModalOpen(true);
        return;
      }
      setIsLoading(true);

      if (companyHasCRMBonus && !skipBonus) {
        if (
          !isFirstPurchase &&
          !saleReducer.usedCRMBonus &&
          (!['os-aberta', 'orcament'].includes(saleStatus) ||
            ['os/venda'].includes(saleStatus))
        ) {
          setAllowShowSaveModal(false);
          handleOpenSaleWithoutBonusModal();
          return;
        }

        if (!!crmBonusCustomerId) {
          setAllowShowSaveModal(false);
          return validateBonus(e.target.name);
        }
      }

      const hasBankSlipIntegratedPayment = installments.some(
        (installment) => installment.formOfPayment.isBankSlipIntegration
      );
      const allowEmitBankSlip =
        hasBankSlipIntegratedPayment &&
        !bankSlipConfirmed &&
        e.target.name === 'os/venda';

      if (allowEmitBankSlip) {
        setShowModal(true);
        return;
      }

      setIsLoading(false);
      dispatch(
        handleSaleStatusAndType(
          saleReducer,
          !hasBankSlipIntegratedPayment || !name ? e.target.name : name,
          history,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    }
  }

  async function isDebtLimitExceeded() {
    setIsLoading(true);
    try {
      const debtLimitInfo =
        await billsToReceiveRepository.getCustomerDebtLimitInfo(
          selectedClient?.id
        );

      let isExceeded =
        debtLimitInfo?.debtLimit !== 0 && debtLimitInfo?.isDebtLimitExceeded;

      setDebtLimitInfo(debtLimitInfo);
      return isExceeded;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeModal(e) {
    if (company?.companyConfig?.manageInternalCredit) {
      setIsChangeModalOpen(true);
      setTargetName({
        target: {
          name: e.target.name,
        },
      });
    }
  }

  const validateBonus = (saleStatus) => {
    if (
      ['os/venda-aberta', 'os-aberta', 'orcament'].includes(saleStatus) &&
      saleReducer.usedCRMBonus
    ) {
      dispatch(handleChange(false, 'openSaveModal'));
      setAllowShowSaveModal(true);
      setSaleStatus(saleStatus);
      handleOpenBonusRescuedModal();
      return;
    }

    handleOpenSelectCampaignModal();
  };

  const handleResetSaleModal = () => {
    dispatch(handleChange(false, 'keepOnScreenAfterSave'));
    dispatch(handleChange(false, 'openSaveModal'));
    setAllowShowSaveModal(true);
    setIsLoading(false);
    handleCloseSelectCampaignModal();
  };

  const handleBonusCheckout = async () => {
    try {
      const res = await CRMBonusRepository.checkout({
        storeCode: companyCRMBonusStoreCode,
        timeRestriction: false,
        useBonus: saleReducer.usedCRMBonus && !!crmIdsBonus,
        idsBonus: saleReducer.usedCRMBonus ? crmIdsBonus || '' : '',
        crmCustomerId: crmBonusCustomerId,
        grossValue: saleReducer.total + saleReducer.redeemedBonus,
        redeemedBonus: saleReducer.redeemedBonus || 0,
        campaignId: selectedCampaign?.id,
        sms: crmCustomerPIN || '',
        isMasterPin: crmCustomerPIN === '2018',
        sellerName: saleReducer?.sellerName
          ? saleReducer?.sellerName
          : userName,
        cellphone: onlyNumbers(crmBonusCurrentCustomer.Phones[0].Number_Phone2),
      });

      if (res.message !== 'sucesso')
        throw new Error(res.message || 'Falha ao relizar checkout');

      const { bonusId, orderId } = res;

      dispatch(handleChange(bonusId, 'bonusId'));
      dispatch(handleChange(orderId, 'orderId'));
      dispatch(handleChange(true, 'keepOnScreenAfterSave'));
    } catch (err) {
      toastr.error('Erro', err?.message);
    }
  };

  const handleSaveSaleWithBonus = async () => {
    try {
      await handleBonusCheckout();

      // if (saleReducer.usedCRMBonus) {
      //   dispatch(handleChange(true, 'keepOnScreenAfterSave'));
      // }

      dispatch(
        handleSaleStatusAndType(
          saleReducer,
          name,
          history,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } catch (err) {
      console.log(err);
      setAllowShowSaveModal(true);
      toastr.error(
        'Houve um erro na comunicação com a CRM Bônus',
        'Por gentileza, verifique com o suporte da CRM o lançamento manual do bônus para o cliente. Caso o problema persista, entre em contato com o atendimento'
      );
    }
  };

  const handleFinishBonus = async () => {
    try {
      const res = await CRMBonusRepository.totalSales({
        totalLiquid: saleReducer.total,
        itemsQuantity: saleReducer.items.reduce(
          (prev, curr) => prev + +curr.Quantity,
          0
        ),
        sellerName: saleReducer.sellerName || '',
        consumerName:
          crmBonusCurrentCustomer.Company_Name || selectedClient.Company_Name,
        consumerCellphone:
          crmBonusCurrentCustomer?.id &&
          crmBonusCurrentCustomer?.Phones.length > 0
            ? onlyNumbers(crmBonusCurrentCustomer?.Phones[0]?.Number_Phone2)
            : selectedClient?.Phones[0]?.Number_Phone2,
        bonusId: saleReducer.bonusId,
        orderId: saleReducer.orderId,
        saleId: saleReducer.saleId,
      });

      if (res.message !== 'Sucesso.')
        throw new Error(res.message || 'Falha ao relizar checkout');

      dispatch(handleChange(false, 'keepOnScreenAfterSave'));
      handleFinishSale();

      dispatch(
        handleSaleStatusAndType(
          saleReducer,
          name,
          history,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } catch (err) {
      console.log(err);
      setAllowShowSaveModal(true);
      toastr.error(
        'Houve um erro na comunicação com a CRM Bônus',
        'Por gentileza, verifique com o suporte da CRM o lançamento manual do bônus para o cliente. Caso o problema persista, entre em contato com o atendimento'
      );
    }
  };

  const handleSaveWithoutBonus = async (shouldResetInstallments) => {
    dispatch(handleChange(0, 'redeemedBonus'));
    dispatch(handleChange(false, 'usedCRMBonus'));

    if (shouldResetInstallments) {
      const { items, installments } = saleReducer;
      const formOfPayments = installments.map((item) => {
        return item.formOfPayment;
      });
      dispatch(handleChange(formOfPayments, 'originalFormOfPayments'));
      dispatch(handleChange(true, 'shouldReplaceInstallments'));
      dispatch([calculate(items), createInstallments(installments.length)]);
    }

    if (
      (saleStatus === 'os/venda' && !!crmBonusCustomerId) ||
      (name === 'os/venda' && !!crmBonusCustomerId)
    ) {
      await handleBonusCheckout();
    }

    handleFinishSale();
    dispatch(handleChange(false, 'isAppointmentSave'));

    dispatch(
      handleSaleStatusAndType(
        saleReducer,
        name,
        history,
        company,
        userId,
        isPlanFree,
        isPlanStart,
        isPlanBasic,
        isPaidWorkmotor
      )
    );
  };

  const handleCloseSale = (name, skipBonus) => {
    if (
      ['os-aberta', 'orcament', 'os/venda', 'os/venda-aberta'].includes(name)
    ) {
      setName(name);
    }
    switch (name) {
      case 'os/venda':
        if (!generateTitleAfterCloseSale) {
          setIsGenerateTitleModal(true);
        } else if (
          Number(change).toFixed(2) > 0 &&
          isPaidWorkmotor &&
          Number(saleReducer?.returnedValue || 0).toFixed(2) !==
            Number(change).toFixed(2)
        ) {
          handleChangeModal({ target: { name: name } });
        } else {
          handleSubmit({ target: { name: name } }, false, skipBonus);
          dispatch(handleChange(false, 'keepOnScreenAfterSave'));
        }
        break;
      default:
        handleSubmit({ target: { name: name } }, false, skipBonus);
        dispatch(handleChange(false, 'keepOnScreenAfterSave'));
        break;
    }
  };

  const handleCloseGenerateTitleModal = () => {
    setIsGenerateTitleModal(false);
  };

  const handleSubmitGenerateTitleModal = () => {
    setIsLoading(true);
    dispatch(handleChange(false, 'isAppointmentSave'));

    try {
      dispatch(
        handleSaleStatusAndTypeWithoutInstallments(
          saleReducer,
          name,
          history,
          company,
          userId,
          isPlanFree,
          isPlanStart,
          isPlanBasic,
          isPaidWorkmotor
        )
      );
    } catch (err) {
      console.log(err);
      setAllowShowSaveModal(true);
      toastr.error(
        'Erro ao Salvar',
        'Ocorreu um erro ao salvar a venda: ' + err?.response?.data?.message
      );
    } finally {
      setIsLoading(false);
      setIsGenerateTitleModal(false);
    }
  };

  useEffect(() => {
    if (crmSaveSaleTrigger && saleReducer.usedCRMBonus && name === 'os/venda') {
      handleSaveSaleWithBonus();
    } else if (crmSaveSaleTrigger) {
      handleSaveWithoutBonus(
        name !== 'os/venda' && saleReducer.usedCRMBonus ? true : false
      );
    }
  }, [crmSaveSaleTrigger]);

  useEffect(() => {
    if (
      companyHasCRMBonus &&
      saleReducer.saleId &&
      saleReducer.allowedToFinishBonus &&
      saleReducer.saleStatusId === 5
    ) {
      handleFinishBonus();
    }
  }, [saleReducer.saleId, saleReducer.allowedToFinishBonus]);

  useEffect(() => {
    if (resetSaveModalTrigger) {
      handleResetSaleModal();
    }
  }, [resetSaveModalTrigger]);

  useEffect(() => {
    if (saveOrcamentTrigger) {
      handleSaveWithoutBonus(true);
    }
  }, [saveOrcamentTrigger]);

  useEffect(() => {
    if (recloseSaleTrigger) {
      handleCloseSale('os/venda', true);
      dispatch(handleChange(false, 'showRecloseModal'));
      toastr.info('Processando refechamento...', 'Por favor, aguarde.');
    }
  }, [recloseSaleTrigger]);

  return (
    <>
      <Modal
        show={allowShowSaveModal && isOpen}
        onHide={() => dispatch(handleChange(false, 'openSaveModal'))}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Você deseja: </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-body-confirmSave">
          {hasService && (
            <Button
              style={{ backgroundColor: 'blue', border: '1px solid blue' }}
              name="os/venda-aberta"
              onClick={(e) => handleCloseSale(e.target.name)}
              disabled={isLoading}
              fill
            >
              <span
                className={
                  name === 'os-aberta' && isLoading
                    ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                    : 'update-spinner-disabled'
                }
              />

              {saleTypeId === 3 && saleStatusId === 3
                ? 'Salvar Alterações da Ordem de Serviço'
                : 'Salvar e Abrir Ordem de Serviço'}
            </Button>
          )}

          {(!saleId || saleStatusId === 3) && (
            <Button
              id={hasService && 'margin'}
              bsStyle={
                hasService && saleTypeId === 3 && saleStatusId === 3
                  ? 'hidden'
                  : ''
              }
              name="orcament"
              onClick={(e) => handleCloseSale(e.target.name)}
              style={{ backgroundColor: 'blue', border: '1px solid blue' }}
              disabled={isLoading}
              fill
            >
              <span
                className={
                  name === 'orcament' && isLoading
                    ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                    : 'update-spinner-disabled'
                }
              />

              {saleTypeId === 1 && saleStatusId === 3
                ? 'Salvar Alterações do Orçamento'
                : 'Salvar como Orçamento'}
            </Button>
          )}

          <Button
            id="margin"
            bsStyle="info"
            name="os/venda"
            onClick={(e) => handleCloseSale(e.target.name)}
            disabled={isLoading}
            fill
          >
            <span
              className={
                name === 'os/venda' && isLoading
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />
            {hasService
              ? 'Salvar e Finalizar Ordem de Serviço'
              : 'Salvar e Finalizar Venda'}
          </Button>
        </Modal.Body>
      </Modal>
      <ChangeModal
        show={isChangeModalOpen}
        setShow={setIsChangeModalOpen}
        handleSubmit={handleSubmit}
        setNeededToCreateCustomerCredit={setNeededToCreateCustomerCredit}
        neededToCreateCustomerCredit={neededToCreateCustomerCredit}
        targetName={targetName}
      />
      <BankSlipConfirmationModal
        show={showModal}
        setShow={setShowModal}
        onSubmit={handleSubmit}
      />
      <DebtLimitModalBlock
        show={isDebtLimitBlockModalOpen}
        setShow={setIsDebtLimitBlockModalOpen}
        info={debtLimitInfo}
      />

      <AlertModal
        show={isGenerateTitleModal}
        onHide={handleCloseGenerateTitleModal}
        onCancel={handleCloseGenerateTitleModal}
        onSubmit={(e) => handleSubmitGenerateTitleModal(e)}
        title="Fechamento de Venda/OS"
        message={<GenerateTitleModalMessage />}
        loading={isLoading}
      />

      {saleReducer.showRecloseModal && (
        <RecloseModal
          open={saleReducer.showRecloseModal}
          handleClose={() => dispatch(handleChange(false, 'showRecloseModal'))}
          setRecloseSaleTrigger={setRecloseSaleTrigger}
        />
      )}
    </>
  );
}

export default withRouter(SaveModal);
