import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { Grid, Row } from 'react-bootstrap';
import { isValid, format, isBefore } from 'date-fns';
import Card from 'components/Card/Card.jsx';
import { toastr } from 'react-redux-toastr';
import cardFlagsRepository from '../../../../repositories/CardFlags';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../../../contexts/auth';
import generic from 'assets/img/cardFlags/generic-card.png';
import {
  currency,
  percentage,
} from '../../../components/ToNormalize/ToNormalize';
import financialTaxCardRepository from '../../../../repositories/CardTaxes';
import { listCardFlags } from './listCardFlags';

const FinancialTaxCard = () => {
  const { companyId } = useAuth();
  const [reportData, setReportData] = useState([]);
  const [hasReportData, setHasReportData] = useState(true);
  const [resumeValues, setResumeValues] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [cardFlagsOptions, setCardFlagsOptions] = useState([]);
  const [selectedCardFlag, setSelectedCardFlag] = useState('');

  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const formatData = (data) => {
    const formattedData = data.map((item) => {
      const cardFlagValue =
        item.BillsToReceiveParcels[0].FormOfPayment.cardFlagTBand;

      let cardFlag = listCardFlags.find((flag) => flag.value === cardFlagValue);

      if (!cardFlag || cardFlagValue === '28' || !cardFlag.img) {
        cardFlag = { img: generic };
      }

      return {
        date: format(new Date(item.createdAt), 'dd/MM/yyyy'),
        saleCode: !!item.Sales
          ? item.Sales?.Code
          : item.BillsToReceiveSales[0]?.Sales?.Code,
        type: !!item.Sales
          ? item.Sales?.SalesType.Description
          : item.BillsToReceiveSales[0]?.Sales?.SalesType.Description,
        formOfPayment:
          item.BillsToReceiveParcels[0].FormOfPayment.TypeOfPayment,
        cardFlag: cardFlag ? cardFlag.img : null,
        paidValue: item.paidValue,
        fee: item.fee === null ? 0 : item.fee,
        feeValue: item.feeValue === null ? 0 : item.feeValue,
        receivedValue: item.paidValue - item.feeValue,
        licensePlateOrMaintenanceObject:
          item.Sales?.Vehicle?.License_Plate ||
          item.Sales?.Vehicle?.Maintenance_Object,
        modelVehicle: item.Sales?.Vehicle?.Model,
      };
    });

    const formattedResume = data.reduce(
      (acc, item) => {
        acc.totalReceivedValue =
          Number(acc.totalReceivedValue) +
          Number(item.paidValue - item.feeValue);
        acc.totalFeeValue = Number(acc.totalFeeValue) + Number(item.feeValue);
        return acc;
      },
      { totalReceivedValue: 0, totalFeeValue: 0 }
    );

    if (formattedData.length === 0) setHasReportData(false);

    return { formattedData, formattedResume };
  };

  const handleData = async () => {
    setTableLoading(true);
    try {
      const { data } = await financialTaxCardRepository.getCardTaxesByPeriod({
        companyId,
        initialDate,
        finalDate,
        selectedCardFlag: selectedCardFlag !== '' ? selectedCardFlag : '',
      });

      const { formattedData, formattedResume } = formatData(data);
      setReportData(formattedData);
      setTableData(formattedData);
      setResumeValues(formattedResume);
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const loadReportData = async () => {
    if (validations()) {
      handleData();
    }
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    return true;
  };

  const loadCardFlags = async () => {
    try {
      const response = await cardFlagsRepository.index({
        companyId,
      });

      const serializedCardFlags = response.data.map((item) => {
        const cardFlag = listCardFlags.find(
          (flag) => flag.value === item.tBand
        );

        return {
          label: item.flag,
          value: item.tBand,
          srcImg: cardFlag ? cardFlag.img : null,
        };
      });

      setCardFlagsOptions(serializedCardFlags);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'OS Digital',
        'Ocorreu um erro ao carregar as bandeiras. Por favor, tente novamente!'
      );
    }
  };

  useEffect(() => {
    loadCardFlags();
  }, []);

  return (
    <>
      <div className="content">
        <Grid fluid>
          <Row>
            <BreadCrumb
              data={['Inicio', 'Financeiro', 'Relatórios', 'Taxa de Cartão']}
              path={['Home', null, null, null]}
            />
          </Row>
        </Grid>
      </div>
      <Card
        content={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <header style={{ display: 'flex' }}>
              <section style={{ marginRight: '5px' }}>
                <label style={{ fontWeight: 'bold' }}>Data Inicial:</label>
                <input
                  type="date"
                  className="form-control foco-input"
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                />
              </section>
              <section style={{ marginRight: '5px' }}>
                <label style={{ fontWeight: 'bold' }}>Data Final:</label>
                <input
                  type="date"
                  className="form-control foco-input"
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </section>
              <section style={{ marginRight: '5px' }}>
                <label style={{ fontWeight: 'bold' }}>Bandeiras:</label>
                <select
                  className="form-control foco-input"
                  value={selectedCardFlag}
                  onChange={(e) => setSelectedCardFlag(e.target.value)}
                >
                  <option value="">Todas</option>
                  {cardFlagsOptions.map((cardFlag) => (
                    <option key={cardFlag.value} value={cardFlag.value}>
                      {cardFlag.label}
                    </option>
                  ))}
                </select>
              </section>
              <section style={{ marginTop: '38px', marginRight: '5px' }}>
                <button
                  className="btn btn-sucesso"
                  type="submit"
                  onClick={() => loadReportData()}
                  disabled={
                    (initialDate === '' && finalDate === '') || tableLoading
                  }
                >
                  <span
                    className={`${
                      tableLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                    }`}
                  />{' '}
                  Gerar relatório
                </button>
              </section>

              <div style={{ marginTop: '38px' }}>
                <DownloadXlsButton
                  archiveName={`TaxasCartao-${initialDate
                    .split('-')
                    .reverse()
                    .join('/')}-${finalDate.split('-').reverse().join('/')}`}
                  data={reportData.reverse()}
                  className="btn btn-export"
                  sortedBy="date"
                  disabled={tableLoading || !reportData.length}
                  columns={[
                    {
                      name: 'Data',
                      acessor: 'date',
                    },
                    {
                      name: 'Venda/OS',
                      acessor: 'saleCode',
                    },
                    {
                      name: 'Veículo',
                      acessor: (row) =>
                        `${
                          row.licensePlateOrMaintenanceObject
                            ? row.licensePlateOrMaintenanceObject
                            : ''
                        } ${
                          !row.licensePlateOrMaintenanceObject &&
                          !row.modelVehicle
                            ? ''
                            : '-'
                        } ${row.modelVehicle ? row.modelVehicle : ''}
                        `,
                    },
                    {
                      name: 'Tipo',
                      acessor: 'type',
                    },
                    {
                      name: 'Forma de pagamento',
                      acessor: 'formOfPayment',
                    },
                    {
                      name: 'Valor Pago',
                      acessor: 'paidValue',
                      Cell: ({ value }) => currency(value),
                    },
                    {
                      name: 'Taxa',
                      acessor: 'fee',
                      Cell: ({ value }) => percentage(value),
                    },
                    {
                      name: 'Valor da Taxa',
                      acessor: 'feeValue',
                      Cell: ({ value }) => currency(value),
                    },
                    {
                      name: 'Valor Recebido',
                      acessor: 'receivedValue',
                      Cell: ({ value }) => currency(value),
                    },
                  ]}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                  Exportar .xls
                </DownloadXlsButton>
              </div>
            </header>
            <main style={{ marginTop: '15px' }}>
              <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                data={tableData}
                columns={[
                  {
                    Header: 'Data',
                    accessor: 'date',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Venda/OS',
                    accessor: 'saleCode',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Veículo',
                    accessor: '',
                    headerClassName: 'text-center',
                    style: {
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto 0',
                    },
                    width: 160,
                    className: 'texto',
                    Cell: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <span>
                          {props.original &&
                          props.original.licensePlateOrMaintenanceObject
                            ? props.original.licensePlateOrMaintenanceObject
                            : ''}
                        </span>
                        <span style={{ textTransform: 'uppercase' }}>
                          <strong>
                            {props.original && props.original.modelVehicle
                              ? props.original.modelVehicle.slice(0, 20)
                              : ''}
                          </strong>
                        </span>
                      </div>
                    ),
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'type',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Forma de pagamento',
                    accessor: '',
                    headerClassName: 'text-center',
                    style: {
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto 0',
                    },
                    width: 150,
                    className: 'texto',
                    Cell: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>
                          {props.original && props.original.formOfPayment
                            ? props.original.formOfPayment
                            : ''}
                        </span>
                        <span>&nbsp;</span>
                        <img
                          src={
                            props.original && props.original.cardFlag
                              ? props.original.cardFlag
                              : ''
                          }
                          style={{
                            height: '20px',
                            width: '30px',
                          }}
                        />
                      </div>
                    ),
                  },
                  {
                    Header: 'Valor Pago',
                    accessor: 'paidValue',
                    headerClassName: 'text-left',
                    Cell: ({ value }) => currency(value),
                  },
                  {
                    Header: 'Taxa',
                    accessor: 'fee',
                    headerClassName: 'text-left',
                    Cell: ({ value }) => percentage(value),
                  },
                  {
                    Header: 'Valor da Taxa',
                    accessor: `feeValue`,
                    headerClassName: 'text-left',
                    Cell: ({ value }) => currency(value),
                  },
                  {
                    Header: 'Valor Recebido',
                    accessor: 'receivedValue',
                    headerClassName: 'text-left',
                    Cell: ({ value }) => currency(value),
                  },
                ]}
                defaultPageSize={10}
                sortable={false}
                loading={tableLoading}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText={
                  hasReportData
                    ? 'Insira um período e clique em Gerar Relatório para buscar as taxas do cartão'
                    : 'Nenhuma informação localizada'
                }
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </main>
            <div
              style={{
                marginTop: '10px',
              }}
            >
              <p
                style={{
                  textAlign: 'end',
                  color: 'red',
                  fontSize: '14px',
                }}
              >
                Total de taxa do período:{' '}
                {resumeValues.totalFeeValue
                  ? currency(resumeValues.totalFeeValue)
                  : currency(0)}
              </p>
              <p
                style={{
                  textAlign: 'end',
                  color: 'red',
                  fontSize: '14px',
                }}
              >
                Valor recebido no período:{' '}
                {resumeValues.totalReceivedValue
                  ? currency(resumeValues.totalReceivedValue)
                  : currency(0)}
              </p>
            </div>
          </div>
        }
      />
    </>
  );
};

export default FinancialTaxCard;
