import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { isValid, isBefore } from 'date-fns'

import { usePlanSignatureContext } from 'contexts/plan-signature'
import { useHistory } from 'react-router'
import { useAuth } from 'contexts/auth'

import salesReturnsRepository from 'repositories/SalesReturns'

import './styles.css'

const PeriodPicker = () => {
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [salesReturn, setSalesReturn] = useState([])

  const { isPlanFree, isPlanStart } = usePlanSignatureContext()
  const history = useHistory()
  const { companyId } = useAuth()

  useEffect(() => {
    if (isPlanFree || isPlanStart) history.goBack()
  }, [isPlanFree, isPlanStart, history])

  useEffect(() => {
    setSalesReturn([])
  }, [initialDate, finalDate])

  const handleSubmit = () => {
    if (validations()) handleProcess()
  }

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/sale-returns-by-period?initialDate=${initialDate}&finalDate=${finalDate}`
    )
  }

  const handleProcess = async () => {
    setLoading(true)
    try {
      const { data } = await salesReturnsRepository.reportByPeriod({
        initialDate,
        finalDate,
        companyId,
      })

      if (!data) {
        toastr.warning('OS Digital', 'Não há dados para o período selecionado')
      }

      setSalesReturn(data)
    } catch (err) {
      toastr.error(
        err.response?.data?.message ||
          'Ocorreu um erro ao buscar o histórico. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.')
      return false
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.')
      return false
    }

    return true
  }

  return (
    <main className="return-history-report">
      <section>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
        />
      </section>
      <section>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
        />
      </section>
      {!salesReturn?.length ? (
        <section>
          <button className="btn btn-sucesso" onClick={handleSubmit}>
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </section>
      ) : (
        <section>
          <button className="btn btn-sucesso" onClick={handlePrint}>
            Imprimir
          </button>
        </section>
      )}
    </main>
  )
}

export default PeriodPicker
