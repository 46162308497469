import React, { useState } from 'react';
import {
  faFaceFrown,
  faFaceMeh,
  faFaceMehBlank,
  faFaceSadTear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';
import './index.css';
import { toastr } from 'react-redux-toastr';
import PollsAnswerRepository from 'repositories/PollsAnswer';

const ModalMHS = ({
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  userId,
  pollId,
  companyId,
}) => {
  const [selectedMHS, setSelectedMHS] = useState(null);
  const [notes, setNotes] = useState('');

  async function sendNPSAnswer() {
    try {
      setIsLoading(true);
      await PollsAnswerRepository.create({
        notes,
        answer: selectedMHS,
        userId,
        pollId,
        companyId,
      });

      toastr.success(
        'Obrigado pela resposta!',
        'Sua opinião é muito importante para que possamos melhorar o sistema e nossa parceria.'
      );
    } catch (err) {
      toastr.error(
        'Erro ao enviar sua resposta',
        'Tentaremos lhe perguntar novamente mais tarde!'
      );
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  }

  return (
    <Modal show={isModalOpen} dialogClassName="nps-modal">
      <Modal.Body>
        <p>Como você se sentiria se não tivesse mais acesso ao OS Digital?</p>

        <div className="nvs-buttons-wrapper">
          <div>
            <FontAwesomeIcon
              color="#c62828"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedMHS !== null && selectedMHS !== 'Não uso o sistema'
                  ? 0.3
                  : 1
              }
              cursor="pointer"
              icon={faFaceMehBlank}
              onClick={() => setSelectedMHS('Não uso o sistema')}
            />
            <span>Não uso o sistema</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#ff8426"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedMHS !== null && selectedMHS !== 'Indiferente' ? 0.3 : 1
              }
              cursor="pointer"
              icon={faFaceMeh}
              onClick={() => setSelectedMHS('Indiferente')}
            />
            <span>Indiferente</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#ffcb21"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedMHS !== null && selectedMHS !== 'Um pouco desapontado'
                  ? 0.3
                  : 1
              }
              cursor="pointer"
              icon={faFaceFrown}
              onClick={() => setSelectedMHS('Um pouco desapontado')}
            />
            <span>Um pouco desapontado</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#36963b"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedMHS !== null && selectedMHS !== 'Muito desapontado'
                  ? 0.3
                  : 1
              }
              cursor="pointer"
              icon={faFaceSadTear}
              onClick={() => setSelectedMHS('Muito desapontado')}
            />
            <span>Muito desapontado</span>
          </div>
        </div>

        {selectedMHS !== null && (
          <textarea
            value={notes}
            placeholder="Muito obrigado pela resposta. Há algo que queira nos falar?"
            onChange={(e) => setNotes(e.target.value)}
          />
        )}

        <div className="nps-send-button">
          <CustomButton
            fill
            style={{
              background: '#5cb85c',
              borderColor: '#5cb85c',
            }}
            disabled={selectedMHS === null || isLoading}
            onClick={() => sendNPSAnswer()}
          >
            Enviar
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMHS;
