import { endOfMonth, format, startOfMonth } from 'date-fns';
import { isSubmitting } from 'redux-form';
import create from 'zustand';

import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { BillsToReceiveRepositoryV2 } from 'v2/repositories/BillsToReceiveRepository';

const useGroupTitlesModalStore = create((set, get) => ({
  isSubmitting: false,
  setIsSubmitting: (isSubmitting) => set({ isSubmitting }),

  initialDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  setInitialDate: (initialDate) => set({ initialDate }),

  finalDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  setFinalDate: (finalDate) => set({ finalDate }),

  currentStep: 1,
  setCurrentStep: (currentStep) => set({ currentStep }),

  closeTrigger: false,
  handleCloseTrigger: (value) => set({ closeTrigger: value }),

  openSubmitConfirmationModalTrigger: false,
  setOpenSubmitConfirmationModalTrigger: (
    openSubmitConfirmationModalTrigger
  ) => ({
    openSubmitConfirmationModalTrigger,
  }),

  selectedCustomer: null,
  sales: [],

  selectedSales: [],
  setSelectedSales: (selectedSales) => set({ selectedSales }),

  feeValue: 0,
  setFeeValue: (feeValue) => set({ feeValue }),

  isSaleSelected: (saleId) => {
    const { selectedSales } = get();
    return selectedSales.some((sale) => sale.id === saleId);
  },

  installments: [],
  setInstallments: (installments) => set({ installments }),
  getSalesTotal: () => {
    const { selectedSales } = get();
    return selectedSales.reduce((prev, curr) => prev + curr.Final_Value, 0);
  },
  getSelectedSalesIds: () => {
    const { selectedSales } = get();
    return selectedSales.map((sale) => sale.id);
  },
  getSerializedInstallments: () => {
    const { installments } = get();

    return installments.map((installment) => {
      return {
        cashierBankId: installment.cashierBank?.value?.id,
        amount: installment.value,
        formOfPaymentId: installment.formOfPayment?.value?.id,
        dueDate: installment.date,
        received: installment.received,
        paidValue: installment.received ? installment.value : 0,
        openValue: !installment.received ? installment.value : 0,
      };
    });
  },
  setSelectedCustomer: (selectedCustomer) => set({ selectedCustomer }),
  setSales: (sales) => set({ sales }),
  reset: () =>
    set(() => ({
      isSubmitting: false,
      initialDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      finalDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
      currentStep: 1,
      openSubmitConfirmationModal: false,
      selectedCustomer: null,
      sales: [],
      selectedSales: [],
      installments: [],
      total: 0,
      feeValue: 0,
    })),
}));

export default useGroupTitlesModalStore;
