import api from '../services/api'
import config from '../config'

const getCompanyModules = (queryParams) =>
  api.get(config.endpoint + 'company-modules', {
    params: queryParams,
  })

const updateModuleStatus = (
  companyId,
  moduleId,
  status,
  isVerification,
  quantity
) =>
  api.put(config.endpoint + `company-modules/${companyId}`, {
    moduleId,
    status,
    isVerification,
    quantity,
  })

const companyModulesRepository = {
  getCompanyModules,
  updateModuleStatus,
}

export default companyModulesRepository
