import React, { useState } from 'react';
import { BankConciliationDetailsContainer } from './BankConciliationDetails.styles';
import { generateBankConciliationDetails } from '../../utils/bankConciliationUtils';
import { BankConciliationDetailsTable } from './components/BankConciliationDetailsTable';
import { BankConciliationFinancialRegister } from './components/BankConciliationFinancialRegisterTables/BankConciliationFinancialRegister';
import { FormProvider, useForm } from 'react-hook-form'; // Alterar para usar useFormContext
import { BankConciliationDetailsSearch } from './components/BankConciliationDetailsSearch/BankConciliationDeatilsSearch';
import { HeaderBankConciliationDetails } from './components/HeaderBankConciliationDetails/HeaderBankConciliationDetails';
import { useBankConciliationStore } from 'v2/store/bankConciliation';
import { BankConciliationDetailsFooterActions } from './components/BankConciliationDetailsFooterActions/BankConciliationDetailsFooterActions';
import { useParams } from 'react-router-dom';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';
import { format } from 'date-fns';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';

export function BankConciliationDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { companyId } = useAuth();

  const { dataOFX, setFileAndData } = useBankConciliationStore((store) => {
    return {
      dataOFX: store.bankConciliation.dataOFX,
      selectedBank: store.bankConciliation.selectedBank,
      setFileAndData: store.setFileAndData,
    };
  });

  const methods = useForm({
    defaultValues: {
      informationFile: {
        data: dataOFX?.data,
      },
      search: {
        details: {
          description: '',
          transactionType: 'Ambos',
          transactionStatus: 'Ambos',
          TransactionInitialDate: '',
          TransactionFinishDate: '',
        },
        financialRegister: {
          typeFinancialRegister: 'titulo',
          description: '',
          transactionType: 'Ambos',
          transactionStatus: 'Ambos',
          TransactionInitialDate: '',
          TransactionFinishDate: '',
        },
        bankConciliation: {
          initialDate: '',
          finalDate: '',
          selectedUserOptions: '',
          type: 'conciliation',
          selectedBankOptions: '',
        },
      },
      optionsBanks: [],
    },
    isViewing: true,
  });

  const selectedTransactions = methods.watch('dataFinancialRegister');

  const prefix = 'details';

  React.useEffect(() => {
    methods.setValue('informationFile.data', dataOFX);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleLoadingViewData = async () => {
    setIsLoading(true);
    try {
      const data = await BankConciliationRepositoryV2.getById(id, companyId);
      const serializedData = {
        headerFile: data.headerFile,
        isValid: true,
        transactions: data.transactions.map((transaction) => ({
          id: transaction.id,
          amount: transaction.amount,
          checkNumber: transaction.checkNumber,
          conciled: transaction.conciled,
          datePosted: format(new Date(transaction.datePosted), 'MM/dd/yyyy'),
          memo: transaction.memo,
          transactionId: transaction.transactionId,
          type: transaction.type,
        })),
      };
      const finantialRegisterSerialized = {
        count: data.bills.length,
        data: data.bills,
      };
      const finantialMovementsSerialized = {
        count: data.movements.length,
        data: data.movements,
      };
      methods.setValue('informationFile.data', serializedData);
      methods.setValue(
        'selectedTransactions',
        serializedData.transactions.filter(
          (transaction) => transaction.conciled
        )
      );

      methods.setValue('dataFinancialRegister', finantialRegisterSerialized);
      methods.setValue('selectedTransactionsConciliation', [
        ...data.bills,
        ...data.movements,
      ]);
      methods.setValue('dataFinancialMovements', finantialMovementsSerialized);
      setFileAndData(null, serializedData, {
        id: serializedData.headerFile.cashierBankId,
        code: serializedData.headerFile.bankCode,
        label: `${serializedData.headerFile.bankCode} - ${serializedData.headerFile.bankName}`,
      });
    } catch (error) {
      if (error.message)
        toastr.error('Erro ao carregar os dados da conciliação', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (id) {
      methods.setValue('isViewing', true);
      handleLoadingViewData();
    } else {
      setIsLoading(false);
      methods.setValue('isViewing', false);
    }
  }, [id]);

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <LoadingSpinnerFullHeight />
      ) : (
        <BankConciliationDetailsContainer>
          <HeaderBankConciliationDetails transactionsDetails={dataOFX || []} />
          <BankConciliationDetailsSearch
            prefix={prefix}
            isFinancialRegister={false}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <BankConciliationDetailsTable prefix={prefix} />
          </div>
          <BankConciliationFinancialRegister
            selectedTransactions={selectedTransactions}
          />
          <BankConciliationDetailsFooterActions
            onCancel={() => {
              history.goBack();
            }}
            /*  isSelectedBank={isSelectedBank} */
          />
        </BankConciliationDetailsContainer>
      )}
    </FormProvider>
  );
}
