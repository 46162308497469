import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from 'client/components/CustomButton/CustomButton'
import { useFormContext } from 'react-hook-form'

export default function NewVoucherPaymentConfirmationModal({
  handleClose,
  handleSubmit,
}) {
  const { setValue } = useFormContext()

  return (
    <Modal show={true} onHide={handleClose} dialogClassName="modal-25w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Criação de Pagamento</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Clique na opção desejada:</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15px',
            marginBottom: '15px',
            gap: '15px',
          }}
        >
          <Button
            bsStyle="primary"
            fill
            style={{
              backgroundColor: '#428bca',
              borderColor: '#428bca',
              width: '228px',
            }}
            onClick={() => {
              setValue('status', 'Finalizada')
              handleSubmit()
            }}
          >
            Finalizar e Realizar Pagamento
          </Button>
          <Button
            bsStyle="primary"
            fill
            style={{
              backgroundColor: '#5cb85c',
              borderColor: '#5cb85c',
              width: '228px',
            }}
            onClick={() => handleSubmit()}
          >
            Salvar em aberto
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
