import { useAuth } from 'contexts/auth';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import VideoLinkRegisterForm from './VideoLinkRegisterForm';
import VideosLinksRepository from '../../../../../repositories/VideosLinks';
import { useAdminAuth } from 'contexts/adminAuth';
import { useEffect } from 'react';

function VideoLinkRegisterMain({ videosLinksId, onSubmit, onCancel }) {
  const { admin } = useAdminAuth();

  useEffect(() => {
    if (videosLinksId) {
      loadVideosLinksToUpdate(videosLinksId);
    }
  }, []);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      brandingId: '',
      route: '',
      title: '',
      link: '',
      adminId: admin.id,
    },
  });

  const validations = (values) => {
    const { brandingId, route, title, link } = values;

    if (!title) {
      toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos com dados válidos e tente novamente'
      );
      return false;
    }

    const checkBlankSpace = (string) => string.trim().length === 0;

    if (!brandingId) {
      toastr.warning(
        'Produto inválido.',
        'Selecione um produto e tente novamente'
      );
      return false;
    }

    if (checkBlankSpace(title)) {
      toastr.warning(
        'Insira uma descrição válida para cadastrar o seu vídeo e tente novamente.'
      );
      return false;
    }
    if (checkBlankSpace(link)) {
      toastr.warning(
        'Insira um link válido para cadastrar o seu vídeo e tente novamente.'
      );
      return false;
    }
    if (checkBlankSpace(route)) {
      toastr.warning(
        'Insira uma rota válida para cadastrar o seu vídeo e tente novamente.'
      );
      return false;
    }

    const isValidURL = (string) => {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i' // fragment locator
      );
      return !!pattern.test(string);
    };

    if (!isValidURL(link)) {
      toastr.warning('Insira um link válido para o video e tente novamente.');
      return false;
    }

    return true;
  };

  const submit = (values) => {
    values.brandingId = values.brandingId;
    values.route = values.route.trim();
    values.title = values.title.trim();
    values.link = values.link.trim();

    if (!validations(values)) {
      return;
    }

    !!videosLinksId ? update(values) : create(values);
  };

  const create = async (values) => {
    try {
      await VideosLinksRepository.create({
        ...values,
      });

      toastr.success(
        'Vídeo vinculado com sucesso.',
        'O vídeo será exibido toda vez que o cliente clicar em vídeo na tela informada'
      );
      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Não foi possível salvar o cadastro',
        err.response?.data?.message ??
          'Ocorreu um erro ao registrar o link do video. Por favor, tente novamente'
      );
    }
  };

  const loadVideosLinksToUpdate = async (videosLinksId) => {
    try {
      const videosLinksToUpdate = await VideosLinksRepository.getById(
        videosLinksId
      );

      setValue('brandingId', videosLinksToUpdate.brandingId);
      setValue('route', videosLinksToUpdate.route);
      setValue('title', videosLinksToUpdate.title);
      setValue('link', videosLinksToUpdate.link);
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar o link do video. Por favor, tente novamente'
      );
    }
  };

  const update = async (values) => {
    try {
      await VideosLinksRepository.update(videosLinksId, {
        ...values,
      });
      toastr.success(
        'Vídeo atualizado com sucesso.',
        'O vídeo será exibido toda vez que o cliente clicar em vídeo na tela informada'
      );
      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao atualizar o vídeo. Por favor, tente novamente'
      );
    }
  };

  return (
    <VideoLinkRegisterForm
      onSubmit={submit}
      videosLinksId={videosLinksId}
      register={register}
      watch={watch}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isDirty={isDirty}
      onCancel={onCancel}
      getValues={getValues}
      control={control}
    />
  );
}

export default VideoLinkRegisterMain;
