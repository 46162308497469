import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import {
  InputContainer,
  InputLabel,
  InputSelectDefault,
} from 'v2/components/Input';
import { InputPassword } from 'v2/components/Input/InputPassword';
import { useEffect } from 'react';
import userRepository from 'repositories/Users';
import { validateUser } from 'v2/repositories/AuthenticationRepository';
import { useDispatch } from 'react-redux';
import { handleConfirmItemWithDiscountGreater } from 'client/views/Sales/NewSale/FormSale/redux/actions';

export function ModalDiscountReleaseWithPassword({
  open,
  onClose,
  items,
  itemToEdit,
  itemToEditIndex,
  isAllowedDiscountGreater,
  onAuthenticationCancel,
}) {
  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    label: 'Selecione um usuário',
    value: '',
  });
  const [password, setPassword] = useState('');
  const [users, setUsers] = useState([]);

  async function loadCompanyUsers(companyId) {
    setLoading(true);
    try {
      const companyUsers = await userRepository.getByCompanyId(companyId);

      setUsers(
        companyUsers.map((user) => ({
          value: user,
          label: user.name,
        }))
      );
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os usuários, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadCompanyUsers(companyId);
    setPassword('');
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await validateUser({
        email: selectedUser.value.email,
        password,
        companyId,
        subFeatureId: 118,
      });

      if (response === 'OK') {
        handleConfirmItemWithDiscountGreater(
          itemToEditIndex,
          itemToEdit,
          items,
          isAllowedDiscountGreater
        )(dispatch);

        onClose();
      }
    } catch (err) {
      console.log(err);
      if (
        err.response.data.error === 'User is not allowed for this operation'
      ) {
        if (onAuthenticationCancel) {
          onAuthenticationCancel();
        }
        toastr.error(
          'Não permitido',
          'Usuário não possui permissão para desconto maior que o limite.'
        );
        handleClose();
        return;
      }
      if (err.response.status === 401) {
        toastr.error(
          'Senha inválida',
          'A senha informada não corresponde com a senha do usuário.'
        );
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setSelectedUser({ label: 'Selecione um usuário', value: '' });
    setPassword('');

    itemToEdit.Discount_Value = 0;
    itemToEdit.Amount = itemToEdit.Unit_Value;

    if (onAuthenticationCancel) {
      onAuthenticationCancel();
    }
    onClose();
  };

  return (
    <>
      <Modal show={open} onHide={handleClose} dialogClassName="modal-35w">
        <Modal.Header>
          <Modal.Title>
            <strong>Liberação de desconto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Insira o usuário e senha de acesso:</span>
          <br />
          <br />

          <div className="flex column center">
            <div className="flex column center">
              <InputContainer variant="column">
                <InputLabel htmlFor="input_email" required>
                  Usuário:
                </InputLabel>
                <InputSelectDefault
                  style={{ fontSize: '12px' }}
                  width={'300px'}
                  height={'35px'}
                  options={users}
                  value={selectedUser}
                  defaultValue={{
                    label: 'Selecione',
                    value: '',
                  }}
                  fontSize={'12px'}
                  onChange={(e) => setSelectedUser(e)}
                />
              </InputContainer>
            </div>
            <div className="flex column center">
              <InputContainer>
                <InputLabel htmlFor="input_password" required>
                  Senha:
                </InputLabel>
                <InputPassword
                  id="input_password"
                  maxLength={18}
                  width="300"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  showPasswordPreviewIcon={false}
                  autocomplete="off"
                />
              </InputContainer>
            </div>
            <p
              style={{
                fontSize: '11px',
                color: '#d9534f',
                textAlign: 'center',
              }}
            >
              Ao acessar, será permitido o desconto para o item em questão.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 flex end gap-050">
            <button
              style={{ height: '38px' }}
              className="button button-red"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button
              style={{ height: '38px' }}
              className="button button-green"
              onClick={handleSubmit}
              disabled={loading}
            >
              Permitir Desconto
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
