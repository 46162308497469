import styled from 'styled-components';

export const SelectContainer = styled.div`
  padding-top: 40px;
`;

export const ViewConditionsHyperlink = styled.span`
  position: relative;
  top: -30px;
  right: 118px;

  & button {
    all: unset;
    color: ${({ theme }) => theme.colors.branding1000};
    font-size: 12px;
  }

  & button:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
