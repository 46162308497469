import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import SuivCompanyFormMain from './SuivCompanyFormMain'

import constants from 'utils/constants'

export default function SuivCompanyForm({ history }) {
  const suivCompanyToEdit = history.location?.state?.suivCompany
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Integrações',
              'SUIV',
              suivCompanyToEdit ? 'Editar Empresa' : 'Nova Empresa',
            ]}
            path={[constants.ROUTES.HOME, null, 'suiv', null]}
          />
          <span style={{ color: 'red', position: 'absolute', right: 10 }}>
            * Campos Obrigatórios
          </span>
          <SuivCompanyFormMain suivCompany={suivCompanyToEdit} />
        </Row>
      </Grid>
    </div>
  )
}
