import React, { useState, useEffect } from 'react'
import { reduxForm } from 'redux-form'
import Button from '../../../../../client/components/CustomButton/CustomButton.jsx'
import CardForm from '../../../../../client/components/CardForm'
import InvoiceData from './InvoiceData'
import Provider from './Provider'
import Items from './Items'

import Footer from './Footer'

import './styles.css'

const FormPurchases = ({
  handleSubmit,
  cancel,
  loading,
  titleId,
  disableInputs,
  typePurchase,
}) => {
  const [invoiceData, setInvoiceData] = useState(true)
  const [provider, setProvider] = useState(false)
  const [items, setItems] = useState(false)

  return (
    <form
      onSubmit={handleSubmit}
      onCancel={cancel}
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm
        show={invoiceData}
        title="Dados da Nota"
        onClick={() => setInvoiceData(!invoiceData)}
      >
        <InvoiceData titleId={titleId} disableInputs={disableInputs} />

        <Button
          id="btn-prosseguir"
          bsStyle="info"
          pullRight
          fill
          onClick={() => {
            setInvoiceData(false)
            setProvider(true)
            setItems(false)
          }}
        >
          Prosseguir
        </Button>
      </CardForm>

      <CardForm
        show={provider}
        title="Fornecedor"
        onClick={() => setProvider(!provider)}
      >
        <Provider
          loading={loading}
          name="purchases"
          disableInputs={disableInputs}
        />
        <Button
          id="btn-prosseguir"
          bsStyle="info"
          pullRight
          fill
          style={{ marginTop: '35px' }}
          onClick={() => {
            setInvoiceData(false)
            setProvider(false)
            setItems(true)
          }}
        >
          Prosseguir
        </Button>
      </CardForm>

      <CardForm show={items} title="Itens" onClick={() => setItems(!items)}>
        <Items
          loading={loading}
          name="purchases"
          disableInputs={disableInputs}
          typePurchase={typePurchase}
        />
      </CardForm>

      <Footer
        handleSubmit={handleSubmit}
        handleCancel={cancel}
        loading={loading}
        disableInputs={disableInputs}
      />
    </form>
  )
}

export default reduxForm({
  form: 'purchases',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormPurchases)
