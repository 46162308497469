import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../components/CustomButton/Button';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import {
  cnpjMask,
  cpfMask,
  phoneMask,
} from '../../../../../components/ToNormalize/ToNormalize';
import customersRepository from 'v2/repositories/CustomerRepository';
import useFilters from '../../../../../../hooks/useFilters';
import { useAuth } from '../../../../../../contexts/auth';
import SearchInput from 'components/SearchInput';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import AlertModal from 'components/AlertModal/AlertModal';
import useDebounce from 'hooks/useDebounce';

const ClientModal = ({ onHide }) => {
  const [loading, setLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');

  const { companyId } = useAuth();

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [type, setType] = useState('');
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const [pagesQuantity, setPagesQuantity] = useState(1);
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    if (companyId) {
      loadCustomers();
    }
  }, [companyId, page, type, limit, queryDebounced]);

  const loadCustomers = async () => {
    setLoading(true);

    try {
      const customers = await customersRepository.index({
        companyId,
        page: page + 1,
        limit,
        query,
        type,
      });

      setCustomers(customers.serializedCustomers);
      setPagesQuantity(Math.ceil(customers.count / limit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os clientes. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleModal = (id) => {
    setCustomerId(id);
    setModalConfirm(true);
  };

  const handleClient = async () => {
    const client = customers.find((c) => c.id === customerId);
    if (!!client) {
      await dispatch(change('pdvOnline', 'customerId', customerId));
    }
    setModalConfirm(false);
    onHide();
  };

  return (
    <>
      <Modal dialogClassName="modal-pdv-client" show animation>
        <Modal.Header>
          <Modal.Title>
            <strong>Busca de Cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="reviewHeader"
            >
              <div
                className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
                style={{ marginLeft: '0px' }}
              >
                <SearchInput
                  placeholder="Pesquisa por Nome, CPF/CNPJ e Placa"
                  value={query}
                  onChange={(e) => handleChangeQuery(e.target.value)}
                  style={{ height: '35px', fontSize: '14px' }}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <label>
                  <strong>Tipo:</strong>
                </label>
                <select
                  className="form-control foco-input"
                  onChange={(event) => setType(event.target.value)}
                  value={type}
                >
                  <option value="">Ambos</option>
                  <option value="Fisica">Física</option>
                  <option value="Juridica">Jurídica</option>
                </select>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                data={customers}
                columns={[
                  {
                    Header: 'CPF/CNPJ',
                    accessor: 'Cpf_Cnpj',
                    width: 140,
                    Cell: (props) =>
                      props.value.length < 14
                        ? cpfMask(props.value)
                        : cnpjMask(props.value),
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'Type',
                    width: 100,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Fisica' ? 'blue' : 'purple',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Nome/Razão Social',
                    accessor: 'Company_Name',
                  },
                  {
                    Header: 'Telefone',
                    accessor: 'phone',
                    width: 140,
                    Cell: (props) => phoneMask(props.value),
                  },
                  {
                    Header: '',
                    accessor: 'id',
                    width: 120,
                    filterable: false,
                    Cell: (props) => (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{
                          color: '#5cb85c',
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleModal(props.value)}
                      />
                    ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination
                sortable
                showPaginationTop={false}
                showPaginationBottom
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'Company_Name',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum cliente encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                pages={pagesQuantity}
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setLimit(value)}
                manual
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-modal-client">
            <button className="danger" onClick={onHide}>
              Voltar
            </button>
          </div>
        </Modal.Footer>
        <AlertModal
          show={modalConfirm}
          message="Deseja adicionar o cliente na tela de PDV? O cliente poderá ser removido da venda, se necessário."
          onHide={() => setModalConfirm(false)}
          onCancel={() => setModalConfirm(false)}
          onSubmit={() => handleClient()}
        />
      </Modal>
    </>
  );
};

export default withRouter(ClientModal);
