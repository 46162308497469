import React from 'react';

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Advertisements from './Advertisements';

const HomeAdvertisements = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Propaganda']}
        path={['home', null]}
      />
      <Advertisements />
    </>
  );
};

export default HomeAdvertisements