import React, { useState, useEffect } from "react";
import { useAuth } from "contexts/auth";
import { toastr } from "react-redux-toastr";
import constants from "../../../../../utils/constants";
import { useHistory } from "react-router-dom";
import FormNewLinkedService from "./FormNewLinkedService";
import { useDispatch } from "react-redux";
import { initialize } from "redux-form";

import linkedServiceRepository from "../../../../../repositories/LinkedService";
import productsRepository from "../../../../../repositories/Products";

const initialValues = {};

const BundleFormLinkedService = ({
  productId,
  product,
  modal,
  handleModal,
  hideModal,
}) => {
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const submit = (values) => {
    if (!values.serviceId) {
      return toastr.warning(
        "Por favor, adicione um serviço vinculado para salvar"
      );
    }
    return create(values);
  };

  const create = async (values) => {
    try {
      const { serviceId } = values;
      await linkedServiceRepository.create({
        companyId,
        serviceId,
        productId,
      });

      if (modal) {
        toastr.success("Serviço vinculado com sucesso.");
        return handleModal(true);
      }
      return toastr.success("Serviço Vinculado adicionado com sucesso.");
    } catch (err) {
      return toastr.warning(
        "Ocorreu um erro ao adicionar o serviço vinculado."
      );
    }
  };

  return (
    <FormNewLinkedService
      modal={modal}
      hideModal={hideModal}
      product={product}
      onSubmit={submit}
      initialValues={initialValues}
      loading={loading}
    />
  );
};

export default BundleFormLinkedService;
