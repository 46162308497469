import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import Button from '../../../../../client/components/CustomButton/CustomButton.jsx'

const ImportedSATCertificate = ({ onUpload }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) return
    onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.pfx',
  })
  const { satCertificate, isSatCertificateImported } = useSelector(
    (state) => state.form.settingsNFCe.values
  )

  return (
    <Button
      style={{
        background: '#428bca',
        border: '0',
        position: 'relative',
        top: '20px',
        right: '-5px',
        height: '36px',
      }}
      fill
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p
        style={{
          fontSize: '14px',
        }}
      >
        Importar certificado para vincular ao SAT{' '}
        {(satCertificate || isSatCertificateImported) && (
          <FontAwesomeIcon icon={faCheck} color="green" />
        )}
      </p>{' '}
    </Button>
  )
}

export default ImportedSATCertificate
