const validate = (values) => {
  const errors = {};
  if (!values.Name) {
    errors.Name = '';
  } else if (values.Name.trim().length < 2) {
    errors.Name = 'Nome menor que 2 caracteres';
  }
  if (!values.Phone) {
    errors.Phone = '';
  } else if (values.Phone.length < 10) {
    errors.Phone = 'Número pequeno para um Telefone';
  }
  if (!values.Email) {
    errors.Email = '';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
    errors.Email = 'E-mail inválido';
  } /*else if (values.emailDuplicado == true) {
        errors.Email = 'Existe uma conta cadastrada com esse e-mail. Faça o login ou recupere a senha'
    }*/
  if (!values.Password) {
    errors.Password = '';
  } else if (values.Password.length < 6) {
    errors.Password = 'Senha menor que 6 carácteres!';
  }
  if (!values.ConfirmPassword) {
    errors.ConfirmPassword = '';
  } else if (values.Password != values.ConfirmPassword) {
    errors.ConfirmPassword = 'As senhas não coincidem, por favor, verifique.';
  }

  if (!values.Segment) {
    errors.Segmento = 'Você precisa selecionar ao menos 1 segmento';
  }
  if (!values.RamoAtuacao) {
    errors.Ramo = 'Você precisa selecionar 1 ramo';
  } else if (values.RamoAtuacao !== 'Auto Peça') {
    if (!values.Servicos) {
      errors.ServicosError = 'Você precisa selecionar ao menos 1 serviço';
    }
  }
  return errors;
};

export default validate;
