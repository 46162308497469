import Button from 'client/components/CustomButton/Button'
import { format } from 'date-fns'
import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize'
import React from 'react'
import ReactTable from 'react-table'

const CustomerListTable = ({
  customers,
  isLoading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {

  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 15,
          fontSize: '12px',
        }}
        data={customers}
        columns={[
          {
            Header: 'CPF/CNPJ',
            accessor: 'customerCpfCnpj',
            width: 175,
            className: 'texto',
            Cell: (props) => cpfOrCnpjMask(props.value)
          },
          {
            Header: 'Tipo',
            accessor: 'customerType',
            width: 90,
            Cell: (props) => (
              <Button
                id='botaoReactTable'
                style={{
                  backgroundColor:
                    props.value === 'Fisica' ? '#0d45a4' : '#5cb85c',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Nome/Razão Social',
            accessor: 'customerName',
            className: 'texto',
            width: 270,
          },
          {
            Header: 'Telefone',
            accessor: 'customerPhone',
            className: 'texto',
            width: 100,
            Cell: (props) => phoneMask(props.value)
          },
          {
            Header: 'Cidade',
            accessor: 'customerCity',
            className: 'texto',
            width: 100,
          },
          {
            Header: 'UF',
            accessor: 'customerState',
            className: 'texto',
            width: 50,
          },
          {
            Header: 'Email',
            accessor: 'customerEmail',
            className: 'texto',
            width: 250,
          },
          {
            Header: 'Dt. Cadast.',
            accessor: 'customerDate',
            className: 'texto',
            width: 100,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Status',
            accessor: 'customerStatus',
            className: 'texto',
            width: 100,
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={isLoading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'Company_Name',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum cliente encontrado."
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default CustomerListTable
