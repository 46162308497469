import React from 'react';
import Button from 'client/components/CustomButton/CustomButton.jsx';

const NewPromotionalPriceFormButtons = ({
  onCancel,
  onSubmit,
  hasId,
  loading,
  disabledSubmit,
}) => {
  return (
    <div className="form-submit-buttons">
      <button
        className="button button-red button-h35"
        disabled={loading}
        onClick={onCancel}
      >
        {hasId ? 'Voltar' : 'Cancelar'}
      </button>
      <button
        className="button button-green button-h35"
        onClick={onSubmit}
        disabled={loading || disabledSubmit}
      >
        <span
          className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          style={{ marginRight: loading ? '5px' : '' }}
        />
        Salvar
      </button>
    </div>
  );
};

export default NewPromotionalPriceFormButtons;
