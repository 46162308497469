import React, { useEffect, useState, useMemo } from 'react';
import CollapsibleSection from 'components/CollapsibleSection';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { DefaultContentContainer } from 'v2/components/layout';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { InputContainer } from 'v2/components/Input';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer } from './FormGroupItem.styles';
import { formSchema, getBreadcrumbData } from './utils';
import { toastr } from 'react-redux-toastr';
import { GroupItemRepository } from 'v2/repositories/GroupItemsRepository';
import { getGroupItem } from 'v2/repositories/GroupItemsRepository';

export function FormGroupItem({
  hideBreadCrumb,
  hideActionButtons,
  groupItemIdProp,
  triggerSubmit,
}) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { groupItemId } = useParams();

  const groupItemIdValue = useMemo(() => {
    return groupItemIdProp || groupItemId;
  }, [groupItemIdProp, groupItemId]);

  const isEditing = !!groupItemIdValue;
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      id: null,
      description: '',
    },
  });

  const handleChangeGroupItemInput = (e) => {
    if (e.target.value.startsWith(' ')) {
      e.preventDefault();
      e.target.value = e.target.value.slice(1);
    }
    if (
      e.nativeEvent.dataTransfer &&
      e.nativeEvent.dataTransfer.getData('text')
    ) {
      e.preventDefault();
    }
    setValue('description', e.target.value);
  };

  const handleErrors = () => {
    if (
      errors.description &&
      errors.description.message === 'description is a required field'
    ) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
    }
  };

  const handleCreate = async (data) => {
    setIsLoading(true);
    try {
      const res = await GroupItemRepository.createGroupItem({
        ...data,
        companyId,
      });

      if (res && res.id) {
        toastr.success(
          'Grupo criado com sucesso',
          'O grupo já pode ser usado nos checklists.'
        );
      }

      navigateToClientPage('group_items', 'base');
    } catch (err) {
      console.log(err);
      if (err.response.data.message === 'This groupItem already exists.') {
        toastr.warning(
          'Não foi possível salvar',
          'Já existe um grupo com o mesmo nome. Verifique e tente novamente.'
        );
      } else {
        toastr.error(
          'Erro na operação',
          'Por favor, tente novamente. Caso persista, contate o suporte.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    setIsLoading(true);
    try {
      await GroupItemRepository.updateGroupItem(data.id, {
        description: data.description,
        companyId,
      });
      toastr.success(
        'Grupo alterado com sucesso',
        'O grupo já pode ser usado nos checklists.'
      );
      navigateToClientPage('group_items', 'base');
    } catch (err) {
      console.log(err);
      if (err.response.data.message === 'This groupItem already exists.') {
        toastr.warning(
          'Não foi possível salvar',
          'Já existe um grupo com o mesmo nome. Verifique e tente novamente.'
        );
      } else {
        toastr.error(
          'Erro na operação',
          'Por favor, tente novamente. Caso persista, contate o suporte.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    return isEditing ? handleUpdate(data) : handleCreate(data);
  };

  const handleLoadFields = async () => {
    setIsLoading(true);
    try {
      const res = await getGroupItem(groupItemIdValue, companyId);
      if (!res) throw 'not found';

      setValue('id', res.id);
      setValue('description', res.description);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para edição',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
      navigateToClientPage('group_items', 'base');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (groupItemIdValue) {
      handleLoadFields();
    }
  }, [groupItemIdValue]);

  useEffect(() => {
    handleErrors();
  }, [errors]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit(submitForm)();
    }
  }, [triggerSubmit]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel={!hideBreadCrumb}>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados do Grupo"
          isExpanded={true}
          handleExpand={() => null}
        >
          <form onSubmit={handleSubmit(submitForm)}>
            <FormContainer>
              <div className="row-1">
                <InputContainer>
                  <InputLabel htmlFor="input__description" required>
                    Descrição:
                  </InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={50}
                    width="400"
                    {...register('description')}
                    onChange={handleChangeGroupItemInput}
                  />
                </InputContainer>
              </div>
              <div className="row-1">
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: '#D9534F',
                  }}
                >
                  Essa informação será exibida no impresso de checklist
                  personalizado para organizar os itens.
                </span>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>
      </div>
      {!hideActionButtons && (
        <div className="flex align-center end gap-075 mt-2">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={() => navigateToClientPage('group_items', 'base')}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            type="submit"
            style={{ width: '100px' }}
            onClick={handleSubmit(submitForm)}
            disabled={isLoading}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      )}
    </DefaultContentContainer>
  );
}
