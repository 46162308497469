import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { reduxForm, change, Field } from 'redux-form'
import RenderField from 'components/RenderField'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import ReactTable from 'react-table'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'
import ItemsModal from './ItemsModal'
import { useDispatch, useSelector } from 'react-redux'
import AlertModal from 'components/AlertModal/AlertModal'
import { onlyNumbersCommaAndDot } from 'client/components/ToNormalize/ToNormalize'

function Items({ disabled }) {
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false)
  const [productIndex, setProductIndex] = useState(null)
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false)

  const { itemsStock, status, observation } = useSelector(
    (state) => state.form.cadastroStock.values
  )

  const dispatch = useDispatch()

  function handleChangeQuantity(value, index) {
    const newItemsStock = [...itemsStock]
    const item = newItemsStock[index]
    item.quantity = value

    newItemsStock[index] = item
    dispatch(change('cadastroStock', 'itemsStock', newItemsStock))

    setTimeout(() => {
      document.getElementById(`itemsStock[${index}].quantity`).focus()
    }, 5)
  }

  function handleAppendItems(items) {
    const newItems = [...itemsStock]

    for (var item of items) {
      const index = newItems.findIndex(
        (child) => child.productId === item.productId
      )

      if (index !== -1) {
        newItems[index].quantity++
      } else {
        const newItem = {
          ...item,
        }
        newItems.push(newItem)
      }
    }

    dispatch(change('cadastroStock', 'itemsStock', newItems))
    setIsItemsModalOpen(false)
  }

  function handleOpenRemoveItemModal(index) {
    if (!disabled) {
      setProductIndex(index)
      setIsRemoveItemModalOpen(true)
    }
  }

  function handleRemoveItem() {
    setIsRemoveItemModalOpen(false)
    const newItems = [...itemsStock].filter((_, i) => i !== productIndex)

    dispatch(change('cadastroStock', 'itemsStock', newItems))
  }

  return (
    <div>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px', marginBottom: '20px' }}
      >
        <Col lg={2} md={2} sm={2} xs={2} style={{ marginTop: '35px' }}>
          <Button
            type='button'
            className={
              status === 'Cancelada' ? 'btn btn-cancelado' : 'btn btn-sucesso'
            }
            bsStyle='info'
            fill
            onClick={() => setIsItemsModalOpen(true)}
            disabled={disabled}
          >
            + Adicionar Item
          </Button>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <Field
            name='movementType'
            label='Tipo:'
            component={RenderField}
            disabled={status === 'Cancelada' || status === 'Finalizada'}
            as='select'
          >
            <option value='Entrada'>Entrada</option>
            <option value='Saída'>Saída</option>
          </Field>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <Field
            name='registerDate'
            label='Data de Criação:'
            component={RenderField}
            disabled={true}
          />
        </Col>
        {status === 'Finalizada' && (
          <Col lg={2} md={2} sm={2} xs={2}>
            <Field
              name='finishedDate'
              label='Data de Finalização:'
              component={RenderField}
              disabled={true}
            />
          </Col>
        )}

        {status === 'Cancelada' && (
          <>
            <Col lg={2} md={2} sm={2} xs={2}>
              <Field
                name='finishedDate'
                label='Data de Finalização:'
                component={RenderField}
                disabled={true}
              />
            </Col>

            <Col lg={3} md={3} sm={3} xs={3}>
              <Field
                name='canceledDate'
                label='Data de Cancelamento:'
                component={RenderField}
                style={{ width: '60%' }}
                disabled={true}
              />
            </Col>
          </>
        )}
      </Col>
      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={6} md={6} sm={4} xs={4}>
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '10px',
            }}
            data={itemsStock}
            columns={[
              {
                Header: 'Código',
                accessor: 'code',
                headerClassName: 'text-left',
                width: 70,
              },
              {
                Header: 'Descrição',
                accessor: 'description',
                headerClassName: 'text-left',
                width: 320,
              },
              {
                Header: 'Quantidade',
                accessor: 'quantity',
                headerClassName: 'text-left',
                width: 90,
                Cell: (props) => (
                  <Field
                    id={`itemsStock[${props.index}].quantity`}
                    name={`itemsStock[${props.index}].quantity`}
                    component={RenderField}
                    normalize={onlyNumbersCommaAndDot}
                    min={0}
                    onChange={(e) =>
                      handleChangeQuantity(e.target.value, props.index)
                    }
                    disabled={disabled}
                  />
                ),
              },
              {
                Header: '',
                accessor: 'Actions',
                headerClassName: 'text-left',
                className: 'NFe-items-table-actions',
                filterable: false,
                width: 30,
                Cell: (props) => (
                  <>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color={status === 'Cancelada' ? '#585757' : 'red'}
                      style={{ cursor: disabled ? 'not-allowed' : 'cursor' }}
                      onClick={() => handleOpenRemoveItemModal(props.index)}
                      disabled={disabled}
                    />
                  </>
                ),
              },
            ]}
            defaultPageSize={5}
            showPagination={true}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            defaultSorted={[
              {
                id: 'createdAt',
                desc: true,
              },
            ]}
            previousText='Anterior'
            nextText='Próximo'
            loadingText='Carregando...'
            noDataText='Clique em "+ Adicionar Item" para adicionar itens.'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
          />
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} style={{}}>
          <label>Observações</label>
          <Field
            style={{ outline: '0' }}
            component={RenderField}
            as='textarea'
            name='observation'
            disabled={status === 'Finalizada' || status === 'Cancelada'}
            style={{ height: '150px' }}
          />
        </Col>
      </Col>
      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        title='Exclusão de item'
        message='Deseja excluir o item da movimentação de estoque?'
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />

      {isItemsModalOpen && (
        <ItemsModal
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAppendItems}
        />
      )}
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroStock',
})(withRouter(Items))
