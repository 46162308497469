import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// import AccessPermissionsRepository from 'v2/repositories/AccessPermissionsRepository';
// import { AccessPermissionsRow } from 'v2/components/AccessPermissions/AccessPermissionsRow';
// import { AccessPermissionsTab } from 'v2/components/AccessPermissions/AccessPermissionsTab';
import renderField from '../../../../../components/RenderField';
import './styles.css';

const ProfileMainData = () => {
  // const { companyId } = useSelector(
  //   (state) => state.form.formPermissionsAccess.values
  // );
  // const [items, setItems] = useState({});
  // const [groupFeatures, setGroupFeatures] = useState([]);
  // const [activeItem, setActiveItem] = useState('Vendas');
  // const formMethods = useForm({
  //   defaultValues: {
  //     profileId: '',
  //     status: true,
  //     groupFeatures: [
  //       {
  //         id: '',
  //         description: '',
  //         lineNumber: '',
  //         status: true,
  //         mainFeatures: [
  //           {
  //             id: '',
  //             description: '',
  //             visible: '',
  //             lineNumber: '',
  //             status: true,
  //             subFeatures: [
  //               {
  //                 id: '',
  //                 description: '',
  //                 lineNumber: '',
  //                 status: true,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // });

  // const { register, control, setValue, getValues } = formMethods;

  // useEffect(() => {
  //   loadItems();
  // }, []);

  // async function loadItems() {
  //   try {
  //     const response = await AccessPermissionsRepository.getAll();
  //     const data = response.data;

  //     setItems(data);
  //     setGroupFeatures(data.groupFeatures);
  //   } catch (err) {
  //     console.error(err);
  //     if (!groupFeatures) {
  //       toastr.warning(
  //         'Ocorreu um erro ao buscar os itens do menu. Por favor, tente novamente.'
  //       );
  //     }
  //   }
  // }

  // const handleSwitchChange = (path, e) => {
  //   const checked = e.target.checked;
  //   setValue(path, checked);

  //   if (path === 'status') {
  //     // Se o switch "Acesso Total" for ativado, ative todos os outros switches
  //     if (checked) {
  //       groupFeatures.forEach((item, i) => {
  //         setValue(`groupFeatures.mainFeatures.${i}.status`, true);
  //         if (Array.isArray(item.subFeatures)) {
  //           item.subFeatures.forEach((subFeature, j) => {
  //             setValue(
  //               `groupFeatures.mainFeatures.${i}.subFeatures.${j}.status`,
  //               true
  //             );
  //           });
  //         }
  //       });
  //     } else {
  //       groupFeatures.forEach((item, i) => {
  //         setValue(`groupFeatures.mainFeatures.${i}.status`, false);
  //         if (Array.isArray(item.subFeatures)) {
  //           item.subFeatures.forEach((subFeature, j) => {
  //             setValue(
  //               `groupFeatures.mainFeatures.${i}.subFeatures.${j}.status`,
  //               false
  //             );
  //           });
  //         }
  //       });
  //     }
  //   } else if (path.includes('mainFeatures')) {
  //     // Se um switch "Todo o módulo" for ativado, ative todos os seus switches filhos
  //     if (checked) {
  //       const mainFeatureId = path.split('.')[2];
  //       setValue(
  //         `groupFeatures.mainFeatures.${mainFeatureId}.subFeatures`,
  //         groupFeatures.map((item) => ({ ...item, status: true }))
  //       );
  //     } else {
  //       // Se um switch "Todo o módulo" for desativado, desative o switch "Acesso Total"
  //       setValue('status', false);
  //     }
  //   } else if (path.includes('subFeatures')) {
  //     // Se um switch filho for desativado, desative o switch "Todo o módulo" correspondente e o switch "Acesso Total"
  //     if (!checked) {
  //       const mainFeatureId = path.split('.')[2];
  //       setValue(`groupFeatures.mainFeatures.${mainFeatureId}.status`, false);
  //       setValue('status', false);
  //     }
  //   }
  // };

  // const tabContent = (groupFeatures ?? []).reduce((acc, item) => {
  //   acc[item.description] = (
  //     <div>
  //       <div>
  //         {
  //           <Controller
  //             name={`groupFeatures.${item.id}.status`}
  //             control={control}
  //             defaultValue={false}
  //             render={({ field }) => (
  //               <AccessPermissionsRow
  //                 width={'100%'}
  //                 title={`Todo o módulo de ${item.description}`}
  //                 subTitle={
  //                   'Inclui todas as funções disponíveis e as futuras. Funções não disponíveis no plano permanecerão inativas.'
  //                 }
  //                 checked={field.value}
  //                 onChange={(e) =>
  //                   handleSwitchChange(`groupFeatures.${item.id}.status`, e)
  //                 }
  //                 disabledToggle={!!!companyId}
  //                 visible={true}
  //                 style={{ marginBottom: '20px' }}
  //               />
  //             )}
  //           />
  //         }
  //       </div>
  //       {(item.mainFeatures ?? []).map((mainFeature) => (
  //         <div key={mainFeature.id}>
  //           {mainFeature.visible === true && (
  //             <div>
  //               {
  //                 <Controller
  //                   name={`groupFeatures.mainFeatures.${mainFeature.id}`}
  //                   control={control}
  //                   defaultValue={false}
  //                   render={({ field }) => (
  //                     <AccessPermissionsRow
  //                       width={'100%'}
  //                       title={`Acesso a ${mainFeature.description}`}
  //                       checked={field.value}
  //                       onChange={(e) =>
  //                         handleSwitchChange(
  //                           `groupFeatures.mainFeatures.${mainFeature.id}.status`,
  //                           e
  //                         )
  //                       }
  //                       disabledToggle={!!!companyId}
  //                       visible={true}
  //                     />
  //                   )}
  //                 />
  //               }
  //             </div>
  //           )}
  //           {(mainFeature.subFeatures ?? []).map((subFeature, subIndex) => (
  //             <div key={subFeature.id}>
  //               <Controller
  //                 name={`groupFeatures.mainFeatures.${mainFeature.id}.subFeatures.${subFeature.id}.status`}
  //                 control={control}
  //                 defaultValue={false}
  //                 render={({ field }) => (
  //                   <AccessPermissionsRow
  //                     width={'100%'}
  //                     title={
  //                       mainFeature.visible === false
  //                         ? `Acesso a ${subFeature.description}`
  //                         : subFeature.description
  //                     }
  //                     checked={field.value}
  //                     onChange={(e) =>
  //                       handleSwitchChange(
  //                         `groupFeatures.mainFeatures.${mainFeature.id}.subFeatures.${subFeature.id}.status`,
  //                         e
  //                       )
  //                     }
  //                     disabledToggle={!!!companyId}
  //                     visible={!mainFeature.visible}
  //                     style={
  //                       subIndex === (mainFeature.subFeatures ?? []).length - 1
  //                         ? { marginBottom: '20px' }
  //                         : {}
  //                     }
  //                   />
  //                 )}
  //               />
  //             </div>
  //           ))}
  //         </div>
  //       ))}
  //     </div>
  //   );
  //   return acc;
  // }, {});

  return (
    <div style={{ width: '100%' }}>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className="accessProfile" style={{ width: '20%' }}>
          <Field
            label="Perfil de Acesso:"
            name="description"
            component={renderField}
            style={{ fontWeight: 'normal' }}
            disabled
          />
        </div>
        <Controller
          name="status"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <AccessPermissionsRow
              width={'80%'}
              title={'Acesso Total'}
              subTitle={
                'Acesso irrestrito à todos os módulos, funções e ações, inclusive as lançadas no futuro.'
              }
              checked={field.value}
              onChange={(e) => handleSwitchChange('status', e)}
              disabledToggle={!!!companyId}
              visible={true}
            />
          )}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '30px',
        }}
      >
        <AccessPermissionsTab
          items={groupFeatures}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          tabContent={tabContent}
        />
      </div> */}
    </div>
  );
};

export default ProfileMainData;
