import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import config from '../config';
import api from '../services/api';

const getAllByCompany = async (companyId) => {
  const response = await api.get(
    config.endpoint + `cashierbankhistoric?companyId=${companyId}`
  );
  return response.data;
};

const create = async (values) => {
  const response = await api.post(
    config.endpoint + 'cashierbankhistoric',
    values
  );
  return response.data;
};

const show = async (id) => {
  const response = await api.get(config.endpoint + `cashierbankhistoric/${id}`);
  return response.data;
};

const close = async (id, values) => {
  await api.put(config.endpoint + `cashierbankhistoric/${id}/close`, values);
};

const getCloseCashierReportByCashierBankHistoric = async (id) => {
  const decryptedId = await decrypt(
    id,
    '@OS-dig:cashierBankHistoricIdToReport'
  );
  const response = await api.get(`cashierbankhistoric/${decryptedId}/report`);
  return response.data;
};

const getCloseCashierBankInfo = async (cashierBankHistoricId) => {
  const response = await api.get(
    `cashierbankhistoric/${cashierBankHistoricId}/values/form-of-payment`
  );
  return response.data;
};

const closeCashierConferece = async (values) => {
  const response = await api.post(`cashier-conference`, values);
  return response.data;
};

export default {
  getAllByCompany,
  create,
  show,
  close,
  getCloseCashierReportByCashierBankHistoric,
  getCloseCashierBankInfo,
  closeCashierConferece,
};
