import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useState } from 'react';

const AnalyticalDocument = ({ responseData }) => {
  const [headerData] = useState(responseData.header);
  const [totalsData] = useState(responseData.totals);
  const [reportData] = useState(responseData.report);

  return (
    <Document
      title={`impresso-analytical-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '35%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Listagem de Notas Fiscais</Text>
          </View>
          <Header data={headerData} />
        </View>
        <TotalizatorsHeader data={totalsData} />
        <InvoicesListing data={reportData} />
      </Page>
    </Document>
  );
};

function FormatPercentage(number) {
  return (number * 100).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
}

function FormatType(invoices) {
  return invoices
    .replace(/Contingencia/g, 'Contingência')
    .replace(/NFCeSAT/g, 'NFC-e/SAT')
    .replace(/NFe/g, 'NF-e')
    .replace(/NFSe/g, 'NFS-e');
}

function FormatCFOP(cfop) {
  return cfop ? `${cfop[0]}.${cfop.slice(1)}` : '';
}

const Header = ({ data }) => {
  return (
    <View style={{ width: '65%', flexDirection: 'row' }}>
      <View
        style={{
          flexDirection: 'column',
          width: '35%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Relatório: </Text>
          </View>
          <View>
            <Text>{data.reportType}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Nota: </Text>
          </View>
          <View>
            <Text>{FormatType(data.invoiceType)}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'column',
          width: '65%',
          marginBottom: '5px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={{ marginRight: '5px' }}>
            <Text>{format(parseISO(data.initialDate), 'dd/MM/yyyy')}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text>{format(parseISO(data.finalDate), 'dd/MM/yyyy')}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Status: </Text>
          </View>
          <View>
            <Text>{FormatType(data.invoiceStatus)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TotalizatorsHeader = ({ data }) => {
  return (
    <>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Totais por Tipo/Status
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '8%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>QTD</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>
      <View style={styles.line} />

      {data &&
        data.byInvoiceType.map((element, index) => (
          <TotalizatorsByInvoiceType key={index} element={element} />
        ))}

      <View style={[styles.line, { width: '70%' }]} />

      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '18%' }]}>Total</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>
          {data.totalizator.quantity}
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>
          {currency(data.totalizator.amount)}
        </Text>
      </View>

      <View style={[styles.line, { width: '40%' }]} />

      {data.byStatus.map((element, index) => (
        <TotalizatorsByInvoiceStatus key={index} element={element} />
      ))}
    </>
  );
};

const TotalizatorsByInvoiceType = ({ key, element }) => {
  return (
    <View
      key={key}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'left',
      }}
    >
      <View style={{ width: '8%' }}>
        <Text style={styles.text}>{FormatType(element.invoiceType)}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{element.status}</Text>
      </View>
      <View style={{ width: '5%' }}>
        <Text style={styles.text}>{element.quantity}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{currency(element.total)}</Text>
      </View>
    </View>
  );
};

const TotalizatorsByInvoiceStatus = ({ key, element }) => {
  return (
    <View
      key={key}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'left',
      }}
    >
      <View style={{ width: '18%' }}>
        <Text style={styles.text}>{element.status}</Text>
      </View>
      <View style={{ width: '5%' }}>
        <Text style={styles.text}>{element.quantity}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{currency(element.total)}</Text>
      </View>
    </View>
  );
};

const InvoicesListing = ({ data }) => {
  return (
    <>
      <NFCeSatListing NFCeSatData={data?.nfceSat ? data.nfceSat : []} />
      <NFeListing NFeData={data?.nfe ? data.nfe : []} />
      <NFSeListing NFSeData={data?.nfse ? data.nfse : []} />
    </>
  );
};

const NFCeSatListing = ({ NFCeSatData }) => {
  const serializedNFCeSatData =
    NFCeSatData?.length > 0
      ? NFCeSatData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                ''
              ).slice(0, 30) +
              ((item.customerCompanyName || item.customerTradingName || '')
                ?.length > 30
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    serializedNFCeSatData?.length > 0 && (
      <>
        <View style={[styles.line, { marginTop: '15px' }]} />
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Listagem de NFC-e/SAT
        </Text>
        <NFCeSatGeneral NFCeSatGeneralData={serializedNFCeSatData} />
      </>
    )
  );
};
const NFCeSatGeneral = ({ NFCeSatGeneralData }) => {
  return (
    <>
      {NFCeSatGeneralData.map((data, index) => (
        <>
          <View style={[styles.line, { width: '100%' }]} />
          <View style={{ width: '90%', flexDirection: 'column' }} key={index}>
            <View style={{ flexDirection: 'column', margin: '2px 0' }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Número: </Text>
                  </View>
                  <View>
                    <Text>{data.code}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '30%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Série: </Text>
                  </View>
                  <View>
                    <Text>{data.serie}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '40%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Tipo: </Text>
                  </View>
                  <View>
                    <Text>{data.type}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '55%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Status: </Text>
                  </View>
                  <View>
                    <Text>{data.status}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Criação: </Text>
                  </View>
                  <View>
                    <Text>
                      {data.createdAt &&
                        format(new Date(data.createdAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Emissão: </Text>
                  </View>
                  <View>
                    <Text>
                      {(data.status === 'Emitida' ||
                        data.status === 'Contingencia') &&
                        format(new Date(data.issuedAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '25%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Venda/OS: </Text>
                  </View>
                  <View>
                    <Text>{data.saleCode}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '55%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Cliente: </Text>
                  </View>
                  <View>
                    <Text>{data.companyNameOrTradingName}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Valor: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.total)}</Text>
                  </View>
                </View>
              </View>
            </View>
            <NFCeSatItemsTitle />
            <NFCeSatItemsContent NFCeSatItems={data.items} />
          </View>
        </>
      ))}
    </>
  );
};
const NFCeSatItemsTitle = () => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View style={{ flexDirection: 'column', margin: '2px 0' }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Text style={[styles.boldText, { width: '20%' }]}>Código</Text>
          <Text style={[styles.boldText, { width: '60%' }]}>Descrição</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>Quantidade</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Unit.</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Total</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>% ICMS</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>CFOP</Text>
        </View>
      </View>
    </>
  );
};
const NFCeSatItemsContent = ({ NFCeSatItems }) => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View
        style={{
          flexDirection: 'column',
          marginTop: '2px',
          marginBottom: '10px',
        }}
      >
        {NFCeSatItems.map((item, index) => (
          <>
            <View key={index}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'left',
                }}
              >
                <Text style={[styles.text, { width: '20%' }]}>{item.code}</Text>
                <Text style={[styles.text, { width: '60%' }]}>
                  {item.description}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {item.quantity}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.unitValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.totalValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {FormatPercentage(item.icms)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {FormatCFOP(item.cfop)}
                </Text>
              </View>
            </View>
          </>
        ))}
      </View>
    </>
  );
};

const NFeListing = ({ NFeData }) => {
  return (
    NFeData?.length > 0 && (
      <>
        <View style={[styles.line, { marginTop: '15px' }]} />
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Listagem de NF-e
        </Text>
        <NFeGeneral NFeGeneralData={NFeData} />
      </>
    )
  );
};
const NFeGeneral = ({ NFeGeneralData }) => {
  const serializedNFeData =
    NFeGeneralData?.length > 0
      ? NFeGeneralData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                item.providerCompanyName ||
                item.providerTradingName ||
                ''
              ).slice(0, 30) +
              ((
                item.customerCompanyName ||
                item.customerTradingName ||
                item.providerCompanyName ||
                item.providerTradingName ||
                ''
              )?.length > 30
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    <>
      {serializedNFeData.map((data, index) => (
        <>
          <View style={[styles.line, { width: '100%' }]} />
          <View style={{ width: '90%', flexDirection: 'column' }} key={index}>
            <View style={{ flexDirection: 'column', margin: '2px 0' }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Número: </Text>
                  </View>
                  <View>
                    <Text>{data.code}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '110px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Série: </Text>
                  </View>
                  <View>
                    <Text>{data.serie}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '115px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Status: </Text>
                  </View>
                  <View>
                    <Text>{data.status}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '150px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Criação: </Text>
                  </View>
                  <View>
                    <Text>
                      {data.createdAt &&
                        format(new Date(data.createdAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '150px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Emissão: </Text>
                  </View>
                  <View>
                    <Text>
                      {(data.status === 'Emitida' ||
                        data.status === 'Contingencia') &&
                        format(new Date(data.issuedAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    /* width: '50%', */
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Nat. Op.: </Text>
                  </View>
                  <View>
                    <Text>{data.CFOP}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Venda/OS: </Text>
                  </View>
                  <View>
                    <Text>{data.saleCode}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '225px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Cliente: </Text>
                  </View>
                  <View>
                    <Text>{data.companyNameOrTradingName}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '150px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Valor: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.total)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '140px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Frete: </Text>
                  </View>
                  <View>
                    <Text>
                      {currency(data.freightValue ? data.freightValue : 0)}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Out. Desp.: </Text>
                  </View>
                  <View>
                    <Text>
                      {currency(data.otherExpenses ? data.otherExpenses : 0)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>BC ICMS: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.BCICMS ? data.BCICMS : 0)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '110px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>ICMS: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.ICMS ? data.ICMS : 0)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '115px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>IPI: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.IPIValue ? data.IPIValue : 0)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    /* width: '15%', */
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Desc: </Text>
                  </View>
                  <View>
                    <Text>
                      {currency(data.discountValue ? data.discountValue : 0)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <NFeItemsTitle />
            <NFeItemsContent NFeItems={data.items} />
          </View>
        </>
      ))}
    </>
  );
};
const NFeItemsTitle = () => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View style={{ flexDirection: 'column', margin: '2px 0' }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Text style={[styles.boldText, { width: '20%' }]}>Código</Text>
          <Text style={[styles.boldText, { width: '60%' }]}>Descrição</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>Quantidade</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Unit.</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Total</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>% ICMS</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ ICMS</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>CFOP</Text>
        </View>
      </View>
    </>
  );
};
const NFeItemsContent = ({ NFeItems }) => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View
        style={{
          flexDirection: 'column',
          marginTop: '2px',
          marginBottom: '10px',
        }}
      >
        {NFeItems.map((item, index) => (
          <>
            <View key={index}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'left',
                }}
              >
                <Text style={[styles.text, { width: '20%' }]}>{item.code}</Text>
                <Text style={[styles.text, { width: '60%' }]}>
                  {item.description}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {item.quantity}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.unitValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.totalValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {FormatPercentage(item.icms)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {FormatPercentage(item.icmsValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {FormatCFOP(item.cfop)}
                </Text>
              </View>
            </View>
          </>
        ))}
      </View>
    </>
  );
};

const NFSeListing = ({ NFSeData }) => {
  const serializedNFSeData =
    NFSeData?.length > 0
      ? NFSeData.map((item) => {
          return {
            ...item,
            companyNameOrTradingName:
              (
                item.customerCompanyName ||
                item.customerTradingName ||
                ''
              ).slice(0, 30) +
              ((item.customerCompanyName || item.customerTradingName || '')
                ?.length > 30
                ? '...'
                : ''),
          };
        })
      : [];

  return (
    serializedNFSeData?.length > 0 && (
      <>
        <View style={[styles.line, { marginTop: '15px' }]} />
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Listagem de NFS-e
        </Text>
        <NFSeGeneral NFSeGeneralData={serializedNFSeData} />
      </>
    )
  );
};
const NFSeGeneral = ({ NFSeGeneralData }) => {
  return (
    <>
      {NFSeGeneralData.map((data, index) => (
        <>
          <View style={[styles.line, { width: '100%' }]} />
          <View style={{ width: '90%', flexDirection: 'column' }} key={index}>
            <View style={{ flexDirection: 'column', margin: '2px 0' }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Número: </Text>
                  </View>
                  <View>
                    <Text>{data.code}</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: '30%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Série: </Text>
                  </View>
                  <View>
                    <Text>{data.serie}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '50%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Status: </Text>
                  </View>
                  <View>
                    <Text>{data.status}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Criação: </Text>
                  </View>
                  <View>
                    <Text>
                      {data.createdAt &&
                        format(new Date(data.createdAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Data de Emissão: </Text>
                  </View>
                  <View>
                    <Text>
                      {(data.status === 'Emitida' ||
                        data.status === 'Contingencia') &&
                        format(new Date(data.updatedAt), 'dd/MM/yyyy')}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '15%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Venda/OS: </Text>
                  </View>
                  <View>
                    <Text>{data.saleCode}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '25%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Cliente: </Text>
                  </View>
                  <View>
                    <Text>{data.companyNameOrTradingName}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '20%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>Valor: </Text>
                  </View>
                  <View>
                    <Text>{currency(data.total)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    width: '30%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <View>
                    <Text style={styles.strong}>CNAE: </Text>
                  </View>
                  <View>
                    <Text>{data.CNAE}</Text>
                  </View>
                </View>
              </View>
            </View>
            <NFSeItemsTitle />
            <NFSeItemsContent NFSeItems={data.items} />
          </View>
        </>
      ))}
    </>
  );
};
const NFSeItemsTitle = () => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View style={{ flexDirection: 'column', margin: '2px 0' }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Text style={[styles.boldText, { width: '20%' }]}>Código</Text>
          <Text style={[styles.boldText, { width: '60%' }]}>Descrição</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>Quantidade</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Unit.</Text>
          <Text style={[styles.boldText, { width: '20%' }]}>R$ Total</Text>
        </View>
      </View>
    </>
  );
};
const NFSeItemsContent = ({ NFSeItems }) => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View
        style={{
          flexDirection: 'column',
          marginTop: '2px',
          marginBottom: '15px',
        }}
      >
        {NFSeItems.map((item, index) => (
          <>
            <View key={index}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'left',
                }}
              >
                <Text style={[styles.text, { width: '20%' }]}>{item.code}</Text>
                <Text style={[styles.text, { width: '60%' }]}>
                  {item.description}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {item.quantity}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.unitValue)}
                </Text>
                <Text style={[styles.text, { width: '20%' }]}>
                  {currency(item.totalValue)}
                </Text>
              </View>
            </View>
          </>
        ))}
      </View>
    </>
  );
};

export default AnalyticalDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
