import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { phoneMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';

const Header = ({
  reportType,
  titleStatus,
  customer,
  cashierBank,
  includeCardFee,
  dateType,
  initialDate,
  finalDate,
}) => (
  <View style={{ width: '78%', flexDirection: 'row' }}>
    <View style={{ flexDirection: 'column', width: '23%' }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Relatório: </Text>
        </View>
        <View>
          <Text>{reportType === 'synthetic' ? 'Sintético' : 'Analítico'}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Data: </Text>
        </View>
        <View>
          <Text>
            {dateType === 'dueDate'
              ? 'Vencimento'
              : dateType === 'createdAt'
              ? 'Criação'
              : 'Pagamento'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '36%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Data Início: </Text>
        </View>
        <View style={{ marginRight: '5px' }}>
          <Text>
            {format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>Data Fim: </Text>
        </View>
        <View>
          <Text>
            {format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Status: </Text>
        </View>
        <View>
          <Text>
            {titleStatus === 'open'
              ? 'Aberto - Todos'
              : titleStatus === 'overdue'
              ? 'Aberto - Vencidos'
              : 'Finalizados'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '20%',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Cliente: </Text>
        </View>
        <View>
          <Text>{customer}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Banco/Caixa: </Text>
        </View>
        <View>
          <Text>{cashierBank === '' ? 'Todos' : cashierBank}</Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '21%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Incluir Taxa do Cartão: </Text>
        </View>
        <View>
          <Text>{includeCardFee === 'true' ? 'Sim' : 'Não'}</Text>
        </View>
      </View>
    </View>
  </View>
);

const AccountsReceivableAnalyticalClient = ({
  reportType,
  titleStatus,
  customer,
  cashierBank,
  includeCardFee,
  responseData,
  displayNotes,
  initialDate,
  finalDate,
  dateType,
}) => {
  return (
    <Document
      title={`Relatorio-de-Contas-a-Receber${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '22%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Títulos a Receber</Text>
          </View>

          <Header
            reportType={reportType}
            titleStatus={titleStatus}
            customer={customer}
            cashierBank={cashierBank}
            includeCardFee={includeCardFee}
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={dateType}
          />
        </View>

        <ReceivedAccountsValues
          totalizatorsByPeriod={responseData[0].totalsByPeriod}
          data={responseData}
          displayNotes={displayNotes}
          dateType={dateType}
          includeCardFee={includeCardFee}
          titleStatus={titleStatus}
        />
      </Page>
    </Document>
  );
};

const ReceivedTitle = ({ customerName, customerCpfCnpj, customerPhone }) => (
  <View>
    <View style={[styles.line]} />
    <View style={[styles.containerRow, { marginTop: '4px' }]}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          CPF/CNPJ:
        </Text>
        <Text style={[styles.text, { width: '14%' }]}>
          {customerCpfCnpj ? cnpjMask(customerCpfCnpj) : ''}
        </Text>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Razão Social/Nome:
        </Text>
        <Text style={[styles.text, { width: '33%' }]}>{customerName}</Text>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Telefone:
        </Text>
        <Text style={[styles.text, { width: '22%' }]}>
          {customerPhone ? phoneMask(customerPhone) : ''}
        </Text>
      </View>
    </View>

    <View style={{ marginTop: '8px' }}></View>
  </View>
);

const ReceivedAccountsValuesTitle = ({ dateType }) => (
  <View>
    <View style={[styles.line]} />
    <View>
      <Text style={[styles.title, { fontSize: '10px', marginTop: '8px' }]}>
        {dateType === 'dueDate'
          ? 'Vencimento'
          : dateType === 'createdAt'
          ? 'Criação'
          : 'Pagamento'}
      </Text>
    </View>
  </View>
);

const ReceivedAccountsValuesTableRow = ({
  dueDate,
  titles,
  displayNotes,
  total,
  includeCardFee,
  titleStatus,
}) => {
  return (
    <>
      <View>
        <View>
          <Text
            style={{
              width: '8%',
              fontSize: '8px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
            }}
          >
            {format(new Date(getDateOnlyFromDate(dueDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        {titles.map((title) => (
          <View>
            <View style={styles.containerRow}>
              <Text style={[styles.boldText, { width: '3%' }]}></Text>
              <Text
                style={{
                  width: '5%',
                  fontSize: '8px',
                }}
              >
                {title.titleCode}
              </Text>
              <Text style={{ width: '8%', fontSize: '8px' }}>
                {title.saleCode}
              </Text>
              <Text
                style={{
                  width: '5%',
                  fontSize: '8px',
                }}
              >
                {title.nfCode}
              </Text>
              <Text
                style={{
                  width: '6%',
                  fontSize: '8px',
                }}
              >
                {title.daysOverdue}
              </Text>
              <Text style={{ width: '15%', fontSize: '8px' }}>
                {title.customer}
              </Text>
              <Text
                style={{
                  width: '7%',
                  fontSize: '8px',
                }}
              >
                {title.dischargeDate
                  ? format(
                      new Date(getDateOnlyFromDate(title.dischargeDate)),
                      'dd/MM/yyyy'
                    )
                  : '' || '/    /'}
              </Text>
              <Text style={{ width: '11%', fontSize: '8px' }}>
                {title.cashierBank}
              </Text>
              <Text style={{ width: '6%', fontSize: '8px' }}>
                {currency(title.grossValue)}
              </Text>
              <Text style={{ width: '5%', fontSize: '8px' }}>
                {currency(title.addedValue)}
              </Text>
              <Text style={{ width: '5%', fontSize: '8px' }}>
                {currency(title.monthlyInterestValue)}
              </Text>
              <Text style={{ width: '5%', fontSize: '8px' }}>
                {currency(title.discountValue)}
              </Text>
              {includeCardFee === 'true' && (
                <Text style={{ width: '7%', fontSize: '8px' }}>
                  {currency(title.feeValue)}
                </Text>
              )}
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.liquidValue)}
              </Text>
              <Text style={{ width: '5%', fontSize: '8px' }}>
                {currency(title.paidValue)}
              </Text>
            </View>

            <View style={styles.containerRow}>
              <Text style={{ width: '3%' }}></Text>
              <View
                style={{
                  width: '15%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    { width: '20%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  Conta:
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { width: '80%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  {title.accountPlan}
                </Text>
              </View>
              <Text style={{ width: '9%' }}></Text>
              <View
                style={{
                  width: '14%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    { width: '40%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  Telefone:
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { width: '60%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  {phoneMask(title.customerPhone)}
                </Text>
              </View>
              {displayNotes ? (
                <View
                  style={{
                    width: '14%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={[
                      styles.boldText,
                      { width: '40%', fontSize: '7px', fontStyle: 'italic' },
                    ]}
                  >
                    Observações:
                  </Text>
                  <Text
                    style={[
                      styles.boldText,
                      { width: '60%', fontSize: '7px', fontStyle: 'italic' },
                    ]}
                  >
                    {title.observations}
                  </Text>
                </View>
              ) : (
                <View />
              )}
            </View>
          </View>
        ))}

        <View style={[styles.containerRow, { marginTop: '2px' }]}>
          <Text style={{ width: '83%' }}></Text>
          <View
            style={{
              width: '17%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={[styles.boldText]}>
              {titleStatus === 'finished'
                ? 'Saldo recebido do dia: '
                : 'Saldo a receber do dia: '}
            </Text>
            <Text style={[styles.boldText]}>{currency(total)}</Text>
          </View>
        </View>
      </View>
    </>
  );
};
const ReceivedAccountsValuesTableHeader = ({ includeCardFee }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '3%' }]}></Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Título</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Venda</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>NF</Text>
        <Text style={[styles.boldText, { width: '6%' }]}>Atraso</Text>
        <Text style={[styles.boldText, { width: '15%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Dt. Baixa</Text>
        <Text style={[styles.boldText, { width: '11%' }]}>Banco</Text>
        <Text style={[styles.boldText, { width: '6%' }]}>R$ Bruto</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Acresc.</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Juros</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Desc.</Text>
        {includeCardFee === 'true' && (
          <Text style={[styles.boldText, { width: '7%' }]}>Tx. Cartão</Text>
        )}
        <Text style={[styles.boldText, { width: '7%' }]}>R$ Líquido</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Pago</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  );
};
const ReceivedAccountsValuesTotal = ({
  titlesQuantity,
  totalToReceive,
  titleStatus,
}) => (
  <View style={styles.containerRow}>
    <Text style={[styles.boldText, { width: '23%' }]}>
      Qtd Títulos: <Text style={[styles.boldText]}>{titlesQuantity}</Text>
    </Text>
    <Text style={[styles.boldText]}>
      {titleStatus === 'finished' ? 'Saldo Recebido: ' : 'Saldo a Receber: '}
      <Text style={[styles.boldText]}>{currency(totalToReceive)}</Text>
    </Text>
  </View>
);

const TotalizatorByPeriod = ({ totalizatorsByPeriod, titleStatus }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Valores totais do período
        </Text>
      </View>
      <View style={[styles.line, { marginBottom: '2px' }]} />
      {totalizatorsByPeriod.rows.map((item) => (
        <View style={{ width: '100%' }}>
          <View style={styles.containerRow}>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'left',
                width: '10%',
              }}
            >
              {item.description}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {currency(item.value)}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {item.quantity}
            </Text>
          </View>
        </View>
      ))}
      <View style={[styles.line, { width: '75%', marginTop: '2px' }]} />
      <View style={{ width: '100%', marginTop: '2px' }}>
        <View style={styles.containerRow}>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'left',
              width: '11%',
            }}
          >
            {titleStatus === 'finished'
              ? 'Saldo Recebido: '
              : 'Saldo a Receber: '}
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'center',
              width: '10%',
            }}
          >
            {currency(totalizatorsByPeriod.balanceReceivable)}
          </Text>
        </View>
        <View
          style={[
            styles.line,
            { width: '50%', marginTop: '2px', marginBottom: '30px' },
          ]}
        />
      </View>
    </View>
  );
};

const ReceivedAccountsValuesWrapper = ({
  dueDate,
  total,
  titles,
  displayNotes,
  includeCardFee,
  titleStatus,
}) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <ReceivedAccountsValuesTableRow
        dueDate={dueDate}
        titles={titles}
        displayNotes={displayNotes}
        total={total}
        includeCardFee={includeCardFee}
        titleStatus={titleStatus}
      />
    </View>
  );
};

const ReceivedAccountsValues = ({
  totalizatorsByPeriod,
  data,
  displayNotes,
  dateType,
  includeCardFee,
  titleStatus,
}) => {
  return (
    <View>
      <TotalizatorByPeriod
        totalizatorsByPeriod={totalizatorsByPeriod}
        titleStatus={titleStatus}
      />

      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Listagem de Títulos
        </Text>
      </View>

      {data.map((customerData, index) => {
        const { customerName, customerCpfCnpj, customerPhone, rows } =
          customerData;

        return (
          <View key={index}>
            <ReceivedTitle
              customerName={customerName}
              customerCpfCnpj={customerCpfCnpj}
              customerPhone={customerPhone}
            />
            <ReceivedAccountsValuesTitle dateType={dateType} />
            <ReceivedAccountsValuesTableHeader
              includeCardFee={includeCardFee}
            />
            {Object.keys(rows).map((key2, index2) => {
              const row2Data = rows[key2];
              return (
                <ReceivedAccountsValuesWrapper
                  key={index2}
                  dueDate={key2}
                  titles={row2Data.rows}
                  total={row2Data.dayTotalToReceive}
                  displayNotes={displayNotes}
                  includeCardFee={includeCardFee}
                  titleStatus={titleStatus}
                />
              );
            })}
            <View style={styles.line} />
            <ReceivedAccountsValuesTotal
              titlesQuantity={customerData.titlesQuantity}
              totalToReceive={customerData.totalToReceive}
              titleStatus={titleStatus}
            />
            <View style={styles.line} />
          </View>
        );
      })}
    </View>
  );
};

export default AccountsReceivableAnalyticalClient;

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
