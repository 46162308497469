import React, { createContext, useState, useEffect, useContext } from 'react';
import { isAfter, isEqual, differenceInDays } from 'date-fns';

import constants from '../utils/constants';
import {
  isSignatureOnEnd,
  getDifferenceInDays,
  validatePlanFree,
  validatePlanStart,
  validatePlanEssencial,
  validatePlanPrime,
  validatePlanWorkmotorEquivalent,
} from '../utils/signatureHelpers';
import signatureRepository from '../repositories/Signatures';

const PlanSignatureContext = createContext({
  signature: null,
  isSignatureReachingEnd: false,
  isSignatureValid: true,
  isUserBlocked: false,
  isSignatureManuallyBlocked: false,
  daysLeft: 0,

  isPlanStart: false,
  isPlanFree: false,
  isPlanBasic: false,
  isPlanPrime: false,
  isPlanWorkmotorFree: false,
  isWorkmotor: false,
  isPaidWorkmotor: false,
  plan: null,
  isFeatureAvailable: (feature) => false,
  loadSignatureByUser: async (userId) => {},
  clearSignatureData: () => {},
});

export default function AuthProvider({ children }) {
  const [signature, setSignature] = useState(null);
  const [plan, setPlan] = useState(null);
  const [features, setFeatures] = useState([]);
  const [isPlanFree, setIsPlanFree] = useState(false);
  const [isPlanStart, setIsPlanStart] = useState(false);
  const [isPlanBasic, setIsPlanBasic] = useState(false);
  const [isPlanPrime, setIsPlanPrime] = useState(false);
  const [isWorkmotor, setIsWorkmotor] = useState(false);
  const [isPaidWorkmotor, setIsPaidWorkmotor] = useState(false);
  const [isPlanWorkmotorFree, setIsPlanWorkmotorFree] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [isSignatureReachingEnd, setIsSignatureReachingEnd] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isSignatureManuallyBlocked, setIsSignatureManuallyBlocked] =
    useState(false);
  const [isSignatureValid, setIsSignatureValid] = useState(true);

  useEffect(() => {
    if (signature) {
      checkSignatureStatus();
    }
  }, [signature]);

  useEffect(() => {
    setIsPlanFree(validatePlanFree(plan?.id));
    setIsPlanStart(validatePlanStart(plan?.id));
    setIsPlanBasic(validatePlanEssencial(plan?.id));
    setIsPlanPrime(validatePlanPrime(plan?.id));
    setIsWorkmotor(validatePlanWorkmotorEquivalent(plan?.id));
    setIsPlanWorkmotorFree(plan?.id === 5);
    setIsPaidWorkmotor(
      !(plan?.id === 5) && validatePlanWorkmotorEquivalent(plan?.id)
    );
    localStorage.setItem('isPlanFree', plan?.Description === 'Free');
  }, [plan]);

  const loadSignatureByUser = async (userId) => {
    try {
      const {
        data: { signature },
      } = await signatureRepository.getByUserWithPayment(userId);
      const serializedFeatures = signature.Plans.Features.map(
        (feature) => feature.name
      );

      setPlan(signature.Plans);
      setSignature(signature);
      setFeatures(serializedFeatures);
    } catch (err) {
      console.error(err.response?.data?.message || err.message);
    }
  };

  const checkSignatureStatus = () => {
    const { End_Date, SignatureStatus_id, signaturePeriod } = signature;
    const isDegustation =
      SignatureStatus_id === constants.STATUS_ID.DEGUSTATION;
    const isActive = SignatureStatus_id === constants.STATUS_ID.ACTIVE;
    const isSignaturePeriodMonthly =
      signaturePeriod === constants.SIGNATURE_PERIODS.MONTHLY;

    const endDate = new Date(End_Date);
    const date = new Date();

    if (isActive && !isSignaturePeriodMonthly) {
      setIsSignatureReachingEnd(isSignatureOnEnd(End_Date));
      setDaysLeft(getDifferenceInDays(End_Date) + 1);
    }

    handleSignatureValidity();
  };

  const handleSignatureValidity = () => {
    const { SignatureStatus_id, End_Date } = signature;
    const isCanceled =
      SignatureStatus_id === constants.SIGNATURE_STATUS.CANCELED;
    const isActive = SignatureStatus_id === constants.SIGNATURE_STATUS.ACTIVE;
    const isBlocked = SignatureStatus_id === constants.SIGNATURE_STATUS.BLOCKED;
    const isDisabled =
      SignatureStatus_id === constants.SIGNATURE_STATUS.DISABLED;
    const isEndOfPlan =
      SignatureStatus_id === constants.SIGNATURE_STATUS.END_PLAN;
    const isManuallyDisabled =
      SignatureStatus_id === constants.SIGNATURE_STATUS.MANUALLY_DISABLED;

    const endDate = new Date(End_Date);
    const date = new Date();
    const dateHasExpired = isAfter(date, endDate);

    if (isManuallyDisabled) {
      setIsSignatureManuallyBlocked(true);
    }

    if (
      ((isCanceled || isActive) && dateHasExpired) ||
      isBlocked ||
      isDisabled ||
      isEndOfPlan
    ) {
      setIsSignatureValid(false);
    } else {
      setIsSignatureValid(true);
    }

    setIsUserBlocked(isBlocked);
  };

  const isFeatureAvailable = (feature) =>
    features.some((child) => child === feature);

  const isRouteAvailable = (plansId = []) => {
    if (!plansId.length) return true;
    return plansId.some((planId) => plan && planId === plan.id);
  };

  const clearSignatureData = () => {
    setSignature(null);
    setPlan(null);
    setFeatures([]);
    setDaysLeft(0);
    setIsSignatureReachingEnd(false);
    setIsSignatureValid(true);
  };

  return (
    <PlanSignatureContext.Provider
      value={{
        signature,
        plan,
        daysLeft,
        isSignatureReachingEnd,
        isSignatureValid,
        isPlanStart,
        isPlanFree,
        isPlanBasic,
        isPlanPrime,
        isWorkmotor,
        isPaidWorkmotor,
        isPlanWorkmotorFree,
        isUserBlocked,
        isSignatureManuallyBlocked,

        isFeatureAvailable,
        isRouteAvailable,
        loadSignatureByUser,
        clearSignatureData,
      }}
    >
      {children}
    </PlanSignatureContext.Provider>
  );
}

export function usePlanSignatureContext() {
  const context = useContext(PlanSignatureContext);
  return context;
}
