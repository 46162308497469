import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import constants from 'utils/constants';
import NewCrmBonusFormMain from './NewCrmBonusFormMain';

export default function NewCrmBonusForm({ match }) {
  const crmBonusId = match.params.id;
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Integrações',
              'CRM Bônus',
              crmBonusId ? 'Editar Cliente' : 'Novo Cliente',
            ]}
            path={[constants.ROUTES.HOME, null, 'crm-bonus', null]}
          />
          <span style={{ color: 'red', position: 'absolute', right: 10 }}>
            * Campos Obrigatórios
          </span>
          <NewCrmBonusFormMain crmBonusId={crmBonusId} />
        </Row>
      </Grid>
    </div>
  );
}
