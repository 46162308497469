import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../CustomButton/CustomButton.jsx'
import SupportButton from '../SupportButton'
import LoaderSpinner from '../LoaderSpinner'
import { useAuth } from 'contexts/auth'
import config from 'config'
import './styles.css'
const DequimentCustomersModal = () => {
  const { nonCompliance } = useAuth()

  const [showDequimentCustomerModal, setShowDequimentCustomerModal] =
    useState(false)

  const [counter, setCounter] = useState(40)

  useEffect(() => {
    const alreadyShownComplienceModal = localStorage.getItem(
      'alreadyShownComplienceModal'
    )

    if (nonCompliance && !alreadyShownComplienceModal) {
      setShowDequimentCustomerModal(true)

      localStorage.setItem('alreadyShownComplienceModal', true)
    }
  }, [nonCompliance])

  useEffect(() => {
    counter > 0
      ? setTimeout(() => setCounter(counter - 1), 1000)
      : setCounter(0)
  }, [counter])

  return (
    <Modal
      show={showDequimentCustomerModal}
      dialogClassName='onboarding-modal'
      onHide={() => counter == 0 && setShowDequimentCustomerModal(false)}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital!</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>Prezado(a) Cliente,</strong>
        <p>
          Identificamos que existem valores pendente. Evite o bloqueio de seu
          sistema efetuando o pagamento do boleto. Caso tenha dúvidas, pode
          acessar a Minha Área financeira em seu sistema ou entre em contato com
          o nosso financeiro através dos contatos abaixo:
        </p>
        <div className='flexItems'>
          <strong>Atendimento online:</strong>
          <SupportButton />
        </div>
        <div className='flexItems'>
          <strong>WhatsApp:</strong>{' '}
          <p>
            (19) 9 9232-8861 ou{' '}
            <a
              href=''
              style={{ color: '#1372e5' }}
              onClick={() =>
                window.open(config.whatsappendpoint + 19992328861, '_blank')
              }
            >
              Clique Aqui
            </a>{' '}
          </p>
        </div>
        <div className='flexItems'>
          <strong>Telefone:</strong> <p>(19) 3755-3003 ou (19) 3755-3029</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='footer-buttons'>
          <div className='flexItems'>
            {counter > 0 ? (
              <>
                <LoaderSpinner
                  style={{
                    fontSize: '3px',
                    margin: '10px',
                  }}
                />
                <strong>Aguarde {counter} segundos para fechar</strong>
              </>
            ) : (
              ''
            )}
          </div>
          <Button
            bsStyle='danger'
            name='Não'
            disabled={counter > 0}
            onClick={() => setShowDequimentCustomerModal(false)}
            fill
          >
            Fechar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DequimentCustomersModal
