import config from '../config';
import api from '../services/api';

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `nfce?companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const cancel = async ({ nfceId, justification }) => {
  try {
    const response = await api.put(config.endpoint + `nfce/${nfceId}/cancel`, {
      justification,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const consult = async (type, nfceId) => {
  try {
    const response = await api.put(
      config.endpoint +
        `nfce/${nfceId}/consult${type !== 'normal' ? '-contingency' : ''}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteNFCe = async (id) => {
  try {
    const response = await api.delete(config.endpoint + `nfce/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const disabledNFCe = async (params) => {
  try {
    const response = await api.post(config.endpoint + 'nfce/disable', params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const search = async (params) => {
  const response = await api.post(config.endpoint + 'nfce/search', params);
  return response.data;
};

const generateBySale = async (saleId) => {
  const response = await api.post(config.endpoint + 'nfce/generate-by-sale', {
    saleId,
  });
  return response.data;
};

const show = async (id) => {
  const response = await api.get(config.endpoint + `nfce/${id}`);
  return response.data;
};

const getByIdToPrint = async (id) => {
  const response = await api.get(config.endpoint + `nfce/${id}/print`);
  return response.data;
};

const updateItem = async (NFCeItemId, values) => {
  await api.put(config.endpoint + `nfce-items/${NFCeItemId}`, values);
};

const emit = async (id, { includeCpfCnpj, customerCpfCnpj, issuedAt }) => {
  const response = await api.put(config.endpoint + `nfce/${id}/emit`, {
    includeCpfCnpj,
    customerCpfCnpj,
    issuedAt,
  });
  return response.data;
};

const getNfcePdv = async (companyId) => {
  const response = await api.get(config.endpoint + `nfce/${companyId}/by-pdv`);
  return response.data;
};

const generateNFCeReport = async (companyId, params) => {
  try {
    const response = await api.get(`report/nfce/synthetic/${companyId}`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  generateNFCeReport,
  getAllByCompany,
  cancel,
  consult,
  deleteNFCe,
  disabledNFCe,
  search,
  generateBySale,
  show,
  updateItem,
  emit,
  getByIdToPrint,
  getNfcePdv,
};
