import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import Toggle from 'react-toggle';

import { description } from 'client/components/ToNormalize/ToNormalize';
import familiesRepository from '../../../../repositories/Families';
import warrantiesRepository from '../../../../repositories/Warranties';
import revisionsRepository from 'repositories/Revisions';

import { useAuth } from '../../../../contexts/auth';

import RenderField from 'components/RenderField';
import { percentMask } from '../../../../utils/masks';

import FormFamily from '../../Families/NewFamilie/FormFamilie';
import FormWarranty from '../../Warranty/NewWarranty/NewWarranty';
import FormRevision from '../../Reviews/NewReview/NewReview';

const ServiceData = ({ serviceId }) => {
  const [families, setFamilies] = useState([]);
  const [warranties, setWarranties] = useState([]);
  const [revisions, setRevisions] = useState([]);

  const [isFamilyModalOpen, setIsFamilyModalOpen] = useState(false);
  const [isWarrantyModalOpen, setIsWarrantyModalOpen] = useState(false);
  const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);

  const { companyId, company } = useAuth();
  const dispatch = useDispatch();
  const {
    isActive,
    companyIdFromService,
    descriptionEditDisabled,
    additionalInformation,
    hasDiscountLimit,
  } = useSelector((state) => state).form.service.values;

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
  });

  const hoursMask = createTextMask({
    pattern: '999:99',
  });

  const marginMask = createNumberMask({
    decimalPlaces: 2,
    suffix: '%',
  });
  useEffect(() => {
    loadFamilies();
    loadWarranties();
    loadRevisions();
  }, []);

  useEffect(() => {
    if (!hasDiscountLimit) {
      dispatch(change('service', 'discountLimit', 0));
    }
  }, [hasDiscountLimit]);

  const loadWarranties = async () => {
    try {
      const warranties = await warrantiesRepository.getAllByCompany(companyId);
      setWarranties(warranties);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as garantias. Por favor, tente novamente'
      );
    }
  };

  const loadFamilies = async () => {
    try {
      const families = await familiesRepository.getAllByCompany(companyId, {
        Type: 'Serviço',
      });
      const defaultFamilies = families.filter((family) => !family.Company_id);
      const companyFamilies = families.filter((family) => !!family.Company_id);
      const companyFamiliesDescription = families
        .filter((family) => !!family.Company_id)
        .map((family) => family.Description);
      const serializedDefaultFamilies = defaultFamilies.filter(
        (defaultFamily) =>
          !companyFamiliesDescription.includes(defaultFamily.Description)
      );
      setFamilies(
        [...serializedDefaultFamilies, ...companyFamilies].filter(
          (family) => family.IsActive
        )
      );
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as familias. Por favor, tente novamente'
      );
    }
  };

  const loadRevisions = async () => {
    try {
      const revisions = await revisionsRepository.getAllByCompany(companyId);
      setRevisions(revisions);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as revisões. Por favor, tente novamente.'
      );
    }
  };

  const handleOpenFamilyModal = () => {
    if (!isFamilySelectionAvailable) return;
    setIsFamilyModalOpen(true);
  };

  const isFamilySelectionAvailable =
    !serviceId || (!!serviceId && !!companyIdFromService);

  return (
    <>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body"
        id="form-service"
      >
        <Col xs={12} sm={3} md={2} lg={2}>
          <Field label="Código:" name="code" component={RenderField} disabled />
        </Col>
        <Col xs={12} sm={7} md={4} lg={4}>
          <Field
            label="Descrição:"
            name="description"
            component={RenderField}
            normalize={(value) => description(value).toUpperCase()}
            required
            disabled={descriptionEditDisabled}
            maxLength={40}
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className="addition-modal-wrapper">
          <Field
            label="Familia:"
            name="familyId"
            component={RenderField}
            as="select"
            required
            disabled={!isFamilySelectionAvailable}
          >
            <option value="">Selecione</option>
            {families.map((family) => (
              <option key={family.id} value={family.id}>
                {family.Description}
              </option>
            ))}
          </Field>
          <a
            className="addition-modal-span"
            style={{
              cursor: isFamilySelectionAvailable ? 'pointer' : 'not-allowed',
            }}
            onClick={handleOpenFamilyModal}
          >
            Adicionar Família
          </a>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className="addition-modal-wrapper">
          <Field
            label="Garantia:"
            name="warrantyId"
            component={RenderField}
            as="select"
            required
          >
            <option value="">Selecione</option>
            {warranties.map((warranty) => (
              <option key={warranty.id} value={warranty.id}>
                {warranty.Description}
              </option>
            ))}
          </Field>
          <a
            className="addition-modal-span"
            onClick={() => setIsWarrantyModalOpen(true)}
          >
            Adicionar Garantia
          </a>
        </Col>
        <Col xs={12} sm={3} md={1} lg={1}>
          <label>Status</label>
          <div>
            {' '}
            <Toggle
              className="custom-toggle-discount"
              checked={!!isActive}
              onChange={(e) =>
                dispatch(change('service', 'isActive', e.target.checked))
              }
            />
          </div>
        </Col>
        <Col xs={12} sm={5} md={2} lg={2}>
          <Field
            label="Preço:"
            name="price"
            component={RenderField}
            required
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={5} md={2} lg={2}>
          <Field
            label="Horas Previstas:"
            name="hoursExpected"
            component={RenderField}
            {...hoursMask}
          />
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="% Comissão:"
            name="Commission_Rate"
            component={RenderField}
            {...percentMask}
            maxLength="7"
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className="addition-modal-wrapper">
          <Field
            label="Revisão:"
            name="reviewId"
            component={RenderField}
            as="select"
          >
            <option value="">Selecione</option>
            {revisions.map((revision) => (
              <option key={revision.id} value={revision.id}>
                {revision.Description}
              </option>
            ))}
          </Field>
          <a
            className="addition-modal-span"
            onClick={() => setIsRevisionModalOpen(true)}
          >
            Adicionar Revisão
          </a>
        </Col>

        <Col xs={12} sm={2} md={2} lg={2}>
          <label>Limite de Desconto</label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
              width: '150px',
            }}
          >
            <Toggle
              checked={hasDiscountLimit}
              onChange={() =>
                dispatch(
                  change('service', 'hasDiscountLimit', !hasDiscountLimit)
                )
              }
            />
            <Field
              name="discountLimit"
              component={RenderField}
              disabled={!hasDiscountLimit}
              {...marginMask}
              maxLength="6"
            />
          </div>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} className="field-hiperlink">
          <span>
            {additionalInformation ? additionalInformation.length : 0}{' '}
            caracteres
          </span>
          <Field
            label="Informações complementares:"
            name="additionalInformation"
            component={RenderField}
            as="textarea"
            maxLength="650"
            normalize={description}
            style={{
              resize: 'vertical',
              marginBottom: 15,
            }}
          />
        </Col>
      </div>

      <Modal
        dialogClassName="modal-80w"
        show={isFamilyModalOpen}
        onHide={() => setIsFamilyModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Nova Família</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormFamily
            modal={true}
            Calling="Cadastro Serviço"
            handleModal={(_, id) => {
              loadFamilies();
              setIsFamilyModalOpen(false);
              dispatch(change('service', 'familyId', id));
            }}
            readOnly={false}
          />
          <span id="required-field-label">* Campos Obrigatórios</span>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-formProduto"
        show={isWarrantyModalOpen}
        onHide={() => setIsWarrantyModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Nova Garantia</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modal-formProduto-body"
          style={{
            marginBottom: '40px',
            marginLeft: '5px',
            height: '240px',
            marginTop: 0,
          }}
        >
          <FormWarranty
            modal={true}
            Calling="Cadastro Serviço"
            handleModal={(_, id) => {
              setIsWarrantyModalOpen(false);
              loadWarranties();
              dispatch(change('service', 'warrantyId', id));
            }}
            readOnly={false}
          />
          <span id="required-field-label">* Campos Obrigatórios</span>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-formProduto"
        show={isRevisionModalOpen}
        onHide={() => setIsRevisionModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Nova Revisão</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modal-formProduto-body"
          style={{
            marginBottom: '40px',
            marginLeft: '5px',
            height: '320px',
            marginTop: 0,
          }}
        >
          <FormRevision
            modal={true}
            Calling="Cadastro Serviço"
            handleModal={(_, id) => {
              setIsRevisionModalOpen(false);
              loadRevisions();
              dispatch(change('service', 'reviewId', id));
            }}
            readOnly={false}
          />
          <span id="required-field-label">* Campos Obrigatórios</span>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ServiceData;
