import api from '../services/api'
import config from '../config'

const index = () => api.get(config.endpoint + 'stone-register')

const show = (stoneLinkId) =>
  api.get(config.endpoint + `stone-register/${stoneLinkId}`)

const create = (stoneLink) =>
  api.post(config.endpoint + 'stone-register', stoneLink, {
    validateStatus: () => true,
  })

const update = (stoneLinkId, stoneLink) =>
  api.put(config.endpoint + `stone-register/${stoneLinkId}`, stoneLink, {
    validateStatus: () => true,
  })

const getAllDataByCompany = (companyId) =>
  api.get(config.endpoint + `stone-register/bycompany/${companyId}`)

export default {
  index,
  show,
  create,
  update,
  getAllDataByCompany,
}
