import React from 'react';
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import fontRobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import fontRobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import fontRobotoThin from '../../../assets/fonts/Roboto-Thin.ttf';

import { ChecklistHeader } from './components/Header';
import { customChecklistSheetStyles } from './CustomChecklistSheet.styles';
import { CustomChecklistDraw } from './components/CustomChecklistDraw';
import { ChecklistObservations } from './components/ChecklistObservations';
import { ChecklistTable } from './components/ChecklistTable';

export const CustomChecklistSheet = ({
  customer,
  sale,
  checklist,
  displayNotes,
}) => {
  return (
    <Document
      title={`impresso-Checklist-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page style={customChecklistSheetStyles.page}>
        <ChecklistHeader
          customer={customer}
          isBike={checklist.defaultDesign === 'Bicicleta'}
        />
        <CustomChecklistDraw checklist={checklist} />

        <ChecklistTable checklist={checklist} />

        <ChecklistObservations displayNotes={displayNotes} sale={sale} />
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoRegular },
    { src: fontRobotoThin, fontWeight: 'thin' },
    { src: fontRobotoBold, fontWeight: 'bold' },
  ],
});
