import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import superlogicaRepository from 'repositories/Superlogica';
import PureLoader from '../../../components/PureLoader';
import './styles.css';

const SuperlogicaClientArea = () => {
  const { company } = useAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!company) return;

    laodSuperlogicaClientArea();
  }, [company]);

  const laodSuperlogicaClientArea = async () => {
    setLoading(true);
    try {
      const companyEmail = company.Email;
      const response = await superlogicaRepository.getSuperlogicaLoginToken(
        companyEmail
      );
      const loginToken = response.data.token;

      var appUrl = encodeURIComponent(document.location.href);
      var iframePlanos = document.createElement('iframe');

      iframePlanos.setAttribute('frameborder', 0);
      iframePlanos.setAttribute('width', '100%');
      iframePlanos.setAttribute('height', 500);
      iframePlanos.setAttribute('allowtransparency', true);
      iframePlanos.src = `https://workinovacao.superlogica.net/clients/areadocliente?url=${appUrl}&token=${loginToken}`;

      document
        .getElementById('superlogica_planos_script')
        .appendChild(iframePlanos);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toastr.error('Ocorreu um erro ao carregar a área financeira.');
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1820);
    }
  };

  return (
    <div className="client-area-container">
      <div id="superlogica_planos_script" className="superlogica-iframe" />
      {loading && <PureLoader message="Aguarde..." />}
    </div>
  );
};

export default SuperlogicaClientArea;
