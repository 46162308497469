import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  currency,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const HeaderTotalsPerQuotationProvider = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Fornecedor</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Quantidade</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const SubHeaderTotalsPerQuotationProvider = (value) => {
  let totalsValueProviders = { total: 0, quantity: 0 }
  value.totalsPerQuotationProvider.map(item => {
    item.map(quotation => {
      totalsValueProviders.quantity += 1
      quotation.Quotation.QuotationItems.map(items => {
        items.QuotationResponseItems.map(quotationResponseItem => {

          if (quotationResponseItem?.QuotationResponse?.providerId === quotation.Provider.id) {
            totalsValueProviders.total += (quotationResponseItem.avaliable_quantity * quotationResponseItem.unit_value)
          }
        })
      })
    })
  })

  return (
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
        <Text>Total</Text>
      </View>
      <View style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
        <Text>{totalsValueProviders.quantity}</Text>
      </View>
      <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
        <Text>{currency(totalsValueProviders.total)}</Text>
      </View>
    </View>
  )
}

const ItemRowTotalsPerQuotationProvider = ({ nameProvider, quantity, totalsValueProvider }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{nameProvider}</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
      <Text style={styles.span}>{quantity}</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(totalsValueProvider)}</Text>
    </View>
  </View>
)

const QuotesHeader = ({ totals, createdAt, status, provider }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      width: '70%'
    }}
  >
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemLabel label='Data Cotação' value={format(new Date(createdAt), 'dd/MM/yyyy')} />
      <ItemLabel label='Fornecedor' value={provider} />
    </View>
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemLabel label='Status' value={status} />
      <ItemLabel label='Valor Cotação' value={currency(totals)} />
    </View>
  </View>
)

const ItemsHeaderQuotes = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Código</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descrição</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Ref. Fabricante</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Marca</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'right', justifyContent: 'center' }}>
      <Text>Qtd. Solicitada</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'right', justifyContent: 'center' }}>
      <Text>Qtd. Disponível</Text>
    </View>
    <View style={{ width: '18%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Valor Unitário</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowQuotes = ({ item, productCode, quantityRequested, description, brand, manufacturerRef, avaliableQuantity, unitValue }) => {
  return (
    <View
      style={{ flexDirection: 'row', height: 12, width: '100%' }}
      wrap={false}
    >
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{productCode}</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{description}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{manufacturerRef}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{brand}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'right', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{quantityRequested}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'right', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Quotation.status === 'Não respondida' ? '' : (avaliableQuantity === 0 ? '' : avaliableQuantity)}</Text>
      </View>
      <View
        style={{ width: '18%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Quotation.status === 'Não respondida' ? '' : (unitValue === 0 ? '' : currency(unitValue))}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Quotation.status === 'Não respondida' ? '' : ((unitValue === 0 || avaliableQuantity === 0) ? '' : currency(avaliableQuantity * unitValue))}</Text>
      </View>
    </View>
  )
}

export const QuotesSupplierPDF = ({
  result = [],
  totalsPerQuotationProvider = [],
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`impresso-cotações-fornecedor-${format(new Date(), 'dd/MM/yyyy')}`}
      subject='quotes-supplier'
    >
      <Page style={styles.page} orientation="landscape">
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Listagem de Cotação por Fornecedor</Text>
          <View style={{ display: 'flex', width: '28%', justifyContent: 'space-between', flexDirection: 'row' }}>
            <ItemLabel label='Data Inicio' value={initialDate} />
            <ItemLabel label='Data Fim' value={finalDate} />
          </View>
        </View>
        <View style={[styles.line, { marginTop: 10 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: 1
          }}
        >
          Totais por Fornecedor
        </Text>
        <View style={styles.line} />
        <HeaderTotalsPerQuotationProvider />
        <View style={styles.line} />
        {totalsPerQuotationProvider.map(item => {
          const nameProvider = item.reduce((prevValue, currentValue) => currentValue.Provider.type === 'Fisica' ? currentValue.Provider?.companyName : currentValue.Provider?.tradingName, '')
          const quantity = item.length

          let totalsValueProvider = item.reduce((prev, curr) => {
            return [...prev, ...curr.Quotation.QuotationItems]
          }, []).map(quotationItems => quotationItems.QuotationResponseItems).reduce((prev, curr) => [...prev, ...curr], []).filter(quotationItem => {

            return quotationItem.QuotationResponse.providerId === item[0]?.providerId
          }).reduce((prev, curr) => {

            if (curr.avaliable_quantity && curr.unit_value) {
              return prev + (curr.avaliable_quantity * curr.unit_value)
            }
          }, 0)

          return (
            <div key={item.id}>
              <ItemRowTotalsPerQuotationProvider
                nameProvider={nameProvider}
                quantity={quantity}
                totalsValueProvider={totalsValueProvider}
              />
            </div>
          )
        })}
        <View style={styles.line} />
        <SubHeaderTotalsPerQuotationProvider result={result} totalsPerQuotationProvider={totalsPerQuotationProvider} />
        <View style={[styles.line, { marginTop: 10 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
          }}
        >
          Listagem de Cotações
        </Text>
        <View style={styles.line} />

        {result.map((item, index) => {
          let totals = item.Quotation.QuotationItems.map(quotationItems => quotationItems.QuotationResponseItems).reduce((prev, curr) => ([...prev, ...curr]), []).filter(responseItem => responseItem?.QuotationResponse?.providerId === item.Provider.id).reduce((prev, curr) => {
            if (curr.avaliable_quantity && curr.unit_value) {
              return prev + (curr.avaliable_quantity * curr.unit_value)
            }

            return prev
          }, 0)

          return (
            <div key={item.id}>
              <QuotesHeader
                totals={item.Quotation.status === 'Respondida' ? totals : ''}
                createdAt={item.Quotation.createdAt}
                status={item.Quotation.status}
                provider={item.Provider.type === 'Fisica' ? `${item.Provider.cpfCnpj} - ${item.Provider.companyName}` : `${item.Provider.cpfCnpj} - ${item.Provider.tradingName}`}
              />
              <View style={styles.line} />
              <ItemsHeaderQuotes />
              <View style={styles.line} />
              {item.Quotation.QuotationItems.map(element => (
                <div key={element.id}>
                  {element.QuotationResponseItems.filter(responseItem => responseItem.QuotationResponse.providerId === item.Provider.id).length === 0 ?
                    (<div>
                      <ItemRowQuotes item={item} key={index} productCode={element.productId} quantityRequested={element.quantity} description={element.description} brand={element.brand} manufacturerRef={element.manufacturer_ref} avaliableQuantity={0} unitValue={0} />
                    </div>)
                    :

                    element.QuotationResponseItems.filter(responseItem => responseItem.QuotationResponse.providerId === item.Provider.id).map(quotationResponseItem => {
                      return (
                        <div key={quotationResponseItem.id}>
                          <ItemRowQuotes item={item} key={index} productCode={element.productId} quantityRequested={element.quantity} description={element.description} brand={element.brand} manufacturerRef={element.manufacturer_ref} avaliableQuantity={quotationResponseItem.avaliable_quantity} unitValue={quotationResponseItem.unit_value} />
                        </div>
                      )
                    })
                  }
                </div>
              ))}
              <View style={styles.line} />
            </div>
          )
        })}
      </Page>
    </Document>
  )
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
