import React, { memo } from 'react'
import Chart from "react-google-charts";

const ChartByProvider = ({ data }) => {

    return (
        <>
            <div className="chart-pagamento" style={{ opacity: '100%' }}>
                <Chart
                    width={'400px'}
                    height={'300px'}
                    chartType="PieChart"
                    data={[
                        ['Fornecedor', 'Total'],
                        ...data
                    ]}
                    chartLanguage="pt-br"
                    options={{
                        chartArea: {
                            left: 0
                        }
                    }}
                />
            </div>
        </>
    )
}

export default memo(ChartByProvider)