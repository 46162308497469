import api from '../services/api'
import config from '../config'

const show = async (groupId) => {
  const { data } = await api.get(config.endpoint + `discount-groups-services`, {
    params: {
      groupId,
    },
  })

  return data
}

const create = async (data) => {
  await api.post(config.endpoint + `discount-groups-services`, data)
}
const update = async (data) => {
  await api.put(config.endpoint + `discount-groups-services`, data)
}

const deleteService = async (id) => {
  await api.delete(config.endpoint + `discount-groups-services/${id}`)
}

export default { show, create, deleteService, update }
