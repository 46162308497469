import React, { useEffect, useState } from 'react';
import CardForm from 'client/components/CardForm';
import { Modal } from 'react-bootstrap';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import suivCompanyRepository from 'repositories/SuivCompany';
import TableFipeRepository from 'repositories/TableFipe';
import MegalaudoRepository from 'repositories/Megalaudo';
import { useForm } from 'react-hook-form';
import customersRepository from 'repositories/Customers';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import vehiclesRepository from 'repositories/Vehicles';
import { addYears, getYear } from 'date-fns';
import OthersVehicleData from './OthersVehicleData';
import VehicleData from './VehicleData';
import LegalPerson from './LegalPerson';
import PhysicalPerson from './PhysicalPerson';
import { onlyNumbers } from '../ToNormalize/ToNormalize';

const NewClientModal = ({ isModalOpen, onHide }) => {
  const [showClientData, setShowClientData] = useState(true);
  const [showVehicleData, setShowVehicleData] = useState(true);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleType, setVehicleType] = useState('Carros/Motos/Caminhoes');
  const [clientType, setClientType] = useState('Fisica');
  const [vehiclePlate, setVehiclePlate] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [numberPhone, setNumberPhone] = useState(null);
  const [contact, setContact] = useState(null);

  const [vehicles, setVehicles] = useState([]);

  const dispatch = useDispatch();

  const { companyId } = useAuth();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      Model: '',
    },
  });

  const loadBrands = async () => {
    setLoading(true);
    try {
      const { data } = await TableFipeRepository.getBrands(companyId);

      const dataToUpperCase = data.map((d) => ({
        Brand: d.Brand.toUpperCase(),
      }));

      setBrands(dataToUpperCase);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar as marcas.',
        'Por favor, tente novamente.'
      );
    }
  };

  const loadVehicleByPlate = async () => {
    setLoading(true);
    try {
      const { data } = await suivCompanyRepository.showVehicleInfo(
        vehiclePlate,
        companyId,
        true
      );

      let fipeCode = data.suivDataCollection[0].fipeId;
      fipeCode = fipeCode.toString().padStart(7, '0');
      fipeCode = fipeCode.slice(0, -1) + '-' + fipeCode.slice(-1);

      const tableFipeData = await TableFipeRepository.getByFipe(fipeCode);

      const serializedVehicle = {
        codeFipe: tableFipeData.Code_FIPE,
        model: tableFipeData.Model,
        brand: tableFipeData.Brand,
        type: tableFipeData.Type,
        label: tableFipeData.Model?.toUpperCase(),
        value: tableFipeData.Model,
      };

      const fuelType = serializeFuelType(data.fuel);

      setSelectedBrand(data.maker);
      setSelectedModel(serializedVehicle);
      setValue('Model', serializedVehicle.model);
      setValue('Fuel_Type', fuelType);
      setValue('NChassi', data.chassis);
      setValue('Year_Manufacture', data.yearFab);
      setValue('Year_Model', data.yearModel);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastr.error(
        'Não foi possível localizar o veículo pela placa.',
        'Por favor, tente novamente.'
      );
    }
  };

  const serializeFuelType = (fuelType) => {
    switch (fuelType) {
      case 'Alcool':
        return 'Álcool';
      case 'Gasolina':
        return 'Gasolina';
      case 'Gas':
        return 'Gás';
      case 'Diesel':
        return 'Diesel';
      case 'Alcool, Gasolina':
        return 'Gasolina/Álcool';
      default:
        return '';
    }
  };

  const loadBrandModels = async () => {
    setLoading(false);
    try {
      const { data } = await TableFipeRepository.getModelsByBrand(
        selectedBrand
      );

      const models = data.map((child) => ({
        model: child.Model,
        fipeCode: child.Code_FIPE,
        label: child.Model.toUpperCase(),
        value: child.Model,
      }));

      setVehicles(
        models.sort((a, b) =>
          a.model.toUpperCase() > b.model.toUpperCase() ? 1 : -1
        )
      );

      setLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar os modelos.',
        'Por favor, tente novamente.'
      );
    }
  };

  const validations = (values) => {
    if (clientType === 'Fisica' && (!companyName || !numberPhone)) {
      toastr.warning('Preencha todos os campos do cliente.');
      return false;
    }

    if (
      clientType === 'Juridica' &&
      (!companyName || !numberPhone || !contact)
    ) {
      toastr.warning('Preencha todos os campos do cliente.');
      return false;
    }

    if (isDirty || selectedBrand || vehiclePlate) {
      if (
        vehicleType === 'Carros/Motos/Caminhoes' &&
        (!values.Kilometer ||
          !values.Year_Manufacture ||
          !values.Year_Model ||
          !selectedBrand ||
          !selectedModel ||
          !vehiclePlate)
      ) {
        toastr.warning('Preencha todos os campos do veículo.');
        return false;
      }

      if (
        vehicleType === 'Outros Veiculos' &&
        (!values.Kilometer ||
          !values.Year_Manufacture ||
          !values.Year_Model ||
          !values.Maintenance_Object ||
          !selectedBrand ||
          !selectedModel)
      ) {
        toastr.warning('Preencha todos os campos do veículo.');
        return false;
      }
    }

    if (parseInt(values.Year_Model) < parseInt(values.Year_Manufacture)) {
      return toastr.warning(
        'Ano de fabricação maior do que ano de modelo.',
        'Por favor, ajuste e tente novamente.'
      );
    }

    const formatedPlate = vehiclePlate?.replace('-', '');

    if (formatedPlate && formatedPlate?.length < 7) {
      return toastr.warning(
        'Placa incompleta',
        'Por favor, preencha corretamente e tente novamente.'
      );
    }

    const twoYears = addYears(new Date(), 2);
    if (parseInt(values.Year_Model) >= getYear(new Date(twoYears))) {
      return toastr.warning(
        'Ano do modelo inválido.',
        'Por favor, preencha corretamente e tente novamente.'
      );
    }

    return true;
  };

  const createVehicle = async (values, customer) => {
    try {
      const vehicle = await vehiclesRepository.create({
        License_Plate: vehiclePlate.replace('-', ''),
        Maintenance_Object: values.Maintenance_Object,
        Brand: selectedBrand,
        Model: selectedModel.value,
        Year_Manufacture: values.Year_Manufacture,
        Year_Model: values.Year_Model,
        Color: values.Color,
        Kilometers: Number(onlyNumbers(values.Kilometer)),
        Company_id: companyId,
        Customer_id: customer,
        NChassi: values.NChassi,
        Vin_Renavam: values.Vin_Renavam,
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao cadastrar o veículo.',
        'Por favor, tente novamente.'
      );
    }
  };

  const onSubmit = async (values) => {
    if (!validations(values)) return;
    setLoading(true);

    try {
      const formatedNumber = numberPhone
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', '')
        .replaceAll('_', '');

      if (formatedNumber.length < 11) {
        setLoading(false);
        return toastr.warning(
          'Número de telefone inválido.',
          'Por favor, preencha o campo inteiro.'
        );
      }

      const customer = await customersRepository.create({
        customer: {
          Company_id: companyId,
          Company_Name: companyName,
          Type: clientType,
          Status: 'Ativo',
        },
        phones: {
          Type_Phone2: 'Celular',
          Contact: contact,
          Number_Phone2: formatedNumber,
        },
      });

      if (vehiclePlate) await createVehicle(values, customer.id);

      setLoading(false);
      dispatch(change('pdvOnline', 'customerId', customer.id));
      onHide();

      if (vehiclePlate)
        return toastr.success('Cliente e veículo cadastrado com sucesso!');

      return toastr.success('Cliente cadastrado com sucesso!');
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao cadastrar o cliente.',
        'Por favor, tente novamente.'
      );
    }
  };

  const onCancel = () => {};

  useEffect(() => {
    loadBrands();
  }, []);

  useEffect(() => {
    loadBrandModels();
    setSelectedModel(null);
  }, [selectedBrand]);

  return (
    <Modal
      dialogClassName="modal-70w"
      show={isModalOpen}
      onHide={onHide}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
          <span
            style={{
              color: 'red',
              position: 'absolute',
              right: 10,
              fontSize: 14,
              marginTop: 15,
            }}
          >
            * Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          }}
        >
          <CardForm
            show={showClientData}
            title="Dados do Cliente"
            onClick={() => setShowClientData(!showClientData)}
          >
            <div className="card__new-client-row">
              <div className="card__new-client-column">
                <span className="card__new-client-span">Tipo de Pessoa:</span>
                <select
                  name="Type"
                  className="form-control foco-input"
                  style={{
                    width: '15rem',
                  }}
                  onChange={(e) => setClientType(e.target.value)}
                >
                  <option value="Fisica">Física</option>
                  <option value="Juridica">Jurídica</option>
                </select>
              </div>
            </div>

            {clientType === 'Fisica' && (
              <PhysicalPerson
                register={register}
                setCompanyName={setCompanyName}
                setNumberPhone={setNumberPhone}
              />
            )}

            {clientType === 'Juridica' && (
              <LegalPerson
                register={register}
                setCompanyName={setCompanyName}
                setNumberPhone={setNumberPhone}
                setContact={setContact}
              />
            )}
          </CardForm>

          <CardForm
            show={showVehicleData}
            title="Dados do Veículo (Opcional)"
            onClick={() => setShowVehicleData(!showVehicleData)}
          >
            <div className="card__new-client-row">
              <div className="card__new-client-column">
                <span className="card__new-client-span">
                  Tipo: <span style={{ color: 'red' }}>*</span>
                </span>
                <select
                  name="TypeVehicle"
                  className="form-control foco-input"
                  style={{
                    width: '23rem',
                  }}
                  onChange={(e) => setVehicleType(e.target.value)}
                >
                  <option value="Carros/Motos/Caminhoes">
                    Carros/Motos/Caminhoes
                  </option>
                  <option value="Outros Veiculos">Outros Veículos</option>
                </select>
              </div>
            </div>

            {vehicleType === 'Outros Veiculos' && (
              <OthersVehicleData
                brands={brands}
                register={register}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
              />
            )}

            {vehicleType === 'Carros/Motos/Caminhoes' && (
              <VehicleData
                brands={brands}
                vehiclePlate={vehiclePlate}
                setVehiclePlate={setVehiclePlate}
                loadVehicleByPlate={loadVehicleByPlate}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                vehicles={vehicles}
                loading={loading}
                register={register}
              />
            )}
          </CardForm>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormSubmitButtons
          onCancel={onHide}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default NewClientModal;
