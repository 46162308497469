import React from 'react'

export default props => (
    <input {...props.input}
        className='form-control foco-input'
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        type={props.type} 
        disabled={props.disabled}
        list={props.list}
        maxLength={props.maxLength}
        style={props.style}/>
)