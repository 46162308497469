import React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { currency } from 'client/components/ToNormalize/ToNormalize';

import {
  FINANCING_LOADING,
  FINANCING_KOIN_INSTALLMENTS,
  FINANCING_KOIN_PAYMENTID,
  FINANCING_KOIN_INCOMING_OPTIONS,
  FINANCING_KOIN_SELECTED_INCOMING_OPTION,
} from '../../../recoil';

const KoinInstallment = ({ saleTotal, installment, onChange }) => {
  const hasInterest =
    parseFloat(saleTotal) < parseFloat(installment.orderValue);

  return (
    <li
      key={installment.paymentType}
      style={{
        marginBottom: '13px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '5px',
      }}
    >
      <input
        type="radio"
        onChange={onChange}
        value={installment.paymentType}
        name="KOIN_PAYMENTID"
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {installment.description} {hasInterest && '(com juros)'} <br />
        <span style={{ color: '#9e9e9e' }}>
          Total de R$ {currency(installment.orderValue)}
        </span>
      </div>
    </li>
  );
};

export default function FinancingAuthorizedStepKoin() {
  const [koinIncomingOptions] = useRecoilState(FINANCING_KOIN_INCOMING_OPTIONS);
  const [koinInstallments, setKoinInstallments] = useRecoilState(
    FINANCING_KOIN_INSTALLMENTS
  );
  const [selectedIcomingOption, setIcomingOption] = useRecoilState(
    FINANCING_KOIN_SELECTED_INCOMING_OPTION
  );
  const [isLoading, setIsLoading] = useRecoilState(FINANCING_LOADING);
  const [paymentId, setPaymentId] = useRecoilState(FINANCING_KOIN_PAYMENTID);

  const { saleReducer } = useSelector((state) => state);

  const { total } = useSelector((state) => state.saleReducer);

  function handleChangePaymentId(e) {
    setPaymentId(e.target.value);
  }

  async function handleChangeIcomingOption(e) {
    setIsLoading(true);
    setIcomingOption(e.target.value);
    const selectedOption = koinIncomingOptions.find(
      (option) => option.value.toString() === e.target.value
    );

    if (selectedOption) {
      setKoinInstallments(selectedOption.installments);
    } else {
      setKoinInstallments([]);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div
        style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <label htmlFor="incomingOptionsLabel">Entradas Disponíveis:</label>
        <select
          className="form-control foco-input"
          name="incomingOptions"
          value={selectedIcomingOption}
          onChange={(e) => handleChangeIcomingOption(e)}
          style={{ width: '150px' }}
        >
          {koinIncomingOptions?.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {currency(
                  option.installments[option.installments.length - 1]
                    .incomingValue
                )}
              </option>
            );
          })}
        </select>
      </div>
      <div
        style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <form>
          <ul
            style={{
              listStyleType: 'none',
              fontSize: '12px',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '50px',
              padding: 0,
            }}
          >
            {koinInstallments.map((installment) => (
              <KoinInstallment
                saleTotal={total}
                installment={installment}
                onChange={handleChangePaymentId}
              />
            ))}
          </ul>
        </form>
      </div>
    </>
  );
}
