import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import constants from 'utils/constants';
import SerivicesTable from './ServicesTable';

const DiscountGroupService = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastros',
              'Vendas',
              'Tabela de Preços Diferenciados',
              'Serviços',
            ]}
            path={[
              '/client/' + constants.ROUTES.HOME,
              null,
              null,
              '/client/' + constants.ROUTES.DISCOUNT_GROUPS,
              null,
            ]}
          />
          <SerivicesTable />
        </Row>
      </Grid>
    </div>
  );
};
export default DiscountGroupService;
