import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card/Card.jsx';
import useFilters from '../../../hooks/useFilters';
import schedulesRepository from '../../../repositories/Schedules';
import boxPrismRepository from '../../../repositories/PrismaBox';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import SearchInput from 'components/SearchInput';
import DownloadXlsButton from 'components/DownloadXlsButton';
import {
  faCloudDownloadAlt,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import AlertModal from 'components/AlertModal/AlertModal';

const HomeSchedules = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [boxPrisms, setBoxPrisms] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [schedulingIdToDelete, setSchedulingIdToDelete] = useState(0);
  const [schedulesToExport, setSchedulesToExport] = useState([]);

  const { companyId } = useAuth();

  const { dateFilter, queryFilter, typeFilter } = useFilters();
  const { type, setType, filterByType } = typeFilter;
  const { finalDate, initialDate, setFinalDate, setInitialDate, filterByDate } =
    dateFilter;
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const receiveInitialDate = params.get('firstDate');
    const receiveFinalDate = params.get('secondDate');
    if (receiveInitialDate && receiveFinalDate) {
      setInitialDate(receiveInitialDate);
      setFinalDate(receiveFinalDate);
    }
  }, [schedules]);

  useEffect(() => {
    if (!!companyId) {
      loadSchedules();
    }
  }, []);

  useEffect(() => {
    loadBoxPrisms();
  }, []);

  useEffect(() => {
    const schedulesToExport = schedules.map((schedule) => ({
      ...schedule,
      prismBoxDescription: schedule.PrismaBox.description,
      salesCode: schedule?.Sales?.Code || '-',
      customerName: schedule.Customer.Company_Name,
      licensePlate:
        schedule?.Sales?.License_Plate ||
        schedule?.Vehicle?.License_Plate ||
        '-',
      vehicle: schedule?.Vehicle?.Model || '-',
      initialDateFormated: format(
        new Date(schedule.startScheduling),
        'dd-MM-yyyy HH:mm'
      ),
      finalDateFormated: format(
        new Date(schedule.endScheduling),
        'dd-MM-yyyy HH:mm'
      ),
    }));

    setSchedulesToExport(schedulesToExport);
  }, [schedules, initialDate, finalDate, query, type]);

  const loadBoxPrisms = async () => {
    setLoading(true);
    const { data: boxPrisms } = await boxPrismRepository.index({
      companyId,
    });
    setBoxPrisms(boxPrisms);
  };

  async function loadSchedules() {
    setLoading(true);

    try {
      const { data: schedules } = await schedulesRepository.index({
        companyId,
      });
      setSchedules(schedules);
    } catch (err) {
      return toastr.warning(
        'Ocorreu um erro ao carregar os agendamentos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  const handleFilters = (schedules) => {
    const searchQuery = [
      schedules['Sales']?.['Code'],
      schedules['Sales']?.['License_Plate'],
      schedules['Vehicle']?.['License_Plate'],
      schedules['Customer']?.['Company_Name'],
    ];
    return (
      filterByQuery(searchQuery) &&
      filterByDate('startScheduling', schedules) &&
      filterByType('boxPrism', schedules)
    );
  };
  const filteredSchedules = schedules.filter(handleFilters);

  const handleOpenDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setSchedulingIdToDelete(id);
  };

  const handleDelete = async () => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    try {
      await schedulesRepository.destroy(schedulingIdToDelete);
      toastr.success('Deletado com sucesso');
      loadSchedules();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao delete o agendamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        content={
          <div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <button
                  className="btn btn-sucesso button-h35"
                  onClick={() =>
                    companyId
                      ? history.push(constants.ROUTES.NEW_SCHEDULING)
                      : setNoCompanyModal(true)
                  }
                  style={{ fontSize: '12px' }}
                >
                  + Novo Agendamento
                </button>
              </div>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Nome, Placa e Nº de Orçamento/OS"
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '320px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Box/Prisma:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Todos</option>
                  {boxPrisms.map((boxPrism) => (
                    <option key={boxPrism.id} value={boxPrism.id}>
                      {boxPrism.description}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <DownloadXlsButton
                  archiveName={`Agendamento`}
                  data={schedulesToExport.filter(handleFilters)}
                  disabled={!!!filteredSchedules.length}
                  className="btn btn-export"
                  columns={[
                    {
                      name: 'Inicio',
                      acessor: 'initialDateFormated',
                    },
                    {
                      name: 'Final',
                      acessor: 'finalDateFormated',
                    },
                    {
                      name: 'Box/Prisma',
                      acessor: 'prismBoxDescription',
                    },
                    {
                      name: 'Venda/OS',
                      acessor: 'salesCode',
                    },
                    {
                      name: 'Nome',
                      acessor: 'customerName',
                    },
                    {
                      name: 'Placa',
                      acessor: 'licensePlate',
                    },
                    {
                      name: 'Veículos',
                      acessor: 'vehicle',
                    },
                  ]}
                  style={{ fontSize: '12px' }}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                  &nbsp;Exportar .xls
                </DownloadXlsButton>
              </div>
            </div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Inicial:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="datetime-local"
                  name="dataInicial"
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                  max={finalDate}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Final:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="datetime-local"
                  name="dataFinal"
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                  min={initialDate}
                  style={{ fontSize: '12px' }}
                />
              </div>
            </div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={schedules.filter(handleFilters)}
              columns={[
                {
                  Header: 'Inicio',
                  accessor: 'startScheduling',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) =>
                    format(new Date(props.value), 'dd/MM/yyyy HH:mm'),
                },
                {
                  Header: 'Final',
                  accessor: 'endScheduling',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) =>
                    format(new Date(props.value), 'dd/MM/yyyy HH:mm'),
                },
                {
                  Header: 'Box/Prisma',
                  accessor: 'PrismaBox.description',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 100,
                },
                {
                  Header: 'Venda/OS',
                  accessor: 'Sales.Code',
                  headerClassName: 'text-center',
                  className: 'texto',
                  Cell: (props) => {
                    if (props.value) {
                      return props.value;
                    } else {
                      return '-';
                    }
                  },
                },
                {
                  Header: 'Nome',
                  accessor: 'Customer.Company_Name',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Placa',
                  accessor: 'Sales.License_Plate',
                  headerClassName: 'text-center',
                  width: 100,
                  className: 'texto',
                  Cell: (props) => {
                    if (props.value) {
                      return props.value;
                    }

                    if (props.original?.Vehicle?.License_Plate) {
                      return props.original?.Vehicle?.License_Plate;
                    }

                    if (props.original?.Vehicle?.Maintenance_Object) {
                      return props.original?.Vehicle?.Maintenance_Object;
                    }

                    return '-';
                  },
                },
                {
                  Header: 'Veículos',
                  accessor: 'Vehicle.Model',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-center',
                  filterable: false,
                  className: 'texto',
                  width: 90,
                  style: { display: 'flex', justifyContent: 'center' },
                  Cell: (props) => (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '50px 50px',
                        marginLeft: '10px',
                      }}
                    >
                      <Link
                        to={{ pathname: `scheduling#${props.original.id}` }}
                      >
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                      <FontAwesomeIcon
                        onClick={() => handleOpenDeleteModal(props.original.id)}
                        title="Deletar"
                        style={{
                          height: '1.4em',
                          width: '1.5em',
                          color: 'red',
                        }}
                        cursor="pointer"
                        icon={faTrash}
                      />
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Date',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum agendamento encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        }
      />
      <AlertModal
        show={isDeleteModalOpen}
        message="Deseja apagar o cadastro?"
        onCancel={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDelete}
        onHide={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default withRouter(HomeSchedules);
