import React, { useEffect, useState } from 'react';
import { ProgressBarContainer, ProgressBarFill } from './ProgressBar.styles';

export function ProgressBar({
  progress,
  progressLabel,
  showProgressPercentage = true,
  type = 'percentage',
  total,
}) {
  return (
    <div className="flex column center gap-050">
      <ProgressBarContainer>
        <ProgressBarFill progress={progress} type={type} total={total} />
      </ProgressBarContainer>
      {showProgressPercentage && <span>{progressLabel}</span>}
    </div>
  );
}
