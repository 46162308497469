import React from 'react'
import { useRecoilState } from 'recoil'

import AlertModal from 'components/AlertModal/AlertModal'

import { FINANCING_SHOW_CANCEL_CONFIRMATION } from '../recoil'

const ConfirmationDialogContent = () => (
  <>
    <span>
      <strong>Você tem certeza que deseja cancelar o financiamento?</strong>
    </span>
    <br />
    <br />
    <span>
      A validação de crédito e solicitação ainda não foi realizada. Caso precise
      realizar novamente, clique no botão Financiamento (24x).
    </span>
  </>
)

export default function FinancingCancelConfirmationDialog({
  handleCloseFinancingModal,
}) {
  const [isOpen, setIsOpen] = useRecoilState(FINANCING_SHOW_CANCEL_CONFIRMATION)

  function handleCloseConfirmationDialog() {
    setIsOpen(false)
  }

  function handleConfirmation() {
    setIsOpen(false)
    handleCloseFinancingModal()
  }

  return (
    <AlertModal
      title="OS Digital"
      show={isOpen}
      message={<ConfirmationDialogContent />}
      onCancel={handleCloseConfirmationDialog}
      onSubmit={handleConfirmation}
      onHide={handleCloseConfirmationDialog}
    />
  )
}
