import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { kmMask } from 'client/components/ToNormalize/ToNormalize'
import React from 'react'
import ReactTable from 'react-table'

const BikeTable = ({
  vehicles,
  handleOpenEditVehicleModal,
  handleOpenDeleteVehicleModal,
  pageLimit,
  currentPage,
  totalPages,
  setCurrentPage,
  setPageLimit,
  loading,
}) => {
  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={vehicles}
        columns={[
          {
            Header: 'Série/Identific.',
            accessor: 'serialIdentification',
          },
          {
            Header: 'Marca',
            accessor: 'Brand',
          },
          {
            Header: 'Modelo',
            accessor: 'Model',
            className: 'texto',
          },
          {
            Header: 'Ano',
            accessor: 'Year_Manufacture',
          },
          {
            Header: 'Cor',
            accessor: 'Color',
          },
          {
            Header: 'Nº Marchas D.',
            accessor: 'bicycleFrontGearId',
          },
          {
            Header: 'Nº Marchas T.',
            accessor: 'bicycleRearGearId',
          },
          {
            Header: 'Ações',
            accessor: 'id',
            filterable: false,
            Cell: (props) => (
              <div className="react-table-actions-wrapper">
                <FontAwesomeIcon
                  icon={faEdit}
                  cursor="pointer"
                  onClick={() => handleOpenEditVehicleModal(props.value)}
                />
                {!props.original.hasSale && (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color="#d9534f"
                    cursor="pointer"
                    onClick={() => handleOpenDeleteVehicleModal(props.value)}
                  />
                )}
              </div>
            ),
          },
        ]}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        defaultPageSize={5}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum veículo encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default BikeTable
