import React from "react";
import { Grid, Row } from "react-bootstrap";

import MyUsers from './Users'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Users = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={["Início", "Meus Usuários"]} path={["home", null]}/>
          <MyUsers/>
        </Row>
      </Grid>
    </div>
  );
}

export default Users