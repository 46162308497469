import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { xlsColumns, formatProducts } from './excelHelper'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { format, isValid, isAfter, isBefore } from 'date-fns'
import { currency } from 'client/components/ToNormalize/ToNormalize'

import ReactTable from 'react-table'
import { toastr } from 'react-redux-toastr'
import Card from 'components/Card/Card.jsx'
import DownloadXlsButton from 'components/DownloadXlsButton'
import Button from 'client/components/CustomButton/Button.jsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import purchaseItensRepository from 'repositories/PurchaseItens'

const BundleAprovedDisaprovedItens = () => {
  const { companyId } = useAuth()

  const [loading, setLoading] = useState(false)
  const [entryType, setEntryType] = useState(1)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [status, setStatus] = useState(false)

  const [itens, setItens] = useState([])
  const [itensToExport, setItensToExport] = useState([])

  useEffect(() => {
    const formmatedToExport = formatProducts(itens)
    setItensToExport(formmatedToExport)
  }, [itens])

  async function loadItens(state) {
    setLoading(true)

    try {
      if (isBefore(new Date(finalDate), new Date(initialDate))) {
        return toastr.warning(
          'Selecione um período válido para gerar o relatório e tente novamente.'
        )
      }

      const { data: itens, headers } = await purchaseItensRepository.index({
        initialDate,
        finalDate,
        status,
        entryType,
        companyId,
      })

      const serializedItens = itens.map((item) => {
        return {
          entryDate: getDateOnlyFromDate(item.Purchases.entryAt),
          purchase: item.Purchases.code,
          isApproved: item.isApproved,
          approvedDate:
            isValid(getDateOnlyFromDate(item.approvedDate)) &&
            isAfter(
              getDateOnlyFromDate(item.approvedDate),
              getDateOnlyFromDate('01/01/2000')
            )
              ? getDateOnlyFromDate(item.approvedDate)
              : null,
          description: item.description,
          multiplierBase: item.multiplierBase,
          quantity: item.quantity,
          unitValue: item.unitValue,
          total: item.total,
        }
      })

      return setItens(serializedItens)
    } catch (err) {
      console.log(err)
      return toastr.warning(
        'Ocorreu um erro ao buscar os itens, tente novamente mais tarde'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <header style={{ display: 'flex' }}>
            <section style={{ marginRight: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>Tipo de Data: </label>
              <select
                value={entryType}
                onChange={(e) => setEntryType(e.target.value)}
                className='form-control foco-input'
              >
                <option value={1}>Data de Entrada</option>
                <option value={2}>Data de Aprovação</option>
              </select>
            </section>
            <section style={{ marginRight: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>Data Inicial: </label>
              <input
                type='date'
                max='9999-12-31'
                className='form-control foco-input'
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </section>
            <section style={{ marginRight: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>Data Final: </label>
              <input
                type='date'
                max='9999-12-31'
                className='form-control foco-input'
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </section>
            <div style={{ marginRight: '5px', width: '20%' }}>
              <label style={{ fontWeight: 'bold' }}>Status: </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className='form-control foco-input'
              >
                <option value={false}>Não Aprovado</option>
                <option value={true}>Aprovado</option>
                <option value={''}>Ambos</option>
              </select>
            </div>
            <section style={{ marginTop: '38px', marginRight: '5px' }}>
              <button
                className='btn btn-sucesso'
                type='submit'
                disabled={loading || !initialDate || !finalDate}
                onClick={() => loadItens()}
              >
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />
                Gerar Relatório
              </button>
            </section>
            <div style={{ marginTop: '38px' }}>
              <DownloadXlsButton
                archiveName={`Itens_Aprovados_NãoAprovados_${initialDate}_${finalDate}`}
                data={itensToExport}
                className='btn btn-export'
                disabled={loading || itens.length < 1}
                columns={xlsColumns}
              >
                <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} />
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </header>

          <main style={{ marginTop: 20 }}>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={itens}
              columns={[
                {
                  Header: 'Data Ent.',
                  accessor: 'entryDate',
                  headerClassName: 'text-center',
                  width: 100,
                  Cell: (props) => (
                    <span>
                      {!!props.value ? format(props.value, 'dd/MM/yyyy') : ''}
                    </span>
                  ),
                },
                {
                  Header: 'Compra',
                  accessor: 'purchase',
                  headerClassName: 'text-center',
                  width: 80,
                },
                {
                  Header: 'Status',
                  accessor: 'isApproved',
                  headerClassName: 'text-left',
                  width: 120,
                  Cell: (props) =>
                    props.value === true ? (
                      <Button
                        id='botaoReactTable'
                        style={{ backgroundColor: '#5cb85c', color: 'white' }}
                      >
                        Aprovado
                      </Button>
                    ) : (
                      <Button
                        id='botaoReactTable'
                        style={{ backgroundColor: 'red', color: 'white' }}
                      >
                        Não Aprovado
                      </Button>
                    ),
                },
                {
                  Header: 'Data Aprov.',
                  accessor: 'approvedDate',
                  headerClassName: 'text-center',
                  width: 100,
                  Cell: (props) => (
                    <span>
                      {!!props.value ? format(props.value, 'dd/MM/yyyy') : ''}
                    </span>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-center',
                  width: 350,
                },
                {
                  Header: 'Bs. Multi',
                  accessor: 'multiplierBase',
                  headerClassName: 'text-center',
                  width: 70,
                },
                {
                  Header: 'QTD',
                  accessor: 'quantity',
                  headerClassName: 'text-center',
                  width: 60,
                },
                {
                  Header: 'Valor Unitário',
                  accessor: 'unitValue',
                  headerClassName: 'text-center',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Total',
                  accessor: 'total',
                  headerClassName: 'text-center',
                  Cell: (props) => currency(props.value),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'entryDate',
                  desc: false,
                },
              ]}
              previousText='Anterior'
              nextText='Próximo'
              loadingText='Carregando...'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              noDataText={
                initialDate && finalDate
                  ? 'Não há produtos importados no período selecionado.'
                  : 'Selecione um período para filtrar os itens.'
              }
            />
          </main>
        </div>
      }
    />
  )
}

export default BundleAprovedDisaprovedItens
