import React, { memo } from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { currency } from 'client/components/ToNormalize/ToNormalize'

function TableByFamily(props) {
    const { data, loading, opacity } = props

    return (
        <>
            <h4><strong>Vendas por Família (R$)</strong></h4>
            <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center', opacity: opacity }}
                data={data}
                columns={[
                    {
                        Header: "Descrição",
                        accessor: "family",
                        headerClassName: "text-left",
                        width: 250,
                        className: "texto"
                    },
                    {
                        Header: "Tipo",
                        accessor: "type",
                        headerClassName: "text-left",
                        width: 80,
                        className: "texto"
                    },
                    {
                        Header: "Valor (R$)",
                        accessor: "amount",
                        headerClassName: "text-left",
                        width: 150,
                        className: "texto",
                        Cell: cell => cell.value.toString().includes('R$') ? cell.value : currency(cell.value)
                    }
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination={false}
                sortable={true}
                loadingText='Carregando...'
                noDataText='Nenhuma informação encontrada'
            />
        </>
    )
}

export default memo(TableByFamily)