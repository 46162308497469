import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import OperationTaxesMain from './components/Main'

import './styles.css'
import { useState } from 'react'

const OperationTaxes = ({ history, location }) => {
  const [patternName, setPatternName] = useState('')
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Configurações NFS-e',
          `${patternName}`,
          'Tributos e Operações',
        ]}
        path={['home', 'nfse-settings', null, null]}
      />
      <OperationTaxesMain
        patternId={history?.location?.hash?.replace('#', '')}
        setPatternName={setPatternName}
      />
    </>
  )
}

export default OperationTaxes
