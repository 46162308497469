import React from 'react';
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import HomePurchases from './Purchases'
import constants from '../../../utils/constants'

function Purchases() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb 
            data={['Início', 'Produtos', 'Compras']}
            path={[constants.ROUTES.HOME, constants.ROUTES.PRODUCTS, null]}
          />
          <HomePurchases />
        </Row>
      </Grid>
    </div>
  )
}

export default Purchases;