import api from 'v2/services/api';

export const listAllSaleSubtypeActive = async (companyId) => {
  try {
    const res = await api.get('sales-subtype/active-by-company/' + companyId);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getSalesSubtype = async (id, companyId) => {
  try {
    const res = await api.get('sales-subtype/' + id, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listSalesSubtype = async (params) => {
  try {
    const res = await api.get('sales-subtype', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createSalesSubtype = async (data) => {
  try {
    const res = await api.post('sales-subtype/', data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateSalesSubtype = async (id, data) => {
  try {
    const res = await api.put('sales-subtype/' + id, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteSalesSubtype = async (id) => {
  try {
    await api.delete('sales-subtype/' + id);
  } catch (err) {
    throw err;
  }
};

const SalesSubtypeRepository = {
  listAllSaleSubtypeActive,
  getSalesSubtype,
  listSalesSubtype,
  createSalesSubtype,
  updateSalesSubtype,
  deleteSalesSubtype,
};

export default SalesSubtypeRepository;
