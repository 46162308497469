import api from '../services/api'
import config from '../config'

const index = () => api.get(config.endpoint + 'integration/matera-company')

const show = (id) =>
  api.get(config.endpoint + `integration/matera-company/${id}`)

const showByCompanyId = (id) =>
  api.get(config.endpoint + `integration/matera-company-id/${id}`)

const getDefaultTaxes = () => api.get(config.endpoint + 'default-taxes')

const create = (data) =>
  api.post(config.endpoint + 'integration/matera-company', data, {
    validateStatus: () => true,
  })

const update = (id, data) =>
  api.put(config.endpoint + `integration/matera-company/${id}`, data, {
    validateStatus: () => true,
  })

const getBalance = (companyId) =>
  api.get(config.endpoint + `integration/matera-company-balance/${companyId}`)

const getExtract = async (companyId, params) => {
  return await api.get(
    config.endpoint + `integration/matera-company-extract/${companyId}`,
    {
      params,
    }
  )
}

export default {
  index,
  getDefaultTaxes,
  create,
  show,
  showByCompanyId,
  update,
  getBalance,
  getExtract,
}
