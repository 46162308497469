import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import profilesRepository from '../../../../../repositories/Profiles'
import constants from '../../../../../utils/constants'
import { useHistory } from 'react-router-dom'
import FormPermissionsAccess from './FormPermissionsAccess'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'

import permissionsRepository from '../../../../../repositories/AccessPermissions'

const initialValues = {
  AccessPermissions: [],
  accessTotal: false,
  permissions: [],
  permissionsToDelete: [],
  permissionsToAdd: [],
}

const BundleFormPermissionsAccess = ({ profileId, modal, hideModal }) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (profileId) loadProfile(profileId)
  }, [profileId])

  const loadPermissions = async (profile) => {
    try {
      const { data } = await permissionsRepository.show()

      const permissions = data.map((permission) => {
        const checked = !!profile?.AccessPermissions?.find(
          (p) => p.permissionId === permission.id
        )
        return {
          ...permission,
          checked,
        }
      })

      const initializeForm = {
        ...initialValues,
        permissions,
        accessTotal: data?.length === profile.AccessPermissions?.length,
        ...profile,
      }

      dispatch(initialize('formPermissionsAccess', initializeForm))
    } catch (err) {
      toastr.warning('Ocorreu um erro ao carregar as permissões.')
    }
  }

  const loadProfile = async (id) => {
    setLoading(true)
    try {
      const { data } = await profilesRepository.getById(id)
      loadPermissions(data)
    } catch (err) {
      toastr.error(
        err.response?.data?.message ||
          'Ocorreu algum problema ao carregar o cargo.',
        'Por favor, tente novamente mais tarde.'
      )
    } finally {
      setLoading(false)
    }
  }

  const submit = (values) => {
    const { permissionsToAdd, permissionsToDelete } = values
    addPermissions(profileId, permissionsToAdd, permissionsToDelete)
  }

  const addPermissions = async (
    profileId,
    permissionsToAdd,
    permissionsToDelete
  ) => {
    setLoading(true)
    try {
      await permissionsRepository.savePermissions(
        profileId,
        permissionsToAdd,
        permissionsToDelete
      )
      toastr.success('Permissões aplicadas com sucesso.')
      history.push(constants.ROUTES.PROFILES)
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao registrar perfil. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormPermissionsAccess
      modal={modal}
      hideModal={hideModal}
      onSubmit={submit}
      initialValues={initialValues}
      loading={loading}
      profileId={profileId}
    />
  )
}

export default BundleFormPermissionsAccess
