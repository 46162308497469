import React from 'react'
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import SuccessReportMain from './SuccessReportMain'

function SuccessReport() {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Relatórios', 'Pontos de Sucesso']}
        path={['home', null, 'success-points-report']}
      />
      <SuccessReportMain />
    </>
  )
}

export default SuccessReport
