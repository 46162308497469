import React from 'react';
import { reduxForm } from 'redux-form';

import CardForm from 'client/components/CardForm';
import Title from './Title';
import Footer from './Footer';
import Payment from '../../../components/BillPayments';

import './styles.css';

const FormBillToPay = ({
  handleSubmit,
  cancel,
  loading,
  titleId,
  isPurchase,
  setIsRemoveConciliationModalOpen,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm title="Dados do Título">
        <Title titleId={titleId} isPurchase={isPurchase} />
      </CardForm>

      <CardForm title="Dados do Pagamento">
        <Payment loading={loading} name="billToPay" />
      </CardForm>

      <Footer
        handleSubmit={handleSubmit}
        handleCancel={cancel}
        titleId={titleId}
        loading={loading}
        setIsRemoveConciliationModalOpen={setIsRemoveConciliationModalOpen}
      />
    </form>
  );
};

export default reduxForm({
  form: 'billToPay',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormBillToPay);
