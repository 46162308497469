import config from '../config'
import api from '../services/api'

const generateSingleQuotationResponseReport = async (quotationResponseId) => {
  const respose = await api.get(
    config.endpoint +
      `quotation-response/quotation-report/${quotationResponseId}`
  )

  return respose.data
}

const verifyResponseByQuotationAndProvider = async (
  quotationId,
  providerId,
  headers
) => {
  const response = await api.get(
    config.endpoint +
      `quotation-response/validate/${quotationId}?providerId=${providerId}`,
    { headers }
  )

  return response.data
}

export default {
  generateSingleQuotationResponseReport,
  verifyResponseByQuotationAndProvider,
}
