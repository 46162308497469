import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'components/Card/Card.jsx';
import DownloadXlsButton from 'components/DownloadXlsButton';

import Button from 'client/components/CustomButton/Button.jsx';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';

import { useAuth } from 'contexts/auth';

import birthdaysRepository from 'repositories/BirthdaysReports';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { addDays, format, isBefore } from 'date-fns';

import '../styles.css';
import SearchInput from 'components/SearchInput';

export default function BirthdaysReportMain() {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [birthdays, setBirthdays] = useState([]);
  const [birthdaysHasLoaded, setBirthdaysHasLoaded] = useState(false);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [personType, setPersonType] = useState('');
  const [query, setQuery] = useState('');
  const [totalNumberBirthdays, setTotalNumberBirthdays] = useState(null);
  const [dataToExportXls, setDataToExportXls] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (companyId) {
      handleSetInitialParams();
    }
  }, [companyId]);

  useEffect(() => {
    if (birthdays.length > 0) {
      handleLoadBirthdays(initialDate, finalDate, personType);
    }
  }, [currentPage, pageLimit]);

  const handleSetInitialParams = () => {
    const params = new URLSearchParams(window.location.search);

    const receivedInitialDate = params.get('firstDate');
    const receivedFinalDate = params.get('secondDate');
    const personTypeDashboard = 'BOTH';

    if (receivedInitialDate && receivedFinalDate) {
      setInitialDate(receivedInitialDate);
      setFinalDate(receivedFinalDate);
      setPersonType('BOTH');
      handleLoadBirthdays(
        receivedInitialDate,
        receivedFinalDate,
        personTypeDashboard
      );
      handleLoadBirthDaysToExport(
        receivedInitialDate,
        receivedFinalDate,
        personTypeDashboard
      );
    }
  };

  const handleValidateSubmit = () => {
    if (
      !initialDate ||
      !finalDate ||
      isBefore(getDateOnlyFromDate(finalDate), getDateOnlyFromDate(initialDate))
    ) {
      return toastr.warning('Selecione um período válido');
    }

    if (!personType) {
      return toastr.warning('Selecione o tipo');
    }

    handleLoadBirthdays(initialDate, finalDate, personType);
    handleLoadBirthDaysToExport(initialDate, finalDate, personType);
  };

  const handleLoadBirthDaysToExport = async (
    initialDate,
    finalDate,
    personType
  ) => {
    const responseToExportXls = await birthdaysRepository.getAllBirthdays({
      query,
      personType,
      initialDate,
      finalDate,
      companyId,
    });

    const serializedDataToExportXls = responseToExportXls.data.map((item) => ({
      ...item,
      birthday: format(addDays(new Date(item.birthday), 1), 'dd/MM/yyyy'),
      phone: phoneMask(item.phone),
    }));

    setDataToExportXls(serializedDataToExportXls);
  };

  const handleLoadBirthdays = async (initialDate, finalDate, personType) => {
    setLoading(true);

    try {
      const response = await birthdaysRepository.getAllBirthdays({
        query,
        personType,
        initialDate,
        finalDate,
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
      });

      const serializedBirthdays = response.data.map((item) => ({
        ...item,
        birthday: format(addDays(new Date(item.birthday), 1), 'dd/MM/yyyy'),
        phone: phoneMask(item.phone),
      }));

      setBirthdays(serializedBirthdays);
      setTotalPages(Math.ceil(response.count / pageLimit));
      setTotalNumberBirthdays(response.count);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'OS Digital',
        'Ocorreu um erro ao buscar os aniversariantes. Por favor, tente novamente!'
      );
    } finally {
      setLoading(false);
      setBirthdaysHasLoaded(true);
    }
  };

  return (
    <Card
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '10px',
          }}
        >
          <div className="birthdays-row">
            <fieldset>
              <span className="typography__text-2">Data Inicial:</span>
              <input
                type="date"
                className="form-control foco-input fontSize-12"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <span className="typography__text-2">Data Final:</span>
              <input
                type="date"
                className="form-control foco-input fontSize-12"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </fieldset>
            <div>
              <span className="typography__text-2">Tipo:</span>
              <select
                value={personType}
                onChange={(e) => setPersonType(e.target.value)}
                className="form-control foco-input fontSize-12"
              >
                <option value="">Selecione</option>
                <option value="BOTH">Ambos</option>
                <option value="CUSTOMER">Cliente</option>
                <option value="EMPLOYEE">Funcionário</option>
              </select>
            </div>
            <fieldset>
              <button
                className="btn btn-sucesso button-h35 fontSize-12"
                type="submit"
                onClick={handleValidateSubmit}
                disabled={!initialDate || !finalDate || !personType || loading}
              >
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />{' '}
                Gerar relatório
              </button>
            </fieldset>
            <div>
              <DownloadXlsButton
                archiveName={`Aniversariantes-${initialDate
                  .split('-')
                  .reverse()
                  .join('/')}-${finalDate.split('-').reverse().join('/')}`}
                data={dataToExportXls}
                className="btn btn-export button-h35 fontSize-12"
                disabled={loading || !dataToExportXls.length}
                columns={[
                  {
                    name: 'Nome Completo',
                    acessor: 'name',
                  },
                  {
                    name: 'Data de Nascimento',
                    acessor: 'birthday',
                  },
                  {
                    name: 'Tipo',
                    acessor: 'type',
                  },
                  {
                    name: 'Celular',
                    acessor: 'phone',
                  },
                  {
                    name: 'E-mail',
                    acessor: 'email',
                  },
                ]}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                <span style={{ marginLeft: '5px' }}>Exportar .xls</span>
              </DownloadXlsButton>
            </div>
          </div>

          <div style={{ width: '100%' }}>
            <SearchInput
              containerStyle={{ width: 500 }}
              placeholder="Pesquisa por Nome Completo, Celular ou E-mail"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              style={{ fontSize: '12px' }}
            />
          </div>

          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={birthdays}
              columns={[
                {
                  Header: 'Nome Completo',
                  accessor: 'name',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Data de Nascimento',
                  accessor: 'birthday',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  headerClassName: 'text-left',
                  width: 150,
                  Cell: (props) => {
                    return props.value === 'Cliente' ? (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: '#428bca',
                          color: 'white',
                        }}
                      >
                        Cliente
                      </Button>
                    ) : (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: '#5cb85c',
                          color: 'white',
                        }}
                      >
                        Funcionário
                      </Button>
                    );
                  },
                },
                {
                  Header: 'Celular',
                  accessor: 'phone',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'E-mail',
                  accessor: 'email',
                  headerClassName: 'text-left',
                },
              ]}
              defaultPageSize={10}
              pageSize={pageLimit}
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              manual
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText={
                birthdaysHasLoaded
                  ? birthdays.length > 0
                    ? ''
                    : 'Não há aniversariantes no período'
                  : 'Selecione o período e o tipo para ver os aniversariantes.'
              }
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </main>

          <div>
            <p
              style={{
                textAlign: 'end',
              }}
            >
              Total de aniversariantes: {totalNumberBirthdays}
            </p>
          </div>
        </div>
      }
    />
  );
}
