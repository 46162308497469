import api from '../services/api'

const show = async (id) => {
  const pattern = await api.get(`/nfse-pattern/${id}`)
  return pattern
}

const index = async ({
  codeOrNameCity,
  statePattern,
  pattern,
  page,
  limit,
}) => {
  const patterns = await api.get(`/nfse-pattern/list/cities`, {
    params: {
      codeOrNameCity,
      statePattern,
      page,
      limit,
      pattern,
    },
  })

  return patterns.data
}

const create = async (patternId, data) => {
  const createdPattern = await api.post(
    `/nfse-pattern/cities/new-city/${patternId}`,
    data
  )
  return createdPattern.data
}

const update = async (patternId, data) => {
  const createdPattern = await api.put(
    `/nfse-pattern/cities/${patternId}`,
    data
  )
  return createdPattern.data
}

const deleteCity = async (id) => {
  return await api.delete(`/nfse-pattern/cities/${id}`)
}

const NFSePatternCitiesRepository = {
  show,
  create,
  update,
  index,
  deleteCity,
}

export default NFSePatternCitiesRepository
