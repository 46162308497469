import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  currency,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const HeaderTotalsByEmployeeAndStatus = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Funcionário</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Status</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Quantidade</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowTotalsByEmployeeAndStatus = ({ nameEmployee, status, quantity, value }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{nameEmployee}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{status}</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}>
      <Text style={styles.span}>{quantity}</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(value)}</Text>
    </View>
  </View>
)

const ItemsHeaderSales = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Data Criação</Text>
    </View>
    <View style={{ width: '18%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Data de Pagamento</Text>
    </View>
    <View style={{ width: '22%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Funcionário</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Valor</Text>
    </View>
    <View style={{ width: '16%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Banco/Caixa</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Status</Text>
    </View>
    <View style={{ width: '16%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Data de Cancelamento</Text>
    </View>
  </View>
)

const ItemRowSales = ({ item }) => {
  return (
    <View
      style={{ flexDirection: 'row', height: 12, width: '100%' }}
      wrap={false}
    >
      <View
        style={{ width: '8%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{format(new Date(item.createdAt), 'dd/MM/yyyy')}</Text>
      </View>
      <View
        style={{ width: '18%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.status === 'Finalizada' ? format(new Date(item.closingDate), 'dd/MM/yyyy') : ''}</Text>
      </View>
      <View
        style={{ width: '22%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Employee?.name}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.value)}</Text>
      </View>
      <View
        style={{ width: '16%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.CashierBank?.cashierBankTypeId == 1 ? 'BC' + ' - ' + item.CashierBank?.description : 'CX' + ' - ' + item.CashierBank?.description}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.status}</Text>
      </View>
      <View
        style={{ width: '16%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.status === 'Cancelada' ? format(new Date(item.updatedAt), 'dd/MM/yyyy') : ''}</Text>
      </View>
    </View>
  )
}

export const VouchersIssuedPDF = ({
  result = [],
  totalsPerStatusAndEmployee = [],
  initialDate,
  finalDate,
}) => {

  return (
    <Document
      title={`impresso-vales-emitidos-${format(new Date(), 'dd/MM/yyyy')}`}
      subject='vales-emitidos'
    >
      <Page style={styles.page} orientation="landscape">
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Listagem de Vales Emitidos</Text>
          <View style={{ display: 'flex', width: '28%', justifyContent: 'space-between', flexDirection: 'row' }}>
            <ItemLabel label='Data Inicio' value={initialDate} />
            <ItemLabel label='Data Fim' value={finalDate} />
          </View>
        </View>
        <View style={[styles.line, { marginTop: 10 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: 1
          }}
        >
          Totais por Funcionário/Status
        </Text>
        <View style={styles.line} />
        <HeaderTotalsByEmployeeAndStatus />
        <View style={styles.line} />
        {totalsPerStatusAndEmployee.map(item => {

          const nameEmployee = item.reduce((prevValue, currentValue) => currentValue.Employee?.name, '')
          const status = item.reduce((prevValue, currentValue) => currentValue.status, 0)
          const quantity = item.length
          const value = item.reduce((prevValue, currentValue) => prevValue + currentValue.value, 0)

          return (
            <ItemRowTotalsByEmployeeAndStatus
              nameEmployee={nameEmployee}
              status={status}
              quantity={quantity}
              value={value}
            />
          )
        })}
        <View style={[styles.line, { marginTop: 5 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
          }}
        >
          Listagem de Vales
        </Text>
        <View style={styles.line} />
        <ItemsHeaderSales />
        <View style={styles.line} />
        {result.map((item, index) => (
          <ItemRowSales item={item} key={index} />
        ))}
      </Page>
    </Document>
  )
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
