import React, { useState, useEffect } from 'react';
import { change, Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import AlertModal from 'components/AlertModal/AlertModal';
import CircleButton from 'components/CirclePlusMinusButton';
import { currencyMask, percentMask } from 'utils/masks';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { quantityMask } from 'utils/masks';
import { percentage } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import ImagesModal from 'client/components/ProductsServicesTable/ImagesModal';
import ProductsRepository from 'repositories/Products';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { ModalDiscountReleaseWithPassword } from 'v2/components/Modals/DiscountRelease/ModalDiscountReleaseWithPassword';
import { handleConfirmEditItem } from 'client/views/Sales/NewSale/FormSale/redux/actions';
import { useAuth } from 'contexts/auth';

const ItensPdv = ({ setUpdateTotalPdvTrigger, readOnly }) => {
  const { company } = useAuth();
  const { validateFeatureAvailability } = useSidebar();
  const [vehicleModel, setVehicleModel] = useState(null);
  const [modal, setModal] = useState(false);
  const [updatedDiscountIndex, setUpdatedDiscountIndex] = useState(null);
  const [updatedTotalIndex, setUpdatedTotalIndex] = useState(null);
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [
    isModalDiscountReleaseWithPasswordOpen,
    setIsModalDiscountReleaseWithPasswordOpen,
  ] = useState(false);
  const [itemToEditIndex, setItemToEditIndex] = useState(null);
  const [productToVisualize, setProductToVisualize] = useState({});
  const isAllowedDiscountGreater = validateFeatureAvailability(
    {
      subFeatureId: 118,
    },
    false
  );

  const dispatch = useDispatch();
  const {
    cart,
    discountType,
    step,
    generalDiscountPercentage,
    generalDiscount,
    totalSale,
  } = useSelector((state) => state.form.pdvOnline.values);

  useEffect(() => {
    const totalCart = getTotalCart();
    dispatch(change('pdvOnline', 'totalSale', totalCart));
    dispatch(change('pdvOnline', 'generalDiscount', 0));
    dispatch(change('pdvOnline', 'generalDiscountPercentage', 0));
  }, [cart?.length]);

  useEffect(() => {
    if (updatedDiscountIndex !== null) {
      const finalValue =
        (cart[updatedDiscountIndex].Sale_Price -
          cart[updatedDiscountIndex].Sale_Price *
            (cart[updatedDiscountIndex].Discount_Rate / 100).toFixed(2)) *
        cart[updatedDiscountIndex].Quantity;

      dispatch(
        change('pdvOnline', `cart[${updatedDiscountIndex}].Total`, finalValue)
      );

      discountPercentValidation(
        cart[updatedDiscountIndex].Discount_Rate,
        updatedDiscountIndex
      );

      setUpdateTotalPdvTrigger(true);
      setUpdatedDiscountIndex(null);
    }
  }, [updatedDiscountIndex]);

  useEffect(() => {
    if (updatedTotalIndex !== null) {
      const item = cart.find((item, index) => index === updatedTotalIndex);

      const itemsSum = item.Quantity * item.Sale_Price;
      let newDiscountRate;

      if (item.Total > itemsSum) {
        dispatch(
          change(
            'pdvOnline',
            `cart[${updatedTotalIndex}].Total`,
            parseFloat(itemsSum.toFixed(2))
          )
        );
        newDiscountRate = 0;
      } else {
        const valuePerItem = item.Total / item.Quantity;

        newDiscountRate = 100 - (valuePerItem * 100) / item.Sale_Price;
      }

      dispatch(
        change(
          'pdvOnline',
          `cart[${updatedTotalIndex}].Discount_Rate`,
          parseFloat(newDiscountRate.toFixed(2))
        )
      );

      setUpdateTotalPdvTrigger(true);
      setUpdatedTotalIndex(null);
    }
  }, [updatedTotalIndex]);

  async function discountPercentValidation(value, index) {
    if (value > 99.99) {
      dispatch(change('pdvOnline', `cart[${index}].Discount_Rate`, 99.99));
    }

    const item = cart.find((item, i) => i === index);

    setItemToEditIndex(index);

    if (company.companyConfig.discountAboveLimit === 'NOT_ALLOWED') {
      if (item.hasDiscountLimit && value > Number(item.discountLimit)) {
        dispatch(change('pdvOnline', `cart[${index}].Discount_Rate`, 0));
        dispatch(change('pdvOnline', `cart[${index}].Total`, item.Sale_Price));

        return toastr.warning(
          'Atenção',
          `O desconto inserido é maior que o valor permitido (Desconto: ${cart[index].discountLimit}%)`
        );
      }
    } else {
      if (item.hasDiscountLimit && value > Number(item.discountLimit)) {
        await dispatch(
          handleConfirmEditItem(
            index,
            cart[index],
            cart,
            isAllowedDiscountGreater,
            setIsModalDiscountReleaseWithPasswordOpen
          )
        );
      }
    }
  }

  function getTotalCart() {
    const totalCart = !cart.length
      ? 0
      : cart.length === 1
      ? cart[0].Total
      : cart.map((item) => item.Total).reduce((prev, curr) => prev + curr);

    return totalCart;
  }

  const handleDeleteItem = () => {
    const cartFiltered = cart.filter((prod) => prod.id !== itemToDeleteId);
    setVehicleModel(null);
    setItemToDeleteId(null);
    setModal(false);
    dispatch(change('pdvOnline', 'cart', cartFiltered));
  };

  const handleQuestionToDeleteItem = (itemId, vehicle) => {
    setItemToDeleteId(itemId);
    setVehicleModel(vehicle);
    setModal(true);
  };

  const handleOpenProductImageModal = async (product) => {
    try {
      const productsPhotos = await ProductsRepository.getPhotos(product.id);

      if (!productsPhotos.length)
        return toastr.warning(
          'Produto sem foto',
          'Este produto não possui imagens para serem visualizadas'
        );

      setProductToVisualize({ ...product, ProductsPhotos: productsPhotos });
      setIsImagesModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as fotos do produto',
        'Por favor, tente novamente'
      );
    }
  };

  const handleChangeValueDiscountRate = async (e, index) => {
    dispatch(
      change('pdvOnline', `cart[${index}].Discount_Rate`, e.target.value)
    );

    setUpdatedDiscountIndex(index);
  };

  const onAuthenticationCancel = async () => {
    dispatch(change('pdvOnline', `cart[${itemToEditIndex}].Discount_Rate`, 0));
    dispatch(
      change(
        'pdvOnline',
        `cart[${itemToEditIndex}].Total`,
        cart[itemToEditIndex].Sale_Price
      )
    );
  };

  return (
    <div className="order-items">
      <legend>{step === 1 ? 'Listagem de Itens' : 'Itens do Pedido'}</legend>

      {!cart.length ? (
        <div className="no-products">
          <FontAwesomeIcon icon={faShoppingBasket} />
          <span>Não há nenhum produto nessa venda</span>
        </div>
      ) : (
        <div className="list-item">
          <table>
            <thead>
              <tr>
                <th>CÓD.</th>
                <th style={{ width: '25%' }}>DESCRIÇÃO</th>
                <th style={{ width: '20%' }}>
                  <center>QTDE.</center>
                </th>
                <th style={{ width: '15%' }}>
                  <center>VLR. UNIT.</center>
                </th>
                <th style={{ width: '20%', textAlign: 'center' }}>
                  DESC. UNIT. (%)
                </th>
                <th style={{ width: '15%' }}>
                  <center>TOTAL</center>
                </th>
                {step === 1 && <th style={{ color: 'transparent' }}>Ações</th>}
              </tr>
            </thead>
            <tbody>
              {cart.map((c, index) => (
                <tr key={index}>
                  <td>
                    <a
                      href="#"
                      className="hyperlink"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenProductImageModal(c);
                      }}
                    >
                      {c.Code}
                    </a>
                  </td>
                  <td>
                    <a
                      href="#"
                      className="hyperlink"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenProductImageModal(c);
                      }}
                    >
                      {c.Description}
                    </a>
                  </td>
                  <td>
                    {readOnly ? (
                      <span style={{ textAlign: 'center', display: 'block' }}>
                        {currency(c.Quantity).replace('R$', '')}
                        &nbsp;
                        {c.Unit_Type ? c.Unit_Type : ''}
                      </span>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <CircleButton
                          type="minus"
                          width="15px"
                          action={() => {
                            const newQuantity =
                              cart[index].Quantity - 1 < 1
                                ? 1
                                : cart[index].Quantity - 1;

                            dispatch(
                              change(
                                'pdvOnline',
                                `cart[${index}].Quantity`,
                                parseFloat(newQuantity)
                              )
                            );

                            setUpdatedDiscountIndex(index);
                          }}
                        />
                        <Field
                          name={`cart[${index}].Quantity`}
                          component="input"
                          className="form-control foco-input"
                          {...quantityMask}
                          style={{ fontSize: '12px' }}
                          onChange={(e) => {
                            dispatch(
                              change(
                                'pdvOnline',
                                `cart[${index}].Quantity`,
                                parseFloat(e.target.value)
                              )
                            );

                            setUpdatedDiscountIndex(index);
                          }}
                        />
                        <CircleButton
                          type="plus"
                          width="15px"
                          action={() => {
                            const newQuantity =
                              cart[index].Quantity + 1 < 1
                                ? 1
                                : cart[index].Quantity + 1;

                            dispatch(
                              change(
                                'pdvOnline',
                                `cart[${index}].Quantity`,
                                parseFloat(newQuantity)
                              )
                            );

                            setUpdatedDiscountIndex(index);
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {readOnly ? (
                      <span
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          fontSize: '12px',
                        }}
                      >
                        {currency(cart[index].Sale_Price)}
                      </span>
                    ) : (
                      <Field
                        name={`cart[${index}].Sale_Price`}
                        component="input"
                        className="form-control foco-input"
                        style={{ fontSize: '12px' }}
                        {...currencyMask}
                        onChange={(e) => {
                          dispatch(
                            change(
                              'pdvOnline',
                              `cart[${index}].Sale_Price`,
                              parseFloat(e.target.value)
                            )
                          );

                          setUpdatedDiscountIndex(index);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {readOnly ? (
                      <span
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          fontSize: '12px',
                        }}
                      >
                        {percentage(cart[index].Discount_Rate)}
                      </span>
                    ) : (
                      <Field
                        name={`cart[${index}].Discount_Rate`}
                        component="input"
                        className="form-control foco-input"
                        style={{ fontSize: '12px' }}
                        {...percentMask}
                        maxLength="7"
                        onChange={(e) =>
                          handleChangeValueDiscountRate(e, index)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {readOnly ? (
                      <span
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          fontSize: '12px',
                        }}
                      >
                        {currency(cart[index].Total)}
                      </span>
                    ) : (
                      <Field
                        name={`cart[${index}].Total`}
                        component="input"
                        className="form-control foco-input"
                        style={{ fontSize: '12px' }}
                        {...currencyMask}
                        onChange={(e) => {
                          dispatch(
                            change(
                              'pdvOnline',
                              `cart[${index}].Total`,
                              parseFloat(e.target.value)
                            )
                          );
                          setUpdatedTotalIndex(index);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {!readOnly && (
                      <div className="actions">
                        <FontAwesomeIcon
                          id="danger"
                          cursor="pointer"
                          icon={faTrashAlt}
                          onClick={() =>
                            handleQuestionToDeleteItem(c.id, c.vehicle)
                          }
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={productToVisualize.Description}
          productPhotos={productToVisualize.ProductsPhotos}
        />
      )}

      <AlertModal
        show={modal}
        message="Deseja excluir o item dessa venda?"
        onHide={() => setModal(false)}
        onCancel={() => setModal(false)}
        onSubmit={handleDeleteItem}
      />

      <ModalDiscountReleaseWithPassword
        open={isModalDiscountReleaseWithPasswordOpen}
        onClose={() => setIsModalDiscountReleaseWithPasswordOpen(false)}
        itemToEdit={cart[itemToEditIndex]}
        itemToEditIndex={itemToEditIndex}
        items={cart}
        onAuthenticationCancel={onAuthenticationCancel}
      />
    </div>
  );
};

export default ItensPdv;
