import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import HomeFamilies from './HomeFamilies';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Families = () => {
  localStorage.setItem('selectedPath', 'client/Familia');

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Produtos/Serviços', 'Famílias']}
            path={['home', null, null, 'Familia']}
          />
          <HomeFamilies />
        </Row>
      </Grid>
    </div>
  );
};

export default Families;
