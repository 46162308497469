import React, { useState } from 'react';

import ConsultReceivableAmountModal from 'client/components/ConsultReceivableAmountModal';

export default function ConsultReceivableAmountBtn() {
  const [isConsultReceivableModalOpen, setIsConsultReceivableModalOpen] =
    useState(false);

  return (
    <div>
      <button
        className="btn button-h35"
        style={{
          backgroundColor: '#5bc0de',
          borderColor: '#5bc0de',
          color: 'white',
          fontSize: '12px',
        }}
        onClick={() => setIsConsultReceivableModalOpen(true)}
      >
        Previsão de Recebimentos
      </button>

      {isConsultReceivableModalOpen && (
        <ConsultReceivableAmountModal
          isOpen={isConsultReceivableModalOpen}
          handleClose={() => setIsConsultReceivableModalOpen(false)}
        />
      )}
    </div>
  );
}
