import React from 'react';

import { PanelAppointmentTabContentWrapper } from '../../PanelAppointmentModal.styles';
import { CreateAppointment } from './CreateAppointment';
import { ServicesInProgress } from './ServicesInProgress';

const Content = ({ mode, maintenance }) => {
  if (mode === 'start-service') {
    return <CreateAppointment mode={mode} maintenance={maintenance} />;
  }

  if (mode === 'stop-service' || mode === 'finish-service') {
    return <ServicesInProgress mode={mode} maintenance={maintenance} />;
  }

  return 'invalid mode';
};

export function FirstTab({ mode, maintenance }) {
  return (
    <PanelAppointmentTabContentWrapper>
      <Content mode={mode} maintenance={maintenance} />
    </PanelAppointmentTabContentWrapper>
  );
}
