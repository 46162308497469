import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import HomeBillsToReceive from './BillsToReceive';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import './styles.css';

const BillsToReceive = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Contas a Receber']}
            path={['home', null]}
          />
          <HomeBillsToReceive />
        </Row>
      </Grid>
    </div>
  );
};

export default BillsToReceive;
