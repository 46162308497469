import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../../../components/CustomButton/CustomButton'
import CardForm from '../../../../components/CardForm'
import CompanyTaxErrorsTable from './CompanyTaxErrorsTable'
import ClientErrorsTable from './ClientErrorsTable'
import ProductErrorsTable from './ProductErrorsTable'

const StructureErrosModal = ({ onCancel, onRetry, errors }) => {
  const companyTaxErrors = errors.filter(
    (error) => error.card === 'Configurações Fiscais'
  )
  const clientErrors = errors.filter((error) => error.card === 'Cliente')
  const productErrors = errors.filter((error) => error.card === 'Produto')

  return (
    <Modal show animation dialogClassName='NFCe-errors-modal-wrapper'>
      <Modal.Header>
        <Modal.Title>
          <strong>Validação de Estrutura</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='NFCe-errors-modal-body'>
        <span>
          Identificamos {errors.length} {errors.length === 1 ? 'erro' : 'erros'}{' '}
          cadastrais que impedem o envio da nota fiscal. Realize as correções
          dos itens abaixo:{' '}
        </span>
        {!!companyTaxErrors.length && (
          <CardForm show title='Configurações Fiscais'>
            <CompanyTaxErrorsTable errors={companyTaxErrors} />
          </CardForm>
        )}
        {!!clientErrors.length && (
          <CardForm show title='Cliente'>
            <ClientErrorsTable errors={clientErrors} />
          </CardForm>
        )}
        {!!productErrors.length && (
          <CardForm show title='Produto'>
            <ProductErrorsTable errors={productErrors} />
          </CardForm>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='NFCe-errors-modal-footer'>
          <Button bsStyle='danger' fill onClick={onCancel}>
            Voltar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default StructureErrosModal
