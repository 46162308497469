import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import Button from '../../../../client/components/CustomButton/CustomButton.jsx';
import AlertModal from 'components/AlertModal/AlertModal';
import SignatureRepository from '../../../../repositories/Signatures';

const QuantityUserModal = ({ client, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentQtd, setCurrentQtd] = useState(1);
  const [newQtd, setNewQtd] = useState(1);
  const [idSignature, setSignature] = useState(0);
  const generateQtdUsers = () =>
    Array.from({ length: 50 }, (_, i) => ({ value: i + 1 }));

  const qtdUsers = generateQtdUsers();

  useEffect(() => {
    loadSignature();
  }, []);

  const loadSignature = async () => {
    if (client) {
      try {
        const signatures = await SignatureRepository.getByCompany(client.id);
        const signaturesSorted = signatures.sort((a, b) => a.id - b.id);
        const lastSignature = signaturesSorted[signaturesSorted.length - 1];

        setCurrentQtd(lastSignature.usersQuantity);
        setSignature(lastSignature.id);
      } catch (err) {
        console.error(err);
        toastr.error('Houve um erro ao buscar assinatura.');
      }
    }
  };

  const updateQtdUsers = async () => {
    setLoading(true);
    try {
      await SignatureRepository.update(idSignature, { usersQuantity: newQtd });
      toastr.success('Quantidade de usuários atualizada com sucesso');
      onCancel();
    } catch (err) {
      console.log(err);
      toastr.error('Houve um erro ao tentar atualizar');
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal show animation onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong> Quantidade de usuários</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="col-md-3 col-lg-3 col-xs-8 col-sm-12">
              <span>Quantidade atual:</span>
              <input
                className="form-control foco-input"
                name="currentQtd"
                value={currentQtd}
                disabled
              />
            </div>
            <div className="col-md-3 col-lg-3 col-xs-8 col-sm-12">
              <span>Nova quantidade:</span>
              <select
                value={newQtd}
                onChange={(e) => setNewQtd(e.target.value)}
                className="form-control foco-input"
              >
                {qtdUsers.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              disabled={loading}
              name="Não"
              onClick={onCancel}
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              disabled={loading}
              name="Sim"
              fill
              onClick={() => setConfirmModal(true)}
            >
              Sim{' '}
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {confirmModal && (
        <AlertModal
          title="Confirmação de atualização"
          show={confirmModal}
          onHide={() => setConfirmModal(false)}
          onCancel={() => setConfirmModal(false)}
          message="Deseja realizar a alteração de quantidade de usuários para essa empresa? A alteração poderá ser efetuada novamente, se necessário."
          onSubmit={updateQtdUsers}
          loading={loading}
        />
      )}
    </div>
  );
};

export default QuantityUserModal;
