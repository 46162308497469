import LoaderSpinner from 'client/components/LoaderSpinner';

import React, {
  useState,
  useEffect,
} from 'react';

import { Modal } from 'react-bootstrap'

const ProductsLoadingModal = ({ 
  showLoadingModal, 
}) => {

  const [show, setShow] = useState(showLoadingModal);

  useEffect(() => {
    setShow(showLoadingModal)
  }, [showLoadingModal]);

  return (  
    <Modal
      show={show}
      keyboard={false}
      backdrop={'static'}
      dialogClassName="products-loading-modal-wrapper"
      centered
      style={{
        marginTop: '250px'
      }}
    >      
      <Modal.Body>
        <div style={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <p style={{
            fontSize: '28px',
            fontWeight: 200
          }}>Por favor, aguarde...</p>
  
          <LoaderSpinner style={{
            fontSize: '5px',
            margin: '10px'
          }}/>   

          <p style={{
            fontSize: '14px',
            fontWeight: 200
          }}> Estamos buscando todos os produtos sem movimentação </p>

          <p style={{
            fontSize: '14px',
            fontWeight: 400
          }}> Não saia ou feche a tela </p>

        </div>        
      </Modal.Body>
    </Modal>
  )
}

export default ProductsLoadingModal;