import config from '../config'
import api from "../services/api"

const getAllByCompany = async companyId => {
  try {

    const response = await api.get(config.endpoint + `tax-by-state?companyId=${companyId}`);
    return response.data

  } catch(err) {
    throw err
  }
}

const destroy = async id => {
  try {

    await api.delete(config.endpoint + `tax-by-state/${id}`);

  } catch(err) {
    throw err
  }
}

export default {
  getAllByCompany,
  destroy
}