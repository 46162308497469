import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import shippingCompaniesRepository from '../../../repositories/ShippingCompanies';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import SearchInput from 'components/SearchInput';

const ShippingCompany = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [shippingCompanies, setShippingCompanies] = useState([]);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const { queryFilter, typeFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      loadShippingCompanies();
    }
  }, []);

  const loadShippingCompanies = async () => {
    setLoading(true);
    try {
      const shippingCompanies =
        await shippingCompaniesRepository.getAllByCompany(companyId);
      const serializedShippingCompanies = shippingCompanies.map((company) => ({
        id: company.id,
        cpfCnpj: company.Cpf_Cnpj,
        Type: company.Type,
        Name: company.Name,
        Phone: company.Number_Phone1
          ? company.Number_Phone1
          : company.Number_Phone2,
        Status: company.IsActive,
      }));

      setShippingCompanies(serializedShippingCompanies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as transportadoras. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (company) => {
    const searchQuery = [company['Name'], company['cpfCnpj']];
    return (
      filterByQuery(searchQuery) &&
      filterByStatus('Status', company) &&
      filterByType('Type', company)
    );
  };

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  !!companyId
                    ? history.push(constants.ROUTES.SHIPPING_COMPANY)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Transportadora
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por CPF/CNPJ ou Nome"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Fisica">Física</option>
                <option value="Juridica">Jurídica</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={shippingCompanies.filter(handleFilters)}
              columns={[
                {
                  Header: 'CFP/CNPJ',
                  id: 'cpfCnpj',
                  accessor: 'cpfCnpj',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <>
                      {props.value.length < 14
                        ? cpfMask(props.value)
                        : cnpjMask(props.value)}
                    </>
                  ),
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value == 'Juridica' ? 'purple' : 'blue',
                      }}
                    >
                      {props.value === 'Fisica' ? 'Física' : 'Jurídica'}
                    </Button>
                  ),
                },
                {
                  Header: 'Nome',
                  accessor: 'Name',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Telefone',
                  accessor: 'Phone',
                  headerClassName: 'text-left',
                  Cell: (props) => <>{phoneMask(props.value)}</>,
                },
                {
                  Header: 'Status',
                  accessor: 'Status',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{ backgroundColor: props.value ? 'green' : 'red' }}
                    >
                      {props.value ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 100,
                  Cell: (props) =>
                    !!companyId ? (
                      <Link
                        to={{
                          pathname: `shipping-company#${props.original.id}`,
                        }}
                      >
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ) : (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="false"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNoCompanyModal(true)}
                      />
                    ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'createdAt',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(ShippingCompany);
