import React from 'react'
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import { format } from 'date-fns'

import {
  cepMask,
  cnpjMask,
  cpfMask,
  milhar,
  phoneMask,
} from '../../../client/components/ToNormalize/ToNormalize'

import bikeImg from '../../../assets/img/checklist-images/bike.png'

import { usePlanSignatureContext } from 'contexts/plan-signature'

const Card = ({ children, style, ...rest }) => (
  <View style={[styles.cardBorder, style]} {...rest}>
    {children}
  </View>
)

const ObservationsCard = ({ sale }) => {
  return (
    <Card style={{ marginTop: '10px' }}>
      <View style={{ flexDirection: 'row', height: 75 }}>
        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Observações</Text>
          <Text>{sale.Comments}</Text>
        </View>

        <View style={[styles.line2]} />

        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Defeitos</Text>
          <Text>{sale.Defects}</Text>
        </View>

        <View style={[styles.line2]} />

        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Avarias</Text>
          <Text>{sale.Malfunctions}</Text>
        </View>
      </View>
    </Card>
  )
}

const Table = ({
  children,
  style = {},
  titleRight,
  titleLeft,
  data = [],
  ...rest
}) => (
  <View style={[styles.tableContainer, style]}>
    <View style={styles.tableHeader}>
      <Text style={styles.bold}>{titleLeft}</Text>
      {titleRight ? <Text style={styles.bold}>{titleRight}</Text> : null}
    </View>
    <View style={styles.table} {...rest}>
      {data.map((item) => (
        <View key={item} style={styles.tableItemContainer}>
          <Text style={styles.tableItemText}>{item}</Text>
          <View style={styles.tableItemStatus} />
        </View>
      ))}
    </View>
  </View>
)

export const BicicletasSheet = ({ customer, sale, displayNotes }) => {
  const { isPlanFree, isPlanStart } = usePlanSignatureContext()

  const printDate = format(new Date(customer.date), 'dd/MM/yyyy')

  const company = {
    Url_Logo: customer.company.Url_Logo,
    Trading_Name: customer.company.Trading_Name,
    Cpf_Cnpj:
      customer.company.Cpf_Cnpj.length <= 11
        ? cpfMask(customer.company.Cpf_Cnpj)
        : cnpjMask(customer.company.Cpf_Cnpj),
    Type: customer.company.Cpf_Cnpj.length <= 11 ? 'Física' : 'Jurídica',
    IE: customer.company.IE ? customer.company.IE : '',
    Address: `${customer.company.Address[0].Address}, ${customer.company.Address[0].Address_Number}, ${customer.company.Address[0].Neighborhood}`,
    AddressCityState: `${customer.company.Address[0].City}/${customer.company.Address[0].State}`,
    Zipcode: customer.company.Address[0].Zipcode
      ? cepMask(customer.company.Address[0].Zipcode)
      : '',
    Telephone: customer.company.Phones[0].Number_Phone1
      ? phoneMask(customer.company.Phones[0].Number_Phone1)
      : '',
    Email: customer.company.Email ? customer.company.Email : '',
  }

  const vehicle = {
    description: customer.vehicle?.Model || '',
    licensePlate: customer.vehicle?.License_Plate || '',
    color: customer.vehicle?.Color || '',
    manufactureYear: customer.vehicle?.Year_Manufacture || '',
    modelYear: customer.vehicle?.Year_Model || '',
    kilometers: milhar(sale.Kilometers) || '',
    Maintenance_Object: customer.vehicle?.Maintenance_Object || '',

    serialIdentification: customer.vehicle?.serialIdentification || '',
    vehicleTypeId: customer.vehicle?.vehicleTypeId || '',
    brand: customer.vehicle?.Brand || '',
    model: customer.vehicle?.Model || '',
    brakeType: customer.vehicle?.BicycleBrakeType?.description || '',
    rearGears: customer.vehicle?.BicycleRearGears?.quantity || '',
    frontGears: customer.vehicle?.BicycleFrontGears?.quantity || '',
    frameSize: customer.vehicle?.BicycleFrameSize?.description || '',
    frameMaterial: customer.vehicle?.BicycleFrameMaterial?.description || '',
    modality: customer.vehicle?.BicycleModality?.description || '',
    rearDerailleur: customer.vehicle?.BicycleRearDerailleurVehicle?.description
      ? customer.vehicle?.BicycleRearDerailleurVehicle?.description
      : customer.vehicle?.BicycleRearDerailleur?.description || '',
    rimSize: customer.vehicle?.BicycleRimSize?.description || '',
    suspensionType: customer.vehicle?.BicycleSuspensionType?.description || '',
  }

  const customerName = customer.Company_Name

  const contentTable = [
    'Freios (Dianteiro/Traseiro)',
    'Garfos e Estruturas',
    'Pneus',
    'Olhos de Gato (Dianteiro/Traseiro)',
    'Corrente e Coroa (Proteção)',
    'Pedais',
    'Guidom',
    'Rodas',
    'Postura do Condutor',
    'Aros e Raios',
    'Suspensão',
    'Marchas Traseiras',
    'Marchas Dianteiras',
    'Freio',
    'Baú (Se houver)',
    'Suporte de Baú (Se houver)',
    'Suporte de Garrafa (Se houver)',
    'Banco/Assento',
  ]

  return (
    <Document
      title={`impresso-Checklist-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page style={styles.page}>
        {/* Dados da Empresa */}
        <Card style={{ marginBottom: 7 }}>
          <View fixed style={{ flexDirection: 'row', width: '100%' }}>
            {company.Url_Logo ? (
              <View
                style={{
                  width: 82,
                  height: '100%',
                  borderRightWidth: 1,
                  borderRightColor: 'black',
                  padding: 3,
                }}
              >
                <Image
                  src={company.Url_Logo}
                  style={{
                    width: 75,
                    height: 60,
                  }}
                />
              </View>
            ) : null}

            <View
              fixed
              style={{
                width: '80%',
                marginLeft: 5,
                height: '100%',
                minHeight: 65,
              }}
            >
              <Text style={styles.mainTitle}>{company.Trading_Name}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '3px',
                  width: '100%',
                }}
              >
                <View>
                  <Text>
                    {company.Type === 'Jurídica' ? 'CNPJ' : 'CPF'}:{' '}
                    {company.Cpf_Cnpj}
                  </Text>
                </View>

                <View>{company.IE ? <Text>IE: {company.IE}</Text> : null}</View>

                <View>
                  <Text>Data: {printDate}</Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row', paddingTop: '3px' }}>
                <Text>
                  Endereço: {customer.company.Address[0].Address}{' '}
                  {customer.company.Address[0].Address_Number},{' '}
                  {customer.company.Address[0].Neighborhood}
                </Text>
                <Text style={{ paddingLeft: '2px' }}>
                  - {company.AddressCityState}{' '}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  paddingTop: '3px',
                }}
              >
                <Text style={{ paddingRight: '10px' }}>
                  CEP: {company.Zipcode}
                </Text>
                <Text style={{ paddingRight: '10px' }}>
                  Telefone: {company.Telephone}
                </Text>
                <Text>Email: {company.Email}</Text>
              </View>
            </View>
          </View>
        </Card>
        {/* Dados da Empresa --- FIM --- */}

        <View
          style={{
            borderTopWidth: 1,
            borderColor: 'black',
            borderStyle: 'dashed',
            borderRadius: 0,
          }}
        />

        {/* Dados do Cliente */}
        <View style={{ paddingTop: 7 }}>
          <View style={[styles.customerRow1]}>
            <View>
              <Text>
                <Text style={styles.bold}>Cliente: </Text> {customerName}
              </Text>
            </View>
          </View>

          <View style={[styles.customerRow1]}>
            <View>
              <Text>
                <Text style={styles.bold}>Série/Identificação: </Text>{' '}
                {vehicle.serialIdentification}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Marca/Modelo: </Text>
                {vehicle?.brand} - {vehicle?.model}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Ano: </Text>
                {vehicle?.manufactureYear}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Aro: </Text>
                {vehicle?.rimSize}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Tam. do Quadro: </Text>{' '}
                {vehicle?.frameSize}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Modalidade: </Text>
                {vehicle?.modality}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Material do Quadro: </Text>
                {vehicle?.frameMaterial}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Tipo de Freio: </Text>
                {vehicle?.brakeType}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Tipo de Suspensão: </Text>
                {vehicle?.suspensionType}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Câmbio Traseiro: </Text>
                {vehicle?.rearDerailleur}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Marcha Dianteira: </Text>
                {vehicle?.frontGears}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Marcha Traseira: </Text>
                {vehicle?.rearGears}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Cor: </Text>
                {vehicle?.color}
              </Text>
            </View>
          </View>

          {/* Dados do Cliente --- FIM --- */}

          <View style={[styles.customerRow1]}>
            <Text style={styles.bold}>Inspeção executada por:</Text>
            <View
              style={{
                width: '80%',
                borderBottomWidth: 1,
                borderColor: 'black',
                borderRadius: 0,
                marginLeft: 2,
                height: 15,
              }}
            />
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={styles.detailsLeft}>
            <Table
              titleLeft="Itens a serem inspecionados"
              titleRight="Status"
              data={contentTable}
              style={{
                marginTop: 10,
              }}
            />

            <View style={{ marginTop: 160 }}>
              <Text style={styles.bold}>OBSERVAÇÕES</Text>
              <View style={styles.line} />
              <View style={styles.line} />
              <View style={styles.line} />
              <View style={styles.line} />
              <View style={styles.line} />
              <View style={styles.line} />
              <View style={styles.line} />
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={styles.bold}>
                Data/Hora: ______/______/______ _____:______
              </Text>
              <Text style={[styles.bold, { marginTop: 20 }]}>
                Ass. Cliente: _________________________________________
              </Text>
            </View>
          </View>
          <View style={styles.detailsRight}>
            <View style={styles.detailsRightContent}>
              <Image src={bikeImg} style={styles.bikeImg} />
              <Text
                style={[
                  styles.bold,
                  {
                    marginTop: 5,
                    display: 'flex',
                    textAlign: 'center',
                    fontSize: 12,
                  },
                ]}
              >
                M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F =
                Faltando
              </Text>
            </View>
          </View>
        </View>

        {displayNotes && <ObservationsCard sale={sale} />}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span: {
    fontSize: 9,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  underline: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  customerRow1: {
    paddingTop: 1,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  imgContainer: {
    marginTop: 5,
    textAlign: 'center',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsLeft: {
    width: '50%',
  },
  detailsRight: {
    width: '50%',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  tableItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    marginTop: -1.2,
  },
  tableItemText: {
    width: 365,
    height: 11.5,
    fontSize: 8,
    borderWidth: 1,
    borderColor: '#444444',
    paddingTop: 1,
    paddingLeft: 4,
  },
  tableItemStatus: {
    width: 25,
    borderWidth: 1,
    borderColor: '#444444',
    marginLeft: -1.2,
  },
  detailsRightContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
  },
  detailsRightText: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 8,
  },
  bikeImg: {
    width: 220,
  },
  withoutMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  withMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 2,
  },
  box: {
    width: 10,
    height: 10,
    borderWidth: 2,
    marginRight: 2,
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 15,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  observationsCard: {
    width: '33%',
    marginLeft: '5px',
    marginTop: '5px',
  },
  line2: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: 'black',
  },
})
