import * as yup from 'yup';
import { listDepartment } from 'v2/repositories/DepartmentRepository';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Produtos/Serviços',
      'Departamento',
      `${isEditing ? 'Editar' : 'Novo'} Departamento`,
    ],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.DEPARTMENT.BASE,
      null,
    ],
  };
};

export const validateDepartmentDescriptionUse = async (
  description,
  companyId,
  departmentId
) => {
  try {
    const departments = await listDepartment({
      query: description,
      companyId,
    });

    const departmentWithSameDescription = departments.rows.find(
      (department) =>
        department.description.toLowerCase() === description.toLowerCase() &&
        (departmentId ? +department.id !== +departmentId : true)
    );

    if (departmentWithSameDescription) {
      return false;
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export const defaultFormValues = {
  id: null,
  description: '',
  observations: '',
  isActive: true,
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  observations: yup.string().notRequired(),
});
