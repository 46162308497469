import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faBoxOpen,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';

const ProgressBarComponent = ({ percent }) => (
  <ProgressBar
    percent={percent}
    filledBackground="rgb(4, 182, 4)"
    unfilledBackground="linear-gradient(to right, rgb(214, 255, 220), rgb(177, 250, 188))"
    style={{ height: '5px' }}
  >
    <Step transition="scale">
      {({ accomplished }) => (
        <div
          id={accomplished ? 'div-step-xml-import2' : 'div-step-xml-import'}
          className={`indexedStep import-progress ${
            accomplished ? 'accomplished' : null
          }`}
        >
          <FontAwesomeIcon
            style={{ height: '43px', width: '43px' }}
            id={accomplished ? 'icon-step-xml-import2' : 'icon-step-xml-import'}
            icon={faCloudUploadAlt}
            className="iconeMenu"
            aria-hidden="false"
          />
          <br />
          <span
            style={{
              fontWeight: accomplished ? 'bold' : 'normal',
            }}
          >
            Importação
          </span>
        </div>
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <div
          id={accomplished ? 'div-step-xml-import2' : 'div-step-xml-import'}
          className={`indexedStep import-progress ${
            accomplished ? 'accomplished' : null
          }`}
        >
          <FontAwesomeIcon
            style={{ height: '43px', width: '43px' }}
            id={accomplished ? 'icon-step-xml-import2' : 'icon-step-xml-import'}
            icon={faHandshake}
            className="iconeMenu"
            aria-hidden="false"
          />
          <br />
          <span
            style={{
              fontWeight: accomplished ? 'bold' : 'normal',
            }}
          >
            Fornecedor
          </span>
        </div>
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <div
          id={accomplished ? 'div-step-xml-import2' : 'div-step-xml-import'}
          className={`indexedStep import-progress ${
            accomplished ? 'accomplished' : null
          }`}
        >
          <FontAwesomeIcon
            style={{ height: '43px', width: '43px' }}
            id={accomplished ? 'icon-step-xml-import2' : 'icon-step-xml-import'}
            icon={faBoxOpen}
            className="iconeMenu"
            aria-hidden="false"
          />
          <br />
          <span
            style={{
              fontWeight: accomplished ? 'bold' : 'normal',
            }}
          >
            Produtos
          </span>
        </div>
      )}
    </Step>
  </ProgressBar>
);

export default ProgressBarComponent;
