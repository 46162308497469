import config from '../config';
import api from '../services/api';

const start = async (data) => {
  const response = await api.post(config.endpoint + 'crm-bonus/start', data);
  return response.data;
};

const bonusAvailable = async (data) => {
  const response = await api.post(
    config.endpoint + 'crm-bonus/bonus-available',
    data
  );
  return response.data;
};

const validatePin = async (data) => {
  const response = await api.post(
    config.endpoint + 'crm-bonus/validate-pin',
    data
  );
  return response.data;
};

const resendPin = async (data) => {
  const response = await api.post(
    config.endpoint + 'crm-bonus/resend-pin',
    data
  );
  return response.data;
};

const campaignAvailable = async (data) => {
  const response = await api.post(
    config.endpoint + 'crm-bonus/campaign-available',
    data
  );
  return response.data;
};

const checkout = async (data) => {
  const response = await api.post(config.endpoint + 'crm-bonus/checkout', data);
  return response.data;
};

const totalSales = async (data) => {
  const response = await api.post(
    config.endpoint + 'crm-bonus/total-sales',
    data
  );
  return response.data;
};

export const CRMBonusRepository = {
  start,
  bonusAvailable,
  validatePin,
  resendPin,
  campaignAvailable,
  checkout,
  totalSales,
};
