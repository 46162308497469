import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import NumberFormat from 'react-number-format'

import CustomButton from 'client/components/CustomButton/CustomButton'
import YearInput from 'components/YearInput'

import { useAuth } from 'contexts/auth'

import billsToReceiveRepository from 'repositories/BillsToReceive'

import './index.css'

export default function ConsultReceivableAmountModal({ isOpen, handleClose }) {
  const [year, setYear] = useState('')
  const [receivables, setReceivables] = useState([])
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  async function handleGetReceivables() {
    setLoading(true)
    try {
      const data =
        await billsToReceiveRepository.getPendingAmountByYearPerMonth({
          year,
          companyId,
        })

      console.log(data)
      setReceivables(data)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar Saldos a Receber',
        'Tente novamente! Caso persista, contate o suporte.'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (year.length === 4) {
      handleGetReceivables()
    }
  }, [year])

  useEffect(() => {
    setYear(`${new Date().getFullYear()}`)
  }, [])

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-25w">
      <Modal.Header>
        <Modal.Title>
          <strong>Previsão de Recebimentos</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Veja abaixo o saldo a receber:</span>
        <br />
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <YearInput value={year} setValue={setYear} showArrows />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          {loading ? (
            'Carregando...'
          ) : (
            <table id="consult-amount-table">
              <thead>
                <tr>
                  <th className="month-cell">Mês</th>
                  <th className="amount-cell">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {receivables.map((receivable) => (
                  <tr>
                    <td className="month-cell">{receivable.month}</td>
                    <td className="amount-cell">
                      <NumberFormat
                        displayType="text"
                        value={receivable.amount}
                        decimalScale="2"
                        fixedDecimalScale={true}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ textAlign: 'right' }}>
          <CustomButton bsStyle="danger" fill onClick={() => handleClose()}>
            Voltar
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
