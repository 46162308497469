import PDFButton from 'client/components/PDFButton'
import { addDays, format } from 'date-fns'
import React from 'react'

const RepresentativeComissionsFilter = ({
  setRepresentative,
  setInitialDate,
  setFinalDate,
  initialDate,
  finalDate,
  setOrcaments,
  setType,
  loadReport,
  isLoading,
  representativeList,
}) => {
  return (
    <>
      <div className="representative-comissions_row">
        <div className="representative-comissions_column">
          <span>Representantes:</span>
          <select
            className="form-control foco-input"
            onChange={(e) => setRepresentative(e.target.value)}
          >
            <option value="">Todos</option>
            {representativeList.map((r) => (
              <option value={r.id} key={r.id}>
                {r.companyName}
              </option>
            ))}
          </select>
        </div>

        <div className="representative-comissions_column">
          <span>Data Inicial:</span>
          <input
            className="form-control foco-input"
            type="date"
            onChange={(e) => setInitialDate(e.target.value)}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
          />
        </div>

        <div className="representative-comissions_column">
          <span>Data Final:</span>
          <input
            className="form-control foco-input"
            type="date"
            onChange={(e) => setFinalDate(e.target.value)}
            max={format(new Date(), 'yyyy-MM-dd')}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : null
            }
          />
        </div>

        <div className="representative-comissions_column">
          <span>Exibir Orçamentos:</span>
          <select
            className="form-control foco-input"
            onChange={(e) => setOrcaments(e.target.value)}
          >
            <option value={0} selected>
              Não
            </option>
            <option value={1}>Sim</option>
          </select>
        </div>

        <div className="representative-comissions_column">
          <span>Tipo:</span>
          <select
            className="form-control foco-input"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="synthetic" selected>
              Sintético
            </option>
            <option value="analytical">Analítico</option>
          </select>
        </div>

        <div className="representative-comissions_button">
          <PDFButton onClick={() => loadReport()} isLoading={isLoading} />
        </div>
      </div>
    </>
  )
}

export default RepresentativeComissionsFilter
