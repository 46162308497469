import segmentsRepository from '../../../../repositories/Segments'

export function handleChange(value, type) {
  return {
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type,
  }
}

export const loadSegment = async (userId, segmentoReducer) => {
  let {
    companySegments,
    Carros,
    Motos,
    Pesados,
    Outros,
    Trator,
    Escavadeira,
    Van,
    Bicicleta,
    Colheitadeira,
  } = segmentoReducer
  const response = await segmentsRepository.getByUser(userId)

  let resp = []
  let todos = []

  response.data.map((seg) => {
    resp.push(seg)

    switch (seg.Description) {
      case 'Carros':
        Carros = true
        break
      case 'Motos':
        Motos = true
        break
      case 'Pesados':
        Pesados = true
        break
      case 'Outros':
        Outros = true
        break
      case 'Trator':
        Trator = true
        break
      case 'Escavadeira':
        Escavadeira = true
        break
      case 'Van':
        Van = true
        break
      case 'Bicicleta':
        Bicicleta = true
        break
      case 'Colheitadeira':
        Colheitadeira = true
        break
    }
    todos.push(seg)
  })

  companySegments = resp

  return {
    type: 'LOAD_SEGMENT',
    payloadCompanySegments: companySegments,
    payloadCurrentySegments: todos,
    Carros: Carros,
    Motos: Motos,
    Pesados: Pesados,
    Outros: Outros,
    Trator: Trator,
    Escavadeira: Escavadeira,
    Van: Van,
    Bicicleta: Bicicleta,
    Colheitadeira: Colheitadeira,
  }
}
