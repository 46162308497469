import React from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import HomeSalesByClient from './SalesByClient';

const SalesByClient = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Relatórios', 'Vendas por Cliente']}
        path={['home', null, null]}
      />
      <HomeSalesByClient />
    </>
  );
};

export default SalesByClient