import api from '../services/api'
import config from '../config'

const list = async () => {
  try {
    const response = await api.get(config.endpoint + 'attendance-type')
    return response.data
  } catch (err) {
    throw err
  }
}

const attendanceTypesRepository = {
  list,
}

export default attendanceTypesRepository
