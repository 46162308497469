import React, { useState, useEffect } from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import constants from "../../../../utils/constants";
import FormCostCenter from "./FormCostCenter";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import { decrypt } from "client/components/ToNormalize/ToNormalize";

const NewCostCenter = ({ location, history }) => {
  const [id, setId] = useState(null);

  // localStorage.setItem("selectedPath", "client/cost-center");

  useEffect(() => {
    checkParams();
  }, []);

  function checkParams() {
    const params = new URLSearchParams(location.search);
    const idParams = params.get("id");

    if (idParams) {
      readHash(idParams);
    }
  }

  async function readHash(hash) {
    const costCenterId = await decrypt(hash, "@OS-dig:costCenterId");
    setId(costCenterId);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              "Início",
              "Cadastro",
              "Financeiro",
              "Centro de Custo",
              `${id ? "Editar" : "Novo"} Centro de Custo`,
            ]}
            path={[
              constants.ROUTES.home,
              null,
              null,
              constants.ROUTES.COST_CENTERS,
              null,
            ]}
          />
          <FormCostCenter
            costCenterId={id}
            onCancel={() => history.push(constants.ROUTES.COST_CENTERS)}
            onSubmit={() => history.push(constants.ROUTES.COST_CENTERS)}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(NewCostCenter);
