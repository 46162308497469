import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BundleFormBillToReceive from './BundleFormBillToReceive'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Condition = ({ match, history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Contas a Receber',
              !!match.params.id ? 'Editar Título' : 'Novo Título',
            ]}
            path={['home', 'bills-to-receive', null]}
          />
          <BundleFormBillToReceive
            titleId={match.params.id}
            defaultValues={history.location.state}
          />
        </Row>
      </Grid>
    </div>
  )
}

export default Condition
