import styled from 'styled-components';
import Select from 'react-select';

export const InputWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputReactSelect = styled(Select)`
  box-sizing: border-box;

  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : '14px')};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray180};
  border-radius: ${(props) => props.theme.radii.xs};

  &:has(input:focus) {
    outline: 1px solid ${(props) => props.theme.colors.gray160};
  }
`;
