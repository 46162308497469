import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { CommissionsDocument } from './CommissionsDocument';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';
import CommissionsReportRepository from 'repositories/CommissionsReport';

import './styles.css';

const CommissionsReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [initialDateQuery, setInitialDateQuery] = useState('');
  const [finalDateQuery, setFinalDateQuery] = useState('');
  const [result, setResult] = useState([]);
  const [paymentForData, setPaymentForData] = useState('');
  const [showTotalizators, setShowTotalizators] = useState(true);
  const [showTypeItem, setShowTypeItem] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const employeeIdHash = searchParams.get('employeeId');

      let employeeId;
      if (employeeIdHash) {
        employeeId = await decrypt(employeeIdHash, '@OS-dig:employeeId');
      }
      const companyIdHash = searchParams.get('companyId');

      let companyId;
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId');
      }

      const showClientData = searchParams.get('showClientData');

      const showTotalizatorsParams = searchParams.get('showTotalizators');

      setShowTotalizators(showTotalizatorsParams);

      if (!employeeId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const showTypeItem = searchParams.get('showTypeItem');

      const paymentForData = searchParams.get('paymentForData');
      setPaymentForData(paymentForData);

      setInitialDateQuery(
        format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy')
      );
      setFinalDateQuery(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));
      return loadReport(employeeId, {
        initialDate,
        finalDate,
        companyId,
        showClientData,
        paymentForData,
        showTotalizators,
        showTypeItem,
      });
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (employeeId, params) => {
    try {
      const empId = employeeId === 'all' ? null : employeeId;
      const data =
        params.paymentForData === 'saleClose'
          ? await CommissionsReportRepository.generateByCompanyId({
              ...params,
              employeeId: empId,
            })
          : await CommissionsReportRepository.generateByCompanyIdTitle({
              ...params,
              group: true,
              employeeId: empId,
            });

      setResult(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="comission-report">
      <main>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <CommissionsDocument
            result={result}
            showTitleCode={paymentForData !== 'saleClose'}
            initialDate={initialDateQuery}
            finalDate={finalDateQuery}
            showTotalizators={showTotalizators}
            paymentForData={paymentForData}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default CommissionsReport;
