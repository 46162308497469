import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton'

const DuplicateProductModal = ({ product, onCancel, onSubmit }) => {
  return (
    <Modal show animation>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Deseja criar um novo cadastro copiando o seguinte produto?</p>
        </div>
        <div className="product-info">
          <p>
            <strong>Código:</strong> {product.code}
          </p>
          <p>
            <strong>Descrição:</strong> {product.description}
          </p>
          <p>
            <strong>Marca:</strong> {product.brand}
          </p>
          <p>
            <strong>Ref. Fabricante: </strong>
            {product.manufacturerRef}
          </p>
        </div>
        <div className="observation-alert">
          <span style={{ color: 'red' }}>
            A referência do fabricante e quantidade de estoque não serão
            copiados
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} bsStyle="danger" fill>
          Não
        </Button>
        <Button onClick={onSubmit} bsStyle="info" fill>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DuplicateProductModal
