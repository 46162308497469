import config from '../config';
import api from '../services/api';

const getAllByCompany = async (companyId, params) => {
  try {
    const response = await api.get(config.endpoint + `massPriceChanges`, {
      params: { companyId, ...params },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getByPurchaseId = async (purchaseId) => {
  try {
    const response = await api.get(
      config.endpoint + `massPriceChanges/purchase/${purchaseId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const create = async (massPrice) => {
  try {
    const response = await api.post(
      config.endpoint + 'massPriceChanges',
      massPrice
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (priceChangeId, massPrice) => {
  try {
    const response = await api.put(
      config.endpoint + `massPriceChanges/${priceChangeId}`,
      massPrice
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (massPriceChangeId) => {
  try {
    const response = await api.get(
      config.endpoint + `massPriceChanges/${massPriceChangeId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getById,
  getByPurchaseId,
  create,
  update,
  getAllByCompany,
};
