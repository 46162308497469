import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input';
import ReactTable from 'react-table';

import CircleButton from 'components/CirclePlusMinusButton';
import AlertModal from 'components/AlertModal/AlertModal';
import CollapsibleSection from 'components/CollapsibleSection';
import SearchInput from 'components/SearchInput';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import Button from 'client/components/CustomButton/CustomButton.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const InventoryCountData = ({
  formMethods,
  productQueryA,
  setProductQueryA,
  handleSearchProducts,
  loadingSubmission,
  loadingProducts,
  productsOfInventory,
  loadingInventory,
  handleRemoveProduct,
  handleValidateCancel,
  handleValidateSubmit,
  inventoryCountId,
  isFinished,
  handleChangeProductQuantity,
}) => {
  const { register } = formMethods;

  return (
    <div>
      <CollapsibleSection
        title="Dados do Inventário"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div className="client__inventory-count-register-row">
          <div className="client__inventory-count-register-column">
            <span>Data de Início:</span>
            <input
              type="date"
              disabled
              className="form-control foco-input"
              {...register('createdAt')}
            />
          </div>

          <div className="client__inventory-count-register-column">
            <span>
              Descrição:<span style={{ color: 'red' }}>*</span>
            </span>

            <input
              style={{
                width: '50rem',
              }}
              type="text"
              disabled={isFinished}
              className="form-control foco-input"
              {...register('description')}
            />
          </div>

          <div className="client__inventory-count-register-column">
            <span>Data de Finalização:</span>
            <input
              type="date"
              disabled
              className="form-control foco-input"
              {...register('finishDate')}
            />
          </div>
        </div>

        <div className="client__inventory-count-register-search">
          <SearchInput
            placeholder="Pesquisa por Descrição, Código, Ref. Fabricante, Cód. de Barras e Apelido"
            value={productQueryA}
            disabled={isFinished}
            onChange={(e) => setProductQueryA(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchProducts();
              }
            }}
          />

          <Button
            bsStyle="info"
            disabled={loadingProducts || isFinished || loadingSubmission}
            onClick={() => handleSearchProducts()}
            fill
          >
            <span
              className={
                loadingProducts
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />
            Pesquisar
          </Button>
        </div>

        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            width: '90%',
            marginTop: '10px',
          }}
          data={productsOfInventory}
          columns={[
            {
              Header: 'Código',
              accessor: 'productCode',
              width: 100,
            },
            {
              Header: 'Descrição',
              accessor: 'productDescription',
              width: 350,
            },
            {
              Header: 'Ref. Fabricante',
              accessor: 'productManufacturerRef',
              width: 150,
            },
            {
              Header: 'R$ Compra',
              accessor: 'productPurchasePrice',
              Cell: (props) => currency(props.value),
              width: 120,
            },
            {
              Header: 'Estoque',
              accessor: 'productQuantity',
              width: 120,
            },
            {
              Header: 'Inventário',
              accessor: 'inventoryQuantity',
              width: 200,
              Cell: (props) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                    }}
                  >
                    {!isFinished && (
                      <CircleButton
                        type="minus"
                        width="15px"
                        action={() => {
                          handleChangeProductQuantity(
                            props.index,
                            Number(
                              props.value.replaceAll('.', '').replace(',', '.')
                            ) -
                              1 <
                              0
                              ? 0
                              : Number(
                                  props.value
                                    .replaceAll('.', '')
                                    .replace(',', '.')
                                ) - 1
                          );
                        }}
                      />
                    )}
                    <CurrencyInput
                      style={{
                        textAlign: 'center',
                        width: '80px',
                        marginTop: '5px',
                      }}
                      id={`productQtd-input-${props.index}`}
                      className="form-control foco-input"
                      value={props.value}
                      disabled={isFinished}
                      onChangeEvent={(e) =>
                        handleChangeProductQuantity(props.index, e.target.value)
                      }
                      decimalSeparator=","
                      thousandSeparator="."
                      suffix=""
                    />
                    {!isFinished && (
                      <CircleButton
                        type="plus"
                        width="15px"
                        action={() => {
                          handleChangeProductQuantity(
                            props.index,
                            Number(
                              props.value.replaceAll('.', '').replace(',', '.')
                            ) + 1
                          );
                        }}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: 'productId',
              width: 50,
              Cell: (props) => {
                const [
                  isDeleteConfirmationModalOpen,
                  setIsDeleteConfirmationModalOpen,
                ] = useState(false);

                return (
                  <>
                    {!isFinished && (
                      <>
                        <FontAwesomeIcon
                          title="Excluir Produto"
                          cursor="pointer"
                          icon={faTrashAlt}
                          style={{
                            width: '1.5em',
                            height: '1.5em',
                            color: 'red',
                            marginLeft: '1px',
                          }}
                          onClick={() => {
                            setIsDeleteConfirmationModalOpen(true);
                          }}
                        />

                        {isDeleteConfirmationModalOpen && (
                          <AlertModal
                            show={isDeleteConfirmationModalOpen}
                            message={
                              <>
                                <span>
                                  <strong>
                                    Você tem certeza que deseja excluir o item?
                                  </strong>
                                </span>
                                <br />
                                <br />
                                <span>
                                  Ao excluir o item, será necessário adicioná-lo
                                  novamente para que seja contabilizado na
                                  contagem/inventário.
                                </span>
                              </>
                            }
                            onCancel={() =>
                              setIsDeleteConfirmationModalOpen(false)
                            }
                            onSubmit={() => handleRemoveProduct(props.value)}
                            onHide={() =>
                              setIsDeleteConfirmationModalOpen(false)
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                );
              },
            },
          ]}
          defaultPageSize={10}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          noDataText="Nenhum item adicionado"
          loading={loadingInventory}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />

        <div className="client__inventory-count-register-row">
          <div
            style={{
              marginTop: '10px',
              width: '100%',
            }}
            className="client__inventory-count-register-column"
          >
            Observações:
            <textarea
              className="form-control foco-input"
              {...register('observations')}
              maxLength={500}
              style={{ width: '100%' }}
              disabled={isFinished}
            />
          </div>
        </div>
      </CollapsibleSection>

      <div className="client__inventory-count-register-buttons">
        <Button
          bsStyle="danger"
          fill
          onClick={() => handleValidateCancel()}
          disabled={loadingSubmission}
        >
          {inventoryCountId ? 'Voltar' : 'Cancelar'}
        </Button>
        {!isFinished && (
          <Button
            disabled={loadingSubmission}
            bsStyle="info"
            fill
            onClick={() => handleValidateSubmit()}
          >
            <span
              className={
                loadingSubmission
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />{' '}
            Salvar
          </Button>
        )}
      </div>
    </div>
  );
};

export default InventoryCountData;
