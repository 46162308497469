import config from '../config'
import api from '../services/api'

const create = (data) => api.post(config.endpoint + 'tray-company', data)

const update = (companyId, data) =>
  api.put(config.endpoint + `tray-company/${companyId}`, data)

const validateTrayCompanyData = async ({ code, companyId, trayUrl }) => {
  const { data } = await api.get(`tray-company/validate-token`, {
    params: {
      code,
      companyId,
      trayUrl,
    },
  })

  return data
}

const showCompany = (companyId) =>
  api.get(config.endpoint + `tray-company/${companyId}`)

export default { create, update, showCompany, validateTrayCompanyData }
