import React, { useState, useEffect } from 'react'
import { isValid } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'
import EmployeesRepository from 'repositories/Employees'
import positionsRepository from 'repositories/Positions'

import PDFButton from '../../../../components/PDFButton'
import '../index.css'

const ListEmployeesMain = () => {
  const [isReportReady, setReportReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dateType, setDateType] = useState('admissionDate')
  const [reportType, setReportType] = useState('synthetic')
  const [displayOff, setDisplayOff] = useState('false')
  const [positionIdSelected, setPositionsIdSelected] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [positions, setPositions] = useState([])
  const { companyId } = useAuth()

  const handleSubmit = async () => {
    handleProcess()
  }

  const handleProcess = async () => {
    setLoading(true)
    setReportReady(false)
    try {
      const params = {
        initialDate,
        finalDate,
        dateType,
        displayOff,
        positionsType: positionIdSelected,
      }

      const data = await EmployeesRepository.generateListEmployee(
        companyId,
        params
      )

      if (!data?.data.employeeData.length)
        return toastr.warning(
          'Nenhuma informação foi localizada.',
          'Nenhum registro foi localizado com os filtros definidos. Selecione novamente os filtros e tente novamente.'
        )
      setReportReady(true)
    } catch (err) {
      console.log(err)
      toastr.error(
        err?.response?.data?.message ||
        'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    if (reportType === 'synthetic') {
      window.open(
        window.location.origin +
        `/report/list-employees?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&dateType=${dateType}&displayOff=${displayOff}&positionsType=${positionIdSelected}`,
        '_blank'
      )
    } else {
      window.open(
        window.location.origin +
        `/report/list-employees-analytical?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&dateType=${dateType}&displayOff=${displayOff}&positionsType=${positionIdSelected}`,
        '_blank'
      )
    }
  }

  useEffect(() => {
    if (dateType === 'resignationDate') {
      setDisplayOff('true')
    } else {
      setDisplayOff('false')
    }
  }, [dateType])

  useEffect(() => {
    if (companyId) {
      loadPositions()
    }
  }, [companyId])

  const loadPositions = async () => {
    setLoading(true)
    try {
      const positions = await positionsRepository.getAllByCompany(companyId, {
        isActive: 1,
      })

      setPositions(positions.data)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar os cargos. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="main-control-list-employees">
      <section>
        <div className="line-one-control">
          <div className="line-label-size-one">
            <strong className='line-control-strong-label_one '>Tipo de Data:</strong>
            <select
              value={dateType}
              onChange={(e) => setDateType(e.target.value)}
              className="form-control foco-input"
            >
              <option value="admissionDate">Admissão</option>
              <option value="resignationDate">Desligamento</option>
            </select>
          </div>
          <div className="line-label-size-one">
            <strong className='line-control-strong-label_one '>Data Inicial:</strong>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>

          <div className="line-label-size-one">
            <strong className='line-control-strong-label_one '>Data Final:</strong>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </div>
        </div>
        <div className="line-two-control">
          <div className="line-two-label-size">
            <strong className='line-control-strong-label_two'>Cargo:</strong>
            <select
              className="form-control foco-input"
              onChange={(e) => setPositionsIdSelected(e.target.value)}
              value={positionIdSelected}
            >
              <option name="" value="">
                Todos
              </option>
              {positions.map((e) => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.description}
                  </option>
                )
              })}
              <option value="0">Sem Cargo</option>
            </select>
          </div>

          <div className="line-two-label-size">
            <strong className='line-control-strong-label_two'>Tipo:</strong>
            <select
              className="form-control foco-input"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="synthetic">Sintético</option>
              <option value="analytical">Analítico</option>
            </select>
          </div>
          <div className="line-two-label-size">
            <strong className='line-control-strong-label_two'>Exibir desligados?</strong>

            <select
              disabled={dateType === 'resignationDate' ? true : false}
              onChange={(e) => setDisplayOff(e.target.value)}
              className="form-control foco-input"
            >
              <option value="true" selected={dateType === 'resignationDate'}>
                Sim
              </option>
              <option value="false" selected={dateType === 'admissionDate'}>
                Não
              </option>
            </select>
          </div>

          <div>
            <button
              className="btn btn-sucesso employee-list-button-control"
              style={{ height: '38px' }}
              onClick={handleSubmit}
              disabled={loading || !initialDate || !finalDate}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </div>
          <div className="employee-list-button-control">
            <PDFButton
              disabled={loading || !isReportReady}
              onClick={handlePrint}
            >
              Imprimir
            </PDFButton>
          </div>
        </div>
      </section>
    </div>
  )
}
export default ListEmployeesMain
