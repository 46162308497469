import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../client/components/CustomButton/CustomButton'

import './styles.css'

const OverwriteUserSessionModal = ({ show, onCancel, onSubmit }) => {
  return (
    <Modal
      show={show}
      animation
      onHide={onCancel}
      dialogClassName='overwrite-user-session-modal'
    >
      <Modal.Header>
        <Modal.Title>
          <strong>OS DIGITAL</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Já existe uma sessão iniciada com esse e-mail em outro navegador ou
          computador. Ao acessar, a sessão aberta será encerrada e uma nova será
          iniciada, toda informação não salva, deverá ser lançada novamente.
        </p>
        <p>Você deseja acessar mesmo assim e derrubar a sessão aberta?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='danger' name='Não' onClick={onCancel} fill>
          Não
        </Button>
        <Button bsStyle='info' name='Sim' fill onClick={onSubmit}>
          Acessar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default OverwriteUserSessionModal
