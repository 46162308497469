import React from 'react'
import { useRecoilState } from 'recoil'

import { FINANCING_BOLETOFLEX_INSTALLMENTS } from '../../../recoil'

export default function FinancingAuthorizedStepBoletoFlex() {
  const [installments] = useRecoilState(FINANCING_BOLETOFLEX_INSTALLMENTS)

  return (
    <div style={{ textAlign: 'left', margin: '10px 0px 10px 30px' }}>
      <ul>
        {installments?.map((installment) => {
          const installmentCondition = installment.value.split('de')[0]
          const installmentValue = installment.value
            .split('(Total:')[0]
            .split('de ')[1]
            .split('Total:')
          const installmentTotal = installment.value
            .split('(Total:')[1]
            .replace(')', '')

          return (
            <li key={installment.key} style={{ marginBottom: '13px' }}>
              {installmentCondition} de R$ {installmentValue} (com juros) <br />
              <span style={{ color: '#9e9e9e' }}>
                Total de R$ {installmentTotal}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
