import React, { useEffect } from 'react'
import './styles.css'
import Select from './components/Select'
import Input from './components/Input'
import TextArea from './components/TextArea'
import PF from './PF'
import PJ from './PJ'

import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, change, Field } from 'redux-form'
import Toggle from 'react-toggle'
import { Col } from 'react-bootstrap'
import { description } from 'client/components/ToNormalize/ToNormalize'

function DadosBasicos({ onSubmit, edit }) {
  const {
    cadastroCliente: {
      values: { Status, Type, withholdsISS },
    },
  } = useSelector((state) => state.form)
  const dispatch = useDispatch()

  function handleType() {
    dispatch([
      change('cadastroCliente', 'Cpf_Cnpj', ''),
      change('cadastroCliente', 'Rg', ''),
      change('cadastroCliente', 'Company_Name', ''),
      change('cadastroCliente', 'Trading_Name', ''),
      change('cadastroCliente', 'Email', ''),
      change('cadastroCliente', 'Number_Phone1', ''),
      change('cadastroCliente', 'Number_Phone2', ''),
      change('cadastroCliente', 'Contact', ''),
      change('cadastroCliente', 'Date_Birth', ''),
      change('cadastroCliente', 'IE', ''),
    ])
  }

  return (
    <form onSubmit={onSubmit}>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={2} md={2} sm={3} xs={12}>
          <label>Tipo de Pessoa:</label>
          <Field
            name="Type"
            component={Select}
            value={Type}
            onChange={handleType}
            disabled={edit}
          >
            <option value="Fisica">Física</option>
            <option value="Juridica">Jurídica</option>
          </Field>
        </Col>
        <Col lg={1} md={2} sm={1} xs={12}>
          <label>
            Status:<span style={{ color: 'red' }}>*</span>
          </label>
          <div id="toggle-status">
            <Toggle
              checked={Status}
              name="Status"
              onChange={() =>
                dispatch(change('cadastroCliente', 'Status', !Status))
              }
            />
          </div>
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        {Type === 'Fisica' ? <PF edit={edit} /> : <PJ edit={edit} />}
      </Col>
    </form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'cadastroCliente',
})(DadosBasicos)
