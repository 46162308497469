import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {
  Editor,
  Transforms,
  Range,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold,
  faItalic,
  faList,
  faListNumeric,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Toolbar, TotalCharacters } from './Campaign.styles';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const RichText = ({ value, onChange, isDisabled }) => {
  const renderElement = useCallback((props) => {
    const style = { ...props.attributes.style, fontSize: '14px' };
    return (
      <span {...props.attributes} style={style}>
        {props.children}
      </span>
    );
  }, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  function calculateTotalTextLength(data) {
    if (!data) {
      return 0;
    }
    return data.reduce((accumulator, item) => {
      return (
        accumulator +
        item.children.reduce((acc, child) => acc + child.text.length, 0)
      );
    }, 0);
  }

  return (
    <Slate editor={editor} initialValue={value} onValueChange={onChange}>
      <Toolbar>
        <MarkButton format="bold" icon={faBold} isDisabled={isDisabled} />
        <MarkButton format="italic" icon={faItalic} isDisabled={isDisabled} />
        <MarkButton
          format="strikethrough"
          icon={faStrikethrough}
          isDisabled={isDisabled}
        />
        {/* <BlockButton format="bulleted-list" icon={faList} />
        <BlockButton format="numbered-list" icon={faListNumeric} /> */}
        <TotalCharacters>
          {calculateTotalTextLength(value)} Caracteres
        </TotalCharacters>
      </Toolbar>
      <div style={{ padding: '5px' }}>
        <Editable
          style={{
            height: '100%',
            minHeight: '200px',
            outlineColor: 'transparent',
          }}
          readOnly={isDisabled}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Insira o texto da campanha"
          spellCheck
          autoFocus
          value={value}
          onChange={onChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();

              if (editor.selection) {
                if (!Range.isCollapsed(editor.selection)) {
                  Transforms.delete(editor);
                }

                Transforms.insertText(editor, '\n');
                return;
              }

              Transforms.insertText(editor, '\n');
              return;
            }

            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </div>
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes < SlateElement > (editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

const MarkButton = ({ format, icon, isDisabled }) => {
  const editor = useSlate();
  return (
    <Button
      disabled={isDisabled}
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

export default RichText;
