import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconStyled = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
  color: ${({ color }) => color};
`;

export const Caption = styled.span`
  color: ${({ color }) => color};
  font-size: 12px;

  display: flex;
  align-items: center;
  gap: 5px;
`;
