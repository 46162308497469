import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import {
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';

const formatQuantity = (item) => {
  if (item.isTotalReturned) {
    return '- ' + item.quantity;
  }
  if (
    item.status &&
    item.type &&
    item.status.includes('Cancelad') &&
    (item.type.includes('Venda') ||
      item.type.includes('PDV') ||
      item.type.includes('Ordem de Serviço'))
  ) {
    return '+ ' + item.quantity;
  }
  if (item.stockMovementType === 'D') {
    return '- ' + item.quantity;
  }
  if (item.stockMovementType === 'C') {
    return '+ ' + item.quantity;
  }
  if (item.type === 'Devolução') {
    return '+ ' + item.quantity;
  }
  return item.quantity;
};

const Header = ({ result, initialDate, finalDate }) => {
  return (
    <View style={{ marginTop: 10 }}>
      <Text>
        {initialDate} - {finalDate}
      </Text>

      <View style={{ marginTop: 10 }}>
        <Text style={[styles.titleTexts, styles.text]}>
          Produto: {result.product.code} - {result.product.description}
        </Text>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '50%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Família: </Text>
            {result.product.family}
          </Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Localização: </Text>
            {result.product.location}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '50%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Marca: </Text>
            {result.product.brand}
          </Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Ref. Fabricante: </Text>
            {result.product.manufacturerRef}
          </Text>
        </View>
      </View>

      <View style={[styles.line, { marginTop: 5 }]} />

      <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>R$ Compra: </Text>
            {currency(result.product.purchasePrice)}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Comissão (%): </Text>
            {percentage(result.product.commissionRate)}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Estoque Mínimo: </Text>
            {result.product.minStock}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Margem: </Text>
            {percentage(result.product.profitMargin)}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Base Multi: </Text>
            {result.product.multiplierBase}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Estoque Máximo: </Text>
            {result.product.maxStock}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '33%' }}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>R$ Venda: </Text>
            {currency(result.product.salePrice)}
          </Text>
        </View>
        <View style={{}}>
          <Text style={styles.text}>
            <Text style={styles.titleTexts}>Estoque Atual: </Text>
            {result.product.stockQuantity}
          </Text>
        </View>
      </View>

      <View style={[styles.line, { marginTop: 5 }]} />
    </View>
  );
};

const HistoryHeader = () => (
  <View style={{ paddingTop: 10 }}>
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View
        style={{ width: '7.5%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Data/Hora</Text>
      </View>
      <View
        style={{
          width: '17.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Tipo</Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Nº</Text>
      </View>
      <View
        style={{
          width: '22.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Descrição</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Cliente/Fornecedor</Text>
      </View>
      <View
        style={{ width: '12%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Quantidade</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>R$ Movimentado</Text>
      </View>
    </View>

    <View style={[styles.line, { marginTop: 1 }]} />
  </View>
);

const HistoryData = ({ item }) => {
  const hasEditedDescription = item.descriptionUpdated;
  const isCancelled = item.status && item.status.includes('Cancelad');

  return (
    <View style={{ paddingTop: 2 }}>
      {isCancelled ? (
        <View
          style={[
            styles.line,
            { position: 'absolute', top: '50%', width: '95%' },
          ]}
        />
      ) : (
        <View />
      )}
      <View
        style={{
          flexDirection: 'row',
          height: 12,
          width: '100%',
          color: hasEditedDescription ? 'red' : 'black',
        }}
      >
        <View
          style={{ width: '7.5%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.content}>
            {item.date ? format(new Date(item.date), 'dd/MM/yyyy') : '--'}
          </Text>
        </View>
        <View
          style={{
            width: '17.5%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>{item.type}</Text>
        </View>
        <View
          style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.content}>{item.code}</Text>
        </View>
        <View
          style={{
            width: '22.5%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>{item.description}</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>{item.name || item.customerName}</Text>
        </View>
        <View
          style={{
            width: '12%',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={
              item.stockMovementType == 'D' ? styles.debitsText : styles.content
            }
          >
            {item && formatQuantity(item)}
            {hasEditedDescription === 1 && '**'}
          </Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.content}>
            {!item.amount ? '--' : currency(item.amount)}
          </Text>
        </View>
        {isCancelled ? (
          <View
            style={{
              textAlign: 'right',
              justifyContent: 'center',
            }}
          >
            <Text style={[styles.content]}>*</Text>
          </View>
        ) : (
          <View />
        )}
      </View>
    </View>
  );
};

const HistoryResults = ({
  result,
  hasProductWithDescriptionUpdated,
  isCancelled,
}) => {
  return (
    <View>
      <View style={[styles.line, { margin: '10px 0px' }]} />
      {hasProductWithDescriptionUpdated || isCancelled ? (
        <View
          style={{
            flexDirection: 'column',
            marginBottom: '10px',
            textAlign: 'right',
          }}
        >
          {isCancelled > 0 ? (
            <Text style={styles.content}>
              * Vendas, Compras ou Movimentações canceladas estão riscadas
            </Text>
          ) : (
            <View />
          )}
          {hasProductWithDescriptionUpdated ? (
            <Text style={styles.content}>
              ** Quantidade de produtos movimentadas com a descrição alterada
            </Text>
          ) : (
            <View />
          )}
        </View>
      ) : (
        <View />
      )}

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingRight: 15,
        }}
      >
        <Text style={styles.text}>
          <Text style={styles.titleTexts}>Saldo de estoque do período: </Text>
          {'       '}
          {result.stockBalance}
        </Text>
      </View>
      <View style={[styles.line, { marginTop: 10 }]} />
    </View>
  );
};

export const ProductDocument = ({ result, initialDate, finalDate }) => {
  const hasProductWithDescriptionUpdated = result.history.some(
    (item) => item.descriptionUpdated
  );

  const isCancelled = result.history.reduce((total, item) => {
    if (item.status && item.status.includes('Cancelad')) {
      return total + item.quantity;
    }
    return total;
  }, 0);

  return (
    <Document
      title={`histórico-do-produto-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="histórico"
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>Histórico do Produto</Text>
        </View>
        <Header
          result={result}
          initialDate={initialDate}
          finalDate={finalDate}
        />
        <HistoryHeader />

        {result.history.length > 0 ? (
          result.history.map((item, index) => (
            <HistoryData key={index} item={item} />
          ))
        ) : (
          <View />
        )}

        {result.history.length > 0 ? (
          <HistoryResults
            result={result}
            hasProductWithDescriptionUpdated={hasProductWithDescriptionUpdated}
            isCancelled={isCancelled}
          />
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  content: {
    fontSize: 9,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  debitsText: {
    fontSize: 9,
    color: '#FF0000',
  },
});
