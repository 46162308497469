import React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { currency, cpfMask } from 'client/components/ToNormalize/ToNormalize';

import {
  FINANCING_AMOUNT,
  FINANCING_FAILED_MESSAGE,
  FINANCING_TRANSACTIONID,
  FINANCING_PARTNERID,
} from '../../recoil';

const FinancingDeniedStep = () => {
  const [partnerId] = useRecoilState(FINANCING_PARTNERID);

  const [financingTransactionId, setFinancingTransactionId] = useRecoilState(
    FINANCING_TRANSACTIONID
  );

  const [financingAmount, setFinancingAmount] =
    useRecoilState(FINANCING_AMOUNT);

  const [financingFailedMessage, setFinancingFailedMessage] = useRecoilState(
    FINANCING_FAILED_MESSAGE
  );
  const { saleReducer } = useSelector((state) => state);
  const { selectedClient, total } = saleReducer;

  return (
    <div>
      <div style={{ fontSize: '13px' }}>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            marginBottom: 0,
          }}
        >
          <FontAwesomeIcon
            icon={faXmarkCircle}
            color="#d9534f"
            fontSize={'15px'}
          />{' '}
          &nbsp;
          <strong>Financiamento não foi aprovado</strong>
        </p>
        <p style={{ marginLeft: '18px' }}>
          Para finalizar a venda/ordem de serviço, será necessário selecionar
          outra forma de pagamento.
        </p>
      </div>
      <div
        style={{
          fontSize: '13px',
          marginTop: '5px',
          marginLeft: '18px',
        }}
      >
        <p style={{ marginBottom: 0 }}>
          <strong>Nome Completo:</strong>&nbsp;{' '}
          {selectedClient?.Company_Name || selectedClient?.Trading_Name}
        </p>
        <p style={{ marginBottom: 0 }}>
          <strong>CPF:</strong>&nbsp; {cpfMask(selectedClient.Cpf_Cnpj)}
        </p>
        <p style={{ marginBottom: 0 }}>
          <strong>Valor do Financiamento:</strong>&nbsp;{' '}
          {currency(financingAmount || total)}
        </p>
        {partnerId === 2 && (
          <div className="flex column">
            <p style={{ marginLeft: '0px' }}>
              Por favor, entre em contato com suporte do <strong>Guru</strong>{' '}
              nos canais abaixo e informe o{' '}
              <strong>Nome Completo, CPF e Valor do Financiamento</strong>:
            </p>{' '}
            <div className="flex row gap-050 between">
              <div className="flex column">
                <span>
                  <strong>Consumidor (WhatsApp): (11) 2103-8530</strong>
                </span>
                <span>- Segunda a sexta das 9h às 18h</span>
                <span>- Sábado das 9h às 18h</span>
              </div>

              <div className="flex column">
                <span>
                  <strong>Lojista (Whatsapp): (48) 8838-6049</strong>
                </span>
                <span>- Segunda a sexta das 8h às 22h</span>
                <span>- Sábado das 8h às 19h</span>
                <span>- Feriados Plantão</span>
              </div>
            </div>
          </div>
        )}
        {financingTransactionId && (
          <p>
            <strong>ID da Transação do Financiamento:</strong>&nbsp;
            {financingTransactionId}
          </p>
        )}
        {financingFailedMessage && (
          <p
            style={{
              marginTop: '20px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            <strong>Erro:</strong>&nbsp;
            {financingFailedMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default FinancingDeniedStep;
