import api from 'v2/services/api';

export const listAllApproachActive = async (companyId) => {
  try {
    const res = await api.get('approach/unqualified', {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getApproach = async (id, companyId) => {
  try {
    const res = await api.get('approach/' + id, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listApproach = async (params) => {
  try {
    const res = await api.get('approach', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createApproach = async (data) => {
  try {
    const res = await api.post('approach', data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateApproach = async (id, data) => {
  try {
    const res = await api.put('approach/' + id, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteApproach = async (id) => {
  try {
    await api.delete('approach/' + id);
  } catch (err) {
    throw err;
  }
};

const CustomerRepository = {
  listAllApproachActive,
  getApproach,
  listApproach,
  createApproach,
  updateApproach,
  deleteApproach,
};

export default CustomerRepository;
