import React, { useState } from 'react'
import MateraResumeCard from './MateraResumeCard'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import CardForm from 'client/components/CardForm'

import {
  currency,
  percentage,
} from '../../../../components/ToNormalize/ToNormalize'

import '../styles.css'

const MateraAccountResume = ({ info, loading }) => {
  const [isResumeOpen, setIsResumeOpen] = useState(true)

  const StyledSkeleton = () => (
    <SkeletonTheme baseColor='#007491' highlightColor='#397f91'>
      <Skeleton height={30} width={200} />
    </SkeletonTheme>
  )

  return (
    <CardForm
      title='Resumo'
      show={isResumeOpen}
      onClick={() => setIsResumeOpen(!isResumeOpen)}
    >
      <p
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          color: '#d9534f',
          fontSize: '12px',
          fontWeight: 'bold',
        }}
      >
        {!loading ? `Taxa PIX:  ${percentage(info?.taxPix)}` : ''}
      </p>
      <div style={{ display: 'flex' }}>
        <MateraResumeCard
          title={'Agência / Conta Bancária'}
          info={
            loading ? (
              <StyledSkeleton />
            ) : (
              `${info?.agency || 0} / ${info?.accountNumber || 0}`
            )
          }
        />
        <MateraResumeCard
          title={'Saldo Atual'}
          info={
            loading ? <StyledSkeleton /> : `${currency(info?.balance || 0)}`
          }
        />
      </div>
    </CardForm>
  )
}

export default MateraAccountResume
