import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import constants from 'utils/constants';
import DiscountGroupTables from './DiscountGroupTables';

const DiscountGroups = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastros',
              'Vendas',
              'Tabela de Preços Diferenciados',
            ]}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <DiscountGroupTables />
        </Row>
      </Grid>
    </div>
  );
};
export default DiscountGroups;
