import React from 'react'
import styled from 'styled-components'

function getBackgroundColorByStatus(status) {
  const colors = {
    'Em Andamento': '#5cb85c',
    'Não Iniciado': '#ffc40d',
    Parado: '#d9534f',
    Finalizado: '#5bc0de',
  }

  return colors[status]
}

const StatusLabel = styled.button`
  height: 29px;
  border: none;
  font-size: 12px;
  color: #fff;
  width: 80%;
  border-radius: 3px;
  background-color: ${(props) => getBackgroundColorByStatus(props.status)};
  -webkit-box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);
`

const ElapsedTimeSpan = styled.span`
  display: block;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
`

export default function MaintenanceStatus({ status, elapsedTime }) {
  return (
    <>
      <StatusLabel status={status}>{status}</StatusLabel>
      {status !== 'Não Iniciado' && (
        <ElapsedTimeSpan>
          Tempo Decorrido: {elapsedTime.replace(':', 'h')}
        </ElapsedTimeSpan>
      )}
    </>
  )
}
