import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import BirthdaysReportMain from './components/BirthdaysReportMain'

const Birthdays = () => {
  return (
    <>
      <div className="content">
        <Grid fluid>
          <Row>
            <BreadCrumb
              data={[
                'Início',
                'Relatórios',
                'Clientes/Funcionários',
                'Relatório de Aniversariantes',
              ]}
              path={['Home', null, null, null]}
            />
          </Row>
        </Grid>

        <BirthdaysReportMain />
      </div>
    </>
  )
}

export default Birthdays
