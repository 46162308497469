import React, { useEffect, useState } from 'react'
import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import { PDFViewer } from '@react-pdf/renderer'

import { SalesPerFamilyProductPDF } from './SalesPerFamilyProductPDF'
import SalesRepository from 'repositories/Sales'
import { addDays, format } from 'date-fns'

export const SalesPerFamilyProductReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [reportType, setReportType] = useState('')
  const [result, setResult] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const companyId = searchParams.get('companyId')
      const familyId = searchParams.get('familyId')
      const reportType = searchParams.get('reportType')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))


      setReportType(reportType)
      setHasError(false)

      return loadReport(companyId, { initialDate, finalDate, familyId, reportType })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (companyId, params) => {
    try {
      const selectedFamilyId = params.familyId == '' ? '' : params.familyId

      const data = await SalesRepository.getAllSalesByFamily({
        ...params,
        companyId,
        Family_id: selectedFamilyId,
      })

      setResult(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='quotes-supplier-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <SalesPerFamilyProductPDF
            result={result}
            initialDate={initialDate}
            finalDate={finalDate}
            reportType={reportType}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
