import React, { useState } from 'react'
import { Modal, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { handleChange, handleConfirmChangesInCashierBankModal } from './redux/actions'
import CurrencyInput from 'client/components/Currency'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import { toastr } from 'react-redux-toastr'

const CashierBankModal = () => {
  const dispatch = useDispatch()
  const { saleReducer } = useSelector(state => state)
  const {
    openCashierBankModal,
    selectedCondOfPayment,
    installments,
    formOfPayments,
    cashierBanks,
    saleStatusId
  } = saleReducer
  const [newInstallments, setNewInstallments] = useState(installments)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const handleReplicateCashierBank = () => {
    if (saleStatusId !== 5 && saleStatusId !== 2) {
      !newInstallments[0].cashierBank?.id
        ? toastr.warning('Selecione um Caixa/Banco na primeira linha')
        : replicateFirstCashierBank()
    }
  }

  const replicateFirstCashierBank = () => {
    setNewInstallments(newInstallments.map(item => ({
      ...item,
      cashierBank: newInstallments[0].cashierBank
    })))
  }

  const handleInstallmentCashierBank = (value, index) => {

    let cashierBank = cashierBanks.filter(c => c.id.toString() === value.toString())[0]

    if (cashierBank) {
      setNewInstallments(newInstallments.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            cashierBank
          }
        }
        return item
      }))
    } else {
      setNewInstallments(newInstallments.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            cashierBank: { id: '' }
          }
        }
        return item
      }))
    }
  }

  const handleConfirm = () => {
    setIsConfirmModalOpen(false)
    dispatch(handleConfirmChangesInCashierBankModal(newInstallments))
  }

  const FooterButtons = () => {
    if (saleStatusId === 5 || saleStatusId === 2) {
      return <Button bsStyle="danger" onClick={() => dispatch(handleChange(false, 'openCashierBankModal'))} fill>Fechar</Button>
    }
    return (
      <>
        <Button bsStyle="danger" onClick={() => dispatch(handleChange(false, 'openCashierBankModal'))} fill>Cancelar</Button>
        <Button bsStyle="info" onClick={() => setIsConfirmModalOpen(true)} fill>Salvar</Button>
      </>
    )
  }

  return (
    <>
      <Modal
        dialogClassName="modal-veiculos"
        show={openCashierBankModal}
        onHide={() => dispatch(handleChange(false, 'openCashierBankModal'))}
        animation
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Alteração de Caixa/Banco dos Títulos</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: "calc(100vh - 120px)",
          overflowY: "auto",
          margin: 0,
          paddingRight: 0,
          paddingLeft: 0
        }}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <strong>Condição de pagamento: </strong>{selectedCondOfPayment?.Description}
          </Col>
          {newInstallments.map((item, index) => (
            <div key={index} id="grid-payment" className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Col xs={1} sm={1} md={1} lg={1}>
                {index === 0 && <label><strong>Parcela</strong></label>}
                <p style={{ marginLeft: '10px' }}>
                  {selectedCondOfPayment.Condition === 'À vista' || selectedCondOfPayment.Condition === '0' ?
                    'À vista' : selectedCondOfPayment.hasEntry && index === 0 ?
                      "Entrada" : !selectedCondOfPayment.hasEntry ?
                        `${index + 1}/${installments.length}` :
                        `${index}/${installments.length - 1}`
                  }
                </p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {index === 0 && <label><strong>Vencimento</strong></label>}
                <input
                  className='form-control foco-input'
                  value={item.date}
                  disabled
                  type='date'
                />
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {index === 0 && <label><strong>Valor</strong></label>}
                <CurrencyInput
                  className='form-control foco-input'
                  value={item.value}
                  disabled
                />
              </Col>

              <Col xs={3} sm={3} md={3} lg={3}>
                {index === 0 &&
                  <label>
                    <strong>Forma de Pagamento</strong>
                  </label>
                }
                <select
                  className='form-control foco-input'
                  value={item.formOfPayment.id}
                  disabled
                >
                  <option value=''>Selecione</option>
                  {formOfPayments.map(f =>
                    <option key={f.id} value={f.id}>{f.Desciption}</option>
                  )}
                </select>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} className="installments-checkbox" >
                {index === 0 && <label><strong>Valor Recebido?</strong></label>}
                <input
                  type="checkbox"
                  checked={item.amountReceived}
                  disabled
                />
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {index === 0 && <label><strong>Caixa/Banco</strong></label>}
                <select
                  className='form-control foco-input'
                  onChange={e => handleInstallmentCashierBank(e.target.value, index)}
                  value={item.cashierBank?.id || ''}
                  disabled={saleStatusId === 5 || saleStatusId === 2}
                >
                  {cashierBanks.map(cashierBank =>
                    <option
                      key={cashierBank.id}
                      value={cashierBank.id}
                    >
                      {cashierBank.CashierBankType.initials} - {cashierBank.description}
                    </option>
                  )}
                </select>
              </Col>

              {index === 0 && installments.length > 1 &&
                <Col style={{ paddingTop: '5px' }}>
                  <Col xs={12} sm={12} md={12} lg={12} id="col-replicate-form-of-payment">
                    <a
                      className="div-a" style={{ paddingTop: '10px', paddingLeft: '10px' }}
                      onClick={handleReplicateCashierBank}
                    >
                      Replicar
                  </a>
                  </Col>
                </Col>
              }
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <FooterButtons />
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isConfirmModalOpen} animation onHide={() => setIsConfirmModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>
            <strong>Confirmação de alteração de Caixa/Banco</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja confirmar a alteração de Caixa/Banco realizada? A mudança poderá ser feita posteriormente nos títulos.
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button bsStyle="danger" onClick={() => setIsConfirmModalOpen(false)} fill>Não</Button>
            <Button bsStyle="info" onClick={handleConfirm} fill>Sim</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CashierBankModal