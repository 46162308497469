import { atom } from 'recoil'

export const inventoryWriteDownMode = atom({
  key: 'inventoryWriteDownMode',
  default: '',
})

const StorageCompanyConfig = {
  inventoryWriteDownMode,
}

export default StorageCompanyConfig
