import React, { useEffect, useState } from 'react'
import CustomerListFilter from './CustomerListFilter'
import CustomerListTable from './CustomerListTable'
import customersRepository from 'repositories/Customers'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { phoneMask } from 'client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const CustomerListMain = () => {
  const [customers, setCustomers] = useState([])
  const [excelData, setExcelData] = useState([])

  const [readyToLoad, setIsReadyToLoad] = useState(false)
  const [customerType, setCustomerType] = useState('')
  const [type, setType] = useState('Sintetico')
  const [state, setState] = useState('')
  const [status, setStatus] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [isDisabled, setIsDisabled] = useState(true)

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const { companyId } = useAuth()

  const loadCustomers = async () => {
    setIsLoading(true)
    loadExcel()
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        customerType,
        state,
        status,
      }

      const response = await customersRepository.getCustomerList(
        companyId,
        params
      )

      const formatedCustomer = response.customers.map((item) => ({
        customerCpfCnpj: cpfOrCnpjMask(item.customerCpfCnpj),
        customerType: item.customerType,
        customerName: item.customerCompanyName || item.customerTradingName,
        customerPhone:
          phoneMask(item.customerPhone) || phoneMask(item.customerCell),
        customerCity: item.customerCity,
        customerState: item.customerState,
        customerEmail: item.customerEmail,
        customerDate: item.customerDate,
        formatedDate: format(new Date(item.customerDate), 'dd/MM/yyyy'),
        customerStatus: item.customerStatus,
      }))

      setCustomers(formatedCustomer)
      setTotalPages(Math.ceil(response.count / pageLimit))
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível carregar a listagem de clientes.',
        'Por favor, tente novamente.'
      )
      setIsLoading(false)
    }
  }

  const openPDF = async () => {
    setIsLoading(true)
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        customerType,
        state,
        status,
      }

      const { customers } = await customersRepository.getCustomerList(
        companyId,
        params
      )

      if (!customers.length) {
        return toastr.warning(
          'Não foi possível encontrar nenhum cliente com os filtros selecionados.',
          'Por favor, altere os filtros e tente novamente.'
        )
      }

      window.open(
        window.location.origin +
          `/report/customers-list?companyId=${companyId}&customerType=${customerType}&state=${state}&status=${status}&type=${type}`
      )

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      toastr.error(
        'Não foi possível abrir o PDF.',
        'Por favor, tente novamente.'
      )
    }
  }

  const loadExcel = async () => {
    setIsLoading(true)
    try {
      const params = {
        customerType,
        state,
        status,
      }

      const { customers } = await customersRepository.getCustomerList(
        companyId,
        params
      )

      const formatedCustomer = customers.map((item) => ({
        customerCpfCnpj: cpfOrCnpjMask(item.customerCpfCnpj),
        customerType: item.customerType,
        customerName: item.customerCompanyName || item.customerTradingName,
        customerPhone:
          phoneMask(item.customerPhone) || phoneMask(item.customerCell),
        customerCity: item.customerCity,
        customerState: item.customerState,
        customerEmail: item.customerEmail,
        customerDate: item.customerDate,
        formatedDate: format(new Date(item.customerDate), 'dd/MM/yyyy'),
        customerStatus: item.customerStatus,
      }))

      setExcelData(formatedCustomer)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      toastr.error(
        'Não foi possível fazer a exportação para xls.',
        'Por favor, tente novamente.'
      )
    }
  }

  useEffect(() => {
    if (readyToLoad) {
      loadCustomers()
    }
  }, [currentPage, pageLimit])

  return (
    <>
      <CustomerListFilter
        customers={customers}
        setCustomerType={setCustomerType}
        setType={setType}
        setState={setState}
        setStatus={setStatus}
        isLoading={isLoading}
        setIsReadyToLoad={setIsReadyToLoad}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        loadCustomers={loadCustomers}
        excelData={excelData}
        openPDF={openPDF}
      />
      <CustomerListTable
        customers={customers}
        isLoading={isLoading}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        loadCustomers={loadCustomers}
      />
    </>
  )
}

export default CustomerListMain
