import React, { useState, useEffect } from 'react'

import { reduxForm } from 'redux-form'

import MainData from './MainData'

import CardForm from 'client/components/CardForm'

import FormSubmitButtons from 'client/components/FormSubmitButtons'

function FormNewDiscountGroupTable({
  handleSubmit,
  initialValues,
  discountGroupId,
  onCancel,
  loading,
}) {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        onCancel={() => onCancel(true)}
        style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
      >
        <CardForm title='Tabela de Preços Diferenciados'>
          <MainData discountGroupId={discountGroupId} />
        </CardForm>
        <FormSubmitButtons
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={() => onCancel(true)}
          hasId={discountGroupId}
        />
      </form>
    </>
  )
}

export default reduxForm({
  form: 'discountGroup',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormNewDiscountGroupTable)
