import React from 'react'
import { Col } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import Toggle from 'react-toggle'

import Input from './components/Input'
import Select from './components/Select'
import TextArea from './components/TextArea'
import { descriptionWithAltSign } from '../../../../components/ToNormalize/ToNormalize'

import PJ from './PJ'
import PF from './PF'

const BasicData = ({ edit }) => {
  const { isActive, type } = useSelector(
    (state) => state.form.formProvider.values
  )

  const dispatch = useDispatch()

  function handleType() {
    dispatch([
      change('cadastroCliente', 'cpfCnpj', ''),
      change('cadastroCliente', 'RG', ''),
      change('cadastroCliente', 'companyName', ''),
      change('cadastroCliente', 'tradingName', ''),
      change('cadastroCliente', 'email', ''),
      change('cadastroCliente', 'commercialPhone', ''),
      change('cadastroCliente', 'cellPhone', ''),
      change('cadastroCliente', 'contact', ''),
      change('cadastroCliente', 'statusIE', ''),
      change('cadastroCliente', 'IE', ''),
    ])
  }

  return (
    <div>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={2} md={2} sm={3} xs={12}>
          <label>Tipo de Pessoa</label>
          <Field
            name="type"
            onChange={handleType}
            disabled={edit}
            component={Select}
          >
            <option value="Juridica">Jurídica</option>
            <option value="Fisica">Física</option>
          </Field>
        </Col>
        <Col lg={1} md={2} sm={1} xs={12}>
          <label>
            Status<span style={{ color: 'red' }}>*</span>
          </label>
          <div id="toggle-status">
            <Toggle
              checked={isActive}
              name="isActive"
              onChange={() =>
                dispatch(change('formProvider', 'isActive', !isActive))
              }
            />
          </div>
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        {/* Feito desta forma porque com === Jurídica, ele exibe o formulário de PF, possivelmente está fazendo a renderização antes de ter um valor do type */}
        {type !== 'Fisica' ? <PJ edit={edit} /> : <PF edit={edit} />}
      </Col>

      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="form-client-dadosb-footer"
      >
        <Col
          lg={4}
          md={4}
          sm={4}
          xs={12}
          style={{
            marginBottom: '20px',
            marginLeft: '0px',
            padding: '0px',
            width: '150px',
          }}
        >
          <Field
            label="Data do cadastro:"
            name="date"
            component={Input}
            disabled
          />
        </Col>
        <Col lg={8} md={8} sm={8} xs={12}>
          <label>Observações (máximo de 500 caracteres):</label>
          <Field
            name="comments"
            component={TextArea}
            normalize={descriptionWithAltSign}
            maxLength="500"
          />
        </Col>
      </Col>
    </div>
  )
}

export default BasicData
