export const getPaymentConditionBreadcrumb = (isEditing) => {
  const data = ['Início', 'Cadastros', 'Vendas', 'Nova Condição'];
  const path = ['home', null, 'payment-conditions', null];

  if (isEditing) {
    data[3] = 'Editar Condição';
  }

  return {
    data,
    path,
  };
};

export const installmentQuantityOptions = (() => {
  const options = [];

  for (let i = 1; i <= 12; i++) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    });
  }

  return options;
})();

export const daysBetweenInstallmentOptions = (() => {
  const options = [];

  for (let i = 1; i <= 30; i++) {
    options.push({
      value: i,
      label: i.toString(),
    });
  }

  return options;
})();

export const hasEntryOptions = [
  {
    value: false,
    label: 'Não',
  },
  {
    value: true,
    label: 'Sim',
  },
];

export const isFeeActiveOptions = [
  {
    value: false,
    label: 'Não',
  },
  {
    value: true,
    label: 'Sim',
  },
];
