import React, { useState } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { isValid, isBefore } from 'date-fns';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { Col } from 'react-bootstrap';

const FinancialStatement = () => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [loading, setLoading] = useState(false);
  const { companyId } = useAuth();

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      handlePrint();
    } catch (err) {
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar o histórico. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    return true;
  };

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    window.open(
      window.location.origin +
        `/income-statement?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}`,
      '_blank'
    );
  };

  return (
    <div>
      <Col
        style={{ display: 'flex', marginTop: '40px', paddingBottom: '50px' }}
      >
        <div style={{ marginLeft: '10px' }}>
          <label>
            <strong>Data Inicial:</strong>
          </label>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            max={finalDate}
            style={{
              width: '160px',
            }}
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <label>
            <strong>Data Final:</strong>
          </label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
            min={initialDate}
            style={{
              width: '160px',
            }}
          />
        </div>
        <section style={{ padding: '38px 0px 0px 10px' }}>
          <button
            className="btn btn-sucesso"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </section>
      </Col>
    </div>
  );
};

export default FinancialStatement;
