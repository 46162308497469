import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'v2/hooks/useRouter';
import { deleteGroupItem } from 'v2/repositories/GroupItemsRepository';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir o grupo? Ao excluir, não poderá
        vinculá-lo a nenhum checklist.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluído, não poderá ser recuperado.
      </span>
    </div>
  );
};

export function TableActions({ id, triggerFetch }) {
  const { navigateToClientPage } = useRouter();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteGroupItem(Number(id));
      toastr.success('Grupo excluído', 'Exclusão realizada com sucesso.');
      triggerFetch();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível excluir o Grupo',
        'O Grupo está vinculado a um ou mais itens de Checklist. Exclua os vínculos com no cadastro da Checklists e tente novamente'
      );
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  const handleDeleteButtonClick = async () => {
    try {
      setIsDeleteModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao validar exclusão',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    }
  };

  return (
    <div className="flex center gap-050">
      <button
        title="Editar"
        className="button-icon"
        onClick={() => navigateToClientPage('group_items', 'form_edit_raw', id)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        title="Excluir"
        className="button-icon"
        onClick={handleDeleteButtonClick}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
