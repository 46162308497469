import React from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import ClientsMain from './ClientsMain';

import './styles.css';

export default function Clients() {
  return (
    <>
      <BreadCrumb data={['Início', 'Clientes']} path={['home', null]} />
      <ClientsMain />
    </>
  );
}
