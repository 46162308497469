import React from 'react'
import { format } from 'date-fns'
import DownloadXlsButton from 'components/DownloadXlsButton'
import ReactSelect from 'react-select'
import PDFButton from 'client/components/PDFButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import '../index.css'

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-40px' }}
    className="fa fa-search"
  />
)

const PendingCustomersSuppliersFilters = ({
  setDateType,
  setInitialDate,
  initialDate,
  setFinalDate,
  finalDate,
  setType,
  type,
  setCustomerSupplier,
  customerSupplier,
  customerInfo,
  data,
  formattedOptions,
  isLoading,
  isDisabled,
  handleSubmit,
  openPDF,
  filterByAll,
  isXlsExportReady,
  isLoadingXlsExport,
  loadExcel,
  setFilterByAll,
  tableData,
}) => {
  return (
    <>
      <div className="client__pending-customers-suppliers-filters">
        <div
          style={{ marginTop: '30px' }}
          className="client__pending-customers-suppliers-row"
        >
          <div className="client__pending-customers-suppliers-column">
            <span>
              <strong>Tipo de Data: </strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setDateType(e.target.value)}
            >
              <option value="dueDate">Vencimento</option>
              <option value="issuedAt">Criação</option>
            </select>
          </div>

          <div className="client__pending-customers-suppliers-column">
            <span>
              <strong>Data Inicial:</strong>
            </span>
            <input
              type="date"
              className="form-control foco-input"
              onChange={(e) => setInitialDate(e.target.value)}
              max={
                finalDate
                  ? format(new Date(finalDate), 'yyyy-MM-dd')
                  : format(new Date(), 'yyyy-MM-dd')
              }
            />
          </div>

          <div className="client__pending-customers-suppliers-column">
            <span>
              <strong>Data Final:</strong>
            </span>
            <input
              type="date"
              className="form-control foco-input"
              onChange={(e) => setFinalDate(e.target.value)}
              min={initialDate}
            />
          </div>

          <div className="client__pending-customers-suppliers-column">
            <span>
              <strong>Tipo: </strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="client">Cliente</option>
              <option value="provider">Fornecedor</option>
            </select>
          </div>
        </div>

        <div
          style={{ marginTop: '15px' }}
          className="client__pending-customers-suppliers-row"
        >
          <div className="client__pending-customers-suppliers-column">
            <span>
              <strong>Filtrar por: </strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setFilterByAll(e.target.value)}
            >
              <option value={true}>Todos</option>
              <option value={false}>Apenas um</option>
            </select>
          </div>
          <div className="client__pending-customers-suppliers-search">
            <ReactSelect
              isSearchable
              defaultValue={{}}
              options={formattedOptions}
              isDisabled={filterByAll === 'true'}
              placeholder="Pesquisar por CPF/CNPJ ou Nome/Razão Social/NomeFantasia"
              onChange={(item) => setCustomerSupplier(item)}
              noOptionsMessage={() => 'Nenhum cliente encontrado'}
              components={{
                DropdownIndicator: Icon,
              }}
              value={customerSupplier}
              isLoading={isLoading}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                  width: '50rem',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '35px',
                  padding: '0 0 0 10px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                }),
              }}
            />
          </div>

          <div className="client__customer-list-button">
            <button
              className="btn btn-sucesso"
              type="submit"
              onClick={() => handleSubmit()}
            >
              <span
                className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </div>

          <div className="client__customer-list-button">
            {isXlsExportReady ? (
              <DownloadXlsButton
                archiveName={`Pendências ${
                  filterByAll !== 'true'
                    ? `de ${tableData[0].personName}`
                    : `${type === 'client' ? 'de Clientes' : 'de Fornecedores'}`
                }`}
                data={data}
                className="btn btn-export"
                disabled={isLoading || !tableData.length}
                columns={[
                  {
                    name: 'Vencimento',
                    acessor: 'dueDate',
                  },
                  {
                    name: 'Título',
                    acessor: 'code',
                  },
                  {
                    name: type === 'client' ? 'Venda' : 'Compra',
                    acessor: 'title',
                  },
                  {
                    name: type === 'client' ? 'Cliente' : 'Fornecedor',
                    acessor: 'personName',
                  },
                  {
                    name: 'Acréscimo',
                    acessor: 'addedValue',
                  },
                  {
                    name: 'Desconto',
                    acessor: 'discountValue',
                  },
                  {
                    name: 'Total',
                    acessor: 'liquidValue',
                  },
                  {
                    name: 'Valor Pago',
                    acessor: 'paidValue',
                  },
                  {
                    name: 'Valor em Aberto',
                    acessor: 'openValue',
                  },
                  {
                    name: 'Criação',
                    acessor: 'issuedAt',
                  },
                ]}
                style={{ width: '100%' }}
              >
                <span
                  className={`${
                    isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                  }`}
                />{' '}
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            ) : (
              <button
                className="btn btn-export"
                onClick={() => loadExcel()}
                disabled={isLoadingXlsExport || !tableData.length}
              >
                {isLoadingXlsExport ? (
                  <span className="fa fa-spinner fa-pulse fa-1x"></span>
                ) : (
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                )}
                &nbsp;Exportar .xls
              </button>
            )}
          </div>

          <div className="client__customer-list-button">
            <PDFButton
              isLoading={isLoading}
              disabled={isDisabled || isLoading || !tableData.length}
              onClick={() => openPDF()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PendingCustomersSuppliersFilters
