import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from 'utils/constants'
import ProductsTable from './ProductsTable'

const DiscountPriceProducts = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastro',
              'Vendas',
              'Tabela de Preços Diferenciados',
              'Produtos',
            ]}
            path={[
              '/client/' + constants.ROUTES.HOME,
              null,
              null,
              '/client/' + constants.ROUTES.DISCOUNT_GROUPS,
              null,
            ]}
          />
          <ProductsTable match={match} />
        </Row>
      </Grid>
    </div>
  )
}
export default DiscountPriceProducts
