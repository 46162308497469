import { currency } from 'client/components/ToNormalize/ToNormalize'
import { format, isValid, isAfter } from 'date-fns'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
export const xlsColumns = [
  {
    name: 'Data Ent.',
    acessor: 'entryDate',
  },
  {
    name: 'Compra',
    acessor: 'purchase',
  },
  {
    name: 'Status',
    acessor: 'isApproved',
  },
  {
    name: 'Data Aprov.',
    acessor: 'approvedDate',
  },
  {
    name: 'Descrição',
    acessor: 'description',
  },
  {
    name: 'Bs. Multi',
    acessor: 'multiplierBase',
  },
  {
    name: 'QTD',
    acessor: 'quantity',
  },
  {
    name: 'Valor Unitário',
    acessor: 'unitValue',
  },
  {
    name: 'Total',
    acessor: 'total',
  },
]

export const formatProducts = (products) => {
  return products.map((prod) => {
    return {
      ...prod,
      unitValue: currency(prod.unitValue),
      total: currency(prod.total),
      isApproved: prod.isApproved ? 'Aprovado' : 'Não Aprovado',
      approvedDate:
        isValid(new Date(prod?.approvedDate)) &&
        isAfter(new Date(prod?.approvedDate), new Date('01/01/2000'))
          ? format(prod?.approvedDate.setHours(0, 0, 0, 0), 'dd/MM/yyyy')
          : null,
    }
  })
}
