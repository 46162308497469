import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NFSeCitiesListMain from './components/CitiesListMain'

import './styles.css'

const NFSeCitiesList = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Nota Fiscal de Serviço', 'Listagem de Cidades']}
        path={['home', null, null]}
      />
      <NFSeCitiesListMain />
    </>
  )
}

export default NFSeCitiesList
