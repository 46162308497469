import React, { useState, useEffect } from 'react';
import { isValid } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import InventoryMovementRepository from '../../../../../repositories/InventoryMovement'


import '../styles.css'

const InventoryMovementListingMain = () => {
  const [loading, setLoading] = useState(false)
  const [finalDate, setFinalDate] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [returnStatus, setReturnStatus] = useState('')
  const [searchByType, setSearchByType] = useState('updatedAt')
  const { companyId } = useAuth()


  const handleSubmit = async () => {
    if (validations()) await handleProcess()
  }

  const validations = () => {

    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate))
    ) {
      toastr.warning('Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente')
      return false
    }

    return true
  }



  const handleProcess = async () => {
    setLoading(true)
    try {

      const data = await InventoryMovementRepository.generateInventoryReportMovement({
        companyId,
        initialDate,
        finalDate,
        returnStatus,
        searchByType
      })

      if (data?.report.inventorys.length <= 0) {
        toastr.warning('Nenhuma informação para o relatório foi encontrada.')
        return
      } else {
        handlePrint()
      }
    } catch (err) {
      console.log(err)
      toastr.error(err?.response?.data?.message || 'Ocorreu um erro com a buscar. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    window.open(window.location.origin + `/report/inventory-movement?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&returnStatus=${returnStatus}&searchByType=${searchByType}`, '_blank')

  }

  return (
    <div className='content-inventory-movement-listing'>
      <section >
        <div style={{ width: '200px' }}>
          <label>Pesquisar por:</label>
          <select value={searchByType}
            onChange={(e) => setSearchByType(e.target.value)}
            className="form-control foco-input">
            <option value="updatedAt">Data de Movimentação</option>
            <option value="createdAt">Data de Cadastro</option>
          </select>
        </div>
        <div className='teste-data-teste-Leandro'>
          <div >
            <label>Data Inicial:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={e => setInitialDate(e.target.value)}
            />
          </div>

          <div >
            <label>Data Final:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={e => setFinalDate(e.target.value)}
            />
          </div>

          <div style={{ width: '200px' }}>
            <label>Status:</label>
            <select value={returnStatus}
              onChange={(e) => setReturnStatus(e.target.value)} className="form-control foco-input">
              <option value="">Todos</option>
              <option value="Finalizada">Finalizada</option>
              <option value="Aberta">Em aberto</option>
              <option value="Cancelada">Cancelada</option>
            </select>
          </div>

          <div>
            <button
              className="btn btn-sucesso inventory-movement-listing-button"
              onClick={handleSubmit}
              disabled={loading}>
              <span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} /> Processar</button>
          </div>
        </div>
      </section>
    </div>
  )
}
export default InventoryMovementListingMain