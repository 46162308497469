import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectMulti } from 'v2/components/Select/Multi';
import { format } from 'date-fns';
import '../../../../styles.css';

export function BankConciliationSearch({ prefix, banksOptions, usersOptions }) {
  const { setValue } = useFormContext();
  const [selectedBankOption, setSelectedBankOption] = useState([
    {
      value: '*',
      label: 'Todos',
    },
  ]);
  const [type, setType] = useState('conciliation');
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [selectedUserOption, setSelectedUserOption] = useState('');

  return (
    <div className="bank-conciliation-row">
      <div>
        <span className="typography__text-2">Bancos:</span>
        <SelectMulti
          value={selectedBankOption}
          options={banksOptions}
          onChange={(e) => {
            setSelectedBankOption(e);
            setValue(`search.${prefix}.selectedBankOption`, e);
          }}
          hasAllOption
          customAllLabel="Todos"
          customVariousLabel="Várias"
          height={'35px'}
          fontSize={'12px'}
          isSearch={true}
          borderColor={'#e3e3e3'}
        />
      </div>
      <fieldset>
        <span className="typography__text-2">Tipo de Data:</span>
        <select
          className="form-control foco-input fontSize-12"
          onChange={(event) => {
            setValue(`search.${prefix}.type`, event.target.value);
            setType(event.target.value);
          }}
          value={type}
        >
          <option value="conciliation">Conciliação</option>
          <option value="archivePeriod">Período do Arquivo</option>
        </select>
      </fieldset>
      <fieldset>
        <span className="typography__text-2">Data Inicial:</span>
        <input
          className="form-control foco-input fontSize-12"
          type="date"
          name="dataInicial"
          value={initialDate}
          max={finalDate || format(new Date(), 'yyyy-MM-dd')}
          onChange={(e) => {
            setValue(`search.${prefix}.initialDate`, e.target.value);
            setInitialDate(e.target.value);
          }}
        />
      </fieldset>
      <fieldset>
        <span className="typography__text-2">Data Final:</span>
        <input
          className="form-control foco-input fontSize-12"
          type="date"
          name="dataFinal"
          value={finalDate}
          min={initialDate}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={(e) => {
            setValue(`search.${prefix}.finalDate`, e.target.value);
            setFinalDate(e.target.value);
          }}
        />
      </fieldset>
      <fieldset>
        <span className="typography__text-2">Responsável:</span>
        <select
          className="form-control foco-input fontSize-12"
          onChange={(event) => {
            setSelectedUserOption(event.target.value);
            setValue(
              `search.${prefix}.selectedUserOptions`,
              event.target.value
            );
          }}
          value={selectedUserOption}
        >
          <option value="">Todos</option>
          {usersOptions.map(({ id, name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
  );
}
