import config from '../config'
import api from "../services/api"

  const create = async values => {
    try {
      const response = await api.post(config.endpoint + 'sat', values)
      return response.data
    } catch (err) {
      throw err
    }
  }

  const getByManufacturerIdAndEquipmentSerie = async (companyId, manufacturerId, equipmentSerie) => {
    try {
      const response = await api.get(config.endpoint + 
        `sat?companyId=${companyId}&manufacturerId=${manufacturerId}&equipmentSerie=${equipmentSerie}`)
      return response.data
    } catch (err) {
      throw err
    }
  }

  const update = async (id, sat) => {
    try {
      await api.put(config.endpoint + `sat/${id}`, sat)
    } catch (err) {
      throw err
    }
  }

  const getById = async id => {
    try {
      const response = await api.get(config.endpoint + `sat/${id}`)
      return response.data
    } catch(err) {
      throw err
    }
  }

  const getAllByCompany = async companyId => {
    try {
      const response = await api.get(config.endpoint + `sat?companyId=${companyId}`)
      return response.data
    } catch (err) {
      throw err
    }
  }

export default {
create,
getByManufacturerIdAndEquipmentSerie,
update,
getById,
getAllByCompany
}