import React from 'react'
import { Col, Modal } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Button from '../CustomButton/CustomButton'

export default function MateraAccountBlockModal({ onCancel }) {
  const iconStyle = { width: 20, height: 20 }

  return (
    <Modal show animation onHide={() => onCancel()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: '16px' }}>
              <strong>
                Você não possui uma conta digital com o OS Digital &nbsp;
              </strong>
            </span>

            <FontAwesomeIcon
              color='#d9534f'
              style={iconStyle}
              icon={faTimesCircle}
            />
          </p>

          <p style={{ marginTop: '30px', fontSize: '14px' }}>
            Caso deseje possuir uma conta digital para receber com o OS Digital,
            recebendo seus Pix com taxa diferenciada, clique no botão abaixo:
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button bsStyle='danger' onClick={() => onCancel()} fill>
            Cancelar
          </Button>

          <Button
            bsStyle='info'
            fill
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20gostaria%20de%20saber%20mais%20sobre%20a%20conta%20digital%20no%20OS%20Digital',
                '_blank'
              )
            }
          >
            <div>
              <FontAwesomeIcon icon={faWhatsapp} /> &nbsp;
              <span>Solicitar informações sobre conta digital</span>
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
