import React, { useState, useEffect } from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import NewNFe from './NewNFe/BundleNewNFe'
import { decrypt } from '../../components/ToNormalize/ToNormalize'
import constants from '../../../utils/constants'

const ViewNewNFe = ({ match, history, location }) => {
  const [id, setId] = useState('')
  const [invoiceCode, setInvoiceCode] = useState(null)

  useEffect(() => {
    localStorage.setItem('selectedPath', 'client/invoices')
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get('id')

    if (idParams) readHash(idParams)
  }

  async function readHash(hash) {
    const hashDecrypted = await decrypt(hash, '@OS-dig:NfeId')
    setId(hashDecrypted)
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <span id='required-field-label'>
            * Campos obrigatórios para emissão
          </span>
          <BreadCrumb
            data={[
              'Início',
              'Notas Fiscais',
              !invoiceCode ? 'Nova NF-e' : `NF-e ${invoiceCode}`,
            ]}
            path={[constants.ROUTES.HOME, constants.ROUTES.INVOICES, null]}
          />
          <NewNFe
            invoiceId={id}
            setInvoiceCode={setInvoiceCode}
            onSubmit={() => history.push(constants.ROUTES.INVOICES)}
            onCancel={() => history.push(constants.ROUTES.INVOICES)}
          />
        </Row>
      </Grid>
    </div>
  )
}

export default withRouter(ViewNewNFe)
