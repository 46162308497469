import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import AlertModal from '../../../../components/AlertModal';
import Button from '../../../components/CustomButton/CustomButton';
import CurrencyInput from 'react-currency-input';
import { encrypt } from '../../../components/ToNormalize/ToNormalize';
import cashierBanksHistoricRepository from '../../../../repositories/CashierBanksHistoric';

import './styles.css';
import ReactTable from 'react-table';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormModalFinancialMovement from 'client/components/FormModals/FinancialMovement';

const CloseCashierBankWithConferenceHistoricModal = ({
  cashierBankHistoricId,
  onCancel,
  onSubmit,
  handleSubmitFormFinancialMovementModal,
  ...rest
}) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isConfirmCloseCashierModalOpen, setIsConfirmCloseCashierModalOpen] =
    useState(false);
  const [
    isFormFinancialMovementModalOpen,
    setIsFormFinancialMovementModalOpen,
  ] = useState(false);

  const cashierBankValue = [];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { control, handleSubmit, getValues } = useForm();

  const { append: closeCashierBankAppend, fields: closeCashierBankField } =
    useFieldArray({
      control,
      name: 'closeCashierBank',
    });

  const [cashierBankHistoric, setCashierBankHistoric] = useState({
    id: null,
    CashierBank: {
      CashierBankType: {
        initials: '',
      },
    },
    description: '',
    initialValue: 0,
    receivedValue: 0,
    exchangeValue: 0,
    paidValue: 0,
    finalValue: 0,
  });

  async function loadCashierBankHistoric() {
    try {
      const cashierBankHistoric = await cashierBanksHistoricRepository.show(
        cashierBankHistoricId
      );
      setCashierBankHistoric(cashierBankHistoric);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar o histórico de caixa. Por favor, tente novamente'
      );
    }
  }

  const loadCashierBankTable = async () => {
    setLoading(true);
    try {
      const response =
        await cashierBanksHistoricRepository.getCloseCashierBankInfo(
          cashierBankHistoricId
        );

      const serializedItens = response.receivedByFormOfPayment.map((item) => ({
        id: item.formOfPaymentId,
        description: item.description,
        receivedValue: item.amount,
        cashierValue: 0,
        observations: '',
      }));

      closeCashierBankAppend(serializedItens);
      setData(serializedItens);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível carregar as formas de pagamento',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCloseCashier(data) {
    setIsConfirmCloseCashierModalOpen(false);
    setLoading(true);
    try {
      await cashierBanksHistoricRepository.close(cashierBankHistoricId, {
        initialValue: cashierBankHistoric.initialValue,
        receivedValue: cashierBankHistoric.receivedValue,
        paidValue: cashierBankHistoric.paidValue,
        finalValue: cashierBankHistoric.finalValue,
        exchangeValue: cashierBankHistoric.exchangeValue,
        closedDate: new Date().getTime(),
      });

      const formatedValues = data.closeCashierBank.map((item) => ({
        formOfPaymentId: item.id,
        observation: item.observations,
        cashValue: parseFloat(
          `${item.cashierValue}`
            ?.replace('R$ ', '')
            ?.replace('.', '')
            ?.replace(',', '.')
        ),
      }));

      await cashierBanksHistoricRepository.closeCashierConferece({
        cashierBankHistoricId,
        payments: formatedValues,
      });

      onSubmit();
    } catch (err) {
      console.log(err);
      if (err.response.data.message === 'payments cannot be empty') {
        return onSubmit();
      }

      toastr.warning(
        'Ocorreu um erro ao fechar o caixa. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleOpenCloseConfirmationModal() {
    const values = getValues();

    const conferenceValues = values.closeCashierBank.map((item) =>
      parseFloat(
        `${item.cashierValue}`
          ?.replace('R$ ', '')
          ?.replace('.', '')
          ?.replace(',', '.')
      )
    );

    const conferenceValuesSum =
      conferenceValues.length === 0
        ? null
        : conferenceValues.reduce((prev, curr) => prev + curr, 0);

    if (conferenceValuesSum === 0) {
      return toastr.warning(
        'OS Digital',
        'Preencha os valores em caixa para prosseguir'
      );
    }

    setIsConfirmCloseCashierModalOpen(true);
  }

  function handleOpenCloseCashierReport(cashierBankHistoricId) {
    const cashierBankHistoricIdHash = encrypt(
      cashierBankHistoricId,
      '@OS-dig:cashierBankHistoricIdToReport'
    );
    window.open(
      window.location.origin +
        `/report/close-cashier?cashierBankHistoricId=${cashierBankHistoricIdHash}`,
      '_blank'
    );
  }

  useEffect(() => {
    if (cashierBankHistoricId) {
      loadCashierBankHistoric();
      loadCashierBankTable();
    }
  }, [cashierBankHistoricId]);

  return (
    <>
      <Modal
        show
        animation
        dialogClassName="close-cashier-bank-with-conference-historic-modal"
        {...rest}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Fechamento de Caixa</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="balance-close-cashier-bank">
            <fieldset>
              <span htmlFor="initialValue">Saldo Inicial (=):</span>
              <CurrencyInput
                id="initialValue"
                className="form-control foco-input"
                disabled
                value={cashierBankHistoric.initialValue}
              />
            </fieldset>
            <fieldset>
              <span htmlFor="receivedValue">Recebido (+):</span>
              <CurrencyInput
                id="receivedValue"
                className="form-control foco-input"
                disabled
                value={cashierBankHistoric.receivedValue}
              />
            </fieldset>
            <fieldset>
              <span htmlFor="exchangeValue">Troco (-):</span>
              <CurrencyInput
                id="exchangeValue"
                className="form-control foco-input"
                disabled
                value={cashierBankHistoric.exchangeValue}
              />
            </fieldset>
            <fieldset>
              <span htmlFor="paidValue">Pago (-):</span>
              <CurrencyInput
                id="paidValue"
                className="form-control foco-input"
                disabled
                value={cashierBankHistoric.paidValue}
              />
            </fieldset>
            <footer>
              <fieldset>
                <span htmlFor="finalValue">
                  <strong>Saldo Final (=):</strong>
                </span>
                <CurrencyInput
                  id="finalValue"
                  className="form-control foco-input final-value-input"
                  disabled
                  value={cashierBankHistoric.finalValue}
                />
              </fieldset>
            </footer>
          </div>
          <div className="close-cashier-bank-table">
            <ReactTable
              data={data}
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '14px',
              }}
              columns={[
                {
                  Header: 'Forma de Pagamento',
                  accessor: 'description',
                  width: 175,
                },
                {
                  Header: 'Valor Recebido',
                  accessor: 'receivedValue',
                  Cell: (props) => currency(props.value),
                  width: 125,
                },
                {
                  Header: 'Valor em Caixa',
                  accessor: 'cashierValue',
                  width: 125,
                  Cell: (props) => {
                    return (
                      <>
                        <Controller
                          name={`closeCashierBank.${props.index}.cashierValue`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <CurrencyInput
                                style={
                                  !parseFloat(
                                    cashierBankValue[props.index]
                                      ?.replace('R$ ', '')
                                      ?.replace('.', '')
                                      ?.replace(',', '.')
                                  )
                                    ? {
                                        color: 'black',
                                      }
                                    : parseFloat(
                                        `${cashierBankValue[props.index]}`
                                          ?.replace('R$ ', '')
                                          ?.replace('.', '')
                                          ?.replace(',', '.')
                                      ) < data[props.index].receivedValue
                                    ? {
                                        color: 'red',
                                      }
                                    : {
                                        color: 'green',
                                      }
                                }
                                id={closeCashierBankField[props.index].id}
                                prefix="R$ "
                                thousandSeparator="."
                                decimalSeparator=","
                                className="form-control foco-input"
                                onChangeEvent={(e) =>
                                  (cashierBankValue[props.index] =
                                    e.target.value)
                                }
                                {...field}
                              />
                            );
                          }}
                        />
                      </>
                    );
                  },
                },
                {
                  Header: 'Observações',
                  accessor: 'observations',
                  resizable: false,
                  Cell: (props) => (
                    <Controller
                      name={`closeCashierBank.${props.index}.observations`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <input
                            style={{
                              width: '100%',
                            }}
                            type="text"
                            className="form-control foco-input"
                            {...field}
                          />
                        );
                      }}
                    />
                  ),
                },
              ]}
              loadingText="Carregando..."
              showPagination={false}
              sortable={false}
              loading={loading}
              noDataText={false}
              minRows={3}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            disabled={loading}
            fill
            onClick={() => handleOpenCloseCashierReport(cashierBankHistoricId)}
          >
            Imprimir Prévia de Fechamento
          </Button>
          <Button
            bsStyle="primary"
            disabled={loading}
            fill
            onClick={() => setIsFormFinancialMovementModalOpen(true)}
          >
            Realizar Movimentação
          </Button>
          <Button
            bsStyle="danger"
            disabled={loading}
            fill
            onClick={() => setIsConfirmCancelModalOpen(true)}
          >
            Cancelar
          </Button>
          <Button
            bsStyle="info"
            disabled={loading}
            fill
            onClick={() => handleOpenCloseConfirmationModal()}
          >
            Fechar Caixa
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="Cancelamento de fechamento de Caixa"
        subtitle="Você tem certeza que deseja cancelar o fechamento e a conferência de caixa?"
        message="Todos os valores e observações não serão salvos, sendo necessário realizar novamente. Se houver algum recebimento, os valores recebidos serão alterados."
        show={isConfirmCancelModalOpen}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <AlertModal
        show={isConfirmCloseCashierModalOpen}
        title="Fechamento de Caixa"
        subtitle="Deseja realizar o fechamento de caixa?"
        message="Ao fechar o caixa, você está confirmando que os valores lançados e registrados estão correspondendo com os valores recebidos durante o período de abertura do caixa. O processo é irreversível."
        onCancel={() => setIsConfirmCloseCashierModalOpen(false)}
        onHide={() => setIsConfirmCloseCashierModalOpen(false)}
        onSubmit={handleSubmit(handleCloseCashier)}
      />

      {isFormFinancialMovementModalOpen && (
        <FormModalFinancialMovement
          onCancel={() => setIsFormFinancialMovementModalOpen(false)}
          onSubmit={(financialMovement) => {
            handleSubmitFormFinancialMovementModal(financialMovement);
            setIsFormFinancialMovementModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CloseCashierBankWithConferenceHistoricModal;
