import React from "react";
import { Grid, Row } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import BundleFormBillToPay from './BundleFormBillToPay'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const BillToPay = ({ match, history }) => {
  localStorage.setItem('selectedPath', 'client/bills-to-pay')

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Contas a Pagar', !!match.params.id ? 'Editar Título' : 'Novo Título']}
            path={['home', 'bills-to-pay', null]}
          />
          <BundleFormBillToPay
           titleId={match.params.id}  
           defaultValues={history.location.state}
           />
        </Row>
      </Grid>
    </div>
  );
}

export default BillToPay