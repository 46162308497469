import config from '../config'
import api from '../services/api'

const index = async (queryParams) => {
  const response = await api.get(config.endpoint + 'prisma-box', {
    params: queryParams,
  })
  return response
}

const show = async (id) => {
  try{
    const response = await api.get(config.endpoint + `prisma-box/${id}`)
    return response
  }catch(err){
    throw err
  }
}

const store = async (data) => {
  try {
    const response = await api.post(config.endpoint + 'prisma-box', data)
    return response
  } catch (err) {
    throw err
  }
}

const update = async (id, data) => {
  try {
    const response = await api.put(
      config.endpoint + `prisma-box/${id}`,
      data
    )
    return response
  } catch (err) {
    throw err
  }
}

export default {
  index,
  show,
  store,
  update,
}
