import React from 'react'
import ReactInputMask from 'react-input-mask'
import CurrencyInput from 'react-currency-input'
import { toastr } from 'react-redux-toastr'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { validateCnpj } from 'client/components/Validations/Validations'

const cpfCnpjLabel = {
  Fisica: 'CPF',
  Juridica: 'CNPJ',
}

const cpfCnpjMask = {
  Fisica: '999.999.999-99',
  Juridica: '99.999.999/9999-99',
}

export default function NewTradeRepresentativeLegalPersonDate({
  handleNextSection,
}) {
  const { register, setValue, control } = useFormContext()

  const type = useWatch({
    control,
    name: 'type',
  })

  const cpfCnpj = useWatch({
    control,
    name: 'cpfCnpj',
  })

  const productComission = useWatch({
    control,
    name: 'productComission',
  })

  const serviceComission = useWatch({
    control,
    name: 'serviceComission',
  })

  const handleValidateCnpj = () => {
    const valueWithoutMask = cpfCnpj.replace(/[^\d]/g, '')
    if (valueWithoutMask && !validateCnpj(valueWithoutMask)) {
      toastr.warning('CNPJ inválido', 'Por favor, informe um CNPJ válido')
      document.getElementById('cpfCnpj').focus()
    }
  }

  return (
    <>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-2 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div>
          <label htmlFor="cpfCnpj">{cpfCnpjLabel[type]}:</label>
          <Controller
            defaultValue={''}
            name={'cpfCnpj'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={cpfCnpjMask[type]}
                  formatChars={{
                    9: '[0-9]',
                  }}
                  onBlur={handleValidateCnpj}
                >
                  {() => (
                    <input
                      id={'cpfCnpj'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>

        <div>
          <label htmlFor="ie">Inscrição Estadual:</label>
          <input
            id={'ie'}
            type="text"
            className="form-control foco-input"
            {...register('ie')}
          />
        </div>

        <div>
          <label htmlFor="companyName">
            Razão Social: <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id={'companyName'}
            type="text"
            className="form-control foco-input"
            {...register('companyName')}
          />
        </div>

        <div>
          <label htmlFor="tradingName">
            Nome Fantasia: <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id={'tradingName'}
            type="text"
            className="form-control foco-input"
            {...register('tradingName')}
          />
        </div>
      </div>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-3 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div>
          <label htmlFor="email">Email:</label>
          <input
            id={'email'}
            type="email"
            className="form-control foco-input"
            {...register('email')}
          />
        </div>
        <div>
          <label htmlFor="commercialPhone">Telefone Comercial:</label>
          <Controller
            defaultValue={''}
            name={'commercialPhone'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={'(99) 9999-9999'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'commercialPhone'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>
        <div>
          <label htmlFor="cellPhone">
            Telefone Celular: <span style={{ color: 'red' }}>*</span>
          </label>
          <Controller
            defaultValue={''}
            name={'cellPhone'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={'(99) 9 9999-9999'}
                  suf
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'cellPhone'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>
        <div>
          <label htmlFor="contact">Responsável:</label>{' '}
          <span style={{ color: 'red' }}>*</span>
          <input
            id={'contact'}
            type="text"
            className="form-control foco-input"
            {...register('contact')}
          />
        </div>
        <div>
          <label htmlFor="statusIE">Indicador de IE:</label>
          <select
            id="statusIE"
            {...register('statusIE')}
            className="form-control foco-input"
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value="Contribuinte de ICMS">Contribuinte de ICMS</option>
            <option value="Não Contribuinte">Não Contribuinte</option>
          </select>
        </div>
        <div>
          <label htmlFor="createdAt">Data de Cadastro:</label>
          <input
            type="date"
            className="form-control foco-input"
            {...register('createdAt')}
            disabled
          />
        </div>
      </div>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-4 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="productComission">% Comissão Produto:</label>
          <CurrencyInput
            className="form-control foco-input"
            suffix="%"
            decimalSeparator=","
            thousandSeparator="."
            maxLength={6}
            value={productComission}
            {...register('productComission')}
            onChangeEvent={(e) => {
              setValue(
                'productComission',
                Number(`${e.target.value}`.replace('%', '').replace(',', '.'))
              )
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="serviceComission">% Comissão Serviço:</label>
          <CurrencyInput
            className="form-control foco-input"
            suffix="%"
            decimalSeparator=","
            thousandSeparator="."
            maxLength={6}
            value={serviceComission}
            {...register('serviceComission')}
            onChangeEvent={(e) => {
              setValue(
                'serviceComission',
                Number(`${e.target.value}`.replace('%', '').replace(',', '.'))
              )
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="serviceComission">Observações:</label>
          <textarea
            className="form-control foco-input"
            maxLength={500}
            {...register('observations')}
          ></textarea>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <button
            className="btn-green"
            type="button"
            onClick={handleNextSection}
          >
            Prosseguir
          </button>
        </div>
      </div>
    </>
  )
}
