import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import SalesPerFamilyServiceMain from './components/SalesPerFamilyServiceMain'

import './index.css'

export default function SalesPerFamilyService({ location }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Vendas por Família(Serviço)',
            ]}
            path={['home', null, null, null]}
          />
          <SalesPerFamilyServiceMain />
        </Row>
      </Grid>
    </div>
  )
}
