import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 15px;
`;

export const ParcelsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.75fr 2fr 2fr 3fr 2fr 2fr;
  gap: 20px;
`;

export const ButttonLink = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.branding1000};
  font-size: 12px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Header = styled.div``;

export const HeaderItem = styled.div`
  font-weight: 700;
`;

export const Body = styled.div`
  padding-top: 20px;
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
`;
