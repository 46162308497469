import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import MainData from './MainData';
import Address from '../../Clients/NewClient/FormClient/Endereco';
import CardForm from '../../../components/CardForm';
import AlertModal from '../../../../components/AlertModal';
import FormSubmitButtons from '../../../components/FormSubmitButtons';
import constants from '../../../../utils/constants';

const MeusDados = ({ handleSubmit, loadCompany, history, loading }) => {
  const [companyData, setCompanyData] = useState(true);
  const [companyAddressData, setCompanyAddressData] = useState(false);
  const [
    isConfirmChangePersonTypeModalOpen,
    setIsConfirmChangePersonTypeModalOpen,
  ] = useState(false);

  const { id, typeOriginal, Type, preventSearchCep } = useSelector(
    (state) => state.form.meusDados.values
  );

  function handleCheckPersonType() {
    if (typeOriginal === 'Física' && Type === 'Jurídica' && id) {
      return setIsConfirmChangePersonTypeModalOpen(true);
    }
    handleSubmit();
  }

  return (
    <>
      <form
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardForm
          show={companyData}
          title="Dados Principais"
          onClick={() => setCompanyData(!companyData)}
          proceedButton
          onProceed={() => {
            setCompanyData(false);
            setCompanyAddressData(true);
          }}
        >
          <MainData loadCompany={loadCompany} />
        </CardForm>

        <CardForm
          show={companyAddressData}
          title="Dados de Endereço"
          onClick={() => setCompanyAddressData(!companyAddressData)}
        >
          <Address
            form="meusDados"
            required
            edit={id}
            preventSearchCep={preventSearchCep}
          />
        </CardForm>

        <FormSubmitButtons
          hasId={id}
          loading={loading}
          onCancel={() => history.push(constants.ROUTES.COMPANIES)}
          onSubmit={handleCheckPersonType}
        />
      </form>

      <AlertModal
        show={isConfirmChangePersonTypeModalOpen}
        message="Ao cadastrar a empresa com CNPJ, não poderá modificar para CPF futuramente. Deseja Continuar?"
        onHide={() => setIsConfirmChangePersonTypeModalOpen(false)}
        onCancel={() => setIsConfirmChangePersonTypeModalOpen(false)}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};

export default reduxForm({
  form: 'meusDados',
  forceUnregisterOnUnmount: true,
})(withRouter(MeusDados));
