import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import AppError from 'components/AppError';

import { AppLoading } from 'client/routes/index.routes';

import BillsReceivedsPDF from './BillsReceivedsPDF';

import BillsToReceiveRepository from '../../../repositories/BillsToReceive';

const ReturnDocument = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [dataReport, setDataReport] = useState([]);

  const [filterOpenPayment, setFilterOpenPayment] = useState(false);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [dateType, setDateType] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const BillsReceivedsReportIdHash = searchParams.get('companyId');
      let companyId;

      if (BillsReceivedsReportIdHash) {
        companyId = await decrypt(
          BillsReceivedsReportIdHash,
          '@OS-dig:companyId'
        );
      }

      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const filterOpenPayment = searchParams.get('filterOpenPayment');
      const dateType = searchParams.get('dateType');

      setInitialDate(initialDate);
      setFinalDate(finalDate);
      setDateType(dateType);

      if (filterOpenPayment === 'true') {
        setFilterOpenPayment(true);
      } else {
        setFilterOpenPayment(false);
      }

      const dados = await loadReport(
        companyId,
        initialDate,
        finalDate,
        filterOpenPayment,
        dateType
      );

      setDataReport(dados);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    filterOpenPayment,
    dateType
  ) => {
    try {
      const data = await BillsToReceiveRepository.generateBillsReceiveds({
        companyId,
        initialDate,
        finalDate,
        filterOpenPayment,
        dateType,
      });

      return data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <BillsReceivedsPDF
            responseData={dataReport}
            filterOpenPayment={filterOpenPayment}
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={dateType === 'payment' ? 'Pagamento' : 'Criação'}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default ReturnDocument;
