import { theme } from 'highcharts';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 55px;

  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.branding1000};
  margin: 0;
`;
