import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import Matera from './Matera'

import './styles.css'

const DigitalAccountHomePage = () => {
  return (
    <main className='integrations'>
      <BreadCrumb data={['Início', 'Conta Digital']} path={['home', null]} />
      <div style={{ marginTop: '40px' }}>
        <Matera />
      </div>
    </main>
  )
}

export default DigitalAccountHomePage
