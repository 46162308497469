import styled from 'styled-components';

export const OptionSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #cb4154;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  border: 1px solid #aaa;
  border-radius: 8px;

  height: 40px;
  padding: 0 4rem;
`;

// TODO: slide animation

export const Option = styled.button`
  all: unset;
  font-size: 16px;
  border-radius: 8px;

  width: 90px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ selected, theme }) =>
    selected ? theme.colors.branding1000 : `transparent`};
  color: ${({ selected }) => (selected ? `#fff` : `#7a7a7a`)};
  font-weight: ${({ selected }) => (selected ? `bold` : `regular`)};

  &:hover {
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
`;
