import { currency } from 'client/components/ToNormalize/ToNormalize'
import React from 'react'

import '../index.css'
import { useBranding } from 'hooks/useBranding'

export const IndicatorsList = ({ indicators }) => {
  const { isWorkmotor } = useBranding()

  return (
    <>
      <div
        className="external-indicators__list"
        style={{
          width: '95%',
          margin: '0 auto',
        }}
      >
        <div className="external-indicators__card">
          <header>
            <span>A receber hoje</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {currency(indicators.pedingAmountToReceiveToday)}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Recebimentos em atraso</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--negative">
            {currency(indicators.pedingAmountToReceiveLate)}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>A pagar hoje</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {currency(indicators.pedingAmountToPayToday)}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Pagamentos em atraso</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--negative">
            {currency(indicators.pedingAmountToPayLate)}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Novos Orçamentos</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {indicators.countBudgetNewsToday > 0 ? '+' : ''}
            {indicators.countBudgetNewsToday}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Novas Vendas Hoje</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {indicators.countSaleNewsToday > 0 ? '+' : ''}
            {indicators.countSaleNewsToday}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Novas Vendas(PDV) Hoje</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {indicators.countPDVNewsToday > 0 ? '+' : ''}
            {indicators.countPDVNewsToday}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Novas Ordens de Serviço</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--positive">
            {indicators.countOSNewsToday > 0 ? '+' : ''}
            {indicators.countOSNewsToday}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Agendamentos para Hoje</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--neutral">
            {indicators.schedulingForToday}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Ticket Médio - Venda</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--neutral">
            {currency(indicators.averageTicketSale)}
          </span>
        </div>

        <div className="external-indicators__card">
          <header>
            <span>Ticket Médio - Ordem de Serviço</span>
          </header>

          <span className="external-indicators__card__value external-indicators__card__value--neutral">
            {currency(indicators.averageServiceOrderTicket)}
          </span>
        </div>
      </div>
    </>
  )
}
