import styled from 'styled-components'

export const BankConciliationDetailsSearchContainer = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */

  .box-input {
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .search {
    display: flex;
    justify-content: end;
    align-items: start;

    input {
      min-width: 350px;
    }
  }

`

export const BankConciliationDetailsSearchContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: calc(100% - 32px);
  justify-content: space-between;
  margin-top: 1.5rem;

  .box-input {
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .search {
    display: flex;
    justify-content: end;
    align-items: start;
  }
`
export const SearchFinancialTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;

  .box-input {
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .search {
    display: flex;
    justify-content: end;
    align-items: start;
  }

  /* label{
    margin: 0px;
    background-color: red;
  } */
`
