import React from 'react';
import { InputCurrencyStyled, InputCurrencyWrapper } from './styles';

export const InputCurrency = React.forwardRef(
  ({ width, height = 40, borderColor, borderColorFocus, ...props }, ref) => {
    return (
      <InputCurrencyWrapper width={width} height={height}>
        <InputCurrencyStyled
          type={'text'}
          borderColor={borderColor}
          borderColorFocus={borderColorFocus}
          {...props}
          ref={ref}
          thousandSeparator="."
          decimalSeparator=","
          prefix="R$ "
          onChangeEvent={(e, maskedValue, floatValue) => {
            props.onChange(floatValue);
          }}
        />
      </InputCurrencyWrapper>
    );
  }
);

InputCurrency.displayName = 'InputCurrency';
