import api from '../services/api';
import config from '../config';

const show = async (data) => {
  try {
    const response = await api.post(
      config.endpoint + 'vehicle/megalaudo/find-license-plate',
      { plate: data }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  show,
};
