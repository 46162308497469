import axios from 'axios';

export async function getUserIpv4() {
  const ipifyPromise = axios
    .get('https://api.ipify.org?format=json')
    .then((res) => res.data.ip)
    .catch((err) => {
      console.log('ipify failed:', err);
      return null;
    });

  const ipinfoPromise = axios
    .get('https://ipinfo.io/json')
    .then((res) => res.data.ip)
    .catch((err) => {
      console.log('ipinfo failed:', err);
      return null;
    });

  try {
    const ipv4 = await Promise.race([
      ipifyPromise.then((ip) => (ip !== null ? ip : Promise.reject())),
      ipinfoPromise.then((ip) => (ip !== null ? ip : Promise.reject())),
    ]);
    return ipv4;
  } catch (err) {
    console.log('Both services failed to retrieve IPv4:', err);
    throw new Error('Failed to retrieve IPv4 address from all sources');
  }
}
