import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { reduxForm, change, Field } from 'redux-form'
import RenderField from 'components/RenderField'
import ProviderRepository from '../../../../../repositories/Providers'
import {
  cnpjMask,
  onlyNumbers,
} from '../../../../components/ToNormalize/ToNormalize'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'contexts/auth'

function Provider({ disabled }) {
  const [providers, setProviders] = useState([])
  const [providersNamesSuggestions, setProvidersNamesSuggestions] = useState([])

  const { companyId } = useAuth()

  const { searchProvider } = useSelector(
    (state) => state.form.cadastroStock.values
  )
  const dispatch = useDispatch()

  useEffect(() => {
    getProviders()
  }, [])

  useEffect(() => {
    let providerCpfCnpj = searchProvider.split(' - ')
    if (!!providerCpfCnpj) {
      providerCpfCnpj = onlyNumbers(
        providerCpfCnpj[providerCpfCnpj.length - 1].trim()
      )
      if (!!providerCpfCnpj) {
        setProviderFromCpfCnpj(providerCpfCnpj)
      }
    }
  }, [searchProvider])

  const getProviders = async () => {
    const providers = await ProviderRepository.getAllActiveByCompany(companyId)
    const providersNamesSuggestions = providers.map(
      (provider) => `${provider.companyName} - ${cnpjMask(provider.cpfCnpj)}`
    )
    setProviders(providers)
    setProvidersNamesSuggestions(providersNamesSuggestions)
  }

  const setProviderFromCpfCnpj = async (cnpj) => {
    const provider = providers.find((provider) => provider.cpfCnpj === cnpj)
    dispatch([
      change('cadastroStock', 'providerId', provider.id),
      change('cadastroStock', 'name', provider.companyName),
      change('cadastroStock', 'cnpj', cnpjMask(provider.cpfCnpj)),
      change('cadastroStock', 'state', provider.Address[0]?.State),
      change('cadastroStock', 'city', provider.Address[0]?.City),
    ])
  }

  return (
    <div>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={5} md={5} sm={5} xs={5}>
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <Field
            name="searchProvider"
            component={RenderField}
            style={{ paddingLeft: '7%' }}
            placeholder="Pesquisa por Razão Social ou CNPJ"
            required
            list="providerList"
            disabled={disabled}
          />
          <datalist id="providerList">
            {providersNamesSuggestions.map((provider) => (
              <option key={provider} value={provider}></option>
            ))}
          </datalist>
        </Col>
      </Col>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={2} md={2} sm={2} xs={2}>
          <Field
            name="cnpj"
            label="CNPJ:"
            component={RenderField}
            disabled={true}
          />
        </Col>
        <Col lg={5} md={5} sm={5} xs={5}>
          <Field
            name="name"
            label="Razão Social:"
            component={RenderField}
            disabled={true}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <Field
            name="city"
            label="Cidade:"
            component={RenderField}
            disabled={true}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <Field
            name="state"
            label="UF:"
            component={RenderField}
            disabled={true}
          />
        </Col>
      </Col>
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroStock',
})(withRouter(Provider))
