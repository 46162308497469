import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import FormWarranty from './NewWarranty'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const NewWarranty = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb 
              data={['Início', 'Cadastros', 'Produtos/Serviços', 'Garantias', !!match.params.id ? 'Editar Garantia' : 'Nova Garantia']}
              path={['home', null, null, 'warranties', null]} />

            <FormWarranty warrantyId={match.params.id} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default NewWarranty