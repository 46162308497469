import { currency } from 'client/components/ToNormalize/ToNormalize';
import React, { memo } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const TableByStatus = ({ data = [], loading, period }) => {
  return (
    <>
      <h4>Pagamentos por Status (R$)</h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: 'Descrição',
            accessor: 'description',
          },
          {
            Header: 'Valor',
            accessor: 'amount',
            Cell: (props) => currency(props.value),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={false}
        sortable
        noDataText={
          !period
            ? 'Selecione o período para carregar os dados'
            : 'Nenhuma informação encontrada'
        }
      />
    </>
  );
};

export default memo(TableByStatus);
