import React from 'react'
import CollapsibleSection from 'components/CollapsibleSection'
import Toggle from 'react-toggle'
import '../styles.css'

const KoinCard = ({ register, isActive, setValue }) => {
  return (
    <>
      <CollapsibleSection
        title="Dados do Cliente"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div className="new-financing-bank-slip__row">
          <div
            style={{
              width: 250,
            }}
          >
            <label htmlFor="cpfCnpj">CNPJ:</label>
            <input
              type="text"
              id="cpfCnpj"
              className="form-control foco-input"
              {...register('cpfCnpj')}
              disabled
            />
          </div>
          <div
            style={{
              width: 300,
            }}
          >
            <label htmlFor="companyName">Razão Social:</label>
            <input
              type="text"
              id="companyName"
              className="form-control foco-input"
              {...register('companyName')}
              disabled
            />
          </div>
          <div
            style={{
              width: 300,
            }}
          >
            <label htmlFor="tradingName">Nome Fantasia:</label>
            <input
              type="text"
              id="tradingName"
              className="form-control foco-input"
              {...register('tradingName')}
              disabled
            />
          </div>
          <div
            style={{
              width: 50,
            }}
            className="toggle-wrapper"
          >
            <label htmlFor="isActive">Status:</label>
            <center>
              <Toggle
                id="isActive"
                checked={isActive}
                onChange={() => setValue('isActive', !isActive)}
              />
            </center>
          </div>
        </div>

        <div className="new-financing-bank-slip__row">
          <div
            style={{
              width: '50%',
            }}
          >
            <label htmlFor="sellerName">
              consumerKey:<span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="sellerName"
              className="form-control foco-input"
              {...register('consumerKey')}
            />
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            <label htmlFor="sellerName">secretKey:<span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              id="sellerName"
              className="form-control foco-input"
              {...register('secretKey')}
            />
          </div>
        </div>
      </CollapsibleSection>
    </>
  )
}

export default KoinCard
