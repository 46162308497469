import React from 'react'
import CustomButton from 'client/components/CustomButton/CustomButton'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import StopReasonModal from './StopReasonModal'
import ShowStopReasonModal from './ShowStopReasonModal'
import FinishServiceModal from './FinishServiceModal'
import StartServiceModal from './StartServiceModal'

import '../index.css'

const PanelOperationModal = ({ show, onCancel, maintenance }) => {
  const [isStopReasonModalOpen, setIsStopReasonModalOpen] = useState(false)
  const [isFinishServiceModalOpen, setIsFinishServiceModalOpen] =
    useState(false)
  const [isShowStopReasonModalOpen, setIsShowStopReasonModalOpen] =
    useState(false)
  const [isOpenStartServiceModalOpen, setIsOpenStartServiceModalOpen] =
    useState(false)

  const startServiceData = {
    id: maintenance.id,
    Sales: {
      id: maintenance.Sales.id,
      code: maintenance.Sales.Code,
      Customer: {
        name:
          maintenance.Sales.Customer?.Trading_Name ||
          maintenance.Sales.Customer?.Company_Name,
        phone: maintenance.Sales.Customer?.Phones[0]?.Number_Phone2,
      },
    },
    Vehicle: {
      License_Plate:
        maintenance.Sales.Vehicle.License_Plate ||
        maintenance.Sales.Vehicle.Maintenance_Object,
      Model: maintenance.Sales.Vehicle.Model,
    },
  }

  return (
    <>
      <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Painel da oficina</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>Selecione a opção desejada: </p>
          <div className="panel-operation-buttons">
            <CustomButton
              fill
              style={{
                background: '#5CB85C',
                borderColor: '#5CB85C',
                fontSize: '16px',
              }}
              disabled={
                maintenance.status === 'Em Andamento' ||
                maintenance.status === 'Finalizado'
              }
              onClick={() => setIsOpenStartServiceModalOpen(true)}
            >
              Iniciar Serviço
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#d9534f',
                borderColor: '#d9534f',
                fontSize: '16px',
              }}
              disabled={maintenance.status !== 'Em Andamento'}
              onClick={() => setIsStopReasonModalOpen(true)}
            >
              Parar Serviço
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#f0ad4e',
                borderColor: '#f0ad4e',
                fontSize: '16px',
              }}
              disabled={maintenance.status !== 'Parado'}
              onClick={() => setIsShowStopReasonModalOpen(true)}
            >
              Visualizar Motivo de Parada
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#428bca',
                borderColor: '#428bca',
                fontSize: '16px',
              }}
              disabled={maintenance.status === 'Finalizado'}
              onClick={() => setIsFinishServiceModalOpen(true)}
            >
              Finalizar Serviço
            </CustomButton>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="Voltar"
              onClick={onCancel}
              fill
            >
              Voltar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>

      {isStopReasonModalOpen && (
        <StopReasonModal
          onCancel={() => setIsStopReasonModalOpen(false)}
          maintenance={maintenance}
        />
      )}

      {isShowStopReasonModalOpen && (
        <ShowStopReasonModal
          onCancel={() => setIsShowStopReasonModalOpen(false)}
          maintenance={maintenance}
        />
      )}

      {isOpenStartServiceModalOpen && (
        <StartServiceModal
          onCancel={() => setIsOpenStartServiceModalOpen(false)}
          maintenance={startServiceData}
        />
      )}

      {isFinishServiceModalOpen && (
        <FinishServiceModal
          onCancel={() => setIsFinishServiceModalOpen(false)}
          maintenance={maintenance}
        />
      )}
    </>
  )
}

export default PanelOperationModal
