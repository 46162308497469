import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
export function GroupedInvoiceEmissionHeaderCount({ selectedSaleInvoices }) {
  function calculateTotalValue(salesArray) {
    return salesArray.reduce((total, item) => {
      return total + (item.Final_Value || 0);
    }, 0);
  }

  return (
    <div className="w-50 flex row h-35 gap-1 fontSize-11">
      <div className="flex start gap-025 align-center">
        <strong>Qtd. Selecionadas:</strong>
        <input
          type="text"
          disabled
          value={selectedSaleInvoices.length}
          style={{
            width: '90px',
            borderRadius: '5px',
            border: 'none',
            textAlign: 'center',
            padding: '0.25rem',
          }}
        />
      </div>
      <div className="flex start gap-025 align-center">
        <strong>R$ Total:</strong>
        <input
          type="text"
          disabled
          value={currency(calculateTotalValue(selectedSaleInvoices))}
          style={{
            width: '90px',
            borderRadius: '5px',
            border: 'none',
            textAlign: 'center',
            padding: '0.25rem',
          }}
        />
      </div>
    </div>
  );
}
