import React from 'react';

import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import Main from './components/Main';

export function PromotionalPriceTable({ match, history }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastros',
              'Vendas',
              'Tabela de Preços Promocionais',
            ]}
            path={['home', null, 'sales', null]}
          />
          <Main history={history} />
        </Row>
      </Grid>
    </div>
  );
}
