import api from '../services/api'

const show = async (id) => {
  const pattern = await api.get(`/nfse-pattern/${id}`)
  return pattern
}

const index = async ({ offline, isHomologated, query, page, limit }) => {
  const patterns = await api.get(`/nfs-pattern`, {
    params: {
      offline,
      isHomologated,
      page,
      limit,
      query,
    },
  })

  return patterns.data
}

const create = async (data) => {
  const createdPattern = await api.post('/nfs-pattern', data)
  return createdPattern.data
}

const update = async (id, data) => {
  const createdPattern = await api.put(`/nfs-pattern/${id}`, data)
  return createdPattern.data
}

const addTribute = async (data) => {
  const createdTribute = await api.post('/nfse-pattern/operations-taxes', data)
  return createdTribute.data
}

const listOperationTaxes = async (patternId) => {
  const operationTaxes = await api.get(`/nfse-pattern/pattern`, {
    params: {
      NFSPatternId: patternId,
    },
  })
  return operationTaxes.data
}

const getPatternCityByPatternIdAndCity = async (patternId, city) => {
  const { data } = await api.get(`nfse-pattern/cities/pattern/${patternId}`, {
    params: { city },
  })
  return data
}

const deleteOperationTax = async (id) => {
  const deletedOperationTax = await api.delete(
    `/nfse-pattern/operations-taxes/${id}`
  )
  return deletedOperationTax.data
}

const getOperationTaxById = async (opId) => {
  const operationTax = await api.get(`/nfse-operation-taxes/${opId}`)
  return operationTax.data
}

const updateOperationTax = async (id, data) => {
  const updatedOperationTax = await api.put(`/nfse-operation-taxes/${id}`, data)
  return updatedOperationTax.data
}

const getCitiesPatterns = async (params) => {
  const { data } = await api.get('nfse-pattern/list/cities', { params })
  return data
}

const addNewCity = async (idPattern, params) => {
  const { data } = await api.post(
    `nfse-pattern/cities/new-city/${idPattern}`,
    params
  )
  return data
}

const copyTributes = async (params) => {
  const { data } = await api.post(`nfse-pattern/copy-tributes`, params)
  return data
}

const NFSePatternsRepository = {
  show,
  create,
  update,
  index,
  addTribute,
  listOperationTaxes,
  deleteOperationTax,
  getOperationTaxById,
  updateOperationTax,
  getCitiesPatterns,
  addNewCity,
  copyTributes,
  getPatternCityByPatternIdAndCity,
}

export default NFSePatternsRepository
