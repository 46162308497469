import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';

import CardForm from '../../../../components/CardForm';
import FormSubmitButtons from '../../../../components/FormSubmitButtons';
import AlertModal from '../../../../../components/AlertModal';
import constants from '../../../../../utils/constants';

import MainData from './MainData';
import CloseFinancialMovementModal from './CloseFinancialMovementModal';

import './styles.css';
import { Button } from 'v2/components/Button/DefaultButton';
import RemoveBankConciliationModal from './RemoveBankConciliationModal';
import { financialMovementsRepository } from 'repositories/FinancialMovements';

import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { useAuth } from 'contexts/auth';

const FinancialMovementForm = ({
  financialMovementId,
  handleSubmit,
  pristine,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [
    isRemoveBankConciliationModalOpen,
    setIsRemoveBankConciliationModalOpen,
  ] = useState(false);
  const [
    isCloseFinancialMovementModalOpen,
    setIsCloseFinancialMovementModalOpen,
  ] = useState(false);
  const { userId } = useAuth();
  const {
    financialMovementTypeId,
    description,
    financialMovementStatusId,
    destinationCashierBankId,
    finality,
    value,
    originCashierBankId,
    isCanceled,
    isClosed,
    cashierBankId,
    haveBankConciliation,
  } = useSelector((state) => state.form.FinancialMovement.values);

  const dispatch = useDispatch();
  const isTransfer =
    Number(financialMovementTypeId) ===
    constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER;

  useEffect(() => {
    if (financialMovementStatusId) {
      handleSubmit();
    }
  }, [financialMovementStatusId]);

  function onSubmit() {
    if (!isFormValid()) {
      return toastr.warning(
        'Preencha todos os campos e selecione as opções obrigatórias para salvar a movimentação.'
      );
    }
    setIsCloseFinancialMovementModalOpen(true);
  }

  function isFormValid() {
    if (isTransfer) {
      return validTransfer;
    } else {
      return validMovement;
    }
  }

  const handleRemoveBankConciliation = async () => {
    setIsLoading(true);
    try {
      await financialMovementsRepository.removeBankConciliation(
        financialMovementId,
        userId
      );
      dispatch(change('FinancialMovement', 'haveBankConciliation', false));
      toastr.success(
        'Conciliação Removida',
        'A conciliação foi removida com sucesso'
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        'Erro ao remover conciliação',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
      setIsRemoveBankConciliationModalOpen(false);
    }
  };

  const validTransfer =
    description && originCashierBankId && destinationCashierBankId && value;
  const validMovement = description && finality && cashierBankId && value;

  return (
    <form id="Financial-movement-form">
      <CardForm show title="Movimento de Saldo">
        <MainData />
      </CardForm>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '5px',
        }}
      >
        {haveBankConciliation && (
          <Button
            color="blue300"
            width="160px"
            height="40px"
            fontSize="14px"
            fontWeight="regular"
            onClick={() => setIsRemoveBankConciliationModalOpen(true)}
          >
            Remover Conciliação
          </Button>
        )}
        <FormSubmitButtons
          hasId={financialMovementId}
          onCancel={() =>
            pristine || !validTransfer || !!isClosed || !!isCanceled
              ? onCancel()
              : setIsConfirmCancelModalOpen(true)
          }
          onSubmit={() =>
            !!isClosed || !!isCanceled ? onCancel() : onSubmit()
          }
          loading={false}
        />
      </div>

      <AlertModal
        show={isConfirmCancelModalOpen}
        message="Deseja cancelar a movimentação? Nenhuma informação será salva e o cadastro poderá ser efetuado posteriormente"
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <RemoveBankConciliationModal
        show={isRemoveBankConciliationModalOpen}
        onHide={() => setIsRemoveBankConciliationModalOpen(false)}
        onSubmit={handleRemoveBankConciliation}
        isLoading={isLoading}
      />

      <CloseFinancialMovementModal
        show={isCloseFinancialMovementModalOpen}
        onHide={() => setIsCloseFinancialMovementModalOpen(false)}
        isTransfer={isTransfer}
      />
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'FinancialMovement',
})(FinancialMovementForm);
