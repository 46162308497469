import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton'
import constants from 'utils/constants'

const NewProductWithKitModal = ({ history, onCancel }) => {
  return (
    <Modal show onHide={onCancel} animation>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Selecione o tipo de cadastro: </p>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
        }}>
          <Button
            style={{
              backgroundColor: '#428bca',
              border: 'none',
              width: '200px',
            }}
            fill
            onClick={() => history.push('/client/product')}
          >
            Cadastro de Produto
          </Button>
          <Button
            style={{
              backgroundColor: '#5cb85c',
              border: 'none',
              width: '200px',
            }}
            fill
            onClick={() => history.push('/client/product-service-kit')}
          >
            Cadastro de Kit
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} bsStyle="danger" fill>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewProductWithKitModal
