import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useAdminAuth } from 'contexts/adminAuth';
import { withRouter } from 'react-router-dom';
import useFilters from 'hooks/useFilters';

import VideoTable from './VideoTable';
import Card from '../../../../components/Card/Card';

import VideosLinksRepository from '../../../../repositories/VideosLinks';
import VideoLinkFilter from './VideoLinkFilter';

function VideoLinkMain({ history }) {
  const { admin } = useAdminAuth();

  const [loading, setLoading] = useState(false);
  const [videoLink, setVideoLink] = useState([]);

  const { dateFilter, queryFilter, statusFilter } = useFilters();
  const { filterByDate, finalDate, initialDate, setFinalDate, setInitialDate } =
    dateFilter;
  const { status, setStatus, filterByStatus } = statusFilter;
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (!!admin) loadVideoLinks();
  }, []);

  const loadVideoLinks = async () => {
    setLoading(true);
    try {
      const data = await VideosLinksRepository.index();
      setVideoLink(data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as notícias. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (videoLink) => {
    if (!!validations()) {
      const querySearch = [videoLink['title'], videoLink['route']];

      return (
        filterByQuery(querySearch) &&
        filterByDate('createdAt', videoLink) &&
        filterByStatus('brandingId', videoLink)
      );
    }
    return;
  };

  const validations = () => {
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        return false;
      }
    }

    return true;
  };

  const filteredVideoLinks = videoLink?.filter(handleFilters);

  return (
    <Card
      content={
        <div>
          <VideoLinkFilter
            history={history}
            query={query}
            setQuery={setQuery}
            branding={status}
            setBranding={setStatus}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
          />

          <VideoTable
            loading={loading}
            videoLink={filteredVideoLinks}
            loadVideoLinks={loadVideoLinks}
          />
        </div>
      }
    />
  );
}

export default withRouter(VideoLinkMain);
