import constants from '../../../utils/constants'
import { format } from 'date-fns'
import {
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

export const xlsColumns = [
  {
    name: 'Título',
    acessor: 'code',
  },
  {
    name: 'Nota',
    acessor: 'invoice',
  },
  {
    name: 'Compra',
    acessor: 'entryNumber',
  },
  {
    name: 'Vencimento',
    acessor: 'dueDate',
  },
  {
    name: 'Fornecedor',
    acessor: 'providerName',
  },
  {
    name: 'Data de emissão',
    acessor: 'issuedAt',
  },
  {
    name: 'Valor Bruto',
    acessor: 'subTotal',
  },
  {
    name: 'Acréscimo',
    acessor: 'addedValue',
  },
  {
    name: 'Desconto',
    acessor: 'discountValue',
  },
  {
    name: 'Valor Líquido',
    acessor: 'liquidValue',
  },
  {
    name: 'Valor pago',
    acessor: 'paidValue',
  },
  {
    name: 'Valor em aberto',
    acessor: 'openValue',
  },
  {
    name: 'Status',
    acessor: 'billStatus',
  },
  {
    name: 'Data da baixa',
    acessor: 'lowDate',
  },
]

export const getBillsToPayInfoToExport = (billsToPay) => {
  return billsToPay.map((bill) => ({
    code: bill.code,
    entryNumber: bill.entryNumber,
    dueDate: format(getDateOnlyFromDate(new Date(bill.dueDate)), 'dd/MM/yyyy'),
    providerName: bill.Provider?.companyName || '',
    issuedAt: format(new Date(bill.issuedAt), 'dd/MM/yyyy'),
    subTotal: Number(bill.subTotal),
    addedValue: Number(bill.addedValue),
    discountValue: Number(bill.discountValue),
    liquidValue: Number(bill.liquidValue),
    paidValue: Number(bill.paidValue),
    openValue: Number(bill.openValue),
    billStatus: constants.BILLS_STATUS_DESCRIPTION[bill.billStatusId],
    lowDate: !bill.lowDate ? '' : format(new Date(bill.lowDate), 'dd/MM/yyyy'),
  }))
}
