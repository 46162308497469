import React, { useState, useEffect } from 'react'
import CardForm from 'client/components/CardForm'
import { Card } from 'components/Card/Card.jsx'
import { Col } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { toastr } from 'react-redux-toastr'

import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { specialChar4 } from 'client/components/ToNormalize/ToNormalize'
import AlertModal from 'components/AlertModal/AlertModal'
import cnaeRepository from '../../../../repositories/Cnaes'
import { useAuth } from '../../../../contexts/auth'

const FormCnae = ({ cnaeId, onSubmit, onCancel }) => {
  const { companyId } = useAuth()

  const [cnae, setCnae] = useState('')
  const [description, setDescription] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [cancelModal, setCancelModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!!cnaeId) {
      loadCnae()
    }
  }, [])

  const loadCnae = async () => {
    const response = await cnaeRepository.showById(cnaeId)
    setCnae(response.code)
    setDescription(response.description)
    setIsActive(response.isActive)
  }

  const handleSubmit = () => {
    setLoading(true)

    if (!cnae || !description) {
      setLoading(false)
      return toastr.warning('Preencha todos os campos para salvar o CNAE.')
    }

    if (!cnaeId) {
      create()
    } else {
      update()
    }
  }

  const create = async () => {
    if (!cnaeId) {
      if (await hasCNAE()) {
        setLoading(false)
        return toastr.warning('Já existe um CNAE cadastrado com esse código.')
      }
    }

    try {
      const data = {
        code: cnae,
        description,
        isActive,
        companyId,
      }
      await cnaeRepository.create(data)
      setLoading(false)
      onSubmit()
    } catch (err) {
      console.log(err)
      console.log(
        'Ocorreu um erro ao salvar a CNAE. Por favor, tente novamente'
      )
      return toastr.error(
        'Ocorreu um erro ao salvar a CNAE. Por favor, tente novamente'
      )
    }
  }

  const update = async () => {
    // if (await hasCNAE()) {
    //   setLoading(false)
    //   return toastr.warning('Já existe um CNAE cadastrado com esse código.')
    // }

    try {
      const data = {
        code: cnae,
        description,
        isActive,
        companyId,
      }
      await cnaeRepository.update(cnaeId, data)
      setLoading(false)
      onSubmit()
    } catch (err) {
      console.log(err)
      console.log(
        'Ocorreu um erro ao atualizar a CNAE. Por favor, tente novamente'
      )
      return toastr.error(
        'Ocorreu um erro ao atualizar a CNAE. Por favor, tente novamente'
      )
    }
  }

  const hasCNAE = async () => {
    const response = await cnaeRepository.getCnaeByCode(cnae)
    const cnaeOfThisCompany = response
      .filter((child) => !!child.companyId)
      .find((child) => parseInt(child.companyId) === parseInt(companyId))
    return !!cnaeOfThisCompany
  }

  return (
    <div
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm show={true} title="Dados do CNAE" onClick={() => {}}>
        <Card
          content={
            <form
              id="form-new-cfop"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 row"
            >
              <Col xs={12} sm={12} md={3} lg={2}>
                <label htmlFor="CNAE">
                  CNAE:<span>*</span>
                </label>
                <input
                  className="form-control foco-input"
                  type="text"
                  id="CNAE"
                  value={cnae}
                  onChange={(e) => setCnae(e.target.value.replace(' ', ''))}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6}>
                <label htmlFor="description">
                  Descrição:<span>*</span>
                </label>
                <input
                  className="form-control foco-input"
                  maxLength="50"
                  value={description}
                  onChange={(e) => setDescription(specialChar4(e.target.value))}
                  type="text"
                  id="description"
                />
              </Col>

              <Col xs={12} sm={12} md={2} lg={2}>
                <label>Status</label>
                <div>
                  {' '}
                  <Toggle
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                  />
                </div>
              </Col>
            </form>
          }
        />
      </CardForm>

      <FormSubmitButtons
        onCancel={() => setCancelModal(true)}
        hasId={!!cnaeId}
        onSubmit={handleSubmit}
        loading={loading}
      />

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message="Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar ?"
          onSubmit={onCancel}
        />
      )}
    </div>
  )
}

export default FormCnae
