import React, { memo } from 'react'
import Chart from "react-google-charts";

const GraphicFormPayment = ({ data }) => {
  return (
    <>
      <h4><strong>Vendas por Forma de Pagamento (%)</strong></h4>
      <div className="chart-pagamento" style={{ opacity: '100%' }}>
        <Chart
          width={'550px'}
          height={'300px'}
          chartType="PieChart"
          data={
            [
              [
                'Forma de Pagamento',
                'Valor',
                { role: 'tooltip', type: 'string' },
              ],
              ...data,
            ]
          }
          chartLanguage="pt-br"
          options={{
            chartArea: {
              width: '75%',
              left: 0,
            },
            legend: {
              width: 200,
            },
          }}
        />
      </div>
    </>
  )
}

export default memo(GraphicFormPayment)
