import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import NumberFormat from 'react-number-format'

import CustomButton from 'client/components/CustomButton/CustomButton'
import YearInput from 'components/YearInput'

import { useAuth } from 'contexts/auth'

import billsToPayRepository from 'repositories/BillsToPay'

import './index.css'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { encrypt } from '../ToNormalize/ToNormalize'

export default function ConsultPayableAmountModal({ isOpen, handleClose }) {
  const [year, setYear] = useState('')
  const [payables, setPayables] = useState([])
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  async function handleGetPayables() {
    setLoading(true)
    try {
      const data = await billsToPayRepository.getPendingAmountByYearPerMonth({
        year,
        companyId
      })

      setPayables(data)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar Saldos a Pagar',
        'Tente novamente! Caso persista, contate o suporte.'
      )
    } finally {
      setLoading(false)
    }
  }

  async function returnLinkReport(index) {
    const initialDate = format(new Date(year, index, 1), 'yyyy-MM-dd')
    const finalDate = format(new Date(year, index + 1, 0), 'yyyy-MM-dd')

    window.open(
      window.location.origin +
        `/report/expense-forecast?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}`,
      '_blank'
    )
  }

  useEffect(() => {
    if (year.length === 4) {
      handleGetPayables()
    }
  }, [year])

  useEffect(() => {
    setYear(`${new Date().getFullYear()}`)
  }, [])

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-25w">
      <Modal.Header>
        <Modal.Title>
          <strong>Previsão de Pagamentos</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Veja abaixo o saldo a pagar:</span>
        <br />
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <YearInput value={year} setValue={setYear} showArrows />
        </div>

        <center>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
              width: '250px',
            }}
          >
            {loading ? (
              'Carregando...'
            ) : (
              <table className="consult-amount-table">
                <thead>
                  <tr>
                    <th className="month-cell">Mês</th>
                    <th className="amount-cell">Saldo</th>
                    <th className="actions-balance"></th>
                  </tr>
                </thead>
                <tbody>
                  {payables.map((payable, index) => (
                    <tr>
                      <td className="month-cell">{payable.month}</td>
                      <td className="amount-cell">
                        <NumberFormat
                          displayType="text"
                          value={payable.amount}
                          decimalScale="2"
                          fixedDecimalScale={true}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </td>
                      <td className="actions-balance">
                        <FontAwesomeIcon
                          icon={faPrint}
                          style={{
                            color: '#444444',
                          }}
                          onClick={() => returnLinkReport(index)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr></tr>
                </tbody>
              </table>
            )}
          </div>
        </center>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ textAlign: 'right' }}>
          <CustomButton bsStyle="danger" fill onClick={() => handleClose()}>
            Voltar
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
