import React, { useEffect, useState } from 'react'
import { endOfMonth, startOfMonth, format, isAfter } from 'date-fns'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Select from 'react-select'

import CustomButton from 'client/components/CustomButton/CustomButton'
import Button from 'client/components/CustomButton/Button.jsx'

import { currency } from 'client/components/ToNormalize/ToNormalize'

import providersRepository from 'repositories/Providers'
import purchasesRepository from 'repositories/Purchases'

import { useAuth } from 'contexts/auth'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import ReactTable from 'react-table'
import AlertModal from 'components/AlertModal/AlertModal'

const selectStyles = {
  control: (base) => ({
    ...base,
    width: '400px',
    height: '35px',
    minHeight: '35px',
    borderColor: '#E3E3E3',
    cursor: 'pointer',
    zIndex: 10000,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: '-6px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: '-6px',
  }),
}

function renderPurchasesTypeButton(props) {
  return (
    <Button
      id="botaoReactTable"
      style={{
        zIndex: '0',
        width: '100%',
        height: '100%',
        backgroundColor: props.value === 'XML' ? '#428bca' : '#5cb85c',
      }}
    >
      {props.value}
    </Button>
  )
}

const PurchasesSelectionFooter = ({
  handleClose,
  purchases,
  setIsConfirmationModalOpen,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <CustomButton
        bsStyle="danger"
        name="backButton"
        onClick={handleClose}
        fill
      >
        Voltar
      </CustomButton>
      <CustomButton
        style={{
          background: '#5DB85B',
          borderColor: '#5DB85B',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
        }}
        name="submitButton"
        onClick={() => setIsConfirmationModalOpen(true)}
        disabled={
          purchases.filter((purchase) => purchase.selected).length === 0
        }
        fill
      >
        Criar Título
      </CustomButton>
    </div>
  )
}

const PurchasesFilterForm = ({
  selectedProvider,
  setSelectedProvider,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  providersOptions,
  getCompanyPurchases,
}) => {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
      <div>
        <Select
          options={providersOptions}
          onChange={(opt) => setSelectedProvider(opt)}
          value={selectedProvider}
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          isSearchable
          placeholder={'Pesquisa por CPF/CNPJ ou Nome/Razão Social'}
          id="customerSelect"
          styles={selectStyles}
        />
      </div>
      <div>
        <label htmlFor="initialDateInput">Data Inicial:</label>
        <input
          className="form-control"
          type="date"
          max={finalDate}
          name="initialDate"
          onChange={(e) => setInitialDate(e.target.value)}
          value={initialDate}
          id="initialDateInput"
        />
      </div>
      <div>
        <label htmlFor="finalDateInput">Data Final:</label>
        <input
          className="form-control"
          type="date"
          min={initialDate}
          onChange={(e) => setFinalDate(e.target.value)}
          value={finalDate}
          name="initialDate"
          id="finalDateInput"
        />
      </div>
      <div>
        <CustomButton
          fill
          style={{
            background: '#5DB85B',
            borderColor: '#5DB85B',
            fontSize: '14px',
            height: '35px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={getCompanyPurchases}
        >
          Processar
        </CustomButton>
      </div>
    </div>
  )
}

const PurchasesTable = ({
  purchases,
  isLoadingPurchases,
  loadedPurchases,
  handlePurchaseSelection,
}) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '13px',
        }}
        data={purchases}
        columns={[
          {
            Header: 'Data',
            accessor: 'entryAt',
            headerClassName: 'text-center',
            width: 100,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Compra',
            accessor: 'code',
            headerClassName: 'text-center',
            className: 'texto',
            width: 85,
          },
          {
            Header: 'Tipo',
            accessor: 'type',
            headerClassName: 'text-center',
            width: 120,
            className: 'texto',
            Cell: (props) => renderPurchasesTypeButton(props),
          },
          {
            Header: 'Nota',
            accessor: 'invoice',
            headerClassName: 'text-center',
            className: 'texto',
            width: 85,
          },
          {
            Header: 'Fornecedor',
            accessor: 'Provider',
            headerClassName: 'text-center',
            className: 'texto',
            Cell: (props) => props.value.tradingName || props.value.companyName,
          },
          {
            Header: 'Valor',
            accessor: 'total',
            headerClassName: 'text-center',
            className: 'texto',
            width: 120,
            Cell: (props) => <a>{currency(props.value)}</a>,
          },
          {
            Header: '',
            accessor: 'id',
            headerClassName: 'text-center',
            filterable: false,
            className: 'texto',
            width: 80,
            style: {
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto 0',
            },
            Cell: (props) => (
              <input
                type="checkbox"
                checked={props.original.selected}
                onChange={() => handlePurchaseSelection(props.original.id)}
              />
            ),
          },
        ]}
        sortable
        showPagination
        loading={isLoadingPurchases}
        defaultPageSize={10}
        showPaginationBottom
        showPageSizeOptions={false}
        defaultSorted={[{ id: 'Date', desc: true }]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={
          loadedPurchases
            ? 'Nenhuma informação encontrada'
            : 'Selecione o fornecedor e o período para buscar as compras finalizadas'
        }
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  )
}

export default function SalesSelectionModal({ isOpen, handleClose, history }) {
  const { companyId } = useAuth()

  const [selectedProvider, setSelectedProvider] = useState(null)

  const [loadedPurchases, setLoadedPurchases] = useState(false)
  const [isLoadingPurchases, setIsLoadingPurchases] = useState(false)
  const [purchases, setPurchases] = useState([])

  const [providersOptions, setProvidersOptions] = useState([])

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [initialDate, setInitialDate] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  )
  const [finalDate, setFinalDate] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  )

  function getSelectedPurchase() {
    return purchases.filter((purchase) => purchase.selected)
  }

  function generateConfirmationMessage() {
    const selectedPurchases = getSelectedPurchase()
    if (selectedPurchases.length === 1) {
      return (
        <>
          <span>
            <strong>Você deseja gerar um título para essa compra?</strong>
          </span>
          <br />
          <br />
          <span>
            O processo é irreversível. Caso tenha outros títulos gerados, todos
            serão cancelados e um novo título será gerado.
          </span>
        </>
      )
    } else {
      return (
        <>
          <span>
            <strong>
              Você deseja gerar um título para as compras selecionadas?
            </strong>
          </span>
          <br />
          <br />
          <span>
            O processo é irreversível. Caso tenha outros títulos gerados, todos
            serão cancelados e um novo título será gerado. Você não poderá gerar
            uma nota para as compras agrupadas.
          </span>
        </>
      )
    }
  }

  function handlePurchaseSelection(saleId) {
    setPurchases(
      purchases.map((sale) => {
        if (sale.id === saleId) {
          return { ...sale, selected: !sale.selected }
        } else {
          return sale
        }
      })
    )
  }

  function handleSubmit() {
    const selectedPurchases = getSelectedPurchase()
    const selectedPurchasesId = selectedPurchases.map((purchase) => purchase.id)
    const selectedPurchasesCode = selectedPurchases.map(
      (purchase) => purchase.code
    )

    const total = selectedPurchases.reduce((prev, curr) => prev + curr.total, 0)

    const grouped = selectedPurchasesId.length > 1

    history.push(`/client/bill-to-pay`, {
      purchasesId: selectedPurchasesId,
      purchasesCode: selectedPurchasesCode,
      code: selectedPurchases.length === 1 && selectedPurchases[0].Code,
      total,
      grouped,
      providerId: selectedProvider.value.id,
      providerName: selectedProvider.value.name,
    })
  }

  async function getCompanyPurchases() {
    if (!selectedProvider) {
      return toastr.warning(
        'OS Digital',
        'Selecione um fornecedor para fazer a busca'
      )
    }

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      return toastr.warning(
        'Busca não realizada',
        'Selecione um período válido para fazer a busca'
      )
    }

    setIsLoadingPurchases(true)
    try {
      const params = {
        providerId: selectedProvider.value.id,
        initialDate,
        finalDate,
        purchaseStatusId: 2,
      }

      const companyPurchases =
        await purchasesRepository.getAllPurchasesByCompany(companyId, params)

      setPages(Math.ceil(companyPurchases.count / pageSize))
      setPurchases(
        companyPurchases.map((purchase) => ({ ...purchase, selected: false }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Falha ao carregar as compras',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setLoadedPurchases(true)
      setIsLoadingPurchases(false)
    }
  }

  async function getActiveCustomers() {
    try {
      const providers = await providersRepository.getAllActiveByCompany(
        companyId
      )

      setProvidersOptions(
        providers.map((provider) => ({
          value: {
            id: provider.id,
            name: provider.companyName || provider.tradingName,
          },
          label: `${
            provider.cpfCnpj && cpfOrCnpjMask(provider.cpfCnpj) + ' - '
          }${provider.companyName || provider.tradingName}`,
        }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Falha ao Carregar os Clientes',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    }
  }

  useEffect(() => {
    getActiveCustomers()
  }, [])

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-70w">
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>
            Selecione as compras para gerar o título:
          </p>
          <PurchasesFilterForm
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            getCompanyPurchases={getCompanyPurchases}
            getActiveCustomers={getActiveCustomers}
            providersOptions={providersOptions}
          />
          <PurchasesTable
            purchases={purchases}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            isLoadingPurchases={isLoadingPurchases}
            pages={pages}
            getCompanySales={getCompanyPurchases}
            loadedPurchases={loadedPurchases}
            selectedProvider={selectedProvider}
            handlePurchaseSelection={handlePurchaseSelection}
          />
        </Modal.Body>
        <Modal.Footer>
          <PurchasesSelectionFooter
            handleClose={handleClose}
            purchases={purchases}
            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          />
        </Modal.Footer>
      </Modal>

      {isConfirmationModalOpen && (
        <AlertModal
          show={true}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onHide={() => null}
          onSubmit={handleSubmit}
          message={generateConfirmationMessage()}
        />
      )}
    </>
  )
}
