import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { change, initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import Loader from '../../../../../components/Loader';
import Form from './FormNewNFCe';
import ErrorsModal from './ErrorsModal';
import InvoiceProcessLoader from '../../../../components/InvoiceProcessLoader';

import {
  cnpjMask,
  cpfMask,
  onlyNumbers,
} from '../../../../components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../../contexts/auth';
import NFCesRepository from '../../../../../repositories/NFCes';
import companiesRepository from '../../../../../repositories/Companies';
import constants, {
  INVOICE_STATUS_DESCRIPTION,
} from '../../../../../utils/constants';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';

const FormNewNfce = ({ history, NFCeId, setCode }) => {
  const [loading, setLoading] = useState(false);
  const [isInvoiceLoaderOpen, setIsInvoiceLoaderOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isIncludeCpfCnpj, setIsIncludeCpfCnpj] = useState(false);
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  const dispatch = useDispatch();
  const { companyId, company } = useAuth();

  useEffect(() => {
    if (!NFCeId) {
      history.push(constants.ROUTES.INVOICES);
    }
    loadNFCe();
  }, [NFCeId]);

  async function loadNFCe() {
    setLoading(true);

    try {
      const nfce = await NFCesRepository.show(NFCeId);
      setCode(nfce.code);
      const statusNFCe = getNFCeStatus(nfce);

      if (nfce.status === 'Aberta' || nfce.status === 'Aberta c/ Erro') {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

      const nfceObject = {
        id: nfce.id,
        nfc: nfce.code,
        serie: nfce.serie,
        venda: nfce.Sale.Code,
        saleId: nfce.saleId,
        saleParcels: nfce.Sale.Payments?.Parcels || [],
        statusNFCe: statusNFCe,
        dataCriacao: format(new Date(), 'yyyy-MM-dd'),
        dataEmissao: !nfce.issuedAt
          ? ''
          : format(new Date(nfce.issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(nfce.issuedAt), 'hh:mm'),
        chaveDeAcesso: formatAccessKey(nfce.accessKey),
        customerId: nfce.customerId,
        situationCode: nfce.situationCode,
        cliente: nfce.Customer.Trading_Name
          ? nfce.Customer.Trading_Name
          : nfce.Customer.Company_Name,
        cnpjCpf: isDefaultCustomer(nfce.Customer)
          ? nfce.cpfCnpj == '00000000000'
            ? ''
            : nfce.cpfCnpj.length === 11
            ? cpfMask(nfce.cpfCnpj)
            : cnpjMask(nfce.cpfCnpj)
          : nfce.Customer.Cpf_Cnpj.length === 11
          ? cpfMask(nfce.Customer.Cpf_Cnpj)
          : cnpjMask(nfce.Customer.Cpf_Cnpj),
        customerHasCpfCnpj: !!nfce.Customer.Cpf_Cnpj,
        subTotal: nfce.subtotal,
        freight: nfce.Sale?.freightValue || 0,
        isDefaultCustomer: isDefaultCustomer(nfce.Customer),
        customerType: nfce.Customer.Type,
        totalProdutos: nfce.total,
        discount: nfce.discount,
        NFCeItems: nfce.NFCeItems,
        bcICMS: nfce.bcICMS,
        valueICMS: nfce.valueICMS,
        bcPIS: nfce.bcPIS,
        valuePIS: nfce.valuePIS,
        bcCOFINS: nfce.bcCOFINS,
        valueCOFINS: nfce.valueCOFINS,
        total: nfce.total,
        status: nfce.status,
        isInvoiceOpen: nfce.status === INVOICE_STATUS_DESCRIPTION.OPEN,
        isInvoiceCanceled: nfce.status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        isInvoiceIssued: nfce.status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          nfce.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
      };

      dispatch(initialize('cadastroNFCe', nfceObject));
      loadCompanyTaxRegime();
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar a NFC-e. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  async function loadCompanyTaxRegime() {
    try {
      const taxData = await companiesRepository.getTaxData(companyId);
      dispatch([
        change(
          'cadastroNFCe',
          'isTaxRegimeSimpleNational',
          taxData.TaxRegime === 'Simples Nacional' ||
            taxData.TaxRegime === 'MEI - Simples Nacional'
        ),
        change('cadastroNFCe', 'companyPIS', taxData.PIS),
        change('cadastroNFCe', 'companyCOFINS', taxData.COFINS),
      ]);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados fiscais da empresa. Por favor, tente novamente'
      );
    }
  }

  function getNFCeStatusLabel(code) {
    switch (code) {
      case 100:
        dispatch(change('cadastroNFCe', 'isInvoiceOpen', false));
        dispatch(change('cadastroNFCe', 'isInvoiceOpenedWithErrors', false));
        toastr.success('Sucesso', 'NFC-e emitida com sucesso.');
        return 'Emitida';
      case 105:
      case 108:
      case 109:
        toastr.warning('', 'NFC-e em contingência.');
        return 'Em Contingência';
      default:
        toastr.error('', 'NFC-e Aberta c/ Erro.');
        return 'Aberta c/ Erro';
    }
  }

  function handleSubmit(values) {
    const { includeCpfCnpj } = values;

    setIsIncludeCpfCnpj(includeCpfCnpj);
    return handleEmit(values);
  }

  async function handleEmit(values) {
    const { includeCpfCnpj, id } = values;

    setIsInvoiceLoaderOpen(true);
    try {
      const result = await NFCesRepository.emit(id, {
        includeCpfCnpj,
        issuedAt: new Date().getTime(),
      });

      const { printInvoicesAutomatically } =
        await companiesRepository.getTaxData(companyId);

      const {
        situationCode,
        situationDescription,
        status,
        bcICMS,
        valueICMS,
        bcPIS,
        valuePIS,
        bcCOFINS,
        valueCOFINS,
        issuedAt,
        accessKey,
        docPdfDownload,
      } = result;

      const statusNFCe = getNFCeStatus({
        status,
        situationDescription,
        situationCode,
      });
      const statusLabel = getNFCeStatusLabel(situationCode);

      const isInvoiceIssued = status === INVOICE_STATUS_DESCRIPTION.ISSUED;
      dispatch([
        change('cadastroNFCe', 'status', statusLabel),
        change('cadastroNFCe', 'statusNFCe', statusNFCe),
        change('cadastroNFCe', 'isInvoiceIssued', isInvoiceIssued),
        change(
          'cadastroNFCe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'cadastroNFCe',
          'dataEmissao',
          format(new Date(), 'yyyy-MM-dd') + 'T' + format(new Date(), 'hh:mm')
        ),
        change('cadastroNFCe', 'chaveDeAcesso', accessKey),
      ]);

      if (isInvoiceIssued) {
        dispatch([
          change('cadastroNFCe', 'bcICMS', bcICMS),
          change('cadastroNFCe', 'valueICMS', valueICMS),
          change('cadastroNFCe', 'bcPIS', bcPIS),
          change('cadastroNFCe', 'valuePIS', valuePIS),
          change('cadastroNFCe', 'bcCOFINS', bcCOFINS),
          change('cadastroNFCe', 'valueCOFINS', valueCOFINS),
        ]);
        if (printInvoicesAutomatically) {
          window.open(docPdfDownload);
        }
      }

      setIsInvoiceLoaderOpen(false);
    } catch (err) {
      console.error(err);
      setIsInvoiceLoaderOpen(false);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        handleOpenErrorsModal(err.response.data.errors);
      } else {
        toastr.warning(
          'Ocorreu uma falha ao enviar a nota',
          err.response.data.message
        );
      }
    }
  }

  function handleOpenErrorsModal(errors) {
    const serializedErrors = errors.map((error) => ({
      ...error,
      isResolved: false,
    }));
    setErrors(serializedErrors);
    setIsErrorsModalOpen(true);
  }

  function formatAccessKey(value) {
    if (!value) return value;
    value = onlyNumbers(value);
    return (
      value.substring(0, 4) +
      ' ' +
      value.substring(4, 8) +
      ' ' +
      value.substring(8, 12) +
      ' ' +
      value.substring(12, 16) +
      ' ' +
      value.substring(16, 20) +
      ' ' +
      value.substring(20, 24) +
      ' ' +
      value.substring(24, 28) +
      ' ' +
      value.substring(28, 32) +
      ' ' +
      value.substring(32, 36) +
      ' ' +
      value.substring(36, 40) +
      ' ' +
      value.substring(40, 44)
    );
  }

  function getNFCeStatus({ status, situationDescription, situationCode }) {
    if (status !== 'Aberta' && status !== 'Contingência') {
      return situationCode + ' - ' + situationDescription;
    }
    return status;
  }

  function handleRetryEmit() {
    setIsErrorsModalOpen(false);
    setIsInvoiceLoaderOpen(true);
    handleEmit({
      id: NFCeId,
      includeCpfCnpj: isIncludeCpfCnpj,
    });
  }

  const initialValues = {
    cnpjCpf: '',
    includeCpfCnpj: false,
    customerId: 0,
    NFCeItems: [],
    editItem: [],
    modalProduto: false,
    situationCode: 0,
    freight: 0,
    subTotal: 0,
    totalProdutos: 0,
    discount: 0,
    status: '',
    bcICMS: 0,
    valueICMS: 0,
    bcPIS: 0,
    valuePIS: 0,
    bcCOFINS: 0,
    valueCOFINS: 0,
    total: 0,
  };
  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={handleSubmit}
        onCancel={() => history.push(constants.ROUTES.INVOICES)}
        initialValues={initialValues}
        loading={loading}
        disabled={disabled}
        loadNFCe={loadNFCe}
      />
      {isErrorsModalOpen && (
        <ErrorsModal
          setErrors={setErrors}
          errors={errors}
          onCancel={() => setIsErrorsModalOpen(false)}
          onRetry={handleRetryEmit}
        />
      )}
      {isInvoiceLoaderOpen && (
        <InvoiceProcessLoader message="Estamos realizando o envio da nota fiscal. Por favor, aguarde." />
      )}

      {loading && <Loader message="Aguarde, carregando NFC-e" />}
    </div>
  );
};

export default withRouter(FormNewNfce);
