import React, { useEffect } from 'react'
import CitiesListFilter from './CitiesListFilter'
import CitiesListTable from './CitiesListTable'
import NFSePatternsRepository from 'repositories/NFSePatterns'
import { useState } from 'react'
import useDebounce from 'hooks/useDebounce'
import { toastr } from 'react-redux-toastr'
import NewCityModal from './NewCityModal'

const CitiesListMain = () => {
  const [loading, setLoading] = useState(false)
  const [citiesList, setCitiesList] = useState([])
  const [patternsList, setPatternsList] = useState([])
  const [query, setQuery] = useState('')
  const [state, setState] = useState('')
  const [pattern, setPattern] = useState('')

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [isNewCityModalOpen, setIsNewCityModalOpen] = useState(false)

  const [queryDebounced, setQueryDebounced] = useState('')
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600)

  const handleChangeQuery = (value) => {
    setQuery(value)
    debouncedSaveQuery(value)
  }

  const handleCancel = () => {
    setIsNewCityModalOpen(false)
  }

  const loadCities = async () => {
    setLoading(true)
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        codeOrNameCity: queryDebounced,
        statePattern: state,
        pattern,
      }
      const cities = await NFSePatternsRepository.getCitiesPatterns(params)

      const { patterns } = await NFSePatternsRepository.index({ query: '' })

      setPatternsList(patterns)
      setCitiesList(cities.data)
      setTotalPages(Math.ceil(cities.count / pageLimit))
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao carregas as cidades',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCities()
  }, [currentPage, pageLimit, state, pattern, queryDebounced])

  return (
    <>
      <CitiesListFilter
        query={query}
        setQuery={setQuery}
        state={state}
        setState={setState}
        pattern={pattern}
        setPattern={setPattern}
        patternsList={patternsList}
        handleChangeQuery={handleChangeQuery}
        setIsNewCityModalOpen={setIsNewCityModalOpen}
      />
      <CitiesListTable
        loading={loading}
        citiesList={citiesList}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />

      {isNewCityModalOpen && (
        <NewCityModal loadCities={loadCities} onCancel={handleCancel} />
      )}
    </>
  )
}

export default CitiesListMain
