import React from 'react';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { InputContainer } from 'v2/components/Input';

export default function ExcelHelperCustomerApproach({ loading, reportData }) {
  const tableColumns = [
    {
      name: 'Dt. Cadastro',
      acessor: 'createdAt',
    },
    {
      name: 'Nome Completo',
      acessor: 'fullName',
    },
    {
      name: 'Tipo',
      acessor: 'type',
    },
    {
      name: 'Abordagem',
      acessor: 'approachDescription',
    },
    {
      name: 'Últ. Venda/OS',
      acessor: 'latestSaleCode',
    },
    {
      name: 'Ticket Médio',
      acessor: 'averageTicket',
    },
  ];

  return (
    <div>
      <InputContainer variant="column">
        <DownloadXlsButton
          archiveName="abordagem_de_clientes"
          data={reportData}
          className="btn btn-export"
          style={{ height: '35px' }}
          disabled={loading || !reportData}
          columns={tableColumns}
        >
          {loading ? (
            <>
              <span
                className="fa fa-spinner fa-pulse fa-1x"
                style={{ marginRight: '5px' }}
              ></span>
            </>
          ) : (
            <>
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
            </>
          )}
          Exportar .xls
        </DownloadXlsButton>
      </InputContainer>
    </div>
  );
}
