import config from "../config";
import api from "../services/api";

const create = async (params) => {
  try {
    const response = await api.post(config.endpoint + `inventory`, params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (params, id) => {
  try {
    const response = await api.put(config.endpoint + `inventory/${id}`, params);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getCode = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `inventory/code/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const show = async (id) => {
  try {
    const response = await api.get(config.endpoint + `inventory/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `inventory?companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllMovementItemsByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `inventory/company/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const cancel = async (id) => {
  try {
    const response = await api.put(config.endpoint + `inventory/cancel/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateInventoryReportMovement = async ({ companyId, initialDate, finalDate, returnStatus, searchByType }) => {
  try {
    const response = await api.get(config.endpoint + `report/inventory-movement-report/${companyId}`, {
      params: {
        initialDate,
        finalDate,
        returnStatus,
        searchByType
      }
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  create,
  getCode,
  show,
  update,
  getAllByCompany,
  getAllMovementItemsByCompany,
  generateInventoryReportMovement,
  cancel,
};
