import React, { useState } from 'react';
import { faChartPie, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import DownloadXlsButton from '../../../../components/DownloadXlsButton';
import CustomLabel from 'components/CustomLabel';
import { BrandingLabel } from 'v2/components/BrandingLabel';
import PollsRepository from 'repositories/Polls';
import { toastr } from 'react-redux-toastr';

const PollsTable = ({
  loading,
  polls,
  PollsStatusSwitch,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  function serializeReport(report) {
    return {
      ...report,
      cpfCnpj:
        report.cpfCnpj.length <= 11
          ? cpfMask(report.cpfCnpj)
          : cnpjMask(report.cpfCnpj),
      userPhone: phoneMask(report.userPhone),
    };
  }

  const handleExportXls = async (index, id) => {
    toastr.warning('Gerando relatório, por favor aguarde.');
    setLoading(true);
    setSelectedIndex(index);
    try {
      const response = await PollsRepository.exportXlsById(id);
      const polls = response.map(serializeReport);

      if (!polls.length) {
        setDataToExport([]);
        return toastr.warning('Sem dados disponíveis');
      }

      setDataToExport(polls);
      return toastr.success(
        'Relatório gerado',
        'Clique no ícone novamente para baixar o relatório'
      );
    } catch (error) {
      toastr.error('Ocorreu um erro ao buscar os dados do relatório');
      console.error('Erro ao exportar dados:', error);
    } finally {
      setLoading(false);
    }
  };

  const FetchDataIcon = ({ index, id }) => {
    return (
      <span>
        <FontAwesomeIcon
          icon={faChartPie}
          cursor="pointer"
          title="Gerar relatório"
          onClick={() => handleExportXls(index, id)}
          style={{ color: index === selectedIndex ? 'green' : 'black' }}
        />
      </span>
    );
  };

  const LoadingState = () => (
    <span>
      <span className="fa fa-spinner fa-pulse fa-1x update-spinner" />
    </span>
  );

  const DownloadReportButton = ({ index, id, description }) => {
    if (isLoading && index === selectedIndex) {
      return (
        <>
          <LoadingState isLoading={isLoading} />
        </>
      );
    }

    if (dataToExport.length && index === selectedIndex) {
      return (
        <>
          <DownloadXlsButton
            style={{ display: 'contents' }}
            archiveName={`Pesquisas - ${description} ${format(
              new Date(),
              'dd-MM-yyyy'
            )} às ${format(new Date(), 'hh:mm').replace(':', 'h')}min`}
            onClick={() => {
              setSelectedIndex(null);
            }}
            data={dataToExport}
            disabled={loading}
            columns={[
              {
                name: 'Nome',
                acessor: 'userName',
              },
              {
                name: 'Email',
                acessor: 'userEmail',
              },
              {
                name: 'CPF/CNPJ',
                acessor: 'cpfCnpj',
              },
              {
                name: 'Nome/Razão Social',
                acessor: 'companyName' || 'tradingName',
              },
              {
                name: 'Plano',
                acessor: 'companyPlan',
              },
              {
                name: 'Telefone',
                acessor: 'userPhone',
              },
              {
                name: 'Nota',
                acessor: 'answer',
              },
              {
                name: 'Observações',
                acessor: 'notes',
              },
              {
                name: 'CustomerX Id',
                acessor: 'customerXId',
              },
            ]}
          >
            <FontAwesomeIcon
              icon={faChartPie}
              cursor="pointer"
              title="Exportar relatório"
              style={{ color: index === selectedIndex ? 'green' : 'black' }}
            />
          </DownloadXlsButton>
        </>
      );
    }

    return (
      <>
        <FetchDataIcon index={index} id={id} />
      </>
    );
  };

  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={polls}
        columns={[
          {
            Header: 'Produto',
            accessor: 'brandingId',
            width: 100,
            Cell: (props) => (
              <BrandingLabel
                height={'22px'}
                brandingId={props.value}
                noBrandingText="Ambos"
              />
            ),
          },
          {
            Header: 'Pesquisa',
            accessor: 'type',
            width: 100,
            Cell: (props) => (
              <CustomLabel
                text={props.value}
                backgroundColor={
                  props.value === 'NPS'
                    ? '#428bca'
                    : props.value === 'NVS'
                    ? '#5cb85c'
                    : '#5bc0de'
                }
              />
            ),
          },
          {
            Header: 'Data Inicial',
            accessor: 'initialDate',
            width: 130,
            Cell: (props) =>
              format(getDateOnlyFromDate(new Date(props.value)), 'dd/MM/yyyy'),
          },
          {
            Header: 'Data Final',
            accessor: 'finalDate',
            width: 130,
            Cell: (props) =>
              format(getDateOnlyFromDate(new Date(props.value)), 'dd/MM/yyyy '),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
          },
          {
            Header: 'Avaliações',
            accessor: 'pollsAnswerCount',
            width: 100,
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => {
              return (
                <PollsStatusSwitch
                  status={props.value}
                  pollsId={props.original.id}
                  brandingId={props.original.brandingId}
                />
              );
            },
            width: 100,
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 100,
            Cell: (props) => (
              <div className="table-actions" style={{ alignItems: 'unset' }}>
                <Link to={{ pathname: `polls-update#${props.value}` }}>
                  <FontAwesomeIcon
                    title="Editar Pesquisa"
                    cursor="pointer"
                    icon={faEdit}
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </Link>

                <DownloadReportButton
                  index={props.index}
                  id={props.original.id}
                  description={props.row.description}
                />
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        defaultSorted={[
          {
            id: 'initialDate',
            desc: true,
          },
        ]}
        page={currentPage}
        onPageChange={(value) => setCurrentPage(value)}
        pages={totalPages}
        manual
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma pesquisa encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default PollsTable;
