import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: ${(props) => props.theme.space[5]};

  & .row-1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
  }

  & .row-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    & div.input__container {
      flex-grow: 1;
    }
  }

  & a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
