import React from 'react';
import { useBankConciliationDetailsColumnsConfig } from '../../../hooks/useBankConciliationDetailsColumnsConfig';
import { useFormContext } from 'react-hook-form';
import { filterTransactions } from '../../../utils/bankConciliationDetailsFilterSearch';
import { Table } from 'v2/components/Table';
import { useBankConciliationStore } from 'v2/store/bankConciliation';
import { StyledTableContainer } from '../BankConciliationDetails.styles';

export function BankConciliationDetailsTable({ prefix }) {
  const { dataOFX, filterTransactions: storeFilterTransactions } = useBankConciliationStore((store) => ({
    dataOFX: store.bankConciliation.dataOFX,
    filterTransactions: store.filterTransactions,
  }));



  const { getValues, watch, setValue } = useFormContext();

  const [filterTransactionsData, setFilterTransactionsData] = React.useState([]);
  const { configColumnsDetails } = useBankConciliationDetailsColumnsConfig(setValue, getValues);

  const description = watch(`search.${prefix}.description`);
  const transactionType = watch(`search.${prefix}.transactionType`);
  const transactionStatus = watch(`search.${prefix}.transactionStatus`);
  const TransactionInitialDate = watch(`search.${prefix}.TransactionInitialDate`);
  const TransactionFinishDate = watch(`search.${prefix}.TransactionFinishDate`);
  const status = watch(`informationFile.data.transactions`);
  const selectedTransactions = watch(`selectedTransactions`);


  React.useEffect(() => {
    const filteredData = filterTransactions(dataOFX?.transactions || [], getValues, prefix);
    setFilterTransactionsData(filteredData);
  }, [
    transactionType,
    TransactionFinishDate,
    TransactionInitialDate,
    transactionStatus,
    description,
    status,
    selectedTransactions,
    dataOFX,
    prefix,
  ]);

  return (
    <div style={{ marginTop: '1.125rem', width: '99%' }}>
      <StyledTableContainer>
        <Table
          columns={configColumnsDetails}
          data={filterTransactionsData}
          style={{
            padding: '0px'
          }}
        />
      </StyledTableContainer>
    </div>
  );
}
