import environmentDevelopment from './development.js';
import environmentProduction from './production.js';
import environmentRelease from './release.js';
import environmentStaging from './staging.js';
import local from './local.js';

const environmentMap = {
  'osdig.carrook.com.br': environmentDevelopment,
  'workmotorapp.carrook.com.br': environmentDevelopment,
  'tecnomotor.carrook.com.br': environmentDevelopment,
  'po360.carrook.com.br': environmentDevelopment,

  'release.app.osdig.com.br': environmentRelease,
  'release.app.workmotor.app': environmentRelease,
  'release.app.sistemabarros.com.br': environmentRelease,
  'rel.app.po360comercial.com.br': environmentRelease,
  'release.app.osdigitaltecnomotor.com.br': environmentRelease,

  'stg.app.osdig.com.br': environmentStaging,
  'stg.app.po360comercial.com.br': environmentStaging,
  'stg.app.workmotor.app': environmentStaging,
  'stg.app.osdigitaltecnomotor.com.br': environmentStaging,
  'stg.app.sistemabarros.com.br': environmentStaging,

  'app.osdig.com.br': environmentProduction,
  'app.po360comercial.com.br': environmentProduction,
  'app.workmotor.app': environmentProduction,
  'app.osdigitaltecnomotor.com.br': environmentProduction,
  'app.sistemabarros.com.br': environmentProduction,
  default: local,
};

const customEnvironmentMap = {
  development: environmentDevelopment,
  production: environmentProduction,
  release: environmentRelease,
  staging: environmentStaging,
  default: environmentDevelopment,
};

export default function ConfigureAppEnvironment() {
  const hostname = window?.location?.hostname;
  const customEnvironment = process.env.REACT_APP_CUSTOM_ENVIRONMENT;
  if (customEnvironment) {
    return (
      customEnvironmentMap[customEnvironment] || customEnvironmentMap['default']
    );
  } else {
    return environmentMap[hostname] || environmentMap['default'];
  }
}
