import React from 'react'

const Vehicle = ({ vehicle }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <span><strong>Placa: {vehicle.License_Plate}</strong></span>
      <span>Veículo: {vehicle.Model}</span>
      <span>Ano Fab./Ano Mod.: {vehicle.Year_Manufacture}/{vehicle.Year_Model}</span>
      <span>Cor: {vehicle.Color}</span>
      <span>KM Atual: {vehicle.Kilometers}</span>
    </div>
  )
}

export default Vehicle