import styled from 'styled-components';
import { Root, Overlay, Content } from '@radix-ui/react-dialog';

export const DialogNewsRoot = styled(Root)`
  z-index: ${(props) => props.theme.zIndex.modal};
`;

export const DialogNewsOverlay = styled(Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;

  z-index: ${(props) => props.theme.zIndex.modal};

  & {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const DialogNewsContent = styled(Content)`
  outline: 0;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: ${(props) => props.theme.zIndex.modal};
`;
