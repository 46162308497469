import React from 'react';
import Segmento from 'client/components/Segmentos/Segmento.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from './ReduxMeusDetalhes/meusDetalhesAction';
import { useState } from 'react';
import { formatToLettersOnly } from 'v2/helpers/normalizeString';

const MySegments = ({ isGridded, branchId }) => {
  const dispatch = useDispatch();
  const { meusDetalhesReducer, segmentoReducer } = useSelector(
    (state) => state
  );
  const { segmentDescription } = meusDetalhesReducer;

  const handleChangeSegmentDescription = (value) => {
    value = formatToLettersOnly(value);
    dispatch(handleChange(value, 'segmentDescription'));
  };

  return (
    <div id="labelSemUpperCase">
      {branchId === 5 ? (
        <>
          <label>
            Insira o segmento que sua empresa atua:
            <span style={{ color: 'red' }}>*</span>
          </label>
          <div id="meusSegmentos">
            <input
              className="form-control"
              name="segmentDescription"
              value={segmentDescription}
              onChange={(e) => handleChangeSegmentDescription(e.target.value)}
              style={{ width: '25%' }}
            ></input>
          </div>
        </>
      ) : (
        <>
          <label>
            Selecione o segmento da sua empresa:
            <span style={{ color: 'red' }}>*</span>
          </label>
          <div id="meusSegmentos">
            <Segmento isGridded={isGridded} />
          </div>
        </>
      )}
    </div>
  );
};

export default MySegments;
