import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import salesRepository from 'repositories/Sales';
import AppointmentsTabSelector from './AppointmentsTabSelector';
import AppointmentsList from './AppointmentsList';
import AppointmentsCreate from './AppointmentsCreate';
import './index.css';

const AppointmentsModal = ({
  open,
  saleId,
  sale = null,
  handleClose,
  isFromSale = false,
}) => {
  const [selectedTab, setSelectedTab] = useState('appointments-list');
  const [saleData, setSaleData] = useState(sale);
  const [isLoadingSale, setIsLoadingSale] = useState(true);

  const handleLoadSaleBySaleId = async () => {
    try {
      const saleInfo = await salesRepository.getById(saleId);
      setSaleData(saleInfo);
      setIsLoadingSale(false);
    } catch (error) {
      setIsLoadingSale(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isFromSale === true && saleId) {
      handleLoadSaleBySaleId();
    } else {
      setIsLoadingSale(false);
    }
  }, [isFromSale]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-90w appointments-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      {isLoadingSale ? (
        <></>
      ) : (
        <>
          <Modal.Header>
            {saleData.SalesType.Description === 'Ordem de Serviço' ? (
              <div className="appointments-modal-header">
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <p>
                    <strong>OS Nº: </strong>
                    {saleData.Code} - {saleData.Customer.Company_Name}
                    &nbsp;&nbsp;&nbsp;
                  </p>
                  {!!saleData.Vehicle && (
                    <>
                      <p>
                        <strong>Veículo: </strong>
                        {saleData.Vehicle.Model}&nbsp;&nbsp;&nbsp;
                      </p>
                      <p>
                        <strong>Ano Fab/Mod: </strong>
                        {saleData.Vehicle.Year_Manufacture}/
                        {saleData.Vehicle.Year_Model}
                        &nbsp;&nbsp;&nbsp;
                      </p>
                      <p>
                        <strong>KM: </strong>
                        {saleData.Kilometers}
                      </p>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <p>
                    <strong>Data de Início: </strong>
                    {format(new Date(saleData.createdAt), 'dd/MM/yyyy HH:mm')}
                    &nbsp;&nbsp;&nbsp;
                  </p>
                  <p>
                    <strong>Data de Fim: </strong>
                    {saleData.Status === 'Finalizado(a)' &&
                      format(new Date(saleData.updatedAt), 'dd/MM/yyyy HH:mm')}
                  </p>
                </div>
              </div>
            ) : (
              <div className="appointments-modal-header">
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <p style={{ width: '32%' }}>
                    <strong>Venda: </strong>
                    {saleData.Code} - {saleData.Customer.Company_Name}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <p style={{ width: '24.5%' }}>
                    <strong>Data da Venda: </strong>
                    {format(new Date(saleData.Date), 'dd/MM/yyyy HH:mm')}
                  </p>
                </div>
              </div>
            )}
          </Modal.Header>
          <Modal.Body>
            <div>
              <AppointmentsTabSelector
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />

              {selectedTab === 'appointments-list' ? (
                <AppointmentsList
                  sale={saleData}
                  saleId={saleData.id}
                  salesItems={saleData.SalesItems}
                />
              ) : (
                <AppointmentsCreate
                  sale={saleData}
                  saleId={saleData.id}
                  salesItems={saleData.SalesItems}
                />
              )}
            </div>
          </Modal.Body>
        </>
      )}
      <Modal.Footer>
        <Button bsStyle="danger" name="Voltar" onClick={handleClose} fill>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentsModal;
