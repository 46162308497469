import React, { useState } from 'react';
import { format, isValid, subDays } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { SelectMulti } from 'v2/components/Select/Multi';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoicesReportRepository from 'v2/repositories/InvoicesReportRepository';
import './styles.css';
import { useEffect } from 'react';

const statusOptions = [
  {
    label: 'Todos',
    value: '*',
  },
  {
    label: 'Aberta',
    value: 'Aberta',
  },
  {
    label: 'Emitida',
    value: 'Emitida',
  },
  {
    label: 'Cancelada',
    value: 'Cancelada',
  },
  {
    label: 'Contingência',
    value: 'Contingencia',
  },
  {
    label: 'Aberta com erro',
    value: 'Aberta c/ Erro',
  },
  {
    label: 'Denegada',
    value: 'Denegada',
  },
];

const invoicesOptions = [
  {
    label: 'Todos',
    value: '*',
  },
  {
    label: 'NFC-e/SAT',
    value: 'NFCeSAT',
  },
  {
    label: 'NF-e',
    value: 'NFe',
  },
  {
    label: 'NFS-e',
    value: 'NFSe',
  },
];

const HomeInvoices = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState('analytic');
  const [invoiceType, setInvoiceType] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [descriptionInvoiceType, setDescriptionInvoiceType] = useState('');
  const [descriptionInvoiceStatus, setDescriptionInvoiceStatus] = useState('');
  const [initialDate, setInitialDate] = useState(
    format(subDays(new Date(), 30), 'yyyy-MM-dd')
  );
  const [finalDate, setFinalDate] = useState(
    format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')
  );
  const [isReportReady, setReportReady] = useState(false);

  useEffect(() => {
    const invoices = invoiceType
      .filter((item) => item.value !== '*')
      .map((item) => item.value)
      .join(',');

    const status = invoiceStatus
      .filter((item) => item.value !== '*')
      .map((item) => item.value)
      .join(',');
    setDescriptionInvoiceType(invoices);
    setDescriptionInvoiceStatus(status);
  }, [invoiceType, invoiceStatus]);

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setReportReady(false);
    try {
      const data = await InvoicesReportRepository.generateInvoicesReport({
        companyId,
        reportType,
        initialDate,
        finalDate,
        invoiceStatus: descriptionInvoiceStatus,
        invoiceType: descriptionInvoiceType,
      });

      if (data?.totals?.byInvoiceType.length) {
        handlePrint();
      } else {
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    if (reportType === 'synthetic') {
      window.open(
        window.location.origin +
          `/report/invoices-synthetic?companyId=${companyId}&reportType=${reportType}&initialDate=${initialDate}&finalDate=${finalDate}&invoiceType=${descriptionInvoiceType}&invoiceStatus=${descriptionInvoiceStatus}`,
        '_blank'
      );
    } else {
      window.open(
        window.location.origin +
          `/report/invoices-analytical?companyId=${companyId}&reportType=${reportType}&initialDate=${initialDate}&finalDate=${finalDate}&invoiceType=${descriptionInvoiceType}&invoiceStatus=${descriptionInvoiceStatus}`,
        '_blank'
      );
    }
  };

  return (
    <div className="content-invoices-report">
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Nota:</InputLabel>
            <SelectMulti
              width={150}
              height={35}
              options={invoicesOptions}
              value={invoiceType}
              onChange={(e) => setInvoiceType(e)}
              hasAllOption
              selectAllByDefault
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Status:</InputLabel>
            <SelectMulti
              width={150}
              height={35}
              options={statusOptions}
              value={invoiceStatus}
              onChange={(e) => setInvoiceStatus(e)}
              hasAllOption
              selectAllByDefault
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Relatório:</InputLabel>
            <select
              className="form-control foco-input"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              style={{ width: '200px' }}
            >
              <option value="synthetic">Sintético</option>
              <option value="analytic">Analítico</option>
            </select>
          </InputContainer>
        </div>

        <div className="flex flex-start gap-025">
          <InputContainer variant="row">
            <button
              className="component__pdf-button"
              onClick={handleSubmit}
              disabled={loading}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '35px',
                marginTop: '24px',
              }}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="lg"
                style={{ marginRight: '5px' }}
              />
              Exportar .pdf
            </button>
          </InputContainer>
        </div>
      </div>
    </div>
  );
};

export default HomeInvoices;
