import { atom } from 'recoil';
import financingStrings from './strings';

export const FINANCING_CUSTOMER_PHONE = atom({
  key: 'FINANCING_CUSTOMER_PHONE',
  default: '',
});

export const FINANCING_PARTNERID = atom({
  key: 'FINANCING_PARTNERID',
  default: null,
});

export const FINANCING_CURRENT_STEP = atom({
  key: 'FINANCING_CURRENT_STEP',
  default: 1,
});

export const FINANCING_LOADING = atom({
  key: 'FINANCING_LOADING',
  default: true,
});

export const FINANCING_LOADING_MESSAGE = atom({
  key: 'FINANCING_LOADING_MESSAGE',
  default: financingStrings.FINANCING_LOADING_CLIENT,
});

export const FINANCING_TRIGGER_SAVE_SALE = atom({
  key: 'FINANCING_TRIGGER_SAVE_SALE',
  default: false,
});

export const FINANCING_TRIGGER_SAVE_CLIENT = atom({
  key: 'FINANCING_TRIGGER_SAVE_CLIENT',
  default: false,
});

export const FINANCING_TRIGGER_CREATE_TRANSACTION = atom({
  key: 'FINANCING_TRIGGER_CREATE_TRANSACTION',
  default: false,
});

export const FINANCING_SHOW_CANCEL_CONFIRMATION = atom({
  key: 'FINANCING_SHOW_CANCEL_CONFIRMATION',
  default: false,
});

export const FINANCING_KOIN_INSTALLMENTS = atom({
  key: 'FINANCING_KOIN_INSTALLMENTS ',
  default: [],
});

export const FINANCING_KOIN_INCOMING_OPTIONS = atom({
  key: 'FINANCING_KOIN_INCOMING_OPTIONS ',
  default: [],
});

export const FINANCING_KOIN_SELECTED_INCOMING_OPTION = atom({
  key: 'FINANCING_KOIN_SELECTED_INCOMING_OPTION ',
  default: '',
});

export const FINANCING_KOIN_FRAUDID = atom({
  key: 'FINANCING_KOIN_FRAUDID ',
  default: null,
});

export const FINANCING_KOIN_PAYMENTID = atom({
  key: 'FINANCING_KOIN_PAYMENTID ',
  default: null,
});

export const FINANCING_KOIN_TOKEN = atom({
  key: 'FINANCING_KOIN_TOKEN ',
  default: null,
});

export const FINANCING_BOLETOFLEX_INSTALLMENTS = atom({
  key: 'FINANCING_BOLETOFLEX_INSTALLMENTS ',
  default: null,
});

export const FINANCING_BOLETOFLEX_REDIRECT_URL = atom({
  key: 'FINANCING_BOLETOFLEX_REDIRECT_URL',
  default: null,
});

export const FINANCING_TRANSACTIONID = atom({
  key: 'FINANCING_TRANSACTIONID ',
  default: null,
});

export const FINANCING_AMOUNT = atom({
  key: 'FINANCING_AMOUNT ',
  default: null,
});

export const FINANCING_FAILED_MESSAGE = atom({
  key: 'FINANCING_FAILED_MESSAGE',
  default: null,
});

const financingStates = {
  FINANCING_CURRENT_STEP,
  FINANCING_LOADING,
  FINANCING_LOADING_MESSAGE,
  FINANCING_TRIGGER_SAVE_SALE,
  FINANCING_TRIGGER_SAVE_CLIENT,
  FINANCING_SHOW_CANCEL_CONFIRMATION,
  FINANCING_KOIN_PAYMENTID,
  FINANCING_KOIN_FRAUDID,
  FINANCING_KOIN_TOKEN,
  FINANCING_KOIN_INCOMING_OPTIONS,
  FINANCING_KOIN_SELECTED_INCOMING_OPTION,
  FINANCING_BOLETOFLEX_INSTALLMENTS,
  FINANCING_TRANSACTIONID,
  FINANCING_BOLETOFLEX_REDIRECT_URL,
  FINANCING_CUSTOMER_PHONE,
};

export default financingStates;
