import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useForm } from "react-hook-form"

import Form from "./FormNewLossReason";

import lossReasonsRepository from "../../../../../repositories/LossReasons";
import { useAuth } from "contexts/auth";

const FormNewLossReason = ({ onCancel, onSubmit, lossReasonId }) => {

    const [loading, setLoading] = useState(false);
    const { companyId } = useAuth();;

    useEffect(() => {
        if (!!lossReasonId) {
            loadLossReason(lossReasonId);
        }
    }, [lossReasonId]);

    const { register, setValue, handleSubmit, reset, watch, formState: { errors, isDirty } } = useForm({
        defaultValues: {
            description: '',
            observation: '',
            isActive: true,
        }
    })


    const validations = (values) => {
        const { description, observation } = values;

        if (!description) {
            toastr.warning(
                "Por favor, preencha todos os campos obrigatórios para salvar o seu cadastro de motivo de perda."
            );
            return false;
        }

        const checkBlankSpace = (string) => string.trim().length === 0

        if (checkBlankSpace(description) || (observation.length > 0 && checkBlankSpace(observation))) {
            toastr.warning(
                "Insira uma descrição válida para cadastrar o seu motivo de perda e tente novamente"
            );
            return false;
        }

        return true;
    };

    const submit = (values) => {
        values.description = values.description.trim();
        values.observation = values.observation.trim();

        setLoading(true);
        if (!validations(values)) {
            setLoading(false);
            return;
        }
        !!lossReasonId ? update(values) : create(values);
    };

    const create = async (values) => {
        try {
            await lossReasonsRepository.create({
                ...values,
                companyId,
            });

            toastr.success("Motivo de perda cadastrado com sucesso.");
            onSubmit()
        } catch (err) {
            console.log(err);
            return toastr.warning(
                err.response?.data?.message ??
                "Ocorreu um erro ao registrar o motivo de perda. Por favor, tente novamente"
            );
        } finally {
            setLoading(false);
        }
    };

    const update = async (values) => {
        try {
            await lossReasonsRepository.update(lossReasonId, {
                ...values,
                companyId,
            });

            toastr.success("Motivo de perda atualizado com sucesso.");
            onSubmit();
        } catch (err) {
            console.log(err);
            return toastr.warning(
                "Ocorreu um erro ao atualizar o motivo de perda. Por favor, tente novamente"
            );
        } finally {
            setLoading(false);
        }
    };

    async function loadLossReason(id) {
        setLoading(true);

        try {
            const lossReason = await lossReasonsRepository.getById(id);        
            setValue('description', lossReason.data.description);
            setValue('isActive', lossReason.data.isActive);
            setValue('observation', lossReason.data.observation);

        } catch (err) {
            console.log(err)
            toastr.warning(
                "Ocorreu um erro ao carregar os dados do motivo de perda. Por favor, tente novamente"
            );
        } finally {
            setLoading(false);
        }
    }


    return (
        <div style={{ marginTop: 30 }}>
            <Form
                onSubmit={submit}
                loading={loading}
                onCancel={onCancel}
                lossReasonId={lossReasonId}
                register={register}
                watch={watch}
                setValue={setValue}
                handleSubmit={handleSubmit}
                isDirty={isDirty}
            />
        </div>
    );
};

export default FormNewLossReason;
