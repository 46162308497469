import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { faCircle, faEquals } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';

import kitsRepository from 'repositories/Kit';
import pdvRepository from 'repositories/PDVs';
import brandsRepository from 'repositories/Brands';

import { CaptionWithIcon } from 'v2/components/CaptionWithIcon/CaptionWithIcon';
import ProductsRepositoryV1 from 'repositories/Products';

import TableFipeRepository from 'repositories/TableFipe';
import familiesRepository from 'repositories/Families';

import { SearchModalTableWithInfiniteScroll } from '../SearchModalTableWithInfiniteScroll';
import ImagesModal from '../ProductsServicesTable/ImagesModal';
import { getPricesOptions } from 'v2/helpers/priceHelpers';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';

const GeneralSearchModal = ({
  productQueryA,
  isSearchModalOpen,
  setIsSearchModalOpen,
  vehicleModel,
  productQuery,
  additionalInformationQuery,
  handleAddProduct,
  allowPromotionalPrices,
  searchType,
  priceType,
  handleSetType,
  setSearchType,
  isGeneralSearch,
  handleChangeQuerySearch,
}) => {
  const { companyId } = useAuth();
  const { sellingPriceType } = useConfigurationsContext();

  const [brands, setBrands] = useState([]);
  const [manufactures, setManufactures] = useState([]);
  const [families, setFamilies] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);

  const [kits, setKits] = useState([]);
  const [pagesKits, setPagesKits] = useState(0);
  const [kitSearchQuery, setKitSearchQuery] = useState('');

  const [productToVisualize, setProductToVisualize] = useState({});
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [productQueryInput, setProductQueryInput] = useState('');
  const [vehicleModelInput, setVehicleModelInput] = useState('');
  const [yearVehicleInput, setYearVehicleInput] = useState('');
  const [additionalInformationInput, setAdditionalInformationInput] =
    useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalKits, setTotalKits] = useState(0);

  const [loading, setLoading] = useState(true);

  const [hasScrolled, setHasScrolled] = useState(false);

  const [data, setData] = useState([]);

  const [manufacturerDescription, setManufacturerDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [familyDescription, setFamilyDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [selectedPriceType, setSelectedPriceType] = useState(priceType);

  const [kitSearchDebounced, setKitSearchDebounced] = useState('');
  const [yearVehicleDebounced, setYearVehicleDebounced] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [brandDebounced, setBrandDebounced] = useState(brandId);
  const [manufacturerDebounced, setManufacturerDebounced] = useState(
    manufacturerDescription
  );
  const [additionalInformationDebounced, setAdditionalInformationDebounced] =
    useState(additionalInformationQuery);
  const [productQueryDebounced, setProductQueryDebounced] =
    useState(productQuery);
  const [vehicleModelDebounced, setVehicleModelDebounced] =
    useState(vehicleModel);
  const [familyDebounced, setFamilyDebounced] = useState(familyDescription);

  const debouncedSaveFamily = useDebounce(setFamilyDebounced, 1000);
  const debouncedSaveBrand = useDebounce(setBrandDebounced, 1000);
  const debouncedSaveManufacturer = useDebounce(setManufacturerDebounced, 1000);
  const debouncedSaveQuery = useDebounce(setProductQueryDebounced, 1000);
  const debouncedSaveVehicleModel = useDebounce(setVehicleModelDebounced, 1000);
  const debouncedSaveAdditionalInformation = useDebounce(
    setAdditionalInformationDebounced,
    1000
  );
  const debouncedKitSearchQuery = useDebounce(setKitSearchDebounced, 1000);
  const debouncedSearchVehicleYear = useDebounce(setYearVehicleDebounced, 1000);

  function handleChangeProductQuery(value) {
    setProductQueryInput(value);
    debouncedSaveQuery(value);
  }
  function handleKeyPress(e) {
    if (e.key === 'Escape') {
      setIsSearchModalOpen(false);
    }
  }

  function handleClose() {
    setIsSearchModalOpen(false);
  }

  function handleChangeVehicleQuery(value) {
    setVehicleModelInput(value);
    debouncedSaveVehicleModel(value);
  }

  function handleChangeFamily(value) {
    setFamilyDescription(value);
    debouncedSaveFamily(value);
  }
  function handleChangePriceType(value) {
    setSelectedPriceType({ label: value, value: value });
  }

  function handleChangeAdditionalInformationQuery(value) {
    setAdditionalInformationInput(value);
    debouncedSaveAdditionalInformation(value);
  }

  function handleChangeBrand(value) {
    setBrandId(value);
    debouncedSaveBrand(value);
  }

  function handleChangeManufacturer(value) {
    setManufacturerDescription(value);
    debouncedSaveManufacturer(value);
  }

  function handleChangeQuerySearchKit(value) {
    setKitSearchQuery(value);
    debouncedKitSearchQuery(value);
  }

  function handleChangeYear(value) {
    const valueOnlyNumbers = value.replace(/\D/g, '');

    if (value === '') {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');
      return;
    }

    const firstDigit = Number(valueOnlyNumbers[0]);

    if (firstDigit > 2 || firstDigit === 0) {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');

      return toastr.warning(
        'Ano inválido',
        'Insira um ano válido para aplicação e tente novamente'
      );
    }

    setYearVehicleInput(valueOnlyNumbers);
    debouncedSearchVehicleYear(valueOnlyNumbers);
  }

  const handleOpenProductImageModal = async (product) => {
    try {
      const productsPhotos = await ProductsRepositoryV1.getPhotos(
        product.productId
      );

      if (!productsPhotos.length)
        return toastr.warning(
          'Produto sem foto',
          'Nenhuma foto foi identificada para esse produto'
        );

      setProductToVisualize({ ...product, ProductsPhotos: productsPhotos });
      setIsImagesModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as fotos do produto',
        'Por favor, tente novamente'
      );
    }
  };

  const handleFetchMoreData = () => {
    if (
      !isLoadingMore &&
      (currentPage < totalPages || currentPage < pagesKits)
    ) {
      setIsLoadingMore(true);

      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        return nextPage;
      });
    }
  };

  async function loadKits() {
    setLoading(true);

    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: 1,
        page: currentPage,
        limit: pageLimit,
        query: kitSearchDebounced,
      });

      const serializedKits = response.kits.rows.map((kit) => ({
        Products: response.kitItemsProducts.filter(
          (product) => product.kitId === kit.id
        ),
        Services: response.kitItemsServices.filter(
          (service) => service.kitId === kit.id
        ),
        productDescription: kit.description,
        productSalePrice: kit.priceTotal,
        productCode: '---',
        productManufacturerRef: '---',
        productBarCode: kit.barCode,
        productQuantity: '---',
        brandDescription: '---',
        vehicleModel: '---',
        productLocation: '---',
      }));

      if (currentPage === 1) {
        setKits(serializedKits);
      } else {
        setKits([...kits, ...serializedKits]);
      }
      setTotalKits(response.kits.count);
      setPagesKits(Math.ceil(response.kits.count / pageLimit));
    } catch (err) {
      console.error(err);
      if (!kits) {
        toastr.warning(
          'Ocorreu um erro ao buscar os kits. Por favor, tente novamente.'
        );
      }
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }

  const handleSearchProducts = async () => {
    setLoading(true);
    try {
      const isAllBrandIdSelected = brandId.some((brand) => brand.value === '*');
      const brandIds = brandId
        .filter((brand) => brand.value !== '*')
        .map((brand) => brand.value)
        .join(',');

      const isAllFamilyDescriptionSelected = familyDescription.some(
        (family) => family.value === '*'
      );

      const familiesDescriptions = familyDescription
        .filter((family) => family.value !== '*')
        .map((family) => family.value)
        .join(',');

      const isAllManufacturerDescriptionSelected = manufacturerDescription.some(
        (manufacturer) => manufacturer.value === '*'
      );
      const manufacturesDescriptions = manufacturerDescription
        .filter((manufacturer) => manufacturer.value !== '*')
        .map((manufacturer) => manufacturer.value)
        .join(',');

      const params = {
        limit: pageLimit,
        page: currentPage,
        productQueryA: productQueryA ? productQueryA : productQueryDebounced,
        vehicleModelA: vehicleModel ? vehicleModel : vehicleModelDebounced,
        productQueryB: productQueryDebounced,
        vehicleModelB: vehicleModelDebounced,
        additionalInformation: additionalInformationDebounced,
        groupBy: true,
      };

      if (!isAllBrandIdSelected && brandIds.length !== 0) {
        params.brandsIds = `[${brandIds}]`;
      }

      if (
        !isAllManufacturerDescriptionSelected &&
        manufacturesDescriptions.length !== 0
      ) {
        params.manufacturesDescriptions = `[${manufacturesDescriptions}]`;
      }

      if (
        !isAllFamilyDescriptionSelected &&
        familiesDescriptions.length !== 0
      ) {
        params.familiesDescriptions = `[${familiesDescriptions}]`;
      }

      if (!!yearVehicleDebounced) {
        params.yearVehicle = yearVehicleDebounced;
      }

      const response = await pdvRepository.getProducts(companyId, params);
      setTotalProducts(response.count);

      if (currentPage === 1) {
        setData(response.products);
      } else {
        setData((prevData) => [...prevData, ...response.products]);
      }
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível realizar a busca de produtos.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleResetSearch = () => {
    setKits([]);
    setKitSearchDebounced('');
    setProductQueryDebounced('');
    setVehicleModelDebounced('');
    setProductQueryInput('');
    setVehicleModelInput('');
    setAdditionalInformationInput('');
    setKitSearchQuery('');
    setYearVehicleInput('');
    setManufacturerDescription(manufactures);
    setManufacturerDebounced(manufactures);
    setBrandId(brands);
    setBrandDebounced(brands);
    setFamilyDebounced(families);

    if (handleChangeQuerySearch) {
      handleChangeQuerySearch('');
    }
  };

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands]
      );

      serializedBrands.unshift({ value: '*', label: 'Todas' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadManufacturers = async () => {
    try {
      const manufactures = await TableFipeRepository.getBrands();

      const serializedManufactures = manufactures.data.map(
        (brand) => ({
          label: brand.Brand,
          value: brand.Brand,
        }),
        [manufactures]
      );

      serializedManufactures.unshift({ value: '*', label: 'Todas' });
      setManufactures(serializedManufactures);
      setManufacturerDescription(serializedManufactures);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadFamilies = async () => {
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        );

      const serializedFamilies = families.map(
        (family) => ({
          label: family.Description,
          value: family.id,
        }),
        [families]
      );

      serializedFamilies.unshift({ value: '*', label: 'Todas' });
      setFamilies(serializedFamilies);
      setFamilyDescription(serializedFamilies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    if (isLoadingMore) {
      if (searchType === 'Produtos') {
        handleSearchProducts().finally(() => setIsLoadingMore(false));
      } else {
        loadKits().finally(() => setIsLoadingMore(false));
      }
      setHasScrolled(false);
    }
  }, [isLoadingMore, searchType]);

  useEffect(() => {
    loadBrands();
    loadManufacturers();
    loadFamilies();
    setPriceTypes(getPricesOptions(sellingPriceType, false, false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoadingMore(true);
  }, [
    productQueryDebounced,
    vehicleModelDebounced,
    familyDebounced,
    additionalInformationDebounced,
    brandDebounced,
    manufacturerDebounced,
    searchType,
    kitSearchDebounced,
    yearVehicleDebounced,
  ]);

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        show={isSearchModalOpen}
        backdrop={false}
        onHide={() => setIsSearchModalOpen(false)}
        onKeyDown={handleKeyPress}
        animation
      >
        <Modal.Body>
          <SearchModalTableWithInfiniteScroll
            loading={loading}
            isGeneralSearch={isGeneralSearch}
            handleClose={handleClose}
            data={data}
            totalProducts={totalProducts}
            totalKits={totalKits}
            totalPages={totalPages}
            currentPage={currentPage}
            brandId={brandId}
            manufacturerDescription={manufacturerDescription}
            productQuery={productQueryInput}
            setProductQueryDebounced={setProductQueryDebounced}
            vehicleModel={vehicleModelInput}
            additionalInformation={additionalInformationInput}
            familyDescription={familyDescription}
            families={families}
            selectedPriceType={selectedPriceType}
            priceTypes={priceTypes}
            priceType={priceType}
            handleSetType={handleSetType}
            handleChangeFamily={handleChangeFamily}
            handleChangePriceType={handleChangePriceType}
            setVehicleModelDebounced={setVehicleModelDebounced}
            handleChangeProductQuery={handleChangeProductQuery}
            handleChangeVehicleQuery={handleChangeVehicleQuery}
            handleChangeAdditionalInformationQuery={
              handleChangeAdditionalInformationQuery
            }
            handleChangeBrand={handleChangeBrand}
            handleChangeManufacturer={handleChangeManufacturer}
            handleAddItem={handleAddProduct}
            allowPromotionalPrices={allowPromotionalPrices}
            handleChangeQuerySearchKit={handleChangeQuerySearchKit}
            kits={kits}
            pagesKits={pagesKits}
            searchType={searchType}
            setSearchType={setSearchType}
            kitSearchQuery={kitSearchQuery}
            brands={brands}
            manufactures={manufactures}
            handleChangeYear={handleChangeYear}
            yearVehicle={yearVehicleInput}
            handleOpenProductImageModal={handleOpenProductImageModal}
            handleSearchProducts={handleSearchProducts}
            isLoadingMore={isLoadingMore}
            handleFetchMoreData={handleFetchMoreData}
            hasScrolled={hasScrolled}
            setHasScrolled={setHasScrolled}
            hideSearchTypeSelect
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 flex between">
            <div className="w-100 flex column start align-start">
              <CaptionWithIcon icon={faCircle} color="#d9534f">
                Produtos com estoque zerado ou negativo
              </CaptionWithIcon>
              <CaptionWithIcon icon={faCircle} color="#4cae4c">
                Produtos com preço promocional
              </CaptionWithIcon>
              <CaptionWithIcon icon={faEquals} color="#4cae4c">
                Produtos com similaridade
              </CaptionWithIcon>
            </div>
            <div className="flex between gap-050 w-250px">
              <button
                className="button button-yellow button-h35 flex center"
                onClick={handleResetSearch}
                disabled={isLoadingMore}
              >
                <span className="flex center">Limpar Pesquisa</span>
              </button>
              <button
                className="button button-red button-h35 flex center"
                onClick={() => setIsSearchModalOpen(false)}
              >
                <span>Voltar(ESC)</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={productToVisualize.Description}
          productPhotos={productToVisualize.ProductsPhotos}
        />
      )}
    </>
  );
};

export default GeneralSearchModal;
