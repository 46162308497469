import config from "../config"
import api from "../services/api"

const getByUser = async userId => {
  try {

    const response = await api.get(config.endpoint + `signature/Search/ByUser/${userId}`)

    if (!response.data.status) {
      throw new Error('Ocorreu um erro ao buscar a assinatura')
    }

    return response.data.data

  } catch (err) {
    throw err
  }
}

const getByCompany = async companyId => {
  try {

    const response = await api.get(config.endpoint + `signature/Search/ByCompany/${companyId}`);
    if (!response.data.status) {
      throw new Error('Ocorreu um erro ao buscar a assinatura')
    }

    return response.data.data

  } catch (err) {
    throw err
  }
}

const getByUserWithPayment = async userId => {
  return api.get(config.endpoint + `signature/by-user/${userId}`)
}

const cancelRecurringPayment = async ({ signatureId, transactionNumber }) => {
  await api.post(config.endpoint + `signaturepayment/cancel-recurring-payment/${transactionNumber}`, {
    Signature_id: signatureId
  });
}

const update = async (id, signature) => {
  try {
    const response = await api.put(config.endpoint + `signature/${id}`, signature)
    if (!response.data.status) {
      throw new Error('Ocorreu um erro ao atualizar assinatura')
    }
    return response.data.message
  } catch (err) {
    throw err
  }

}

const create = async params => {
  try {

    const { usersQuantity, User_id, Company_id, Days, Months, Plan_id, signaturePeriod,
      Observation, SignatureStatus_id, SignaturePayment_id, startDate, endDate } = params

    const response = await api.post(config.endpoint + `signature`, {
      usersQuantity, User_id, Company_id, Days, Months, Plan_id, signaturePeriod,
      Observation, SignatureStatus_id, SignaturePayment_id, startDate, endDate
    })

    return response.data.data

  } catch (err) {
    throw err
  }
}

const cancelOldSignatures = (companyId, currentSignatureId) =>
  api.put(`signature/cancel-old-signatures/${companyId}`, null, {
    params: {
      currentSignatureId,
    },
  })

export default {
  getByUserWithPayment,
  getByUser,
  getByCompany,
  cancelRecurringPayment,
  update,
  create,
  cancelOldSignatures,
}