import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { change, initialize } from 'redux-form'

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../../components/CustomButton/CustomButton.jsx'
import SettingsNFSe from '../../../MyCompanyTaxData/FormCompanyTaxData/SettingsNFSe'
import settingsNFSeRepository from '../../../../../repositories/SettingsNFSe'
import { useAuth } from '../../../../../contexts/auth.js'

const SettingsNFSeErrorsTable = ({ errors, onCorrection }) => {
  const [settingsNFSeErrors, setSettingsNFSeErrors] = useState([])
  const [indexToEdit, setIndexToEdit] = useState(null)
  const [isSettingsNFSeModalOpen, setIsSettingsNFSeModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  useEffect(() => {
    setSettingsNFSeErrors(errors)
  }, [errors])

  async function loadNFSeSettings() {
    try {
      const settingsNFSe = await settingsNFSeRepository.search({
        companyId: companyId
      })
      const { CMCCode, CNAE, IM, ISS, environment, id, lastNFSeSent, municipalTaxCode,
        printingTemplate, serie, serviceListItem, serviceLocationCode, specialTaxRegimeCode,
        userAccess, userPassword, NFSPatterns, taxType, operationType, NFSPatternId } = settingsNFSe
  
      const data = {
        settingsNFSeId: id,
        lastNFSeSent: String(lastNFSeSent),
        municipalTaxCode,
        CMCCode,
        CNAE,
        IM,
        ISS,
        environment,
        printingTemplate,
        serie,
        serviceListItem,
        serviceLocationCode: String(serviceLocationCode),
        specialTaxRegimeCode: String(specialTaxRegimeCode),
        userAccess,
        userPassword,
        pattern: NFSPatterns.pattern,
        taxType,
        operationType,
        NFSPatternId
      }

      dispatch(initialize('settingsNFSe', data))

    } catch (err) {
      console.error(err)
      toastr.warning('Ocorreu um erro ao buscar os dados fiscais da empresa. Por favor, tente novamente')
    }
  }

  async function handleOpenSettingsNFSeModal(index) {
    await loadNFSeSettings()
    setIndexToEdit(index)
    setIsSettingsNFSeModalOpen(true)
  }

  function handleSubmitSettingsNFSeModal() {
    const newErrors = [...settingsNFSeErrors]
    newErrors[indexToEdit].isResolved = true

    setIsSettingsNFSeModalOpen(false)
    onCorrection(newErrors)
    loadNFSeSettings()
  }

  return (
    <>
      <ReactTable
        className='settingsNFSe-errors-table'
        data={errors}
        columns={[
          {
            Header: '',
            accessor: 'isResolved',
            headerClassName: 'text-left',
            width: 50,
            Cell: props => (
              <FontAwesomeIcon
                icon={props.value ? faCheckCircle : faTimesCircle}
                color={props.value ? 'green' : 'red'}
                size={30}
              />
            )
          },
          {
            Header: 'Item',
            accessor: 'item',
            headerClassName: 'text-left',
            width: 250
          },
          {
            Header: 'Erro',
            accessor: 'error',
            headerClassName: 'text-left',
            width: 250
          },
          {
            Header: 'Correção',
            accessor: 'correction',
            headerClassName: 'text-left',
            Cell: props => (
              <span
                className='hiperlink'
                onClick={() => handleOpenSettingsNFSeModal(props.index)}
              >
                {props.value}
              </span>
            )
          }
        ]}
        defaultPageSize={5}
        sortable={false}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText=''
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      <Modal
        dialogClassName='edit-settings-nfse-modal-wrapper'
        show={isSettingsNFSeModalOpen}
        onHide={() => setIsSettingsNFSeModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Configurações NFSe</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-settings-nfse-content'>
          <SettingsNFSe
            isNFSePatternAvailable
            onSubmit={handleSubmitSettingsNFSeModal}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            bsStyle="danger"
            fill
            onClick={() => setIsSettingsNFSeModalOpen(false)}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SettingsNFSeErrorsTable