import api from '../services/api'
import config from '../config'

const emit = (saleId, companyId, bankBilletAccountId) =>
  api.post(`bank-slip/by-sale/${saleId}`, {
    companyId,
    bankBilletAccountId,
  })

const emitByTitle = (
  billId,
  { companyId, bankBilletAccountId, formOfPaymentId }
) =>
  api.post(`bank-slip/${billId}`, {
    companyId,
    bankBilletAccountId,
    formOfPaymentId,
  })

const update = (billId, bankSlipId, { value, dueDate }) =>
  api.put(
    `/bank-slip/${billId}/${bankSlipId}`,
    { value, dueDate },
    { validateStatus: () => true }
  )

const cancel = (bankSlipId) => api.delete(`/bank-slip/${bankSlipId}`)

export default {
  emit,
  emitByTitle,
  update,
  cancel,
}
