import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { currency } from '../../../client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';

const Header = ({ initialDate, finalDate, typeOfDate, statusSlip }) => {
  return (
    <View style={{ width: '60%', flexDirection: 'row' }}>
      <View
        style={{
          flexDirection: 'column',
          width: '30%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Data: </Text>
          </View>
          <View>
            <Text>{typeOfDate}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Boleto: </Text>
          </View>
          <View>
            <Text>{statusSlip}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '70%',
          marginBottom: '5px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={{ marginRight: '5px' }}>
            <Text>{initialDate}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text>{finalDate}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TotalizatorsItemsPaidHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View style={{ width: '20%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Tipo</Text>
        </View>
        <View style={{ width: '5%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>QTD</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Subtotal</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Juros</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Total</Text>
        </View>
      </View>
      <View style={styles.line}></View>
    </>
  );
};

const TotalizatorsItemsPaidContent = ({ data }) => {
  return (
    <>
      {data.totalizatorsPaid ? (
        data.totalizatorsPaid.map((item, i) => (
          <View
            key={i}
            style={{ flexDirection: 'row', height: 15, width: '100%' }}
          >
            <View
              style={{
                width: '20%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.description}</Text>
            </View>
            <View
              style={{
                width: '5%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.quantity}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.subTotalByType)}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.feeByType)}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.totalByType)}</Text>
            </View>
          </View>
        ))
      ) : (
        <View></View>
      )}
      <View style={[styles.line]} />
      <View
        style={{
          flexDirection: 'row',
          height: 15,
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <View
          style={{
            width: '20%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>Total</Text>
        </View>
        <View
          style={{
            width: '5%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {data.generalInformationPaid &&
              data.generalInformationPaid.quantityByStatusOpen}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationPaid &&
                data.generalInformationPaid.subTotalByStatusOpen
            )}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationPaid &&
                data.generalInformationPaid.feeByStatusOpen
            )}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationPaid &&
                data.generalInformationPaid.totalByStatusOpen
            )}
          </Text>
        </View>
      </View>
    </>
  );
};

const TotalizatorsItemsOpenHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View style={{ width: '20%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Tipo</Text>
        </View>
        <View style={{ width: '5%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>QTD</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Subtotal</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Juros</Text>
        </View>
        <View style={{ width: '10%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Total</Text>
        </View>
      </View>
      <View style={styles.line}></View>
    </>
  );
};

const TotalizatorsItemsOpenContent = ({ data }) => {
  return (
    <>
      {data.totalizatorsOpen ? (
        data.totalizatorsOpen.map((item, i) => (
          <View
            key={i}
            style={{ flexDirection: 'row', height: 15, width: '100%' }}
          >
            <View
              style={{
                width: '20%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.description}</Text>
            </View>
            <View
              style={{
                width: '5%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.quantity}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.subTotalByType)}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.feeByType)}</Text>
            </View>
            <View
              style={{
                width: '10%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.totalByType)}</Text>
            </View>
          </View>
        ))
      ) : (
        <View></View>
      )}
      <View style={[styles.line]} />
      <View
        style={{
          flexDirection: 'row',
          height: 15,
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <View
          style={{
            width: '20%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>Total</Text>
        </View>
        <View
          style={{
            width: '5%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {data.generalInformationOpen &&
              data.generalInformationOpen.quantityByStatusOpen}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationOpen &&
                data.generalInformationOpen.subTotalByStatusOpen
            )}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationOpen &&
                data.generalInformationOpen.feeByStatusOpen
            )}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.strong}>
            {currency(
              data.generalInformationOpen &&
                data.generalInformationOpen.totalByStatusOpen
            )}
          </Text>
        </View>
      </View>
    </>
  );
};

const IssuedSlipListItemsHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View style={{ width: '9%', justifyContent: 'center' }}>
          <Text style={styles.headerTitle}>Vencimento</Text>
        </View>
        <View
          style={{
            width: '5%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Título</Text>
        </View>
        <View
          style={{
            width: '5%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Venda</Text>
        </View>
        <View
          style={{
            width: '13%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Cliente</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Valor</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Juros</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Multa</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Total</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Dt. de criação</Text>
        </View>
        <View
          style={{
            width: '9%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Dt. de pgto</Text>
        </View>
        <View
          style={{
            width: '13%',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Carteira</Text>
        </View>
      </View>
      <View style={styles.line}></View>
    </>
  );
};

const IssuedSlipListItemsContent = ({ data }) => {
  return (
    <>
      {data && data.issuedSlipsList ? (
        data.issuedSlipsList.map((item, i) => (
          <View
            key={i}
            style={{ flexDirection: 'row', height: 15, width: '100%' }}
          >
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {format(new Date(item.dueDate), 'dd/MM/yyyy')}
              </Text>
            </View>
            <View
              style={{
                width: '5%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.billsToReceiveCode}</Text>
            </View>
            <View
              style={{
                width: '5%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.saleCode}</Text>
            </View>
            <View
              style={{
                width: '13%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {item.customerTradingName || item.customerCompanyName}
              </Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {item.subTotal && currency(item.subTotal)}
              </Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {item.feeTotal ? currency(item.feeTotal) : currency(0)}
              </Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(item.fine)}</Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {item.liquidValue && currency(item.liquidValue)}
              </Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {format(new Date(item.issuedAt), 'dd/MM/yyyy')}
              </Text>
            </View>
            <View
              style={{
                width: '9%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {item.lowDate
                  ? format(new Date(item.lowDate), 'dd/MM/yyyy')
                  : ''}
              </Text>
            </View>
            <View
              style={{
                width: '13%',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{item.nameBank}</Text>
            </View>
          </View>
        ))
      ) : (
        <View></View>
      )}
    </>
  );
};

export const IssuedSlipDocument = ({
  initialDate,
  finalDate,
  typeOfDate,
  statusSlip,
  result,
}) => {
  return (
    <Document
      title={`boletos-emitidos${format(new Date(), 'dd/MM/yyyy')}`}
      subject="boletos"
    >
      <Page style={styles.page} orientation="landscape">
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '5px',
          }}
        >
          <View
            style={{
              width: '40%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Boletos Emitidos</Text>
          </View>

          <Header
            initialDate={initialDate}
            finalDate={finalDate}
            typeOfDate={
              typeOfDate === 'issuedAt'
                ? 'Criação'
                : typeOfDate === 'lowDate'
                ? 'Pagamento'
                : typeOfDate === 'dueDate'
                ? 'Vencimento'
                : 'Vencimento'
            }
            statusSlip={
              statusSlip === 'allSlip'
                ? 'Abertos - Todos'
                : statusSlip === 'allExpiredSlip'
                ? 'Abertos - Vencidos'
                : statusSlip === 'allPaidSlip'
                ? 'Pagos'
                : 'Todos'
            }
          />
        </View>

        <View style={[styles.line]} />
        <View>
          <Text style={styles.subtitle}>Boletos emitidos por status</Text>
        </View>
        <View style={[styles.line]} />

        {result && result.generalInformationPaid ? (
          <View>
            <TotalizatorsItemsPaidHeader />
            <TotalizatorsItemsPaidContent data={result} />
          </View>
        ) : (
          <View></View>
        )}

        {result && result.generalInformationOpen ? (
          <View>
            <TotalizatorsItemsOpenHeader />
            <TotalizatorsItemsOpenContent data={result} />
          </View>
        ) : (
          <View></View>
        )}

        <View style={[styles.line]} />

        <View>
          <Text style={styles.subtitle}>Listagem de boletos</Text>
        </View>

        <View style={[styles.line]} />

        <IssuedSlipListItemsHeader />
        <IssuedSlipListItemsContent data={result} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  containerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  content: {
    fontSize: 10,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  debitsText: {
    fontSize: 10,
    color: '#FF0000',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
  headerTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
});
