import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Input, InputContainer } from './SearchInput.styles';

const defaultIcon = faSearch;

const SearchInput = React.forwardRef(
  (
    { icon = defaultIcon, width, height, margin, background, ...props },
    ref
  ) => {
    return (
      <InputContainer
        width={width}
        height={height}
        margin={margin}
        background={background}
      >
        <FontAwesomeIcon icon={icon} color="#808080" />
        <Input type={'text'} ref={ref} {...props} />
      </InputContainer>
    );
  }
);

export { SearchInput };
