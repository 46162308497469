import api from 'v2/services/api';

const generateListCanceledItemsReport = async (params) => {
  try {
    const response = await api.get(`report/sales-canceled-items`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const ListCanceledItemsReportRepository = {
  generateListCanceledItemsReport,
};

export default ListCanceledItemsReportRepository;
