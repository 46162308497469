import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { Col, Row } from 'react-bootstrap'

import productsRepository from '../../../repositories/Products'
import useDebounce from '../../../hooks/useDebounce';
import Button from "client/components/CustomButton/CustomButton.jsx";

const SelectProductModal = ({ show, handleCancel, selectedProduct, onSelect, onUpdate, loading }) => {
  const [products, setProducts] = useState([])
  const [productSuggestions, setProductSuggestions] = useState([])
  const [searchProduct, setSearchProduct] = useState('')  
  const [product, setProduct] = useState({
    id: '',
    code: '',
    description: '',
    manufacturerRef: '',
    productProviderCode: ''
  })

  const companyId = localStorage.getItem('ID_EMPRESA')

  const debouncedSaveQuerySearch = useDebounce(setSearchProduct, 800) 
  const [query, setQuery] = useState('');


  useEffect(() => {
    if (!!selectedProduct) {
      setProduct(selectedProduct)
    } else if (!!companyId) {
      loadProducts()
    }
  }, [])

  useEffect(() => {
    if (!!query) {
      loadProducts();
    }
  }, [searchProduct])

  useEffect(() => {
    handleProductsSuggestions()
  }, [products]);

  const loadProducts = async () => {
    try {  
      const products = await productsRepository.getAllPaginatedToAddItem({
        Company_id: companyId, query
      })
      setProducts(products.data.map(product => {
        return ({
          id: product.id,
          code: product.Code,
          description: product.Description,
          manufacturerRef: product.Manufacturer_Ref,
        })          
      }))
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar os produtos. Por favor tente novamente')
    }
  }

  const handleSelectProduct = e => {
    const querySearch = e.target.value
    if (!querySearch) return

    const selectedProductCode = querySearch.split('|')[0].trim()
 
    if (!selectedProductCode) return

    const product = products.find(product => String(product.code) === String(selectedProductCode))
    
    if (!!product) {
      setProduct({
        id: product.id,
        code: product.code,
        description: product.description,
        manufacturerRef: product.manufacturerRef,
        productProviderCode: ''
      })

      setSearchProduct('')
    }
  }
  
  const handleSubmitS = () => {  
    if (!product.id || !product.productProviderCode) return

    if (!selectedProduct) {
      onSelect(product)
    } else {
      onUpdate(product)
    }
  }

  const handleChange = e => {
    const newProduct = {
      ...product
    }
    newProduct[e.target.name] = e.target.value

    setProduct(newProduct)
  }

  function handleChangeSearchQuery(value) {
    setQuery(value)
    debouncedSaveQuerySearch(value)
  }

  async function handleProductsSuggestions() {
    const suggestions = products.map((product) => (`${product.code} | ${product.description} | ${product.manufacturerRef ? product.manufacturerRef : '' }`))

    setProductSuggestions(suggestions)
  }


  return (
    <Modal show={show} dialogClassName="modal-provider-product" size='lg' onHide={handleCancel} animation>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{!!selectedProduct ? 'Editar Item' : 'Adicionar Item'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div id='modal-provider-product-body'>
          {
            !selectedProduct && (
              <>
                <i id="iconepesquisar" style={{ cursor: 'pointer' }} className="fa fa-search" />
                <input
                  type="text"
                  style={{ paddingLeft: '5%' }}
                  className="form-control"
                  placeholder="Pesquisa por Código Interno, Código do Fornecedor ou Descrição"
                  value={query}
                  onChange={(e) => handleChangeSearchQuery(e.target.value)}
                  onSelect={handleSelectProduct}
                  list='product-lists'
                />
                <datalist id='product-lists'>
                  {productSuggestions.map(suggestion => <option key={suggestion} value={suggestion} />)}
                </datalist>
              </>
            )
          }

          <Row>
            <Col xs={12} sm={12} md={2} lg={2}>
              <label htmlFor='code'>Código Interno: </label>
              <input
                id='code'
                type="text"
                value={product.code}
                name='code'
                onChange={handleChange}
                className='form-control foco-input'
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <label htmlFor='description'>Descrição: </label>
              <input
                id='description'
                value={product.description}
                name='description'
                onChange={handleChange}
                type="text"
                className='form-control foco-input'
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <label htmlFor='manufactureCode'>Código do Fabricante: </label>
              <input
                id='manufactureCode'
                value={product.manufacturerRef}
                name='manufacturerRef'
                onChange={handleChange}
                type="text"
                className='form-control foco-input'
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <label htmlFor='productProviderCode'>Código do Fornecedor: </label>
              <input
                id='productProviderCode'
                value={product.productProviderCode}
                name='productProviderCode'
                onChange={handleChange}
                type="text"
                className='form-control foco-input'
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button bsStyle="danger" fill onClick={handleCancel}>Cancelar</Button>
          <Button bsStyle="info" fill onClick={handleSubmitS} ><span className={loading ? "fa fa-spinner fa-pulse fa-1x" : ''} />Salvar</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectProductModal