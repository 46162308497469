import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { isValid, format, addDays, isBefore } from 'date-fns'

import './styles.css'

import lossReasonsRepository from '../../../../../repositories/LossReasons'
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { useHistory } from 'react-router-dom';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

export const HomeCanceledBudgets = () => {
  const [lossReasons, setLossReasons] = useState([])
  const [lossReasonId, setLossReasonId] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [reportData, setReportData] = useState([])

  const { companyId } = useAuth()
  const { isPlanFree, isPlanStart } = usePlanSignatureContext()

  const history = useHistory()

  useEffect(() => {
    if (!!companyId) loadLossReasons()
  }, [companyId])

  useEffect(() => {
    setReportData([])
  }, [lossReasonId, initialDate, finalDate])

  useEffect(() => {
    if (isPlanFree || isPlanStart) history.goBack()
  },[isPlanFree, isPlanStart, history])

  const loadLossReasons = async () => {
    setLoading(true)
    try {
      const { data } = await lossReasonsRepository.getAllByCompany({ companyId })
      console.log(data)
      setLossReasons(data)
    } catch (err) {
      toastr.error(err?.response?.data?.message || 'Ocorreu algum erro ao buscar Motivos. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (validations()) handleProcess()
  }

  const validations = () => {
    if (!lossReasonId) {
      toastr.warning('Selecione o motivo para gerar o relatório.')
      return false
    }

    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate))
    ) {
      toastr.warning('Selecione um período válido para gerar o relatório.')
      return false
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.')
      return false
    }

    return true
  }

  const handleProcess = async () => {
    setLoading(true)
    try {
      handlePrint()
    } catch (err) {
      toastr.error(err?.response?.data?.message || 'Ocorreu algum erro ao buscar orçamentos. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    const lossReasonIdHash = encrypt(lossReasonId, '@OS-dig:lossReasonId')
    window.open(window.location.origin + `/report/canceled-budgets?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&lossReasonId=${lossReasonIdHash}`, '_blank')
  }

  const handleArchiveName = () => {
    let name = ''
    let initialDateName = ''
    let finalDateName = ''
    if (!!lossReasonId) {
      const lossReason = lossReasons.find(({ id }) => id === Number(lossReasonId))
      // name = lossReason?.description
    }

    if (isValid(new Date(initialDate))) initialDateName = format(addDays(new Date(initialDate), 1), 'dd-MM-yyyy')
    if (isValid(new Date(finalDate))) finalDateName = format(addDays(new Date(finalDate), 1), 'dd-MM-yyyy')

    return `Comissão_${name}_${initialDateName}_${finalDateName}`
  }

  const archiveName = handleArchiveName()

  return (
    <main className="sales-canceled-budgets-report">
      <section>
        <label>Motivo:</label>
        <select
          className="form-control foco-input"
          value={lossReasonId}
          onChange={(e) => {
            setLossReasonId(e.target.value)
            }
          }
        >
          {
            loading === true ? <option value="" disabled selected>Carregando...</option> : 
                               <option value="" disabled selected>Selecione o motivo</option>
          }
          {
            lossReasons.length === 0 ? <option value="" disabled selected>Nenhum motivo encontrado</option> : null
          }
          {
            lossReasons.length > 1 ? <option value="all">Todos</option> : null
          }
          {
            lossReasons.map(({ id, description }) => (
              <option key={id} value={id}>{description}</option>
            ))
          }
        </select>
      </section>
      <section>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={e => setInitialDate(e.target.value)}
        />
      </section>
      <section>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={e => setFinalDate(e.target.value)}
        />
      </section>
      <section>
        <button
          className="btn btn-sucesso"
          onClick={handleSubmit}
          disabled={loading}
        ><span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} />Gerar Relatório</button>
      </section>
    </main>
  )
}