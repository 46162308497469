import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../contexts/auth";
import NewCostCenter from "./NewCostCenter";

import { toastr } from "react-redux-toastr";

import costCenterRepository from "../../../../../repositories/CostCenter";
import constants from "utils/constants";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { initialize } from "redux-form";

const FormCostCenter = ({ costCenterId, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch()

  const { companyId } = useAuth();

  useEffect(() => {
    if (!!costCenterId) {
      loadCostCenter()
    }
  }, [costCenterId])

  const submit = (values) => {
    if (!validations(values))
      return toastr.warning(
        "Preencha todos os campos obrigatórios para salvar o cadastro"
      );
    !!costCenterId ? update(values) : create(values);
  };

  const validations = (values) => {
    const { description } = values;

    if (!description) return false;

    return true;
  };

  const create = async (values) => {
    setLoading(true);
    try {
      await costCenterRepository.store({
        ...values,
        companyId,
      });

      toastr.success("Centro de Custo criado com sucesso.");
      history.push(constants.ROUTES.COST_CENTERS);
    } catch (err) {
      console.log(err);
      if (err.response?.data?.message) {
        return toastr.warning(err.response.data.message);
      } else {
        return toastr.error(
          "Ocorreu algum problema",
          "Por favor, tente mais tarde"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const loadCostCenter = async () => {
    try {
      const { data } = await costCenterRepository.getById(costCenterId)
      dispatch(initialize("formCostCenter", data))
    } catch (err) {
      toastr.warning("Erro ao carregar as informações do Centro de Custo.")
    }
  }

  const update = async (values) => {
    setLoading(true);
    try {
      await costCenterRepository.update({
        ...values,
        companyId
      });

      toastr.success("Centro de Custo atualizado com sucesso.");
      return onSubmit();
    } catch (err) {
      if (err.response?.data?.message) {
        return toastr.warning(err.response.data.message);
      } else {
        return toastr.error(
          "Ocorreu algum problema",
          "Por favor, tente mais tarde"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewCostCenter
      onSubmit={submit}
      initialValues={{ isActive: true }}
      loading={loading}
      costCenterId={costCenterId}
    />
  );
};

export default FormCostCenter;
