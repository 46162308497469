import config from '../config'
import api from '../services/api'

const generateReportOfSalesByPassages = async (companyId, params) => {
  const response = await api.get(`/report/sales-passing/${companyId}`, {
    params,
  })
  return response.data
}

const getAllVehiclesByCustomer = async (customerId) => {
  const response = await api.get(
    config.endpoint + `customer/${customerId}/vehicles`
  )
  return response.data
}

const getAllActiveByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `customer?Status=Ativo&Company_id=${companyId}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `customer?Company_id=${companyId}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const getCustomer = async (customerId) => {
  try {
    const response = await api.get(
      config.endpoint + `customer/byid/${customerId}`
    )

    return response.data.data
  } catch (err) {
    throw err
  }
}

const getCustomerByCpfCnpj = async (Cpf_Cnpj) => {
  try {
    const response = await api.get(
      config.endpoint + `customer/search/${Cpf_Cnpj}`
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

const show = async (id) => {
  const response = await api.get(config.endpoint + `customer/byid/${id}`)
  if (!response.data.status) {
    throw new Error(
      'Ocorreu um erro ao buscar o cliente. Por favor, tente novamente'
    )
  }

  return response.data.data
}

const create = async (customer) => {
  const response = await api.post(config.endpoint + 'customer', customer)
  return response.data.customer
}

const update = async (customerId, customer) => {
  const response = await api.put(
    config.endpoint + `customer/${customerId}`,
    customer
  )
  return response.data
}

const getByCompanyAndCpfCnpj = async ({ cpfCnpj, companyId }) => {
  const response = await api.get(
    config.endpoint + `customer/cnpjByCompany/${cpfCnpj}/${companyId}`
  )
  return response.data
}

const getClientByIdForChecklistSheet = async (clientId) => {
  return await api.get(config.endpoint + `customer/${clientId}/checklist-sheet`)
}

const importCustomers = async (params) => {
  const response = await api.post(config.endpoint + 'customer/import', params)
  return response.data
}

const getCustomerList = async (companyId, params) => {
  const { data } = await api.get(`customer/list/by-company/${companyId}`, {
    params,
  })
  return data
}

const verifyCustomerExclusionEligibility = async ({
  customerId,
  companyId,
}) => {
  const response = await api.get(
    `customer/verify/exclusion-eligibility/${customerId}`,
    {
      params: { companyId },
    }
  )

  return response.data
}

const deleteCustomer = async ({ customerId, companyId }) => {
  await api.delete(`customer/${customerId}`, {
    params: {
      companyId,
    },
  })
}

const getCustomersThatReturnedByPeriod = async ({
  companyId,
  period,
  params,
}) => {
  return await api.get(`customer/forecast/${companyId}/${period}`, { params })
}

const customersRepository = {
  getCustomersThatReturnedByPeriod,
  getAllActiveByCompany,
  getAllByCompany,
  getCustomer,
  getByCompanyAndCpfCnpj,
  create,
  update,
  show,
  getAllVehiclesByCustomer,
  getCustomerByCpfCnpj,
  getClientByIdForChecklistSheet,
  importCustomers,
  getCustomerList,
  verifyCustomerExclusionEligibility,
  deleteCustomer,
  generateReportOfSalesByPassages,
}

export default customersRepository
