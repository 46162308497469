import React from 'react'

import { Col } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import ReactTable from 'react-table'

import { currency } from '../ToNormalize/ToNormalize'
import SearchInput from 'components/SearchInput'
import TableActionsSimilarities from './TableActionSimilarities'
import useFilters from '../../../hooks/useFilters'

export default function SimilarityModal({
  productDescription = '',
  productSimilarities = [],
  onHide,
  onCancel,
  onInclude,
}) {
  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const handleFilters = (productSimilarity) => {
    const product = productSimilarity.similarProduct

    const querySearch = [
      product['Code'],
      product['Description'],
      product['Bar_Code'],
    ]
    return filterByQuery(querySearch)
  }
  return (
    <Modal dialogClassName='modal-60w' show animation onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <strong>Similaridade do {productDescription}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '70%', padding: 0 }}>
            <SearchInput
              placeholder='Pesquisar por Código, Descrição e Código de Barras'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <ReactTable
            style={{
              marginTop: 10,
              fontWeight: 'bold',
              textAlign: 'center',
              height: '427px',
            }}
            data={productSimilarities.filter(handleFilters)}
            columns={[
              {
                Header: 'Código',
                accessor: 'similarProduct.Code',
                width: 70,
              },
              {
                Header: 'Descrição',
                accessor: 'similarProduct.Description',
              },
              {
                Header: 'Marca',
                accessor: 'similarProduct.Brands',
                Cell: (props) =>
                  props.value ? props.value.Description : '---',
              },

              {
                Header: 'Estoque',
                accessor: 'similarProduct.Stock_Quantity',
              },
              {
                Header: 'Valor',
                accessor: 'similarProduct.Sale_Price',
                Cell: (props) => (
                  <div>
                    {currency(props.original.similarProduct.Sale_Price)}
                  </div>
                ),
              },
              {
                Header: '',
                accessor: 'Add',
                width: 60,
                Cell: (props) => (
                  <TableActionsSimilarities row={props} onInclude={onInclude} />
                ),
              },
            ]}
            defaultPageSize={10}
            showPagination
            sortable={false}
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[10, 20, 25, 50, 100]}
            previousText='Anterior'
            nextText='Próximo'
            loadingText='Carregando...'
            noDataText='Não há informação'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            manual
          />
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='danger' onClick={onCancel} fill>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
