import React from 'react'
import { useRecoilState } from 'recoil'

import { FINANCING_PARTNERID } from '../../recoil'

import FinancingAuthorizedTemplate from './components/Template'

import FinancingAuthorizedStepBoletoFlex from './components/BoletoFlex'
import FinancingAuthorizedStepKoin from './components/Koin'

const Partner = () => {
  const [partnerId] = useRecoilState(FINANCING_PARTNERID)

  if (partnerId === 1) {
    return <FinancingAuthorizedStepKoin />
  }

  if (partnerId === 2) {
    return <FinancingAuthorizedStepBoletoFlex />
  }
}

export default function FinancingAuthorizedStep() {
  return (
    <FinancingAuthorizedTemplate>
      <Partner />
    </FinancingAuthorizedTemplate>
  )
}
