import api from '../services/api'

const create = async (values) => {
  const { data } = await api.post('kit', values)
  return data
}

const update = async (id, values) => {
  const { data } = await api.put(`kit/${id}`, values)
  return data
}

const show = async (id) => {
  const { data } = await api.get(`kit/${id}`)
  return data
}

const getAll = async (queryParams) => {
  const response = await api.get('kit', {
    params: queryParams,
  })

  return response.data
}

export default {
  create,
  update,
  show,
  getAll
}
