import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, change, Field } from 'redux-form';
import { Col, Modal } from 'react-bootstrap';
import { differenceInMinutes, format } from 'date-fns';
import Select from 'react-select';

import TextArea from '../../../Clients/NewClient/FormClient/components/TextArea';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { description } from '../../../../components/ToNormalize/ToNormalize';
import CustomButton from 'client/components/CustomButton/CustomButton';
import RenderField from 'components/RenderField';
import BudgetModal from './BudgetModal';
import ModalOsBudget from './ModalOsBudget';

import { setClient } from '../../../Sales/NewSale/FormSale/redux/actions';

import boxPrismRepository from '../../../../../repositories/PrismaBox';
import customersRepository from '../../../../../repositories/Customers';
import FormPrismabox from '../../../PrismaBox/NewPrismaBox/BundleFormPrismaBox';

import './styles.css';

import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import RegisterVehicleModal from 'client/components/RegisterVehicleModal';

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-40px' }}
    className="fa fa-search"
  />
);

function DadosCadastrais({ isModal }) {
  const [clients, setClients] = useState([]);
  const [sales, setSales] = useState([]);
  const [boxPrisms, setBoxPrisms] = useState([]);
  const [clientsSuggestions, setClientsSuggestions] = useState([]);

  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [isPrismBoxModalOpen, setIsPrismBoxModalOpen] = useState(false);
  const [isModalOsBugetOpen, setIsModalOsBudgetOpen] = useState(false);

  const [isNewVehicleModalOpen, setIsNewVehicleModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState('');

  const [query, setQuery] = useState('');
  const [queryB, setQueryB] = useState('');

  const { saleReducer } = useSelector((state) => state);
  const { companyId } = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();

  const [selectedClient, setSelectedClient] = useState(null);

  const {
    initialDate,
    initialHour,
    finalDate,
    finalHour,
    saleId,
    licensePlate,
    customerId,
  } = useSelector((state) => state.form?.formNewScheduling?.values);

  useEffect(() => {
    const total = differenceInMinutes(
      new Date(finalDate + 'T' + finalHour),
      new Date(initialDate + 'T' + initialHour)
    );

    const totalDays = Math.floor(total / 1440);
    const totalHours = Math.floor((total % 1440) / 60);
    const totalMinutes = Math.floor((total % 1440) % 60);
    const dateDifference = `${totalDays ? totalDays + 'd' : ''} ${
      totalHours ? totalHours + 'h' : ''
    } ${totalMinutes ? totalMinutes + 'm' : ''}`;

    dispatch(change('formNewScheduling', 'total', dateDifference));
  }, [initialDate, initialHour, finalDate, finalHour]);

  useEffect(() => {
    loadBoxPrisms();
  }, []);

  useEffect(() => {
    if (
      customerId &&
      customerId !== selectedClient?.id &&
      clients.length !== 0
    ) {
      handleSelectClient(customerId, true);
    }
  }, [customerId, clients]);

  const loadBoxPrisms = async () => {
    const { data: boxPrisms } = await boxPrismRepository.index({
      companyId,
      status: 1,
    });
    setBoxPrisms(boxPrisms);
  };

  useEffect(() => {
    if (!!companyId) {
      loadClients();
    }
  }, [companyId]);

  const loadClients = async () => {
    const clients = await customersRepository.getAllByCompany(companyId);
    setClients(clients);
  };

  useEffect(() => {
    const suggestions = clients.map((client) => {
      const clientName =
        client.Type === 'Fisica'
          ? `Nome: ${client.Company_Name}`
          : `Razão Social: ${client.Company_Name}, Nome Fantasia: ${client.Trading_Name}`;

      const clientDocument = client.Cpf_Cnpj
        ? client.Type === 'Fisica'
          ? `CPF: ${client.Cpf_Cnpj}`
          : `CNPJ: ${client.Cpf_Cnpj}`
        : '';

      const clientVehicles = !client.Vehicle.length
        ? 'Sem Veículo'
        : client.Vehicle.length > 5
        ? 'Muitos Veículos cadastrados'
        : `Veículo${client.Vehicle.length > 1 ? 's' : ''}: ${client.Vehicle.map(
            (vehicle) => {
              if (!vehicle.License_Plate) return '';
              return (
                vehicle.License_Plate.substring(0, 3) +
                '-' +
                vehicle.License_Plate.substring(3, 7)
              );
            }
          )}`;

      return {
        value: client.id,
        label: `${clientName} ${
          clientDocument && `, ${clientDocument}`
        } - ${clientVehicles.replace(',,', ',').replace(',', ', ')}`,
      };
    });

    setClientsSuggestions(suggestions);
  }, [clients]);

  const handleSelectClient = (id, keepPlate, query) => {
    if (!keepPlate) {
      dispatch(change('formNewScheduling', 'licensePlate', ''));
      dispatch(change('formNewScheduling', 'vehicle', ''));
      dispatch(change('formNewScheduling', 'vehicleId', ''));
    }
    const client = clients.find((client) => client.id === id);

    if (!!client) {
      dispatch(
        change(
          'formNewScheduling',
          'customer',
          client.Company_Name || client.Trading_Name
        )
      );
      dispatch(change('formNewScheduling', 'customerId', client.id));
      setSelectedClient(client);

      if (query) {
        const clientVehicleWithInputedPlate = client.Vehicle.find(
          (vehicle) =>
            vehicle.License_Plate?.includes(query) ||
            vehicle.Maintenance_Object?.includes(query)
        );

        if (clientVehicleWithInputedPlate) {
          dispatch(
            change(
              'formNewScheduling',
              'vehicleId',
              clientVehicleWithInputedPlate.id
            )
          );
          dispatch(
            change(
              'formNewScheduling',
              'licensePlate',
              clientVehicleWithInputedPlate.License_Plate
            )
          );
          dispatch(
            change(
              'formNewScheduling',
              'vehicle',
              clientVehicleWithInputedPlate.Model
            )
          );
        }

        setQuery('');
      }
    }
  };

  const handleSelectVehicle = (vehicle) => {
    const clientVehicles = [...selectedClient.Vehicle, vehicle];
    setSelectedClient({ ...selectedClient, Vehicle: clientVehicles });

    dispatch(change('formNewScheduling', 'vehicleId', vehicle.id));
    dispatch(
      change(
        'formNewScheduling',
        'licensePlate',
        vehicle.License_Plate || vehicle.Maintenance_Object
      )
    );
    dispatch(change('formNewScheduling', 'vehicle', vehicle.Model));

    setIsNewVehicleModalOpen(false);
  };

  const handleSelectPlate = (plate) => {
    console.log(plate);
    const vehicle = selectedClient.Vehicle.find(
      (vehicle, index) =>
        vehicle.License_Plate === plate || vehicle.Maintenance_Object === plate
    );

    dispatch(change('formNewScheduling', 'vehicleId', vehicle.id));
    dispatch(change('formNewScheduling', 'licensePlate', plate));
    dispatch(change('formNewScheduling', 'vehicle', vehicle.Model));
  };

  const registerPrismBoxSuccess = (boxPrism) => {
    setIsPrismBoxModalOpen(false);
    dispatch(change('formNewScheduling', 'boxPrism', boxPrism));
    loadBoxPrisms();
  };

  return (
    <>
      <div className="new-scheduling-container">
        <div className="form-scheduling">
          <div className="form-row">
            {!isModal && (
              <div className="custom-button-scheduling">
                <CustomButton
                  bsStyle="primary"
                  fill
                  clas
                  disabled={isModal}
                  onClick={() => setIsBudgetModalOpen(true)}
                >
                  Selecionar Orçamento/OS
                </CustomButton>
              </div>
            )}
            {!isModal && (
              <div style={{ width: '500px' }}>
                <Select
                  isSearchable
                  options={clientsSuggestions}
                  placeholder="Pesquisar por Placa/Objeto de Manutenção, CPF/CNPJ ou Nome"
                  onInputChange={(value) => {
                    setQuery(value);
                  }}
                  onChange={(item) => {
                    return isModal || saleId
                      ? null
                      : handleSelectClient(item.value, false, query);
                  }}
                  value={query}
                  isDisabled={isModal || saleId ? true : false}
                  noOptionsMessage={() => 'Nenhum cliente encontrado'}
                  components={{
                    DropdownIndicator: Icon,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '40px',
                      minHeight: '40px',
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      height: '40px',
                      padding: '0 0 0 10px',
                    }),
                  }}
                />
              </div>
            )}
          </div>
          <div className="form-row">
            <div style={{ width: '85px' }}>
              <Field
                label="Nº:"
                name="number"
                disabled
                component={Input}
                normalize={description}
              />
            </div>
            <div style={{ width: '280px' }}>
              <Field
                label="Cliente:"
                name="customer"
                disabled
                component={Input}
                normalize={description}
              />
            </div>
            <div>
              <Field
                className="form-control foco-input"
                name="licensePlate"
                as="select"
                label="Placa:"
                component={RenderField}
                disabled={
                  (!licensePlate && !selectedClient) ||
                  selectedClient?.Vehicle?.length === 0 ||
                  saleId
                }
                onChange={(e) => handleSelectPlate(e.target.value)}
              >
                <option key="" value="" disabled>
                  Selecione
                </option>

                {selectedClient?.Vehicle?.map((vehicle, index) => (
                  <option
                    key={index}
                    value={
                      vehicle.License_Plate
                        ? vehicle.License_Plate
                        : vehicle.Maintenance_Object
                    }
                  >
                    {vehicle.License_Plate !== ''
                      ? vehicle.License_Plate
                      : vehicle.Maintenance_Object}
                  </option>
                ))}
              </Field>
            </div>

            <div style={{ width: '350px' }}>
              <div className="div-a-modal">
                <a
                  onClick={() => {
                    if (isModal || saleId) {
                      return toastr.warning(
                        'Atenção',
                        'Não é possível alterar o veículo para um diferente do vinculado à OS/Orçamento'
                      );
                    }

                    if (!saleId && !selectedClient) {
                      return toastr.warning(
                        'Atenção',
                        'Selecione um cliente para cadastrar o veículo'
                      );
                    }

                    setIsNewVehicleModalOpen(true);
                  }}
                >
                  Cadastrar Veículo
                </a>
              </div>
              <div>
                <Field
                  label="Veículo:"
                  name="vehicle"
                  disabled
                  component={Input}
                  normalize={description}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <label>
                  Box/Prisma:<span style={{ color: 'red' }}>*</span>
                </label>

                <a onClick={() => setIsPrismBoxModalOpen(true)}>
                  Adicionar Box/Prisma
                </a>
              </div>
              <div>
                <Field
                  name="boxPrism"
                  as="select"
                  required
                  component={RenderField}
                >
                  <option value="">Selecione</option>
                  {boxPrisms.map((boxPrism) => (
                    <option key={boxPrism.id} value={boxPrism.id}>
                      {boxPrism.description}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div>
              <Field
                label="Início:"
                required
                component={RenderField}
                type="date"
                name="initialDate"
                maxDate={finalDate}
              />
            </div>
            <div>
              <Field
                className="time-picker"
                required
                component={RenderField}
                type="time"
                name="initialHour"
              />
            </div>

            <div style={{ width: '180px' }}>
              <Field
                label="Final:"
                required
                component={RenderField}
                type="date"
                name="finalDate"
                minDate={initialDate}
              />
            </div>
            <div>
              <Field
                className="time-picker"
                required
                component={RenderField}
                type="time"
                name="finalHour"
              />
            </div>

            <div style={{ width: '130px' }}>
              <Field
                label="Total:"
                name="total"
                disabled
                component={Input}
                normalize={description}
              />
            </div>

            <div>
              <CustomButton
                bsStyle="warning"
                fill
                onClick={() => setIsModalOsBudgetOpen(true)}
                disabled={!saleId}
                style={{
                  backgroundColor: '#f0ad4e',
                  marginTop: '39px',
                }}
              >
                Visualizar Orçamento/Venda/OS
              </CustomButton>
            </div>
          </div>

          <div className="form-row">
            <div style={{ flex: 1 }}>
              <label>Observações:</label>
              <Field name="observations" component={TextArea} maxLength="200" />
            </div>
          </div>
        </div>
        {isNewVehicleModalOpen && (
          <RegisterVehicleModal
            clientId={selectedClient.id}
            handleClose={() => setIsNewVehicleModalOpen(false)}
            handleSelectVehicle={handleSelectVehicle}
          />
        )}
      </div>

      <BudgetModal
        show={isBudgetModalOpen}
        onCancel={() => setIsBudgetModalOpen(false)}
      />
      <Modal
        dialogClassName="modal-60w"
        show={isPrismBoxModalOpen}
        onHide={() => setIsPrismBoxModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              margin: 0,
            }}
          >
            <Modal.Title>
              <strong>Novo Box/Prisma</strong>
            </Modal.Title>
            <span style={{ color: 'red', margin: '0' }}>
              * Campos Obrigatórios
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FormPrismabox
            modal={true}
            initialValues={{ status: true }}
            handleModal={registerPrismBoxSuccess}
            onCancel={() => setIsPrismBoxModalOpen(false)}
          />
        </Modal.Body>
      </Modal>
      {isModalOsBugetOpen && (
        <ModalOsBudget
          onCancel={() => setIsModalOsBudgetOpen(false)}
          show={isModalOsBugetOpen}
          id={saleId}
        />
      )}
    </>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'formNewScheduling',
})(DadosCadastrais);
