import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import CustomButton from 'client/components/CustomButton/CustomButton';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

import { useAuth } from 'contexts/auth';
import { useWorkshopPanel } from 'contexts/workshopPanel';

import companyRepository from 'repositories/Companies';

import '../index.css';
import ReactInputMask from 'react-input-mask';

const SharePanelAccessWithClientModal = ({
  show,
  onCancel,
  onSubmit,
  maintenance,
}) => {
  const { companyId } = useAuth();

  const [isWhatsappShareModalOpen, setIsWhatsappShareModalOpen] =
    useState(false);

  const [clientNumber, setClientNumber] = useState(
    maintenance?.Sales?.Customer?.phone
  );

  useEffect(() => {
    if (maintenance?.Sales?.Customer?.phone) {
      setClientNumber(maintenance?.Sales?.Customer?.phone);
    }
  }, [maintenance]);

  const { setReloadPanelInformation } = useWorkshopPanel();

  const sendWhatsappMessage = async () => {
    const panelLink = getExternalPanelLink();

    const companyInfo = await loadCompanyInfo();

    const companyPhone =
      companyInfo?.Phones[0].Number_Phone1 ||
      companyInfo?.Phones[0].Number_Phone2 ||
      '';

    const text = `Prezado(a) ${
      maintenance?.Sales?.Customer?.name || ''
    }%0a%0aFoi iniciado o serviço em seu veículo. Você poderá acompanhar o andamento dele através do link: ${panelLink} %0a%0aEm caso de dúvidas, pedimos que entre em contato conosco através do telefone ${
      companyPhone ? phoneMask(clientNumber) : ''
    }%0a%0aAtenciosamente, %0a%0a${
      companyInfo?.Trading_Name || companyInfo?.Company_Name || ''
    }`;

    window.open(
      `https://api.whatsapp.com/send?phone=55${clientNumber?.replace(
        /\D/g,
        ''
      )}&text=${text}`,
      '_blank'
    );

    setReloadPanelInformation(true);
  };

  const getExternalPanelLink = () => {
    let companyIdHash = encodeURIComponent(
      encrypt(companyId, '@OS-dig:companyId')
    );
    let saleIdHash = encodeURIComponent(
      encrypt(maintenance?.Sales?.id, '@OS-dig:saleId')
    );

    const domain = window.location.host;

    return `${domain}/external-workshop-panel/${companyIdHash}/${saleIdHash}`;
  };

  const loadCompanyInfo = async () => {
    try {
      const data = await companyRepository.getWithAddressAndPhones(companyId);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onCancel} backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Compartilhamento de acesso com cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>
            Você deseja enviar o link para que o cliente veja o andamento do
            serviço?
          </p>

          <p style={{ fontSize: '14px' }}>
            O cliente visualizará apenas o status do seu veículo e dessa OS.
            Você poderá compartilhar mais tarde através do botão compartilhar na
            tela da OS. Uma vez enviada para o cliente, não poderá ser bloqueado
            o acesso.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              style={{
                width: '80px',
              }}
              bsStyle="danger"
              name="denied"
              onClick={onCancel}
              fill
            >
              Não
            </CustomButton>
            <CustomButton
              style={{
                width: '80px',
              }}
              bsStyle="info"
              name="approve"
              onClick={() => setIsWhatsappShareModalOpen(true)}
              fill
            >
              Sim
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>

      {isWhatsappShareModalOpen && (
        <Modal
          show={isWhatsappShareModalOpen}
          onHide={() => setIsWhatsappShareModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Compartilhar via WhatsApp</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <label>Nome</label>
                <input
                  className="form-control foco-input"
                  value={
                    maintenance?.Sales?.Customer?.name ??
                    'Cliente não encontrado'
                  }
                  disabled={true}
                />
              </div>
              <div>
                <label for="homePhoneNumber">Celular</label>
                <ReactInputMask
                  onChange={(e) => {
                    setClientNumber(e.target.value);
                  }}
                  value={clientNumber}
                  onKeyDown={(e) =>
                    e.key == 'Enter' ? sendWhatsappMessage() : null
                  }
                  mask={'(99) 99999-9999'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'homePhoneNumber'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <CustomButton
                style={{
                  padding: '5px',
                  fontWeight: 'normal',
                  fontSize: '15px',
                  marginLeft: '5px',
                }}
                onClick={() => sendWhatsappMessage()}
                type="button"
                id="btn-salvar"
                pullRight
                fill
              >
                Enviar <span />
              </CustomButton>
              <CustomButton
                style={{
                  marginTop: '5px',
                  padding: '5px',
                  fontWeight: 'normal',
                  fontSize: '15px',
                }}
                onClick={() => setIsWhatsappShareModalOpen(false)}
                type="button"
                id="cancelar"
                pullRight
                fill
              >
                Cancelar
              </CustomButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SharePanelAccessWithClientModal;
