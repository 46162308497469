import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import CollapsibleSection from 'components/CollapsibleSection';

export const CRMBonusCreateCustomerModalForm = () => {
  const { register, control } = useFormContext();

  return (
    <div>
      <CollapsibleSection
        title="Dados do Cliente"
        isExpanded={true}
        handleExpand={null}
        style={{ width: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '6fr 3fr 3fr 4fr',
            gap: '10px',
          }}
        >
          <div>
            <label htmlFor="name">
              Nome Completo:<span style={{ color: 'red' }}>*</span>
            </label>

            <input
              type="text"
              className="form-control foco-input"
              id="name"
              {...register('name')}
            />
          </div>
          <div>
            <label htmlFor="cpf">
              CPF:<span style={{ color: 'red' }}>*</span>
            </label>

            <Controller
              defaultValue={''}
              name={'cpf'}
              control={control}
              render={({ field }) => {
                return (
                  <InputMask
                    {...field}
                    mask={'999.999.999-99'}
                    formatChars={{
                      9: '[0-9]',
                    }}
                  >
                    {() => (
                      <input
                        id={'cpf'}
                        type="text"
                        className="form-control foco-input"
                      />
                    )}
                  </InputMask>
                );
              }}
            />
          </div>
          <div>
            <label htmlFor="phoneNumber">
              Telefone Celular:<span style={{ color: 'red' }}>*</span>
            </label>

            <Controller
              defaultValue={''}
              name={'phoneNumber'}
              control={control}
              render={({ field }) => {
                return (
                  <InputMask
                    {...field}
                    mask={'(99) 9 9999-9999'}
                    formatChars={{
                      9: '[0-9]',
                    }}
                  >
                    {() => (
                      <input
                        id={'phoneNumber'}
                        type="text"
                        className="form-control foco-input"
                      />
                    )}
                  </InputMask>
                );
              }}
            />
          </div>
          <div>
            <label htmlFor="birthdate">
              Data de Nascimento:<span style={{ color: 'red' }}>*</span>
            </label>

            <input
              type="date"
              className="form-control foco-input"
              id="birthdate"
              {...register('birthdate')}
            />
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '6fr 3fr 3fr 4fr',
            gap: '10px',
          }}
        >
          <div>
            <label htmlFor="email">
              E-mail:<span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="email"
              className="form-control foco-input"
              id="email"
              {...register('email')}
            />
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
};
