import { differenceInDays } from 'date-fns';
import constants, {
  ESSENCIAL_PLANS_ID,
  FREE_PLANS_ID,
  PRIME_EQUIVALENT_PLANS_ID,
  PRIME_ONLY_PLANS_ID,
  START_PLANS_ID,
  WORKMOTOR_EQUIVALENT_PLANS_ID,
} from './constants';

export const isSignatureOnEnd = (endDate) => {
  return !endDate
    ? false
    : getDifferenceInDays(endDate) < constants.START_DATE_FOR_PLAN_RENEWAL;
};

export const getDifferenceInDays = (endDate) =>
  differenceInDays(new Date(endDate), new Date());

export const validatePlanFree = (plan) => {
  return FREE_PLANS_ID.includes(plan);
};

export const validatePlanStart = (plan) => {
  return START_PLANS_ID.includes(plan);
};

export const validatePlanEssencial = (plan) => {
  return ESSENCIAL_PLANS_ID.includes(plan);
};

export const validatePlanPrime = (plan) => {
  return PRIME_EQUIVALENT_PLANS_ID.includes(plan);
};

export const validatePlanWorkmotorEquivalent = (plan) => {
  return WORKMOTOR_EQUIVALENT_PLANS_ID.includes(plan);
};

export const getPossiblePlansIdsByName = (planName) => {
  switch (planName) {
    case 'Free':
      return FREE_PLANS_ID;
    case 'Start':
      return START_PLANS_ID;
    case 'Essencial':
      return ESSENCIAL_PLANS_ID;
    case 'Prime':
      return PRIME_ONLY_PLANS_ID;
    case 'Workmotor':
      return WORKMOTOR_EQUIVALENT_PLANS_ID;
    case 'Enterprise':
      return WORKMOTOR_EQUIVALENT_PLANS_ID;
  }
};
