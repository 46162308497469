import React, { useEffect, useState, useMemo } from 'react'
import NumberFormat from 'react-number-format'
import { toastr } from 'react-redux-toastr'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  useFieldArray,
  useFormContext,
  useWatch,
  Controller,
} from 'react-hook-form'

import CollapsibleSection from 'components/CollapsibleSection'
import ReactTableAutoPaginated from 'components/ReactTableAutoPaginated'
import CustomButton from 'client/components/CustomButton/CustomButton.jsx'

import ItemsModal from 'client/views/NFe/NewNFe/ItemsModal'

const DecimalInput = (props) => {
  return <input type="text" className="form-control" {...props} />
}

const QuotationItemsActions = ({
  data,
  status,
  isEditing,
  index,
  handleRemoveItems,
}) => {
  const disabled = isEditing && status !== 'Não Enviada'

  return (
    <div>
      <a
        title="Cotações enviadas ou canceladas não podem ser alteradas"
        onClick={(e) => {
          e.preventDefault()
          if (disabled) return
          handleRemoveItems([index])
        }}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{
            color: disabled ? 'grey' : 'red',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          aria-hidden="false"
        />
      </a>
    </div>
  )
}

export default function QuotationItems() {
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false)
  const [quotationItemsList, setQuotationItemsList] = useState([])

  const { control, register, setValue, getValues } = useFormContext()
  const { fields, append, replace } = useFieldArray({
    name: 'items',
    control,
  })

  const loadingItemsList = useWatch({
    control,
    name: 'loadingItemsList',
  })

  function handleAddItems(items) {
    const alreadyAddedItemsIds = quotationItemsList.map(
      (item) => item.productId
    )

    const formatedItems = []

    const itemsNotAdded = items.filter(
      (item) => !alreadyAddedItemsIds.includes(item.productId)
    )

    itemsNotAdded.forEach((item) => {
      const newItem = {
        productId: item.productId,
        code: item.code,
        brand: item.Brand,
        description: item.description,
        manufacturer_ref: item.Manufacturer_Ref,
        quantity: item.quantity,
        quotationItemId: item.quotationItemId || null,
      }

      formatedItems.push(newItem)
      append(newItem)
    })

    if (itemsNotAdded.length !== items.length) {
      toastr.warning(
        'Atenção',
        'Um ou mais itens selecionados já foram adicionados anteriormente'
      )
    }

    setQuotationItemsList([...quotationItemsList, ...formatedItems])
    setIsItemsModalOpen(false)
  }

  function handleRemoveItems(itemsIndex) {
    const formValues = getValues()

    const itemsToRemove = formValues.items.filter((item, index) =>
      itemsIndex.includes(index)
    )

    if (isEditing) {
      const itemsWithRegister = itemsToRemove.filter(
        (item) => item.quotationItemId
      )
      if (itemsWithRegister.length > 0) {
        const itemsToRemoveIds = itemsWithRegister.map(
          (item) => item.quotationItemId
        )
        setValue('removedItems', [
          ...formValues.removedItems,
          ...itemsToRemoveIds,
        ])
      }
    }

    const updatedFields = formValues.items.filter(
      (item, itemIndex) => !itemsIndex.includes(itemIndex)
    )

    replace(updatedFields)
    setQuotationItemsList(updatedFields)
  }

  function handleLoadItemsToEdit() {
    const formatedItems = quotationToEdit.QuotationItems?.map((item) => {
      return {
        productId: item.productId,
        code: item.Product.Code,
        Brand: item.brand,
        description: item.description,
        Manufacturer_Ref: item.manufacturer_ref,
        quantity: item.quantity,
        quotationItemId: item.id,
      }
    })
    handleAddItems(formatedItems)
  }

  const quotationItemsColumns = useMemo(() => {
    return [
      {
        Header: 'Código',
        accessor: 'code',
        width: 100,
      },
      {
        Header: () => (
          <span>
            Descrição <span style={{ color: 'red' }}>*</span>
          </span>
        ),
        accessor: 'description',
        Cell: (props) => (
          <Controller
            name={`items.${props.index}.description`}
            control={control}
            render={({ field }) => {
              return (
                <input
                  type="text"
                  className="form-control"
                  {...field}
                  disabled={
                    isEditing && quotationToEdit.status !== 'Não Enviada'
                      ? true
                      : false
                  }
                />
              )
            }}
          />
        ),
      },
      {
        Header: () => <span>Ref. Fabricante</span>,
        accessor: 'manufacturer_ref',
        width: 240,
        Cell: (props) => (
          <Controller
            name={`items.${props.index}.manufacturer_ref`}
            control={control}
            render={({ field }) => {
              return (
                <input
                  key={field.id}
                  type="text"
                  className="form-control"
                  {...field}
                  disabled={
                    isEditing && quotationToEdit.status !== 'Não Enviada'
                      ? true
                      : false
                  }
                />
              )
            }}
          />
        ),
      },
      {
        Header: () => <span>Marca</span>,
        accessor: 'brand',
        width: 200,
        Cell: (props) => (
          <Controller
            name={`items.${props.index}.brand`}
            control={control}
            render={({ field }) => {
              return (
                <input
                  type="text"
                  className="form-control"
                  {...field}
                  disabled={
                    isEditing && quotationToEdit.status !== 'Não Enviada'
                      ? true
                      : false
                  }
                />
              )
            }}
          />
        ),
      },
      {
        Header: () => (
          <span>
            Quantidade<span style={{ color: 'red' }}>*</span>
          </span>
        ),
        accessor: 'quantity',
        width: 150,
        Cell: (props) => (
          <Controller
            name={`items.${props.index}.quantity`}
            control={control}
            render={({ field: { onChange, onBlur, name, value, ref } }) => {
              return (
                <NumberFormat
                  customInput={DecimalInput}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onValueChange={(v) => onChange(v.floatValue)}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  disabled={
                    isEditing && quotationToEdit.status !== 'Não Enviada'
                      ? true
                      : false
                  }
                />
              )
            }}
          />
        ),
      },
      {
        Header: '',
        accessor: 'id',
        width: 50,
        Cell: (props) => (
          <QuotationItemsActions
            id={props.value}
            isEditing={isEditing}
            status={quotationToEdit.status}
            index={props.index}
            handleRemoveItems={handleRemoveItems}
            data={props.original}
          />
        ),
      },
    ]
  })

  const quotationToEdit = useWatch({
    control,
    name: 'quotationToEdit',
  })

  const isEditing = useWatch({
    control,
    name: 'isEditing',
  })

  useEffect(() => {
    if (isEditing && !loadingItemsList) {
      handleLoadItemsToEdit()
    }
  }, [isEditing, loadingItemsList])

  return (
    <CollapsibleSection
      title="Itens"
      isExpanded={true}
      handleExpand={() => null}
    >
      <div className="items-section">
        <div className="items-header">
          <CustomButton
            bsStyle="info"
            fill
            onClick={() => setIsItemsModalOpen(true)}
            disabled={
              isEditing && quotationToEdit.status !== 'Não Enviada'
                ? true
                : false
            }
          >
            + Adicionar Item
          </CustomButton>
        </div>

        <div style={{ width: '100%' }}>
          <ReactTableAutoPaginated
            data={quotationItemsList}
            columns={quotationItemsColumns}
            defaultPageSize={5}
            loading={loadingItemsList}
          />
        </div>

        <div>
          <label htmlFor="observations" className="observations-label">
            Observações:
          </label>
          <textarea
            id="observations"
            className="form-control"
            maxLength={600}
            disabled={
              isEditing && quotationToEdit.status !== 'Não Enviada'
                ? true
                : false
            }
            {...register('observations')}
          ></textarea>
        </div>
      </div>

      {isItemsModalOpen && (
        <ItemsModal
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAddItems}
        />
      )}
    </CollapsibleSection>
  )
}
