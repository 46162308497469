import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import CardForm from 'client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import AlertModal from 'components/AlertModal';
import MainData from './MainData';

import './styles.css';

const AdvertisingForm = ({
  handleSubmit,
  dirty,
  loading,
  onCancel,
  type,
  setType,
  handleBrandingChange,
  branding,
}) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);

  const { id, isActive } = useSelector(
    (state) => state.form.advertising.values
  );

  return (
    <div id="advertising-form-page" onSubmit={handleSubmit}>
      <CardForm show title="Dados da Propaganda">
        <MainData
          type={type}
          setType={setType}
          branding={branding}
          isActive={isActive}
          handleBrandingChange={handleBrandingChange}
        />
      </CardForm>

      <FormSubmitButtons
        loading={loading}
        onCancel={() =>
          dirty ? setIsConfirmCancelModalOpen(true) : onCancel()
        }
        onSubmit={handleSubmit}
        hasId={!!id}
      />

      <AlertModal
        show={isConfirmCancelModalOpen}
        title="Cancelamento de cadastro"
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        message="Deseja cancelar o cadastro? O processo é irreversível, mas poderá ser feito um novo cadastro posteriormente"
        onSubmit={onCancel}
      />
    </div>
  );
};

export default reduxForm({
  form: 'advertising',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: true,
})(AdvertisingForm);
