import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { useDispatch, useSelector } from 'react-redux'

import StonePaymentProgressModal from 'client/components/StonePaymentProgressModal'
import stoneTransactionRepository from 'repositories/StoneTransaction'

import {
  handleChange,
  finishStonePayment,
  createSaleSuccess,
  updateSaleSuccess,
} from './redux/actions'

const StonePaymentModal = (sale_id) => {
  const [processingPayment, setProcessingPayment] = useState(false)
  const [processedPayment, setProcessedPayment] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)

  const { total, installments, companyId, saleId } = useSelector(
    (state) => state.saleReducer
  )

  const dispatch = useDispatch()

  useEffect(() => {
    handleStoneTransaction()
  }, [])

  const handleStoneTransaction = async () => {
    try {
      const formatedInstallments = installments.map((installment) => {
        return {
          ...installment,
          formOfPaymentId: installment.formOfPayment.id,
        }
      })

      const { stonePreTransactionId } = await stoneTransactionRepository.store({
        amount: total,
        installments: formatedInstallments,
        companyId,
        saleId,
      })

      dispatch(handleChange(stonePreTransactionId, 'stonePreTransactionId'))

      const sleep = (time) => new Promise((s) => setTimeout(s, time))

      let transactionStatus
      while (
        transactionStatus !== 'processed' &&
        transactionStatus !== 'undone' &&
        transactionStatus !== 'canceled'
      ) {
        await sleep(3000)

        const { status } = await stoneTransactionRepository.show(
          stonePreTransactionId
        )

        if (status === 'processing') {
          setProcessingPayment(true)
        } else if (status === 'processed') {
          setProcessedPayment(true)
        }

        transactionStatus = status
      }

      if (transactionStatus !== 'processed') {
        dispatch({ type: 'CREATE_SALE_FAILURE' })
        return toastr.error('Transação cancelada na maquininha.')
      }

      await sleep(3000)
      dispatch(finishStonePayment())

      await sleep(1000)
      if (sale_id) dispatch(createSaleSuccess(saleId))
      else dispatch(updateSaleSuccess())
    } catch (err) {
      console.error(err)
      return toastr.error('Ocorreu um erro ao processar a transação Stone.')
    } finally {
      setIsModalOpen(false)
    }
  }

  return (
    <StonePaymentProgressModal
      show={isModalOpen}
      processing={processingPayment}
      processed={processedPayment}
    />
  )
}

export default StonePaymentModal
