
import React, { useState, useEffect } from 'react';
import { isValid } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import SalesRepository from '../../../../../repositories/Sales'

import './styles.css'
const ReturnReportDevolution = () => {
  const [loading, setLoading] = useState(false)
  const [returnType, setReturnType] = useState('ALL')
  const [finalDate, setFinalDate] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [isReportReady, setReportReady] = useState(false)
  const { companyId } = useAuth()


  const handleSubmit = async () => {

    if (validations()) handleProcess()
  }

  const validations = () => {

    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate))
    ) {
      toastr.warning('Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente')
      return false
    }

    return true
  }

  const handleProcess = async () => {
    setLoading(true)
    setReportReady(false)
    try {
      const data = await SalesRepository.generateReturnReport({
        initialDate,
        finalDate,
        companyId,
        returnType
      })
      console.log(data)

      // console.log(data)

      if (!data?.report.invoices.length) return toastr.warning('Nenhuma informação para o relatório foi encontrada.')
      setReportReady(true)
    } catch (err) {
      console.log(err)
      toastr.error(err?.response?.data?.message || 'Ocorreu um erro com a buscar. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    window.open(window.location.origin + `/report/return-listing?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&returnType=${returnType}`, '_blank')

  }


  return (
    <div className='content-return-report'>
      <div>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={e => setInitialDate(e.target.value)}
        />
      </div>

      <div>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={e => setFinalDate(e.target.value)}
        />
      </div>

      <div>
        <label>Tipo de Venda:</label>
        <select value={returnType}
          onChange={(e) => setReturnType(e.target.value)} className="form-control foco-input" style={{ width: '160px' }}>
          <option value="ALL">Todos</option>
          <option value="PDV">PDV</option>
          <option value="Ordem de Serviço">Ordem de Serviço</option>
          <option value="Venda">Venda</option>
        </select>
      </div>
      <div>
        <button
          className="btn btn-sucesso"
          onClick={handleSubmit}
          disabled={loading}>
          <span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} /> Processar</button>
      </div>
      {isReportReady && (
        <div>
          <button
            className="btn btn-sucesso"
            onClick={handlePrint}
          >Imprimir</button>
        </div>

      )
      }

    </div>
  )
}
export default ReturnReportDevolution