import React, { useState, useEffect } from 'react'

import CardForm from '../../../components/CardForm'
import { Col } from 'react-bootstrap'
import { useParams, withRouter } from 'react-router-dom'
import Button from '../../../../client/components/CustomButton/CustomButton'
import discountGroupRepository from '../../../../repositories/DiscountGroups'
import discountGroupServicesRepository from '../../../../repositories/DiscountGroupServices'

import { change } from 'redux-form'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'contexts/auth'
import useFilters from 'hooks/useFilters'

import SearchInput from 'components/SearchInput'
import TableData from './TableData'

import ItemsModal from '../../NFSe/NFSeForm/ItemsModal'
import PercentInput from '../../../components/Percent/index'
import AlertModal from '../../../../components/AlertModal'

import './styles.scss'

const ServicesTable = ({ history }) => {
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const [isConfirmationDeleteModalOpen, setIsConfirmationDeleteModalOpen] =
    useState(false)
  const [loading, setLoading] = useState(false)

  const [discountGroupService, setDiscountGroupService] = useState([])
  const [discountGroupServiceMargin, setDiscountGroupServiceMargin] =
    useState(0)
  const [servicesTable, setServicesTable] = useState([])
  const [isService, setIsService] = useState(true)

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const [discountGroupId, setDiscountGroupId] = useState(null)

  useEffect(() => {
    setDiscountGroupId(history.location.hash.split('#')[1])
  }, [history])

  useEffect(() => {
    if (discountGroupId) loadDiscountGroup(discountGroupId)
  }, [discountGroupId])
  useEffect(() => {
    if (discountGroupId) loadDiscountGroupServices(discountGroupId)
  }, [discountGroupId])
  useEffect(() => {
    setIsService(true)
  }, [])

  async function loadDiscountGroup(id) {
    try {
      setLoading(true)

      const data = await discountGroupRepository.findById(id)

      setDiscountGroupService(data)
      setDiscountGroupServiceMargin(data.margin)
    } catch (err) {
      toastr.error(
        'Erro ao carregar dados da tabela',
        'Ocorreu um erro ao carregar os dados da tabela de preços diferenciados, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function loadDiscountGroupServices(id) {
    try {
      setLoading(true)

      const data = await discountGroupServicesRepository.show(id)

      const formattedService = data.map((data) => {
        const saleDif =
          data.Service.Price * (data.margin / 100) + data.Service.Price
        return {
          ...data,
          saleDif,
          margin: data.margin,
          code: data.Service.Code,
          description: data.Service.Description,
          profitMargin: data.Service.Profit_Margin,
          salePrice: data.Service.Price,
        }
      })

      setServicesTable(formattedService)
    } catch (err) {
      toastr.error(
        'Erro ao carregar dados da tabela',
        'Ocorreu um erro ao carregar os dados da tabela, tente novamente'
      )
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(values) {
    try {
      setLoading(true)

      await discountGroupServicesRepository.create({
        services: values.map((service) => service.serviceId),
        groupId: discountGroupId,
        margin: discountGroupService.margin,
      })

      setIsNewServiceModalOpen(false)

      loadDiscountGroupServices(discountGroupId)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleChangeDiscontPrice() {
    try {
      setLoading(true)

      const updateServices = servicesTable.map((service) => {
        return {
          id: service.id,
          serviceId: service.serviceId,
        }
      })

      await discountGroupServicesRepository.update({
        margin: discountGroupServiceMargin,
        groupId: discountGroupId,
        services: updateServices,
      })

      loadDiscountGroupServices(discountGroupId)
      toastr.success('Margem alterada com sucesso')
    } catch (err) {
      toastr.error('Erro ao salvar os dados da tabela')
      console.error(err)
    } finally {
      setLoading(false)
      setOpenConfirmationModal(false)
    }
  }

  async function handleDeleteService(ServicesToDelete) {
    try {
      setLoading(true)

      await discountGroupServicesRepository.deleteService(ServicesToDelete.id)

      await loadDiscountGroupServices(discountGroupId)

      setIsConfirmationDeleteModalOpen(false)
      toastr.success(
        'Serviço removido com sucesso da Tabela de Preços diferenciados.'
      )
    } catch (err) {
      toastr.error('Erro ao exlcuir serviço da tabela')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  function handleFilter(discountGroup) {
    const querySearch = [discountGroup['code'], discountGroup['description']]

    return filterByQuery(querySearch)
  }

  return (
    <CardForm style={{ marginTop: '30px' }} title="Dados Principais">
      <header>
        <Col lg={12} md={12} sm={12} xs={12} style={{ width: '50%' }}>
          <Col lg={5} md={5} sm={5} xs={12}>
            <label htmlFor="description">Descrição: </label>
            <input
              style={{ paddingLeft: '10px' }}
              name="description"
              className="input"
              id="description"
              disabled
              value={discountGroupService.name}
            />
          </Col>
          <Col lg={5} md={5} sm={5} xs={12}>
            <label htmlFor="description">Margem Diferenciada: </label>
            {/* <input name='description' className='input' id='description' /> */}
            <PercentInput
              suffix="%"
              className="form-control foco-input"
              decimalSeparator=","
              inputType="text"
              value={discountGroupServiceMargin}
              onChangeEvent={(e) =>
                setDiscountGroupServiceMargin(e.target.value)
              }
            />
          </Col>
          <Col lg={2} md={2} sm={2} xs={12}>
            <Button
              bsStyle="primary"
              name="apply-margin"
              fill
              style={{ marginTop: '36px' }}
              onClick={() => setOpenConfirmationModal(true)}
            >
              Aplicar Margem
            </Button>
          </Col>
        </Col>

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ margin: '16px 0 16px 0' }}
        >
          <div className="search">
            <div>
              <Button
                bsStyle="info"
                name="save"
                fill
                onClick={() => setIsNewServiceModalOpen(true)}
              >
                + Novo Serviço
              </Button>
            </div>
            <div className="input-search">
              <div id="review-search">
                <SearchInput
                  placeholder="Pesquisar por Código Interno ou Descrição"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Col>
      </header>

      <TableData
        loading={loading}
        services={servicesTable}
        handleFilter={handleFilter}
        handleDeleteService={handleDeleteService}
        setIsConfirmationDeleteModalOpen={setIsConfirmationDeleteModalOpen}
        isConfirmationDeleteModalOpen={isConfirmationDeleteModalOpen}
      />
      {isNewServiceModalOpen && (
        <ItemsModal
          onCancel={() => setIsNewServiceModalOpen(false)}
          onSubmit={handleSubmit}
          discountGroupServices={servicesTable}
        />
      )}

      <AlertModal
        show={openConfirmationModal}
        title="OS Digital"
        message={
          <>
            <p>
              <strong>
                Você tem certeza que deseja alterar a margem diferenciada da
                tabela de preços?
              </strong>
            </p>
            <p>
              Ao alterar a margem, será replicado para todos os itens da tabela.
              Você poderá alterar novamente mais tarde.
            </p>
          </>
        }
        onCancel={() => setOpenConfirmationModal(false)}
        onHide={() => setOpenConfirmationModal(false)}
        onSubmit={() => handleChangeDiscontPrice()}
      />
    </CardForm>
  )
}

export default withRouter(ServicesTable)
