import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Select from 'react-select'
import ReactTable from 'react-table'

import AlertModal from 'components/AlertModal/AlertModal'
import SearchIcon from 'components/SelectIcons/SearchIcon'
import Button from 'client/components/CustomButton/CustomButton.jsx'

import NFSePatternsRepository from 'repositories/NFSePatterns'

export default function CopyTributesModal({
  patternId,
  handleClose,
  handleReload,
}) {
  const [patternOptions, setPatternOptions] = useState([])
  const [loadingPatternOptions, setLoadingPatternOptions] = useState(false)

  const [selectedPattern, setSelectedPattern] = useState(null)
  const [tributesList, setTributesList] = useState([])
  const [loadingTributes, setLoadingTributes] = useState(false)

  const [loading, setLoading] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  async function handleLoadPatternOptions() {
    setLoadingPatternOptions(true)
    try {
      const { patterns } = await NFSePatternsRepository.index({ query: '' })
      setPatternOptions(
        patterns
          .filter((item) => Number(item.id) !== Number(patternId))
          .map((item) => ({
            value: item,
            label: `${item.pattern} | ${
              item.offline ? 'Offline' : 'Online'
            } | ${item.isHomologated ? 'Homologado' : 'Não Homologado'} `,
          }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao carregar os padrões',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoadingPatternOptions(false)
    }
  }

  async function handleLoadPatternTributes() {
    setLoadingTributes(true)
    try {
      const tributes = await NFSePatternsRepository.listOperationTaxes(
        selectedPattern.value.id
      )
      setTributesList([...tributes.operations, ...tributes.taxes])
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao carregar os tributos',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoadingTributes(false)
    }
  }

  function validateSubmit() {
    try {
      if (!selectedPattern) {
        return toastr.warning(
          'Ação não realizada',
          'Não há padrão selecionado para copiar os tributos. Selecione um padrão e tente novamente'
        )
      }

      if (tributesList?.length === 0) {
        return toastr.warning(
          'Ação não realizada',
          'Não há tributos para serem copiados. Verifique e tente novamente mais tarde'
        )
      }

      setIsConfirmationModalOpen(true)
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro na operação. Tente novamente!'
      )
    }
  }

  async function handleSubmit() {
    setLoading(true)
    try {
      await NFSePatternsRepository.copyTributes({
        patternToCopyId: selectedPattern.value.id,
        patternToReceiveId: patternId,
      })

      toastr.success(
        'Tributos copiados com sucesso',
        'Os tributos foram copiados de outro padrão com sucesso.'
      )

      handleReload()
      handleClose()
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro na operação. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleLoadPatternOptions()
  }, [])

  useEffect(() => {
    if (selectedPattern?.value.id) {
      handleLoadPatternTributes()
    }
  }, [selectedPattern])

  return (
    <Modal show={true} onHide={null} dialogClassName={`modal-40w`}>
      <Modal.Header>
        <Modal.Title>
          <strong>Copiar Tributos de Outro Padrão</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="admin__form-copy-tributes">
          <div className="admin__form-copy-tributes__row-1">
            <div>
              <Select
                isSearchable
                options={patternOptions}
                placeholder={
                  loadingPatternOptions
                    ? 'Carregando padrões...'
                    : 'Pesquisa Descrição'
                }
                onChange={(e) => {
                  setSelectedPattern(e)
                }}
                value={selectedPattern}
                loading
                noOptionsMessage={() => 'Nenhuma cidade encontrada'}
                components={{
                  DropdownIndicator: SearchIcon,
                }}
                isDisabled={loadingPatternOptions}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '40px',
                    minHeight: '40px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '40px',
                    padding: '0 0 0 10px',
                    marginTop: '-4px',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    marginTop: '-4px',
                  }),
                }}
              />
            </div>
          </div>
          <div className="admin__form-copy-tributes__row-2">
            <div>
              <label htmlFor="patternName">Nome do Padrão:</label>
              <input
                id="patternName"
                type="text"
                value={selectedPattern?.value?.pattern}
                className="form-control"
                disabled
              />
            </div>
            <div>
              <label htmlFor="patternType">Tipo:</label>
              <input
                id="patternType"
                type="text"
                value={
                  selectedPattern !== null
                    ? selectedPattern?.value?.offline
                      ? 'Offline'
                      : 'Online'
                    : ''
                }
                className="form-control"
                disabled
              />
            </div>
            <div>
              <label htmlFor="patternStatus">Status:</label>
              <input
                id="patternStatus"
                type="text"
                value={
                  selectedPattern !== null
                    ? selectedPattern?.value?.isHomologated
                      ? 'Homologado'
                      : 'Não Homologado'
                    : ''
                }
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="admin__form-copy-tributes__row-3">
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
              data={tributesList}
              columns={[
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 100,
                  Cell: (props) =>
                    props.value === 'T' ? 'Tributo' : 'Operação',
                },
                {
                  Header: 'Código',
                  accessor: 'typeCode',
                  width: 100,
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  width: 300,
                },
              ]}
              defaultPageSize={10}
              loading={loadingTributes}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="danger"
          name="Cancelar"
          onClick={handleClose}
          disabled={loading}
          fill
        >
          Cancelar
        </Button>

        <Button
          bsStyle="info"
          name="Salvar"
          onClick={validateSubmit}
          disabled={loading}
          fill
        >
          Copiar Tributos
        </Button>
      </Modal.Footer>

      {isConfirmationModalOpen && (
        <AlertModal
          title="OS Digital"
          show={isConfirmationModalOpen}
          message={
            <>
              <span>
                Você tem certeza que deseja copiar os tributos do padrão{' '}
                {selectedPattern.value.pattern}?
              </span>
              <br />
              <br />
              <span>
                Após copiar, você poderá editar e as alterações ficarão
                disponíveis apenas para esse padrão.
              </span>
            </>
          }
          onCancel={() => setIsConfirmationModalOpen(false)}
          onSubmit={() => {
            handleSubmit()
            setIsConfirmationModalOpen(false)
          }}
          onHide={null}
        />
      )}
    </Modal>
  )
}
