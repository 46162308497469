import api from '../services/api'

const getRevenuesPerPeriodReport = (companyId, params) =>
  api.get(`report/revenue-per-period/${companyId}`, {
    params,
  })

export default {
  getRevenuesPerPeriodReport,
}
