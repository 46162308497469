import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { useAlerts } from 'v2/hooks/useAlerts';
import { useRecoilState } from 'recoil';
import { companyBranch as companyBranchAtom } from 'storage/companyDetailsStorage';

export default function WorkshopPanel() {
  const { getVisualizeWorkshopPanelAlerts } = useAlerts();
  const { companyId } = useAuth();
  const { activateWorkshopPanel, pointingByThePanel, autoPanelAppointment } =
    useSelector((state) => state.form.salesConfiguration.values);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [companyBranch] = useRecoilState(companyBranchAtom);

  function handleActivateWorkshopPanel() {
    if (activateWorkshopPanel) {
      dispatch(change('salesConfiguration', 'pointingByThePanel', false));
    }
    dispatch(
      change(
        'salesConfiguration',
        'activateWorkshopPanel',
        !activateWorkshopPanel
      )
    );
  }

  function handlePointingByThePanel() {
    if (!activateWorkshopPanel) {
      return toastr.warning(
        'Não foi possível ativar',
        'Necessário ativar o painel antes de ativar essa configuração'
      );
    }
    dispatch(
      change('salesConfiguration', 'pointingByThePanel', !pointingByThePanel)
    );
  }

  const handleVisualizeAllAlerts = async () => {
    setIsLoading(true);
    try {
      await getVisualizeWorkshopPanelAlerts(companyId);
    } catch (err) {
      toastr.error('Erro ao atualizar os status das notificações', `${err}.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="workshopPanel">
      <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
        <div>
          <label>Ativar o Painel da Oficina: </label>
        </div>
        <Toggle
          checked={activateWorkshopPanel}
          onChange={() => handleActivateWorkshopPanel()}
        />
      </Col>
      <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
        <div>
          <label>
            Apontar Serviço por {companyBranch === 5 ? 'Técnico' : 'Mecânico'}:{' '}
          </label>
        </div>
        <Toggle
          checked={pointingByThePanel}
          onChange={() => handlePointingByThePanel()}
        />
      </Col>
      <Col
        xs={12}
        sm={12}
        md={2}
        lg={2}
        className="toggle-column"
        style={{ width: '230px' }}
      >
        <div>
          <label>Apontamento automático</label>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Toggle
            checked={autoPanelAppointment}
            onChange={() => {
              dispatch(
                change(
                  'salesConfiguration',
                  'autoPanelAppointment',
                  !autoPanelAppointment
                )
              );
            }}
          />
        </div>
      </Col>

      <Col xs={12} sm={12} md={2} lg={2}>
        <div>
          <label />
        </div>
        <div>
          <button
            className="button button-h35 button-blue flex gap-050"
            onClick={async () => await handleVisualizeAllAlerts()}
            disabled={isLoading}
          >
            <span>Limpar Notificações</span>
            {isLoading ? (
              <span
                className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
              />
            ) : (
              <FontAwesomeIcon icon={faBell} style={{ fontSize: '17px' }} />
            )}
          </button>
        </div>
      </Col>
    </div>
  );
}
