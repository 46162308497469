import config from '../config'
import api from "../services/api"

const getAllByCompany = async companyId => {
    try {
      const response = await api.get(config.endpoint + `sat-manufacturers?companyId=${companyId}`)
      return response.data
    } catch(err) {
      throw err
    }
  }

export default {
    getAllByCompany
}