import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import Integrations from './Integrations'

import './styles.css'

const IntegrationsHomePage = () => {
  return (
    <main className='integrations'>
      <BreadCrumb data={['Início', 'Integrações']} path={['home', null]} />
      <Integrations />
    </main>
  )
}

export default IntegrationsHomePage
