import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import HomeIssuedSlip from './IssuedSlip';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const IssuedSlip = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Financeiro', 'Boletos Emitidos']}
            path={['home', null, null, null]}
          />
          <HomeIssuedSlip />
        </Row>
      </Grid>
    </div>
  );
};

export default IssuedSlip;
