import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

const CertificateDropZone = ({ onUpload }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) return
    onUpload(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pfx',
  })
  const { digitalCertificateFile, isDigitalCertificateImported } = useSelector(
    (state) => state.form.settingsTaxData.values
  )

  return (
    <div className="certificate-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Solte o arquivo aqui</p>
      ) : digitalCertificateFile || isDigitalCertificateImported ? (
        <p style={{ fontSize: '15px' }}>
          Certificado Importado <FontAwesomeIcon icon={faCheck} color="green" />
        </p>
      ) : (
        <p>Importar</p>
      )}
    </div>
  )
}

export default CertificateDropZone
