import React from 'react';
import ReactInputMask from 'react-input-mask';
import ReactSelect from 'react-select';
import MaskedInput from 'react-text-mask';

const VehicleData = ({
  brands,
  vehiclePlate,
  setVehiclePlate,
  loadVehicleByPlate,
  selectedBrand,
  setSelectedBrand,
  selectedModel,
  setSelectedModel,
  vehicles,
  loading,
  register,
}) => {
  return (
    <>
      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Placa: <span style={{ color: 'red' }}>*</span>
          </span>
          <MaskedInput
            className="form-control foco-input"
            name="newVehiclePlate"
            mask={[
              /[A-Za-z]/,
              /[A-Za-z]/,
              /[A-Za-z]/,
              '-',
              /[0-9]/,
              /[A-Za-z0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            value={vehiclePlate}
            onChange={(e) => setVehiclePlate(e.target.value.toUpperCase())}
            style={{ width: '10rem', height: '35px', fontSize: '14px' }}
          />
        </div>

        <div className="client__customer-list-button">
          <button
            className="btn btn-sucesso"
            type="button"
            onClick={() => loadVehicleByPlate()}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Buscar Dados do Veículo
          </button>
        </div>
      </div>

      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Marca: <span style={{ color: 'red' }}>*</span>
          </span>
          <select
            className="form-control foco-input"
            style={{
              width: '23rem',
              height: '35px',
              fontSize: '14px',
            }}
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">Selecione</option>
            {brands.map((b) => (
              <option value={b.Brand} key={b.Brand}>
                {b.Brand}
              </option>
            ))}
          </select>
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Modelo: <span style={{ color: 'red' }}>*</span>
          </span>
          <ReactSelect
            isSearchable
            defaultValue={{}}
            options={vehicles}
            onChange={(e) => setSelectedModel(e)}
            value={selectedModel}
            placeholder="Selecione"
            openMenuOnFocus
            noOptionsMessage={() => 'Nenhum modelo encontrado'}
            styles={{
              control: (base) => ({
                ...base,
                width: '30rem',
                height: '35px',
                minHeight: '35px',
                borderColor: '#E3E3E3',
                fontSize: '14px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              valueContainer: (provided) => ({
                ...provided,
                marginTop: '-6px',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                marginTop: '-6px',
              }),
            }}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Renavam:</span>
          <input
            type="number"
            name="Renavam"
            className="form-control foco-input"
            style={{
              width: '20rem',
              height: '35px',
              fontSize: '14px',
            }}
            {...register('Vin_Renavam')}
          />
        </div>
      </div>

      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Ano Fab.: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem', height: '35px', fontSize: '14px' }}
            {...register('Year_Manufacture')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Ano Mod.: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem', height: '35px', fontSize: '14px' }}
            {...register('Year_Model')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            KM:{' '}
            <span style={{ color: 'red', height: '35px', fontSize: '14px' }}>
              *
            </span>
          </span>
          <input
            onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
            type="number"
            className="form-control foco-input"
            style={{ width: '8rem' }}
            {...register('Kilometer')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Cor:</span>
          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '10rem', height: '35px', fontSize: '14px' }}
            {...register('Color')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Chassi/Série:</span>
          <input
            type="text"
            className="form-control foco-input"
            style={{ width: '25rem', height: '35px', fontSize: '14px' }}
            {...register('NChassi')}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">Tipo de Combustível:</span>
          <select
            name="Fuel_Type"
            className="form-control foco-input"
            style={{
              width: '15rem',
            }}
            {...register('Fuel_Type')}
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Outro">Outro</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default VehicleData;
