import React from 'react';
import { Modal } from 'react-bootstrap';

import { listCopyProfilesByCompanyId } from 'v2/repositories/AccessPermissionsRepository';

import { useFormContext } from 'react-hook-form';
import { useAuth } from 'contexts/auth';
import { useState } from 'react';
import { useEffect } from 'react';

import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { copyProfileByProfileId } from 'v2/repositories/AccessPermissionsRepository';
import { SearchInput } from 'v2/components/form';
import { Table } from 'v2/components/Table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import AlertModal from 'components/AlertModal/AlertModal';
import useDebounce from 'hooks/useDebounce';

const ConfirmationMessage = () => {
  return (
    <div className="flex column gap-075">
      <p>
        Você tem certeza que deseja copiar as permissões do perfil selecionado
        para esse perfil? Para aplicar o efeito, será necessário relogar no
        sistema.
      </p>
      <span className="text-center text-red">
        As permissões serão aplicadas após salvar o cadastro/edição
      </span>
    </div>
  );
};

export function CopyProfileModal({ isOpen, onCancel }) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { companyId } = useAuth();

  const { watch, setValue } = useFormContext();
  const { profileId } = watch();

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [serializedData, setSerializedData] = useState([]);
  const [profileIdToCopy, setProfileIdToCopy] = useState('');

  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 600);

  useEffect(() => {
    handleFetch();
  }, [companyId]);

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const res = await listCopyProfilesByCompanyId(companyId);

      setDataTable(res.data);
      setSerializedData(res.data);
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const filterTable = (query) => {
    if (!query) {
      setSerializedData(dataTable);
    } else {
      const filteredData = dataTable.filter((item) =>
        item.description.toLowerCase().includes(query.toLowerCase())
      );
      setSerializedData(filteredData);
    }
  };

  useEffect(() => {
    filterTable(queryInput);
  }, [queryInput]);

  const handleSubmit = async () => {
    setIsConfirmationModalOpen(false);
    setIsLoading(true);

    try {
      const res = await copyProfileByProfileId(
        profileIdToCopy,
        profileId,
        companyId
      );

      setValue('groupFeatures', res.data.groupFeatures);
      toastr.success(
        'Permissões aplicadas',
        ' As permissões foram copiadas e aplicadas nesse perfil. '
      );
      handleBack();
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  function handleBack() {
    onCancel();
  }

  const columns = [
    {
      Header: 'Perfil de Acesso',
      accessor: 'description',
    },
    {
      Header: 'Tipo de Acesso',
      accessor: 'accessType',
    },
    {
      accessor: 'id',
      width: 50,
      Cell: (props) => {
        return (
          <button
            title="Editar"
            className="button-icon"
            onClick={() => {
              setProfileIdToCopy(props.value);
              setIsConfirmationModalOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              color="#72bb53"
              size="lg"
              style={{
                color: '#72bb53',
                cursor: 'pointer',
              }}
            />
          </button>
        );
      },
    },
  ];

  return (
    <>
      <Modal.Dialog open={isOpen} width={800}>
        <Modal.Header>
          <Modal.Title>
            <strong style={{ fontSize: 18 }}>Seleção de Perfil</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <SearchInput
                id="input__query"
                placeholder="Pesquisa por Perfil de Acesso"
                width={230}
                onChange={(e) => debounceQueryInput(e.target.value)}
              />
              <Table
                columns={columns}
                data={serializedData}
                loading={isFetching}
                defaultPageSize={5}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-050">
            <button className="button button-red w-150px" onClick={handleBack}>
              Cancelar
            </button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>

      {isConfirmationModalOpen && (
        <AlertModal
          title={'Permissão de Acesso'}
          show={isConfirmationModalOpen}
          message={<ConfirmationMessage />}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onSubmit={() => handleSubmit()}
          closeButton={false}
          onHide={(e) => e}
        />
      )}
    </>
  );
}
