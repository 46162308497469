import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import AlertModal from 'components/AlertModal/AlertModal';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';

import { currentBrandingName } from 'v2/helpers/brandingHelpers';

import {
  daysBetweenInstallmentOptions,
  getPaymentConditionBreadcrumb,
  hasEntryOptions,
  installmentQuantityOptions,
  isFeeActiveOptions,
} from './helpers';

import PaymentConditionsRepository from 'repositories/PaymentConditions';

import { useAuth } from 'contexts/auth';
import constants from 'utils/constants';

import { RegisterPaymentConditionForm } from './Form';
import './index.css';

const defaultFormValues = {
  description: '',
  installmentsQuantity: '',
  daysBetweenInstallment: '',
  hasEntry: '',
  minValue: 0,
  isActive: true,
  isFeeActive: isFeeActiveOptions[0],
  feePercent: 0,
  showAsSuggestion: false,
};

const RegisterPaymentCondition = ({
  match,
  hasParentModal,
  handleParentModalClose,
  shouldTriggerReloadList,
  reloadConditionsList,
  modalConditionId,
}) => {
  const [conditionId, setConditionId] = useState(null);

  const { companyId } = useAuth();
  const history = useHistory();

  const breadCrumbInformation = getPaymentConditionBreadcrumb(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isUnavailableFeatureModalOpen, setIsUnavailableFeatureModalOpen] =
    useState(false);

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  });

  const { setValue, control, handleSubmit } = formMethods;

  const { isFeeActive } = useWatch({
    control,
  });

  const serializeData = (formData) => {
    const serializedData = {
      description: formData.description,
      condition: formData.installmentsQuantity.value,
      hasEntry: formData.hasEntry?.value,
      daysBetweenInstallment: formData.daysBetweenInstallment?.value,
      minValue: formData.minValue,
      isActive: formData.isActive,
      isFeeActive: formData.isFeeActive?.value,
      feePercent: formData.feePercent,
      showAsSuggestion: formData.showAsSuggestion,
      companyId,
    };

    return serializedData;
  };

  const validateSubmit = (formData) => {
    const { description, condition, daysBetweenInstallment, hasEntry } =
      formData;

    try {
      switch (true) {
        case !description ||
          !condition ||
          !daysBetweenInstallment ||
          hasEntry === undefined:
          throw false;
        default:
          return true;
      }
    } catch (err) {
      toastr.warning(
        currentBrandingName,
        'Preencha todos os campos obrigatórios'
      );
      return false;
    }
  };

  const create = async (data) => {
    setIsLoading(true);
    try {
      await PaymentConditionsRepository.create(data);
      toastr.success(currentBrandingName, 'Condição criada com sucesso');

      next();
    } catch (err) {
      console.log(err);
      handlePaymentConditionError(err);
    }
    setIsLoading(false);
  };

  const update = async (data) => {
    setIsLoading(true);
    try {
      await PaymentConditionsRepository.update(conditionId, data);
      toastr.success(currentBrandingName, 'Condição atualizada com sucesso');

      next();
    } catch (err) {
      console.log(err);
      handlePaymentConditionError(err);
    }
    setIsLoading(false);
  };

  const handlePaymentConditionError = (err) => {
    let errorMessage = err.response.data.error;

    switch (errorMessage) {
      case 'Você ja possui uma condição com esta descrição':
        toastr.warning(
          'Cadastro não realizado',
          'Já existe uma condição de pagamento com essa descrição. Verifique e tente novamente'
        );
        break;

      default:
        toastr.error(
          currentBrandingName,
          'Erro ao atualizar. Tente novamente!'
        );
        break;
    }
  };

  const next = () => {
    if (hasParentModal) handleParentModalClose();
    else history.push(constants.ROUTES.PAYMENT_CONDITIONS);

    if (shouldTriggerReloadList) reloadConditionsList();
  };

  const handleSubmitButtonClick = (formData) => {
    const serializedData = serializeData(formData);
    const isSubmitValid = validateSubmit(serializedData);

    if (isSubmitValid) {
      return conditionId ? update(serializedData) : create(serializedData);
    }
  };

  const loadConditionData = async (conditionId) => {
    setIsLoading(true);
    try {
      const conditionOfPayment = await PaymentConditionsRepository.getById(
        conditionId
      );

      const {
        Condition,
        Description,
        IsActive,
        daysBetweenInstallment,
        hasEntry,
        isFeeActive,
        feePercent,
        minValue,
        showAsSuggestion,
      } = conditionOfPayment;

      const data = {
        description: Description,
        isActive: IsActive,
        feePercent: +feePercent,
        minValue: +minValue,
        showAsSuggestion,
        hasEntry: hasEntryOptions.find((option) => option.value === hasEntry),
        installmentsQuantity: installmentQuantityOptions.find(
          (option) => option.value === Condition
        ),
        daysBetweenInstallment: daysBetweenInstallmentOptions.find(
          (option) => option.value === +daysBetweenInstallment
        ),
        isFeeActive: isFeeActiveOptions.find(
          (option) => option.value === isFeeActive
        ),
      };

      for (const key in data) {
        const value = data[key];
        setValue(key, value);
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        currentBrandingName,
        'Erro ao carregar os dados. Tente novamente!'
      );
      next();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const conditionId = match?.params?.id || modalConditionId;
    if (conditionId) {
      setConditionId(conditionId);
      loadConditionData(conditionId);
    }
  }, [match?.params?.id, modalConditionId]);

  useEffect(() => {
    if (!isFeeActive) {
      setValue('feePercent', 0);
    }
  }, [isFeeActive]);

  return (
    <div className="content">
      <div>
        <BreadCrumb {...breadCrumbInformation} />
        <span id="required-field-label">* Campos Obrigatórios</span>
      </div>

      <FormProvider {...formMethods}>
        <RegisterPaymentConditionForm
          setIsUnavailableFeatureModalOpen={setIsUnavailableFeatureModalOpen}
        />
      </FormProvider>

      <div className="flex end gap-075 mt-2">
        <button
          className="button button-red button-h35 button-w100"
          disabled={isLoading}
          onClick={() => setIsCancelModalOpen(true)}
        >
          Cancelar
        </button>
        <button
          className="button button-green button-h35 button-w100"
          onClick={handleSubmit(handleSubmitButtonClick)}
          disabled={isLoading}
        >
          Salvar
        </button>
      </div>

      <UnavailableFeatureModal
        show={isUnavailableFeatureModalOpen}
        onHide={() => setIsUnavailableFeatureModalOpen(false)}
      />

      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={
          hasParentModal
            ? handleParentModalClose()
            : () => history.push(constants.ROUTES.PAYMENT_CONDITIONS)
        }
        message="Ao cancelar, nenhuma informação será salva. Deseja realmente cancelar?"
      />
    </div>
  );
};

export { RegisterPaymentCondition };
