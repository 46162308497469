import React from 'react'

const AppointmentsTabSelector = ({ selectedTab, setSelectedTab }) => {
  const selectedStyle = {
    backgroundColor: '#008db1',    
    borderColor: '#008db1',    
    color: '#FFF',
  }

  return (
    <div className='appointments-tab-selector'>
      <div
        className='appointments-tab-title'
        onClick={() => setSelectedTab('appointments-list')}
        style={selectedTab === 'appointments-list' ? selectedStyle : {}}
      >
        Apontamentos Realizados
      </div>
      <div
        className='appointments-tab-title'
        onClick={() => setSelectedTab('appointments-create')}
        style={selectedTab === 'appointments-create' ? selectedStyle : {}}
      >
        Lançamento de Apontamento
      </div>
    </div>
  )
}

export default AppointmentsTabSelector
