import api from '../services/api';

export const authenticateUser = async (data) => {
  const res = await api.post('authentication/user', data);
  return res.data;
};

export const validateUser = async (data) => {
  const res = await api.post('authentication/user/validate', data);
  return res.data;
};

const AuthenticationRepository = {
  authenticateUser,
};

export { AuthenticationRepository };
