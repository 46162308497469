import React from 'react';

export const ViewNFeSales = ({ sales, NFCeCodes }) => {
  const processSalesData = () => {
    const result = {
      vendas: [],
      OS: [],
      PDV: [],
      saleIds: [],
    };

    sales.forEach((item) => {
      result.saleIds.push(item.Code);

      if (item.SalesType_id === 2) {
        result.vendas.push(item.Code);
      } else if (item.SalesType_id === 3) {
        result.OS.push(item.Code);
      } else if (item.SalesType_id === 4) {
        result.PDV.push(item.Code);
      }
    });

    return result;
  };

  const allCodesOpen = NFCeCodes?.filter(
    (item) => item.status === 'Aberta' || item.status === 'Aberta c/ Erro'
  )
    .map((item) => item.code)
    .join(', ');
  const allCodesOther = NFCeCodes?.filter(
    (item) => item.status !== 'Aberta' && item.status !== 'Aberta c/ Erro'
  )
    .map((item) => item.code)
    .join(', ');

  const codes = [
    {
      order: 2,
      title: 'NFC-e Não Emitida',
      color: 'red',
      code: allCodesOpen,
    },
    {
      order: 1,
      title: 'NFC-e Emitida',
      color: '#428bca',
      code: allCodesOther,
    },
  ].sort((a, b) => a.order - b.order);

  const { vendas, OS, PDV } = processSalesData();

  return (
    <div>
      <div>
        <p>As vendas vinculadas são:</p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <strong style={{ minWidth: '60px' }}>Vendas:</strong>
          <input
            style={{
              flex: 1,
              height: '80px',
              borderRadius: '5px',
              border: 'none',
              paddingLeft: '10px',
              color: '#428bca',
            }}
            type="text"
            disabled
            value={vendas.join(', ')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <strong style={{ minWidth: '60px' }}>OS:</strong>
          <input
            style={{
              flex: 1,
              height: '80px',
              borderRadius: '5px',
              border: 'none',
              paddingLeft: '10px',
              color: '#428bca',
            }}
            type="text"
            disabled
            value={OS.join(', ')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <strong style={{ minWidth: '60px' }}>PDV:</strong>
          <input
            style={{
              flex: 1,
              height: '80px',
              borderRadius: '5px',
              border: 'none',
              paddingLeft: '10px',
              color: '#428bca',
            }}
            type="text"
            disabled
            value={PDV.join(', ')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {codes.map((item, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <strong style={{ minWidth: '60px' }}> {item.title}:</strong>
                <input
                  key={`open-${index}`}
                  style={{
                    flex: 1,
                    height: '40px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    paddingLeft: '10px',
                    color: item.color,
                  }}
                  type="text"
                  disabled
                  value={item.code}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
