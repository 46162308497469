import React, { useEffect } from 'react';
import { Grid, Row } from 'react-bootstrap';

import Dashboard from './Dashboard';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import Advertisements from 'client/components/Advertisements';
import OnboardingModal from 'client/components/OnboardingModal';
import DequimentCustomersModal from 'client/components/DequimentCustomersModal';
import NewsModal from 'client/components/NewsModal';
import PollsModalMain from 'client/components/ModalPolls';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { useHistory } from 'react-router-dom';

import './styles.css';
import constants from 'utils/constants';

const Home = () => {
  const { isPlanFree } = usePlanSignatureContext();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shouldRedirectToSale = params.get('redirectSales');

    if (shouldRedirectToSale) {
      history.push(constants.ROUTES.HOME);
    }
  }, []);

  return (
    <>
      <Advertisements />
      <div className="content">
        <Grid fluid>
          <Row>
            <BreadCrumb data={['Início']} path={['home']} />
            <Dashboard />
          </Row>
        </Grid>
      </div>
      {!isPlanFree && <PollsModalMain />}
      <OnboardingModal />
      <NewsModal />
      <DequimentCustomersModal />
    </>
  );
};

export default Home;
