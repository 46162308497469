import api from '../services/api';
import config from '../config';

const authenticate = async (email, password) => {
  const response = await api.post(config.endpoint + `admins/authenticate`, {
    Email: email,
    Password: password,
  });
  return response.data;
};

const getByEmail = async (email) => {
  const response = await api.get(config.endpoint + `admins?email=${email}`);
  return response.data[0];
};

const show = async (id) => {
  const response = await api.get(config.endpoint + `admins/${id}`);
  return response.data;
};

const updateCompany = async (id, company) => {
  await api.put(config.endpoint + `admins/clients/${id}`, company);
};

const blockClient = async (signatureId, data) => {
  await api.put(config.endpoint + `admins/${signatureId}/block-client`, data);
};

const getAllClients = async (params) => {
  const response = await api.get(config.endpoint + 'admins/client/find-all', {
    params,
  });
  return response.data;
};

const getClientFullInformation = async (id) => {
  const response = await api.get(
    config.endpoint + 'admins/client/find-one/' + id
  );
  return response.data;
};

const getAllTaxByClientes = async () => {
  const response = await api.get(
    config.endpoint + 'admins/counters/invoice-by-clients'
  );
  return response.data;
};

const getClientsIndicators = async () => {
  const response = await api.get(config.endpoint + 'admins/counters/clients');
  return response.data;
};

const getClientsReport = async ({ finalDate, initialDate }) => {
  const response = await api.get(
    config.endpoint +
      `admins/reports/clients?initialDate=${initialDate}&finalDate=${finalDate}`
  );
  return response.data;
};

const getInvoiceClientsReport = async ({ finalDate, initialDate }) => {
  const response = await api.get(
    `admins/reports/invoice-by-clients?initialDate=${initialDate}&finalDate=${finalDate}`
  );
  return response.data;
};

const adminsRepository = {
  authenticate,
  getByEmail,
  show,
  updateCompany,
  getAllClients,
  getAllTaxByClientes,
  getClientsIndicators,
  getClientsReport,
  getInvoiceClientsReport,
  getClientFullInformation,
  blockClient,
};

export default adminsRepository;
