import config from '../config'
import api from '../services/api'

const index = async (query) => {
  try {
    const response = await api.get(config.endpoint + 'product-base')
    return response.data
  } catch (err) {
    throw err
  }
}

const store = async (data) => {
  try {
    const response = await api.post(
      config.endpoint + 'product-base/import',
      data
    )
    return response
  } catch (err) {
    throw err
  }
}

export default {
  index,
  store,
}
