import React, { useState, useEffect } from 'react';
import { IndicatorCard } from '../../components/IndicatorCard';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import {
  ContainerMainPageScreen,
  BackgroundWrapper,
  CardWrapper,
  FlexEndWrapper,
  ClickableWrapper,
} from './MainPage.styles';
import { CRMRepository } from 'v2/repositories/CRMRepository';
import { Text } from 'v2/components/Text';
import useCRMStore from '../../store/crm-store';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { useThemeContext } from 'v2/contexts/themeContext';

export function MainPage() {
  const { companyId } = useAuth();
  const { isLoading, fetchLoading } = useLoading();
  const [birthdayData, setBirthdayData] = useState(null);
  const [returnData, setReturnData] = useState(null);
  const { setDocumentTitle, brandingId } = useThemeContext();

  const { setOpenNewCampaignForm, setSelectedOption, setSelectedCard } =
    useCRMStore();

  async function loadBirthdayData() {
    try {
      const birthdayData = await CRMRepository.getBirthDayIndicators(companyId);

      setBirthdayData(birthdayData);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as contas diárias. Por favor, tente novamente.'
      );
    }
  }

  async function loadReturnData() {
    try {
      const returnData = await CRMRepository.getReturnIndicators(companyId);

      setReturnData(returnData);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as contas diárias. Por favor, tente novamente.'
      );
    }
  }

  const handleFetchData = async () => {
    await fetchLoading(fetchData);
  };

  const fetchData = async () => {
    try {
      await Promise.all([loadBirthdayData(), loadReturnData()]);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.'
      );
    }
  };

  const handleSelectCard = (type, value) => {
    setSelectedOption('Campanhas');
    setOpenNewCampaignForm(true);
    setSelectedCard({ type, value });
  };

  useEffect(() => {
    if (companyId) {
      handleFetchData();
    }
  }, [companyId]);

  useEffect(() => {
    if (brandingId) {
      setDocumentTitle('CRM');
    }
  }, [brandingId]);

  return (
    <ContainerMainPageScreen>
      <Text color="gray300" fontWeight="bold" size="4x">
        Aniversariantes
      </Text>
      <CardWrapper>
        <BackgroundWrapper>
          <ClickableWrapper
            onClick={() => handleSelectCard('birthday', 'today')}
          >
            <IndicatorCard
              title="Hoje"
              value={birthdayData?.todayMoney}
              showTotalBadge
              total={birthdayData?.todayQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="red"
              loading={isLoading}
            />
          </ClickableWrapper>

          <ClickableWrapper
            onClick={() => handleSelectCard('birthday', 'week')}
          >
            <IndicatorCard
              title="Essa Semana"
              value={birthdayData?.weekMoney}
              showTotalBadge
              total={birthdayData?.weekQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>

          <ClickableWrapper
            onClick={() => handleSelectCard('birthday', 'month')}
          >
            <IndicatorCard
              title="Esse mês"
              value={birthdayData?.monthMoney}
              showTotalBadge
              total={birthdayData?.monthQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>
        </BackgroundWrapper>
        <BackgroundWrapper>
          <ClickableWrapper
            onClick={() => handleSelectCard('birthday', 'nextMonth')}
          >
            <IndicatorCard
              title="Próximo mês"
              value={birthdayData?.nextMonthMoney}
              showTotalBadge
              total={birthdayData?.nextMonthQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>
        </BackgroundWrapper>
      </CardWrapper>
      <FlexEndWrapper style={{ width: '1020px', marginBottom: '10px' }}>
        * Valores Aproximados de compras pelos clientes
      </FlexEndWrapper>
      <Text color="gray300" fontWeight="bold" size="4x">
        Previsão de Retorno
      </Text>
      <CardWrapper>
        <BackgroundWrapper>
          <ClickableWrapper onClick={() => handleSelectCard('return', 'today')}>
            <IndicatorCard
              title="Hoje"
              value={returnData?.todayMoney}
              showTotalBadge
              total={returnData?.todayQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="red"
              loading={isLoading}
            />
          </ClickableWrapper>
          <ClickableWrapper onClick={() => handleSelectCard('return', 'week')}>
            <IndicatorCard
              title="Essa Semana"
              value={returnData?.weekMoney}
              showTotalBadge
              total={returnData?.weekQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>
          <ClickableWrapper onClick={() => handleSelectCard('return', 'month')}>
            <IndicatorCard
              title="Esse mês"
              value={returnData?.monthMoney}
              showTotalBadge
              total={returnData?.monthQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>
        </BackgroundWrapper>
        <BackgroundWrapper>
          <ClickableWrapper
            onClick={() => handleSelectCard('return', 'nextMonth')}
          >
            <IndicatorCard
              title="Próximo mês"
              value={returnData?.nextMonthMoney}
              showTotalBadge
              total={returnData?.nextMonthQuantity}
              type="single"
              variant="gray"
              titleColor="gray"
              textColor="indigo"
              loading={isLoading}
            />
          </ClickableWrapper>
        </BackgroundWrapper>
      </CardWrapper>
      <FlexEndWrapper style={{ width: '1020px' }}>
        * Previsibilidade de ganho com o retorno
      </FlexEndWrapper>
    </ContainerMainPageScreen>
  );
}
