import React from 'react';
import { Modal } from 'react-bootstrap';
import { InputLabel } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { useSelector } from 'react-redux';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';

export const ApprovedFinancingDialog = ({
  isOpen,
  handleClose,
  saleStatusId,
}) => {
  const { KoinTransaction } = useSelector((state) => state.saleReducer);
  const { transactionId, createdAt, KoinInstallmentsOptions } = KoinTransaction;
  const chosenInstallment = KoinInstallmentsOptions.find(
    (option) => option.isChosen
  );
  const { description, orderValue } = chosenInstallment;

  const handleTitle = () => {
    switch (saleStatusId) {
      case 6:
        return 'Proposta de Financiamento em Análise';
      case 7:
        return 'Proposta de Financiamento Aprovada';
      default:
        return 'Proposta de Financiamento';
    }
  };

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-45w">
      <Modal.Header>
        <Modal.Title>
          <strong>{handleTitle()}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputContainer variant="row">
            <InputLabel>ID da Transação do Financiamento: </InputLabel>
            {transactionId}
          </InputContainer>

          <InputContainer variant="row">
            <InputLabel>Valor do Financiamento: </InputLabel>
            {currency(orderValue)}
          </InputContainer>

          <InputContainer variant="row">
            <InputLabel>Data: </InputLabel>
            {format(new Date(createdAt), 'dd/MM/yyyy') || ''}
          </InputContainer>

          <InputContainer variant="row">
            <InputLabel>Parcelas: </InputLabel>
            {description}
          </InputContainer>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex end">
        <div>
          <button
            className="button button-h35 button-red"
            onClick={handleClose}
          >
            Voltar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
