import React from 'react'
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import { format } from 'date-fns'

import {
  cepMask,
  cnpjMask,
  cpfMask,
  milhar,
  phoneMask,
} from '../../../client/components/ToNormalize/ToNormalize'

import motoImg from '../../../assets/img/checklist-images/moto.png'
import gasImg from '../../../assets/img/checklist-images/gas.png'

import fontRobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf'
import fontRobotoBold from '../../../assets/fonts/Roboto-Bold.ttf'
import fontRobotoThin from '../../../assets/fonts/Roboto-Thin.ttf'

const Card = ({ children, style, ...rest }) => (
  <View style={[styles.cardBorder, style]} {...rest}>
    {children}
  </View>
)

const Table = ({
  children,
  style = {},
  titleRight,
  titleLeft,
  data = [],
  ...rest
}) => (
  <View style={[styles.tableContainer, style]}>
    <View style={styles.tableHeader}>
      <Text style={styles.bold}>{titleLeft}</Text>
      {titleRight ? <Text style={styles.bold}>{titleRight}</Text> : null}
    </View>
    <View style={styles.table} {...rest}>
      {data.map((item) => (
        <View key={item} style={styles.tableItemContainer}>
          <Text style={styles.tableItemText}>{item}</Text>
          <View style={styles.tableItemStatus} />
        </View>
      ))}
    </View>
  </View>
)

export const MotosSheet = ({ customer }) => {
  const printDate = format(new Date(customer.date), 'dd/MM/yyyy')

  const company = {
    Url_Logo: customer.company.Url_Logo,
    Trading_Name: customer.company.Trading_Name,
    Cpf_Cnpj:
      customer.company.Cpf_Cnpj.length <= 11
        ? cpfMask(customer.company.Cpf_Cnpj)
        : cnpjMask(customer.company.Cpf_Cnpj),
    Type: customer.company.Cpf_Cnpj.length <= 11 ? 'Física' : 'Jurídica',
    IE: customer.company.IE ? customer.company.IE : '',
    Address: `${customer.company.Address[0].Address}, ${customer.company.Address[0].Address_Number}, ${customer.company.Address[0].Neighborhood}`,
    AddressCityState: `${customer.company.Address[0].City}/${customer.company.Address[0].State}`,
    Zipcode: customer.company.Address[0].Zipcode
      ? cepMask(customer.company.Address[0].Zipcode)
      : '',
    Telephone: customer.company.Phones[0].Number_Phone1
      ? phoneMask(customer.company.Phones[0].Number_Phone1)
      : '',
    Email: customer.company.Email ? customer.company.Email : '',
  }

  const vehicle = {
    description: customer.vehicle?.Model || '',
    licensePlate: customer.vehicle?.License_Plate || '',
    color: customer.vehicle?.Color || '',
    manufactureYear: customer.vehicle?.Year_Manufacture || '',
    modelYear: customer.vehicle?.Year_Model || '',
    kilometers: customer.vehicle?.Kilometers
      ? milhar(customer.vehicle?.Kilometers)
      : '',
    brand: customer.vehicle?.Brand,
  }

  const customerName = customer.Company_Name

  let contentTable1 = []
  for (let i = 0; i < 17; i++) {
    contentTable1 = contentTable1.concat('')
  }

  return (
    <Document
      title={`impresso-Checklist-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page style={styles.page}>
        {/* Dados da Empresa */}
        <Card style={{ marginBottom: 7 }}>
          <View fixed style={{ flexDirection: 'row', width: '100%' }}>
            {company.Url_Logo ? (
              <View
                style={{
                  width: 82,
                  height: '100%',
                  borderRightWidth: 1,
                  borderRightColor: 'black',
                  padding: 3,
                }}
              >
                <Image
                  src={company.Url_Logo}
                  style={{
                    width: 75,
                    height: 60,
                  }}
                />
              </View>
            ) : null}

            <View
              fixed
              style={{
                width: '80%',
                marginLeft: 5,
                height: '100%',
                minHeight: 65,
              }}
            >
              <Text style={styles.mainTitle}>{company.Trading_Name}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '3px',
                  width: '100%',
                }}
              >
                <View>
                  <Text>
                    {company.Type === 'Jurídica' ? 'CNPJ' : 'CPF'}:{' '}
                    {company.Cpf_Cnpj}
                  </Text>
                </View>

                <View>{company.IE ? <Text>IE: {company.IE}</Text> : null}</View>

                <View>
                  <Text>Data: {printDate}</Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row', paddingTop: '3px' }}>
                <Text>
                  Endereço: {customer.company.Address[0].Address}{' '}
                  {customer.company.Address[0].Address_Number},{' '}
                  {customer.company.Address[0].Neighborhood}
                </Text>
                <Text style={{ paddingLeft: '2px' }}>
                  - {company.AddressCityState}{' '}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  paddingTop: '3px',
                }}
              >
                <Text style={{ paddingRight: '10px' }}>
                  CEP: {company.Zipcode}
                </Text>
                <Text style={{ paddingRight: '10px' }}>
                  Telefone: {company.Telephone}
                </Text>
                <Text>Email: {company.Email}</Text>
              </View>
            </View>
          </View>
        </Card>
        {/* Dados da Empresa --- FIM --- */}

        <View
          style={{
            borderTopWidth: 1,
            borderColor: 'black',
            borderStyle: 'dashed',
            borderRadius: 0,
          }}
        />

        {/* Dados do Cliente */}
        <View style={{ paddingTop: 7 }}>
          <View style={[styles.customerRow1]}>
            <View>
              <Text>
                <Text style={styles.bold}>Cliente: </Text> {customerName}
              </Text>
            </View>
            <View style={{ paddingLeft: !customerName ? 170 : 20 }}>
              <Text>
                <Text style={styles.bold}>Placa: </Text> {vehicle.licensePlate}
              </Text>
            </View>
            <View
              style={{
                paddingLeft: !customerName
                  ? 70
                  : !vehicle.licensePlate
                  ? 70
                  : 20,
              }}
            >
              <Text>
                <Text style={styles.bold}>Veículo: </Text> {vehicle.description}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Marca: </Text> {vehicle.brand}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>KM Atual: </Text> {vehicle.kilometers}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Cor: </Text> {vehicle.color}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Ano Fb.: </Text>{' '}
                {vehicle.manufactureYear}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Ano Mod.: </Text> {vehicle.modelYear}
              </Text>
            </View>
          </View>
          {/* Dados do Cliente --- FIM --- */}

          <View style={[styles.customerRow1]}>
            <Text style={styles.bold}>Inspeção executada por:</Text>
            <View
              style={{
                width: '80%',
                borderBottomWidth: 1,
                borderColor: 'black',
                borderRadius: 0,
                marginLeft: 2,
                height: 15,
              }}
            />
          </View>
        </View>

        <View style={styles.imgContainer}>
          <Image src={motoImg} />
          <Text style={[styles.bold, { marginTop: 5 }]}>
            M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F = Faltando
          </Text>
        </View>

        <View style={styles.detailsContainer}>
          <View style={styles.detailsLeft}>
            <Table
              titleLeft='ITENS OBSERVADOS'
              data={contentTable1}
              titleRight='Status'
              style={{
                marginTop: 10,
              }}
            />
          </View>
          <View style={styles.detailsRight}>
            <View style={styles.detailsRightContent}>
              <Text style={styles.detailsRightText}>
                Medidor de Combustível
              </Text>
              <Image src={gasImg} style={styles.gasImg} />
              {/* <View style={styles.malfunctionsContainer}> */}
              <View style={styles.withoutMalfunctions}>
                <View style={styles.box} />
                <Text style={[styles.underline, { marginRight: 2 }]}>SEM</Text>
                <Text>Avarias</Text>
              </View>
              <View style={styles.withMalfunctions}>
                <View style={styles.box} />
                <Text style={[styles.underline, { marginRight: 2 }]}>COM</Text>
                <Text>Avarias</Text>
              </View>
              {/* </View> */}
            </View>
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.bold}>OBSERVAÇÕES</Text>
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
        </View>

        <View style={styles.footer}>
          <Text style={styles.bold}>
            Data/Hora: ______/______/______ ___________
          </Text>
          <Text style={styles.bold}>
            Ass. Cliente: _________________________________________
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span: {
    fontSize: 9,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  underline: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  customerRow1: {
    paddingTop: 1,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  imgContainer: {
    marginTop: 5,
    textAlign: 'center',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsLeft: {
    width: '70%',
  },
  detailsRight: {
    width: '30%',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  tableItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    marginTop: -1.2,
  },
  tableItemText: {
    width: 365,
    height: 18.5,
    fontSize: 8,
    borderWidth: 1,
    borderColor: '#444444',
    paddingTop: 1,
    paddingLeft: 4,
  },
  tableItemStatus: {
    width: 25,
    borderWidth: 1,
    borderColor: '#444444',
    marginLeft: -1.2,
  },
  detailsRightContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
  },
  detailsRightText: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 8,
  },
  gasImg: {
    width: 100,
  },
  withoutMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  withMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 2,
  },
  box: {
    width: 10,
    height: 10,
    borderWidth: 2,
    marginRight: 2,
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 15,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
})
