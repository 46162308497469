import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import './index.css'

const MoreFiltersButton = ({ onClick, isLoading, disabled, showSecond }) => {
  return (
    <>
      <button
        className="component__moreFilters-button"
        type="submit"
        onClick={onClick}
        disabled={disabled}
      >
        <span
          className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
        />{' '}
        <FontAwesomeIcon
          icon={faFilter}
          size="lg"
          style={{ marginRight: '5px' }}
        />
        {!showSecond ? 'Mais Filtros' : 'Menos Filtros'}
      </button>
    </>
  )
}

export default MoreFiltersButton
