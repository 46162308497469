import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../CustomButton/CustomButton'
import SupportButton from '../SupportButton'
import { ButtonContainer } from './styles'

import { currency } from '../ToNormalize/ToNormalize'

export function InactiveModuleModal({
  show,
  onHide,
  module,
  customMessage,
  customLink,
  customButtonText,
  showWhatsAppIcon,
}) {
  return (
    <Modal show={show} animation onHide={onHide}>
      <Modal.Header closeButton={onHide}>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <span>
          <strong>Módulo não habilitado</strong>
        </span>
        <br />
        <span>
          {customMessage ? (
            customMessage
          ) : (
            <>
              Para utilizar o {module.description} no OS Digital, será
              adicionado o valor {currency(module.price)} na sua mensalidade.
            </>
          )}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <Button fill bsStyle="danger" onClick={onHide}>
            Voltar
          </Button>
          <SupportButton
            buttonText={
              customButtonText ? customButtonText : 'Solicitar Módulo'
            }
            showWhatsAppIcon={showWhatsAppIcon}
            customLink={customLink}
          />
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  )
}
