import React from 'react';
import { Table } from 'v2/components/Table';

export default function TableFamilyBrandStock({
  reportData,
  loading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const tableColumns = [
    {
      Header: 'Cód. Interno',
      accessor: 'Code',
      width: 100,
    },
    {
      Header: 'Descrição',
      accessor: 'Description',
    },
    {
      Header: 'Ref. Fabricante',
      accessor: 'Manufacturer_Ref',
    },
    {
      Header: 'Família',
      accessor: 'Family',
      width: 180,
    },
    {
      Header: 'Marca',
      accessor: 'Brand',
      width: 180,
    },
    {
      Header: 'Qtd. Entrada',
      accessor: 'Entry',
    },
    {
      Header: 'Qtd. Saída',
      accessor: 'Exit',
    },
    {
      Header: 'Estoque Atual',
      accessor: 'Stock_Quantity',
    },
  ];

  return (
    <div>
      <Table
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        sortable={true}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        data={reportData}
        columns={tableColumns}
        fontSize="12px"
        defaultSorted={[
          {
            id: 'Code',
            desc: false,
          },
        ]}
      />
    </div>
  );
}
