import React, { useEffect, useState } from 'react';
import FormPurchases from './FormPurchases';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import { useDispatch, connect, useSelector } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { format, isAfter, differenceInMonths } from 'date-fns';
import Button from '../../../../../client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';
import constants from '../../../../../utils/constants';
import purchasesRepository from '../../../../../repositories/Purchases';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import AlertModal from 'components/AlertModal/AlertModal';
import { purchaseAccessKey } from '../../../../../utils/accessKeyParse';
import { getProductsUnderStock } from '../../../../components/MinStockAlertButton/redux/actions';

function BundleFormPurchases({ history, titleId, purchases }) {
  const [loading, setLoading] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] = useState(false);
  const [typePurchase, setTypePurchase] = useState('');

  const formValues = useSelector((state) => state.form.purchases.values);

  const [disableInputs, setDisableInputs] = useState(!titleId ? false : true);

  const companyId = localStorage.getItem('ID_EMPRESA');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!titleId) {
      getPurchase();
    }
  }, []);

  function isPurchaseXML(purchaseType) {
    setTypePurchase(purchaseType);
    return purchaseType === 'XML' ? true : false;
  }

  function isPurchaseClosedOrCanceled(purchaseStatusId) {
    return purchaseStatusId === 2 || purchaseStatusId === 3;
  }

  async function getPurchase() {
    setLoading(true);
    try {
      const purchase = await purchasesRepository.getById(titleId);
      const acessKey = purchaseAccessKey(purchase.accessKey);

      dispatch([
        // Dados Nota
        change('purchases', 'invoice', purchase.invoice),
        change('purchases', 'request', purchase.request),
        change('purchases', 'serie', purchase.serie),
        change('purchases', 'model', purchase.model),
        change('purchases', 'accessKey', acessKey),
        change('purchases', 'type', purchase.type),
        change(
          'purchases',
          'issuedAt',
          purchase.issuedAt
            ? format(
                new Date(getDateOnlyFromDate(purchase.issuedAt)),
                'yyyy-MM-dd'
              )
            : ''
        ),
        change(
          'purchases',
          'entryAt',
          purchase.entryAt !== '0000-00-00' && !!purchase.entryAt
            ? format(
                new Date(getDateOnlyFromDate(purchase.entryAt)),
                'yyyy-MM-dd'
              )
            : ''
        ),
        change(
          'purchases',
          'additionalInformation',
          purchase.additionalInformation
        ),
        change('purchases', 'purchaseStatusId', purchase.purchaseStatusId),
        change('purchases', 'providerId', purchase.providerId),

        // Dados Fornecedor
        change('purchases', 'companyName', purchase.Provider.companyName),
        change('purchases', 'tradingName', purchase.Provider.tradingName),
        change('purchases', 'cpfCnpj', purchase.Provider.cpfCnpj),
        change('purchases', 'IE', purchase.Provider.IE),
        change('purchases', 'Zipcode', purchase?.Provider?.Address[0]?.Zipcode),
        change('purchases', 'Address', purchase?.Provider?.Address[0]?.Address),
        change(
          'purchases',
          'Address_Number',
          purchase?.Provider?.Address[0]?.Address_Number || ''
        ),
        change(
          'purchases',
          'Neighborhood',
          purchase?.Provider?.Address[0]?.Neighborhood || ''
        ),
        change(
          'purchases',
          'Complement',
          purchase?.Provider?.Address[0]?.Complement || ''
        ),
        change(
          'purchases',
          'Reference_Point',
          purchase?.Provider?.Address[0]?.Reference_Point || ''
        ),
        change(
          'purchases',
          'State',
          purchase?.Provider?.Address[0]?.State || ''
        ),
        change('purchases', 'City', purchase?.Provider?.Address[0]?.City || ''),

        // Dados Rodapé
        change('purchases', 'code', purchase.code),
        change('purchases', 'BCICMS', purchase.BCICMS),
        change('purchases', 'BCICMSST', purchase.BCICMSST),
        change('purchases', 'PISValue', purchase.PISValue),
        change('purchases', 'freightValue', purchase.freightValue),
        change('purchases', 'IPIValue', purchase.IPIValue),
        change('purchases', 'FCPValue', purchase.FCPValue),
        change('purchases', 'productsValue', purchase.productsValue),
        change('purchases', 'total', purchase.total),
        change('purchases', 'ICMSValue', purchase.ICMSValue),
        change('purchases', 'ICMSSTValue', purchase.ICMSSTValue),
        change('purchases', 'COFINSValue', purchase.COFINSValue),
        change('purchases', 'inssuranceValue', purchase.inssuranceValue),
        change('purchases', 'otherExpenses', purchase.otherExpenses),
        change('purchases', 'FCPSTValue', purchase.FCPSTValue),
        change('purchases', 'discountValue', purchase.discountValue),
      ]);

      const items = purchase.PurchaseItems.map((purchaseItem) => ({
        ...purchaseItem,
        approvedDate:
          !purchaseItem.approvedDate ||
          purchaseItem.approvedDate === '0000-00-00'
            ? ''
            : format(
                new Date(getDateOnlyFromDate(purchaseItem.approvedDate)),
                'yyyy-MM-dd'
              ),
        unitValue: purchaseItem.unitValue,
        total: purchaseItem.total,
      })).sort((a, b) => (a.productCode < b.productCode ? -1 : 1));

      dispatch(change('purchases', 'items', items));
      dispatch(change('purchases', 'allItems', purchase.PurchaseItems));

      setDisableInputs(
        isPurchaseXML(purchase.type) ||
          isPurchaseClosedOrCanceled(purchase.purchaseStatusId)
      );
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleSubmitXML(values) {
    const { items } = values;

    const anyItemWithoutApprovedDate = items.some(
      (items) => !items.approvedDate
    );
    if (anyItemWithoutApprovedDate) {
      dispatch(
        change('purchases', 'purchaseStatusId', constants.PURCHASES_STATUS.OPEN)
      );
      return updateXMLPurchase(constants.PURCHASES_STATUS.OPEN, values);
    }

    let invalidDates = false;
    items.forEach((item) => {
      if (item.approvedDate) {
        const isDateFuture = isAfter(new Date(item.approvedDate), new Date());
        const differenceDatesInMonths = differenceInMonths(
          new Date(),
          new Date(item.approvedDate)
        );

        if (isDateFuture || differenceDatesInMonths > 3) {
          toastr.warning(
            'Insira uma data de pagamento menor que 3 meses ou coloque a data atual.'
          );
          invalidDates = true;
          return;
        }
      }
    });

    if (invalidDates) return;

    if (titleId) {
      setIsSaveModalOpen(true);
    }
  }

  async function handleSubmitManual(values) {
    const { items, providerId, entryAt } = values;

    if (!providerId) {
      return toastr.warning(
        'Compra não salva',
        'Selecione um fornecedor para salvar sua compra'
      );
    }

    if (items.length === 0) {
      return toastr.warning(
        'Compra não salva',
        'Adicione ao menos um item para salvar sua compra'
      );
    }

    if (isAfter(new Date(entryAt), new Date())) {
      return toastr.warning(
        'Data de Entrada inválida',
        'Insira uma data válida até a data atual e tente novamente.'
      );
    }

    let invalidDates = false;
    items.forEach((item) => {
      if (item.approvedDate) {
        const isDateFuture = isAfter(new Date(item.approvedDate), new Date());
        const differenceDatesInMonths = differenceInMonths(
          new Date(),
          new Date(item.approvedDate)
        );

        if (isDateFuture || differenceDatesInMonths > 3) {
          toastr.warning(
            'Insira uma data de entrada menor que 3 meses ou coloque a data atual.'
          );
          invalidDates = true;
          return;
        }
      }
    });

    if (invalidDates) return;

    const anyItemWithoutApprovedDate = items.some(
      (items) => !items.approvedDate
    );

    if (anyItemWithoutApprovedDate) {
      if (!titleId) {
        createManualPurchase(values);
      } else {
        updateManualPurchase(values);
      }
    } else {
      setIsSaveModalOpen(true);
    }
  }

  function handleSubmit(values) {
    const { type } = values;

    if (type === 'Manual') {
      handleSubmitManual(values);
    } else {
      handleSubmitXML(values);
    }
  }

  function handleConfirmModal(purchasesStatus) {
    const { items, type } = purchases;
    setIsSaveModalOpen(false);
    setIsCancelBillModalOpen(false);

    if (purchasesStatus === constants.PURCHASES_STATUS.CLOSED) {
      const anyItemWithoutApprovedDate = items.some(
        (items) => !items.approvedDate
      );
      if (anyItemWithoutApprovedDate) {
        return toastr.warning('Existem itens sem data de aprovação');
      }
    }

    if (type === 'XML') {
      updateXMLPurchase(purchasesStatus, purchases);
    } else {
      if (titleId) {
        updateManualPurchase(formValues, purchasesStatus);
      } else {
        createManualPurchase(formValues, purchasesStatus);
      }
    }
  }

  async function createManualPurchase(values, status) {
    setLoading(true);
    try {
      const purchase = {
        invoice: values.invoice,
        serie: values.serie,
        additionalInformation: values.additionalInformation,
        accessKey: values.accessKey,
        issuedAt: values.issuedAt,
        entryAt: values.entryAt,
        BCICMS: values.BCICMS,
        BCICMSST: values.BCICMSST,
        PISValue: values.PISValue,
        freightValue: values.freightValue,
        IPIValue: values.IPIValue,
        FCPValue: values.FCPValue,
        productsValue: values.productsValue,
        total: values.total,
        ICMSValue: values.ICMSValue,
        ICMSSTValue: values.ICMSSTValue,
        COFINSValue: values.COFINSValue,
        inssuranceValue: values.inssuranceValue,
        otherExpenses: values.otherExpenses,
        FCPSTValue: values.FCPSTValue,
        discountValue: values.discountValue,
        purchaseStatusId: status || values.purchaseStatusId,
        request: values.request,
        type: 'Manual',
      };

      const purchaseItems = values.items.map((item) => {
        return {
          ...item,
          NCM: item.NCM ? item.NCM : ' ',
        };
      });

      await purchasesRepository.create({
        purchase,
        items: purchaseItems,
        companyId: companyId,
        providerId: values.providerId,
        type: 'Manual',
      });

      dispatch(getProductsUnderStock(companyId));
      toastr.success(
        `Compra ${
          status === constants.PURCHASES_STATUS.OPEN ||
          values.purchaseStatusId === constants.PURCHASES_STATUS.OPEN
            ? 'Salva'
            : (status === constants.PURCHASES_STATUS.CANCELED ||
                values.purchaseStatusId) === constants.PURCHASES_STATUS.CANCELED
            ? 'Cancelada'
            : 'Finalizada'
        } com sucesso`
      );
      history.push(constants.ROUTES.PURCHASES);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Cadastrar a Compra',
        err?.response?.data?.message ||
          'Ocorreu um erro ao salvar a compra. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  async function updateManualPurchase(values, status) {
    setLoading(true);

    try {
      const purchase = {
        invoice: values.invoice,
        serie: values.serie,
        accessKey: values.accessKey,
        issuedAt: values.issuedAt,
        entryAt: values.entryAt,
        BCICMS: values.BCICMS,
        BCICMSST: values.BCICMSST,
        PISValue: values.PISValue,
        freightValue: values.freightValue,
        IPIValue: values.IPIValue,
        FCPValue: values.FCPValue,
        productsValue: values.productsValue,
        total: values.total,
        ICMSValue: values.ICMSValue,
        ICMSSTValue: values.ICMSSTValue,
        COFINSValue: values.COFINSValue,
        additionalInformation: values.additionalInformation,
        inssuranceValue: values.inssuranceValue,
        otherExpenses: values.otherExpenses,
        FCPSTValue: values.FCPSTValue,
        discountValue: values.discountValue,
        purchaseStatusId: status || values.purchaseStatusId,
        request: values.request,
        type: 'Manual',
      };

      const purchaseItems = values.items.map((item) => {
        return { ...item };
      });

      await purchasesRepository.update(titleId, {
        purchase,
        items: purchaseItems,
        providerId: values.providerId,
      });

      dispatch(getProductsUnderStock(companyId));
      toastr.success(
        `Compra ${
          status === constants.PURCHASES_STATUS.OPEN ||
          values.purchaseStatusId === constants.PURCHASES_STATUS.OPEN
            ? 'Salva'
            : (status === constants.PURCHASES_STATUS.CANCELED ||
                values.purchaseStatusId) === constants.PURCHASES_STATUS.CANCELED
            ? 'Cancelada'
            : 'Finalizada'
        } com sucesso`
      );
      history.push(constants.ROUTES.PURCHASES);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Cadastrar',
        'Ocorreu um erro ao salvar a compra. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  async function updateXMLPurchase(purchaseStatusId, purchase) {
    const { items, request } = purchase;
    setLoading(true);

    try {
      await purchasesRepository.update(titleId, {
        purchase: {
          purchaseStatusId: purchaseStatusId,
          request,
        },
        items,
        companyId,
      });

      dispatch(getProductsUnderStock(companyId));
      toastr.success(
        `Compra ${
          purchaseStatusId === constants.PURCHASES_STATUS.OPEN
            ? 'Salva'
            : purchaseStatusId === constants.PURCHASES_STATUS.CANCELED
            ? 'Cancelada'
            : 'Finalizada'
        } com sucesso`
      );
      history.push(constants.ROUTES.PURCHASES);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar a Compra. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setIsCancelBillModalOpen(true);
  }

  const initialValues = {
    invoice: 0,
    serie: 0,
    model: 0,
    request: '',
    accessKey: '0000 0000 0000 0000 0000 0000 0000 0000 0000 0000',
    issuedAt: format(new Date(), 'yyyy-MM-dd'),
    entryAt: format(new Date(), 'yyyy-MM-dd'),
    additionalInformation: '',
    items: [
      {
        productCode: '',
        productProviderCode: '',
        description: '',
        multiplierBase: 1,
        quantity: 1,
        approvedDate: '',
        unitValue: 0,
        total: 0,
      },
    ],
  };

  return (
    <>
      <FormPurchases
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={loading}
        titleId={titleId}
        cancel={handleCancel}
        disableInputs={disableInputs}
        typePurchase={typePurchase}
      />

      <Modal
        dialogClassName="bill-to-receive-save-modal"
        show={isSaveModalOpen}
        size="sm"
        onHide={() => setIsSaveModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>O.S Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>Como você quer salvar essa Compra ?</span>
            <div>
              <Button
                bsStyle="info"
                fill
                onClick={() =>
                  handleConfirmModal(constants.PURCHASES_STATUS.OPEN)
                }
              >
                Salvar Compra
              </Button>
              <Button
                bsStyle="primary"
                fill
                onClick={() =>
                  handleConfirmModal(constants.PURCHASES_STATUS.CLOSED)
                }
              >
                Finalizar Compra
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <AlertModal
        show={isCancelBillModalOpen}
        onHide={() => setIsCancelBillModalOpen(false)}
        onCancel={() => setIsCancelBillModalOpen(false)}
        onSubmit={() => handleConfirmModal(constants.PURCHASES_STATUS.CANCELED)}
        message="Deseja cancelar a entrada de compra? Caso tenha ocorrido movimentação no estoque, o processo será desfeito"
      />
    </>
  );
}

BundleFormPurchases = reduxForm({
  form: 'purchases',
  initialValues: {
    invoice: '',
    request: '',
    serie: '',
    accessKey: '',
    type: 'Manual',
    issuedAt: format(new Date(getDateOnlyFromDate(new Date())), 'yyyy-MM-dd'),
    entryAt: format(new Date(getDateOnlyFromDate(new Date())), 'yyyy-MM-dd'),
    additionalInformation: '',
    purchaseStatusId: 1,
    companyName: '',
    tradingName: '',
    cpfCnpj: '',
    IE: '',
    Zipcode: '',
    Address: '',
    Address_Number: '',
    Neighborhood: '',
    Complement: '',
    Reference_Point: '',
    State: '',
    City: '',

    code: '',
    BCICMS: 0,
    BCICMSST: 0,
    PISValue: 0,
    freightValue: 0,
    IPIValue: 0,
    FCPValue: 0,
    productsValue: 0,
    total: 0,
    ICMSValue: 0,
    ICMSSTValue: 0,
    COFINSValue: 0,
    inssuranceValue: 0,
    otherExpenses: 0,
    FCPSTValue: 0,
    discountValue: 0,
    items: [],
    allItems: [],
  },
  enableReinitialize: true,
})(BundleFormPurchases);
function mapStateToProps(state) {
  return { purchases: state.form.purchases?.values };
}
export default connect(mapStateToProps)(withRouter(BundleFormPurchases));
