import React, { useState, useEffect } from 'react'
import { change, Field } from 'redux-form'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import RenderField from '../../../../components/RenderField'
import TextsForInvoiceModal from '../../../components/TextsForInvoiceModal'

import constants from '../../../../utils/constants'
import { toastr } from 'react-redux-toastr'
import settingsNFSeRepository from '../../../../repositories/SettingsNFSe'

const OtherInfos = () => {
  const [isTextsForInvoiceModalOpen, setIsTextsForInvoiceModalOpen] =
    useState(false)
  const [operations, setOperations] = useState([])
  const [taxes, setTaxes] = useState([])

  const {
    textsForInvoice,
    isInvoiceIssued,
    isInvoiceCanceled,
    isInvoiceProcessing,
    NFSPatternId,
  } = useSelector((state) => state.form.NFSe.values)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!NFSPatternId) {
      loadOperationTaxes()
    }
  }, [NFSPatternId])

  async function loadOperationTaxes() {
    try {
      const operationsTaxes =
        await settingsNFSeRepository.getOperationTaxesByNFSPattern(NFSPatternId)
      setOperations(operationsTaxes.operations)
      setTaxes(operationsTaxes.taxes)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as operações e impostos. Por favor, tente novamente'
      )
    }
  }

  function handleOpenTextForInvoicesModal() {
    setIsTextsForInvoiceModalOpen(true)
  }

  function handleCloseTextForInvoicesModal() {
    setIsTextsForInvoiceModalOpen(false)
  }

  function handleIncludeTexts(texts) {
    dispatch(change('NFSe', 'textsForInvoice', texts))
    setIsTextsForInvoiceModalOpen(false)
  }

  return (
    <div id='NFSe-other-infos'>
      <div>
        <Col xs={12} sm={12} md={3} lg={3} className='col-padding'>
          <Field
            name='specialTaxRegime'
            component={RenderField}
            label='Regime Especial de Tributação'
            as='select'
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          >
            <option value=''>Selecione</option>
            {constants.SPECIAL_TAX_REGIME_CODE.map((regime) => (
              <option key={regime.value} value={regime.value}>
                {regime.label}
              </option>
            ))}
          </Field>
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} className='col-padding'>
          <Field
            name='operationType'
            component={RenderField}
            label='Natureza de Operação'
            as='select'
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          >
            <option value=''>Selecione</option>
            {operations.map(({ id, description, typeCode }) => (
              <option key={id} value={typeCode}>
                {description}
              </option>
            ))}
          </Field>
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} className='col-padding'>
          <Field
            name='taxType'
            component={RenderField}
            label='Tributação'
            as='select'
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          >
            <option value=''>Selecione</option>
            {taxes.map(({ id, description, typeCode }) => (
              <option key={id} value={typeCode}>
                {description}
              </option>
            ))}
          </Field>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className='col-padding'
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <label>Texto Fiscal</label>
          <Field
            name='textsForInvoice'
            component={() => (
              <button
                type='button'
                className='text-for-invoices-button'
                onClick={handleOpenTextForInvoicesModal}
                disabled={
                  isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
                }
              >
                Aplicar Texto Fiscal
              </button>
            )}
          />
        </Col>
      </div>
      <div>
        <Col xs={12} sm={12} md={12} lg={12} className='col-padding'>
          <Field
            name='additionalInformation'
            component={RenderField}
            label='Informações Complementares'
            as='textarea'
            disabled={
              isInvoiceIssued || isInvoiceCanceled || isInvoiceProcessing
            }
          />
        </Col>
      </div>

      {isTextsForInvoiceModalOpen && (
        <TextsForInvoiceModal
          onCancel={handleCloseTextForInvoicesModal}
          onSubmit={handleIncludeTexts}
          textsIds={textsForInvoice}
        />
      )}
    </div>
  )
}

export default OtherInfos
