import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../../client/components/CustomButton/CustomButton';

const ConfirmChangePriceModal = ({ onCancel, onSubmit }) => {
  return (
    <div>
      <Modal
        show
        animation
        onHide={onCancel}
        dialogClassName="modal-30w modal-70h"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Alteração de Preço</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong style={{ fontSize: '16px' }}>
            Você tem certeza que deseja alterar os valores dos produtos
            selecionados?
          </strong>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexFlow: 'column wrap',
              lineHeight: '5px',
            }}
          >
            <p style={{ fontSize: '12px', color: 'red' }}>
              O processo é irreversível. O preço poderá ser alterado novamente
              no futuro.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button bsStyle="danger" name="Calcelar" onClick={onCancel} fill>
              Não
            </Button>
            <Button bsStyle="info" name="Salvar" fill onClick={onSubmit}>
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmChangePriceModal;
