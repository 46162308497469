import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { isToday } from 'date-fns'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import CloseCashierBankHistoricModal from '../../../../views/CashierBankHistoric/CloseCashierBankHistoricModal/CloseCashierBank'
import OpenCashierBankHistoricModal from '../../../../views/CashierBankHistoric/OpenCashierBankHistoricModal'
import FinancialMovementFormModal from '../../../FormModals/FinancialMovement'
import { handleChange } from '../../../BlockSalesModals/actions'
import constants from '../../../../../utils/constants'

import './styles.css'

const CashierBankPDVModal = ({ onCancel }) => {
  const [isOpenCashierBankHistoricModalOpen, setIsOpenCashierBankHistoricModalOpen] = useState(false)
  const [isCloseCashierBankHistoricModalOpen, setIsCloseCashierBankHistoricModalOpen] = useState(false)
  const [isFinancialMovementFormModalOpen, setIsFinancialMovementFormModalOpen] = useState(false)

  const { cashierBankOpened } = useSelector(state => state.blockSalesModalsReducer)
  const hasCashierBankOpen = !!cashierBankOpened
  const isCashierBankOpenDateNotToday = hasCashierBankOpen && !isToday(new Date(cashierBankOpened.openDate))
  const dispatch = useDispatch()

  function handleOpenCashierBankHistoric() {
    if (isCashierBankOpenDateNotToday) {
      return toastr.warning('Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras')
    }
    if (hasCashierBankOpen) {
      return toastr.warning('Já existe um caixa aberto. Feche o caixa para abrir um novo')
    }
    setIsOpenCashierBankHistoricModalOpen(true)

  }

  function handleCloseCashierBankHistoric() {
    if (!hasCashierBankOpen) {
      return toastr.warning('Funções disponíveis para caixa aberto')
    }

    setIsCloseCashierBankHistoricModalOpen(true)
  }

  function handleOpenCashierBleed() {
    if (!hasCashierBankOpen) {
      return toastr.warning('Funções disponíveis para caixa aberto')
    }
    if (isCashierBankOpenDateNotToday) {
      return toastr.warning('Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras')
    }

    const initialValues = {
      financialMovementTypeId: String(constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT),
      description: 'Sangria',
      finality: constants.FINANCIAL_MOVEMENTS_FINALITY.EXIT,
      cashierBankId: cashierBankOpened.cashierBankId,
      isCashierBleed: true
    }

    setIsFinancialMovementFormModalOpen(true)
    dispatch(initialize('FinancialMovement', initialValues))
  }

  function handleOpenCashierSupply() {
    if (!hasCashierBankOpen) {
      return toastr.warning('Funções disponíveis para caixa aberto')
    }
    if (isCashierBankOpenDateNotToday) {
      return toastr.warning('Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras')
    }

    const initialValues = {
      financialMovementTypeId: String(constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT),
      description: 'Suprimento',
      finality: constants.FINANCIAL_MOVEMENTS_FINALITY.ENTRY,
      cashierBankId: cashierBankOpened.cashierBankId,
      isCashierSupply: true
    }

    setIsFinancialMovementFormModalOpen(true)
    dispatch(initialize('FinancialMovement', initialValues))
  }

  function handleSubmitCloseCashierBankHistoricModal() {
    setIsCloseCashierBankHistoricModalOpen(false)
    dispatch(handleChange(null, 'cashierBankOpened'))
  }

  function handleSubmitOpenCashierBankHistoricModal(cashierBankHistoric) {
    setIsOpenCashierBankHistoricModalOpen(false)
    dispatch(handleChange(cashierBankHistoric, 'cashierBankOpened'))
  }

  return (
    <>
      <Modal
        show
        animation
        dialogClassName='cashier-bank-pdv-modal-wrapper'
      >
        <Modal.Header>
          <Modal.Title>
            <strong>
              Caixa
          </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Selecione a opção desejada:</span>
          <div>
            <button
              className={`success ${(hasCashierBankOpen || isCashierBankOpenDateNotToday) ? 'disabled' : ''}`}
              onClick={handleOpenCashierBankHistoric}
              title={
                isCashierBankOpenDateNotToday
                  ? 'Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras'
                  : hasCashierBankOpen
                    ? 'Já existe um caixa aberto. Feche o caixa para abrir um novo'
                    : undefined
              }
            >
              Abrir Caixa
            </button>
            <button
              className={`success ${!hasCashierBankOpen ? 'disabled' : ''}`}
              title={!hasCashierBankOpen && 'Funções disponíveis para caixa aberto'}
              onClick={handleCloseCashierBankHistoric}
            >
              Fechar Caixa
            </button>
            <button
              className={`warning ${(!hasCashierBankOpen || isCashierBankOpenDateNotToday) ? 'disabled' : ''}`}
              title={
                !hasCashierBankOpen
                  ? 'Funções disponíveis para caixa aberto'
                  : isCashierBankOpenDateNotToday
                    ? 'Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras'
                    : undefined
              }
              onClick={handleOpenCashierBleed}
            >
              Sangria
            </button>
            <button
              className={`info ${(!hasCashierBankOpen || isCashierBankOpenDateNotToday) ? 'disabled' : ''}`}
              title={
                !hasCashierBankOpen
                  ? 'Funções disponíveis para caixa aberto'
                  : isCashierBankOpenDateNotToday
                    ? 'Feche o caixa com data anterior e abra um novo caixa para efetuar funções financeiras'
                    : undefined
              }
              onClick={handleOpenCashierSupply}
            >
              Suprimento
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='danger' onClick={onCancel}>Voltar</button>
        </Modal.Footer>
      </Modal>

      {
        isOpenCashierBankHistoricModalOpen &&
        <OpenCashierBankHistoricModal
          onCancel={() => setIsOpenCashierBankHistoricModalOpen(false)}
          onSubmit={handleSubmitOpenCashierBankHistoricModal}
        />
      }

      {
        isCloseCashierBankHistoricModalOpen &&
        <CloseCashierBankHistoricModal
          onCancel={() => setIsCloseCashierBankHistoricModalOpen(false)}
          cashierBankHistoricId={cashierBankOpened.id}
          onSubmit={handleSubmitCloseCashierBankHistoricModal}
        />
      }
      {
        isFinancialMovementFormModalOpen &&
        <FinancialMovementFormModal
          onCancel={() => setIsFinancialMovementFormModalOpen(false)}
          onSubmit={() => setIsFinancialMovementFormModalOpen(false)}
        />
      }
    </>

  )
}

export default CashierBankPDVModal
