import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import Select from '../../../../../client/views/Clients/NewClient/FormClient/components/Select';
import Input from '../../../../../client/views/Clients/NewClient/FormClient/components/Input';
import Button from '../../../../../client/components/CustomButton/CustomButton.jsx';
import CNAEsRepository from '../../../../../repositories/Cnaes';
import settingsNFSeRepository from '../../../../../repositories/SettingsNFSe';

import {
  onlyNumbers,
  specialChar,
} from '../../../../../client/components/ToNormalize/ToNormalize';
import { ISSMask } from '../../../../../utils/masks';
import { useAuth } from '../../../../../contexts/auth';
import constants from '../../../../../utils/constants';
import { toastr } from 'react-redux-toastr';

const FormSettingsNFSe = ({ loading, handleSubmit, pristine }) => {
  const [CNAEs, setCNAEs] = useState([]);
  const [operations, setOperations] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const { allowHomologation, settingsNFSeId, NFSPatternId } = useSelector(
    (state) => state.form.settingsNFSe.values
  );
  const { companyId } = useAuth();

  useEffect(() => {
    loadCNAEs();
  }, []);

  useEffect(() => {
    if (!!NFSPatternId) {
      loadOperationTaxes();
    }
  }, [NFSPatternId]);

  async function loadCNAEs() {
    try {
      const CNAEs = await CNAEsRepository.getAllActiveByCompany(companyId);
      setCNAEs(CNAEs);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os CNAES. Por favor, tente novamente'
      );
    }
  }

  async function loadOperationTaxes() {
    try {
      const operationsTaxes =
        await settingsNFSeRepository.getOperationTaxesByNFSPattern(
          NFSPatternId
        );

      setOperations(operationsTaxes.operations);
      setTaxes(operationsTaxes.taxes);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as operações e impostos. Por favor, tente novamente'
      );
    }
  }

  return (
    <div className="company-tax-data-page">
      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={3} md={3} sm={3} xs={12} className="col-fit-content">
          <Field label="Padrão" name="pattern" component={Input} disabled />
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={2} md={2} sm={2} xs={2} className="col-fit-content">
          <Field
            label="Inscrição Municipal"
            name="IM"
            component={Input}
            normalize={(value) => value.trim()}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>CNAE</label>
          <Field name="CNAE" component={Select}>
            <option value="">Selecione</option>
            {CNAEs.map((CNAE) => (
              <option key={CNAE.id} value={CNAE.code}>
                {CNAE.code} - {CNAE.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="col-fit-content">
          <Field
            label="Alíquota de ISS (%)"
            name="ISS"
            component={Input}
            maxLength={11}
            {...ISSMask}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="col-fit-content">
          <Field
            label="Item de lista de serviço"
            name="serviceListItem"
            component={Input}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <Field
            label="Cód. de Tributação do Município"
            name="municipalTaxCode"
            component={Input}
          />
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={2} md={2} sm={2} xs={2} className="col-fit-content">
          <label>Ambiente de Envio</label>
          <Field name="environment" component={Select}>
            {allowHomologation && (
              <option value="Homologação">Homologação</option>
            )}
            <option value="Produção">Produção</option>
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <Field
            label="Última RPS/NFS-e Enviada"
            name="lastNFSeSent"
            component={Input}
            normalize={onlyNumbers}
          />
        </Col>
        <Col lg={1} md={1} sm={1} xs={1} className="col-fit-content">
          <Field
            label="Série"
            name="serie"
            component={Input}
            normalize={specialChar}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <Field
            label="Usuário de acesso na prefeitura"
            name="userAccess"
            component={Input}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <Field
            label="Senha de acesso na prefeitura"
            name="userPassword"
            component={Input}
          />
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <Field
            label="Cad. Mobiliário do Contribuinte"
            name="CMCCode"
            component={Input}
            normalize={onlyNumbers}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Regime Especial de Tributação</label>
          <Field name="specialTaxRegimeCode" component={Select}>
            <option value="">Selecione</option>
            {constants.SPECIAL_TAX_REGIME_CODE.map((regime, key) => (
              <option key={key} value={regime.value}>
                {regime.label}
              </option>
            ))}
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Local de Prestação de Serviço</label>
          <Field name="serviceLocationCode" component={Select}>
            <option value="">Selecione</option>
            {constants.SERVICE_LOCATION_CODE.map((location) => (
              <option key={location.value} value={location.value}>
                {location.label}
              </option>
            ))}
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Modelo de Impressão</label>
          <Field name="printingTemplate" component={Select}>
            {constants.PRINTING_TEMPLATE_LABEL.map((label) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </Field>
        </Col>
      </Col>

      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Optante pelo Simples Nacional?</label>
          <Field name="opt_sn" component={Select}>
            {constants.OPTING_SN_OPTIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Natureza de Operação</label>
          <Field name="operationType" component={Select}>
            <option value="">Selecione</option>
            {operations.map((operation) => (
              <option key={operation.id} value={operation.typeCode}>
                {operation.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3} className="col-fit-content">
          <label>Tributação</label>
          <Field name="taxType" component={Select}>
            <option value="">Selecione</option>
            {taxes.map((tax) => (
              <option key={tax.id} value={tax.typeCode}>
                {tax.description}
              </option>
            ))}
          </Field>
        </Col>
      </Col>

      <Button bsStyle="info" fill onClick={handleSubmit} disabled={loading}>
        <span
          className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          style={{ marginRight: loading ? '5px' : '' }}
        />
        {!!settingsNFSeId ? 'Atualizar' : 'Salvar'}
      </Button>
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'settingsNFSe',
})(FormSettingsNFSe);
