import axios from 'axios';
import config from 'config';
import http from 'http';
import { Service } from 'axios-middleware';

const service = new Service(axios);

const api = axios.create({
  baseURL: config.endpoint + 'v2',
  httpAgent: new http.Agent({ keepAlive: true }),
  responseType: 'json',
  headers: {
    ['x-force-auth']: true,
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      (error.response.status === 401 &&
        error.response.data.message === 'token not provided') ||
      (error.response.status === 401 &&
        error.response.data.message === 'invalid token')
    ) {
      window.location.href = '/client-login?expiredSession=true';
      localStorage.clear();
      return;
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
