import React from 'react'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'

import RenderField from 'components/RenderField'
import DropPhotoContainer from 'components/DropPhotoContainer'

const MainData = ({ companyType, logo, isEdit }) => {
  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="Tipo de pessoa:"
            name="type"
            component={RenderField}
            disabled
          />
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="CPF/CNPJ:"
            name="cpfCnpj"
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <Field
            label="Razão Social:"
            name="companyName"
            component={RenderField}
            disabled
          />
        </Col>
        {companyType !== 'Física' ? (
          <Col xs={12} sm={6} md={4} lg={4}>
            <Field
              label="Nome Fantasia:"
              name="fantasyName"
              component={RenderField}
              disabled
            />
          </Col>
        ) : (
          <Col xs={12} sm={6} md={4} lg={4}>
            <Field
              label="Email:"
              name="email"
              component={RenderField}
              disabled
            />
          </Col>
        )}
        <Col xs={12} sm={3} md={2} lg={2}>
          <div style={{ width: 'fit-content', marginTop: '-42px' }}>
            <DropPhotoContainer image={logo} style={{ width: 'fit-content' }} />
          </div>
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        {companyType !== 'Física' && (
          <Col xs={12} sm={6} md={3} lg={3}>
            <Field
              label="Email:"
              name="email"
              component={RenderField}
              disabled
            />
          </Col>
        )}
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="Telefone:"
            name="phone"
            component={RenderField}
            disabled
          />
        </Col>
        {companyType !== 'Física' && (
          <Col xs={12} sm={6} md={3} lg={3}>
            <Field
              label="Responsável:"
              name="contact"
              component={RenderField}
              disabled
            />
          </Col>
        )}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={5} lg={5}>
          <label>
            Stonecode do Cliente:{' '}
            {isEdit && <span style={{ color: 'red' }}>*</span>}
          </label>
          <Field name="stoneCode" component={RenderField} />
        </Col>
        <Col xs={12} sm={6} md={5} lg={5}>
          <Field
            label="Status:"
            name="status"
            component={RenderField}
            disabled
          />
        </Col>
      </Col>
    </>
  )
}

export default MainData
