import React, { useEffect, useState } from 'react';

import NewsTable from './NewsTable';
import Card from '../../../../components/Card/Card';

import { useAdminAuth } from 'contexts/adminAuth';

import useFilters from 'hooks/useFilters';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import newsRepository from '../../../../repositories/News';
import NewsFilter from './NewsFilter';

function NewsMain({ history }) {
  //ID do admin = admin.id
  const { admin } = useAdminAuth();

  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  const { dateFilter, queryFilter, statusFilter, typeFilter } = useFilters();
  const { filterByDate, finalDate, initialDate, setFinalDate, setInitialDate } =
    dateFilter;

  const { status, setStatus, filterByStatus } = statusFilter;
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;

  const [branding, setBranding] = useState({ label: 'Ambos', value: '' });

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    setLoading(true);
    try {
      const data = await newsRepository.getAll();
      setNews(data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as notícias. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (news) => {
    if (!!validations()) {
      const querySearch = [news['description']];

      return (
        filterByQuery(querySearch) &&
        filterByDate('createdAt', news) &&
        filterByStatus('status', news) &&
        filterByType('brandingId', news)
      );
    }
    return;
  };

  const validations = () => {
    //verifica se initialDate e finalDate são maiores que a data atual
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const handleUpdateStatus = async (newsId, status) => {
    try {
      await newsRepository.updateStatus(newsId, status);
      toastr.success('Notícia atualizada com sucesso!');
      setNews(
        news.map((item) => {
          if (item.id === newsId) {
            return { ...item, status };
          } else {
            return item;
          }
        })
      );
    } catch (err) {
      toastr.warning(
        'Não foi possível ativar a News',
        'Apenas uma única news por produto pode ser ativada. Verifique as news ativadas e tente novamente.'
      );
    }
  };

  const filteredNews = news?.filter(handleFilters);

  useEffect(() => {
    setType(branding.value);
  }, [branding]);

  return (
    <Card
      content={
        <div>
          <NewsFilter
            history={history}
            query={query}
            setQuery={setQuery}
            status={status}
            setStatus={setStatus}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            branding={branding}
            setBranding={setBranding}
          />

          <NewsTable
            loading={loading}
            news={filteredNews}
            handleUpdateStatus={handleUpdateStatus}
          />
        </div>
      }
    />
  );
}

export default withRouter(NewsMain);
