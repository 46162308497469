import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import salesReturnsRepository from '../../../repositories/SalesReturns'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { SaleReturnsByPeriodDocument } from './SaleReturnsByPeriodDocument'
import { useAuth } from 'contexts/auth'

export const SaleReturnsByPeriodReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [reportData, setReportData] = useState({})
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')

      setInitialDate(initialDate)
      setFinalDate(finalDate)

      return loadReport(initialDate, finalDate)
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (initialDate, finalDate) => {
    try {
      console.log(companyId)
      const { data } = await salesReturnsRepository.reportByPeriod({
        initialDate,
        companyId,
        finalDate,
      })
      setReportData(data)
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )
  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <SaleReturnsByPeriodDocument
            reportData={reportData}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
