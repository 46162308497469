export const xlsColumns = [
  {
    name: 'NCM',
    acessor: 'productNCM'
  },
  {
    name: 'Código',
    acessor: 'productCode'
  },
  {
    name: 'Descrição',
    acessor: 'productDescription'
  },
  {
    name: 'Unidade',
    acessor: 'productUnitType'
  },
  {
    name: 'Quantidade',
    acessor: 'productQuantity'
  },
  {
    name: 'Preço Unitário',
    acessor: 'productPrice'
  },
  {
    name: 'Total',
    acessor: 'productTotalPrice'
  }
]
