import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import TableByItem from './TableByItem';
import ChartValuesByStatus from './ChartValuesByStatus';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import salesRepository from '../../../../../repositories/Sales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { format, isValid, subMonths } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import UpgradeFreePlanModal from 'client/components/UpgradeFreePlanModal';
import { ModalExportXLS } from './ModalExportXLS';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import familiesRepository from 'repositories/Families';

const PorProdutoServico = () => {
  const [isUpgradeFreePlanModalOpen, setIsUpgradeFreePlanModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [valuesStatusChartData, setValuesStatusChartData] = useState([]);
  const [formatTableData, setFormatTableData] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [valuesByStatusToExport, setValuesByStatusToExport] = useState([]);
  const [valuesBySaleToExport, setValuesBySaleToExport] = useState([]);
  const [typeOfItem, setTypeOfItem] = useState('all');
  const [orderBy, setOrderBy] = useState('total');
  const [isXlsModalOpen, setIsXlsModalOpen] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState([]);
  const [families, setFamilies] = useState([]);
  const [familiesIds, setFamiliesIds] = useState([]);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const isPlanFree = localStorage.getItem('ID_EMPRESA');

  useEffect(() => {
    loadFamilies();
  }, [typeOfItem]);

  useEffect(() => {
    if (selectedFamily.length > 0) {
      const transformedFamily =
        selectedFamily[0].value === '*'
          ? ''
          : selectedFamily
              .map((item) => item.value)
              .filter((value) => value !== '*')
              .join(',');
      setFamiliesIds(transformedFamily);
    } else {
      setFamiliesIds(false);
    }
  }, [selectedFamily]);

  async function loadFamilies() {
    setLoading(true);
    try {
      const families = await familiesRepository.getAllByTypesAndCompany(
        companyId
      );

      const filteredFamilies =
        typeOfItem === 'product'
          ? families.filter((item) => item.Type === 'Produto')
          : typeOfItem === 'service'
          ? families.filter((item) => item.Type === 'Servico')
          : families;

      const serializedFamilies = filteredFamilies.map((family) => ({
        label: `${family.Type === 'Produto' ? 'P' : 'S'} - ${
          family.Description
        }`,
        value: family.id,
      }));

      serializedFamilies.unshift({
        label: 'Todas',
        value: '*',
      });

      setFamilies(serializedFamilies);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (familiesIds === false) {
      toastr.warning(
        'Selecione pelo menos uma família para gerar o relatório e tente novamente.'
      );
      return false;
    }

    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    setDisabled(false);
    try {
      const data = await salesRepository.generateSalesByProductService({
        typeOfItem,
        familiesIds,
        orderBy,
        companyId,
        initialDate,
        finalDate,
      });

      if (!data.sales.length) {
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente.'
        );
      }

      const formatedSales = data.sales.map((item) => ({
        description: item.description,
        type: item.typeItem,
        family: item.familyDescription || '-',
        qtdSale: item.qtdSale,
        sale: item.sale,
        os: item.os,
        pdv: item.pdv,
        total: item.total,
        salesTotalUnitGeneral:
          data.totalizatorsSalesByProductService.salesTotalUnitGeneral,
        osTotalGeneral: data.totalizatorsSalesByProductService.osTotalGeneral,
        pdvTotalGeneral: data.totalizatorsSalesByProductService.pdvTotalGeneral,
        salesTotalGeneral:
          data.totalizatorsSalesByProductService.salesTotalGeneral,
        salesQuantityGeneral:
          data.totalizatorsSalesByProductService.salesQuantityGeneral,
      }));

      const dataXLS = data.sales.map((item) => {
        return {
          percentage: data.totalizatorsSalesByProductService.salesTotalGeneral
            ? (
                (item.total /
                  data.totalizatorsSalesByProductService.salesTotalGeneral) *
                100
              ).toFixed(1)
            : 100,
          description: item.description,
          type: item.typeItem,
          family: item.familyDescription || '-',
          qtdSale: item.qtdSale,
          sale: item.sale,
          os: item.os,
          pdv: item.pdv,
          total: item.total,
        };
      });

      setValuesStatusChartData(
        data?.sales.map((item) => {
          return [
            item.description,
            Number(item.total),
            currency(Number(item.total)),
          ];
        })
      );

      const serializedValuesBySale = data.totalizatorsBySales.map((item) => ({
        ...item,
        saleDate: format(
          getDateOnlyFromDate(new Date(item.saleDate)),
          'dd/MM/yyyy'
        ),
        unitValue: item.unitValue,
        totalValue: item.totalValue,
      }));

      setFormatTableData(formatedSales);
      setValuesByStatusToExport(dataXLS);
      setValuesBySaleToExport(serializedValuesBySale);
      setDisabled(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com ao buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relatorio-por-pagamento-container"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <div>
          <span>
            <strong>Tipo de item:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="typeOfItem"
            value={typeOfItem}
            onChange={(e) => setTypeOfItem(e.target.value)}
          >
            <option value={'all'} selected>
              Todos
            </option>
            <option value={'product'}>Produto</option>
            <option value={'service'}>Serviço</option>
          </select>
        </div>

        <div>
          <span>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="initialDate"
            max={finalDate}
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>

        <div>
          <span>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="finalDate"
            min={initialDate}
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>

        <div>
          <InputContainer variant="column">
            <InputLabel
              style={{
                fontWeight: 'bold',
                height: '15px',
              }}
            >
              Família:
            </InputLabel>
            <SelectMulti
              width={150}
              height={35}
              options={families}
              value={selectedFamily}
              onChange={(e) => setSelectedFamily(e)}
              hasAllOption
              selectAllByDefault
              customAllLabel={'Todas'}
            />
          </InputContainer>
        </div>

        <div>
          <span>
            <strong>Ordenar por:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="orderBy"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <option value={'total'} selected>
              Valor Total
            </option>
            <option value={'qtdSale'}>Quantidade</option>
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            className="btn btn-sucesso"
            disabled={loading}
            onClick={handleSubmit}
            style={{ height: '35px' }}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            className="btn btn-export"
            onClick={() => setIsXlsModalOpen(true)}
            style={{ height: '35px' }}
            disabled={loading || !valuesByStatusToExport.length}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </button>
        </div>

        {isPlanFree === 'true' && (
          <div className="unlock-badge-container">
            <span
              className="sales-report-unlock-badge"
              onClick={() => setIsUpgradeFreePlanModalOpen(true)}
            >
              Desbloquear dados antes de{' '}
              {format(subMonths(new Date(), 1), "dd 'de' MMMM", {
                locale: pt,
              })}
            </span>
          </div>
        )}
      </div>

      <section>
        <div
          style={{
            padding: 5,
          }}
        >
          <TableByItem data={formatTableData} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          <ChartValuesByStatus data={valuesStatusChartData} />
        </div>
      </section>

      <UpgradeFreePlanModal
        show={isUpgradeFreePlanModalOpen}
        onCancel={() => setIsUpgradeFreePlanModalOpen(false)}
      />

      {isXlsModalOpen && (
        <ModalExportXLS
          isOpen={true}
          handleClose={() => setIsXlsModalOpen(false)}
          dataByType={valuesByStatusToExport}
          dataBySales={valuesBySaleToExport}
        />
      )}
    </div>
  );
};

export default PorProdutoServico;
