import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'

import Button from 'client/components/CustomButton/CustomButton'
import CollapsibleSection from 'components/CollapsibleSection'
import NewTradeRepresentativeBasicData from './BasicData'

import { useAuth } from 'contexts/auth'
import { format } from 'date-fns'
import AlertModal from 'components/AlertModal'
import { toastr } from 'react-redux-toastr'

import tradeRepresentativeRepository from 'repositories/TradeRepresentative'
import NewTradeRepresentativeAddress from './Address'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { validateCnpj } from 'client/components/Validations/Validations'
import { validateCpf } from 'client/components/Validations/Validations'
import { isEmail } from 'utils/validations'

export default function NewTradeRepresentativeMain({ tradeRepresentativeId }) {
  const { companyId } = useAuth()
  const history = useHistory()

  const [isBasicDataExpanded, setIsBasicDataExpanded] = useState(true)
  const [isAddressDataExpanded, setIsAddressDataExpanded] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false)

  const handleBasicDataSectionState = () => {
    setIsBasicDataExpanded(!isBasicDataExpanded)
  }

  const handleAddressDataSectionState = () => {
    setIsAddressDataExpanded(!isAddressDataExpanded)
  }

  const handleNextSection = () => {
    handleBasicDataSectionState()
    setIsAddressDataExpanded(true)
  }

  const handleCancel = () => {
    if (isDirty) {
      return setIsCancelConfirmationModalOpen(true)
    }
    history.push('trade-representatives')
  }

  const validateData = (data) => {
    if (data.email && !isEmail(data.email)) {
      toastr.warning('E-mail inválido', 'Por favor, informe um e-mail válido')
      return false
    }

    if (
      data.cellPhone &&
      data.cellPhone?.replace(/[^\d]/g, '')?.length !== 11
    ) {
      toastr.warning('Celular inválido', 'Por favor, informe um celular válido')
      return false
    }

    if (
      data.commercialPhone &&
      data.commercialPhone?.replace(/[^\d]/g, '')?.length !== 10
    ) {
      toastr.warning(
        'Telefone comercial inválido',
        'Por favor, informe um telefone comercial válido'
      )
      return false
    }

    if (data.type === 'Fisica') {
      if (!data.companyName || !data.cellPhone || !data.email) return false
      return true
    } else {
      if (
        !data.companyName ||
        !data.tradingName ||
        !data.contact ||
        !data.cellPhone
      )
        return false
      return true
    }
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const isValid = validateData(data)
      if (!isValid) {
        setIsLoading(false)
        return toastr.warning(
          'Não foi possível salvar',
          'Existem campos obrigatórios não preenchidos. Preencha todos os campos e tente novamente.'
        )
      }

      const formattedData = {
        provider: {
          companyName: data.companyName,
          tradingName: data.tradingName,
          cpfCnpj: data.cpfCnpj.replace(/[^\d]/g, ''),
          birthdate: data.birthdate,
          ie: data.ie,
          RG: data.RG,
          type: data.type,
          email: data.email,
          isActive: data.isActive,
          observations: data.observations,
          serviceComission: data.serviceComission,
          productComission: data.productComission,
          companyId,
        },
        phones: {
          phonesId: data.phonesId,
          commercialPhone: data.commercialPhone?.replace(/[^\d]/g, ''),
          cellPhone: data.cellPhone?.replace(/[^\d]/g, ''),
          contact: data.contact,
        },
        address: {
          id: data.addressId,
          zipcode: data.zipcode,
          street: data.street,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          streetNumber: data.streetNumber,
          referencePoint: data.referencePoint,
        },
      }

      if (tradeRepresentativeId) {
        await tradeRepresentativeRepository.update(
          tradeRepresentativeId,
          formattedData
        )
      } else {
        await tradeRepresentativeRepository.create(formattedData)
      }

      toastr.success(
        'Cadastro realizado',
        'Representante cadastrado com sucesso'
      )

      history.push('trade-representatives')
    } catch (err) {
      console.log(err)
      setIsLoading(false)
      toastr.error('OS Digital', 'Ocorreu um erro. Tente novamente!')
    }
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      const tradeRepresentative = await tradeRepresentativeRepository.show(
        tradeRepresentativeId
      )

      setValue('type', tradeRepresentative.type)
      setValue('cpfCnpj', tradeRepresentative.cpfCnpj)
      setValue('birthdate', tradeRepresentative.birthdate)
      setValue(
        'createdAt',
        format(getDateOnlyFromDate(tradeRepresentative.createdAt), 'yyyy-MM-dd')
      )
      setValue('companyName', tradeRepresentative.companyName)
      setValue('tradingName', tradeRepresentative.tradingName)
      setValue('email', tradeRepresentative.email)
      setValue('RG', tradeRepresentative.RG)
      setValue('IE', tradeRepresentative.IE)
      setValue('productComission', tradeRepresentative.productComission)
      setValue('serviceComission', tradeRepresentative.serviceComission)
      setValue('isActive', tradeRepresentative.isActive)
      setValue('statusIE', tradeRepresentative.statusIE || '')
      setValue('cellPhone', tradeRepresentative.Phones[0].Number_Phone2)
      setValue('commercialPhone', tradeRepresentative.Phones[0].Number_Phone1)
      setValue('phonesId', tradeRepresentative.Phones[0].id)
      setValue('contact', tradeRepresentative.Phones[0].Contact)
      setValue('observations', tradeRepresentative.observations)
      setValue('addressId', tradeRepresentative.Address[0].id)
      setValue('zipcode', tradeRepresentative.Address[0].Zipcode)
      setValue('street', tradeRepresentative.Address[0].Street)
      setValue('complement', tradeRepresentative.Address[0].Complement)
      setValue('neighborhood', tradeRepresentative.Address[0].Neighborhood)
      setValue('city', tradeRepresentative.Address[0].City)
      setValue('state', tradeRepresentative.Address[0].State)
      setValue('streetNumber', tradeRepresentative.Address[0].Address_Number)
      setValue('referencePoint', tradeRepresentative.Address[0].Reference_Point)

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados. Tente novamente!'
      )
      setIsLoading(false)
      history.push('trade-representatives')
    }
  }
  const formMethods = useForm({
    defaultValues: {
      companyName: '',
      tradingName: '',
      cpfCnpj: '',
      ie: '',
      RG: '',
      type: 'Juridica',
      email: '',
      isActive: 1,
      observations: '',
      serviceComission: '',
      birthdate: null,
      productComission: '',
      companyId,

      phonesId: '',
      commercialPhone: '',
      cellPhone: '',
      contact: '',

      addressId: '',
      zipcode: '',
      street: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      streetNumber: '',
      referencePoint: '',

      createdAt: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const {
    setValue,
    formState: { isDirty },
  } = formMethods

  useEffect(() => {
    if (tradeRepresentativeId) {
      loadData()
    }
  }, [])

  return (
    <div className="new-trade-representative">
      <FormProvider {...formMethods}>
        <CollapsibleSection
          title={'Dados Básicos'}
          isExpanded={isBasicDataExpanded}
          handleExpand={handleBasicDataSectionState}
        >
          <NewTradeRepresentativeBasicData
            handleNextSection={handleNextSection}
            tradeRepresentativeId={tradeRepresentativeId}
          />
        </CollapsibleSection>
        <CollapsibleSection
          title={'Endereço Principal (Opcional)'}
          isExpanded={isAddressDataExpanded}
          handleExpand={handleAddressDataSectionState}
        >
          <NewTradeRepresentativeAddress />
        </CollapsibleSection>

        <div className="new-trade-representative__actions">
          <Button
            fill
            bsStyle="danger"
            name="cancelar"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            fill
            bsStyle="info"
            disabled={isLoading}
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            Salvar
          </Button>
        </div>
      </FormProvider>

      {isCancelConfirmationModalOpen && (
        <AlertModal
          show={isCancelConfirmationModalOpen}
          animation={false}
          message={
            <>
              {tradeRepresentativeId ? (
                <>
                  <span>Você deseja cancelar a edição?</span>
                  <br />
                  <span>
                    Todos os valores alterados serão perdidos e será necessário
                    alterar novamente mais tarde.
                  </span>
                </>
              ) : (
                <>
                  <span>Você deseja cancelar o cadastro de representante?</span>
                  <br />
                  <span>
                    Se cancelar, você poderá cadastrar novamente mais tarde.
                  </span>
                </>
              )}
            </>
          }
          onHide={() => setIsCancelConfirmationModalOpen(false)}
          onCancel={() => setIsCancelConfirmationModalOpen(false)}
          onSubmit={() => history.push('trade-representatives')}
        />
      )}
    </div>
  )
}
