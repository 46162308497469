import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import CardForm from '../../../../../client/components/CardForm'
import InactiveNFeModule from './InactiveNFeModule'
import FormSettingsNFe from './SettingsNFe'

import settingsNFeRepository from '../../../../../repositories/SettingsNFe'
import NFesRepository from '../../../../../repositories/NFes'

import { useAuth } from '../../../../../contexts/auth'
import { useModulesContext } from '../../../../../contexts/modules'

const SettingsNFe = () => {
  const [isNFeDataOpen, setIsNFeDataOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()
  const dispatch = useDispatch()

  const { isNFeModuleActive } = useModulesContext()

  async function handleSubmit(values) {
    if (loading) return
    const { settingsNFeId, lastNFeSent, serie, environment } = values
    setLoading(true)

    const code = parseInt(lastNFeSent || 0) + 1
    const nfe = await NFesRepository.search({
      companyId,
      code,
      serie,
      environment,
    })

    if (!!nfe) {
      setLoading(false)
      return toastr.warning(
        `Já existe uma NF-e criada com a numeração ${code} para essa série e esse ambiente. Valide a numeração correta`
      )
    }
    if (hasNFeInfosNotInserted(values)) {
      setLoading(false)
      return toastr.warning('Insira todos os campos de NF-e')
    }

    if (!settingsNFeId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { environment, serie, printingTemplate, lastNFeSent } = values

    try {
      const settingsNFe = await settingsNFeRepository.create({
        companyId,
        environment,
        serie: serie || null,
        printingTemplate,
        lastNFeSent: lastNFeSent || null,
      })
      const data = {
        settingsNFeId: settingsNFe.id,
        environment,
        lastNFeSent: String(lastNFeSent),
        serie,
        printingTemplate,
      }
      await dispatch(initialize('settingsNFe', data))
      setLoading(false)
      toastr.success('Configurações de NF-e salva')
    } catch (err) {
      setLoading(false)
      console.error(err)
      toastr.error(err.response.data.message)
    }
  }

  async function update(values) {
    const { environment, serie, printingTemplate, lastNFeSent, settingsNFeId } =
      values

    try {
      await settingsNFeRepository.update(settingsNFeId, {
        environment,
        serie: serie || null,
        printingTemplate,
        lastNFeSent: lastNFeSent || null,
      })
      await dispatch(initialize('settingsNFe', values))
      setLoading(false)
      toastr.success('Configurações de NF-e atualizada')
    } catch (err) {
      console.error(err)
      setLoading(false)
      toastr.error(err.response.data.message)
    }
  }

  function hasNFeInfosNotInserted(values) {
    const { lastNFeSent, serie, environment } = values

    if (lastNFeSent === '' && !serie && !environment) {
      return false
    }

    let fieldValuesInserted = 0

    if (serie) fieldValuesInserted++
    if (lastNFeSent) fieldValuesInserted++
    if (environment) fieldValuesInserted++

    if (fieldValuesInserted < 3) return true

    return false
  }

  const initialValues = {
    environment: '',
    printingTemplate: 'Retrato',
  }

  return (
    <CardForm
      show={isNFeDataOpen}
      title="Dados da NF-e"
      onClick={() => setIsNFeDataOpen(!isNFeDataOpen)}
    >
      {isNFeModuleActive ? (
        <FormSettingsNFe
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={loading}
        />
      ) : (
        <InactiveNFeModule />
      )}
    </CardForm>
  )
}

export default SettingsNFe
