import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import purchaseRepository from '../../../../repositories/Purchases';
import { PURCHASE_STATUS } from 'utils/constants';

export const usePurchaseXmlImport = (onSubmit, companyId, providerInDB) => {
  const dispatch = useDispatch();

  const handleFinish = async (values, items) => {
    const allProductsHaveMarginAndSalesPrice = items.some((p) => {
      return (
        (p.newMargin !== undefined &&
          p.newMargin > 0 &&
          p.newSalesPrice !== undefined) ||
        (p.profitMarginWholesale !== undefined &&
          p.profitMarginWholesale > 0 &&
          p.salePriceWholesale !== undefined)
      );
    });

    if (!allProductsHaveMarginAndSalesPrice) {
      toastr.warning(
        'Compra não criada por campo incompleto',
        'Existem produtos não vinculados e/ou produtos que não possui os campos obrigatórios preenchidos. Verifique e tente novamente'
      );
      return;
    }
    if (items.some((p) => !p.productId))
      return toastr.warning('Vincule ou cadastre todos os itens para concluir');

    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change(
        'PurchaseXmlImport',
        'loadingMessage',
        'Aguarde, finalizando importação'
      ),
    ]);

    try {
      await purchaseRepository.create({
        purchase: {
          invoice: values.nfCode,
          serie: values.serie,
          model: values.mod,
          request: values.request,
          accessKey: values.accessKey,
          issuedAt: new Date(values.dhEmi),
          entryAt: new Date(),
          BCICMS: values.vBC,
          BCICMSST: values.vBCST,
          PISValue: values.vPIS,
          IPIValue: values.vIPI,
          FCPValue: values.vFCP,
          productsValue: values.vProd,
          total: values.vNF,
          ICMSValue: values.vICMS,
          ICMSSTValue: values.vST,
          COFINSValue: values.vCOFINS,
          inssuranceValue: values.vSeg,
          FCPSTValue: values.vFCPST,
          discountValue: values.vDesc,
          purchaseStatusId: PURCHASE_STATUS.OPEN,
          otherExpenses: values.vOutro,
          freightValue: values.vFrete,
        },
        companyId,
        providerId: providerInDB.id,
        items: items.map((item) => ({
          ...item,
          productProviderCode: item.providerCode,
          multiplierBase: item.multiplierBase,
          total: item.unitValue * item.quantity,
          CSTCSOSN: item.CST || item.CSOSN,
          NCM: item.ncm,
          unitDiscount: item.unitDiscountValue,
          salesPrice: item.newSalesPrice,
          margin: item.newMargin,
          profitMarginWholesale: item.profitMarginWholesale,
          salePriceWholesale: item.salePriceWholesale,
          CFOP: item.cfop,
        })),
      });

      toastr.success('Compra importada com sucesso');
      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum erro inesperado.',
        'Por favor, tente novamente mais tarde.'
      );
    } finally {
      dispatch(change('PurchaseXmlImport', 'loading', false));
    }
  };

  return { handleFinish };
};
