import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: ['Início', 'Cadastros', 'Checklist Personalizado', 'Grupos de Itens'],
    path: [
      '/client/home',
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.GROUP_ITEMS.BASE,
    ],
  };
};
