import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import VouchersIssuedMain from './components/VouchersIssuedMain'

import './index.css'

export default function VouchersIssued({ location }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Financeiro',
              'Vales Emitidos',
            ]}
            path={['home', null, null, null]}
          />
          <VouchersIssuedMain />
        </Row>
      </Grid>
    </div>
  )
}
