import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from 'client/components/CustomButton/CustomButton'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import { xlsInventoryCountColumns } from './excelInventoryCount'
import DownloadXlsButton from 'components/DownloadXlsButton'

const InventoryCountPrintModal = ({
  isPrintModalOpen,
  setIsPrintModalOpen,
  inventoryCountId,
  inventoryCountList,
}) => {
  const inventory = inventoryCountList.find(
    (inventory) => inventory.id === inventoryCountId
  )

  const products = inventory.InventoryProducts.map((item) => {
    return {
      ...item,
      code: item.Products.code,
    }
  })

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/inventory-count?inventoryCountId=${inventoryCountId}`,
      '_blank'
    )
  }

  return (
    <Modal
      animation
      show={isPrintModalOpen}
      onHide={() => setIsPrintModalOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Selecione a opção desejada:</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <button
            style={{ width: '240px' }}
            className="btn btn-export"
            onClick={handlePrint}
          >
            Gerar Relatório em impresso
          </button>
          <DownloadXlsButton
            archiveName={`Inventário - ${inventory.observations}`}
            data={products}
            className="btn btn-sucesso"
            columns={xlsInventoryCountColumns}
            style={{ width: '240px' }}
          >
            Gerar Relatório em XLS
          </DownloadXlsButton>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          bsStyle="danger"
          fill
          onClick={() => setIsPrintModalOpen(false)}
        >
          Voltar
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default InventoryCountPrintModal
