import React, { useState } from 'react'
import { reduxForm } from 'redux-form'

import CardForm from 'client/components/CardForm'
import TrayConfigForm from './TrayConfigForm'
import ChooseModeModal from './ChooseModeModal'
import CreateOrUpdateTrayProductModal from './CreateOrUpdateTrayProductModal'

const TrayForm = ({ handleSubmit, pristine, loading }) => {
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(true)
  const [isProductsOptionsModalOpen, setIsProductsOptionsModalOpen] =
    useState(false)
  const [isCreateUpdateProductModalOpen, setIsCreateUpdateProductModalOpen] =
    useState(false)
  const [mode, setMode] = useState()

  const openCreateUpdateProductModal = (mode) => {
    setMode(mode)
    setIsCreateUpdateProductModalOpen(true)
  }

  return (
    <>
      <section>
        <CardForm
          title='Configuração'
          show={isConfigFormOpen}
          onClick={() => setIsConfigFormOpen(!isConfigFormOpen)}
        >
          <TrayConfigForm
            onSubmit={handleSubmit}
            pristine={pristine}
            loading={loading}
          />
        </CardForm>
        <button
          className='btn'
          style={{
            backgroundColor: '#428BCA',
            borderColor: '#428BCA',
            opacity: 1,
            color: 'white',
          }}
          onClick={() => setIsProductsOptionsModalOpen(true)}
        >
          Enviar produtos
        </button>
      </section>
      <ChooseModeModal
        show={isProductsOptionsModalOpen}
        onCancel={() => setIsProductsOptionsModalOpen(false)}
        openCreateUpdateProductModal={openCreateUpdateProductModal}
      />
      {isCreateUpdateProductModalOpen ? (
        <CreateOrUpdateTrayProductModal
          mode={mode}
          show={isCreateUpdateProductModalOpen}
          setIsCreateUpdateProductModalOpen={setIsCreateUpdateProductModalOpen}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default reduxForm({
  form: 'tray',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: true,
})(TrayForm)
