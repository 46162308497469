import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import ReactTable from 'react-table'
import CurrencyInput from 'react-currency-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import {
  QuotationDataContainer,
  SectionContainer,
  SectionTitle,
} from '../styles'

export function QuotationData({
  quotationItems,
  replied,
  cancelled,
  showMobileVersion,
}) {
  const { getValues, control, register } = useFormContext()
  const { quotation } = getValues()

  return (
    <>
      {showMobileVersion ? (
        <>
          <div className="quotation-container-mobile">
            <div className="quotation-header-mobile">
              <p className="quotation-title">
                <FontAwesomeIcon
                  icon={faBoxOpen}
                  style={{ paddingRight: '7px', fontSize: '20px' }}
                />{' '}
                Dados da Cotação
              </p>
              <p>
                Número da Cotação: <strong>{quotation.code}</strong> <br />
                Data da Cotação:{' '}
                <strong>
                  {format(new Date(quotation.createdAt), 'dd/MM/yyyy')}
                </strong>
              </p>
              <hr />
              <textarea
                className="form-control"
                value={quotation.observations}
                disabled
                placeholder={
                  quotation.observations ||
                  'Sem observação do cliente para cotação'
                }
              />
            </div>
          </div>
          {quotationItems.map((item, index) => (
            <div
              key={index}
              className="quotation-container-mobile quotation-item-card"
            >
              <p>
                <strong>{item.description}</strong>
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>Ref. Fabricante: {item.manufacturer_ref}</p>
                <p style={{ color: '#d9534f' }}>
                  <strong>Qtd. Necessária: {item.quantity}</strong>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Controller
                  name={`quotationItems.${index}.avaliable_quantity`}
                  control={control}
                  render={({ field }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label
                        className="quotation-response-label"
                        htmlFor={`avaliable_quantity-${index}`}
                      >
                        Qtd. Disponível:
                      </label>
                      <CurrencyInput
                        thousandSeparator="."
                        decimalSeparator=","
                        id={`avaliable_quantity-${index}`}
                        className="form-control"
                        value={item.avaliable_quantity}
                        style={{
                          border: 'none',
                          width: '50%',
                          borderBottom: '1px solid #c0c0c0',
                          color: '#5DB85B',
                        }}
                        disabled={replied || cancelled}
                        {...field}
                      />
                    </div>
                  )}
                />
                <Controller
                  name={`quotationItems.${index}.unit_value`}
                  control={control}
                  render={({ field }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label
                        className="quotation-response-label"
                        htmlFor={`unit_value-${index}`}
                      >
                        Valor Unitário:
                      </label>
                      <CurrencyInput
                        prefix={'R$ '}
                        thousandSeparator="."
                        decimalSeparator=","
                        id={`unit_value-${index}`}
                        className="form-control"
                        value={item.unit_value}
                        style={{
                          border: 'none',
                          width: '50%',
                          borderBottom: '1px solid #c0c0c0',
                          color: '#5DB85B',
                        }}
                        disabled={replied || cancelled}
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          ))}

          <textarea
            maxLength={1000}
            id="reply_observations"
            className="quotation-container-mobile"
            placeholder="Insira aqui a sua observação para o cliente"
            {...register('reply_observations')}
          />

          <div>
            <hr />
          </div>
        </>
      ) : (
        <SectionContainer>
          <QuotationDataContainer>
            <SectionTitle style={{ marginTop: 0 }}>
              <FontAwesomeIcon
                icon={faBoxOpen}
                style={{ paddingRight: '7px' }}
              />
              Dados da Cotação
            </SectionTitle>
            <div className="quotation-info">
              <p>
                Número da cotação: {quotation.code}
                <br />
                Data da cotação:{' '}
                {format(new Date(quotation.createdAt), 'dd/MM/yyyy')}
              </p>
            </div>

            <div className="quotation-products">
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
                data={quotationItems}
                columns={[
                  {
                    Header: 'Descrição',
                    accessor: 'description',
                    minWidth: 100,
                  },
                  {
                    Header: 'Ref. Fabricante',
                    accessor: 'manufacturer_ref',
                    minWidth: 50,
                  },
                  { Header: 'Qtd', accessor: 'quantity', minWidth: 30 },
                  {
                    Header: 'Qtd. Disponível',
                    accessor: 'avaliable_quantity',
                    minWidth: 60,
                    Cell: (props) => (
                      <Controller
                        name={`quotationItems.${props.index}.avaliable_quantity`}
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control foco-input"
                            value={props.value}
                            disabled={replied || cancelled}
                            {...field}
                          />
                        )}
                      />
                    ),
                  },
                  {
                    Header: 'Valor unitário',
                    accessor: 'unit_value',
                    minWidth: 60,
                    Cell: (props) => (
                      <Controller
                        name={`quotationItems.${props.index}.unit_value`}
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            prefix={'R$ '}
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control foco-input"
                            value={props.value}
                            disabled={replied || cancelled}
                            {...field}
                          />
                        )}
                      />
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPagination={quotationItems.length > 10}
                pageSizeOptions={
                  quotationItems.length > 10 && [5, 10, 20, 25, 50, 100]
                }
                sortable={false}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum produto encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                manual
              />
            </div>
            <textarea
              className="form-control"
              value={quotation.observations}
              disabled
              placeholder={
                quotation.observations ||
                'Sem observação do cliente para cotação'
              }
            />
            <label
              htmlFor="reply_observations"
              className="reply_observations-label"
            >
              Observação:
            </label>
            <textarea
              maxLength={1000}
              id="reply_observations"
              className="form-control"
              {...register('reply_observations')}
            />
          </QuotationDataContainer>
        </SectionContainer>
      )}
    </>
  )
}
