import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NewStock from './FormNewStock'
import constants from '../../../../utils/constants'

const ViewNewStock = ({ history, match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <BreadCrumb
              data={[
                'Início',
                'Produtos',
                'Movimentação de Estoque',
                !!match.params.id ? 'Editar Movimentação' : 'Nova Movimentação',
              ]}
              path={[
                constants.ROUTES.HOME,
                null,
                constants.ROUTES.STOCKS,
                null,
              ]}
            />
            <span style={{ color: 'red', position: 'absolute', right: 10 }}>
              * Campos Obrigatórios
            </span>
          </div>
          <NewStock
            id={match.params.id}
            onCancel={() => history.push(constants.ROUTES.STOCKS)}
            onSubmit={() => history.push(constants.ROUTES.STOCKS)}
          />
        </Row>
      </Grid>
    </div>
  )
}

export default withRouter(ViewNewStock)
