const { default: styled } = require('styled-components');

export const SaleInstallmentsWrapper = styled.div``;

export const SaleInstallmentHeader = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr 1.6fr 2fr 1fr 2fr;
  align-items: center;
  gap: 1.5rem;
`;

export const HeaderItem = styled.span`
  display: block;
  margin: 0 !important;
  text-align: center;
  font-size: 14px !important;
  font-weight: bold;
`;

export const HeaderPaymentForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HyperlinkButton = styled.button`
  all: unset;
  font-size: 13px;
  color: #1dc7ea;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const InstallmentsContainer = styled.div`
  margin-top: 1.5rem;
`;

export const InstallmentRow = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr 1.6fr 2fr 1fr 2fr;
  align-items: center;
  gap: 1.5rem;
`;
