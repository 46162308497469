import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import FormCliente from '../../../Clients/NewClient/FormClient'

const ClientErrorsTable = ({ errors, onCorrection }) => {
  const [indexToEdit, setIndexToEdit] = useState(null)
  const [isClientModalOpen, setIsClientModalOpen] = useState(false)
  const [clientErrors, setClientErrors] = useState([])

  const dispatch = useDispatch()
  const { customerId } = useSelector(state => state.form.NFSe.values)

  useEffect(() => {
    setClientErrors(errors)
  }, [errors])

  function handleOpenClientModal(index) {
    setIndexToEdit(index)
    setIsClientModalOpen(true)
  }

  function handleSubmitClientModal() {
    const newErrors = [...clientErrors]
    newErrors[indexToEdit].isResolved = true
    
    setIsClientModalOpen(false)
    onCorrection(newErrors)
  }

  return (
    <>
      <ReactTable
        className='client-errors-table'
        data={clientErrors}
        columns={[
          {
            Header: "",
            accessor: 'isResolved',
            headerClassName: "text-left",
            width: 50,
            Cell: props => (
              <FontAwesomeIcon
                icon={props.value ? faCheckCircle : faTimesCircle}
                color={props.value ? 'green' : 'red'}
                size={30}
              />
            )
          },
          {
            Header: "Item",
            accessor: 'item',
            headerClassName: "text-left",
            width: 250
          },
          {
            Header: "Erro",
            accessor: "error",
            headerClassName: "text-left",
            width: 250
          },
          {
            Header: "Correção",
            accessor: "correction",
            headerClassName: "text-left",
            Cell: props => (
              <span
                className='hiperlink'
                onClick={() => handleOpenClientModal(props.index)}
              >
                {props.value}
              </span>
            )
          }
        ]}
        defaultPageSize={5}
        sortable={false}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText=''
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />

      <Modal
        dialogClassName="edit-customer-modal-wrapper"
        show={isClientModalOpen}
        onHide={() => setIsClientModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Editar Cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='edit-customer-content'>
          <FormCliente
            modal
            // hideModal={() => setIsClientModalOpen(false)}
            hideModal={handleSubmitClientModal}
            handleModal={handleSubmitClientModal}
            dispatch={dispatch}
            ClientId={customerId}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClientErrorsTable