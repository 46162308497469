import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import HomeProfiles from './Profiles';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Profiles = () => {
  localStorage.setItem('selectedPath', 'client/home');

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Meus Perfis de Acesso']}
            path={['home', null]}
          />
          <HomeProfiles />
        </Row>
      </Grid>
    </div>
  );
};

export default Profiles;
