import SearchInput from 'components/SearchInput';
import { addDays, format } from 'date-fns';
import React from 'react';
import constants from 'utils/constants';
import '../styles.css';
import { Span } from 'v2/views/client/ExportXML/ExportXML.styles';

const PartsRequisitionFilter = ({
  history,
  setStatus,
  handleChangeQuery,
  employees,
  setEmployeeId,
  setInitialDate,
  setFinalDate,
  finalDate,
  initialDate,
}) => {
  return (
    <div>
      <div
        style={{
          padding: 0,
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'flex-end',
          gap: '10px',
          marginTop: '10px',
        }}
      >
        <div>
          <button
            className="btn btn-sucesso button-h35"
            onClick={() => history.push(constants.ROUTES.NEW_PART_REQUISITION)}
            style={{ fontSize: '12px' }}
          >
            + Novo Lançamento
          </button>
        </div>
        <div>
          <SearchInput
            placeholder="Pesquisa por Venda/OS/PDV ou Cliente"
            onChange={(e) => handleChangeQuery(e.target.value)}
            style={{
              fontSize: '12px',
              height: '35px',
              width: '312px',
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Status:</strong>
          </span>
          <select
            className="foco-input form-control"
            onChange={(e) => setStatus(e.target.value)}
            style={{ fontSize: '12px' }}
          >
            <option value="">Ambos</option>
            <option value="Entregue">Entregue</option>
            <option value="Cancelado">Cancelado</option>
          </select>
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Funcionários:</strong>
          </span>
          <select
            className="foco-input form-control"
            onChange={(e) => setEmployeeId(e.target.value)}
            style={{ fontSize: '12px' }}
          >
            <option value="">Todos</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Data Inicial:</strong>
          </span>
          <input
            type="date"
            className="foco-input form-control"
            onChange={(e) => setInitialDate(e.target.value)}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
            style={{ fontSize: '12px' }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Data Final:</strong>
          </span>
          <input
            type="date"
            className="foco-input form-control"
            onChange={(e) => setFinalDate(e.target.value)}
            max={format(new Date(), 'yyyy-MM-dd')}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : null
            }
            style={{ fontSize: '12px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PartsRequisitionFilter;
