import React from 'react';
import { Modal } from 'react-bootstrap';

export function ModalDigitalPaymentAlert({
  open,
  onClose,
  onSendInvoice,
  onVerifyPayments,
}) {
  const handleClose = () => {
    onClose();
  };

  const handleSendInvoice = () => {
    onSendInvoice();
    handleClose();
  };

  const handleVerifyPayments = () => {
    onVerifyPayments();
    handleClose();
  };

  return (
    <Modal show={open} onHide={handleClose} dialogClassName="modal-35w">
      <Modal.Header>
        <Modal.Title>
          <strong>Comprovante de Pagamento Eletrônico</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Existem formas de pagamento eletrônicas nessa venda.</span>
        <br />
        <br />
        <span>
          Deseja verificar os códigos de comprovante de pagamento antes de
          enviar?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 flex end gap-050">
          <button
            className="button button-blue button-h35"
            onClick={handleSendInvoice}
          >
            Enviar a nota
          </button>
          <button
            className="button button-green button-h35"
            onClick={handleVerifyPayments}
          >
            Verificar antes de enviar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
