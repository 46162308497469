import styled from 'styled-components';

export const ProfitPanelPage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #eeeeee;

  overflow: auto;
`;

export const DeleteButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  transition: 0.5s;

  &:hover {
    background-color: #eeeeee;
    transition: 0.5s;
  }
`;

export const ProfitPanelCard = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: #f3f3f3;
  color: ${({ theme }) => theme.colors.branding1000};
  border-radius: 5px;

  display: grid;
  grid-template-columns: 1.3fr 4fr;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
`;

export const ProfitPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const CardTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 52px;

  font-size: 18px;
  color: ${({ theme }) => theme.colors.branding1000};
  font-weight: bold;

  border-bottom: 1px solid #cfcfcf;

  & svg {
    color: ${({ theme }) => theme.colors.branding1000} !important;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`;

export const CardContent = styled.div`
  background: white;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #cfcfcf;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const CardTable = styled.div`
  width: 100%;
`;

export const CardTableBody = styled.div`
  width: 100%;
  height: ${({ width }) => width};
`;

export const ProfitProjection = styled.span`
  display: block;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  color: ${({ theme }) => theme.colors.branding1000};

  & .total {
    color: #72bb53;
  }

  & .red {
    color: red;
  }
`;

export const AddCategoryButton = styled.button`
  all: unset;
  font-size: 16px;
  color: #72bb53;
  font-weight: bold;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    color: #fff;
    width: 15px;
    height: 15px;
    padding: 2px;
    background-color: #72bb53;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
    color: #43752e;
    & svg {
      background-color: #43752e;
    }
  }
`;

export const ProfitTable = styled.table`
  border: 6px solid #ffffff;
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  max-height: 270px;
  overflow-y: auto;

  td,
  th {
    border: 0px solid #aaaaaa;
    padding: 3px 8px;
  }

  tbody td {
    font-size: 13px;
  }

  thead {
    border-bottom: 1px solid #cfcfcf;
  }

  thead th {
    font-size: 15px;
    font-weight: bold;
    text-align: left;

    cursor: pointer;
  }

  tfoot td {
    font-size: 14px;
  }

  tfoot .links {
    text-align: right;

    a {
      display: inline-block;
      background: #1c6ea4;
      color: #ffffff;
      padding: 2px 8px;
      border-radius: 5px;
    }
  }
`;
