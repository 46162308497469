const nfseHelper = {
  handleConstructXMLLinkFromPDFLink(pdfLink) {
    const parmCriptMatch = pdfLink.split('ParmCript=')[1];

    if (!parmCriptMatch) {
      return pdfLink;
    }

    const xmlBaseUrl = 'https://app.invoicy.com.br/HNUC002.aspx';
    const xmlLink = `${xmlBaseUrl}?ParmCript=${parmCriptMatch}&DocDetArqCodigo=0&DocEveTipo=0&DocEvenSeq=0`;

    return xmlLink;
  },
};

export { nfseHelper };
