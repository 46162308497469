import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BankConciliationTable } from '../../../BankConciliationTable/BankConciliationTable';
import {
  BankConciliationFinancialRegisterContainer,
  StyledTableContainer,
} from '../../BankConciliationDetails.styles';
import { useBankConciliation } from '../../../../hooks/useBankConciliation';
import { BankConciliationDetailsSearch } from '../BankConciliationDetailsSearch/BankConciliationDeatilsSearch';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';
import { toastr as toast } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { useBankConciliationDetailsColumnsConfig } from 'v2/views/client/BankConciliation/hooks/useBankConciliationDetailsColumnsConfig';
import CollapsibleSection from 'components/CollapsibleSection';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { Table } from 'v2/components/Table';
import { useBankConciliationStore } from 'v2/store/bankConciliation';

export function BankConciliationFinancialRegister() {
  const { bankId } = useBankConciliationStore((store) => {
    return {
      bankId: store.bankConciliation.dataOFX.headerFile.cashierBankId,
    };
  });

  const { getValues, setValue, watch, trigger } = useFormContext();

  const [isOpenCollapsibleSection, setIsOpenCollapsibleSection] =
    React.useState(true);
  const { companyId } = useAuth();
  const { configColumnsFinancialRegister, configColumnsFinancialMovements } =
    useBankConciliationDetailsColumnsConfig(setValue, getValues, trigger);

  const { handleFinancialRegisterInicialDateChange } = useBankConciliation(
    getValues,
    setValue
  );

  const prefix = 'financialRegister';

  const description = watch(`search.${prefix}.description`);
  const transactionType = watch(`search.${prefix}.transactionType`);
  const transactionStatus = watch(`search.${prefix}.transactionStatus`);
  const TransactionInitialDate = watch(
    `search.${prefix}.TransactionInitialDate`
  );
  const TransactionFinishDate = watch(`search.${prefix}.TransactionFinishDate`);
  const dataMovements = watch(`dataFinancialMovements`);
  const typeTable = watch(`search.${prefix}.typeFinancialRegister`);
  const dataFinancialRegister = watch(`dataFinancialRegister`);
  const isViewing = watch('isViewing');

  const [totalPages, setTotalPages] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { setIsLoading, isLoading } = useLoading();

  async function loadFinancialTitle() {
    try {
      setIsLoading(true);
      const type =
        transactionType?.label === 'Ambos' ? '' : transactionType?.label;
      const status = transactionStatus?.id === 0 ? '' : transactionStatus?.id;

      const { initialDate, finalDate } =
        handleFinancialRegisterInicialDateChange();
      const getBills = await BankConciliationRepositoryV2.getBills({
        initialDate: TransactionInitialDate || initialDate,
        finalDate: TransactionFinishDate || finalDate,
        companyId: companyId,
        cashierBankId: bankId,
        page: currentPage + 1,
        limit: pageLimit,
        planId: '',
        status: status,
        query: description,
        type: type,
      });

      setTotalPages(Math.ceil(getBills.count / pageLimit));
      setValue('dataFinancialRegister', getBills);
      return dataFinancialRegister;
    } catch (err) {
      toast.warning(
        'Ocorreu um erro ao carregar os títulos. Por favor, tente novamente'
      );
    } finally {
      setIsLoading(false);
    }
  }
  async function loadFinancialMovements() {
    try {
      const type = transactionType?.id === 0 ? '' : transactionType?.id;
      const status = transactionStatus?.id === 0 ? '' : transactionStatus?.id;

      const { initialDate, finalDate } =
        handleFinancialRegisterInicialDateChange();
      setIsLoading(true);
      const movements = await BankConciliationRepositoryV2.getMovements({
        companyId: companyId,
        cashierBankId: bankId,
        query: description,
        initialDate: TransactionInitialDate || initialDate,
        finalDate: TransactionFinishDate || finalDate,
        type: type,
        status: status,
        page: currentPage + 1,
        limit: pageLimit,
      });

      setTotalPages(Math.ceil(dataFinancialRegister.count / pageLimit));
      setValue('dataFinancialMovements', movements);
      setIsLoading(false);
      return dataFinancialRegister;
    } catch (err) {
      toast.warning(
        'Ocorreu um erro ao carregar os Movimentações/Transações. Por favor, tente novamente'
      );
    }
  }

  React.useEffect(() => {
    if (isViewing === true || isViewing === undefined) return;
    if (typeTable === 'titulo') {
      loadFinancialTitle();
    } else {
      loadFinancialMovements();
    }
  }, [
    description,
    TransactionInitialDate,
    TransactionFinishDate,
    currentPage,
    pageLimit,
    transactionType,
    transactionStatus,
  ]);

  const listSelectedTransactions = watch('selectedTransactions');
  const listSelectedConciliationTitles = watch(
    'selectedTransactionsConciliation'
  );

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const sumTransactions = (transactions) =>
    transactions?.reduce((sum, { amount }) => sum + parseFloat(amount || 0), 0);

  const sumTotalFinancialTitle = (transactions) =>
    transactions?.reduce(
      (sum, { total, value }) => sum + parseFloat(total || value || 0),
      0
    );

  const sumTransactionAmount = sumTransactions(listSelectedTransactions);
  const sumTitleTotal = sumTotalFinancialTitle(listSelectedConciliationTitles);
  const resultCalculationDifference =
    parseFloat(sumTransactionAmount) - sumTitleTotal;

  return (
    <BankConciliationFinancialRegisterContainer>
      <CollapsibleSection
        title="Registro Financeiro"
        isExpanded={isOpenCollapsibleSection}
        handleExpand={() =>
          setIsOpenCollapsibleSection(!isOpenCollapsibleSection)
        }
        css={{ width: '99%' }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BankConciliationDetailsSearch isFinancialRegister prefix={prefix} />
          {typeTable === 'titulo' ? (
            <div style={{ marginTop: '1.125rem', width: '100%' }}>
              <StyledTableContainer>
                <Table
                  columns={configColumnsFinancialRegister}
                  data={dataFinancialRegister?.data}
                  pageSize={pageLimit}
                  page={currentPage}
                  onPageChange={setCurrentPage}
                  manual
                  onPageSizeChange={setPageLimit}
                  pages={totalPages}
                  loading={isLoading}
                />
              </StyledTableContainer>
            </div>
          ) : (
            <div style={{ marginTop: '1.125rem', width: '100%' }}>
              <StyledTableContainer>
                <Table
                  columns={configColumnsFinancialMovements}
                  data={dataMovements?.data}
                  pageSize={pageLimit}
                  page={currentPage}
                  onPageChange={setCurrentPage}
                  manual
                  onPageSizeChange={setPageLimit}
                  pages={totalPages}
                  loading={isLoading}
                />
              </StyledTableContainer>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1rem',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <div>
              <span>
                {' '}
                <strong>R$ Transação:</strong>{' '}
                {formatCurrency(sumTransactionAmount || 0)}
              </span>
            </div>
            <div>
              <span>
                <strong>R$ Selecionado:</strong>{' '}
                {formatCurrency(sumTitleTotal || 0)}
              </span>
            </div>
            <div>
              <span
                style={{
                  color: resultCalculationDifference ? '#D9534F' : 'inherit',
                }}
              >
                <strong>R$ Diferença:</strong>{' '}
                {formatCurrency(resultCalculationDifference || 0)}
              </span>
            </div>
          </div>
        </div>
      </CollapsibleSection>
    </BankConciliationFinancialRegisterContainer>
  );
}
