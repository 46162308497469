import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import {
  handleChange,
  handleSubmitCashierBankModal,
  handleSubmitPreviousDayCashierBankModal,
} from './actions'
import OpenCashierBankHistoricModal from 'client/views/CashierBankHistoric/OpenCashierBankHistoricModal'
import CloseCashierBankHistoricModal from 'client/views/CashierBankHistoric/CloseCashierBankHistoricModal/CloseCashierBank'

const BlockSalesModals = ({ onSubmit, onCancel }) => {
  const {
    isNoCashierBankOpenedModalOpen,
    isPreviousDayCashierBankOpenedModalOpen,
    isOpenCashierBankHistoricModalOpen,
    isCloseCashierBankHistoricModalOpen,
    cashierBankOpened,
  } = useSelector((state) => state.blockSalesModalsReducer)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleCloseModalOpenCashierBankModal = (cashierBankHistoric) => {
    dispatch([
      handleChange(false, 'isOpenCashierBankHistoricModalOpen'),
      handleChange(cashierBankHistoric, 'cashierBankOpened'),
    ])
    onSubmit()
  }

  const handleSubmitCloseCashierBankHistoricModal = () => {
    dispatch([
      handleChange(false, 'isCloseCashierBankHistoricModalOpen'),
      handleChange(true, 'isOpenCashierBankHistoricModalOpen'),
    ])
  }

  return (
    <>
      <Modal show={isNoCashierBankOpenedModalOpen} animation>
        <Modal.Header>
          <Modal.Title>
            <strong>Não é possível iniciar a venda</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Não há nenhum caixa aberto para iniciar venda.</strong>
          <br />
          <br />
          Para iniciar as vendas você precisa abrir o caixa. Deseja realizar a
          abertura de caixa agora?
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              name="Não"
              onClick={() =>
                dispatch(handleChange(false, 'isNoCashierBankOpenedModalOpen'))
              }
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              name="Sim"
              fill
              onClick={() => dispatch(handleSubmitCashierBankModal())}
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isPreviousDayCashierBankOpenedModalOpen} animation>
        <Modal.Header>
          <Modal.Title>
            <strong>Não é possível iniciar a venda</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Existe um caixa em aberto do dia anterior.</strong>
          <br />
          <br />
          Você precisa realizar o fechamento do caixa em aberto para iniciar as
          vendas do dia. Deseja realizar o fechamento do caixa e a abertura do
          caixa de hoje?
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              name="Não"
              onClick={() => {
                if (onCancel) onCancel()
                dispatch(
                  handleChange(false, 'isPreviousDayCashierBankOpenedModalOpen')
                )
              }}
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              name="Sim"
              fill
              onClick={() =>
                dispatch(handleSubmitPreviousDayCashierBankModal())
              }
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {isOpenCashierBankHistoricModalOpen && (
        <OpenCashierBankHistoricModal
          onCancel={() =>
            dispatch(handleChange(false, 'isOpenCashierBankHistoricModalOpen'))
          }
          onSubmit={handleCloseModalOpenCashierBankModal}
        />
      )}

      {isCloseCashierBankHistoricModalOpen && (
        <CloseCashierBankHistoricModal
          onCancel={() =>
            dispatch(handleChange(false, 'isCloseCashierBankHistoricModalOpen'))
          }
          cashierBankHistoricId={cashierBankOpened?.id}
          onSubmit={handleSubmitCloseCashierBankHistoricModal}
        />
      )}
    </>
  )
}

export default BlockSalesModals
