import React, { useEffect, useState } from 'react'
import StoneCard from './StoneCard'
import Card from 'components/Card/Card.jsx'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import './styles.css'

import {
  faCalendarCheck,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons'
import WeeksAgendaCard from './WeeksAgendaCard'
import StoneTransactionsTable from './StoneTransactionsTable'
import stoneCardsRepository from '../../../../repositories/StoneCards'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'

const TransactionsStoneInfo = () => {
  const [cardData, setCardData] = useState([])

  const { companyId } = useAuth()

  useEffect(() => {
    if (companyId) getStoneCardsData()
  }, [])

  async function getStoneCardsData() {
    try {
      const data = await stoneCardsRepository.show(companyId)

      setCardData(data)
    } catch (err) {
      toastr.error(
        'Ocorreu ao carregar os dados do extrato. Por favor, tente novamente'
      )
    }
  }

  return (
    <Card
      content={
        <div>
          <div className='dashboard-container-stone'>
            <StoneCard
              title='Recebimento Total'
              value={currency(!!cardData.total ? cardData.total : 0)}
              icon={faCalendarCheck}
              color='#97c852 '
            />

            <StoneCard
              title='Recebimentos de Hoje'
              value={currency(
                !!cardData.todayReceives ? cardData.todayReceives : 0
              )}
              icon={faCalendarDay}
              color='#70c4bc '
            />

            <StoneCard
              title='Recebimento da Semana'
              value={currency(
                !!cardData.weekReceives ? cardData.weekReceives : 0
              )}
              icon={faCalendarWeek}
              color='#40b0a6 '
            />
            <StoneCard
              title='Recebimentos Futuros'
              value={currency(
                !!cardData.futureReceives ? cardData.futureReceives : 0
              )}
              icon={faCalendarAlt}
              color='#009688 '
            />
          </div>
          <div className='dashboard-bottom'>
            <WeeksAgendaCard />
            <StoneTransactionsTable />
          </div>
        </div>
      }
    />
  )
}

export default TransactionsStoneInfo
