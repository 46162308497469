import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'

import FormPosition from './FormPosition'
import positionsRepository from '../../../../../repositories/Positions'

import constants from '../../../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'

const initialValues = {
  performServices: false,
  sellProducts: true,
  isActive: true,
}

const BundleFormPosition = ({ positionId, modal, handleModal, hideModal }) => {
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (positionId) loadPosition(positionId)
  }, [positionId])

  const loadPosition = async id => {
    setLoading(true)
    try {
      const { data } = await positionsRepository.getById(id)
      dispatch(initialize('formPosition', data))
    } catch (err) {
      toastr.error(err.response?.data?.message || 'Ocorreu algum problema ao carregar o cargo.', 'Por favor, tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const submit = values => {
    if (!values.description) return toastr.warning('Preenche todos os campos obrigatórios para salvar o seu cadastro de cargo.')

    if (positionId) {
      return update(values)
    }
    create(values)
  }

  const create = async values => {
    setLoading(true)
    try {
      const response = await positionsRepository.create({ ...values, companyId })

      if(modal){
        const position = response.data.data;
        return handleModal(position.id)
      }

      history.push(constants.ROUTES.POSITIONS)
    } catch (err) {
      toastr.warning(err.response?.data?.message || 'Ocorreu um erro ao registrar Cargo. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const update = async values => {
    setLoading(true)
    try {
      await positionsRepository.update(values)
      history.push(constants.ROUTES.POSITIONS)
    } catch (err) {
      toastr.warning(err.response?.data?.message || 'Ocorreu um erro ao atualizar Cargo. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormPosition
      onSubmit={submit}
      initialValues={initialValues}
      loading={loading}
      positionId={positionId}
      modal={modal}
      hideModal={hideModal}
    />
  )
}

export default BundleFormPosition
