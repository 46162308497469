import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import AppError from 'components/AppError'

import { AppLoading } from 'client/routes/index.routes'

import ProductServiceReviewsReportPDF from './ProductServiceReviewsReportPDF'


import SalesRepository from '../../../repositories/Sales'

const ReturnDocumentPurchasesSupplier = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])
  const [review, setReview] = useState(false)
  const [warranty, setWarranty] = useState(false)

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])



  const loadQuery = async searchParams => {
    try {
      const ReturnListingResponseIdHash = searchParams.get('companyId')
      let companyId

      if (ReturnListingResponseIdHash) {
        companyId = await decrypt(ReturnListingResponseIdHash, '@OS-dig:companyId')
      }

      if (!companyId) {
        setHasError(true)
        setLoading(false)
      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      setWarranty(searchParams.get('warranty'))
      setReview(searchParams.get('review'))

      const dados = await loadReport(
        companyId,
        initialDate,
        finalDate,
        warranty
      )
      console.log(dados)
      setDataReport(dados)
      setLoading(false)

    } catch (err) {
      setHasError(true)
      setLoading(false)

    }

  }

  const loadReport = async (
    companyId,
    initialDate,
    finalDate
  ) => {
    try {
      const data = await SalesRepository.generateProductServiceReviews({
        companyId,
        initialDate,
        finalDate
      })

      return data.report

    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />


  return (

    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}>
          <ProductServiceReviewsReportPDF
            responseData={dataReport}
            review={review}
            warranty={warranty}
          />
        </PDFViewer>
      </main>
    </div>
  )

}

export default ReturnDocumentPurchasesSupplier 