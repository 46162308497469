import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FinancingStep = ({ icon, accomplished, denied }) => (
  <div
    id={
      accomplished
        ? 'div-step-financing-filled'
        : 'div-step-financing-not-filled'
    }
    className={`indexedStep ${accomplished ? 'accomplished' : null}`}
  >
    <FontAwesomeIcon
      style={{ height: 25, width: 25, marginTop: 2 }}
      icon={icon}
      className={`${
        accomplished
          ? `icon-step-financing-filled ${denied ? 'denied' : ''}`
          : 'icon-step-financing-not-filled'
      }`}
      aria-hidden="false"
    />
  </div>
)

export default FinancingStep
