import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { format, addDays } from 'date-fns'
import { Modal } from 'react-bootstrap'

import FormEmployee from './FormEmployee'
import FormUser from '../../../MyUsers/NewUser/BundleFormUsuario'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import SupportButton from '../../../../components/SupportButton'
import {
  validateCpf,
  validadeEmail,
} from '../../../../components/Validations/Validations'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'
import Button from 'client/components/CustomButton/CustomButton.jsx'

import employeesRepository from '../../../../../repositories/Employees'
import signaturesRepository from '../../../../../repositories/Signatures'
import companiesRepository from '../../../../../repositories/Companies'

import constants from '../../../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'

const initialValues = {
  isActive: true,
}

const BundleFormEmployee = ({ employeeId, modal, handleModal, hideModal }) => {
  const [loading, setLoading] = useState(false)
  const [isUserModalOpen, setIsUserModalOpen] = useState(false)
  const [isUserRegisterModalOpen, setIsUserRegisterModalOpen] = useState(false)
  const [isUserLimitReachedModalOpen, setIsUserLimitReachedModalOpen] =
    useState(false)

  const { companyId } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (employeeId) loadEmployee(employeeId)
  }, [employeeId])

  const loadEmployee = async (id) => {
    setLoading(true)
    try {
      const { data } = await employeesRepository.getById(id)

      const obj = {
        ...data,
        birthDate: data.birthDate
          ? format(addDays(new Date(data.birthDate), 1), 'yyyy-MM-dd')
          : null,
        admissionDate: data.admissionDate
          ? format(addDays(new Date(data.admissionDate), 1), 'yyyy-MM-dd')
          : null,
        resignationDate: data.resignationDate
          ? format(addDays(new Date(data.resignationDate), 1), 'yyyy-MM-dd')
          : null,
      }

      dispatch(initialize('formEmployee', obj))
    } catch (err) {
      toastr.error(
        err.response?.data?.message ||
          'Ocorreu algum problema ao carregar o funcionário.',
        'Por favor, tente novamente mais tarde.'
      )
    } finally {
      setLoading(false)
    }
  }

  const validations = (values) => {
    setLoading(true)

    if (!values.name || !values.cpf) {
      setLoading(false)
      return toastr.warning(
        'Preencha todos os campos obrigatórios para salvar o seu cadastro de funcionário.'
      )
    }

    if (!validateCpf(onlyNumbers(values.cpf))) {
      setLoading(false)
      return toastr.warning(
        'CPF inválido',
        'Preencha a informação corretamente'
      )
    }

    if (values.email) {
      if (!validadeEmail(values.email)) {
        setLoading(false)
        return toastr.warning(
          'E-mail inválido',
          'Preencha a informação corretamente'
        )
      }
    }

    if (values.birthDate) {
      if (!validateDates(values.birthDate)) {
        setLoading(false)
        return toastr.warning(
          'Data de nascimento inválida',
          'Preencha a informação corretamente'
        )
      }
    }

    if (values.admissionDate) {
      if (!validateDates(values.admissionDate)) {
        setLoading(false)
        return toastr.warning(
          'Data de admissão inválida',
          'Preencha a informação corretamente'
        )
      }
    }

    if (values.resignationDate) {
      if (!validateDates(values.resignationDate)) {
        setLoading(false)
        return toastr.warning(
          'Data de demissão inválida',
          'Preencha a informação corretamente'
        )
      }
    }

    return submit(values)
  }

  const submit = (values) => {
    if (employeeId) return update(values)

    create(values)
  }

  const create = async (values) => {
    setLoading(true)
    try {
      const object = buildObject(values)

      if (object.sendSMSIndicators && !object.isActive) {
        setLoading(false)
        return toastr.warning(
          'Não foi possível salvar',
          'A função de envio de SMS é possível apenas para cadastros ativos. Ative o cadastro e tente novamente.'
        )
      }

      const { data } = await employeesRepository.create(object)

      if (modal) {
        return handleModal(data.id)
      }

      if (values.email) {
        setIsUserModalOpen(true)
      } else {
        toastr.success('Funcionário cadastrado com sucesso.')
        history.push(constants.ROUTES.EMPLOYEES)
      }
    } catch (err) {
      console.log(err)
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao registrar funcionário. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const update = async (values) => {
    setLoading(true)
    const object = buildObject(values)

    if (object.sendSMSIndicators && !object.isActive) {
      setLoading(false)
      return toastr.warning(
        'Não foi possível salvar',
        'A função de envio de SMS é possível apenas para cadastros ativos. Ative o cadastro e tente novamente.'
      )
    }

    const objectToUpdate = {
      ...object,
      id: employeeId,
    }

    try {
      await employeesRepository.update(objectToUpdate)

      toastr.success('Funcionário atualizado com sucesso.')
      history.push(constants.ROUTES.EMPLOYEES)
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao atualizar funcionário. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const buildObject = (values) => {
    var object = {
      companyId: companyId,
      admissionDate: values.admissionDate,
      birthDate: values.birthDate,
      resignationDate: values.resignationDate,
      cellPhone: !!values.cellPhone ? onlyNumbers(values.cellPhone) : '',
      comissionRate: values.comissionRate,
      cpf: values.cpf ? onlyNumbers(values.cpf) : '',
      email: values.email,
      homePhone: !!values.homePhone ? onlyNumbers(values.homePhone) : '',
      isActive: values.isActive,
      sendSMSIndicators: values.sendSMSIndicators,
      name: values.name,
      motherName: values.motherName,
      observations: values.observations,
      positionId: values.positionId,
      rg: !!values.rg ? onlyNumbers(values.rg) : '',
    }

    if (values.Zipcode || values.Address) {
      object = {
        ...object,
        address: {
          zipcode: !!values.Zipcode ? onlyNumbers(values.Zipcode) : '',
          street: values.Address,
          streetNumber: values.Address_Number,
          complement: values.Complement,
          neighborhood: values.Neighborhood,
          city: values.City,
          state: values.State,
          referencePoint: values.Reference_Point,
        },
      }
    }

    return object
  }

  const validateDates = (date) => {
    const today = format(new Date(), 'yyyy-MM-dd')

    if (date <= today) {
      return true
    }

    return false
  }

  const userRegisterValidation = async () => {
    try {
      let signatureUserQuantity, activeUsers

      const signatures = await signaturesRepository.getByCompany(companyId)

      if (!!signatures.length) {
        signatureUserQuantity = signatures[signatures.length - 1].usersQuantity
      }

      const users = await companiesRepository.getAllUsers(companyId)
      const usersAtivo = users.filter((user) => user.IsActive)

      activeUsers = usersAtivo.length

      if (activeUsers === signatureUserQuantity) {
        setIsUserModalOpen(false)
        return setIsUserLimitReachedModalOpen(true)
      }

      return setIsUserRegisterModalOpen(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleUserRegisterSuccess = () => {
    toastr.success('Funcionário cadastrado com sucesso.')
    history.push(constants.ROUTES.EMPLOYEES)
  }

  const cancelUserRegister = () => {
    setIsUserModalOpen(false)
    toastr.success('Funcionário cadastrado com sucesso.')
    history.push(constants.ROUTES.EMPLOYEES)
  }

  const cancelSupportModal = () => {
    setIsUserLimitReachedModalOpen(false)
    toastr.success('Funcionário cadastrado com sucesso.')
    return history.push(constants.ROUTES.EMPLOYEES)
  }

  return (
    <>
      <FormEmployee
        modal={modal}
        hideModal={hideModal}
        onSubmit={validations}
        initialValues={initialValues}
        loading={loading}
        employeeId={employeeId}
      />

      {isUserModalOpen && (
        <AlertModal
          show={isUserModalOpen}
          onHide={() => {}}
          onCancel={() => cancelUserRegister()}
          message={`Deseja criar um usuário de acesso ao sistema para esse funcionário?`}
          onSubmit={userRegisterValidation}
        />
      )}

      {isUserRegisterModalOpen && (
        <Modal
          dialogClassName="modal-formProduto"
          show={isUserRegisterModalOpen}
          onHide={() => setIsUserRegisterModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Novo Usuário</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormUser
              modal
              handleModal={handleUserRegisterSuccess}
              hideModal={() => setIsUserRegisterModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {isUserLimitReachedModalOpen && (
        <Modal
          show={isUserLimitReachedModalOpen}
          onHide={() => setIsUserLimitReachedModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>OS Digital</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>O limite de usuários foi atingido</strong>
              <p style={{ paddingTop: 10, fontSize: 14 }}>
                Se deseja cadastrar o usuário, será necessário contratar mais
                usuários. Solicite junto ao atendimento a cliente clicando no
                botão abaixo:
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <Button
                  bsStyle="danger"
                  style={{ width: '100px', height: '35px', padding: '5px' }}
                  onClick={cancelSupportModal}
                  fill
                >
                  Voltar
                </Button>
              </div>
              <SupportButton />
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default BundleFormEmployee
