import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Field, change } from 'redux-form';
import Toggle from 'react-toggle';
import { useSelector, useDispatch } from 'react-redux';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import stockPermissionsRepository from '../../../../../repositories/StockPermissions';
import RenderField from 'components/RenderField';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';

export default function Sales() {
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const [stockPermissions, setStockPermissions] = useState([]);
  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isPlanPrime,
    isPlanWorkmotorFree,
    isPaidWorkmotor,
    isWorkmotor: isPlanWorkmotor,
  } = usePlanSignatureContext();
  const isPlanFreeOrStart = isPlanFree || isPlanStart;
  const isPlanWmFree = isPlanWorkmotorFree;
  const isPlanFreeStartOrEssencial = isPlanFree || isPlanStart || isPlanBasic;
  const dispatch = useDispatch();

  const {
    schedulingOnSale,
    sendSMSWhenFinished,
    boxPrismaOnSale,
    responsibleSeller,
    changePriceOnSale,
    showPurchasePriceOnSale,
    updateServiceDescriptionOnSale,
    allowOrderOfServiceWithoutVehicle,
    salesRepresentative,
    typeOfService,

    discountAboveLimit,
    saleItemApprovalCancellation,
  } = useSelector((state) => state.form.salesConfiguration.values);

  useEffect(() => {
    loadStockPermissions();
  }, []);

  const loadStockPermissions = async () => {
    try {
      const { data } = await stockPermissionsRepository.show();
      setStockPermissions(data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar as Permissões de Estoque. Por favor, tente novamente'
      );
    }
  };

  function handleSaleItemApprovalCancellation() {
    if (isPlanFreeOrStart || isPlanWmFree) {
      setIsOpenFeatureNotAvailableModal(true);
    } else {
      dispatch(
        change(
          'salesConfiguration',
          'saleItemApprovalCancellation',
          !saleItemApprovalCancellation
        )
      );
    }
  }

  return (
    <div className="sale-config__container flex column gap-050">
      <div className="flex row gap-050">
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="toggle-column"
          style={{ marginBottom: '10px' }}
        >
          <div>
            <label>Alterar preço na tela da venda</label>
            <FontAwesomeIcon
              title="Função que permite alterar preços na tela da venda."
              cursor="pointer"
              icon={faInfoCircle}
              style={{ height: '1.1em', width: '1.1em' }}
              color="#0088cc"
            />
          </div>
          <Toggle
            checked={changePriceOnSale}
            onChange={() =>
              dispatch(
                change(
                  'salesConfiguration',
                  'changePriceOnSale',
                  !changePriceOnSale
                )
              )
            }
          />
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
          <div>
            <label>Alterar descrição do serviço na venda</label>
          </div>
          <Toggle
            checked={updateServiceDescriptionOnSale}
            onChange={() =>
              dispatch(
                change(
                  'salesConfiguration',
                  'updateServiceDescriptionOnSale',
                  !updateServiceDescriptionOnSale
                )
              )
            }
          />
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
          <div>
            <label>Exibir preço de custo na venda</label>
          </div>
          <Toggle
            checked={showPurchasePriceOnSale}
            onChange={() =>
              dispatch(
                change(
                  'salesConfiguration',
                  'showPurchasePriceOnSale',
                  !showPurchasePriceOnSale
                )
              )
            }
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="toggle-column"
          style={{ marginLeft: '-30px' }}
        >
          <div>
            <label>Permitir OS sem veículo</label>
          </div>

          <Toggle
            checked={allowOrderOfServiceWithoutVehicle}
            onChange={() =>
              dispatch(
                change(
                  'salesConfiguration',
                  'allowOrderOfServiceWithoutVehicle',
                  !allowOrderOfServiceWithoutVehicle
                )
              )
            }
          />
        </Col>
      </div>
      <div className="flex row gap-075">
        {!isPlanFree && (
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={2}
            style={{
              position: 'relative',
              width: 'fit-content',
            }}
          >
            <Field
              name="lastSaleCode"
              component={RenderField}
              label="Número da Última Venda/OS"
              style={{
                marginLeft: '15px',
                width: '100px',
                alignItems: 'center',
              }}
              disabled={isPlanFree}
              normalize={onlyNumbers}
            />
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <>
            <Field
              name="saleWithZeroStockPermissionId"
              component={RenderField}
              label="Venda com Estoq. Zerado:"
              as="select"
              disabled={isPlanFree || isPlanStart}
            >
              {stockPermissions.map((stockPermission) => (
                <option value={stockPermission.id} key={stockPermission.id}>
                  {stockPermission.description}
                </option>
              ))}
            </Field>
            <>
              <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
                <div>
                  <label>Representante na Venda</label>
                </div>

                <Toggle
                  checked={salesRepresentative}
                  onChange={() =>
                    dispatch(
                      change(
                        'salesConfiguration',
                        'salesRepresentative',
                        !salesRepresentative
                      )
                    )
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
                <div>
                  <label>Tipo de Atendimento</label>
                </div>

                <Toggle
                  checked={typeOfService}
                  onChange={() =>
                    dispatch(
                      change(
                        'salesConfiguration',
                        'typeOfService',
                        !typeOfService
                      )
                    )
                  }
                />
              </Col>
            </>

            <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
              <div>
                <label>Enviar SMS ao finalizar OS</label>
              </div>
              <>
                <Toggle
                  checked={sendSMSWhenFinished}
                  onChange={() =>
                    dispatch(
                      change(
                        'salesConfiguration',
                        'sendSMSWhenFinished',
                        !sendSMSWhenFinished
                      )
                    )
                  }
                />
              </>
            </Col>
          </>
        )}
      </div>

      <div className="flex row gap-050" style={{ paddingBottom: '40px' }}>
        {!isPlanFreeOrStart && (
          <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
            <div>
              <label>Box/Prisma na Tela de Venda</label>
            </div>
            <>
              <Toggle
                checked={boxPrismaOnSale}
                onChange={() =>
                  dispatch(
                    change(
                      'salesConfiguration',
                      'boxPrismaOnSale',
                      !boxPrismaOnSale
                    )
                  )
                }
              />
            </>
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col className="toggle-column">
            <div>
              <label>Vendedor Responsável</label>
            </div>
            <>
              <Toggle
                checked={responsibleSeller}
                onChange={() =>
                  dispatch(
                    change(
                      'salesConfiguration',
                      'responsibleSeller',
                      !responsibleSeller
                    )
                  )
                }
              />
            </>
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
            <div>
              <label>Aprovação/Cancelamento de Itens</label>
            </div>

            <Toggle
              checked={saleItemApprovalCancellation}
              onChange={() => handleSaleItemApprovalCancellation()}
            />
          </Col>
        )}
        {!isPlanFreeOrStart && (
          <Col xs={12} sm={12} md={2} lg={2} style={{ width: '250px' }}>
            <label style={{ margin: 0 }}>Desconto acima do limite (item)</label>
            <select
              className="form-control foco-input"
              onChange={(e) => {
                dispatch(
                  change(
                    'salesConfiguration',
                    'discountAboveLimit',
                    e.target.value
                  )
                );
              }}
              value={discountAboveLimit}
            >
              <option value="NOT_ALLOWED">Bloquear</option>
              <option value="PASSWORD_REQUIRED">Permitir c/ senha</option>
            </select>
          </Col>
        )}

        {(!!isPaidWorkmotor || !!isPlanPrime) && (
          <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
            <div>
              <label>Agendamento na tela da venda</label>
              <FontAwesomeIcon
                title="O agendamento ocorrerá apenas quando o Orçamento/OS tiver veículo atrelado."
                cursor="pointer"
                icon={faInfoCircle}
                style={{ height: '1.1em', width: '1.1em' }}
                color="#0088cc"
              />
            </div>
            <Toggle
              checked={schedulingOnSale}
              onChange={() =>
                dispatch(
                  change(
                    'salesConfiguration',
                    'schedulingOnSale',
                    !schedulingOnSale
                  )
                )
              }
            />
          </Col>
        )}
      </div>

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
    </div>
  );
}
