import { faEdit, faPrint, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertModal from 'components/AlertModal/AlertModal';
import { useAuth } from 'contexts/auth';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import quotationsRepository from '../../../../repositories/Quotations';
import './styles.css'

import { useHistory } from 'react-router';

const QuotationsTableAction = ({ data, reloadQuotations }) => {

  const [cancelModal, setCancelModal] = useState(false);
  const { saleReducer } = useSelector((state) => state)
  const { loadingSpin } = saleReducer

  const history = useHistory();

  const { companyId } = useAuth();

  const checkStatus = () => {
    if (data.status === 'Respondida' || data.status === 'Cancelada') {
      return true
    }
    return false
  }

  const handleCancelClick = () => {
    setCancelModal(true)
  }

  const handleCancelModalConfirmation = async (id) => {
    try {
      await quotationsRepository.updateStatus(id, companyId);
    } catch (e) {
      console.log(e)
      return toastr.warning('Não foi possível atualizar o status para cancelado. Por favor, tente novamente mais tarde');
    } finally {
      setCancelModal(false)
      reloadQuotations()
    }
  }

  return (
    <>
      <div className='sales-table-actions-wrapper'>
        <FontAwesomeIcon
          icon={faEdit}
          title='Editar'
          color='black'
          style={{
            height: '1.6em',
            width: '1.6em',
            marginBottom: -1,
          }}
          onClick={() => history.push('/client/quotation#' + data.id)}
        />

        {checkStatus() && (
          <FontAwesomeIcon
            icon={faPrint}
            title='Imprimir'
            color='black'
            onClick={() => window.open(window.location.origin + '/report/general-quotation-report?id=' + data.id, '_blank')}
            style={{
              height: '1.6em',
              width: '1.6em',
              marginBottom: -1,
            }}
          />
        )}

        {!checkStatus() && (
          <FontAwesomeIcon
            icon={faWindowClose}
            title='Cancelar'
            color='red'
            onClick={handleCancelClick}
            style={{
              height: '1.6em',
              width: '1.6em',
              marginBottom: -1,
            }}
          />
        )}
      </div>

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          loading={loadingSpin}
          subtitle={'Você tem certeza que deseja cancelar a cotação?'}
          message={'O cancelamento é irreversível. Ao cancelar a criação, a cotação não será criada e nem disparada para o fornecedor, sendo necessário a criação de uma nova.'}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          onSubmit={() => handleCancelModalConfirmation(data.id)}
        />
      )}
    </>

  )
};

export default QuotationsTableAction;