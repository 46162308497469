import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import 'react-tabs/style/react-tabs.css'
import BundleFormPurchases from '../NewPurchase/FormNewPurchase/BundleFormPurchases'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'

function NewPurchase({ match }) {
  localStorage.setItem('selectedPath', 'client/purchases')

  return (
    <div className="content" style={{ marginBottom: '55px' }}>
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              'Compras',
              !!match.params.id ? 'Editar Compra' : 'Nova Compra',
            ]}
            path={['home', 'products', 'purchases', null]}
          />
          <BundleFormPurchases titleId={match.params.id} />
        </Row>
      </Grid>
    </div>
  )
}

export default NewPurchase
