import React from 'react'
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, percentage } from 'client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

const Header = ({ initialDate, finalDate, typeOfDate, origin }) => (
  <View>
    <View>
      <Text style={styles.title}>Valores por Status</Text>
    </View>

    <View style={[styles.line, { marginTop: '5px' }]} />

    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px', paddingBottom: '5px' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Origem:
        </Text>
        <Text style={[styles.text]}>
          {origin === 'billsToReceive' ? 'Contas a Receber' : 'Contas a Pagar'}
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '6px' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Tipo de Data:
        </Text>
        <Text style={[styles.text]}>
          {typeOfDate === 'creation' ? 'Criação' : 'Pagamento'}
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '6px' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Data Início:
        </Text>
        <Text style={[styles.text]}>
          {initialDate}
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '6px' }}>
        <Text
          style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
        >
          Data Fim:
        </Text>
        <Text style={[styles.text]}>
          {finalDate}
        </Text>
      </View>
    </View>
  </View>
)

const BillsByStatusHeader = ({ billDescription, origin }) => {
  return (
    <View>
      <View style={[styles.line, { marginTop: '0px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', fontSize: '14px' }]}>
          {billDescription}
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '20%' }]}>
          Vencimento
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Título</Text>
        {
          origin === 'billsToReceive' &&
          <Text style={[styles.boldText, { width: '15%' }]}>Venda</Text>
        }
        <Text style={[styles.boldText, { width: '20%' }]}>{origin === 'billsToReceive' ? 'Cliente' : 'Fornecedor'}</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor</Text>
        {
          origin === 'billsToReceive' &&
          <Text style={[styles.boldText, { width: '10%' }]}>Juros</Text>
        }
        <Text style={[styles.boldText, { width: '10%' }]}>Acréscimo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Descontos</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Total</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>{billDescription === 'Em aberto (Vencido)' || billDescription === 'Em aberto (No Prazo)' ? 'Criação' : billDescription === 'Cancelado' ? 'Cancelamento' : 'Pagamento'}</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const BillsByStatusRow = ({ item, origin, billDescription }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={{ width: '20%' }}>
          <Text style={styles.text}>{billDescription === 'Em aberto (Vencido)' ? `${format(getDateOnlyFromDate(new Date(item.dueDate)), 'dd/MM/yyyy')} (${item.daysDifference > 0 ? `+${item.daysDifference}` : item.daysDifference} dias)` : billDescription === 'Em aberto (No Prazo)' ? `${format(getDateOnlyFromDate(new Date(item.dueDate)), 'dd/MM/yyyy')} (${item.daysDifference > 0 ? `-${item.daysDifference}` : item.daysDifference} dias)` : format(getDateOnlyFromDate(new Date(item.dueDate)), 'dd/MM/yyyy')}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{item.code}</Text>
        </View>
        {
          origin === 'billsToReceive' &&
          <View style={{ width: '15%' }}>
            <Text style={styles.text}>{item.sales && item.sales.map(item => item.saleCode).toString()}</Text>
          </View>
        }
        <View style={{ width: '20%' }}>
          <Text style={styles.text}>{item.customer || item.provider}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.subTotal)}</Text>
        </View>
        {
          origin === 'billsToReceive' &&
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>{currency(item.feeValue)}</Text>
          </View>
        }
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.addedValue)}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.discountValue)}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.liquidValue)}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{billDescription === 'Em aberto (Vencido)' || billDescription === 'Em aberto (No Prazo)' ? format(new Date(item.createdAt), 'dd/MM/yyyy') : billDescription === 'Cancelado' ? format(new Date(item.updatedAt), 'dd/MM/yyyy') : format(new Date(item.paymentDate), 'dd/MM/yyyy')}</Text>
        </View>
      </View>
    </View>
  )
}

const BillsByStatusTotal = ({ data, origin }) => {
  return (
    <View style={styles.containerRow}>
      <Text style={[styles.boldText, { width: origin === 'billsToReceive' ? '84%' : '70%' }]}>Total</Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(data)}
      </Text>
    </View>
  )
}

const BillsByStatus = ({ data, origin }) => {
  return data.map(element => {
    return (
      <>
        <BillsByStatusHeader billDescription={element.billStatusDescription} origin={origin} />

        {element.rows.map((item, index) => (
          <BillsByStatusRow key={index} item={item} origin={origin} billDescription={element.billStatusDescription} />
        ))}

        <View style={[styles.line, { marginTop: '2px', marginBottom: '2px' }]} />

        <BillsByStatusTotal data={element.total} origin={origin} />

        <View style={[styles.line, { marginTop: '2px' }]} />
      </>
    )
  })
}

const ValuesByStatusPDF = ({
  result,
  initialDate,
  finalDate,
  typeOfDate,
  origin
}) => {
  const billsByStatus = result.billsByStatus

  return (
    <Document
      title={`Valores-por-Status${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="valores-por-status"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <Header initialDate={initialDate} finalDate={finalDate} typeOfDate={typeOfDate} origin={origin} />

        <BillsByStatus
          data={billsByStatus} origin={origin}
        />
      </Page>
    </Document>
  )
}

export default ValuesByStatusPDF

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
})
