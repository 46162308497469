import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import Carousel from 'react-elastic-carousel'

let MyCarousel = ({ images }) => (
  <Carousel itemsToShow={1} className="modal-add-items-carousel" enableMouseSwipe >
    {images.map(img => (
      <img src={img.link} alt={img.name} />
    ))}
  </Carousel>
)

MyCarousel = memo(MyCarousel)

export default function ImagesModal({ productDescription = '', productPhotos = [], onHide, onCancel }) {
  return (
    <Modal
      dialogClassName="modal-60w"
      show
      animation
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>{productDescription}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MyCarousel images={productPhotos} />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={onCancel} fill>Voltar</Button>
      </Modal.Footer>
    </Modal>
  )
}