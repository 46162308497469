export const xlsColumns = [
  {
    name: 'Descrição',
    acessor: 'description'
  },
  {
    name: 'Status',
    acessor: 'status'
  }
]

export const getPrismaBoxInfoToExport = prismabox => {
  return prismabox.map(pb => (
    (pb.status === true ? pb.status = 'Ativo' : pb.status = 'Inativo'),
    {
    id: pb.id,
    description: pb.description,
    observation: pb.observation,
    status: pb.status,
  }))
}