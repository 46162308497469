import React from "react";
import { Grid, Row } from "react-bootstrap";
import FormMeuPlano from './MyPlan'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const HomeMyPlan = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Meu Plano']}
            path={['home', 'MeuPlano']}
          />
          <div className="quebralinha">
            <FormMeuPlano />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default HomeMyPlan
