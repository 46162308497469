import React, { useEffect, useState, useMemo } from 'react';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import CollapsibleSection from 'components/CollapsibleSection';
import { useAuth } from 'contexts/auth';

import { DefaultContentContainer } from 'v2/components/layout';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputTextArea } from 'v2/components/Input';

import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { useRouter } from 'v2/hooks/useRouter';

import { defaultFormValues, formSchema, getBreadcrumbData } from './utils';

import { FormContainer } from './FormCSTConversor.styles';
import { InputSelectDefault } from 'v2/components/Input';
import {
  createCSTConversor,
  updateCSTConversor,
  getCSTConversor,
} from 'v2/repositories/CSTConversorRepository';
import { PRODUCT_CST, PRODUCT_CSOSN } from 'utils/constants';
import natureOfTransactionsRepository from 'repositories/NatureOfTransactions';

export function FormCSTConversor({
  hideBreadCrumb,
  hideActionButtons,
  CSTtoCSOSNIdProp,
  onSubmitSuccess,
  onSubmitFail,
  triggerSubmit,
}) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { id } = useParams();
  const CSTtoCSOSNIdValue = useMemo(() => {
    return CSTtoCSOSNIdProp || id;
  }, [CSTtoCSOSNIdProp, id]);

  const isEditing = !!CSTtoCSOSNIdValue;

  const [isLoading, setIsLoading] = useState(false);
  const [originalCFOPs, setOriginalCFOPs] = useState([]);
  const [originCST, setOriginCST] = useState(null);
  const [originCFOP, setOriginCFOP] = useState(null);
  const [convertedCFOP, setConvertedCFOP] = useState(null);
  const [convertedCSOSN, setConvertedCSOSN] = useState(null);
  const [optionsCFOP, setOptionsCFOP] = useState([]);
  const [optionsCST, setOptionsCST] = useState([]);
  const [optionsCSOSN, setOptionsCSOSN] = useState([]);
  const [observations, setObservations] = useState('');

  const handleOriginCFOP = (e) => {
    const CFOP = optionsCFOP.find((item) => item.value === e.value);
    setOriginCFOP(CFOP);
  };

  const handleConvertedCFOP = (e) => {
    const CFOP = optionsCFOP.find((item) => item.value === e.value);
    setConvertedCFOP(CFOP);
  };

  async function loadCFOPs() {
    try {
      const natureOftransactions =
        await natureOfTransactionsRepository.getAllByCompanyAndDefaults(
          companyId
        );
      return natureOftransactions;
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as CFOPs. Por favor, tente novamente'
      );
    }
  }

  const loadSelects = async () => {
    const cfops = await loadCFOPs();
    setOriginalCFOPs(cfops);
    setOptionsCFOP(handleSerializeSelects(cfops, true));
    setOptionsCST(handleSerializeSelects(PRODUCT_CST));
    setOptionsCSOSN(handleSerializeSelects(PRODUCT_CSOSN));
  };

  const handleSerializeSelects = (data, isCFOP) => {
    if (isCFOP) {
      return data.map((CFOP) => ({
        label: `${CFOP.CFOP} - ${CFOP.description}`,
        value: CFOP.id,
      }));
    }
    return data.map((item) => ({
      label: item,
      value: item,
    }));
  };

  const getValues = () => {
    return {
      originCST: originCST.value,
      originCFOP: originCFOP.value,
      convertedCFOP: convertedCFOP.value,
      convertedCSOSN: convertedCSOSN.value,
      observations,
    };
  };

  const handleCreate = async () => {
    const params = await getValues();
    try {
      await createCSTConversor({ ...params, companyId });
      toastr.success('Cadastro realizado', ' com sucesso');
      navigateToClientPage('cst_conversor', 'base');
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        return toastr.warning(
          err.response.data.message.title,
          err.response.data.message.text
        );
      }
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    const params = await getValues();
    try {
      await updateCSTConversor(id, { ...params, companyId });
      toastr.success('Edição realizada', 'Cadastro alterado com sucesso');
      navigateToClientPage('cst_conversor', 'base');
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        return toastr.warning(
          err.response.data.message.title,
          err.response.data.message.text
        );
      }
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!handleValidate()) {
      setIsLoading(false);
      return;
    }
    try {
      isEditing ? handleUpdate() : handleCreate();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidate = () => {
    if (!originCST || !originCFOP || !convertedCFOP || !convertedCSOSN) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios '
      );
      return false;
    }
    return true;
  };

  const handleLoadFields = async () => {
    setIsLoading(true);
    try {
      const res = await getCSTConversor(CSTtoCSOSNIdValue, companyId);
      if (!res) throw 'not found';

      setOriginCST({ label: res.originCST, value: res.originCST });
      setOriginCFOP(
        optionsCFOP.find(
          (item) => item.value === res.originNatureOfTransaction.id
        )
      );
      setConvertedCFOP(
        optionsCFOP.find(
          (item) => item.value === res.convertedNatureOfTransaction.id
        )
      );
      setConvertedCSOSN({
        label: res.convertedCSOSN,
        value: res.convertedCSOSN,
      });
      setObservations(res.observations);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para edição',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
      navigateToClientPage('cst_conversor', 'base');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (CSTtoCSOSNIdValue && companyId && optionsCFOP?.length > 0) {
      handleLoadFields();
    }
  }, [CSTtoCSOSNIdValue, optionsCFOP]);

  useEffect(() => {
    loadSelects();
  }, [companyId]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel={!hideBreadCrumb}>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados para conversão"
          isExpanded={true}
          handleExpand={() => null}
        >
          <FormContainer>
            <div className="row-1">
              <InputContainer variant="column">
                <InputLabel required>CST da NF:</InputLabel>
                <InputSelectDefault
                  height={'35px'}
                  options={optionsCST}
                  value={originCST}
                  onChange={(value) => setOriginCST(value)}
                />
              </InputContainer>
              <InputContainer variant="column">
                <InputLabel required>CFOP da NF:</InputLabel>
                <InputSelectDefault
                  width={'400px'}
                  height={'35px'}
                  options={optionsCFOP}
                  value={originCFOP}
                  onChange={(value) => handleOriginCFOP(value)}
                />
              </InputContainer>
              <InputContainer variant="column">
                <InputLabel required>CFOP Convertido:</InputLabel>
                <InputSelectDefault
                  width={'400px'}
                  height={'35px'}
                  options={optionsCFOP}
                  value={convertedCFOP}
                  onChange={(value) => handleConvertedCFOP(value)}
                />
              </InputContainer>
              <InputContainer variant="column">
                <InputLabel required>CSOSN Convertido:</InputLabel>
                <InputSelectDefault
                  height={'35px'}
                  options={optionsCSOSN}
                  value={convertedCSOSN}
                  onChange={(value) => setConvertedCSOSN(value)}
                />
              </InputContainer>
            </div>

            <div className="row-2 mt-1">
              <InputContainer className="input__container">
                <InputLabel htmlFor="input__observations">
                  Observações:
                </InputLabel>
                <InputTextArea
                  id="input__observations"
                  rows={4}
                  maxLength={150}
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                />
              </InputContainer>
            </div>
          </FormContainer>
        </CollapsibleSection>
      </div>
      {!hideActionButtons && (
        <div className="flex align-center end gap-075 mt-2">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={() => navigateToClientPage('cst_conversor', 'base')}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            type="submit"
            style={{ width: '100px' }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      )}
    </DefaultContentContainer>
  );
}
