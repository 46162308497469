import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

import DownloadXlsButton from 'components/DownloadXlsButton';

import brandsRepository from '../../../../repositories/Brands';
import familiesRepository from '../../../../repositories/Families';
import productsRepository from '../../../../repositories/Products';

import { format } from 'date-fns';
import useFilters from '../../../../hooks/useFilters';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { percentage } from 'client/components/ToNormalize/ToNormalize';

import './styles.css';

const StockList = () => {
  const [products, setProducts] = useState([]);
  const [productsToExport, setProductsToExport] = useState([]);
  const [brands, setBrands] = useState([]);
  const [families, setFamilies] = useState([]);
  const [stockValuePurchases, setStockValuePurchases] = useState(0);
  const [stockValueSales, setStockValueSales] = useState(0);

  const [brandId, setBrandId] = useState('');
  const [familyId, setFamilyId] = useState('');
  const [noStock, setNoStock] = useState(true);
  const [underStock, setUnderStock] = useState(true);
  const [productType, setProductType] = useState('');
  const [productionType, setProductionType] = useState('');

  const [loading, setLoading] = useState(false);

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  const companyId = localStorage.getItem('ID_EMPRESA');
  const tableColumns = [
    {
      Header: 'Código',
      name: 'Código Interno',
      accessor: 'Code',
      acessor: 'Code',
      width: 55,
    },
    {
      Header: 'Ref. Fabricante',
      name: 'Ref. Fabricante',
      accessor: 'Manufacturer_Ref',
      acessor: 'Manufacturer_Ref',
    },
    {
      Header: 'Descrição',
      name: 'Descrição',
      accessor: 'Description',
      acessor: 'Description',
      width: 160,
    },
    {
      Header: 'Tp.Produto',
      name: 'Tipo de Produto',
      accessor: 'product_type',
      acessor: 'product_type',
    },
    {
      Header: 'Família',
      name: 'Família',
      accessor: 'familyDescription',
      acessor: 'familyDescription',
    },
    {
      Header: 'Marca',
      name: 'Marca',
      accessor: 'brandDescription',
      acessor: 'brandDescription',
      width: 115,
    },
    {
      Header: 'NCM',
      name: 'NCM',
      accessor: 'NCM_Code',
      acessor: 'NCM_Code',
    },
    {
      Header: 'Qtd',
      name: 'Qtd',
      accessor: 'Stock_Quantity',
      acessor: 'Stock_Quantity',
      width: 77,
    },
    {
      Header: 'R$ Compra',
      name: 'Preço de Compra',
      accessor: 'Purchase_Price',
      acessor: 'Purchase_Price',
      width: 77,
    },
    {
      Header: 'Margem',
      name: 'Margem',
      accessor: 'Profit_Margin',
      acessor: 'Profit_Margin',
    },
    {
      Header: 'R$ Venda',
      name: 'Preço de Venda',
      accessor: 'Sale_Price',
      acessor: 'Sale_Price',
    },
    {
      Header: 'Produção',
      name: 'Produção',
      accessor: 'production',
      acessor: 'production',
    },
  ];

  useEffect(() => {
    if (!!companyId) {
      loadBrands();
      loadFamilies();
      setLoading(false);
    }
  }, []);

  const loadProducts = async (state) => {
    setLoading(true);

    try {
      const products = await productsRepository.getAll({
        Company_id: companyId,
        Brand_id: brandId,
        product_type: productType,
        familyId,
        noStock,
        underStock,
        production: productionType,
        IsActive: true,
      });

      const serializedProducts = products.map(
        ({ Brands, Families, ...rest }) => ({
          ...rest,
          Sale_Price: currency(rest.Sale_Price || 0),
          Profit_Margin: percentage(rest.Profit_Margin || 0),
          Purchase_Price: currency(rest.Purchase_Price || 0),
          familyDescription: Families?.Description,
          brandDescription: Brands?.Description,
          PurchasePrice: rest.Purchase_Price || 0,
        })
      );

      const serializedProductsToExport = products.map(
        ({ Brands, Families, ...rest }) => ({
          ...rest,
          Sale_Price: rest.Sale_Price || 0,
          Profit_Margin: rest.Profit_Margin / 100 || 0,
          Purchase_Price: rest.Purchase_Price || 0,
          familyDescription: Families?.Description,
          brandDescription: Brands?.Description,
          PurchasePrice: rest.Purchase_Price || 0,
        })
      );

      setProducts(serializedProducts);
      setProductsToExport(serializedProductsToExport);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadFamilies = async () => {
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        );
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        Description: family.Description,
      }));
      setFamilies(serializedFamilies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      );
    }
  };

  const loadBrands = async () => {
    setLoading(true);
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        Description: brand.Description,
      }));

      setBrands(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      );
    }
  };

  function handleFilters(product) {
    const querySearch = [
      product['Code'],
      product['Description'],
      product['Manufacturer_Ref'],
      product['Bar_Code'],
      product['NCM_Code'],
    ];

    return filterByQuery(querySearch);
  }

  useEffect(() => {
    const totalPurchases = products.reduce((acc, product) => {
      if (product.Stock_Quantity > 0) {
        const purchaseTotal = product.Stock_Quantity * product.PurchasePrice;
        return acc + purchaseTotal;
      }
      return acc;
    }, 0);

    const formattedTotalPurchases = parseFloat(totalPurchases.toFixed(2));

    const totalSales = products.reduce((acc, product) => {
      if (product.Stock_Quantity > 0) {
        const salePriceString = product.Sale_Price.replace('R$', '')
          .replace(/\./g, '')
          .replace(',', '.')
          .trim();
        const salePriceNumeric = Number(salePriceString);
        const saleTotal = product.Stock_Quantity * salePriceNumeric;
        return acc + saleTotal;
      }
      return acc;
    }, 0);

    const formattedTotalSales = Number(totalSales.toFixed(2));

    setStockValuePurchases(formattedTotalPurchases);
    setStockValueSales(formattedTotalSales);
  }, [products]);

  return (
    <div className="main-control-list-current_inventory">
      <section>
        <div className="line-current_inventory-one-control">
          <div className="line-label-current_inventory-size-one">
            <strong className="line-control-strong-label_one">Família:</strong>
            <select
              className="form-control foco-input select-families"
              value={familyId}
              onChange={(e) => setFamilyId(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value="">Selecione</option>
              {families.map((family) => (
                <option key={family.id} value={family.id}>
                  {family.Description}
                </option>
              ))}
            </select>
          </div>

          <div className="line-label-current_inventory-size-one">
            <strong className="line-control-strong-label_one">Marca: </strong>

            <select
              className="form-control foco-input select-brand"
              value={brandId}
              onChange={(e) => setBrandId(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value="">Selecione</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.Description}
                </option>
              ))}
            </select>
          </div>

          <div style={{ width: '120px' }}>
            <strong className="line-control-strong-label_one">
              Estoque zerado?{' '}
            </strong>

            <select
              className="form-control foco-input"
              value={noStock}
              onChange={(e) => setNoStock(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value={true}>Não</option>
              <option value="">Sim</option>
            </select>
          </div>

          <div style={{ width: '120px' }}>
            <strong className="line-control-strong-label_one">
              Estoque Negativo?{' '}
            </strong>

            <select
              className="form-control foco-input "
              value={underStock}
              onChange={(e) => setUnderStock(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value={true}>Não</option>
              <option value="">Sim</option>
            </select>
          </div>

          <div className="line-label-current_inventory-size-one">
            <strong className="line-control-strong-label_one">
              Tipo de Produto:{' '}
            </strong>
            <select
              className="form-control foco-input "
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value={''}>Todos</option>
              <option value={'Produto Final'}>Produto Final</option>
              <option value={'Uso e Consumo'}>Uso e Consumo</option>
              <option value={'Matéria Prima'}>Matéria Prima</option>
            </select>
          </div>

          <div style={{ width: '110px' }}>
            <strong className="line-control-strong-label_one">
              Produção:{' '}
            </strong>
            <select
              className="form-control foco-input "
              value={productionType}
              onChange={(e) => setProductionType(e.target.value)}
              style={{ height: '35px' }}
            >
              <option value={''}>Ambos</option>
              <option value="Terceiros">Terceiros</option>
              <option value="Própria">Própria</option>
            </select>
          </div>
        </div>

        <div className="line-current_inventory-two-control">
          <div
            style={{
              width: '100%',
              marginTop: 15,
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            <div style={{ width: '500px' }} id="review-search">
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{
                  paddingLeft: '30px',
                  width: '100%',
                  height: '35px',
                  fontSize: '13px',
                }}
                className="form-control foco-input"
                placeholder="Pesquisa por Descrição, Código, Ref. Fabricante, Código de barras ou NCM"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            <div>
              <button
                className="btn btn-sucesso"
                disabled={loading}
                onClick={loadProducts}
                style={{ height: '35px' }}
              >
                <span
                  className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                />
                Processar
              </button>
            </div>

            <div>
              <DownloadXlsButton
                archiveName={`listagem-de-estoque-atual${format(
                  new Date(),
                  'dd/MM/yyyy'
                )}`}
                data={productsToExport.filter(handleFilters)}
                className="btn btn-export"
                disabled={loading || !productsToExport.length}
                columns={tableColumns}
                style={{ height: '35px' }}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
        </div>
      </section>

      <main>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '11px',
          }}
          data={products.filter(handleFilters)}
          columns={tableColumns}
          defaultPageSize={10}
          loading={loading}
          showPagination
          sortable
          showPaginationBottom
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'Description',
              desc: false,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando itens..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '14px',
              margin: '10px 0px 10px 0px',
            }}
          >
            <span style={{ color: '#5cb85c' }}>
              Valor de estoque atual (R$ Compra):{' '}
              {currency(stockValuePurchases)}
            </span>
            <span style={{ color: '#444444' }}>
              Valor total de vendas (R$ Vendas): {currency(stockValueSales)}
            </span>
          </div>
        </div>
      </main>
    </div>
  );
};

export default StockList;
