import React from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusIncompleteCustomerModal = ({
  isOpen,
  handleClose,
  handleContinueWithoutBonus,
  handleProceedIncompleteCustomer,
}) => {
  const continueWithoutBonus = () => {
    handleContinueWithoutBonus();
    handleClose();
  };

  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          O Cliente informado foi localizado, mas o cadastro está incompleto
          para lançar o bônus. <br />
          <br />
          <strong>Deseja atualizar o cadastro do cliente? </strong>
          <br />
          <br />
          <span style={{ color: '#d43f3a', fontSize: '12px' }}>
            Caso o cliente mude de ideia após esse passo, será necessário voltar
            e iniciar o fechamento novamente
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button
            className="button button-red w-150px"
            onClick={continueWithoutBonus}
          >
            Continuar sem Bônus
          </button>
          <button
            className="button button-green w-150px"
            onClick={handleProceedIncompleteCustomer}
          >
            Atualizar Cadastro
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
