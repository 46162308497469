import api from 'v2/services/api';

export const getDepartment = async (departmentId, companyId) => {
  const department = 'department/' + departmentId;

  try {
    const res = await api.get(department, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listDepartment = async (params) => {
  try {
    const res = await api.get('department', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createDepartment = async (data) => {
  try {
    const res = await api.post('department', data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateDepartment = async (id, data) => {
  try {
    const res = await api.put('department/' + id, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteDepartment = async (id) => {
  try {
    await api.delete('department/' + id);
  } catch (err) {
    throw err;
  }
};
