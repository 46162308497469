const { default: axios } = require('axios');

const brasilApi = axios.create({
  baseURL: 'https://brasilapi.com.br/api/',
});

const consultCnpj = async (cnpj) => {
  return await brasilApi.get(`cnpj/v1/${cnpj}`);
};

export const BrasilAPIService = {
  consultCnpj,
};
