import React from 'react';
import { BankConciliationHeaderCard } from './BankConciliationHeaderCard';
import {
  HeaderBankConciliationDetailsContainer,
  HeaderBankConciliationDetailsNavLink,
} from '../../BankConciliationDetails.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { useFormContext } from 'react-hook-form';
import { generateBankConciliationDetails } from 'v2/views/client/BankConciliation/utils/bankConciliationUtils';
import { DefaultContentContainer } from 'v2/components/layout';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';
export function HeaderBankConciliationDetails() {
  const [transactionsDetails, setTransactionsDetails] = React.useState([]);
  const { watch } = useFormContext();
  const dataOFX = watch('informationFile.data');
  const isViewing = watch('isViewing');

  React.useEffect(() => {
      const bankConciliationArray = generateBankConciliationDetails(dataOFX);
      setTransactionsDetails(bankConciliationArray);
  }, [dataOFX]);


  const path= ['/client/home', null, '/client/' + CLIENT_PATH_ROUTES.BANK_CONCILIATION.BASE, null];


  return (
    <HeaderBankConciliationDetailsContainer>
      <DefaultContentContainer showRequiredFieldsLabel>
        <BreadCrumb
          data={[
            'Início',
            'Financeiro ',
            'Conciliação Bancária',
            `${isViewing ? 'Visualizar' : 'Nova'} Conciliação`,
          ]}

          path={path}

          /* path={['home', null, null, null]} */
        />
      </DefaultContentContainer>
      <div className='bank-conciliation-header-card'>
        <div style={{ display: 'flex', alignItems: 'center', width:'calc(100% - 32px)', gap: '12px'}}>
          {transactionsDetails.map((transaction, index) => (
          <BankConciliationHeaderCard props={ transaction }  key={transaction.title} index={index}/>
        ))}
        </div>
      </div>
    </HeaderBankConciliationDetailsContainer>
  );
}
