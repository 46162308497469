import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'

import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import EmployeeMainData from './MainData'
import Address from '../../../Clients/NewClient/FormClient/Endereco';
import constants from '../../../../../utils/constants'

const FormEmployee = ({ handleSubmit, loading, employeeId, pristine, modal, hideModal }) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [isMainDataCardOpen, setIsMainDataCardOpen] = useState(true)
  const [isAddressCardOpen, setIsAddressCardOpen] = useState(false)

  const history = useHistory()

  const handleCancel = () => {
    if (pristine) return cancel()
    setIsAlertModalOpen(true)
  }

  const cancel = () => {
    if (modal) return hideModal()
    history.push(constants.ROUTES.EMPLOYEES)
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show={isMainDataCardOpen}
          title="Dados do Funcionário"
          onClick={ () => setIsMainDataCardOpen(!isMainDataCardOpen) }
        >
          <EmployeeMainData />

          <Col lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            className="btn-green"
            type="button"
            onClick={() => {
              setIsMainDataCardOpen(false)
              setIsAddressCardOpen(true)
            }}
            style={{ position: 'absolute', right: 0, top: -40 }}
          >Prosseguir</button>
          </Col>
        </CardForm>

        <CardForm
        show={isAddressCardOpen}
        title="Endereço (Opcional)"
        onClick={ () => setIsAddressCardOpen(!isAddressCardOpen) }
        >
          <Address form='formEmployee' edit={!!employeeId}/>
        </CardForm>

        <FormSubmitButtons loading={loading} hasId={!!employeeId} onCancel={handleCancel} onSubmit={handleSubmit} />
      </form>

      <AlertModal
        show={isAlertModalOpen}
        onHide={() => setIsAlertModalOpen(false)}
        onCancel={() => setIsAlertModalOpen(false)}
        message={`Deseja cancelar ${employeeId ? 'a edição': 'o cadastro'} do funcionário? Todos os campos preenchidos não serão salvos e ${employeeId ? 'a edição': 'o cadastro'} poderá ser realizad${employeeId ? 'a': 'o'} posteriormente.`}
        onSubmit={cancel}
      />
    </>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formEmployee'
})(FormEmployee)