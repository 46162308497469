import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardForm from 'client/components/CardForm';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import SearchInput from 'components/SearchInput';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input';
import { Controller } from 'react-hook-form';
import ReactTable from 'react-table';
import '../styles.css';
import { useAuth } from 'contexts/auth';

const NewPromotionalPriceItems = ({
  setQuery,
  setDiscountType,
  discountType,
  discountValue,
  setDiscountValue,
  loading,
  control,
  data,
  setIsAddItemModalOpen,
  handleDeleteItem,
  handleChangeSelectedDiscountValue,
  discountOption,
  setDiscountOption,
}) => {
  const [show, setShow] = useState(true);
  const { company } = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (e) => {
    e.preventDefault();

    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((item, index) => index));
    }
  };

  const isButtonDisabled = selectedRows.length === 0;
  const isWholesale =
    company?.companyConfig.sellingPriceType === 'Preço Atacado e Varejo';

  return (
    <CardForm
      show={show}
      title="Itens da Promoção"
      onClick={() => setShow(!show)}
    >
      <div className="new-promotional-price__items-header">
        <div className="new-promotional-price__row h-35">
          <div className="new-promotional-price__button">
            <button
              className="btn btn-sucesso button-h35"
              onClick={() => setIsAddItemModalOpen(true)}
            >
              Adicionar Item
            </button>
          </div>

          <div
            style={{ width: '40rem' }}
            className="new-promotional-price__button"
          >
            <SearchInput
              placeholder="Pesquisa por Código, Ref. Fabricante ou Descrição"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="new-promotional-price__row ">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                marginRight: '-8px',
                marginLeft: '10px',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              Aplicar Desc. para todos itens:
            </span>
          </div>
          {isWholesale && (
            <select
              style={{
                width: '120px',
                height: '35px',
                borderRadius: '4px',
                fontSize: '12px',
              }}
              className="form-control foco-input"
              value={discountOption}
              onChange={(e) => setDiscountOption(e.target.value)}
            >
              <option value="retail">Varejo</option>
              <option value="wholesale">Atacado</option>
              <option value="both">Ambos</option>
            </select>
          )}

          <select
            style={{
              width: '75px',
              height: '35px',
              borderRadius: '4px',
              fontSize: '12px',
            }}
            className="form-control foco-input"
            onChange={(e) => setDiscountType(e.target.value)}
          >
            <option value="percentage">%</option>
            <option value="value">R$</option>
          </select>

          <CurrencyInput
            style={{
              width: '100px',
              height: '35px',
              borderRadius: '4px',
              fontSize: '12px',
            }}
            className="form-control foco-input"
            maxLength={discountType === 'percentage' ? 6 : 100}
            decimalSeparator=","
            thousandSeparator="."
            suffix={discountType === 'percentage' ? '%' : ''}
            prefix={discountType === 'value' ? 'R$ ' : ''}
            value={discountValue}
            onChangeEvent={(e) => setDiscountValue(e.target.value)}
          />

          <div className="new-promotional-price__button">
            <button
              className="btn btn-import button-h35"
              onClick={() => handleChangeSelectedDiscountValue(selectedRows)}
              disabled={isButtonDisabled}
            >
              Aplicar Desconto
            </button>
          </div>
        </div>
      </div>
      {data.length > 0 ? (
        <div style={{ marginTop: '10px' }}>
          <a href="#" onClick={handleSelectAll} style={{ fontSize: '12px' }}>
            {selectedRows.length === data.length
              ? `Remover Todos (${
                  selectedRows.length ? selectedRows.length : 'x'
                })`
              : `Selecionar Todos (${data.length ? data.length : 'x'})`}
          </a>
        </div>
      ) : (
        <div />
      )}

      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          marginTop: 10,
          fontSize: '12px',
        }}
        data={data}
        columns={[
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                &nbsp;
              </span>
            ),
            accessor: 'checkbox',
            width: Math.round((window.innerWidth - 52) * 0.02),
            style: {
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto 0',
            },
            Cell: (props) => (
              <input
                type="checkbox"
                style={{
                  margin: '0',
                }}
                checked={selectedRows.includes(props.index)}
                onChange={() => {
                  const index = selectedRows.indexOf(props.index);
                  if (index === -1) {
                    setSelectedRows([...selectedRows, props.index]);
                  } else {
                    setSelectedRows(selectedRows.filter((_, i) => i !== index));
                  }
                }}
              />
            ),
          },
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Código
              </span>
            ),
            accessor: 'Code',
            width: Math.round((window.innerWidth - 55) * 0.07),
            style: { fontSize: '12px' },
          },
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Ref. Fabricante
              </span>
            ),
            accessor: 'Manufacturer_Ref',
            width: Math.round((window.innerWidth - 55) * 0.1),
            style: { fontSize: '12px' },
          },
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Descrição
              </span>
            ),
            accessor: 'Description',
            style: { fontSize: '12px' },
          },
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Marca
              </span>
            ),
            accessor: 'Brand',
            width: Math.round((window.innerWidth - 55) * 0.1),
            style: { fontSize: '12px' },
          },
          {
            Header: (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Família
              </span>
            ),
            accessor: 'Family',
            width: Math.round((window.innerWidth - 55) * 0.1),
            style: { fontSize: '12px' },
          },
          {
            Header: isWholesale ? (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                R$ Venda <br /> (Varejo)
              </span>
            ) : (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                R$ Venda
              </span>
            ),
            accessor: 'Unit_Value',
            width: Math.round((window.innerWidth - 55) * 0.1),
            Cell: (props) => (
              <a style={{ fontSize: '12px' }}>{currency(props.value)}</a>
            ),
          },
          {
            Header: isWholesale ? (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Preço Promo. <br /> (Varejo)
              </span>
            ) : (
              <span
                style={{
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Preço Promo.
              </span>
            ),
            accessor: 'promotionalPrice',
            width: Math.round((window.innerWidth - 55) * 0.08),
            style: { fontSize: '12px' },
            Cell: (props) => {
              return (
                <Controller
                  name={`promotionalPriceTableItems.${props.index}.promotionalPrice`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        id={`promotionalPriceTableItems.${props.index}.promotionalPrice`}
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={props.value}
                        className="form-control foco-input"
                        style={{
                          fontSize: '12px',
                          height: '35px',
                          borderRadius: '4px',
                        }}
                        {...field}
                        onChange={(e, value) => {
                          setTimeout(() => {
                            document
                              .getElementById(
                                `promotionalPriceTableItems.${props.index}.promotionalPrice`
                              )
                              .focus();
                          }, 150);
                          field.onChange(value);
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          ...(isWholesale
            ? [
                {
                  Header: (
                    <span
                      style={{
                        height: '38px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      R$ Venda <br /> (Atacado)
                    </span>
                  ),
                  accessor: 'salePriceWholesale',
                  width: Math.round((window.innerWidth - 55) * 0.1),
                  Cell: (props) => (
                    <a style={{ fontSize: '12px' }}>
                      {currency(
                        props.original.Type === 'Produto'
                          ? props.value
                          : props.original.Unit_Value
                      )}
                    </a>
                  ),
                },
                {
                  Header: (
                    <span
                      style={{
                        height: '38px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Preço Promo.
                      <br /> (Atacado)
                    </span>
                  ),
                  accessor: 'promotionalPriceWholesale',
                  width: Math.round((window.innerWidth - 55) * 0.08),
                  style: { fontSize: '12px' },
                  Cell: (props) => {
                    return (
                      <Controller
                        name={`promotionalPriceTableItems.${props.index}.promotionalPriceWholesale`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <CurrencyInput
                              disabled={
                                props.original.Service_id ||
                                props.original.Type === 'Serviço'
                              }
                              id={`promotionalPriceTableItems.${props.index}.promotionalPriceWholesale`}
                              prefix="R$ "
                              decimalSeparator=","
                              thousandSeparator="."
                              value={
                                props.original.Type === 'Produto'
                                  ? props.value
                                  : (field.value = props.original.Unit_Value)
                              }
                              className="form-control foco-input"
                              style={{
                                fontSize: '12px',
                                height: '35px',
                                borderRadius: '4px',
                              }}
                              {...field}
                              onChange={(e, value) => {
                                setTimeout(() => {
                                  document
                                    .getElementById(
                                      `promotionalPriceTableItems.${props.index}.promotionalPriceWholesale`
                                    )
                                    .focus();
                                }, 150);
                                field.onChange(value);
                              }}
                            />
                          );
                        }}
                      />
                    );
                  },
                },
              ]
            : []),
          {
            Header: '',
            accessor: 'id',
            width: Math.round((window.innerWidth - 55) * 0.04),
            Cell: (props) => (
              <FontAwesomeIcon
                title="Excluir"
                cursor="pointer"
                style={{
                  textAlign: 'center',
                  height: '1.5em',
                  width: '1.5em',
                  color: 'red',
                }}
                icon={faTrashAlt}
                onClick={() => handleDeleteItem(props.index)}
              />
            ),
          },
        ]}
        defaultPageSize={10}
        pageSize={10}
        manual
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        sortable={false}
        loading={loading}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={false}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </CardForm>
  );
};

export default NewPromotionalPriceItems;
