import React from 'react'
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import routes from '../../../routes'
import ReceivedByFormOfPayment from './ReceivedByFormOfPayment';
import ValuesByStatus from './ValuesByStatus';
import PaymentsByStatus from './PaymentsByStatus';
import CashierBankByDate from './CashierBankByDate';
import FinancialStatement from './FinancialStatement';

const FinancialReports = () => {
  const route = routes.filter(r => `/client/${r.path}` === window.location.pathname)

  const renderComponent = name => {
    switch (name) {
      case 'Recebimentos por Forma de Pagamento':
        return <ReceivedByFormOfPayment />
      case 'Valores por Status':
        return <ValuesByStatus />
      case 'Resumo Pagamentos por Status':
        return <PaymentsByStatus />
      case 'Fluxo de Caixa':
        return <CashierBankByDate />
      case 'DRE - Demonstrativo de Resultado do Exercício':
        return <FinancialStatement />
      default:
        return <div />
    }
  }


  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Relatórios', 'Financeiro', route[0].name]}
            path={['Home', null, null, null]} />
          {renderComponent(route[0].name)}
        </Row>
      </Grid>
    </div>
  )
}

export default FinancialReports
