import api from '../services/api'

const index = async (query) => {
  const { data } = await api.get('product', { params: query })
  return data
}

export default {
  index,
}
