import api from '../services/api'
import config from '../config'

const getAll = async () => {
  const response = await api.get(config.endpoint + 'signature-status');
  return response.data
}

const signatureStatusRepository = {
  getAll
}

export default signatureStatusRepository