import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';

import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import AlertModal from 'components/AlertModal/AlertModal';
import SearchInput from 'components/SearchInput';
import CollapsibleSection from 'components/CollapsibleSection';

import config from 'config';
import api from 'services/api';
import useDebounce from 'hooks/useDebounce';

import NFSePatternsRepository from 'repositories/NFSePatterns';
import NFSePatternCitiesRepository from 'repositories/NFSePatternCities';
import NFSePatternCitiesModalForm from './ModalForm';

const NFSeSettingsMain = ({ history, patternName, patternId }) => {
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);
  const [uf, setUf] = useState('');

  const [patternCities, setPatternCities] = useState([]);
  const [patternCitiesCount, setPatternCitiesCount] = useState(0);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);

  const [statesOptions, setStatesOptions] = useState([]);

  const [pattern, setPattern] = useState({
    pattern: patternName,
  });

  function handleChangeQuery(value) {
    setQuery(value);
    debouncedSaveQuery(value);
  }

  async function handleLoadCities() {
    setLoading(true);
    try {
      const response = await NFSePatternCitiesRepository.index({
        statePattern: uf,
        pattern: patternId,
        codeOrNameCity: query,
        page: page + 1,
        limit,
      });

      setPatternCities(response?.data);
      setPatternCitiesCount(response?.count);
      setTotalPages(Math.ceil(response?.count / limit));
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadStates() {
    try {
      const response = await api.get(config.endpoint + `address/states`);
      setStatesOptions(response.data);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Erro ao carregar as Unidades Federativas (UF). Tente novamente!'
      );
    }
  }

  async function handleLoadPatternData() {
    setLoading(true);
    try {
      const pattern = await NFSePatternsRepository.show(patternId);
      setPattern(pattern.data);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados do padrão. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleLoadCities();
  }, [queryDebounced, uf, page, limit]);

  useEffect(() => {
    handleLoadStates();
  }, []);

  useEffect(() => {
    if (!patternId) {
      history.push('nfse-settings');
    } else {
      handleLoadPatternData();
    }
  }, [patternId]);

  return (
    <main className="admin__pattern-cities">
      <CollapsibleSection
        title="Cidades do Padrão"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div style={{ padding: '0 20px' }}>
          <section className="admin__pattern-cities__pattern-data-row">
            <div>
              <label htmlFor="patternName">Nome do Padrão:</label>
              <input
                id="patternName"
                type="text"
                className="form-control"
                value={pattern.pattern}
                disabled
              />
            </div>
            <div>
              <label htmlFor="offline">Tipo:</label>
              <input
                id="offline"
                type="text"
                className="form-control"
                value={pattern.offline === true ? 'Offline' : 'Online'}
                disabled
              />
            </div>
            <div>
              <label htmlFor="isHomologated">Status:</label>
              <input
                id="isHomologated"
                type="text"
                className="form-control"
                value={
                  pattern.isHomologated === true
                    ? 'Homologado'
                    : 'Não Homologado'
                }
                disabled
              />
            </div>
          </section>
          <section className="admin__pattern-cities__filter-row">
            <button
              className="btn btn-sucesso"
              onClick={() => setIsModalFormOpen(true)}
            >
              + Nova Cidade
            </button>
            <div className="search-input-wrapper">
              <SearchInput
                placeholder="Pesquisa por Código da Cidade, Nome"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
              />
            </div>
            <fieldset>
              <label style={{ marginRight: 0, paddingRight: 0 }}>UF:</label>
              <select
                value={uf}
                onChange={(e) => setUf(e.target.value)}
                className="form-control foco-input"
              >
                <option value="">Todas</option>
                {statesOptions.map((state) => {
                  return (
                    <option key={state.id} value={state.Initials}>
                      {state.Initials}
                    </option>
                  );
                })}
              </select>
            </fieldset>
          </section>

          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            data={patternCities}
            columns={[
              {
                Header: 'Código IBGE',
                accessor: 'IBGECityCode',
                width: 200,
              },
              {
                Header: 'Nome',
                accessor: 'city',
                width: 300,
              },
              {
                Header: 'UF',
                accessor: 'UF',
                width: 80,
              },
              {
                Header: 'Permite NFS Homolog.?',
                accessor: 'allowHomologation',
                width: 200,
                Cell: (props) => (props.value ? 'Sim' : 'Não'),
              },
              {
                Header: 'Permite Cancelar NFS-e?',
                accessor: 'allowCancel',
                width: 200,
                Cell: (props) => (props.value ? 'Sim' : 'Não'),
              },
              {
                Header: 'Tipo de Texto Adicional',
                accessor: 'concatAdditionalInformation',
                width: 300,
                Cell: (props) =>
                  props.value ? 'Concatenado' : 'Campo Próprio',
              },
              {
                Header: 'Ações',
                accessor: 'id',
                width: 200,
                Cell: (props) => {
                  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
                  const [isDeleteModalOpen, setIsDeleteModalOpen] =
                    useState(false);
                  const [isSubmitting, setIsSubmitting] = useState(false);

                  async function handleDeleteCity() {
                    setIsSubmitting(true);
                    try {
                      await NFSePatternCitiesRepository.deleteCity(props.value);
                      toastr.success(
                        'OS Digital',
                        'Exclusão realizada com sucesso!'
                      );
                      setIsDeleteModalOpen(false);
                      handleLoadCities();
                    } catch (err) {
                      console.log(err);
                      toastr.error(
                        'OS Digital',
                        'Ocorreu um erro na exclusão. Tente novamente!'
                      );
                      setIsSubmitting(false);
                    }
                  }

                  return (
                    <>
                      <span title="Editar Cidade">
                        <FontAwesomeIcon
                          cursor="pointer"
                          onClick={() => setIsEditModalOpen(true)}
                          icon={faEdit}
                          style={{ width: '1.2em', height: '1.2em' }}
                        />
                      </span>
                      &nbsp;
                      <span title="Excluir Cidade">
                        <FontAwesomeIcon
                          cursor="pointer"
                          onClick={() => setIsDeleteModalOpen(true)}
                          icon={faTrashAlt}
                          style={{
                            width: '1.2em',
                            height: '1.2em',
                            color: 'red',
                          }}
                        />
                      </span>
                      {isEditModalOpen && (
                        <NFSePatternCitiesModalForm
                          patternId={patternId}
                          patternCityId={props.original.id}
                          handleClose={() => setIsEditModalOpen(false)}
                          handleReload={() => handleLoadCities()}
                          patternAllowCancel={pattern.allowCancel}
                          patternConcatAdditionalInfo={
                            pattern.concatAdditionalInformation
                          }
                        />
                      )}
                      {isDeleteModalOpen && (
                        <AlertModal
                          title="OS Digital"
                          show={isDeleteModalOpen}
                          loading={isSubmitting}
                          message={
                            <>
                              <span>
                                Você tem certeza que deseja excluir a cidade do
                                padrão?
                              </span>
                              <br />
                              <br />
                              <span>
                                Ao excluir, não poderá fazer a emissão da mesma
                                e nem validação de disponibilidade. Ao final da
                                operação, faça o vínculo no mesmo momento em
                                outro padrão.
                              </span>
                            </>
                          }
                          onCancel={() => setIsDeleteModalOpen(false)}
                          onSubmit={() => {
                            handleDeleteCity();
                          }}
                          onHide={null}
                        />
                      )}
                    </>
                  );
                },
              },
            ]}
            defaultPageSize={10}
            page={page}
            onPageChange={setPage}
            pageSize={limit}
            onPageSizeChange={(value) => {
              setLimit(value);
            }}
            showPagination
            loading={loading}
            pages={totalPages}
            manual
            sortable
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Não há informação"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </div>
      </CollapsibleSection>

      {isModalFormOpen && (
        <NFSePatternCitiesModalForm
          patternId={patternId}
          handleClose={() => setIsModalFormOpen(false)}
          handleReload={() => handleLoadCities()}
          patternAllowCancel={pattern.allowCancel}
          patternConcatAdditionalInfo={pattern.concatAdditionalInformation}
        />
      )}
    </main>
  );
};

export default withRouter(NFSeSettingsMain);
