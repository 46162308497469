import React from 'react'
import ReactExport from "react-export-excel";

import Button from 'client/components/CustomButton/CustomButton.jsx';

const DownloadXlsCustomized = ({ data, columns, children, archiveName, ...rest }) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  
    return (
        <ExcelFile element={
          <Button
            {...rest}
          >
            {children}
          </Button>} filename={archiveName}>
            <ExcelSheet data={data} name={archiveName}>
                {columns.map(c => <ExcelColumn label={c.name} key={c.acessor} value={c.acessor}/>)}
            </ExcelSheet>
        </ExcelFile>
    )
  }
export default DownloadXlsCustomized;