import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useBonus } from 'hooks/useBonus';

export const Totals = () => {
  const { totalSale, paidValue, step, totalSaleWithBonus, redeemedBonus } =
    useSelector((state) => state.form.pdvOnline.values);

  const { companyHasCRMBonus } = useBonus();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      change('pdvOnline', 'totalSaleWithBonus', totalSale - redeemedBonus)
    );
  }, [totalSale]);

  return (
    <div>
      {step === 2 ? (
        <div className="totals-wrapper">
          {companyHasCRMBonus && step === 2 && (
            <>
              <div>
                <span>Bônus:</span>
                <span style={{ color: '#51a751' }}>
                  {currency(redeemedBonus)}
                </span>
              </div>
            </>
          )}
          <div>
            <span>Total:</span>
            <span>
              {currency(
                companyHasCRMBonus && totalSaleWithBonus > 0
                  ? totalSaleWithBonus
                  : totalSale
              )}
            </span>
          </div>
          {step === 2 && (
            <>
              <div>
                <span>Valor Pago: </span>
                <span>{currency(paidValue)}</span>
              </div>
              <div>
                <span>Troco: </span>
                <span>
                  {paidValue <= 0
                    ? currency(0)
                    : paidValue >
                      (totalSaleWithBonus ? totalSaleWithBonus : totalSale)
                    ? currency(
                        paidValue -
                          (totalSaleWithBonus ? totalSaleWithBonus : totalSale)
                      )
                    : currency(0)}
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div style={{ color: '#606060', fontSize: '20px', fontWeight: 600 }}>
          <span>Total:</span>
          <span>
            {currency(
              companyHasCRMBonus && totalSaleWithBonus > 0
                ? totalSaleWithBonus
                : totalSale
            )}
          </span>
        </div>
      )}
    </div>
  );
};
