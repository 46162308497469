import config from '../config';
import api from '../services/api';

const getById = async (id) => {
  try {
    const response = await api.get(config.endpoint + `users/${id}`);
    if (!response.data.status) {
      throw new Error('Erro ao buscar o usuário');
    }

    return response.data.data;
  } catch (err) {
    throw err;
  }
};

const getByEmail = async (email) => {
  try {
    const response = await api.get(
      config.endpoint + `users/search/byEmail/${email}`
    );
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

const getByEmailV2 = async (email) => {
  const { data } = await api.get(`/users/search/by-email/${email}`);

  return data;
};

const getByCompanyId = async (companyId) => {
  const { data } = await api.get(`users/all-by-company/${companyId}`);

  return data;
};

const getBranchIdByUserId = async (userId) => {
  const { data } = await api.get(`users/branchId/${userId}`);

  return data;
};

const create = async (user) => {
  try {
    const response = await api.post(config.endpoint + 'users', user);
    if (!response.data.status) {
      throw new Error('Ocorreu um erro ao criar o usuário');
    }

    return response.data.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, user) => {
  try {
    await api.put(config.endpoint + `users/${id}`, user);
  } catch (err) {
    throw err;
  }
};

const updateEmail = async (id, { email, forceUpdate }) => {
  await api.put(`/users/change/email/${id}`, { email, forceUpdate });
};

const authenticate = async ({ email, password, developerMode }) => {
  const response = await api.post(config.endpoint + `users/authenticate`, {
    Email: email,
    User_Password: password,
    developerMode,
  });

  return response.data;
};

const validateComplience = async (companyId) => {
  const response = await api.get(
    config.endpoint + `integration/superlogica/plan-issue-date`,
    {
      params: { id: companyId },
    }
  );

  return response.data;
};

const mainUpdate = (userId, values) => api.put(`users/${userId}/main`, values);

export default {
  getById,
  update,
  updateEmail,
  create,
  getByEmail,
  getByEmailV2,
  getByCompanyId,
  getBranchIdByUserId,
  authenticate,
  mainUpdate,
  validateComplience,
};
