import React from 'react'

import { Modal } from 'react-bootstrap'
import Button from '../../../client/components/CustomButton/CustomButton.jsx'

export const SegmentsBlockedModal = ({ companyId, show, onHide, onSubmit }) => {
  return (
    <Modal show={show} animation onHide={onHide} dialogClassName="modal-40w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'left' }}>
          <strong
            style={{
              fontSize: '14px',
            }}
          >
            Função não disponível para o seu segmento.
          </strong>
          <p
            style={{
              fontSize: '14px',
              marginTop: '20px',
            }}
          >
            A função de busca de peças online é exclusiva para os segmentos
            <strong> Carros, Motos, Caminhões e Vans.</strong> Caso utilize
            algum desses segmentos, ative para usar a consulta
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="upgrade-modal-footer">
          <Button bsStyle="danger" name="Voltar" onClick={onHide} fill>
            Voltar
          </Button>
          {companyId && (
            <Button
              bsStyle="info"
              className="btn btn-sucesso"
              fill
              onClick={onSubmit}
            >
              <span>Alterar Segmento</span>
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}
