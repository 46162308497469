import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useAuth } from 'contexts/auth'

import materaCompanyRepository from '../../../../repositories/MateraCompany'

import MateraAccountResume from './MateraAccountResume'
import MateraAccountExtract from './MateraAccountExtract'

const Matera = () => {
  const [materaByCompany, setMateraByCompany] = useState(null)
  const [hasMateraAccount, setHasMateraAccount] = useState(true)

  const [loading, setLoading] = useState(true)

  const { companyId } = useAuth()

  useEffect(() => {
    loadMateraInformation()
  }, [])

  const loadMateraInformation = async () => {
    setLoading(true)
    try {
      const { data: materaCompany } =
        await materaCompanyRepository.showByCompanyId(companyId)

      if (!materaCompany.id || materaCompany?.status !== 'FINALIZADO') {
        setHasMateraAccount(false)
        return
      }

      const { data: balance } = await materaCompanyRepository.getBalance(
        companyId
      )

      const realBalance = balance.accountBalance.data.balances.find(
        (balance) => balance.balanceType === 'REAL'
      )
      const taxPix = materaCompany.taxPixWork + materaCompany.taxPixMatera

      setMateraByCompany({
        balance: realBalance.amount,
        agency: materaCompany.agency,
        accountNumber: materaCompany.accountNumber,
        taxPix,
      })
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao buscar as informações da conta. Tente novamente!'
      )
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <MateraAccountResume
        hasMateraAccount={hasMateraAccount}
        info={materaByCompany}
        loading={loading}
      />
      <MateraAccountExtract
        hasMateraAccount={hasMateraAccount}
        info={materaByCompany}
        loading={loading}
        loadMateraInformation={loadMateraInformation}
      />
    </>
  )
}

export default Matera
