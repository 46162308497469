import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import MainData from './MainData'

const FormCashierBank = ({ handleSubmit, loading, onCancel,history }) => {
  const [modal, setModal] = useState(false)

  const {
    id,
    description,
    observations,
    bankId,
    agency,
    checkingAccount,
    phoneNumber,
    manager
  } = useSelector(state => state.form.formCashierBank.values)

  const handleCancelModal = () => {
    if (
      description ||
      observations ||
      bankId ||
      agency ||
      checkingAccount ||
      phoneNumber ||
      manager
    ) return setModal(true)
    onCancel()
  }

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }} >
      <CardForm
        show
        title="Dados do Banco/Caixa"
        onClick={() => { }}
      >
        <MainData />
      </CardForm>

      <FormSubmitButtons
        loading={loading}
        onCancel={handleCancelModal}
        onSubmit={handleSubmit}
        hasId={!!id}
      />

      {modal &&
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message='Deseja cancelar o cadastro? Nenhuma informação será salva e o cadastro poderá ser efetuado posteriormente'
          onSubmit={onCancel}
        />}
    </form>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formCashierBank'
})(FormCashierBank)