import api from '../services/api';
import config from '../config';

const verifyNews = async (userId, brandingId) => {
  try {
    const response = await api.get(
      config.endpoint + `news-user/verify-dashboard/${userId}/${brandingId}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const generateReport = async (newsId) => {
  try {
    const response = await api.get(
      config.endpoint + `news-user/generate-report/${newsId}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export default {
  verifyNews,
  generateReport,
};
