import api from '../services/api'
import config from '../config'

const list = async (companyId, { page, limit, query, type, status }) => {
  try {
    const response = await api.get(config.endpoint + 'trade-representative', {
      params: {
        companyId,
        page,
        limit,
        query,
        type,
        status,
      },
    })
    return response.data
  } catch (err) {
    throw err
  }
}

const create = async (data) => {
  try {
    const response = await api.post(`trade-representative`, data)
    return response.data
  } catch (err) {
    throw err
  }
}

const update = async (id, data) => {
  try {
    const response = await api.put(`trade-representative/` + id, data)
    return response.data
  } catch (err) {
    throw err
  }
}

const show = async (id) => {
  try {
    const response = await api.get(`trade-representative/` + id)
    return response.data
  } catch (err) {
    throw err
  }
}

const getActivesRepresentativesList = async (companyId) => {
  const { data } = await api.get(`trade-representative/by-company/${companyId}`)
  return data
}

const TradeRepresentativeRepository = {
  list,
  getActivesRepresentativesList,
  create,
  update,
  show,
}

export default TradeRepresentativeRepository
