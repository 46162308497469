import React from 'react'
import Button from 'client/components/CustomButton/CustomButton.jsx'

const NewRequisitionFormButtons = ({
  onCancel,
  onSubmit,
  hasId,
  loading,
  disabledSubmit,
  backLabel,
  data,
}) => {
  const quantityOfItemsWithoutAvailableQuantity = data.filter(item => item.availableQuantity === 0).length

  return (
    <div className="form-submit-buttons">
      <Button bsStyle="danger" fill disabled={loading} onClick={onCancel}>
        {hasId ? 'Voltar' : 'Cancelar'}
      </Button>
      {!hasId && (
        <Button
          bsStyle="info"
          fill
          onClick={onSubmit}
          disabled={loading || disabledSubmit || quantityOfItemsWithoutAvailableQuantity === data.length}
        >
          <span
            className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
            style={{ marginRight: loading ? '5px' : '' }}
          />
          Salvar
        </Button>
      )}
    </div>
  )
}

export default NewRequisitionFormButtons
