import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  h1: {
    fontSize: 20,
    fontFamily: 'Helvetica-Bold'
  },
})

export const H1 = ({ children, style = {}, ...rest }) => <Text {...rest} style={[styles.h1, style]}>{children}</Text>