import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NewFinancingBankSlipMain from './components/NewFinancingBankSlipMain'

import './styles.css'

const FinancingBankSlipConfig = ({ match, history }) => {
  const financingBankSlipId =
    match.params.id || history.location.hash.split('#')[1]

  const partnerId = history?.location?.state?.partnerId

  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Integrações',
          'Boleto Parcelado',
          financingBankSlipId ? 'Editar Cliente' : 'Novo Cliente',
        ]}
        path={['home', null, 'financing-bank-slip', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <NewFinancingBankSlipMain
        financingBankSlipId={financingBankSlipId}
        partnerId={partnerId}
        goToHomePage={() => history.push('financing-bank-slip')}
      />
    </>
  )
}

export default withRouter(FinancingBankSlipConfig)
