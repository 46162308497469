import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { isValid, format, addDays, isBefore } from 'date-fns';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadXlsButton from 'components/DownloadXlsButton';

import './styles.css';

import employeesRepository from '../../../../../repositories/Employees';
import { encrypt, currency } from 'client/components/ToNormalize/ToNormalize';

import { usePlanSignatureContext } from 'contexts/plan-signature';
import { useHistory } from 'react-router-dom';
import CommissionsReportRepository from 'repositories/CommissionsReport';
import { percentage } from 'client/components/ToNormalize/ToNormalize';
import PDFButton from 'client/components/PDFButton';

export const CommissionsReport = () => {
  const [employees, setEmployees] = useState([]);
  const [employeeId, setEmployeeId] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [showClientData, setShowClientData] = useState(true);
  const [showTotalizators, setShowTotalizators] = useState(true);
  const [showTypeItem, setShowTypeItem] = useState('');
  const [loading, setLoading] = useState(false);
  const [commissions, setCommissions] = useState([]);
  const [paymentForData, setPaymentForData] = useState('saleClose');

  const { companyId } = useAuth();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const history = useHistory();

  useEffect(() => {
    if (!!companyId) loadEmployees();
  }, [companyId]);

  useEffect(() => {
    setCommissions([]);
  }, [employeeId, initialDate, finalDate, showClientData]);

  useEffect(() => {
    if (isPlanFree || isPlanStart) history.goBack();
  }, [isPlanFree, isPlanStart, history]);

  const loadEmployees = async () => {
    setLoading(true);
    try {
      const { data } = await employeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      });
      setEmployees(data);
    } catch (err) {
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar funcionários. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (!!!employeeId) {
      toastr.warning('Selecione o funcionário para gerar o relatório.');
      return false;
    }

    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const empId = employeeId === 'all' ? null : employeeId;

      const data =
        paymentForData === 'saleClose'
          ? await CommissionsReportRepository.generateByCompanyId({
              initialDate,
              finalDate,
              companyId,
              employeeId: empId,
              xls: true,
              showClientData,
            })
          : await CommissionsReportRepository.generateByCompanyIdTitle({
              initialDate,
              finalDate,
              companyId,
              employeeId: empId,
              xls: true,
              showClientData,
            });

      if (!!!data.length)
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );

      setCommissions(
        data
          .map((employee) => {
            const employeeCommissionsF = [];

            employee.commissions.forEach((c) => {
              employeeCommissionsF.push({
                date: format(new Date(c.date), 'dd/MM/yyyy'),
                employeeName: employee.name,
                saleCode: c.saleCode,
                type: c.type,
                description: c.description,
                qtd: c.itemQuantity,
                unitValue: currency(c.unitValue),
                amount: currency(c.amount),
                commissionRate: percentage(c.commissionRate),
                commissionValue: currency(c.commissionValue),
              });
            });

            return employeeCommissionsF;
          })
          .reduce((prev, curr) => {
            if (!prev) {
              return [...curr];
            } else {
              return [...prev, ...curr];
            }
          }, [])
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar comissões. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    const employeeIdHash = encrypt(employeeId, '@OS-dig:employeeId');
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    window.open(
      window.location.origin +
        `/report/commissions?employeeId=${employeeIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyIdHash}&showClientData=${showClientData}&paymentForData=${paymentForData}&showTotalizators=${showTotalizators}&showTypeItem=${showTypeItem}`,
      '_blank'
    );
  };

  const handleArchiveName = () => {
    let name = '';
    let initialDateName = '';
    let finalDateName = '';
    if (!!employeeId) {
      const employee = employees.find(({ id }) => id === Number(employeeId));
      name = employee?.name;
    }

    if (isValid(new Date(initialDate)))
      initialDateName = format(addDays(new Date(initialDate), 1), 'dd-MM-yyyy');
    if (isValid(new Date(finalDate)))
      finalDateName = format(addDays(new Date(finalDate), 1), 'dd-MM-yyyy');

    return `Comissão_${initialDateName}_${finalDateName}`;
  };

  const archiveName = handleArchiveName();

  return (
    <main className="sales-commissions-report">
      <section style={{ 'margin-left': '11px' }}>
        <label>Funcionário:</label>
        <select
          className="form-control foco-input"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        >
          <option value="">Selecione</option>
          {employees.length > 1 ? <option value="all">Todos</option> : null}
          {employees.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </section>
      <section>
        <label>Filtrar por:</label>
        <select
          style={{ width: '270px' }}
          className="form-control foco-input"
          value={paymentForData}
          onChange={(e) => setPaymentForData(e.target.value)}
        >
          <option value="saleClose" selected>
            Data do Apontamento da Comissão
          </option>
          <option value="commissionTitle">Data de Pagamento do Título</option>
        </select>
      </section>
      <section>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
        />
      </section>
      <section>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
        />
      </section>
      <section>
        <label>Exibir Dados do Cliente:</label>
        <select
          className="form-control foco-input"
          value={showClientData}
          onChange={(e) => setShowClientData(e.target.value)}
        >
          <option value="true" selected>
            Sim
          </option>
          <option value="false">Não</option>
        </select>
      </section>
      <section>
        <label>Exibir Totais Vendidos:</label>
        <select
          className="form-control foco-input"
          value={showTotalizators}
          onChange={(e) => setShowTotalizators(e.target.value)}
        >
          <option value="true" selected>
            Sim
          </option>
          <option value="false">Não</option>
        </select>
      </section>
      <section>
        <label>Filtrar Por:</label>
        <select
          className="form-control foco-input"
          value={showTypeItem}
          onChange={(e) => setShowTypeItem(e.target.value)}
        >
          <option value="" selected>
            Ambos
          </option>
          <option value="services">Serviços</option>
          <option value="products">Produtos</option>
        </select>
      </section>

      {!!!commissions.length ? (
        <section>
          <button
            className="btn btn-sucesso"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Processar
          </button>
        </section>
      ) : (
        <>
          <section>
            <PDFButton
              className="btn btn-sucesso"
              onClick={handlePrint}
            ></PDFButton>
          </section>
          <section>
            <DownloadXlsButton
              archiveName={archiveName}
              data={commissions}
              className="btn btn-export"
              columns={[
                {
                  name: 'Data',
                  acessor: 'date',
                },
                {
                  name: 'Funcionário',
                  acessor: 'employeeName',
                },
                {
                  name: 'Nº Venda',
                  acessor: 'saleCode',
                },
                {
                  name: 'Tipo',
                  acessor: 'type',
                },
                {
                  name: 'Item',
                  acessor: 'description',
                },
                {
                  name: 'Qtd',
                  acessor: 'qtd',
                },
                {
                  name: 'Valor',
                  acessor: 'unitValue',
                },
                {
                  name: 'Total',
                  acessor: 'amount',
                },
                {
                  name: '% Comisão',
                  acessor: 'commissionRate',
                },
                {
                  name: 'R$ Comissão',
                  acessor: 'commissionValue',
                },
              ]}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </section>
        </>
      )}
    </main>
  );
};
