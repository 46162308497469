import config from '../config'
import api from "../services/api"

const getAllByCompany = async companyId => {
  try {

    const response = await api.get(config.endpoint + `review?Company_id=${companyId}`)
    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  getAllByCompany
}