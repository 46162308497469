import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { reduxForm, Field, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import Input from './components/Input';
import Select from './components/Select';
import TextArea from './components/TextArea';
import {
  description,
  name,
  email,
  phoneMask,
  cnpjMask,
  descriptionWithAltSign,
  onlyNumbers,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';
import TradeRepresentativeRepository from 'repositories/TradeRepresentative';
import { toastr } from 'react-redux-toastr';
import { currencyMask } from 'utils/masks';
import RenderField from 'components/RenderField';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { listApproach } from 'v2/repositories/ApproachRepository';
import { BrasilAPIService } from 'v2/services/brasilApi';

const PJ = ({ edit }) => {
  const {
    cadastroCliente: {
      values: {
        Status_IE,
        IE,
        hasCpfCnpj,
        tradeRepresentativeId,
        debtLimit,
        availableCredit,
        sellingPriceType,
        withholdsISS,
        approachId,
        Cpf_Cnpj,
        Client_id,
        PrincipalAddress,
      },
    },
  } = useSelector((state) => state.form);

  const response = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const { companyId, company } = useAuth();
  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isWorkmotor,
    isPlanWorkmotorFree,
  } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;
  const [tradeRepresentativeOptions, setTradeRepresentativeOptions] = useState(
    []
  );
  const [approachOptions, setApproachOptions] = useState([]);
  const [priceType, setPriceType] = useState(sellingPriceType);

  useEffect(() => {
    if (companyId) {
      loadApproach();
      loadTradeRepresentatives();
    }
  }, []);

  useEffect(() => {
    if (IE || Status_IE) {
      dispatch(
        change(
          'cadastroCliente',
          'Status_IE',
          Status_IE ?? 'Contribuinte de ICMS'
        )
      );
    } else {
      dispatch(change('cadastroCliente', 'Status_IE', ''));
    }
  }, [IE, Status_IE]);

  useEffect(() => {
    if (Cpf_Cnpj?.length === 18 && !Client_id) {
      fillDataByCNPJ();
    }
  }, [Cpf_Cnpj]);

  const fillDataByCNPJ = async () => {
    try {
      const { data } = await BrasilAPIService.consultCnpj(
        onlyNumbers(Cpf_Cnpj)
      );

      const cellphone =
        data.ddd_telefone_1?.length === 11
          ? data.ddd_telefone_1
          : data.ddd_telefone_2?.length === 11
          ? data.ddd_telefone_2
          : '';

      const commercialPhone =
        data.ddd_telefone_1.length === 10
          ? data.ddd_telefone_1
          : data.ddd_telefone_2?.length === 10
          ? data.ddd_telefone_2
          : '';

      dispatch(
        change('cadastroCliente', 'Company_Name', data.razao_social || '')
      );
      dispatch(
        change('cadastroCliente', 'Trading_Name', data.nome_fantasia || '')
      );
      dispatch(change('cadastroCliente', 'Email', data.email || ''));
      dispatch(
        change(
          'cadastroCliente',
          `PrincipalAddress.Zipcode`,
          cepMask(data.cep) || ''
        )
      );
      dispatch(
        change(
          'cadastroCliente',
          `DeliveryAddress.Zipcode`,
          cepMask(data.cep) || ''
        )
      );
      dispatch(change('cadastroCliente', `Zipcode`, cepMask(data.cep) || ''));

      dispatch(
        change(
          'cadastroCliente',
          'Number_Phone2',
          cellphone ? phoneMask(cellphone) : ''
        )
      );
      dispatch(
        change(
          'cadastroCliente',
          'Number_Phone1',
          commercialPhone ? phoneMask(commercialPhone) : ''
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const loadApproach = async () => {
    try {
      const response = await listApproach({
        companyId,
        isActive: true,
        limit: 10000,
        page: 1,
      });

      setApproachOptions(response.rows);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Abordagens',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadTradeRepresentatives = async () => {
    try {
      const response = await TradeRepresentativeRepository.list(companyId, {
        status: 1,
      });

      setTradeRepresentativeOptions(response.tradeRepresentativeList);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Representantes',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  function normalizeIM(value) {
    if (!value) return value;
    if (!value.match(/^[a-zA-Z0-9-.]+$/g)) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  function handleStatusIE(event) {
    const {
      target: { value },
    } = event;

    if (value === 'Contribuinte Isento') {
      dispatch(change('cadastroCliente', 'IE', 'ISENTO'));
    }
  }

  return (
    <>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="CNPJ:"
          name="Cpf_Cnpj"
          component={Input}
          normalize={cnpjMask}
          placeholder=""
          maxLength="18"
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Inscrição Estadual:"
          name="IE"
          component={Input}
          maxLength="14"
          disabled={Status_IE === 'Contribuinte Isento'}
          required={Status_IE === 'Contribuinte de ICMS'}
          normalize={onlyNumbers}
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Razão social:"
          required
          name="Company_Name"
          component={Input}
          normalize={description}
          placeholder="Indústria Mecânica Modelo Ltda."
          maxLength={60}
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Nome Fantasia:"
          name="Trading_Name"
          component={Input}
          normalize={description}
          placeholder="Mecânica"
          maxLength={60}
        />
      </Col>
      <Col lg={3} md={5} sm={3} xs={12}>
        <Field
          label="E-mail:"
          name="Email"
          type="text"
          placeholder="seucliente@gmail.com"
          component={Input}
          normalize={email}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Comercial:"
          name="Number_Phone1"
          placeholder="(21)2872-2294"
          component={Input}
          normalize={phoneMask}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Celular:"
          required
          name="Number_Phone2"
          placeholder="(21)98473-6441"
          component={Input}
          normalize={phoneMask}
        />
      </Col>
      <Col lg={3} md={3} sm={4} xs={12}>
        <Field
          label="Nome do Responsável:"
          required
          name="Contact"
          placeholder="João"
          component={Input}
          normalize={name}
          maxLength=""
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <label>Indicador de IE:</label>
        <Field
          name="Status_IE"
          value={Status_IE}
          component={Select}
          onChange={handleStatusIE}
        >
          <option value="">Selecione</option>
          <option value="Contribuinte de ICMS">Contribuinte de ICMS</option>
          <option value="Contribuinte Isento">Contribuinte Isento</option>
          <option value="Não Contribuinte">Não Contribuinte</option>
        </Field>
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Inscrição Municipal:"
          name="IM"
          component={Input}
          normalize={normalizeIM}
        />
      </Col>
      {company.salesRepresentative === true && (
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Representante:</label>
          <Field
            name="tradeRepresentativeId"
            value={Status_IE}
            component={Select}
          >
            <option value="">Selecione</option>
            {tradeRepresentativeOptions.map((representative) => (
              <option key={representative.id} value={representative.id}>
                {representative.companyName || representative.tradingName}
              </option>
            ))}
          </Field>
        </Col>
      )}

      {isPlanPrime && (
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Abordagem:</label>
          <Field name="approachId" value={approachId} component={Select}>
            <option value="">Selecione</option>
            {approachOptions.map((approach) => (
              <option key={approach.id} value={approach.id}>
                {approach.description}
              </option>
            ))}
          </Field>
        </Col>
      )}
      {isWorkmotor && !isPlanWorkmotorFree && (
        <>
          <Col lg={2} md={2} sm={2} xs={12}>
            <Field
              name="debtLimit"
              component={RenderField}
              label="Limite Devedor:"
              {...currencyMask}
            />
          </Col>
          {company?.companyConfig.manageInternalCredit && (
            <Col lg={2} md={2} sm={2} xs={12}>
              <Field
                name="availableCredit"
                component={RenderField}
                label="Crédito Disponível:"
                disabled
                {...currencyMask}
              />
            </Col>
          )}
          {company?.companyConfig.sellingPriceType ===
            'Preço Atacado e Varejo' && (
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>Tipo de Preço:</label>
              <Field
                name="sellingPriceType"
                value={priceType}
                component={Select}
                onChange={(e) => {
                  setPriceType(e.target.value);
                }}
              >
                <option value="Varejo">Varejo</option>
                <option value="Atacado">Atacado</option>
              </Field>
            </Col>
          )}
        </>
      )}

      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px', alignItems: 'center' }}
        className="form-client-dadosb-footer"
      >
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="Data de Cadastro:"
            disabled
            name="createdAt"
            component={Input}
          />
        </Col>
        <Col lg={6} md={6} sm={4} xs={12}>
          <label>Observações (máximo de 500 caracteres):</label>
          <Field
            name="Observation"
            component={TextArea}
            normalize={descriptionWithAltSign}
            maxLength="500"
          />
        </Col>

        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Retém ISS:</label>
          <Field name="withholdsISS" value={withholdsISS} component={Select}>
            <option value="1">Sim</option>
            <option value="2">Não</option>
          </Field>
        </Col>

        <Col
          lg={2}
          md={2}
          sm={4}
          xs={12}
          style={{
            display: 'flex',
            marginLeft: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="btn-green"
            type="submit"
            style={{
              marginTop: '30px',
            }}
          >
            Prosseguir
          </button>
        </Col>
      </Col>
    </>
  );
};

export default PJ;
