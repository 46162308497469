import React, { useState, useEffect } from 'react';
import api from 'services/api';
import config from 'config';
import { validadeEmail } from '../Validations/Validations';
import { phoneMask, onlyNumbers } from '../ToNormalize/ToNormalize';
import { Modal } from 'react-bootstrap';
import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './styles.css';

const ShareQuickQuote = (props) => {
  const [shareOption, setShareOption] = useState(0);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientSelectedNumber, setClientSelectedNumber] = useState('');
  const [clientSelectedMail, setClientSelectedMail] = useState('');
  const [link, setLink] = useState('');

  const options = [
    {
      key: 'WhatsApp',
      icon: (
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="whatsAppIcon"
          aria-hidden="false"
        />
      ),
      text: <span id="optionText">Compartilhar via WhatsApp</span>,
      onClick: () => setShareOption(1),
    },
    {
      key: 'E-mail',
      icon: (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="mailIcon"
          aria-hidden="false"
        />
      ),
      text: <span id="optionText">Compartilhar via E-mail</span>,
      onClick: () => setShareOption(2),
    },
  ];

  useEffect(() => {
    loadClientSelected(props.quickQuoteInfo);
    loadLink(props.quickQuoteInfo.id);
  }, []);

  const loadLink = (quickQuoteId) => {
    const hash = `Vend=v723dha5tHSSpS83711${quickQuoteId}5422hsGsdAQLk8423oPL31`;
    const link = `${window.location.origin}/share-sheets/quick-quote/${hash}`;
    setLink(link);
  };

  const loadClientSelected = async (quickQuoteInfo) => {
    setClientName(quickQuoteInfo.customerName);
    setClientSelectedNumber(quickQuoteInfo.customerTelephone);
    setClientSelectedMail(quickQuoteInfo.customerEmail);
  };

  const renderModalTitle = (option) => {
    switch (option) {
      case 0:
        return 'Opções de compartilhamento';
      case 1:
        return 'Compartilhar via WhatsApp';
      case 2:
        return 'Compartilhar via E-mail';
      default:
        return 'Opções de compartilhamento';
    }
  };

  const renderModalBody = (option) => {
    switch (option) {
      case 0:
        return indexBody();
      case 1:
        return whatsappOption();
      case 2:
        return emailOption();
      default:
        return indexBody();
    }
  };

  const renderModalFooter = (option) => {
    if (option === 0) return indexFooter();
    return optionFooter();
  };

  const indexFooter = () => {
    return (
      <CustomButton
        style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
        onClick={() => props.onHide()}
        type="button"
        bsStyle="danger"
        pullRight
        fill
      >
        Cancelar
      </CustomButton>
    );
  };

  const optionFooter = () => {
    return (
      <div>
        <CustomButton
          style={{
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={() => handleSubmit()}
          disabled={!!loadingSendMail}
          type="button"
          id="btn-salvar"
          pullRight
          fill
        >
          Enviar{' '}
          <span
            className={loadingSendMail ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          />
        </CustomButton>
        <CustomButton
          style={{
            marginTop: '5px',
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
          }}
          onClick={() => handleClose()}
          disabled={!!loadingSendMail}
          type="button"
          id="cancelar"
          pullRight
          fill
        >
          Cancelar
        </CustomButton>
      </div>
    );
  };

  const indexBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.key}
              className="cardOption"
              id={op.key}
              onClick={op.onClick}
            >
              <span style={{ width: '80px' }}>{op.icon}</span>
              {op.text}
            </div>
          );
        })}
      </div>
    );
  };

  const whatsappOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className="form-control foco-input"
            value={clientName}
            onChange={(event) => setClientName(event.target.value)}
          />
        </div>
        <div>
          <label>Celular</label>
          <input
            className="form-control foco-input"
            value={phoneMask(clientSelectedNumber)}
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
            onChange={(event) =>
              setClientSelectedNumber(onlyNumbers(event.target.value))
            }
          />
        </div>
      </div>
    );
  };

  const emailOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className="form-control foco-input"
            value={clientName}
            onChange={(event) => setClientName(event.target.value)}
          />
        </div>
        <div>
          <label>E-mail</label>
          <input
            className="form-control foco-input"
            value={clientSelectedMail}
            onChange={(event) =>
              setClientSelectedMail(event.target.value.trim())
            }
            onKeyDown={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          />
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    switch (shareOption) {
      case 1:
        return handleSentWhatsApp();
      case 2:
        return handleMail();
      default:
        break;
    }
  };

  const handleMail = async () => {
    if (!clientName || !clientSelectedMail) {
      return toastr.warning(
        'Preencha o Nome e Email para compartilhar o orçamento.'
      );
    }

    if (!validadeEmail(clientSelectedMail))
      return toastr.warning('E-mail inválido');

    setLoadingSendMail(true);

    try {
      await api.post(config.endpoint + `email/sendQuickQuoteToClient`, {
        Email: clientSelectedMail,
        Link: link,
        quickQuoteId: props.quickQuoteInfo.id,
        Location: window.location.origin,
        customerName: clientName,
      });

      toastr.success('E-mail enviado com sucesso');

      setShareOption(0);
      props.onSubmit();
    } catch (err) {
      return toastr.error(
        'Falha ao enviar email',
        'Identificamos uma falha ao enviar email. Tente novamente mais tarde e caso persista, acione o suporte técnico online'
      );
    } finally {
      setLoadingSendMail(false);
    }
  };

  const handleSentWhatsApp = async () => {
    if (!clientSelectedNumber) {
      return toastr.warning('Preencha o celular para compartilhar o orçamento');
    }

    setShareOption(0);
    props.onSubmit();
    console.log('Numero: ', onlyNumbers(clientSelectedNumber));

    window.open(
      config.whatsappendpoint +
        `${onlyNumbers(clientSelectedNumber)}?text=${link}&lang=pt_br`,
      '_blank'
    );
  };

  const handleClose = () => {
    setShareOption(0);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => handleClose()}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{renderModalTitle(shareOption)}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody(shareOption)}</Modal.Body>
      <Modal.Footer>{renderModalFooter(shareOption)}</Modal.Footer>
    </Modal>
  );
};

export default ShareQuickQuote;
