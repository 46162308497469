import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  phoneMask,
  cpfOrCnpjMask,
  RgMask,
  cepMask,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const TableContent = ({ totalValues, customers, reportType }) => {
  return (
    <>
      <View>
        <Text style={styles.title}>
          Listagem de Clientes -{' '}
          {reportType === 'Sintetico' ? 'Sintético' : 'Analítico'}
        </Text>
      </View>

      <View style={styles.line} />

      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por UF
        </Text>
      </View>

      <View style={styles.line} />

      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>UF</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
      </View>

      <View style={styles.line} />

      <TotalsByStatus totalValues={totalValues} />

      <View style={[styles.line]} />

      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Clientes
        </Text>
      </View>

      <View style={[styles.line]} />

      {reportType === 'Sintetico' && (
        <CustomersListSynthetic customers={customers} />
      )}

      {reportType === 'Analitico' && (
        <CustomersListAnalytic customers={customers} />
      )}
    </>
  )
}

const CustomersListAnalytic = ({ customers }) => {
  return (
    <View>
      {customers.map((c) => (
        <>
          <View style={styles.containerRow}>
            <Text style={[styles.text, { width: '55%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Nome Completo/Razão Social"
                value={c.customerCompanyName || c.customerTradingName}
              />
            </Text>

            <Text style={[styles.text, { width: '25%' }]}>
              <ItemLabel
                key={c.customerId}
                label="CPF/CNPJ"
                value={cpfOrCnpjMask(c.customerCpfCnpj)}
              />
            </Text>

            <Text style={[styles.text, { width: '20%' }]}>
              <ItemLabel
                key={c.customerId}
                label="RG/IE"
                value={c.customerRG ? RgMask(c.customerRG) : c.customerIE}
              />
            </Text>
          </View>

          <View style={[styles.containerRow, { marginTop: '5px' }]}>
            <Text style={[styles.text, { width: '30%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Data de Nascimento"
                value={format(new Date(c.customerDateBirth), 'dd/MM/yyyy')}
              />
            </Text>

            <Text style={[styles.text, { width: '25%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Data de Cadastro"
                value={format(new Date(c.customerDate), 'dd/MM/yyyy')}
              />
            </Text>

            <Text style={[styles.text, { width: '30%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Email"
                value={c.customerEmail}
              />
            </Text>

            <Text style={[styles.text, { width: '15%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Status"
                value={c.customerStatus}
              />
            </Text>
          </View>

          <View style={[styles.containerRow, { marginTop: '5px' }]}>
            <Text style={[styles.text, { width: '15%' }]}>
              <ItemLabel
                key={c.customerId}
                label="CEP"
                value={cepMask(c.customerZipcode)}
              />
            </Text>

            <Text style={[styles.text, { width: '40%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Endereço"
                value={c.customerAddress}
              />
            </Text>

            <Text style={[styles.text, { width: '10%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Nº"
                value={c.customerAddressNumber}
              />
            </Text>

            <Text style={[styles.text, { width: '30%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Bairro"
                value={c.customerNeighborhood}
              />
            </Text>
          </View>

          <View style={[styles.containerRow, { marginTop: '5px' }]}>
            <Text style={[styles.text, { width: '25%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Complemento"
                value={c.customerComplement}
              />
            </Text>

            <Text style={[styles.text, { width: '30%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Ponto de Referência"
                value={c.customerReferencePoint}
              />
            </Text>

            <Text style={[styles.text, { width: '10%' }]}>
              <ItemLabel
                key={c.customerId}
                label="UF"
                value={c.customerState}
              />
            </Text>

            <Text style={[styles.text, { width: '20%' }]}>
              <ItemLabel
                key={c.customerId}
                label="Cidade"
                value={c.customerCity}
              />
            </Text>
          </View>
          <View style={[styles.line]} />
        </>
      ))}
    </View>
  )
}

const CustomersListSynthetic = ({ customers }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '5%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>CPF/CNPJ</Text>
        <Text style={[styles.boldText, { width: '23%' }]}>
          Razão Social/Nome Completo
        </Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Telefone</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Email</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Cidade</Text>
        <Text style={[styles.boldText, { width: '3%' }]}>UF</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Dt. Cadastro</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Status</Text>
      </View>

      {customers.map((c) => (
        <View style={styles.containerRow}>
          <Text style={[styles.text, { width: '5%' }]}>{c.customerType}</Text>
          <Text style={[styles.text, { width: '12%' }]}>
            {cpfOrCnpjMask(c.customerCpfCnpj)}
          </Text>
          <Text style={[styles.text, { width: '23%' }]}>
            {c.customerCompanyName || c.customerTradingName}
          </Text>
          <Text style={[styles.text, { width: '12%' }]}>
            {phoneMask(c.customerPhone || c.customerCell)}
          </Text>
          <Text style={[styles.text, { width: '20%' }]}>{c.customerEmail}</Text>
          <Text style={[styles.text, { width: '12%' }]}>{c.customerCity}</Text>
          <Text style={[styles.text, { width: '3%' }]}>{c.customerState}</Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {format(new Date(c.customerDate), 'dd/MM/yyyy')}
          </Text>
          <Text style={[styles.text, { width: '5%' }]}>{c.customerStatus}</Text>
        </View>
      ))}
    </View>
  )
}

const TotalsByStatus = ({ totalValues }) => {
  return (
    <View>
      {totalValues.map((item) => (
        <View style={styles.containerRow}>
          <View style={{ width: '8%' }}>
            <Text style={styles.text}>{item.State}</Text>
          </View>
          <View style={{ width: '12%' }}>
            <Text style={styles.text}>{item.Status}</Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>{item.quantity}</Text>
          </View>
        </View>
      ))}
    </View>
  )
}

export default function CustomersListPDF({ responseData, reportType }) {
  return (
    <Document
      title='listagem-de-clientes'
    >
      <Page 
      orientation={reportType === 'Sintetico' ? 'landscape' : 'portrait'}
      style={styles.page}
      >
        <TableContent
          totalValues={responseData.totalValues}
          customers={responseData.customers}
          reportType={reportType}
        />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
})
