import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTable from 'react-table';

import { toastr } from 'react-redux-toastr';

import Captions from './Captions';
import SearchInput from 'components/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import LoadingModal from '../LoadingModal';

import useFilters from 'hooks/useFilters';
import { getTableColumnsByImportType } from './tableHelpers';

import AlertModal from 'components/AlertModal';
import PendingItemsModal from 'client/components/PendingItemsModal';

import productsRepository from 'repositories/Products';
import customersRepository from 'repositories/Customers';
import servicesRepository from 'repositories/Services';
import providersRepository from 'repositories/Providers';

import { getModelsColumnsByImportType } from '../ImportFromXlsModal/importHelpers';

import { useAuth } from 'contexts/auth';

const { Header, Title, Body, Footer } = Modal;

const ImportTableModal = ({
  show,
  onCancel,
  importType,
  items,
  setItems,
  closeImportModal,
  loadItems,
  loadItemsParams,
}) => {
  const [isConfirmImportModalOpen, setIsConfirmImportModalOpen] =
    useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  const { companyId } = useAuth();

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [isPendingItemsModalOpen, setIsPendingItemsModalOpen] = useState(false);
  const [importProgress, setImportProgress] = useState(false);
  const [currentImportCountLabel, setCurrentImportCountLabel] = useState(``);

  function handleFilters(item) {
    const fieldsToSearch = {
      products: [item['description'], item['manufacturerRef']],
      customers: [item['Cpf_Cnpj'], item['Trading_Name']],
      services: [item['Description']],
      providers: [item['cpfCnpj'], item['companyName']],
    };

    const querySearch = fieldsToSearch[importType];
    return filterByQuery(querySearch);
  }

  const [failedItems, setFailedItems] = useState([]);

  function checkPendingItems() {
    if (itemsNotImported?.length > 0) {
      setIsPendingItemsModalOpen(true);
    } else {
      if (failedItems?.length > 0) {
        setIsPendingItemsModalOpen(true);
      } else {
        setIsCloseModalOpen(false);
        onCancel();
        closeImportModal();
        setItems([]);
        loadItems(loadItemsParams);
      }
    }
  }

  function checkModalMessage(importType) {
    if (importType === 'products') return 'produtos';
    if (importType === 'services') return 'serviços';
    if (importType === 'customers') return 'clientes';
    if (importType === 'providers') return 'fornecedores';
  }

  function checkPlaceholder(importType) {
    if (importType === 'products')
      return 'Descrição ou Referência do Fabricante';
    if (importType === 'services') return 'Descrição';
    if (importType === 'customers') return 'CPF/CNPJ e Razão Social';
    if (importType === 'providers')
      return 'CPF/CNPJ e Nome Completo / Razão Social';
  }

  async function handleImport() {
    setIsConfirmImportModalOpen(false);
    setLoading(true);

    try {
      switch (importType) {
        case 'services':
          const servicesToImport = items.filter(
            (item) =>
              item.dataFilled === 'all' || item.dataFilled === 'required'
          );
          await servicesRepository.importServices({
            companyId,
            services: servicesToImport,
          });
          toastr.success('Importação de serviços realizadas com sucesso!');
          checkPendingItems();
          return;

        case 'products':
          const productsToImport = items.filter(
            (item) =>
              item.dataFilled === 'all' || item.dataFilled === 'required'
          );
          await productsRepository.importProducts({
            companyId,
            products: productsToImport,
          });
          toastr.success('Importação de cadastros realizadas com sucesso!');
          checkPendingItems();
          return;

        case 'providers':
          const providersToimport = items
            .filter(
              (item) =>
                item.dataFilled === 'all' || item.dataFilled === 'required'
            )
            .map((item) => {
              return {
                provider: {
                  cpfCnpj: item.cpfCnpj,
                  type: item.type,
                  companyName: item.companyName,
                  tradingName: item.tradingName,
                  email: item.email,
                  IE: item.IE,
                  statusIE: item.statusIE,
                },
                address: {
                  zipcode: item.Zipcode,
                  street: item.Address,
                  streetNumber: item.Address_Number,
                  neighborhood: item.Neighborhood,
                  complement: item.Complement,
                  referencePoint: item.Reference_Point,
                  state: item.State,
                  city: item.City,
                },
                phones: {
                  Number_Phone1: item.Number_Phone1,
                  Number_Phone2: item.Number_Phone2,
                  Type_Phone1: 'Comercial',
                  Type_Phone2: 'Celular',
                },
              };
            });
          await providersRepository.importProviders({
            companyId,
            providers: providersToimport,
          });
          toastr.success('Importação de cadastros realizadas com sucesso!');
          checkPendingItems();
          return;

        case 'customers':
          const customersToImport = items
            .filter(
              (item) =>
                item.dataFilled === 'all' || item.dataFilled === 'required'
            )
            .map((item) => {
              return {
                customer: {
                  Cpf_Cnpj: item.Cpf_Cnpj,
                  Type: item.Type,
                  Company_Name: item.Company_Name,
                  Trading_Name: item.Trading_Name,
                  Email: item.Email,
                  Date_Birth: item.Date_Birth,
                  Status_IE: item.Status_IE,
                  IE: item.IE,
                  IM: item.IM,
                  Status: 'Ativo',
                },
                principalAddress: {
                  Address: item.AddressP,
                  Address_Number: item.Address_NumberP,
                  Complement: item.ComplementP,
                  City: item.CityP,
                  Country: item.CountryP,
                  Neighborhood: item.NeighborhoodP,
                  State: item.StateP,
                  Type_Address: 'Principal',
                  Zipcode: item.ZipcodeP,
                  Reference_Point: item.Reference_PointP,
                },
                deliveryAddress: {
                  Address: item.AddressE,
                  Address_Number: item.Address_NumberE,
                  Complement: item.ComplementE,
                  City: item.CityE,
                  Country: item.CountryE,
                  Neighborhood: item.NeighborhoodE,
                  State: item.StateE,
                  Type_Address: 'Entrega',
                  Zipcode: item.ZipcodeE,
                  Reference_Point: item.Reference_PointE,
                },
                phones: {
                  Number_Phone1: item.Number_Phone1,
                  Number_Phone2: item.Number_Phone2,
                  Type_Phone1: 'Telefone',
                  Type_Phone2: 'Celular',
                },
              };
            });

          const totalItems = customersToImport.length;
          let importedItems = 0;
          let successfulImports = 0;
          let failedImports = 0;

          setCurrentImportCountLabel(`0 de ${totalItems}`);

          const itemsFailed = [];

          const importItem = async (item) => {
            try {
              await customersRepository.importCustomers({
                companyId,
                customers: [item],
              });
              successfulImports++;
            } catch (err) {
              failedImports++;
              itemsFailed.push(item); // Adiciona o item falhado à lista de itens falhados
            }
            importedItems++;
            const progress = Math.floor((importedItems / totalItems) * 100);
            setImportProgress(progress);
            setCurrentImportCountLabel(
              <div>
                {`${importedItems} de ${totalItems} (${successfulImports}
                sucesso(s) / ${failedImports} falha(s))`}
                {itemsNotImported?.length > 0 ? (
                  <div className="flex center">
                    {`${itemsNotImported?.length} com erro de layout`}
                    <br />
                    <br />
                  </div>
                ) : (
                  ``
                )}
              </div>
            );
          };

          const importInBatches = async (batchSize) => {
            for (let i = 0; i < customersToImport.length; i += batchSize) {
              const batch = customersToImport.slice(i, i + batchSize);
              await Promise.all(batch.map(importItem));
            }
          };

          const BATCH_SIZE = 10; // Define o tamanho do lote para controlar o número de requisições simultâneas
          await importInBatches(BATCH_SIZE);

          toastr.success(
            `Aviso`,
            `Importação de cadastros finalizada! ${successfulImports} sucesso(s) / ${failedImports} falha(s)`
          );

          if (failedImports > 0) {
            const formattedFailedItems = itemsFailed.map((customer) => {
              return {
                Cpf_Cnpj: customer.customer.Cpf_Cnpj,
                Type: customer.customer.Type,
                Company_Name: customer.customer.Company_Name,
                Trading_Name: customer.customer.Trading_Name,
                Number_Phone1: customer.phones.Number_Phone1,
                Number_Phone2: customer.phones.Number_Phone2,
                Date_Birth: customer.customer.Date_Birth,
                IE: customer.customer.IE,
                IM: customer.customer.IM,
                Status_IE: customer.customer.Status_IE,
                ZipcodeP: customer.principalAddress.Zipcode,
                AddressP: customer.principalAddress.Address,
                Address_NumberP: customer.principalAddress.Address_Number,
                ComplementP: customer.principalAddress.Complement,
                Reference_PointP: customer.principalAddress.Reference_Point,
                NeighborhoodP: customer.principalAddress.Neighborhood,
                CityP: customer.principalAddress.City,
                StateP: customer.principalAddress.State,
                CountryP: customer.principalAddress.Country,
                ZipcodeE: customer.deliveryAddress.Zipcode,
                AddressE: customer.deliveryAddress.Address,
                Address_NumberE: customer.deliveryAddress.Address_Number,
                ComplementE: customer.deliveryAddress.Complement,
                Reference_PointE: customer.deliveryAddress.Reference_Point,
                NeighborhoodE: customer.deliveryAddress.Neighborhood,
                CityE: customer.deliveryAddress.City,
                StateE: customer.deliveryAddress.State,
                CountryE: customer.deliveryAddress.Country,
              };
            });

            setFailedItems(formattedFailedItems);
            setIsPendingItemsModalOpen(true);
          }

          checkPendingItems();
          return;

        default:
          toastr.error(`Tipo de importação inválido.`);
      }
    } catch (err) {
      setIsDisable(true);
      toastr.error(
        `Ocorreu um erro na importação.`,
        'Por favor, atualize a pagina e verifique se cadastros foram criados com sucesso antes de reimportar'
      );
      console.log(err);
    } finally {
      setImportProgress(0);
      setCurrentImportCountLabel('');
      setLoading(false);
    }
  }

  const itemsToImportQuantity = items.filter(
    (item) => item.dataFilled === 'all' || item.dataFilled === 'required'
  ).length;

  const itemsNotImported = items.filter(
    (item) => item.dataFilled === 'doesntHaveRequired'
  );

  return (
    <>
      <Modal
        show={show}
        onHide={() => setIsCloseModalOpen(true)}
        dialogClassName="modal-80w"
      >
        <Header>
          <Title>
            <strong>Importação de Planilha</strong>
          </Title>
        </Header>
        <Body>
          <section className="upper-table">
            <div>
              <SearchInput
                style={{ width: 500 }}
                placeholder={`Pesquisa por ${checkPlaceholder(importType)}`}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="upper-table-warnings">
              <span>
                Apenas cadastros{' '}
                <FontAwesomeIcon color="#5cb85c" icon={faCheckCircle} /> e{' '}
                <FontAwesomeIcon color="#eea236" icon={faExclamationCircle} />{' '}
                serão importados
              </span>
              <span>* Campos Obrigatórios</span>
            </div>
          </section>
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            data={items.filter(handleFilters)}
            columns={getTableColumnsByImportType(importType)}
            defaultPageSize={10}
            loading={false}
            showPagination
            sortable
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[5, 10, 20, 25, 50]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Não há informação"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
          <Captions importType={importType} />
        </Body>
        <Footer>
          <section>
            <button
              className="btn btn-delete"
              onClick={() => setIsCloseModalOpen(true)}
              title="Voltar"
            >
              Voltar
            </button>
            <button
              className="btn btn-sucesso"
              disabled={isDisable}
              onClick={() => {
                if (itemsToImportQuantity > 0) {
                  setIsConfirmImportModalOpen(true);
                } else {
                  toastr.error(`Não há itens a serem importados.`);
                }
              }}
              title="Importar cadastros"
            >
              Importar cadastros
            </button>
          </section>
        </Footer>
      </Modal>
      <AlertModal
        show={isConfirmImportModalOpen}
        title="OS DIGITAL"
        subtitle={`Você tem certeza que quer importar ${itemsToImportQuantity} cadastros?`}
        message="Serão importados apenas os cadastros que possuem os campos obrigatórios preenchidos. O processo é irreversível, se houver dúvidas, verifique o cadastro e tente novamente."
        onCancel={() => setIsConfirmImportModalOpen(false)}
        onSubmit={() => handleImport()}
        onHide={() => setIsConfirmImportModalOpen(false)}
      />
      <PendingItemsModal
        show={isPendingItemsModalOpen}
        title="Aviso"
        subtitle={`Existem ${checkModalMessage(
          importType
        )} que não foram importados.`}
        message={`Existem ${checkModalMessage(
          importType
        )} que não foram importados, pois não possuem os campos obrigatórios preenchidos ou ocorreu um erro ao tentar importar. Você pode baixar a listagem e importar novamente mais tarde quando os dados forem preenchidos.`}
        importType={importType}
        onCancel={() => {
          setIsCloseModalOpen(false);
          onCancel();
          closeImportModal();
          setItems([]);
          setIsPendingItemsModalOpen(false);
          loadItems(loadItemsParams);
        }}
        onHide={() => {
          setIsCloseModalOpen(false);
          onCancel();
          closeImportModal();
          setItems([]);
          setIsPendingItemsModalOpen(false);
          loadItems(loadItemsParams);
        }}
        itemsToExport={itemsNotImported.concat(failedItems)}
        columns={getModelsColumnsByImportType(importType)}
      />
      <AlertModal
        show={isCloseModalOpen}
        message="Deseja sair da importação de cadastro?"
        onCancel={() => setIsCloseModalOpen(false)}
        onSubmit={() => {
          setIsCloseModalOpen(false);
          onCancel();
          closeImportModal();
          setItems([]);
        }}
        onHide={() => setIsCloseModalOpen(false)}
      />

      {loading ? (
        <LoadingModal
          showLoadingModal={true}
          isProgress={importType === 'customers'}
          progress={importProgress}
          importCountLabel={currentImportCountLabel}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ImportTableModal;
