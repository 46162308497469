import React from "react";
import { Grid, Row } from "react-bootstrap";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import FormNewLinkedService from "./FormNewLinkedService/index";

const NewLinkedService = () => {
  localStorage.setItem("selectedPath", "client/linked-service");

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              "Início",
              "Serviços Vinculados",
              "Adicionar Serviço Vinculado",
            ]}
            path={["home", "products", null]}
          />
          <div style={{ marginTop: 30 }}>
            <FormNewLinkedService />
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default NewLinkedService;
