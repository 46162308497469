import React from 'react'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { createNumberMask } from 'redux-form-input-masks'

import RenderField from 'components/RenderField'
import { useAuth } from '../../../../contexts/auth'
import { description } from 'client/components/ToNormalize/ToNormalize'
import Input from '../../Clients/NewClient/FormClient/components/Input'
import { percentMask } from 'utils/masks'

const marginMask = createNumberMask({
  decimalPlaces: 2,
  suffix: '%',
})

const MainData = ({ discountGroupId }) => {
  const { companyId } = useAuth()
  const dispatch = useDispatch()
  const { name, margin } = useSelector((state) => state).form.discountGroup
    .values

  return (
    <>
      <div
        className='col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body'
        id='form-service'
      >
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label='Descrição:'
            name='name'
            component={RenderField}
            normalize={(value) => description(value)}
            maxLength={50}
            required
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            {...percentMask}
            label='Margem Diferenciada:'
            name='margin'
            component={Input}
            maxLength='7'
          />
        </Col>
      </div>
    </>
  )
}

export default MainData
