import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'

export default function AlertModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={() => (props?.loading ? {} : props.onHide())}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{!props.title ? 'O.S Digital' : props.title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.subtitle && (
          <div style={{ marginBottom: '10px' }}>
            <strong>{props.subtitle}</strong>
          </div>
        )}
        {props.message}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            bsStyle='danger'
            disabled={props?.loading}
            name='Não'
            onClick={(e) => props.onCancel(e)}
            fill
          >
            Não
          </Button>
          <Button
            bsStyle='info'
            disabled={props?.loading}
            name='Sim'
            fill
            onClick={(e) => props.onSubmit(e)}
          >
            Sim{' '}
            <span
              className={props?.loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
            />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
