import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import CardForm from '../../../../../client/components/CardForm';
import FormAdditionalInfo from './SettingsAdditionalInformation';
import AdditionalInfoRepository from '../../../../../repositories/AdditionalInformation';
import { useAuth } from 'contexts/auth';
import { useModulesContext } from 'contexts/modules';

const AdditionalInfo = (isDigitalCertificateImported) => {
  useEffect(() => {
    loadOptions();
  }, []);
  const { isNFeModuleActive, isNFCeModuleActive, isNFSeModuleActive } =
    useModulesContext();
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyId } = useAuth();

  async function handleSubmit(values) {
    if (loading) return;
    let { selectedOptions, description } = values;

    setLoading(true);
    if (!selectedOptions) {
      selectedOptions = [];
    }
    const removedAsterisk = selectedOptions.filter(
      (option) => option.value !== '*'
    );
    const data = removedAsterisk.map((option) => ({ id: option.value }));

    description = !!description ? description : '';
    create(companyId, description, data);
  }
  async function create(companyId, description, data) {
    try {
      const settingsAdditionalInfo = await AdditionalInfoRepository.create({
        companyId,
        additionalInformationText: description,
        additionalInformationOptions: data,
      });

      setLoading(false);

      return toastr.success('Informações Adicionais salvas com sucesso');
    } catch (err) {
      setLoading(false);
      console.error(err);
      return toastr.error(err.response.data.message);
    }
  }

  const loadOptions = async () => {
    setLoading(false);
    try {
      const data = await AdditionalInfoRepository.getOptions();

      setLoading(false);
      return data;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar os dados.',
        'Por favor, tente novamente.'
      );
    }
  };

  const handleCertificate = () => {
    if (
      isDigitalCertificateImported &&
      (isNFeModuleActive || isNFCeModuleActive || isNFSeModuleActive)
    ) {
      setIsAdditionalInfo(!isAdditionalInfo ? true : false);
      return;
    }
    return toastr.warning(
      'Módulos fiscais não configurados',
      'Para que possa configurar as informações a serem enviadas, realize a configuração do certificado e do módulo.'
    );
  };

  const initialValues = {
    InvoiceAdditionalInformationOptions: [],
    InvoiceAdditionalInformationText: '',
  };

  return (
    <CardForm
      show={isAdditionalInfo}
      title="Informações Adicionais (Opcional)"
      onClick={handleCertificate}
    >
      <FormAdditionalInfo
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </CardForm>
  );
};

export default AdditionalInfo;
