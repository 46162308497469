export const getStatusIndicatorColor = (shortStatus) => {
  switch (true) {
    case shortStatus === 'NI' || shortStatus === 'Não Iniciado':
      return '#FFC40D';
    case shortStatus === 'E' || shortStatus === 'Em Execução':
      return '#428BCA';
    case shortStatus === 'P' || shortStatus === 'Parado':
      return '#D9534F';
    default:
      return '#5CB85C';
  }
};
