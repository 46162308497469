import React from 'react'
import { Icon } from '@material-ui/core'
import ReactSelect from 'react-select'

const SelectSearchInput = ({
  formattedOptions,
  setValue,
  value,
  isLoading,
  placeholder,
  noOptionsMessage,
  disabled,
}) => {
  return (
    <ReactSelect
      isSearchable
      defaultValue={{}}
      options={formattedOptions}
      placeholder={placeholder}
      onChange={(item) => setValue(item)}
      noOptionsMessage={noOptionsMessage}
      components={{
        DropdownIndicator: Icon,
      }}
      loadingMessage={() => 'Carregando...'}
      isDisabled={disabled}
      value={value}
      isLoading={isLoading}
      styles={{
        control: (base) => ({
          ...base,
          height: '35px',
          minHeight: '35px',
          width: '50rem',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        valueContainer: (base) => ({
          ...base,
          height: '35px',
          padding: '0 0 0 10px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
        }),
      }}
    />
  )
}

export default SelectSearchInput
