import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import customersRepository from 'repositories/Customers';
import vehiclesRepository from 'repositories/Vehicles';
import employeesRepository from 'repositories/Employees';
import '../styles.css';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import ReactSelect from 'react-select';
import { addDays, format } from 'date-fns';
import PDFButton from 'client/components/PDFButton';
import { toastr } from 'react-redux-toastr';
import SelectCustomers from 'v2/client/components/SelectCustomers';
import SelectVehicles from 'v2/client/components/SelectVehicles';
import { listAllSaleSubtypeActive } from 'v2/repositories/SalesSubtypeRepository';

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-500px', marginBottom: '2px' }}
    className="fa fa-search"
  />
);

const SalesPassingFilters = () => {
  const [searchBy, setSearchBy] = useState('CLIENT');
  const [dateType, setDateType] = useState('CREATION');
  const [formattedOptionsCustomer, setFormattedOptionsCustomer] = useState({});
  const [formattedOptionsVehicle, setFormattedOptionsVehicle] = useState({});
  const [customer, setCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [reportType, setReportType] = useState('SYNTHETIC');
  const [showObservations, setShowObservations] = useState(0);
  const [sellerId, setSellerId] = useState(null);
  const [salesStatus, setSalesStatus] = useState(5);
  const [salesSubtype, setSalesSubtype] = useState([]);
  const [salesSubtypeId, setSalesSubtypeId] = useState('1');
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyId } = useAuth();

  const openDocument = async () => {
    setIsLoading(true);
    try {
      if (!initialDate || !finalDate) {
        return toastr.warning(
          'Não foi possível gerar o relatório.',
          'Preencha todos os campos e tente novamente.'
        );
      }

      if (initialDate && finalDate) {
        const timeDifference = new Date(finalDate) - new Date(initialDate);
        const time31Days = 31 * 24 * 60 * 60 * 1000;
        if (timeDifference > time31Days) {
          return toastr.warning(
            'Período informado maior que permitido.',
            'O período informado é maior que 31 dias. Verifique o período e tente novamente.'
          );
        }
      }

      const data = await customersRepository.generateReportOfSalesByPassages(
        companyId,
        {
          initialDate,
          finalDate,
          reportType,
          searchBy,
          dateType,
          showObservations,
          sellerId,
          customerId,
          salesStatus,
          vehicleId,
        }
      );

      if (!data.length) {
        setIsLoading(false);
        return toastr.warning(
          'Relatório não gerado',
          'Nenhuma informação foi localizada. Valide os filtros e tente novamente.'
        );
      }

      setIsLoading(false);

      window.open(
        window.location.origin +
          `/report/sales-passing?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&searchBy=${searchBy}&dateType=${dateType}&showObservations=${showObservations}&sellerId=${sellerId}&salesStatus=${salesStatus}&reportType=${reportType}&customerId=${customerId}&vehicleId=${vehicleId}${
            salesSubtypeId ? `&salesSubtypeId=${salesSubtypeId}` : ''
          }`,
        '_blank'
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadSalesSubtype = async () => {
    try {
      const data = await listAllSaleSubtypeActive(companyId);
      setSalesSubtype(data);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar subtipos de venda',
        'Por favor, recarregue a página e tente novamente.'
      );
    }
  };

  function handleClearSelectCustomer() {
    setCustomer(null);
    setCustomerId(null);
  }

  function handleClearSelectVehicle() {
    setVehicle(null);
    setVehicleId(null);
  }

  const loadCustomers = async () => {
    try {
      const customers = await customersRepository.getAllActiveByCompany(
        companyId
      );

      const formattedCustomers = customers.map((c) => ({
        value: c.id,
        label: `${cpfOrCnpjMask(c.Cpf_Cnpj)} - ${
          c.Company_Name || c.Trading_Name
        }`,
      }));

      setFormattedOptionsCustomer(formattedCustomers);

      const employees = await employeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      });

      setEmployees(employees.data);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar os clientes e funcionários',
        'Por favor, tente novamente.'
      );
    }
  };

  useEffect(() => {
    if (searchBy === 'CLIENT') {
      loadCustomers();
    }
  }, [searchBy]);

  useEffect(() => {
    loadSalesSubtype();
  }, []);

  return (
    <div className="sales-passing__filters">
      <div className="sales-passing__filters-row">
        <div className="sales-passing__filters-row-item">
          <strong>Pesquisar por: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => {
              setSearchBy(e.target.value);
              {
                e.target.value === 'CLIENT'
                  ? handleClearSelectVehicle()
                  : handleClearSelectCustomer();
              }
            }}
          >
            <option value="CLIENT" selected>
              Cliente
            </option>
            <option value="VEHICLE">Veículo</option>
          </select>
        </div>
        <div
          style={{ justifyContent: 'flex-end' }}
          className="sales-passing__filters-row-item"
        >
          {searchBy === 'CLIENT' ? (
            <SelectCustomers
              onChange={(item) => {
                setCustomer(item);
                setCustomerId(item.value.customer_id);
              }}
              value={customer}
              showDefaultLabel={false}
              showVehicles={false}
              placeholder="Pesquisa por CPF/CNPJ ou Nome, Razão Social ou Nome Fantasia"
              status="ATIVO"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '55rem',
                  height: '35px',
                  minHeight: '35px',
                  marginTop: '1.5px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-7px',
                }),
              }}
            />
          ) : (
            <SelectVehicles onChange={(item) => setVehicleId(item.value)} />
          )}
        </div>
        <div className="sales-passing__filters-row-item">
          <strong>Tipo de Data: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => setDateType(e.target.value)}
          >
            <option value="CREATION">Criação</option>
            <option value="CLOSURE">Fechamento</option>
          </select>
        </div>

        <div className="sales-passing__filters-row-item">
          <strong>Data Inicial: </strong>

          <input
            type="date"
            className="form-control foco-input"
            onChange={(e) => setInitialDate(e.target.value)}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
          />
        </div>

        <div className="sales-passing__filters-row-item">
          <strong>Data Final: </strong>

          <input
            type="date"
            className="form-control foco-input"
            onChange={(e) => setFinalDate(e.target.value)}
            max={format(new Date(), 'yyyy-MM-dd')}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : null
            }
          />
        </div>
      </div>

      <div className="sales-passing__filters-row">
        <div
          style={{ width: '140px' }}
          className="sales-passing__filters-row-item"
        >
          <strong>Tipo de Relatório: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="SYNTHETIC">Sintético</option>
            <option value="ANALYTICAL">Analítico</option>
          </select>
        </div>

        <div
          style={{ width: '140px' }}
          className="sales-passing__filters-row-item"
        >
          <strong>Exibir Observações: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => setShowObservations(e.target.value)}
          >
            <option value={0}>Não</option>
            <option value={1}>Sim</option>
          </select>
        </div>

        <div
          style={{ width: '140px' }}
          className="sales-passing__filters-row-item"
        >
          <strong>Funcionários: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => setSellerId(e.target.value)}
          >
            <option value="" selected>
              Todos
            </option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name}
              </option>
            ))}
          </select>
        </div>

        <div
          style={{ width: '140px' }}
          className="sales-passing__filters-row-item"
        >
          <strong>Status da Venda: </strong>

          <select
            className="form-control foco-input"
            onChange={(e) => setSalesStatus(e.target.value)}
          >
            <option value={5}>Finalizados</option>
            <option value={3}>Em Aberto</option>
            <option value={2}>Cancelados</option>
          </select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
            marginLeft: '10px',
          }}
        >
          <strong>Subtipo:</strong>

          <select
            value={salesSubtypeId}
            onChange={(e) => setSalesSubtypeId(e.target.value)}
            className="form-control foco-input"
          >
            <option value={''}>Todos</option>
            {salesSubtype.map((subtype) => (
              <option key={subtype.id} value={subtype.id}>
                {subtype.description}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <PDFButton isLoading={isLoading} onClick={() => openDocument()} />
        </div>
      </div>
    </div>
  );
};

export default SalesPassingFilters;
