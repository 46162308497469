import React from 'react';
import { Range } from 'react-range';

import {
  Card,
  CardContent,
  CardTitle,
  ProfitProjection,
  ProfitTable,
} from '../ProfitPanel.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useTheme } from 'styled-components';
import { InputRound } from 'v2/components/Input/InputRound';

export function ProfitCard({
  icon,
  title,
  handleChangeValue,
  tableItems,
  objectName,
  showProfitProjection,
  calculateProfitProjection,
  handleChangeQuantity,
  handleChangeMargin,
  maxQuantity,
  getMinMargin,
  maxMargin,
  setInputRoundType,
  inputRoundType,

  hideCostColumn,
  hideTotalPriceColumn,
}) {
  const theme = useTheme();

  return (
    <Card>
      <CardTitle>
        <FontAwesomeIcon icon={icon} />
        {title}

        {showProfitProjection && (
          <div
            className="flex row end"
            style={{ flexGrow: 1, paddingRight: '15px' }}
          >
            <div className="flex column end">
              <ProfitProjection>
                Projeção de Lucratividade do Mês:{' '}
                <span
                  className={`total ${
                    calculateProfitProjection() < 0 ? 'red' : ''
                  }`}
                >
                  {currency(calculateProfitProjection())} <br />
                </span>
              </ProfitProjection>

              <div className="flex end">
                <span
                  style={{ fontSize: '12px' }}
                  className="flex center gap-075"
                >
                  *Passe o mouse sobre o nome colunas para mais detalhes |
                  Alteração via preenchimento?
                  <input
                    type="checkbox"
                    checked={inputRoundType}
                    onClick={(e) => {
                      setInputRoundType(e.target.checked);
                    }}
                    style={{ margin: 0 }}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </CardTitle>

      <CardContent>
        <ProfitTable>
          <thead>
            <tr>
              <th style={{ width: '230px' }} title="Grupo do Item">
                {objectName === 'servicesFamilies' ? 'Tipo' : 'Família'}
              </th>
              <th style={{ width: '100px' }} title="Preço médio de venda">
                Médio
              </th>
              <th
                style={{ width: '150px' }}
                title="Margem de contribuição média"
              >
                {objectName === 'servicesFamilies' ? 'Acréscimo' : 'Margem'}
              </th>
              <th style={{ width: '150px' }} title="Quantidade vendida">
                Qtd.
              </th>
              <th
                title={
                  hideCostColumn
                    ? ''
                    : 'Soma de todos os custos de todos os produtos'
                }
              >
                {hideCostColumn ? '-' : 'R$ Custo Total'}
              </th>
              <th
                title={`Soma do preço de venda de todos os ${
                  objectName === 'servicesFamilies' ? 'serviços' : 'produtos'
                }`}
              >
                R$ Vendido
              </th>
              <th title={'Total vendido - Custo total'}>R$ Margem</th>
            </tr>
          </thead>
          <tbody>
            {tableItems.map((item, index) => (
              <tr key={item.id}>
                <td style={{ width: '230px' }}>{item.description}</td>

                <td style={{ width: '100px' }}>
                  {currency(item.averagePrice)}
                </td>
                <td style={{ width: '150px' }}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '25px 1fr',
                      alignItems: 'center',
                      gap: '15px',
                      paddingRight: '10px',
                    }}
                  >
                    {inputRoundType ? (
                      <InputRound
                        style={{
                          fontSize: '13px',
                          color: '#333333',
                          height: '25px',
                        }}
                        buttonSize={15}
                        maxLength={10}
                        minLength={10}
                        width="100"
                        height="25"
                        type="percentage"
                        value={
                          objectName === 'servicesFamilies'
                            ? item.profitMargin - 100
                            : item.profitMargin
                        }
                        handleIncrement={() => {
                          handleChangeMargin({
                            item: item,
                            index,
                            object: objectName,
                            value:
                              objectName === 'servicesFamilies'
                                ? item.profitMargin + 1 >= 400
                                  ? 400
                                  : item.profitMargin + 1
                                : maxMargin <= item.profitMargin + 1
                                ? maxMargin
                                : item.profitMargin + 1,
                          });
                        }}
                        handleDecrement={() => {
                          handleChangeMargin({
                            item: item,
                            index,
                            object: objectName,
                            value:
                              item.profitMargin <= 0
                                ? 0
                                : item.profitMargin - 1,
                          });
                        }}
                        onChange={(values) => {
                          handleChangeMargin({
                            item: item,
                            index,
                            object: objectName,
                            value:
                              objectName === 'servicesFamilies'
                                ? values >= 400
                                  ? 400
                                  : values
                                : maxMargin <= values
                                ? maxMargin
                                : values,
                          });
                        }}
                      />
                    ) : (
                      <>
                        {objectName === 'servicesFamilies'
                          ? item.profitMargin - 100
                          : item.profitMargin}
                        %
                        <Range
                          step={1}
                          min={
                            objectName === 'servicesFamilies'
                              ? 100
                              : getMinMargin(item.originalProfitMargin)
                          }
                          max={
                            objectName === 'servicesFamilies' ? 400 : maxMargin
                          }
                          values={[item.profitMargin]}
                          onChange={(values) => {
                            handleChangeMargin({
                              item: item,
                              index,
                              object: objectName,
                              value: values[0],
                            });
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#ccc',
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '15px',
                                width: '15px',
                                borderRadius: '100%',
                                backgroundColor: theme.colors.branding1000,
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                </td>
                <td style={{ width: '150px' }}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '25px 1fr',
                      alignItems: 'center',
                      gap: '15px',
                      paddingRight: '10px',
                    }}
                  >
                    {inputRoundType ? (
                      <InputRound
                        style={{
                          fontSize: '13px',
                          color: '#333333',
                          height: '25px',
                        }}
                        buttonSize={15}
                        maxLength={10}
                        minLength={10}
                        width="100"
                        height="25"
                        value={item.quantity}
                        handleIncrement={() => {
                          handleChangeQuantity({
                            item: item,
                            index,
                            object: objectName,
                            value:
                              item.quantity < maxQuantity
                                ? item.quantity + 1
                                : maxQuantity,
                          });
                        }}
                        handleDecrement={() => {
                          handleChangeQuantity({
                            item: item,
                            index,
                            object: objectName,
                            value: item.quantity <= 0 ? 0 : item.quantity - 1,
                          });
                        }}
                        onChange={(values) => {
                          handleChangeQuantity({
                            item: item,
                            index,
                            object: objectName,
                            value: values <= maxQuantity ? values : maxQuantity,
                          });
                        }}
                      />
                    ) : (
                      <>
                        {item.quantity}
                        <Range
                          step={1}
                          min={0}
                          max={maxQuantity}
                          values={[item.quantity]}
                          onChange={(values) => {
                            handleChangeQuantity({
                              item: item,
                              index,
                              object: objectName,
                              value: values[0],
                            });
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#ccc',
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: '15px',
                                width: '15px',
                                borderRadius: '100%',
                                backgroundColor: theme.colors.branding1000,
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                </td>
                <td>{hideCostColumn ? '-' : currency(item.totalCost)}</td>
                <td>
                  {hideTotalPriceColumn ? '-' : currency(item.totalPrice)}
                </td>
                <td>{currency(item.profit)}</td>
              </tr>
            ))}
          </tbody>
        </ProfitTable>
      </CardContent>
    </Card>
  );
}
