import React, { useState } from 'react'
import ReactTable from 'react-table'


export default function ReactTableAutoPaginated({
  style,
  data,
  columns,
  defaultPageSize,
  loading,
}) {
  return (
    <ReactTable
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        ...style,
      }}
      data={data}
      columns={columns}
      defaultPageSize={defaultPageSize}
      loading={loading}
      showPagination
      sortable
      showPaginationTop={false}
      showPaginationBottom
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      previousText='Anterior'
      nextText='Próximo'
      loadingText='Carregando...'
      noDataText={!loading && "Não há informação"}
      pageText='Página'
      ofText='de'
      rowsText='linhas'
    />
  )
}
