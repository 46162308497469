import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';

import CardForm from '../../../../../client/components/CardForm';
import InactiveNFCeModule from './InactiveNFCeModule';
import FormSettingsNFCe from './SettingsNFCe';

import settingsNFCeRepository from '../../../../../repositories/SettingsNFCe';
import NFCesRepository from '../../../../../repositories/NFCes';
import companiesRepository from '../../../../../repositories/Companies';

import { useAuth } from '../../../../../contexts/auth';
import { useModulesContext } from '../../../../../contexts/modules';
import { toastr } from 'react-redux-toastr';

const SettingsNFCe = ({ fiscalDocumentType, setFiscalDocumentType }) => {
  const [isNFCeDataOpen, setIsNFCeDataOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [companyUF, setCompanyUF] = useState(null);

  const { companyId, company } = useAuth();
  const dispatch = useDispatch();

  const { isNFCeModuleActive } = useModulesContext();

  useEffect(() => {
    loadCompanyAddress();
  }, []);

  async function loadCompanyAddress() {
    const { Address } = await companiesRepository.getById(companyId);
    const companyAddress = Address[0];
    setCompanyUF(companyAddress);
  }

  async function handleSubmit(values) {
    if (loading) return;
    const {
      settingsNFCeId,
      lastNFCeSent,
      serie,
      environment,
      satCertificate,
      fiscalDocumentType,
    } = values;
    setLoading(true);

    if (!!satCertificate && !company.digitalCertificatePassword) {
      setLoading(false);
      return toastr.warning('Insira a senha do certificado');
    }

    const code = parseInt(lastNFCeSent || 0) + 1;
    const nfce = await NFCesRepository.search({
      companyId,
      code,
      serie,
      environment,
    });

    if (!!nfce) {
      setLoading(false);
      return toastr.warning(
        `Já existe uma NFC-e criada com a numeração ${code} para essa série e esse ambiente. Valide a numeração correta`
      );
    }

    if (hasNFCeInfosNotInserted(values)) {
      setLoading(false);
      return toastr.warning('Insira todos os campos de NFC-e');
    }

    if (fiscalDocumentType === 'SAT') {
      if (hasNFCSATeInfosNotInserted(values)) {
        setLoading(false);
        return toastr.warning(
          'Preencha todos os campos para salvar o cadastro de SAT'
        );
      }
    }

    if (!settingsNFCeId) {
      create(values);
    } else {
      update(values);
    }
  }

  async function create(values) {
    const {
      lastNFCeSent,
      serie,
      environment,
      CSCId,
      CSC,
      printingTemplate,
      fiscalDocumentType,
      satActivationCode,
      satCertificate,
    } = values;

    try {
      const settingsNFCe = await settingsNFCeRepository.create({
        companyId,
        lastNFCeSent: lastNFCeSent || null,
        serie: serie || null,
        environment,
        CSCId: CSCId || '',
        CSC: CSC || '',
        printingTemplate,
        fiscalDocumentType,
        satActivationCode,
      });
      const data = {
        settingsNFCeId: settingsNFCe.id,
        environment,
        CSC,
        CSCId,
        lastNFCeSent,
        serie,
        printingTemplate,
        fiscalDocumentType,
        satActivationCode,
      };
      await dispatch(initialize('settingsNFCe', data));

      if (satCertificate) await importSatCertificate(satCertificate);

      setLoading(false);
      if (fiscalDocumentType === 'SAT') {
        toastr.success(' Informações fiscais salvas com sucesso');
      } else {
        toastr.success('Configurações de NFC-e salva');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      console.error(err.response.data.message);
      toastr.error('Não foi possível salvar as configurações do de NFC-e/SAT');
    }
  }

  async function update(values) {
    const {
      settingsNFCeId,
      lastNFCeSent,
      serie,
      environment,
      CSCId,
      CSC,
      printingTemplate,
      fiscalDocumentType,
      satActivationCode,
      satCertificate,
    } = values;

    try {
      await settingsNFCeRepository.update(settingsNFCeId, {
        lastNFCeSent: lastNFCeSent || null,
        serie: serie || null,
        environment,
        CSCId: CSCId || '',
        CSC: CSC || '',
        printingTemplate,
        fiscalDocumentType,
        satActivationCode,
      });

      if (satCertificate) await importSatCertificate(satCertificate);

      dispatch(initialize('settingsNFCe', values));
      setLoading(false);
      if (fiscalDocumentType === 'SAT') {
        toastr.success('Informações fiscais salvas com sucesso');
      } else {
        toastr.success('Configurações de NFC-e atualizadas');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      console.error(err.response?.data?.message);
      toastr.error(
        'Atenção',
        `Não foi possível atualizar as configurações de NFC-e/SAT: ${err.response.data.message}`
      );
    }
  }

  async function importSatCertificate(satCertificate) {
    const formData = new FormData();

    formData.append('certificate', satCertificate);
    formData.append('certificatePassword', company.digitalCertificatePassword);
    formData.append('companyId', companyId);
    const response = await settingsNFCeRepository.importCertificateEnotas(
      formData
    );

    if (!response.status) {
      throw new Error(response.message);
    }
  }

  const hasNFCSATeInfosNotInserted = (values) => {
    const {
      CSCId,
      CSC,
      lastNFCeSent,
      serie,
      environment,
      satActivationCode,
      satCertificate,
      isSatCertificateImported,
    } = values;

    if (
      !CSCId &&
      !CSC &&
      !lastNFCeSent &&
      !serie &&
      !environment &&
      !satActivationCode &&
      !isSatCertificateImported &&
      !satCertificate
    ) {
      return false;
    }

    let fieldValuesInserted = 0;

    if (CSCId) fieldValuesInserted++;
    if (CSC) fieldValuesInserted++;
    if (lastNFCeSent) fieldValuesInserted++;
    if (serie) fieldValuesInserted++;
    if (environment) fieldValuesInserted++;
    if (satActivationCode) fieldValuesInserted++;
    if (satCertificate) fieldValuesInserted++;
    if (isSatCertificateImported) fieldValuesInserted++;

    if (fieldValuesInserted < 7) return true;

    return false;
  };

  const hasNFCeInfosNotInserted = (values) => {
    const { CSCId, CSC, lastNFCeSent, serie, environment } = values;

    if (!CSCId && !CSC && !lastNFCeSent && !serie && !environment) {
      return false;
    }

    let fieldValuesInserted = 0;

    if (CSCId) fieldValuesInserted++;
    if (CSC) fieldValuesInserted++;
    if (lastNFCeSent) fieldValuesInserted++;
    if (serie) fieldValuesInserted++;
    if (environment) fieldValuesInserted++;

    if (fieldValuesInserted < 5) return true;

    return false;
  };

  const initialValues = {
    environment: '',
    printingTemplate: 'Bobina Térmica',
  };

  return (
    <CardForm
      show={isNFCeDataOpen}
      title="Dados da NFC-e/SAT"
      onClick={() => setIsNFCeDataOpen(!isNFCeDataOpen)}
    >
      {isNFCeModuleActive ? (
        <FormSettingsNFCe
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={loading}
          companyUF={companyUF}
          fiscalDocumentType={fiscalDocumentType}
          setFiscalDocumentType={setFiscalDocumentType}
        />
      ) : (
        <InactiveNFCeModule />
      )}
    </CardForm>
  );
};

export default SettingsNFCe;
