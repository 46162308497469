import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import Button from '../../../../../client/components/CustomButton/CustomButton';
import ConfirmProductModal from './ConfirmModal';
import ImportProgressModal from './ImportProgressModal';
import PureLoader from '../../../../../components/PureLoader';

import productsRepository from 'repositories/Products';
import productBaseRepository from 'repositories/ProductBase';
import companyRepository from 'repositories/Companies';

const ProductBaseModal = ({ client, onCancel }) => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingClient, setLoadingCLient] = useState(true);

  const [clientProductsQtde, setClientProductsQtde] = useState(0);
  const [clientbaseStatus, setClientBaseStatus] = useState();

  const [carsBaseQtde, setCarsBaseQtde] = useState(0);
  const [motorcycleBaseQtde, setMotorcycleBaseQtde] = useState(0);
  const [heavyBaseQtde, setHeavyBaseQtde] = useState(0);
  const [fusaoBaseQtde, setFusaoBaseQtde] = useState(0);

  const [carsInsert, setCarsInsert] = useState(false);
  const [motoInsert, setMotoInsert] = useState(false);
  const [heavyInsert, setHeavyInsert] = useState(false);
  const [fusaoInsert, setFusaoInsert] = useState(false);

  const [totalParts, setTotalParts] = useState(0);

  const [confirmModal, setConfirmModal] = useState(false);
  const [progressModal, setProgressModal] = useState(false);

  useEffect(() => {
    loadClientData(client.id);
    loadProductBase();
  }, []);

  useEffect(() => {
    calculateParts();
  }, [carsInsert, motoInsert, heavyInsert, fusaoInsert]);

  const loadClientData = async (companyId) => {
    try {
      const { count: productsCount } =
        await productsRepository.countAllByCompany(companyId);
      setClientProductsQtde(productsCount);

      const company = await companyRepository.getById(companyId);

      if (company.productBaseInsertDate === null) {
        setClientBaseStatus(null);
      } else {
        const date = format(
          new Date(company.productBaseInsertDate),
          'dd/MM/yyyy'
        );
        setClientBaseStatus(date);
      }
    } catch (err) {
      // console.log(err)
      toastr.error('Houve um erro ao carregar os produtos do cliente.');
    } finally {
      setLoadingCLient(false);
    }
  };

  const loadProductBase = async () => {
    try {
      const { cars, motorcycles, heavy, fusao } =
        await productBaseRepository.index();

      setCarsBaseQtde(cars);
      setMotorcycleBaseQtde(motorcycles);
      setHeavyBaseQtde(heavy);
      setFusaoBaseQtde(fusao);
    } catch (err) {
      return toastr.error(
        'Houve um erro ao carregar a base de produtos, tente novamente'
      );
    } finally {
      setLoadingProducts(false);
    }
  };

  function changeStatus(type) {
    if (type === 'cars') {
      carsInsert === false ? setCarsInsert(true) : setCarsInsert(false);
    } else if (type === 'moto') {
      motoInsert === false ? setMotoInsert(true) : setMotoInsert(false);
    } else if (type === 'heavy') {
      heavyInsert === false ? setHeavyInsert(true) : setHeavyInsert(false);
    } else if (type === 'fusao') {
      fusaoInsert === false ? setFusaoInsert(true) : setFusaoInsert(false);
    } else {
      return console.log('Type not found');
    }
  }

  function calculateParts() {
    let result = 0;

    if (carsInsert === true) {
      result += carsBaseQtde;
    }
    if (motoInsert === true) {
      result += motorcycleBaseQtde;
    }
    if (heavyInsert === true) {
      result += heavyBaseQtde;
    }
    if (fusaoInsert === true) {
      result += fusaoBaseQtde;
    }

    setTotalParts(result);
  }

  return (
    <div>
      <Modal show animation onHide={onCancel}>
        {(loadingClient || loadingProducts) && (
          <PureLoader message="Carregando dados..." />
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            <strong> Base de Produtos </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Header>
          <div className="modules-container">
            <p>
              <strong>Total de produtos cadastrados:</strong>{' '}
              {clientProductsQtde}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong>Status da base de produtos:</strong>
              <div
                className="tag"
                style={{
                  backgroundColor:
                    clientbaseStatus === null ? '#d9534f' : '#5cb85c',
                  color: '#ffff',
                  width: 'max-content',
                  marginLeft: '7px',
                }}
              >
                {clientbaseStatus === null
                  ? 'Não inserido'
                  : 'importado em ' + clientbaseStatus}
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginBottom: '10px' }}>
            <p>Selecione a base de peças que deseja inserir:</p>
          </div>
          <div style={{ display: 'flex', flexFlow: 'column wrap', gap: '0' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                onChange={() => {
                  changeStatus('cars');
                }}
                disabled={loadingProducts}
                value={carsInsert}
              />
              <p style={{ padding: '14px 0 0 5px' }}>
                Base Carros ({carsBaseQtde} peças)
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                disabled={loadingProducts}
                onChange={() => {
                  changeStatus('moto');
                }}
                value={motoInsert}
              />{' '}
              <p style={{ padding: '14px 0 0 5px' }}>
                Base Motos: ({motorcycleBaseQtde} peças)
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                disabled={loadingProducts}
                onChange={() => {
                  changeStatus('heavy');
                }}
                value={heavyInsert}
              />
              <p style={{ padding: '14px 0 0 5px' }}>
                Base Pesados-Caminhões ({heavyBaseQtde} peças)
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                disabled={loadingProducts}
                onChange={() => {
                  changeStatus('fusao');
                }}
                value={heavyInsert}
              />
              <p style={{ padding: '14px 0 0 5px' }}>
                Leandro Fusão das Peças ({fusaoBaseQtde} peças)
              </p>
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <p>
              <strong>Total de peças: </strong> {totalParts}
            </p>
            <p>
              <strong>Total de produtos previstos: </strong>{' '}
              {totalParts + clientProductsQtde}
            </p>
          </div>
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <span style={{ color: '#d9534f' }}>
              A linha diesel está aplicada na base de pesados-caminhões
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="info"
              disabled={
                loadingProducts ||
                loadingClient ||
                (!carsInsert && !motoInsert && !heavyInsert && !fusaoInsert)
              }
              name="Salvar"
              fill
              onClick={() => setConfirmModal(true)}
            >
              Inserir base de produtos{' '}
              <span
                className={
                  loadingProducts ? 'fa fa-spinner fa-pulse fa-1x' : ''
                }
              />
            </Button>
            <Button bsStyle="danger" name="Calcelar" onClick={onCancel} fill>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {confirmModal && (
        <ConfirmProductModal
          onCancel={() => setConfirmModal(false)}
          onSubmit={() => {
            setConfirmModal(false);
            setProgressModal(true);
          }}
          carsInsert={carsInsert}
          cars={carsBaseQtde}
          motosInsert={motoInsert}
          motos={motorcycleBaseQtde}
          heavyInsert={heavyInsert}
          heavys={heavyBaseQtde}
          totalProducts={totalParts + clientProductsQtde}
          clientProducts={clientProductsQtde}
        />
      )}
      {progressModal && (
        <ImportProgressModal
          selectedProducts={totalParts}
          clientProducts={clientProductsQtde}
          companyId={client.id}
          cars={carsInsert}
          motorcycles={motoInsert}
          heavy={heavyInsert}
          fusao={fusaoInsert}
          finish={() =>
            setTimeout(() => {
              setProgressModal(false);
              onCancel();
            }, 960)
          }
        />
      )}
    </div>
  );
};

export default ProductBaseModal;
