import React from 'react';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import xlsColumns from './ExcelHelper';

import '../index.css';
import PDFButton from 'client/components/PDFButton';

const PartsPerApplicationMain = ({
  typeVehicles,
  setTypeVehicles,
  brandsAll,
  brandsVehiclesSelectedId,
  setBrandsVehiclesSelectedId,
  brandsVehiclesSelected,
  setBrandsVehiclesSelected,
  vehiclesModelsSelected,
  setVehiclesModelsSelected,
  vehiclesModelsAll,
  setVehiclesModelsAll,
  isLoading,
  loadProducts,
  setIsReadyToLoad,
  excelData,
  openPDF,
  setProductsType,
  productsType,
}) => {
  const handleProcessing = () => {
    setIsReadyToLoad(true);
    loadProducts();
  };

  return (
    <div className="content-filters-par-application">
      <section>
        <div className="first-line-filters">
          <div className="first-line-corretion-size-vehicles">
            <strong className="first-application-strong ">
              Tipo de Veiculo:
            </strong>
            <select
              value={typeVehicles}
              onChange={(e) => {
                setTypeVehicles(e.target.value);
                setVehiclesModelsSelected('');
                setProductsType([]);
              }}
              className="form-control foco-input"
            >
              <option value="Carro">Carro</option>
              <option value="Moto">Moto</option>
              <option value="Pesados">Pesados</option>
            </select>
          </div>
        </div>

        <div className="second-line-type-date">
          <div className="first-line-corretion-size">
            <strong className="first-application-strong ">Marca:</strong>
            <select
              value={brandsVehiclesSelectedId}
              onChange={(e) => {
                const selectedBrand = brandsAll.find(
                  (item) => item.id === Number(e.target.value)
                );

                setBrandsVehiclesSelected(selectedBrand);
                setBrandsVehiclesSelectedId(e.target.value);
                setVehiclesModelsSelected('');
                setProductsType([]);
              }}
              className="form-control foco-input"
            >
              <option value="">Selecione</option>
              {brandsAll?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.Brand}
                </option>
              ))}
            </select>
          </div>

          <div style={{ width: '600px' }}>
            <strong className="first-application-strong">Modelo:</strong>
            <Select
              options={vehiclesModelsAll}
              defaultValue={''}
              value={vehiclesModelsSelected}
              placeholder="Selecione"
              openMenuOnFocus
              noOptionsMessage={() => 'Nenhum modelo encontrado'}
              isDisabled={!brandsVehiclesSelected}
              onChange={(e) => {
                setVehiclesModelsSelected(e);
                setProductsType([]);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '35px',
                  padding: '0 0 0 5px',
                  marginTop: '-4px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-4px',
                }),
              }}
            />
          </div>
          <div>
            <button
              className="btn btn-sucesso"
              type="submit"
              disabled={isLoading || !vehiclesModelsSelected?.value}
              onClick={() => handleProcessing()}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
            >
              <span
                className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </div>

          <div>
            <DownloadXlsButton
              archiveName="Peças por Aplicação"
              data={excelData}
              className="btn btn-export "
              columns={xlsColumns}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
              disabled={!productsType?.length}
            >
              <span
                className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              <FontAwesomeIcon
                color="white"
                icon={faCloudDownloadAlt}
                style={{ marginRight: '5px' }}
              />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </div>
          <div>
            <PDFButton
              isLoading={isLoading}
              onClick={() => openPDF()}
              disabled={!productsType?.length}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartsPerApplicationMain;
