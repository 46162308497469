import React, { useEffect, useState, useMemo } from 'react';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import CollapsibleSection from 'components/CollapsibleSection';
import { useAuth } from 'contexts/auth';

import { DefaultContentContainer } from 'v2/components/layout';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputTextArea } from 'v2/components/Input';

import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { useRouter } from 'v2/hooks/useRouter';
import {
  getSalesSubtype,
  createSalesSubtype,
  updateSalesSubtype,
} from 'v2/repositories/SalesSubtypeRepository';

import {
  defaultFormValues,
  formSchema,
  getBreadcrumbData,
  validateSalesSubtypeDescriptionUse,
} from './utils';

import { FormContainer } from './FormSaleSubtype.styles';

export function FormSaleSubtype({ hideBreadCrumb, saleSubtypeIdProp }) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { id } = useParams();

  const saleSubtypeId = useMemo(() => {
    return saleSubtypeIdProp || id;
  }, [saleSubtypeIdProp, id]);

  const isEditing = !!saleSubtypeId;

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: defaultFormValues,
  });

  const isActive = useWatch({
    control,
    name: 'isActive',
  });

  const generateTitleAfterCloseSale = useWatch({
    control,
    name: 'generateTitleAfterCloseSale',
  });

  const handleChangeSalesSubtypeInput = (e) => {
    const value = formatToLettersOnly(e.target.value);

    if (value.length > 30) return;
    setValue('description', formatToLettersOnly(value));
  };

  const handleErrors = () => {
    if (Object.keys(errors).length) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios '
      );
    }
  };

  useEffect(() => {
    if (saleSubtypeId) {
      handleLoadFields();
    }
  }, [saleSubtypeId]);

  const handleCreate = async (data) => {
    try {
      await createSalesSubtype({ ...data, companyId });
      toastr.success('Cadastro realizado', 'Subtipo cadastrado com sucesso');
      navigateToClientPage('sale_subtype', 'base');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      await updateSalesSubtype(saleSubtypeId, {
        ...data,
        companyId,
      });
      toastr.success('Edição realizada', 'Subtipo alterado com sucesso');
      navigateToClientPage('sale_subtype', 'base');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    setIsLoading(true);

    if (!data.description.trim()) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente.'
      );
      setIsLoading(false);
      return;
    }

    try {
      const descriptionValid = await validateSalesSubtypeDescriptionUse(
        data.description,
        companyId,
        saleSubtypeId
      );

      if (descriptionValid) {
        return isEditing ? handleUpdate(data) : handleCreate(data);
      }

      toastr.warning(
        'Não foi possível salvar',
        'Já existe um subtipo com essa descrição. Verifique e tente novamente.'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadFields = async () => {
    setIsLoading(true);
    try {
      const res = await getSalesSubtype(saleSubtypeId, companyId);
      if (!res) throw 'not found';
      setValue('description', res.description);
      setValue('generateTitleAfterCloseSale', res.generateTitleAfterCloseSale);
      setValue('isActive', res.isActive);
      setValue('observations', res.observations);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para edição',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
      navigateToClientPage('sale_subtype', 'base');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleErrors();
  }, [errors]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados do Subtipo"
          isExpanded={true}
          handleExpand={() => null}
        >
          <form method="POST">
            <FormContainer>
              <div className="row-1">
                <InputContainer>
                  <InputLabel htmlFor="input__description" required>
                    Descrição:
                  </InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={15}
                    width="400"
                    {...register('description')}
                    onChange={handleChangeSalesSubtypeInput}
                  />
                </InputContainer>

                <InputContainer variant="column" align="center">
                  <InputLabel htmlFor="generateTitleAfterCloseSale">
                    Gerar Título ao Fechar a Venda/OS/PDV
                  </InputLabel>

                  <div className="flex center" style={{ height: '40px' }}>
                    <Toggle
                      id="generateTitleAfterCloseSale"
                      checked={generateTitleAfterCloseSale}
                      onChange={() =>
                        setValue(
                          'generateTitleAfterCloseSale',
                          !generateTitleAfterCloseSale
                        )
                      }
                    />
                  </div>
                </InputContainer>

                <InputContainer variant="column" align="center">
                  <InputLabel htmlFor="isActive">Status:</InputLabel>

                  <div className="flex center" style={{ height: '40px' }}>
                    <Toggle
                      id="isActive"
                      checked={isActive}
                      onChange={() => setValue('isActive', !isActive)}
                    />
                  </div>
                </InputContainer>
              </div>

              <div className="row-2">
                <InputContainer className="input__container">
                  <InputLabel htmlFor="input__observations">
                    Observações:
                  </InputLabel>
                  <InputTextArea
                    id="input__observations"
                    rows={4}
                    maxLength={100}
                    {...register('observations')}
                  />
                </InputContainer>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>
      </div>
      <div className="flex align-center end gap-075 mt-2">
        <button
          className="button button-red button-h35 flex center"
          style={{ width: '100px' }}
          onClick={() => navigateToClientPage('sale_subtype', 'base')}
          disabled={isLoading}
        >
          <span>Cancelar</span>
        </button>
        <button
          className="button button-green button-h35 flex center"
          type="submit"
          style={{ width: '100px' }}
          onClick={handleSubmit(submitForm)}
          disabled={isLoading}
        >
          <span className="flex center">
            {isLoading ? <span className="fa fa-spinner fa-pulse" /> : 'Salvar'}
          </span>
        </button>
      </div>
    </DefaultContentContainer>
  );
}
