import api from '../services/api'
import config from '../config'

const create = (data) => {
  return api.post(config.endpoint + 'employees', data)
}

const update = (data) => {
  return api.put(config.endpoint + `employees/${data.id}`, data)
}

const getAllByCompany = (companyId, queryParams) => {
  return api.get(config.endpoint + `employees/by-company/${companyId}`, {
    params: queryParams,
  })
}

const getById = (id) => {
  return api.get(config.endpoint + `employees/${id}`)
}

const getEmployeesThatPerformServicesOrSellProducts = (
  companyId,
  queryParams
) => {
  return api.get(config.endpoint + `employees/${companyId}/perform-services`, {
    params: queryParams,
  })
}

const getCommissionsReport = (id, queryParams) => {
  return api.get(config.endpoint + `employees/${id}/report/commission`, {
    params: queryParams,
  })
}
const generateListEmployee = (companyId, params) => {
  return api.get(`report/employee-listing-report/${companyId}`, {
    params: params,
  })
}
export default {
  create,
  update,
  getById,
  getAllByCompany,
  getEmployeesThatPerformServicesOrSellProducts,
  getCommissionsReport,
  generateListEmployee
}
