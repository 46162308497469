import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  useStyles,
  AntTabs,
  AntTab,
  StyledTabs,
  StyledTab,
} from './Tabs.style';
import { useState } from 'react';

export default function CustomizedTabs({ tabLabels, onChange, value }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={onChange} fontSize={14}>
          {tabLabels.map((label, index) => (
            <AntTab
              indicatorColor="black"
              textColor="red"
              key={index}
              label={label}
            />
          ))}
        </AntTabs>
        <Typography className={classes.padding} fontSize={14} />
      </div>
    </div>
  );
}
