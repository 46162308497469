import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const Header = ({
  reportType,
  titleStatus,
  provider,
  cashierBank,
  initialDate,
  finalDate,
  dateType,
}) => (
  <View style={{ width: '70%', flexDirection: 'row' }}>
    <View style={{ flexDirection: 'column', width: '30%' }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Relatório: </Text>
        </View>
        <View>
          <Text>{reportType === 'synthetic' ? 'Sintético' : 'Analítico'}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Data: </Text>
        </View>
        <View>
          <Text>
            {dateType === 'dueDate'
              ? 'Vencimento'
              : dateType === 'createdAt'
              ? 'Criação'
              : 'Pagamento'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '40%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Data Início: </Text>
        </View>
        <View style={{ marginRight: '5px' }}>
          <Text>
            {format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>Data Fim: </Text>
        </View>
        <View>
          <Text>
            {format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Status: </Text>
        </View>
        <View>
          <Text>
            {titleStatus === 'open'
              ? 'Aberto - Todos'
              : titleStatus === 'overdue'
              ? 'Aberto - Vencidos'
              : 'Finalizados'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '30%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Fornecedor: </Text>
        </View>
        <View>
          <Text>{provider}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Banco/Caixa: </Text>
        </View>
        <View>
          <Text>{cashierBank === '' ? 'Todos' : cashierBank}</Text>
        </View>
      </View>
    </View>
  </View>
);

const AccountsReceivableSyntheticPDF = ({
  reportType,
  titleStatus,
  provider,
  cashierBank,
  responseDate,
  dateType,
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`Relatório-de-Contas-a-Pagar${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '30%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Títulos a Pagar</Text>
          </View>

          <Header
            reportType={reportType}
            titleStatus={titleStatus}
            provider={provider}
            cashierBank={cashierBank}
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={dateType}
          />
        </View>

        <PayableValues
          totalizatorsByPeriod={responseDate.totalsByPeriod}
          titleToPay={responseDate.titleToPay}
          totals={responseDate.totals}
        />
      </Page>
    </Document>
  );
};

const PayableValuesTableHeader = () => {
  return (
    <View style={{ width: '100%' }}>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { textAlign: 'left', width: '10%' }]}>
          Vencimento
        </Text>
        <Text style={[styles.boldText, { textAlign: 'left', width: '10%' }]}>
          Qtde. de Títulos
        </Text>
        <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
          Valor Bruto
        </Text>
        <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
          Acréscimo
        </Text>
        <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
          Desconto
        </Text>
        <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
          Valor Pago
        </Text>
        <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
          Saldo a Pagar
        </Text>
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

const PayableValuesTableRows = ({ item }) => {
  return (
    <View style={{ width: '100%' }}>
      <View style={styles.containerRow}>
        <Text style={{ textAlign: 'left', width: '10%' }}>
          {format(getDateOnlyFromDate(new Date(item.Vencimento)), 'dd/MM/yyyy')}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {item.QtdeTítulos}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {currency(item.ValorBruto)}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {currency(item.Acrescimo)}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {currency(item.Desconto)}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {currency(item.ValorPago)}
        </Text>
        <Text style={{ textAlign: 'center', width: '10%' }}>
          {currency(item.SaldoPagar)}
        </Text>
      </View>
    </View>
  );
};

const PayableValuesTotal = ({ totals }) => (
  <View style={{ width: '100%', marginTop: '2px' }}>
    <View style={styles.containerRow}>
      <Text style={[styles.boldText, { textAlign: 'left', width: '10%' }]}>
        Total Geral
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {totals.titles}
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {currency(totals.grossValue)}
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {currency(totals.addedValue)}
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {currency(totals.discountValue)}
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {currency(totals.paidValue)}
      </Text>
      <Text style={[styles.boldText, { textAlign: 'center', width: '10%' }]}>
        {currency(totals.pendingValue)}
      </Text>
    </View>
    <View style={{ marginTop: '2px' }}></View>
  </View>
);

const PayableValuesWrapper = ({ titleToPay }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Listagem de Títulos
        </Text>
      </View>
      <PayableValuesTableHeader />

      {titleToPay.map((item) => (
        <PayableValuesTableRows item={item} />
      ))}
    </View>
  );
};

const TotalizatorByPeriod = ({ totalizatorsByPeriod }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Valores totais do período
        </Text>
      </View>
      <View style={[styles.line, { marginBottom: '2px' }]} />
      {totalizatorsByPeriod.rows.map((item) => (
        <View style={{ width: '100%' }}>
          <View style={styles.containerRow}>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'left',
                width: '10%',
              }}
            >
              {item.description}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {currency(item.value)}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {item.quantity}
            </Text>
          </View>
        </View>
      ))}
      <View style={[styles.line, { width: '75%', marginTop: '2px' }]} />
      <View style={{ width: '100%', marginTop: '2px' }}>
        <View style={styles.containerRow}>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'left',
              width: '10%',
            }}
          >
            Saldo a Pagar:
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'center',
              width: '10%',
            }}
          >
            {currency(totalizatorsByPeriod.balancePayable)}
          </Text>
        </View>
        <View
          style={[
            styles.line,
            { width: '50%', marginTop: '2px', marginBottom: '30px' },
          ]}
        />
      </View>
    </View>
  );
};

const PayableValues = ({ totalizatorsByPeriod, titleToPay, totals }) => {
  return (
    <View>
      <TotalizatorByPeriod totalizatorsByPeriod={totalizatorsByPeriod} />

      <PayableValuesWrapper titleToPay={titleToPay} />
      <View style={styles.line} />

      <PayableValuesTotal totals={totals} />
      <View style={styles.line} />
    </View>
  );
};
export default AccountsReceivableSyntheticPDF;

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
