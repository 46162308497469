import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'contexts/auth';
import rankingRepository from 'repositories/ProductsServicesRanking';
import { toastr } from 'react-redux-toastr';
import Button from 'client/components/CustomButton/Button.jsx';
import './styles.css';

const HomeRankingSales = () => {
  const { companyId } = useAuth();
  const [rankingType, setRankingType] = useState();
  const [type, setType] = useState();
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [loading, setLoading] = useState(false);
  const [reportSalesRanking, setReportSalesRanking] = useState([]);
  const [numberRankingSales, setNumberRankingSales] = useState(null);
  const [noDataText, setNoDataText] = useState(
    'Selecione o período e o filtros para gerar seu ranking de vendas'
  );
  const [dataToExportXls, setDataToExportXls] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const page = currentPage + 1;
  const offset = (page - 1) * pageLimit;

  useEffect(() => {
    if (initialDate || finalDate) {
      loadProductsAndServicesRanking();
    }
  }, [currentPage, pageLimit]);

  const handleLoadProductsAndServicesRankingToExport = async () => {
    const responseToExportXls =
      await rankingRepository.getProductsAndServicesRanking({
        companyId,
        initialDate,
        finalDate,
        type,
        rankingType,
      });

    const serializedDataToExportXls = responseToExportXls.data.map(
      (saleItem, index) => ({
        ...saleItem,
        numberRanking: String(offset + index + 1) + 'º',
        brand: saleItem.type === 'Produto' ? saleItem.brandDescription : '',
        soldValue: currency(saleItem.soldValue),
      })
    );

    setDataToExportXls(serializedDataToExportXls);
  };

  const loadProductsAndServicesRanking = async () => {
    try {
      if (!initialDate || !finalDate)
        return toastr.warning(
          'Selecione um período e clique em Gerar relatório'
        );

      setLoading(true);

      const response = await rankingRepository.getProductsAndServicesRanking({
        companyId,
        initialDate,
        finalDate,
        type,
        rankingType,
        limit: pageLimit,
        page: currentPage + 1,
      });

      if (!response.data.length) {
        toastr.warning('Nenhum item foi localizado no período');
        return setNoDataText('Nenhum item foi localizado no período');
      }

      const serializedProductsAndServices = response.data.map(
        (saleItem, index) => ({
          ...saleItem,
          numberRanking: String(offset + index + 1) + 'º',
          brand: saleItem.type === 'Produto' ? saleItem.brandDescription : '',
          soldValue: currency(saleItem.soldValue),
        })
      );

      setReportSalesRanking(serializedProductsAndServices);
      setTotalPages(Math.ceil(response.count[0].numberRows / pageLimit));
      setNumberRankingSales(response.count[0].numberRows);
    } catch (err) {
      console.log(err);
      return toastr.warning('Ocorreu um erro ao gerar o relatório.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="home-ranking-sales-column">
        <section className="home-ranking-sales-row">
          <fieldset>
            <span className="typography__text-2">Data Inicial:</span>
            <input
              type="date"
              className="form-control foco-input fontSize-12"
              value={initialDate}
              max={finalDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Data Final:</span>
            <input
              type="date"
              className="form-control foco-input fontSize-12"
              value={finalDate}
              min={initialDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Tipo:</span>
            <select
              className="form-control foco-input fontSize-12"
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <option value="">Ambos</option>
              <option value="Product">Produto</option>
              <option value="Service">Serviço</option>
            </select>
          </fieldset>
          <fieldset>
            <span className="typography__text-2">Ranking por:</span>
            <select
              className="form-control foco-input fontSize-12"
              onChange={(event) => setRankingType(event.target.value)}
              value={rankingType}
            >
              <option value="soldQuantity">Quantidade vendida</option>
              <option value="soldValue">Valor faturado</option>
            </select>
          </fieldset>
          <div>
            <button
              className="btn btn-sucesso button-h35 fontSize-12"
              type="submit"
              disabled={initialDate === '' || finalDate === '' || loading}
              onClick={() => {
                loadProductsAndServicesRanking();
                handleLoadProductsAndServicesRankingToExport();
              }}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Gerar relatório
            </button>
          </div>
          <DownloadXlsButton
            archiveName={`Ranking de Produtos-Serviços vendidos - ${format(
              addDays(new Date(initialDate || null), 1),
              'dd-MM-yyyy'
            )} - ${format(
              addDays(new Date(finalDate || null), 1),
              'dd-MM-yyyy'
            )}`}
            data={dataToExportXls}
            className="btn btn-export export-forecast button-h35 fontSize-12"
            style={{ gap: '10px' }}
            disabled={loading || !dataToExportXls.length}
            columns={[
              {
                name: '',
                acessor: 'numberRanking',
              },
              {
                name: 'Código Interno',
                acessor: 'code',
              },
              {
                name: 'Descrição',
                acessor: 'description',
              },
              {
                name: 'Tipo',
                acessor: 'type',
              },
              {
                name: 'Marca',
                acessor: 'brand',
              },
              {
                name: 'Quantidade vendida',
                acessor: 'soldQuantity',
              },
              {
                name: 'Valor vendido',
                acessor: 'soldValue',
              },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </section>
      </section>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={reportSalesRanking}
        columns={[
          {
            Header: '',
            accessor: 'numberRanking',
            headerClassName: 'text-left',
            width: 40,
            className: 'texto',
            fontWeight: 'bold',
          },
          {
            Header: 'Código Interno',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 130,
            className: 'texto',
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            headerClassName: 'text-left',
            className: 'texto',
          },
          {
            Header: 'Tipo',
            accessor: 'type',
            headerClassName: 'text-left',
            width: 130,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  backgroundColor:
                    props.value == 'Produto' ? '#428bca' : '#5cb85c',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Marca',
            accessor: 'brand',
            headerClassName: 'text-left',
            width: 130,
            className: 'texto',
          },
          {
            Header: 'Quantidade vendida',
            accessor: 'soldQuantity',
            headerClassName: 'text-left',
            width: 180,
            className: 'texto',
          },
          {
            Header: 'Valor vendido',
            accessor: 'soldValue',
            headerClassName: 'text-left',
            width: 120,
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        page={currentPage}
        onPageChange={(value) => setCurrentPage(value)}
        pages={totalPages}
        manual
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={noDataText}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div>
        <p
          style={{
            marginTop: '10px',
            textAlign: 'end',
          }}
        >
          Total de Itens: {numberRankingSales}
        </p>
      </div>
    </>
  );
};
export default HomeRankingSales;
