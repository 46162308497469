import React from 'react'
import { Col } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import renderField from '../../../../../components/RenderField'
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux'

const ProfileMainData = () => {

  const { isActive } = useSelector(state => state.form.formProfile.values)
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(change('formProfile', 'isActive', !isActive))
  }

  return (
    <Col>
      <Col xs={12} sm={6} md={3} lg={3} >
        <Field
          label="Descrição do Perfil de Acesso:"
          name="description"
          component={renderField}
          maxLength="50"
          required
        />
      </Col>
      <Col xs={12} sm={1} md={1} lg={1}>
        <label>Status</label>
        <div style={{ paddingLeft: '10px' }}><Toggle checked={isActive} onChange={handleToggle} /></div>
      </Col>
    </Col>
  )
}

export default ProfileMainData