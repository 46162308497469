import React from 'react';
import { InputRoundStyled, InputRoundWrapper } from './styles';
import CircleButton from 'components/CirclePlusMinusButton';
import { useTheme } from 'styled-components';

export const InputRound = React.forwardRef(
  (
    {
      width,
      height = 40,
      buttonSize = 20,
      borderColor,
      borderColorFocus,
      type = 'number',
      handleIncrement,
      handleDecrement,
      decimal = false,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <InputRoundWrapper width={width} height={height}>
        <CircleButton
          color={theme.colors.branding1000}
          type="minus"
          width={buttonSize}
          height={buttonSize}
          action={() => handleDecrement()}
        />
        {type === 'percentage' ? (
          <InputRoundStyled
            type={'text'}
            {...props}
            ref={ref}
            height={height}
            precision={!decimal ? 0 : 2}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={true}
            suffix="%"
            onChangeEvent={(e, maskedValue, floatValue) => {
              props.onChange(floatValue);
            }}
          />
        ) : (
          <InputRoundStyled
            type={'text'}
            {...props}
            ref={ref}
            height={height}
            precision={!decimal ? 0 : 2}
            allowNegative={true}
            decimalSeparator=","
            onChangeEvent={(e, maskedValue, floatValue) => {
              props.onChange(floatValue);
            }}
          />
        )}
        <CircleButton
          color={theme.colors.branding1000}
          type="plus"
          width={buttonSize}
          height={buttonSize}
          action={() => handleIncrement()}
        />
      </InputRoundWrapper>
    );
  }
);

InputRound.displayName = 'InputRound';
