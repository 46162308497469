import { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import useWebSocket from '../useWebSocket';
import { whatsappAuthenticate } from 'v2/repositories/WhatsappRepository';
// import useDebounce from 'hooks/useDebounce';
// import { useCallback } from 'react';

export function useWhatsapp() {
  const { webSocket, isConnected } = useWebSocket();
  const { company } = useAuth();
  const [whatsappConnectionStatus, setWhatsappConnectionStatus] = useState('');
  const [whatsappQrCodeBase64, setWhatsappQrCodeBase64] = useState('');
  //const [refreshInterval, setRefreshInterval] = useState(0);

  const fetchWebSocket = async () => {
    setWhatsappQrCodeBase64('');
    if (webSocket.onopen === webSocket.OPEN) {
      return;
    }
    const data = {
      type: 'identify',
      clientId: company.Cpf_Cnpj,
    };
    webSocket.send(JSON.stringify(data));
  };

  const getAuthentication = async () => {
    return await whatsappAuthenticate(company.Cpf_Cnpj);
  };

  const handleWebSocketStatus = (data) => {
    const resStatus = data.status;
    setWhatsappConnectionStatus(resStatus);

    switch (resStatus.toUpperCase()) {
      case 'AUTHENTICATED':
        return getAuthentication();
      case 'CONNECTED':
      case 'OPEN':
        return setWhatsappConnectionStatus('CONNECTED');
      case 'QRCODE':
      case 'NOTLOGGED':
      case 'QRREADERROR':
      case 'INITIALIZING':
      case 'CONNECTING':
        setWhatsappConnectionStatus('QRCODE');
        if (!!data.qrCode) {
          setWhatsappQrCodeBase64(data.qrCode);
        }
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (company && isConnected) {
      fetchWebSocket();
    }
  }, [isConnected, company]);

  useEffect(() => {
    if (isConnected && webSocket.onmessage) {
      webSocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        handleWebSocketStatus(message);
      };
    }
  }, [webSocket, isConnected]);

  return {
    whatsappConnectionStatus,
    whatsappQrCodeBase64,
  };
}
