import React from 'react'
import { Modal } from 'react-bootstrap'

import ProgressStep from './ProgressStep'

import './styles.css'

const StonePaymentProgressModal = ({ show, processing, processed }) => {
  return (
    <Modal show={show} dialogClassName='stone-payment-progress-modal'>
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>Acompanhe a transação</strong>
        <br />
        <span>Veja o status da transação enviada para a maquininha Stone!</span>
        <section className='progress-steps'>
          <ProgressStep description='Insira o cartão na máquina' active />
          <ProgressStep
            description='Processando pagamento'
            active={processing}
          />
          <ProgressStep
            description='Transação aprovada'
            active={processed}
            lastStep
          />
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default StonePaymentProgressModal
