import config from '../config'
import api from "../services/api"

const search = async params => {
  try {
    const response = await api.post(config.endpoint + `nfse/search`, params)
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async values => {
  const response = await api.post(config.endpoint + 'nfse', values)
  return response.data
}

const show = async id => {
  const response = await api.get(config.endpoint + `nfse/${id}`)
  return response.data
}

const update = async (id, values) => {
  await api.put(config.endpoint + `nfse/${id}`, values)
}

const getAllByCompany = async companyId => {
  try {
    const response = await api.get(config.endpoint + `nfse?companyId=${companyId}`)
    return response.data

  }
  catch (err) {
    throw err
  }
}

const cancel = async (id, params) => {
  await api.put(config.endpoint + `nfse/${id}/cancel`, { ...params })
}

const emit = async NFSeId => {
  const response = await api.post(config.endpoint + 'nfse/emit', {
    NFSeId
  })
  return response.data
}

const consult = async NFSeId => {
  const response = await api.post(config.endpoint + 'nfse/consult', {
    NFSeId
  })
  return response.data
}

const erase = async id => {
  await api.delete(config.endpoint + `nfse/${id}`)
}

const generate = saleId => api.post(`nfse/generate-by-sale/${saleId}`)


export default {
  search,
  create,
  getAllByCompany,
  cancel,
  show,
  emit,
  update,
  consult,
  erase,
  generate
}