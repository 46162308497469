import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

import SingleQuotationReportDocument from './SingleQuotationReportDocument'

import quotationResponsesRepository from '../../../../repositories/QuotationResponses'

const SingleQuotationReport = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [quotationReportData, setQuotationReportData] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [])

  const loadQuery = async searchParams => {
    try {
      const quotationResponseIdHash = searchParams.get('quotationResponseId')

      let quotationResponseId
      if (quotationResponseIdHash) {
        quotationResponseId = await decrypt(quotationResponseIdHash, '@OS-dig:quotationResponseId')
      }

      if (!quotationResponseId) {
        setHasError(true)
        setLoading(false)
      }

      console.log(quotationResponseId)

      return loadReport(quotationResponseId)
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (quotationResponseId) => {
    try {
      const data = await quotationResponsesRepository.generateSingleQuotationResponseReport(quotationResponseId)
      setQuotationReportData(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >   
            <SingleQuotationReportDocument 
              responseData={quotationReportData}
            />
        </PDFViewer>
      </main>
    </div>
  )
}

export default SingleQuotationReport