import React from 'react';
import WhatsappQRCodeComponent from './WhatsappQRCodeComponent';
import { useWhatsapp } from 'v2/hooks/useWhatsapp/useWhatsapp';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

export const WhatsAppModal = ({
  isOpen,
  onSuccess,
  handleOpen,
  handleClose,
}) => {
  const { whatsappConnectionStatus, whatsappQrCodeBase64 } = useWhatsapp();

  const handleConnected = () => {
    if (!!onSuccess) {
      onSuccess();
    }
    if (!!isOpen) {
      handleClose();
    }
    return;
  };

  const renderContent = () => {
    if (whatsappConnectionStatus === 'QRCODE') {
      return <WhatsappQRCodeComponent qrCodeBase64={whatsappQrCodeBase64} />;
    }
    if (whatsappConnectionStatus === 'CONNECTED') {
      return handleConnected();
    }
    return <LoadingSpinnerFullHeight />;
  };

  const handleValidateShowModal = () => {
    switch (whatsappConnectionStatus) {
      case '':
      case 'AUTHENTICATED':
        return;
      case 'CONNECTED':
        return handleClose();
      default:
        return handleOpen();
    }
  };

  useEffect(() => {
    return handleValidateShowModal();
  }, [isOpen, whatsappConnectionStatus]);

  return (
    isOpen && (
      <div>
        <Modal show={isOpen} animation dialogClassName="modal-50w">
          <Modal.Header>
            <Modal.Title className="flex center text-left">
              <strong>
                Conexão com WhatsApp <FontAwesomeIcon icon={faWhatsapp} />
              </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="flex center">
            <div style={{ minWidth: '400px' }} className="flex center ">
              {renderContent()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ gap: '2rem' }} className="flex end">
              {whatsappConnectionStatus === 'QRCODE' && (
                <div
                  style={{
                    color: 'red',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <strong>
                    Após a leitura, é necessário aguardar a sincronização de
                    todas as conversas.
                  </strong>
                  <strong>Esse processo poderá demorar alguns minutos.</strong>
                </div>
              )}
              <button
                className="button button-h35 button-red"
                onClick={() => handleClose()}
              >
                Voltar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};
