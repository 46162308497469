import React, { useState, useEffect } from 'react';

import { reduxForm, change, Field, initialize } from 'redux-form';
import { withRouter } from 'react-router';
import { Col } from 'react-bootstrap';
import RenderField from '../../../../../components/RenderField';
import FormVehicleByLicensePlate from '../../../../components/Cadastros/Vehicle/MainData';
import { useDispatch } from 'react-redux';
import CardForm from 'client/components/CardForm';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';
import Select from 'client/views/Clients/NewClient/FormClient/components/Select';
import { listApproach } from 'v2/repositories/ApproachRepository';
import { toastr } from 'react-redux-toastr';
import { usePlanSignatureContext } from 'contexts/plan-signature';

let FormClientByLicensePlate = () => {
  const [type, setType] = useState('Fisica');
  const dispatch = useDispatch();
  const [selectedModel, setSelectedModel] = useState(null);

  const { companyId } = useAuth();
  const { isWorkmotor, isPlanWorkmotorFree } = usePlanSignatureContext();

  const [approachOptions, setApproachOptions] = useState([]);

  const loadApproach = async () => {
    try {
      const response = await listApproach({
        companyId,
        isActive: true,
        limit: 10000,
        page: 1,
      });

      setApproachOptions(response.rows);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Abordagens',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  useEffect(() => {
    dispatch(change('vehicle', 'type', type));
  }, [type]);

  useEffect(() => {
    loadApproach();
  }, []);

  return (
    <form
      onSubmit={false}
      style={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm title="Dados do Veículo">
        <FormVehicleByLicensePlate
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      </CardForm>
      <CardForm title="Dados do Cliente">
        <Col lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label>Tipo de Pessoa:</label>
              <select
                className="form-control foco-input"
                value={type}
                onChange={(event) => setType(event.target.value)}
                style={{ width: '200px' }}
              >
                {' '}
                <option value="Fisica">Física</option>
                <option value="Juridica">Jurídica</option>
              </select>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label>
                {type === 'Fisica' ? 'Nome Completo:' : 'Razão Social:'}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                component={RenderField}
                name="name"
                style={{ width: '400px' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
              }}
            >
              <label>
                Telefone Celular:<span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                component={RenderField}
                name="phone"
                style={{ width: '200px' }}
                normalize={phoneMask}
              />
            </div>
            {isWorkmotor && !isPlanWorkmotorFree && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px',
                }}
              >
                <label>Abordagem:</label>
                <Field
                  component={Select}
                  name="approachId"
                  style={{ width: '200px' }}
                >
                  <option value="">Selecione</option>
                  {approachOptions.map((approach) => (
                    <option key={approach.id} value={approach.id}>
                      {approach.description}
                    </option>
                  ))}
                </Field>
              </div>
            )}
            {type === 'Juridica' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px',
                }}
              >
                <label>
                  Responsável:<span style={{ color: 'red' }}>*</span>
                </label>
                <Field
                  component={RenderField}
                  name="responsible"
                  style={{ width: '200px' }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
      </CardForm>
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'vehicle',
})(withRouter(FormClientByLicensePlate));
