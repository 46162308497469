import api from '../services/api';

export const index = async () => {
  const { data } = await api.get('terms-of-use');
  return data;
};

const findByBranding = async (brandingId) => {
  const response = await api.get('terms-of-use/brandingid/' + brandingId, {
    params: {
      brandingId,
    },
  });
  return response.data;
};

const TermsOfUseRepository = {
  index,
  findByBranding,
};

export default TermsOfUseRepository;
