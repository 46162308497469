import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { change, initialize } from "redux-form";
import { useDispatch } from "react-redux";

import Form from "./FormNewIntermediary";

import {
  cnpjMask,
  onlyNumbers,
} from "../../../../components/ToNormalize/ToNormalize";
import { validateCnpj } from "../../../../components/Validations/Validations";
import intermediaryRepository from "../../../../../repositories/Intermediary";
import constants from "../../../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/auth";

const initialValues = {
  isActive: true,
};

const FormNewIntermediary = ({ onCancel, onSubmit, intermediaryId }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!!intermediaryId) {
      loadIntermediary();
    }
  }, [intermediaryId]);

  const { companyId } = useAuth();

  async function loadIntermediary() {
    setLoading(true);
    try {
      const { data } = await intermediaryRepository.getById(intermediaryId);
      dispatch(
        initialize("formIntermediary", {
          ...data,
          cpfCnpj: cnpjMask(data.cpfCnpj),
          isActive: data.isActive === 1,
        })
      );
    } catch (err) {
      toastr.error("Ocorreu um problema ao carregador intermediador");
    } finally {
      setLoading(false);
    }
  }

  const validations = (values) => {
    const { cpfCnpj, description } = values;

    if (!description || !cpfCnpj) {
      toastr.warning(
        "Por favor, preencha todos os campos obrigatórios para salvar o seu cadastro de intermediador."
      );

      return false;
    }

    if (!validateCnpj(onlyNumbers(cpfCnpj))) {
      toastr.warning("CNPJ inválido");
      return false;
    }

    return true;
  };

  const submit = (values) => {
    setLoading(true);
    if (!validations(values)) {
      setLoading(false);
      return;
    }
    !!intermediaryId ? update(values) : create(values);
  };

  const create = async (values) => {
    try {
      await intermediaryRepository.create({
        ...values,
        cpfCnpj: onlyNumbers(values.cpfCnpj),
        companyId,
      });

      toastr.success("Intermediador cadastrado com sucesso.");
      onSubmit()
    } catch (err) {
      console.log(err);
      return toastr.warning(
        err.response?.data?.message ??
          "Ocorreu um erro ao registrar intermediador. Por favor, tente novamente"
      );
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    try {
      await intermediaryRepository.update(intermediaryId, {
        ...values,
        cpfCnpj: onlyNumbers(values.cpfCnpj),
        companyId,
      });

      toastr.success("Intermediador atualizado com sucesso.");
      onSubmit()
    } catch (err) {
      console.log(err);
      return toastr.warning(
        "Ocorreu um erro ao atualizar intermediador. Por favor, tente novamente"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        loading={loading}
        onCancel={onCancel}
        intermediaryId={intermediaryId}
      />
    </div>
  );
};

export default FormNewIntermediary;
