import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import constants from '../../../utils/constants'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NewsMain from './components/NewsMain'

function News() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'News']}
            path={[constants.ROUTES.HOME, null]}
          />
          <NewsMain />
        </Row>
      </Grid>
    </div>
  )
}

export default News
