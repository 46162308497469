import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import MateraAccountForm from './Form'

const MateraAccount = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Integrações',
          'Conta Digital - Matera',
          match.params.id ? 'Editar Conta' : 'Nova Conta',
        ]}
        path={['home', null, 'matera', null]}
      />
      <MateraAccountForm
        materaId={match.params.id}
        goToHomepage={() => history.push('matera')}
      />
    </>
  )
}

export default withRouter(MateraAccount)
