
import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { isValid } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import PurchasesRepository from '../../../../../repositories/Purchases'
import ProvidersRepository from '../../../../../repositories/Providers'

import './styles.css'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-600px', marginBottom: '2px' }}
    className="fa fa-search"
  />
)

const ListPurchasesSupplier = () => {
  const [loading, setLoading] = useState(false)
  const [finalDate, setFinalDate] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [providerId, setProviderId] = useState('')
  const [providersOptions, setProvidersOptions] = useState([])
  const [selectedProvider, setSelectedProvider] = useState(null)
  const { companyId } = useAuth()


  const handleSubmit = async () => {
    if (validations()) await handleProcess()
  }

  const validations = () => {

    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate))
    ) {
      toastr.warning('Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente')
      return false
    }

    return true
  }



  const handleProcess = async () => {
    setLoading(true)
    try {
      const data = await PurchasesRepository.generatePurchasesSupplier({
        companyId,
        initialDate,
        finalDate,
        providerId: selectedProvider ? selectedProvider.value.id : ''
      })

      if (data?.report.purchases.length <= 0) {
        toastr.warning('Nenhuma informação para o relatório foi encontrada.')
        return
      } else {
        handlePrint()
      }
    } catch (err) {
      console.log(err)
      toastr.error(err?.response?.data?.message || 'Ocorreu um erro com a buscar. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    window.open(window.location.origin + `/report/list-purchases-supplier?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&providerId=${selectedProvider ? selectedProvider.value.id : ''}`, '_blank')

  }

  const loadProvidersOptions = async () => {
    try {
      const data = await ProvidersRepository.getAllActiveByCompany(companyId)

      setProvidersOptions(
        data.map((provider) => ({
          label: `${cpfOrCnpjMask(provider.cpfCnpj)} - ${provider.companyName}` || `${cpfOrCnpjMask(provider.cpfCnpj)} - ${provider.tradingName}`,
          value: provider,
        }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os fornecedores',
        'Tente novamente! Caso persista, entre em contato com o suporte.'
      )
    }
  }

  function handleSelectProvider(provider) {
    setSelectedProvider(provider)
  }

  function handleClearSelectProvider() {
    setSelectedProvider(null)
  }

  useEffect(() => {
    loadProvidersOptions()
  }, [])

  return (
    <div className='content-supplier-purchases'>
      <div className='content-return-purchases-date '>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={e => setInitialDate(e.target.value)}
        />
      </div>

      <div className='content-supplier-purchases-all '>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={e => setFinalDate(e.target.value)}
        />
      </div>
      <div
        className='content-supplier-purchases-all '
        style={{
          width: '600px',
        }}
      >
        <label>
          Fornecedor:
        </label>

        <Select
          placeholder={
            'Pesquisar por CPF/CNPJ, Razão Social, Nome ou Nome Fantasia'
          }
          onFocus={handleClearSelectProvider}
          filterOption={(provider, input) => {
            return (
              provider.data.value.cpfCnpj?.includes(input) ||
              provider.data.value.tradingName?.includes(input) ||
              provider.data.value.companyName?.includes(input) ||
              provider.data.label.includes(input)
            )

          }}
          options={providersOptions}
          value={selectedProvider}
          components={{
            DropdownIndicator: Icon,
          }}
          styles={{
            control: (base) => ({
              ...base,
              height: '30px',
              minHeight: '40px',
              width: '600px'

            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (base) => ({
              ...base,
              height: '40px',
              padding: '0 0 0 10px',
              marginTop: '-4px',
              left: '15px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              marginTop: '-4px',
            }),
          }}
          onChange={(e) => {
            handleSelectProvider(e)
          }}
          noOptionsMessage={() => 'Nenhum fornecedor encontrado'}
        />


        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',

        }}>
          <span>
            <p style={{ fontSize: 12, color: '#d43f3a' }}>
              Deixe em branco para buscar todos os fornecedores
            </p>
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'flex-end',
          margin: '15px',
          flexDirection: 'column',
          marginTop: '40px'
        }}
      >
        <button
          className="btn btn-sucesso"
          onClick={handleSubmit}
          disabled={loading}>
          <span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} /> Processar</button>
      </div>

    </div>
  )
}
export default ListPurchasesSupplier