import React, { useEffect, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router'

import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import salesRepository from 'repositories/Sales'
import productsRepository from 'repositories/Products'
import { useAuth } from '../../../contexts/auth'

import PurchaseSuggestionSheet from './PurchaseSuggestionSheet'
import { isAfter, isBefore, subDays } from 'date-fns'

const SuggestionSheet = ({ location }) => {
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])

  const { companyId } = useAuth()

  useEffect(() => {
    if (!!companyId) {
      loadProductsMinStock()
    }
  }, [companyId])

  async function loadProductsMinStock() {
    try {
      setLoading(true)
      const { sales } = await salesRepository.getAllByCompany(companyId, {
        limit: 10000,
      })

      const { data: products } =
        await productsRepository.getProductsUnderMinStock(companyId)

      const filteredProducts = await Promise.all(
        products.map(async (product) => {
          let weekSales = await getSalesQuantity(product, sales, 7)
          let monthSales = await getSalesQuantity(product, sales, 30)

          return {
            id: product.id,
            description: product.Description,
            stockQuantity: product.Stock_Quantity,
            minStock: product.minStock,
            maxStock: product.maxStock,
            weekSales,
            monthSales,
          }
        })
      )
      return setProducts(filteredProducts)
    } catch (err) {
      setHasError(true)
      toastr.warning(
        'Ocorreu um erro ao carregar sugestão de compra. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function getSalesQuantity({ id }, sales, period) {
    try {
      let weekSalesItems = sales
        .filter((sale) =>
          isAfter(new Date(sale.Date), subDays(new Date(), period))
        )
        .map((sale) => sale.SalesItems)
        .flat()
        .filter((saleItem) => saleItem.Product_id === id)

      let weekSalesItemsQuantity = weekSalesItems
        .map((sale) => sale.Quantity)
        .reduce((acc, curr) => {
          return acc + curr
        }, 0)

      return weekSalesItemsQuantity
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar sugestão de compra. Por favor, tente novamente'
      )
    }
  }

  if (hasError) return <AppError message='TESTE' />
  if (loading) return <AppLoading />

  return (
    <div id='sale-printing-sheet-page'>
      <main
        style={{
          height: '100vh',
          width: '100vw',
        }}
      >
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <PurchaseSuggestionSheet products={products} />
        </PDFViewer>
      </main>
    </div>
  )
}

export default withRouter(SuggestionSheet)
