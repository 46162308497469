import React, { useState, useEffect } from 'react'
import api from 'services/api'
import config from 'config'
import { phoneMask, onlyNumbers } from '../ToNormalize/ToNormalize'
import { Modal } from 'react-bootstrap'
import CustomButton from 'client/components/CustomButton/CustomButton.jsx'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastr } from 'react-redux-toastr'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import './styles.css'

const ShareQRCodeModal = (props) => {
  const [shareOption, setShareOption] = useState(1)
  const [clientName, setClientName] = useState(props.clientName)
  const [clientSelectedNumber, setClientSelectedNumber] = useState('')

  const options = [
    {
      key: 'WhatsApp',
      icon: (
        <FontAwesomeIcon
          icon={faWhatsapp}
          className='whatsAppIcon'
          aria-hidden='false'
        />
      ),
      text: <span id='optionText'>Compartilhar via WhatsApp</span>,
      onClick: () => setShareOption(1),
    },
    {
      key: 'E-mail',
      icon: (
        <FontAwesomeIcon
          icon={faEnvelope}
          className='mailIcon'
          aria-hidden='false'
        />
      ),
      text: <span id='optionText'>Compartilhar via E-mail</span>,
      onClick: () => setShareOption(2),
    },
  ]

  function onHide() {
    props.setShow(false)
  }

  const renderModalTitle = (option) => {
    switch (option) {
      case 0:
        return 'Opções de compartilhamento'
      case 1:
        return 'Compartilhar via WhatsApp'
      case 2:
        return 'Compartilhar via E-mail'
      default:
        return 'Opções de compartilhamento'
    }
  }

  const renderModalBody = (option) => {
    switch (option) {
      case 0:
        return indexBody()
      case 1:
        return whatsappOption()
      default:
        return indexBody()
    }
  }

  const renderModalFooter = (option) => {
    if (option === 0) return indexFooter()
    return optionFooter()
  }

  const indexFooter = () => {
    return (
      <CustomButton
        style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
        onClick={() => onHide()}
        type='button'
        bsStyle='danger'
        pullRight
        fill
      >
        Cancelar
      </CustomButton>
    )
  }

  const optionFooter = () => {
    return (
      <div>
        <CustomButton
          style={{
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={() => handleSubmit()}
          type='button'
          id='btn-salvar'
          pullRight
          fill
        >
          Enviar{' '}
        </CustomButton>
        <CustomButton
          style={{
            marginTop: '5px',
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
          }}
          onClick={() => handleClose()}
          type='button'
          id='cancelar'
          pullRight
          fill
        >
          Cancelar
        </CustomButton>
      </div>
    )
  }

  const indexBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.key}
              className='cardOption'
              id={op.key}
              onClick={op.onClick}
            >
              <span style={{ width: '80px' }}>{op.icon}</span>
              {op.text}
            </div>
          )
        })}
      </div>
    )
  }

  const whatsappOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className='form-control foco-input'
            value={clientName}
            onChange={(event) => setClientName(event.target.value)}
            disabled
          />
        </div>
        <div>
          <label>Celular</label>
          <input
            className='form-control foco-input'
            value={phoneMask(clientSelectedNumber)}
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
            onChange={(event) =>
              setClientSelectedNumber(onlyNumbers(event.target.value))
            }
          />
        </div>
      </div>
    )
  }

  const handleSubmit = async () => {
    switch (shareOption) {
      case 1:
        return handleSentWhatsApp()
      default:
        break
    }
  }

  const handleSentWhatsApp = async () => {
    if (!clientSelectedNumber) {
      return toastr.warning('Preencha o celular para compartilhar o PIX')
    }

    if (
      onlyNumbers(clientSelectedNumber).length !== 10 &&
      onlyNumbers(clientSelectedNumber).length !== 11
    ) {
      return toastr.warning('Informe um número de celular válido')
    }

    window.open(
      config.whatsappendpoint +
        `${onlyNumbers(clientSelectedNumber)}?text=${props.pixCode}&lang=pt_br`,
      '_blank'
    )

    handleClose()
  }

  const handleClose = () => {
    setShareOption(0)
    onHide()
  }

  return (
    <Modal
      show={props.show}
      onHide={() => handleClose()}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{renderModalTitle(shareOption)}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody(shareOption)}</Modal.Body>
      <Modal.Footer>{renderModalFooter(shareOption)}</Modal.Footer>
    </Modal>
  )
}

export default ShareQRCodeModal
