import { format } from 'date-fns'
import React from 'react'
import ReactTable from 'react-table'

function SuccessReportTable({ loading, reportData }) {
  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: 550,
        }}
        data={reportData}
        columns={[
          {
            Header: 'Funcionalidade',
            accessor: 'functionality',
            width: 250,
          },
          {
            Header: 'Quantidade',
            accessor: 'count',
            width: 100,
          },
          {
            Header: 'Último Registro',
            accessor: 'lastRecord',
            width: 200,
            Cell: (props) =>
              props.value !== null
                ? format(new Date(props.value), 'dd/MM/yyyy ')
                : '',
          },
        ]}
        loading={loading}
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        showPagination={false}
        showPaginationTop={false}
        showPaginationBottom={false}
        showPageSizeOptions={false}
        minRows={14}
      />
    </>
  )
}

export default SuccessReportTable
