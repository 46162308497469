import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import xlsx from 'xlsx';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUploadAlt,
  faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';

import { jsonFromXlsxArrayOfRows, serializeItems } from './importHelpers';

import './styles.css';

const XlsDropzone = ({ importType, items, setItems }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);

      reader.onabort = () =>
        toastr.warning('A importação do .xlsx foi abortada');
      reader.onerror = () =>
        toastr.warning(
          'Ocorreu um erro ao importar arquivo .xlsx. Por favor, tente novamente'
        );
      reader.onload = (e) => {
        var bstr = e.target.result;
        var workbook = xlsx.read(bstr, { type: rABS ? 'binary' : 'array' });
        var sheetNameList = workbook.SheetNames[0];
        var arrayOfRowsFromExcel = xlsx.utils.sheet_to_json(
          workbook.Sheets[sheetNameList],
          {
            raw: false,
            dateNF: 'DD-MM-YYYY',
            header: 1,
            defval: '',
          }
        );

        const xlsxJson = jsonFromXlsxArrayOfRows(arrayOfRowsFromExcel);
        const serializedItems = serializeItems(xlsxJson, importType);

        if (serializedItems) setItems(serializedItems);
      };
    });
  }, []);

  const onDropRejected = (filesRejections) => {
    const error = filesRejections[0].errors[0].message;

    if (error === 'Too many files') {
      toastr.warning('Insira somente um único arquivo .xls');
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      onDrop,
      onDropRejected,
      multiple: false,
      noClick: true,
      noKeyboard: true,
    });

  const dragActive = {
    borderColor: '#04b604',
  };

  const dragReject = {
    borderColor: '#e57878',
  };

  const renderMessage = () => {
    if (!isDragActive) {
      return (
        <>
          <span>Arraste e solte o arquivo .XLSX</span>
          <span>
            ou{' '}
            <button
              style={{
                border: 'none',
                background: '#fff',
                color: '#4089A2',
                cursor: 'pointer',
              }}
              onClick={open}
            >
              selecione o arquivo de seu computador
            </button>
          </span>
        </>
      );
    }

    if (isDragReject) {
      return <span type="error">Arquivo não suportado</span>;
    }

    return <span type="success">Solte os arquivos aqui</span>;
  };

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          ...(isDragActive ? dragActive : null),
          ...(isDragReject ? dragReject : null),
        }}
        className="xlsx-page-import"
      >
        <input {...getInputProps()} />
        <div className="xml-import-dropzone-content">
          <FontAwesomeIcon
            style={{
              height: '80px',
              width: '80px',
              color: items.length ? '#5cb85c' : '#c0c0c0',
              marginBottom: 5,
            }}
            icon={items.length ? faCheckDouble : faCloudUploadAlt}
          />
          {renderMessage(isDragActive, isDragReject)}
        </div>
      </div>
    </>
  );
};

export default XlsDropzone;
