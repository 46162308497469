import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import { useAuth } from 'contexts/auth'
import trayCompanyRepository from 'repositories/TrayCompany'

import TrayForm from './TrayForm'

const Tray = () => {
  const [trayCompany, setTrayCompany] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const {
    companyId,
    company: { Cpf_Cnpj },
  } = useAuth()

  useEffect(() => {
    loadTrayInformation()
  }, [])

  const loadTrayInformation = async () => {
    try {
      const { data: trayCompany } = await trayCompanyRepository.showCompany(
        companyId
      )

      setTrayCompany(trayCompany)
      dispatch(initialize('tray', trayCompany))
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit = async (values) => {
    const { trayPlanId, code, trayUrl } = values

    if (!trayPlanId) {
      return toastr.warning(
        'Selecione o seu plano contratado e tente novamente.'
      )
    }
    if (!code || !trayUrl) {
      return toastr.warning(
        'Insira as informações obrigatórias para prosseguir'
      )
    }
    const { duplicatedCode, duplicatedUrl } =
      await trayCompanyRepository.validateTrayCompanyData({
        code,
        companyId,
        trayUrl,
      })

    if (duplicatedCode)
      return toastr.warning('Este token já foi cadastrado em outra conta')

    if (duplicatedUrl)
      return toastr.warning('Esta URL já foi cadastrado em outra conta')

    setLoading(true)

    if (trayCompany) return update(values)
    create(values)
  }

  const create = async (values) => {
    const { code, trayPlanId, trayUrl } = values

    try {
      const {
        data: { trayCompany },
      } = await trayCompanyRepository.create({
        code,
        trayUrl,
        trayPlanId,
        companyId,
        cpfCnpj: Cpf_Cnpj,
      })

      toastr.success('Integração Tray salva com sucesso')

      setTrayCompany(trayCompany)
    } catch (err) {
      toastr.error('Ocorreu um erro ao salvar informações da Tray')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const update = async (values) => {
    const { code, trayPlanId, trayUrl } = values

    try {
      await trayCompanyRepository.update(companyId, {
        trayUrl,
        code,
        trayPlanId,
        companyId,
        cpfCnpj: Cpf_Cnpj,
      })

      toastr.success('Integração Tray atualizada com sucesso')

      loadTrayInformation()
    } catch (err) {
      toastr.error('Ocorreu um erro ao atualizar informações da Tray')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TrayForm
      onSubmit={handleSubmit}
      initialValues={{ trayTokenStatus: null }}
      loading={loading}
    />
  )
}

export default Tray
