import React from 'react'
import { Col } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import Select from '../../../../../client/views/Clients/NewClient/FormClient/components/Select'
import Input from '../../../../../client/views/Clients/NewClient/FormClient/components/Input'
import Button from '../../../../../client/components/CustomButton/CustomButton.jsx'

import {
  onlyNumbers,
  maxLength,
} from '../../../../../client/components/ToNormalize/ToNormalize'

const FormSettingsNFe = ({ loading, handleSubmit, pristine }) => {
  const { settingsNFeId } = useSelector(
    (state) => state.form.settingsNFe.values
  )

  return (
    <div className='company-tax-data-page'>
      <Col lg={2} md={2} sm={3} xs={12}>
        <label>Ambiente de Envio</label>
        <Field name='environment' component={Select}>
          <option value=''>Selecione</option>
          <option value='Homologação'>Homologação</option>
          <option value='Produção'>Produção</option>
        </Field>
      </Col>
      <Col lg={2} md={2} sm={1} xs={12}>
        <Field
          label='Última NF-e Enviada'
          name='lastNFeSent'
          component={Input}
          normalize={(value) => maxLength(onlyNumbers(value), 10)}
        />
      </Col>
      <Col lg={1} md={1} sm={1} xs={12}>
        <Field
          label='Série'
          name='serie'
          component={Input}
          normalize={(value) => maxLength(onlyNumbers(value), 999)}
        />
      </Col>
      <Col lg={2} md={2} sm={2} xs={12}>
        <label>Modelo de impressão</label>
        <Field name='printingTemplate' component={Select}>
          <option value='Retrato'>Retrato</option>
          <option value='Paisagem'>Paisagem</option>
        </Field>
      </Col>

      <Button bsStyle='info' fill onClick={handleSubmit} disabled={loading}>
        <span
          className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          style={{ marginRight: loading ? '5px' : '' }}
        />
        {!!settingsNFeId ? 'Atualizar' : 'Salvar'}
      </Button>
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'settingsNFe',
})(FormSettingsNFe)
