import styled from 'styled-components';

export const PanelAppointmentTabSelector = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
`;

export const PanelAppointmentTabTitle = styled.div`
  width: 200px;
  height: 30px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #c0c0c0;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
`;

export const PanelAppointmentModalHeader = styled.div`
  & div p {
    font-size: 14px;
  }
`;

export const PanelAppointmentTabContentWrapper = styled.div`
  border: 1px solid #c0c0c0;
  padding: 10px;
  margin-top: 0;
`;

export const ServiceStatusLabel = styled.span`
  width: 90%;
  display: block;
  padding: 2px;
  border-radius: 3px;
  color: #fff;
  background-color: ${({ bg }) => bg};
  -webkit-box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);
`;
