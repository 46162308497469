const xlsColumns = [
  {
    name: 'Vencimento',
    acessor: 'payDate',
  },
  {
    name: 'Qtde. de Títulos',
    acessor: 'amountsTitles',
  },
  {
    name: 'Juros Previsto',
    acessor: 'expectedInterest',
  },
  {
    name: 'Valor Pago',
    acessor: 'amountPaid',
  },
  {
    name: 'Saldo a Receber',
    acessor: 'balanceReceive',
  },
]

export default xlsColumns
