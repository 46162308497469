import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BankConciliationDetailsSearchContent } from './BankConciliationDetailsSearch.styles';

export function SearchDetailsFile({ optionsTransactionsType, prefix }) {
  const { register, setValue } = useFormContext();
  const [selectedTipo, setSelectedTipo] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const { watch } = useFormContext();
  const isViewing = watch('isViewing');

  const statusOptions = [
    {
      id: 1,
      label: 'Ambos',
    },
    {
      id: 2,
      label: 'Conciliado',
    },
    {
      id: 3,
      label: 'Não Conciliado',
    },
  ];


  return (
    <BankConciliationDetailsSearchContent>
      <div className="box-input search" style={{ marginLeft: '0px' }}>
        <i
          id="iconepesquisar"
          style={{ cursor: 'pointer' }}
          className="fa fa-search"
        />
        <input
          type="text"
          style={{ paddingLeft: '9%' }}
          className="form-control"
          placeholder="Pesquisar por Descrição e Valor"
          disabled={isViewing}
          {...register(`search.${prefix}.description`)}
        />
      </div>

      <div className="box-input label-input">
        <strong /* htmlFor={`search.${prefix}.transactionType`} */>
          Tipo:
        </strong>
        <select
          id={`${prefix}.transactionType`}
          name={`${prefix}.transactionType`}
          className="form-control foco-input"
          value={selectedTipo}
          disabled={isViewing}
          onChange={(e) => {
            setSelectedTipo(e.target.value);
            setValue(`search.${prefix}.transactionType`, e.target.value);
          }}
        >
          <option value="">Ambos</option>
          {optionsTransactionsType?.map(({ id, label }) => (
            <option
              key={id}
              value={label}
              style={{ textTransform: 'uppercase' }}
            >
              {label}
            </option>
          ))}
        </select>
      </div>

      <div className="box-input">
        <strong   style={{ width: '100%' }}>
          Status:
        </strong>
        <select
          id="status"
          name="status"
          className="form-control foco-input"
          value={selectedStatus}
          disabled={isViewing}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            setValue(`search.${prefix}.transactionStatus`, e.target.value);
          }}
        >
          {statusOptions?.map(({ id, label }) => (
            <option key={id} value={label}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="box-input">
        <strong style={{ width: '100%' }}>
          Data Inicial:
        </strong>
        <input
          type="date"
          id="dataInicial"
          name="dataInicial"
          disabled={isViewing}
          className="form-control foco-input"
          {...register(`search.${prefix}.TransactionInitialDate`)}
        />
      </div>
      <div className="box-input">
        <strong style={{ width: '100%' }}>
          Data Final:
        </strong>
        <input
          type="date"
          id="dataFinal"
          name="dataFinal"
          disabled={isViewing}
          className="form-control foco-input"
          {...register(`search.${prefix}.TransactionFinishDate`)}
        />
      </div>
    </BankConciliationDetailsSearchContent>
  );
}
