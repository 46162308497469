import api from '../services/api'
import config from '../config'

const index = async (queryParams) =>
  api.get(config.endpoint + `linked-services`, { params: queryParams })

const create = (data) => {
  return api.post(config.endpoint + 'linked-services', data)
}

const remove = async (linkedServiceId) => {
  return api.delete(config.endpoint + `linked-services/${linkedServiceId}`)
}

export default {
  index,
  create,
  remove,
}
