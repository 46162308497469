import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import HomeCashierBank from './CashierBank'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const CashierBank = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastro', 'Financeiro', 'Banco/Caixa']}
            path={['home', null, null, null]}
          />
          <HomeCashierBank />
        </Row>
      </Grid>
    </div>
  )
}
export default CashierBank