import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import {
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const Header = ({ initialDate, finalDate, dateType }) => {
  return (
    <View style={{ marginTop: 10 }}>
      <View style={styles.containerRow}>
        <Text style={styles.strong}>Tipo de Data: </Text>
        <Text>{dateType}</Text>
      </View>

      <View style={styles.containerRow}>
        <View style={{ width: '7%' }}>
          <Text style={styles.strong}>Data Início: </Text>
        </View>
        <View style={{ width: '8%' }}>
          <Text>{initialDate}</Text>
        </View>

        <View style={{ width: '6%' }}>
          <Text style={styles.strong}>Data Fim: </Text>
        </View>
        <View style={{ width: '8%' }}>
          <Text>{finalDate}</Text>
        </View>
      </View>

      <View style={[styles.line, { marginTop: 5 }]} />
    </View>
  );
};

const TableHead = ({ showMarginAndProfit }) => (
  <View style={{ paddingTop: 2 }}>
    <View style={[styles.line, { marginTop: 1 }]} />
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View
        style={{ width: '2%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Tipo</Text>
      </View>
      <View
        style={{
          width: '20%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Descrição</Text>
      </View>
      <View
        style={{
          width: '13.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Marca</Text>
      </View>
      <View
        style={{
          width: '2%',
          textAlign: 'center',
          justifyContent: 'center',
          marginLeft: '4px',
        }}
      >
        <Text style={styles.text}>Qtd</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Vlr Unit.</Text>
      </View>
      <View
        style={{ width: '8%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Desc (%)</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Total</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>Qtd. Devolv.</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.text}>R$ Devol.</Text>
      </View>
      {showMarginAndProfit && showMarginAndProfit === 'true' ? (
        <>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>Custo</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>Margem</Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.text}>Lucro</Text>
          </View>
        </>
      ) : (
        <></>
      )}
    </View>

    <View style={[styles.line, { marginTop: 1 }]} />
  </View>
);

const SyntheticSalesDetails = ({ data }) => {
  return (
    <View>
      <View>
        <Text style={styles.titleTexts}>Data</Text>
      </View>
      <View style={styles.line} />

      <View style={styles.containerRow}>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Venda</Text>
        </View>
        <View style={{ width: '7%' }}>
          <Text style={styles.titleTexts}>Tipo</Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={styles.titleTexts}>Nome</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Nº da NF</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Peças</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Serviços</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Desconto Geral</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Total Venda</Text>
        </View>
      </View>

      <View style={styles.line} />

      <View style={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(data).map((key, index) => {
          const totalProducts = data[key].reduce(
            (acc, curr) => acc + curr.salesProductValue,
            0
          );
          const totalServices = data[key].reduce(
            (acc, curr) => acc + curr.salesServiceValue,
            0
          );
          const totalDiscount = data[key].reduce(
            (acc, curr) => acc + curr.salesDiscountValue,
            0
          );
          const totalSale = data[key].reduce(
            (acc, curr) => acc + curr.salesFinalValue,
            0
          );

          return (
            <View style={{ marginBottom: 10 }}>
              <View>
                <Text style={[styles.strong]}>
                  {format(getDateOnlyFromDate(new Date(key)), 'dd/MM/yyyy')}
                </Text>
              </View>
              {data[key].map((item) => (
                <>
                  <View style={[styles.containerRow]} key={index}>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {item.salesCode} {item.salesSubtype}
                      </Text>
                    </View>
                    <View style={{ width: '7%' }}>
                      <Text style={styles.text}>
                        {item.salesType === 'Ordem de Serviço'
                          ? 'OS'
                          : item.salesType}
                      </Text>
                    </View>
                    <View style={{ width: '25%' }}>
                      <Text style={styles.text}>
                        {item.customerCompanyName}
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>{item.taxCodeNFe}</Text>
                      <Text style={styles.text}>{item.taxCodeNFCe}</Text>
                      <Text style={styles.text}>{item.taxCodeNFSe}</Text>
                      <Text style={styles.text}>{item.taxCodeSAT}</Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {currency(item.salesProductValue)}
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {currency(item.salesServiceValue)}
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {currency(item.salesDiscountValue)}
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {currency(item.salesFinalValue)}
                      </Text>
                    </View>
                  </View>
                </>
              ))}
              <View style={[styles.containerRow, { width: '100%' }]}>
                <View style={{ width: '42%' }} />
                <Text style={[styles.strong, { width: '10%' }]}>
                  Total por dia:
                </Text>
                <Text style={[styles.strong, { width: '10%' }]}>
                  {currency(totalProducts)}
                </Text>
                <Text style={[styles.strong, { width: '10%' }]}>
                  {currency(totalServices)}
                </Text>
                <Text style={[styles.strong, { width: '10%' }]}>
                  {currency(totalDiscount)}
                </Text>
                <Text style={[styles.strong, { width: '10%' }]}>
                  {currency(totalSale)}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.line} />
    </View>
  );
};

const AnalyticalSalesDetails = ({
  item,
  showPaymentDetails,
  showMarginAndProfit,
}) => {
  return (
    <View>
      <View>
        <View style={styles.line} />

        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '16%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Data Criação: </Text>
              {format(new Date(item.createdAt), 'dd/MM/yyyy')}
            </Text>
          </View>
          <View style={{ width: '19%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Data Fechamento: </Text>
              {format(new Date(item.updatedAt), 'dd/MM/yyyy')}
            </Text>
          </View>

          <View style={{ width: '30%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Cliente: </Text>
              {item.Customer.Company_Name}
            </Text>
          </View>

          <View style={{ width: '15%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Venda/OS: </Text>
              {item.Code} {item.salesSubtype}
            </Text>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '15%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Valor Total:</Text>
              {currency(item.Final_Value)}
            </Text>
          </View>
          <View style={{ width: '17%' }}>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Desconto Geral: </Text>
              {currency(item.Discount_Value)}
            </Text>
          </View>
          <View>
            <Text style={styles.text}>
              <Text style={styles.titleTexts}>Veículo: </Text>
              {item.Vehicle
                ? `${item.Vehicle.Model} (${item.Vehicle.License_Plate})`
                : '--'}
            </Text>
          </View>
        </View>
      </View>

      <TableHead showMarginAndProfit={showMarginAndProfit} />

      {item.SalesItems.map((saleItem) => {
        return (
          <SalesItems
            item={saleItem}
            key={saleItem.id}
            showMarginAndProfit={showMarginAndProfit}
          />
        );
      })}

      {showPaymentDetails === 'true' && (
        <View
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <View style={[styles.line, { width: '81%' }]} />
          <View style={{ width: '80%', marginTop: '2px' }}>
            <View style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
              <View
                style={{ display: 'flex', flexDirection: 'row', width: '35%' }}
              >
                <Text style={[styles.strong, { fontSize: '8px' }]}>
                  Condição de pagamento:{' '}
                </Text>
                <Text style={{ fontSize: '8px' }}>
                  {item.Payments?.CondPayment?.Description}
                </Text>
              </View>
              <View style={{ width: '16%', alignItems: 'center' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  Vencimento
                </Text>
              </View>
              <View style={{ width: '23%', alignItems: 'center' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  Forma de Pagamento
                </Text>
              </View>
              <View
                style={{
                  width: '18%',
                  alignItems: 'flex-start',
                  paddingLeft: '10px',
                }}
              >
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  Valor
                </Text>
              </View>
              <View style={{ width: '3%', alignItems: 'right' }}>
                <Text style={[styles.strong, , { fontSize: '8px' }]}>
                  Pago?
                </Text>
              </View>
            </View>

            {item.BillsToReceive.map((bill) => {
              return (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                  key={bill.id}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '35%',
                    }}
                  />
                  <View style={{ width: '16%', alignItems: 'center' }}>
                    <Text style={{ fontSize: '8px' }}>
                      {format(
                        getDateOnlyFromDate(new Date(bill?.dueDate)),
                        'dd/MM/yyyy'
                      )}
                    </Text>
                  </View>
                  <View style={{ width: '23%', alignItems: 'center' }}>
                    <Text style={[{ fontSize: '8px', textAlign: 'center' }]}>
                      {bill?.BillsToReceiveParcels
                        ? bill?.BillsToReceiveParcels[0]?.FormOfPayment
                            ?.TypeOfPayment
                        : '-'}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '18%',
                      alignItems: 'flex-start',
                      paddingLeft: '10px',
                    }}
                  >
                    <Text style={[{ fontSize: '8px' }]}>
                      {currency(parseFloat(bill.liquidValue))}
                    </Text>
                  </View>
                  <View style={{ width: '3%', alignItems: 'right' }}>
                    <Text style={{ fontSize: '8px', textAlign: 'right' }}>
                      {bill.BillStatus?.id === 2 ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}

      <View style={styles.line} />
      <View style={{ marginBottom: '25px' }} />
    </View>
  );
};

const SalesItems = ({ item, showMarginAndProfit }) => {
  return (
    <View
      wrap={false}
      style={{
        flexDirection: 'row',
        height: 12,
        width: '100%',
      }}
    >
      <View
        style={{ width: '2%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.content}>{!item.Products ? 'S' : 'P'}</Text>
      </View>
      <View
        style={{
          width: '20%',
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Text style={{ ...styles.content }}>{item.Description}</Text>
      </View>
      <View
        style={{
          width: '13.5%',
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Text style={styles.content}>
          {item.Products !== null ? item.Products?.Brands?.Description : '-'}
        </Text>
      </View>
      <View
        style={{
          width: '2%',
          textAlign: 'center',
          justifyContent: 'center',
          marginLeft: '4px',
        }}
      >
        <Text style={styles.content}>{item.Quantity}</Text>
      </View>
      <View
        style={{
          width: '15%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.content}>{currency(item.Unit_Value)}</Text>
      </View>
      <View
        style={{ width: '8%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.content}>{percentage(item.Discount_Value)}</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.content}>{currency(item.Amount)}</Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.content}>
          {item.QuantityItemsReturned > 0 ? item.QuantityItemsReturned : '0'}
        </Text>
      </View>
      <View
        style={{
          width: '12.5%',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={styles.content}>
          {item.QuantityItemsReturned > 0
            ? currency(item.Unit_Value * ((100 - item.Discount_Value) / 100))
            : 'R$ 0,00'}
        </Text>
      </View>
      {showMarginAndProfit && showMarginAndProfit === 'true' ? (
        <>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.content}>
              {!item.Products ? '' : currency(item.Value_Cost * item.Quantity)}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.content}>
              {!item.Products
                ? ''
                : percentage((item.Unit_Value / item.Value_Cost - 1) * 100)}
            </Text>
          </View>
          <View
            style={{
              width: '10%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.content}>
              {!item.Products
                ? ''
                : currency(item.Amount - item.Value_Cost * item.Quantity)}
            </Text>
          </View>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

const ResumeHeader = ({ resumeData }) => {
  return (
    <>
      <View>
        <Text style={styles.subtitle}>Resumo de Vendas</Text>
      </View>
      <View style={[styles.line]} />

      {resumeData.revenuePerPeriodTypes.map((resume) => {
        return (
          <View key={resume.id} style={{ flexDirection: 'row' }}>
            <View style={{ width: '20%' }}>
              <Text style={styles.strong}>Quantidade de {resume.type}:</Text>
            </View>
            <View style={{ width: '15%' }}>
              <Text style={styles.content}>{resume.quantity}</Text>
            </View>
          </View>
        );
      })}

      <View style={[styles.containerRow, { marginTop: 10 }]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Total Peças: </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.totalProductValue)}
          </Text>
        </View>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Total Serviços: </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.totalServiceValue)}
          </Text>
        </View>
      </View>

      <View style={styles.containerRow}>
        <View style={{ width: '15%' }} />
        <Text style={styles.content}>--------------------------------</Text>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Sub-Total: </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.subTotalValue)}
          </Text>
        </View>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Total Desconto Geral: </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.totalDiscountValue)}
          </Text>
        </View>
      </View>

      <View style={styles.containerRow}>
        <View style={{ width: '15%' }} />
        <Text style={styles.content}>--------------------------------</Text>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Total Vendido (Lucro Bruto): </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.totalGrossProfit)}
          </Text>
        </View>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>Total Custo: </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(resumeData.revenuePerPeriodSum.totalValueCost)}
          </Text>
        </View>
      </View>

      <View style={styles.containerRow}>
        <View style={{ width: '15%' }} />
        <Text style={styles.content}>--------------------------------</Text>
      </View>

      <View style={[styles.containerRow]}>
        <View style={{ width: '18%' }}>
          <Text style={styles.strong}>
            Lucro Líquido: <span style={{ color: 'red' }}>*</span>
          </Text>
        </View>
        <View style={{ width: '8%', textAlign: 'right' }}>
          <Text style={styles.content}>
            {currency(
              resumeData.revenuePerPeriodSum.totalGrossProfit -
                resumeData.revenuePerPeriodSum.totalValueCost
            )}
          </Text>
        </View>
      </View>

      <View style={[styles.containerRow, { justifyContent: 'flex-end' }]}>
        <View style={{ width: '40%' }}>
          <Text style={{ fontSize: '8px', fontWeight: 'normal', color: 'red' }}>
            * Esse relatório não leva em consideração comissão e impostos,
            apenas vendas e custos. <br />
            ** Esse totalizador indica apenas as vendas do subtipo vendas.
            Outros subtipos não serão contabilizados.
          </Text>
        </View>
      </View>

      <View style={[styles.line]} />
    </>
  );
};

export const RevenuesPerPeriodDocument = ({
  data,
  initialDate,
  finalDate,
  dateType,
  showPaymentDetails,
  showMarginAndProfit,
  reportType,
  showResume,
  resumeData,
}) => {
  return (
    <Document
      title={`faturamento-por-periodo${format(new Date(), 'dd/MM/yyyy')}`}
      subject="faturamento"
    >
      <Page style={styles.page} orientation="landscape">
        <View>
          <Text style={styles.title}>
            Faturamento Por Período -{' '}
            {reportType === 'SYNTHETIC' ? 'Sintético' : 'Analítico'}
          </Text>
        </View>
        <Header
          data={data}
          initialDate={initialDate}
          finalDate={finalDate}
          dateType={dateType === 'CLOSING' ? 'Fechamento' : 'Criação'}
        />

        {showResume === 'true' ? (
          <ResumeHeader resumeData={resumeData} />
        ) : (
          <View />
        )}

        <View>
          <Text style={styles.subtitle}>Listagem de Vendas</Text>
        </View>

        {reportType === 'SYNTHETIC' ? (
          <>
            <View style={[styles.line]} />

            <SyntheticSalesDetails data={data} />
          </>
        ) : (
          <View />
        )}

        {reportType === 'ANALYTICAL' ? (
          data.map((item) => {
            return (
              <>
                <AnalyticalSalesDetails
                  key={item.id}
                  item={item}
                  showPaymentDetails={showPaymentDetails}
                  showMarginAndProfit={showMarginAndProfit}
                />
              </>
            );
          })
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  content: {
    fontSize: 10,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  debitsText: {
    fontSize: 10,
    color: '#FF0000',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
