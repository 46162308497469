import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench, faUser } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

import sobreRodasLogo from 'assets/img/sobre-rodas-logo.png';

import environmentConfig from 'config';

import '../index.css';
import LoadingSpinnerFullHeight from '../../components/LoadingSpinnerFullHeight';
import MaintenanceStatus from '../../components/MaintenanceStatus';
import { useBranding } from 'hooks/useBranding';

export const ExternalWorkshopTable = ({ maintenance, isLoading }) => {
  const customer =
    maintenance?.Sales?.Customer?.Company_Name ||
    maintenance?.Sales?.Customer?.Trading_Name;

  const handleOpenSaleDocument = () => {
    const hash = `Vend=v723dha5tHSSpS83711${maintenance?.saleId}5422hsGsdAQLk8423oPL31`;

    const link = `${window.location.origin}/share-sheets/sale/${hash}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <WorkshopTable
        maintenance={maintenance}
        isLoading={isLoading}
        handleOpenSaleDocument={handleOpenSaleDocument}
      />
      <MobileTable
        maintenance={maintenance}
        customer={customer}
        handleOpenSaleDocument={handleOpenSaleDocument}
        isLoading={isLoading}
      />
    </>
  );
};

// web

const WorkshopTable = ({ maintenance, isLoading, handleOpenSaleDocument }) => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  const textColorByBrand = isWorkmotor
    ? 'wm-text-primary'
    : isTecnomotor
    ? 'tecnomotor-text-primary'
    : isBarros
    ? 'barros-text-primary'
    : isPO360
    ? 'po360-text-primary'
    : '';
  return (
    <div className="workshop-panel-frame__external">
      <div className="workshop-panel-frame-content__external">
        <div className="workshop-panel-maintenances__external">
          {isLoading ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <table>
              <thead>
                <tr>
                  <th className={`${textColorByBrand}`} style={{ width: '5%' }}>
                    OS
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '25%' }}
                  >
                    Cliente
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '15%', textAlign: 'center' }}
                  >
                    Mecânicos
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '25%' }}
                  >
                    Reparos
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Início
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Status
                  </th>
                  <th
                    className={`${textColorByBrand}`}
                    style={{ width: '10%', textAlign: 'center' }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                <MaintenanceTableRow
                  maintenance={maintenance}
                  handleOpenSaleDocument={handleOpenSaleDocument}
                  textColorByBrand={textColorByBrand}
                />
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className={`workshop-panel-last-update ${textColorByBrand}`}>
        Atualizado em {format(new Date(), 'dd/MM/yyyy')} -{' '}
        {format(new Date(), 'HH:mm')}
      </div>
    </div>
  );
};

const MaintenanceTableRow = ({
  maintenance,
  handleOpenSaleDocument,
  textColorByBrand,
}) => {
  const getBackgroundColorByStatus = (status) => {
    const colors = {
      'Em Andamento': '#5cb85c',
      'Não Iniciado': '#ffc40d',
      Parado: '#d9534f',
      Finalizado: '#5bc0de',
    };

    return colors[status];
  };

  return (
    <tr>
      <td style={{ width: '5%' }}>
        <span>{maintenance?.Sales?.Code}</span>
      </td>
      <td style={{ width: '25%' }}>
        <p>
          {maintenance?.Sales?.Customer?.Trading_Name ||
            maintenance?.Sales?.Customer?.Company_Name}
        </p>
        <p>
          <strong>{`${
            maintenance?.Sales?.Vehicle?.License_Plate
              ? maintenance?.Sales?.Vehicle?.License_Plate
              : maintenance?.Sales?.Vehicle?.Maintenance_Object
          } - ${maintenance?.Sales?.Vehicle?.Model}`}</strong>
        </p>
      </td>
      <td style={{ width: '15%', textAlign: 'center' }}>
        <div
          style={{
            display: 'block',
            marginBottom: '10px',
          }}
        >
          {maintenance?.MaintenanceEmployees?.map((item) => (
            <EmployeeTableCard key={item.id} name={item.Employee.name} />
          ))}
        </div>
      </td>
      <td style={{ width: '25%' }}>
        <ul>
          {maintenance?.Sales?.SalesItems?.map((item) => (
            <li key={item.id}>{item.Description}</li>
          ))}
        </ul>
      </td>
      <td style={{ width: '10%' }}>
        <p style={{ fontSize: '11px', textAlign: 'center' }}>
          {maintenance?.startDate &&
            format(new Date(maintenance?.startDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </td>
      <td style={{ width: '10%', textAlign: 'center' }}>
        <div className="external-workshop-panel__table__maintenance-status">
          <button
            className="external-workshop-panel__table__status-button"
            style={{
              backgroundColor: getBackgroundColorByStatus(maintenance?.status),
            }}
          >
            {/* status da manutenção */}
            {maintenance?.status}
          </button>
          <span className="external-workshop-panel__table__elapsed-time">
            {/* Tempo decorrido */}
            Tempo decorrido: {maintenance?.elapsedTime}
          </span>
        </div>
        <br />
      </td>
      <td style={{ width: '10%', textAlign: 'center' }}>
        <div className="external-workshop-panel__table__services-info-visualize-OS--mobile">
          <a
            className={`${textColorByBrand}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenSaleDocument()}
          >
            Visualizar OS
          </a>
        </div>{' '}
      </td>
    </tr>
  );
};

const EmployeeTableCard = ({ name }) => {
  const splitedName = name.split(' ');

  return (
    <div className="employee-table-card">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{`${splitedName[0]}`}</span>
    </div>
  );
};

// mobile

const MobileTable = ({
  maintenance,
  customer,
  handleOpenSaleDocument,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <LoadingSpinnerFullHeight />
      ) : (
        <div>
          <div
            style={{
              width: '100%',
            }}
            className="external-workshop-panel__table"
          >
            <div>
              <BasicInfo
                saleCode={maintenance?.Sales?.Code}
                vehicle={maintenance?.Sales?.Vehicle}
                customer={customer}
              />
              <StatusInfo
                startDate={maintenance?.startDate}
                elapsedTime={maintenance?.elapsedTime}
                status={maintenance?.status}
              />
              <ServicesInfo
                salesItems={maintenance?.Sales?.SalesItems}
                handleOpenSaleDocument={handleOpenSaleDocument}
              />
              <EmployeesInfo employees={maintenance?.MaintenanceEmployees} />
              <UpdatedAtInfo />
            </div>
          </div>
          <SobreRodasLink />
        </div>
      )}
    </>
  );
};

const BasicInfo = ({ saleCode, vehicle, customer }) => {
  return (
    <>
      <div className="external-workshop-panel__table__basic-info--mobile">
        <strong className="external-workshop-panel__table__title">OS</strong>
        <strong className="external-workshop-panel__table__title">
          Cliente
        </strong>
      </div>
      <div className="external-workshop-panel__table__basic-info-content--mobile">
        <span
          className="external-workshop-panel__table__title"
          style={{
            alignSelf: 'center',
          }}
        >
          {saleCode}
        </span>
        <div
          className="external-workshop-panel__table__text"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Customer Name */}
          <span>{customer}</span>
          {/* Vehicle Info */}
          <strong>
            {vehicle?.License_Plate || vehicle?.Maintenance_Object} -{' '}
            {vehicle?.Model}
          </strong>
        </div>
      </div>
    </>
  );
};

const StatusInfo = ({ startDate, elapsedTime, status }) => {
  const getBackgroundColorByStatus = (status) => {
    const colors = {
      'Em Andamento': '#5cb85c',
      'Não Iniciado': '#ffc40d',
      Parado: '#d9534f',
      Finalizado: '#5bc0de',
    };

    return colors[status];
  };

  return (
    <>
      <div
        className="external-workshop-panel__table__status-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Início
        </strong>
        <strong
          className="external-workshop-panel__table__title"
          style={{
            justifySelf: 'center',
          }}
        >
          Status
        </strong>
      </div>
      <div className="external-workshop-panel__table__status-info-content--mobile">
        <span
          style={{
            alignSelf: 'center',
          }}
        >
          {/* início da manutenção */}
          {startDate && format(new Date(startDate), 'dd/MM/yyyy HH:mm')}
        </span>
        <div className="external-workshop-panel__table__maintenance-status">
          <button
            className="external-workshop-panel__table__status-button"
            style={{
              backgroundColor: getBackgroundColorByStatus(status),
            }}
          >
            {/* status da manutenção */}
            {status}
          </button>
          <span className="external-workshop-panel__table__elapsed-time">
            {/* Tempo decorrido */}
            Tempo decorrido: {elapsedTime}
          </span>
        </div>
      </div>
    </>
  );
};

const ServicesInfo = ({ salesItems, handleOpenSaleDocument }) => {
  return (
    <>
      <div
        className="external-workshop-panel__table__services-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Reparos
        </strong>
      </div>
      <div>
        <ul className="external-workshop-panel__table__services-info-list--mobile">
          {salesItems?.map((item) => (
            <li key={item?.Code}>
              <span>{item?.Description}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="external-workshop-panel__table__services-info-visualize-OS--mobile">
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenSaleDocument()}
        >
          Visualizar OS
        </a>
      </div>
    </>
  );
};

const UpdatedAtInfo = () => {
  return (
    <>
      <div className="external-workshop-panel__table__updatedAtInfo">
        <span>
          Atualizado em {format(new Date(), 'dd/MM/yyyy')} -{' '}
          {format(new Date(), 'HH:mm')}
        </span>
      </div>
    </>
  );
};

// employees

const EmployeesInfo = ({ employees }) => {
  return (
    <>
      <div
        className="external-workshop-panel__table__services-info--mobile"
        style={{
          marginTop: '1.5rem',
        }}
      >
        <strong className="external-workshop-panel__table__title">
          Mecânicos
        </strong>
      </div>
      <div className="external-workshop-panel__table__employee-card-list--mobile">
        {employees?.map((employee) => (
          <EmployeeCard
            key={employee?.id}
            employee={employee?.Employee?.name}
          />
        ))}
      </div>
    </>
  );
};

const EmployeeCard = ({ employee }) => {
  return (
    <div className="external-workshop-panel__table__employee-card--mobile">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{employee}</span>
    </div>
  );
};

// sobreRodas

const SobreRodasLink = () => {
  return (
    <div className="external-workshop-panel__sobreRodas-link">
      <a href={`${environmentConfig.sobreRodasWebEndpoint}/client/login/`}>
        Histórico Completo do veículo
      </a>
      <img src={sobreRodasLogo} alt="Sobre Rodas" />
    </div>
  );
};
