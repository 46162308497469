import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import {
  ButtonWrapper,
  CampaignFormWrapper,
  FlexEndWrapper,
  InlineWrapper,
  Option,
  OptionsWrapper,
  DefaultCustomerText,
} from './CampaignForm.styles';
import {
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import CampaignCloseModal from './modals/CampaignCloseModal';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { Button } from 'v2/components/Button/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FormProvider, useForm } from 'react-hook-form';
import { Campaign } from './tabs/Campaign';
import { CRMCampaignRepository } from 'v2/repositories/CRMCampaignRepository';
import { PreviewCampaignModal } from './modals/PreviewCampaignModal';
import Clients from './tabs/ClientsMain/Clients';
import CampaignDispatchModal from './modals/CampaignDispatchModal';
import { WhatsApp } from 'v2/components/WhatsApp';
import s3Repository from 'repositories/S3';
import { toastr } from 'react-redux-toastr';
import useCRMStore from '../../store/crm-store';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
export function CampaignForm({ closeForm, campaignId }) {
  const { fetchLoading, isLoading, setIsLoading } = useLoading();
  const { companyId, company } = useAuth();
  const [campaignSelectedOption, setCampaignSelectedOption] =
    useState('Clientes');
  const [typesOptions, setTypesOptions] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showCampaignCloseModal, setShowCampaignCloseModal] = useState(false);
  const [typeToChange, setTypeToChange] = useState(null);
  const { selectedCard } = useCRMStore();

  const formMethods = useForm({
    defaultValues: {
      isReview: false,
      description: '',
      type: {
        label: 'Selecione...',
        value: '',
      },
      header: {
        label: 'Olá, Nome do Cliente',
        value: 1,
      },
      cnpjCase: {
        label: 'Nome do Responsável',
        value: 1,
      },
      photo: null,
      message: [
        {
          type: 'paragraph',
          align: 'left',
          children: [{ text: '' }],
        },
      ],
      Customer: {
        initialDate: '',
        finalDate: '',
        phoneFilter: { label: 'Todos', value: '' },
        customSubtype: { label: 'Todos os clientes', value: '' },
        customersIds: [],
        tableData: [],
        totalPages: 1,
        cards: null,
        totalValueCustomers: 0,
      },
    },
  });
  const { register, handleSubmit, setValue, watch } = formMethods;
  const { WhatsAppComponent, handleOpenWhatsAppModal } = WhatsApp();
  const type = watch('type');
  const description = watch('description');
  const customersIds = watch('Customer.customersIds');
  const header = watch('header');
  const cnpjCase = watch('cnpjCase');
  const message = watch('message');
  const isReview = watch('isReview');

  const loadOptions = async () => {
    setIsLoading(true);
    try {
      const types = await CRMCampaignRepository.getTypes();
      setTypesOptions(
        types.map((type) => ({ value: type.id, label: type.description }))
      );
      return types;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const getCampaignById = async () => {
    try {
      const response = await CRMCampaignRepository.getCampaignById(campaignId, {
        companyId,
      });

      fillFieldsCampaignReview(response);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as campanhas. Por favor, tente novamente.'
      );
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);
  useEffect(() => {
    if (campaignId) {
      handleLoadCampaign();
      setValue('isReview', true);
    }
  }, [campaignId]);

  const handleLoadCampaign = async () => {
    await fetchLoading(getCampaignById);
  };

  const handleSerializeData = (data, photo) => {
    const {
      description,
      type,
      header,
      suggestion,
      cnpjCase,
      message,
      Customer,
    } = data;

    const { customersIds, initialDate, finalDate, customSubtype } = Customer;

    return {
      companyId,
      filters: {
        initialDate,
        finalDate,
        filterType: type.label,
        customSubtype: customSubtype?.label,
      },
      campaign: {
        description,
        crmTypeId: type.value,
        crmSuggestionId: suggestion ? suggestion.value.id : null,
        crmHeaderId: header.value,
        crmCnpjCaseId: cnpjCase?.value || null,
        photo,
        message: JSON.stringify(message),
      },
      customers: customersIds,
    };
  };

  const onSubmit = async (data) => {
    try {
      let photo = '';
      if (data.photo) {
        const response = await s3Repository.uploadImage(
          data.photo,
          company.Cpf_Cnpj + '/whatsapp'
        );
        photo = response;
      }
      const serialized = handleSerializeData(data, photo);
      await CRMCampaignRepository.createAndSendCampaign(serialized);
      closeForm();
    } catch (error) {
      console.log(error);
    }
  };

  const cleanAllFields = () => {
    setValue('Customer.customersIds', []);
    setValue('Customer.initialDate', '');
    setValue('Customer.finalDate', '');
    setValue('Customer.phoneFilter', { label: 'Todos', value: '' });
    setValue('Customer.customSubtype', {
      label: 'Todos os clientes',
      value: '',
    });
    setValue('Customer.tableData', []);
    setValue('Customer.totalPages', 1);
    setValue('Customer.cards', null);
    setValue('Customer.totalValueCustomers', 0);
  };
  const fillFieldsCampaignReview = (data) => {
    const calculateCardsValue = data.tableData.reduce(
      (acc, item) => item.totalSpent ?? 0 + acc,
      0
    );

    setValue('description', data.description);
    setValue('type', {
      label: data.typeDescription,
      value: data.typeDescription,
    });

    setValue(
      'Customer.customersIds',
      data.tableData.map((item) => item.id)
    );
    setValue('cnpjCase', {
      label: data.CNPJCaseDescription,
      value: data.CNPJCaseDescription,
    });
    setValue(
      'Customer.initialDate',
      format(new Date(data.finalDate), 'yyyy-MM-dd') || ''
    );
    setValue(
      'Customer.finalDate',
      format(new Date(data.finalDate), 'yyyy-MM-dd') || ''
    );
    setValue('Customer.customSubtype', {
      label: data.customSubtype,
      value: '',
    });

    setValue(
      'Customer.tableData',
      data.tableData.map((item) => {
        return {
          checkbox: item.id,
          name: item.customerName,
          cpfCnpj: item.cpfCnpj,
          numberPhone: item.phone,
          totalValueSales: item.totalSpent,
          averageTicket: item.averageTicket,
          quantitySales: item.saleQuantity,
          quantityOS: item.osQuantity,
          totalValueCustomers: item.totalSpent,
          lastSale: item.lastVisit,
          dateBirth: item.birthdayDate,
          forecastReturn: item.returnDate,
        };
      })
    );
    setValue('photo', { preview: data.photo });
    setValue('message', JSON.parse(data.message));
    setValue('header', {
      label: data.headerDescription,
      value: 0,
    });
    setValue('Customer.totalPages', null);
    setValue('Customer.cards', {
      totalCount: data.tableData.length,
      totalValue: calculateCardsValue,
      correctPhoneTotalCount: data.tableData.length,
      correctPhoneTotalValue: calculateCardsValue,
    });
  };

  const validateFields = () => {
    const messageLength = message.reduce((accumulator, item) => {
      return (
        accumulator +
        item.children.reduce((acc, child) => acc + child.text.length, 0)
      );
    }, 0);
    if (
      !description ||
      (!header && !cnpjCase && header.label !== 'Não Enviar') ||
      customersIds.length < 1 ||
      messageLength === 0
    ) {
      toastr.error('Preencha todos os campos obrigatórios');
      return false;
    }
    return true;
  };

  const handleSetType = (option) => {
    if (customersIds.length) {
      setTypeToChange(option);
      return setShowCampaignCloseModal(true);
    }
    setValue('type', option);
    cleanAllFields();
  };

  const handleCloseCampaignCloseModal = () => {
    setValue('type', typeToChange);
    cleanAllFields();
    setShowCampaignCloseModal(false);
  };

  const handlePresetData = async () => {
    const today = new Date();
    let initialDate, finalDate;

    switch (selectedCard.value) {
      case 'today':
        initialDate = finalDate = format(today, 'yyyy-MM-dd');
        break;
      case 'week':
        initialDate = format(startOfWeek(today), 'yyyy-MM-dd');
        finalDate = format(endOfWeek(today), 'yyyy-MM-dd');
        break;
      case 'month':
        initialDate = format(startOfMonth(today), 'yyyy-MM-dd');
        finalDate = format(endOfMonth(today), 'yyyy-MM-dd');
        break;
      case 'nextMonth':
        const nextMonth = addMonths(today, 1);
        initialDate = format(startOfMonth(nextMonth), 'yyyy-MM-dd');
        finalDate = format(endOfMonth(nextMonth), 'yyyy-MM-dd');
        break;
    }
    setValue('Customer.initialDate', initialDate);
    setValue('Customer.finalDate', finalDate);

    const types = await loadOptions();
    if (selectedCard.type === 'birthday') {
      setValue(
        'type',
        types
          .map((type) => ({ value: type.id, label: type.description }))
          .find((type) => type.label === 'Aniversário')
      );
    } else {
      setValue(
        'type',
        types
          .map((type) => ({ value: type.id, label: type.description }))
          .find((type) => type.label === 'Retorno/Revisão')
      );
    }
  };

  useEffect(() => {
    if (!selectedCard) return;
    handlePresetData();
  }, []);

  return (
    <CampaignFormWrapper>
      <FormProvider {...formMethods}>
        <FlexEndWrapper>* Campos Obrigatórios</FlexEndWrapper>
        <InlineWrapper>
          <InputContainer>
            <InputLabel required>Descrição:</InputLabel>
            <InputText
              id="input__description"
              maxLength={50}
              width="400"
              height={'35px'}
              {...register('description')}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              isDisabled={isReview}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel required>Tipo:</InputLabel>
            <InputSelectDefault
              width={'160px'}
              height={'35px'}
              options={typesOptions}
              value={type}
              disabled={isReview}
              isLoading={isLoading}
              onChange={(option) => handleSetType(option)}
            />
          </InputContainer>
          <ButtonWrapper>
            <Button
              height="35px"
              color="blue300"
              gap="5px"
              onClick={() => setPreviewModalOpen(true)}
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
              Exibir Pré-visualização
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              height="35px"
              color="green300"
              gap="5px"
              onClick={() => {
                if (!validateFields()) return;
                handleOpenWhatsAppModal();
              }}
              disabled={isReview || isLoading}
              loading={isLoading}
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              Disparar Campanha({customersIds.length})
            </Button>
          </ButtonWrapper>
        </InlineWrapper>
        <OptionsWrapper>
          <Option
            selected={campaignSelectedOption === 'Clientes'}
            onClick={() => {
              setCampaignSelectedOption('Clientes');
            }}
          >
            Clientes
          </Option>
          <Option
            selected={campaignSelectedOption === 'Campanha'}
            onClick={() => {
              setCampaignSelectedOption('Campanha');
            }}
          >
            Campanha
          </Option>
        </OptionsWrapper>
        <br />
        {campaignSelectedOption === 'Clientes' && (
          <div
            style={{
              width: '100%',
            }}
          >
            {type?.value !== '' ? (
              <Clients />
            ) : (
              <DefaultCustomerText>
                Selecione o tipo e preencha os filtros para exibir os clientes
              </DefaultCustomerText>
            )}
          </div>
        )}
        {campaignSelectedOption === 'Campanha' && <Campaign />}
        {previewModalOpen && (
          <div style={{ width: '200px' }}>
            <PreviewCampaignModal
              open={previewModalOpen}
              onClose={() => setPreviewModalOpen(false)}
            />
          </div>
        )}
        <CampaignDispatchModal
          show={showDispatchModal}
          onHide={() => setShowDispatchModal(false)}
          onSubmit={handleSubmit(onSubmit)}
        />
        <CampaignCloseModal
          show={showCampaignCloseModal}
          OnCancel={() => setShowCampaignCloseModal(false)}
          onSubmit={() => handleCloseCampaignCloseModal()}
        />
        <WhatsAppComponent onSuccess={() => setShowDispatchModal(true)} />
      </FormProvider>
    </CampaignFormWrapper>
  );
}
