import React, { useCallback, useState } from 'react'

import { useAuth } from 'contexts/auth'
import { useEffect } from 'react'
import { toastr } from 'react-redux-toastr'

import EmployeesRepository from 'repositories/Employees'
import { format } from 'date-fns'

export default function VouchersIssuedMain() {
  const { companyId } = useAuth()

  const [employees, setEmployees] = useState([])
  const [employeeId, setEmployeeId] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [status, setStatus] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    if (validations()) handleProcess()
  }

  const loadEmployeesOptions = useCallback(async () => {
    try {
      const employees = await EmployeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      })
      setEmployees(employees.data)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os funcionários',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    }
  }, [companyId])

  const validations = () => {
    if (new Date(finalDate) && new Date(initialDate)) {
      if (new Date(finalDate) < new Date(initialDate)) {
        toastr.warning('A data final deve ser maior que a data inicial.')
        return false
      }
    }

    if (!finalDate || !initialDate) {
      return toastr.warning(
        'Não foi possível gerar o relatório',
        'Preencha todos os campos e tente novamente!'
      )
    }

    return true
  }

  const handleProcess = async () => {
    setIsLoading(true)
    try {
      const empId = employeeId === 'all' ? '' : employeeId
      const selectedStatus = status === 'all' ? '' : status

      if (initialDate && finalDate) {
        window.open(
          window.location.origin +
            `/report/voucher-issued?employeeId=${empId}&initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyId}&status=${selectedStatus}`,
          '_blank'
        )
      }

    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadEmployeesOptions()
  }, [loadEmployeesOptions])

  return (
    <div className="voucher-issued-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
          max={finalDate ? finalDate : format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
          min={initialDate}
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div
        style={{
          width: '400px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center', width: 400 }}>
          <strong>Funcionário:</strong>
        </span>
        <select
          className="form-control foco-input"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        >
          <option value="">Selecione</option>
          {employees.length > 1 ? <option value="all">Todos</option> : ''}
          {employees.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Status:</strong>
        </span>
        <select
          className="form-control foco-input"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="all">Todos</option>
          <option value="Em Aberto">Em Aberto</option>
          <option value="Finalizada">Finalizada</option>
          <option value="Cancelada">Cancelada</option>
        </select>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <button
          className="btn btn-sucesso"
          type="submit"
          onClick={handleSubmit}
        >
          <span
            className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />{' '}
          Processar
        </button>
      </div>
    </div>
  )
}
