import React, {
  useEffect,
  useState,
} from 'react';

import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import Button from '../../../components/CustomButton/CustomButton'
import ProductsFilterForm from './ProductsFilterForm';
import ProductsLoadingModal from './ProductsLoadingModal';
import ProductsTable from './ProductsTable';

import AlertModal from '../../../../components/AlertModal'

import useDebounce from '../../../../hooks/useDebounce'

import productsRepository from '../../../../repositories/Products';
import { useAuth } from '../../../../contexts/auth';

import './styles.css';

const BulkDeletionModal = ({ 
  showBulkDeletionModal, 
  handleShowBulkDeletionModal,
  setDeletedProducts,
  brands, 
}) => {

  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);

  const [allProductsSelected, setAllProductsSelected] = useState(false);

  const [allowClose, setAllowClose] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState('');
  const [brandId, setBrandId] = useState('');
  const [query, setQuery] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800);

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const handleChangeQuerySearch = (value) => {
    setQuery(value);
    debouncedSaveQuerySearch(value);
  }

  const handleSelectedProductsChange = (selectedProduct) => {
    const productsCopy = [...products];
    const productIndex = productsCopy.findIndex((bill) => bill.id === selectedProduct.id);
    productsCopy[productIndex].selected = !productsCopy[productIndex].selected;

    setProducts(productsCopy);
  
  }

  const { companyId } = useAuth();

  const  handleSelectAll = () => {
    const serializedProducts = products.map((product) => ({
      ...product,     
      selected: true,
    }))

    setProducts(serializedProducts);
  }

  const  handleUnselectAll = () => {
    const serializedProducts = products.map((product) => ({
      ...product,     
      selected: false,
    }))

    setProducts(serializedProducts);
  }

  const handleClose = (origin) => {    
    if (origin === 'exitConfirmationModal') {
      handleShowBulkDeletionModal(false);
      return;
    }

    const selectedProductsLength = products.filter(product => product.selected).length;
    if (selectedProductsLength !== 0) {     
      setShowExitConfirmationModal(true);
      return;
    }

    setShowExitConfirmationModal(false);
    handleShowBulkDeletionModal(false);
    
  } 

  useEffect(() => {
    loadProducts()
  }, []);

  useEffect(() => {
    if(loading) {
      setAllowClose(false);
    } else {
      setAllowClose(true);
    }
  }, [loading])

  const loadProducts = async (state) => {
    setLoading(true);
    setShowLoadingModal(true);
    try {
      const response = await productsRepository.getAllUnused({
        Company_id: companyId || 0,
        query: state.query,
        IsActive: state.status,
        Brand_id: state.brandId,
      });

      const total = response.headers['x-total-count'];
      const serializeProducts = response.data.map((product) => ({
        ...product,
        Code: Number(product.Code),
        Brand: product.Brands?.Description,
        selected: false,
      }));

      setProducts(serializeProducts);
    } catch (err) {
      console.error(err);
      if (!products) {
        toastr.warning(
          'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
        );
        handleClose();
      }
    } finally {
      setLoading(false);
      setShowLoadingModal(false);
    }
  }

  const handleBulkDeletionConfirmation =  () => {
    const selectedProductsLength = products.filter(product => product.selected).length;
    if (selectedProductsLength === 0) {     
      toastr.warning(
        'Selecione um ou mais cadastro de produtos para excluir'
      )
      return;
    }
    setShowDeletionConfirmationModal(true);
  }

  const handleBulkDeletion = async () => {
    try {

      let productsToDelete = products.filter(product => product.selected);
      productsToDelete = productsToDelete.map(prod => { 
        return { id: prod.id }
      });

      let productsToDeleteSlice = []

      for (let i = 0; i < productsToDelete.length; i++) {
        if (productsToDeleteSlice.length < 1000 && productsToDelete[i].id !== productsToDelete.slice(-1)[0].id) {
          productsToDeleteSlice.push(productsToDelete[i])
        } else {

          if (productsToDelete[i].id === productsToDelete.slice(-1)[0].id) {
            productsToDeleteSlice.push(productsToDelete[i])
          } 
          
          await productsRepository.bulkDeletion(productsToDeleteSlice);
          
          productsToDeleteSlice = []  
          productsToDeleteSlice.push(productsToDelete[i])          
        }
      }

      toastr.success('Produtos excluídos com sucesso.');

      setDeletedProducts(true);
      handleShowBulkDeletionModal(false);
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao deletar o produto.')
    } finally {
      setShowDeletionConfirmationModal(false)
    }
  }

  useEffect(() => {
    loadProducts({  
      query: searchQuery,
      status,
      brandId,
    });
  }, [status, brandId, searchQuery]);

  useEffect(() => {

    const selectedProductsLength = products.filter(product => product.selected).length;
    if (selectedProductsLength !== products.length) {     
      setAllProductsSelected(false)
      return;
    }

    setAllProductsSelected(true);

  }, [products]);

  return (<>
    <Modal
      show={showBulkDeletionModal}
      keyboard={allowClose}
      backdrop={allowClose ? true : 'static'}
      onHide={() => handleClose(false)}
      dialogClassName="bulk-deletion-modal-wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>          
        </Modal.Title>          
      </Modal.Header>
      <Modal.Body>
        <div className="modal-advice-label">
          <span>Apenas produtos sem histórico de movimentação de estoque poderão ser deletados</span>
        </div>     

        <ProductsFilterForm 
          brands={brands}
          status={status}
          setStatus={setStatus}
          brandId={brandId}
          setBrandId={setBrandId}
          handleChangeQuerySearch={handleChangeQuerySearch}
          query={query}
        />

        <div className="select-all-products hyperlink">
          <a href="#" onClick={!allProductsSelected ? handleSelectAll : handleUnselectAll}>{allProductsSelected ? 'Remover Todos' : 'Selecionar Todos'}</a>
        </div>   

        <ProductsTable
          products={products}
          loadProducts={loadProducts}
          loading={loading}          
          handleSelectedProductsChange={handleSelectedProductsChange}
        />

        <div className='modal-footer-buttons'>
          <Button
            bsStyle='danger'
            pullRight
            fill
            onClick={() =>
              handleClose(false)
            }
            disabled={allowClose ? false : true}
          >
            Voltar
          </Button>          
          <Button
            bsStyle='info'
            pullRight
            fill
            onClick={() => handleBulkDeletionConfirmation()}
          >
            Excluir Itens Selecionados
          </Button>                  
        </div>  
        <ProductsLoadingModal showLoadingModal={showLoadingModal} />
      </Modal.Body>
    </Modal>

    {
      showExitConfirmationModal ?
      <AlertModal
        show={showExitConfirmationModal}
        title='OS Digital'
        message={
          <>
            <p><strong>Você tem certeza que deseja sair da exclusão de produtos?</strong></p>
            <p>Os produtos permanecerão em seu cadastro e poderão ser excluídos posteriormente, desde que não tenha venda ou compra vinculada.</p>
          </>}
        onCancel={() => setShowExitConfirmationModal(false)}
        onSubmit={() => {
          handleClose('exitConfirmationModal');
        }}
        loading={loading}
      /> : ''   
    }

    {
      showDeletionConfirmationModal ?
      <AlertModal
        show={showDeletionConfirmationModal}
        title='OS Digital'
        message={
          <>
            <p><strong>Você tem certeza que deseja excluir o(s) produto(s) cadastrado(s)?</strong></p>
            <p>A exclusão é irreversível e deverá cadastrar novamente se necessário.</p>
          </>}
        onCancel={() => setShowDeletionConfirmationModal(false)}
        onSubmit={() => handleBulkDeletion() }
        loading={loading}
      /> : ''
    }
          
  </>)
}

export default BulkDeletionModal;