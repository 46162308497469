import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import AvailableEmployees from 'client/views/WorkshopPanel/components/AvailableEmployees';
import { MaintenanceTable } from './components/MaintenanceTable';

export function AppointmentByEmployeePanel() {
  const history = useHistory();
  const { companyId, loading: loadingAuth } = useAuth();

  const { reloadPanelInformation, loadPanelInformation, setLastRefresh } =
    useWorkshopPanel();

  const [refreshInterval, setRefreshInterval] = useState();

  const handleGetPanelInformation = useCallback(async () => {
    if (companyId) {
      await loadPanelInformation(companyId, 'appointment-by-employee');
    }
    setLastRefresh(new Date());
  }, [companyId]);

  const validateUserSign = useCallback(() => {
    if (!loadingAuth && !companyId) {
      return history.replace('/');
    }
  }, [companyId, loadingAuth]);

  const refreshWorkshopPanel = useCallback(
    (refreshInterval) => {
      if (reloadPanelInformation) {
        if (refreshInterval) {
          clearInterval(refreshInterval);
        }

        handleGetPanelInformation();

        const refreshIntervalFn = setInterval(() => {
          handleGetPanelInformation();
        }, 300000);

        setRefreshInterval(refreshIntervalFn);
      }
    },
    [companyId, reloadPanelInformation]
  );

  useEffect(() => {
    validateUserSign();
  }, [validateUserSign]);

  useEffect(() => {
    refreshWorkshopPanel(refreshInterval);
  }, [refreshWorkshopPanel]);

  return (
    <div className="workshop-panel">
      <MaintenanceTable />
      <AvailableEmployees />
    </div>
  );
}
