import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from 'contexts/auth';

import { useRouter } from 'v2/hooks/useRouter';
import { deleteChecklist } from 'v2/repositories/ChecklistRepository';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir o defeito? Ao excluir, não poderá
        vinculá-lo a nenhum checklist personalizado e a correções.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluída, não poderá ser recuperada.
      </span>
    </div>
  );
};

const alreadyInUseToasterTitle = 'Checklist vinculado a Venda';
const alreadyInUserToasterMessage =
  'O checklist está vinculado a uma ou mais vendas e não pode ser excluído. Inative-o caso não queira mais utilizar.';

export function TableActions({ id, triggerFetch }) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteChecklist(id);
      toastr.success(
        'Exclusão realizada com sucesso',
        'O checklist foi excluído'
      );
      triggerFetch();
    } catch (err) {
      console.log(err);

      const alreadyInUse =
        err.response?.data?.message ===
        'This Checklist is vinculated to a sale and cannot be deleted.';

      const title = alreadyInUse ? alreadyInUseToasterTitle : 'Erro ao excluir';

      const message = alreadyInUse
        ? alreadyInUserToasterMessage
        : 'Por favor, tente novamente. Caso persista, contate o suporte.';

      toastr.error(title, message);
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  const handleDeleteButtonClick = async () => {
    try {
      setIsDeleteModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao validar exclusão',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    }
  };

  return (
    <div className="flex center gap-050">
      <button
        title="Editar"
        className="button-icon"
        onClick={() => {
          navigateToClientPage('checklist', 'form_edit_raw', id);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        title="Excluir"
        className="button-icon"
        onClick={handleDeleteButtonClick}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
