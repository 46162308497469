import React, { useCallback, useEffect, useState, useRef } from 'react';
import { addDays, addMonths, format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';

import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import CurrencyInput from 'client/components/Currency';
import AlertModal from 'components/AlertModal/AlertModal';
import HomePaymentConditions from '../../PaymentConditions/PaymentCondition';
import HomePaymentForms from '../../FormOfPayment/FormOfPayment';

import { getDateOnlyFromDate } from '../../../../utils/dateHelpers';
import {
  createInstallments,
  splitParcelsValue,
} from '../../../../utils/createInstallments';

import billsToPayRepository from '../../../../repositories/BillsToPay';
import paymentConditionsRepository from '../../../../repositories/PaymentConditions';
import paymentFormsRepository from '../../../../repositories/PaymentForms';
import cashierBankRepository from '../../../../repositories/CashierBank';
import accountPlansRepository from '../../../../repositories/AccountPlans';

import NewAccountPlanFormModal from '../../../components/FormModals/AccountPlan';

import { useAuth } from 'contexts/auth';

const BillsToPayCreation = ({ handleCancel, onSubmit, purchase }) => {
  const [paymentConditions, setPaymentConditions] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [condition, setCondition] = useState(null);
  const [cashierBanks, setCashierBanks] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const [accountPlanId, setAccountPlanId] = useState(0);
  const [isAccountPlanModalOpen, setIsAccountPlanModalOpen] = useState(false);
  const [clickedCurrencyInputIndex, setClickedCurrencyInputIndex] = useState();

  const [isPaymentConditionsModalOpen, setIsPaymentConditionsModalOpen] =
    useState(false);
  const [isPaymentFormsModalOpen, setIsPaymentFormsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    companyId,
    company: {
      hasCashierControl,
      defaultCashierBankId,
      defaultPurchaseAccountPlanId,
    },
  } = useAuth();

  const currencyInputRef = useRef();

  useEffect(() => {
    if (companyId) loadCombos();
  }, [companyId]);

  useEffect(() => {
    if (companyId && hasCashierControl) {
      loadCashierBanks();
    }
  }, [companyId, hasCashierControl]);

  useEffect(() => {
    if (!!defaultPurchaseAccountPlanId)
      setAccountPlanId(defaultPurchaseAccountPlanId);
  }, [defaultPurchaseAccountPlanId]);

  useEffect(() => {
    if (currencyInputRef.current && currencyInputRef.current.theInput) {
      currencyInputRef.current.theInput.focus();
    }
  }, [clickedCurrencyInputIndex]);

  const loadCombos = () => {
    loadPaymentConditions();
    loadPaymentForms();
    getAccountPlans();
  };

  const loadCashierBanks = async () => {
    const cashierBanks =
      await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
        companyId
      );
    setCashierBanks(cashierBanks);
  };

  const loadPaymentConditions = async () => {
    setLoading(true);
    try {
      const paymentConditions =
        await paymentConditionsRepository.getAllByCompanyActiveSortedByCondition(
          companyId
        );
      setPaymentConditions(paymentConditions);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as condições de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadPaymentForms = async () => {
    setLoading(true);
    try {
      const paymentForms =
        await paymentFormsRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      setPaymentForms(paymentForms);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as formas de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  async function getAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({
        companyId,
        isActive: 1,
        type: 'Débito',
      });

      setAccountPlans(data);
    } catch (err) {
      toastr.warning('Erro ao carregar Planos de Conta');
    }
  }

  const handleChangeCondition = (e) => {
    if (!e.target.value) {
      setCondition(null);
      return setInstallments([]);
    }

    const condition = getConditionById(e.target.value);

    if (!!condition) {
      let installments = createInstallments(
        condition,
        hasCashierControl,
        defaultCashierBankId
      );
      if (!condition.hasEntry) {
        installments = splitParcelsValue(installments, purchase.total);
      }

      setInstallments(installments);
      setCondition(condition);
    }
  };

  const handleChangeFormOfPayment = (e, i) => {
    const newInstallments = [...installments];
    newInstallments[i].formOfPaymentId = e.target.value;

    const formOfPayment = getFormOfPaymentById(e.target.value);

    newInstallments[i].automaticDownload = !!formOfPayment
      ? !!formOfPayment.automaticDownload
      : false;

    setInstallments(newInstallments);
  };

  const getConditionById = (id) =>
    paymentConditions.find((condition) => String(condition.id) === id);
  const getFormOfPaymentById = (id) =>
    paymentForms.find((form) => String(form.id) === id);

  const handleReplicateFormOfPayment = () => {
    if (installments[0].formOfPaymentId === '') {
      return toastr.warning(
        'Insira uma forma de pagamento para ser replicado.'
      );
    }

    const newInstallments = [...installments];

    newInstallments.forEach((installment) => {
      installment.formOfPaymentId = newInstallments[0].formOfPaymentId;
    });

    setInstallments(newInstallments);
  };

  const handleAdjustInstallmentValues = (e, index) => {
    const newInstallments = [...installments];
    newInstallments[index].value = Number(e.target.value);

    const installmentNumber = index + 1;
    const remainingInstallments = newInstallments.length - installmentNumber;

    if (installmentNumber === newInstallments.length) return;

    if (newInstallments[index].value >= purchase.total) {
      const serializedInstallments = splitParcelsValue(
        newInstallments,
        purchase.total
      );
      setInstallments(serializedInstallments);
      return;
    }

    let parcelsValuesAmount = 0;
    for (let i = 0; i < installmentNumber; i++) {
      parcelsValuesAmount = parcelsValuesAmount + newInstallments[i].value;
    }

    const restValue = purchase.total - parcelsValuesAmount;
    const parcelValue = Number((restValue / remainingInstallments).toFixed(2));
    const rest = restValue - parcelValue * remainingInstallments;

    for (let i = installmentNumber; i < newInstallments.length; i++) {
      newInstallments[i].value = parcelValue;
    }

    newInstallments[newInstallments.length - 1].value += rest;

    setInstallments(newInstallments);
  };

  const handleAdjustInstallmentDates = (e, index) => {
    const newInstallments = [...installments];
    const daysBetweenInstallment = Number(condition.daysBetweenInstallment);
    if (!e.target.value) {
      newInstallments[index].dueDate = e.target.value;
      return setInstallments(newInstallments);
    }

    const date = format(
      new Date(getDateOnlyFromDate(e.target.value)),
      'yyyy-MM-dd'
    );
    newInstallments[index].dueDate = e.target.value;

    const installmentNumber = index + 1;
    if (installmentNumber === newInstallments.length)
      return setInstallments(newInstallments);

    let countDays = 1;
    let countMonths = 1;

    for (let i = installmentNumber; i < newInstallments.length; i++) {
      if (daysBetweenInstallment === 30) {
        newInstallments[i].dueDate = format(
          addMonths(getDateOnlyFromDate(date), countMonths),
          'yyyy-MM-dd'
        );
        countMonths++;
      } else {
        newInstallments[i].dueDate = format(
          addDays(
            getDateOnlyFromDate(date),
            daysBetweenInstallment * countDays
          ),
          'yyyy-MM-dd'
        );
        countDays++;
      }
    }

    setInstallments(newInstallments);
  };

  const handleClosePaymentConditionsModal = () => {
    setIsPaymentConditionsModalOpen(false);
    loadPaymentConditions();
  };

  const handleClosePaymentFormsModal = () => {
    setIsPaymentFormsModalOpen(false);
    loadPaymentForms();
  };

  const handleSubmit = () => {
    if (!installments.length) {
      return toastr.warning('Escolha a condição de pagamento');
    }

    if (!accountPlanId) return toastr.warning('Informe o Plano de Contas');

    if (
      installments.some(
        (installment) =>
          !installment.dueDate ||
          !installment.formOfPaymentId ||
          !installment.value
      )
    ) {
      return toastr.warning('Existem parcelas com informações incompletas');
    }

    const total =
      installments.length === 1
        ? installments[0].value
        : installments
            .map((installment) => installment.value)
            .reduce((prev, curr) => prev + curr);

    if (
      Math.round(total * 100) / 100 !== purchase.total &&
      Math.round(total * 100) / 100 !== Math.round(purchase.total * 100) / 100
    ) {
      return toastr.warning(
        'O somatório das parcelas deve ser igual ao total da compra'
      );
    }

    setIsConfirmModalOpen(true);
  };

  const handleCreateBills = async () => {
    setIsConfirmModalOpen(false);
    setLoading(true);

    const serializedInstallments = installments.map((installment, i) => ({
      dueDate: installment.dueDate,
      liquidValue: installment.value,
      formOfPaymentId: installment.formOfPaymentId,
      automaticDownload: installment.automaticDownload,
      issuedAt: new Date(),
      installment: i + 1,
      entryNumber: purchase.code,
      entryDate: purchase.issuedAt,
      providerId: purchase.providerId,
      cashierBankId: installment.cashierBankId,
    }));

    try {
      await billsToPayRepository.bulkCreate({
        installments: serializedInstallments,
        companyId: companyId,
        purchaseId: purchase.id,
        accountPlanId,
      });

      onSubmit();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao criar os títulos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleInstallmentAmountReceived = useCallback(
    (index) => {
      setInstallments(
        installments.map((installment, i) => {
          if (i === index) {
            return {
              ...installment,
              automaticDownload: !installment.automaticDownload,
            };
          }
          return installment;
        })
      );
    },
    [installments]
  );

  const handleInstallmentCashierBankId = useCallback(
    (event, index) => {
      setInstallments(
        installments.map((installment, i) => {
          if (i === index) {
            return {
              ...installment,
              cashierBankId: Number(event.target.value),
            };
          }
          return installment;
        })
      );
    },
    [installments]
  );

  const handleSubmitNewAccountPlan = (accountPlan) => {
    getAccountPlans();
    setAccountPlanId(accountPlan.created.id);
    setIsAccountPlanModalOpen(false);
  };

  return (
    <div id="purchases-bills-to-pay-creation">
      <header>
        <section className="cond-payment-select">
          <span
            className="link"
            onClick={() => setIsPaymentConditionsModalOpen(true)}
          >
            Visualizar condições
          </span>
          <div>
            <span>Condição de Pagamento:</span>
            <select
              disabled={!paymentConditions.length}
              value={condition?.id || ''}
              className="form-control foco-input"
              onChange={handleChangeCondition}
            >
              <option value="">Selecione</option>
              {paymentConditions.map((condition) => (
                <option key={condition.id} value={condition.id}>
                  {condition.Description}
                </option>
              ))}
            </select>
          </div>
        </section>
        <section className="account-plans-select">
          <span
            className="link"
            onClick={() => setIsAccountPlanModalOpen(true)}
          >
            Adicionar Conta
          </span>
          <div>
            <span>Plano de Contas:</span>
            <select
              disabled={!accountPlans.length}
              value={accountPlanId}
              className="form-control foco-input"
              onChange={(e) => setAccountPlanId(e.target.value)}
            >
              <option value="">Selecione</option>
              {accountPlans.map((acc) => (
                <option key={acc.id} value={acc.id}>
                  {acc.description}
                </option>
              ))}
            </select>
          </div>
        </section>
      </header>

      <main>
        <table className="bills-to-pay-installments-table">
          <thead>
            <tr>
              <td>Parcela</td>
              <td>Vencimento</td>
              <td>Valor</td>
              <td>
                Forma de Pagamento {'   '}
                <a
                  className="div-a"
                  onClick={() => setIsPaymentFormsModalOpen(true)}
                >
                  Visualizar Formas Pagto.
                </a>
              </td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Valor Pago?
              </td>
              {hasCashierControl && <td>Caixa/Banco</td>}
            </tr>
          </thead>
          <tbody>
            {installments.map((installment, i) => (
              <tr key={i}>
                <td>{installment.parcel}</td>
                <td>
                  <input
                    type="date"
                    value={installment.dueDate}
                    onChange={(e) => handleAdjustInstallmentDates(e, i)}
                    className="form-control foco-input"
                    max="9999-12-31"
                    disabled={installments[i].parcel === 'À vista'}
                  />
                </td>
                <td>
                  <CurrencyInput
                    className="form-control foco-input"
                    value={installment.value}
                    onChangeEvent={(e) => handleAdjustInstallmentValues(e, i)}
                    onClick={() => setClickedCurrencyInputIndex(i)}
                    ref={(input) => {
                      if (i === clickedCurrencyInputIndex) {
                        currencyInputRef.current = input;
                      }
                    }}
                  />
                </td>
                <td>
                  <select
                    className="form-control foco-input"
                    value={installment.formOfPaymentId}
                    onChange={(e) => handleChangeFormOfPayment(e, i)}
                  >
                    <option value="">Selecione</option>
                    {paymentForms.map((form) => (
                      <option key={form.id} value={form.id}>
                        {form.Desciption}
                      </option>
                    ))}
                  </select>
                  {installments.length > 1 && i === 0 && (
                    <a
                      className="div-a"
                      id="form-of-payment-replicate"
                      onClick={handleReplicateFormOfPayment}
                    >
                      Replicar
                    </a>
                  )}
                </td>
                <td className="installments-checkbox">
                  <input
                    type="checkbox"
                    checked={installment.automaticDownload}
                    onChange={() => handleInstallmentAmountReceived(i)}
                    disabled={!!!installment.formOfPaymentId}
                  />
                </td>
                {hasCashierControl && (
                  <td>
                    <select
                      className="form-control foco-input"
                      onChange={(e) => handleInstallmentCashierBankId(e, i)}
                      value={installment.cashierBankId || ''}
                    >
                      {cashierBanks.map((cashierBank) => (
                        <option key={cashierBank.id} value={cashierBank.id}>
                          {cashierBank.CashierBankType.initials} -{' '}
                          {cashierBank.description}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </main>

      <hr />
      <footer>
        <CustomButton
          bsStyle="danger"
          fill
          disabled={loading}
          onClick={handleCancel}
        >
          Cancelar
        </CustomButton>
        <CustomButton bsStyle="info" fill onClick={handleSubmit}>
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          Gerar Títulos
        </CustomButton>
      </footer>

      <Modal
        size="lg"
        dialogClassName="modal-payment-conditions"
        animation
        show={isPaymentConditionsModalOpen}
        onHide={handleClosePaymentConditionsModal}
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Condições de Pagamento</strong>
          </Modal.Title>
        </Modal.Header>
        <HomePaymentConditions />
      </Modal>

      <Modal
        size="lg"
        dialogClassName="modal-payment-forms"
        animation
        show={isPaymentFormsModalOpen}
        onHide={handleClosePaymentFormsModal}
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Formas de Pagamento</strong>
          </Modal.Title>
        </Modal.Header>
        <HomePaymentForms />
      </Modal>

      <AlertModal
        show={isConfirmModalOpen}
        message="Deseja realmente confirmar a geração dos títulos ?"
        onHide={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onSubmit={handleCreateBills}
      />

      {isAccountPlanModalOpen && (
        <NewAccountPlanFormModal
          onCancel={() => setIsAccountPlanModalOpen(false)}
          onSubmit={handleSubmitNewAccountPlan}
          newAccountModalType="purchase"
        />
      )}
    </div>
  );
};

export default BillsToPayCreation;
