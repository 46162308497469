import api from "../services/api";

const getAllByCompany = (params) =>
  api.get('intermediary', { params });

const create = (values) => api.post("intermediary", values);

const update = (intermediaryId, values) =>
  api.put(`intermediary/${intermediaryId}`, values);

const getById = (intermediaryId) => api.get(`intermediary/${intermediaryId}`);

export default {
  getAllByCompany,
  create,
  update,
  getById,
};
