import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from '../../../components/Card/Card';
import Button from '../../components/CustomButton/CustomButton';
import CardForm from '../../components/CardForm';
import AlertModal from '../../../components/AlertModal';

import MySegments from './MySegments';
import OtherInfos from './OtherInfos';
import MyServices from './MyServices';
import MyBranch from './MyBranch';

import {
  fetchCompanyDetails,
  updateCompanyDetails,
  handleChange,
} from './ReduxMeusDetalhes/meusDetalhesAction';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';

import './styles.css';

const FormMyCompanyDetails = ({ history }) => {
  const [segments, setSegments] = useState(true);
  const [otherInfos, setOtherInfos] = useState(false);
  const [services, setServices] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSegmentsSelected = (objeto, segmentMapping) => {
    const segmentsSelected = [];

    if (parseInt(Branch_id) === 5) {
      segmentsSelected.push({ description: 'Outros', id: 4 });
      return segmentsSelected;
    }

    for (const [key, value] of Object.entries(objeto)) {
      if (value === true && segmentMapping[key] !== undefined) {
        segmentsSelected.push({
          description: key,
          id: segmentMapping[key],
        });
      }
    }

    dispatch(handleChange('', 'segmentDescription'));
    return segmentsSelected;
  };

  const { companyId, loadCompany } = useAuth();
  const dispatch = useDispatch();
  const { meusDetalhesReducer, segmentoReducer } = useSelector(
    (state) => state
  );
  const { Branch_id, StartTime, EndTime, companyServices } =
    meusDetalhesReducer;
  const {
    Carros,
    Motos,
    Pesados,
    Outros,
    Trator,
    Escavadeira,
    Van,
    Bicicleta,
    Colheitadeira,
    segmentDescription,
  } = segmentoReducer;

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompanyDetails(companyId));
    }
  }, [companyId]);

  const handleUpdate = () => {
    setLoading(true);

    if (
      parseInt(Branch_id) !== 5 &&
      !Carros &&
      !Motos &&
      !Pesados &&
      !Trator &&
      !Escavadeira &&
      !Van &&
      !Bicicleta &&
      !Colheitadeira &&
      !Outros
    ) {
      setLoading(false);
      return toastr.warning('É obrigatório ao menos 1 segmento estar ativo.');
    }

    if ((!StartTime, !EndTime)) {
      setLoading(false);
      return toastr.warning('Por favor, informe o horário de funcionamento.');
    }

    let Ini = StartTime;
    let End = EndTime;
    // verificação de data de 00:00 até 23:59
    if (!Ini.match(/^(?:\d|[01]\d|2[0-3]):[0-5]\d$/)) {
      setLoading(false);

      return toastr.warning('Hora inicial inválida', 'Digite uma hora válida.');
    }
    if (!End.match(/(?:\d|[01]\d|2[0-3]):[0-5]\d$/)) {
      setLoading(false);

      return toastr.warning('Hora final inválida', 'Digite uma hora válida.');
    }
    if (Ini > End) {
      setLoading(false);

      return toastr.warning(
        'Por favor, informe um horário final maior que o inicial.'
      );
    }
    if (Ini === End) {
      setLoading(false);

      return toastr.warning('Defina um horário de funcionamento válido.');
    }
    if (
      (parseInt(Branch_id) === 2 || parseInt(Branch_id) === 3) &&
      companyServices.length === 0
    ) {
      setLoading(false);

      return toastr.warning(
        'Deve selecionar ao menos 1 serviço para o seu ramo de atuação!'
      );
    }

    const segmentMapping = {
      Carros: 1,
      Motos: 2,
      Pesados: 3,
      Trator: 5,
      Escavadeira: 6,
      Van: 7,
      Bicicleta: 8,
      Colheitadeira: 9,
    };

    const Segments = getSegmentsSelected(segmentoReducer, segmentMapping);

    if (
      parseInt(Branch_id) === 5 &&
      (!segmentDescription?.trim() || segmentDescription?.trim().length < 3)
    ) {
      toastr.warning(
        'Segmento não preenchido',
        'Preencha o segmento para poder salvar.'
      );
      setLoading(false);
      return;
    }

    dispatch([
      handleChange(Segments, 'Segments'),
      updateCompanyDetails(companyId, history),
    ]);
    loadCompany();
  };

  return (
    <>
      <Card
        content={
          <div>
            <CardForm
              show={segments}
              title="Ramo e Segmento"
              onClick={() => setSegments(!segments)}
              style={{ marginTop: '15px' }}
            >
              <MyBranch />
              <MySegments isGridded={false} branchId={Number(Branch_id)} />

              <Button
                id="btn-prosseguir"
                type="button"
                style={{ marginTop: '-40px' }}
                bsStyle="info"
                pullRight
                fill
                onClick={() => {
                  setSegments(false);
                  setOtherInfos(true);
                  setServices(false);
                }}
              >
                Prosseguir
              </Button>
            </CardForm>

            <CardForm
              show={otherInfos}
              title="Horário e Outras informações"
              onClick={() => setOtherInfos(!otherInfos)}
            >
              <OtherInfos />
              <Button
                id="btn-prosseguir"
                type="button"
                style={{
                  marginTop: '45px',
                  marginBottom: '10px',
                }}
                bsStyle="info"
                pullRight
                fill
                onClick={() => {
                  setSegments(false);
                  setOtherInfos(false);
                  setServices(true);
                }}
              >
                Prosseguir
              </Button>
            </CardForm>

            {parseInt(Branch_id) !== 1 ? (
              <CardForm
                show={services}
                title={`Serviços Prestados ${
                  parseInt(Branch_id) === 4 ? '(Opcional)' : ''
                }`}
                onClick={() => setServices(!services)}
              >
                <MyServices branchId={Branch_id} />
              </CardForm>
            ) : (
              <div style={{ display: 'none' }} />
            )}

            <Button
              id="btn-prosseguir"
              disabled={loading}
              type="submit"
              bsStyle="info"
              pullRight
              fill
              style={{
                marginBottom: '5px',
              }}
              onClick={handleUpdate}
            >
              <span
                className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                style={{
                  marginRight: '5px',
                }}
              />
              Atualizar
            </Button>
            <Button
              id="btn-cancelar"
              disabled={loading}
              bsStyle="danger"
              type="submit"
              pullRight
              fill
              style={{
                marginBottom: '5px',
                marginRight: '10px',
              }}
              onClick={() => setCancelModal(true)}
            >
              Voltar
            </Button>

            <AlertModal
              show={cancelModal}
              onHide={() => setCancelModal(false)}
              animation={false}
              message="Deseja cancelar as alterações? Esta ação não salvará as informações alterada nas tela."
              onCancel={() => setCancelModal(false)}
              onSubmit={() => history.push(constants.ROUTES.COMPANIES)}
            />
          </div>
        }
      />
    </>
  );
};
export default withRouter(FormMyCompanyDetails);
