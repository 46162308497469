import api from '../services/api'

const getPendingCustomersSuppliers = async (companyId, params) => {
  const { data } = await api.get(
    `/report/pending-customers-suppliers-report/${companyId}`,
    { params }
  )
  return data
}

export default {
  getPendingCustomersSuppliers,
}
