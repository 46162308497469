import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  consultingProtocolId: null,
  exportProtocols: [],
  initialDateExport: null,

  finalDateExport: null,
};

export const useExportXMLStore = create(
  persist(
    (set, get) => {
      return {
        ...initialState,

        setInitialDateExport: (initialDateExport) => set({ initialDateExport }),
        setFinalDateExport: (finalDateExport) => set({ finalDateExport }),

        setConsultingProtocolId: (consultingProtocolId) =>
          set({ consultingProtocolId }),

        setExportProtocols: (exportProtocols) => set({ exportProtocols }),
        changeReadyProtocol: (protocolType) => {
          const { exportProtocols } = get();

          const updatedProtocols = exportProtocols.map((protocol) =>
            protocol.type === protocolType
              ? {
                  ...protocol,
                  ready: true,
                }
              : protocol
          );

          set({
            exportProtocols: updatedProtocols,
          });
        },
        changeDownloadedProtocol: (protocolType) => {
          const { exportProtocols } = get();

          const updatedProtocols = exportProtocols.map((protocol) =>
            protocol.type === protocolType
              ? {
                  ...protocol,
                  downloaded: true,
                }
              : protocol
          );

          set({
            exportProtocols: updatedProtocols,
          });
        },
        cleanDownloadedProtocols: () => {
          const { exportProtocols } = get();

          const protocolsNotDownloaded = exportProtocols.some(
            (protocol) => !protocol.downloaded && protocol.total > 0
          );
          if (!protocolsNotDownloaded) {
            set(initialState);
          }
        },
      };
    },
    {
      name: 'export-xml-store',
    }
  )
);
