import React, { useState, useEffect } from 'react';
import '../LoginPage/styles.css';
import api from 'services/api';
import config from 'config';
import CryptoJS from 'crypto-js';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { useBranding } from 'hooks/useBranding';

import logoOSD from 'assets/img/osdigital.jpg';
import logoWM from 'assets/img/workmotor/logo.png';

const NewPassword = ({ location, history }) => {
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [eye, setEye] = useState('fa fa-eye-slash');
  const [eye2, setEye2] = useState('fa fa-eye-slash');
  const [loading, setLoading] = useState(false);
  const { isWorkmotor } = useBranding();

  const imgLogo = isWorkmotor ? logoWM : logoOSD;

  useEffect(() => {
    checkURL();
  }, []);

  const checkURL = async () => {
    const params = new URLSearchParams(location.search); // classe para pegar queryString da URL
    let userID = params.get('id');
    let time = params.get('time');

    if (!userID || !time) {
      return history.push('client-login');
    }

    /// TROCANDO ESPAÇO EM BRANCO POR + DO HASH
    while (userID.toString().includes(' ') || time.toString().includes(' ')) {
      userID = userID.replace(' ', '+');
      time = time.replace(' ', '+');
    }

    userID = CryptoJS.AES.decrypt(userID, 'userID'); // MÉTODO PARA DECRIPTAR string hasheada
    time = CryptoJS.AES.decrypt(time, 'reqTime'); // MÉTODO PARA DECRIPTAR string hasheada

    time = time.toString(CryptoJS.enc.Utf8);
    time = parseFloat(time);
    userID = userID.toString(CryptoJS.enc.Utf8);

    let timeNow = new Date().getTime();

    if (timeNow > time) {
      // CHECAR SE O LINK PARA REDEFINIR SENHA FOI CLICADO EM ATÉ 4 HORAS
      alert('Link para redefinir senha expirado');
      return history.push('/');
    }

    setUserId(userID);
  };

  const handleShowPassword = () => {
    if (eye === 'fa fa-eye') {
      setEye('fa fa-eye-slash');
    } else {
      setEye('fa fa-eye');
    }
  };

  const handleShowPassword2 = () => {
    if (eye2 === 'fa fa-eye') {
      setEye2('fa fa-eye-slash');
    } else {
      setEye2('fa fa-eye');
    }
  };

  const validatePasswords = (e) => {
    e.preventDefault();

    setLoading(true);
    if (password === '' || password2 === '') {
      setLoading(false);
      return toastr.error(
        'Senha vazia.',
        'Preencha todos os campos, por gentileza.'
      );
    }

    if (password !== password2) {
      setLoading(false);
      return toastr.error('Senha inválida.', 'As senhas devem ser iguais.');
    }

    if (password.length < 6 || password2.length > 8) {
      setLoading(false);

      // this.setState({ hidden: '' })
      return toastr.error(
        'Senha inválida.',
        'Senha tem que ser maior que 6 e menor que 8.'
      );
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    if (!userId) {
      setLoading(false);

      return toastr.warning(
        'Não foi possivel alterar senha',
        'Por favor, tente novamente!'
      );
    }

    const user = await api.get(config.endpoint + `users/${userId}`);

    if (!user.data.status) {
      setLoading(false);
      return toastr.warning(
        'Não foi possivel alterar senha',
        'Por favor, tente novamente!'
      );
    }

    await api.put(config.endpoint + `users/${userId}`, {
      User_Password: password,
    });

    setLoading(false);
    await toastr.success('Sucesso!', 'Senha alterada!');
    setTimeout(() => {
      history.push('/client-login');
    }, 2000);
  };

  return (
    <div id="client-new-password-page">
      <form onSubmit={validatePasswords} id="card-login">
        <header>
          <img src={imgLogo} alt={isWorkmotor ? 'Workmotor' : 'OS Digital'} />
          <center>
            <h5>Recuperar Senha</h5>
            <p>Crie uma nova senha de acesso abaixo</p>
          </center>
        </header>

        <main>
          <fieldset>
            <h5>
              Nova senha <span>*</span>
            </h5>
            <div className="password-div">
              <input
                type={eye === 'fa fa-eye' ? 'text' : 'password'}
                maxLength="18"
                className="form-control foco-input"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <span
                className={`fa fa-fw fa-eye ${eye}`}
                onClick={handleShowPassword}
              />
            </div>
          </fieldset>

          <fieldset>
            <h5>
              Confirmar senha <span>*</span>
            </h5>
            <div className="password-div">
              <input
                type={eye2 === 'fa fa-eye' ? 'text' : 'password'}
                maxLength="18"
                className="form-control foco-input"
                value={password2}
                onChange={(event) => setPassword2(event.target.value)}
              />
              <span
                className={`fa fa-fw fa-eye ${eye2}`}
                onClick={handleShowPassword2}
              />
            </div>
          </fieldset>
        </main>

        <footer>
          <button className="btn btn-sucesso" type="submit" disabled={loading}>
            <span
              className={loading && 'fa fa-spinner fa-pulse fa-1x'}
              style={{ marginRight: '5px' }}
            ></span>
            Confirmar
          </button>
        </footer>
      </form>
    </div>
  );
};

export default withRouter(NewPassword);
