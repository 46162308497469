import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';

import { toastr } from 'react-redux-toastr';
import { isAfter } from 'date-fns';

import useDebounce from 'hooks/useDebounce';

import { PromotionalPriceTableList } from './PromotionalPriceTableList';
import { PromotionalPriceTableFilter } from './PromotionalPriceTableFilter';
import AlertModal from 'components/AlertModal/AlertModal';

import PromotionalPriceTableRepository from 'repositories/PromotionalPriceTable';

import '../styles.css';

export default function Main({ history }) {
  const [tablePromotionalPricesList, setTablePromotionalPricesList] = useState(
    []
  );
  const [loading, setLoading] = useState(true);

  const [selectedTablePromotionalPrice, setSelectedTablePromotionalPrice] =
    useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [dateType, setDateType] = useState('initialDate');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [initialDateDebounced, setInitialDateDebounced] = useState('');
  const [finalDateDebounced, setFinalDateDebounced] = useState('');
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 800);
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 800);

  const [status, setStatus] = useState(null);

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const { companyId } = useAuth();

  useEffect(() => {
    loadPromotionalPriceTables();
  }, [
    currentPage,
    pageLimit,
    dateType,
    status,
    queryDebounced,
    initialDateDebounced,
    finalDateDebounced,
  ]);

  const loadPromotionalPriceTables = async () => {
    setLoading(true);
    try {
      validateDate();

      const params = {
        companyId,
        description: queryDebounced,
        limit: pageLimit,
        page: currentPage + 1,
        isActive: status,
        dateType,
        initialDate,
        finalDate,
      };

      const response = await PromotionalPriceTableRepository.getAllByCompany(
        params
      );

      setTablePromotionalPricesList(response?.promotionalTables);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as tabelas promocionais',
        'Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const validateDate = () => {
    if (initialDate && finalDate) {
      if (isAfter(new Date(initialDate), new Date(finalDate))) {
        handleChangeInitialDate('');
        handleChangeFinalDate('');
        setInitialDateDebounced('');
        setFinalDateDebounced('');

        return toastr.warning(
          'Datas inválidas',
          'A data inicial deve ser menor que a data final'
        );
      }
    } else {
      return;
    }
  };

  const handleChangeInitialDate = (value) => {
    setInitialDate(value);
    debouncedSaveInitialDate(value);
  };

  const handleChangeFinalDate = (value) => {
    setFinalDate(value);
    debouncedSaveFinalDate(value);
  };

  const handleUpdateStatus = async (tableId, status) => {
    try {
      await PromotionalPriceTableRepository.updateStatus(tableId, companyId);

      const newTablePromotionalPricesList = tablePromotionalPricesList.map(
        (table) => {
          if (table.id === tableId) {
            table.isActive = status;
          }
          if (table.isActive && table.id !== tableId) {
            table.isActive = false;
          }
          return table;
        }
      );

      setTablePromotionalPricesList(newTablePromotionalPricesList);
      toastr.success('Tabela atualizada com sucesso!');
    } catch (err) {
      handleUpdateError(err.response.data.message);
    }
  };

  const handleUpdateError = (error) => {
    switch (error) {
      case 'There is already an active promotional price table':
        toastr.warning(
          'Não foi possível ativar a tabela promocional',
          'Só é possível ativar uma tabela por vez. Inative a outra e tente novamente'
        );

        break;
      case 'Promotional price table is expired':
        toastr.warning(
          'Não foi possível ativar a tabela promocional',
          'A tabela de promoção está com a vigência expirada. Verifique e tente novamente'
        );

        break;
      default:
        toastr.error(
          'Erro ao atualizar a tabela promocional',
          'Tente novamente!'
        );
    }
  };

  const handleIsDeleteConfirmationModalOpen = (tableId) => {
    setIsDeleteConfirmationModalOpen(!isDeleteConfirmationModalOpen);
    setSelectedTablePromotionalPrice(tableId);
  };

  const handleDeleteTable = async () => {
    setIsDeleteConfirmationModalOpen(false);
    setLoading(true);
    try {
      await PromotionalPriceTableRepository.deleteTable(
        selectedTablePromotionalPrice
      );

      const newTablePromotionalPricesList = tablePromotionalPricesList.filter(
        (table) => table.id !== selectedTablePromotionalPrice
      );

      setTablePromotionalPricesList(newTablePromotionalPricesList);
      toastr.success(
        'Tabela excluída com sucesso!',
        'A tabela de promoção foi excluída com sucesso e não poderá mais ser usada na venda.'
      );
    } catch (err) {
      toastr.warning(
        'Não foi possível deletar a tabela promocional',
        'Verifique e tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PromotionalPriceTableFilter
        loading={loading}
        setStatus={setStatus}
        setDateType={setDateType}
        handleChangeQuery={handleChangeQuery}
        handleChangeInitialDate={handleChangeInitialDate}
        handleChangeFinalDate={handleChangeFinalDate}
        history={history}
        initialDate={initialDate}
        finalDate={finalDate}
      />

      <PromotionalPriceTableList
        loading={loading}
        tablePromotionalPricesList={tablePromotionalPricesList}
        handleUpdateStatus={handleUpdateStatus}
        handleIsDeleteConfirmationModalOpen={
          handleIsDeleteConfirmationModalOpen
        }
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />

      <AlertModal
        title="Exclusão"
        show={isDeleteConfirmationModalOpen}
        dialogClassName="modal-35w"
        message={
          <>
            <span>
              <strong>Você tem certeza que deseja excluir essa tabela?</strong>
            </span>
            <br />
            <br />
            <span>
              A partir do momento que for excluído, se ativa, as vendas não
              exibirão mais o preço promocional.
            </span>
          </>
        }
        onCancel={() => setIsDeleteConfirmationModalOpen(false)}
        onSubmit={() => {
          handleDeleteTable();
        }}
        onHide={() => setIsDeleteConfirmationModalOpen(false)}
      />
    </>
  );
}
