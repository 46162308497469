import React from 'react';
import {
  CardContainer,
  Container,
  IndicatorItem,
  IndicatorTotalBadge,
  Title,
  TitleContainer,
} from './IndicatorCard.styles';
import PropTypes from 'prop-types';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

export function IndicatorCard({
  type = 'single',
  variant,
  titleColor = 'white',
  textColor = 'white',
  showTotalBadge = false,
  title,
  secondaryTitle,
  value,
  secondaryValue,
  total,
  secondaryTotal,
  loading = false,
}) {
  return (
    <Container background={variant} type={type}>
      {type === 'single' && (
        <TitleContainer>
          <Title color={titleColor}>{title}</Title>
        </TitleContainer>
      )}
      <CardContainer background={variant}>
        {type === 'double' && (
          <TitleContainer>
            <Title color={titleColor}>{title}</Title>
            {showTotalBadge && (
              <IndicatorTotalBadge color={textColor} background={textColor}>
                Total: {total}
              </IndicatorTotalBadge>
            )}
          </TitleContainer>
        )}
        {loading ? (
          <LoadingSpinnerFullHeight />
        ) : (
          <IndicatorItem color={textColor}>
            {currency(value || 0)}
          </IndicatorItem>
        )}
      </CardContainer>
      {type === 'double' && (
        <CardContainer background={variant}>
          <TitleContainer>
            <Title color={titleColor}>{secondaryTitle}</Title>
            {showTotalBadge && (
              <IndicatorTotalBadge color={textColor} background={textColor}>
                Total: {secondaryTotal}
              </IndicatorTotalBadge>
            )}
          </TitleContainer>
          {loading ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <IndicatorItem color={textColor}>
              {currency(secondaryValue || 0)}
            </IndicatorItem>
          )}
        </CardContainer>
      )}
    </Container>
  );
}

IndicatorCard.propTypes = {
  type: PropTypes.oneOf(['single', 'double']),
  variant: PropTypes.oneOf(['white', 'indigo', 'red', 'blue']).isRequired,
  titleColor: PropTypes.oneOf(['white', 'indigo', 'red', 'gray']),
  textColor: PropTypes.oneOf(['white', 'indigo', 'red', 'gray']),
  showTotalBadge: PropTypes.bool,
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string,
  value: PropTypes.number.isRequired,
  secondaryValue: PropTypes.number,
  total: PropTypes.string,
  secondaryTotal: PropTypes.string,
};

IndicatorCard.defaultProps = {
  type: 'single',
  titleColor: 'white',
  textColor: 'white',
  showTotalBadge: false,
  secondaryTitle: '',
  secondaryValue: 0,
  total: '',
  secondaryTotal: '',
};
