import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { reduxForm, change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { isAfter, isEqual, format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import info from '../../../../../assets/img/info-ibpt.png';
import Select from 'client/views/Clients/NewClient/FormClient/components/Select';
import Input from 'client/views/Clients/NewClient/FormClient/components/Input';
import Button from 'client/components/CustomButton/CustomButton.jsx';

import CertificateDropZone from './CertificateDropZone';
import renderFieldSpan from 'client/components/Input/renderInputSpan';
import {
  onlyNumbers,
  cnpjMask,
  email,
  cpfMask,
} from 'client/components/ToNormalize/ToNormalize';
import { percentMask } from '../../../../../utils/masks';
import { getDateOnlyFromDate } from '../../../../../utils/dateHelpers';
import Toggle from 'react-toggle';

import '../../styles.css';
import { useEffect } from 'react';

import { useIBPT } from './hooks/useIBPT';
import { IBPTDropZone } from './IBPTDropZone';

const FormSettingsTaxData = ({
  loading,
  handleSubmit,
  pristine,
  companyId,
}) => {
  const [modalInfo, setModalInfo] = useState(false);
  const dispatch = useDispatch();
  const { renderIBPTExpireDate } = useIBPT();

  const [certificateNameValid, setCertificateNameValid] = useState(true);

  const {
    digitalCertificateExpireDate,
    isDigitalCertificateImported,
    printInvoicesAutomatically,
    issueInvoicesAutomatically,
    taxRegime,
    pCredSN,
    dateFinalValidityIBPTFile,
  } = useSelector((state) => state.form.settingsTaxData.values);

  const renderExpireDate = () => {
    return (
      <span style={{ color: isCertificateExpired ? 'red' : '' }}>
        {format(
          getDateOnlyFromDate(digitalCertificateExpireDate),
          'dd/MM/yyyy'
        )}
      </span>
    );
  };

  const isCertificateExpired =
    isEqual(new Date(), new Date(digitalCertificateExpireDate)) ||
    isAfter(new Date(), new Date(digitalCertificateExpireDate));

  const validateCertificateName = (file) => {
    //retirando extensão do nome do arquivo
    const fileName = file.name.replace(/\..+$/, '');

    if (fileName.trim().length > 15) {
      return toastr.warning(
        'O certificado possui um nome extenso. Altere o nome do certificado para no máximo 10 caracteres e tente novamente.'
      );
    }
  };

  const handleChangeTaxRegime = () => {
    if (
      taxRegime !== taxRegime.includes('Simples Nacional') &&
      taxRegime !== taxRegime.includes('MEI - Simples Nacional')
    ) {
      dispatch([change('settingsTaxData', 'pCredSN', 0)]);
    }
  };

  const handleUploadCertificate = (file) => {
    validateCertificateName(file);
    const fileName = file.name.replace(/\..+$/, '');

    if (fileName.trim().length <= 15) {
      dispatch([
        change('settingsTaxData', 'digitalCertificateFile', file),
        change('settingsTaxData', 'digitalCertificateNotImported', true),
      ]);
    }
  };

  return (
    <div className="company-tax-data-page">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '20rem' }}
          >
            <label>Regime Tributário</label>
            <Field
              name="taxRegime"
              component={Select}
              onChange={() => handleChangeTaxRegime()}
            >
              <option value="Simples Nacional">Simples Nacional</option>
              <option value="Simples Nacional - Excesso de Sublimite de Receita Bruta">
                Simples Nacional – Excesso de Sublimite de Receita Bruta
              </option>
              <option value="MEI - Simples Nacional">
                MEI - Simples Nacional
              </option>
              <option value="Lucro Presumido">Lucro Presumido</option>
              <option value="Lucro Real">Lucro Real</option>
            </Field>
          </div>
          {taxRegime.includes('Simples Nacional') ||
            (taxRegime.includes('MEI - Simples Nacional') && (
              <div
                style={{
                  width: '12rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Field
                  label="Alíquota SN"
                  name="pCredSN"
                  maxLength="7"
                  component={Input}
                  {...percentMask}
                />
              </div>
            ))}
          <div style={{ width: '12rem' }}>
            <Field
              label="Alíquota PIS"
              name="PIS"
              maxLength="7"
              component={Input}
              {...percentMask}
            />
          </div>
          <div style={{ width: '13rem' }}>
            <Field
              label="Alíquota COFINS"
              name="COFINS"
              maxLength="7"
              component={Input}
              {...percentMask}
            />
          </div>
          <Field
            label="CPF/CNPJ da Contabilidade"
            name="accountingCpfCnpj"
            component={Input}
            normalize={(value) =>
              value?.length > 14 ? cnpjMask(value) : cpfMask(value)
            }
            maxLength="18"
          />
          <Field
            label="Email para receber XML:"
            name="accountingEmail"
            component={Input}
            normalize={email}
            style={{ width: '220px' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
            <label>
              Inscrição Estadual <span style={{ color: 'red' }}>*</span>
            </label>
            <Field
              name="IE"
              component={Input}
              normalize={(value) => onlyNumbers(value)}
              style={{ width: '130px' }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '45rem' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <label>Token IBPT - De Olho no Imposto </label>
              <a
                className="info-button"
                onClick={() => setModalInfo(true)}
                style={{
                  fontSize: '12px',
                  textDecoration: 'underline',

                  textAlign: 'end',
                }}
              >
                O que é Token IBPT?
              </a>
            </div>
            <Field
              name="tokenIBPT"
              component={Input}
              maxLength="100"
              normalize={(value) => value.trim()}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="digitalCertificate">Tabela IBPT:</label>
            <IBPTDropZone
              companyId={companyId}
              isDateValidityIBPTFile={!!dateFinalValidityIBPTFile}
            />
            {dateFinalValidityIBPTFile !== '' && (
              <div
                style={{
                  textAlign: 'right',
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'flex-end',
                }}
              >
                Válido até
                <strong>
                  {renderIBPTExpireDate(dateFinalValidityIBPTFile)}
                </strong>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="digitalCertificate">
              Certificado Digital A1 (.pfx)
            </label>
            <CertificateDropZone onUpload={handleUploadCertificate} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Senha do Certificado</label>
            <Field
              name="digitalCertificatePassword"
              component={renderFieldSpan}
              normalize={(value) => value.trim()}
            />
            <div
              style={{ textAlign: 'right' }}
              className={isDigitalCertificateImported ? '' : 'hidden'}
            >
              Válido até{' '}
              <strong>
                {digitalCertificateExpireDate
                  ? renderExpireDate()
                  : 'DD/mm/aaaa'}
              </strong>
            </div>
          </div>

          <div className="toggle-column">
            <label>Imprimir notas automaticamente</label>
            <Toggle
              style={{ padding: 0 }}
              checked={printInvoicesAutomatically}
              onChange={() =>
                dispatch(
                  change(
                    'settingsTaxData',
                    'printInvoicesAutomatically',
                    !printInvoicesAutomatically
                  )
                )
              }
            />
          </div>
        </div>
        <div className="toggle-column">
          <label>Emissão ao fechar venda/os</label>
          <Toggle
            style={{ padding: 0 }}
            checked={issueInvoicesAutomatically}
            onChange={() =>
              dispatch(
                change(
                  'settingsTaxData',
                  'issueInvoicesAutomatically',
                  !issueInvoicesAutomatically
                )
              )
            }
          />
        </div>

        <br />
        <Button bsStyle="info" fill onClick={handleSubmit} disabled={loading}>
          <span
            className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
            style={{ marginRight: loading ? '5px' : '' }}
          />
          Atualizar
        </Button>
      </div>

      <Modal
        show={modalInfo}
        onHide={() => setModalInfo(false)}
        dialogClassName="modal-info"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Informações Token IBPT - De Olho no Imposto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            O Token IBPT é um método disponibilizado para obter as alíquotas do
            de Olho no Imposto de forma automática.
            <br />
            Necessário apenas que realize o cadastro de sua empresa{' '}
            <a
              href="https://deolhonoimposto.ibpt.org.br/Site/Entrar"
              alt=""
              target="_blank"
            >
              clicando aqui
            </a>
            .
          </p>
          <img src={info} alt="informações de Olho no Imposto" />
          <p style={{ marginTop: '10px' }}>
            Caso tenha dúvidas, entre em contato com o seu contador(a).
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              name="Voltar"
              onClick={() => setModalInfo(false)}
              fill
            >
              Voltar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'settingsTaxData',
})(FormSettingsTaxData);
