import React from 'react';
import Toggle from 'react-toggle';
import { Col } from 'react-bootstrap';
import { change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';

const MainData = () => {
  const dispatch = useDispatch();
  const {
    advancedPricing,
    automaticFreightApportionment,
    automaticApportionmentOfOtherExpenses,
    automaticInsurancePooling,
  } = useSelector((state) => state.form.purchaseConfiguration.values);

  function handleChangeAdvancedPricing() {
    dispatch(
      change('purchaseConfiguration', 'advancedPricing', !advancedPricing)
    );

    if (advancedPricing) {
      dispatch(
        change('purchaseConfiguration', 'automaticFreightApportionment', false)
      );
      dispatch(
        change(
          'purchaseConfiguration',
          'automaticApportionmentOfOtherExpenses',
          false
        )
      );
      dispatch(
        change('purchaseConfiguration', 'automaticInsurancePooling', false)
      );
    }
  }

  function handleChangeAutomaticFreightApportionment() {
    if (!advancedPricing) {
      toastr.warning(
        'Não permitido',
        'Funções de rateio automática disponíveis apenas para a tela de alteração de preço avançado'
      );
      return;
    }
    dispatch(
      change(
        'purchaseConfiguration',
        'automaticFreightApportionment',
        !automaticFreightApportionment
      )
    );
  }

  function handleChangeAutomaticApportionmentOfOtherExpenses() {
    if (!advancedPricing) {
      toastr.warning(
        'Não permitido',
        'Funções de rateio automática disponíveis apenas para a tela de alteração de preço avançado'
      );
      return;
    }
    dispatch(
      change(
        'purchaseConfiguration',
        'automaticApportionmentOfOtherExpenses',
        !automaticApportionmentOfOtherExpenses
      )
    );
  }

  function handleChangeAutomaticInsurancePooling() {
    if (!advancedPricing) {
      toastr.warning(
        'Não permitido',
        'Funções de rateio automática disponíveis apenas para a tela de alteração de preço avançado'
      );
      return;
    }
    dispatch(
      change(
        'purchaseConfiguration',
        'automaticInsurancePooling',
        !automaticInsurancePooling
      )
    );
  }

  return (
    <div className="purchase-form-main-data">
      <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
        <label>Alteração de preço avançado:</label>
        <Toggle
          checked={advancedPricing}
          onChange={handleChangeAdvancedPricing}
        />
      </Col>
      <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
        <label>Rateio automático de Frete:</label>
        <Toggle
          checked={automaticFreightApportionment}
          onChange={handleChangeAutomaticFreightApportionment}
        />
      </Col>
      <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
        <label>Rateio automático de Outras Despesas:</label>
        <Toggle
          checked={automaticApportionmentOfOtherExpenses}
          onChange={handleChangeAutomaticApportionmentOfOtherExpenses}
        />
      </Col>
      <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
        <label>Rateio automático de Seguro:</label>
        <Toggle
          checked={automaticInsurancePooling}
          onChange={handleChangeAutomaticInsurancePooling}
        />
      </Col>
    </div>
  );
};

export default MainData;
