import React, { useState } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { format, subDays } from 'date-fns';
import PDFButton from 'client/components/PDFButton';
import IssuedSlipRepository from 'repositories/IssuedSlip';
import './styles.css';

export default function HomeIssuedSlip() {
  const { companyId } = useAuth();
  const [typeOfDate, setTypeOfDate] = useState('dueDate');
  const [initialDate, setInitialDate] = useState(
    format(subDays(new Date(), 30), 'yyyy-MM-dd')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [statusSlip, setStatusSlip] = useState('');
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await IssuedSlipRepository.generateIssuedSlip({
        companyId,
        typeOfDate,
        initialDate,
        finalDate,
        statusSlip,
      });

      setResult(data);
      if (initialDate && finalDate && data.issuedSlipsList.length) {
        window.open(
          window.location.origin +
            `/report/issued-slip?typeOfDate=${typeOfDate}&initialDate=${initialDate}&finalDate=${finalDate}&statusSlip=${statusSlip}&companyId=${companyId}`,
          '_blank'
        );
      } else {
        return toastr.warning(
          'Não foi possível gerar o relatório.',
          'Nenhum item foi localizado. Verifique os filtros e tente novamente.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="issued-slip-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Tipo de Data:</strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={typeOfDate}
          onChange={(e) => setTypeOfDate(e.target.value)}
        >
          <option value="issuedAt">Criação de boleto</option>
          <option value="lowDate">Pagamento</option>
          <option value="dueDate" selected>
            Vencimento
          </option>
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
          max={finalDate ? finalDate : format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
          min={initialDate}
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Boletos: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={statusSlip}
          onChange={(e) => setStatusSlip(e.target.value)}
        >
          <option value="" selected>
            Todos
          </option>
          <option value="allSlip">Abertos - Todos</option>
          <option value="allExpiredSlip">Abertos - Vencidos</option>
          <option value="allPaidSlip">Pagos</option>
        </select>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <PDFButton isLoading={isLoading} onClick={handleSubmit} />
      </div>
    </div>
  );
}
