import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { onlyNumbers, phoneMask } from '../../components/ToNormalize/ToNormalize'
import CustomButton from '../../components/CustomButton/CustomButton.jsx'
import AlertModal from 'components/AlertModal'

import config from '../../../config'

const SendWhatsAppModal = ({ onCancel, customer, onSubmit }) => {
  const [message, setMessage] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const phone = customer.Phones?.length ? customer.Phones[0].Number_Phone2 : 0

  async function handleSendMessage() {
    if (!message.length) {
      return
    }
    await window.open(config.whatsappendpoint + `${onlyNumbers(phone)}?text=${message}&lang=pt_br`, '_blank')
    onSubmit()
  }

  return (
    <>
      <Modal
        show
        onHide={() => !message.length
          ? onCancel()
          : setIsCancelModalOpen(true)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='header-modal-sendWhatsapp' >
              <h4 style={{ marginBottom: '0%', marginTop: '2%' }}>
                <strong>
                  {customer.Company_Name}
                </strong>
              </h4>
              <p>
                <strong>CPF: </strong>
                {customer.Cpf_Cnpj}
              </p>
              <p>
                <strong>Telefone: </strong>{phoneMask(phone)}
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Informe abaixo a mensagem que deseja enviar para o cliente.</span>
          <textarea
            maxLength='600'
            className='foco-input form-control'
            style={{
              resize: 'vertical'
            }}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <CustomButton
              bsStyle="danger"
              name="Cancelar"
              onClick={() => !message.length ? onCancel() : setIsCancelModalOpen(true)}
              fill
            >
              Cancelar
            </CustomButton>

            <CustomButton bsStyle="info" name="Enviar" fill onClick={handleSendMessage}>
              Enviar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isCancelModalOpen}
        message='Deseja realmente cancelar o envio da mensagem ?'
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </>
  )
}

export default SendWhatsAppModal