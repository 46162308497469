import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton'

import './styles.css'

export default function BankSlipErrorsModal({
  onCancel,
  show,
  errors,
  statusCode,
}) {
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {errors.map((error, index) => (
            <strong key={index}>
              Código {statusCode} - {error.title}
            </strong>
          ))}
        </div>

        <div className='message-container'>
          <p>
            Houve um erro no momento de atualizar o boleto. O boleto não foi
            atualizado, possívelmente houve uma falha de comunicação, ou o
            boleto já foi pago
          </p>

          <span className='contact-support-message'>
            Caso o problema persista, entre em contato com o suporte e informe
            este aviso
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle='danger' onClick={onCancel}>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
