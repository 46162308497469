import api from 'v2/services/api';

const generateInvoicesReport = async (params) => {
  try {
    const response = await api.get(`report/invoices/list`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const InvoicesReportRepository = {
  generateInvoicesReport,
};

export default InvoicesReportRepository;
