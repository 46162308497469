import React, { useState } from 'react'
import { reduxForm } from 'redux-form'

import FormSubmitButtons from 'client/components/FormSubmitButtons/index.js';
import CardForm from 'client/components/CardForm/index.js';
import DadosBasicos from './DadosBasicos'
import { useHistory } from 'react-router-dom'
import constants from '../../../../utils/constants'
import AlertModal from 'components/AlertModal/AlertModal.js'

const FormUser = ({ loading, userId, handleSubmit, pristine, modal, handleModal }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const history = useHistory()

  const handleCancelModal = () => {
    if (!pristine) return setIsCancelModalOpen(true)
    cancel()
  }
  const cancel = () => {
    if (modal) return handleModal(true)
    history.push(constants.ROUTES.USERS)
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }} >
        <CardForm title='Dados do Usuário'>
          <DadosBasicos userId={userId} />
        </CardForm>

        <FormSubmitButtons onCancel={handleCancelModal} hasId={!!userId} onSubmit={handleSubmit} loading={loading} />

      </form>

      <AlertModal
        show={isCancelModalOpen}
        animation={true}
        message={`Deseja cancelar ${!!userId ? 'a edição' : 'o cadastro'} do usuário? Todos os campos preenchidos não serão salvos e ${!!userId ? 'a edição' : 'o cadastro'} poderá ser realizad${!!userId ? 'a' : 'o'} posteriormente.`}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={() => modal ? handleModal(true) : history.push(constants.ROUTES.USERS)} />
    </>
  )
}

export default reduxForm({
  form: 'formUser',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormUser)