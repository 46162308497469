import React from 'react'
import {
  faBoxOpen,
  faAddressBook,
  faTools,
  faMoneyBillAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import Card from './Card'

import {
  phoneMask,
  cnpjMask,
  cpfMask,
  currency,
  percentage,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

const styles = {
  line: {
    border: '1px solid black',
  },
  mainPanel: {
    marginBottom: '20px',
    marginTop: '20px',
    width: '100%',
    boxShadow: '5px 10px 18px #d6d6d6',
    background: 'white',
  },
  gridCompany: {
    display: 'grid',
    gridTemplateRows: '15% 15% 15% 15%',
    marginTop: '3%',
  },
  spaceMainPanel: {
    padding: '10px',
    marginLeft: '10px',
  },
  fontConteudo: {
    fontSize: '14px',
    color: '#444',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '350px 350px',
  },
  grid3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '10px 20px',
  },
  borderRigth: {
    marginRight: '10px',
    borderRight: '1px solid #d6d6d6',
  },
  borderBottom: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom2: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom1: {
    borderBottom: '1px solid #d6d6d6',
    width: '42%',
  },
  value: {
    marginTop: '90px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '500px',
    fontSize: '16px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  imgCompany: {
    width: '100px',
    height: '100px',
    marginLeft: '-10px',
    marginRight: '-15px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
}

const RenderQuickQuoteMobile = ({ sale, quickQuote }) => {
  const company = quickQuote.Company
  const companyPhones = company.Phones[0]

  const customerName = quickQuote.customerName
  const customerTelephone = quickQuote.customerTelephone
  const customerEmail = quickQuote.customerEmail
  const quickQuoteVehicle = quickQuote.vehicle
  const licensePlate = quickQuote.licensePlate
  const observations = quickQuote.observations
  const quickQuoteItems = quickQuote?.QuickQuotesItems

  return (
    <div id='print' style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
      <div style={styles.mainPanel}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            padding: '20px',
          }}
        >
          <div>
            <p style={{ fontSize: '17px' }}>
              <strong>{company.Company_Name}</strong>
            </p>
            <p style={{ fontSize: '14px', marginTop: '-4px' }}>
              {company.Trading_Name}
            </p>
            <span style={{ marginTop: '5px' }}>
              {company.Address[0].Address +
                ', ' +
                company.Address[0].Address_Number +
                ' - ' +
                company.Address[0].Neighborhood}{' '}
              -{' '}
            </span>{' '}
            &nbsp;
            <span>
              {company.Address[0].City + ' /' + company.Address[0].State} -{' '}
              {cepMask(company.Address[0].Zipcode)}
            </span>
          </div>
          <div>
            {company.Url_Logo ? (
              <div style={styles.spaceMainPanel}>
                <img
                  style={styles.imgCompany}
                  src={company.Url_Logo}
                  alt='OS Digital'
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            paddingLeft: '20px',
            paddingBottom: '20px',
          }}
        >
          <span>
            {phoneMask(
              companyPhones.Number_Phone1
                ? companyPhones.Number_Phone1
                : companyPhones.Number_Phone2
            )}{' '}
            - {company.Email}
          </span>
        </div>
      </div>

      <Card icon={faAddressBook} title='Dados do Cliente'>
        <div>
          <div style={{ ...styles.fontConteudo, ...styles.borderRigth }}>
            <div>
              <span>
                <strong>Nome Completo</strong>
              </span>
            </div>
            <div style={styles.borderBottom}>
              <span>CPF/CNPJ:</span>
            </div>
            <div style={styles.borderBottom}>
              <span>
                Nome: <strong>{customerName}</strong>
              </span>
            </div>
            <div style={styles.borderBottom}>
              Telefone:
              <strong>{phoneMask(customerTelephone || '')}</strong>
            </div>

            <div style={styles.borderBottom}>
              Email: <strong>{customerEmail}</strong>
            </div>
            <span>Contato: </span>
          </div>
          <div style={styles.fontConteudo}>
            <span>
              <strong>Endereço Principal</strong>
            </span>
            <div style={styles.borderBottom}>
              <span>CEP:</span>
            </div>
            <div style={styles.borderBottom}>Endereço:</div>
            <div style={styles.borderBottom}>Nº:</div>
            <div>Cidade/UF:</div>
          </div>
        </div>
      </Card>

      <Card icon={faTools} title='Dados do Veículo'>
        <div>
          <div style={{ ...styles.fontConteudo, ...styles.borderRigth }}>
            <div style={styles.borderBottom}>
              Placa: <strong>{licensePlate}</strong>
            </div>
            <div style={styles.borderBottom}>
              Veículo: <strong>{quickQuoteVehicle}</strong>
            </div>
            <div style={styles.borderBottom}>Marca:</div>
            <span>KM Atual:</span>
          </div>
          <div style={styles.fontConteudo}>
            <div style={styles.borderBottom2}>
              <div style={{ marginBottom: '15px' }}>Observação:</div>{' '}
              <strong>{observations}</strong>
            </div>
            <div style={styles.borderBottom2}>
              <div style={{ marginBottom: '15px' }}>Defeitos: </div>
            </div>
            <div>
              <div style={{ marginBottom: '15px' }}>Avarias:</div>
            </div>
          </div>
        </div>
      </Card>

      <Card icon={faBoxOpen} title='Compartilhar'>
        <div style={{ fontSize: '14px' }}>
          <div style={styles.borderBottom1}>
            <span>
              Número:
              <strong> {quickQuote.code}</strong>
            </span>
          </div>
          <div>
            <span>
              Data:
              <strong>
                {' '}
                {format(
                  new Date(getDateOnlyFromDate(quickQuote.createdAt)),
                  'dd/MM/yyyy'
                )}
              </strong>
            </span>
          </div>
        </div>

        <div>
          <table style={{ width: '100%', margin: '10px', textAlign: 'center' }}>
            <thead style={styles.borderBottom}>
              <tr>
                <th style={{ textAlign: 'center', maxWidth: '30px' }}>Cód.</th>
                <th style={{ textAlign: 'center' }}>Descrição</th>
              </tr>
              <tr style={styles.borderBottom}>
                <th style={{ textAlign: 'center' }}>Qtd.</th>
                <th style={{ textAlign: 'center' }}>Valor Unit.</th>
                <th style={{ textAlign: 'center' }}>Desc Unit.</th>
                <th style={{ textAlign: 'center' }}>Valor Total </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '14px' }}>
              {quickQuoteItems.map((item, index) => (
                <>
                  <tr key={index * -1}>
                    <td style={{ maxWidth: '30px' }}>
                      {!!item.productId
                        ? item.Products.Code
                        : item.Services.Code}
                    </td>
                    <td>{item.description}</td>
                  </tr>
                  <tr key={index}>
                    <td>{item.quantity}</td>
                    <td>{currency(item.unitValue)}</td>
                    <td>{percentage(item.discountValue)}</td>
                    <td>{currency(item.amount)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      <Card
        icon={faMoneyBillAlt}
        title='Informações de Pagamento'
        className='grid-3'
      >
        <div style={styles.flexColumn}>
          <span>
            <strong>Total:</strong> {currency(quickQuote.finalValue)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(
              !quickQuote.Discount_Value ? 0 : quickQuote.totalDiscountValue
            )}{' '}
            (
            {percentage(
              !quickQuote.totalDiscountPercentage
                ? 0
                : quickQuote.totalDiscountPercentage
            )}
            )
          </span>
        </div>

        <div style={styles.flexColumn}>
          <span>
            <strong>Total de Produtos:</strong>{' '}
            {currency(!quickQuote.productsValue ? 0 : quickQuote.productsValue)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(
              !quickQuote.productsDiscountValue
                ? 0
                : quickQuote.productsDiscountValue
            )}{' '}
            (
            {percentage(
              !quickQuote.productsDiscountPercentage
                ? 0
                : quickQuote.productsDiscountPercentage
            )}
            )
          </span>
        </div>

        <div style={styles.flexColumn}>
          <span>
            <strong>Total de Serviços:</strong>{' '}
            {currency(!quickQuote.servicesValue ? 0 : quickQuote.servicesValue)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(
              !quickQuote.servicesDiscountValue
                ? 0
                : quickQuote.servicesDiscountValue
            )}{' '}
            (
            {percentage(
              !quickQuote.servicesDiscountPercentage
                ? 0
                : quickQuote.servicesDiscountPercentage
            )}
            )
          </span>
        </div>
      </Card>
    </div>
  )
}

export default RenderQuickQuoteMobile
