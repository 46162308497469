import React from "react";
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'
import ProductServiceReviewsWarrantyMain from './components/ProductServiceReviewsWarrantyMain'

const ProductServiceReviewsWarrant = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Venda', 'Revisões/Garantias de Produtos e Serviços']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <ProductServiceReviewsWarrantyMain />
        </Row>
      </Grid>
    </div>
  );
}
export default ProductServiceReviewsWarrant