import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import { change } from 'redux-form';
import NFesRepository from '../../../../repositories/NFes';
import companiesRepository from 'repositories/Companies';
import ErrosModal from './ErrorsModal';
import FormNewNFe from './FormNewNFe';
import InvoiceProcessLoader from 'client/components/InvoiceProcessLoader';
import PureLoader from '../../../../components/PureLoader';

import {
  cnpjMask,
  cpfMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';
import {
  INVOICE_STATUS_DESCRIPTION,
  INVOICE_FINALITY,
} from '../../../../utils/constants';
import { useAuth } from 'contexts/auth';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { getInvoiceError } from 'v2/repositories/InvoiceErrorsRepository';

const BundleNewNFe = ({ setInvoiceCode, invoiceId, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [isLoadingValidations, setIsLoadingValidations] = useState(false);

  const [loadingNFe, setLoadingNFe] = useState(false);
  const [showErrosModal, setShowErrosModal] = useState(false);

  const { company } = useAuth();

  const companyId = localStorage.getItem('ID_EMPRESA');
  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceId) {
      getNfe();
    }
  }, [invoiceId]);

  function setStatus({ status, situationDescription, situationCode }) {
    if (status !== 'Aberta' && status !== 'Contingência') {
      return situationCode + ' - ' + situationDescription;
    }
    return status;
  }

  async function loadInvoiceError(situationCode) {
    try {
      const res = await getInvoiceError(situationCode);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }

  async function getNfe(retry) {
    setLoadingNFe(true);

    try {
      const nfe = await NFesRepository.show(invoiceId, false);
      const statusNFe = setStatus(nfe);
      setInvoiceCode(nfe.code);

      const customer = nfe.Customer || {};
      const provider = nfe.Provider || {};

      const customersList = await indexCustomersWithVehicles({
        company_id: companyId,
        page: 1,
        limit: 10,
        query: customer.Trading_Name || customer.Company_Name,
        show_label: false,
        show_vehicles: false,
      });

      const currentCustomer = customersList.rows.find(
        (item) => item.customer_id === nfe.customerId
      );

      const currentCustomerLabel = currentCustomer
        ? `${currentCustomer.customer_name} ${
            currentCustomer.customer_cpfcnpj &&
            `- ${cpfOrCnpjMask(currentCustomer.customer_cpfcnpj)}`
          }`
        : '';

      let invoiceError = null;

      try {
        invoiceError = await loadInvoiceError(nfe.situationCode);
      } catch (err) {}

      const nfeObject = {
        code: nfe.code,
        NFeId: nfe.id,
        status: statusNFe,
        saleParcels: nfe.Sales?.Payments?.Parcels,
        saleCode: nfe.Sales?.Code,
        saleId: nfe.Sales?.id,
        manySale: nfe.manySales,
        NFCeCodes: nfe.NFCeCodes,
        salesIds:
          nfe.manySales.length > 0
            ? nfe.manySales?.map((sale) => sale.Code).join(', ')
            : null,
        isOpenViewSalesNFeModal: false,
        accessKey: nfe.accessKey,
        NFCeCode: nfe.NFCeCode,
        issuedAt: !nfe.issuedAt
          ? ''
          : format(new Date(nfe.issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(nfe.issuedAt), 'hh:mm'),
        CFOP: nfe.CFOP,
        isCalculateICMSWithOtherExpenses:
          nfe.cfopData?.calculateICMSWithOtherExpenses,
        NFCeId: nfe.NFCeId,
        date: format(new Date(nfe.date), 'yyyy-MM-dd'),
        finality: nfe.finality,
        serie: nfe.serie,
        customerId: nfe.customerId,
        customerName: customer.Trading_Name || customer.Company_Name,
        customerCpfCnpj:
          customer.Cpf_Cnpj?.length === 11
            ? cpfMask(customer.Cpf_Cnpj || '')
            : cnpjMask(customer.Cpf_Cnpj || ''),
        customerIE: customer.IE,
        providerId: nfe.providerId,
        providerName: provider.tradingName || provider.companyName,
        providerCpfCnpj:
          provider.cpfCnpj?.length === 11
            ? cpfMask(provider.cpfCnpj || '')
            : cnpjMask(provider.cpfCnpj || ''),
        providerIE: provider.type === 'Juridica' ? provider.IE : 'ISENTO',
        BCICMS: nfe.BCICMS,
        ShowBCICMS: nfe.BCICMS,
        BCICMSST: nfe.BCICMSST,
        COFINSValue: nfe.COFINSValue,
        FCPSTValue: nfe.FCPSTValue,
        FCPValue: nfe.FCPValue,
        ICMSSTValue: nfe.ICMSSTValue,
        ICMSValue: nfe.ICMSValue,
        ShowICMSValue: nfe.ICMSValue,
        IPIValue: nfe.IPIValue,
        PISValue: nfe.PISValue,
        discountValue: nfe.discountValue,
        shippingCompanyId: nfe.shippingCompanyId,
        freightIndicator: String(nfe.freightIndicator),
        brand: nfe.brand,
        freightValue: nfe.freightValue,
        grossWeight: nfe.grossWeight,
        inssuranceValue: nfe.inssuranceValue,
        licensePlate: nfe.licensePlate,
        netWeight: nfe.netWeight,
        otherExpenses: nfe.otherExpenses,
        presenceIndicator: nfe.presenceIndicator,
        specie: nfe.specie,
        subTotal: nfe.subTotal,
        situationCode: nfe.situationCode,
        total: nfe.total,
        volume: nfe.volume,
        NFeItems: nfe.NFeItems,
        isInvoiceIssued: nfe.status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          nfe.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
        isInvoiceIssuedInContingency:
          nfe.status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY,
        isInvoiceDenied: nfe.status === INVOICE_STATUS_DESCRIPTION.DENIED,
        isInvoiceCanceled: nfe.status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        intermediaryId: nfe.intermediaryId,
        referenceAccessKey: nfe.referenceAccessKey,
        additionalInformation: nfe.additionalInformation,
        selectedTextsForInvoices: nfe.TextForInvoices?.map((text) => text.id),
        selectedCustomer: currentCustomer
          ? {
              ...currentCustomer,
              label: currentCustomerLabel,
            }
          : {},
        defaultType: nfe.customerId
          ? 'customer'
          : nfe.providerId
          ? 'provider'
          : null,
        invoiceError,
      };

      const fields = [
        'code',
        'NFeId',
        'status',
        'saleParcels',
        'saleCode',
        'saleId',
        'accessKey',
        'NFCeCode',
        'issuedAt',
        'CFOP',
        'NFCeId',
        'date',
        'finality',
        'serie',
        'customerId',
        'customerName',
        'customerCpfCnpj',
        'customerIE',
        'providerId',
        'providerName',
        'providerCpfCnpj',
        'providerIE',
        'BCICMS',
        'ShowBCICMS',
        'BCICMSST',
        'COFINSValue',
        'FCPSTValue',
        'FCPValue',
        'isCalculateICMSWithOtherExpenses',
        'ICMSSTValue',
        'ICMSValue',
        'ShowICMSValue',
        'IPIValue',
        'PISValue',
        'discountValue',
        'shippingCompanyId',
        'freightIndicator',
        'brand',
        'freightValue',
        'grossWeight',
        'inssuranceValue',
        'licensePlate',
        'netWeight',
        'otherExpenses',
        'presenceIndicator',
        'specie',
        'subTotal',
        'situationCode',
        'total',
        'volume',
        'NFeItems',
        'isInvoiceIssued',
        'isInvoiceOpenedWithErrors',
        'isInvoiceIssuedInContingency',
        'isInvoiceDenied',
        'isInvoiceCanceled',
        'intermediaryId',
        'referenceAccessKey',
        'additionalInformation',
        'defaultType',
        'selectedTextsForInvoices',
        'selectedCustomer',
        'invoiceError',
        'manySale',
        'isOpenViewSalesNFeModal',
        'salesIds',
        'NFCeCodes',
      ];

      fields.map((f) => {
        dispatch(change('NFe', f, nfeObject[f]));
      });

      if (retry) {
        dispatch(change('NFe', 'toEmit', true));
        const obj = {
          ...nfeObject,
          toEmit: true,
        };
        await handleSubmit(obj);
      }
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar a NF-e. Por favor, tente novamente'
      );
    } finally {
      setLoadingNFe(false);
    }
  }

  const handleSubmit = (values) => {
    const {
      CFOP,
      customerId,
      freightIndicator,
      providerId,
      referenceAccessKey,
      finality,
      NFeItems,
      intermediaryId,
      presenceIndicator,
      licensePlate,
      NFeId,
      toEmit,
    } = values;
    if (!CFOP || (!customerId && !providerId)) {
      return toastr.warning('Campos obrigatórios não preenchidos');
    }

    const hasItemWithInvalidEntries = NFeItems.some(
      (item) =>
        !item.quantity ||
        item.quantity === '0' ||
        !item.unitValue ||
        !item.CSTCSOSN ||
        !item.CFOP
    );
    if (hasItemWithInvalidEntries) {
      return toastr.warning('A NF-e possui itens sem informações');
    }

    if (
      !intermediaryId &&
      (presenceIndicator === '2' ||
        presenceIndicator === '3' ||
        presenceIndicator === '9')
    ) {
      return toastr.warning(
        'Intermediador não preenchido',
        ' É obrigatório o preenchimento do intermediador quando a venda não for presencial, conforme determinação da SEFAZ. Faça o preenchimento e tente novamente.'
      );
    }

    if (toEmit && !NFeItems.length) {
      dispatch(change('NFe', 'toEmit', false));
      return toastr.warning('Insira ao menos um item para emissão da NF-e');
    }

    if (finality === INVOICE_FINALITY.DEVOLUTION) {
      if (!referenceAccessKey) {
        return toastr.warning(
          'Chave de Acesso da nota original obrigatório para Nota de Devolução'
        );
      }

      if (referenceAccessKey.length < 44) {
        return toastr.warning('Chave de Acesso da nota original incompleta');
      }

      const hasItemWithNoQuantityDevolution = NFeItems.some(
        (item) =>
          !item.quantityDevolution ||
          parseFloat(item.quantityDevolution) > parseFloat(item.quantity) ||
          parseFloat(item.quantityDevolution) < 0
      );
      if (hasItemWithNoQuantityDevolution) {
        return toastr.warning(
          'A NF-e possui itens com quantidade devolvida incorreta'
        );
      }
    }

    if (!NFeId) {
      create(values);
    } else {
      update(values);
    }
  };

  const create = async (values) => {
    const {
      BCICMS,
      BCICMSST,
      CFOP,
      status,
      COFINSValue,
      FCPSTValue,
      FCPValue,
      ICMSSTValue,
      ICMSValue,
      IPIValue,
      NFeItems,
      PISValue,
      accessKey,
      referenceAccessKey,
      issuedAt,
      additionalInformation,
      brand,
      customerCpfCnpj,
      customerIE,
      customerId,
      providerCpfCnpj,
      providerIE,
      providerId,
      date,
      discountValue,
      freightIndicator,
      freightValue,
      grossWeight,
      finality,
      inssuranceValue,
      licensePlate,
      netWeight,
      otherExpenses,
      presenceIndicator,
      serie,
      shippingCompanyId,
      specie,
      subTotal,
      volume,
      total,
      toEmit,
      textsForInvoice,
      intermediaryId,
    } = values;

    const serializedNFeItems = NFeItems.map(({ id, ...rest }) => ({ ...rest }));
    setLoading(true);

    try {
      const NFe = await NFesRepository.create({
        NFe: {
          finality,
          status,
          CFOP,
          date,
          issuedAt,
          accessKey,
          referenceAccessKey,
          customerCpfCnpj: !customerCpfCnpj
            ? null
            : onlyNumbers(customerCpfCnpj),
          customerIE,
          providerCpfCnpj: !providerCpfCnpj
            ? null
            : onlyNumbers(providerCpfCnpj),
          providerIE,
          providerId,
          freightIndicator,
          licensePlate,
          volume,
          grossWeight,
          netWeight,
          specie,
          brand,
          inssuranceValue,
          freightValue,
          BCICMS,
          ICMSValue,
          BCICMSST,
          ICMSSTValue,
          COFINSValue,
          PISValue,
          IPIValue,
          FCPValue,
          FCPSTValue,
          presenceIndicator,
          additionalInformation,
          otherExpenses,
          subTotal: subTotal,
          discountValue,
          total,
          companyId,
          serie,
          customerId,
          shippingCompanyId,
          intermediaryId,
        },
        items: serializedNFeItems,
        textsForInvoice: textsForInvoice,
      });
      setInvoiceCode(NFe.code);

      if (!toEmit) {
        onSubmit(NFe);
      }
      dispatch([
        change('NFe', 'NFeId', NFe.id),
        change('NFe', 'code', NFe.code),
        change('NFe', 'serie', NFe.serie),
      ]);

      if (toEmit) {
        dispatch(change('NFe', 'toEmit', false));
        handleEmit(NFe.id);
      }
    } catch (err) {
      console.log(err);
      dispatch(change('NFe', 'toEmit', false));
      return toastr.warning('Ocorreu uma falha ao enviar a nota', err.message);
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const {
      finality,
      CFOP,
      issuedAt,
      accessKey,
      customerCpfCnpj,
      customerIE,
      freightIndicator,
      licensePlate,
      volume,
      grossWeight,
      netWeight,
      specie,
      brand,
      inssuranceValue,
      freightValue,
      BCICMS,
      ICMSValue,
      BCICMSST,
      ICMSSTValue,
      COFINSValue,
      PISValue,
      IPIValue,
      FCPValue,
      FCPSTValue,
      presenceIndicator,
      otherExpenses,
      NFeItems,
      discountValue,
      subTotal,
      customerId,
      shippingCompanyId,
      NFeId,
      additionalInformation,
      toEmit,
      total,
      textsForInvoice,
      referenceAccessKey,
      providerCpfCnpj,
      providerIE,
      providerId,
      intermediaryId,
    } = values;

    const serializedNFeItems = NFeItems.map(({ id, ...rest }) => ({ ...rest }));
    setLoading(true);

    try {
      await NFesRepository.update(NFeId, {
        NFe: {
          finality,
          CFOP,
          issuedAt,
          accessKey,
          referenceAccessKey,
          customerCpfCnpj: !customerCpfCnpj
            ? null
            : onlyNumbers(customerCpfCnpj),
          customerIE,
          providerCpfCnpj: !providerCpfCnpj
            ? null
            : onlyNumbers(providerCpfCnpj),
          providerIE,
          freightIndicator,
          licensePlate,
          volume,
          grossWeight,
          netWeight,
          specie,
          brand,
          inssuranceValue,
          freightValue,
          BCICMS,
          ICMSValue,
          BCICMSST,
          ICMSSTValue,
          COFINSValue,
          PISValue,
          IPIValue,
          FCPValue,
          FCPSTValue,
          presenceIndicator,
          additionalInformation,
          otherExpenses,
          subTotal: subTotal,
          discountValue,
          total,
          companyId,
          customerId,
          providerId,
          shippingCompanyId,
          intermediaryId,
        },
        items: serializedNFeItems,
        textsForInvoice: textsForInvoice,
      });

      if (!toEmit) {
        return onSubmit(NFeId);
      }

      if (toEmit) {
        dispatch(change('NFe', 'toEmit', false));
        await handleEmit(NFeId);
      }
    } catch (err) {
      console.log(err);
      dispatch(change('NFe', 'toEmit', false));
      return toastr.warning(
        'Ocorreu um erro ao atualizar a NF-e. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleEmit(id) {
    setIsLoadingValidations(true);
    emit(id);
  }

  async function emit(id) {
    try {
      const response = await NFesRepository.emit(id, {
        issuedAt: new Date(),
      });

      const { printInvoicesAutomatically } =
        await companiesRepository.getTaxData(companyId);

      const {
        issuedAt,
        accessKey,
        status,
        situationCode,
        situationDescription,
        BCICMS,
        ICMSValue,
        PISValue,
        COFINSValue,
        ICMSSTValue,
        BCICMSST,
        docPDFDownload,
      } = response;

      const statusNFe = setStatus({
        status,
        situationDescription,
        situationCode,
      });

      let invoiceError = null;

      try {
        invoiceError = await loadInvoiceError(situationCode);
      } catch (err) {}

      const isInvoiceIssued = status === INVOICE_STATUS_DESCRIPTION.ISSUED;
      dispatch([
        change('NFe', 'invoiceError', invoiceError),
        change(
          'NFe',
          'issuedAt',
          !issuedAt
            ? ''
            : format(new Date(issuedAt), 'yyyy-MM-dd') +
                'T' +
                format(new Date(issuedAt), 'hh:mm')
        ),
        change('NFe', 'accessKey', accessKey),
        change('NFe', 'status', statusNFe),
        change(
          'NFe',
          'isInvoiceIssued',
          status === INVOICE_STATUS_DESCRIPTION.ISSUED
        ),
        change(
          'NFe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'NFe',
          'isInvoiceIssuedInContingency',
          status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY
        ),
        change(
          'NFe',
          'isInvoiceDenied',
          status === INVOICE_STATUS_DESCRIPTION.DENIED
        ),
        change('NFe', 'PISValue', PISValue),
        change('NFe', 'COFINSValue', COFINSValue),
        change('NFe', 'BCICMS', BCICMS),
        change('NFe', 'ICMSValue', ICMSValue),
        change('NFe', 'BCICMSST', BCICMSST),
        change('NFe', 'ICMSSTValue', ICMSSTValue),
      ]);

      switch (status) {
        case INVOICE_STATUS_DESCRIPTION.ISSUED:
          toastr.success('Sucesso', 'NF-e emitida com sucesso.');

          if (printInvoicesAutomatically && isInvoiceIssued) {
            window.open(docPDFDownload);
          }

          return;
        case INVOICE_STATUS_DESCRIPTION.CONTINGENCY:
          return toastr.warning('', 'NF-e em contingência.');
        case INVOICE_STATUS_DESCRIPTION.DENIED:
          return toastr.warning('', 'NF-e Denegada.');
        default:
          return toastr.error('', 'NF-e Aberta c/ Erro.');
      }
    } catch (err) {
      console.error(err);
      dispatch(change('NFe', 'toEmit', false));
      setLoading(false);
      setIsLoadingValidations(false);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        const errorsFiscal = err.response.data.errors.filter(
          (x) => x.card === 'Configurações Fiscais'
        );
        const errorsClient = err.response.data.errors.filter(
          (x) => x.card === 'Cliente'
        );
        const errorsProduct = err.response.data.errors.filter(
          (x) => x.card === 'Produto'
        );
        const errorsProvider = err.response.data.errors.filter(
          (x) => x.card === 'Fornecedor'
        );

        dispatch([
          change('NFe', 'errorsFiscal', errorsFiscal),
          change('NFe', 'errorsClient', errorsClient),
          change('NFe', 'errorsProduct', errorsProduct),
          change('NFe', 'errorsProvider', errorsProvider),
          change(
            'NFe',
            'totalErrors',
            errorsFiscal.length + errorsClient.length + errorsProduct.length
          ),
        ]);

        handleOpenErrorsModal(err.response.data.errors);
      } else {
        if (
          err.response.status === 400 &&
          err.response.data?.message?.title === 'Não há NFC-e emitida vinculada'
        ) {
          toastr.error(
            err.response.data.message.title,
            err.response.data.message.content
          );
        } else {
          toastr.warning(
            'Ocorreu uma falha ao enviar a nota',
            err.response.data.message
          );
        }
      }
    } finally {
      setIsLoadingValidations(false);
    }
  }

  function handleOpenErrorsModal(errors) {
    setShowErrosModal(true);
  }

  async function retry() {
    hideModalValidations();
    setIsLoadingValidations(true);
    await getNfe('retry');
  }

  function hideModalValidations() {
    setShowErrosModal(false);
    setIsLoadingValidations(false);
    cleanErrors();
  }

  function cleanErrors() {
    dispatch([
      change('NFe', 'errorsFiscal', []),
      change('NFe', 'errorsClient', []),
      change('NFe', 'errorsProduct', []),
    ]);
  }

  async function handleConsultInvoiceInContingency() {
    setLoading(true);
    try {
      const response = await NFesRepository.consultContingency(invoiceId);
      const { issuedAt, status, situationCode, situationDescription } =
        response;

      const statusNFe = setStatus({
        status,
        situationDescription,
        situationCode,
      });
      dispatch([
        change(
          'NFe',
          'issuedAt',
          !issuedAt
            ? ''
            : format(new Date(issuedAt), 'yyyy-MM-dd') +
                'T' +
                format(new Date(issuedAt), 'hh:mm')
        ),
        change('NFe', 'status', statusNFe),
        change(
          'NFe',
          'isInvoiceIssued',
          status === INVOICE_STATUS_DESCRIPTION.ISSUED
        ),
        change(
          'NFe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'NFe',
          'isInvoiceIssuedInContingency',
          status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY
        ),
        change(
          'NFe',
          'isInvoiceDenied',
          status === INVOICE_STATUS_DESCRIPTION.DENIED
        ),
      ]);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao consultar a NF-e em contingência. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  const initialValues = {
    finality: INVOICE_FINALITY.NORMAL,
    searchClient: '',
    searchShippingCompany: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    accessKey: '00000000000000000000000000000000000000000000',
    taxesByState: [],
    CFOPs: [],
    NFeItems: [],
    subTotal: 0,
    productsTotal: 0,
    discountValue: 0,

    freightIndicator: '9',
    presenceIndicator: '1',

    toEmit: false,
  };

  return (
    <div>
      {loading && <PureLoader message="Carregando dados..." />}
      <FormNewNFe
        onSubmit={handleSubmit}
        invoiceId={invoiceId}
        loading={loading}
        setLoading={setLoading}
        initialValues={initialValues}
        onCancel={onCancel}
        onConsultContingency={handleConsultInvoiceInContingency}
      />

      <ErrosModal
        show={showErrosModal}
        hideModalValidations={hideModalValidations}
        retry={retry}
      />

      {isLoadingValidations && (
        <InvoiceProcessLoader message="Estamos realizando o envio da nota fiscal. Por favor, aguarde." />
      )}

      {loadingNFe && <PureLoader message="Aguarde, carregando NF-e" />}
    </div>
  );
};

export default withRouter(BundleNewNFe);
