import api from '../services/api'
import config from '../config'

const create = data => {
  return api.post(config.endpoint + 'similarity', data)
}

const deleteSimilarity = data => {
  return api.post(config.endpoint + 'similarity/delete', data)
}

const getAllProductSugggestions = async queryParams => {
  const response = await api.get(config.endpoint + 'similarity/suggestions', {
    params: queryParams
  })
  
  return response
}

export default {
  create,
  deleteSimilarity,
  getAllProductSugggestions
}