import React from 'react'
import LoaderSpinner from 'client/components/LoaderSpinner'

export default function LoadingSpinnerFullHeight() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoaderSpinner
        style={{
          fontSize: '3px',
          margin: '10px',
        }}
      />
    </div>
  )
}
