import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import { addMonths, format } from 'date-fns'
import { kmMask } from 'client/components/ToNormalize/ToNormalize'


const ProductServiceReviewaPDF = ({ responseData, review, warranty }) => {

  const period = responseData.period
  const sales = responseData.sales

  return (
    <Document
      title={`impresso-service-reviews-warranty-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Revisões/Garantias de Produtos e Serviços
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                Data Inicio:
              </Text>
              <Text style={[styles.text]}> {format(new Date(`${period.start} 03:00:01`), 'dd/MM/yyyy')}</Text>
            </View>

            <View style={{ left: '6px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                  Data Fim:
                </Text>
                <Text style={[styles.text]}>{format(new Date(`${period.end} 23:59:59`), 'dd/MM/yyyy')}</Text>
              </View>
            </View>
          </View>
        </View>

        <TableHeader
          sales={sales} review={review} warranty={warranty}
        />

      </Page>
    </Document>
  )

}

const TableHeader = ({ sales, review, warranty }) => {
  return (
    <>

      <View style={[styles.line, { marginTop: '10px' }]} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Revisões e Garantias
        </Text>
      </View>
      <View style={[styles.line]} />

      {
        sales.map((sale, index) => (<ServiceReviewsWarrantyDetails sale={sale} review={review} warranty={warranty} key={index} />))
      }


      <View style={[styles.line, { marginTop: '50px' }]} />
    </>
  )
}

const totalItemsInSale = (sale, review, warranty) => {
  return sale.SalesItems.filter(item => {

    let ProductWarranty = warranty === 'false' ? false : true
    let ProductReview = review === 'false' ? false : true

    if (ProductWarranty && ProductReview)
      return item

    if (!ProductWarranty && ProductReview) {
      if (item?.Products?.Warranty || item?.Services?.Warranty)
        return item
    }

    if (ProductWarranty && !ProductReview) {
      if (item?.Products?.Review || item?.Services?.Review)
        return item
    }

    if (!ProductWarranty && !ProductReview) {
      if ((item?.Products?.Warranty && item?.Products?.Review) || (item?.Services?.Warranty && item?.Services?.Review))
        return item
    }
  }).length
}

const ServiceReviewsWarrantyDetails = ({ sale, review, warranty }) => {

  return (
    totalItemsInSale(sale, review, warranty) == 0 ? <></> :
      <View style={{ marginTop: '10px' }}>
        <ServiceReviewsWarrantyDetailsHeader sale={sale} />
        <ServiceReviewsWarrantyDetailsTableHeader />
        {
          sale.SalesItems.filter(item => {

            let ProductWarranty = warranty === 'false' ? false : true
            let ProductReview = review === 'false' ? false : true

            if (ProductWarranty && ProductReview)
              return item

            if (!ProductWarranty && ProductReview) {
              if (item?.Products?.Warranty || item?.Services?.Warranty)
                return item
            }

            if (ProductWarranty && !ProductReview) {
              if (item?.Products?.Review || item?.Services?.Review)
                return item
            }

            if (!ProductWarranty && !ProductReview) {
              if ((item?.Products?.Warranty && item?.Products?.Review) || (item?.Services?.Warranty && item?.Services?.Review))
                return item
            }
          }

          ).map((item, index) => (
            <ServiceReviewsWarrantyDetailsTableItem

              item={item} sale={sale} />
          ))
        }
        <View style={styles.line} />
      </View>
  )
}

const ServiceReviewsWarrantyDetailsHeader = ({ sale, key }) => {
  return (
    <View key={key}>
      <View style={{ marginTop: '6px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Venda/OS: </Text>
        <Text style={{ width: '7%', right: '4px' }}>{format(new Date(sale.Date), 'dd/MM/yyyy')}</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Número: </Text>
        <Text style={{ width: '7%', left: '1px' }}>{sale.code}
        </Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '5%' }]}>Cliente: </Text>
        <Text style={[{ width: '32%', right: '4px' }]}>{sale?.Customer.Cpf_Cnpj} - {sale?.Customer.Company_Name || sale?.Customer.Trading_Name}</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Veiculo:  </Text>
        <Text style={[{ width: '26%', right: '4px' }]}>{sale.Vehicle?.License_Plate} - {sale.Vehicle?.Model} </Text>
      </View>

    </View>
  )
}



const ServiceReviewsWarrantyDetailsTableHeader = () => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Codigo</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '16%', left: '130px' }]}>Ref.Fabricação</Text>
        <Text style={[styles.boldText, { width: '20%', left: '120px' }]}>Marca</Text>
        <Text style={[styles.boldText, { width: '23%', left: '73px' }]}>Data da Revisão</Text>
        <Text style={[styles.boldText, { width: '13%', left: '15px' }]}>KM Revisão</Text>
        <Text style={[styles.boldText, { width: '14%', left: '2px' }]}>Data da Garantia</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>KM Garantia</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}


const ServiceReviewsWarrantyDetailsTableItem = ({ item, key, sale }) => {


  let addingMonthsReviews = format(addMonths(new Date(sale.Date), parseInt(`${item.Products ? (!item.Products?.Review?.Months ? 0 : item.Products?.Review?.Months) : (!item.Services?.Review?.Months ? 0 : item.Services?.Review?.Months)}`)), 'dd/MM/yyyy')

  let addingMonthsWarranty = format(addMonths(new Date(sale.Date), parseInt(`${item.products ? (!item.Products?.Warranty?.Months ? 0 : item.Products?.Warranty?.Months) : (!item.Services?.Warranty?.Months ? 0 : item.Services?.Warranty?.Months)
    }`)), 'dd/MM/yyyy')

  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={{ width: '8%' }}>{item.Products ? item.Products?.Code : (item.Services ? item.Services?.Code : '')}</Text>
        <Text style={{ width: '23%' }}>{item.Products ? item.Products?.Description : (item.Services ? item.Services?.Description : '')}</Text>
        <Text style={{ width: '21%', left: '66px' }}>{item.Products ? item.Products?.Manufacturer_Ref : ''}</Text>
        <Text style={{ width: '12%' }}>{item.Products ? item.Products?.Brands?.Description : ''}</Text>
        <Text style={{ width: '12%', left: '3px' }}>{addingMonthsReviews}</Text>
        <Text style={{ width: '9%', left: '9px' }}>{kmMask(String(sale.Kilometers + (item.Products ? (item.Products?.Review?.Km || '') : item.Services?.Review?.Km || '')))}</Text>
        <Text style={{ width: '12%', left: '12px' }}>{addingMonthsWarranty}</Text>
        <Text style={{ width: '9%', left: '23px' }}>{kmMask(String(sale.Kilometers + (item.Products ? (item.Products?.Warranty?.Kilometers || '') : item.Services?.Warranty?.Kilometers || '')))}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>

  )
}

export default ProductServiceReviewaPDF

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },

})
