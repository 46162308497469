import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import './styles.css'
import { useParams } from 'react-router'
import NewRequisitionMain from './components/NewRequisitionMain'

const NewPartsRequisition = ({ history }) => {
  const { id } = useParams()

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              'Requisição de Peças',
              id ? 'Editar Requisição' : 'Nova Requisição',
            ]}
            path={['home', 'products', 'parts-requisition', null]}
          />
          <NewRequisitionMain history={history} hasId={id} />
        </Row>
      </Grid>
    </div>
  )
}

export default NewPartsRequisition
