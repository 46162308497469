import React, { memo, useState, useEffect } from 'react'
import Chart from 'react-google-charts'

function LineChartByType(props) {
  const { data, opacity } = props

  return (
    <>
      <h4>
        <strong style={{ marginBottom: '-30px' }}>
          Vendas por Tipo (Período)
        </strong>
      </h4>
      <div style={{ opacity: opacity }}>
        <Chart
          width={'100%'}
          height={'350px'}
          style={{ margin: 0, border: 0, padding: 0 }}
          chartType="LineChart"
          data={data}
          chartLanguage="pt-br"
          options={{
            colors: ['#000000', '#800080', '#00008B', '#008B8B'],
            hAxis: {
              title: 'Tempo (dias)',
              format: 'd/M',
            },
            vAxis: {
              title: 'Valor (R$)',
            },
          }}
        />
      </div>
    </>
  )
}

export default memo(LineChartByType)
