import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'

import { useFormContext, useWatch } from 'react-hook-form'

import AlertModal from 'components/AlertModal/AlertModal'
import CustomButton from 'client/components/CustomButton/CustomButton.jsx'
import Button from 'client/components/CustomButton/CustomButton.jsx'

import QuotationRepository from 'repositories/Quotations'

const warningToastr = () => {
  return toastr.warning(
    'Não foi possível salvar a cotação',
    'Preencha a cotação com pelo menos 1 item e 1 fornecedor, com todos os campos preenchidos e tente novamente.'
  )
}

export default function QuotationFooter() {
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false)

  const [isSendConfirmationModalOpen, setIsSendConfirmationModalOpen] =
    useState(false)

  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext()
  const history = useHistory()

  const { companyName } = getValues()

  const isEditing = useWatch({
    control,
    name: 'isEditing',
  })

  const quotationToEdit = useWatch({
    control,
    name: 'quotationToEdit',
  })

  const disabledSave = quotationToEdit.status !== 'Não Enviada' && isEditing

  function handleCancelQuotation() {
    if (isDirty & !isEditing) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      history.push('/client/quotations')
    }
  }

  function handleValidateFields(data) {
    if (!isDirty) {
      return warningToastr()
    }

    const providersNotFilled = data.providers?.filter((provider) => {
      return !provider.companyName || !provider.provider_phone
    })

    if (providersNotFilled.length || !data.providers.length) {
      return warningToastr()
    }

    const itemsNotFilled = data.items?.filter((item) => {
      return !item.description || !item.quantity
    })

    if (itemsNotFilled.length || !data.items.length) {
      return warningToastr()
    }

    onSubmit(data)
  }

  function openSendQuotation(data) {
    const formatedQuotation = {
      id: data.quotation.id,
      providers: data.providers,
    }

    formatedQuotation.providers.forEach((item) => {
      const osdigQuotationResponseLink =
        window.location.host +
        `/quotation/reply?quotationProviderId=${
          item.id || item.quotationProviderId
        }`

      window.open(
        `https://wa.me/55${item.provider_phone}?text=Prezado(a)%20Fornecedor%2C%0A%0Asomos%20da%20${companyName}%20e%20precisamos%20cotar%20alguns%20produtos%20para%20compra.%20%0A%0ASegue%20o%20link%20da%20nossa%20cotação%20online%20${osdigQuotationResponseLink}`,
        '_blank'
      )
    })
  }

  async function onSubmit(data) {
    setLoading(true)
    try {
      if (isEditing) {
        const formatedData = {
          quotation: {
            ...data.quotation,
            status: data.status,
            observations: data.observations,
          },
          items: data.items.map((item) => {
            return {
              ...item,
              id: item.quotationItemId || null,
            }
          }),
          removedItems: [...data.removedItems],
          providers: data.providers.map((provider) => {
            return {
              ...provider,
              id: provider.quotationProviderId || null,
              provider_phone: provider.provider_phone
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '')
                .replaceAll(' ', '')
                .replaceAll('_', ''),
            }
          }),
          removedProviders: [...data.removedProviders],
        }

        const hasError = formatedData.providers.find(
          (providers) => providers.provider_phone.length < 11
        )

        if (hasError) {
          return toastr.warning(
            'O campo "Celular" precisa estar com todos os dígitos preenchidos.'
          )
        }

        const res = await QuotationRepository.update(formatedData)

        if (data.status === 'Enviada') {
          openSendQuotation(res.data)
        }
      } else {
        const formatedData = {
          quotation: {
            ...data.quotation,
            status: data.status,
            observations: data.observations,
            companyId: data.companyId,
          },
          items: data.items.map((item) => {
            return {
              ...item,
              id: item.quotationItemId || null,
            }
          }),
          providers: data.providers.map((provider) => {
            return {
              ...provider,
              id: provider.quotationProviderId || null,
              provider_phone: provider.provider_phone
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '')
                .replaceAll(' ', '')
                .replaceAll('_', ''),
            }
          }),
        }

        const hasError = formatedData.providers.find(
          (providers) => providers.provider_phone.length < 11
        )

        if (hasError) {
          return toastr.warning(
            'O campo "Celular" precisa estar com todos os dígitos preenchidos.'
          )
        }

        const res = await QuotationRepository.create(formatedData)

        if (data.status === 'Enviada') {
          openSendQuotation(res.data)
        }
      }

      data.status === 'Enviada'
        ? toastr.success(
            'Cotação criada com sucesso',
            'A cotação já foi salva e criada com sucesso. As janelas foram abertas para enviar a cotação'
          )
        : toastr.success(
            'Cotação criada com sucesso',
            'A cotação já foi salva e criada com sucesso. '
          )

      history.push('/client/quotations')
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao realizar operação',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="quotation-footer">
      <CustomButton
        bsStyle="danger"
        fill
        onClick={handleCancelQuotation}
        disabled={loading}
      >
        Cancelar
      </CustomButton>

      <CustomButton
        bsStyle="primary"
        style={{
          backgroundColor: '#428bca',
          borderColor: '#428bca',
          width: '150px',
        }}
        fill
        onClick={() => {
          setValue('status', 'Não Enviada')
          handleSubmit(handleValidateFields)()
        }}
        disabled={disabledSave || loading}
      >
        Salvar
      </CustomButton>

      <CustomButton
        bsStyle="info"
        fill
        onClick={() => setIsSendConfirmationModalOpen(true)}
        disabled={disabledSave || loading}
      >
        Salvar e Enviar
      </CustomButton>

      {isCancelConfirmationModalOpen && (
        <AlertModal
          show={isCancelConfirmationModalOpen}
          subtitle={'Você tem certeza que deseja cancelar a cotação?'}
          onSubmit={() => history.push('/client/quotations')}
          onCancel={() => setIsCancelConfirmationModalOpen(false)}
          onHide={() => setIsCancelConfirmationModalOpen(false)}
          message={
            <>
              O cancelamento é irreversível. Ao cancelar a criação, a cotação
              não será criada e nem disparada para o fornecedor, sendo
              necessária a criação de uma nova.
            </>
          }
        />
      )}

      {isSendConfirmationModalOpen && (
        <AlertModal
          show={isSendConfirmationModalOpen}
          subtitle={
            'Você deseja salvar e enviar a cotação para os fornecedores?'
          }
          onSubmit={() => history.push('/client/quotations')}
          onCancel={() => setIsSendConfirmationModalOpen(false)}
          onHide={() => setIsSendConfirmationModalOpen(false)}
          message={
            <>
              Após o envio da cotação, os fornecedores deverão respondê-la para
              que você possa visualizar a resposta e escolher o melhor preço de
              compra.
              <br />
              <br />
              Você também pode cancelar a cotação para que seus fornecedores não
              respondam.
            </>
          }
          customFooter={
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  bsStyle="danger"
                  disabled={loading}
                  name="Não"
                  onClick={() => setIsSendConfirmationModalOpen(false)}
                  fill
                >
                  Não
                </Button>
                <Button
                  bsStyle="primary"
                  disabled={loading}
                  style={{
                    backgroundColor: '#428bca',
                    borderColor: '#428bca',
                    width: '150px',
                  }}
                  name="Não"
                  onClick={() => {
                    setValue('status', 'Não Enviada')
                    handleSubmit(handleValidateFields)()
                  }}
                  fill
                >
                  Apenas Salvar
                </Button>
                <Button
                  bsStyle="info"
                  disabled={loading}
                  name="Sim"
                  fill
                  onClick={() => {
                    setValue('status', 'Enviada')
                    handleSubmit(handleValidateFields)()
                  }}
                >
                  Sim
                  <span
                    className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                  />
                </Button>
              </div>
            </>
          }
        />
      )}
    </div>
  )
}
