import styled from 'styled-components';

export const StyledText = styled('span')`
  font-family: Roboto, sans-serif;
  font-size: ${(props) => props.theme.fontSizes[props.size]};
  font-weight: ${(props) => props.theme.fontWeights[props.fontWeight]};
  ${(props) =>
    props.color ? `color: ${props.theme.colors[props.color]};` : ''}
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${(props) =>
    props.lineHeight
      ? `line-height: ${props.theme.lineHeights[props.lineHeight]};`
      : ''}
`;
