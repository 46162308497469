import React from 'react'
import { withRouter } from 'react-router'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import VideoLinkRegisterMain from './components/VideoLinkRegisterMain'

const VideoLinksRegister = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Vídeos',
          match.params.id ? 'Editar Vídeo' : 'Novo Vídeo',
        ]}
        path={['home', 'videos', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <VideoLinkRegisterMain
        videosLinksId={match.params.id}
        onCancel={() => history.push('/admin/videos')}
        onSubmit={() => history.push('/admin/videos')}
      />
    </>
  )
}

export default withRouter(VideoLinksRegister)
