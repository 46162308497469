import React from "react";
import { Grid, Row } from "react-bootstrap";

import BundleFormService from './BundleFormService'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const NewService = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb data={['Início', 'Serviços', match.params.id ? 'Editar Serviço' : 'Novo Serviço']}
            path={['home', 'services', null]} />
          <BundleFormService serviceId={match.params.id} />
        </Row>
      </Grid>
    </div>
  );
}

export default NewService;