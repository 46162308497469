import React from "react";
import { Grid, Row } from "react-bootstrap";

import ListEmployeesMain from './components/ListEmployeesMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const ListEmployees = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Clientes/Funcionários', 'Listagem de Funcionários']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <ListEmployeesMain />
        </Row>
      </Grid>
    </div>
  );
}
export default ListEmployees