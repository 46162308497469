import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import ProductsServicesTable from '../../../components/ProductsServicesTable';
import NFSeSheet from '../../../components/ProductsServicesTable/Sheets/NFSeSheet';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import { toastr } from 'react-redux-toastr';

const ItemsModal = ({ onCancel, onSubmit, discountGroupServices }) => {
  const [items, setItems] = useState([]);
  const [isClearSheetModalOpen, setIsClearSheetModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleAdd = (item) => {
    const { id, Description, Price, Code } = item;

    const newItems = [...items];
    const index = newItems.findIndex((item) => item.serviceId === id);

    if (index !== -1) {
      newItems[index].quantity++;
    } else {
      const product = {
        serviceId: id,
        code: Code,
        description: Description,
        quantity: 1,
        unitValue: Price,
      };

      newItems.push(product);
    }

    if (discountGroupServices) {
      const isAlreadyInTable = discountGroupServices.map(
        (service) => service.Service.id
      );

      if (isAlreadyInTable.includes(item.id)) {
        return toastr.error(
          'Serviço já faz parte dessa tabela de preços diferenciados'
        );
      }
    }

    setItems(newItems);
  };

  const handleClearSheet = () => {
    setItems([]);
    setIsClearSheetModalOpen(false);
  };

  return (
    <>
      <Modal
        show
        onHide={() => (!items.length ? onCancel() : setIsCancelModalOpen(true))}
        animation
        dialogClassName="NFSe-items-modal-wrapper modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Adicionar Itens</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="NFSe-items-modal">
            <ProductsServicesTable
              DiscountPriceProductsTable="Serviço"
              servicesList
              onInclude={handleAdd}
            />
            <NFSeSheet items={items} setItems={setItems} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end align-center">
            <Button
              style={{
                height: '35px',
              }}
              type="button"
              bsStyle="danger"
              pullRight
              fill
              onClick={() =>
                !items.length ? onCancel() : setIsCancelModalOpen(true)
              }
            >
              Cancelar
            </Button>
            <Button
              style={{
                height: '35px',
              }}
              disabled={items.length === 0}
              pullRight
              type="button"
              bsStyle="primary"
              onClick={() => setIsClearSheetModalOpen(true)}
              fill
            >
              Limpar Itens
            </Button>
            <Button
              style={{
                height: '35px',
              }}
              type="button"
              bsStyle="info"
              pullRight
              fill
              onClick={() => onSubmit(items)}
            >
              Adicionar Itens
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        message="Deseja limpar todos os itens ?"
        show={isClearSheetModalOpen}
        onHide={() => setIsClearSheetModalOpen(false)}
        onCancel={() => setIsClearSheetModalOpen(false)}
        onSubmit={handleClearSheet}
      />

      <AlertModal
        message="Deseja fechar o modal e não adicionar os itens selecionados ?"
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </>
  );
};

export default ItemsModal;
