import config from "../config"
import api from "../services/api"

const create = async params => {
    try {

        const { Brand, Price, Card_Number, Month_Validity, Year_Validity, Transaction_Number,
            Qtd_Parcels, Value_Parcel, typePayment } = params
            
        const response = await api.post(config.endpoint + `signaturepayment`, {
            Brand, Price, Card_Number, Month_Validity, Year_Validity, Transaction_Number,
            Qtd_Parcels, Value_Parcel, typePayment
        })

        if(!response.status){
            return false
        }

        return response.data.data

    } catch (err) {
        throw err
    }
}

export default {
    create
}