import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import Button from 'client/components/CustomButton/CustomButton'

import NFSePatternsRepository from 'repositories/NFSePatterns'

const NewCityModal = ({
  onCancel,
  loadCities,
  patternToRegisterId,
  handleSelectCity,
}) => {
  const [name, setName] = useState('')
  const [stateToRegister, setStateToRegister] = useState('')
  const [patternToRegister, setPatternToRegister] = useState(null)
  const [IBGECode, setIBGECode] = useState(null)

  const [patternsList, setPatternsList] = useState([])

  const [loading, setLoading] = useState(false)

  const loadPatterns = async () => {
    setLoading(true)
    try {
      const { patterns } = await NFSePatternsRepository.index({ query: '' })

      setPatternsList(patterns)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao carregar os padrões',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const validations = async () => {
    try {
      const { data } = await NFSePatternsRepository.getCitiesPatterns({
        codeOrNameCity: IBGECode,
        limit: 1000,
      })

      const patternWithExactlySameCode = data?.find(
        (pattern) => pattern.IBGECityCode === IBGECode
      )

      if (patternWithExactlySameCode) {
        return false
      }
      return true
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Não foi possível validar a utilização do código IBGE do padrão. Tente novamente!'
      )
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!IBGECode || !name || !stateToRegister || !patternToRegister) {
        return toastr.warning(
          'Não foi possível realizar o cadastro',
          'Preencha todos os campos e tente novamente'
        )
      }

      if (!(await validations())) {
        return toastr.warning(
          'Não foi possível salvar o padrão',
          'Já existe um padrão com esse código IBGE. Verifique os padrões cadastrados e tente novamente.'
        )
      }

      const { data } = await NFSePatternsRepository.show(patternToRegister)

      const params = {
        city: name,
        UF: stateToRegister,
        IBGECityCode: IBGECode,
        allowCancel: data.allowCancel,
      }

      const createdCity = await NFSePatternsRepository.addNewCity(
        data.id,
        params
      )

      toastr.success(
        'Cidade salva com sucesso!',
        'O cadastro da cidade já foi salva e pode ser utilizado posteriormente.'
      )

      if (handleSelectCity) {
        handleSelectCity(createdCity)
      }

      onCancel()
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao salvar a cidade.',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
      loadCities()
    }
  }

  useEffect(() => {
    loadPatterns()
  }, [])

  useEffect(() => {
    if (patternsList.length > 0) {
      setPatternToRegister(patternToRegisterId)
    }
  }, [patternsList])

  return (
    <>
      <Modal show animation onHide={onCancel} dialogClassName="modal-60w">
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Cadastrar Nova Cidade</strong>
            <span
              style={{
                color: 'red',
                position: 'absolute',
                right: 10,
                marginTop: '25px',
                fontSize: '14px',
              }}
            >
              * Campos Obrigatórios
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '110px',
              }}
            >
              <label>
                Código IBGE:
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="number"
                value={IBGECode}
                onChange={(e) => setIBGECode(e.target.value)}
                className="form-control foco-input"
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '320px',
              }}
            >
              <label>
                Nome:
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control foco-input"
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '110px',
              }}
            >
              <label>
                UF:
                <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                value={stateToRegister}
                onChange={(e) => setStateToRegister(e.target.value)}
                className="form-control foco-input"
              >
                <option value="">Selecione</option>
                <option value="AC">AC</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="SC">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '150px',
              }}
            >
              <label>
                Padrão Vinculado:
                <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                value={patternToRegister}
                onChange={(e) => setPatternToRegister(e.target.value)}
                className="form-control foco-input"
                disabled={patternToRegisterId}
              >
                <option value="">Selecione</option>
                {patternsList?.map((pattern) => (
                  <option key={pattern.id} value={pattern.id}>
                    {pattern.pattern}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              name="Voltar"
              onClick={onCancel}
              disabled={loading}
              fill
            >
              Cancelar
            </Button>
            <Button
              bsStyle="info"
              name="Salvar"
              onClick={handleSubmit}
              disabled={loading}
              fill
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewCityModal
