import React, { useCallback, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { Col, Modal } from 'react-bootstrap';
import {
  faEdit,
  faShareAlt,
  faFileInvoiceDollar,
  faSleigh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { format, isBefore } from 'date-fns';

import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import quickQuotesRepository from '../../../repositories/QuickQuotes';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useHistory, Link } from 'react-router-dom';
import ConsultPricesModal from 'client/components/ConsultPricesModal';

import ShareQuickQuote from 'client/components/ShareQuickQuote/ShareQuickQuote.js';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';

import useDebounce from 'hooks/useDebounce';
import SearchInput from 'components/SearchInput';

const QuickQuotes = () => {
  const [isQuickQuoteModalOpen, setIsQuickQuoteModalOpen] = useState(true);
  const [isQuickQuoteEditModalOpen, setIsQuickQuoteEditModalOpen] =
    useState(false);
  const [
    isRedirectionToSaleModalAlertOpen,
    setIsRedirectionToSaleModalAlertOpen,
  ] = useState(false);
  const [isCreateNewSaleModalOpen, setIsCreateNewSaleModalOpen] =
    useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [sale, setSale] = useState(null);
  const [quickQuoteToNewSale, setQuickQuoteToNewSale] = useState(null);
  const [quickQuoteId, setQuickQuoteId] = useState(null);
  const [quickQuotes, setQuickQuotes] = useState([]);
  const [quickQuoteInfo, setQuickQuoteInfo] = useState(null);
  const [quickQuoteInfoToShare, setQuickQuoteInfoToShare] = useState(null);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [orderType, setOrderType] = useState('DESC');
  const [orderField, setOrderField] = useState('date');

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [quickQuoteStatusId, setQuickQuoteStatusId] = useState('');
  const [query, setQuery] = useState('');

  const [allowSubmit, setAllowSubmit] = useState(false);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const [initialDateDebounced, setInitialDateDebounced] = useState('');
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 800);

  const [finalDateDebounced, setFinalDateDebounced] = useState('');
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 800);

  const { companyId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (companyId) loadQuickQuotes();
  }, [isQuickQuoteModalOpen]);

  useEffect(() => {
    if (!!quickQuoteToNewSale)
      handleItemsArray(quickQuoteToNewSale.QuickQuotesItems);
  }, [quickQuoteToNewSale]);

  const loadQuickQuotes = async () => {
    setLoading(true);
    if (
      finalDate &&
      initialDate &&
      isBefore(new Date(finalDate), new Date(initialDate))
    ) {
      setLoading(false);
      return toastr.warning(
        'Período Inválido',
        'A data final precisa ser maior ou igual a inicial'
      );
    }

    try {
      const { data } = await quickQuotesRepository.getAll(companyId, {
        initialDate,
        finalDate,
        quickQuoteStatusId,
        limit: pageSize,
        page: currentPage + 1,
        query,
        orderType,
        orderField,
      });
      setQuickQuotes(data.rows);
      setTotalPages(Math.ceil(data.count / pageSize));
      setAllowSubmit(true);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os orçamentos rápidos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  function handleItemsArray(quickQuoteItems) {
    var itemsNewArray = [];

    quickQuoteItems.map((item) => {
      var object = {
        id: item.type == 'Produto' ? item?.Products.id : item?.Services.id,
        Code:
          item.type == 'Produto' ? item?.Products?.Code : item?.Services?.Code,
        Manufacturer_Ref:
          item.type == 'Produto' ? item.Products.Manufacturer_Ref : '',
        Product_id:
          item.type == 'Produto' ? item?.Products.id : item?.Services.id,
        Description:
          item.type == 'Produto'
            ? item?.Products.Description
            : item?.Services.Description,
        Brand:
          item.type == 'Produto' && item?.Products?.Brands?.Description
            ? item?.Products?.Brands?.Description
            : null,
        Value_Cost: item.unitValue,
        Unit_Value: item.unitValue,
        Quantity: item.quantity,
        Type: item.type,
        WarrantyCode:
          item.type == 'Produto'
            ? item?.Products?.Warranty?.Code
            : item?.Services?.Warranty?.Code,
        WarrantyDescription:
          item.type == 'Produto'
            ? item?.Products?.Warranty?.Description
            : item?.Services?.Warranty?.Description,
        Discount_Value: 0,
        edit: false,
      };

      itemsNewArray.push(object);
    });

    setItems(itemsNewArray);
  }

  const handleNewQuickQuote = () => {
    setIsQuickQuoteModalOpen(true);
  };

  const handleEditQuickQuote = (values) => {
    setQuickQuoteInfo(values);
    setIsQuickQuoteEditModalOpen(true);
  };

  const handleRedirectToSaleAlertModal = (sale) => {
    setSale(sale);
    setIsRedirectionToSaleModalAlertOpen(true);
  };

  const handleNewSaleModal = (values) => {
    setQuickQuoteToNewSale(values);
    setQuickQuoteId(values.id);
    setIsCreateNewSaleModalOpen(true);
  };

  function handleInitiateSale() {
    localStorage.setItem('COTATIONS', JSON.stringify(items));
    setIsCreateNewSaleModalOpen(false);

    const quickHash = encrypt(quickQuoteId, '@OS-dig:quickQuoteId');
    history.push(`${constants.ROUTES.SALE}?quickQuoteId=${quickHash}`);
  }

  const handleSuccessQuickQuote = () => {
    setIsQuickQuoteModalOpen(false);
    loadQuickQuotes();
  };

  const handleSuccessEditQuickQuote = () => {
    setIsQuickQuoteEditModalOpen(false);
    loadQuickQuotes();
  };

  const handleShareModal = async (values) => {
    await setQuickQuoteInfoToShare(values);
    setIsShareModalOpen(true);
  };

  const handleNavigateToCompany = () => {
    history.push(constants.ROUTES.COMPANY_DATA);
  };

  useEffect(() => {
    if (allowSubmit) {
      loadQuickQuotes();
    }
  }, [
    initialDateDebounced,
    finalDateDebounced,
    queryDebounced,
    quickQuoteStatusId,
    currentPage,
    pageSize,
    orderType,
    orderField,
  ]);

  return (
    <>
      <Card
        content={
          <div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <button
                  className="btn btn-sucesso button-h35"
                  onClick={handleNewQuickQuote}
                  style={{ fontSize: '12px' }}
                >
                  + Novo Orçamento Rápido
                </button>
              </div>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Nome ou Placa"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    debouncedSaveQuery(e.target.value);
                  }}
                  background={'#FFFFFF'}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '312px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Inicial:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="initialDate"
                  value={initialDate}
                  max={
                    finalDate
                      ? format(
                          new Date(getDateOnlyFromDate(finalDate)),
                          'yyyy-MM-dd'
                        )
                      : format(new Date(), 'yyyy-MM-dd')
                  }
                  onChange={(e) => {
                    debouncedSaveInitialDate(e.target.value);
                    setInitialDate(e.target.value);
                  }}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Final:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="finalDate"
                  value={finalDate}
                  min={
                    initialDate
                      ? format(
                          new Date(getDateOnlyFromDate(initialDate)),
                          'yyyy-MM-dd'
                        )
                      : undefined
                  }
                  onChange={(e) => {
                    debouncedSaveFinalDate(e.target.value);
                    setFinalDate(e.target.value);
                  }}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={quickQuoteStatusId}
                  onChange={(e) => setQuickQuoteStatusId(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="1">Em Aberto</option>
                  <option value="2">Finalizado</option>
                </select>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                }}
                data={quickQuotes}
                columns={[
                  {
                    Header: 'Data',
                    accessor: 'date',
                    width: 150,
                    sortable: true,
                    headerClassName: 'text-left',
                    Cell: (props) =>
                      !!props.value
                        ? format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')
                        : '',
                  },
                  {
                    Header: 'Nº Orçamento',
                    accessor: 'code',
                    sortable: true,
                    width: 180,
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Nome',
                    sortable: true,
                    accessor: 'customerName',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Placa',
                    sortable: true,
                    accessor: 'licensePlate',
                    width: 150,
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Status',
                    accessor: 'quickQuoteStatusId',
                    headerClassName: 'text-left',
                    sortable: false,
                    width: 110,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          zIndex: '0',
                          height: '100%',
                          backgroundColor:
                            props.value === 1 ? '#FFCC00' : '#008000',
                        }}
                      >
                        {props.value === 1 ? `Em Aberto` : `Finalizado`}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    headerClassName: 'text-left',
                    sortable: false,
                    width: 90,
                    filterable: false,
                    className: 'texto',
                    Cell: (props) =>
                      props.original.quickQuoteStatusId === 2 ? (
                        <>
                          <FontAwesomeIcon
                            title={`Acessar Venda/OS`}
                            cursor="pointer"
                            icon={faFileInvoiceDollar}
                            style={{ width: '1.5em', height: '1.5em' }}
                            onClick={() =>
                              handleRedirectToSaleAlertModal(
                                props.original?.Sales
                              )
                            }
                          />
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            title="Editar Orçamento Rápido"
                            cursor="pointer"
                            icon={faEdit}
                            style={{ width: '1.5em', height: '1.5em' }}
                            onClick={() => handleEditQuickQuote(props.original)}
                          />
                          <FontAwesomeIcon
                            title={`Gerar Venda/OS`}
                            cursor="pointer"
                            icon={faFileInvoiceDollar}
                            style={{ width: '1.5em', height: '1.5em' }}
                            onClick={() => {
                              handleNewSaleModal(props.original);
                            }}
                          />
                          <FontAwesomeIcon
                            title={`Compartilhar`}
                            cursor="pointer"
                            icon={faShareAlt}
                            style={{ width: '1.5em', height: '1.5em' }}
                            onClick={() => {
                              handleShareModal(props.original);
                            }}
                          />
                        </>
                      ),
                  },
                ]}
                onSortedChange={(value) => {
                  setOrderField(value[0].id);
                  setOrderType(value[0].desc ? 'DESC' : 'ASC');
                }}
                manual
                onPageSizeChange={(value) => setPageSize(value)}
                onPageChange={(value) => setCurrentPage(value)}
                pageSize={pageSize}
                pages={totalPages}
                defaultPageSize={10}
                className=""
                loading={loading}
                showPagination={true}
                sortable={false}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'date',
                    desc: true,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Não há informação"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        }
      />

      {!!companyId && isQuickQuoteModalOpen && (
        <ConsultPricesModal
          onCancel={() => setIsQuickQuoteModalOpen(false)}
          onSuccess={handleSuccessQuickQuote}
          allowPromotionalPrices={true}
        />
      )}

      {isQuickQuoteEditModalOpen && (
        <ConsultPricesModal
          onCancel={() => setIsQuickQuoteEditModalOpen(false)}
          quickQuoteInfo={quickQuoteInfo}
          onSuccess={handleSuccessEditQuickQuote}
          allowPromotionalPrices={true}
        />
      )}

      {isRedirectionToSaleModalAlertOpen && (
        <Modal
          show={isRedirectionToSaleModalAlertOpen}
          onHide={() => setIsRedirectionToSaleModalAlertOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{`Acessar Venda/OS ${sale.Code}`}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{`Deseja acessar a Venda/OS ${sale.Code}?`}</Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ paddingRight: '3px' }}>
                <Button
                  bsStyle="danger"
                  style={{ backgroundColor: '#EE2D20' }}
                  onClick={() => setIsRedirectionToSaleModalAlertOpen(false)}
                  fill
                >
                  Não
                </Button>
              </div>
              <div>
                <Link to={{ pathname: `sale#${sale.id}` }}>
                  <Button
                    bsStyle="info"
                    style={{ backgroundColor: 'rgb(2, 177, 2)' }}
                    fill
                  >
                    Sim
                  </Button>
                </Link>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isCreateNewSaleModalOpen && (
        <Modal
          show={isCreateNewSaleModalOpen}
          onHide={() => setIsCreateNewSaleModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Gerar Venda/OS</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Deseja gerar uma Venda/OS para esse orçamento rápido?
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ paddingRight: '3px' }}>
                <Button
                  bsStyle="danger"
                  style={{ backgroundColor: '#EE2D20' }}
                  onClick={() => setIsCreateNewSaleModalOpen(false)}
                  fill
                >
                  Não
                </Button>
              </div>
              <div>
                <Button
                  bsStyle="info"
                  style={{ backgroundColor: 'rgb(2, 177, 2)' }}
                  onClick={handleInitiateSale}
                  fill
                >
                  Sim
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isShareModalOpen && (
        <ShareQuickQuote
          show={isShareModalOpen}
          animation={true}
          onHide={() => setIsShareModalOpen(false)}
          onSubmit={() => setIsShareModalOpen(false)}
          quickQuoteInfo={quickQuoteInfoToShare}
        />
      )}

      {!!!companyId && (
        <ModalAlertCompany
          show
          onHide={() => history.goBack()}
          onClick={handleNavigateToCompany}
        />
      )}
    </>
  );
};

export default QuickQuotes;
