import api from '../services/api'
import config from '../config'

const create = data => {
  return api.post(config.endpoint + 'positions', data)
}

const getAllByCompany = (companyId, queryParams) => {
  return api.get(config.endpoint + `positions/${companyId}`, {
    params: queryParams
  })
}

const getById = id => {
  return api.get(config.endpoint + `positions/positionId/${id}`)
}

const update = data => {
  return api.put(config.endpoint + `positions/${data.id}`, data)
}

export default {
  create,
  getAllByCompany,
  getById,
  update
}