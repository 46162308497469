import Button from 'client/components/CustomButton/CustomButton';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectCustomers from 'v2/client/components/SelectCustomers';

import { useGroupedInvoiceEmission } from '../hooks/useGroupedInvoiceEmission';
import { InputSelectDefault } from 'v2/components/Input';

export function GroupedInvoiceEmissionFilter() {
  const { watch, setValue, getValues } = useFormContext();
  const { handleGetSalesInvoice, isLoading, typeOptions } =
    useGroupedInvoiceEmission(getValues, setValue);

  const query = watch('searchInput');
  const typeDate = watch('typeDate');
  const initialDate = watch('initialDate');
  const finalDate = watch('finalDate');

  const handleCustomerChange = (item) => {
    setValue('searchInput', item);
  };

  const getSalesInvoice = async () => {
    setValue('selectedSaleInvoices', []);
    await handleGetSalesInvoice();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      <div style={{ flex: 1 }}>
        <SelectCustomers
          placeholder="Pesquisa por CPF/CNPJ, Nome, Razão Social"
          onChange={handleCustomerChange}
          value={query}
          height="35px"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'end',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <div>
          <span className="typography__text-2">Tipo de Data:</span>
          <InputSelectDefault
            height="35px"
            options={typeOptions}
            value={typeDate}
            onChange={(value) => {
              setValue('typeDate', value);
            }}
            fontSize="12px"
            width="116px"
          />
        </div>
        <div>
          <span className="typography__text-2">
            Data Inicial:<span className="text-red">*</span>
          </span>
          <input
            required
            style={{ fontSize: '12px', height: '40px !important' }}
            className="form-control foco-input fontSize-12"
            type="date"
            name="initialDate"
            value={initialDate}
            onChange={(e) => setValue('initialDate', e.target.value)}
          />
        </div>
        <div>
          <span className="typography__text-2">
            Data Final:<span className="text-red">*</span>
          </span>
          <input
            required
            className="form-control foco-input fontSize-12"
            type="date"
            name="finalDate"
            value={finalDate}
            onChange={(e) => setValue('finalDate', e.target.value)}
          />
        </div>
        <div className="">
          <Button
            disabled={isLoading || (!query && !initialDate && !finalDate)}
            onClick={getSalesInvoice}
            className="btn-sucesso fontSize-12"
            style={{ padding: '8px 30px', margin: '0px' }}
            name="Processar"
            fill
          >
            Processar
          </Button>
        </div>
      </div>
    </div>
  );
}
