import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import constants from 'utils/constants';
import HomeFamilyBrandStockReport from './components/HomeFamilyBrandStock';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const FamilyBrandStockReport = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Produtos',
              'Estoque por Marca/Família',
            ]}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <HomeFamilyBrandStockReport />
        </Row>
      </Grid>
    </div>
  );
};
export default FamilyBrandStockReport;
