import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'
import { toastr } from 'react-redux-toastr'

import NewVoucherPaymentForm from './NewVoucherPaymentForm'
import NewVoucherPaymentConfirmationModal from './NewVoucherPaymentConfirmationModal'

import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import AlertModal from 'components/AlertModal/AlertModal'

import { useAuth } from 'contexts/auth'

import employeesRepository from 'repositories/Employees'
import cashierBanksRepository from 'repositories/CashierBank'
import voucherPaymentRepository from 'repositories/VoucherPayment'
import { loadPaymentData } from 'client/views/Sales/NewSale/FormSale/redux/actions'

export default function NewVoucherPaymentMain({ id, redirect }) {
  const { companyId } = useAuth()

  const formMethods = useForm({
    defaultValues: {
      code: '',
      createdAt: format(new Date(), 'dd/MM/yyyy HH:mm'),
      closingDate: '',
      employeeId: '',
      cashierBankId: '',
      value: '0,00',
      observations: '',
      status: 'Em Aberto',
      companyId,
    },
  })

  const [employeesOptions, setEmployeesOptions] = useState([])
  const [cashierBanksOptions, setCashierBanksOptions] = useState([])

  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false)
  const [isSubmitConfirmationModalOpen, setIsSubmitConfirmationModalOpen] =
    useState(false)

  const [loading, setLoading] = useState(false)

  async function loadEmployeesOptions() {
    try {
      const employees = await employeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      })

      const formatedEmployeesOptions = employees.data.map((item) => ({
        value: item.id,
        label: `${item.name} - ${cpfOrCnpjMask(item.cpf)}`,
      }))

      setEmployeesOptions(formatedEmployeesOptions)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os funcionário',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    }
  }

  async function loadCashierBankOptions() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getCashierBanksByCompany(companyId)

      const activeCashierBanks = cashierBanks.filter(
        (cashierBank) => cashierBank.isActive
      )

      const formatedCashierBanksOptions = activeCashierBanks.map((item) => ({
        value: item.id,
        label: `${item.CashierBankType.initials} - ${item.description}`,
      }))

      setCashierBanksOptions(formatedCashierBanksOptions)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os seus bancos / caixas',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    }
  }

  async function handleValidateSubmit(values) {
    const { employeeId, cashierBankId, value } = values
    if (!employeeId || !cashierBankId || value === '0,00') {
      toastr.warning('Preencha todos os campos obrigatórios e tente novamente!')
      return
    }

    setIsSubmitConfirmationModalOpen(true)
  }

  async function handleSubmit() {
    setLoading(true)
    setIsSubmitConfirmationModalOpen(false)
    try {
      const formValues = formMethods.getValues()

      if (id) {
        await voucherPaymentRepository.update(id, {
          ...formValues,
          value: parseFloat(
            `${formValues.value}`.replaceAll('.', '').replace(',', '.')
          ),
        })
      } else {
        await voucherPaymentRepository.create({
          ...formValues,
          value: parseFloat(
            `${formValues.value}`.replaceAll('.', '').replace(',', '.')
          ),
        })
      }

      const successMessage =
        formValues.status === 'Em Aberto'
          ? `O pagamento foi ${
              id ? 'salvo' : 'criado'
            } e permanece aberto para a baixa`
          : `O pagamento foi  ${
              id ? 'salvo' : 'criado'
            } e já foi feita a baixa do mesmo`

      toastr.success(
        `Pagamento  ${id ? 'salvo' : 'criado'} com sucesso`,
        successMessage
      )
      redirect()
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao salvar o pagamento',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function loadPaymentData() {
    setLoading(true)
    try {
      const payment = await voucherPaymentRepository.findById(id)
      const createAtFormated = format(
        new Date(payment.createdAt),
        'dd/MM/yyyy HH:mm'
      )

      const closingDateFormated = payment.closingDate
        ? format(new Date(payment.createdAt), 'dd/MM/yyyy HH:mm')
        : ''

      formMethods.setValue('code', payment.code)
      formMethods.setValue('cashierBankId', payment.cashierBankId)
      formMethods.setValue('value', `${payment.value}`.replace('.', ','))
      formMethods.setValue('observations', payment.observations)
      formMethods.setValue('createdAt', createAtFormated)
      formMethods.setValue('closingDate', closingDateFormated)
      formMethods.setValue('status', payment.status)
      formMethods.setValue('employeeId', payment.employeeId)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os dados do pagamento',
        'Caso persista, contate o suporte!'
      )
    } finally {
      setLoading(false)
    }
  }

  function handleCancelConfirmation() {
    if (formMethods.formState.isDirty) {
      return setIsCancelConfirmationModalOpen(true)
    }
    redirect()
  }

  async function handleLoadData() {
    await loadEmployeesOptions()
    await loadCashierBankOptions()
    if (id) {
      await loadPaymentData()
    }
  }

  useEffect(() => {
    handleLoadData()
  }, [id])

  return (
    <FormProvider {...formMethods}>
      <NewVoucherPaymentForm
        employeesOptions={employeesOptions}
        cashierBanksOptions={cashierBanksOptions}
        handleCancelConfirmation={handleCancelConfirmation}
        handleValidateSubmit={handleValidateSubmit}
        loading={loading}
      />

      {isCancelConfirmationModalOpen && (
        <AlertModal
          show={true}
          loading={false}
          onCancel={() => setIsCancelConfirmationModalOpen(false)}
          onSubmit={() => redirect()}
          message={
            <>
              <span>
                <strong>
                  Você tem certeza que deseja cancelar o pagamento?
                </strong>
              </span>
              <br />
              <br />
              <span>
                O cancelamento do lançamento é irreversível. Ao cancelar o
                lançamento, será necessário ser feito um novo para que conste o
                pagamento.
              </span>
            </>
          }
        />
      )}

      {isSubmitConfirmationModalOpen && (
        <NewVoucherPaymentConfirmationModal
          handleClose={() => setIsSubmitConfirmationModalOpen(false)}
          handleSubmit={handleSubmit}
        />
      )}
    </FormProvider>
  )
}
