import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
  faClone,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'client/components/CustomButton/Button.jsx';

import './styles.css';

const iconStyle = { width: 20, height: 20 };

const tableColumns = {
  services: [
    {
      Header: '',
      accessor: 'dataFilled',
      width: 30,
      Cell: (props) => {
        if (props.value === 'all')
          return (
            <FontAwesomeIcon
              color="#5cb85c"
              style={iconStyle}
              icon={faCheckCircle}
            />
          );
        if (props.value === 'required')
          return (
            <FontAwesomeIcon
              color="#eea236"
              style={iconStyle}
              icon={faExclamationCircle}
            />
          );
        if (props.value === 'doesntHaveRequired')
          return (
            <FontAwesomeIcon
              color="#d9534f"
              style={iconStyle}
              icon={faTimesCircle}
            />
          );
      },
    },
    {
      Header: () => <span className="mandatory-column">Descrição*</span>,
      accessor: 'Description',
      width: 330,
    },
    {
      Header: () => <span className="mandatory-column">Preço*</span>,
      accessor: 'Price',
      width: 120,
    },
    {
      Header: 'Horas Previstas',
      accessor: 'Hours_Expected',
      width: 120,
    },
    {
      Header: '% Comissão',
      accessor: 'Commission_Rate',
      width: 100,
    },
    {
      Header: 'Informações Complementares',
      accessor: 'Additional_Information',
      width: 350,
    },
  ],

  products: [
    {
      Header: '',
      accessor: 'dataFilled',
      width: 30,
      Cell: (props) => {
        if (props.value === 'all')
          return (
            <FontAwesomeIcon
              color="#5cb85c"
              style={iconStyle}
              icon={faCheckCircle}
            />
          );
        if (props.value === 'required')
          return (
            <FontAwesomeIcon
              color="#eea236"
              style={iconStyle}
              icon={faExclamationCircle}
            />
          );
        if (props.value === 'doesntHaveRequired')
          return (
            <FontAwesomeIcon
              color="#d9534f"
              style={iconStyle}
              icon={faTimesCircle}
            />
          );
      },
    },
    {
      Header: () => <span className="mandatory-column">Descrição*</span>,
      accessor: 'description',
      width: 350,
    },
    {
      Header: () => <span className="mandatory-column">UN*</span>,
      accessor: 'measureUnit',
      width: 80,
    },
    {
      Header: 'Estoque',
      accessor: 'inventory',
      width: 80,
    },
    {
      Header: 'Localização',
      accessor: 'location',
      width: 200,
    },
    {
      Header: 'Ref. Fabricante',
      accessor: 'manufacturerRef',
      width: 150,
    },
    {
      Header: 'Código de Barras (EAN)',
      accessor: 'barCode',
      width: 250,
    },
    {
      Header: 'Código de Barras (EAN - Tributável)',
      accessor: 'tributeBarCode',
      width: 250,
    },
    {
      Header: () => <span className="mandatory-column">Preço de Compra*</span>,
      accessor: 'purchasePrice',
      width: 150,
    },
    {
      Header: <span className="mandatory-column">Margem*</span>,
      accessor: 'margin',
      width: 150,
    },
    {
      Header: <span className="mandatory-column">Preço de venda*</span>,
      accessor: 'salePrice',
      width: 150,
    },
    {
      Header: 'Estoque Mín.',
      accessor: 'minStock',
      width: 150,
    },
    {
      Header: 'Estoque Máx.',
      accessor: 'maxStock',
      width: 150,
    },
    {
      Header: '%Comissão',
      accessor: 'comission',
      width: 150,
    },
    {
      Header: 'Origem da mercadoria',
      accessor: 'origin',
      width: 150,
    },
    {
      Header: 'CSOSN/CST',
      accessor: 'csosn',
      width: 100,
    },
    {
      Header: 'CFOP Dentro do Estado',
      accessor: 'cfop',
      width: 200,
    },
    {
      Header: 'NCM',
      accessor: 'ncm',
      width: 100,
    },
    {
      Header: 'CEST',
      accessor: 'cest',
      width: 100,
    },
    {
      Header: 'ST PIS (Saída)',
      accessor: 'pisExit',
      width: 150,
    },
    {
      Header: 'ST COFINS (Saída)',
      accessor: 'cofinsExit',
      width: 150,
    },
    {
      Header: 'ST PIS (Entrada)',
      accessor: 'pisEntry',
      width: 150,
    },
    {
      Header: 'ST COFINS (Entrada)',
      accessor: 'cofinsEntry',
      width: 150,
    },
    {
      Header: '%IPI',
      accessor: 'ipi',
      width: 75,
    },
    {
      Header: 'Código ANP',
      accessor: 'anp',
      width: 150,
    },
    {
      Header: 'Informações Complementares',
      accessor: 'additionalInformation',
      width: 250,
    },
  ],

  customers: [
    {
      Header: '',
      accessor: 'dataFilled',
      width: 30,
      Cell: (props) => {
        if (props.value === 'all')
          return (
            <FontAwesomeIcon
              color="#5cb85c"
              style={iconStyle}
              icon={faCheckCircle}
            />
          );
        if (props.value === 'required')
          return (
            <FontAwesomeIcon
              color="#eea236"
              style={iconStyle}
              icon={faExclamationCircle}
            />
          );
        if (props.value === 'doesntHaveRequired')
          return (
            <FontAwesomeIcon
              color="#d9534f"
              style={iconStyle}
              icon={faTimesCircle}
            />
          );
        if (props.value === 'duplicated')
          return (
            <FontAwesomeIcon color="#7a43b6" style={iconStyle} icon={faClone} />
          );
      },
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'Cpf_Cnpj',
      width: 150,
    },
    {
      Header: 'Tipo',
      accessor: 'Type',
      headerClassName: 'text-center',
      Cell: (props) => (
        <Button
          id="botaoReactTable"
          style={{
            backgroundColor: props.value === 'Fisica' ? '#0d45a4' : '#631678',
          }}
        >
          {props.value}
        </Button>
      ),
    },
    {
      Header: () => (
        <span className="mandatory-column">Nome Completo / Razão Social*</span>
      ),
      name: 'Nome Completo / Razão Social',
      accessor: 'Company_Name',
      width: 150,
    },
    {
      Header: 'Nome Fantasia',
      accessor: 'Trading_Name',
      width: 150,
    },
    {
      Header: 'Telefone Residencial',
      accessor: 'Number_Phone1',
      width: 80,
    },
    {
      Header: () => <span className="mandatory-column">Celular*</span>,
      name: 'Celular',
      accessor: 'Number_Phone2',
      width: 80,
    },
    {
      Header: 'Data de Nascimento',
      accessor: 'Date_Birth',
      width: 80,
    },
    {
      Header: 'Insc. Estadual',
      accessor: 'IE',
      width: 80,
    },
    {
      Header: 'Insc. Municipal',
      accessor: 'IM',
      width: 80,
    },
    {
      Header: 'Indicador de IE',
      accessor: 'Status_IE',
      width: 80,
    },
    {
      Header: 'CEP (Principal)',
      accessor: 'ZipcodeP',
      width: 100,
    },
    {
      Header: 'Rua (Principal)',
      accessor: 'AddressP',
      width: 150,
    },
    {
      Header: 'Número (Principal)',
      accessor: 'Address_NumberP',
      width: 50,
    },
    {
      Header: 'Complemento (Principal)',
      accessor: 'ComplementP',
      width: 100,
    },
    {
      Header: 'Ponto de Referência (Principal)',
      accessor: 'Reference_PointP',
      width: 150,
    },
    {
      Header: 'Bairro (Principal)',
      accessor: 'NeighborhoodP',
      width: 150,
    },
    {
      Header: 'Cidade (Principal)',
      accessor: 'CityP',
      width: 150,
    },
    {
      Header: 'Estado (Principal)',
      accessor: 'StateP',
      width: 80,
    },
    {
      Header: 'País (Principal)',
      accessor: 'CountryP',
      width: 80,
    },
    {
      Header: 'CEP (Entrega)',
      accessor: 'ZipcodeE',
      width: 100,
    },
    {
      Header: 'Rua (Entrega)',
      accessor: 'AddressE',
      width: 150,
    },
    {
      Header: 'Número (Entrega)',
      accessor: 'Address_NumberE',
      width: 50,
    },
    {
      Header: 'Complemento (Entrega)',
      accessor: 'ComplementE',
      width: 100,
    },
    {
      Header: 'Ponto de Referência (Entrega)',
      accessor: 'Reference_PointE',
      width: 150,
    },
    {
      Header: 'Bairro (Entrega)',
      accessor: 'NeighborhoodE',
      width: 150,
    },
    {
      Header: 'Cidade (Entrega)',
      accessor: 'CityE',
      width: 150,
    },
    {
      Header: 'Estado (Entrega)',
      accessor: 'StateE',
      width: 80,
    },
    {
      Header: 'País (Entrega)',
      accessor: 'CountryE',
      width: 80,
    },
  ],
  providers: [
    {
      Header: '',
      accessor: 'dataFilled',
      width: 30,
      Cell: (props) => {
        if (props.value === 'all')
          return (
            <FontAwesomeIcon
              color="#5cb85c"
              style={iconStyle}
              icon={faCheckCircle}
            />
          );
        if (props.value === 'required')
          return (
            <FontAwesomeIcon
              color="#eea236"
              style={iconStyle}
              icon={faExclamationCircle}
            />
          );
        if (props.value === 'doesntHaveRequired')
          return (
            <FontAwesomeIcon
              color="#d9534f"
              style={iconStyle}
              icon={faTimesCircle}
            />
          );
        if (props.value === 'duplicated')
          return (
            <FontAwesomeIcon color="#7a43b6" style={iconStyle} icon={faClone} />
          );
      },
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 150,
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      headerClassName: 'text-center',
      Cell: (props) => (
        <Button
          id="botaoReactTable"
          style={{
            backgroundColor: props.value === 'Fisica' ? '#0d45a4' : '#631678',
          }}
        >
          {props.value}
        </Button>
      ),
    },
    {
      Header: () => (
        <span className="mandatory-column">Nome Completo / Razão Social*</span>
      ),
      name: 'Nome Completo / Razão Social',
      accessor: 'companyName',
      width: 150,
    },
    {
      Header: 'Nome Fantasia',
      accessor: 'tradingName',
      width: 150,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 150,
    },
    {
      Header: 'Telefone Residencial / Comercial',
      accessor: 'Number_Phone1',
      width: 80,
    },
    {
      Header: () => <span className="mandatory-column">Celular*</span>,
      name: 'Celular',
      accessor: 'Number_Phone2',
      width: 80,
    },
    {
      Header: 'Insc. Estadual',
      accessor: 'IE',
      width: 80,
    },
    {
      Header: 'Indicador de IE',
      accessor: 'statusIE',
      width: 80,
    },
    {
      Header: 'CEP',
      accessor: 'Zipcode',
      width: 100,
    },
    {
      Header: 'Rua',
      accessor: 'Address',
      width: 150,
    },
    {
      Header: 'Número',
      accessor: 'Address_Number',
      width: 50,
    },
    {
      Header: 'Complemento',
      accessor: 'Complement',
      width: 100,
    },
    {
      Header: 'Ponto de Referência',
      accessor: 'Reference_Point',
      width: 150,
    },
    {
      Header: 'Bairro',
      accessor: 'Neighborhood',
      width: 150,
    },
    {
      Header: 'Cidade',
      accessor: 'City',
      width: 150,
    },
    {
      Header: 'Estado',
      accessor: 'State',
      width: 80,
    },
  ],
};

export const getTableColumnsByImportType = (importType) =>
  tableColumns[importType];
