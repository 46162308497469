import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen,
  faCloudDownloadAlt,
  faShoppingCart,
  faTools,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';

import DashboardCard from '../home/DashboardCard';
import { SelectBranding } from 'v2/components/Select';
import SearchInput from '../../../components/SearchInput';
import DownloadXlsButton from '../../../components/DownloadXlsButton';

import {
  cpfMask,
  cnpjMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';
import { getSalesByClientInfoToExport, xlsColumns } from './excelHelpers';
import adminsRepository from '../../../repositories/Admins';
import useFilters from '../../../hooks/useFilters';
import { getDateOnlyFromDate } from '../../../utils/dateHelpers';

import './styles.css';

const SalesByClient = () => {
  const [loading, setLoading] = useState(true);
  const [isProcessEnabled, setIsProcessEnabled] = useState(true);
  const [clients, setClients] = useState([]);
  const [clientsInfoToExport, setClientsInfoToExport] = useState([]);
  const [clientsFiltered, setClientsFiltered] = useState([]);
  const [clientsTotals, setClientsTotals] = useState({
    sales: 0,
    orcaments: 0,
    orderOfServices: 0,
  });
  const [clientsGeneralIndicators, setClientsGeneralIndicators] = useState({
    activeClients: {
      total: 0,
      osd: 0,
      wm: 0,
    },
    sales: {
      total: 0,
      osd: 0,
      wm: 0,
    },
    orcaments: {
      total: 0,
      osd: 0,
      wm: 0,
    },
    orderOfServices: {
      total: 0,
      osd: 0,
      wm: 0,
    },
    pdvs: {
      total: 0,
      osd: 0,
      wm: 0,
    },
  });

  const { queryFilter, dateFilter, brandingFilter } = useFilters();
  const [brandingId, setBrandingId] = useState({
    value: '',
    label: 'Todos',
  });
  const [query, setQuery] = useState('');
  const { branding, setBranding, filterByBranding } = brandingFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate } = dateFilter;

  useEffect(() => {
    const loadClientsIndicators = async () => {
      try {
        const clientsGeneralIndicators =
          await adminsRepository.getClientsIndicators();
        setClientsGeneralIndicators({
          activeClients: {
            total: clientsGeneralIndicators.activeClients.total,
            osd: clientsGeneralIndicators.activeClients.osd,
            wm: clientsGeneralIndicators.activeClients.wm,
          },
          sales: {
            total: clientsGeneralIndicators.sales.total,
            osd: clientsGeneralIndicators.sales.osd,
            wm: clientsGeneralIndicators.sales.wm,
          },
          orcaments: {
            total: clientsGeneralIndicators.orcaments.total,
            osd: clientsGeneralIndicators.orcaments.osd,
            wm: clientsGeneralIndicators.orcaments.wm,
          },
          orderOfServices: {
            total: clientsGeneralIndicators.orderOfServices.total,
            osd: clientsGeneralIndicators.orderOfServices.osd,
            wm: clientsGeneralIndicators.orderOfServices.wm,
          },
          pdvs: {
            total: clientsGeneralIndicators.pdvs.total,
            osd: clientsGeneralIndicators.pdvs.osd,
            wm: clientsGeneralIndicators.pdvs.wm,
          },
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
        toastr.warning(
          'Ocorreu um erro ao buscar os indicadores. Por favor, tente novamente'
        );
      }
    };

    loadClientsIndicators();
  }, []);

  useEffect(() => {
    if (!isProcessEnabled) {
      setIsProcessEnabled(true);
    }
  }, [initialDate, finalDate, brandingId]);

  async function handleLoadClients() {
    setLoading(true);
    setQuery('');
    setBrandingId({
      value: '',
      label: 'Todos',
    });

    try {
      const clients = await adminsRepository.getClientsReport({
        initialDate,
        finalDate,
      });

      setClients(clients);
      setClientsFiltered(clients);
      setLoading(false);
      setIsProcessEnabled(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os clientes. Por favor, tente novamente'
      );
    }
  }

  useEffect(() => {
    const clientsInfoToExportAux =
      getSalesByClientInfoToExport(clientsFiltered);
    setClientsInfoToExport(clientsInfoToExportAux);

    handleClientsTotals();
  }, [clientsFiltered]);

  useEffect(() => {
    if (query === '' && brandingId.value === '') {
      setClientsFiltered(clients);
      return;
    }

    const clientsFilteredSerializer = clients.filter((client) => {
      const lowerCaseQuery = query.toLowerCase();
      const formattedCpfCnpj = client.Cpf_Cnpj.replace(/\D/g, '');

      const matchesQuery =
        client.Company_Name.toLowerCase().includes(lowerCaseQuery) ||
        formattedCpfCnpj.includes(lowerCaseQuery);

      const matchesBrandingId =
        brandingId.value === '' ||
        client.brandingId === parseInt(brandingId.value);

      return matchesQuery && matchesBrandingId;
    });

    setClientsFiltered(clientsFilteredSerializer);
  }, [query, brandingId, clients]);

  function handleClientsTotals() {
    const clientTotals = {
      sales: 0,
      orcaments: 0,
      orderOfServices: 0,
      pdvs: 0,
    };

    for (let client of clients) {
      clientTotals.sales += client.sales;
      clientTotals.orcaments += client.orcaments;
      clientTotals.orderOfServices += client.orderOfServices;
      clientTotals.pdvs += client.pdvs;
    }

    setClientsTotals(clientTotals);
  }

  return (
    <div id="sales-by-client-report">
      <header>
        <DashboardCard
          className="card-header"
          title={<span>Total de clientes ativos</span>}
          value={clientsGeneralIndicators.activeClients.total}
          color="#009688"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.activeClients.osd}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.activeClients.wm}
                </strong>
              </strong>
            </div>
          }
        />

        <DashboardCard
          className="card-header"
          title={<span>Orçamentos</span>}
          value={clientsGeneralIndicators.orcaments.total}
          color="#337ab7"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.orcaments.osd}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.orcaments.wm}
                </strong>
              </strong>
            </div>
          }
        />

        <DashboardCard
          className="card-header"
          title={<span>Vendas</span>}
          value={clientsGeneralIndicators.sales.total}
          color="#ffa834"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.sales.osd}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.sales.wm}
                </strong>
              </strong>
            </div>
          }
        />

        <DashboardCard
          className="card-header"
          title={<span>PDV</span>}
          value={clientsGeneralIndicators.pdvs.total}
          color="#d43f3a"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.pdvs.osd}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.pdvs.wm}
                </strong>
              </strong>
            </div>
          }
        />

        <DashboardCard
          className="card-header"
          title={<span>Ordens de Serviço</span>}
          value={clientsGeneralIndicators.orderOfServices.total}
          color="#021f54"
          subtitle={
            <div className="card-subtitle">
              <strong style={{ color: '#1d7391' }}>
                OSD:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.orderOfServices.osd}
                </strong>
              </strong>
              <strong style={{ color: '#444444' }}>
                WM:{' '}
                <strong style={{ color: '#4cae4c' }}>
                  {clientsGeneralIndicators.orderOfServices.wm}
                </strong>
              </strong>
            </div>
          }
        />
      </header>
      <main>
        <header>
          <fieldset>
            <SearchInput
              placeholder="Pesquisa por CNPJ ou Razão Social"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="initialDate">Data Inicial:</label>
            <input
              className="form-control foco-input"
              id="initialDate"
              type="date"
              max={
                finalDate
                  ? format(
                      new Date(getDateOnlyFromDate(finalDate)),
                      'yyyy-MM-dd'
                    )
                  : format(new Date(), 'yyyy-MM-dd')
              }
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <label htmlFor="finalDate">Data Final:</label>
            <input
              className="form-control foco-input"
              id="finalDate"
              type="date"
              min={
                initialDate
                  ? format(
                      new Date(getDateOnlyFromDate(initialDate)),
                      'yyyy-MM-dd'
                    )
                  : undefined
              }
              max={format(new Date(), 'yyyy-MM-dd')}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <SelectBranding
              fontSize={14}
              variant="column"
              height={35}
              value={brandingId}
              onChange={(e) => setBrandingId(e)}
              showAllOption
            />
          </fieldset>
          <fieldset>
            <button
              disabled={loading || !isProcessEnabled}
              className="btn btn-export"
              onClick={handleLoadClients}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </fieldset>
          <fieldset>
            <DownloadXlsButton
              archiveName={`Vendas-por-cliente-${format(
                new Date(),
                'dd-MM-yyyy'
              )}`}
              data={clientsInfoToExport}
              className="btn btn-export"
              disabled={loading || !clientsInfoToExport.length}
              columns={xlsColumns}
              style={{ width: '100%' }}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </fieldset>
        </header>

        <div>
          <div className="table">
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
              }}
              data={clientsFiltered}
              columns={[
                {
                  Header: 'CNPJ',
                  accessor: 'Cpf_Cnpj',
                  width: 140,
                  Cell: (props) =>
                    props.value.length === 11
                      ? cpfMask(props.value)
                      : cnpjMask(props.value),
                },
                {
                  Header: 'Razão Social',
                  accessor: 'Company_Name',
                  minWidth: 300,
                },
                {
                  Header: 'Orçamento',
                  accessor: 'orcaments',
                  width: 80,
                },
                {
                  Header: 'Venda',
                  accessor: 'sales',
                  width: 80,
                },
                {
                  Header: 'OS',
                  accessor: 'orderOfServices',
                  width: 80,
                },
                {
                  Header: 'PDV',
                  accessor: 'pdvs',
                  width: 80,
                },
                {
                  Header: 'R$ Vendido',
                  accessor: 'BRLSold',
                  width: 100,
                  Cell: (props) => <a>{currency(props.value)}</a>,
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Company_Name',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum cliente encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
          <aside>
            <DashboardCard
              className="card-aside"
              title={<span>Orçamentos</span>}
              value={clientsTotals.orcaments}
              color="#337ab7"
            />
            <DashboardCard
              className="card-aside"
              title={<span>Vendas</span>}
              value={clientsTotals.sales}
              color="#ffa834"
            />
            <DashboardCard
              className="card-aside"
              title={<span>PDV</span>}
              value={clientsTotals.pdvs}
              color="#d43f3a"
            />
            <DashboardCard
              className="card-aside"
              title={<span>Ordens de Serviço</span>}
              value={clientsTotals.orderOfServices}
              color="#021f54"
            />
          </aside>
        </div>
      </main>
    </div>
  );
};

export default SalesByClient;
