import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'

import Card from 'components/Card/Card.jsx'

import { getDateOnlyFromDate } from '../../../../utils/dateHelpers'
import { toastr } from 'react-redux-toastr'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { format, differenceInDays } from 'date-fns'
import { useAuth } from '../../../../contexts/auth'

import stoneCardsRepository from '../../../../repositories/StoneCards'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const StoneTransactionsTable = () => {
  const [extractions, setExtractions] = useState([])
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [toExportExtractions, setToExportExtractions] = useState([])
  const [hasStoneExtractions, setHasStoneExtractions] = useState(false)

  const { companyId } = useAuth()

  useEffect(() => {
    const formattedValues = formatToXlsData(extractions)
    setToExportExtractions(formattedValues)
  }, [extractions])

  const getStoneExtractions = async () => {
    try {
      if (initialDate > finalDate) {
        toastr.warning('Selecione um período válido')
      }
      const differenceBetweenDays = differenceInDays(
        new Date(finalDate),
        new Date(initialDate)
      )
      if (differenceBetweenDays > 31) {
        throw new Error('Selecione um período entre 31 dias')
      }
      setLoading(true)
      let allExtractions = await stoneCardsRepository.getExtrations({
        companyId,
        initialDate,
        finalDate,
        type,
      })
      allExtractions.map((data) => {
        let type = ''
        if (data.type == 1) {
          type = data.type = 'Débito'
        } else if (data.type == 2) {
          type = data.type = 'Crédito'
        } else if (data.type == 4) {
          type = data.type = 'Antecipação'
        } else {
          type = data.type = ''
        }
        return {
          ...data,
          type,
        }
      })
      if (allExtractions.length <= 0) {
        setHasStoneExtractions(true)
      }
      setExtractions(allExtractions)
    } catch (err) {
      if (err) {
        toastr.warning(`${err.message}. Por favor, tente novamente`)
      } else {
        toastr.error(
          'Ocorreu ao carregar os extratos. Por favor, tente novamente'
        )
      }

      setExtractions([])
    } finally {
      setLoading(false)
    }
  }

  const formatToXlsData = (data) => {
    return data.map((data) => ({
      ...data,
      date: format(getDateOnlyFromDate(data.date), 'dd/MM/yyyy'),
      value: currency(data.value),
    }))
  }

  return (
    <>
      <Card
        content={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <header style={{ display: 'flex', marginBottom: '10px' }}>
              <section style={{ marginRight: '5px' }}>
                <label style={{ fontWeight: 'bold' }}>Data Inicial:</label>
                <input
                  type='date'
                  max='9999-12-31'
                  className='form-control foco-input'
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                />
              </section>
              <section style={{ marginRight: '5px' }}>
                <label style={{ fontWeight: 'bold' }}>Data Final:</label>
                <input
                  type='date'
                  max='9999-12-31'
                  className='form-control foco-input'
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </section>
              <div style={{ marginRight: '5px', width: '20%' }}>
                <label style={{ fontWeight: 'bold' }}>Tipo:</label>
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className='form-control foco-input'
                >
                  <option value=''>Todos</option>
                  <option value='1'>Débito</option>
                  <option value='2'>Crédito</option>
                  <option value='4'>Antecipação</option>
                </select>
              </div>
              <section style={{ marginTop: '38px', marginRight: '5px' }}>
                <button
                  className='btn btn-sucesso'
                  type='submit'
                  disabled={loading}
                  onClick={getStoneExtractions}
                  disabled={
                    initialDate === '' && finalDate === '' && type === ''
                  }
                >
                  <span
                    className={`${
                      loading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                    }`}
                  />{' '}
                  Gerar relatório
                </button>
              </section>

              <div style={{ marginTop: '38px' }}>
                <DownloadXlsButton
                  archiveName={`Extrato Stone
                  ${initialDate}
                  -${finalDate}`}
                  data={toExportExtractions}
                  className='btn btn-export'
                  disabled={loading || !toExportExtractions.length}
                  columns={[
                    {
                      name: 'Data',
                      acessor: 'date',
                    },
                    {
                      name: 'Tipo',
                      acessor: 'type',
                    },
                    {
                      name: 'Venda/OS',
                      acessor: 'sale',
                    },
                    {
                      name: 'Cliente',
                      acessor: 'customer',
                    },
                    {
                      name: 'Valor',
                      acessor: 'value',
                    },
                    {
                      name: 'Bandeira',
                      acessor: 'brand',
                    },
                  ]}
                >
                  <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} />{' '}
                  Exportar .xls
                </DownloadXlsButton>
              </div>
            </header>

            <main>
              <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                data={extractions}
                columns={[
                  {
                    Header: 'Data',
                    accessor: 'date',
                    headerClassName: 'text-left',

                    Cell: (props) =>
                      !!props.value
                        ? format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')
                        : '',
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'type',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Venda/OS',
                    accessor: 'sale',
                    headerClassName: 'text-left',
                    width: 150,
                  },
                  {
                    Header: 'Cliente',
                    accessor: 'customer',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Valor',
                    accessor: 'value',
                    headerClassName: 'text-left',
                    Cell: (props) => currency(props.value),
                  },
                  {
                    Header: 'Bandeira',
                    accessor: 'brand',
                    headerClassName: 'text-left',
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                previousText='Anterior'
                nextText='Próximo'
                loadingText='Carregando...'
                pageText='Página'
                ofText='de'
                noDataText={
                  hasStoneExtractions
                    ? 'Não há extratos nesse período'
                    : 'Selecione os filtros para gerar o relatório'
                }
                rowsText='linhas'
              />
            </main>
          </div>
        }
      />
    </>
  )
}

export default StoneTransactionsTable
