import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../../../../../client/components/CustomButton/CustomButton'
import {
  currency,
  percentage,
} from '../../../../../components/ToNormalize/ToNormalize'

const ConfirmModal = ({ onCancel, onSubmit, data, confirmModalData }) => {
  return (
    <div>
      <Modal show animation onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS DIGITAL</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong style={{ fontSize: '16px' }}>
            Você tem certeza que quer alterar o(s) preço(s) do seguinte filtro:
          </strong>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexFlow: 'column wrap',

              lineHeight: '5px',
            }}
          >
            <p style={{ marginTop: '20px' }}>
              <strong>Família:</strong>
              {data[0].Family}
            </p>
            <p>
              <strong>Marca:</strong>
              {data[0].Brand}
            </p>
            <p style={{ marginBottom: '20px' }}>
              <strong>Status:</strong>
              {confirmModalData.status === '1'
                ? 'Ativo'
                : data.status === '2'
                ? 'Inativo'
                : 'Ambos'}
            </p>
            <p>
              <strong>Alterar por:</strong>
              {confirmModalData.isTypePercentageOrValue === '1'
                ? 'Porcentagem'
                : 'Valor'}
            </p>
            <p>
              <strong>Função:</strong>
              {confirmModalData.isAddOrSubtract === '1' ? 'Somar' : 'Subtrair'}
            </p>

            <p>
              <strong>Valor:</strong>
              {confirmModalData.isTypePercentageOrValue === '1'
                ? percentage(confirmModalData.newValue)
                : currency(confirmModalData.newValue)}
            </p>
            <p style={{ margin: '10px 0' }}>
              <strong>Quantidade de produtos:</strong>
              {data.length}
            </p>
            <p style={{ color: 'red' }}>
              A alteração de preço é irreversível. Uma vez efetuada, não poderá
              ser desfeita
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button bsStyle='danger' name='Calcelar' onClick={onCancel} fill>
              Não
            </Button>
            <Button bsStyle='info' name='Salvar' fill onClick={onSubmit}>
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ConfirmModal
