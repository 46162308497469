import api from '../services/api';

const getProductsAndServicesRanking = async (params) => {
  const { data } = await api.get(`ranking-sales`, {
    params,
  });
  return data;
};

export default {
  getProductsAndServicesRanking,
};
