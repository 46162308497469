import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useModulesContext } from '../../../../../contexts/modules'
import SupportButton from 'client/components/SupportButton'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const AvailableInactiveNFSePattern = ({ city, pattern }) => {
  const { nfseModule } = useModulesContext()

  return (
    <>
      <div>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <label>Cidade</label>
            <br />
            <input
              name="city"
              type="text"
              value={city}
              disabled="true"
              style={{
                borderRadius: '4px',
                border: '1px solid #b7b6b6',
                padding: '8px 12px',
                width: '80%',
              }}
            ></input>
          </Col>

          <Col xs={12} sm={12} md={5} lg={5}>
            <label>Padrão</label>
            <br />
            <input
              name="pattern"
              type="text"
              value={pattern}
              disabled="true"
              style={{
                borderRadius: '4px',
                border: '1px solid #b7b6b6',
                padding: '8px 12px',
                width: '80%',
              }}
            ></input>
          </Col>
        </Row>
        <div style={{ paddingTop: '10px' }}>
          <p style={{ fontSize: '14px' }}>
            Para utilizar a emissão da
            <strong> nota fiscal de serviço eletrônica (NFS-e)</strong> no OS
            Digital será adicionado o valor de
            <strong> {currency(nfseModule.price)}</strong> na sua mensalidade.
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontSize: '14px', paddingRight: '5px' }}>
              Para realizar a assinatura do módulo, solicite junto ao
              atendimento ao cliente clicando no botão ao lado:
            </p>
            <SupportButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default AvailableInactiveNFSePattern
