import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react'
import ReactTable from 'react-table';

const InventoryTable = ({
  products,
  totalValues,
  loading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={products}
        columns={[
          {
            Header: 'NCM',
            accessor: 'productNCM',
            width: 100,
          },
          {
            Header: 'Código',
            accessor: 'productCode',
            width: 100,
          },
          {
            Header: 'Descrição',
            accessor: 'productDescription',
            width: 500,
          },
          {
            Header: 'Unidade',
            accessor: 'productUnitType',
            width: 100,
            Footer: (
              <strong>Total: </strong>
            )
          },
          {
            Header: 'Quantidade',
            accessor: 'productQuantity',
            width: 125,
            Footer: (
              <strong>{totalValues.productsTotalStock}</strong>
            )
          },
          {
            Header: 'Preço Unitário',
            accessor: 'productPrice',
            width: 125,
            Cell: props => currency(props.value),
            Footer: (
              <strong>{currency(totalValues.productsTotalPurchasePrice)}</strong>
            )
          },
          {
            Header: 'Total',
            accessor: 'productTotalPrice',
            width: 125,
            Cell: props => currency(props.value),
            Footer: (
              <strong>{currency(totalValues.productsTotalPrice)}</strong>
            )
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'Company_Name',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Selecione um período e os filtros que deseja para fazer a pesquisa."
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default InventoryTable;
