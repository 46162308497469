import React from "react";
import { Grid, Row } from "react-bootstrap";
import BundleFormUsuario from './BundleFormUsuario'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const User = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginBottom: '10px', paddingLeft: 0 }}>
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb data={['Início', 'Meus Usuários', match.params.id ? 'Editar Usuário' : 'Novo Usuário']}
              path={['home', 'users', null]} />
            <br />
            <BundleFormUsuario userId={match.params.id} />
          </div>
        </Row>
      </Grid>
    </div>
  );
}

export default User
