import React from 'react'

const renderSelect = ({ input, maxLength, meta: { touched, error }, ...rest }) => (
  <textarea
    className="form-control foco-input" {...input} {...rest}
    maxLength={maxLength}
    style={touched && error ? { borderColor: 'red' } : {}}
  />
)

export default renderSelect