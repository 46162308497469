import React from 'react'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'

import constants from '../../../utils/constants'

const ModalAlertCompany = ({ show, onHide, onClick, history }) => {

  return (
    <Modal show={show} animation onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title><strong>O.S Digital</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <label> Ops, identificamos que você ainda não cadastrou os dados da sua empresa.</label>
        <br />
        <button className="btn btn-sucesso" onClick={() => !onClick ? history.push(constants.ROUTES.COMPANY_DATA): onClick()}>
          Cadastrar Dados da Empresa
                </button>
      </Modal.Body>
    </Modal>
  )
}

ModalAlertCompany.propTypes = {
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  onClick: propTypes.func
}

export default withRouter(ModalAlertCompany)