import React from 'react';
import { useTheme } from 'styled-components';

import { InputReactSelect, InputWrapper } from './styles';

export const InputSelectInTable = React.forwardRef(
  (
    {
      width,
      height,
      disabled,
      isLoading = false,
      isSearchable = false,
      placeholder = 'Selecione...',
      options,
      noOptionsMessage = 'Sem Opções',
      defaultValue,
      isMulti,
      onChange,
      value,
      onInputChange,
      fontSize,
      isRowTable = false,
      borderColor,
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <InputWrapper width={width} height={height}>
        <InputReactSelect
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isMulti={isMulti}
          value={value}
          onChange={onChange}
          noOptionsMessage={() => noOptionsMessage}
          options={options}
          onInputChange={onInputChange}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: isRowTable ? 99999 : 99999,
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: isRowTable ? 99999 : 99999,
            }),
            container: (base) => ({
              ...base,
              width: width || 'auto',
              height: height || '40px',
              border: `1px solid ${borderColor || '#CACACA'}`,
            }),
            control: (base) => ({
              ...base,
              boxSizing: 'border-box',
              height: height || '40px',
              border: 'none',
              boxShadow: 'none',
              minHeight: '35px',
              paddingBottom: '5px',
              background: 'transparent',
              fontSize: fontSize || '14px',
              display: 'flex',
              alignItems: 'center',
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              boxSizing: 'border-box',
              height: height || '40px',
              paddingTop: 0,
              fontSize: fontSize || '14px',
              paddingLeft: '5px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            input: (provided, state) => ({
              ...provided,
              boxSizing: 'border-box',
              margin: 0,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '##515151',
              width: '30px',
              '&:hover': {
                color: '##515151',
              },
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              left: '-5px',
            }),
            option: (provided, state) => ({
              ...provided,
              fontSize: fontSize || '14px',
              backgroundColor: state.isSelected
                ? theme.colors.branding1000
                : 'inherit',
              '&:hover': {
                backgroundColor: state.isSelected
                  ? theme.colors.branding900
                  : 'rgb(222, 235, 255)',
              },
            }),
          }}
          isDisabled={disabled || isLoading}
          isLoading={isLoading}
        />
      </InputWrapper>
    );
  }
);

InputSelectInTable.displayName = 'InputSelectInTable';
