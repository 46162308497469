import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { H1 } from '../components/H1';
import { currency } from '../../../client/components/ToNormalize/ToNormalize';
import constants from '../../../utils/constants';

const Content = ({ children, style, ...rest }) => (
  <View style={[styles.content, style]} {...rest}>
    {children}
  </View>
);

const Table = ({ children, ...rest }) => (
  <View style={styles.table} {...rest}>
    {children}
  </View>
);

const TableHead = ({ children, style = {}, ...rest }) => (
  <View style={[styles.thead, style]} {...rest}>
    {children}
  </View>
);

const TableHeadTitle = ({ children, style, ...rest }) => (
  <Text style={[styles.theadTitle, style]} {...rest}>
    {children}
  </Text>
);

const TableBody = ({ children, ...rest }) => (
  <View style={styles.tbody} {...rest}>
    {children}
  </View>
);

const TableBodyRow = ({ children, style = {}, ...rest }) => (
  <View style={[styles.tbodyRow, style]} {...rest}>
    {children}
  </View>
);

const TableBodyRowData = ({ children, style, ...rest }) => (
  <View style={[styles.tbodyRowData, style]} {...rest}>
    {children}
  </View>
);

const CashClosingReportDocument = ({
  cashierBankHistoric,
  dailyResume,
  receivedByFormOfPayment,
  cashierConferencePayments,
  hasCashierConference,
}) => {
  function isFinancialMovementExit(financialMovement) {
    return (
      (financialMovement.finality === 0 &&
        financialMovement.originCashierBankId ===
          cashierBankHistoric.cashierBankId) ||
      financialMovement.finality === constants.FINANCIAL_MOVEMENTS_FINALITY.EXIT
    );
  }
  const receivedByFormOfPaymentAndCashierConferencePayments =
    receivedByFormOfPayment.rows.map((received) => {
      const cashierConferencePayment = cashierConferencePayments?.find(
        (cashierConferencePayment) =>
          cashierConferencePayment.formOfPaymentId === received.formOfPaymentId
      );

      return {
        ...received,
        cashValue: cashierConferencePayment?.cashValue || 0,
        observation: cashierConferencePayment?.observation,
        difference:
          (cashierConferencePayment?.cashValue || 0) - received.amount,
      };
    });

  const totalCashier =
    receivedByFormOfPaymentAndCashierConferencePayments.reduce(
      (total, received) => {
        return total + (received.cashValue || 0);
      },
      0
    );

  const totalDifference =
    receivedByFormOfPaymentAndCashierConferencePayments.reduce(
      (total, received) => {
        return total + received.difference;
      },
      0
    );

  return (
    <Document
      title={`relatorio-fechamento-de-caixa-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="Relatório de Fechamento de Caixa"
    >
      <Page size="A4" style={styles.page}>
        <H1 fixed style={styles.mainTitle}>
          {cashierBankHistoric.closedDate === null
            ? 'Fechamento de Caixa - Aberto'
            : 'Fechamento de Caixa - Fechado'}
        </H1>
        <Content fixed>
          <Text style={styles.contentDetailsLabel}>
            {format(new Date(cashierBankHistoric.openDate), 'dd/MM/yyyy HH:mm')}
            {cashierBankHistoric.closedDate &&
              ` - ${format(
                new Date(cashierBankHistoric.closedDate),
                'dd/MM/yyyy HH:mm'
              )}`}
          </Text>
        </Content>

        <Content>
          <Text style={[styles.title]}>
            {cashierBankHistoric.CashierBank.description}
          </Text>
        </Content>

        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Text style={styles.subTitle}>
            {hasCashierConference && cashierConferencePayments?.length > 0
              ? 'Conferência de Caixa'
              : 'Valores vendidos por Forma de Pagamento'}
          </Text>
        </View>

        <View style={styles.line} />

        <Content>
          {(!hasCashierConference ||
            (hasCashierConference &&
              !cashierConferencePayments?.length > 0)) && (
            <Table>
              <TableHead
                style={[
                  styles.contentText,
                  {
                    paddingBottom: 3.5,
                    fontWeight: 'bold',
                    marginTop: '2px',
                  },
                ]}
              >
                <TableHeadTitle style={{ marginLeft: -5, width: '30%' }}>
                  Tipo
                </TableHeadTitle>
                <TableHeadTitle style={{ marginLeft: -5, width: '20%' }}>
                  Quantidade
                </TableHeadTitle>
                <TableHeadTitle style={{ marginLeft: -5, width: '20%' }}>
                  Total
                </TableHeadTitle>
              </TableHead>

              <TableBody>
                {receivedByFormOfPayment.rows.map((received) => {
                  return (
                    <TableBodyRow key={received.formOfPaymentId}>
                      <TableBodyRowData
                        style={[styles.contentText, { width: '30%' }]}
                      >
                        <Text>{received.description}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[styles.contentText, { width: '20%' }]}
                      >
                        <Text>{received.quantity}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[styles.contentText, { width: '20%' }]}
                      >
                        <Text>{currency(received.amount)}</Text>
                      </TableBodyRowData>
                    </TableBodyRow>
                  );
                })}
              </TableBody>

              <View style={styles.line} />

              <TableBodyRow>
                <TableBodyRowData
                  style={[
                    styles.contentText,
                    {
                      width: '30%',
                      fontWeight: 'bold',
                    },
                  ]}
                >
                  <Text>{'Total:'}</Text>
                </TableBodyRowData>
                <TableBodyRowData
                  style={[styles.contentText, { width: '20%' }]}
                >
                  <Text>{''}</Text>
                </TableBodyRowData>
                <TableBodyRowData
                  style={[
                    styles.contentText,
                    { width: '20%', fontWeight: 'bold' },
                  ]}
                >
                  <Text>{currency(receivedByFormOfPayment.totalAmount)}</Text>
                </TableBodyRowData>
              </TableBodyRow>
            </Table>
          )}
        </Content>

        {hasCashierConference && cashierConferencePayments?.length > 0 && (
          <Table>
            <TableHead
              style={[
                styles.contentText,
                {
                  paddingBottom: 3.5,
                  fontWeight: 'bold',
                  marginTop: '2px',
                },
              ]}
            >
              <TableHeadTitle style={{ marginLeft: -5, width: '20%' }}>
                Descrição
              </TableHeadTitle>
              <TableHeadTitle style={{ marginLeft: -5, width: '15%' }}>
                Valor Receb.
              </TableHeadTitle>
              <TableHeadTitle style={{ marginLeft: -5, width: '15%' }}>
                Valor Caix.
              </TableHeadTitle>
              <TableHeadTitle style={{ marginLeft: -5, width: '15%' }}>
                Dif.
              </TableHeadTitle>
              <TableHeadTitle style={{ marginLeft: -5, width: '35%' }}>
                Observações
              </TableHeadTitle>
            </TableHead>

            <TableBody>
              {receivedByFormOfPaymentAndCashierConferencePayments.map(
                (received) => (
                  <TableBodyRow key={received.formOfPaymentId}>
                    <TableBodyRowData
                      style={[styles.contentText, { width: '20%' }]}
                    >
                      <Text>{received.description}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[
                        styles.contentText,
                        { marginLeft: -5, width: '15%' },
                      ]}
                    >
                      <Text>{currency(received.amount)}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[
                        styles.contentText,
                        { marginLeft: -5, width: '15%' },
                      ]}
                    >
                      <Text>{currency(received.cashValue)}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[
                        styles.contentText,
                        {
                          marginLeft: -5,
                          width: '15%',
                          color: received.difference < 0 ? 'red' : '',
                        },
                      ]}
                    >
                      <Text>{currency(received.difference)}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[
                        styles.contentText,
                        { marginLeft: -5, width: '35%' },
                      ]}
                    >
                      <Text>{received.observation}</Text>
                    </TableBodyRowData>
                  </TableBodyRow>
                )
              )}
            </TableBody>

            <View style={styles.line} />

            <TableBodyRow>
              <TableBodyRowData
                style={[
                  styles.contentText,
                  {
                    width: '20%',
                    fontWeight: 'bold',
                  },
                ]}
              >
                <Text>{'Total:'}</Text>
              </TableBodyRowData>
              <TableBodyRowData
                style={[
                  styles.contentText,
                  { marginLeft: -5, width: '15%', fontWeight: 'bold' },
                ]}
              >
                <Text>{currency(receivedByFormOfPayment.totalAmount)}</Text>
              </TableBodyRowData>
              <TableBodyRowData
                style={[
                  styles.contentText,
                  { marginLeft: -5, width: '15%', fontWeight: 'bold' },
                ]}
              >
                <Text>{currency(totalCashier)}</Text>
              </TableBodyRowData>
              <TableBodyRowData
                style={[
                  styles.contentText,
                  { marginLeft: -5, width: '35%', fontWeight: 'bold' },
                ]}
              >
                <Text>{currency(totalDifference)}</Text>
              </TableBodyRowData>
            </TableBodyRow>

            <View style={[styles.line, { marginBottom: '5px' }]} />
          </Table>
        )}

        <Content>
          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Text style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Resumo Diário
            </Text>
          </View>
        </Content>

        <Content style={{ marginTop: -3.5 }}>
          <Table>
            <TableHead
              style={[
                styles.contentText,
                { paddingBottom: 3.5, fontWeight: 'bold', marginTop: '2px' },
              ]}
            >
              <TableHeadTitle style={[{ marginLeft: -5, width: '40%' }]}>
                Descrição
              </TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>Valor</TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>Tipo</TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>
                R$ Custo
              </TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>
                Lucro(%)
              </TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>
                Lucro(R$)
              </TableHeadTitle>
            </TableHead>

            <TableBody>
              <TableBodyRow>
                <TableBodyRowData style={{ width: '40%' }}>
                  <Text style={[styles.contentText, { fontWeight: 'bold' }]}>
                    Saldo Anterior
                  </Text>
                </TableBodyRowData>
                <TableBodyRowData style={{ width: '12%' }}>
                  <Text style={[styles.contentText]}>
                    {currency(dailyResume.amountPreviousDay)}
                  </Text>
                </TableBodyRowData>
              </TableBodyRow>

              {dailyResume.movements.map((movement, index) => (
                <View key={index}>
                  <TableBodyRow>
                    <TableBodyRowData style={{ width: '40%' }}>
                      <Text style={[styles.contentText]}>
                        {!movement.saleId &&
                          (movement.financialMovementTypeId ===
                          constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT
                            ? '(M)'
                            : movement.table !== 'financialMovements'
                            ? ''
                            : '(T)')}{' '}
                        {movement.description}
                      </Text>
                      {!!movement.observation && (
                        <Text style={styles.observations}>
                          Observações: {movement.observation}
                        </Text>
                      )}
                    </TableBodyRowData>
                    <TableBodyRowData style={{ width: '12%' }}>
                      <Text
                        style={[
                          isFinancialMovementExit(movement) &&
                            styles.tbodyRowDataRedLabel,
                          styles.contentText,
                          { width: '12%' },
                        ]}
                      >
                        {currency(
                          movement.value ||
                            movement.expense ||
                            movement.income ||
                            0
                        )}
                      </Text>
                    </TableBodyRowData>
                    <TableBodyRowData style={{ width: '12%' }}>
                      <Text
                        style={
                          (isFinancialMovementExit(movement) &&
                            styles.tbodyRowDataRedLabel,
                          styles.contentText)
                        }
                      >
                        {isFinancialMovementExit(movement) ? '(-)' : '(+)'}
                      </Text>
                    </TableBodyRowData>
                    {!!movement.saleId && !!movement.validShowSale ? (
                      <View
                        style={{
                          width: '36%',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <TableBodyRowData style={{ width: '33.3%' }}>
                          <Text style={[styles.contentText]}>
                            {movement.Sale?.SalesItems?.length ? (
                              currency(
                                getValueCost(movement.Sale.SalesItems || [])
                              )
                            ) : (
                              <View />
                            )}
                          </Text>
                        </TableBodyRowData>
                        <TableBodyRowData style={{ width: '33.3%' }}>
                          <Text style={[styles.contentText]}>
                            {movement.Sale?.SalesItems?.length ? (
                              getProfitValuePercent(movement.Sale)
                            ) : (
                              <View />
                            )}
                          </Text>
                        </TableBodyRowData>

                        <TableBodyRowData style={{ width: '33.3%' }}>
                          <Text style={[styles.contentText]}>
                            {movement.Sale?.SalesItems?.length ? (
                              currency(getProfitValue(movement.Sale))
                            ) : (
                              <View />
                            )}
                          </Text>
                        </TableBodyRowData>
                      </View>
                    ) : (
                      <View />
                    )}
                  </TableBodyRow>

                  {/* Detalhes da venda / compra */}
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    {movement.Sale?.SalesItems?.length &&
                    movement.validShowSale ? (
                      <>
                        <View style={[styles.line, { width: '90%' }]} />

                        <TableBodyRow style={{ width: '90%' }}>
                          <TableBodyRowData style={{ width: '6%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Tipo
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '20%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Descrição
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '12%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Marca
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '6%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Qtd
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Valor Unit.
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '12%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Desc. Unit(%)
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Total
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '8%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Custo
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Lucro(%)
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text
                              style={[
                                styles.additionalInfo,
                                { fontWeight: 'bold' },
                              ]}
                            >
                              Lucro(R$)
                            </Text>
                          </TableBodyRowData>
                        </TableBodyRow>
                        <View style={[styles.line, { width: '90%' }]} />
                      </>
                    ) : (
                      <View />
                    )}

                    {movement.validShowSale &&
                      movement.Sale?.SalesItems?.map((item) => (
                        <TableBodyRow style={{ width: '90%' }}>
                          <TableBodyRowData style={{ width: '6%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {item.Type === 'Produto' ? 'P' : 'S'}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '20%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {item.Description.slice(0, 25)}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '12%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {item.Products?.Brands?.Description}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '6%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {item.Quantity}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {currency(item.Unit_Value)}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '12%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {movement.Sale?.Discount_Value
                                ? getValuePercentItem(
                                    item.Amount,
                                    movement.Sale
                                  ) + '%'
                                : item.Discount_Value + '%'}
                            </Text>
                          </TableBodyRowData>
                          <TableBodyRowData style={{ width: '10%' }}>
                            <Text style={[styles.additionalInfo]}>
                              {movement.Sale?.Discount_Value
                                ? currency(
                                    getValueItem(item.Amount, movement.Sale)
                                  )
                                : currency(item.Amount)}
                            </Text>
                          </TableBodyRowData>

                          {item.Type === 'Produto' ? (
                            <>
                              <TableBodyRowData style={{ width: '8%' }}>
                                <Text style={[styles.additionalInfo]}>
                                  {currency(item.Value_Cost * item.Quantity)}
                                </Text>
                              </TableBodyRowData>
                              <TableBodyRowData style={{ width: '10%' }}>
                                <Text style={[styles.additionalInfo]}>
                                  {parseFloat(
                                    (100 *
                                      ((movement.Sale?.Discount_Value
                                        ? getValueItem(
                                            item.Amount,
                                            movement.Sale
                                          )
                                        : item.Amount) -
                                        item.Value_Cost * item.Quantity)) /
                                      (item.Value_Cost * item.Quantity)
                                  ).toFixed(2) + '%'}
                                </Text>
                              </TableBodyRowData>
                            </>
                          ) : (
                            <View style={{ width: '18%' }} />
                          )}

                          {item.Type === 'Produto' ? (
                            <TableBodyRowData style={{ width: '10%' }}>
                              <Text style={[styles.additionalInfo]}>
                                {currency(
                                  (movement.Sale?.Discount_Value
                                    ? getValueItem(item.Amount, movement.Sale)
                                    : item.Amount) -
                                    item.Value_Cost * item.Quantity
                                )}
                              </Text>
                            </TableBodyRowData>
                          ) : (
                            <View style={{ width: '10%' }} />
                          )}
                        </TableBodyRow>
                      ))}
                  </View>
                </View>
              ))}
              <TableBodyRow style={{ marginTop: 15, marginBottom: -10 }}>
                <TableBodyRowData style={styles.tbodyRowDataResumeDescription}>
                  <Text>Fechamento de Caixa:</Text>
                </TableBodyRowData>
                <TableBodyRowData style={styles.tbodyRowDataResumeValue}>
                  <Text>{currency(dailyResume.amount)}</Text>
                </TableBodyRowData>
              </TableBodyRow>
            </TableBody>
          </Table>
        </Content>
      </Page>
    </Document>
  );
};

const getValueCost = (salesItems) => {
  let valueCost = 0;

  salesItems.forEach((item) => {
    valueCost += item?.Type === 'Produto' ? item.Value_Cost * item.Quantity : 0;
  });

  return valueCost;
};

const getProfitValue = (sale) => {
  let totalItems = 0;

  sale.SalesItems.forEach((item) => {
    totalItems +=
      item?.Type === 'Produto' ? getValueItem(item.Amount, sale) : 0;
  });

  return totalItems - getValueCost(sale.SalesItems || []);
};

const getValuePercentItem = (item, sale) => {
  const discount = Math.round(
    ((item * 100) / sale.SubTotal) * sale.Discount_Value,
    2
  );
  const percent = parseFloat((discount * 100) / item / 100).toFixed(2);

  return percent;
};

const getValueItem = (item, sale) => {
  const discount = ((item * 100) / sale.SubTotal / 100) * sale.Discount_Value;

  return item - discount;
};

const getProfitValuePercent = (sale) => {
  const cost = getValueCost(sale.SalesItems || []);
  const profit = getProfitValue(sale);

  if (cost == 0 && profit == 0) return parseFloat(0).toFixed(2) + '%';

  const profitValuePercentTotal = parseFloat((profit * 100) / cost).toFixed(2);

  return profitValuePercentTotal + '%';
};

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    fontSize: 10,
  },
  mainTitle: {
    marginBottom: 10,
  },

  subTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: 5,
  },

  content: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 5,
  },

  additionalInfo: {
    fontFamily: 'Roboto',
    fontSize: 8,
  },

  contentText: {
    fontFamily: 'Roboto',
    fontSize: 9,
  },

  contentDetailsLabel: {
    fontSize: 12,
  },

  table: {
    display: 'table',
  },

  thead: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },

  theadTitle: {
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: '0px 5px',
  },

  theadReceivedByPaymentFormTitleDescription: {
    width: 430,
  },

  theadReceivedByPaymentFormTitleValue: {
    width: 430,
  },

  theadResumeTitleDescription: {
    width: 430,
  },

  theadResumeTitleValue: {
    width: 100,
  },

  theadResumeTitleType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tbody: {
    padding: '5px 0px 10px 0px',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },

  tbodyRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },

  tbodyRowData: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'flex-start',
    margin: '4px 0px',
  },

  tbodyRowDataRedLabel: {
    color: 'red',
  },

  tbodyRowDataReceivedByPaymentFormDescription: {
    width: 430,
  },

  tbodyRowDataReceivedByPaymentFormValue: {
    width: 100,
  },

  tbodyRowDataResumeDescription: {
    width: 430,
  },

  tbodyRowDataResumeValue: {
    width: 100,
  },

  tbodyRowDataResumeType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  observations: {
    fontSize: 10,
    fontFamily: 'Roboto',
    marginLeft: 20,
  },
  page: {
    padding: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
});
export default CashClosingReportDocument;
