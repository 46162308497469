import React from "react";

import { Field, change } from "redux-form";
import { Col } from "react-bootstrap";
import Toggle from "react-toggle";
import RenderField from "../../../../../components/RenderField";
import { useDispatch, useSelector } from "react-redux";

const MainData = () => {
  const dispatch = useDispatch();
  const { isActive } = useSelector((state) => state.form.formCostCenter.values);

  const handleToggle = () => {
    dispatch(change("formCostCenter", "isActive", !isActive));
  };

  return (
    <>
      <Col xs={12} sm={6} md={4} lg={4}>
        <Field
          label="Descrição:"
          name="description"
          component={RenderField}
          maxLength="30"
          required
        />
      </Col>
      <Col xs={12} sm={1} md={1} lg={1}>
        <label>Status:</label>
        <div style={{ paddingLeft: "10px" }}>
          <Toggle checked={isActive} onChange={handleToggle} />
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Field
          label="Observações:"
          name="observations"
          component={RenderField}
          as="textarea"
          maxLength="300"
        />
      </Col>
    </>
  );
};

export default MainData;
