import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { Col } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';

import RenderField from 'components/RenderField';
import AlertModal from '../../../../components/AlertModal';
import cashierBanksRepository from '../../../../repositories/CashierBank';
import accountPlansRepository from '../../../../repositories/AccountPlans';
import NewAccountPlanModal from 'client/components/FormModals/AccountPlan';
import FormModalCashierBank from '../../../components/FormModals/CashierBank';

import { percentMask } from 'utils/masks';

import { useAuth } from 'contexts/auth';
import companiesRepository from 'repositories/Companies';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const MainData = () => {
  const [cashierBanks, setCashierBanks] = useState([]);
  const [isFormModalCashierBankOpen, setIsFormModalCashierBankOpen] =
    useState(false);
  const [isNewAccountPlanModalOpen, setIsNewAccountPlanModalOpen] =
    useState(false);
  const [
    isConfirmCashierControlModalOpen,
    setIsConfirmCashierControlModalOpen,
  ] = useState(false);
  const [hasConfirmCashierControl, setHasconfirmCashierControl] =
    useState(false);
  const [newAccountModalType, setNewAccountModalType] = useState('');
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false);
  const { isWorkmotor } = usePlanSignatureContext();

  const {
    hasCashierControl,
    hasAutomaticOpenAndCloseCashierMovement,
    monthlyInterest,
    cashierConference,
    generateTitleDiff,
    manageInternalCredit,
  } = useSelector((state) => state.form.financialConfiguration.values);

  const [saleAccounts, setSaleAccounts] = useState([]);
  const [purchaseAndChangeAccounts, setPurchaseAndChangeAccounts] = useState(
    []
  );

  const dispatch = useDispatch();
  const { companyId } = useAuth();

  useEffect(() => {
    loadCashierBanks();
    loadAccountPlans();
  }, []);

  async function loadAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({ companyId });
      const accountPlans = data;

      const saleAccounts = accountPlans.filter(
        (accountPlan) =>
          accountPlan.type === 'Crédito' && accountPlan.categoryId === 1
      );

      const purchaseAndChangeAccounts = accountPlans.filter(
        (accountPlan) =>
          accountPlan.type === 'Débito' && accountPlan.categoryId !== 1
      );

      setSaleAccounts(saleAccounts);
      setPurchaseAndChangeAccounts(purchaseAndChangeAccounts);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os planos de conta. Por favor, tente novamente'
      );
    }
  }

  async function loadCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      const serializedCashierBanks = cashierBanks.map((cashierBank) => ({
        id: cashierBank.id,
        description: cashierBank.description,
        initials: cashierBank.CashierBankType.initials,
      }));

      setCashierBanks(serializedCashierBanks);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os caixa/bancos. Por favor, tente novamente'
      );
    }
  }

  function handleSubmitFormModalCashierBank() {
    loadCashierBanks();
    setIsFormModalCashierBankOpen(false);
  }

  async function handleSubmitNewAccountPlanModal(planAccount) {
    if (newAccountModalType === 'sale') {
      dispatch(
        change(
          'financialConfiguration',
          'defaultSaleAccountPlanId',
          planAccount.created.id
        )
      );
    } else if (newAccountModalType === 'purchase') {
      dispatch(
        change(
          'financialConfiguration',
          'defaultPurchaseAccountPlanId',
          planAccount.created.id
        )
      );
    }

    await loadAccountPlans();
    setIsNewAccountPlanModalOpen(false);
  }

  function handleChangeCashierControl() {
    if (hasCashierControl) {
      dispatch(change('financialConfiguration', 'hasCashierControl', false));
    } else if (!hasConfirmCashierControl) {
      setIsConfirmCashierControlModalOpen(true);
    } else {
      dispatch(change('financialConfiguration', 'hasCashierControl', true));
    }
  }

  function handleSubmitConfirmCashierControlModal() {
    dispatch([
      change('financialConfiguration', 'hasCashierControl', true),
      change(
        'financialConfiguration',
        'hasAutomaticOpenAndCloseCashierMovement',
        true
      ),
    ]);
    setIsConfirmCashierControlModalOpen(false);
    setHasconfirmCashierControl(true);
  }

  function handleChangeGenerateTitleDiff() {
    dispatch(
      change('financialConfiguration', 'generateTitleDiff', !generateTitleDiff)
    );
  }
  function handleManageInternalCredit() {
    if (!isWorkmotor) return setIsOpenFeatureNotAvailableModal(true);

    dispatch(
      change(
        'financialConfiguration',
        'manageInternalCredit',
        !manageInternalCredit
      )
    );
  }

  return (
    <div className="financial-form-main-data">
      <Col xs={12} sm={12} md={3} lg={3} style={{ position: 'relative' }}>
        <Field
          name="defaultCashierBankId"
          component={RenderField}
          label="Caixa/Banco Padrão:"
          required
          as="select"
        >
          <option value="">Selecione</option>
          {cashierBanks.map((cashierBank) => (
            <option value={cashierBank.id} key={cashierBank.id}>
              {cashierBank.initials} - {cashierBank.description}
            </option>
          ))}
        </Field>
        <span
          className="hiperlink"
          onClick={() => setIsFormModalCashierBankOpen(true)}
        >
          Adicionar Caixa/Banco
        </span>
      </Col>
      <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
        <label>
          Controle de Caixa/Banco: <span>*</span>
        </label>
        <Toggle
          checked={hasCashierControl}
          onChange={handleChangeCashierControl}
        />
      </Col>
      <Col xs={12} sm={12} md={4} lg={4} className="toggle-column">
        <label>
          Movimentação automática de abertura/fechamento<span>*</span>
        </label>
        <Toggle
          checked={hasAutomaticOpenAndCloseCashierMovement}
          onChange={() =>
            dispatch(
              change(
                'financialConfiguration',
                'hasAutomaticOpenAndCloseCashierMovement',
                !hasAutomaticOpenAndCloseCashierMovement
              )
            )
          }
          disabled={!hasCashierControl}
        />
      </Col>

      <Col xs={12} sm={12} md={2} lg={2} className="toggle-column">
        <label>Conferência de Caixa</label>
        <Toggle
          checked={cashierConference}
          onChange={() =>
            dispatch(
              change(
                'financialConfiguration',
                'cashierConference',
                !cashierConference
              )
            )
          }
        />
      </Col>

      <Col
        xs={12}
        sm={12}
        md={12}
        lg={11}
        style={{ margin: '30px 0', position: 'relative' }}
      >
        <Col xs={12} sm={12} md={4} lg={4} style={{ paddingLeft: '0' }}>
          <Field
            name="defaultSaleAccountPlanId"
            component={RenderField}
            label="Plano de conta padrão de venda:"
            required
            as="select"
          >
            {saleAccounts.map((saleAccount) => (
              <option value={saleAccount.id} key={saleAccount.id}>
                {saleAccount.description}
              </option>
            ))}
          </Field>
          <span
            className="hiperlink"
            onClick={() => {
              setIsNewAccountPlanModalOpen(true);
              setNewAccountModalType('sale');
            }}
          >
            Adicionar Conta
          </span>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name="defaultPurchaseAccountPlanId"
            component={RenderField}
            label="Plano de conta padrão de compra:"
            required
            as="select"
          >
            {purchaseAndChangeAccounts.map((purchaseAccount) => (
              <option value={purchaseAccount.id} key={purchaseAccount.id}>
                {purchaseAccount.description}
              </option>
            ))}
          </Field>
          <span
            className="hiperlink"
            onClick={() => {
              setIsNewAccountPlanModalOpen(true);
              setNewAccountModalType('purchase');
            }}
          >
            Adicionar Conta
          </span>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name="defaultChangeAccountPlanId"
            component={RenderField}
            label="Plano de conta padrão de troco:"
            required
            as="select"
          >
            {purchaseAndChangeAccounts.map((changeAccount) => (
              <option value={changeAccount.id} key={changeAccount.id}>
                {changeAccount.description}
              </option>
            ))}
          </Field>
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} style={{ position: 'relative' }}>
        <Col xs={3} sm={3} md={3} lg={3} style={{ paddingLeft: '0' }}>
          <label>Juros mensal em títulos vencidos:</label>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
            }}
          >
            <Toggle
              style={{ marginLeft: '10px' }}
              checked={monthlyInterest}
              onChange={() =>
                dispatch([
                  change(
                    'financialConfiguration',
                    'monthlyInterest',
                    !monthlyInterest
                  ),
                  change('financialConfiguration', 'monthlyInterestValue', 0),
                ])
              }
            />
            <Field
              style={{ marginLeft: '8px' }}
              name="monthlyInterestValue"
              component={RenderField}
              maxLength="7"
              disabled={!monthlyInterest}
              {...percentMask}
            />
          </div>
        </Col>

        <Col xs={2} sm={2} md={2} lg={2}>
          <Field
            label="Modelo de recibo:"
            component={RenderField}
            name="billModel"
            as="select"
          >
            <option value="A4">Folha A4</option>
            <option value="A5">Folha A5</option>
          </Field>
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
          <label>Gerar diferença no contas a receber:</label>
          <Toggle
            checked={generateTitleDiff}
            onChange={handleChangeGenerateTitleDiff}
          />
        </Col>

        {isWorkmotor && (
          <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
            <label>Gerenciar Crédito Interno:</label>
            <Toggle
              checked={manageInternalCredit}
              onChange={handleManageInternalCredit}
            />
          </Col>
        )}
      </Col>

      {isFormModalCashierBankOpen && (
        <FormModalCashierBank
          onCancel={() => setIsFormModalCashierBankOpen(false)}
          onSubmit={handleSubmitFormModalCashierBank}
        />
      )}
      {isNewAccountPlanModalOpen && (
        <NewAccountPlanModal
          onCancel={() => setIsNewAccountPlanModalOpen(false)}
          onSubmit={handleSubmitNewAccountPlanModal}
          newAccountModalType={newAccountModalType}
        />
      )}
      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />
      <AlertModal
        show={isConfirmCashierControlModalOpen}
        title="Função de Caixa/Banco"
        message="Deseja ativar a função de caixa em seu sistema? Ao ativar, poderá utilizar a função de recebimento, controle de banco e caixa. A função poderá ser desativada posteriormente"
        onHide={() => setIsConfirmCashierControlModalOpen(false)}
        onCancel={() => setIsConfirmCashierControlModalOpen(false)}
        onSubmit={handleSubmitConfirmCashierControlModal}
      />
    </div>
  );
};

export default MainData;
