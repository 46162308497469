import config from '../config';
import api from '../services/api';

const getAllByCustomer = async (customerId) => {
  const response = await api.get(
    config.endpoint + `vehicle?customerId=${customerId}`
  );
  return response.data;
};

const getAllByCompanyPaginated = async (query) => {
  const { data } = await api.get('vehicle/list-paginated', { params: query });
  return data;
};

const getAllByCompany = async (companyId) => {
  const response = await api.get(
    config.endpoint + `vehicle?companyId=${companyId}`
  );
  return response.data;
};

const show = async (vehicleId) => {
  const response = await api.get(config.endpoint + `vehicle/${vehicleId}`);
  return response.data;
};

const create = async (values) => {
  const response = await api.post(config.endpoint + 'vehicle', values);
  return response.data;
};

const update = async (id, values) => {
  await api.put(config.endpoint + `vehicle/${id}`, values);
};

const remove = async (id) => {
  await api.delete(config.endpoint + `vehicle/${id}`);
};
const getVehicleByPlate = async (customerId, licensePlate) => {
  const respose = await api.get(config.endpoint + `vehicle`, {
    params: { customerId, licensePlate },
  });

  return respose.data;
};

const updatePlate = async ({ oldPlate, newPlate, companyId }) => {
  try {
    const response = await api.put(
      config.endpoint +
        `vehicle/plate/mercosul-update?oldPlate=${oldPlate}&newPlate=${newPlate}&companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const vehicleValidateByPlate = async ({ plate, companyId }) => {
  try {
    const response = await api.get(
      config.endpoint +
        `vehicle/validate/byplate?plate=${plate}&companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const vehicleValidateOpenSales = async ({ licensePlate, companyId }) => {
  try {
    const response = await api.get(
      config.endpoint + `vehicle/validate/open-sales`,
      {
        params: {
          licensePlate,
          companyId,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getVehicleTypes = async () => {
  const response = await api.get('vehicle-type');
  return response.data;
};

const getBicycleBrakeType = async () => {
  const response = await api.get('bicycle-brake-type');
  return response.data;
};

const getBicycleSuspensionType = async () => {
  const response = await api.get('bicycle-suspension-type');
  return response.data;
};

const getBicycleFrontGears = async () => {
  const response = await api.get('bicycle-front-gears');
  return response.data;
};

const getBicycleRearGears = async () => {
  const response = await api.get('bicycle-rear-gears');
  return response.data;
};

const getBicycleRimSizes = async () => {
  const response = await api.get('bicycle-rim-size');
  return response.data;
};

const getBicycleFrameSizes = async () => {
  const response = await api.get('bicycle-frame-size');
  return response.data;
};

const getBicycleModality = async () => {
  const response = await api.get('bicycle-modality');
  return response.data;
};

const getBicycleRearDerailleur = async () => {
  const response = await api.get('bicycle-rear-derailleur');
  return response.data;
};

const getBicycleFrameMaterial = async () => {
  const response = await api.get('bicycle-frame-material');
  return response.data;
};

const getBicycleModelsByBrand = async (brandId, companyId) => {
  const response = await api.get(`bicycle-model/brand/${brandId}`, {
    params: { companyId },
  });
  return response.data;
};

const getVehiclesByCustomerPaginated = async (params) => {
  const response = await api.get('vehicle/get-vehicles-by-customer', {
    params,
  });
  return response.data;
};

const getVehicleTypeByCompanySegments = async (companyId) => {
  const response = await api.get(`vehicle-type/by-company/${companyId}`);
  return response.data;
};

const createBicycleModel = async (values) => {
  const response = await api.post('bicycle-model', values);
  return response.data;
};

const vehicleValidateOpenSalesWithSale = async (params) => {
  const response = await api.get('vehicle/sales-by-plate', {
    params,
  });
  return response.data;
};

export default {
  getAllByCustomer,
  show,
  create,
  update,
  remove,
  getVehicleByPlate,
  updatePlate,
  vehicleValidateByPlate,
  getAllByCompany,
  getAllByCompanyPaginated,
  vehicleValidateOpenSales,
  getVehicleTypes,
  getBicycleBrakeType,
  getBicycleSuspensionType,
  getBicycleFrontGears,
  getBicycleRearGears,
  getBicycleRimSizes,
  getBicycleFrameSizes,
  getBicycleModality,
  getBicycleRearDerailleur,
  getBicycleFrameMaterial,
  getBicycleModelsByBrand,
  getVehiclesByCustomerPaginated,
  getVehicleTypeByCompanySegments,
  createBicycleModel,
  vehicleValidateOpenSalesWithSale,
};
