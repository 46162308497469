import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import profilesRepository from '../../../../../repositories/Profiles'
import constants from '../../../../../utils/constants'
import { useHistory } from 'react-router-dom'
import FormSimilarity from './FormSimilarity'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'

import similarityRepository from '../../../../../repositories/Similarities'

const initialValues = {
}

const BundleFormSimilarity = ({ product, modal, handleModal, hideModal }) => {
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()

  const submit = values => {
    if (!values.productId || !values.similarProductId) {
     return toastr.warning('Por favor, adicione um produto similar para salvar')
    }
    
    return create(values.productId, values.similarProductId)
  }

  const create = async (productId, similarProductId) => {
    try {
      const response = await similarityRepository.create({
        productId,
        similarProductId
      })

      if (modal) {
        toastr.success('Produto similar adicionado com sucesso.')
        return handleModal(true)
      }

      return toastr.success('Produto similar adicionado com sucesso.')
    } catch (err) {
      return toastr.warning('Ocorreu um erro ao adicionar a similaridade.')
    }
  }

  return (
    <FormSimilarity
      modal={modal}
      hideModal={hideModal}
      product={product}
      onSubmit={submit}
      initialValues={initialValues}
      loading={loading}
    />
  )
}

export default BundleFormSimilarity