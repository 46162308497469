import React from 'react';
import { Modal, Col } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton';

import { reduxForm, Field } from 'redux-form';
import { useSelector } from 'react-redux';

import TextArea from '../../Clients/NewClient/FormClient/components/TextArea';

import { currency } from '../../../components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';

function FormCorrectionLetterModal({
  onCancel,
  onHide,
  handleSubmit,
  loading,
  nfeSelected,
}) {
  const {
    correctionLetter: {
      values: { correction },
    },
  } = useSelector((state) => state.form);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      toastr.error(
        'Comando Inválido',
        'Carta de Correção não permite quebra de linha (Enter), apenas texto corrido.'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton={onHide}>
        <Modal.Title>
          <strong>Carta de Correção Eletrônica</strong>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              NF-e:{' '}
              <span style={{ fontWeight: 'normal' }}>{nfeSelected?.code}</span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Cliente:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {nfeSelected?.clientName}
              </span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Valor:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {currency(nfeSelected?.total || 0)}
              </span>
            </span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col style={{ marginBottom: 5 }}>
          <label>Insira a correção abaixo:</label>
          <span
            style={{
              position: 'absolute',
              right: 15,
              top: 30,
              color: correction?.length >= 15 ? 'green' : 'red',
            }}
          >
            {correction?.length || 0} caracteres
          </span>
          <Field
            name="correction"
            component={TextArea}
            placeholder="O motivo deverá ter pelo menos 15 caracteres"
            onKeyPress={handleKeyPress}
          />
        </Col>
        <Col className="col-alerta">
          <p style={{ fontWeight: 'bold' }}>
            Não poderá ser feito correção caso as informações estejam
            relacionadas com:
          </p>
          <p>
            1 - As variáveis que determinam o valor do imposto tais como: base
            de cálculo, alíquota, diferença de preço, quantidade, valor;
          </p>
          <p>
            2 - A correção de dados cadastrais que implique mudança do remetente
            ou do destinário;
          </p>
          <p>3 - A data de emissão da NF-e ou a data de saída da mercadoria.</p>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button bsStyle="danger" disabled={loading} onClick={onCancel} fill>
            Voltar
          </Button>
          <Button
            bsStyle="info"
            disabled={loading}
            fill
            type="submit"
            onClick={handleSubmit}
          >
            Emitir CC-e{' '}
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
      </Modal.Footer>
    </form>
  );
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'correctionLetter',
})(FormCorrectionLetterModal);
