import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SwitchRow } from 'v2/components/form';
import { MainFeatureContent } from '../MainFeatureContent';
import { useModulesContext } from 'contexts/modules';

export function GroupFeatureContent({ currentGroupFeatureIndex }) {
  const {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  } = useModulesContext();
  const modules = {
    isAnyFiscalModuleActive: [
      'CFOP',
      'NCM/NBS',
      'Texto para Notas Fiscais',
      'Transportadora',
      'Tributo por Estado',
      'CNAE',
      'Intermediador',
      'Fiscal',
      'Notas Fiscais',
    ],
    isNFeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFCeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFSeModuleActive: ['Nota Fiscal de Serviço (NFS-e)'],
    isWorkshopPanelModuleActive: ['Painel da Oficina'],
  };

  const activeModules = {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  };

  function hasActiveFeatureModule(feature) {
    for (let module in modules) {
      if (modules[module].includes(feature.description)) {
        return feature.isAllowedToUse && activeModules[module];
      }
    }
    return feature.isAllowedToUse;
  }

  const { watch, setValue } = useFormContext();

  const { groupFeatures } = watch();
  const currentGroupFeature = groupFeatures[currentGroupFeatureIndex];

  const isAllSubFeaturesEnabled = groupFeatures[
    currentGroupFeatureIndex
  ].mainFeatures.reduce((acc, current, currentIndex) => {
    if (acc === false && currentIndex !== 0) {
      return false;
    }

    if (
      current.subFeatures
        .filter((subFeature) => subFeature.isAllowedToUse)
        .some((subFeature) => !subFeature.status)
    )
      return false;

    return true;
  }, false);

  function handleGroupFeatureSwitchToggle() {
    const newStatus = !currentGroupFeature.status;
    setValue(`groupFeatures[${currentGroupFeatureIndex}].status`, newStatus);

    return changeAllFeatures(newStatus);
  }

  function changeAllFeatures(newStatus) {
    const changedMainFeatures = currentGroupFeature.mainFeatures.map(
      (mainFeature) => ({
        ...mainFeature,
        status:
          newStatus &&
          hasActiveFeatureModule(mainFeature) &&
          mainFeature.isAllowedToUse,
        subFeatures: mainFeature.subFeatures.map((subFeature) => ({
          ...subFeature,
          status:
            newStatus &&
            hasActiveFeatureModule(subFeature) &&
            subFeature.isAllowedToUse,
        })),
      })
    );

    setValue(
      `groupFeatures[${currentGroupFeatureIndex}].mainFeatures`,
      changedMainFeatures
    );
    changeIsMasterStatus();
    return;
  }

  function changeIsMasterStatus() {
    const newStatus = currentGroupFeature.status;

    setValue(`isMaster`, newStatus);
  }

  function validateGroupFeatureStatusBySubfeatures() {
    if (isAllSubFeaturesEnabled) {
      setValue(`groupFeatures[${currentGroupFeatureIndex}].status`, true);
    } else {
      setValue(`groupFeatures[${currentGroupFeatureIndex}].status`, false);
    }
  }

  useEffect(() => {
    validateGroupFeatureStatusBySubfeatures();
  }, [isAllSubFeaturesEnabled]);

  return (
    <div style={{ margin: '20px' }}>
      <SwitchRow
        width={'100%'}
        title={`Todo o módulo de ${currentGroupFeature?.description}`}
        subTitle={
          'Inclui todas as funções disponíveis e as futuras. Funções não disponíveis no plano permanecerão inativas.'
        }
        onChange={() => handleGroupFeatureSwitchToggle()}
        checked={currentGroupFeature.status}
        visible={true}
        style={{ marginBottom: '20px' }}
        radius="full"
        disabledToggle={!hasActiveFeatureModule(currentGroupFeature)}
      />

      <MainFeatureContent currentGroupFeatureIndex={currentGroupFeatureIndex} />
    </div>
  );
}
