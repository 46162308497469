import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import InventoryCountMain from './components/InventoryCountMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import constants from '../../../utils/constants'

const InventoryCount = () => {
  return (
    <>
      <div className="content">
        <Grid fluid>
          <Row>
            <BreadCrumb
              data={['Início', 'Produtos', 'Inventário/Contagem']}
              path={[constants.ROUTES.HOME, null, null]}
            />
            <InventoryCountMain />
          </Row>
        </Grid>
      </div>
    </>
  )
}

export default InventoryCount
