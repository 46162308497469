import api from '../services/api'

const simulate = async (saleId) => {
  const response = await api.post('/boleto-flex/transaction/simulate', {
    saleId,
  })
  return response.data
}

const create = async (saleId) => {
  const response = await api.post('/boleto-flex/transaction', { saleId })
  return response.data
}

const BoletoFlexTransactions = {
  simulate,
  create,
}

export default BoletoFlexTransactions
