import React from 'react';
import PropTypes from 'prop-types';
import { Container, RequiredFieldSpan } from './DefaultContentContainer.styles';

function DefaultContentContainer({ children, showRequiredFieldsLabel }) {
  return (
    <Container>
      {showRequiredFieldsLabel && (
        <RequiredFieldSpan id="label__required-field">
          * Campos Obrigatórios
        </RequiredFieldSpan>
      )}

      {children}
    </Container>
  );
}

DefaultContentContainer.propTypes = {
  children: PropTypes.node,
  showRequiredFieldsLabel: PropTypes.bool,
};

export { DefaultContentContainer };
