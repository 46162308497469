import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from 'client/components/CustomButton/CustomButton'

import './styles.css'

export default function ChangeDataModal({ company, onCancel, selectOption }) {
  return (
    <Modal show animation>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Clique na opção desejada:</p>
        <div className='options-container'>
          <Button
            fill
            style={{
              background: '#428BCA',
              borderColor: '#428BCA'
            }}
            name='update-cpf-cnpj'
            onClick={() => selectOption('cpf-cnpj')}
          >
            Atualizar CPF/CNPJ
          </Button>
          <Button
            fill
            style={{
              background: '#5CB85C',
              borderColor: '#5CB85C'
            }}
            name='update-email'
            onClick={() => selectOption('user-email')}
          >
            Atualizar email de usuário
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='danger' name='cancelar' onClick={onCancel} fill>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
