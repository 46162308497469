import React, { useEffect, useState } from 'react'
import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import { PDFViewer } from '@react-pdf/renderer'

import { ProductsFamilyOrBrandPDF } from './ProductsFamilyOrBrandPDF'
import ProductRepository from 'repositories/Products'

export const ProductsFamilyOrBrandReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [selectedListBy, setSelectedListBy] = useState('')

  const [result, setResult] = useState([])
  const [totalsPerFamilyOrBrand, setTotalsPerFamilyOrBrand] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const companyId = searchParams.get('companyId')
      const familyId = searchParams.get('familyId')
      const brandId = searchParams.get('brandId')
      const isActive = searchParams.get('isActive')

      setHasError(false)

      return loadReport(companyId, { familyId, brandId, isActive })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (companyId, params) => {
    try {
      const selectedFamilyId = params.familyId == '' ? '' : params.familyId
      const selectedBrandId = params.brandId == '' ? '' : params.brandId
      const selectedActive = params.isActive == false ? false : params.isActive

      const data = await ProductRepository.getAllByFamilyOrBrand({
        ...params,
        companyId,
        Family_id: selectedFamilyId,
        Brand_id: selectedBrandId,
        IsActive: selectedActive
      })

      setResult(data.productByFamilyOrBrand)
      setTotalsPerFamilyOrBrand(data.totalsPerFamilyOrBrand)

      if (selectedFamilyId) {
        setSelectedListBy('family')
      } else {
        setSelectedListBy('brand')
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='quotes-supplier-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <ProductsFamilyOrBrandPDF
            result={result}
            totalsPerFamilyOrBrand={totalsPerFamilyOrBrand}
            selectedListBy={selectedListBy}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
