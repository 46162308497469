import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faXmark } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';
import { toastr } from 'react-redux-toastr';
import { useBankConciliationStore } from 'v2/store/bankConciliation';

export const useBankConciliationDetailsColumnsConfig = (
  setValue,
  getValues
) => {
  const isViewing = getValues('isViewing');
  const [isOpenEditorConciliationModal, setIsOpenEditorConciliationModal] =
    React.useState(false);
  const history = useHistory();

  const { filterRemoveConciliation } = useBankConciliationStore((store) => ({
    filterRemoveConciliation: store.filterRemoveConciliation,
  }));

  const handleCheckboxChange = (transaction, isChecked) => {
    const selectedTransactions = getValues('selectedTransactions') || [];

    const isAlreadySelected = selectedTransactions.some(
      (t) => t.transactionId === transaction.transactionId
    );

    const updatedTransactions = isChecked
      ? isAlreadySelected
        ? selectedTransactions
        : [...selectedTransactions, transaction]
      : selectedTransactions.filter(
          (t) => t.transactionId !== transaction.transactionId
        );

    setValue('selectedTransactions', updatedTransactions);
  };

  const handleCheckboxChangeFinancialRegister = (transaction, isChecked) => {
    const selectedTransactions =
      getValues('selectedTransactionsConciliation') || [];

    const isAlreadySelected = selectedTransactions.some(
      (t) => t.id === transaction.id
    );

    const updatedTransactions = isChecked
      ? isAlreadySelected
        ? selectedTransactions
        : [...selectedTransactions, transaction]
      : selectedTransactions.filter((t) => t.id !== transaction.id);

    setValue('selectedTransactionsConciliation', updatedTransactions, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const deselectAllCheckboxes = () => {
    setValue('selectedTransactionsConciliation', []);
  };

  const handleRemoveConciliation = async (transaction) => {
    try {
      await BankConciliationRepositoryV2.removeConciliation({
        transactionId: transaction.id,
      });

      filterRemoveConciliation(transaction.id, false);
      handleCheckboxChange(transaction, false);

      toastr.success('Conciliação removida com sucesso.');
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao remover a conciliação.');
    }
  };

  const configColumnsConciliation = [
    {
      Header: 'Dt. Conciliação',
      accessor: 'updatedAt',
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Banco',
      accessor: 'bankInfo',
      width: 200,
    },
    {
      Header: 'Dt. Inicial OFX',
      accessor: 'initialDate',
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Dt. Final OFX',
      accessor: 'finalDate',
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Tot. Transações',
      accessor: 'totalTransactions',
    },
    {
      Header: 'Tot. Conciliações',
      accessor: 'totalConciliations',
    },
    {
      Header: 'Responsável',
      accessor: 'responsable',
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 70,
      Cell: (props) => (
        <FontAwesomeIcon
          title="Visualizar"
          cursor="pointer"
          style={{ height: '1.5em', width: '1.5em', color: 'black' }}
          icon={faEye}
          onClick={() =>
            history.push(`bank-conciliation/view-conciliation/${props.value}`)
          }
        />
      ),
    },
  ];
  const configColumnsDetails = [
    {
      Header: '',
      accessor: 'checkbox',
      Cell: (props) => {
        const selectedTransactions = getValues('selectedTransactions') || [];

        const isChecked = selectedTransactions.some(
          (transaction) =>
            transaction.transactionId === props.original.transactionId
        );
        const isConciliated = props.original.conciled;

        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              handleCheckboxChange(props.original, e.target.checked);
            }}
            disabled={isConciliated || isViewing}
            style={{ padding: '0px' }}
          />
        );
      },
      width: 70,
      padding: 0,
    },
    {
      Header: 'Data',
      accessor: 'datePosted',
      Cell: (props) => {
        const date = new Date(props.value);
        return new Intl.DateTimeFormat('pt-BR').format(date);
      },
    },

    {
      Header: 'Tipo',
      accessor: 'type',
      Cell: (props) => {
        return (
          <span
            style={{
              textTransform: 'uppercase',
            }}
          >
            {props.value}
          </span>
        );
      },
    },
    {
      Header: 'Valor',
      accessor: 'amount',
      Cell: (props) => {
        const value = Number(props.value);
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const color = value > 0 ? '#5CB85C' : '#D9534F';

        return <span style={{ color }}>{formatter.format(value)}</span>;
      },
    },
    {
      Header: 'Descrição',
      accessor: 'memo',
      Cell: (props) => props?.value,
    },
    {
      Header: 'Identificador',
      accessor: 'checkNumber',
      headerClassName: 'text-left',
      filterable: false,
      className: 'texto',

      Cell: (props) => props?.value,
    },
    {
      Header: 'Status',
      accessor: 'conciled',
      headerClassName: 'text-left',
      filterable: false,
      className: 'texto',

      Cell: (props) => {
        const statusLabel = props?.value ? 'Conciliado' : 'Nao Conciliado';
        const statusColor = props?.value ? '#5CB85C' : '#D9534F';

        return (
          <span
            style={{
              backgroundColor: statusColor,
              color: 'white',
              /* padding: '3px 12px', */
              borderRadius: '5px',
              width: '120px',
            }}
          >
            {statusLabel}
          </span>
        );
      },
    },
  ];

  if (isViewing) {
    configColumnsDetails.push({
      Header: 'Ações',
      accessor: '',
      width: 70,
      Cell: (props) => {
        const isConciliated = props.original.conciled;

        return (
          isConciliated && (
            <div>
              <FontAwesomeIcon
                cursor="pointer"
                icon={faXmark}
                title="Remover conciliação"
                style={{ color: '#d43f3a', width: '1.5em', height: '1.5em' }}
                disabled={!isConciliated || !isViewing}
                onClick={() => handleRemoveConciliation(props.original)}
              />
            </div>
          )
        );
      },
    });
  }

  const configColumnsFinancialRegister = [
    {
      Header: '',
      accessor: 'checkbox',
      Cell: (props) => {
        const selectedTransactions =
          getValues('selectedTransactionsConciliation') || [];

        // Verifica se a transação está selecionada
        const isChecked = selectedTransactions.some(
          (transaction) => transaction.id === props.original.id
        );

        return (
          <input
            type="checkbox"
            checked={isChecked}
            disabled={isViewing}
            onChange={(e) => {
              handleCheckboxChangeFinancialRegister(
                props.original,
                e.target.checked
              );
            }}
            style={{ padding: '0px' }}
          />
        );
      },
      width: 80,
    },

    {
      Header: 'Titulo',
      accessor: 'titleCode',
      Cell: (props) => props.value,
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      Cell: (props) => {
        // Determina o valor e a cor com base no tipo
        const type = props.value;
        let statusLabel;
        let statusColor;

        if (type === 'Receber') {
          statusLabel = 'Receber';
          statusColor = '#5CB85C'; // Verde
        } else if (type === 'Pagar') {
          statusLabel = 'Pagar';
          statusColor = '#D9534F'; // Vermelho
        } else {
          statusLabel = type; // Usa o valor original se não for Crédito ou Débito
          statusColor = '#999'; // Cor padrão para outros tipos
        }

        return (
          <span
            style={{
              backgroundColor: statusColor,
              color: 'white',
              /* padding: '3px 12px', */
              borderRadius: '5px',
              width: '76px',
            }}
          >
            {statusLabel}
          </span>
        );
      },
    },
    {
      Header: 'Venda/Compra',
      accessor: 'saleOrPurchase',
    },
    {
      Header: 'Vencimento',
      accessor: 'dueDate',
      Cell: (props) => {
        const date = new Date(props.value);
        return new Intl.DateTimeFormat('pt-BR').format(date);
      },
    },
    {
      Header: 'Cliente/Fornecedor',
      accessor: 'customerOrProvider',
      Cell: (props) => props?.value,
      width: 400,
    },
    {
      Header: 'Valor em Aberto',
      accessor: 'openValue',
      Cell: (props) => {
        const value = Number(props.value);
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const color = value > 0 ? '#5CB85C' : '#D9534F';

        return <span style={{ color }}>{formatter.format(value)}</span>;
      },
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: (props) => {
        const value = Number(props.value);
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const color = value > 0 ? '#5CB85C' : '#D9534F';

        return <span style={{ color }}>{formatter.format(value)}</span>;
      },
    },
    {
      Header: 'status',
      accessor: 'status',

      Cell: (props) => {
        const status = props.value;
        let statusLabel;
        let statusColor;

        if (status === 'Finalizado') {
          statusLabel = 'Finalizado';
          statusColor = '#5CB85C';
        } else if (status === 'Aberto') {
          statusLabel = 'Aberto';
          statusColor = '#FFC40D';
        } else {
          statusLabel = status;
          statusColor = '#999';
        }

        return (
          <span
            style={{
              backgroundColor: statusColor,
              color: 'white',
              /* padding: '3px 12px', */
              borderRadius: '5px',
              width: '100px',
            }}
          >
            {statusLabel}
          </span>
        );
      },
    },
  ];

  const configColumnsFinancialMovements = [
    {
      Header: '',
      accessor: 'checkbox',
      Cell: (props) => {
        const selectedTransactions =
          getValues('selectedTransactionsConciliation') || [];

        // Verifica se a transação está selecionada
        const isChecked = selectedTransactions.some(
          (transaction) => transaction.id === props.original.id
        );

        //Trocar para logica Movimentação Financeiora

        return (
          <input
            type="checkbox"
            checked={isChecked}
            disabled={isViewing}
            onChange={(e) => {
              handleCheckboxChangeFinancialRegister(
                props.original,
                e.target.checked
              );
            }}
          />
        );
      },
      width: 70,
    },

    {
      Header: 'Dt. Criação',
      accessor: 'createdAt',
      Cell: (props) => {
        const date = new Date(props.value);
        return new Intl.DateTimeFormat('pt-BR').format(date);
      },
    },
    {
      Header: 'Código',
      accessor: 'code',
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      Cell: (props) => {
        const type = props.value;
        let statusLabel;
        let statusColor;

        if (type === 'Movimentação') {
          statusLabel = 'Movimentação';
          statusColor = '#428BCA';
        } else if (type === 'Transferência') {
          statusLabel = 'Transferência';
          statusColor = '#5CB85C';
        } else {
          statusLabel = type;
          statusColor = '#999';
        }

        return (
          <span
            style={{
              backgroundColor: statusColor,
              color: 'white',
              /* padding: '3px 12px', */
              borderRadius: '5px',
              width: '120px',
            }}
          >
            {statusLabel}
          </span>
        );
      },
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 400,
    },
    {
      Header: 'Valor',
      accessor: 'total',
      Cell: (props) => {
        const value = Number(props.value);
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const color = value > 0 ? '#5CB85C' : '#D9534F';

        return <span style={{ color }}>{formatter.format(value)}</span>;
      },
    },

    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props) => {
        const status = props.value;
        let statusLabel;
        let statusColor;

        if (status === 'Finalizada') {
          statusLabel = 'Finalizada';
          statusColor = '#46A546';
        } else if (status === 'Em Aberto') {
          statusLabel = 'Em Aberto';
          statusColor = '#FFC40D';
        } else {
          statusLabel = status;
          statusColor = '#999';
        }

        return (
          <span
            style={{
              backgroundColor: statusColor,
              color: 'white',
              /* padding: '3px 12px', */
              borderRadius: '5px',
              width: '100px',
            }}
          >
            {statusLabel}
          </span>
        );
      },
    },
  ];
  return {
    configColumnsDetails,
    configColumnsFinancialRegister,
    configColumnsFinancialMovements,
    isOpenEditorConciliationModal,
    setIsOpenEditorConciliationModal,
    deselectAllCheckboxes,
    configColumnsConciliation,
  };
};
