import config from '../config'
import api from '../services/api'

const getSimplifiedCustomerHistory = (customerId, queryParams) => {
  return api.get(config.endpoint + `customer-history/${customerId}/simplified`, {
    params: queryParams
  })
}

const getCompleteCustomerHistory = (customerId, queryParams) => {
  return api.get(config.endpoint + `customer-history/${customerId}/complete`, {
    params: queryParams
  })
}

const getCompleteCustomerHistoryBySaleId = saleId => {
  return api.get(config.endpoint + `customer-history/${saleId}/sale/complete`)
}

export default {
  getSimplifiedCustomerHistory,
  getCompleteCustomerHistory,
  getCompleteCustomerHistoryBySaleId
}