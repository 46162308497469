import Input from 'client/components/Input/Input';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import customersRepository from 'repositories/Customers';

export const CRMBonusPDVInputCPFModal = ({
  isOpen,
  handleClose,
  handleBack,
  customerCPF,
  handleContinueWithoutBonus,
  handleProceedPDVInputCPF,
}) => {
  const [cpf, setCPF] = useState('');

  useEffect(() => {
    if (
      customerCPF !== '00000000000' &&
      customerCPF !== null &&
      customerCPF !== ''
    ) {
      const formattedCPF = cpfMask(customerCPF);
      setCPF(formattedCPF);
    }
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    const formattedCPF = cpfMask(value);
    setCPF(formattedCPF);
  };

  const isCPFComplete = cpf.length === 14;

  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Insira o CPF:</span>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <input
            type="text"
            value={cpf}
            onChange={handleChange}
            placeholder="999.999.99-99"
            style={{ width: '150px', padding: '10px' }}
          />
          <br />
          <span style={{ color: '#d43f3a', fontSize: '12px' }}>
            Caso o cliente mude de ideia após esse passo, será necessário voltar
            e iniciar o fechamento novamente
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-150px" onClick={handleBack}>
            Voltar
          </button>
          <button
            className="button button-green w-150px"
            disabled={!isCPFComplete}
            onClick={() => handleProceedPDVInputCPF(cpf)}
          >
            Confirmar
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
