import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';

export const formatToLettersOnly = (value) =>
  String(value).replace(/[^a-zA-Z\sçãÃõÕáÁéÉíÍóÓúÚ]/gu, '');

export const isLegalOrPhysicalPerson = (cpfOrCnpj) => {
  if (onlyNumbers(cpfOrCnpj || '').length > 11) {
    return 'Juridica';
  } else {
    return 'Fisica';
  }
};
