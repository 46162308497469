import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router'
import { change } from 'redux-form'
import { useDispatch } from 'react-redux'

import prismaboxRepository from 'repositories/PrismaBox'
import { useAuth } from '../../../../contexts/auth'
import constants from '../../../../utils/constants'

import AlertModal from '../../../../components/AlertModal'
import FormPrismabox from './FormPrismabox'

const BundleFormPrismaBox = ({
  pristine,
  history,
  prismaboxId,
  handleModal,
  onCancel,
  modal,
}) => {
  const [loading, setLoading] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const dispatch = useDispatch()

  const { companyId } = useAuth()

  function cancelModal() {
    if (!!pristine) {
      return history.push(constants.ROUTES.BOX_PRISMA)
    } else {
      return setIsCancelModalOpen(true)
    }
  }

  useEffect(() => {
    if (!!prismaboxId) {
      loadPrismabox()
    }
  }, [])

  async function loadPrismabox() {
    setLoading(true)

    try {
      const prismabox = await prismaboxRepository.show(prismaboxId)

      dispatch([
        change('prismabox', 'descr', prismabox.data.description),
        change('prismabox', 'observation', prismabox.data.observation),
        change('prismabox', 'status', prismabox.data.status),
      ])
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os dados do Prisma/Box. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(values) {
    const { descr, observation, status } = values

    if (!descr) {
      return toastr.warning(
        'Preencha todos os campos obrigatórios para salvar o seu cadastro'
      )
    }

    if (!prismaboxId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { descr, status, observation } = values
    setLoading(true)

    try {
      const descriptionExist = await prismaboxRepository.index({
        companyId: companyId || 0,
      })
      const exist = await descriptionExist.data.filter(
        (pb) => pb.description === descr
      )
      if (exist.length !== 0) {
        return toastr.error(
          'Não foi possível fazer esse cadastro, pois já existe um box/prisma com essa descrição'
        )
      }

      const prismBox = await prismaboxRepository.store({
        description: descr,
        status,
        companyId,
        observation,
      })
      if (modal) {
        return handleModal(prismBox.data.prismaBox.id)
      }

      toastr.success('Sucesso', 'Prisma/Box atualizado com sucesso')
      history.push(constants.ROUTES.BOX_PRISMA)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar o Prisma/Box. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { descr, status, observation } = values
    setLoading(true)

    try {
      const { data: prismaboxes } = await prismaboxRepository.index({
        companyId,
      })
      prismaboxes.map((pb) => {
        if (pb.description === descr && pb.id != prismaboxId) {
          throw new Error('duplicação de registro')
        }
      })

      await prismaboxRepository.update(prismaboxId, {
        description: descr,
        status,
        companyId,
        observation,
      })

      toastr.success('Sucesso', `Prisma/Box atualizado com sucesso`)
      history.push(constants.ROUTES.BOX_PRISMA)
    } catch (err) {
      if (err.message === 'duplicação de registro') {
        toastr.error(
          'Não foi possível fazer esse cadastro, pois já existe um box/prisma com essa descrição'
        )
      } else {
        toastr.warning(
          'Ocorreu um erro ao salvar o Prisma/Box. Por favor, tente novamente'
        )
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FormPrismabox
        initialValues={{
          status: true,
          descr: '',
          observation: '',
        }}
        onSubmit={handleSubmit}
        loading={loading}
        prismaboxId={prismaboxId}
        onCancel={cancelModal}
      />
      {isCancelModalOpen && (
        <AlertModal
          show={isCancelModalOpen}
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          message={
            !prismaboxId
              ? 'Deseja cancelar o cadastro do box/prisma? Todos os campos preenchidos não serão salvos e o cadastro poderá ser realizado posteriormente'
              : 'Deseja cancelar a edição do box/prisma? Todos os campos alterados não serão salvos e a alteração poderá ser realizada posteriormente'
          }
          onSubmit={() =>
            onCancel ? onCancel() : history.push(constants.ROUTES.BOX_PRISMA)
          }
        />
      )}
    </>
  )
}

export default withRouter(BundleFormPrismaBox)
