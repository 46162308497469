import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { change } from 'redux-form'

import Button from 'client/components/CustomButton/CustomButton.jsx'

import '../../styles.css'

const ReplicatePaymentModal = ({ paymentForms = [], show, onCancel }) => {
  const [paymentFormId, setPaymentFormId] = useState(null)
  const [replicateOption, setReplicateOption] = useState('withoutPaymentOnly')

  const { selectedTitles: bills } = useSelector(
    (state) => state.form.titleQuickDischarge.values
  )

  const dispatch = useDispatch()

  const replicatePaymentForm = () => {
    const replicateFunction =
      replicateOption === 'all'
        ? replicatePaymentFormToAllBills
        : replicatePaymentFormToBillsWithoutOne

    const replicatedPaymentBills = bills.map(replicateFunction)

    dispatch(
      change('titleQuickDischarge', 'selectedTitles', replicatedPaymentBills)
    )
    onCancel()
  }

  const replicatePaymentFormToAllBills = (bill) => ({
    ...bill,
    paymentFormId,
  })

  const replicatePaymentFormToBillsWithoutOne = (bill) => {
    if (!bill.paymentFormId) return { ...bill, paymentFormId }
    return { ...bill }
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main className='replicate-payment-modal'>
          <fieldset>
            <span>Selecione a forma de pagamento</span>
            <select
              className='form-control foco-input'
              value={paymentFormId}
              onChange={(e) => setPaymentFormId(e.target.value)}
            >
              <option value=''>Selecione</option>
              {paymentForms.map((paymentForm) => (
                <option value={paymentForm.id} key={paymentForm.id}>
                  {paymentForm.Desciption}
                </option>
              ))}
            </select>
          </fieldset>
          <fieldset>
            <span>Replicar as formas para:</span>
            <select
              className='form-control foco-input'
              value={replicateOption}
              onChange={(e) => setReplicateOption(e.target.value)}
            >
              <option value='withoutPaymentOnly'>
                Títulos sem forma de pagamento
              </option>
              <option value='all'>Todos os títulos</option>
            </select>
          </fieldset>
        </main>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='danger' onClick={onCancel} fill>
          Voltar
        </Button>
        <Button
          bsStyle='info'
          fill
          disabled={!paymentFormId}
          onClick={replicatePaymentForm}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReplicatePaymentModal
