import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { format, isValid, subDays } from 'date-fns';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import ExcelHelperCustomerApproach from './ExcelHelperCustomerApproach';
import TableCustomerApproach from './TableCustomerApproach';
import CustomerApproachReportRepository from 'v2/repositories/CustomerApproachReportRepository';
import ApproachRepository from 'v2/repositories/ApproachRepository';
import GraphicCustomerApproach from './GraphicCustomerApproach';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const HomeCustomerApproachReport = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isReportReady, setIsReportReady] = useState(false);
  const [initialDate, setInitialDate] = useState(
    format(subDays(new Date(), 30), 'yyyy-MM-dd')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [reportData, setReportData] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedApproach, setSelectedApproach] = useState('');
  const [approachs, setApproachs] = useState([]);
  const [approachsChartData, setApproachsChartData] = useState([]);
  const [valuesToExport, setValuesToExport] = useState([]);

  useEffect(() => {
    loadApproachs();
  }, [companyId]);

  useEffect(() => {
    if (validations()) {
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [initialDate, finalDate, companyId]);

  useEffect(() => {
    if (isReportReady) {
      handleProcess();
    }
  }, [currentPage, pageLimit]);

  async function loadApproachs() {
    setLoading(true);
    setIsReportReady(false);
    try {
      const approachs = await ApproachRepository.listAllApproachActive(
        companyId
      );

      const serializedApproach = approachs.map((approach) => ({
        label: approach.description,
        value: approach.id,
      }));

      serializedApproach.unshift({
        label: 'Todas',
        value: '',
      });

      setApproachs(serializedApproach);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as abordagens. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsReportReady(true);
    }
  }

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate)) ||
      !companyId
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const data =
        await CustomerApproachReportRepository.generateCustomerApproachReport({
          companyId,
          initialDate,
          finalDate,
          approach: selectedApproach,
          limit: pageLimit,
          page: currentPage + 1,
        });

      if (data.report.length > 0) {
        setReportData(data.report);
        setTotalPages(Math.ceil(data.count / pageLimit));
        setApproachsChartData(
          data.totals.map((item) => {
            return [
              item.approach,
              Number(item.percentage),
              Number(item.customersQuantity),
            ];
          })
        );

        const serializedValuesToExport = data.report.map((item) => ({
          ...item,
          createdAt: format(
            getDateOnlyFromDate(new Date(item.createdAt)),
            'dd/MM/yyyy'
          ),
        }));

        setValuesToExport(serializedValuesToExport);
      } else {
        return toastr.warning(
          'Nenhum cadastro foi localizado com essa abordagem.',
          'Nenhum cliente foi cadastrado nesse período. Verifique e tente.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Abordagem:</InputLabel>
            <select
              className="form-control foco-input"
              name="selectedApproach"
              value={selectedApproach}
              onChange={(e) => setSelectedApproach(e.target.value)}
            >
              {approachs.map((approach) => {
                return (
                  <option key={approach.value} value={approach.value}>
                    {approach.label}
                  </option>
                );
              })}
            </select>
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="btn btn-sucesso"
            style={{ height: '35px' }}
            onClick={handleSubmit}
            disabled={loading || !isReportReady}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Gerar Relatório
          </button>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <ExcelHelperCustomerApproach
            isReportReady={isReportReady}
            loading={loading}
            reportData={valuesToExport}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: '40px',
        }}
      >
        <div style={{ padding: 15 }}>
          <TableCustomerApproach
            reportData={reportData}
            loading={loading}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <GraphicCustomerApproach data={approachsChartData} />
        </div>
      </div>
    </div>
  );
};

export default HomeCustomerApproachReport;
