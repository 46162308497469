import { AsyncPaginate } from 'react-select-async-paginate';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  height: ${(props) => (props.height ? `${props.height}` : '35px')};
  display: flex;
  align-items: flex-start;
`;

export const InputReactSelectPaginate = styled(AsyncPaginate)`
  box-sizing: border-box;

  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};

  &:has(input:focus) {
    outline: 1px solid ${(props) => props.theme.colors.branding1000};
  }
`;
