import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  ${(props) =>
    props.height &&
    `
    height: ${props.height};
  `}

  display: flex;
  flex-direction: column;
`;

export const InputTextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};
  padding: 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.branding1000} !important;
  }
`;
