import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';

import AlertModal from 'components/AlertModal/AlertModal';
import SearchIcon from 'components/SelectIcons/SearchIcon';
import Button from 'client/components/CustomButton/CustomButton.jsx';

import NFSePatternCitiesRepository from 'repositories/NFSePatternCities';
import NewCityModal from '../../CitiesList/components/NewCityModal';

export default function NFSePatternCitiesModalForm({
  patternId,
  patternCityId,
  handleClose,
  handleReload,
  patternAllowCancel,
  patternConcatAdditionalInfo,
}) {
  const [citiesOptions, setCitiesOptions] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [allowHomologation, setAllowHomologation] = useState(false);
  const [allowCancel, setAllowCancel] = useState(patternAllowCancel);
  const [concatAdditionalInformation, setConcatAdditionalInformation] =
    useState(patternConcatAdditionalInfo);

  const [loading, setLoading] = useState(false);

  const [
    isOverwriteConfirmationModalOpen,
    setIsOverwriteConfirmationModalOpen,
  ] = useState(false);

  const [isNewCityModalOpen, setIsNewCityModalOpen] = useState(false);

  function handleSelectPatternByCity() {
    setLoading(true);
    try {
      const cityToSelect = citiesOptions.find(
        (city) => parseInt(city.value.id) === parseInt(patternCityId)
      );

      setSelectedCity(cityToSelect);
      setAllowHomologation(cityToSelect?.value?.allowHomologation);
      setAllowCancel(cityToSelect?.value?.allowCancel);
      setConcatAdditionalInformation(
        cityToSelect?.value?.concatAdditionalInformation
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleSelectCity(city) {
    setCitiesOptions([
      ...citiesOptions,
      {
        value: city,
        label: `${city.IBGECityCode} - ${city.city}`,
      },
    ]);

    setSelectedCity({
      value: city,
      label: `${city.IBGECityCode} - ${city.city}`,
    });
  }

  async function handleLoadCitiesOptions() {
    setLoading(true);
    try {
      const patternCities = await NFSePatternCitiesRepository.index({
        limit: 1000000000,
        page: 1,
      });
      setCitiesOptions(
        patternCities.data.map((item) => ({
          value: item,
          label: `${item.IBGECityCode} - ${item.city}`,
        }))
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar as opções de cidade. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  function validateSubmit() {
    try {
      if (
        selectedCity.value.NFSPatternId &&
        parseInt(selectedCity.value.NFSPatternId) !== parseInt(patternId)
      ) {
        return setIsOverwriteConfirmationModalOpen(true);
      }

      handleSubmit();
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro na operação. Tente novamente!'
      );
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await NFSePatternCitiesRepository.update(patternId, {
        idCity: selectedCity.value.id,
        city: selectedCity.value.city,
        IBGECityCode: selectedCity.value.IBGECityCode,
        UF: selectedCity.value.UF,
        allowHomologation,
        allowCancel,
        devURL: selectedCity.value.devURL,
        useCertificate: selectedCity.value.useCertificate,
        concatAdditionalInformation,
      });

      toastr.success(
        'Cidade vinculada com sucesso',
        'A cidade informada foi vinculada com o padrão e poderá emitir nota por ele'
      );

      handleReload();
      handleClose();
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro na operação. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleLoadCitiesOptions();
  }, []);

  useEffect(() => {
    if (citiesOptions.length > 0 && patternCityId) {
      handleSelectPatternByCity();
    }
  }, [citiesOptions, patternCityId]);

  return (
    <Modal show={true} onHide={null} dialogClassName={`modal-60w`}>
      <Modal.Header>
        <Modal.Title>
          <strong>
            {patternCityId ? 'Editar' : 'Adicionar'} Cidade ao Padrão
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="admin__form-pattern-cities">
          <div className="admin__form-pattern-cities__row-1">
            <div>
              {!patternCityId && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <button
                    type="button"
                    onClick={() => setIsNewCityModalOpen(true)}
                    className="button__hyperlink-style"
                  >
                    Não encontrei a cidade
                  </button>
                </div>
              )}
              <Select
                isSearchable
                options={citiesOptions}
                placeholder={
                  loading
                    ? 'Carregando opções...'
                    : 'Pesquisa por Código da Cidade ou Nome'
                }
                onChange={(e) => {
                  setSelectedCity(e);
                }}
                value={selectedCity}
                loading
                noOptionsMessage={() => 'Nenhuma cidade encontrada'}
                components={{
                  DropdownIndicator: SearchIcon,
                }}
                isDisabled={loading || patternCityId}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '40px',
                    minHeight: '40px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '40px',
                    padding: '0 0 0 10px',
                    marginTop: '-4px',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    marginTop: '-4px',
                  }),
                }}
              />
            </div>
          </div>
          <div className="admin__form-pattern-cities__row-2">
            <div>
              <label htmlFor="ibgeCode">Código IBGE:</label>
              <input
                id="ibgeCode"
                type="text"
                value={selectedCity?.value?.IBGECityCode}
                className="form-control"
                disabled
              />
            </div>
            <div>
              <label htmlFor="city">Nome:</label>
              <input
                id="city"
                type="text"
                value={selectedCity?.value?.city}
                className="form-control"
                disabled
              />
            </div>
            <div>
              <label htmlFor="uf">UF:</label>
              <input
                id="uf"
                type="text"
                value={selectedCity?.value?.UF}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="admin__form-pattern-cities__row-3">
            <div>
              <label htmlFor="allowHomologation">Permite NFS Homolog.?</label>
              <select
                name="allowHomologation"
                className="form-control foco-input"
                id="allowHomologation"
                value={allowHomologation}
                disabled={loading}
                onChange={(e) => {
                  setAllowHomologation(
                    e.target.value === 'true' ? true : false
                  );
                }}
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>
            <div>
              <label htmlFor="allowHomologation">Permite Cancelar NFS-e?</label>
              <select
                name="allowCancel"
                className="form-control foco-input"
                id="allowCancel"
                value={allowCancel}
                disabled={loading}
                onChange={(e) =>
                  setAllowCancel(e.target.value === 'true' ? true : false)
                }
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>
            <div>
              <label htmlFor="concatAdditionalInformation">
                Tipo de Texto Adicional:
              </label>
              <select
                name="concatAdditionalInformation"
                className="form-control foco-input"
                id="concatAdditionalInformation"
                value={concatAdditionalInformation}
                disabled={loading}
                onChange={(e) => {
                  setConcatAdditionalInformation(
                    e.target.value === 'true' ? true : false
                  );
                }}
              >
                <option value={false}>Campo Próprio</option>
                <option value={true}>Concatenado</option>
              </select>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="danger"
          name="Cancelar"
          onClick={handleClose}
          disabled={loading}
          fill
        >
          <span className={loading && 'fa fa-spinner fa-pulse fa-1x'} />
          Cancelar
        </Button>

        <Button
          bsStyle="info"
          name="Salvar"
          onClick={validateSubmit}
          disabled={loading}
          fill
        >
          <span className={loading && 'fa fa-spinner fa-pulse fa-1x'} />
          Salvar
        </Button>
      </Modal.Footer>

      {isOverwriteConfirmationModalOpen && (
        <AlertModal
          title="OS Digital"
          show={isOverwriteConfirmationModalOpen}
          message={
            <>
              <span>A cidade em questão já está vinculada em outro padrão</span>
              <br />
              <br />
              <span>
                <strong>Cidade/UF:</strong> {selectedCity.value.city}/
                {selectedCity.value.UF}
              </span>
              <br />
              <span>
                <strong>Padrão:</strong>{' '}
                {selectedCity.value.NFSPatterns.pattern}
              </span>
              <br />
              <span>
                <strong>Status padrão:</strong>{' '}
                {selectedCity.value.NFSPatterns.isHomologated
                  ? 'Homologado'
                  : 'Não Homologado'}
              </span>
              <br />
              <br />
              <span>Deseja mover a cidade em questão para esse padrão?</span>
            </>
          }
          onCancel={() => setIsOverwriteConfirmationModalOpen(false)}
          onSubmit={() => {
            handleSubmit();
            setIsOverwriteConfirmationModalOpen(false);
          }}
          onHide={null}
        />
      )}

      {isNewCityModalOpen && (
        <NewCityModal
          onCancel={() => setIsNewCityModalOpen(false)}
          patternToRegisterId={patternId}
          handleSelectCity={handleSelectCity}
          loadCities={() => null}
        />
      )}
    </Modal>
  );
}
