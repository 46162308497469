import React, { useState, useEffect, memo } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValid, isBefore } from 'date-fns';
import { encrypt, currency } from 'client/components/ToNormalize/ToNormalize';
import useDebounce from 'hooks/useDebounce';
import { Col } from 'react-bootstrap';
import productsRepository from '../../../../../repositories/Products';
import productHistoryRepository from '../../../../../repositories/ProductHistory';

const ProductHistory = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [productHistory, setProductHistory] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [xlsArchiveName, setXlsArchiveName] = useState('');
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);
  const { companyId } = useAuth();

  useEffect(() => {
    if (!!companyId) {
      loadProducts();
    }
  }, [companyId, queryDebounced]);

  useEffect(() => {
    if (!!productInfo) handleArchiveName();
  }, [productInfo]);

  useEffect(() => {
    setProductHistory([]);
  }, [selectedProduct, initialDate, finalDate]);

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const { data } = await productHistoryRepository.getProductHistoryReport(
        selectedProduct.id,
        { initialDate, finalDate, companyId }
      );
      if (!data.history.length > 0)
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );
      setProductHistory(data.history);
      setProductInfo(data.product);
    } catch (err) {
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar o histórico. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSearch = async (value) => {
    let productCode = value.split(' / ')[0];
    setQuery(value);
    debouncedSaveQuery(value);
    getProduct(productCode);
  };

  const getProduct = (productCode) => {
    const selectedProduct = products.find((p) => p.Code == productCode);

    if (!!selectedProduct) {
      setSelectedProduct(selectedProduct);
    }
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Selecione um período válido para gerar o relatório.');
      return false;
    }

    if (!selectedProduct) {
      toastr.warning('Selecione o produto para gerar o relatório.');
      return false;
    }

    return true;
  };

  const loadProducts = async () => {
    setLoading(true);
    try {
      const resp = await productsRepository.getAllPaginatedToAddItem({
        Company_id: companyId,
        page: 1,
        limit: 20,
        query: queryDebounced,
        IsActive: '1',
      });

      setProducts(resp.data);
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao carregar os produtos', 'Tente novamente!');
    } finally {
      setLoading(false);
    }
  };

  const formatQueryProduct = ({
    Code,
    Description,
    Bar_Code,
    Manufacturer_Ref,
  }) =>
    `${Code} / ${Description} /${!!Bar_Code ? `${Bar_Code}` : ''} /${
      !!Manufacturer_Ref ? `${Manufacturer_Ref}` : ''
    }`;

  const handlePrint = () => {
    const productIdHash = encrypt(selectedProduct.id, '@OS-dig:productId');
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    window.open(
      window.location.origin +
        `/report/product-history?productId=${productIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyIdHash}`,
      '_blank'
    );
  };

  const handleArchiveName = () => {
    return setXlsArchiveName(
      `Histórico do Produto-${productInfo.code}-${productInfo.description}-${initialDate}-${finalDate}`
    );
  };

  return (
    <div>
      <Col style={{ display: 'flex', marginTop: '30px', height: '150px' }}>
        <div style={{ display: 'column', width: '500px' }}>
          <label>
            <strong>
              Produto
              <div style={{ padding: '10px 0' }} id="review-search">
                <i id="iconepesquisar" className="fa fa-search"></i>
                <input
                  style={{
                    paddingLeft: '30px',
                    width: '480px',
                    fontWeight: 400,
                  }}
                  className="form-control foco-input"
                  list="search-products"
                  type="text"
                  component="input"
                  id="query"
                  autoFocus
                  placeholder="Pesquisa por Código Interno, Descrição ou Ref. Fabricante"
                  onChange={(e) => handleChangeSearch(e.target.value)}
                  value={query}
                />
              </div>
              <datalist id="search-products">
                {products?.map(
                  ({
                    id,
                    Code,
                    Description,
                    Bar_Code,
                    IsActive,
                    Manufacturer_Ref,
                  }) => {
                    if (IsActive) {
                      return (
                        <option
                          key={id}
                          value={formatQueryProduct({
                            Code,
                            Description,
                            Bar_Code,
                            Manufacturer_Ref,
                          })}
                        />
                      );
                    }
                  }
                )}
              </datalist>
            </strong>
          </label>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <label>
            <strong>Data Inicial:</strong>
          </label>
          <input
            type="date"
            className="form-control foco-input"
            v
            alue={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{
              width: '160px',
            }}
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <label>
            <strong>Data Final:</strong>
          </label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{
              width: '160px',
            }}
          />
        </div>

        {!!!productHistory.length ? (
          <section style={{ padding: '38px 0px 0px 10px' }}>
            <button
              className="btn btn-sucesso"
              onClick={handleSubmit}
              disabled={loading}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </section>
        ) : (
          <>
            <section style={{ padding: '38px 0px 0px 10px' }}>
              <button className="btn btn-sucesso" onClick={handlePrint}>
                Imprimir
              </button>
            </section>
            <section style={{ padding: '38px 0px 0px 10px' }}>
              <DownloadXlsButton
                archiveName={xlsArchiveName}
                data={productHistory}
                className="btn btn-export"
                columns={[
                  {
                    name: 'Data/Hora',
                    acessor: 'dateForXls',
                  },
                  {
                    name: 'Tipo',
                    acessor: 'typeAndStatusForXls',
                  },
                  {
                    name: 'Descrição',
                    acessor: 'description',
                  },
                  {
                    name: 'Cliente/Fornecedor',
                    acessor: 'name',
                  },
                  {
                    name: 'Nº',
                    acessor: 'code',
                  },
                  {
                    name: 'Quantidade',
                    acessor: 'quantityForXls',
                  },
                  {
                    name: 'R$ Movimentado',
                    acessor: 'amount',
                    Cell: (props) => currency(props.value),
                  },
                ]}
              >
                <FontAwesomeIcon
                  color="white"
                  icon={faCloudDownloadAlt}
                  size="lg"
                />
                <span style={{ padding: '0px 5px', marginLeft: 7 }}>
                  Exportar .xls
                </span>
              </DownloadXlsButton>
            </section>
          </>
        )}
      </Col>
    </div>
  );
};

export default memo(ProductHistory);
