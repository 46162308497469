import React from 'react'

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import Matera from './Matera'

const MateraHome = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Integrações', 'Conta Digital - Matera']}
        path={['home', null, null]}
      />
      <Matera />
    </>
  )
}

export default MateraHome
