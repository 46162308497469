import config from '../config'
import api from "../services/api"

const getAll = async () => {
  const response = await api.get(config.endpoint + 'printing-sheets');
  return response.data
}

export default {
  getAll
}