import config from '../config';
import api from '../services/api';

const show = async (Params) =>
  api.get(config.endpoint + `parts-application/${Params}`);

const create = (data) => {
  return api.post(config.endpoint + 'parts-application', data);
};

const deletePartApplication = async ({
  productId,
  vehicleId,
  partApplicationId,
}) => {
  return api.delete(
    config.endpoint +
      `parts-application/${productId}/${vehicleId}/${partApplicationId}`
  );
};

const deleteAllPartApplicationsByProduct = async (productId) => {
  return api.delete(config.endpoint + `parts-application/${productId}`);
};

const updatePartApplication = async ({
  productId,
  vehicleId,
  initialYear,
  finalYear,
}) => {
  return api.put(config.endpoint + `parts-application`, {
    productId,
    vehicleId,
    initialYear,
    finalYear,
  });
};

export default {
  show,
  create,
  deletePartApplication,
  deleteAllPartApplicationsByProduct,
  updatePartApplication,
};
