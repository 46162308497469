import React, { useState } from 'react'
import {
  faEdit,
  faPrint,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { INVOICE_STATUS_DESCRIPTION } from '../../../../../utils/constants'

import CancelInvoiceModal from 'client/views/Invoices/CancelSATModal'

const SATActions = ({ SAT, onChange, onEdit }) => {
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false)

  const isDisabled = SAT.status === INVOICE_STATUS_DESCRIPTION.DISABLED
  const isIssued = SAT.status === INVOICE_STATUS_DESCRIPTION.ISSUED

  function onSuccessCancelInvoiceModal() {
    setShowCancelInvoiceModal(false)
    onChange()
  }

  function handleOpenPDFDownloadPage() {
    const hash = `Vend=v723dha5tHSSpS83711${SAT.SATSale.id}5422hsGsdAQLk8423oPL31`
    const satDocumentLink = `${window.location.origin}/sheets/${hash}`

    window.open(satDocumentLink, 'blank')
  }

  if (isDisabled) return null

  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(SAT)}
        className='btn-acoes'
        style={{ color: '#000000' }}
      />
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handleOpenPDFDownloadPage}
            className='btn-acoes'
            style={{ color: '#444444' }}
          />
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => setShowCancelInvoiceModal(true)}
            className='btn-acoes'
            style={{ color: '#e61610' }}
          />
        </>
      )}

      <CancelInvoiceModal
        show={showCancelInvoiceModal}
        onCancel={() => setShowCancelInvoiceModal(false)}
        satId={SAT.id}
        reloadInvoices={onChange}
        onSuccess={onSuccessCancelInvoiceModal}
      />
    </div>
  )
}

export default SATActions
