import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeTaxByState from './TaxByState'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const TaxByState = () => {
    localStorage.setItem('selectedPath', 'client/taxes-by-state')
    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'Tributo por Estado']}
                        path={['home', null, null, null]} />
                    <HomeTaxByState />
                </Row>
            </Grid>
        </div>
    );
}
export default TaxByState