import React from 'react'

import './styles.css'

const FlashingDots = ({ active, vertical }) => {
  return (
    <div
      className={active ? 'dot-flashing' : 'dot-flashing-inactive'}
      style={vertical && { transform: 'rotate(90deg)' }}
    ></div>
  )
}

export default FlashingDots
