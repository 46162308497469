import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from 'contexts/auth';

import { useRouter } from 'v2/hooks/useRouter';
import { deleteCSTConversor } from 'v2/repositories/CSTConversorRepository';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir o cadastro? Ao excluir, quando
        importado uma nota com atribuição diferente de sua empresa, a conversão
        não será realizada automaticamente.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluído, não poderá ser recuperado.
      </span>
    </div>
  );
};

export function TableActions({ id, triggerFetch }) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteCSTConversor(id);
      toastr.success(
        'Exclusão realizada com sucesso',
        'O cadastro foi excluído e a próxima compra não realizará a conversão'
      );
      triggerFetch();
    } catch (err) {
      console.log(err);

      toastr.error(
        'Erro ao excluir',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  const handleDeleteButtonClick = async () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="flex center">
      <button
        title="Editar"
        className="button-icon"
        onClick={() =>
          navigateToClientPage('cst_conversor', 'form_edit_raw', id)
        }
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        title="Excluir"
        className="button-icon"
        onClick={handleDeleteButtonClick}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
