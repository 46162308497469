import SelectSearchInput from 'client/components/SelectSearchInput'
import React from 'react'
import '../styles.css'

const NewRequisitionHeader = ({
  formattedOptions,
  setValue,
  value,
  isLoading,
  register,
  hasId
}) => {
  return (
    <div className="new-parts-requisition__filter-page">
      <div className="new-parts-requisition__filter-row">
        <div className='new-parts-requisition__filter-input'>
          <SelectSearchInput
            formattedOptions={formattedOptions}
            setValue={(e) => setValue(e)}
            value={value}
            isLoading={isLoading}
            placeholder="Pesquisa por Venda/OS/PDV ou Cliente"
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
            disabled={hasId}
          />
        </div>

        <div style={{ width: '80px' }} className="new-parts-requisition__filter-column">
          <span>Venda:</span>
          <input
            className="form-control foco-input"
            type="text"
            disabled
            {...register('saleCode')}
          />
        </div>

        <div style={{ width: '300px' }} className="new-parts-requisition__filter-column">
          <span>Cliente:</span>
          <input
            className="form-control foco-input"
            type="text"
            disabled
            {...register('customerName')}
          />
        </div>

        <div style={{ width: '300px' }} className="new-parts-requisition__filter-column">
          <span>Atendente:</span>
          <input
            className="form-control foco-input"
            type="text"
            disabled
            {...register('employeeName')}
          />
        </div>
      </div>
    </div>
  )
}

export default NewRequisitionHeader
