import * as yup from 'yup';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Produtos/Serviços',
      'Defeitos',
      `${isEditing ? 'Editar' : 'Novo'} Defeito`,
    ],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.DEFECTS.BASE,
      null,
    ],
  };
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  details: yup.string().required(),
});
