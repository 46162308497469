import React from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusCancelUpdateCustomerModal = ({
  isOpen,
  handleClose,
  handleConfirmation,
}) => {
  return (
    <Modal.Dialog
      open={isOpen}
      style={{
        zIndex: 2000,
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          <strong>Deseja cancelar a atualização de cadastro?</strong>
          <br />
          Sem os dados atualizados, o cliente não receberá e não terá direito a
          bônus na próxima compra
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-70px" onClick={handleClose}>
            Não
          </button>
          <button
            className="button button-green w-70px"
            onClick={handleConfirmation}
          >
            Sim
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
