import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import ReactTable from 'react-table'

import CustomButton from '../CustomButton/CustomButton'

import { useAuth } from 'contexts/auth'

import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize'

import customerRepository from 'repositories/Customers'
import companyConfigRepository from 'repositories/CompanyConfig'

const FormDiv = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;

  & label {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  & select {
    width: 200px;
  }
`

const ProcessButton = ({ isLoading, onClick }) => {
  return (
    <button
      className="btn btn-sucesso"
      onClick={onClick}
      style={{
        padding: '7px 30px',
        margin: '0 10px',
        height: '35px',
      }}
    >
      <span className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} />
      Processar
    </button>
  )
}

const Table = ({
  data,
  pagesQuantity,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  isLoading,
}) => {
  return (
    <ReactTable
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: '12px',
        marginTop: '10px',
      }}
      data={data}
      columns={[
        {
          Header: 'CPF/CNPJ',
          accessor: 'CpfCnpj',
          headerClassName: 'text-center',
          width: 112,
          Cell: (props) => (
            <span>{props.value ? cpfOrCnpjMask(props.value) : ''}</span>
          ),
        },
        {
          Header: 'Nome/Razão Social',
          accessor: 'Name',
          headerClassName: 'text-center',
        },
        {
          Header: 'Telefone',
          accessor: 'phone',
          headerClassName: 'text-center',
          width: 112,
          Cell: (props) => (
            <span>{props.value ? phoneMask(props.value) : ''}</span>
          ),
        },
        {
          Header: 'Qtd. Vendas/OS',
          accessor: 'quantitySalesPeriod',
          headerClassName: 'text-center',
        },
      ]}
      showPagination
      defaultPageSize={10}
      showPaginationBottom
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      manual
      loading={isLoading}
      page={currentPage}
      pages={pagesQuantity}
      pageSize={pageSize}
      onPageChange={(page) => setCurrentPage(page)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Nenhuma venda encontrada"
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  )
}

export default function CustomersThatReturnedModal({ handleClose }) {
  const [allowLoad, setAllowLoad] = useState(false)

  const [customersThatReturned, setCustomersThatReturned] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [period, setPeriod] = useState('3')

  const [pageSize, setPageSize] = useState(10)
  const [pagesQuantity, setPagesQuantity] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)

  const { companyId } = useAuth()

  const setMonthRangeOfCustomerReturnCalc = async () => {
    try {
      const { monthRangeOfCustomerReturnCalc } =
        await companyConfigRepository.show(companyId)

      setPeriod(`${monthRangeOfCustomerReturnCalc}`)
    } catch (err) {
      throw err
    }
  }

  const handleLoadCustomersThatReturned = async () => {
    setIsLoading(true)

    try {
      const { data } =
        await customerRepository.getCustomersThatReturnedByPeriod({
          period,
          companyId,
          params: {
            page: currentPage + 1,
            limit: pageSize,
          },
        })

      setCustomersThatReturned(data.clients)
      setPagesQuantity(Math.ceil(data.totalClients / pageSize))
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Houve um problema ao carregar os dados dos clientes. Tente novamente!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (allowLoad) handleLoadCustomersThatReturned()
  }, [pageSize, currentPage])

  useEffect(() => {
    if (companyId) setMonthRangeOfCustomerReturnCalc()
  }, [companyId])

  useEffect(() => {
    setTimeout(() => {
      setAllowLoad(true)
    }, 1000)
  }, [])

  return (
    <Modal show onHide={handleClose} dialogClassName={`modal-50w`}>
      <Modal.Header>
        <Modal.Title>
          <strong>Clientes que Retornaram</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormDiv>
            <div>
              <label htmlFor="period">Período:</label>
              <select
                id="period"
                className="form-control"
                onChange={(e) => setPeriod(e.target.value)}
                value={period}
              >
                <option value="3">3 meses</option>
                <option value="6">6 meses</option>
                <option value="9">9 meses</option>
                <option value="12">12 meses</option>
              </select>
            </div>

            <ProcessButton
              isLoading={isLoading}
              onClick={handleLoadCustomersThatReturned}
            />
          </FormDiv>
        </div>
        <div>
          <Table
            data={customersThatReturned}
            pagesQuantity={pagesQuantity}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isLoading={isLoading}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton fill bsStyle="danger" onClick={handleClose}>
          Voltar
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}
