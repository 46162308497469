import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { format } from 'date-fns'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany'
import constants from '../../../utils/constants'

const NoSignatureDetails = ({ signature, cancelled, history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false)
  const companyId = localStorage.getItem('ID_EMPRESA')
  const signatureEndDate = !signature.End_Date
    ? ''
    : format(new Date(signature.End_Date), 'dd/MM/yyyy')

  return (
    <>
      <p id='bdPlanoBody'>
        Você pode utilizar {cancelled ? 'o sistema' : 'a versão de testes'} até{' '}
        <strong>{signatureEndDate}</strong>
      </p>
      {noCompanyModal && (
        <ModalAlertCompany
          show={noCompanyModal}
          onHide={() => setNoCompanyModal(false)}
        />
      )}
    </>
  )
}

export default withRouter(NoSignatureDetails)
