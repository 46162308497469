import api from '../services/api'
import config from '../config'

const getAllByCompany = (params) =>
  api.get(config.endpoint + 'loss-reason', { params })

const create = (values) => api.post(config.endpoint + 'loss-reason', values)

const update = (lossReasonId, values) =>
  api.put(config.endpoint + `loss-reason/${lossReasonId}`, values)

const getById = (lossReasonId) =>
  api.get(config.endpoint + `loss-reason/${lossReasonId}`)

export default {
  getAllByCompany,
  create,
  update,
  getById,
}
