import React, { useState, useCallback, memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusSquare,
  faImages,
  faEquals,
} from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'
import ImagesModal from './ImagesModal'
import SimilarityModal from './SimilarityModal'
import PartApplicationModal from './PartApplicationModal'

import { usePlanSignatureContext } from '../../../contexts/plan-signature'
import UnavailableFeatureModal from '../UnavailableFeatureModal'

export default function TableActions({ row, onInclude, isProductIncluded }) {
  const [isOpenFeatureNotAvailableModal, setIsOpenFeatureNotAvailableModal] =
    useState(false)
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false)
  const [isSimilarityModalOpen, setIsSimilarityModalOpen] = useState(false)
  const [isPartApplicationModalOpen, setIsPartApplicationModalOpen] =
    useState(false)

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext()
  const handleSimilarity = useCallback(
    (product) => {
      if (isPlanFree || isPlanStart || isPlanBasic)
        return setIsOpenFeatureNotAvailableModal(true)

      if (product.Similarities.length < 1)
        return toastr.warning(
          'Não foi cadastrada a similaridade para o produto.'
        )

      setIsSimilarityModalOpen(true)
    },
    [row]
  )

  const handleImages = useCallback(
    (product) => {
      if (isPlanFree || isPlanStart)
        return setIsOpenFeatureNotAvailableModal(true)

      if (product.ProductsPhotos.length === 0)
        return toastr.warning('O produto não tem foto para ser visualizada.')

      setIsImagesModalOpen(true)
    },
    [row]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        {row.original.Type !== 'Serviço' && row.original.Type !== 'Kit' && (
          <>
            <div>
              <FontAwesomeIcon
                icon={faEquals}
                style={{ color: '#444', cursor: 'pointer', userSelect: 'none' }}
                aria-hidden="false"
                onClick={() => handleSimilarity(row.original)}
                size="50px"
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faImages}
                style={{ color: '#444', cursor: 'pointer', userSelect: 'none' }}
                aria-hidden="false"
                onClick={() => handleImages(row.original)}
                size="12px"
              />
            </div>
          </>
        )}
        <div>
          <FontAwesomeIcon
            icon={faPlusSquare}
            style={{
              color: isProductIncluded ? 'grey' : 'green',
              cursor: isProductIncluded ? 'not-allowed' : 'pointer',
              userSelect: 'none',
            }}
            aria-hidden="false"
            onClick={() =>
              !isProductIncluded ? onInclude(row.original) : null
            }
            size="12px"
            title={isProductIncluded ? 'Produto já adicionado.' : ''}
          />
        </div>
      </div>

      <UnavailableFeatureModal
        show={isOpenFeatureNotAvailableModal}
        onHide={() => setIsOpenFeatureNotAvailableModal(false)}
      />

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={row.original.Description}
          productPhotos={row.original.ProductsPhotos}
        />
      )}
      {isSimilarityModalOpen && (
        <SimilarityModal
          onHide={() => setIsSimilarityModalOpen(false)}
          onCancel={() => setIsSimilarityModalOpen(false)}
          onInclude={onInclude}
          productDescription={row.original.Description}
          productSimilarities={row.original.Similarities}
        />
      )}
      {isPartApplicationModalOpen && (
        <PartApplicationModal
          onHide={() => setIsPartApplicationModalOpen(false)}
          onCancel={() => setIsPartApplicationModalOpen(false)}
          onInclude={onInclude}
          productDescription={row.original.Description}
          productPartApplication={row.original.PartApplications}
        />
      )}
    </>
  )
}
