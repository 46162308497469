import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { ConfirmationModalFooterActions } from './ConfirmationModalFooterActions';
import { useBankConciliation } from 'v2/views/client/BankConciliation/hooks/useBankConciliation';
import { BankConciliationButtonsActions } from '../BankConciliationButtonsActions';
import { useAuth } from 'contexts/auth';
import { useBankConciliationStore } from 'v2/store/bankConciliation';
import { useHistory } from 'react-router-dom';

export function BankConciliationDetailsFooterActions() {
  const { watch, getValues, reset, setValue } = useFormContext();
  const { userId, companyId } = useAuth()
  const isSelectedBank = watch('informationFile.bank');
  const isFile = watch('informationFile');
  const isViewing = watch('isViewing');
  const { LoadingComponent, setIsLoading, isLoading } = useLoading();

  const transactions = watch('informationFile.data.transactions');
  const bills = watch('selectedTransactionsConciliation');
  const selectedTransactions = watch('selectedTransactions');
  const history = useHistory()

  const {
    markAllAsConciliated,
    saveOrUpdateConciliation,
    defaultValues,
    removeVinculoTransaction,
  } = useBankConciliation(getValues, setValue);

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [modalType, setModalType] = React.useState(null);

  const { bankConciliation } = useBankConciliationStore((store) => {
    return {
      bankConciliation: store.bankConciliation,
    };
  });

  const cashierBankId = getValues('informationFile.data.isSelectedBank')


  const registerConciliation = async () => {
    setIsLoading(true);
    try {
      await saveOrUpdateConciliation(transactions, bills, cashierBankId, userId, companyId);
      reset(defaultValues);
      setIsLoading(false);
    }
    catch (error) {
      return error
    }
    finally {
      setIsLoading(false);
      history.push('/client/bank-conciliation')
    }
  };

  const isSaveButtonDisabled =
    bills === undefined ||
    bills?.length === 0 ||
    selectedTransactions === undefined ||
    selectedTransactions?.length === 0;

  const isConfirmationButtonDisabled =
    isSelectedBank === '' ||
    !isFile?.data?.isValid ||
    (!isFile?.file && isFile?.file === null);

  const handleOpenCancelModal = () => {
    setModalType('cancel');
    setIsOpenModal(true);
  };

  const handleOpenConfirmModal = () => {
    setModalType('confirm');
    setIsOpenModal(true);
  };

  return (
    <>
      <div className='flex end' style={{ padding: '12px 0px' }}>
        <BankConciliationButtonsActions
          onNoClick={() => {
            if (isViewing) return  history.push('/client/bank-conciliation');
            handleOpenCancelModal();
          }}
          onYesClick={() => {
            if (!markAllAsConciliated()) {
              setIsOpenModal(false);
              return;
            }
            handleOpenConfirmModal();
          }}
          noLabel={isViewing ? 'Voltar' : 'Cancelar'}
          yesLabel="Salvar"
          isYesDisabled={isSaveButtonDisabled || isViewing}
        />
      </div>
      <ConfirmationModalFooterActions
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        isLoading={isLoading}
        modalType={modalType}
        onConfirm={registerConciliation}
        isConfirmationButtonDisabled={isConfirmationButtonDisabled}
        reset={reset}
        LoadingComponent={LoadingComponent}
        removeVinculoTransaction={removeVinculoTransaction}
      />
    </>
  );
}
