import React, { useState, useEffect } from 'react'

import { Col } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import ReactTable from 'react-table'
import SearchInput from 'components/SearchInput'

import { useAuth } from 'contexts/auth'
import useFilters from '../../../hooks/useFilters'
import api from 'services/api'
import config from 'config'

export default function PartApplicationModal({
  productDescription = '',
  vehicles = [],
  onHide,
  onCancel,
}) {
  const [brands, setBrands] = useState([])
  const { queryFilter, typeFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter
  const { type, setType, filterByType } = typeFilter

  const { companyId } = useAuth()

  useEffect(() => {
    loadBrands()
  }, [])

  async function loadBrands() {
    try {
      const {
        data: { data: brands },
      } = await api.get(config.endpoint + '/tabelaFipe/getBrands')

      setBrands(brands)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscas as marcas. Por favor, tente novamente'
      )
    }
  }

  const handleFilter = (vehicle) => {
    const querySearch = [vehicle['Code_FIPE'], vehicle['Model']]
    return filterByQuery(querySearch) && filterByType('Brand', vehicle)
  }

  return (
    <Modal dialogClassName='modal-60w' show animation onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <strong>Busca de aplicação do {productDescription}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ padding: '0px', padding: '10px 0', gap: '10px' }}
          >
            <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: '3px' }}>
              <div style={{ width: '70%', padding: 0 }}>
                <SearchInput
                  placeholder='Pesquisar por Código Fipe ou Modelo'
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  style={{
                    fontSize: 12,
                    width: 350,
                  }}
                  containerStyle={{ margin: '0 10px' }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px',
                  marginRight: '10px',
                  marginTop: '3px',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Marca: </strong>
                </span>
                <select
                  className='form-control foco-input'
                  name='searchBrand'
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value={''}>Todas</option>
                  {brands.length
                    ? brands.map((brand, index) => (
                        <option value={brand.Brand} key={index}>
                          {brand.Brand}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
            </Col>
          </Col>
          <ReactTable
            style={{
              marginTop: 10,
              fontWeight: 'bold',
              textAlign: 'center',
              height: '427px',
            }}
            data={vehicles.filter(handleFilter)}
            columns={[
              {
                Header: 'Marca',
                accessor: 'Brand',
                width: 180,
              },
              {
                Header: 'Modelo',
                accessor: 'Model',
              },
              {
                Header: 'Código FIPE',
                accessor: 'Code_FIPE',
              },
            ]}
            defaultPageSize={10}
            showPagination
            sortable={false}
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[10, 20, 25, 50, 100]}
            previousText='Anterior'
            nextText='Próximo'
            loadingText='Carregando...'
            noDataText='Não há informação'
            pageText='Página'
            ofText='de'
            rowsText='linhas'
            manual
          />
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='danger' onClick={onCancel} fill>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
