import api from '../services/api'
import config from '../config'

const create = async (values) => {
  const response = await api.post(config.endpoint + 'settings-nfce', values)
  return response.data
}

const update = async (id, values) => {
  await api.put(config.endpoint + `settings-nfce/${id}`, values)
}

const getSatSoftwareDownloadLink = async (companyId) =>
  await api.get(config.endpoint + `settings-nfce/sat-software-downlod/${companyId}`)

const importCertificateEnotas = async (formData) =>
  await api.post(config.endpoint + `settings-nfce/import-certificate-enotas`, formData)

const settingsNCeRepository = {
  create,
  update,
  getSatSoftwareDownloadLink,
  importCertificateEnotas,
}

export default settingsNCeRepository
