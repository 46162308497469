import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons'

import useFilters from 'hooks/useFilters'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import SearchInput from 'components/SearchInput'
import DownloadXlsButton from 'components/DownloadXlsButton'
import Button from 'client/components/CustomButton/Button.jsx'
import stoneLinkRepository from 'repositories/StoneCompany'

import './styles.css'

const Stone = ({ history }) => {
  const [companyLinks, setCompanyLinks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadStoneCompanyLinks()
  }, [])

  const loadStoneCompanyLinks = async () => {
    setLoading(true)

    try {
      const { data: companyLinks } = await stoneLinkRepository.index()

      const serializedCompanyLinks = companyLinks.map((companyLink) => ({
        id: companyLink.id,
        createdAt: companyLink.createdAt,
        formatedDate: format(new Date(companyLink.createdAt), 'dd/MM/yyyy'),
        cpfCnpj: cpfOrCnpjMask(companyLink.stoneCompany.Cpf_Cnpj),
        companyName: companyLink.stoneCompany.Company_Name,
        tradingName: companyLink.stoneCompany.Trading_Name,
        integrationStatus: companyLink.integrationStatus
          ? 'Finalizado'
          : 'Não Finalizado',
      }))

      setCompanyLinks(serializedCompanyLinks)
    } catch (err) {
      return toastr.error(
        'Ocorreu um erro ao carregar os vínculos. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const { statusFilter, queryFilter, dateFilter } = useFilters()

  const { query, setQuery, filterByQuery } = queryFilter
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter
  const { status, setStatus, filterByStatus } = statusFilter

  function handleFilters(companyLink) {
    const querySearch = [companyLink?.cpfCnpj, companyLink?.companyName]

    return (
      filterByQuery(querySearch) &&
      filterByDate('createdAt', companyLink) &&
      filterByStatus('integrationStatus', companyLink)
    )
  }

  return (
    <main className="stone-link-wrapper">
      <section className="stone-link-row">
        <button
          className="btn btn-sucesso new-stone-link"
          onClick={() =>
            toastr.error(
              'Atenção',
              'A partir de 31/12/2022 o OS Digital não está mais homologado com a Stone, sendo assim, não é possível criar/ativar um novo vínculo'
            )
          }
        >
          + Novo Vínculo Stone
        </button>
        <div className="search-input-wrapper">
          <SearchInput
            placeholder="Pesquisa por CNPJ ou CPF, Razão Social ou Nome"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <DownloadXlsButton
          archiveName={`Integracoes-Stone
            -${format(new Date(initialDate || null), 'dd-MM-yyyy')}
            -${format(new Date(finalDate || null), 'dd-MM-yyyy')}`}
          data={companyLinks.filter(handleFilters)}
          className="btn btn-export export-stone-link"
          disabled={!companyLinks.length}
          columns={[
            {
              name: 'Data',
              acessor: 'formatedDate',
            },
            {
              name: 'CNPJ/CPF',
              acessor: 'cpfCnpj',
            },
            {
              name: 'Razão Social/Nome Completo',
              acessor: 'companyName',
            },
            {
              name: 'Nome Fantasia',
              acessor: 'tradingName',
            },
            {
              name: 'Status',
              acessor: 'integrationStatus',
            },
          ]}
        >
          <FontAwesomeIcon
            color="white"
            style={{ marginRight: '10px' }}
            icon={faCloudDownloadAlt}
          />
          Exportar .xls
        </DownloadXlsButton>
      </section>
      <section className="stone-link-row">
        <fieldset>
          <label>Data Inicial:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label>Data Final:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label style={{ width: '75px' }}>Status:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value="Finalizado">Finalizado</option>
            <option value="Não Finalizado">Não Finalizado</option>
          </select>
        </fieldset>
      </section>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={companyLinks.filter(handleFilters)}
        columns={[
          {
            Header: 'Data',
            accessor: 'formatedDate',
            width: 150,
          },
          {
            Header: 'CNPJ/CPF',
            accessor: 'cpfCnpj',
            width: 175,
          },
          {
            Header: 'Razão Social/Nome Completo',
            accessor: 'companyName',
            width: 275,
          },
          {
            Header: 'Nome Fantasia',
            accessor: 'tradingName',
            width: 275,
          },
          {
            Header: 'Status',
            accessor: 'integrationStatus',
            width: 150,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor:
                    props.value === 'Finalizado' ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 75,
            Cell: (props) => (
              <Link to={{ pathname: `stone-link#${props.value}` }}>
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </Link>
            ),
          },
          // {
          //   Header: 'Ações',
          //   accessor: 'id',
          //   headerClassName: 'text-left',
          //   width: 100,
          //   filterable: false,
          //   className: 'texto',
          //   Cell: (props) => (
          //     <Link to={{ pathname: `user#${props.value}` }}>
          //       <FontAwesomeIcon
          //         cursor='pointer'
          //         icon={faEdit}
          //         style={{ width: '1.5em', height: '1.5em' }}
          //       />
          //     </Link>
          //   ),
          // }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </main>
  )
}

export default withRouter(Stone)
