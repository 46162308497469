import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import './styles.css';

import SearchInput from 'components/SearchInput';
import Button from 'client/components/CustomButton/Button.jsx';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import bankBilletAccountRepository from 'repositories/BankBilletAccount';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { format, isAfter } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import { currency } from 'client/components/ToNormalize/ToNormalize';

export default function TicketIssuanceHome() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [query, setQuery] = useState('');
  const [status, setStatus] = useState();
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    loadHomologatedCompanies();
  }, [currentPage, queryDebounced, pageLimit, status, initialDate, finalDate]);

  async function loadHomologatedCompanies() {
    setLoading(true);
    try {
      if (initialDate && finalDate) {
        if (isAfter(new Date(initialDate), new Date(finalDate))) {
          return toastr.warning(
            'Datas inválidas',
            'A data inicial deve ser menor que a data final'
          );
        }
      }

      const companiesList = await bankBilletAccountRepository.index({
        limit: pageLimit,
        page: currentPage + 1,
        query: queryDebounced,
        status,
        initialDate,
        finalDate,
      });

      const serializedCompanies = companiesList.data.rows.map((company) => ({
        ...company,
        companyCpfCnpj: cpfOrCnpjMask(company.companyCpfCnpj),
        createdAt: format(new Date(company.createdAt), 'dd/MM/yyyy'),
        totalIssueds: company.totalIssueds
          ? currency(company.totalIssueds)
          : '',
        lastIssuedDate: company.lastIssuedDate
          ? format(new Date(company.lastIssuedDate), 'dd/MM/yyyy')
          : '',
        status: company.status === true ? 'Ativo' : 'Inativo',
      }));

      setCompanies(serializedCompanies);
      setTotalPages(Math.ceil(companiesList.data.count / pageLimit));
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <main className="container-wrapper">
      <section className="container-row">
        <button
          className="btn btn-sucesso new-account fontSize-12"
          onClick={() => history.push('bank-billet-account')}
          style={{
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          + Nova Carteira Bancária
        </button>
        <SearchInput
          placeholder="Pesquisa por CNPJ ou Razão Social"
          value={query}
          onChange={(e) => handleChangeQuery(e.target.value)}
          style={{ fontSize: '12px' }}
        />
        <DownloadXlsButton
          archiveName={`Carteira-de-cobrança-${format(
            initialDate ? new Date(initialDate) : new Date(),
            'dd-MM-yyyy'
          )}-${format(
            finalDate ? new Date(finalDate) : new Date(),
            'dd-MM-yyyy'
          )}`}
          data={companies}
          style={{
            height: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
          }}
          className="btn btn-export"
          disabled={!companies.length}
          columns={[
            {
              name: 'Data',
              acessor: 'createdAt',
            },
            {
              name: 'CNPJ',
              acessor: 'companyCpfCnpj',
            },
            {
              name: 'Razão Social',
              acessor: 'companyTradingName',
            },
            {
              name: 'Banco',
              acessor: 'bankName',
            },
            {
              name: 'ID Carteira',
              acessor: 'bankBilletId',
            },
            {
              name: 'Qtd. Emit.',
              acessor: 'quantityIssueds',
            },
            {
              name: 'Total Emit.',
              acessor: 'totalIssueds',
            },
            {
              name: 'Últ. Emit.',
              acessor: 'lastIssuedDate',
            },
            {
              name: 'Status',
              acessor: 'status',
            },
          ]}
        >
          <FontAwesomeIcon
            color="white"
            style={{ marginRight: '10px' }}
            icon={faCloudDownloadAlt}
          />
          Exportar .xls
        </DownloadXlsButton>
      </section>
      <section>
        <fieldset>
          <span className="typography__text-2">Data Inicial:</span>
          <input
            type="date"
            className="form-control foco-input fontSize-12"
            value={initialDate}
            max={finalDate || '9999-12-31'}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Data Final:</span>
          <input
            type="date"
            className="form-control foco-input fontSize-12"
            value={finalDate}
            min={initialDate}
            max={'9999-12-31'}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Status:</span>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="">Todos</option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </fieldset>
      </section>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={companies}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: Math.round((window.innerWidth - 55) * 0.07),
          },
          {
            Header: 'CNPJ',
            accessor: 'companyCpfCnpj',
            width: Math.round((window.innerWidth - 55) * 0.11),
          },
          {
            Header: 'Razão Social',
            accessor: 'companyTradingName',
            width: Math.round((window.innerWidth - 55) * 0.14),
          },
          {
            Header: 'Banco',
            accessor: 'bankName',
            width: Math.round((window.innerWidth - 55) * 0.14),
          },
          {
            Header: 'ID Carteira',
            accessor: 'bankBilletId',
            width: Math.round((window.innerWidth - 55) * 0.06),
          },
          {
            Header: 'Qtd. Emit.',
            accessor: 'quantityIssueds',
            width: Math.round((window.innerWidth - 55) * 0.065),
          },
          {
            Header: 'Total Emit.',
            accessor: 'totalIssueds',
            width: Math.round((window.innerWidth - 55) * 0.09),
          },
          {
            Header: 'Últ. Emit.',
            accessor: 'lastIssuedDate',
            width: Math.round((window.innerWidth - 55) * 0.07),
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: Math.round((window.innerWidth - 55) * 0.06),
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor:
                    props.value === 'Ativo' ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: (props) => (
              <Link
                to={{ pathname: `bank-billet-account#${props.value}` }}
                title="Editar Carteira Bancária"
              >
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </Link>
            ),
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        page={currentPage}
        onPageChange={(value) => setCurrentPage(value)}
        pages={totalPages}
        manual
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </main>
  );
}
