import config from "../config"
import api from "../services/api"

const create = async companyUser => {
  
  try {

    const response = await api.post(config.endpoint + 'companyUser', companyUser)
    if(!response.data.status) {
      throw new Error('Ocorreu um erro ao enviar o email. Por favor, tente novamente')
    }

  } catch(err) {
    throw err
  }
}

export default {
  create
}