import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  currency,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const HeaderTotalsSalesPerFamily = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Família</Text>
    </View>
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Status</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'center', justifyContent: 'center' }}>
      <Text>Quantidade</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowTotalsSalesPerFamily = ({ item }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{item.Family}</Text>
    </View>
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{item.SaleStatus}</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'center', justifyContent: 'center' }}>
      <Text style={styles.span}>{item.QuantitySale}</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(item.Amount)}</Text>
    </View>
  </View>
)

const QuotesHeader = ({ description }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      width: '70%'
    }}
  >
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemLabel label={'Família'} value={description} />
    </View>
  </View>
)

const ItemsHeaderQuotes = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Código</Text>
    </View>
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descrição</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Nº Venda</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Qtd.</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>R$ Venda</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Tot. Venda</Text>
    </View>
  </View>
)

const ItemRowQuotes = ({ item }) => {
  return (
    <View
      style={{ flexDirection: 'row', width: '100%' }}
      wrap={false}
    >
      <View style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        {item.options.map(element => (
          <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <View
              style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{element.CodeService}</Text>
            </View>
            <View
              style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{element.Description}</Text>
            </View>
            <View
              style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{item.label}</Text>
            </View>
            <View
              style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{element.QuantitySale}</Text>
            </View>
            <View
              style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{currency(element.ValueSaleService)}</Text>
            </View>
            <View
              style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
            >
              <Text style={styles.span}>{currency(element.QuantitySale * element.ValueSaleService)}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

const TotalsRowQuotes = ({ totalQuantity, totalSaleService, totalSale }) => {
  return (
    <>
      <View
        style={{ flexDirection: 'row', height: 12, width: '100%' }}
        wrap={false}
      >
        <View
          style={{ width: '21%', textAlign: 'right', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}></Text>
        </View>
        <View
          style={{ width: '14%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}>Total</Text>
        </View>
        <View
          style={{ width: '10%', textAlign: 'right', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}></Text>
        </View>
        <View
          style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}>{totalQuantity}</Text>
        </View>
        <View
          style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}>{currency(totalSaleService)}</Text>
        </View>
        <View
          style={{ width: '11%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={{ ...styles.span, fontWeight: 'bold' }}>{currency(totalSale)}</Text>
        </View>
      </View>
      <View style={styles.space} />
    </>
  )
}

export const SalesPerFamilyServicePDF = ({
  result = [],
  initialDate,
  finalDate,
  reportType,
}) => {
  const groupedMap = result.services.reduce((map, { CodeSale, ...option }) => {
    if (!map.has(CodeSale)) {
      map.set(CodeSale, [])
    }
    map.get(CodeSale).push(option)
    return map
  }, new Map())

  const groupedSales = Array.from(groupedMap, ([label, options]) => ({
    label,
    options
  }))

  return (
    <Document
      title={`impresso-vendas-por-família-servicos-${format(new Date(), 'dd/MM/yyyy')}`}
      subject='sales-per-family-service'
    >
      <Page style={styles.page} orientation="landscape">
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Vendas por Família</Text>
          <View style={{ display: 'flex', width: '28%', justifyContent: 'space-between', flexDirection: 'row' }}>
            <ItemLabel label='Data Inicio' value={initialDate} />
            <ItemLabel label='Data Fim' value={finalDate} />
          </View>
        </View>
        <View style={[styles.line, { marginTop: 10 }]} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 14,
            marginTop: 1
          }}
        >
          Totais por Família/Status
        </Text>
        <View style={styles.line} />
        <HeaderTotalsSalesPerFamily reportType={reportType} />
        <View style={styles.line} />
        {result.totals.map(item => {
          return (
            <div key={item.id}>
              <ItemRowTotalsSalesPerFamily
                item={item}
                reportType={reportType}
              />
            </div>
          )
        })}
        {
          reportType === 'analytical' ?
            <>
              <View style={[styles.line]} />
              <Text
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Listagem de OS por Família
              </Text>
              <View style={styles.line} />
              <QuotesHeader
                description={result.totals.reduce((prevValue, currentValue) => currentValue.Family, '')}
              />
              <View style={styles.line} />
              <ItemsHeaderQuotes />
              <View style={styles.line} />
            </>
            :
            <View></View>
        }
        {
          groupedSales.map((item, index) => {
            const totalQuantity = item.options.reduce((prevValue, currentValue) => prevValue + currentValue.QuantitySale, 0)
            const totalValueCost = item.options.reduce((prevValue, currentValue) => prevValue + currentValue.ValueCost, 0)
            const totalSaleService = item.options.reduce((prevValue, currentValue) => prevValue + currentValue.ValueSaleService, 0)
            const totalSale = item.options.reduce((prevValue, currentValue) => prevValue + (currentValue.ValueSaleService * currentValue.QuantitySale), 0)
            const totalMarginSale = item.options.reduce((prevValue, currentValue) => prevValue + currentValue.MarginValueProfit, 0)

            return (
              <div key={index}>
                {
                  reportType === 'analytical' ?
                    <>
                      <ItemRowQuotes item={item} />
                      <TotalsRowQuotes totalQuantity={totalQuantity} totalValueCost={totalValueCost} totalSaleService={totalSaleService} totalSale={totalSale} totalMarginSale={totalMarginSale} />
                    </>
                    :
                    <View></View>
                }
              </div>
            )
          })
        }
      </Page>
    </Document>
  )
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  space: {
    width: '100%',
    height: 12,
  },
})
