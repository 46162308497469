import config from '../config';
import api from '../services/api';

const getBySection = async (sectionId, companyId) => {
  try {
    const res = await api.get('families/by-section/id/' + sectionId, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

const getAllByTypesAndCompany = async (companyId, queryParams) => {
  const response = await api.get(
    config.endpoint + `families/all-by-company/${companyId}`,
    {
      params: queryParams,
    }
  );
  return response.data;
};

const getAllByCompany = async (companyId, queryParams) => {
  const response = await api.get(
    config.endpoint + `families/by-company/${companyId}`,
    {
      params: queryParams,
    }
  );
  return response.data;
};

const getAllProductsFamiliesActiveByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `families/by-company/${companyId}`
    );
    return response.data.filter(
      (family) => !!family.IsActive && family.Type === 'Produto'
    );
  } catch (err) {
    throw err;
  }
};

const getAllServicesFamiliesActiveByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `families/by-company/${companyId}`
    );
    return response.data.filter(
      (family) =>
        !!family.IsActive &&
        (family.Type === 'Servico' || family.Type === 'Serviço')
    );
  } catch (err) {
    throw err;
  }
};

const getAllActiveProductsFamiliesByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `families/active-products-by-company/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getDefaults = async () => {
  try {
    const response = await api.get(config.endpoint + 'families/defaults');
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getBySection,
  getAllByTypesAndCompany,
  getAllByCompany,
  getDefaults,
  getAllProductsFamiliesActiveByCompany,
  getAllServicesFamiliesActiveByCompany,
  getAllActiveProductsFamiliesByCompany,
};
