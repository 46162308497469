import React, { useState, useEffect } from 'react'

import { Field, change } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'

import { Col } from 'react-bootstrap'
import Toggle from 'react-toggle'
import RenderField from '../../../../../components/RenderField'

import cashierBankRepository from '../../../../../repositories/CashierBank'
import BankFields from './BankFields'

const MainData = () => {
  const [cashierBankTypes, setCashierBankTypes] = useState([])

  const dispatch = useDispatch()
  const { isActive, cashierBankTypeId, id } = useSelector(state => state.form.formCashierBank.values)

  useEffect(() => {
    cashierBankRepository.getCashierBankTypes().then(resp => setCashierBankTypes(resp))
  }, [])

  const handleToggle = () => {
    dispatch(change('formCashierBank', 'isActive', !isActive))
  }

  const handleChangeType = () => {
    dispatch([
      change('formCashierBank', 'bankId', ''),
      change('formCashierBank', 'agency', ''),
      change('formCashierBank', 'checkingAccount', ''),
      change('formCashierBank', 'phoneNumber', ''),
      change('formCashierBank', 'manager', ''),
    ])
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="Tipo:"
            name="cashierBankTypeId"
            component={RenderField}
            as="select"
            required
            onChange={handleChangeType}
            disabled={!!id}
          >
            {cashierBankTypes.map(({id, description}) => (
              <option key={id} value={id}>{description}</option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <label>Status</label>
          <div style={{ paddingLeft: '10px' }}><Toggle checked={isActive} onChange={handleToggle} /></div>
        </Col>
        <Col xs={12} sm={6} md={2} lg={2}>
          <Field
            label="Data de Criação:"
            name="createdAt"
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Field
            label="Data da últ. movimentação:"
            name="lastMovementAt"
            component={RenderField}
            disabled
          />
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={cashierBankTypeId === "1" ? 2 : 4} lg={cashierBankTypeId === "1" ? 2 : 4}>
          <Field
            label="Descrição:"
            name="description"
            component={RenderField}
            maxLength="30"
            required
          />
        </Col>
        {cashierBankTypeId === "1" && <BankFields />}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
          <Field
            label="Observações:"
            name="observations"
            component={RenderField}
            as="textarea"
            maxLength="300"
          />
      </Col>
    </>
  )
}

export default MainData