import React from 'react';
import { Modal } from 'react-bootstrap';

export const CRMBonusFirstPurchaseModal = ({
  isOpen,
  handleProceedFirstPurchase,
}) => {
  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          É a primeira compra do cliente com direito a cashback. O Cliente terá
          bônus{' '}
        </span>
        <strong>apenas na próxima compra</strong>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end">
          <button
            className="button button-green w-70px"
            onClick={handleProceedFirstPurchase}
          >
            Continuar
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
