import React, { useEffect } from 'react';
import { Modal, Col } from 'react-bootstrap';
import { reduxForm, change, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format, addDays, addMonths, addYears } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import RenderField from 'components/RenderField';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import plansRepository from 'v2/repositories/Plans';

const getQtdUsersOptions = () => {
  const qtdUsers = [];

  for (let i = 1; i <= 99; i++) {
    qtdUsers.push({ value: i });
  }

  return qtdUsers;
};

const NewSignature = ({ onCancel, handleSubmit, company }) => {
  const { plans, startDate, period, brand } = useSelector(
    (state) => state.form.cadastroNewSignature.values
  );
  const dispatch = useDispatch();

  const handleDate = async () => {
    let date;
    if (!!startDate) {
      if (period === 'Mensal') {
        date = format(
          addMonths(addDays(new Date(startDate), 1), 1),
          'yyyy-MM-dd'
        );
      } else if (period === 'Trimestral') {
        date = format(
          addMonths(addDays(new Date(startDate), 1), 3),
          'yyyy-MM-dd'
        );
      } else if (period === 'Anual') {
        date = format(
          addYears(addDays(new Date(startDate), 1), 1),
          'yyyy-MM-dd'
        );
      }
      await dispatch(change('cadastroNewSignature', 'endDate', date));
    }
  };

  const getPlans = async () => {
    try {
      const brandingPlans = await plansRepository.getAll(brand);
      const plans = brandingPlans.reduce(
        (prev, curr) => [...prev, ...curr.Plans],
        []
      );
      await dispatch(change('cadastroNewSignature', 'plans', plans));
    } catch (err) {
      toastr.error('Ocorreu algum erro ao buscar planos');
    }
  };

  useEffect(() => {
    handleDate();
  }, [startDate, period]);

  useEffect(() => {
    if (company) getPlans();
  }, [brand]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Assinar Plano</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="signature-modal">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Field
                name="brand"
                label="Produto:"
                as="select"
                component={RenderField}
              >
                <option value="">Selecione</option>
                <option value="1">OS Digital</option>
                <option value="2">WorkMotor</option>
                <option value="3">Tecnomotor</option>
                <option value="4">Barros</option>
                <option value="5">PO360</option>
              </Field>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Field
                name="plan"
                label="Plano:"
                as="select"
                component={RenderField}
                disabled={!brand}
              >
                <option value="">Selecione</option>
                {plans.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.description}
                  </option>
                ))}
              </Field>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Field
                name="period"
                label="Período:"
                as="select"
                component={RenderField}
              >
                <option value="">Selecione</option>
                <option value="Mensal">Mensal</option>
                <option value="Trimestral">Trimestral</option>
                <option value="Anual">Anual</option>
              </Field>
            </Col>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Field
                name="startDate"
                label="Início da Vigência:"
                component={RenderField}
                type="date"
                max={format(new Date(), 'yyyy-MM-dd')}
              />
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Field
                name="endDate"
                label="Final da Vigência:"
                component={RenderField}
                type="date"
                disabled={true}
              />
            </Col>
            <Col lg={3} md={3} sm={4} xs={4}>
              <Field
                name="qtdUsers"
                label="Qtd. de usuários"
                as="select"
                component={RenderField}
              >
                {getQtdUsersOptions().map((p) => (
                  <option key={p.value} value={p.value}>
                    {' '}
                    {p.value}
                  </option>
                ))}
              </Field>
            </Col>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div id="form-submit-buttons">
          <Button bsStyle="danger" fill onClick={onCancel}>
            Voltar
          </Button>
          <Button className={'btn btn-sucesso'} onClick={handleSubmit}>
            Assinar
          </Button>
        </div>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroNewSignature',
})(withRouter(NewSignature));
