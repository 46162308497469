import React from 'react';

import './styles.css';

const PageSelector = ({ pageSelected, setPageSelected }) => {
  const selectedStyle = {
    backgroundColor: '#1d7391',
    color: '#FFF',
  };

  return (
    <div className="page-selector">
      <div
        className="page-tab-title"
        onClick={() => setPageSelected('tray')}
        style={pageSelected === 'tray' ? selectedStyle : {}}
      >
        Tray (E-commerce)
      </div>
    </div>
  );
};

export default PageSelector;
