import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { useSelector } from 'react-redux';
import { createNumberMask } from 'redux-form-input-masks';
import './style.css';
import Toggle from 'react-toggle';
import InputNumber from 'client/components/InputNumberCustom';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
});

const marginMask = createNumberMask({
  decimalPlaces: 2,
  suffix: '%',
});

const kgMask = createNumberMask({
  decimalPlaces: 3,
});
export default function AtacVarDadosEstoque({ dispatch, loaded, setLoaded }) {
  const [editSalePrice, setEditSalePrice] = useState(false);
  const [editSalePriceWholesale, setEditSalePriceWholeSale] = useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const {
    formProduto: {
      values: {
        Purchase_Price,
        Profit_Margin,
        salePriceWholesale,
        profitMarginWholesale,
        Sale_Price,
        Multiplier_Base,
        updateDescriptionOnSale,
        hasDiscountLimit,
        singlePhase,
        weight,
        ProductId,
      },
    },
  } = useSelector((state) => state.form);
  useEffect(() => {
    if (!hasDiscountLimit) {
      dispatch(change('formProduto', 'discountLimit', 0));
    }
  }, [hasDiscountLimit]);

  useEffect(() => {
    if (!editSalePrice && loaded) {
      if (Purchase_Price && Profit_Margin >= 0) {
        calculateSalePrice();
      } else {
        dispatch(change('formProduto', 'Sale_Price', 0));
      }
    }
  }, [Purchase_Price, Profit_Margin, editSalePrice, loaded]);

  useEffect(() => {
    if (!editSalePriceWholesale && loaded) {
      if (Purchase_Price && profitMarginWholesale >= 0) {
        calculateSalePriceWholeSale();
      } else {
        dispatch(change('formProduto', 'salePriceWholesale', 0));
      }
    }
  }, [Purchase_Price, profitMarginWholesale, editSalePriceWholesale, loaded]);

  const calculateSalePrice = async () => {
    let percent = Profit_Margin / 100;
    let salePrice = Purchase_Price + Purchase_Price * percent;
    await dispatch(change('formProduto', 'Sale_Price', salePrice));
  };

  const calculateSalePriceWholeSale = async () => {
    let percent = profitMarginWholesale / 100;
    let salePriceWholesale = Purchase_Price + Purchase_Price * percent;
    await dispatch(
      change('formProduto', 'salePriceWholesale', salePriceWholesale)
    );
  };

  const handleChangeMultiplierBase = (value) => {
    dispatch(change('formProduto', 'Multiplier_Base', value));
    document.getElementById('Multiplier_Base').focus();
  };
  const handleSinglePhase = () => {
    dispatch(change('formProduto', 'singlePhase', !singlePhase));
  };

  const handleChangeSalePrice = async (salePrice) => {
    await setEditSalePrice(true);

    let percentage;
    let profitMargin;
    if (
      (salePrice === 0 && Purchase_Price === 0) ||
      salePrice < Purchase_Price
    ) {
      percentage = 0;
    } else if (salePrice > 0 && Purchase_Price > 0) {
      profitMargin = salePrice / Purchase_Price - 1;
      percentage = profitMargin * 100;
    } else {
      percentage = 100;
    }
    await dispatch(change('formProduto', 'Profit_Margin', percentage));
  };

  const handleChangeSalePriceWholesale = async (salePriceWholeSale) => {
    await setEditSalePriceWholeSale(true);

    let percentage;
    let profitMargin;

    if (
      (salePriceWholeSale === 0 && Purchase_Price === 0) ||
      salePriceWholeSale < Purchase_Price
    ) {
      percentage = 0;
    } else if (salePriceWholeSale > 0 && Purchase_Price > 0) {
      profitMargin = salePriceWholeSale / Purchase_Price - 1;
      percentage = profitMargin * 100;
    } else {
      percentage = 100;
    }
    await dispatch(change('formProduto', 'profitMarginWholesale', percentage));
  };

  const handleChangeMargin = async () => {
    setEditSalePrice(false);
  };
  const handleChangeMarginWholesale = async () => {
    setEditSalePriceWholeSale(false);
  };

  useEffect(() => {
    if (!editSalePrice && loaded) {
      if (Purchase_Price && Profit_Margin >= 0) {
        calculateSalePrice();
      } else {
        dispatch(change('formProduto', 'Sale_Price', 0));
      }
    } else {
      handleChangeSalePrice(Sale_Price);
      setLoaded(true);
    }
  }, [Purchase_Price, Profit_Margin, editSalePrice, Sale_Price, weight]);

  useEffect(() => {
    if (!editSalePriceWholesale && loaded) {
      if (Purchase_Price && profitMarginWholesale >= 0) {
        calculateSalePriceWholeSale();
      } else {
        dispatch(change('formProduto', 'salePriceWholesale', 0));
      }
    } else {
      handleChangeSalePriceWholesale(salePriceWholesale);
      setLoaded(true);
    }
  }, [
    Purchase_Price,
    profitMarginWholesale,
    editSalePriceWholesale,
    salePriceWholesale,
    weight,
  ]);

  return (
    <>
      <div className="flex column mt-075" style={{ marginLeft: '11px' }}>
        <div className="flex row gap-050">
          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">
              R$ de Compra:
              <span className="text-red">*</span>
            </label>
            <Field
              style={{ width: '120px', marginLeft: '8px' }}
              required
              name="Purchase_Price"
              component={Input}
              {...currencyMask}
              maxLength="18"
            />
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">
              Margem (<span className="text-bold">Varejo</span>):
              <span className="text-red">*</span>
            </label>
            <Field
              id="input-margin"
              style={{ width: '120px', marginLeft: '8px' }}
              required
              name="Profit_Margin"
              component={Input}
              {...marginMask}
              // maxLength="6"
              onChange={() =>
                editSalePrice ? handleChangeMargin() : calculateSalePrice()
              }
            />
          </div>
          <div style={{ marginRight: '10px', width: '180px' }}>
            <label className="fontSize-12 text-normal">
              R$ de Venda (<span className="text-bold">Varejo</span>):
              <span className="text-red">*</span>
            </label>
            <Field
              required
              name="Sale_Price"
              component={Input}
              {...currencyMask}
              style={{ marginLeft: '8px' }}
              maxLength="18"
              onChange={(e) => handleChangeSalePrice(e.target.value)}
            />
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">
              Margem (<span className="text-bold">Atacado</span>):
              <span className="text-red">*</span>
            </label>
            <Field
              style={{ width: '120px', marginLeft: '8px' }}
              required
              name="profitMarginWholesale"
              component={Input}
              {...marginMask}
              // maxLength="6"
              onChange={() =>
                editSalePriceWholesale
                  ? handleChangeMarginWholesale()
                  : calculateSalePriceWholeSale()
              }
            />
          </div>

          <div style={{ marginRight: '10px', width: '180px' }}>
            <label className="fontSize-12 text-normal">
              R$ de Venda (<span className="text-bold">Atacado</span>):
              <span className="text-red">*</span>
            </label>
            <Field
              required
              name="salePriceWholesale"
              component={Input}
              style={{ marginLeft: '8px' }}
              {...currencyMask}
              maxLength="18"
              onChange={(e) => handleChangeSalePriceWholesale(e.target.value)}
            />
          </div>

          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <label className="fontSize-12 text-normal">
              Base Multiplicadora:
            </label>
            <InputNumber
              className="form-control foco-input"
              style={{ display: 'flex' }}
              onChange={handleChangeMultiplierBase}
              value={Multiplier_Base}
              name="Multiplier_Base"
              id="Multiplier_Base"
            />
          </div>

          <div className="toggle-column">
            <label className="fontSize-12 text-normal">
              Altera Descrição na Venda?
            </label>

            <Toggle
              checked={updateDescriptionOnSale}
              onChange={() =>
                dispatch(
                  change(
                    'formProduto',
                    'updateDescriptionOnSale',
                    !updateDescriptionOnSale
                  )
                )
              }
            />
          </div>
        </div>
        <div className="flex row gap-050">
          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">Estoque Mín.: </label>
            <Field
              style={{ marginLeft: '8px', maxWidth: '120px' }}
              name="minStock"
              disabled={updateDescriptionOnSale}
              component={Input}
              normalize={onlyNumbers}
            />
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">Estoque Máx.: </label>
            <Field
              style={{ marginLeft: '8px', maxWidth: '120px' }}
              name="maxStock"
              disabled={updateDescriptionOnSale}
              component={Input}
              normalize={onlyNumbers}
              maxWidth="200px"
            />
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">% Comissão: </label>
            <Field
              name="Commission_Rate"
              style={{ marginLeft: '8px' }}
              component={Input}
              {...marginMask}
              maxLength="6"
            />
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">
              Tipo de Produto:
              <span className="text-red">*</span>
            </label>
            <Field
              style={{ marginLeft: '8px' }}
              name="product_type"
              component="select"
              className="form-control foco-input"
            >
              <option value="Produto Final">Produto Final</option>
              <option value="Uso e Consumo">Uso e Consumo</option>
              <option value="Matéria Prima">Matéria Prima</option>
            </Field>
          </div>

          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">Peso Líq. (KG):</label>
            <Field
              name="netWeight"
              style={{ maxWidth: '140px', marginLeft: '8px' }}
              component={Input}
              {...kgMask}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <label className="fontSize-12 text-normal">Peso Bruto (KG):</label>
            <Field
              name="weight"
              style={{ maxWidth: '140px', marginLeft: '8px' }}
              component={Input}
              {...kgMask}
            />
          </div>

          {!isPlanFree && !isPlanStart && (
            <>
              <div style={{ marginRight: '10px' }}>
                <label className="fontSize-12 text-normal">Produção:</label>
                <Field
                  name="production"
                  component="select"
                  className="form-control foco-input"
                  style={{ marginLeft: '8px' }}
                >
                  <option value="Terceiros">Terceiros</option>
                  <option value="Própria">Própria</option>
                </Field>
              </div>

              <div className="toggle-column">
                <label className="fontSize-12 text-normal">Monofásico:</label>

                <Toggle
                  name="singlePhase"
                  checked={singlePhase}
                  onChange={() => handleSinglePhase()}
                />
              </div>
            </>
          )}
        </div>
        <div>
          <label className="fontSize-12 text-normal">Limite de Desconto:</label>
          <div
            style={{
              marginLeft: '8px',
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
              width: '150px',
            }}
          >
            <Toggle
              checked={hasDiscountLimit}
              onChange={() =>
                dispatch(
                  change('formProduto', 'hasDiscountLimit', !hasDiscountLimit)
                )
              }
            />
            <Field
              name="discountLimit"
              component={Input}
              disabled={!hasDiscountLimit}
              {...marginMask}
              maxLength="6"
            />
          </div>
        </div>
      </div>
    </>
  );
}
