import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useSelector, useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';

export default function PDV() {
  const { AutoAddSingleItemToPDV } = useSelector(
    (state) => state.form.salesConfiguration.values
  );
  const dispatch = useDispatch();
  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isPlanPrime,
    isPlanWorkmotorFree,
    isWorkmotor: isPlanWorkmotor,
  } = usePlanSignatureContext();
  const isPlanFreeOrStart = isPlanFree || isPlanStart;

  function handleAutoAddSingleItemToPDV() {
    dispatch(
      change(
        'salesConfiguration',
        'AutoAddSingleItemToPDV',
        !AutoAddSingleItemToPDV
      )
    );
  }
  return (
    <div className="pdv">
      {!isPlanFreeOrStart && (
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className="toggle-column"
          style={{
            marginLeft: '-20px',
          }}
        >
          <div>
            <label>Adição Automático de item único</label>
          </div>

          <Toggle
            checked={AutoAddSingleItemToPDV}
            onChange={() => handleAutoAddSingleItemToPDV()}
          />
        </Col>
      )}
    </div>
  );
}
