import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import constants from '../../../utils/constants'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import SuivCompanyMain from './components/Main'

export function SuivCompany() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Integrações', 'SUIV']}
            path={[constants.ROUTES.HOME, null, null]}
          />
          <SuivCompanyMain />
        </Row>
      </Grid>
    </div>
  )
}
