import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeIntermediary from './Intermediary'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import './styles.css';

const Intermediary = () => {
  localStorage.setItem('selectedPath', 'client/intermediaries')
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'Intermediador']}
           path={['home', null, null, null ]} />
          <HomeIntermediary />
        </Row>
      </Grid>
    </div>
  );
}
export default Intermediary