import React, { useState, useEffect, memo } from 'react';
import './styles.scss';

import Client from './Client';
import SearchModal from './components/SearchModal';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';
import { useAuth } from 'contexts/auth';
import { useSelector } from 'react-redux';
import {
  getPricesOptions,
  getDefaultPriceOptions,
} from 'v2/helpers/priceHelpers';
import { toastr } from 'react-redux-toastr';

const SalesPDV = ({ setUpdateTotalPdvTrigger }) => {
  const { companyId } = useAuth();
  const [allowModalOpen, setAllowModalOpen] = useState(false);

  const { customerId } = useSelector((state) => state.form.pdvOnline.values);
  const [productQuery, setProductQuery] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [buttonText, setButtonText] = useState('Pesquisar');

  const { sellingPriceType } = useConfigurationsContext();
  const [activeType, setActiveType] = useState({});
  const [priceTypes, setPriceTypes] = useState([]);

  const loadOptions = async () => {
    setPriceTypes(getPricesOptions(sellingPriceType, false, false));
    setActiveType(await getDefaultPriceOptions(sellingPriceType, customerId));
  };

  useEffect(() => {
    if (companyId && sellingPriceType) {
      loadOptions();
    }
  }, [companyId, sellingPriceType, customerId]);

  useEffect(() => {
    if (
      productQuery !== '' ||
      vehicleModel !== '' ||
      additionalInformation !== ''
    ) {
      setButtonText('Pesquisar Produto');
    } else {
      setButtonText('Pesquisar');
    }
  }, [productQuery, vehicleModel, additionalInformation]);

  const handleSetType = async (e) => {
    if (customerId) {
      const customerDefaultOption = await getDefaultPriceOptions(
        sellingPriceType,
        customerId
      );
      if (
        e.value.includes('Produtos') &&
        !customerDefaultOption.value.includes(e.value.split(' - ')[1])
      ) {
        return toastr.warning(
          'Selecione outro tipo ou cliente.',
          `O cliente selecionado utiliza apenas o preço de ${customerDefaultOption.value}`
        );
      }
    }
    setActiveType(e);
  };

  return (
    <div className="container-left">
      <div className="search-input">
        <div className="search-input-fields">
          <div className="search-input-code">
            <span className="text-teste">
              Código/Descrição/Apelido/Ref. Fab/Código de Barras
            </span>
            <input
              id="queryInput"
              type="text"
              onChange={(e) => setProductQuery(e.target.value)}
              className="form-control"
              value={productQuery}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAllowModalOpen(true);
                }
              }}
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
            />
          </div>
          <div className="search-input-vehicle">
            <span className="text-teste">Modelo do Veículo(Aplicação)</span>
            <input
              type="text"
              value={vehicleModel}
              onChange={(e) => setVehicleModel(e.target.value)}
              className="form-control"
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
            />
          </div>
        </div>
        <div className="additionalInformation-input">
          <span className="text-teste">Informações Complementares</span>
          <input
            type="text"
            value={additionalInformation}
            onChange={(e) => setAdditionalInformation(e.target.value)}
            className="form-control"
            style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
          />
        </div>
        <button
          type="button"
          className="btn btn-sucesso"
          onClick={() => setAllowModalOpen(true)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '38px',
            fontSize: '18px',
          }}
        >
          {buttonText}
        </button>
        <p
          style={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#d9534f',
            marginTop: '4px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          Clique no botão para pesquisar todos os itens.
        </p>
      </div>
      <div>
        <Client />
      </div>

      {allowModalOpen && (
        <SearchModal
          handleClose={() => {
            setVehicleModel('');
            setProductQuery('');
            setAdditionalInformation('');
            setAllowModalOpen(false);
            setTimeout(() => {
              const queryInput = document.getElementById('queryInput');
              queryInput.focus();
            }, 500);
          }}
          productQuery={productQuery}
          vehicleModel={vehicleModel}
          priceTypes={priceTypes}
          priceType={activeType}
          handleSetType={handleSetType}
          additionalInformationQuery={additionalInformation}
          setProductQuery={setProductQuery}
          setVehicleModel={setVehicleModel}
          setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger}
          allowPromotionalPrices
        />
      )}
    </div>
  );
};

export default memo(SalesPDV);
