const saleInvoiceGenerationErrors = {
  100: 'Preencha a Inscrição Municipal (IM) nas configurações fiscais',
  101: 'Preencha o CNAE nas configurações fiscais',
  102: 'Preencha o ISS nas configurações fiscais',
  103: 'Não há serviços para gerar NFSe nas configurações fiscais',
  104: 'Preencha a Série nas configurações fiscais',
  105: 'Informe a última NFSe enviada nas configurações fiscais',
  defaultMessage:
    'Ocorreu algum problema ao gerar NFS-e. Verifique a configuração da NFS-e em "Minha Empresa > Dados Fiscais".',
}

export default saleInvoiceGenerationErrors
