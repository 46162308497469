const xlsColumns = [
  {
    name: 'Vencimento',
    acessor: 'dueDate',
  },
  {
    name: 'Título',
    acessor: 'titleCode',
  },
  {
    name: 'Vendas',
    acessor: 'saleCode',
  },
  {
    name: 'NF',
    acessor: 'nfCode',
  },
  {
    name: 'Atraso',
    acessor: 'daysOverdue',
  },
  {
    name: 'Cliente',
    acessor: 'customer',
  },
  {
    name: 'Telefone',
    acessor: 'customerPhone',
  },
  {
    name: 'Data de baixa',
    acessor: 'dischargeDate',
  },
  {
    name: 'Banco/Caixa',
    acessor: 'cashierBank',
  },
  {
    name: 'Valor do título',
    acessor: 'amount',
  },
  {
    name: 'Conta',
    acessor: 'accountPlan',
  },
  {
    name: 'DT Criação',
    acessor: 'createdAt',
  },
  {
    name: 'DT Pagamento',
    acessor: 'payDate',
  },
]

export default xlsColumns
