import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.css'
import stoneCardsRepository from '../../../../repositories/StoneCards'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import {
  getDay,
  previousSunday,
  eachDayOfInterval,
  format,
  nextSaturday,
  isEqual,
} from 'date-fns'

const WeeksAgendaCard = () => {
  const [weekDays, setWeekDays] = useState([])
  const [weekDaysDetails, setWeekDaysDetails] = useState([])
  const [finalDateWithValue, setFinalDateWithValue] = useState([])

  const { companyId } = useAuth()

  useEffect(() => {
    getStoneCardsData()
  }, [])

  useEffect(() => {
    getDaysOfWeek()
  }, [])

  useEffect(() => {
    getValues(weekDaysDetails)
  }, [weekDaysDetails, weekDays])

  //Busca dados da api e seleciona apenas o objeto weekDaysDetails que contém os dados da semana
  async function getStoneCardsData() {
    try {
      const data = await stoneCardsRepository.show(companyId)

      setWeekDaysDetails(data.weekDaysDetails)
    } catch (err) {
      toastr.error(
        'Ocorreu ao carregar os dados do extrato. Por favor, tente novamente'
      )
    }
  }

  //Cria os dias da semana e seta de acordo com o dia da semana
  function getDaysOfWeek() {
    //Retorna os dias do intervalo de domingo a sábado
    const daysOfWeek = eachDayOfInterval({
      start: previousSunday(new Date()),
      end: nextSaturday(new Date()),
    })

    let daysOfWeekName = {
      0: 'Domingo',
      1: 'Segunda-feira',
      2: 'Terça-feira',
      3: 'Quarta-feira',
      4: 'Quinta-feira',
      5: 'Sexta-feira',
      6: 'Sábado',
    }
    //O getDay retorna um numero de 0 a 6
    const days = daysOfWeek.map((date) => ({
      dayName: daysOfWeekName[getDay(new Date(date))],
      date: date,
    }))

    setWeekDays(days)
  }

  function getValues(dayValue) {
    let newWeekDays = [...weekDays]

    for (const index in newWeekDays) {
      for (const day of dayValue) {
        const isDateEqual = isEqual(
          new Date(newWeekDays[index].date),
          getDateOnlyFromDate(day.day)
        )
        if (isDateEqual) {
          newWeekDays[index] = { ...newWeekDays[index], ...day }
        }
      }
    }

    setFinalDateWithValue(newWeekDays)
  }

  return (
    <div className='week-card'>
      <main>
        <div className='div-title'>
          <p>Agenda da Semana</p>
          <FontAwesomeIcon
            title='Os valores podem demorar em até 3 horas para serem atualizados'
            cursor='pointer'
            icon={faInfoCircle}
            style={{
              width: '20px',
              height: '20px',
              color: '#97c852',
            }}
            aria-hidden='false'
          />
        </div>
        {finalDateWithValue.map((d, index) => (
          <div className='div-date' key={index}>
            <div className='div-day'>
              <h2>{d.dayName}</h2>
              <p>{format(d.date, 'dd/MM/yyyy')}</p>
            </div>
            <h3>{!!d?.amount ? currency(d?.amount) : currency(0)}</h3>
          </div>
        ))}
      </main>
    </div>
  )
}

export default WeeksAgendaCard
