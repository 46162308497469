import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../CustomButton/CustomButton';
import SupportButton from '../SupportButton';
import { currency } from '../ToNormalize/ToNormalize';

import './styles.css';

const FiscalModuleInactiveModal = ({ show, onHide, module }) => {
  return (
    <Modal show={show} animation onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <span>
          <strong>Módulo fiscal não habilitado</strong>
        </span>
        <br />
        <span>
          Para utilizar a emissão da {module?.description} no OS Digital, será
          adicionado o valor de {currency(module?.price)} na sua mensalidade.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="buttons-wrapper">
          <Button bsStyle="danger" fill onClick={onHide}>
            Voltar
          </Button>
          <SupportButton buttonText="Solicitar módulo fiscal" />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FiscalModuleInactiveModal;
