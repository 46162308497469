import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { change } from 'redux-form'
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { createTextMask } from 'redux-form-input-masks'
import Input from 'client/components/Input/Input.jsx'
import DropPhotoContainer from '../../../../components/DropPhotoContainer'
import CustomButton from 'client/components/CustomButton/CustomButton'
import { useDispatch } from 'react-redux'
import AlertModal from 'components/AlertModal/AlertModal'

import companyRepository from 'repositories/Companies'

import semImg from 'assets/img/sem-imagem.png'

import './styles.css'
import { toastr } from 'react-redux-toastr'
import companyConfig from 'repositories/CompanyConfig'
import Toggle from 'react-toggle'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'

const CpfCnpjMask = createTextMask({
  pattern: '99.999.999/9999-99',
})

const CpfMask = createTextMask({
  pattern: '999.999.999-99',
})

const MainData = ({
  dispatch,
  previousSuperLogicaId,
  setPreviousSuperLogicaId,
}) => {
  const { meusDados } = useSelector((state) => state.form)

  const [advertisement, setAdvertisement] = useState('GLOBAL')
  const [isGlobalAdActive, setIsGlobalAdActive] = useState(null)

  const [isEditingSuperLogica, setIsEditingSuperLogica] = useState(false)
  const [companyWithSuperLogicaId, setCompanyWithSuperLogicaId] = useState({})

  const [loadingChangeSuperLogicaId, setLoadingChangeSuperLogicaId] =
    useState(false)

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const Type = meusDados?.values?.Type
  const companyId = meusDados?.values?.Company_id
  const logo = meusDados?.values?.logo
  const currentAdvertisement = meusDados?.values?.advertisement
  const superLogicaId = meusDados?.values?.superLogicaId
  const advertisementOptions = meusDados?.values?.advertisementOptions
  const signatureStatus = meusDados?.values?.signatureStatus
  const onboardingVideoVisualizedDate =
    meusDados?.values?.OnboardingVideoVisualizedDate

  const formatedOnboardingDateSplited = format(
    new Date(onboardingVideoVisualizedDate || null),
    'dd/MM/yyyy HH:mm'
  ).split(' ')

  useEffect(() => {
    if (!!companyId) loadCompanyConfigData()
  }, [companyId])

  function getStatusTagDescription(statusId) {
    const statusLabelDescriptions = {
      2: 'Ativo',
      3: 'Bloqueado',
      4: 'Cancelado',
    }

    return statusLabelDescriptions[statusId] || ''
  }

  function getStatusTagColor(statusId) {
    const statusLabelColors = {
      2: '#5cb85c',
      3: '#f0ad4e',
      4: '#d9534f',
    }

    return statusLabelColors[statusId] || '#c1c1c1'
  }

  function handleStopEditingSuperLogica() {
    dispatch(change('meusDados', 'superLogicaId', previousSuperLogicaId))
    setIsEditingSuperLogica(false)
  }

  async function handleSubmitSuperLogicaId() {
    if (!superLogicaId || superLogicaId === '0') {
      return toastr.warning(
        'Não foi possível salvar',
        'Aplique o código da superlógica e tente novamente'
      )
    }

    setLoadingChangeSuperLogicaId(true)
    try {
      const companyWithId = await companyRepository.showBySuperLogicaId(
        superLogicaId
      )

      if (companyWithId && companyWithId.id !== companyId) {
        setCompanyWithSuperLogicaId(companyWithId)
        setIsConfirmationModalOpen(true)
        setLoadingChangeSuperLogicaId(false)
        return
      }

      handleUpdateSuperLogicaId()
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao validar o ID da Super Lógica. Tente novamente!'
      )
      setLoadingChangeSuperLogicaId(false)
    } finally {
      setLoadingChangeSuperLogicaId(false)
    }
  }

  async function handleUpdateSuperLogicaId() {
    setLoadingChangeSuperLogicaId(true)

    try {
      await companyRepository.updateSuperLogicaId({
        companyId,
        superLogicaId,
      })

      setPreviousSuperLogicaId(superLogicaId)
      setIsEditingSuperLogica(false)
      setLoadingChangeSuperLogicaId(false)

      toastr.success(
        'Código alterado com sucesso',
        'O Código da SL foi atualizado. Se necessário, atualize a assinatura do cliente.'
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao validar o ID da Super Lógica. Tente novamente!'
      )
      setLoadingChangeSuperLogicaId(false)
    }
  }

  async function handleAdvertisementChange(value) {
    try {
      if (value !== currentAdvertisement) {
        setAdvertisement(value)
        await companyConfig.changeAd(companyId, value)
        toastr.success('Propaganda alterada com sucesso!')
      }
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao atualizar a propaganda. Tente novamente!'
      )
      console.log(err)
    }
  }

  async function handleGlobalAdStatusChange(value) {
    try {
      setIsGlobalAdActive(value)
      await companyConfig.changeGlobalAdStatus(companyId, value)
      toastr.success('Status atualizado com sucesso!')
    } catch (err) {
      toastr.error('Ocorreu um erro ao atualizar o status. Tente novamente!')
      console.log(err)
    }
  }

  async function loadCompanyConfigData() {
    try {
      const { isGlobalAdActive } = await companyConfig.show(companyId)
      setIsGlobalAdActive(isGlobalAdActive)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!currentAdvertisement) {
      setAdvertisement(null)
    } else {
      setAdvertisement(currentAdvertisement)
    }
  }, [currentAdvertisement])

  return (
    <div className="">
      <Row className="tipoPessoa" lg={12} md={12} sm={12} xs={12}>
        <Col lg={2} md={8} sm={3} xs={12}>
          <label>Tipo de Pessoa:</label>
          <Field
            component="select"
            disabled={true}
            className="form-control foco-input"
            name="type"
            onChange={() => {}}
          >
            <option value="Jurídica">Jurídica</option>
            <option value="Física">Física</option>
          </Field>
        </Col>
        <Col lg={2} md={3} sm={3} xs={12}>
          <label>Status</label>
          <div
            className="tag"
            style={{
              backgroundColor: getStatusTagColor(signatureStatus),
              marginLeft: '5px',
            }}
          >
            {getStatusTagDescription(signatureStatus)}
          </div>
        </Col>

        <Col lg={2} md={3} sm={3} xs={12}>
          <label>Onboarding:</label>
          <div
            className="tag"
            style={{
              backgroundColor: onboardingVideoVisualizedDate
                ? '#5cb85c'
                : '#d9534f',
              width: 'max-content',
            }}
          >
            {onboardingVideoVisualizedDate
              ? `Visualizado dia ${formatedOnboardingDateSplited[0]} às ${formatedOnboardingDateSplited[1]}`
              : 'Não visualizado'}
          </div>
        </Col>
      </Row>

      <Row>
        <div style={{ marginLeft: '80%', marginTop: '-80px' }}>
          <label>
            <small>Logo:</small>
          </label>
          <div style={{ width: 'fit-content' }}>
            {logo?.preview ? (
              <DropPhotoContainer
                image={logo}
                style={{ width: 'fit-content' }}
                onRemove={() => {}}
                onUpload={() => {}}
              />
            ) : (
              <img
                src={semImg}
                style={{
                  width: 'fit-content',
                  height: '115px',
                  width: '135px',
                }}
              />
            )}
          </div>
        </div>
      </Row>

      <div>
        <Row
          style={{
            marginTop: '-60px',
            marginRight: 'auto',
          }}
        >
          <Col xs={12} sm={12} md={4} lg={4}>
            <div
              style={{
                margin: 'auto',
                alignSelf: 'left',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label htmlFor="isGlobalActive">
                Propaganda Dashboard 1 (Global):
              </label>
              <Toggle
                style={{ position: 'fixed' }}
                disabled={!advertisementOptions}
                checked={isGlobalAdActive}
                onChange={(e) => handleGlobalAdStatusChange(!isGlobalAdActive)}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <div>
              <label htmlFor="type">Propaganda Dashboard 2 (Exclusivo):</label>
            </div>

            <select
              className="form-control foco-input"
              name="advertisement"
              value={advertisement}
              onChange={(e) => handleAdvertisementChange(e.target.value)}
            >
              <option value="">Selecionar</option>
              {advertisementOptions?.map((ad) => {
                return (
                  <option key={ad.id} value={ad.id}>
                    {ad.description}
                  </option>
                )
              })}
            </select>
          </Col>
        </Row>
        {Type === 'Jurídica' ? (
          <>
            <Col lg={2} md={3} sm={4} xs={12}>
              <label>CNPJ</label>
              <Field
                disabled={true}
                name="Cpf_Cnpj"
                placeholder="CNPJ"
                component={Input}
                type="text"
                {...CpfCnpjMask}
              />
            </Col>

            <Col lg={5} md={6} sm={12} xs={12}>
              <label>Razão Social</label>
              <Field
                disabled={true}
                name="Company_Name"
                placeholder="Razão Social"
                component={Input}
              />
            </Col>

            <Col lg={3} md={3} sm={12} xs={12}>
              <label>Nome Fantasia</label>
              <Field
                disabled={true}
                name="Trading_Name"
                placeholder="Nome Fantasia"
                component={Input}
              />
            </Col>

            <Col lg={2}>
              <label>Contato</label>
              <Field disabled={true} name="Contato" component={Input} />
            </Col>
          </>
        ) : (
          <>
            <Col lg={2} md={3} sm={4} xs={12}>
              <label>CPF</label>
              <Field
                disabled={true}
                name="Cpf_Cnpj"
                placeholder="CPF"
                component={Input}
                {...CpfMask}
              />
            </Col>
            <Col lg={4} md={5} sm={12} xs={12}>
              <label>Nome Completo</label>
              <Field
                disabled={true}
                name="Company_Name"
                placeholder="Nome Completo"
                component={Input}
              />
            </Col>
          </>
        )}
        <Col lg={4} md={2} sm={12} xs={12}>
          <label>Email</label>
          <Field
            disabled={true}
            name="Email"
            placeholder="E-mail"
            component={Input}
          />
        </Col>
        <Col lg={2}>
          <label>Telefone</label>
          <Field
            disabled={true}
            className="form-control foco-input"
            required
            name="Phone"
            component={Input}
          />
        </Col>
        <Col lg={2}>
          <label>Tot. Usuários</label>
          <Field disabled={true} name="totalUsuarios" component={Input} />
        </Col>
        <Col lg={2}>
          <label>Usuários Ativos</label>
          <Field disabled={true} name="userActive" component={Input} />
        </Col>
        <Col lg={2}>
          <label>Data de Cadastro</label>
          <Field disabled={true} name="dataCadastro" component={Input} />
        </Col>
        <Col lg={2}>
          <label>Data de Atualização</label>
          <Field disabled={true} name="dataAtualizacao" component={Input} />
        </Col>
        <Col lg={2}>
          <label>Último Acesso</label>
          <Field disabled={true} name="lastLogin" component={Input} />
        </Col>
        <Col lg={2}>
          <label>Data de Vigência</label>
          <Field disabled={true} name="signatureEndDate" component={Input} />
        </Col>
        <Col lg={2}>
          <label>ID Banco de Dados</label>
          <Field disabled={true} name="Company_id" component={Input} />
        </Col>
        <Col lg={2}>
          <label>ID Superlógica</label>
          <Field
            className="form-control foco-input"
            disabled={isEditingSuperLogica ? false : true}
            name="superLogicaId"
            normalize={onlyNumbers}
            component={Input}
          />
        </Col>
        <Col lg={Type === 'Jurídica' ? 2 : 8} style={{ height: '75px' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isEditingSuperLogica ? (
              <>
                <CustomButton
                  bsStyle="danger"
                  name="Cancelar"
                  style={{
                    fontSize: '12px',
                  }}
                  onClick={() => {
                    handleStopEditingSuperLogica()
                  }}
                  fill
                  disabled={loadingChangeSuperLogicaId}
                >
                  Cancelar
                </CustomButton>
                &nbsp;
                <CustomButton
                  bsStyle="info"
                  name="Editar"
                  style={{
                    backgroundColor: '#5cb85c',
                    borderColor: '#5cb85c',
                    fontSize: '12px',
                  }}
                  onClick={() => handleSubmitSuperLogicaId()}
                  fill
                  disabled={loadingChangeSuperLogicaId}
                >
                  Salvar
                </CustomButton>
              </>
            ) : (
              <CustomButton
                bsStyle="info"
                name="Editar"
                onClick={() => {
                  setIsEditingSuperLogica(true)
                }}
                style={{
                  backgroundColor: '#5cb85c',
                  borderColor: '#5cb85c',
                  fontSize: '12px',
                }}
                fill
              >
                Editar
              </CustomButton>
            )}
          </div>
        </Col>
      </div>

      {isConfirmationModalOpen && (
        <AlertModal
          title="OS Digital"
          show={isConfirmationModalOpen}
          message={
            <>
              <span>
                <strong>
                  O código da Superlógica informado está presente em outra
                  empresa.
                </strong>
              </span>
              <br />
              <br />
              <span>
                CPF/CNPJ: {cpfOrCnpjMask(companyWithSuperLogicaId.cpfCnpj)}
              </span>{' '}
              <br />
              <span>
                Razão Social/Nome: {companyWithSuperLogicaId.tradingName}
              </span>{' '}
              <br />
              <span>Assinatura: {companyWithSuperLogicaId.plan}</span> <br />
              <span>
                Último acesso:{' '}
                {companyWithSuperLogicaId.lastLogin !== '-'
                  ? format(
                      getDateOnlyFromDate(
                        new Date(companyWithSuperLogicaId.lastLogin)
                      ),
                      'dd/MM/yyyy'
                    )
                  : '-'}
              </span>{' '}
              <br /> <br />
              <span>
                Verifique o cadastro da outra empresa na Superlógica se o código
                está informado correto, lembrando que:
              </span>
              <br />
              <ul>
                <li>
                  <span>
                    Não podem haver 2 cadastros com o mesmo CPF/CNPJ na SL
                  </span>
                </li>
                <li>
                  <span>
                    Não podem haver 2 cadastros com o mesmo código da SL
                  </span>
                </li>
              </ul>
              <br />
              <span>
                Após realizar a troca, o código será apagado na outra empresa e
                a sua asinatura será impactada, podendo ser cancelada a qualquer
                momento.
              </span>
              <br />
              <br />
              <span
                style={{ display: 'block', textAlign: 'center', color: 'red' }}
              >
                Lembre-se de aplicar o código do campo ID Banco de Dados dessa
                empresa no campo identificador da Superlógica.
              </span>
            </>
          }
          onCancel={() => setIsConfirmationModalOpen(false)}
          onSubmit={() => {
            setIsConfirmationModalOpen(false)
            handleUpdateSuperLogicaId()
          }}
          onHide={null}
        />
      )}
    </div>
  )
}

export default MainData
