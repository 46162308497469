import React from 'react'

import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import CustomButton from 'client/components/CustomButton/CustomButton'
import constants from 'utils/constants'

export default function BillsToPayRegistrationMethodModal({
  isOpen,
  handleClose,
  handleOpenPurchasesSelectionModal,
}) {
  const history = useHistory()

  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>
            Selecione o tipo de título que deseja criar:{' '}
          </p>
          <div className="panel-operation-buttons">
            <CustomButton
              fill
              style={{
                background: '#428bca',
                borderColor: '#428bca',
                fontSize: '16px',
              }}
              onClick={() => {
                handleOpenPurchasesSelectionModal()
                handleClose()
              }}
            >
              Criar Título de Compras
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#5CB85C',
                borderColor: '#5CB85C',
                fontSize: '16px',
              }}
              onClick={() => history.push(constants.ROUTES.BILL_TO_PAY)}
            >
              Criar Título Manual
            </CustomButton>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="Voltar"
              onClick={handleClose}
              fill
            >
              Voltar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
