import React from 'react'
import { Modal } from 'react-bootstrap'

import FormCliente from '../../../views/Clients/NewClient/FormClient'

const FormModalClient = ({ customerId, onCancel, onSubmit, ...rest }) => {
  return (
    <Modal
      dialogClassName="modal-formProduto"
      show
      onHide={onCancel}
      animation
      {...rest}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{!customerId ? 'Cadastrar Cliente' : 'Dados do Cliente'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        id="modal-edit-customer"
        style={{
          maxHeight: "calc(100vh - 120px)",
          overflowY: "auto"
        }}
      >
        <FormCliente
          modal
          ClientId={customerId}
          handleModal={onSubmit}
          hideModal={onCancel}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FormModalClient