import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
export function PaymentAuthorizationManyHeaderCount({ sales }) {
  function countUniqueSaleId() {
    const uniqueSaleIds = new Set();
    sales.forEach((sale) => {
      uniqueSaleIds.add(sale.saleId);
    });

    return uniqueSaleIds.size;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <strong>Qtd. Vendas:</strong>
        <input
          style={{
            width: '90px',
            height: '80px',
            borderRadius: '5px',
            border: 'none',
            textAlign: 'center',
          }}
          type="text"
          disabled
          value={countUniqueSaleId()}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <strong>Qtd Título:</strong>
        <input
          style={{
            width: '90px',
            height: '80px',
            borderRadius: '5px',
            border: 'none',
            textAlign: 'center',
          }}
          type="text"
          disabled
          value={sales.length}
        />
      </div>
    </div>
  );
}
