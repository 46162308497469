import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import RepresentativeComissionsMain from './components/RepresentativeComissionsMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import './styles.css'

const RepresentativeComissions = () => {

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Comissão de Representantes',
            ]}
            path={['home', null, null, null]}
          />
          <RepresentativeComissionsMain />
        </Row>
      </Grid>
    </div>
  )
}
export default RepresentativeComissions
