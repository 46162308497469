import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useRouter } from 'v2/hooks/useRouter';
import { deleteApproach } from 'v2/repositories/ApproachRepository';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir a abordagem? Ao excluir, não poderá
        vinculá-la a nenhum cliente e deverá cadastrar uma nova.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluída, não poderá ser recuperada.
      </span>
    </div>
  );
};

export function TableActions({ id, triggerFetch }) {
  const { navigateToClientPage } = useRouter();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteApproach(id);
      toastr.success(
        'Exclusão realizada com sucesso',
        'A abordagem foi excluída'
      );
      triggerFetch();
    } catch (err) {
      console.log(err);

      const alreadyInUse =
        err.response?.data?.message === 'This approach is already in use.';

      const title = alreadyInUse
        ? 'Abordagem vinculada a cliente'
        : 'Erro ao excluir';

      const message = alreadyInUse
        ? 'A abordagem está vinculada a cliente(s) e não poderá ser excluída. Inative-a caso não queira mais utilizá-la ou remova do(s) cliente(s).'
        : 'Por favor, tente novamente. Caso persista, contate o suporte.';

      toastr.error(title, message);
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex center gap-050">
      <button
        title="Editar"
        className="button-icon"
        onClick={() => navigateToClientPage('approach', 'form_edit_raw', id)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        title="Excluir"
        className="button-icon"
        onClick={() => setIsDeleteModalOpen(true)}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
