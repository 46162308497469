import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NFSeSettingsMain from './components/Main'

import './styles.css'

const NFSeSettings = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Configurações NFS-e']}
        path={['home', null]}
      />
      <NFSeSettingsMain />
    </>
  )
}

export default NFSeSettings
