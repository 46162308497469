import SearchInput from 'components/SearchInput';
import { format } from 'date-fns';
import React from 'react';
import { SelectBranding } from 'v2/components/Select';
import '../styles.css';

function PollsFilter({
  history,
  query,
  status,
  setStatus,
  type,
  setType,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  setBranding,
  branding,
  handleChangeQuery,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="polls-row">
        <div>
          <button
            className="btn btn-sucesso button-h35 fontSize-12"
            onClick={() => history.push('polls-register')}
          >
            + Nova Pesquisa
          </button>
        </div>
        <SearchInput
          containerStyle={{ width: 370 }}
          placeholder="Pesquisa por Descrição"
          value={query}
          onChange={(e) => handleChangeQuery(e.target.value)}
          style={{ fontSize: '12px' }}
        />
        <fieldset>
          <span className="typography__text-2">Status:</span>
          <select
            className="form-control foco-input fontSize-12"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
          >
            <option value="">Ambos</option>
            <option value="true">Ativas</option>
            <option value="false">Inativas</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Pesquisa:</span>
          <select
            className="form-control foco-input fontSize-12"
            onChange={(event) => setType(event.target.value)}
            value={type}
          >
            <option value="">Todas</option>
            <option value="1">NPS</option>
            <option value="2">NVS</option>
            <option value="3">MHV</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Produto:</span>
          <SelectBranding
            showAllOption
            variant={'column'}
            height={'35px'}
            value={branding}
            onChange={(e) => setBranding(e)}
            hideLabel={true}
            fontSizeInput={'12px'}
          />
        </fieldset>
      </div>

      <div className="polls-row">
        <fieldset>
          <span className="typography__text-2">Data Inicial:</span>
          <input
            className="form-control foco-input fontSize-12"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={'9999-12-31'}
            onChange={(e) => {
              setInitialDate(e.target.value);
            }}
          />
        </fieldset>

        <fieldset>
          <span className="typography__text-2">Data Final:</span>
          <input
            className="form-control foco-input fontSize-12"
            type="date"
            name="dataFinal"
            value={finalDate}
            min={initialDate && format(new Date(initialDate), 'yyyy-MM-dd')}
            max={'9999-12-31'}
            onChange={(e) => {
              setFinalDate(e.target.value);
            }}
          />
        </fieldset>
      </div>
    </div>
  );
}

export default PollsFilter;
