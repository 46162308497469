import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from 'client/components/CustomButton/CustomButton';

export function ModalSharePanelAccess({
  show,
  onCancel,
  onSubmit,
  setOpenWhatsAppConfirmationModal,
}) {
  return (
    <>
      <Modal show={show} onHide={onCancel} backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Compartilhamento de acesso com cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div className="flex gap-075 column">
              <span>
                <strong>
                  Você deseja enviar o link para que o cliente veja o andamento
                  do serviço?
                </strong>
              </span>
              <span>
                O cliente visualizará apenas o status do seu veículo e dessa OS.
                Você poderá compartilhar mais tarde através do botão
                compartilhar na tela da OS. Uma vez enviada para o cliente, não
                poderá ser bloqueado o acesso.
              </span>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-075">
            <CustomButton
              style={{
                width: '80px',
              }}
              bsStyle="danger"
              name="denied"
              onClick={onCancel}
              fill
            >
              Não
            </CustomButton>
            <CustomButton
              style={{
                width: '80px',
              }}
              bsStyle="info"
              name="approve"
              onClick={() => setOpenWhatsAppConfirmationModal(true)}
              fill
            >
              Sim
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
