import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import constants from '../../../../utils/constants'

import BundleFormPrismaBox from './BundleFormPrismaBox'

function NewPrismaBox({ match }) {
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Cadastro',
              'Vendas',
              'Prisma/Box',
              match.params.id ? 'Editar Prisma/Box' : 'Novo Prisma/Box',
            ]}
            path={[
              constants.ROUTES.HOME,
              null,
              null,
              constants.ROUTES.BOX_PRISMA,
              null,
            ]}
          />
          <BundleFormPrismaBox prismaboxId={match.params.id} />
        </Row>
      </Grid>
    </div>
  )
}

export default NewPrismaBox
