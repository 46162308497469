import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NFSePatternCitiesMain from './components/Main'

import './styles.css'
import { withRouter } from 'react-router'

const NFSePatternCities = ({ history, match }) => {
  const params = new URLSearchParams(window.location.search)
  const patternId = params.get('patternId')
  const patternName = params.get('patternName')

  return (
    <>
      <BreadCrumb
        data={['Início', 'Configurações NFS-e', patternName, 'Cidades']}
        path={['home', 'nfse-settings', null, null]}
      />
      <NFSePatternCitiesMain patternName={patternName} patternId={patternId} />
    </>
  )
}

export default withRouter(NFSePatternCities)
