import React from 'react';
import { Table } from 'v2/components/Table';
import { useGroupedInvoiceEmissionColumnsConfig } from '../hooks/useGroupedInvoiceEmissionColumnsConfig';
import { useFormContext } from 'react-hook-form';
import { useGroupedInvoiceEmission } from '../hooks/useGroupedInvoiceEmission';
import { GroupedInvoiceEmissionHeaderCount } from './GroupedInvoiceEmissionHeaderCount';
import styled from 'styled-components';
export function GroupedInvoiceEmissionTable() {
  const { watch, getValues, setValue } = useFormContext();

  const { configColumnsInvoiceEmission } =
    useGroupedInvoiceEmissionColumnsConfig(getValues, setValue);

  const { isLoading } = useGroupedInvoiceEmission(getValues, setValue);

  const listSaleInvoices = watch('listSaleInvoices');
  const selectedSaleInvoices = watch('selectedSaleInvoices') || [];

  return (
    <div
      style={{
        marginTop: '1.125rem',
        width: '99%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <GroupedInvoiceEmissionHeaderCount
        selectedSaleInvoices={selectedSaleInvoices}
      />
      <StyledTable
        columns={configColumnsInvoiceEmission}
        data={listSaleInvoices.rows || []}
        style={{
          padding: '0px',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '11px',
        }}
        loading={isLoading}
        cellStyle={{ padding: '0px' }}
      />
    </div>
  );
}
const StyledTable = styled(Table)`
  .ReactTable .rt-td {
    padding: 0px !important;
    max-width: 60px;
  }
`;
