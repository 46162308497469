import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useState } from 'react';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { kmMask } from 'client/components/ToNormalize/ToNormalize';

const ListRefusedItemsDocument = ({ responseData }) => {
  const [headerData] = useState(responseData.header);
  const [totalsData] = useState(responseData?.totals);
  const [reportData] = useState(responseData.report);

  return (
    <Document
      title={`listagem-de-itens-recusados-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="listagem-de-itens-recusados"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
            justifyContent: 'flex-start',
          }}
        >
          <View
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>
              Listagem de Itens Recusados (Orçamento Digital)
            </Text>
          </View>
          <Header headerData={headerData} />
        </View>

        {totalsData ? (
          <>
            <View style={styles.line} />
            <Totalizators totalsData={totalsData} />
          </>
        ) : (
          <View />
        )}
        <View style={styles.line} />
        <ReportListing reportData={reportData} />
      </Page>
    </Document>
  );
};

function FormatPercentage(number) {
  return (number * 100).toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
}

const Header = ({ headerData }) => {
  return (
    <>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <View
          style={{
            width: '30.7%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Pesquisa por: </Text>
            <Text>{headerData.searchBy}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Status de Vendas: </Text>
            <Text>{headerData.saleStatus}</Text>
          </View>
        </View>
        <View
          style={{
            width: '43%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Tipo de Data: </Text>
            <Text>{headerData.typeOfDate}</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Data Início: </Text>
            <Text>
              {format(
                getDateOnlyFromDate(new Date(headerData.initialDate)),
                'dd/MM/yyyy'
              )}
            </Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Text style={styles.strong}>Data Fim: </Text>
            <Text>
              {format(
                getDateOnlyFromDate(new Date(headerData.finalDate)),
                'dd/MM/yyyy'
              )}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const Totalizators = ({ totalsData }) => {
  return (
    <>
      <TotalizatorsHeader title={totalsData.title} />
      <TotalizatorsItems items={totalsData.items} />
      <TotalizatorsFinal
        title={totalsData.totalTitle}
        qtd={totalsData.totalQuantity}
        value={totalsData.totalValue}
        totalApprovedValue={totalsData.totalApprovedValue}
      />
    </>
  );
};
const TotalizatorsHeader = () => {
  return (
    <>
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Totais Recusados
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          Tipo
        </Text>
        <Text style={[styles.boldText, { width: '5%', textAlign: 'left' }]}>
          QTD
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          Total
        </Text>
      </View>
      <View style={styles.line} />
    </>
  );
};
const TotalizatorsItems = ({ items }) => {
  return (
    items &&
    items.map((item, key) => (
      <>
        <View
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'left',
            padding: '1px',
          }}
        >
          <View style={{ width: '10%', textAlign: 'left' }}>
            <Text style={styles.text}>{item.itemType}</Text>
          </View>
          <View style={{ width: '5%', textAlign: 'left' }}>
            <Text style={styles.text}>{item.quantity}</Text>
          </View>
          <View style={{ width: '10%', textAlign: 'left' }}>
            <Text style={styles.text}>{currency(item.total)}</Text>
          </View>
        </View>
      </>
    ))
  );
};
const TotalizatorsFinal = ({ title, qtd, value, totalApprovedValue }) => {
  return (
    <>
      <View style={[styles.line]} />
      <View
        style={{
          width: '100%',
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          {title}
        </Text>
        <Text style={[styles.boldText, { width: '5%', textAlign: 'left' }]}>
          {qtd}
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'left' }]}>
          {currency(value)}
        </Text>
      </View>
      <View
        style={{
          width: '100%',
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Text style={[styles.boldText, { width: '100%', textAlign: 'left' }]}>
          Valor total dos itens aprovados: {currency(totalApprovedValue)}
        </Text>
      </View>
    </>
  );
};

const ReportListing = ({ reportData }) => {
  return (
    <>
      <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
        Listagem de Vendas
      </Text>
      <View style={[styles.line, { width: '100%' }]} />
      <View style={{ marginBottom: '10px' }} />
      {reportData &&
        reportData.map((sale, key) => (
          <>
            <View style={{ margin: '10px 0' }}>
              <ListingHeader sale={sale} key={key} />
              <ListingItems items={sale.items} />
              <View style={[styles.line, { width: '100%' }]} />
            </View>
          </>
        ))}
    </>
  );
};

const ListingHeader = ({ sale, key }) => {
  return (
    <>
      <View style={{ width: '100%', flexDirection: 'column' }} key={key}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '17%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Dt. de Criação: </Text>
            </View>
            <View>
              <Text>
                {sale.saleCreatedAt &&
                  format(
                    getDateOnlyFromDate(new Date(sale.saleCreatedAt)),
                    'dd/MM/yyyy'
                  )}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '19%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Dt. de Fechamento: </Text>
            </View>
            <View>
              <Text>
                {sale.saleClosingDate ||
                  (sale.saleUpdatedAt &&
                    format(
                      getDateOnlyFromDate(
                        new Date(sale.saleClosingDate || sale.saleUpdatedAt)
                      ),
                      'dd/MM/yyyy'
                    ))}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '30%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Cliente: </Text>
            </View>
            <View>
              <Text>
                {sale.customerInfo?.length > 40
                  ? `${sale.customerInfo.substring(0, 40)}...`
                  : sale.customerInfo || ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '25%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Veículo: </Text>
            </View>
            <View>
              <Text>
                {sale.vehicle?.length > 25
                  ? `${sale.vehicle.substring(0, 25)}...`
                  : sale.vehicle || ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '10%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>KM: </Text>
            </View>
            <View>
              <Text>
                {sale.saleKilometers ? kmMask(String(sale.saleKilometers)) : 0}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '15%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Venda/OS: </Text>
            </View>
            <View>
              <Text>{sale.saleCode}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '18%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Total Venda: </Text>
            </View>
            <View>
              <Text>
                {currency(sale.saleApprovedAmount + sale.saleRefusedAmount)}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '18%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Valor Aprovado: </Text>
            </View>
            <View>
              <Text>{currency(sale.saleApprovedAmount)}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: '18%',
              justifyContent: 'flex-start',
            }}
          >
            <View>
              <Text style={styles.strong}>Valor Recusado: </Text>
            </View>
            <View>
              <Text>{currency(sale.saleRefusedAmount)}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
const ListingItems = ({ items, key }) => {
  return (
    <>
      <ListingItemsTitle />
      {items &&
        items.map((item) => <ListingItemsContent item={item} key={key} />)}
    </>
  );
};
const ListingItemsTitle = () => {
  return (
    <>
      <View style={[styles.line, { width: '100%' }]} />
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Código</Text>
        </View>
        <View
          style={{
            width: '5%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Tipo</Text>
        </View>
        <View
          style={{
            width: '23%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Descrição</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Marca</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Quantidade</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Preço Unit</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Desc. Unit %</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>Total</Text>
        </View>
        <View
          style={{
            width: '12%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldText, { width: '100%' }]}>
            Data/Hora da Recusa
          </Text>
        </View>
      </View>
      <View style={[styles.line, { width: '100%' }]} />
    </>
  );
};
const ListingItemsContent = ({ item, key }) => {
  return (
    <>
      <View
        key={key}
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '2px 0',
        }}
      >
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.saleItemCode}
          </Text>
        </View>
        <View
          style={{
            width: '5%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.boldTextOfType, { width: '100%' }]}>
            {item.saleItemType === 'Produto' ? 'P' : 'S'}
          </Text>
        </View>
        <View
          style={{
            width: '23%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.saleItemDescription}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.saleItemBrand ? item.saleItemBrand : '-'}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.saleItemQuantity}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.saleItemUnitValue)}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {FormatPercentage(item.saleItemUnitDiscountValue)}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {currency(item.saleItemAmount)}
          </Text>
        </View>
        <View
          style={{
            width: '12%',
            textAlign: 'left',
          }}
        >
          <Text style={[styles.text, { width: '100%' }]}>
            {item.saleItemCreatedAt &&
              format(new Date(item.saleItemCreatedAt), 'dd/MM/yyyy HH:mm:ss')}
          </Text>
        </View>
      </View>
    </>
  );
};

export default ListRefusedItemsDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  boldTextOfType: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
