import * as yup from 'yup';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Grupos de Itens',
      `${isEditing ? 'Editar' : 'Novos'} Grupos`,
    ],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.GROUP_ITEMS.BASE,
      null,
    ],
  };
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
});
