import api from '../services/api'
import config from '../config'

const index = async (companyId) => {
  const response = await api.get(config.endpoint + 'settings-nfse', {
    params: { companyId },
  })
  return response
}

const verifyAvailability = async (companyId) => {
  const response = await api.post(
    config.endpoint + 'settings-nfse/validate-pattern',
    {
      companyId: companyId,
    }
  )
  return response.data
}

const search = async (values) => {
  const response = await api.post(
    config.endpoint + 'settings-nfse/search',
    values
  )
  return response.data
}

const create = async (values) => {
  const response = await api.post(config.endpoint + 'settings-nfse', values)
  return response.data
}

const update = async (id, values) => {
  await api.put(config.endpoint + `settings-nfse/${id}`, values)
}

const getOperationTaxesByNFSPattern = async (NFSPatternId) => {
  const response = await api.get(
    config.endpoint + `nfse-operation-taxes?NFSPatternId=${NFSPatternId}`
  )
  return response.data
}

const settingsNFSeRepository = {
  index,
  create,
  update,
  search,
  verifyAvailability,
  getOperationTaxesByNFSPattern,
}

export default settingsNFSeRepository
