import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { Col } from 'react-bootstrap';
import cashierBankRepository from '../../../../../repositories/CashierBank';
import { toastr } from 'react-redux-toastr';
import { encrypt } from '../../../../components/ToNormalize/ToNormalize';
import PDFButton from 'client/components/PDFButton';
import { SelectMulti } from 'v2/components/Select/Multi';

const CashierBankByDate = () => {
  const { companyId, company } = useAuth();

  const [cashierBanks, setCashierBanks] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [showSaleItems, setShowSaleItems] = useState(false);
  const [showSaleVehicles, setShowSaleVehicles] = useState(false);
  const [showTotalPaymentMethod, setShowTotalPaymentMethod] = useState(true);
  const [showPurchaseItems, setShowPurchaseItems] = useState(false);
  const [selectedBanks, setSelectedBanks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await loadCashierBanks();
    };

    fetchData();
  }, []);

  const loadCashierBanks = async () => {
    try {
      const response =
        await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );

      const transformedOptions = response.map((item) => ({
        label: `${item.CashierBankType.initials} - ${item.description}`,
        value: item.id,
      }));
      transformedOptions.unshift({
        label: 'Todos',
        value: '*',
      });
      setCashierBanks(transformedOptions);
      if (!!company && !!transformedOptions) {
        const defaultOption = transformedOptions.find(
          (option) => option.value === company.defaultCashierBankId
        );
        setSelectedBanks([defaultOption]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const generateReport = async () => {
    if (selectedBanks.length === 0)
      return toastr.warning(
        'Selecione ao menos 1 caixa/banco para gerar o relatório.'
      );

    if (!initialDate || !finalDate)
      return toastr.warning('Preencha ambos campos de data.');

    if (finalDate < initialDate)
      return toastr.warning('Data final deve ser maior que a data inicial');

    const cashierBanksToReport = selectedBanks
      .map((item) => item.value)
      .filter((value) => value !== '*');

    const cashierBanksToReportHash = encrypt(
      JSON.stringify(cashierBanksToReport),
      '@OS-dig:cashierBanks'
    );

    window.open(
      window.location.origin +
        `/report/cashier-bank-flow?cashierBanks=${cashierBanksToReportHash}&initialDate=${initialDate}&finalDate=${finalDate}&showTotalPaymentMethod=${showTotalPaymentMethod}&showSaleItems=${showSaleItems}&showSaleVehicles=${showSaleVehicles}&showPurchaseItems=${showPurchaseItems}`,
      '_blank'
    );
  };

  return (
    <Col
      style={{
        margin: '30px',
        minHeight: '110px',
        marginLeft: '15px',
        gap: '15px',
      }}
    >
      <div style={{ display: 'flex', marginBottom: '5px', gap: '15px' }}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <label>Caixa/Banco:</label>
            <SelectMulti
              width={150}
              height={35}
              options={cashierBanks}
              value={selectedBanks}
              onChange={(e) => setSelectedBanks(e)}
              hasAllOption
            />
          </div>
        </div>

        <div>
          <label>Data Inicial:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{
              width: '160px',
            }}
          />
        </div>
        <div>
          <label>Data Final:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{
              width: '160px',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <PDFButton height={35} width={150} onClick={generateReport} />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '15px' }}>
        <div>
          <label>Itens das Vendas/OS</label>
          <select
            value={showSaleItems}
            onChange={(e) => setShowSaleItems(e.target.value)}
            className="form-control foco-input"
            style={{
              width: '100%',
            }}
          >
            <option value={true}>Exibir</option>
            <option value={false}>Não Exibir</option>
          </select>
        </div>
        <div>
          <label>Veículos das Vendas/OS</label>
          <select
            value={showSaleVehicles}
            onChange={(e) => setShowSaleVehicles(e.target.value)}
            className="form-control foco-input"
            style={{
              width: '100%',
            }}
          >
            <option value={true}>Exibir</option>
            <option value={false}>Não Exibir</option>
          </select>
        </div>
        <div>
          <label>Itens das Compras</label>
          <select
            value={showPurchaseItems}
            onChange={(e) => setShowPurchaseItems(e.target.value)}
            className="form-control foco-input"
            style={{
              width: '100%',
            }}
          >
            <option value={true}>Exibir</option>
            <option value={false}>Não Exibir</option>
          </select>
        </div>
        <div>
          <label>Totais por Forma de Pagamento</label>
          <select
            value={showTotalPaymentMethod}
            onChange={(e) => setShowTotalPaymentMethod(e.target.value)}
            className="form-control foco-input"
            style={{
              width: '100%',
            }}
          >
            <option value={true}>Exibir</option>
            <option value={false}>Não Exibir</option>
          </select>
        </div>
      </div>
    </Col>
  );
};

export default CashierBankByDate;
