import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NewTradeRepresentativeMain from './components/Main'

import './index.css'

export default function NewTradeRepresentative({ match }) {
  return (
    <div className="content">
      <Grid fluid>
        <span id="required-field-label">* Campos Obrigatórios</span>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Meus Representantes',
              `${match?.params?.id ? 'Editar' : 'Novo'} Representante`,
            ]}
            path={['home', 'trade-representatives', null]}
          />
        </Row>
      </Grid>

      <NewTradeRepresentativeMain tradeRepresentativeId={match?.params?.id} />
    </div>
  )
}
