import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import BoletoFlexConfigurationMain from './components/BoletoFlexConfigurationsMain'

import './styles.css'

const BoletoFlexConfigurations = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Integrações',
          'Boleto Flex',
          match.params.id ? 'Editar Cliente' : 'Novo Cliente',
        ]}
        path={['home', null, 'boleto-flex', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <BoletoFlexConfigurationMain
        boletoFlexConfigurationId={match.params.id}
        goToHomePage={() => history.push('boleto-flex')}
      />
    </>
  )
}

export default withRouter(BoletoFlexConfigurations)
