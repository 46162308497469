import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
// import Revisao from 'components/Cadastros/FormRevisao'
import FormReview from './NewReview'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

class NewReview extends Component {

  componentWillMount() {
    localStorage.setItem('selectedPath', 'client/review')
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <span id='required-field-label'>* Campos Obrigatórios</span>
              <BreadCrumb data={['Início', 'Cadastros', 'Produtos/Serviços', 'Revisão', this.props.match.params.id ? 'Editar Revisão' : 'Nova Revisão']}
                path={['home', null, null, 'revisions', null]} />

              <FormReview newReview={this.props.match.params.id ? false : true} ReviewId={this.props.match.params.id} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NewReview;
