import React from "react";
import { Grid, Row } from "react-bootstrap";
import PartsPerApplicationMain from './components/PartsPerApplicationMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from 'utils/constants'

const PartsPerApplication = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Produtos', 'Peças por Aplicação']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <PartsPerApplicationMain />
        </Row>
      </Grid>
    </div>
  )
}

export default PartsPerApplication;