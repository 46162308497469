import React from 'react'

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import Home from './Home'

const TicketIssuanceIndex = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Integrações', 'Boleto Bancário']}
        path={['home', null, null]}
      />
      <Home />
    </>
  )
}

export default TicketIssuanceIndex
