import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: ['Início', 'Meus Perfis de Acesso', 'Permissões e Acessos'],
    path: [
      '/client/home',
      '/client/profiles',
      '/client/' + CLIENT_PATH_ROUTES.ACCESS_PROFILE.BASE,
    ],
  };
};

export const defaultFormValues = {
  profileId: '',
  profileDescription: '',
  isMaster: false,
  status: true,
  groupFeatures: [],
};
