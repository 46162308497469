import React from 'react'
import Dropzone from 'react-dropzone'
import UploadContainer from './UploadContainer'

export default function DropPhotoContainer(props) {
  return (
    <Dropzone accept="image/*" onDropAccepted={props.onUpload}>
      {(dropzoneProps) => <UploadContainer {...dropzoneProps} {...props} />}
    </Dropzone>
  )
}
