import { filterTransactions } from 'v2/views/client/BankConciliation/utils/bankConciliationDetailsFilterSearch';
import { create } from 'zustand';
import { toastr as toast } from 'react-redux-toastr';
import { BankConciliationRepositoryV2 } from 'v2/repositories/BankConciliationRepository';

const initialState = {
  bankConciliation: {
    file: undefined,
    dataOFX: {
      isValid: undefined,
      headerFile: undefined,
      message: '',
      transactions: [],
    },
  },
  selectedBank: undefined,
  selectedTransactions: [],
  selectedTransactionsConciliation: [],
  financialRegisterTitle: [],
};

export const useBankConciliationStore = create((set, get) => ({
  ...initialState,

  setFileAndData: (file, dataOFX, isSelectedBank) => {
    set((state) => ({
      bankConciliation: {
        ...state.bankConciliation,
        file,
        dataOFX: {
          ...dataOFX, // Mantém os valores anteriores de dataOFX
          isSelectedBank, // Adiciona ou atualiza o isSelectedBank no dataOFX
        },
      },
    }));
  },

  filterTransactions: (getValues, prefix) => {
    const { bankConciliation } = get();
    const { transactions } = bankConciliation.dataOFX;

    if (transactions.length === 0) return;

    const filteredTransactions = filterTransactions(
      transactions,
      getValues,
      prefix
    );

    set((state) => ({
      bankConciliation: {
        ...state.bankConciliation,
        dataOFX: {
          ...state.bankConciliation.dataOFX,
          transactions: filteredTransactions,
        },
      },
    }));
  },
  filterRemoveConciliation: (transactionId, status) => {
    const { bankConciliation } = get();
    const { transactions } = bankConciliation.dataOFX;

    if (transactions.length === 0) return;

    const updatedTransactions = transactions.map((transaction) => {
      if (transaction.id === transactionId) {
        return { ...transaction, conciled: status };
      }
      return transaction;
    });

    set((state) => ({
      bankConciliation: {
        ...state.bankConciliation,
        dataOFX: {
          ...state.bankConciliation.dataOFX,
          transactions: updatedTransactions,
        },
      },
    }));
  },

  loadFinancialTitle: async (
    companyId,
    TransactionInitialDate,
    TransactionFinishDate,
    description,
    transactionType,
    transactionStatus,
    currentPage,
    pageLimit
  ) => {
    try {
      const type =
        transactionType?.label === 'Ambos' ? '' : transactionType?.label;
      const status = transactionStatus?.id === 0 ? '' : transactionStatus?.id;

      const getBills = await BankConciliationRepositoryV2.getBills({
        initialDate: TransactionInitialDate,
        finalDate: TransactionFinishDate,
        companyId: companyId,
        page: currentPage + 1,
        limit: pageLimit,
        planId: '',
        status: status,
        query: description,
        type: type,
      });

      set(() => ({
        financialRegisterTitle: getBills,
      }));

      return {
        data: getBills,
      };
    } catch (err) {
      toast.warning(
        'Ocorreu um erro ao carregar os títulos. Por favor, tente novamente'
      );
    }
  },

  reset: () => set({ ...initialState }),
}));
