import React, { useEffect, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

import AlertModal from 'components/AlertModal/AlertModal';
import InputNumberDecimal from '../../../InputNumberDecimal';
import { currency } from '../../../ToNormalize/ToNormalize';
import InputUnitValue from '../../../InputUnitValue';
import { toastr } from 'react-redux-toastr';

import './styles.css';

const SaleSheet = ({ items, setItems }) => {
  const [total, setTotal] = useState(0);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);

  useEffect(() => {
    const total = items
      .map((item) => item.Quantity * item.Unit_Value)
      .reduce((prev, curr) => prev + curr, 0);

    setTotal(total);
  }, [items]);

  function handleRemoveItem() {
    setIsRemoveItemModalOpen(false);
    const newItems = [...items].filter((_, i) => i !== indexToRemove);
    setItems(newItems);
  }

  function handleOpenRemoveItemModal(index) {
    setIndexToRemove(index);
    setIsRemoveItemModalOpen(true);
  }

  function handleQuantity(value, index) {
    const newItems = [...items];
    if (value <= 9999) {
      newItems[index].Quantity = value;
      setItems(newItems);
    }
  }

  const handleUnityValue = (e, index) => {
    const value = e.target.value
      ?.replace('R$ ', '')
      ?.replace('.', '')
      ?.replace(',', '.');
    const newItems = [...items];

    if (Number(value) === 0)
      return toastr.warning('Não é possível informar um valor R$ 0,00.');

    if (Number(value) > 0) {
      newItems[index].Unit_Value = Number(value);
      setItems(newItems);
    }
  };

  return (
    <Col className="folha-consulta-preco flex column between">
      <div>
        <table id="table-consulta-preco">
          <thead>
            <tr className="flex between">
              <th style={{ width: '150px' }}>Descrição</th>
              <th style={{ width: '100px' }}>Qtde.</th>
              <th style={{ width: '90px' }}>R$ Unit.</th>
              <th style={{ width: '90px' }}>R$ Total</th>
              <th style={{ width: '20px' }} />
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr className="flex between align-center" key={index}>
                <td
                  style={{
                    fontSize: '12px',
                    width: '160px',
                    overflowWrap: 'break-word',
                  }}
                >
                  {item.Description}
                </td>
                <td style={{ fontSize: '12px', width: '100px' }}>
                  <InputNumberDecimal
                    id="qtd-cotacao"
                    onChange={(e) => handleQuantity(e, index)}
                    value={item.Quantity}
                  />
                </td>
                <td>
                  <InputUnitValue
                    style={{
                      fontSize: '12px',
                      width: '90px',
                      textAlign: 'center',
                    }}
                    onChangeEvent={(e) => handleUnityValue(e, index)}
                    value={item.Unit_Value}
                  />
                </td>
                <td style={{ fontSize: '12px', width: '90px' }}>
                  {currency(item.Unit_Value * item.Quantity)}
                </td>
                <td style={{ width: '20px' }}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: 'red' }}
                    cursor="pointer"
                    onClick={() => handleOpenRemoveItemModal(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="folha-consulta-precos-totais-wrapper">
        <div className="folha-consulta-precos-totais">
          <strong>Itens: </strong>
          {items.length}
          <br />
          <strong>Valor Total: </strong>
          {currency(total)}
        </div>
      </div>

      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        message="Deseja realmente excluir o item ?"
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />
    </Col>
  );
};

export default SaleSheet;
