import api from '../services/api';

const getAll = async (brandingId) => {
  const response = await api.get('plans', {
    params: {
      brandingId,
    },
  });
  return response.data;
};

const plansRepository = {
  getAll,
};

export default plansRepository;
