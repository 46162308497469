import React, { createContext, useState, useContext } from 'react'

export const BannerContext = createContext({
  isAdvertisementOpen: true,
})

export default function BannerProvider({ children }) {
  const [isAdvertisementOpen, setIsAdvertisementOpen] = useState(false)

  return (
    <BannerContext.Provider
      value={{
        isAdvertisementOpen,
        setIsAdvertisementOpen,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}

export function UseBannerContext() {
  return useContext(BannerContext)
}
