import React from 'react';
import Toggle from 'react-toggle';
import {
  Container,
  TextContainer,
  Text,
  ToggleContainer,
} from './SwitchRow.styles';

export const SwitchRow = ({
  title,
  subTitle,
  checked,
  disabledToggle,
  onChange,
  width,
  style,
  boldSubtitle,
  boldTitle = true,
  radius,
  borderTop,
}) => {
  return (
    <Container
      width={width}
      style={style}
      radius={radius}
      borderTop={borderTop}
    >
      <TextContainer subTitle={subTitle}>
        <Text bold={boldTitle}>{title}</Text>
        {subTitle && <Text bold={boldSubtitle}>{subTitle}</Text>}
      </TextContainer>
      <ToggleContainer>
        <Toggle
          id="isActive"
          checked={checked}
          onChange={onChange}
          disabled={disabledToggle}
          icons={null}
        />
      </ToggleContainer>
    </Container>
  );
};
