import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';

import { Table } from 'v2/components/Table';

import Companies from 'repositories/Companies';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { ActiveStatusLabel } from 'v2/components/ActiveStatusLabel';
import { InputPassword } from 'v2/components/Input/InputPassword';

export const ClientUsersModal = ({ companyId, isOpen, handleClose }) => {
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);

  const loadCompanyUsers = async () => {
    try {
      const companyUsers = await Companies.getAllUsers(companyId);
      setUsers(companyUsers);
      setIsLoadingUsers(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os usuários',
        'Por favor, tente novamente.'
      );
    }
  };

  const tableColumns = [
    {
      Header: 'Nome',
      accessor: 'Name',
    },
    {
      Header: 'E-mail',
      accessor: 'Email',
    },
    {
      Header: 'Telefone',
      accessor: 'Phone',
      Cell: (props) => {
        return phoneMask(props.value);
      },
    },
    {
      Header: 'Perfil de Acesso',
      accessor: 'UserType.Description',
    },
    {
      Header: 'Último Acesso',
      accessor: 'lastLogin',
      Cell: (props) => {
        return format(new Date(props.value), 'dd/MM/yyyy HH:mm');
      },
    },
    {
      Header: 'Status',
      accessor: 'IsActive',
      width: 90,
      Cell: (props) => {
        return (
          <div className="flex center w-100 h-100">
            <ActiveStatusLabel isActive={props.value} />
          </div>
        );
      },
    },
    {
      Header: 'Senha',
      accessor: 'User_Password',
      width: 115,
      Cell: (props) => {
        return (
          <InputPassword value={props.value} onChange={null} height={30} />
        );
      },
    },
  ];

  useEffect(() => {
    loadCompanyUsers();
  }, []);

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-70w">
      <ModalHeader>
        <ModalTitle>
          <strong>Usuários</strong>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isLoadingUsers ? (
          <div className="flex center">
            <span className="fa fa-spinner fa-pulse"></span>
          </div>
        ) : (
          <div>
            <Table fontSize={'1.2rem'} data={users} columns={tableColumns} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="flex end">
          <button className="button button-red" onClick={handleClose}>
            Fechar
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
