import React, { useEffect, useState } from "react"

import QuotationItems from "./QuotationItems"
import QuotationProviders from "./QuotationProviders"
import QuotationFooter from "./QuotationFooter"

import { toastr } from 'react-redux-toastr'

import { useForm, FormProvider } from "react-hook-form"

import QuotationRepository from "repositories/Quotations"
import { useAuth } from "contexts/auth"

export default function QuotationRegisterMain({ quotationId }) {	
  
  const { companyId, company } = useAuth()

  const methods = useForm({
    defaultValues: {
      quotation: {},   
      items: [],
      removedItems: [],
      providers: [],
      removedProviders: [],
      companyId,
      loadingProvidersList: false,
      isEditing: false,
      quotationToEdit: {},
      companyName: company.Company_Name,
    }
  })	
	
	const [quotationData, setQuotationData] = useState(null)

  async function loadQuotationData() {
    methods.setValue("loadingProvidersList", true)
    methods.setValue("loadingItemsList", true)
    methods.setValue("isEditing", true)
		try {
      const quotation = await QuotationRepository.getById(quotationId, companyId)		
      methods.setValue('quotationToEdit', quotation.data[0])
      methods.setValue('quotation', {
        id: quotation.data[0].id,
        code: quotation.data[0].code,
        status: quotation.data[0].status,
        companyId: quotation.data[0].companyId,
      })
      methods.setValue('observations', quotation.data[0].observations)
		} catch (err) {
      console.log(err)
			toastr.error("Falha ao carregar dados da cotação", "Tente novamente.")    
    } finally {
      methods.setValue('loadingProvidersList', false)
      methods.setValue("loadingItemsList", false)
    }
	}

	useEffect(() => {
		if (quotationId) {
			loadQuotationData()
		}
	}, [quotationId])
	
	return (
    <div className='quotation-register-main'>
      <FormProvider {...methods}>
        <form>
          <QuotationProviders />
          <QuotationItems
            isEditing={quotationData !== null}
            quotationData={quotationData}
          />
          <QuotationFooter
            isEditing={quotationData !== null}
            quotationData={quotationData}
          />
        </form>
      </FormProvider>
    </div>
  )
}