import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import { initialize, change } from 'redux-form'

import FormUsuario from './FormUsuario'
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'

import { phoneMask } from 'client/components/ToNormalize/ToNormalize'

import usersRepository from '../../../../repositories/Users'
import companyUsersRepository from '../../../../repositories/CompanyUsers'
import signaturesRepository from '../../../../repositories/Signatures'
import companiesRepository from '../../../../repositories/Companies'
import constants from '../../../../utils/constants'
import { usePlanSignatureContext } from '../../../../contexts/plan-signature'
import { useAuth } from '../../../../contexts/auth'

const BundleFormUser = ({ userId, history, modal, handleModal, hideModal, employeeId }) => {
  const [loading, setLoading] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [activeUsersQuantity, setActiveUsersQuantity] = useState(0)
  const [users, setUsers] = useState([])

  const { signature } = usePlanSignatureContext()
  const { companyId } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!userId) {
      loadUser()
    }
  }, [userId])

  useEffect(() => {
    if (!!companyId) getActiveUsers()
  }, [companyId])

  useEffect(() => {
    if (!!employeeId) {
      dispatch(change('formUser', 'employeeId', employeeId))
    }
  }, [employeeId])

  const getActiveUsers = async () => {
    try {
      const users = await companiesRepository.getAllUsers(companyId)
      const usersAtivo = users.filter(user => user.IsActive)

      setActiveUsersQuantity(usersAtivo.length)
      setUsers(users)
    } catch (err) {
      setActiveUsersQuantity(1)
    }
  }

  const loadUser = async () => {
    setLoading(true)

    try {
      const user = await usersRepository.getById(userId)

      const obj = {
        ...user,
        Password: user.User_Password,
        Phone: phoneMask(user.Phone),
        IsActive: Boolean(user.IsActive)
      }

      dispatch(initialize('formUser', obj))

    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar o usuário. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const validates = async values => {
    const { Name, Email, Phone, Password, IsActive, Password2, profileId } = values

    if (!userId) {
      if (!Name || !Email || !Phone || !Password || !Password2 || !profileId ) {
        toastr.warning("Por favor, preencha todos os campos para continuar")
        return false
      }

      if (!Email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
        toastr.warning("Por favor, informe o e-mail corretamente.")
        return false
      }

      try {
        await usersRepository.getByEmail(Email)
        toastr.warning('E-mail já cadastrado. Por favor, informe outro e-mail.')
        return false
      }
      catch (err) {
        console.log(err)
      }

      if (Name.length < 3) {
        toastr.warning('Por favor, informe o nome corretamente.')
        return false
      }

      if (Password.length < 6 || Password2.length < 6) {
        toastr.warning('A senha deve ter pelo menos 6 caracteres.')
        return false
      }

      if (Password !== Password2) {
        toastr.warning('As senhas não coincidem.')
        return false
      }
    } else if (!Phone) {
      toastr.warning("Por favor, preencha todos os campos para continuar")
      return false
    }

    const phone = onlyNumbers(Phone)
    if (phone.length < 10) {
      toastr.warning("Por favor, informe o telefone corretamente.")
      return false
    }

    if (String(users[0]?.id) === userId && !IsActive) {
      toastr.warning('Este usuário não pode ser inativado.')
      return false
    }

    if (!userId && IsActive && activeUsersQuantity === signature.usersQuantity) {
      toastr.warning('Quantidade total de usuários ativos atingida.')
      return false
    }

    const localStorageUserId = localStorage.getItem('ID_USUARIO')
    if (String(localStorageUserId) === String(userId) && !IsActive) {
      toastr.warning('Usuário em uso.')
      return false
    }

    return true
  }

  const submit = async values => {
    setLoading(true)

    const isValid = await validates(values)
    if (!isValid) {
      setLoading(false)
      return
    }

    if (!!userId) {
      await update(values)
    } else {
      await create(values)
    }
    setLoading(false)
  }

  const update = async values => {
    const { Name, Email, Phone, IsActive, Password, employeeId, profileId } = values
    const phone = onlyNumbers(Phone)

    try {

      await usersRepository.update(userId, {
        Name,
        Email,
        Phone: phone,
        IsActive,
        User_Password: Password,
        employeeId: employeeId === '' ? null : employeeId,
        profileId: profileId === '' ? null : profileId
      })

      if (modal) {
        return handleModal(true)
      }

      toastr.success('Usuário atualizado com sucesso.')
      history.push(constants.ROUTES.USERS)

    } catch (err) {
      toastr.warning(err.response?.data?.message || 'Ocorreu um erro ao atualizar o usuário. Por favor, tente novamente')
    }
  }

  const create = async values => {
    const { Name, Email, Phone, IsActive, Password, employeeId, profileId } = values
    const phone = onlyNumbers(Phone)

    try {
      const user = await usersRepository.create({
        Name,
        Email,
        Phone: phone,
        IsActive,
        User_Password: Password,
        UserType_id: 2,
        employeeId,
        profileId
      })

      await companyUsersRepository.create({
        Company_id: companyId,
        User_id: user.id
      })

      if (modal) {
        return handleModal(true)
      }

      toastr.success('Usuário cadastrado com sucesso.')
      history.push(constants.ROUTES.USERS)
    }
    catch (err) {
      toastr.warning(err.response?.data?.message || 'Ocorreu um erro ao atualizar o usuário. Por favor, tente novamente')
    }
  }

  return (
    <FormUsuario
      onSubmit={submit}
      userId={userId}
      initialValues={{
        IsActive: true,
        quantUser: 0
      }}
      loading={loading}
      modal={modal}
      hideModal={hideModal}
      handleModal={handleModal}
    />
  )
}
export default withRouter(BundleFormUser)