export const getDefaultText = (type, sale) => {
  if (type === 'Orçamento' || type === 'Orçamento Rápido') {
    return sale.Company.defaultOrcamentFooterText;
  } else if (
    type === 'Ordem de Serviço' ||
    type === 'Venda' ||
    type === 'PDV'
  ) {
    return sale.Company.defaultSalesOrderFooterText;
  }
};
