import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { sidebarData } from './sidebarData'
import AccessDeniedModal from '../AccessDeniedModal'

import { useAdminAuth } from '../../../contexts/adminAuth'

import constants from '../../../utils/constants'

import './styles.css'
import { useEffect } from 'react'

const SubMenuItem = ({ item, degree, onNavigate }) => {
  const { adminRoleId } = useAdminAuth()
  const newDegree = degree + 1

  const doNotHavePermission =
    item.role === constants.ADMIN_ROLES.ADMINISTRATOR &&
    adminRoleId !== constants.ADMIN_ROLES.ADMINISTRATOR

  return (
    <>
      <ul
        style={{
          color: doNotHavePermission ? '#aaaaaa' : '#bbd5de',
        }}
        onClick={() => onNavigate(item)}
      >
        {item.title}
        {!!item.list && (
          <div className={`admin-sub-menu-items admin-degree-${degree}`}>
            {item.list.map((listItem, i) => (
              <SubMenuItem
                key={i}
                onNavigate={onNavigate}
                item={listItem}
                degree={newDegree}
              />
            ))}
          </div>
        )}
      </ul>
    </>
  )
}

const SubMenu = ({ item, onNavigate }) => {
  const [height, setHeight] = useState('')

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    const height = window.innerHeight
    setHeight(height)
  }

  function handleGetMarginTop(item) {
    if (height <= 768) {
      if (item.title === 'Nota Fiscal de Serviço') {
        return '-60px'
      }
    }
    return 0
  }

  return (
    <div
      className="admin-sub-menu"
      style={{
        marginTop: handleGetMarginTop(item),
      }}
    >
      <header>{item.title}</header>
      {item.list.map((listItem, i) => (
        <SubMenuItem
          key={i}
          item={listItem}
          degree={0}
          onNavigate={onNavigate}
        />
      ))}
    </div>
  )
}

const SideBarItem = ({ item, active, onConsult, onNavigate }) => {
  const { adminRoleId } = useAdminAuth()

  const doNotHavePermission =
    item.role === constants.ADMIN_ROLES.ADMINISTRATOR &&
    adminRoleId !== constants.ADMIN_ROLES.ADMINISTRATOR

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false)

  function handleNavigate(childItem) {
    if (doNotHavePermission) return setShowAccessDeniedModal(true)

    const newPath = '/client/' + childItem.path
    if (!childItem.path || window.location.pathname === newPath) {
      return
    }

    onNavigate(childItem)
  }

  return (
    <>
      <section
        className={`admin-side-bar-item ${
          active === item.active ? 'admin-side-bar-item-active' : ''
        }`}
        onClick={() =>
          item.title === 'Orçamento Rápido' ? onConsult() : handleNavigate(item)
        }
        title={item.title}
      >
        <FontAwesomeIcon
          icon={item.icon}
          style={{
            color: doNotHavePermission ? '#aaaaaa' : '#bbd5de',
          }}
        />
        <p
          style={{
            color: doNotHavePermission ? '#aaaaaa' : '#bbd5de',
          }}
        >
          {item.title}
        </p>
        {!!item.subMenu && (
          <SubMenu item={item.subMenu} onNavigate={handleNavigate} />
        )}
      </section>
      <AccessDeniedModal
        show={showAccessDeniedModal}
        onCancel={() => setShowAccessDeniedModal(false)}
      />
    </>
  )
}

const Sidebar = ({ history, setActive, active }) => {
  function handleNavigate(item) {
    history.push(item.path)
    setActive(item.active)
  }

  return (
    <>
      <aside id="admin-side-bar">
        {sidebarData.map((item, i) => (
          <SideBarItem
            key={i}
            item={item}
            onNavigate={handleNavigate}
            active={active}
          />
        ))}
      </aside>
    </>
  )
}

export default withRouter(Sidebar)
