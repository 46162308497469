import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from "../../client/components/CustomButton/CustomButton";

import './styles.css'

const UserSessionClosedModal = ({ show, onSubmit }) => {
  return (
    <Modal
      show={show}
      animation
      dialogClassName='user-session-closed-modal'
    >
      <Modal.Header>
        <Modal.Title>
          <strong>OS DIGITAL</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Houve o acesso com esse e-mail em outro navegador ou computador. A sessão atual foi encerrada.</p>
        <p>Caso não tenha sido você, valide internamente e, se necessário, troque a sua senha.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" name="Não" onClick={onSubmit} fill>Voltar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserSessionClosedModal