import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  currency,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const HeaderTotalsPerFamilyOrBrand = ({ selectedListBy }) => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>{selectedListBy === 'family' ? 'Família' : 'Marca'}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Quantidade</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowTotalsPerFamilyOrBrand = ({ description, quantity, totalsValueProduct }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{description}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}>
      <Text style={styles.span}>{quantity}</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(totalsValueProduct)}</Text>
    </View>
  </View>
)

const QuotesHeader = ({ description, selectedListBy }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      width: '70%'
    }}
  >
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemLabel label={selectedListBy === 'family' ? 'Família' : 'Marca'} value={description} />
    </View>
  </View>
)

const ItemsHeaderQuotes = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Código</Text>
    </View>
    <View style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descrição</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Peso</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Dt. Atualiz.</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Dt. Compra</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Margem</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Estoque</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>R$ Compra</Text>
    </View>
    <View style={{ width: '12%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total Compra</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>R$ Venda</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total Venda</Text>
    </View>
  </View>
)

const ItemRowQuotes = ({ item, lastDatePurchase }) => {
  return (
    <View
      style={{ flexDirection: 'row', height: 12, width: '100%' }}
      wrap={false}
    >
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Code}</Text>
      </View>
      <View
        style={{ width: '25%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Description}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Weight}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.updatedAt ? format(new Date(item.updatedAt), 'dd/MM/yyyy') : ''}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{lastDatePurchase ? format(new Date(lastDatePurchase), 'dd/MM/yyyy') : ''}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{`${item.Profit_Margin}` + `%`}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Stock_Quantity}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Purchase_Price)}</Text>
      </View>
      <View
        style={{ width: '12%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Stock_Quantity * item.Purchase_Price)}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Sale_Price)}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Stock_Quantity * item.Sale_Price)}</Text>
      </View>
    </View>
  )
}

export const ProductsFamilyOrBrandPDF = ({
  result = [],
  totalsPerFamilyOrBrand = [],
  selectedListBy,
}) => {
  return (
    <Document
      title={`impresso-produtos-por-família-marca-${format(new Date(), 'dd/MM/yyyy')}`}
      subject='quotes-supplier'
    >
      <Page style={styles.page} orientation="landscape">
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Listagem de Produto por Famílias/Marcas</Text>
        </View>
        <View style={[styles.line, { marginTop: 10 }]} />
        {
          selectedListBy ?
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
                marginTop: 1
              }}
            >
              {selectedListBy === 'family' ? 'Totais por Família' : 'Totais por Marca'}
            </Text>
            :
            ''
        }
        <View style={styles.line} />
        <HeaderTotalsPerFamilyOrBrand selectedListBy={selectedListBy} />
        <View style={styles.line} />
        {totalsPerFamilyOrBrand.map(item => {
          const description = item.reduce((prevValue, currentValue) => selectedListBy === 'family' ? currentValue.Families?.Description : currentValue.Brands?.Description, '')
          const quantity = item.length

          const totalsValueProduct = item.reduce((prevValue, currentValue) => {
            if (currentValue.Stock_Quantity && currentValue.Purchase_Price) {
              return prevValue + (currentValue.Stock_Quantity * currentValue.Purchase_Price)
            } else {
              return prevValue
            }
          }, 0)

          return (
            <div key={item.id}>
              <ItemRowTotalsPerFamilyOrBrand
                description={description}
                quantity={quantity}
                totalsValueProduct={totalsValueProduct}
              />
            </div>
          )
        })}
        <View style={[styles.line]} />
        {
          selectedListBy ?
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              {selectedListBy === 'family' ? ' Produtos por Família' : 'Produtos por Marca'}
            </Text>
            :
            ''
        }
        <View style={styles.line} />
        {totalsPerFamilyOrBrand.map((item, index) => {
          const description = item.reduce((prevValue, currentValue) => selectedListBy === 'family' ? currentValue.Families?.Description : currentValue.Brands?.Description, '')
          return (
            <div key={item.id}>
              <QuotesHeader
                description={description}
                selectedListBy={selectedListBy}
              />
              <View style={styles.line} />
              <ItemsHeaderQuotes />
              <View style={styles.line} />
              {item.map(element => {
                const lastDatePurchase = element.PurchaseItems.filter((item) => item.productId === element.id).slice(-1).map(value => value.createdAt)
                return (
                  <>
                    <ItemRowQuotes item={element} key={index} lastDatePurchase={lastDatePurchase[0]}/>
                  </>
                )
              })}
              <View style={styles.line} />
            </div>
          )
        })}
      </Page>
    </Document>
  )
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
