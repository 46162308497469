import React from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from 'client/components/CustomButton/CustomButton'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'
import { toastr } from 'react-redux-toastr'

const BillsToReceivePrintModal = ({
  isPrintModalOpen,
  setIsPrintModalOpen,
  selectedTitleToPrint,
}) => {
  const validateReceiptPrint = () => {
    if (selectedTitleToPrint.BillStatus.Description !== 'Finalizado') {
      toastr.warning(
        'A impressão do recibo é disponível apenas para títulos finalizados(pagos).'
      )
      return false
    }
    handleReceiptPrint()
    setIsPrintModalOpen(false)
  }

  const handleReceiptPrint = () => {
    const billIdHash = encrypt(selectedTitleToPrint.id, '@OS-dig:billId')
    const companyIdHash = encrypt(
      selectedTitleToPrint.Company.id,
      '@OS-dig:companyId'
    )
    console.log('selectedTitle: ', selectedTitleToPrint)
    window.open(
      window.location.origin +
        `/report/bill-to-receive-receipt?companyId=${companyIdHash}&billId=${billIdHash}`,
      '_blank'
    )
  }

  return (
    <Modal
      animation
      show={isPrintModalOpen}
      onHide={() => setIsPrintModalOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Impressão</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Escolha a opção desejada:</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <button
            style={{ width: '240px' }}
            className="btn btn-export"
            onClick={() => {
              window.open(selectedTitleToPrint.bank_slip_file_url)
              setIsPrintModalOpen(false)
            }}
            disabled={
              !(
                selectedTitleToPrint.BillStatus.Description !== 'Finalizado' &&
                selectedTitleToPrint.bankSlipId !== null
              )
            }
          >
            Imprimir Boleto
          </button>
          <button
            style={{ width: '240px' }}
            className="btn btn-sucesso"
            onClick={validateReceiptPrint}
          >
            Imprimir Recibo
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          bsStyle="danger"
          fill
          onClick={() => setIsPrintModalOpen(false)}
        >
          Voltar
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default BillsToReceivePrintModal
