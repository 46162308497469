import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { customChecklistSheetStyles } from '../../CustomChecklistSheet.styles';
import { FuelGaugeImage } from '../FuelGaugeImage/FuelGaugeImage';
import bikeImg from 'assets/img/checklist-images/bike.png';

const TableSimple = ({
  children,
  style = {},
  titleRight,
  titleLeft,
  data = [],
  ...rest
}) => (
  <View style={[customChecklistSheetStyles.tableContainer, style]}>
    <View style={customChecklistSheetStyles.tableHeader}>
      <Text style={customChecklistSheetStyles.bold}>{titleLeft}</Text>
      {titleRight ? (
        <Text style={customChecklistSheetStyles.bold}>{titleRight}</Text>
      ) : null}
    </View>
    <View style={customChecklistSheetStyles.table} {...rest}>
      {data.map((item) => (
        <View key={item} style={customChecklistSheetStyles.tableItemContainer}>
          <Text style={customChecklistSheetStyles.tableItemText}>
            {item.itemToCheck}
          </Text>
          <View
            style={[
              customChecklistSheetStyles.tableItemStatus,
              { justifyContent: 'center', flexDirection: 'column' },
            ]}
          >
            <Text style={{ textAlign: 'center', fontSize: 7 }}>
              {item.status !== null ? (item.status ? 'OK' : 'NOK') : ''}
            </Text>
          </View>
        </View>
      ))}
    </View>
  </View>
);

const TableDefects = ({
  children,
  style = {},
  titleRight,
  titleLeft,
  data = [],
  ...rest
}) => (
  <View style={[customChecklistSheetStyles.tableContainer, style]}>
    <View style={customChecklistSheetStyles.tableHeader}>
      <Text style={customChecklistSheetStyles.bold}>{titleLeft}</Text>
      {titleRight ? (
        <Text style={customChecklistSheetStyles.bold}>{titleRight}</Text>
      ) : null}
    </View>
    <View style={customChecklistSheetStyles.table} {...rest}>
      {data.map((item, index) => {
        const isLastItem = index === data.length - 1;
        let haveTwoLines = false;
        if (item.defectDescription || item.correctionDescription) {
          const defectDescriptionLength = item?.defectDescription?.length || 0;
          const defectDetailsLength = item?.defectDetails?.length || 0;
          const correctionDescriptionLength =
            item?.correctionDescription?.length || 0;
          const correctionDetailsLength = item?.correctionDetails?.length || 0;

          haveTwoLines =
            defectDescriptionLength + defectDetailsLength > 50 ||
            correctionDescriptionLength + correctionDetailsLength > 50;
        }

        return (
          <View
            key={item}
            style={customChecklistSheetStyles.tableItemContainer}
          >
            <View>
              <Text style={customChecklistSheetStyles.tableItemText}>
                {item.itemToCheck}
              </Text>
              <View
                style={[
                  customChecklistSheetStyles.tableItemText,
                  {
                    ...(!isLastItem ? { borderBottom: 0 } : {}),
                    borderTop: 0,
                    ...(haveTwoLines ? { height: '18px' } : {}),
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    position: 'relative',
                    top: '1px',
                  }}
                >
                  <View
                    style={[
                      {
                        flexDirection: 'row',
                        width: '50%',
                        fontSize: 5,
                        fontWeight: 'bold',
                      },
                    ]}
                  >
                    <Text
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Defeito :{' '}
                      {item.defectDescription
                        ? `${item.defectDescription} - ${item.defectDetails}`
                        : ''}
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        flexDirection: 'row',
                        width: '50%',
                        fontSize: 5,
                        fontWeight: 'bold',
                      },
                    ]}
                  >
                    <Text>
                      | Correção:{' '}
                      {item.correctionDescription
                        ? `${item.correctionDescription} - ${item.correctionDetails}`
                        : ''}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                { justifyContent: 'center', flexDirection: 'column' },
                customChecklistSheetStyles.tableItemStatus,
              ]}
            >
              {
                <Text style={{ textAlign: 'center', fontSize: 7 }}>
                  {item.status !== null ? (item.status ? 'OK' : 'NOK') : ''}
                </Text>
              }
            </View>
          </View>
        );
      })}
    </View>
  </View>
);

export function ChecklistTable({ checklist }) {
  const showFuelGaugeImage = ['Motos', 'Pesados'].includes(
    checklist?.defaultDesign
  );

  const isBike = checklist?.defaultDesign === 'Bicicleta';

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={customChecklistSheetStyles.detailsTable}>
        {checklist.showDefectCorrectionOnReport
          ? checklist.items.map((item) => (
              <TableDefects
                titleLeft={item.groupItemDescription}
                titleRight="Status"
                data={item.items}
                style={{
                  marginTop: 10,
                }}
              />
            ))
          : checklist.items.map((item) => (
              <TableSimple
                titleLeft={item.groupItemDescription}
                titleRight="Status"
                data={item.items}
                style={{
                  marginTop: 10,
                }}
              />
            ))}
      </View>

      {isBike && (
        <View style={{ width: '30%', padding: 10 }}>
          <Image src={bikeImg} style={customChecklistSheetStyles.bikeImg} />
          <Text
            style={[
              customChecklistSheetStyles.bold,
              {
                marginTop: 5,
                display: 'flex',
                textAlign: 'center',
                fontSize: 12,
              },
            ]}
          >
            M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F = Faltando
          </Text>
        </View>
      )}
      {showFuelGaugeImage && <FuelGaugeImage />}
    </View>
  );
}
