import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import gasImg from 'assets/img/checklist-images/gas.png';
import { customChecklistSheetStyles } from '../../CustomChecklistSheet.styles';

export function FuelGaugeImage() {
  return (
    <View style={{ width: '30%' }}>
      <View style={customChecklistSheetStyles.detailsRightContent}>
        <Text style={customChecklistSheetStyles.detailsRightText}>
          Medidor de Combustível
        </Text>
        <Image src={gasImg} style={customChecklistSheetStyles.gasImg} />
        <View style={customChecklistSheetStyles.withoutMalfunctions}>
          <View style={customChecklistSheetStyles.box} />
          <Text
            style={[customChecklistSheetStyles.underline, { marginRight: 2 }]}
          >
            SEM
          </Text>
          <Text>Avarias</Text>
        </View>
        <View style={customChecklistSheetStyles.withMalfunctions}>
          <View style={customChecklistSheetStyles.box} />
          <Text
            style={[customChecklistSheetStyles.underline, { marginRight: 2 }]}
          >
            COM
          </Text>
          <Text>Avarias</Text>
        </View>
      </View>
    </View>
  );
}
