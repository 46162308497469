import React from 'react'
import { Modal } from 'react-bootstrap'

const { Header, Title, Body, Footer } = Modal

const TokenModal = ({ show, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Header>
        <Title>
          <strong>OS Digital</strong>
        </Title>
      </Header>
      <Body>
        <strong>
          Para obter o seu token, entre em contato com o atendimento da SUIV e
          solicite-o.
        </strong>
        <br />
        <br />
        <span>
          Caso o token informado não seja válido, as funções da SUIV não
          funcionarão corretamente.
        </span>
      </Body>
      <Footer>
        <button className='btn btn-delete' onClick={onCancel}>
          Voltar
        </button>
      </Footer>
    </Modal>
  )
}

export default TokenModal
