const conpass = {

  startFlow(flow) {
    if(window.Conpass) {
      window.Conpass.startFlow(flow)
    }
  },

  init(name, email) {
    if (window.Conpass) {
      window.Conpass.init({
        name,
        email
      })
    }
  }


}

export default conpass