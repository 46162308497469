import * as yup from 'yup';
import { listApproach } from 'v2/repositories/ApproachRepository';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Vendas',
      'Abordagem',
      `${isEditing ? 'Editar' : 'Nova'} Abordagem`,
    ],
    path: [
      '/client/home',
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.APPROACH.BASE,
      null,
    ],
  };
};

export const validateApproachDescriptionUse = async (
  description,
  companyId,
  approachId
) => {
  try {
    const approaches = await listApproach({
      query: description,
      companyId,
    });

    const approachWithSameDescription = approaches.rows.find(
      (approach) =>
        approach.description.toUpperCase() === description.toUpperCase() &&
        (approachId ? +approach.id !== +approachId : true)
    );

    if (approachWithSameDescription) {
      return false;
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export const defaultFormValues = {
  id: null,
  description: '',
  observations: '',
  isActive: true,
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  observations: yup.string().notRequired(),
});
