import { currency } from 'client/components/ToNormalize/ToNormalize';
import React, { memo } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const TableByItem = ({ data, loading }) => {
  const {
    salesQuantityGeneral,
    salesTotalUnitGeneral,
    pdvTotalGeneral,
    osTotalGeneral,
    salesTotalGeneral,
    sale,
    pdv,
    os,
    qtdSale,
    total,
  } = data[0] || {};

  const footerStyle = {
    background: '#E5E5E5',
  };

  return (
    <>
      <h4>
        <strong>Valores por Item (R$)</strong>
      </h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 160,
            Footer: <strong>Total</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'Tp',
            accessor: 'type',
            width: 45,
            height: '100%',
            Footer: <strong>-</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'Família',
            accessor: 'family',
            width: 130,
            height: '100%',
            Footer: <strong>-</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'Qtd',
            accessor: 'qtdSale',
            width: 45,
            Footer: <strong>{salesQuantityGeneral || qtdSale || 0}</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'Venda (R$)',
            accessor: 'sale',
            width: 90,
            Cell: (cell) =>
              cell.value.toString().includes('R$')
                ? cell.value
                : currency(cell.value),
            Footer: (
              <strong>{currency(salesTotalUnitGeneral || sale || 0)}</strong>
            ),
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'PDV (R$)',
            accessor: 'pdv',
            width: 90,
            Cell: (cell) =>
              cell.value.toString().includes('R$')
                ? cell.value
                : currency(cell.value),
            Footer: <strong>{currency(pdvTotalGeneral || pdv || 0)}</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'OS (R$)',
            accessor: 'os',
            width: 90,
            Cell: (cell) =>
              cell.value.toString().includes('R$')
                ? cell.value
                : currency(cell.value),
            Footer: <strong>{currency(osTotalGeneral || os || 0)}</strong>,
            getFooterProps: () => ({ style: footerStyle }),
          },
          {
            Header: 'Valor Total (R$)',
            accessor: 'total',
            headerClassName: 'text-left',
            width: 120,
            className: 'texto',
            Cell: (cell) =>
              cell.value.toString().includes('R$')
                ? cell.value
                : currency(cell.value),
            Footer: (
              <strong>{currency(salesTotalGeneral || total || 0)}</strong>
            ),
            getFooterProps: () => ({ style: footerStyle }),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={false}
        sortable={false}
        showPaginationTop={false}
        showPaginationBottom={false}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma informação encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  );
};

export default memo(TableByItem);
