import React from 'react'

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import StoneLinks from './StoneLinks'

const StoneHome = () => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Integrações', 'Integração Stone']}
        path={['home', null, null]}
      />
      <StoneLinks />
    </>
  )
}

export default StoneHome
