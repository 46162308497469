import React from 'react';
import { change, Field } from 'redux-form';
import ReactTable from 'react-table';
import Button from '../../../../components/CustomButton/CustomButton';
import { format } from 'date-fns';
import RenderField from 'components/RenderField';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import constants from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createNumberMask } from 'redux-form-input-masks';
import { formatCST } from 'v2/utils/taxesUtils';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
});

const Items = ({ loading, onCancel, aprovedItems, items }) => {
  const { purchaseStatusId, issuedAt } = useSelector(
    (state) => state.form.purchases.values
  );

  const isCanceled = purchaseStatusId === constants.PURCHASES_STATUS.CANCELED;
  let isClosed = purchaseStatusId === constants.PURCHASES_STATUS.CLOSED;
  const dispatch = useDispatch();

  const verifyDate = async (index, data) => {
    setTimeout(
      () => document.getElementById(`input-${index}.approvedDate`).focus(),
      50
    );
    // fazer metodo para verificar se data é maior que data de emissão e retornar alerta se n for
    if (data) {
      let dataAprovacao = format(new Date(data), 'dd/MM/yyyy');
      let dataEmissao = format(new Date(issuedAt), 'dd/MM/yyyy');
      if (
        dataAprovacao <= dataEmissao ||
        dataAprovacao > format(new Date(), 'dd/MM/yyyy')
      ) {
        toastr.warning(
          'Data de entrada de produto maior que a data atual. Selecione uma data válida'
        );
      }
    } else if (!data) {
      await dispatch(
        change('purchases', 'purchaseStatusId', constants.PURCHASES_STATUS.OPEN)
      );
    }
  };

  return (
    <>
      <div style={{ padding: '10px' }}>
        <ReactTable
          style={{
            width: '100%',
            fontWeight: 'bold',
            textAlign: 'center',
            height: '400px',
          }}
          data={items}
          columns={[
            {
              Header: 'Código Interno',
              accessor: 'productCode',
              headerClassName: 'text-center',
              width: 120,
              className: 'texto',
            },
            {
              Header: 'Ref. Fabricante',
              accessor: 'Products.Manufacturer_Ref',
              headerClassName: 'text-center',
              className: 'texto',
              width: 120,
            },
            {
              Header: 'Descrição',
              accessor: 'description',
              width: 300,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'Base Mult',
              accessor: 'multiplierBase',
              headerClassName: 'text-center',
              className: 'texto',
              width: 100,
            },
            {
              Header: 'Qtd',
              accessor: 'quantity',
              headerClassName: 'text-center',
              className: 'texto',
              width: 80,
            },
            {
              Header: 'Valor Unitário',
              accessor: 'unitValue',
              headerClassName: 'text-center',
              className: 'texto',
              width: 150,
              Cell: (props) => (props.value ? currency(props.value) : 0),
            },
            {
              Header: 'Desconto Unitário',
              accessor: 'unitDiscount',
              headerClassName: 'text-center',
              className: 'texto',
              width: 150,
              Cell: (props) => (props.value ? currency(props.value) : 0),
            },
            {
              Header: 'Valor Total',
              accessor: 'total',
              headerClassName: 'text-center',
              className: 'texto',
              width: 150,
              Cell: (props) => (props.value ? currency(props.value) : 0),
            },
            {
              Header: 'Data de aprovação',
              accessor: 'approvedDate',
              width: 170,
              headerClassName: 'text-center',
              className: 'texto',
              Cell: (props) => (
                <Field
                  id={`input-${props.index}.approvedDate`}
                  name={`items[${props.index}].approvedDate`}
                  component={RenderField}
                  type="date"
                  disabled={isCanceled ? true : false}
                  onChange={(e) => verifyDate(props.index, e.target.value)}
                />
              ),
            },
            {
              Header: 'CST/CSOSN',
              accessor: 'CSTCSOSN',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'CFOP',
              accessor: 'CFOP',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'NCM',
              accessor: 'NCM',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'ICMS (%)',
              accessor: 'ICMS',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'BC ICMS',
              accessor: 'BCICMS',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'Valor ICMS',
              accessor: 'ICMSValue',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'BC ICMS ST',
              accessor: 'BCICMSST',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'Valor ICMS ST',
              accessor: 'ICMSSTValue',
              width: 150,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'Frete',
              accessor: 'freightValue',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'IPI (%)',
              accessor: 'IPI',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'BC IPI',
              accessor: 'BCIPI',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'Valor IPI',
              accessor: 'IPIValue',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'CST PIS',
              accessor: 'CSTPIS',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
              Cell: (props) => formatCST(props.value),
            },
            {
              Header: 'Valor PIS',
              accessor: 'PISValue',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
            {
              Header: 'CST COFINS',
              accessor: 'CSTCOFINS',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
              Cell: (props) => formatCST(props.value),
            },
            {
              Header: 'Valor COFINS',
              accessor: 'COFINSValue',
              width: 100,
              headerClassName: 'text-center',
              className: 'texto',
            },
          ]}
          defaultPageSize={100}
          loading={loading}
          showPagination
          sortable
          showPaginationBottom
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'productCode',
              desc: false,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Nenhum Produto encontrado"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />

        <div style={{ display: 'grid' }}>
          <div style={{ display: 'block' }}>
            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="BC ICMS:"
                name="BCICMS"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="BC ICMS ST:"
                name="BCICMSST"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="Valor PIS:"
                name="PISValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="Valor Frete:"
                name="freightValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="Valor IPI:"
                name="IPIValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="Valor FCP:"
                name="FCPValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor Produtos:"
                name="productsValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor Total:"
                name="total"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>

            <Col xs={12} sm={12} md={2} lg={2} className="rodape">
              <Field
                label="Valor ICMS:"
                name="ICMSValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor ICMS ST:"
                name="ICMSSTValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor COFINS:"
                name="COFINSValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor Seguro:"
                name="inssuranceValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Outras Despesas:"
                name="otherExpenses"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Valor FCP ST:"
                name="FCPSTValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="rodapeMaior">
              <Field
                label="Descontos:"
                name="discountValue"
                component={RenderField}
                disabled
                {...currencyMask}
              />
            </Col>
          </div>

          <div
            className="col-lg-12"
            style={{
              marginTop: '-38px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              type="button"
              bsStyle="danger"
              pullRight
              fill
              onClick={() => onCancel()}
            >
              {isCanceled || isClosed ? 'Voltar' : 'Cancelar'}
            </Button>

            <Button
              pullRight
              type="button"
              bsStyle="primary"
              fill
              style={{ marginRight: '10px', width: 'fit-content' }}
              onClick={() => aprovedItems()}
              disabled={isCanceled || isClosed}
            >
              Aprovar Todos os Itens
            </Button>

            {/* {props.sale ?  */}
            <Button
              type="button"
              bsStyle="info"
              pullRight
              fill
              onClick={() => onCancel()}
              disabled={isCanceled || isClosed}
            >
              Salvar Alteração
            </Button>
            {/* :
                        <Button type="button" className='btn-sucesso' bsStyle="info" onClick={() => setConfirmModal(true)} fill style={{ width: 'fit-content' }} >Iniciar Venda</Button>
                    } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Items;
