import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { CustomerHistory } from 'client/components/CustomerHistory';

export const CustomerHistorySaleButton = ({
  saleTypeId,
  customer,
  vehicles = [],
}) => {
  const [isCustomerHistoryModalOpen, setIsCustomerHistoryModalOpen] =
    useState(false);

  return (
    <>
      <Button
        bsStyle="primary"
        onClick={() => setIsCustomerHistoryModalOpen(true)}
        fill
        disabled={!customer.id || saleTypeId === 4}
        style={{
          marginRight: 10,
          height: '35px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Histórico do Cliente
      </Button>
      {isCustomerHistoryModalOpen && (
        <Modal
          show
          dialogClassName="modal-70w"
          onHide={() => setIsCustomerHistoryModalOpen(false)}
          animation
        >
          <Modal.Header>
            <Modal.Title>
              <strong>Histórico do Cliente</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerHistory customer={customer} vehicles={vehicles} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="danger"
              onClick={() => setIsCustomerHistoryModalOpen(false)}
              fill
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
