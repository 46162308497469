import React from "react";
import { Grid, Row } from "react-bootstrap";
import HomeServiceInvoices from './ServiceInvoices'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const ServiceInvoices = () => {
    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <BreadCrumb data={['Início', 'Notas Fiscais de Serviço']} path={['home', null]} />
                    <HomeServiceInvoices />
                </Row>
            </Grid>
        </div>
    );
}

export default ServiceInvoices;
