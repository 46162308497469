import React from 'react';
import { Modal } from 'react-bootstrap';
import { currency } from 'client/components/ToNormalize/ToNormalize';

export const CRMBonusRescuedModal = ({
  isOpen,
  handleProceed,
  handleClose,
  saleType,
  redeemedBonus,
}) => {
  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          O Orçamento possui bonus de{' '}
          <strong>
            {redeemedBonus ? `${currency(redeemedBonus)}` : currency(0)}
          </strong>
          .
          <br />
          <br />
          Uma vez aplicado o bônus, o fechamento precisa ocorrer no mesmo
          momento. Em caso de salvar em aberto, o bônus será zerado e o
          orçamento terá o seu valor original aplicado e caso tenha parcelas
          aplicadas, elas serão recalculadas. Deseja continuar?
        </span>
        <br />
        <div style={{ textAlign: 'center' }}>
          <br />
          <span style={{ color: 'red', fontSize: '12px' }}>
            O bonus aplicado nesse orçamento não será utilizado. Ele poderá ser
            utilizado futuramente, mediante as regras e o prazo de validade.
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-050">
          <button className="button button-red w-150px" onClick={handleClose}>
            Voltar
          </button>
          <button
            className="button button-green w-150px"
            onClick={handleProceed}
          >
            Continuar
          </button>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  );
};
