import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change, initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import CardForm from '../../../../../client/components/CardForm';
import FormSettingsNFSe from './SettingsNFSe';
import ValidateNFSePattern from './ValidateNFSePattern';
import AvailableInactiveNFSePattern from './AvailableInactiveNFSePattern';

import settingsNFSeRepository from '../../../../../repositories/SettingsNFSe';
import NFSeRepository from '../../../../../repositories/NFSes';
import CompanyRepository from '../../../../../repositories/Companies';

import { useAuth } from '../../../../../contexts/auth';
import { useModulesContext } from '../../../../../contexts/modules';

const SettingsNFSe = ({
  onSubmit,
  isNFSePatternAvailable,
  setIsNFSePatternAvailable,
}) => {
  const [isNFSeData, setIsNFSeData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nfseModule, setNfseModule] = useState(0);
  const [nfsePattern, setNfsePattern] = useState(null);
  const [nfseCity, setNfseCity] = useState(null);

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const { companyId } = useAuth();
  const { nfseModuleStatus } = useModulesContext();

  const dispatch = useDispatch();

  async function handleSubmit(values) {
    if (loading) return;
    const { settingsNFSeId, lastNFSeSent, serie, environment } = values;
    setLoading(true);

    const code = parseInt(lastNFSeSent || 0) + 1;
    const nfse = await NFSeRepository.search({
      companyId: companyId,
      code: code,
      serie: serie,
      environment: environment,
    });

    if (!!nfse) {
      setLoading(false);
      return toastr.warning(
        `Já existe uma NFS-e criada com a numeração ${code} para essa série e esse ambiente. Valide a numeração correta`
      );
    }

    if (!settingsNFSeId) {
      return create(values);
    }
    return update(values);
  }

  async function create(values) {
    const {
      NFSPatternId,
      CMCCode,
      CNAE,
      IM,
      ISS,
      serie,
      lastNFSeSent,
      environment,
      municipalTaxCode,
      printingTemplate,
      serviceListItem,
      serviceLocationCode,
      specialTaxRegimeCode,
      userAccess,
      userPassword,
      pattern,
      taxType,
      operationType,
      opt_sn,
    } = values;

    try {
      const settingsNFSe = await settingsNFSeRepository.create({
        companyId,
        NFSPatternId,
        CMCCode,
        CNAE,
        IM,
        ISS,
        serie,
        lastNFSeSent,
        environment,
        municipalTaxCode,
        printingTemplate,
        serie,
        serviceListItem,
        serviceLocationCode,
        specialTaxRegimeCode,
        userAccess,
        userPassword,
        taxType,
        operationType,
        opt_sn,
      });
      const data = {
        settingsNFSeId: settingsNFSe.id,
        lastNFSeSent,
        municipalTaxCode,
        CMCCode,
        CNAE,
        IM,
        ISS,
        environment,
        printingTemplate,
        serie,
        serviceListItem,
        serviceLocationCode,
        specialTaxRegimeCode,
        userAccess,
        userPassword,
        pattern,
        taxType,
        operationType,
        opt_sn,
      };
      await dispatch(initialize('settingsNFSe', data));
      setLoading(false);
      toastr.success('Configurações de NFS-e registradas com sucesso');
      onSubmit(settingsNFSe);
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastr.error(err.response.data.message);
    }
  }

  async function update(values) {
    const {
      CMCCode,
      CNAE,
      IM,
      ISS,
      serie,
      lastNFSeSent,
      environment,
      settingsNFSeId,
      municipalTaxCode,
      printingTemplate,
      serviceListItem,
      serviceLocationCode,
      specialTaxRegimeCode,
      userAccess,
      userPassword,
      taxType,
      operationType,
      opt_sn,
    } = values;

    try {
      await settingsNFSeRepository.update(settingsNFSeId, {
        CMCCode: CMCCode || null,
        CNAE: CNAE || '',
        IM: IM || '',
        ISS,
        serie: serie || '',
        lastNFSeSent: lastNFSeSent || '',
        environment,
        settingsNFSeId,
        municipalTaxCode: municipalTaxCode || '',
        printingTemplate,
        serie,
        serviceListItem: serviceListItem || '',
        serviceLocationCode: serviceLocationCode || '',
        specialTaxRegimeCode: specialTaxRegimeCode || '',
        userAccess: userAccess || '',
        userPassword: userPassword || '',
        taxType: taxType || '',
        operationType: operationType || '',
        opt_sn: opt_sn || '',
      });
      await dispatch(initialize('settingsNFSe', values));
      setLoading(false);
      toastr.success('Configurações de NFS-e atualizadas');
      onSubmit(values);
    } catch (err) {
      console.error(err.response);
      setLoading(false);
      toastr.error(err.response.data.message);
    }
  }

  function handleSuccessNFSeAvailability(pattern) {
    setIsNFSePatternAvailable(true);
    setNfsePattern(pattern.NFSPatterns.pattern);

    dispatch([
      change('settingsNFSe', 'pattern', pattern.NFSPatterns.pattern),
      change('settingsNFSe', 'NFSPatternId', pattern.NFSPatternId),
    ]);
  }

  const initialValues = {
    allowHomologation: true,
    printingTemplate: 'Detalhado Antigo',
    environment: 'Produção',
    opt_sn: 2,
  };

  async function getCompanyInfo() {
    const companyInfo = await CompanyRepository.getById(companyId);

    if (companyInfo) {
      setNfseModule(nfseModuleStatus);
      setNfseCity(companyInfo.Address[0].City);

      if (nfseModuleStatus === 2 || nfseModuleStatus === 3) {
        const response = await settingsNFSeRepository.verifyAvailability(
          companyId
        );

        if (response.status) {
          setNfsePattern(response.pattern.NFSPatterns.pattern);

          if (nfseModuleStatus === 3) {
            dispatch([
              change(
                'settingsNFSe',
                'pattern',
                response.pattern.NFSPatterns.pattern
              ),
              change(
                'settingsNFSe',
                'NFSPatternId',
                response.pattern.NFSPatternId
              ),
              change(
                'settingsNFSe',
                'allowHomologation',
                response.pattern.allowHomologation
              ),
            ]);
          }
        }
      }
    }
  }

  return (
    <>
      <CardForm
        show={isNFSeData}
        title="Dados da NFS-e"
        onClick={() => setIsNFSeData(!isNFSeData)}
      >
        {nfseModule === 3 ? (
          <FormSettingsNFSe
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={loading}
          />
        ) : isNFSePatternAvailable || nfseModule === 2 ? (
          <AvailableInactiveNFSePattern city={nfseCity} pattern={nfsePattern} />
        ) : (
          <ValidateNFSePattern onSuccess={handleSuccessNFSeAvailability} />
        )}
      </CardForm>
    </>
  );
};

export default SettingsNFSe;
