import React from 'react'
import { withRouter } from 'react-router'
import constants from '../../../../utils/constants'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NpsRegisterMain from './components/NpsRegisterMain'

import './index.css'

const NpsRegister = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Pesquisas', match.params.id ? 'Editar Pesquisa' : 'Nova Pesquisa']}
        path={['home', 'polls', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <NpsRegisterMain pollsId={match.params.id} />
    </>
  )
}

export default withRouter(NpsRegister)
