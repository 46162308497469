import React, { useEffect, useState } from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, percentage, cepMask, CFOPMask } from 'client/components/ToNormalize/ToNormalize'

const InventoryMovementListingPDF = (value) => {
  const inventorys = value.responseData.inventorys
  const totalsPerTypeAndStatus = value.responseData.totalsPerTypeAndStatus
  const period = value.responseData.period
  return (
    <Document
      title={`impresso-inventory-movement-listing-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Listagem de Movimentações
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Data Inicio:
            </Text>
            <Text style={[styles.text]}> {format(new Date(`${period.start} 03:00:01`), 'dd/MM/yyyy')}</Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                Data Fim:
              </Text>
              <Text style={[styles.text]}>{format(new Date(`${period.end} 23:59:59`), 'dd/MM/yyyy')}</Text>
            </View>
          </View>

        </View>


        <TableHeader
          totalsPerTypeAndStatus={totalsPerTypeAndStatus}
          inventorys={inventorys}
        />

      </Page>
    </Document>
  )

}

const TableHeader = ({ totalsPerTypeAndStatus, inventorys }) => {
  let totalQuantity = 0

  totalsPerTypeAndStatus.map(item =>
    totalQuantity += item.length
  )

  return (
    <>
      <View style={[styles.line, { marginTop: '20px' }]} />
      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Tipo
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
      </View>

      <View style={[styles.line]} />


      {totalsPerTypeAndStatus.map(item => {
        const type = item.reduce((prevValue, currentValue) => currentValue.movementType, '')
        const status = item.reduce((prevValue, currentValue) => currentValue.status, '')
        const quantity = item.length
        return (
          <TotalsByTypeStatusItems key={item.id} type={type} status={status} quantity={quantity} />
        )
      })}

      <View style={[styles.line]} />

      <SubTotals totalQuantity={totalQuantity} />

      <View style={[styles.line, { marginTop: '10px' }]} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Movimentações
        </Text>
      </View>
      <View style={[styles.line]} />

      {
        inventorys.map((inventory, index) => (<InventaryDetails inventory={inventory} key={index} />))
      }
      <View style={[styles.line, { marginTop: '50px' }]} />
    </>
  )
}

const TotalsByTypeStatusItems = ({ type, status, quantity }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={{ width: '8%' }}>
          <Text style={styles.text}>{type}</Text>
        </View>
        <View style={{ width: '12%' }}>
          <Text style={styles.text}>{status}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{quantity}</Text>
        </View>

      </View>

    </View>
  )
}

const SubTotals = ({ totalQuantity }) => {
  return (
    <View>

      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '20%' }]}>Total </Text>
        <Text style={[styles.boldText, { width: '7%' }]}>{totalQuantity}</Text>
      </View>

    </View>



  )
}



const InventaryDetails = ({ inventory, key }) => {

  return (
    <View style={{ marginTop: '10px' }}>
      <InventaryDetailsHeader inventory={inventory} key={key} />
      <InventaryDetailsTableHeader />


      {
        inventory.InventoryMovementItems.map((itemProducts, index) => (<InventaryDetailsTableItem itemProducts={itemProducts} key={index} inventory={inventory} />))
      }

      <View style={styles.line} />
    </View>
  )
}

const InventaryDetailsHeader = ({ inventory, key }) => {
  return (
    <View key={key}>
      <View style={{ marginTop: '6px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Cadastro:</Text>
        <Text style={{ width: '8%', right: '6px' }}>{format(new Date(inventory.createdAt), 'dd/MM/yyyy')}</Text>

        {
          inventory.status === 'Finalizada' ?
            <>
              <Text style={[styles.boldText, { width: '12%' }]}>Data Movimentação: </Text>
              <Text style={{ width: '8%', right: '6px' }}>{format(new Date(inventory.updatedAt), 'dd/MM/yyyy')}</Text>
            </>
            :
            <></>
        }

        {
          inventory.status === 'Cancelada' ?
            <>
              <Text style={[styles.boldText, { width: '12%' }]}>Data Cancelamento: </Text>
              <Text style={{ width: '8%', right: '6px' }}>{format(new Date(inventory.canceledDate), 'dd/MM/yyyy')} </Text>
            </>
            :
            <></>
        }
        <Text style={[styles.boldText, { width: '4%' }]}>Status: </Text>
        <Text style={{ width: '7%', left: '2px' }}>{inventory.status}</Text>
        <Text style={[styles.boldText, { width: '3%' }]}>Tipo: </Text>
        <Text style={{ width: '6%', left: '2px' }}>{inventory.movementType}</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Movimentação: </Text>
        <Text style={{ width: '7%', left: '2px' }}>{inventory.code}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '7%' }]}>Fornecedor: </Text>
        <Text style={[{ width: '57%', right: '4px' }]}>{inventory.Provider?.cpfCnpj ? inventory.Provider?.cpfCnpj : ''} - {inventory.Provider?.tradingName ? inventory.Provider?.tradingName : ''}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>Observações: </Text>
        <Text style={[{ width: '57%', right: '4px' }]}>{inventory.observation}</Text>
      </View>
    </View>
  )
}



const InventaryDetailsTableHeader = () => {
  return (
    <View >
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Codigo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Ref.Fabricação</Text>
        <Text style={[styles.boldText, { width: '14%' }]}>Marca</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Qtd.</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}


const InventaryDetailsTableItem = ({ itemProducts, key }) => {

  return (
    <View >
      <View style={styles.containerRow}>
        <Text style={{ width: '9%' }}>{itemProducts.Products.Code}</Text>
        <Text style={{ width: '20%' }}>{itemProducts.Products.Description}</Text>
        <Text style={{ width: '12%' }}>{itemProducts.Products.Manufacturer_Ref}</Text>
        <Text style={{ width: '14%' }}>{itemProducts.Products?.Brands?.Description}</Text>
        <Text style={{ width: '9%', left: '3px' }}>{itemProducts.quantity}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>

  )
}

export default InventoryMovementListingPDF



const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },

})