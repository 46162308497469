import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ProductsFilterForm from './Components/ProductsFilterForm'
import ProductsTable from './Components/ProductsTable'
import AlertModal from 'components/AlertModal/AlertModal'
import Button from '../../../../../components/CustomButton/CustomButton'
import trayCompanyRepository from 'repositories/TrayCompany'

import useFilters from '../../../../../../hooks/useFilters'

import brandsRepository from 'repositories/Brands'

import { toastr } from 'react-redux-toastr'

import './styles.css'
import ProductsLoadingModal from './Components/ProductsLoadingModal'

import productsRepository from 'repositories/Products'
import { useAuth } from 'contexts/auth'

const { Header, Title, Body, Footer } = Modal

const CreateOrUpdateTrayProductModal = ({
  show,
  setIsCreateUpdateProductModalOpen,
  mode,
}) => {
  const modalModeTitle = {
    create: 'Envio de produtos - Tray',
    update: 'Envio de atualizações de cadastro - Tray',
  }

  const { code, trayPlanId, trayUrl } = useSelector(
    (state) => state.form.tray.values
  )

  const [loading, setLoading] = useState(false)

  const [brands, setBrands] = useState([])
  const [brandId, setBrandId] = useState([])
  const [status, setStatus] = useState('')
  const [products, setProducts] = useState([])
  const [allProductsSelected, setAllProductsSelected] = useState(false)

  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showExitConfirmationModal, setShowExitConfirmationModal] =
    useState(false)

  const { queryFilter, typeFilter } = useFilters()

  const { query, setQuery, filterByQuery } = queryFilter
  const { type, setType, filterByType } = typeFilter

  const {
    companyId,
    company: { Cpf_Cnpj },
  } = useAuth()

  function handleFilter(product) {
    const querySearch = [
      product['Description'],
      product['Code'],
      product['Manufacturer_Ref'],
    ]
    return filterByQuery(querySearch) && filterByType('Brand_id', product)
  }

  const filteredProducts = products.filter(handleFilter)

  function handleSelectAll() {
    setProducts(
      products.map((prod) => {
        return { ...prod, selected: true }
      })
    )
  }

  function handleUnselectAll() {
    setProducts(
      products.map((prod) => {
        return { ...prod, selected: false }
      })
    )
  }

  function handleSelectedProductsChange(selectedProduct) {
    const productsCopy = [...products]
    const productIndex = productsCopy.findIndex(
      (prod) => prod.id === selectedProduct.id
    )
    productsCopy[productIndex].selected = !productsCopy[productIndex].selected
    setProducts(productsCopy)
  }

  async function loadProducts() {
    let products = []
    setLoading(true)
    setShowLoadingModal(true)

    try {
      if (mode === 'create') {
        products = await productsRepository.getAllTray(companyId)
      }

      if (mode === 'update') {
        products = await productsRepository.getAllTray(companyId, true)
      }

      const serializedProducts = products.map((prod) => {
        return { ...prod, selected: false }
      })

      setProducts(serializedProducts)
    } catch (err) {
      setLoading(false)
      setShowLoadingModal(false)
      toastr.warning('Ocorreu um erro inesperado. Por favor, tente novamente')
      onCancel()
    } finally {
      setLoading(false)
      setShowLoadingModal(false)
    }
  }

  async function handleSubmit() {
    setLoading(true)
    try {
      const selectedProductsLength = products.filter(
        (product) => product.selected
      ).length

      if (selectedProductsLength === 0) {
        toastr.warning(
          'Selecione ao menos 1 produto para enviar para Tray e tente novamente'
        )
        return
      }

      let productsToSend = products.filter((product) => product.selected)
      productsToSend = productsToSend.map((prod) => {
        return { id: prod.id }
      })

      const {
        data: {
          trayCompany: { trayAccessToken },
        },
      } = await trayCompanyRepository.update(companyId, {
        trayUrl,
        code,
        trayPlanId,
        companyId,
        cpfCnpj: Cpf_Cnpj,
      })

      if (!trayAccessToken) {
        return toastr.warning(
          'Falha ao atualizar o token de acesso da tray, feche a tela e tente novamente'
        )
      }

      if (mode === 'create') {
        await productsRepository.sendProductTray({
          products: productsToSend,
          trayToken: trayAccessToken,
          trayUrl,
        })

        toastr.success('Envio de cadastros de produtos efetuados com sucesso')
      } else if (mode === 'update') {
        await productsRepository.updateProductTray({
          products: productsToSend,
          trayToken: trayAccessToken,
          trayUrl,
        })

        toastr.success('Envio de atualização realizado com sucesso')
      }
      setIsCreateUpdateProductModalOpen(false)
    } catch (err) {
      if (mode === 'create') {
        toastr.error(
          'Não foi possível efetuar o cadastro dos produtos selecionados. Verifique se o seu plano possui cadastros disponíveis e tente novamente mais tarde.'
        )
      } else {
        toastr.error(
          'Não foi possível atualizar os dados do produto. Verifique o cadastro do produto na Tray e tente novamente mais tarde.'
        )
      }
    } finally {
      setLoading(false)
    }
  }

  function onCancel(origin) {
    if (origin === 'exitConfirmationModal') {
      setIsCreateUpdateProductModalOpen(false)
      return
    }

    const selectedProductsLength = products.filter(
      (product) => product.selected
    ).length
    if (selectedProductsLength !== 0) {
      setShowExitConfirmationModal(true)
      return
    }

    setShowExitConfirmationModal(false)
    setIsCreateUpdateProductModalOpen(false)
  }

  async function loadBrands() {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId)
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        description: brand.Description,
      }))
      setBrands(serializedBrands)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscas as marcas. Por favor, tente novamente'
      )
    }
  }

  useEffect(() => {
    loadProducts()
    loadBrands()
  }, [])

  useEffect(() => {
    const selectedProductsLength = products.filter(
      (product) => product.selected
    ).length
    if (selectedProductsLength !== products.length) {
      setAllProductsSelected(false)
      return
    }

    setAllProductsSelected(true)
  }, [products])

  return (
    <>
      <Modal show={show} onHide={onCancel} dialogClassName='modal-90w'>
        <Header>
          <Title>
            <strong>{modalModeTitle[mode]}</strong>
          </Title>
        </Header>
        <Body>
          <div className='modal-advice-label'>
            <span>Apenas produtos ativos aparecerão na listagem</span>
          </div>

          <ProductsFilterForm
            brands={brands}
            companyId={companyId}
            status={status}
            setStatus={setStatus}
            brandId={brandId}
            setBrandId={setBrandId}
            setQuery={setQuery}
            query={query}
            type={type}
            setType={setType}
          />

          <div className='select-all-products hyperlink'>
            <a
              href='#'
              onClick={
                !allProductsSelected ? handleSelectAll : handleUnselectAll
              }
            >
              {allProductsSelected ? 'Remover Todos' : 'Selecionar Todos'}
            </a>
          </div>

          <ProductsTable
            loading={loading}
            products={filteredProducts}
            handleSelectedProductsChange={handleSelectedProductsChange}
          />

          <div className='modal-footer-buttons tray'>
            <Button
              bsStyle='danger'
              pullRight
              fill
              onClick={() => onCancel()}
              disabled={loading ? 'disabled' : ''}
            >
              Voltar
            </Button>
            &nbsp;
            <Button
              bsStyle='info'
              pullRight
              fill
              onClick={() => handleSubmit()}
              disabled={loading ? 'disabled' : ''}
            >
              Enviar {mode === 'create' ? 'Produtos' : 'Atualizações'}
            </Button>
          </div>
        </Body>
        <Footer></Footer>
        <ProductsLoadingModal showLoadingModal={showLoadingModal} />
      </Modal>

      {showExitConfirmationModal ? (
        <AlertModal
          show={showExitConfirmationModal}
          title='OS Digital'
          message={
            <>
              <p>
                <strong>
                  Você tem certeza que deseja sair do envio de produtos?
                </strong>
              </p>
              <p>
                O processo de envio poderá ser realizado mais tarde.
                Atualizações efetuadas na plataforma não replicam no seu
                cadastro do OS Digital.
              </p>
            </>
          }
          onCancel={() => setShowExitConfirmationModal(false)}
          onSubmit={() => {
            onCancel('exitConfirmationModal')
          }}
          loading={loading}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default CreateOrUpdateTrayProductModal
