import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import ImportFromXlsModal from 'client/components/ImportFromXlsModal';

import Card from '../../../components/Card/Card';
import Button from '../../components/CustomButton/Button.jsx';
import ModalAlertCompany from '../../components/ModalAlertCompany/ModalAlertCompany';
import {
  cnpjMask,
  cpfMask,
  phoneMask,
} from '../../components/ToNormalize/ToNormalize';
import ClientActions from './ClientActions';
import FormModalVehicle from '../../components/FormModals/Vehicle';
import VehiclesTable from '../../components/VehiclesTable';
import customersRepository from '../../../v2/repositories/CustomerRepository';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import {
  faCarRear,
  faCloudDownloadAlt,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadXlsButton from 'components/DownloadXlsButton';
import PlateChangeModal from './components/PlateChangeModal';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';

import './styles.css';
import SearchInput from 'components/SearchInput';
import { format } from 'date-fns';
import useDebounce from 'hooks/useDebounce';

import LoadingModal from 'client/components/LoadingModal';
import CustomersThatReturnedButton from 'client/components/CustomersThatReturnedButton';

const HomeClients = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientIndex, setClientIndex] = useState(null);
  const [isVehicleFormModalOpen, setIsVehicleFormModalOpen] = useState(false);
  const [isVehiclesModalOpen, setIsVehiclesModalOpen] = useState(false);
  const [isImportFromXlsModalOpen, setIsImportFromXlsModalOpen] =
    useState(false);
  const [isPlateChangeModalOpen, setIsPlateChangeModalOpen] = useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const { companyId } = useAuth();

  const [selectedClientId, setSelectedClientId] = useState(null);
  const [customers, setCustomers] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pagesQuantity, setPagesQuantity] = useState(1);

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const [isXlsExportReady, setIsXlsExportReady] = useState(false);
  const [isXlsExportLoading, setIsXlsExportLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);

  const [reloadTrigger, setReloadTrigger] = useState(false);

  const handleResetXlsExport = () => {
    setIsXlsExportReady(false);
    setIsXlsExportLoading(false);
    setDataToExport([]);
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const loadCustomers = async () => {
    setLoading(true);

    try {
      const params = {
        companyId,
        page: page + 1,
        type,
        status,
        query,
        limit,
      };

      const customer = await customersRepository.index(params);
      setCustomers(customer?.serializedCustomers);
      setPagesQuantity(Math.ceil(customer?.count / limit));
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os clientes. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  async function loadDataToExport() {
    setIsXlsExportReady(false);
    setIsXlsExportLoading(true);

    try {
      const params = {
        companyId,
        type,
        status,
        query,
      };

      const allCustomers = await customersRepository.index(params);

      setDataToExport(allCustomers.serializedCustomers);
      setIsXlsExportReady(true);
      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para exportação',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    } finally {
      setIsXlsExportLoading(false);
    }
  }

  function handleOpenClientVehiclesModal(client, index) {
    setClientIndex(index);
    if (!client.vehiclesQuantity) {
      setIsVehicleFormModalOpen(true);
      return;
    }
    setIsVehiclesModalOpen(true);
  }

  function handleIncludeCustomerVehicles() {
    setReloadTrigger(true);
    setIsVehicleFormModalOpen(false);
  }

  const hideModalHomeVeiculo = () => {
    loadCustomers();
    setIsVehiclesModalOpen(false);
  };

  const handleLoadCustomersList = () => {
    handleResetXlsExport();
    loadCustomers();
  };

  useEffect(() => {
    if (!!companyId) {
      handleLoadCustomersList();
    } else {
      setNoCompanyModal(true);
    }
  }, [companyId, page, limit, type, queryDebounced, status]);

  useEffect(() => {
    if (reloadTrigger) {
      handleLoadCustomersList();
      setReloadTrigger(false);
    }
  }, [reloadTrigger]);

  return (
    <>
      <Card
        content={
          <div>
            <div className="customers-row">
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso button-h35 fontSize-12"
                  onClick={() =>
                    !!companyId
                      ? history.push(constants.ROUTES.CUSTOMER)
                      : setNoCompanyModal(true)
                  }
                >
                  + Novo Cliente
                </button>
              </div>
              <SearchInput
                placeholder="Pesquisa por Nome, CPF/CNPJ, Placa e Obj. Man."
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                style={{ fontSize: '12px' }}
              />
              <fieldset>
                <span className="typography__text-2">Tipo:</span>
                <select
                  className="form-control foco-input fontSize-12"
                  onChange={(event) => setType(event.target.value)}
                  value={type}
                >
                  <option value="">Ambos</option>
                  <option value="Fisica">Física</option>
                  <option value="Juridica">Jurídica</option>
                </select>
              </fieldset>
              <fieldset>
                <span className="typography__text-2">Status:</span>
                <select
                  className="form-control foco-input fontSize-12"
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                  style={{ width: '100px' }}
                >
                  <option value="">Ambos</option>
                  <option value="Ativo">Ativo</option>
                  <option value="Inativo">Inativo</option>
                </select>
              </fieldset>
            </div>
            <div className="customers-row">
              <div>
                {isXlsExportReady ? (
                  <DownloadXlsButton
                    archiveName={`Relatório de Clientes - ${format(
                      new Date(),
                      'dd/MM/yyyy'
                    )}`}
                    data={dataToExport}
                    className="btn btn-export button-h35 fontSize-12"
                    columns={[
                      {
                        name: 'Tipo',
                        acessor: 'Type',
                      },
                      {
                        name: 'CPF/CNPJ',
                        acessor: 'cpfCnpj',
                      },
                      {
                        name: 'RG/IE',
                        acessor: 'rgOrIE',
                      },
                      {
                        name: 'Nome/Razão Social',
                        acessor: 'Company_Name',
                      },
                      {
                        name: 'Nome Fantasia',
                        acessor: 'Trading_Name',
                      },
                      {
                        name: 'Email',
                        acessor: 'Email',
                      },
                      {
                        name: 'Telefone Comercial',
                        acessor: 'commercialAndHomePhone',
                      },
                      {
                        name: 'Telefone Residencial',
                        acessor: 'commercialAndHomePhone',
                      },
                      {
                        name: 'Celular',
                        acessor: 'phoneToXLS',
                      },
                      {
                        name: 'Nome do Responsável',
                        acessor: 'nameOfResponsible',
                      },
                      {
                        name: 'Inscrição Municipal',
                        acessor: 'IM',
                      },
                      {
                        name: 'Data de Cadastro',
                        acessor: 'registerDate',
                      },
                      {
                        name: 'Data de Atualização',
                        acessor: 'updatedDate',
                      },
                      {
                        name: 'Observações',
                        acessor: 'Observation',
                      },
                      {
                        name: 'CEP(Principal)',
                        acessor: 'principalAddressZipcode',
                      },
                      {
                        name: 'Endereço(Principal)',
                        acessor: 'principalAddressStreet',
                      },
                      {
                        name: 'Nº(Principal)',
                        acessor: 'principalAddressNumber',
                      },
                      {
                        name: 'Bairro(Principal)',
                        acessor: 'principalAddressNeighborhood',
                      },
                      {
                        name: 'Complemento(Principal)',
                        acessor: 'principalAddressComplement',
                      },
                      {
                        name: 'Ponto de Referência(Principal)',
                        acessor: 'principalAddressReference_Point',
                      },
                      {
                        name: 'Estado(Principal)',
                        acessor: 'principalAddressState',
                      },
                      {
                        name: 'Cidade(Principal)',
                        acessor: 'principalAddressCity',
                      },
                      {
                        name: 'CEP(Entrega)',
                        acessor: 'secondaryAddressZipcode',
                      },
                      {
                        name: 'Endereço(Entrega)',
                        acessor: 'secondaryAddressStreet',
                      },
                      {
                        name: 'Nº(Entrega)',
                        acessor: 'secondaryAddressNumber',
                      },
                      {
                        name: 'Bairro(Entrega)',
                        acessor: 'secondaryAddressNeighborhood',
                      },
                      {
                        name: 'Complemento(Entrega)',
                        acessor: 'secondaryAddressComplement',
                      },
                      {
                        name: 'Ponto de Referência(Entrega)',
                        acessor: 'secondaryAddressReference_Point',
                      },
                      {
                        name: 'Estado(Entrega)',
                        acessor: 'secondaryAddressState',
                      },
                      {
                        name: 'Cidade(Entrega)',
                        acessor: 'secondaryAddressCity',
                      },
                    ]}
                  >
                    <FontAwesomeIcon
                      color="white"
                      icon={faCloudDownloadAlt}
                      style={{
                        marginRight: '5px',
                      }}
                    />
                    Exportar .xls
                  </DownloadXlsButton>
                ) : (
                  <button
                    className="btn btn-export button-h35 fontSize-12"
                    onClick={() => loadDataToExport()}
                    disabled={isXlsExportLoading}
                  >
                    {isXlsExportLoading ? (
                      <span className="fa fa-spinner fa-pulse fa-1x"></span>
                    ) : (
                      <FontAwesomeIcon
                        color="white"
                        style={{
                          marginRight: '5px',
                        }}
                        icon={faCloudDownloadAlt}
                      />
                    )}
                    &nbsp;Exportar .xls
                  </button>
                )}
              </div>

              {!isPlanFree && !isPlanStart && (
                <div>
                  <button
                    className="btn btn-export-suggestion button-h35 fontSize-12"
                    onClick={() => setIsImportFromXlsModalOpen(true)}
                    title="Importar Clientes"
                  >
                    <FontAwesomeIcon
                      cursor="pointer"
                      style={{
                        marginRight: '5px',
                      }}
                      icon={faFileUpload}
                    />
                    Importar Clientes
                  </button>
                </div>
              )}

              {!isPlanFree && (
                <div>
                  <button
                    className="btn btn-warning button-h35 fontSize-12"
                    onClick={() => setIsPlateChangeModalOpen(true)}
                    title="Troca Placa"
                  >
                    <FontAwesomeIcon
                      cursor="pointer"
                      style={{
                        marginRight: '5px',
                      }}
                      icon={faCarRear}
                    />
                    Troca Placa (Mercosul)
                  </button>
                </div>
              )}

              <CustomersThatReturnedButton />
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                data={customers}
                columns={[
                  {
                    Header: 'CPF/CNPJ',
                    accessor: 'Cpf_Cnpj',
                    width: 140,
                    Cell: (props) =>
                      props.value.length < 14
                        ? cpfMask(props.value)
                        : cnpjMask(props.value),
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'Type',
                    width: 100,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Fisica' ? 'blue' : 'purple',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Nome',
                    accessor: 'Company_Name',
                  },
                  {
                    Header: 'Telefone',
                    accessor: 'phone',
                    width: 140,
                    Cell: (props) => phoneMask(props.value),
                  },
                  {
                    Header: 'Veículos',
                    accessor: 'vehiclesQuantity',
                    width: 150,
                    Cell: (props) => {
                      return (
                        <button
                          className="btn-link"
                          onClick={() =>
                            handleOpenClientVehiclesModal(
                              props.original,
                              props.index
                            )
                          }
                        >
                          {!props.value
                            ? 'Adicionar Veículo'
                            : props.value > 1
                            ? 'Visualizar Veículos'
                            : props.original.Vehicle[0]?.Maintenance_Object
                                ?.length > 0
                            ? props.original.Vehicle[0]?.Maintenance_Object
                            : props.original.License_Plate
                            ? props.original.License_Plate
                            : props.original.Vehicle[0]?.serialIdentification}
                        </button>
                      );
                    },
                  },
                  {
                    Header: 'Status',
                    accessor: 'Status',
                    width: 100,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Ativo' ? 'green' : 'red',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    width: 140,
                    filterable: false,
                    Cell: (props) => (
                      <ClientActions
                        customer={props.original}
                        loadCustomers={loadCustomers}
                        selectedClientId={selectedClientId}
                        setSelectedClientId={setSelectedClientId}
                      />
                    ),
                  },
                ]}
                defaultPageSize={10}
                pageSize={limit}
                onPageSizeChange={(value) => setLimit(value)}
                page={page}
                onPageChange={(value) => setPage(value)}
                pages={pagesQuantity}
                loading={loading}
                manual
                showPagination
                sortable
                showPaginationTop={false}
                showPaginationBottom
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'Company_Name',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum cliente encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>

            {isVehiclesModalOpen && (
              <Modal
                show
                onHide={hideModalHomeVeiculo}
                animation
                dialogClassName="modal-veiculos"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <strong>Visualizar Veículos</strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <VehiclesTable customerId={customers[clientIndex].id} />
                  {/* <HomeVeiculos
                    idCliente={clients[clientIndex].id}
                    hideModalHomeVeiculo={hideModalHomeVeiculo}
                  /> */}
                </Modal.Body>
              </Modal>
            )}

            {isVehicleFormModalOpen && (
              <FormModalVehicle
                show
                companyId={companyId}
                customerId={customers[clientIndex].id}
                onCancel={() => setIsVehicleFormModalOpen(false)}
                onSubmit={handleIncludeCustomerVehicles}
              />
            )}
          </div>
        }
      />
      <ImportFromXlsModal
        show={isImportFromXlsModalOpen}
        onCancel={() => setIsImportFromXlsModalOpen(false)}
        importType="customers"
        loadItems={loadCustomers}
      />

      <PlateChangeModal
        isPlateChangeModalOpen={isPlateChangeModalOpen}
        setIsPlateChangeModalOpen={setIsPlateChangeModalOpen}
        loadCustomers={loadCustomers}
        companyId={companyId}
      />

      {isXlsExportLoading && (
        <LoadingModal
          showLoadingModal={true}
          customMessage="Preparando exportação... Não recarregue ou feche esta tela"
        />
      )}
    </>
  );
};

export default withRouter(HomeClients);
