import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeInvoices from './Invoices'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Invoices = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Notas Fiscais']}
            path={['home', null]}
          />
          <HomeInvoices />
        </Row>
      </Grid>
    </div>
  );
}

export default Invoices;
