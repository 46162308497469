import React from 'react'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import BundleFormNewAccountPlan from './BundleFormNewAccountPlan'

import './styles.css'

const NewAccountPlan = ({ match }) => {
  return (
    <div className="content">
      <span id='required-field-label'>* Campos Obrigatórios</span>
      <BreadCrumb
        data={['Início', 'Cadastro', 'Financeiro', 'Plano de Contas', !match.params.id ? 'Nova Conta' : 'Editar Conta']}
        path={['home', null, null, 'account-plans', null]}
      />
      <BundleFormNewAccountPlan accountPlanId={match.params.id} />
    </div>
  )
}

export default NewAccountPlan
