import api from 'v2/services/api';

export const listAllCSTConversor = async (companyId) => {
  try {
    const res = await api.get('cst-to-csosn/unqualified', {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getCSTConversor = async (id, companyId) => {
  try {
    const res = await api.get('cst-to-csosn/' + id, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getByCSTandCFOP = async ({ companyId, cfop, cst }) => {
  try {
    const res = await api.get('cst-to-csosn/get-by-cst', {
      params: {
        companyId,
        cst,
        cfop,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listCSTConversor = async (params) => {
  try {
    const res = await api.get('cst-to-csosn', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createCSTConversor = async (data) => {
  try {
    const res = await api.post('cst-to-csosn', data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateCSTConversor = async (id, data) => {
  try {
    const res = await api.put('cst-to-csosn/' + id, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteCSTConversor = async (id) => {
  try {
    await api.delete('cst-to-csosn/' + id);
  } catch (err) {
    throw err;
  }
};

const CSTConversorRepository = {
  listAllCSTConversor,
  getCSTConversor,
  getByCSTandCFOP,
  listCSTConversor,
  createCSTConversor,
  updateCSTConversor,
  deleteCSTConversor,
};

export default CSTConversorRepository;
