import api from 'services/api'

const update = async (id, values) => {
  const res = await api.put(`/koin-alert/${id}`, values)
  return res.data
}

const visualizeAll = async (companyId, values) => {
  const res = await api.put(`/koin-alert/by-company/${companyId}`, values)
  return res.data
}

const KoinAlert = {
  update,
  visualizeAll,
}

export default KoinAlert
