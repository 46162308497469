import React, { useEffect, useState, useMemo } from 'react';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import CollapsibleSection from 'components/CollapsibleSection';
import { useAuth } from 'contexts/auth';

import { DefaultContentContainer } from 'v2/components/layout';
import { InputSelectDefault } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputTextArea } from 'v2/components/Input';

import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { useRouter } from 'v2/hooks/useRouter';
import {
  getSection,
  createSection,
  updateSection,
} from 'v2/repositories/SectionRepository';

import {
  defaultFormValues,
  formSchema,
  getBreadcrumbData,
  getDepartmentOptions,
  validateSectionDescriptionUse,
} from './utils';

import { FormContainer } from './FormSection.styles';
import { FormDepartmentModal } from '../../Department/Form';

export function FormSection({
  hideBreadCrumb,
  hideActionButtons,
  sectionIdProp,
  onSubmitSuccess,
  triggerSubmit,
  allowEdit = true,
}) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { sectionId } = useParams();

  const sectionIdValue = useMemo(() => {
    return sectionIdProp || sectionId;
  }, [sectionIdProp, sectionId]);

  const isEditing = !!sectionIdValue && allowEdit;

  const [isLoading, setIsLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [isFormDepartmentModalOpen, setIsFormDepartmentModalOpen] =
    useState(false);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: defaultFormValues,
  });

  const { isActive } = useWatch({
    control,
  });

  const handleChangeActiveToggle = () => {
    setValue('isActive', !isActive);
  };

  const handleChangeSectionInput = (e) => {
    const value = formatToLettersOnly(e.target.value);

    if (value.length > 30) return;
    setValue('description', formatToLettersOnly(value));
  };

  const handleChangeDepartmentInput = (value) => {
    setValue('department', value);
  };

  const handleErrors = () => {
    if (Object.keys(errors).length) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
    }
  };

  const handleCreate = async (data) => {
    try {
      const res = await createSection({
        ...data,
        departmentId: data.department.value,
        companyId,
      });
      toastr.success('Cadastro realizado', 'Seção cadastrado com sucesso');

      if (onSubmitSuccess) {
        return onSubmitSuccess({
          ...res,
          departmentDescription: data.department.label,
        });
      }

      navigateToClientPage('section', 'base');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      await updateSection(sectionIdValue, {
        ...data,
        departmentId: data.department.value,
        companyId,
      });
      toastr.success('Edição realizada', 'Seção alterada com sucesso');
      navigateToClientPage('section', 'base');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    setIsLoading(true);

    try {
      const descriptionValid = await validateSectionDescriptionUse(
        data.description,
        companyId,
        sectionIdValue
      );

      if (descriptionValid) {
        return isEditing ? handleUpdate(data) : handleCreate(data);
      }

      toastr.warning(
        'Não foi possível salvar',
        'Já existe uma seção com essa descrição. Verifique e tente novamente.'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadFields = async () => {
    setIsLoading(true);
    try {
      const res = await getSection(sectionIdValue, companyId);
      if (!res) throw 'not found';

      setValue('description', res.description);
      setValue('isActive', res.isActive);
      setValue('observations', res.observations);

      const departmentOptionToSelect = departmentOptions.find(
        (option) => +option.value === +res.departmentId
      );

      setValue('department', departmentOptionToSelect);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para edição',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
      navigateToClientPage('section', 'base');
    } finally {
      setIsLoading(false);
    }
  };

  const loadDepartmentsOptions = async () => {
    setIsLoading(true);
    try {
      const options = await getDepartmentOptions(companyId);
      setDepartmentOptions(options);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os departamentos',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewDepartment = (department) => {
    loadDepartmentsOptions();
    setValue('department', {
      value: department.id,
      label: department.description,
    });
  };

  useEffect(() => {
    loadDepartmentsOptions();
  }, []);

  useEffect(() => {
    handleErrors();
  }, [errors]);

  useEffect(() => {
    if (sectionIdValue && departmentOptions.length > 0 && allowEdit) {
      handleLoadFields();
    }
  }, [sectionIdValue, departmentOptions]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit(submitForm)();
    }
  }, [triggerSubmit]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel={!hideBreadCrumb}>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados da Seção"
          isExpanded={true}
          handleExpand={() => null}
        >
          <form method="POST">
            <FormContainer>
              <div className="row-1">
                <InputContainer>
                  <InputLabel htmlFor="input__description" required>
                    Seção:
                  </InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={30}
                    width="300"
                    {...register('description')}
                    onChange={handleChangeSectionInput}
                  />
                </InputContainer>
                <InputContainer align="flex-start">
                  <InputLabel htmlFor="input__department" required>
                    Departamento:
                  </InputLabel>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                      <InputSelectDefault
                        width="300px"
                        height="35px"
                        options={departmentOptions}
                        onChange={handleChangeDepartmentInput}
                        {...field}
                      />
                    )}
                  />
                  <div className="flex end" style={{ width: '100%' }}>
                    <a onClick={() => setIsFormDepartmentModalOpen(true)}>
                      Adicionar Departamento
                    </a>
                  </div>
                </InputContainer>
                <InputContainer variant="column" align="center">
                  <InputLabel htmlFor="input__is-active">Status:</InputLabel>

                  <div className="flex center" style={{ height: '40px' }}>
                    <Toggle
                      id="input__is-active"
                      checked={isActive}
                      onChange={() => handleChangeActiveToggle()}
                    />
                  </div>
                </InputContainer>
              </div>

              <div className="row-2">
                <InputContainer className="input__container">
                  <InputLabel htmlFor="input__observations">
                    Observações:
                  </InputLabel>
                  <InputTextArea
                    id="input__observations"
                    rows={4}
                    maxLength={100}
                    {...register('observations')}
                  />
                </InputContainer>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>
      </div>
      {!hideActionButtons && (
        <div className="flex align-center end gap-075 mt-2">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={() => navigateToClientPage('section', 'base')}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            type="submit"
            style={{ width: '100px' }}
            onClick={handleSubmit(submitForm)}
            disabled={isLoading}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      )}

      {isFormDepartmentModalOpen && (
        <FormDepartmentModal
          isOpen={isFormDepartmentModalOpen}
          handleClose={() => setIsFormDepartmentModalOpen(false)}
          afterSubmit={handleNewDepartment}
        />
      )}
    </DefaultContentContainer>
  );
}
