import api from '../services/api'

const getAllByCompany = async (params) => {
  const { data } = await api.get('sale-requisition', { params })
  return data
}

const getSaleRequisition = async (id) => {
  const { data } = await api.get(`sale-requisition/sale/${id}`)
  return data
}

const create = async (values) => {
  const { data } = await api.post('sale-requisition', values)
  return data
}

const view = async (id) => {
  const { data } = await api.get(`sale-requisition/${id}`)
  return data
}

const cancelRequisition = async (id) => {
  const { data } = await api.put(`sale-requisition/${id}/cancel`)
  return data
}

export default {
  getAllByCompany,
  getSaleRequisition,
  create,
  view,
  cancelRequisition,
}
