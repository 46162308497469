import config from '../config'
import api from '../services/api'

const create = (data) => api.post(config.endpoint + 'suiv-company', data)

const update = (companyId, data) =>
  api.put(config.endpoint + `suiv-company/${companyId}`, data)

const showCompany = (companyId) =>
  api.get(config.endpoint + `suiv-company/${companyId}`)

const showVehicleInfo = async (plate, companyId, onlyVehicleInfo = false) => {
  try {
    const res = await api.get(
      config.endpoint +
        `suiv-company/vehicle-info?plate=${plate}&companyId=${companyId}&onlyVehicleInfo=${onlyVehicleInfo}`
    )
    return res
  } catch (err) {
    throw err
  }
}

const getVehicleToken = async ({
  searchType,
  plate,
  fipeId,
  yearManufacturer,
}) => {
  try {
    const res = await api.get(config.endpoint + `catalog/vehicle/token`, {
      params: {
        searchType,
        plate,
        fipeId,
        yearManufacturer,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

const getSets = async ({ vehicleToken }) => {
  try {
    const res = await api.get(config.endpoint + `catalog/set`, {
      params: {
        vehicleToken,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

const getNicknameBySet = async ({ vehicleToken, setId }) => {
  try {
    const res = await api.get(config.endpoint + `catalog/nickname`, {
      params: {
        vehicleToken,
        setId,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

const getNicknamesByDescription = async ({ vehicleToken, search }) => {
  try {
    const res = await api.get(config.endpoint + `catalog/search/nickname`, {
      params: {
        vehicleToken,
        search,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}

const getPartsByNickname = async ({ vehicleToken, nicknameId }) => {
  try {
    const res = await api.get(config.endpoint + `parts`, {
      params: {
        vehicleToken,
        nicknameId,
      },
    })
    return res.data
  } catch (err) {
    throw err
  }
}
const createGenuineParts = async ({ companyId, parts }) => {
  try {
    const res = await api.post(config.endpoint + `suiv-vehicle/genuine-parts`, {
      companyId,
      parts,
    })

    return res.data
  } catch (err) {
    throw err
  }
}

const getFipeTableByBrands = async ({ brand }) => {
  const { data } = await api.get(
    config.endpoint + `tabelaFipe/byBrands/${brand}`
  )
  return data
}

/// new routes to handle new way to vinculate companies to suiv

const vinculateCompany = async ({ companyId, email }) => {
  const { data } = await api.post(
    config.endpoint + `suiv-company/suiv-integrator`,
    {
      companyId,
      email,
    }
  )
  return data
}

const getSuivCompaniesPaginated = async ({
  initialDate,
  finalDate,
  query,
  isIntegrated,
  page,
  limit,
}) => {
  const { data } = await api.get(
    config.endpoint + `suiv-company/list/paginated`,
    {
      params: {
        initialDate,
        finalDate,
        query,
        isIntegrated,
        page,
        limit,
      },
    }
  )

  return data
}

const getNotIntegratedCompanies = async () => {
  const { data } = await api.get(
    config.endpoint + `suiv-company/list/suiv/not-integrated`
  )
  return data
}

const updateSuivCompany = async (suivCompanyId, data) => {
  const { data: response } = await api.put(
    config.endpoint + `suiv-company/suiv-integrator/${suivCompanyId}`,
    data
  )
}

export default {
  create,
  update,
  showCompany,
  getSets,
  getNicknameBySet,
  getNicknamesByDescription,
  showVehicleInfo,
  getVehicleToken,
  getPartsByNickname,
  createGenuineParts,
  getFipeTableByBrands,
  getSuivCompaniesPaginated,
  updateSuivCompany,
  getNotIntegratedCompanies,
  vinculateCompany,
}
