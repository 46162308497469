import { onlyNumbers } from "client/components/ToNormalize/ToNormalize"
import { addDays, addMonths, format } from 'date-fns';


const createInstallments = (condition, hasCashierControl, defaultCashierBankId) => {
  const installments = []
  const daysBetweenInstallment = Number(condition.daysBetweenInstallment)
  const parcelNumbers = Number(onlyNumbers(condition.Condition))
  let conditionTimes = parcelNumbers

  if (conditionTimes === 0) {
    installments.push({
      parcel: 'À vista',
      dueDate: format(new Date(), 'yyyy-MM-dd'),
      value: 0,
      formOfPaymentId: '',
      automaticDownload: false,
      cashierBankId: hasCashierControl ? defaultCashierBankId : null
    })

    return installments
  }

  if (condition.hasEntry) {
    conditionTimes++
  }

  let countMonth = 1
  let countDays = 1
  let countParcel = 1

  for (let i = 0; i < conditionTimes; i++) {

    let dueDate = ''
    if (condition.hasEntry && i === 0) {
      dueDate = format(new Date(), 'yyyy-MM-dd')
    } else {

      if (daysBetweenInstallment === 30) {
        dueDate = format(addMonths(new Date(), countMonth), 'yyyy-MM-dd')
        countMonth++
      } else {
        dueDate = format(addDays(new Date(), daysBetweenInstallment * countDays), 'yyyy-MM-dd')
        countDays++
      }
    }

    let parcel = 0

    if (i === 0 && condition.hasEntry) {
      parcel = 'Entrada'
    } else {
      parcel = `${parcel + countParcel}/${parcelNumbers}`
      countParcel++
    }

    installments.push({
      parcel,
      dueDate,
      value: 0,
      formOfPaymentId: '',
      automaticDownload: false,
      cashierBankId: hasCashierControl ? defaultCashierBankId : null
    })
  }

  return installments
}

const splitParcelsValue = (installments, total) => {
  if (installments.length === 1) {
    installments[0].value = total
  } else {
    const parcelValue = Number((total / installments.length).toFixed(2))
    const rest = total - (parcelValue * installments.length)

    installments.forEach(installment => {
      installment.value = parcelValue
    })

    installments[installments.length - 1].value += rest
  }

  return installments
}

export {
  createInstallments,
  splitParcelsValue
}