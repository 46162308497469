import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { change, initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import Loader from '../../../../../components/Loader';
import Form from './FormNewSATCFe';
import ErrorsModal from './ErrorsModal';
import InvoiceProcessLoader from '../../../../components/InvoiceProcessLoader';

import {
  cnpjMask,
  cpfMask,
  onlyNumbers,
} from '../../../../components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../../contexts/auth';
import SATCFesRepository from '../../../../../repositories/SATCFe';
import companiesRepository from '../../../../../repositories/Companies';
import constants, {
  INVOICE_STATUS_DESCRIPTION,
} from '../../../../../utils/constants';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';

const FormNewSATCFe = ({ history, SATCFeId, setCode }) => {
  const [loading, setLoading] = useState(false);
  const [isInvoiceLoaderOpen, setIsInvoiceLoaderOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isIncludeCpfCnpj, setIsIncludeCpfCnpj] = useState(false);
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saleId, setSaleId] = useState();

  const dispatch = useDispatch();
  const { companyId, company } = useAuth();

  useEffect(() => {
    if (!SATCFeId) {
      history.push(constants.ROUTES.INVOICES);
    }
    loadSATCFe();
  }, [SATCFeId]);

  async function loadSATCFe() {
    setLoading(true);

    try {
      const satcfe = await SATCFesRepository.show(SATCFeId);
      setCode(satcfe.code);
      setSaleId(satcfe.saleId);
      const statusSATCFe = getSATCFeStatus(satcfe);

      if (satcfe.status === 'Aberta' || satcfe.status === 'Aberta c/ Erro') {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

      let cliente =
        satcfe.SATCustomer.Trading_Name ||
        satcfe.SATCustomer.Company_Name ||
        '';

      let customerCpfCnpj = null;

      if (satcfe.cpfCnpj) {
        customerCpfCnpj = isDefaultCustomer(satcfe.SATCustomer)
          ? satcfe.cpfCnpj == '00000000000'
            ? ''
            : satcfe.cpfCnpj.length === 11
            ? cpfMask(satcfe.cpfCnpj)
            : cnpjMask(satcfe.cpfCnpj)
          : satcfe.SATCustomer.Cpf_Cnpj.length === 11
          ? cpfMask(satcfe.SATCustomer.Cpf_Cnpj)
          : cnpjMask(satcfe.SATCustomer.Cpf_Cnpj);
      }

      const satcfeObject = {
        id: satcfe.id,
        sat: satcfe.code,
        serie: satcfe.serie,
        venda: satcfe.SATSale.Code,
        statusSATCFe: statusSATCFe,
        dataCriacao: format(new Date(), 'yyyy-MM-dd'),
        dataEmissao: !satcfe.issuedAt
          ? ''
          : format(new Date(satcfe.issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(satcfe.issuedAt), 'hh:mm'),
        chaveDeAcesso: formatAccessKey(satcfe.accessKey),
        customerId: satcfe.customerId,
        situationCode: satcfe.situationCode,
        cliente,
        cnpjCpf: customerCpfCnpj,
        customerHasCpfCnpj: !!satcfe.SATCustomer.Cpf_Cnpj,
        subTotal: satcfe.subTotal,
        isDefaultCustomer: isDefaultCustomer(satcfe.SATCustomer),
        customerType: satcfe.SATCustomer.Type,
        totalProdutos: satcfe.total,
        discount: satcfe.discount,
        SATItems: satcfe.SATItems,
        bcICMS: satcfe.bcICMS,
        valueICMS: satcfe.valueICMS,
        bcPIS: satcfe.bcPIS,
        valuePIS: satcfe.valuePIS,
        bcCOFINS: satcfe.bcCOFINS,
        valueCOFINS: satcfe.valueCOFINS,
        total: satcfe.total,
        status: satcfe.status,
        isInvoiceOpen: satcfe.status === INVOICE_STATUS_DESCRIPTION.OPEN,
        isInvoiceCanceled:
          satcfe.status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        isInvoiceIssued: satcfe.status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          satcfe.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
      };
      dispatch(initialize('cadastroSATCFe', satcfeObject));
      loadCompanyTaxRegime();
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o SAT. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  async function loadCompanyTaxRegime() {
    try {
      const taxData = await companiesRepository.getTaxData(companyId);

      dispatch([
        change(
          'cadastroSATCFe',
          'isTaxRegimeSimpleNational',
          taxData.TaxRegime === 'Simples Nacional' ||
            taxData.TaxRegime === 'MEI - Simples Nacional'
        ),
        change('cadastroSATCFe', 'companyPIS', taxData.PIS),
        change('cadastroSATCFe', 'companyCOFINS', taxData.COFINS),
        change('cadastroSATCFe', 'settingsNFCe', taxData.SettingsNFCe),
      ]);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados fiscais da empresa. Por favor, tente novamente'
      );
    }
  }

  function getSATCFeStatusLabel(code) {
    switch (code) {
      case '100':
        toastr.success('SAT emitido com sucesso.');
        return 'Emitida';
      case '105':
      case '108':
      case '109':
        toastr.warning('SAT em contingência.');
        return 'Em Contingência';
      default:
        toastr.error(
          'Falha na emissão do SAT, verifique o motivo do erro na tela do SAT, no menu Notas Fiscais.'
        );
        return 'Aberta c/ Erro';
    }
  }

  function handleSubmit(values) {
    const { includeCpfCnpj } = values;
    setIsIncludeCpfCnpj(includeCpfCnpj);
    return handleEmit(values);
  }

  async function handleEmit(values) {
    const { includeCpfCnpj, cnpjCpf, id } = values;

    setIsInvoiceLoaderOpen(true);
    try {
      const result = await SATCFesRepository.emit(id, {
        includeCpfCnpj,
        customerCpfCnpj:
          cnpjCpf === null ? '00000000000' : cnpjCpf.replace(/\D/g, ''),
        issuedAt: new Date().getTime(),
      });

      const { printInvoicesAutomatically } =
        await companiesRepository.getTaxData(companyId);

      const {
        situationCode,
        situationDescription,
        status,
        bcICMS,
        valueICMS,
        bcPIS,
        valuePIS,
        bcCOFINS,
        chaveAcesso,
        valueCOFINS,
        dataEmissao,
      } = result;

      const statusSATCFe = getSATCFeStatus({
        status,
        situationDescription,
        situationCode,
      });
      const statusLabel = getSATCFeStatusLabel(situationCode);

      const isInvoiceIssued = status === INVOICE_STATUS_DESCRIPTION.ISSUED;

      dispatch([
        change('cadastroSATCFe', 'status', statusLabel),
        change('cadastroSATCFe', 'statusSATCFe', statusSATCFe),
        change('cadastroSATCFe', 'isInvoiceIssued', isInvoiceIssued),
        change(
          'cadastroSATCFe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'cadastroSATCFe',
          'dataEmissao',
          format(new Date(dataEmissao), 'yyyy-MM-dd') +
            'T' +
            format(new Date(dataEmissao), 'hh:mm')
        ),
      ]);

      if (isInvoiceIssued) {
        dispatch([
          change('cadastroSATCFe', 'bcICMS', bcICMS),
          change('cadastroSATCFe', 'valueICMS', valueICMS),
          change('cadastroSATCFe', 'bcPIS', bcPIS),
          change('cadastroSATCFe', 'valuePIS', valuePIS),
          change('cadastroSATCFe', 'bcCOFINS', bcCOFINS),
          change('cadastroSATCFe', 'valueCOFINS', valueCOFINS),
          change(
            'cadastroSATCFe',
            'chaveDeAcesso',
            formatAccessKey(chaveAcesso)
          ),
        ]);

        if (printInvoicesAutomatically) {
          const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31?fiscal=true`;
          const satDocumentLink = `${window.location.origin}/sheets/${hash}`;

          window.open(satDocumentLink, '_blank');
        }
      }

      setIsInvoiceLoaderOpen(false);
    } catch (err) {
      console.error(err);
      setIsInvoiceLoaderOpen(false);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        handleOpenErrorsModal(err.response.data.errors);
      } else {
        toastr.warning(
          'Ocorreu uma falha ao enviar a nota',
          err.response.data.message
        );
      }
    }
  }

  function handleOpenErrorsModal(errors) {
    const serializedErrors = errors.map((error) => ({
      ...error,
      isResolved: false,
    }));
    setErrors(serializedErrors);
    setIsErrorsModalOpen(true);
  }

  function formatAccessKey(value) {
    if (!value) return value;
    value = onlyNumbers(value);
    return (
      value.substring(0, 4) +
      ' ' +
      value.substring(4, 8) +
      ' ' +
      value.substring(8, 12) +
      ' ' +
      value.substring(12, 16) +
      ' ' +
      value.substring(16, 20) +
      ' ' +
      value.substring(20, 24) +
      ' ' +
      value.substring(24, 28) +
      ' ' +
      value.substring(28, 32) +
      ' ' +
      value.substring(32, 36) +
      ' ' +
      value.substring(36, 40) +
      ' ' +
      value.substring(40, 44)
    );
  }

  function getSATCFeStatus({ status, situationDescription, situationCode }) {
    if (!situationDescription) return status;

    if (situationDescription !== 'Autorizada') {
      return situationCode + ' - ' + situationDescription;
    }

    return 'Autorizado o uso do SAT';
  }

  function handleRetryEmit() {
    setIsErrorsModalOpen(false);
    setIsInvoiceLoaderOpen(true);
    handleEmit({
      id: SATCFeId,
      includeCpfCnpj: isIncludeCpfCnpj,
    });
  }

  const initialValues = {
    cnpjCpf: '',
    includeCpfCnpj: false,
    customerId: 0,
    SATItems: [],
    editItem: [],
    modalProduto: false,
    situationCode: 0,
    subTotal: 0,
    totalProdutos: 0,
    discount: 0,
    status: '',
    bcICMS: 0,
    valueICMS: 0,
    bcPIS: 0,
    valuePIS: 0,
    bcCOFINS: 0,
    valueCOFINS: 0,
    total: 0,
  };
  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={handleSubmit}
        onCancel={() => history.push(constants.ROUTES.INVOICES)}
        initialValues={initialValues}
        loading={loading}
        disabled={disabled}
      />
      {isErrorsModalOpen && (
        <ErrorsModal
          setErrors={setErrors}
          errors={errors}
          onCancel={() => setIsErrorsModalOpen(false)}
          onRetry={handleRetryEmit}
        />
      )}
      {isInvoiceLoaderOpen && (
        <InvoiceProcessLoader message="Estamos realizando o envio da nota fiscal. Por favor, aguarde." />
      )}

      {loading && <Loader message="Aguarde, carregando SAT" />}
    </div>
  );
};

export default withRouter(FormNewSATCFe);
