import React from 'react';

import { Grid, Row } from 'react-bootstrap';

import Main from './components/TradeRepresentativeMain';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import './index.css';

export default function TradeRepresentative({ match, history }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Vendas', 'Meus Representantes']}
            path={['home', null, null, null]}
          />
          <Main />
        </Row>
      </Grid>
    </div>
  );
}
