import React from 'react';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { Col } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { cpfMask, name, email, phoneMask, onlyNumbers } from '../../../../components/ToNormalize/ToNormalize';

function PF({ edit }) {

  function checkComercialPhone(value) {
    value = onlyNumbers(value);
    if (value.charAt([0]) === "0" ) return false;
    return true;
  };

  return (
    <>
      <Col lg={2} md={2} sm={3} xs={12}>
        <Field
          label="CPF"
          required
          name="Cpf_Cnpj"
          component={Input}
          normalize={cpfMask}
          placeholder="445.995.420-67"
          maxLength="14"
          disabled={edit}
        />
      </Col>
      <Col lg={4} md={6} sm={6} xs={12}>
        <Field
          label="Nome"
          required
          name="Name"
          component={Input}
          normalize={name}
          placeholder="Insira o nome do seu cliente"
          maxLength="80"
        />
      </Col>
      <Col lg={3} md={3} sm={3} xs={12}>
        <Field
          label="E-mail"
          name="Email"
          type="text"
          placeholder="seucliente@gmail.com"
          component={Input}
          normalize={email}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone"
          name="Number_Phone1"
          placeholder="(21)2872-2294"
          component={Input}
          normalize={value => {
            if (!checkComercialPhone(value)) return
            return phoneMask(value)
          }}
        />
      </Col>
    </>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'cadastroTransportadora'
})(PF)