import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';
import * as yup from 'yup';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Produtos/Serviços',
      'Checklist',
      `${isEditing ? 'Editar' : 'Novo'} Checklist`,
    ],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.CHECKLIST.BASE,
      null,
    ],
  };
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  id: yup.number().optional().nullable(),
  defaultDesign: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  orderBy: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
});

export const optionsOrderBySelect = [
  {
    label: 'Ordem Alfabética',
    value: 'Alphabetical',
  },
  {
    label: 'Lançamento na tabela itens',
    value: 'itens_index',
  },
];
