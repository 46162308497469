import React, { createContext, useContext, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import MaintenanceRepository from 'repositories/Maintenance';
import employeesRepository from 'repositories/Employees';

import { useAuth } from 'contexts/auth';
import { MaintenanceServicesRepository } from 'v2/repositories/MaintenanceServicesRepository';

const WorkshopPanelContext = createContext();

export default function WorkshopPanelProvider({ children }) {
  const { company } = useAuth();

  const [reloadPanelInformation, setReloadPanelInformation] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(true);

  const [maintenances, setMaintenances] = useState([]);
  const [isLoadingMaintenances, setIsLoadingMaintenances] = useState(true);

  const [isLoadingPanelInformation, setIsLoadingPanelInformation] =
    useState(true);

  const [lastRefresh, setLastRefresh] = useState(new Date());

  function hasMaintenanceEmployees(maintenance) {
    return (
      maintenance?.MaintenanceEmployees?.length > 0 ||
      maintenance?.employees?.length > 0
    );
  }

  function getOnlyMaintenanceEmployeesIds(maintenanceEmployees) {
    return maintenanceEmployees.map(
      (maintenanceEmployee) =>
        maintenanceEmployee.employeeId || maintenanceEmployee.id
    );
  }

  function handleSetEmployees(maintenances, employees) {
    const busyEmployees = maintenances
      .filter((maintenance) => hasMaintenanceEmployees(maintenance))
      .map((maintenance) => {
        return getOnlyMaintenanceEmployeesIds(
          maintenance?.MaintenanceEmployees || maintenance.employees
        );
      })
      .reduce((prevEmployees, currEmployees) => {
        return [...prevEmployees, ...currEmployees];
      }, []);

    const mappedEmployees = employees.map((employee) => {
      if (busyEmployees.includes(employee.id)) {
        return { ...employee, show: false };
      } else {
        return { ...employee, show: true };
      }
    });

    setEmployees(mappedEmployees);
  }

  const getEmployees = async (companyId) => {
    setIsLoadingEmployees(true);
    try {
      const res =
        await employeesRepository.getEmployeesThatPerformServicesOrSellProducts(
          companyId,
          {
            isActive: 1,
            performService: 1,
          }
        );

      return res.data;
    } catch (err) {
      toastr.error(
        'Erro ao carregar funcionários',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoadingEmployees(false);
    }
  };

  const getMaintenances = async (companyId, workShopPanelMode = 'default') => {
    setIsLoadingMaintenances(true);
    try {
      if (workShopPanelMode === 'default') {
        const res = await MaintenanceRepository.getAllMaintenancesByCompanyId(
          companyId
        );
        return res;
      }

      const res = await MaintenanceServicesRepository.getAllByCompanyId(
        companyId
      );

      return res;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as manutenções',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoadingMaintenances(false);
    }
  };

  const loadPanelInformation = async (companyId, panelMode) => {
    try {
      const maintenances = await getMaintenances(companyId, panelMode);
      const employees = await getEmployees(companyId);

      setMaintenances(maintenances);

      handleSetEmployees(maintenances, employees);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as informações do painel',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoadingPanelInformation(false);
      setReloadPanelInformation(false);
    }
  };

  return (
    <WorkshopPanelContext.Provider
      value={{
        reloadPanelInformation,
        setReloadPanelInformation,
        loadPanelInformation,
        getMaintenances,
        getEmployees,

        employees,
        isLoadingEmployees,

        maintenances,
        isLoadingMaintenances,

        isLoadingPanelInformation,

        lastRefresh,
        setLastRefresh,
      }}
    >
      {children}
    </WorkshopPanelContext.Provider>
  );
}

export function useWorkshopPanel() {
  const context = useContext(WorkshopPanelContext);
  return context;
}
