import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addHours, format } from 'date-fns'
import React from 'react'
import DownloadXlsButton from 'components/DownloadXlsButton'
import xlsColumns from './ExcelHelper'
import InputMask from 'react-input-mask'
import './styles.css'

function ProductsCurveFilter({
  loading,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  type,
  setType,
  curveA,
  curveB,
  curveC,
  setCurveA,
  setCurveB,
  setCurveC,
  handleSubmit,
  products,
}) {
  return (
    <div className="filters-div">
      <div>
        <span>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          name="initialDate"
          max={finalDate}
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
        />
      </div>

      <div>
        <span>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          name="finalDate"
          min={initialDate}
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
        />
      </div>

      <div>
        <span>
          <strong>Tipo:</strong>
        </span>
        <select
          className="form-control foco-input"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="Faturamento">Faturamento</option>
          <option value="Quantidade">Quantidade</option>
        </select>
      </div>

      <div>
        <span>
          <strong>Curva A:</strong>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <InputMask
            style={{
              width: '50px',
              textAlign: 'center',
            }}
            type="number"
            maxLength="2"
            className="form-control foco-input"
            value={curveA}
            onChange={(e) => setCurveA(e.target.value.replaceAll('_', ''))}
          />
        </div>
      </div>

      <div>
        <span>
          <strong>Curva B:</strong>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <InputMask
            style={{
              width: '50px',
              textAlign: 'center',
            }}
            type="number"
            maxLength="2"
            className="form-control foco-input"
            value={curveB}
            onChange={(e) => setCurveB(e.target.value)}
          />
        </div>
      </div>

      <div>
        <span>
          <strong>Curva C:</strong>
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <InputMask
            style={{
              width: '50px',
              textAlign: 'center',
            }}
            type="number"
            maxLength="2"
            className="form-control foco-input"
            value={curveC}
            onChange={(e) => setCurveC(e.target.value)}
          />
        </div>
      </div>

      <div style={{ justifyContent: 'flex-end' }}>
        <button className="btn btn-sucesso" onClick={handleSubmit}>
          <span
            className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />
          Processar
        </button>
      </div>

      <div
        style={{
          justifyContent: 'flex-end',
          marginBottom: '-4px',
        }}
      >
        <DownloadXlsButton
          archiveName={`Curva ABC - ${format(
            addHours(new Date(initialDate || null), 3),
            'dd/MM/yyyy'
          )}- ${format(
            addHours(new Date(finalDate || null), 3),
            'dd/MM/yyyy'
          )}`}
          data={products}
          className="btn btn-export"
          disabled={loading || !products}
          columns={xlsColumns}
        >
          <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
          .xls
        </DownloadXlsButton>
      </div>
    </div>
  )
}

export default ProductsCurveFilter
