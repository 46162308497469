import React, { useEffect, useState } from 'react'
import ProductsCurveTable from './ProductsCurveTable'
import ProductsCurveFilter from './ProductsCurveFilter'
import Card from '../../../../../../components/Card/Card'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import abcCurve from 'repositories/ABCCurve'
import { isAfter } from 'date-fns/esm'

function ProductsCurveMain() {
  const { companyId } = useAuth()
  const [loading, setLoading] = useState()
  const [products, setProducts] = useState()
  const [curveA, setCurveA] = useState(70)
  const [curveB, setCurveB] = useState(20)
  const [curveC, setCurveC] = useState(10)
  const [type, setType] = useState('Faturamento')
  const [initialDate, setInitialDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())

  const loadProducts = async () => {
    setLoading(true)
    try {
      if (!validations()) return
      const params = {
        initialDate,
        finalDate,
        type,
        curveA,
        curveB,
        curveC,
      }

      const data = await abcCurve.getProductsCurveReport(companyId, params)

      if (!data.length) {
        return toastr.warning(
          'Nenhum retorno foi identificado com os filtros selecionados.'
        )
      }

      let serializedProducts = data.map((items) => ({
        Code: items.Products?.Code,
        Manufacturer_Ref: items.Products?.Manufacturer_Ref,
        Description: items.Description,
        Unit_Value: items.Products ? items.Products?.Sale_Price : 0,
        TotalAmount: items.TotalAmount,
        TotalQuantity: items.TotalQuantity,
        curve: items.curve,
        participation: items.participation,
        cumulative: items.cumulative,
      }))

      setProducts(serializedProducts)
    } catch (err) {
      console.log(err)
      return toastr.warning('Ocorreu um erro ao gerar o relatório.')
    } finally {
      setLoading(false)
    }
  }

  const validations = () => {
    if (!initialDate || !finalDate) {
      toastr.warning(
        'Não foi possível gerar o relatório',
        'Preencha as datas para gerar o relatório e tente novamente.'
      )
      return false
    }

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório',
        'A data inicial não pode ser maior do que o campo de data final.'
      )
      return false
    }

    if (!curveA || !curveB || !curveC) {
      toastr.warning(
        'Não foi possível gerar o relatório',
        'Preencha todo os campos para gerar o relatório e tente novamente.'
      )
      return false
    }

    if (Number(curveA) <= 0 || Number(curveB) <= 0 || Number(curveC) <= 0) {
      toastr.warning(
        'Não foi possível gerar o relatório',
        'Insira curvas maiores que 0 para gerar o relatório e tente novamente.'
      )
      return false
    }

    if (Number(curveA) + Number(curveB) + Number(curveC) > 100) {
      toastr.warning(
        'Não foi possível inserir o valor da curva',
        'Com o valor inserido, ultrapassará os 100% do faturamento. Por favor, insira um valor válido e tente novamente.'
      )
      setCurveA(70)
      setCurveB(20)
      setCurveC(10)
    }

    return true
  }

  useEffect(() => {
    if (curveA && curveB) {
      setCurveC(100 - (Number(curveA) + Number(curveB)))
    }
  }, [curveA, curveB, curveC])

  return (
    <Card
      content={
        <div>
          <ProductsCurveFilter
            loading={loading}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            type={type}
            setType={setType}
            curveA={curveA}
            curveB={curveB}
            curveC={curveC}
            setCurveA={setCurveA}
            setCurveB={setCurveB}
            setCurveC={setCurveC}
            handleSubmit={loadProducts}
            products={products}
          />

          <ProductsCurveTable loading={loading} products={products} />
        </div>
      }
    />
  )
}

export default ProductsCurveMain
