import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { addDays, format } from 'date-fns'
import SalesRepository from 'repositories/Sales'
import SalesByFormPaymentPDF from './SalesByFormPaymentPDF'

const SalesByFormPaymentReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [displayItens, setDisplayItens] = useState('')
  const [groupBy, setGroupBy] = useState('')
  const [result, setResult] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const Company_id = searchParams.get('companyId')
      const displayItens = searchParams.get('displayItens')
      const groupBy = searchParams.get('groupBy')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))
      setDisplayItens(displayItens)
      setGroupBy(groupBy)

      return loadReport({
        Company_id,
        initialDate,
        finalDate,
      })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (params) => {
    try {
      const data = await SalesRepository.generateSalesByFormPayment({
        ...params
      })

      setResult(data)
      setLoading(false)
      return data
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <SalesByFormPaymentPDF
            result={result}
            initialDate={initialDate}
            finalDate={finalDate}
            groupBy={groupBy}
            displayItens={displayItens}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default SalesByFormPaymentReport
