import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { toastr } from 'react-redux-toastr';

import RenderField from 'components/RenderField';
import formsOfPaymentRepository from '../../../repositories/PaymentForms';
import AlertModal from 'components/AlertModal/AlertModal';
import constants from '../../../utils/constants';
import { useAuth } from 'contexts/auth';

import './styles.css';
import { format, isAfter, isSameDay } from 'date-fns';

const BillPayment = ({ name }) => {
  const [formsOfPayment, setFormsOfPayment] = useState([]);
  const [indexToRemove, setIndexToRemove] = useState(0);
  const [isRemovePaymentModalOpen, setIsRemovePaymentModalOpen] =
    useState(false);

  const { company } = useAuth();
  const generateTitleDiffConfig = company.companyConfig.generateTitleDiff;

  const { payments, fee, amount, billStatusId, clientId, openValue } =
    useSelector((state) => state.form[name].values);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const dispatch = useDispatch();

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
  });

  const validateInputDate = (e, index) => {
    e.preventDefault();
    if (name !== 'billToReceive') return;

    const currentPayment = { ...payments[index] };
    const date = new Date(payments[index].paymentDate);
    const today = new Date();

    const isDateFuture = isAfter(date, today);

    if (isDateFuture) {
      toastr.warning(
        'Não foi possível inserir a data',
        'A data informada é maior que a data atual. Insira uma data atual ou menor.'
      );

      dispatch(
        change(
          name,
          `payments[${index}].paymentDate`,
          format(today, 'yyyy-MM-dd')
        )
      );
      return;
    }

    if (index !== 0) {
      const firstPaymentDate = payments[0].paymentDate;

      if (
        generateTitleDiffConfig &&
        !isSameDay(new Date(firstPaymentDate), date)
      ) {
        toastr.warning(
          'Não é possível alterar a data',
          ' Caso o cliente tenha pago em datas diferentes, finalize o título e gere diferença do valor.'
        );

        dispatch(
          change(name, `payments[${index}].paymentDate`, firstPaymentDate)
        );

        return;
      }
    }
  };

  useEffect(() => {
    const formOfPaymentId = payments[0]?.formOfPaymentId;

    if (formOfPaymentId) {
      const formOfPayment = formsOfPayment.find(
        (formOfPayment) => String(formOfPayment.id) === String(formOfPaymentId)
      );

      if (formOfPayment) {
        const { operatorFee } = formOfPayment;

        dispatch(change(name, 'fee', operatorFee));
      }
    }
  }, [payments, formsOfPayment]);

  useEffect(() => {
    let newFeeValue = 0;
    if (!!fee) {
      newFeeValue = amount * (fee / 100);
    }
    dispatch(change(name, 'feeValue', newFeeValue));
  }, [fee, amount]);

  useEffect(() => {
    loadFormsOfPayment();
  }, []);

  async function loadFormsOfPayment() {
    try {
      let formsOfPayment =
        await formsOfPaymentRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );

      setFormsOfPayment(formsOfPayment);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as formas de pagamento. Por favor, tente novamente'
      );
    }
  }

  const getPaymentLine = () => {
    return {
      formOfPaymentId: '',
      value: 0,
      paymentDate: '',
      observations: '',
      isCustomerCreditPayment: false,
    };
  };

  async function handleFormOfPayment(formOfPaymentId, index) {
    const paymentForm = await formsOfPayment.find(
      (p) => p.id === parseInt(formOfPaymentId)
    );

    if (paymentForm.TypeOfPayment === 'Crédito Loja') {
      dispatch(
        change(
          name,
          `payments[${index}].dueDate`,
          format(new Date(), 'yyyy-MM-dd')
        )
      );
      dispatch(
        change(name, `payments[${index}].isCustomerCreditPayment`, true)
      );
    }
  }

  function handleAddPayment() {
    const newPayments = [...payments];

    newPayments.push(getPaymentLine());

    dispatch(change(name, 'payments', newPayments));
  }

  function handleOpenCancelModal(index) {
    if (isClosed || payments.length > 1) {
      setIndexToRemove(index);
      setIsRemovePaymentModalOpen(true);
    }
  }

  function handleRemovePayment() {
    const newPayments = [...payments];

    if (isClosed) {
      newPayments[indexToRemove] = getPaymentLine();

      dispatch([
        change(name, 'payments', newPayments),
        change(name, 'billStatusId', constants.BILLS_STATUS.OPEN),
      ]);
    } else {
      const paymentsFiltered = newPayments.filter(
        (_, i) => i !== indexToRemove
      );
      dispatch(change(name, 'payments', paymentsFiltered));
    }

    setIsRemovePaymentModalOpen(false);
  }

  const isClosedOrCanceled =
    billStatusId === constants.BILLS_STATUS.CLOSED ||
    billStatusId === constants.BILLS_STATUS.CANCELED;
  const isOpen = billStatusId === constants.BILLS_STATUS.OPEN;
  const isClosed = billStatusId === constants.BILLS_STATUS.CLOSED;

  return (
    <>
      <table id="bill-payments-table">
        <thead>
          <tr>
            <td className="icon" />
            <td className="formOfPayment">
              Forma de Pagamento:<span style={{ color: 'red' }}>*</span>
            </td>
            <td className="value">
              Valor:<span style={{ color: 'red' }}>*</span>
            </td>
            <td className="paymentDate">Data de Pagamento:</td>
            <td className="observations">Observação:</td>
            <td className="actions">Ações</td>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, i) => (
            <tr key={i}>
              <td>
                <FontAwesomeIcon
                  style={{ width: '2rem', height: '2rem' }}
                  icon={faCalendar}
                  color={payment.paymentDate ? 'green' : 'gray'}
                />
              </td>
              <td>
                <Field
                  name={`payments[${i}].formOfPaymentId`}
                  component={RenderField}
                  as="select"
                  onChange={(e) => handleFormOfPayment(e.target.value, i)}
                  disabled={isClosedOrCanceled}
                >
                  <option value="">Selecione</option>
                  {formsOfPayment.map((formOfPayment) => (
                    <option key={formOfPayment.id} value={formOfPayment.id}>
                      {formOfPayment.Desciption}
                    </option>
                  ))}
                </Field>
              </td>
              <td>
                <Field
                  name={`payments[${i}].value`}
                  component={RenderField}
                  type="text"
                  disabled={isClosedOrCanceled}
                  {...currencyMask}
                />
              </td>
              <td>
                <Field
                  key={`date-${i}`}
                  name={`payments[${i}].paymentDate`}
                  component={RenderField}
                  type="date"
                  disabled={isClosedOrCanceled}
                  onBlur={(e) => validateInputDate(e, i)}
                  max={format(new Date(), 'yyyy-MM-dd')}
                />
              </td>
              <td>
                <Field
                  name={`payments[${i}].observations`}
                  component={RenderField}
                  maxLength={60}
                  disabled={isClosedOrCanceled}
                />
              </td>
              <td>
                <div id="payments-actions">
                  <a
                    title="Excluir"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenCancelModal(i);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      cursor="pointer"
                      color="red"
                    />
                  </a>
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <FontAwesomeIcon
                style={{ width: '2rem', height: '2rem' }}
                cursor="pointer"
                onClick={() => isOpen && handleAddPayment()}
                icon={faPlusSquare}
                color="green"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <AlertModal
        show={isRemovePaymentModalOpen}
        onHide={() => setIsRemovePaymentModalOpen(false)}
        onCancel={() => setIsRemovePaymentModalOpen(false)}
        onSubmit={handleRemovePayment}
        message="Deseja excluir o pagamento do título ?"
      />
    </>
  );
};

export default BillPayment;
