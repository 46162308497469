import constants from '../utils/constants';
import config from '../config';
import api from '../services/api';

const index = (queryParams) =>
  api.get(config.endpoint + 'bills-to-receive', {
    params: queryParams,
  });

const create = async (billToReceive) => {
  try {
    const response = await api.post(
      config.endpoint + 'bills-to-receive',
      billToReceive
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (billToReceive) => {
  try {
    const response = await api.put(
      config.endpoint + `bills-to-receive/${billToReceive.titleId}`,
      billToReceive
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(
      config.endpoint + `bills-to-receive/by-id/${id}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `bills-to-receive?companyId=${companyId}`,
      {
        params,
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompanyPerPeriod = async (
  companyId,
  initialDate,
  finalDate,
  status,
  dateType,
  accountPlanId
) => {
  try {
    const response = await api.get(
      `titles/bills-to-receive-list?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&status=${status}&dateType=${dateType}&accountPlanId=${accountPlanId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllClosedByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `bills-to-receive?companyId=${companyId}&billStatusId=${constants.BILLS_STATUS.CLOSED}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateReceiptReport = async ({ billId, companyId }) => {
  try {
    const response = await api.get(
      config.endpoint +
        `bills-to-receive/receipt/${billId}?companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getPendingAmountByYearPerMonth = async ({ year, companyId }) => {
  try {
    const response = await api.get(
      `bills-to-receive/consult/pending-amount/by-years-month`,
      {
        params: {
          year,
          companyId,
        },
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsReceiveds = async ({
  companyId,
  initialDate,
  finalDate,
  filterOpenPayment,
  dateType,
}) => {
  try {
    const response = await api.get(
      `/report/receipts-payment-method/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
          filterOpenPayment,
          dateType,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsReceivedsTitleSynthetic = async ({
  companyId,
  initialDate,
  finalDate,
  customerId,
  dateType,
  titleStatus,
  cashierBankId,
  includeCardFee,
}) => {
  try {
    const response = await api.get(
      `report/bills/to-receive/${companyId}/synthetic`,
      {
        params: {
          companyId,
          initialDate,
          finalDate,
          customerId,
          dateType,
          titleStatus,
          cashierBankId,
          includeCardFee,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsReceivedsTitleAnalytical = async ({
  companyId,
  initialDate,
  finalDate,
  customerId,
  dateType,
  titleStatus,
  cashierBankId,
  sortByCustomer,
  includeCardFee,
}) => {
  try {
    const response = await api.get(
      `report/bills/to-receive/${companyId}/analytical`,
      {
        params: {
          companyId,
          initialDate,
          finalDate,
          customerId,
          dateType,
          titleStatus,
          cashierBankId,
          sortByCustomer,
          includeCardFee,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateValuesByStatus = async ({
  origin,
  typeOfDate,
  companyId,
  initialDate,
  finalDate,
}) => {
  try {
    const response = await api.get(`report/values-by-status/${companyId}`, {
      params: {
        origin,
        typeOfDate,
        initialDate,
        finalDate,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getCustomerDebtLimitInfo = async (customerId) => {
  const response = await api.get(
    config.endpoint +
      `bills-to-receive/consult/customer-open-titles-and-credit-info/customer/${customerId}`
  );

  return response.data;
};

const removeBankConciliation = async (id, userId) => {
  const response = await api.put(
    `bills-to-receive/${id}/bank-conciliation?userId=${userId}`
  );

  return response.data;
};

export default {
  index,
  create,
  getById,
  getAllByCompanyPerPeriod,
  update,
  getAllByCompany,
  getAllClosedByCompany,
  generateReceiptReport,
  getPendingAmountByYearPerMonth,
  generateBillsReceiveds,
  generateBillsReceivedsTitleSynthetic,
  generateBillsReceivedsTitleAnalytical,
  generateValuesByStatus,
  getCustomerDebtLimitInfo,
  removeBankConciliation,
};
