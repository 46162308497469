import React from 'react';
import { Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { milhar } from '../../../client/components/ToNormalize/ToNormalize';
import './styles.css';

const DashboardCard = ({
  width,
  link,
  title,
  color,
  titleColor,
  subtitle,
  value,
  handleModal,
}) => {
  const valueToStringAux = value?.toString();

  return (
    <div
      style={
        width
          ? { width: width, height: '88px' }
          : { width: '170px', height: '88px' }
      }
    >
      <Link
        to={link}
        id="card-dashboard"
        onClick={() => handleModal(title, valueToStringAux)}
      >
        <Col
          className="borderTopCard"
          style={{
            backgroundColor: color,
            height: '8px',
            padding: '0px',
          }}
        >
          <div></div>
        </Col>
        <Button className="painelCardVendas">
          <Col className="painelCardVendas-body">
            <Col>
              <p
                className="tituloDashboard"
                style={titleColor ? { color: titleColor } : { color: color }}
              >
                {title}
              </p>
              <h1 className="topDescription">
                <strong>
                  {!valueToStringAux ? 0 : milhar(valueToStringAux)}
                </strong>
              </h1>
              {subtitle && <p className="subTitleDescription">{subtitle}</p>}
            </Col>
          </Col>
        </Button>
      </Link>
    </div>
  );
};

export default DashboardCard;
