import React from "react";
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../../utils/constants'
import ProdtctsListingLocationMain from './components/ProdtctsListingLocationMain'

const ProductServiceReviewsWarrant = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Produtos', 'Listagem de Produtos por Localização']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <ProdtctsListingLocationMain />
        </Row>
      </Grid>
    </div>
  );
}
export default ProductServiceReviewsWarrant