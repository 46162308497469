import api from 'services/api'
import config from 'config'
import usersRepository from './repositories/Users'

export const isValidated = async (user_id) => {
  let response
  try {
    response = await api.get(config.endpoint + `signature/by-user/${user_id}`)
  } catch (err) {
    throw err
  }

  const { SignatureStatus_id, End_Date } = response.data.signature

  var x = new Date().toISOString().substring(0, 10)
  var y = new Date(End_Date).toISOString().substring(0, 10)

  if (
    (SignatureStatus_id == 4 && x > y) || // CHECA SE O USUARIO ESTÁ CANCELADO E SE A DATA ATUAL É MENOR
    SignatureStatus_id == 3 ||
    SignatureStatus_id == 5 ||
    SignatureStatus_id == 6
  ) {
    return false
  }

  if (SignatureStatus_id == 2) {
    var x = new Date().toISOString().substring(0, 10)
    var y = new Date(End_Date).toISOString().substring(0, 10)

    if (x > y) return false
  }

  return true
}

export const isUserLoggedOnOtherDevice = async (userId) => {
  const token = localStorage.getItem('TOKEN')
  const user = await usersRepository.getById(userId)

  return user.Device_Id !== '' && user.Device_Id !== token
}
