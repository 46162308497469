export const xlsInventoryCountColumns = [
  {
    name: 'Código',
    acessor: 'code'
  },
  {
    name: 'Ref. Fabricante',
    acessor: 'manufacturerReference'
  },
  {
    name: 'Descrição',
    acessor: 'description'
  },
  {
    name: 'Compra',
    acessor: 'purchasePrice'
  },
  {
    name: 'Qtd. Estoque',
    acessor: 'stockQuantity'
  },
  {
    name: 'Qtd. Inventário',
    acessor: 'quantity'
  }
]
