import ProductsWithoutMovementFilters from './ProductsWithoutMovementFilters'
import ProductsWithoutMovementTable from './ProductsWithoutMovementTable'
import brandsRepository from 'repositories/Brands'
import familiesRepository from 'repositories/Families'
import productsRepository from 'repositories/Products'
import React, { useEffect } from 'react'
import Card from 'components/Card/Card'
import { useAuth } from 'contexts/auth'
import { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { isAfter, isBefore } from 'date-fns'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { percentage } from 'client/components/ToNormalize/ToNormalize'

const ProductWithoutMovementMain = () => {
  const { companyId } = useAuth()
  const [loading, setLoading] = useState()
  const [products, setProducts] = useState([])
  const [productType, setProductType] = useState('Produto Final')
  const [movementType, setMovementType] = useState('Ambos')
  const [brands, setBrands] = useState([])
  const [brandId, setBrandId] = useState(null)
  const [families, setFamilies] = useState([])
  const [familyId, setFamilyId] = useState([])
  const [initialDate, setInitialDate] = useState()
  const [finalDate, setFinalDate] = useState()

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [totalProducts, setTotalProducts] = useState(0)

  const [isExportReady, setIsExportReady] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [dataToExport, setDataToExport] = useState([])

  const loadProducts = async () => {
    setLoading(true)
    try {
      if (!finalDate || !initialDate) return
      if (isBefore(finalDate, initialDate)) return

      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        productType,
        movementType,
        brandId,
        familyId,
        initialDate,
        finalDate,
      }

      const paginatedProducts =
        await productsRepository.getProductsWithoutMovement(companyId, params)

      if (
        finalDate &&
        initialDate &&
        (paginatedProducts?.products?.length === 0 ||
          paginatedProducts.products === undefined)
      ) {
        toastr.warning('Nenhum retorno encontrado com os filtros selecionados.')
        setProducts()
        return
      }

      setProducts(paginatedProducts.products)
      setTotalPages(Math.ceil(paginatedProducts.count / pageLimit))
      setTotalProducts(paginatedProducts.count)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível gerar o relatório.',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }
  
  const loadBrands = async () => {
    setLoading(true)
    try {
      const brands = await brandsRepository.getAllByCompany(companyId)
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        Description: brand.Description,
      }))

      setBrands(serializedBrands)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const loadFamilies = async () => {
    setLoading(true)
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        )
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        Description: family.Description,
      }))

      setFamilies(serializedFamilies)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleProcessXLS() {
    if (!finalDate || !initialDate) return
    if (isBefore(finalDate, initialDate)) return

    setIsExportLoading(true)
    toastr.info('Processando exportação', 'Por favor, aguarde.')
    try {
      const params = {
        page: 1,
        limit: 1000000000,
        productType,
        movementType,
        brandId,
        familyId,
        initialDate,
        finalDate,
      }

      const allProducts = await productsRepository.getProductsWithoutMovement(
        companyId,
        params
      )

      if (
        finalDate &&
        initialDate &&
        (allProducts?.products?.length === 0 ||
          allProducts.products === undefined)
      ) {
        toastr.warning('Nenhum retorno encontrado com os filtros selecionados.')
        setProducts()
        return
      }

      setDataToExport(
        allProducts.products.map((product) => ({
          ...product,
          productPurchasePriceF: currency(product.productPurchasePrice),
          productSalePriceF: currency(product.productSalePrice),
          productMarginF: percentage(product.productMargin),
        }))
      )
      setIsExportReady(true)
      toastr.success(
        'Exportação pronta',
        'Clique no botão para baixar o arquivo'
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados para exportação. Tente novamente!'
      )
    } finally {
      setIsExportLoading(false)
    }
  }

  useEffect(() => {
    loadBrands()
    loadFamilies()
  }, [])

  useEffect(() => {
    loadProducts()
  }, [currentPage, pageLimit])

  return (
    <Card
      content={
        <>
          <ProductsWithoutMovementFilters
            loading={loading}
            products={products}
            handleSubmit={loadProducts}
            finalDate={finalDate}
            initialDate={initialDate}
            productType={productType}
            movementType={movementType}
            brands={brands}
            brandId={brandId}
            families={families}
            setFinalDate={setFinalDate}
            setInitialDate={setInitialDate}
            setProductType={setProductType}
            setMovementType={setMovementType}
            setBrands={setBrands}
            setBrandId={setBrandId}
            setFamilies={setFamilies}
            setFamilyId={setFamilyId}
            isExportReady={isExportReady}
            dataToExport={dataToExport}
            handleProcessXLS={handleProcessXLS}
            isExportLoading={isExportLoading}
          />

          <ProductsWithoutMovementTable
            loading={loading}
            products={products}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            totalProducts={totalProducts}
            loadProducts={loadProducts}
          />
        </>
      }
    />
  )
}

export default ProductWithoutMovementMain
