import React from 'react'
import { useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import NewKitBasicData from './NewKitBasicData'
import NewKitItems from './NewKitItems'
import ItemsModal from 'client/views/Sales/NewSale/FormSale/ItemsModal'
import useFilters from 'hooks/useFilters'
import { useEffect } from 'react'
import NewKitFormButtons from './NewKitFormButtons'
import AlertModal from 'components/AlertModal/AlertModal'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import kitRepository from 'repositories/Kit'

const NewKitMain = ({ history, kitId }) => {
  const [itemsData, setItemsData] = useState([])
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false)
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false)
  const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState(false)

  const [totalItemsSum, setTotalItemsSum] = useState(0)
  const [totalKitValue, setTotalKitValue] = useState(0)

  const [isBasicDataOpen, setIsBasicDataOpen] = useState(true)
  const [isItemsOpen, setIsItemsOpen] = useState(false)

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      additionalInfo: '',
      isActive: true,
      description: '',
      barCode: '',
    },
  })

  const { append, fields, replace } = useFieldArray({
    name: 'kitItemsTable',
    control,
  })

  const additionalInfo = useWatch({
    control,
    name: 'additionalInfo',
  })

  const isActive = useWatch({
    control,
    name: 'isActive',
  })

  const description = useWatch({
    control,
    name: 'description',
  })

  const barCode = useWatch({
    control,
    name: 'barCode',
  })

  const handleItemPrice = (item) => {
    return !item.Discount_Value
      ? item.Unit_Value * item.Quantity
      : item.Quantity *
          (item.Unit_Value - (item.Unit_Value * item.Discount_Value) / 100)
  }

  const handleAppendItems = (items) => {
    const serializedItems = items.map((item) => ({
      ...item,
      kitPrice: handleItemPrice(item)
    }))
    append(serializedItems)
    setIsAddItemModalOpen(false)
  }

  const handleDeleteItem = (index) => {
    const newItems = fields.filter((_, i) => i !== index)
    replace(newItems)
  }

  const validations = (data) => {
    const findKitPriceWithZero = data.kitItemsTable.find(
      (item) => item.kitPrice === 0
    )

    if (findKitPriceWithZero) {
      toastr.warning(
        'Não foi possível salvar.',
        'O preço do item não pode ser 0 (zero).'
      )
      return false
    }

    if (fields.length === 0 || !data.description) {
      toastr.warning(
        'Não foi possível salvar.',
        'Preencha a descrição e adicione ao menos 1 item.'
      )
      return false
    }

    return true
  }

  const loadKit = async () => {
    setLoading(true)
    try {
      const data = await kitRepository.show(kitId)
      const itemsTableProducts = data.kitItemsProducts.map((item) => ({
        Product_id: item.productId,
        kitId: item.kitId,
        Type: item.type,
        Code: item.code,
        Description: item.description,
        kitPrice: item.kitPrice,
        Manufacturer_Ref: item.manufacturerRef,
        Brand: item.brand,
        Family: item.family,
        Unit_Value: item.unitValue,
      }))

      const itemsTableService = data.kitItemsServices.map((item) => ({
        Service_id: item.serviceId,
        kitId: item.kitId,
        Code: item.code,
        Description: item.description,
        kitPrice: item.kitPrice,
        Manufacturer_Ref: item.manufacturerRef,
        Family: item.family,
        Unit_Value: item.unitValue,
      }))

      const itemsTable = [...itemsTableProducts, ...itemsTableService]

      setValue('additionalInfo', data.additionalInformation)
      setValue('description', data.description)
      setValue('barCode', data.barCode)
      setValue('isActive', data.isActive)
      append(itemsTable)

      const totalKitPriceValue = itemsTable.reduce(
        (acc, item) => acc + item.kitPrice,
        0
      )

      setTotalKitValue(totalKitPriceValue)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar o kit, tente novamente mais tarde.'
      )
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    if (!validations(data)) return
    setLoading(true)
    try {
      const kitItemsProducts = data.kitItemsTable.filter(
        (item) => item.Product_id && item.Type === 'Produto'
      )
      const kitItemsService = data.kitItemsTable.filter(
        (item) =>
          item.Service_id || (item.Product_id && item.Type === 'Serviço')
      )

      const params = {
        kit: {
          additionalInformation: data.additionalInfo,
          description: data.description,
          barCode: data.barCode,
          isActive: data.isActive,
          companyId,
        },
        kitItemsProducts: kitItemsProducts.map((item) => ({
          productId: item.Product_id,
          kitPrice: item.kitPrice,
        })),
        kitItemsServices: kitItemsService.map((item) => ({
          serviceId: item.Service_id || item.Product_id,
          kitPrice: item.kitPrice,
        })),
      }

      if (kitId) {
        await kitRepository.update(kitId, params)
        toastr.success(
          'Atualização efetuada com sucesso',
          'O kit foi atualizado e pode ser utilizado normalmente.'
        )
      } else {
        await kitRepository.create(params)
        toastr.success(
          'Cadastro efetuado com sucesso',
          'O kit foi cadastrado e pode ser utilizado normalmente.'
        )
      }
      history.push('/client/products')
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao salvar o kit, tente novamente mais tarde.'
      )
    }
    setLoading(false)
  }

  const onChangeKitPrice = (index, value) => {
    const newFields = [...fields]
    newFields[index].kitPrice = value
    setTotalKitValue(newFields.reduce((acc, item) => acc + item.kitPrice, 0))

    setTimeout(() => {
      document.getElementById(`kitItemsTable[${index}].kitPrice`).focus()
    }, 1)
  }

  const handleRedirect = () => {
    if (kitId) {
      setIsConfirmEditModalOpen(true)
    } else {
      setIsConfirmCancelModalOpen(true)
    }
  }

  const handleFilters = (desc) => {
    const querySearch = [
      desc['Description'],
      desc['Code'],
      desc['Manufacturer_Ref'],
    ]
    return filterByQuery(querySearch)
  }

  const filteredItems = fields.filter(handleFilters)

  useEffect(() => {
    const totalItemsValue = fields.reduce((acc, item) => {
      return acc + item['Unit_Value']
    }, 0)
    setTotalItemsSum(totalItemsValue)

    const totalKitValue = fields.reduce((acc, item) => {
      return acc + item['kitPrice']
    }, 0)
    setTotalKitValue(totalKitValue)
  }, [fields])

  useEffect(() => {
    if (!!kitId) {
      loadKit()
    }
  }, [kitId])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <NewKitBasicData
          additionalInfoLength={additionalInfo?.length}
          register={register}
          isBasicDataOpen={isBasicDataOpen}
          setIsBasicDataOpen={setIsBasicDataOpen}
          isItemsOpen={isItemsOpen}
          setIsItemsOpen={setIsItemsOpen}
          isActive={isActive}
          kitId={kitId}
          setValue={setValue}
        />
        <NewKitItems
          setQuery={setQuery}
          control={control}
          data={filteredItems}
          setIsAddItemModalOpen={setIsAddItemModalOpen}
          handleDeleteItem={handleDeleteItem}
          totalItemsValue={totalItemsSum}
          totalKitValue={totalKitValue}
          onChangeKitPrice={onChangeKitPrice}
          isItemsOpen={isItemsOpen}
          setIsItemsOpen={setIsItemsOpen}
        />
        <NewKitFormButtons
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => handleRedirect()}
          loading={loading}
        />
      </div>

      {isAddItemModalOpen && (
        <ItemsModal
          onCancel={() => setIsAddItemModalOpen(false)}
          onSubmit={handleAppendItems}
          alreadyAddedItems={fields}
          allowPromotionalPrices={false}
          hasKitSearch={false}
        />
      )}

      {isConfirmCancelModalOpen && (
        <AlertModal
          show={isConfirmCancelModalOpen}
          title="OS Digital"
          subtitle="Você tem certeza que deseja cancelar o cadastro de kit?"
          message="O processo de cancelamento do kit é irreversível e será necessário cadastrar novamente."
          onHide={() => setIsConfirmCancelModalOpen(false)}
          onCancel={() => setIsConfirmCancelModalOpen(false)}
          onSubmit={() => history.push('/client/products')}
        />
      )}

      {isConfirmEditModalOpen && (
        <AlertModal
          show={isConfirmEditModalOpen}
          title="OS Digital"
          subtitle="Você tem certeza que deseja cancelar a edição de kit?"
          message="O processo de cancelamento de kit é irreversível e será necessário editar novamente"
          onHide={() => setIsConfirmEditModalOpen(false)}
          onCancel={() => setIsConfirmEditModalOpen(false)}
          onSubmit={() => history.push('/client/products')}
        />
      )}
    </>
  )
}

export default NewKitMain
