import React, { useState, useEffect } from 'react'
import { Grid, Row } from 'react-bootstrap'
import FormProfile from './FormProfile'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

const NewProfile = ({ location }) => {
  const [id, setId] = useState(null)

  localStorage.setItem('selectedPath', 'client/profile')

  useEffect(() => {
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get("id");

    if (idParams) {
      readHash(idParams)
    }
  }

  async function readHash(hash) {
    const profileId = await decrypt(hash, '@OS-dig:profileId');
    setId(profileId);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb data={['Início', 'Meus Perfis de Acesso', !!id ? 'Editar Perfil' : 'Novo Perfil']}
            path={['home', 'profiles', null]} />
          <div style={{ marginTop: 30 }}>
            <FormProfile profileId={id} />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewProfile