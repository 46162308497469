import React, { useEffect, useState } from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import NewIntermediary from "./FormNewIntermediary";
import constants from "../../../../utils/constants";
import { decrypt } from "client/components/ToNormalize/ToNormalize";

const ViewNewIntermediary = ({ history, location }) => {
  const [id, setId] = useState(null);

  useEffect(() => {
    checkParams();
  }, [location]);

  function checkParams() {
    const params = new URLSearchParams(location.search);
    const idParams = params.get("id");

    if (idParams) {
      readHash(idParams);
    }
  }

  async function readHash(hash) {
    const intermediaryId = await decrypt(hash, "@OS-dig:intermediaryId");
    setId(intermediaryId);
  }
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <BreadCrumb
              data={[
                "Início",
                "Cadastros",
                "Fiscal",
                "Intermediador",
                `${id ? "Editar" : "Novo"} Intermediador`,
              ]}
              path={[
                constants.ROUTES.HOME,
                null,
                null,
                constants.ROUTES.INTERMEDIARIES,
                null,
              ]}
            />
            <span style={{ color: "red", position: "absolute", right: 10 }}>
              * Campos Obrigatórios
            </span>
          </div>
          <NewIntermediary
            intermediaryId={id}
            onCancel={() => history.push(constants.ROUTES.INTERMEDIARIES)}
            onSubmit={() => history.push(constants.ROUTES.INTERMEDIARIES)}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(ViewNewIntermediary);
