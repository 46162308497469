import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { useBranding } from 'hooks/useBranding';

export default function BreadCrumb(props) {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  return (
    <h5
      id="bc"
      className={`bcSpace
        ${isWorkmotor ? 'wm-text-primary' : ''}
        ${isTecnomotor ? 'tecnomotor-text-primary' : ''}
        ${isBarros ? 'barros-text-primary' : ''}
        ${isPO360 ? 'po360-text-primary' : ''}
      `}
    >
      {' '}
      {props.data.map((p, i) => (
        <div key={p}>
          {' '}
          {i === props.path.length - 1 ? (
            <strong key={i}> {p} </strong>
          ) : (
            <span key={i}>
              {' '}
              {props.path[i] ? (
                <Link
                  to={props.path[i]}
                  className={`
                    ${isWorkmotor ? 'wm-text-primary' : ''}
                    ${isTecnomotor ? 'tecnomotor-text-primary' : ''}
                    ${isBarros ? 'barros-text-primary' : ''}
                    ${isPO360 ? 'po360-text-primary' : ''}
                  `}
                >
                  {' '}
                  {p}{' '}
                </Link>
              ) : (
                <span> {p} </span>
              )}{' '}
              <span> {'>'} </span>
            </span>
          )}{' '}
        </div>
      ))}{' '}
    </h5>
  );
}
