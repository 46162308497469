import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import InputMask from "react-input-mask";
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from "components/AlertModal/AlertModal";
import { withRouter } from "react-router";
import CardForm from '../../../../components/CardForm';
import { toastr } from "react-redux-toastr";
import {onlyNumbers} from "client/components/ToNormalize/ToNormalize"
import PercentInput from 'client/components/Percent';
import constants from '../../../../../utils/constants'

import api from "services/api";
import config from 'config'

function TributoPorEstado({ id, history }) {

  const [dadosCadastrais, setDadosCadastrais] = useState(true);
  const [cancelModal, setCancelModal] = useState(false)
  const [states, setStates] = useState([])
  const [cfops, setCfops] = useState([])
  const [uf, setUf] = useState('')
  const [cfop, setCfop] = useState('')
  const [csosn, setCsosn] = useState('')
  const [icms, setIcms] = useState('')
  const [redIcms, setRedIcms] = useState('')
  const [mva, setMva] = useState('')
  const [icmsInter, setIcmsInter] = useState('')
  const [redIcmsSt, setRedIcmsSt] = useState('')
  const [fcp, setFcp] = useState('')
  const [regime, setRegime] = useState(false)
  const [loading, setLoading] = useState(false)

  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    getState()
    getCfop()
    getCompany()
    if (!!id) {
      getTaxByState()
    }
  }, [])

  const getTaxByState = async () => {
    try {
      const response = await api.get(config.endpoint + `tax-by-state/${id}`)
      setUf(response.data.uf)
      setCfop(response.data.codeCfop)
      setCsosn(response.data.csosn)
      setIcms(response.data.icms ? response.data.icms.toFixed(2) > 10 ? response.data.icms.toFixed(2) : `0${response.data.icms.toFixed(2)}` : '')
      setRedIcms(formatPercent(response.data.icmsReduced))
      setRedIcmsSt(formatPercent(response.data.icmsStReduced))
      setIcmsInter(response.data.internalIcms ? response.data.internalIcms.toFixed(2) > 10 ? response.data.internalIcms.toFixed(2) : `0${response.data.internalIcms.toFixed(2)}` : '')
      setFcp(response.data.fcp ? response.data.fcp.toFixed(2) > 10 ? response.data.fcp.toFixed(2) : `0${response.data.fcp.toFixed(2)}` : '')
      setMva(formatPercent(response.data.mva))
    } catch (err) {
      console.log(err)
      return toastr.error('Ocorreu um erro ao carregar. Tente novamente.')
    }

  }

  const formatPercent = (value) => {
    const percent = String(value?.toFixed(2).replace(".",",")).padStart(6,"0")
    return percent
  }

  const getState = async () => {
    const response = await api.get(config.endpoint + `address/states`)
    setStates(response.data)
  }

  const getCompany = async () => {
    const response = await api.get(config.endpoint + `company/${companyId}`)
    if (response.data.status) {
      if (response.data.data.TaxRegime === "Simples Nacional") {
        setRegime(true)
      } else {
        setRegime(false)
      }
    }
  }

  const getCfop = async () => {
    const response = await api.get(config.endpoint + `natureoftransactions/by-company/${companyId}`)
    setCfops(response.data)
  }

  const onCancel = async () => {
    await history.push(constants.ROUTES.TAXES_BY_STATE)
  }

  const handleSubmit = async () => {
    setLoading(true)

    if (!uf || !cfop || !csosn) {
      setLoading(false)

      return toastr.error('Para salvar é necessário definir UF, CFOP e CSOSN/CST')
    }

    if (id) {
      update()
    }else{
      create()
    }
  }

  const create = async () => {
    const getTax = await api.get(config.endpoint + `tax-by-state?companyId=${companyId}`)
    const allTributos = getTax.data

    for (let i = 0; i < allTributos.length; i++) {
      if (allTributos[i].uf === uf && allTributos[i].codeCfop === cfop && allTributos[i].csosn === csosn) {
        setLoading(false)
        return toastr.error('Já existe cadastro com UF, CFOP e CSOSN/CST')
      }
    }

    const descriptionCfop = cfops.find(cfops => cfops.CFOP === cfop)
    try {
      await api.post(config.endpoint + `tax-by-state`, {
        companyId: companyId,
        uf,
        codeCfop: cfop,
        descriptionCfop: descriptionCfop.description,
        csosn,
        icms: icms ? icms.toString().replace(',', '.').replace('%', '') : null,
        icmsReduced: redIcms ? redIcms.toString().replace(',', '.').replace('%', '') : null,
        mva: mva ? mva.toString().replace(',', '.').replace('%', '') : null,
        internalIcms: icmsInter ? icmsInter.toString().replace(',', '.').replace('%', '') : null,
        icmsStReduced: redIcmsSt ? redIcmsSt.toString().replace(',', '.').replace('%', '') : null,
        fcp: fcp ? fcp.toString().replace(',', '.').replace('%', '') : null
      })

      toastr.success("Sucesso!", "Tributo por estado cadastrado com sucesso.")
      history.push(constants.ROUTES.TAXES_BY_STATE)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao salvar o tributo por estado. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const update = async () => {
    const getTax = await api.get(config.endpoint + `tax-by-state?companyId=${companyId}`)
    const allTributos = getTax.data

    for (let i = 0; i < allTributos.length; i++) {
      if (allTributos[i].uf === uf && allTributos[i].codeCfop === cfop && allTributos[i].csosn === csosn && allTributos[i].id != id) {
        setLoading(false)
        return toastr.error('Já existe cadastro com UF, CFOP e CSOSN/CST')
      }
    }

    const descriptionCfop = cfops.find(cfops => cfops.CFOP === cfop)
    try {
      await api.put(config.endpoint + `tax-by-state/${id}`, {
        uf,
        codeCfop: cfop,
        descriptionCfop: descriptionCfop.description,
        csosn,
        icms: icms ? icms.toString().replace(',', '.').replace('%', '') : null,
        icmsReduced: redIcms ? redIcms.toString().replace(',', '.').replace('%', '') : null,
        mva: mva ? mva.toString().replace(',', '.').replace('%', '') : null,
        internalIcms: icmsInter ? icmsInter.toString().replace(',', '.').replace('%', '') : null,
        icmsStReduced: redIcmsSt ? redIcmsSt.toString().replace(',', '.').replace('%', '') : null,
        fcp: fcp ? fcp.toString().replace(',', '.').replace('%', '') : null
      })

      toastr.success("Sucesso!", "Tributo por estado atualizado com sucesso.")
      history.push(constants.ROUTES.TAXES_BY_STATE)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao salvar o tributo por estado. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (

    <div style={{ marginTop: 30 }}>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show={dadosCadastrais}
          title="Dados de Tributo"
          onClick={() => setDadosCadastrais(!dadosCadastrais)}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>UF<span style={{ color: 'red' }}>*</span></label>
              <select className="form-control foco-input"
                name="cardMonth" value={uf} onChange={e => setUf(e.target.value)}>
                <option value="">Selecione</option>
                {
                  states.map(e => {
                    return (
                      <option key={e.id} value={e.Initials}>{e.Initials}</option>
                    );
                  })
                }
              </select>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <label>CFOP<span style={{ color: 'red' }}>*</span></label>
              <select className="form-control foco-input"
                name="cardMonth" value={cfop} onChange={e => setCfop(e.target.value)}>
                <option value="">Selecione</option>
                {
                  cfops.map(e => {
                    return (
                      <option key={e.id} value={e.CFOP}>{e.CFOP + ' - ' + e.description}</option>
                    );
                  })
                }
              </select>
            </Col>
            <Col lg={1} md={1} sm={1} xs={12}>
              <label>{regime ? 'CSOSN' : 'CST'}<span style={{ color: 'red' }}>*</span></label>
              <input className="form-control foco-input" maxLength={regime ? '3' : '2'} value={csosn} onChange={e => setCsosn(onlyNumbers(e.target.value))}></input>
            </Col>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>% ICMS</label>
              <InputMask mask="99,99%" minLength="5" className="form-control foco-input"
                value={icms}
                onChange={e => setIcms(e.target.value)} />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>% Red. ICMS</label>
              <InputMask mask="999,99%" minLength="5" className="form-control foco-input"
                value={redIcms}
                onChange={e => setRedIcms(e.target.value)} />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>% MVA</label>
              <InputMask mask="999,99%" minLength="5" className="form-control foco-input"
                value={mva}
                onChange={e => setMva(e.target.value)} />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>% ICMS Inter.</label>
              <InputMask mask="99,99%" minLength="5" className="form-control foco-input"
                value={icmsInter}
                onChange={e => setIcmsInter(e.target.value)} />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>% Red. ICMS ST</label>
              <InputMask mask="999,99%" minLength="5" className="form-control foco-input"
                value={redIcmsSt}
                onChange={e => setRedIcmsSt(e.target.value)} />
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>FCP</label>
              <InputMask mask="99,99%" minLength="5" className="form-control foco-input"
                value={fcp}
                onChange={e => setFcp(e.target.value)} />
            </Col>
          </Col>
        </CardForm>
        <FormSubmitButtons onCancel={() => setCancelModal(true)} hasId={!!id} onSubmit={handleSubmit} loading={loading} />

        {cancelModal &&
          <AlertModal
            show={cancelModal}
            onHide={() => setCancelModal(false)}
            onCancel={() => setCancelModal(false)}
            message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar ?'
            onSubmit={onCancel}
          />
        }
      </form>

    </div >
  );
};


export default withRouter(TributoPorEstado)