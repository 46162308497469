import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import CardForm from 'client/components/CardForm'
import AlertModal from '../../../components/AlertModal'

import { useAuth } from '../../../contexts/auth'
import useDebounce from '../../../hooks/useDebounce'

import productsRepository from '../../../repositories/Products'
import linkedServiceRepository from '../../../repositories/LinkedService'
import './styles.css'
import FormNewLinkedService from './NewLinkedService/FormNewLinkedService'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const HomeLinkedServices = ({ productId }) => {
  const [loading, setLoading] = useState(false)
  const [
    isLinkedServicesRegisterModalOpen,
    setIsLinkedServicesRegisterModalOpen,
  ] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [linkedServiceToDelete, setLinkedServiceToDelete] = useState(null)
  const [linkedServices, setLinkedServices] = useState([])

  const [code, setCode] = useState(null)
  const [description, setDescription] = useState(null)
  const [manufacturerRef, setManufacturerRef] = useState(null)
  const [brand, setBrand] = useState(null)
  const [query, setQuery] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const [pageSize, setPageSize] = useState(10)
  const [pages, setPages] = useState(0)
  const [page, setPage] = useState(1)

  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800)

  const { companyId } = useAuth()

  useEffect(() => {
    if (!!productId) {
      loadProduct()
      loadLinkedServices({
        page: 0,
        pageSize,
        query: searchQuery,
      })
    }
  }, [productId, searchQuery])

  async function loadProduct() {
    try {
      const product = await productsRepository.getById(productId)
      setCode(product.Code)
      setDescription(product.Description)
      setManufacturerRef(product.Manufacturer_Ref)
      setBrand(product.Brands.Description)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao carregar o produto. Por favor, tente novamente'
      )
    }
  }

  const loadLinkedServices = async (state) => {
    setLoading(true)

    try {
      const response = await linkedServiceRepository.index({
        companyId,
        productId,
        query: state.query,
      })

      const total = response.headers['x-total-count']

      setPages(Math.floor(total / state.pageSize) + 1)
      setLinkedServices(response.data)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os serviços vinculados.')
    } finally {
      setLoading(false)
    }
  }

  const handleNewLinkedService = () => {
    setIsLinkedServicesRegisterModalOpen(true)
  }

  function handleChangeQuerySearch(value) {
    setQuery(value)
    debouncedSaveQuerySearch(value)
  }

  const handleLinkedServiceRegisterSuccess = () => {
    setIsLinkedServicesRegisterModalOpen(false)
    loadLinkedServices({
      page,
      pageSize,
      query: searchQuery,
    })
  }

  const handleOpenDeleteModal = (linkedServiceId) => {
    setIsDeleteModalOpen(true)
    setLinkedServiceToDelete(linkedServiceId)
  }

  const handleDelete = async () => {
    setIsDeleteModalOpen(false)
    setLoading(true)
    try {
      await linkedServiceRepository.remove(linkedServiceToDelete)
      await loadLinkedServices({
        page,
        pageSize,
        query: searchQuery,
      })
      toastr.success('Deletado com sucesso')
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao deletar o serviço vinculado. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm show title='Serviço Vinculado' onClick={() => {}}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div class='product-input'>
              <label>Código:</label>
              <input class='product-input-values' value={code} disabled />
            </div>
            <div class='product-input'>
              <label>Descrição:</label>
              <input
                class='product-input-values'
                value={description}
                disabled
                style={{ width: '400px' }}
              />
            </div>
            <div class='product-input'>
              <label>Ref. Fabricante:</label>
              <input
                class='product-input-values'
                value={manufacturerRef}
                disabled
              />
            </div>
            <div class='product-input'>
              <label>Marca:</label>
              <input class='product-input-values' value={brand} disabled />
            </div>
          </div>
          <div
            className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
            id='reviewHeader'
          >
            <div>
              <button
                className='btn btn-sucesso'
                onClick={handleNewLinkedService}
              >
                + Novo Serviço Vinculado
              </button>
            </div>
            <div
              className='col-xs-12 col-sm-8 col-md-4 col-lg-4'
              id='review-search'
            >
              <i id='iconepesquisar' className='fa fa-search'></i>
              <input
                type='text'
                style={{ paddingLeft: '30px', width: '174%' }}
                className='form-control foco-input'
                placeholder='Pesquisar por Código e Descrição'
                value={query}
                onChange={(e) => handleChangeQuerySearch(e.target.value)}
              />
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={linkedServices}
              columns={[
                {
                  Header: 'Código',
                  accessor: 'Service.Code',
                  headerClassName: 'text-left',
                  width: 130,
                },
                {
                  Header: 'Descrição',
                  accessor: 'Service.Description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Valor',
                  accessor: 'Service.Price',
                  headerClassName: 'text-left',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Horas',
                  accessor: 'Service.Hours_Expected',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 150,
                  Cell: (props) => (
                    <>
                      <FontAwesomeIcon
                        title='Excluir'
                        cursor='pointer'
                        style={{
                          height: '1.5em',
                          width: '1.5em',
                          color: 'red',
                          paddingLeft: '1px',
                        }}
                        icon={faTrashAlt}
                        onClick={() => handleOpenDeleteModal(props.value)}
                      />
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              pages={pages}
              manual
              defaultSorted={[
                {
                  id: 'Code',
                  desc: false,
                },
              ]}
              previousText='Anterior'
              nextText='Próximo'
              loadingText='Carregando...'
              noDataText='Não há informação'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
              onPageChange={(page) => setPage(page)}
              onPageSizeChange={(newPageSize, _) => setPageSize(newPageSize)}
            />
          </div>
          <div></div>
        </div>
      </CardForm>
      {isLinkedServicesRegisterModalOpen && (
        <Modal
          dialogClassName='modal-formProduto'
          show={setIsLinkedServicesRegisterModalOpen}
          onHide={() => setIsLinkedServicesRegisterModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Adicionar Serviço Vinculado</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormNewLinkedService
              modal
              handleModal={handleLinkedServiceRegisterSuccess}
              hideModal={() => setIsLinkedServicesRegisterModalOpen(false)}
              productId={productId}
            />
          </Modal.Body>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <AlertModal
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          message={`Você tem certeza que deseja excluir o serviço vinculado? A exclusão é irreversível e deverá vincular o serviço novamente, se necessário.`}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default withRouter(HomeLinkedServices)
