import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { RevenuesPerPeriodDocument } from './RevenuesPerPeriodDocument';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import revenuesPerPeriodRepository from '../../../repositories/RevenuesPerPeriod';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';

const RevenuesPerPeriodReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [dateType, setDateType] = useState('');
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showMarginAndProfit, setShowMarginAndProfit] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportType, setReportType] = useState('');
  const [showResume, setShowResume] = useState(false);
  const [salesSubtypeId, setSalesSubtypeId] = useState('1');

  const [resumeData, setResumeData] = useState({});
  const [productsSum, setProductsSum] = useState(0);
  const [servicesSum, setServicesSum] = useState(0);
  const [discountsSum, setDiscountsSum] = useState(0);
  const [revenueTotal, setRevenueTotal] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyIdHash = searchParams.get('companyId');
      let companyId;
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId');
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'));
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));

      const dateType = searchParams.get('dateType');

      if (dateType !== 'CLOSING' && dateType !== 'CREATION')
        throw 'Tipo de data inválido';

      setDateType(dateType);

      const lookingFor = searchParams.get('lookingFor');
      if (
        lookingFor !== 'ORDER OF SERVICE' &&
        lookingFor !== 'BOTH' &&
        lookingFor !== 'SALE'
      )
        throw 'Tipo de busca inválida';

      const reportType = searchParams.get('reportType');
      setReportType(reportType);

      const salesSubtypeId = searchParams.get('salesSubtypeId');
      setSalesSubtypeId(salesSubtypeId);

      const showResume = searchParams.get('showResume');
      setShowResume(showResume);

      const showPaymentDetails = searchParams.get('showPaymentDetails');
      setShowPaymentDetails(showPaymentDetails);

      const showMarginAndProfit = searchParams.get('showMarginAndProfit');
      setShowMarginAndProfit(showMarginAndProfit);

      return loadReport(companyId, {
        initialDate,
        finalDate,
        dateType,
        lookingFor,
        showPaymentDetails,
        showMarginAndProfit,
        reportType,
        showResume,
        salesSubtypeId,
      });
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (companyId, params) => {
    try {
      const { data: reportData } =
        await revenuesPerPeriodRepository.getRevenuesPerPeriodReport(
          companyId,
          params
        );

      if (params.showResume === 'true') {
        setResumeData(reportData.revenuePerPeriodHeader);
      }

      setReportData(reportData.serializedReport);

      if (reportType === 'ANALYTICAL') {
        let productsSum = 0;
        let servicesSum = 0;
        let discountsSum = 0;

        reportData.forEach((sale) => {
          discountsSum += sale.Discount_Value;

          sale.SalesItems.forEach((item) => {
            if (item.Type === 'Produto') {
              productsSum += item.Amount;
            } else {
              servicesSum += item.Amount;
            }
          });
          return;
        });

        let revenueTotal = productsSum + servicesSum - discountsSum;

        setProductsSum(productsSum);
        setServicesSum(servicesSum);
        setDiscountsSum(discountsSum);
        setRevenueTotal(revenueTotal);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <RevenuesPerPeriodDocument
            data={reportData}
            productsSum={productsSum}
            servicesSum={servicesSum}
            discountsSum={discountsSum}
            revenueTotal={revenueTotal}
            initialDate={initialDate}
            finalDate={finalDate}
            showPaymentDetails={showPaymentDetails}
            showMarginAndProfit={showMarginAndProfit}
            dateType={dateType}
            reportType={reportType}
            showResume={showResume}
            resumeData={resumeData}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default RevenuesPerPeriodReport;
