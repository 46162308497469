import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDays, format, isAfter, subDays } from 'date-fns/esm'

import TableByStatus from './TableByStatus'
import DownloadXlsButton from 'components/DownloadXlsButton'
import purchasesRepository from '../../../../../repositories/Purchases'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
import { getPurchasesInfoToExport, xlsColumns } from './excelHelpers'
import constants from '../../../../../utils/constants'
import ChartByStatus from './ChartByStatus'

const PurchasesByStatus = () => {
  const [loading, setLoading] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [period, setPeriod] = useState(15)
  const [status, setStatus] = useState('')

  const [purchases, setPurchases] = useState([])
  const [purchasesFiltered, setPurchasesFiltered] = useState([])
  const [purchasesGroupedByStatus, setPurchasesGroupedByStatus] = useState([])
  const [purchasesChartData, setPurchasesChartData] = useState([])
  const [purchasesInfoToExport, setPurchasesInfoToexport] = useState([])

  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    if (!!companyId) {
      loadPurchases()
    }
  }, [])

  useEffect(() => {
    let purchasesFilteredByStatus = purchases
    if (status !== '') {
      purchasesFilteredByStatus = purchases.filter(
        (purchase) => String(purchase.purchaseStatusId) === String(status)
      )
    }

    if (period === '0') {
      setIsCustom(true)
      setPurchasesFiltered(purchasesFilteredByStatus)
    } else {
      setIsCustom(false)
      setInitialDate('')
      setFinalDate('')

      const dateToFilter = subDays(new Date(), period)
      const purchasesFilteredByPeriod = purchasesFilteredByStatus.filter(
        (purchase) => {
          const entryAt = new Date(getDateOnlyFromDate(purchase.entryAt))
          return isAfter(entryAt, dateToFilter)
        }
      )
      setPurchasesFiltered(purchasesFilteredByPeriod)
    }
  }, [purchases, period, status])

  useEffect(() => {
    if (!initialDate && !finalDate && !status) {
      return setPurchasesFiltered(purchases)
    }

    let purchasesFilteredByDate = purchases

    if (status !== '') {
      purchasesFilteredByDate = purchasesFilteredByDate.filter(
        (purchase) => String(purchase.purchaseStatusId) === String(status)
      )
    }

    if (!!initialDate) {
      const initialOnlydate = new Date(getDateOnlyFromDate(initialDate))
      purchasesFilteredByDate = purchasesFilteredByDate.filter((purchase) => {
        const entryAt = addDays(
          new Date(getDateOnlyFromDate(purchase.entryAt)),
          1
        )

        return isAfter(entryAt, initialOnlydate)
      })
    }

    if (!!finalDate) {
      const finalDateOnly = addDays(new Date(getDateOnlyFromDate(finalDate)), 1)
      purchasesFilteredByDate = purchasesFilteredByDate.filter((purchase) => {
        const entryAt = new Date(getDateOnlyFromDate(purchase.entryAt))

        return isAfter(finalDateOnly, entryAt)
      })
    }

    setPurchasesFiltered(purchasesFilteredByDate)
  }, [initialDate, finalDate, status])

  useEffect(() => {
    const purchasesInfoToExport = getPurchasesInfoToExport(purchasesFiltered)
    setPurchasesInfoToexport(purchasesInfoToExport)
    handlePurchasesTableData()
  }, [purchasesFiltered])

  useEffect(() => {
    const purchasesChartData = purchasesGroupedByStatus.map((purchase) => [
      purchase.status,
      purchase.quantity,
    ])
    setPurchasesChartData(purchasesChartData)
  }, [purchasesGroupedByStatus])

  const handlePurchasesTableData = () => {
    const purchasesGroupedByStatus = []

    purchasesFiltered.forEach((purchase) => {
      const findedIndex = purchasesGroupedByStatus.findIndex(
        (child) => child.purchaseStatusId === purchase.purchaseStatusId
      )

      if (findedIndex !== -1) {
        purchasesGroupedByStatus[findedIndex].amount =
          purchasesGroupedByStatus[findedIndex].amount + purchase.total
        purchasesGroupedByStatus[findedIndex].quantity++
      } else {
        purchasesGroupedByStatus.push({
          amount: purchase.total,
          status:
            constants.PURCHASES_STATUS_DESCRIPTION[purchase.purchaseStatusId],
          purchaseStatusId: purchase.purchaseStatusId,
          quantity: 1,
        })
      }
    })

    setPurchasesGroupedByStatus(purchasesGroupedByStatus)
  }

  const loadPurchases = async () => {
    setLoading(true)
    try {
      const purchases = await purchasesRepository.getPurchases(companyId)
      setPurchases(purchases)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as compras. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='relatorio-por-pagamento-container'>
      <header>
        <div>
          <span>
            <strong>Período:</strong>
          </span>
          <select
            className='form-control foco-input'
            name='period'
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value={15}>Ultimos 15 dias</option>
            <option value={30}>Ultimos 30 dias</option>
            <option value={60}>Ultimos 60 dias</option>
            <option value={0}>Personalizar</option>
          </select>
        </div>

        {isCustom && (
          <>
            <div>
              <span>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className='form-control foco-input'
                type='date'
                name='initialDate'
                value={initialDate}
                max={
                  finalDate
                    ? format(
                        new Date(getDateOnlyFromDate(finalDate)),
                        'yyyy-MM-dd'
                      )
                    : format(new Date(), 'yyyy-MM-dd')
                }
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div>
              <span>
                <strong>Data Final:</strong>
              </span>
              <input
                className='form-control foco-input'
                type='date'
                name='finalDate'
                value={finalDate}
                min={
                  initialDate
                    ? format(
                        new Date(getDateOnlyFromDate(initialDate)),
                        'yyyy-MM-dd'
                      )
                    : undefined
                }
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </>
        )}
        <div>
          <span>
            <strong>Tipo:</strong>
          </span>
          <select
            className='form-control foco-input'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value=''>Todos</option>
            <option value={constants.PURCHASES_STATUS.CLOSED}>
              Finalizada
            </option>
            <option value={constants.PURCHASES_STATUS.OPEN}>Em Aberto</option>
            <option value={constants.PURCHASES_STATUS.CANCELED}>
              Cancelada
            </option>
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <DownloadXlsButton
            archiveName={`compras-por-status${format(
              new Date(),
              'dd/MM/yyyy'
            )}`}
            data={purchasesInfoToExport}
            className='btn btn-export'
            disabled={loading || !purchasesInfoToExport.length}
            columns={xlsColumns}
          >
            <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByStatus data={purchasesGroupedByStatus} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByStatus data={purchasesChartData} />
        </div>
      </section>
    </div>
  )
}

export default PurchasesByStatus
