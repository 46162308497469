import React, { memo } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { currency } from 'client/components/ToNormalize/ToNormalize'

function TableByType({ data, loading }) {
  return (
    <>
      <h4>
        <strong>Vendas por Tipo (R$)</strong>
      </h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: 'Tipo',
            accessor: 'type',
            headerClassName: 'text-left',
            width: '50%',
          },
          {
            Header: 'Status',
            accessor: 'status',
            headerClassName: 'text-left',
            width: '50%',
          },
          {
            Header: 'Qtd',
            accessor: 'quantity',
            headerClassName: 'text-left',
            width: '50%',
          },
          {
            Header: 'Valor',
            accessor: 'total',
            headerClassName: 'text-left',
            width: '50%',
            Cell: ({ value }) => (value ? currency(value) : 0),
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma informação encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default memo(TableByType)
