import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, change } from 'redux-form'
import Select from 'react-select'

import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import AlertModal from 'components/AlertModal'
import MainData from './MainData'
import SearchIcon from 'components/SelectIcons/SearchIcon'
import companyRepository from 'repositories/Companies'
import stoneLinkRepository from 'repositories/StoneCompany'
import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize'
import StoneModal from '../../../../../client/components/StoneModal'
import './styles.css'

const StoneLinkForm = ({
  handleSubmit,
  onSubmit,
  dirty,
  saveLoading,
  integrationLoading,
  onCancel,
  stoneLinkId,
}) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false)
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [company, setCompany] = useState()
  const [companies, setCompanies] = useState()
  const [isStoneModalOpen, setIsStoneModalOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    loadCompanies()
  }, [])

  useEffect(() => {
    if (stoneLinkId && companies) loadStoneLink(stoneLinkId)
  }, [stoneLinkId, companies])

  useEffect(() => {
    if (!company) return

    const { Type, Cpf_Cnpj, Company_Name, Trading_Name, Email, Phones } =
      company

    const { Number_Phone1, Contact } = Phones[0]

    dispatch([
      change('stoneLink', 'type', Type),
      change('stoneLink', 'cpfCnpj', cpfOrCnpjMask(Cpf_Cnpj)),
      change('stoneLink', 'companyName', Company_Name),
      change('stoneLink', 'fantasyName', Trading_Name),
      change('stoneLink', 'email', Email),
      change('stoneLink', 'phone', phoneMask(Number_Phone1)),
      change('stoneLink', 'contact', Contact),
      change('stoneLink', 'companyId', company.id),
      change('stoneLink', 'status', 'Não integrado'),
    ])
  }, [company])

  const loadCompanies = async () => {
    const { data: companies } =
      await companyRepository.getCompaniesWithPaidPlan()

    setCompanies(companies)

    const { data: companiesLinks } = await stoneLinkRepository.index()
    const companiesLinksIds = companiesLinks.map(
      (companyLink) => companyLink.companyId
    )
    const unlinkedCompanies = companies.filter(
      (company) => !companiesLinksIds.includes(company.id)
    )

    const companiesOptions = unlinkedCompanies.map((company) => ({
      label: `${company.Company_Name} - ${cpfOrCnpjMask(company.Cpf_Cnpj)}`,
      value: company,
    }))

    setCompaniesOptions(companiesOptions)
  }

  const loadStoneLink = async (stoneLinkId) => {
    const { data: stoneLink } = await stoneLinkRepository.show(stoneLinkId)
    const company = companies.find(
      (company) => company.id === stoneLink.companyId
    )

    setCompany(company)

    dispatch([
      change('stoneLink', 'stoneCode', stoneLink.stoneCode),
      change(
        'stoneLink',
        'status',
        stoneLink.integrationStatus ? 'Integrado' : 'Não Integrado'
      ),
    ])
  }

  function handleOpenStoneModal() {
    setIsStoneModalOpen(true)
  }

  return (
    <main className="stone-link-form">
      <div className="test">
        <section className="search-bar test">
          <Select
            isSearchable
            options={companiesOptions}
            placeholder="Pesquisar por CPF/CNPJ, Razão Social ou Nome"
            onChange={(item) => setCompany(item.value)}
            value={null}
            noOptionsMessage={() => 'Nenhum cliente encontrado'}
            components={{
              DropdownIndicator: SearchIcon,
            }}
            isDisabled={!!stoneLinkId}
            className="select-company"
            styles={{
              control: (base) => ({
                ...base,
                height: '40px',
                minHeight: '40px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              valueContainer: (base) => ({
                ...base,
                height: '40px',
                padding: '0 0 0 10px',
                marginTop: '-4px',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                marginTop: '-4px',
              }),
            }}
          />
        </section>
        <a
          className="stone-hyperlink"
          style={{
            alignItems: 'center',
            alignSelf: 'center',
          }}
          href="#"
          onClick={(e) => {
            e.preventDefault()
            handleOpenStoneModal(true)
          }}
        >
          <u>Veja quais são as máquinas que funcionam no OS Digital</u>
        </a>
      </div>

      <section className="form">
        {stoneLinkId && (
          <span
            style={{
              color: 'red',
              fontSize: 13,
              marginBottom: 5,
              alignSelf: 'flex-end',
            }}
          >
            * Campos Obrigatórios
          </span>
        )}
        <CardForm show={!!company} title="Dados Principais">
          <MainData
            companyType={company?.Type}
            logo={{ preview: company?.Url_Logo }}
            isEdit={!!stoneLinkId}
          />
        </CardForm>

        <section className="submit-buttons">
          <FormSubmitButtons
            loading={saveLoading}
            onCancel={() =>
              dirty ? setIsConfirmCancelModalOpen(true) : onCancel()
            }
            onSubmit={handleSubmit((values) => onSubmit(values, false))}
            hasId={!!stoneLinkId}
          />
          <Button
            onClick={handleSubmit((values) => onSubmit(values, true))}
            bsStyle="primary"
            fill
            loading={integrationLoading}
          >
            {stoneLinkId ? 'Atualizar' : 'Salvar'} e transmitir
          </Button>
        </section>

        <AlertModal
          show={isConfirmCancelModalOpen}
          title="Cancelamento de vínculo"
          onHide={() => setIsConfirmCancelModalOpen(false)}
          onCancel={() => setIsConfirmCancelModalOpen(false)}
          message="Deseja cancelar o vínculo? O processo é irreversível, mas poderá ser feito um novo vínculo posteriormente."
          onSubmit={onCancel}
        />
      </section>
      <StoneModal show={isStoneModalOpen} setShow={setIsStoneModalOpen} />
    </main>
  )
}

export default reduxForm({
  form: 'stoneLink',
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: true,
})(StoneLinkForm)
