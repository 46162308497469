import React, { useEffect, useState } from 'react';
import {
  faFaceFrown,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile,
  faFaceTired,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import PollsAnswerRepository from 'repositories/PollsAnswer';

import './index.css';

const ModalNVS = ({
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  userId,
  pollId,
  companyId,
}) => {
  const [selectedNVS, setSelectedNVS] = useState(null);
  const [notes, setNotes] = useState('');

  async function sendNPSAnswer() {
    try {
      setIsLoading(true);
      await PollsAnswerRepository.create({
        notes,
        answer: selectedNVS,
        userId,
        pollId,
        companyId,
      });

      toastr.success(
        'Obrigado pela resposta!',
        'Sua opinião é muito importante para que possamos melhorar o sistema e nossa parceria.'
      );
    } catch (err) {
      toastr.error(
        'Erro ao enviar sua resposta',
        'Tentaremos lhe perguntar novamente mais tarde!'
      );
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  }

  return (
    <Modal show={isModalOpen} dialogClassName="nps-modal">
      <Modal.Body>
        <p>
          Como você classifica o OS Digital em comparação com outros sistemas
          similiares?
        </p>

        <div className="nvs-buttons-wrapper">
          <div>
            <FontAwesomeIcon
              color="#c62828"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedNVS !== null && selectedNVS !== 'Muito pior' ? 0.3 : 1
              }
              cursor="pointer"
              icon={faFaceTired}
              onClick={() => setSelectedNVS('Muito pior')}
            />
            <span>Muito pior</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#ef5350"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedNVS !== null && selectedNVS !== 'Um pouco pior'
                  ? 0.3
                  : 1
              }
              cursor="pointer"
              icon={faFaceFrown}
              onClick={() => setSelectedNVS('Um pouco pior')}
            />
            <span>Um pouco pior</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#ffad08"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedNVS !== null && selectedNVS !== 'Nem melhor, nem pior'
                  ? 0.3
                  : 1
              }
              cursor="pointer"
              icon={faFaceMeh}
              onClick={() => setSelectedNVS('Nem melhor, nem pior')}
            />
            <span>Nem melhor, nem pior</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#9ccc65"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedNVS !== null && selectedNVS !== 'Melhor' ? 0.3 : 1
              }
              cursor="pointer"
              icon={faFaceSmile}
              onClick={() => setSelectedNVS('Melhor')}
            />
            <span>Melhor</span>
          </div>

          <div>
            <FontAwesomeIcon
              color="#36963b"
              style={{ width: '35px' }}
              className="nvs-grade-button"
              opacity={
                selectedNVS !== null && selectedNVS !== 'Muito melhor' ? 0.3 : 1
              }
              cursor="pointer"
              icon={faFaceGrinStars}
              onClick={() => setSelectedNVS('Muito melhor')}
            />
            <span>Muito melhor</span>
          </div>
        </div>

        {selectedNVS !== null && (
          <textarea
            value={notes}
            placeholder="Muito obrigado pela resposta. Há algo que queira nos falar?"
            onChange={(e) => setNotes(e.target.value)}
          />
        )}

        <div className="nps-send-button">
          <CustomButton
            fill
            style={{
              background: '#5cb85c',
              borderColor: '#5cb85c',
            }}
            disabled={selectedNVS === null || isLoading}
            onClick={() => sendNPSAnswer()}
          >
            Enviar
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNVS;
