import api from '../services/api'
import config from '../config'

const generateByCompanyId = async ({
  companyId,
  initialDate,
  finalDate,
  employeeId,
  status,
}) => {
  try {
    const response = await api.get(
      'voucher-payment/voucher-issued/' + companyId,
      {
        params: {
          initialDate,
          finalDate,
          employeeId,
          status,
        },
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const findAllByCompanyId = async ({
  companyId,
  limit,
  page,
  query,
  status,
  initialDate,
  finalDate,
}) => {
  const { data } = await api.get(`voucher-payment`, {
    params: {
      companyId,
      limit,
      page,
      query,
      status,
      initialDate,
      finalDate,
    },
  })
  return data
}

const findById = async (paymentId) => {
  const { data } = await api.get(`voucher-payment/single/${paymentId}`)
  return data
}

const create = async (paymentData) => {
  return await api.post(`voucher-payment`, paymentData)
}

const update = async (paymentId, paymentData) => {
  const { data } = await api.put(`voucher-payment/${paymentId}`, paymentData)
  return data
}

const VoucherPaymentRepository = {
  generateByCompanyId,
  findAllByCompanyId,
  findById,
  create,
  update,
}

export default VoucherPaymentRepository
