import React from 'react';
import { withRouter } from 'react-router';

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import AdvertisingForm from './Form';

const HomeAdvertising = ({ match, history }) =>  {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Propaganda', match.params.id ? 'Editar Propaganda': 'Nova Propaganda' ]}
        path={['home', 'advertisements', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <AdvertisingForm advertisingId={match.params.id} onCancel={() => history.push('advertisements')} onSubmit={() => history.push('advertisements')}/>
    </>
  );
};

export default withRouter(HomeAdvertising)