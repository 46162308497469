import React from 'react';
import { Modal } from 'react-bootstrap';

export function ComissionProrateNotAvailable({ open, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <strong>Ratear Comissão</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          Para ativar o rateio de comissão, é necessário definir o cálculo da
          comissão para 'Cadastro de produto' e 'Cadastro de Serviço'. Qualquer
          configuração diferente dessa, não será ativada.
        </span>
        <br />
        <center>
          <small style={{ color: '#d9534F' }}>
            Verifique e tente novamente
          </small>
        </center>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 flex end gap-050">
          <button
            className="button button-red button-h35"
            onClick={handleClose}
          >
            Voltar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
