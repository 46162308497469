import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const Header = ({
  reportType,
  titleStatus,
  customer,
  cashierBank,
  includeCardFee,
  initialDate,
  finalDate,
  dateType,
}) => (
  <View style={{ width: '78%', flexDirection: 'row' }}>
    <View style={{ flexDirection: 'column', width: '23%' }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Relatório: </Text>
        </View>
        <View>
          <Text>{reportType === 'synthetic' ? 'Sintético' : 'Analítico'}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Data: </Text>
        </View>
        <View>
          <Text>
            {dateType === 'dueDate'
              ? 'Vencimento'
              : dateType === 'createdAt'
              ? 'Criação'
              : 'Pagamento'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '36%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Data Início: </Text>
        </View>
        <View style={{ marginRight: '5px' }}>
          <Text>
            {format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>Data Fim: </Text>
        </View>
        <View>
          <Text>
            {format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Status: </Text>
        </View>
        <View>
          <Text>
            {titleStatus === 'open'
              ? 'Aberto - Todos'
              : titleStatus === 'overdue'
              ? 'Aberto - Vencidos'
              : 'Finalizados'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '20%',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Cliente: </Text>
        </View>
        <View>
          <Text>{customer}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Banco/Caixa: </Text>
        </View>
        <View>
          <Text>{cashierBank === '' ? 'Todos' : cashierBank}</Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '21%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Incluir Taxa do Cartão: </Text>
        </View>
        <View>
          <Text>{includeCardFee === 'true' ? 'Sim' : 'Não'}</Text>
        </View>
      </View>
    </View>
  </View>
);

const AccountsReceivableSyntheticPDF = ({
  reportType,
  titleStatus,
  customer,
  cashierBank,
  includeCardFee,
  responseDate,
  dateType,
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`Relatorio-de-Contas-a-Receber${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '22%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Títulos a Receber</Text>
          </View>

          <Header
            reportType={reportType}
            titleStatus={titleStatus}
            customer={customer}
            cashierBank={cashierBank}
            includeCardFee={includeCardFee}
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={dateType}
          />
        </View>

        <ReceivedValues
          totalizatorsByPeriod={responseDate.totalsByPeriod}
          includeCardFee={includeCardFee}
          titleReceive={responseDate.titleReceive}
          totals={responseDate.totals}
          titleStatus={titleStatus}
        />
      </Page>
    </Document>
  );
};

const ReceivedValuesTableHeader = ({ includeCardFee, titleStatus }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Vencimento</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Qtd. de Títulos</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Bruto</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Acréscimo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Juros</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Desconto</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Pago</Text>
        {includeCardFee === 'true' && (
          <Text style={[styles.boldText, { width: '11%' }]}>
            Taxa do Cartão
          </Text>
        )}
        {includeCardFee === 'true' && (
          <Text style={[styles.boldText, { width: '10%' }]}>
            {titleStatus === 'finished' ? 'Saldo Recebido' : 'Saldo a Receber'}
          </Text>
        )}
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

const ReceivedValuesTableRows = ({ item, includeCardFee, titleStatus }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={{ width: '10%' }}>
          {format(new Date(getDateOnlyFromDate(item.Vencimento)), 'dd/MM/yyyy')}
        </Text>
        <Text style={{ width: '10%' }}>{item.QtdeTítulos}</Text>
        <Text style={{ width: '10%' }}>{currency(item.ValorBruto)}</Text>
        <Text style={{ width: '10%' }}>{currency(item.Acrescimo)}</Text>
        <Text style={{ width: '10%' }}>{currency(item.Juros)}</Text>
        <Text style={{ width: '10%' }}>{currency(item.Desconto)}</Text>
        <Text style={{ width: '10%' }}>{currency(item.ValorPago)}</Text>
        {includeCardFee === 'true' && (
          <Text style={{ width: '11%' }}>{currency(item.TaxaCartao)}</Text>
        )}
        {includeCardFee === 'true' && (
          <Text style={{ width: '10%' }}>
            {titleStatus === 'finished'
              ? currency(item.ValorPago - item.TaxaCartao)
              : currency(item.SaldoReceber)}
          </Text>
        )}
      </View>
    </View>
  );
};

const ReceivedValuesTotal = ({ totals, includeCardFee, titleStatus }) => (
  <View style={{ marginTop: '2px' }}>
    <View style={styles.containerRow}>
      <Text style={[styles.boldText, { width: '10%' }]}>Total Geral:</Text>
      <Text style={[styles.boldText, { width: '10%' }]}>{totals.titles}</Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(totals.grossValue)}
      </Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(totals.addedValue)}
      </Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(totals.feeTax)}
      </Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(totals.discountValue)}
      </Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(totals.paidValue)}
      </Text>
      {includeCardFee === 'true' && (
        <Text style={[styles.boldText, { width: '11%' }]}>
          {currency(totals.taxCard)}
        </Text>
      )}
      {includeCardFee === 'true' && (
        <Text style={[styles.boldText, { width: '10%' }]}>
          {titleStatus === 'finished'
            ? currency(totals.balanceReceived)
            : currency(totals.pendingValue)}
        </Text>
      )}
    </View>
    <View style={{ marginTop: '2px' }}></View>
  </View>
);

const TotalizatorByPeriod = ({ totalizatorsByPeriod, titleStatus }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Valores totais do período
        </Text>
      </View>
      <View style={[styles.line, { marginBottom: '2px' }]} />
      {totalizatorsByPeriod.rows.map((item) => (
        <View style={{ width: '100%' }}>
          <View style={styles.containerRow}>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'left',
                width: '10%',
              }}
            >
              {item.description}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {currency(item.value)}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {item.quantity}
            </Text>
          </View>
        </View>
      ))}
      <View style={[styles.line, { width: '75%', marginTop: '2px' }]} />
      <View style={{ width: '100%', marginTop: '2px' }}>
        <View style={styles.containerRow}>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'left',
              width: '10%',
            }}
          >
            {titleStatus === 'finished'
              ? 'Saldo Recebido:'
              : 'Saldo a Receber:'}
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'center',
              width: '10%',
            }}
          >
            {currency(totalizatorsByPeriod.balanceReceivable)}
          </Text>
        </View>
        <View
          style={[
            styles.line,
            { width: '50%', marginTop: '2px', marginBottom: '30px' },
          ]}
        />
      </View>
    </View>
  );
};

const ReceivedValuesWrapper = ({
  includeCardFee,
  titleReceive,
  titleStatus,
}) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Listagem de Títulos
        </Text>
      </View>
      <ReceivedValuesTableHeader
        includeCardFee={includeCardFee}
        titleStatus={titleStatus}
      />

      {titleReceive.map((item) => (
        <ReceivedValuesTableRows
          item={item}
          includeCardFee={includeCardFee}
          titleStatus={titleStatus}
        />
      ))}
    </View>
  );
};

const ReceivedValues = ({
  totalizatorsByPeriod,
  includeCardFee,
  titleReceive,
  totals,
  titleStatus,
}) => {
  return (
    <View>
      <TotalizatorByPeriod
        totalizatorsByPeriod={totalizatorsByPeriod}
        titleStatus={titleStatus}
      />

      <ReceivedValuesWrapper
        titleReceive={titleReceive}
        includeCardFee={includeCardFee}
        titleStatus={titleStatus}
      />
      <View style={styles.line} />

      <ReceivedValuesTotal
        totals={totals}
        includeCardFee={includeCardFee}
        titleStatus={titleStatus}
      />
      <View style={styles.line} />
    </View>
  );
};
export default AccountsReceivableSyntheticPDF;

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
