import React from 'react'
import { Modal } from 'react-bootstrap'

import FormFinancialMovement from '../../../views/FinancialMovements/FinancialMovement/Form'

import './styles.css'

const FormModalFinancialMovement = ({
  financialMovementId,
  onCancel,
  onSubmit,
  preventClose,
  ...rest
}) => {
  return (
    <Modal
      show
      animation
      dialogClassName="form-financial-movement-modal"
      onHide={!preventClose ? onCancel : () => null}
      {...rest}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>
            {!financialMovementId
              ? 'Nova Movimentação de Caixa/Banco'
              : 'Dados da Movimentação de Caixa/Banco'}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormFinancialMovement
          financialMovementId={financialMovementId}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FormModalFinancialMovement
