import config from '../config'
import api from "../services/api"

const getAllStates = async () => {
  try {
    const response = await api.get(config.endpoint + 'address/states')
    return response.data
  } catch (err) {
    throw err
  }
}

const getAllCities = async () => {
  try {
    const response = await api.get(config.endpoint + `address/cities`)
    return response.data
  } catch (err) {
    throw err
  }
}

const getCompanyAddress = companyId => api.get(config.endpoint + `address/company/${companyId}`)

const updateCompanyAddress = (companyId, addressId, data) =>
  api.put(config.endpoint + `address/company/${companyId}/${addressId}`, data)

export default {
  getAllStates,
  getAllCities,
  getCompanyAddress,
  updateCompanyAddress,
}
