import React, { useState, useEffect } from 'react'
import { Grid, Row } from 'react-bootstrap'
import FormEmployee from './FormEmployee'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

const NewEmployee = ({ location }) => {
  const [id, setId] = useState(null)

  localStorage.setItem('selectedPath', 'client/employee')

  useEffect(() => {
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get("id");

    if (idParams) {
      readHash(idParams)
    }
  }

  async function readHash(hash) {
    const employeeId = await decrypt(hash, '@OS-dig:employeeId');
    setId(employeeId);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb data={['Início', 'Meus Funcionários', `${id ? 'Editar' : 'Novo'} Funcionário`]}
            path={['home', 'employees', null]} />
          <div style={{ marginTop: 30 }}>
            <FormEmployee employeeId={id} />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewEmployee