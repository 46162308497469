import produce from 'immer'
import { ActionTypes } from './types'

const INITIAL_STATE = {
  isNoCashierBankOpenedModalOpen: false,
  isPreviousDayCashierBankOpenedModalOpen: false,
  isOpenCashierBankHistoricModalOpen: false,
  isCloseCashierBankHistoricModalOpen: false,
  cashierBankOpened: null,
}

const blockSales = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.handleChange:
        draft[action.payload?.type] = action.payload?.value
        break
      case ActionTypes.handleSubmitCashierBankModal:
        draft.isNoCashierBankOpenedModalOpen = false
        draft.isOpenCashierBankHistoricModalOpen = true
        break
      case ActionTypes.noCashierBankOpened:
        draft.isNoCashierBankOpenedModalOpen = true
        break
      case ActionTypes.previousDayCashierBankExists:
        draft.isPreviousDayCashierBankOpenedModalOpen = true
        draft.cashierBankOpened = action.cashierBankOpened
        break
      case ActionTypes.handleSubmitPreviousDayCashierBankModal:
        draft.isPreviousDayCashierBankOpenedModalOpen = false
        draft.isCloseCashierBankHistoricModalOpen = true
        break
      case ActionTypes.cashierBankHistoricValidationsSuccess:
        draft.cashierBankOpened = action.cashierBankOpened
        break
      default: {
        return draft
      }
    }
  })
}

export default blockSales
