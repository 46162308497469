import React from 'react';

import { ExternalWorkshopTable } from './ExternalWorkshopTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

import '../index.css';
import { useBranding } from 'hooks/useBranding';

export const ExternalWorkshopContent = ({ maintenance, isLoading }) => {
  const companyName = maintenance?.company;

  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  const textColorByBrand = isWorkmotor
    ? 'wm-text-primary'
    : isTecnomotor
    ? 'tecnomotor-text-primary'
    : isBarros
    ? 'barros-text-primary'
    : isPO360
    ? 'po360-text-primary'
    : '';
  return (
    <>
      <p
        className={`external-workshop-panel__maintenance-title ${textColorByBrand}`}
      >
        <FontAwesomeIcon icon={faScrewdriverWrench} size={'lg'} />
        &nbsp; Manutenções - {companyName}
      </p>
      <ExternalWorkshopTable maintenance={maintenance} isLoading={isLoading} />
    </>
  );
};
