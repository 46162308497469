import api from 'services/api';
import config from '../config';

export const bulkCreate = async (saleId, data) => {
  const res = await api.post(config.endpoint + `appointment/${saleId}`, data);
  return res.data;
};

export const getBySale = async (saleId, companyId) => {
  const res = await api.get(config.endpoint + 'appointment', {
    params: {
      saleId,
      companyId,
    },
  });
  return res.data;
};

export const update = async (id, workedHours) => {
  await api.put(config.endpoint + 'appointment/' + id, { workedHours });
  return;
};

export const deleteAppointment = async (id) => {
  await api.delete(config.endpoint + 'appointment/' + id);
  return;
};

const AppointmentsRepository = {
  bulkCreate,
  getBySale,
  update,
  deleteAppointment,
};

export default AppointmentsRepository;
