import React from 'react'
import { Modal } from 'react-bootstrap'

import FormProduct from '../../../views/Products/NewProduct/FormProduto'

import './styles.css'

const FormModalProduct = ({ productId, onCancel, onSubmit, ...rest }) => {
  return (
    <Modal
      show
      animation
      dialogClassName='form-product-modal'
      onHide={onCancel}
      {...rest}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>{!productId ? 'Cadastrar Produto' : 'Dados do Produto'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProduct
          ProductId={productId}
          onCancel={onCancel}
          onSuccess={onSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FormModalProduct