import React, { useState } from 'react';
import { SearchInput } from 'v2/components/form';
import { Table } from 'v2/components/Table';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { useEffect } from 'react';
import useDebounce from 'hooks/useDebounce';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { listDefects } from 'v2/repositories/DefectsRepository';
import CreateNewDefectModal from './CreateNewDefectModal';

export function AddDefectsModal({ handleClose, handleSubmit }) {
  const { companyId } = useAuth();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [isNewDefectModalOpen, setIsNewDefectModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const atLeastOneDefectSelected = selectedRows.length > 0;

  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 300);

  const allSelected = rows.every((row) => row.selected);

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 250,
      Footer: (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <button title="Excluir" className="button-icon" onClick={() => null}>
            <FontAwesomeIcon
              cursor="pointer"
              fontSize={20}
              style={{
                color: 'green',
              }}
              icon={faPlusCircle}
              onClick={() => setIsNewDefectModalOpen(true)}
            />
          </button>
        </div>
      ),
    },
    {
      Header: 'Detalhes dos defeitos',
      accessor: 'details',
      Footer: (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <a id="hiperlink-text" onClick={() => setIsNewDefectModalOpen(true)}>
            Cadastrar Novo Defeito
          </a>
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'id',
      width: 60,
      Cell: (props) => (
        <input
          type="checkbox"
          className="checkbox-input bulk-deletion-table"
          checked={props.original.selected}
          onChange={() => handleCheckboxChange(props.index, props.original)}
        />
      ),
    },
  ];

  const onHide = () => {
    return handleClose();
  };

  const onCancel = () => {
    return handleClose();
  };

  const onSubmit = () => {
    handleSubmit(selectedRows);
    handleClose();
    toastr.success(
      'Defeitos adicionados',
      'Os itens selecionados foram adicionados na relação com sucesso'
    );
    return;
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const res = await listDefects({
        page: page + 1,
        limit,
        query: queryInput,
        companyId,
      });
      const mappedRows = res.rows.map((row) => {
        const selected = selectedRows.some(
          (selectedRow) => selectedRow.id === row.id
        );
        return {
          ...row,
          selected,
        };
      });
      setRows(mappedRows);
      setCount(res.count);
      setPages(Math.ceil(res.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const handleCheckboxChange = (index, item) => {
    const newRows = rows.map((row, currentIndex) => {
      if (currentIndex === index) {
        return {
          ...row,
          selected: !row.selected,
        };
      }
      return row;
    });

    if (newRows[index].selected) {
      setSelectedRows([...selectedRows, newRows[index]]);
    } else {
      setSelectedRows(
        selectedRows.filter((row) => row.id !== newRows[index].id)
      );
    }

    setRows(newRows);
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    const value = allSelected ? false : true;

    const newSelectedRows = [
      ...new Set([
        ...selectedRows,
        ...rows
          .map((row) => ({ ...row, selected: value }))
          .filter(
            (row, index, self) =>
              index === self.findIndex((t) => t.id === row.id)
          ),
      ]),
    ];

    setSelectedRows(newSelectedRows);
    setRows(
      rows.map((row) => {
        return {
          ...row,
          selected: value,
        };
      })
    );
  };

  const handleSelectNewDefect = (defect) => {
    const newRow = {
      id: defect.id,
      companyId: defect.companyId,
      correctionsAssociated: [],
      description: defect.description,
      details: defect.details,
      status: defect.status,
      totalCorrections: 0,
      selected: true,
    };
    setRows([...rows, newRow]);
    setSelectedRows([...selectedRows, newRow]);
  };

  useEffect(() => {
    handleFetch();
  }, [queryInput, companyId, handleSubmit, page]);

  return (
    <Modal
      dialogClassName="modal-marginTop10 modal-70w"
      show={true}
      onHide={() => (isLoading ? {} : onHide())}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <strong>Adicionar Defeitos</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <SearchInput
            id="input__query"
            placeholder="Pesquisa por Descrição ou Detalhes"
            width={500}
            onChange={(e) => debounceQueryInput(e.target.value)}
          />
          <div
            className="mt-2"
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <a
              href="#"
              onClick={handleSelectAll}
              style={{ marginRight: '10px' }}
            >
              {!allSelected ? 'Selecionar' : 'Remover'} Todos
            </a>
          </div>
        </div>
        <div className="mt-2" style={{ width: '100%', alignItems: 'center' }}>
          <Table
            columns={columns}
            data={rows}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            manual
            onPageSizeChange={setLimit}
            pages={pages}
            loading={isFetching}
            style={{
              fontSize: '12px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '330px',
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Button
            bsStyle="danger"
            disabled={isLoading}
            name="Não"
            onClick={(e) => onCancel(e)}
            fill
          >
            Cancelar
          </Button>

          <Button
            bsStyle="info"
            disabled={isLoading || !atLeastOneDefectSelected}
            name="Sim"
            fill
            onClick={(e) => onSubmit(e)}
          >
            Salvar
            <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
        {isNewDefectModalOpen && (
          <CreateNewDefectModal
            handleClose={() => setIsNewDefectModalOpen(false)}
            companyId={companyId}
            onInclude={handleSelectNewDefect}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}
