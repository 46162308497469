import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 15px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #ccc;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  width: ${({ progress, type, total }) => {
    if (progress === 0) return 0;
    if (type === 'percentage') {
      return (progress / total) * 100;
    } else {
      return progress < 100 ? progress : 100;
    }
  }}%;
  height: 100%;
  background: ${({ progress }) => (progress > 0 ? '#5cb85c' : 'transparent')};
  transition: width 0.3s ease;
`;
