import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import renderField from '../Input/RenderField';
import validate from 'views/TryForFree/validateExpGrts';
import {
  faCar,
  faMotorcycle,
  faVanShuttle,
  faTruck,
  faTractor,
  faSnowplow,
  faCogs,
  faBicycle,
  faWheatAwn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { handleChange, loadSegment } from './ReduxSegmento/segmentoAction';

import './styles.css';

class SegmentoExpGrts extends Component {
  state = {
    userId: localStorage.getItem('ID_USUARIO'),
    validado: false,
  };

  componentDidMount() {
    let Segment = this.props.Segment;
    if (Segment) {
      Segment = Object.keys(Segment);
      for (let i = 0; i < Segment.length; i++) {
        this.setState({ [Segment[i]]: true });
      }
    }
    this.props.loadSegment(this.state.userId, this.props.segmentoReducer);
  }

  verificarSegmetos() {
    const {
      Carros,
      Motos,
      Pesados,
      Trator,
      Escavadeira,
      Van,
      Bicicleta,
      Colheitadeira,
    } = this.props.segmentoReducer;

    const status = [
      Carros,
      Motos,
      Pesados,
      Trator,
      Escavadeira,
      Van,
      Bicicleta,
      Colheitadeira,
    ];

    let count = 0;

    for (let i = 0; i < status.length; i++) {
      if (status[i]) {
        count++;
      }
    }

    if (count === 1) {
      toastr.warning('É obrigatório ao menos 1 segmento estar ativo.');
      return false;
    }

    return true;
  }

  setValue(event) {
    let { currentSegments } = this.props.segmentoReducer;
    let obj = [];
    let todos = [];

    this.setState({ [event.target.name]: !this.state[event.target.name] });

    switch (event.target.name) {
      case 'Carros':
        obj = {
          id: 1,
          Description: 'Carros',
        };
        break;
      case 'Motos':
        obj = {
          id: 2,
          Description: 'Motos',
        };
        break;
      case 'Pesados':
        obj = {
          id: 3,
          Description: 'Pesados',
        };
        break;
      case 'Outros':
        obj = {
          id: 4,
          Description: 'Outros',
        };
      case 'Trator':
        obj = {
          id: 5,
          Description: 'Trator',
        };
      case 'Escavadeira':
        obj = {
          id: 6,
          Description: 'Escavadeira',
        };
        break;
      case 'Van':
        obj = {
          id: 7,
          Description: 'Van',
        };
        break;
      case 'Bicicleta':
        obj = {
          id: 8,
          Description: 'Bicicleta',
        };
        break;
      case 'Colheitadeira':
        obj = {
          id: 9,
          Description: 'Colheitadeira',
        };
        break;
    }
    todos.push(obj);
    let filter = [];
    for (let i = 0; i < currentSegments.length; i++) {
      for (let j = 0; j < todos.length; j++) {
        if (currentSegments[i].id !== todos[j].id) {
          this.props.handleChange(
            [...this.props.segmentoReducer.currentSegments, ...todos],
            'currentSegments'
          );
        } else {
          filter = currentSegments.filter((x) => x.id != todos[j].id);
          this.props.handleChange(filter, 'currentSegments');
        }
      }
    }
  }

  render() {
    const { handleSubmit, isGridded } = this.props;
    const {
      Carros,
      Motos,
      Pesados,
      Trator,
      Escavadeira,
      Van,
      Bicicleta,
      Colheitadeira,
      Outros,
    } = this.props.segmentoReducer;
    const { validado } = this.state;
    return (
      <form onSubmit={handleSubmit}>
        <div
          className={
            isGridded
              ? 'segments__vehicle-icons-grid'
              : 'segments__vehicle-icons-flex'
          }
        >
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Carros}
              className="hidden"
              onClick={() => {
                if (Carros) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Carros');
                  }
                } else {
                  this.props.handleChange(true, 'Carros');
                }
              }}
              name="Carros"
              id="cb1"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Carros}
            />
            <label htmlFor="cb1" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faCar}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Carros ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Carros ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Carro
              </p>
            </label>
          </div>
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Motos}
              className="hidden"
              onClick={() => {
                if (Motos) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Motos');
                  }
                } else {
                  this.props.handleChange(true, 'Motos');
                }
              }}
              name="Motos"
              id="cb2"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Motos}
            />
            <label htmlFor="cb2" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faMotorcycle}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Motos ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Motos ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Moto
              </p>
            </label>
          </div>
          <div className="textoCenter" style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              style={{ cursor: 'pointer' }}
              checked={Pesados}
              className="hidden"
              onClick={() => {
                if (Pesados) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Pesados');
                  }
                } else {
                  this.props.handleChange(true, 'Pesados');
                }
              }}
              name="Pesados"
              id="cb3"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Pesados}
            />
            <label htmlFor="cb3" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faTruck}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Pesados ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Pesados ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Caminhão
              </p>
            </label>
          </div>
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Van}
              className="hidden"
              onClick={() => {
                if (Van) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Van');
                  }
                } else {
                  this.props.handleChange(true, 'Van');
                }
              }}
              name="Van"
              id="cb7"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Van}
            />
            <label htmlFor="cb7" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faVanShuttle}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Van ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Van ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Van
              </p>
            </label>
          </div>{' '}
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Bicicleta}
              className="hidden"
              onClick={() => {
                if (Bicicleta) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Bicicleta');
                  }
                } else {
                  this.props.handleChange(true, 'Bicicleta');
                }
              }}
              name="Bicicleta"
              id="cb8"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Bicicleta}
            />
            <label htmlFor="cb8" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faBicycle}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Bicicleta ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Bicicleta ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Bicicleta
              </p>
            </label>
          </div>{' '}
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Trator}
              className="hidden"
              onClick={() => {
                if (Trator) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Trator');
                  }
                } else {
                  this.props.handleChange(true, 'Trator');
                }
              }}
              name="Trator"
              id="cb5"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Trator}
            />
            <label htmlFor="cb5" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faTractor}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Trator ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Trator ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Trator
              </p>
            </label>
          </div>
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Escavadeira}
              className="hidden"
              onClick={() => {
                if (Escavadeira) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Escavadeira');
                  }
                } else {
                  this.props.handleChange(true, 'Escavadeira');
                }
              }}
              name="Escavadeira"
              id="cb6"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Escavadeira}
            />
            <label htmlFor="cb6" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faSnowplow}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Escavadeira ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Escavadeira ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Escavadeira
              </p>
            </label>
          </div>
          <div className="textoCenter">
            <input
              type="checkbox"
              checked={Colheitadeira}
              className="hidden"
              onClick={() => {
                if (Colheitadeira) {
                  const valid = this.verificarSegmetos();
                  if (!valid) {
                    return;
                  } else {
                    this.props.handleChange(false, 'Colheitadeira');
                  }
                } else {
                  this.props.handleChange(true, 'Colheitadeira');
                }
              }}
              name="Colheitadeira"
              id="cb9"
              onChange={(e) => {
                this.setValue(e);
              }}
              value={Colheitadeira}
            />
            <label htmlFor="cb9" style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faWheatAwn}
                className="iconeMeusSegmentos"
                aria-hidden="false"
                style={{
                  color: Colheitadeira ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              />
              <p
                id="pSeg"
                className="fontMeusSegmentos"
                style={{
                  color: Colheitadeira ? '#1d7391' : '#444444',
                  cursor: 'pointer',
                }}
              >
                Colheitadeira
              </p>
            </label>
          </div>
        </div>

        <Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              paddingBottom: '10px',
            }}
          >
            <Field name="Segmento" type="hidden" component={renderField} />
          </Col>
        </Col>
      </form>
    );
  }
}

SegmentoExpGrts = reduxForm({
  form: 'ExpGrtsForm', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(SegmentoExpGrts);

const selector = formValueSelector('ExpGrtsForm');

const mapStateToProps = (state) => ({
  Segment: selector(state, 'Segment'),
  segmentoReducer: state.segmentoReducer,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleChange, loadSegment }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SegmentoExpGrts);
