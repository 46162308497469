import React from 'react'
import CustomButton from 'client/components/CustomButton/CustomButton'
import { format } from 'date-fns'

import { Modal } from 'react-bootstrap'

import '../index.css'

const ShowStopReasonModal = ({ maintenance, onCancel }) => {
  const lastMaintenanceHoursIndex = maintenance.MaintenanceHours.length - 1
  const lastStopHour = new Date(
    maintenance.MaintenanceHours[lastMaintenanceHoursIndex].stop
  )

  return (
    <>
      <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Painel da Oficina</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>
            <strong>Data da última parada:</strong> <br />
            {format(lastStopHour, 'dd/MM/yyyy HH:mm')}
          </p>

          <div style={{ marginTop: '20px', maxWidth: '100%' }}>
            <label htmlFor="stop-reason" id="stop-reason-label">
              Motivo:
            </label>
            <textarea
              disabled
              type="text"
              className="form-control"
              name="stop-reason"
              id="stop-reason"
              maxLength="500"
              rows="5"
              value={maintenance.stopReason}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="voltar"
              onClick={onCancel}
              fill
            >
              Voltar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShowStopReasonModal
