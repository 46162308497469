import React from "react";
import { Grid, Row } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import './styles.css';
import HomeNcmNbs from './NcmNbs'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Ncm = () => {
  localStorage.setItem('selectedPath', 'client/Transportadora')
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'NCM/NBS']}
           path={['home', null, null, 'NcmNbs' ]} />
          <HomeNcmNbs />
        </Row>
      </Grid>
    </div>
  );
}
export default Ncm