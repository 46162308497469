import api from '../services/api'
import config from '../config'

const show = async (companyId) => {
  const response = await api.get(
    config.endpoint + `stone-cards?companyId=${companyId}`
  )

  return response.data
}

const getExtrations = async ({ companyId, initialDate, finalDate, type }) => {
  const response = await api.get('stone-cards/statement', {
    params: { companyId, initialDate, finalDate, type },
  })

  return response.data
}

const stoneCards = {
  show,
  getExtrations,
}

export default stoneCards
