import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { HomePurchaseSelect } from 'client/components/PurchaseSelect/HomePurchaseSelect';

export const PurchaseSelectModal = ({
  onHide,
  onCancel,
  handleSelectPurchase,
}) => {
  return (
    <Modal
      show
      backdrop={'static'}
      dialogClassName="modal-80w modal-70h"
      onHide={onHide}
      animation
    >
      <Modal.Header>
        <Modal.Title>
          <strong>Selecione a compra</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HomePurchaseSelect handleSelectPurchase={handleSelectPurchase} />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={() => onCancel()} fill>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
