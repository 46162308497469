import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import RenderField from 'components/RenderField'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import {
  onlyNumbers,
  onlyNumbersLetters,
} from 'client/components/ToNormalize/ToNormalize'

const InvoiceData = ({ disableInputs }) => {
  const { type } = useSelector((state) => state.form.purchases.values)

  return (
    <>
      <Row className="row-min-margin">
        <Col xs={12} sm={12} md={2} lg={2} className="entry-number-field">
          <Field
            label="Nota:"
            name="invoice"
            normalize={onlyNumbers}
            component={RenderField}
            disabled={disableInputs}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="entry-number-field">
          <Field
            label="Série:"
            name="serie"
            normalize={onlyNumbersLetters}
            component={RenderField}
            disabled={disableInputs}
          />
        </Col>
        {type === 'XML' && (
          <Col xs={12} sm={12} md={2} lg={2} className="entry-number-field">
            <Field
              label="Modelo:"
              name="model"
              component={RenderField}
              disabled={disableInputs}
            />
          </Col>
        )}
        <Col xs={12} sm={12} md={4} lg={4} style={{ width: '440px' }}>
          <Field
            label="Chave de Acesso:"
            name="accessKey"
            normalize={onlyNumbersLetters}
            component={RenderField}
            disabled={disableInputs}
          />
        </Col>

        <Col xs={12} sm={12} md={2} lg={2} className="date-field">
          <Field
            label="Data de Emissão:"
            name="issuedAt"
            component={RenderField}
            max={format(new Date(), 'yyyy-MM-dd')}
            type="date"
            disabled={disableInputs}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="date-field">
          <Field
            label="Data de Entrada:"
            name="entryAt"
            max={format(new Date(), 'yyyy-MM-dd')}
            component={RenderField}
            type="date"
            disabled={disableInputs}
            required={true}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="entry-number-field">
          <Field
            label="Pedido:"
            name="request"
            component={RenderField}
            disabled={disableInputs}
          />
        </Col>

        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            label="Informações Complementares:"
            name="additionalInformation"
            component={RenderField}
            as="textarea"
            maxLength={100}
            disabled={disableInputs}
          />
        </Col>
      </Row>
    </>
  )
}

export default reduxForm({
  form: 'purchases',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(InvoiceData)
