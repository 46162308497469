import api from '../services/api'

const getAll = async (companyId) => {
  const { data } = await api.get(`/discount-groups?companyId=${companyId}`)

  return data
}

const findById = async (id) => {
  const { data } = await api.get(`/discount-groups/${id}`)

  return data
}

const create = async (discountGroupInfo) => {
  const response = await api.post('/discount-groups', discountGroupInfo, {
    validateStatus: () => true,
  })

  return response
}

const update = async (id, discountGroupInfo) => {
  const response = await api.put(`/discount-groups/${id}`, discountGroupInfo, {
    validateStatus: () => true,
  })

  return response
}

const deleteGroup = async (id) => await api.delete(`/discount-groups/${id}`)

export default { getAll, findById, create, update, deleteGroup }
