import api from '../services/api'
import config from '../config'

const getServicesByCompany = async (companyId) => {
  const response = await api.get(
    config.endpoint + `companyServiceType/Search/ByCompany/${companyId}`
  )

  return response.data
}

const companyServiceTypeRepository = {
  getServicesByCompany,
}

export default companyServiceTypeRepository
