import React, { useEffect, useState } from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency } from 'client/components/ToNormalize/ToNormalize'

export const CanceledBudgetsDocument = ({
  formatedLossReasons,
  result,
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`impresso-orcamentos-cancelados-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>Listagem de Orçamentos Cancelados</Text>
          <Text style={styles.text}>
            {initialDate} - {finalDate}
          </Text>
        </View>

        <CanceledBudgetsResume formatedLossReasons={formatedLossReasons} />

        <View
          style={{
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.subtitle}>Orçamentos Cancelados</Text>
        </View>
        <View style={styles.line} />

        {result.map((item, index) => (
          <>
            <CanceledBudgetItemDetail
              key={index}
              budgetCode={item.Code}
              creationDate={item.Date}
              cancelDate={item.updatedAt}
              plate={item.License_Plate}
              kilometers={item.Kilometers}
              lossReason={item.LossReason.description}
            />

            <CanceledBudgetItem
              key={item.SalesItems.id}
              salesItems={item.SalesItems}
            />
          </>
        ))}
      </Page>
    </Document>
  )
}

const CanceledBudgetItem = ({ salesItems }) => {
  return (
    <>
      <CanceledBudgetsHeader />

      {salesItems.map((sale) => {
        return (
          <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
            <View
              style={{
                width: '18%',
                textAlign: 'left',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {sale.Sale_id} - {sale?.Description}
              </Text>
            </View>
            <View
              style={{
                width: '10%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {sale.Type === 'Produto' ? 'P' : 'S'}
              </Text>
            </View>
            <View
              style={{
                width: '10%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{sale.Quantity}</Text>
            </View>
            <View
              style={{
                width: '11%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>{currency(sale.Unit_Value)}</Text>
            </View>
            <View
              style={{
                width: '11%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {currency(sale.Quantity * sale.Unit_Value)}
              </Text>
            </View>
            <View
              style={{
                width: '20%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {sale.Type === 'Produto'
                  ? sale.Products?.Warranty?.Description
                  : sale.Services?.Warranty?.Description}
              </Text>
            </View>
            <View
              style={{
                width: '20%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.text}>
                {sale.revisionDate
                  ? format(new Date(sale.revisionDate), 'dd/MM/yyyy')
                  : ''}
              </Text>
            </View>
          </View>
        )
      })}

      <View style={styles.line} />
    </>
  )
}

const CanceledBudgetItemDetail = ({
  budgetCode,
  creationDate,
  cancelDate,
  plate,
  kilometers,
  lossReason,
}) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          height: 15,
          width: '100%',
          marginTop: 10,
        }}
      >
        <View
          style={{ width: '24%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            <Text style={styles.header}>Data de Criação: </Text>
            {format(new Date(creationDate), 'dd/MM/yyyy')}
          </Text>
        </View>
        <View
          style={{ width: '76%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            <Text style={styles.header}>Data de Cancelamento: </Text>
            {format(new Date(cancelDate), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '16%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            <Text style={styles.header}>Orçamento: </Text>
            {budgetCode}
          </Text>
        </View>
        <View
          style={{ width: '16%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            <Text style={styles.header}>Placa: </Text>
            {plate.replace(/(\d{0})(\d)/, '$1-$2').toString() + ' '}
          </Text>
        </View>
        <View
          style={{ width: '78%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            <Text style={styles.header}>KM Atual: </Text>
            {kilometers}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          height: 15,
          width: '100%',
          textAlign: 'left',
        }}
      >
        <Text style={styles.text}>
          <Text style={styles.header}>Motivo: </Text>
          {lossReason}
        </Text>
      </View>
    </>
  )
}

const CanceledBudgetsHeader = () => {
  return (
    <>
      <View style={styles.line} />
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '18%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.header}>Descrição</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Tipo</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Qtd</Text>
        </View>
        <View
          style={{
            width: '11%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>R$ Unit</Text>
        </View>
        <View
          style={{
            width: '11%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Total</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Garantia</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Revisão</Text>
        </View>
      </View>
      <View style={styles.line} />
    </>
  )
}

const CanceledBudgetsResume = ({ formatedLossReasons }) => {
  return (
    <View>
      <View
        style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.subtitle}>Resumo</Text>

        <ResumeHeader />

        {formatedLossReasons.map((lossReason) => (
          <ResumeItemsList
            key={lossReason.id}
            description={lossReason.description}
            quantity={lossReason.count}
            value={lossReason.totalValue}
          />
        ))}
        <View style={styles.line} />
      </View>
    </View>
  )
}

const ResumeItemsList = ({ description, quantity, value }) => {
  return (
    <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
      <View
        style={{ width: '65%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{description}</Text>
      </View>
      <View
        style={{ width: '25%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{quantity}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'right', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{currency(value)}</Text>
      </View>
    </View>
  )
}

const ResumeHeader = () => {
  return (
    <>
      <View style={styles.line} />
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '65%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.header}>Motivo:</Text>
        </View>
        <View
          style={{
            width: '25%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Quantidade</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.header}>Valor:</Text>
        </View>
      </View>
      <View style={styles.line} />
    </>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  header: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 8,
  },
})
