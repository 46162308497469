import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'

import BundleCompanyData from './BundleCompanyData'

import 'react-tabs/style/react-tabs.css'

const ClientData = ({ clientId, reload }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BundleCompanyData companyId={clientId} reload={reload} />
        </Row>
      </Grid>
    </div>
  )
}

export default withRouter(ClientData)
