import React from 'react';

import FormNewProvider from 'client/views/Providers/NewProvider/FormNewProvider';

export default function NewProviderForm({
  handleClose,
  handleSelectCreatedProvider,
  providerId,
}) {
  function onSubmit(createdProvider) {
    handleSelectCreatedProvider(createdProvider);
    handleClose();
  }

  return (
    <FormNewProvider
      onSubmit={onSubmit}
      onCancel={handleClose}
      providerId={providerId}
    />
  );
}
