import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import Form from './FinancialConfigurationForm';
import companiesRepository from '../../../../repositories/Companies';
import { useAuth } from '../../../../contexts/auth';
import constants, { FREE_PLANS_ID } from '../../../../utils/constants';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const FinancialForm = () => {
  const { loadCompany, companyId, company } = useAuth();
  const { plan } = usePlanSignatureContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company) {
      const initialValues = {
        defaultCashierBankId: String(company.defaultCashierBankId),
        hasCashierControl: company.hasCashierControl,
        hasAutomaticOpenAndCloseCashierMovement:
          company.hasAutomaticOpenAndCloseCashierMovement,
        cashierConference: company.cashierConference,
        defaultSaleAccountPlanId: company.defaultSaleAccountPlanId,
        defaultPurchaseAccountPlanId: company.defaultPurchaseAccountPlanId,
        defaultChangeAccountPlanId: company.defaultChangeAccountPlanId,
        monthlyInterest: company.companyConfig.monthlyInterest,
        monthlyInterestValue: company.companyConfig.monthlyInterestValue,
        billModel: company.companyConfig.billModel,
        generateTitleDiff: company.companyConfig.generateTitleDiff,
        manageInternalCredit: company.companyConfig.manageInternalCredit,
      };
      dispatch(initialize('financialConfiguration', initialValues));
    }
  }, [company]);

  async function handleUpdateFinancialConfiguration(values) {
    const {
      hasCashierControl,
      defaultCashierBankId,
      hasAutomaticOpenAndCloseCashierMovement,
      cashierConference,
      defaultSaleAccountPlanId,
      defaultPurchaseAccountPlanId,
      defaultChangeAccountPlanId,
      monthlyInterest,
      monthlyInterestValue,
      generateTitleDiff,
      billModel,
      manageInternalCredit,
    } = values;

    if (FREE_PLANS_ID.includes(plan.id))
      return toastr.warning(`Função indisponível para o plano free`);

    try {
      await companiesRepository.updateFinancialConfiguration(companyId, {
        hasCashierControl,
        defaultCashierBankId,
        hasAutomaticOpenAndCloseCashierMovement,
        cashierConference,
        defaultSaleAccountPlanId,
        defaultPurchaseAccountPlanId,
        defaultChangeAccountPlanId,
        monthlyInterest,
        monthlyInterestValue,
        generateTitleDiff,
        billModel,
        manageInternalCredit,
      });
      toastr.success('Configurações Financeiras salvas com sucesso.');
      loadCompany();
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao atualizar as configurações financeiras'
      );
    }
  }

  const initialValues = {
    hasCashierControl: false,
    hasAutomaticOpenAndCloseCashierMovement: false,
    cashierConference: false,
    monthlyInterest: true,
    monthlyInterestValue: 0,
  };

  return (
    <Form
      onSubmit={handleUpdateFinancialConfiguration}
      initialValues={initialValues}
    />
  );
};

export default FinancialForm;
