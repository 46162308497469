import React from 'react';
import PDFButton from 'client/components/PDFButton';
import MoreFiltersButton from 'client/components/MoreFiltersButton';
import xlsColumnsSynthetic from './ExcelHelperSynthetic';
import xlsColumnsAnalytical from './ExcelHelperAnalytical';
import DownloadXlsButton from 'components/DownloadXlsButton';
import Select from 'react-select';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../index.css';

const AccountsReceivableFilter = ({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  clientsSuggestions,
  isLoading,
  showSecond,
  setShowSecond,
  excelData,
  cashierBanks,
  dateType,
  setDateType,
  reportType,
  setReportType,
  titleStatus,
  setTitleStatus,
  cashierBanksValue,
  setCashierBanksValue,
  displayNotes,
  setDisplayNotes,
  sortByCustomer,
  setSortByCustomer,
  setCustomerId,
  customerId,
  includeCardFee,
  setIncludeCardFee,
  validateExcel,
  handlePrint,
}) => {
  return (
    <div className="container-filters-accountsReceivable">
      <section>
        <div className="first-row_of_filters">
          <div>
            <Select
              placeholder={
                'Pesquisa por CPF/CNPJ ou Nome, Razão Social ou Nome Fantasia'
              }
              options={clientsSuggestions}
              value={customerId}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  width: '475px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
              onChange={(e) => {
                setCustomerId(e);
              }}
              noOptionsMessage={() => 'Sem opções'}
            />
          </div>

          <div className="first-row_corretion_size">
            <span className="first-row_strong">Tipo de Data:</span>
            <select
              className="form-control foco-input"
              value={dateType}
              onChange={(e) => {
                setDateType(e.target.value);
              }}
            >
              <option value="dueDate" selected>
                Vencimento
              </option>
              <option value="createdAt">Criação</option>
              <option value="lowDate">Pagamento</option>
            </select>
          </div>

          <div className="first-row_corretion_size ">
            <span className="first-row_strong">Data Inicial:</span>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>

          <div className="first-row_corretion_size ">
            <span className="first-row_strong">Data Final:</span>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </div>
        </div>

        <div className="second-row_of_filters">
          <div className="second-row_corretion_size">
            <span className="first-row_strong">Tipo de Relatório:</span>
            <select
              className="form-control foco-input"
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);
              }}
            >
              <option value="synthetic" selected>
                Sintético
              </option>
              <option value="analytical">Analítico</option>
            </select>
          </div>

          <div className="second-row_corretion_size">
            <span className="first-row_strong">Status de título:</span>
            <select
              className="form-control foco-input"
              value={titleStatus}
              onChange={(e) => {
                setTitleStatus(e.target.value);
              }}
            >
              <option value="open" selected>
                Aberto - Todos
              </option>
              <option value="overdue">Aberto - Vencidos</option>
              <option value="finished">Finalizados </option>
            </select>
          </div>

          <div className="second-row_corretion_size">
            <span className="first-row_strong">Banco/Caixa:</span>
            <select
              className="form-control foco-input"
              value={cashierBanksValue}
              onChange={(e) => {
                setCashierBanksValue(e.target.value);
              }}
            >
              <option value="">Todos</option>
              {cashierBanks.map((cashierBank) => (
                <option value={cashierBank.id} key={cashierBank.id}>
                  {cashierBank.description}
                </option>
              ))}
            </select>
          </div>

          <div>
            <MoreFiltersButton
              style={{ height: '35px' }}
              showSecond={showSecond}
              onClick={() => {
                setShowSecond(!showSecond);
              }}
            />
          </div>

          <div>
            <PDFButton isLoading={isLoading} onClick={() => handlePrint()} />
          </div>

          {reportType === 'synthetic' ? (
            <div>
              <DownloadXlsButton
                archiveName="Contas a Receber"
                data={excelData}
                className="btn btn-export "
                columns={xlsColumnsSynthetic}
                style={{ width: '100%', height: '35px' }}
                disabled={!validateExcel}
              >
                <span
                  className={`${
                    isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                  }`}
                />{' '}
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          ) : (
            <div>
              <DownloadXlsButton
                archiveName="Contas a Receber"
                data={excelData}
                className="btn btn-export "
                columns={xlsColumnsAnalytical}
                style={{ width: '100%', height: '35px' }}
                disabled={!validateExcel}
              >
                <span
                  className={`${
                    isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                  }`}
                />{' '}
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          )}
        </div>
        {showSecond && (
          <div className="three-row_of_filters">
            <div className="three-row_corretion_size">
              <span className="first-row_strong">Exibir Observ:</span>
              <select
                className="form-control foco-input"
                value={displayNotes}
                onChange={(e) => {
                  setDisplayNotes(e.target.value);
                }}
              >
                <option value="true">Sim</option>
                <option value="false" selected>
                  Não{' '}
                </option>
              </select>
            </div>

            <div className="three-row_corretion_size">
              <span className="first-row_strong">Ordenar por Cliente:</span>
              <select
                className="form-control foco-input"
                value={sortByCustomer}
                onChange={(e) => {
                  setSortByCustomer(e.target.value);
                }}
              >
                <option value="true">Sim</option>
                <option value="false" selected>
                  Não
                </option>
              </select>
            </div>

            <div className="three-row_corretion_size">
              <span className="first-row_strong">Incluir taxa do cartão:</span>
              <select
                className="form-control foco-input"
                value={includeCardFee}
                onChange={(e) => {
                  setIncludeCardFee(e.target.value);
                }}
              >
                <option value="true" selected>
                  Sim
                </option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default AccountsReceivableFilter;
