import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { toastr } from 'react-redux-toastr';

import { faPix } from '@fortawesome/free-brands-svg-icons';

import { currencyMask } from 'utils/masks';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';

import RenderField from '../../../../../components/RenderField';
import { splitParcelsValue } from '../../../../../utils/createInstallments';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useBonus } from 'hooks/useBonus';

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    {props.data.isStoneIntegrated && (
      <FontAwesomeIcon
        className="option-icon"
        color="#444444"
        icon={faCreditCard}
      />
    )}
    <span>{props.data.label}</span>
  </Option>
);

const Installment = ({ paymentForms, installment, installments, index }) => {
  const [paymentFormsOptions, setPaymentFormsOptions] = useState();
  const [saleTypeOptions, setSaleTypeOptions] = useState();
  const [selectedStonePaymentId, setSelectedStonePaymentId] = useState(null);
  const [selectedMateraPaymentId, setSelectedMateraPaymentId] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [isAvailableCreditPayment, setIsAvailableCreditPayment] =
    useState(false);
  const dispatch = useDispatch();
  const { totalSale } = useSelector((state) => state.form.pdvOnline.values);
  const { companyHasCRMBonus, redeemedBonus } = useBonus();

  useEffect(() => {
    if (!paymentForms) return;

    const paymentFormsOptions = paymentForms.map(
      ({
        id,
        Desciption: description,
        isStoneIntegration,
        isMateraIntegration,
      }) => ({
        label: isMateraIntegration ? (
          <>
            {description} &nbsp;
            <FontAwesomeIcon
              color="#000"
              icon={faPix}
              className="option-icon"
            />
          </>
        ) : (
          description
        ),
        value: id,
        isStoneIntegrated: isStoneIntegration,
        isMateraIntegrated: isMateraIntegration,
        isAvailableCreditPayment: isAvailableCreditPayment,
      })
    );

    setPaymentFormsOptions(paymentFormsOptions);
  }, [paymentForms]);

  useEffect(() => {
    const selectedStonePayment = installments.find(
      (installment) => installment.isStoneIntegrated
    );

    const selectedMateraPayment = installments.find(
      (installment) => installment.isMateraIntegrated
    );

    setSelectedStonePaymentId(selectedStonePayment?.formOfPaymentId);
    setSelectedMateraPaymentId(selectedMateraPayment?.formOfPaymentId);
  }, [installments]);

  useEffect(() => {
    clearPaymentForm();
  }, [installments.length]);

  function handleAdjustInstallmentValues(value) {
    const newInstallments = [...installments];
    newInstallments[index].value = onlyNumbers(value) / 100;

    const installmentNumber = index + 1;
    const remainingInstallments = newInstallments.length - installmentNumber;

    if (installmentNumber === newInstallments.length) return;

    if (companyHasCRMBonus && redeemedBonus > 0) {
      if (newInstallments[index].value >= totalSale - redeemedBonus) {
        const serializedInstallments = splitParcelsValue(
          newInstallments,
          totalSale - redeemedBonus
        );
        dispatch(change('pdvOnline', 'installments', serializedInstallments));
        return;
      }
    } else {
      if (newInstallments[index].value >= totalSale) {
        const serializedInstallments = splitParcelsValue(
          newInstallments,
          totalSale
        );
        dispatch(change('pdvOnline', 'installments', serializedInstallments));
        return;
      }
    }

    let parcelsValuesAmount = 0;
    for (let i = 0; i < installmentNumber; i++) {
      parcelsValuesAmount = parcelsValuesAmount + newInstallments[i].value;
    }

    let restValue = 0;

    if (companyHasCRMBonus && redeemedBonus > 0) {
      restValue = totalSale - redeemedBonus - parcelsValuesAmount;
    } else {
      restValue = totalSale - parcelsValuesAmount;
    }

    const parcelValue = Number((restValue / remainingInstallments).toFixed(2));
    const rest = restValue - parcelValue * remainingInstallments;

    for (let i = installmentNumber; i < newInstallments.length; i++) {
      newInstallments[i].value = parcelValue;
    }

    newInstallments[newInstallments.length - 1].value += rest;
    dispatch(change('pdvOnline', 'installments', newInstallments));
  }

  async function handleFormOfPayment(paymentFormId) {
    const form = await paymentForms.find(
      (p) => p.id === parseInt(paymentFormId)
    );

    const paymentForm = paymentForms.find(
      (paymentForm) => paymentForm.id === paymentFormId
    );

    if (
      paymentForm.isStoneIntegration &&
      !!selectedStonePaymentId &&
      selectedStonePaymentId !== paymentFormId &&
      installments.length > 1
    ) {
      clearPaymentForm();

      return toastr.warning(
        'Não é permitido mais de uma forma de pagamento na integração com Stone.'
      );
    }

    if (
      paymentForm.isMateraIntegration &&
      !!selectedMateraPaymentId &&
      selectedMateraPaymentId !== paymentFormId &&
      installments.length > 1
    ) {
      clearPaymentForm();

      return toastr.warning(
        'Só é possível utilizar apenas 1 pagamento PIX integrado com conta digital por venda.'
      );
    }

    const selectedFormOfPayment = paymentFormsOptions.find(
      (paymentForm) => paymentForm.value === paymentFormId
    );
    setSelectValue(selectedFormOfPayment);
    dispatch([
      change('pdvOnline', `${installment}.formOfPaymentId`, paymentFormId),
      change(
        'pdvOnline',
        `installments[${index}].amountReceived`,
        paymentForm.TypeOfPayment === 'Crédito Loja'
          ? !!form.automaticDownload
          : !!form.automaticDownload
      ),
      change(
        'pdvOnline',
        `installments[${index}].isStoneIntegrated`,
        paymentForm.isStoneIntegration
      ),
      change(
        'pdvOnline',
        `installments[${index}].isMateraIntegrated`,
        paymentForm.isMateraIntegration
      ),
    ]);

    if (paymentForm.TypeOfPayment === 'Crédito Loja') {
      setIsAvailableCreditPayment(true);
      dispatch(
        change(
          'pdvOnline',
          `${installment}.dueDate`,
          format(new Date(), 'yyyy-MM-dd')
        )
      );
      dispatch(
        change('pdvOnline', `${installment}.isCustomerCreditPayment`, true)
      );
      setIsAvailableCreditPayment(false);
    } else {
      setIsAvailableCreditPayment(false);
    }
  }

  function clearPaymentForm() {
    setSelectValue(null);

    dispatch([
      change('pdvOnline', `${installment}.formOfPaymentId`, null),
      change('pdvOnline', `installments[${index}].amountReceived`, false),
      change('pdvOnline', `installments[${index}].isStoneIntegrated`, false),
    ]);
  }

  return (
    <>
      <Col xs={4} lg={4} md={4} xl={4} style={{ width: '185px' }}>
        <label htmlFor="date">Data</label>
        <Field
          component={RenderField}
          name={`${installment}.dueDate`}
          type="date"
          id="date"
          disabled={
            installments[index].parcel === 'À vista' || isAvailableCreditPayment
          }
        />
      </Col>

      <Col xs={4} lg={4} md={4} xl={4}>
        <label htmlFor="payment-form">Forma de Pagamento</label>
        <Select
          options={paymentFormsOptions}
          placeholder="Selecione"
          noOptionsMessage={() => 'Nenhuma forma de pagamento encontrada'}
          components={{ Option: IconOption }}
          onChange={(formOfPayment) => handleFormOfPayment(formOfPayment.value)}
          value={selectValue}
          styles={{
            control: (base) => ({
              ...base,
              height: '35px',
              minHeight: '35px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              marginTop: '-6px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              marginTop: '-6px',
            }),
          }}
        />
      </Col>

      <Col xs={3} lg={3} md={3} xl={3}>
        <label htmlFor="installment-value">Valor</label>
        <Field
          component={RenderField}
          name={`${installment}.value`}
          id="installment-value"
          {...currencyMask}
          onChange={(e) => handleAdjustInstallmentValues(e.target.value)}
        />
      </Col>
      <Col xs={1} lg={1} md={1} xl={1}>
        <label htmlFor="installment-value">Pago?</label>
        <Field
          component={RenderField}
          name={`${installment}.amountReceived`}
          id="installment-value"
          type="checkbox"
          disabled={!installments[index].formOfPaymentId}
          style={{ margin: '0px' }}
        />
      </Col>
    </>
  );
};

const Installments = ({ fields, paymentForms, installments }) =>
  fields.map((installment, index) => {
    return (
      <Installment
        key={index}
        installment={installment}
        index={index}
        paymentForms={paymentForms}
        installments={installments}
      />
    );
  });

export default Installments;
