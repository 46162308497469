import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import familiesRepository from '../../../repositories/Families';
import warrantiesRepository from '../../../repositories/Warranties';
import revisionsRepository from 'repositories/Revisions';
import { useAuth } from '../../../contexts/auth';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { format } from 'date-fns';
import SearchInput from 'components/SearchInput';

const HomeFamilies = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [families, setFamilies] = useState([]);

  const { companyId } = useAuth();
  const { queryFilter, typeFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;

  const [familiesToExport, setfamiliesToExport] = useState([]);
  const [warranties, setWarranties] = useState([]);
  const [revisions, setRevisions] = useState([]);

  useEffect(() => {
    if (!!companyId) {
      loadFamilies();
      loadWarranties();
      loadRevisions();
    }
  }, []);

  useEffect(() => {
    const data = LoadExportData(families, warranties, revisions);

    setfamiliesToExport(data);
  }, [families, query, type]);

  function LoadExportData(families, warranties, revisions) {
    return families.map((family) => {
      let revision = revisions.filter(
        (revision) => revision.id === family.Review_id
      );
      let warranty = warranties.filter(
        (warranty) => warranty.id === family.Warranty_id
      );
      return {
        ...family,
        type: family.Type,
        description: family.Description,
        isActive: family.IsActive == 1 ? 'Ativo' : 'Inativo',
        revisionDescription: revision[0]?.Description,
        warrantyDescription: warranty[0]?.Description,
      };
    });
  }

  const loadFamilies = async () => {
    setLoading(true);

    try {
      const families = await familiesRepository.getAllByCompany(companyId);
      const familiesNewArray = families;

      const defaultFamilies = await familiesRepository.getDefaults();
      const defaultFamiliesNewArray = defaultFamilies;

      for (let j = 0; j < families.length; j++) {
        for (let i = 0; i < familiesNewArray.length; i++) {
          if (families[j]?.Company_id == null) {
            familiesNewArray.splice(j, 1);
          }
        }
      }

      for (let x = 0; x < defaultFamilies.length; x++) {
        for (let c = 0; c < familiesNewArray.length; c++) {
          if (
            defaultFamiliesNewArray[x]?.Description ===
              familiesNewArray[c]?.Description &&
            defaultFamiliesNewArray[x]?.Type === familiesNewArray[c]?.Type &&
            defaultFamiliesNewArray[x]?.Code === familiesNewArray[c]?.Code
          ) {
            defaultFamiliesNewArray.splice(x, 1);
          }
        }
      }

      const serializedFamilies = [];
      serializedFamilies.push(...defaultFamiliesNewArray, ...familiesNewArray);
      setFamilies(serializedFamilies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as familias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };
  const loadRevisions = async () => {
    try {
      const revisions = await revisionsRepository.getAllByCompany(companyId);
      setRevisions(revisions);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as revisões. Por favor, tente novamente.'
      );
    }
  };

  const loadWarranties = async () => {
    try {
      const warranties = await warrantiesRepository.getAllByCompany(companyId);
      setWarranties(warranties);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as garantias. Por favor, tente novamente'
      );
    }
  };

  const handleFilters = (family) => {
    const querySearch = [family['Description']];
    return filterByQuery(querySearch) && filterByType('Type', family);
  };

  const filteredFamilies = families.filter(handleFilters);
  const filteredExportFamilies = familiesToExport.filter(handleFilters);
  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.FAMILY)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Família
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo:</strong>
              </span>
              <select
                className="form-control foco-input"
                onChange={(event) => setType(event.target.value)}
                value={type}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Produto">Produto</option>
                <option value="Servico">Serviço</option>
              </select>
            </div>
            <div>
              <DownloadXlsButton
                archiveName={`Famílias - ${format(
                  new Date(),
                  'dd-MM-yyyy-HH-mm'
                )}`}
                data={filteredExportFamilies}
                className="btn btn-export"
                disabled={loading || !families.length}
                columns={[
                  {
                    name: 'Tipo',
                    acessor: 'type',
                  },
                  {
                    name: 'Descrição',
                    acessor: 'description',
                  },
                  {
                    name: 'Status',
                    acessor: 'isActive',
                  },
                  {
                    name: 'Garantia',
                    acessor: 'warrantyDescription',
                  },
                  {
                    name: 'Revisão',
                    acessor: 'revisionDescription',
                  },
                ]}
                style={{ fontSize: '12px' }}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                &nbsp;Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={filteredFamilies}
              columns={[
                {
                  Header: 'Código',
                  id: 'Code',
                  accessor: (props) => Number(props.Code),
                  headerClassName: 'text-left',
                  width: 100,
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value == 'Produto' ? 'blue' : 'purple',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Status',
                  accessor: 'IsActive',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value == 1 ? 'green' : 'red',
                      }}
                    >
                      {props.value == 1 ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 100,
                  Cell: (props) =>
                    !companyId ? (
                      <i
                        className="fa fa-pencil-square-o"
                        style={{ cursor: 'not-allowed' }}
                        title={
                          props.original.Company_id
                            ? 'Necessário cadastro de Empresa'
                            : 'Famílias padrões do sistema não podem ser alteradas'
                        }
                        aria-hidden="false"
                      />
                    ) : (
                      <Link to={{ pathname: `family#${props.original.id}` }}>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ),
                },
              ]}
              defaultPageSize={20}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Code',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeFamilies);
