import constants from '../../../utils/constants'
import { format } from 'date-fns'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

export const xlsColumns = [
  {
    name: 'Título',
    acessor: 'code',
  },
  {
    name: 'Venda',
    acessor: 'saleCode',
  },
  {
    name: 'Vencimento',
    acessor: 'dueDate',
  },
  {
    name: 'Cliente',
    acessor: 'clientName',
  },
  {
    name: 'Data de emissão',
    acessor: 'issuedAt',
  },
  {
    name: 'Valor Bruto',
    acessor: 'subTotal',
  },
  {
    name: 'Acréscimo',
    acessor: 'addedValue',
  },
  {
    name: 'Desconto',
    acessor: 'discountValue',
  },
  {
    name: 'Valor Líquido',
    acessor: 'liquidValue',
  },
  {
    name: 'Taxa do Cartão',
    acessor: 'fee',
  },
  {
    name: 'Valor da Taxa',
    acessor: 'feeValue',
  },
  {
    name: 'Valor pago',
    acessor: 'paidValue',
  },
  {
    name: 'Valor em aberto',
    acessor: 'openValue',
  },
  {
    name: 'Status',
    acessor: 'billStatus',
  },
  {
    name: 'Data da baixa',
    acessor: 'lowDate',
  },
]

function getSaleCode(bill) {
  if (bill.BillsToReceiveSales.length > 0) {
    return bill.BillsToReceiveSales.length > 1
      ? 'Várias'
      : bill.BillsToReceiveSales[0].Sales?.Code || '---'
  } else {
    return bill.Sales?.Code || '---'
  }
}

export const getBillsToReceiveInfoToExport = (billsToReceive) => {
  return billsToReceive.map((bill) => ({
    code: bill.code,
    saleCode: getSaleCode(bill),
    dueDate: format(getDateOnlyFromDate(new Date(bill.dueDate)), 'dd/MM/yyyy'),
    clientName: bill.Customer?.Company_Name || '',
    issuedAt: format(new Date(bill.issuedAt), 'dd/MM/yyyy'),
    subTotal: Number(bill.subTotal),
    addedValue: Number(bill.addedValue),
    discountValue: Number(bill.discountValue),
    liquidValue: Number(bill.liquidValue),
    fee: bill.fee,
    feeValue: Number(bill.feeValue),
    paidValue: Number(bill.paidValue),
    openValue: Number(bill.openValue),
    billStatus: constants.BILLS_STATUS_DESCRIPTION[bill.billStatusId],
    lowDate: !bill.lowDate ? '' : format(new Date(bill.lowDate), 'dd/MM/yyyy'),
  }))
}
