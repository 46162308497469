import api from 'v2/services/api';

const getProfitPanelInformation = async (companyId) => {
  try {
    const res = await api.get(`/profit-panel/${companyId}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const ProfitPanelRepository = {
  getProfitPanelInformation,
};
