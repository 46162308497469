import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { calculate, handleChange } from '../NewSale/FormSale/redux/actions';
import constants from 'utils/constants';
import salesRepository from '../../../../repositories/Sales';
import './index.css';
import segmentsRepository from 'repositories/Segments';

const DuplicateSalesModal = ({ sale, onCancel, onSubmit }) => {
  const [copyCustomerAndVehicle, setCopyCustomerAndVehicle] = useState(false);
  const [copyProductsAndServices, setCopyProductsAndServices] = useState(false);
  const [copyPaymentConditions, setCopyPaymentConditions] = useState(false);
  const [segmentIsOthers, setSegmentIsOthers] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId } = useAuth();

  const handleCopyCustomerAndVehicleChange = () => {
    setCopyCustomerAndVehicle(!copyCustomerAndVehicle);
  };

  const handleCopyProductsAndServicesChange = () => {
    const newValue = !copyProductsAndServices;
    setCopyProductsAndServices(newValue);
    if (!newValue) {
      setCopyPaymentConditions(false);
    }
  };

  const handleCopyPaymentConditionsChange = () => {
    if (copyProductsAndServices) {
      setCopyPaymentConditions(!copyPaymentConditions);
    } else {
      toastr.warning(
        'Não foi possível selecionar',
        'Para selecionar o pagamento, é necessário selecionar os produtos.'
      );
      setCopyPaymentConditions(false);
    }
  };

  const getCheckboxLabel = () => {
    loadSegment();
    if (segmentIsOthers === null) {
      return;
    } else if (segmentIsOthers) {
      return 'Cliente e Objeto de manutenção';
    }
    return 'Cliente e Veículo/Obj. de Manutenção';
  };

  const loadSegment = async () => {
    try {
      const response = await segmentsRepository.getByCompany(companyId);
      const isOthers =
        response.data.some((item) => item?.Segment?.Description === 'Outros') ||
        false;
      setSegmentIsOthers(isOthers);
    } catch (err) {
      console.error(err);
    }
  };

  const loadPartialSale = async (
    saleId,
    copyCustomerAndVehicle,
    copyProductsAndServices,
    copyPaymentConditions
  ) => {
    dispatch({ type: 'LOAD_SALE_STARTED' });
    try {
      const sale = await salesRepository.getById(saleId);
      dispatch(
        loadPartialSaleSuccess(
          sale,
          copyCustomerAndVehicle,
          copyProductsAndServices,
          copyPaymentConditions
        )
      );
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar a venda. Por favor, tente novamente'
      );
      dispatch({ type: 'LOAD_SALE_FAILURE' });
    }
  };
  const loadPartialSaleSuccess =
    (
      sale,
      copyCustomerAndVehicle,
      copyProductsAndServices,
      copyPaymentConditions
    ) =>
    async (dispatch) => {
      const {
        Kilometers,
        Comments,
        Defects,
        Malfunctions,
        Customer,
        SalesStatus_id,
        SalesType_id,
        salesSubtypeId,
        SalesSubtype,
        Discount_Value_Percentage,
        Discount_Value,
        Discount_Type,
        SubTotal,
        Final_Value,
        Payments,
        Vehicle_id,
        previousKilometers,
        SalesItems,
        SalesItemsCanceled,
        employeeId,

        returnedValue,
        forecastReturn,
        prismaBoxId,

        tradeRepresentativeId,
        attendanceTypeId,
        freightValue,
        SalesEmployees,
        redeemedBonus,
        usedCRMBonus,
        bonusId,
        orderId,
        customerRequest,
      } = sale;

      const serializedSale = {
        prismaBoxId,

        redeemedBonus,
        usedCRMBonus,
        bonusId,
        orderId,
        tradeRepresentativeId,
        attendanceTypeId,
        saleTypeId: SalesType_id,
        salesSubtypeId,
        generateTitleAfterCloseSale: SalesSubtype?.generateTitleAfterCloseSale,
        forecastReturn,
        employeeId,
        returnedValue,
        freightValue,
      };

      if (copyCustomerAndVehicle) {
        serializedSale.customerRequest = customerRequest;
        serializedSale.previousKilometers = previousKilometers || 0;
        serializedSale.selectedClient = Customer;
        serializedSale.observations = Comments;
        serializedSale.defects = Defects;
        serializedSale.malfunctions = Malfunctions;

        if (Customer.Vehicle.length) {
          serializedSale.clientVehicles = Customer.Vehicle.map(
            (vehicle, i) => ({
              ...vehicle,
              index: i,
            })
          );
        }

        if (Vehicle_id !== null && serializedSale.clientVehicles) {
          serializedSale.selectedVehicle = serializedSale.clientVehicles.find(
            (vehicle) => String(vehicle.id) === String(Vehicle_id)
          );

          if (SalesStatus_id === 5) {
            if (serializedSale?.selectedVehicle) {
              serializedSale.selectedVehicle.Kilometers = previousKilometers;
            }
          }
        }
      }
      if (SalesEmployees?.length > 0) {
        const salesEmployeesIds = SalesEmployees.map(
          (saleEmployee) => saleEmployee.employeeId
        );

        serializedSale.salesEmployeesIds = salesEmployeesIds;
      }

      if (
        sale?.Payments?.CondPayment?.isFeeActive &&
        sale?.Payments?.CondPayment?.feePercent
      ) {
        dispatch(
          handleChange(
            sale?.Payments?.CondPayment?.feePercent,
            'installmentFees'
          )
        );
      }

      if (copyProductsAndServices) {
        const getItemTypeData = (item) => {
          const isProduto = item.Type === 'Produto';
          return {
            id: isProduto ? item.Product_id : item.Service_id,
            Code: isProduto ? item.Products?.Code : item.Services?.Code,
            Manufacturer_Ref: isProduto
              ? item.Products?.Manufacturer_Ref
              : '---',
            Brand: isProduto
              ? item.Products?.Brands?.Description || 'Não informado'
              : '---',
            WarrantyDescription: isProduto
              ? item.Products?.Warranty?.Description
              : item.Services?.Warranty?.Description,
          };
        };

        const salesItems = SalesItems.map((item) => ({
          SalesItems_id: item.id,
          Description: item.Description,
          edit: false,
          Commission_Rate: item.commission,
          updateDescriptionOnSale: item?.Products?.updateDescriptionOnSale,
          ...item,
          ...getItemTypeData(item),
        })).sort((a, b) => a?.lineNumber - b?.lineNumber);

        const salesItemsCanceled = SalesItemsCanceled.map((item) => ({
          SalesItems_id: item.id,
          Description: item.Description,
          edit: false,
          Commission_Rate: item.commission,
          updateDescriptionOnSale: item.updateDescriptionOnSale,
          isCancelled: true,
          ...item,
          ...getItemTypeData(item),
        })).sort((a, b) => a?.lineNumber - b?.lineNumber);

        serializedSale.items = [...salesItems, ...salesItemsCanceled];
        serializedSale.generalDiscountPercentage = Discount_Value_Percentage;
        serializedSale.generalDiscountCash = Discount_Value;
        serializedSale.subTotal = SubTotal;
        serializedSale.total = Final_Value;
        serializedSale.discountType = Discount_Type;
        await dispatch(calculate(serializedSale.items));
      }
      if (copyPaymentConditions) {
        if (Payments !== null) {
          serializedSale.selectedCondOfPayment = Payments.CondPayment;
          let totalReceived = 0;
          serializedSale.installments = Payments.Parcels.map((item) => {
            totalReceived += item.Amount;
            return {
              id: item.id,
              date:
                item?.Due_Date !== '0000-00-00' &&
                new Date(item?.Due_Date).toISOString().split('T')[0],
              value: item.Amount,
              formOfPayment: item.FormOfPayment,
              amountReceived: item.amountReceived,
              authorizationCode: item.PaymentsAuthorizations?.code,
              cashierBank: { id: item.cashierBankId },
            };
          });
          serializedSale.totalReceived = totalReceived;

          if (totalReceived > Final_Value) {
            serializedSale.change = totalReceived - Final_Value;
            serializedSale.opened = 0;
          } else if (totalReceived < Final_Value) {
            serializedSale.change = 0;
            serializedSale.opened = Final_Value - totalReceived;
          } else {
            serializedSale.change = 0;
            serializedSale.opened = 0;
          }
        }
      }

      // let feePercent = serializedSale.selectedCondOfPayment.feePercent

      dispatch({ type: 'LOAD_SALE_SUCCESS', payload: serializedSale });
    };

  const handleSubmit = async () => {
    await history.push('/client/' + constants.ROUTES.SALE);
    await loadPartialSale(
      sale.id,
      copyCustomerAndVehicle,
      copyProductsAndServices,
      copyPaymentConditions
    );

    toastr.success(
      'OS/Venda',
      'Todas as opções selecionadas foram copiadas para a nova OS/Venda.'
    );
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <Modal show animation onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Copiar Venda/OS</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Selecione as opções que deseja copiar:</p>
        </div>
        <div className="product-info">
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="copyCustomerAndVehicle"
              checked={copyCustomerAndVehicle}
              onChange={handleCopyCustomerAndVehicleChange}
              disabled={sale.SalesType.Description === 'PDV'}
            />
            <label htmlFor="copyCustomerAndVehicle">{getCheckboxLabel()}</label>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="copyProductsAndServices"
              checked={copyProductsAndServices}
              onChange={handleCopyProductsAndServicesChange}
            />
            <label htmlFor="copyProductsAndServices">Produtos e Serviços</label>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="copyPaymentConditions"
              checked={copyPaymentConditions}
              onChange={handleCopyPaymentConditionsChange}
            />
            <label htmlFor="copyPaymentConditions">
              Condição e Forma de Pagamento
            </label>
          </div>
        </div>
        <div className="observation-alert">
          <span style={{ color: 'red' }}>
            Após a cópia, será necessário salvar para criar a venda/os
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} bsStyle="danger" fill>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          bsStyle="info"
          fill
          disabled={
            !copyCustomerAndVehicle &&
            !copyProductsAndServices &&
            !copyPaymentConditions
          }
        >
          Copiar Venda/OS
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateSalesModal;
