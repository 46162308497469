import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { ProductDocument } from './ProductDocument';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import productHistoryRepository from '../../../repositories/ProductHistory';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const ProductHistoryReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [productHistory, setProductHistory] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const productIdHash = searchParams.get('productId');
      let productId;
      if (productIdHash) {
        productId = await decrypt(productIdHash, '@OS-dig:productId');
      }

      const companyIdHash = searchParams.get('companyId');
      let companyId;
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId');
      }

      if (!productId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      setInitialDate(
        format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')
      );
      setFinalDate(
        format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')
      );

      return loadReport(productId, { initialDate, finalDate, companyId });
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (productId, params) => {
    try {
      const { data } = await productHistoryRepository.getProductHistoryReport(
        productId,
        params
      );

      setProductHistory(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <ProductDocument
            result={productHistory}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default ProductHistoryReport;
