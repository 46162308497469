const { default: styled } = require('styled-components');

export const Tab = styled.li`
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;

  font-family: Roboto;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 'bold' : '300')};

  color: ${(props) => (props.active ? '#ffffff' : '#6c757d')};
  background-color: ${(props) =>
    props.active ? props.theme.colors.branding1000 : 'transparent'};

  border-radius: 4px;
  margin-right: 50px;

  &:hover {
    background-color: ${(props) =>
      !props.active && props.theme.colors['branding100']};
  }
`;

export const TabList = styled.ul`
  width: 100%;
  height: 500px;
  background-color: 'transparent';
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const TabPanel = styled.div`
  width: 80%;
  background-color: 'transparent';
`;
