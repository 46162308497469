import React, { useState, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'
import { initialize } from 'redux-form'
import { useDispatch } from 'react-redux'

import FormNewFormOfPayment from './NewFormOfPayment'
import paymentFormsRepository from '../../../../repositories/PaymentForms'
import constants from '../../../../utils/constants'

const BundleFormOfPayment = ({
  history,
  formOfPaymentId,
  closeModal,
  modal,
  nativeModal,
  reloadInfo,
}) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    if (!!formOfPaymentId) {
      loadFormOfPayment()
    }
  }, [])

  async function loadFormOfPayment() {
    setLoading(true)

    try {
      const formOfPayment = await paymentFormsRepository.getById(
        formOfPaymentId
      )
      const serializedFormOfPayment = {
        ...formOfPayment,
        IsActive: !!formOfPayment.IsActive,
      }

      dispatch(initialize('formOfPaymentRegister', serializedFormOfPayment))
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao carregar a Forma de Pagamento. Por favor, tente novamente'
      )
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(values) {
    const {
      Desciption,
      TypeOfPayment,
      operatorFee,
      isBankSlipIntegration,
      bankBilletAccountId,
    } = values

    if (!Desciption || !TypeOfPayment) {
      return toastr.warning('Preencha todos os campos para salvar')
    }

    if (operatorFee > 100) {
      return toastr.warning('Porcentagem inválida, verifique por favor!')
    }

    if (isBankSlipIntegration && !bankBilletAccountId) {
      return toastr.warning(
        'Emissão de boleto ativa sem carteira definida. Selecione uma carteira e tenta novamente. Caso a carteira de emissão não esteja sendo exibida, entre em contato com o atendimento online e solicite o cadastro da carteira, para que possa emitir os boletos.'
      )
    }

    const formsOfPayment = await paymentFormsRepository.getAllByCompany(
      companyId
    )
    const hasFormOfPayment = formsOfPayment.some(
      (formOfPayment) =>
        formOfPayment.Desciption === Desciption &&
        String(formOfPayment.id) !== String(formOfPaymentId)
    )

    if (hasFormOfPayment) {
      return toastr.warning(
        'Já existe uma forma de pagamento com essa descrição. Por favor, verifique.'
      )
    }

    if (!formOfPaymentId) {
      create(values)
    } else {
      update(formOfPaymentId, values)
    }
  }

  async function create(values) {
    setLoading(true)
    try {
      await paymentFormsRepository.create({
        Company_id: companyId,
        TypeOfPayment: values.TypeOfPayment,
        Desciption: values.Desciption,
        operatorFee: values.operatorFee,
        providerId: values.providerId,
        cardOperator: values.cardOperator,
        automaticDownload: values.automaticDownload,
        IsActive: values.IsActive,
        isStoneIntegration: values.isStoneIntegration,
        cardFlagTBand: values.cardFlagTBand,
        isMateraIntegration: values.isMateraIntegration,
        isBankSlipIntegration: values.isBankSlipIntegration,
        bankBilletAccountId: values.bankBilletAccountId,
        feeType: values.feeType,
        fineType: values.fineType,
        feeValue:
          typeof values.feeValue === 'string'
            ? values.feeValue.split(' ')[1]
            : values.feeValue,
        fineValue:
          typeof values.fineValue === 'string'
            ? values.fineValue.split(' ')[1]
            : values.fineValue,
        daysToStartFine: values.daysToStartFine,
        daysToStartFee: values.daysToStartFee,
      })

      toastr.success('Sucesso!', 'Forma de Pagamento cadastrada com sucesso.')
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao cadastrar a Forma de Pagamento. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)

      if (modal) closeModal()
      else history.push(constants.ROUTES.PAYMENT_FORMS)

      if (nativeModal) {
        await reloadInfo()
      }
    }
  }

  async function update(id, values) {
    setLoading(true)
    try {
      await paymentFormsRepository.update(id, {
        Company_id: values.Company_id,
        TypeOfPayment: values.TypeOfPayment,
        Desciption: values.Desciption,
        operatorFee: values.operatorFee,
        providerId: values.providerId,
        cardOperator: values.cardOperator,
        automaticDownload: values.automaticDownload,
        IsActive: values.IsActive,
        isStoneIntegration: values.isStoneIntegration,
        cardFlagTBand: values.cardFlagTBand,
        isMateraIntegration: values.isMateraIntegration,
        isBankSlipIntegration: values.isBankSlipIntegration,
        bankBilletAccountId: values.bankBilletAccountId,
        feeType: values.feeType,
        fineType: values.fineType,
        feeValue:
          typeof values.feeValue === 'string'
            ? isNaN(Number(values.feeValue))
              ? values.feeValue.split(' ')[1]
              : Number(values.feeValue)
            : values.feeValue,
        fineValue:
          typeof values.fineValue === 'string'
            ? values.fineValue.split(' ')[1]
            : values.fineValue,
        daysToStartFine: values.daysToStartFine,
        daysToStartFee: values.daysToStartFee,
      })

      toastr.success('Sucesso!', 'Forma de Pagamento atualizado com sucesso.')
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao atualiar a Forma de Pagamento. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)

      if (modal) closeModal()
      else history.push(constants.ROUTES.PAYMENT_FORMS)

      if (nativeModal) {
        await reloadInfo()
      }
    }
  }

  return (
    <>
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <div style={{ marginTop: 30 }}>
        <FormNewFormOfPayment
          onSubmit={handleSubmit}
          initialValues={{
            IsActive: true,
            automaticDownload: '0',
            fineType: 'unexistent',
            daysToStartFine: 1,
            feeType: 'unexistent',
            daysToStartFee: 1,
          }}
          loading={loading}
          formOfPaymentId={formOfPaymentId}
          closeModal={closeModal}
          openModal={modal}
        />
      </div>
    </>
  )
}

export default withRouter(BundleFormOfPayment)
