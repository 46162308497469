import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import ProductsFamilyOrBrandMain from './components/ProductsFamilyOrBrandMain'

import './index.css'

export default function ProductsFamilyOrBrand({ location }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Produtos',
              'Listagem de Produto por Famílias/Marcas',
            ]}
            path={['home', null, null, null]}
          />
          <ProductsFamilyOrBrandMain />
        </Row>
      </Grid>
    </div>
  )
}
