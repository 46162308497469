import React from 'react'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { Grid, Row } from 'react-bootstrap'
import PeriodPicker from './PeriodPicker'

const ReturnHistoryByPeriod = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Relatórios', 'Vendas', 'Histórico de Devolução']}
            path={['Home', null, null, null]} />
          <PeriodPicker />
        </Row>
      </Grid>
    </div>
  )
}

export default ReturnHistoryByPeriod
