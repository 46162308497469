import config from '../config'
import api from '../services/api'

const getAll = async ({ companyId, limit, page, type, query }) => {
  try {
    const response = await api.get(`ncm-nbs`, {
      params: { companyId, limit, page, type, query },
    })

    return response.data
  } catch (err) {
    throw err
  }
}

export const update = async (CEST, companyId, ncmNbsId) => {
  await api.put(
    'ncm-nbs',
    {
      CEST,
    },
    {
      params: {
        companyId,
        ncmNbsId,
      },
    }
  )
  return
}

export default {
  getAll,
  update,
}
