import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  cpfMask,
  cnpjMask,
  cepMask,
  phoneMask,
  currency,
  kmMask,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format, isPast, addHours } from 'date-fns'
import constants from '../../../utils/constants'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const HeaderTotalsBySaleType = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Tipo de Venda</Text>
    </View>
    <View style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const ItemRowTotalBySaleType = ({ label, total }) => (
  <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{label}</Text>
    </View>
    <View style={{ width: '40%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(parseFloat(total))}</Text>
    </View>
  </View>
)

const TitlesHeader = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Título</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Vencimento</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Venda</Text>
    </View>
    <View style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
  </View>
)

const TitlesRow = ({ code, dueDate, saleCode, amount }) => (
  <View
    style={{ flexDirection: 'row', height: 12, width: '100%' }}
    wrap={false}
  >
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{code}</Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>
        {format(addHours(new Date(dueDate), 3), 'dd/MM/yyyy')}
      </Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{saleCode}</Text>
    </View>
    <View style={{ width: '50%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(amount)}</Text>
    </View>
  </View>
)

const SalesHeader = ({ Code, createdAt, updatedAt, kilometers }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 15,
    }}
  >
    <ItemLabel label="Venda/OS" value={Code} />
    <ItemLabel
      label="Data de Criação"
      value={format(new Date(createdAt), 'dd/MM/yyyy')}
    />
    <ItemLabel
      label="Data de Fechamento"
      value={format(new Date(updatedAt), 'dd/MM/yyyy')}
    />
    <ItemLabel
      label="KM Atual"
      value={kilometers ? kmMask(String(kilometers)) : ''}
    />
  </View>
)

const ItemsHeaderSales = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
    wrap={false}
  >
    <View style={{ width: '36%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descrição</Text>
    </View>
    <View
      style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text>Tipo</Text>
    </View>
    <View
      style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text>Qtd</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>R$ Unit</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Total</Text>
    </View>
    <View style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Garantia</Text>
    </View>
    <View style={{ width: '19%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Revisão</Text>
    </View>
  </View>
)

const ItemRowSales = ({ item }) => {
  const type = item.Type?.substring(0, 1)
  const serviceOrProduct =
    type === 'P' ? item.Products || {} : item.Services || {}
  const warranty = serviceOrProduct?.Warranty
  const review = serviceOrProduct?.Review

  return (
    <View
      style={{ flexDirection: 'row', height: 12, width: '100%' }}
      wrap={false}
    >
      <View style={{ width: '36%', textAlign: 'left', justifyContent: 'left' }}>
        <Text style={styles.span}>
          {serviceOrProduct.Code} - {serviceOrProduct.Description}
        </Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{type}</Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{item.Quantity}</Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {currency(item.Unit_Value - item.Unit_Discount_Value)}
        </Text>
      </View>
      <View
        style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>{currency(item.Amount)}</Text>
      </View>
      <View
        style={{ width: '15%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {!!warranty
            ? `${warranty.Kilometers} km ou ${warranty.Months} meses`
            : ''}
        </Text>
      </View>
      <View
        style={{ width: '19%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.span}>
          {!!review ? `${review.Km} km ou ${review.Months} meses` : ''}
        </Text>
      </View>
    </View>
  )
}

export const VehicleHistoryPDF = ({
  sales = [],
  vehicle = {},
  totalsPerSaleType = [],
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`impresso-historico-veiculo-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`Placa: ${vehicle.License_Plate || vehicle.Maintenance_Object}`}
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>Histórico de Veículo</Text>
          <Text>
            {initialDate} - {finalDate}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <ItemLabel
            label="Placa/Obj. Manutenção"
            value={vehicle.License_Plate || vehicle.Maintenance_Object}
          />
          <ItemLabel label="Modelo" value={vehicle.Model} />

          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '60%',
            }}
          >
            <ItemLabel
              label="Ano Fab/Mod"
              value={`${vehicle.Year_Manufacture}/${vehicle.Year_Model}`}
            />
            <ItemLabel label="Cor" value={`${vehicle.Color}`} />
            <ItemLabel label="KM Atual" value={vehicle.Kilometers} />
          </View>

          <ItemLabel label="Chassi/Série" value={vehicle.NChassi} />
        </View>
        <View style={styles.line} />
        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 15,
            marginTop: 1,
          }}
        >
          Totais de Valores por Tipo de Venda
        </Text>
        <View style={styles.line} />
        <HeaderTotalsBySaleType />
        <View style={styles.line} />
        {Object.keys(totalsPerSaleType).map((value, index) => (
          <ItemRowTotalBySaleType
            key={index}
            label={value}
            total={totalsPerSaleType[value]}
          />
        ))}
        <View style={[styles.line, { marginTop: 5 }]} />

        <Text
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 15,
            marginTop: 15,
          }}
        >
          Itens Por Venda/Ordem de Serviço
        </Text>
        {sales.map((sale) => (
          <View key={sale.id}>
            <SalesHeader
              Code={sale.Code}
              createdAt={sale.createdAt}
              updatedAt={sale.updatedAt}
              kilometers={sale.Kilometers || ''}
            />
            <View style={styles.line} />
            <ItemsHeaderSales />
            <View style={styles.line} />
            {sale.SalesItems.map((item, index) => (
              <ItemRowSales item={item} key={index} />
            ))}
            <View style={styles.line} />
          </View>
        ))}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
