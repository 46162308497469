import React from 'react';
import {
  faBoxOpen,
  faAddressBook,
  faTools,
  faMoneyBillAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

import {
  phoneMask,
  cnpjMask,
  cpfMask,
  currency,
  percentage,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import { faCheck, faPrint } from '@fortawesome/free-solid-svg-icons';

import Button from '../../../client/components/CustomButton/CustomButton';

import Card from './Card';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';

const styles = {
  line: {
    border: '1px solid black',
  },
  mainPanel: {
    marginBottom: '20px',
    marginTop: '20px',
    width: '100%',
    boxShadow: '5px 10px 18px #d6d6d6',
    background: 'white',
  },
  gridCompany: {
    display: 'grid',
    gridTemplateRows: '15% 15% 15% 15%',
    marginTop: '3%',
  },
  spaceMainPanel: {
    padding: '10px',
    marginLeft: '10px',
  },
  fontConteudo: {
    fontSize: '14px',
    color: '#444',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '350px 350px',
  },
  grid3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '10px 20px',
  },
  borderRigth: {
    marginRight: '10px',
    borderRight: '1px solid #d6d6d6',
  },
  borderBottom: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom2: {
    borderBottom: '1px solid #d6d6d6',
    width: '90%',
  },
  borderBottom1: {
    borderBottom: '1px solid #d6d6d6',
    width: '42%',
  },
  value: {
    marginTop: '90px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '500px',
    fontSize: '16px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  imgCompany: {
    width: '140px',
    height: '115px',
    marginLeft: '-10px',
    marginRight: '-15px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
};

const RenderSaleMobile = ({
  sale,
  handlePrint,
  showApproveBudgetButton,
  handleOpenApproveBudgetModal,
}) => {
  const company = sale.Company;
  const customer = sale.Customer;
  const companyPhones = company.Phones[0];
  const customerAddress = customer?.Address[0];
  const customerPhone = customer?.Phones[0];
  const saleItems = sale.SalesItems;
  const vehicle = sale.Vehicle;

  return (
    <div
      id="printSale"
      style={{
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'regular',
        padding: '0 10px',
      }}
    >
      <div style={styles.mainPanel}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            padding: '20px',
          }}
        >
          <div>
            <p style={{ fontSize: '17px' }}>
              <strong>{company.Company_Name}</strong>
            </p>
            <span>{cpfOrCnpjMask(company.Cpf_Cnpj)}</span>
            <p style={{ fontSize: '14px', marginTop: '-4px' }}>
              {company.Trading_Name}
            </p>
            <span>
              {company.Address[0].Address.split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ') +
                ', ' +
                company.Address[0].Address_Number +
                ' - ' +
                company.Address[0].Neighborhood.split(' ')
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(' ') +
                ' - ' +
                company.Address[0].City.charAt(0).toUpperCase() +
                company.Address[0].City.slice(1).toLowerCase() +
                '/' +
                company.Address[0].State +
                ' - ' +
                cepMask(company.Address[0].Zipcode)}
            </span>
          </div>
          <div>
            {company.Url_Logo ? (
              <div style={styles.spaceMainPanel}>
                <img
                  style={styles.imgCompany}
                  src={company.Url_Logo}
                  alt="OS Digital"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            paddingLeft: '20px',
            paddingBottom: '20px',
          }}
        >
          <span>
            {phoneMask(
              companyPhones.Number_Phone1
                ? companyPhones.Number_Phone1
                : companyPhones.Number_Phone2
            )}{' '}
            - {company.Email}
          </span>
        </div>
      </div>

      <Card icon={faAddressBook} title="Dados do Cliente">
        <div>
          <div className="border-bottom">
            {customer.Type === 'Fisica' ? (
              <span>
                CPF:{' '}
                <strong>
                  {customer.Cpf_Cnpj
                    ? cpfMask(customer.Cpf_Cnpj)
                    : 'Não informado'}
                </strong>
              </span>
            ) : (
              <span>
                CNPJ:{' '}
                <strong>
                  {customer.Cpf_Cnpj
                    ? cnpjMask(customer.Cpf_Cnpj)
                    : 'Não informado'}
                </strong>
              </span>
            )}
          </div>
          <div className="border-bottom">
            {customer.Type === 'Fisica' ? (
              <span>
                Nome Completo:{' '}
                <strong>
                  {customer.Company_Name
                    ? customer.Company_Name
                    : 'Não informado'}
                </strong>
              </span>
            ) : (
              <>
                <span>
                  Razão Social:{' '}
                  <strong>
                    {customer.Company_Name
                      ? customer.Company_Name
                      : 'Não informado'}
                  </strong>
                </span>
              </>
            )}
          </div>
          {customer.Type !== 'Fisica' ? (
            <div className="border-bottom">
              <span>
                Nome Fantasia:{' '}
                <strong>
                  {customer.Trading_Name
                    ? customer.Trading_Name
                    : 'Não informado'}
                </strong>
              </span>
            </div>
          ) : (
            ''
          )}
          {customerPhone ? (
            <div className="border-bottom">
              <span>
                Telefone:
                <strong>
                  &nbsp;
                  {phoneMask(
                    customerPhone.Number_Phone1
                      ? customerPhone.Number_Phone1
                      : customerPhone.Number_Phone2
                  )}
                </strong>
              </span>
            </div>
          ) : (
            ''
          )}
          {customer.Type !== 'Fisica' ? (
            <div className="border-bottom">
              <span>
                Contato:{' '}
                <strong>
                  {customerPhone.Contact
                    ? customerPhone.Contact
                    : 'Não informado'}
                </strong>
              </span>
            </div>
          ) : (
            ''
          )}
          <div className="border-bottom">
            <span>
              E-mail:{' '}
              <strong>
                {customer.Email ? customer.Email : 'Não informado'}
              </strong>
            </span>
          </div>
        </div>

        <div>
          <span>
            <strong>Endereço Principal</strong>
          </span>
          <div className="border-bottom">
            <span>
              CEP:{' '}
              <strong>
                {customerAddress?.Zipcode
                  ? cepMask(customerAddress?.Zipcode)
                  : 'Não informado'}
              </strong>
            </span>
          </div>
          <div className="border-bottom">
            <span>
              Endereço: &nbsp;
              <strong>
                {customerAddress?.Address
                  ? `${customerAddress?.Address} - ${customerAddress?.Neighborhood}`
                  : 'Não informado'}
              </strong>
            </span>
          </div>
          <div className="border-bottom">
            <span>
              Nº:{' '}
              <strong>
                {customerAddress?.Address_Number
                  ? customerAddress?.Address_Number
                  : 'Não informado'}
              </strong>
            </span>
          </div>
          <div className="border-bottom">
            <span>
              Cidade/UF:{' '}
              <strong>
                {customerAddress?.City
                  ? customerAddress?.City
                  : 'Não informado'}{' '}
                -{' '}
                {customerAddress?.State
                  ? customerAddress?.State
                  : 'Não informado'}
              </strong>
            </span>
          </div>
        </div>
      </Card>

      {vehicle && (
        <Card icon={faTools} title="Dados do Veículo">
          {vehicle?.vehicleTypeId !== 4 ? (
            <div>
              <div className="border-bottom">
                <span>
                  {vehicle.License_Plate ? 'Placa:' : 'Obj. Manutenção'}{' '}
                  <strong>
                    {vehicle.License_Plate || vehicle.Maintenance_Object}
                  </strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Veiculo: <strong>{vehicle.Model}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Marca: <strong>{vehicle.Brand}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  KM Atual: <strong>{sale.Kilometers}</strong>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="border-bottom">
                <span>
                  Série/Identificação:
                  <strong>{vehicle.serialIdentification}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Marca: <strong>{vehicle.Brand}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Modelo: <strong>{vehicle.Model}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Ano: <strong>{vehicle.Year_Manufacture}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  Cor: <strong>{vehicle.Color}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  QTD. Marchas Dianteiras:{' '}
                  <strong>{vehicle?.BicycleFrontGears?.quantity}</strong>
                </span>
              </div>
              <div className="border-bottom">
                <span>
                  QTD. Marchas Traseiras:{' '}
                  <strong>{vehicle?.BicycleRearGears?.quantity}</strong>
                </span>
              </div>
            </div>
          )}

          <div>
            <div className="border-bottom" style={{ marginBottom: '15px' }}>
              <span>
                Observação:{' '}
                <strong>
                  {sale.Comments ? sale.Comments : 'Não informado'}
                </strong>
              </span>
            </div>
            <div className="border-bottom" style={{ marginBottom: '15px' }}>
              <span>
                Defeitos:{' '}
                <strong>{sale.Defects ? sale.Defects : 'Não informado'}</strong>{' '}
              </span>
            </div>
            <div className="border-bottom" style={{ marginBottom: '15px' }}>
              <span>
                Avarias:{' '}
                <strong>
                  {sale.Malfunctions ? sale.Malfunctions : 'Não informado'}
                </strong>
              </span>
            </div>
          </div>
        </Card>
      )}

      <Card
        icon={faBoxOpen}
        title={
          sale.SalesType_id === 1
            ? 'Informações do Orçamento'
            : sale.SalesType_id === 2
            ? 'Informações da Venda'
            : 'Dados da Ordem de Serviço'
        }
        className="grid-1"
      >
        <div style={{ fontSize: '14px' }}>
          <div style={styles.borderBottom1}>
            <span>
              {sale.SalesType_id === 1
                ? 'Número do Orçamento:'
                : sale.SalesType_id === 2
                ? 'Número da Venda:'
                : 'Ordem de Serviço:'}
              <strong> {sale.Code}</strong>
            </span>
          </div>
          <div>
            <span>
              Data:
              <strong>
                {' '}
                {format(new Date(getDateOnlyFromDate(sale.Date)), 'dd/MM/yyyy')}
              </strong>
            </span>
          </div>
        </div>

        <div>
          <table style={{ width: '100%', margin: '10px', textAlign: 'center' }}>
            <thead style={styles.borderBottom}>
              <tr>
                <th style={{ textAlign: 'center', maxWidth: '30px' }}>
                  Código
                </th>
                <th style={{ textAlign: 'center' }}>Descrição</th>
              </tr>
              <tr style={styles.borderBottom}>
                <th style={{ textAlign: 'center' }}>Qtd.</th>
                <th style={{ textAlign: 'center' }}>Valor Unit.</th>
                <th style={{ textAlign: 'center' }}>Desc Unit.</th>
                <th style={{ textAlign: 'center' }}>Valor Total </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '13px' }}>
              {saleItems.map((saleItem, index) => (
                <>
                  <tr key={index}>
                    <td style={{ maxWidth: '30px' }}>
                      {!!saleItem.Product_id
                        ? saleItem.Products.Code
                        : saleItem.Services.Code}
                    </td>
                    <td>{saleItem.Description}</td>
                  </tr>
                  <tr key={index * 100} className="border-bottom">
                    <td>{saleItem.Quantity}</td>
                    <td>{currency(saleItem.Unit_Value)}</td>
                    <td>{percentage(saleItem.Discount_Value)}</td>
                    <td>{currency(saleItem.Amount)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      <Card
        icon={faMoneyBillAlt}
        title="Informações de Pagamento"
        className="grid-3"
      >
        <div style={styles.flexColumn}>
          <span>
            <strong>Total:</strong> {currency(sale.Final_Value)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(!sale.Discount_Value ? 0 : sale.Discount_Value)} (
            {percentage(
              !sale.Discount_Value_Percentage
                ? 0
                : sale.Discount_Value_Percentage
            )}
            )
          </span>
        </div>

        <div style={styles.flexColumn}>
          <span>
            <strong>Total de Produtos:</strong>{' '}
            {currency(!sale.Product_Value ? 0 : sale.Product_Value)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(!sale.Discount_Products ? 0 : sale.Discount_Products)} (
            {percentage(
              !sale.Discount_Products_Percentage
                ? 0
                : sale.Discount_Products_Percentage
            )}
            )
          </span>
        </div>

        <div style={styles.flexColumn}>
          <span>
            <strong>Total de Serviços:</strong>{' '}
            {currency(!sale.Service_Value ? 0 : sale.Service_Value)}
          </span>
          <span>
            <strong>Desconto:</strong>{' '}
            {currency(!sale.Discount_Services ? 0 : sale.Discount_Services)} (
            {percentage(
              !sale.Discount_Services_Percentage
                ? 0
                : sale.Discount_Services_Percentage
            )}
            )
          </span>
        </div>
      </Card>

      <div
        className="no-print"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        {showApproveBudgetButton && (
          <Button
            bsStyle="info"
            onClick={() => handleOpenApproveBudgetModal()}
            style={{
              height: '50px',
              fontSize: '20px',
            }}
            fill
          >
            Aprovar Orçamento
          </Button>
        )}

        <Button
          bsStyle="warning"
          onClick={() => handlePrint()}
          style={{
            height: '50px',
            fontSize: '20px',
          }}
          fill
        >
          Imprimir
        </Button>
      </div>
    </div>
  );
};

export default RenderSaleMobile;
