import config from '../config'
import api from '../services/api'
import constants from '../utils/constants'

const getCashierBankTypes = async () => {
  try {
    const response = await api.get(config.endpoint + 'cashierbanktypes')
    return response.data
  } catch (err) {
    throw err
  }
}

const getBanks = async () => {
  try {
    const response = await api.get(config.endpoint + 'banks')
    return response.data
  } catch (err) {
    throw err
  }
}

const store = async (values) => {
  try {
    const response = await api.post(config.endpoint + 'cashierbanks', values)
    return response.data
  } catch (err) {
    throw err
  }
}

const getCashierBanksByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `cashierbanks?companyId=${companyId}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const getAllByCompanyActiveSortedByDescription = async (companyId) => {
  const response = await api.get(
    config.endpoint +
      `cashierbanks?companyId=${companyId}&isActive=1&sortBy=description&orderBy=asc`
  )
  return response.data
}

const getAllByCompanyAndTypeCashier = async (companyId) => {
  const response = await api.get(
    config.endpoint +
      `cashierbanks?companyId=${companyId}&isActive=1&sortBy=description&orderBy=asc&cashierBankTypeId=${constants.CASHIER_BANK_TYPE_ID.CASHIER}`
  )
  return response.data
}

const getCashierBanksById = async (id) => {
  try {
    const response = await api.get(config.endpoint + `cashierbanks/by-id/${id}`)
    return response.data
  } catch (err) {
    throw err
  }
}

const update = async (values) => {
  try {
    const response = await api.put(config.endpoint + 'cashierbanks', values)
    return response.data
  } catch (err) {
    throw err
  }
}

const getCashierBankFlow = async (
  cashierBanksIds,
  initialDate,
  finalDate,
  showSaleDetails,
  showTotalPaymentMethod
) => {
  try {
    const response = await api.get(
      config.endpoint +
        `cashierbanks/flow-report?cashierBanksIds=${cashierBanksIds}&initialDate=${initialDate}&finalDate=${finalDate}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const getBalanceAmountByYearPerMonth = async ({
  year,
  companyId,
  cashierBankId,
}) => {
  const response = await api.get(
    `cashierbanks/for-year/${year}/${cashierBankId}`
  )
  console.log(response.data)
  return response.data
}

export default {
  getCashierBankTypes,
  getBanks,
  store,
  getCashierBanksByCompany,
  getAllByCompanyActiveSortedByDescription,
  getCashierBanksById,
  update,
  getAllByCompanyAndTypeCashier,
  getCashierBankFlow,
  getBalanceAmountByYearPerMonth,
}
