import getCategoryDescriptionById from 'utils/accountPlanHelpers'

export const xlsColumns = [
  {
    name: 'Descrição',
    acessor: 'description'
  },
  {
    name: 'Tipo',
    acessor: 'type'
  },
  {
    name: 'Categorias',
    acessor: 'category'
  },
  {
    name: 'Status',
    acessor: 'isActive'
  },
]

export const getAccountPlansToExport = (accountPlans, accountPlanCategories) => {
  return accountPlans.map(account => ({
    description: account.description,
    type: account.type,
    category: getCategoryDescriptionById(account.categoryId, accountPlanCategories),
    isActive: account.isActive ? 'Ativo' : 'Inativo'
  }))
}
