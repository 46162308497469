import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import CollapsibleSection from 'components/CollapsibleSection';
import Toggle from 'react-toggle';
import '../styles.css';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { CRMBonusRepositoryV2 } from 'v2/repositories/CRMBonus';
import companyRepository from 'repositories/Companies';
import Button from 'client/components/CustomButton/CustomButton';

export default function NewCrmBonusFormMain({ crmBonusId }) {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [storeCode, setStoreCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      const data = await CRMBonusRepositoryV2.show(crmBonusId);
      const companyAux = data.Company;
      setIsActive(companyAux.CRMBonus);
      setStoreCode(data.storeCode);
      setSelectedCompany({
        label: `${cpfOrCnpjMask(companyAux.Cpf_Cnpj)} - ${
          companyAux.Company_Name
        }`,
        value: companyAux,
      });
    }
    if (crmBonusId) {
      loadData();
    }
  }, []);

  async function onSubmit() {
    if (!storeCode) {
      return toastr.warning(
        'Campo obrigatório',
        'É necessário inserir o código da loja.'
      );
    }

    setLoading(true);
    try {
      if (!crmBonusId) {
        await CRMBonusRepositoryV2.create({
          companyId: selectedCompany.value.id,
          storeCode,
          CRMBonus: isActive,
        });
      } else {
        await CRMBonusRepositoryV2.update(crmBonusId, {
          companyId: selectedCompany.value.id,
          storeCode,
          CRMBonus: isActive,
        });
      }

      toastr.success(
        `Cadastro ${crmBonusId ? 'atualizado' : 'realizado'} com sucesso`,
        'O cliente já pode utilizar o CRM Bônus. Peça-o para deslogar e logar novamente para aplicar o cadastro.'
      );

      history.push('crm-bonus');
    } catch (err) {
      console.log(err);

      if (err.response) {
        if (err.response.status === 401) {
          setLoading(false);
          return toastr.warning(
            'Código de loja já existente',
            err.response.data.error
          );
        } else {
          toastr.error(
            'Erro',
            'Ocorreu um erro ao efetuar a operação. Tente novamente!'
          );
        }
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCompanySelect(item) {
    setSelectedCompany(item);
  }

  function handleResetCompanySelect() {
    setSelectedCompany(null);
  }

  useEffect(() => {
    async function getCompanyOptions() {
      try {
        const { data: allCompanies } =
          await companyRepository.getCompaniesWithPaidPlan();

        const { data: allCompaniesWithCRMBonus } =
          await CRMBonusRepositoryV2.index({});

        const juridicalCompaniesOnlyCNPJ = allCompanies.filter(
          (company) => company.Cpf_Cnpj.length === 14
        );

        const juridicalCompanies = juridicalCompaniesOnlyCNPJ.filter(
          (company) =>
            !allCompaniesWithCRMBonus
              .map((crmBonus) => crmBonus.companyId)
              .includes(company.id)
        );

        setCompanies(juridicalCompanies);

        const options = juridicalCompanies.map((item) => ({
          label: `${cpfOrCnpjMask(item.Cpf_Cnpj)} - ${item.Company_Name}`,
          value: item,
        }));

        setCompanyOptions(options);
      } catch (err) {
        console.log(err);
        toastr.error(
          'Ocorreu um erro ao carregar os clientes. Tente novamente!'
        );
      }
    }
    getCompanyOptions();
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <CollapsibleSection title="Dados do Cliente" isExpanded={true}>
          <div className="suiv-register__row">
            <Select
              isSearchable
              options={companyOptions}
              placeholder={'Pesquisar por CNPJ, Razão Social ou Nome Fantasia'}
              onFocus={handleResetCompanySelect}
              onChange={handleCompanySelect}
              value={selectedCompany}
              noOptionsMessage={() => `Nenhum cliente encontrado`}
              components={{
                DropdownIndicator: Icon,
              }}
              isDisabled={!!crmBonusId}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '30px',
                  minHeight: '40px',
                  width: '600px',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '40px',
                  padding: '0 0 0 10px',
                  marginTop: '-4px',
                  left: '15px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-4px',
                }),
              }}
            />
          </div>
          <div className="suiv-register__row">
            <div
              style={{
                width: '23%',
              }}
            >
              <label htmlFor="cpfCnpj">CNPJ:</label>
              <input
                type="text"
                className="form-control"
                name="cpfCnpj"
                id="cpfCnpj"
                value={
                  selectedCompany?.value?.Cpf_Cnpj
                    ? cpfOrCnpjMask(selectedCompany?.value?.Cpf_Cnpj)
                    : ''
                }
                disabled
              />
            </div>
            <div
              style={{
                width: '33%',
              }}
            >
              <label htmlFor="companyName">Razão Social:</label>
              <input
                type="text"
                className="form-control"
                name="companyName"
                id="companyName"
                value={
                  selectedCompany?.value?.Company_Name
                    ? selectedCompany?.value?.Company_Name
                    : ''
                }
                disabled
              />
            </div>
            <div
              style={{
                width: '33%',
              }}
            >
              <label htmlFor="tradingName">Nome Fantasia:</label>
              <input
                type="text"
                className="form-control"
                name="tradingName"
                id="tradingName"
                value={
                  selectedCompany?.value?.Trading_Name
                    ? selectedCompany?.value?.Trading_Name
                    : ''
                }
                disabled
              />
            </div>
            <div
              style={{
                width: 50,
              }}
              className="toggle-wrapper"
            >
              <label htmlFor="isActive">Status:</label>
              <center>
                <Toggle
                  id="isActive"
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
              </center>
            </div>
          </div>
          <div className="suiv-register__row">
            <div
              style={{
                width: '23%',
              }}
            >
              <label>
                Código da Loja: <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="storeCode"
                id="storeCode"
                value={storeCode}
                onChange={(e) => setStoreCode(e.target.value.trim())}
              />
            </div>
          </div>
        </CollapsibleSection>

        <div className="crm-bonus-buttons">
          <Button
            bsStyle="danger"
            disabled={loading}
            onClick={() => history.push('crm-bonus')}
            fill
          >
            Cancelar
          </Button>

          <Button bsStyle="info" disabled={loading} onClick={onSubmit} fill>
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-600px', marginBottom: '2px' }}
    className="fa fa-search"
  />
);
