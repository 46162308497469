import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import fontRobotoBoldItalic from 'assets/fonts/Roboto-BoldItalic.ttf';
import JsBarcode from 'jsbarcode';

export const HangtagProduct = ({
  hangtags,
  showSalePriceType,
  showBarcode,
}) => (
  <Document>
    {hangtags.map((hangtagPage, index) => (
      <Page key={index} style={styles.body} wrap={true} size={'LETTER'}>
        <View style={styles.hangtagContainer}>
          {hangtagPage.map((hangtagRow, indexRow) => (
            <View style={styles.hangtagRow} key={indexRow}>
              <HangTag
                data={hangtagRow[0]}
                showSalePriceType={showSalePriceType}
                showBarcode={showBarcode}
              />
              {hangtagRow[1] ? (
                <HangTag
                  data={hangtagRow[1]}
                  showSalePriceType={showSalePriceType}
                  showBarcode={showBarcode}
                />
              ) : (
                <View />
              )}
            </View>
          ))}
        </View>
      </Page>
    ))}
  </Document>
);

const PriceTypeOption = ({ data, showSalePriceType }) => {
  switch (showSalePriceType) {
    case 'retail':
      return (
        <Text style={[styles.text, styles.rightCentered]}>
          {currency(data.salePrice)}
        </Text>
      );
    case 'wholesale':
      return (
        <Text style={[styles.text, styles.rightCentered]}>
          {currency(data.salePriceWholesale)}
        </Text>
      );
    case 'both':
      return (
        <Text style={[styles.text, styles.rightCentered]}>
          <Text style={{ fontWeight: 'bold' }}>Atacado:</Text>{' '}
          {currency(data.salePriceWholesale)} /{' '}
          <Text style={{ fontWeight: 'bold' }}>Varejo:</Text>{' '}
          {currency(data.salePrice)}
        </Text>
      );
    default:
      return null;
  }
};

const HangTag = ({ data, showSalePriceType, showBarcode }) => {
  const getBarCode = (barcodeValue) => {
    let canvas = document.createElement('canvas');
    JsBarcode(canvas, barcodeValue, {
      displayValue: false,
      height: '50px',
    });

    return canvas.toDataURL();
  };

  const formatBarcode = (code, prefix) => {
    const totalLength = 18;
    const prefixLength = prefix.length;
    const codeLength = code.length;
    const zerosNeeded = totalLength - prefixLength - codeLength;

    if (codeLength >= totalLength - prefixLength) {
      return `${prefix}${code}`;
    }

    return `${prefix}${'0'.repeat(zerosNeeded)}${code}`;
  };

  const getCodeDisplay = () => {
    const codeTypes = {
      byBarCode: 'barcode',
      byInternalCode: 'code',
      byAlternativeCode: 'alternativeCode',
    };

    const codeField = codeTypes[showBarcode];

    return data[codeField] ?? '';
  };

  const renderBarcode = () => {
    let barcodeData = null;

    if (showBarcode === 'byBarCode' && data.barcode) {
      barcodeData = data.barcode;
    } else if (showBarcode === 'byInternalCode' && data.code) {
      barcodeData = formatBarcode(data.code, 'CI');
    } else if (showBarcode === 'byAlternativeCode' && data.alternativeCode) {
      barcodeData = formatBarcode(data.alternativeCode, 'CA');
    }

    if (!barcodeData) {
      return <View />;
    }

    return <Image style={styles.barcode} src={getBarCode(barcodeData)} />;
  };

  return (
    <View style={styles.hangtag}>
      <View style={styles.hangtagHeader}>{renderBarcode()}</View>
      <View style={styles.hangtagContent}>
        <View style={styles.hangtagSpaceBetween}>
          <Text style={styles.text}>
            {getCodeDisplay()
              ? `Código: ${getCodeDisplay()} - ${data.description}`
              : data.description}
          </Text>
          {showSalePriceType ? (
            <Text style={[styles.text, styles.rightCentered]}>
              {data.unitType}
            </Text>
          ) : (
            <View />
          )}
        </View>
        <View style={styles.hangtagSpaceBetween}>
          <Text style={styles.text}>{data.brand}</Text>
          {showSalePriceType ? (
            <PriceTypeOption
              data={data}
              showSalePriceType={showSalePriceType}
            />
          ) : (
            <View />
          )}
        </View>
        <Text style={styles.text}>{data.location}</Text>
      </View>
    </View>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  body: {
    marginVertical: '1.27cm',
    paddingLeft: '.40cm',
    paddingRight: '0.40cm',
  },
  hangtag: {
    width: '10.16cm',
    height: '2.54cm',
    padding: '6px',
    fontSize: '8px',
    fontFamily: 'Roboto',
  },
  hangtagContainer: {
    display: 'flex',
  },
  hangtagProductCode: {
    fontFamily: 'Roboto',
    fontSize: '12px',
  },
  hangtagSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  hangtagRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hangtagContent: {
    marginTop: '4px',
  },
  text: {
    textAlign: 'justify',
    padding: '2px',
    fontSize: '7px',
  },
  rightCentered: {
    width: '140px',
    textAlign: 'center',
  },
  barcode: {
    width: '40%',
    height: 'auto',
  },
});
