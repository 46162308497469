import api from 'v2/services/api';

export const getAllByCompanyId = async (companyId, saleId) => {
  const res = await api.get(`/maintenance-services/${companyId}`, {
    params: { saleId },
  });
  return res.data;
};

export const create = async (appointments) => {
  return await api.post(`/maintenance-services`, appointments);
};

export const getHistory = async (maintenanceId) => {
  const history = await api.get(
    `/maintenance-services/history/${maintenanceId}`
  );
  return history;
};

export const stop = async (maintenanceId, data) => {
  return await api.put(`/maintenance-services/stop/${maintenanceId}`, data);
};

export const finish = async (maintenanceId, data) => {
  return await api.put(`/maintenance-services/finish/${maintenanceId}`, data);
};

export const MaintenanceServicesRepository = {
  create,
  getAllByCompanyId,
  getHistory,
  stop,
  finish,
};
