import React, { useEffect, useState, useSearchParams } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

import Sheet1 from './Sheet1';
import Sheet2 from './Sheet2';
import ThermalCoil from './ThermalCoil';
import SATThermalCoil from './SATThermalCoil';
import { useRecoilState } from 'recoil';

import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import salesRepository from '../../repositories/Sales';
import companyConfigRepository from '../../repositories/CompanyConfig';
import { useAuth } from '../../contexts/auth';
import constants from '../../utils/constants';
import { companyBranch as companyBranchAtom } from '../../storage/companyDetailsStorage';

const Sheets = ({ location, history }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sale, setSale] = useState(null);
  const [config, setConfig] = useState(null);
  const [companyPrintingSheetId, setCompanyPrintingSheetId] = useState(null);
  const [showPaymentSuggestions, setShowPaymentSuggestions] = useState(false);

  const [isProductionOrder, setIsProductionOrder] = useState(false);

  const { company, loading: contextLoading, isSigned, companyId } = useAuth();

  const isFiscal = ('location', location.search.split('=')[1]);

  const [companyBranch] = useRecoilState(companyBranchAtom);

  useEffect(() => {
    if (!contextLoading && !isSigned) {
      history.push('/');
    }
  }, [contextLoading, isSigned]);

  useEffect(() => {
    const getSaleIdFromPathName = () =>
      location.pathname
        .split('Vend=v723dha5tHSSpS83711')[1]
        ?.split('5422hsGsdAQLk8423oPL31')[0];
    const saleId = getSaleIdFromPathName();

    const query = new URLSearchParams(location.search);
    if (query.get('productionOrder')) {
      setIsProductionOrder(true);
    } else {
      setIsProductionOrder(false);
    }

    if (!!saleId) {
      loadSale(saleId);
      return;
    } else {
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    if (sale && company) {
      setShowPaymentSuggestions(company.companyConfig.budgetPaymentSuggestions);

      const saleTypeId = sale.SalesType_id;

      if (saleTypeId === constants.SALES_TYPE_ID.ORCAMENT)
        return setCompanyPrintingSheetId(company.orcamentPrintingSheetId);

      if (saleTypeId === constants.SALES_TYPE_ID.SALE) {
        if (sale.SATSale && sale.SATSale.id && isFiscal === 'true') {
          return setCompanyPrintingSheetId(
            constants.PRINTING_SHEETS.SAT_THERMAL_COIL
          );
        } else {
          return setCompanyPrintingSheetId(company.salePrintingSheetId);
        }
      }

      if (saleTypeId === constants.SALES_TYPE_ID.ORDER_OF_SERVICE) {
        if (sale.SATSale && sale.SATSale.id && isFiscal === 'true') {
          return setCompanyPrintingSheetId(
            constants.PRINTING_SHEETS.SAT_THERMAL_COIL
          );
        }

        return setCompanyPrintingSheetId(company.orderOfServicePrintingSheetId);
      }

      if (saleTypeId === constants.SALES_TYPE_ID.PDV) {
        if (sale.SATSale && sale.SATSale.id && isFiscal === 'true') {
          return setCompanyPrintingSheetId(
            constants.PRINTING_SHEETS.SAT_THERMAL_COIL
          );
        } else {
          return setCompanyPrintingSheetId(company.pdvPrintingSheetId);
        }
      }
    }
  }, [sale, company]);

  useEffect(() => {
    if (companyPrintingSheetId) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [companyPrintingSheetId]);
  useEffect(() => {
    if (companyId) {
      getSaleConfig();
    }
  }, [companyId]);

  async function loadSale(saleId) {
    try {
      const sale = await salesRepository.getSaleToPrint(saleId);
      setSale(sale);
    } catch (err) {
      setHasError(true);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar o impresso. Por favor, tente novamente'
        );
      }
    }
  }

  async function getSaleConfig() {
    try {
      const configSale = await companyConfigRepository.show(companyId);
      setConfig(configSale);
    } catch (err) {
      setHasError(true);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar o impresso. Por favor, tente novamente'
        );
      }
    }
  }

  function renderSheet() {
    const values = {
      [constants.PRINTING_SHEETS.SHEET1]: (
        <Sheet1
          sale={sale}
          config={config}
          isProductionOrder={isProductionOrder}
          showPaymentSuggestions={showPaymentSuggestions}
          companyBranch={companyBranch}
        />
      ),
      [constants.PRINTING_SHEETS.SHEET2]: (
        <Sheet2
          sale={sale}
          config={config}
          isProductionOrder={isProductionOrder}
          showPaymentSuggestions={showPaymentSuggestions}
          companyBranch={companyBranch}
        />
      ),
      [constants.PRINTING_SHEETS.THERMAL_COIL]: (
        <ThermalCoil
          sale={sale}
          config={config}
          isProductionOrder={isProductionOrder}
          showPaymentSuggestions={showPaymentSuggestions}
          companyBranch={companyBranch}
        />
      ),
      [constants.PRINTING_SHEETS.SAT_THERMAL_COIL]: (
        <SATThermalCoil sale={sale} />
      ),
    };

    return values[companyPrintingSheetId] || <Sheet1 sale={sale} />;
  }

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar a venda. Por favor, tente novamente" />
    );
  if (loading) return <AppLoading />;

  return (
    <div id="sale-printing-sheet-page">
      <main
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <PDFViewer style={{ height: '100vh', width: '100vw' }}>
          {renderSheet()}
        </PDFViewer>
      </main>
    </div>
  );
};

export default withRouter(Sheets);
