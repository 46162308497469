import React from "react";
import { Grid, Row } from "react-bootstrap";

import FormMyCompanyDetails from './MyCompanyDetails'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const MyCompanyDetails = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Minha Empresa', 'Meus Detalhes']}
            path={['home', 'companies', 'MeusDetalhes']}
          />
            <FormMyCompanyDetails />
        </Row>
      </Grid>
    </div>
  );
}

export default MyCompanyDetails;