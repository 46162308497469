import { CFOPMask } from 'client/components/ToNormalize/ToNormalize.js';
import RenderField from 'components/RenderField';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { change, Field } from 'redux-form';
import productsRepository from '../../../../repositories/Products';
import { PRODUCT_CST, PRODUCT_CSOSN } from 'utils/constants.js';
import { currencyMask, CFOPsMask, CESTMask, percentMask } from 'utils/masks.js';
import Button from '../../../../client/components/CustomButton/CustomButton.jsx';
import FormProduct from '../../Products/NewProduct/FormProduto';
import useProductTax from './utils.js';
import { useAuth } from 'contexts/auth';

const TaxesByProductModal = ({ onCancel, index }) => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    NFeItems,
    NFeItemEditing,
    CFOPs,
    isCompanyTaxRegimeSimpleNational,
    isInvoiceIssued,
    isInvoiceIssuedInContingency,
    saleCode,
    COFINSValue,
    PISValue,
  } = useSelector((state) => state.form.NFe.values);

  const { companyId } = useAuth();

  const itemName = `NFeItems[${index}]`;
  const product = NFeItems[index];
  const dispatch = useDispatch();
  const productTax = useProductTax();
  useEffect(() => {
    const taxesInfo = productTax.getTaxInfo(product);
    const newNFeItems = [...NFeItems];
    newNFeItems[index] = {
      ...newNFeItems[index],
      originDescription: taxesInfo.originDescription,
      unitTypeDescription: taxesInfo.unitTypeDescription,
    };

    dispatch(change('NFe', 'NFeItems', newNFeItems));
  }, []);

  useEffect(() => {
    dispatch(change('NFe', 'NFeItemEditing', NFeItems[index]));

    return () => {
      dispatch(change('NFe', 'NFeItemEditing', undefined));
    };
  }, []);
  useEffect(() => {
    dispatch(change('NFe', `${itemName}.STCOFINSValue`, COFINSValue));
    dispatch(change('NFe', `${itemName}.STPISValue`, PISValue));
  }, [COFINSValue, PISValue]);

  function handleCancelEdit() {
    const newNFeItems = [...NFeItems];
    newNFeItems[index] = NFeItemEditing;
    dispatch(change('NFe', 'NFeItems', newNFeItems));
    onCancel();
  }

  async function handlePullProductInfo() {
    setLoading(true);

    try {
      const response = await productsRepository.getById(product.productId);

      const {
        PIS_Exit,
        COFINS_Exit,
        Origin,
        NCM_Code,
        NcmNbs,
        CEST_Code,
        Bar_Code,
        Tribute_BarCode,
        Unit_Type,
        ANP,
        ANP_Description,
        ANP_UF_Code,
      } = response;

      const newNFeItems = [...NFeItems];
      const item = {
        ...newNFeItems[index],
        unitType: Unit_Type,
        NCM: NcmNbs ? NcmNbs.code : NCM_Code,
        CEST:
          NcmNbs?.CestCompany?.filter(
            (company) => company.companyId === companyId
          )[0]?.CEST || CEST_Code,
        barCode: Bar_Code,
        tributeBarCode: Tribute_BarCode,
        origin: Origin,
        STPIS: PIS_Exit,
        STCOFINS: COFINS_Exit,
        ANP,
        ANPDescription: ANP_Description,
        ANPUF: ANP_UF_Code,
        hasNumberPed: !(newNFeItems[index]?.pedNumber === null),
      };
      const serializedItem = productTax.getTaxInfo(item);
      newNFeItems[index] = serializedItem;

      dispatch(change('NFe', 'NFeItems', newNFeItems));
      toastr.success('Informações do item atualizado');
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as informações atualizadas do Produto'
      );
    } finally {
      setIsProductModalOpen(false);
      setLoading(false);
    }
  }
  return (
    <>
      <Modal
        show
        animation
        dialogClassName="NFe-items-tax-by-product-modal-wrapper"
      >
        <Modal.Header closeButton onHide={onCancel}>
          <Modal.Title>
            <strong>Tributos do produto {product.description}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="NFe-items-tax-by-product-modal">
            <header>
              <span
                onClick={() => setIsProductModalOpen(true)}
                className="link"
              >
                Visualizar Cadastro do Produto
              </span>
            </header>
            <main>
              <Field
                name={`${itemName}.code`}
                label="Código Interno:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.unitTypeDescription`}
                label="Unidade de Medida:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.NCM`}
                label="NCM:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.CEST`}
                label="CEST:"
                component={RenderField}
                {...CESTMask}
                disabled
              />
              <Field
                name={`${itemName}.barCode`}
                label="Cód. de Barras (EAN):"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.tributeBarCode`}
                label="Cód. de Barras (EAN Trib):"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.originDescription`}
                label="Origem da Mercadoria:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.CSTCSOSN`}
                label={isCompanyTaxRegimeSimpleNational ? 'CSOSN' : 'CST'}
                component={RenderField}
                as="select"
              >
                <option value="">Selecione</option>
                {isCompanyTaxRegimeSimpleNational
                  ? PRODUCT_CSOSN.map((CSOSN) => (
                      <option key={CSOSN} value={CSOSN}>
                        {CSOSN}
                      </option>
                    ))
                  : PRODUCT_CST.map((CST) => (
                      <option key={CST} value={CST}>
                        {CST}
                      </option>
                    ))}
              </Field>

              <fieldset>
                <>
                  <Field
                    name={`${itemName}.CFOP`}
                    component={RenderField}
                    label="CFOP:"
                    {...CFOPsMask}
                    list="CFOPSuggestions"
                  />
                  <datalist id="CFOPSuggestions">
                    {CFOPs.map((CFOP) => (
                      <option key={CFOP.id} value={CFOPMask(CFOP.CFOP)} />
                    ))}
                  </datalist>
                </>
                <Field
                  name={`${itemName}.IPI`}
                  label="% IPI:"
                  component={RenderField}
                  {...percentMask}
                />
              </fieldset>
            </main>
            <footer>
              <Field
                name={`${itemName}.ICMS`}
                label="% ICMS Destino:"
                component={RenderField}
                {...percentMask}
                maxLength={8}
                style={{ width: '130px' }}
              />
              <Field
                name={`${itemName}.ICMSRed`}
                label="% Red. ICMS:"
                component={RenderField}
                {...percentMask}
                maxLength={8}
              />
              <Field
                name={`${itemName}.MVA`}
                label="% MVA:"
                component={RenderField}
                {...percentMask}
                maxLength={8}
              />
              <Field
                name={`${itemName}.ICMSInternal`}
                label="% ICMS Interno: "
                component={RenderField}
                {...percentMask}
                maxLength={8}
                style={{ width: '130px' }}
              />
              <Field
                name={`${itemName}.ICMSRedST`}
                label="% Red. ICMS ST.:"
                component={RenderField}
                {...percentMask}
                maxLength={8}
                style={{ width: '130px' }}
              />
              <Field
                name={`${itemName}.FCP`}
                label="% FCP:"
                component={RenderField}
                {...percentMask}
                maxLength={8}
              />
              <Field
                name={`${itemName}.STPIS`}
                label="ST PIS:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.STPISValue`}
                label="Valor PIS:"
                component={RenderField}
                {...currencyMask}
                disabled
              />
              <Field
                name={`${itemName}.STCOFINS`}
                label="ST COFINS:"
                component={RenderField}
                disabled
              />
              <Field
                name={`${itemName}.STCOFINSValue`}
                label="Valor COFINS:"
                component={RenderField}
                {...currencyMask}
                disabled
              />
              <Field
                name={`${itemName}.pedNumber`}
                label="Nº Ped.:"
                component={RenderField}
                disabled={
                  (saleCode && product?.hasNumberPed) ||
                  !!isInvoiceIssued ||
                  !!isInvoiceIssuedInContingency
                }
              />
              <Field
                name={`${itemName}.pedItemNumber`}
                label="Nº Item Ped.:"
                component={RenderField}
              />
            </footer>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div id="NFe-items-tax-by-product-modal-footer">
            <Button bsStyle="danger" fill onClick={handleCancelEdit}>
              Voltar
            </Button>
            <Button
              bsStyle="primary"
              fill
              disabled={
                loading || !!isInvoiceIssued || !!isInvoiceIssuedInContingency
              }
              onClick={handlePullProductInfo}
            >
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
              Importar dados do cadastro
            </Button>
            <Button
              bsStyle="info"
              fill
              onClick={onCancel}
              disabled={!!isInvoiceIssued || !!isInvoiceIssuedInContingency}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isProductModalOpen}
        animation
        dialogClassName="NFe-items-product-modal"
        onHide={() => setIsProductModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Cadastro do Produto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormProduct
            ProductId={product.productId}
            onCancel={() => setIsProductModalOpen(false)}
            onSuccess={handlePullProductInfo}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaxesByProductModal;
