import styled from 'styled-components';

export const SidebarContainer = styled.nav`
  width: 64px;
  height: calc(100vh - 50px);
  position: fixed;
  top: 50px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branding1000};
  z-index: ${(props) => props.theme.zIndex.menu};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
`;
