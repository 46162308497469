import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "react-table";
import { Link, withRouter, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { Modal } from 'react-bootstrap'
import FormSimilarity from './NewSimilarity/FormSimilarity'
import CardForm from 'client/components/CardForm'
import AlertModal from '../../../components/AlertModal'

import CustomButton from 'client/components/CustomButton/CustomButton.jsx'
import useFilters from '../../../hooks/useFilters'
import constants from '../../../utils/constants'
import { useAuth } from '../../../contexts/auth'

import similarityRepository from '../../../repositories/Similarities'
import productsRepository from '../../../repositories/Products'
import './styles.css'

const HomeSimilarities = ({ productId }) => {
  const [loading, setLoading] = useState(false)
  const [productSimilarities, setProductsSimilarities] = useState([])
  const [isSimilarityRegisterModalOpen, setIsSimilarityRegisterModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [similarityToDelete, setSimilarityToDelete] = useState(null)

  const [code, setCode] = useState(null)
  const [description, setDescription] = useState(null)
  const [manufacturerRef, setManufacturerRef] = useState(null)
  const [brand, setBrand] = useState(null)
  const [productInfo, setProductInfo] = useState()

  const { companyId } = useAuth()
  const history = useHistory()

  const { queryFilter, typeFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  useEffect(() => {
    if (!!productId) {
      loadSimilarities()
    }
  }, [productId])

  const loadSimilarities = async () => {
    setLoading(true)

    try {
      const similarity = await productsRepository.getById(productId)

      setProductsSimilarities(similarity?.Similarities)
      setProductInfo(similarity)
      setCode(similarity.Code)
      setDescription(similarity.Description)
      setManufacturerRef(similarity.Manufacturer_Ref)
      setBrand(similarity?.Brands?.Description)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as similaridades.')
    } finally {
      setLoading(false)
    }
  }

  const handleFilters = similarity => {
    const { similarProduct } = similarity
    const querySearch = [similarProduct['Description'], similarProduct['Manufacturer_Ref'], similarProduct['Code']]
    return filterByQuery(querySearch)
  }

  const handleNewSimilarity = () => {
    setIsSimilarityRegisterModalOpen(true)
  }

  const handleSimilarityRegisterSuccess = () => {
    setIsSimilarityRegisterModalOpen(false)

    loadSimilarities()
  }

  const handleReturnButton = () => {
    return history.push(constants.ROUTES.PRODUCTS)
  }

  const handleDeleteButton = (id) => {
    setSimilarityToDelete(id)
    setIsDeleteModalOpen(true)
  }

  const handleDeleteSimilarity = async () => {
    try {
      await similarityRepository.deleteSimilarity({ similarityId: similarityToDelete })

      setIsDeleteModalOpen(false)
      loadSimilarities()
    } catch (err) {
      return toastr.warning(err.message)
    }
  }

  return (
    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
      <CardForm
        show
        title="Similaridade"
        onClick={() => { }}
      >
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div class="product-input">
              <label>Código:</label>
              <input
                class="product-input-values"
                value={code}
                disabled
              />
            </div>
            <div class="product-input">
              <label>Descrição:</label>
              <input
                class="product-input-values"
                value={description}
                disabled
              />
            </div>
            <div class="product-input">
              <label>Ref. Fabricante:</label>
              <input
                class="product-input-values"
                value={manufacturerRef}
                disabled
              />
            </div>
            <div class="product-input">
              <label>Marca:</label>
              <input
                class="product-input-values"
                value={brand}
                disabled
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="reviewHeader">
            <div>
              <button
                className="btn btn-sucesso"
                onClick={handleNewSimilarity}
              >
                + Nova Similaridade
              </button>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4" id="review-search">
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px', width: '174%' }}
                className="form-control foco-input"
                placeholder="Pesquisar por Código, Ref. Fabricante ou Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={productSimilarities.filter(handleFilters)}
              columns={[
                {
                  Header: "Código",
                  accessor: "similarProduct.Code",
                  headerClassName: "text-left",
                  width: 130,
                },
                {
                  Header: "Descrição",
                  accessor: "similarProduct.Description",
                  headerClassName: "text-left",
                },
                {
                  Header: "Ref. Fabricante",
                  accessor: "similarProduct.Manufacturer_Ref",
                  headerClassName: "text-left",
                },
                {
                  Header: "Marca",
                  accessor: "similarProduct.Brands",
                  headerClassName: "text-left",
                  Cell: ({ value }) => value ? value.Description : ''
                },
                {
                  Header: "Ações",
                  accessor: "id",
                  headerClassName: "text-left",
                  width: 150,
                  Cell: props => (
                    <>
                      <FontAwesomeIcon
                        title='Excluir'
                        cursor='pointer'
                        style={{ height: '1.5em', width: '1.5em', color: 'red', paddingLeft: '1px' }}
                        icon={faTrashAlt}
                        onClick={() => handleDeleteButton(props.value)}
                      />
                    </>
                  )
                }
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[{
                id: 'Code',
                desc: false
              }]}
              previousText='Anterior'
              nextText='Próximo'
              loadingText='Carregando...'
              noDataText='Não há informação'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
            />
          </div>
          <div>
          </div>
        </div>
      </CardForm>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <CustomButton
          bsStyle="danger"
          fill
          onClick={handleReturnButton}
        >
          Cancelar
      </CustomButton>
      </div>


      {
        isSimilarityRegisterModalOpen &&
        <Modal
          dialogClassName="modal-formProduto"
          show={setIsSimilarityRegisterModalOpen}
          onHide={() => setIsSimilarityRegisterModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Adicionar Item</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormSimilarity
              modal
              handleModal={handleSimilarityRegisterSuccess}
              hideModal={() => setIsSimilarityRegisterModalOpen(false)}
              product={productInfo}
            />
          </Modal.Body>
        </Modal>
      }

      {
        isDeleteModalOpen &&
        <AlertModal
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          message={`Deseja excluir a similaridade desses produtos? O produto poderá ser adicionado posteriormente.`}
          onSubmit={handleDeleteSimilarity}
        />
      }

    </div>
  )
}

export default withRouter(HomeSimilarities)