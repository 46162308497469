import api from 'v2/services/api';

const validationIBPTFile = async (base64) => {
  try {
    const response = await api.post(`ibpt-import/validation/`, {
      base64,
    });
    return response.status;
  } catch (err) {
    throw err;
  }
};
const importIBPTFile = async (companyId, base64) => {
  try {
    const response = await api.post(`ibpt-import/import/`, {
      companyId,
      base64,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
const getFinalValidityIBPTFile = async (params) => {
  try {
    const response = await api.get(`ibpt-import/`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const IBPTRepository = {
  validationIBPTFile,
  importIBPTFile,
  getFinalValidityIBPTFile,
};

export default IBPTRepository;
