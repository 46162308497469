import React, { useState } from 'react'
import {
  faEdit,
  faPrint,
  faSearch,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastr } from 'react-redux-toastr'

import { INVOICE_STATUS_DESCRIPTION } from '../../../../../utils/constants'
import NFCesRepository from '../../../../../repositories/NFCes'

import CancelInvoiceModal from 'client/views/Invoices/CancelInvoiceModal'
import Loader from 'components/Loader'

const NFCeActions = ({ NFCe, onChange, onEdit }) => {
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false)

  const [loadingConsult, setLoadingConsult] = useState(false)

  const isDisabled = NFCe.status === INVOICE_STATUS_DESCRIPTION.DISABLED
  const isIssued = NFCe.status === INVOICE_STATUS_DESCRIPTION.ISSUED
  const isInContingency = NFCe.status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY

  function onSuccessCancelInvoiceModal() {
    setShowCancelInvoiceModal(false)
    onChange()
  }

  function handleOpenPDFDownloadPage() {
    window.open(NFCe.docPdfDownload)
  }

  async function consultNFCe(type) {
    setLoadingConsult(true)
    try {
      await NFCesRepository.consult(type, NFCe.id)
      onChange()
      toastr.success('NFC-e consultada com sucesso.')
    } catch (err) {
      console.log(err.response)
      toastr.error('Ocorreu um erro ao consultar NFC-e.')
    }
    setLoadingConsult(false)
  }

  if (isDisabled) return null

  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(NFCe)}
        className='btn-acoes'
        style={{ color: '#000000' }}
      />
      {isInContingency && (
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => consultNFCe('contingency')}
          className='btn-acoes'
          style={{ color: '#444444' }}
        />
      )}
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handleOpenPDFDownloadPage}
            className='btn-acoes'
            style={{ color: '#444444' }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => consultNFCe('normal')}
            className='btn-acoes'
            style={{ color: '#444444' }}
          />
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => setShowCancelInvoiceModal(true)}
            className='btn-acoes'
            style={{ color: '#e61610' }}
          />
        </>
      )}

      <CancelInvoiceModal
        show={showCancelInvoiceModal}
        onCancel={() => setShowCancelInvoiceModal(false)}
        onHide={() => setShowCancelInvoiceModal(false)}
        onSuccess={onSuccessCancelInvoiceModal}
        nfceSelected={{ ...NFCe, type: 'NFC-e' }}
      />

      {loadingConsult && <Loader message='Aguarde, consultando NFC-e' />}
    </div>
  )
}

export default NFCeActions
