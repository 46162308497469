import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudDownloadAlt,
  faEdit,
  faFileInvoice,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { format, isAfter, addDays, subDays } from 'date-fns';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import TitleQuickDischargeModal from 'client/components/TitleQuickDischargeModal';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import constants from '../../../utils/constants';
import useDebounce from 'hooks/useDebounce';
import { xlsColumns, getBillsToReceiveInfoToExport } from './excelHelpers';

import Button from 'client/components/CustomButton/Button.jsx';
import CustomButton from 'client/components/CustomButton/CustomButton';
import Card from 'components/Card/Card.jsx';
import billsToReceiveRepository from '../../../repositories/BillsToReceive';
import formsOfPaymentRepository from '../../../repositories/PaymentForms';
import bankSlipRepository from '../../../repositories/BankSlip';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import DownloadXlsButton from '../../../components/DownloadXlsButton';

import accountPlansRepository from 'repositories/AccountPlans';
import cashierBankRepository from 'repositories/CashierBank';
import customerRepository from '../../../repositories/Customers';

import { useModulesContext } from 'contexts/modules';
import { Modal } from 'react-bootstrap';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from 'contexts/auth';
import BillsToReceivePrintModal from './BillsToReceivePrintModal';
import RegistrationMethodModal from './components/RegistrationMethodModal';
import { usePlanSignatureContext } from 'contexts/plan-signature';

import ConsultReceivableAmountBtn from './components/ConsultReceivableAmountBtn';
import { getBankSlipTooltip } from 'utils/bankSlipUtils';
import { getColorClassByBankSlipStatus } from 'utils/bankSlipUtils';
import { isCPFOrCNPJ } from 'brazilian-values';
import { GroupTitlesModal } from 'v2/client/components/GroupTitlesModal';
import SearchInput from 'components/SearchInput';

const HomeBillsToReceive = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billsToReceive, setBillsToReceive] = useState([]);

  const [isQuickDischargeModalOpen, setIsQuickDischargeModalOpen] =
    useState(false);

  const { companyId } = useAuth();
  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();

  const { isBankSlipModuleActive } = useModulesContext();
  const [isBankSlipDisabledModalOpen, setIsBankSlipDisabledModalOpen] =
    useState(false);
  const [isBankSlipActionsModalOpen, setIsBankSlipActionsModalOpen] =
    useState(false);
  const [selectedTitleToBankSlipActions, setSelectedTitleToBankSlipActions] =
    useState({});

  const [dataToExport, setDataToExport] = useState([]);
  const [isXlsExportReady, setIsXlsExportReady] = useState(false);
  const [isXlsExportLoading, setIsXlsExportLoading] = useState(false);

  const [
    isGenBankSlipConfirmationModalOpen,
    setIsGenBankSlipConfirmationModalOpen,
  ] = useState(false);

  const [isGenerateBankSlipModalOpen, setIsGenerateBankSlipModalOpen] =
    useState(false);

  const [isRegistrationMethodModalOpen, setRegistrationMethodModalOpen] =
    useState(false);

  const [status, setStatus] = useState('');
  const [accountPlanId, setAccountPlanId] = useState('');
  const [cashierBankId, setCashierBankId] = useState('');
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const [initialDateDebounced, setInitialDateDebounced] = useState(null);
  const [finalDateDebounced, setFinalDateDebounced] = useState(null);

  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 800);
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 800);

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(10);

  const [isInitialFiltersDefined, setIsInitialFiltersDefined] = useState(false);

  const [
    isCancelBankSlipConfirmationModalOpen,
    setIsCancelBankSlipConfirmationModalOpen,
  ] = useState(false);

  const [formOfPaymentOptions, setFormOfPaymentOptions] = useState([]);
  const [paymentFormToBankSlip, setPaymentFormToBankSlip] = useState(null);

  const [processingBankSlip, setProcessingBankSlip] = useState(false);

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [selectedTitleToPrint, setSelectedTitleToPrint] = useState(null);

  const [accountPlans, setAccountPlans] = useState([]);
  const [cashierBank, setCashierBank] = useState([]);

  const [triggerLoadList, setTriggerLoadList] = useState(false);

  const [isGroupTitlesModalOpen, setIsGroupTitlesModalOpen] = useState(false);

  function handleSetInitialFilters() {
    const params = new URLSearchParams(window.location.search);
    const receive = params.get('receive');

    if (!!receive) {
      setStatus(constants.BILLS_STATUS.OPEN);
      if (receive === 'today') {
        const dataInicial = format(new Date(), 'yyyy-MM-dd');
        setInitialDate(dataInicial);
        setFinalDate(dataInicial);
        setInitialDateDebounced(dataInicial);
        setFinalDateDebounced(dataInicial);
      } else {
        const lastDay = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        setFinalDate(lastDay);
        setFinalDateDebounced(lastDay);
      }
    }

    setIsInitialFiltersDefined(true);
  }

  useEffect(() => {
    if (isInitialFiltersDefined === false) {
      return;
    } else {
      getBillsToReceive();
    }
  }, [
    page,
    pageLimit,
    cashierBankId,
    accountPlanId,
    status,
    initialDateDebounced,
    finalDateDebounced,
    queryDebounced,
    isInitialFiltersDefined,
  ]);

  useEffect(() => {
    if (triggerLoadList) {
      getBillsToReceive();
    }
  }, [triggerLoadList]);

  useEffect(() => {
    handleSetInitialFilters();
    if (companyId && isInitialFiltersDefined === true) getBillsToReceive();
    loadFormsOfPayment();
    loadActiveAccountPlans();
    loadActiveCashierBanks();
  }, [isInitialFiltersDefined]);

  useEffect(() => {
    if (selectedTitleToBankSlipActions?.id) {
      handleOpenBankSlipActionsModal();
    }
  }, [selectedTitleToBankSlipActions]);

  const getBillsToReceive = async () => {
    setLoading(true);
    setIsXlsExportReady(false);

    try {
      const response = await billsToReceiveRepository.getAllByCompany(
        companyId,
        {
          page: page + 1,
          limit: pageLimit,
          cashierBankId,
          accountPlanId,
          billStatusId: status,
          initialDate: initialDateDebounced,
          finalDate: finalDateDebounced,
          query: queryDebounced,
        }
      );

      setTotalPages(Math.ceil(response.count / pageLimit));
      setBillsToReceive(response.bills);
    } catch (err) {
      toastr.error('Ocorreu ao carregar títulos. Por favor, tente novamente');
    }
    setLoading(false);
  };

  const loadDataToExport = async () => {
    setIsXlsExportLoading(true);
    toastr.warning(
      'Preparando Exportação',
      'Avisaremos quando estiver concluída. Por favor, aguarde.'
    );
    try {
      const response = await billsToReceiveRepository.getAllByCompany(
        companyId,
        {
          cashierBankId,
          accountPlanId,
          billStatusId: status,
          initialDate: initialDateDebounced,
          finalDate: finalDateDebounced,
          query: queryDebounced,
        }
      );

      const serializedDataToExport = getBillsToReceiveInfoToExport(
        response.bills
      );
      setDataToExport(serializedDataToExport);
      setIsXlsExportLoading(false);
      setIsXlsExportReady(true);

      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar títulos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  async function loadActiveAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({ companyId });
      const accountPlansCredit = data.filter(
        (accountPlan) => accountPlan.type === 'Crédito'
      );

      setAccountPlans(accountPlansCredit);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente.'
      );
    }
  }

  async function loadActiveCashierBanks() {
    try {
      const response =
        await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );

      setCashierBank(response);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os Caixas/Bancos. Por favor, tente novamente.'
      );
    }
  }

  function handleNewTitleButton() {
    if (!isPlanFree && !isPlanStart && !isPlanBasic) {
      setRegistrationMethodModalOpen(true);
    } else {
      history.push(constants.ROUTES.BILL_TO_RECEIVE);
    }
  }

  function handleOpenBankSlipActionsModal() {
    if (!isBankSlipModuleActive) {
      setSelectedTitleToBankSlipActions({});
      return setIsBankSlipDisabledModalOpen(true);
    } else {
      if (selectedTitleToBankSlipActions?.billStatusId === 1) {
        return setIsBankSlipActionsModalOpen(true);
      } else {
        const titleStatus =
          selectedTitleToBankSlipActions?.billStatusId === 3
            ? 'cancelados'
            : 'finalizados';
        return toastr.warning(
          `Não há ações de boleto para títulos ${titleStatus}`
        );
      }
    }
  }

  function handleCloseBankSlipActionsModal() {
    setSelectedTitleToBankSlipActions({});
    setIsBankSlipActionsModalOpen(false);
  }

  function handleChangeQuery(value) {
    setQuery(value);
    debouncedSaveQuery(value);
  }

  function handleChangeInitialDate(value) {
    setInitialDate(value);
    debouncedSaveInitialDate(value);
  }

  function handleChangeFinalDate(value) {
    setFinalDate(value);
    debouncedSaveFinalDate(value);
  }

  async function loadFormsOfPayment() {
    try {
      const formsOfPayment =
        await formsOfPaymentRepository.getAllByCompanySortedByDescription(
          companyId
        );
      setFormOfPaymentOptions(
        formsOfPayment?.filter(
          (item) => item.isBankSlipIntegration && item.IsActive
        )
      );
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as formas de pagamento. Por favor, tente novamente'
      );
    }
  }

  async function handleEmitBankSlip() {
    try {
      setProcessingBankSlip(true);

      if (!paymentFormToBankSlip) {
        setProcessingBankSlip(false);
        return toastr.warning(
          'Selecione uma carteira para receber o pagamento'
        );
      }

      if (
        isAfter(
          new Date(),
          addDays(
            getDateOnlyFromDate(selectedTitleToBankSlipActions?.dueDate),
            1
          )
        )
      ) {
        setProcessingBankSlip(false);
        return toastr.warning(
          'Não é possível gerar boletos para títulos já vencidos'
        );
      }

      const formOfPayment = formOfPaymentOptions.find(
        (item) => parseInt(item.id) === parseInt(paymentFormToBankSlip)
      );

      await bankSlipRepository.emitByTitle(selectedTitleToBankSlipActions?.id, {
        companyId,
        bankBilletAccountId: formOfPayment.bankBilletAccountId,
        formOfPaymentId: paymentFormToBankSlip,
      });

      setPaymentFormToBankSlip('');
      setIsGenerateBankSlipModalOpen(false);
      handleCloseBankSlipActionsModal();

      toastr.success('Boleto gerado com sucesso e encaminhado ao cliente!');

      getBillsToReceive();
    } catch (err) {
      const messageError = err?.response?.data?.message;
      console.log(messageError || err);
      toastr.warning(
        'Ocorreu um erro ao emitir o boleto',
        'Verifique os dados do cliente, número e data do título e, se persistir, contate o suporte!'
      );
    } finally {
      setProcessingBankSlip(false);
    }
  }

  async function handleCancelBankSlip() {
    try {
      setProcessingBankSlip(true);

      await bankSlipRepository.cancel(
        selectedTitleToBankSlipActions.bankSlipId
      );

      toastr.success('Boleto cancelado com sucesso!');

      setIsCancelBankSlipConfirmationModalOpen(false);
      handleCloseBankSlipActionsModal();
      getBillsToReceive();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Falha ao cancelar o boleto',
        'Tente novamente e, se persistir, contate o suporte!'
      );
    } finally {
      setProcessingBankSlip(false);
    }
  }

  const loadCustomerInfo = async (dataBills) => {
    const customerInfo = await customerRepository.getCustomer(
      dataBills.Customer.id
    );

    return customerInfo;
  };

  const validateCustomerData = (customerInfo) => {
    if (!customerInfo) return ['cliente não encontrado'];

    const addressInfo = customerInfo?.Address?.find(
      (address) => address.Type_Address === 'Principal'
    );

    const errors = [
      !isCPFOrCNPJ(customerInfo.Cpf_Cnpj) && 'cpf ou cnpj',
      !customerInfo.Email && 'e-mail',
      !addressInfo && 'endereço principal',
      addressInfo &&
        !addressInfo.Address_Number &&
        'número do endereço principal',
    ].filter(Boolean);

    return errors;
  };

  const handleValidateRequiredDataBills = async (dataBills) => {
    try {
      const customerInfo = await loadCustomerInfo(dataBills);
      const errors = validateCustomerData(customerInfo);

      if (errors.length > 0) {
        const nameCustomer =
          customerInfo?.Trading_Name || customerInfo?.Company_Name || 'Cliente';
        return displayErrorToast(errors, nameCustomer);
      }

      setSelectedTitleToBankSlipActions(dataBills);
    } catch (error) {
      console.error('Error validating required data bills:', error);
      toastr.error(
        'Emissão de Boleto',
        'Ocorreu um erro ao validar os dados. Por favor, tente novamente.'
      );
    }
  };

  const handleFlowBills = async (dataBills) => {
    try {
      switch (dataBills.bank_slip_status_description) {
        case null:
          handleValidateRequiredDataBills(dataBills);
          break;

        default:
          setSelectedTitleToBankSlipActions(dataBills);
          break;
      }
    } catch (error) {
      console.error('Error flow data bills: ', error);
    }
  };

  const displayErrorToast = (errors, nameCustomer) => {
    const combinedErrors = errors.join(' e ');
    const messageContent = 'Por favor, atualize os dados.';
    const messageTitle = 'Emissão de Boleto';

    toastr.error(
      messageTitle,
      `Não foi localizado ${combinedErrors} do(a) cliente ${nameCustomer}. ${messageContent}`
    );
  };

  const handleTriggerLoadList = () => {
    setTriggerLoadList(true);
    setTimeout(() => {
      setTriggerLoadList(false);
    }, [500]);
  };

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  !!companyId ? handleNewTitleButton() : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Novo Título
              </button>
            </div>

            <div>
              <SearchInput
                placeholder="Pesquisa por Título, Venda ou Cliente"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value={constants.BILLS_STATUS.OPEN}>Aberto</option>
                <option value={constants.BILLS_STATUS.CLOSED}>
                  Finalizado
                </option>
                <option value={constants.BILLS_STATUS.CANCELED}>
                  Cancelado
                </option>
              </select>
            </div>

            <div>
              {isXlsExportReady ? (
                <DownloadXlsButton
                  data={dataToExport}
                  className="btn btn-export"
                  archiveName={`contas-a-receber-${format(
                    new Date(),
                    'dd/MM/yyyy'
                  )}`}
                  columns={xlsColumns}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '35px',
                    fontSize: '12px',
                  }}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                  &nbsp;Baixar Relatório
                </DownloadXlsButton>
              ) : (
                <button
                  className="btn btn-export"
                  onClick={() => loadDataToExport()}
                  disabled={isXlsExportLoading}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '35px',
                    fontSize: '12px',
                  }}
                >
                  {isXlsExportLoading ? (
                    <span className="fa fa-spinner fa-pulse fa-1x"></span>
                  ) : (
                    <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                  )}
                  &nbsp;Exportar .xls
                </button>
              )}
            </div>
            <div>
              <button
                className="btn button-h35"
                style={{
                  backgroundColor: '#1d7391',
                  borderColor: '#1d7391',
                  color: 'white',
                  fontSize: '12px',
                }}
                onClick={() => setIsQuickDischargeModalOpen(true)}
              >
                Baixa Rápida de Títulos
              </button>
            </div>
            {!isPlanFree && !isPlanStart && <ConsultReceivableAmountBtn />}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
              padding: 0,
              gap: '10px',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial: </strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataInicial"
                style={{ fontSize: '12px' }}
                value={initialDate}
                onChange={(e) => handleChangeInitialDate(e.target.value)}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Final: </strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataFinal"
                style={{ fontSize: '12px' }}
                value={finalDate}
                onChange={(e) => handleChangeFinalDate(e.target.value)}
              />
            </div>
            <div>
              <span>
                <strong style={{ fontSize: '12px' }}>Plano de Contas:</strong>
              </span>
              <select
                className="form-control foco-input"
                style={{ fontSize: '12px' }}
                value={accountPlanId}
                onChange={(e) => setAccountPlanId(e.target.value)}
              >
                <option value="">Todos</option>
                {accountPlans.map(({ id, description }) => (
                  <option key={id} value={id}>
                    {description}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <span>
                <strong style={{ fontSize: '12px' }}>Caixa/Bancos: </strong>
              </span>
              <select
                className="form-control foco-input"
                style={{ fontSize: '12px' }}
                value={cashierBankId}
                onChange={(e) => setCashierBankId(e.target.value)}
              >
                <option value="">Todos</option>
                {cashierBank.map(
                  ({ id, description, CashierBankType: { initials } }) => (
                    <option key={id} value={id}>
                      {initials} - {description}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={billsToReceive}
              columns={[
                {
                  Header: 'Título',
                  accessor: (props) => Number(props.code),
                  id: 'code',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                  Cell: (props) => (
                    <>
                      <Link
                        id="linkVenda"
                        to={{
                          pathname: `bill-to-receive#${props?.original?.id}`,
                        }}
                        title="Clique para editar"
                      >
                        <button className="btn-link">{props?.value}</button>
                      </Link>
                    </>
                  ),
                },
                {
                  Header: 'Venda',
                  accessor: 'BillsToReceiveSales',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                  Cell: (props) => {
                    if (props.value.length > 0) {
                      return props.value.length > 1
                        ? 'Várias'
                        : props.value[0]?.Sales?.Code || '---';
                    } else {
                      return props.original.Sales?.Code || '---';
                    }
                  },
                },
                {
                  Header: 'Vencimento',
                  accessor: 'dueDate',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <span
                      style={{
                        color: isAfter(
                          new Date(),
                          addDays(getDateOnlyFromDate(props?.value), 1)
                        )
                          ? 'red'
                          : '',
                      }}
                    >
                      {format(getDateOnlyFromDate(props?.value), 'dd/MM/yyyy')}
                    </span>
                  ),
                },
                {
                  Header: 'Cliente',
                  accessor: 'Customer.Company_Name',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Valor em aberto',
                  accessor: 'openValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => <a>{currency(props?.value)}</a>,
                },
                {
                  Header: 'Juros',
                  accessor: 'monthlyInterestValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => <a>{currency(props?.value)}</a>,
                },
                {
                  Header: 'Valor Pago',
                  accessor: 'paidValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) =>
                    props?.value ? <a>{currency(props?.value)}</a> : '',
                },
                {
                  Header: 'Total',
                  accessor: 'liquidValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => <a>{currency(props?.value)}</a>,
                },
                {
                  Header: 'Status',
                  accessor: 'BillStatus.Description',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'Aberto'
                            ? '#ffcc00'
                            : props.value === 'Finalizado'
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {props?.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-center',
                  filterable: false,
                  className: 'texto',
                  width: 120,
                  style: { display: 'flex', justifyContent: 'center' },
                  Cell: (props) => (
                    <>
                      <Link
                        to={{ pathname: `bill-to-receive#${props?.value}` }}
                        title="Editar"
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          icon={faEdit}
                          style={{ width: '1.5em', height: '1.5em' }}
                        />
                      </Link>

                      <a
                        title={getBankSlipTooltip(
                          props?.original?.bank_slip_status_description
                        )}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFlowBills(props?.original);
                        }}
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          className={getColorClassByBankSlipStatus(
                            props?.original?.bank_slip_status_description
                          )}
                          icon={faFileInvoice}
                          style={{
                            width: '1.5em',
                            height: '1.5em',
                          }}
                        />
                      </a>
                      <a
                        title="Impressão"
                        onClick={() => setSelectedTitleToPrint(props?.original)}
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          onClick={() => setIsPrintModalOpen(true)}
                          icon={faPrint}
                          style={{ width: '1.5em', height: '1.5em' }}
                        />
                      </a>
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              pages={totalPages}
              onPageSizeChange={(value) => setPageLimit(value)}
              onPageChange={(value) => setPage(value)}
              manual
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'code',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma venda ou título foi encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          {!!isPrintModalOpen && (
            <BillsToReceivePrintModal
              selectedTitleToPrint={selectedTitleToPrint}
              isPrintModalOpen={isPrintModalOpen}
              setIsPrintModalOpen={setIsPrintModalOpen}
            />
          )}

          {isQuickDischargeModalOpen && (
            <TitleQuickDischargeModal
              initialValues={{ page: 1 }}
              billType="toReceive"
              onCancel={() => setIsQuickDischargeModalOpen(false)}
              loadTitles={getBillsToReceive}
            />
          )}

          {isBankSlipDisabledModalOpen && (
            <Modal
              show={isBankSlipDisabledModalOpen}
              onHide={() => setIsBankSlipDisabledModalOpen(false)}
              animation
              dialogClassName="modal-info-company"
              style={{ width: '50%', margin: '0 auto' }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>OS Digital</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'left' }}>
                <span>
                  <strong>Emissão de boleto não ativo para sua conta.</strong>
                </span>
                <br />
                <br />
                <span>
                  Se desejar emitir boletos pelo OS Digital, solicite o módulo
                  junto ao nosso departamento comercial, clicando no botão
                  abaixo.
                </span>
              </Modal.Body>
              <Modal.Footer>
                <div className="upgrade-modal-footer">
                  <CustomButton
                    bsStyle="danger"
                    fill
                    onClick={() => setIsBankSlipDisabledModalOpen(false)}
                  >
                    Voltar
                  </CustomButton>
                  <CustomButton
                    bsStyle="info"
                    fill
                    onClick={() =>
                      window.open(
                        'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20gostaria%20de%20saber%20mais%20sobre%20a%20emiss%C3%A3o%20de%20boleto%20no%20OS%20Digital'
                      )
                    }
                  >
                    <div>
                      <FontAwesomeIcon icon={faWhatsapp} />
                      <span>Contratar Emissão de Boletos</span>
                    </div>
                  </CustomButton>
                </div>
              </Modal.Footer>
            </Modal>
          )}

          {isBankSlipActionsModalOpen && (
            <Modal
              show={isBankSlipActionsModalOpen}
              onHide={() => handleCloseBankSlipActionsModal()}
              animation
              dialogClassName="modal-info-company"
              style={{ width: '50%', margin: '0 auto' }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>OS Digital</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'left' }}>
                <span>Clique abaixo na função desejada para o seu boleto:</span>
                <br />
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <CustomButton
                    bsStyle="info"
                    fill
                    style={{ width: '240px' }}
                    onClick={() => setIsGenBankSlipConfirmationModalOpen(true)}
                    disabled={
                      !!selectedTitleToBankSlipActions?.bankSlipId
                        ? true
                        : false
                    }
                  >
                    Criar Boleto
                  </CustomButton>
                  {/* // Botão retirado a pedido enquanto funcionalidade não existe */}
                  {/* <CustomButton
                    onClick={() => null}
                    bsStyle="primary"
                    fill
                    style={{ width: '240px' }}
                    disabled={
                      !selectedTitleToBankSlipActions?.bankSlipId
                        ? true
                        : false
                    }
                  >
                    Atualizar Boleto
                  </CustomButton> */}
                  <CustomButton
                    onClick={() =>
                      setIsCancelBankSlipConfirmationModalOpen(true)
                    }
                    bsStyle="danger"
                    fill
                    style={{ width: '240px' }}
                    disabled={
                      !selectedTitleToBankSlipActions?.bankSlipId ? true : false
                    }
                  >
                    Cancelar Boleto
                  </CustomButton>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                    }}
                  >
                    As alterações realizadas no Boleto Bancário não podem ser
                    revertidas
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="upgrade-modal-footer">
                  <CustomButton
                    bsStyle="danger"
                    fill
                    onClick={() => handleCloseBankSlipActionsModal()}
                  >
                    Voltar
                  </CustomButton>
                </div>
              </Modal.Footer>
            </Modal>
          )}

          {isGenBankSlipConfirmationModalOpen && (
            <Modal
              show={isGenBankSlipConfirmationModalOpen}
              onHide={() => setIsGenBankSlipConfirmationModalOpen(false)}
              animation
              dialogClassName="modal-info-company"
              style={{ width: '50%', margin: '0 auto' }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>OS Digital</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'left' }}>
                <span>
                  <strong>
                    Você deseja gerar um boleto bancário para esse título?
                  </strong>
                </span>
                <br />
                <br />
                <span>
                  O boleto é gerado com todo valor do título. Caso tenha algum
                  valor pago, será excluído e gerado do valor total do título.
                </span>
                <br />
                <br />
                <div
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  Gerar um novo boleto poderá acarretar em cobranças em seu
                  banco e na sua conta de boletos no OS Digital.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="upgrade-modal-footer">
                  <CustomButton
                    bsStyle="danger"
                    fill
                    onClick={() => setIsGenBankSlipConfirmationModalOpen(false)}
                  >
                    Não
                  </CustomButton>
                  &nbsp;
                  <CustomButton
                    bsStyle="info"
                    fill
                    onClick={() => {
                      setIsGenBankSlipConfirmationModalOpen(false);
                      setIsGenerateBankSlipModalOpen(true);
                    }}
                  >
                    Sim
                  </CustomButton>
                </div>
              </Modal.Footer>
            </Modal>
          )}

          {isGenerateBankSlipModalOpen && (
            <Modal
              show={isGenerateBankSlipModalOpen}
              onHide={
                !processingBankSlip
                  ? () => {
                      setPaymentFormToBankSlip(null);
                      setIsGenerateBankSlipModalOpen(false);
                    }
                  : null
              }
              animation
              dialogClassName="modal-info-company"
              style={{ width: '50%', margin: '0 auto' }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>OS Digital</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'left' }}>
                <span>
                  <strong>
                    Confirme os dados e selecione uma carteira para o boleto:
                  </strong>
                </span>
                <br />
                <br />
                <span>
                  <strong>Título:</strong>&nbsp;
                  {selectedTitleToBankSlipActions.code}
                  <br />
                  <strong>Valor:</strong>&nbsp;
                  {currency(selectedTitleToBankSlipActions.liquidValue)} <br />
                  <strong>Vencimento:</strong>&nbsp;
                  {format(
                    getDateOnlyFromDate(
                      selectedTitleToBankSlipActions?.dueDate
                    ),
                    'dd/MM/yyyy'
                  )}
                </span>
                <br />
                <br />
                <span>Selecione a forma de pagamento:</span>
                <div>
                  <select
                    className="form-control foco-input"
                    value={paymentFormToBankSlip}
                    onChange={(e) => setPaymentFormToBankSlip(e.target.value)}
                    style={{ width: '300px' }}
                  >
                    <option value="">Selecione</option>
                    {formOfPaymentOptions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.Desciption}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <br />
                <div
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  O boleto gerado é encaminhado para o e-mail do cliente.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="upgrade-modal-footer">
                  <CustomButton
                    bsStyle="danger"
                    fill
                    disabled={processingBankSlip}
                    onClick={() => {
                      setPaymentFormToBankSlip(null);
                      setIsGenerateBankSlipModalOpen(false);
                    }}
                  >
                    Voltar
                  </CustomButton>
                  &nbsp;
                  <CustomButton
                    bsStyle="info"
                    fill
                    onClick={() => handleEmitBankSlip()}
                    disabled={processingBankSlip}
                  >
                    <span
                      className={
                        processingBankSlip
                          ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                          : 'update-spinner-disabled'
                      }
                    />
                    Emitir Boleto
                  </CustomButton>
                </div>
              </Modal.Footer>
            </Modal>
          )}

          {isRegistrationMethodModalOpen && (
            <RegistrationMethodModal
              isOpen={isRegistrationMethodModalOpen}
              handleClose={() => setRegistrationMethodModalOpen(false)}
              handleOpenSalesSelectionModal={() =>
                setIsGroupTitlesModalOpen(true)
              }
            />
          )}

          {isGroupTitlesModalOpen && (
            <GroupTitlesModal
              isOpen={isGroupTitlesModalOpen}
              handleClose={() => setIsGroupTitlesModalOpen(false)}
              handleTriggerLoadList={handleTriggerLoadList}
              history={history}
            />
          )}

          {isCancelBankSlipConfirmationModalOpen && (
            <Modal
              show={isCancelBankSlipConfirmationModalOpen}
              onHide={
                !processingBankSlip
                  ? () => setIsCancelBankSlipConfirmationModalOpen(false)
                  : null
              }
              animation
              dialogClassName="modal-info-company"
              style={{ width: '50%', margin: '0 auto' }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>OS Digital</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'left' }}>
                <span>
                  <strong>
                    Você tem certeza que deseja cancelar o boleto?
                  </strong>
                </span>
                <br />
                <br />
                <span>
                  O processo de cancelamento de boleto é irreversível e será
                  necessário gerar um novo boleto para esse título.
                </span>
                <br />
                <br />
                <div
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  Gerar um novo boleto poderá acarretar em cobranças em seu
                  banco e na sua conta de boletos no OS Digital.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="upgrade-modal-footer">
                  <CustomButton
                    bsStyle="danger"
                    fill
                    disabled={processingBankSlip}
                    onClick={() =>
                      setIsCancelBankSlipConfirmationModalOpen(false)
                    }
                  >
                    Não
                  </CustomButton>
                  &nbsp;
                  <CustomButton
                    bsStyle="info"
                    disabled={processingBankSlip}
                    fill
                    onClick={() => {
                      handleCancelBankSlip();
                    }}
                  >
                    <span
                      className={
                        processingBankSlip
                          ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                          : 'update-spinner-disabled'
                      }
                    />
                    Sim
                  </CustomButton>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      }
    />
  );
};

export default withRouter(HomeBillsToReceive);
