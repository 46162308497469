import React from 'react';
import { HeaderContainer, Title } from './Header.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { OptionSelector } from '../OptionSelector';

export function Header() {
  return (
    <HeaderContainer>
      <Title>
        Indicadores Gerenciais &nbsp;&nbsp;
        <FontAwesomeIcon
          icon={faChartColumn}
          style={{ color: '#1D7391', height: '20px' }}
        />
      </Title>
      <OptionSelector />
    </HeaderContainer>
  );
}
