const onBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = '';
};

export const handleBeforeUnload = () => {
  window.addEventListener('beforeunload', onBeforeUnload, { capture: true });

  return () => {
    window.removeEventListener('beforeunload', onBeforeUnload);
  };
};
