const xlsColumns = [
  {
    name: 'CPF/CNPJ',
    acessor: 'customerCpfCnpj',
  },
  {
    name: 'Tipo de Cliente',
    acessor: 'customerType',
  },
  {
    name: 'Nome/Razão Social',
    acessor: 'customerName',
  },
  {
    name: 'Telefone',
    acessor: 'customerPhone',
  },
  {
    name: 'Cidade',
    acessor: 'customerCity',
  },
  {
    name: 'Estado',
    acessor: 'customerState',
  },
  {
    name: 'E-mail',
    acessor: 'customerEmail',
  },
  {
    name: 'Data de Cadastro',
    acessor: 'formatedDate',
  },
  {
    name: 'Status',
    acessor: 'customerStatus',
  },
]

export default xlsColumns;