import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from './Form';
import { toastr } from 'react-redux-toastr';
import NFesRepository from '../../../../repositories/NFes';
import NFCesRepository from '../../../../repositories/NFCes';
import { ManySalesCancelInvoice } from './ManySalesCancelInvoice';

export default function DisableInvoiceModal({
  onHide,
  onCancel,
  onSuccess,
  show,
  nfceSelected,
  hasManySales,
  isManySaleHasNFe,
}) {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  async function handleSubmit(values) {
    setLoading(true);
    try {
      await validateAndSubmit(values);
    } finally {
      setLoading(false);
    }
  }

  async function validateAndSubmit(values) {
    const { justification } = values;

    if (!justification) {
      return toastr.warning('Preencha o campo de justificativa');
    }

    if (justification.length < 15) {
      return toastr.warning(
        'O campo de justificativa deve ter ao menos 15 caracteres.'
      );
    }

    if (nfceSelected?.type === 'NFC-e') {
      await submitNFCe({ nfceId: nfceSelected.id, justification });
      return;
    }

    if (hasManySales || currentStep === 1) {
      setCurrentStep(2);

      return;
    }

    return await submitNFe({
      nfeId: nfceSelected.id,
      justification,
    });
  }

  async function submitNFCe(params) {
    try {
      await NFCesRepository.cancel(params);
      toastr.success('Nota cancelada com sucesso.');
      onSuccess();
    } catch (err) {
      handleError(err, 'Prazo para cancelamento expirado.');
    }
  }

  async function submitNFe(params) {
    try {
      await NFesRepository.cancel(params);
      toastr.success('Nota cancelada com sucesso.');
      onSuccess();
    } catch (err) {
      handleError(err, 'Erro ao cancelar nota.');
    }
  }

  function handleError(err, defaultMessage) {
    console.error(err);
    const message = err.response?.data?.message || defaultMessage;
    toastr.error(message);
  }

  function renderStep() {
    if (!hasManySales || currentStep === 1) {
      return (
        <Form
          initialValues={{ companyId: localStorage.getItem('ID_EMPRESA') }}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          loading={loading}
          nfceSelected={nfceSelected}
        />
      );
    }

    return (
      <ManySalesCancelInvoice
        nfeId={nfceSelected?.id}
        submitNFe={submitNFe}
        loading={loading}
        onSuccess={onSuccess}
        isManySaleHasNFe={isManySaleHasNFe}
        backCurrentStep={() => setCurrentStep(1)}
      />
    );
  }

  return (
    <Modal show={show} onHide={onHide} animation>
      <div>{renderStep()}</div>
    </Modal>
  );
}
