import config from '../config'
import api from "../services/api"

const reportOneBySale = saleId => api.get(config.endpoint + `sale-return/report/${saleId}`)

const reportByPeriod = queryParams => api.get(config.endpoint + 'sale-return/report-from-period', {
  params: queryParams
})

export default {
  reportOneBySale,
  reportByPeriod,
}