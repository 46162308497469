import { currency, percentage } from 'client/components/ToNormalize/ToNormalize'
import React from 'react'
import ReactTable from 'react-table'

function ProductsCurveTable({ loading, products }) {
  return (
    <ReactTable
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '20px',
      }}
      data={products}
      columns={[
        {
          Header: 'Código',
          accessor: 'Code',
          width: 70,
        },
        {
          Header: 'Ref. Fabricante',
          accessor: 'Manufacturer_Ref',
          width: 120,
        },
        {
          Header: 'Descrição',
          accessor: 'Description',
        },
        {
          Header: 'Vlr. Unit.',
          accessor: 'Unit_Value',
          width: 130,
          Cell: (cell) => currency(cell.value),
        },
        {
          Header: 'Qtd. Vendida',
          accessor: 'TotalQuantity',
          width: 100,
        },
        {
          Header: 'Valor Total',
          accessor: 'TotalAmount',
          width: 135,
          Cell: (cell) => currency(cell.value),
        },
        {
          Header: 'Participação(%)',
          accessor: 'participation',
          width: 120,
          Cell: (cell) => percentage(cell.value),
        },
        {
          Header: '% Acumulada',
          accessor: 'cumulative',
          width: 120,
          Cell: (cell) => percentage(cell.value),
        },
        {
          Header: 'Curva',
          accessor: 'curve',
          width: 130,
          Cell: (props) => (
            <div
              style={{
                width: 'full',
                height: 'full',
                backgroundColor:
                  props.value === 'A'
                    ? '#5cb85c'
                    : props.value === 'B'
                    ? '#f0ad4e'
                    : '#d9534f',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {props.value}
            </div>
          ),
        },
      ]}
      defaultPageSize={10}
      loading={loading}
      showPagination
      sortable
      showPaginationTop={false}
      showPaginationBottom
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Insira um período para gerar o relatório."
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  )
}

export default ProductsCurveTable
