import React, { useEffect, useState } from 'react'
import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import { PDFViewer } from '@react-pdf/renderer'

import customerHistoryRepository from '../../repositories/CustomerHistory'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import { format, addDays } from 'date-fns'

import { FinancialStatementPDF } from './FinancialStatementPDF'

export const FinancialStatement = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [sales, setSales] = useState([])
  const [titles, setTitles] = useState([])
  const [valuesByVehicle, setValuesByVehicle] = useState([])
  const [customer, setCustomer] = useState({})
  const [initialDateQuery, setInitialDateQuery] = useState('')
  const [finalDateQuery, setFinalDateQuery] = useState('')
  const [saleId, setSaleId] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const saleIdHash = searchParams.get('saleId')

      let saleId
      if (saleIdHash) {
        saleId = Number(await decrypt(saleIdHash, '@OS-dig:saleId'))
        setSaleId(saleId)
      }

      if (!!saleId) return loadSheetBySaleId(saleId)

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const customerIdHash = searchParams.get('customerId')
      const vehicleIdHash = searchParams.get('vehicleId')

      setInitialDateQuery(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDateQuery(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      let customerId, vehicleId

      if (customerIdHash) {
        customerId = Number(await decrypt(customerIdHash, '@OS-dig:customerId'))
      }

      if (vehicleIdHash) {
        vehicleId = Number(await decrypt(vehicleIdHash, '@OS-dig:vehicleId'))
      }

      return loadSheetByCustomerIdAndDateRange(customerId, { initialDate, finalDate, vehicleId })
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadSheetByCustomerIdAndDateRange = async (customerId, queryParams) => {
    try {
      const { data } = await customerHistoryRepository.getCompleteCustomerHistory(customerId, queryParams)
      setSales(data.sales)
      setTitles(data.titles)
      setValuesByVehicle(data.valuesByVehicle)
      setCustomer(data.customer)
    } catch (err) {
      console.log(err.response)
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  const loadSheetBySaleId = async saleId => {
    try {
      const { data } = await customerHistoryRepository.getCompleteCustomerHistoryBySaleId(saleId)
      setSales([data.sale])
      setInitialDateQuery(format(new Date(data.sale.Date), 'dd/MM/yyyy'))
      setFinalDateQuery(format(new Date(data.sale.Date), 'dd/MM/yyyy'))
      setValuesByVehicle(data.valuesByVehicle)
      setCustomer(data.customer)
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <FinancialStatementPDF
            sales={sales}
            customer={customer}
            valuesByVehicle={valuesByVehicle}
            initialDate={initialDateQuery}
            finalDate={finalDateQuery}
            titles={titles}
            saleId={saleId}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
