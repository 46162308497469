import React from 'react';
import { Modal } from 'react-bootstrap';
import VehicleForm from '../../Cadastros/Vehicle';

const FormModalVehicle = ({
  onCancel,
  vehicleId,
  customerId,
  companyId,
  onSubmit,
  saleId,
  setSelectedVehicleType,
}) => {
  return (
    <Modal show onHide={onCancel} animation dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>
            {!vehicleId
              ? 'Cadastrar Veículo/Obj. de Manutenção'
              : 'Editar Veículo/Obj. de Manutenção'}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VehicleForm
          customerId={customerId}
          vehicleId={vehicleId}
          companyId={companyId}
          onCancel={onCancel}
          onSubmit={onSubmit}
          saleId={saleId}
          setSelectedVehicleType={setSelectedVehicleType}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FormModalVehicle;
