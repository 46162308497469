import React from 'react';
import { reduxForm } from 'redux-form';

import CardForm from '../../../../client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import PrismaBoxData from './PrismaBoxData';

import './styles.css';

const FormPrismabox = ({ handleSubmit, onCancel, loading, prismaboxId }) => {
  return (
    <form
      onSubmit={handleSubmit}
      onCancel={onCancel}
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm title="Dados básicos">
        <PrismaBoxData prismaboxId={prismaboxId} />
      </CardForm>

      <FormSubmitButtons
        loading={loading}
        onCancel={onCancel}
        hasId={!!prismaboxId}
        onSubmit={handleSubmit}
      />
    </form>
  );
};

export default reduxForm({
  form: 'prismabox',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormPrismabox);
