import React, { useState, useEffect } from 'react'

import { Modal } from 'react-bootstrap'

import { useAuth } from 'contexts/auth'

import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { change, reduxForm } from 'redux-form'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import AlertModal from 'components/AlertModal'

import StepWizard from './StepWizard'
import TitleSelector from './pages/TitleSelector'
import TitleDischarger from './pages/TitleDischarger'

import titleQuickDischargeRepository from 'repositories/TitleQuickDischarge'
import accountPlansRepository from 'repositories/AccountPlans'

import './styles.css'

const TitleQuickDischargeModal = ({ billType, onCancel, loadTitles }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isDischargeModalOpen, setIsDischargeModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [everyBillHavePaymentFormAndDate, setEveryBillHavePaymentFormAndDate] =
    useState()

  const [accountPlans, setAccountPlans] = useState([])
  const [debitAccountPlans, setDebitAccountPlans] = useState([])

  const { companyId } = useAuth()

  const {
    page,
    filteredBills = [],
    selectedTitles = [],
  } = useSelector((state) => state.form.titleQuickDischarge.values)

  useEffect(() => {
    loadAccountPlans()
  }, [])

  useEffect(() => {
    const everyBillHavePaymentForm = selectedTitles.every(
      (bill) => bill.paymentFormId && bill.lowDate
    )

    setEveryBillHavePaymentFormAndDate(everyBillHavePaymentForm)
  }, [JSON.stringify(selectedTitles)])

  const dispatch = useDispatch()

  const nextPage = () => {
    dispatch(change('titleQuickDischarge', 'page', page + 1))
  }

  const previousPage = () => {
    dispatch(change('titleQuickDischarge', 'page', page - 1))
  }

  const handleCloseModal = () => {
    if (!filteredBills.length) return onCancel()
    setIsCancelModalOpen(true)
  }

  const dischargeTitles = async () => {
    setLoading(true)
    setIsDischargeModalOpen(false)

    try {
      await titleQuickDischargeRepository.update({
        bills: selectedTitles,
        type: billType,
        companyId,
      })

      toastr.success('Títulos baixados com sucesso')
      loadTitles()
      onCancel()
    } catch (err) {
      console.error(err)
      toastr.error(
        'Ocorreu um erro ao executar a baixa dos títulos. Por favor, tente novamente mais tarde.'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleDischargeTitlesButton = () => {
    if (!everyBillHavePaymentFormAndDate)
      return toastr.warning(
        'Aplique forma de pagamento e a data em todos os títulos para realizar a baixa.'
      )

    setIsDischargeModalOpen(true)
  }

  const loadAccountPlans = async () => {
    try {
      const { data: credit } =
        await accountPlansRepository.getAccountPlanByType(companyId, 'Credito')

      const { data: debit } = await accountPlansRepository.getAccountPlanByType(
        companyId,
        'Debito'
      )

      setAccountPlans(credit)
      setDebitAccountPlans(debit)
    } catch (err) {
      console.error(err)
      toastr.warning('Ocorreu um erro ao carregar os planos de contas.')
    }
  }

  return (
    <>
      <Modal show dialogClassName="modal-90w" onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <strong>Baixa Rápida de Títulos</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <main className="title-quick-discharge">
            <section className="step-wizard-wrapper">
              <StepWizard percent={page === 1 ? 1 : 100} />
            </section>
            {page === 1 && (
              <TitleSelector
                billType={billType}
                accountPlans={accountPlans}
                debitAccountPlans={debitAccountPlans}
              />
            )}
            {page === 2 && (
              <TitleDischarger
                billType={billType}
                previousPage={previousPage}
                accountPlans={accountPlans}
                debitAccountPlans={debitAccountPlans}
              />
            )}
          </main>
        </Modal.Body>
        <Modal.Footer>
          {page === 1 ? (
            <>
              <Button bsStyle="danger" fill onClick={handleCloseModal}>
                Cancelar
              </Button>
              <Button
                bsStyle="info"
                fill
                onClick={nextPage}
                disabled={!selectedTitles.length}
              >
                Avançar
              </Button>
            </>
          ) : (
            <>
              <Button
                bsStyle="danger"
                fill
                onClick={previousPage}
                disabled={loading}
              >
                Voltar
              </Button>
              <Button bsStyle="info" fill onClick={handleDischargeTitlesButton}>
                <span
                  className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
                />
                Baixar títulos
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
        title="O.S Digital"
        subtitle="Você deseja fechar a tela de baixa rápida?"
        message="Toda filtragem e seleção de títulos será descartada, sendo necessário selecionar novamente."
      />
      <AlertModal
        show={isDischargeModalOpen}
        onHide={() => setIsDischargeModalOpen(false)}
        onCancel={() => setIsDischargeModalOpen(false)}
        onSubmit={dischargeTitles}
        subtitle="Você tem certeza que quer baixar todos os títulos selecionados?"
        message="A baixa rápida é um processo irreversível que baixará todos os títulos selecionados de maneira automática. Se houver dúvidas, confirme novamente antes da baixa"
      />
    </>
  )
}

export default reduxForm({
  form: 'titleQuickDischarge',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(TitleQuickDischargeModal)
