import React from 'react';
import { formatToDate } from 'brazilian-values';
import { Modal } from 'react-bootstrap';

import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { useBonus } from 'hooks/useBonus';
import { useEffect, useState } from 'react';

export const CRMBonusPINValidationModal = ({
  isOpen,
  handleClose,
  handleContinueWithoutBonus,
  handleValidatePIN,
  handleResentPIN,
  customer,
}) => {
  const continueWithoutBonus = () => {
    handleContinueWithoutBonus();
    handleClose();
  };

  const [isLoading, setIsLoading] = useState(false);

  const {
    Company_Name: customerName,
    Email: customerEmail,
    Date_Birth: customerDateOfBirth,
  } = customer;

  const {
    handleInitCRMBonus,
    crmCustomerPIN,
    setCRMCustomerPIN,
    handleConsultAvailableBonus,
  } = useBonus();
  const customerCell = customer.Phones[0].Number_Phone2;

  const handleChangePIN = (value) => {
    setCRMCustomerPIN(value);
  };

  const handleInit = async () => {
    try {
      await handleInitCRMBonus(customer);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleValidatePINButton = async () => {
    try {
      setIsLoading(true);
      await handleValidatePIN();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => handleInit(), [2000]);
  }, [customer?.id]);

  return (
    <Modal.Dialog open={isOpen}>
      <Modal.Header>
        <Modal.Title>
          <strong>CRM Bônus</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <span className={'fa fa-spinner fa-pulse fa-1x update-spinner'} />
          </div>
        ) : (
          <div>
            <strong>
              Informe o pin abaixo para verificar se o cliente possui bônus:
            </strong>
            <br />
            <br />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <strong>Nome: </strong>
                <span>{customerName ? `${customerName}` : 'Nome'}</span>
              </div>
              <div>
                <strong>Email: </strong>
                <span>
                  {customerEmail ? `${customerEmail}` : 'email@email.com'}
                </span>
              </div>
              <div>
                <strong>Celular: </strong>
                <span>
                  {customerCell
                    ? `${phoneMask(customerCell)}`
                    : '(99) 9-9999-9999'}
                </span>
              </div>
              <div>
                <strong>Nascimento: </strong>
                <span>
                  {customerDateOfBirth
                    ? `${formatToDate(new Date(customerDateOfBirth))}`
                    : 'dd/mm/aaaa'}
                </span>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  placeholder="1234"
                  value={crmCustomerPIN}
                  onChange={(event) => handleChangePIN(event.target.value)}
                  style={{
                    textAlign: 'center',
                    width: '100px',
                  }}
                ></input>
                <button
                  className="btn-link"
                  type="button"
                  onClick={handleResentPIN}
                >
                  Reenviar Pin
                </button>
              </div>
              <button
                style={{ width: '200px' }}
                className={'button button-green'}
                onClick={handleValidatePINButton}
                disabled={isLoading}
              >
                <span
                  className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                />
                Validar Pin
              </button>
              <span style={{ color: 'red', fontSize: '12px' }}>
                Caso o cliente não esteja com o celular agora, contate o suporte
                CRM Bônus para que possa disponibilizar o bônus manualmente.
              </span>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isLoading && (
          <div className="flex end gap-050">
            <button className="button button-red w-150px" onClick={handleClose}>
              Voltar
            </button>
            <button
              className="button button-blue w-150px"
              onClick={continueWithoutBonus}
            >
              Continuar sem bônus
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal.Dialog>
  );
};
