import React from 'react';

import { Grid, Row } from 'react-bootstrap';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import Main from './components/Main';

export function Hangtag({ match, history }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Etiqueta']}
            path={['home', 'products', null]}
          />
          <span style={{ color: 'red', position: 'absolute', right: 10 }}>
            * Campos Obrigatórios
          </span>
          <Main history={history} />
        </Row>
      </Grid>
    </div>
  );
}
