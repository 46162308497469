import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import Button from 'client/components/CustomButton/CustomButton.jsx'
import ClientActions from './ClientActions'
import ClientData from './ClientData'

import adminsRepository from 'repositories/Admins'

export default function ViewClientModal({
  isOpen,
  handleClose,
  handleReloadClients,
  clientId,
}) {
  const [clientInfo, setClientInfo] = useState({})
  const [reload, setReload] = useState(true)

  async function loadClientInformation() {
    try {
      const data = await adminsRepository.getClientFullInformation(clientId)
      setClientInfo(data.client)
    } catch (err) {
      console.log(err)
      toastr.error('Erro ao carregar o cliente', 'Tente novamente!')
    }
  }

  useEffect(() => {
    if (clientId) {
      loadClientInformation()
    }
  }, [clientId, reload])

  function handleCloseModal() {
    handleReloadClients()
    handleClose()
  }

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      animation
      dialogClassName="modal-info-company"
    >
      <Modal.Header closeButton style={{ padding: '7px' }}></Modal.Header>
      <Modal.Body>
        <ClientData reload={reload} setReload={setReload} clientId={clientId} />
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-buttons">
          <ClientActions client={clientInfo} setReload={setReload} />
          <Button bsStyle="danger" name="Não" onClick={handleCloseModal} fill>
            Fechar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
