import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import { IssuedSlipDocument } from './IssuedSlipDocument';
import { addDays, format } from 'date-fns';
import issuedSlipRepository from 'repositories/IssuedSlip';

const IssuedSlipReport = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [issuedList, setIssuedList] = useState([]);
  const [params, setParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId');
      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'));
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'));

      const typeOfDate = searchParams.get('typeOfDate');
      const statusSlip = searchParams.get('statusSlip');

      setHasError(false);

      return loadReport({
        companyId,
        initialDate,
        finalDate,
        typeOfDate,
        statusSlip,
      });
    } catch (err) {
      console.log(err);
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (params) => {
    setLoading(true);
    try {
      const data = await issuedSlipRepository.generateIssuedSlip(params);

      setParams(params);
      setIssuedList(data);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <IssuedSlipDocument
            initialDate={initialDate}
            finalDate={finalDate}
            typeOfDate={params.typeOfDate}
            statusSlip={params.statusSlip}
            result={issuedList}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default IssuedSlipReport;
