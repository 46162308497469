import React, { useState } from "react";
import { reduxForm } from "redux-form";

import CardForm from "../../../../components/CardForm";
import AlertModal from "../../../../../components/AlertModal/AlertModal";
import FormSubmitButtons from "../../../../components/FormSubmitButtons";
import DadosCadastrais from "./DadosCadastrais";

function FormNewIntermediary({
  onCancel,
  handleSubmit,
  loading,
  intermediaryId,
  pristine,
}) {
  const [modal, setModal] = useState(false);

  const handleCancelModal = () => {
    if (pristine) return onCancel();
    setModal(true);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}
    >
      <CardForm show title="Dados do Intermediador" onClick={() => {}}>
        <DadosCadastrais />
      </CardForm>
      <FormSubmitButtons
        onCancel={handleCancelModal}
        onSubmit={handleSubmit}
        loading={loading}
        hasId={!!intermediaryId}
      />
      {modal && (
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message={`Deseja cancelar ${
            intermediaryId ? "a edição" : "o cadastro"
          } do intermediador? Todos os campos preenchidos não serão salvos e ${
            intermediaryId ? "a edição" : "o cadastro"
          } poderá ser realizad${intermediaryId ? "a" : "o"} posteriormente`}
          onSubmit={onCancel}
        />
      )}
    </form>
  );
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: "formIntermediary",
})(FormNewIntermediary);
