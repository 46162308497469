import * as yup from 'yup';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';
import { listSalesSubtype } from 'v2/repositories/SalesSubtypeRepository';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Vendas',
      'Subtipo de Venda',
      `${isEditing ? 'Editar' : 'Novo'} Subtipo`,
    ],
    path: [
      '/client/home',
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.SALE_SUBTYPE.BASE,
      null,
    ],
  };
};

export const validateSalesSubtypeDescriptionUse = async (
  description,
  companyId,
  saleSubtypeId
) => {
  try {
    const saleSubtypes = await listSalesSubtype({
      query: description,
      companyId,
    });

    const salesSubtypeWithSameDescription = saleSubtypes.rows.find(
      (saleSubtypes) =>
        saleSubtypes.description.toUpperCase() === description.toUpperCase() &&
        (saleSubtypeId ? +saleSubtypes.id !== +saleSubtypeId : true)
    );

    if (salesSubtypeWithSameDescription) {
      return false;
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export const defaultFormValues = {
  id: null,
  description: '',
  observations: '',
  isActive: true,
  generateTitleAfterCloseSale: false,
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  generateTitleAfterCloseSale: yup.boolean().required(),
  observations: yup.string().notRequired(),
});
