import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import billsToReceiveRepository from 'repositories/BillsToReceive';
import AccountsReceivableSyntheticPDF from './AccountsReceivableSynthetic';
import customersRepository from 'repositories/Customers';
import cashierBanksRepository from 'repositories/CashierBank';

const AccountsReceivableSyntheticReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [dataAccount, setDataAccount] = useState([]);
  const [reportType, setReportType] = useState('');
  const [titleStatus, setTitleStatus] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [dateType, setDateType] = useState('');
  const [customer, setCustomer] = useState('');
  const [includeCardFee, setIncludeCardFee] = useState(true);
  const [cashierBank, setCashierBank] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyIdResponseIdHash = searchParams.get('companyId');
      let companyId;

      if (companyIdResponseIdHash) {
        companyId = await decrypt(companyIdResponseIdHash, '@OS-dig:companyId');
      }

      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const customerId = searchParams.get('costumerIdAux');
      const dateType = searchParams.get('dateType');
      const titleStatus = searchParams.get('titleStatus');
      const cashierBankId = searchParams.get('cashierBanksValue');
      const reportType = searchParams.get('reportType');
      const includeCardFee = searchParams.get('includeCardFee');

      if (customerId) {
        try {
          const infoCustomer = await customersRepository.getCustomer(
            customerId
          );
          const { Company_Name, Trading_Name } = infoCustomer;
          const descriptionCustomer = Company_Name || Trading_Name;

          if (descriptionCustomer.length > 15) {
            setCustomer(`${descriptionCustomer.slice(0, 15)}...`);
          } else {
            setCustomer(descriptionCustomer);
          }
        } catch (error) {
          console.error('Erro ao obter informações do cliente:', error);
          setCustomer('Todos');
        }
      } else {
        setCustomer('Todos');
      }

      if (cashierBankId) {
        try {
          const infoCashierBank =
            await cashierBanksRepository.getCashierBanksById(cashierBankId);
          const { description } = infoCashierBank;

          setCashierBank(description);
        } catch (error) {
          console.error('Erro ao obter informações do caixa/banco:', error);
          setCashierBank('Todos');
        }
      } else {
        setCashierBank('Todos');
      }

      setInitialDate(initialDate);
      setFinalDate(finalDate);
      setDateType(dateType);
      setReportType(reportType);
      setTitleStatus(titleStatus);
      setIncludeCardFee(includeCardFee);

      const data = await loadReport(
        companyId,
        initialDate,
        finalDate,
        customerId,
        dateType,
        titleStatus,
        cashierBankId,
        includeCardFee
      );

      setDataAccount(data);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    customerId,
    dateType,
    titleStatus,
    cashierBankId,
    includeCardFee
  ) => {
    try {
      const data =
        await billsToReceiveRepository.generateBillsReceivedsTitleSynthetic({
          companyId,
          initialDate,
          finalDate,
          customerId,
          dateType,
          titleStatus,
          cashierBankId,
          includeCardFee,
        });
      return data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );
  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <AccountsReceivableSyntheticPDF
            reportType={reportType}
            titleStatus={titleStatus}
            customer={customer}
            includeCardFee={includeCardFee}
            cashierBank={cashierBank}
            responseDate={dataAccount}
            dateType={dateType}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default AccountsReceivableSyntheticReport;
