import config from '../config'
import api from '../services/api'

const show = async (companyId) => {
  const response = await api.get(
    config.endpoint + `company/${companyId}/configuration`
  )

  return response.data
}

const changeAd = async (companyId, advertisementId) => {
  const response = await api.put(
    config.endpoint + `company/${companyId}/configuration-ad`,
    {
      advertisementId,
    }
  )

  return response.data
}

const changeGlobalAdStatus = async (companyId, status) => {
  const response = await api.put(
    config.endpoint + `company/${companyId}/config/global-status`,
    {
      status,
    }
  )

  return response.data
}

export default {
  show,
  changeAd,
  changeGlobalAdStatus,
}
