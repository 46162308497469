import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { subHours, format, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { ServiceStatusLabel } from '../../PanelAppointmentModal.styles';
import { getStatusIndicatorColor } from '../../../../utils';
import { MaintenanceServicesRepository } from 'v2/repositories/MaintenanceServicesRepository';
import index from 'axios-middleware';
import { usePanelAppointmentStore } from '../../../../store';

const tableStyles = {
  fontWeight: 'bold',
  textAlign: 'center',
  height: '203px',
  fontSize: '14px',
  width: '100%',
};

export function ServicesInProgress({ maintenance, mode }) {
  const { tempAppointments, setTempAppointments } = usePanelAppointmentStore();
  const [loadingHistory, setLoadingHistory] = useState(true);

  const servicesStatusToFilter = ['Em Execução'];

  const [services, setServices] = useState([]);

  const allServicesSelected = services.every((service) => service.selected);

  const handleChangeStopReson = (value, index) => {
    const servicesUpdated = services.map((service, i) =>
      i === index ? { ...service, stopReason: value } : service
    );

    setServices(servicesUpdated);

    setTimeout(() => {
      document.getElementById(`input-stop-reason__${index}`).focus();
    }, 30);
  };

  const servicesColumns = [
    {
      Header: 'Funcionário',
      accessor: 'employeeName',
      className: 'texto',
      resizable: false,
    },
    {
      Header: 'Serviço',
      accessor: 'description',
      className: 'texto',
      resizable: false,
    },
    ...(mode === 'stop-service'
      ? [
          {
            Header: 'Motivo da Pausa',
            accessor: 'stopReason',
            className: 'texto',
            resizable: false,
            Cell: (props) => {
              return (
                <input
                  key={`input-stop-reason__${props.index}`}
                  id={`input-stop-reason__${props.index}`}
                  className="form-control focus-input"
                  type="text"
                  disabled={!props.original.selected}
                  value={props.value}
                  onChange={(e) =>
                    handleChangeStopReson(e.target.value, props.index)
                  }
                />
              );
            },
          },
        ]
      : []),
    {
      width: 150,
      Header: 'Status',
      accessor: 'status',
      resizable: false,
      Cell: (props) => {
        return (
          <ServiceStatusLabel
            bg={getStatusIndicatorColor(
              props.original.status === 'Em Execução' ? 'E' : 'P'
            )}
          >
            {props.value}
          </ServiceStatusLabel>
        );
      },
    },
    {
      accessor: 'id',
      className: 'texto',
      resizable: false,
      width: 70,
      Cell: (props) => {
        return (
          <>
            <input
              type="checkbox"
              id={`services.${props.index}.id`}
              className="checkbox-input bulk-deletion-table"
              checked={services[props.index].selected}
              onChange={() => handleSelectService(props.original.id)}
            />
          </>
        );
      },
    },
  ];

  async function loadServicesHistory() {
    setLoadingHistory(true);
    try {
      const res = await MaintenanceServicesRepository.getHistory(
        maintenance.maintenanceId
      );

      setServices(
        res.data
          .filter((service) => servicesStatusToFilter.includes(service.status))
          .map((service) => ({
            ...service,
            selected: tempAppointments.some(
              (appointment) =>
                appointment.id === service.id && appointment.selected
            ),
            stopReason: '',
          }))
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar o histórico de serviços',
        'Por favor, tente novamente.'
      );
    }
    setLoadingHistory(false);
  }

  const handleSelectAllServicesClick = () => {
    const selectedValue = !allServicesSelected;

    setServices(
      services.map((service) => ({
        ...service,
        selected: selectedValue,
      }))
    );
  };

  const handleSelectService = (serviceId) => {
    setServices(
      services.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            selected: !service.selected,
            stopReason: !service.selected ? service.stopReason : '',
          };
        }
        return service;
      })
    );
  };

  const clearAllSelections = () => {
    setServices(
      services.map((service) => ({
        ...service,
        selected: false,
        stopReason: '',
      }))
    );
  };

  useEffect(() => {
    setTempAppointments(services.filter((service) => service.selected));
  }, [services]);

  useEffect(() => {
    loadServicesHistory();
  }, []);

  return (
    <div>
      <span>Selecione o(s) serviço(s):</span>
      <div style={{ textAlign: 'right' }}>
        <a
          className="hyperlink"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleSelectAllServicesClick();
          }}
        >
          {!allServicesSelected ? 'Selecionar ' : 'Remover '} todos
        </a>
      </div>
      <ReactTable
        data={services}
        style={tableStyles}
        columns={servicesColumns}
        showPagination={false}
        noDataText="Nenhum serviço encontrado"
        loading={loadingHistory}
        loadingText="Carregando..."
      />

      <div className="flex end mt-2 gap-075">
        <button
          className="button button-red button-h35"
          onClick={clearAllSelections}
        >
          Limpar
        </button>
      </div>
    </div>
  );
}
