import React from 'react';
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
  Font,
} from '@react-pdf/renderer';
import {
  cpfMask,
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import fontRobotoBoldItalic from '../../../assets/fonts/Roboto-BoldItalic.ttf';

const CommissionView = ({ label, value, style = {} }) => (
  <View
    style={{
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: 20,
      ...style,
    }}
  >
    <Text style={{ fontWeight: 'bold', marginRight: 10 }}>{label}</Text>
    <Text>{value}</Text>
  </View>
);

const SalesItemHeader = ({ showTitleCode }) => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.text}>Data/Hora</Text>
    </View>
    {showTitleCode ? (
      <View
        style={{ width: '4%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Título</Text>
      </View>
    ) : (
      <View />
    )}
    <View
      style={{ width: '7%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Nº Venda/OS</Text>
    </View>
    <View
      style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Tipo</Text>
    </View>
    <View style={{ width: '19%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.text}>Item</Text>
    </View>
    <View
      style={{ width: '4%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Qtd</Text>
    </View>
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'left' }}>
      <Text style={styles.text}>Valor Unit.</Text>
    </View>
    <View style={{ width: '8%', textAlign: 'left', justifyContent: 'left' }}>
      <Text style={styles.text}>Total</Text>
    </View>
    <View
      style={{ width: '6%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>% Comiss.</Text>
    </View>
    <View style={{ width: '6%', textAlign: 'right', justifyContent: 'center' }}>
      <Text style={styles.text}>R$ Comiss.</Text>
    </View>
    <View
      style={{ width: '10%', textAlign: 'right', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Temp. Recomend.</Text>
    </View>
    <View
      style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Tempo. Trab.</Text>
    </View>
    <View
      style={{ width: '10%', textAlign: 'center', justifyContent: 'center' }}
    >
      <Text style={styles.text}>Diferença</Text>
    </View>
  </View>
);

const SalesItemData = ({ item, index, showTitleCode }) => {
  return (
    <>
      <View
        key={index}
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: 3,
          marginBottom: 3,
          fontWeight: 'bold',
        }}
        wrap={false}
      >
        {item.customerName ? (
          <View style={{ textAlign: 'left', marginRight: 12 }}>
            <Text style={styles.textClient}>Cliente: {item.customerName}</Text>
          </View>
        ) : null}
        {item.licensePlate || item.maintenanceObject ? (
          <View style={{ textAlign: 'left' }}>
            <Text style={styles.textClient}>
              Veículo: {item.licensePlate || item.maintenanceObject} -{' '}
              {item.model}
            </Text>
          </View>
        ) : null}
      </View>

      <View
        key={index}
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
        wrap={false}
      >
        <View
          style={{ width: '9%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            {format(new Date(item.date), 'dd/MM/yyyy hh:mm')}
          </Text>
        </View>
        {showTitleCode && (
          <View
            style={{ width: '3%', textAlign: 'left', justifyContent: 'center' }}
          >
            <Text>{item.billCode}</Text>
          </View>
        )}
        <View
          style={{ width: '7%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>{item.saleCode}</Text>
        </View>
        <View
          style={{ width: '5%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>{item.type}</Text>
        </View>
        <View
          style={{ width: '19%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.text}>{item.description.slice(0, 34)}</Text>
        </View>
        <View
          style={{ width: '4%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>{item.itemQuantity}</Text>
        </View>
        <View
          style={{ width: '8%', textAlign: 'left', justifyContent: 'left' }}
        >
          <Text style={styles.text}>{currency(item.unitValue)}</Text>
        </View>
        <View
          style={{ width: '8%', textAlign: 'left', justifyContent: 'left' }}
        >
          <Text style={styles.text}>{currency(item.amount)}</Text>
        </View>
        <View
          style={{ width: '6%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            {item.commissionRate ? percentage(item.commissionRate) : '-'}
          </Text>
        </View>
        <View
          style={{ width: '6%', textAlign: 'center', justifyContent: 'center' }}
        >
          <Text style={styles.text}>
            {item.commissionValue === 0 ? '*' : '+'}
            {currency(item.commissionValue)}
          </Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>{item.hoursExpected}</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>{item.workedHours}</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.text}>
            {item.hoursDiff ? item.hoursDiff : ''}
          </Text>
        </View>
      </View>
    </>
  );
};

const SalesItemsPerDay = ({
  key,
  haveItemWithZeroCommission,
  dayCommission,
  children,
  showTitleCode,
  ...rest
}) => (
  <View {...rest}>
    <SalesItemHeader key={key} showTitleCode={showTitleCode} />
    <View style={styles.line} />
    {children}
    <View style={[styles.line, { marginTop: 10, marginBottom: 10 }]} />
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <CommissionView
        label="R$ Comissão do Dia:"
        value={currency(dayCommission)}
      />
    </View>
    <Text style={{ marginBottom: '10px' }}>
      {haveItemWithZeroCommission
        ? '*Comissão zerada pois no momento do fechamento não havia comissão aplicada.'
        : ''}
    </Text>
  </View>
);

const ResultItem = ({
  item,
  initialDate,
  finalDate,
  showTitleCode,
  showTotalizators,
  paymentForData,
  ...rest
}) => {
  const isShowTotalizators = showTotalizators === 'true' ? true : false;

  return (
    <View {...rest} style={{ marginTop: 10 }}>
      <Text style={{ fontWeight: 'bold' }}>
        Funcionário: {item.name}
        {'       '}CPF:{cpfMask(item.cpf)}
      </Text>
      <Text>{item.position ? `Cargo: ${item.position}` : ''}</Text>
      <Text>
        Filtragem por:{' '}
        {paymentForData === 'commissionTitle'
          ? 'Data de Pagamento do Título'
          : 'Data do Apontamento da Comissão'}
      </Text>
      <Text>
        Período: {initialDate} - {finalDate}
      </Text>
      <View style={[styles.line, { marginTop: 5 }]} />
      {Object.keys(item.commissions).map((perDayCommission) => {
        let haveItemWithZeroCommission =
          item.commissions[perDayCommission].filter(
            (item) => item.commissionValue === 0
          ).length > 0
            ? true
            : false;

        const dayCommission = item.commissions[perDayCommission].reduce(
          (prev, curr) => {
            return prev + curr.commissionValue;
          },
          0
        );

        return (
          <View>
            <SalesItemsPerDay
              haveItemWithZeroCommission={haveItemWithZeroCommission}
              dayCommission={dayCommission}
              showTitleCode={showTitleCode}
            >
              <View>
                {item.commissions[perDayCommission].map((commission, index) => {
                  return (
                    <SalesItemData
                      item={commission}
                      key={index}
                      showTitleCode={showTitleCode}
                    />
                  );
                })}
              </View>
            </SalesItemsPerDay>
          </View>
        );
      })}
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <CommissionView
            label="R$ Comissão do Período:"
            value={currency(item.totalPeriodCommission)}
          />
        </View>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          {isShowTotalizators === true ? (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <CommissionView
                  label="Total R$ de Serviço do Período:"
                  value={currency(item.totalValueService)}
                />
                <CommissionView
                  label="Total R$ de Produto do Período:"
                  value={currency(item.totalValueProduct)}
                />
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

export const CommissionsDocument = ({
  result,
  initialDate,
  finalDate,
  showTitleCode,
  showTotalizators,
  paymentForData,
}) => {
  return (
    <Document
      title={`impresso-comissoes-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      {result.map((item, index) => (
        <Page style={styles.page} orientation={'landscape'} wrap={false}>
          {index === 0 ? (
            <View>
              <Text style={styles.title}>
                Relatório de Comissão/Rendimentos do Mecânico
              </Text>
            </View>
          ) : (
            <View />
          )}
          <View key={item.id}>
            <ResultItem
              item={item}
              initialDate={initialDate}
              finalDate={finalDate}
              showTitleCode={showTitleCode}
              showTotalizators={showTotalizators}
              paymentForData={paymentForData}
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 8,
  },
  textClient: {
    fontSize: 9,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
});
