import React, { useState, useEffect, memo } from 'react'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'

import RenderField from 'components/RenderField'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import TokenModal from './TokenModal'
import {dosentAllowSpaces} from 'client/components/ToNormalize/ToNormalize'

import './styles.css'

const SuivConfigForm = ({ onSubmit, pristine, loading }) => {
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false)

  return (
    <div>
      <Col xs={12} sm={12} md={12} lg={12} className='no-w-padding'>
        <Col xs={12} sm={6} md={5} lg={5} className='token-area no-w-padding'>
          <Field
            label='Token de Integração:'
            type='text'
            required='required'
            name='suivToken'
            pattern='[a-zA-Z0-9]+'
            component={RenderField}
            normalize={dosentAllowSpaces}
          />
          <span
            className='token-information-hiperlink'
            onClick={() => setIsTokenModalOpen(true)}
          >
            Onde encontro o token?
          </span>
        </Col>

        <Col xs={12} sm={6} md={2} lg={2}>
          <Button
            bsStyle='info'
            name='Salvar'
            fill
            style={{ marginTop: 36 }}
            disabled={pristine}
            onClick={onSubmit}
          >
            <span
              className={
                loading && 'fa fa-spinner fa-pulse fa-1x update-spinner'
              }
            ></span>
            Salvar
          </Button>
        </Col>
      </Col>
      <TokenModal
        show={isTokenModalOpen}
        onCancel={() => setIsTokenModalOpen(false)}
      />
    </div>
  )
}

export default SuivConfigForm
