import React, { useState } from 'react';
import { Field } from 'redux-form';
import { Col } from 'react-bootstrap';

import RenderField from '../../../../../components/RenderField';
import AdvertisingImage from './AdvertisingImage';
import { toastr } from 'react-redux-toastr';
import { SelectBranding } from 'v2/components/Select';

const MainData = ({
  type,
  setType,
  handleBrandingChange,
  branding,
  isActive,
}) => {
  function handleTypeChange(value) {
    if (isActive) {
      return toastr.warning(
        'A propaganda está ativa e a mudança do tipo poderá impactar na publicidade ativa. Desative a propaganda antes de editar.'
      );
    }
    setType(value);
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={5} md={5} lg={5}>
          <Field
            label="Descrição:"
            name="description"
            component={RenderField}
            required
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <div>
            <div>
              <label htmlFor="type">Tipo:</label>
              <span style={{ color: 'red' }}>*</span>
            </div>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="select-plans-wrapper"
            >
              <select
                id="type"
                value={type}
                onChange={(e) => handleTypeChange(e.target.value)}
                className="form-control foco-input"
              >
                <option value="" disabled>
                  Selecionar
                </option>
                <option value={'GLOBAL'}>Global</option>
                <option value={'EXCLUSIVE'}>Exclusivo</option>
              </select>
            </Col>
          </div>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <div>
            <div>
              <label htmlFor="type">Produto:</label>
              <span style={{ color: 'red' }}>*</span>
            </div>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="select-plans-wrapper"
            >
              <SelectBranding
                value={branding}
                onChange={handleBrandingChange}
                hideLabel
                height={35}
              />
            </Col>
          </div>
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <label htmlFor="">
          Imagem:<span style={{ color: 'red' }}>*</span>
          <span style={{ fontSize: 10 }}> (max. 540x142px)</span>
        </label>
        <AdvertisingImage />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={6} md={8} lg={8}>
          <Field
            label="Link de Redirecionamento:"
            name="redirectUrl"
            component={RenderField}
          />
        </Col>
      </Col>
    </>
  );
};

export default MainData;
