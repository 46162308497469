import React, { useState } from 'react'

import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import { useAuth } from 'contexts/auth'

import FormSubmitButtons from 'client/components/FormSubmitButtons'

import vehiclesRepository from '../../../../repositories/Vehicles'

export const BikeModelModal = ({
  getBicycleModelsByBrand,
  manufacturers,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false)

  const [selectedManufacturer, setSelectedManufacturer] = useState(null)
  const [model, setModel] = useState('')

  const manufacturerOptions = manufacturers?.map((m) => {
    return {
      value: m.id,
      label: m.Description,
    }
  })

  const dispatch = useDispatch()

  const { companyId } = useAuth()

  const createModel = async () => {
    const isValid = validateFields()
    if (!isValid) return

    setLoading(true)
    try {
      const createdModel = await vehiclesRepository.createBicycleModel({
        companyId,
        brandId: selectedManufacturer.value,
        description: model,
      })

      toastr.success(
        'Cadastro efetuado com sucesso',
        'O modelo foi cadastrado e pode ser usada em seu cadastro'
      )
      getBicycleModelsByBrand(selectedManufacturer.value)
      dispatch(change('vehicle', 'bicycleModelId', createdModel.id))
      onCancel()
    } catch (error) {
      await handleError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const handleError = async (message) => {
    switch (message) {
      case 'Model already exists':
        toastr.warning(
          'Não foi possível fazer o cadastro',
          ' Já existe esse modelo para essa montadora, verifique e tente novamente'
        )

        break

      default:
        toastr.error(
          'Não foi possível fazer o cadastro',
          'Ocorreu um erro ao cadastrar o modelo, tente novamente'
        )
    }
  }

  const validateFields = () => {
    if (!selectedManufacturer || !model) {
      toastr.warning(
        'Não foi possível fazer o cadastro',
        'Preencha todos os campos para realizar o cadastro do modelo'
      )

      return false
    }

    return true
  }

  return (
    <Modal show={true} onHide={onCancel} dialogClassName="modal-50w">
      <Modal.Header>
        <Modal.Title>
          <strong>Cadastro de Modelo</strong>
          <span
            style={{
              position: 'absolute',
              top: '30px',
              right: '10px',
              color: 'red',
              fontSize: '12px',
              marginLeft: 'auto',
            }}
          >
            *Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
          }}
        >
          <div>
            <label>
              <strong>
                Montadora: <span style={{ color: 'red' }}>*</span>
              </strong>
            </label>
            <Select
              options={manufacturerOptions}
              value={selectedManufacturer}
              placeholder="Selecione"
              openMenuOnFocus
              noOptionsMessage={() => 'Nenhuma marca encontrado'}
              isDisabled={loading}
              onChange={(value) => setSelectedManufacturer(value)}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  width: '220px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
            />
          </div>
          <div>
            <label>
              <strong>
                Modelo: <span style={{ color: 'red' }}>*</span>
              </strong>
            </label>
            <input
              type="text"
              className="form-control foco-input"
              value={model}
              maxLength="50"
              onChange={(e) => setModel(e.target.value)}
              style={{
                width: '350px',
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <FormSubmitButtons
            onCancel={onCancel}
            onSubmit={createModel}
            loading={loading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}
