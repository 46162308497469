import config from '../config';
import api from '../services/api';

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(`condPayment?companyId=${companyId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompanySortedByCondition = async (companyId) => {
  try {
    const response = await api.get(
      `condPayment?companyId=${companyId}&sortBy=Condition`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompanyActiveSortedByCondition = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `condPayment?companyId=${companyId}&sortBy=Condition&isActive=true`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPaginated = async (params) => {
  try {
    const response = await api.get(`condPayment`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const create = async (condition) => {
  try {
    const response = await api.post('condPayment', condition);
    return response;
  } catch (err) {
    throw err;
  }
};

const getAllActiveByCompany = async (companyId) => {
  try {
    const response = await api.get(
      `condPayment?companyId=${companyId}&isActive=true`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllActiveSuggestionsCount = async (companyId) => {
  try {
    const response = await api.get(
      `condPayment/count/active-suggestions/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(`condPayment/${id}`);
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, condition) => {
  try {
    const response = await api.put(`condPayment/${id}`, condition);
    return response;
  } catch (err) {
    throw err;
  }
};

const PaymentConditionsRepository = {
  getAllByCompany,
  getAllActiveSuggestionsCount,
  update,
  getById,
  create,
  getAllActiveByCompany,
  getAllByCompanySortedByCondition,
  getAllByCompanyActiveSortedByCondition,
  getAllPaginated,
};

export default PaymentConditionsRepository;
