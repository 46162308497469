import api from '../services/api'
import config from '../config'

const getAll = async ({ initialDate, finalDate, providerId, companyId }) => {
  try {
    const response = await api.get('quotation-providers/', {
      params: {
        initialDate,
        finalDate,
        providerId,
        companyId
      }
    })
    return response.data
  }
  catch (err) {
    console.log(err)
  }
}

const getAllByCompany = (companyId) =>
  api.get(config.endpoint + 'quotation', { params: { companyId } })

const updateStatus = (quotationId, companyId) =>
  api.put(
    config.endpoint +
    `quotation/cancel-quotation/${quotationId}?companyId=${companyId}`
  )

const create = (data) => api.post(config.endpoint + `quotation`, data)

const answerQuotation = (quotationId, data) =>
  api.post('quotation-response/' + quotationId, data)

const update = (data) => api.put(config.endpoint + `quotation`, data)

const getById = (id, companyId) =>
  api.get(config.endpoint + `quotation/${id}`, {
    params: {
      companyId,
    },
  })

const getQuotationWithItems = async (id) => {
  const { data } = await api.get('quotation/items/' + id)

  return data
}

const getIdsFromQuotationProvider = async (quotationProviderId) => {
  const { data } = await api.get('quotation-providers/' + quotationProviderId)

  return data
}

const getGeneralReport = (id) =>
  api.get(config.endpoint + `quotation/report/${id}`)

export default {
  getAll,
  getIdsFromQuotationProvider,
  getQuotationWithItems,
  getAllByCompany,
  answerQuotation,
  updateStatus,
  create,
  getById,
  update,
  getGeneralReport,
}
