import React, { useEffect, useState } from 'react'
import RenderField from 'components/RenderField'
import { Col } from 'react-bootstrap'
import { reduxForm, Field, change } from 'redux-form'
import Select from 'react-select'

import {
  description,
  name,
  email,
  phoneMask,
  cnpjMask,
  onlyNumbers,
  cepMask,
  cpfOrCnpjMask,
} from '../../../../components/ToNormalize/ToNormalize'
import { toastr } from 'react-redux-toastr'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth } from 'contexts/auth'

import ProvidersRepository from 'repositories/Providers'
import NewProviderHyperlinkWithFieldLabel from 'client/components/NewProviderHyperlinkWithFieldLabel'

function Provider({ disableInputs }) {
  const { type } = useSelector((state) => state.form.purchases.values)
  const [providersOptions, setProvidersOptions] = useState([])
  const [selectedProvider, setSelectedProvider] = useState(null)

  const { companyId } = useAuth()

  const dispatch = useDispatch()

  // useEffect(() => {
  //     handleChangeStatusIE()
  // }, [statusIE])f

  const checkCellphone = (value) => {
    value = onlyNumbers(value)
    if (value.length === 3 && value.charAt([2]) !== '9') return false
    return true
  }

  const checkComercialPhone = (value) => {
    value = onlyNumbers(value)
    if (value.charAt([0]) === '0') return false
    return true
  }

  const loadProvidersOptions = async () => {
    try {
      const data = await ProvidersRepository.getAllActiveByCompany(companyId)

      const sortedData = data.sort((a, b) => {
        if (a.companyName < b.companyName) {
          return -1
        }
        if (a.companyName > b.companyName) {
          return 1
        }
        return 0
      })

      setProvidersOptions(
        sortedData.map((provider) => ({
          label: `${
            provider.cpfCnpj ? cpfOrCnpjMask(provider.cpfCnpj) : ''
          } - ${provider.companyName}`,
          value: provider,
          show: true,
        }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os fornecedores',
        'Tente novamente! Caso persista, entre em contato com o suporte.'
      )
    }
  }

  function handleSelectProvider(provider) {
    setSelectedProvider(provider)

    dispatch([
      change('purchases', 'providerId', provider.value.id),
      change('purchases', 'cpfCnpj', provider.value.cpfCnpj),
      change('purchases', 'companyName', provider.value.companyName),
      change('purchases', 'tradingName', provider.value.tradingName),
      change('purchases', 'IE', provider.value.IE),
      change('purchases', 'Zipcode', provider.value?.Address[0]?.Zipcode),
      change('purchases', 'Address', provider.value?.Address[0]?.Address),
      change(
        'purchases',
        'Address_Number',
        provider.value?.Address[0]?.Address_Number
      ),
      change(
        'purchases',
        'Neighborhood',
        provider.value?.Address[0]?.Neighborhood
      ),
      change('purchases', 'Complement', provider.value?.Address[0]?.Complement),
      change(
        'purchases',
        'Reference_Point',
        provider.value?.Address[0]?.Reference_Point
      ),
      change('purchases', 'State', provider.value?.Address[0]?.State),
      change('purchases', 'City', provider.value?.Address[0]?.City),
    ])
  }

  async function handleSelectCreatedProvider(createdProvider) {
    await loadProvidersOptions()
    handleSelectProvider({
      label: `${
        !!createdProvider.cpfCnpj && cpfOrCnpjMask(createdProvider.cpfCnpj)
      } - ${createdProvider.companyName}`,
      value: {
        ...createdProvider,
        Address: createdProvider.Address ? [createdProvider.Address] : [],
      },
      show: true,
    })
  }

  useEffect(() => {
    loadProvidersOptions()
  }, [])

  return (
    <>
      {type === 'Manual' && !disableInputs && (
        <Col lg={12} md={12} sm={12} xs={12}>
          <div style={{ width: '500px' }}>
            <Select
              className="react-select react-select-primary"
              classNamePrefix="react-select"
              placeholder={'Pesquisa por Razão Social ou CNPJ'}
              options={providersOptions}
              value={selectedProvider}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                  cursor: 'pointer',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
              onChange={(e) => {
                handleSelectProvider(e)
              }}
              isDisabled={disableInputs}
              noOptionsMessage={() => 'Sem Opções'}
            />
            <NewProviderHyperlinkWithFieldLabel
              label={''}
              fieldId={''}
              required={false}
              handleSelectCreatedProvider={handleSelectCreatedProvider}
            />
          </div>
        </Col>
      )}

      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label={
            type === 'Manual' ? 'Razão Social/Nome Completo: ' : 'Razão Social:'
          }
          name="companyName"
          component={RenderField}
          normalize={description}
          maxLength="80"
          required={type === 'Manual' ? true : false}
          disabled
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Nome Fantasia"
          name="tradingName"
          component={RenderField}
          normalize={description}
          maxLength="80"
          disabled
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label={type === 'Manual' ? 'CPF/CNPJ:' : 'CNPJ:'}
          name="cpfCnpj"
          component={RenderField}
          normalize={cpfOrCnpjMask}
          placeholder=""
          maxLength="18"
          disabled
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Inscrição Estadual"
          name="IE"
          component={RenderField}
          maxLength="9"
          disabled
        />
      </Col>

      <Col lg={2} md={2} sm={3} xs={12}>
        <Field
          label="CEP"
          name="Zipcode"
          component={RenderField}
          normalize={cepMask}
          disabled
        />
      </Col>
      <Col lg={4} md={7} sm={6} xs={12}>
        <Field
          label="Endereço"
          name="Address"
          component={RenderField}
          disabled
        />
      </Col>
      <Col lg={1} md={3} sm={3} xs={12}>
        <Field
          label="Número"
          name="Address_Number"
          component={RenderField}
          normalize={onlyNumbers}
          disabled
        />
      </Col>
      <Col lg={3} md={6} sm={6} xs={12}>
        <Field
          label="Bairro"
          name="Neighborhood"
          component={RenderField}
          disabled
        />
      </Col>
      <Col lg={2} md={6} sm={6} xs={12}>
        <Field
          label="Complemento"
          name="Complement"
          component={RenderField}
          disabled
        />
      </Col>

      <Col lg={3} md={6} sm={6} xs={12}>
        <Field
          label="Ponto de Referência"
          name="Reference_Point"
          component={RenderField}
          maxLength="15"
          disabled
        />
      </Col>

      <Col lg={3} md={6} sm={6} xs={12}>
        <Field name="State" label="Estado" component={RenderField} disabled />
      </Col>

      <Col lg={3} md={6} sm={6} xs={12}>
        <Field label="Cidade" name="City" component={RenderField} disabled />
      </Col>
    </>
  )
}

export default reduxForm({
  form: 'purchases',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Provider)
