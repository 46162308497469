import React from 'react'
import ReactInputMask from 'react-input-mask'
import CurrencyInput from 'react-currency-input'
import { toastr } from 'react-redux-toastr'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { validateCpf } from 'client/components/Validations/Validations'

const cpfCnpjLabel = {
  Fisica: 'CPF',
  Juridica: 'CNPJ',
}

const cpfCnpjMask = {
  Fisica: '999.999.999-99',
  Juridica: '99.999.999/9999-99',
}

export default function PhysicalPerson({ handleNextSection }) {
  const { register, setValue, control } = useFormContext()

  const type = useWatch({
    control,
    name: 'type',
  })

  const cpfCnpj = useWatch({
    control,
    name: 'cpfCnpj',
  })

  const productComission = useWatch({
    control,
    name: 'productComission',
  })

  const serviceComission = useWatch({
    control,
    name: 'serviceComission',
  })

  const handleValidateCpf = () => {
    const valueWithoutMask = cpfCnpj.replace(/[^\d]/g, '')
    if (valueWithoutMask && !validateCpf(valueWithoutMask)) {
      toastr.warning('CPF inválido', 'Por favor, informe um CNPJ válido')
      document.getElementById('cpfCnpj').focus()
    }
  }

  return (
    <>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-2 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div>
          <label htmlFor="cpfCnpj">{cpfCnpjLabel[type]}:</label>
          <Controller
            defaultValue={''}
            name={'cpfCnpj'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={cpfCnpjMask[type]}
                  formatChars={{
                    9: '[0-9]',
                  }}
                  onBlur={handleValidateCpf}
                >
                  {() => (
                    <input
                      id={'cpfCnpj'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>

        <div>
          <label htmlFor="rg">RG:</label>
          <Controller
            defaultValue={''}
            name={'rg'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={'99.999.999-9'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'rg'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>

        <div>
          <label htmlFor="companyName">
            Nome Completo: <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id={'companyName'}
            type="text"
            className="form-control foco-input"
            {...register('companyName')}
          />
        </div>

        <div>
          <label htmlFor="email">
            Email: <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id={'email'}
            type="email"
            className="form-control foco-input"
            {...register('email')}
          />
        </div>
      </div>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-3 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div>
          <label htmlFor="commercialPhone">Telefone Residencial:</label>
          <Controller
            defaultValue={''}
            name={'commercialPhone'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={'(99) 9999-9999'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'commercialPhone'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>
        <div>
          <label htmlFor="cellPhone">
            Telefone Celular: <span style={{ color: 'red' }}>*</span>
          </label>
          <Controller
            defaultValue={''}
            name={'cellPhone'}
            control={control}
            render={({ field }) => {
              return (
                <ReactInputMask
                  {...field}
                  mask={'(99) 9 9999-9999'}
                  suf
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'cellPhone'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </ReactInputMask>
              )
            }}
          />
        </div>
        <div>
          <label htmlFor="birthdate">Data de Nacimento:</label>
          <input
            type="date"
            className="form-control foco-input"
            {...register('birthdate')}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="productComission">% Comissão Produto:</label>
          <CurrencyInput
            className="form-control foco-input"
            suffix="%"
            decimalSeparator=","
            thousandSeparator="."
            maxLength={6}
            value={productComission}
            onChangeEvent={(e) => {
              setValue(
                'productComission',
                Number(`${e.target.value}`.replace('%', '').replace(',', '.'))
              )
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="serviceComission">% Comissão Serviço:</label>
          <CurrencyInput
            className="form-control foco-input"
            suffix="%"
            decimalSeparator=","
            thousandSeparator="."
            maxLength={6}
            value={serviceComission}
            onChangeEvent={(e) => {
              setValue(
                'serviceComission',
                Number(`${e.target.value}`.replace('%', '').replace(',', '.'))
              )
            }}
          />
        </div>
      </div>
      <div
        className={`new-trade-representative__basic-data-wrapper-row-4 ${
          type === 'Juridica' ? 'legal-person' : 'physical-person'
        }`}
      >
        <div>
          <label htmlFor="createdAt">Data de Cadastro</label>
          <input
            type="date"
            className="form-control foco-input"
            {...register('createdAt')}
            disabled
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="serviceComission">
            Observações (Máximo de 500 caracteres):
          </label>
          <textarea
            className="form-control foco-input"
            maxLength={500}
            {...register('observations')}
          ></textarea>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <button
            className="btn-green"
            type="button"
            onClick={handleNextSection}
          >
            Prosseguir
          </button>
        </div>
      </div>
    </>
  )
}
