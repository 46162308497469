import config from '../config'
import api from '../services/api'

const create = async (scheduling) => {
  try {
    const response = await api.post(config.endpoint + 'scheduling', scheduling)
    return response
  } catch (err) {
    throw err
  }
}

const update = async (id, scheduling) => {
  try {
    const response = await api.put(
      config.endpoint + `scheduling/${id}`,
      scheduling
    )
    return response
  } catch (err) {
    throw err
  }
}

const getById = async (schedulingId) => {
  try {
    const response = await api.get(
      config.endpoint + `scheduling/${schedulingId}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const index = async (queryParams) =>
  await api.get(config.endpoint + `scheduling`, { params: queryParams })

const destroy = async (schedulingId) =>
  await api.delete(config.endpoint + `scheduling/${schedulingId}`)

export default {
  getById,
  create,
  update,
  index,
  destroy,
}
