import api from 'services/api';

export const getAllMaintenancesByCompanyId = async (companyId) => {
  const res = await api.get(`/maintenance/${companyId}`);
  return res.data;
};

const getMaintenanceBySaleId = async (companyId, saleId) => {
  const res = await api.get(`/maintenance/${companyId}?saleId=${saleId}`);
  return res.data[0];
};

const updateMaintenance = async (maintenanceId, data) => {
  const res = await api.put(`maintenance/${maintenanceId}`, data);
  return res.data;
};

const createMaintenanceBySaleId = async (saleId) => {
  const response = await api.post('maintenance', { saleId });

  return response.data;
};

const MaintenanceRepository = {
  getAllMaintenancesByCompanyId,
  updateMaintenance,
  createMaintenanceBySaleId,
  getMaintenanceBySaleId,
};

export default MaintenanceRepository;
