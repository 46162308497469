import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import Button from '../../../../../../client/components/CustomButton/CustomButton';
import ConfirmModal from './ConfirmModal';
import PureLoader from '../../../../../../components/PureLoader';
import massPriceChangeRepository from '../../../../../../repositories/MassPriceChanges';
import { useAuth } from 'contexts/auth';

const ChangePriceModal = ({
  data,
  onCancel,
  onSubmit,
  prices,
  confirmModalData,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitData, setSubmitData] = useState([]);
  const { userId, companyId } = useAuth();

  const handleSubmit = async (type) => {
    if (type) {
      setConfirmModal(true);
    } else {
      const productsQuantity = data.length;
      try {
        const saveItems = await massPriceChangeRepository.create({
          massPriceChangesItems: prices,
          status: type,
          userId,
          companyId,
          productsQuantity,
        });

        toastr.success('Novos preços salvos com sucesso ');

        onSubmit();

        return saveItems;
      } catch (error) {
        toastr.error(
          'Ocorreu um erro ao salvar os novos preços. Por favor, tente novamente'
        );
      }
    }
  };
  return (
    <Modal show animation onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Confirmação de Alteração</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="modules-container">
          <p>
            <strong>
              Atenção, a alteração de preço é um processo irreversível
            </strong>
          </p>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <p>
            Você poderá salvar e realizar a alteração de preço mais tarde, mas
            ao realizar a alteração, não poderá desfazer o processo
            posteriormente.
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Button
            bsStyle="info"
            fill
            className="btn btn-export"
            style={{ width: '35%' }}
            onClick={() => onSubmit(true, false, true)}
          >
            Atualizar preços
          </Button>
          <Button
            className="btn btn-sucesso"
            style={{ width: '35%' }}
            name="Salvar"
            fill
            onClick={() => onSubmit(false, true)}
          >
            Salvar e Sair
          </Button>
          <p style={{ color: 'red', fontSize: '12px' }}>
            {' '}
            Verifique os valores a serem alterados antes de executar
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button bsStyle="danger" name="Calcelar" onClick={onCancel} fill>
            Voltar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePriceModal;
