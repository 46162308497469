import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import CollapsibleSection from 'components/CollapsibleSection';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

import { FormHeader } from './components/FormHeader';
import { FormContentWrapper } from './components/FormContentWrapper/FormContentWrapper';
import { GroupFeatureContent } from './components/GroupFeatureContent';

import { TabMenu } from 'v2/components/TabMenu';
import { DefaultContentContainer } from 'v2/components/layout';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

import ProfilesRepository from 'repositories/Profiles';
import AccessPermissionsRepository from 'v2/repositories/AccessPermissionsRepository';

import { defaultFormValues, getBreadcrumbData } from './utils';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';

export function AccessProfiles({ hideActionButtons }) {
  const { id_hash } = useParams();
  const { companyId } = useAuth();
  const formMethods = useForm({
    defaultValues: defaultFormValues,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { navigateToClientPage } = useRouter();

  const [tabMenuItems, setTabMenuItems] = useState([]);
  const [tabMenuActiveItemIndex, setTabMenuActiveItemIndex] = useState(0);

  const { setValue } = formMethods;

  const setProfile = useCallback(
    (profile) => {
      setValue('profileDescription', profile.description);
      setValue('profileId', profile.id);
    },
    [setValue]
  );

  const loadPageData = async () => {
    try {
      setIsLoading(true);

      const profileId = await decrypt(id_hash, '@OS-dig:profileId');
      const profile = await loadProfile(profileId);
      const features = await loadFeatures(profileId);

      setProfile(profile);
      setValue('groupFeatures', features.groupFeatures);
      setValue('profileId', profileId);
      setValue('isMaster', features.status);

      populateTabMenu(features.groupFeatures);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  async function loadProfile(profileId) {
    try {
      const response = await ProfilesRepository.getById(profileId);
      const data = response.data;
      return data;
    } catch (err) {
      console.error(err);
      if (err.response.status === 400) {
        const data = err.response;
        const message = data.data.message;
        toastr.error(
          'Ocorreu um erro ao buscar o Perfil de Acesso. Por favor, tente novamente.',
          message
        );
      }
      throw err;
    }
  }

  async function loadFeatures(profileId) {
    try {
      const response = await AccessPermissionsRepository.getByProfileId(
        profileId,
        companyId
      );

      return response.data;
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um carregar os itens do menu',
        'Por favor, tente novamente.'
      );
      throw err;
    }
  }

  function populateTabMenu(groupFeatures) {
    const items = groupFeatures
      .map((item) => ({
        id: item.id,
        description: item.description,
        lineNumber: item.lineNumber,
      }))
      .sort((a, b) => a.lineNumber - b.lineNumber);

    setTabMenuItems(items);
    setTabMenuActiveItemIndex(0);
  }

  async function onSubmit(data) {
    setIsSubmitting(true);
    try {
      await AccessPermissionsRepository.updateByProfileId(data.profileId, data);
      toastr.success('Sucesso', 'As permissões foram atribuídas ao perfil.');
      navigateToClientPage('profiles', 'base');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Salvar as Permissões',
        'Por favor, tente novamente.'
      );
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    loadPageData();
  }, [id_hash]);

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />

      <FormProvider {...formMethods}>
        <div className="content__main">
          <CollapsibleSection
            title="Permissões de Acesso"
            isExpanded={true}
            handleExpand={() => null}
          >
            {isLoading ? (
              <LoadingSpinnerFullHeight />
            ) : (
              <>
                <FormHeader />
                <FormContentWrapper>
                  <TabMenu
                    items={tabMenuItems}
                    activeItem={tabMenuActiveItemIndex}
                    setActiveItem={setTabMenuActiveItemIndex}
                  />

                  <GroupFeatureContent
                    currentGroupFeatureIndex={tabMenuActiveItemIndex}
                  />
                </FormContentWrapper>
              </>
            )}
          </CollapsibleSection>

          {!isLoading && !hideActionButtons && (
            <div className="flex align-center end gap-075 mt-2">
              <button
                className="button button-red button-h35 flex center"
                style={{ width: '100px' }}
                onClick={() => navigateToClientPage('profiles', 'base')}
                disabled={isSubmitting}
              >
                <span>Cancelar</span>
              </button>
              <button
                className="button button-green button-h35 flex center"
                type="submit"
                style={{ width: '100px' }}
                onClick={formMethods.handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <span className="flex center">
                  {isSubmitting ? (
                    <span className="fa fa-spinner fa-pulse" />
                  ) : (
                    'Salvar'
                  )}
                </span>
              </button>
            </div>
          )}
        </div>
      </FormProvider>
    </DefaultContentContainer>
  );
}
