import React from 'react';
import { Modal } from 'react-bootstrap';

import DownloadXlsButton from 'components/DownloadXlsButton';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { toastr } from 'react-redux-toastr';

export function ModalExportXLS({
  isOpen,
  handleClose,
  dataByType,
  dataBySales,
}) {
  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Selecione a opção desejada:
          </p>
          <div className="panel-operation-buttons">
            <DownloadXlsButton
              className="btn btn-export"
              archiveName={`Vendas por Produto-Serviço`}
              data={dataByType}
              style={{
                background: '#428bca',
                borderColor: '#428bca',
                fontSize: '14px',
              }}
              columns={[
                {
                  name: 'Descrição',
                  acessor: 'description',
                },
                {
                  name: 'Tp',
                  acessor: 'type',
                },
                {
                  name: 'Família',
                  acessor: 'family',
                },
                {
                  name: 'Qtd',
                  acessor: 'qtdSale',
                },
                {
                  name: 'Venda',
                  acessor: 'sale',
                },
                {
                  name: 'PDV',
                  acessor: 'pdv',
                },
                {
                  name: 'OS',
                  acessor: 'os',
                },
                {
                  name: 'Valor Total',
                  acessor: 'total',
                },
                {
                  name: '% do item',
                  acessor: 'percentage',
                },
              ]}
            >
              Listagem de valores por tipo
            </DownloadXlsButton>

            <DownloadXlsButton
              className="btn"
              archiveName={`Vendas por Produto e Serviço (Itens Detalhados)`}
              data={dataBySales}
              style={{
                background: '#5CB85C',
                borderColor: '#5CB85C',
                color: '#fff',
                fontSize: '14px',
              }}
              columns={[
                {
                  name: 'DATA',
                  acessor: 'saleDate',
                },
                {
                  name: 'N. VENDA',
                  acessor: 'saleCode',
                },
                {
                  name: 'CÓDIGO',
                  acessor: 'itemCode',
                },
                {
                  name: 'DESCRIÇÃO',
                  acessor: 'description',
                },
                {
                  name: 'TIPO',
                  acessor: 'itemType',
                },
                {
                  name: 'FAMÍLIA',
                  acessor: 'familyDescription',
                },
                {
                  name: 'QUANTIDADE',
                  acessor: 'quantity',
                },
                {
                  name: 'VALOR UNITÁRIO (R$)',
                  acessor: 'unitValue',
                },
                {
                  name: 'VALOR TOTAL (R$)',
                  acessor: 'totalValue',
                },
              ]}
            >
              Itens detalhados das vendas
            </DownloadXlsButton>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="Voltar"
              onClick={handleClose}
              fill
            >
              Voltar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
