import React from 'react'

import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IndicatorsList } from './IndicatorsList'

import { format } from 'date-fns'

import '../index.css'

export const ExternalIndicatorsContent = ({ history, indicators }) => {
  return (
    <div className="external_indicators__content">
      <p className="external-indicators__title">
        <FontAwesomeIcon icon={faChartLine} size={'lg'} />
        &nbsp; Indicadores Diários
      </p>

      <ExternalIndicatorsUpdatedAt />
      <IndicatorsList indicators={indicators} />
    </div>
  )
}

const ExternalIndicatorsUpdatedAt = () => {
  return (
    <div className="external-indicators__last-update">
      Atualizado em {format(new Date(), 'dd/MM/yyyy')} -{' '}
      {format(new Date(), 'HH:mm')}
    </div>
  )
}
