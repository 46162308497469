import visa from 'assets/img/cardFlags/visa-1.png';
import mastercard from 'assets/img/cardFlags/mastercard.png';
import americanExpress from 'assets/img/cardFlags/american-express.png';
import generic from 'assets/img/cardFlags/generic-card.png';
import discover from 'assets/img/cardFlags/discover.png';
import dinersClub from 'assets/img/cardFlags/diners-club.png';
import elo from 'assets/img/cardFlags/elo-1.png';
import hipercard from 'assets/img/cardFlags/hipercard.png';
import aura from 'assets/img/cardFlags/Aura.png';
import mais from 'assets/img/cardFlags/mais.png';
import cabal from 'assets/img/cardFlags/cabal.png';

export const listCardFlags = [
  {
    img: visa,
    value: '01',
  },
  {
    img: mastercard,
    value: '02',
  },
  {
    img: americanExpress,
    value: '03',
  },
  {
    img: dinersClub,
    value: '05',
  },
  {
    img: elo,
    value: '06',
  },
  {
    img: hipercard,
    value: '07',
  },
  {
    img: aura,
    value: '08',
  },
  {
    img: cabal,
    value: '09',
  },
  {
    img: discover,
    value: '14',
  },
  {
    img: mais,
    value: '19',
  },
  {
    img: generic,
    value: '28',
  },
];
