import React, { useState, useEffect } from 'react'
import { Grid, Row } from 'react-bootstrap'
import FormPosition from './FormPosition'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

const NewPosition = ({ location }) => {
  const [id, setId] = useState(null)

  localStorage.setItem('selectedPath', 'client/position')

  useEffect(() => {
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get("id");

    if (idParams) {
      readHash(idParams)
    }
  }

  async function readHash(hash) {
    const positionId = await decrypt(hash, '@OS-dig:positionId');
    setId(positionId);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb data={['Início', 'Meus Cargos', !!id ? 'Editar Cargo' : 'Novo Cargo']}
            path={['home', 'positions', null]} />
          <div style={{ marginTop: 30 }}>
            <FormPosition positionId={id} />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewPosition