import api from '../services/api'
import config from '../config'

const store = async (info) => {
  const response = await api.post(config.endpoint + 'stone-transaction', info)
  return response.data
}

const show = async (stonePreTransactionId) => {
  const response = await api.get(
    config.endpoint + `stone-transaction/${stonePreTransactionId}`
  )

  return response.data
}

const stoneTransactionRepository = {
  store,
  show,
}

export default stoneTransactionRepository
