import Home from './views/Home';
import Produto from './views/Products';
import Clients from './views/Clients';
import Servico from './views/Services';
import Familia from './views/Families';
import Marcas from './views/Brands';
import Garantia from './views/Warranty';
import Revisao from './views/Reviews';
import FormaPagamento from './views/FormOfPayment';
import CondicaoPagamento from './views/PaymentConditions';
import MeusUsuarios from './views/MyUsers';
import NovoCFOP from './views/CFOPS/NewCFOP';
import MeusDados from './views/MyCompanyData';
import NovoCliente from './views/Clients/NewClient';
import NovoUsuario from './views/MyUsers/NewUser';
import NovaMarca from './views/Brands/NewBrand';
import NovaRevisao from './views/Reviews/NewReview';
import NovaGarantia from './views/Warranty/NewWarranty';
import NovaFamilia from './views/Families/NewFamilie';
import NovoServico from './views/Services/NewService';
import Companies from './views/Companies';
import MeusDetalhes from './views/MyCompanyDetails';
import NovoProduto from './views/Products/NewProduct';
import NewKit from './views/Products/NewKit';
import Vendas from './views/Sales';
import NovaVenda from './views/Sales/NewSale';
import MyPlan from './views/MyCompanyPlan';
import RelatoriosVenda from './views/Reports/Sales';
import CFOPS from './views/CFOPS';
import TextoParaNotaFiscal from './views/TextForInvoices';
import Transportadora from './views/ShippingCompany';
import NewTextFormInvoices from './views/TextForInvoices/NewTextForInvoices';
import NewShippingCompany from './views/ShippingCompany/NewShippingCompany';
import NewFormOfPayment from './views/FormOfPayment/NewFormOfPayment';
import Fornecedores from './views/Providers';
import Ncm from './views/NcmNbs';
import NewProvider from './views/Providers/NewProvider';
import TaxData from './views/MyCompanyTaxData';
import TributoPorEstado from './views/TaxByState';
import NewTaxByState from './views/TaxByState/NewTaxByState';
import Invoices from './views/Invoices';
import NewNFCe from './views/NFCe/newNFCe';
import BillsToReceive from './views/BillsToReceive';
import BillToReceive from './views/BillsToReceive/NewBillToReceive';
import FinancialReports from './views/Reports/Financial';
import PurchasesReports from './views/Reports/Purchases';
import Birthdays from './views/Reports/Birthdays';
import ProductsReports from './views/Reports/Products';
import BillToPay from './views/BillsToPay/NewBillToPay';
import BillsToPay from './views/BillsToPay';
import Purchases from './views/Purchases';
import ProviderProducts from './views/ProviderProducts';
import Configurations from './views/Configurations';
import Purchase from './views/Purchases/NewPurchase';
import NewStock from './views/Stock/NewStock';
import VoucherPayment from './views/VoucherPayment';
import Stock from './views/Stock';
import BatchPriceChange from './views/BatchPriceChange';
import NewBatchPriceChange from './views/BatchPriceChange/NewBatchPriceChange';
import NewNFe from './views/NFe';
import NewCNAE from './views/Cnae/NewCnae/index';
import CNAES from './views/Cnae';
import NFSe from './views/NFSe';
import ServiceInvoices from './views/ServiceInvoices';
import FinancialMovement from './views/FinancialMovements/FinancialMovement';
import HomeFinancialMovements from './views/FinancialMovements';
import NewSAT from './views/SAT/NewSAT';
import FinancialTaxCard from './views/Reports/FinancialTaxCard';
import NewSATCFe from './views/SATCFe/newSATCFe';
import NewCashierBank from './views/CashierBank/NewCashierBank';
import HomeCashierBank from './views/CashierBank';
import CashierBankHistoric from './views/CashierBankHistoric';
import NewPosition from './views/Positions/NewPosition';
import NewProfile from './views/Profiles/NewProfile';
import NewEmployee from './views/Employees/NewEmployee';
import Profiles from './views/Profiles';
import Positions from './views/Positions';
import Employees from './views/Employees';
import Similarities from './views/Similarities';
import Similarity from './views/Similarities/NewSimilarity';
import QuickQuotes from './views/QuickQuotes';
import ProfileAccessPermissions from './views/Profiles/AccessPermissions';
import HomeCostCenter from './views/CostCenter';
import NewCostCenter from './views/CostCenter/NewCostCenter';
import AccountPlans from './views/AccountPlans';
import NewAccountPlan from './views/AccountPlans/NewAccountPlan';
import ReturnHistory from './views/Reports/Sales/ReturnHistoryByPeriod';
import MyFinancialArea from './views/MyFinancialArea';
import Intermediador from './views/Intermediary';
import NewIntermediary from './views/Intermediary/NewIntermediary';
import PrismaBox from './views/PrismaBox';
import NewPrismaBox from './views/PrismaBox/NewPrismaBox';
import Schedules from './views/Schedules';
import NewScheduling from './views/Schedules/NewScheduling';
import LinkedServices from './views/LinkedService';
import NewLinkedServices from './views/LinkedService/NewLinkedService';
import PartApplication from './views/PartApplication';
import NewPartAppication from './views/PartApplication';
import RankingSales from './views/Reports/Sales/Ranking';
import AprovedDisaprovedItens from './views/Reports/Purchases/AprovedDisaprovedItens';
import TitleListing from './views/Reports/Financial/TitleListing';
import TransactionsStone from './views/Reports/TransactionsStone';
import Integrations from './views/Integrations';
import DigitalAccount from './views/DigitalAccount';
import DiscountPriceProducts from './views/DiscountPriceProducts';
import DiscountGroupService from './views/DiscountGroupService';
import DiscountGroups from './views/DiscountGroupTables';
import NewDiscountGroup from './views/DiscountGroupTables/newDiscountGroupTables';
import LossReasons from './views/LossReasons';
import NewLossReason from './views/LossReasons/NewLossReason';
import HomeCanceledBudgets from './views/Reports/Sales/CanceledBudgets';
import Quotations from './views/Quotations/index.jsx';
import QuotationRegister from './views/Quotations/QuotationRegister';
import VehicleHistory from './views/Reports/VehicleHistory';
import ABCCurve from './views/Reports/Sales/ABCCurve';
import NewVoucherPayment from './views/VoucherPayment/NewVoucherPayment';
import VouchersIssued from './views/Reports/VouchersIssued';
import ProductsWithoutMovement from './views/Reports/Products/ProductWithoutMovement';
import Inventory from './views/Reports/Products/Inventory';
import InvoicesReport from './views/Reports/Invoices';
import ReturnListingDevolution from './views/Reports/ReturnListingDevolution';

import QuotesSupplier from './views/Reports/QuotesSupplier';
import PurchasesSupplier from './views/Reports/PurchasesSupplier';
import ProductsFamilyOrBrand from './views/Reports/ProductsFamilyOrBrand';
import InventoryMovementListing from './views/Reports/InventoryMovementListing';
import ProductServiceReviewsWarrant from './views/Reports/ProductServiceReviews';
import SalesPerFamilyProduct from './views/Reports/SalesPerFamilyProduct';
import SalesPerFamilyService from './views/Reports/SalesPerFamilyService';
import InventoryCount from './views/InventoryCount';
import NewInventoryCount from './views/InventoryCount/InventoryCountRegister';
import ProdtctsListingLocation from './views/Reports/Products/ProdtctsListingLocation';
import ListEmployees from './views/Reports/ListEmployees';
import CustomerList from './views/Reports/CustomerList';
import PendingCustomersSuppliers from './views/Reports/PendingCustomersSuppliers';
import PartsPerApplication from './views/Reports/Products/PartsPerApplication';
import SalesPassing from './views/Reports/Sales/SalesPassing';
import AccountsReceivable from './views/Reports/Financial/AccountsReceivable';
import AccountsPayable from './views/Reports/Financial/AccountsPayable';
import TradeRepresentative from './views/TradeRepresentative';
import RepresentativeComissions from './views/Reports/Sales/RepresentativeComissions';
import NewTradeRepresentative from './views/TradeRepresentative/NewTradeRepresentative';
import CustomerCredit from './views/CustomerCredit';
import PartsRequisition from './views/PartsRequisition';
import { PromotionalPriceTable } from './views/PromotionalPriceTable';
import NewPartsRequisition from './views/PartsRequisition/NewPartsRequisition';
import NewPromotionalPriceTable from './views/PromotionalPriceTable/NewPromotionalPrice';
import IssuedSlip from './views/IssuedSlip';
import { Hangtag } from './views/HangTag';
import { RegisterPaymentCondition } from './views/PaymentConditions/RegisterPaymentCondition';
import SalesPerDayReport from './views/Reports/SalesPerDay';
import FamilyBrandStockReport from './views/Reports/FamilyBrandStock';
import ListCanceledItems from './views/Reports/ListCanceledItems';
import CustomerApproachReport from './views/Reports/CustomerApproach';
import constants from '../utils/constants';
import { CLIENT_ROUTES } from 'v2/constants/routes';
import { Fraga } from 'admin/views/Fraga';

const clientRoutes = [
  {
    path: constants.ROUTES.HOME,
    active: 'home',
    name: 'Página Inicial',
    component: Home,
  },
  {
    path: constants.ROUTES.PRODUCTS,
    active: 'products',
    name: 'Produtos',
    component: Produto,
    group: 'Produtos',
  },
  {
    path: constants.ROUTES.SERVICES,
    active: 'services',
    name: 'Serviços',
    component: Servico,
    group: 'Serviços',
  },
  {
    path: constants.ROUTES.SERVICE,
    active: 'services',
    name: 'Novo Serviço',
    component: NovoServico,
  },
  {
    path: constants.ROUTES.CUSTOMERS,
    active: 'customers',
    name: 'Clientes',
    component: Clients,
    group: 'Clientes',
  },
  {
    path: constants.ROUTES.FAMILIES,
    active: 'cadastros',
    name: 'Familias',
    component: Familia,
  },
  {
    path: constants.ROUTES.BRANDS,
    active: 'cadastros',
    name: 'Marcas',
    component: Marcas,
  },
  {
    path: constants.ROUTES.WARRANTIES,
    active: 'cadastros',
    name: 'Garantias',
    component: Garantia,
  },
  {
    path: constants.ROUTES.REVISIONS,
    active: 'cadastros',
    name: 'Revisões',
    component: Revisao,
  },
  {
    path: constants.ROUTES.PAYMENT_CONDITIONS,
    active: 'cadastros',
    name: 'Condições de Pagamento',
    component: CondicaoPagamento,
  },

  {
    path: constants.ROUTES.PAYMENT_CONDITION,
    active: 'cadastros',
    name: 'Nova Condição de Pagamento',
    component: RegisterPaymentCondition,
  },
  {
    path: constants.ROUTES.EDIT_PAYMENT_CONDITION,
    active: 'cadastros',
    name: 'Editar Condição de Pagamento',
    component: RegisterPaymentCondition,
  },
  {
    path: constants.ROUTES.PAYMENT_FORMS,
    active: 'cadastros',
    name: 'Formas de Pagamento',
    component: FormaPagamento,
  },
  {
    path: constants.ROUTES.PAYMENT_FORM,
    active: 'cadastros',
    name: 'Nova Forma de Pagamento',
    component: NewFormOfPayment,
  },
  {
    path: constants.ROUTES.EDIT_PAYMENT_FORM,
    active: 'cadastros',
    name: 'Editar Forma de Pagamento',
    component: NewFormOfPayment,
  },
  {
    path: constants.ROUTES.USERS,
    active: 'home',
    name: 'Meus Usuários',
    component: MeusUsuarios,
    group: 'Dados e Configurações',
  },
  {
    path: constants.ROUTES.COMPANY_DATA,
    active: 'home',
    name: 'Meus Dados',
    component: MeusDados,
  },
  {
    path: constants.ROUTES.COMPANIES,
    active: 'home',
    name: 'Minhas Empresa',
    component: Companies,
  },
  {
    path: constants.ROUTES.CUSTOMER,
    active: 'customers',
    name: 'Novo Cliente',
    component: NovoCliente,
  },
  {
    path: constants.ROUTES.EDIT_CUSTOMER,
    active: 'customers',
    name: 'Editar Cliente',
    component: NovoCliente,
  },
  {
    path: constants.ROUTES.BRAND,
    active: 'cadastros',
    name: 'Nova Marca',
    component: NovaMarca,
  },
  {
    path: constants.ROUTES.REVIEW,
    active: 'cadastros',
    name: 'Nova Revisão',
    component: NovaRevisao,
  },
  {
    path: constants.ROUTES.FAMILY,
    active: 'cadastros',
    name: 'Nova Família',
    component: NovaFamilia,
  },
  {
    path: constants.ROUTES.EDIT_REVIEW,
    active: 'cadastros',
    name: 'Editar Revisao',
    component: NovaRevisao,
  },
  {
    path: constants.ROUTES.EDIT_BRAND,
    active: 'cadastros',
    name: 'Editar Marca',
    component: NovaMarca,
  },
  {
    path: constants.ROUTES.WARRANTY,
    active: 'cadastros',
    name: 'Editar Garantia',
    component: NovaGarantia,
  },
  {
    path: constants.ROUTES.EDIT_WARRANTY,
    active: 'cadastros',
    name: 'Nova Garantia',
    component: NovaGarantia,
  },
  {
    path: constants.ROUTES.EDIT_FAMILY,
    active: 'cadastros',
    name: 'Editar Família',
    component: NovaFamilia,
  },
  {
    path: constants.ROUTES.EDIT_SERVICE,
    active: 'cadastros',
    name: 'Editar Serviço',
    component: NovoServico,
  },
  {
    path: constants.ROUTES.COMPANY_DETAILS,
    active: 'home',
    name: 'Meus Detalhes',
    component: MeusDetalhes,
  },
  {
    path: constants.ROUTES.PRODUCT,
    active: 'products',
    name: 'Novo Produto',
    component: NovoProduto,
  },
  {
    path: constants.ROUTES.NEW_PRODUCT_SERVICE_KIT,
    active: 'products',
    name: 'Novo Kit',
    component: NewKit,
  },
  {
    path: constants.ROUTES.EDIT_PRODUCT_SERVICE_KIT,
    active: 'products',
    name: 'Editar Kit',
    component: NewKit,
  },
  {
    path: constants.ROUTES.EDIT_PRODUCT,
    active: 'products',
    name: 'Editar Produto',
    component: NovoProduto,
  },
  {
    path: constants.ROUTES.SALES,
    active: 'sales',
    name: 'Vendas',
    component: Vendas,
    group: 'Vendas',
  },
  {
    path: constants.ROUTES.SALE,
    active: 'sales',
    name: 'Nova Venda',
    component: NovaVenda,
  },
  {
    path: constants.ROUTES.EDIT_SALE,
    active: 'sales',
    name: 'Editar Venda',
    component: NovaVenda,
  },
  {
    path: constants.ROUTES.USER,
    active: 'home',
    name: 'Novo Usuário',
    component: NovoUsuario,
  },
  {
    path: constants.ROUTES.EDIT_USER,
    active: 'home',
    name: 'Editar Usuário',
    component: NovoUsuario,
  },
  {
    path: constants.ROUTES.TRADE_REPRESENTATIVES,
    active: 'cadastros',
    name: 'Meus Representantes',
    component: TradeRepresentative,
    group: 'Vendas',
  },
  {
    path: constants.ROUTES.NEW_TRADE_REPRESENTATIVES,
    active: 'cadastros',
    name: 'Meus Representantes',
    component: NewTradeRepresentative,
    group: 'Vendas',
  },
  {
    path: constants.ROUTES.EDIT_TRADE_REPRESENTATIVES,
    active: 'cadastros',
    name: 'Meus Representantes',
    component: NewTradeRepresentative,
    group: 'Vendas',
  },
  {
    path: constants.ROUTES.PLAN,
    active: 'home',
    name: 'Meu Plano',
    component: MyPlan,
    group: 'Dados e Configurações',
  },
  {
    path: constants.ROUTES.MY_FINANCIAL_AREA,
    active: 'home',
    name: 'Minha Área Financeira',
    component: MyFinancialArea,
    group: 'Dados e Configurações',
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.ESSENCIAL,
      constants.PLANS_ID.ESSENCIAL_FISCAL,
      constants.PLANS_ID.WORKMOTOR,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.SALES_BY_FAMILY_REPORT,
    active: 'relatorios',
    name: 'Vendas por Família',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.SALES_BY_PAYMENT_FORM_REPORT,
    active: 'relatorios',
    name: 'Vendas por Formas de Pagamento',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.SALES_BY_PRODUCT_SERVICE_REPORT,
    active: 'relatorios',
    name: 'Vendas por Produto/Serviço',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.SALES_BY_TYPE_REPORT,
    active: 'relatorios',
    name: 'Vendas por Tipo/Status',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.SALES_COMMISSIONS_REPORT,
    active: 'relatorios',
    name: 'Comissões/Rendimentos do Mecânico',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.RETURN_FORECAST,
    active: 'relatorios',
    name: 'Previsão de Retorno',
    component: RelatoriosVenda,
    feature: constants.FEATURES.COMISSAO,
  },
  {
    path: constants.ROUTES.RECEIVEDS_BY_PAYMENT_FORM_REPORT,
    active: 'relatorios',
    name: 'Recebimentos por Forma de Pagamento',
    component: FinancialReports,
    feature: constants.FEATURES.RELATORIOS_FINANCEIROS,
  },
  {
    path: constants.ROUTES.REVENUE_PER_PERIOD_REPORT,
    active: 'relatorios',
    name: 'Faturamento por Período',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIOS_DE_VENDAS,
  },
  {
    path: constants.ROUTES.SALES_CANCELED_BUDGETS_REPORT,
    active: 'relatorios',
    name: 'Listagem de Orçamentos Cancelados',
    component: HomeCanceledBudgets,
    feature: constants.FEATURES.RELATORIO_ORCAMENTOS_CANCELADOS,
  },
  {
    path: constants.ROUTES.VALUES_BY_STATUS_REPORT,
    active: 'relatorios',
    name: 'Valores por Status',
    component: FinancialReports,
    feature: constants.FEATURES.RELATORIOS_FINANCEIROS,
  },
  {
    path: constants.ROUTES.PAYMENTS_BY_STATUS_REPORT,
    active: 'relatorios',
    name: 'Resumo Pagamentos por Status',
    component: FinancialReports,
    feature: constants.FEATURES.RELATORIOS_FINANCEIROS,
  },
  {
    path: constants.ROUTES.CASHIER_FLOW_REPORT,
    active: 'relatorios',
    name: 'Fluxo de Caixa',
    component: FinancialReports,
    feature: constants.FEATURES.RELATORIOS_FINANCEIROS,
  },
  {
    path: constants.ROUTES.FINANCIAL_STATEMENT_REPORT,
    active: 'relatorios',
    name: 'DRE - Demonstrativo de Resultado do Exercício',
    component: FinancialReports,
    feature: constants.FEATURES.DRE,
  },
  {
    path: constants.ROUTES.ISSUED_SLIP_REPORT,
    active: 'relatorios',
    name: 'Boletos Emitidos',
    component: IssuedSlip,
    feature: constants.FEATURES.ISSUED_SLIP,
  },
  {
    path: constants.ROUTES.CARD_TAXES_REPORT,
    active: 'relatorios',
    name: 'Taxa de Cartão',
    component: FinancialTaxCard,
    feature: constants.FEATURES.TAXA_DE_CARTAO,
  },
  {
    path: constants.ROUTES.STONE_TRANSACTIONS,
    active: 'relatorios',
    name: 'Transações Stone',
    component: TransactionsStone,
    feature: constants.FEATURES.RELATORIOS_FINANCEIROS,
  },
  {
    path: constants.ROUTES.PURCHASES_BY_STATUS_REPORT,
    active: 'relatorios',
    name: 'Compras por Status',
    component: PurchasesReports,
    feature: constants.FEATURES.RELATORIO_DE_COMPRAS,
  },
  {
    path: constants.ROUTES.APROVES_DISAPROVED_ITENS,
    active: 'relatorios',
    name: 'Itens Aprovados/Não Aprovados',
    component: AprovedDisaprovedItens,
    feature: constants.FEATURES.RELATORIO_DE_COMPRAS,
  },
  {
    path: constants.ROUTES.PURCHASES_BY_PROVIDER_REPORT,
    active: 'relatorios',
    name: 'Compras por Fornecedor',
    component: PurchasesReports,
    feature: constants.FEATURES.RELATORIO_DE_COMPRAS,
  },
  {
    path: constants.ROUTES.RETURN_HISTORY,
    active: 'relatorios',
    name: 'Histórico de Devolução',
    component: ReturnHistory,
    feature: constants.FEATURES.COMISSAO,
  },
  {
    path: constants.ROUTES.CFOPS,
    active: 'cadastros',
    name: 'CFOPs',
    component: CFOPS,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.CFOP,
    active: 'cadastros',
    name: 'Novo CFOP',
    component: NovoCFOP,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_CFOP,
    active: 'cadastros',
    name: 'Editar CFOP',
    component: NovoCFOP,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.TEXTS_FOR_INVOICES,
    active: 'cadastros',
    name: 'Textos Para Notas Fiscais',
    component: TextoParaNotaFiscal,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.TEXT_FOR_INVOICE,
    active: 'cadastros',
    name: 'Novo Texto Para Notas Fiscais',
    component: NewTextFormInvoices,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_TEXT_FOR_INVOICE,
    active: 'cadastros',
    name: 'Editar Texto Para Notas Fiscais',
    component: NewTextFormInvoices,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.SHIPPING_COMPANIES,
    active: 'cadastros',
    name: 'Transportadoras',
    component: Transportadora,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.SHIPPING_COMPANY,
    active: 'home',
    name: 'Nova Transportadora',
    component: NewShippingCompany,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_SHIPPING_COMPANY,
    active: 'home',
    name: 'Editar Transportadora',
    component: NewShippingCompany,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.INTERMEDIARIES,
    active: 'cadastros',
    name: 'Intermediador',
    component: Intermediador,
  },
  {
    path: constants.ROUTES.INTERMEDIARY,
    active: 'home',
    name: 'Novo Intermediador',
    component: NewIntermediary,
  },
  {
    path: constants.ROUTES.EDIT_INTERMEDIARY,
    active: 'home',
    name: 'Editar Intermediador',
    component: NewIntermediary,
  },
  {
    path: constants.ROUTES.PROVIDERS,
    active: 'providers',
    name: 'Fornecedores',
    component: Fornecedores,
    feature: constants.FEATURES.CADASTRO_DE_FORNECEDORES,
  },
  {
    path: constants.ROUTES.NCM_NBS,
    active: 'cadastros',
    name: 'NCM/NBS',
    component: Ncm,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.PROVIDER,
    active: 'providers',
    name: 'Novo Fornecedor',
    component: NewProvider,
  },
  {
    path: constants.ROUTES.EDIT_PROVIDER,
    active: 'providers',
    name: 'Editar Fornecedor',
    component: NewProvider,
  },
  {
    path: constants.ROUTES.COMPANY_TAX_DATA,
    active: 'home',
    name: 'Dados Fiscais',
    component: TaxData,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.TAXES_BY_STATE,
    active: 'cadastros',
    name: 'Tributo por Estado',
    component: TributoPorEstado,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.TAX_BY_STATE,
    active: 'cadastros',
    name: 'Novo Tributo por Estado',
    component: NewTaxByState,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_TAX_BY_STATE,
    active: 'cadastros',
    name: 'Novo Tributo por Estado',
    component: NewTaxByState,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.INVOICES,
    active: 'invoices',
    name: 'Notas Fiscais',
    component: Invoices,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.NFE,
    active: 'invoices',
    name: 'Nova NF-e',
    component: NewNFe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_NFE,
    active: 'invoices',
    name: 'Editar NF-e',
    component: NewNFe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.NFCE,
    active: 'invoices',
    name: 'NFC-e',
    component: NewNFCe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_NFCE,
    active: 'invoices',
    name: 'Editar NFC-e',
    component: NewNFCe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.NEW_SATCFE,
    active: 'invoices',
    name: 'SATCF-e',
    component: NewSATCFe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_SATCFE,
    active: 'invoices',
    name: 'Editar SATCF-e',
    component: NewSATCFe,
  },
  {
    path: constants.ROUTES.BILLS_TO_RECEIVE,
    active: 'financial',
    name: 'Contas a Receber',
    component: BillsToReceive,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
    group: 'Financeiro',
  },
  {
    path: constants.ROUTES.BILL_TO_RECEIVE,
    active: 'financial',
    name: 'Novo Título',
    component: BillToReceive,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
  },
  {
    path: constants.ROUTES.BILL_TO_RECEIVE,
    active: 'financial',
    name: 'Novo Pagamento',
    component: BillToReceive,
    feature: constants.FEATURES.PAGAMENTO_DE_VALE,
  },
  {
    path: constants.ROUTES.EDIT_BILL_TO_RECEIVE,
    active: 'financial',
    name: 'Editar Título',
    component: BillToReceive,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
  },
  {
    path: constants.ROUTES.BILL_TO_PAY,
    active: 'financial',
    name: 'Novo Título',
    component: BillToPay,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
  },
  {
    path: constants.ROUTES.EDIT_BILL_TO_PAY,
    active: 'financial',
    name: 'Editar Título',
    component: BillToPay,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
  },
  {
    path: constants.ROUTES.BILLS_TO_PAY,
    active: 'financial',
    name: 'Contas a Pagar',
    component: BillsToPay,
    feature: constants.FEATURES.CONTAS_A_PAGAR_E_RECEBER,
    group: 'Financeiro',
  },
  {
    path: constants.ROUTES.PURCHASES,
    active: 'products',
    name: 'Compras',
    component: Purchases,
    feature: constants.FEATURES.IMPORTACAO_DE_XML,
  },
  {
    path: constants.ROUTES.PROVIDER_PRODUCTS,
    active: 'providers',
    name: 'Produtos por Fornecedor',
    component: ProviderProducts,
  },
  {
    path: constants.ROUTES.PURCHASE,
    active: 'products',
    name: 'Nova Compra',
    component: Purchase,
    feature: constants.FEATURES.IMPORTACAO_DE_XML,
  },
  {
    path: constants.ROUTES.EDIT_PURCHASE,
    active: 'products',
    name: 'Editar Compra',
    component: Purchase,
    feature: constants.FEATURES.IMPORTACAO_DE_XML,
  },
  {
    path: constants.ROUTES.BIRTHDAYS,
    active: 'relatorios',
    name: 'Aniversariantes',
    component: Birthdays,
    feature: constants.FEATURES.RELATORIO_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.ACTUAL_STOCK_REPORT,
    active: 'relatorios',
    name: 'Listagem de Estoque Atual',
    component: ProductsReports,
    feature: constants.FEATURES.RELATORIO_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.BRAND_FAMILY_REPORT,
    active: 'relatorios',
    name: 'Produtos Sem Marca e Família',
    component: ProductsReports,
  },
  {
    path: constants.ROUTES.MIN_MAX_STOCK_REPORT,
    active: 'relatorios',
    name: 'Estoque Mínimo e Máximo',
    component: ProductsReports,
  },
  {
    path: constants.ROUTES.PRODUCT_HISTORY_REPORT,
    active: 'relatorios',
    name: 'Histórico do Produto',
    component: ProductsReports,
  },
  {
    path: constants.ROUTES.STOCK,
    active: 'products',
    name: 'Nova Movimentação',
    component: NewStock,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.EDIT_STOCK,
    active: 'products',
    name: 'Editar Movimentação',
    component: NewStock,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.STOCKS,
    active: 'products',
    name: 'Movimentação de Estoque',
    component: Stock,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.BATCH_PRICE_CHANGES,
    active: 'products',
    name: 'Alteração de Preço em Lote',
    component: BatchPriceChange,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.BATCH_PRICE_CHANGE,
    active: 'products',
    name: 'Nova Alteração',
    component: NewBatchPriceChange,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.EDIT_BATCH_PRICE_CHANGE,
    active: 'products',
    name: 'Editar Alteração',
    component: NewBatchPriceChange,
    feature: constants.FEATURES.CONTROLE_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.CNAE,
    active: 'cadastros',
    name: 'Novo CNAE',
    component: NewCNAE,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_CNAE,
    active: 'cadastros',
    name: 'Editar CNAE',
    component: NewCNAE,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.CNAES,
    active: 'cadastros',
    name: 'CNAES',
    component: CNAES,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.NFSE,
    active: 'invoices',
    name: 'Nova NFS-e',
    component: NFSe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.EDIT_NFSE,
    active: 'invoices',
    name: 'Editar NFS-e',
    component: NFSe,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.SERVICE_INVOICES,
    active: 'invoices',
    name: 'Service Invoices',
    component: ServiceInvoices,
    feature: constants.FEATURES.NOTAS_FISCAIS_ILIMITADAS,
  },
  {
    path: constants.ROUTES.SAT,
    active: 'home',
    name: 'Novo SAT',
    component: NewSAT,
  },
  {
    path: constants.ROUTES.EDIT_SAT,
    active: 'home',
    name: 'Edição SAT',
    component: NewSAT,
  },
  {
    path: constants.ROUTES.CASHIER_BANK,
    active: 'cadastros',
    name: 'Novo Caixa/Banco',
    component: NewCashierBank,
    feature: constants.FEATURES.CONTROLE_CAIXA_BANCO,
  },
  {
    path: constants.ROUTES.FINANCIAL_MOVEMENT,
    active: 'financial',
    name: 'Movimentação de Saldo',
    component: FinancialMovement,
  },
  {
    path: constants.ROUTES.EDIT_FINANCIAL_MOVEMENT,
    active: 'financial',
    name: 'Movimentação de Saldo',
    component: FinancialMovement,
  },
  {
    path: constants.ROUTES.FINANCIAL_MOVEMENTS,
    active: 'financial',
    name: 'Movimentação de Saldo',
    component: HomeFinancialMovements,
  },
  {
    path: constants.ROUTES.EDIT_CASHIER_BANK,
    active: 'cadastros',
    name: 'Editar Caixa/Banco',
    component: NewCashierBank,
    feature: constants.FEATURES.CONTROLE_CAIXA_BANCO,
  },
  {
    path: constants.ROUTES.CASHIER_BANKS,
    active: 'cadastros',
    name: 'Caixa/Banco',
    component: HomeCashierBank,
    feature: constants.FEATURES.CONTROLE_CAIXA_BANCO,
  },
  {
    path: constants.ROUTES.COMPANY_CONFIGURATIONS,
    active: 'home',
    name: 'Configurações',
    component: Configurations,
  },
  {
    path: constants.ROUTES.CASHIER_BANK_HISTORIC,
    active: 'sales',
    name: 'Caixa',
    component: CashierBankHistoric,
  },

  {
    path: constants.ROUTES.POSITION,
    active: 'home',
    name: 'Novo Cargo',
    component: NewPosition,
  },
  {
    path: constants.ROUTES.PROFILE,
    active: 'home',
    name: 'Novo Perfil',
    component: NewProfile,
  },
  {
    path: constants.ROUTES.PERMISSIONS,
    active: 'home',
    name: 'Permissões de Acesso',
    component: ProfileAccessPermissions,
  },
  {
    path: constants.ROUTES.PROFILES,
    active: 'profiles',
    name: 'Perfis',
    component: Profiles,
  },
  {
    path: constants.ROUTES.POSITIONS,
    active: 'home',
    name: 'Meus Cargos',
    component: Positions,
  },
  {
    path: constants.ROUTES.EMPLOYEE,
    active: 'home',
    name: 'Meus Funcionários',
    component: NewEmployee,
  },
  {
    path: constants.ROUTES.EMPLOYEES,
    active: 'employees',
    name: 'Funcionários',
    component: Employees,
  },
  {
    path: constants.ROUTES.SIMILARITIES,
    active: 'similarities',
    name: 'Similaridades',
    component: Similarities,
  },
  {
    path: constants.ROUTES.SIMILARITY,
    active: 'similarity',
    name: 'Adicionar Similaridade',
    component: Similarity,
  },
  {
    path: constants.ROUTES.QUICK_QUOTES,
    active: 'quick-quotes',
    name: 'Orçamentos Rápidos',
    component: QuickQuotes,
  },
  {
    path: constants.ROUTES.ACCOUNT_PLAN,
    active: 'cadastros',
    name: 'Nova Conta',
    component: NewAccountPlan,
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.EDIT_ACCOUNT_PLAN,
    active: 'cadastros',
    name: 'Editar Conta',
    component: NewAccountPlan,
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.ACCOUNT_PLANS,
    active: 'cadastros',
    name: 'Plano de Contas',
    component: AccountPlans,
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.COST_CENTERS,
    active: 'cadastros',
    name: 'Centro de Custo',
    component: HomeCostCenter,
    feature: constants.FEATURES.CENTRO_DE_CUSTO,
  },
  {
    path: constants.ROUTES.COST_CENTER,
    active: 'cadastros',
    name: 'Novo Centro de Custo',
    component: NewCostCenter,
    feature: constants.FEATURES.CENTRO_DE_CUSTO,
  },
  {
    path: constants.ROUTES.EDIT_COST_CENTER,
    active: 'cadastros',
    name: 'Editar Centro de Custo',
    component: NewCostCenter,
    feature: constants.FEATURES.CENTRO_DE_CUSTO,
  },
  {
    path: constants.ROUTES.BOX_PRISMA,
    active: 'cadastros',
    name: 'Box/Prisma',
    component: PrismaBox,
    feature: constants.FEATURES.PRISMA_BOX,
  },
  {
    path: constants.ROUTES.NEW_PRISMA_BOX,
    active: 'cadastros',
    name: 'Novo Box/Prisma',
    component: NewPrismaBox,
    feature: constants.FEATURES.PRISMA_BOX,
  },
  {
    path: constants.ROUTES.EDIT_PRISMA_BOX,
    active: 'cadastros',
    name: 'Editar Box/Prisma',
    component: NewPrismaBox,
    feature: constants.FEATURES.PRISMA_BOX,
  },
  {
    path: constants.ROUTES.SCHEDULES,
    active: 'sales',
    name: 'Agenda',
    component: Schedules,
    feature: constants.FEATURES.SCHEDULING,
  },
  {
    path: constants.ROUTES.NEW_SCHEDULING,
    active: 'agendamento',
    name: 'Novo Agendamento',
    component: NewScheduling,
    feature: constants.FEATURES.SCHEDULING,
  },
  {
    path: constants.ROUTES.EDIT_SCHEDULING,
    active: 'agendamento',
    name: 'Editar Agendamento',
    component: NewScheduling,
    feature: constants.FEATURES.SCHEDULING,
  },
  {
    path: constants.ROUTES.LINKED_SERVICES,
    active: 'products',
    name: 'Serviços Vinculados',
    component: LinkedServices,
  },
  {
    path: constants.ROUTES.LINKED_SERVICE,
    active: 'products',
    name: 'Adicionar Serviço Vinculado',
    component: NewLinkedServices,
  },
  {
    path: constants.ROUTES.PART_APPLICATION,
    active: 'products',
    name: 'Adicionar Aplicação de Peças',
    component: NewPartAppication,
  },
  {
    path: constants.ROUTES.PART_APPLICATIONS,
    active: 'products',
    name: 'Aplicação de Peças',
    component: PartApplication,
  },
  {
    path: constants.ROUTES.RANKING_SALES_REPORT,
    active: 'relatorios',
    name: 'Ranking dos Produtos/Serviços Vendidos',
    component: RankingSales,
    // feature: constants.FEATURES.RELATORIO_DE_VENDAS,
  },
  {
    path: constants.ROUTES.TITLE_LISTING,
    active: 'relatorios',
    name: 'Listagem de Títulos',
    component: TitleListing,
    feature: constants.FEATURES.LISTAGEM_DE_TITULOS,
  },
  {
    path: constants.ROUTES.INTEGRATIONS,
    active: 'home',
    name: 'Integrações',
    component: Integrations,
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.ESSENCIAL,
      constants.PLANS_ID.WORKMOTOR,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.DIGITAL_ACCOUNT,
    active: 'home',
    name: 'Conta Digital',
    component: DigitalAccount,
    plansAllowed: [
      constants.PLANS_ID.PRIME,
      constants.PLANS_ID.ESSENCIAL,
      constants.PLANS_ID.WORKMOTOR_FISCAL,
    ],
  },
  {
    path: constants.ROUTES.DISCOUNT_GROUP_PRODUCTS,
    active: 'cadastros',
    name: 'Tabela de preços diferenciados produto',
    component: DiscountPriceProducts,
    feature: constants.FEATURES.PREÇOS_DIFERENCIADOS,
  },
  {
    path: constants.ROUTES.DISCOUNT_GROUP_SERVICES,
    active: 'cadastros',
    name: 'Tabela de preços diferenciados serviços',
    component: DiscountGroupService,
    feature: constants.FEATURES.PREÇOS_DIFERENCIADOS,
  },
  {
    path: constants.ROUTES.DISCOUNT_GROUPS,
    active: 'cadastros',
    name: 'Tabela de preços diferenciados',
    component: DiscountGroups,
    feature: constants.FEATURES.PREÇOS_DIFERENCIADOS,
  },
  {
    path: constants.ROUTES.NEW_DISCOUNT_GROUPS,
    active: 'cadastros',
    name: 'Nova tabela de preços diferenciados',
    component: NewDiscountGroup,
    feature: constants.FEATURES.PREÇOS_DIFERENCIADOS,
  },
  {
    path: constants.ROUTES.EDIT_DISCOUNT_GROUPS,
    active: 'cadastros',
    name: 'Editar tabela de preços diferenciados',
    component: NewDiscountGroup,
    feature: constants.FEATURES.PREÇOS_DIFERENCIADOS,
  },
  {
    path: constants.ROUTES.LOSS_REASONS,
    active: 'cadastros',
    name: 'Motivos de Perda',
    component: LossReasons,
    feature: constants.FEATURES.MOTIVOS_DE_PERDA,
  },
  {
    path: constants.ROUTES.NEW_LOSS_REASON,
    active: 'cadastros',
    name: 'Novo motivo de perda',
    component: NewLossReason,
    feature: constants.FEATURES.MOTIVOS_DE_PERDA,
  },
  {
    path: constants.ROUTES.EDIT_LOSS_REASON,
    active: 'cadastros',
    name: 'Editar tabela de motivo de perda',
    component: NewLossReason,
    feature: constants.FEATURES.MOTIVOS_DE_PERDA,
  },
  {
    path: constants.ROUTES.QUOTATIONS,
    active: 'products',
    name: 'Cotação de Peças Online',
    component: Quotations,
    feature: constants.FEATURES.COTACAO_PECAS,
  },
  {
    path: constants.ROUTES.ONLINE_PARTS_CONSULTATION,
    active: 'online-parts-consultation',
    name: 'Consulta de Peças Online',
    component: Fraga,
  },
  {
    path: constants.ROUTES.NEW_QUOTATION,
    active: 'products',
    name: 'Nova Cotação',
    component: QuotationRegister,
    feature: constants.FEATURES.COTACAO_PECAS,
  },
  {
    path: constants.ROUTES.EDIT_QUOTATION,
    active: 'products',
    name: 'Editar Cotação',
    component: QuotationRegister,
    feature: constants.FEATURES.COTACAO_PECAS,
  },
  {
    path: constants.ROUTES.VEHICLE_HISTORY,
    active: 'relatorios',
    name: 'Histórico de Veículo',
    component: VehicleHistory,
    feature: constants.FEATURES.RELATORIO_HISTORICO_VEICULO,
  },
  {
    path: constants.ROUTES.ABC_CURVE,
    active: 'relatorios',
    name: 'Curva ABC de Produtos',
    component: ABCCurve,
    feature: constants.FEATURES.CURVA_ABC,
  },
  {
    path: constants.ROUTES.VOUCHER_PAYMENTS,
    name: 'Pagamento de Vale',
    active: 'financial',
    component: VoucherPayment,
    feature: constants.FEATURES.PAGAMENTO_VALE,
  },
  {
    path: constants.ROUTES.NEW_VOUCHER_PAYMENT,
    name: 'Novo Pagamento de Vale',
    active: 'financial',
    component: NewVoucherPayment,
    feature: constants.FEATURES.PAGAMENTO_VALE,
  },
  {
    path: constants.ROUTES.EDIT_VOUCHER_PAYMENT,
    name: 'Editar de Vale',
    active: 'financial',
    component: NewVoucherPayment,
    feature: constants.FEATURES.PAGAMENTO_VALE,
  },
  {
    path: constants.ROUTES.VOUCHER_ISSUED,
    active: 'relatorios',
    name: 'Relatório de Vales Emitidos',
    component: VouchersIssued,
    feature: constants.FEATURES.RELATORIO_VALES_EMITIDOS,
    group: 'Financeiro',
  },
  {
    path: constants.ROUTES.PRODUCTS_WITHOUT_MOVEMENT,
    name: 'Produtos sem Movimento',
    active: 'relatorios',
    component: ProductsWithoutMovement,
    feature: constants.FEATURES.PRODUTOS_SEM_MOVIMENTO,
  },
  {
    path: constants.ROUTES.PRODUCTS_INVENTORY,
    name: 'Relatório de Inventário',
    active: 'relatorios',
    component: Inventory,
    feature: constants.FEATURES.RELATORIO_DE_INVENTARIO,
  },
  {
    path: constants.ROUTES.INVOICES_REPORT,
    active: 'relatorios',
    name: 'Listagem de Notas Fiscais',
    component: InvoicesReport,
    feature: constants.FEATURES.RELATORIO_SINTETICO,
  },
  {
    path: constants.ROUTES.REPORT_RETURN_LIST,
    active: 'relatorios',
    name: 'Listagem de Devolução',
    component: ReturnListingDevolution,
    feature: constants.FEATURES.RELATORIO_DE_DEVOLUÇÃO,
  },
  {
    path: constants.ROUTES.QUOTES_SUPPLIER,
    active: 'relatorios',
    name: 'Listagem de Cotação por Fornecedor',
    component: QuotesSupplier,
    feature: constants.FEATURES.LISTAGEM_COTACOES_FORNECEDOR,
    group: 'Compras',
  },
  {
    path: constants.ROUTES.LIST_PURCHASES_SUPPLIER,
    active: 'relatorios',
    name: 'Listagem de Compras por Fornecedor',
    component: PurchasesSupplier,
    feature: constants.FEATURES.RELATORIO_DE_LISTAGEM_DE_COMPRAS_POR_FORNECEDOR,
  },
  {
    path: constants.ROUTES.PRODUCT_SERVICE_REVIEWS_WARRANTY,
    active: 'relatorios',
    name: 'Revisões/Garantias de Produtos e Serviços',
    component: ProductServiceReviewsWarrant,
    feature: constants.FEATURES.RELATORIO_DE_REVISAO_DE_PRODUTOS_E_SERVICOS,
  },
  {
    path: constants.ROUTES.PRODUCT_LISTING_LOCATION,
    active: 'relatorios',
    name: 'Listagem de produtos por localização',
    component: ProdtctsListingLocation,
    feature: constants.FEATURES.RELATORIO_LISTAGEM_DE_PRODUTOS_POR_LOCALIZACAO,
  },
  {
    path: constants.ROUTES.PRODUCTS_BY_FAMILIES_OR_BRANDS,
    active: 'relatorios',
    name: 'Listagem de Produto por Famílias/Marcas',
    component: ProductsFamilyOrBrand,
    feature: constants.FEATURES.LISTAGEM_PRODUTOS_FAMILIAS_E_MARCAS,
  },
  {
    path: constants.ROUTES.INVENTORY_MOVEMENT_LISTING,
    active: 'relatorios',
    name: 'Listagem de movimentação de Estoque',
    component: InventoryMovementListing,
    feature:
      constants.FEATURES.RELATORIO_DE_LISTAGEM_DE_MOVIMENTAÇÃO_DE_ESTOQUE,
  },
  {
    path: constants.ROUTES.INVENTORY_COUNT,
    active: 'products',
    name: 'Inventário/Contagem',
    component: InventoryCount,
    feature: constants.FEATURES.INVENTARIO_CONTAGEM,
  },
  {
    path: constants.ROUTES.NEW_INVENTORY_COUNT,
    active: 'products',
    name: 'Inventário/Contagem',
    component: NewInventoryCount,
    feature: constants.FEATURES.INVENTARIO_CONTAGEM,
  },
  {
    path: constants.ROUTES.EDIT_INVENTORY_COUNT,
    active: 'products',
    name: 'Editar Inventário/Contagem',
    component: NewInventoryCount,
  },
  {
    path: constants.ROUTES.CUSTOMER_LIST,
    active: 'relatorios',
    name: 'Listagem de Clientes',
    component: CustomerList,
    feature: constants.FEATURES.LISTAGEM_DE_CLIENTES,
  },
  {
    path: constants.ROUTES.SALES_PER_FAMILY_PRODUCT,
    active: 'relatorios',
    name: 'Vendas por Família(Produto)',
    component: SalesPerFamilyProduct,
    feature: constants.FEATURES.VENDAS_POR_FAMILIA_PRODUTO,
  },
  {
    path: constants.ROUTES.SALES_PER_FAMILY_SERVICE,
    active: 'relatorios',
    name: 'Vendas por Família(Serviço)',
    component: SalesPerFamilyService,
    feature: constants.FEATURES.VENDAS_POR_FAMILIA_SERVICO,
  },
  {
    path: constants.ROUTES.PENDING_CUSTOMERS_SUPPLIERS,
    active: 'relatorios',
    name: 'Pendências de Clientes/Fornecedor',
    component: PendingCustomersSuppliers,
    feature: constants.FEATURES.LISTAGEM_PENDENCIAS,
  },
  {
    path: constants.ROUTES.LIST_OF_EMPLOYEES,
    active: 'relatorios',
    name: 'Listagem de Funcionários',
    component: ListEmployees,
    feature: constants.FEATURES.LISTAGEM_DE_FUNCIONARIOS,
  },
  {
    path: constants.ROUTES.PARTS_PER_APPLICATION,
    active: 'relatorios',
    name: 'Relatório Peças por Aplicação',
    component: PartsPerApplication,
    feature: constants.FEATURES.RELATORIO_PECAS,
  },
  {
    path: constants.ROUTES.ACCOUNTS_RECEIVABLE_REPORT,
    active: 'relatorios',
    name: 'Relatório de Contas a Receber',
    component: AccountsReceivable,
    feature: constants.FEATURES.CONTAS_A_RECEBER_RELATORIO,
  },
  {
    path: constants.ROUTES.ACCOUNTS_PAYABLE_REPORT,
    active: 'relatorios',
    name: 'Relatório de  Contas a Pagar',
    component: AccountsPayable,
    feature: constants.FEATURES.CONTAS_A_PAGAR_RELATORIO,
  },
  {
    path: constants.ROUTES.REPRESENTATIVE_COMISSIONS,
    active: 'relatorios',
    name: 'Comissões de Representantes',
    component: RepresentativeComissions,
    feature: constants.FEATURES.COMISSOES_REPRESENTANTES,
  },
  {
    path: constants.ROUTES.SALES_BY_ATTENDANCE,
    active: 'relatorios',
    name: 'Vendas por Atendimento',
    component: RelatoriosVenda,
    feature: constants.FEATURES.RELATORIO_VENDAS_ATENDIMENTO,
  },
  {
    path: constants.ROUTES.SALE_PASSING,
    active: 'relatorios',
    name: 'Relatório de Venda/OS - Passagens',
    component: SalesPassing,
    feature: constants.FEATURES.RELATORIO_DE_VENDAS_OS_PASSAGENS,
  },
  {
    path: constants.ROUTES.CUSTOMER_CREDIT,
    active: 'customers',
    name: 'Crédito para Cliente',
    component: CustomerCredit,
    feature: constants.FEATURES.CREDITO_PARA_CLIENTE,
  },
  {
    path: constants.ROUTES.PARTS_REQUISITION,
    active: 'products',
    name: 'Requisição de Peças',
    component: PartsRequisition,
    feature: constants.FEATURES.REQUISICAO_DE_PECAS,
  },

  {
    path: constants.ROUTES.NEW_PART_REQUISITION,
    active: 'products',
    name: 'Nova Requisição de Peças',
    component: NewPartsRequisition,
    feature: constants.FEATURES.REQUISICAO_DE_PECAS,
  },
  {
    path: constants.ROUTES.VIEW_PART_REQUISITION,
    active: 'products',
    name: 'Visualizar Requisição de Peças',
    component: NewPartsRequisition,
    feature: constants.FEATURES.REQUISICAO_DE_PECAS,
  },
  {
    path: constants.ROUTES.PROMOTIONAL_PRICE_TABLE,
    active: 'cadastros',
    name: 'Tabela de Preço Promocional',
    component: PromotionalPriceTable,
    feature: constants.FEATURES.TABELA_DE_PRECO_PROMOCIONAL,
  },
  {
    path: constants.ROUTES.NEW_PROMOTIONAL_PRICE_TABLE,
    active: 'cadastros',
    name: 'Nova Tabela de Preço Promocional',
    component: NewPromotionalPriceTable,
    feature: constants.FEATURES.TABELA_DE_PRECO_PROMOCIONAL,
  },
  {
    path: constants.ROUTES.EDIT_PROMOTIONAL_PRICE_TABLE,
    active: 'cadastros',
    name: 'Editar Tabela de Preço Promocional',
    component: NewPromotionalPriceTable,
    feature: constants.FEATURES.TABELA_DE_PRECO_PROMOCIONAL,
  },
  {
    path: constants.ROUTES.HANG_TANGS,
    active: 'products',
    name: 'Etiquetas',
    component: Hangtag,
    feature: constants.FEATURES.ETIQUETAS,
  },
  {
    path: constants.ROUTES.SALES_PER_DAY_REPORT,
    active: 'relatorios',
    name: 'Vendas por Dia',
    component: SalesPerDayReport,
    feature: constants.FEATURES.RELATORIO_VENDAS_POR_DIA,
  },
  {
    path: constants.ROUTES.FAMILY_BRAND_STOCK_REPORT,
    active: 'relatorios',
    name: 'Estoque por Marca/Família',
    component: FamilyBrandStockReport,
    feature: constants.FEATURES.RELATORIO_ESTOQUE_POR_MARCA_FAMILIA,
  },
  {
    path: constants.ROUTES.LIST_CANCELED_ITEMS_REPORT,
    active: 'relatorios',
    name: 'Listagem de Itens Cancelados',
    component: ListCanceledItems,
    feature: constants.FEATURES.RELATORIO_LISTAGEM_DE_ITENS_CANCELADOS,
  },
  {
    path: constants.ROUTES.CUSTOMER_APPROACH_REPORT,
    active: 'relatorios',
    name: 'Abordagem de Clientes',
    component: CustomerApproachReport,
    feature: constants.FEATURES.RELATORIO_ABORDAGEM_DE_CLIENTES,
  },
  ...CLIENT_ROUTES,
];

export default clientRoutes;
