import api from '../services/api'
import config from '../config'

const index = (companyId) =>
  api.get(config.endpoint + `matera/transaction?companyId=${companyId}`)
const show = (id) => api.get(config.endpoint + `matera/transaction/${id}`)
const create = (data) => api.post(config.endpoint + 'matera/transaction', data)

export default {
  index,
  show,
  create,
}
