import React from 'react'

import './styles.css'

const AppError = ({ message }) => {
  return (
    <div id='app-error'>
      {message || 'Ocorreu um erro inesperado :('}
    </div>
  )
}

export default AppError