import React, { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import CustomerCreditFilters from './CustomerCreditFilters';
import CustomerCreditTable from './CustomerCreditTable';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import CustomerCreditModal from './CustomerCreditModal';
import customerCreditRepository from 'repositories/CustomerCredit';

const CustomerCreditMain = () => {
  const [release, setRelease] = useState('');
  const [query, setQuery] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customerCreditId, setCustomerCreditId] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [newCustomerCredit, setNewCustomerCredit] = useState(false);

  const [customerCreditList, setCustomerCreditList] = useState([]);

  const [pageLimit, setPageLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const { companyId } = useAuth();

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const loadCustomerCreditList = async () => {
    setLoading(true);
    try {
      const params = {
        companyId,
        query,
        limit: pageLimit,
        page: currentPage + 1,
        initialDate,
        finalDate,
        type: release,
      };

      const response = await customerCreditRepository.index(params);

      setCustomerCreditList(response.customerCredits);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar os créditos dos cliente',
        'Por favor, tente novamente'
      );
    }
    setLoading(false);
  };

  const handleExitModal = () => {
    setNewCustomerCredit(false);
    setCustomerCreditId(null);
    setRefresh(!refresh);
  };

  useEffect(() => {
    loadCustomerCreditList();
  }, [
    queryDebounced,
    pageLimit,
    currentPage,
    initialDate,
    finalDate,
    release,
    refresh,
  ]);

  return (
    <>
      <CustomerCreditFilters
        setRelease={setRelease}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        initialDate={initialDate}
        finalDate={finalDate}
        setNewCustomerCredit={setNewCustomerCredit}
        handleChangeQuery={handleChangeQuery}
      />
      <CustomerCreditTable
        loading={loading}
        customerCreditList={customerCreditList}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        setNewCustomerCredit={setNewCustomerCredit}
        setCustomerCreditId={setCustomerCreditId}
      />

      {newCustomerCredit && (
        <CustomerCreditModal
          setNewCustomerCredit={setNewCustomerCredit}
          newCustomerCredit={newCustomerCredit}
          customerCreditId={customerCreditId}
          handleExit={handleExitModal}
        />
      )}
    </>
  );
};

export default CustomerCreditMain;
