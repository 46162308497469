import React, { useState, useEffect } from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import NewLossReason from "./FormNewLossReason";
import constants from "../../../../utils/constants";

const ViewNewLossReason = ({ match, history }) => {
  
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb
              data={[
                "Início",
                "Cadastros",
                "Fiscal",
                "Motivos de Perda",
                `${match.params.id ? "Editar" : "Novo"} Motivo de Perda`,
              ]}
              path={[
                constants.ROUTES.HOME,
                null,
                null,
                constants.ROUTES.LOSS_REASONS,
                null,
              ]}
            />
            <span style={{ color: "red", position: "absolute", right: 10 }}>
              * Campos Obrigatórios
            </span>
          </div>
          <NewLossReason
            lossReasonId={match.params.id}
            onCancel={() => history.push('/client/' + constants.ROUTES.LOSS_REASONS)}
            onSubmit={() => history.push(constants.ROUTES.LOSS_REASONS)}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(ViewNewLossReason);
