import React, { useState, useEffect } from "react";
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { Card } from 'components/Card/Card.jsx'
import { Col } from 'react-bootstrap'
import './styles.css'
import AlertModal from "components/AlertModal/AlertModal";
import { toastr } from "react-redux-toastr";
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';

import api from "services/api";
import config from 'config'
import Toggle from "react-toggle";
import { specialChar4 } from "client/components/ToNormalize/ToNormalize";


const FormTextForInvoices = ({ textForInvoicesId, onSubmit, onCancel, modal, handleModal }) => {
  const [code, setCode] = useState(0)
  const [isActive, setIsActive] = useState(true)
  const [text, setText] = useState('')
  const [cancelModal, setCancelModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const companyId = localStorage.getItem('ID_EMPRESA')

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!textForInvoicesId) {
      loadTextForInvoices()
    } else {
      loadLastCode()
    }
  }, [])

  const loadTextForInvoices = async () => {
    setLoading(true)
    try {
      const response = await api.get(config.endpoint + `textforinvoices/${textForInvoicesId}`)

      setCode(response.data.code)
      setIsActive(response.data.isActive)
      setText(response.data.text)

    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar os dados do texto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const loadLastCode = async () => {
    const response = await api.get(config.endpoint + `textforinvoices/last-code/${companyId}`)
    setCode(response.data)
  }

  const handleSubmit = () => {
    if (!text) {
      return toastr.warning('Por favor, preencha o campo para cadastrar o texto.')
    }

    if (!textForInvoicesId) {
      create()
    } else {
      update()
    }
  }

  const create = async () => {
    setLoading(true)
    try {
      const newText = await api.post(config.endpoint + 'textforinvoices', {
        companyId,
        text,
        code,
        isActive
      })
      if (modal) {
        dispatch(change('NFe', 'textsForInvoice', newText.data.id))
        handleModal('texts')
      } else {
        onSubmit()
      }
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao salvar o texto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const update = async () => {
    setLoading(true)
    try {

      const updateText = await api.put(config.endpoint + `textforinvoices/${textForInvoicesId}`, {
        text,
        code,
        isActive
      })
      if (modal) {
        dispatch(change('NFe', 'textsForInvoice', updateText.data.id))
        handleModal('texts')
      } else {
        onSubmit()
      }
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao atualizar o texto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }} >
      <CardForm
        show={true}
        title="Dados Básicos"
        onClick={() => { }}
      >
        <Card content={
          <form id='form-new-text-for-invoices' className="col-xs-12 col-sm-12 col-md-12 col-lg-12 row">
            <Col xs={12} sm={12} md={3} lg={1} >
              <label htmlFor='code'>Código:</label>
              <input
                disabled
                className='form-control foco-input'
                type="text"
                id='code'
                value={code}
              />
            </Col>

            <Col xs={12} sm={12} md={2} lg={2} >
              <label>Status</label>
              <div>
                {" "}
                <Toggle
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} >
              <label htmlFor="text">Texto:<span>*</span></label>
              <textarea className="form-control foco-input" id="text" value={text} onChange={e => setText(specialChar4(e.target.value))} maxLength='255' />
            </Col>

          </form>
        } />

      </CardForm>

      <FormSubmitButtons onCancel={() => setCancelModal(true)} hasId={!!textForInvoicesId} onSubmit={handleSubmit} loading={loading} />

      {cancelModal &&
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar ?'
          onSubmit={!modal ? onCancel : handleModal}
        />
      }
    </div>
  )
}

export default FormTextForInvoices