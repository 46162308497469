import React, { useState } from 'react'
import settingsNFSeRepository from '../../../../../repositories/SettingsNFSe'
import companyModulesRepository from 'repositories/CompanyModules'
import { useAuth } from '../../../../../contexts/auth'
import { toastr } from 'react-redux-toastr'

const ValidateNFSePattern = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const { companyId } = useAuth()

  async function handleVerifyAvailability() {
    setLoading(true)

    try {
      const response = await settingsNFSeRepository.verifyAvailability(companyId)

      if(!response.status) {
        changeNFSeModuleStatus(1) //Status do Módulo: Padrão/Cidade Não Disponível
        setLoading(false)
        return toastr.error(response.message)
      }

      if(!response.pattern.NFSPatterns.offline) {
        changeNFSeModuleStatus(2) //Status do Módulo: Inativo
        onSuccess(response.pattern)
        setLoading(false)
      } else {
        return toastr.error(`Infelizmente o padrão de NFS-e da sua prefeitura não permite integrações. O módulo não está disponível para sua cidade.`)
      }

    } catch(err) {
      setLoading(false)
      toastr.error('Ocorreu um erro ao checar a disponibilidade da cidade. Por favor, tente novamente.')
    }
  }

  async function changeNFSeModuleStatus(statusId) {
    await companyModulesRepository.updateModuleStatus(
      companyId,
      3,
      statusId,
      true
    )
  }

  return (
      <button
        onClick={handleVerifyAvailability}
        disabled={loading}
        className='nfs-pattern-validate-btn'
      >
        <span className={loading ? "fa fa-spinner fa-pulse fa-1x" : ''} style={{ marginRight: loading ? "5px" : '' }} />
        Verificar disponibilidade da cidade
      </button> 
  )
}

export default ValidateNFSePattern