import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import FormNewBatchPrice from './FormNewBatchPrice'
import constants from '../../../../utils/constants'

const ViewNewBatchPriceChange = ({ location, match }) => {
  const productInfo = location.productInfo
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <BreadCrumb
              data={[
                'Início',
                'Produtos',
                'Alteração de Preço em Lote',
                !!match.params.id ? 'Editar Alteração' : 'Nova Alteração',
              ]}
              path={['home', null, 'batch-price-changes', null]}
            />
            <span style={{ color: 'red', position: 'absolute', right: 10 }}>
              * Campos Obrigatórios
            </span>
          </div>
          <FormNewBatchPrice priceChangeId={match.params.id} />
        </Row>
      </Grid>
    </div>
  )
}

export default withRouter(ViewNewBatchPriceChange)
