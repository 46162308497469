import ArrowRoundedButton from 'components/ArrowRoundedButton'
import React from 'react'
import InputMask from 'react-input-mask'

export default function YearInput({ value, setValue, showArrows }) {
  function handleChange(newValue) {
    setValue(`${newValue}`)
  }

  function handleIncreaseYear() {
    handleChange(`${parseInt(value) + 1}`)
  }

  function handleDecreaseYear() {
    handleChange(`${parseInt(value) - 1}`)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
      {showArrows && (
        <ArrowRoundedButton direction="left" action={handleDecreaseYear} />
      )}
      <InputMask
        style={{
          width: '80px',
          textAlign: 'center',
        }}
        mask={'1999'}
        disableUnderline
        alwaysShowMask={false}
        formatChars={{
          9: '[0-9]',
          1: '[1-9]',
        }}
        type="text"
        className="form-control foco-input"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
      {showArrows && (
        <ArrowRoundedButton direction="right" action={handleIncreaseYear} />
      )}
    </div>
  )
}
