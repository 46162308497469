import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from './PurchaseConfigurationForm';
import companiesRepository from '../../../../repositories/Companies';
import { useAuth } from '../../../../contexts/auth';

const PurchaseForm = () => {
  const { loadCompany, companyId, company } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company) {
      const initialValues = {
        advancedPricing: company.companyConfig.advancedPricing,
        automaticFreightApportionment:
          company.companyConfig.automaticFreightApportionment,
        automaticApportionmentOfOtherExpenses:
          company.companyConfig.automaticApportionmentOfOtherExpenses,
        automaticInsurancePooling:
          company.companyConfig.automaticInsurancePooling,
      };
      dispatch(initialize('purchaseConfiguration', initialValues));
    }
  }, [company]);

  async function handleUpdatePurchaseConfiguration(values) {
    const {
      advancedPricing,
      automaticFreightApportionment,
      automaticApportionmentOfOtherExpenses,
      automaticInsurancePooling,
    } = values;

    try {
      await companiesRepository.updatePurchaseConfiguration(companyId, {
        advancedPricing,
        automaticFreightApportionment,
        automaticApportionmentOfOtherExpenses,
        automaticInsurancePooling,
      });
      toastr.success('Configurações de Compra salvas com sucesso.');
      loadCompany();
    } catch (err) {
      toastr.warning('Ocorreu um erro ao atualizar as configurações de compra');
    }
  }

  const initialValues = {
    advancedPricing: false,
    automaticFreightApportionment: false,
    automaticApportionmentOfOtherExpenses: false,
    automaticInsurancePooling: false,
  };

  return (
    <Form
      onSubmit={handleUpdatePurchaseConfiguration}
      initialValues={initialValues}
    />
  );
};

export default PurchaseForm;
