import config from '../config'
import api from '../services/api'

const create = async (data) => {
  return await api.post(config.endpoint + 'quick-quote/', data)
}

const update = async (id, data) => {
  return await api.put(config.endpoint + `quick-quote/${id}`, data)
}

const getLastCode = async (companyId) => {
  const response = await api.get(
    config.endpoint + `quick-quote/${companyId}/last-one`
  )
  return parseInt(response.data.lastCode) + 1
}

const getById = async (id) => {
  return await api.get(config.endpoint + `quick-quote/single/${id}`)
}

const getAll = async (companyId, params) => {
  return await api.get(config.endpoint + `quick-quote/${companyId}`, {
    params,
  })
}

const deleteQuickQuote = async (id) => {
  return await api.post(config.endpoint + `quick-quote/${id}/delete`)
}

export default {
  create,
  update,
  getAll,
  getById,
  getLastCode,
  deleteQuickQuote,
}
