import React from 'react'
import Button from "client/components/CustomButton/CustomButton.jsx";
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { currency } from 'client/components/ToNormalize/ToNormalize';

export default function RecurringSignatureDetails({ plan, signature, signaturePayment }) {
  const creditCardValidity = format(new Date(new Date(new Date().setFullYear(signaturePayment.Year_Validity)).setMonth(signaturePayment.Month_Validity - 1)), 'MM/yyyy')
  const signatureEndDate = !signature.End_Date ? '' : format(new Date(signature.End_Date), 'dd/MM/yyyy')

  return (
    <div>
      <p id="bdPlanoBody">Sua Próxima cobrança será no dia <strong>{signatureEndDate}</strong></p>
      <p id="bdPlanoBody">O valor da cobrança mensal é de <strong>{currency(plan.Price)}</strong></p>

      <p>Cartão que termina com {signaturePayment.Card_Number.toString().substring(signaturePayment.Card_Number.length - 4, signaturePayment.Card_Number.length)}</p>
      <p><span id="bdPlanoSpan">Vencimento: {creditCardValidity}</span></p>

      <Link to="payment-details">
        <Button id="btn-prosseguir" type="button" fill>Atualizar Cartão de Crédito</Button>
      </Link>
    </div>
  )
}