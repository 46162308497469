import api from 'services/api'
import config from '../config'

export const getByFipe = async (fipeCode) => {
  const res = await api.get(
    config.endpoint + `tabelaFipe/single/by-fipe/${fipeCode}`
  )

  return res.data
}

export const getModelsByBrand = async (brand) => {
  const { data } = await api.get(`tabelaFipe/byBrands/${brand}`)
  return data
}

export const getBrands = async () => {
  const { data } = await api.get(`tabelaFipe/getBrands`)
  return data
}

const getFipeByType = async (type) => {
  const reponse = await api.get(`table-fipe/by-type/`, { params: type })
  return reponse.data
}

const getOnlyBrandsByType = async (type) => {
  const reponse = await api.get(`table-fipe/brands-by-type/`, {
    params: type,
  })
  return reponse.data
}

const TableFipeRepository = {
  getByFipe,
  getModelsByBrand,
  getBrands,
  getFipeByType,
  getOnlyBrandsByType,
}

export default TableFipeRepository
