import React, { useEffect, useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import ExpenseForecastReportDocument from './ExpenseForecastReportDocument'
import './styles.css'
import { addDays, format } from 'date-fns'
import BillsToPayRepository from 'repositories/BillsToPay'

const ExpenseForecast = ({ location, history }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [result, setResult] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search, history])

  const loadQuery = async searchParams => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const companyId = searchParams.get('companyId')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      setHasError(false)

      return loadReport({ initialDate, finalDate, companyId })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (params) => {
    try {
      const data = await BillsToPayRepository.getExpenseForecast({
        ...params,
      })

      setResult(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError)
    return (
      <AppError message='Ocorreu um erro ao carregar o relatório. Por favor, tente novamente' />
    )

  return (
    <div id='expense-forecast-report-page'>
      <main>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <ExpenseForecastReportDocument
            result={result}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default ExpenseForecast
