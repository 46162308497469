import { currency } from 'client/components/ToNormalize/ToNormalize';
import React, { memo } from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";

const TableByStatus = ({ data, loading }) => {
  return (
    <>
      <h4><strong>Valores por Status (R$)</strong></h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: "Status",
            accessor: "description",
            width: 200,
            Footer: (<strong>Total</strong>),
            getFooterProps: () => {
              return {
                style: {
                  background: '#E5E5E5'
                }
              }
            }
          },
          {
            Header: "Qtd",
            accessor: "quantity",
            Footer: (<strong>{data[0]?.quantityTotal ? data[0].quantityTotal : 0}</strong>),
            getFooterProps: () => {
              return {
                style: {
                  background: '#E5E5E5'
                }
              }
            }
          },
          {
            Header: "Valor",
            accessor: "amount",
            Cell: props => currency(props.value),
            Footer: (<strong>{currency(data[0]?.valueTotal ? data[0].valueTotal : '')}</strong>),
            getFooterProps: () => {
              return {
                style: {
                  background: '#E5E5E5'
                }
              }
            }
          },
        ]}
        defaultPageSize={4}
        loading={loading}
        showPagination={false}
        sortable={false}
        showPaginationTop={false}
        showPaginationBottom={false}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Nenhuma informação encontrada'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </>
  )
}

export default memo(TableByStatus)
