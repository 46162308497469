import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Card from '../../../components/Card/Card';
import { format, isBefore } from 'date-fns';
import useFilters from '../../../hooks/useFilters';
import purchasesRepository from '../../../repositories/Purchases';
import massPriceChangeRepository from '../../../repositories/MassPriceChanges';
import { useAuth } from '../../../contexts/auth';
import { currency } from '../../components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from '../../../utils/dateHelpers';
import SearchInput from 'components/SearchInput';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import ConfirmPurchaseModal from 'client/views/BatchPriceChange/NewBatchPriceChange/FormNewBatchPrice/PurchaseSelectModal/ConfirmPurchaseModal';
import { toastr } from 'react-redux-toastr';

export const HomePurchaseSelect = ({ handleSelectPurchase }) => {
  const [loading, setLoading] = useState(false);
  const [isConfirmModalDataOpen, setIsConfirmModalDataOpen] = useState(false);
  const [isFirstPriceChange, setIsFirstPriceChange] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const { companyId } = useAuth();
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  const [selectedPurchase, setSelectedPurchase] = useState({});

  async function handleLoadPurchases(initialDate, finalDate) {
    try {
      setLoading(true);

      const params = {
        initialDate,
        finalDate,
        purchaseStatusId: 2,
      };

      const response = await purchasesRepository.getAllPurchasesByCompany(
        companyId,
        params
      );
      setPurchases(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function handleFilters(product) {
    const querySearch = [
      String(product['code']),
      product['invoice'],
      product['request'],
      product['Provider']?.['companyName'],
    ];
    return filterByQuery(querySearch) && handleFilterDate(product);
  }

  function handleFilterDate({ entryAt }) {
    if (!initialDate && !finalDate) return true;

    if (initialDate && !finalDate) {
      if (initialDate <= entryAt) return true;
    }

    if (!initialDate && finalDate) {
      if (finalDate >= entryAt) return true;
    }

    if (initialDate && finalDate) {
      return initialDate <= entryAt && finalDate >= entryAt;
    }

    return false;
  }

  function getBackgroundColorByPurchaseType(purchaseType) {
    const backgroundColors = {
      XML: '#428bca',
      Manual: '#5cb85c',
    };

    return backgroundColors[purchaseType];
  }

  const handleValidateSubmit = () => {
    if (
      !initialDate ||
      !finalDate ||
      isBefore(getDateOnlyFromDate(finalDate), getDateOnlyFromDate(initialDate))
    ) {
      return toastr.warning('Selecione um período válido');
    }

    handleLoadPurchases(initialDate, finalDate);
  };

  async function handleValidatePurchase(purchase) {
    const response = await massPriceChangeRepository.getByPurchaseId(
      purchase.code
    );

    setSelectedPurchase(purchase);
    setIsConfirmModalDataOpen(true);
    setIsFirstPriceChange(response);
  }

  return (
    <Card
      content={
        <div>
          <div
            style={{
              width: '100%',
              height: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginBottom: '10px',
              marginTop: '-10px',
            }}
          >
            <span
              style={{ color: 'red', fontWeight: 'normal', fontSize: '12px' }}
            >
              <strong>Apenas compras finalizadas serão exibidas</strong>
            </span>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <div
              className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
              style={{
                margin: 0,
                marginLeft: '-20px',
                padding: 0,
                width: '380px',
              }}
            >
              <SearchInput
                placeholder="Pesquisa por Compra, Nota, Pedido ou Fornecedor"
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '220px',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '80px' }}>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ width: '140px' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                width: '220px',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '80px' }}>
                <strong>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ width: '140px' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <button
                className="btn btn-sucesso"
                style={{
                  padding: '7px 30px',
                }}
                disabled={!initialDate || !finalDate || loading}
                onClick={handleValidateSubmit}
              >
                Processar
              </button>
            </div>
          </div>
          <ReactTable
            style={{
              width: '100%',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            data={purchases.filter(handleFilters)}
            columns={[
              {
                Header: 'Dt. Entrada',
                accessor: 'entryAt',
                width: 100,
                Cell: (props) =>
                  format(
                    new Date(getDateOnlyFromDate(props.value)),
                    'dd/MM/yyyy'
                  ),
              },
              {
                Header: 'Compra',
                accessor: 'code',
                headerClassName: 'text-center',
                width: 70,
                className: 'texto',
              },
              {
                Header: 'Tipo',
                accessor: 'type',
                headerClassName: 'text-center',
                width: 120,
                className: 'texto',
                Cell: (props) => {
                  return (
                    <button
                      className="admin-custom-label"
                      style={{
                        backgroundColor: getBackgroundColorByPurchaseType(
                          props.value
                        ),
                      }}
                    >
                      {props.value}
                    </button>
                  );
                },
              },
              {
                Header: 'Nota',
                accessor: 'invoice',
                headerClassName: 'text-center',
                width: 60,
                className: 'texto',
              },
              {
                Header: 'Pedido',
                accessor: 'request',
                headerClassName: 'text-center',
                width: 80,
                className: 'texto',
              },
              {
                Header: 'Fornecedor',
                accessor: 'Provider.companyName',
                headerClassName: 'text-center',
                className: 'texto',
              },
              {
                Header: 'Valor',
                accessor: 'total',
                width: 110,
                Cell: (props) => currency(props.value),
              },
              {
                Header: '',
                accessor: '',
                width: 75,
                Cell: (props) => (
                  <Link to={{}}>
                    <FontAwesomeIcon
                      cursor="pointer"
                      icon={faCirclePlus}
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      color="#5cb85c"
                      onClick={() => handleValidatePurchase(props.original)}
                    />
                  </Link>
                ),
              },
            ]}
            defaultPageSize={10}
            loading={loading}
            showPagination
            sortable
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            defaultSorted={[
              {
                id: 'code',
                desc: true,
              },
            ]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Nenhuma compra encontrada"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
          {isConfirmModalDataOpen && (
            <ConfirmPurchaseModal
              onCancel={() => setIsConfirmModalDataOpen(false)}
              isFirstPriceChange={isFirstPriceChange}
              onSubmit={() => {
                handleSelectPurchase(selectedPurchase);
                setIsConfirmModalDataOpen(false);
              }}
            />
          )}
        </div>
      }
    />
  );
};
