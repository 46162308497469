const { default: api } = require('v2/services/api');

const ExportXMLRepository = {
  async generateExport(data) {
    return await api.post('/export-xml/generate', data);
  },
  async consultProtocol(params) {
    return await api.get('/export-xml/consult', {
      params,
    });
  },
};

export { ExportXMLRepository };
