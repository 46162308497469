import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr';
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, subMonths } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import GraphicByFamily from './GraphicByFamily'
import TableByFamily from './TableByFamily'
import api from 'services/api'
import config from 'config'
import DownloadXlsButton from 'components/DownloadXlsButton'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import UpgradeFreePlanModal from 'client/components/UpgradeFreePlanModal';

import './styles.css'
import { getDateOnlyFromDate } from 'utils/dateHelpers'
export default class PorFamilia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            graphicData: [['Família', 'Valor (R$)', { role: "tooltip", type: "string" }]],
            company_id: localStorage.getItem('ID_EMPRESA'),
            isPlanFree: localStorage.getItem('isPlanFree'),
            custom: false,
            isUpgradeFreePlanModalOpen: false,
            period: 15,
            initialDate: '',
            finalDate: '',
            opacity: '100%',
            loading: false,
            optionType: 0,
            table: [],
            tableAny: [],
            tableToExport: [],
            toExportAny: []
        }
    }

    componentDidMount() {
        if (this.state.company_id) this.loadSales()
    }

    loadSales = async () => {
        this.setState({
            loading: true,
            opacity: '25%',
            tableToExport: [],
            toExportAny: [],
            table: [],
            tableAny: [],
            optionType: 0
        })
        const { period, custom, initialDate, finalDate } = this.state

        let params = {}
        params.Company_id = this.state.company_id

        // SE USADA OS CAMPOS DE DATA INICIAL E FINAL
        if (custom) {
            params.initialDate = format(getDateOnlyFromDate(initialDate), 'yyyy-MM-dd') + ' 00:00:00'
            params.finalDate = format(getDateOnlyFromDate(finalDate), 'yyyy-MM-dd') + ' 23:59:59'
        }
        else {
            let dateNow = new Date().getTime()

            /// CALCULA A DIFERENÇA COM BASE NO PERÍODO
            let diference = period * 3600 * 24 * 1000
            let initialDate = dateNow - diference

            params.initialDate = new Date(initialDate)
            params.finalDate = new Date(dateNow)
        }

        try {
            const res = await api.get(config.endpoint + 'sales/by-date', { params })

            let sales = res.data.filter(s => s.SalesStatus_id === 5)

            this.loadItems(sales)
        }
        catch (err) {
            toastr.warning("Ocorreu um erro ao carregar os dados", "Por favor, tente novamente")
        }
    }

    loadItems = async sales => {

        /// SE NÃO HOUVER INFORMAÇÃO
        if (sales.length === 0) {

            let graphicData = [['Família', 'Valor (R$)', { role: "tooltip", type: "string" }]]
            graphicData.push(['Sem informação', 1, 'Sem informação'])
            return this.setState({
                graphicData,
                loading: false,
                opacity: '100%',
                table: [],
                tableToExport: []
            })
        }

        let obj = []
        /// BUSCA OS ITEMS DE CADA VENDA
        for (let i = 0; i < sales.length; i++) {
            const res = await api.get(config.endpoint + `salesitem/saleById/${sales[i].id}`)
            for (let j = 0; j < res.data.length; j++) {
                res.data[j].Date = sales[i].Date
                res.data[j].Sale_Code = sales[i].Code
            }
            obj = obj.concat(res.data)
        }

        this.processGraphic(obj)
    }

    handleSort = (table, atr, order = 'dec') => {//Ordena atributos decrescentes
        if (order === 'dec') return table.sort((a, b) => b[atr] - a[atr])
        return table.sort((a, b) => a[atr] - b[atr])
    }

    processGraphic = async data => {
        let products = data.filter(c => c.Type === 'Produto')
        let services = data.filter(c => c.Type === 'Serviço')

        let table = this.processData(products, "Produto")
        let table2 = this.processData(services, "Serviço")

        let topFamiliesProd = this.handleTopTen(this.handleSort(table, 'amount'))
        let topFamiliesServ = this.handleTopTen(this.handleSort(table2, 'amount'))

        table = this.handleSort(table.concat(table2), 'amount')

        // CRIAR ARRAY USADO PELO GRÁFICO
        let graphicData = this.handleGraphic(table.filter((t, i) => i < 10))

        this.setState({
            table: table.filter((t, i) => i < 10),
            graphicData,
            tableAny: table
        })

        let arrayTableToExport1 = await this.processTableToExport(products, 'Produto', topFamiliesProd)
        let arrayTableToExport2 = await this.processTableToExport(services, 'Serviço', topFamiliesServ)

        const toExportAny = this.handleSort(arrayTableToExport1.concat(arrayTableToExport2), 'amount')
        const tableToExport = this.handleSort(this.handleToExport(table.filter((t, i) => i < 10), toExportAny), 'sale_code', 'cre')

        this.setState({
            toExportAny,
            tableToExport,
            loading: false,
            opacity: '100%',
        })

    }

    handleTopTen = table => {//Recebe uma tabela já ordenada e retorna as 10 primeiras posições, indexando o ID e o TIPO
        let array = []

        for (let i = 0; i < 10; i++) {
            array.push(table[i] ? table[i].id : 0)
        }

        return array
    }

    handleToExport = (table, toExportAny) => {//Monta os dados para exportação do XLS
        let array = this.handleTopTen(table)
        let tableToExport = []

        for (let i = 0; i < toExportAny.length; i++) {
            for (let j = 0; j < array.length; j++) {
                if (toExportAny[i].family_id === array[j]) {
                    toExportAny[i].amount = toExportAny[i].amount.toString().includes('R$') ? toExportAny[i].amount : currency(toExportAny[i].amount)
                    tableToExport.push(toExportAny[i])
                }
            }
        }

        return tableToExport
    }

    processTableToExport = async (data, type, topFamilies) => {
        let array = []
        for (let i = 0; i < data.length; i++) {
            let withSaleId = false
            let withFamilyId = false
            let family_description
            let family_id = data[i].Type === 'Produto' ?
                data[i].Products.Family_id
                :
                data[i].Services.Family_id

            for (let k = 0; k < topFamilies.length; k++) {
                if (family_id === topFamilies[k]) { //Se a familia do item não pertencer ao top 10, não será adicionado ao array
                    for (let j = 0; j < array.length; j++) { //verifica se já há informações de sale_code e family_description, evitando gets a mais na API

                        if (type === 'Produto') {
                            if (data[i].Products.Family_id === array[j].family_id) {
                                withFamilyId = true
                                family_description = array[j].family_description
                            }
                        } else {
                            if (data[i].Services.Family_id === array[j].family_id) {
                                withFamilyId = true
                                family_description = array[j].family_description
                            }
                        }
                    }

                    if (!withFamilyId) { //Se ainda não temos o family_description, vamos buscá-lo
                        try {
                            const family = await api.get(config.endpoint + `families/search/ById/${this.state.company_id}/${family_id}`)
                            family_description = family.data.data?.Description
                        } catch (err) {
                            family_description = 0
                        }
                    }

                    const obj = {
                        date: data[i].Date.substring(0, 10).split('-').reverse().join('/'),
                        sale_id: data[i].Sale_id,
                        sale_code: data[i].Sale_Code,
                        family_id: family_id,
                        family_description: family_description,
                        type: data[i].Type,
                        amount: data[i].Amount
                    }

                    array.push(obj)
                }
            }
        }

        return array
    }

    processData = (data, type) => {
        const table = []
        //PROCESSA CADA ARRAY PRA RETORNAR A TABELA
        for (let i = 0; i < data.length; i++) {
            let exist = false;
            let obj = {}
            let typeOf

            // VERIFICAÇÃO PARA PROCURAR NO OBJETO SE FOR PRODUTO OU SERVIÇO
            if (type === 'Produto') typeOf = data[i].Products
            else typeOf = data[i].Services

            for (let j = 0; j < table.length; j++) {
                if (table[j].id === typeOf.Family_id) {
                    table[j].amount += data[i].Amount
                    exist = true
                }
            }

            if (!exist) {
                obj.id = typeOf.Family_id
                obj.family = typeOf.Families?.Description
                obj.amount = data[i].Amount
                obj.type = type
                table.push(obj)
            }
        }
        return table;
    }

    handlePeriod = async e => {
        const { value, name } = e.target
        await this.setState({
            [name]: value,
            initialDate: '',
            finalDate: '',
            opacity: '25%',
            table: [],
            tableAny: [],
            tableToExport: [],
            toExportAny: [],
            graphicData: [['Família', 'Valor (R$)', { role: "tooltip", type: "string" }]],
            optionType: 0
        })

        if (value === "0") {
            return this.setState({
                custom: true
            })
        }

        await this.setState({ custom: false })
        if (this.state.company_id) this.loadSales()
    }

    handleProcess = () => {
        const { initialDate, finalDate } = this.state

        if (!initialDate || !finalDate) return toastr.warning('Insira as duas datas para processar')

        let initYear = new Date(initialDate).getFullYear().toString()
        let finalYear = new Date(finalDate).getFullYear().toString()

        if (initYear.length > 4 || finalYear.length > 4) {
            return toastr.warning("Ano inválido")
        }

        let initTime = new Date(initialDate).getTime()
        let finalTime = new Date(finalDate).getTime()

        if (finalTime < initTime) return toastr.warning("Data Final deve ser maior que a Data Inicial")

        if (this.state.company_id) this.loadSales()
    }

    handleGraphic = table => {
        let graphicData = [['Família', 'Valor (R$)', { role: "tooltip", type: "string" }]] // ESTE ULTIMO OBJETO INFORMA O QUE SERÁ MOSTRADO AO PASSA O MOUSE POR CIMA
        for (let i = 0; i < table.length; i++) {
            graphicData.push([
                table[i].family,
                table[i].amount,
                currency(table[i].amount)
            ])
        }

        return graphicData
    }

    handleType = event => {
        const { value, name } = event.target
        const { tableAny, toExportAny } = this.state
        this.setState({ [name]: value, loading: true, opacity: '25%', table: [], tableToExport: [] })

        switch (value) {
            case "":
                return this.setState({
                    table: tableAny.filter((t, i) => i < 10),
                    tableToExport: this.handleSort(this.handleToExport(tableAny.filter((t, i) => i < 10), toExportAny), 'sale_code', 'cre'),
                    loading: false,
                    graphicData: this.handleGraphic(tableAny.filter((t, i) => i < 10)),
                    opacity: '100%'
                })
            case "1":
                return this.setState({
                    table: tableAny.filter(t => t.type === 'Produto').filter((t, i) => i < 10),
                    tableToExport: this.handleSort(this.handleToExport(tableAny.filter(t => t.type === 'Produto').filter((t, i) => i < 10), toExportAny), 'sale_code', 'cre'),
                    loading: false,
                    graphicData: this.handleGraphic(tableAny.filter(t => t.type === 'Produto').filter((t, i) => i < 10)),
                    opacity: '100%'
                })
            case "2":
                return this.setState({
                    table: tableAny.filter(t => t.type === 'Serviço').filter((t, i) => i < 10),
                    tableToExport: this.handleSort(this.handleToExport(tableAny.filter(t => t.type === 'Serviço').filter((t, i) => i < 10), toExportAny), 'sale_code', 'cre'),
                    loading: false,
                    graphicData: this.handleGraphic(tableAny.filter(t => t.type === 'Serviço').filter((t, i) => i < 10)),
                    opacity: '100%'
                })
            default:
                return this.setState({
                    table: tableAny.filter((t, i) => i < 10),
                    tableToExport: this.handleSort(this.handleToExport(tableAny.filter((t, i) => i < 10), toExportAny), 'sale_code', 'cre'),
                    loading: false,
                    graphicData: this.handleGraphic(tableAny.filter((t, i) => i < 10)),
                    opacity: '100%'
                })
        }
    }


    render() {
        const { isUpgradeFreePlanModalOpen, isPlanFree, custom, period, initialDate, finalDate, table, tableAny, loading, graphicData, opacity, tableToExport, optionType } = this.state

        return (
            <div className="relatorio-por-familia-container">
                <header>
                    <div>
                        <span><strong>Período:</strong></span>
                        <select className="form-control foco-input" name="period" value={period} onChange={this.handlePeriod}>
                            <option value={15}>Últimos 15 dias</option>
                            <option value={30}>Últimos 30 dias</option>
                            {
                                isPlanFree === 'false' && (
                                    <>
                                        <option value={60}>Últimos 60 dias</option>
                                        <option value={0}>Personalizar</option>
                                    </>
                                )
                            }
                        </select>
                    </div>

                    <div>
                        <span><strong>Tipo:</strong></span>
                        <select disabled={loading || !tableAny.length} className="form-control foco-input" value={optionType} name="optionType" onChange={this.handleType}>
                            <option value="">Todos</option>
                            <option value="1">Produtos</option>
                            <option value="2">Serviços</option>
                        </select>
                    </div>

                    {custom && <>
                        <div>
                            <span><strong>Data Inicial:</strong></span>
                            <br />
                            <input
                                className="form-control foco-input"
                                type="date"
                                name="initialDate"
                                value={initialDate}
                                onChange={event => this.setState({ initialDate: event.target.value })}
                            />
                        </div>

                        <div>
                            <span><strong>Data Final:</strong></span>
                            <input
                                className="form-control foco-input"
                                type="date"
                                name="finalDate"
                                value={finalDate}
                                onChange={event => this.setState({ finalDate: event.target.value })}
                            />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <button
                                className='btn btn-sucesso'
                                disabled={loading}
                                onClick={this.handleProcess}>
                                <span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} />Processar
                            </button>
                        </div>
                    </>}

                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <DownloadXlsButton
                            archiveName='VendasPorFamília'
                            data={tableToExport}
                            className='btn btn-export'
                            disabled={loading || !tableToExport.length}
                            columns={[
                                {
                                    name: 'DATA',
                                    acessor: 'date'
                                },
                                {
                                    name: 'N. VENDA',
                                    acessor: 'sale_code'
                                },
                                {
                                    name: 'TIPO',
                                    acessor: 'type'
                                },
                                {
                                    name: 'FAMÍLIA',
                                    acessor: 'family_description'
                                },
                                {
                                    name: 'VALOR (R$)',
                                    acessor: 'amount'
                                }
                            ]}
                        >
                            <span className={loading && "fa fa-spinner fa-pulse fa-1x"}
                                style={{ marginRight: "5px" }}
                            ></span>
                            <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xls
                        </DownloadXlsButton>
                    </div>
                </header>
                {
                    isPlanFree === 'true' && (
                        <div className="unlock-badge-container">
                            <span
                                className='sales-report-unlock-badge'
                                onClick={() => this.setState({ isUpgradeFreePlanModalOpen: true })}
                            >
                                Desbloquear dados antes de {format(subMonths(new Date(), 1), "dd 'de' MMMM", {
                                    locale: pt
                                })}
                            </span>
                        </div>
                    )
                }

                <section>
                    <div style={{ padding: 5 }} >
                        <TableByFamily data={table} loading={loading} opacity={opacity} />
                    </div>

                    <div style={{ padding: 5, display: 'flex', flexDirection: 'column', marginLeft: '5%' }}>
                        <GraphicByFamily data={graphicData} opacity={opacity} />
                    </div>
                </section>

                <UpgradeFreePlanModal show={isUpgradeFreePlanModalOpen} onCancel={() => this.setState({ isUpgradeFreePlanModalOpen: false })} />
            </div>
        )
    }
}
