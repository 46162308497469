import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  width: 60%;
  height: 40px;
  margin-left: auto;
  justify-content: right;
  gap: 10px;
`
