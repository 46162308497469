import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faWindowClose,
  faSyncAlt,
  faPrint,
  faShareAltSquare,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';

import { SERVICE_INVOICE_STATUS_DESCRIPTION } from '../../../utils/constants';
import AlertModal from 'components/AlertModal/AlertModal';

import NFSesRepository from '../../../repositories/NFSes';
import settingsNFSeRepository from '../../../repositories/SettingsNFSe';
import NFSePatternsRepository from 'repositories/NFSePatterns';
import addressRepository from '../../../repositories/Addresses';
import InvoiceProcessLoader from 'client/components/InvoiceProcessLoader';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import ShareNFSe from 'client/components/ShareNFSe/ShareNFSe';

const NFSeActions = ({
  NFSe,
  onChange,
  onEdit,
  onDelete,
  setIsNFSeIssueErrorModalOpen,
  setNfseErrorDescription,
}) => {
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvoiceLoaderOpen, setIsInvoiceLoaderOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [patternId, setPatternId] = useState();
  const [allowCancel, setAllowCancel] = useState(true);
  const [isShareNFSeModalOpen, setIsShareNFSeModalOpen] = useState(false);

  const isOpen = NFSe.status === SERVICE_INVOICE_STATUS_DESCRIPTION.OPEN;
  const isOpenWithErrors =
    NFSe.status === SERVICE_INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS;
  const isProcessing =
    NFSe.status === SERVICE_INVOICE_STATUS_DESCRIPTION.PROCESSING;
  const isIssued = NFSe.status === SERVICE_INVOICE_STATUS_DESCRIPTION.ISSUED;

  const { companyId } = useAuth();

  useEffect(() => {
    if (!companyId) return;
    getNFSeSettingsByCompany();
  }, [companyId]);

  async function handleCancelInvoice() {
    setLoading(true);
    try {
      await NFSesRepository.cancel(NFSe.id, { patternId });
      const newNFSe = {
        ...NFSe,
        status: 'Cancelada',
      };
      onChange(newNFSe);
      setLoading(false);
      setShowCancelInvoiceModal(false);
      toastr.success('NFS-e Cancelada com sucesso');
    } catch (err) {
      setLoading(false);
      setShowCancelInvoiceModal(false);
      toastr.warning(
        'Retorno da prefeitura',
        !!err.response.data.message &&
          err.response.data.message ===
            'Efetivado - A tentativa de cancelamento não obteve resultado do município. Tente novamente em alguns minutos.'
          ? err.response.data.message
          : 'Ocorreu um erro ao cancelar a NFS-e. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleConsultInvoice() {
    setIsInvoiceLoaderOpen(true);

    try {
      const response = await NFSesRepository.consult(NFSe.id);
      const newNFSe = {
        ...NFSe,
        ...response,
      };

      switch (response.situationCode) {
        case 101:
          toastr.danger('NFS-e Cancelada');
          break;
        case 100:
          toastr.success('NFS-e Emitida');
          break;
        case 104:
          toastr.info('NFS-e Processando');
          break;
        case 105:
          toastr.info('NFS-e Processando');
          break;
        default:
          setNfseErrorDescription(response.situationDescription);
          setIsNFSeIssueErrorModalOpen(true);
          toastr.warning('NFS-e Aberta c/ Erro');
          break;
      }

      setIsInvoiceLoaderOpen(false);
      onChange(newNFSe);
    } catch (err) {
      console.error(err);
      const message =
        err.response?.data?.message ||
        'Ocorreu um erro ao consultar a NFS-e. Por favor, tente novamente';
      setIsInvoiceLoaderOpen(false);
      toastr.warning(currentBrandingName, message);
    }
  }

  async function getNFSeSettingsByCompany() {
    try {
      const { data: NFSeConfiguration } = await settingsNFSeRepository.index(
        companyId
      );

      const address = await getCompanyAddress(companyId);
      const city = address.City;

      const data =
        await NFSePatternsRepository.getPatternCityByPatternIdAndCity(
          NFSeConfiguration.NFSPatternId,
          city
        );

      setPatternId(NFSeConfiguration.NFSPatternId);
      setAllowCancel(data.allowCancel);
    } catch (err) {
      console.error(err);
      toastr.warning('Ocorreu um erro ao carregar as configurações de NFSe');
    }
  }

  async function getCompanyAddress(companyId) {
    try {
      const { data: companyAddress } =
        await addressRepository.getCompanyAddress(companyId);
      return companyAddress.data[0];
    } catch (err) {
      console.log(err);
    }
  }

  function handleDownloadInvoicePDF() {
    window.open(NFSe.docPDFDownload, '_blank');
  }

  async function handleDeleteInvoice() {
    setLoading(true);

    try {
      await NFSesRepository.erase(NFSe.id);

      setIsDeleteModalOpen(false);
      onDelete();
    } catch (err) {
      console.error(err);
      setLoading(false);
      setIsDeleteModalOpen(false);
      toastr.warning(
        'Ocorreu um erro ao excluir a NFS-e. Por favor, tente novamente'
      );
    }
  }

  async function handleValidateCancelInvoice() {
    getNFSeSettingsByCompany();

    if (
      patternId === 126 ||
      patternId === 155 ||
      patternId === 100 ||
      patternId === 137 ||
      allowCancel == 'false' ||
      allowCancel == false
    ) {
      toastr.warning(
        'A prefeitura não permite cancelamento fora de seu sistema. Por gentileza, faça o cancelamento via site'
      );
    } else {
      setShowCancelInvoiceModal(true);
    }
  }

  return (
    <div style={{ display: 'flex', padding: '0px 32px', fontSize: '17px' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(NFSe)}
        className="btn-acoes"
        title="Editar NFS-e"
        color="black"
      />
      {(isOpen || isOpenWithErrors) && (
        <FontAwesomeIcon
          icon={faWindowClose}
          title="Apagar NFS-e"
          onClick={() => setIsDeleteModalOpen(true)}
          className="btn-acoes"
          color="#e61610"
        />
      )}
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => handleValidateCancelInvoice()}
            className="btn-acoes"
            color="#e61610"
          />

          <FontAwesomeIcon
            icon={faPrint}
            title="Baixar PDF"
            color="black"
            className="btn-acoes"
            onClick={handleDownloadInvoicePDF}
          />

          <FontAwesomeIcon
            icon={faShareAltSquare}
            onClick={() => setIsShareNFSeModalOpen(true)}
            className="btn-acoes"
            style={{ color: '#72bb53' }}
          />
        </>
      )}
      {isProcessing && (
        <FontAwesomeIcon
          icon={faSyncAlt}
          onClick={handleConsultInvoice}
          className="btn-acoes"
          color="#008db1"
        />
      )}

      <AlertModal
        show={showCancelInvoiceModal}
        onHide={() => setShowCancelInvoiceModal(false)}
        onCancel={() => setShowCancelInvoiceModal(false)}
        onSubmit={handleCancelInvoice}
        loading={loading}
        message="Deseja cancelar a NFS-e em  questão ?"
      />

      <AlertModal
        show={isDeleteModalOpen}
        onHide={() => setIsDeleteModalOpen(false)}
        onCancel={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDeleteInvoice}
        loading={loading}
        message="Deseja excluir o essa nota fiscal ? Ao excluir, a numeração ficará vaga e, se houver necessidade, deverá ajustar a numeração nas configurações."
      />

      {isShareNFSeModalOpen && (
        <ShareNFSe
          show={isShareNFSeModalOpen}
          animation
          onHide={() => setIsShareNFSeModalOpen(false)}
          nfse={NFSe}
          onSubmit={() => setIsShareNFSeModalOpen(false)}
        />
      )}

      {isInvoiceLoaderOpen && <InvoiceProcessLoader />}
    </div>
  );
};

export default NFSeActions;
