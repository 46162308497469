import api from '../services/api';

const generateIssuedSlip = async ({
  companyId,
  typeOfDate,
  initialDate,
  finalDate,
  statusSlip,
}) => {
  try {
    const response = await api.get(`report/issued-slip/${companyId}`, {
      params: {
        typeOfDate,
        initialDate,
        finalDate,
        statusSlip,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  generateIssuedSlip,
};
