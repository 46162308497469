import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import HomeAccountPlans from './AccountPlans'

const AccountPlans = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastro', 'Financeiro', 'Plano de Contas']}
            path={['home', null, null, null]}
          />
          <HomeAccountPlans />
        </Row>
      </Grid>
    </div>
  )
}

export default AccountPlans
