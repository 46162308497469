import React from 'react'

import Header from './components/Header.js'
import Footer from './components/Footer'

import './style.css'

const HeaderFooter = ({
  children,
  onSubmit,
  onOpenCashierBankHistoric,
  onOpenOrcaments,
  onOpenListNFCe,
  hasSettingsNFCe,
  hasCashierControl,
  loading,
}) => {
  return (
    <div className="container-pdv">
      <Header />
      {children}
      <Footer
        onSubmit={onSubmit}
        onOpenOrcaments={onOpenOrcaments}
        onOpenListNFCe={onOpenListNFCe}
        hasSettingsNFCe={hasSettingsNFCe}
        hasCashierControl={hasCashierControl}
        onOpenCashierBankHistoric={onOpenCashierBankHistoric}
        loading={loading}
      />
    </div>
  )
}

export default HeaderFooter
