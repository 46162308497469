import api from 'v2/services/api';

export const getAll = async () => {
  try {
    const res = await api.get('/access-permissions/');
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getByProfileId = async (profileId, companyId) => {
  try {
    const res = await api.get('/access-permissions/' + profileId, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateByProfileId = async (profileId, data) => {
  try {
    const res = await api.put('/access-permissions/' + profileId, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listCopyProfilesByCompanyId = async (companyId) => {
  try {
    const res = await api.get('/access-permissions/copy/' + companyId);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const copyProfileByProfileId = async (
  profileIdToCopy,
  profileId,
  companyId
) => {
  try {
    const res = await api.post('/access-permissions/copy/', {
      profileIdToCopy,
      profileId,
      companyId,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

const AccessPermissionsRepository = {
  getAll,
  getByProfileId,
  updateByProfileId,
  listCopyProfilesByCompanyId,
  copyProfileByProfileId,
};

export default AccessPermissionsRepository;
