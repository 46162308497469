import React, { useEffect, useState } from 'react';
import customersRepository from 'repositories/Customers';
import providersRepository from 'repositories/Providers';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import PendingCustomersSuppliersTable from './PendingCustomersSuppliersTable';
import PendingCustomersSuppliersFilters from './PendingCustomersSuppliersFilters';
import pendingCustomerSuppliers from 'repositories/PendingCustomersSuppliers';
import '../index.css';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { format, isBefore } from 'date-fns';

const PendingCustomersSuppliersMain = () => {
  const [dateType, setDateType] = useState('dueDate');
  const [initialDate, setInitialDate] = useState(new Date('2022-01-01'));
  const [finalDate, setFinalDate] = useState(new Date('2022-11-25'));
  const [type, setType] = useState('client');
  const [customerSupplier, setCustomerSupplier] = useState(null);
  const [filterByAll, setFilterByAll] = useState('true');

  const [tableData, setTableData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [formattedOptions, setFormattedOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [isXlsExportReady, setIsXlsExportReady] = useState(false);
  const [isLoadingXlsExport, setIsLoadingXlsExport] = useState(false);

  const { companyId } = useAuth();

  const handleSubmit = async () => {
    if (!initialDate || !finalDate) {
      return toastr.warning(
        'Datas inicias e finais inválidas.',
        'Por favor, preencha os campos e tente novamente'
      );
    }

    if (!customerSupplier && filterByAll === 'false') {
      return toastr.warning(
        'Nenhum cliente/fornecedor selecionado.',
        'Por favor, selecione um cliente ou fornecedor e tente novamente.'
      );
    }
    setIsDisabled(false);
    setIsLoading(true);
    setIsXlsExportReady(false);
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        dateType,
        initialDate,
        finalDate,
        type,
        personId: customerSupplier?.value,
      };

      const report =
        await pendingCustomerSuppliers.getPendingCustomersSuppliers(
          companyId,
          params
        );

      if (report.billsCount === 0)
        return toastr.warning(
          'OS Digital',
          'Não há dados correspondentes à filtragem especificada'
        );

      const tableData = report.reportData
        .map((data) => [...data.expiredBills, ...data.notExpiredBills])
        .reduce((prev, curr) => [...prev, ...curr], [])
        .sort((a, b) => {
          if (isBefore(new Date(a.dueDate), new Date(b.dueDate))) return -1;
          if (isBefore(new Date(b.dueDate), new Date(a.dueDate))) return 1;
          return 0;
        })
        .map((bill) => ({
          ...bill,
          dueDate: format(new Date(bill.dueDate), 'dd/MM/yyyy'),
        }));

      setTableData(tableData);
      setTotalPages(Math.ceil(report.billsCount / pageLimit));

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao processar o relatório.',
        'Por favor, tente novamente.'
      );
    }
  };

  const loadCustomers = async () => {
    setIsLoading(true);
    setCustomerSupplier(null);
    try {
      if (type === 'client') {
        const customers = await customersRepository.getAllActiveByCompany(
          companyId
        );

        const formattedCustomers = customers.map((c) => ({
          value: c.id,
          label: `${cpfOrCnpjMask(c.Cpf_Cnpj)} - ${c.Company_Name}`,
        }));

        setFormattedOptions(formattedCustomers);
      }

      if (type === 'provider') {
        const providers = await providersRepository.getAllActiveByCompany(
          companyId
        );

        const formattedProviders = providers.map((p) => ({
          value: p.id,
          label: `${cpfOrCnpjMask(p.cpfCnpj)} - ${p.companyName} | ${
            p.tradingName
          }`,
        }));

        setFormattedOptions(formattedProviders);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar os clientes.',
        'Por favor, tente novamente.'
      );
    }
  };

  const openPDF = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        dateType,
        initialDate,
        finalDate,
        type,
        personId: customerSupplier?.value,
      };

      const report =
        await pendingCustomerSuppliers.getPendingCustomersSuppliers(
          companyId,
          params
        );

      if (report.billsCount === 0)
        return toastr.warning('OS Digital', 'Não há dados para gerar o PDF');
      window.open(
        window.location.origin +
          `/report/pending-customers-suppliers?companyId=${companyId}&dateType=${dateType}&initialDate=${initialDate}&finalDate=${finalDate}&type=${type}${
            customerSupplier?.value
              ? `&personId=${customerSupplier?.value}`
              : ''
          }`
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toastr.error(
        'Não foi possível abrir o PDF.',
        'Por favor, tente novamente.'
      );
    }
  };

  const loadExcel = async () => {
    setIsLoadingXlsExport(true);
    toastr.warning('OS Digital', 'Preparando exportação...');
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        dateType,
        initialDate,
        finalDate,
        type,
        personId: customerSupplier?.value,
      };

      const report =
        await pendingCustomerSuppliers.getPendingCustomersSuppliers(
          companyId,
          params
        );

      if (report.billsCount === 0)
        return toastr.warning(
          'OS Digital',
          'Não há dados correspondentes à filtragem especificada'
        );

      const excelData = report.reportData
        .map((data) => [...data.expiredBills, ...data.notExpiredBills])
        .reduce((prev, curr) => [...prev, ...curr], [])
        .sort((a, b) => {
          if (isBefore(new Date(a.dueDate), new Date(b.dueDate))) return -1;
          if (isBefore(new Date(b.dueDate), new Date(a.dueDate))) return 1;
          return 0;
        })
        .map((bill) => ({
          dueDate: format(new Date(bill.dueDate), 'dd/MM/yyyy'),
          personName: bill.personName,
          code: bill.code,
          title: bill.saleCode || bill.purchaseCode,
          addedValue: currency(bill.addedValue),
          discountValue: currency(bill.discountValue),
          liquidValue: currency(bill.liquidValue),
          paidValue: currency(bill.paidValue),
          openValue: currency(bill.openValue),
          issuedAt: format(new Date(bill.issuedAt), 'dd/MM/yyyy'),
        }));

      setExcelData(excelData);
      toastr.success(
        'OS Digital',
        'Exportação pronta! Clique no botão para baixar o arquivo.'
      );

      setIsXlsExportReady(true);
      setIsLoadingXlsExport(false);
    } catch (err) {
      setIsLoadingXlsExport(false);
      console.log(err);
      toastr.error(
        'Não foi possível carregar a exportação para xls.',
        'Por favor, tente novamente.'
      );
    }
  };

  useEffect(() => {
    setExcelData([]);
    setTableData([]);
    setIsXlsExportReady(false);
    setIsLoadingXlsExport(false);
  }, [filterByAll, customerSupplier]);

  useEffect(() => {
    setCustomerSupplier(null);
  }, [filterByAll]);

  useEffect(() => {
    loadCustomers();
    setCustomerSupplier(null);
    setTableData([]);
    setIsXlsExportReady(false);
    setIsLoadingXlsExport(false);
    setExcelData([]);
    setTableData([]);
  }, [type]);

  useEffect(() => {
    if (!isDisabled) {
      handleSubmit();
    }
  }, [currentPage, pageLimit]);

  return (
    <>
      <PendingCustomersSuppliersFilters
        setDateType={setDateType}
        setInitialDate={setInitialDate}
        initialDate={initialDate}
        setFinalDate={setFinalDate}
        finalDate={finalDate}
        setType={setType}
        type={type}
        setCustomerSupplier={setCustomerSupplier}
        customerSupplier={customerSupplier}
        customerInfo={customerInfo}
        data={excelData}
        formattedOptions={formattedOptions}
        isLoading={isLoading}
        isDisabled={isDisabled}
        handleSubmit={handleSubmit}
        filterByAll={filterByAll}
        setFilterByAll={setFilterByAll}
        openPDF={openPDF}
        setIsXlsExportReady={setIsXlsExportReady}
        isXlsExportReady={isXlsExportReady}
        setIsLoadingXlsExport={setIsLoadingXlsExport}
        isLoadingXlsExport={isLoadingXlsExport}
        loadExcel={loadExcel}
        tableData={tableData}
        setTableData={setTableData}
      />

      <PendingCustomersSuppliersTable
        data={tableData}
        type={type}
        isLoading={isLoading}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default PendingCustomersSuppliersMain;
