import React from 'react'
import { Modal } from 'react-bootstrap'

const { Header, Title, Body, Footer } = Modal

const TokenModal = ({ show, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Header>
        <Title>
          <strong>OS Digital</strong>
        </Title>
      </Header>
      <Body>
        <strong>
          Para localizar o token a ser utilizado, deverá seguir o seguinte fluxo
          na sua conta Tray:
        </strong>
        <br />
        <br />
        <span>
          Acesse a sua conta Tray &gt; Vá no Menu Meus Aplicativos &gt; Botão
          Instalar novos aplicativos, procure pelo app OS Digital e realize a
          instalação.
        </span>
        <br />
        <br />
        <span>
          Após instalado, ao clicar em "acessar", você deverá copiar o campo
          <strong> code</strong> e colar no campo Token.
        </span>
      </Body>
      <Footer>
        <button className='btn btn-delete' onClick={onCancel}>
          Voltar
        </button>
      </Footer>
    </Modal>
  )
}

export default TokenModal
