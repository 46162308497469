const INITIAL_STATE = {
    cardNumber: '',
    cardMonth: 'XX',
    cardYear: 'XX',
    codSeg: '',
    brand: '',
    maxLength: null,
    focus: '',
    loading: false
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHANGE_VALUE':
            return { ...state, [action.payloadName]: action.payload }
        default:
            return { ...state }
    }
}