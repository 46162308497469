import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";

import FormCFOP from './NewCFOP'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewCFOP = ({ match, history }) => {

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <span style={{ marginRight: -10 }} id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb
              data={['Início', 'Cadastros', 'Fiscal', 'CFOP', !!match.params.id ? 'Editar CFOP' : 'Novo CFOP']}
              path={[constants.ROUTES.HOME, null, null, constants.ROUTES.CFOPS, null]}
            />
            <FormCFOP
              cfopId={match.params.id}
              onCancel={() => history.push(constants.ROUTES.CFOPS)}
              onSubmit={() => history.push(constants.ROUTES.CFOPS)}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(NewCFOP)


