import React, { Component } from 'react';
import Card from 'components/Card/Card.jsx';
import api from 'services/api';
import config from 'config';
import ReactTable from 'react-table';
// import "client/views/Products/node_modules/react-table/react-table.css";
import 'moment-timezone';
import orderBy from 'lodash/orderBy';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

class CustomerDetalhes extends Component {
  state = {
    todos: [],
    query: '',
    columnToSort: '',
    sortDirection: 'desc',
    columnToQuery: [],
    loading: false,
    filtered: [],
    idempresa: localStorage.getItem('ID_EMPRESA'),
    todosClientes: [],
    idCliente: '',
    tableToExport: [['', '']],
    disabled: '',
    companiesIds: [],
  };

  componentDidMount() {
    this.loadClientes();
  }

  loadClientes = async () => {
    const companyUsers = [];
    const todosDadosClientes = [];

    this.setState({ loading: true });
    for (let i = 0; i < this.props.dados.length; i++) {
      // CONSULTANDO COMPANY USER POR CADA ID DE USUARIO
      const users = await api.get(
        config.endpoint + `companyUser/byId/${this.props.dados[i].user_id}`
      );
      companyUsers.push(users.data);
    }
    await this.setState({ companiesIds: companyUsers });

    for (let j = 0; j < this.state.companiesIds.length; j++) {
      if (this.state.companiesIds[j][0]) {
        if (this.state.companiesIds[j][0].Company_id != null) {
          //PEGANDO OS DADO DA EMPRESA POR CADA ID
          const company = await api.get(
            config.endpoint +
              `admins/Company/${this.state.companiesIds[j][0].Company_id}`
          );
          const { data } = company.data;
          let Cpf_Cnpj_Masked = data.Cpf_Cnpj;
          if (data.Cpf_Cnpj.length === 11) {
            Cpf_Cnpj_Masked = data.Cpf_Cnpj.replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d{1,2})/, '$1-$2')
              .replace(/(-\d{2})\d+?$/, '$1');
          } else {
            Cpf_Cnpj_Masked = data.Cpf_Cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              '$1.$2.$3/$4-$5'
            );
          }
          let tel = data.Phones[0].Number_Phone1;
          let tel2 = data.Phones[0].Number_Phone2;
          if (tel[0] === '0' || tel2[0] === '0') {
            tel =
              tel.substring(0, 3) +
              ' ' +
              tel.substring(3, 6) +
              ' ' +
              tel.substring(6, 10);
            tel2 =
              tel2.substring(0, 3) +
              ' ' +
              tel2.substring(3, 6) +
              ' ' +
              tel2.substring(6, 10);
          } else if (tel.length <= 10 || tel2.length <= 10) {
            tel = tel
              .replace(/(\d{0})(\d)/, '$1($2')
              .replace(/(\d{2})(\d)/, '$1)$2')
              .replace(/(\d{4})(\d)/, '$1-$2');
            tel2 = tel2
              .replace(/(\d{0})(\d)/, '$1($2')
              .replace(/(\d{2})(\d)/, '$1)$2')
              .replace(/(\d{4})(\d)/, '$1-$2');
          } else if (tel.length === 11 || tel.length === 11) {
            tel = tel
              .replace(/(\d{0})(\d)/, '$1($2')
              .replace(/(\d{2})(\d)/, '$1)$2')
              .replace(/(\d{5})(\d)/, '$1-$2');
            tel2 = tel2
              .replace(/(\d{0})(\d)/, '$1($2')
              .replace(/(\d{2})(\d)/, '$1)$2')
              .replace(/(\d{5})(\d)/, '$1-$2');
          }

          let dataCadastro = new Date(data.createdAt)
            .toISOString()
            .substring(0, 10)
            .replace('-', '/')
            .replace('-', '/')
            .split('/')
            .reverse()
            .join('/');
          let dataVigencia;
          if (!data.Signature[0]) {
            dataVigencia = '---';
          } else {
            dataVigencia = new Date(data.Signature[0].End_Date)
              .toISOString()
              .substring(0, 10)
              .replace('-', '/')
              .replace('-', '/')
              .split('/')
              .reverse()
              .join('/');
          }

          const obj = {
            id: data.id,
            Company_Name: data.Company_Name,
            Cpf_Cnpj: Cpf_Cnpj_Masked,
            City: data.Address[0] ? data.Address[0].City : '---',
            State: data.Address[0] ? data.Address[0].State : '---',
            Phones: tel === '' ? tel2 : tel,
            Plan: data.Signature[0]
              ? data.Signature[0].Plans.Description
              : '---',
            createdAt: dataCadastro,
            End_Date: dataVigencia,
          };

          todosDadosClientes.push(obj);
        }
      }
      await this.setState({ todosClientes: todosDadosClientes });
    }
    await this.setState({ loading: false });
  };

  render() {
    const lowerCaseQuery = this.state.query.toLowerCase();
    const { todosClientes, tableToExport, disabled } = this.state;
    return (
      <Card
        content={
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="reviewHeader"
            >
              <div
                className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
                style={{ marginLeft: '-3%' }}
              >
                <i
                  id="iconepesquisar"
                  style={{ cursor: 'pointer' }}
                  className="fa fa-search"
                />
                <input
                  type="text"
                  style={{ paddingLeft: '9%' }}
                  className="form-control"
                  placeholder="Pesquisar Clientes"
                  value={this.state.query}
                  onChange={(e) => this.setState({ query: e.target.value })}
                ></input>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <DownloadXlsButton
                  archiveName="ClienteOsDigital"
                  data={todosClientes}
                  className="btn btn-export"
                  disabled={disabled}
                  columns={[
                    {
                      name: 'CPF/CNPJ',
                      acessor: 'Cpf_Cnpj',
                    },
                    {
                      name: 'RAZÃO SOCIAL',
                      acessor: 'Company_Name',
                    },
                    {
                      name: 'CIDADE',
                      acessor: 'City',
                    },
                    {
                      name: 'ESTADO',
                      acessor: 'State',
                    },
                    {
                      name: 'TELEFONE',
                      acessor: 'Phones',
                    },
                    {
                      name: 'PLANO',
                      acessor: 'Plan',
                    },
                    {
                      name: 'DT. CADASTRO',
                      acessor: 'createdAt',
                    },
                    {
                      name: 'DT. VIGÊNCIA',
                      acessor: 'End_Date',
                    },
                  ]}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                  Exportar .xls
                </DownloadXlsButton>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  marginLeft:
                    localStorage.getItem('EXPANDED') === 2 ? '0%' : '2%',
                  width:
                    localStorage.getItem('EXPANDED') === 2 ? '100%' : '92%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                data={orderBy(
                  this.state.query
                    ? todosClientes.filter(
                        (x) =>
                          x['Cpf_Cnpj']
                            .replace(/\D/g, '')
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseQuery) ||
                          x['Company_Name']
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseQuery)
                      )
                    : todosClientes,
                  this.state.columnToSort,
                  this.state.sortDirection
                )}
                onClick={() => this.setState({ filtered: [] })}
                columns={[
                  {
                    Header: 'CPF/CNPJ',
                    accessor: 'Cpf_Cnpj',
                    headerClassName: 'text-left',
                    width: 140,
                    className: 'texto',
                  },
                  {
                    Header: 'Razão Social',
                    accessor: 'Company_Name',
                    headerClassName: 'text-left',
                    width: 300,
                    className: 'texto',
                  },
                  {
                    Header: 'Cidade',
                    accessor: 'City',
                    headerClassName: 'text-left',
                    width: 100,
                    className: 'texto',
                  },
                  {
                    Header: 'Estado',
                    accessor: 'State',
                    headerClassName: 'text-left',
                    width: 70,
                    className: 'texto',
                  },
                  {
                    Header: 'Telefone',
                    accessor: 'Phones',
                    headerClassName: 'text-left',
                    width: 150,
                    className: 'texto',
                  },
                  {
                    Header: 'Plano',
                    accessor: 'Plan',
                    headerClassName: 'text-left',
                    width: 120,
                    className: 'texto',
                  },
                  {
                    Header: 'Dt. Cadastro',
                    accessor: 'createdAt',
                    headerClassName: 'text-left',
                    width: 100,
                    className: 'texto',
                  },
                  {
                    Header: 'Dt. Vigência',
                    accessor: 'End_Date',
                    headerClassName: 'text-left',
                    width: 100,
                    className: 'texto',
                  },
                ]}
                defaultPageSize={5}
                className=""
                loading={this.state.loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'Company_Name',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum cliente encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        }
      />
    );
  }
}

export default withRouter(CustomerDetalhes);
