import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import { format } from 'date-fns'

import SearchInput from 'components/SearchInput'
import AlertModal from 'components/AlertModal'

import pdvsRepository from '../../../../../repositories/PDVs'
import useFilters from '../../../../../hooks/useFilters'
import { isDefaultCustomer } from 'utils/isDefaultCustomer'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { useAuth } from 'contexts/auth'

import './styles.css'

const OpenOrcamentsModal = ({ onCancel, onSelectOrcament }) => {
  const [orcaments, setOrcaments] = useState([])
  const [loading, setLoading] = useState(false)
  const [isConfirmEditOrcamentModalOpen, setIsConfirmEditOrcamentModalOpen] =
    useState(false)
  const [saleId, setSaleId] = useState(null)
  const [type, setType] = useState(null)

  const { companyId } = useAuth()
  const { dateFilter, queryFilter } = useFilters()
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter
  const { query, setQuery, filterByQuery } = queryFilter

  useEffect(() => {
    if (companyId) {
      loadOpenOrcaments()
    }
  }, [companyId])

  async function loadOpenOrcaments() {
    setLoading(true)
    try {
      const params = {
        page: 1,
        limit: 10,
      }

      const orcaments = await pdvsRepository.getReceipts(companyId, params)
      setOrcaments(orcaments)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar os orçamentos. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  function handleOpenConfirmEditOrcamentModal(values) {
    setSaleId(values.id)
    setType(values.type)
    setIsConfirmEditOrcamentModalOpen(true)
  }

  function handleLoadOrcament() {
    onSelectOrcament(saleId, type)
    setIsConfirmEditOrcamentModalOpen(false)
  }

  function handleFilters(sale) {
    const querySearch = [sale['code'], sale['customer'], sale['vehiclePlate']]
    return filterByQuery(querySearch) && filterByDate('date', sale)
  }

  function getBackgroundColorByType(type) {
    const backgroundColors = {
      Orçamento: '#363636',
      OS: '#00008B',
      'Orç. Rápido': '#5cb85c',
    }

    return backgroundColors[type]
  }

  return (
    <>
      <Modal show animation dialogClassName="open-orcaments-modal-wrapper">
        <Modal.Header>
          <Modal.Title>
            <strong style={{ fontSize: 20 }}>
              Recebimentos de Orçamentos/OS
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '15px',
              gap: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '500px',
                marginLeft: -5,
              }}
            >
              <SearchInput
                placeholder="Pesquisa por Nº Orçamento/OS ou Orçamento Rápido, Cliente ou Placa"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="">Data de Criação Inicial:</label>
              <input
                className="form-control foco-input"
                type="date"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="">Data de Criação Final:</label>
              <input
                className="form-control foco-input"
                type="date"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </div>
          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12 }}
              data={orcaments.filter(handleFilters)}
              columns={[
                {
                  Header: 'Data',
                  accessor: 'date',
                  width: 100,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Nº',
                  accessor: 'code',
                  width: 90,
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 120,
                  Cell: (props) => {
                    return (
                      <button
                        className="admin-custom-label"
                        style={{
                          backgroundColor: getBackgroundColorByType(
                            props.value
                          ),
                        }}
                      >
                        {props.value}
                      </button>
                    )
                  },
                },
                {
                  Header: 'Cliente',
                  accessor: 'customer',
                },
                {
                  Header: 'Placa',
                  accessor: 'licensePlate',
                  width: 100,
                },
                {
                  Header: 'Valor',
                  accessor: 'value',
                  width: 150,
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  filterable: false,
                  width: 80,
                  Cell: ({ original, value, props }) => (
                    <FontAwesomeIcon
                      title="Editar"
                      cursor="pointer"
                      style={{
                        height: '1.5em',
                        width: '1.5em',
                        color: 'black',
                      }}
                      icon={faEdit}
                      onClick={() =>
                        handleOpenConfirmEditOrcamentModal(original)
                      }
                    />
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'date',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum recebimento encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </main>
        </Modal.Body>
        <Modal.Footer>
          <button className="danger" onClick={onCancel}>
            Voltar
          </button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmEditOrcamentModalOpen}
        message="Deseja continuar o orçamento do cliente?"
        onHide={() => setIsConfirmEditOrcamentModalOpen(false)}
        onCancel={() => setIsConfirmEditOrcamentModalOpen(false)}
        onSubmit={handleLoadOrcament}
      />
    </>
  )
}

export default OpenOrcamentsModal
