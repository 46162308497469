import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format, addDays, parseISO } from 'date-fns';
import { H1 } from '../components/H1';
import { currency } from '../../../client/components/ToNormalize/ToNormalize';
import constants from '../../../utils/constants';

const Header = ({
  initialDate,
  finalDate,
  salesItems,
  salesVehicles,
  cashierBanks,
  purchaseItems,
  totalPerMethods,
}) => {
  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '0 5px',
        marginBottom: '2px',
        gap: '5px',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={[styles.headerText, { marginRight: '5px' }]}>
            <Text>{initialDate}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{finalDate}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Itens das Vendas/OS: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{salesItems}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Veículos das Vendas/OS: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{salesVehicles}</Text>
          </View>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Caixa Banco: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{cashierBanks}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Itens das Compras: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{purchaseItems}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Totais por Forma de Pagto: </Text>
          </View>
          <View>
            <Text style={styles.headerText}>{totalPerMethods}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const Content = ({ children, style, ...rest }) => (
  <View style={[styles.content, style]} {...rest}>
    {children}
  </View>
);

const Table = ({ children, ...rest }) => (
  <View style={styles.table} {...rest}>
    {children}
  </View>
);

const TableHead = ({ children, style = {}, ...rest }) => (
  <View style={[styles.thead, style]} {...rest}>
    {children}
  </View>
);

const TableHeadTitle = ({ children, style, ...rest }) => (
  <Text style={[styles.theadTitle, style]} {...rest}>
    {children}
  </Text>
);

const TableBody = ({ children, ...rest }) => (
  <View style={styles.tbody} {...rest}>
    {children}
  </View>
);

const TableBodyRow = ({ children, style = {}, ...rest }) => (
  <View style={[styles.tbodyRow, style]} {...rest}>
    {children}
  </View>
);

const TableBodyRowData = ({ children, style, ...rest }) => (
  <View style={[styles.tbodyRowData, style]} {...rest}>
    {children}
  </View>
);

const TotalPaymentMethodTable = (arr) => {
  return (
    <View>
      <View
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          marginTop: '2px',
        }}
      >
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            marginBottom: 5,
          }}
        >
          Totais Recebidos por Forma de Pagto
        </Text>
      </View>

      <View style={styles.line} />

      <Content>
        <Table>
          <TableHead
            style={[
              styles.contentText,
              {
                padding: '2px 0',
                fontWeight: 'bold',
                /*  marginTop: '2px', */
              },
            ]}
          >
            <TableHeadTitle style={[{ marginLeft: -5, width: '30%' }]}>
              Descrição
            </TableHeadTitle>
            <TableHeadTitle style={[{ marginLeft: -5, width: '20%' }]}>
              Qtd.
            </TableHeadTitle>
            <TableHeadTitle style={[{ marginLeft: -5, width: '20%' }]}>
              Valor Total
            </TableHeadTitle>
          </TableHead>

          <TableBody>
            {arr?.items?.length > 0 ? (
              arr.items?.map((item) => {
                return (
                  <TableBodyRow>
                    <TableBodyRowData
                      style={[styles.contentText, { width: '30%' }]}
                    >
                      <Text>{item.desc}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[styles.contentText, { width: '20%' }]}
                    >
                      <Text>{item.qtd}</Text>
                    </TableBodyRowData>
                    <TableBodyRowData
                      style={[
                        styles.contentText,
                        { marginLeft: -5, width: '20%' },
                      ]}
                    >
                      <Text>{currency(item.total)}</Text>
                    </TableBodyRowData>
                  </TableBodyRow>
                );
              })
            ) : (
              <TableBodyRow>
                <TableBodyRowData
                  style={[styles.contentText, { width: '30%' }]}
                >
                  <Text>Não há valores recebidos para exibir.</Text>
                </TableBodyRowData>
              </TableBodyRow>
            )}
          </TableBody>
        </Table>
      </Content>
      {arr?.items?.length > 0 ? (
        <TableHead
          style={[
            styles.contentText,
            {
              fontWeight: 'bold',
              paddingBottom: '3px',
              marginTop: 0,
            },
          ]}
        >
          <TableHeadTitle style={[{ marginLeft: -5, width: '50%' }]}>
            Total
          </TableHeadTitle>
          <TableHeadTitle style={[{ marginLeft: -5, width: '20%' }]}>
            {currency(arr.items.reduce((total, item) => total + item.total, 0))}
          </TableHeadTitle>
        </TableHead>
      ) : (
        <TableHead
          style={[
            styles.contentText,
            {
              fontWeight: 'bold',
              paddingBottom: '2px',
              margin: 0,
            },
          ]}
        >
          <TableHeadTitle style={[{ marginLeft: -5, width: '50%' }]}>
            Total
          </TableHeadTitle>
          <TableHeadTitle style={[{ marginLeft: -5, width: '20%' }]}>
            {currency(0)}
          </TableHeadTitle>
        </TableHead>
      )}
    </View>
  );
};

const CashClosingReportDocument = ({
  results,
  finalValues,
  initialDate,
  finalDate,
  showSaleItems,
  showSaleVehicles,
  showPurchaseItems,
  showTotalPaymentMethod,
}) => {
  function showValuesConvert(value) {
    return value === 'true' ? 'Exibir' : 'Não Exibir';
  }

  function concatCashierDescriptions(results) {
    return results.map((result) => result.descriptionWithType).join(', ');
  }

  function formatDate(date) {
    if (!date) {
      return '';
    }
    const objetoData = parseISO(date);
    return format(objetoData, 'dd/MM/yyyy');
  }

  let lastDate = '';

  function renderDate(date) {
    if (formatDate(date) !== lastDate || lastDate === '') {
      lastDate = formatDate(date);
      return <Text style={[styles.contentText]}>{lastDate}</Text>;
    }
    return <View />;
  }

  function calcDescPurchase(item) {
    if (!item || item.quantity <= 0 || item.total <= 0) {
      return null;
    }
    const descPercent =
      (item.unitDiscount / (item.unitValue * item.quantity)) * 100;
    return descPercent;
  }

  return (
    <Document
      title={`relatorio-fluxo-de-caixa-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="Relatório de Fluxo de Caixa"
    >
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '20%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Fluxo de Caixa</Text>
          </View>
          <div style={{ width: '80%' }}>
            <Header
              initialDate={formatDate(initialDate)}
              finalDate={formatDate(finalDate)}
              salesItems={showValuesConvert(showSaleItems)}
              salesVehicles={showValuesConvert(showSaleVehicles)}
              purchaseItems={showValuesConvert(showPurchaseItems)}
              totalPerMethods={showValuesConvert(showTotalPaymentMethod)}
              cashierBanks={concatCashierDescriptions(results)}
            />
          </div>
        </View>
        <View style={[styles.line]} />
        {results.map(
          (
            { cashierBank, resume, totalPerMethods, descriptionWithType },
            resultIndex
          ) => {
            return (
              <View key={resultIndex}>
                {showTotalPaymentMethod !== 'false' ? (
                  <TotalPaymentMethodTable items={totalPerMethods} />
                ) : (
                  <View />
                )}

                <Content>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      marginTop: '2px',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {`Movimentação do Período (${descriptionWithType})`}
                    </Text>
                  </View>
                </Content>

                <Content style={{ marginTop: -3.5 }}>
                  <Table>
                    <TableHead
                      style={[
                        styles.contentText,
                        {
                          paddingBottom: 3.5,
                          fontWeight: 'bold',
                          marginTop: '2px',
                        },
                      ]}
                    >
                      <TableHeadTitle
                        style={[{ marginLeft: -5, width: '10%' }]}
                      >
                        Data
                      </TableHeadTitle>
                      <TableHeadTitle style={[{ width: '35%' }]}>
                        Descrição
                      </TableHeadTitle>
                      <TableHeadTitle style={[{ width: '25%' }]}>
                        Conta
                      </TableHeadTitle>
                      <TableHeadTitle style={[{ width: '15%' }]}>
                        R$ Entrada (+)
                      </TableHeadTitle>
                      <TableHeadTitle style={[{ width: '15%' }]}>
                        R$ Saída (-)
                      </TableHeadTitle>
                    </TableHead>

                    <TableBody>
                      <TableBodyRow>
                        <TableBodyRowData style={{ width: '10%' }} />
                        <TableBodyRowData style={{ width: '40%' }}>
                          <Text
                            style={[styles.contentText, { fontWeight: 'bold' }]}
                          >
                            SALDO INICIAL:
                          </Text>
                        </TableBodyRowData>
                        <TableBodyRowData style={{ width: '20%' }}>
                          <Text
                            style={[styles.contentText, { fontWeight: 'bold' }]}
                          >
                            {currency(resume.inicialValue)}
                          </Text>
                        </TableBodyRowData>
                      </TableBodyRow>

                      {resume.cashierBankFlow.map((flow, index) => (
                        <View key={index}>
                          <TableBodyRow>
                            <TableBodyRowData style={{ width: '10%' }}>
                              {renderDate(flow.date)}
                            </TableBodyRowData>
                            <TableBodyRowData style={{ width: '35%' }}>
                              <Text style={[styles.contentText]}>
                                {!flow.saleId &&
                                  (flow.financialMovementTypeId ===
                                  constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT
                                    ? '(M)'
                                    : flow.table !== 'financialMovements'
                                    ? ''
                                    : '(T)')}{' '}
                                {flow.description}
                              </Text>
                              {!!flow.observation && (
                                <Text style={styles.observations}>
                                  Observações: {flow.observation}
                                </Text>
                              )}
                            </TableBodyRowData>
                            <TableBodyRowData style={{ width: '25%' }}>
                              <Text style={[styles.contentText]}>
                                {flow.accountPlan}
                              </Text>
                            </TableBodyRowData>
                            <TableBodyRowData style={{ width: '15%' }}>
                              <Text
                                style={[
                                  styles.contentText,
                                  {
                                    width: '100%',
                                    color: flow.income < 0 && 'red',
                                  },
                                ]}
                              >
                                {currency(flow.income)}
                              </Text>
                            </TableBodyRowData>
                            <TableBodyRowData style={{ width: '15%' }}>
                              <Text
                                style={[
                                  styles.contentText,
                                  {
                                    width: '100%',
                                    color: flow.expense < 0 && 'red',
                                  },
                                ]}
                              >
                                {currency(flow.expense)}
                              </Text>
                            </TableBodyRowData>
                          </TableBodyRow>

                          {flow.saleId || flow.purchaseId ? (
                            <View
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {showSaleVehicles !== 'false' && flow.Vehicle && (
                                <TableBodyRow
                                  style={{
                                    width: '90%',
                                  }}
                                >
                                  <TableBodyRowData style={{ width: '35%' }}>
                                    <Text style={[styles.clientAndVehicle]}>
                                      {flow.Vehicle.customer}
                                    </Text>
                                  </TableBodyRowData>
                                  <TableBodyRowData style={{ width: '55%' }}>
                                    <Text style={[styles.clientAndVehicle]}>
                                      {flow.Vehicle.vehicle}
                                    </Text>
                                  </TableBodyRowData>
                                </TableBodyRow>
                              )}
                              {flow.saleId && showSaleItems !== 'false' && (
                                <>
                                  <View
                                    style={[styles.line, { width: '90%' }]}
                                  />
                                  <TableBodyRow
                                    style={{
                                      width: '90%',
                                    }}
                                  >
                                    <TableBodyRowData style={{ width: '6%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Tipo
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '28%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Descrição
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '12%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Marca
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '8%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Qtd
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '10%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Valor Unit.
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '6%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Desc(%)
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData
                                      style={{
                                        width: '10%',
                                        marginLeft: '16px',
                                      }}
                                    >
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Total
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '8%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Custo
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '10%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Lucro(%)
                                      </Text>
                                    </TableBodyRowData>
                                    <TableBodyRowData style={{ width: '10%' }}>
                                      <Text
                                        style={[
                                          styles.additionalInfo,
                                          { fontWeight: 'bold' },
                                        ]}
                                      >
                                        Lucro(R$)
                                      </Text>
                                    </TableBodyRowData>
                                  </TableBodyRow>
                                  <View
                                    style={[styles.line, { width: '90%' }]}
                                  />
                                </>
                              )}

                              {showSaleItems !== 'false' &&
                                flow.Sale?.SalesItems?.map((item) => {
                                  return (
                                    <>
                                      <TableBodyRow
                                        style={{
                                          width: '90%',
                                        }}
                                      >
                                        <TableBodyRowData
                                          style={{ width: '6%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.Type === 'Produto'
                                              ? 'P'
                                              : 'S'}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '28%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.Description.slice(0, 25)}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '12%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.Products?.Brands?.Description}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '8%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.Quantity}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '10%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {currency(item.Unit_Value)}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '6%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.Discount_Value + '%'}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '10%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {currency(item.Amount)}
                                          </Text>
                                        </TableBodyRowData>

                                        {item.Type === 'Produto' ? (
                                          <>
                                            <TableBodyRowData
                                              style={{ width: '8%' }}
                                            >
                                              <Text
                                                style={[styles.additionalInfo]}
                                              >
                                                {currency(item.Value_Cost)}
                                              </Text>
                                            </TableBodyRowData>
                                            <TableBodyRowData
                                              style={{ width: '10%' }}
                                            >
                                              <Text
                                                style={[styles.additionalInfo]}
                                              >
                                                {Math.ceil(
                                                  (100 *
                                                    (item.Amount -
                                                      item.Value_Cost *
                                                        item.Quantity)) /
                                                    item.Amount
                                                ) + '%'}
                                              </Text>
                                            </TableBodyRowData>
                                          </>
                                        ) : (
                                          <View style={{ width: '18%' }} />
                                        )}

                                        {item.Type === 'Produto' ? (
                                          <TableBodyRowData
                                            style={{ width: '10%' }}
                                          >
                                            <Text
                                              style={[styles.additionalInfo]}
                                            >
                                              {currency(
                                                item.Amount -
                                                  item.Value_Cost *
                                                    item.Quantity
                                              )}
                                            </Text>
                                          </TableBodyRowData>
                                        ) : (
                                          <View style={{ width: '10%' }} />
                                        )}
                                      </TableBodyRow>
                                      <View
                                        style={[styles.line, { width: '90%' }]}
                                      />
                                    </>
                                  );
                                })}

                              {flow.purchaseId &&
                                showPurchaseItems !== 'false' && (
                                  <>
                                    <View
                                      style={[styles.line, { width: '90%' }]}
                                    />
                                    <TableBodyRow
                                      style={{
                                        width: '90%',
                                      }}
                                    >
                                      <TableBodyRowData
                                        style={{ width: '36%' }}
                                      >
                                        <Text
                                          style={[
                                            styles.additionalInfo,
                                            { fontWeight: 'bold' },
                                          ]}
                                        >
                                          Descrição
                                        </Text>
                                      </TableBodyRowData>
                                      <TableBodyRowData style={{ width: '8%' }}>
                                        <Text
                                          style={[
                                            styles.additionalInfo,
                                            { fontWeight: 'bold' },
                                          ]}
                                        >
                                          Qtd
                                        </Text>
                                      </TableBodyRowData>
                                      <TableBodyRowData
                                        style={{ width: '10%' }}
                                      >
                                        <Text
                                          style={[
                                            styles.additionalInfo,
                                            { fontWeight: 'bold' },
                                          ]}
                                        >
                                          Valor Unit.
                                        </Text>
                                      </TableBodyRowData>
                                      <TableBodyRowData
                                        style={{ width: '10%' }}
                                      >
                                        <Text
                                          style={[
                                            styles.additionalInfo,
                                            { fontWeight: 'bold' },
                                          ]}
                                        >
                                          Desc(%)
                                        </Text>
                                      </TableBodyRowData>
                                      <TableBodyRowData
                                        style={{
                                          width: '10%',
                                          marginLeft: '16px',
                                        }}
                                      >
                                        <Text
                                          style={[
                                            styles.additionalInfo,
                                            { fontWeight: 'bold' },
                                          ]}
                                        >
                                          Total
                                        </Text>
                                      </TableBodyRowData>
                                    </TableBodyRow>
                                    <View
                                      style={[styles.line, { width: '90%' }]}
                                    />
                                  </>
                                )}

                              {showPurchaseItems !== 'false' &&
                                flow.Purchase?.PurchaseItems?.map((item) => {
                                  return (
                                    <>
                                      <TableBodyRow
                                        style={{
                                          width: '90%',
                                        }}
                                      >
                                        <TableBodyRowData
                                          style={{ width: '36%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.description.slice(0, 25)}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '8%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {item.quantity}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '10%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {currency(item.unitValue)}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '12%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {calcDescPurchase(item) + '%'}
                                          </Text>
                                        </TableBodyRowData>
                                        <TableBodyRowData
                                          style={{ width: '10%' }}
                                        >
                                          <Text style={[styles.additionalInfo]}>
                                            {currency(item.total)}
                                          </Text>
                                        </TableBodyRowData>
                                      </TableBodyRow>
                                      <View
                                        style={[styles.line, { width: '90%' }]}
                                      />
                                    </>
                                  );
                                })}
                            </View>
                          ) : (
                            <View />
                          )}
                        </View>
                      ))}

                      <View style={[styles.line]} />
                      <TableBodyRow>
                        <TableBodyRowData style={{ width: '70%' }} />
                        <TableBodyRowData style={{ width: '15%' }}>
                          <Text
                            style={[styles.contentText, { fontWeight: 'bold' }]}
                          >
                            {currency(resume.totalReceivedValue)}
                          </Text>
                        </TableBodyRowData>
                        <TableBodyRowData style={{ width: '15%' }}>
                          <Text
                            style={[styles.contentText, { fontWeight: 'bold' }]}
                          >
                            {currency(resume.totalPaidValue)}
                          </Text>
                        </TableBodyRowData>
                      </TableBodyRow>
                      <TableBodyRow>
                        <TableBodyRowData style={{ width: '70%' }} />
                        <TableBodyRowData style={{ width: '10%' }}>
                          <Text style={[styles.headerText]}>Saldo Final:</Text>
                        </TableBodyRowData>
                        <TableBodyRowData style={{ width: '20%' }}>
                          <Text
                            style={[styles.contentText, { fontWeight: 'bold' }]}
                          >
                            {currency(resume.finalValue)}
                          </Text>
                        </TableBodyRowData>
                      </TableBodyRow>
                    </TableBody>
                  </Table>
                </Content>
              </View>
            );
          }
        )}
      </Page>
      <Page size="A4" style={styles.page}>
        <H1 fixed>Fluxo de Caixa</H1>
        <Content fixed>
          <Text style={styles.contentDetailsLabel}>
            {formatDate(initialDate)} - {formatDate(finalDate)}
          </Text>
        </Content>

        <Content>
          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Totalizadores
            </Text>
          </View>
        </Content>

        <Content style={{ marginTop: -3.5 }}>
          <Table>
            <TableHead
              style={[
                styles.contentText,
                {
                  paddingBottom: 3.5,
                  fontWeight: 'bold',
                  marginTop: '2px',
                },
              ]}
            >
              <TableHeadTitle style={[{ marginLeft: -5, width: '40%' }]}>
                Descrição
              </TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>Valor</TableHeadTitle>
              <TableHeadTitle style={[{ width: '12%' }]}>Tipo</TableHeadTitle>
            </TableHead>

            <TableBody>
              {finalValues.map(
                ({ cashierBankDescription, type, finalValue }, index) => (
                  <TableBodyRow key={index}>
                    <TableBodyRowData style={{ width: '40%' }}>
                      <Text style={[styles.contentText]}>
                        {cashierBankDescription}
                      </Text>
                    </TableBodyRowData>

                    <TableBodyRowData style={{ width: '12%' }}>
                      <Text
                        style={[
                          styles.contentText,
                          finalValue < 0 && { color: 'red' },
                        ]}
                      >
                        {currency(finalValue)}
                      </Text>
                    </TableBodyRowData>

                    <TableBodyRowData style={{ width: '12%' }}>
                      <Text style={[styles.contentText]}>{type}</Text>
                    </TableBodyRowData>
                  </TableBodyRow>
                )
              )}
            </TableBody>
          </Table>
        </Content>
      </Page>
    </Document>
  );
};

const getValueCost = (salesItems) => {
  let valueCost = 0;

  salesItems.forEach((item) => {
    valueCost += item.Value_Cost;
  });

  return valueCost;
};

const getProfitValue = (salesItems) => {
  let totalValueCost = 0;
  let totalAmount = 0;

  salesItems.forEach((item) => {
    totalValueCost += item.Value_Cost;
    totalAmount += item.Amount;
  });

  return totalAmount - totalValueCost;
};

const getProfitValuePercent = (salesItems) => {
  let totalValueCost = 0;
  let totalAmount = 0;

  salesItems.forEach((item) => {
    totalValueCost += item.Value_Cost;
    totalAmount += item.Amount;
  });

  return Math.ceil((100 * (totalAmount - totalValueCost)) / totalAmount) + '%';
};

const CashierBankFlowHeader = () => {
  return (
    <>
      <View style={{ flexDirection: 'row', height: 15, width: '100%' }}>
        <View
          style={{ width: '13%', textAlign: 'left', justifyContent: 'center' }}
        >
          <Text style={styles.headerTitle}>Data Criação</Text>
        </View>
        <View
          style={{
            width: '13%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Data Fech.</Text>
        </View>
        <View
          style={{
            width: '13%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Tipo</Text>
        </View>
        <View
          style={{
            width: '10%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Número</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Cliente</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Placa</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Valor</Text>
        </View>
        <View
          style={{
            width: '15%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.headerTitle}>Vendedor</Text>
        </View>
      </View>
      <View style={styles.line}></View>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 5,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    fontSize: '14',
  },
  contentText: {
    fontFamily: 'Roboto',
    fontSize: 9,
  },
  contentDetailsLabel: {
    fontSize: 9,
  },
  table: {
    display: 'table',
  },
  thead: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  theadTitle: {
    fontSize: 10,
    fontFamily: 'Roboto',
    padding: '0px 5px',
  },
  theadResumeTitleDescription: {
    width: 430,
  },
  theadResumeTitleValue: {
    width: 100,
  },
  theadResumeTitleType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tbody: {
    padding: '5px 0px 0px 0px',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  tbodyRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  tbodyRowData: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'flex-start',
    margin: '4px 0px',
  },
  tbodyRowDataRedLabel: {
    color: 'red',
  },
  tbodyRowDataReceivedByPaymentFormValue: {
    width: 100,
  },
  tbodyRowDataResumeDescription: {
    width: 430,
  },
  tbodyRowDataResumeValue: {
    width: 100,
  },
  tbodyRowDataResumeType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  additionalInfo: {
    fontFamily: 'Roboto',
    fontSize: '7px',
  },
  observations: {
    fontFamily: 'Roboto',
    fontSize: 9,
    marginLeft: 20,
  },
  clientAndVehicle: {
    fontFamily: 'Roboto',
    fontWeight: 'Medium',
    fontStyle: 'italic',
    fontSize: 7,
  },
  page: {
    padding: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
  headerText: {
    fontSize: 10,
  },
});
export default CashClosingReportDocument;
