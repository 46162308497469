import { create } from "zustand";


const initialValue = {
  finishedSaleId: null,
}
export const useSaleStore = create((set) => ({
  ...initialValue,
   saveFinishedSaleId: (finishedSaleId) => {
     set({ finishedSaleId })
  },
}))
