import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { usePlanSignatureContext } from '../../contexts/plan-signature'
import SuperlogicaClientArea from '../../client/components/SuperlogicaClientArea'

import logoImage from '../../assets/img/os-digital-letra-branca.png'

import './styles.css'

const BlockedClient = ({ history }) => {
  const { isClientBlocked, isSignatureManuallyBlocked, isSignatureValid } =
    usePlanSignatureContext()

  useEffect(() => {
    if (!isClientBlocked && !isSignatureManuallyBlocked && isSignatureValid) {
      history.push('/client-login')
    }
  }, [isClientBlocked, isSignatureManuallyBlocked, isSignatureValid])

  return (
    <div className="blocked-client-page">
      <header>
        <img src={logoImage} alt="OS Digital" />
      </header>
      <main>
        <section>
          <p>Prezado(a) Cliente,</p>
          <p>
            Você possui débitos em aberto. Efetue o pagamento para que o seu
            sistema seja liberado. Caso tenha dúvidas, entre em contato através
            dos telefones: (19) 3755-3003 / 3029 ou via WhatsApp:
            <a
              className="whatsapp-link"
              href="https://api.whatsapp.com/send?phone=5519992328861"
              target="_blank"
            >
              {' '}
              Clicando aqui
              {' '}
              <FontAwesomeIcon
                icon={faWhatsapp}
                color="#25d366"
                cursor="pointer"
              />
            </a>
          </p>
        </section>
        <SuperlogicaClientArea />
      </main>
    </div>
  )
}

export default BlockedClient
