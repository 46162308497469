import React, { useEffect, useState } from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, phoneMask, cpfOrCnpjMask, cepMask } from 'client/components/ToNormalize/ToNormalize'

const SingleQuotationReportDocument = ({ responseData }) => {   
    return (
        <Document
          title={`impresso-recibo-${format(new Date(), 'dd/MM/yyyy')}`}
        >   
            <Page style={styles.page} >
                <HeaderInfos 
                    quotation={responseData.Quotation} 
                    provider={responseData.Provider}
                    createdAt={responseData.createdAt}
                    providerPhone={responseData.provider_phone}
                />

                <TableHeader 
                    items={responseData.QuotationResponseItems}
                />

                <Text style={[styles.text, { paddingVertical: '10px', marginHorizontal: ''}]}>
                    <Text style={[styles.boldText, { marginRight: '10px' }]}>
                        Observações: 
                    </Text> 
                        {responseData?.observations}
                </Text>

                <View style={styles.line} />
            </Page>
        </Document>
      )
}

const TableHeader = ({ items }) => {
    return (
        <>
            <View style={[styles.line, { marginTop: '10px' }]}/>
                <View style={{ display: 'flex', padding: '5px', flexDirection: 'row', alignContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>Código</Text>
                    <Text style={[styles.boldText, { width: '25%', textAlign: 'center' }]}>Descrição</Text>
                    <Text style={[styles.boldText, { width: '15%', textAlign: 'center' }]}>Ref. Fabricante</Text>
                    <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>Marca</Text>
                    <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>QTD</Text>
                    <Text style={[styles.boldText, { width: '15%', textAlign: 'center' }]}>Valor Unit.</Text>
                    <Text style={[styles.boldText, { width: '15%', textAlign: 'center' }]}>Total</Text>
                </View>

                <View style={styles.line} />
                    {
                        items && (items.map((items, index) => {
                            return (
                                <View key={index} style={{ padding: '5px', display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', width: '100%', justifyContent: 'left' }}>
                                    <View style={{ width: '10%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{items.QuotationItem.Product.code}</Text>
                                    </View>
                                    <View style={{ width: '25%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{items.QuotationItem.description}</Text>
                                    </View>
                                    <View style={{ width: '15%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{items.QuotationItem.manufacturer_ref}</Text>
                                    </View>
                                    <View style={{ width: '10%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{items.QuotationItem.brand}</Text>
                                    </View>
                                    <View style={{ width: '10%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{items.avaliable_quantity}</Text>
                                    </View>
                                    <View style={{ width: '15%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{currency(items.unit_value)}</Text>
                                    </View>
                                    <View style={{ width: '15%', textAlign: 'center' }}>
                                        <Text style={styles.text}>{currency(items.avaliable_quantity * items.unit_value)}</Text>
                                    </View>
                                </View>
                            )
                        }))
                    }
                <View style={styles.line} />
        </>
    )
}

const HeaderInfos = ({ createdAt, quotation, provider, providerPhone }) => {
    return (
        <>
            <Text style={styles.title}>Cotação Online {quotation?.code}</Text>
            <View style={{ marginTop: '5px' }}>
                <Text style={styles.text}><Text style={styles.boldText}>Fornecedor: </Text>{provider?.companyName}</Text>
                <Text style={styles.text}><Text style={styles.boldText}>CPF/CNPJ: </Text>{provider?.cpfCnpj ? cpfOrCnpjMask(provider?.cpfCnpj) : 'Não informado'}</Text>
                <Text style={styles.text}><Text style={styles.boldText}>Celular: </Text>{ phoneMask(providerPhone) }</Text>
                <Text style={styles.text}><Text style={styles.boldText}>Status: </Text>Respondido - {createdAt && `${format(new Date(createdAt), 'dd/MM/yyyy')} ${format(new Date(createdAt), 'HH:mm')}`}</Text>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 10,
      fontFamily: 'Roboto'
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Roboto'
    },
    boldText:{
      fontSize: 9,
      fontWeight: 'bold',
      fontFamily: 'Roboto'
    },
    text:{
        fontSize: 9,
        fontFamily: 'Roboto'
    },
    line: {
        width: '100%',
        borderBottomWidth: 1,
        height: 2
    },
})

export default SingleQuotationReportDocument