import React from 'react'
import Toggle from 'react-toggle'

import { useSelector, useDispatch } from 'react-redux'
import { handleChange } from './ReduxMeusDetalhes/meusDetalhesAction'

import { usePlanSignatureContext } from 'contexts/plan-signature'

import environmentConfig from 'config'

const OtherInfos = () => {
  const { meusDetalhesReducer } = useSelector((state) => state)
  const { StartTime, EndTime, Site, showCompanyInSobreRodas } =
    meusDetalhesReducer
  const dispatch = useDispatch()

  const { isPlanFree } = usePlanSignatureContext()

  const handleOpenSobreRodas = () => {
    window.open(`${environmentConfig.sobreRodasWebEndpoint}`, '_blank')
  }

  return (
    <div id="labelSemUpperCase">
      <div
        className="col-xs-12 col-sm-3 col-md-3 col-lg-5"
        style={{
          maxWidth: '330px',
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <label>
          Horário de funcionamento:<span style={{ color: 'red' }}>*</span>
        </label>
        <div className="horarios col-md-11" style={{ paddingLeft: '10px' }}>
          <input
            type="time"
            name="StartTime"
            value={StartTime}
            className="form-control foco-input"
            onChange={(event) =>
              dispatch(handleChange(event.target.value, 'StartTime'))
            }
            style={{
              maxWidth: '130px',
            }}
          />
          <input
            type="time"
            name="EndTime"
            value={EndTime}
            className="form-control foco-input"
            onChange={(event) =>
              dispatch(handleChange(event.target.value, 'EndTime'))
            }
            style={{
              maxWidth: '130px',
            }}
          />
        </div>
      </div>
      <div
        className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
        style={{ marginLeft: '-30px' }}
      >
        <label>Site ou página da empresa:</label>
        <input
          className="form-control"
          value={Site}
          name="Site"
          onChange={(event) =>
            dispatch(handleChange(event.target.value, 'Site'))
          }
        ></input>
      </div>
      {/* TODO - Descomentar após ativação do SR */}
      {/* <div
        className="col-xs-12 col-sm-5 col-md-5 col-lg-3"
        style={{
          alignSelf: 'left',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label>
          Exibir Empresa no{' '}
          <a
            className="otherInfos__sobreRodasLink"
            onClick={() => {
              handleOpenSobreRodas()
            }}
          >
            Sobre Rodas
          </a>
        </label>
        <Toggle
          checked={showCompanyInSobreRodas}
          disabled={isPlanFree}
          onChange={() =>
            dispatch(
              handleChange(!showCompanyInSobreRodas, 'showCompanyInSobreRodas')
            )
          }
        />
      </div> */}
    </div>
  )
}

export default OtherInfos
