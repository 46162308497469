import React from 'react'
import Button from 'client/components/CustomButton/CustomButton.jsx'

const NewKitFormButtons = ({
  onCancel,
  onSubmit,
  hasId,
  loading,
  disabledSubmit,
}) => {
  return (
    <div className="form-submit-buttons">
      <Button bsStyle="danger" fill disabled={loading} onClick={onCancel}>
        {hasId ? 'Voltar' : 'Cancelar'}
      </Button>
      <Button
        bsStyle="info"
        fill
        onClick={onSubmit}
        disabled={loading || disabledSubmit}
      >
        <span
          className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          style={{ marginRight: loading ? '5px' : '' }}
        />
        Salvar
      </Button>
    </div>
  )
}

export default NewKitFormButtons
