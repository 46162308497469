import { currency } from 'client/components/ToNormalize/ToNormalize';
import React, { memo } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const TableByFormPayment = ({ data, loading }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <span
        style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}
      >
        Recebimentos por Forma de Pagamento (R$)
      </span>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: 'Tipo',
            accessor: 'type',
            width: 100,
          },
          {
            Header: 'Descrição',
            accessor: 'description',
          },
          {
            Header: 'Valor',
            accessor: 'amount',
            Cell: (props) => currency(props.value),
            width: 100,
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma informação encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default memo(TableByFormPayment);
