import { currency, cnpjMask } from "client/components/ToNormalize/ToNormalize"

export const xlsColumns = [
  {
    name: 'Fornecedor',
    acessor: 'name'
  },
  {
    name: 'CNPJ',
    acessor: 'cnpj'
  },
  {
    name: 'Total de Compras',
    acessor: 'quantity'
  },
  {
    name: 'R$ Valor Total',
    acessor: 'amount'
  }
]

export const getPurchasesInfoToExport = purchases => {
  return purchases.map(purchase => ({
    providerId: purchase.providerId,
    name: purchase.name,
    cnpj: cnpjMask(purchase.cnpj),
    amount: currency(purchase.amount),
    quantity: purchase.quantity
  }))
}