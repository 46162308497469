import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import RenderField from 'components/RenderField';
import { useSelector } from 'react-redux';
import { getColorClassByBankSlipStatus } from 'utils/bankSlipUtils';

const BillDataForm = () => {
  const { bank_slip_status_description } = useSelector(
    (state) => state.form.billToReceive?.values
  );

  return (
    <div className="bills-to-receive__bank-slip-grid">
      <Field
        label="Código de barras/Linha Digitável:"
        name="bank_slip_barcode"
        component={RenderField}
        disabled={true}
      />
      <Field
        label="Carteira Bancária:"
        name="bankBilletAccount"
        component={RenderField}
        disabled={true}
      />
      <Field
        label="Juros:"
        name="bank_slip_fee"
        component={RenderField}
        disabled={true}
      />
      <Field
        label="Multa:"
        name="bank_slip_fine"
        component={RenderField}
        disabled={true}
      />

      <Field
        label="Status do Boleto:"
        name="bank_slip_status_description"
        component={RenderField}
        disabled={true}
        className={getColorClassByBankSlipStatus(bank_slip_status_description)}
      />
    </div>
  );
};

export default BillDataForm;
