import React from 'react';
import { toastr } from 'react-redux-toastr';
import { format, differenceInDays, parse } from 'date-fns';
import IBPTRepository from 'v2/repositories/IPBTRepository';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
export const useIBPT = () => {
  const dispatch = useDispatch();

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const validationIBPTFile = async (file) => {
    try {
      const base64 = await convertFileToBase64(file);
      const validationFile = await IBPTRepository.validationIBPTFile(base64);

      return validationFile;
    } catch (error) {
      toastr.error('Tabela não importada', error.response.data.message);
      return;
    }
  };
  const importIBPTFile = async (companyId, file) => {
    try {
      const base64 = await convertFileToBase64(file);
      const validationFile = await IBPTRepository.importIBPTFile(
        companyId,
        base64
      );
      const dateFinalValidityIBPTFile = await getFinalValidityIBPTFile(
        companyId
      );
      dispatch(
        change(
          'settingsTaxData',
          'dateFinalValidityIBPTFile',
          dateFinalValidityIBPTFile
        )
      );
      toastr.success(
        'Tabela importada com sucesso',
        `A tabela foi importada e será válida até ${format(
          new Date(dateFinalValidityIBPTFile),
          'dd/MM/yyyy'
        )}`
      );
      return validationFile;
    } catch (error) {
      console.error('Erro ao importar o arquivo:', error);
      toastr.error('Tabela não importada', error.response.data.message);
      return null;
    }
  };

  const getFinalValidityIBPTFile = async (companyId) => {
    try {
      const response = await IBPTRepository.getFinalValidityIBPTFile({
        companyId,
      });
      return response;
    } catch (error) {
      console.error('Erro ao importar o arquivo:', error);
      return null;
    }
  };

  const renderIBPTExpireDate = (dateFinalValidityIBPTFile) => {
    if (dateFinalValidityIBPTFile) {
      const today = new Date();

      const expirationDate = new Date(dateFinalValidityIBPTFile);

      const daysUntilExpiration = differenceInDays(expirationDate, today);

      const getColorByExpiration = (days) => {
        if (days === 0) return '#D9534F';
        if (days > 0 && days <= 7) return '#D9534F';
        if (days > 0 && days <= 30) return '#F0AD4E';
        return 'black';
      };

      return (
        <span style={{ color: getColorByExpiration(daysUntilExpiration) }}>
          {format(expirationDate, 'dd/MM/yyyy')}
        </span>
      );
    }
    return null;
  };

  return {
    validationIBPTFile,
    importIBPTFile,
    getFinalValidityIBPTFile,
    renderIBPTExpireDate,
  };
};
