import config from "../config";
import api from "../services/api";

export const getByVehicleId = async (vehicleId, params) => {
  const response = await api.get(
    config.endpoint + `report/vehicle-history/${vehicleId}`
  , { params: { ...params }});
  return response.data;
};

const VehicleHistory = {
  getByVehicleId,
}

export default VehicleHistory;
