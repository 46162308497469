import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from 'utils/constants'
import BundleNewDiscountGroup from './BundleNewDiscountGroup'

const NewDiscountGroup = ({ match }) => {
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Cadastro',
              'Vendas',
              'Tabela de Preços Diferenciados',
              match.params.id ? 'Editar Tabela' : 'Nova Tabela',
            ]}
            path={[
              constants.ROUTES.HOME,
              null,
              null,
              constants.ROUTES.DISCOUNT_GROUPS,
              null,
            ]}
          />
          <BundleNewDiscountGroup />
        </Row>
      </Grid>
    </div>
  )
}
export default NewDiscountGroup
