import React, { useState, useEffect } from "react";
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { Card } from 'components/Card/Card.jsx'
import { Col } from 'react-bootstrap'
import './styles.css'
import AlertModal from "components/AlertModal/AlertModal";

import { CFOPMask, specialChar4 } from 'client/components/ToNormalize/ToNormalize'
import { CFOPsTypes, CFOPsInputReturn, CFOPsOutputReturn } from './cfopsEntries'
import { toastr } from "react-redux-toastr";

import api from "services/api";
import config from 'config'

const FormCFOP = ({ cfopId, onSubmit, onCancel }) => {
  const [cfop, setCfop] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('Entrada')
  const [calculateICMSWithOtherExpenses, setCalculateICMSWithOtherExpenses] = useState(true)
  const [calculateICMSWithIPI, setCalculateICMSWithIPI] = useState(true)
  const [observation, setObservation] = useState('')
  const [cancelModal, setCancelModal] = useState(false)
  const [companyIdFromTheCfop, setCompanyIdFromTheCfop] = useState(null)
  
  const companyId = localStorage.getItem('ID_EMPRESA')

  useEffect(() => {
    if(!!cfopId) {
      loadCFOP()
    }
  }, [])

  const loadCFOP = async () => {
    
    try {
      const response = await api.get(config.endpoint + `natureoftransactions/${cfopId}`)

      setCfop(response.data.CFOP)
      setDescription(response.data.description)
      setType(response.data.type)
      setCalculateICMSWithOtherExpenses(!!response.data.calculateICMSWithOtherExpenses)
      setCalculateICMSWithIPI(!!response.data.calculateICMSWithIPI)
      setObservation(response.data.observation)
      setCalculateICMSWithIPI(response.data.calculateICMSWithIPI)
      setCompanyIdFromTheCfop(response.data.companyId)

    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao carregar a CFOP. Por favor, tente novamente')
    }
  }

  const handleChangeCFOP = e => {
    const { value } = e.target

    const acceptedFirstNumbers = ['1', '2', '3', '5', '6', '7']
    if(!value) return setCfop('')

    if(value.length === 1) {

      if(!acceptedFirstNumbers.includes(value[0])) {
        setCfop('')
        return toastr.warning('Os números permitidos são 1, 2, 3, 5, 6 e 7, por favor, verifique.')
      }

    }
    handleTypeOfCFOP(value)


    setCfop(CFOPMask(e.target.value))
  }

  const handleTypeOfCFOP = value => {

    if(value.length < 5) {

      switch(value[0]) {
        case '1': 
        case '2':
        case '3': setType('Entrada')
          break;
        case '5':
        case '6': 
        case '7': setType('Saída')
      }

    } else {
      if(CFOPsInputReturn.map(cfop => cfop.toString()).includes(value)) {
        setType('Devolução de Entrada')

      } else if(CFOPsOutputReturn.map(cfop => cfop.toString()).includes(value)){
        
        setType('Devolução de Saída')
      }
    }
  }

  const handleSubmit = () => {

    if(!cfop || !description) {
      return toastr.warning('Por favor, preencha todos os dados para cadastrar')
    }

    if(cfop.length < 5) {
      return toastr.warning('CFOP incompleto')
    }

    if(cfop === '6.405'){
      return toastr.error('A CFOP 6.405 é uma CFOP inexistente na relação de CFOP disponibilizada pela SEFAZ. Valide o cadastro e tente novamente.')
    }


    if(!cfopId || (isDefaultCFOP())) {
      create()
    } else {
      update()
    }
  }

  const isDefaultCFOP = () => !!cfopId && !companyIdFromTheCfop

  const create = async () => {
    if(!cfopId) {
      if(( await hasNatureOfTransactionWithThisCFOP(cfop) )) {
        return toastr.warning('CFOP já cadastrado')
      }
    }

    try {
      
      await api.post(config.endpoint + 'natureoftransactions', { 
        CFOP: cfop, 
        description, 
        type, 
        calculateICMSWithOtherExpenses, 
        calculateICMSWithIPI, 
        observation, 
        companyId
      })

      onSubmit()

    } catch(err) {
      console.log(err);
      return console.log(('Ocorreu um erro ao salvar a CFOP. Por favor, tente novamente'));
    }

  }

  const update = async () => {

    try {
      await api.put(config.endpoint + `natureoftransactions/${cfopId}`, {
        description,
        type,
        calculateICMSWithIPI,
        calculateICMSWithOtherExpenses,
        observation
      })

      onSubmit()

    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar a CFOP. Por favor, tente novamente')
    }
  }

  const hasNatureOfTransactionWithThisCFOP = async value => {
    const response = await api.get(config.endpoint + `natureoftransactions?CFOP=${value}`)

    const natureOfTransactionOfThisCompany = response.data.filter(child => !!child.companyId).find(child => parseInt(child.companyId) === parseInt(companyId))
    const defaultOfTransactionWithThisCFOP = response.data.filter(child => !child.companyId).find(child => child.CFOP === value)

    return (!!natureOfTransactionOfThisCompany || !!defaultOfTransactionWithThisCFOP)
  }
  
  return (
    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }} >
      <CardForm
        show={true}
        title="Dados Cadastrais"
        onClick={() => {} }
      >
        <Card content={
          <form id='form-new-cfop' className="col-xs-12 col-sm-12 col-md-12 col-lg-12 row">
            <Col xs={12} sm={12} md={3} lg={1} >
              <label htmlFor='CFOP'>CFOP:<span>*</span></label>
              <input
                disabled={!!cfopId}
                className='form-control foco-input' 
                type="text" 
                id='CFOP' 
                value={cfop} 
                onChange={handleChangeCFOP}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={8} >
              <label htmlFor='description'>Descrição:<span>*</span></label>
              <input 
                className='form-control foco-input' 
                maxLength='50' 
                value={description} 
                onChange={e => setDescription(specialChar4(e.target.value))} 
                type="text" 
                id='description'
              />
            </Col>

            <Col xs={12} sm={12} md={3} lg={2} style={{ minWidth: 'fit-content' }} >
              <label htmlFor='type'>Tipo:<span>*</span></label>
              <select className='form-control foco-input' id='type' onChange={(e) => setType(e.target.value)}  value={type}>
                {CFOPsTypes.map(cfopType => <option key={cfopType} value={cfopType}>{cfopType}</option>)}
              </select>
            </Col>

            <Col xs={12} sm={12} md={6} lg={3} style={{ maxWidth: 250 }}>
              <label htmlFor='calculateICMSWithOtherExpenses'>Calcula ICMS c/ Outras Desp.?<span>*</span></label>
              <Col xs={12} sm={12} md={6} lg={6} >
                <select
                  className='form-control foco-input' 
                  value={calculateICMSWithOtherExpenses} 
                  type="text" 
                  id='calculateICMSWithOtherExpenses'
                  onChange={e => setCalculateICMSWithOtherExpenses(e.target.value === 'true')}
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </Col>
            </Col>
{/* 
            <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{ maxWidth: 250 }}>
              <label htmlFor='calculateICMSWithIPI'>Calcula ICMS com R$ IPI?<span>*</span></label>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <select 
                  className='form-control foco-input' 
                  value={calculateICMSWithIPI} 
                  type="text" 
                  id='calculateICMSWithIPI'
                  onChange={e => setCalculateICMSWithIPI(e.target.value === 'true')}
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </Col>
            </Col> */}

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <label htmlFor='observations'>Observações:</label>
              <textarea className='form-control foco-input' type="text" value={observation} onChange={e => setObservation(e.target.value)} maxLength='500' id='observations'/>
            </Col>

          </form>
        } />

      </CardForm>

      <FormSubmitButtons onCancel={() => setCancelModal(true)} hasId={!!cfopId} onSubmit={handleSubmit}  />

      {cancelModal && 
        <AlertModal 
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar ?'
          onSubmit={onCancel}
        />
      }
    </div>
  )
}

export default FormCFOP