import React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import Button from 'client/components/CustomButton/CustomButton'

export default function SelectPurchaseRegisterTypeModal({
  handleClose,
  handleOpenImportXMLModal,
}) {
  const history = useHistory()

  return (
    <>
      <Modal show animation handleClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <span style={{ display: 'block' }}>
              Selecione o tipo de compra que deseja criar:
            </span>

            <br />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Button
                style={{
                  backgroundColor: '#428bca',
                  border: 'none',
                  width: '200px',
                }}
                fill
                onClick={() => history.push('/client/purchase')}
              >
                Criar compra manualmente
              </Button>

              <Button
                style={{
                  backgroundColor: '#5cb85c',
                  border: 'none',
                  width: '200px',
                }}
                fill
                onClick={() => {
                  handleOpenImportXMLModal()
                  handleClose()
                }}
              >
                Importar XML
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" onClick={() => handleClose()} fill>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
