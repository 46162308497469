import React from 'react'

import { Grid, Row } from 'react-bootstrap'

import Main from './components/VoucherPaymentMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

export default function VoucherPayment({ match, history }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Pagamento de Vale']}
            path={['home', null]}
          />
          <Main />
        </Row>
      </Grid>
    </div>
  )
}