import { currency, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import React, { memo } from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";

const TableByProvider = ({ data, loading }) => {
  return (
    <>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: "Fornecedor",
            accessor: "name",
            width: 200
          },
          {
            Header: "CNPJ",
            accessor: "cnpj",
            width: 150,
            Cell: props => cnpjMask(props.value)
          },
          {
            Header: "Total de Compras",
            accessor: "quantity",
            width: 150
          },
          {
            Header: "R$ Valor Total",
            accessor: "amount",
            width: 150,
            Cell: props => currency(props.value)
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Nenhuma informação encontrada'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </>
  )
}

export default memo(TableByProvider)