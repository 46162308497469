import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import CustomerCreditMain from './components/CustomerCreditMain'

const CustomerCredit = ({ history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Clientes', 'Crédito para Cliente']}
            path={['home', 'customers', null]}
          />
          <CustomerCreditMain history={history} />
        </Row>
      </Grid>
    </div>
  )
}

export default CustomerCredit
