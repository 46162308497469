import React from 'react';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { PanelAppointmentModal } from '../PanelAppointmentModal';

export function OperationModal({ onCancel, maintenance }) {
  const [isStopServiceModalOpen, setIsStopServiceModalOpen] = useState(false);
  const [isFinishServiceModalOpen, setIsFinishServiceModalOpen] =
    useState(false);
  const [isShowHistoryModal, setIsShowHistoryModal] = useState(false);
  const [isOpenStartServiceModalOpen, setIsOpenStartServiceModalOpen] =
    useState(false);

  const hasServicesNotStarted = maintenance.services.some(
    (service) => service.shortStatus === 'NI' || service.shortStatus === 'P'
  );

  const hasServicesInProgress = maintenance.services.some(
    (service) => service.shortStatus === 'E'
  );

  const hasServicesStopped = maintenance.services.some(
    (service) => service.shortStatus === 'P'
  );

  return (
    <>
      <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Painel da oficina</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>Selecione a opção desejada: </p>
          <div className="panel-operation-buttons">
            <CustomButton
              fill
              style={{
                background: '#5CB85C',
                borderColor: '#5CB85C',
                fontSize: '16px',
              }}
              disabled={!hasServicesNotStarted}
              onClick={() => setIsOpenStartServiceModalOpen(true)}
            >
              Iniciar Serviço
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#d9534f',
                borderColor: '#d9534f',
                fontSize: '16px',
              }}
              disabled={maintenance.status !== 'Em Andamento'}
              onClick={() => setIsStopServiceModalOpen(true)}
            >
              Parar Serviço
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#f0ad4e',
                borderColor: '#f0ad4e',
                fontSize: '16px',
              }}
              onClick={() => setIsShowHistoryModal(true)}
            >
              Histórico dos Serviços
            </CustomButton>
            <CustomButton
              fill
              style={{
                background: '#428bca',
                borderColor: '#428bca',
                fontSize: '16px',
              }}
              disabled={!hasServicesInProgress && !hasServicesStopped}
              onClick={() => setIsFinishServiceModalOpen(true)}
            >
              Finalizar Serviço
            </CustomButton>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="Voltar"
              onClick={onCancel}
              fill
            >
              Voltar
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>

      {isOpenStartServiceModalOpen && (
        <PanelAppointmentModal
          open={true}
          handleClose={() => setIsOpenStartServiceModalOpen(false)}
          maintenance={maintenance}
          mode="start-service"
        />
      )}

      {isShowHistoryModal && (
        <PanelAppointmentModal
          open={true}
          handleClose={() => setIsShowHistoryModal(false)}
          maintenance={maintenance}
          mode="service-history"
        />
      )}

      {isStopServiceModalOpen && (
        <PanelAppointmentModal
          open={true}
          handleClose={() => setIsStopServiceModalOpen(false)}
          maintenance={maintenance}
          mode="stop-service"
        />
      )}

      {isFinishServiceModalOpen && (
        <PanelAppointmentModal
          open={true}
          handleClose={() => setIsFinishServiceModalOpen(false)}
          maintenance={maintenance}
          mode="finish-service"
        />
      )}
    </>
  );
}
