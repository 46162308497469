import React, { useState, useEffect } from 'react'
import './style.css'
import google from '../../assets/img/chrome.png'
import firefox from '../../assets/img/firefox.png'
import Bowser from "bowser";

const BrowserLock = () => {
  const [browser, setBrowser] = useState(null)
  useEffect(() => {
    getBrowser()
  }, [])

  const getBrowser = async () => {
    const userBrowser = Bowser.getParser(window.navigator.userAgent);

    setBrowser(userBrowser.getBrowserName())
  }

  return (
    <div>
      <div className='navegador-header'>
        <span id='TextHeader'>OS Digital</span>
      </div>
      <div className='container-navegador  col-xs-12 col-sm-12 col-md-12 col-lg-12'>
        <div style={{ margin: '0px 15%', display: 'flex', flexDirection: 'column' }} >
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', height: '120%', backgroundColor: '#ddd', marginTop: 25, padding: 30 }}>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{ display: 'flex', flexDirection: 'row', paddingLeft: '15%' }}>
              <div className='col-xs-12 col-sm-12 col-md-2 col-lg-2'>
                <span className="fa fa-frown-o fa-6" style={{ fontSize: '85px', marginTop: '-15px' }} aria-hidden="true"></span>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-10'>
                <span>
                  <p>O navegador <b>{browser}</b> não suporta corretamente as funções do sistema.</p>
                  <p>Por favor instale um dos navegadores compatíveis para continuar.</p>
                </span>
              </div>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', height: '120px', backgroundColor: 'white', marginTop: 10, padding: 30 }}>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{
                display: 'flex', flexDirection: 'row', paddingLeft: 30,
                justifyContent: 'center', alignItems: 'center', textAlign: 'center'
            }}>
              <a id='btn-navegador' href='https://www.google.com/intl/pt-BR/chrome/' target='_blank'>
                <img style={{ cursor: 'pointer', height: '40px' }} src={google} />
              </a>

              <a id='btn-navegador' href='https://www.mozilla.org/pt-BR/firefox/new/' target='_blank'>
                <img style={{ cursor: 'pointer', height: '40px' }} src={firefox} />
              </a>

              </div>
          </div>

        </div>
      </div>
    </div>
)
}

export default BrowserLock