import React from 'react';
import SearchInput from 'components/SearchInput';
import '../styles.css';
import { addDays, format } from 'date-fns';

const CustomerCreditFilters = ({
  setRelease,
  handleChangeQuery,
  setInitialDate,
  setFinalDate,
  initialDate,
  finalDate,
  setNewCustomerCredit,
}) => {
  return (
    <div
      style={{
        padding: 0,
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'flex-end',
        gap: '10px',
        marginTop: '10px',
      }}
    >
      <button
        className="btn btn-sucesso button-h35"
        onClick={() => setNewCustomerCredit(true)}
        style={{ fontSize: '12px' }}
      >
        + Novo Lançamento
      </button>
      <div>
        <SearchInput
          placeholder="Pesquisa por CPF/CNPJ ou Nome/Nome Fantasia"
          onChange={(e) => handleChangeQuery(e.target.value)}
          style={{
            fontSize: '12px',
            height: '35px',
            width: '320px',
            marginLeft: 0,
            marginRight: 0,
          }}
        />
      </div>
      <fieldset>
        <span style={{ fontSize: '12px' }}>
          <strong>Lançamento:</strong>
        </span>
        <select
          onChange={(e) => setRelease(e.target.value)}
          className="form-control foco-input"
          style={{ fontSize: '12px' }}
        >
          <option value="">Ambos</option>
          <option value="Entrada">Entrada</option>
          <option value="Saída">Saída</option>
        </select>
      </fieldset>
      <fieldset>
        <span style={{ fontSize: '12px' }}>
          <strong>Data Inicial: </strong>
        </span>
        <input
          type="date"
          className="form-control foco-input"
          onChange={(e) => setInitialDate(e.target.value)}
          max={
            finalDate
              ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
              : format(new Date(), 'yyyy-MM-dd')
          }
          style={{ fontSize: '12px' }}
        />
      </fieldset>

      <fieldset>
        <span style={{ fontSize: '12px' }}>
          <strong>Data Final: </strong>
        </span>
        <input
          type="date"
          className="form-control foco-input"
          onChange={(e) => setFinalDate(e.target.value)}
          max={format(new Date(), 'yyyy-MM-dd')}
          min={
            initialDate
              ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
              : null
          }
          style={{ fontSize: '12px' }}
        />
      </fieldset>
    </div>
  );
};

export default CustomerCreditFilters;
