import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import HomeCashierBankHistoric from './CashierBankHistoric'
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import constants from '../../../utils/constants'

const CashierBankHistoric = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Venda', 'Caixa']}
            path={[constants.ROUTES.HOME, null, null]}
          />
          <HomeCashierBankHistoric />
        </Row>
      </Grid>
    </div>
  )
}
export default CashierBankHistoric