import React, { useEffect, useState } from 'react';

import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { toastr } from 'react-redux-toastr';

import EmployeesRepository from 'repositories/Employees';
import { useAuth } from 'contexts/auth';

import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import AppointmentsRepository from 'repositories/AppointmentsRepository';
import InputMask from 'react-input-mask';
import AlertModal from 'components/AlertModal/AlertModal';

export default function AppointmentsCreate({ sale, saleId, salesItems }) {
  const [employees, setEmployees] = useState([]);
  const [allEmployeesSelected, setAllEmployeesSelected] = useState(false);

  const [items, setItems] = useState([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const { companyId } = useAuth();

  const { control, watch, getValues, register, handleSubmit } = useForm();

  const [dataToSubmit, setDataToSubmit] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const {
    fields: appointmentsFields,
    append: appointmentsAppend,
    replace: appointmentsReplace,
  } = useFieldArray({
    control,
    name: 'appointments',
  });

  const {
    fields: employeesFields,
    append: employeesAppend,
    replace: employeesReplace,
  } = useFieldArray({
    control,
    name: 'employees',
  });

  const watchedEmployees = watch('employees');

  function handleSelectAllEmployees() {
    const selectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: true,
    }));

    employeesReplace(selectedEmployees);
  }

  function handleUnselectAllEmployees() {
    const unselectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: false,
    }));

    employeesReplace(unselectedEmployees);
  }

  function handleSelectItems(selectedItemId) {
    const itemsCopy = [...items];
    const itemIndex = itemsCopy.findIndex((item) => item.id === selectedItemId);
    itemsCopy[itemIndex].selected = !itemsCopy[itemIndex].selected;

    setItems(itemsCopy);
  }

  function handleSelectAllItems() {
    const selectedItems = items.map((item) => ({
      ...item,
      selected: true,
    }));

    const selectedAppointmentsFields = getValues('appointments').map(
      (field) => {
        return {
          ...field,
          selected: true,
        };
      }
    );

    appointmentsReplace(selectedAppointmentsFields);
    setItems(selectedItems);
  }

  function handleUnselectAllItems({ resetWorkedHours }) {
    const selectedItem = items.map((item) => ({
      ...item,
      selected: false,
    }));

    const selectedAppointmentsFields = getValues('appointments').map(
      (field) => ({
        ...field,
        workedHours: resetWorkedHours ? '' : field.workedHours,
        selected: false,
      })
    );

    appointmentsReplace(selectedAppointmentsFields);
    setItems(selectedItem);
  }

  function handleCleanAllSelected() {
    handleUnselectAllEmployees();
    handleUnselectAllItems({ resetWorkedHours: true });
  }

  function validateWorkedHours(appointments) {
    const selectedAppointments = appointments.filter((item) => item.selected);

    let hasOnlyValidWorkedHours = true;

    selectedAppointments.forEach((appointment) => {
      const workedHoursWithoutMask = appointment.workedHours
        .replaceAll('_', '', '')

        .replace(':', '');
      if (
        workedHoursWithoutMask.length === 5 ||
        workedHoursWithoutMask.length === 0
      )
        return;
      return (hasOnlyValidWorkedHours = false);
    });

    return hasOnlyValidWorkedHours;
  }

  function validateSelectedFields(employees, items) {
    const selectedEmployeesLength = employees.filter(
      (item) => item.selected
    ).length;
    if (selectedEmployeesLength < 1) {
      return false;
    }

    const selectedItemsLength = items.filter((item) => item.selected).length;
    if (selectedItemsLength < 1) {
      return false;
    }

    return true;
  }

  function handleOpenConfirmationModal(data) {
    if (!validateSelectedFields(data?.employees, data?.appointments)) {
      toastr.warning(
        'Atenção',
        'Selecione ao menos 1 funcionário e 1 serviço para fazer o apontamento'
      );
      return;
    }

    if (!validateWorkedHours(data?.appointments)) {
      toastr.warning(
        'Atenção',
        'Insira as horas trabalhadas em um formato válido (hhh:mm)'
      );
      return;
    }

    setDataToSubmit(data);
    setIsConfirmationModalOpen(true);
  }

  function handleCloseConfirmationModal() {
    setDataToSubmit(null);
    setIsConfirmationModalOpen(false);
  }

  async function handleSubmitAppointments(dataToSubmit) {
    setLoading(true);

    try {
      const selectedItems = dataToSubmit?.appointments.filter(
        (item) => item.selected
      );
      const selectedEmployees = dataToSubmit?.employees.filter(
        (item) => item.selected
      );

      await AppointmentsRepository.bulkCreate(saleId, {
        employees: selectedEmployees,
        services: selectedItems
          .filter((item) => item.type === 'Serviço')
          .map((item) => ({ ...item, serviceId: item.id })),
        products: selectedItems
          .filter((item) => item.type === 'Produto')
          .map((item) => ({ ...item, productId: item.id })),
        companyId,
      });
      toastr.success('Apontamentos realizados!');

      handleCleanAllSelected();
      setDataToSubmit(null);
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao lançar os apontamentos. Tente novamente! ' +
          err?.data
      );
    } finally {
      setLoading(false);
      handleCloseConfirmationModal();
    }
  }

  function getItemsValue(item, value) {
    const itemProportion = item.Amount / sale.SubTotal;
    const itemSaleDiscount = sale.Discount_Value * itemProportion;

    if (value === 'Amount') {
      return item.Amount - itemSaleDiscount;
    }
    if (value === 'Unit_Value') {
      const unitItemDiscount = itemSaleDiscount / item.Quantity;
      return item.Unit_Value - unitItemDiscount;
    }
  }

  async function loadItems() {
    const serializedItems = salesItems.map((item) => {
      return {
        ...item,
        Amount: getItemsValue(item, 'Amount'),
        Unit_Value: getItemsValue(item, 'Unit_Value'),
        selected: false,
      };
    });

    serializedItems.forEach((item) => {
      appointmentsAppend({
        id: item.id,
        type: item.Type,
        commission:
          item.Services?.Commission_Rate || item.Products?.Commission_Rate,
        amount: item.Amount,
        workedHours: '',
        selected: false,
      });
    });
    setItems(serializedItems);
  }

  async function loadEmployees() {
    try {
      setLoading(true);

      let hasService;
      let hasProduct;

      salesItems.map((items) => {
        if (items.Type === 'Serviço') {
          hasService = 1;
        }
        if (items.Type === 'Produto') {
          hasProduct = 1;
        }
      });

      const { data } =
        await EmployeesRepository.getEmployeesThatPerformServicesOrSellProducts(
          companyId,
          {
            performService: hasService,
            sellProduct: hasProduct,
            isActive: 1,
          }
        );

      const serializedEmployees = data.map((item) => ({
        id: item.id,
        name: item.name,
        employeeCommission: item.comissionRate,
        roleCommissionService: item.Position?.serviceComission,
        roleCommissionProduct: item.Position?.productComission,
        selected: false,
      }));

      serializedEmployees.forEach((item) => {
        employeesAppend({
          employeeId: item.id,
          selected: false,
          employeeCommission: item.employeeCommission,
          roleCommissionService: item.roleCommissionService,
          roleCommissionProduct: item.roleCommissionProduct,
        });
      });

      setEmployees(serializedEmployees);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar funcionários',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadEmployees();
    loadItems();
  }, []);

  useEffect(() => {
    const allEmployeesLength = employees.length;
    if (allEmployeesLength > 0) {
      const selectedEmployeesLength = watch('employees').filter(
        (item) => item.selected
      ).length;

      if (selectedEmployeesLength === allEmployeesLength) {
        setAllEmployeesSelected(true);
      } else {
        setAllEmployeesSelected(false);
      }
    }
  }, [watchedEmployees]);

  useEffect(() => {
    const allItemsLength = items.length;
    if (allItemsLength > 0) {
      const selectedItemsLength = items.filter((item) => item.selected).length;

      if (selectedItemsLength === allItemsLength) {
        setAllItemsSelected(true);
      } else {
        setAllItemsSelected(false);
      }
    }
  }, [items]);

  return (
    <div
      style={{
        border: '1px solid #c0c0c0',
        padding: '10px',
        marginTop: 0,
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1.6fr 3fr',
          gap: 10,
        }}
      >
        <div>
          <span>Selecione o funcionário:</span>
          <div style={{ textAlign: 'right' }}>
            <a
              style={{
                textDecoration: 'underline',
                textAlign: 'right',
                color: '#176CF7',
                cursor: 'pointer',
              }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (!allEmployeesSelected) {
                  handleSelectAllEmployees();
                } else {
                  handleUnselectAllEmployees();
                }
              }}
            >
              {!allEmployeesSelected ? 'Selecionar ' : 'Remover '} todos
            </a>
          </div>
          <ReactTable
            data={employees}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              height: '203px',
              fontSize: '14px',
              width: '100%',
            }}
            columns={[
              {
                Header: 'Nome',
                accessor: 'name',
                className: 'texto',
                resizable: false,
              },
              {
                Header: ' ',
                accessor: 'id',
                className: 'texto',
                resizable: false,
                width: 70,
                Cell: (props) => {
                  return (
                    <>
                      <input
                        type="checkbox"
                        id={employeesFields[props.index].id}
                        className="checkbox-input bulk-deletion-table"
                        {...register(`employees.${props.index}.selected`)}
                      />
                    </>
                  );
                },
              },
            ]}
            showPagination={false}
            noDataText="Nenhum funcionário encontrado"
            loading={loading}
            loadingText="Carregando..."
          />
        </div>
        <div>
          <span>Selecione o item:</span>
          <div style={{ textAlign: 'right' }}>
            <a
              style={{
                textDecoration: 'underline',
                textAlign: 'right',
                color: '#176CF7',
                cursor: 'pointer',
              }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (!allItemsSelected) {
                  handleSelectAllItems();
                } else {
                  handleUnselectAllItems({ resetWorkedHours: false });
                }
              }}
            >
              {!allItemsSelected ? 'Selecionar ' : 'Remover '} todos
            </a>
          </div>
          <ReactTable
            data={items}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%',
              height: '203px',
              fontSize: '14px',
            }}
            columns={[
              {
                Header: 'Descrição',
                accessor: 'Description',
                resizable: false,
              },
              {
                Header: 'Qtd',
                accessor: 'Quantity',
                resizable: false,
                width: 80,
              },
              {
                Header: 'Vlr. Unit',
                accessor: 'Unit_Value',
                width: 140,
                resizable: false,
                Cell: (props) => {
                  return currency(props.value);
                },
              },
              {
                Header: 'Vlr. Total',
                accessor: 'Amount',
                width: 140,
                resizable: false,
                Cell: (props) => {
                  return currency(props.value);
                },
              },
              {
                Header: 'Hr Trabalhada',
                accessor: 'workedHours',
                resizable: false,
                width: 150,
                Cell: (props) => {
                  return (
                    <>
                      <Controller
                        name={`appointments.${props.index}.workedHours`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <InputMask
                              id={appointmentsFields[props.index].id}
                              mask={'999:59'}
                              formatChars={{
                                9: '[0-9]',
                                5: '[0-5]',
                              }}
                              maskChar={'0'}
                              disabled={
                                props.original.Type === 'Serviço' ? false : true
                              }
                              className="form-control"
                              {...field}
                            />
                          );
                        }}
                      />
                    </>
                  );
                },
              },
              {
                Header: '',
                accessor: 'id',
                resizable: false,
                width: 70,
                Cell: (props) => {
                  return (
                    <>
                      <input
                        key={appointmentsFields[props.index].id}
                        type="checkbox"
                        id={appointmentsFields[props.index].id}
                        className="checkbox-input bulk-deletion-table"
                        {...register(`appointments.${props.index}.selected`)}
                        onChange={(e) => {
                          handleSelectItems(props.value);
                          register(
                            `appointments.${props.index}.selected`
                          ).onChange(e);
                        }}
                      />
                    </>
                  );
                },
              },
            ]}
            pageSize={items.length}
            showPagination={false}
            noDataText="Nenhum serviço encontrado"
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          justifyContent: 'flex-end',
          gap: 5,
        }}
      >
        <Button
          style={{
            background: '#5BC0DE',
            borderColor: '#5BC0DE',
          }}
          fill
          onClick={handleCleanAllSelected}
        >
          Limpar
        </Button>
        <Button
          bsStyle="info"
          fill
          onClick={handleSubmit(handleOpenConfirmationModal)}
        >
          Aplicar
        </Button>
      </div>

      <AlertModal
        show={isConfirmationModalOpen}
        onHide={handleCloseConfirmationModal}
        onCancel={handleCloseConfirmationModal}
        onSubmit={() => handleSubmitAppointments(dataToSubmit)}
        loading={loading}
        message={
          <>
            <span>
              <strong>Você deseja realizar o(s) apontamento(s)?</strong>
            </span>
            <br />
            <br />
            <span>
              Ao realizar o apontamento, o funcionário será comissionado em cima
              da ação realizada. Você poderá verificar os apontamentos lançados
              na aba Apontamentos Realizados.
            </span>
          </>
        }
      />
    </div>
  );
}
