import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { Card } from 'components/Card/Card.jsx';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import { description } from '../../../components/ToNormalize/ToNormalize';
import api from 'services/api';
import config from 'config';
import { change } from 'redux-form';
import FormGarantia from '../../Warranty/NewWarranty/ModalFormWarranty';
import FormRevisao from '../../Reviews/NewReview/ModalNewReview.js';
import constants from '../../../../utils/constants';
import { isFirefox } from '../../../../utils/browserTypes';
import { useBranding } from 'hooks/useBranding.js';
import { InputSelectDefault } from 'v2/components/Input/index.js';
import { InputLabel } from 'v2/components/Input/index.js';
import { InputContainer } from 'v2/components/Input/index.js';
import { listSection } from 'v2/repositories/SectionRepository.js';
import { InputText } from 'v2/components/Input/index.js';
import './styles.css';
import { FormSectionModal } from 'v2/views/client/Section/Form/FormSectionModal.jsx';
import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal/index.js';

class FormFamilia extends Component {
  state = {
    companyId: localStorage.getItem('ID_EMPRESA'),
    code: '',
    description: '',
    reviews: [],
    review: null,
    warranties: [],
    warranty: '',
    sections: [],
    selectedSection: {
      value: null,
      label: 'Selecione',
      departmentId: null,
      departmentDescription: '',
    },
    showAlertCompany: false,
    warrantyId: '',
    newFamily: true,
    familyId: '',
    breadcrumbDescription: '',
    typeFamilie: true,
    disabled: '',
    openModal: false,
    styleModal: {},
    modalTitle: '',
    formComponent: '',
    ativo: true,
    disabledBtn: false,
    loading: false,
    empresaCadastro: 0,
    disabledInput: '',
    isFormSectionModalOpen: false,
    isPlanPrimeWithoutPlansFree:
      localStorage.getItem('isPlanPrimeWithoutPlansFree') === 'true'
        ? true
        : false,
    isOpenFeatureNotAvailableModal: false,
  };

  componentDidMount() {
    this.descriptionInput.focus();
    this.checkCompanyId(localStorage.getItem('ID_USUARIO'));
    this.loadReviews();
    this.loadWarranties();
    this.loadSections();
  }

  checkCompanyId = async (User_id) => {
    var _this = this;
    const res = await api.get(config.endpoint + `companyuser/${User_id}`);
    if (res.data.status) {
      _this.setState({
        showAlertCompany: res.data.data.Company_id ? false : true,
        companyId: res.data.data.Company_id,
      });
    } else {
      setTimeout(
        () => _this.setState({ showAlertCompany: true, code: 1 }),
        500
      );
    }

    if (!this.props.modal) {
      this.props.newFamily ? this.loadLastCode() : this.loadFamilia();
    } else {
      this.loadLastCode();
    }
  };

  loadFamilia = async () => {
    await api
      .get(
        config.endpoint +
          `families/search/ById/${this.state.companyId}/${this.props.FamilyId}`
      )
      .then(async (res) => {
        const {
          Code,
          Description,
          id,
          Warranty_id,
          Review_id,
          Type,
          IsActive,
          Company_id,
          sectionId,
        } = res.data.data;

        const section = res.data.data.Section;

        let familiaOs = await api.get(config.endpoint + `families/defaults`);
        for (let j = 0; j < familiaOs.data.length; j++) {
          if (
            Description === familiaOs.data[j].Description &&
            Type == familiaOs.data[j].Type
          ) {
            await this.setState({ disabledInput: 'disabled' });
          }
        }
        if (res.data.status) {
          await this.setState({
            code: Code,
            description: Description,
            newFamily: false,
            familyId: id,
            warranty: Warranty_id,
            review: Review_id,
            typeFamilie: Type === 'Produto' ? true : false,
            breadcrumbDescription: Description,
            disabled: 'disabled',
            ativo: IsActive === 1,
            empresaCadastro: Company_id,
            selectedSection: {
              value: sectionId || null,
              label: res.data && section ? section.description : 'Selecione',
              departmentId: res.data && section ? section.departmentId : null,
              departmentDescription:
                res.data && section ? section.Department.description : '',
            },
          });
        } else window.location.href = '/client/Familia';
      });
  };

  loadReviews = async () => {
    const _this = this;
    await api
      .get(config.endpoint + `review?Company_id=${this.state.companyId}`)
      .then((resp) => {
        _this.setState({ reviews: resp.data });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  loadWarranties = async () => {
    const _this = this;
    await api
      .get(
        config.endpoint + `warranty/search/ByCompany/${this.state.companyId}`
      )
      .then((resp) => {
        if (resp.data.status) {
          _this.setState({ warranties: resp.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  loadLastCode = async () => {
    var _this = this;

    if (this.props.modal) {
      switch (this.props.Calling) {
        case 'Cadastro Produto':
          this.setState({ typeFamilie: true, disabled: 'disabled' });
          break;
        case 'Cadastro Serviço':
          this.setState({ typeFamilie: false, disabled: 'disabled' });
          break;
        default:
          return;
      }
    }

    if (this.state.typeFamilie == true) {
      let Type = 'Produto';
      await api
        .get(
          config.endpoint + `families/LastOne/${this.state.companyId}/${Type}`
        )

        .then((resp) => {
          if (resp.data.status) {
            _this.setState({ code: parseInt(resp.data.data) + 1 });
          } else {
            _this.setState({ code: 1 });
          }
        });
    } else {
      let Type = 'Servico';
      await api
        .get(
          config.endpoint + `families/LastOne/${this.state.companyId}/${Type}`
        )

        .then((resp) => {
          if (resp.data.status) {
            _this.setState({ code: parseInt(resp.data.data) + 1 });
          } else {
            _this.setState({ code: 1 });
          }
        });
    }
  };

  loadSections = async () => {
    try {
      const _this = this;
      const sections = await listSection({
        companyId: this.state.companyId,
        isActive: true,
        limit: 1000,
      });

      const serializedSections = sections.rows.map((section) => ({
        value: section.id,
        label: section.description,
        departmentId: section.departmentId,
        departmentDescription: section.Department.description,
      }));

      _this.setState({ sections: serializedSections });
    } catch (e) {
      console.log(e);
    }
  };

  handleChange(event) {
    event.preventDefault();
    if (event.target.name === 'description') {
      const value = description(event.target.value);
      this.setState({ [event.target.name]: value.toUpperCase() });
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.setState({
      disabledBtn: !this.state.disabledBtn,
      loading: !this.state.loading,
    });
    const self = this.state;
    var _this = this;
    let stopMethod = 0;
    if (self.description === '') {
      await this.setState({
        disabledBtn: !this.state.disabledBtn,
        loading: !this.state.loading,
      });

      return toastr.warning('Por favor, informe todos os dados para salvar');
    } else if (self.description.length <= 3) {
      await this.setState({
        disabledBtn: !this.state.disabledBtn,
        loading: !this.state.loading,
      });
      return toastr.warning(
        'Por favor, informe a descrição correta da familia.'
      );
    }

    if (self.newFamily) {
      let type = self.typeFamilie == true ? 'Produto' : 'Servico';
      await api
        .post(config.endpoint + `families/search/${self.companyId}`, {
          Description: self.description.trim(),
          Type: type,
        })
        .then(async (resp) => {
          if (resp.data.status) {
            stopMethod = 1;
            await this.setState({
              disabledBtn: !this.state.disabledBtn,
              loading: !this.state.loading,
            });
            return toastr.warning('Família já existente');
          }
        });

      if (stopMethod != 0) return;

      let garantiaOsDigital = await api.get(
        config.endpoint + `families/defaults`
      );
      for (let j = 0; j < garantiaOsDigital.data.length; j++) {
        if (
          self.description.trim() === garantiaOsDigital.data[j].Description &&
          type == garantiaOsDigital.data[j].Type
        ) {
          await this.setState({
            disabledBtn: !this.state.disabledBtn,
            loading: !this.state.loading,
          });
          return toastr.warning('Família já existente');
        }
      }

      if (stopMethod != 0) return;
      this.loadLastCode();

      await api
        .post(config.endpoint + `families/${self.companyId}`, {
          Description: self.description.toUpperCase(),
          Code: self.code,
          Review_id: self.review == '' ? null : self.review,
          Warranty_id: self.warranty == '' ? null : self.warranty,
          Type: self.typeFamilie == true ? 'Produto' : 'Servico',
          IsActive: self.ativo ? 1 : 0,
          sectionId: self.selectedSection?.value,
        })
        .then(async (resp) => {
          if (resp.data.status) {
            toastr.success('Sucesso', `Família cadastrada com sucesso`);
            if (!_this.props.modal) {
              _this.setState({
                code: '',
                description: '',
                review: '',
                months: '',
                warranty: '',
                ativo: true,
                loading: false,
                disabledBtn: false,
              });
              _this.loadLastCode();
              _this.descriptionInput.focus();
            } else {
              switch (_this.props.Calling) {
                case 'Cadastro Produto':
                  _this.props.dispatch(
                    change('formProduto', 'Family', resp.data.data.id)
                  );
                  _this.props.handleModal('families');
                  return;

                case 'Cadastro Serviço':
                  _this.props.handleModal('familia', resp.data.data.id);
                  return;

                default:
                  return;
              }
            }
            return _this.props.history.push(constants.ROUTES.FAMILIES);
          } else {
            await this.setState({
              disabledBtn: !this.state.disabledBtn,
              loading: !this.state.loading,
            });
            if (
              resp.data.message ===
              'Familia não cadastrada, pois está na blacklist'
            ) {
              toastr.error(
                `Não é possível fazer o cadastro com essa descrição.`,
                'A descrição que você informou prejudica a organização estratégica da sua empresa.'
              );
              this.descriptionInput.focus();
              return;
            }
            return toastr.error(
              'Ocorreu algum problema',
              'Por favor, tente novamente'
            );
          }
        })
        .catch(async (e) => {
          console.log(e.message);
          await this.setState({
            disabledBtn: !this.state.disabledBtn,
            loading: !this.state.loading,
          });
          return toastr.error(
            'Ocorreu algum problema',
            'Por favor, tente novamente'
          );
        });
    } else {
      let type = self.typeFamilie == true ? 'Produto' : 'Servico';
      await api
        .post(config.endpoint + `families/search/${self.companyId}`, {
          Description: self.description.trim(),
          Type: type,
        })
        .then(async (resp) => {
          if (resp.data.status) {
            if (resp.data.data[0].id !== self.familyId) {
              stopMethod = 1;
              await this.setState({
                disabledBtn: !this.state.disabledBtn,
                loading: !this.state.loading,
              });
              return toastr.warning('Família já existente');
            }
          }
        });

      if (stopMethod != 0) return;

      if (!self.typeFamilie) {
        let garantiaOsDigital = await api.get(
          config.endpoint + `families/defaults`
        );
        for (let j = 0; j < garantiaOsDigital.data.length; j++) {
          if (
            self.description.trim() === garantiaOsDigital.data[j].Description &&
            type == garantiaOsDigital.data[j].Type &&
            self.code != garantiaOsDigital.data[j].Code &&
            self.familyId === garantiaOsDigital.data[j].id
          ) {
            await this.setState({
              disabledBtn: !this.state.disabledBtn,
              loading: !this.state.loading,
            });
            return toastr.warning('Família já existente');
          }
        }
      }
      if (self.empresaCadastro == null) {
        await api
          .post(config.endpoint + `families/${self.companyId}`, {
            Description: self.description.toUpperCase(),
            Code: self.code,
            Review_id: self.review == '' ? null : self.review,
            Warranty_id: self.warranty == '' ? null : self.warranty,
            Type: self.typeFamilie == true ? 'Produto' : 'Servico',
            IsActive: self.ativo ? 1 : 0,
            sectionId: self.selectedSection?.value,
          })
          .then(async (resp) => {
            if (resp.data.status) {
              _this.props.history.push(constants.ROUTES.FAMILIES);
              toastr.success('Sucesso', `Família atualizada com sucesso`);
            } else {
              await this.setState({
                disabledBtn: !this.state.disabledBtn,
                loading: !this.state.loading,
              });
              return toastr.error(
                'Ocorreu algum problema',
                'Por favor, tente novamente'
              );
            }
          })
          .catch(async (e) => {
            console.log(e.message);
            await this.setState({
              disabledBtn: !this.state.disabledBtn,
              loading: !this.state.loading,
            });
            return toastr.error(
              'Ocorreu algum problema',
              'Por favor, tente novamente'
            );
          });
      } else {
        await api
          .put(
            config.endpoint + `families/${self.familyId}/${self.companyId}`,
            {
              Description: self.description.toUpperCase(),
              Code: self.code,
              Review_id: self.review,
              Warranty_id: self.warranty,
              Type: self.typeFamilie == true ? 'Produto' : 'Servico',
              IsActive: self.ativo ? 1 : 0,
              sectionId: self.selectedSection?.value,
            }
          )
          .then(async (response) => {
            await _this.setState({
              disabled: !_this.state.disabled,
              loadingSpin: !_this.state.loadingSpin,
            });
            if (
              response.data.message ===
              'Familia não cadastrada, pois está na blacklist'
            ) {
              toastr.error(
                `Não é possível fazer o cadastro com essa descrição.`,
                'A descrição que você informou prejudica a organização estratégica da sua empresa.'
              );
              await _this.setState({
                disabled: !_this.state.disabled,
                loadingSpin: !_this.state.loadingSpin,
              });
              this.descriptionInput.focus();
              return;
            }
            if (response.status) {
              await this.setState({
                disabledBtn: !this.state.disabledBtn,
                loading: !this.state.loading,
              });

              _this.props.history.push(constants.ROUTES.FAMILIES);
              return toastr.success('Sucesso', `Familia atualizada!`);
            } else
              await this.setState({
                disabledBtn: !this.state.disabledBtn,
                loading: !this.state.loading,
              });
            return toastr.error(
              'Erro',
              'Ocorreu um erro durante a atualização. Por favor, repita o procedimento'
            );
          });
      }
    }
  };

  async changeType(e) {
    if (!this.props.readOnly) {
      await this.setState({ typeFamilie: !this.state.typeFamilie });
      this.loadLastCode();
    }
  }

  handleNewSection = async (section) => {
    await this.loadSections();
    this.setState({
      selectedSection: {
        value: section.id,
        label: section.description,
        departmentId: section.departmentId,
        departmentDescription: section.departmentDescription,
      },
    });
  };

  handleModal(field, id) {
    this.loadReviews();
    this.loadWarranties();
    this.setState({ openModal: false, [field]: id });
  }

  renderForm(Form) {
    return (
      <Form
        modal={true}
        Calling="Cadastro Familia"
        handleModal={(field, id) => this.handleModal(field, id)}
        readOnly={true}
      />
    );
  }

  render() {
    const {
      code,
      description,
      reviews,
      review,
      warranty,
      warranties,
      sections,
      selectedSection,
      typeFamilie,
      disabled,
      openModal,
      styleModal,
      modalTitle,
      formComponent,
      ativo,
      isFormSectionModalOpen,
    } = this.state;

    const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

    return (
      <>
        <Card
          content={
            <form>
              <div
                style={{ marginTop: '15px' }}
                className="cardComponent row bg-primary-border-color"
              >
                <div className="div-card">
                  <Button
                    id="btn-components"
                    className="bg-primary"
                    onClick={(e) => e.preventDefault()}
                  >
                    <a id="btn-a">Dados da Família</a>
                  </Button>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body">
                  <Col xs={12}>
                    <Col
                      xs={6}
                      sm={3}
                      md={2}
                      lg={2}
                      style={{ padding: '0px', marginRight: '15px' }}
                    >
                      <div className="custom-control custom-radio custom-control-inline">
                        <label>Tipo de Família</label>
                        <select
                          value={typeFamilie ? 'Produto' : 'Serviço'}
                          className="form-control foco-input"
                          disabled={disabled}
                          name="Type"
                          onChange={(e) => this.changeType(e)}
                        >
                          <option value="Produto">Produto</option>
                          <option value="Serviço">Serviço</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <label>Status</label>
                      <div>
                        <Toggle
                          checked={ativo}
                          onChange={() => this.setState({ ativo: !ativo })}
                        />
                      </div>
                    </Col>
                  </Col>
                  <Col xs={12} sm={3} md={3} lg={2}>
                    <label>Código</label>
                    <input
                      disabled={true}
                      onChange={(event) => event.preventDefault()}
                      style={{ textAlign: 'right' }}
                      className="form-control foco-input"
                      name="code"
                      value={code}
                    />
                  </Col>
                  <Col xs={12} sm={9} md={9} lg={4}>
                    <label>
                      Descrição<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      disabled={
                        this.state.disabledInput == 'disabled' ? true : false
                      }
                      ref={(input) => {
                        this.descriptionInput = input;
                      }}
                      maxLength="40"
                      className="form-control foco-input"
                      onChange={(event) => {
                        if (event.target.value.startsWith(' ')) {
                          event.preventDefault();
                          event.target.value = event.target.value.slice(1);
                        }
                        this.handleChange(event);
                      }}
                      name="description"
                      value={description}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={3}>
                    <label>Garantia</label>
                    <div className="div-a-modal">
                      <a
                        onClick={() =>
                          this.setState({
                            formComponent: FormGarantia,
                            openModal: true,
                            styleModal: {
                              marginBottom: '40px',
                              marginLeft: '5px',
                              height: '240px',
                              marginTop: 0,
                            },
                            modalTitle: 'Nova Garantia',
                          })
                        }
                      >
                        Adicionar Garantia
                      </a>
                    </div>
                    <select
                      maxLength="40"
                      className="form-control foco-input"
                      onChange={(event) => this.handleChange(event)}
                      name="warranty"
                      value={warranty}
                    >
                      <option key="" value="">
                        Selecione
                      </option>
                      {warranties.map((r) => {
                        return (
                          <option key={r.id} value={r.id}>
                            {r.Description}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    className={typeFamilie ? '' : 'hidden'}
                  >
                    <label>Revisão</label>
                    <div className="div-a-modal">
                      <a
                        onClick={() =>
                          this.setState({
                            formComponent: FormRevisao,
                            openModal: true,
                            styleModal: {
                              marginBottom: isFirefox ? '185px' : '170px',
                              marginLeft: '5px',
                              marginTop: 0,
                            },
                            modalTitle: 'Nova Revisão',
                          })
                        }
                      >
                        Adicionar Revisão
                      </a>
                    </div>
                    <select
                      maxLength="40"
                      className="form-control foco-input"
                      onChange={(event) => this.handleChange(event)}
                      name="review"
                      value={review || ''}
                    >
                      <option key="" value="">
                        Selecione
                      </option>
                      {reviews.map((r) => {
                        return (
                          <option key={r.id} value={r.id}>
                            {r.Description}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body">
                  <Col xs={12}>
                    <Col
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      style={{ padding: '0px', marginRight: '15px' }}
                    >
                      <div
                        className="flex end new-section__hyperlink"
                        style={{
                          marginBottom: '-20px',
                          width: '100%',
                        }}
                      >
                        <a
                          onClick={() => {
                            this.state.isPlanPrimeWithoutPlansFree
                              ? this.setState({
                                  isFormSectionModalOpen: true,
                                })
                              : this.setState({
                                  isOpenFeatureNotAvailableModal: true,
                                });
                          }}
                        >
                          Adicionar Seção
                        </a>
                      </div>
                      <InputContainer variant="column">
                        <InputLabel htmlFor="input__section">Seção:</InputLabel>
                        <InputSelectDefault
                          id="input__section"
                          options={sections}
                          value={selectedSection}
                          width={'100%'}
                          height="35px"
                          onChange={(e) => {
                            this.setState({ selectedSection: e });
                          }}
                        />
                      </InputContainer>
                    </Col>
                    <Col
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      style={{ padding: '0px', marginRight: '15px' }}
                    >
                      <InputContainer variant="column">
                        <InputLabel htmlFor="input__section">
                          Departamento:
                        </InputLabel>
                        <InputText
                          id="input__section"
                          value={selectedSection?.departmentDescription}
                          disabled
                          width="350px"
                          height="35px"
                        />
                      </InputContainer>
                    </Col>
                  </Col>
                </div>
              </div>
              <Button
                disabled={this.state.disabledBtn}
                onClick={(event) => this.handleSubmit(event)}
                type="button"
                bsStyle="info"
                pullRight
                fill
              >
                <span
                  className={
                    this.state.loading ? 'fa fa-spinner fa-pulse fa-1x' : ''
                  }
                  style={{ marginRight: '5px' }}
                ></span>
                {this.state.newFamily ? 'Salvar' : 'Atualizar'}
              </Button>
              <Button
                style={{ marginRight: '5px', marginTop: '0px' }}
                disabled={this.state.disabledBtn}
                onClick={() =>
                  this.props.modal
                    ? this.props.handleModal('families')
                    : this.props.history.push(constants.ROUTES.FAMILIES)
                }
                type="button"
                bsStyle="danger"
                pullRight
                fill
              >
                Cancelar
              </Button>

              <Modal
                dialogClassName="modal-formProduto"
                show={openModal}
                onHide={() => this.setState({ openModal: false })}
                animation={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <strong>{modalTitle}</strong>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body id="modal-formProduto-body" style={styleModal}>
                  {this.renderForm(formComponent)}
                  <span id="required-field-label">* Campos Obrigatórios</span>
                </Modal.Body>
              </Modal>

              {isFormSectionModalOpen && (
                <FormSectionModal
                  isOpen={isFormSectionModalOpen}
                  handleClose={() => {
                    this.setState({ isFormSectionModalOpen: false });
                  }}
                  afterSubmit={this.handleNewSection}
                />
              )}
            </form>
          }
        />
        <UnavailableFeatureModal
          show={this.state.isOpenFeatureNotAvailableModal}
          onHide={() => {
            this.setState({ isOpenFeatureNotAvailableModal: false });
          }}
        />
      </>
    );
  }
}

export default withRouter(FormFamilia);
