import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import MainData from './MainData';
import AddressData from './AddressData';
import UsersData from './UsersData';
import './styles.css';
import CardForm from 'client/components/CardForm';
import AlertModal from 'components/AlertModal/AlertModal.js';

let MeusDados = ({
  handleSubmit,
  loadCompany,
  history,
  initialize,
  loading,
  Type,
  typeOriginal,
  dispatch,
  previousSuperLogicaId,
  setPreviousSuperLogicaId,
  addressInformation,
}) => {
  const [companyData, setCompanyData] = useState(true);
  const [companyAddressData, setCompanyAddressData] = useState(false);
  const [users, setUsers] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);

  return (
    <div>
      <form
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ marginTop: '20px' }}
      >
        <CardForm
          show={companyData}
          title="Dados da Empresa"
          onClick={() => setCompanyData(!companyData)}
        >
          <MainData
            initialize={initialize}
            loadCompany={loadCompany}
            dispatch={dispatch}
            previousSuperLogicaId={previousSuperLogicaId}
            setPreviousSuperLogicaId={setPreviousSuperLogicaId}
          />
        </CardForm>

        <CardForm
          show={users}
          title="Usuários"
          onClick={() => setUsers(!users)}
        >
          <UsersData />
        </CardForm>

        <CardForm
          show={companyAddressData}
          title="Endereço"
          onClick={() => setCompanyAddressData(!companyAddressData)}
        >
          <AddressData addressInformation={addressInformation} />
        </CardForm>
      </form>

      {/* MODAL PARA CONFIRMAÇÃO DE PESSOA JURÍDICA */}
      <AlertModal
        show={modalConfirmation}
        animation={false}
        message="Ao cadastrar a empresa com CNPJ, não poderá modificar para CPF futuramente. Deseja Continuar?"
        onHide={() => setModalConfirmation(false)}
        onCancel={() => setModalConfirmation(false)}
        onSubmit={() => handleSubmit()}
      />
    </div>
  );
};

MeusDados = reduxForm({
  form: 'meusDados',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MeusDados);
const selector = formValueSelector('meusDados');
const mapStateToProps = (state) => ({
  meusDados: selector(state, 'meusDados'),
  Type: selector(state, 'Type'),
  typeOriginal: selector(state, 'typeOriginal'),
});

export default connect(mapStateToProps)(withRouter(MeusDados));
