import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import suivCompanyRepository from 'repositories/SuivCompany'

import { useAuth } from 'contexts/auth'

import SuivForm from './SuivForm'

const Suiv = () => {
  const [suivByCompany, setSuivByCompany] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const { companyId } = useAuth()

  useEffect(() => {
    loadSuivInformation()
  }, [])

  const loadSuivInformation = async () => {
    try {
      const { data: suivCompany } = await suivCompanyRepository.showCompany(
        companyId
      )

      setSuivByCompany(suivCompany)
      dispatch(initialize('suiv', suivCompany))
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit = async (values) => {


    const { suivToken } = values

    if (!suivToken) {
      return toastr.warning('Preencha o campo token com um token válido.')
    }

    const formattedToken = suivToken.replace(/\s+/g, '')
    
    if(suivToken !== formattedToken){
      return toastr.warning('Preencha o campo token com um token válido.')
    }

    setLoading(true)

    if (suivByCompany) return update(values)
    create(values)
  }

  const create = async (values) => {
    const { suivToken } = values

    try {
      const {
        data: { suivCompany },
      } = await suivCompanyRepository.create({
        suivToken,
        companyId,
      })


      toastr.success('Integração SUIV salva com sucesso')

      setSuivByCompany(suivCompany)
    } catch (err) {
      toastr.error('Ocorreu um erro ao salvar informações da SUIV')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const update = async (values) => {
    const { suivToken } = values

    try {
     await suivCompanyRepository.update(companyId, {
        suivToken,
        companyId,
      })

      toastr.success('Integração SUIV atualizada com sucesso')

      loadSuivInformation()
    } catch (err) {
      toastr.error('Ocorreu um erro ao atualizar informações da SUIV')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }



  return <SuivForm onSubmit={handleSubmit} loading={loading} />
}

export default Suiv
