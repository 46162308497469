import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import CurrencyInput from 'react-currency-input'

import CollapsibleSection from 'components/CollapsibleSection'
import Button from 'client/components/CustomButton/CustomButton'

export default function NewVoucherPaymentForm({
  employeesOptions,
  cashierBanksOptions,
  handleCancelConfirmation,
  handleValidateSubmit,
  loading,
}) {
  const { register, handleSubmit, control } = useFormContext()

  const paymentStatus = useWatch({
    control,
    name: 'status',
  })

  return (
    <div className="new-voucher-container">
      <CollapsibleSection title="Movimentação de Saldo" isExpanded={true}>
        <form method="POST">
          <div className="form-row">
            <div style={{ width: '100px' }}>
              <label htmlFor="code">Código:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="code"
                disabled
                {...register('code')}
              />
            </div>
            <div>
              <label htmlFor="createdAt">Data de Criação:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="createdAt"
                disabled
                {...register('createdAt')}
              />
            </div>
            <div>
              <label htmlFor="closingDate">Data de Fechamento:</label>
              <input
                type="text"
                className="form-control foco-input"
                id="closingDate"
                disabled
                {...register('closingDate')}
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{ width: '350px' }}>
              <label htmlFor="employee">
                Funcionário:
                <span className="required">*</span>
              </label>
              <select
                id="employee"
                defaultValue=""
                className="form-control foco-input"
                disabled={
                  paymentStatus === 'Finalizada' ||
                    paymentStatus === 'Cancelada'
                    ? true
                    : false
                }
                {...register('employeeId')}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {employeesOptions?.map((employeeOpt) => (
                  <option key={employeeOpt.value} value={employeeOpt.value}>
                    {employeeOpt.label}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: '250px' }}>
              <label htmlFor="cashierBank">
                Banco/Caixa:
                <span className="required">*</span>
              </label>
              <select
                id="cashierBank"
                className="form-control foco-input"
                defaultValue=""
                {...register('cashierBankId')}
                disabled={
                  paymentStatus === 'Finalizada' ||
                    paymentStatus === 'Cancelada'
                    ? true
                    : false
                }
              >
                <option value="" disabled>
                  Selecione
                </option>
                {cashierBanksOptions?.map((cashierBankOpt) => (
                  <option
                    key={cashierBankOpt.value}
                    value={cashierBankOpt.value}
                  >
                    {cashierBankOpt.label}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: '150px' }}>
              <label htmlFor="value">
                Valor (R$):
                <span className="required">*</span>
              </label>
              <Controller
                name={`value`}
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    id="value"
                    thousandSeparator="."
                    decimalSeparator=","
                    className="form-control foco-input"
                    disabled={
                      paymentStatus === 'Finalizada' ||
                        paymentStatus === 'Cancelada'
                        ? true
                        : false
                    }
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="form-row">
            <div style={{ flex: 1 }}>
              <label htmlFor="observations">Observações:</label>
              <textarea
                id="observations"
                maxLength={500}
                className="form-control foco-input"
                {...register('observations')}
                disabled={
                  paymentStatus === 'Finalizada' ||
                    paymentStatus === 'Cancelada'
                    ? true
                    : false
                }
              ></textarea>
            </div>
          </div>
        </form>
      </CollapsibleSection>
      <div className="voucher-submit-buttons-container">
        <Button
          bsStyle="danger"
          onClick={handleCancelConfirmation}
          fill
          disabled={loading}
        >
          {paymentStatus === 'Finalizada' || paymentStatus === 'Cancelada'
            ? 'Voltar'
            : 'Cancelar'}
        </Button>
        {paymentStatus !== 'Finalizada' && paymentStatus !== 'Cancelada' && (
          <Button
            bsStyle="info"
            onClick={handleSubmit(handleValidateSubmit)}
            fill
            disabled={loading}
          >
            Salvar
          </Button>
        )}
      </div>
    </div>
  )
}
