import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import RenderField from '../../../../../components/RenderField'
import cashierBankRepository from '../../../../../repositories/CashierBank'
import { onlyNumbersAndTrace, phoneMask, onlyNumbersLetters } from '../../../../components/ToNormalize/ToNormalize'

const BankFields = () => {
  const [banks, setBanks] = useState([])
  const [showAgencyLegend, setShowAgencyLegend] = useState(false)
  const [showAccountLegend, setShowAccountLegend] = useState(false)

  useEffect(() => {
    cashierBankRepository.getBanks().then(resp => setBanks(resp))
  }, [])

  return (
    <>
      <Col xs={12} sm={6} md={2} lg={2}>
        <Field
          label="Banco:"
          name="bankId"
          component={RenderField}
          as="select"
          required
        >
          <option value="" >Selecione</option>
          {banks.map(({ id, code, name }) => (
            <option key={id} value={id} >{code} - {name}</option>
          ))}
        </Field>
      </Col>
      <Col xs={12} sm={6} md={2} lg={2}>
        <Field
          label="Agência:"
          name="agency"
          component={RenderField}
          required
          normalize={onlyNumbersAndTrace}
          onFocus={() => setShowAgencyLegend(true)}
          onBlur={() => setShowAgencyLegend(false)}
        />
        {showAgencyLegend && <span id="legend-agency">Troque X por 0, se necessário</span>}
      </Col>
      <Col xs={12} sm={6} md={2} lg={2}>
        <Field
          label="Conta Corrente:"
          name="checkingAccount"
          component={RenderField}
          required
          normalize={onlyNumbersAndTrace}
          onFocus={() => setShowAccountLegend(true)}
          onBlur={() => setShowAccountLegend(false)}
        />
        {showAccountLegend && <span id="legend-agency">Troque X por 0, se necessário</span>}
      </Col>
      <Col xs={12} sm={6} md={2} lg={2}>
        <Field
          label="Telefone:"
          name="phoneNumber"
          component={RenderField}
          normalize={phoneMask}
        />
      </Col>
      <Col xs={12} sm={6} md={2} lg={2}>
        <Field
          label="Gerente ou Responsável:"
          name="manager"
          component={RenderField}
          normalize={onlyNumbersLetters}
          maxLength="15"
        />
      </Col>
    </>
  )
}

export default BankFields