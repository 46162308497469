import React, { memo } from 'react';
import Chart from 'react-google-charts';

const ChartByFormOfPayment = ({ data }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
        Recebimentos por Forma de Pagamento (%)
      </span>
      <div
        className="chart-pagamento"
        style={{
          opacity: '100%',
        }}
      >
        <Chart
          width={'550px'}
          height={'300px'}
          chartType="PieChart"
          data={[
            [
              'Forma de Pagamento',
              'Valor',
              { role: 'tooltip', type: 'string' },
            ],
            ...data,
          ]}
          chartLanguage="pt-br"
          options={{
            chartArea: {
              width: '75%',
              left: 0,
            },
            legend: {
              width: 200,
            },
          }}
        />
      </div>
    </div>
  );
};

export default memo(ChartByFormOfPayment);
