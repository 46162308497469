import React from 'react'
import { withRouter } from 'react-router'
import constants from "../../../../utils/constants";
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NewsRegisterMain from './components/NewsRegisterMain';

const NewsRegister = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'News',
          match.params.id ? 'Editar Notícia' : 'Nova Notícia',
        ]}
        path={['home', 'news', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <NewsRegisterMain
        newsId={match.params.id}
        onCancel={() => history.push('/admin/news' )}
        onSubmit={() => history.push('/admin/news')}
      />
    </>
  )
}

export default withRouter(NewsRegister)
