import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import AppError from 'components/AppError'

import { AppLoading } from 'client/routes/index.routes'

import InventoryMovementListingPDF from './InventoryMovementListingPDF'


import InventoryMovementRepository from '../../../repositories/InventoryMovement'

const ReturnDocumentPurchasesSupplier = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const InventoryMovementResponseIdHash = searchParams.get('companyId')
      let companyId

      if (InventoryMovementResponseIdHash) {
        companyId = await decrypt(InventoryMovementResponseIdHash, '@OS-dig:companyId')
      }

      if (!companyId) {
        setHasError(true)
        setLoading(false)
      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const returnStatus = searchParams.get('returnStatus')

      const dados = await loadReport(
        companyId,
        initialDate,
        finalDate,
        returnStatus)

      setDataReport(dados)
      setLoading(false)

    } catch (err) {
      setHasError(true)
      setLoading(false)

    }

  }

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    returnStatus) => {
    try {
      const data = await InventoryMovementRepository.generateInventoryReportMovement({
        companyId,
        initialDate,
        finalDate,
        returnStatus
      })
      console.log(data)
      return data.report
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />


  return (

    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}>
          <InventoryMovementListingPDF
            responseData={dataReport}
          />
        </PDFViewer>
      </main>
    </div>
  )

}




export default ReturnDocumentPurchasesSupplier 