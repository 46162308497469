import React from "react";
import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import './styles.css'

function SatisfactionSurvey({ratingProductService, ratingPrice, comments, wouldIndicate, SatisfactionSurveyId}) {
        return (
            <div className="survey-content" style={{ padding: '0px' }}>
                <label>Qual a sua avaliação sobre a qualidade dos produtos e serviços? </label>
                <Rating
                    readOnly 
                    value={ratingProductService || null}
                    name="Rating_Product_Service" 
                    size="large" 
                />
                    
                <label>Qual a sua avaliação sobre o preço? </label>
                <Rating 
                    readOnly 
                    value={ratingPrice || null}
                    name="Rating_Price" 
                    size="large" 
                />
                <label>Você indicaria o fornecedor para seus amigos? </label>
                <section>
                        <div name="Sim"  style={{ backgroundColor: '#02b102', display: SatisfactionSurveyId ? wouldIndicate ? '' : 'none' : ''}} >
                            <FontAwesomeIcon icon={faThumbsUp} aria-hidden="false" /> <span>Sim</span>
                        </div>
                        <div name="Não"  style={{ backgroundColor: '#ff4a55', marginLeft: 5, display: SatisfactionSurveyId ? wouldIndicate ? 'none' : '' : '' }} >
                            <FontAwesomeIcon icon={faThumbsDown} aria-hidden="false" /> <span>Não</span>
                        </div>
                </section>
                <label>Deixe um comentario</label>
                <textarea disabled={true} value={comments || ""} maxLength="500" className="form-control foco-input" name="Comment" />
            </div >
        )
}
export default SatisfactionSurvey