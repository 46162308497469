import React from 'react';
import { format } from 'date-fns';
import ReactTable from 'react-table';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const CustomerCreditTable = ({
  loading,
  customerCreditList,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
  setNewCustomerCredit,
  setCustomerCreditId,
}) => {
  function getBackgroundColorByType(type) {
    const backgroundColors = {
      Entrada: '#5cb85c',
      Saída: '#d9534f',
      Troco: '#428bca',
      Devolução: '#5bc0de',
      Manual: '#f0ad4e',
      Venda: '#5cb85c',
    };

    return backgroundColors[type];
  }

  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '12px',
        }}
        data={customerCreditList}
        columns={[
          {
            Header: 'Data',
            accessor: 'date',
            width: 120,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Lançamento',
            accessor: 'type',
            width: 160,
            Cell: (props) => {
              return (
                <button
                  className="admin-custom-label"
                  style={{
                    backgroundColor: getBackgroundColorByType(props.value),
                  }}
                >
                  {props.value}
                </button>
              );
            },
          },
          {
            Header: 'CPF/CNPJ',
            accessor: 'customerCpfCnpj',
            width: 170,
            Cell: (props) => cpfOrCnpjMask(props.value),
          },
          {
            Header: 'Nome/Nome Fantasia',
            accessor: 'customerName',
          },
          {
            Header: 'Motivo',
            accessor: 'reason',
            width: 150,
            Cell: (props) => {
              return (
                <button
                  className="admin-custom-label"
                  style={{
                    backgroundColor: getBackgroundColorByType(props.value),
                  }}
                >
                  {props.value}
                </button>
              );
            },
          },
          {
            Header: 'Valor',
            accessor: 'value',
            width: 150,
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 90,
            Cell: (props) => (
              <a title="Visualizar Lançamento">
                <FontAwesomeIcon
                  style={{ cursor: 'pointer', color: '#606060' }}
                  icon={faEye}
                  onClick={() => {
                    setNewCustomerCredit(true);
                    setCustomerCreditId(props.value);
                  }}
                />
              </a>
            ),
          },
        ]}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum crédito para cliente encontrado "
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default CustomerCreditTable;
