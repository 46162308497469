import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import AlertModal from 'client/components/PendingItemsModal/AlertModal';
import Button from 'client/components/CustomButton/CustomButton';

import api from 'services/api';
import config from 'config/index.js';

import PartApplicationRepository from 'repositories/PartApplications';

import PartApplicationFilterForm from './PartApplicationFilterForm';
import PartApplicationTable from './PartApplicationTable';

import './styles.css';

const AddPartApplicationModal = ({
  show,
  setShow,
  productInfo,
  loadPartApplication,
  brandsOptions,
  appliedVehicles,
}) => {
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [filteredVehiclesFipe, setFilteredVehiclesFipe] = useState([]);

  const [loading, setLoading] = useState(false);

  const [allVehiclesFipeSelected, setAllVehiclesFipeSelected] = useState(false);
  const [vehiclesFipe, setVehiclesFipe] = useState([]);

  useEffect(() => {
    loadVehicleFipe();
  }, []);

  useEffect(() => {
    const selectedPartApplicationLength = vehiclesFipe.filter(
      (vehicle) => vehicle.selected
    ).length;
    if (selectedPartApplicationLength !== vehiclesFipe.length) {
      setAllVehiclesFipeSelected(false);
      return;
    }

    setAllVehiclesFipeSelected(true);
  }, [vehiclesFipe]);

  function handleSelectAll() {
    const serializedVehiclesFipe = vehiclesFipe.map((vehicle) => {
      const isSelected = filteredVehiclesFipe.some(
        (filteredVehicle) => filteredVehicle.id === vehicle.id
      );
      return {
        ...vehicle,
        selected: isSelected,
      };
    });
    setVehiclesFipe(serializedVehiclesFipe);
    setAllVehiclesFipeSelected(true);
  }

  function handleUnselectAll() {
    const serializedVehiclesFipe = vehiclesFipe.map((vehicle) => {
      return {
        ...vehicle,
        selected: false,
      };
    });
    setVehiclesFipe(serializedVehiclesFipe);
    setAllVehiclesFipeSelected(false);
  }

  function handleSelectedPartApplication(selectedPartApplication) {
    const vehiclesCopy = [...vehiclesFipe];
    const vehiclesIndex = vehiclesCopy.findIndex(
      (vehicle) => vehicle.id === selectedPartApplication.id
    );
    vehiclesCopy[vehiclesIndex].selected =
      !vehiclesCopy[vehiclesIndex].selected;
    setVehiclesFipe(vehiclesCopy);
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const vehiclesFipeToApply = vehiclesFipe.filter(
        (vehicle) => vehicle.selected
      );

      if (vehiclesFipeToApply.length === 0) {
        return toastr.warning(
          'Selecione ao menos 1 veículo para salvar a aplicação'
        );
      }

      const isValidYears = await validateYears(vehiclesFipeToApply);

      if (isValidYears === false) {
        return;
      }

      const partApplicationsToSend = vehiclesFipeToApply.map((vehicle) => {
        return {
          vehicleId: vehicle.id,
          initialYear: vehicle.initialYear,
          finalYear: vehicle.finalYear,
        };
      });

      await PartApplicationRepository.create({
        vehicleInfo: partApplicationsToSend,
        productId: productInfo.id,
      });

      await loadPartApplication();
      toastr.success('Aplicação de peça adicionada com sucesso!');

      setShow(false);
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu um erro ao realizar a aplicação de peças. Tente novamente!'
      );
    } finally {
      setLoading(false);
    }
  }

  async function validateYears(vehiclesFipeToApply) {
    const invalidYears = vehiclesFipeToApply.some((vehicle) => {
      if (
        vehicle.initialYear?.length === 0 ||
        vehicle.finalYear?.length === 0 ||
        (vehicle.initialYear?.length > 0 &&
          vehicle.initialYear?.length !== 4) ||
        (vehicle.finalYear?.length > 0 && vehicle.finalYear?.length !== 4)
      ) {
        return true;
      }
    });

    if (invalidYears) {
      toastr.warning(
        'Não foi possível adicionar o veículo.',
        'Verifique os anos dos veículos selecionados'
      );
      return false;
    }
  }

  async function loadVehicleFipe() {
    try {
      setLoading(true);
      const { data: vehiclesFipe } = await api.get(
        config.endpoint + `tabelaFipe`
      );

      const appliedVehiclesIds = appliedVehicles.map((vehicle) => vehicle.id);

      const filteredVehiclesFipe = vehiclesFipe.filter((vehicleFipe) => {
        return (
          !appliedVehiclesIds.includes(vehicleFipe.id) ||
          !!appliedVehiclesIds.includes(vehicleFipe.id)
        );
      });

      const serializedVehiclesFipe = filteredVehiclesFipe.map((vehicle) => {
        return {
          ...vehicle,
          selected: false,
        };
      });

      setVehiclesFipe(serializedVehiclesFipe);
    } catch (err) {
      setLoading(false);
      return toastr.warning(
        'Ocorreu um erro ao carregar os carros registrados na tabela FIPE'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleVerifySelectedPartApplication() {
    const vehiclesFipeToApply = vehiclesFipe.filter(
      (vehicle) => vehicle.selected
    );
    if (!!vehiclesFipeToApply.length) {
      setIsVerifyModalOpen(true);
    } else {
      setShow(false);
    }
  }

  return (
    <Modal
      dialogClassName="part-application-modal-wrapper"
      show={show}
      onHide={() => handleVerifySelectedPartApplication()}
      animation
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Adicionar Aplicação</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PartApplicationFilterForm
          brandsOptions={brandsOptions}
          vehiclesFipe={vehiclesFipe}
          setFilteredVehiclesFipe={setFilteredVehiclesFipe}
        />

        <div
          className="select-all-products hyperlink"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <a
            href="#"
            onClick={
              !vehiclesFipe.filter((vehicle) => vehicle.selected === true)
                .length
                ? handleSelectAll
                : handleUnselectAll
            }
          >
            {vehiclesFipe.filter((vehicle) => vehicle.selected === true).length
              ? 'Remover Todos'
              : `Selecionar Todos (${filteredVehiclesFipe.length})`}
          </a>
        </div>
        <PartApplicationTable
          loading={loading}
          filteredVehiclesFipe={filteredVehiclesFipe}
          vehiclesFipe={vehiclesFipe}
          setVehiclesFipe={setVehiclesFipe}
          handleSelectedPartApplication={handleSelectedPartApplication}
        />

        <div className="modal-footer-buttons tray">
          <Button
            bsStyle="danger"
            pullRight
            fill
            disabled={loading ? 'disabled' : ''}
            onClick={() => handleVerifySelectedPartApplication()}
          >
            Voltar
          </Button>

          <Button
            bsStyle="info"
            pullRight
            fill
            onClick={() => handleSubmit()}
            disabled={loading ? 'disabled' : ''}
          >
            Salvar
          </Button>
        </div>
      </Modal.Body>

      {isVerifyModalOpen && (
        <AlertModal
          show={isVerifyModalOpen}
          onHide={() => setIsVerifyModalOpen(false)}
          onCancel={() => setIsVerifyModalOpen(false)}
          message={`Você tem certeza que deseja sair da aplicação do produto ? Ao sair, nenhuma aplicação será salva, porém, o processo poderá ser feito novamente mais tarde.`}
          onSubmit={() => setShow(false)}
        />
      )}
    </Modal>
  );
};

export default AddPartApplicationModal;
