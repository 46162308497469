import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { change, initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';

import inventoryRepository from 'repositories/InventoryMovement';
import AlertModal from 'components/AlertModal/AlertModal';
import { cnpjMask } from '../../../../components/ToNormalize/ToNormalize';
import Loader from '../../../../../components/Loader';
import Form from './FormNewStock';
import { getProductsUnderStock } from 'client/components/MinStockAlertButton/redux/actions';

import { useAuth } from 'contexts/auth';

const BundleFormNewStock = ({ onSubmit, onCancel, id }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [values, setValues] = useState([]);
  const [status, setStatus] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loadingStock, setLoadingStock] = useState(false);

  const { companyId } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      loadStock(id);
    } else {
      getLastCode();
    }
  }, [id]);

  async function loadStock(movementId) {
    setLoadingStock(true);
    try {
      const movement = await inventoryRepository.show(movementId);
      setDisabled(
        movement.status === 'Finalizada' || movement.status === 'Cancelada'
      );

      const stockObj = {
        movementId: movement.id,
        canceledDate: movement.canceledDate
          ? format(new Date(movement.canceledDate), 'dd/MM/yyyy')
          : null,
        code: movement.code,
        finishedDate: movement.finishedDate
          ? format(new Date(movement.finishedDate), 'dd/MM/yyyy')
          : null,
        movementType: movement.movementType,
        providerId: movement.providerId,
        registerDate: movement.registerDate
          ? format(new Date(movement.registerDate), 'dd/MM/yyyy')
          : null,
        status: movement.status,
        itemsStock: movement.InventoryMovementItems,
        cnpj: movement.Provider ? cnpjMask(movement.Provider.cpfCnpj) : null,
        name: movement.Provider?.companyName,
        city: movement.Provider?.Address[0]?.City,
        state: movement.Provider?.Address[0]?.State,
        searchProvider: '',
        observation: movement.observation,
      };

      dispatch(initialize('cadastroStock', stockObj));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar a movimentação. Por favor, tente novamente'
      );
    } finally {
      setLoadingStock(false);
    }
  }

  async function getLastCode() {
    const code = await inventoryRepository.getCode(companyId);
    dispatch(change('cadastroStock', 'code', code));
  }

  async function handleSubmit(values) {
    const { itemsStock } = values;

    if (itemsStock.length <= 0) {
      return toastr.warning('Adicione ao menos 1 item');
    }

    if (itemsStock.some((item) => item.quantity <= 0)) {
      return toastr.warning(
        'Existe um ou mais produtos com quantidade inválida. Verifique e tente novamente.'
      );
    }

    setShowModal(true);
    setValues(values);
  }

  function handleModal(status) {
    setStatus(status);
    setShowConfirmModal(true);
  }

  function confirmModal() {
    setShowModal(false);
    setShowConfirmModal(false);
    if (!!id) {
      update(values);
    } else {
      create(values);
    }
  }

  async function create(values) {
    const { itemsStock, providerId, code, movementType, observation } = values;

    try {
      setLoading(true);

      const isFinished = status === 'Finalizada';
      const movement = await inventoryRepository.create({
        movement: {
          movementType,
          code: code,
          registerDate: new Date(),
          finishedDate: isFinished ? new Date() : null,
          providerId,
          companyId,
          status: status,
        },
        observation,
        items: itemsStock,
      });

      if (isFinished) dispatch(getProductsUnderStock(companyId));

      setShowModal(false);
      toastr.success('Movimentação de estoque criada com sucesso.');
      return onSubmit(movement);
    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar movimentação.');
    } finally {
      setLoading(false);
    }
  }

  async function update(values) {
    const { itemsStock, providerId, code, movementType, observation } = values;

    try {
      setLoading(true);

      const isFinished = status === 'Finalizada';
      await inventoryRepository.update(
        {
          movement: {
            movementType,
            code: code,
            finishedDate: isFinished ? new Date() : null,
            providerId,
            companyId,
            status: status,
          },
          observation,
          items: itemsStock,
        },
        id
      );

      if (isFinished) dispatch(getProductsUnderStock(companyId));

      setShowModal(false);
      toastr.success('Movimentação atualizada com sucesso.');
      return onSubmit();
    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar movimentação.');
    } finally {
      setLoading(false);
    }
  }

  const initialValues = {
    itemsStock: [],
    searchProvider: '',
    registerDate: format(new Date(), 'dd/MM/yyyy'),
    status: 'Aberta',
    movementType: 'Entrada',
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        loading={loading}
        disabled={disabled}
        id={id}
      />

      <AlertModal
        show={showConfirmModal}
        animation={false}
        title="OS Digital"
        message={
          status === 'Finalizada'
            ? ` Deseja finalizar a movimentação e ${
                values.movementType === 'Entrada' ? 'creditar' : 'debitar'
              } o estoque com as quantidades definidas?`
            : `Deseja salvar a ${values.movementType?.toLowerCase()}? Nenhum valor será movimentado no estoque.`
        }
        onHide={() => setShowConfirmModal(false)}
        onCancel={() => setShowConfirmModal(false)}
        onSubmit={() => confirmModal()}
      />

      <Modal
        dialogClassName="modal-25w"
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="survey-modal-header">
              <strong>OS Digital</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: '14px' }}>
            Deseja salvar ou finalizar a movimentação?
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <button
              className="button button-h35 button-green"
              onClick={() => handleModal('Finalizada')}
              style={{ width: '230px' }}
            >
              Finalizar e movimentar estoque
            </button>
            <button
              className="button button-h35 button-blue"
              onClick={() => handleModal('Aberta')}
              style={{ width: '230px' }}
            >
              Salvar movimentação em aberto
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {loadingStock && (
        <Loader message="Aguarde, carregando movimentação de estoque" />
      )}
    </div>
  );
};

export default BundleFormNewStock;
