const validate = values => {
  const errors = {}

  if (!values.description) {
    errors.description = 'Obrigatório'
  }
  if (!values.type) {
    errors.type = 'Obrigatório'
  }
  if (!values.categoryId) {
    errors.categoryId = 'Obrigatório'
  }

  if (values.description?.length > 50) {
    errors.description = 'Campo deve ter até 50 caracteres.'
  }
  if (values.observations?.length > 250) {
    errors.observations = 'Campo deve ter até 250 caracteres.'
  }

  return errors
}

export default validate