import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import renderField from '../../../../../components/RenderField'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useAuth } from '../../../../../contexts/auth'
import Datalist from 'components/Datalist'
import useDebounce from 'hooks/useDebounce'

import similaritiesRepository from '../../../../../repositories/Similarities'
import kitRepository from '../../../../../repositories/Kit'

const SimilarityMainData = ({ productInfo }) => {
  const [products, setProducts] = useState([])
  const [productsSuggestions, setProductsSuggestions] = useState([])
  const [searchQuery, setSearchQuery] = useState()

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  const debouncedSearchQuery = useDebounce(setSearchQuery, 800)

  useEffect(() => {
    if (!!companyId) {
      loadProducts()
    }
  }, [companyId, searchQuery])

  useEffect(() => {
    handleProductsSuggestions()
  }, [products])

  async function handleProductsSuggestions() {
    const kits = await kitRepository.getAll({
      companyId
    })

    const productsId = kits.kitItemsProducts.map(element => element.productId)

    const filteredProducts = products.filter((product) => {
      return !productsId.includes(product.id)
    })

    const suggestions = filteredProducts.map(product => {
      return ({
        primary: `Código: ${product.Code}, Descrição: ${product.Description} ${!!product.Manufacturer_Ref
          ? `, Ref. Fabricante: ${product.Manufacturer_Ref}`
          : ''
          }`
      })
    })

    setProductsSuggestions(suggestions)
  }

  async function loadProducts() {
    try {
      const response = await similaritiesRepository.getAllProductSugggestions({
        Company_id: companyId,
        IsActive: 1,
        productId: productInfo.id,
        query: searchQuery
      })

      const serializeProducts = response.data.map((productInfos) => ({
        ...productInfos,
        Code: Number(productInfos.Code),
        brand: productInfos.Brands?.Description,
      }))

      setProducts(serializeProducts)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
      )
    }
  }

  const handleChangeSearchQuery = (value) => {
    debouncedSearchQuery(value)

    const productCode = value.split(': ')[1]?.split(',')[0]
    getProduct(productCode)
  }

  const getProduct = (productCode) => {
    const selectedProduct = products.find((p) => p.Code == productCode)

    if (selectedProduct) {
      dispatch([
        change('formSimilarity', 'similarProductId', selectedProduct.id),
        change('formSimilarity', 'productId', productInfo.id),
        change('formSimilarity', 'code', selectedProduct.Code),
        change('formSimilarity', 'description', selectedProduct.Description),
        change(
          'formSimilarity',
          'manufacturer_ref',
          selectedProduct.Manufacturer_Ref
        ),
        change('formSimilarity', 'brandDescription', selectedProduct.brand),
      ])
    }
  }

  return (
    <>
      <Col xs={12} sm={5} md={5} lg={5}>
        <i id='iconepesquisar' className='fa fa-search'></i>
        <input
          list='searchProduct'
          type='text'
          style={{ paddingLeft: '7%' }}
          className='form-control foco-input'
          placeholder='Pesquisar por Código, Ref. Fabricante ou Descrição'
          onChange={(e) => handleChangeSearchQuery(e.target.value)}
          value={null}
        />
        <Datalist suggestions={productsSuggestions} id='searchProduct' />
      </Col>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Col xs={6} sm={3} md={2} lg={2}>
          <Field label='Código:' name='code' component={renderField} disabled />
        </Col>
        <Col xs={12} sm={9} md={9} lg={4}>
          <Field
            label='Descrição:'
            name='description'
            component={renderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Field
            label='Ref. Fabricante:'
            name='manufacturer_ref'
            component={renderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Field
            label='Marca'
            name='brandDescription'
            component={renderField}
            disabled
          />
        </Col>
      </div>
    </>
  )
}

export default SimilarityMainData
