import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SwitchRow } from 'v2/components/form';
import { SubFeatureContent } from '../SubFeatureContent';
import { useModulesContext } from 'contexts/modules';

export function MainFeatureContent({ currentGroupFeatureIndex }) {
  const { watch, setValue } = useFormContext();

  const { groupFeatures } = watch();
  const currentGroupFeature = groupFeatures[currentGroupFeatureIndex];

  const {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  } = useModulesContext();

  const modules = {
    isAnyFiscalModuleActive: [
      'CFOP',
      'NCM/NBS',
      'Texto para Notas Fiscais',
      'Transportadora',
      'Tributo por Estado',
      'CNAE',
      'Intermediador',
      'Fiscal',
      'Notas Fiscais',
    ],
    isNFeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFCeModuleActive: ['Nota Fiscal (NF-e, NFC-e e SAT-CFe)'],
    isNFSeModuleActive: ['Nota Fiscal de Serviço (NFS-e)'],
    isWorkshopPanelModuleActive: ['Painel da Oficina'],
  };

  const activeModules = {
    isAnyFiscalModuleActive,
    isWorkshopPanelModuleActive,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
  };

  function hasActiveFeatureModule(feature) {
    for (let module in modules) {
      if (modules[module].includes(feature.description)) {
        return feature.isAllowedToUse && activeModules[module];
      }
    }
    return feature.isAllowedToUse;
  }

  function handleMainFeatureSwitchToggle(mainFeature, index) {
    const newStatus =
      hasActiveFeatureModule(mainFeature) && !mainFeature.status;

    setValue(
      `groupFeatures[${currentGroupFeatureIndex}].mainFeatures[${index}].status`,
      newStatus
    );

    changeIsMasterStatus(mainFeature);

    return changeAllSubFeaturesStatus(index, newStatus);
  }
  function changeIsMasterStatus(mainFeature) {
    const newStatus =
      hasActiveFeatureModule(mainFeature) && !mainFeature.status;

    setValue(`isMaster`, !newStatus);
  }

  function changeAllSubFeaturesStatus(mainFeatureIndex, newStatus) {
    const subfeaturesChanged = groupFeatures[
      currentGroupFeatureIndex
    ].mainFeatures[mainFeatureIndex].subFeatures.map((subFeature) => ({
      ...subFeature,
      status: newStatus && hasActiveFeatureModule(subFeature),
    }));

    setValue(
      `groupFeatures[${currentGroupFeatureIndex}].mainFeatures[${mainFeatureIndex}].subFeatures`,
      subfeaturesChanged
    );
  }

  return (
    <div>
      {currentGroupFeature?.mainFeatures.map((mainFeature, index) => {
        return (
          <div key={mainFeature.id} style={{ marginBottom: '20px' }}>
            <div>
              {mainFeature.visible && (
                <SwitchRow
                  width={'100%'}
                  title={`Acesso a ${mainFeature.description}`}
                  onChange={() =>
                    handleMainFeatureSwitchToggle(mainFeature, index)
                  }
                  checked={
                    hasActiveFeatureModule(mainFeature) && mainFeature.status
                  }
                  radius="top"
                  disabledToggle={!hasActiveFeatureModule(mainFeature)}
                />
              )}
            </div>
            <SubFeatureContent
              currentGroupFeatureIndex={currentGroupFeatureIndex}
              currentMainFeatureIndex={index}
              isMainFeatureVisible={mainFeature.visible}
            />
          </div>
        );
      })}
    </div>
  );
}
