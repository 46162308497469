import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import LoginPage from '../../views/LoginPage'

const ClientInitialRoutes = () => {
    return (
        <Switch>
            <Route path="/client-login" component={LoginPage} />
            <Redirect path='/client/*' to='/client-login' />
        </Switch>
    )
}

export default ClientInitialRoutes