import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import CardForm from '../../../../../client/components/CardForm';

import SATsRepository from '../../../../../repositories/SATs'
import companiesRepository from '../../../../../repositories/Companies'
import constants from '../../../../../utils/constants'

const HomeSat = ({ history, companyId }) => {
    const [isSATDataOpen, setIsSATDataOpen] = useState(true)
    const [noCompanyModal, setNoCompanyModal] = useState(false)
    const [sats, setSats] = useState([])
    const [state, setState] = useState('')
    const [loading, setLoading] = useState(false)

    
    useEffect(() => {
        if (companyId) {
            loadSats()
            loadState()
        }
    }, [])

    const loadSats = async () => {
        setLoading(true)
        try {
            const response = await SATsRepository.getAllByCompany(companyId);
            await setSats(response)
        }catch(err){
            console.log(err)
            console.log('Erro ao carregar SAT')
        }
        setLoading(false)

    }

    const loadState = async () => {
        try{
            const response = await companiesRepository.getById(companyId);
            setState(response.Address[0].State)
        }catch(err){
            console.log(err)
            console.log('Erro ao buscar estado da empresa') 
        }
    }
    return (
       state === "SP" ? 
            <CardForm
            show={isSATDataOpen}
            title="Dados da SAT (Opcional)"
            onClick={() => setIsSATDataOpen(!isSATDataOpen)}
        >
            <div>
                <ModalAlertCompany
                    show={noCompanyModal}
                    onHide={() => setNoCompanyModal(false)}
                />
                <button
                    id="btn-nova-revisao"
                    className="btn btn-sucesso"
                    style={{ marginBottom: '20px'}}
                    onClick={() => !!companyId ? history.push(constants.ROUTES.SAT) : setNoCompanyModal(true)}
                >
                    + Novo SAT
          </button>
            </div>
            <ReactTable
                style={{ fontWeight: "bold", textAlign: "center" }}
                data={sats}
                columns={[
                    {
                        Header: "Fabricante",
                        accessor: "SATManufacturers.description",
                        headerClassName: "text-left",
                        width: 200,
                    },
                    {
                        Header: "Nº de Série do Equipamento",
                        accessor: "equipmentSerie",
                        headerClassName: "text-left",
                        width: 200,
                        Cell: props =>
                            <>
                                <Link id='linkVenda' to={{ pathname: `SAT#${props.original.id}` }} title='Clique para editar'>
                                    <button className='btn-link'>
                                        {props.value}
                                    </button>
                                </Link>
                            </>
                    },
                    {
                        Header: "Código de ativação",
                        accessor: "activationCode",
                        headerClassName: "text-left",
                        width: 200,
                    },
                    {
                        Header: "Versão",
                        accessor: "version",
                        headerClassName: "text-left",
                        width: 100,
                    },
                    {
                        Header: "Série de Emissão",
                        accessor: "serie",
                        headerClassName: "text-left",
                        width: 200,
                    },
                    {
                        Header: "Status",
                        accessor: "isActive",
                        headerClassName: "text-left",
                        width: 100,
                        Cell: props =>
                            <button
                                className="admin-custom-label"
                                style={{ backgroundColor: props.value ? "green" : "red" }}
                            >{props.value ? "Ativo" : "Inativo"}</button>
                    },
                    {
                        Header: "Ações",
                        accessor: "id",
                        headerClassName: "text-left",
                        filterable: false,
                        Cell: props =>
                            !!companyId ?
                                <Link
                                    to={{ pathname: `SAT#${props.value}` }} title="Clique para editar"
                                >
                                    <FontAwesomeIcon
                                        title='Editar'
                                        cursor='pointer'
                                        style={{ height: '1.5em', width: '1.5em', color: 'black' }}
                                        icon={faEdit}
                                    />
                                </Link>
                                :
                                <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="false"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setNoCompanyModal(true)}
                                />
                    }
                ]}
                defaultPageSize={5}
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                    {
                        id: "createdAt",
                        desc: true
                    }
                ]}
                previousText='Anterior'
                nextText='Próximo'
                loadingText="Carregando..."
                noDataText="Não há informação"
                pageText='Página'
                ofText='de'
                rowsText='linhas'
            />
                    
        </CardForm >
        : 
        <div></div>
    );
}
export default withRouter(HomeSat)
