import React, { useEffect } from 'react'
import osDigitalLogo from '../../assets/img/os-digital-letra-branca.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-regular-svg-icons';

import { useAuth } from '../../contexts/auth'
import './styles.css'
import { withRouter } from 'react-router';

const CompanyBlock = ({ history }) => {
  const { isCompanyActive } = useAuth()

  useEffect(() => {
    if(isCompanyActive) {
      history.push('/client/home')
    }
  }, [isCompanyActive])

  return (
    <div id='company-block-page'>
      <header className='block-header'>
        <img src={osDigitalLogo} alt='O.S Digital Logo' />
      </header>

      <main className='content'>
        <div>
          <FontAwesomeIcon icon={faCalendarTimes} />
        </div>
        <section>
          <p>Prezado cliente,</p>
          <div>
            <p>Foi identificado que existem boletos em atraso. </p>
            <p>Por gentileza, entre em contato com o nosso financeiro
              <a
                href='http://workg.mysuite.com.br/client/chatan.php?h=5574b0e888998b175df1eee2212c58da&sl=wkg' 
                target='_blank' 
                rel='noopener noreferrer'
              >
                {' '}clicando aqui{' '}
              </a>
              ou através dos
            </p>
            <p>telefones (19) 3755-3004 // (19) 3755-3029 ou através do WhatsApp (19) 9 9232-8861</p>
          </div>
          <p>Financeiro Work Inteligência</p>
        </section>
      </main>
    </div>
  )
}

export default withRouter(CompanyBlock)