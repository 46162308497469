import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'

import FormCashierBank from './FormCashierBank'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewCashierBank = ({ location, history }) => {
  const params = new URLSearchParams(location.search)
  const idParams = params.get("id");

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Cadastro', 'Financeiro', 'Bancos/Caixa', `${idParams ? 'Edição' : 'Cadastro'} de Caixa/Banco`]}
            path={[constants.ROUTES.home, null, null, constants.ROUTES.CASHIER_BANKS, null]}
          />
          <FormCashierBank cashierBankId={idParams} onCancel={() => history.push(constants.ROUTES.CASHIER_BANKS)} onSubmit={() => history.push(constants.ROUTES.CASHIER_BANKS)}/>
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(NewCashierBank)