import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function FinancingAuthorizedTemplate({ children }) {
  return (
    <div>
      <div style={{ fontSize: '13px' }}>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            marginBottom: 0,
          }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color="#4cae4c"
            fontSize={'15px'}
          />
          &nbsp;
          <strong>Financiamento Pré-aprovado</strong>
        </p>
      </div>
      <div
        style={{
          fontSize: '13px',
          marginLeft: '18px',
        }}
      >
        <p style={{ marginBottom: 0 }}>
          As condições de parcelamento disponíveis para você:
        </p>
        <span style={{ color: '#d9534f', fontSize: '11px' }}>
          * O parcelamento contém acréscimo de juros
        </span>
      </div>

      <div>{children}</div>

      <div
        style={{
          fontSize: '13px',
          marginLeft: '18px',
          marginTop: '20px',
        }}
      >
        <p>
          Ao avançar, será criada uma proposta de financimento para o cliente.
        </p>
      </div>
    </div>
  )
}
