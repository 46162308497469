import React from 'react'

let inputType = 'password'
let descriptionInput;

const renderFieldSpan = ({ input, type = inputType, id, meta: { touched, error } }) => {

    return (
        <div className="password-div-refresh">
            <input id="input-pwd" ref={(input) => { descriptionInput = input; }} maxLength="18" id={id} className="form-control foco-input" autoComplete="new-password" {...input} type={type} />
            <span style={{ position: 'absolute', top: '10px', right: '5px', transition: 'right 0.2s' }} className={type === 'text' ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => {
                inputType = type === 'password' ? 'text' : 'password'
                descriptionInput.focus()
            }} />
            {touched && error && <span style={{
                color: 'red',
                textAlign: 'left',
                display: 'block',
                paddingLeft: '10px'
            }}>{error}</span>}
        </div>
    )
}

export default renderFieldSpan