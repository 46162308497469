import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeShipping from './ShippingCompany'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

import './styles.css';

const Shipping = () => {
  localStorage.setItem('selectedPath', 'client/shipping-companies')
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'Transportadora']}
           path={['home', null, null, null ]} />
          <HomeShipping />
        </Row>
      </Grid>
    </div>
  );
}
export default Shipping