import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { useParams } from 'react-router'
import NewPromotionalPriceMain from './components/NewPromotionalPriceMain'

export default function NewPromotionalPriceTable({ match, history }) {
  const { id } = useParams()

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Vendas',
              'Tabela de Preço Promocional',
              id
                ? 'Editar Tabela de Preço Promocional'
                : 'Nova Tabela de Preço Promocional',
            ]}
            path={['home', 'sales', 'promotional-price-table', null]}
          />
          <NewPromotionalPriceMain
            history={history}
            id={id}
            hideDiscountColumn={true}
          />
        </Row>
      </Grid>
    </div>
  )
}
