import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import CardForm from '../../../components/CardForm';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import MainData from './MainData';
import OtherInfos from './OtherInfos.js';
import Items from './Items';
import Footer from './Footer';

import './styles.css';

const FormNFSe = ({
  handleSubmit,
  loading,
  setLoading,
  onConsult,
  onCancel,
}) => {
  const [isMainCardOpen, setIsMainCardOpen] = useState(true);
  const [isItemsCardOpen, setIsItemsCardOpen] = useState(false);
  const [isOtherInfosCardOpen, setIsOtherInfosCardOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isAddressValid, setisAddressValid] = useState(true);

  const { customerId, NFSeItems } = useSelector(
    (state) => state.form.NFSe.values
  );

  function handleCancel() {
    if (!customerId && !NFSeItems.length) {
      onCancel();
      return;
    }
    setIsCancelModalOpen(true);
  }

  return (
    <form id="NFSe-form">
      <CardForm
        show={isMainCardOpen}
        title="Dados da Nota"
        proceedButton
        onProceed={() => {
          setIsMainCardOpen(false);
          setIsItemsCardOpen(true);
          setIsOtherInfosCardOpen(true);
        }}
        onClick={() => setIsMainCardOpen(!isMainCardOpen)}
      >
        <MainData
          setLoading={setLoading}
          setisAddressValid={setisAddressValid}
          isAddressValid={isAddressValid}
        />
      </CardForm>
      <CardForm
        show={isItemsCardOpen}
        title="Itens"
        proceedButton
        onProceed={() => {
          setIsMainCardOpen(false);
          setIsItemsCardOpen(false);
          setIsOtherInfosCardOpen(true);
        }}
        onClick={() => setIsItemsCardOpen(!isItemsCardOpen)}
      >
        <Items />
      </CardForm>

      <CardForm
        show={isOtherInfosCardOpen}
        title="Outras Informações (Opcional)"
        onClick={() => setIsOtherInfosCardOpen(!isOtherInfosCardOpen)}
      >
        <OtherInfos />
      </CardForm>

      <Footer
        loading={loading}
        onCancel={handleCancel}
        onConsult={onConsult}
        onSubmit={handleSubmit}
        isAddressValid={isAddressValid}
      />

      {isCancelModalOpen && (
        <AlertModal
          show={isCancelModalOpen}
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          message="Deseja voltar e cancelar a nota ?"
          onSubmit={onCancel}
        />
      )}
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'NFSe',
})(FormNFSe);
