import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import CustomerListMain from './components/CustomerListMain'

import './index.css'

export default function CustomerList ({ location }) {
 
  return (
    <div className='content'>
      <Grid fluid>
        <Row>         
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Clientes/Funcionários',
              'Listagem de Clientes'
            ]}
            path={['home', null, null, null]}
          />
          <CustomerListMain />
        </Row>
      </Grid>
    </div>
  )
}
