import api from '../services/api';

export const create = async (data) => {
  await api.post('bills-to-receive', data);
  return data;
};

export const update = async (data) => {
  await api.put('bills-to-receive/' + data.titleId, data);
  return data;
};

export const createGroupedBills = async (data) => {
  const res = await api.post('bills-to-receive/bills-grouped', data);
  return res.data;
};
export const saveAuthorizationCode = async (data, companyId) => {
  const res = await api.put(`bills-to-receive/c-auth/${companyId}`, data);
  return res.data;
};

const BillsToReceiveRepositoryV2 = {
  create,
  update,
  createGroupedBills,
  saveAuthorizationCode,
};

export { BillsToReceiveRepositoryV2 };
