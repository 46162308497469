import React, { useState, useEffect } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import 'react-tabs/style/react-tabs.css'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import HomePartApplications from './HomePartApplications'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

const PartApplications = ({ location }) => {
  localStorage.setItem('selectedPath', 'client/part-applications')
  const [id, setId] = useState(null)

  useEffect(() => {
    checkParams()
  }, [])

  function checkParams() {
    const params = new URLSearchParams(location.search)
    const idParams = params.get('id')

    if (idParams) {
      readHash(idParams)
    }
  }

  async function readHash(hash) {
    const productId = await decrypt(hash, '@OS-dig:productId')
    setId(productId)
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Aplicação de Peça']}
            path={['home', 'products', null]}
          />
          <HomePartApplications productId={id} />
        </Row>
      </Grid>
    </div>
  )
}

export default PartApplications
