import api from 'v2/services/api';

const generateFamilyBrandStockReport = async (params) => {
  try {
    const response = await api.get(`report/family-brand-stock`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const FamilyBrandStockReportRepository = {
  generateFamilyBrandStockReport,
};

export default FamilyBrandStockReportRepository;
