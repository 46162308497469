import React, { useEffect, useState } from 'react'
import { change, Field } from 'redux-form'
import { Col, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import {
  cnpjMask,
  cpfMask,
  onlyNumbers,
  onlyLetters,
  milhar,
} from 'client/components/ToNormalize/ToNormalize'
import { currencyMask, licensePlateMask, KGMask } from 'utils/masks'

import shippingCompaniesRepository from '../../../../repositories/ShippingCompanies'
import RenderField from 'components/RenderField'
import NewShippingCompany from '../../ShippingCompany/NewShippingCompany/FormNewShippingCompany'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const Freight = () => {
  const [shippingCompanies, setShippingCompanies] = useState([])
  const [shippingCompanyNameSuggestions, setShippingCompanyNameSuggestions] =
    useState([])
  const [isShippingCompanyModalOpen, setIsShippingCompanyModalOpen] =
    useState(false)

  const {
    searchShippingCompany,
    freightIndicator,
    isInvoiceDenied,
    isInvoiceCanceled,
    isInvoiceIssuedInContingency,
    isInvoiceIssued,
    shippingCompanyId,
  } = useSelector((state) => state.form.NFe.values)

  const companyId = localStorage.getItem('ID_EMPRESA')
  const dispatch = useDispatch()

  useEffect(() => {
    loadShippingCompanies()
  }, [])

  useEffect(() => {
    if (freightIndicator === '9') {
      dispatch([
        change('NFe', 'shippingCompanyName', null),
        change('NFe', 'shippingCompanyId', null),
        change('NFe', 'searchShippingCompanyName', null),
        change('NFe', 'searchShippingCompany', ''),
        change('NFe', 'licensePlate', null),
        change('NFe', 'inssuranceValue', null),
        change('NFe', 'grossWeight', null),
        change('NFe', 'netWeight', null),
        change('NFe', 'freightValue', null),
        change('NFe', 'brand', null),
        change('NFe', 'specie', null),
        change('NFe', 'volume', null),
      ])
    }
  }, [freightIndicator])

  useEffect(() => {
    let shippingCompanyCNPJ = searchShippingCompany.split('|')[0]

    if (!!shippingCompanyCNPJ) {
      shippingCompanyCNPJ = onlyNumbers(shippingCompanyCNPJ.trim())

      if (!!shippingCompanyCNPJ) {
        setShippingCompanyByCPFNPJ(shippingCompanyCNPJ)
      }
    }
  }, [searchShippingCompany])

  useEffect(() => {
      const company = shippingCompanies.find(
        (shippingCompany) => shippingCompany.id === shippingCompanyId
      )
  
      if (!!company) {
        dispatch([
          change('NFe', 'shippingCompanyId', company.id),
          change('NFe', 'searchShippingCompanyName', company.Name),
        ])
      }
  }, [shippingCompanyId])

  async function loadShippingCompanies() {
    try {
      const shippingCompanies =
        await shippingCompaniesRepository.getAllByCompany(companyId)

      const shippingCompanyNameSuggestions = shippingCompanies.map(
        (company) => {
          if (company.Type === 'Juridica') {
            return `${cnpjMask(company.Cpf_Cnpj)} | ${company.Name}`
          }

          return `${cpfMask(company.Cpf_Cnpj)} | ${company.Name}`
        }
      )
      setShippingCompanies(shippingCompanies)
      setShippingCompanyNameSuggestions(shippingCompanyNameSuggestions)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as transportadoras. Por favor, tente novamente'
      )
    }
  }

  function setShippingCompanyByCPFNPJ(companyCpfCnpj) {
    const company = shippingCompanies.find(
      (shippingCompany) => shippingCompany.Cpf_Cnpj === companyCpfCnpj
    )

    if (!!company) {
      dispatch([
        change('NFe', 'shippingCompanyId', company.id),
        change('NFe', 'searchShippingCompanyName', company.Name),
      ])
    }
  }

  function handleRemoveShippingCompany() {
    dispatch([
      change('NFe', 'shippingCompanyId', null),
      change('NFe', 'searchShippingCompany', ''),
      change('NFe', 'searchShippingCompanyName', null),
    ])
  }

  function handleSubmitShippingCompanyModal() {
    setShippingCompanyNameSuggestions([])
    setIsShippingCompanyModalOpen(false)
    handleRemoveShippingCompany()
    loadShippingCompanies()
  }
  return (
    <div id="NFe-freight">
      <div>
        <Col xs={12} sm={12} md={3} lg={3} style={{ padding: '2px 2px' }}>
          <Field
            name="freightIndicator"
            component={RenderField}
            label="Frete por Conta"
            as="select"
            disabled={
              isInvoiceDenied ||
              isInvoiceCanceled ||
              isInvoiceIssuedInContingency ||
              isInvoiceIssued
            }
          >
            <option value="9">Sem Frete</option>
            <option value="0">Frete por conta do Remetente</option>
            <option value="1">Frete por conta do Destinatário</option>
            <option value="2">Frete por conta de Terceiros</option>
            <option value="4">Próprio por conta do Destinatário</option>
          </Field>
        </Col>
      </div>
      {freightIndicator !== '9' && (
        <>
          <div>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ position: 'relative', padding: '2px 2px' }}
            >
              <span
                className="field-hiperlink"
                onClick={() =>
                  !!shippingCompanyId &&
                  !isInvoiceDenied &&
                  !isInvoiceCanceled &&
                  !isInvoiceIssued &&
                  !isInvoiceIssuedInContingency &&
                  setIsShippingCompanyModalOpen(true)
                }
                style={{
                  cursor:
                    !!shippingCompanyId &&
                    !isInvoiceDenied &&
                    !isInvoiceCanceled &&
                    !isInvoiceIssued &&
                    !isInvoiceIssuedInContingency
                      ? 'pointer'
                      : 'not-allowed',
                }}
              >
                Visualizar Transportador
              </span>
              {!shippingCompanyId ? (
                <>
                  <Field
                    name="searchShippingCompany"
                    component={RenderField}
                    label="Transportadora"
                    list="shippingCompanySuggestions"
                    disabled={
                      isInvoiceDenied ||
                      isInvoiceCanceled ||
                      isInvoiceIssuedInContingency ||
                      isInvoiceIssued
                    }
                  />
                  <datalist id="shippingCompanySuggestions">
                    {shippingCompanyNameSuggestions.map((company) => (
                      <option key={company} value={company} />
                    ))}
                  </datalist>
                </>
              ) : (
                <Field
                  name="searchShippingCompanyName"
                  label="Transportadora"
                  className="selected-client-field"
                  component={RenderField}
                  onClick={handleRemoveShippingCompany}
                  disabled={
                    isInvoiceDenied ||
                    isInvoiceCanceled ||
                    isInvoiceIssuedInContingency ||
                    isInvoiceIssued
                  }
                />
              )}
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '130px', padding: '2px 2px' }}
            >
              <Field
                name="licensePlate"
                component={RenderField}
                label="Placa do Veículo"
                {...licensePlateMask}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '130px', padding: '2px 2px' }}
            >
              <Field
                name="volume"
                component={RenderField}
                label="Qtd. Volume"
                normalize={(value) => milhar(onlyNumbers(value))}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '130px', padding: '2px 2px' }}
            >
              <Field
                name="grossWeight"
                component={RenderField}
                label="Peso Bruto (kg)"
                {...KGMask}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '140px', padding: '2px 2px' }}
            >
              <Field
                name="netWeight"
                component={RenderField}
                label="Peso Líquido (kg)"
                {...KGMask}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '130px', padding: '2px 2px' }}
            >
              <Field
                name="specie"
                component={RenderField}
                label="Espécie"
                maxLength={10}
                normalize={onlyLetters}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{ width: '130px', padding: '2px 2px' }}
            >
              <Field
                name="brand"
                component={RenderField}
                label="Marca"
                maxLength={10}
                normalize={onlyLetters}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
          </div>
          <div>
            <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '2px 2px' }}>
              <Field
                name="freightValue"
                component={RenderField}
                label="Valor do Frete"
                {...currencyMask}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '2px 2px' }}>
              <Field
                name="inssuranceValue"
                component={RenderField}
                label="Valor do Seguro"
                {...currencyMask}
                disabled={
                  isInvoiceDenied ||
                  isInvoiceCanceled ||
                  isInvoiceIssuedInContingency ||
                  isInvoiceIssued
                }
              />
            </Col>
          </div>
        </>
      )}

      <Modal
        dialogClassName="modal-formProduto"
        show={isShippingCompanyModalOpen}
        onHide={() => setIsShippingCompanyModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Dados do Transportador</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'scroll' }}>
          <NewShippingCompany
            shippingCompanyId={shippingCompanyId}
            onCancel={() => setIsShippingCompanyModalOpen(false)}
            onSubmit={handleSubmitShippingCompanyModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Freight
