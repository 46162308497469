import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  phoneMask,
  cpfOrCnpjMask,
  cepMask,
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ItemLabel = ({ label, value, width, fontSize }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      width: width,
      fontSize: fontSize,
    }}
  >
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const TableContent = ({ reportData, reportType, initialDate, finalDate }) => {
  return (
    <>
      <View>
        <Text style={styles.title}>Comissão de Representante</Text>
        <ItemLabel
          label="Tipo de Filtragem"
          value={reportType === 'synthetic' ? 'Sintético' : 'Analítico'}
        />
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <ItemLabel label="Data Início" value={initialDate} width="15%" />
          <ItemLabel label="Data Fim" value={finalDate} />
        </View>
      </View>

      <RepresentativeLoop reportData={reportData} reportType={reportType} />

      {/* <View style={styles.line} /> */}
    </>
  )
}

const RepresentativeLoop = ({ reportData, reportType }) => {
  return (
    <>
      {reportData.map((report, index) => {
        return (
          <>
            <View>
              {report.totals.OS.quantity !== 0 ||
              report.totals.PDV.quantity !== 0 ||
              report.totals.Sales.quantity !== 0 ||
              report.saleListByType.budget.lenght !== 0 ? (
                <>
                  <View style={styles.line} />

                  <RepresentativeInfo
                    representativeInfo={report.representativeInfo}
                  />

                  {report.totals.OS.quantity !== 0 ||
                  report.totals.PDV.quantity !== 0 ||
                  report.totals.Sales.quantity !== 0 ? (
                    <>
                      <View style={styles.line} />

                      <View>
                        <Text style={[styles.title, { textAlign: 'center' }]}>
                          Totais por Tipo/Status
                        </Text>
                      </View>

                      <View style={styles.line} />

                      <TotalsByStatus
                        totals={report.totals}
                        reportType={reportType}
                      />
                    </>
                  ) : (
                    <View />
                  )}

                  {reportType === 'synthetic' && (
                    <RepresentativeComissionsSynthetic sales={report.sales} />
                  )}
                  {reportType === 'analytical' && (
                    <RepresentativeComissionsAnalytic
                      sales={report.saleListByType}
                    />
                  )}
                </>
              ) : (
                <View />
              )}
            </View>

            {index < reportData.length - 1 && <View break />}
          </>
        )
      })}
    </>
  )
}

const RepresentativeInfo = ({ representativeInfo }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <ItemLabel
          label="CPF/CNPJ"
          value={cpfOrCnpjMask(representativeInfo.tradeRepresentativeCpfCnpj)}
        />
      </View>
      <View style={styles.containerRow}>
        <ItemLabel
          label="Razão Social/Nome"
          width="40%"
          value={
            representativeInfo.tradeRepresentativeName ||
            representativeInfo.tradeRepresentativeTradingName
          }
        />
        <ItemLabel
          label="Telefone"
          width="17%"
          value={phoneMask(
            representativeInfo.tradeRepresentativePhone1 ||
              representativeInfo.tradeRepresentativePhone2
          )}
        />
        <ItemLabel
          label="Email"
          value={representativeInfo.tradeRepresentativeEmail}
        />
      </View>
      <View style={styles.containerRow}>
        <ItemLabel
          label="Endereço"
          width="30%"
          value={representativeInfo.tradeRepresentativeAddress}
        />
        <ItemLabel
          label="Nº"
          width="10%"
          value={representativeInfo.tradeRepresentativeAddressNumber}
        />
        <ItemLabel
          label="Bairro"
          width="20%"
          value={representativeInfo.tradeRepresentativeNeighborhood}
        />
        <ItemLabel
          label="Cidade/UF"
          value={`${
            representativeInfo.tradeRepresentativeCity
              ? representativeInfo.tradeRepresentativeCity
              : ''
          }/${
            representativeInfo.tradeRepresentativeState
              ? representativeInfo.tradeRepresentativeState
              : ''
          }`}
        />
      </View>
      <View style={styles.containerRow}>
        <ItemLabel
          label="CEP"
          value={cepMask(representativeInfo.tradeRepresentativeZipCode)}
        />
      </View>
    </View>
  )
}

const RepresentativeComissionsSynthetic = ({ sales }) => {
  const othersSales = sales.filter((sale) => sale.saleType !== 'Orçamento')
  const budgetsSales = sales.filter((sale) => sale.saleType === 'Orçamento')

  return (
    <View>
      <>
        {othersSales.length ? (
          <>
            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Vendas/Ordens de Serviço
              </Text>
            </View>

            <View style={[styles.line]} />

            <View style={styles.containerRow}>
              <Text style={[styles.boldText, { width: '9%' }]}>
                Data Criação
              </Text>
              <Text style={[styles.boldText, { width: '8%' }]}>Data Fech.</Text>
              <Text style={[styles.boldText, { width: '7%' }]}>Venda/OS</Text>
              <Text style={[styles.boldText, { width: '20%' }]}>Cliente</Text>
              <Text style={[styles.boldText, { width: '8%' }]}>Valor</Text>
              <Text style={[styles.boldText, { width: '12%' }]}>
                % Comiss. Prod.
              </Text>
              <Text style={[styles.boldText, { width: '12%' }]}>
                R$ Comiss. Prod.
              </Text>
              <Text style={[styles.boldText, { width: '12%' }]}>
                % Comiss. Serv.
              </Text>
              <Text style={[styles.boldText, { width: '12%' }]}>
                R$ Comiss. Serv.
              </Text>
            </View>

            <View style={[styles.line]} />
          </>
        ) : (
          <View />
        )}

        {othersSales.map((s) => (
          <View style={styles.containerRow}>
            <Text style={[styles.text, { width: '9%' }]}>
              {format(new Date(s.createdAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '8%' }]}>
              {format(new Date(s.updatedAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '7%' }]}>{s.code}</Text>
            <Text style={[styles.text, { width: '20%' }]}>
              {s.customerName}
            </Text>
            <Text style={[styles.text, { width: '8%' }]}>
              {currency(s.finalValue)}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {percentage(s.repProductComissionPercentage)}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {currency(s.repProductComissionValue)}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {percentage(s.repServiceComissionPercentage)}
            </Text>
            <Text style={[styles.text, { width: '12%' }]}>
              {currency(s.repServiceComissionValue)}
            </Text>
          </View>
        ))}

        {budgetsSales.length ? (
          <>
            <View style={[styles.line]} />

            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Orçamentos
              </Text>
            </View>

            <View style={[styles.line]} />

            <View style={styles.containerRow}>
              <Text style={[styles.boldText, { width: '9%' }]}>
                Data Criação
              </Text>
              <Text style={[styles.boldText, { width: '8%' }]}>Data Fech.</Text>
              <Text style={[styles.boldText, { width: '7%' }]}>Venda/OS</Text>
              <Text style={[styles.boldText, { width: '20%' }]}>Cliente</Text>
              <Text style={[styles.boldText, { width: '9%' }]}>Valor</Text>
            </View>

            <View style={[styles.line]} />
          </>
        ) : (
          <View />
        )}

        {budgetsSales.map((s) => (
          <View style={styles.containerRow}>
            <Text style={[styles.text, { width: '9%' }]}>
              {format(new Date(s.createdAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '8%' }]}>
              {format(new Date(s.updatedAt), 'dd/MM/yyyy')}
            </Text>
            <Text style={[styles.text, { width: '7%' }]}>{s.code}</Text>
            <Text style={[styles.text, { width: '20%' }]}>
              {s.customerName}
            </Text>
            <Text style={[styles.text, { width: '9%' }]}>
              {currency(s.finalValue)}
            </Text>
          </View>
        ))}
      </>
    </View>
  )
}

const RepresentativeComissionsAnalytic = ({ sales }) => {
  let othersSales = []
  let budgets = []

  sales.sales.map((sale) => {
    othersSales.push(sale)
  })

  sales.salesOS.map((sale) => {
    othersSales.push(sale)
  })

  sales.salesPDV.map((sale) => {
    othersSales.push(sale)
  })

  sales.budget.map((sale) => {
    budgets.push(sale)
  })

  const getTotalItemComission = (salesItems, type) => {
    return salesItems
      .filter((item) => item.type === type)
      .reduce((prev, curr) => prev + curr.itemComissionValue, 0)
  }

  return (
    <View>
      <>
        {othersSales.length ? (
          <>
            <View style={[styles.line]} />

            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Vendas/Ordens de Serviço
              </Text>
            </View>
          </>
        ) : (
          <View />
        )}

        {othersSales?.map((sales) => (
          <>
            <View style={[styles.line]} />

            <View style={styles.containerRow} wrap={false}>
              <ItemLabel
                width="15%"
                label="Data Criação"
                value={format(new Date(sales.createdAt), 'dd/MM/yyyy')}
              />
              <ItemLabel
                width="20%"
                label="Data Fechamento"
                value={format(new Date(sales.updatedAt), 'dd/MM/yyyy')}
              />
            </View>

            <View style={styles.containerRow} wrap={false}>
              <ItemLabel
                width="25%"
                label="Cliente"
                value={sales.customerName}
              />
              <ItemLabel width="15%" label="Venda/OS" value={sales.code} />
              <ItemLabel
                width="15%"
                label="Valor"
                value={currency(sales.totalValue)}
              />
            </View>

            <View style={[styles.line]} />
            <View style={styles.containerRow} wrap={false}>
              <Text style={[styles.boldText, { width: '8%' }]}>Código</Text>
              <Text style={[styles.boldText, { width: '5%' }]}>Tipo</Text>
              <Text style={[styles.boldText, { width: '25%' }]}>Descrição</Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Quantidade
              </Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Preço Unit.
              </Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Desc. Unit.
              </Text>
              <Text style={[styles.boldText, { width: '12%' }]}>Total</Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                % Comissão
              </Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                R$ Comissão
              </Text>
            </View>

            <View style={[styles.line]} />

            {sales.salesItems.map((items) => (
              <View style={styles.containerRow} wrap={false}>
                <Text style={[styles.text, { width: '8%' }]}>{items.code}</Text>
                <Text style={[styles.text, { width: '5%' }]}>{items.type}</Text>
                <Text style={[styles.text, { width: '25%' }]}>
                  {items.description}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {items.quantity}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {currency(items.unitValue)}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {percentage(items.discountUnit)}
                </Text>
                <Text style={[styles.text, { width: '12%' }]}>
                  {currency(items.amount)}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {percentage(items.comissionPercentage)}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {currency(items.itemComissionValue)}
                </Text>
              </View>
            ))}

            <View
              style={{ display: 'flex', alignItems: 'flex-end' }}
              wrap={false}
            >
              <View style={[styles.secondline]} />

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <ItemLabel
                  label="Condição de Pagamento"
                  width="33%"
                  value={sales.conditionDescription}
                  fontSize="10px"
                />
                <Text style={[styles.boldText, { width: '10%' }]}>
                  Vencimento
                </Text>
                <Text style={[styles.boldText, { width: '15%' }]}>
                  Forma de Pagamento
                </Text>
                <Text style={[styles.boldText, { width: '7%' }]}>Valor</Text>
                <Text style={[styles.boldText, { width: '5%' }]}>Pago?</Text>
              </View>

              {sales.payments.map((payments) => (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: '33%' }} />
                  <Text style={[styles.text, { width: '10%' }]}>
                    {format(new Date(payments.dueDate), 'dd/MM/yyyy')}
                  </Text>
                  <Text style={[styles.text, { width: '15%' }]}>
                    {payments.formPayment}
                  </Text>
                  <Text style={[styles.text, { width: '7%' }]}>
                    {currency(payments.amount)}
                  </Text>
                  <Text style={[styles.text, { width: '5%' }]}>
                    {payments.payDate ? 'Sim' : 'Não'}
                  </Text>
                </View>
              ))}
            </View>

            <View style={[styles.line]} />

            <View
              style={{ display: 'flex', alignItems: 'flex-end' }}
              wrap={false}
            >
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <ItemLabel
                  label="Total R$ Comissão Produto"
                  value={currency(getTotalItemComission(sales.salesItems, 'P'))}
                />
              </View>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <ItemLabel
                  label="Total R$ Comissão Serviço"
                  value={currency(getTotalItemComission(sales.salesItems, 'S'))}
                />
              </View>
            </View>
          </>
        ))}

        {budgets.length ? (
          <View wrap={false}>
            <View style={[styles.line]} />

            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Orçamentos
              </Text>
            </View>

            <View style={[styles.line]} />
          </View>
        ) : (
          <View />
        )}

        {budgets?.map((budget) => (
          <View wrap={false}>
            <View style={styles.containerRow}>
              <ItemLabel
                width="15%"
                label="Data Criação"
                value={format(new Date(budget.createdAt), 'dd/MM/yyyy')}
              />
            </View>

            <View style={styles.containerRow}>
              <ItemLabel
                width="25%"
                label="Cliente"
                value={budget.customerName}
              />
              <ItemLabel width="15%" label="Venda/OS" value={budget.saleId} />
              <ItemLabel
                width="15%"
                label="Valor"
                value={currency(budget.totalValue)}
              />
            </View>

            <View style={[styles.line]} />

            <View style={styles.containerRow}>
              <Text style={[styles.boldText, { width: '8%' }]}>Código</Text>
              <Text style={[styles.boldText, { width: '5%' }]}>Tipo</Text>
              <Text style={[styles.boldText, { width: '25%' }]}>Descrição</Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Quantidade
              </Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Preço Unit.
              </Text>
              <Text style={[styles.boldText, { width: '10%' }]}>
                Desc. Unit.
              </Text>
              <Text style={[styles.boldText, { width: '12%' }]}>Total</Text>
            </View>

            <View style={[styles.line]} />

            {budget.salesItems.map((items) => (
              <View style={styles.containerRow}>
                <Text style={[styles.text, { width: '8%' }]}>{items.code}</Text>
                <Text style={[styles.text, { width: '5%' }]}>{items.type}</Text>
                <Text style={[styles.text, { width: '25%' }]}>
                  {items.description}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {items.quantity}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {currency(items.unitValue)}
                </Text>
                <Text style={[styles.text, { width: '10%' }]}>
                  {percentage(items.discountUnit)}
                </Text>
                <Text style={[styles.text, { width: '12%' }]}>
                  {currency(items.amount)}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </>
    </View>
  )
}

const TotalsByStatus = ({ totals, reportType }) => {
  const totalQuantity =
    totals.PDV.quantity + totals.OS.quantity + totals.Sales.quantity

  const totalValue = totals.PDV.value + totals.OS.value + totals.Sales.value

  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '13%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Qtd.</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>

      <View style={styles.line} />

      {totals.PDV.quantity > 0 && (
        <View style={styles.containerRow}>
          <View style={{ width: '13%' }}>
            <Text style={styles.text}>{totals.PDV.status}</Text>
          </View>
          <View style={{ width: '5%' }}>
            <Text style={styles.text}>{totals.PDV.quantity}</Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>{currency(totals.PDV.value)}</Text>
          </View>
        </View>
      )}

      {totals.OS.quantity > 0 && (
        <View style={styles.containerRow}>
          <View style={{ width: '13%' }}>
            <Text style={styles.text}>{totals.OS.status}</Text>
          </View>
          <View style={{ width: '5%' }}>
            <Text style={styles.text}>{totals.OS.quantity}</Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>{currency(totals.OS.value)}</Text>
          </View>
        </View>
      )}

      {totals.Sales.quantity > 0 && (
        <View style={styles.containerRow}>
          <View style={{ width: '13%' }}>
            <Text style={styles.text}>{totals.Sales.status}</Text>
          </View>
          <View style={{ width: '5%' }}>
            <Text style={styles.text}>{totals.Sales.quantity}</Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text style={styles.text}>{currency(totals.Sales.value)}</Text>
          </View>
        </View>
      )}

      {reportType === 'synthetic' && (
        <View>
          <View style={styles.line} />

          <View style={styles.containerRow}>
            <View style={{ width: '13%' }}>
              <Text style={styles.text}></Text>
            </View>
            <View style={{ width: '5%' }}>
              <Text style={styles.text}>{totalQuantity}</Text>
            </View>
            <View style={{ width: '10%' }}>
              <Text style={styles.text}>{currency(totalValue)}</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

export default function RepresentativeComissionsPDF({
  reportData,
  reportType,
  initialDate,
  finalDate,
}) {
  return (
    <Document title="listagem-de-clientes">
      <Page style={styles.page} orientation="landscape" wrap>
        <TableContent
          reportData={reportData}
          reportType={reportType}
          initialDate={initialDate}
          finalDate={finalDate}
        />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    height: '100%',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  secondline: {
    marginBottom: 5,
    marginTop: 5,
    width: '70%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
})
