import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';

import { useWorkshopPanel } from 'contexts/workshopPanel';
import { CreateAppointmentVariationContainer } from './FirstTab.styles';
import { ServiceStatusLabel } from '../../PanelAppointmentModal.styles';
import { getStatusIndicatorColor } from '../../../../utils';
import { usePanelAppointmentStore } from '../../../../store';

const tableStyles = {
  fontWeight: 'bold',
  textAlign: 'center',
  height: '203px',
  fontSize: '14px',
  width: '100%',
};

export function CreateAppointment({ maintenance }) {
  const { tempAppointments, setTempAppointments } = usePanelAppointmentStore();

  const { employees: availableEmployees, isLoadingEmployees } =
    useWorkshopPanel();

  const [employees, setEmployees] = useState([]);
  const allEmployeesSelected = employees.every((employee) => employee.selected);

  const [services, setServices] = useState(
    maintenance.services.filter(
      (service) =>
        service.shortStatus === 'NI' ||
        service.shortStatus === 'P' ||
        service.shortStatus === 'E'
    )
  );

  const allServicesSelected = services.every((service) => service.selected);
  const hasServicesAndEmployeesSelected =
    employees.some((employee) => employee.selected) &&
    services.some((service) => service.selected);

  const employeesColumns = [
    {
      Header: 'Nome',
      accessor: 'name',
      className: 'texto',
      resizable: false,
    },
    {
      Header: ' ',
      accessor: 'id',
      className: 'texto',
      resizable: false,
      width: 70,
      Cell: (props) => {
        return (
          <>
            <input
              type="checkbox"
              id={`employees.${props.index}.id`}
              className="checkbox-input bulk-deletion-table"
              checked={employees[props.index].selected}
              onChange={() => handleSelectEmployee(props.original.id)}
            />
          </>
        );
      },
    },
  ];

  const servicesColumns = [
    {
      Header: 'Descrição',
      accessor: 'description',
      className: 'texto',
      resizable: false,
    },
    {
      Header: 'Qtd',
      width: 80,
      accessor: 'quantity',
      className: 'texto',
      resizable: false,
    },
    {
      width: 150,
      Header: 'Status',
      accessor: 'status',
      resizable: false,
      Cell: (props) => {
        return (
          <ServiceStatusLabel
            bg={getStatusIndicatorColor(props.original.shortStatus)}
          >
            {props.value}
          </ServiceStatusLabel>
        );
      },
    },
    {
      accessor: 'id',
      className: 'texto',
      resizable: false,
      width: 70,
      Cell: (props) => {
        return (
          <>
            <input
              type="checkbox"
              id={`services.${props.index}.id`}
              className="checkbox-input bulk-deletion-table"
              checked={services[props.index].selected}
              onChange={() => handleSelectService(props.original.id)}
            />
          </>
        );
      },
    },
  ];

  const handleSelectAllEmployeesClick = () => {
    const selectedValue = !allEmployeesSelected;

    setEmployees(
      employees.map((employee) => ({
        ...employee,
        selected: selectedValue,
      }))
    );
  };

  const handleSelectAllServicesClick = () => {
    const selectedValue = !allServicesSelected;

    setServices(
      services.map((service) => ({
        ...service,
        selected: selectedValue,
      }))
    );
  };

  const handleSelectEmployee = (employeeId) => {
    setEmployees(
      employees.map((employee) => {
        if (employee.id === employeeId) {
          return { ...employee, selected: !employee.selected };
        }
        return employee;
      })
    );
  };

  const handleSelectService = (serviceId) => {
    setServices(
      services.map((service) => {
        if (service.id === serviceId) {
          return { ...service, selected: !service.selected };
        }
        return service;
      })
    );
  };

  const clearAllSelections = () => {
    setEmployees(
      employees.map((employee) => ({
        ...employee,
        selected: false,
      }))
    );

    setServices(
      services.map((service) => ({
        ...service,
        selected: false,
      }))
    );
  };

  const handleAppointments = () => {
    const selectedEmployees = employees.filter((employee) => employee.selected);
    const selectedServices = services.filter((service) => service.selected);

    const appointments = [];

    selectedEmployees.forEach((employee) => {
      selectedServices.forEach((service) => {
        appointments.push({
          key: employee.id + service.id,
          employee,
          service,
        });
      });
    });

    const newAppointments = [...tempAppointments, ...appointments];
    const uniqueAppointments = Array.from(
      new Set(newAppointments.map(JSON.stringify))
    ).map(JSON.parse);

    setTempAppointments(uniqueAppointments);
    clearAllSelections();

    toastr.success(
      'Mecânicos apontados',
      'O registro será efetivado após o início do serviço'
    );
  };

  useEffect(() => {
    setEmployees(availableEmployees.filter((employee) => employee.show));
  }, [availableEmployees]);

  return (
    <CreateAppointmentVariationContainer>
      <div>
        <span>Selecione o(s) funcionário(s):</span>
        <div style={{ textAlign: 'right' }}>
          <a
            className="hyperlink"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSelectAllEmployeesClick();
            }}
          >
            {!allEmployeesSelected ? 'Selecionar ' : 'Remover '} todos
          </a>
        </div>
        <ReactTable
          data={employees}
          style={tableStyles}
          columns={employeesColumns}
          showPagination={false}
          noDataText="Nenhum funcionário disponível encontrado"
          loading={isLoadingEmployees}
          loadingText="Carregando..."
        />
      </div>
      <div>
        <span>Selecione o(s) serviço(s):</span>
        <div style={{ textAlign: 'right' }}>
          <a
            className="hyperlink"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSelectAllServicesClick();
            }}
          >
            {!allServicesSelected ? 'Selecionar ' : 'Remover '} todos
          </a>
        </div>
        <ReactTable
          data={services}
          style={tableStyles}
          columns={servicesColumns}
          showPagination={false}
          noDataText="Nenhum serviço não iniciado encontrado"
          loading={false}
          loadingText="Carregando..."
        />

        <div className="flex end mt-2 gap-075">
          <button
            className="button button-red button-h35"
            onClick={clearAllSelections}
          >
            Limpar
          </button>
          <button
            className="button button-green button-h35"
            onClick={handleAppointments}
            disabled={!hasServicesAndEmployeesSelected}
          >
            Apontar Mecânico(s)
          </button>
        </div>
      </div>
    </CreateAppointmentVariationContainer>
  );
}
