import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import {
  format,
  subMonths,
  differenceInCalendarDays,
  parseISO,
  addDays,
} from 'date-fns';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import pt from 'date-fns/locale/pt-BR';
import TableByType from './TableByType';
import UpgradeFreePlanModal from 'client/components/UpgradeFreePlanModal';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import LineChartByType from './LineChartByType';

import { useAuth } from 'contexts/auth';
import { usePlanSignatureContext } from 'contexts/plan-signature';

import salesRepository from '../../../../../repositories/Sales';

import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PorTipo = () => {
  const { companyId, user } = useAuth();
  const { isPlanFree } = usePlanSignatureContext();

  const [isUpgradeFreePlanModalOpen, setIsUpgradeFreePlanModalOpen] =
    useState(false);

  const [opacity, setOpacity] = useState('0%');
  const [loading, setLoading] = useState(false);
  const [isAccountAtLeast30DaysOld, setIsAccountAtLeast30DaysOld] =
    useState(false);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [showItems, setShowItems] = useState('0');
  const [saleType, setSaleType] = useState('');
  const [saleStatus, setSaleStatus] = useState('');

  const [lineChartData, setLineChartData] = useState([
    [{ type: 'date' }, 0, 0, 0],
  ]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (companyId) {
      checkAccountAge();
    }
  }, []);

  const handleProcess = () => {
    if (validateDate()) {
      if (companyId) loadReport();
    }
  };

  const validateDate = () => {
    if (!initialDate || !finalDate) {
      toastr.warning('Insira as duas datas para processar');
      return false;
    }

    let initYear = new Date(initialDate).getFullYear().toString();
    let finalYear = new Date(finalDate).getFullYear().toString();

    if (initYear.length > 4 || finalYear.length > 4) {
      toastr.warning('Ano inválido');
      return false;
    }

    let initTime = new Date(initialDate).getTime();
    let finalTime = new Date(finalDate).getTime();

    if (finalTime < initTime) {
      toastr.warning('Data Final deve ser maior que a Data Inicial');
      return false;
    }

    return true;
  };

  const loadReport = async () => {
    setLoading(true);

    try {
      const { salesReportTable, salesReportLineChart } =
        await salesRepository.getSalesByTypeReportUIVersion({
          initialDate,
          finalDate,
          saleType,
          saleStatus,
          companyId,
        });

      setTableData(salesReportTable);
      formatLineChart(salesReportLineChart);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  const formatLineChart = (salesReportLineChart) => {
    let lineChartData = [
      [
        { type: 'date' },
        'Orçamento',
        { role: 'tooltip', type: 'string' },
        'Venda',
        { role: 'tooltip', type: 'string' },
        'Ordem de Serviço',
        { role: 'tooltip', type: 'string' },
        'PDV',
        { role: 'tooltip', type: 'string' },
      ],
    ];

    let days = differenceInCalendarDays(
      parseISO(finalDate),
      parseISO(initialDate)
    );

    for (let i = 0; i <= days; i++) {
      let date = addDays(parseISO(initialDate), i);

      let dateFormatted = format(date, 'yyyy-MM-dd', { locale: pt });

      let budget = salesReportLineChart.find(
        (item) => item.Type === 'Orçamento' && item.Dia === dateFormatted
      );

      let sale = salesReportLineChart.find(
        (item) => item.Type === 'Venda' && item.Dia === dateFormatted
      );

      let serviceOrder = salesReportLineChart.find(
        (item) => item.Type === 'Ordem de Serviço' && item.Dia === dateFormatted
      );

      let pdv = salesReportLineChart.find(
        (item) => item.Type === 'PDV' && item.Dia === dateFormatted
      );

      lineChartData.push([
        date,
        budget ? budget.Total : 0,
        budget ? `${date} ${currency(budget.Total)}` : 'Sem informação',
        sale ? sale.Total : 0,
        sale ? `${date} ${currency(sale.Total)}` : 'Sem informação',
        serviceOrder ? serviceOrder.Total : 0,
        serviceOrder
          ? `${date} ${currency(serviceOrder.Total)}`
          : 'Sem informação',
        pdv ? pdv.Total : 0,
        pdv ? `${date} ${currency(pdv.Total)}` : 'Sem informação',
      ]);
    }

    setLineChartData(lineChartData);
    if (lineChartData.length > 1) {
      setOpacity(100);
    }
  };

  const checkAccountAge = () => {
    const accountAgeInCalendarDays = differenceInCalendarDays(
      new Date(),
      parseISO(user.createdAt)
    );
    setIsAccountAtLeast30DaysOld(accountAgeInCalendarDays >= 30);
  };

  const handleOpenReport = () => {
    if (validateDate()) {
      window.open(
        window.location.origin +
          `/report/sales-by-type?saleType=${saleType}&showItems=${showItems}&initialDate=${initialDate}&finalDate=${finalDate}&saleStatus=${saleStatus}`,
        '_blank'
      );
    }
  };

  return (
    <div className="relatorio-por-tipo-container">
      <header>
        <div>
          <span>Data Inicial:</span>
          <br />
          <input
            className="form-control foco-input"
            type="date"
            name="initialDate"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>

        <div>
          <span>Data Final:</span>
          <input
            className="form-control foco-input"
            type="date"
            name="finalDate"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>

        <div>
          <span>Tipo:</span>
          <select
            className="form-control foco-input"
            name="type"
            value={saleType}
            onChange={(e) => setSaleType(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="1">Orçamento</option>
            <option value="2">Venda</option>
            <option value="3">Ordem de Serviço</option>
            <option value="4">PDV</option>
          </select>
        </div>

        <div>
          <span>Status:</span>
          <select
            className="form-control foco-input"
            name="saleStatus"
            value={saleStatus}
            onChange={(e) => setSaleStatus(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="5">Finalizada</option>
            <option value="3">Em Aberto</option>
            <option value="2">Cancelada</option>
          </select>
        </div>

        <div>
          <span>Exibir itens nos detalhes:</span>
          <select
            className="form-control foco-input"
            name="showItems"
            value={showItems}
            onChange={(e) => setShowItems(e.target.value)}
          >
            <option value="0">Não</option>
            <option value="1">Sim</option>
          </select>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <button
            className="btn btn-sucesso"
            disabled={loading}
            onClick={handleProcess}
            style={{ height: '35px' }}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />
            Processar
          </button>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            className="component__pdf-button"
            type="submit"
            onClick={handleOpenReport}
            disabled={loading}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              style={{ marginRight: '5px' }}
            />
            Exportar .pdf
          </button>
        </div>
      </header>

      {isPlanFree && isAccountAtLeast30DaysOld && (
        <div className="unlock-badge-container">
          <span
            className="sales-report-unlock-badge"
            onClick={() => setIsUpgradeFreePlanModalOpen(true)}
          >
            Desbloquear dados antes de{' '}
            {format(subMonths(new Date(), 1), "dd 'de' MMMM", {
              locale: pt,
            })}
          </span>
        </div>
      )}

      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
          }}
        >
          <TableByType data={tableData} loading={loading} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '5%',
            width: '50%',
          }}
        >
          <LineChartByType data={lineChartData} opacity={opacity} />
        </div>
      </section>

      <UpgradeFreePlanModal
        show={isUpgradeFreePlanModalOpen}
        onCancel={() => setIsUpgradeFreePlanModalOpen(false)}
      />
    </div>
  );
};

export default PorTipo;
