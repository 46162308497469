import { StyleSheet } from '@react-pdf/renderer';

export const customChecklistSheetStyles = StyleSheet.create({
  mainTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span: {
    fontSize: 9,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  box: {
    width: 10,
    height: 10,
    borderWidth: 2,
    marginRight: 2,
  },
  underline: {
    fontWeight: 'bold',
    textDecorationStyle: 'solid',
    textDecoration: 'underline',
  },
  customerRow: {
    flexDirection: 'row',
    height: 30,
    paddingTop: 1,
    paddingLeft: 3,
  },
  customerRow1: {
    paddingTop: 1,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  cardTitle: {
    marginTop: 7,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  checkBorder: {
    borderColor: 'black',
    borderWidth: 1.5,
    width: '15px',
    borderRadius: 2,
  },
  detailsLeft50: {
    width: '50%',
  },
  detailsLeft: {
    width: '70%',
  },
  detailsTable: {
    width: '70%',
  },
  detailsTable50: {
    width: '50%',
  },
  detailsRightContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  tableItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    marginTop: -1.2,
  },
  tableItemText: {
    width: 365,
    height: 11.5,
    fontSize: 8,
    borderWidth: 1,
    borderColor: '#444444',
    paddingTop: 1,
    paddingLeft: 4,
  },
  tableItemStatus: {
    width: 25,
    borderWidth: 1,
    borderColor: '#444444',
    marginLeft: -1.2,
  },
  withoutMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  withMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 2,
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 15,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  gasImg: {
    width: 100,
  },
  carImg: {
    width: '300px',
    height: '190px',
    paddingLeft: 80,
  },
  observationsCard: {
    width: '33%',
    marginLeft: '5px',
    marginTop: '5px',
  },
  line2: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: 'black',
  },
  bikeImg: {
    width: 180,
  },
});
