import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import NewSAT from './FormNewSAT';
import constants from '../../../../utils/constants'

export default function FormSAT({ match, history, location }) {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <BreadCrumb data={['Início', 'Minha Empresa', 'Dados Fiscais', !!match.params.id ? 'Edição de SAT' : 'Novo SAT']}
              path={[constants.ROUTES.HOME, constants.ROUTES.COMPANIES, constants.ROUTES.COMPANY_TAX_DATA, null]} /> 
            <span style={{color: 'red', position: 'absolute', right: 10}}>* Campos Obrigatórios</span>
          </div>
          <NewSAT id={match.params.id} submit={() => history.push(constants.ROUTES.COMPANY_TAX_DATA)} />
        </Row>
      </Grid>
    </div>
  );
};