import React from "react";
import { Grid, Row } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import { withRouter } from "react-router";

import FormMyCompanyTaxData from './FormCompanyTaxData'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../utils/constants'

const MyCompanyTaxData = ({ history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Minha Empresa', 'Dados Fiscais']}
            path={[constants.ROUTES.HOME, constants.ROUTES.COMPANIES, null]}
          />
            <FormMyCompanyTaxData onCancel={() => history.push(constants.ROUTES.COMPANIES)}/>
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(MyCompanyTaxData)