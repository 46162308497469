import api from 'services/api'

const getAllByCompanyId = async (companyId) => {
  const res = await api.get(`/maintenance-alert?companyId=${companyId}`)
  return res.data
}

const update = async (id, values) => {
  const res = await api.put(`/maintenance-alert/${id}`, values)
  return res.data
}

const visualizeAll = async (companyId, values) => {
  const res = await api.put(
    `/maintenance-alert/by-company/${companyId}`,
    values
  )
  return res.data
}

const MaintenanceAlertRepository = {
  update,
  visualizeAll,
  getAllByCompanyId,
}

export default MaintenanceAlertRepository
