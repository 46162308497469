import React from 'react'

import {
  faAddressCard,
  faListUl,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'

import { Step } from 'react-step-progress-bar'
import FinancingProgressBar from './ProgressBar'

import FinancingStep from './Step'

const FinancingStepWizardFull = ({ percent }) => (
  <FinancingProgressBar percent={percent}>
    <Step transition="scale">
      {({ accomplished }) => (
        <FinancingStep accomplished={accomplished} icon={faAddressCard} />
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <FinancingStep accomplished={accomplished} icon={faListUl} />
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <FinancingStep accomplished={accomplished} icon={faCheck} />
      )}
    </Step>
  </FinancingProgressBar>
)

export default FinancingStepWizardFull
