import React from 'react'
import './styles.css'

const AdvancedSearchButton = ({ onClick, disabled, isLoading }) => {
  return (
    <button
      className="component__advanced-search-button"
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} />{' '}
      Busca Avançada
    </button>
  )
}

export default AdvancedSearchButton
