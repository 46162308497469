import config from '../config'
import api from '../services/api'

const getAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `sat?companyId=${companyId}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const cancel = async (satId) => {
  try {
    const response = await api.patch(config.endpoint + `sat/cancel/${satId}`)
    return response.data
  } catch (err) {
    throw err
  }
}

const consult = async (type, satId) => {
  try {
    const response = await api.put(
      config.endpoint +
        `sat/${satId}/consult${type !== 'normal' ? '-contingency' : ''}`
    )
    return response.data
  } catch (err) {
    throw err
  }
}

const deleteSATCFe = async (id) => {
  try {
    const response = await api.delete(config.endpoint + `sat/${id}`)
    return response.data
  } catch (err) {
    throw err
  }
}

const disabledSATCFe = async (params) => {
  try {
    const response = await api.post(config.endpoint + 'sat/disable', params)
    return response.data
  } catch (err) {
    throw err
  }
}

const search = async (params) => {
  const response = await api.post(config.endpoint + 'sat/search', params)
  return response.data
}

const generateBySale = async (saleId) => {
  const response = await api.post(config.endpoint + 'sat', {
    saleId,
  })
  return response.data
}

const show = async (id) => {
  const response = await api.get(config.endpoint + `sat/${id}`)
  return response.data
}

const getByIdToPrint = async (id) => {
  const response = await api.get(config.endpoint + `sat/${id}/print`)
  return response.data
}

const updateItem = async (satItemId, values) => {
  await api.put(config.endpoint + `sat-items/${satItemId}`, values)
}

const emit = async (id, { includeCpfCnpj, customerCpfCnpj, issuedAt }) => {
  const response = await api.put(config.endpoint + `sat/${id}/emit`, {
    includeCpfCnpj,
    customerCpfCnpj,
    issuedAt,
  })
  return response.data
}

const getsatPdv = async (companyId) => {
  const response = await api.get(config.endpoint + `sat/${companyId}/by-pdv`)
  return response.data
}

export default {
  getAllByCompany,
  cancel,
  consult,
  deleteSATCFe,
  disabledSATCFe,
  search,
  generateBySale,
  show,
  updateItem,
  emit,
  getByIdToPrint,
  getsatPdv,
}
