import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const PDFButton = ({
  onClick,
  isLoading,
  disabled,
  height,
  width,
  fontSize = 14,
}) => {
  return (
    <>
      <button
        className="component__pdf-button"
        type="submit"
        onClick={onClick}
        disabled={disabled}
        style={{
          height: `${height}px`,
          width: `${width}px`,
          fontSize: `${fontSize}px`,
        }}
      >
        <span
          className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
        />{' '}
        <FontAwesomeIcon
          icon={faFilePdf}
          size="lg"
          style={{ marginRight: '5px' }}
        />
        Exportar .pdf
      </button>
    </>
  );
};

export default PDFButton;
