import Button from 'client/components/CustomButton/Button'
import { format } from 'date-fns'
import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize'
import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { currency, percentage } from 'client/components/ToNormalize/ToNormalize'

const PartsPerApplicationTable = ({
  productsType,
  isLoading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 15,
          fontSize: '12px',
        }}
        data={productsType}
        columns={[
          {
            Header: 'Código',
            name: 'Código Interno',
            accessor: 'code',
            width: 90
          },
          {
            Header: 'Ref. Fabricante',
            name: 'Ref. Fabricante',
            accessor: 'manufacturerRef',
            width: 130
          },
          {
            Header: 'Descrição',
            name: 'Descrição',
            accessor: 'descriptionProduct',
          },
          {
            Header: 'Família',
            name: 'Família',
            accessor: 'descriptionFamilies',
            width: 130
          },
          {
            Header: 'Marca',
            name: 'Marca',
            accessor: 'descriptionBrands',
            width: 120
          },
          {
            Header: 'Qtd',
            name: 'Qtd',
            accessor: 'stockQuantity',
            width: 77
          },
          {
            Header: 'Preço de Compra',
            name: 'Preço de Compra',
            accessor: 'purchasePrice',
            width: 180,

          },
          {
            Header: 'Margem',
            name: 'Margem',
            accessor: 'profitMargin',
            width: 120,

          },
          {
            Header: 'Preço de Venda',
            name: 'Preço de Venda',
            accessor: 'salePrice',
            width: 230,

          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={isLoading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'Company_Name',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum produto encontrado."
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default PartsPerApplicationTable
