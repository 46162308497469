import React, { useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import CardForm from 'client/components/CardForm'
import ReactTable from 'react-table'
import Button from 'client/components/CustomButton/Button.jsx'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { toastr } from 'react-redux-toastr'
import { format } from 'date-fns'

import { useAuth } from 'contexts/auth'

import MateraTransferModal from '../MateraTransferModal'

import materaCompanyRepository from '../../../../../repositories/MateraCompany'

import { phoneMask } from '../../../../components/ToNormalize/ToNormalize'

import '../styles.css'

const MateraAccountExtract = ({
  info,
  loading,
  accountId,
  loadMateraInformation,
}) => {
  const [isExtractOpen, setIsExtractOpen] = useState(true)
  const [loadingExtract, setLoadingExtract] = useState(false)
  const [materaTransactions, setMateraTransactions] = useState([])
  const [showTransferModal, setShowTransferModal] = useState(false)

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const { companyId } = useAuth()

  async function handleProcessExtract() {
    try {
      setLoadingExtract(true)
      if (initialDate > finalDate) {
        return toastr.warning('Selecione um período válido e tente novamente.')
      }

      const { data } = await materaCompanyRepository.getExtract(companyId, {
        initialDate,
        finalDate,
      })

      setMateraTransactions(data.data)
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao buscar o extrato da conta. Tente novamente ou contate o suporte!'
      )
      console.log(err.message)
    } finally {
      setLoadingExtract(false)
    }
  }

  return (
    <CardForm
      title='Extrato'
      show={isExtractOpen}
      onClick={() => setIsExtractOpen(!isExtractOpen)}
    >
      <header style={{ display: 'flex' }}>
        <section style={{ marginRight: '5px' }}>
          <label style={{ fontWeight: 'bold' }}>Data Inicial:</label>
          <input
            type='date'
            className='form-control foco-input'
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </section>
        <section style={{ marginRight: '5px' }}>
          <label style={{ fontWeight: 'bold' }}>Data Final:</label>
          <input
            type='date'
            className='form-control foco-input'
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </section>

        <section style={{ marginTop: '38px' }}>
          <button
            className='btn btn-sucesso'
            type='button'
            style={{ marginRight: '20px' }}
            onClick={handleProcessExtract}
          >
            <span
              className={`${
                loadingExtract ? 'fa fa-spinner fa-pulse fa-1x' : ''
              }`}
            />{' '}
            Processar
          </button>

          <button
            className='btn'
            style={{
              backgroundColor: '#428bca',
              color: 'white',
              border: '1px solid #428bca',
            }}
            type='button'
            onClick={() => setShowTransferModal(true)}
          >
            <span
              className={`${
                loadingExtract ? 'fa fa-spinner fa-pulse fa-1x' : ''
              }`}
            />{' '}
            Transferência de Saldo
          </button>
        </section>
      </header>
      <main>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            width: '80%',
            margin: '16px 0',
          }}
          data={materaTransactions.statement}
          columns={[
            {
              Header: 'Data',
              accessor: 'entryDate',
              headerClassName: 'text-left',
              Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
              width: 200,
            },
            {
              Header: 'Tipo',
              accessor: 'type',
              headerClassName: 'text-left',
              width: 200,
              Cell: (props) => (
                <Button
                  id='botaoReactTable'
                  style={{
                    zIndex: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor:
                      props.value === 'C'
                        ? 'green'
                        : props.value === 'D'
                        ? 'red'
                        : 'gray',
                  }}
                >
                  {props.value === 'C'
                    ? 'Entrada'
                    : props.value === 'D'
                    ? 'Saída'
                    : 'Transferência'}
                </Button>
              ),
            },
            {
              Header: 'Valor',
              accessor: 'amount',
              headerClassName: 'text-left',
              width: 200,
              Cell: (props) => currency(props.value),
            },
            {
              Header: 'Observações',
              accessor: 'comment',
              headerClassName: 'text-left',
            },
          ]}
          defaultPageSize={10}
          showPagination={true}
          loading={loadingExtract}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText='Anterior'
          nextText='Próximo'
          loadingText='Carregando...'
          noDataText={'Selecione o período e gere o extrato de sua conta.'}
          pageText='Página'
          ofText='de'
          rowsText='linhas'
        />

        {showTransferModal ? (
          <MateraTransferModal
            show={showTransferModal}
            setShow={setShowTransferModal}
            loadMateraInformation={loadMateraInformation}
          />
        ) : (
          ''
        )}
      </main>
    </CardForm>
  )
}

export default MateraAccountExtract
