import React, { useEffect } from 'react';
import { useState } from 'react';
import NewPromotionalPriceData from './NewPromotionalPriceData';
import NewPromotionalPriceItems from './NewPromotionalPriceItems';
import '../styles.css';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import ItemsModal from 'client/views/Sales/NewSale/FormSale/ItemsModal';
import NewPromotionalPriceFormButtons from './NewPromotionalPriceFormButtons';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import PromotionalPriceTableRepository from 'repositories/PromotionalPriceTable';
import useFilters from 'hooks/useFilters';
import AlertModal from 'components/AlertModal';
import { format } from 'date-fns';

const NewPromotionalPriceMain = ({ history, id, hideDiscountColumn }) => {
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState(0);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [discountOption, setDiscountOption] = useState('retail');

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  const [loading, setLoading] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);

  const { companyId } = useAuth();

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      promotionalPriceTable: {},
      promotionalPriceTableItems: [],
    },
  });

  const { append, fields, replace } = useFieldArray({
    name: 'promotionalPriceTableItems',
    control,
  });

  const currentItems = useWatch({
    control,
    name: 'promotionalPriceTableItems',
  });

  const handleAppendItems = (items) => {
    const serializedItems = items.map((item) => ({
      ...item,
      Unit_Value: item?.originalPrices?.Sale_Price || item?.Unit_Value,
      promotionalPrice:
        item.Type === 'Serviço'
          ? item?.Unit_Value
          : item?.originalPrices?.Sale_Price || item?.Price,
      salePriceWholesale: item?.salePriceWholesale || item?.Price,
      promotionalPriceWholesale: item?.salePriceWholesale || item?.Price,
    }));

    replace([...currentItems, ...serializedItems]);
    setIsAddItemModalOpen(false);
  };

  const handleDeleteItem = (index) => {
    const newItems = currentItems.filter((_, i) => i !== index);
    replace(newItems);
  };

  const handleChangeSelectedDiscountValue = (selectedRows) => {
    const formattedDiscountValue = parseFloat(
      discountValue.replace(/[^0-9,]/g, '').replace(',', '.')
    );

    const newItems = fields.map((item, index) => {
      if (selectedRows.includes(index)) {
        if (discountType === 'percentage') {
          const retailDiscount =
            item.Unit_Value - item.Unit_Value * (formattedDiscountValue / 100);
          const wholesaleDiscount =
            item.salePriceWholesale -
            item.salePriceWholesale * (formattedDiscountValue / 100);

          if (discountOption === 'retail') {
            return { ...item, promotionalPrice: retailDiscount };
          } else if (discountOption === 'wholesale') {
            return { ...item, promotionalPriceWholesale: wholesaleDiscount };
          } else {
            return {
              ...item,
              promotionalPrice: retailDiscount,
              promotionalPriceWholesale: wholesaleDiscount,
            };
          }
        } else {
          const retailDiscount = item.Unit_Value - formattedDiscountValue;
          const wholesaleDiscount =
            item.salePriceWholesale - formattedDiscountValue;

          if (discountOption === 'retail') {
            return {
              ...item,
              promotionalPrice:
                retailDiscount < 0 ? item.promotionalPrice : retailDiscount,
            };
          } else if (discountOption === 'wholesale') {
            return {
              ...item,
              promotionalPriceWholesale:
                wholesaleDiscount < 0
                  ? item.promotionalPriceWholesale
                  : wholesaleDiscount,
            };
          } else {
            return {
              ...item,
              promotionalPrice:
                retailDiscount < 0 ? item.promotionalPrice : retailDiscount,
              promotionalPriceWholesale:
                wholesaleDiscount < 0
                  ? item.promotionalPriceWholesale
                  : wholesaleDiscount,
            };
          }
        }
      }
      return item;
    });

    replace(newItems);
  };

  const handleChangeAllDiscountValue = () => {
    const formattedDiscountValue = parseFloat(
      discountValue.replace(/[^0-9,]/g, '').replace(',', '.')
    );

    if (discountType === 'percentage') {
      const newItems = fields.map((item) => {
        const retailDiscount =
          item.Unit_Value - item.Unit_Value * (formattedDiscountValue / 100);
        const wholesaleDiscount =
          item.salePriceWholesale -
          item.salePriceWholesale * (formattedDiscountValue / 100);

        if (discountOption === 'retail') {
          return { ...item, promotionalPrice: retailDiscount };
        } else if (discountOption === 'wholesale') {
          return { ...item, promotionalPriceWholesale: wholesaleDiscount };
        } else {
          return {
            ...item,
            promotionalPrice: retailDiscount,
            promotionalPriceWholesale: wholesaleDiscount,
          };
        }
      });

      replace(newItems);
    } else {
      const newItems = fields.map((item) => {
        const retailDiscount = item.Unit_Value - formattedDiscountValue;
        const wholesaleDiscount =
          item.salePriceWholesale - formattedDiscountValue;

        if (discountOption === 'retail') {
          return {
            ...item,
            promotionalPrice:
              retailDiscount < 0 ? item.promotionalPrice : retailDiscount,
          };
        } else if (discountOption === 'wholesale') {
          return {
            ...item,
            promotionalPriceWholesale:
              wholesaleDiscount < 0
                ? item.promotionalPriceWholesale
                : wholesaleDiscount,
          };
        } else {
          return {
            ...item,
            promotionalPrice:
              retailDiscount < 0 ? item.promotionalPrice : retailDiscount,
            promotionalPriceWholesale:
              wholesaleDiscount < 0
                ? item.promotionalPriceWholesale
                : wholesaleDiscount,
          };
        }
      });

      const hasItemWithNegativePrice = newItems.some(
        (item) =>
          item.promotionalPrice < 0 || item.promotionalPriceWholesale < 0
      );
      if (hasItemWithNegativePrice) {
        return toastr.warning(
          'Não foi possível aplicar o desconto',
          'Um ou mais produtos ficarão com o valor menor que zero caso o desconto informado seja aplicado.'
        );
      }

      replace(newItems);
    }
  };

  const validations = (data) => {
    if (
      !initialDate ||
      !finalDate ||
      data.promotionalPriceTableItems.length === 0 ||
      !data.promotionalPriceTable.description
    ) {
      toastr.warning(
        'Não foi possível salvar a tabela.',
        'Preencha todos os campos e selecione ao menos um produto.'
      );
      setLoading(false);
      return false;
    }

    const withoutPromotionalPrice = data.promotionalPriceTableItems.filter(
      (item) => +item.promotionalPrice === 0 || isNaN(item.promotionalPrice)
    );

    if (withoutPromotionalPrice.length > 0) {
      toastr.warning(
        'Não foi possível salvar a tabela ',
        'Existe 1 produto ou mais com valor promocional zerado. Verifique e tenta novamente.'
      );
      setLoading(false);
      return false;
    }

    return true;
  };

  const loadPromotionalPriceTable = async () => {
    setLoading(true);
    try {
      const response = await PromotionalPriceTableRepository.getById(id);
      const { promotionalPriceTable, promotionalPriceTableItems } = response;

      setInitialDate(
        format(
          getDateOnlyFromDate(new Date(promotionalPriceTable.initialDate)),
          'yyyy-MM-dd'
        )
      );
      setFinalDate(
        format(
          getDateOnlyFromDate(new Date(promotionalPriceTable.finalDate)),
          'yyyy-MM-dd'
        )
      );
      setValue(
        'promotionalPriceTable.description',
        promotionalPriceTable.description
      );
      const formattedTableData = promotionalPriceTableItems.map((item) => ({
        ...item,
        Product_id: item.productId,
        Service_id: item.serviceId,
        Unit_Value: item.price,
        promotionalPrice: parseFloat(item.promotionalPrice),
        promotionalPriceWholesale: parseFloat(item.promotionalPriceWholesale),
        salePriceWholesale: parseFloat(item.priceWholesale),
        Code: item.code,
        Description: item.description,
        Manufacturer_Ref: item.manufacturerRef,
        Family: item.family,
        Brand: item.brand,
      }));

      append(formattedTableData);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar a tabela de preços promocionais',
        'Por favor, tente novamente'
      );
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const formattedData = !id
        ? formatCreateData(data)
        : formatUpdateData(data);

      if (!validations(formattedData)) return;

      if (id) {
        await PromotionalPriceTableRepository.updateTable(id, formattedData);
        toastr.success(
          'A tabela de preço promocional foi atualizada!',
          'Ative assim que possível para usar os preços.'
        );
        history.push('/client/promotional-price-table');
        return;
      } else {
        await PromotionalPriceTableRepository.create(formattedData);
        toastr.success(
          'Tabela cadastrada com sucesso!',
          'A tabela de preço promocional foi cadastrada. Ative assim que possível para usar os preços.'
        );
        history.push('/client/promotional-price-table');
        return;
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível salvar a tabela de preços promocionais',
        'Verifique os dados e tente novamente'
      );
    }
    setLoading(false);
  };

  const formatCreateData = (data) => {
    return {
      promotionalPriceTable: {
        initialDate: initialDate,
        finalDate: finalDate,
        description: data.promotionalPriceTable.description,
        companyId,
      },
      promotionalPriceTableItems: data.promotionalPriceTableItems?.map(
        (item) => ({
          productId: item.Type === 'Produto' ? item.Product_id : null,
          serviceId:
            item.Type === 'Serviço' || item.Type === undefined
              ? item.Product_id || item.productId
              : null,
          promotionalPrice: item.promotionalPrice,
          promotionalPriceWholesale: item.promotionalPriceWholesale,
        })
      ),
    };
  };

  const formatUpdateData = (data) => {
    return {
      promotionalPriceTable: {
        initialDate: initialDate,
        finalDate: finalDate,
        description: data.promotionalPriceTable.description,
        companyId,
      },
      promotionalPriceTableItems: data.promotionalPriceTableItems?.map(
        (item) => ({
          productId: item.Product_id,
          serviceId: item.Service_id,
          promotionalPrice: item.promotionalPrice,
          promotionalPriceWholesale: item.promotionalPriceWholesale,
        })
      ),
    };
  };

  const handleCancelSubmit = () => {
    if (isDirty) {
      setIsCancelModalOpen(true);
    } else {
      history.push('/client/promotional-price-table');
    }
  };

  const handleFilters = (desc) => {
    const querySearch = [
      desc['Description'],
      desc['Code'],
      desc['Manufacturer_Ref'],
    ];
    return filterByQuery(querySearch);
  };

  const filteredItems = fields.filter(handleFilters);

  useEffect(() => {
    if (id) {
      loadPromotionalPriceTable();
    }
  }, []);

  return (
    <>
      <div className="new-promotional-price__page">
        <NewPromotionalPriceData
          initialDate={initialDate}
          finalDate={finalDate}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          control={control}
          register={register}
        />
        <NewPromotionalPriceItems
          setQuery={setQuery}
          loading={loading}
          setDiscountType={setDiscountType}
          setDiscountValue={setDiscountValue}
          discountValue={discountValue}
          discountType={discountType}
          hasId={id}
          control={control}
          data={filteredItems}
          setIsAddItemModalOpen={setIsAddItemModalOpen}
          handleDeleteItem={handleDeleteItem}
          handleChangeSelectedDiscountValue={handleChangeSelectedDiscountValue}
          handleChangeAllDiscountValue={handleChangeAllDiscountValue}
          discountOption={discountOption}
          setDiscountOption={setDiscountOption}
        />
        <NewPromotionalPriceFormButtons
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => handleCancelSubmit()}
          hasId={id}
          loading={loading}
        />
      </div>

      {isAddItemModalOpen && (
        <ItemsModal
          hideDiscountColumn={hideDiscountColumn}
          onCancel={() => setIsAddItemModalOpen(false)}
          onSubmit={handleAppendItems}
          alreadyAddedItems={fields}
        />
      )}
      {isCancelModalOpen && (
        <AlertModal
          show={isCancelModalOpen}
          title="OS Digital"
          subtitle="Deseja realmente cancelar o cadastro da tabela de preços promocionais?"
          message="Caso cancele sem salvar, todas as alterações feitas até aqui serão perdidas."
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={() => history.push('/client/promotional-price-table')}
          onHide={() => setIsCancelModalOpen(false)}
        />
      )}
    </>
  );
};

export default NewPromotionalPriceMain;
