import { percentage } from 'client/components/ToNormalize/ToNormalize';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import React from 'react'
import ReactTable from 'react-table';

const ProductsWithoutMovementTable = ({
  loading,
  products,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
  totalProducts,
}) => {

  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: '20px',
        }}
        data={products}
        columns={[
          {
            Header: 'Código',
            accessor: 'productCode',
            width: 70,
          },
          {
            Header: 'Ref. Fabricante',
            accessor: 'productManufacturerRef',
            width: 120,
          },
          {
            Header: 'Descrição',
            accessor: 'productDescription',
          },
          {
            Header: 'Família',
            accessor: 'familyDescription',
            width: 130,
          },
          {
            Header: 'Marca',
            accessor: 'brandDescription',
            width: 100,
          },
          {
            Header: 'Estoque',
            accessor: 'productQuantity',
            width: 135,
          },
          {
            Header: 'Preço de Compra',
            accessor: 'productPurchasePrice',
            width: 120,
            Cell: (cell) => currency(cell.value),
          },
          {
            Header: 'Margem',
            accessor: 'productMargin',
            width: 120,
            Cell: (cell) => percentage(cell.value),
          },
          {
            Header: 'Preço de Venda',
            accessor: 'productSalePrice',
            width: 130,
            Cell: (cell) => currency(cell.value)
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'Company_Name',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Selecione um período e os filtros que deseja para fazer a pesquisa."
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default ProductsWithoutMovementTable;
