import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import { nfseHelper } from 'v2/helpers/invoiceHelpers';

const ShareNFSe = (props) => {
  const [shareOption, setShareOption] = useState(0);
  const [loadingDownloadXML, setLoadingDownloadXML] = useState(false);

  const options = [
    {
      key: 'XML',
      icon: (
        <FontAwesomeIcon
          icon={faDownload}
          className="mailIcon"
          aria-hidden="false"
          title="Baixar XML"
        />
      ),
      text: (
        <>
          <span id="optionText">
            Download XML &nbsp;
            {loadingDownloadXML && <span class="fa fa-spinner fa-pulse"></span>}
          </span>
        </>
      ),
      onClick: () => handleXMLDownloadButton(),
    },
  ];

  const renderModalTitle = (option) => {
    switch (option) {
      case 0:
        return 'Opções de compartilhamento';
      default:
        return 'Opções de compartilhamento';
    }
  };

  const renderModalBody = (option) => {
    switch (option) {
      case 0:
        return indexBody();
      default:
        return indexBody();
    }
  };

  const renderModalFooter = (option) => {
    if (option === 0) return indexFooter();
    return optionFooter();
  };

  const optionFooter = () => {
    return (
      <div>
        <CustomButton
          style={{
            marginTop: '5px',
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
          }}
          onClick={() => handleClose()}
          type="button"
          id="cancelar"
          pullRight
          fill
        >
          Cancelar
        </CustomButton>
      </div>
    );
  };

  const indexFooter = () => {
    return (
      <CustomButton
        style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
        onClick={() => props.onHide()}
        type="button"
        bsStyle="danger"
        pullRight
        fill
      >
        Cancelar
      </CustomButton>
    );
  };

  const indexBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.key}
              className="cardOption"
              id={op.key}
              onClick={op.onClick}
            >
              <span style={{ width: '80px' }}>{op.icon}</span>
              {op.text}
            </div>
          );
        })}
      </div>
    );
  };

  const handleXMLDownloadButton = async () => {
    setLoadingDownloadXML(true);
    try {
      const xmlUrl =
        props.nfse.docXMLDownload ||
        nfseHelper.handleConstructXMLLinkFromPDFLink(props.nfse.docPDFDownload);
      if (xmlUrl) {
        window.open(xmlUrl, '_blank');
      } else {
        toastr.error('URL do XML não encontrada');
      }
    } catch (e) {
      toastr.error(e);
    } finally {
      setLoadingDownloadXML(false);
    }
  };

  const handleClose = () => {
    setShareOption(0);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => handleClose()}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{renderModalTitle(shareOption)}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody(shareOption)}</Modal.Body>
      <Modal.Footer>{renderModalFooter(shareOption)}</Modal.Footer>
    </Modal>
  );
};

export default ShareNFSe;
