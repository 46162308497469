import React, { useState } from 'react';
import Product from './ProductInfo';
import Item from '../Item';
import { Totals } from '../Totals';
import { GeneralDiscount } from '../GeneralDiscount';
import './styles.scss';
import { useSelector } from 'react-redux';

const SelectProducts = () => {
  const [updateTotalPdvTrigger, setUpdateTotalPdvTrigger] = useState(false);

  const { step } = useSelector((state) => state.form.pdvOnline.values);

  return (
    <div id="select-products-step">
      <Product setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger} />
      <div className="product-list">
        <Item setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger} />
        <footer>
          <GeneralDiscount
            updateTotalPdvTrigger={updateTotalPdvTrigger}
            setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger}
          />
          {step === 2 && <Totals />}
        </footer>
      </div>
    </div>
  );
};

export default SelectProducts;
