import React from "react";
import { Grid, Row } from "react-bootstrap";

import PendingCustomersSuppliersMain from './components/PendingCustomersSuppliersMain'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const PendingCustomersSuppliers = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Financeiros', 'Listagem de Pendências de Cliente/Fornecedor']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <PendingCustomersSuppliersMain />
        </Row>
      </Grid>
    </div>
  );
}
export default PendingCustomersSuppliers