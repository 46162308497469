import React, { useEffect, useState } from 'react';

import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';

import { useForm, Controller } from 'react-hook-form';

import AppointmentsRepository from 'repositories/AppointmentsRepository';
import InputMask from 'react-input-mask';
import AlertModal from 'components/AlertModal/AlertModal';
import { percentage } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { format } from 'date-fns';

import {
  faEdit,
  faTrashAlt,
  faTimes,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AppointmentsList({ sale, saleId, salesItems }) {
  const [appointments, setAppointments] = useState([]);

  const { companyId } = useAuth();

  const { control, setValue, getValues, reset } = useForm({
    defaultValues: {
      newWorkedHours: '',
    },
  });

  const [loading, setLoading] = useState(false);

  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [appointmentToEditIndex, setAppointmentToEditIndex] = useState(null);

  function handleCloseDeleteConfirmationModal() {
    setAppointmentToDelete(null);
    setIsDeleteConfirmationModalOpen(false);
  }

  function validateWorkedHours(value) {
    const valueWithoutMask = value.replaceAll('_', '').replace(':', '');
    if (valueWithoutMask.length === 5) return true;
    return false;
  }

  async function handleUpdateAppointment() {
    const appointment = appointments[appointmentToEditIndex];
    const { newWorkedHours: newValue } = getValues();

    if (!newValue) {
      setIsEditing(false);
      setAppointmentToEditIndex(null);
      return;
    }

    if (!validateWorkedHours(newValue)) {
      return toastr.warning(
        'Atenção',
        'Insira as horas trabalhadas em um formato válido (hhh:mm)'
      );
    }

    try {
      await AppointmentsRepository.update(appointment.id, newValue);
      setAppointments(
        appointments.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              workedHours: newValue,
            };
          } else {
            return item;
          }
        })
      );
      setIsEditing(false);
      setAppointmentToEditIndex(null);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao editar as horas trabalhadas. Tente novamente!'
      );
    }
  }

  async function handleDeleteAppointment() {
    setLoading(true);
    try {
      await AppointmentsRepository.deleteAppointment(appointmentToDelete);
      setAppointments(
        appointments.filter((item) => item.id !== appointmentToDelete)
      );
      loadAppointments();
      toastr.success('Apontamento excluído com sucesso!');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao excluir o apontamento. Tente novamente!'
      );
    } finally {
      setLoading(false);
      handleCloseDeleteConfirmationModal();
    }
  }

  async function loadAppointments() {
    try {
      setLoading(true);
      const appointments = await AppointmentsRepository.getBySale(
        saleId,
        companyId
      );
      setAppointments(appointments);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro ao buscar os apontamentos. Tente novmanete!'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadAppointments();
  }, []);

  useEffect(() => {
    if (appointmentToDelete) {
      setIsDeleteConfirmationModalOpen(true);
    }
  }, [appointmentToDelete]);

  useEffect(() => {
    if (appointmentToEditIndex !== null) {
      setValue(
        'newWorkedHours',
        appointments[appointmentToEditIndex].workedHours.slice(0, 6)
      );
    }
  }, [appointmentToEditIndex]);

  return (
    <div
      style={{
        border: '1px solid #c0c0c0',
        padding: '10px',
        marginTop: 0,
      }}
    >
      <div
        style={{
          display: 'grid',
        }}
      >
        <div>
          <p>
            <strong>Comissões apontadas:</strong>
          </p>

          <ReactTable
            data={appointments}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%',
              fontSize: '14px',
            }}
            columns={[
              {
                Header: 'Funcionário',
                accessor: 'Employee',
                resizable: false,
                width: 300,
                Cell: (props) => {
                  return <>{props.value.name}</>;
                },
              },
              {
                Header: 'Descrição',
                accessor: 'SalesItem',
                resizable: false,
                Cell: (props) => {
                  return <>{props.value.Description}</>;
                },
              },
              {
                Header: 'Qtd',
                accessor: 'SalesItem',
                resizable: false,
                width: 80,
                Cell: (props) => {
                  return <>{props.value.Quantity}</>;
                },
              },
              {
                Header: 'Comissão',
                accessor: 'commissionRate',
                resizable: false,
                width: 80,
                Cell: (props) => {
                  return props.value === null
                    ? percentage(0)
                    : percentage(parseFloat(props.value));
                },
              },
              {
                Header: 'Hrs Trabalhadas',
                accessor: 'workedHours',
                resizable: false,
                width: 150,
                Cell: (props) => {
                  return (
                    <>
                      {isEditing && appointmentToEditIndex === props.index ? (
                        <>
                          <Controller
                            name={`newWorkedHours`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <InputMask
                                  mask={'999:59'}
                                  formatChars={{
                                    9: '[0-9]',
                                    5: '[0-5]',
                                  }}
                                  className="form-control"
                                  {...field}
                                />
                              );
                            }}
                          />
                        </>
                      ) : (
                        props.value
                      )}
                    </>
                  );
                },
              },
              {
                Header: 'Hrs Recomendadas',
                accessor: 'SalesItem',
                resizable: false,
                width: 150,
                Cell: (props) => {
                  return <>{props.value.Services?.Hours_Expected}</>;
                },
              },
              {
                Header: 'Data',
                accessor: 'createdAt',
                resizable: false,
                width: 150,
                Cell: (props) => {
                  return (
                    <>
                      {format(
                        getDateOnlyFromDate(new Date(props.value)),
                        'dd/MM/yyy'
                      )}
                    </>
                  );
                },
              },
              {
                Header: '',
                accessor: 'id',
                resizable: false,
                width: 70,
                Cell: (props) => {
                  return (
                    <>
                      {isEditing && appointmentToEditIndex === props.index ? (
                        <>
                          <FontAwesomeIcon
                            title="Confirmar"
                            color="green"
                            icon={faCheckSquare}
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                              marginBottom: -1,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleUpdateAppointment();
                            }}
                          />
                          &nbsp;
                          <FontAwesomeIcon
                            title="Cancelar Edição"
                            color="red"
                            icon={faTimes}
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                              marginBottom: -1,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAppointmentToEditIndex(null);
                              setIsEditing(false);
                              reset();
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {props.row.SalesItem.Services && (
                            <FontAwesomeIcon
                              title="Editar Apontamento"
                              color="black"
                              icon={faEdit}
                              style={{
                                height: '1.2em',
                                width: '1.2em',
                                marginBottom: -1,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                reset();
                                setAppointmentToEditIndex(props.index);
                                setIsEditing(true);
                              }}
                            />
                          )}
                          &nbsp;
                          <FontAwesomeIcon
                            title="Excluir Apontamento"
                            color="red"
                            icon={faTrashAlt}
                            style={{
                              height: '1.2em',
                              width: '1.6em',
                              marginBottom: -1,
                              cursor: 'pointer',
                              alignSelf: 'end',
                            }}
                            onClick={() => {
                              setAppointmentToDelete(props.original.id);
                            }}
                          />
                        </>
                      )}
                    </>
                  );
                },
              },
            ]}
            defaultPageSize={5}
            previousText="Anterior"
            nextText="Próximo"
            noDataText="Nenhum apontamento encontrado"
            loadingText="Carregando..."
            pageText="Página"
            ofText="de"
            rowsText="linhas"
            loading={loading}
          />
        </div>
      </div>

      <AlertModal
        show={isDeleteConfirmationModalOpen}
        onHide={handleCloseDeleteConfirmationModal}
        onCancel={handleCloseDeleteConfirmationModal}
        onSubmit={() => handleDeleteAppointment()}
        loading={loading}
        message={
          <>
            <span>
              <strong>Você deseja excluir o apontamento?</strong>
            </span>
            <br />
            <br />
            <span>
              Ao excluir o apontamento, o funcionário não será comissionado em
              cima da ação realizada. Você poderá fazer um novo apontamento, se
              necessário.
            </span>
          </>
        }
      />
    </div>
  );
}
