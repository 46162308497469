import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import HomeTitleListing from './TitleListing'

const TitleListing = () => {
  localStorage.setItem('selectedPath', 'client/title-listing')
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Financeiro', 'Listagem de Títulos']}
            path={['home', null, null, null]}
          />
          <HomeTitleListing />
        </Row>
      </Grid>
    </div>
  )
}
export default TitleListing
