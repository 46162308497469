import api from '../services/api';

const index = (filters) =>
  api.get('crm-bonus-config', {
    params: filters,
  });

const show = async (id) => {
  const response = await api.get('/crm-bonus-config/' + id);
  return response.data;
};

const findByCompany = async (companyId) => {
  const response = await api.get('/crm-bonus-config/companyId/' + companyId);
  return response.data;
};

const create = async (data) => {
  const response = await api.post('/crm-bonus-config', data);
  return response.data;
};

const update = async (id, data) => {
  const response = await api.put('/crm-bonus-config/' + id, data);
  return response.data;
};

export const CRMBonusRepositoryV2 = {
  index,
  show,
  create,
  update,
  findByCompany,
};
