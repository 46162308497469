import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';

import PollsTable from './PollsTable';
import Card from '../../../../components/Card/Card';

import { useAdminAuth } from 'contexts/adminAuth';

import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import pollsRepository from '../../../../repositories/Polls';
import PollsFilter from './PollsFilter';
import AlertModal from 'components/AlertModal';
import useDebounce from 'hooks/useDebounce';

function PollsMain({ history }) {
  const { admin } = useAdminAuth();

  const [loading, setLoading] = useState(false);
  const [polls, setPolls] = useState([]);

  const [branding, setBranding] = useState({ label: 'Todos', value: '' });
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  useEffect(() => {
    if (!!admin) {
      if (!!validations()) {
        loadPolls();
      }
    }
  }, [
    currentPage,
    queryDebounced,
    pageLimit,
    status,
    type,
    branding,
    initialDate,
    finalDate,
  ]);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  const loadPolls = async () => {
    setLoading(true);
    try {
      const polls = await pollsRepository.getAll({
        query: queryDebounced,
        status,
        type,
        branding: branding.value,
        initialDate,
        finalDate,
        limit: pageLimit,
        page: currentPage + 1,
      });

      setPolls(polls.data);
      setTotalPages(Math.ceil(polls.count[0].numberRows / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as pesquisas. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  const PollsStatusSwitch = ({ pollsId, brandingId, status }) => {
    const [confirmModal, setConfirmModal] = useState(false);

    const updateStatus = async () => {
      await pollsRepository.updateStatus(pollsId, !status);
      setPolls(
        polls.map((item) => {
          if (item.id === pollsId) {
            return { ...item, status: !status };
          } else {
            return item;
          }
        })
      );
    };

    const handleUpdateStatus = async () => {
      try {
        if (status) return updateStatus();

        const hasActivePolls = polls.some(
          (item) => item.status === true && item.brandingId === brandingId
        );

        if (!hasActivePolls) {
          setConfirmModal(true);
        } else {
          return toastr.warning(
            'Pesquisa não foi ativada',
            'Só é possível ativar uma pesquisa por vez e existe uma pesquisa ativa. Desative antes para ativar essa.'
          );
        }
      } catch (err) {
        return toastr.warning(
          'Pesquisa não foi ativa',
          'Ocorreu um erro ao atualizar pesquisa ' + err
        );
      }
    };

    return (
      <div>
        <Toggle
          style={{ position: 'fixed' }}
          checked={status}
          onChange={() => handleUpdateStatus(pollsId, !status)}
        />

        <AlertModal
          title="OS Digital"
          subtitle="Você tem certeza que deseja ativar a pesquisa?"
          show={confirmModal}
          message="Ao ativar, será exibida a partir desse momento para todos os clientes na home do OS Digital"
          onCancel={() => setConfirmModal(false)}
          onHide={() => setConfirmModal(false)}
          onSubmit={updateStatus}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <Card
      content={
        <div>
          <PollsFilter
            history={history}
            query={query}
            status={status}
            setStatus={setStatus}
            type={type}
            setType={setType}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            branding={branding}
            setBranding={setBranding}
            handleChangeQuery={handleChangeQuery}
          />

          <PollsTable
            loading={loading}
            polls={polls}
            PollsStatusSwitch={PollsStatusSwitch}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      }
    />
  );
}

export default withRouter(PollsMain);
