import React, { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import AlertModal from '../../../../../components/AlertModal/AlertModal'
import { useDispatch, useSelector } from 'react-redux'

import SimilarityMainData from './MainData'
import { useHistory } from 'react-router-dom'
import constants from '../../../../../utils/constants'

const FormSimilarity = ({ handleSubmit, loading, product, pristine, modal, hideModal }) => {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const { values } = useSelector(state => state).form.formSimilarity
  const { productId, similarProductId } = values

  const history = useHistory()
  
  const handleCancel = () => {
    if (pristine) return cancel()
    setIsAlertModalOpen(true)
  }

  const cancel = () => {
    if (modal) return hideModal()
    history.push(constants.ROUTES.SIMILARITIES)
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <SimilarityMainData productInfo={product} style={{ marginBottom: '20px'}}/>
        <div style={{ paddingTop: '20px', paddingRight: '10px'}}>
          <FormSubmitButtons loading={loading} onCancel={handleCancel} onSubmit={handleSubmit}/>
        </div>
      </form>

      <AlertModal
        show={isAlertModalOpen}
        onHide={() => setIsAlertModalOpen(false)}
        onCancel={() => setIsAlertModalOpen(false)}
        message={`Deseja cancelar o cadastro da similaridade? Todos os campos preenchidos não serão salvos e o cadastro poderá ser realizado posteriormente`}
        onSubmit={cancel}
      />
    </>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formSimilarity'
})(FormSimilarity)