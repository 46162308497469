import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import RepresentativeComissionsFilter from './RepresentativeComissionsFilter'
import { useAuth } from 'contexts/auth'
import TradeRepresentativeRepository from 'repositories/TradeRepresentative'
import RepresentativeComissionsRepository from 'repositories/RepresentativeComissions'

const RepresentativeComissionsMain = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [representativeId, setRepresentativeId] = useState('')
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [showBudgets, setShowBudgets] = useState(0)
  const [reportType, setReportType] = useState('synthetic')
  const [representativeList, setRepresentativeList] = useState([])

  const { companyId } = useAuth()

  const validations = () => {
    if (!finalDate || !initialDate) {
      toastr.warning(
        'Data final e inicial incorretas.',
        'Por favor, preencha os campos e tente novamente'
      )
      setIsLoading(false)
      return false
    }

    return true
  }

  const loadReport = async () => {
    setIsLoading(true)
    try {
      if (!validations()) return

      const params = {
        initialDate,
        finalDate,
        tradeRepresentativeId: representativeId,
        showBudgets: parseInt(showBudgets),
        reportType,
      }

      const representativeComissions =
        await RepresentativeComissionsRepository.getRepresentativeComissionsReport(
          companyId,
          params
        )

      if (!representativeComissions.length) {
        setIsLoading(false)
        return toastr.warning(
          'Não foi encontrado nenhum dado com os filtros selecionados.',
          'Verifique os filtros selecionados e tente novamente.'
        )
      }

      window.open(
        window.location.origin +
          `/report/representative-comissions?companyId=${companyId}&representativeId=${representativeId}&initialDate=${initialDate}&finalDate=${finalDate}&showBudgets=${showBudgets}&reportType=${reportType}`
      )

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      return toastr.error(
        'Ocorreu um erro ao carregar o PDF.',
        'Por favor, tente novamente.'
      )
    }
  }

  const LoadRepresentatives = async () => {
    setIsLoading(true)
    try {
      const representativesList =
        await TradeRepresentativeRepository.getActivesRepresentativesList(
          companyId
        )

      setRepresentativeList(representativesList)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      toastr.error(
        'Não foi possível carergar os representantes.',
        'Por favor, tente novamente.'
      )
    }
  }

  useEffect(() => {
    LoadRepresentatives()
  }, [])

  return (
    <>
      <RepresentativeComissionsFilter
        setRepresentative={setRepresentativeId}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setOrcaments={setShowBudgets}
        setType={setReportType}
        loadReport={loadReport}
        isLoading={isLoading}
        initialDate={initialDate}
        finalDate={finalDate}
        representativeList={representativeList}
      />
    </>
  )
}

export default RepresentativeComissionsMain
