import Button from 'client/components/CustomButton/Button';
import { format } from 'date-fns';
import React from 'react';
import ReactTable from 'react-table';
import QuotationsTableAction from './QuotationsTableAction';

const QuotationsTable = ({ loading, quotations, reloadQuotations }) => {
  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '12px',
        }}
        data={quotations}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 160,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Código',
            accessor: 'code',
            width: 150,
          },
          {
            Header: 'Fornecedores',
            accessor: 'QuotationProviders',
            Cell: (props) => {
              if (props?.value?.length === 0) {
                return 'Nenhum fornecedor';
              } else if (props?.value?.length > 1) {
                return `${props.value.length} fornecedores`;
              } else {
                return props?.value[0]?.Provider.companyName;
              }
            },
          },
          {
            Header: 'Qtd. Produtos',
            accessor: 'QuotationItems',
            Cell: (props) => props?.value?.length,
            width: 150,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  zIndex: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    props.value == 'Respondida'
                      ? '#5cb85c'
                      : props.value == 'Enviada'
                      ? '#428bca'
                      : props.value == 'Não Enviada'
                      ? '#f0ad4e'
                      : '#d9534f',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 100,

            Cell: (props) => (
              <QuotationsTableAction
                data={props.original}
                reloadQuotations={() => reloadQuotations()}
              />
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'code',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma cotação encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default QuotationsTable;
