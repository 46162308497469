import React, { useEffect, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import AlertModal from 'components/AlertModal/AlertModal';
import InputNumberDecimal from '../../../InputNumberDecimal';
import InputUnitValue from '../../../InputUnitValue';
import PercentInput from '../../../Percent';
import { currency, percentage } from '../../../ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { ModalDiscountReleaseWithPassword } from 'v2/components/Modals/DiscountRelease/ModalDiscountReleaseWithPassword';
import './styles.css';

const SaleSheet = ({
  items,
  setItems,
  clientName,
  allowPromotionalPrices,
  hideDiscountColumn,
}) => {
  const dispatch = useDispatch();
  const [
    isModalDiscountReleaseWithPasswordOpen,
    setIsModalDiscountReleaseWithPasswordOpen,
  ] = useState(false);
  const [total, setTotal] = useState(0);
  const [discounts, setDiscounts] = useState(0);
  const [percents, setPercents] = useState(0);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);
  const [itemToEditIndex, setItemToEditIndex] = useState(null);

  useEffect(() => {
    const total = items
      .map((c) =>
        c.Discount_Value > 0
          ? (c.Unit_Value - c.Unit_Value * (c.Discount_Value / 100)) *
            c.Quantity
          : c.Quantity * c.Unit_Value
      )
      .reduce((prev, curr) => prev + curr, 0);

    const discounts = items
      .filter((c) => c.Discount_Value > 0)
      .map((c) => c.Unit_Value * (c.Discount_Value / 100) * c.Quantity)
      .reduce((prev, curr) => prev + curr, 0);

    const totalWithoutDiscount = !items.length
      ? 1
      : items
          .map((c) => c.Unit_Value * c.Quantity)
          .reduce((prev, curr) => prev + curr, 0);

    let percents = 0;
    if (discounts !== 0 && totalWithoutDiscount !== 0) {
      percents = (discounts * 100) / totalWithoutDiscount;
    }

    setTotal(total);
    setDiscounts(discounts);
    setPercents(percents);
  }, [items]);

  const handleRemoveItem = () => {
    setIsRemoveItemModalOpen(false);
    const newItems = [...items].filter((_, i) => i !== indexToRemove);
    setItems(newItems);
  };

  const handleOpenRemoveItemModal = (index) => {
    setIndexToRemove(index);
    setIsRemoveItemModalOpen(true);
  };

  const handleQuantity = (value, index) => {
    const newItems = [...items];
    if (value <= 9999) {
      newItems[index].Quantity = value;
      setItems(newItems);
    }
  };

  const handlePorcentage = async (e, index) => {
    let value = Number(e.target.value);
    const newItems = [...items];

    newItems[index].Discount_Value = value > 99.99 ? 0 : value;
    setItems(newItems);
    setItemToEditIndex(index);
  };

  const handleUnityValue = (e, index) => {
    const unformattedValue = e.target.value;
    const parsedValue = parseFloat(
      unformattedValue.replace('R$ ', '').replace('.', '').replace(',', '.')
    );

    const newItems = [...items];

    if (parsedValue === 0)
      return toastr.warning('Não é possível informar um valor R$ 0,00.');

    if (parsedValue > 0) {
      newItems[index].Unit_ValueF = unformattedValue;
      newItems[index].Unit_Value = parsedValue;

      setItems(newItems);
    }
  };

  return (
    <Col className="folha-sale flex column between">
      <div>
        <table id="table-sale">
          <thead>
            <tr className="flex between align-center">
              <th style={{ width: '130px' }}>Descrição</th>
              <th style={{ width: '100px' }}>Qtde.</th>
              <th style={{ width: '90px' }}>R$ Unit.</th>
              {!hideDiscountColumn && (
                <th style={{ width: '90px' }}>Desc. (%)</th>
              )}
              <th style={{ width: '90px' }}>R$ Total</th>
              <th style={{ width: '20px' }}> </th>
            </tr>
          </thead>
          <tbody style={{ width: '100%' }}>
            {items.map((item, index) => (
              <tr className="flex between align-center" key={index}>
                <td
                  style={{
                    fontSize: '12px',
                    width: '140px',
                    overflowWrap: 'break-word',
                  }}
                >
                  {item.Description}
                </td>
                <td style={{ fontSize: '12px', width: '100px' }}>
                  <InputNumberDecimal
                    id="qtd-cotacao"
                    onChange={(e) => handleQuantity(e, index)}
                    value={item.Quantity}
                  />
                </td>
                <td
                  style={{
                    fontSize: '12px',
                    width: '90px',
                    textAlign: 'center',
                  }}
                >
                  <InputUnitValue
                    style={{
                      fontSize: '12px',
                      width: '90px',
                      textAlign: 'center',
                    }}
                    className="form-control foco-input"
                    onChangeEvent={(e) => handleUnityValue(e, index)}
                    value={item.Unit_Value}
                  />
                </td>
                {!hideDiscountColumn && (
                  <td style={{ width: '90px' }}>
                    <PercentInput
                      style={{
                        fontSize: '12px',
                        width: '90px',
                        textAlign: 'center',
                      }}
                      onChangeEvent={(e) => handlePorcentage(e, index)}
                      selectAllOnFocus
                      className="form-control foco-input"
                      value={item.Discount_Value}
                    />
                  </td>
                )}
                <td style={{ fontSize: '12px', width: '90px' }}>
                  {currency(
                    !item.Discount_Value
                      ? item.Unit_Value * item.Quantity
                      : item.Quantity *
                          (item.Unit_Value -
                            (item.Unit_Value * item.Discount_Value) / 100)
                  )}
                </td>
                <td style={{ width: '20px' }}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: 'red' }}
                    cursor="pointer"
                    onClick={() => handleOpenRemoveItemModal(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="folha-sale-totais-wrapper">
        <div className="folha-sale-totais">
          <strong>Itens: </strong>
          {items.length}
          <br />
          <strong>Desconto: </strong>
          <span style={{ color: 'red' }}>
            {currency(discounts)} ({percentage(percents)})
          </span>
          <br />
          <strong>Valor Total: </strong>
          <a style={{ color: 'green' }}>{currency(total)}</a>
        </div>
      </div>

      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        message="Deseja realmente excluir o item ?"
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />

      <ModalDiscountReleaseWithPassword
        open={isModalDiscountReleaseWithPasswordOpen}
        onClose={() => setIsModalDiscountReleaseWithPasswordOpen(false)}
        itemToEdit={items[itemToEditIndex]}
        itemToEditIndex={itemToEditIndex}
        items={items}
      />
    </Col>
  );
};

export default SaleSheet;
