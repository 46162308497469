import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import Button from '../../../../../components/CustomButton/CustomButton'
import CardForm from '../../../../../components/CardForm'
import CompanyTaxErrorsTable from './CompanyTaxErrorsTable'
import ClientErrorsTable from './Client'
import ProductErrorsTable from './ProductErrorsTable'

const StructureErrosModal = ({
  onCancel,
  onRetry,
  errors,
  setErrors,
  customerId,
}) => {
  const companyTaxErrors = errors.filter(
    (error) => error.card === 'Configurações Fiscais'
  )
  const clientErrors = errors.filter((error) => error.card === 'Cliente')
  const productErrors = errors.filter((error) => error.card === 'Produto')

  const isAnyErrorCorrected = errors.some((error) => error.isResolved)
  const { loadingUpdateErrorsModal } = useSelector(
    (state) => state.form.cadastroSATCFe.values
  )

  useEffect(() => {
    const isAllErrorsCorrected = errors.every((error) => error.isResolved)
    if (isAllErrorsCorrected) {
      onRetry()
    }
  }, [errors])

  function handleChangeClientErrors(clientErrors) {
    const newErrors = [...clientErrors, ...companyTaxErrors, ...productErrors]
    setErrors(newErrors)
  }

  function handleChangeCompanyTaxErrors(companyTaxErrors) {
    const newErrors = [...clientErrors, ...companyTaxErrors, ...productErrors]
    setErrors(newErrors)
  }

  function handleChangeProductErrors(productErrors) {
    const newErrors = [...clientErrors, ...companyTaxErrors, ...productErrors]
    setErrors(newErrors)
  }

  return (
    <>
      <Modal show animation dialogClassName='NFCe-errors-modal-wrapper'>
        <Modal.Header>
          <Modal.Title>
            <strong>Validação de Estrutura</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='NFCe-errors-modal-body'>
          <span>
            Identificamos {errors.length}{' '}
            {errors.length === 1 ? 'erro' : 'erros'} cadastrais que impedem o
            envio da nota fiscal. Realize as correções dos itens abaixo:{' '}
          </span>
          {!!companyTaxErrors.length && (
            <CardForm show title='Configurações Fiscais'>
              <CompanyTaxErrorsTable
                errors={companyTaxErrors}
                onCorrection={handleChangeCompanyTaxErrors}
              />
            </CardForm>
          )}
          {!!clientErrors.length && (
            <CardForm show title='Cliente'>
              <ClientErrorsTable
                errors={clientErrors}
                onCorrection={handleChangeClientErrors}
                customerId={customerId}
              />
            </CardForm>
          )}
          {!!productErrors.length && (
            <CardForm show title='Produto'>
              <ProductErrorsTable
                errors={productErrors}
                onCorrection={handleChangeProductErrors}
              />
            </CardForm>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='NFCe-errors-modal-footer'>
            <Button
              bsStyle='danger'
              fill
              onClick={onCancel}
              disabled={loadingUpdateErrorsModal}
            >
              Voltar{' '}
              {loadingUpdateErrorsModal ? (
                <i className='fa fa-spinner fa-pulse fa-1x' />
              ) : (
                ''
              )}
            </Button>
            {isAnyErrorCorrected && (
              <Button bsStyle='info' fill onClick={onRetry}>
                Reenviar SAT
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StructureErrosModal
