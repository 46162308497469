import constants from '../utils/constants';
import config from '../config';
import api from '../services/api';

const index = (queryParams) =>
  api.get(config.endpoint + 'bills-to-pay', {
    params: queryParams,
  });

const create = async (billToPay) => {
  try {
    const response = await api.post(
      config.endpoint + 'bills-to-pay',
      billToPay
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const bulkCreate = async (billsToPay) => {
  try {
    const response = await api.patch(
      config.endpoint + 'bills-to-pay',
      billsToPay
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (billToPay) => {
  try {
    const response = await api.put(
      config.endpoint + `bills-to-pay/${billToPay.titleId}`,
      billToPay
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getExpenseForecast = async ({ companyId, initialDate, finalDate }) => {
  try {
    const response = await api.get(
      `bills-to-pay/expense-forecast-report?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(
      config.endpoint + `bills-to-pay/by-id?id=${id}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `bills-to-pay?companyId=${companyId}`,
      { params }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompanyPerPeriod = async (
  companyId,
  initialDate,
  finalDate,
  status,
  dateType,
  accountPlanId
) => {
  try {
    const response = await api.get(
      `titles/bills-to-pay-list?companyId=${companyId}&initialDate=${initialDate}&finalDate=${finalDate}&status=${status}&dateType=${dateType}&accountPlanId=${accountPlanId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByPurchase = async (purchaseId, companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `bills-to-pay?purchaseId=${purchaseId}&companyId=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllClosedByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `bills-to-pay?companyId=${companyId}&billStatusId=${constants.BILLS_STATUS.CLOSED}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const reCloseByPurchase = async (purchaseId) => {
  await api.patch(config.endpoint + 'bills-to-pay/re-close', {
    purchaseId,
  });
};

const generateCurrentBill = (companyId, values) => {
  return api.post(
    config.endpoint + `bills-to-pay/${companyId}/generate-current-bill`,
    values
  );
};

const getPendingAmountByYearPerMonth = async ({ year, companyId }) => {
  try {
    const response = await api.get(
      `bills-to-pay/consult/pending-amount/by-years-month`,
      {
        params: {
          year,
          companyId,
        },
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsToPay = async ({
  companyId,
  initialDate,
  finalDate,
  typeOfDate,
}) => {
  try {
    const response = await api.get(`/report/payments-by-status/${companyId}`, {
      params: {
        initialDate,
        finalDate,
        typeOfDate,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsPayableTitleSynthetic = async ({
  companyId,
  initialDate,
  finalDate,
  providerId,
  dateType,
  titleStatus,
  cashierBankId,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/bills/to-pay/${companyId}/synthetic`,
      {
        params: {
          companyId,
          initialDate,
          finalDate,
          providerId,
          dateType,
          titleStatus,
          cashierBankId,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateBillsPayableTitleAnalytical = async ({
  companyId,
  initialDate,
  finalDate,
  providerId,
  dateType,
  titleStatus,
  cashierBankId,
  sortByCustomer,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/bills/to-pay/${companyId}/analytical`,
      {
        params: {
          companyId,
          initialDate,
          finalDate,
          providerId,
          dateType,
          titleStatus,
          cashierBankId,
          sortByCustomer,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const removeBankConciliation = async (id, userId) => {
  const response = await api.put(
    `bills-to-pay/${id}/bank-conciliation?userId=${userId}`
  );

  return response.data;
};

export default {
  index,
  create,
  getById,
  update,
  getAllByCompany,
  getAllClosedByCompany,
  getAllByCompanyPerPeriod,
  getExpenseForecast,
  bulkCreate,
  getAllByPurchase,
  reCloseByPurchase,
  generateCurrentBill,
  getPendingAmountByYearPerMonth,
  generateBillsToPay,
  generateBillsPayableTitleSynthetic,
  generateBillsPayableTitleAnalytical,
  removeBankConciliation,
};
