import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from 'utils/constants'
import AccountsReceivableMain from './components/AccountsReceivableMain'
const AccountsReceivable = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Financeiro', 'Contas a Receber']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <AccountsReceivableMain />
        </Row>
      </Grid>
    </div>
  )
}

export default AccountsReceivable
