import React from 'react'
import { ProgressBar } from 'react-step-progress-bar/'

import 'react-step-progress-bar/styles.css'

const FinancingProgressBar = ({ percent, children }) => (
  <ProgressBar
    percent={percent}
    filledBackground="#4cae4c"
    unfilledBackground="linear-gradient(to right, rgb(214, 255, 220), rgb(177, 250, 188))"
    style={{ height: '5px' }}
  >
    {children}
  </ProgressBar>
)

export default FinancingProgressBar
