import React, { useEffect } from 'react'
import CryptoJS from 'crypto-js'

import companiesRepository from 'repositories/Companies'
import usersRepository from 'repositories/Users'

const OnboardingVideoRedirect = ({ location }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search)

    let hashedCompanyId = params.get('companyId')
    let hashedUserId = params.get('userId')

    if (!hashedCompanyId || !hashedUserId) return

    while (
      hashedCompanyId.includes(' ') ||
      hashedUserId.includes(' ')
    ) {
      hashedCompanyId = hashedCompanyId.replace(' ', '+');
      hashedUserId = hashedUserId.replace(' ', '+');
    }

    hashedCompanyId = CryptoJS.AES.decrypt(hashedCompanyId, 'companyId')
    hashedUserId = CryptoJS.AES.decrypt(hashedUserId, 'userId')

    const companyId = hashedCompanyId.toString(CryptoJS.enc.Utf8)
    const userId = hashedUserId.toString(CryptoJS.enc.Utf8)

    updateOnboardingFieldsAndRedirect(companyId, userId)
  }, [])

  const updateOnboardingFieldsAndRedirect = async (companyId, userId) => {
    try {
      const date = new Date()

      await usersRepository.mainUpdate(userId, {
        OnboardingVideoVisualizedDate: date,
      })

      const company = await companiesRepository.show(companyId)

      if (!company.OnboardingVideoVisualizedDate) {
        await companiesRepository.mainUpdate(companyId, {
          OnboardingVideoVisualizedDate: date,
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      window.location.href = 'https://youtu.be/wmf0-pdOAXU'
    }
  }

  return <div>Redirecionando...</div>
}

export default OnboardingVideoRedirect
