import React from 'react';
import { TitleStepContainer } from '../GroupTitlesModal.styles';
import { SelectPaymentCondition } from '../../SelectPaymentCondition';
import { FormParcels } from '../../FormParcels';
import useGroupTitlesModalStore from '../store';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';

export function TitleStep() {
  const {
    feeValue,
    setFeeValue,
    selectedCustomer,
    installments,
    setInstallments,
    getSalesTotal,
    selectedSales,
  } = useGroupTitlesModalStore();

  const total = getSalesTotal();
  const selectedSalesQuantity = selectedSales?.length;

  return (
    <TitleStepContainer>
      <div className="flex column gap-050 w-80">
        <div className="flex between">
          <div>
            <strong>Cliente:</strong> {selectedCustomer?.value.name}
            {' - '}
            {selectedCustomer?.value.cpfCnpj
              ? `${cpfOrCnpjMask(selectedCustomer?.value.cpfCnpj)}`
              : '(CPF/CNPJ não informado)'}
          </div>
          <div>
            <strong>Telefone:</strong>{' '}
            {phoneMask(selectedCustomer?.value.phone)}
          </div>
          <div>
            <strong>Email:</strong> {selectedCustomer?.value.email}
          </div>
        </div>

        <div>
          <strong>Valor:</strong> {currency(total + feeValue)}{' '}
          {feeValue > 0 && (
            <span className="text-red">
              ({currency(feeValue)} juros) {''}
            </span>
          )}
          <strong>({selectedSalesQuantity} vendas selecionadas)</strong>
        </div>
      </div>

      <FormParcels
        total={total}
        installments={installments}
        setInstallments={setInstallments}
        feeValue={feeValue}
        setFeeValue={setFeeValue}
      />
    </TitleStepContainer>
  );
}
