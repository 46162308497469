import React, { memo } from 'react'
import Chart from 'react-google-charts'

const ChartByStatus = ({ data }) => {
  const chartColors = []

  data.forEach((info) => {
    switch (info[0]) {
      case 'Finalizado':
        chartColors.push('#5cb85c')
        break
      case 'Em aberto (Vencido)':
        chartColors.push('#ffcc00')
        break
      case 'Cancelado':
        chartColors.push('#ff0000')
        break
      case 'Em aberto (No Prazo)':
        chartColors.push('#3366cc')
        break
    }
  })

  return (
    <>
      <h4>
        <strong>Valores por Status (%)</strong>
      </h4>
      <div className='chart-pagamento' style={{ opacity: '100%' }}>
        <Chart
          width={'500px'}
          height={'300px'}
          chartType='PieChart'
          data={[
            ['Status', 'Valor', { role: 'tooltip', type: 'string' }],
            ...data,
          ]}
          chartLanguage='pt-br'
          options={{
            chartArea: {
              left: 0,
            },
            legend: {
              width: 200,
            },
            colors: chartColors,
          }}
        />
      </div>
    </>
  )
}

export default memo(ChartByStatus)
