import React from 'react';
import { Container, GraphWrapper } from './IndicatorGraph.styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

export function IndicatorGraph({
  data,
  width,
  height,
  colorLineA = 'indigo',
  colorLineB = 'red',
  colorLineC = 'blue',
  propertyLineA,
  descriptionLineA,
  propertyLineB,
  descriptionLineB,
  propertyLineC,
  descriptionLineC,
  propertyLineX,
  graphTitle,
  isCurrency = false,
  loading = false,
}) {
  const colors = {
    indigo: '#1D7391',
    red: '#cb4154',
    blue: '#3D8AF7',
  };

  return (
    <Container>
      <GraphWrapper>
        {loading ? (
          <div style={{ height: height || 250, width: width || 1100 }}>
            <LoadingSpinnerFullHeight />
          </div>
        ) : (
          <LineChart
            width={width || 1100}
            height={height || 250}
            data={data}
            margin={{ left: 50, right: 80 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              width={width || 1100}
              dataKey={propertyLineX}
              interval={0}
              fontSize={12}
            />
            <YAxis
              height={height || 250}
              interval={0}
              type="number"
              includeHidden
              padding={{ top: 20 }}
              /*
              label={{
              value: graphTitle,
              angle: -90,
              offset: 30,
              position: 'end',
              }} */
              fontSize={12}
              tickFormatter={(value) => (isCurrency ? currency(value) : value)}
            />
            <Tooltip />
            <Legend />
            <Line
              name={descriptionLineA}
              type="monotone"
              dataKey={propertyLineA}
              stroke={colors[colorLineA] || colorLineA}
              activeDot={{ r: 8 }}
              formatter={(value) => (isCurrency ? currency(value) : value)}
            />
            {propertyLineB && (
              <Line
                name={descriptionLineB}
                type="monotone"
                dataKey={propertyLineB}
                stroke={colors[colorLineB] || colorLineB}
                activeDot={{ r: 8 }}
                formatter={(value) => (isCurrency ? currency(value) : value)}
              />
            )}
            {propertyLineC && (
              <Line
                name={descriptionLineC}
                type="monotone"
                dataKey={propertyLineC}
                stroke={colors[colorLineC] || colorLineC}
                activeDot={{ r: 8 }}
                formatter={(value) => (isCurrency ? currency(value) : value)}
              />
            )}
          </LineChart>
        )}
      </GraphWrapper>
    </Container>
  );
}
