import { cnpjMask, cpfMask } from 'client/components/ToNormalize/ToNormalize';

export const xlsColumns = [
  {
    name: 'CNPJ',
    acessor: 'cpfCnpj',
  },
  {
    name: 'Razão Social',
    acessor: 'companyName',
  },
  {
    name: 'NF-e',
    acessor: 'nfe',
  },
  {
    name: 'NFC-e',
    acessor: 'nfce',
  },
  {
    name: 'NFS-e',
    acessor: 'nfse',
  },
  {
    name: 'SAT',
    acessor: 'sat',
  },
];

export const getInvoiceByClientInfoToExport = (clients) => {
  return clients.map((client) => ({
    cpfCnpj:
      client.Cpf_Cnpj.length <= 11
        ? cpfMask(client.Cpf_Cnpj)
        : cnpjMask(client.Cpf_Cnpj),
    companyName: client.Company_Name,
    nfce: client.nfce,
    nfe: client.nfe,
    nfse: client.nfse,
    sat: client.sat,
  }));
};
