import React from "react";
import { Grid, Row } from "react-bootstrap";

import QuickQuotes from './QuickQuotes'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const MyPositions = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={["Início", "Orçamento Rápido"]} path={["home", null]}/>
          <QuickQuotes/>
        </Row>
      </Grid>
    </div>
  );
}

export default MyPositions