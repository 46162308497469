import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import constants from 'utils/constants';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import HomeCustomerApproachReport from './components/HomeCustomerApproach';

const CustomerApproachReport = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Clientes/Funcionários',
              'Abordagem de Clientes',
            ]}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <HomeCustomerApproachReport />
        </Row>
      </Grid>
    </div>
  );
};
export default CustomerApproachReport;
