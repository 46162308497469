import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.css';

const Card = (props) => {
  return (
    <div className="sale-card-main">
      <div className="sale-card-container">
        <div className="sale-card-header">
          <span style={{ color: '#1d7391', fontSize: '17px' }}>
            <FontAwesomeIcon color="#1d7391" icon={props.icon} />
            <strong style={{ marginLeft: '5px' }}>{props.title}</strong>
          </span>
        </div>

        <div className={`sale-card-content  ${props.className}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Card;
