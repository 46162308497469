import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { FinancialStatementDocument } from './FinancialStatementDocument'

import api from '../../../services/api'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import { addDays, format } from 'date-fns'

const FinancialStatementReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [report, setReport] = useState({})

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const companyIdHash = searchParams.get('companyId')
      let companyId
      if (companyIdHash) {
        companyId = await decrypt(companyIdHash, '@OS-dig:companyId')
      }

      if (!companyId) {
        setHasError(true)
        setLoading(false)
      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      return loadReport(companyId, { initialDate, finalDate })
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (companyId, params) => {
    try {
      const { data } = await api.get(`financial-statement/report/${companyId}`, {
        params
      })
      setReport(data)
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <FinancialStatementDocument report={report} initialDate={initialDate} finalDate={finalDate} />
        </PDFViewer>
      </main>
    </div>
  )
}

export default FinancialStatementReport