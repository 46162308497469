import React from 'react'
import { useAuth } from 'contexts/auth'
import { Modal } from 'react-bootstrap'

import FormVehicle from '../Cadastros/Vehicle'

export default function RegisterVehicleModal({
  clientId,
  handleSelectVehicle,
  handleClose,
}) {
  const { companyId } = useAuth()

  return (
    <Modal
      dialogClassName="modal-70w"
      show={true}
      onHide={handleClose}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Novo Veículo</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormVehicle
          companyId={companyId}
          customerId={clientId}
          onCancel={handleClose}
          onSubmit={(vehicle) => handleSelectVehicle(vehicle)}
        />
      </Modal.Body>
    </Modal>
  )
}
