import React from 'react';
import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';
import { useExportXMLStore } from 'v2/store/exportXMLStore';
import { TimeLeft } from 'v2/components/TimeLeft';

import { TableButton } from '../ExportXML.styles';
import { ExportXMLRepository } from 'v2/repositories/ExportXML';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSync } from '@fortawesome/free-solid-svg-icons';

export function ProtocolDownloadButton({ protocol }) {
  const { companyId } = useAuth();
  const {
    consultingProtocolId,
    setConsultingProtocolId,
    changeDownloadedProtocol,
    changeReadyProtocol,
  } = useExportXMLStore();

  const isConsultingProtocol =
    consultingProtocolId === protocol.protocolInfo.protocol;

  const handleConsultProtocol = async (protocol, type) => {
    if (consultingProtocolId !== null) {
      toastr.info(
        'Há outra consulta em andamento',
        'Por favor, aguarde a conclusão'
      );
      return;
    }

    setConsultingProtocolId(protocol);
    try {
      const res = await ExportXMLRepository.consultProtocol({
        companyId,
        protocol,
      });

      if (!res.data.success) throw new Error();

      const byteCharacters = atob(res.data.base64Files);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/zip' });

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `exportacao-xml-(${protocol}).zip`;
      link.click();

      changeDownloadedProtocol(type);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Falha ao consultar protocolo',
        'Por favor, tente novamente'
      );
    }
    setConsultingProtocolId(null);
  };

  const handleDownloadSAT = async (base64Files, protocol) => {
    try {
      const byteCharacters = atob(base64Files);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/zip' });

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `exportacao-xml-sat.zip`;
      link.click();

      changeDownloadedProtocol(protocol.type);
    } catch (err) {
      console.log(err);
      toastr.error('Falha ao baixar arquivo', 'Por favor, tente novamente');
    }
    setConsultingProtocolId(null);
  };

  if (protocol.type === 'SAT CF-e') {
    return (
      <TableButton
        className="button button-green flex row align-center gap-075"
        disabled={isConsultingProtocol}
        onClick={() =>
          handleDownloadSAT(protocol.protocolInfo.base64Files, protocol)
        }
      >
        <span className="flex justify-between align-center gap-050 w-100">
          Download
          <FontAwesomeIcon icon={faFileDownload} />
        </span>
      </TableButton>
    );
  }

  if (!protocol.protocolInfo.success) {
    return (
      <span className="text-red text-center">
        {protocol.protocolInfo.message}
      </span>
    );
  }

  return (
    <>
      <TableButton
        className="button button-green flex row align-center gap-075"
        disabled={isConsultingProtocol || !protocol.ready}
        onClick={() =>
          handleConsultProtocol(protocol.protocolInfo.protocol, protocol.type)
        }
      >
        {isConsultingProtocol && (
          <span
            className={
              isConsultingProtocol
                ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                : 'update-spinner-disabled'
            }
          />
        )}
        {!protocol.ready ? (
          <>
            Consultar <FontAwesomeIcon icon={faSync} />
          </>
        ) : (
          <span className="flex justify-between align-center gap-050 w-100">
            Download
            <FontAwesomeIcon icon={faFileDownload} />
          </span>
        )}
      </TableButton>

      {!protocol.ready && (
        <TimeLeft
          hideOnEnd
          onEnd={() => changeReadyProtocol(protocol.type)}
          endTime={protocol.protocolInfo.dateConsultPermited}
        />
      )}
    </>
  );
}
