import Button from 'client/components/CustomButton/Button'
import { format } from 'date-fns'
import React from 'react'
import ReactTable from 'react-table'

const CitiesListTable = ({
  loading,
  citiesList,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={citiesList}
        columns={[
          {
            Header: 'Código IBGE',
            accessor: 'IBGECityCode',
            width: 120,
          },
          {
            Header: 'Cidade',
            accessor: 'city',
            width: 375,
          },
          {
            Header: 'UF',
            accessor: 'UF',
            width: 75,
          },
          {
            Header: 'Padrão',
            accessor: 'NFSPatterns.pattern',
            width: 175,
          },
          {
            Header: 'Tipo',
            accessor: 'NFSPatterns.offline',
            width: 120,
            Cell: (props) => {
              return (
                <Button
                  id="botaoReactTable"
                  className="react-table__button-label"
                  style={{
                    width: '80%',
                    height: '100%',
                    backgroundColor: !props.value ? '#5cb85c' : '#f0ad4e',
                  }}
                >
                  {!props.value ? 'Online' : 'Offline'}
                </Button>
              )
            },
          },
          {
            Header: 'Status',
            accessor: 'NFSPatterns.isHomologated',
            width: 165,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                className="react-table__button-label"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: props.value ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value ? 'Homologado' : 'Não Homologado'}
              </Button>
            ),
          },
          {
            Header: 'Data Homologação',
            accessor: 'updatedAt',
            width: 140,
            Cell: (props) =>
              props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '',
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma cidade encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  )
}

export default CitiesListTable
