import React from "react"
import { Grid, Row } from "react-bootstrap"

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import QuotationRegisterMain from "./components/QuotationRegisterMain"

import './index.css'

const QuotationRegister = ({ location }) => {
  const quotationId = location.hash?.split("#")[1]
 
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              'Cotação',
              quotationId ? 'Editar Cotação' : 'Nova Cotação',
            ]}
            path={['home', 'products', 'quotations', null]}
          />
          <QuotationRegisterMain quotationId={quotationId} />
        </Row>
      </Grid>
    </div>
  )
}

export default QuotationRegister