import React from 'react';
import { Modal } from 'react-bootstrap';

import './styles.css';

const RemoveBankConciliationModal = ({ show, onHide, onSubmit, isLoading }) => {
  return (
    <Modal show={show} animation dialogClassName="modal-10w" onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <strong>Remover Conciliação</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: '5px' }}>
          <strong>Você tem certeza que deseja remover a conciliação?</strong>
        </div>
        O proceso é irreversível e ao remover a conciliação, o vínculo será
        removido. Sendo obrigatório refazer novamente,
      </Modal.Body>

      <Modal.Footer>
        <div className="flex end gap-075">
          <button
            className="button button-red button-h35"
            onClick={onHide}
            disabled={isLoading}
          >
            Voltar
          </button>

          <button
            className="button button-green button-h35"
            onClick={onSubmit}
            disabled={isLoading}
          >
            Remover Conciliação
            <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveBankConciliationModal;
