import config from '../config'
import api from "../services/api"

const getAllByCompany = async companyId => {
  try {

    const response = await api.get(config.endpoint + `formofpayment?Company_id=${companyId}`);
    return response.data

  } catch(err) {
    throw err
  }
}

const getAllByCompanySortedByDescription = async companyId => {
  try {
    const response = await api.get(config.endpoint + `formofpayment?Company_id=${companyId}&sortBy=Desciption`);
    return response.data
  } catch(err) {
    throw err
  }
}

const getAllByCompanyActiveSortedByDescription = async companyId => {
  try {
    const response = await api.get(config.endpoint + `formofpayment?Company_id=${companyId}&sortBy=Desciption&IsActive=1`);
    return response.data
  } catch(err) {
    throw err
  }
}


const getById = async id => {
  try {

    const response = await api.get(config.endpoint + `formofpayment/${id}`)
    return response.data

  } catch(err) {
    throw err
  }
}

const update = async (id, formOfPayment) => {
  try {
    await api.put(config.endpoint + `formofpayment/${id}`, formOfPayment)
  } catch(err) {
    throw err
  }
}

const create = async formOfPayment => {
  try {
    const response = await api.post(config.endpoint + 'formofpayment', formOfPayment)
    return response.data
  } catch(err) {
    throw err
  }
}


const getAllActiveByCompany = async (companyId) => {
  try {

    const response = await api.get(config.endpoint + `formofpayment?IsActive=1&Company_id=${companyId}`)
    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  getAllByCompany,
  update,
  getAllActiveByCompany,
  getById,
  create,
  getAllByCompanySortedByDescription,
  getAllByCompanyActiveSortedByDescription
}