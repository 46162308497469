import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { Field, change } from 'redux-form';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import { useSelector, useDispatch } from 'react-redux';
import RenderField from 'components/RenderField';
import { percentMask } from '../../../../../utils/masks';
import { ComissionProrateNotAvailable } from 'v2/components/Modals/SalesConfig/ModalCommissionProrateNotAvailable';
import AlertModal from 'components/AlertModal/AlertModal';

const optionsForProductCommissionCalculatedBy = [
  {
    id: 1,
    description: 'Cadastro do Produto',
  },
  {
    id: 2,
    description: 'Funcionário',
  },
  {
    id: 3,
    description: 'Cargo',
  },
];

const optionsForServiceCommissionCalculatedBy = [
  {
    id: 1,
    description: 'Cadastro do Serviço',
  },
  {
    id: 2,
    description: 'Funcionário',
  },
  {
    id: 3,
    description: 'Cargo',
  },
];

export default function Commission() {
  const [commissionAlert, setCommissionAlert] = useState(false);
  const [isSwitchAvailable, setIsSwitchAvailable] = useState(false);

  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isPlanPrime,
    isPlanWorkmotorFree,
    isWorkmotor: isPlanWorkmotor,
  } = usePlanSignatureContext();
  const dispatch = useDispatch();

  const {
    comissionProrate,
    serviceCommissionCalculatedBy,
    productCommissionCalculatedBy,
  } = useSelector((state) => state.form.salesConfiguration.values);

  function handleSwitchProrate(e) {
    if (e.target.value !== '1' && comissionProrate) {
      setCommissionAlert(true);
    }
  }

  function handleComissionProrate() {
    if (
      productCommissionCalculatedBy !== '1' ||
      serviceCommissionCalculatedBy !== '1'
    ) {
      return setIsSwitchAvailable(true);
    }

    dispatch(
      change('salesConfiguration', 'comissionProrate', !comissionProrate)
    );
  }

  function handleChangeCalcProrate() {
    if (comissionProrate) {
      dispatch(change('salesConfiguration', 'comissionProrate', false));
      setCommissionAlert(false);
    }
  }

  function handleCancelCalcProrate() {
    if (comissionProrate) {
      dispatch(
        change('salesConfiguration', 'serviceCommissionCalculatedBy', '1')
      );
      dispatch(
        change('salesConfiguration', 'productCommissionCalculatedBy', '1')
      );
      setCommissionAlert(false);
    }
  }
  return (
    <div className="flex column mt-075">
      <div className="flex row gap-050">
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="productCommissionCalculatedBy"
            component={RenderField}
            label="Comissão de Prod. calculado pelo:"
            as="select"
            disabled={isPlanFree || isPlanStart}
            onChange={(e) => handleSwitchProrate(e)}
          >
            {optionsForProductCommissionCalculatedBy.map((option) => (
              <option value={option.id} key={option.id}>
                {option.description}
              </option>
            ))}
          </Field>
        </Col>

        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="serviceCommissionCalculatedBy"
            component={RenderField}
            label="Comissão de Serv. calculado pelo:"
            as="select"
            disabled={isPlanFree || isPlanStart}
            onChange={(e) => handleSwitchProrate(e)}
          >
            {optionsForServiceCommissionCalculatedBy.map((option) => (
              <option value={option.id} key={option.id}>
                {option.description}
              </option>
            ))}
          </Field>
        </Col>

        <Col xs={12} sm={12} md={3} lg={3} style={{ width: '270px' }}>
          <Field
            name="defaultProductCommissionRate"
            component={RenderField}
            label="Comissão Padrão de Prod.:"
            {...percentMask}
            maxLength="7"
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} style={{ width: '270px' }}>
          <Field
            name="defaultServiceCommissionRate"
            component={RenderField}
            label="Comissão Padrão de Serv.:"
            {...percentMask}
            maxLength="7"
          />
        </Col>
      </div>

      <div className="flex row gap-050">
        <Col xs={12} sm={12} md={3} lg={3} className="toggle-column">
          <div>
            <label>Rateio de Comissão</label>
          </div>

          <Toggle
            checked={comissionProrate}
            onChange={() => handleComissionProrate()}
          />
        </Col>
      </div>
      <ComissionProrateNotAvailable
        open={isSwitchAvailable}
        onClose={() => setIsSwitchAvailable(false)}
      />

      <AlertModal
        show={commissionAlert}
        title="Ratear Comissão"
        message={
          <>
            <p>Você tem certeza que deseja a forma de cálculo da comissão?</p>

            <p>
              Ao alterar, o rateio será desativado pois não atenderá os
              requisistos de funcionamento.
            </p>
          </>
        }
        onCancel={() => handleCancelCalcProrate(false)}
        onSubmit={() => handleChangeCalcProrate()}
        onHide={() => handleCancelCalcProrate(false)}
      />
    </div>
  );
}
