import api from '../services/api'
import  config from '../config'

const getAll = async () => {
  try {
    const response = await api.get(config.endpoint + 'typeunity')
    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  getAll
}