import React from 'react'
import { Grid, Row } from "react-bootstrap";
import { withRouter } from 'react-router';

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import FinancialMovement from './Form'
import constants from '../../../../utils/constants'

const FinancialMovementHome = ({ match, history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Financeiro', 'Movimentação de Banco/Caixa', !match.params.id ? 'Nova Movimentação' : `Editar Movimentação`]}
            path={[constants.ROUTES.HOME, null, constants.ROUTES.FINANCIAL_MOVEMENTS, null]}
          />
          <FinancialMovement
            financialMovementId={match.params.id}
            onCancel={() => history.push(constants.ROUTES.FINANCIAL_MOVEMENTS)}
            onSubmit={() => history.push(constants.ROUTES.FINANCIAL_MOVEMENTS)}
          />
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(FinancialMovementHome)