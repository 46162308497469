import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import {
  forName,
  maxLength,
  onlyNumbers,
  specialChar,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize';
import Input from 'client/components/Input/Input.jsx';
import { useDispatch } from 'react-redux';

const AddressData = ({ addressInformation }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (addressInformation) {
      dispatch([
        change('meusDados', 'Country', 'Brasil'),
        change('meusDados', 'Zipcode', addressInformation.Zipcode),
        change('meusDados', 'Address', addressInformation.Address),
        change(
          'meusDados',
          'Address_Number',
          addressInformation.Address_Number
        ),
        change('meusDados', 'Complement', addressInformation.Complement),
        change('meusDados', 'Neighborhood', addressInformation.Neighborhood),
        change('meusDados', 'State', addressInformation.State),
        change('meusDados', 'City', addressInformation.City),
      ]);
    }
  }, [addressInformation]);

  return (
    <div>
      <Col lg={2}>
        <label>
          CEP<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Zipcode"
          normalize={(value) => maxLength(cepMask(value), 10)}
        />
      </Col>
      <Col lg={4}>
        <label>
          Rua<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Address"
          normalize={(value) => maxLength(forName(value), 50)}
        />
      </Col>
      <Col lg={1}>
        <label>
          Número<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Address_Number"
          normalize={(value) => maxLength(onlyNumbers(value), 5)}
        />
      </Col>
      <Col lg={2}>
        <label>Complemento</label>
        <Field
          disabled={true}
          component={Input}
          name="Complement"
          normalize={(value) => maxLength(specialChar(value), 100)}
        />
      </Col>
      <Col lg={3}>
        <label>
          Bairro<span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          disabled={true}
          component={Input}
          name="Neighborhood"
          normalize={(value) => maxLength(forName(value), 30)}
        />
      </Col>
      <Col lg={3}>
        <label>
          Estado<span style={{ color: 'red' }}>*</span>
        </label>
        <Field component={Input} disabled={true} name="State">
          <option value="">Selecione</option>
        </Field>
      </Col>
      <Col lg={3}>
        <label>
          Cidade<span style={{ color: 'red' }}>*</span>
        </label>
        <Field component={Input} disabled={true} name="City">
          <option value="">Selecione</option>
        </Field>
      </Col>
    </div>
  );
};

export default AddressData;
