import React from 'react'
import { Modal } from 'react-bootstrap'

import {
  onlyNumbers,
  cpfMask,
  cnpjMask,
} from '../../../ToNormalize/ToNormalize'

import './styles.css'

const IncludeCpfCnpjModal = ({
  customerCpfCnpj,
  setCustomerCpfCnpj,
  onSubmit,
  fiscalDocumentType,
}) => {
  return (
    <>
      <Modal show animation dialogClassName='include-cpf-cnpj-modal-wrapper'>
        <Modal.Header>
          <Modal.Title>
            <strong>Emissão de {fiscalDocumentType || 'NFCe'}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Deseja emitir com CPF/CNPJ?</span>
          <div style={{ margin: '16px' }}>
            <input
              type='text'
              className='form-control foco-input'
              value={
                customerCpfCnpj.length <= 11
                  ? cpfMask(customerCpfCnpj)
                  : cnpjMask(customerCpfCnpj)
              }
              onChange={(e) => setCustomerCpfCnpj(onlyNumbers(e.target.value))}
              maxLength={18}
            />
            {fiscalDocumentType === 'SAT' && (
              <p style={{ color: '#d43f3a', fontSize: '12px' }}>
                A emissão do SAT só poderá ser feita no computador que ele está
                instalado
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='danger'
            onClick={() => onSubmit(false, customerCpfCnpj)}
          >
            Não
          </button>
          <button
            className='success'
            onClick={() => onSubmit(true, customerCpfCnpj)}
          >
            Sim
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default IncludeCpfCnpjModal
