import React from 'react'
import { useFormContext } from 'react-hook-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import {
  phoneMask,
  cpfMask,
  cnpjMask,
  email,
  styles,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize'
import { ProviderContainer, SectionContainer, SectionTitle } from '../styles'

export function QuotationProvider({ showMobileVersion }) {
  const { getValues } = useFormContext()

  const { provider } = getValues()

  return (
    <>
      {showMobileVersion ? (
        <div className="quotation-container-mobile">
          <div className="quotation-header-mobile">
            <p className="quotation-title">
              <FontAwesomeIcon
                icon={faAddressBook}
                style={{ paddingRight: '7px', fontSize: '20px' }}
              />{' '}
              Dados do fornecedor
            </p>
            <p>
              <strong>{provider.tradingName || provider.companyName}</strong>
            </p>

            <p>
              CPF/CNPJ:{' '}
              {provider.cpfCnpj
                ? provider.cpfCnpj.length === 11
                  ? cpfMask(provider.cpfCnpj)
                  : cnpjMask(provider.cpfCnpj)
                : 'Não informado'}
              <br />
              Telefone:{' '}
              {provider.Phones[0].Number_Phone1 ||
              provider.Phones[0].Number_Phone2
                ? phoneMask(
                    provider?.Phones[0].Number_Phone1 ||
                      provider?.Phones[0].Number_Phone2
                  )
                : 'Não informado'}
              <br />
              Email da empresa: {provider.email || 'Não informado'}
            </p>
          </div>
        </div>
      ) : (
        <SectionContainer>
          <ProviderContainer>
            <div className="provider-header">
              <SectionTitle style={{ marginTop: 0 }}>
                <FontAwesomeIcon
                  icon={faAddressBook}
                  style={{ paddingRight: '7px' }}
                />
                Dados do fornecedor
              </SectionTitle>
            </div>
            <div className="provider-data">
              <p className="provider-title">
                <strong>{provider.tradingName || provider.companyName}</strong>
              </p>

              <p>
                CPF/CNPJ:{' '}
                {provider.cpfCnpj
                  ? provider.cpfCnpj.length === 11
                    ? cpfMask(provider.cpfCnpj)
                    : cnpjMask(provider.cpfCnpj)
                  : 'Não informado'}
                <br />
                Telefone:{' '}
                {provider.Phones[0].Number_Phone1 ||
                provider.Phones[0].Number_Phone2
                  ? phoneMask(
                      provider?.Phones[0].Number_Phone1 ||
                        provider?.Phones[0].Number_Phone2
                    )
                  : 'Não informado'}
                <br />
                Email da empresa: {provider.email || 'Não informado'}
              </p>
            </div>
            <div className="provider-address">
              <p className="provider-title">
                <strong>Endereço Principal</strong>
              </p>
              <p>
                CEP:{' '}
                {provider?.Address[0]?.Zipcode
                  ? cepMask(provider.Address[0].Zipcode)
                  : 'Não informado'}
                <br />
                Endereço:{' '}
                {provider?.Address[0]?.Address
                  ? provider.Address[0].Address
                  : 'Não informado'}
                , Nº:{' '}
                {provider?.Address[0]?.Address_Number
                  ? provider.Address[0].Address_Number
                  : 'Não informado'}
                <br />
                Cidade/UF:{' '}
                {provider?.Address[0]?.City
                  ? provider.Address[0].City
                  : 'Não Informado'}
                /
                {provider?.Address[0]?.State
                  ? provider.Address[0].State
                  : 'Não Informado'}
              </p>
            </div>
          </ProviderContainer>
        </SectionContainer>
      )}
    </>
  )
}
