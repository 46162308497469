import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format, addDays } from 'date-fns';
import { H1 } from '../components/H1';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const Content = ({ children, style, ...rest }) => (
  <View style={[styles.content, style]} {...rest}>
    {children}
  </View>
);

const Table = ({ children, ...rest }) => (
  <View style={styles.table} {...rest}>
    {children}
  </View>
);

const TableHead = ({ children, style = {}, ...rest }) => (
  <View style={[styles.thead, style]} {...rest}>
    {children}
  </View>
);

const TableHeadTitle = ({ children, style, ...rest }) => (
  <Text style={[styles.theadTitle, style]} {...rest}>
    {children}
  </Text>
);

const TableBody = ({ children, ...rest }) => (
  <View style={styles.tbody} {...rest}>
    {children}
  </View>
);

const TableBodyRow = ({ children, style = {}, ...rest }) => (
  <View style={[styles.tbodyRow, style]} {...rest}>
    {children}
  </View>
);

const TableBodyRowData = ({ children, style, ...rest }) => (
  <View style={[styles.tbodyRowData, style]} {...rest}>
    {children}
  </View>
);

const ExpenseForecastReportDocument = ({ result, initialDate, finalDate }) => {
  return (
    <Document
      title={`relatorio-previsao-de-despesas-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="Relatório de Previsão de Despesas"
    >
      <Page size="A4" style={styles.page} orientation={'landscape'}>
        <H1 fixed>Previsão de Despesas</H1>
        <Content fixed style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={[styles.contentDetailsLabel, { fontWeight: 'bold' }]}>
            Período:
          </Text>
          <Text style={[styles.contentDetailsLabel, { marginLeft: '3px' }]}>
            ({initialDate} - {finalDate})
          </Text>
        </Content>
        <Content>
          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Totais por Conta
            </Text>
          </View>
        </Content>

        <Content>
          <Table>
            <TableHead
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              <TableHeadTitle
                style={[
                  { width: '25%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Conta
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '20%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Categoria
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Quantidade
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '45%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Valor
              </TableHeadTitle>
            </TableHead>

            <TableBody>
              {result.totalsPerExpenseForecast.length > 0 ? (
                result.totalsPerExpenseForecast.map((item) => {
                  const descriptionAccount = item.reduce(
                    (prevValue, currentValue) =>
                      currentValue.AccountPlans?.description,
                    ''
                  );
                  const descriptionCategory = item.reduce(
                    (prevValue, currentValue) =>
                      currentValue.AccountPlans?.AccountPlanCategories
                        ?.description,
                    ''
                  );
                  const valueTotal = item.reduce(
                    (prevValue, currentValue) =>
                      prevValue +
                      (currentValue.subTotal +
                        currentValue.addedValue -
                        currentValue.discountValue),
                    0
                  );
                  return (
                    <TableBodyRow>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '25%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{descriptionAccount || 'Sem Conta'}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '20%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{descriptionCategory}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.length}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '45%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{currency(valueTotal)}</Text>
                      </TableBodyRowData>
                    </TableBodyRow>
                  );
                })
              ) : (
                <TableBodyRow>
                  <TableBodyRowData
                    style={[styles.contentText, { width: '30%' }]}
                  >
                    <Text>Não há valores recebidos para exibir.</Text>
                  </TableBodyRowData>
                </TableBodyRow>
              )}
            </TableBody>
          </Table>
        </Content>

        <Content>
          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Listagem de Títulos
            </Text>
          </View>
        </Content>

        <Content>
          <Table>
            <TableHead
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              <TableHeadTitle
                style={[
                  {
                    width: '10%',
                    display: 'flex',
                    textAlign: 'left',
                    justifyContent: 'center',
                  },
                ]}
              >
                Dt. Venc.
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Título
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Compra
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                NF
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '15%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Fornecedor
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '15%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Conta
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Valor
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Acréscimo
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Desconto
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '10%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Total
              </TableHeadTitle>
              <TableHeadTitle
                style={[
                  { width: '15%', textAlign: 'left', justifyContent: 'center' },
                ]}
              >
                Valor em Aberto
              </TableHeadTitle>
            </TableHead>

            <TableBody>
              {result.expenseForecast.length > 0 ? (
                result.expenseForecast.map((item, index) => {
                  return (
                    <TableBodyRow key={index}>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>
                          {format(
                            addDays(new Date(item.dueDate), 1),
                            'dd/MM/yyyy'
                          )}
                        </Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.code}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.Purchases?.code}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.Purchases?.invoice}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '15%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.Provider?.companyName}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '15%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{item.AccountPlans?.description}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{currency(item.subTotal)}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{currency(item.addedValue)}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{currency(item.discountValue)}</Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '10%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>
                          {currency(
                            item.subTotal + item.addedValue - item.discountValue
                          )}
                        </Text>
                      </TableBodyRowData>
                      <TableBodyRowData
                        style={[
                          styles.contentText,
                          {
                            width: '15%',
                            textAlign: 'left',
                            justifyContent: 'center',
                          },
                        ]}
                      >
                        <Text>{currency(item.openValue)}</Text>
                      </TableBodyRowData>
                    </TableBodyRow>
                  );
                })
              ) : (
                <TableBodyRow>
                  <TableBodyRowData
                    style={[styles.contentText, { width: '30%' }]}
                  >
                    <Text>Não há valores recebidos para exibir.</Text>
                  </TableBodyRowData>
                </TableBodyRow>
              )}
            </TableBody>
          </Table>
        </Content>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  content: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 5,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    fontSize: '14',
  },
  contentText: {
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  contentDetailsLabel: {
    fontSize: 9,
  },
  table: {
    display: 'table',
  },
  thead: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  theadTitle: {
    fontSize: 10,
    fontFamily: 'Roboto',
    padding: '0px 5px',
  },
  theadResumeTitleDescription: {
    width: 430,
  },
  theadResumeTitleValue: {
    width: 100,
  },
  theadResumeTitleType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tbody: {
    padding: '5px 0px 0px 0px',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  tbodyRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  tbodyRowData: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'flex-start',
    margin: '0px 0px 0px 4px',
  },
  tbodyRowDataRedLabel: {
    color: 'red',
  },
  tbodyRowDataReceivedByPaymentFormValue: {
    width: 100,
  },
  tbodyRowDataResumeDescription: {
    width: 430,
  },
  tbodyRowDataResumeValue: {
    width: 100,
  },
  tbodyRowDataResumeType: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  additionalInfo: {
    fontFamily: 'Roboto',
    fontSize: '7px',
  },
  observations: {
    fontSize: 10,
    fontFamily: 'Roboto',
    marginLeft: 20,
  },
  page: {
    padding: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
});
export default ExpenseForecastReportDocument;
