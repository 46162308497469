import React from 'react';
import { CustomerStep } from './steps/CustomerStep';
import { TitleStep } from './steps/TitleStep';

import useGroupTitlesModalStore from './store';

export const groupTitlesConfirmationMessage = () => {
  return (
    <>
      <span>
        <strong>
          Você deseja gerar um título para as Vendas/OS selecionadas?
        </strong>
      </span>
      <br />
      <br />
      <span>
        O processo é irreversível. Caso tenha outros títulos gerados, todos
        serão cancelados e um novo título será gerado. Você não poderá gerar uma
        nota para as vendas/OS agrupadas.
      </span>
    </>
  );
};

export const SalesSelectionFooter = ({
  currentStep,
  previousStep,
  nextStep,
}) => {
  const { selectedSales } = useGroupTitlesModalStore.getState();

  return (
    <div className="flex end gap-050">
      <button className="button button-red" onClick={previousStep}>
        {currentStep === 1 ? 'Cancelar' : 'Voltar'}
      </button>
      <button
        className="button button-green"
        onClick={nextStep}
        disabled={currentStep === 1 && selectedSales.length < 2}
      >
        {currentStep === 1 ? 'Avançar' : 'Concluir'}
      </button>
    </div>
  );
};

export const renderContentByStep = (currentStep) => {
  switch (currentStep) {
    case 1:
      return <CustomerStep />;
    case 2:
      return <TitleStep />;
    default:
      return <></>;
  }
};
