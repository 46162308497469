import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import AlertModal from 'components/AlertModal/AlertModal';
import constants from 'utils/constants';
import { useAuth } from 'contexts/auth';

const getCaption = (bill) => {
  const { payments } = bill;
  const hasParcelsWithoutDate = payments.some(
    (payment) => !payment.paymentDate
  );

  switch (true) {
    case hasParcelsWithoutDate:
      return 'Existe uma forma de pagamento sem data de pagamento. Caso gere diferença, o valor sem forma vinculada e o valor sem data de pagamento serão migrados para o novo título.';
    default:
      return 'Em caso de pagamento com datas diferentes, será necessário gerar um novo título com essa diferença em aberto';
  }
};

export function SaveBillModal({ isOpen, bill, handleClose, handleSubmit }) {
  const dispatch = useDispatch();
  const [
    isSaveWithDifferenceConfirmationModalOpen,
    setIsSaveWithDifferenceConfirmationModalOpen,
  ] = useState(false);

  const handleSaveOpen = () => {
    dispatch(change('billToReceive', 'generateDiffTitle', false));
    handleSubmit(constants.BILLS_STATUS.OPEN);
  };

  const handleSaveClosed = (generateDiffTitle) => {
    handleSubmit(constants.BILLS_STATUS.CLOSED, generateDiffTitle);
  };

  const handleOpenSaveWithDifferenceConfirmationModal = () => {
    setIsSaveWithDifferenceConfirmationModalOpen(true);
  };

  const handleCloseSaveWithDifferenceConfirmationModal = () => {
    setIsSaveWithDifferenceConfirmationModalOpen(false);
  };

  const hasOpenValue = useMemo(() => bill.openValue > 0, [bill.openValue]);

  const { company } = useAuth();
  const generateTitleDiffConfig = company.companyConfig.generateTitleDiff;

  return (
    <>
      <Modal show={isOpen} size="sm" onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong className="modal__title">Salvar Título</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex column gap-025">
            {hasOpenValue && generateTitleDiffConfig ? (
              <>
                <span className="typography__text-1 text-bold">
                  Existem valores em aberto sem pagamento e/ou data de pagamento
                  preenchida
                </span>
                <span className="typography__text-1">
                  Você poderá salvar em aberto para preencher e fechá-lo mais
                  tarde ou gerar um novo título com o valor em aberto.
                </span>
              </>
            ) : (
              <span className="typography__text-1">
                Como deseja salvar este título?
              </span>
            )}

            <div className="flex column center gap-050 mt-2">
              <button
                className="button button-blue button-h35 flex center"
                style={{ width: '250px' }}
                onClick={
                  hasOpenValue
                    ? () => handleOpenSaveWithDifferenceConfirmationModal()
                    : () => handleSaveClosed(false)
                }
              >
                <span className="flex center">
                  Finalizar Título
                  {hasOpenValue &&
                    generateTitleDiffConfig &&
                    ' e Gerar Diferença'}
                </span>
              </button>
              <button
                className="button button-green button-h35 flex center"
                style={{ width: '250px' }}
                onClick={handleSaveOpen}
              >
                <span className="flex center">Salvar Título em Aberto</span>
              </button>
            </div>

            {hasOpenValue && generateTitleDiffConfig && (
              <div className="mt-2">
                <span className="typography__caption text-red text-center">
                  {getCaption(bill)}
                </span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end">
            <button
              className="button button-red button-h35 flex center"
              onClick={handleClose}
            >
              Voltar
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isSaveWithDifferenceConfirmationModalOpen}
        onSubmit={() => handleSaveClosed(true)}
        onHide={handleCloseSaveWithDifferenceConfirmationModal}
        onCancel={handleCloseSaveWithDifferenceConfirmationModal}
        title={'Confirmação'}
        message={
          <div className="flex column gap-025">
            <span>
              Você tem certeza que deseja finalizar o título atual e gerar um
              novo com o valor em aberto?
            </span>
            <span className="typography__caption text-red text-center">
              O processo é irreversível. Uma vez gerado diferença, o titulo
              atual será baixo e um novo criado, sem possibilidade de retono
            </span>
          </div>
        }
      />
    </>
  );
}
