import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";

import AlertModal from "../../../../components/AlertModal";
import Button from "../../../components/CustomButton/CustomButton";
import CurrencyInput from "../../../components/Currency";
import { encrypt } from "../../../components/ToNormalize/ToNormalize";
import cashierBanksHistoricRepository from "../../../../repositories/CashierBanksHistoric";

import "./styles.css";

const CloseCashierBankHistoricModal = ({
  cashierBankHistoricId,
  onCancel,
  onSubmit,
  ...rest
}) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isConfirmCloseCashierModalOpen, setIsConfirmCloseCashierModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [cashierBankHistoric, setCashierBankHistoric] = useState({
    id: null,
    CashierBank: {
      CashierBankType: {
        initials: "",
      },
    },
    description: "",
    initialValue: 0,
    receivedValue: 0,
    exchangeValue: 0,
    paidValue: 0,
    finalValue: 0,
  });

  useEffect(() => {
    if (cashierBankHistoricId) {
      loadCashierBankHistoric();
    }
  }, [cashierBankHistoricId]);

  async function loadCashierBankHistoric() {
    setLoading(true);
    try {
      const cashierBankHistoric = await cashierBanksHistoricRepository.show(
        cashierBankHistoricId
      );
      setCashierBankHistoric(cashierBankHistoric);
    } catch (err) {
      toastr.warning(
        "Ocorreu um erro ao buscar o histórico de caixa. Por favor, tente novamente"
      );
    }
    setLoading(false);
  }

  async function handleCloseCashier() {
    setIsConfirmCloseCashierModalOpen(false);
    setLoading(true);

    try {
      await cashierBanksHistoricRepository.close(cashierBankHistoricId, {
        initialValue: cashierBankHistoric.initialValue,
        receivedValue: cashierBankHistoric.receivedValue,
        paidValue: cashierBankHistoric.paidValue,
        finalValue: cashierBankHistoric.finalValue,
        exchangeValue: cashierBankHistoric.exchangeValue,
        closedDate: new Date().getTime(),
      });
      onSubmit();
    } catch (err) {
      toastr.warning(
        "Ocorreu um erro ao fechar o caixa. Por favor, tente novamente"
      );
    }
    setLoading(false);
  }

  function handleOpenCloseCashierReport(cashierBankHistoricId) {
    const cashierBankHistoricIdHash = encrypt(
      cashierBankHistoricId,
      "@OS-dig:cashierBankHistoricIdToReport"
    );
    window.open(
      window.location.origin +
        `/report/close-cashier?cashierBankHistoricId=${cashierBankHistoricIdHash}`,
      "_blank"
    );
  }

  return (
    <>
      <Modal
        show
        animation
        dialogClassName="close-cashier-bank-historic-modal"
        {...rest}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Fechamento de Caixa</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Banco/Caixa:{" "}
            {cashierBankHistoric.CashierBank.CashierBankType.initials} -{" "}
            {cashierBankHistoric.CashierBank.description}
          </span>
          <fieldset>
            <label htmlFor="initialValue">Saldo Inicial (=):</label>
            <CurrencyInput
              id="initialValue"
              className="form-control foco-input"
              disabled
              value={cashierBankHistoric.initialValue}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="receivedValue">Total Recebido (+):</label>
            <CurrencyInput
              id="receivedValue"
              className="form-control foco-input"
              disabled
              value={cashierBankHistoric.receivedValue}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="exchangeValue">Total Troco (-):</label>
            <CurrencyInput
              id="exchangeValue"
              className="form-control foco-input"
              disabled
              value={cashierBankHistoric.exchangeValue}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="paidValue">Total Pago (-):</label>
            <CurrencyInput
              id="paidValue"
              className="form-control foco-input"
              disabled
              value={cashierBankHistoric.paidValue}
            />
          </fieldset>
          <footer>
            <fieldset>
              <label htmlFor="finalValue">
                <strong>Saldo Final (=):</strong>
              </label>
              <CurrencyInput
                id="finalValue"
                className="form-control foco-input final-value-input"
                disabled
                value={cashierBankHistoric.finalValue}
              />
            </fieldset>
          </footer>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            disabled={loading}
            fill
            onClick={() => handleOpenCloseCashierReport(cashierBankHistoricId)}
          >
            Detalhes do Fechamento
          </Button>
          <Button
            bsStyle="danger"
            disabled={loading}
            fill
            onClick={() => setIsConfirmCancelModalOpen(true)}
          >
            Cancelar
          </Button>
          <Button
            bsStyle="info"
            disabled={loading}
            fill
            onClick={() => setIsConfirmCloseCashierModalOpen(true)}
          >
            Fechar Caixa
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="Cancelamento de fechamento de Caixa"
        message="Deseja cancelar a fechamento de caixa? A abertura poderá ser feita posteriormente."
        show={isConfirmCancelModalOpen}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <AlertModal
        show={isConfirmCloseCashierModalOpen}
        title="Fechamento de Caixa"
        message="Deseja realizar o fechamento de caixa? Ao fechar o caixa, entende que os valores apresentados estão correspondendo os valores de caixa. O processo é irreversível."
        onCancel={() => setIsConfirmCloseCashierModalOpen(false)}
        onHide={() => setIsConfirmCloseCashierModalOpen(false)}
        onSubmit={handleCloseCashier}
      />
    </>
  );
};

export default CloseCashierBankHistoricModal;
