import { format } from 'date-fns'
import {
  cnpjMask,
  cpfMask,
  phoneMask,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize'

export const xlsColumns = [
  {
    name: 'CNPJ',
    acessor: 'cpfCnpjXls',
  },
  {
    name: 'Nome Fantasia',
    acessor: 'tradingName',
  },
  {
    name: 'Razão Social',
    acessor: 'companyName',
  },

  {
    name: 'Celular',
    acessor: 'phoneXls',
  },
  {
    name: 'Status',
    acessor: 'isActive',
  },
  {
    name: 'Tipo de Pessoa',
    acessor: 'type',
  },
  {
    name: 'Inscrição Estadual',
    acessor: 'iE',
  },
  {
    name: 'E-mail',
    acessor: 'email',
  },
  {
    name: 'Telefone Comercial',
    acessor: 'phoneCommercial',
  },
  {
    name: 'Responsável',
    acessor: 'responsable',
  },
  {
    name: 'Data de Cadastro',
    acessor: 'createdAt',
  },
  {
    name: 'Observações',
    acessor: 'observation',
  },
  {
    name: 'CEP',
    acessor: 'cep',
  },
  {
    name: 'Endereço',
    acessor: 'address',
  },
  {
    name: 'Número',
    acessor: 'addressNumber',
  },
  {
    name: 'Bairro',
    acessor: 'neighborhood',
  },
  {
    name: 'Complemento',
    acessor: 'complement',
  },
  {
    name: 'Ponto de referência',
    acessor: 'referencePoint',
  },
  {
    name: 'Estado',
    acessor: 'state',
  },
  {
    name: 'Cidade',
    acessor: 'city',
  },
]

export function getProvidersToExport(data) {
  return data.map((provider) => {
    return {
      ...provider,
      phoneXls: phoneMask(provider.Phone),
      cpfCnpjXls: !!provider.cpfCnpj
        ? provider.type === 'Juridica'
          ? cnpjMask(provider.cpfCnpj)
          : cpfMask(provider.cpfCnpj)
        : null,
      isActive: provider.isActive === true ? 'Ativo' : 'Inativo',
      phoneCommercial: provider?.Phones[0]?.Number_Phone1
        ? phoneMask(provider?.Phones[0]?.Number_Phone1)
        : '',
      address: provider?.Address[0]?.Address,
      city: provider?.Address[0]?.City,
      complement: provider?.Address[0]?.Complement,
      neighborhood: provider?.Address[0]?.Neighborhood,
      state: provider?.Address[0]?.State,
      addressNumber: provider?.Address[0]?.Address_Number,
      cep: cepMask(provider?.Address[0]?.Zipcode),
      referencePoint: provider?.Address[0]?.Reference_Point,
      createdAt: format(new Date(provider.createdAt), 'dd/MM/yyyy'),
      observation: provider?.comments,
      iE: provider?.IE,
      responsable: provider?.Phones[0]?.Contact,
    }
  })
}
