import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import { useRecoilState } from 'recoil';

import {
  FINANCING_AMOUNT,
  FINANCING_PARTNERID,
  FINANCING_TRANSACTIONID,
} from '../../recoil';

const FinancingAdvice = () => {
  const [partnerId] = useRecoilState(FINANCING_PARTNERID);

  switch (partnerId) {
    case 1:
      return (
        <>
          <p style={{ color: '#d9534f', fontSize: '12px', textAlign: 'left' }}>
            IMPORTANTE: Após a contratação, como confirmação, é obrigatório que
            seja feito o pagamento da primeira parcela como sinal
          </p>

          <p
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '12px',
              textAlign: 'center',
              marginTop: '20px',
              marginLeft: '-18px',
            }}
          >
            Pagamentos da 1ª parcela no boleto tem o tempo de processamento de
            até 72 horas úteis. Pagamento em PIX tem a aprovação em poucos
            minutos
          </p>
        </>
      );
    default:
      return <></>;
  }
};

export default function FinancingConfirmedStep() {
  const [financingAmount] = useRecoilState(FINANCING_AMOUNT);
  const [transactionId] = useRecoilState(FINANCING_TRANSACTIONID);

  return (
    <div>
      <div style={{ fontSize: '13px' }}>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            marginBottom: '0',
          }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color="#4cae4c"
            fontSize={'15px'}
          />
          &nbsp;
          <strong>Proposta de Financiamento Criada Com Sucesso</strong>{' '}
        </p>
      </div>
      <div
        style={{
          fontSize: '13px',
          marginTop: '5px',
          marginLeft: '18px',
        }}
      >
        <p style={{ marginTop: '30px', marginBottom: 0 }}>
          <strong>Valor do Financiamento:</strong>&nbsp;{' '}
          {currency(financingAmount)}
        </p>

        <p style={{ color: '#d9534f', fontSize: '12px', textAlign: 'left' }}>
          *A venda/ordem de serviço ficará com status "Financiamento em Análise"
          até o cliente responder.
        </p>

        {transactionId && (
          <p>
            <strong>ID da Transação do Financiamento:</strong>&nbsp;
            {transactionId}
          </p>
        )}

        <FinancingAdvice />
      </div>
    </div>
  );
}
