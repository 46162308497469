import api from 'services/api'

const update = async (id, values) => {
  const res = await api.put(`/boleto-flex/alert/${id}`, values)
  return res.data
}

const visualizeAll = async (companyId, values) => {
  const res = await api.put(
    `/boleto-flex/alert/by-company/${companyId}`,
    values
  )
  return res.data
}

const BoletoFlexAlertRepository = {
  update,
  visualizeAll,
}

export default BoletoFlexAlertRepository
