import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepContainer, StepIcon, StepTitle } from './Step.styles';

const GroupTitlesStep = ({ icon, accomplished, title }) => {
  return (
    <StepContainer>
      <StepIcon filled={accomplished}>
        <FontAwesomeIcon
          style={{ height: 25, width: 25, marginTop: 2, color: '#fff' }}
          icon={icon}
          aria-hidden="false"
        />
      </StepIcon>
      <StepTitle>{title}</StepTitle>
    </StepContainer>
  );
};

export default GroupTitlesStep;
