import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import FormBrand from './NewBrand'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewBrand = ({ match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <BreadCrumb
              data={['Início', 'Cadastros', 'Produtos/Serviços', 'Marcas', match.params.id ? 'Editar Marca' : 'Nova Marca']}
              path={[constants.ROUTES.HOME, null, null, constants.ROUTES.BRANDS, null]}
            />
            <FormBrand newBrand={match.params.id ? false : true} BrandId={match.params.id} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default NewBrand