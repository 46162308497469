import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { isValid, isBefore, addDays, differenceInDays } from 'date-fns';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { Col } from 'react-bootstrap';
import revenuesPerPeriodRepository from '../../../../../repositories/RevenuesPerPeriod';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { listAllSaleSubtypeActive } from 'v2/repositories/SalesSubtypeRepository';

const RevenuePerPeriod = () => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [dateType, setDateType] = useState('CLOSING');
  const [lookingFor, setLookingFor] = useState('BOTH');
  const [reportType, setReportType] = useState('ANALYTICAL');
  const [showResume, setShowResume] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showMarginAndProfit, setShowMarginAndProfit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salesSubtypeId, setSalesSubtypeId] = useState('1');
  const [maxDate, setMaxDate] = useState('');

  const [salesSubtype, setSalesSubtype] = useState([]);

  const { companyId } = useAuth();

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const res = await revenuesPerPeriodRepository.getRevenuesPerPeriodReport(
        companyId,
        {
          initialDate,
          finalDate,
          dateType,
          lookingFor,
          showResume,
          reportType,
          salesSubtypeId,
        }
      );

      if (reportType === 'SYNTHETIC') {
        if (Object.keys(res.data.serializedReport).length === 0)
          return toastr.warning(
            'Nenhuma informação para o relatório foi encontrada.'
          );
      } else {
        if (!res.data.serializedReport.length > 0)
          return toastr.warning(
            'Nenhuma informação para o relatório foi encontrada.'
          );
      }

      handlePrint();
    } catch (err) {
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu algum erro ao buscar o histórico. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Selecione um período válido para gerar o relatório e tente novamente.'
      );
      return false;
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning(
        'Selecione um período válido para gerar o relatório e tente novamente.'
      );
      return false;
    }

    return true;
  };

  const handleReportType = (value) => {
    setReportType(value);
    if (value === 'SYNTHETIC') {
      setShowPaymentDetails(false);
      setShowMarginAndProfit(false);
    }
  };

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId');
    window.open(
      window.location.origin +
        `/report/revenues-per-period?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&dateType=${dateType}&lookingFor=${lookingFor}&showPaymentDetails=${showPaymentDetails}&showMarginAndProfit=${showMarginAndProfit}&showResume=${showResume}&reportType=${reportType}&salesSubtypeId=${salesSubtypeId}`,
      '_blank'
    );
  };

  const loadSalesSubtype = async () => {
    try {
      const data = await listAllSaleSubtypeActive(companyId);
      setSalesSubtype(data);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar subtipos de venda',
        'Por favor, recarregue a página e tente novamente.'
      );
    }
  };

  const validateFinalDate = (date) => {
    if (date && initialDate) {
      const daysDifference = differenceInDays(
        new Date(date),
        new Date(initialDate)
      );
      if (daysDifference > 90) {
        toastr.warning(
          'Período Inválido',
          'O período selecionado deve ser no máximo 90 dias (3 meses). Selecione um período válido e tente novamente.'
        );
        setFinalDate('');
        return false;
      }
    }
    return true;
  };

  const validateInitialDate = (date) => {
    if (date && finalDate) {
      const daysDifference = differenceInDays(
        new Date(finalDate),
        new Date(date)
      );
      if (daysDifference > 90) {
        toastr.warning(
          'Período Inválido',
          'O período selecionado dever ser no máximo 90 dias (3 meses). Selecione um período válido e tente novamente.'
        );
        setInitialDate('');
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    loadSalesSubtype();
  }, []);

  useEffect(() => {
    if (initialDate) {
      const max = addDays(new Date(initialDate), 90);
      setMaxDate(max.toISOString().split('T')[0]);
    }
  }, [initialDate]);

  return (
    <div>
      <Col style={{ display: 'flex', marginTop: '40px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '130px',
            marginLeft: '10px',
          }}
        >
          <strong>Tipo de Data:</strong>

          <select
            value={dateType}
            onChange={(e) => setDateType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="CLOSING">Fechamento</option>
            <option value="CREATION">Criação</option>
          </select>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <strong>Data Inicial:</strong>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            onChange={(e) => {
              if (validateInitialDate(e.target.value)) {
                setInitialDate(e.target.value);
              }
            }}
            style={{
              width: '140px',
            }}
          />
        </div>
        <div style={{ marginLeft: '10px' }}>
          <strong>Data Final:</strong>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            onChange={(e) => {
              if (validateFinalDate(e.target.value)) {
                setFinalDate(e.target.value);
              }
            }}
            style={{
              width: '140px',
            }}
          />
        </div>
      </Col>
      <Col style={{ display: 'flex', paddingBottom: '50px', marginTop: 15 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            marginLeft: '10px',
          }}
        >
          <strong>Tipo:</strong>

          <select
            value={reportType}
            onChange={(e) => handleReportType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="ANALYTICAL">Analítico</option>
            <option value="SYNTHETIC">Sintético</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '120px',
            marginLeft: '10px',
          }}
        >
          <strong>Buscar Por:</strong>
          <select
            value={lookingFor}
            onChange={(e) => setLookingFor(e.target.value)}
            className="form-control foco-input"
          >
            <option value="BOTH">Ambos</option>
            <option value="SALE">Venda</option>
            <option value="ORDER OF SERVICE">Ordem de Serviço</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '140px',
            marginLeft: '10px',
          }}
        >
          <strong>Exibir pagamento:</strong>
          <select
            value={showPaymentDetails}
            onChange={(e) => setShowPaymentDetails(e.target.value)}
            className="form-control foco-input"
            disabled={reportType === 'SYNTHETIC'}
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
            marginLeft: '10px',
          }}
        >
          <strong>Exibir Margem e Lucro:</strong>
          <select
            value={showMarginAndProfit}
            onChange={(e) => setShowMarginAndProfit(e.target.value)}
            className="form-control foco-input"
            disabled={reportType === 'SYNTHETIC'}
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
            marginLeft: '10px',
          }}
        >
          <strong>Exibir Resumo Totais:</strong>

          <select
            value={showResume}
            onChange={(e) => setShowResume(e.target.value)}
            className="form-control foco-input"
          >
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '170px',
            marginLeft: '10px',
          }}
        >
          <strong>Subtipo:</strong>

          <select
            value={salesSubtypeId}
            onChange={(e) => setSalesSubtypeId(e.target.value)}
            className="form-control foco-input"
          >
            {salesSubtype.map((subtype) => (
              <option key={subtype.id} value={subtype.id}>
                {subtype.description}
              </option>
            ))}
          </select>
        </div>
        <section
          style={{ display: 'flex', alignItems: 'flex-end', marginLeft: 10 }}
        >
          <button
            className="component__pdf-button"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            <FontAwesomeIcon
              icon={faFilePdf}
              size="lg"
              style={{ marginRight: '5px' }}
            />
            Exportar .pdf
          </button>
        </section>
      </Col>
    </div>
  );
};

export default RevenuePerPeriod;
