import React, { useEffect, useState } from 'react'
import NewRequisitionHeader from './NewRequisitionHeader'
import SalesRepository from 'repositories/Sales'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import PartsRequisitionRepository from 'repositories/PartsRequisition'
import NewRequisitionTable from './NewRequisitionTable'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { addDays, format } from 'date-fns'
import AlertModal from 'components/AlertModal'
import NewRequisitionFormButtons from './NewRequisitionFormButtons'

const NewRequisitionMain = ({ history, hasId }) => {
  const [formattedOptions, setFormattedOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [confirmModal, setConfirmModal] = useState(false)

  const { companyId } = useAuth()

  const { register, setValue, handleSubmit, control, watch } = useForm({
    defaultValues: {
      saleRequisition: {
        saleId: '',
        employeeId: '',
      },
      saleRequisitionItems: [],
    },
  })

  const { append, fields, replace } = useFieldArray({
    name: 'saleRequisitionItems',
    control,
  })

  const loadPartRequisitionData = async () => {
    setLoading(true)
    try {
      if (selectedCustomer) {
        const saleInfo = await PartsRequisitionRepository.getSaleRequisition(
          selectedCustomer?.saleIdAux
        )

        setValue('saleCode', saleInfo?.header[0]?.saleCode)
        setValue('customerName', saleInfo?.header[0]?.customerName)
        setValue('employeeName', saleInfo?.header[0]?.employeeName)
        setValue('saleRequisition.saleId', saleInfo?.header[0]?.saleId)
        setValue('saleRequisition.employeeId', saleInfo?.header[0]?.employeeId)

        replace(saleInfo?.saleRequisitionItems)
      }
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível carregar a venda.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
  }

  const loadSalesOptions = async () => {
    setLoading(true)
    try {
      const salesOptions = await SalesRepository.salesWithCodeAndCustomer(
        companyId
      )

      const formattedCustomers = salesOptions.map((c) => ({
        saleIdAux: c.saleId,
        value: c.saleCode,
        label: c.label,
      }))

      setFormattedOptions(formattedCustomers)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível carregar as opções de clientes.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
  }

  const validations = (data) => {
    const actualDate = format(new Date(), 'yyyy-MM-dd')

    const dateError = data.saleRequisitionItems.filter(
      (item) => item.deliveredDate > actualDate
    )

    if (dateError.length > 0) {
      toastr.warning(
        'Não foi possível realizar a requisição.',
        'A data de entrega não pode ser maior que a data atual.'
      )
      setLoading(false)
      setConfirmModal(false)
      return false
    }

    const quantityError = data.saleRequisitionItems.filter(
      (item) => item.deliveredQuantity > item.availableQuantity
    )

    if (quantityError.length > 0) {
      toastr.warning(
        'Não foi possível realizar a requisição.',
        'A quantidade entregue não pode ser maior que a quantidade disponível.'
      )
      setLoading(false)
      setConfirmModal(false)
      return false
    }

    const negativeQuantityError = data.saleRequisitionItems.filter(
      (item) => item.deliveredQuantity < 0
    )

    if (negativeQuantityError.length > 0) {
      toastr.warning(
        'Não foi possível realizar a requisição.',
        'A quantidade entregue não pode ser menor que zero.'
      )
      setLoading(false)
      setConfirmModal(false)
      return false
    }

    return true
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const formatedData = {
        saleRequisition: {
          date: format(new Date(), 'yyyy-MM-dd'),
          status: 'Entregue',
          ...data.saleRequisition,
        },
        saleRequisitionItems: data.saleRequisitionItems.filter(item => parseInt(item.deliveredQuantity) > 0 && parseInt(item.availableQuantity) > 0).map((item) => ({
          saleItemId: item.saleItemId,
          availableQuantity: item.availableQuantity,
          deliveredQuantity: parseInt(item.deliveredQuantity) || 0,
          deliveredDate: item.deliveredDate || format(new Date(), 'yyyy-MM-dd'),
          quantityOnSale: item.quantityOnSale
        })),
      }

      if (!validations(formatedData)) return

      await PartsRequisitionRepository.create(formatedData)
      setConfirmModal(false)
      history.push('/client/parts-requisition')
      toastr.success(
        'Requisição realizada com sucesso!',
        'Os produtos foram baixados do estoque.'
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível realizar a requisição.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
  }

  const loadViewData = async () => {
    setLoading(true)
    try {
      const viewData = await PartsRequisitionRepository.view(hasId)

      setSelectedCustomer({
        value: viewData?.header[0]?.saleId,
        label: viewData?.header[0]?.label,
      })

      setValue('saleCode', viewData?.header[0]?.saleCode)
      setValue('customerName', viewData?.header[0]?.customerName)
      setValue('employeeName', viewData?.header[0]?.employeeName)

      append(viewData?.saleRequisitionItems)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível carregar a requisição.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
  }

  const handleCustomerChange = (item) => {
    setSelectedCustomer(item)
    setValue('customerId', item.value)
  }

  useEffect(() => {
    if (hasId) {
      loadViewData()
    }
  }, [hasId])

  useEffect(() => {
    if (!hasId) {
      loadPartRequisitionData()
    }
  }, [selectedCustomer])

  useEffect(() => {
    loadSalesOptions()
  }, [])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <NewRequisitionHeader
          formattedOptions={formattedOptions}
          loading={loading}
          setValue={handleCustomerChange}
          value={selectedCustomer}
          register={register}
          hasId={hasId}
        />
        <NewRequisitionTable
          loading={loading}
          data={fields}
          control={control}
          hasId={hasId}
        />
        <NewRequisitionFormButtons
          onSubmit={() => setConfirmModal(true)}
          hasId={hasId}
          disabledSubmit={!fields.length}
          data={fields}
          loading={loading}
          onCancel={() => history.push('/client/parts-requisition')}
        />
      </div>

      {confirmModal && (
        <AlertModal
          show={confirmModal}
          title="OS Digital"
          subtitle="Você tem certeza que deseja confirmar essa entrega?"
          message="A partir do momento que for finalizado, as peças serão baixadas do estoque e confirmado a entrega pro cliente."
          onSubmit={handleSubmit(onSubmit)}
          onCancel={() => setConfirmModal(false)}
          onHide={() => setConfirmModal(false)}
          loading={loading}
        />
      )}
    </>
  )
}

export default NewRequisitionMain
