import api from '../services/api';

export const getChecklistSerializedBySaleId = async (saleId) => {
  try {
    const res = await api.get('/checklist-sales/' + saleId + '/serialized');
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listAllChecklist = async (params) => {
  try {
    const res = await api.get('/checklists', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getBySaleId = async (id, companyId) => {
  try {
    const res = await api.get(`/checklist-sales/${id}?companyId=${companyId}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createChecklistSales = async (params) => {
  try {
    const res = await api.post('/checklist-sales/', {
      ...params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateChecklistSales = async (id, params) => {
  try {
    const res = await api.put(`/checklist-sales/${id}`, {
      ...params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const ChecklistSalesRepository = {
  getBySaleId,
  listAllChecklist,
  createChecklistSales,
  updateChecklistSales,
  getChecklistSerializedBySaleId,
};
