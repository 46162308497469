import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'

import GeneralQuotationReportDocument from './GeneralQuotationReportDocument'
import QuotationsRepository from './../../../../repositories/Quotations'
import produce from 'immer'
import { format } from 'date-fns'

const GeneralQuotationReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [quotationInfo, setQuotationInfo] = useState({})
  const [quotationResume, setQuotationResume] = useState([])
  const [quotationItemsWithResponses, setQuotationItemsWithResponses] =
    useState([])
  const [quotationObservations, setQuotationObservations] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadReportData(searchParams)
  }, [])

  function getObservationsPerProviderIdentifier(quotation) {
    const providers = quotation.QuotationProviders
    const responses = quotation.QuotationResponses

    const observations = providers.map((prov) => {
      const responseOfThisProvider = responses.find(
        (res) => res.providerId === prov.Provider.id
      )

      if (responseOfThisProvider) {
        return {
          identifier: prov.provider_identifier,
          observations: responseOfThisProvider.observations
            ? responseOfThisProvider.observations
            : '-',
        }
      } else {
        return {
          identifier: prov.provider_identifier,
          observations: '-',
        }
      }
    })

    return observations
  }

  function getQuotationResponsesByItem(itemId, responses, providers) {
    const responsesByProvider = providers.map((prov) => {
      const response = responses.find(
        (res) => res.providerId === prov.Provider.id
      )
      if (response) {
        const thisItemResponse = response.QuotationResponseItems.find(
          (item) => item.quotationItemId === itemId
        )
        return {
          identifier: prov.provider_identifier,
          avaliableQuantity: thisItemResponse.avaliable_quantity,
          unitValue: thisItemResponse.unit_value,
          total:
            thisItemResponse.unit_value * thisItemResponse.avaliable_quantity,
        }
      } else {
        return {
          identifier: prov.provider_identifier,
          avaliableQuantity: null,
          unitValue: null,
          total: null,
        }
      }
    })

    return responsesByProvider
  }

  function getQuotationItems(quotation) {
    const items = quotation.QuotationItems.map((item) => {
      return {
        id: item.id,
        manufacturer_ref: item.manufacturer_ref,
        brand: item.brand,
        description: item.description,
        quantity: item.quantity,
        code: item.Product?.code,
        responses: getQuotationResponsesByItem(
          item.id,
          quotation.QuotationResponses,
          quotation.QuotationProviders
        ),
      }
    })

    return items
  }

  function getProviderResponseStatus(id, quotationResponses) {
    const providerResponse = quotationResponses.find(
      (item) => item.providerId === id
    )

    if (providerResponse) {
      return `Respondida - ${format(
        new Date(providerResponse.createdAt),
        'dd/MM/yyyy HH:mm'
      )}`
    } else {
      return 'Não Respondida'
    }
  }

  function getQuotationResume(quotation) {
    const resume = quotation.QuotationProviders.map((item) => {
      return {
        id: item.id,
        identifier: item.provider_identifier,
        phone: item.provider_phone,
        name: item.Provider.companyName,
        cpfCnpj: item.Provider.cpfCnpj,
        status: getProviderResponseStatus(
          item.Provider.id,
          quotation.QuotationResponses
        ),
      }
    })

    return resume
  }

  const loadReportData = async (searchParams) => {
    try {
      const id = searchParams.get('id')

      if (!id) throw new Error('quotation id not provided')

      const res = await QuotationsRepository.getGeneralReport(id)

      const quotationInfo = {
        id: res.data.id,
        code: res.data.code,
        observations: res.data.observations,
      }

      setQuotationInfo(quotationInfo)
      setQuotationResume(getQuotationResume(res.data))
      setQuotationItemsWithResponses(getQuotationItems(res.data))
      setQuotationObservations(getObservationsPerProviderIdentifier(res.data))
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <GeneralQuotationReportDocument
            quotationInfo={quotationInfo}
            quotationResume={quotationResume}
            quotationItemsWithResponses={quotationItemsWithResponses}
            quotationObservations={quotationObservations}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default GeneralQuotationReport
