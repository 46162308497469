import React from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import NewsRegisterForm from './NewsRegisterForm';
import newsRepository from '../../../../../repositories/News';
import { useAdminAuth } from 'contexts/adminAuth';
import { useEffect, useState } from 'react';
import { getbrandingNameById } from 'v2/helpers/brandingHelpers';
import s3Repository from 'repositories/S3';

function NewsRegisterMain({ newsId, onSubmit, onCancel }) {
  const { admin } = useAdminAuth();
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (newsId) {
      loadNewsToUpdate(newsId);
    }
  }, [newsId]);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      description: '',
      url: '',
      adminId: admin.id,
      brandingId: { label: 'Ambos', value: '' },
      imageUrl: '',
    },
  });

  const validations = (values) => {
    const { description, url } = values;

    if (!description) {
      toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos com dados válidos e tente novamente'
      );
      return false;
    }

    const checkBlankSpace = (string) => string.trim().length === 0;

    if (checkBlankSpace(description) || checkBlankSpace(url)) {
      toastr.warning(
        'Insira uma descrição e um link válido para cadastrar a sua notícia e tente novamente.'
      );
      return false;
    }

    const checkBlankSpaceURL = (string) => /\s/g.test(string);

    if (checkBlankSpaceURL(url)) {
      toastr.warning(
        'Insira um link válido para sua notícia e tente novamente.'
      );
      return false;
    }

    return true;
  };

  const submit = (values) => {
    values.description = values.description.trim();
    values.url = values.url.trim();

    if (!validations(values)) {
      return;
    }

    !!newsId ? update(values) : create(values);
  };

  const create = async (values) => {
    if (!imageFile) {
      return toastr.warning(
        'Selecione uma imagem',
        'É obrigatória a seleção de uma imagem para cadastrar as News'
      );
    }

    try {
      const imageUrl = await s3Repository.uploadImage({
        file: imageFile,
        name: Math.floor(Math.random() * 100001),
      });

      await newsRepository.create({
        ...values,
        brandingId: values.brandingId.value || null,
        imageUrl,
      });

      toastr.success('Notícia cadastrada com sucesso.');
      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.warning(
        err.response?.data?.message ??
          'Ocorreu um erro ao registrar a notícia. Por favor, tente novamente'
      );
    }
  };

  const loadNewsToUpdate = async (newsId) => {
    try {
      const newsToUpdate = await newsRepository.getById(newsId);

      const brandingIdFormatted = {
        value: newsToUpdate.brandingId || '',
        label: newsToUpdate.brandingId
          ? getbrandingNameById(newsToUpdate.brandingId)
          : 'Ambos',
      };

      setValue('description', newsToUpdate.description);
      setValue('url', newsToUpdate.url);
      setValue('imageUrl', newsToUpdate.imageUrl);
      setValue('brandingId', brandingIdFormatted);
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar a notícia. Por favor, tente novamente'
      );
    }
  };

  const update = async (values) => {
    try {
      let imageUrl = values.imageUrl;

      if (imageFile) {
        imageUrl = await s3Repository.uploadImage({
          file: imageFile,
          name: Math.floor(Math.random() * 100001),
        });
      }

      if (values.imageUrlToRemove) {
        await s3Repository.deleteImages([values.imageUrlToRemove]);
      }

      await newsRepository.update(newsId, {
        ...values,
        brandingId: values.brandingId.value || null,
        imageUrl,
      });

      toastr.success('Notícia atualizada com sucesso.');
      onSubmit();
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao atualizar a notícia. Por favor, tente novamente'
      );
    }
  };

  return (
    <NewsRegisterForm
      onSubmit={submit}
      newsId={newsId}
      register={register}
      watch={watch}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isDirty={isDirty}
      onCancel={onCancel}
      getValues={getValues}
      control={control}
      setImageFile={setImageFile}
      imageFile={imageFile}
    />
  );
}

export default NewsRegisterMain;
