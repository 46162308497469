import React from 'react';


export function BankConciliationButtonsActions({
  onNoClick,
  onYesClick,
  noLabel = 'Não',
  yesLabel = 'Sim',
  isNoDisabled = false,
  isYesDisabled = false  }) {
  return (
    <div className='flex end gap-0.25' /* style={{ display: 'flex', flexDirection: 'row', gap: '10px' }} */>
      <button
        className="button button-h35 button-red flex center"
        style={{ marginRight: '10px', gap: '10px', alignItems: 'center' }}
        onClick={onNoClick}
         disabled={isNoDisabled}
      >
        {noLabel}
      </button>
      <button
        className="button button-h35 button-green flex center"
        style={{ marginRight: '10px', gap: '10px', alignItems: 'center' }}
        onClick={onYesClick}
        disabled={isYesDisabled}
      >
        {yesLabel}
      </button>
    </div>
  );
}
