import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import AlertModal from '../../../../components/AlertModal'
import Button from '../../../components/CustomButton/CustomButton'
import CurrencyInput from '../../../components/Currency'
import cashierBanksRepository from '../../../../repositories/CashierBank'
import cashierBanksHistoricRepository from '../../../../repositories/CashierBanksHistoric'

import { useAuth } from '../../../../contexts/auth'

import './styles.css'

const OpenCashierBankHistoricModal = ({ onCancel, onSubmit, ...rest }) => {
  const [cashierBanks, setCashierBanks] = useState([])
  const [initialBalance, setInitialBalance] = useState(0)
  const [observations, setObservations] = useState('')
  const [cashierBankId, setCashierBankId] = useState('')
  const [defaultCashierBankId, setDefaultCashierBankId] = useState('')
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false)
  const [isConfirmOpenCashierModalOpen, setIsConfirmOpenCashierModalOpen] =
    useState(false)

  const [loading, setLoading] = useState(false)

  const { companyId, company } = useAuth()

  useEffect(() => {
    if (company) {
      setDefaultCashierBankId(company.defaultCashierBankId)
    }
  }, [company])

  useEffect(() => {
    if (companyId) {
      loadCashierBanks()
    }
  }, [companyId])

  useEffect(() => {
    if (cashierBanks.length === 1) {
      setCashierBankId(cashierBanks[0].id)
    } else {
      const defaultCashierBank = cashierBanks.some(
        (cashierBank) => cashierBank.id === defaultCashierBankId
      )
      if (defaultCashierBank) {
        setCashierBankId(defaultCashierBankId)
      }
    }
  }, [cashierBanks, defaultCashierBankId])

  async function loadCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getAllByCompanyAndTypeCashier(companyId)
      const serializedCashierBanks = cashierBanks.map((cashierBank) => ({
        id: cashierBank.id,
        description: cashierBank.description,
        initials: cashierBank.CashierBankType.initials,
      }))
      setCashierBanks(serializedCashierBanks)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao trazer os caixas. Por favor, tente novamente'
      )
    }
  }

  function handleOpenConfirmCancel() {
    if (isAnyFieldFilled) {
      return setIsConfirmCancelModalOpen(true)
    }
    onCancel()
  }

  async function handleOpenCashier() {
    try {
      if (loading) return
      setLoading(true)
      setIsConfirmOpenCashierModalOpen(false)
      const cashierBankHistoric = await cashierBanksHistoricRepository.create({
        companyId,
        cashierBankId,
        openDate: new Date().getTime(),
        initialValue: initialBalance,
        observations,
        isOpen: true,
      })
      toastr.success('Abertura de caixa realizada com sucesso.')
      setIsConfirmOpenCashierModalOpen(false)
      onSubmit(cashierBankHistoric)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (err.response) {
        toastr.warning(err.response.data.message)
        return
      }
      toastr.warning(
        'Ocorreu um erro ao abrir o caixa. Por favor, tente novamente'
      )
    }
  }

  const isAnyFieldFilled = initialBalance || observations || cashierBankId

  const handleChangeInitialBalance = (value) => {
    setInitialBalance(Number(value))
    document.getElementById('initial-balance').blur()
    setTimeout(() => {
      document.getElementById('initial-balance').focus()
    }, 100)
  }

  return (
    <>
      <Modal
        show
        animation
        dialogClassName="open-cashier-bank-historic-modal"
        {...rest}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Abertura de Caixa</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset>
            <label htmlFor="cashier">
              <strong>Caixa:</strong>
            </label>
            <select
              name="cashier"
              id="cashier"
              className="form-control foco-input"
              value={cashierBankId}
              onChange={(e) => setCashierBankId(e.target.value)}
              disabled={cashierBanks.length === 1}
            >
              <option value="">Selecione</option>
              {cashierBanks.map((cashierBank) => (
                <option value={cashierBank.id} key={cashierBank.id}>
                  {cashierBank.initials} - {cashierBank.description}
                </option>
              ))}
            </select>
          </fieldset>
          <fieldset>
            <label htmlFor="initial-balance">Saldo de início:</label>
            <CurrencyInput
              id="initial-balance"
              className="form-control foco-input"
              value={initialBalance}
              onChangeEvent={(e) => handleChangeInitialBalance(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="observations">Observações:</label>
            <textarea
              id="observations"
              className="form-control foco-input"
              rows="3"
              maxLength={200}
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
            />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="danger"
            fill
            onClick={handleOpenConfirmCancel}
            disabled={loading ? 'disabled' : ''}
          >
            Cancelar
          </Button>
          <Button
            bsStyle="info"
            fill
            onClick={() => setIsConfirmOpenCashierModalOpen(true)}
            disabled={loading ? 'disabled' : ''}
          >
            <span
              className={
                loading
                  ? 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  : 'update-spinner-disabled'
              }
            />
            Abrir Caixa
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="Cancelamento de abertura de Caixa"
        message="Deseja cancelar a abertura de caixa? A abertura poderá ser feita posteriormente."
        show={isConfirmCancelModalOpen}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />

      <AlertModal
        show={isConfirmOpenCashierModalOpen}
        title="Abertura de caixa"
        message="Deseja realizar a abertura de caixa? O processo de abertura é irreversível e só poderá ser fechado posteriormente."
        onCancel={() => setIsConfirmOpenCashierModalOpen(false)}
        onHide={() => setIsConfirmOpenCashierModalOpen(false)}
        onSubmit={handleOpenCashier}
      />
    </>
  )
}

export default OpenCashierBankHistoricModal
