import api from '../services/api'

const getReport = async (params) => {
  try {
    const response = await api.get('report/sales/by-attendance', { params })
    return response.data
  } catch (err) {
    throw err
  }
}

const SalesByAttendanceRepository = {
  getReport,
}

export default SalesByAttendanceRepository
