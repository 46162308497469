import React, { useEffect, useState } from 'react';

import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import constants from '../../utils/constants';
import CustomerRepository from '../../repositories/Customers';
import CompaniesRepository from '../../repositories/Companies';
import SalesRepository from '../../repositories/Sales';
import PrismaBoxRepository from 'repositories/PrismaBox';

import { MotosSheet } from './MotosSheet';
import { VanSheet } from './VanSheet';
import { SuivSheet } from './SuivSheet';
import { CarrosSheet } from './CarrosSheet';
import { PesadosSheet } from './PesadosSheet';
import { BicicletasSheet } from './BicicletasSheet';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { PDFViewer } from '@react-pdf/renderer';
import { useAuth } from 'contexts/auth';

import suivCompanyRepository from 'repositories/SuivCompany';
import { CustomChecklistSheet } from './CustomSheet';
import { getChecklistSerializedBySaleId } from 'v2/repositories/ChecklistSalesRepository';

export const SheetsChecklistPrint = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [type, setType] = useState('Carros');
  const [customer, setCustomer] = useState({});
  const [suivInformation, setSuivInformation] = useState({});
  const [sale, setSale] = useState({});
  const [checklist, setChecklist] = useState({});
  const [displayNotes, setDisplayNotes] = useState(null);
  let kilometersActual = null;
  const { companyId } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams && companyId) return loadQuery(searchParams);
  }, [location.search, companyId]);

  const loadQuery = async (searchParams) => {
    try {
      const typeQuery = searchParams.get('type').trim();
      setType(typeQuery);
      const clientIdHash = searchParams.get('clientId');
      const vehicleIdHash = searchParams.get('vehicleId');
      const saleIdHash = searchParams.get('saleId');
      const prismaBoxIdHash = searchParams.get('prismaBoxId');
      const revisionIndex = searchParams.get('revisionIndex');
      const km = searchParams.get('Kilometers');
      kilometersActual = km;

      let clientId;
      let vehicleId;
      let saleId;
      let prismaBoxId;

      if (clientIdHash) {
        clientId = Number(await decrypt(clientIdHash, '@OS-dig:clientId'));
      }

      if (vehicleIdHash) {
        vehicleId = Number(await decrypt(vehicleIdHash, '@OS-dig:vehicleId'));
      }

      if (saleIdHash) {
        saleId = Number(await decrypt(saleIdHash, '@OS-dig:saleId'));
      }

      if (prismaBoxIdHash) {
        prismaBoxId = Number(
          await decrypt(prismaBoxIdHash, '@OS-dig:prismaBoxId')
        );
      }

      if (!clientId) return handleLoadDataWithoutClientId();

      handleLoadData(
        clientId,
        vehicleId,
        saleId,
        prismaBoxId,
        typeQuery,
        revisionIndex
      );
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const renderPdf = () => {
    switch (type) {
      case 'custom':
        return (
          <CustomChecklistSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
            checklist={checklist}
          />
        );
      case constants.SEGMENTS_DESCRIPTION.Motos:
        return (
          <MotosSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
          />
        );
      case constants.SEGMENTS_DESCRIPTION.Carros:
        return (
          <CarrosSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
          />
        );
      case constants.SEGMENTS_DESCRIPTION.Pesados:
        return (
          <PesadosSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
          />
        );
      case constants.SEGMENTS_DESCRIPTION.Van:
        return (
          <VanSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
          />
        );
      case constants.SEGMENTS_DESCRIPTION.Bicicleta:
        return (
          <BicicletasSheet
            customer={customer}
            sale={sale}
            displayNotes={displayNotes}
          />
        );
      case 'SUIV':
        return (
          <SuivSheet customer={customer} suivInformation={suivInformation} />
        );
      default:
        setHasError(true);
        break;
    }
  };

  const handleLoadDataWithoutClientId = async () => {
    try {
      const company = await CompaniesRepository.getById(companyId);

      const checkListData = {
        company,
        vehicle: {},
        date: new Date(),
      };

      setDisplayNotes(company.displayNotesInChecklist);
      setCustomer(checkListData);
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadData = async (
    clientId,
    vehicleId,
    saleId,
    prismaBoxId,
    type,
    revisionIndex
  ) => {
    try {
      const { data } = await CustomerRepository.getClientByIdForChecklistSheet(
        clientId
      );

      let prismaBox;
      if (prismaBoxId) {
        const { data: prismaBoxData } = await PrismaBoxRepository.show(
          prismaBoxId
        );
        prismaBox = prismaBoxData.description;
      }

      const company = data.Company;
      delete data.Company;

      const { displayNotesInChecklist } = await CompaniesRepository.getById(
        companyId
      );

      setDisplayNotes(displayNotesInChecklist);

      if (type === 'SUIV') {
        const vehicle = data.Vehicle.find((v) => v.id === vehicleId);

        const { data: suivData } = await suivCompanyRepository.showVehicleInfo(
          vehicle.License_Plate,
          companyId
        );

        setSuivInformation(suivData.revisionPlan[revisionIndex]);
      }

      if (saleId) {
        const sale = await SalesRepository.getSaleToPrint(saleId);

        setSale(sale);
      }

      const checkListData = {
        ...data,
        company,
        prismaBox,
        vehicle: vehicleId ? data.Vehicle.find((v) => v.id === vehicleId) : {},
        date: new Date(),
      };
      delete checkListData.Vehicle;
      const km =
        kilometersActual > 0
          ? kilometersActual
          : checkListData?.vehicle?.Kilometers;

      if (checkListData.vehicle) {
        checkListData.vehicle.Kilometers = km;
      }

      setCustomer(checkListData);

      if (type === 'custom') {
        const checklistData = await getChecklistSerializedBySaleId(saleId);
        setChecklist(checklistData);
      }
    } catch (err) {
      console.log(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  if (!!customer)
    return (
      <div id="sale-printing-sheet-page">
        <main style={{ height: '100vh', width: '100vw' }}>
          <PDFViewer style={{ height: '100%', width: '100%' }}>
            {renderPdf()}
          </PDFViewer>
        </main>
      </div>
    );
};
