import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from "client/components/CustomButton/CustomButton.jsx";

const AccessDeniedModal = ({ show, animation, onCancel }) =>{
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Acesso negado</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>O seu usuário não possui acesso para essa função.</strong>
        <br />
        <br />
        <span>Caso necessite de acesso, entre em contato com o administrador.</span>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button bsStyle="danger" name="Voltar" onClick={onCancel} fill>Voltar</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AccessDeniedModal