import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import StoneModal from '../../../assets/img/StoneModal/stone.png'
import './index.css'

export default function AlertModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={() => (props?.loading ? {} : props.setShow(false))}
      dialogClassName='modal-80w'    
    >
      <Modal.Header >
        <Modal.Title>
          <strong>{!props.title ? 'Máquinas Homologadas' : props.title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Após realizar o credenciamento, uma máquina dessas é enviada.<br/>
     <strong>Qualquer máquina diferente das apresentadas, não funcionará com o  OS Digital. Será necessário entrar em contato com a Stone e informar a <br/> 
      situação
     </strong>
      <div className='stone-machines-container'>
        <img src={StoneModal} />
      </div>
      
      </Modal.Body>
      {!!props.customFooter
        ?
        <Modal.Footer>    
          {props.customFooter}
        </Modal.Footer>
        :
        <Modal.Footer>        
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle='danger'
              disabled={props?.loading}
              name='Voltar'
              onClick={() => props.setShow(false)}
              fill
            >
              Voltar
            </Button>
          </div>              
        </Modal.Footer>
      }      
    </Modal>
  )
}
