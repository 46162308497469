import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import CustomersListPDF from './CustomersListPDF'
import customersRepository from 'repositories/Customers'

const CustomersListReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])
  const [type, setType] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId')
      if (!companyId) {
        setHasError(true)
        setLoading(false)
      }
      const customerType = searchParams.get('customerType')
      const state = searchParams.get('state')
      const status = searchParams.get('status')
      const type = searchParams.get('type')

      const dados = await loadReport(
        companyId,
        customerType,
        state,
        status,
        type
      )

      setType(type)
      setDataReport(dados)
      setLoading(false)
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (companyId, customerType, state, status, type) => {
    try {
      const params = {
        customerType,
        state,
        status,
        type,
      }

      const data = await customersRepository.getCustomerList(companyId, params)

      return data
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <CustomersListPDF
            responseData={dataReport}
            reportType={type}
          />
        </PDFViewer>
      </main>
    </div>
  )
}

export default CustomersListReport
