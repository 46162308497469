import React, { useState } from 'react';
import { faCheckDouble, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertModal from 'components/AlertModal/AlertModal';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange, recalculateTotals } from '../redux/actions';

const ContentCancelationConfirmationModal = () => (
  <div>
    <span>Você tem certeza que deseja cancelar o item?</span>
    <br />
    <br />
    <span>
      Ao cancelar o item, os valores serão removidos ao total da venda, e em
      caso de produto, o estoque{' '}
      <strong>
        <u>não sofrerá</u>
      </strong>{' '}
      baixa ao finalizar a venda/os.
    </span>
    <br />
    <br />
    <span
      style={{
        fontSize: '12px',
        color: '#d43f3a',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Ao cancelar o item, se houver apontamento vinculado, o mesmo será
      excluído.
    </span>
  </div>
);

const ContentReactivationConfirmationModal = () => (
  <div>
    <span>Você tem certeza que deseja reativar o item?</span>
    <br />
    <br />
    <span>
      Ao reativar o item, os valores serão somados ao total da venda, e em caso
      de produto, o estoque sofrerá baixa ao finalizar a venda/os.
    </span>
  </div>
);

export function ItemCancellationActionButtons({ item, index }) {
  const dispatch = useDispatch();
  const { saleReducer } = useSelector((state) => state);
  const { items, saleStatusId } = saleReducer;

  const isDisabled =
    saleStatusId === 5 ||
    saleStatusId === 2 ||
    saleStatusId === 6 ||
    saleStatusId === 9 ||
    saleStatusId === 7;

  const [
    isCancellationConfirmationModalOpen,
    setIsCancellationConfirmationModalOpen,
  ] = useState(false);

  const [
    isReactivationConfirmationModalOpen,
    setIsReactivationConfirmationModalOpen,
  ] = useState(false);

  const handleCancelItem = () => {
    let newItems = [...items];
    newItems[index].isCancelled = true;
    dispatch([handleChange(newItems, 'items'), recalculateTotals(items)]);
  };

  const handleReactivateItem = () => {
    const newItems = [...items];
    newItems[index].isCancelled = false;

    dispatch(handleChange(newItems, 'items'));
    dispatch([handleChange(newItems, 'items'), recalculateTotals(items)]);
  };

  if (item.isCancelled) {
    return (
      <>
        <FontAwesomeIcon
          icon={faCheckDouble}
          title="Reativar Item"
          disabled={isDisabled}
          style={{
            color: '#4cae4c',
            cursor: 'pointer',
            width: '1.3em',
            height: '1.3em',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
          aria-hidden="false"
          onClick={() => {
            if (
              saleReducer.saleStatusId !== 2 &&
              saleReducer.saleStatusId !== 5
            ) {
              setIsReactivationConfirmationModalOpen(true);
            }
          }}
        />

        <AlertModal
          show={isReactivationConfirmationModalOpen}
          title="Reativar o Item"
          onSubmit={handleReactivateItem}
          onCancel={() => setIsReactivationConfirmationModalOpen(false)}
          onHide={() => setIsReactivationConfirmationModalOpen(false)}
          message={<ContentReactivationConfirmationModal />}
        />
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faXmark}
        title="Cancelar Item"
        style={{
          color: '#d43f3a',
          cursor: 'pointer',
          width: '1.6em',
          height: '1.6em',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
        aria-hidden="false"
        onClick={() => {
          if (saleReducer.saleStatusId !== 2 && saleReducer.saleStatusId !== 5)
            setIsCancellationConfirmationModalOpen(true);
        }}
      />

      <AlertModal
        show={isCancellationConfirmationModalOpen}
        title="Cancelamento de Item"
        onSubmit={handleCancelItem}
        onCancel={() => setIsCancellationConfirmationModalOpen(false)}
        onHide={() => setIsCancellationConfirmationModalOpen(false)}
        message={<ContentCancelationConfirmationModal />}
      />
    </>
  );
}
