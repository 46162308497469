import React from 'react';
import { Modal } from 'react-bootstrap';

import BundleFormNewAccountPlan from 'client/views/AccountPlans/NewAccountPlan/BundleFormNewAccountPlan';

const NewAccountPlanModal = ({ onCancel, newAccountModalType, onSubmit }) => {
  return (
    <Modal show animation onHide={onCancel} dialogClassName="modal-90w">
      <Modal.Header>
        <Modal.Title>
          <strong>Nova conta</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BundleFormNewAccountPlan
          newAccountModalType={newAccountModalType}
          onCancel={onCancel}
          handleModalSubmit={onSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

export default NewAccountPlanModal;
