import React, { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Toggle from 'react-toggle';

import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { InputCurrency } from 'v2/components/Input';
import { InputPercentage } from 'v2/components/Input';

import CollapsibleSection from 'components/CollapsibleSection';

import { useAuth } from 'contexts/auth';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import PaymentConditionsRepository from 'repositories/PaymentConditions';

import {
  daysBetweenInstallmentOptions,
  hasEntryOptions,
  installmentQuantityOptions,
  isFeeActiveOptions,
} from './helpers';

export const RegisterPaymentConditionForm = ({
  setIsUnavailableFeatureModalOpen,
}) => {
  const { companyId } = useAuth();
  const { register, control, setValue } = useFormContext();
  const { isActive, isFeeActive, showAsSuggestion } = useWatch({
    control,
  });

  const { isWorkmotor, isPlanFree, isPlanStart, isPlanWorkmotorFree } =
    usePlanSignatureContext();
  const isPlanPaidWorkmotor = isWorkmotor && !isPlanWorkmotorFree;
  const isPlanFreeOrStart = isPlanFree || isPlanStart;

  const isPlanPaidAnyProduct =
    isPlanPaidWorkmotor || (!isPlanFreeOrStart && !isWorkmotor);

  const validateActiveSuggestions = useCallback(async () => {
    try {
      const activeSuggestions =
        await PaymentConditionsRepository.getAllActiveSuggestionsCount(
          companyId
        );

      if (activeSuggestions.count === 3) {
        toastr.warning(
          'Limite de sugestões atingido',
          ' Existem 3 sugestões com a sugestão ativada. Desative em uma delas para ativar essa.'
        );
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      toastr.error(
        currentBrandingName,
        'Erro ao validar sugestões ativas. Tente novamente!'
      );
      return false;
    }
  }, [companyId]);

  const handleIsActiveToggleClick = async () => {
    const newValue = !isActive;

    if (newValue === true) {
      const suggestionsValidation = await validateActiveSuggestions();

      if (!suggestionsValidation && showAsSuggestion && isPlanPaidWorkmotor) {
        setValue('showAsSuggestion', false);
      }
    }

    setValue('isActive', newValue);
  };

  const handleShowAsSuggestionToggleClick = async () => {
    const newValue = !showAsSuggestion;

    if (newValue === true) {
      if (!isPlanPaidWorkmotor) {
        setIsUnavailableFeatureModalOpen(true);
        return;
      }

      const suggestionsValidation = await validateActiveSuggestions();
      if (!suggestionsValidation) return;
    }

    setValue('showAsSuggestion', newValue);
  };

  return (
    <div className="register-payment-condition__form-container">
      <CollapsibleSection
        title="Dados da Condição de Pagamento"
        handleExpand={() => null}
        isExpanded
      >
        <form className="register-payment-condition__form" method="POST">
          <div className="register-payment-condition__form-group">
            <InputContainer>
              <InputLabel
                fontWeight={'normal'}
                htmlFor="input__description"
                required
              >
                Descrição:
              </InputLabel>
              <InputText
                id="input__description"
                width={400}
                {...register('description')}
                required
              />
            </InputContainer>
            <InputContainer justify="flex-start">
              <InputLabel
                fontWeight={'normal'}
                htmlFor="input__installments-quantity"
                required
              >
                Qtd. de Parcelas:
              </InputLabel>
              <Controller
                name={`installmentsQuantity`}
                control={control}
                render={({ field }) => {
                  return (
                    <InputSelectDefault
                      id="input__installments-quantity"
                      height="35px"
                      width="150px"
                      options={installmentQuantityOptions}
                      required
                      {...field}
                    />
                  );
                }}
              />
            </InputContainer>
            <InputContainer justify="flex-start">
              <InputLabel
                fontWeight={'normal'}
                htmlFor="input__days-between-installments"
                required
              >
                Dias Entre Parcelas:
              </InputLabel>
              <Controller
                name={`daysBetweenInstallment`}
                control={control}
                render={({ field }) => {
                  return (
                    <InputSelectDefault
                      id="input__days-between-installments"
                      height="35px"
                      width="150px"
                      options={daysBetweenInstallmentOptions}
                      required
                      {...field}
                    />
                  );
                }}
              />
            </InputContainer>
            <InputContainer justify="flex-start">
              <InputLabel
                fontWeight={'normal'}
                htmlFor="input__has-entry"
                required
              >
                Possui Entrada?
              </InputLabel>
              <Controller
                name={`hasEntry`}
                control={control}
                render={({ field }) => {
                  return (
                    <InputSelectDefault
                      id="input__has-entry"
                      height="35px"
                      width="150px"
                      options={hasEntryOptions}
                      required
                      {...field}
                    />
                  );
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel fontWeight={'normal'} htmlFor="input__min-value">
                Valor Mínimo?
              </InputLabel>
              <Controller
                name={`minValue`}
                control={control}
                render={({ field }) => {
                  return (
                    <InputCurrency
                      id="input__min-value"
                      width={120}
                      disabled={!isPlanPaidWorkmotor}
                      {...field}
                      {...(!isPlanPaidWorkmotor
                        ? { onChange: () => null }
                        : {})}
                    />
                  );
                }}
              />
            </InputContainer>

            <InputContainer variant="column" align="center">
              <InputLabel fontWeight={'normal'} htmlFor="input__is-active">
                Status
              </InputLabel>

              <div className="flex center" style={{ height: '40px' }}>
                <Toggle
                  id="input__is-active"
                  checked={isActive}
                  onChange={() => handleIsActiveToggleClick()}
                />
              </div>
            </InputContainer>
          </div>
          <div className="register-payment-condition__form-group">
            {isPlanPaidAnyProduct && (
              <InputContainer justify="flex-start">
                <InputLabel
                  fontWeight={'normal'}
                  htmlFor="input__is-fee-active"
                  required
                >
                  Possui Juros?
                </InputLabel>
                <div className="flex gap-050">
                  <Controller
                    name={`isFeeActive`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputSelectDefault
                          id="input__is-fee-active"
                          height="35px"
                          width="150px"
                          options={isFeeActiveOptions}
                          {...field}
                        />
                      );
                    }}
                  />

                  <Controller
                    name={`feePercent`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputPercentage
                          width={100}
                          disabled={!isFeeActive.value}
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
              </InputContainer>
            )}
            <InputContainer variant="column" align="center">
              <InputLabel
                fontWeight={'normal'}
                htmlFor="input__show-as-suggestion"
              >
                Sugestão de Pagamento?
              </InputLabel>
              <div className="flex center" style={{ height: '40px' }}>
                <Toggle
                  id="input__show-as-suggestion"
                  checked={showAsSuggestion}
                  onChange={() => handleShowAsSuggestionToggleClick()}
                />
              </div>
            </InputContainer>
          </div>
        </form>
      </CollapsibleSection>
    </div>
  );
};
