import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import ReactTable from "react-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { toastr } from 'react-redux-toastr'
import CardForm from 'client/components/CardForm'
import useFilters from '../../../hooks/useFilters'
import providersRepository from '../../../repositories/Providers'
import AlertModal from 'components/AlertModal/AlertModal'
import SelectProductModal from './SelectProductModal'

import './styles.css'

const ProviderProducts = ({ providerId }) => {
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [products, setProducts] = useState([])
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isSelectProductModalOpen, setIsSelectProductModalOpen] = useState(false)
  const [provider, setProvider] = useState({
    companyName: '',
    tradingName: '',
    cnpj: '',
    IE: ''
  })

  const { queryFilter: { query, setQuery, filterByQuery } } = useFilters()

  useEffect(() => {
    if (!!providerId) {
      loadProvider()
    }
  }, [])

  useEffect(() => {
    if (!isSelectProductModalOpen && !isRemoveModalOpen) {
      setProduct(null)
    }
  }, [isSelectProductModalOpen, isRemoveModalOpen])

  const loadProvider = async () => {
    setLoading(true)
    try {

      const provider = await providersRepository.getProviderAndProducts(providerId)

      const serializedProvider = {
        companyName: provider.companyName,
        tradingName: provider.tradingName,
        cnpj: provider.cpfCnpj,
        IE: provider.IE,
      }

      const serializedProducts = provider.Products.map(product => ({
        id: product.id,
        code: product.code,
        description: product.Description,
        manufacturerRef: product.Manufacturer_Ref,
        productProviderCode: product.ProvidersProducts.productProviderCode
      }))

      setProvider(serializedProvider)
      setProducts(serializedProducts)
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar os produtos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenRemoveModal = product => {
    setProduct(product)
    setIsRemoveModalOpen(true)
  }

  const handleRemoveProduct = async () => {
    setIsRemoveModalOpen(false)
    setLoading(true)

    try {
      await providersRepository.deleteProductRelationship(providerId, product.id)
      const newProducts = products.filter(child => child !== product)

      setProducts(newProducts)
    } catch (err) {

      console.log(err)
      toastr.warning('Ocorreu um erro ao remover o produto. Por favor, tente novamente')

    } finally {
      setLoading(false)
    }
  }

  const handleInsertProduct = async product => {
    const { productProviderCode, id } = product

    if (productAlreadyLinked(id)) {
      return toastr.warning('Produto já vinculado')
    }
    if (productProviderCodeAlreadyLinked(productProviderCode)) {
      return toastr.warning('Código já vinculado')
    }
    setLoading(true)


    try {
      await providersRepository.createProductRelationship(providerId, {
        productId: product.id,
        productProviderCode
      })

      const newProducts = [...products]
      newProducts.push(product)

      setIsSelectProductModalOpen(false)
      setProducts(newProducts)
    } catch (err) {

      console.log(err)
      toastr.warning('Ocorreu um erro ao adicionar o produto. Por favor, tente novamente')

    } finally {
      setLoading(false)
    }
  }

  const handleUpdateProduct = async product => {
    const { productProviderCode, id } = product

    if (productProviderCodeAlreadyLinked(productProviderCode)) {
      return toastr.warning('Código já vinculado')
    }
    setLoading(true)


    try {
      await providersRepository.updateProductRelationship(providerId, id, {
        productProviderCode
      })

      const newProducts = [...products]
      const indexOf = newProducts.findIndex(product => product.id === id)
      newProducts[indexOf].productProviderCode = productProviderCode

      setProducts(newProducts)
      setIsSelectProductModalOpen(false)
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao atualizar o produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const productAlreadyLinked = id => products.some(product => product.id === id)
  const productProviderCodeAlreadyLinked = productProviderCode => products.some(product => product.productProviderCode === productProviderCode)

  const handleFilters = product => {
    const searchQuery = [product['code'], product['description'], product['manufacturerRef']]
    return filterByQuery(searchQuery)
  }

  const handleEditItem = product => {
    setProduct(product)
    setIsSelectProductModalOpen(true)
  }

  return (
    <div id='provider-products-page'>
      <CardForm title='Produtos'>
        <Col xs={12} sm={12} md={4} lg={4}>
          <label htmlFor='companyName'>Razão Social: </label>
          <input id='companyName' type="text" value={provider.companyName} className='form-control foco-input' disabled />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <label htmlFor='tradingName'>Nome Fantasia: </label>
          <input id='tradingName' type="text" value={provider.tradingName} className='form-control foco-input' disabled />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2}>
          <label htmlFor='cnpj'>CNPJ: </label>
          <input id='cnpj' type="text" value={provider.cnpj} className='form-control foco-input' disabled />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2}>
          <label htmlFor='IE'>Inscrição Estadual: </label>
          <input id='IE' type="text" value={provider.IE} className='form-control foco-input' disabled />
        </Col>
        <br />
        <main>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Col xs={12} sm={12} md={1} lg={1} style={{ minWidth: '130px' }}>
              <button className="btn btn-sucesso" onClick={() => setIsSelectProductModalOpen(true)}>
                + Novo Item
              </button>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <i id="iconepesquisar" style={{ cursor: 'pointer' }} className="fa fa-search" />
              <input
                type="text"
                style={{ paddingLeft: '9%' }}
                className="form-control"
                placeholder="Pesquise pelo Código Interno, Referência do Fabricante ou Descrição"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </Col>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '30px' }}
              data={products.filter(handleFilters)}
              columns={[
                {
                  Header: "Código Interno",
                  accessor: "code",
                  width: 180
                },
                {
                  Header: "Código do Fornecedor",
                  accessor: "productProviderCode",
                  width: 230
                },
                {
                  Header: "Descrição",
                  accessor: "description",
                },
                {
                  Header: "Ações",
                  accessor: "id",
                  width: 100,
                  headerClassName: "text-left",
                  filterable: false,
                  Cell: props =>
                    <div className='table-actions'>
                      <FontAwesomeIcon
                        title='Editar'
                        cursor='pointer'
                        color='black'
                        style={{ height: '1.5em', width: '1.5em' }}
                        icon={faEdit}
                        onClick={() => handleEditItem(props.original)}
                      />
                      <FontAwesomeIcon
                        title='Remover'
                        cursor='pointer'
                        icon={faTrashAlt}
                        style={{ height: '1.5em', width: '1.5em' }}
                        color='red'
                        onClick={() => handleOpenRemoveModal(props.original)}
                      />
                    </div>
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[{
                id: 'description',
                desc: false,
              }]}
              previousText='Anterior'
              nextText='Próximo'
              loadingText='Carregando...'
              noDataText='Nenhum Produto encontrado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
            />
          </Col>
        </main>

        <AlertModal
          show={isRemoveModalOpen}
          onCancel={() => setIsRemoveModalOpen(false)}
          onHide={() => setIsRemoveModalOpen(false)}
          onSubmit={handleRemoveProduct}
          message='Deseja remover o vínculo do código interno com o fornecedor ?'
        />

        {isSelectProductModalOpen && (
          <SelectProductModal
            show={isSelectProductModalOpen}
            loading={loading}
            selectedProduct={product}
            onSelect={handleInsertProduct}
            handleCancel={() => setIsSelectProductModalOpen(false)}
            onUpdate={handleUpdateProduct}
          />
        )}
      </CardForm>
    </div>
  )
}

export default ProviderProducts