import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'
import InventoryMovementListingMain from './Components/InventoryMovementListingMain'

const InventoryMovement = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Produtos',
              'Listagem de Movimentações de Estoque',
            ]}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <InventoryMovementListingMain />
        </Row>
      </Grid>
    </div>
  )
}

export default InventoryMovement
