import React from 'react';

import FlashingDots from 'components/LoadingEffects/FlashingDots';

const ProgressSteps = ({ description, active, lastStep }) => {
  const style = {
    borderColor: active ? (lastStep ? '#008000' : '#1d7391') : '#d6d6d6',
    color: active ? (lastStep ? '#008000' : '#444444') : '#d6d6d6',
  };

  return (
    <>
      <div className="progress-step" style={style}>
        {description}
      </div>
      {!lastStep && <FlashingDots active={active} vertical />}
    </>
  );
};

export default ProgressSteps;
