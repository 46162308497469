import { toastr } from 'react-redux-toastr';
import api from 'services/api';
import config from 'config';
import constants from '../../../../utils/constants';

export function handleChange(value, type) {
  return {
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type,
  };
}

export const fetchCompanyDetails = (companyId) => async (dispatch) => {
  dispatch({ type: 'FETCH_COMPANY_DETAILS_STARTED' });

  try {
    const response = await api.get(
      config.endpoint + `company/dadoscompany/${companyId}`
    );

    if (!!response.data.data.StartTime && !!response.data.data.EndTime) {
      response.data.data.StartTime = response.data.data.StartTime.substring(
        0,
        5
      );
      response.data.data.EndTime = response.data.data.EndTime.substring(0, 5);
    } else {
      response.data.data.StartTime = '';
      response.data.data.EndTime = '';
    }

    response.data.data.showCompanyInSobreRodas =
      response.data.data.companyConfig.showCompanyInSobreRodas;

    await dispatch({
      type: 'FETCH_COMPANY_DETAILS_SUCCESS',
      payload: response.data.data,
    });

    await dispatch(
      handleChange(response.data.data.segmentDescription, 'segmentDescription')
    );

    fetchCompanyDetailsServices(companyId);
  } catch (err) {
    console.log(err);
    toastr.warning(
      'Ocorreu um erro ao carregar os dados da empresa. Por favor, tente novamente'
    );
    return dispatch({ type: 'FETCH_COMPANY_DETAILS_FAILURE' });
  }
};

const fetchCompanyDetailsServices = (companyId) => async (dispatch) => {
  const response = await api.get(
    config.endpoint + `companyServiceType/Search/ByCompany/${companyId}`
  );

  const services = response.data.data.map((serv) => ({
    relation_Id: serv.id,
    Company_id: serv.Company_id,
    User_id: serv.User_id,
    ServiceType_id: serv.ServiceType_id,
    id: serv.ServiceType.id,
    Description: serv.ServiceType.Description,
  }));

  dispatch({ type: 'LOAD_COMPANY_DETAILS_SERVICES', payload: services });
};

export const updateCompanyDetails =
  (companyId, history) => async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_COMPANY_DETAILS_STARTED' });
    const { meusDetalhesReducer } = getState();
    const {
      StartTime,
      EndTime,
      Site,
      showCompanyInSobreRodas,
      Branch_id,
      companyServices,
      Segments,
      segmentDescription,
    } = meusDetalhesReducer;

    try {
      await api.put(
        config.endpoint + `integration/company-config-data/${companyId}`,
        {
          servicesTypes: parseInt(Branch_id) === 1 ? [] : companyServices,
          segments: Segments,
          branchId: Branch_id,
          openAt: StartTime,
          closesAt: EndTime,
          site: Site,
          segmentDescription,
          showCompanyInSobreRodas,
        }
      );

      dispatch({ type: 'UPDATE_COMPANY_DETAILS_SUCCESS' });
      toastr.success('Informações atualizadas com sucesso');
      history.push(constants.ROUTES.COMPANIES);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar as informações. Por favor, tente novamente'
      );
      return dispatch({ type: 'UPDATE_COMPANY_DETAILS_FAILURE' });
    }
  };
