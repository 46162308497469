import {
  cnpjMask,
  cpfMask,
  currency,
} from 'client/components/ToNormalize/ToNormalize';

export const xlsColumns = [
  {
    name: 'CNPJ',
    acessor: 'cpfCnpj',
  },
  {
    name: 'Razão Social',
    acessor: 'companyName',
  },
  {
    name: 'Orçamento',
    acessor: 'orcaments',
  },
  {
    name: 'Venda',
    acessor: 'sales',
  },
  {
    name: 'OS',
    acessor: 'orderOfServices',
  },
  {
    name: 'PDV',
    acessor: 'pdvs',
  },
  {
    name: 'R$ Vendido',
    acessor: 'BRLSold',
  },
];

export const getSalesByClientInfoToExport = (clients) => {
  return clients.map((client) => ({
    cpfCnpj:
      client.Cpf_Cnpj.length <= 11
        ? cpfMask(client.Cpf_Cnpj)
        : cnpjMask(client.Cpf_Cnpj),
    companyName: client.Company_Name,
    orcaments: client.orcaments,
    sales: client.sales,
    orderOfServices: client.orderOfServices,
    pdvs: client.pdvs,
    BRLSold: currency(client.BRLSold),
  }));
};
