const providersColumns = [
  {
    name: 'CPF / CNPJ',
    acessor: 'cpfCnpj',
  },
  {
    name: 'Tipo',
    acessor: 'type',
  },
  {
    name: 'Nome Completo / Razão Social',
    acessor: 'companyName',
  },
  {
    name: 'Nome Fantasia',
    acessor: 'tradingName',
  },
  {
    name: 'Email',
    acessor: 'email',
  },
  {
    name: 'Telefone Residencial / Comercial',
    acessor: 'Number_Phone1',
  },
  {
    name: 'Celular',
    acessor: 'Number_Phone2',
  },
  {
    name: 'IE',
    acessor: 'IE',
  },
  {
    name: 'Indicador de IE',
    acessor: 'statusIE',
  },
  {
    name: 'CEP',
    acessor: 'Zipcode',
  },
  {
    name: 'Endereço',
    acessor: 'Address',
  },
  {
    name: 'Número',
    acessor: 'Address_Number',
  },
  {
    name: 'Bairro',
    acessor: 'Neighborhood',
  },
  {
    name: 'Complemento',
    acessor: 'Complement',
  },
  {
    name: 'Ponto de Referência',
    acessor: 'Reference_Point',
  },
  {
    name: 'Estado',
    acessor: 'State',
  },
  {
    name: 'Cidade',
    acessor: 'City',
  },
];

module.exports = providersColumns;
