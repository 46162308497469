import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import CardForm from '../../../../../client/components/CardForm';
import FormSettingsTaxData from './TaxData';

import companiesRepository from '../../../../../repositories/Companies';

import { onlyNumbers } from '../../../../../client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../../contexts/auth';
import { useModulesContext } from '../../../../../contexts/modules';

const SettingsTaxData = ({ onSubmit }) => {
  const [isTaxDataOpen, setIsTaxDataOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const dispatch = useDispatch();

  const { isNFeModuleActive, isNFCeModuleActive, isNFSeModuleActive } =
    useModulesContext();

  const isSomeFiscalModuleActive =
    isNFeModuleActive || isNFCeModuleActive || isNFSeModuleActive;

  async function handleSubmit(values) {
    if (loading) return;
    const {
      digitalCertificateFile,
      digitalCertificatePassword,
      digitalCertificateNotImported,
    } = values;
    setLoading(true);

    if (!values.IE.trim()) {
      setLoading(false);
      return toastr.warning(
        'O preenchimento da inscrição estadual é obrigatório. Preencha o campo e tente novamente'
      );
    }

    if (!!digitalCertificateFile && !digitalCertificatePassword) {
      setLoading(false);
      return toastr.warning('Insira a senha do certificado');
    }

    let certificateInfos = {};
    if (digitalCertificateFile && digitalCertificateNotImported) {
      try {
        certificateInfos = await uploadAndGetCertificateInfos(values);
      } catch (err) {
        const message =
          err.response?.data?.message ||
          err?.message ||
          'Falha ao validar o certificado. Tente novamente!';
        toastr.warning('OS Digital', message);
        setLoading(false);
        return;
      }
    }

    update(values, certificateInfos);
  }

  async function uploadAndGetCertificateInfos({
    digitalCertificateFile,
    digitalCertificatePassword,
  }) {
    const formData = new FormData();

    formData.append('certificate', digitalCertificateFile);
    formData.append('digitalCertificatePassword', digitalCertificatePassword);
    formData.append('companyId', companyId);

    const response = await companiesRepository.validateCertificate(formData);

    if (!response.status) {
      throw new Error(response.message);
    }

    return {
      digitalCertificateExpireDate: response.digitalCertificateExpireDate,
      digitalCertificatePassword: digitalCertificatePassword,
      digitalCertificateBase64: response.digitalCertificateBase64,
    };
  }

  async function update(values, certificateInfos) {
    const {
      pCredSN,
      COFINS,
      PIS,
      accountingCpfCnpj,
      accountingEmail,
      taxRegime,
      tokenIBPT,
      digitalCertificatePassword,
      IE,
      NFCePrintingTemplate,
      printInvoicesAutomatically,
      issueInvoicesAutomatically,
    } = values;

    try {
      await companiesRepository.updateTaxData(companyId, {
        ...certificateInfos,
        taxRegime,
        pCredSN,
        PIS: PIS || 0,
        COFINS: COFINS || 0,
        IE: IE ?? '',
        accountingCpfCnpj: accountingCpfCnpj
          ? onlyNumbers(accountingCpfCnpj)
          : '',
        tokenIBPT: tokenIBPT || '',
        accountingEmail: accountingEmail || '',
        digitalCertificatePassword: digitalCertificatePassword || '',
        printInvoicesAutomatically,
        issueInvoicesAutomatically,
        NFCePrintingTemplate,
      });
      await dispatch(initialize('settingsTaxData', values));
      toastr.success('Dados Fiscais atualizados');
      onSubmit();
    } catch (err) {
      console.error(err);
      if (!err.response) {
        return toastr.error(
          'Ocorreu um erro inesperado. Por favor, tente novamente'
        );
      }
      toastr.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  const initialValues = {
    taxRegime: 'Simples Nacional',
  };

  if (isSomeFiscalModuleActive) {
    return (
      <CardForm
        show={isTaxDataOpen}
        title="Dados Fiscais da Empresa"
        onClick={() => setIsTaxDataOpen(!isTaxDataOpen)}
      >
        <FormSettingsTaxData
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={loading}
          companyId={companyId}
        />
      </CardForm>
    );
  } else {
    return null;
  }
};

export default SettingsTaxData;
