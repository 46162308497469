import React from 'react';

import { StyledText } from './styles';

export const Text = ({
  children,
  padding,
  fontWeight = 'regular',
  size = 'lg',
  color,
  lineHeight,
}) => {
  return (
    <StyledText
      padding={padding}
      fontWeight={fontWeight}
      color={color}
      size={size}
      lineHeight={lineHeight}
    >
      {children}
    </StyledText>
  );
};
