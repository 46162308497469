import React from 'react'

const Footer = ({ attendant }) => {
  return (
    <footer>
      {/* <span>Atendente: {attendant.Name}</span> */}
      <center>
        <span>
          <strong>Esse cupom não é um documento fiscal</strong>
        </span>
      </center>
    </footer>
  )
}

export default Footer