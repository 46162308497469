/* React */
import React, { useEffect, useState } from 'react'

/* Custom Components */
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'

import CardForm from 'client/components/CardForm'
import Button from 'client/components/CustomButton/CustomButton'
import AlertModal from 'components/AlertModal'
import CurrencyInput from 'client/components/Currency'

/* Repositories & Contexts */
import cashierBankRepository from 'repositories/CashierBank'
import materaCompanyRepository from 'repositories/MateraCompany'
import materaTEDRepository from 'repositories/MateraTEDTransaction'
import { useAuth } from 'contexts/auth'

/* Style */
import './styles.css'

const MateraTransferModal = ({ show, setShow, loadMateraInformation }) => {
  const { companyId } = useAuth()

  const [materaId, setMateraId] = useState('')

  const [loading, setLoading] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(true)
  const [bankOptions, setBankOptions] = useState([])

  const [destinationAccountId, setDestinationAccountId] = useState('')
  const [amount, setAmount] = useState(0)
  const [observations, setObservations] = useState('')

  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false)
  const [showTransferConfirmationModal, setShowTransferConfirmationModal] =
    useState(false)

  function handleClose() {
    if (amount || destinationAccountId || observations) {
      setShowCancelConfirmationModal(true)
      return
    }
    setShow(false)
  }

  async function loadBanks() {
    try {
      const data =
        await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        )
      /* Filtra todas as contas cujo tipo é BANCO, ignorando os caixas internos */
      setBankOptions(data.filter((bank) => bank.CashierBankType.id === 1))
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os bancos. Tente novamente!')
    }
  }

  async function loadMateraId() {
    try {
      const { data: materaCompany } =
        await materaCompanyRepository.showByCompanyId(companyId)

      setMateraId(materaCompany.id)
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao carregar as informações da conta matera. Tente novamente!'
      )
    }
  }

  async function handleTransfer() {
    try {
      setShowTransferConfirmationModal(false)
      setLoading(true)

      await materaTEDRepository.create({
        materaId,
        destinationAccountId,
        amount,
        observations,
      })

      toastr.success('Transferência realizada com sucesso!')

      await loadMateraInformation()
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao realizar a transferência. Tente novamente!'
      )
    } finally {
      setLoading(false)
      setShow(false)
    }
  }

  useEffect(() => {
    loadBanks()
    loadMateraId()
  }, [])

  return (
    <>
      <Modal
        show={show}
        keyboard={false}
        backdrop={'static'}
        onHide={() => handleClose()}
        dialogClassName='matera-transfer-modal-wrapper'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Transferência de Saldo - Conta Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardForm
            title='Transferência de Saldo'
            show={isFormOpen}
            onClick={() => setIsFormOpen(!isFormOpen)}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span style={{ color: 'red' }}>
                A transferência só poderá ser feita para contas com o mesmo
                responsável da conta digital
              </span>
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <div style={{ width: 220 }}>
                <label>
                  Banco Destino:<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className='form-control foco-input'
                  value={destinationAccountId}
                  onChange={(e) => setDestinationAccountId(e.target.value)}
                  required
                >
                  <option value=''>Selecione</option>
                  {bankOptions.map(
                    ({ id, description, CashierBankType: { initials } }) => (
                      <option key={id} value={id}>
                        {initials} - {description}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div style={{ width: 120 }}>
                <label htmlFor='value'>
                  Valor (R$):<span style={{ color: 'red' }}>*</span>
                </label>
                <CurrencyInput
                  id='value'
                  className='form-control foco-input'
                  value={amount}
                  onChangeEvent={(e) => setAmount(Number(e.target.value))}
                  required
                />
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <label htmlFor='observations'>Observações:</label>
              <textarea
                id='observations'
                className='form-control foco-input'
                rows='3'
                maxLength={200}
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
            </div>
          </CardForm>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle='danger'
              pullRight
              fill
              onClick={() => handleClose(false)}
              disabled={loading ? 'disabled' : ''}
            >
              Cancelar
            </Button>
            <Button
              bsStyle='info'
              pullRight
              fill
              onClick={() => {
                if (!amount || !destinationAccountId) {
                  return toastr.warning('Preencha os campos obrigatórios!')
                }
                setShowTransferConfirmationModal(true)
              }}
              disabled={loading ? 'disabled' : ''}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Transferir
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {showCancelConfirmationModal ? (
        <AlertModal
          show={showCancelConfirmationModal}
          title='OS Digital'
          message={
            <>
              <p>
                Deseja cancelar a transferência? Ela poderá ser feita
                futuramente, se houver saldo.
              </p>
            </>
          }
          onCancel={() => setShowCancelConfirmationModal(false)}
          onHide={() => setShowCancelConfirmationModal(false)}
          onSubmit={() => setShow(false)}
          loading={loading}
        />
      ) : (
        ''
      )}

      {showTransferConfirmationModal ? (
        <AlertModal
          show={showTransferConfirmationModal}
          title='OS Digital'
          message={
            <>
              <p>
                <strong>Você irá fazer uma transferência de saldo.</strong>
              </p>
              <p>
                Deseja transferir o valor selecionado? A transferência é
                irreversível e, uma vez transferido, não poderá ser revertido.
              </p>
            </>
          }
          onCancel={() => setShowTransferConfirmationModal(false)}
          onHide={() => setShowTransferConfirmationModal(false)}
          onSubmit={() => handleTransfer(false)}
          loading={loading}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default MateraTransferModal
