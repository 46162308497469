import React from 'react'
import './styles.css'

const PureLoader = ({ message, subMessage }) => {
  return (
    <div className='lds-ring-container'>
      <div className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {message && <span>{message}</span>}
      {subMessage && <span className='sub-message'>{subMessage}</span>}
    </div>
  )
}

export default PureLoader
