import React, { useState } from 'react'
import Select from 'react-select'
import { useAuth } from 'contexts/auth'
import { useEffect } from 'react'
import { toastr } from 'react-redux-toastr'

import { format } from 'date-fns'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import ProvidersRepository from 'repositories/Providers'
import QuotationRepository from 'repositories/Quotations'

const Icon = () => (
  <i
    id="iconepesquisar"
    style={{ cursor: 'pointer', marginLeft: '-40px', marginBottom: '8px' }}
    className="fa fa-search"
  />
)

export default function QuotesSupplierMain() {
  const { companyId } = useAuth()

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [providerId, setProviderId] = useState('')
  const [providersOptions, setProvidersOptions] = useState([])
  const [selectedProvider, setSelectedProvider] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    if (validations()) handleProcess()
  }

  const validations = () => {
    if (new Date(finalDate) && new Date(initialDate)) {
      if (new Date(finalDate) < new Date(initialDate)) {
        toastr.warning('A data final deve ser maior que a data inicial.')
        return false
      }
    }

    if (!finalDate || !initialDate) {
      return toastr.warning(
        'Não foi possível gerar o relatório',
        'Preencha todos os campos e tente novamente!'
      )
    }

    return true
  }

  const handleProcess = async () => {
    setIsLoading(true)
    try {
      const provId = providerId === '' ? '' : providerId

      const data = await QuotationRepository.getAll({
        initialDate,
        finalDate,
        providerId: provId,
        companyId
      })

      if (!data.quotationsList.length) {
        return toastr.warning(
          'Não foi possível gerar o relatório.',
          'Nenhum item foi localizado. Verifique os filtros e tente novamente.'
        )
      }

      if (initialDate && finalDate && data) {
        window.open(
          window.location.origin +
            `/report/quotes-supplier?providerId=${provId}&initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyId}`,
          '_blank'
        )
      }
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const loadProvidersOptions = async () => {
    try {
      const data = await ProvidersRepository.getAllActiveByCompany(companyId)

      setProvidersOptions(
        data.map((provider) => ({
          label:
            provider.type === 'Fisica'
              ? `${cpfOrCnpjMask(provider.cpfCnpj)} - ${provider.companyName}}`
              : `${cpfOrCnpjMask(provider.cpfCnpj)} - ${provider.tradingName}`,
          value: provider,
        }))
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao carregar os fornecedores',
        'Tente novamente! Caso persista, entre em contato com o suporte.'
      )
    }
  }

  function handleSelectProvider(provider) {
    setSelectedProvider(provider)
    setProviderId(provider.value.id)
  }

  function handleClearSelectProvider(provider) {
    setSelectedProvider(null)
    setProviderId('')
  }

  useEffect(() => {
    loadProvidersOptions()
  }, [])

  return (
    <div className="voucher-issued-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={initialDate}
          onChange={(e) => setInitialDate(e.target.value)}
          max={finalDate ? finalDate : format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          value={finalDate}
          onChange={(e) => setFinalDate(e.target.value)}
          min={initialDate}
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div
        style={{
          width: '470px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center', width: 470 }}>
          <strong>Fornecedor:</strong>
        </span>
        <Select
          className="react-select react-select-primary"
          classNamePrefix="react-select"
          placeholder={
            'Pesquisar por CPF/CNPJ, Razão Social, Nome ou Nome Fantasia'
          }
          onFocus={handleClearSelectProvider}
          options={providersOptions}
          value={selectedProvider}
          components={{
            DropdownIndicator: Icon,
          }}
          styles={{
            control: (base) => ({
              ...base,
              height: '35px',
              minHeight: '35px',
              borderColor: '#E3E3E3',
              cursor: 'pointer',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              marginTop: '-6px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
            }),
          }}
          onChange={(e) => {
            handleSelectProvider(e)
          }}
          noOptionsMessage={() => 'Nenhum fornecedor encontrado'}
        />
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            width: 460,
          }}
        >
          <p style={{ fontSize: 12, color: '#d43f3a' }}>
            Deixe em branco para buscar todos os fornecedores
          </p>
        </span>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <button
          className="btn btn-sucesso"
          type="submit"
          onClick={handleSubmit}
        >
          <span
            className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />{' '}
          Processar
        </button>
      </div>
    </div>
  )
}
