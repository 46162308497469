import React from 'react';
import { Tab, TabList, TabPanel } from './TabMenu.styles';

export const TabMenu = ({ items, activeItem, setActiveItem }) => {
  return (
    <nav>
      <TabList>
        {items &&
          items.map((item, index) => (
            <Tab
              key={item.id}
              value={item.id}
              active={activeItem === index}
              onClick={() => setActiveItem(index)}
            >
              {item.description}
            </Tab>
          ))}
      </TabList>
    </nav>
  );
};
