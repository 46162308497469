import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import TextForInvoices from './TextForInvoices';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const Texts = () => {
  localStorage.setItem('selectedPath', 'client/texts-for-invoices');
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Fiscal', 'Texto Para Notas Fiscais']}
            path={['home', null, null, 'texts-for-invoices']}
          />
          <TextForInvoices />
        </Row>
      </Grid>
    </div>
  );
};

export default Texts;
