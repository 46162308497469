import api from '../services/api'
import config from '../config'

const store = async ({ transactionId, companyId }) => {
  const response = await api.post(
    config.endpoint + `stone-claimcheck/${transactionId}`,
    {
      companyId,
    }
  )

  return response.data
}

const stoneClaimCheckRepository = {
  store,
}

export default stoneClaimCheckRepository
