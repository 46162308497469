import React from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { addDays, format } from 'date-fns'
import { percentage, phoneMask, cpfOrCnpjMask, cepMask } from 'client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'



const ListEmployeesPDF = (responseData) => {
  const data = responseData.responseData.data
  return (
    <Document
      title={`impresso-list-employees-${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Listagem de Funcionários - Sintético
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Tipo de Data:
            </Text>
            <Text style={[styles.text]}> {data.period.dateTypes === 'admissionDate' ? 'Admissão' : 'Demissão'}</Text>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Data Inicio:
            </Text>
            <Text style={[styles.text]}> {format(new Date(`${data.period.end} 03:00:01`), 'dd/MM/yyyy')}</Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                Data Fim:
              </Text>
              <Text style={[styles.text]}>{format(new Date(`${data.period.end} 23:59:59`), 'dd/MM/yyyy')}</Text>
            </View>
          </View>
        </View>

        <TableHeader
          data={data}
        />
      </Page>
    </Document>
  )
}

const TableHeader = ({ data }) => {
  return (
    <>

      <View style={[styles.line]} />
      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Cargo
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Cargo</Text>
        <Text style={[styles.boldText, { width: '6%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
      </View>

      <View style={styles.line} />

      {
        data.totalsPerTypeAndStatus.map(item => {
          const type = item.reduce((prevValue, currentValue) => currentValue.Position?.description || 'Sem Cargo', '')
          const status = item.reduce((prevValue, currentValue) => currentValue.isActive, '')
          const quantity = item.length

          return (
            <TotalsByTypeStatusItems
              key={item.id} type={type} status={status} quantity={quantity}
            />
          )
        })
      }

      <View style={styles.line} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Funcionário
        </Text>
      </View>
      <View style={{ padding: '5px' }} />

      <EmployeeDetailsTableHeader />

      {
        data.employeeData.map((employee, index) => (<EmployeeDetails employee={employee} key={index} />))
      }
      <View style={styles.line} />

    </>
  )
}

const EmployeeDetails = ({ employee, index }) => {

  return (
    <View >


      <EmployeeDetailsTableItem employee={employee} key={index} />


    </View>
  )
}

const TotalsByTypeStatusItems = ({ key, type, status, quantity }) => {

  return (
    <View key={key} style={styles.containerRow}>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{type}</Text>
      </View>
      <View style={{ width: '9%' }}>
        <Text style={styles.text}>{status === true ? 'Ativo' : 'Inativo'}</Text>
      </View>
      <View style={{ width: '10%' }}>
        <Text style={styles.text}>{quantity}</Text>
      </View>
    </View>
  )
}

const EmployeeDetailsTableHeader = ({ }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Admissão</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Data Deslig.</Text>
        <Text style={[styles.boldText, { width: '18%' }]}>Nome Completo</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>CPF</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Cargo</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Telefone</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Comissão</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Email</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Usuário</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const EmployeeDetailsTableItem = ({ employee, key }) => {


  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={[styles.text, { width: '9%', fontSize: '8px' }]}>{employee.admissionDate ? format(getDateOnlyFromDate(new Date(employee.admissionDate), 1), 'dd/MM/yyyy') : ''}</Text>
        <Text style={[styles.text, { width: '9%', fontSize: '8px' }]}>{employee.resignationDate ? format(getDateOnlyFromDate(new Date(employee.resignationDate)), 'dd/MM/yyyy') : ''}</Text>
        <Text style={[styles.text, { width: '18%', fontSize: '8px' }]}>{employee.name}</Text>
        <Text style={[styles.text, { width: '8%', fontSize: '8px' }]}>{cpfOrCnpjMask(employee.cpf)}</Text>
        <Text style={[styles.text, { width: '8%', fontSize: '8px' }]}>{employee.Position?.description}</Text>
        <Text style={[styles.text, { width: '9%', fontSize: '8px' }]}>{phoneMask(employee?.cellPhone)}</Text>
        <Text style={[styles.text, { width: '7%', fontSize: '8px', left: '9px' }]}>{percentage(employee.comissionRate)}</Text>
        <Text style={[styles.text, { width: '16%', fontSize: '8px' }]}>{employee?.email}</Text>
        <Text style={[styles.text, { width: '16%', fontSize: '8px' }]}>{employee.User?.Email}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>

  )
}


export default ListEmployeesPDF



const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold'
  },

})