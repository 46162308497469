import React from 'react'
import {
  Document,
  Text,
  Image,
  Font,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import { format } from 'date-fns'

import {
  cepMask,
  cnpjMask,
  percentage,
  cpfMask,
  milhar,
  phoneMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

import fontRobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf'
import fontRobotoBold from '../../../assets/fonts/Roboto-Bold.ttf'
import fontRobotoThin from '../../../assets/fonts/Roboto-Thin.ttf'

import carImg from '../../../assets/img/checklist-images/carro.png'
import gasImg from '../../../assets/img/checklist-images/gas.png'

import { usePlanSignatureContext } from 'contexts/plan-signature'

export const CarrosSheet = ({ customer, sale, displayNotes }) => {
  const { isPlanFree, isPlanStart } = usePlanSignatureContext()
  const printDate = format(new Date(customer.date), 'dd/MM/yyyy')

  const company = {
    Url_Logo: customer.company.Url_Logo,
    Trading_Name: customer.company.Trading_Name,
    Cpf_Cnpj:
      customer.company.Cpf_Cnpj.length <= 11
        ? cpfMask(customer.company.Cpf_Cnpj)
        : cnpjMask(customer.company.Cpf_Cnpj),
    Type: customer.company.Cpf_Cnpj.length <= 11 ? 'Física' : 'Jurídica',
    IE: customer.company.IE ? customer.company.IE : '',
    Address: `${customer.company.Address[0].Address}, ${customer.company.Address[0].Address_Number}, ${customer.company.Address[0].Neighborhood}`,
    AddressCityState: `${customer.company.Address[0].City}/${customer.company.Address[0].State}`,
    Zipcode: customer.company.Address[0].Zipcode
      ? cepMask(customer.company.Address[0].Zipcode)
      : '',
    Telephone: customer.company.Phones[0].Number_Phone1
      ? phoneMask(customer.company.Phones[0].Number_Phone1)
      : '',
    Email: customer.company.Email ? customer.company.Email : '',
  }

  const vehicle = {
    description: customer.vehicle?.Model || '',
    licensePlate: customer.vehicle?.License_Plate || '',
    color: customer.vehicle?.Color || '',
    manufactureYear: customer.vehicle?.Year_Manufacture || '',
    modelYear: customer.vehicle?.Year_Model || '',
    kilometers: customer.vehicle?.Kilometers
      ? milhar(customer.vehicle?.Kilometers)
      : '',
    brand: customer.vehicle?.Brand,
  }

  const customerName = customer.Company_Name

  const contentTable1 = [
    'Luzes controle / sinalização e iluminação: verificar funcionamento',
    'Palhetas dos limpadores de vidros: verificar funcionamento e ressecamento',
    'Extintor / Triângulo / Macaco e Chave de Roda: verificar existência / estado e carga',
    'Faróis: verificar funcionamento',
    'Direção: verificar folga',
    'Pedal do Freio e alavanca do freio de estacionamento: verificar funcionamento',
    'Fluido de Freio: verificar a necessidade de substituição (02 anos ou 60 mil KM)',
    'Embreagem: verificar / corrigir posicionamento do pedal',
    'Cintos de Segurança: verificar funcionamento, estado e fixações',
    'Sistema de Autodiagnóstico (se existir): efetuar a leitura da memória de defeitos',
  ]

  const contentTable2 = [
    'Reservatório do Fluido de Freio / Fluido ATF da direção hidráulica: verificar',
    'Óleo do Motor: verificar quilometragem e/ou nível',
    'Bateria: verificar danos e possíveis vazamentos',
    'Líquido do sistema de Arrefecimento: verificar existência de aditivo e nível',
    'Reservatório dos lavadores de vídros: verificar existência de detergente e abastecer',
    'Elementos filtrantes do Motor: verificar estado',
    'Correias (não encapsuladas): verificar o estado e a tensão',
    'Marcha Lenta (veículos carburados): verificar e ajustar',
    'Velas de Ignição: verificar os eletrodos',
    'Motor: verificar quanto a danos e vazamentos',
  ]

  const contentTable3 = [
    'Pneus / Rodas: verificar estado, inclusive o reserva',
    'Linhas do sistema de Arrefecimento / escapamento / Freio: verificar vazamentos',
    'Motor / Transmissão: verificar vazamentos',
    'Amortecedores: verificar fixação e vazamentos',
    'Pastilhas de Freio: verificar a espessura e estado',
    'Rolamentos de Roda (Traseiros): verificar ruídos anormais e folgas',
    'Coifas do sistema de Direção e Articulações Homocinéticas: verificar estado',
  ]

  return (
    <Document
      title={`impresso-Checklist-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page style={styles.page}>
        {/* Dados da Empresa */}
        <Card fixed style={{ marginBottom: 7 }}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            {company.Url_Logo ? (
              <View
                style={{
                  width: 82,
                  height: '100%',
                  borderRightWidth: 1,
                  borderRightColor: 'black',
                  padding: 3,
                }}
              >
                <Image
                  src={company.Url_Logo}
                  style={{
                    width: 75,
                    height: 60,
                  }}
                />
              </View>
            ) : null}

            <View
              fixed
              style={{
                width: '80%',
                marginLeft: 5,
                height: '100%',
                minHeight: 65,
              }}
            >
              <Text style={styles.mainTitle}>{company.Trading_Name}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingTop: '3px',
                  width: '100%',
                }}
              >
                <View>
                  <Text>
                    {company.Type === 'Jurídica' ? 'CNPJ' : 'CPF'}:{' '}
                    {company.Cpf_Cnpj}
                  </Text>
                </View>

                <View>{company.IE ? <Text>IE: {company.IE}</Text> : null}</View>

                <View>
                  <Text>Data: {printDate}</Text>
                </View>
              </View>

              <View style={{ flexDirection: 'row', paddingTop: '3px' }}>
                <Text>
                  Endereço: {customer.company.Address[0].Address}{' '}
                  {customer.company.Address[0].Address_Number},{' '}
                  {customer.company.Address[0].Neighborhood}
                </Text>
                <Text style={{ paddingLeft: '2px' }}>
                  - {company.AddressCityState}{' '}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  paddingTop: '3px',
                }}
              >
                <Text style={{ paddingRight: '10px' }}>
                  CEP: {company.Zipcode}
                </Text>
                <Text style={{ paddingRight: '10px' }}>
                  Telefone: {company.Telephone}
                </Text>
                <Text>Email: {company.Email}</Text>
              </View>
            </View>
          </View>
        </Card>
        {/* Dados da Empresa --- FIM --- */}

        <View
          style={{
            borderTopWidth: 1,
            borderColor: 'black',
            borderStyle: 'dashed',
            borderRadius: 0,
          }}
        />

        <View style={{ paddingTop: 7 }}>
          <View style={[styles.customerRow1]}>
            <View>
              <Text>
                <Text style={styles.bold}>Cliente: </Text> {customerName}
              </Text>
            </View>
            <View style={{ paddingLeft: !customerName ? 170 : 20 }}>
              <Text>
                <Text style={styles.bold}>Placa: </Text> {vehicle.licensePlate}
              </Text>
            </View>
            <View
              style={{
                paddingLeft: !customerName
                  ? 70
                  : !vehicle.licensePlate
                  ? 70
                  : 20,
              }}
            >
              <Text>
                <Text style={styles.bold}>Veículo: </Text> {vehicle.description}
              </Text>
            </View>
          </View>

          <View
            style={[styles.customerRow1, { justifyContent: 'space-between' }]}
          >
            <View>
              <Text>
                <Text style={styles.bold}>Marca: </Text> {vehicle.brand}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>KM Atual: </Text> {vehicle.kilometers}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Cor: </Text> {vehicle.color}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Ano Fb.: </Text>{' '}
                {vehicle.manufactureYear}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={styles.bold}>Ano Mod.: </Text> {vehicle.modelYear}
              </Text>
            </View>
          </View>

          {!isPlanFree && !isPlanStart ? (
            <View style={[styles.customerRow1]}>
              <Text>
                <Text style={styles.bold}>Prisma/Box: </Text>{' '}
                {customer.prismaBox}{' '}
              </Text>
            </View>
          ) : (
            ''
          )}

          <View style={[styles.customerRow1]}>
            <Text style={styles.bold}>Inspeção executada por:</Text>
            <View
              style={{
                width: '80%',
                borderBottomWidth: 1,
                borderColor: 'black',
                borderRadius: 0,
                marginLeft: 2,
                height: 15,
              }}
            />
          </View>
        </View>
        {/* Dados do Cliente --- FIM --- */}

        {/* Imagens */}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.detailsLeft}>
            <Image src={carImg} style={styles.carImg} />
            <Text style={{ fontWeight: 'bold', paddingTop: 2, paddingLeft: 5 }}>
              M = Manchado, R = Riscado, A = Amassado, Q = Quebrado, F =
              Faltando
            </Text>
          </View>

          <View style={{ width: '30%' }}>
            <View style={styles.detailsRightContent}>
              <Text style={styles.detailsRightText}>
                Medidor de Combustível
              </Text>
              <Image src={gasImg} style={styles.gasImg} />
              <View style={styles.withoutMalfunctions}>
                <View style={styles.box} />
                <Text style={[styles.underline, { marginRight: 2 }]}>SEM</Text>
                <Text>Avarias</Text>
              </View>
              <View style={styles.withMalfunctions}>
                <View style={styles.box} />
                <Text style={[styles.underline, { marginRight: 2 }]}>COM</Text>
                <Text>Avarias</Text>
              </View>
            </View>
          </View>
        </View>
        {/* Imagens --- FIM --- */}

        {/* Tabelas */}
        <View>
          <View style={styles.detailsTable}>
            <Table
              titleLeft="NO INTERIOR DO"
              titleRight="Status"
              data={contentTable1}
              style={{
                marginTop: 10,
              }}
            />
            <Table
              titleLeft="NO COMPARTIMENTO DO MOTOR"
              data={contentTable2}
              style={{
                marginTop: 5,
              }}
            />
            <Table
              titleLeft="NO INTERIOR DO"
              data={contentTable3}
              style={{
                marginTop: 5,
              }}
            />
          </View>
        </View>
        {/* Fim das Tabelas --- FIM --- */}

        {/* Observações e Footer */}

        <View style={{ marginTop: 20 }}>
          <Text style={styles.bold}>OBSERVAÇÕES</Text>
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
        </View>

        {displayNotes && <ObservationsCard sale={sale} />}

        <View style={styles.footer}>
          <Text style={styles.bold}>
            Data/Hora: ______/______/______ ___________
          </Text>
          <Text style={styles.bold}>
            Ass. Cliente: _________________________________________
          </Text>
        </View>

        {/* Observações e Footer  --- FIM --- */}
      </Page>
    </Document>
  )
}

const Card = ({ children, style, ...rest }) => (
  <View style={[styles.cardBorder, style]} {...rest}>
    {children}
  </View>
)

const ObservationsCard = ({ sale }) => {
  return (
    <Card break style={{ marginTop: '10px' }}>
      <View style={{ flexDirection: 'row', height: 75 }}>
        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Observações</Text>
          <Text>{sale.Comments}</Text>
        </View>

        <View style={[styles.line2]} />

        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Defeitos</Text>
          <Text>{sale.Defects}</Text>
        </View>

        <View style={[styles.line2]} />

        <View style={[styles.observationsCard]}>
          <Text style={styles.bold}>Avarias</Text>
          <Text>{sale.Malfunctions}</Text>
        </View>
      </View>
    </Card>
  )
}

const Table = ({
  children,
  style = {},
  titleRight,
  titleLeft,
  data = [],
  ...rest
}) => (
  <View style={[styles.tableContainer, style]}>
    <View style={styles.tableHeader}>
      <Text style={styles.bold}>{titleLeft}</Text>
      {titleRight ? <Text style={styles.bold}>{titleRight}</Text> : null}
    </View>
    <View style={styles.table} {...rest}>
      {data.map((item) => (
        <View key={item} style={styles.tableItemContainer}>
          <Text style={styles.tableItemText}>{item}</Text>
          <View style={styles.tableItemStatus} />
        </View>
      ))}
    </View>
  </View>
)

const styles = StyleSheet.create({
  mainTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  span: {
    fontSize: 9,
  },
  p: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 'bold',
  },
  box: {
    width: 10,
    height: 10,
    borderWidth: 2,
    marginRight: 2,
  },
  underline: {
    fontWeight: 'bold',
    textDecorationStyle: 'solid',
    textDecoration: 'underline',
  },
  customerRow: {
    flexDirection: 'row',
    height: 30,
    paddingTop: 1,
    paddingLeft: 3,
  },
  customerRow1: {
    paddingTop: 1,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  cardTitle: {
    marginTop: 7,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#444444',
  },
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  checkBorder: {
    borderColor: 'black',
    borderWidth: 1.5,
    width: '15px',
    borderRadius: 2,
  },
  detailsLeft: {
    width: '70%',
  },
  detailsTable: {
    width: '70%',
  },
  detailsRightContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  tableItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0,
    marginTop: -1.2,
  },
  tableItemText: {
    width: 365,
    height: 11.5,
    fontSize: 8,
    borderWidth: 1,
    borderColor: '#444444',
    paddingTop: 1,
    paddingLeft: 4,
  },
  tableItemStatus: {
    width: 25,
    borderWidth: 1,
    borderColor: '#444444',
    marginLeft: -1.2,
  },
  withoutMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  withMalfunctions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 2,
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 15,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  gasImg: {
    width: 100,
  },
  carImg: {
    width: '300px',
    height: '190px',
    paddingLeft: 80,
  },
  observationsCard: {
    width: '33%',
    marginLeft: '5px',
    marginTop: '5px',
  },
  line2: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: 'black',
  },
})

Font.register({
  family: 'Roboto',
  fonts: [
    { src: fontRobotoRegular },
    { src: fontRobotoThin, fontWeight: 'thin' },
    { src: fontRobotoBold, fontWeight: 'bold' },
  ],
})
