import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import Form from './Form'

const BankBilletAccount = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Integrações',
          'Boleto Bancário',
          match.params.id ? 'Editar Carteira' : 'Nova Carteira',
        ]}
        path={['home', null, 'ticket-issuance', null]}
      />
      <Form goToHomepage={() => history.push('/ticket-issuance')} />
    </>
  )
}

export default withRouter(BankBilletAccount)
