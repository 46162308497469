import React from "react";
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import HomeCostCenter from "./CostCenter";

const CostCenter = ({ history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={["Início", "Cadastro", "Financeiro", "Centro de Custo"]}
            path={["home", null, null, "costCenters"]}
          />
          <HomeCostCenter history={history}/>
        </Row>
      </Grid>
    </div>
  );
};
export default CostCenter;
