import config from '../config';
import api from '../services/api';

const generateSalesByProductService = async ({
  typeOfItem,
  familiesIds,
  orderBy,
  companyId,
  initialDate,
  finalDate,
}) => {
  try {
    const response = await api.get(
      `report/sales-by-product-service/${companyId}`,
      {
        params: {
          typeOfItem,
          familiesIds,
          orderBy,
          initialDate,
          finalDate,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateSalesByFormPayment = async ({
  Company_id,
  initialDate,
  finalDate,
}) => {
  try {
    const response = await api.get(
      `report/sales-by-form-payment/${Company_id}`,
      {
        params: {
          initialDate,
          finalDate,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllSalesByFamily = async ({
  initialDate,
  finalDate,
  companyId,
  familyId,
}) => {
  try {
    const response = await api.get(`sales/sales-by-family/${companyId}`, {
      params: {
        initialDate,
        finalDate,
        familyId,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getAllSalesByFamilyService = async ({
  initialDate,
  finalDate,
  companyId,
  familyId,
}) => {
  try {
    const response = await api.get(
      `sales/sales-by-family-service/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
          familyId,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const create = async (sale) => {
  try {
    const response = await api.post(config.endpoint + 'sales', sale);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, sale) => {
  try {
    await api.put(config.endpoint + `sales/${id}`, sale);
  } catch (err) {
    throw err;
  }
};

const updateShowOnPanelStatus = async (id, showOnPanel) => {
  try {
    await api.put(
      config.endpoint +
        `sales/${id}/panel-status/by-query?showOnPanel=${showOnPanel}`
    );
  } catch (err) {
    throw err;
  }
};

const cancel = (id, lossReasonId) =>
  api.put(config.endpoint + `sales/${id}/cancel`, {
    lossReasonId,
  });

const getSaleToPrint = async (saleId) => {
  try {
    const response = await api.get(config.endpoint + `sales/${saleId}/print`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getSaleToPrintPDV = async (saleId) => {
  const response = await api.get(config.endpoint + `sales/${saleId}/print-pdv`);
  return response.data;
};

export const getById = async (saleId) => {
  try {
    const response = await api.get(config.endpoint + `sales/${saleId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getByCode = async (code) => {
  try {
    const response = await api.get(config.endpoint + `sales/by-code/${code}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId, queryParams) => {
  const response = await api.get(
    config.endpoint + `sales/company/${companyId}`,
    { params: queryParams }
  );

  return response.data;
};

const getAllByCompanyWithCustomerAndCodeOnly = async (
  companyId,
  queryParams
) => {
  const response = await api.get(
    config.endpoint + `sales/company/${companyId}/customer-and-sale-code-only`,
    { params: queryParams }
  );

  return response.data;
};

const devolution = (Sale_id, items, userId, devolutionComplete) =>
  api.post(config.endpoint + `salesitem/devolution`, {
    Sale_id,
    items,
    userId,
    devolutionComplete: devolutionComplete || false,
  });

const getBudgetsAndServiceOrders = (companyId, initialDate, finalDate) =>
  api.get(config.endpoint + `sales/budgets-service-orders/${companyId}`, {
    params: {
      initialDate,
      finalDate,
    },
  });

const getCanceledBudgetsToPrint = async ({
  companyId,
  initialDate,
  finalDate,
  lossReasonId,
}) => {
  try {
    const canceledBudgets = await api.get(
      config.endpoint + `sales/canceled-budgets`,
      {
        params: {
          companyId,
          initialDate,
          finalDate,
          lossReasonId,
        },
      }
    );

    return canceledBudgets;
  } catch (err) {
    throw err;
  }
};

const createMaintenance = async (saleId) => {
  const { data } = await api.post(config.endpoint + 'maintenance', { saleId });
  return data;
};

const approveBugdet = (saleId) =>
  api.put(config.endpoint + `bank-slip/approve-budget/${saleId}`);

const generateReturnReport = async ({
  companyId,
  initialDate,
  finalDate,
  returnType,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/devolution-report/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
          returnType,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getSalesByTypeReportUIVersion = async ({
  initialDate,
  finalDate,
  saleType,
  saleStatus,
  companyId,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/sales/by-type/ui-version`,
      {
        params: {
          initialDate,
          finalDate,
          saleType,
          saleStatus,
          companyId,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getSalesByTypeReportSheetVersion = async ({
  initialDate,
  finalDate,
  saleType,
  saleStatus,
  showItems,
  companyId,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/sales/by-type/sheet-version`,
      {
        params: {
          initialDate,
          finalDate,
          saleType,
          saleStatus,
          showItems,
          companyId,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const generateProductServiceReviews = async ({
  companyId,
  initialDate,
  finalDate,
}) => {
  try {
    const response = await api.get(
      `/report/product-service-review-report/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const salesWithCodeAndCustomer = async (companyId, query) => {
  const { data } = await api.get(
    `sales/company/${companyId}/labels/sales-with-code-and-customer`,
    { query }
  );
  return data;
};

export default {
  getByCode,
  getById,
  create,
  update,
  cancel,
  getAllByCompany,
  getSaleToPrint,
  devolution,
  getSaleToPrintPDV,
  getBudgetsAndServiceOrders,
  approveBugdet,
  getCanceledBudgetsToPrint,
  createMaintenance,
  updateShowOnPanelStatus,
  generateReturnReport,
  getSalesByTypeReportUIVersion,
  getSalesByTypeReportSheetVersion,
  generateProductServiceReviews,
  getAllSalesByFamily,
  getAllSalesByFamilyService,
  generateSalesByFormPayment,
  getAllByCompanyWithCustomerAndCodeOnly,
  generateSalesByProductService,
  salesWithCodeAndCustomer,
};
