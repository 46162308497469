import React from 'react'
import 'react-step-progress-bar/styles.css'
import { ProgressBar, Step } from 'react-step-progress-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCashRegister,
  faMoneyBillAlt,
} from '@fortawesome/free-solid-svg-icons'

const ProgressBarComponent = ({ percent }) => (
  <ProgressBar
    percent={percent}
    filledBackground="rgb(4, 182, 4)"
    unfilledBackground="linear-gradient(to right, rgb(214, 255, 220), rgb(177, 250, 188))"
    style={{ height: '5px' }}
  >
    <Step transition="scale">
      {({ accomplished }) => (
        <div
          id={accomplished ? 'div-step-xml-import2' : 'div-step-xml-import'}
          className={`indexedStep import-progress ${
            accomplished ? 'accomplished' : null
          }`}
        >
          <FontAwesomeIcon
            style={{ height: 43, width: 43, marginTop: 2 }}
            id={accomplished ? 'icon-step-xml-import2' : 'icon-step-xml-import'}
            icon={faMoneyBillAlt}
            className="iconeMenu"
            aria-hidden="false"
          />
          <br />
          <span
            style={{
              fontWeight: accomplished ? 'bold' : 'normal',
              fontSize: '12px',
            }}
          >
            Títulos
          </span>
        </div>
      )}
    </Step>

    <Step transition="scale">
      {({ accomplished }) => (
        <div
          id={accomplished ? 'div-step-xml-import2' : 'div-step-xml-import'}
          className={`indexedStep import-progress ${
            accomplished ? 'accomplished' : null
          }`}
        >
          <FontAwesomeIcon
            style={{ height: 37, width: 37 }}
            id={accomplished ? 'icon-step-xml-import2' : 'icon-step-xml-import'}
            icon={faCashRegister}
            className="iconeMenu"
            aria-hidden="false"
          />
          <br />
          <span
            style={{
              fontWeight: accomplished ? 'bold' : 'normal',
              fontSize: '12px',
            }}
          >
            Pagamento
          </span>
        </div>
      )}
    </Step>
  </ProgressBar>
)

export default ProgressBarComponent
