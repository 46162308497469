import React from 'react'
import { useFormContext } from 'react-hook-form'
import { phoneMask } from 'client/components/ToNormalize/ToNormalize'
import {
  HeaderContainer,
  LogoContainer,
  SectionContainer,
  Title,
} from '../styles'

export function QuotationHeader({ defaultLogo, showMobileVersion }) {
  const { getValues } = useFormContext()

  const { company } = getValues()

  return (
    <>
      {showMobileVersion ? (
        <div className="quotation-container-mobile">
          <div className="quotation-header-mobile center">
            <p>
              <strong>{company.Trading_Name}</strong>
            </p>
            <p>
              {company.Company_Name} <br />
              {company?.Address[0].Address}, Nº
              {company?.Address[0].Address_Number} -{' '}
              {company?.Address[0].Neighborhood}
              <br />
              {company?.Address[0].City}/{company?.Address[0].State}
              <br />
              {phoneMask(
                company?.Phones[0].Number_Phone1 ||
                  company?.Phones[0].Number_Phone2
              )}{' '}
              - <a href={`mailto:${company.Email}`}>{company.Email}</a>
            </p>
          </div>
        </div>
      ) : (
        <SectionContainer>
          <HeaderContainer>
            <LogoContainer>
              <img src={company.Url_Logo || defaultLogo} alt="logo-empresa" />
            </LogoContainer>
            <div className="company-info">
              <p>
                <strong>{company.Trading_Name}</strong> <br />
                {company.Company_Name}
                <br />
                {company?.Address[0].Address}, Nº
                {company?.Address[0].Address_Number} -{' '}
                {company?.Address[0].Neighborhood}
                <br />
                {company?.Address[0].City}/{company?.Address[0].State}
                <br />
                <div>
                  {phoneMask(
                    company?.Phones[0].Number_Phone1 ||
                      company?.Phones[0].Number_Phone2
                  )}{' '}
                  - <a href={`mailto:${company.Email}`}>{company.Email}</a>
                </div>
              </p>
            </div>
          </HeaderContainer>
        </SectionContainer>
      )}
    </>
  )
}
