import React from "react";
import { Grid, Row } from "react-bootstrap";

import ListPurchasesSupplier from './PurchasesSupplierReports/ListPurchasesSupplier'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'


const ReturnListingDevolution = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Compras', 'Listagem de Compras por Fornecedor']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <ListPurchasesSupplier />
        </Row>
      </Grid>
    </div>
  );
}
export default ReturnListingDevolution