import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import NewKitMain from './components/NewKitMain'

const NewKit = ({ history, match }) => {
  const kitId = match?.params?.id || history?.location?.hash?.split('#')[1] || null

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              `${kitId ? `Editar Kit` : 'Novo Kit'}`,
            ]}
            path={['home', 'products', null]}
          />
          <div style={{ marginTop: 30 }}>
            <NewKitMain history={history} kitId={kitId} />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewKit
