import React from 'react'

const MateraResumeCard = ({ title, info }) => {
  return (
    <div
      style={{
        padding: '10px',
      }}
    >
      <div className='matera-resume-card'>
        <div className='matera-resume-card-title'> {title} </div>
        <div className='matera-resume-card-info'> {info} </div>
      </div>
    </div>
  )
}

export default MateraResumeCard
