import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import Button from 'client/components/CustomButton/Button.jsx'
import CustomButton from 'client/components/CustomButton/CustomButton.jsx'
import { format, isAfter } from 'date-fns'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customerHistoryRepository from '../../../repositories/CustomerHistory'
import constants from '../../../utils/constants'
import { currency, encrypt } from '../ToNormalize/ToNormalize'

import './index.css'

export const CustomerHistory = ({ customer = {}, vehicles = [] }) => {
  const [loading, setLoading] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [vehicleId, setVehicleId] = useState('')
  const [sales, setSales] = useState([])

  const validations = () => {
    if (!initialDate || !finalDate) {
      toastr.warning('Insira as duas datas para processar.')
      return false
    }

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      toastr.warning('A data final deve ser maior que a data inicial.')
      return false
    }

    return true
  }

  const handleProcess = () => {
    if (!validations()) return
    getSimplifiedCustomerHistory()
  }

  const getSimplifiedCustomerHistory = async () => {
    setSales([])
    setLoading(true)
    try {
      const { data } =
        await customerHistoryRepository.getSimplifiedCustomerHistory(
          customer.id,
          { initialDate, finalDate, vehicleId }
        )
      if (!!!data.length)
        return toastr.warning(
          'Não foram encontrados registros no período selecionado, por favor, verifique o período e tente novamente.'
        )

      setSales(data)
    } catch (err) {
      toastr.warning(
        err?.response?.data?.message ||
          'Ocorre algum problema. Por favor, tente novamente mais tarde.'
      )
    } finally {
      setLoading(false)
    }
  }

  const salesTypeButtonColor = (salesTypeId) => {
    const colors = {
      [constants.SALES_TYPE_ID.ORCAMENT]: '#363636',
      [constants.SALES_TYPE_ID.ORDER_OF_SERVICE]: '#00008B',
      [constants.SALES_TYPE_ID.SALE]: 'purple',
      [constants.SALES_TYPE_ID.PDV]: '#ffa834',
    }

    return colors[salesTypeId]
  }

  const salesStatusButtonColor = (salesStatusId) => {
    const colors = {
      [constants.SALE_STATUS_ID.OPEN]: 'blue',
      [constants.SALE_STATUS_ID.CANCELED]: 'red',
      [constants.SALE_STATUS_ID.FINISHED]: 'green',
    }

    return colors[salesStatusId]
  }

  const handleChangeFinalDate = (event) => {
    const value = event.target.value
    if (!value) {
      setFinalDate(format(new Date(), 'yyyy-MM-dd'))
    } else {
      setFinalDate(value)
    }
  }

  const handleOpenCustomerHistoryBySaleIdReport = (saleId) => {
    const saleIdHash = encrypt(saleId, '@OS-dig:saleId')
    window.open(
      window.location.origin + `/report/customer-history?saleId=${saleIdHash}`,
      '_blank'
    )
  }

  const handleOpenCustomerHistoryByCustomerIdAndDateRangeReport = () => {
    const customerIdHash = encrypt(customer.id, '@OS-dig:customerId')
    let vehicleIdHash
    if (vehicleId) {
      vehicleIdHash = encrypt(vehicleId, '@OS-dig:vehicleId')
    }

    window.open(
      window.location.origin +
        `/report/customer-history?customerId=${customerIdHash}&initialDate=${initialDate}&finalDate=${finalDate}${
          vehicleIdHash ? `&vehicleId=${vehicleIdHash}` : ''
        }`,
      '_blank'
    )
  }

  const handleOpenFinancialStatementReport = () => {
    const customerIdHash = encrypt(customer.id, '@OS-dig:customerId')
    let vehicleIdHash
    if (vehicleId) {
      vehicleIdHash = encrypt(vehicleId, '@OS-dig:vehicleId')
    }

    window.open(
      window.location.origin +
        `/report/financial-statement?customerId=${customerIdHash}&initialDate=${initialDate}&finalDate=${finalDate}${
          vehicleIdHash ? `&vehicleId=${vehicleIdHash}` : ''
        }`,
      '_blank'
    )
  }

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <div
          style={{
            width: '170px',
            marginRight: 10,
          }}
        >
          <label>Data Inicial:</label>
          <input
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            type="date"
            className="form-control foco-input"
          />
        </div>
        <div
          style={{
            width: '170px',
            marginRight: 10,
          }}
        >
          <label>Data Final:</label>
          <input
            value={finalDate}
            onChange={handleChangeFinalDate}
            type="date"
            className="form-control foco-input"
          />
        </div>
        <div
          style={{
            width: '180px',
            marginRight: 10,
          }}
        >
          <label>Veículos:</label>
          <select
            className="form-control foco-input"
            value={vehicleId}
            onChange={(e) => setVehicleId(e.target.value)}
            disabled={!!!vehicles.length}
          >
            <option value="">Todos</option>
            {vehicles.map((vehicle) => (
              <option value={vehicle.id}>{vehicle.License_Plate}</option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <button
            className="btn btn-sucesso"
            style={{
              padding: '7px 30px',
            }}
            disabled={loading}
            onClick={handleProcess}
          >
            Processar
          </button>
        </div>
      </div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={sales}
        columns={[
          {
            Header: 'Data',
            accessor: 'Date',
            width: 100,
            Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Tipo',
            accessor: 'SalesType_id',
            // width: 180,
            Cell: ({ value }) => (
              <Button
                id="botaoReactTable"
                style={{
                  zIndex: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor: salesTypeButtonColor(value),
                }}
              >
                {constants.SALE_TYPE_DESCRIPTION[value]}
              </Button>
            ),
          },
          {
            Header: 'Nº Venda',
            accessor: 'Code',
            width: 100,
          },
          {
            Header: 'Placa',
            accessor: 'Vehicle',
            width: 100,
            Cell: ({ value }) => (value ? value.License_Plate : ''),
          },
          {
            Header: 'Valor',
            accessor: 'Final_Value',
            Cell: ({ value }) => currency(value),
          },
          {
            Header: 'Status',
            accessor: 'SalesStatus_id',
            // width: 180,
            Cell: ({ value }) => (
              <Button
                id="botaoReactTable"
                style={{
                  zIndex: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor: salesStatusButtonColor(value),
                }}
              >
                {constants.SALE_STATUS_DESCRIPTION[value]}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 180,
            Cell: ({ value }) => (
              <FontAwesomeIcon
                cursor="pointer"
                style={{
                  height: '1.5em',
                  width: '1.5em',
                }}
                title="Visualizar"
                icon={faPrint}
                aria-hidden="false"
                onClick={() => handleOpenCustomerHistoryBySaleIdReport(value)}
              />
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum cliente encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            width: '20%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 15,
          }}
        >
          <CustomButton
            bsStyle="primary"
            fill
            style={{ backgroundColor: '#F0AD4E', border: 'none' }}
            disabled={!!!sales.length}
            onClick={handleOpenFinancialStatementReport}
          >
            Ficha Financeira
          </CustomButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginTop: 15,
          }}
          className="customer-history__print-historic-button"
        >
          <CustomButton
            bsStyle="primary"
            fill
            style={{ backgroundColor: '#428bca', border: 'none' }}
            disabled={!!!sales.length}
            onClick={handleOpenCustomerHistoryByCustomerIdAndDateRangeReport}
          >
            Imprimir Histórico Completo do Cliente
          </CustomButton>
        </div>
      </div>
    </>
  )
}
