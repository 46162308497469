import React, { memo } from 'react'
import Chart from 'react-google-charts'

const CharByStatus = ({ data }) => {
  const chartColors = []

  data.forEach((info) => {
    switch (info[0]) {
      case 'Aberto':
        chartColors.push('#ffcc00')
        break
      case 'Finalizado':
        chartColors.push('#5cb85c')
        break
      case 'Cancelado':
        chartColors.push('#ff0000')
        break
    }
  })

  return (
    <>
      <h4>
        <strong>Compras por Status (%)</strong>
      </h4>
      <div className='chart-pagamento' style={{ opacity: '100%' }}>
        <Chart
          width={'500px'}
          height={'300px'}
          chartType='PieChart'
          data={[['Status', 'Quantidade'], ...data]}
          chartLanguage='pt-br'
          options={{
            chartArea: {
              left: 0,
            },
            colors: chartColors,
          }}
        />
      </div>
    </>
  )
}

export default memo(CharByStatus)
