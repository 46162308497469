import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import InventoryCountPDF from './InventoryCountPDF'
import InventoryCount from '../../../repositories/InventoryCount'

const ReturnDocumentPurchasesSupplier = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const inventoryCountId = searchParams.get('inventoryCountId')


      const dados = await loadReport({
        inventoryCountId
      })

      setDataReport(dados)
      setLoading(false)

    } catch (err) {
      setHasError(true)
      setLoading(false)

    }

  }


  const loadReport = async ({ inventoryCountId }) => {
    try {
      const data = await InventoryCount.getById(inventoryCountId)
      console.log(data)
      return data
    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />


  return (

    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}>
          <InventoryCountPDF
            responseData={dataReport}
          />
        </PDFViewer>
      </main>
    </div>
  )

}




export default ReturnDocumentPurchasesSupplier 