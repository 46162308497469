import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import AdditionalInfoRepository from '../../../../../repositories/AdditionalInformation';
import Select from '../../../Clients/NewClient/FormClient/components/Select';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import Button from '../../../../components/CustomButton/CustomButton.jsx';

import { sanitizeInput } from '../../../../components/ToNormalize/ToNormalize';
import { SelectMulti } from 'v2/components/Select/Multi';
import { useState } from 'react';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { useEffect } from 'react';
import { isArray } from 'highcharts';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';

const FormAdditionalInfo = ({ data, loading, handleSubmit }) => {
  const { companyId } = useAuth();

  const dispatch = useDispatch();
  const [additionalInfoText, setAdditionalInfoText] = useState('');
  const [additionalInfoOptions, setAdditionalInfoOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleLoadOptions = async () => {
    setLoading(false);
    try {
      const data = await AdditionalInfoRepository.getOptions();
      let options = data.map((option) => ({
        value: option.id,
        label: option.description,
      }));

      setAllOptions(options);

      setLoading(false);
      return data;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar os dados.',
        'Por favor, tente novamente.'
      );
    }
  };

  const handleFetchData = async () => {
    const data = await AdditionalInfoRepository.getByCompany(companyId);
    setAdditionalInfoOptions(data.InvoiceAdditionalInformationOptions);
    setAdditionalInfoText(data.InvoiceAdditionalInformationText);
    setIsUpdate(
      data.InvoiceAdditionalInformationOptions.length > 0 ? true : false
    );
    dispatch(
      change(
        'settingsAdditionalInfo',
        'selectedOptions',
        data.InvoiceAdditionalInformationOptions
      )
    );
    dispatch(
      change(
        'settingsAdditionalInfo',
        'description',
        data.InvoiceAdditionalInformationText
      )
    );
    return;
  };

  useEffect(() => {
    if (companyId) {
      handleLoadData();
    }
  }, [companyId]);

  const handleLoadData = async () => {
    await handleLoadOptions();
    await handleFetchData();
  };
  const handleDescription = async (e) => {
    const serializedValue = await sanitizeInput(e.target.value);
    setAdditionalInfoText(serializedValue);
    dispatch(change('settingsAdditionalInfo', 'description', serializedValue));
  };
  return (
    <>
      <div
        style={{ display: 'flex', justifycontent: 'flex-end', width: '100%' }}
      >
        <span
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#d43f3a',
            marginLeft: 'auto',
          }}
        >
          O IBPT é enviado automaticamente se o produto possuir NCM e o token
          estiver preenchido
        </span>
      </div>
      <div className="flex flex-start">
        <InputContainer variant="column">
          <InputLabel
            style={{
              fontWeight: 'bold',
              height: '15px',
            }}
          >
            Exibir dados da venda na Danfe (NFe, NFS-e e NFC-e/SAT):
          </InputLabel>

          <SelectMulti
            width={370}
            height={35}
            options={allOptions}
            value={additionalInfoOptions}
            onChange={(e) => {
              setAdditionalInfoOptions(e);
              dispatch(change('settingsAdditionalInfo', 'selectedOptions', e));
            }}
          />
        </InputContainer>
      </div>
      <div>
        <div
          className="mt-075"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
          }}
        >
          <InputContainer variant="row" justify="space-between">
            <InputLabel
              style={{
                fontWeight: 'bold',
                height: '15px',
              }}
            >
              Informações Adicionais:
            </InputLabel>
            <span
              style={{
                alignSelf: 'flex-end',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#d43f3a',
              }}
            >
              O texto será exibido em informações complementares de todas as
              notas
            </span>
          </InputContainer>
          <textarea
            className="form-control foco-input"
            maxLength={500}
            value={additionalInfoText}
            onChange={(e) => {
              handleDescription(e);
            }}
          />
        </div>

        <div
          style={{ display: 'flex', justifycontent: 'flex-end', width: '100%' }}
        >
          <Button
            style={{ marginLeft: 'auto' }}
            bsStyle="info"
            fill
            onClick={handleSubmit}
            disabled={loading}
          >
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            {!!isUpdate ? 'Atualizar' : 'Salvar'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'settingsAdditionalInfo',
})(FormAdditionalInfo);
