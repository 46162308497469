import React, { useState, useEffect } from 'react';
import api from 'services/api';
import config from 'config';
import { validadeEmail } from '../Validations/Validations';
import { phoneMask, onlyNumbers, encrypt } from '../ToNormalize/ToNormalize';
import { Modal } from 'react-bootstrap';
import CustomButton from 'client/components/CustomButton/CustomButton.jsx';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { faEnvelope, faTableList } from '@fortawesome/free-solid-svg-icons';

import './styles.css';
import maintenanceRepository from 'repositories/Maintenance';
import companyRepository from 'repositories/Companies';
import { useAuth } from 'contexts/auth';

const ShareSale = ({
  animation,
  onHide,
  sale_id,
  sale_Type,
  show,
  customer_id,
  onSubmit,
}) => {
  const [shareOption, setShareOption] = useState(0);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [clientSelected, setClientSelected] = useState([]);
  const [clientSelectedNumber, setClientSelectedNumber] = useState('');
  const [clientSelectedMail, setClientSelectedMail] = useState('');
  const [link, setLink] = useState('');

  const [maintenance, setMaintenance] = useState();

  const { companyId } = useAuth();

  const [options, setOptions] = useState([
    {
      key: 'WhatsApp',
      icon: (
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="whatsAppIcon"
          aria-hidden="false"
          width={20}
        />
      ),
      text: (
        <span
          id="optionText"
          style={{
            fontSize: '14px',
          }}
        >
          Compartilhar via WhatsApp
        </span>
      ),
      onClick: () => setShareOption(1),
    },
    {
      key: 'E-mail',
      icon: (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="mailIcon"
          aria-hidden="false"
          width={20}
        />
      ),
      text: (
        <span
          id="optionText"
          style={{
            fontSize: '14px',
          }}
        >
          Compartilhar via E-mail
        </span>
      ),
      onClick: () => setShareOption(2),
    },
  ]);

  // const options = [
  //   {
  //     key: 'WhatsApp',
  //     icon: (
  //       <FontAwesomeIcon
  //         icon={faWhatsapp}
  //         className="whatsAppIcon"
  //         aria-hidden="false"
  //         width={20}
  //       />
  //     ),
  //     text: (
  //       <span
  //         id="optionText"
  //         style={{
  //           fontSize: '14px',
  //         }}
  //       >
  //         Compartilhar via WhatsApp
  //       </span>
  //     ),
  //     onClick: () => setShareOption(1),
  //   },
  //   {
  //     key: 'E-mail',
  //     icon: (
  //       <FontAwesomeIcon
  //         icon={faEnvelope}
  //         className="mailIcon"
  //         aria-hidden="false"
  //         width={20}
  //       />
  //     ),
  //     text: (
  //       <span
  //         id="optionText"
  //         style={{
  //           fontSize: '14px',
  //         }}
  //       >
  //         Compartilhar via E-mail
  //       </span>
  //     ),
  //     onClick: () => setShareOption(2),
  //   },
  // ]

  useEffect(() => {
    validateMaintenanceBySale(sale_id);
    loadClientSelected(customer_id);
    loadLink(sale_id);
  }, []);

  const loadLink = (sale_id) => {
    const hash = `Vend=v723dha5tHSSpS83711${sale_id}5422hsGsdAQLk8423oPL31`;
    const link = `${window.location.origin}/share-sheets/sale/${hash}`;
    setLink(link);
  };

  const loadClientSelected = async (Customer_id) => {
    if (!Customer_id)
      return console.log(
        'Ocorreu algum problema ao carregar o Cliente da Venda'
      );
    const response = await api.get(
      config.endpoint + `customer/byid/${Customer_id}`
    );
    if (response.data.status) {
      setClientSelected(response.data.data);
      setClientSelectedNumber(response.data.data.Phones[0]?.Number_Phone2);
      setClientSelectedMail(response.data.data.Email);
    } else {
      return console.log(
        'Ocorreu algum problema ao carregar o Cliente da Venda'
      );
    }
  };

  const validateMaintenanceBySale = async () => {
    try {
      const data = await maintenanceRepository.getMaintenanceBySaleId(
        companyId,
        sale_id
      );

      setMaintenance(data);

      if (
        !!data &&
        sale_Type === 'Ordem de Serviço' &&
        (data.status === 'Parado' || data.status === 'Em Andamento')
      ) {
        setOptions((options) => [
          ...options,
          {
            key: 'Painel',
            icon: (
              <FontAwesomeIcon
                icon={faTableList}
                className="tableListIcon"
                aria-hidden="false"
                width={20}
                style={{
                  color: '#fff',
                }}
              />
            ),
            text: (
              <span
                id="optionText"
                style={{
                  fontSize: '14px',
                }}
              >
                Compartilhar Link do Painel
              </span>
            ),
            onClick: () => setShareOption(3),
          },
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderModalTitle = (option) => {
    switch (option) {
      case 0:
        return 'Opções de compartilhamento';
      case 1:
        return 'Compartilhar via WhatsApp';
      case 2:
        return 'Compartilhar via E-mail';
      case 3:
        return 'Compartilhar Link do Painel';
      default:
        return 'Opções de compartilhamento';
    }
  };

  const renderModalBody = (option) => {
    switch (option) {
      case 0:
        return indexBody();
      case 1:
        return whatsappOption();
      case 2:
        return emailOption();
      case 3:
        return linkOption();
      default:
        return indexBody();
    }
  };

  const renderModalFooter = (option) => {
    if (option === 0) return indexFooter();
    return optionFooter();
  };

  const indexFooter = () => {
    return (
      <CustomButton
        style={{ padding: '5px', fontWeight: 'normal', fontSize: '15px' }}
        onClick={() => onHide()}
        type="button"
        bsStyle="danger"
        pullRight
        fill
      >
        Cancelar
      </CustomButton>
    );
  };

  const optionFooter = () => {
    return (
      <div>
        <CustomButton
          style={{
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={() => handleSubmit()}
          disabled={!!loadingSendMail}
          type="button"
          id="btn-salvar"
          pullRight
          fill
        >
          Enviar{' '}
          <span
            className={loadingSendMail ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          />
        </CustomButton>
        <CustomButton
          style={{
            marginTop: '5px',
            padding: '5px',
            fontWeight: 'normal',
            fontSize: '15px',
          }}
          onClick={() => handleClose()}
          disabled={!!loadingSendMail}
          type="button"
          id="cancelar"
          pullRight
          fill
        >
          Cancelar
        </CustomButton>
      </div>
    );
  };

  const indexBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.key}
              className="cardOption"
              id={op.key}
              onClick={op.onClick}
              style={{
                width: '230px',
              }}
            >
              <span style={{ width: '50px' }}>{op.icon}</span>
              <span>{op.text}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const whatsappOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className="form-control foco-input"
            value={clientSelected?.Company_Name ?? 'Cliente não encontrado'}
            disabled={true}
          />
        </div>
        <div>
          <label>Celular</label>
          <input
            className="form-control foco-input"
            value={phoneMask(clientSelectedNumber)}
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
            onChange={(event) =>
              setClientSelectedNumber(onlyNumbers(event.target.value))
            }
          />
        </div>
      </div>
    );
  };

  const emailOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className="form-control foco-input"
            value={clientSelected?.Company_Name ?? 'Cliente não encontrado'}
          />
        </div>
        <div>
          <label>E-mail</label>
          <input
            className="form-control foco-input"
            value={clientSelectedMail}
            onChange={(event) =>
              setClientSelectedMail(event.target.value.trim())
            }
            onKeyDown={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
          />
        </div>
      </div>
    );
  };

  const linkOption = () => {
    return (
      <div>
        <div>
          <label>Nome</label>
          <input
            className="form-control foco-input"
            value={clientSelected?.Company_Name ?? 'Cliente não encontrado'}
            disabled={true}
          />
        </div>
        <div>
          <label>Celular</label>
          <input
            className="form-control foco-input"
            value={phoneMask(clientSelectedNumber)}
            onKeyDown={(e) => (e.key == 'Enter' ? handleSubmit() : null)}
            onChange={(event) =>
              setClientSelectedNumber(onlyNumbers(event.target.value))
            }
          />
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    switch (shareOption) {
      case 1:
        return handleWhatsapp();
      case 2:
        return handleMail();
      case 3:
        return handleLink();
      default:
        break;
    }
  };

  const handleMail = async () => {
    if (!clientSelected || !clientSelectedMail) {
      return toastr.warning(
        'Preencha o Nome e o Email para compartilhar a venda'
      );
    }

    if (!validadeEmail(clientSelectedMail))
      return toastr.warning('E-mail inválido');

    setLoadingSendMail(true);
    const response = await api.post(config.endpoint + `email/sendToClient`, {
      Email: clientSelectedMail,
      Link: link,
      Sale_Type: sale_Type,
      Sale_Id: sale_id,
      Location: window.location.origin,
    });

    setLoadingSendMail(false);
    if (response.data.status) {
      toastr.success(response.data.message);
    } else {
      toastr.error(
        'Falha ao enviar email',
        'Identificamos uma falha ao enviar email. Tente novamente mais tarde e caso persista, acione o suporte técnico online'
      );
    }

    setShareOption(0);
    onSubmit();
  };

  const handleWhatsapp = async () => {
    if (!clientSelectedNumber) {
      return toastr.warning('Preencha o celular para compartilhar a venda');
    }
    window.open(
      config.whatsappendpoint +
        `${clientSelectedNumber}?text=${link}&lang=pt_br`,
      '_blank'
    );
    setShareOption(0);
    onSubmit();
  };

  const handleLink = async () => {
    const panelLink = getExternalPanelLink();

    const companyInfo = await loadCompanyInfo();

    const companyPhone =
      companyInfo?.Phones[0].Number_Phone1 ||
      companyInfo?.Phones[0].Number_Phone2 ||
      '';

    const text = `Prezado(a) ${
      maintenance?.Sales?.Customer?.name || ''
    }%0a%0aFoi iniciado o serviço em seu veículo. Você poderá acompanhar o andamento dele através do link: ${panelLink} %0a%0aEm caso de dúvidas, pedimos que entre em contato conosco através do telefone ${
      companyPhone ? phoneMask(companyPhone) : ''
    }%0a%0aAtenciosamente, %0a%0a${
      companyInfo?.Trading_Name || companyInfo?.Company_Name || ''
    }`;

    window.open(
      `https://api.whatsapp.com/send?phone=55${clientSelectedNumber?.replace(
        /\D/g,
        ''
      )}&text=${text}`,
      '_blank'
    );

    setShareOption(0);
  };

  const getExternalPanelLink = () => {
    try {
      const companyIdHash = encodeURIComponent(
        encrypt(String(companyId), '@OS-dig:companyId')
      );
      const saleIdHash = encodeURIComponent(
        encrypt(String(maintenance?.Sales?.id), '@OS-dig:saleId')
      );

      const domain = window.location.host;

      const link = `${domain}/external-workshop-panel/${companyIdHash}/${saleIdHash}`;
      return link.replace(/\s/g, '');
    } catch (error) {
      console.error('Erro ao gerar link de painel externo: ', error);
    }
  };

  const loadCompanyInfo = async () => {
    try {
      const data = await companyRepository.getWithAddressAndPhones(companyId);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setShareOption(0);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      animation={animation}
      dialogClassName="modal-30w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{renderModalTitle(shareOption)}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody(shareOption)}</Modal.Body>
      <Modal.Footer>{renderModalFooter(shareOption)}</Modal.Footer>
    </Modal>
  );
};

export default ShareSale;
