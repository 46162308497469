
import React, { useState, useEffect } from 'react';
import { isValid } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import SalesRepository from '../../../../../repositories/Sales'

import './styles.css'

const ProductServiceReviews = () => {
  const [loading, setLoading] = useState(false)
  const [warranty, setWarranty] = useState(false)
  const [review, setReview] = useState(false)
  const [finalDate, setFinalDate] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const { companyId } = useAuth()

  const handleSubmit = async () => {

    if (validations()) handleProcess()
  }

  const validations = () => {

    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate))
    ) {
      toastr.warning('Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente')
      return false
    }

    return true
  }


  const handleProcess = async () => {
    setLoading(true)
    try {


      const data = await SalesRepository.generateProductServiceReviews({
        companyId,
        initialDate,
        finalDate,
      })

      if (data?.report.sales.length <= 0) {
        toastr.warning('Nenhuma informação para o relatório foi encontrada.')
        return
      } else {
        handlePrint()
      }

    } catch (err) {
      console.log(err)
      toastr.error(err?.response?.data?.message || 'Ocorreu um erro com a buscar. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    const companyIdHash = encrypt(companyId, '@OS-dig:companyId')
    window.open(window.location.origin + `/report/product-service-reviews?companyId=${companyIdHash}&initialDate=${initialDate}&finalDate=${finalDate}&warranty=${warranty}&review=${review}`, '_blank')
  }
  return (
    <div className='content-service-reviews-report'>
      <div>
        <label>Data Inicial:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={initialDate}
          onChange={e => setInitialDate(e.target.value)}
        />
      </div>

      <div>
        <label>Data Final:</label>
        <input
          type="date"
          className="form-control foco-input"
          value={finalDate}
          onChange={e => setFinalDate(e.target.value)}
        />
      </div>

      <div>
        <label>Exibir itens sem garantia:</label>
        <select
          value={warranty}
          onChange={(e) => setWarranty(e.target.value)}
          className="form-control foco-input"
          style={{ width: '190px' }}>
          <option value="false">Não</option>
          <option value="true">Sim</option>
        </select>
      </div>

      <div>
        <label>Exibir itens sem revisão:</label>
        <select
          value={review}
          onChange={(e) => setReview(e.target.value)}
          className="form-control foco-input"
          style={{ width: '190px' }}>
          <option value="false">Não</option>
          <option value="true">Sim</option>
        </select>
      </div>

      <div>
        <button
          className="btn btn-sucesso"
          onClick={handleSubmit}
          disabled={loading}>
          <span className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`} /> Processar</button>
      </div>
    </div>
  )

}
export default ProductServiceReviews
