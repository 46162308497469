import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import InputMask from 'react-input-mask'
import axios from 'axios'
import { useFormContext, useWatch, Controller } from 'react-hook-form'

import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize'

import addressRepository from 'repositories/Addresses'

export default function NewTradeRepresentativeAddress() {
  const { register, setValue, control } = useFormContext()

  const [citiesOptions, setCitiesOptions] = useState([])
  const [statesOptions, setStatesOptions] = useState([])

  const watchedZipcode = useWatch({
    control: control,
    name: 'zipcode',
  })

  function handleCity(city) {
    switch (city) {
      case 'Birigüi':
        return 'Birigui'
      default:
        return city
    }
  }

  async function handleSearchAddressByZipcode() {
    try {
      const onlyNumbersZipCode = watchedZipcode
        ? onlyNumbers(watchedZipcode)
        : ''
      const addressSearch = await axios.get(
        `https://viacep.com.br/ws/${onlyNumbersZipCode}/json/`
      )

      setValue('address', addressSearch.data.logradouro)
      setValue('neighborhood', addressSearch.data.bairro)
      setValue('state', addressSearch.data.uf)
      setValue('city', handleCity(addressSearch.data.localidade))
    } catch (err) {
      console.log(err)
      toastr.warning(
        'OS Digital',
        'Endereço não localizado. Por favor, insira manualmente.'
      )
    }
  }

  async function loadCities() {
    try {
      const cities = await addressRepository.getAllCities()
      setCitiesOptions(cities)
    } catch (err) {
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar as cidades. Tente novamente!'
      )
    }
  }

  async function loadStates() {
    try {
      const states = await addressRepository.getAllStates()
      setStatesOptions(states)
    } catch (err) {
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os estados. Tente novamente!'
      )
    }
  }

  useEffect(() => {
    loadCities()
    loadStates()
  }, [])

  useEffect(() => {
    let watchedZipCodeNumbers = watchedZipcode
      ? onlyNumbers(watchedZipcode)
      : ''
    if (watchedZipCodeNumbers.length === 8) {
      handleSearchAddressByZipcode()
    }
  }, [watchedZipcode])

  return (
    <div className="financing-client-form address-data">
      <div className="row-1">
        <div className="input-block">
          <label htmlFor="zipcode">
            CEP:
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
            >
              Não sei meu CEP
            </a>
          </label>

          <Controller
            defaultValue={''}
            name={'zipcode'}
            control={control}
            render={({ field }) => {
              return (
                <InputMask
                  {...field}
                  mask={'99999-999'}
                  formatChars={{
                    9: '[0-9]',
                  }}
                >
                  {() => (
                    <input
                      id={'zipcode'}
                      type="text"
                      className="form-control foco-input"
                    />
                  )}
                </InputMask>
              )
            }}
          />
        </div>
        <div className="input-block">
          <label htmlFor="address">Endereço:</label>
          <input
            type="text"
            className="form-control foco-input"
            id="address"
            {...register('address')}
          />
        </div>
        <div className="input-block">
          <label htmlFor="streetNumber">Nº:</label>
          <input
            type="text"
            className="form-control foco-input"
            id="streetNumber"
            {...register('streetNumber')}
          />
        </div>
        <div className="input-block">
          <label htmlFor="neighborhood">Bairro:</label>
          <input
            type="text"
            className="form-control foco-input"
            id="neighborhood"
            {...register('neighborhood')}
          />
        </div>
        <div className="input-block">
          <label htmlFor="complement">Complemento:</label>
          <input
            type="text"
            className="form-control foco-input"
            id="complement"
            {...register('complement')}
          />
        </div>
      </div>
      <div className="row-2">
        <div className="input-block">
          <label htmlFor="referencePoint">Ponto de Referência:</label>
          <input
            type="text"
            className="form-control foco-input"
            id="referencePoint"
            {...register('referencePoint')}
          />
        </div>
        <div className="input-block">
          <label htmlFor="city">Cidade:</label>
          <Controller
            defaultValue={''}
            control={control}
            name="city"
            render={({ field }) => (
              <select className="form-control foco-input" id="city" {...field}>
                <option value="" disabled>
                  Selecione:
                </option>
                {citiesOptions.map((c) => {
                  return (
                    <option key={c.id} value={c.Name}>
                      {c.Name}
                    </option>
                  )
                })}
              </select>
            )}
          />
        </div>
        <div className="input-block">
          <label htmlFor="state">Estado:</label>
          <Controller
            defaultValue={''}
            control={control}
            name="state"
            render={({ field }) => (
              <select className="form-control foco-input" id="state" {...field}>
                <option value="" disabled>
                  Selecione:
                </option>
                {statesOptions.map((e) => {
                  return (
                    <option key={e.id} value={e.Initials}>
                      {e.Name}
                    </option>
                  )
                })}
              </select>
            )}
          />
        </div>
      </div>
    </div>
  )
}
