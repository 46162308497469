import React, { useEffect } from 'react'
import Toggle from 'react-toggle'
import { useFormContext, useWatch } from 'react-hook-form'

import LegalPerson from './LegalPerson'
import PhysicalPerson from './PhysicalPerson'

export default function NewTradeRepresentativeBasicData({
  handleNextSection,
  tradeRepresentativeId,
}) {
  const { register, setValue, control } = useFormContext()

  const isActive = useWatch({
    control,
    name: 'isActive',
  })

  const type = useWatch({
    control,
    name: 'type',
  })

  const serviceComission = useWatch({
    control,
    name: 'serviceComission',
  })

  const productComission = useWatch({
    control,
    name: 'productComission',
  })

  return (
    <div className="new-trade-representative__basic-data-wrapper">
      <div className="new-trade-representative__basic-data-wrapper-row-1">
        <div>
          <label htmlFor="type">Tipo de Pessoa:</label>
          <select
            id="type"
            className="form-control foco-input"
            {...register('type')}
            disabled={!!tradeRepresentativeId}
          >
            <option value={'Juridica'}>Jurídica</option>
            <option value={'Fisica'}>Física</option>
          </select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="isActive">
            Status: <span style={{ color: 'red' }}>*</span>
          </label>
          <Toggle
            id="isActive"
            checked={isActive}
            name="Status"
            style={{ position: 'fixed' }}
            readOnly
            onClick={() => setValue('isActive', !isActive)}
          />
        </div>
      </div>

      {type === 'Juridica' ? (
        <LegalPerson handleNextSection={handleNextSection} />
      ) : (
        <PhysicalPerson handleNextSection={handleNextSection} />
      )}
    </div>
  )
}
