import React, { useState } from 'react';
import { SearchInput } from 'v2/components/form';
import { Table } from 'v2/components/Table';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { listCorrections } from 'v2/repositories/CorrectionsRepository';
import CreateNewCorrectionModal from './CreateNewCorrectionModal';
import useFilters from 'hooks/useFilters';

export function AddCorrectionsModal({
  handleClose,
  handleSubmit,
  alreadyLinkedCorrections,
}) {
  const { companyId } = useAuth();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [isNewCorrectionModalOpen, setIsNewCorrectionModalOpen] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const atLeastOneCorrectionSelected = selectedRows.length > 0;
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const allSelected = rows.every((row) => row.selected);

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 250,
      Footer: (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            title="Excluir"
            onClick={() => null}
            style={{
              padding: 0,
              color: 'green',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            <FontAwesomeIcon
              cursor="pointer"
              fontSize={20}
              icon={faPlusCircle}
              onClick={() => setIsNewCorrectionModalOpen(true)}
            />
          </button>
        </div>
      ),
    },
    {
      Header: 'Detalhes as correções',
      accessor: 'details',
      Footer: (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            onClick={() => setIsNewCorrectionModalOpen(true)}
            style={{ cursor: 'pointer', color: '#428bca' }}
          >
            Cadastrar Nova Correção
          </span>
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'id',
      width: 60,
      Cell: (props) => (
        <input
          type="checkbox"
          className="checkbox-input bulk-deletion-table"
          checked={props.original.selected}
          onChange={() => handleCheckboxChange(props.index, props)}
        />
      ),
    },
  ];

  const onHide = () => {
    return handleClose();
  };

  const onCancel = () => {
    return handleClose();
  };

  const onSubmit = () => {
    handleSubmit(selectedRows);
    handleClose();
    toastr.success(
      'Correções adicionadas',
      'Os itens selecionados foram adicionados na relação com sucesso'
    );
    return;
  };

  const handleFetch = async () => {
    setIsFetching(true);
    try {
      const res = await listCorrections({
        page: page + 1,
        limit,
        query: '',
        companyId,
      });

      setCount(res.count);
      const mappedRows = res.rows.map((row) => {
        const selected = selectedRows.some(
          (selectedRow) => selectedRow.id === row.id
        );
        return {
          ...row,
          selected,
        };
      });
      setRows(mappedRows);
      setPages(Math.ceil(res.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const handleSelectNewCorrection = (correction) => {
    const newRow = {
      id: correction.id,
      companyId: correction.companyId,
      correctionsAssociated: [],
      description: correction.description,
      details: correction.details,
      status: correction.status,
      totalCorrections: 0,
      selected: true,
    };
    setRows([...rows, newRow]);
    setSelectedRows([...selectedRows, newRow]);
  };

  const handleCheckboxChange = (index, item) => {
    const newRows = rows.map((row, currentIndex) => {
      if (currentIndex === index) {
        return {
          ...row,
          selected: !row.selected,
        };
      }
      return row;
    });

    if (newRows[index].selected) {
      setSelectedRows([...selectedRows, newRows[index]]);
    } else {
      setSelectedRows(
        selectedRows.filter((row) => row.id !== newRows[index].id)
      );
    }
    setRows(newRows);
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    const value = allSelected ? false : true;

    const newSelectedRows = [
      ...new Set([
        ...selectedRows,
        ...rows
          .map((row) => ({ ...row, selected: value }))
          .filter(
            (row, index, self) =>
              index === self.findIndex((t) => t.id === row.id)
          ),
      ]),
    ];

    setSelectedRows(newSelectedRows);
    setRows(
      rows.map((row) => {
        return {
          ...row,
          selected: value,
        };
      })
    );
  };

  const handleSwitchModal = () => {
    setIsNewCorrectionModalOpen(!isNewCorrectionModalOpen);
  };

  useEffect(() => {
    handleFetch();
  }, [query, companyId, handleSubmit, page]);

  return (
    <Modal
      dialogClassName="modal-marginTop10 modal-70w backdrop='static'"
      show={true}
      onHide={() => (isLoading ? {} : onHide())}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <strong>Adicionar Correções</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <SearchInput
            id="input__query"
            placeholder="Pesquisa por Descrição ou Detalhes"
            value={query}
            width={300}
            onChange={(e) => setQuery(e.target.value)}
          />
          <a
            id="hiperlink-text"
            onClick={handleSelectAll}
            style={{ marginRight: '10px' }}
          >
            {!allSelected ? 'Selecionar' : 'Remover'} Todos
          </a>
        </div>
        <div className="mt-075" style={{ width: '100%', alignItems: 'center' }}>
          <Table
            columns={columns}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            onPageSizeChange={setLimit}
            manual
            pages={pages}
            data={rows}
            loading={isFetching}
            style={{
              fontSize: '12px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              height: '330px',
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Button
            bsStyle="danger"
            disabled={isLoading}
            name="Não"
            onClick={(e) => onCancel(e)}
            fill
          >
            Cancelar
          </Button>

          <Button
            bsStyle="info"
            disabled={isLoading || !atLeastOneCorrectionSelected}
            name="Sim"
            fill
            onClick={(e) => onSubmit(e)}
          >
            Salvar
            <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
        {isNewCorrectionModalOpen && (
          <CreateNewCorrectionModal
            handleClose={handleSwitchModal}
            companyId={companyId}
            onInclude={handleSelectNewCorrection}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}
