import React, { useState } from 'react';
import { InputPasswordStyled, InputPasswordWrapper } from './styles';

export const InputPassword = React.forwardRef(
  ({ width, height = 40, showPasswordPreviewIcon = true, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const eyeIcon = showPassword ? 'fa-eye-slash' : 'fa-eye';

    function handleShowPassword() {
      setShowPassword(!showPassword);
    }

    return (
      <InputPasswordWrapper width={width} height={height}>
        <InputPasswordStyled
          type={showPassword ? 'text' : 'password'}
          {...props}
          ref={ref}
        />
        {showPasswordPreviewIcon === true ? (
          <span
            title={`${showPassword ? 'Exibir' : 'Ocultar'} Senha`}
            className={`fa ${eyeIcon} pointer`}
            onClick={handleShowPassword}
          ></span>
        ) : (
          <></>
        )}
      </InputPasswordWrapper>
    );
  }
);
