import React, { useEffect } from 'react';
import { useAdminAuth } from '../../contexts/adminAuth';
import AdminInitialRoutes from './initial.routes';
import AdminLoggedRoutes from './logged.routes';

import faviconOSD from '../../assets/favicon/osd.ico';

const AdminRoutes = () => {
  const { isSigned, loading } = useAdminAuth();

  useEffect(() => {
    const headTitleOSD = document.querySelector('head');
    const brandingFaviconOSD = document.createElement('link');
    brandingFaviconOSD.setAttribute('rel', 'shortcut icon');
    brandingFaviconOSD.setAttribute('href', faviconOSD);
    headTitleOSD.appendChild(brandingFaviconOSD);
  }, []);

  if (loading) return <div id="app-loading">Carregando</div>;

  return !isSigned ? <AdminInitialRoutes /> : <AdminLoggedRoutes />;
};

export default AdminRoutes;
