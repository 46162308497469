import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import ClientModal from './ClientModal';
import customersRepository from '../../../../../repositories/Customers';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';
import NewClientModal from 'client/components/NewClientVehicle';

const ClientPdv = () => {
  const [modal, setModal] = useState(false);
  const [newClientModal, setNewClientModal] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const { customerId, vehicleId } = useSelector(
    (state) => state.form.pdvOnline.values
  );
  const dispatch = useDispatch();

  const [clientVehicles, setClientVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');

  useEffect(() => {
    if (!!customerId) {
      loadClient();
    } else {
      defaultCustomer();
    }
  }, [customerId]);

  const loadClient = async () => {
    try {
      const client = await customersRepository.getCustomer(customerId);
      setClientVehicles(client?.Vehicle);

      if (client?.Vehicle?.length > 0) {
        dispatch(change('pdvOnline', 'vehicleId', client?.Vehicle[0].id));
        setSelectedVehicle(client?.Vehicle[0].Model);
      } else {
        setSelectedVehicle('');
      }

      setIsDefault(isDefaultCustomer(client));
      if (!!client) {
        const obj = {
          name: client.Company_Name,
          cpfCnpj: client.Cpf_Cnpj,
          email: client.Email,
          phone:
            client.Phones.length > 0
              ? client.Phones[0].Number_Phone2 || client.Phones[0].Number_Phone1
              : null,
        };
        dispatch(change('pdvOnline', 'client', obj));
        dispatch(
          change('pdvOnline', 'availableCredit', client.availableCredit)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const defaultCustomer = async () => {
    const res = await customersRepository.getCustomerByCpfCnpj('00000000000');
    setIsDefault(isDefaultCustomer(res));

    if (!!res) {
      const obj = {
        name: res.Company_Name,
        cpfCnpj: res.Cpf_Cnpj,
        email: res.Email,
      };
      dispatch(change('pdvOnline', 'client', obj));
      dispatch(change('pdvOnline', 'customerId', res.id));
    }
  };

  return (
    <div style={{ alignItems: 'end' }}>
      {!!isDefault ? (
        <div>
          <span>Cliente</span>
          <a
            style={{
              marginLeft: '10px',
              color: '#00a4d3',
              cursor: 'pointer',
              fontfamily: 'Roboto',
            }}
            onClick={() => setNewClientModal(true)}
          >
            Novo Cliente
          </a>
          <a
            style={{
              float: 'right',
              color: '#00a4d3',
              fontFamily: 'Roboto',
              cursor: 'pointer',
            }}
            onClick={() => {
              setModal(true);
            }}
          >
            Alterar Cliente
          </a>
          <Field
            name="client.name"
            id="client"
            component="input"
            disabled
            className="form-control"
            style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
          />
        </div>
      ) : (
        <div className="client-pdv-info">
          <div className="client-pdv-cpf" style={{ width: '100%' }}>
            <div>
              <span>CPF/CNPJ</span>
            </div>
            <Field
              name="client.cpfCnpj"
              id="cpf"
              component="input"
              disabled
              className="form-control"
              style={{
                fontSize: '14px',
                height: '35px',
                width: '100%',
                borderRadius: '2px',
              }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div>
              <span>Cliente</span>
              <a
                style={{
                  float: 'right',
                  cursor: 'pointer',
                }}
                onClick={defaultCustomer}
              >
                Remover Cliente
              </a>
            </div>
            <Field
              name="client.name"
              id="client"
              component="input"
              disabled
              className="form-control"
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div>
              <span>Celular</span>
            </div>
            <Field
              name="client.phone"
              id="phone"
              component="input"
              disabled
              className="form-control"
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div>
              <span>E-mail</span>
            </div>
            <Field
              name="client.email"
              id="email"
              component="input"
              disabled
              className="form-control"
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
            />
          </div>
          <div>
            <span>Placa/Obj. Manut.</span>
            <select
              disabled={!clientVehicles.length}
              className="form-control"
              style={{
                fontSize: '14px',
                height: '35px',
                width: '100%',
                borderColor: '#c0c0c0',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderRadius: '0px',
                backgroundColor: 'transparent',
                borderRadius: '2px',
              }}
              value={vehicleId}
              onChange={(e) => {
                const vehicle = clientVehicles.find(
                  (vehicle) => vehicle.id === Number(e?.target?.value)
                );

                if (e?.target?.value === '') {
                  setSelectedVehicle('');
                } else {
                  setSelectedVehicle(vehicle?.Model);
                }

                dispatch(change('pdvOnline', 'vehicleId', e?.target?.value));
              }}
            >
              {clientVehicles.length >= 1 ? (
                <option key="" value="">
                  Selecione
                </option>
              ) : null}

              {clientVehicles.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.License_Plate !== ''
                    ? c.License_Plate
                    : c.Maintenance_Object}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div>
              <span>Modelo do Veículo</span>
            </div>
            <input
              className="form-control foco-input"
              type="text"
              value={selectedVehicle}
              style={{ fontSize: '14px', height: '35px', borderRadius: '2px' }}
              disabled
            />
          </div>
        </div>
      )}

      {newClientModal && (
        <NewClientModal
          onHide={() => setNewClientModal(false)}
          isModalOpen={newClientModal}
        />
      )}
      {modal && <ClientModal onHide={() => setModal(false)} />}
    </div>
  );
};

export default ClientPdv;
