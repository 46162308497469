import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton'

import './styles.css'

export default function BankSlipUpdateConfirmModal({ onSubmit, values, show }) {
  return (
    <Modal show={show} animation onHide={() => onSubmit(values)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section>
          <div style={{ marginBottom: '2%' }}>
            <strong>Você deseja realizar a atualização no boleto?</strong>
          </div>
          <div>
            <p>
              Será enviado para o banco a atualização no valor e no vencimento
              do boleto efetuando a atualização.
            </p>
          </div>
          <div>
            <p>
              Caso o cliente já tenha o boleto, pedimos que reenvie o mesmo para
              que faça o pagamento do boleto corretamente
            </p>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle='danger' onClick={() => onSubmit(values)}>
          Voltar
        </Button>
        <Button fill bsStyle='info' onClick={() => onSubmit(values, true)}>
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
