import Button from 'client/components/CustomButton/CustomButton'
import React from 'react'
import { Modal } from 'react-bootstrap'

import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize'

import './styles.css'

export default function ChangeCompanyDocumentConfirmModal({
  user,
  email,
  company,
  onCancel,
  handleSubmit,
}) {
  return (
    <Modal show animation onHide={onCancel}>
      <Modal.Header closeButton={onCancel}>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '14px' }}>
        <strong>Não foi possível realizar a troca de email do usuário</strong>
        
        <br />
        <br />
        
        <div className='user-information-container'>
          <span>
            O novo email já está vinculado a seguinte empresa:&nbsp;
            {company?.cpfCnpj.length <= 11
              ? cpfMask(company.cpfCnpj)
              : cnpjMask(company.cpfCnpj)}
          </span>
          <span>A assinatura dessa empresa está: {company?.signatureStatus}</span>
          <span>O plano dessa empresa é: {company?.plan}</span>
        </div>
                
        <br />
        <span style={{ margin: '12px 0' }}>
          Você deseja realiza a troca mesmo assim?
        </span>

        <br />
        <br />
        
        <div style={{
          color: '#d43f3a',
          fontSize: '12px',
          textAlign: 'center'
        }}>
          A troca inutilizará o acesso a outra empresa através desse usuário
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle='danger' name='cancelar' onClick={onCancel}>
          Não
        </Button>
        <Button
          fill
          bsStyle='info'
          name='update-document'
          onClick={() => handleSubmit(user, email, true)}
        >
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
