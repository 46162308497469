import React from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import InvoicesByCustomer from './InvoicesByCustomer';

function ReportInvoicesByCustomer() {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Relatórios', 'Notas Fiscais por Cliente']}
        path={['home', null, 'invoice-by-customer']}
      />
      <InvoicesByCustomer />
    </>
  );
};

export default ReportInvoicesByCustomer;