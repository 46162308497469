import Card from '../../../../components/Card/Card';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { useAuth } from '../../../../contexts/auth';
import quotationsRepository from '../../../../repositories/Quotations';
import useFilters from 'hooks/useFilters';
import QuotationsTable from './QuotationsTable';
import QuotationsFilter from './QuotationsFilters';

function QuotationsMain({ history }) {

  const { companyId } = useAuth();

  const [loading, setLoading] = useState(false);
  const [quotations, setQuotations] = useState([]);

  const { dateFilter, queryFilter, statusFilter } = useFilters();
  const { filterByDate, finalDate, initialDate, setFinalDate, setInitialDate } = dateFilter;
  const { status, setStatus, filterByStatus } = statusFilter;
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (!!companyId) loadQuotations();
  }, []);

  const loadQuotations = async () => {
    setLoading(true);
    try {
      const data = await quotationsRepository.getAllByCompany(companyId);
      setQuotations(data);
    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao buscar as cotaçoes online. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    };
  };


  const handleFilters = (quotations) => {
    const querySearch = [
      quotations['code'],
      quotations.QuotationProviders[0]?.Provider?.companyName
    ];

    return (
      filterByQuery(querySearch) &&
      filterByDate('createdAt', quotations) &&
      filterByStatus('status', quotations)
    );
  };


  const filteredQuotations = quotations?.data?.filter(handleFilters);

  return (
    <Card
      content={
        <div>
          <QuotationsFilter
            history={history}
            query={query}
            setQuery={setQuery}
            status={status}
            setStatus={setStatus}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
          />

          <QuotationsTable
            loading={loading}
            quotations={filteredQuotations}
            reloadQuotations={loadQuotations}
          />
        </div>
      }
    />
  )
}

export default withRouter(QuotationsMain);
