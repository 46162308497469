import api from '../services/api';

export const getGroupItem = async (id, companyId) => {
  try {
    const res = await api.get('/group-items/' + id, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listGroupItems = async (params) => {
  try {
    const res = await api.get('/group-items', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateGroupItem = async (id, data) => {
  try {
    await api.put('/group-items/' + id, data);
  } catch (err) {
    throw err;
  }
};

export const createGroupItem = async (body) => {
  try {
    const res = await api.post('/group-items', body);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteGroupItem = async (id) => {
  try {
    await api.delete('/group-items/' + id);
  } catch (err) {
    throw err;
  }
};

export const GroupItemRepository = {
  getGroupItem,
  listGroupItems,
  updateGroupItem,
  createGroupItem,
  deleteGroupItem,
};
