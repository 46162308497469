import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { format, isAfter } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

import useFilters from 'hooks/useFilters';
import { useAuth } from 'contexts/auth';
import SearchInput from 'components/SearchInput';
import DownloadXlsButton from 'components/DownloadXlsButton';

import salesItemsRepository from 'repositories/SalesItems';

import { currency } from 'client/components/ToNormalize/ToNormalize';

import './styles.css';

const ReturnForecast = () => {
  const [loading, setLoading] = useState(false);
  const [reportSalesItems, setReportSalesItems] = useState([]);
  const [noDataText, setNoDataText] = useState(
    'Selecione um período e clique em Gerar relatório'
  );

  const { companyId } = useAuth();

  const { typeFilter, queryFilter, dateFilter } = useFilters();

  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;

  useEffect(() => {
    if (companyId) {
      const params = new URLSearchParams(window.location.search);

      const receiveInitialDate = params.get('firstDate');
      const receiveFinalDate = params.get('secondDate');

      if (receiveInitialDate && receiveFinalDate) {
        setInitialDate(receiveInitialDate);
        setFinalDate(receiveFinalDate);
        loadReturnForecastReport();
      }
    }
  }, [companyId, initialDate, finalDate]);

  const loadReturnForecastReport = async () => {
    try {
      if (!validations()) return;

      setLoading(true);

      const { data: reportSalesItems } =
        await salesItemsRepository.getReturnForecastSalesItems(companyId);

      let serializedReportSalesItems = reportSalesItems.map((saleItem) => ({
        date: format(new Date(saleItem.Sales.Date), 'dd/MM/yyyy'),
        code: saleItem.Sales.Code,
        customer: saleItem.Sales.Customer.Company_Name,
        licensePlate: saleItem.Sales.License_Plate,
        type: saleItem.Type,
        description: saleItem.Description,
        unitValue: saleItem.Unit_Value,
        revisionDate: saleItem.revisionDate,
        revisionKilometrage: `${saleItem.revisionKilometrage} km`,
        formatedRevisionDate: format(
          new Date(saleItem.revisionDate),
          'dd/MM/yyyy'
        ),
      }));

      serializedReportSalesItems = serializedReportSalesItems.filter(
        (saleItem) => saleItem
      );
      if (!serializedReportSalesItems.length) {
        setNoDataText('Nenhum retorno foi identificado');
      } else {
        setReportSalesItems(serializedReportSalesItems);
      }
    } catch (err) {
      return toastr.warning('Ocorreu um erro ao gerar o relatório.');
    } finally {
      setLoading(false);
    }
  };

  const validations = () => {
    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      toastr.warning('Selecione um período válido.');
      return false;
    }

    return true;
  };

  const handleFilters = (reportSalesItems) => {
    const querySearch = [
      reportSalesItems.code,
      reportSalesItems.customer,
      reportSalesItems.licensePlate,
    ];

    return (
      filterByType('type', reportSalesItems) &&
      filterByQuery(querySearch) &&
      filterByDate('revisionDate', reportSalesItems)
    );
  };

  const filteredReport = reportSalesItems.filter(handleFilters);

  return (
    <>
      <section className="return-forecast-container">
        <section>
          <fieldset>
            <label>Data Inicial:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              max={finalDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label>Data Final:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              min={initialDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label>Tipo</label>
            <select
              className="form-control foco-input"
              onChange={(event) => setType(event.target.value)}
              value={type}
            >
              <option value="">Todos</option>
              <option value="Produto">Produto</option>
              <option value="Serviço">Serviço</option>
            </select>
          </fieldset>
          <div>
            <button
              className="btn btn-sucesso"
              type="submit"
              disabled={initialDate === '' || finalDate === '' || loading}
              onClick={loadReturnForecastReport}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Gerar relatório
            </button>
          </div>
          <DownloadXlsButton
            archiveName={`Previsão_de_Retorno
              -${format(new Date(initialDate || null), 'dd-MM-yyyy')}
              -${format(new Date(finalDate || null), 'dd-MM-yyyy')}`}
            data={filteredReport}
            className="btn btn-export export-forecast"
            disabled={loading || !filteredReport.length}
            columns={[
              {
                name: 'Data',
                acessor: 'date',
              },
              {
                name: 'N.º da Venda',
                acessor: 'code',
              },
              {
                name: 'Cliente',
                acessor: 'customer',
              },
              {
                name: 'Placa',
                acessor: 'licensePlate',
              },
              {
                name: 'Tipo',
                acessor: 'type',
              },
              {
                name: 'Item',
                acessor: 'description',
              },
              {
                name: 'Valor',
                acessor: 'unitValue',
              },
              {
                name: 'Revisão - Data',
                acessor: 'formatedRevisionDate',
              },
              {
                name: 'Revisão - KM',
                acessor: 'revisionKilometrage',
              },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </section>
        <section>
          <div className="search-input">
            <SearchInput
              placeholder="Pesquisa por Venda, Cliente ou Placa"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </section>
      </section>
      <main>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            width: '100%',
          }}
          data={filteredReport}
          columns={[
            {
              Header: 'Data',
              accessor: 'date',
              width: 100,
            },
            {
              Header: 'N.º da Venda',
              accessor: 'code',
              width: 110,
            },
            {
              Header: 'Cliente',
              accessor: 'customer',
              width: 220,
            },
            {
              Header: 'Placa',
              accessor: 'licensePlate',
              width: 80,
            },
            {
              Header: 'Tipo',
              accessor: 'type',
              width: 40,
              Cell: (props) => (props.value === 'Produto' ? 'P' : 'S'),
            },
            {
              Header: 'Item',
              accessor: 'description',
              width: 220,
            },
            {
              Header: 'Valor',
              accessor: 'unitValue',
              width: 80,
              Cell: (props) => currency(props.value),
            },
            {
              Header: 'Revisão - Data',
              accessor: 'formatedRevisionDate',
              width: 150,
            },
            {
              Header: 'Revisão - KM',
              accessor: 'revisionKilometrage',
              width: 100,
            },
          ]}
          defaultPageSize={10}
          sortable={false}
          loading={loading}
          pageSizeOptions={[5, 10, 20, 25]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText={noDataText}
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
        <div>
          <p
            style={{
              textAlign: 'end',
              margin: '10px',
              marginRight: '3rem',
            }}
          >
            Total de Revisões: {filteredReport.length}
          </p>
        </div>
      </main>
    </>
  );
};

export default ReturnForecast;
