import React from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';

export const BankConciliationTable = ({ dataOfx, columnsConfig,page,setPage,limit,setLimit , loading, totalPages, isFinancialRegister = false,}) => {


  const StyledTable = styled(ReactTable)`

    margin-top: 1rem;
    width: 100%;
    max-width: 1043px;

    .rt-table{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rt-tr {
      align-items: unset;
    }
    .rt-td {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rt-table {
      border-collapse: collapse;
      width: 100%;
      align-items: center;
    }

    /* Estilo para as células do cabeçalho */
    .rt-thead .rt-th {
      border: 1px solid #ddd;
      text-align: center;
    }

    /* Estilo para as células do corpo */
    .rt-tbody .rt-td {
      border: 1px solid #ddd;
      text-align: center;
      height: 40px;
    }

    /* Remover a borda direita da última célula de cada linha */
    .rt-thead .rt-th:last-child,
    .rt-tbody .rt-td:last-child {
      border-right: none;
    }

    /* Remover a borda inferior da última linha */
    .rt-tbody .rt-tr:last-child .rt-td {
      border-bottom: none;
    }
  `;

  return isFinancialRegister ? (
    <StyledTable
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
      }}
      data={dataOfx}
      columns={columnsConfig}
      defaultPageSize={10}
      page={page}
      onPageChange={setPage}
      pageSize={limit}
      onPageSizeChange={(value) => {
        setLimit(value);
      }}
      showPagination
      loading={loading}

      pages={totalPages}
      manual
      sortable
      showPaginationTop={false}
      showPaginationBottom
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Não há informação"
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  ) : (
    <StyledTable
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        }}
      pages={totalPages}
      data={dataOfx}
      columns={columnsConfig}
      defaultPageSize={10}
      showPagination
      sortable
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Nenhum dado disponível"
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  );
}
