import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';

import CustomButton from 'client/components/CustomButton/CustomButton';
import { Modal } from 'react-bootstrap';

import MaintenanceRepository from 'repositories/Maintenance';
import SalesRepository from 'repositories/Sales';

import '../index.css';
import { useWorkshopPanel } from 'contexts/workshopPanel';

const FinishServiceModal = ({ maintenance, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const { setReloadPanelInformation } = useWorkshopPanel();

  const handleFinishService = async () => {
    setLoading(true);
    try {
      await MaintenanceRepository.updateMaintenance(maintenance?.id, {
        status: 'Finalizado',
      });

      await SalesRepository.updateShowOnPanelStatus(maintenance.saleId, false);
      toastr.success('Serviço finalizado com sucesso!');
      setLoading(false);
      setReloadPanelInformation(true);
      onCancel();
    } catch (err) {
      console.log(err);
      setLoading(false);
      toastr.error(
        'Erro ao finalizar o serviço',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    }
  };

  return (
    <>
      <Modal show={true} onHide={!loading ? onCancel : null}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Painel da Oficina</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Você tem certeza de que deseja finalizar o serviço?</strong>
          <p style={{ fontSize: '14px', marginTop: '20px' }}>
            O processo é irreversível, uma vez finalizado, não poderá ser
            desfeito. Ao finalizar, todos os mecânicos apontados serão liberados
            para serem usados em outros serviços
          </p>

          <p
            style={{
              marginTop: '20px',
              fontSize: '11px',
              color: 'red',
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            O apontamento será lançado automaticamente, sendo necessário
            realizar <br /> a inclusão das horas trabalhadas por serviço.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              bsStyle="danger"
              name="voltar"
              onClick={onCancel}
              disabled={loading}
              fill
            >
              Voltar
            </CustomButton>
            <CustomButton
              bsStyle="info"
              name="stop-service"
              onClick={handleFinishService}
              disabled={loading}
              fill
            >
              {loading && (
                <span
                  className={
                    loading && 'fa fa-spinner fa-pulse fa-1x update-spinner'
                  }
                />
              )}
              Finalizar Serviço
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FinishServiceModal;
