import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Produtos/Serviços',
      'Conversor de CST para CSOSN',
    ],
    path: [
      '/client/home',
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.CST_CONVERSOR.BASE,
    ],
  };
};

export const optionsIsActiveSelect = [
  {
    label: 'Ambos',
    value: '',
  },
  {
    label: 'Ativo',
    value: true,
  },
  {
    label: 'Inativo',
    value: false,
  },
];
