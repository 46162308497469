import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import PartsRequisitionMain from './components/PartsRequisitionMain'

import './styles.css'

const PartsRequisition = ({ history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Requisição de Peças']}
            path={['home', 'products', null]}
          />
          <PartsRequisitionMain history={history} />
        </Row>
      </Grid>
    </div>
  )
}

export default PartsRequisition
