import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import SuperlogicaClientArea from '../../components/SuperlogicaClientArea'

const Users = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Minha Área Financeira']}
            path={['home', null]}
          />
          <SuperlogicaClientArea />
        </Row>
      </Grid>
    </div>
  )
}

export default Users
