import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import CashClosingReportDocument from './CashClosingReportDocument';
import { AppLoading } from 'client/routes/index.routes';
import cashierBankHistoricRepository from '../../../repositories/CashierBanksHistoric';
import companyRepository from '../../../repositories/Companies';
import { toastr } from 'react-redux-toastr';
import AppError from 'components/AppError';
import { useAuth } from 'contexts/auth';
import './styles.css';

const CashClosingReport = ({ location, history }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [receivedByFormOfPayment, setReceivedByFormOfPayment] = useState([]);
  const [cashierConferencePayments, setCashierConferencePayments] = useState(
    []
  );
  const [dailyResume, setDailyResume] = useState(null);
  const [cashierBankHistoric, setCashierBankHistoric] = useState(null);
  const [hasCashierConference, setHasCashierConference] = useState(false);

  const { companyId } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cashierBankHistoricId = searchParams.get('cashierBankHistoricId');

    if (!cashierBankHistoricId) {
      history.push('/');
    } else {
      getCloseCashierReportByCashierBankHistoric(cashierBankHistoricId);
    }
  }, [location.search, history]);

  useEffect(() => {
    if (!!companyId) {
      loadCompanyInfo(companyId);
    }
  }, [companyId]);

  async function loadCompanyInfo(companyId) {
    const data = await companyRepository.getById(companyId);

    setHasCashierConference(data.cashierConference);
  }

  async function getCloseCashierReportByCashierBankHistoric(
    cashierBankHistoricId
  ) {
    try {
      const closeCashierReport =
        await cashierBankHistoricRepository.getCloseCashierReportByCashierBankHistoric(
          cashierBankHistoricId
        );

      setCashierBankHistoric(closeCashierReport.cashierBankHistoric);
      setReceivedByFormOfPayment(closeCashierReport.receivedByFormOfPayment);
      setCashierConferencePayments(
        closeCashierReport.cashierConferencePayments
      );
      setDailyResume(closeCashierReport.resume);
    } catch (err) {
      setHasError(true);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar o relatório. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o relatório. Por favor, tente novamente" />
    );

  return (
    <div id="cash-closing-report-page">
      <main>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <CashClosingReportDocument
            cashierBankHistoric={cashierBankHistoric}
            dailyResume={dailyResume}
            receivedByFormOfPayment={receivedByFormOfPayment}
            cashierConferencePayments={cashierConferencePayments}
            hasCashierConference={hasCashierConference}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default CashClosingReport;
