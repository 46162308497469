import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import './styles.css'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import { HomeCanceledBudgets } from './HomeCanceledBudgets'

const SalesRanking = () => {
  localStorage.setItem('selectedPath', 'canceled-budgets-report')
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Listagem de Orçamentos Cancelados',
            ]}
            path={['home', null, null, null]}
          />
          <HomeCanceledBudgets />
        </Row>
      </Grid>
    </div>
  )
}
export default SalesRanking
