import React from 'react'

import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Button from '../../../../components/CustomButton/CustomButton'

import './index.css'

export default function BankSlipConfirmationModal({ onSubmit, show, setShow }) {

  const dispatch = useDispatch();

  async function handleSubmit(value) {
    dispatch({ type: 'EMIT_BANK_SLIP', userResponse: value })
    setShow(false)
    onSubmit(value, true)
  }

  return (
    <div>
      <Modal show={show} animation>
        <Modal.Header>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <strong>
            Existem 1 ou mais boletos a serem gerados
          </strong>
          
          <br />
          <br />
                  
          Você pode gerar os boletos agora ou pode gerar mais tarde através do
          contas a receber.
          
          <br />

          Deseja gerar os boletos após finalizar a venda?

          <div className="bank-slip-action-buttons">
            <Button bsStyle="primary" name="Voltar" onClick={() => handleSubmit(true)} fill>
              Finalizar e gerar boletos <u>agora</u> 
            </Button>
            <Button bsStyle="info" name="Voltar" onClick={() => handleSubmit(false)} fill>
              Finalizar e não gerar boletos
            </Button>
          </div>

          <div
            style={{
              color: 'red',
              marginTop: '10px',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            Os boletos gerados são encaminhados para o e-mail de cada cliente
          </div>
         
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </div>
  )
}