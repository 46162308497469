import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { initialize } from 'redux-form'
import { useAuth } from '../../../../../contexts/auth'
import './styles.css'

import { format } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import { decrypt, phoneMask, onlyNumbers } from '../../../../components/ToNormalize/ToNormalize'

import FormCashierBank from './FormCashierBank'
import cashierBankRepository from '../../../../../repositories/CashierBank'

const BundleFormCashierBank = ({ cashierBankId, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false)

  const {
    companyId
  } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    if (cashierBankId) loadCashierBank()
  }, [])

  const readHash = async hash => {
    const hashDecrypted = await decrypt(hash, '@OS-dig:CashierBankId')
    return hashDecrypted
  }

  const loadCashierBank = async () => {
    const idDecrypted = await readHash(cashierBankId)
    try {
      const cashierBank = await cashierBankRepository.getCashierBanksById(idDecrypted)
      const data = {
        ...cashierBank,
        cashierBankTypeId: String(cashierBank.cashierBankTypeId),
        createdAt: format(new Date(new Date(cashierBank.createdAt).getTime()), 'dd/MM/yyyy'),
        lastMovementAt: format(new Date(new Date(cashierBank.lastMovementAt).getTime()), 'dd/MM/yyyy HH:mm'),
        phoneNumber: phoneMask(cashierBank.phoneNumber),
        id: idDecrypted,
      }
      dispatch(initialize('formCashierBank', data))
    } catch (err) {
      console.error(err)
    }
  }

  const submit = async values => {
    if (await !validations(values)) return toastr.warning('Preencha todos os campos obrigatórios para salvar o cadastro')

    !!values.id ?
    update(values)
    : 
    create(values)
  }

  const validations = values => {
    const {
      cashierBankTypeId,
      description,
      bankId,
      agency,
      checkingAccount
    } = values

    if (
      !cashierBankTypeId ||
      !description
    ) return false

    if (cashierBankTypeId === '1') {
      if (
        !bankId ||
        !agency ||
        !checkingAccount
      ) return false
    }

    return true
  }

  const create = async values => {
    setLoading(true)
    try {
      await cashierBankRepository.store({
        ...values,
        cashierBankTypeId: Number(values.cashierBankTypeId),
        createdAt: new Date().getTime(),
        phoneNumber: onlyNumbers(values.phoneNumber || ''),
        companyId
      })

      toastr.success(`${values.cashierBankTypeId === '1' ? 'Banco' : 'Caixa Interno'} criado com sucesso.`)
      return onSubmit()
    } catch (err) {
      console.log(err)
      if (err.response?.data?.message) {
        return toastr.warning(err.response.data.message)
      } else {
        return toastr.error('Ocorreu algum problema', 'Por favor, tente mais tarde')
      }
    } finally {
      setLoading(false)
    }
  }

  const update = async values => {
    setLoading(true)
    try {
      await cashierBankRepository.update({
        ...values,
        id: Number(values.id),
        cashierBankTypeId: Number(values.cashierBankTypeId),
        createdAt: new Date().getTime(),
        phoneNumber: onlyNumbers(values.phoneNumber || ''),
        bankId: values.bankId || null,
        agency: values.agency || '',
        checkingAccount: values.checkingAccount || '',
        manager: values.manager || '',
        companyId
      })

      toastr.success(`${values.cashierBankTypeId === '1' ? 'Banco' : 'Caixa Interno'} atualizado com sucesso.`)
      return onSubmit()
    } catch (err) {
      if (err.response?.data?.message) {
        return toastr.warning(err.response.data.message)
      } else {
        return toastr.error('Ocorreu algum problema', 'Por favor, tente mais tarde')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormCashierBank
      onSubmit={submit}
      initialValues={{
        createdAt: format(new Date(new Date().getTime()), 'dd/MM/yyyy'),
        cashierBankTypeId: "1",
        isActive: true
      }}
      loading={loading}
      onCancel={onCancel}
    />
  )
}

export default BundleFormCashierBank