import api from '../services/api'

const getAll = async (companyId, params) => {
  const { data } = await api.get(`financial-statement/report/${companyId}`, {
    params,
  })
  return data
}

const incomeStatementRepository = {
  getAll,
}

export default incomeStatementRepository
