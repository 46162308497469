const { default: styled } = require('styled-components');

export const ModalTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const FinishedSalesAdvice = styled.span`
  color: #d9534f;
  font-size: 12px;
  padding-right: 10px;
`;

export const StepWizardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const StepWizardWrapper = styled.div`
  width: 40%;
`;

export const TitleStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  padding-top: 5rem;
`;
