import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '35px')};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};
  padding: 0 ${(props) => props.theme.space[2]};
  background: ${(props) => (props.background ? `${props.background}` : '')};

  &:has(input:focus) {
    outline: 1px solid ${(props) => props.theme.colors.branding1000};
  }
`;

export const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 80% !important;
  border: none;
  outline: none;
  border-radius: ${(props) => props.theme.radii.xs};
  margin-left: ${(props) =>
    props.margin ? `${props.margin}px` : props.theme.space[2]};
`;
