import React from 'react'
import { Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'

const StoneCard = ({
  link,
  title,
  value,
  icon,
  color,
  fontColor,
  pendingAmount,
  onClick,
}) => {
  return (
    <Link to={link} id="card-dashboard">
      <Button onClick={onClick}>
        <main>
          <p style={{ color: color }}>{title}</p>
          <h1>
            <strong style={{ color: fontColor }}>{value}</strong>
          </h1>
          <div
            style={{
              backgroundColor: color,
              borderRadius: '800px',
              height: '40px',
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              className="iconedashboard"
              aria-hidden="false"
            />
          </div>
          {pendingAmount ? (
            <span style={{ fontSize: '10px' }}>
              Existe o valor de{' '}
              <strong style={{ color: fontColor }}> {pendingAmount} </strong>{' '}
              pendente de baixa
            </span>
          ) : (
            <div />
          )}
        </main>
      </Button>
    </Link>
  )
}

export default StoneCard
