import React, { memo } from 'react';
import Chart from 'react-google-charts';

const GraphicCustomerApproach = ({ data }) => {
  return (
    <>
      <div className="chart-pagamento" style={{ opacity: '100%' }}>
        <Chart
          width={'400px'}
          height={'300px'}
          chartType="PieChart"
          data={[
            ['Abordagem', 'Quantidade', { role: 'tooltip', type: 'string' }],
            ...data,
          ]}
          chartLanguage="pt-br"
          options={{
            chartArea: {
              width: '75%',
              left: 0,
            },
            legend: {
              width: 200,
            },
          }}
        />
      </div>
    </>
  );
};

export default memo(GraphicCustomerApproach);
