const xlsColumns = [
  {
    name: 'Codigo',
    acessor: 'code',
  },
  {
    name: 'Ref.Fabricante',
    acessor: 'manufacturerRef',
  },
  {
    name: 'Descrição',
    acessor: 'descriptionProduct',
  },
  {
    name: 'Familia',
    acessor: 'descriptionFamilies',
  },
  {
    name: 'Marca',
    acessor: 'descriptionBrands',
  },
  {
    name: 'Qtd',
    acessor: 'stockQuantity',
  },
  {
    name: 'Preço de Compra',
    acessor: 'purchasePrice',
  },
  {
    name: 'Margem',
    acessor: 'profitMargin',
  },
  {
    name: 'Preço de Venda',
    acessor: 'salePrice',
  },
]

export default xlsColumns;