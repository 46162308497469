import React from 'react';
import ReactInputMask from 'react-input-mask';

const LegalPerson = ({ setCompanyName, setNumberPhone, setContact }) => {
  return (
    <>
      <div className="card__new-client-row" style={{ marginTop: 15 }}>
        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Razão Social: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            type="text"
            style={{ width: '40rem' }}
            className="form-control foco-input"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Telefone Celular: <span style={{ color: 'red' }}>*</span>
          </span>
          <ReactInputMask
            mask={'(99) 99999-9999'}
            className="form-control"
            onChange={(e) => setNumberPhone(e.target.value)}
          />
        </div>

        <div className="card__new-client-column">
          <span className="card__new-client-span">
            Responsável: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            type="text"
            style={{ width: '15rem' }}
            className="form-control foco-input"
            onChange={(e) => setContact(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default LegalPerson;