import Button from 'client/components/CustomButton/Button'
import React from 'react'

const VehiclesTypes = ({
  selectedVehicleType,
  setSelectedVehicleType,
  segments,
  totalVehiclesTypes,
  setCurrentPage,
}) => {
  return (
    <>
      <div className="vehicles-table__select-vehicle-type">
        <div className="vehicles-table__select-vehicle-buttons">
          {segments.carMotobikesTrucks && (
            <Button
              style={{
                backgroundColor:
                  selectedVehicleType === 1 ? '#428bca' : '#2a6cd1',
                height: 35,
              }}
              onClick={() => {
                setSelectedVehicleType(1)
                setCurrentPage(0)
              }}
            >
              Carro/Moto/Pesados ({totalVehiclesTypes.carMotobikesTrucks})
            </Button>
          )}

          {segments.tractorsExcavators && (
            <Button
              style={{
                backgroundColor:
                  selectedVehicleType === 2 ? '#428bca' : '#2a6cd1',
                height: 35,
              }}
              onClick={() => {
                setSelectedVehicleType(2)
                setCurrentPage(0)
              }}
            >
              Tratores/Escavadeiras ({totalVehiclesTypes.tractorsExcavators})
            </Button>
          )}

          {segments.harvester && (
            <Button
              style={{
                backgroundColor:
                  selectedVehicleType === 3 ? '#428bca' : '#2a6cd1',
                height: 35,
              }}
              onClick={() => {
                setSelectedVehicleType(3)
                setCurrentPage(0)
              }}
            >
              Colheitadeira ({totalVehiclesTypes.harvesters})
            </Button>
          )}

          {segments.bike && (
            <Button
              style={{
                backgroundColor:
                  selectedVehicleType === 4 ? '#428bca' : '#2a6cd1',
                height: 35,
              }}
              onClick={() => {
                setSelectedVehicleType(4)
                setCurrentPage(0)
              }}
            >
              Bicicleta ({totalVehiclesTypes.bikes})
            </Button>
          )}

          <Button
            style={{
              backgroundColor:
                selectedVehicleType === 5 ? '#428bca' : '#2a6cd1',
              height: 35,
            }}
            onClick={() => {
              setSelectedVehicleType(5)
              setCurrentPage(0)
            }}
          >
            Outros Objetos ({totalVehiclesTypes.others})
          </Button>
        </div>
      </div>
    </>
  )
}

export default VehiclesTypes
