import api from '../services/api';

const getCardTaxesByPeriod = async ({
  companyId,
  initialDate,
  finalDate,
  selectedCardFlag,
}) => {
  try {
    const cardTaxes = await api.get(`report/${companyId}/taxes/card`, {
      params: {
        initialDate,
        finalDate,
        selectedCardFlag,
      },
    });

    return cardTaxes;
  } catch (err) {
    throw err;
  }
};

export default {
  getCardTaxesByPeriod,
};
