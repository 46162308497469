import React from 'react'

import SearchInput from 'components/SearchInput'

const ProductsFilterForm = ({
  brands,
  query,
  setQuery,
  type,
  setType,
}) => {
  return (
    <div className='products-filter-row'>
      <SearchInput
        placeholder='Pesquisa por Descrição, Código, Ref. Fabricante'
        value={query}
        style={{
          fontSize: 12,
          width: 350,
        }}
        containerStyle={{ margin: '0 10px' }}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Marca: </strong>
        </span>
        <select
          className='form-control foco-input'
          name='searchBrand'
          value={type}
          onChange={(e) => setType(e.target.value)}
          style={{ marginLeft: '10px' }}
        >
          <option value={null}>Sem Marca</option>
          <option value={''}>Todas</option>
          {brands.length !== 0
            ? brands.map((brand) => (
                <option value={brand.id} key={brand.id}>
                  {brand.description}
                </option>
              ))
            : ''}
        </select>
      </div>
    </div>
  )
}

export default ProductsFilterForm
