import React from 'react';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from 'components/SearchInput';
import { format } from 'date-fns';
import constants from 'utils/constants';
import DownloadXlsButton from 'components/DownloadXlsButton';

import '../styles.css';

const InventoryCountFilter = ({
  history,
  setStatus,
  setDateType,
  setInitialDate,
  setFinalDate,
  handleChangeQuery,
  inventoryCountList,
  actualDate,
  initialDate,
  finalDate,
  dataToExport,
  isLoadingExport,
  isExportReady,
  handleLoadDataToExport,
}) => {
  return (
    <div>
      <div
        style={{
          padding: 0,
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'flex-end',
          gap: '10px',
          marginTop: '10px',
        }}
      >
        <button
          className="btn btn-sucesso button-h35"
          onClick={() => history.push(constants.ROUTES.NEW_INVENTORY_COUNT)}
          style={{ fontSize: '12px' }}
        >
          + Novo Inventário/Contagem
        </button>
        <div>
          <SearchInput
            placeholder="Pesquisa por Descrição"
            onChange={(e) => handleChangeQuery(e.target.value)}
            background={'#FFFFFF'}
            style={{
              fontSize: '12px',
              height: '35px',
              width: '312px',
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px', fontWeight: 'bold' }}>Status:</span>
          <select
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="">Ambos</option>
            <option value="Em Aberto">Em Aberto</option>
            <option value="Finalizada">Finalizada</option>
          </select>
        </div>
        <div>
          <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
            Tipo de Data:
          </span>
          <select
            onChange={(e) => setDateType(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="createdAt">Criação</option>
            <option value="finishDate">Finalização</option>
          </select>
        </div>
        <div>
          <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
            Data Inicial:
          </span>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            max={actualDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
            Data Final:
          </span>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            max={actualDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </div>
        <div>
          {!isExportReady ? (
            <button
              className="btn button-h35"
              onClick={() => handleLoadDataToExport()}
              disabled={isLoadingExport}
              style={{
                backgroundColor: '#3c8af7',
                borderColor: '#3c8af7',
                color: '#FFFFFF',
                fontSize: '12px',
              }}
            >
              {isLoadingExport ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp;Exportar .xls
            </button>
          ) : (
            <DownloadXlsButton
              archiveName={`Inventário/Contagem - ${format(
                new Date(),
                'dd/MM/yyyy'
              )}`}
              data={dataToExport}
              className="btn btn-export "
              disabled={!dataToExport.length}
              columns={[
                {
                  name: 'Criação',
                  acessor: 'createdAt',
                },
                {
                  name: 'Descrição',
                  acessor: 'description',
                },
                {
                  name: 'Qtd Produtos',
                  acessor: 'qtdItems',
                },
                {
                  name: 'Data Finalização',
                  acessor: 'finishDate',
                },
                {
                  name: 'Status',
                  acessor: 'status',
                },
              ]}
              style={{
                backgroundColor: '#3c8af7',
                borderColor: '#3c8af7',
                color: '#FFFFFF',
                fontSize: '12px',
              }}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              &nbsp;Baixar .xls
            </DownloadXlsButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryCountFilter;
