import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { Field, reduxForm, change } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'

import { useAuth } from 'contexts/auth'

import settingsNCeRepository from 'repositories/SettingsNFCe'

import Select from '../../../../../client/views/Clients/NewClient/FormClient/components/Select'
import Input from '../../../../../client/views/Clients/NewClient/FormClient/components/Input'
import Button from '../../../../../client/components/CustomButton/CustomButton.jsx'
import {
  onlyNumbers,
  maxLength,
  dosentAllowSpaces,
} from '../../../../../client/components/ToNormalize/ToNormalize'

import ImportedSATCertificate from './ImportedSATCertificate'
import ModalMoreInfoSAT from './ModalMoreInfoSAT'

const FormSettingsNFCe = ({
  loading,
  handleSubmit,
  companyUF,
  fiscalDocumentType,
  setFiscalDocumentType,
}) => {
  const { settingsNFCeId, eNotasId } = useSelector(
    (state) => state.form.settingsNFCe.values
  )

  const { companyId } = useAuth()

  const dispatch = useDispatch()

  const [isSATInfoModalOpen, setIsSATInfoModalOpen] = useState(false)
  const [loadingSatSoftware, setLoadingSatSoftware] = useState(false)

  const handleChangeData = (event) => {
    const { value } = event.target
    setFiscalDocumentType(value)

    if (value === 'SAT') {
      dispatch(change('settingsNFCe', 'environment', 'Produção'))
    }
  }

  const handleUploadSATCerticate = (file) => {
    dispatch([change('settingsNFCe', 'satCertificate', file)])
  }

  const downloadSatSoftware = async () => {
    setLoadingSatSoftware(true)
    if (!eNotasId)
      toastr.warning('Você não possui o código de ativação cadastrado.')
    try {
      const { data: satSoftwareDownloadLink } =
        await settingsNCeRepository.getSatSoftwareDownloadLink(companyId)

      window.location.href = satSoftwareDownloadLink
    } catch (err) {
      console.error(err)
      return toastr.warning(
        'Ocorreu um erro ao baixar o software de comunicação'
      )
    } finally {
      setLoadingSatSoftware(false)
    }
  }

  return (
    <div className="company-tax-data-page">
      <Col lg={12} md={12} sm={12} xs={12}>
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Tipo de envio:</label>
          <Field
            name="fiscalDocumentType"
            component={Select}
            onChange={handleChangeData}
          >
            <option value="NFCe">NFC-e</option>
            {companyUF?.State === 'SP' && (
              <option value="SAT">SAT - Exclusivo p/ SP</option>
            )}
          </Field>
        </Col>
        {fiscalDocumentType === 'SAT' && (
          <Col lg={6} md={6} sm={6} xs={12}>
            <Button
              style={{
                background: '#1ea14e',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                border: '0',
                position: 'relative',
                right: '-5px',
                top: '38px',
              }}
              fill
              disabled={!settingsNFCeId || loadingSatSoftware || !eNotasId}
              onClick={downloadSatSoftware}
            >
              <FontAwesomeIcon cursor="pointer" icon={faDownload} />
              Baixar Software de Comunicação{' '}
              <span
                className={
                  loadingSatSoftware &&
                  'fa fa-spinner fa-pulse fa-1x update-spinner'
                }
              />
            </Button>
          </Col>
        )}
      </Col>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginBottom: fiscalDocumentType === 'SAT' ? '0px' : '70px',
        }}
      >
        <Col lg={2} md={2} sm={3} xs={12}>
          <label>Ambiente de Envio</label>
          <Field
            name="environment"
            component={Select}
            disabled={fiscalDocumentType === 'SAT'}
          >
            <option value="">Selecione</option>
            <option value="Homologação">Homologação</option>
            <option value="Produção">Produção</option>
          </Field>
        </Col>
        <Col lg={3} md={3} sm={3} xs={12} style={{ width: 'fit-content' }}>
          <Field
            label="Código de Identificação de CSC"
            name="CSCId"
            component={Input}
            normalize={onlyNumbers}
          />
        </Col>
        <Col lg={3} md={3} sm={1} xs={12} style={{ width: 'fit-content' }}>
          <Field
            label="CSC - Código de Segurança do Contribuinte"
            name="CSC"
            component={Input}
            normalize={(value) => value.trim()}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12} style={{ width: '155px' }}>
          <Field
            label="Última NFC-e Criada"
            name="lastNFCeSent"
            component={Input}
            normalize={(value) => maxLength(onlyNumbers(value), 10)}
          />
        </Col>
        <Col lg={1} md={1} sm={1} xs={12}>
          <Field
            label="Série"
            name="serie"
            component={Input}
            normalize={(value) => maxLength(onlyNumbers(value), 999)}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12} style={{ width: 'fit-content' }}>
          <label>Modelo de impressão</label>
          <Field name="printingTemplate" component={Select}>
            <option value="Bobina Térmica">Bobina Térmica</option>
            <option value="Folha A4">Folha A4</option>
          </Field>
        </Col>
      </Col>

      {fiscalDocumentType === 'SAT' && (
        <>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              marginBottom: '60px',
              alignItems: 'center',
            }}
          >
            <Col lg={3} md={3} sm={3} xs={12}>
              <label>Código de Ativação do SAT:</label>
              <Field
                name="satActivationCode"
                component={Input}
                normalize={dosentAllowSpaces}
              />
            </Col>

            <Col
              lg={6}
              md={6}
              sm={6}
              xs={12}
              style={{
                top: '20px',
              }}
            >
              <ImportedSATCertificate onUpload={handleUploadSATCerticate} />
            </Col>
          </Col>

          <Button
            style={{
              background: '#f0ad4e',
              borderColor: '#f0ad4e',
              marginRight: '108px',
            }}
            fill
            onClick={() => setIsSATInfoModalOpen(true)}
          >
            Mais informações sobre o SAT
          </Button>
        </>
      )}

      <Button bsStyle="info" fill onClick={handleSubmit} disabled={loading}>
        <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
        {!!settingsNFCeId ? 'Atualizar' : 'Salvar'}
      </Button>

      <ModalMoreInfoSAT
        show={isSATInfoModalOpen}
        onHide={() => setIsSATInfoModalOpen(false)}
      />
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'settingsNFCe',
})(FormSettingsNFCe)
