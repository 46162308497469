import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { faChartPie, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import advertisementsRepository from 'repositories/Advertisements';
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import './styles.css';

const AdvertisingActions = ({ id, redirectUrl, description }) => {
  const [isLoading, setLoading] = useState(false);
  const [buttonColor, setButtonColor] = useState('black');
  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    toastr.warning('Gerando relatório, por favor aguarde.');
    setLoading(true);
    try {
      const response = await advertisementsRepository.getReport(id);
      const advertisingReport = response.map(serializeReport);

      if (!advertisingReport.length) {
        setReportData([]);
        return toastr.warning('Sem dados disponíveis');
      }
      setReportData(advertisingReport);
      setButtonColor('green');
      return toastr.success(
        'Relatório gerado com sucesso.',
        'Clique novamente para baixar'
      );
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados do relatório');
      return console.log(err);
    } finally {
      setLoading(false);
    }
  };

  function serializeReport(report) {
    return {
      ...report,
      clickedAt: format(new Date(report.clickedAt), 'dd/MM/yyyy'),
      cpfCnpj:
        report?.cpfCnpj?.length <= 11
          ? cpfMask(report?.cpfCnpj || '00000000000')
          : cnpjMask(report?.cpfCnpj || '00000000000000'),
      companyName: report.companyName || report.userName,
      phone: phoneMask(report.userPhone),
      email: report.userEmail,
      segment: report.segment || '-',
      plan: report.planDescription || '-',
    };
  }

  const FetchDataIcon = () => {
    return (
      <span>
        <FontAwesomeIcon
          icon={faChartPie}
          cursor="pointer"
          onClick={fetchData}
          color={buttonColor}
        />
      </span>
    );
  };

  const LoadingState = () => (
    <span>
      <span className="fa fa-spinner fa-pulse fa-1x update-spinner" />
    </span>
  );

  const DownloadReportButton = ({ description }) => {
    if (isLoading) {
      return (
        <>
          <LoadingState isLoading={isLoading} />
        </>
      );
    }
    if (!reportData.length || buttonColor === 'black') {
      return (
        <>
          <FetchDataIcon />
        </>
      );
    }
    return (
      <>
        <DownloadXlsButton
          style={{ display: 'contents' }}
          archiveName={`${description} - ${format(
            new Date(),
            'dd/MM/aaaa HH:mm'
          )}`}
          onClick={() => setButtonColor('black')}
          data={reportData}
          columns={[
            {
              name: 'Data do Clique',
              acessor: 'clickedAt',
            },
            {
              name: 'CNPJ',
              acessor: 'cpfCnpj',
            },
            {
              name: 'Razão Social',
              acessor: 'companyName',
            },
            {
              name: 'Plano',
              acessor: 'plan',
            },
            {
              name: 'Segmento',
              acessor: 'segment',
            },
            {
              name: 'Telefone',
              acessor: 'phone',
            },
            {
              name: 'E-mail',
              acessor: 'email',
            },
          ]}
        >
          <FontAwesomeIcon
            icon={faChartPie}
            cursor="pointer"
            color={buttonColor}
          />
        </DownloadXlsButton>
      </>
    );
  };

  return (
    <div className="table-actions-advertising">
      <Link
        to={{
          pathname: `advertising#${id}`,
        }}
      >
        <FontAwesomeIcon cursor="pointer" icon={faEdit} color="black" />
      </Link>
      {redirectUrl && (
        <>
          <DownloadReportButton description={description} />
        </>
      )}
    </div>
  );
};

export default AdvertisingActions;
