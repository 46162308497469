import React, { useState, useEffect } from 'react'
import CardForm from 'client/components/CardForm'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { Card } from 'components/Card/Card.jsx'
import { Col } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { useAuth } from '../../../../contexts/auth'
import {
  onlyNumbersTraceDot,
  onlyNumbersLetters,
  onlyNumbers,
} from '../../../components/ToNormalize/ToNormalize'
import SATManufacturersRepository from '../../../../repositories/SATManufacturers'
import SATsRepository from '../../../../repositories/SATs'
import { toastr } from 'react-redux-toastr'

import AlertModal from 'components/AlertModal/AlertModal'

const FormSAT = ({ id, submit }) => {
  const { companyId } = useAuth()
  const [manufacturers, setManufacturers] = useState([]) // fabricantes

  const [manufacturer, setManufacturer] = useState(0)
  const [equipmentSerie, setEquipmentSerie] = useState('')
  const [activationCode, setActivationCode] = useState('')
  const [serie, setSerie] = useState('')
  const [ACSignature, setACSignature] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [version, setVersion] = useState('0.08')
  const [loading, setLoading] = useState(false)
  const [cancel, setCancel] = useState(false)

  useEffect(() => {
    if (companyId) {
      loadManufacturers()
    }
    if (id) {
      loadSat()
    }
  }, [])

  const loadSat = async () => {
    try {
      const sat = await SATsRepository.getById(id)
      setManufacturer(sat.manufacturerId)
      setEquipmentSerie(sat.equipmentSerie)
      setActivationCode(sat.activationCode)
      setSerie(sat.serie)
      setVersion(sat.version)
      setACSignature(sat.ACSignature)
      setIsActive(sat.isActive)
    } catch (err) {
      console.log(err)
      console.log('Ocorreu um erro ao carregar sat')
    }
  }

  async function loadManufacturers() {
    try {
      const resp = await SATManufacturersRepository.getAllByCompany(companyId)
      setManufacturers(resp)
    } catch (err) {
      console.log(err)
      console.log('Ocorreu erro ao carregar os fornce')
    }
  }

  const openCancelModal = async () => {
    if (
      manufacturer ||
      equipmentSerie ||
      activationCode ||
      serie ||
      ACSignature ||
      version
    ) {
      await setCancel(true)
    } else {
      submit()
    }
  }

  const handleSubmit = async () => {
    setLoading(true)

    if (!manufacturer || !equipmentSerie || !activationCode || !serie) {
      setLoading(false)
      return toastr.warning('Preencha todos os campos para salvar o cadastro')
    }

    if (!id) {
      create()
    } else {
      update()
    }
    setLoading(false)
  }

  const create = async () => {
    const satRegistered =
      await SATsRepository.getByManufacturerIdAndEquipmentSerie(
        companyId,
        manufacturer,
        equipmentSerie
      )
    if (satRegistered.length) {
      setLoading(false)
      return toastr.warning(
        'Já existe um cadastro de SAT desse Fabricante com esse Nº de Série do Equipamento.'
      )
    }

    const sat = {
      manufacturerId: manufacturer,
      companyId,
      equipmentSerie,
      activationCode,
      version,
      serie,
      ACSignature,
      isActive,
    }
    try {
      await SATsRepository.create(sat)
      toastr.success('SAT salvo com sucesso')
      submit()
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao salvar SAT')
    }
  }

  const update = async () => {
    const satRegistered =
      await SATsRepository.getByManufacturerIdAndEquipmentSerie(
        companyId,
        manufacturer,
        equipmentSerie
      )
    for (let i = 0; i < satRegistered.length; i++) {
      if (satRegistered[i].id === id) {
        setLoading(false)
        return toastr.warning(
          'Já existe um cadastro de SAT desse Fabricante com esse Nº de Série do Equipamento.'
        )
      }
    }
    const sat = {
      companyId,
      activationCode,
      version,
      serie,
      ACSignature,
      isActive,
    }
    try {
      await SATsRepository.update(id, sat)
      toastr.success('SAT atualizado com sucesso')
      submit()
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao atualizar SAT')
    }
  }

  return (
    <div
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm show={true} title='Dados do SAT' onClick={() => {}}>
        <Card
          content={
            <form
              id='form-new-cfop'
              className='col-xs-12 col-sm-12 col-md-12 col-lg-12 row'
            >
              <Col xs={12} sm={12} md={3} lg={2}>
                <label htmlFor='manufacturer'>
                  {' '}
                  Fabricante: <span> * </span>
                </label>
                <select
                  className='form-control foco-input'
                  name='searchStatus'
                  value={manufacturer}
                  disabled={id}
                  onChange={(e) => setManufacturer(e.target.value)}
                >
                  <option value='0'> Selecione </option>{' '}
                  {manufacturers.map((m) => (
                    <option key={m.id} value={m.id}>
                      {' '}
                      {m.description}{' '}
                    </option>
                  ))}{' '}
                </select>{' '}
              </Col>
              <Col xs={12} sm={12} md={4} lg={3}>
                <label htmlFor='equipmentSerie'>
                  {' '}
                  Nº de Série do Equipamento: <span> * </span>
                </label>
                <input
                  className='form-control foco-input'
                  maxLength='50'
                  value={equipmentSerie}
                  onChange={(e) =>
                    setEquipmentSerie(onlyNumbersTraceDot(e.target.value))
                  }
                  disabled={id}
                  type='text'
                  id='equipmentSerie'
                />
              </Col>
              <Col xs={12} sm={12} md={3} lg={2}>
                <label htmlFor='description'>
                  {' '}
                  Código de Ativação: <span> * </span>
                </label>
                <input
                  className='form-control foco-input'
                  maxLength='50'
                  value={activationCode}
                  onChange={(e) =>
                    setActivationCode(onlyNumbersLetters(e.target.value))
                  }
                  type='text'
                  id='description'
                />
              </Col>{' '}
              <Col xs={12} sm={12} md={2} lg={2}>
                <label htmlFor='description'> Versão: </label>
                <select
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  className='form-control foco-input'
                >
                  <option value='0.08'> 0.08 </option>
                  <option value='0.07'> 0.07 </option>
                  <option value='0.06'> 0.06 </option>
                </select>{' '}
              </Col>
              <Col xs={12} sm={12} md={2} lg={2}>
                <label htmlFor='description'>
                  {' '}
                  Série de Emissão: <span> * </span>
                </label>
                <input
                  className='form-control foco-input'
                  maxLength='50'
                  value={serie}
                  onChange={(e) => setSerie(onlyNumbers(e.target.value))}
                  type='text'
                  id='description'
                />
              </Col>
              <Col lg={1} md={2} sm={1} xs={12}>
                <label> Status </label>
                <div id='toggle-status'>
                  <Toggle
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    name='isActive'
                  />{' '}
                </div>{' '}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <label htmlFor='text'> Assinatura AC: </label>{' '}
                <textarea
                  className='form-control foco-input'
                  id='text'
                  value={ACSignature}
                  onChange={(e) => setACSignature(e.target.value)}
                  maxLength='255'
                />
              </Col>
            </form>
          }
        />
      </CardForm>
      <FormSubmitButtons
        onCancel={() => openCancelModal()}
        hasId={!!id}
        loading={loading}
        onSubmit={handleSubmit}
      />

      <AlertModal
        show={cancel}
        title='Cadastro de SAT'
        message='Deseja cancelar o cadastro atual? Poderá efetuar o cadastro posteriormente?'
        onCancel={() => setCancel(false)}
        onSubmit={() => submit()}
        onHide={() => setCancel(false)}
      />
    </div>
  )
}

export default FormSAT
