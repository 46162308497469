import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import salesReturnsRepository from '../../../repositories/SalesReturns'

import AppError from 'components/AppError'
import { AppLoading } from 'client/routes/index.routes'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import { SaleReturnDocument } from './SaleReturnsDocument'

export const SaleReturnReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [report, setReport] = useState({})

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const saleIdHash = searchParams.get('saleId')
      let saleId
      if (saleIdHash) {
        saleId = await decrypt(saleIdHash, '@OS-dig:saleId')
      }

      if (!saleId) {
        setHasError(true)
        setLoading(false)
      }

      return loadReport(saleId)
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async saleId => {
    try {
      const { data } = await salesReturnsRepository.reportOneBySale(saleId)
      setReport(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />
  return (
    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <SaleReturnDocument {...report} />
        </PDFViewer>
      </main>
    </div>
  )
}