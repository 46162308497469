import React from 'react';
import Toggle from 'react-toggle';
import { format } from 'date-fns';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BrandingLabel } from 'v2/components/BrandingLabel';

const NewsTable = ({ loading, news, handleUpdateStatus }) => {
  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          maxWidth: '1200px',
        }}
        data={news}
        columns={[
          {
            Header: 'Dt. Criação',
            accessor: 'createdAt',
            width: 150,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 400,
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => {
              return (
                <div className="flex center">
                  <Toggle
                    style={{ position: 'fixed' }}
                    checked={props.value}
                    onChange={() =>
                      handleUpdateStatus(props.original.id, !props.value)
                    }
                  />
                </div>
              );
            },
            width: 150,
          },
          {
            Header: 'Produto',
            accessor: 'brandingId',
            width: 90,
            Cell: (props) => (
              <div className="flex center h-100">
                <BrandingLabel
                  brandingId={props.value}
                  noBrandingText="Ambos"
                />
              </div>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 90,
            Cell: (props) => (
              <Link to={{ pathname: `news-update#${props.value}` }}>
                <FontAwesomeIcon
                  title="Editar notícia"
                  cursor="pointer"
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </Link>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        defaultSorted={[{ id: 'status', desc: true }]}
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma notícia encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default NewsTable;
