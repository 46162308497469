import React from 'react';
import { InputTextAreaStyled, InputWrapper } from './styles';

export const InputTextArea = React.forwardRef(
  ({ width, height = 40, ...props }, ref) => {
    return (
      <InputWrapper width={width} height={height}>
        <InputTextAreaStyled type={'text'} {...props} ref={ref} />
      </InputWrapper>
    );
  }
);

InputTextArea.displayName = 'InputTextArea';
