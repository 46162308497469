import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { isBefore } from 'date-fns';

import ClientsFilter from './ClientsFilter';
import ClientsTable from './ClientsTable';

import useDebounce from 'hooks/useDebounce';
import adminsRepository from 'repositories/Admins';
import signatureStatusRepository from 'repositories/SignatureStatus';
import { useAdminAuth } from 'contexts/adminAuth';
import { getbrandingNameById } from 'v2/helpers/brandingHelpers';
import { useBranding } from 'hooks/useBranding';
import { useMemo } from 'react';

export default function ClientsMain() {
  const [branding, setBranding] = useState({ label: 'Todos', value: '' });

  const [allBrandingPlans, setAllBrandingPlans] = useState([]);

  const serializedBrandingPlans = allBrandingPlans
    .filter((brandingPlan) =>
      branding.value ? brandingPlan.id === branding.value : true
    )
    .reduce((prev, curr) => [...prev, ...curr.Plans], [])
    .map((option) => ({
      value: option.id,
      label: option.description,
    }));

  const plansOptions = useMemo(
    () => [{ label: 'Todos', value: '*' }, ...serializedBrandingPlans],
    [allBrandingPlans, branding]
  );

  const [statusOptions, setStatusOptions] = useState([]);

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClients, setTotalClients] = useState(0);

  const [type, setType] = useState('ALL');

  const [signaturePeriod, setSignaturePeriod] = useState('');
  const [signatureStatusIds, setSignatureStatusIds] = useState([]);
  const statusIdsQuery = signatureStatusIds
    .filter((plan) => plan.value !== '*')
    .map((plan) => plan.value)
    .join(',');

  const [planIds, setPlanIds] = useState([]);
  const plansIdsQuery = planIds
    .filter((plan) => plan.value !== '*')
    .map((plan) => plan.value)
    .join(',');

  const [dateType, setDateType] = useState('createdAt');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const [isXlsExportReady, setIsXlsExportReady] = useState(false);
  const [isXlsExportLoading, setIsXlsExportLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);

  const [areParamsSetted, setAreParamsSetted] = useState(false);

  const { isRoleAdministrator } = useAdminAuth();
  const { loadPlansByBranding } = useBranding();

  function handleChangeQuery(value) {
    setQuery(value);
    debouncedSaveQuery(value);
  }

  function handleLocationSearch() {
    if (statusOptions.length && allBrandingPlans.length) {
      const params = new URLSearchParams(window.location.search);

      const statusParam = params.get('signatureStatusId');
      if (statusParam) {
        const statusToSelect = statusOptions.filter(
          (option) => Number(option.value) === Number(statusParam)
        );
        setSignatureStatusIds(statusToSelect);
      }

      const plansParam = params.get('plansId');
      if (plansParam) {
        const plansToSelect = plansOptions.filter((option) =>
          plansParam.includes(Number(option.value))
        );
        setPlanIds(plansToSelect);
      }

      const brandingParam = params.get('brandingsId');
      if (brandingParam) {
        if (Number(brandingParam) === 1) {
          setBranding({
            value: 1,
            label: 'OS Digital',
          });
        } else if (brandingParam && Number(brandingParam) === 2) {
          setBranding({
            value: 2,
            label: 'WorkMotor',
          });
        } else {
          setBranding({
            label: 'Todos',
            value: '',
          });
        }
      }

      const dateTypeParam = params.get('dateType');
      if (dateTypeParam) {
        setDateType(dateTypeParam);
      }

      const initialDateParam = params.get('initialDate');
      if (initialDateParam) {
        setInitialDate(initialDateParam);
      }

      const finalDateParam = params.get('finalDate');
      if (finalDateParam) {
        setFinalDate(finalDateParam);
      }
    }

    setAreParamsSetted(true);
  }

  async function getPlans() {
    const plans = await loadPlansByBranding();
    setAllBrandingPlans(plans);
  }

  async function loadClients() {
    setLoading(true);
    try {
      if ((initialDate && !finalDate) || (finalDate && !initialDate)) return;
      if (isBefore(finalDate, initialDate)) return;

      const paginatedClients = await adminsRepository.getAllClients({
        page: currentPage + 1,
        limit: pageLimit,
        type,
        signaturePeriod,
        signatureStatusId: statusIdsQuery,
        planId: plansIdsQuery,
        brandingId: branding.value,
        dateType,
        initialDate,
        finalDate,
        query: queryDebounced.replace("'", "''"),
      });

      setClients(paginatedClients.companies);
      setTotalPages(Math.ceil(paginatedClients.count / pageLimit));
      setTotalClients(paginatedClients.count);
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao carregar os clientes', 'Tente novamente!');
    } finally {
      setLoading(false);
    }
  }

  async function loadStatusOptions() {
    try {
      const signatureStatus = await signatureStatusRepository.getAll();
      let serializedOptions = signatureStatus.map((option) => ({
        label: option.Description,
        value: option.id,
      }));

      serializedOptions.unshift({ value: '*', label: 'Todos' });
      setStatusOptions(serializedOptions);
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao carregar as opções de status', 'Tente novamente!');
    }
  }

  async function loadDataToExport() {
    setIsXlsExportReady(false);
    setIsXlsExportLoading(true);
    toastr.warning(
      'Preparando Exportação',
      'Avisaremos quando estiver concluída. Por favor, aguarde.'
    );
    try {
      if ((initialDate && !finalDate) || (finalDate && !initialDate)) return;
      if (isBefore(finalDate, initialDate)) return;

      const allClients = await adminsRepository.getAllClients({
        page: 1,
        limit: totalClients,
        type,
        signaturePeriod,
        signatureStatusId: statusIdsQuery,
        planId: plansIdsQuery,
        brandingId: branding.value,
        dateType,
        initialDate,
        finalDate,
        query: queryDebounced.replace("'", "''"),
      });

      const serializedData = allClients.companies.map((company) => ({
        ...company,
        branding: getbrandingNameById(company.brandingId),
      }));

      setDataToExport(serializedData);
      setIsXlsExportReady(true);
      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os dados para exportação',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    } finally {
      setIsXlsExportLoading(false);
    }
  }

  useEffect(() => {
    loadStatusOptions();
  }, []);

  useEffect(() => {
    getPlans(branding.value);
    setPlanIds(plansOptions);
  }, []);

  useEffect(() => {
    handleLocationSearch();
  }, [statusOptions, allBrandingPlans]);

  useEffect(() => {
    if (areParamsSetted) {
      loadClients();
      setIsXlsExportReady(false);
    }
  }, [
    currentPage,
    pageLimit,
    type,
    signaturePeriod,
    signatureStatusIds,
    planIds,
    branding,
    initialDate,
    finalDate,
    dateType,
    queryDebounced,
    areParamsSetted,
  ]);

  return (
    <div id="admin-clients-page">
      <ClientsFilter
        plansOptions={plansOptions}
        statusOptions={statusOptions}
        type={type}
        setType={setType}
        signaturePeriod={signaturePeriod}
        setSignaturePeriod={setSignaturePeriod}
        signatureStatusIds={signatureStatusIds}
        setSignatureStatusIds={setSignatureStatusIds}
        planIds={planIds}
        setPlanIds={setPlanIds}
        dateType={dateType}
        setDateType={setDateType}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        query={query}
        handleChangeQuery={handleChangeQuery}
        isXlsExportReady={isXlsExportReady}
        loadDataToExport={loadDataToExport}
        isXlsExportLoading={isXlsExportLoading}
        dataToExport={dataToExport}
        branding={branding}
        setBranding={setBranding}
      />

      <ClientsTable
        clients={clients}
        loading={loading}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        totalClients={totalClients}
        loadClients={loadClients}
        isRoleAdministrator={isRoleAdministrator}
      />
    </div>
  );
}
