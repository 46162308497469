import React, { useState } from 'react';
import NewProviderModal from '../NewProviderModal';

import './index.css';

export default function NewProviderHyperlinkWithFieldLabel({
  label,
  fieldId,
  required,
  handleSelectCreatedProvider,
  onlyView,
  providerId,
}) {
  const [isNewProviderModalOpen, setIsNewProviderModalOpen] = useState(false);

  function handleOpenNewProviderModal(e) {
    e.preventDefault();
    setIsNewProviderModalOpen(true);
  }

  return (
    <>
      <div style={styles.wrapperDiv}>
        {label ? (
          <label for={fieldId} style={styles.label}>
            {label}:
            {required && <span style={styles.requiredIndicator}>*</span>}
          </label>
        ) : (
          <div />
        )}
        <a
          href="#"
          style={styles.hyperlink}
          onClick={(e) => handleOpenNewProviderModal(e)}
        >
          {onlyView ? 'Visualizar' : 'Adicionar'} Fornecedor
        </a>
      </div>

      {isNewProviderModalOpen && (
        <NewProviderModal
          handleSelectCreatedProvider={handleSelectCreatedProvider}
          handleClose={() => setIsNewProviderModalOpen(false)}
          onlyView={onlyView}
          providerId={providerId}
        />
      )}
    </>
  );
}

const styles = {
  wrapperDiv: {
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  requiredIndicator: {
    color: 'red',
  },
  hyperlink: {
    textDecoration: 'underline',
  },
};
