import { isBefore, addDays, isAfter, subDays } from 'date-fns';
import { useState } from 'react';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const useFilters = (filters) => {
  const [initialDate, setInitialDate] = useState(filters?.initialDate || '');
  const [finalDate, setFinalDate] = useState(filters?.finalDate || '');
  const [category, setCategory] = useState(filters?.category || '');
  const [status, setStatus] = useState(filters?.status || '');
  const [brandingId, setBranding] = useState(filters?.brandingId || '');
  const [query, setQuery] = useState(filters?.query || '');
  const [type, setType] = useState(filters?.type || '');
  const [multiplesOptions, setMultiplesOptions] = useState(
    filters?.multiplesOptions || []
  );
  const [vehicle, setVehicle] = useState(filters?.vehicle || '');
  const [accountPlanId, setAccountPlanId] = useState(
    filters?.accountPlanId || ''
  );
  const [cashierBankId, setCashierBankId] = useState(
    filters?.cashierBankId || ''
  );

  const filterByDate = (attribute, object) => {
    if (!initialDate && !finalDate) return true;

    if (!!initialDate && !!finalDate) {
      const initialDateToCompare = new Date(
        getDateOnlyFromDate(initialDate)
      ).setUTCHours(0, 0, 0);
      const finalDateToCompare = new Date(
        getDateOnlyFromDate(finalDate)
      ).setUTCHours(23, 59, 59);
      const date = new Date(getDateOnlyFromDate(object[attribute]));

      return (
        isAfter(date, initialDateToCompare) &&
        isBefore(date, finalDateToCompare)
      );
    }

    if (!!initialDate) {
      const dateToCompare = new Date(
        getDateOnlyFromDate(initialDate)
      ).setUTCHours(0, 0, 0);
      const date = new Date(getDateOnlyFromDate(object[attribute]));

      return isAfter(date, dateToCompare);
    }

    if (!!finalDate) {
      const dateToCompare = new Date(
        getDateOnlyFromDate(finalDate)
      ).setUTCHours(23, 59, 59);
      const date = new Date(getDateOnlyFromDate(object[attribute]));

      return isAfter(dateToCompare, date);
    }
  };

  const filterByQuery = (attributesToSearch) => {
    if (!query) {
      return true;
    }
    const arrayQuery = query.split(' ');
    let result = attributesToSearch;

    arrayQuery.forEach((word) => {
      result = result.filter((attribute) =>
        attribute?.toString().toLowerCase().includes(word.toLowerCase())
      );
    });
    return result.some((res) => res);
  };

  const filterByVehicle = (attributesToSearch) => {
    if (!vehicle) {
      return true;
    }
    const arrayVehicle = vehicle.split(' ');
    let result = attributesToSearch;

    arrayVehicle.forEach((word) => {
      result = result.filter((attribute) =>
        attribute?.toString().toLowerCase().includes(word.toLowerCase())
      );
    });
    return result.some((res) => res);
  };

  const filterByType = (attribute, object) => {
    if (!type) return true;

    return String(object[attribute]) === String(type);
  };

  const filterByStatus = (attribute, object) => {
    if (!status) return true;

    if (Array.isArray(status)) {
      return (
        String(status).includes(String(object[attribute])) &&
        status.find((option) => option === object[attribute])
      );
    }

    return String(object[attribute]) === String(status);
  };

  const filterByBranding = (attribute, object) => {
    if (!brandingId.value) return true;

    return String(object[attribute]) === String(brandingId.value);
  };

  const filterByCategory = (attribute, object) => {
    if (!category) return true;

    return String(object[attribute]) === String(category);
  };

  const filterByAccountPlan = (attribute, object) => {
    if (!accountPlanId) return true;

    return String(object[attribute]) === String(accountPlanId);
  };

  const filterByCashierBank = (attribute, object) => {
    if (!cashierBankId) return true;

    return String(object[attribute]) === String(cashierBankId);
  };

  const filterByMultiplesOptions = (attribute, object) => {
    if (!multiplesOptions.length) return true;
    return multiplesOptions.includes(String(object[attribute]));
  };

  return {
    dateFilter: {
      filterByDate,
      initialDate,
      setInitialDate,
      finalDate,
      setFinalDate,
    },

    queryFilter: {
      query,
      setQuery,
      filterByQuery,
    },

    vehicleFilter: {
      vehicle,
      setVehicle,
      filterByVehicle,
    },

    typeFilter: {
      type,
      setType,
      filterByType,
    },

    statusFilter: {
      status,
      setStatus,
      filterByStatus,
    },

    brandingFilter: {
      brandingId,
      setBranding,
      filterByBranding,
    },

    categoryFilter: {
      category,
      setCategory,
      filterByCategory,
    },

    accountPlanFilter: {
      accountPlanId,
      setAccountPlanId,
      filterByAccountPlan,
    },

    cashierBankFilter: {
      cashierBankId,
      setCashierBankId,
      filterByCashierBank,
    },

    multiplesOptionsFilter: {
      multiplesOptions,
      setMultiplesOptions,
      filterByMultiplesOptions,
    },
  };
};

export default useFilters;
