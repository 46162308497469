import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import { decrypt } from '../../../client/components/ToNormalize/ToNormalize';
import cashierBankRepository from '../../../repositories/CashierBank';

import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import CashierBankFlowReportDocument from './CashierBankFlowReportDocument';
import { toastr } from 'react-redux-toastr';
import './styles.css';

const CashierBankFlow = ({ location, history }) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [results, setResults] = useState([]);
  const [finalValues, setFinalValues] = useState([]);
  const [showSaleItems, setShowSaleItems] = useState(false);
  const [showSaleVehicles, setShowSaleVehicles] = useState(false);
  const [showTotalPaymentMethod, setShowTotalPaymentMethod] = useState(true);
  const [showPurchaseItems, setShowPurchaseItems] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cashierBanksHash = searchParams.get('cashierBanks');
    const initialDateParams = searchParams.get('initialDate');
    const finalDateParams = searchParams.get('finalDate');
    const showTotalPaymentMethod = searchParams.get('showTotalPaymentMethod');
    const showSaleItems = searchParams.get('showSaleItems');
    const showSaleVehicles = searchParams.get('showSaleVehicles');
    const showPurchaseItems = searchParams.get('showPurchaseItems');

    setShowTotalPaymentMethod(showTotalPaymentMethod);
    setShowSaleItems(showSaleItems);
    setShowSaleVehicles(showSaleVehicles);
    setShowPurchaseItems(showPurchaseItems);

    if (!cashierBanksHash || !initialDateParams || !finalDateParams) {
      setHasError(true);
      setLoading(false);
    } else {
      getCashierBankByDate(
        cashierBanksHash,
        initialDateParams,
        finalDateParams
      );
    }
  }, [location.search, history]);

  const getCashierBankByDate = async (
    cashierBanksHash,
    initialDateParams,
    finalDateParams
  ) => {
    try {
      const cashierBanks = await decrypt(
        cashierBanksHash,
        '@OS-dig:cashierBanks'
      );

      if (!cashierBanks) {
        console.log('if negativo');
        setHasError(true);
        setLoading(false);
        return;
      }

      const cashierBanksFlow = await cashierBankRepository.getCashierBankFlow(
        cashierBanks,
        initialDateParams,
        finalDateParams
      );

      const methods = cashierBanksFlow.results[0].paymentMethods.map(
        (method) => {
          return {
            id: method.id,
            desc: method.Description,
          };
        }
      );

      const formOfPayments = cashierBanksFlow.results[0].formOfPayments.map(
        (method) => {
          return {
            id: method.id,
            desc: method.Desciption,
          };
        }
      );

      const methodsVSflow = [];

      cashierBanksFlow.results.forEach((result, index) => {
        const items = formOfPayments
          .map((method) => {
            let qtd = 0;
            let totalAmount = 0;

            let parcelsWithThisMethod = [];

            const billsToReceive = result.resume.cashierBankFlow.filter(
              (item) => item.table === 'billsToReceive'
            );

            billsToReceive.forEach((bill) => {
              bill.parcels.forEach((parcel) => {
                if (parcel.formOfPaymentId === method.id) {
                  qtd += 1;
                  totalAmount += parcel.amount;
                  parcelsWithThisMethod.push(parcel);
                }
              });
            });

            if (qtd !== 0) {
              return {
                ...method,
                cashierBankId: result.cashierBank.id,
                qtd,
                total: totalAmount,
              };
            }
            return;
          })
          .filter((item) => item !== undefined);
        cashierBanksFlow.results[index].totalPerMethods = items;
      });

      setResults(cashierBanksFlow.results);
      setFinalValues(cashierBanksFlow.finalValues);
      setInitialDate(initialDateParams);
      setFinalDate(finalDateParams);
    } catch (err) {
      setHasError(true);
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao carregar o relatório. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o relatório. Por favor, tente novamente" />
    );

  return (
    <div id="cashier-bank-report-page">
      <main>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <CashierBankFlowReportDocument
            showSaleItems={showSaleItems}
            showSaleVehicles={showSaleVehicles}
            showPurchaseItems={showPurchaseItems}
            showTotalPaymentMethod={showTotalPaymentMethod}
            results={results}
            finalValues={finalValues}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default CashierBankFlow;
