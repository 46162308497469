import React, { useEffect, useState } from 'react'
import DownloadXlsButton from 'components/DownloadXlsButton'
import ProductsRepository from 'repositories/Products'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { xlsColumns } from './excelHelper'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import InventoryTable from './InventoryTable'

import '../index.css'

const InventoryMain = () => {
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState()
  const [negativeStock, setNegativeStock] = useState('0')
  const [zeroStock, setZeroStock] = useState('0')
  const [inactiveStock, setInactiveStock] = useState('0')

  const [query, setQuery] = useState('')

  const [productsTable, setProductsTable] = useState([])
  const [productsXLS, setProductsXLS] = useState([])
  const [totalValues, setTotalValues] = useState({})

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [totalProducts, setTotalProducts] = useState(0)

  const { companyId } = useAuth()

  const loadProductsToExport = async () => {
    if (!date) return
    setLoading(true)
    try {
      const params = {
        page: currentPage + 1,
        date,
        negativeStock,
        zeroStock,
        inactiveStock,
        query,
      }

      const paginatedProducts = await ProductsRepository.getInventoryReport(
        companyId,
        params
      )

      setProductsXLS(paginatedProducts.inventoryReport.productsData)
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Ocorreu um erro ao processar os dados.',
        'Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const loadProducts = async () => {
    if (!date) return
    setLoading(true)
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        date,
        negativeStock,
        zeroStock,
        inactiveStock,
        query,
      }

      const paginatedProducts = await ProductsRepository.getInventoryReport(
        companyId,
        params
      )

      setProductsTable(paginatedProducts.inventoryReport.productsData)
      setTotalValues(paginatedProducts.inventoryReport.totalValue[0])
      setTotalPages(Math.ceil(paginatedProducts.count / pageLimit))

      console.log('COUNT' + paginatedProducts.count)
      setTotalProducts(paginatedProducts.count)
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Ocorreu um erro ao processar os dados.',
        'Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = () => {
    if (!date) {
      return toastr.warning(
        'Não foi possível gerar o relatório.',
        'Preencha todos os campos e tente novamente.'
      )
    }
    loadProducts()
    loadProductsToExport()
  }

  useEffect(() => {
    loadProducts()
  }, [currentPage, pageLimit])

  return (
    <>
      <div className="inventory-report-main-div">
        {/* <div className='inventory-first-line'> */}
        {/* <div style={{ width: '40rem' }}>
            <i id='iconepesquisar' className='fa fa-search'></i>
            <input
              type='text'
              style={{ paddingLeft: '30px', width: '100%' }}
              className='form-control foco-input'
              placeholder='Pesquisa por Descrição ou Código'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div> */}
        {/* </div> */}

        <div className="inventory-second-line">
          <div>
            <span>
              <strong>Data do inventário:</strong>
            </span>
            <input
              type="date"
              className="form-control foco-input"
              onChange={(e) => setDate(e.target.value)}
            ></input>
          </div>

          <div>
            <span>
              <strong>Exibir estoque negativo:</strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setNegativeStock(e.target.value)}
            >
              <option value="0" selected>
                Não
              </option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div>
            <span>
              <strong>Exibir estoque zerado:</strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setZeroStock(e.target.value)}
            >
              <option value="0" selected>
                Não
              </option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div>
            <span>
              <strong>Exibir produto inativo:</strong>
            </span>
            <select
              className="form-control foco-input"
              onChange={(e) => setInactiveStock(e.target.value)}
            >
              <option value="0" selected>
                Não
              </option>
              <option value="1">Sim</option>
            </select>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              className="btn btn-sucesso"
              type="submit"
              onClick={() => handleSubmit()}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Processar
            </button>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <DownloadXlsButton
              archiveName={`Inventario-${date}`}
              data={productsXLS}
              className="btn btn-export"
              sortedBy="date"
              disabled={loading || !productsXLS.length}
              columns={xlsColumns}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </div>
        </div>
      </div>

      <InventoryTable
        products={productsTable}
        totalValues={totalValues}
        loading={loading}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  )
}

export default InventoryMain
