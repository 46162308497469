import api from '../services/api'
import config from '../config'

const getAllAccountPlanCategories = async () => {
  const categories = await api.get(config.endpoint + `account-plan-categories`)
  return categories.data
}

const getCategoryById = async categoryId => {
  const category = await api.get(config.endpoint + `account-plan-categories/${categoryId}`)
  return category.data
}

export default {
  getAllAccountPlanCategories,
  getCategoryById,
}
