import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, isAfter, isBefore } from 'date-fns';
import CollapsibleSection from 'components/CollapsibleSection';
import AlertModal from 'components/AlertModal/AlertModal';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import pollsRepository from '../../../../../repositories/Polls';
import { isEqual } from 'date-fns/esm';
import { toastr } from 'react-redux-toastr';
import { SelectBranding } from 'v2/components/Select';
import { Controller } from 'react-hook-form';

export default function NpsRegisterForm({
  control,
  pollsId,
  register,
  handleSubmit,
  onSubmit,
  initialDate,
  finalDate,
}) {
  const [saveModal, setSaveModal] = useState(false);

  const history = useHistory();

  const submitValidation = async (force) => {
    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      return toastr.warning(
        'Período inválido',
        'A data final tem de ser maior que a inicial'
      );
    }

    const isSelectedPeriodValid = await validateNPSPeriod();

    if (isSelectedPeriodValid || !!force) {
      return handleSubmit(onSubmit)();
    }

    return setSaveModal(true);
  };

  async function validateNPSPeriod() {
    try {
      const allNps = await pollsRepository.getAll();

      const anyNpsWithConflict = await allNps.find((item) => {
        const parsedItemInitialDate = new Date(item.initialDate);
        const parsedItemFinalDate = new Date(item.finalDate);

        const parsedInitialDate = new Date(initialDate);
        const parsedFinalDate = new Date(finalDate);

        if (
          (isBefore(parsedItemInitialDate, parsedFinalDate) ||
            isEqual(parsedItemInitialDate, parsedFinalDate)) &&
          (isAfter(parsedItemFinalDate, parsedInitialDate) ||
            isEqual(parsedItemFinalDate, parsedInitialDate))
        ) {
          return true;
        }

        return false;
      });

      return !!anyNpsWithConflict?.id ? false : true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  return (
    <div style={{ marginTop: 30 }}>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <CollapsibleSection title="Dados da Pesquisa" isExpanded={true}>
          <div>
            <div>
              <label htmlFor="brandingId">Produto:</label> &nbsp;
              <Controller
                name={'brandingId'}
                control={control}
                render={({ field }) => (
                  <SelectBranding {...field} id="brandingId" hideLabel />
                )}
              />
            </div>
          </div>
          <div className="nps-register-form">
            <div>
              <label>
                <strong>Tipo de Pesquisa:</strong>
              </label>
              <select
                className="form-control foco-input"
                {...register('typeId')}
                name="typeId"
                id="typeId"
              >
                <option value="1">NPS</option>
                <option value="2">NVS</option>
                <option value="3">MHV</option>
              </select>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 135,
              }}
            >
              <label htmlFor="initialDate">
                Data Inicial:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="form-control foco-input"
                type="date"
                min={format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')}
                {...register('initialDate')}
                name="initialDate"
                id="initialDate"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 135,
              }}
            >
              <label htmlFor="finalDate">
                Data Final:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="date"
                {...register('finalDate')}
                className="form-control foco-input"
                min={format(
                  new Date(getDateOnlyFromDate(initialDate)),
                  'yyyy-MM-dd'
                )}
                name="finalDate"
                id="finalDate"
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 700,
              }}
            >
              <label>
                Descrição:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                {...register('description')}
                className="form-control"
                name="description"
                id="description"
                maxLength="30"
              />
            </div>
          </div>
        </CollapsibleSection>

        <div style={{ marginTop: '10px' }}>
          <FormSubmitButtons
            onCancel={() => {
              history.push('/admin/polls');
            }}
            backLabel={pollsId}
            hasId={pollsId}
            onSubmit={() => submitValidation()}
          />
        </div>
        <AlertModal
          title="OS Digital"
          subtitle="Já existe uma pesquisa com esse periodo."
          show={saveModal}
          message="Existe uma pesquisa cadastrada com esse periodo, deseja cadastrar mesmo assim? Só poderá ativar uma pesquisa por vez."
          onCancel={() => setSaveModal(false)}
          onHide={() => setSaveModal(false)}
          onSubmit={() => submitValidation(true)}
          onCancelLabel="Voltar"
          onSubmitLabel="Cadastrar"
        />
      </form>
    </div>
  );
}
