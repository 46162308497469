import React from 'react'
import { phoneMask, cnpjMask, cpfMask, cepMask } from 'client/components/ToNormalize/ToNormalize'
import logOsDigital from '../../../assets/img/Logo_OS_Digital-12.jpg'
import './LoadNFCEStyles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { currency } from '../../../client/components/ToNormalize/ToNormalize';

const styles = {
    line: {
        border: '1px solid black'
    },

    saleInformation: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px'
    },
    resumoPedido: {
        background: '#f7f6f6',
        border: '#f0f3f6',
        padding: '20px 20px 50px 20px'
    },
    box: {
        padding: '15px',
        display: 'flex'
    },
    imgCompany: {
        width: '140px',
        height: '115px',
        marginLeft: '-10px',
        marginRight: '-15px',
    },
    headerFirstColumn: {
        paddingRight: '30px',
        width: '100%'
    },
    customerCardLeft: {
        padding: '10px',
        width: '100%'
    },
    customerCardRight: {
        padding: '10px',
        width: '28%',
        marginLeft: '2%'
    },
    cardSubTotal: {
        border: '1px solid black',
        padding: '10px',
        width: '78%'
    },
    cardTotal: {
        border: '1px solid black',
        padding: '10px',
        width: '20%',
        marginLeft: '2%'
    },
    garantia: {
        width: '33%',
        marginRight: '2%'
    },
    pagamento: {
        width: '65%'
    }
}

function licensePlateMask(value) {
    return value.substring(0, 3) + "-" + value.substring(3, 7)
}

const renderNFCE = props => (
    <div id="print" style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        {/* EMPRESA */}
        <div >

            <div style={{ ...styles.box, ...styles.header }}>
                <img style={styles.imgCompany} src={props.Company.Url_Logo ? props.Company.Url_Logo : logOsDigital} alt="OS Digital" />
            </div>
            <div style={{ fontSize: "18px", marginBottom: "20px" }}>
                <span><strong>Olá, {props.Customer.Company_Name}</strong></span>
            </div>
            {/* DADOS DA VENDA */}
            <div style={{ ...styles.saleInformation }}>
                <span style={{ fontSize: "18px" }}>Este é o cupom da compra que</span>
                <span style={{ fontSize: "18px" }}>você realizou no dia <strong>{props.DateSale}</strong></span>
                <span style={{ fontSize: "18px" }}>no valor de <strong>R$ {props.NFCe.subtotal.toFixed(2).replace('.', ',')}</strong>.</span>
            </div>

            {/* RESUMO PEDIDO */}
            <div style={{ ...styles.resumoPedido }}>
                <span style={{ fontSize: '16px' }}><strong>
                    <FontAwesomeIcon
                        cursor='not-allowed'
                        style={{ height: '1.5em', width: '1.5em', color: '#357ebd', marginRight: '7px' }}
                        icon={faShoppingBag}
                    />

            RESUMO DO PEDIDO</strong></span>
                <table className="table" style={{ width: "100%", textAlignLast: 'center', borderBottom: '1px #dddddd solid' }}>
                    <thead>
                        <tr style={{ fontSize: "13px", color: "#6a6f8d" }}>
                            <th style={{ width: "300px" }}>PRODUTO</th>
                            <th>QTD</th>
                            <th>VALOR</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.NFCeItems.map((items, index) => (
                            <tr key={index}>
                                <td style={{ maxWidth: '40px' }}>{items.description}</td>
                                <td>{items.quantity}</td>
                                <td>{items.unitValue.toFixed(2).replace('.', ',')}</td>
                                <td>{(items.unitValue * items.quantity).toFixed(2).replace('.', ',')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ fontSize: "15px", display: "flex", flexDirection: "row" }}>
                    <div
                        style={{
                            color: "#6a6f8d",
                            display: "flex",
                            width: '100%'
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: '100%',
                                minWidth: '250px'
                            }}
                        >
                            <span>< strong > Tributos aproximação R$</strong> </span>
                            {/*<span>#.###,## Federal e #.###,## Estadual </span> */}
                            <span>{props.NFCe.ibptValues}</span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: 'column',
                                width: '100px',
                                alignItems: 'center'
                            }}
                        >
                            <strong>Fonte</strong>
                            <span>IBPT</span>
                        </div>
                    </div >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'flex-end'
                        }}
                    >
                        <strong style={{ marginBottom: 5 }}>Subtotal</strong>
                        <strong style={{ marginBottom: 5 }}>Desconto</strong>
                        <strong style={{ fontSize: "20px", marginBottom: 5 }}>Valor Total</strong>
                        <strong>Forma de pagamento</strong>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'flex-end'
                        }}
                    >
                        <strong style={{ marginBottom: 5 }}>{currency(props.NFCe.totalValueProducts)}</strong>
                        <strong style={{ marginBottom: 5 }}>{currency(props.NFCe.discount)}</strong>
                        <strong style={{ fontSize: '20px', marginBottom: 5 }}>{currency(props.NFCe.totalValueProducts - props.NFCe.discount)}</strong>
                        <span
                            style={{
                                color: "#6a6f8d",
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'flex-end'
                            }}
                        >
                            {props.FormOfPayment.map((form, index) => (
                                <span key={index}>{form}</span>
                            ))}
                        </span>
                        <span>{props.CondPayment} de {currency(props.Payments.Parcels[0].Amount)}</span>
                    </div>
                </div>
            </div>
            <div>
                <table className="table" style={{ width: "100%", textAlignLast: 'center', borderBottom: '1px #dddddd solid', marginTop: '25px' }}>
                    <thead>
                        <tr style={{ fontSize: "13px", color: "#6a6f8d" }}>
                            <th style={{ width: "100px" }}>NFC-e Nº</th>
                            <th>Série</th>
                            <th>Emissão</th>
                            <th>Via do consumidor</th>
                            <th style={{ width: "225px" }}>Chave de Acesso</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={props.Code}>
                            <td>{props.Code}</td>
                            <td>{props.Serie}</td>
                            <td>{props.DateIssued}</td>
                            <td>Consulte pela chave de acesso na SEFAZ</td>
                            <td>{props.AcessKey}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "440px 315px" }}>
                <div style={{ display: "grid", gridTemplateRows: '20px 20px', fontSize: '13px', fontWeight: '700', color: '#171b34 ' }}>
                    <span style={{ marginTop: '10px', margin: '0' }}>DANFE NFC-e - Documento Auxiliar </span>
                    <span style={{ margin: 0 }}> da Nota Fiscal de Consumo Eletrônico</span>
                </div >
                <div style={{ display: "grid", gridTemplateRows: "50px 30px 30px 30px 30px 30px", fontSize: "15px", color: "#6a6f8d" }}>
                    <span><strong>{props.Company.Company_Name}</strong></span>
                    <span>CEP: {cepMask(props.Company.Address[0].Zipcode)}</span>
                    <span>{props.Company.Address[0].Address}, {props.Company.Address[0].Address_Number}</span>
                    <span>{props.Company.Address[0].Neighborhood} - {props.Company.Address[0].City}, {props.Company.Address[0].State}</span>
                    <span>Telefone: {phoneMask(props.Company.Phones[0].Number_Phone1)}</span>
                    <span>CNPJ: {props.Company.Cpf_Cnpj.length == 14 ? cnpjMask(props.Company.Cpf_Cnpj) : cpfMask(props.Company.Cpf_Cnpj)}</span>
                    <span>IE: {props.Company.IE}</span>
                </div>
            </div>
        </div>


    </div >
)

export default renderNFCE