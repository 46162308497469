import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { usePlanSignatureContext } from '../../contexts/plan-signature'
import { useAuth } from '../../contexts/auth'
import constants from '../../utils/constants'
import logoImage from '../../assets/img/os-digital-letra-branca.png'

import './styles.css'

const CanceledClient = ({ history }) => {
  const { loading, userId } = useAuth()
  const { isSignatureManuallyBlocked, isSignatureValid } =
    usePlanSignatureContext()

  useEffect(() => {
    if (
      !loading &&
      (!userId || (!isSignatureManuallyBlocked && isSignatureValid))
    ) {
      history.push('/client-login')
    }
  }, [loading, userId, isSignatureManuallyBlocked, isSignatureValid])

  return (
    <div id="canceled-client-page">
      <header>
        <img src={logoImage} alt="Logo O.S Digital" />
      </header>
      <main>
        <div className="content-grid">
          <section>
            <p>Prezado(a) Cliente,</p>
            <p>
              Atendemos o seu pedido de cancelamento e sua assinatura está
              suspensa. Esperamos que tenha gostado do nosso sistema e será um
              prazer tê-lo (a) como cliente no futuro novamente!
            </p>
            <br />
            <p>
              Se mudar de ideia é simples, basta nos contatar através dos
              telefones: (19) 3755-3001 / 3016 / 3034 ou via WhatsApp:
              <a
                className="whatsapp-link"
                href={
                  constants.WHATSAPP_API_SEND_MESSAGE_ENDPOINT +
                  '?phone=5519982440071&text=Ol%C3%A1,%20gostaria%20de%20reativar%20a%20minha%20assinatura%20do%20OS%20Digital.%20Poderia%20me%20auxiliar,%20por%20gentileza'
                }
                target="_blank"
              >
                {' '}
                Clicando aqui{' '}
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  color="#25d366"
                  cursor="pointer"
                />
              </a>
            </p>
          </section>
          <section>
            <FontAwesomeIcon
              icon={faCalendarTimes}
              color="#444444"
              className="calendar-times"
            />
          </section>
        </div>
      </main>
    </div>
  )
}

export default withRouter(CanceledClient)
