import React, { useState, useEffect } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { reduxForm, change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { isCPFOrCNPJ } from 'brazilian-values';
import { toastr } from 'react-redux-toastr';

import CardForm from '../../../../components/CardForm';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import MainData from './MainData';
import Items from './Items';
import Totals from './Totals';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { ModalDigitalPaymentAlert } from 'v2/components/fiscal/ModalDigitalPaymentAlert/ModalDigitalPaymentAlert';
import { ModalPaymentAuthorizationCodeReview } from 'v2/components/sale/ModalPaymentAuthorizationCodeReview/ModalPaymentAuthorizationCodeReview';
import { hasDigitalPayments } from 'v2/helpers/paymentHelpers';
import { getCompanyAddress } from 'v2/helpers/addressHelpers';
import { useAuth } from 'contexts/auth';

const NFCe = ({ handleSubmit, disabled, onCancel, loadNFCe }) => {
  const [dadosItens, setDadosItens] = useState(true);
  const [dadosNota, setDadosNota] = useState(true);
  const [totaisNota, setTotaisNota] = useState(true);
  const [isIncludeCpfCnpjModalOpen, setIsIncludeCpfCnpjModalOpen] =
    useState(false);
  const [msgBtn, setMsgBtn] = useState('');

  const { companyId } = useAuth();

  const [isModalDigitalPaymentAlertOpen, setIsModalDigitalPaymentAlertOpen] =
    useState();

  const [submitAfterSaveAuthorization, setSubmitAfterSaveAuthorization] =
    useState(false);

  const [
    isModalPaymentAuthorizationCodeReviewOpen,
    setIsModalPaymentAuthorizationCodeReviewOpen,
  ] = useState(false);

  const {
    id,
    cnpjCpf,
    customerType,
    isDefaultCustomer,
    statusNFCe,
    isInvoiceOpen,
    status,
    isInvoiceIssued,
    isInvoiceCanceled,
    isInvoiceOpenedWithErrors,
    customerHasCpfCnpj,
    saleId,
    saleParcels,
  } = useSelector((state) => state.form.cadastroNFCe.values);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!id & (statusNFCe === 'Aberta')) {
      validateSubmission();
    }
  }, [statusNFCe, id]);

  useEffect(() => {
    if (!isInvoiceOpen || !isInvoiceOpenedWithErrors) {
      msgButton();
    }
  }, [status]);

  async function handleIncludeCpfCnpj(includeCpfCnpj) {
    const isNotValid = !isCPFOrCNPJ(cnpjCpf);

    if (isNotValid && includeCpfCnpj) {
      const documentType = customerType === 'Fisica' ? 'CPF' : 'CNPJ';
      return toastr.warning('OS Digital', `Informe um ${documentType} válido`);
    }

    setIsIncludeCpfCnpjModalOpen(false);
    dispatch(change('cadastroNFCe', 'includeCpfCnpj', includeCpfCnpj));
    setTimeout(() => handleSubmit(), 1000);
  }

  async function validateSubmission() {
    const address = await getCompanyAddress(companyId);

    const isCompanyFromRS = address.State === 'RS';
    const hasSale = !!saleId;

    if (isCompanyFromRS && hasSale && hasDigitalPayments(saleParcels)) {
      setSubmitAfterSaveAuthorization(true);
      setIsModalDigitalPaymentAlertOpen(true);
      return;
    }

    setIsIncludeCpfCnpjModalOpen(true);
    return;
  }

  function msgButton() {
    switch (status) {
      case 'Emitida':
        setMsgBtn('NFC-e Emitida');
        break;
      case 'Cancelada':
        setMsgBtn('NFC-e Cancelada');
        break;
      case 'Denegada':
        setMsgBtn('NFC-e Denegada');
        break;
      case 'Contingência':
        setMsgBtn('NFC-e em Contingência');
        break;
      default:
        break;
    }
  }

  const handleOpenModalPaymentAuthorizationCodeReview = () => {
    if (!saleId) {
      toastr.warning(
        'Função indisponível',
        'A função é habilitada apenas para notas com vendas vinculadas'
      );
      return;
    }

    setSubmitAfterSaveAuthorization(false);
    setIsModalPaymentAuthorizationCodeReviewOpen(true);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show={dadosNota}
          title="Dados da Nota"
          onClick={() => setDadosNota(!dadosNota)}
        >
          <MainData disabled={disabled} loadNFCe={loadNFCe} />
        </CardForm>
        <CardForm
          show={dadosItens}
          title="Itens"
          onClick={() => setDadosItens(!dadosItens)}
        >
          <Items disabled={disabled} />
        </CardForm>
        {(isInvoiceIssued || isInvoiceCanceled) && (
          <CardForm
            show={totaisNota}
            title="Totais"
            onClick={() => setTotaisNota(!totaisNota)}
          >
            <Totals disabled={disabled} />
          </CardForm>
        )}

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            bsStyle="danger"
            onClick={onCancel}
            style={{ marginRight: '12px' }}
            name="Cancelar"
            fill
          >
            Voltar
          </Button>
          <Button
            bsStyle="warning"
            fill
            onClick={handleOpenModalPaymentAuthorizationCodeReview}
            disabled={disabled}
            style={{ marginRight: '12px' }}
          >
            Pagamentos
          </Button>
          {isInvoiceOpen || isInvoiceOpenedWithErrors ? (
            <Button
              bsStyle="info"
              disabled={disabled}
              name="Salvar"
              fill
              onClick={() => validateSubmission()}
            >
              Emitir NFC-e
            </Button>
          ) : (
            <Button
              bsStyle="info"
              className="color-button"
              disabled={disabled}
              name="Salvar"
              fill
            >
              {msgBtn}
            </Button>
          )}
        </Col>
      </div>

      <Modal
        dialogClassName="modal-cpfnota modal-20w"
        show={isIncludeCpfCnpjModalOpen}
        animation
        onHide={() => setIsIncludeCpfCnpjModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              {customerType === 'Fisica' ? 'CPF' : 'CNPJ'} na Nota?
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modal-edit-customer"
          style={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto' }}
        >
          Deseja colocar o {customerType === 'Fisica' ? 'CPF' : 'CNPJ'} na nota?
          <div id="center-cpf-input">
            <Field
              name="cnpjCpf"
              component={Input}
              disabled={!isDefaultCustomer && customerHasCpfCnpj}
              normalize={(value) =>
                onlyNumbers(value).length <= 11
                  ? cpfMask(value)
                  : cnpjMask(value)
              }
              maxLength={18}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-identify-in-nfce-footer">
            <Button
              type="button"
              name="Voltar"
              style={{ float: 'left' }}
              bsStyle="danger"
              fill
              onClick={() => handleIncludeCpfCnpj(false)}
            >
              Não
            </Button>
            <Button
              type="button"
              name="Sim"
              bsStyle="info"
              fill
              onClick={() => handleIncludeCpfCnpj(true)}
            >
              Sim
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <ModalDigitalPaymentAlert
        open={isModalDigitalPaymentAlertOpen}
        onClose={() => setIsModalDigitalPaymentAlertOpen(false)}
        onSendInvoice={() => setIsIncludeCpfCnpjModalOpen(true)}
        onVerifyPayments={() =>
          setIsModalPaymentAuthorizationCodeReviewOpen(true)
        }
      />

      {saleId && isModalPaymentAuthorizationCodeReviewOpen && (
        <ModalPaymentAuthorizationCodeReview
          open={isModalPaymentAuthorizationCodeReviewOpen}
          onClose={() => setIsModalPaymentAuthorizationCodeReviewOpen(false)}
          afterSave={
            submitAfterSaveAuthorization
              ? () => setIsIncludeCpfCnpjModalOpen(true)
              : () => null
          }
          saleId={saleId}
        />
      )}
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroNFCe',
})(withRouter(NFCe));
