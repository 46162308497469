import { currency } from 'client/components/ToNormalize/ToNormalize'
import { format, isValid } from 'date-fns/esm'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

export const xlsColumns = [
  {
    name: 'Tipo',
    acessor: 'type',
  },
  {
    name: 'Dt Criação Venda',
    acessor: 'saleDate',
  },
  {
    name: 'Dt Fechamento Venda',
    acessor: 'saleCloseDate',
  },
  {
    name: 'Nº Venda/OS',
    acessor: 'saleCode',
  },
  {
    name: 'Tipo de Venda',
    acessor: 'saleType',
  },
  {
    name: 'Cliente',
    acessor: 'customer',
  },
  {
    name: 'Valor',
    acessor: 'billValue',
  },
  {
    name: 'Dt Vencimento',
    acessor: 'dueDate',
  },
  {
    name: 'Dt Pagamento',
    acessor: 'paymentDate',
  },
]

export const getBillsToReceiveInfoToExport = (billsToReceive) => {
  let billsToReceiveInfoToExport = []

  for (let billToReceive of billsToReceive) {
    const billsToReceiveParcels = billToReceive.BillsToReceiveParcels.map(
      (parcel) => ({
        payDate: isValid(new Date(parcel.payDate))
          ? format(new Date(getDateOnlyFromDate(parcel.payDate)), 'dd/MM/yyyy')
          : null,
        billToReceiveIssuedAt: isValid(new Date(billToReceive.issuedAt))
          ? format(
              new Date(getDateOnlyFromDate(billToReceive.issuedAt)),
              'dd/MM/yyyy'
            )
          : null,
        amount: currency(parcel.amount),
        billToReceiveCode: billToReceive.code,
        formOfPayment: parcel.FormOfPayment.Desciption,
        clientName: billToReceive.Customer.Company_Name,
        parcelPaidValue: currency(
          parcel.amount - (billToReceive.feeValue || 0)
        ),
      })
    )

    billsToReceiveInfoToExport = billsToReceiveInfoToExport.concat(
      billsToReceiveParcels
    )
  }

  return billsToReceiveInfoToExport
}
