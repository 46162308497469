const xlsColumns = [
  {
    name: 'Vencimento',
    acessor: 'dueDate',
  },
  {
    name: 'Título',
    acessor: 'titleCode',
  },
  {
    name: 'Compra',
    acessor: 'purchaseCode',
  },
  {
    name: 'NF',
    acessor: 'nfCode',
  },
  {
    name: 'Atraso',
    acessor: 'daysOverdue',
  },
  {
    name: 'Cliente',
    acessor: 'providerName',
  },
  {
    name: 'Telefone',
    acessor: 'providerPhone',
  },
  {
    name: 'Data de baixa',
    acessor: 'dischargeDate',
  },
  {
    name: 'Banco/Caixa',
    acessor: 'cashierBank',
  },
  {
    name: 'Valor do título',
    acessor: 'amount',
  },
  {
    name: 'Conta',
    acessor: 'accountPlan',
  },
  // {
  //   name: 'DT Criação',
  //   acessor: 'createdAt',
  // },
  // {
  //   name: 'DT Pagamento',
  //   acessor: 'payDate',
  // },
]

export default xlsColumns
