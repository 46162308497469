import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import {
  phoneMask,
  cpfOrCnpjMask,
  RgMask,
  cepMask,
  currency,
} from '../../../client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';

const ItemLabel = ({ label, value, width }) => (
  <View style={{ display: 'flex', flexDirection: 'row', width: width }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
);

const TableContent = ({
  data,
  dateType,
  type,
  initialDate,
  finalDate,
  totalizers,
  totalsPerPerson,
  personId,
}) => {
  return (
    <>
      <View>
        <Text style={styles.title}>Pendências Financeiras em Aberto</Text>
      </View>

      <View>
        <ItemLabel
          label="Tipo de Filtragem"
          value={dateType === 'dueDate' ? 'Vencimento' : 'Criação'}
        />
      </View>

      <View style={styles.containerRow}>
        <ItemLabel
          label="Data Inicial"
          value={format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy')}
          width="15%"
        />
        <ItemLabel
          label="Data Final"
          value={format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy')}
        />
      </View>

      {!personId ? (
        <View>
          <View style={styles.line} />
          <View>
            <Text
              style={[styles.title, { textAlign: 'center', fontSize: '14px' }]}
            >
              Totais por {type === 'client' ? 'Cliente' : 'Fornecedor'}
            </Text>
          </View>
          <View style={styles.line} />

          <TotalsByPerson
            totalsPerPerson={totalsPerPerson}
            totalizers={totalizers}
            type={type}
          />
        </View>
      ) : (
        <View />
      )}

      {data.map((item, index) => {
        return (
          <View key={index} style={{ marginTop: '30px' }}>
            <View style={styles.line} />
            <CustomerSupplierInfo person={item.person} />
            <View style={styles.line} />
            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Totais por Status
              </Text>
            </View>
            <View style={styles.line} />
            <TotalsByStatus
              isExpired={item.totalsPerStatus.expired.qtd}
              isNotExpired={item.totalsPerStatus.notExpired.qtd}
              totalExpiredValue={item.totalsPerStatus.expired.total}
              totalNotExpiredValue={item.totalsPerStatus.notExpired.total}
              type={type}
            />
            <View style={[styles.line]} />

            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Pendências Dentro da Validade
              </Text>
            </View>

            <View style={[styles.line]} />

            <NotExpiredData data={item.notExpiredBills} type={type} />

            <View style={[styles.line]} />

            <View>
              <Text style={[styles.title, { textAlign: 'center' }]}>
                Pendências Vencidas
              </Text>
            </View>

            <View style={[styles.line]} />
            <ExpiredData data={item.expiredBills} type={type} />
            <View style={[styles.line]} />
          </View>
        );
      })}

      <View style={[styles.line]} />
    </>
  );
};

const CustomerSupplierInfo = ({ person }) => {
  return (
    <View style={{ marginTop: '5px' }}>
      <View style={styles.containerRow}>
        <ItemLabel
          label="CPF/CNPJ"
          value={person.cpfCnpj ? cpfOrCnpjMask(person.cpfCnpj) : ''}
          width="30%"
        />
        <ItemLabel
          label="RG/IE"
          value={person.rg ? RgMask(person.rg) : person.ie}
          width="25%"
        />
      </View>

      <View style={styles.containerRow}>
        <ItemLabel
          label="Razão Social/Nome"
          value={
            person.personName?.length
              ? person.personName.length > 24
                ? `${person.personName.slice(0, 24)}...`
                : person.personName
              : ''
          }
          width="30%"
        />
        <ItemLabel
          label="Telefone"
          value={phoneMask(person.phone)}
          width="20%"
        />
        <ItemLabel label="Email" value={person.email} width="30%" />
      </View>

      <View style={styles.containerRow}>
        <ItemLabel
          label="Endereço"
          value={
            person?.address
              ? person?.address.street?.length > 37
                ? `${person.address.street.slice(0, 37)}...`
                : person?.address.street
              : ''
          }
          width="30%"
        />
        <ItemLabel
          label="Nº"
          value={person?.address ? person.address.number : ''}
          width="20%"
        />
        <ItemLabel
          label="Bairro"
          value={person?.address ? person.address.neighborhood : ''}
          width="25%"
        />
        <ItemLabel
          label="Cidade/UF"
          value={`${
            person?.address
              ? `${person.address.city}/${person.address.state}`
              : ''
          }`}
          width="20%"
        />
      </View>

      <View>
        <ItemLabel
          label="CEP"
          value={person?.Address ? person.address.zipcode : ''}
          width="25%"
        />
      </View>
    </View>
  );
};

const NotExpiredData = ({ data, type }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Data Vencimento</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Número</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>
          {type === 'client' ? 'Venda/OS' : 'Compra'}
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Acréscimo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Desconto</Text>
        {type === 'client' && (
          <Text style={[styles.boldText, { width: '10%' }]}>Juros</Text>
        )}
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Total</Text>
      </View>

      <View style={[styles.line]} />

      {data?.map((d) => (
        <View style={styles.containerRow}>
          <Text style={[styles.text, { width: '10%' }]}>
            {format(new Date(d.issuedAt), 'dd/MM/yyyy')}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {format(new Date(d.dueDate), 'dd/MM/yyyy')}
          </Text>
          <Text style={[styles.text, { width: '5%' }]}>{d.code}</Text>
          <Text style={[styles.text, { width: '7%' }]}>
            {type === 'client' ? d.saleCode : d.purchaseCode}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.subTotal)}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.addedValue)}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.discountValue)}
          </Text>
          {type === 'client' && (
            <Text style={[styles.text, { width: '10%' }]}>
              {currency(d.feeValue || 0)}
            </Text>
          )}
          <Text style={[styles.text, { width: '10%' }]}>
            {type === 'client'
              ? currency(d.openValue + (d.feeValue || 0))
              : currency(d.openValue)}
          </Text>
        </View>
      ))}
    </View>
  );
};

const ExpiredData = ({ data, type }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Data Vencimento</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Número</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>
          {type === 'client' ? 'Venda/OS' : 'Compra'}
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Acréscimo</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Desconto</Text>
        {type === 'client' && (
          <Text style={[styles.boldText, { width: '10%' }]}>Juros</Text>
        )}
        <Text style={[styles.boldText, { width: '10%' }]}>Valor Total</Text>
      </View>

      <View style={[styles.line]} />

      {data.map((d, index) => (
        <View key={index} style={styles.containerRow}>
          <Text style={[styles.text, { width: '10%' }]}>
            {format(new Date(d.issuedAt), 'dd/MM/yyyy')}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {format(new Date(d.dueDate), 'dd/MM/yyyy')}
          </Text>
          <Text style={[styles.text, { width: '5%' }]}>{d.code}</Text>
          <Text style={[styles.text, { width: '7%' }]}>
            {type === 'client' ? d.saleCode : d.purchaseCode}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.subTotal)}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.addedValue)}
          </Text>
          <Text style={[styles.text, { width: '10%' }]}>
            {currency(d.discountValue)}
          </Text>
          {type === 'client' && (
            <Text style={[styles.text, { width: '10%' }]}>
              {currency(d.feeValue || 0)}
            </Text>
          )}
          <Text style={[styles.text, { width: '10%' }]}>
            {type === 'client'
              ? currency(d.openValue + (d.feeValue || 0))
              : currency(d.openValue)}
          </Text>
        </View>
      ))}
    </View>
  );
};

const TotalsByStatus = ({
  isExpired,
  isNotExpired,
  totalExpiredValue,
  totalNotExpiredValue,
}) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '12%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Qtd.</Text>
        <Text style={[styles.boldText, { width: '15%' }]}>Total</Text>
      </View>

      <View style={styles.line} />

      <View style={styles.containerRow}>
        <View style={{ width: '12%' }}>
          <Text style={styles.text}>Dentro da Validade</Text>
        </View>
        <View style={{ width: '8%' }}>
          <Text style={styles.text}>{isNotExpired}</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.text}>{currency(totalNotExpiredValue)}</Text>
        </View>
      </View>

      <View style={styles.containerRow}>
        <View style={{ width: '12%' }}>
          <Text style={styles.text}>Vencidos</Text>
        </View>
        <View style={{ width: '8%' }}>
          <Text style={styles.text}>{isExpired}</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.text}>{currency(totalExpiredValue)}</Text>
        </View>
      </View>
    </View>
  );
};

const TotalsByPerson = ({ totalsPerPerson, type, totalizers }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '35%' }]}>
          {type === 'client' ? 'Cliente' : 'Fornecedor'}
        </Text>
        <Text style={[styles.boldText, { width: '15%', textAlign: 'center' }]}>
          Status
        </Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>
          QTD
        </Text>
        <Text style={[styles.boldText, { width: '20%', textAlign: 'center' }]}>
          Total
        </Text>
      </View>

      <View style={styles.line} />

      {totalsPerPerson.map((item, index) => (
        <View key={index}>
          <View style={styles.containerRow}>
            <View style={{ width: '35%' }}>
              <Text style={styles.text}>{item.personName}</Text>
            </View>
            <View style={{ width: '15%', textAlign: 'center' }}>
              <Text style={styles.text}>Vencidas</Text>
            </View>
            <View style={{ width: '10%', textAlign: 'center' }}>
              <Text style={styles.text}>{item.expired.qtd}</Text>
            </View>
            <View style={{ width: '20%', textAlign: 'center' }}>
              <Text style={styles.text}>{currency(item.expired.total)}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={{ width: '35%' }}>
              <Text style={styles.text}>{item.personName}</Text>
            </View>
            <View style={{ width: '15%', textAlign: 'center' }}>
              <Text style={styles.text}>Abertas</Text>
            </View>
            <View style={{ width: '10%', textAlign: 'center' }}>
              <Text style={styles.text}>{item.notExpired.qtd}</Text>
            </View>
            <View style={{ width: '20%', textAlign: 'center' }}>
              <Text style={styles.text}>{currency(item.notExpired.total)}</Text>
            </View>
          </View>
        </View>
      ))}

      <View style={styles.line} />
      <View style={styles.containerRow}>
        <View style={{ width: '35%' }}></View>
        <View style={{ width: '15%' }}></View>
        <View style={{ width: '10%', textAlign: 'center' }}>
          <Text style={styles.boldText}>{totalizers.qtd}</Text>
        </View>
        <View style={{ width: '20%', textAlign: 'center' }}>
          <Text style={styles.boldText}>{currency(totalizers.total)}</Text>
        </View>
      </View>
      <View style={styles.line} />
    </View>
  );
};

export default function CustomersListPDF({
  responseData,
  type,
  dateType,
  initialDate,
  finalDate,
  totalsPerPerson,
  totalizers,
  personId,
}) {
  return (
    <Document title="listagem-de-clientes">
      <Page orientation="landscape" style={styles.page}>
        <TableContent
          totalsPerPerson={totalsPerPerson}
          data={responseData}
          dateType={dateType}
          type={type}
          initialDate={initialDate}
          finalDate={finalDate}
          totalizers={totalizers}
          personId={personId}
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
});
