import React from 'react'

import { currency } from '../../../client/components/ToNormalize/ToNormalize'

const Items = ({ items }) => {
  return (
    <article className='items-content'>
      <table>
        <thead>
          <tr>
            <td className='code'><span>Código</span></td>
            <td className='product'><span>Produto</span></td>
            <td className='quantity'><span>Quantidade</span></td>
            <td />
            <td className='unit-price'><span>Preço Unit.</span></td>
            <td />
            <td className='amount'><span>Preço Total</span></td>
          </tr>
        </thead>

        <tbody>
          {
            items.map(item => {
              const discountPercentage = (100 - item.Discount_Value) / 100
              const unitValue = item.Unit_Value * discountPercentage

              return (
                <tr key={item.id}>
                  <td className='code'><span>{!item.Product_id ? item.Services?.Code : item.Products?.Code}</span></td>
                  <td className='product'><span>{item.Description}</span></td>
                  <td className='quantity'><span>{item.Quantity}</span></td>
                  <td><span>x</span></td>
                  <td className='unit-price'><span>{currency(unitValue)}</span></td>
                  <td><span>=</span></td>
                  <td className='amount'><span>{currency(item.Amount)}</span></td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </article>
  )
}

export default Items