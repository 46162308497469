import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { CRMBonusUpdateCustomerModalForm } from './Form';

import {
  cpfMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';

import { getDateOnlyFromDate } from 'utils/dateHelpers';
import customersRepository from 'repositories/Customers';
import { useBonus } from 'hooks/useBonus';

function handleValidateClientData(data) {
  if (
    !data.cpf ||
    !data.name ||
    !data.email ||
    !data.birthdate ||
    !data.phoneNumber
  ) {
    return false;
  }

  return true;
}

function handleFormatClientData(clientData) {
  return {
    identifiers: {
      Phone_id: clientData.phoneId,
    },
    customer: {
      Cpf_Cnpj: clientData?.cpf ? onlyNumbers(clientData.cpf) : null,
      Company_Name: clientData.name,
      Email: clientData.email,
      Date_Birth: clientData.birthdate,
    },
    phones: {
      Number_Phone2: clientData?.phoneNumber
        ? onlyNumbers(clientData.phoneNumber)
        : null,
    },
  };
}

export const CRMBonusUpdateCustomerModal = ({
  isOpen,
  handleCancelUpdate,
  handleProceedUpdateCustomer,
  customerId,
  customer,
}) => {
  const [isLoading, setIsLoading] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const { setCRMBonusCurrentCustomer } = useBonus();

  const formMethods = useForm({
    defaultValues: {
      id: '',
      cpf: '',
      name: '',
      email: '',
      birthdate: null,
      phoneId: '',
      phoneNumber: '',
      companyId: '',
    },
  });

  const { register, control, setValue, getValues } = formMethods;

  function handleSetCustomer() {
    const customerData = getValues();

    setCRMBonusCurrentCustomer({
      id: customerData.id,
      Company_Name: customerData.name,
      Cpf_Cnpj: customerData.cpf,
      Date_Birth: customerData.birthdate,
      Email: customerData.email,
      Phones: [{ Number_Phone2: customerData.phoneNumber }],
      Company_id: customerData.companyId,
    });
  }

  async function handleLoadClientData() {
    setIsLoading(true);

    try {
      const client = await customersRepository.show(customerId || customer?.id);
      handleSetClientData({ client });
      handleSetCustomer(client);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados do cliente. Tente novamente!'
      );
    }
  }

  async function handleValidateCustomerCPFUse(cpf, customerId, companyId) {
    try {
      const res = await customersRepository.getByCompanyAndCpfCnpj({
        cpfCnpj: onlyNumbers(cpf),
        companyId: companyId,
      });

      if (
        res.message !== 'Cliente não encontrado' &&
        customerId &&
        String(res.data.id) !== String(customerId)
      ) {
        toastr.warning('CPF já existente', 'Já existe um cliente com este CPF');
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao validar o CPF do cliente',
        'Por favor, tente novamente'
      );
      return true;
    }
  }

  async function handleSetCustomer(customerData) {
    setCRMBonusCurrentCustomer({
      id: customerData.id,
      Company_Name: customerData.name,
      Cpf_Cnpj: customerData.cpf,
      Date_Birth: customerData.birthdate,
      Email: customerData.email,
      Phones: [{ Number_Phone2: customerData.phoneNumber }],
      Company_id: customerData.companyId,
    });
  }

  async function handleUpdateClientData() {
    try {
      const clientData = getValues();
      const isValid = handleValidateClientData(clientData);

      await handleSetCustomer(clientData);

      if (!isValid) {
        return toastr.warning(
          'OS Digital',
          'Existem campos em branco, por favor preencha todo os campos obrigatórios e tente novamente!'
        );
      }

      setIsLoading(true);

      const isCPFAlreadyRegistered = await handleValidateCustomerCPFUse(
        clientData.cpf,
        clientData.id,
        clientData.companyId
      );
      if (isCPFAlreadyRegistered) return;

      const formattedCustomerData = handleFormatClientData(clientData);

      await customersRepository.update(
        customerId || customer?.id,
        formattedCustomerData
      );

      handleProceedUpdateCustomer(customer);

      toastr.success('OS Digital', 'Cliente atualizado com Sucesso!');
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao atualizar os dados do cliente. Tente novamente!'
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleSetClientData({ client }) {
    setValue('id', client?.id);
    setValue('cpf', client?.Cpf_Cnpj ? cpfMask(client.Cpf_Cnpj) : '');
    setValue('name', client?.Company_Name);
    setValue('email', client?.Email);
    setValue('companyId', client?.Company_id);

    setValue(
      'birthdate',
      client?.Date_Birth
        ? format(getDateOnlyFromDate(new Date(client.Date_Birth)), 'yyyy-MM-dd')
        : null
    );

    setValue(
      'phoneNumber',
      client?.Phones ? client?.Phones[0]?.Number_Phone2 : ''
    );

    setValue('phoneId', client?.Phones[0].id);
  }

  useEffect(() => {
    handleLoadClientData();
  }, []);

  return (
    <Modal show={isOpen} dialogClassName="modal-60w">
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '0',
            height: '50px',
          }}
        >
          <h1 style={{ margin: '0' }}>Cadastro de Cliente</h1>
          <span style={{ color: 'red', margin: '0' }}>
            *Campos Obrigatórios
          </span>
        </div>
      </Modal.Header>
      <Modal.Body style={{ width: '100%' }}>
        <FormProvider {...formMethods}>
          <CRMBonusUpdateCustomerModalForm />
        </FormProvider>
      </Modal.Body>
      <div className="flex end gap-050" style={{ padding: '0 10px 10px 10px' }}>
        <button
          className="button button-red w-100px"
          onClick={handleCancelUpdate}
        >
          Cancelar
        </button>
        <button
          disabled={isLoading}
          className="button button-green w-100px"
          onClick={handleUpdateClientData}
        >
          Salvar
        </button>
      </div>
    </Modal>
  );
};
