import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import { currency } from '../../../client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'
import constants from '../../../utils/constants'

const ReturnContentHeader = () => (
  <View>
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View
        style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Item</Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>QTD</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Valor Unit.</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>Total</Text>
      </View>
    </View>

    <View style={[styles.line, { marginTop: 1 }]} />
  </View>
)

const ReturnContent = ({ description, quantity, unityValue }) => (
  <View>
    <View style={{ flexDirection: 'row', height: 12, width: '100%' }}>
      <View
        style={{ width: '30%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{description}</Text>
      </View>
      <View
        style={{ width: '5%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{quantity}</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{currency(unityValue)}</Text>
      </View>
      <View
        style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}
      >
        <Text style={styles.text}>{currency(unityValue * quantity)}</Text>
      </View>
    </View>
  </View>
)

const ReturnHeader = ({ createdAt, responsibleForTheReturn }) => (
  <View style={{ paddingTop: 10 }}>
    <View
      style={{
        flexDirection: 'row',
        height: 12,
        fontWeight: 'bold',
        width: '100%',
      }}
    >
      <View
        style={{
          width: '30%',
          textAlign: 'left',
          justifyContent: 'flex-start',
          flexDirection: 'row',
        }}
      >
        <Text style={styles.text}>Data/Hora: </Text>
        <Text style={[styles.text, { fontWeight: 'normal' }]}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </Text>
      </View>
      <View
        style={{
          width: '70%',
          textAlign: 'left',
          justifyContent: 'flex-start',
          flexDirection: 'row',
        }}
      >
        <Text style={styles.text}>Reponsável pela devolução: </Text>
        <Text style={[styles.text, { fontWeight: 'normal' }]}>
          {responsibleForTheReturn.length > 17
            ? `${responsibleForTheReturn.slice(0, 17)}...`
            : responsibleForTheReturn}
        </Text>
      </View>
    </View>

    <View style={[styles.line, { marginTop: 1 }]} />
  </View>
)

const ReturnContainer = ({ resume }) =>
  resume.map(({ id, createdAt, responsibleForTheReturn, items }) => (
    <View key={id}>
      <ReturnHeader
        createdAt={createdAt}
        responsibleForTheReturn={responsibleForTheReturn}
      />
      <ReturnContentHeader />
      {items.map((item, index) => (
        <View key={index}>
          <ReturnContent {...item} />
        </View>
      ))}

      <View style={[styles.line, { marginTop: 1 }]} />
    </View>
  ))

export const SaleReturnDocument = ({
  saleType,
  saleCode,
  value,
  customerName,
  resume,
}) => {
  return (
    <Document
      title={`devolução-venda-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="devolução"
    >
      <Page style={styles.page}>
        <View style={{ marginBottom: 10 }}>
          <Text style={styles.title}>Histórico de Devolução</Text>
        </View>
        <View>
          <Text>
            {constants.SALES_TYPE_ID.ORDER_OF_SERVICE === saleType
              ? 'OS'
              : constants.SALES_TYPE_DESCRIPTION[saleType]}
            : {saleCode}
          </Text>
          <Text>Cliente: {customerName}</Text>
          <Text>Valor: {currency(value)}</Text>
        </View>
        <ReturnContainer resume={resume} />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
})
