import React, { useEffect } from 'react'
import { useState } from 'react'
import PartsRequisitionFilter from './PartsRequisitionFilter'
import PartsRequisitionTable from './PartsRequisitionTable'
import EmployeesRepository from 'repositories/Employees'
import { toastr } from 'react-redux-toastr'
import { useAuth } from 'contexts/auth'
import PartsRequisitionRepository from 'repositories/PartsRequisition'
import useDebounce from 'hooks/useDebounce'
import AlertModal from 'components/AlertModal'

const PartsRequisitionMain = ({ history }) => {
  const [status, setStatus] = useState('')
  const [query, setQuery] = useState('')
  const [employees, setEmployees] = useState([])
  const [employeeId, setEmployeeId] = useState(null)
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)

  const [refreshPage, setRefreshPage] = useState(false)

  const [cancelModal, setCancelModal] = useState(false)

  const [partsRequisitionList, setPartsRequisitionList] = useState([])

  const [loading, setLoading] = useState(false)

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [queryDebounced, setQueryDebounced] = useState('')
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600)

  const { companyId } = useAuth()

  const handleChangeQuery = (value) => {
    setQuery(value)
    debouncedSaveQuery(value)
  }

  const loadPartsRequisition = async () => {
    setLoading(true)
    try {
      const params = {
        companyId,
        query: queryDebounced,
        limit: pageLimit,
        page: currentPage + 1,
        status,
        employeeId,
        initialDate,
        finalDate,
      }

      const response = await PartsRequisitionRepository.getAllByCompany(params)

      setPartsRequisitionList(response.saleRequisitionList)
      setTotalPages(Math.ceil(response.count / pageLimit))
    } catch (err) {}
    setLoading(false)
  }

  const loadEmployees = async () => {
    setLoading(true)
    try {
      const employees = await EmployeesRepository.getAllByCompany(companyId, {
        isActive: 1,
      })

      setEmployees(employees.data)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível carregar os funcionários.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
  }

  const cancelRequisition = async () => {
    setLoading(true)
    try {
      await PartsRequisitionRepository.cancelRequisition(cancelModal)

      setRefreshPage(!refreshPage)

      toastr.success(
        'Requisição cancelada com sucesso!',
        'As peças foram devolvidas para o estoque.'
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'Não foi possível cancelar a requisição.',
        'Por favor, tente novamente.'
      )
    }
    setLoading(false)
    setCancelModal(false)
  }

  useEffect(() => {
    loadEmployees()
  }, [])

  useEffect(() => {
    loadPartsRequisition()
  }, [
    currentPage,
    pageLimit,
    status,
    employees,
    queryDebounced,
    refreshPage,
    employeeId,
    initialDate,
    finalDate,
  ])

  return (
    <>
      <PartsRequisitionFilter
        history={history}
        loading={loading}
        employees={employees}
        setStatus={setStatus}
        setEmployeeId={setEmployeeId}
        handleChangeQuery={handleChangeQuery}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        finalDate={finalDate}
        initialDate={initialDate}
      />
      <PartsRequisitionTable
        loading={loading}
        partsRequisitionList={partsRequisitionList}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        setCancelModal={setCancelModal}
      />

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          title="OS Digital"
          subtitle="Você tem certeza que deseja cancelar essa entrega?"
          message="A partir do momento que for cancelado, as peças serão devolvidas para o estoque. O processo é irreversível."
          onSubmit={() => cancelRequisition()}
          onCancel={() => setCancelModal(false)}
          onHide={() => setCancelModal(false)}
          loading={loading}
        />
      )}
    </>
  )
}

export default PartsRequisitionMain
