import React, { useEffect, useState } from 'react'
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
  Image,
} from '@react-pdf/renderer'
import { addDays, format } from 'date-fns'
import {
  currency,
  percentage,
  cepMask,
  CFOPMask,
} from 'client/components/ToNormalize/ToNormalize'

const InventoryCountPDF = (value) => {
  const inventory = value.responseData.inventory
  const totals = value.responseData.totals

  const formatedCreatedAt = inventory.inventory.createdAt.split(' ')[0]
  const formatedfinishDate = inventory.inventory.finishDate.split(' ')[0]

  return (
    <Document
      title={`listing-inventory-report-${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View>
          <Text style={styles.title}>
            Relatório Contagem/Inventário - {inventory.inventory.description}
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
            >
              Data Iniciada:
            </Text>
            <Text style={[styles.text]}>
              {format(new Date(formatedCreatedAt), 'dd/MM/yyyy')}
            </Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text
                style={[
                  styles.text,
                  { fontWeight: 'bold', marginRight: '3px' },
                ]}
              >
                Data Finalizada:
              </Text>
              <Text style={[styles.text]}>
                {format(new Date(formatedfinishDate), 'dd/MM/yyyy')}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
          >
            Observações:
          </Text>
          <Text style={[styles.text]}>{inventory.inventory.observations}</Text>
        </View>

        <TableHeader totals={totals} inventory={inventory} />
      </Page>
    </Document>
  )
}

const TableHeader = ({ totals, inventory }) => {
  return (
    <>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Família
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '23%' }]}>Fornecedor</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Quantidade</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>

      <View style={[styles.line]} />

      {totals.map((total, index) => {
        return <TotalsByTypeStatusItems total={total} key={index} />
      })}

      <View style={[styles.line]} />

      <SubTotals
        subtotal={totals.reduce((prev, curr) => prev + curr.Total, 0)}
        qtdItems={totals.reduce((prev, curr) => prev + curr.TotalItems, 0)}
      />

      <View style={[styles.line, { marginTop: '10px' }]} />

      <View>
        <Text style={[styles.title, { textAlign: 'center' }]}>Inventário</Text>
      </View>
      <View style={[styles.line]} />

      <InventoryCountDetails inventory={inventory} />
    </>
  )
}

const TotalsByTypeStatusItems = ({ key, total }) => {
  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <View style={{ width: '25%' }}>
          <Text style={styles.text}>{total.Description}</Text>
        </View>
        <View style={{ width: '9%' }}>
          <Text style={styles.text}>{total.TotalItems}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(total.Total)}</Text>
        </View>
      </View>
    </View>
  )
}

const SubTotals = ({ qtdItems, subtotal }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '25%' }]}>Total </Text>
        <Text style={[styles.boldText, { width: '9%' }]}>{qtdItems}</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>
          {currency(subtotal)}
        </Text>
      </View>
    </View>
  )
}

const InventoryCountDetails = ({ inventory }) => {
  return (
    <View style={{ marginTop: '10px' }}>
      <InventoryCountDetailsTableHeader />
      {inventory.inventoryItems.map((item, index) => {
        return <InventoryCountDetailsTableItem inventorie={item} key={index} />
      })}

      <View style={styles.line} />
    </View>
  )
}

const InventoryCountDetailsTableHeader = ({ }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '5%' }]}>Codigo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Ref.Fabricação</Text>
        <Text style={[styles.boldText, { width: '14%' }]}>Família</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>R$ Compra</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Qtd. Estoque</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Qtd. Inventário</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Difer.</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const InventoryCountDetailsTableItem = ({ inventorie, key }) => {
  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={{ width: '5%' }}>{inventorie.Products?.code}</Text>
        <Text style={{ width: '20%' }}>{inventorie.description}</Text>
        <Text style={{ width: '12%' }}>{inventorie.manufacturerReference}</Text>
        <Text style={{ width: '15%' }}>
          {inventorie.Products?.Families?.Description || 'Sem Família'}
        </Text>
        <Text style={{ width: '9%' }}>
          {currency(inventorie.purchasePrice)}
        </Text>
        <Text style={{ width: '10%' }}>{inventorie.stockQuantity}</Text>
        <Text style={{ width: '7%' }}>{inventorie.quantity}</Text>
        <Text style={{ width: '7%' }}>
          {inventorie.stockQuantity - inventorie.quantity}
        </Text>
        <Text style={{ width: '8%' }}>
          {currency(inventorie.purchasePrice * inventorie.stockQuantity)}
        </Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>
  )
}

export default InventoryCountPDF

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
})
