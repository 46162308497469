import React, { useEffect, useState } from 'react'
import { reduxForm, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import CustomButton from 'client/components/CustomButton/CustomButton'
import ReactTable from 'react-table'
import salesRepository from '../../../../../repositories/Sales'
import vehicleRepository from '../../../../../repositories/Vehicles'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { format } from 'date-fns'

const ModalOsBudget = ({ onCancel, show, id }) => {
  const [sale, setSale] = useState({})
  const [company, setCompany] = useState({})
  const [vehicle, setVehicle] = useState([])
  const [allModalData, setAllModalData] = useState([])

  useEffect(() => {
    loadSale()
  }, [])

  const loadSale = async () => {
    try {
      const sale = await salesRepository.getById(id)

      setSale(sale)
      setCompany(sale.Company)

      const getVehicleByPlate = await vehicleRepository.getVehicleByPlate(
        sale.Customer.id,
        sale.License_Plate
      )

      setVehicle(getVehicleByPlate)

      const mapSalesItems = sale.SalesItems.map((data) => {
        return {
          ...data,
          id: data.id,
          type: data.Type,
          description: data.Description,
          unitValue: data.Unit_Value,
          total: data.Amount,
          quantity: data.Quantity,
          manufacturerRef:
            data.Type === 'Produto' && data.Products.Manufacturer_Ref,
          brand: data.Type === 'Produto' && data.Products.Brands?.Description,
        }
      })

      setAllModalData(mapSalesItems)
    } catch (err) {
      console.log(err)
    }
  }

  const filterSalesType = (id) => {
    if (id === 1) {
      return 'Dados do Orçamento'
    } else if (id === 3) {
      return 'Dados da Ordem de Serviço'
    } else {
      return 'Número inválido'
    }
  }

  const getClientName = (type) => {
    if (type === 'Jurídica') {
      return company.Trading_Name
    } else if (type === 'Física') {
      return company.Company_Name
    } else {
      return null
    }
  }

  const formatDate = (date) => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    } else {
      return '---'
    }
  }

  return (
    <>
      <Modal
        show={show}
        animation
        dialogClassName="modal-70w modal-70h "
        onHide={onCancel}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>
              {filterSalesType(sale.SalesType_id)}:{sale.Code}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ fontWeight: 'bold' }}>Data: {formatDate(sale.Date)}</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 'bold', width: '30%' }}>
                Cliente: {getClientName(company.Type)}
              </p>
              <div style={{ display: 'flex', width: '70%' }}>
                <p
                  style={{
                    marginRight: '3rem',
                    marginLeft: '3rem',
                    fontWeight: 'bold',
                  }}
                >
                  Placa: {sale.License_Plate}
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  Modelo:{' '}
                  {vehicle.map((vehicle) => {
                    if (vehicle.id === sale.Vehicle_id) return vehicle.Model
                  })}
                </p>
              </div>
            </div>
          </div>
          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={allModalData}
              columns={[
                {
                  Header: 'Código',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 70,
                },
                {
                  Header: 'Ref. Fabricante',
                  accessor: 'manufacturerRef',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  headerClassName: 'text-left',
                  Cell: (props) => (props.value === 'Produto' ? 'P' : 'S'),
                  width: 70,
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                  width: 250,
                },
                {
                  Header: 'Marca',
                  accessor: 'brand',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Qtd',
                  accessor: 'quantity',
                  headerClassName: 'text-left',
                  width: 70,
                },
                {
                  Header: 'Valor Unit',
                  accessor: 'unitValue',
                  headerClassName: 'text-left',
                  width: 90,
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Valor Total',
                  accessor: 'total',
                  headerClassName: 'text-left',
                  width: 90,
                  Cell: (props) => currency(props.value),
                },
              ]}
              defaultPageSize={10}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </main>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton bsStyle="danger" fill onClick={onCancel}>
            Voltar
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalOsBudget
