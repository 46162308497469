import React from 'react'
import { useModulesContext } from '../../../../../contexts/modules'
import SupportButton from 'client/components/SupportButton'
import { currency } from 'client/components/ToNormalize/ToNormalize'

const InactiveNFeModule = () => {
  const { nfeModule } = useModulesContext()

  return (
    <>
      <div>
        <div style={{ paddingTop: '10px' }}>
          <p style={{ fontSize: '14px' }}>
            Para utilizar a emissão da
            <strong> nota fiscal eletrônica (NF-e)</strong> no OS Digital será
            adicionado o valor de <strong> {currency(nfeModule.price)}</strong>{' '}
            na sua mensalidade.
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontSize: '14px', paddingRight: '5px' }}>
              Para realizar a assinatura do módulo, solicite junto ao
              atendimento ao cliente clicando no botão ao lado:
            </p>
            <SupportButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default InactiveNFeModule
