import SearchInput from 'components/SearchInput';
import React from 'react';
import constants from 'utils/constants';

function QuotationsFilter({
  history,
  query,
  setQuery,
  status,
  setStatus,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          padding: 0,
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'flex-end',
          gap: '10px',
        }}
      >
        <div>
          <button
            className="btn btn-sucesso button-h35"
            onClick={() => history.push(constants.ROUTES.NEW_QUOTATION)}
            style={{ fontSize: '12px' }}
          >
            + Nova Cotação
          </button>
        </div>
        <div>
          <SearchInput
            placeholder="Pesquisa por Código ou Fornecedores"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={{
              fontSize: '12px',
              height: '35px',
              width: '312px',
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Status:</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
            style={{ fontSize: '12px' }}
          >
            <option value="">Todos</option>
            <option value="Respondida">Respondida</option>
            <option value="Enviada">Enviada</option>
            <option value="Não Enviada">Não Enviada</option>
            <option value="Cancelada">Cancelada</option>
          </select>
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </div>
        <div>
          <span style={{ fontSize: '12px' }}>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataFinal"
            maxLength="2"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default QuotationsFilter;
