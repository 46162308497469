import React from 'react'

import { Modal } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input'
import CustomButton from '../CustomButton/CustomButton'

import './styles.css'

export const DebtLimitModalBlock = ({ show, setShow, info }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName={`modal-40w`}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>Pendências em aberto</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>Fechamento não autorizado.</strong>
        <p className="debt-limit-modal-block__p">
          O cliente possui as seguintes pendências em aberto:{' '}
        </p>

        <div className="debt-limit-modal-block__informative-inputs">
          <div>
            <span>
              <strong>Títulos em Aberto:</strong>
            </span>
            <input
              className="form-control foco-input"
              type="text"
              value={info?.openTitles}
              disabled
            />
          </div>
          <fieldset>
            <span>
              <strong>Valor em Aberto:</strong>
            </span>
            <CurrencyInput
              id="initial-balance"
              prefix="R$ "
              className="form-control foco-input"
              value={info?.openTitlesValue}
              disabled
            />
          </fieldset>
          <div>
            <span>
              <strong className="debt-limit-modal-block__overdue-title">
                Títulos Vencidos:
              </strong>
            </span>
            <input
              className="form-control foco-input"
              type="text"
              value={info?.overdueTitles}
              disabled
            />
          </div>
          <fieldset>
            <span>
              <strong className="debt-limit-modal-block__overdue-title">
                Valor Vencido:
              </strong>
            </span>
            <CurrencyInput
              id="initial-balance"
              prefix="R$ "
              className="form-control foco-input"
              value={info?.overdueTitlesValue}
              disabled
            />
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton fill bsStyle="danger" onClick={() => setShow(false)}>
          Fechar
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}
