import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'

const AlertModal = ({
  show,
  onHide,
  animation,
  title,
  subtitle,
  loading,
  message,
  onCancel,
  onSubmit,
}) => {
  return (
    <Modal show={show} onHide={onHide} animation={animation}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{!title ? 'OS Digital' : title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtitle ? (
          <>
            <strong>{subtitle}</strong>
            <br></br>
            <br></br>
          </>
        ) : null}
        {message}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            bsStyle='danger'
            disabled={loading}
            name='Não'
            onClick={(e) => onCancel(e)}
            fill
          >
            Não
          </Button>
          <Button
            bsStyle='info'
            disabled={loading}
            name='Sim'
            fill
            onClick={(e) => onSubmit(e)}
          >
            Sim{' '}
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AlertModal
