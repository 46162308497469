import React from 'react'
import { Col } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import renderField from '../../../../../components/RenderField'
import Toggle from 'react-toggle'
import { percentMask } from '../../../../../utils/masks'
import { useDispatch, useSelector } from 'react-redux'

const PositionMainData = () => {

  const { isActive } = useSelector(state => state.form.formPosition.values)
  const dispatch = useDispatch()

  const handleToggle = () => {
    dispatch(change('formPosition', 'isActive', !isActive))
  }

  return (
    <Col>
      <Col xs={12} sm={6} md={3} lg={3} >
        <Field
          label="Descrição do Cargo:"
          name="description"
          component={renderField}
          maxLength="50"
          required
        />
      </Col>
      <Col xs={12} sm={3} md={2} lg={2} style={{ width: '190px' }}>
        <Field
          label="Comissão de Produto:"
          name="productComission"
          component={renderField}
          {...percentMask}
          style={{
            textAlign: 'right'
          }}
        />
      </Col>
      <Col xs={12} sm={3} md={2} lg={2} style={{ width: '190px' }}>
        <Field
          label="Comissão de Serviço:"
          name="serviceComission"
          component={renderField}
          {...percentMask}
          style={{
            textAlign: 'right'
          }}
        />
      </Col>
      <Col xs={12} sm={3} md={1} lg={1} style={{ width: '165px' }}>
        <Field
          label="Executa Serviços?"
          name="performServices"
          component={renderField}
          as="select"
        >
          <option value={true}>Sim</option>
          <option value={false}>Não</option>
        </Field>
      </Col>
      <Col xs={12} sm={3} md={1} lg={1} style={{ width: '165px' }}>
        <Field
          label="Vende Produtos?"
          name="sellProducts"
          component={renderField}
          as="select"
        >
          <option value={true}>Sim</option>
          <option value={false}>Não</option>
        </Field>
      </Col>
      <Col xs={12} sm={1} md={1} lg={1}>
        <label>Status</label>
        <div style={{ paddingLeft: '10px' }}><Toggle checked={isActive} onChange={handleToggle} /></div>
      </Col>
    </Col>
  )
}

export default PositionMainData
