import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { toastr } from 'react-redux-toastr'

import materaRepository from 'repositories/MateraCompany'
import companyRepository from 'repositories/Companies'

import MateraAccountForm from './MateraAccountForm'

const BundleMateraAccountForm = ({ materaId, goToHomepage }) => {
  const [saveLoading, setSaveLoading] = useState(false)

  const [showChooseModeModal, setShowChooseModeModal] = useState(false)

  const dispatch = useDispatch()

  const handleSubmit = async (values, integrate) => {
    setShowChooseModeModal(false)

    const { accountType, companyId, taxPixWork, taxPixMatera, representative } =
      values

    if (!companyId)
      return toastr.warning('Selecione uma empresa para criar a conta.')

    if (!accountType) return toastr.warning('Selecione o tipo de conta!')

    const allCompanyUsers = await companyRepository.getAllUsers(companyId)
    const user = await allCompanyUsers.find(
      (user) => user.id === parseInt(representative)
    )

    const data = {
      accountType,
      companyId,
      taxPixWork,
      taxPixMatera,
      representativeId: user.employeeId,
      integrate,
    }

    if (materaId) {
      return update(data)
    }
    return create(data)
  }

  const create = async (values) => {
    const { integrate } = values

    setSaveLoading(true)

    try {
      const materaAccount = await materaRepository.create(values)
      if (!materaAccount.data.success) {
        throw new Error(materaAccount)
      }

      if (integrate) toastr.success('Conta criada com sucesso!')
      else toastr.success('Cadastro salvo com sucesso!')

      dispatch([
        change('matera', 'materaAccountId', materaAccount.data.accountId),
      ])

      return setTimeout(() => goToHomepage(), 1000)
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Ocorreu um erro o cadastrar. Tente novamente mais tarde.'
      )
    } finally {
      setSaveLoading(false)
    }
  }

  const update = async (values) => {
    const { integrate, taxPixWork } = values

    setSaveLoading(true)

    try {
      const materaAccount = await materaRepository.update(materaId, {
        taxPixWork,
        integrate,
      })
      if (!materaAccount.data.success) {
        throw new Error(materaAccount.data.message)
      }

      if (integrate) toastr.success('Conta criada com sucesso!')
      else toastr.success('Conta atualizada com sucesso!')

      dispatch([
        change('matera', 'materaAccountId', materaAccount.data.accountId),
      ])

      return setTimeout(() => goToHomepage(), 1000)
    } catch (err) {
      if (err.message.includes('{')) {
        const { status } = JSON.parse(err.message)

        switch (status) {
          case 401:
            return toastr.error(
              'Erro matera',
              'Acesso negado, contate o suporte'
            )
            break
          case 500:
            return toastr.error(
              'Erro matera',
              'Sistema indisponível, tente novamente mais tarde'
            )
            break
          case 400:
            return toastr.error(
              'Erro matera',
              'Informações para cadastro incorretas, verifique se todas as informações necessárias foram cadastradas e tente novamente'
            )
        }
      }

      return toastr.error(
        'Ocorreu um erro ao atualizar vínculo. Tente novamente mais tarde.'
      )
    } finally {
      setSaveLoading(false)
    }
  }

  return (
    <MateraAccountForm
      onSubmit={handleSubmit}
      onCancel={goToHomepage}
      saveLoading={saveLoading}
      materaId={materaId}
      showChooseModeModal={showChooseModeModal}
      setShowChooseModeModal={setShowChooseModeModal}
    />
  )
}

export default BundleMateraAccountForm
