import React, { useState } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { Modal } from 'react-bootstrap'

import SATThermalCoil from 'views/Sheets/SATThermalCoil'

import salesRepository from 'repositories/Sales'

import AlertModal from 'components/AlertModal'

import './styles.css'

const ClosedInvoiceModal = ({
  show,
  fiscalDocumentType,
  onInitNewSale,
  downloadNFCeLink,
  saleId,
}) => {
  const [isConfirmInitNewSaleModalOpen, setIsConfirmInitNewSaleModalOpen] =
    useState(false)

  function handleViewDocument() {
    if (fiscalDocumentType === 'SAT') {
      handleOpenSaleInvoice()
    } else {
      window.open(downloadNFCeLink, '_blank')
    }
  }

  function handleOpenSaleInvoice() {
    const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31?isFiscal=true`
    const saleSheet = `${window.location.origin}/sheets/${hash}`

    window.open(saleSheet, '_blank')
  }

  function handleOpenSaleReceipt() {
    const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31`
    const saleSheet = `${window.location.origin}/sheets/${hash}`

    window.open(saleSheet, '_blank')
  }

  function handleSubmitNewSale() {
    setIsConfirmInitNewSaleModalOpen(false)
    onInitNewSale()
  }

  return (
    <>
      <Modal show={show} animation dialogClassName="closed-nfce-modal-wrapper">
        <Modal.Header>
          <Modal.Title>
            <strong>
              {fiscalDocumentType === 'SAT' ? 'SAT enviado' : 'NFC-e enviada'}{' '}
              com sucesso!
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Selecione a opção desejada:</span>
          <div>
            <button className="warning" onClick={handleViewDocument}>
              Imprimir {fiscalDocumentType}
            </button>
            <button className="info" onClick={handleOpenSaleReceipt}>
              Imprimir Comprovante de Venda
            </button>
            <button
              className="success"
              onClick={() => setIsConfirmInitNewSaleModalOpen(true)}
            >
              Iniciar Nova Venda
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <AlertModal
        show={isConfirmInitNewSaleModalOpen}
        message="Deseja iniciar uma nova venda? A impressão da nota pode ser feita posteriormente, se necessário."
        onHide={() => setIsConfirmInitNewSaleModalOpen(false)}
        onCancel={() => setIsConfirmInitNewSaleModalOpen(false)}
        onSubmit={handleSubmitNewSale}
      />
    </>
  )
}

export default ClosedInvoiceModal
