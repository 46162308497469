import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { toastr } from 'react-redux-toastr';
import { isCNPJ } from 'brazilian-values';
import { useAuth } from 'contexts/auth';

import {
  OPEN_INCOMPLETE_CUSTOMER_MODAL_ATOM,
  OPEN_CONFIRMATION_SALE_WITHOUT_BONUS_MODAL_ATOM,
  OPEN_SELECT_CAMPAIGN_MODAL,
  OPEN_CANCEL_UPDATE_MODAL_ATOM,
  OPEN_FIRST_PURSHASE_MODAL_ATOM,
  OPEN_PIN_VALIDATION_MODAL_ATOM,
  OPEN_BONUS_AVAILABLE_MODAL_ATOM,
  OPEN_CAMPAIGN_CONFIRMATION_MODAL,
  OPEN_BONUS_RESCUED_MODAL_ATOM,
  COMPANY_CRM_BONUS_STORE_CODE_ATOM,
  COMPANY_HAS_CRM_BONUS_ATOM,
  OPEN_UPDATE_CUSTOMER_MODAL_ATOM,
  CRM_BONUS_CUSTOMER_ID_ATOM,
  CRM_BONUS_CURRENT_CUSTOMER_ATOM,
  CRM_BONUS_CURRENT_SALE_VALUE_ATOM,
  CRM_IDS_BONUS_ATOM,
  CRM_BONUS_AVAILABLE_VALUE_ATOM,
  CRM_CUSTOMER_PIN_ATOM,
  CAMPAINGS_ATOM,
  SAVE_SALE_ATOM,
  SELECTED_CAMPAIGN_ATOM,
  RESET_SAVE_MODAL_TRIGGER_ATOM,
  SAVE_ORCAMENT_TRIGGER_ATOM,
  OPEN_PDV_CONFIRM_CPF_MODAL_ATOM,
  OPEN_PDV_INPUT_CPF_MODAL_ATOM,
  PDV_SALE_ATOM,
  IS_PDV_ATOM,
  SAVE_PDV_ATOM,
  REDEEMED_BONUS_ATOM,
  OPEN_NOT_FOUND_CUSTOMER_MODAL_ATOM,
  OPEN_CREATE_CUSTOMER_MODAL_ATOM,
  CURRENT_CUSTOMER_CPF_ATOM,
  ALREADY_STARTED_CRM_CUSTOMER_ATOM,
  PDV_VALIDATED_CUSTOMER_ATOM,
  USED_CRMBONUS,
  CONSULTED_BONUS,
} from 'atoms/crmBonusAtoms';

import { CRMBonusRepositoryV2 } from 'v2/repositories/CRMBonus';
import SaleRepository from 'v2/repositories/SaleRepository';
import { CRMBonusRepository } from 'repositories/CRMBonus';
import { CRM_APPLY_BONUS_TRIGGER_ATOM } from 'atoms/crmBonusAtoms';
import { CONTINUE_WITHOUT_BONUS_TRIGGER_ATOM } from 'atoms/crmBonusAtoms';
import customersRepository from 'repositories/Customers';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { BONUS_ID_ATOM } from 'atoms/crmBonusAtoms';
import { ORDER_ID_ATOM } from 'atoms/crmBonusAtoms';
import { IS_FIRST_PURCHASE } from 'atoms/crmBonusAtoms';

export const useBonus = () => {
  const { companyId } = useAuth();

  const [isPDV, setIsPDV] = useRecoilState(IS_PDV_ATOM);

  const [alreadyStartedCRMCustomer, setAlreadyStartedCRMCustomer] =
    useRecoilState(ALREADY_STARTED_CRM_CUSTOMER_ATOM);

  const [pdvValidatedCustomer, setPdvValidatedCustomer] = useRecoilState(
    PDV_VALIDATED_CUSTOMER_ATOM
  );

  const [companyHasCRMBonus, setCompanyHasCRMBonus] = useRecoilState(
    COMPANY_HAS_CRM_BONUS_ATOM
  );

  const [companyCRMBonusStoreCode, setCompanyCRMBonusStoreCode] =
    useRecoilState(COMPANY_CRM_BONUS_STORE_CODE_ATOM);

  const [crmBonusCustomerId, setCRMBonusCustomerId] = useRecoilState(
    CRM_BONUS_CUSTOMER_ID_ATOM
  );

  const [crmBonusCurrentCustomer, setCRMBonusCurrentCustomer] = useRecoilState(
    CRM_BONUS_CURRENT_CUSTOMER_ATOM
  );

  const [crmBonusCurrentSaleValue, setCRMBonusCurrentSaleValue] =
    useRecoilState(CRM_BONUS_CURRENT_SALE_VALUE_ATOM);

  const [openIncompleteCustomerModal, setOpenIncompleteCustomerModal] =
    useRecoilState(OPEN_INCOMPLETE_CUSTOMER_MODAL_ATOM);

  const [crmCustomerPIN, setCRMCustomerPIN] = useRecoilState(
    CRM_CUSTOMER_PIN_ATOM
  );

  const [crmBonusAvailableValue, setCRMBonusAvailableValue] = useRecoilState(
    CRM_BONUS_AVAILABLE_VALUE_ATOM
  );

  const [resetSaveModalTrigger, setResetSaveModalTrigger] = useRecoilState(
    RESET_SAVE_MODAL_TRIGGER_ATOM
  );

  const [saveOrcamentTrigger, setSaveOrcamentTrigger] = useRecoilState(
    SAVE_ORCAMENT_TRIGGER_ATOM
  );

  const [crmIdsBonus, setCRMIdsBonus] = useRecoilState(CRM_IDS_BONUS_ATOM);

  const [crmApplyBonusTrigger, setCRMApplyBonusTrigger] = useRecoilState(
    CRM_APPLY_BONUS_TRIGGER_ATOM
  );

  const [crmSaveSaleTrigger, setCRMSaveSaleTrigger] =
    useRecoilState(SAVE_SALE_ATOM);

  const [crmcontinueWithoutBonusTrigger, setContinueWithoutBonusTrigger] =
    useRecoilState(CONTINUE_WITHOUT_BONUS_TRIGGER_ATOM);

  const [usedCRMBonus, setUsedCRMBonus] = useRecoilState(USED_CRMBONUS);

  const [campaigns, setCampaigns] = useRecoilState(CAMPAINGS_ATOM);

  const [selectedCampaign, setSelectedCampaign] = useRecoilState(
    SELECTED_CAMPAIGN_ATOM
  );

  const [
    openConfirmationSaleWithoutBonusModal,
    setOpenConfirmationSaleWithoutBonusModal,
  ] = useRecoilState(OPEN_CONFIRMATION_SALE_WITHOUT_BONUS_MODAL_ATOM);

  const [openSelectCampaignModal, setOpenSelectCampaignModal] = useRecoilState(
    OPEN_SELECT_CAMPAIGN_MODAL
  );

  const [openCancelUpdateCustomerModal, setOpenCancelUpdateCustomerModal] =
    useRecoilState(OPEN_CANCEL_UPDATE_MODAL_ATOM);

  const [openFirstPurchaseModal, setOpenFirstPurchaseModal] = useRecoilState(
    OPEN_FIRST_PURSHASE_MODAL_ATOM
  );

  const [openPINValidationModal, setOpenPINValidationModal] = useRecoilState(
    OPEN_PIN_VALIDATION_MODAL_ATOM
  );

  const [openBonusAvailableModal, setOpenBonusAvailableModal] = useRecoilState(
    OPEN_BONUS_AVAILABLE_MODAL_ATOM
  );

  const [openBonusRescuedModal, setOpenBonusRescuedModal] = useRecoilState(
    OPEN_BONUS_RESCUED_MODAL_ATOM
  );

  const [openUpdateCustomerModal, setOpenUpdateCustomerModal] = useRecoilState(
    OPEN_UPDATE_CUSTOMER_MODAL_ATOM
  );

  const [openCampaignConfirmationModal, setOpenCampaignConfirmationModal] =
    useRecoilState(OPEN_CAMPAIGN_CONFIRMATION_MODAL);

  const [openPDVConfirmCPFModal, setOpenPDVConfirmCPFModal] = useRecoilState(
    OPEN_PDV_CONFIRM_CPF_MODAL_ATOM
  );

  const [openPDVInputCPFModal, setOpenPDVInputCPFModal] = useRecoilState(
    OPEN_PDV_INPUT_CPF_MODAL_ATOM
  );

  const [openNotFoundCustomerModal, setOpenNotFoundCustomerModal] =
    useRecoilState(OPEN_NOT_FOUND_CUSTOMER_MODAL_ATOM);

  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useRecoilState(
    OPEN_CREATE_CUSTOMER_MODAL_ATOM
  );

  const [currentCustomerCPF, setCurrentCustomerCPF] = useRecoilState(
    CURRENT_CUSTOMER_CPF_ATOM
  );

  const [pdvSale, setPDVSale] = useRecoilState(PDV_SALE_ATOM);

  const [bonusId, setBonusId] = useRecoilState(BONUS_ID_ATOM);

  const [orderId, setOrderId] = useRecoilState(ORDER_ID_ATOM);

  const [savePDVTrigger, setSavePDVTrigger] = useRecoilState(SAVE_PDV_ATOM);

  const [redeemedBonus, setRedeemedBonus] = useRecoilState(REDEEMED_BONUS_ATOM);

  const [consultedBonus, setConsultedBonus] = useRecoilState(CONSULTED_BONUS);

  const [isFirstPurchase, setIsFirstPurchase] =
    useRecoilState(IS_FIRST_PURCHASE);

  const handleConsultedBonus = () => {
    setConsultedBonus(true);
  };

  const handleOpenNotFoundCustomerModal = () => {
    setOpenNotFoundCustomerModal(true);
  };

  const handleCloseNotFoundCustomerModal = () => {
    setOpenNotFoundCustomerModal(false);
  };
  const handleOpenCreateCustomerModal = () => {
    setOpenCreateCustomerModal(true);
  };

  const handleCloseCreateCustomerModal = () => {
    setOpenCreateCustomerModal(false);
  };
  const handleOpenPDVConfirmCPFModal = () => {
    setOpenPDVConfirmCPFModal(true);
  };

  const handleClosePDVConfirmCPFModal = () => {
    setOpenPDVConfirmCPFModal(false);
  };

  const handleOpenPDVInputCPFModal = () => {
    setOpenPDVInputCPFModal(true);
  };

  const handleClosePDVInputCPFModal = () => {
    setOpenPDVInputCPFModal(false);
  };

  const handleOpenBonusAvailable = () => {
    setOpenBonusAvailableModal(true);
  };

  const handleOpenIncompleteCustomerModal = () => {
    setOpenIncompleteCustomerModal(true);
  };

  const handleCloseIncompleteCustomerModal = () => {
    setOpenIncompleteCustomerModal(false);
  };

  const handleOpenSaleWithoutBonusModal = () => {
    setOpenConfirmationSaleWithoutBonusModal(true);
  };

  const handleCloseSaleWithoutBonusModal = () => {
    setOpenConfirmationSaleWithoutBonusModal(false);
    triggerResetSaveModal();
  };

  const handleProceedPDVConfirmCPF = () => {
    handleClosePDVConfirmCPFModal();
    handleOpenPDVInputCPFModal();
  };

  const handleCloseSelectCampaignModal = () => {
    setOpenSelectCampaignModal(false);
  };

  const handleOpenSelectCampaignModal = () => {
    setOpenSelectCampaignModal(true);
  };

  const handleOpenUpdateCustomerModal = () => {
    setOpenUpdateCustomerModal(true);
  };

  const handleOpenFirstPurchaseModal = () => {
    setOpenFirstPurchaseModal(true);
  };

  const handleOpenPINValidationModal = () => {
    setOpenPINValidationModal(true);
  };

  const handleOpenCampaignConfirmationModal = () => {
    setOpenCampaignConfirmationModal(true);
  };

  const handleCloseCampaignConfirmationModal = () => {
    setOpenCampaignConfirmationModal(false);
  };

  const handleCloseCancelUpdateCustomerModal = () => {
    setOpenCancelUpdateCustomerModal(false);
  };

  const handleOpenCancelUpdateCustomerModal = () => {
    setOpenCancelUpdateCustomerModal(true);
  };

  const handleCloseFirstPurchaseModal = () => {
    setOpenFirstPurchaseModal(false);
  };

  const handleClosePINValidationModal = () => {
    setOpenPINValidationModal(false);
  };

  const handleCloseBonusAvailableModal = () => {
    setOpenBonusAvailableModal(false);
  };

  const handleOpenBonusRescuedModal = () => {
    setOpenBonusRescuedModal(true);
  };

  const handleCloseBonusRescuedModal = () => {
    setOpenBonusRescuedModal(false);
  };

  const handleCloseUpdateCustomerModal = () => {
    setOpenCancelUpdateCustomerModal(false);
    setOpenUpdateCustomerModal(false);
  };

  let hasPurchases;

  const getCompanyCRMBonusConfig = async () => {
    try {
      const response = await CRMBonusRepositoryV2.findByCompany(
        companyId || localStorage.getItem('ID_EMPRESA')
      );
      if (response.length > 0) {
        const config = response[0].Company.CRMBonus;
        setCompanyHasCRMBonus(config);
        setCompanyCRMBonusStoreCode(response[0].storeCode);
      } else {
        setCompanyHasCRMBonus(false);
      }
    } catch (e) {
      setCompanyHasCRMBonus(false);
      console.log(e);
      toastr.error(
        'Erro ao carregar CRM Bônus',
        'Tivemos um problema em obter suas configurações. Tente novamente!'
      );
    }
  };

  const handleInitCRMBonus = async (customer) => {
    if (customer) {
      setCRMBonusCurrentCustomer(customer);
    }

    try {
      const customerId = customer?.id || crmBonusCurrentCustomer?.id;

      if (!customerId || alreadyStartedCRMCustomer) return;

      const res = await CRMBonusRepository.start({
        customerId,
        storeCode: companyCRMBonusStoreCode,
      });

      setCRMCustomerPIN('');

      if (res.message === 'sucesso') {
        setAlreadyStartedCRMCustomer(true);
        setCRMBonusCustomerId(res.crmCustomerId);

        if (!res.pinRequired) {
          await handleConsultAvailableBonus(res.crmCustomerId);
          if (isPDV) {
            setPdvValidatedCustomer(true);
          }
          handleClosePINValidationModal();
        }
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao enviar SMS', 'Por favor, tente novamente.');
      handleClosePINValidationModal();
    }
  };

  const handleConsultAvailableBonus = async (customerId) => {
    try {
      const res = await CRMBonusRepository.bonusAvailable({
        crmCustomerId: crmBonusCustomerId || customerId,
        storeCode: companyCRMBonusStoreCode,
        grossValue: crmBonusCurrentSaleValue,
        cellphone: onlyNumbers(crmBonusCurrentCustomer.Phones[0].Number_Phone2),
      });
      setCRMBonusAvailableValue(res.bonusValue);
      setCRMIdsBonus(res.idsBonus);

      if (res.shouldAccumulateBonus) {
        handleOpenBonusAvailable();
      } else {
        handleUseBonusConfirmation(res.bonusValue);
      }

      handleClosePINValidationModal();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao consultar o bônus disponível',
        'Por favor, tente novamente.'
      );
    }
  };

  const handleResentPIN = async () => {
    try {
      const res = await CRMBonusRepository.resendPin({
        storeCode: companyCRMBonusStoreCode,
        crmCustomerId: crmBonusCustomerId,
        cellphone: onlyNumbers(crmBonusCurrentCustomer.Phones[0].Number_Phone2),
      });

      if (res.message === 'sucesso') {
        toastr.success(
          'SMS disparado com sucesso',
          'A CRM disparou um novo SMS com o Pin.'
        );
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      console.log(err);
      toastr.error('Falha no disparo do SMS', 'Por favor, tente novamente.');
    }
  };

  const handleValidatePIN = async () => {
    try {
      const res = await CRMBonusRepository.validatePin({
        pin: crmCustomerPIN,
        crmCustomerId: crmBonusCustomerId,
        cellphone: onlyNumbers(crmBonusCurrentCustomer.Phones[0].Number_Phone2),
        storeCode: companyCRMBonusStoreCode,
      });

      if (res.message === 'sucesso' && res.validPin) {
        const purshase = await SaleRepository.verifyCRMBonusByCustomer(
          crmBonusCurrentCustomer.id
        );

        if (isPDV) {
          setPdvValidatedCustomer(true);
        }
        hasPurchases = purshase;
        if (hasPurchases) {
          toastr.success('PIN validado', 'Aguarde, consultando bônus...');
          handleConsultAvailableBonus();
          return;
        } else {
          toastr.success('PIN validado', 'Aguarde...');
          handleUseBonusConfirmation();
          handleClosePINValidationModal();
          return;
        }
      } else if (res.message === 'sucesso' && !res.validPin) {
        toastr.warning(
          'Falha na validação do PIN',
          'O pin informado é inválido. Tente novamente, se necessário, reenvie o pin para o cliente.'
        );
        return;
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao validar o PIN', 'Por favor, tente novamente.');
    }
  };

  const handleCampaignAvailable = async (customer, sale) => {
    try {
      const res = await CRMBonusRepository.campaignAvailable({
        storeCode: companyCRMBonusStoreCode,
        liquidTotal:
          crmBonusCurrentSaleValue > 0 ? crmBonusCurrentSaleValue : sale.total,
        cardOwnStore: false,
        cellphone: onlyNumbers(customer.Phones[0].Number_Phone2),
      });

      handleOpenSelectCampaignModal();

      if (res.message === 'sucesso') {
        setCampaigns(res.campaigns);
      }
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao buscar Campanhas', 'Por favor, tente novamente.');
    }
  };

  const handleProceedIncompleteCustomer = () => {
    handleOpenUpdateCustomerModal();
  };

  const handleCancelUpdateCustomer = () => {
    handleCloseUpdateCustomerModal();
    handleCloseCancelUpdateCustomerModal();
  };

  const handleCancelUpdateCustomerModal = () => {
    handleCloseUpdateCustomerModal();
  };

  const handleCancelProceedUpdateCustomer = () => {
    handleCloseCancelUpdateCustomerModal();
  };

  const handleProceedCancelCreateOrUpdateCustomer = () => {
    handleCloseCancelUpdateCustomerModal();
    handleCloseCreateCustomerModal();
    handleCloseUpdateCustomerModal();
  };

  const handleProceedCreateCustomer = () => {
    handleCloseNotFoundCustomerModal();
    handleOpenCreateCustomerModal();
  };

  const handleProceedCancelCreateCustomer = () => {
    handleCloseCancelUpdateCustomerModal();
    handleCloseCreateCustomerModal();
  };

  const handleCustomerFirstPurchaseProceed = async () => {
    setCRMBonusAvailableValue(0);
    setIsFirstPurchase(true);
    setRedeemedBonus(0);
    triggerApplyBonus();
    await handleInitCRMBonus();
    handleOpenPINValidationModal();
    handleCloseFirstPurchaseModal();
  };

  const handleProceedPDVInputCPF = async (cpf) => {
    try {
      const res = await customersRepository.getByCompanyAndCpfCnpj({
        cpfCnpj: onlyNumbers(cpf),
        companyId: companyId,
      });

      if (res.status === false) {
        setCurrentCustomerCPF(onlyNumbers(cpf));
        handleClosePDVInputCPFModal();
        handleOpenNotFoundCustomerModal();
        return;
      } else {
        const selectedClient = res.data;

        validateCustomer(selectedClient);
        setPDVSale(selectedClient);
        handleClosePDVInputCPFModal();
      }

      return false;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao validar o CPF do cliente',
        'Por favor, tente novamente'
      );
      return true;
    }
  };

  const handleProceedCreateOrUpdateCustomer = (customer) => {
    validateCustomerFirstSale(customer);
    handleCloseIncompleteCustomerModal();
    handleCloseUpdateCustomerModal();
    handleCloseCreateCustomerModal();
  };

  const handleUseBonusConfirmation = (bonusValue) => {
    const bonusValueToApply =
      crmBonusAvailableValue > 0 ? crmBonusAvailableValue : bonusValue;
    setRedeemedBonus(bonusValueToApply);
    triggerApplyBonus();
    handleCloseBonusAvailableModal();
    return;
  };

  const handleRedeemBonusClick = (sale) => {
    if (!companyHasCRMBonus) return;

    const isCustomerPJ = isCNPJ(sale.selectedClient.Cpf_Cnpj);
    if (isCustomerPJ) {
      toastr.warning(
        'Função disponível apenas para pessoas físicas',
        ' Verifique o cliente e tente novamente'
      );
      return;
    }

    if (sale?.items?.length === 0 || !sale?.selectedClient?.id) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Selecione ao menos 1 item e 1 cliente para solicitar o resgate de bonus'
      );
      return;
    }

    if (!!sale?.selectedClient) {
      setCRMBonusCurrentCustomer(sale?.selectedClient);
    }
    setCRMCustomerPIN('');
    setCRMBonusAvailableValue(0);
    setCRMBonusCurrentSaleValue(sale.total);
    setAlreadyStartedCRMCustomer(false);
    validateCustomer(sale?.selectedClient);
  };

  const validateCustomer = async (customer) => {
    const { id, Cpf_Cnpj, Company_Name, Email, Date_Birth, Phones } = customer;

    if (!Cpf_Cnpj || !Company_Name || !Email || !Date_Birth || !Phones.length) {
      return handleOpenIncompleteCustomerModal();
    }

    const selectedClient = {
      id,
      Cpf_Cnpj,
      Company_Name,
      Email,
      Date_Birth,
      Phones,
    };

    setCRMBonusCurrentCustomer(selectedClient);

    validateCustomerFirstSale(
      selectedClient ? selectedClient : crmBonusCurrentCustomer
    );
  };

  const validateCustomerFirstSale = async (customer) => {
    const currentCustomer = customer ? customer : crmBonusCurrentCustomer?.id;

    const res = await SaleRepository.verifyCRMBonusByCustomer(
      currentCustomer.id
    );

    hasPurchases = res;

    if (hasPurchases === true) {
      handleOpenPINValidationModal();
      return;
    }
    if (hasPurchases === false) {
      handleOpenFirstPurchaseModal();
      return;
    }
  };

  const handleRedeemPDVBonusClick = ({ liquidValue, customer }) => {
    setCurrentCustomerCPF(customer.cpfCnpj);
    setIsPDV(true);
    setCRMCustomerPIN('');
    setCRMBonusAvailableValue(0);
    setCampaigns([]);
    setCRMBonusCurrentSaleValue(liquidValue);
    setSavePDVTrigger(false);
    handleOpenPDVConfirmCPFModal();
  };

  const handleBackPDVInputCPFModal = () => {
    handleClosePDVInputCPFModal();
    handleOpenPDVConfirmCPFModal();
  };

  const triggerApplyBonus = () => {
    setCRMApplyBonusTrigger(true);
    setUsedCRMBonus(true);
    if (!isPDV) {
      setTimeout(() => {
        setCRMApplyBonusTrigger(false);
      }, 5000);
    }
  };

  const triggerContinueWithoutBonus = () => {
    setContinueWithoutBonusTrigger(true);
    setTimeout(() => {
      setContinueWithoutBonusTrigger(false);
    }, 500);
  };

  const triggerContinueWithoutBonusPDV = () => {
    setContinueWithoutBonusTrigger(true);
  };

  const triggerSaveSale = () => {
    setCRMSaveSaleTrigger(true);

    handleCloseCampaignConfirmationModal();
    handleCloseSelectCampaignModal();
    handleCloseSaleWithoutBonusModal();

    setTimeout(() => {
      setCRMSaveSaleTrigger(false);
    }, 500);
  };
  const triggerResetSaveModal = () => {
    setResetSaveModalTrigger(true);

    setTimeout(() => {
      setResetSaveModalTrigger(false);
    }, 500);
  };

  const triggerSaveOrcament = () => {
    handleCloseBonusRescuedModal();
    setSaveOrcamentTrigger(true);

    setTimeout(() => {
      setSaveOrcamentTrigger(false);
    }, 500);
  };

  const triggerSavePDV = () => {
    setSavePDVTrigger(true);
    handleCloseCampaignConfirmationModal();
    handleCloseSelectCampaignModal();

    setTimeout(() => {
      setSavePDVTrigger(false);
    }, 500);
  };

  const handleResetPDV = () => {
    setIsPDV(false);
    setPdvValidatedCustomer(false);
    setAlreadyStartedCRMCustomer(false);
    setCRMBonusCustomerId('');
    setCRMCustomerPIN('');
    setCRMBonusAvailableValue(0);
    setRedeemedBonus(0);
    setCampaigns([]);
    setCRMBonusCurrentSaleValue(0);
    setCRMBonusCurrentCustomer({});
    setSavePDVTrigger(false);
    setCRMApplyBonusTrigger(false);
    setContinueWithoutBonusTrigger(false);
    setBonusId(0);
    setOrderId(0);
    setCurrentCustomerCPF('');
    setUsedCRMBonus(false);
    setConsultedBonus(false);
    setIsFirstPurchase(false);
  };

  const handleResetSale = () => {
    setPdvValidatedCustomer(false);
    setAlreadyStartedCRMCustomer(false);
    setCRMBonusCustomerId('');
    setCRMCustomerPIN('');
    setCRMBonusAvailableValue(0);
    setRedeemedBonus(0);
    setCampaigns([]);
    setCRMBonusCurrentSaleValue(0);
    setCRMBonusCurrentCustomer({});
    setCRMApplyBonusTrigger(false);
    setContinueWithoutBonusTrigger(false);
    setBonusId(0);
    setOrderId(0);
    setUsedCRMBonus(false);
    setIsFirstPurchase(false);
  };

  const handleFinishPDV = () => {
    handleResetPDV();
  };

  const handleFinishSale = () => {
    handleResetSale();
  };

  useEffect(() => {
    if (companyHasCRMBonus === null) getCompanyCRMBonusConfig();
  }, [companyHasCRMBonus]);

  return {
    companyHasCRMBonus,
    companyCRMBonusStoreCode,
    setCRMBonusCurrentCustomer,
    setCRMCustomerPIN,
    crmCustomerPIN,
    campaigns,
    selectedCampaign,
    setSelectedCampaign,
    setResetSaveModalTrigger,
    setSaveOrcamentTrigger,
    isFirstPurchase,

    crmBonusCustomerId,
    crmBonusCurrentCustomer,
    crmApplyBonusTrigger,
    crmBonusAvailableValue,
    crmcontinueWithoutBonusTrigger,
    crmSaveSaleTrigger,
    crmIdsBonus,
    crmBonusCurrentSaleValue,

    triggerContinueWithoutBonus,
    triggerSaveSale,
    triggerResetSaveModal,
    triggerSaveOrcament,
    triggerContinueWithoutBonusPDV,
    triggerSavePDV,

    resetSaveModalTrigger,
    saveOrcamentTrigger,
    savePDVTrigger,
    isPDV,
    redeemedBonus,
    bonusId,
    setBonusId,
    orderId,
    setOrderId,
    handleFinishPDV,
    companyId,
    currentCustomerCPF,
    usedCRMBonus,
    consultedBonus,

    openIncompleteCustomerModal,
    openConfirmationSaleWithoutBonusModal,
    openSelectCampaignModal,
    openCancelUpdateCustomerModal,
    openFirstPurchaseModal,
    openPINValidationModal,
    openBonusAvailableModal,
    openBonusRescuedModal,
    openUpdateCustomerModal,
    openCampaignConfirmationModal,
    openPDVConfirmCPFModal,
    openPDVInputCPFModal,
    openNotFoundCustomerModal,
    openCreateCustomerModal,

    handleOpenBonusRescuedModal,
    handleOpenIncompleteCustomerModal,
    handleOpenUpdateCustomerModal,
    handleOpenCancelUpdateCustomerModal,
    handleOpenSaleWithoutBonusModal,
    handleOpenSelectCampaignModal,
    handleOpenPINValidationModal,
    handleOpenCampaignConfirmationModal,
    handleOpenPDVConfirmCPFModal,
    handleOpenPDVInputCPFModal,
    handleOpenNotFoundCustomerModal,
    handleOpenCreateCustomerModal,
    handleOpenFirstPurchaseModal,
    handleConsultAvailableBonus,
    handleConsultedBonus,

    handleCloseNotFoundCustomerModal,
    handleCloseCampaignConfirmationModal,
    handleCloseUpdateCustomerModal,
    handleCloseSaleWithoutBonusModal,
    handleCloseSelectCampaignModal,
    handleCloseIncompleteCustomerModal,
    handleCloseCancelUpdateCustomerModal,
    handleCloseFirstPurchaseModal,
    handleClosePINValidationModal,
    handleCloseBonusAvailableModal,
    handleCloseBonusRescuedModal,
    handleClosePDVConfirmCPFModal,
    handleClosePDVInputCPFModal,
    handleCancelProceedUpdateCustomer,
    handleCloseNotFoundCustomerModal,
    handleCloseCreateCustomerModal,

    handleRedeemPDVBonusClick,
    handleRedeemBonusClick,
    handleProceedIncompleteCustomer,
    handleCancelUpdateCustomer,
    handleProceedCancelCreateOrUpdateCustomer,
    handleCustomerFirstPurchaseProceed,
    handleCampaignAvailable,
    handleBackPDVInputCPFModal,
    handleProceedPDVInputCPF,
    handleProceedPDVConfirmCPF,
    handleCancelUpdateCustomerModal,
    handleProceedCancelCreateCustomer,
    handleProceedCreateCustomer,
    handleProceedCreateOrUpdateCustomer,

    handleInitCRMBonus,
    handleValidatePIN,
    handleResentPIN,
    handleUseBonusConfirmation,
    handleFinishSale,

    pdvSale,
    pdvValidatedCustomer,
  };
};
