import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './PanelAppointmentModal.css';
import { PanelAppointmentModalHeader } from './PanelAppointmentModal.styles';
import { TabSelector } from './components/TabSelector';
import { FirstTab } from './components/FirstTab';
import { ServiceHistoryTab } from './components/ServiceHistoryTab/ServiceHistoryTab';
import { ModalFooter } from '../ModalFooter';
import { usePanelAppointmentStore } from '../../store';

export function PanelAppointmentModal({
  open,
  handleClose,
  maintenance,
  mode,
}) {
  const [selectedTab, setSelectedTab] = useState(
    mode === 'service-history' ? 'service-history' : 'first-tab'
  );
  const { setTempAppointments } = usePanelAppointmentStore();

  useEffect(() => {
    setTempAppointments([]);
  }, []);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-90w appointments-modal modal-resizable"
    >
      <Modal.Header>
        <Modal.Title>
          <strong>Apontamento de Mecânico por Serviço</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Header>
        <PanelAppointmentModalHeader>
          <div className="flex column">
            <p>
              <strong>OS Nº: </strong>
              {maintenance.saleCode} - {maintenance.customer.Company_Name}
            </p>
            {maintenance?.vehicleObject?.id && (
              <div className="flex gap-4">
                <p>
                  <strong>Veículo: </strong>
                  {maintenance.vehicleObject.Model}
                </p>
                <p>
                  <strong>Ano Fab/Mod: </strong>
                  {maintenance.vehicleObject.Year_Manufacture}/
                  {maintenance.vehicleObject.Year_Model}
                </p>
                <p>
                  <strong>KM: </strong>
                  {maintenance.vehicleObject.Kilometers}
                </p>
              </div>
            )}
          </div>
        </PanelAppointmentModalHeader>
      </Modal.Header>
      <Modal.Body>
        <div>
          <TabSelector
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            mode={mode}
          />
        </div>

        {selectedTab === 'first-tab' ? (
          <FirstTab maintenance={maintenance} mode={mode} />
        ) : (
          <ServiceHistoryTab maintenance={maintenance} mode={mode} />
        )}
      </Modal.Body>

      <Modal.Footer>
        <ModalFooter
          maintenance={maintenance}
          mode={mode}
          handleClose={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
