export function formatCST(number) {
  if (Number.isInteger(number) && number >= 1 && number <= 99) {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
  } else {
    return number
  }
}
