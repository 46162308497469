import SearchInput from 'components/SearchInput';
import React from 'react';

const FinancingBankSlipFilter = ({
  query,
  handleChangeQuery,
  status,
  setStatus,
  partner,
  setPartnerId,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  history,
}) => {
  return (
    <>
      <section className="financing-bank-slip__row">
        <div className="new-financing-bank-slip__button">
          <button
            className="btn btn-sucesso fontSize-12"
            onClick={() => history.push('financing-bank-slip-config')}
            style={{
              height: '35px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            + Novo Cliente
          </button>
        </div>
        <div className="search-input-wrapper new-financing-bank-slip__button">
          <SearchInput
            placeholder="Pesquisar por CNPJ ou Nome/Razão Social"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </div>
        <fieldset>
          <span className="typography__text-2">Status:</span>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input fontSize-12"
          >
            <option value="">Ambos</option>
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Parceiro:</span>
          <select
            value={partner}
            onChange={(e) => setPartnerId(e.target.value)}
            className="form-control foco-input fontSize-12"
          >
            <option value="">Todos</option>
            <option value={1}>Koin</option>
            <option value={2}>Boleto Flex</option>
          </select>
        </fieldset>
      </section>

      <section className="financing-bank-slip__row">
        <fieldset>
          <span className="typography__text-2">Data Inicial:</span>
          <input
            type="date"
            className="form-control foco-input fontSize-12"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Data Final:</span>
          <input
            type="date"
            className="form-control foco-input fontSize-12"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
      </section>
    </>
  );
};

export default FinancingBankSlipFilter;
