import React, { useState, useEffect } from 'react'

import ReactTable from 'react-table'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import ConfirmationModal from './ConfirmationModal'
import Button from 'client/components/CustomButton/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { isEmail } from 'utils/validations'
import userRepository from 'repositories/Users'
import companyRepository from 'repositories/Companies'

export default function ChangeUserEmail({ company, onCancel }) {
  // States
  const [loading, setLoading] = useState(false)
  const [thirdCompany, setThirdCompany] = useState({})
  const [companyUsers, setCompanyUsers] = useState([])
  const [newEmail, setNewEmail] = useState('')
  const [userToUpdate, setUserToUpdate] = useState({})
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // Async functions
  async function loadCompanyUsers(companyId) {
    setLoading(true)
    try {
      const users = await userRepository.getByCompanyId(companyId)

      setCompanyUsers(users)
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os usuários, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  async function validations(userId, email) {
    setLoading(true)
    try {
      if (!isEmail(email)) {
        return toastr.warning(
          'Email inválido',
          'Insira um email válido e tente novamente'
        )
      }

      const user = companyUsers.find((usr) => usr.id === userId)
      const userAlreadyRegistered = await userRepository.getByEmailV2(email)

      if (userAlreadyRegistered) {
        let anotherCompany = ''
        if (userAlreadyRegistered.Company[0]) {
          anotherCompany = await companyRepository.getByCpfCnpj(
            userAlreadyRegistered.Company[0]?.Cpf_Cnpj ?
            userAlreadyRegistered.Company[0]?.Cpf_Cnpj :
            ''
          )

          setThirdCompany(anotherCompany)
        }
        else{
          return updateUserEmail(user, email, true)
        }
        setUserToUpdate(user)
        setNewEmail(email)
        return setShowConfirmationModal(true)
      }
      else {
        console.log('entrou no else')
        return updateUserEmail(user, email)
      }
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro inesperado tente novamente, se o erro persistir contate o suporte'
      )
    } finally {
      setLoading(false)
    }
  }

  async function updateUserEmail(user, email, forceUpdate) {
    try {
      await userRepository.updateEmail(user.id, { email, forceUpdate })

      setShowConfirmationModal(false)

      toastr.success(
        'Sucesso',
        `Email do usuário ${user.name} atualizado com sucesso`
      )
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao atualizar o email do usuário, tente novamente'
      )
    } finally {
      loadCompanyUsers(company.id)
    }
  }

  // UseEffects
  useEffect(() => {
    loadCompanyUsers(company.id)
  }, [])

  return (
    <>
      <Modal show animation onHide={onCancel} dialogClassName="modal-60w">
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Altere o email do usuário que deseja alterar:</p>
          <ReactTable
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              margin: '0 auto',
            }}
            data={companyUsers}
            columns={[
              {
                Header: 'Nome',
                accessor: 'name',
                width: 250,
              },
              {
                Header: 'Email Atual',
                accessor: 'email',
              },
              {
                Header: 'Novo Email',
                accessor: 'newEmail',
                Cell: (props) => (
                  <input
                    type='email'
                    contentEditable={true}
                    className='form-control foco-input'
                    value={props.value}
                    onChange={(e) => {
                      props.original.newEmail = e.target.value
                    }}
                  />
                ),
              },
              {
                accessor: 'id',
                Cell: (props) => (
                  <FontAwesomeIcon
                    title='Confirmar alteração de email'
                    icon={faCheckSquare}
                    style={{
                      color: '#5cb85c',
                      cursor: 'pointer',
                      fontSize: '1.8rem'
                    }}
                    aria-hidden='false'                  
                    onClick={() =>
                      validations(props.value, props.original.newEmail)
                    }
                  />
                ),
                maxWidth: 40,
              },
            ]}
            sortable
            defaultPageSize={5}
            loadingText='Carregando...'
            noDataText='Nenhum usuário encontrado'
            defaultSorted={[{ id: 'Name', desc: false }]}
            // Show in many users cases like in develop environment
            pageSizeOptions={companyUsers.length > 5 && [5, 10, 20]}
            showPaginationBottom={companyUsers.length > 5 ? true : false}
            previousText={companyUsers.length > 5 && 'Anterior'}
            pageText={companyUsers.length > 5 && 'Página'}
            ofText={companyUsers.length > 5 && 'de'}
            rowsText={companyUsers.length > 5 && 'linhas'}
            nextText={companyUsers.length > 5 && 'Próximo'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button fill bsStyle='danger' name='cancelar' onClick={onCancel}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      {showConfirmationModal && (
        <ConfirmationModal
          onCancel={() => setShowConfirmationModal(false)}
          handleSubmit={updateUserEmail}
          company={thirdCompany}
          user={userToUpdate}
          email={newEmail}
        />
      )}
    </>
  )
}
