import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Form from './Form';
import { toastr } from 'react-redux-toastr';

import NFesRepository from '../../../../repositories/NFes'

export default function DisableInvoiceModal({ onHide, onCancel, onSuccess, show, nfeSelected }) {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(values) {
    setLoading(true);
    await validations(values);
    setLoading(false);
  };

  async function validations(values) {
    const {
      companyId,
      correction,
      nfeId
    } = values;

    if (correction.length < 15) return toastr.warning('O campo de justificativa deve ter ao menos 15 caracteres.');

    await submit({
      companyId,
      number: Number(nfeSelected.code),
      correction,
      serie: nfeSelected.serie,
      environment: nfeSelected.environment,
      nfeId
    } )
  };

  async function submit(params) {
    try {
      await NFesRepository.correctionNfe(params)

      toastr.success('Carta de Correção emitida com sucesso')
      return onSuccess();
    } catch (err) {
      if (err.response?.data?.data?.length) return toastr.error(err.response?.data?.message);

      return toastr.error('Falha na requisição', 'Por favor, tente novamente mais tarde.');
    };
  };

  return (
    <Modal
      show={show}
      animation
    >
      <Form
        initialValues={{
          companyId: localStorage.getItem('ID_EMPRESA'),
          nfeId: nfeSelected?.id,
          correction: ''
        }}
        onCancel={onCancel}
        onHide={() => loading ? null : onHide()}
        onSubmit={handleSubmit}
        loading={loading}
        nfeSelected={nfeSelected}
      />
    </Modal>
  );
};