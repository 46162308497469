import * as yup from 'yup';
import { listSection } from 'v2/repositories/SectionRepository';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';
import { listDepartment } from 'v2/repositories/DepartmentRepository';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Produtos/Serviços',
      'Seção',
      `${isEditing ? 'Editar' : 'Nova'} Seção`,
    ],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.SECTION.BASE,
      null,
    ],
  };
};

export const validateSectionDescriptionUse = async (
  description,
  companyId,
  sectionId
) => {
  try {
    const sections = await listSection({
      query: description,
      companyId,
    });

    const sectionWithSameDescription = sections.rows.find(
      (section) =>
        section.description.toUpperCase() === description.toUpperCase() &&
        (sectionId ? +section.id !== +sectionId : true)
    );

    if (sectionWithSameDescription) {
      return false;
    }

    return true;
  } catch (err) {
    throw err;
  }
};

export const defaultFormValues = {
  id: null,
  description: '',
  observations: '',
  department: '',
  isActive: true,
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  department: yup.object().required(),
  isActive: yup.boolean().required(),
  observations: yup.string().notRequired(),
});

export const getDepartmentOptions = async (companyId) => {
  try {
    const res = await listDepartment({
      companyId,
      isActive: true,
      limit: 10000,
      page: 1,
    });

    const serializedOptions = res.rows.map((department) => ({
      value: department.id,
      label: department.description,
    }));

    return serializedOptions;
  } catch (err) {
    throw err;
  }
};
