import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Card from 'components/Card/Card.jsx';
import { format } from 'date-fns';

import '../styles.css';

import productsRepository from '../../../../../repositories/Products';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';

const NoBrandAndFamily = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingAllProds, setLoadingAllProds] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [noBrandsOrFamily, setNoBrandsOrFamily] = useState(
    'withoutBrandsAndFamilies'
  );
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadProducts();
  }, [page, pageSize, noBrandsOrFamily]);

  async function loadProducts() {
    setLoading(true);
    try {
      const response = await productsRepository.getAllPaginated({
        Company_id: companyId,
        page: page + 1,
        limit: pageSize,
        noBrandsOrFamily,
      });

      const formatedProduct = response.data.map((product) => {
        return {
          ...product,
          Code: Number(product.Code),
          createdAt: format(new Date(product.createdAt), 'dd/MM/yyyy'),
        };
      });

      const total = response.headers['x-total-count'];
      setPages(Math.floor(total / pageSize) + 1);

      setProducts(formatedProduct);
    } catch (err) {
      return toastr.warning(
        'Houve um erro ao carregar os produtos sem marca e família, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      content={
        <div id="page-stock-list">
          <header>
            <div>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Produto: </strong>
              </span>
              <select
                className="form-control foco-input select-families"
                style={{ marginBottom: '20px', width: '20%' }}
                onChange={(e) => setNoBrandsOrFamily(e.target.value)}
                value={noBrandsOrFamily}
              >
                <option value={'withoutBrandsAndFamilies'}>
                  Sem Marca e Família
                </option>
                <option value={'withoutBrands'}>Sem Marca</option>
                <option value={'withoutFamilies'}>Sem Família</option>
              </select>
            </div>
          </header>
          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={products}
              columns={[
                {
                  Header: 'Código',
                  accessor: 'Code',
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                },
                {
                  Header: 'Estoque',
                  accessor: 'Stock_Quantity',
                },
                {
                  Header: 'Ref. Fabricante',
                  accessor: 'Manufacturer_Ref',
                },
                {
                  Header: 'Data de Cadastro',
                  accessor: 'createdAt',
                },
              ]}
              defaultPageSize={10}
              pages={pages}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onPageChange={(page) => setPage(page)}
              manual
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              noDataText={`Não há produtos sem ${
                noBrandsOrFamily === 'withoutBrandsAndFamilies'
                  ? 'família e marca'
                  : noBrandsOrFamily === 'withoutBrands'
                  ? 'marca'
                  : 'familia'
              }`}
            />
          </main>
        </div>
      }
    />
  );
};

export default NoBrandAndFamily;
