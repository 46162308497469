import api from '../services/api'

const index = async (params) => {
  const response = await api.get('/financing-bank-slip', { params })
  return response.data
}

const show = async (id, params) => {
  const response = await api.get(`/financing-bank-slip/${id}`, { params })
  return response.data
}

const findClientsWithoutConfiguration = async (params) => {
  const response = await api.get(
    '/financing-bank-slip/find/clients-without-configuration',
    { params }
  )
  return response.data
}

const create = async (data) => {
  const response = await api.post('/financing-bank-slip', data)
  return response.data
}

const update = async (id, data) => {
  const response = await api.put(`/financing-bank-slip/${id}`, data)
  return response.data
}

const findByCompany = async (companyId, params) => {
  const response = await api.get(
    `/financing-bank-slip/by-company/${companyId}`,
    { params }
  )
  return response.data
}

const FinancialBankSlip = {
  index,
  show,
  findClientsWithoutConfiguration,
  create,
  update,
  findByCompany,
}

export default FinancialBankSlip
