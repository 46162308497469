import React, { useState, useEffect, useCallback, useMemo } from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';

import {
  useFieldArray,
  useFormContext,
  useWatch,
  Controller,
} from 'react-hook-form';

import {
  faTrashAlt,
  faShare,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CollapsibleSection from 'components/CollapsibleSection';
import ReactTableAutoPaginated from 'components/ReactTableAutoPaginated';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import ProvidersRepository from 'repositories/Providers';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

function QuotationProvidersStatusLabel({ data, quotationData }) {
  const handleSingleQuotationReport = (data) => {
    let providerId = data.providerId;
    let quotationResponseId = quotationData.QuotationResponses.find(
      (quotation) => quotation.providerId === providerId
    ).id;

    let quotationResponseIdHash;
    if (quotationResponseId) {
      quotationResponseIdHash = encrypt(
        quotationResponseId,
        '@OS-dig:quotationResponseId'
      );
    }

    window.open(
      window.location.origin +
        `/report/single-quotation-report?quotationResponseId=${quotationResponseIdHash}`,
      '_blank'
    );
  };

  switch (data.status) {
    case 'Cancelada':
      return 'Cancelada';
    case 'Não Enviada':
      return 'Não Enviada';
    case 'Aguardando Resposta':
      return (
        <>
          <FontAwesomeIcon
            icon={faClock}
            style={{
              color: '#232323',
            }}
          />{' '}
          &nbsp; Aguardando Resposta
        </>
      );
    case 'Respondida':
      return (
        <>
          <FontAwesomeIcon
            icon={faEnvelopeOpenText}
            style={{
              color: '#d9534f',
            }}
          />{' '}
          <a
            onClick={() => handleSingleQuotationReport(data)}
            target="_blank"
            style={{
              cursor: 'pointer',
              color: 'red',
              textDecoration: 'underline',
            }}
          >
            Visualizar Resposta
          </a>
        </>
      );
    default:
      return 'Não Enviada';
  }
}

export default function QuotationProviders() {
  const [loadingOptions, setIsLoadingOptions] = useState(true);
  const noOptionsSelectLabel = loadingOptions ? 'Carregando...' : 'Sem opções';
  const [providersOptions, setProvidersOptions] = useState([]);
  const [selectProviderAux, setSelectProviderAux] = useState(null);

  const [quotationProviders, setQuotationProviders] = useState([]);
  const providersColumns = useMemo(() => {
    return [
      {
        Header: 'CPF/CNPJ',
        accessor: 'cpfCnpj',
        Cell: (props) => (props.value ? cpfOrCnpjMask(props.value) : '-'),
      },
      {
        Header: 'Razão Social/Nome',
        accessor: 'companyName',
        Cell: (props) => props.value || '-',
      },
      {
        Header: () => (
          <span>
            Celular <span style={{ color: 'red' }}>*</span>
          </span>
        ),
        accessor: 'provider_phone',
        Cell: (props) => (
          <Controller
            name={`providers.${props.index}.provider_phone`}
            control={control}
            render={({ field }) => {
              return (
                <InputMask
                  mask={'(99) 99999-9999'}
                  className="form-control"
                  disabled={
                    isEditing && quotationToEdit.status !== 'Não Enviada'
                      ? true
                      : false
                  }
                  {...field}
                />
              );
            }}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) =>
          QuotationProvidersStatusLabel({
            data: props.original,
            quotationData: quotationToEdit,
          }),
      },
      {
        Header: 'Ação',
        Cell: (props) => {
          const disabledRemove = props.original.status !== 'Não Enviada';
          const showResendBtn = props.original.status === 'Aguardando Resposta';

          const osdigQuotationResponseLink =
            window.location.host +
            `/quotation/reply?quotationProviderId=${props.original.quotationProviderId}`;

          const message = `Prezado(a) Fornecedor,%0A%0Asomos da ${companyName} e precisamos cotar alguns produtos para compra.%0A%0ASegue o link da nossa cotação online: ${osdigQuotationResponseLink}`;
          const encodedMessage = encodeURIComponent(message);

          const link = `https://wa.me/55${props.original.provider_phone}?text=${encodedMessage}`;

          return (
            <>
              {showResendBtn && (
                <>
                  <a title={'Reenviar Cotação'} href={link} target="_blank">
                    <FontAwesomeIcon
                      icon={faShare}
                      style={{
                        color: '#232323',
                        cursor: 'pointer',
                      }}
                      aria-hidden="false"
                    />
                  </a>
                  &nbsp; &nbsp;
                </>
              )}
              <a
                title={'Remover'}
                onClick={(e) => {
                  e.preventDefault();
                  if (disabledRemove) return;
                  handleRemoveProviders([props.index]);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    color: disabledRemove ? 'grey' : 'red',
                    cursor: disabledRemove ? 'not-allowed' : 'pointer',
                  }}
                  aria-hidden="false"
                  title="Remover"
                />
              </a>
            </>
          );
        },
      },
    ];
  });

  const { getValues, control, setValue } = useFormContext();
  const { append, replace } = useFieldArray({
    control,
    name: 'providers',
  });

  const { companyId, companyName } = getValues();

  const loadingProvidersList = useWatch({
    control,
    name: 'loadingProvidersList',
  });

  const quotationToEdit = useWatch({
    control,
    name: 'quotationToEdit',
  });

  const isEditing = useWatch({
    control,
    name: 'isEditing',
  });

  const loadProvidersOptions = useCallback(async () => {
    setIsLoadingOptions(true);
    try {
      const data = await ProvidersRepository.getAllActiveByCompany(companyId);

      const sortedData = data.sort((a, b) => {
        if (a.companyName < b.companyName) {
          return -1;
        }
        if (a.companyName > b.companyName) {
          return 1;
        }
        return 0;
      });

      setProvidersOptions(
        sortedData.map((provider) => ({
          label: `${!!provider.cpfCnpj && cpfOrCnpjMask(provider.cpfCnpj)} - ${
            provider.companyName
          }`,
          value: provider,
          show: true,
        }))
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os fornecedores',
        'Tente novamente! Caso persista, entre em contato com o suporte.'
      );
    } finally {
      setIsLoadingOptions(false);
    }
  }, [companyId]);

  function handleHideProvidersOptions(providersIds) {
    setProvidersOptions(
      providersOptions.map((opt) => {
        if (providersIds.includes(opt.value.id)) {
          return { ...opt, show: false };
        } else {
          return { ...opt };
        }
      })
    );
  }

  function handleShowProvidersOptions(providersIds) {
    setProvidersOptions(
      providersOptions.map((opt) => {
        if (providersIds.includes(opt.value.id)) {
          return { ...opt, show: true };
        } else {
          return { ...opt };
        }
      })
    );
  }

  function handleSelectProviders(providers) {
    const formatedProviders = providers.map((provider) => {
      return {
        cpfCnpj: provider.value.cpfCnpj,
        companyName: provider.value.companyName,
        provider_phone:
          provider.value.provider_phone ||
          provider.value.Phones[0]?.Number_Phone2,
        status: provider.status || 'Não Enviada',
        providerId: provider.value.id,
        quotationProviderId: provider.quotationProviderId,
      };
    });

    const providersIds = providers.map((provider) => provider.value.id);
    handleHideProvidersOptions(providersIds);

    setQuotationProviders([...quotationProviders, ...formatedProviders]);
    append(formatedProviders);
  }

  function handleRemoveProviders(providersIndex) {
    const formValues = getValues();

    const providersToRemove = formValues.providers.filter((provider, index) => {
      return providersIndex.includes(index);
    });

    const providersToShowAgainIds = providersToRemove.map(
      (provider) => provider.providerId
    );

    if (isEditing) {
      const providersWithRegister = providersToRemove.filter(
        (provider) => provider.quotationProviderId
      );

      if (providersWithRegister.length > 0) {
        const quotationProvidersToRemoveIds = providersWithRegister.map(
          (provider) => provider.quotationProviderId
        );
        if (quotationProvidersToRemoveIds) {
          setValue('removedProviders', [
            ...formValues.removedProviders,
            ...quotationProvidersToRemoveIds,
          ]);
        }
      }
    }

    const updatedFields = formValues.providers.filter(
      (item, itemIndex) => !providersIndex.includes(itemIndex)
    );

    replace(updatedFields);
    setQuotationProviders(updatedFields);
    handleShowProvidersOptions(providersToShowAgainIds);
  }

  function handleLoadProvidersToEdit() {
    const providers = quotationToEdit.QuotationProviders;
    const responses = quotationToEdit.QuotationResponses;

    const formatedProviders = providers.map((quotationProvider) => {
      const response = responses.find(
        (resp) => resp.providerId === quotationProvider.providerId
      );

      const option = providersOptions.find(
        (opt) => opt.value.id === quotationProvider.providerId
      );

      let status;
      if (quotationToEdit.status === 'Cancelada') {
        status = 'Cancelada';
      } else if (quotationToEdit.status === 'Não Enviada') {
        status = 'Não Enviada';
      } else {
        if (response) {
          status = 'Respondida';
        } else {
          status = 'Aguardando Resposta';
        }
      }

      return {
        ...option,
        quotationProviderId: quotationProvider.id,
        value: {
          ...option.value,
          provider_phone: quotationProvider.provider_phone,
        },
        status,
      };
    });
    handleSelectProviders(formatedProviders);
  }

  useEffect(() => {
    loadProvidersOptions();
  }, [loadProvidersOptions]);

  useEffect(() => {
    if (isEditing && !loadingProvidersList && !loadingOptions) {
      handleLoadProvidersToEdit();
    }
  }, [isEditing, loadingProvidersList, loadingOptions]);

  return (
    <CollapsibleSection
      title="Fornecedores"
      isExpanded={true}
      handleExpand={null}
    >
      <div className="providers-section">
        <div className="providers-select">
          <Select
            className="react-select react-select-primary"
            classNamePrefix="react-select"
            placeholder={'Pesquisa por Razão Social ou CNPJ'}
            options={providersOptions.filter((opt) => opt.show)}
            value={selectProviderAux}
            styles={{
              control: (base) => ({
                ...base,
                height: '35px',
                minHeight: '35px',
                borderColor: '#E3E3E3',
                cursor: 'pointer',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              valueContainer: (provided) => ({
                ...provided,
                marginTop: '-6px',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                marginTop: '-6px',
              }),
            }}
            onChange={(e) => {
              handleSelectProviders([e]);
              setSelectProviderAux(null);
            }}
            isDisabled={
              isEditing && quotationToEdit.status !== 'Não Enviada'
                ? true
                : false
            }
            noOptionsMessage={() => noOptionsSelectLabel}
          />
        </div>
        <div style={{ width: '80%' }}>
          <ReactTableAutoPaginated
            data={quotationProviders}
            columns={providersColumns}
            defaultPageSize={5}
            loading={loadingProvidersList}
          />
        </div>
      </div>
    </CollapsibleSection>
  );
}
