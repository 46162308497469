import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import AlertModal from 'components/AlertModal/AlertModal'

export default function TributeTable({
  operationTaxes,
  loading,
  handleDeleteTribute,
  isDeleteTributeModalOpen,
  setIsDeleteTributeModalOpen,
  handleFilters,
  type,
  selectedOperationTax,
  setSelectedOperationTax,
  setIsAddTributeModalOpen,
}) {
  function handleDelete(operationTax) {
    setSelectedOperationTax(operationTax)
    setIsDeleteTributeModalOpen(true)
  }

  function handleEdit(operationTax) {
    setSelectedOperationTax(operationTax)
    setIsAddTributeModalOpen(true)
  }

  return (
    <>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '750px',
        }}
        data={operationTaxes
          .filter(handleFilters)
          .filter((item) => !type || item.type === type)}
        columns={[
          {
            Header: 'Tipo',
            accessor: 'type',
            width: 200,
            Cell: ({ value }) => {
              return value === 'O' ? 'Operação' : 'Tributação'
            },
          },
          {
            Header: 'Código',
            accessor: 'typeCode',
            width: 100,
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 350,
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 100,
            Cell: (props) => (
              <>
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                  onClick={() => {
                    handleEdit(props.value)
                  }}
                />
                <FontAwesomeIcon
                  title="Excluir Operação/Tributo"
                  cursor="pointer"
                  icon={faTrashAlt}
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    color: 'red',
                    marginLeft: '1px',
                  }}
                  onClick={() => {
                    handleDelete(props.value)
                  }}
                />
              </>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />

      {isDeleteTributeModalOpen && (
        <AlertModal
          show={isDeleteTributeModalOpen}
          message={
            <>
              <p>
                <strong>
                  Você tem certeza de que deseja excluir o tributo?
                </strong>
              </p>
              <p>
                O processo é irreversível. Ao excluir não poderá fazer a emissão
                de NFS-e com esse tributo, se estiver homologado o padrão. Será
                necessário efetuar o cadastro novamente.
              </p>
            </>
          }
          onCancel={() => setIsDeleteTributeModalOpen(false)}
          onSubmit={() => handleDeleteTribute(selectedOperationTax)}
          onHide={() => setIsDeleteTributeModalOpen(false)}
          loading={loading}
        />
      )}
    </>
  )
}
