import React from 'react'

const Search = () => (
  <i
    id='iconepesquisar'
    style={{ cursor: 'pointer', marginLeft: '-40px' }}
    className='fa fa-search'
  />
)

export default Search
