import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AdminLogin } from 'admin/views/Login';

const AdminInitialRoutes = () => {
  return (
    <Switch>
      <Route path="/admin-login" component={AdminLogin} />
      <Redirect path="/admin/*" to="/admin-login" />
    </Switch>
  );
};

export default AdminInitialRoutes;
