import React from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";

import FormNewProvider from './FormNewProvider'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import constants from '../../../../utils/constants'

const NewProvider = ({ match, history }) => {

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Fornecedores', !!match.params.id ? 'Editar Fornecedor' : 'Novo Fornecedor']}
            path={[constants.ROUTES.HOME, constants.ROUTES.PROVIDERS, null]}
          />
          <FormNewProvider
            providerId={match.params.id}
            onCancel={() => history.push(constants.ROUTES.PROVIDERS)}
            onSubmit={() => history.push(constants.ROUTES.PROVIDERS)}
          />
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(NewProvider)
