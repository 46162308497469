import React, { useEffect, useState } from 'react';
import { change, Field } from 'redux-form';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';

import RenderField from '../../../../../components/RenderField';
import constants from '../../../../../utils/constants';
import { currencyMask } from '../../../../../utils/masks';
import { useAuth } from '../../../../../contexts/auth';
import cashierBanksRepository from '../../../../../repositories/CashierBank';
import { specialChar4 } from '../../../../components/ToNormalize/ToNormalize';

const MainData = () => {
  const [cashierBanks, setCashierBanks] = useState([]);

  const {
    financialMovementTypeId,
    isCashierSupply,
    isCashierBleed,
    originCashierBankId,
    destinationCashierBankId,
    disabled,
    isClosed,
    isCanceled,
    isDevolution,
    value,
    haveBankConciliation,
  } = useSelector((state) => state.form.FinancialMovement.values);

  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const isTransfer =
    Number(financialMovementTypeId) ===
    constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER;

  useEffect(() => {
    loadActiveCashierBanks();
  }, []);

  useEffect(() => {
    if (financialMovementTypeId) {
      dispatch([
        change('FinancialMovement', 'description', undefined),
        change('FinancialMovement', 'originCashierBankId', undefined),
        change('FinancialMovement', 'cashierBankId', undefined),
        change('FinancialMovement', 'destinationCashierBankId', undefined),
        change('FinancialMovement', 'finality', undefined),
        change('FinancialMovement', 'value', undefined),
        change('FinancialMovement', 'observation', undefined),
      ]);
    }
  }, [financialMovementTypeId]);

  useEffect(() => {
    if (originCashierBankId === destinationCashierBankId) {
      dispatch(
        change('FinancialMovement', 'destinationCashierBankId', undefined)
      );
    }
  }, [originCashierBankId]);

  async function loadActiveCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      setCashierBanks(cashierBanks);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os caixa/bancos. Por favor, tente novamente'
      );
    }
  }

  return (
    <section className="main-data">
      <div>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          style={{ maxWidth: '230px', whiteSpace: 'nowrap' }}
        >
          <Field
            name="financialMovementTypeId"
            component={RenderField}
            label="Tipo de movimentação:"
            as="select"
            required
            disabled={
              disabled || isCashierBleed || isCashierSupply || isDevolution
            }
          >
            <option
              value={String(constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT)}
            >
              Movimentação
            </option>
            <option
              value={String(constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER)}
            >
              Transferência
            </option>
          </Field>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1}>
          <Field name="code" component={RenderField} label="Código:" disabled />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="date-column"
          style={{ maxWidth: '200px', whiteSpace: 'nowrap' }}
        >
          <Field
            name="createdAt"
            component={RenderField}
            label="Dt. de Criação:"
            disabled
          />
        </Col>
        {!!isClosed || !!isCanceled ? (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="date-column"
            style={{ maxWidth: '200px', whiteSpace: 'nowrap' }}
          >
            <Field
              name="endedIn"
              component={RenderField}
              label="Dt. de Movimentação:"
              disabled
            />
          </Col>
        ) : (
          <></>
        )}
        {!!isCanceled && (
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="date-column"
            style={{ maxWidth: '200px', whiteSpace: 'nowrap' }}
          >
            <Field
              name="canceledIn"
              component={RenderField}
              label="Dt. de Cancelamento:"
              disabled
            />
          </Col>
        )}
        {!!haveBankConciliation ? (
          <>
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ maxWidth: '220px', whiteSpace: 'nowrap' }}
            >
              <Field
                name="identifierConciliation"
                component={RenderField}
                label="Identificador de Conciliação:"
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name="dateConciliaton"
                component={RenderField}
                label="Dt. Conciliação:"
                disabled
              />
            </Col>
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name="description"
            component={RenderField}
            label="Descrição da movimentação:"
            maxLength={30}
            required
            normalize={specialChar4}
            disabled={disabled || isCashierBleed || isCashierSupply}
          />
        </Col>
        {isTransfer ? (
          <>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name="originCashierBankId"
                component={RenderField}
                label="Banco/Caixa Origem:"
                required
                as="select"
                disabled={disabled}
              >
                <option value="">Selecione</option>
                {cashierBanks.map((cashierBank) => (
                  <option value={cashierBank.id} key={cashierBank.id}>
                    {cashierBank.description}
                  </option>
                ))}
              </Field>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name="destinationCashierBankId"
                component={RenderField}
                label="Banco/Caixa Destino:"
                as="select"
                required
                disabled={disabled}
              >
                <option value="">Selecione</option>
                {originCashierBankId
                  ? cashierBanks
                      .filter(
                        (cashierBank) =>
                          String(cashierBank.id) !== originCashierBankId
                      )
                      .map((cashierBank) => (
                        <option value={cashierBank.id} key={cashierBank.id}>
                          {cashierBank.description}
                        </option>
                      ))
                  : cashierBanks.map((cashierBank) => (
                      <option value={cashierBank.id} key={cashierBank.id}>
                        {cashierBank.description}
                      </option>
                    ))}
              </Field>
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name="cashierBankId"
                component={RenderField}
                label="Banco/Caixa:"
                as="select"
                required
                disabled={disabled || isCashierBleed || isCashierSupply}
              >
                <option value="">Selecione</option>
                {cashierBanks.map((cashierBank) => (
                  <option value={cashierBank.id} key={cashierBank.id}>
                    {cashierBank.description}
                  </option>
                ))}
              </Field>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name="finality"
                component={RenderField}
                label="Finalidade:"
                as="select"
                required
                disabled={disabled || isCashierBleed || isCashierSupply}
              >
                <option value="">Selecione</option>
                <option value="1">Entrada</option>
                <option value="2">Saída</option>
              </Field>
            </Col>
          </>
        )}
        <Col xs={12} sm={12} md={2} lg={2}>
          <Field
            name="value"
            component={RenderField}
            label="Valor (R$):"
            {...currencyMask}
            required
            disabled={disabled || isDevolution}
          />
        </Col>
      </div>
      <div>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Field
            name="observation"
            component={RenderField}
            label="Observações:"
            as="textarea"
            maxLength={300}
            disabled={disabled}
          />
        </Col>
      </div>
    </section>
  );
};

export default MainData;
