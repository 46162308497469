import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import {
  phoneMask,
  currency,
  cnpjMask,
} from 'client/components/ToNormalize/ToNormalize';

const Header = ({
  reportType,
  titleStatus,
  provider,
  cashierBankDescription,
  initialDate,
  finalDate,
  dateType,
}) => (
  <View style={{ width: '70%', flexDirection: 'row' }}>
    <View style={{ flexDirection: 'column', width: '30%' }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Relatório: </Text>
        </View>
        <View>
          <Text>{reportType === 'synthetic' ? 'Sintético' : 'Analítico'}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Tipo de Data: </Text>
        </View>
        <View>
          <Text>
            {dateType === 'dueDate'
              ? 'Vencimento'
              : dateType === 'createdAt'
              ? 'Criação'
              : 'Pagamento'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '40%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Data Início: </Text>
        </View>
        <View style={{ marginRight: '5px' }}>
          <Text>
            {format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>Data Fim: </Text>
        </View>
        <View>
          <Text>
            {format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Status: </Text>
        </View>
        <View>
          <Text>
            {titleStatus === 'open'
              ? 'Aberto - Todos'
              : titleStatus === 'overdue'
              ? 'Aberto - Vencidos'
              : 'Finalizados'}
          </Text>
        </View>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'column',
        width: '30%',
        marginBottom: '5px',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Fornecedor: </Text>
        </View>
        <View>
          <Text>{provider}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <View>
          <Text style={styles.strong}>Banco/Caixa: </Text>
        </View>
        <View>
          <Text>
            {cashierBankDescription === '' ? 'Todos' : cashierBankDescription}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

const AccountsPayableAnalytical = ({
  reportType,
  titleStatus,
  provider,
  cashierBankDescription,
  responseData,
  displayNotes,
  initialDate,
  finalDate,
  dateType,
}) => {
  return (
    <Document
      title={`Relatorio-por-Contas-a-Pagar${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '30%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Títulos a Pagar</Text>
          </View>

          <Header
            reportType={reportType}
            titleStatus={titleStatus}
            provider={provider}
            cashierBankDescription={cashierBankDescription}
            initialDate={initialDate}
            finalDate={finalDate}
            dateType={dateType}
          />
        </View>

        <PayableAccountsValues
          totalizatorsByPeriod={responseData[0].totalsByPeriod}
          displayNotes={displayNotes}
          data={responseData}
          dateType={dateType}
          titleStatus={titleStatus}
        />
      </Page>
    </Document>
  );
};

const PayableTitle = ({ providerName, providerCpfCnpj, providerPhone }) => (
  <View>
    <View style={[styles.line]} />
    <View style={[styles.containerRow, { marginTop: '4px' }]}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          CPF/CNPJ:
        </Text>
        <Text style={[styles.text, { width: '14%' }]}>
          {providerCpfCnpj ? cnpjMask(providerCpfCnpj) : ''}
        </Text>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Razão Social/Nome:
        </Text>
        <Text style={[styles.text, { width: '33%' }]}>{providerName}</Text>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Telefone:
        </Text>
        <Text style={[styles.text, { width: '22%' }]}>
          {providerPhone ? phoneMask(providerPhone) : ''}
        </Text>
      </View>
    </View>

    <View style={{ marginTop: '8px' }}></View>
  </View>
);

const PayableAccountsValuesTitle = ({ dateType }) => (
  <View>
    <View style={[styles.line]} />
    <View>
      <Text style={[styles.title, { fontSize: '10px', marginTop: '8px' }]}>
        {dateType === 'dueDate'
          ? 'Vencimento'
          : dateType === 'createdAt'
          ? 'Criação'
          : 'Pagamento'}
      </Text>
    </View>
  </View>
);

const PayableAccountsValuesTableRow = ({
  dueDate,
  titles,
  displayNotes,
  total,
  titleStatus,
}) => {
  return (
    <>
      <View>
        <View>
          <Text style={{ width: '8%', fontSize: '8px' }}>
            {format(new Date(getDateOnlyFromDate(dueDate)), 'dd/MM/yyyy')}
          </Text>
        </View>

        {titles.map((title) => (
          <View>
            <View style={styles.containerRow}>
              <Text style={[styles.boldText, { width: '3%' }]}></Text>
              <Text
                style={{
                  width: '5%',
                  fontSize: '8px',
                }}
              >
                {title.titleCode}
              </Text>
              <Text style={{ width: '8%', fontSize: '8px' }}>
                {title.purchaseCode}
              </Text>
              <Text
                style={{
                  width: '5%',
                  fontSize: '8px',
                }}
              >
                {title.nfCode}
              </Text>
              <Text
                style={{
                  width: '6%',
                  fontSize: '8px',
                }}
              >
                {title.daysOverdue}
              </Text>
              <Text style={{ width: '25%', fontSize: '8px' }}>
                {title.provider}
              </Text>
              <Text
                style={{
                  width: '7%',
                  fontSize: '8px',
                }}
              >
                {title.dischargeDate
                  ? format(
                      new Date(getDateOnlyFromDate(title.dischargeDate)),
                      'dd/MM/yyyy'
                    )
                  : '' || '/    /'}
              </Text>
              <Text style={{ width: '11%', fontSize: '8px' }}>
                {title.cashierBank}
              </Text>
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.grossValue)}
              </Text>
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.addedValue)}
              </Text>
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.discountValue)}
              </Text>
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.amount)}
              </Text>
              <Text style={{ width: '7%', fontSize: '8px' }}>
                {currency(title.paidValue)}
              </Text>
            </View>

            <View style={styles.containerRow}>
              <Text style={{ width: '3%' }}></Text>
              <View
                style={{
                  width: '14%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    { width: '20%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  Conta:
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { width: '80%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  {title.accountPlan}
                </Text>
              </View>
              <Text style={{ width: '9%' }}></Text>
              <View
                style={{
                  width: '14%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={[
                    styles.boldText,
                    { width: '40%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  Telefone:
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    { width: '60%', fontSize: '7px', fontStyle: 'italic' },
                  ]}
                >
                  {phoneMask(title.providerPhone)}
                </Text>
              </View>
              {displayNotes ? (
                <View
                  style={{
                    width: '14%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={[
                      styles.boldText,
                      { width: '40%', fontSize: '7px', fontStyle: 'italic' },
                    ]}
                  >
                    Observações:
                  </Text>
                  <Text
                    style={[
                      styles.boldText,
                      { width: '60%', fontSize: '7px', fontStyle: 'italic' },
                    ]}
                  >
                    {title.observations}
                  </Text>
                </View>
              ) : (
                <View />
              )}
            </View>
          </View>
        ))}

        <View style={[styles.containerRow, { marginTop: '2px' }]}>
          <Text style={{ width: '83%' }}></Text>
          <View
            style={{
              width: '17%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={[styles.boldText]}>
              {titleStatus === 'finished'
                ? 'Saldo pago do dia: '
                : 'Saldo a pagar do dia: '}
            </Text>
            <Text style={[styles.boldText]}>{currency(total)}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const PayableAccountsValuesTableHeader = () => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '3%' }]}></Text>
        <Text style={[styles.boldText, { width: '5%' }]}>Título</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Venda</Text>
        <Text style={[styles.boldText, { width: '5%' }]}>NF</Text>
        <Text style={[styles.boldText, { width: '6%' }]}>Atraso</Text>
        <Text style={[styles.boldText, { width: '25%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Dt. Baixa</Text>
        <Text style={[styles.boldText, { width: '11%' }]}>Banco</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>R$ Bruto</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Acresc.</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Desc.</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>R$ Líquido</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Pago</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

const PayableAccountsValuesTotal = ({
  titlesQuantity,
  totalToPay,
  titleStatus,
}) => (
  <View style={styles.containerRow}>
    <Text style={[styles.boldText, { width: '23%' }]}>
      Qtd Títulos: <Text style={[styles.boldText]}>{titlesQuantity}</Text>
    </Text>
    <Text style={[styles.boldText]}>
      {titleStatus === 'finished' ? 'Saldos pagos: ' : 'Saldo a pagar: '}
      <Text style={[styles.boldText]}>{currency(totalToPay)}</Text>
    </Text>
  </View>
);

const TotalizatorByPeriod = ({ totalizatorsByPeriod, titleStatus }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Valores totais do período
        </Text>
      </View>
      <View style={[styles.line, { marginBottom: '2px' }]} />
      {totalizatorsByPeriod.rows.map((item) => (
        <View style={{ width: '100%' }}>
          <View style={styles.containerRow}>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'left',
                width: '10%',
              }}
            >
              {item.description}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {currency(item.value)}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontWeight: 'normal',
                fontFamily: 'Roboto',
                textAlign: 'center',
                width: '10%',
              }}
            >
              {item.quantity}
            </Text>
          </View>
        </View>
      ))}
      <View style={[styles.line, { width: '75%', marginTop: '2px' }]} />
      <View style={{ width: '100%', marginTop: '2px' }}>
        <View style={styles.containerRow}>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'left',
              width: '10%',
            }}
          >
            {titleStatus === 'finished' ? 'Saldo Pago: ' : 'Saldo a Pagar: '}
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              textAlign: 'center',
              width: '10%',
            }}
          >
            {currency(totalizatorsByPeriod.balancePayable)}
          </Text>
        </View>
        <View
          style={[
            styles.line,
            { width: '50%', marginTop: '2px', marginBottom: '30px' },
          ]}
        />
      </View>
    </View>
  );
};

const PayableAccountsValuesWrapper = ({
  dueDate,
  total,
  titles,
  displayNotes,
  titleStatus,
}) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <PayableAccountsValuesTableRow
        dueDate={dueDate}
        titles={titles}
        displayNotes={displayNotes}
        total={total}
        titleStatus={titleStatus}
      />
    </View>
  );
};

const PayableAccountsValues = ({
  totalizatorsByPeriod,
  displayNotes,
  data,
  dateType,
  titleStatus,
}) => {
  return (
    <View>
      <TotalizatorByPeriod
        totalizatorsByPeriod={totalizatorsByPeriod}
        titleStatus={titleStatus}
      />

      <View style={[styles.line]} />
      <View>
        <Text style={[styles.subTitle, { textAlign: 'center' }]}>
          Listagem de Títulos
        </Text>
      </View>

      {data.map((providerData, index) => {
        const { ProviderName, ProviderCpfCnpj, ProviderPhone, rows } =
          providerData;

        return (
          <View key={index}>
            <PayableTitle
              providerName={ProviderName}
              providerCpfCnpj={ProviderCpfCnpj}
              providerPhone={ProviderPhone}
            />
            <PayableAccountsValuesTitle dateType={dateType} />
            <PayableAccountsValuesTableHeader />
            {Object.keys(rows).map((key2, index2) => {
              const row2Data = rows[key2];
              return (
                <PayableAccountsValuesWrapper
                  key={index2}
                  dueDate={key2}
                  titles={row2Data.rows}
                  total={row2Data.dayTotalToPay}
                  displayNotes={displayNotes}
                  titleStatus={titleStatus}
                />
              );
            })}
            <View style={styles.line} />
            <PayableAccountsValuesTotal
              titlesQuantity={providerData.titlesQuantity}
              totalToPay={providerData.totalToPay}
              titleStatus={titleStatus}
            />
            <View style={styles.line} />
          </View>
        );
      })}
    </View>
  );
};

export default AccountsPayableAnalytical;

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
