import React from 'react';
import CardForm from 'client/components/CardForm';
import { useState } from 'react';
import '../styles.css';
import { addDays, format } from 'date-fns';

const NewPromotionalPriceData = ({
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  setDescription,
  register,
}) => {
  const [show, setShow] = useState(true);

  return (
    <CardForm
      style={{ marginTop: 30 }}
      show={show}
      title="Dados da Promoção (Obrigatório)"
      onClick={() => setShow(!show)}
    >
      <div className="new-promotional-price__row">
        <div className="new-promotional-price__column">
          <span>
            Data Inicial: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            type="date"
            className="form-control foco-input"
            onChange={(e) => setInitialDate(e.target.value)}
            value={initialDate}
            min={format(new Date(), 'yyyy-MM-dd')}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : null
            }
          />
        </div>

        <div className="new-promotional-price__column">
          <span>
            Data Final: <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            type="date"
            className="form-control foco-input"
            onChange={(e) => setFinalDate(e.target.value)}
            value={finalDate}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
          />
        </div>

        <div className="new-promotional-price__column">
          <span>
            Descrição <span style={{ color: 'red' }}>*</span>
          </span>
          <input
            style={{ width: '50rem' }}
            type="text"
            className="form-control foco-input"
            onChange={(e) => setDescription(e.target.value)}
            {...register('promotionalPriceTable.description')}
          />
        </div>
      </div>
    </CardForm>
  );
};

export default NewPromotionalPriceData;
