import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CurrencyInput from 'client/components/Currency'

const Totals = () => {
	const { bcICMS, valueICMS, bcPIS,
		valuePIS, bcCOFINS, valueCOFINS, total } = useSelector(state => state.form.cadastroSATCFe.values)

	return (
		<div>
			<Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
				<label>BC ICMS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={bcICMS}
					disabled
				/>
			</Col>
			<Col lg={2} md={2} sm={2} xs={12} style={{ padding: '1px 1px' }}>
				<label>Valor de ICMS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={valueICMS}
					disabled
				/>
			</Col>
			<Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
				<label>BC PIS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={bcPIS}
					disabled
				/>
			</Col>
			<Col lg={2} md={2} sm={2} xs={12} style={{ padding: '1px 1px' }}>
				<label>Valor de PIS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={valuePIS}
					disabled
				/>
			</Col>
			<Col lg={1} md={1} sm={1} xs={12} style={{ padding: '1px 1px' }}>
				<label>BC COFINS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={bcCOFINS}
					disabled
				/>
			</Col>
			<Col lg={2} md={2} sm={2} xs={12} style={{ padding: '1px 1px' }}>
				<label>Valor de COFINS</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={valueCOFINS}
					disabled
				/>
			</Col>
			<Col lg={2} md={2} sm={2} xs={12} style={{ padding: '1px 1px' }}>
				<label>Valor Total</label>
				<CurrencyInput
					prefix="R$ "
					className='form-control foco-input'
					value={total}
					disabled
				/>
			</Col>
		</div>
	);
};

export default Totals