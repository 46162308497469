import React, { useState } from 'react';

import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUser } from '@fortawesome/free-solid-svg-icons';

import PanelOperationModal from './PanelOperationModal';
import MaintenanceStatus from './MaintenanceStatus';
import { format } from 'date-fns';
import LoadingSpinnerFullHeight from './LoadingSpinnerFullHeight';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import { formatLicensePlate } from 'client/components/ToNormalize/ToNormalize';

const EmployeeTableCard = ({ name }) => {
  const splitedName = name.split(' ');

  return (
    <div className="employee-table-card">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>{`${splitedName[0]}`}</span>
    </div>
  );
};

const MaintenanceOperationModalButton = ({ maintenance }) => {
  const [isOperationModalOpen, setIsOperationModalOpen] = useState(false);
  return (
    <>
      <button
        className="service-order-code"
        onClick={() => setIsOperationModalOpen(true)}
      >
        {maintenance?.Sales?.Code}
      </button>

      {isOperationModalOpen && (
        <PanelOperationModal
          maintenance={maintenance}
          onCancel={() => setIsOperationModalOpen(false)}
        />
      )}
    </>
  );
};

const MaintenanceTableRow = ({ maintenance }) => {
  const vehicle = maintenance.Sales?.Vehicle;
  const getVehicleLabel = () => {
    if (vehicle?.vehicleTypeId === 1) {
      return `${formatLicensePlate(vehicle?.License_Plate)} - ${
        vehicle?.Model
      }`;
    } else if ([2, 3, 4].includes(vehicle?.vehicleTypeId)) {
      return `${vehicle?.serialIdentification} - ${vehicle?.Model}`;
    } else {
      return `${vehicle?.License_Plate || vehicle?.Maintenance_Object} - ${
        vehicle?.Model
      }`;
    }
  };

  return (
    <tr>
      <td style={{ width: '5%' }}>
        <MaintenanceOperationModalButton maintenance={maintenance} />
      </td>
      <td style={{ width: '25%' }}>
        <p>
          {maintenance.Sales.Customer.Trading_Name ||
            maintenance.Sales.Customer.Company_Name}
        </p>
        <p>
          <strong>{getVehicleLabel()}</strong>
        </p>
      </td>
      <td style={{ width: '15%', textAlign: 'center' }}>
        <div
          style={{
            display: 'block',
            marginBottom: '10px',
          }}
        >
          {maintenance?.MaintenanceEmployees.map((item) => (
            <EmployeeTableCard key={item.id} name={item.Employee.name} />
          ))}
        </div>
      </td>
      <td style={{ width: '25%' }}>
        <ul>
          {maintenance?.Sales.SalesItems.map((item) => (
            <li key={item.id}>{item.Description}</li>
          ))}
        </ul>
      </td>
      <td style={{ width: '10%' }}>
        <p style={{ fontSize: '11px', textAlign: 'center' }}>
          {maintenance.startDate &&
            format(new Date(maintenance.startDate), 'dd/MM/yyyy HH:mm')}
        </p>
      </td>
      <td style={{ width: '20%', textAlign: 'center' }}>
        <MaintenanceStatus
          status={maintenance.status}
          elapsedTime={maintenance.elapsedTime}
        />
        <br />
      </td>
    </tr>
  );
};

export default function Maintenances() {
  const {
    maintenances,
    isLoadingMaintenances,
    isLoadingPanelInformation,
    lastRefresh,
  } = useWorkshopPanel();

  return (
    <div className="workshop-panel-frame">
      <p className="workshop-panel-frame-title">
        <FontAwesomeIcon icon={faScrewdriverWrench} size={'lg'} />
        &nbsp; Manutenções
      </p>
      <div className="workshop-panel-frame-content">
        <div className="workshop-panel-maintenances">
          {isLoadingMaintenances || isLoadingPanelInformation ? (
            <LoadingSpinnerFullHeight />
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>OS</th>
                  <th style={{ width: '25%' }}>Cliente</th>
                  <th style={{ width: '15%', textAlign: 'center' }}>
                    Mecânicos
                  </th>
                  <th style={{ width: '25%' }}>Reparos</th>
                  <th style={{ width: '10%', textAlign: 'center' }}>Início</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {maintenances?.map((maintenance, index) => (
                  <MaintenanceTableRow
                    key={maintenance.id}
                    maintenance={maintenance}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="workshop-panel-last-update">
        Atualizado em {format(new Date(lastRefresh), 'dd/MM/yyyy')} -{' '}
        {format(new Date(lastRefresh), 'HH:mm')}
      </div>
    </div>
  );
}
