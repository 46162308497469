import React, { useState } from 'react'

import { Modal } from 'react-bootstrap'

import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { toastr } from 'react-redux-toastr'

import brandsRepository from '../../../../repositories/Brands'
import { useAuth } from 'contexts/auth'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

export const BikeManufacturerModal = ({
  loadBicycleManufacturers,
  onCancel,
  type,
}) => {
  const [loading, setLoading] = useState(false)
  const [manufacturer, setManufacturer] = useState('')

  const dispatch = useDispatch()

  const { companyId } = useAuth()

  const createManufacturer = async () => {
    const isValid = await validateFields()
    if (!isValid) return

    setLoading(true)
    try {
      const lastCode = await brandsRepository.getLastCode(companyId)

      const newBrand = {
        Code: lastCode + 1,
        Description: manufacturer,
        Company_id: companyId,
        Type: type,
      }

      const brand = await brandsRepository.create(companyId, newBrand)

      toastr.success(
        'Cadastro efetuado com sucesso',
        'A montadora foi cadastrada e pode ser usada em seu cadastro'
      )

      loadBicycleManufacturers()
      dispatch(change('vehicle', 'Brand', brand.data.id))
      onCancel()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const validateFields = async () => {
    if (!manufacturer) {
      toastr.warning(
        'Não foi possível fazer o cadastro',
        'Preencha todos os campos para realizar o cadastro da montadora'
      )

      return false
    }

    setLoading(true)
    try {
      const manufacturerExists =
        await brandsRepository.getByCompanyAndDescription(
          companyId,
          manufacturer
        )

      if (manufacturerExists?.status === true) {
        toastr.warning(
          'Não foi possível fazer o cadastro',
          'Já existe essa montadora para esse tipo de veículo, verifique e tente novamente'
        )

        return false
      }

      return true
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={true} onHide={onCancel} dialogClassName="modal-35w">
      <Modal.Header>
        <Modal.Title>
          <strong>Cadastro de Montadora</strong>
          <span
            style={{
              position: 'absolute',
              top: '30px',
              right: '10px',
              color: 'red',
              fontSize: '12px',
              marginLeft: 'auto',
            }}
          >
            *Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            <strong>
              Montadora: <span style={{ color: 'red' }}>*</span>
            </strong>
          </label>
          <input
            type="text"
            className="form-control foco-input"
            value={manufacturer}
            maxLength="20"
            onChange={(e) => setManufacturer(e.target.value)}
            style={{
              width: '220px',
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <FormSubmitButtons
            onCancel={onCancel}
            onSubmit={createManufacturer}
            loading={loading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}
