import api from '../services/api'
import config from '../config'

const getProductsCurveReport = async (companyId, params) => {
  const { data } = await api.get(config.endpoint + `report/sales/abc-curve/${companyId}`, {params})
  return data
}

export default {
  getProductsCurveReport
}
