import React, { createContext, useState, useEffect, useContext } from 'react';
import { useRecoilState } from 'recoil';

import usersRepository from '../repositories/Users';
import companiesRepository from '../repositories/Companies';
import { isUserLoggedOnOtherDevice } from '../auth';
import { usePlanSignatureContext } from './plan-signature';
import { useModulesContext } from './modules';

import {
  companyBranch as companyBranchAtom,
  companySegments as companySegmentsAtom,
} from '../storage/companyDetailsStorage';

import api from 'services/api';
import AccessPermissionsRepository from 'v2/repositories/AccessPermissionsRepository';
import { authenticateUser } from 'v2/repositories/AuthenticationRepository';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';

const ClientContext = createContext({
  signature: null,
  userId: null,
  userName: '',
  userEmail: '',

  companyId: null,
  company: null,
  isSigned: false,
  loading: true,
  isCompanyActive: true,
  isUserSessionClosed: false,
  nonCompliance: false,

  signIn: ({ email, password, developerMode, changeOperator }) => {},
  signOut: () => {},
  checkUsersDevice: () => {},
  loadUser: () => {},
  clearToken: () => {},
  loadCompany: () => {},
  isUserAllowed: () => {},
});

export default function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [isUserSessionClosed, setIsUserSessionClosed] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [userDoesntHaveProfile, setUserDoesntHaveProfile] = useState(false);

  const [companyId, setCompanyId] = useState(null);
  const [company, setCompany] = useState(null);
  const [isCompanyActive, setCompanyIsActive] = useState(true);

  const [nonCompliance, setNonCompliance] = useState(false);

  const { loadSignatureByUser, clearSignatureData } = usePlanSignatureContext();
  const { getModulesData } = useModulesContext();
  const { getConfigurationsData } = useConfigurationsContext();

  const [companyBranch, setCompanyBranch] = useRecoilState(companyBranchAtom);
  const [companySegments, setCompanySegments] =
    useRecoilState(companySegmentsAtom);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (!companyId || nonCompliance) return;
    validateDequimentCustomer(companyId);
  }, [companyId]);

  useEffect(() => {
    if (!company) return;

    getModulesData(company);
    getConfigurationsData(company);
  }, [company]);

  const loadUser = async () => {
    const token = localStorage.getItem('JWT');
    api.defaults.headers.common['x-access-token'] = token;

    const userId = localStorage.getItem('ID_USUARIO');

    if (!userId) {
      setLoading(false);
      return;
    }

    const permissionsHash = localStorage.getItem('USER_PERMISSIONS');

    const permissionsObject = await decrypt(
      permissionsHash?.replace('"', ''),
      '@OSD-userPermissions'
    );

    const permissions = JSON.parse(permissionsObject);

    await loadSignatureByUser(userId);

    try {
      const user = await usersRepository.getById(userId);

      await setUserData({ ...user, permissions });
    } catch (err) {
      console.error(err);
      signOut();
      setLoading(false);
    }
  };

  const setUserData = async (user) => {
    setUser(user);
    setUserEmail(user.Email);
    setUserId(user.id);
    setUserName(user.Name?.split(' ')[0]);

    if (!!user.Company?.length) {
      try {
        const company = await companiesRepository.show(user.Company[0].id);
        setCompanyData(company);

        const segments = company.Segment.map((segment) => segment.id);
        setCompanySegments(segments);

        setCompanyBranch(company.Branch_id);
      } catch (err) {
        console.error(err);
      }
    }

    if (
      user.Profiles?.description == 'ADMINISTRADOR' &&
      user.Profiles?.companyId == null
    ) {
      setIsAdministrator(true);
    }

    const permissions = user.permissions;

    const groupFeatures = permissions?.groupFeatures?.map((groupFeature) => ({
      ...groupFeature,
      mainFeatures: undefined,
    }));

    const mainFeatures = permissions?.groupFeatures?.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.mainFeatures.map((mainFeature) => ({
          ...mainFeature,
          subFeatures: undefined,
        })),
      ],
      []
    );

    const subFeatures = permissions?.groupFeatures?.reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.mainFeatures
          .map((mainFeature) => [...mainFeature.subFeatures])
          .reduce((acc2, curr2) => [...acc2, ...curr2], []),
      ];
    }, []);

    setUserPermissions({
      groupFeatures,
      mainFeatures,
      subFeatures,
      loaded: true,
    });

    localStorage.setItem('NOME_USUARIO', user.Name);
    localStorage.setItem('EMAIL_USUARIO', user.Email);
    localStorage.setItem('ID_USUARIO', user.id);

    setLoading(false);
  };

  const loadCompany = async () => {
    if (!companyId) {
      return;
    }

    try {
      const company = await companiesRepository.show(companyId);
      setCompanyData(company);

      const segments = company.Segment.map((segment) => segment.id);
      setCompanySegments(segments);

      setCompanyBranch(company.Branch_id);
    } catch (err) {
      console.error(err);
    }
  };

  const setCompanyData = (company) => {
    setCompanyId(company.id);
    setCompany(company);
    setCompanyIsActive(company.isActive);

    localStorage.setItem('ID_EMPRESA', company.id);
  };

  const doNotShowOnboardingModalAgain = (value) => {
    setUser({
      ...user,
      DoNotShowAgain: value,
    });
  };

  const checkUsersDevice = async () => {
    if (!userId) return;

    const hasBeenLogged = await isUserLoggedOnOtherDevice(userId);
    if (hasBeenLogged) {
      setIsUserSessionClosed(true);
    }
  };

  const signIn = async ({ email, password, developerMode, changeOperator }) => {
    const { data } = await authenticateUser({
      email,
      password,
      developerMode,
      changeOperator,
    });
    const { user, token, permissions } = data;

    const encryptedPermissions = encrypt(
      JSON.stringify(permissions),
      '@OSD-userPermissions'
    );

    localStorage.clear();
    localStorage.setItem('TOKEN', user.Device_Id);
    localStorage.setItem('JWT', token);
    localStorage.setItem(
      'USER_PERMISSIONS',
      JSON.stringify(encryptedPermissions)
    );

    api.defaults.headers.common['x-access-token'] = token;

    await loadSignatureByUser(user.id);
    await setUserData({ ...user, permissions });
  };

  const signOut = () => {
    localStorage.clear();

    setUser(null);
    setUserEmail('');
    setUserName('');
    setUserId(null);
    setUserPermissions({
      groupFeatures: [],
      mainFeatures: [],
      subFeatures: [],
      loaded: false,
    });
    setIsAdministrator(false);
    setUserDoesntHaveProfile(false);

    setCompanyId(null);
    setCompany(null);
    setCompanyIsActive(true);

    clearSignatureData();
  };

  async function validateDequimentCustomer(companyId) {
    try {
      localStorage.removeItem('alreadyShownComplienceModal');

      const { nonCompliance } = await usersRepository.validateComplience(
        companyId
      );

      setNonCompliance(nonCompliance);
    } catch (err) {
      console.error(err);
    }
  }

  const clearToken = async (userId) => {
    await usersRepository.update(userId, {
      Device_Id: '',
    });
  };

  const isUserAllowed = (item) => {
    if (item.title === 'Início') return true;

    if (userDoesntHaveProfile) return true;

    if (isAdministrator) return true;

    const doesUserHavePermission = true;

    return doesUserHavePermission;
  };

  return (
    <ClientContext.Provider
      value={{
        isSigned: !!user,
        company,
        loading,
        companyId,
        user,
        userId,
        userEmail,
        userName,
        isCompanyActive,
        isUserSessionClosed,
        nonCompliance,

        checkUsersDevice,
        signIn,
        signOut,
        loadUser,
        clearToken,
        loadCompany,
        isUserAllowed,
        doNotShowOnboardingModalAgain,
        userPermissions,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(ClientContext);

  return context;
}
