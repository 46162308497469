import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { customChecklistSheetStyles } from '../../CustomChecklistSheet.styles';

const ObservationsCard = ({ sale }) => {
  return (
    <View
      break
      style={[customChecklistSheetStyles.cardBorder, { marginTop: '10px' }]}
    >
      <View style={{ flexDirection: 'row', height: 75 }}>
        <View style={[customChecklistSheetStyles.observationsCard]}>
          <Text style={customChecklistSheetStyles.bold}>Observações</Text>
          <Text>{sale.Comments}</Text>
        </View>

        <View style={[customChecklistSheetStyles.line2]} />

        <View style={[customChecklistSheetStyles.observationsCard]}>
          <Text style={customChecklistSheetStyles.bold}>Defeitos</Text>
          <Text>{sale.Defects}</Text>
        </View>

        <View style={[customChecklistSheetStyles.line2]} />

        <View style={[customChecklistSheetStyles.observationsCard]}>
          <Text style={customChecklistSheetStyles.bold}>Avarias</Text>
          <Text>{sale.Malfunctions}</Text>
        </View>
      </View>
    </View>
  );
};

export function ChecklistObservations({ displayNotes, sale }) {
  {
    return (
      <View>
        {displayNotes && <ObservationsCard sale={sale} />}

        <View style={customChecklistSheetStyles.footer}>
          <Text style={customChecklistSheetStyles.bold}>
            Data/Hora: ______/______/______ ___________
          </Text>
          <Text style={customChecklistSheetStyles.bold}>
            Ass. Cliente: _________________________________________
          </Text>
        </View>
      </View>
    );
  }
}
