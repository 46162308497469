import React from 'react';

import { InputCurrency } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';

import {
  AddCategoryButton,
  Card,
  CardContent,
  CardTitle,
  DeleteButton,
  ProfitTable,
} from '../ProfitPanel.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTimes } from '@fortawesome/free-solid-svg-icons';

export function CostCard({
  icon,
  title,
  handleAddItem,
  handleRemoveItem,
  handleChangeValue,
  tableItems,
  objectName,
}) {
  return (
    <Card>
      <CardTitle>
        <FontAwesomeIcon icon={icon} />
        {title}
      </CardTitle>

      <CardContent>
        <ProfitTable>
          <thead>
            <tr>
              <th>Conta</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {tableItems.map((item, index) => (
              <tr key={item.id || item.description}>
                <td>
                  {item.manual ? (
                    <InputText
                      height={30}
                      value={item.description}
                      onChange={(e) =>
                        handleChangeValue({
                          object: objectName,
                          attribute: 'description',
                          index,
                          value: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <span style={{ textTransform: 'uppercase' }}>
                      {item.description}
                    </span>
                  )}
                </td>
                <td className="flex row center gap-050">
                  <InputCurrency
                    value={item.averageValue}
                    height={30}
                    onChange={(value) =>
                      handleChangeValue({
                        object: objectName,
                        attribute: 'averageValue',
                        index,
                        value,
                      })
                    }
                  />

                  {item.manual && (
                    <DeleteButton>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() =>
                          handleRemoveItem({
                            object: objectName,
                            index,
                          })
                        }
                      />
                    </DeleteButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </ProfitTable>
        <AddCategoryButton onClick={handleAddItem}>
          <FontAwesomeIcon icon={faAdd} />
          Adicionar Conta
        </AddCategoryButton>
      </CardContent>
    </Card>
  );
}
