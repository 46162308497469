import React from 'react';
import { InputLabelStyled } from './styles';

export const InputLabel = ({ required = false, children, ...props }) => {
  return (
    <InputLabelStyled {...props}>
      {children} {required && <span style={{ color: 'red' }}>*</span>}
    </InputLabelStyled>
  );
};
