import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import { useAuth } from 'contexts/auth';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';
import { SalesByTypeDocument } from './SalesByTypeDocument';

import salesRepository from '../../../repositories/Sales';

export const SalesByTypeReport = ({ location, history }) => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  // const [hasLoaded, setHasLoaded] = useState(false)

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [showItems, setShowItems] = useState(false);
  const [saleStatus, setSaleStatus] = useState('');
  const [saleType, setSaleType] = useState('');

  const [totalsByType, setTotalsByType] = useState([]);
  const [salesList, setSalesList] = useState([]);

  useEffect(() => {
    if (!!companyId) loadQuery();
  }, [location.search, companyId, history]);

  const loadQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    const initialDate = searchParams.get('initialDate');
    const finalDate = searchParams.get('finalDate');
    const saleType = searchParams.get('saleType');
    const saleStatus = searchParams.get('saleStatus');
    const showItems = searchParams.get('showItems');

    let params = {
      initialDate,
      finalDate,
      saleType,
      saleStatus,
      showItems: showItems === '1' ? true : false,
    };

    setInitialDate(initialDate);
    setFinalDate(finalDate);
    setShowItems(params.showItems);
    setSaleStatus(params.saleStatus);
    setSaleType(params.saleType);
    loadReport(params);
  };

  const loadReport = async (params) => {
    setLoading(true);

    try {
      const { salesReportTable } =
        await salesRepository.getSalesByTypeReportUIVersion({
          initialDate: params.initialDate,
          finalDate: params.finalDate,
          saleType: params.saleType,
          saleStatus: params.saleStatus,
          companyId,
        });

      const salesList = await salesRepository.getSalesByTypeReportSheetVersion({
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        saleType: params.saleType,
        saleStatus: params.saleStatus,
        showItems: params.showItems,
        companyId,
      });

      if (params.showItems === false) {
        const formatedSalesList = formatSaleListByStatus(salesList);
        setSalesList(formatedSalesList);
      } else {
        setSalesList(salesList);
      }

      setTotalsByType(salesReportTable);
    } catch (error) {
      setLoading(false);
      setHasError(true);
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  const formatSaleListByStatus = (salesList) => {
    const salesListByStatus = salesList.reduce((acc, sale) => {
      const { status } = sale;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(sale);
      return acc;
    }, {});

    return salesListByStatus;
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <SalesByTypeDocument
            initialDate={initialDate}
            finalDate={finalDate}
            totalsByType={totalsByType}
            salesList={salesList}
            showItems={showItems}
            saleStatus={saleStatus}
            saleType={saleType}
          />
        </PDFViewer>
      </main>
    </div>
  );
};
