import CustomButton from 'client/components/CustomButton/CustomButton';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { getInvoiceError } from 'v2/repositories/InvoiceErrorsRepository';

export function InvoiceErrorSolutionModal({
  open,
  onClose,
  situationCode,
  errorInformation,
  ...props
}) {
  return (
    <Modal
      show
      animation
      dialogClassName="modal-40w"
      onHide={onClose}
      {...props}
    >
      <Modal.Header>
        <Modal.Title>
          <strong>Solução - Rejeição {situationCode}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex column gap-050">
          <div>
            <strong>Descrição:</strong> <br />
            {errorInformation.description}
          </div>
          <br />
          <div>
            <strong>Por que ocorre?</strong> <br />
            <span>{errorInformation.cause}</span>
          </div>
          <br />
          <div>
            <strong>Como resolver?</strong> <br />
            <span>{errorInformation.solution}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end">
          <CustomButton
            bsStyle="danger"
            name="Cancelar"
            style={{
              fontSize: '12px',
            }}
            onClick={() => {
              onClose();
            }}
            fill
          >
            Fechar
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
