import { create } from 'zustand';
import { MaintenanceServicesRepository } from 'v2/repositories/MaintenanceServicesRepository';

export const usePanelAppointmentStore = create((set, get) => ({
  tempAppointments: [],
  setTempAppointments: (appointments) =>
    set({ tempAppointments: appointments }),

  handleStartServices: async (maintenanceId) => {
    try {
      const { tempAppointments } = get();

      const serializedAppointments = tempAppointments?.map((appointment) => ({
        employeeId: appointment.employee.id,
        saleItemId: appointment.service.id,
      }));

      await MaintenanceServicesRepository.create({
        maintenances: serializedAppointments,
        maintenanceId,
      });
    } catch (err) {
      throw err;
    }
  },

  handleStopServices: async (maintenanceId) => {
    try {
      const { tempAppointments } = get();

      const serializedAppointments = tempAppointments?.map((appointment) => ({
        id: appointment.id,
        stopReason: appointment.stopReason,
        employeeId: appointment.employeeId,
      }));

      await MaintenanceServicesRepository.stop(maintenanceId, {
        maintenanceServices: serializedAppointments,
      });
    } catch (err) {
      throw err;
    }
  },

  handleFinishServices: async (maintenanceId) => {
    try {
      const { tempAppointments } = get();

      const serializedAppointments = tempAppointments?.map((appointment) => ({
        id: appointment.id,
        start: appointment.start,
        saleItemId: appointment.saleItemId,
        employeeId: appointment.employeeId,
      }));

      await MaintenanceServicesRepository.finish(maintenanceId, {
        maintenanceServices: serializedAppointments,
      });
    } catch (err) {
      throw err;
    }
  },
}));
