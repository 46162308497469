import Button from 'client/components/CustomButton/CustomButton'
import React from 'react'
import { Modal } from 'react-bootstrap'

export default function ChangeCompanyDocumentConfirmModal({
  company,
  actualCompanyId,
  onCancel,
  handleSubmit,
}) {
  return (
    <Modal show animation onHide={onCancel}>
      <Modal.Header closeButton={onCancel}>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '14px' }}>
        <strong>
          Não foi possível realizar a troca de CPF/CNPJ
        </strong>

        <br />
        <br />

        <div className='company-information-container'>
          <span>
            O {company.cpfCnpj.length === 11 ? 'CPF' : 'CNPJ'} já está vinculado
            a outra empresa
            <br />A assinatura dessa empresa está: {company.signatureStatus}
            <br />O plano dessa empresa é: {company.plan}
          </span>
        </div>

        <br />
        <span style={{ margin: '12px 0' }}>
          Você deseja realiza a troca mesmo assim?
        </span>

        <br />
        <br />

        <div style={{
          color: '#d43f3a',
          fontSize: '12px',
          margin: '0 auto',
          textAlign: 'center'
        }}>
          A troca inutilizará o acesso a outra empresa através desse usuário
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle='danger' name='cancelar' onClick={onCancel}>
          Não
        </Button>
        <Button
          fill
          bsStyle='info'
          name='update-document'
          onClick={() => handleSubmit(company.cpfCnpj, true)}
        >
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
