import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'client/components/CustomButton/CustomButton.jsx'

import { CustomerHistory } from '../../components/CustomerHistory'

export const CustomerHistoryModal = ({ onHide, onCancel, customer = {} }) => {

  const vehicles = customer.Vehicle || []

  return (
    <Modal
      show
      dialogClassName="modal-70w"
      onHide={onHide}
      animation
    >
      <Modal.Header>
        <Modal.Title>
          <strong>Histórico do Cliente</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerHistory customer={customer} vehicles={vehicles} />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={() => onCancel()} fill>Voltar</Button>
      </Modal.Footer>
    </Modal>
  )
}