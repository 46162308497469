import React from 'react'
import { Modal } from 'react-bootstrap'

import './styles.css'

const { Header, Title, Body, Footer } = Modal

const ChooseMateraModeModal = ({ show, onCancel, onSubmit, handleSubmit }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Header>
        <Title>
          <strong>OS Digital</strong>
        </Title>
      </Header>
      <Body>
        <strong>Escolha a opção desejada</strong>
        <br />
        <br />
        <span>
          Você poderá cadastrar a empresa e não criar conta nesse momento. A conta poderá ser criada posteriormente.
        </span>
        <br />
        <section className='tray-option-buttons'>
          <button
            className='btn'
            style={{
              backgroundColor: '#428BCA',
              borderColor: '#428BCA',
              opacity: 1,
              color: 'white',
            }}
            onClick={handleSubmit((values) => onSubmit(values, false))}        
          >
            Salvar Cadastro e Não Criar Conta
          </button>
          <button
            className='btn'
            style={{
              backgroundColor: '#5CB85C',
              borderColor: '#5CB85C',
              opacity: 1,
              color: 'white',
            }}
            onClick={handleSubmit((values) => onSubmit(values, true))}
          >
            Salvar Cadastro e Criar Conta
          </button>
        </section>        
      </Body>
      <Footer>
        <button
          className='btn'
          style={{
            backgroundColor: '#D9534F',
            borderColor: '#D9534F',
            opacity: 1,
            color: 'white',
          }}
          onClick={onCancel}
        >
          Voltar
        </button>
      </Footer>
    </Modal>
  )
}

export default ChooseMateraModeModal
