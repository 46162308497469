import React, { useEffect } from 'react';
import { Grid, Row } from 'react-bootstrap';

import HomeFormOfPayment from './FormOfPayment';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const FormOfPayment = () => {
  useEffect(() => {
    if (localStorage.getItem('selectedPath') === 'client/NovaVenda') {
      localStorage.setItem('selectedPath', 'client/NovaVenda');
    } else {
      localStorage.setItem('selectedPath', 'client/payment-forms');
    }
  }, []);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastros', 'Vendas', 'Formas de Pagamento']}
            path={['home', null, null, null]}
          />
          <HomeFormOfPayment />
        </Row>
      </Grid>
    </div>
  );
};

export default FormOfPayment;
