import React, { useState, useEffect } from 'react'
import useDebounce from 'hooks/useDebounce'
import { format } from 'date-fns'

import { withRouter } from 'react-router-dom'
import InventoryCountFilter from './InventoryCountFilter'
import InventoryCountTable from './InventoryCountTable'
import inventoryCountRepository from 'repositories/InventoryCount'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'
import { startOfMonth } from 'date-fns'
import { getDateOnlyFromDate } from 'utils/dateHelpers'

const firstDayOfMonth = format(
  startOfMonth(getDateOnlyFromDate(new Date())),
  'yyyy-MM-dd'
)
const actualDate = format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')

const InventoryCountMain = ({ history }) => {
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  const [query, setQuery] = useState('')
  const [status, setStatus] = useState('')
  const [dateType, setDateType] = useState('CreatedAt')
  const [initialDate, setInitialDate] = useState(firstDayOfMonth)
  const [finalDate, setFinalDate] = useState(actualDate)

  const [inventoryCountList, setInventoryCountList] = useState([])

  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [queryDebounced, setQueryDebounced] = useState('')
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600)

  const [dataToExport, setDataToExport] = useState([])
  const [isExportReady, setIsExportReady] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const handleChangeQuery = (value) => {
    setQuery(value)
    debouncedSaveQuery(value)
  }

  const loadInventoryCount = async () => {
    setLoading(true)
    setIsExportReady(false)
    setDataToExport([])
    try {
      if (initialDate === '' || finalDate === '') return setLoading(false)

      const params = {
        query,
        limit: pageLimit,
        page: currentPage + 1,
        initialDate,
        finalDate,
        dateType,
        status,
        companyId,
      }

      const response = await inventoryCountRepository.getAll(params)

      const inventories = response.inventories.map((inventory) => {
        return {
          ...inventory,
          qtdItems: inventory.InventoryProducts.length,
        }
      })

      setInventoryCountList(inventories)
      setTotalPages(Math.ceil(response.count / pageLimit))
      setLoading(false)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Não foi possível carregar a lista.',
        'Por favor, tente novamente'
      )
    }
  }

  async function handleLoadDataToExport() {
    setIsExportReady(false)

    setIsLoadingExport(true)
    try {
      const params = {
        query,
        initialDate,
        finalDate,
        dateType,
        status,
        companyId,
      }

      const data = await inventoryCountRepository.getAll(params)

      const inventories = data.inventories.map((inventory) => {
        return {
          ...inventory,
          createdAt: format(
            getDateOnlyFromDate(new Date(inventory.createdAt)),
            'dd/MM/yyyy'
          ),
          finishDate: format(
            getDateOnlyFromDate(new Date(inventory.finishDate)),
            'dd/MM/yyyy'
          ),
          qtdItems: inventory.InventoryProducts.length,
        }
      })

      setDataToExport(inventories)
      setIsLoadingExport(false)
      setIsExportReady(true)

      toastr.success(
        'Exportação preparada',
        'Clique no botão para baixar o relatório'
      )
    } catch (err) {
      console.log(err)
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os dados para a exportação. Tente novamente!'
      )
      setIsLoadingExport(false)
    }
  }

  useEffect(() => {
    loadInventoryCount()
  }, [
    currentPage,
    pageLimit,
    status,
    dateType,
    initialDate,
    finalDate,
    queryDebounced,
  ])

  return (
    <>
      <InventoryCountFilter
        history={history}
        setQuery={setQuery}
        inventoryCountList={inventoryCountList}
        query={query}
        setStatus={setStatus}
        setDateType={setDateType}
        setInitialDate={setInitialDate}
        initialDate={initialDate}
        setFinalDate={setFinalDate}
        finalDate={finalDate}
        handleChangeQuery={handleChangeQuery}
        actualDate={actualDate}
        dataToExport={dataToExport}
        isLoadingExport={isLoadingExport}
        isExportReady={isExportReady}
        handleLoadDataToExport={handleLoadDataToExport}
      />

      <InventoryCountTable
        history={history}
        loading={loading}
        inventoryCountList={inventoryCountList}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        initialDate={initialDate}
        finalDate={finalDate}
        dateType={dateType}
      />
    </>
  )
}

export default withRouter(InventoryCountMain)
