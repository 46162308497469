import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';

export const FinancialStatementDocument = ({
  report,
  initialDate,
  finalDate,
}) => {
  return (
    <Document
      title={`demonstrativo-de-resultado-do-período-${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="demonstrativo-de-resultado"
    >
      <Page style={styles.page}>
        <View>
          <Text style={styles.title}>
            DRE - Demonstrativo de Resultado de Exercício
          </Text>
          <Text style={styles.text}>
            {initialDate} - {finalDate}
          </Text>
        </View>
        <View style={{ marginTop: 20 }}>
          {report.map(({ title, total, results }, index) => (
            <View key={index} style={{ marginBottom: 5 }}>
              <View style={styles.header}>
                <Text style={styles.titleTexts}>{title}</Text>
                <Text style={styles.titleTexts}>{currency(total)}</Text>
              </View>
              <View>
                {results.map(({ description, value, simbol }) => (
                  <View key={Math.random()} style={styles.item}>
                    <Text>
                      ({simbol}) {description}
                    </Text>
                    <Text>{currency(value)}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  header: {
    fontSize: 12,
    padding: 5,
    backgroundColor: '#c0c0c0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 10,
    paddingLeft: 10,
  },
});
