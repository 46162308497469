import React from 'react';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import DownloadXlsButton from '../../../components/DownloadXlsButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SelectBranding } from 'v2/components/Select';
import { SelectMulti } from 'v2/components/Select/Multi';
import SearchInput from 'components/SearchInput';

export default function ClientsFilter({
  plansOptions,
  statusOptions,
  type,
  setType,
  signaturePeriod,
  setSignaturePeriod,
  signatureStatusIds,
  setSignatureStatusIds,
  planIds,
  setPlanIds,
  dateType,
  setDateType,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  branding,
  setBranding,
  query,
  handleChangeQuery,
  isXlsExportReady,
  isXlsExportLoading,
  loadDataToExport,
  dataToExport,
}) {
  return (
    <div className="admin-client__filters">
      <div className="admin-clients-row">
        <SearchInput
          containerStyle={{ width: 420 }}
          placeholder="Pesquisar por CPF/CNPJ, Nome/Razão Social, Nome fantasia ou ID"
          value={query}
          onChange={(e) => handleChangeQuery(e.target.value)}
          style={{ fontSize: '12px' }}
        />
        <fieldset>
          <span className="typography__text-2">Produto:</span>
          <SelectBranding
            variant="column"
            height={35}
            value={branding}
            onChange={(e) => setBranding(e)}
            showAllOption
            hideLabel={true}
            fontSizeInput={'12px'}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Planos:</span>
          <SelectMulti
            width={130}
            height={35}
            options={plansOptions}
            value={planIds}
            onChange={(e) => setPlanIds(e)}
            hasAllOption
            style={{ fontSize: '12px' }}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Status:</span>
          <SelectMulti
            width={130}
            height={35}
            options={statusOptions}
            value={signatureStatusIds}
            onChange={(e) => setSignatureStatusIds(e)}
            hasAllOption
            selectAllByDefault
            style={{ fontSize: '12px' }}
          />
        </fieldset>
      </div>
      <div className="admin-clients-row">
        <fieldset>
          <span className="typography__text-2">Tipo de Data:</span>
          <select
            value={dateType}
            onChange={(e) => setDateType(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="createdAt">Cadastro</option>
            <option value="lastSignatureEndDate">Vigência</option>
            <option value="lastLogin">Últ. Acesso</option>
            <option value="lastSaleDate">Últ. Venda</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Data Inicial:</span>
          <input
            className="form-control foco-input"
            type="date"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Data Final:</span>
          <input
            className="form-control foco-input"
            type="date"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
            style={{ fontSize: '12px' }}
          />
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Período:</span>
          <select
            value={signaturePeriod}
            onChange={(e) => setSignaturePeriod(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="">Todos</option>
            <option value="Mensal">Mensal</option>
            <option value="Trimestral">Trimestral</option>
            <option value="Semestral">Semestral</option>
            <option value="Anual">Anual</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="typography__text-2">Tipo:</span>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="form-control foco-input"
            style={{ fontSize: '12px' }}
          >
            <option value="ALL">Ambos</option>
            <option value="Física">Física</option>
            <option value="Jurídica">Jurídica</option>
          </select>
        </fieldset>
        <div className="flex center gap-025">
          {isXlsExportReady ? (
            <DownloadXlsButton
              archiveName="Relação de Clientes"
              data={dataToExport}
              className="btn btn-export"
              columns={[
                {
                  name: 'CPF/CNPJ',
                  acessor: 'Cpf_Cnpj',
                },
                {
                  name: 'RAZÃO SOCIAL',
                  acessor: 'Company_Name',
                },
                {
                  name: 'EMAIL',
                  acessor: 'Email',
                },
                {
                  name: 'CIDADE',
                  acessor: 'City',
                },
                {
                  name: 'ESTADO',
                  acessor: 'State',
                },
                {
                  name: 'TELEFONE',
                  acessor: 'Number_Phone1',
                },
                {
                  name: 'PRODUTO',
                  acessor: 'branding',
                },
                {
                  name: 'PLANO',
                  acessor: 'planDescription',
                },
                {
                  name: 'DT. CADASTRO',
                  acessor: 'createdAt',
                },
                {
                  name: 'DT. VIGÊNCIA',
                  acessor: 'SignatureEnd_Date',
                },
                {
                  name: 'ULTIMA VENDA',
                  acessor: 'lastSaleDate',
                },
                {
                  name: 'ULTIMO ACESSO',
                  acessor: 'lastLogin',
                },
              ]}
              style={{
                fontSize: '12px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                color="white"
                icon={faCloudDownloadAlt}
                style={{ marginRight: '5px' }}
              />
              Baixar Relatório
            </DownloadXlsButton>
          ) : (
            <button
              className="btn btn-export"
              onClick={() => loadDataToExport()}
              disabled={isXlsExportLoading}
              style={{
                fontSize: '12px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isXlsExportLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp;Exportar .xls
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
