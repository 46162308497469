import React from 'react'
import { Grid, Row } from 'react-bootstrap'

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import InventoryCountRegisterMain from './components/InventoryCountRegisterMain'

import './styles.css'
import { useParams } from 'react-router'

const InventoryCountRegister = ({ match, location }) => {
  const { id } = useParams()

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id="required-field-label">* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              'Inventário/Contagem',
              id ? 'Editar Inventário' : 'Novo Inventário',
            ]}
            path={['home', 'products', 'inventories-counts', null]}
          />
          <InventoryCountRegisterMain inventoryCountId={id} />
        </Row>
      </Grid>
    </div>
  )
}

export default InventoryCountRegister
