import React, { useState, useEffect } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import AppError from 'components/AppError'

import { AppLoading } from 'client/routes/index.routes'

import PurchasesSupplierPDF from './PurchasesSupplierPDF'


import PurchasesRepository from '../../../repositories/Purchases'

const ReturnDocumentPurchasesSupplier = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [dataReport, setDataReport] = useState([])

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search)
    if (searchParams) loadQuery(searchParams)
  }, [location.search])



  const loadQuery = async searchParams => {
    try {
      const ReturnListingResponseIdHash = searchParams.get('companyId')
      let companyId

      if (ReturnListingResponseIdHash) {
        companyId = await decrypt(ReturnListingResponseIdHash, '@OS-dig:companyId')
      }

      if (!companyId) {
        setHasError(true)
        setLoading(false)

      }

      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const providerId = searchParams.get('providerId')


      const dados = await loadReport(
        companyId,
        initialDate,
        finalDate,
        providerId)

      setDataReport(dados)
      setLoading(false)

    } catch (err) {
      setHasError(true)
      setLoading(false)

    }

  }

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    providerId) => {
    try {
      const data = await PurchasesRepository.generatePurchasesSupplier({
        companyId,
        initialDate,
        finalDate,
        providerId
      })


      return data.report

    } catch (err) {
      console.log(err)
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />
  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />


  return (

    <div id='sale-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}>
          <PurchasesSupplierPDF
            responseData={dataReport}
          />
        </PDFViewer>
      </main>
    </div>
  )

}




export default ReturnDocumentPurchasesSupplier 