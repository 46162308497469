import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import { format } from 'date-fns'
import Button from 'client/components/CustomButton/Button'
import SearchInput from 'components/SearchInput'

import nfceRepository from '../../../../../repositories/NFCes'
import satRepository from '../../../../../repositories/SATCFe'
import useFilters from '../../../../../hooks/useFilters'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { useAuth } from 'contexts/auth'
import { INVOICE_STATUS_DESCRIPTION } from '../../../../../utils/constants'

import NFCeActions from './NFCeActions'
import InvoiceDetailsModal from '../InvoiceDetailsModal'

import SATActions from './SATActions'

import './styles.css'

const NFCeList = ({ onCancel }) => {
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)

  const [invoiceDetails, setInvoiceDetails] = useState(false)
  const [invoiceId, setInvoiceId] = useState('')
  const [invoiceType, setInvoiceType] = useState('')

  const { companyId } = useAuth()
  const { dateFilter, queryFilter, statusFilter } = useFilters()
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter
  const { query, setQuery, filterByQuery } = queryFilter
  const { status, setStatus, filterByStatus } = statusFilter

  useEffect(() => {
    if (!!companyId) {
      getInvoices()
    }
  }, [companyId])

  const getInvoices = async () => {
    setLoading(true)
    try {
      const nfces = await nfceRepository.getAllByCompany(companyId)
      const sats = await satRepository.getAllByCompany(companyId)

      const serializedNFCes = nfces.map((nfce) => ({ ...nfce, type: 'NFC-e' }))
      const serializedSATs = sats.map((sat) => ({
        ...sat,
        type: 'SAT',
        Customer: sat.SATCustomer,
      }))

      setInvoices([...serializedNFCes, ...serializedSATs])
    } catch (err) {
      return toastr.error(
        'Ocorreu um erro ao carregar as notas. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  const renderBtnStatus = (value) => {
    let color = ''
    switch (value) {
      case 'Aberta':
        color = '#ffcc00'
        break
      case 'Emitida':
        color = '#5cb85c'
        break
      case 'Inutilizada':
        color = '#555555'
        break
      case 'Cancelada':
        color = '#d9534f'
        break
      case 'Contingência':
        color = '#7a43b6'
        break
      case 'Aberta c/ Erro':
        color = '#ffcc00'
        break
      case 'Denegada':
        color = '#000000'
        break
      default:
        break
    }

    return (
      <Button
        id='botaoReactTable'
        style={{
          backgroundColor: color,
        }}
      >
        {value}
      </Button>
    )
  }

  const handleEdit = (invoice) => {
    setInvoiceId(invoice.id)
    setInvoiceType(invoice.type)
    setInvoiceDetails(true)
  }

  function handleFilters(invoice) {
    const querySearch = [
      invoice['code'],
      invoice['Customer']?.['Company_Name'],
      invoice['saleCode'],
    ]
    return (
      filterByQuery(querySearch) &&
      filterByDate('createdAt', invoice) &&
      filterByStatus('status', invoice)
    )
  }

  const fiscalTagsColors = {
    'NFC-e': '#FFA834',
    SAT: '#5BC0DE',
  }

  return (
    <>
      <Modal show animation dialogClassName='nfce-list'>
        <Modal.Header>
          <Modal.Title>
            <strong>Listagem de NFC-e/SAT</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <header>
            <div>
              <SearchInput
                placeholder='Pesquisa por Nº da Nota, Venda e Cliente'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor=''>Data de Criação Inicial:</label>
              <input
                className='form-control foco-input'
                type='date'
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor=''>Data de Criação Final:</label>
              <input
                className='form-control foco-input'
                type='date'
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor=''>Status:</label>
              <select
                className='form-control foco-input'
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value=''>Todos</option>
                <option value={INVOICE_STATUS_DESCRIPTION.OPEN}>Aberta</option>
                <option value={INVOICE_STATUS_DESCRIPTION.ISSUED}>
                  Emitida
                </option>
                <option value={INVOICE_STATUS_DESCRIPTION.DISABLED}>
                  Inutilizada
                </option>
                <option value={INVOICE_STATUS_DESCRIPTION.CANCELED}>
                  Cancelada
                </option>
                <option value={INVOICE_STATUS_DESCRIPTION.CONTINGENCY}>
                  Contingência
                </option>
                <option value={INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS}>
                  Aberta c/ Erro
                </option>
                <option value={INVOICE_STATUS_DESCRIPTION.DENIED}>
                  Denegada
                </option>
              </select>
            </div>
          </header>
          <main>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={invoices.filter(handleFilters)}
              columns={[
                {
                  Header: 'Data',
                  accessor: 'createdAt',
                  width: 100,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 85,
                  Cell: (props) => (
                    <Button
                      id='botaoReactTable'
                      style={{
                        backgroundColor: fiscalTagsColors[props.value],
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Nota',
                  accessor: 'code',
                  width: 85,
                },
                {
                  Header: 'Venda',
                  accessor: 'saleCode',
                  width: 85,
                },
                {
                  Header: 'Série',
                  accessor: 'serie',
                  width: 85,
                },
                {
                  Header: 'Cliente',
                  accessor: 'Customer.Company_Name',
                },
                {
                  Header: 'Valor',
                  accessor: 'total',
                  width: 100,
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Ambiente',
                  accessor: 'environment',
                  width: 150,
                  Cell: (props) => (
                    <Button
                      id='botaoReactTable'
                      style={{
                        backgroundColor:
                          props.value === 'Homologação' ? '#1c0c4f' : 'b70f0a',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  width: 150,
                  Cell: (props) => renderBtnStatus(props.value),
                },
                {
                  Header: '',
                  accessor: 'type',
                  width: 100,
                  Cell: (props) => {
                    if (props.value === 'NFC-e') {
                      return (
                        <NFCeActions
                          NFCe={props.original}
                          onChange={() => getInvoices()}
                          onEdit={() => handleEdit(props.original)}
                        />
                      )
                    } else {
                      return (
                        <SATActions
                          SAT={props.original}
                          onChange={() => getInvoices()}
                          onEdit={() => handleEdit(props.original)}
                        />
                      )
                    }
                  },
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Date',
                  desc: true,
                },
              ]}
              previousText='Anterior'
              nextText='Próximo'
              loadingText='Carregando...'
              noDataText='Nenhum Orçamento encontrado'
              pageText='Página'
              ofText='de'
              rowsText='linhas'
            />
          </main>
        </Modal.Body>
        <Modal.Footer>
          <button className='danger' onClick={onCancel}>
            Voltar
          </button>
        </Modal.Footer>
      </Modal>
      {invoiceDetails && (
        <InvoiceDetailsModal
          invoiceId={invoiceId}
          fiscalDocumentType={invoiceType}
          onInitNewSale={() => setInvoiceDetails(false)}
        />
      )}
    </>
  )
}

export default NFCeList
