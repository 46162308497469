import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import billsToPayRepository from 'repositories/BillsToPay';
import AccountsReceivableSyntheticPDF from './AccountsPayableSynthetic';
import providersRepository from 'repositories/Providers';
import cashierBanksRepository from 'repositories/CashierBank';

const AccountsPayableSyntheticReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [dataAccount, setDataAccount] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [dateType, setDateType] = useState('');
  const [reportType, setReportType] = useState('');
  const [titleStatus, setTitleStatus] = useState('');
  const [provider, setProvider] = useState('');
  const [cashierBank, setCashierBank] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyIdResponseIdHash = searchParams.get('companyId');
      let companyId;

      if (companyIdResponseIdHash) {
        companyId = await decrypt(companyIdResponseIdHash, '@OS-dig:companyId');
      }

      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const providerId = searchParams.get('providerId');
      const dateType = searchParams.get('dateType');
      const titleStatus = searchParams.get('titleStatus');
      const cashierBankId = searchParams.get('cashierBanksValue');
      const reportType = searchParams.get('reportType');

      if (providerId) {
        try {
          const infoProvider = await providersRepository.getById(providerId);
          const { companyName, tradingName } = infoProvider;
          const descriptionProvider = companyName || tradingName;

          if (descriptionProvider.length > 15) {
            setProvider(`${descriptionProvider.slice(0, 15)}...`);
          } else {
            setProvider(descriptionProvider);
          }
        } catch (error) {
          console.error('Erro ao obter informações do fornecedor:', error);
          setProvider('Todos');
        }
      } else {
        setProvider('Todos');
      }

      if (cashierBankId) {
        try {
          const infoCashierBank =
            await cashierBanksRepository.getCashierBanksById(cashierBankId);
          const { description } = infoCashierBank;

          setCashierBank(description);
        } catch (error) {
          console.error('Erro ao obter informações do caixa/banco:', error);
          setCashierBank('Todos');
        }
      } else {
        setCashierBank('Todos');
      }

      setInitialDate(initialDate);
      setFinalDate(finalDate);
      setDateType(dateType);
      setReportType(reportType);
      setTitleStatus(titleStatus);

      const data = await loadReport(
        companyId,
        initialDate,
        finalDate,
        providerId,
        dateType,
        titleStatus,
        cashierBankId
      );

      setDataAccount(data);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    providerId,
    dateType,
    titleStatus,
    cashierBankId
  ) => {
    try {
      const data =
        await billsToPayRepository.generateBillsPayableTitleSynthetic({
          companyId,
          initialDate,
          finalDate,
          providerId,
          dateType,
          titleStatus,
          cashierBankId,
        });
      return data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );
  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          <AccountsReceivableSyntheticPDF
            reportType={reportType}
            titleStatus={titleStatus}
            provider={provider}
            cashierBank={cashierBank}
            responseDate={dataAccount}
            dateType={dateType}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  );
};

export default AccountsPayableSyntheticReport;
