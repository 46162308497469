import React, { useEffect, useState } from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, percentage, cepMask, CFOPMask } from 'client/components/ToNormalize/ToNormalize'





const ReturnListingPDF = (responseData) => {
  const totals = responseData.responseData.totals
  const period = responseData.responseData.period
  const purchases = responseData.responseData.purchases
  const subTotals = responseData.responseData.subTotals


  return (
    <Document
      title={`impresso-list-purchases-report-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Listagem de Compras  por Fornecedor
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Data Inicio:
            </Text>
            <Text style={[styles.text]}> {format(new Date(`${period.start} 03:00:01`), 'dd/MM/yyyy')}</Text>
          </View>

          <View style={{ left: '6px' }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
                Data Fim:
              </Text>
              <Text style={[styles.text]}>{format(new Date(`${period.end} 23:59:59`), 'dd/MM/yyyy')}</Text>
            </View>
          </View>

        </View>


        <TableHeader
          totals={totals} purchases={purchases} subTotals={subTotals}
        />

      </Page>
    </Document>
  )

}

const TableHeader = ({ totals, purchases, subTotals }) => {
  return (
    <>
      <View style={[styles.line, { marginTop: '20px' }]} />
      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Fornecedor
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '23%' }]}>Fornecedor</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Quantidade</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>

      <View style={[styles.line]} />

      {
        totals.map((tot, index) => (
          <TotalsByTypeStatusItems
            key={index}
            tot={tot}
          />
        ))
      }

      <View style={[styles.line]} />
      {
        <SubTotals
          key={totals}
          sub={subTotals}
        />
      }

      <View style={[styles.line, { marginTop: '10px' }]} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Compras
        </Text>
      </View>
      <View style={[styles.line]} />

      {
        purchases.map((purchase, index) => (<PurchaseDetails purchase={purchase} key={index} />))
      }


      <View style={[styles.line, { marginTop: '50px' }]} />
    </>
  )
}



const TotalsByTypeStatusItems = ({ key, tot }) => {
  return (
    <View>

      <View style={styles.containerRow}>
        <View style={{ width: '26%' }}>
          <Text style={styles.text}>{tot.providerCpfCnpj} - {tot.providerCompanyName} </Text>
        </View>
        <View style={{ width: '8%' }}>
          <Text style={styles.text}>{tot.qtde}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(tot.total)}</Text>
        </View>

      </View>

    </View>
  )
}

const SubTotals = ({ key, sub }) => {

  return (

    <View>

      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '26%' }]}>Total </Text>
        <Text style={[styles.boldText, { width: '8%' }]}>{sub.qtde}</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>{currency(sub.total)}</Text>
      </View>

    </View>



  )
}



const PurchaseDetails = ({ purchase }) => {

  return (
    <View style={{ marginTop: '10px' }}>
      <PurchaseDetailsHeader purchase={purchase} />
      <PurchaseDetailsTableHeader />

      {
        purchase.PurchaseItems.map((item, index) => (
          <PurchaseDetailsTableItem item={item} key={index} purchase={purchase} />
        ))
      }
      <View style={styles.line} />
    </View>
  )
}

const PurchaseDetailsHeader = ({ purchase, key }) => {
  return (
    <View key={key}>
      <View style={{ marginTop: '6px' }} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '8%' }]}>Data Cotação: </Text>
        <Text style={{ width: '7%', right: '4px' }}>{format(new Date(purchase.createdAt), 'dd/MM/yyyy')}</Text>
        <Text style={[styles.boldText, { width: '4%' }]}>Status: </Text>
        <Text style={{ width: '7%', left: '2px' }}>{purchase.PurchaseStatus.description}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '7%' }]}>Fornecedor: </Text>
        <Text style={[{ width: '57%', right: '4px' }]}>{purchase.Provider.cpfCnpj} - {purchase.Provider.cpfCnpj.lenght == 11 ? purchase.Provider.tradingName : purchase.Provider.companyName}</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor Compras:  </Text>
        <Text style={[{ width: '6%', right: '6px' }]}>{currency(purchase.productsValue)}</Text>
      </View>

    </View>
  )
}



const PurchaseDetailsTableHeader = ({ }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '9%' }]}>Codigo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Ref.Fabricação</Text>
        <Text style={[styles.boldText, { width: '14%' }]}>Marca</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Qtd.</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Valor Unitário</Text>
        <Text style={[styles.boldText, { width: '9%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}


const PurchaseDetailsTableItem = ({ item, key }) => {

  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={{ width: '9%' }}>{item.productCode}</Text>
        <Text style={{ width: '20%' }}>{item.description}</Text>
        <Text style={{ width: '12%' }}>{item.Products.Manufacturer_Ref}</Text>
        <Text style={{ width: '15%' }}>{item.Products.Brands?.Description}</Text>
        <Text style={{ width: '9%' }}>{item.quantity}</Text>
        <Text style={{ width: '8%' }}>{currency(item.unitValue)}</Text>
        <Text style={{ width: '9%' }}>{currency(item.unitValue * item.quantity)}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>

  )
}

export default ReturnListingPDF



const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },

})