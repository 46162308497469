import config from '../config'
import api from "../services/api"

const getCnaeByCode = async (code) => {
    try {
        const response = await api.get(config.endpoint + `cnae?code=${code}`)
        return response.data
    } catch (err) {
        throw err
    }
}

const create = async (cnae) => {
    try {
        const response = await api.post(config.endpoint + 'cnae', cnae)
        return response.data
    } catch (err) {
        throw err
    }
}

const getAllByCompany = async companyId => {
    try {

        const response = await api.get(config.endpoint + `cnae?companyId=${companyId}`)
        return response.data

    } catch (err) {
        throw err
    }
}

const getAllActiveByCompany = async companyId => {
    const response = await api.get(config.endpoint + `cnae?companyId=${companyId}&isActive=1`)
    return response.data
}

const search = async params => {
    const response = await api.post(config.endpoint + 'cnae/search', params)
    return response.data
}

const update = async (id, cnae) => {
    try {
        await api.put(config.endpoint + `cnae/${id}`, cnae)
    } catch (err) {
        throw err
    }
}

const showById = async (id) => {
    try {
        const response = await api.get(config.endpoint + `cnae/${id}`)
        return response.data
    } catch (err) {
        throw err
    }
}

export default {
    getCnaeByCode,
    create,
    getAllByCompany,
    update,
    showById,
    getAllActiveByCompany,
    search
}