import React from 'react';
import { Grid, Row } from "react-bootstrap";
import { withRouter } from 'react-router';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import NewShippingCompany from './FormNewShippingCompany';
import constants from '../../../../utils/constants'

const ViewNewShippingCompany = ({ history, match }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
            <BreadCrumb
              data={['Início', 'Cadastros', 'Fiscal', 'Transportadora', !!match.params.id ? 'Editar Transportadora' : 'Nova Transportadora']}
              path={[constants.ROUTES.HOME, null, null, constants.ROUTES.SHIPPING_COMPANIES, null]}
            />
            <span style={{ color: 'red', position: 'absolute', right: 10 }}>* Campos Obrigatórios</span>
          </div>
          <NewShippingCompany
            shippingCompanyId={match.params.id}
            onCancel={() => history.push(constants.ROUTES.SHIPPING_COMPANIES)}
            onSubmit={() => history.push(constants.ROUTES.SHIPPING_COMPANIES)}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(ViewNewShippingCompany)