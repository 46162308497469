import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import HomeSales from './Sales'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Sales = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb data={['Início', 'Vendas']} path={['home', null]} />
          <HomeSales />
        </Row>
      </Grid>
    </div>
  )
}

export default Sales
