import api from '../services/api'

const getAll = async (params) => {
  const { data } = await api.get('inventoryA', { params })
  return data
}

const create = async (params) => {
  const { data } = await api.post('inventoryA', params)
  return data
}

const update = async (id, params) => {
  const { data } = await api.put('inventoryA/' + id, params)
  return data
}

const getById = async (inventoryCountId) => {
  const { data } = await api.get(`inventoryA/${inventoryCountId}`)
  return data
}

const inventoryCountReport = async (params) => {
  console.log(params)
  const { data } = await api.get('inventoryA/', { params })
  return data
}

export default {
  getAll,
  create,
  getById,
  update,
  inventoryCountReport,
}
