import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLoading } from 'v2/hooks/useLoading/useLoading';

export function ManySalesCancelInvoice({
  nfeId,
  submitNFe,
  backCurrentStep,
  isManySaleHasNFe,
}) {
  const { justification } = useSelector(
    (state) => state.form.cancelInvoice.values
  );

  const { isLoading, setIsLoading } = useLoading();

  const handleCancelInvoice = async (isCanceled) => {
    const params = {
      nfeId,
      justification,
      cancelSale: isCanceled,
    };
    setIsLoading(true);
    try {
      await submitNFe(params);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <strong>Cancelamento de NF-e</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <strong style={{ fontSize: '16px' }}>
            Você tem certeza que deseja cancelar essa nota agrupada?
          </strong>
          <span style={{ fontSize: '16px', marginTop: '10px' }}>
            Caso alguma venda dessa nota possua NFC-e emitida, não será possível
            cancelar a venda, apenas a nota em questão. Você realmente deseja
            fazer o cancelamento?
          </span>
          <small className="text-red text-center mt-1">
            Ao cancelar Nota e Venda, todas as vendas serão canceladas, ou seja,
            títulos serão cancelados e estoque será devolvido.
          </small>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}
      >
        <button
          className="button button-red button-h35"
          onClick={backCurrentStep}
          disabled={isLoading}
        >
          Voltar
          <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
        </button>
        <button
          className="button button-green button-h35"
          onClick={() => handleCancelInvoice(true)}
          disabled={isLoading || isManySaleHasNFe}
        >
          <span>
            Cancelar{' '}
            <strong style={{ textDecoration: 'underline' }}> Nota</strong> e{' '}
            <strong style={{ textDecoration: 'underline' }}> Vendas</strong>
          </span>
          <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
        </button>
        <button
          className="button button-cyan button-h35"
          onClick={() => handleCancelInvoice(false)}
          disabled={isLoading}
        >
          <span>
            Cancelar{' '}
            <strong style={{ textDecoration: 'underline' }}>
              apenas a Nota
            </strong>
          </span>
          <span className={isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
        </button>
      </Modal.Footer>
    </>
  );
}
