import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import StoneLinkForm from './Form'

const StoneLink = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Integrações',
          'Integração Stone',
          match.params.id ? 'Editar Vínculo' : 'Novo Vínculo',
        ]}
        path={['home', null, 'stone-links', null]}
      />
      <StoneLinkForm
        stoneLinkId={match.params.id}
        goToHomepage={() => history.push('stone-links')}
      />
    </>
  )
}

export default withRouter(StoneLink)
