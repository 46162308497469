import React, { useState, useEffect } from 'react';
import SearchInput from 'components/SearchInput';
import useFilters from 'hooks/useFilters';
import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/Button';
import { format } from 'date-fns';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { Link } from 'react-router-dom';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { useHistory } from 'react-router-dom';
import { CRMBonusRepositoryV2 } from 'v2/repositories/CRMBonus';

export const CrmBonusHome = () => {
  const history = useHistory();
  const [crmBonusList, setCrmBonusList] = useState([]);
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const [status, setStatus] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  useEffect(() => {
    loadCrms({ status, initialDate, finalDate });
  }, [status, initialDate, finalDate]);

  async function loadCrms({ status, initialDate, finalDate }) {
    const response = await CRMBonusRepositoryV2.index({
      status,
      initialDate,
      finalDate,
    });

    const formatedResponse = response.data
      .map((crm) => ({
        id: crm.id,
        data:
          crm.createdAt &&
          format(getDateOnlyFromDate(crm.createdAt), 'dd/MM/yyyy'),
        cpfCnpj: cpfOrCnpjMask(crm.Company.Cpf_Cnpj),
        companyName: crm.Company.Company_Name,
        tradingName: crm.Company.Trading_Name,
        status: crm.Company.CRMBonus,
      }))
      .filter((element) => element.cpfCnpj.length > 14);

    setCrmBonusList(formatedResponse);
  }

  function handleQueryFilter(crm) {
    const fields = [crm.cpfCnpj, crm.companyName, crm.tradingName];

    return filterByQuery(fields);
  }

  return (
    <main className="container-wrapper">
      <section className="container-row">
        <button
          className="btn btn-sucesso new-crm"
          onClick={() => history.push('crm-bonus-register')}
        >
          + Novo Cliente
        </button>
        <div className="search-input-wrapper">
          <SearchInput
            placeholder="Pesquisa por CNPJ, Razão Social ou Nome Fantasia"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <fieldset>
          <label className="status-label">Status:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </fieldset>
        <fieldset>
          <label>Data Inicial:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={initialDate}
            max={finalDate || new Date().toISOString().split('T')[0]}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </fieldset>
        <fieldset>
          <label>Data Final:</label>
          <input
            type="date"
            className="form-control foco-input"
            value={finalDate}
            min={initialDate}
            max={new Date().toISOString().split('T')[0]}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </fieldset>
      </section>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={crmBonusList.filter(handleQueryFilter)}
        columns={[
          {
            Header: 'Data',
            accessor: 'data',
            width: 150,
          },
          {
            Header: 'CNPJ',
            accessor: 'cpfCnpj',
            width: 175,
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName',
            minWidth: 250,
          },
          {
            Header: 'Nome Fantasia',
            accessor: 'tradingName',
            minWidth: 250,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: props.value === true ? '#5cb85c' : '#d9534f',
                }}
              >
                {props.value === true ? 'Ativo' : 'Inativo'}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 150,
            Cell: (props) => (
              <Link
                to={{ pathname: `crm-bonus-register#${props.value}` }}
                title="Editar"
              >
                <FontAwesomeIcon
                  cursor="pointer"
                  icon={faEdit}
                  style={{ width: '20px', height: '18px' }}
                />
              </Link>
            ),
          },
        ]}
        defaultPageSize={10}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </main>
  );
};
