export const filterTransactions = (transactions, getValues, prefix) => {
  const {
    description,
    TransactionInitialDate,
    TransactionFinishDate,
    transactionStatus,
    transactionType
  } = getValues(`search.${prefix}`);

  const parseDateMMDDYYYY = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const parseDateYYYYMMDD = (dateString) => {
    return new Date(dateString);
  };

  return transactions.filter(transaction => {

    // Verifica se a descrição deve ser filtrada
    const matchesDescription = description
      ? transaction.memo.toLowerCase().includes(description.toLowerCase()) ||
        transaction.amount.toString().includes(description)
      : true;

    // Converte a data da transação (formato MM/DD/YYYY) para um objeto Date
    const transactionDate = parseDateMMDDYYYY(transaction.datePosted);
    const initialDate = TransactionInitialDate ? parseDateYYYYMMDD(TransactionInitialDate) : null;
    const finishDate = TransactionFinishDate ? parseDateYYYYMMDD(TransactionFinishDate) : null;

    // Filtra por intervalo de datas se as datas estiverem preenchidas
    const matchesDateRange =
      (!initialDate || transactionDate >= initialDate) &&
      (!finishDate || transactionDate <= finishDate);


    // Filtra por status
    const matchesStatus = transactionStatus === "Ambos"
      ? true
      : transactionStatus === "Conciliado"
      ? transaction.conciled === true
      : transactionStatus === "Não Conciliado"
      ? transaction.conciled === false
      : true;

    const matchesType = transactionType === "Ambos"
      ? true
      : transactionType
      ? transaction.type === transactionType
        : true;

    return matchesDescription && matchesDateRange && matchesStatus && matchesType;
  });
};
