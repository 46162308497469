import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeProviderProducts from './ProviderProducts'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const ProviderProducts = ({ match }) => {
    localStorage.setItem('selectedPath', 'client/provider-products')

    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <BreadCrumb data={['Início', 'Fornecedores', 'Produtos por Fornecedor']}
                        path={['home', 'providers', null]} />
                    <HomeProviderProducts providerId={match.params.id} />
                </Row>
            </Grid>
        </div>
    )
}

export default ProviderProducts
