import React, { useState, useEffect } from 'react';
import PartsPerApplicationFilter from './PartsPerApplicationFilter';
import PartsPerApplicationTable from './PartsPerApplicationTable';
import {
  currency,
  percentage,
} from 'client/components/ToNormalize/ToNormalize';

import productsRepository from 'repositories/Products';

import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import api from 'services/api';
import config from 'config';

const PartsPerApplicationMain = () => {
  const [typeVehicles, setTypeVehicles] = useState('Carro');
  const [brandsAll, setBrandsAll] = useState([]);
  const [brandsVehiclesSelectedId, setBrandsVehiclesSelectedId] =
    useState(null);
  const [brandsVehiclesSelected, setBrandsVehiclesSelected] = useState(null);
  const [vehiclesModelsSelected, setVehiclesModelsSelected] = useState([]);
  const [vehiclesModelsAll, setVehiclesModelsAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [productsType, setProductsType] = useState([]);
  const [readyToLoad, setIsReadyToLoad] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const { companyId } = useAuth();

  const loadProducts = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        vehicleId: vehiclesModelsSelected.value,
      };

      const response = await productsRepository.getPartsPerApplication(
        companyId,
        params
      );
      if (!response?.reportData?.length) {
        setIsLoading(false);
        setExcelData([]);
        setProductsType([]);
        setTotalPages(1);
        return toastr.warning(
          'Nenhuma informação foi localizada.',
          'Nenhum registro foi localizado com os filtros definidos. Selecione novamente os filtros e tente novamente.'
        );
      }

      const formatedProducts = response.reportData.map((item) => ({
        code: item?.Code,
        manufacturerRef: item?.Manufacturer_Ref,
        descriptionProduct: item.Description,
        stockQuantity: item.Stock_Quantity,
        purchasePrice: currency(item.Purchase_Price),
        profitMargin: percentage(item.Profit_Margin),
        salePrice: currency(item.Sale_Price),
        descriptionFamilies: item.familyDescription,
        descriptionBrands: item.brandDescription,
      }));

      setExcelData(formatedProducts);
      setProductsType(formatedProducts);
      setTotalPages(Math.ceil(response.reportDataCount / pageLimit));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar a listagem.',
        'Por favor, tente novamente.'
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBrands();
  }, []);

  useEffect(() => {
    if (brandsVehiclesSelected) {
      loadModels(brandsVehiclesSelected);
    }
  }, [brandsVehiclesSelected, typeVehicles]);

  const loadBrands = async () => {
    try {
      const brands = await api.get(config.endpoint + `tabelaFipe/getBrands`);
      setBrandsAll(
        brands.data.data.sort((a, b) =>
          a.Brand.toUpperCase() > b.Brand.toUpperCase() ? 1 : -1
        )
      );
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadModels = async (model) => {
    try {
      const res = await api.get(
        config.endpoint + `tabelaFipe/byBrands/${model.Brand}`
      );

      if (!res.data.status) {
        return toastr.warning(
          'Ocorreu um erro ao carregar os modelos. Por favor, tente novamente'
        );
      }

      const models = res.data.data
        .filter((model) => model.Type === typeVehicles)
        .map((child) => ({
          fipeCode: child.Code_FIPE,
          label: child.Model.toUpperCase(),
          value: child.id,
        }));

      const sortedModels = models.sort((a, b) => (a.label > b.label ? 1 : -1));

      const modelsOptions = sortedModels.map((vehicle) => ({
        value: vehicle.value,
        label: vehicle.fipeCode + ' - ' + vehicle.label,
      }));

      setVehiclesModelsAll(modelsOptions);
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar os modelos. Por favor, tente novamente'
      );
    }
  };

  const openPDF = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        vehicleId: vehiclesModelsSelected.value,
      };

      const response = await productsRepository.getPartsPerApplication(
        companyId,
        params
      );

      window.open(
        window.location.origin +
          `/report/parts-per-application?companyId=${companyId}&vehicleId=${vehiclesModelsSelected.value}`
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toastr.error(
        'Não foi possível abrir o PDF.',
        'Por favor, tente novamente.'
      );
    }
  };
  useEffect(() => {
    if (readyToLoad) {
      loadProducts();
    }
  }, [currentPage, pageLimit]);

  return (
    <div>
      <PartsPerApplicationFilter
        typeVehicles={typeVehicles}
        setTypeVehicles={setTypeVehicles}
        brandsVehiclesSelected={brandsVehiclesSelected}
        setBrandsVehiclesSelected={setBrandsVehiclesSelected}
        brandsVehiclesSelectedId={brandsVehiclesSelectedId}
        setBrandsVehiclesSelectedId={setBrandsVehiclesSelectedId}
        brandsAll={brandsAll}
        vehiclesModelsSelected={vehiclesModelsSelected}
        setVehiclesModelsSelected={setVehiclesModelsSelected}
        vehiclesModelsAll={vehiclesModelsAll}
        setVehiclesModelsAll={setVehiclesModelsAll}
        isLoading={isLoading}
        setIsReadyToLoad={setIsReadyToLoad}
        loadProducts={loadProducts}
        excelData={excelData}
        openPDF={openPDF}
        setProductsType={setProductsType}
        productsType={productsType}
      />
      <PartsPerApplicationTable
        productsType={productsType}
        isLoading={isLoading}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        loadProducts={loadProducts}
      />
    </div>
  );
};

export default PartsPerApplicationMain;
