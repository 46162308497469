import React, { useEffect, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

import AlertModal from 'components/AlertModal/AlertModal';
import InputNumberDecimal from '../../../InputNumberDecimal';
import { currency } from '../../../ToNormalize/ToNormalize';

import './styles.css';

const NFSeSheet = ({ items, setItems }) => {
  const [total, setTotal] = useState(0);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] = useState(false);

  useEffect(() => {
    const total = calculateTotal();
    setTotal(total);
  }, [items]);

  const calculateTotal = () => {
    const total = !items.length
      ? 0
      : items.length === 1
      ? items[0].unitValue * items[0].quantity
      : items
          .map((item) => item.unitValue * item.quantity)
          .reduce((prev, curr) => prev + curr);

    return total;
  };

  const handleRemoveItem = () => {
    setIsRemoveItemModalOpen(false);
    const newItems = [...items].filter((_, i) => i !== indexToRemove);
    setItems(newItems);
  };

  const handleOpenRemoveItemModal = (index) => {
    setIndexToRemove(index);
    setIsRemoveItemModalOpen(true);
  };

  const handleChangequantity = (value, index) => {
    const newItems = [...items];
    if (value <= 9999) {
      newItems[index].quantity = value;
      setItems(newItems);
    }
  };

  return (
    <Col className="folha-NFSe flex column between">
      <div>
        <table id="table-NFSe">
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Descrição</th>
              <th style={{ width: '100px' }}>Qtde.</th>
              <th style={{ width: '90px' }}>R$ Unit.</th>
              <th style={{ width: '90px' }}>R$ Total</th>
              <th style={{ width: '20px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {items.map((c, index) => (
              <tr key={index}>
                <td
                  style={{
                    fontSize: '12px',
                    overflowWrap: 'break-word',
                    width: '160px',
                  }}
                >
                  {c.description}
                </td>
                <td style={{ fontSize: '12px', width: '100px' }}>
                  <InputNumberDecimal
                    id="qtd-cotacao"
                    onChange={(value) => handleChangequantity(value, index)}
                    value={c.quantity}
                  />
                </td>
                <td style={{ fontSize: '12px', width: '90px' }}>
                  {currency(c.unitValue)}
                </td>
                <td style={{ fontSize: '12px', width: '90px' }}>
                  {currency(c.unitValue * c.quantity)}
                </td>
                <td style={{ width: '20px' }}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: 'red', cursor: 'pointer' }}
                    aria-hidden="false"
                    onClick={() => handleOpenRemoveItemModal(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="folha-NFSe-totais-wrapper">
        <div className="folha-NFSe-totais">
          <strong>Itens: </strong>
          {items.length}
          <br />
          <strong>Valor Total: </strong>
          {currency(total)}
        </div>
      </div>

      <AlertModal
        show={isRemoveItemModalOpen}
        animation={false}
        message="Deseja remover o item ?"
        onHide={() => setIsRemoveItemModalOpen(false)}
        onCancel={() => setIsRemoveItemModalOpen(false)}
        onSubmit={handleRemoveItem}
      />
    </Col>
  );
};

export default NFSeSheet;
