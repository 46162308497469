import React, { useState, useEffect } from 'react'
import { Grid, Row } from 'react-bootstrap'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import SimilarityForm from './FormSimilarity/'

const NewSimilarity = ({ location, product }) => {
  const [id, setId] = useState(null)

  localStorage.setItem('selectedPath', 'client/similarity')

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb data={['Início', 'Minhas Similaridades', 'Adicionar Similaridade']}
            path={['home', 'similarities', null]} />
          <div style={{ marginTop: 30 }}>
            <SimilarityForm/>
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewSimilarity