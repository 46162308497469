import React, { useState } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import InfiniteScroll from 'react-infinite-scroller';
import {
  faCirclePlus,
  faEquals,
  faImages,
  faMagnifyingGlassDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { Text } from 'v2/components/Text';
import { SelectMulti } from 'v2/components/Select/Multi';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import SearchInput from 'components/SearchInput';
import SearchSimilarityModal from 'client/views/PdvOnline/FormSale/SelectProducts/components/SearchSimilarityModal';
import './styles.css';
import { InputSelectDefault } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { getDefaultPriceOptions } from 'v2/helpers/priceHelpers';
import { useConfigurationsContext } from 'v2/contexts/configurationsContext';
import { getProductPromotionalPrices } from 'v2/helpers/priceHelpers';
import { useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import PromotionalPriceTable from 'repositories/PromotionalPriceTable';

const SearchModalTableWithInfiniteScroll = ({
  loading,
  isGeneralSearch,
  data,
  totalProducts,
  totalKits,
  totalPages,
  currentPage,
  brandId,
  manufacturerDescription,
  familyDescription,
  productQuery,
  vehicleModel,
  additionalInformation,
  handleChangeProductQuery,
  handleChangeVehicleQuery,
  handleChangeAdditionalInformationQuery,
  handleAddItem,
  allowPromotionalPrices,
  searchType,
  setSearchType,
  kitSearchQuery,
  handleChangeQuerySearchKit,
  pagesKits,
  kits,
  brands,
  manufactures,
  families,
  selectedPriceType,
  priceTypes,
  priceType,
  handleSetType,
  yearVehicle,
  handleChangeYear,
  handleChangeBrand,
  handleChangeManufacturer,
  handleChangeFamily,
  handleChangePriceType,
  handleOpenProductImageModal,
  hideSimilarityIcon,
  hideSearchTypeSelect,
  isLoadingMore,
  handleFetchMoreData,
  hasScrolled,
  setHasScrolled,
}) => {
  const { companyId } = useAuth();
  const { isWorkmotor, isPlanPrime } = usePlanSignatureContext();
  const { sellingPriceType } = useConfigurationsContext();
  const [promotionalTableisValid, setPromotionalTableisValid] = useState(false);
  const fetchMoreData = () => {
    if (hasScrolled) {
      handleFetchMoreData();
    }
  };
  const handlePromotionalTable = async () => {
    const activePromotionalTable =
      await PromotionalPriceTable.getActiveByCompanyId(companyId);
    const currentDate = new Date().toISOString().split('T')[0];

    const promotionalTableisValid =
      currentDate >= activePromotionalTable.initialDate &&
      currentDate <= activePromotionalTable.finalDate;
    setPromotionalTableisValid(promotionalTableisValid);
    console.log(promotionalTableisValid);
  };

  useEffect(() => {
    handlePromotionalTable();
  }, []);

  return (
    <div
      id="search-modal-content"
      onScroll={(e) => {
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <=
          e.target.clientHeight + 1;
        if (bottom && !isLoadingMore && e.target.className === 'rt-tbody') {
          setHasScrolled(true);
        }
      }}
    >
      <div className="pdv__search-modal-input">
        {searchType === 'Produtos' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <SearchInput
                style={{ marginRight: '5px', fontSize: '12px', width: '500px' }}
                placeholder="Pesquisa por Código / Descrição / Apelido / Ref. Fab / Código de Barras"
                value={productQuery}
                onChange={(e) => handleChangeProductQuery(e.target.value)}
              />

              <div
                style={{
                  width: '500px',
                  gap: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <SelectMulti
                  value={brandId}
                  options={brands}
                  onChange={(e) => handleChangeBrand(e)}
                  hasAllOption
                  customAllLabel="Marca"
                  customVariousLabel="Várias"
                  height={'35px'}
                  fontSize={'12px'}
                  isSearch={true}
                  borderColor={'#e3e3e3'}
                />

                <SelectMulti
                  value={manufacturerDescription}
                  options={manufactures}
                  onChange={(e) => handleChangeManufacturer(e)}
                  hasAllOption
                  customAllLabel="Montadora"
                  customVariousLabel="Várias"
                  height={'35px'}
                  fontSize={'12px'}
                  isSearch={true}
                  borderColor={'#e3e3e3'}
                />
              </div>

              <input
                className="form-control foco-input"
                placeholder="Modelo do Veículo(Aplicação)"
                value={vehicleModel}
                onChange={(e) => handleChangeVehicleQuery(e.target.value)}
                style={{ fontSize: '12px', marginLeft: '5px' }}
              />

              <input
                className="form-control foco-input"
                placeholder="Ano"
                value={yearVehicle}
                onChange={(e) => handleChangeYear(e.target.value)}
                onBlur={() => {
                  if (yearVehicle !== '' && yearVehicle.length !== 4) {
                    handleChangeYear('');
                    return toastr.warning(
                      'Ano inválido',
                      'Insira um ano válido para aplicação e tente novamente'
                    );
                  }
                }}
                maxLength={4}
                style={{ fontSize: '12px', marginLeft: '5px' }}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '5px',
                }}
              >
                <InputContainer
                  width={'500px'}
                  height={'35px'}
                  margin={'0 0 0 5px'}
                >
                  <input
                    className="form-control foco-input"
                    placeholder="Informações Complementares"
                    value={additionalInformation}
                    onChange={(e) =>
                      handleChangeAdditionalInformationQuery(e.target.value)
                    }
                    style={{ width: '500px', fontSize: '12px' }}
                  />
                </InputContainer>

                <div
                  style={{
                    width: '500px',
                    gap: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {families?.length && (
                    <SelectMulti
                      value={familyDescription}
                      options={families}
                      onChange={(e) => handleChangeFamily(e)}
                      hasAllOption
                      customAllLabel="Família"
                      customVariousLabel="Várias"
                      height={'35px'}
                      fontSize={'12px'}
                      isSearch={true}
                      borderColor={'#e3e3e3'}
                    />
                  )}

                  {sellingPriceType !== 'Preço Único' && (
                    <InputSelectDefault
                      value={priceType}
                      id="typePrice"
                      fontSize={'12px'}
                      onChange={(e) => {
                        handleSetType(e);
                      }}
                      height={'35px'}
                      options={priceTypes}
                      borderColor={'#e3e3e3'}
                    />
                  )}
                  {!hideSearchTypeSelect && (
                    <select
                      className="form-control foco-input"
                      type="text"
                      style={{
                        width: 140,
                        fontSize: '12px',
                      }}
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="Produtos" selected>
                        Produtos
                      </option>
                      <option value="Kit">Kit</option>
                    </select>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Exibindo {data.length} de {totalProducts} produtos
                </span>
              </div>
            </div>
          </div>
        )}

        {searchType === 'Kit' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <SearchInput
                style={{ marginLeft: '-5px', fontSize: '12px', width: '550px' }}
                placeholder="Pesquisa por Descrição ou Código de Barras"
                value={kitSearchQuery}
                onChange={(e) => handleChangeQuerySearchKit(e.target.value)}
              />
              {!hideSearchTypeSelect && (
                <select
                  className="form-control foco-input"
                  type="text"
                  style={{
                    width: 140,
                    fontSize: '12px',
                  }}
                  value={searchType}
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <option value="Produtos" selected>
                    Produtos
                  </option>
                  <option value="Kit">Kit</option>
                </select>
              )}
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: '400',
                }}
              >
                Exibindo {kits.length} de {totalKits} kits
              </span>
            </div>
          </div>
        )}
      </div>

      <InfiniteScroll
        pageStart={currentPage}
        loadMore={fetchMoreData}
        hasMore={
          searchType === 'Produtos'
            ? currentPage <= totalPages
            : currentPage <= pagesKits
        }
        useWindow={true}
      >
        <ReactTable
          className="react-table__infinite-scroll-modal-items"
          style={{
            fontSize: 12,
            fontWeight: 'bold',
            textAlign: 'center',
            height: '400px',
          }}
          data={searchType === 'Produtos' ? data : kits}
          columns={[
            ...(isGeneralSearch
              ? [
                  {
                    Header: '',
                    width: 80,
                    Cell: (props) => {
                      return (
                        <div className="flex center gap-025">
                          <FontAwesomeIcon
                            style={{
                              width: 16,
                              height: 16,
                              color: 'green',
                              cursor: 'pointer',
                            }}
                            icon={faCirclePlus}
                            onClick={() => handleAddItem(props.original)}
                          />
                          {(isPlanPrime || isWorkmotor) && (
                            <FontAwesomeIcon
                              style={{
                                width: 16,
                                height: 13,
                                cursor: 'pointer',
                              }}
                              icon={faImages}
                              onClick={() =>
                                handleOpenProductImageModal(props.original)
                              }
                            />
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <>
                        <FontAwesomeIcon
                          style={{
                            width: 15,
                            height: 15,
                            color: 'black',
                          }}
                          icon={faMagnifyingGlassDollar}
                        />
                      </>
                    ),
                    id: 'similarityIcon',
                    minWidth: 30,
                    Cell: (props) => {
                      const [isSimilarityModalOpen, setIsSimilarityModalOpen] =
                        useState(false);

                      return (
                        <>
                          <div className="flex center gap-025">
                            {!hideSimilarityIcon && (
                              <FontAwesomeIcon
                                style={{
                                  width: 15,
                                  height: 15,
                                  cursor: 'pointer',
                                  color: props.original.hasSimilarity
                                    ? '#629c44'
                                    : '',
                                }}
                                icon={faEquals}
                                onClick={() => {
                                  if (props.original.hasSimilarity) {
                                    setIsSimilarityModalOpen(true);
                                  } else {
                                    toastr.warning(
                                      'Produto sem similaridade existente hoje'
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>

                          {isSimilarityModalOpen && (
                            <SearchSimilarityModal
                              handleClose={() =>
                                setIsSimilarityModalOpen(false)
                              }
                              product={props.original}
                              handleAddItemCallback={handleAddItem}
                              allowPromotionalPrices={allowPromotionalPrices}
                              additionalInformationQuery={additionalInformation}
                            />
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: 'Código',
                    accessor: 'productCode',
                    minWidth: 60,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Descrição',
                    accessor: 'productDescription',
                    id: 'productDescription',
                    minWidth: 200,
                    getProps: (state, rowInfo, column) => {
                      return {
                        style: {
                          fontSize: '11px',
                          fontWeight: 'regular',
                        },
                      };
                    },
                    Cell: (props) => {
                      return (
                        <div className="flex center column">
                          {props.value}
                          {props.original.brandDescription ? (
                            <div className="flex center">
                              <Text fontWeight="bold" size="sm">
                                Marca:{' '}
                                {props.original.brandDescription
                                  ? props.original.brandDescription
                                  : 'Não possui marca'}
                              </Text>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: 'Ref. Fabricante',
                    accessor: 'productManufacturerRef',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Código de Barras',
                    accessor: 'productBarCode',
                    minWidth: 120,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Estoque',
                    accessor: 'productQuantity',
                    minWidth: 80,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'R$ Venda',
                    accessor: 'productSalePrice',
                    minWidth: 100,
                    Cell: (props) => {
                      const {
                        outOfStock,
                        productSalePrice,
                        productSalePriceWholesale,
                        promotionalPrice,
                        promotionalPriceWholesale,
                      } = props.original;
                      let style = {};
                      if (outOfStock) {
                        style.color = '#d9534f';
                      } else if (
                        promotionalTableisValid &&
                        allowPromotionalPrices &&
                        (promotionalPrice > 0 || promotionalPriceWholesale > 0)
                      ) {
                        style.color = '#629c44';
                      }
                      const { Sale_Price } = getProductPromotionalPrices(
                        productSalePrice,
                        productSalePriceWholesale,
                        promotionalTableisValid
                          ? allowPromotionalPrices
                          : false,
                        promotionalPrice,
                        promotionalPriceWholesale,
                        priceType?.value
                      );
                      return <span style={style}>{currency(Sale_Price)}</span>;
                    },
                  },
                  {
                    Header: 'Localização',
                    accessor: 'productLocation',
                    minWidth: 100,
                    resizable: false,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Inf. Complementar',
                    accessor: 'productAdditionalInfo',
                    minWidth: 200,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Apelido',
                    accessor: 'productSurname',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Código alternativo',
                    accessor: 'productAlternativeCode',
                    minWidth: 120,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Montadora',
                    accessor: 'vehicleBrand',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Família',
                    accessor: 'familyDescription',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Garantia',
                    accessor: 'warrantyDescription',
                    minWidth: 150,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                ]
              : [
                  {
                    Header: '',
                    width: 80,
                    Cell: (props) => {
                      return (
                        <div className="flex center gap-025">
                          <FontAwesomeIcon
                            style={{
                              width: 16,
                              height: 16,
                              color: 'green',
                              cursor: 'pointer',
                            }}
                            icon={faCirclePlus}
                            onClick={() => handleAddItem(props.original)}
                          />
                          {(isPlanPrime || isWorkmotor) && (
                            <FontAwesomeIcon
                              style={{
                                width: 16,
                                height: 13,
                                cursor: 'pointer',
                              }}
                              icon={faImages}
                              onClick={() =>
                                handleOpenProductImageModal(props.original)
                              }
                            />
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: () => (
                      <>
                        <FontAwesomeIcon
                          style={{
                            width: 15,
                            height: 15,
                            color: 'black',
                          }}
                          icon={faMagnifyingGlassDollar}
                        />
                      </>
                    ),
                    id: 'similarityIcon',
                    minWidth: 30,
                    Cell: (props) => {
                      const [isSimilarityModalOpen, setIsSimilarityModalOpen] =
                        useState(false);

                      return (
                        <>
                          <div className="flex center gap-025">
                            {!hideSimilarityIcon && (
                              <FontAwesomeIcon
                                style={{
                                  width: 15,
                                  height: 15,
                                  cursor: 'pointer',
                                  color: props.original.hasSimilarity
                                    ? '#629c44'
                                    : '',
                                }}
                                icon={faEquals}
                                onClick={() => {
                                  if (props.original.hasSimilarity) {
                                    setIsSimilarityModalOpen(true);
                                  } else {
                                    toastr.warning(
                                      'Produto sem similaridade existente hoje'
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>

                          {isSimilarityModalOpen && (
                            <SearchSimilarityModal
                              handleClose={() =>
                                setIsSimilarityModalOpen(false)
                              }
                              product={props.original}
                              handleAddItemCallback={handleAddItem}
                              allowPromotionalPrices={allowPromotionalPrices}
                              additionalInformationQuery={additionalInformation}
                            />
                          )}
                        </>
                      );
                    },
                  },
                  {
                    Header: 'Código',
                    accessor: 'productCode',
                    minWidth: 60,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Descrição',
                    accessor: 'productDescription',
                    id: 'productDescription',
                    minWidth: 200,
                    getProps: (state, rowInfo, column) => {
                      return {
                        style: {
                          fontSize: '11px',
                          fontWeight: 'regular',
                        },
                      };
                    },
                    Cell: (props) => {
                      return (
                        <div className="flex center column">
                          {props.value}
                          {props.original.brandDescription ? (
                            <div className="flex center">
                              <Text fontWeight="bold" size="sm">
                                Marca:{' '}
                                {props.original.brandDescription
                                  ? props.original.brandDescription
                                  : 'Não possui marca'}
                              </Text>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: 'Ref. Fabricante',
                    accessor: 'productManufacturerRef',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Código de Barras',
                    accessor: 'productBarCode',
                    minWidth: 120,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Estoque',
                    accessor: 'productQuantity',
                    minWidth: 80,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Veículo',
                    accessor: 'vehicleModel',
                    minWidth: 180,
                    getProps: (state, rowInfo, column) => {
                      return {
                        style: {
                          fontSize: '11px',
                          fontWeight: 'regular',
                        },
                      };
                    },
                    Cell: (props) => {
                      const style = props.original.outOfStock
                        ? { color: '#d9534f' }
                        : {};

                      if (!props.value) {
                        return <span style={style}>Não possui veículo</span>;
                      }

                      return (
                        <div className="flex center column" style={style}>
                          {props.value}
                          {props.original.initialYear ? (
                            <div className="flex center">
                              <Text fontWeight="bold" size="sm">
                                Ano: {props.original.initialYear} -{' '}
                                {props.original.finalYear}
                              </Text>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    Header: 'R$ Venda',
                    accessor: 'productSalePrice',
                    minWidth: 100,
                    Cell: (props) => {
                      const {
                        outOfStock,
                        productSalePrice,
                        productSalePriceWholesale,
                        promotionalPrice,
                        promotionalPriceWholesale,
                      } = props.original;
                      let style = {};
                      if (outOfStock) {
                        style.color = '#d9534f';
                      } else if (
                        promotionalTableisValid &&
                        allowPromotionalPrices &&
                        (promotionalPrice > 0 || promotionalPriceWholesale > 0)
                      ) {
                        style.color = '#629c44';
                      }
                      const { Sale_Price } = getProductPromotionalPrices(
                        productSalePrice,
                        productSalePriceWholesale,
                        promotionalTableisValid
                          ? allowPromotionalPrices
                          : false,
                        promotionalPrice,
                        promotionalPriceWholesale,
                        priceType.value
                      );
                      return <span style={style}>{currency(Sale_Price)}</span>;
                    },
                  },
                  {
                    Header: 'Inf. Complementar',
                    accessor: 'productAdditionalInfo',
                    minWidth: 140,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Localização',
                    accessor: 'productLocation',
                    minWidth: 100,
                    resizable: false,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Apelido',
                    accessor: 'productSurname',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Código alternativo',
                    accessor: 'productAlternativeCode',
                    minWidth: 120,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Montadora',
                    accessor: 'vehicleBrand',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Família',
                    accessor: 'familyDescription',
                    minWidth: 100,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                  {
                    Header: 'Garantia',
                    accessor: 'warrantyDescription',
                    minWidth: 150,
                    Cell: (props) =>
                      props.original.outOfStock ? (
                        <span style={{ color: '#d9534f' }}>{props.value}</span>
                      ) : (
                        <span>{props.value}</span>
                      ),
                  },
                ]),
          ]}
          pageSize={searchType === 'Produtos' ? data.length : kits.length}
          getTdProps={(base) => ({
            display: 'flex',
            alignItems: 'center',
            height: '45px',
          })}
          showPagination={false}
          sortable={false}
          noDataText="Nenhum produto encontrado"
          loading={loading || isLoadingMore}
          loadingText="Carregando..."
        />
      </InfiniteScroll>
    </div>
  );
};

export default SearchModalTableWithInfiniteScroll;
