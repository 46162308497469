import React from 'react'

import LoaderSpinner from '../LoaderSpinner'

import './styles.css'

const InvoiceProcessLoader = ({ message, title }) => {
  return (
    <div id='invoice-process-loader'>
      <div className='main-content'>
        {<h2>{title}</h2> || <h2>Aguarde o processamento da nota</h2>}
        <LoaderSpinner
          style={{
            fontSize: '3px',
            margin: '10px',
          }}
        />
        <span>
          {message ||
            'Estamos consultando o status da nota na prefeitura. Por favor, aguarde.'}
        </span>
      </div>
    </div>
  )
}

export default InvoiceProcessLoader
