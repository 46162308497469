import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { FormProvider, useForm } from 'react-hook-form';

import { CRMBonusCreateCustomerModalForm } from './Form';

import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';

import customersRepository from 'repositories/Customers';
import { useBonus } from 'hooks/useBonus';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { useEffect } from 'react';

function handleValidateClientData(data) {
  if (
    !data.cpf ||
    !data.name ||
    !data.email ||
    !data.birthdate ||
    !data.phoneNumber
  ) {
    return false;
  }

  return true;
}

function handleFormatClientData(clientData, companyId) {
  return {
    identifiers: {
      Phone_id: clientData.phoneId,
    },
    customer: {
      Type: 'Fisica',
      Status: 'Ativo',
      Cpf_Cnpj: clientData?.cpf ? onlyNumbers(clientData.cpf) : null,
      Company_Name: clientData.name,
      Email: clientData.email,
      Date_Birth: clientData.birthdate,
      Company_id: companyId,
    },
    phones: {
      Number_Phone2: clientData?.phoneNumber
        ? onlyNumbers(clientData.phoneNumber)
        : null,
    },
  };
}

export const CRMBonusCreateCustomerModal = ({
  isOpen,
  handleCancelCreate,
  handleProceed,
  customerCPF,
}) => {
  const [isLoading, setIsLoading] = useState('');

  const { setCRMBonusCurrentCustomer, companyId, crmBonusCurrentCustomer } =
    useBonus();

  const formMethods = useForm({
    defaultValues: {
      id: '',
      cpf: '',
      name: '',
      email: '',
      birthdate: null,
      phoneId: '',
      phoneNumber: '',
      companyId: companyId,
    },
  });

  const { register, control, setValue, getValues } = formMethods;

  useEffect(() => {
    if (
      customerCPF !== '00000000000' ||
      customerCPF !== null ||
      customerCPF !== ''
    ) {
      setValue('cpf', customerCPF ? cpfMask(customerCPF) : '');
    }
  }, []);

  async function handleValidateCustomerCPFUse(cpf) {
    try {
      const res = await customersRepository.getByCompanyAndCpfCnpj({
        cpfCnpj: onlyNumbers(cpf),
        companyId: companyId,
      });

      if (res.status === true) {
        toastr.warning('CPF já existente', 'Já existe um cliente com este CPF');
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao validar o CPF do cliente',
        'Por favor, tente novamente'
      );
      return true;
    }
  }

  async function handleCreateClientData() {
    try {
      const clientData = getValues();
      const isValid = handleValidateClientData(clientData);

      if (!isValid) {
        return toastr.warning(
          'OS Digital',
          'Existem campos em branco, por favor preencha todo os campos obrigatórios e tente novamente!'
        );
      }

      setIsLoading(true);

      const isCPFAlreadyRegistered = await handleValidateCustomerCPFUse(
        clientData.cpf
      );
      if (isCPFAlreadyRegistered) return;

      const formattedCustomerData = handleFormatClientData(
        clientData,
        companyId
      );

      const createdCustomer = await customersRepository.create(
        formattedCustomerData
      );

      const serializedCustomer = {
        id: createdCustomer.id,
        Company_Name: clientData.name,
        Cpf_Cnpj: clientData.cpf,
        Date_Birth: clientData.birthdate,
        Email: clientData.email,
        Company_id: clientData.companyId,
        Phones: [
          {
            Number_Phone2: clientData.phoneNumber,
          },
        ],
      };

      setCRMBonusCurrentCustomer(serializedCustomer);

      toastr.success('OS Digital', 'Cliente atualizado com Sucesso!');
      handleProceed(serializedCustomer);
    } catch (err) {
      console.log(err);
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao atualizar os dados do cliente. Tente novamente!'
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal show={isOpen} dialogClassName="modal-60w">
      <Modal.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '0',
            height: '50px',
          }}
        >
          <h1 style={{ margin: '0' }}>Cadastro de Cliente</h1>
          <span style={{ color: 'red', margin: '0' }}>
            *Campos Obrigatórios
          </span>
        </div>
      </Modal.Header>
      <Modal.Body style={{ width: '100%' }}>
        <FormProvider {...formMethods}>
          <CRMBonusCreateCustomerModalForm />
        </FormProvider>
      </Modal.Body>
      <div className="flex end gap-050" style={{ padding: '0 10px 10px 10px' }}>
        <button
          className="button button-red w-100px"
          onClick={handleCancelCreate}
        >
          Cancelar
        </button>
        <button
          disabled={isLoading}
          className="button button-green w-100px"
          onClick={handleCreateClientData}
        >
          Salvar
        </button>
      </div>
    </Modal>
  );
};
