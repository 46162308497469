import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import GroupedInvoiceEmissionRepository from 'v2/repositories/GroupedInvoiceEmissionRepository';
import { useState } from 'react';
import constants from 'utils/constants';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useHistory } from 'react-router-dom';

export const useGroupedInvoiceEmission = (getValues, setValue) => {
  const { companyId } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = [
    {
      value: 'closingDate',
      label: 'Fechamento',
    },
    {
      value: 'createdAt',
      label: 'Criação',
    },
  ];

  const validateFields = () => {
    const { searchInput, typeDate, initialDate, finalDate } = getValues();
    let hasError = false;

    if (!searchInput || !searchInput.value?.customer_id) {
      toastr.error('Por favor, selecione um cliente.');
      hasError = true;
    }
    if (!typeDate || !typeDate.value) {
      toastr.error('Por favor, selecione um tipo de data.');
      hasError = true;
    }
    if (!initialDate) {
      toastr.error('Por favor, selecione uma data inicial.');
      hasError = true;
    }
    if (!finalDate) {
      toastr.error('Por favor, selecione uma data final.');
      hasError = true;
    }

    return !hasError; // Retorna true se não houver erros, false caso contrário
  };

  const handleGetSalesInvoice = async () => {
    if (!validateFields()) return;
    try {
      setIsLoading(true);
      const { searchInput, typeDate, initialDate, finalDate } = getValues();
      const response = await GroupedInvoiceEmissionRepository.getSalesInvoices({
        companyId,
        customerId: searchInput.value.customer_id,
        typeDate: typeDate.value,
        initialDate,
        finalDate,
        page: 1,
        limit: 1000,
      });

      setValue('listSaleInvoices', response);
      toastr.success(`Consulta realizada com sucesso`);
    } catch (err) {
      toastr.error('Erro ao realizar consulta ', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendGroupedInvoices = async () => {
    try {
      setIsLoading(true);
      const { selectedSaleInvoices } = getValues();

      const saleIds = selectedSaleInvoices.map((invoice) => invoice.id);
      const response =
        await GroupedInvoiceEmissionRepository.sendGroupedInvoices(
          companyId,
          saleIds
        );

      setValue('listSaleInvoices', response);
      toastr.success(`Agrupamento realizado com sucesso`);
      const hash = await encrypt(String(response.id), '@OS-dig:NfeId');
      history.push(`${constants.ROUTES.EDIT_NFE}?id=${hash}`);
    } catch (err) {
      toastr.error('Erro ao realizar agrupamento ', err.message);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    handleGetSalesInvoice,
    handleSendGroupedInvoices,
    isLoading,
    typeOptions,
  };
};
