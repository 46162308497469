import React from 'react';
import { Caption, IconStyled } from './CaptionWithIcon.styles';

export function CaptionWithIcon({ children, icon, color }) {
  return (
    <Caption color={color}>
      <IconStyled icon={icon} color={color} />
      {children}
    </Caption>
  );
}
