import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

/* modificado */
import { useDispatch, useSelector } from 'react-redux'

import CardForm from '../../../components/CardForm';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import FormSubmitButtons from '../../../components/FormSubmitButtons';
import DadosFormaPagamento from './DadosFormaPagamento';
import constants from '../../../../utils/constants'

function FormNewFormOfPayment({ handleSubmit, history, loading, closeModal, openModal, formOfPaymentId }) {
  const [dadosFormaPagamento, setDadosFormaPagamento] = useState(true);
  const [cancelModal, setCancelModal] = useState(false);

  function handleCancel() {
    setCancelModal(false);
    if (openModal) closeModal()
    else history.push(constants.ROUTES.PAYMENT_FORMS)
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
      <CardForm
        show={dadosFormaPagamento}
        title="Dados da Forma de Pagamento"
        onClick={() => setDadosFormaPagamento(!dadosFormaPagamento)}
      >
        <DadosFormaPagamento />
      </CardForm>

      <FormSubmitButtons loading={loading} onCancel={() => setCancelModal(true)} hasId={!!formOfPaymentId} onSubmit={handleSubmit} />

      {cancelModal &&
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar?'
          onSubmit={handleCancel}
        />}

    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formOfPaymentRegister'
})(withRouter(FormNewFormOfPayment));