import React from 'react'
import { Document, Text, Page, StyleSheet, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, percentage } from 'client/components/ToNormalize/ToNormalize'


const PerApplicationPDF = (responseData) => {
  const reportData = responseData.responseData.reportData
  const vehicleModel = responseData.responseData.vehicleModel
  const reportTotal = responseData.responseData.reportTotal

  const formatedArray = reportData.map((item) => ({
    code: item?.Code,
    manufacturerRef: item?.Manufacturer_Ref,
    descriptionProduct: item.Description,
    stockQuantity: item.Stock_Quantity,
    purchasePrice: item.Purchase_Price,
    profitMargin: item.Profit_Margin,
    salePrice: item.Sale_Price,
    descriptionFamilies: item.familyDescription,
    descriptionBrands: item.brandDescription,
  }))


  return (
    <Document
      title={`peças-aplicáveis-veículo-${format(new Date(), 'dd/MM/yyyy')}`}
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View >
          <Text style={styles.title}>
            Peças Aplicáveis ao Veículo
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Marca:
            </Text>
            <Text style={[styles.text]}> {vehicleModel.Brand}</Text>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
              Modelo:
            </Text>
            <Text style={[styles.text]}> {vehicleModel.Code_FIPE} - {vehicleModel.Model}</Text>
          </View>
        </View>
        <TableHeader
          formatedArray={formatedArray} reportTotal={reportTotal}
        />
      </Page>
    </Document>
  )
}

const TableHeader = ({ formatedArray, reportTotal }) => {
  return (
    <>

      <View style={[styles.line]} />
      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Totais por Família
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '20%' }]}>Família</Text>
        <Text style={[styles.boldText, { width: '10%', textAlign: 'center' }]}>Quantidade</Text>
      </View>

      <View style={styles.line} />

      {
        reportTotal.map((item, key) => (
          <TotalsByTypeStatusItems item={item} key={key} />
        ))
      }

      <View style={styles.line} />

      <View >
        <Text style={[styles.title, { textAlign: 'center' }]}>
          Listagem de Peças
        </Text>
      </View>

      <PartsPerApplicationDetailsTableHeader formatedArray={formatedArray} />


      {
        formatedArray.map((item, index) => (<PartsPerApplicationDetails item={item} key={index} />))
      }

      <View style={styles.line} />



    </>
  )
}
const TotalsByTypeStatusItems = ({ item, key }) => {
  return (
    <View ke={key} style={styles.containerRow}>
      <View style={{ width: '20%' }}>
        <Text style={styles.text}>{item.familyDescription}</Text>
      </View>
      <View style={{ width: '10%', textAlign: 'center' }}>
        <Text style={styles.text}>{item.totalProducts}</Text>
      </View>
    </View>
  )
}


const PartsPerApplicationDetailsTableHeader = () => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '7%' }]}>Código</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Ref.Fabricante</Text>
        <Text style={[styles.boldText, { width: '14%' }]}>Marca</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>R$ Compra</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Margem</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>R$ Venda</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const PartsPerApplicationDetails = ({ item }) => {

  return (
    <View >


      <PartsPerApplicationTableItem item={item} />


    </View>
  )
}

const PartsPerApplicationTableItem = ({ item, key }) => {


  return (
    <View key={key}>
      <View style={styles.containerRow}>
        <Text style={[styles.text, { width: '9%', left: '19px' }]}>{item.Code || '1'}</Text>
        <Text style={[styles.text, { width: '22%', right: '8px' }]}>{item.descriptionProduct}</Text>
        <Text style={[styles.text, { width: '17%', left: '3px' }]}>{item.manufacturerRef}</Text>
        <Text style={[styles.text, { width: '20%', left: '4px' }]}>{item.descriptionBrands}</Text>
        <Text style={[styles.text, { width: '12%' }]}>{item.stockQuantity}</Text>
        <Text style={[styles.text, { width: '10%', right: '28px' }]}>{currency(item.salePrice)}</Text>
        <Text style={[styles.text, { width: '13%', right: '18px' }]}>{percentage(item.profitMargin)}</Text>
        <Text style={[styles.text, { width: '16%', right: '13px' }]}>{currency(item.purchasePrice)}</Text>
      </View>
      <View style={{ marginTop: '5px' }} />
    </View>
  )
}

export default PerApplicationPDF



const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },

})