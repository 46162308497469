import React, { useState, useRef, useCallback } from 'react';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { Table } from 'v2/components/Table';
import { format, parseISO } from 'date-fns';
import { PaymentAuthorizationManyHeaderCount } from './PaymentAuthorizationManyHeaderCount';

export function PaymentAuthorizationCodeReviewManySalesTable({ sales }) {
  const [authorizationCodes, setAuthorizationCodes] = useState(sales);

  const handleTableDataChange = useCallback(
    (index, field, value) => {
      if (authorizationCodes[index][field] !== value) {
        const updatedData = [...authorizationCodes];
        updatedData[index][field] = value;
        setAuthorizationCodes(updatedData);
      }
    },
    [authorizationCodes]
  );

  function getStatusValue(id) {
    const statusTable = {
      2: 'Cancelado',
      3: 'Em Aberto',
      5: 'Finalizado',
    };
    return statusTable[id] || 'Status desconhecido';
  }

  const configColumns = [
    {
      Header: 'Venda',
      accessor: 'saleId',
      width: 80,
    },
    {
      Header: 'Título',
      accessor: 'title',
      width: 80,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 110,
      Cell: ({ value }) => {
        let backgroundColor = '#f0ad4e';

        if (value === 3) {
          backgroundColor = 'rgb(255, 196, 13)';
        } else if (value === 2) {
          backgroundColor = 'red';
        } else if (value === 5) {
          backgroundColor = 'green';
        }

        return (
          <span
            style={{
              backgroundColor,
              borderRadius: '5px',
              width: '100px',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {getStatusValue(value)}
          </span>
        );
      },
    },
    {
      Header: 'Vencimento',
      accessor: 'dueDate',
      Cell: (props) => {
        return format(parseISO(props.value), 'dd/MM/yyyy');
      },
      width: 80,
    },
    {
      Header: 'Valor',
      accessor: 'amount',
      Cell: (props) => currency(String(props.value)),
      width: 120,
    },
    {
      Header: 'Forma de Pagamento',
      accessor: 'paymentForm',
    },
    {
      Header: 'Código de Autorização de Pagto',
      Cell: (props) => {
        const [localValue, setLocalValue] = useState(
          props.original?.authorizationCode
        );
        const [initialValue, setInitialValue] = useState(
          props.original?.authorizationCode
        );

        const inputRef = useRef(null);

        const handleInputChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleInputBlur = () => {
          if (localValue !== initialValue) {
            handleTableDataChange(props.index, 'authorizationCode', localValue);
          }

          inputRef.current.blur();
        };

        return (
          <div>
            <input
              className="form-control"
              type="text"
              value={localValue}
              fontSize={'13px'}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              ref={inputRef}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        marginTop: '1.125rem',
        width: '99%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <PaymentAuthorizationManyHeaderCount sales={sales} />
      <Table
        columns={configColumns}
        data={authorizationCodes}
        style={{ padding: '0px' }}
        cellStyle={{ padding: '0px' }}
      />
    </div>
  );
}
