import api from '../services/api'

const authorizate = async (data) => {
  const response = await api.post('/koin/auth', data)
  return response.data
}

const createTransaction = async (data) => {
  const response = await api.post('/koin', data)
  return response.data
}

const FinancialBankSlip = {
  authorizate,
  createTransaction,
}

export default FinancialBankSlip
