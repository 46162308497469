import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import "./styles.css";

import Button from "client/components/CustomButton/Button.jsx";
import { phoneMask } from "../../../../client/components/ToNormalize/ToNormalize";

const PasswordInput = ({ password }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="password-div">
      <input
        autoComplete="off"
        type={showPassword ? "text" : "password"}
        maxLength="18"
        className="form-control foco-input"
        value={password}
      />
      <span
        className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
        onClick={() => setShowPassword(!showPassword)}
      />
    </div>
  );
};

const UsersData = () => {
  const { meusDados } = useSelector((state) => state.form);
  const allUsers = meusDados?.values?.allUsers;
  const [loading] = useState(false);
 
  return (
    <div style={{ marginTop: "-20px" }}>
      <div className="quebralinha">
        <ReactTable
          style={{ fontWeight: "bold", textAlign: "center" }}
          data={allUsers}
          columns={[
            {
              Header: "Nome",
              accessor: "Name",
              headerClassName: "text-left",
              width: 300,
            },
            {
              Header: "E-mail",
              accessor: "Email",
              headerClassName: "text-left",
              width: 250,
            },
            {
              Header: "Telefone",
              accessor: "Phone",
              headerClassName: "text-center",
              width: 150,
              filterable: false,
              Cell: (props) => phoneMask(props.value),
            },
            {
              Header: "Ultimo Acesso",
              accessor: "lastLogin",
              headerClassName: "text-left",
              width: 200,
              Cell: (props) =>
                format(new Date(props.value), "dd/MM/yyyy HH:mm:ss"),
            },
            {
              Header: "Status",
              accessor: "IsActive",
              headerClassName: "text-left",
              width: 100,
              Cell: (props) => (
                <Button
                  id="botaoReactTable"
                  style={{ backgroundColor: props.value ? "green" : "red" }}
                >
                  {props.value ? "Ativo" : "Inativo"}
                </Button>
              ),
            },
            {
              Header: "Senha",
              accessor: "User_Password",
              headerClassName: "text-left",
              width: 130,
              Cell: (props) => <PasswordInput password={props.value} />,
            },
          ]}
          defaultPageSize={5}
          className=""
          loading={loading}
          showPagination={true}
          sortable={false}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: "Name",
              desc: false,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>
    </div>
  );
};

export default UsersData;
