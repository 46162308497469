import React, { useState, useEffect, useCallback } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faEdit,
  faSave,
  faTrashAlt,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import CardForm from 'client/components/CardForm';

import api from '../../../services/api';
import config from '../../../config';
import useFilters from '../../../hooks/useFilters';

import PartApplicationRepository from '../../../repositories/PartApplications';
import productsRepository from '../../../repositories/Products';

import './styles.css';
import AddPartApplicationModal from './AddPartApplicationModal';
import Button from 'client/components/CustomButton/Button';
import CustomButton from 'client/components/CustomButton/CustomButton';
import AlertModal from 'components/AlertModal/AlertModal';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import { useMemo } from 'react';

const HomePartApplications = ({ productId }) => {
  const [loading, setLoading] = useState(false);

  const [showAddPartApplicationModal, setShowPartApplicationRegisterModalOpen] =
    useState(false);

  const [brandsOptions, setBrandsOptions] = useState([]);

  const { queryFilter, multiplesOptionsFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const {
    setMultiplesOptions: setBrandsOptionsFilter,
    filterByMultiplesOptions: filterByBrands,
  } = multiplesOptionsFilter;

  const [appliedVehicles, setAppliedVehicles] = useState([]);

  let appliedVehiclesBrands = useMemo(
    () => ['Todas', ...appliedVehicles?.map((vehicle) => vehicle.Brand)],
    [appliedVehicles]
  );

  const filteredBrandsOptions = useMemo(
    () =>
      brandsOptions.filter((option) =>
        appliedVehiclesBrands.includes(option?.label)
      ),
    [brandsOptions, appliedVehiclesBrands]
  );

  const [partApplicationToDelete, setPartApplicationToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleteAllApplicationModalOpen, setIsDeleteAllApplicationModalOpen] =
    useState(false);

  /* Current product information */
  const [code, setCode] = useState(null);
  const [description, setDescription] = useState(null);
  const [manufacturerRef, setManufacturerRef] = useState(null);
  const [brand, setBrand] = useState([]);
  const [productInfo, setProductInfo] = useState({});

  const [brandLabel, setBrandLabel] = useState('');

  const [editingInitialYear, setEditingInitialYear] = useState(0);
  const [editingFinalYear, setEditingFinalYear] = useState(0);

  const [currentEditingRow, setCurrentEditingRow] = useState(null);

  const fillEditingYears = () => {
    const partApplication = appliedVehicles[currentEditingRow].PartsApplication;

    setEditingInitialYear(partApplication.initialYear);
    setEditingFinalYear(partApplication.finalYear);
  };

  const resetEditingYears = () => {
    setEditingInitialYear(0);
    setEditingFinalYear(0);
  };

  const handleChangeInitialYear = (value) => {
    const onlyNumbers = value.replace(/\D/g, '');
    const trimmedValue = onlyNumbers.slice(0, 4);

    setEditingInitialYear(trimmedValue ? parseInt(trimmedValue) : '');
    setTimeout(() => {
      document.getElementById('initialYearInput').focus();
    }, [50]);
  };

  const handleChangeFinalYear = (value) => {
    const onlyNumbers = value.replace(/\D/g, '');
    const trimmedValue = onlyNumbers.slice(0, 4);

    setEditingFinalYear(trimmedValue ? parseInt(trimmedValue) : '');
    setTimeout(() => {
      document.getElementById('finalYearInput').focus();
    }, [50]);
  };

  useEffect(() => {
    if (currentEditingRow !== null) {
      fillEditingYears();
    } else {
      resetEditingYears();
    }
  }, [currentEditingRow]);

  useEffect(() => {
    if (!!productId) {
      loadPartApplication();
    }
  }, [productId]);

  useEffect(() => {
    if (!!productId) {
      loadProduct();
    }
  }, [productId]);

  useEffect(() => {
    loadBrands();
  }, []);

  function handleFilter(vehicle) {
    const querySearch = [
      vehicle['Code_FIPE'],
      vehicle['Model'],
      vehicle['Brand'],
    ];
    return filterByQuery(querySearch) && filterByBrands('Brand', vehicle);
  }

  function handleDeleteButton(value) {
    setPartApplicationToDelete({
      vehicleId: value.id,
      partApplicationId: value.PartsApplication.id,
    });
    setIsDeleteModalOpen(true);
  }

  async function loadBrands() {
    try {
      const response = await api.get(config.endpoint + `tabelaFipe/getBrands`);

      let brands = response.data.data.map((brand) => ({
        value: brand.Brand,
        label: brand.Brand,
      }));

      brands.unshift({ value: '*', label: 'Todas' });
      setBrandsOptions(brands);
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  }

  async function loadProduct() {
    setLoading(true);
    try {
      const productData = await productsRepository.getById(productId);
      setProductInfo(productData);
      setCode(productData.Code);
      setDescription(productData.Description);
      setManufacturerRef(productData.Manufacturer_Ref);
      setBrandLabel(productData?.Brands?.Description);
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as informações do produto.');
    } finally {
      setLoading(false);
    }
  }

  async function loadPartApplication() {
    setLoading(true);
    try {
      const { data: partApplication } = await PartApplicationRepository.show(
        productId
      );
      setAppliedVehicles(partApplication.ProductsVehicleApplication || []);
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar a aplicação');
    } finally {
      setLoading(false);
    }
  }

  async function handleDeletePartApplication() {
    try {
      await PartApplicationRepository.deletePartApplication({
        productId,
        vehicleId: partApplicationToDelete.vehicleId,
        partApplicationId: partApplicationToDelete.partApplicationId,
      });
      setIsDeleteModalOpen(false);
      loadPartApplication();
      toastr.success('Aplicação de veículo removido com sucesso.');
    } catch (err) {
      return toastr.warning(err.message);
    }
  }

  async function handleDeleteAllPartApplication() {
    setLoading(true);
    try {
      await PartApplicationRepository.deleteAllPartApplicationsByProduct(
        productId
      );

      setIsDeleteAllApplicationModalOpen(false);
      loadPartApplication();
      toastr.success(
        'Aplicações removidas',
        'Todos os veículos foram excluídos com sucesso.'
      );
    } catch (err) {
      toastr.error('Ocorreu um erro ao excluir as aplicações');
    } finally {
      setLoading(false);
    }
  }

  async function handleYearsRange({ initialYear, finalYear, model, codeFipe }) {
    if (initialYear > finalYear) {
      toastr.warning(
        'Ano inválido',
        'O ano inicial não pode ser maior que o ano final.'
      );
      return false;
    }

    if (initialYear.length < 4 || finalYear < 4) {
      toastr.warning(
        'Ano inválido',
        'Insira um ano válido para aplicação e tente novamente'
      );
      return false;
    }

    for (let i = 0; i < appliedVehicles.length; i++) {
      if (
        codeFipe !== appliedVehicles[i].Code_FIPE &&
        model === appliedVehicles[i].Model &&
        ((initialYear >= appliedVehicles[i].PartsApplication.initialYear &&
          initialYear <= appliedVehicles[i].PartsApplication.finalYear) ||
          (finalYear >= appliedVehicles[i].PartsApplication.initialYear &&
            finalYear <= appliedVehicles[i].PartsApplication.finalYear))
      ) {
        toastr.warning(
          'Não foi possível salvar',
          'Já existe uma aplicação que possui parte ou total período informado. Verifique e tente novamente'
        );
        return false;
      }
    }

    return true;
  }

  async function handleEditConfirmation(index) {
    appliedVehicles.filter(handleFilter)[index].PartsApplication.initialYear =
      editingInitialYear.toString();
    appliedVehicles.filter(handleFilter)[index].PartsApplication.finalYear =
      editingFinalYear.toString();

    const appliedVehicleToUpdate = appliedVehicles.filter(handleFilter)[index];

    const isValid = await handleYearsRange({
      initialYear: appliedVehicleToUpdate.PartsApplication.initialYear,
      finalYear: appliedVehicleToUpdate.PartsApplication.finalYear,
      model: appliedVehicleToUpdate.Model,
      codeFipe: appliedVehicleToUpdate.Code_FIPE,
    });

    if (!isValid) return;

    setLoading(true);

    try {
      await PartApplicationRepository.updatePartApplication({
        vehicleId: appliedVehicleToUpdate.PartsApplication.vehicleId,
        productId: appliedVehicleToUpdate.PartsApplication.productId,
        initialYear: appliedVehicleToUpdate.PartsApplication.initialYear,
        finalYear: appliedVehicleToUpdate.PartsApplication.finalYear,
      });

      setCurrentEditingRow(null);
      loadPartApplication();
      toastr.success(
        'Alteração realizada com sucesso',
        'A alteração de ano foi salva com sucesso.'
      );
    } catch (e) {
      toastr.error('Erro', 'Ocorreu um erro ao atualizar a aplicação.');
    } finally {
      setLoading(false);
      setCurrentEditingRow(null);
    }
  }

  const handleSetBrandsFilter = useCallback(() => {
    const serializedBrandsDescription = brand.map((brand) => brand.value);
    setBrandsOptionsFilter(serializedBrandsDescription);
  }, [brand]);

  useEffect(() => {
    handleSetBrandsFilter();
  }, [brand]);

  return (
    <>
      <CardForm show title="Aplicação de Peça" style={{ marginTop: '30px' }}>
        <div>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ padding: '0px', paddingBottom: '10px', width: '70%' }}
          >
            <Col xs={12} sm={12} md={2} lg={2}>
              <label htmlFor="code">Código:</label>
              <input
                class="product-input-values"
                name="code"
                value={code}
                disabled
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <label htmlFor="description">Descrição:</label>
              <input
                name="description"
                class="product-input-values"
                value={description}
                disabled
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <label htmlFor="ref">Ref. Fabricante:</label>
              <input
                class="product-input-values"
                value={manufacturerRef}
                disabled
                name="ref"
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <label htmlFor="brand">Marca:</label>
              <input
                class="product-input-values"
                name="brand"
                value={brandLabel}
                disabled
                style={{ width: '100%' }}
              />
            </Col>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ padding: '0px', padding: '10px 0', gap: '10px' }}
          >
            <Col xs={12} sm={12} md={2} lg={2}>
              <button
                className="btn btn-sucesso"
                onClick={() => setShowPartApplicationRegisterModalOpen(true)}
              >
                + Nova Aplicação
              </button>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} style={{ marginTop: '3px' }}>
              <div>
                <i id="iconepesquisar" className="fa fa-search"></i>
                <input
                  type="text"
                  style={{ paddingLeft: '30px' }}
                  className="form-control foco-input"
                  placeholder="Pesquisar por Código Fipe ou Modelo"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </Col>

            <Col xs={12} sm={12} md={3} lg={3}>
              <InputContainer variant="row">
                <InputLabel>Marca:</InputLabel>
                <SelectMulti
                  value={brand}
                  options={filteredBrandsOptions}
                  onChange={(e) => setBrand(e)}
                  hasAllOption
                  customAllLabel="Todas"
                  customVariousLabel="Várias"
                  selectAllByDefault
                  height={'35px'}
                />
              </InputContainer>
            </Col>

            <Col xs={12} sm={12} md={2} lg={2}>
              <CustomButton
                bsStyle="danger"
                name="Voltar"
                onClick={() => setIsDeleteAllApplicationModalOpen(true)}
                disabled={loading}
                fill
                style={{
                  backgroundColor: '#d9534f',
                  border: '1px solid #d43f3a',
                }}
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
                Excluir todas aplicações
              </CustomButton>
            </Col>
          </Col>

          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={appliedVehicles.filter(handleFilter)}
              columns={[
                {
                  Header: 'Montadora',
                  accessor: 'Brand',
                  headerClassName: 'text-left',
                  width: 160,
                },
                {
                  Header: 'Modelo',
                  accessor: 'Model',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Ano Inicial',
                  accessor: 'PartsApplication.initialYear',
                  headerClassName: 'text-center',
                  width: 100,
                  Cell: (props) => {
                    const showForm = currentEditingRow === props.index;

                    if (showForm) {
                      return (
                        <input
                          id="initialYearInput"
                          type="text"
                          pattern="[0-9]*"
                          className="form-control"
                          value={editingInitialYear}
                          onChange={(e) => {
                            handleChangeInitialYear(e.target.value);
                          }}
                        />
                      );
                    }

                    return props.value;
                  },
                },
                {
                  Header: 'Ano Final',
                  accessor: 'PartsApplication.finalYear',
                  width: 100,
                  Cell: (props) => {
                    const showForm = currentEditingRow === props.index;

                    if (showForm) {
                      return (
                        <input
                          id="finalYearInput"
                          type="text"
                          pattern="[0-9]*"
                          className="form-control"
                          value={editingFinalYear}
                          onChange={(e) => {
                            handleChangeFinalYear(e.target.value);
                          }}
                        />
                      );
                    }

                    return props.value;
                  },
                },
                {
                  Header: 'Código FIPE',
                  accessor: 'Code_FIPE',
                  headerClassName: 'text-left',
                  width: 160,
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  width: 120,
                  Cell: (props) => {
                    if (currentEditingRow === props.index) {
                      return (
                        <>
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            color="green"
                            style={{ height: '1.5em', width: '1.5em' }}
                            icon={faCheckSquare}
                            onClick={() => handleEditConfirmation(props.index)}
                          />

                          <FontAwesomeIcon
                            title="Cancelar"
                            cursor="pointer"
                            color="red"
                            style={{ height: '1.5em', width: '1.5em' }}
                            icon={faXmark}
                            onClick={() => setCurrentEditingRow(null)}
                          />
                        </>
                      );
                    }

                    return (
                      <>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          color="black"
                          style={{ height: '1.5em', width: '1.5em' }}
                          icon={faEdit}
                          onClick={() => setCurrentEditingRow(props.index)}
                        />

                        <FontAwesomeIcon
                          title="Excluir"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'red',
                            paddingLeft: '1px',
                          }}
                          icon={faTrashAlt}
                          onClick={() => handleDeleteButton(props.original)}
                        />
                      </>
                    );
                  },
                },
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Code',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há Aplicação de Peça"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />

            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                justifyContent: 'flex-end',
                marginTop: '5px',
              }}
            >
              <span
                style={{
                  fontSize: '13px',
                  color: '#d43f3a',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {appliedVehicles.filter(handleFilter).length} veículos
                </span>{' '}
                sendo exibidos
              </span>
            </div>
          </div>
        </div>
      </CardForm>

      {showAddPartApplicationModal ? (
        <AddPartApplicationModal
          show={showAddPartApplicationModal}
          setShow={setShowPartApplicationRegisterModalOpen}
          productInfo={productInfo}
          brandsOptions={brandsOptions}
          setBrandsOptions={setBrandsOptions}
          appliedVehicles={appliedVehicles}
          loadPartApplication={loadPartApplication}
        />
      ) : (
        ''
      )}

      {isDeleteModalOpen && (
        <AlertModal
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          message={`Você tem certeza que deseja excluir a aplicação do produto? A exclusão é irreversível e aplicar o veículo novamente se necessário.`}
          onSubmit={handleDeletePartApplication}
        />
      )}

      {isDeleteAllApplicationModalOpen && (
        <AlertModal
          title={'OS Digital'}
          show={isDeleteAllApplicationModalOpen}
          onHide={() => setIsDeleteAllApplicationModalOpen(false)}
          onCancel={() => setIsDeleteAllApplicationModalOpen(false)}
          onCancelLabel="Cancelar"
          onSubmitLabel="Confirmar"
          message={
            <>
              <span>
                <strong>Você deseja excluir todas as aplicações?</strong>
              </span>
              <br />
              <br />
              <span>
                O processo é irreversível. Ao excluir o produto ficará sem
                aplicação e poderá dificultar a busca de peça na venda, mas
                novas aplicações poderão ser adicionadas porteriormente.
              </span>
            </>
          }
          onSubmit={handleDeleteAllPartApplication}
        />
      )}
    </>
  );
};

export default withRouter(HomePartApplications);
