import React from "react";
import { Grid, Row } from "react-bootstrap";

import BundleCompanyData from './Form'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../utils/constants'

const MyCompanyData = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={['Início', 'Minha Empresa', 'Meus Dados']}
            path={[constants.ROUTES.HOME, constants.ROUTES.COMPANIES, null]}
          />
          <BundleCompanyData />
        </Row>
      </Grid>
    </div>
  );
}

export default MyCompanyData;