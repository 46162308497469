import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import ReactTable from 'react-table'
import {
  faSearchPlus,
  faEdit,
  faCheckSquare,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PercentInput from '../../../../components/Percent'
import {
  currency,
  CFOPMask,
} from '../../../../components/ToNormalize/ToNormalize'
import ProductTaxModal from './ProductTaxModal'

import { PRODUCT_CSOSN, PRODUCT_CST } from '../../../../../utils/constants'
import { useAuth } from '../../../../../contexts/auth'
import SATCFeRepository from '../../../../../repositories/SATCFe'

import natureOfTransactionsRepository from '../../../../../repositories/NatureOfTransactions'

import './styles.css'

const Items = ({ isMultiInvoiceModal }) => {
  const [index, setIndex] = useState(null)
  const [cfops, setCfops] = useState([])
  const [edit, setEdit] = useState(false)
  const [row, setRow] = useState('')
  const [isProductTaxModalOpen, setIsProductTaxModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const formName = isMultiInvoiceModal ? 'invoiceForm' : 'cadastroSATCFe'
  const {
    SATItems,
    subTotal,
    totalProdutos,
    isInvoiceOpen,
    isInvoiceOpenedWithErrors,
    discount,
    isTaxRegimeSimpleNational,
  } = useSelector((state) => state.form[formName].values)

  const dispatch = useDispatch()
  const { companyId } = useAuth()

  useEffect(() => {
    if (!!companyId) {
      loadCFOPs()
    }
  }, [])

  const loadCFOPs = async () => {
    try {
      const cfops =
        await natureOfTransactionsRepository.getAllByCompanyAndDefaults(
          companyId
        )
      setCfops(cfops)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as CFOPs. Por favor, tente novamente'
      )
    }
  }

  const handleCheckCSOSN = async (value) => {
    let valueCsosn = await value
    if (!valueCsosn) return

    if (valueCsosn.length === 3) {
      let checkCSOSN
      PRODUCT_CSOSN.forEach((csosn) => {
        if (csosn === valueCsosn) {
          checkCSOSN = true
        }
      })

      if (!checkCSOSN) return toastr.warning('CSOSN inválida')
    }
  }

  const handleCheckCST = async (value) => {
    let valueCst = await value
    if (!valueCst) return

    if (valueCst.length === 2) {
      let checkCST
      PRODUCT_CST.forEach((cst) => {
        if (cst === valueCst) {
          checkCST = true
        }
      })

      if (!checkCST) return toastr.warning('CST inválido')
    }
  }

  const handleOpenProductTaxModal = async (index) => {
    setIndex(index)
    await dispatch(change('cadastroSATCFe', 'editItem', SATItems[index]))
    setIsProductTaxModalOpen(true)
  }

  const changeField = async (field, value, id) => {
    if (id === 'CSOSN' && value.length > 3) {
      return
    } else if (id === 'CST' && value.length > 2) {
      return
    }
    await dispatch(change('cadastroSATCFe', field, value))

    document.getElementById(id).focus()

    if (id === 'CSOSN') {
      handleCheckCSOSN(value)
    } else if (id === 'CST') handleCheckCST(value)
  }

  const editRowItem = async (index) => {
    setEdit(true)
    setRow(index)
  }

  const handleEditItem = async () => {
    const item = SATItems[row]
    setLoading(true)
    try {
      await SATCFeRepository.updateItem(item.id, item)
      setEdit(false)
    } catch (err) {
      toastr.warning(
        'Ocorreu algum problema ao atualizar o item.',
        'Por favor, tente novamente mais tarde.'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={SATItems || []}
        columns={[
          {
            Header: 'Código',
            id: 'code',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 70,
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            headerClassName: 'text-left',
            width: 320,
          },
          {
            Header: 'QTD',
            accessor: 'quantity',
            headerClassName: 'text-left',
            width: 70,
          },
          {
            Header: 'Desc. Unit.',
            accessor: 'unitDiscountValue',
            headerClassName: 'text-left',
          },
          {
            Header: 'Preço Unit.',
            accessor: 'unitValue',
            headerClassName: 'text-left',
            Cell: (props) => <a>{currency(props.value)}</a>,
          },
          {
            Header: isTaxRegimeSimpleNational ? 'CSOSN' : 'CST',
            accessor: 'CSOSN',
            headerClassName: 'text-left',
            Cell: (props) => (
              <div>
                {isTaxRegimeSimpleNational ? (
                  <input
                    disabled={edit && props.index === row ? false : true}
                    type="number"
                    maxLength="3"
                    value={props.value}
                    id="CSOSN"
                    className="form-control foco-input meu-input"
                    onChange={(e) =>
                      changeField(
                        `SATItems[${props.index}].CSOSN`,
                        e.target.value,
                        'CSOSN'
                      )
                    }
                  />
                ) : (
                  <input
                    disabled={edit && props.index === row ? false : true}
                    type="number"
                    maxLength="2"
                    value={props.value}
                    id="CST"
                    className="form-control foco-input meu-input"
                    onChange={(e) =>
                      changeField(
                        `SATItems[${props.index}].CSOSN`,
                        e.target.value,
                        'CST'
                      )
                    }
                  />
                )}
              </div>
            ),
          },
          {
            Header: '% ICMS',
            accessor: 'ICMS',
            headerClassName: 'text-left',
            Cell: (props) => (
              <div>
                <PercentInput
                  suffix="%"
                  id="ICMS"
                  className="form-control foco-input"
                  name="ICMS"
                  decimalSeparator=","
                  inputType="text"
                  onChangeEvent={(e) =>
                    changeField(
                      `SATItems[${props.index}].ICMS`,
                      e.target.value,
                      'ICMS'
                    )
                  }
                  value={props.value}
                  disabled={edit && props.index === row ? false : true}
                />
              </div>
            ),
          },
          {
            Header: 'CFOP',
            accessor: 'CFOP',
            headerClassName: 'text-left',
            Cell: (props) => (
              <div>
                <input
                  list="CFOP_list"
                  value={CFOPMask(String(props.value))}
                  id="CFOP"
                  disabled={edit && props.index === row ? false : true}
                  className="form-control foco-input"
                  onChange={(e) =>
                    changeField(
                      `SATItems[${props.index}].CFOP`,
                      e.target.value,
                      'CFOP'
                    )
                  }
                />
                <datalist id="CFOP_list">
                  {cfops.map((c) => {
                    if (
                      String(c.CFOP).charAt([0]) === '5' &&
                      c.type.toLowerCase().includes('saída')
                    ) {
                      return (
                        <option key={c.id} value={c.CFOP}>
                          {c.CFOP}
                        </option>
                      )
                    }
                  })}
                </datalist>
              </div>
            ),
          },
          {
            Header: '% FCP',
            accessor: 'FCP',
            headerClassName: 'text-left',
            Cell: (props) => (
              <div>
                <PercentInput
                  suffix="%"
                  id="FCP"
                  className="form-control foco-input"
                  name="Discount_Value"
                  decimalSeparator=","
                  inputType="text"
                  onChangeEvent={(e) =>
                    changeField(
                      `SATItems[${props.index}].FCP`,
                      e.target.value,
                      'FCP'
                    )
                  }
                  value={props.value}
                  disabled={edit && props.index === row ? false : true}
                />
              </div>
            ),
          },
          {
            Header: '',
            accessor: 'Actions',
            headerClassName: 'text-left',
            filterable: false,
            width: 100,
            Cell: (props) => (
              <>
                {edit && props.index === row ? (
                  loading ? (
                    <i className="fa fa-spinner fa-pulse fa-1x" />
                  ) : (
                    <div className="table-actions">
                      <FontAwesomeIcon
                        title="Confirmar"
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={handleEditItem}
                        icon={faCheckSquare}
                      />
                      <FontAwesomeIcon
                        style={{ color: 'red' }}
                        title="Fechar"
                        onClick={() => setEdit(false)}
                        cursor="pointer"
                        icon={faTimes}
                      />
                    </div>
                  )
                ) : (
                  <div className="table-actions">
                    <FontAwesomeIcon
                      onClick={() =>
                        isInvoiceOpen || isInvoiceOpenedWithErrors
                          ? editRowItem(props.index)
                          : ''
                      }
                      icon={faEdit}
                      style={{
                        cursor:
                          isInvoiceOpen || isInvoiceOpenedWithErrors
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                    />
                    <FontAwesomeIcon
                      onClick={() => handleOpenProductTaxModal(props.index)}
                      cursor="pointer"
                      icon={faSearchPlus}
                    />
                  </div>
                )}
              </>
            ),
          },
        ]}
        defaultPageSize={5}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <Col xs={12} sm={12} md={12} lg={12} id="rodape-itensVenda">
        <Col style={{ marginTop: 5, marginRight: '9%' }}>
          <strong>Subtotal:</strong> <span>{currency(subTotal)}</span>
        </Col>
        <Col style={{ marginTop: 5, marginRight: '9%' }}>
          <strong>Desconto:</strong> <span>{currency(discount)}</span>
        </Col>
        <Col style={{ marginTop: 5, marginRight: '9%' }}>
          <strong>Valor Total dos Produtos:</strong>{' '}
          <span>{currency(totalProdutos)}</span>
        </Col>
      </Col>

      {isProductTaxModalOpen && (
        <ProductTaxModal
          onCancel={() => setIsProductTaxModalOpen(false)}
          CFOPs={cfops}
          index={index}
        />
      )}
    </div>
  )
}

export default Items
