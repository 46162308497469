import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

import { usePlanSignatureContext } from 'contexts/plan-signature';

import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { milhar } from 'client/components/ToNormalize/ToNormalize';
import { cepMask } from 'client/components/ToNormalize/ToNormalize';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';

import { customChecklistSheetStyles } from '../../CustomChecklistSheet.styles';

const Card = ({ children, style, ...rest }) => (
  <View style={[customChecklistSheetStyles.cardBorder, style]} {...rest}>
    {children}
  </View>
);

export function ChecklistHeader({ customer, isBike }) {
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const printDate = format(new Date(customer.date), 'dd/MM/yyyy');

  const company = {
    Url_Logo: customer.company.Url_Logo,
    Trading_Name: customer.company.Trading_Name,
    Cpf_Cnpj:
      customer.company.Cpf_Cnpj.length <= 11
        ? cpfMask(customer.company.Cpf_Cnpj)
        : cnpjMask(customer.company.Cpf_Cnpj),
    Type: customer.company.Cpf_Cnpj.length <= 11 ? 'Física' : 'Jurídica',
    IE: customer.company.IE ? customer.company.IE : '',
    Address: `${customer.company.Address[0].Address}, ${customer.company.Address[0].Address_Number}, ${customer.company.Address[0].Neighborhood}`,
    AddressCityState: `${customer.company.Address[0].City}/${customer.company.Address[0].State}`,
    Zipcode: customer.company.Address[0].Zipcode
      ? cepMask(customer.company.Address[0].Zipcode)
      : '',
    Telephone: customer.company.Phones[0].Number_Phone1
      ? phoneMask(customer.company.Phones[0].Number_Phone1)
      : '',
    Email: customer.company.Email ? customer.company.Email : '',
  };

  const vehicle = {
    description: customer.vehicle?.Model || '',
    licensePlate: customer.vehicle?.License_Plate || '',
    color: customer.vehicle?.Color || '',
    manufactureYear: customer.vehicle?.Year_Manufacture || '',
    modelYear: customer.vehicle?.Year_Model || '',
    kilometers: customer.vehicle?.Kilometers
      ? milhar(customer.vehicle?.Kilometers)
      : '',
    brand: customer.vehicle?.Brand,
  };

  const customerName = customer.Company_Name;

  return (
    <View>
      <Card fixed style={{ marginBottom: 7 }}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          {company.Url_Logo ? (
            <View
              style={{
                width: 82,
                height: '100%',
                borderRightWidth: 1,
                borderRightColor: 'black',
                padding: 3,
              }}
            >
              <Image
                src={company.Url_Logo}
                style={{
                  width: 75,
                  height: 60,
                }}
              />
            </View>
          ) : null}

          <View
            fixed
            style={{
              width: '80%',
              marginLeft: 5,
              height: '100%',
              minHeight: 65,
            }}
          >
            <Text style={customChecklistSheetStyles.mainTitle}>
              {company.Trading_Name}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '3px',
                width: '100%',
              }}
            >
              <View>
                <Text>
                  {company.Type === 'Jurídica' ? 'CNPJ' : 'CPF'}:{' '}
                  {company.Cpf_Cnpj}
                </Text>
              </View>

              <View>{company.IE ? <Text>IE: {company.IE}</Text> : null}</View>

              <View>
                <Text>Data: {printDate}</Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row', paddingTop: '3px' }}>
              <Text>
                Endereço: {customer.company.Address[0].Address}{' '}
                {customer.company.Address[0].Address_Number},{' '}
                {customer.company.Address[0].Neighborhood}
              </Text>
              <Text style={{ paddingLeft: '2px' }}>
                - {company.AddressCityState}{' '}
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                display: 'flex',
                paddingTop: '3px',
              }}
            >
              <Text style={{ paddingRight: '10px' }}>
                CEP: {company.Zipcode}
              </Text>
              <Text style={{ paddingRight: '10px' }}>
                Telefone: {company.Telephone}
              </Text>
              <Text>Email: {company.Email}</Text>
            </View>
          </View>
        </View>
      </Card>
      <View
        style={{
          borderTopWidth: 1,
          borderColor: 'black',
          borderStyle: 'dashed',
          borderRadius: 0,
        }}
      />

      {isBike ? (
        <View>
          <View style={{ paddingTop: 7 }}>
            <View style={[customChecklistSheetStyles.customerRow1]}>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>Cliente: </Text>{' '}
                  {customerName}
                </Text>
              </View>
            </View>

            <View style={[customChecklistSheetStyles.customerRow1]}>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Série/Identificação:{' '}
                  </Text>{' '}
                  {vehicle.serialIdentification}
                </Text>
              </View>
            </View>

            <View
              style={[
                customChecklistSheetStyles.customerRow1,
                { justifyContent: 'space-between' },
              ]}
            >
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Marca/Modelo:{' '}
                  </Text>
                  {vehicle?.brand} - {vehicle?.model}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>Ano: </Text>
                  {vehicle?.manufactureYear}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>Aro: </Text>
                  {vehicle?.rimSize}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Tam. do Quadro:{' '}
                  </Text>{' '}
                  {vehicle?.frameSize}
                </Text>
              </View>
            </View>

            <View
              style={[
                customChecklistSheetStyles.customerRow1,
                { justifyContent: 'space-between' },
              ]}
            >
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Modalidade:{' '}
                  </Text>
                  {vehicle?.modality}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Material do Quadro:{' '}
                  </Text>
                  {vehicle?.frameMaterial}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Tipo de Freio:{' '}
                  </Text>
                  {vehicle?.brakeType}
                </Text>
              </View>
            </View>

            <View
              style={[
                customChecklistSheetStyles.customerRow1,
                { justifyContent: 'space-between' },
              ]}
            >
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Tipo de Suspensão:{' '}
                  </Text>
                  {vehicle?.suspensionType}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Câmbio Traseiro:{' '}
                  </Text>
                  {vehicle?.rearDerailleur}
                </Text>
              </View>
            </View>

            <View
              style={[
                customChecklistSheetStyles.customerRow1,
                { justifyContent: 'space-between' },
              ]}
            >
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Marcha Dianteira:{' '}
                  </Text>
                  {vehicle?.frontGears}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>
                    Marcha Traseira:{' '}
                  </Text>
                  {vehicle?.rearGears}
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={customChecklistSheetStyles.bold}>Cor: </Text>
                  {vehicle?.color}
                </Text>
              </View>
            </View>

            {/* Dados do Cliente --- FIM --- */}

            <View style={[customChecklistSheetStyles.customerRow1]}>
              <Text style={customChecklistSheetStyles.bold}>
                Inspeção executada por:
              </Text>
              <View
                style={{
                  width: '80%',
                  borderBottomWidth: 1,
                  borderColor: 'black',
                  borderRadius: 0,
                  marginLeft: 2,
                  height: 15,
                }}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={{ paddingTop: 7 }}>
          <View style={[customChecklistSheetStyles.customerRow1]}>
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Cliente: </Text>{' '}
                {customerName}
              </Text>
            </View>
            <View style={{ paddingLeft: !customerName ? 170 : 20 }}>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Placa: </Text>{' '}
                {vehicle.licensePlate}
              </Text>
            </View>
            <View
              style={{
                paddingLeft: !customerName
                  ? 70
                  : !vehicle.licensePlate
                  ? 70
                  : 20,
              }}
            >
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Veículo: </Text>{' '}
                {vehicle.description}
              </Text>
            </View>
          </View>

          <View
            style={[
              customChecklistSheetStyles.customerRow1,
              { justifyContent: 'space-between' },
            ]}
          >
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Marca: </Text>{' '}
                {vehicle.brand}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>KM Atual: </Text>{' '}
                {vehicle.kilometers}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Cor: </Text>{' '}
                {vehicle.color}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Ano Fb.: </Text>{' '}
                {vehicle.manufactureYear}
              </Text>
            </View>
            <View>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>Ano Mod.: </Text>{' '}
                {vehicle.modelYear}
              </Text>
            </View>
          </View>

          {!isPlanFree && !isPlanStart ? (
            <View style={[customChecklistSheetStyles.customerRow1]}>
              <Text>
                <Text style={customChecklistSheetStyles.bold}>
                  Prisma/Box:{' '}
                </Text>{' '}
                {customer.prismaBox}{' '}
              </Text>
            </View>
          ) : (
            ''
          )}

          <View style={[customChecklistSheetStyles.customerRow1]}>
            <Text style={customChecklistSheetStyles.bold}>
              Inspeção executada por:
            </Text>
            <View
              style={{
                width: '80%',
                borderBottomWidth: 1,
                borderColor: 'black',
                borderRadius: 0,
                marginLeft: 2,
                height: 15,
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
}
