import React, { useEffect, useState } from 'react'
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
  Image,
} from '@react-pdf/renderer'
import { format } from 'date-fns'
import {
  currency,
  phoneMask,
  cpfOrCnpjMask,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize'
import { ViewStream } from '@material-ui/icons'

const number = require('numero-por-extenso')

const BillsToReceiveReceiptDocumentA5 = ({ receipt }) => {
  return (
    <Document title={`impresso-recibo-${format(new Date(), 'dd/MM/yyyy')}`}>
      <Page size="A5" orientation={'landscape'} style={styles.page}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Text style={styles.title}>COMPROVANTE DE RECEBIMENTO</Text>
        </View>

        <CompanyInfo company={receipt.companyInfo} />

        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '18%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CompanySignature />
          </View>
          <View
            style={{
              width: '82%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
            }}
          >
            <InvoiceInfo bill={receipt.billInfo} />
            <CustomerInfo customer={receipt.billInfo.Customer} />
            <InFullValueInfo billPaidValue={receipt.billInfo.paidValue} />
            <ReceivedConfirmSignature />
          </View>
        </View>
      </Page>
    </Document>
  )
}

const CompanySignature = () => {
  return (
    <View
      style={{
        border: '1px solid black',
        borderRadius: '5px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'bottom',
        flexDirection: 'column',
        transform: 'rotate(-90deg)',
        marginTop: '10px',
        marginLeft: '-60px',
        width: '210px',
        height: '90px',
      }}
    >
      <View
        style={{
          marginTop: 'auto',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: '1px',
            height: '1px',
            borderRightWidth: 165,
            borderRightColor: 'black',
            padding: 0.1,
          }}
        ></View>
        <Text style={styles.headerTitle}>ASSINATURA DA EMPRESA</Text>
      </View>
    </View>
  )
}

const ReceivedConfirmSignature = () => {
  return (
    <View
      style={{
        padding: '5px',
        border: '1px solid black',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginTop: '10px',
        width: '100%',
      }}
    >
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Text style={styles.text}>
          Confirmamos o recebimento da quantia apresentada acima pelo pagante
          listado
        </Text>
      </View>
      {/* criar campos de assinatura do pagante e assinatura do vendedor */}
      <View
        style={{
          marginTop: '40',
          display: 'flex',
          justifyContent: 'left',
          flexDirection: 'row',
        }}
      >
        <View style={{ alignItems: 'center', marginRight: 'auto' }}>
          <Text style={{ fontSize: '6', fontStyle: 'italic', fontWeight: 900 }}>
            Esse documento não tem validade fiscal
          </Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View
            style={{
              width: '1px',
              height: '1px',
              borderRightWidth: 165,
              borderRightColor: 'black',
              padding: 0.1,
            }}
          ></View>
          <Text style={{ fontSize: '7px' }}>Assinatura do pagante</Text>
        </View>
      </View>
    </View>
  )
}

const InFullValueInfo = ({ billPaidValue }) => {
  return (
    <View
      style={{
        padding: '5px',
        border: '1px solid black',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginTop: '10px',
        width: '100%',
      }}
    >
      <View>
        <Text style={styles.headerText}>
          <Text style={styles.headerTitle}>VALOR POR EXTENSO: </Text>{' '}
          {number
            .porExtenso(billPaidValue, number.estilo.monetario)
            .toUpperCase()}
        </Text>
      </View>
    </View>
  )
}

const CustomerInfo = ({ customer }) => {
  return (
    <>
      <View
        style={{
          padding: '5px',
          border: '1px solid black',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignContent: 'center',
          marginTop: '10px',
          width: '100%',
        }}
      >
        <View>
          <Text style={styles.text}>
            <Text style={styles.boldText}>NOME DO PAGANTE: </Text>{' '}
            {customer?.Company_Name}
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            <Text style={styles.boldText}>ENDEREÇO: </Text>{' '}
            {customer?.Address[0]
              ? `${customer?.Address[0]?.Address}, ${
                  customer?.Address[0]?.Address_Number
                } - ${cepMask(customer?.Address[0]?.Zipcode)}`
              : ''}
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            <Text style={styles.boldText}>BAIRRO: </Text>{' '}
            {customer?.Address[0]
              ? `${customer?.Address[0]?.Neighborhood} - ${customer?.Address[0]?.City}/${customer?.Address[0]?.State}`
              : ''}
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={styles.text}>
            <Text style={styles.boldText}>TELEFONE: </Text>
            {customer?.Phones[0]?.Number_Phone2
              ? phoneMask(customer?.Phones[0]?.Number_Phone2)
              : phoneMask(customer?.Phones[0]?.Number_Phone1)}
          </Text>
          <Text style={{ fontSize: '8', marginLeft: '10' }}>
            <Text style={styles.boldText}>EMAIL: </Text>
            {customer.Email ? customer.Email : 'Não informado'}
          </Text>
        </View>
      </View>
    </>
  )
}

const InvoiceInfo = ({ bill }) => {
  return (
    <View
      style={{
        padding: '5px',
        border: '1px solid black',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginTop: '10px',
        width: '100%',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.headerTitle}>Fatura nº</Text>
        <Text style={styles.headerText}>{bill.code}</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.headerTitle}>Valor Pago</Text>
        <Text style={styles.headerText}>{currency(bill.paidValue)}</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.headerTitle}>Sequência</Text>
        {/* converting numbers to alphabet letters... 1 = A; 2 = B; 3 = C */}
        <Text style={styles.headerText}>
          {(bill.installment + 9).toString(36).toUpperCase()}
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.headerTitle}>Data de Vencimento</Text>
        <Text style={styles.headerText}>
          {' '}
          {format(new Date(`${bill.dueDate} 23:59:59`), 'dd/MM/yyyy')}
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.headerTitle}>Data de Pagamento</Text>
        <Text style={styles.headerText}>
          {format(
            new Date(`${bill.BillsToReceiveParcels[0].payDate} 23:59:59`),
            'dd/MM/yyyy'
          )}
        </Text>
      </View>
    </View>
  )
}

const CompanyInfo = ({ company }) => {
  if (company.Url_Logo) {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '5px',
          border: '1px solid black',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <View style={{ padding: '10px', width: '15%' }}>
          <Image
            src={company.Url_Logo}
            style={{ width: '80px', height: '60px', borderRadius: '3px' }}
          />
        </View>
        <View
          style={{
            width: '1px',
            borderRightWidth: 1,
            borderRightColor: 'black',
            padding: 6,
          }}
        ></View>
        <View style={{ padding: '10px', width: '60%' }}>
          <Text style={styles.headerTitle}>{company?.Company_Name}</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 'auto',
              justifyContent: 'space-between',
              width: '75%',
            }}
          >
            <Text style={styles.text}>
              CNPJ: {cpfOrCnpjMask(company?.Cpf_Cnpj)}
            </Text>
            <Text style={styles.text}>IE: {company?.IE}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2px',
              justifyContent: 'space-between',
            }}
          >
            {company.Address[0] && (
              <Text style={styles.text}>
                Endereço: {company?.Address[0]?.Address},{' '}
                {company?.Address[0]?.Address_Number},{' '}
                {company?.Address[0]?.Neighborhood} -{' '}
                {company?.Address[0]?.City}/{company?.Address[0]?.State}
              </Text>
            )}
            {!company.Address[0] && (
              <Text style={styles.text}>Endereço: Não informado</Text>
            )}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Text style={styles.text}>
              CEP: {cepMask(company.Address[0].Zipcode)}{' '}
            </Text>
            <Text style={styles.text}>
              Telefone:{' '}
              {company.Phones[0].Number_Phone2
                ? phoneMask(company.Phones[0].Number_Phone2)
                : phoneMask(company.Phones[0].Number_Phone1)}{' '}
            </Text>
            <Text style={styles.text}>Email: {company.Email} </Text>
          </View>
        </View>
        <View style={{ width: '25%', padding: '10px', gap: '5px' }}>
          <Text style={styles.text}>
            <Text style={styles.boldText}>Data de Emissão: </Text>
            {format(new Date(), 'dd/MM/yyyy')}
          </Text>
          <Text style={styles.text}>
            <Text style={styles.boldText}>Hora de Emissão: </Text>
            {format(new Date(), 'HH:mm')}
          </Text>
        </View>
      </View>
    )
  } else {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '5px',
          border: '1px solid black',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <View style={{ padding: '10px', width: '80%' }}>
          <Text style={styles.headerTitle}>{company?.Company_Name}</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
              width: '60%',
            }}
          >
            <Text style={styles.text}>
              CNPJ:{' '}
              {company?.Cpf_Cnpj
                ? cpfOrCnpjMask(company.Cpf_Cnpj)
                : 'Não Informado'}
            </Text>
            <Text style={styles.text}>
              IE: {company?.IE ? company.IE : 'Não Informado'}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2px',
              justifyContent: 'space-between',
            }}
          >
            {company.Address[0] && (
              <Text style={styles.text}>
                Endereço: {company?.Address[0]?.Address},{' '}
                {company?.Address[0]?.Address_Number},{' '}
                {company?.Address[0]?.Neighborhood} -{' '}
                {company?.Address[0]?.City}/{company?.Address[0]?.State}
              </Text>
            )}
            {!company.Address[0] && (
              <Text style={styles.text}>Endereço: Não informado</Text>
            )}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2px',
              justifyContent: 'space-between',
            }}
          >
            <Text style={styles.text}>
              CEP:{' '}
              {company.Address[0].Zipcode
                ? cepMask(company.Address[0].Zipcode)
                : 'Não informado'}
            </Text>
            <Text style={styles.text}>
              Telefone:{' '}
              {company.Phones[0].Number_Phone2
                ? phoneMask(company.Phones[0].Number_Phone2)
                : phoneMask(company.Phones[0].Number_Phone1)}
            </Text>
            <Text style={styles.text}>
              Email: {company?.Email ? company.Email : 'Não informado'}
            </Text>
          </View>
        </View>
        <View style={{ width: '50%', padding: '10px', alignItems: 'flex-end' }}>
          <View>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Data de Emissão: </Text>
              {format(new Date(), 'dd/MM/yyyy')}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Hora de Emissão: </Text>
              {format(new Date(), 'HH:mm')}
            </Text>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  headerTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  headerText: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 8,
  },
  boldText: {
    fontSize: 8,
    fontWeight: 'bold',
  },
})

export default BillsToReceiveReceiptDocumentA5
