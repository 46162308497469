import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import AlertModal from 'components/AlertModal/AlertModal';
import {
  FinishedSalesAdvice,
  ModalTitleContainer,
  StepWizardContainer,
  StepWizardWrapper,
} from './GroupTitlesModal.styles';

import { GroupTitlesStepWizard } from './step-wizard';
import useGroupTitlesModalStore from './store';

import {
  groupTitlesConfirmationMessage,
  renderContentByStep,
  SalesSelectionFooter,
} from './utils';

import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { BillsToReceiveRepositoryV2 } from 'v2/repositories/BillsToReceiveRepository';

import { useAuth } from 'contexts/auth';

export function GroupTitlesModal({
  isOpen,
  handleClose,
  handleTriggerLoadList,
}) {
  const { companyId, company } = useAuth();

  const [submitData, setSubmitData] = useState(null);
  const [openSubmitConfirmationModal, setOpenSubmitConfirmationModal] =
    useState(false);

  const {
    currentStep,
    setCurrentStep,
    selectedSales,
    isSubmitting,
    closeTrigger,
    handleCloseTrigger,
  } = useGroupTitlesModalStore();

  const handlePreviousStep = () => {
    if (currentStep === 1) {
      handleClose();
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (currentStep === 2) {
      validateSubmit();
      return;
    }

    if (selectedSales.length !== 0) {
      setCurrentStep(currentStep + 1);
      return;
    }

    toastr.warning(
      currentBrandingName,
      'Selecione ao menos uma Venda/OS para continuar'
    );
  };

  const validateSubmit = () => {
    const {
      selectedCustomer,
      getSelectedSalesIds,
      getSerializedInstallments,
      feeValue,
      getSalesTotal,
    } = useGroupTitlesModalStore.getState();

    const total = getSalesTotal();

    const submitData = {
      billToReceive: {
        observations: 'Título Agrupado',
        customerId: selectedCustomer.value.id,
        isGrouped: true,
        accountPlanId: company.defaultSaleAccountPlanId,
      },
      companyId: company.id,
      salesIds: getSelectedSalesIds(),
      payments: getSerializedInstallments(),
    };

    if (submitData.payments.length === 0) {
      toastr.warning(
        'Parcelas indefinidas',
        'Por favor, preencha as parcelas para continuar'
      );
      return;
    }

    const parcelsWithoutFormOfPayment = submitData.payments.some(
      (parcel) => !parcel?.formOfPaymentId
    );

    if (parcelsWithoutFormOfPayment) {
      toastr.warning(
        'Parcelas sem forma de pagamento',
        'Por favor, preencha todas as formas de pagamento para continuar'
      );
      return;
    }

    const totalParcels = submitData.payments.reduce(
      (prev, curr) => prev + curr.amount,
      0
    );

    if (totalParcels !== total + feeValue) {
      toastr.warning(
        'Somatória das parcelas é diferente o agrupamento total',
        'Por favor, preencha os valores corretamente para continuar'
      );
      return;
    }

    setSubmitData(submitData);
    setOpenSubmitConfirmationModal(true);
  };

  const handleSubmit = async () => {
    const { setIsSubmitting, handleCloseTrigger } =
      useGroupTitlesModalStore.getState();
    setIsSubmitting(true);

    try {
      await BillsToReceiveRepositoryV2.createGroupedBills(submitData);
      setOpenSubmitConfirmationModal(false);

      toastr.success(
        'Criação realizada com sucesso',
        'Os títulos foram criados conforme configurado'
      );

      handleCloseTrigger(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        currentBrandingName,
        'Erro ao criar os títulos. Por favor, tente novamente.'
      );
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    useGroupTitlesModalStore.getState().reset();
  }, []);

  useEffect(() => {
    if (closeTrigger) {
      handleCloseTrigger(false);
      handleTriggerLoadList();
      handleClose();
    }
  }, [closeTrigger]);

  return (
    <>
      <Modal show={isOpen} onHide={() => null} dialogClassName="modal-80w">
        <Modal.Header closeButton>
          <Modal.Title>
            <ModalTitleContainer>
              <strong>Agrupamento de Títulos</strong>
              <FinishedSalesAdvice>
                Apenas Vendas/OS Finalizadas e que não possuem NF-e criadas
                serão exibidas
              </FinishedSalesAdvice>
            </ModalTitleContainer>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StepWizardContainer>
            <StepWizardWrapper>
              <GroupTitlesStepWizard percent={currentStep === 1 ? 1 : 100} />
            </StepWizardWrapper>
          </StepWizardContainer>
          {renderContentByStep(currentStep)}
        </Modal.Body>
        <Modal.Footer>
          <SalesSelectionFooter
            currentStep={currentStep}
            previousStep={handlePreviousStep}
            nextStep={handleNextStep}
          />
        </Modal.Footer>
      </Modal>

      <AlertModal
        title="Confirmar agrupamento"
        show={openSubmitConfirmationModal}
        message={groupTitlesConfirmationMessage()}
        onSubmit={handleSubmit}
        loading={isSubmitting}
        onCancel={() => setOpenSubmitConfirmationModal(false)}
      />
    </>
  );
}
