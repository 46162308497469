import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { toastr } from 'react-redux-toastr'

import ProductRepository from 'repositories/Products'
import FamiliesRepository from 'repositories/Families'
import BrandsRepository from 'repositories/Brands'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DownloadXlsButton from 'components/DownloadXlsButton'

export default function ProductsFamilyMain() {
  const { companyId } = useAuth()

  const [selectedFamilyId, setSelectedFamilyId] = useState('')
  const [familiesOptions, setFamiliesOptions] = useState([])
  const [selectedBrandId, setSelectedBrandId] = useState('')
  const [brandsOptions, setBrandsOptions] = useState([])
  const [selectedInactiveProduct, setSelectedInactiveProduct] = useState(false)
  const [selectedListBy, setSelectedListBy] = useState('brand')
  const [xlsArchiveName, setXlsArchiveName] = useState('')
  const [result, setResult] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const familyId = selectedFamilyId === '' ? '' : selectedFamilyId
      const brandId = selectedBrandId === '' ? '' : selectedBrandId
      const isActive =
        selectedInactiveProduct === false ? false : selectedInactiveProduct

      if (selectedFamilyId === '' && selectedBrandId === '') {
        toastr.warning(
          'Selecione ao menos uma marca/familia para processar o relatório'
        )
        return
      }

      const data = await ProductRepository.getAllByFamilyOrBrand({
        companyId,
        familyId,
        brandId,
        isActive,
      })

      if (data?.productByFamilyOrBrand.length === 0) {
        toastr.warning(
          'Não foi possível gerar o relatório',
          'Nenhum item foi localizado'
        )
        return
      }

      const products = data.productByFamilyOrBrand.map((product) => {
        let LastDatePurchaseAux = product.PurchaseItems.filter(
          (item) => item.productId === product.id
        )
          .slice(-1)
          .map((value) => value.createdAt)
        let item = {}
        item = product
        item.TotalPurchase = product.Stock_Quantity * product.Purchase_Price
        item.TotalSalesItem = product.Stock_Quantity * product.Sale_Price
        item.nameArchiveName = selectedFamilyId
          ? product.Families?.Description
          : product.Brands?.Description
        item.LastDatePurchase = LastDatePurchaseAux[0]

        return item
      })

      setResult(products)

      handleArchiveName(products)

      if (data.productByFamilyOrBrand.length) {
        window.open(
          window.location.origin +
            `/report/products-family-brand?familyId=${familyId}&brandId=${brandId}&companyId=${companyId}&isActive=${isActive}`,
          '_blank'
        )
      } else {
        return toastr.warning(
          'Não foi possível gerar o relatório.',
          'Nenhum item foi localizado. Verifique os filtros e tente novamente.'
        )
      }
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const loadFamiliesOptions = async () => {
    try {
      const families =
        await FamiliesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        )
      const serializedFamilies = families.map((family) => ({
        id: family.id,
        description: family.Description,
      }))
      setFamiliesOptions(serializedFamilies)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      )
    }
  }

  const loadBrandsOptions = async () => {
    try {
      const brands = await BrandsRepository.getAllByCompany(companyId)
      const serializedBrands = brands.map((brand) => ({
        id: brand.id,
        description: brand.Description,
      }))

      setBrandsOptions(serializedBrands)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      )
      toastr.error(
        'Erro ao carregar as marcas',
        'Tente novamente. Caso persista, contate o suporte!'
      )
    }
  }

  const handleArchiveName = (products) => {
    return setXlsArchiveName(
      `Listagem de peças por família-marca - ${products[0].nameArchiveName}`
    )
  }

  useEffect(() => {
    loadFamiliesOptions()
    loadBrandsOptions()
  }, [])

  useEffect(() => {
    if (selectedListBy && selectedListBy === 'family') {
      setSelectedBrandId('')
    } else {
      setSelectedFamilyId('')
    }
  }, [selectedListBy])

  return (
    <div className="voucher-issued-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Listagem por: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={selectedListBy}
          onChange={(e) => setSelectedListBy(e.target.value)}
        >
          <option value="family">Família</option>
          <option value="brand">Marca</option>
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Família: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={selectedFamilyId}
          onChange={(e) => setSelectedFamilyId(e.target.value)}
          disabled={selectedListBy === 'brand' ? true : false}
        >
          <option value="">Selecione</option>
          {familiesOptions.map((family) => (
            <option key={family.id} value={family.id}>
              {family.description}
            </option>
          ))}
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Marca: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={selectedBrandId}
          onChange={(e) => setSelectedBrandId(e.target.value)}
          disabled={selectedListBy === 'family' ? true : false}
        >
          <option value="">Selecione</option>
          {brandsOptions.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.description}
            </option>
          ))}
        </select>
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Exibir produto inativo: </strong>
        </span>
        <select
          className="form-control foco-input select-families"
          value={selectedInactiveProduct}
          onChange={(e) => setSelectedInactiveProduct(e.target.value)}
        >
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <button
          className="btn btn-sucesso"
          type="submit"
          onClick={handleSubmit}
        >
          <span
            className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />{' '}
          Processar
        </button>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <DownloadXlsButton
          archiveName={xlsArchiveName}
          data={result}
          className="btn btn-export"
          disabled={isLoading || !result.length}
          columns={[
            {
              name: 'Código',
              acessor: 'Code',
            },
            {
              name: 'Descrição',
              acessor: 'Description',
            },
            {
              name: 'Peso',
              acessor: 'Weight',
            },
            {
              name: 'Dt. Atualiz.',
              acessor: 'updatedAt',
            },
            {
              name: 'Dt. Compra',
              acessor: 'LastDatePurchase',
            },
            {
              name: 'Margem',
              acessor: 'Profit_Margin',
            },
            {
              name: 'Estoque',
              acessor: 'Stock_Quantity',
            },
            {
              name: 'R$ Compra',
              acessor: 'Purchase_Price',
            },
            {
              name: 'Total Compra',
              acessor: `TotalPurchase`,
            },
            {
              name: 'R$ Venda',
              acessor: 'Sale_Price',
            },
            {
              name: 'Total Venda',
              acessor: `TotalSalesItem`,
            },
          ]}
        >
          <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
          .xls
        </DownloadXlsButton>
      </div>
    </div>
  )
}
