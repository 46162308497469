import React from 'react';
import { format } from 'date-fns';
import SearchInput from 'components/SearchInput';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

import './styles.css';

function VideoLinkFilter({
  history,
  query,
  setQuery,
  branding,
  setBranding,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
}) {
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="quotations-row">
        <div>
          <button
            className="btn btn-sucesso"
            onClick={() => history.push('videos-register')}
          >
            + Novo Vídeo
          </button>
        </div>

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <SearchInput
            placeholder="Pesquisa por Descrição ou Página"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="quotations-row-data">
        <div className="filter-links-videos col-xs-12 col-sm-7 col-md-7 col-lg-7">
          <span
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <strong>Produto:</strong>
          </span>
          <select
            className="form-control foco-input"
            value={branding}
            onChange={(e) => setBranding(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="1">OS Digital</option>
            <option value="2">Workmotor</option>
            <option value="3">Tecnomotor</option>
            <option value="4">Barros</option>
            <option value="5">PO360</option>
          </select>
          <span
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={
              finalDate
                ? finalDate
                : format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')
            }
            onChange={(e) => {
              setInitialDate(e.target.value);
            }}
          />
          <span
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataFinal"
            value={finalDate}
            min={initialDate}
            max={format(getDateOnlyFromDate(new Date()), 'yyyy-MM-dd')}
            onChange={(e) => {
              setFinalDate(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default VideoLinkFilter;
