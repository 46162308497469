import api from 'v2/services/api';

const getBillsToday = async (companyId) => {
  try {
    const response = await api.get(
      `management-indicator/bills-today/` + companyId
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getBillsMonth = async (companyId, date) => {
  try {
    const response = await api.get(
      `management-indicator/bills-month/` + companyId,
      {
        params: {
          date,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getOrcamentsOS = async (companyId, date) => {
  try {
    const response = await api.get(
      `management-indicator/orcaments-os/` + companyId,
      {
        params: {
          date,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAverageTicket = async (companyId, date) => {
  try {
    const response = await api.get(
      `management-indicator/average-ticket/` + companyId,
      {
        params: {
          date,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getItemsSold = async (companyId, date) => {
  try {
    const response = await api.get(
      `management-indicator/items-sold/` + companyId,
      {
        params: {
          date,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAverageTicketGraph = async (companyId, period) => {
  try {
    const response = await api.get(
      `management-indicator/average-ticket-graph/` + companyId,
      {
        params: {
          period,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getRevenueGraph = async (companyId, period) => {
  try {
    const response = await api.get(
      `management-indicator/revenue-graph/` + companyId,
      {
        params: {
          period,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getCashierBankFlowGraph = async (companyId, period) => {
  try {
    const response = await api.get(
      `management-indicator/cashier-bank-flow-graph/` + companyId,
      {
        params: {
          period,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const ManagementIndicatorRepository = {
  getBillsToday,
  getBillsMonth,
  getOrcamentsOS,
  getAverageTicket,
  getItemsSold,
  getCashierBankFlowGraph,
  getAverageTicketGraph,
  getRevenueGraph,
};

export default ManagementIndicatorRepository;
