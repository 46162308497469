import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from 'client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';

import './styles.css';

function NewsRegisterForm({ onSubmit, videosLinksId, register, handleSubmit }) {
  const [basicData, setBasicData] = useState(true);
  const history = useHistory();

  const submitValidationUrl = (submitValues) => {
    submitValues();
  };

  return (
    <div style={{ marginTop: 30 }}>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <CardForm
          show={basicData}
          title="Dados da Notícia"
          onClick={() => setBasicData(!basicData)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: 120 }}>
            <label>
              Produto:<span style={{ color: 'red' }}>*</span>
            </label>
            <select
              type="text"
              className="form-control"
              name="brandingId"
              id="brandingId"
              {...register('brandingId')}
              maxLength="1"
            >
              <option value="">Selecione</option>
              <option value="1">OS Digital</option>
              <option value="2">Workmotor</option>
              <option value="3">Tecnomotor</option>
              <option value="4">Barros</option>
              <option value="5">PO360</option>
            </select>
          </div>
          <div className="news-register-form">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 400,
              }}
            >
              <label htmlFor="description">
                Página:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                {...register('route')}
                className="form-control"
                name="route"
                id="description"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 400,
              }}
            >
              <label htmlFor="description">
                Descrição:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                {...register('title')}
                className="form-control"
                name="title"
                id="description"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 700,
              }}
            >
              <label>
                Link do Vídeo:<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                {...register('link')}
                className="form-control"
                name="link"
                id="url"
                maxLength="100"
              />
            </div>
          </div>
        </CardForm>

        <FormSubmitButtons
          onCancel={() => {
            history.push('/admin/videos');
          }}
          backLabel={videosLinksId}
          hasId={videosLinksId}
          onSubmit={() => submitValidationUrl(handleSubmit(onSubmit))}
        />
      </form>
    </div>
  );
}

export default NewsRegisterForm;
