import React from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NewVoucherPaymentMain from './components/NewVoucherPaymentMain'

import './styles.css'

const NewVoucherPaymentForm = ({ match, history }) => {
  return (
    <div className="content">
      <BreadCrumb
        data={[
          'Início',
          'Pagamento de Vale',
          !match.params.id ? 'Novo Pagamento' : `Editar Pagamento`,
        ]}
        path={['home', 'voucher-payments', null]}
      />
      <span id="required-field-label">* Campos Obrigatórios</span>

      <NewVoucherPaymentMain
        id={history.location?.hash?.split('#')[1]}
        redirect={() => history.push('/client/voucher-payments')}
      />
    </div>
  )
}
export default withRouter(NewVoucherPaymentForm)
