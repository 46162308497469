import {
  faCloudDownloadAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DownloadXlsButton from 'components/DownloadXlsButton'
import React from 'react'
import PDFButton from 'client/components/PDFButton'
import xlsColumns from './ExcelHelper'
import '../index.css'

const CustomerListFilter = ({
  customers,
  setCustomerType,
  setState,
  setType,
  setStatus,
  isLoading,
  setIsReadyToLoad,
  isDisabled,
  setIsDisabled,
  loadCustomers,
  openPDF,
  excelData,
}) => {
  const handleLoad = () => {
    setIsReadyToLoad(true)
    setIsDisabled(false)
    loadCustomers()
  }

  return (
    <>
      <div className="client__customer-list-rows">
        <div className="client__customer-list-columns">
          <span>
            <strong>Tipo de Cliente:</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setCustomerType(e.target.value)}
          >
            <option value="">Ambos</option>
            <option value="Fisica">Pessoa Física</option>
            <option value="Juridica">Pessoa Jurídica</option>
          </select>
        </div>

        <div className="client__customer-list-columns">
          <span>
            <strong>UF Principal:</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setState(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="AC">AC</option>
            <option value="AL">AL</option>
            <option value="AP">AP</option>
            <option value="AM">AM</option>
            <option value="BA">BA</option>
            <option value="CE">CE</option>
            <option value="DF">DF</option>
            <option value="ES">ES</option>
            <option value="GO">GO</option>
            <option value="MA">MA</option>
            <option value="MT">MT</option>
            <option value="MS">MS</option>
            <option value="MG">MG</option>
            <option value="PA">PA</option>
            <option value="PB">PB</option>
            <option value="PR">PR</option>
            <option value="PE">PE</option>
            <option value="PI">PI</option>
            <option value="RJ">RJ</option>
            <option value="RN">RN</option>
            <option value="RS">RS</option>
            <option value="RO">RO</option>
            <option value="RR">RR</option>
            <option value="SC">SC</option>
            <option value="SP">SP</option>
            <option value="SE">SE</option>
            <option value="TO">TO</option>
          </select>
        </div>

        <div className="client__customer-list-column">
          <span>
            <strong>Tipo:</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="Sintetico">Sintético</option>
            <option value="Analitico">Analítico</option>
          </select>
        </div>

        <div className="client__customer-list-column">
          <span>
            <strong>Exibir inativos?</strong>
          </span>
          <select
            className="form-control foco-input"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={0}>Não</option>
            <option value={1}>Sim</option>
          </select>
        </div>

        <div className="client__customer-list-button">
          <button
            className="btn btn-sucesso"
            type="submit"
            onClick={() => handleLoad()}
          >
            <span
              className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Processar
          </button>
        </div>
        <div className="client__customer-list-button">
          <DownloadXlsButton
            archiveName="Listagem de Clientes"
            data={excelData}
            className="btn btn-export"
            disabled={isLoading || !excelData.length}
            columns={xlsColumns}
            style={{ width: '100%' }}
          >
            <span
              className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
        <div className="client__customer-list-button">
          <PDFButton
            isLoading={isLoading}
            disabled={isDisabled || isLoading || !customers.length}
            onClick={() => openPDF()}
          />
        </div>
      </div>
    </>
  )
}

export default CustomerListFilter
