import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { isValid, isBefore, differenceInDays } from 'date-fns'
import Form from './Form'
import AlertModal from 'components/AlertModal/AlertModal'
import billsToPayRepository from '../../../../repositories/BillsToPay'
import './styles.css'
import { useAuth } from 'contexts/auth'
import { useSelector } from 'react-redux'

export const GenerateCurrentBill = ({ onCancel, onSuccess }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { companyId } = useAuth()

  const generateCurrentBillForm = useSelector(state => state.form.generateCurrentBill)

  const submit = values => {
    if (!validations(values)) return
    setIsConfirmModalOpen(true)
  }

  const validations = values => {
    const {
      initialDate,
      finalDate,
      daysBetweenInstallments,
      valueByInstallment,
      providerId,
      cashierBankId,
      formOfPaymentId,
      accountPlanId
    } = values

    if (
      !initialDate ||
      !finalDate ||
      !valueByInstallment ||
      !providerId ||
      !cashierBankId ||
      !formOfPaymentId||
      !accountPlanId
    ) {
      toastr.warning('Preencha todos os campos obrigatórios para continuar.')
      return false
    }

    if (!isValid(new Date(initialDate))) {
      toastr.warning('Data Inicial está invália.')
      return false
    }

    if (!isValid(new Date(finalDate))) {
      toastr.warning('Data Final está invália.')
      return false
    }

    if (isBefore(new Date(finalDate), new Date(initialDate))) {
      toastr.warning('Valide as datas e adicione um período válido para continuar.')
      return false
    }

    const days = Math.abs(differenceInDays(new Date(initialDate), new Date(finalDate)))

    if (days < Number(daysBetweenInstallments)) {
      toastr.warning('Valide as datas e adicione um período válido juntamente com o dia entre parcelas para continuar.')
      return false
    }

    return true
  }

  const handleGenerate = async () => {
    setLoading(true)
    try {
      await billsToPayRepository.generateCurrentBill(companyId, generateCurrentBillForm.values)
      toastr.success('Conta recorrente gerada com sucesso.')
      onSuccess()
    } catch (err) {
      toastr.error(err?.response?.data?.message || 'Ocorreu algum erro ao gerar as contas. Por favor, tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Form
        initialValues={{
          daysBetweenInstallments: 30,
          providerId: null,
          searchProvider: '',
        }}
        onSubmit={submit}
        onCancel={onCancel}
      />

      <AlertModal
        show={isConfirmModalOpen}
        onHide={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        message="Deseja criar os títulos da conta recorrente?"
        onSubmit={handleGenerate}
        loading={loading}
      />
    </>
  )
}