import api from '../services/api';
import config from '../config';

const getAll = async () => {
  const response = await api.get(config.endpoint + 'segment');
  return response.data;
};

const getByUser = async (userId) => {
  return await api.get(config.endpoint + `companySegment/${userId}/by-user`);
};
const getByCompany = async (companyId) => {
  const { data } = await api.get(
    config.endpoint + `companySegment/Search/ByCompany/${companyId}`
  );
  return data;
};

const segmentsRepository = {
  getAll,
  getByUser,
  getByCompany,
};

export default segmentsRepository;
