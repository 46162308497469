import React, { useEffect, useState } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import NFSeForm from './NFSeForm';
import constants from '../../../utils/constants';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

const NFSe = ({ history, location }) => {
  const [id, setId] = useState('');
  const [invoiceCode, setInvoiceCode] = useState(null);

  useEffect(() => {
    localStorage.setItem('selectedPath', 'client/invoices');
    checkParams();
  }, [location]);

  function checkParams() {
    const params = new URLSearchParams(location.search);
    const idParams = params.get('id');

    if (idParams) readHash(idParams);
  }

  async function readHash(hash) {
    const hashDecrypted = await decrypt(hash, '@OS-dig:NfseId');
    setId(hashDecrypted);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Notas Fiscais',
              'Nota Fiscal de Serviço (NFS-e)',
              !invoiceCode ? 'Nova NFS-e' : `NFS-e ${invoiceCode}`,
            ]}
            path={[
              constants.ROUTES.HOME,
              null,
              constants.ROUTES.SERVICE_INVOICES,
              null,
            ]}
          />
          <NFSeForm
            NFSeId={id}
            setInvoiceCode={setInvoiceCode}
            onSubmit={() => history.push(constants.ROUTES.SERVICE_INVOICES)}
            onCancel={() => history.push(constants.ROUTES.SERVICE_INVOICES)}
          />
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(NFSe);
