import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';

import { toastr } from 'react-redux-toastr';
import { withRouter, Link } from 'react-router-dom';
import { format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCity,
  faGears,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

import Button from 'client/components/CustomButton/Button.jsx';
import SearchInput from 'components/SearchInput';
import { useAdminAuth } from 'contexts/adminAuth';

import constants from 'utils/constants';
import useDebounce from 'hooks/useDebounce';

import NFSePatternsRepository from 'repositories/NFSePatterns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const NFSeSettingsMain = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);

  const [offline, setOffline] = useState('');
  const [isHomologated, setIsHomologated] = useState('');

  const [nfsePatterns, setNfsePatterns] = useState([]);
  const [nfsePatternsCount, setNfsePatternsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const { adminRoleId } = useAdminAuth();

  function validateAdminPermission(url) {
    if (adminRoleId === constants.ADMIN_ROLES.ADMINISTRATOR)
      return history.push(url);
    return toastr.warning(
      'Acesso não permitido',
      'A função que está tentando acessar é uso exclusiva do administrador. Em caso de dúvidas, entre em contato com os responsáveis do desenvolvimento.'
    );
  }

  function handleChangeQuery(value) {
    setQuery(value);
    debouncedSaveQuery(value);
  }

  function handleOpenPatternDocs(patternRow) {
    if (!patternRow.externalUrl) {
      return toastr.warning(
        'Atenção',
        'A documentação não foi aplicada para este padrão'
      );
    }
    return window.open(patternRow.externalUrl, '_blank');
  }

  async function handleLoadPatterns() {
    setLoading(true);
    try {
      const data = await NFSePatternsRepository.index({
        query,
        isHomologated,
        offline,
        page: page + 1,
        limit,
      });

      setNfsePatterns(data.patterns);
      setNfsePatternsCount(data.count);
      setTotalPages(Math.ceil(data.count / limit));
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os dados. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleLoadPatterns();
  }, [offline, isHomologated, queryDebounced, page, limit]);

  return (
    <main className="admin__nfse-settings-page">
      <section className="admin__nfse-settings-page__filter-row">
        <button
          className="btn btn-sucesso"
          onClick={() => validateAdminPermission('nfse-pattern')}
        >
          + Novo Padrão
        </button>
        <div className="search-input-wrapper">
          <SearchInput
            placeholder="Pesquisa por Padrão"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
          />
        </div>
        <fieldset>
          <label style={{ width: '75px' }}>Tipo:</label>
          <select
            value={offline}
            onChange={(e) => setOffline(e.target.value)}
            className="form-control foco-input"
          >
            <option value={''}>Ambos</option>
            <option value={false}>Online</option>
            <option value={true}>Offline</option>
          </select>
        </fieldset>
        <fieldset>
          <label style={{ width: '75px' }}>Status:</label>
          <select
            value={isHomologated}
            onChange={(e) => setIsHomologated(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value={true}>Homologado</option>
            <option value={false}>Não Homologado</option>
          </select>
        </fieldset>
        <button
          className="btn btn-next"
          style={{
            width: '200px',
            marginLeft: '10px',
            fontSize: '14px',
          }}
        >
          Listagem de Cidades
        </button>
      </section>

      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={nfsePatterns}
        columns={[
          {
            Header: 'Padrão',
            accessor: 'pattern',
            width: 300,
          },
          {
            Header: 'Tipo',
            accessor: 'offline',
            width: 100,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: !props.value ? '#5CB85C' : '#f0ad4e',
                }}
              >
                {!props.value ? 'Online' : 'Offline'}
              </Button>
            ),
          },
          {
            Header: 'Cidades',
            accessor: 'linkedCitiesQuantity',
            width: 100,
          },
          {
            Header: 'Status',
            accessor: 'isHomologated',
            width: 200,
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                className="react-table__button-label"
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: props.value ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value ? 'Homologado' : 'Não Homologado'}
              </Button>
            ),
          },
          {
            Header: 'Data Homologação',
            accessor: 'homologationDate',
            width: 140,
            Cell: (props) =>
              props.value
                ? format(
                    getDateOnlyFromDate(new Date(props.value)),
                    'dd/MM/yyyy'
                  )
                : '-',
          },
          {
            Header: 'Tipo de Texto Adicional',
            accessor: 'concatAdditionalInformation',
            width: 300,
            Cell: (props) => (props.value ? 'Concatenado' : 'Campo Próprio'),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 200,
            Cell: (props) => (
              <>
                <span title="Editar Padrão">
                  <FontAwesomeIcon
                    cursor="pointer"
                    onClick={() =>
                      validateAdminPermission(`nfse-pattern#${props.value}`)
                    }
                    icon={faEdit}
                    style={{ width: '1.2em', height: '1.2em' }}
                  />
                </span>
                &nbsp;
                <span title="Listagem de Cidades de Padrão">
                  <FontAwesomeIcon
                    onClick={() =>
                      validateAdminPermission(
                        `nfse-pattern-cities?patternName=${props.original.pattern}&patternId=${props.original.id}`
                      )
                    }
                    cursor="pointer"
                    icon={faCity}
                    style={{ width: '1.2em', height: '1.2em' }}
                  />
                </span>
                &nbsp;
                {!props.row.offline && (
                  <>
                    <span title="Tributos e Operações do Padrão">
                      <FontAwesomeIcon
                        onClick={() =>
                          validateAdminPermission(
                            `nfse-pattern-operation-taxes#${props.value}`
                          )
                        }
                        cursor="pointer"
                        icon={faGears}
                        style={{ width: '1.2em', height: '1.2em' }}
                      />
                    </span>
                    &nbsp;
                    <span title="Abrir documentação do Padrão">
                      <FontAwesomeIcon
                        onClick={() => handleOpenPatternDocs(props.original)}
                        cursor="pointer"
                        icon={faArrowUpRightFromSquare}
                        style={{ width: '1.2em', height: '1.2em' }}
                      />
                    </span>
                  </>
                )}
              </>
            ),
          },
        ]}
        defaultPageSize={10}
        page={page}
        onPageChange={setPage}
        pageSize={limit}
        onPageSizeChange={(value) => {
          setLimit(value);
        }}
        showPagination
        loading={loading}
        pages={totalPages}
        manual
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </main>
  );
};

export default withRouter(NFSeSettingsMain);
