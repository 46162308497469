import React from "react";
import { Grid, Row } from "react-bootstrap";
import { withRouter } from "react-router";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import HomeConfigurations from './Configurations'

const Configurations = ({ history }) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Minha Empresa', 'Configurações']}
            path={['home', 'companies', null]}
          />
            <span id='required-field-label'>* Campos Obrigatórios</span>
            <HomeConfigurations onCancel={history.goBack} />
        </Row>
      </Grid>
    </div>
  );
}

export default withRouter(Configurations)