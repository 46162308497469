import styled from 'styled-components';

export const InputLabelStyled = styled.label`
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) =>
    props.fontWeight || props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.gray500};
  margin: 0 !important;
  padding: 0 !important;
`;
