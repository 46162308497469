import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';

import { HangtagProduct } from './HangtagProduct';
import { HangtagShelf } from './HangtagShelf';

import HangtagRepository from 'v2/repositories/HangtagRepository';

export const HangTagPDF = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [showBarcode, setShowBarcode] = useState(false);
  const [showSalePriceType, setShowSalePriceType] = useState(false);
  const [hangtagModel, setHangtagModel] = useState('product');

  const [hangtagId, setHangtagId] = useState(undefined);
  const [hangtags, setHangtags] = useState([]);

  useEffect(() => {
    setLoading(true);
    handleSetParams();
  }, []);

  useEffect(() => {
    if (hangtagId) {
      handleLoadHangtags();
    }
  }, [hangtagId]);

  const handleSetParams = () => {
    const searchParams = new URLSearchParams(location.search);

    const barcode = searchParams.get('barcode');
    const price = searchParams.get('price');
    const model = searchParams.get('model');
    const id = searchParams.get('id');

    setShowBarcode(barcode);
    setShowSalePriceType(price);
    setHangtagId(id);
    setHangtagModel(model || 'product');
  };

  const multiplyHangtagsByQuantity = (hangtags) => {
    const hangtagsMultiplied = hangtags.map((hangtag) => {
      let hangtagArray = [...Array(hangtag.quantity).keys()];

      hangtagArray = hangtagArray.map((_) => ({
        ...hangtag,
      }));

      return hangtagArray;
    });

    return hangtagsMultiplied;
  };

  const reduceHangtags = (hangtags) => {
    return hangtags.reduce((prev, curr) => [...prev, ...curr], []);
  };

  const divideHangtagsPerPages = (hangtags) => {
    const serializedHangtagsPerPage = [];

    for (let i = 0; i < hangtags.length; i += 20) {
      serializedHangtagsPerPage.push(hangtags.slice(i, i + 20));
    }

    return serializedHangtagsPerPage;
  };

  const pairHangtags = (hangtags) => {
    const pairedHangtags = [];

    for (let i = 0; i < hangtags.length; i++) {
      const subArray = hangtags[i];
      const subArrayPairs = [];

      for (let j = 0; j < subArray.length; j += 2) {
        const pair = subArray.slice(j, j + 2);
        subArrayPairs.push(pair);
      }

      pairedHangtags.push(subArrayPairs);
    }

    return pairedHangtags;
  };

  const serializeHangtags = (hangtags) => {
    const hangtagsMultiplied = multiplyHangtagsByQuantity(hangtags);
    const hangtagsReduced = reduceHangtags(hangtagsMultiplied);
    const hangtagsPerPage = divideHangtagsPerPages(hangtagsReduced);
    const hangtagsPaired = pairHangtags(hangtagsPerPage);

    return hangtagsPaired;
  };

  const handleLoadHangtags = async () => {
    try {
      if (!hangtagId) throw 'hangtagId not specified';
      const hangtags = await HangtagRepository.getHangtags(hangtagId);
      const serializedHangtags = serializeHangtags(hangtags.items);

      setHangtags(serializedHangtags);

      HangtagRepository.deleteHangtags(hangtagId);
    } catch (err) {
      console.log(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar as etiquetas. Por favor, tente novamente" />
    );

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <PDFViewer style={{ height: '100%', width: '100%' }}>
        {hangtagModel === 'product' ? (
          <HangtagProduct
            hangtags={hangtags}
            showBarcode={showBarcode}
            showSalePriceType={showSalePriceType}
          />
        ) : (
          <HangtagShelf hangtags={hangtags} />
        )}
      </PDFViewer>
    </div>
  );
};
