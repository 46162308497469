import React, { useState, useEffect } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import InvoiceProcessLoader from '../../../../components/InvoiceProcessLoader'
import { currency } from 'client/components/ToNormalize/ToNormalize'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { handleChange } from './redux/actions'
import {
  faCar,
  faPrint,
  faTachometerAlt,
  faTasks,
} from '@fortawesome/free-solid-svg-icons'
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature'
import { useAuth } from 'contexts/auth'

import suivCompanyRepository from 'repositories/SuivCompany'

import './styles.css'
import { encrypt } from 'client/components/ToNormalize/ToNormalize'

export const SaleSearchSuiv = ({ saleTypeId }) => {
  const { isPlanFree } = usePlanSignatureContext()
  const { companyId } = useAuth()

  const [suivToken, setSuivToken] = useState('')
  const [colors, setColors] = useState('')
  const [currentTab, setCurrentTab] = useState('register') // register, specifications, revision_plans

  const [suivInformation, setSuivInformation] = useState({
    vehicleInfo: {},
    revisionPlan: [],
    technicalSpecs: {},
  })

  const [loading, setLoading] = useState(false)

  const { selectedVehicle } = useSelector((state) => state.saleReducer)

  const dispatch = useDispatch()
  const {
    saleReducer: { isSearchSuivModalOpen, saleStatusId },
  } = useSelector((state) => state)

  const loadToken = async () => {
    try {
      const { data: suivCompany } = await suivCompanyRepository.showCompany(
        companyId
      )
      setSuivToken(suivCompany.suivToken)
    } catch (err) {
      console.error(err)
    }
  }

  const loadSuivInformation = async () => {
    try {
      setLoading(true)
      const { data } = await suivCompanyRepository.showVehicleInfo(
        selectedVehicle?.License_Plate,
        companyId
      )

      setSuivInformation(data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchSuivModalOpen = async () => {
    if (!suivToken) {
      toastr.error(
        'Função disponível apenas para cliente SUIV. Entre em contato com o nosso comercial para mais informações'
      )
      return
    }

    if (Object.keys(selectedVehicle)?.length === 0) {
      toastr.error('Selecione um veículo para buscar os dados')
      return
    }

    if (
      selectedVehicle?.vehicleTypeId !== 1
    ) {
      toastr.error('Função disponível apenas para Carro, Moto e Caminhão')
      return
    }

    loadSuivInformation()
    dispatch(handleChange(true, 'isSearchSuivModalOpen'))
  }

  useEffect(() => {
    loadToken()
  }, [])

  return (
    <>
      {/* {!isPlanFree ? (
        <Button
          disabled={saleTypeId === 4}
          bsStyle="primary"
          fill
          onClick={() => handleSearchSuivModalOpen()}
          style={{
            marginRight: 10,
          }}
        >
          Pesquisar Dados do Veículo via SUIV
        </Button>
      ) : (
        ''
      )} */}

      {loading ? (
        <InvoiceProcessLoader
          title="Aguarde o Processamento"
          message="Por favor, aguarde, estamos localizando os dados do veículo."
        />
      ) : (
        <Modal
          show={isSearchSuivModalOpen}
          dialogClassName="modal-60w"
          onHide={() => {
            dispatch(handleChange(false, 'isSearchSuivModalOpen'))
          }}
        >
          <div className="tab-header">
            <div
              className={
                currentTab === 'register'
                  ? 'tab-button waves-effect tab-header-selected'
                  : 'tab-button waves-effect '
              }
              onClick={() => setCurrentTab('register')}
            >
              <FontAwesomeIcon
                icon={faCar}
                style={{
                  cursor: 'pointer',
                  height: '30px',
                  width: '30px',
                }}
              />
              <p>Cadastro</p>
            </div>
            <div
              className={
                currentTab === 'specifications'
                  ? 'tab-button waves-effect tab-header-selected'
                  : 'tab-button waves-effect '
              }
              onClick={() => setCurrentTab('specifications')}
            >
              <FontAwesomeIcon
                icon={faTachometerAlt}
                style={{
                  cursor: 'pointer',
                  height: '30px',
                  width: '30px',
                }}
              />
              <p>Especificações</p>
            </div>
            <div
              className={
                currentTab === 'revision_plans'
                  ? 'tab-button waves-effect tab-header-selected'
                  : 'tab-button waves-effect '
              }
              onClick={() => setCurrentTab('revision_plans')}
            >
              <FontAwesomeIcon
                icon={faTasks}
                style={{
                  cursor: 'pointer',
                  height: '30px',
                  width: '30px',
                }}
              />
              <p>Planos de Revisão</p>
            </div>
          </div>

          <div className="content-modalSuiv">
            {currentTab === 'register' ? (
              <div className="fade-in-result limit-height">
                <h3 className="title-suiv">Ficha Cadastral</h3>
                <div className="row-suiv">
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Placa: <b>{selectedVehicle?.License_Plate}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      VIN: <b>{suivInformation.vehicleInfo.chassis}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Número do Motor:{' '}
                      <b>{suivInformation.vehicleInfo.engineNumber}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Número Cx. de Câmbio:{' '}
                      <b>{suivInformation.vehicleInfo.gearBoxNumber}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Cilindradas: <b>{suivInformation.vehicleInfo.cc}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Combustível: <b>{suivInformation.vehicleInfo.fuel}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Segmento: <b>{suivInformation.vehicleInfo.type}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Subsegmento: <b>{suivInformation.vehicleInfo.species}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Carroceria: <b>{suivInformation.vehicleInfo.bodywork}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Potência: <b>{suivInformation.vehicleInfo.power} CV</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Procedência:{' '}
                      <b>{suivInformation.vehicleInfo.isNational}</b>
                    </p>
                  </div>
                  <div className="colSuiv-6">
                    <p className="card-text">
                      Peso Bruto:{' '}
                      <b>{suivInformation.vehicleInfo.totalGrossWeigth}</b>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {currentTab === 'specifications' ? (
              <>
                {!!(Object.keys(suivInformation.technicalSpecs).length > 0) ? (
                  <>
                    <div className="fade-in-result limit-height">
                      <h3 className="title-suiv">Ficha Técnica</h3>
                      <div className="suiv-col">
                        <div className="suiv-list">
                          <ul>
                            {suivInformation.technicalSpecs['Aerodinâmica'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Aerodinâmica']
                                    .title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Aerodinâmica'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Bateria'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Bateria']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Bateria'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Consumo'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Consumo']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Consumo'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Desempenho'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Desempenho']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Desempenho'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Dimensões'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Dimensões']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Dimensões'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Direção'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Direção']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Direção'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Freios'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Freios']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Freios'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Geral'] && (
                              <li className="suiv-list-title">
                                {suivInformation.technicalSpecs['Geral']?.title}
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Geral'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation?.technicalSpecs['Iluminação'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Iluminação']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation?.technicalSpecs[
                              'Iluminação'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Lubrificante'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Lubrificante']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Lubrificante'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Motor'] && (
                              <li className="suiv-list-title">
                                {suivInformation.technicalSpecs['Motor']?.title}
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Motor'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Suspensão'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Suspensão']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Suspensão'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                            {suivInformation.technicalSpecs['Transmissão'] && (
                              <li className="suiv-list-title">
                                {
                                  suivInformation.technicalSpecs['Transmissão']
                                    ?.title
                                }
                              </li>
                            )}
                            {suivInformation.technicalSpecs[
                              'Transmissão'
                            ]?.specs?.map((aero) => (
                              <div className="col-6">
                                <p>
                                  {aero.property}
                                  {': '}
                                  <b>{aero.value}</b>
                                </p>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '90%',
                    }}
                  >
                    <p>Nenhuma especificações localizada para esse veículo</p>
                  </div>
                )}
              </>
            ) : (
              ''
            )}

            {currentTab === 'revision_plans' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h3 className-="title-suiv" style={{ marginLeft: '15px' }}>
                    Planos de Revisão
                  </h3>
                  <span style={{ color: '#d9534f', fontSize: '11px' }}>
                    *Algumas revisões não possuem preço sugerido da montadora
                  </span>
                </div>
                <hr />
                {suivInformation.revisionPlan ? (
                  suivInformation.revisionPlan.map((revision, index) => (
                    <div className="fade-in-result limit-height">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          marginBottom: '4px',
                          gap: '5px',
                        }}
                      >
                        <h4 style={{ marginLeft: 0 }}>
                          Revisão dos {revision.kilometers}Km (ou{' '}
                          {revision.months} meses)
                        </h4>
                        <Button
                          bsStyle="warning"
                          fill
                          onClick={() => {
                            const vehicleIdHash = encrypt(
                              selectedVehicle?.id,
                              '@OS-dig:vehicleId'
                            )

                            const clientIdHash = encrypt(
                              selectedVehicle?.Customer_id,
                              '@OS-dig:clientId'
                            )
                            window.open(
                              `/checklist?type=SUIV&revisionIndex=${index}&vehicleId=${vehicleIdHash}&clientId=${clientIdHash}`
                            )
                          }}
                          style={{
                            marginRight: 0,
                            height: '30px',
                            width: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPrint}
                            style={{
                              height: '15px',
                              width: '15px',
                            }}
                          />
                          &nbsp; Imprimir Checklist
                        </Button>
                      </div>
                      <hr />
                      <div style={{ marginLeft: '15px', marginTop: '10px' }}>
                        <div className="suiv-col">
                          <span className="suiv-revision-label">
                            Valor Sugerido:
                          </span>{' '}
                          {currency(revision.fullPrice)}
                        </div>

                        <div className="suiv-col">
                          <span className="suiv-revision-label">
                            Peças trocadas:
                          </span>
                          <div className="suiv-list">
                            <ul>
                              {revision.changedParts.map((part) => (
                                <li>{part.description}</li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="suiv-col">
                          <span className="suiv-revision-label">
                            Itens inspecionados:
                          </span>
                          <div className="suiv-list">
                            <ul>
                              {revision.inspections.map((inspection) => (
                                <li>{inspection}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '90%',
                    }}
                  >
                    <p>Nenhuma revisões localizada para esse veículo</p>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </Modal>
      )}
    </>
  )
}
