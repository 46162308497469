import React from 'react';

import SearchInput from '../../../../../components/SearchInput';

const ProductsFilterForm = ({
  brands,
  status,
  setStatus,
  brandId,
  setBrandId,
  query,
  handleChangeQuerySearch
}) => {

  return (
    <div className='products-filter-row'>
      <SearchInput
        placeholder='Pesquisa por Descrição, Código, Ref. Fabricante'
        value={query}
        style={{
          fontSize: 12,
          width: 350,
        }}
        containerStyle={{ margin: '0 10px' }}
        onChange={(e) => handleChangeQuerySearch(e.target.value)}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Status: </strong>
        </span> 
        <select
          className='form-control foco-input'
          name='searchStatus'
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{ marginLeft: '10px' }}
        >
          <option value=''>Ambos</option>
          <option value={1}>Enviados</option>
          <option value={0}>Não enviados</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Marca: </strong>
        </span>
        <select
          className='form-control foco-input'
          name='searchBrand'
          value={brandId}
          onChange={(e) => setBrandId(e.target.value)}
          style={{ marginLeft: '10px' }}
        >
          <option value={'null'}>Sem Marca</option>
          <option value={''}>Todas</option>
          {brands.map((brand) => (
            <option value={brand.id} key={brand.id}>
              {brand.description}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default ProductsFilterForm
