export const dashboardFeatureItems = {
  70: {
    title: 'Previsão de Retorno',
    subFeatureId: 70,
    plansNotAllowed: ['Free', 'Start', 'Essencial'],
  },
  1: {
    subFeatureId: 1,
    title: 'Vendas/Ordem de Serviço',
  },
  35: {
    title: 'Cadastro de Produto',
    subFeatureId: 35,
  },
  31: {
    subFeatureId: 31,
    title: 'Clientes',
  },
  7: {
    title: 'Serviços',
    groupFeatureId: 7,
    active: 'services',
    group: 'Serviços',
  },
  99: {
    title: 'Contas a Receber',
    subFeatureId: 99,
    plansNotAllowed: ['Free', 'Start'],
  },
  100: {
    title: 'Contas a Pagar',
    subFeatureId: 100,
    plansNotAllowed: ['Free', 'Start'],
  },
  4: {
    subFeatureId: 4,
    title: 'Agenda',
    plansNotAllowed: ['Free', 'Start'],
  },
  45: {
    title: 'Aniversariantes',
    subFeatureId: 45,
    plansNotAllowed: ['Free', 'Start'],
  },
};
