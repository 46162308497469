import React, { useState } from 'react';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import LoadingOverlay from 'react-loading-overlay';
import { format } from 'date-fns';

import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';

import signaturesRepository from '../../../repositories/Signatures';
import emailsRepository from '../../../repositories/Emails';

import AlertModal from 'components/AlertModal/AlertModal.js';
import RecurringSignatureDetails from './RecurringSignatureDetails';
import UniqueSignatureDetails from './UniqueSignatureDetails';
import NoSignatureDetails from './NoSignatureDetails';

import './styles.css';

const MyPlan = () => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isCancelSignatureModalOpen, setIsCancelSignatureModalOpen] =
    useState(false);

  const { user, userId } = useAuth();
  const { plan, signature, loadSignatureByUser } = usePlanSignatureContext();

  const signaturePayment = signature?.SignaturePayment;
  const usersQuantity = signature?.usersQuantity;

  const handleCancelRecurringSignature = async () => {
    setLoadingText('Aguarde, estamos processando a sua transação');
    setLoading(true);
    setIsCancelSignatureModalOpen(false);

    try {
      await signaturesRepository.cancelRecurringPayment({
        transactionNumber: signaturePayment.Transaction_Number,
        signatureId: signature.id,
      });

      /// ENVIO DE EMAIL SOBRE CANCELAMENTO
      cancelationEmail();

      toastr.success('A assinatura foi cancelada com sucesso');
      loadSignatureByUser(userId);
    } catch (err) {
      return toastr.error(
        'Ocorreu um erro ao realizar o cancelamento da Assinatura',
        err.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  const cancelationEmail = async () => {
    await emailsRepository.cancelSignature({
      Email: user.Email,
      Name: user.Name.split(' ')[0],
      Password: user.User_Password,
      Location: window.location.origin + '/client-login',
      End_date: signature.End_Date,
    });
  };

  const hasPlan = !!signature.SignaturePayment_id;
  const isSignatureCanceled =
    signature.SignatureStatus_id === constants.STATUS_ID.CANCELED;

  return (
    <Col>
      <LoadingOverlay
        active={loading}
        spinner
        text={loadingText}
        classNamePrefix="MyLoaderCard_"
        fadeSpeed={100}
      >
        <Col xs={12} sm={12} md={12} lg={12} id="cbPlano">
          <p id="cbPlanoTagP">Plano {plan.Description}</p>
          {isSignatureCanceled && (
            <p style={{ color: 'red', fontSize: '15px', marginTop: '-15px' }}>
              Você cancelou o seu plano em{' '}
              <strong>
                {format(new Date(signature.Cancellation_Date), 'dd/MM/yyy')}
              </strong>
            </p>
          )}
        </Col>
        {plan.id === constants.PLANS_ID.FREE ? (
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            id="bdPlano"
            style={{ minHeight: '200px' }}
          >
            <p id="bdPlanoTitle">
              <strong>Perfil</strong>
            </p>
            <div>
              <p id="bdPlanoBody">
                A conta {plan.Description}, permite apenas{' '}
                <strong>{usersQuantity} usuário</strong>.
              </p>
              <p>
                Você poderá utilizar para sempre, mas existem limitações de
                visualização de dados.
              </p>
            </div>
          </Col>
        ) : (
          <>
            <Col xs={6} sm={6} md={6} lg={6} id="bdPlano">
              <p id="bdPlanoTitle">
                <strong>Perfil</strong>
              </p>
              <div>
                <p id="bdPlanoBody">
                  Até {usersQuantity} usuários conectados de forma simultânea.
                </p>
                {!isSignatureCanceled &&
                  hasPlan &&
                  signaturePayment.Qtd_Parcels === 1 &&
                  signaturePayment.typePayment !== 'Boleto' && (
                    <Button
                      onClick={() => setIsCancelSignatureModalOpen(true)}
                      id="btn-cancelar"
                      type="button"
                      bsStyle="danger"
                      fill
                    >
                      Cancelar Plano
                    </Button>
                  )}
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} id="bdPlano">
              <p id="bdPlanoTitle">
                <strong>Pagamento</strong>
              </p>
              {!isSignatureCanceled && hasPlan ? (
                signaturePayment.Qtd_Parcels !== 1 ||
                signaturePayment.typePayment === 'Boleto' ? (
                  <UniqueSignatureDetails
                    signature={signature}
                    signaturePayment={signaturePayment}
                  />
                ) : (
                  <RecurringSignatureDetails
                    plan={plan}
                    signature={signature}
                    signaturePayment={signaturePayment}
                  />
                )
              ) : (
                <NoSignatureDetails
                  cancelled={isSignatureCanceled}
                  signature={signature}
                />
              )}
            </Col>
          </>
        )}

        <AlertModal
          show={isCancelSignatureModalOpen}
          animation
          message="Deseja realmente cancelar o seu plano?"
          onHide={() => setIsCancelSignatureModalOpen(false)}
          onCancel={() => setIsCancelSignatureModalOpen(false)}
          onSubmit={handleCancelRecurringSignature}
        />
      </LoadingOverlay>
    </Col>
  );
};

export default MyPlan;
