import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const renderDragMessage = (isDragActive, isDragReject) => {
  if(!isDragActive) return <span>Adicionar logo</span>

  if(isDragReject) return <span style={{ color: 'red' }}>Arquivo não Suportado</span>

  return <span style={{ color: 'green' }}>Arquivos suportados</span>
}

export default function UploadContainer({ getRootProps, getInputProps, isDragActive, isDragReject, image, onRemove }) {
  return(
    <>
    {!image?.preview 
      ? <div {...getRootProps()} className='container-add-image'>
          <FontAwesomeIcon icon={faPlusCircle} />
          <br/>
          {renderDragMessage(isDragActive, isDragReject)}

          <input {...getInputProps()}/>
        </div>

      : <div>
          <div className='container-show-image' >
            {/* <FontAwesomeIcon icon={faTimes} onClick={onRemove} /> */}
            <img src={image?.preview} alt={image.name} />
          </div>

          {/* <center>
            <button {...getRootProps()}  type='button' className='btn-link'>
              <input {...getInputProps()}/>
              Alterar
            </button>
          </center> */}
          
        </div>
    }
    </>
  )
}