import React from 'react'
import {
  Document,
  Text,
  Page,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import { format } from 'date-fns'
import { currency, percentage } from 'client/components/ToNormalize/ToNormalize'

const Header = ({ initialDate, finalDate, groupBy, displayItens }) => (
  <View>
    <View>
      <Text style={styles.title}>Vendas por Forma de Pagamento</Text>
    </View>

    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Agrupado por:
        </Text>
        <Text style={[styles.text]}>
          {groupBy === 'paymentMethod' ? 'Forma de Pagamento' : displayItens === 'true' ? 'Vendas (Com Itens)' : 'Vendas (Sem Itens)'}
        </Text>
      </View>
    </View>

    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Data Início:
        </Text>
        <Text style={[styles.text]}>
          {initialDate}
        </Text>
      </View>

      <View style={{ left: '6px' }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
          >
            Data Fim:
          </Text>
          <Text style={[styles.text]}>
            {finalDate}
          </Text>
        </View>
      </View>
    </View>
  </View>
)

const TotalizatorsByFormOfPaymentAndSaleTypeHeader = () => {
  return (
    <View>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', fontSize: '12px' }]}>
          Totais Vendidos por Forma de pagamento
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '18%' }]}>
          Forma de Pagamento
        </Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo de Venda</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>QTD</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const TotalizatorsByFormOfPaymentAndSaleTypeRow = ({ item }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={{ width: '18%' }}>
          <Text style={styles.text}>{item.formOfPayment}</Text>
        </View>
        <View style={{ width: '16%' }}>
          <Text style={styles.text}>{item.saleType}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{item.qtd}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.total)}</Text>
        </View>
      </View>
    </View>
  )
}

const TotalizatorsByFormOfPaymentAndSaleTypeTotal = ({ data }) => {
  return (
    <View style={styles.containerRow}>
      <Text style={[styles.boldText, { width: '34%' }]}></Text>
      <Text style={[styles.boldText, { width: '10%' }]}>{data.qtd}</Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(data.total)}
      </Text>
    </View>
  )
}

const TotalizatorsByFormOfPaymentAndSaleType = ({ data }) => {
  return (
    <>
      <TotalizatorsByFormOfPaymentAndSaleTypeHeader />

      {data.rows.map((item, index) => (
        <TotalizatorsByFormOfPaymentAndSaleTypeRow key={index} item={item} />
      ))}

      <View style={[styles.line, { marginTop: '2px', marginBottom: '2px' }]} />

      <TotalizatorsByFormOfPaymentAndSaleTypeTotal data={data} />

      <View style={[styles.line, { marginTop: '2px' }]} />
    </>
  )
}

const ProductsValuesHeader = () => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '10%' }]}>
          Código
        </Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '30%' }]}>Descrição</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Quantidade</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Preço Unit</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Desc. Unit %</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const SalesReceivedValuesTitle = () => (
  <View>
    <View>
      <Text
        style={[
          styles.title,
          { textAlign: 'center', marginTop: '2px', fontSize: '12px' },
        ]}
      >
        Valores Recebidos
      </Text>
    </View>
    <View style={[styles.line]} />
  </View>
)

const SalesReceivableValuesTitle = () => (
  <View>
    <View>
      <Text
        style={[
          styles.title,
          { textAlign: 'center', marginTop: '2px', fontSize: '12px' },
        ]}
      >
        Valores a Receber
      </Text>
    </View>
    <View style={[styles.line]} />
  </View>
)

const SalesInfoValuesTitle = () => (
  <View>
    <View>
      <Text
        style={[
          styles.title,
          { textAlign: 'center', marginTop: '2px', fontSize: '12px' },
        ]}
      >
        Vendas/Ordens de Serviço
      </Text>
    </View>
    <View style={[styles.line]} />
  </View>
)

const SalesValuesFormOfPayment = ({ formOfPayment }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '22%', fontSize: '11px' }]}>
          {formOfPayment}
        </Text>
      </View>
    </View>
  )
}

const SalesValuesHeader = ({ data }) => {
  return (
    <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row', width: '75%' }}>
      <View style={{ width: '25%', marginTop: '3px', marginBottom: '3px' }}>
        <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row' }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
            Venda/OS:
          </Text>
          <Text style={[styles.text]}>
            {data.saleCode}
          </Text>
        </View>
        <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row' }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
            Cliente:
          </Text>
          <Text style={[styles.text]}>
            {data.customer}
          </Text>
        </View>
      </View>
      <View style={{ width: '25%', marginTop: '3px', marginBottom: '3px' }}>
        <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row' }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
            Data Criação:
          </Text>
          <Text style={[styles.text]}>
            {format(new Date(data.saleCreationDate), 'dd/MM/yyyy')}
          </Text>
        </View>
        <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row' }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
            Valor:
          </Text>
          <Text style={[styles.text]}>
            {currency(data.total)}
          </Text>
        </View>
      </View>
      <View style={{ width: '25%', marginTop: '3px', marginBottom: '3px' }}>
        <View style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'row' }}>
          <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
            Data Fechamento:
          </Text>
          <Text style={[styles.text]}>
            {format(new Date(data.saleCloseDate), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
    </View>
  )
}

const SalesValuesTableHeader = ({ showPaymentDate }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '14%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Data Fech.</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Nº</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '29%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Valor Recebido?</Text>
        <Text style={[styles.boldText, { width: '16%', left: '12px' }]}>
          Dt. de Vencimento
        </Text>
        <Text style={[styles.boldText, { width: '10%', left: '14px' }]}>
          Título
        </Text>
        {showPaymentDate ? (
          <Text style={[styles.boldText, { width: '12%', right: '3px' }]}>
            Dt. Pagamento
          </Text>
        ) : (
          <View />
        )}
      </View>
      <View style={[styles.line]} />
    </View>
  )
}

const SalesValuesContent = ({ data, displayItens }) => {
  return (
    <View>
      <View style={{ marginTop: '5px', marginBottom: '5px', marginLeft: !displayItens ? '0%' : '25%', flexDirection: 'row', width: '75%', borderTop: '1px' }}>
        <Text style={[styles.boldText, { width: '20%' }]}>Condição de pagamento:</Text>
        <Text style={{ width: '20%', fontSize: 10, fontFamily: 'Roboto' }}>{data.isReceived === false ? 'A pagar' : 'Pago'}</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Vencimento</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Forma de pagamento</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Pago?</Text>
      </View>
    </View>
  )
}

const ProductsValuesContent = ({ key, item }) => {
  return (
    <View key={key}>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <Text style={{ width: '10%' }}>{item.productCode}</Text>
        <Text style={{ width: '10%' }}>
          {item.productType}
        </Text>
        <Text style={{ width: '30%' }}>{item.productDescription}</Text>
        <Text style={{ width: '10%' }}>{item.productQuantity}</Text>
        <Text style={{ width: '10%' }}>
          {currency(item.productUnitValue)}
        </Text>
        <Text style={{ width: '10%' }}>
          {percentage(item.productUnitDiscountValue)}
        </Text>
        <Text style={{ width: '10%' }}>
          {currency(item.productAmount)}
        </Text>
      </View>
    </View>
  )
}

const SalesValuesContentItens = ({ key, item, displayItens }) => {
  return (
    <>
      <View key={key}>
        <View style={{ marginBottom: '3px', marginLeft: !displayItens ? '0%' : '25%', flexDirection: 'row', width: '75%' }}>
          <Text style={{ width: '40%' }}>{''}</Text>
          <Text style={{ width: '12%' }}>
            {item.dueDate
              ? format(new Date(item.dueDate), 'dd/MM/yyyy')
              : ''}
          </Text>
          <Text style={{ width: '20%' }}>{item.formOfPaymentDescription}</Text>
          <Text style={{ width: '12%' }}>{currency(item.amount)}</Text>
          <Text style={{ width: '12%' }}>
            {item.isReceived ? 'Sim' : 'Não'}
          </Text>
        </View>
      </View>
    </>
  )
}

const SalesValuesTableRow = ({ key, item, showPaymentDate }) => {
  return (
    <>
      <View key={key}>
        <View style={styles.containerRow}>
          <Text style={{ width: '14%' }}>
            {item.saleCreationDate
              ? format(new Date(item.saleCreationDate), 'dd/MM/yyyy')
              : ''}
          </Text>
          <Text style={{ width: '12%' }}>
            {item.saleCloseDate
              ? format(new Date(item.saleCloseDate), 'dd/MM/yyyy')
              : ''}
          </Text>
          <Text style={{ width: '7%' }}>{item.saleCode}</Text>
          <Text style={{ width: '16%' }}>{item.saleType}</Text>
          <Text style={{ width: '29%' }}>{item.customer}</Text>
          <Text style={{ width: '8%' }}>{currency(item.amount)}</Text>
          <Text style={{ width: '12%', left: '18px' }}>
            {item.isReceived ? 'Sim' : 'Não'}
          </Text>
          <Text style={{ width: '16%', left: '12px' }}>
            {format(new Date(item.dueDate), 'dd/MM/yyyy')}
          </Text>
          <Text style={{ width: '10%', left: '14px' }}>{item.titleCode}</Text>
          {showPaymentDate ? (
            <Text style={{ width: '12%', right: '3px' }}>
              {item.paymentDate
                ? format(new Date(item.paymentDate), 'dd/MM/yyyy')
                : ''}
            </Text>
          ) : (
            <View />
          )}
        </View>
      </View>
    </>
  )
}

const SalesValuesTotal = ({ total }) => {
  return (
    <View style={{ marginTop: '4px' }}>
      <Text style={[styles.boldText, { textAlign: 'center', display: 'flex' }]}>
        Total: <Text style={[styles.boldText]}>{currency(total)}</Text>
      </Text>
      <View style={{ marginTop: '4px' }}></View>
    </View>
  )
}


const SalesReceivedValuesWrapper = ({ data }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <SalesValuesFormOfPayment formOfPayment={data.formOfPayment} />
      <SalesValuesTableHeader showPaymentDate={true} />
      {data.rows.filter(item => item.isReceived).map((item, key) => {
        return (
          <SalesValuesTableRow
            key={key}
            item={item}
            showPaymentDate={true}
          />
        )
      })}
      <View style={[styles.line]} />
      <SalesValuesTotal total={data.rows.filter(item => item.isReceived).reduce((prev, curr) => Number(curr.amount) + Number(prev), 0)} />
      <View style={styles.line} />
    </View>
  )
}

const SalesReceivableValuesWrapper = ({ data }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <SalesValuesFormOfPayment formOfPayment={data.formOfPayment} />
      <SalesValuesTableHeader showPaymentDate={false} />
      {data.rows.filter(item => !item.isReceived).map((item, key) => (
        <SalesValuesTableRow
          key={key}
          item={item}
          showPaymentDate={false}
        />
      ))}
      <View style={[styles.line]} />
      <SalesValuesTotal total={data.rows.filter(item => !item.isReceived).reduce((prev, curr) => Number(curr.amount) + Number(prev), 0)} />
      <View style={styles.line} />
    </View>
  )
}

const SalesInfoWithoutItensValuesWrapper = ({ data }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <SalesValuesHeader data={data} />
      <SalesValuesContent data={data} />
      {data.rows.map((item, key) => (
        <SalesValuesContentItens
          key={key}
          item={item}
        />
      ))}
      <View style={[styles.line]} />
    </View>
  )
}

const SalesInfoWithItensValuesWrapper = ({ data, displayItens }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <SalesValuesHeader data={data} />
      <ProductsValuesHeader />
      {data.salesItems.map((item, key) => (
        <ProductsValuesContent
          key={key}
          item={item}
        />
      ))}
      <SalesValuesContent data={data} displayItens={displayItens} />
      {data.rows.map((item, key) => (
        <SalesValuesContentItens
          key={key}
          item={item}
          displayItens={displayItens}
        />
      ))}
      <View style={[styles.line]} />
    </View>
  )
}

const SalesReceivedValues = ({ data }) => {
  return (
    <View>
      <SalesReceivedValuesTitle />
      {data.filter(item => item.rows.filter(row => row.isReceived)?.length > 0).map((item, index) => (
        <SalesReceivedValuesWrapper
          key={index}
          data={item}
        />
      ))}
    </View>
  )
}

const SalesReceivableValues = ({ data }) => {
  return (
    <View>
      <SalesReceivableValuesTitle />
      {data.filter(item => item.rows.filter(row => !row.isReceived)?.length > 0).map((item, index) => (
        <SalesReceivableValuesWrapper
          key={index}
          data={item}
        />
      ))}
    </View>
  )
}

const SalesInfoWithoutItensValues = ({ data }) => {
  return (
    <View>
      <SalesInfoValuesTitle />
      {data.map((item, index) => (
        <SalesInfoWithoutItensValuesWrapper
          key={index}
          data={item}
        />
      ))}
    </View>
  )
}

const SalesInfoWithItensValues = ({ data, displayItens }) => {
  return (
    <View>
      <SalesInfoValuesTitle />
      {data.map((item, index) => (
        <SalesInfoWithItensValuesWrapper
          key={index}
          data={item}
          displayItens={displayItens}
        />
      ))}
    </View>
  )
}

const SalesByFormPaymentPDF = ({
  result,
  initialDate,
  finalDate,
  groupBy,
  displayItens
}) => {
  const formOfPayments = result.formOfPayments
  const totalizatorsByFormOfPaymentAndSaleType =
    result.totalizatorsByFormOfPaymentAndSaleType
  const totalizatorsSalesBySaleCode = result.totalizatorsSalesBySaleCode

  return (
    <Document
      title={`Vendas-por-Forma-de-Pagamento${format(
        new Date(),
        'dd/MM/yyyy'
      )}`}
      subject="vendas-por-forma-de-pagamento"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <Header initialDate={initialDate} finalDate={finalDate} groupBy={groupBy} displayItens={displayItens} />

        <TotalizatorsByFormOfPaymentAndSaleType
          data={totalizatorsByFormOfPaymentAndSaleType}
        />

        {groupBy === 'paymentMethod' ?
          <>
            <SalesReceivedValues
              data={formOfPayments}
            />

            <SalesReceivableValues
              data={formOfPayments}
            />
          </>
          :
          groupBy === 'sales' && displayItens === 'true' ?
            <>
              <SalesInfoWithItensValues
                data={totalizatorsSalesBySaleCode}
                displayItens={displayItens}
              />
            </>
            :
            <>
              <SalesInfoWithoutItensValues
                data={totalizatorsSalesBySaleCode}
              />
            </>
        }
      </Page>
    </Document>
  )
}

export default SalesByFormPaymentPDF

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
})
