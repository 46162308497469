import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeCFOPS from './CFOPS'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const CFOPS = () => {
    localStorage.setItem('selectedPath', 'client/CFOPS')
    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'CFOP']}
                        path={['home', null, null, 'CFOPS']} />
                    <HomeCFOPS
                    />
                </Row>
            </Grid>
        </div>
    );
}
export default CFOPS