import { useHistory } from 'react-router-dom';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export function useRouter() {
  const history = useHistory();

  const navigateToClientPage = (resource, page, params) => {
    const path =
      CLIENT_PATH_ROUTES[resource.toUpperCase()][page?.toUpperCase()];
    history.push('/client/' + path + `${!!params ? params : ''}`);
  };

  return {
    navigateToClientPage,
  };
}
