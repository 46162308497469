import React, { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { SelectMultiStyled } from './styles';

const defaultPlaceholder = 'Selecione...';

export const SelectMulti = ({
  options,
  value,
  onChange,
  selectAllByDefault,
  hasAllOption,
  customAllLabel = 'Todos',
  customVariousLabel = 'Vários',
  width = 140,
  height = 39,
  fontSize,
  isSearch = false,
  borderColor = '#CACACA',
}) => {
  const theme = useTheme();

  const getLabel = () => {
    switch (true) {
      case !value?.length:
        return defaultPlaceholder;
      case value?.length === options.length:
        return customAllLabel;
      case value?.length > 1:
        return `${customVariousLabel} (${value.length})`;
      default:
        return value[0]?.label;
    }
  };

  const handleChange = (value, event) => {
    switch (true) {
      case event.action === 'select-option' && event.option.value === '*':
        return onChange(options);
      case event.action === 'deselect-option' && event.option.value === '*':
        return onChange([]);
      case event.action === 'deselect-option':
        return onChange(value.filter((option) => option.value !== '*'));
      case value.length === options.length - (hasAllOption ? 1 : 0):
        return onChange(options);
      default:
        return onChange([...value]);
    }
  };

  useEffect(() => {
    if (selectAllByDefault && options.length > 1) {
      onChange(options);
    }
  }, [selectAllByDefault, options]);

  const sortedOptions = useMemo(() => {
    const allOption = options.find((option) => option.value === '*');

    const selectedOptions = options.filter(
      (option) =>
        value.some((val) => val.value === option.value) && option.value !== '*'
    );

    const notSelectedOptions = options.filter(
      (option) =>
        !value.some((val) => val.value === option.value) && option.value !== '*'
    );

    return allOption
      ? [allOption, ...selectedOptions, ...notSelectedOptions]
      : [...selectedOptions, ...notSelectedOptions];
  }, [options, value]);

  return (
    <SelectMultiStyled
      options={sortedOptions}
      placeholder="Selecione..."
      getDropdownButtonLabel={getLabel}
      onChange={handleChange}
      value={value}
      styles={{
        dropdownButton: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: 'none',
          border: `1px solid ${borderColor}`,
          borderRadius: '4px',
          width,
          height,
          fontSize,
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: '#CACACA',
        }),
        control: (baseStyles, state) => ({
          display: isSearch ? baseStyles.display : 'none',
          border: isSearch ? '1px solid #CACACA' : '',
          borderRadius: isSearch ? '4px 4px 0px 0px' : '',
        }),
        groupHeading: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: 'red',
        }),
        option: (basedStyles, state) => ({
          ...basedStyles,

          display: 'flex',
          alignItems: 'center',

          backgroundColor: state.isSelected ? theme.colors.white : 'inherit',
          fontWeight: 'regular',

          '&:hover': {
            backgroundColor: 'rgb(222, 222, 222)',
          },

          '&': {
            height: '35px',
          },

          '& input': {
            margin: 0,
          },
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          border: '1px solid #CACACA',
          borderRadius: isSearch ? '0px 0px 4px 4px' : '4px',
        }),
      }}
    />
  );
};
