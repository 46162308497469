import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import FormProduto from './FormProduto'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const NewProduct = ({ match, location }) => {
  localStorage.setItem('selectedPath', 'client/product')
  const productInfo = location.productInfo
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <span id='required-field-label'>* Campos Obrigatórios</span>
          <BreadCrumb
            data={[
              'Início',
              'Produtos',
              !!match.params.id ? 'Editar Produto' : 'Novo Produto',
            ]}
            path={['home', 'products', null]}
          />
          <div style={{ marginTop: 30 }}>
            <FormProduto
              ProductId={match.params.id}
              productInfo={productInfo}
            />
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default NewProduct
