import React from 'react'
import ReactTable from 'react-table'

import Button from 'client/components/CustomButton/Button'
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize'
import { cpfMask } from 'client/components/ToNormalize/ToNormalize'
import { phoneMask } from 'client/components/ToNormalize/ToNormalize'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

export default function TradeRepresentativeTable({
  tradeRepresentatives,
  totalPages,
  currentPage,
  setCurrentPage,
  setPageLimit,
  loading,
}) {
  return (
    <ReactTable
      style={{ fontWeight: 'bold', textAlign: 'center' }}
      data={tradeRepresentatives}
      columns={[
        {
          Header: 'CPF/CNPJ',
          accessor: 'cpfCnpj',
          headerClassName: 'text-left',
          width: 180,
          Cell: (props) =>
            props.value.length < 14
              ? cpfMask(props.value)
              : cnpjMask(props.value),
        },
        {
          Header: 'Tipo',
          accessor: 'type',
          headerClassName: 'text-left',
          width: 140,
          Cell: (props) => (
            <Button
              id="botaoReactTable"
              style={{
                backgroundColor:
                  props.value === 'Fisica' ? '#0d45a4' : '#631678',
              }}
            >
              {props.value}
            </Button>
          ),
        },
        {
          Header: 'Nome/Razão Social',
          accessor: 'companyName',
          headerClassName: 'text-left',
          width: 300,
        },
        {
          Header: 'Nome Fantasia',
          accessor: 'tradingName',
          headerClassName: 'text-left',
          width: 270,
        },
        {
          Header: 'Telefone',
          accessor: 'Phones[0].Number_Phone1',
          headerClassName: 'text-left',
          width: 150,
          Cell: (props) =>
            props.value
              ? phoneMask(props.value)
              : props.original?.Phones[0]?.Number_Phone2
              ? phoneMask(props.original?.Phones[0]?.Number_Phone2)
              : '',
        },
        {
          Header: 'Status',
          accessor: 'isActive',
          width: 70,
          Cell: (props) => (
            <Button
              id="botaoReactTable"
              style={{
                width: '80%',
                height: '100%',
                backgroundColor: props.value === true ? '#5CB85C' : '#D9534F',
              }}
            >
              {props.value === true ? 'Ativo' : 'Inativo'}
            </Button>
          ),
        },
        {
          Header: 'Ações',
          accessor: 'id',
          width: 75,
          Cell: (props) => (
            <Link to={{ pathname: `trade-representative#${props.value}` }}>
              <FontAwesomeIcon
                cursor="pointer"
                icon={faEdit}
                style={{ width: '1.5em', height: '1.5em' }}
              />
            </Link>
          ),
        },
      ]}
      pages={totalPages}
      page={currentPage}
      onPageChange={setCurrentPage}
      manual
      onPageSizeChange={(value) => {
        setPageLimit(value)
      }}
      defaultPageSize={10}
      loading={loading}
      showPagination={true}
      sortable={true}
      showPaginationTop={false}
      showPaginationBottom={true}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      defaultSorted={[
        {
          id: 'createdAt',
          desc: false,
        },
      ]}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Não há informação"
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  )
}
