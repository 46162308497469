import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import { useAuth } from 'contexts/auth';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { useEffect } from 'react';

const defaultStyles = (height, width) => ({
  control: (base) => ({
    ...base,
    width: width,
    height: height,
    minHeight: height,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: width,
    height: height,
    padding: '0 0 0 10px',
  }),
  option: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '12px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    height: height,
  }),
});

const defaultPlaceholder =
  'Pesquisar por Placa/Objeto de Manutenção, CPF/CNPJ ou Nome';

export default function SelectCustomers({
  onChange,
  value,
  styles,
  status = 'Ativo',
  placeholder = defaultPlaceholder,
  showDefaultLabel = true,
  showVehicles = true,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  height = '40px',
  width,
  ...rest
}) {
  const { companyId } = useAuth();
  const [currentInputKey, setCurrentInputKey] = useState(1);

  useEffect(() => {
    if (reloadTrigger) {
      setReloadTrigger(false);
      setCurrentInputKey(currentInputKey + 1);
    }
  }, [reloadTrigger]);

  function handleLabel(customer) {
    switch (true) {
      case showDefaultLabel:
        return customer.label;
      default:
        return `${customer.customer_name} ${
          customer.customer_cpfcnpj &&
          `- ${cpfOrCnpjMask(customer.customer_cpfcnpj)}`
        }`;
    }
  }

  async function handleLoadCustomers(search, loadedOptions, { page }) {
    try {
      const res = await indexCustomersWithVehicles({
        company_id: companyId,
        page,
        limit: 10,
        query: search,
        show_label: showDefaultLabel,
        show_vehicles: showVehicles,
        customer_status: status,
      });

      const customersOptions = res.rows.map((customer) => ({
        label: handleLabel(customer),
        value: {
          ...customer,
          label: handleLabel(customer),
        },
      }));

      return {
        options: customersOptions,
        hasMore: page * 10 < res.customer_total,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  return (
    <AsyncPaginate
      isDisabled={disabled}
      key={currentInputKey}
      debounceTimeout={500}
      loadOptions={handleLoadCustomers}
      cacheOptions
      defaultValue={null}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      additional={{
        page: 1,
      }}
      noOptionsMessage={() => 'Sem opções'}
      loadingMessage={() => 'Carregando...'}
      styles={styles || defaultStyles(height, width)}
      {...rest}
    />
  );
}
