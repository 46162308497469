import { atom } from 'recoil';

export const companyBranch = atom({
  key: 'companyBranch',
  default: '',
});

export const companySegments = atom({
  key: 'companySegments',
  default: [],
});

const StorageCompanyDetails = {
  companyBranch,
  companySegments,
};

export default StorageCompanyDetails;
