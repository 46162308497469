import api from '../services/api'

const index = async () => {
  const response = await api.get('/boleto-flex/configuration')
  return response.data
}

const show = async (id) => {
  const response = await api.get('/boleto-flex/configuration/' + id)
  return response.data
}

const create = async (data) => {
  const response = await api.post('/boleto-flex/configuration/', data)
  return response.data
}

const update = async (id, data) => {
  const response = await api.put('/boleto-flex/configuration/' + id, data)
  return response.data
}

const findClientsWithoutConfiguration = async () => {
  const response = await api.get(
    '/boleto-flex/configuration/find/clients-without-configuration'
  )
  return response.data
}

const BoletoFlexConfigurations = {
  index,
  show,
  create,
  update,
  findClientsWithoutConfiguration,
}

export default BoletoFlexConfigurations
