import React, { useState, useEffect } from 'react';
import { Grid, Row } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import HomeLinkedServices from './LinkedServices';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

const LinkedServices = ({ location }) => {
  localStorage.setItem('selectedPath', 'client/linked-services');
  const [id, setId] = useState(null);

  useEffect(() => {
    checkParams();
  }, []);

  function checkParams() {
    const params = new URLSearchParams(location.search);
    const idParams = params.get('id');

    if (idParams) {
      readHash(idParams);
    }
  }

  async function readHash(hash) {
    const productId = await decrypt(hash, '@OS-dig:productId');
    setId(productId);
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Produtos', 'Serviço Vinculado']}
            path={['home', 'products', null]}
          />
          <HomeLinkedServices productId={id} />
        </Row>
      </Grid>
    </div>
  );
};

export default LinkedServices;
