import { useRecoilState } from 'recoil';
import AlertRepository from 'repositories/Alert';
import BoletoFlexAlertRepository from 'repositories/BoletoFlexAlert';
import MaintenanceAlertRepository from 'repositories/MaintenanceAlert';
import KoinAlertRepository from 'repositories/KoinAlert';
import { ALL_ALERTS_STATE } from 'v2/store/AlertPopoverStore';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { useModulesContext } from 'contexts/modules';

export function useAlerts() {
  const { companyId } = useAuth();
  const [allAlerts, setAllAlerts] = useRecoilState(ALL_ALERTS_STATE);
  const { isWorkshopPanelModuleActive } = useModulesContext();

  const getAlerts = async (companyId) => {
    try {
      const alerts = await AlertRepository.getAllByCompanyId(companyId);

      if (!isWorkshopPanelModuleActive) {
        setAllAlerts(alerts?.filter((alert) => alert.type !== 'MAINTENANCE'));
        return;
      }

      setAllAlerts(alerts);
    } catch (err) {
      return err;
    }
  };

  const getVisualizeAllAlerts = async (companyId) => {
    try {
      await MaintenanceAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });

      await BoletoFlexAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });
    } catch (err) {
      return err;
    } finally {
      getAlerts();
    }
  };

  const getVisualizeWorkshopPanelAlerts = async (companyId) => {
    try {
      await MaintenanceAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });
    } catch (err) {
      return err;
    } finally {
      getAlerts();
    }
  };

  const handleVisualizeAlert = async (id, type) => {
    try {
      switch (type) {
        case 'maintenance':
          await MaintenanceAlertRepository.update(id, {
            visualizedDate: new Date(),
          });
          break;
        case 'boleto-flex':
          await BoletoFlexAlertRepository.update(id, {
            visualizedDate: new Date(),
          });
          break;
        case 'koin':
          await KoinAlertRepository.update(id);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
      toastr.error('Falha ao atualizar alerta', 'Por favor, tente novamente');
    } finally {
      await getAlerts(companyId);
    }
  };

  return {
    allAlerts,
    getAlerts,
    getVisualizeAllAlerts,
    getVisualizeWorkshopPanelAlerts,
    handleVisualizeAlert,
  };
}
