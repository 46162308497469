import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';
import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';
import AccountsPayableAnalyticalPDF from './AccountsPayableAnalytical';
import billsToPayRepository from 'repositories/BillsToPay';
import AccountsPayableAnalyticalClient from './AccountsPayableAnalyticalClient';
import providersRepository from 'repositories/Providers';
import cashierBanksRepository from 'repositories/CashierBank';

const AccountsPayableAnalyticalReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [dataAccount, setDataAccount] = useState([]);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [sortByCustomer, setSortByCustomer] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [dateType, setDateType] = useState('');
  const [reportType, setReportType] = useState('');
  const [titleStatus, setTitleStatus] = useState('');
  const [provider, setProvider] = useState('');
  const [cashierBankDescription, setCashierBankDescription] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyIdResponseIdHash = searchParams.get('companyId');
      let companyId;

      if (companyIdResponseIdHash) {
        companyId = await decrypt(companyIdResponseIdHash, '@OS-dig:companyId');
      }

      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }

      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');
      const providerId = searchParams.get('providerId');
      const dateType = searchParams.get('dateType');
      const titleStatus = searchParams.get('titleStatus');
      const cashierBankId = searchParams.get('cashierBanksValue');
      const reportType = searchParams.get('reportType');
      const displayNotes = searchParams.get('displayNotes');
      const sortByCustomer = searchParams.get('sortByCustomer');

      if (providerId) {
        try {
          const infoProvider = await providersRepository.getById(providerId);
          const { companyName, tradingName } = infoProvider;
          const descriptionProvider = companyName || tradingName;

          if (descriptionProvider.length > 15) {
            setProvider(`${descriptionProvider.slice(0, 15)}...`);
          } else {
            setProvider(descriptionProvider);
          }
        } catch (error) {
          console.error('Erro ao obter informações do fornecedor:', error);
          setProvider('Todos');
        }
      } else {
        setProvider('Todos');
      }

      if (cashierBankId) {
        try {
          const infoCashierBank =
            await cashierBanksRepository.getCashierBanksById(cashierBankId);
          const { description } = infoCashierBank;

          setCashierBankDescription(description);
        } catch (error) {
          console.error('Erro ao obter informações do caixa/banco:', error);
          setCashierBankDescription('Todos');
        }
      } else {
        setCashierBankDescription('Todos');
      }

      setInitialDate(initialDate);
      setFinalDate(finalDate);
      setDateType(dateType);
      setReportType(reportType);
      setTitleStatus(titleStatus);
      setSortByCustomer(sortByCustomer);

      if (displayNotes === 'true') {
        setDisplayNotes(true);
      } else {
        setDisplayNotes(false);
      }

      const data = await loadReport(
        companyId,
        initialDate,
        finalDate,
        providerId,
        dateType,
        titleStatus,
        cashierBankId,
        sortByCustomer
      );

      setDataAccount(data);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  const loadReport = async (
    companyId,
    initialDate,
    finalDate,
    providerId,
    dateType,
    titleStatus,
    cashierBankId,
    sortByCustomer
  ) => {
    try {
      const data =
        await billsToPayRepository.generateBillsPayableTitleAnalytical({
          companyId,
          initialDate,
          finalDate,
          providerId,
          dateType,
          titleStatus,
          cashierBankId,
          sortByCustomer: sortByCustomer === 'true' ? true : '',
        });
      return data;
    } catch (err) {
      console.log(err);
      setLoading(false);
      setHasError(true);
    }
  };

  if (loading) return <AppLoading />;
  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );
  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          {sortByCustomer === 'true' ? (
            <AccountsPayableAnalyticalClient
              reportType={reportType}
              titleStatus={titleStatus}
              provider={provider}
              cashierBankDescription={cashierBankDescription}
              responseData={dataAccount}
              displayNotes={displayNotes}
              initialDate={initialDate}
              finalDate={finalDate}
              dateType={dateType}
            />
          ) : (
            <AccountsPayableAnalyticalPDF
              reportType={reportType}
              titleStatus={titleStatus}
              provider={provider}
              cashierBankDescription={cashierBankDescription}
              responseData={dataAccount}
              displayNotes={displayNotes}
              initialDate={initialDate}
              finalDate={finalDate}
              dateType={dateType}
            />
          )}
        </PDFViewer>
      </main>
    </div>
  );
};

export default AccountsPayableAnalyticalReport;
