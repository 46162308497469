import React from 'react';

import ReactTable from 'react-table';

import './styles.css';

const PartApplicationsTable = ({
  loading,
  handleSelectedPartApplication,
  filteredVehiclesFipe = [],
  setVehiclesFipe,
  vehiclesFipe,
}) => {
  function handleChangeInitialYear(value, vehicle) {
    const onlyNumbers = value.replace(/\D/g, '');
    const trimmedValue = onlyNumbers.slice(0, 4);

    const serializedVehiclesFipe = vehiclesFipe.map((vehicleFipe) => {
      if (vehicleFipe.id === vehicle.id) {
        return {
          ...vehicleFipe,
          initialYear: trimmedValue,
        };
      }
      return vehicleFipe;
    });

    setVehiclesFipe(serializedVehiclesFipe);

    console.log('serializedVehiclesFipe', serializedVehiclesFipe);

    setTimeout(() => {
      document.getElementById(`input-${vehicle.id}.initialYearInput`).focus();
    }, [50]);
  }

  function handleChangeFinalYear(value, vehicle) {
    const onlyNumbers = value.replace(/\D/g, '');
    const trimmedValue = onlyNumbers.slice(0, 4);

    const serializedVehiclesFipe = vehiclesFipe.map((vehicleFipe) => {
      if (vehicleFipe.id === vehicle.id) {
        return {
          ...vehicleFipe,
          finalYear: trimmedValue,
        };
      }
      return vehicleFipe;
    });

    setVehiclesFipe(serializedVehiclesFipe);

    setTimeout(() => {
      document.getElementById(`input-${vehicle.id}.finalYearInput`).focus();
    }, [50]);
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      <ReactTable
        className="bulk-deletion-table"
        style={{
          width: '100%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={filteredVehiclesFipe}
        columns={[
          {
            Header: 'Montadora',
            accessor: 'Brand',
            width: 250,
          },
          {
            Header: 'Modelo',
            accessor: 'Model',
          },
          {
            Header: 'Ano Inicial',
            accessor: 'initialYear',
            headerClassName: 'text-center',
            width: 100,
            Cell: (props) => (
              <input
                id={`input-${props.original.id}.initialYearInput`}
                key={props.original.id}
                type="text"
                pattern="[0-9]*"
                className="form-control"
                value={props.value}
                onChange={(e) => {
                  handleChangeInitialYear(e.target.value, props.original);
                }}
              />
            ),
          },
          {
            Header: 'Ano Final',
            accessor: 'finalYear',
            headerClassName: 'text-center',
            width: 100,
            Cell: (props) => (
              <input
                id={`input-${props.original.id}.finalYearInput`}
                key={props.original.id}
                type="text"
                pattern="[0-9]*"
                className="form-control"
                value={props.value}
                onChange={(e) => {
                  handleChangeFinalYear(e.target.value, props.original);
                }}
              />
            ),
          },
          {
            Header: 'Código Fipe',
            accessor: 'Code_FIPE',
            width: 90,
          },

          {
            Header: '',
            accessor: 'selected',
            filterable: false,
            width: 50,
            sortable: false,
            Cell: (props) => (
              <>
                <input
                  type="checkbox"
                  checked={props.value}
                  className="checkbox-input bulk-deletion-table"
                  onChange={() => handleSelectedPartApplication(props.original)}
                />
              </>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum Produto encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSortMethod={[{ id: 'Code_', desc: false }]}
      />

      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          justifyContent: 'flex-end',
          marginTop: '5px',
        }}
      >
        <span
          style={{
            fontSize: '13px',
            color: '#d43f3a',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {filteredVehiclesFipe.filter((vehicle) => vehicle.selected)
              .length || 0}{' '}
            veículos
          </span>{' '}
          selecionados
        </span>
      </div>
    </div>
  );
};

export default PartApplicationsTable;
