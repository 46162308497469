import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormDepartment } from '..';

export function FormDepartmentModal({ isOpen, handleClose, afterSubmit }) {
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitSuccess = (res) => {
    setIsLoading(false);
    afterSubmit(res);
    handleClose();
  };

  const handleSubmitButtonClick = () => {
    setTriggerSubmit(true);
    setIsLoading(true);

    setTimeout(() => {
      setTriggerSubmit(false);
      setIsLoading(false);
    }, [500]);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-60w">
      <Modal.Header>
        <Modal.Title>
          <span>
            <strong>Cadastro de Departamento</strong>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormDepartment
            hideBreadCrumb={true}
            hideActionButtons={true}
            onSubmitSuccess={handleSubmitSuccess}
            triggerSubmit={triggerSubmit}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end gap-075">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={handleClose}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            style={{ width: '100px' }}
            onClick={handleSubmitButtonClick}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
