import React, { useState, useEffect } from 'react';
import { IndicatorCard } from '../../components/IndicatorCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import ManagementIndicatorRepository from 'v2/repositories/ManagementIndicatorRepository';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { IndicatorGraph } from '../../components/IndicatorGraph';
import { InputSelectDefault } from 'v2/components/Input';
import {
  ContainerContentCard,
  ContainerGraph,
  ContainerFinancialScreen,
  ContainerGraphHeader,
  ContainerGraphContent,
  TextHeaderCardTitle,
  ButtonPreviousOrNext,
  ContainerHeaderCardMonth,
} from './Financial.styles';

export function Financial() {
  const { companyId } = useAuth();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [billsToday, setBillsToday] = useState({});
  const [billsMonth, setBillsMonth] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [graphPeriod, setGraphPeriod] = useState({
    label: 'Últimos 6 meses',
    value: 6,
  });
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(
    currentDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate.getMonth() + 1);
    setSelectedYear(newDate.getFullYear());
  };

  const goToNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentDate(newDate);
    setSelectedMonth(newDate.getMonth() + 1);
    setSelectedYear(newDate.getFullYear());
  };

  async function loadBillsToday() {
    setLoading(true);
    try {
      const bills = await ManagementIndicatorRepository.getBillsToday(
        companyId
      );

      setBillsToday(bills);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as contas diárias. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  async function loadBillsMonth() {
    setLoading(true);
    try {
      const date = `${String(selectedMonth).padStart(2, '0')}-${selectedYear}`;

      const bills = await ManagementIndicatorRepository.getBillsMonth(
        companyId,
        date
      );

      setBillsMonth(bills);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as contas mensais. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  }

  async function loadCashierBank() {
    setLoadingGraph(true);
    try {
      const graph = await ManagementIndicatorRepository.getCashierBankFlowGraph(
        companyId,
        graphPeriod.value
      );

      setGraphData(graph);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar os dados do gráfico. Por favor, tente novamente.'
      );
    } finally {
      setLoadingGraph(false);
    }
  }

  function formatDateRange() {
    const today = new Date();
    let startDate;

    startDate = new Date(
      today.getFullYear(),
      today.getMonth() - graphPeriod.value,
      1
    );

    const startDateFormat = startDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const endDate = today;
    const endDateFormat = endDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return `${startDateFormat} - ${endDateFormat}`;
  }

  async function fetchData() {
    if (!companyId) return;

    try {
      await Promise.all([loadBillsToday(), loadCashierBank()]);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.'
      );
    }
  }

  useEffect(() => {
    setSelectedMonth(currentDate.getMonth() + 1);
    setSelectedYear(currentDate.getFullYear());

    if (!!companyId) {
      loadBillsMonth();
    }
  }, [currentDate, companyId]);

  useEffect(() => {
    fetchData();
  }, [companyId]);

  useEffect(() => {
    if (companyId) loadCashierBank();
  }, [graphPeriod, companyId]);

  return (
    <ContainerFinancialScreen>
      <ContainerHeaderCardMonth>
        <div style={{ display: 'flex' }}>
          <ButtonPreviousOrNext onClick={goToPreviousMonth} disabled={loading}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ width: '16px', height: '16px', color: '#1D7391' }}
            />
          </ButtonPreviousOrNext>
          <ButtonPreviousOrNext onClick={goToNextMonth} disabled={loading}>
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ width: '16px', height: '16px', color: '#1D7391' }}
            />
          </ButtonPreviousOrNext>
          <span
            style={{
              fontSize: '16px',
              fontWeight: '900',
              textTransform: 'uppercase',
              color: '#1D7391',
              ...(loading && {
                opacity: 0.6,
                background: 'transparent',
                border: 0,
                cursor: 'not-allowed',
              }),
              ...(loading && {
                '&:hover:not(:disabled)': {
                  filter: 'brightness(85%)',
                },
              }),
            }}
          >
            {currentDate.toLocaleString('pt-BR', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
        </div>
      </ContainerHeaderCardMonth>
      <ContainerContentCard>
        <div>
          <TextHeaderCardTitle>
            HOJE,{' '}
            {new Date().toLocaleDateString('pt-BR', {
              day: 'numeric',
              month: 'long',
            })}
          </TextHeaderCardTitle>
          <div className="flex gap-1">
            <IndicatorCard
              title="A receber hoje"
              value={billsToday.billsToReceiveToday}
              variant="indigo"
            />
            <IndicatorCard
              title="A pagar hoje"
              value={billsToday.billsToPayToday}
              variant="red"
            />
          </div>
        </div>
        <div>
          <TextHeaderCardTitle>ESSE MÊS</TextHeaderCardTitle>
          <div className="flex gap-1">
            <IndicatorCard
              title="A receber esse mês"
              value={billsMonth.billsToReceiveMonth}
              variant="white"
              titleColor="gray"
              textColor="indigo"
              loading={loading}
            />
            <IndicatorCard
              title="A pagar esse mês"
              value={billsMonth.billsToPayMonth}
              variant="white"
              titleColor="gray"
              textColor="red"
              loading={loading}
            />
          </div>
        </div>
      </ContainerContentCard>
      <ContainerGraph>
        <ContainerGraphHeader>
          <span
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#428BCA',
              textTransform: 'uppercase',
            }}
          >
            Fluxo de Caixa
          </span>
          <span
            style={{
              fontSize: '12px',
              fontWeight: '900',
              color: '#444444',
              textTransform: 'uppercase',
            }}
          >
            {formatDateRange()}
          </span>
          <InputSelectDefault
            style={{
              fontSize: '12px',
              borderColor: '#aaa',
              borderRadius: '8px',
            }}
            width={'150px'}
            options={[
              {
                label: 'Últimos 6 meses',
                value: 6,
              },
              {
                label: 'Últimos 3 meses',
                value: 3,
              },
            ]}
            value={graphPeriod}
            fontSize={'12px'}
            onChange={(e) => setGraphPeriod(e)}
            isLoading={loadingGraph}
          />
        </ContainerGraphHeader>
        <ContainerGraphContent>
          <IndicatorGraph
            data={graphData}
            width={1300}
            height={250}
            propertyLineA="totalReceived"
            propertyLineB="totalPaid"
            propertyLineX="period"
            descriptionLineA="Receitas"
            descriptionLineB="Despesas"
            graphTitle={'Ticket Médio(Peças x Serviços)(R$)'}
            isCurrency
            loading={loadingGraph}
          />
        </ContainerGraphContent>
      </ContainerGraph>
    </ContainerFinancialScreen>
  );
}
