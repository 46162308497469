import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import AppError from 'components/AppError';
import { AppLoading } from 'client/routes/index.routes';

import SalesByAttendanceSyntheticPDF from './Synthetic';

import salesByAttendanceRepository from 'repositories/SalesByAttendance';
import SalesByAttendanceAnalyticalPDF from './Analytical';

const SalesByAttendanceReport = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [reportData, setReportData] = useState([]);
  const [reportType, setReportType] = useState('');
  const [seller, setSeller] = useState([]);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) loadQuery(searchParams);
  }, [location.search]);

  const loadQuery = async (searchParams) => {
    try {
      const companyId = searchParams.get('companyId');
      if (!companyId) {
        setHasError(true);
        setLoading(false);
      }
      const typeDate = searchParams.get('typeDate');
      const initialDate = searchParams.get('initialDate');
      const finalDate = searchParams.get('finalDate');

      const reportType = searchParams.get('reportType');
      const saleStatus = searchParams.get('saleStatus');
      const saleType = searchParams.get('saleType');
      const seller = searchParams.get('seller');

      const data = await salesByAttendanceRepository.getReport({
        companyId,
        typeDate,
        initialDate,
        finalDate,
        saleStatus,
        saleType,
        seller,
      });

      setInitialDate(initialDate);
      setFinalDate(finalDate);

      setReportType(reportType);
      setReportData(data);
      setSeller(seller);

      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  return (
    <div id="sale-printing-sheet-page">
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer style={{ height: '100%', width: '100%' }}>
          {reportType === 'synthetic' ? (
            <SalesByAttendanceSyntheticPDF
              initialDate={initialDate}
              finalDate={finalDate}
              reportData={reportData}
              seller={seller}
            />
          ) : (
            <SalesByAttendanceAnalyticalPDF
              initialDate={initialDate}
              finalDate={finalDate}
              reportData={reportData}
              seller={seller}
            />
          )}
        </PDFViewer>
      </main>
    </div>
  );
};

export default SalesByAttendanceReport;
