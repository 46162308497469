import React, { useState } from 'react';
import { reduxForm } from 'redux-form';

import CardForm from '../../../../components/CardForm';
import FormSubmitButtons from '../../../../components/FormSubmitButtons';
import DadosCadastrais from './DadosCadastrais';
import AlertModal from '../../../../../components/AlertModal/AlertModal';
import constants from '../../../../../utils/constants';
import { useHistory } from 'react-router-dom';
import CollapsibleSection from 'components/CollapsibleSection';
function FormNewScheduling({
  handleSubmit,
  loading,
  schedulingId,
  pristine,
  isModal,
  closeModal,
}) {
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const history = useHistory();

  const handleCancel = () => {
    if (pristine) return cancel();
    setIsAlertModalOpen(true);
  };

  const cancel = () => {
    if (isModal) {
      closeModal();
      history.push(constants.ROUTES.SALES);
    } else {
      history.push(constants.ROUTES.SCHEDULES);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <CollapsibleSection title="Dados do Agendamento" isExpanded={true}>
          <DadosCadastrais
            initialValues={{
              boxPrism: '',
            }}
            schedulingId={schedulingId}
            isModal={isModal}
          />
        </CollapsibleSection>
        {/* <CardForm show title="Dados do Agendamento" onClick={() => {}}>

        </CardForm> */}
        <FormSubmitButtons
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          loading={loading}
          hasId={!!schedulingId}
        />
      </form>

      <AlertModal
        show={isAlertModalOpen}
        onHide={() => setIsAlertModalOpen(false)}
        onCancel={() => setIsAlertModalOpen(false)}
        message={`Deseja cancelar ${
          schedulingId ? 'a edição' : 'o cadastro'
        } do agendamento? Todos os campos preenchidos não serão salvos e ${
          schedulingId ? 'a edição' : 'o cadastro'
        } poderá ser realizad${schedulingId ? 'a' : 'o'} posteriormente`}
        onSubmit={cancel}
      />
    </>
  );
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formNewScheduling',
})(FormNewScheduling);
