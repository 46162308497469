import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { addDays, format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const Header = ({ initialDate, finalDate, dateType }) => (
  <View>
    <View>
      <Text style={styles.title}>Recebimentos por Forma de Pagamento</Text>
    </View>

    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}>
          Tipo de Data:
        </Text>
        <Text style={[styles.text]}> {dateType}</Text>
      </View>

      <View style={{ left: '3px' }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
          >
            Data Inicio:
          </Text>
          <Text style={[styles.text]}>
            {' '}
            {format(getDateOnlyFromDate(new Date(initialDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>

      <View style={{ left: '6px' }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={[styles.text, { fontWeight: 'bold', marginRight: '3px' }]}
          >
            Data Fim:
          </Text>
          <Text style={[styles.text]}>
            {format(getDateOnlyFromDate(new Date(finalDate)), 'dd/MM/yyyy')}
          </Text>
        </View>
      </View>
    </View>
  </View>
);

const TotalizatorsByFormOfPaymentAndSaleTypeHeader = () => {
  return (
    <View>
      <View style={[styles.line, { marginTop: '20px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', fontSize: '11px' }]}>
          Totais Recebido por Forma de pagamento
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '18%' }]}>
          Forma de Pagamaneto
        </Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo de Venda</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>QTD</Text>
        <Text style={[styles.boldText, { width: '10%' }]}>Total</Text>
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

const TotalizatorsByFormOfPaymentAndSaleTypeRow = ({ item }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={{ width: '18%' }}>
          <Text style={styles.text}>{item.formOfPayment}</Text>
        </View>
        <View style={{ width: '16%' }}>
          <Text style={styles.text}>{item.saleType}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{item.qtd}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.text}>{currency(item.total)}</Text>
        </View>
      </View>
    </View>
  );
};

const TotalizatorsByFormOfPaymentAndSaleTypeTotal = ({ data }) => {
  return (
    <View style={styles.containerRow}>
      <Text style={[styles.boldText, { width: '34%' }]}></Text>
      <Text style={[styles.boldText, { width: '10%' }]}>{data.qtd}</Text>
      <Text style={[styles.boldText, { width: '10%' }]}>
        {currency(data.total)}
      </Text>
    </View>
  );
};

const TotalizatorsByFormOfPaymentAndSaleType = ({ data }) => {
  return (
    <>
      <TotalizatorsByFormOfPaymentAndSaleTypeHeader />

      {data.rows.map((item, index) => (
        <TotalizatorsByFormOfPaymentAndSaleTypeRow key={index} item={item} />
      ))}

      <View style={[styles.line, { marginTop: '2px', marginBottom: '2px' }]} />

      <TotalizatorsByFormOfPaymentAndSaleTypeTotal data={data} />

      <View style={[styles.line, { marginTop: '2px' }]} />
    </>
  );
};

const ReceivedValuesTitle = () => (
  <View>
    <View>
      <Text
        style={[
          styles.title,
          { textAlign: 'center', marginTop: '2px', fontSize: '11px' },
        ]}
      >
        Valores Recebidos
      </Text>
    </View>
    <View style={[styles.line]} />
  </View>
);

const ReceivedValuesFormOfPayment = ({ formOfPayment }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '22%', fontSize: '11px' }]}>
          {formOfPayment}
        </Text>
      </View>
    </View>
  );
};

const ReceivedValuesTableHeader = ({ showPaymentDate }) => {
  return (
    <View>
      <View style={[styles.line]} />
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '14%' }]}>Data Criação</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Data Fech.</Text>
        <Text style={[styles.boldText, { width: '7%' }]}>Nº</Text>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '29%' }]}>Cliente</Text>
        <Text style={[styles.boldText, { width: '8%' }]}>Valor</Text>
        <Text style={[styles.boldText, { width: '12%' }]}>Valor Recebido?</Text>
        <Text style={[styles.boldText, { width: '16%', left: '12px' }]}>
          Dt. de Vencimento
        </Text>
        <Text style={[styles.boldText, { width: '10%', left: '14px' }]}>
          Título
        </Text>
        {showPaymentDate ? (
          <Text style={[styles.boldText, { width: '12%', right: '3px' }]}>
            Dt. Pagamento
          </Text>
        ) : (
          <View />
        )}
      </View>
      <View style={[styles.line]} />
    </View>
  );
};

const ReceivedValuesTableRow = ({ key, item, showPaymentDate }) => {
  return (
    <>
      <View key={key}>
        <View style={styles.containerRow}>
          <Text style={{ width: '14%' }}>
            {item.saleCreationDate
              ? format(new Date(item.saleCreationDate), 'dd/MM/yyyy')
              : ''}
          </Text>
          <Text style={{ width: '12%' }}>
            {item.saleCloseDate
              ? format(new Date(item.saleCloseDate), 'dd/MM/yyyy')
              : ''}
          </Text>
          <Text style={{ width: '7%' }}>{item.saleCode}</Text>
          <Text style={{ width: '16%' }}>{item.saleType}</Text>
          <Text style={{ width: '29%' }}>{item.customer}</Text>
          <Text style={{ width: '8%' }}>{currency(item.amount)}</Text>
          <Text style={{ width: '12%', left: '18px' }}>
            {item.isReceived ? 'Sim' : 'Não'}
          </Text>
          <Text style={{ width: '16%', left: '12px' }}>
            {format(new Date(item.dueDate), 'dd/MM/yyyy')}
          </Text>
          <Text style={{ width: '10%', left: '14px' }}>{item.titleCode}</Text>
          {showPaymentDate ? (
            <Text style={{ width: '12%', right: '3px' }}>
              {item.paymentDate
                ? format(new Date(item.paymentDate), 'dd/MM/yyyy')
                : ''}
            </Text>
          ) : (
            <View />
          )}
        </View>
      </View>
    </>
  );
};

const ReceivedValuesTotal = ({ total }) => (
  <View style={{ marginTop: '4px' }}>
    <Text style={[styles.boldText, { textAlign: 'center', display: 'flex' }]}>
      Total: <Text style={[styles.boldText]}>{currency(total)}</Text>
    </Text>
    <View style={{ marginTop: '4px' }}></View>
  </View>
);

const ReceivedValuesWrapper = ({ data, showPaymentDate }) => {
  return (
    <View style={{ marginTop: '2px' }}>
      <ReceivedValuesFormOfPayment formOfPayment={data.formOfPayment} />
      <ReceivedValuesTableHeader showPaymentDate={showPaymentDate} />
      {data.rows.map((item, key) => (
        <ReceivedValuesTableRow
          key={key}
          item={item}
          showPaymentDate={showPaymentDate}
        />
      ))}
      <View style={[styles.line]} />
      <ReceivedValuesTotal total={data.total} />
      <View style={styles.line} />
    </View>
  );
};

const ReceivedValues = ({ data, filterOpenPayment }) => {
  return (
    <View>
      <ReceivedValuesTitle />
      {data.map((item, index) => (
        <ReceivedValuesWrapper
          key={index}
          data={item}
          showPaymentDate={filterOpenPayment}
        />
      ))}
    </View>
  );
};

const BillsReceivedsPDF = ({
  responseData,
  filterOpenPayment,
  initialDate,
  finalDate,
  dateType,
}) => {
  const formOfPayments = responseData.formOfPayments;
  const totalizatorsByFormOfPaymentAndSaleType =
    responseData.totalizatorsByFormOfPaymentAndSaleType;

  return (
    <Document
      title={`Recebimentos-por-Forma-de-Pagamento - ${format(
        addDays(new Date(initialDate), 1),
        'dd/MM/yyyy'
      )} - ${format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy')}`}
      subject="comissoes"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <Header
          initialDate={initialDate}
          finalDate={finalDate}
          dateType={dateType}
        />

        <TotalizatorsByFormOfPaymentAndSaleType
          data={totalizatorsByFormOfPaymentAndSaleType}
        />

        <ReceivedValues
          data={formOfPayments}
          filterOpenPayment={filterOpenPayment}
        />
      </Page>
    </Document>
  );
};

export default BillsReceivedsPDF;

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  containerRow: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
});
