import api from 'v2/services/api';

const getByCompany = async (companyId) => {
  try {
    const response = await api.get(`additional-information/${companyId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getOptions = async () => {
  try {
    const response = await api.get(`additional-information/options`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
const create = async (data) => {
  try {
    const response = await api.put(`additional-information/`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const AdditionalInformationRepository = {
  getByCompany,
  getOptions,
  create,
};

export default AdditionalInformationRepository;
