import React from 'react';
import { useMemo } from 'react';

export function TabSelector({ selectedTab, setSelectedTab, mode }) {
  const selectedStyle = {
    backgroundColor: '#008db1',
    borderColor: '#008db1',
    color: '#FFF',
  };

  const firstTabLabel = useMemo(() => {
    switch (mode) {
      case 'start-service':
        return 'Lançamento de Apontamento';
      default:
        return 'Serviços em Andamento';
    }
  }, [mode]);

  return (
    <div className="appointments-tab-selector">
      {mode !== 'service-history' && (
        <div
          className="appointments-tab-title"
          onClick={() => setSelectedTab('first-tab')}
          style={selectedTab === 'first-tab' ? selectedStyle : {}}
        >
          {firstTabLabel}
        </div>
      )}
      <div
        className="appointments-tab-title"
        onClick={() => setSelectedTab('service-history')}
        style={selectedTab === 'service-history' ? selectedStyle : {}}
      >
        Histórico de Serviço
      </div>
    </div>
  );
}
