import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import CustomButton from '../CustomButton/CustomButton';
import PollsAnswerRepository from 'repositories/PollsAnswer';
import './index.css';

const GradeButton = ({ grade, selectedGrade, handleSelectGradeFn }) => {
  return (
    <>
      <button
        className="nps-grade-button"
        reducedOpacity={
          selectedGrade !== null && selectedGrade !== grade ? 'true' : 'false'
        }
        grade={grade}
        onClick={handleSelectGradeFn}
      >
        {grade}
      </button>
    </>
  );
};

function getPlaceholderByGrade(grade) {
  if (grade <= 2) {
    return 'Lamentamos que esteja insatisfeito. Poderia nos contar o que está ocorrendo?';
  } else if (grade <= 7) {
    return 'Obrigado pela resposta. Poderia nos contar o que poderíamos melhorar?';
  } else {
    return 'Muito obrigado pela nota. Ficamos felizes em saber que está satisfeito. Existe alguma informação que queira adicionar?';
  }
}
const ModalNPS = ({
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  userId,
  pollId,
  companyId,
}) => {
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [notes, setNotes] = useState('');
  const grades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  async function sendNPSAnswer() {
    try {
      setIsLoading(true);
      await PollsAnswerRepository.create({
        notes,
        answer: selectedGrade,
        userId,
        pollId,
        companyId,
      });

      toastr.success(
        'Obrigado pela resposta!',
        'Sua opinião é muito importante para que possamos melhorar o sistema e nossa parceria.'
      );
    } catch (err) {
      toastr.error(
        'Erro ao enviar sua resposta',
        'Tentaremos lhe perguntar novamente mais tarde!'
      );
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  }

  return (
    <Modal show={isModalOpen} dialogClassName="nps-modal">
      <Modal.Body>
        <p>
          Em uma escala de 0 a 10, qual a probabilidade de você recomendar nossa
          empresa a um amigo ou colega?
        </p>

        <div className="nps-buttons-wrapper">
          {grades.map((grade) => (
            <GradeButton
              key={grade}
              grade={grade}
              selectedGrade={selectedGrade}
              handleSelectGradeFn={() => setSelectedGrade(grade)}
            />
          ))}
        </div>

        {selectedGrade !== null && (
          <textarea
            value={notes}
            placeholder={getPlaceholderByGrade(selectedGrade)}
            onChange={(e) => setNotes(e.target.value)}
          />
        )}

        <div className="nps-send-button">
          <CustomButton
            fill
            style={{
              background: '#5cb85c',
              borderColor: '#5cb85c',
            }}
            disabled={selectedGrade === null || isLoading}
            onClick={() => sendNPSAnswer()}
          >
            Enviar
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNPS;
