import React from 'react';
import { Grid, Row } from "react-bootstrap";
import constants from '../../../../../utils/constants'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import SalesPassingFilters from './components/SalesPassingFilters';

function SalesPassing() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Relatórios', 'Venda', 'Venda/OS - Passagens']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <SalesPassingFilters />
        </Row>
      </Grid>
    </div>
  )
}

export default SalesPassing;
