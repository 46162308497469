import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { Controller, useWatch } from 'react-hook-form';

import AlertModal from 'components/AlertModal/AlertModal';
import CardForm from 'client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';

import { SelectBranding } from 'v2/components/Select';

import ValidationModal from './ValidationModal';
import { NewsRegisterImage } from './NewsRegisterImage';

import './styles.css';

function NewsRegisterForm({
  onSubmit,
  newsId,
  register,
  getValues,
  watch,
  handleSubmit,
  isDirty,
  onCancel,
  control,
  setValue,
  setImageFile,
  imageFile,
}) {
  const [basicData, setBasicData] = useState(true);
  const [cancelModal, setCancelModal] = useState(false);
  const [validationModal, setValidationModal] = useState(false);

  const history = useHistory();

  const link = useWatch({
    control,
    name: 'url',
  });
  const isValidUrl = (url) => {
    // regex para validação de URL
    const regex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return regex.test(url);
  };

  const validationUrl = () => {
    const { url } = getValues();

    if (isValidUrl(url)) {
      setValidationModal(true);
    } else {
      toastr.warning('Insira um link válido para notícia e tente novamente');
    }
  };

  const submitValidationUrl = (submitValues) => {
    const { url } = getValues();
    if (isValidUrl(url)) {
      submitValues();
    } else {
      toastr.warning('Insira um link válido para notícia e tente novamente');
    }
  };

  return (
    <div>
      <div style={{ marginTop: 30 }}>
        <form
          // onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <CardForm
            show={basicData}
            title="Dados da Notícia"
            onClick={() => setBasicData(!basicData)}
          >
            <div className="news-register-form">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  width: 400,
                }}
              >
                <label htmlFor="description">
                  Descrição<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  {...register('description')}
                  className="form-control"
                  name="description"
                  id="description"
                  maxLength="50"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  width: 700,
                }}
              >
                <label>
                  Link de Redirecionamento:
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  {...register('url')}
                  className="form-control"
                  name="url"
                  id="url"
                />
                <a className="link" onClick={validationUrl}>
                  Validar Link
                </a>
              </div>
              <div
                style={{
                  gap: '-5px',
                }}
              >
                <Controller
                  control={control}
                  name="brandingId"
                  render={({ field }) => (
                    <SelectBranding
                      height={'35px'}
                      customAllOptionLabel={'Ambos'}
                      hideLabel
                      showAllOption
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <label htmlFor="">
                Imagem:<span style={{ color: 'red' }}>*</span>
                <span style={{ fontSize: 10 }}> (max. 690x350px)</span>
              </label>
              <NewsRegisterImage
                setValue={setValue}
                imageFile={imageFile}
                setImageFile={setImageFile}
                watch={watch}
              />
            </div>
          </CardForm>

          {validationModal && (
            <ValidationModal
              validationModal={validationModal}
              setValidationModal={setValidationModal}
              link={link}
            />
          )}

          <FormSubmitButtons
            onCancel={() => {
              if (isDirty) return setCancelModal(true);
              history.push('/admin/news');
            }}
            backLabel={newsId}
            hasId={newsId}
            onSubmit={() => submitValidationUrl(handleSubmit(onSubmit))}
          />

          {cancelModal && (
            <AlertModal
              show={cancelModal}
              onHide={() => setCancelModal(false)}
              onCancel={() => setCancelModal(false)}
              subtitle={
                !newsId
                  ? 'Você tem certeza que deseja cancelar o cadastro de notícia?'
                  : null
              }
              message={
                !newsId
                  ? `Ao cancelar o cadastro da News, você não conseguirá publicar a mesma. Você poderá cadastrar novamente mais tarde.`
                  : `Deseja cancelar a edição? Nenhuma alteração será salva.`
              }
              onSubmit={onCancel}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default NewsRegisterForm;
