import React from 'react'

import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb'
import FinancingBankSlipMain from './components/FinancingBankSlipMain'

import './styles.css'

const FinancingBankSlip = ({ history }) => {
  return (
    <>
      <BreadCrumb
        data={['Início', 'Integrações', 'Boleto Parcelado']}
        path={['home', null, null]}
      />
      <FinancingBankSlipMain history={history} />
    </>
  )
}

export default FinancingBankSlip
