import React from 'react'
import CurrencyInput from 'react-currency-input'

const InputUnitValue = (props) => {
  return (
    <CurrencyInput
      {...props}
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      onChangeEvent={props.onChangeEvent}
    />
  )
}

export default InputUnitValue
