import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import constants from '../../utils/constants';

import { AppLoading } from 'client/routes/index.routes';
import AppError from 'components/AppError';

import CompaniesRepository from '../../repositories/Companies';
import quickQuotesRepository from '../../repositories/QuickQuotes';

import Sheet1 from './Sheet1';
import Sheet2 from './Sheet2';
import ThermalCoil from './ThermalCoil';
import { PDFViewer } from '@react-pdf/renderer';

const QuickQuote = ({ location }) => {
  const [sale, setSale] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { company, companyId } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams && company) {
      loadQuery(searchParams);
    }
  }, [location.search, company]);

  const loadQuery = async (searchParams) => {
    try {
      const name = searchParams.get('name');
      const phone = searchParams.get('phone');
      const model = searchParams.get('model');
      const licensePlate = searchParams.get('licensePlate');
      const observations = searchParams.get('observations');

      const quickQuoteId = searchParams
        .get('Id')
        .split('v723dha5tHSSpS83711')[1]
        ?.split('5422hsGsdAQLk8423oPL31')[0];

      let Final_Value = 0;
      let Product_Value = 0;
      let Service_Value = 0;

      const info = await quickQuotesRepository.getById(quickQuoteId);
      const quickQuote = info.data;

      const SalesItems = quickQuote.QuickQuotesItems;

      const serializedItems = SalesItems.map((item) => {
        const Amount = item.quantity * item.unitValue;
        Final_Value += Amount;
        if (item.type === 'Produto') {
          Product_Value += Amount;
          return {
            ...item,
            Unit_Value: item.unitValue,
            Discount_Value: item.discountValue,
            Description: item.description,
            Type: item.type,
            Quantity: item.quantity,
            Amount,
            Products: {
              Code: item.Products.Code,
            },
          };
        }
        Service_Value += Amount;
        const Service_id = item.Product_id;
        delete item.Product_id;
        return {
          ...item,
          Amount,
          Unit_Value: item.unitValue,
          Type: item.type,
          Discount_Value: item.discountValue,
          Description: item.description,
          Quantity: item.quantity,
          Services: {
            Code: item.Services.Code,
          },
          Service_id,
        };
      });

      const Company = await CompaniesRepository.getById(companyId);

      const loadSale = {
        QuickQuoteCode: quickQuote.code,
        Company,
        Customer: {
          Company_Name: quickQuote.customerName,
          Trading_Name: quickQuote.customerName,
          Phones: [
            {
              Number_Phone2: quickQuote.customerTelephone,
            },
          ],
          Cpf_Cnpj: '',
          Address: [],
        },
        Vehicle: {
          Model: quickQuote.vehicle,
          description: quickQuote.vehicle,
          License_Plate: quickQuote.licensePlate,
          licensePlate: quickQuote.licensePlate,
        },
        SalesItems: serializedItems.length !== 0 ? serializedItems : [],
        Address: [],
        SalesType: {
          Description: 'Orçamento Rápido',
        },
        Date: new Date(),
        updatedAt: new Date(),
        Comments: quickQuote.observations,
        Final_Value,
        Discount_Value: 0,
        Discount_Value_Percentage: 0,
        Product_Value,
        Discount_Products: 0,
        Discount_Products_Percentage: 0,
        Service_Value,
        Discount_Services: 0,
        Discount_Services_Percentage: 0,
      };

      await setSale(loadSale);
    } catch (err) {
      console.log(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderPdf = () => {
    switch (company.orcamentPrintingSheetId) {
      case constants.PRINTING_SHEETS.SHEET1:
        return <Sheet1 sale={sale} quickQuote />;
      case constants.PRINTING_SHEETS.SHEET2:
        return <Sheet2 sale={sale} quickQuote />;
      case constants.PRINTING_SHEETS.THERMAL_COIL:
        return <ThermalCoil sale={sale} quickQuote />;
      default:
        setHasError(true);
        break;
    }
  };

  if (loading) return <AppLoading />;

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    );

  if (!!sale)
    return (
      <div id="sale-printing-sheet-page">
        <main style={{ height: '100vh', width: '100vw' }}>
          <PDFViewer style={{ height: '100%', width: '100%' }}>
            {renderPdf()}
          </PDFViewer>
        </main>
      </div>
    );
};

export default QuickQuote;
