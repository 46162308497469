const providersData = [
  {
    cpfCnpj: '12123456000170',
    type: 'Juridica',
    companyName: 'Empresa X',
    tradingName: 'Nome Fantasia Empresa X',
    email: 'empresax@empresa.com',
    Number_Phone1: '(11) 3304-0972',
    Number_Phone2: '(11) 9 9543-2349',
    IE: '0',
    statusIE: 'Não Contribuinte',
    Zipcode: '07250-200',
    Address: 'Rua Etc e Tal',
    Address_Number: '54',
    Neighborhood: 'Bairro Tal',
    Complement: 'Apartamento 150',
    Reference_Point: 'Próximo ao supermercado',
    State: 'SP',
    City: 'Campinas',
  },
  {
    cpfCnpj: '25488627049',
    type: 'Fisica',
    companyName: 'Empresa XX',
    tradingName: 'Nome Fantasia Empresa XX',
    email: 'empresaxx@empresa.com',
    Number_Phone1: '(11) 3304-0972',
    Number_Phone2: '(11) 9 9543-2349',
    IE: '0',
    statusIE: 'Não Contribuinte',
    Zipcode: '07250-200',
    Address: 'Rua Etc e Tal',
    Address_Number: '54',
    Neighborhood: 'Bairro Tal',
    Complement: 'Apartamento 150',
    Reference_Point: 'Próximo ao supermercado',
    State: 'SP',
    City: 'Campinas',
  },
];

module.exports = providersData;
