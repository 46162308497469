import React from 'react';
import { Modal } from 'react-bootstrap';

import NewProviderForm from './components/NewProviderForm';

export default function NewProviderModal({
  handleClose,
  handleSelectCreatedProvider,
  onlyView,
  providerId,
}) {
  return (
    <Modal show={true} onHide={handleClose} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{onlyView ? 'Visualizar' : 'Novo'} Fornecedor</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewProviderForm
          handleClose={handleClose}
          handleSelectCreatedProvider={handleSelectCreatedProvider}
          onlyView={onlyView}
          providerId={providerId}
        />
      </Modal.Body>
    </Modal>
  );
}
