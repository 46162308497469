import React from 'react';

import {
  InputContainer,
  InputLabel,
  InputSelectDefault,
} from 'v2/components/Input';

import { BRANDINGS } from 'v2/utils/constants';

export const SelectBranding = ({
  variant,
  hideLabel = false,
  customLabel = 'Produto:',
  width = 130,
  height,
  showAllOption = false,
  fontSize,
  fontSizeInput,
  boldLabel = false,
  customAllOptionLabel,
  ...props
}) => {
  const serializeOptions = () => {
    const serializedOptions = BRANDINGS.map((saleType) => ({
      value: saleType.id,
      label: saleType.name,
    }));

    if (showAllOption)
      serializedOptions.unshift({
        label: customAllOptionLabel || 'Todos',
        value: '',
      });

    return serializedOptions;
  };

  const options = serializeOptions();

  return (
    <InputContainer variant={variant}>
      {!hideLabel && <InputLabel fontSize={fontSize}>{customLabel}</InputLabel>}
      <InputSelectDefault
        id="select__branding"
        width={width}
        height={height}
        options={options}
        fontSize={fontSizeInput}
        {...props}
      />
    </InputContainer>
  );
};
