import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import natureOfTransactionRepository from '../../../repositories/NatureOfTransactions';
import SearchInput from 'components/SearchInput';

const HomeCFOPs = ({ history }) => {
  const [natureOfTransactions, setNatureOfTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const { queryFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      loadNatureOfTransactions();
    }
  }, []);

  const loadNatureOfTransactions = async () => {
    setLoading(true);
    try {
      const companyNatureOfTransactionAndDefaults =
        await natureOfTransactionRepository.getAllByCompanyAndDefaults(
          companyId
        );

      //BUSCA DE SERVIÇOS COM COMPANY_ID
      const companyNatureOfTransaction =
        await natureOfTransactionRepository.getAllByCompany(companyId);

      const allData = [];
      const allNatureOfTransaction = [];

      //OBJETO APENAS COM OS CFOPS QUE POSSUI COMPANY_ID
      for (let i = 0; i < companyNatureOfTransaction.length; i++) {
        const obj = {
          id: companyNatureOfTransaction[i].id,
          CFOP: companyNatureOfTransaction[i].CFOP,
          Description: companyNatureOfTransaction[i].description,
          Type: companyNatureOfTransaction[i].type,
          Company_id: companyNatureOfTransaction[i].companyId,
        };
        allNatureOfTransaction.push(obj);
      }

      //OBJETO PARA TODOS OS CFOPS INDEPENDENTE DO COMPANY_ID
      for (let i = 0; i < companyNatureOfTransactionAndDefaults.length; i++) {
        const obj = {
          id: companyNatureOfTransactionAndDefaults[i].id,
          CFOP: companyNatureOfTransactionAndDefaults[i].CFOP,
          Description: companyNatureOfTransactionAndDefaults[i].description,
          Type: companyNatureOfTransactionAndDefaults[i].type,
          Company_id: companyNatureOfTransactionAndDefaults[i].companyId,
        };
        allData.push(obj);
      }

      //VERIFICAÇÃO SE JÁ EXISTE CFOPS COM A MESMA DESCRIÇÃO E CÓDIGO
      for (let j = 0; j < allNatureOfTransaction.length; j++) {
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].CFOP === allNatureOfTransaction[j].CFOP &&
            !allData[i].Company_id
          ) {
            allData.splice(i, 1);
          }
        }
      }
      setNatureOfTransactions(allData);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as naturezas de operação. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (natureOfTransaction) => {
    const querySearch = [
      natureOfTransaction['Description'],
      natureOfTransaction['CFOP'],
    ];
    return (
      filterByQuery(querySearch) && filterByStatus('Type', natureOfTransaction)
    );
  };

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.CFOP)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Novo CFOP
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Código ou Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="Entrada">Entrada</option>
                <option value="Saída">Saída</option>
                <option value="Simples Remessa">Simples Remessa</option>
                <option value="Devolução de Entrada">
                  Devolução de Entrada
                </option>
                <option value="Devolução de Saída">Devolução de Saída</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={natureOfTransactions.filter(handleFilters)}
              columns={[
                {
                  Header: 'CFOP',
                  id: 'CFOP',
                  accessor: (props) => Number(props.CFOP),
                  headerClassName: 'text-left',
                  Cell: (props) =>
                    companyId ? (
                      <Link
                        id="linkVenda"
                        to={{ pathname: `CFOP#${props.original.id}` }}
                        title="Clique para editar"
                      >
                        <button className="btn-link">
                          {props.value.toFixed(3)}
                        </button>
                      </Link>
                    ) : (
                      <Link id="linkVenda">
                        <button
                          className="btn-link"
                          onClick={() => setNoCompanyModal(true)}
                        >
                          {props.value.toFixed(3)}
                        </button>
                      </Link>
                    ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-left',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        zIndex: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'Entrada'
                            ? 'green'
                            : props.value === 'Saída'
                            ? 'red'
                            : props.value === 'Simples Remessa'
                            ? 'purple'
                            : props.value === 'Devolução de Entrada'
                            ? 'blue'
                            : 'gray',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 100,
                  Cell: (props) =>
                    companyId ? (
                      <Link to={{ pathname: `CFOP#${props.original.id}` }}>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ) : (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="false"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNoCompanyModal(true)}
                      />
                    ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeCFOPs);
