import React, { memo } from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";

import { currency } from 'client/components/ToNormalize/ToNormalize';

const TableByStatus = ({ data, loading }) => {
  return (
    <>
      <h4><strong>Compras por Status (R$)</strong></h4>
      <ReactTable
        style={{ fontWeight: 'bold', textAlign: 'center' }}
        data={data}
        columns={[
          {
            Header: "Status",
            accessor: "status"
          },
          {
            Header: "Quantidade",
            accessor: "quantity"
          },
          {
            Header: "Valor",
            accessor: "amount",
            Cell: props => currency(props.value)
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={false}
        sortable={false}
        showPaginationTop={false}
        showPaginationBottom={false}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Nenhuma informação encontrada'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </>
  )
}

export default memo(TableByStatus)