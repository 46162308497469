import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FamiliesRepository from 'repositories/Families';

import { useRouter } from 'v2/hooks/useRouter';
import { deleteSection } from 'v2/repositories/SectionRepository';
import { useAuth } from 'contexts/auth';

const DeleteModalMessage = () => {
  return (
    <div className="flex column gap-075">
      <span className="typography__text-1">
        Você tem certeza que deseja excluir a seção? Ao excluir, não poderá
        vinculá-la a nenhuma família e deverá cadastrar uma nova.
      </span>
      <span className="typography__caption text-red text-center">
        O processo é irreversível. Uma vez excluída, não poderá ser recuperada.
      </span>
    </div>
  );
};

const alreadyInUseToasterTitle = 'Seção vinculado a família';
const alreadyInUserToasterMessage =
  'A seção está vinculada a uma ou mais famílias e não pode ser excluída. Inative-o caso não queira mais utilizar ou remova da família.';

export function TableActions({ id, triggerFetch }) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteSection(id);
      toastr.success('Exclusão realizada com sucesso', 'A seção foi excluída');
      triggerFetch();
    } catch (err) {
      console.log(err);

      const alreadyInUse =
        err.response?.data?.message === 'This section is in use.';

      const title = alreadyInUse ? alreadyInUseToasterTitle : 'Erro ao excluir';

      const message = alreadyInUse
        ? alreadyInUserToasterMessage
        : 'Por favor, tente novamente. Caso persista, contate o suporte.';

      toastr.error(title, message);
    } finally {
      handleCloseDeleteModal();
      setIsDeleting(false);
    }
  };

  const handleDeleteButtonClick = async () => {
    try {
      const familiesWithThisSection = await FamiliesRepository.getBySection(
        id,
        companyId
      );

      if (familiesWithThisSection?.length > 0) {
        toastr.warning(alreadyInUseToasterTitle, alreadyInUserToasterMessage);
        return;
      }

      setIsDeleteModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao validar exclusão',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    }
  };

  return (
    <div className="flex center gap-050">
      <button
        title="Editar"
        className="button-icon"
        onClick={() => navigateToClientPage('section', 'form_edit_raw', id)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        title="Excluir"
        className="button-icon"
        onClick={handleDeleteButtonClick}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
      </button>

      {isDeleteModalOpen && (
        <AlertModal
          show={true}
          onHide={handleCloseDeleteModal}
          onCancel={handleCloseDeleteModal}
          onSubmit={handleDelete}
          title="Exclusão"
          message={<DeleteModalMessage />}
          loading={isDeleting}
        />
      )}
    </div>
  );
}
