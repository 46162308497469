import React from 'react'

const renderField = ({ input, type, placeholder, id, meta: { touched, error } }) => (
  <div >
    <input className="form-control foco-input" autoComplete="new-password" id={id} placeholder={placeholder} {...input}  type={type} />
    {touched && error && <span style={{
      color: 'red', 
      textAlign: 'left', 
      display: 'block',
      paddingLeft: '10px'
      }}>{error}</span>}
  </div>
)

export default renderField