import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CardForm from '../../../../components/CardForm';
import AlertModal from '../../../../../components/AlertModal/AlertModal';
import FormSubmitButtons from '../../../../components/FormSubmitButtons';
import DadosCadastrais from './DadosCadastrais';
import Endereco from '../../../Clients/NewClient/FormClient/Endereco';

function FormNewShippingCompany({ handleSubmit, onCancel, loading }) {
  const [dadosCadastrais, setDadosCadastrais] = useState(true);
  const [endereco, setEndereco] = useState(false);
  const [modal, setModal] = useState(false);

  const { cadastroTransportadora: { values: { id } } } = useSelector(state => state.form);

  function cancelModal() {
    setModal(false);
    onCancel()
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
      <CardForm
        show={dadosCadastrais}
        title="Dados Cadastrais"
        onClick={() => setDadosCadastrais(!dadosCadastrais)}
      >
        <DadosCadastrais
          onSubmit={(event) => {
            event.preventDefault()
            setDadosCadastrais(false)
            setEndereco(true)
          }}
        />
      </CardForm>
      <CardForm
        show={endereco}
        title="Endereço"
        onClick={() => setEndereco(!endereco)}
      >
        <Endereco form='cadastroTransportadora' required edit={!!id} />
      </CardForm>

      <FormSubmitButtons loading={loading} onCancel={() => setModal(true)} hasId={!!id} onSubmit={handleSubmit} />

      {modal &&
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message='Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar?'
          onSubmit={cancelModal}
        />}

    </form>
  );
};


export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroTransportadora'
})(withRouter(FormNewShippingCompany));