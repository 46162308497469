import React from 'react'

import { isFirefox } from '../../utils/browserTypes'

const Datalist = ({ suggestions = [], ...rest }) => {
  if (isFirefox) {
    return (
      <datalist {...rest}>
        {
          suggestions.map((suggestion, i) =>
            <option key={i} value={`${suggestion.primary} - ${suggestion.secondary ? suggestion.secondary : ''}`} />
          )
        }
      </datalist>
    )
  }

  return (
    <datalist {...rest}>
      {
        suggestions.map((suggestion, i) =>
          <option key={i} value={suggestion.primary}>
            {suggestion.secondary}
          </option>
        )
      }
    </datalist>
  )


}

export default Datalist