import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import api from 'services/api';
import config from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from './ReduxMeusDetalhes/meusDetalhesAction';
import { toastr } from 'react-redux-toastr';

const MyBranchs = () => {
  const [branchs, setBranchs] = useState([]);

  const dispatch = useDispatch();
  const { Branch_id } = useSelector((state) => state).meusDetalhesReducer;

  useEffect(() => {
    try {
      getBranchs();
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os ramos. Por favor, tente novamente'
      );
    }
  }, []);

  const getBranchs = async () => {
    const response = await api.get(config.endpoint + `branch`);
    setBranchs(response.data);
  };

  return (
    <div id="labelSemUpperCase">
      <label>
        Selecione o ramo de atuação da sua empresa:
        {parseInt(Branch_id) !== 5 && <span style={{ color: 'red' }}>*</span>}
      </label>
      <select
        className="form-control foco-input"
        id="select-services"
        value={Branch_id}
        onChange={(e) => dispatch(handleChange(e.target.value, 'Branch_id'))}
        style={{
          width: '240px',
        }}
      >
        {branchs.map((s) => (
          <option key={s.id} value={s.id}>
            {s.Description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MyBranchs;
