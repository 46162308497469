import * as yup from 'yup';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Vendas',
      'Checklist Personalizado',
      'Correções',
      `${isEditing ? 'Editar' : 'Novas'} Correções`,
    ],
    path: [
      '/client/home',
      null,
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.CORRECTIONS.BASE,
      null,
    ],
  };
};

export const formSchema = yup.object().shape({
  description: yup.string().required(),
  isActive: yup.boolean().required(),
  details: yup.string().required(),
});
