import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import familiesRepository from 'repositories/Families';
import brandsRepository from 'repositories/Brands';
import FamilyBrandStockReportRepository from 'v2/repositories/FamilyBrandStockReportRepository';
import { toastr } from 'react-redux-toastr';
import { format, isValid, subDays } from 'date-fns';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import TableFamilyBrandStock from './TableFamilyBrandStock';
import ExcelHelperFamilyBrandStock from './ExcelHelperFamilyBrandStock';

const HomeFamilyBrandStockReport = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isReportReady, setIsReportReady] = useState(false);
  const [initialDate, setInitialDate] = useState(
    format(subDays(new Date(), 30), 'yyyy-MM-dd')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState([]);
  const [familiesIds, setFamiliesIds] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandsIds, setBrandsIds] = useState([]);
  const [reportData, setReportData] = useState();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    loadFamilies() && loadBrands();
  }, [companyId]);

  useEffect(() => {
    if (selectedFamily.length > 0) {
      const transformedFamily = selectedFamily
        .map((item) => item.value)
        .filter((value) => value !== '*')
        .join(',');
      setFamiliesIds(transformedFamily);
    } else {
      setIsReportReady(false);
      setFamiliesIds(false);
    }

    if (selectedBrand.length > 0) {
      const transformedBrand = selectedBrand
        .map((item) => item.value)
        .filter((value) => value !== '*')
        .join(',');
      setBrandsIds(transformedBrand);
    } else {
      setBrandsIds(false);
    }
  }, [selectedFamily, selectedBrand]);

  useEffect(() => {
    if (validations()) {
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [initialDate, finalDate, companyId, familiesIds, brandsIds]);

  useEffect(() => {
    if (isReportReady) {
      handleProcess();
    }
  }, [currentPage, pageLimit]);

  async function loadFamilies() {
    setLoading(true);
    setIsReportReady(false);
    try {
      const families =
        await familiesRepository.getAllActiveProductsFamiliesByCompany(
          companyId
        );

      const serializedFamilies = families.map((family) => ({
        label: family.Description,
        value: family.id,
      }));

      serializedFamilies.unshift({
        label: 'Todas',
        value: '*',
      });

      setFamilies(serializedFamilies);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
      setIsReportReady(true);
    }
  }

  async function loadBrands() {
    setLoading(true);
    setIsReportReady(false);
    try {
      const brands = await brandsRepository.getAllActiveProductsBrandsByCompany(
        companyId
      );

      const serializedBrands = brands.map((brands) => ({
        label: brands.Description,
        value: brands.id,
      }));

      serializedBrands.unshift({
        label: 'Todas',
        value: '*',
      });

      setBrands(serializedBrands);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar as famílias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
      setIsReportReady(true);
    }
  }

  const validations = () => {
    if (
      !isValid(new Date(initialDate)) ||
      !isValid(new Date(finalDate)) ||
      !companyId ||
      !familiesIds ||
      !brandsIds
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    handleProcess();
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const data =
        await FamilyBrandStockReportRepository.generateFamilyBrandStockReport({
          initialDate,
          finalDate,
          companyId,
          families: familiesIds,
          brands: brandsIds,
          limit: pageLimit,
          page: currentPage + 1,
        });

      if (data.report.length > 0) {
        setReportData(data.report);
        setTotalPages(Math.ceil(data.count / pageLimit));
      } else {
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
          marginBottom: '15px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Família:</InputLabel>
            <SelectMulti
              width={150}
              height={35}
              options={families}
              value={selectedFamily}
              onChange={(e) => setSelectedFamily(e)}
              hasAllOption
              selectAllByDefault
              customAllLabel={'Todas'}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Marca:</InputLabel>
            <SelectMulti
              width={150}
              height={35}
              options={brands}
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e)}
              hasAllOption
              selectAllByDefault
              customAllLabel={'Todas'}
            />
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="btn btn-sucesso"
            style={{ height: '35px' }}
            onClick={handleSubmit}
            disabled={loading || !isReportReady}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Gerar Relatório
          </button>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <ExcelHelperFamilyBrandStock
            isReportReady={isReportReady}
            loading={loading}
            reportData={reportData}
          />
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '40px' }}>
        <TableFamilyBrandStock
          reportData={reportData}
          loading={loading}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default HomeFamilyBrandStockReport;
