import React from 'react'
import { Modal } from 'react-bootstrap'

import './styles.css'

const { Header, Title, Body, Footer } = Modal

const ChooseModeModal = ({ show, onCancel, openCreateUpdateProductModal }) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Header>
        <Title>
          <strong>OS Digital</strong>
        </Title>
      </Header>
      <Body>
        <strong>Escolha a opção desejada</strong>
        <br />
        <br />
        <span>
          Você poderá cadastrar novos produtos, mediante ao plano contratado, ou
          enviar atualizações de cadastro aos produtos
        </span>
        <br />
        <section className='tray-option-buttons'>
          <button
            className='btn'
            style={{
              backgroundColor: '#428BCA',
              borderColor: '#428BCA',
              opacity: 1,
              color: 'white',
            }}
            onClick={() => openCreateUpdateProductModal('create')}
          >
            Enviar novos cadastros
          </button>
          <button
            className='btn'
            style={{
              backgroundColor: '#5CB85C',
              borderColor: '#5CB85C',
              opacity: 1,
              color: 'white',
            }}
            onClick={() => openCreateUpdateProductModal('update')}
          >
            Enviar atualizações de cadastro
          </button>
        </section>
        <span className='active-products-warning'>
          *Apenas produtos ativos serão exibidos na listagem
        </span>
      </Body>
      <Footer>
        <button
          className='btn'
          style={{
            backgroundColor: '#D9534F',
            borderColor: '#D9534F',
            opacity: 1,
            color: 'white',
          }}
          onClick={onCancel}
        >
          Voltar
        </button>
      </Footer>
    </Modal>
  )
}

export default ChooseModeModal
