import { format, parseISO  } from 'date-fns';
export function generateBankConciliationDetails(dataOfx) {

  if (!dataOfx) return [];

 const startDate = dataOfx?.headerFile?.initialDate
  ? format(parseISO(dataOfx.headerFile.initialDate), 'dd/MM/yyyy')
  : '';

const endDate = dataOfx?.headerFile?.finalDate
  ? format(parseISO(dataOfx.headerFile.finalDate), 'dd/MM/yyyy')
  : '';

  const period = `${startDate} - ${endDate}`;

  const bankInfo = `${dataOfx.headerFile.bankCode} - ${dataOfx.headerFile.bankName}`;

  const totalTransactions = dataOfx.transactions.length;
  const creditTransactions = dataOfx.transactions.filter(
    (transaction) => transaction.type === 'Crédito'
  ).length;
  const debitTransactions = dataOfx.transactions.filter(
    (transaction) => transaction.type === 'Débito'
  ).length;

  const creditPercentage = (
    (creditTransactions / totalTransactions) *
    100
  ).toFixed(2);
  const debitPercentage = (
    (debitTransactions / totalTransactions) *
    100
  ).toFixed(2);

  const totalConciliations = dataOfx.transactions?.reduce((acc, transaction) => {
    if (transaction.conciled) {
      acc.trueCount++;
    }
    acc.totalCount++;
    return acc;
  }, { trueCount: 0, totalCount: 0 });

  const { trueCount, totalCount } = totalConciliations || {
    trueCount: 0,
    totalCount: 0,
  };

  const conciliationInfo = {
    trueCount,
    totalCount,
  };
  /* } `${trueCount} de ${totalCount}`; */

  const bankConciliationArray = [
    { title: 'Banco', info: `${bankInfo}` },
    { title: 'Período', info: period },
    { title: 'Total de Transações', info: totalTransactions },
    {
      title: 'Transações de Crédito',
      info: creditTransactions,
      porcentagem: creditPercentage,
    },
    {
      title: 'Transações de Débito',
      info: debitTransactions,
      porcentagem: debitPercentage,
    },
    { title: 'Conciliação Realizada', info: conciliationInfo },
  ];

  return bankConciliationArray;
}
