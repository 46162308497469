import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { change, Field, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import validate from './validate'
import CardForm from '../../../components/CardForm'
import FormSubmitButtons from '../../../components/FormSubmitButtons'
import RenderField from '../../../../components/RenderField'
import AlertModal from 'components/AlertModal'
import { useHistory } from 'react-router'

import AccountPlanCategoriesRepository from 'repositories/AccountPlanCategories'

import './styles.css'

const FormNewAccountPlan = ({ accountPlanId, newAccountModalType, handleSubmit, onCancel, loading, dirty }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [categories, setCategories] = useState([])
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false)
  const [cancelMessage, setCancelMessage] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()
  const { isActive } = useSelector(state => state).form.accountPlan.values


  useEffect(() => {
    loadCategories()

    if (newAccountModalType === 'sale') {
      dispatch([
        change('accountPlan', 'type', 'Crédito'),
        change('accountPlan', 'categoryId', 1)
      ])
    }

    if (newAccountModalType === 'purchase') {
      dispatch(change('accountPlan', 'type', 'Débito'))
    }

    setCancelMessage(
      accountPlanId
        ? 'Deseja cancelar a edição? Todos os campos preenchidos não serão salvos e a edição poderá ser realizado posteriormente'
        : 'Deseja cancelar o cadastro da conta? Todos os campos preenchidos não serão salvos e o cadastro poderá ser realizado posteriormente'
    )
  }, [])

  const loadCategories = async () => {
    const categories = await AccountPlanCategoriesRepository.getAllAccountPlanCategories()

    if (newAccountModalType === 'purchase') {
      const purchaseCategories = categories.filter(category => category.id !== 1)
      return setCategories(purchaseCategories)
    }

    setCategories(categories)
  }

  return (
    <div id="new-account-plan-container">
      <CardForm
        show={isOpen}
        title="Dados da Conta"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Col xs={12} sm={12} md={12} lg={12}>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='description'
              component={RenderField}
              label='Descrição'
              disabled={!!accountPlanId}
              required
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='type'
              component={RenderField}
              label='Tipo'
              as='select'
              required
              disabled={!!newAccountModalType}
            >
              <option value=''>Selecione</option>
              <option value='Débito'>Débito (-)</option>
              <option value='Crédito'>Crédito (+)</option>
            </Field>
          </Col>

          <Col xs={12} sm={12} md={5} lg={5}>
            <Field
              name='categoryId'
              component={RenderField}
              label='Categoria'
              as='select'
              required
              disabled={newAccountModalType === 'sale'}
            >
              <option value=''>Selecione</option>
              {categories.length && categories.map(category => (
                <option key={category.id} value={category.id}>{category.description}</option>
              ))}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={1} lg={1}>
            <label htmlFor="isActive">Status</label>
            <br />
            <Toggle
              checked={isActive}
              onChange={e => dispatch(change('accountPlan', 'isActive', e.target.checked))}
              disabled={!!newAccountModalType}
            />
          </Col>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              name='observations'
              component={RenderField}
              label='Observações'
              as='textarea'
            />
          </Col>
        </Col>
      </CardForm>

      <FormSubmitButtons
        onCancel={() => dirty ? setIsConfirmCancelModalOpen(true) : history.push('account-plans')}
        onSubmit={handleSubmit}
        loading={loading}
      />

      <AlertModal
        show={isConfirmCancelModalOpen}
        title='OS Digital'
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        message={cancelMessage}
        onSubmit={() => history.push('account-plans')}
      />
    </div>
  )
}

export default reduxForm({
  form: 'accountPlan',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate: validate
})(FormNewAccountPlan)