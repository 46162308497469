import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from './components/Input';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import Select from './components/Select';
import TextArea from './components/TextArea';
import {
  cpfMask,
  RgMask,
  name,
  email,
  phoneMask,
  descriptionWithAltSign,
} from 'client/components/ToNormalize/ToNormalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import TradeRepresentativeRepository from 'repositories/TradeRepresentative';
import { currencyMask } from 'utils/masks';
import RenderField from 'components/RenderField';
import { description } from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { SelectApproach } from 'v2/client/components/SelectApproach';
import { listApproach } from 'v2/repositories/ApproachRepository';

const PF = ({ edit }) => {
  const {
    cadastroCliente: {
      values: {
        Status_IE,
        IE,
        tradeRepresentativeId,
        hasCpfCnpj,
        withholdsISS,
        debtLimit,
        availableCredit,
        sellingPriceType,
        approachId,
      },
    },
  } = useSelector((state) => state.form);

  const [tradeRepresentativeOptions, setTradeRepresentativeOptions] = useState(
    []
  );

  const [approachOptions, setApproachOptions] = useState([]);
  const [priceType, setPriceType] = useState(sellingPriceType);

  const dispatch = useDispatch();
  const { companyId, company } = useAuth();

  const {
    isPlanFree,
    isPlanStart,
    isPlanBasic,
    isWorkmotor,
    isPlanWorkmotorFree,
  } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

  useEffect(() => {
    if (companyId) {
      loadApproach();
      loadTradeRepresentatives();
    }
  }, []);

  useEffect(() => {
    if (IE) {
      dispatch(
        change(
          'cadastroCliente',
          'Status_IE',
          Status_IE ?? 'Contribuinte de ICMS'
        )
      );
    } else {
      dispatch(change('cadastroCliente', 'Status_IE', ''));
    }
  }, [IE]);

  const loadTradeRepresentatives = async () => {
    try {
      const response = await TradeRepresentativeRepository.list(companyId, {
        status: 1,
      });

      setTradeRepresentativeOptions(response.tradeRepresentativeList);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Representantes',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadApproach = async () => {
    try {
      const response = await listApproach({
        companyId,
        isActive: true,
        limit: 10000,
        page: 1,
      });

      setApproachOptions(response.rows);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Abordagens',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  return (
    <>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginLeft: '0px',
          paddingLeft: '0px',
          paddingRight: '0px',
          marginRight: '0px',
        }}
      >
        <Col lg={3} md={3} sm={3} xs={12}>
          <Field
            label="CPF:"
            name="Cpf_Cnpj"
            component={Input}
            normalize={cpfMask}
            placeholder="445.995.420-67"
            maxLength="14"
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="RG:"
            name="Rg"
            component={Input}
            normalize={RgMask}
            maxLength="13"
            placeholder="53.788.784-9"
          />
        </Col>
        <Col lg={4} md={4} sm={4} xs={12}>
          <Field
            label="Nome Completo:"
            required
            name="Company_Name"
            component={Input}
            normalize={name}
            placeholder="Insira o nome do seu cliente"
            maxLength={60}
          />
        </Col>
        <Col lg={3} md={3} sm={3} xs={12}>
          <Field
            label="E-mail:"
            name="Email"
            type="text"
            placeholder="seucliente@gmail.com"
            component={Input}
            normalize={email}
          />
        </Col>
      </Col>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginLeft: '0px',
          paddingLeft: '0px',
          paddingRight: '0px',
          marginRight: '0px',
        }}
      >
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="Telefone Residencial:"
            name="Number_Phone1"
            placeholder="(21)2872-2294"
            component={Input}
            normalize={phoneMask}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="Telefone Celular:"
            required
            name="Number_Phone2"
            placeholder="(21)98473-6441"
            component={Input}
            normalize={phoneMask}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="Data de Nascimento:"
            name="Date_Birth"
            placeholder="10/08/1985"
            component={Input}
            type="date"
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Inscrição Estadual:</label>
          <FontAwesomeIcon
            title="Campo exclusivo para produtor agrícola."
            cursor="pointer"
            icon={faInfoCircle}
            style={{ height: '1.1em', width: '1.1em' }}
            color="#0088cc"
          />
          <Field
            name="IE"
            component={Input}
            maxLength="14"
            required={Status_IE === 'Contribuinte'}
            normalize={onlyNumbers}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Indicador de IE:</label>
          <FontAwesomeIcon
            title="Campo exclusivo para produtor agrícola."
            cursor="pointer"
            icon={faInfoCircle}
            style={{ height: '1.1em', width: '1.1em' }}
            color="#0088cc"
          />
          <Field name="Status_IE" value={Status_IE} component={Select}>
            <option value="">Selecione</option>
            <option value="Contribuinte de ICMS">Contribuinte de ICMS</option>
            <option value="Não Contribuinte">Não Contribuinte</option>
          </Field>
        </Col>
        {company.salesRepresentative === true && (
          <Col lg={2} md={2} sm={2} xs={12}>
            <label>Representante:</label>
            <Field
              name="tradeRepresentativeId"
              value={Status_IE}
              component={Select}
            >
              <option value="">Selecione</option>
              {tradeRepresentativeOptions.map((representative) => (
                <option key={representative.id} value={representative.id}>
                  {representative.companyName || representative.tradingName}
                </option>
              ))}
            </Field>
          </Col>
        )}
      </Col>

      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <Col lg={2} md={2} sm={2} xs={12}>
          <Field
            label="Data de Cadastro:"
            disabled
            name="createdAt"
            component={Input}
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={12}>
          <label>Retém ISS:</label>
          <Field name="withholdsISS" value={withholdsISS} component={Select}>
            <option value="1">Sim</option>
            <option value="2">Não</option>
          </Field>
        </Col>
        {isPlanPrime && (
          <Col lg={2} md={2} sm={2} xs={12}>
            <label>Abordagem:</label>
            <Field name="approachId" value={approachId} component={Select}>
              <option value="">Selecione</option>
              {approachOptions.map((approach) => (
                <option key={approach.id} value={approach.id}>
                  {approach.description}
                </option>
              ))}
            </Field>
          </Col>
        )}

        {isWorkmotor && !isPlanWorkmotorFree && (
          <>
            <Col lg={2} md={2} sm={2} xs={12}>
              <Field
                name="debtLimit"
                component={RenderField}
                label="Limite Devedor:"
                {...currencyMask}
              />
            </Col>
            {company?.companyConfig.manageInternalCredit && (
              <Col lg={2} md={2} sm={2} xs={12}>
                <Field
                  name="availableCredit"
                  component={RenderField}
                  label="Crédito Disponível:"
                  disabled
                  {...currencyMask}
                />
              </Col>
            )}
            {company?.companyConfig.sellingPriceType ===
              'Preço Atacado e Varejo' && (
              <Col lg={2} md={2} sm={2} xs={12}>
                <label>Tipo de Preço:</label>
                <Field
                  name="sellingPriceType"
                  value={priceType}
                  component={Select}
                  onChange={(e) => {
                    setPriceType(e.target.value);
                  }}
                >
                  <option value="Varejo">Varejo</option>
                  <option value="Atacado">Atacado</option>
                </Field>
              </Col>
            )}
          </>
        )}
      </Col>

      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px' }}
        className="form-client-dadosb-footer"
      >
        <Col lg={6} md={6} sm={4} xs={12}>
          <label>Observações (máximo de 500 caracteres):</label>
          <Field
            name="Observation"
            component={TextArea}
            normalize={descriptionWithAltSign}
            maxLength="500"
          />
        </Col>

        <Col
          lg={2}
          md={2}
          sm={4}
          xs={12}
          style={{
            display: 'flex',
            marginLeft: 'auto',
            justifyContent: 'flex-end',
          }}
        >
          <button className="btn-green" type="submit">
            Prosseguir
          </button>
        </Col>
      </Col>
    </>
  );
};

export default PF;
