import React, { useState, useEffect } from 'react'

import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'
import SearchInput from 'components/SearchInput'

import NFSePatternRepository from 'repositories/NFSePatterns'
import CollapsibleSection from 'components/CollapsibleSection'
import AddTributeModal from './AddTributeModal'
import TributeTable from './TributeTable'
import useFilters from 'hooks/useFilters'
import CopyTributesModal from './CopyTributesModal'

const OperationTaxesMain = ({ patternId, setPatternName, history }) => {
  const [patternInfo, setPatternInfo] = useState({})
  const [operationTaxes, setOperationTaxes] = useState([])

  const [isAddTributeModalOpen, setIsAddTributeModalOpen] = useState(false)
  const [isDeleteTributeModalOpen, setIsDeleteTributeModalOpen] =
    useState(false)
  const [isCopyTributesModalOpen, setIsCopyTributesModalOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter
  const [type, setType] = useState('')

  const [selectedOperationTax, setSelectedOperationTax] = useState(null)

  useEffect(() => {
    loadPatternInfo(patternId)
    loadPatternOperationTaxes(patternId)
  }, [patternId])

  const handleFilters = (operationTaxes) => {
    const searchQuery = [
      operationTaxes['description'],
      operationTaxes['typeCode'],
    ]
    return filterByQuery(searchQuery)
  }

  async function loadPatternOperationTaxes(patternId) {
    setLoading(true)
    try {
      const response = await NFSePatternRepository.listOperationTaxes(patternId)

      let operations = []
      let taxes = []

      if (response !== null) {
        operations = response.operations
        taxes = response.taxes
      }

      const operationTaxes = [...operations, ...taxes].sort((a, b) => {
        return b.id - a.id
      })

      setOperationTaxes(operationTaxes)
    } catch (err) {
      console.log(err)
      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar os tributos e operações do padrão. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function loadPatternInfo(patternId) {
    setLoading(true)
    try {
      const { data } = await NFSePatternRepository.show(patternId)

      setPatternName(data.pattern)
      setPatternInfo(data)
    } catch (err) {
      console.log(err)

      toastr.error(
        'Erro',
        'Ocorreu um erro ao carregar o padrão. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleOperationTaxSubmit(operationTaxId, data) {
    if (!operationTaxId) {
      handleAddTributeSubmit(data)
    } else {
      handleUpdateOperationTax(operationTaxId, data)
    }
  }

  async function handleAddTributeSubmit(data) {
    setLoading(true)
    try {
      const dataToCompare = {
        ...data,
        type: data.type === 'Operação' ? 'O' : 'T',
      }

      console.log(dataToCompare)

      const operationTax = operationTaxes.find(
        (operationTax) =>
          operationTax.type === dataToCompare.type &&
          operationTax.typeCode === dataToCompare.code
      )

      if (!!operationTax) {
        toastr.warning(
          'Não foi possível cadastrar o tributo',
          'Já existe um tributo com esse tipo e código. Verifique e tente novamente.'
        )
        return
      }

      await NFSePatternRepository.addTribute({
        NFSPatternId: patternId,
        type: data.type === 'Operação' ? 'O' : 'T',
        typeCode: data.code,
        description: data.description,
      })

      setIsAddTributeModalOpen(false)
      toastr.success('Sucesso', 'Tributo adicionado com sucesso!')

      loadPatternOperationTaxes(patternId)
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao adicionar o tributo. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleUpdateOperationTax(operationTaxId, data) {
    setLoading(true)
    try {
      const dataToCompare = {
        ...data,
        type: data.type === 'Operação' ? 'O' : 'T',
      }

      const operationTax = operationTaxes.find(
        (operationTax) =>
          operationTax.type === dataToCompare.type &&
          operationTax.typeCode === dataToCompare.code &&
          operationTax.id !== operationTaxId
      )

      if (!!operationTax) {
        toastr.warning(
          'Não foi possível cadastrar o tributo',
          'Já existe um tributo com esse tipo e código. Verifique e tente novamente.'
        )
        return
      }

      await NFSePatternRepository.updateOperationTax(operationTaxId, {
        NFSPatternId: patternId,
        type: data.type === 'Operação' ? 'O' : 'T',
        typeCode: data.code,
        description: data.description,
      })

      setIsAddTributeModalOpen(false)
      toastr.success('Sucesso', 'Tributo atualizado com sucesso!')

      loadPatternOperationTaxes(patternId)
      setSelectedOperationTax(null)
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao atualizar o tributo. Tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  async function handleDeleteTribute(id) {
    setLoading(true)
    try {
      await NFSePatternRepository.deleteOperationTax(id)

      toastr.success(
        'Cadastro excluído com sucesso',
        'O tributo foi excluído do padrão e não poderá mais ser utilizado.'
      )

      loadPatternOperationTaxes(patternId)
      setSelectedOperationTax(null)
    } catch (err) {
      toastr.error(
        'Erro',
        'Ocorreu um erro ao excluir o tributo. Tente novamente!'
      )
    } finally {
      setLoading(false)
      setIsDeleteTributeModalOpen(false)
    }
  }

  return (
    <div className="pattern-form__wraper">
      <CollapsibleSection
        title="Tributos do Padrão"
        isExpanded={true}
        handleExpand={() => null}
      >
        <div className="pattern-form__row-1">
          <div>
            <label htmlFor="pattern">Nome do Padrão:</label>
            <input
              type="text"
              id="pattern"
              disabled={true}
              className="form-control foco-input"
              value={patternInfo?.pattern}
            />
          </div>
          <div>
            <label htmlFor="pattern">Tipo:</label>
            <input
              type="text"
              id="pattern"
              disabled={true}
              className="form-control foco-input"
              value={patternInfo?.offline ? 'Offline' : 'Online'}
            />
          </div>
          <div>
            <label htmlFor="pattern">Status:</label>
            <input
              type="text"
              id="pattern"
              disabled={true}
              className="form-control foco-input"
              value={patternInfo?.isHomologated ? 'Homologado' : 'Produção'}
            />
          </div>
        </div>
        <section className="operation-taxes-filter-row">
          <button
            className="btn btn-sucesso new-boleto-flex-config"
            onClick={() => setIsAddTributeModalOpen(true)}
          >
            + Novo Tributo
          </button>
          <div className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por Código e Descrição"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <fieldset style={{ display: 'flex', marginLeft: '15px', gap: '1px' }}>
            <label>Tipo:</label>
            <select
              className="form-control foco-input"
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <option value="">Ambos</option>
              <option value="O">Operação</option>
              <option value="T">Tributação</option>
            </select>
          </fieldset>
          <button
            style={{ marginLeft: '15px' }}
            className="btn btn-warning"
            onClick={() => setIsCopyTributesModalOpen(true)}
          >
            Copiar Tributos
          </button>
        </section>

        <TributeTable
          operationTaxes={operationTaxes}
          loading={loading}
          handleDeleteTribute={handleDeleteTribute}
          isDeleteTributeModalOpen={isDeleteTributeModalOpen}
          setIsDeleteTributeModalOpen={setIsDeleteTributeModalOpen}
          handleFilters={handleFilters}
          type={type}
          selectedOperationTax={selectedOperationTax}
          setSelectedOperationTax={setSelectedOperationTax}
          setIsAddTributeModalOpen={setIsAddTributeModalOpen}
        />

        {isAddTributeModalOpen && (
          <AddTributeModal
            loading={loading}
            handleClose={() => setIsAddTributeModalOpen(false)}
            handleOperationTaxSubmit={handleOperationTaxSubmit}
            operationTaxId={selectedOperationTax}
          />
        )}

        {isCopyTributesModalOpen && (
          <CopyTributesModal
            patternId={patternId}
            handleClose={() => setIsCopyTributesModalOpen(false)}
            handleReload={() => loadPatternOperationTaxes(patternId)}
          />
        )}
      </CollapsibleSection>
    </div>
  )
}

export default withRouter(OperationTaxesMain)
