import React from 'react'
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer'
import {
  cpfMask,
  cnpjMask,
  cepMask,
  phoneMask,
  currency,
} from '../../client/components/ToNormalize/ToNormalize'
import { format, isPast, addHours } from 'date-fns'

const ItemLabel = ({ label, value }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
)

const TitlesHeader = () => (
  <View
    style={{
      flexDirection: 'row',
      height: 12,
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Vencimento</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Título</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Venda</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Parcela</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Valor</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Juros</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Acréscimo</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Descontos</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Valor Total</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text>Criação</Text>
    </View>
  </View>
)

const TotalHeader = ({ totalAmount }) => (
  <View
    style={{
      flexDirection: 'row',
      fontWeight: 'bold',
      width: '100%',
    }}
  >
    <View style={{ width: '80%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={{ fontSize: 10 }}>
        Total
      </Text>
    </View>
    <View style={{ width: '20%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={{ fontSize: 10 }}>
        {currency(totalAmount)}
      </Text>
    </View>
  </View>
)

const TitlesRow = ({ code, dueDate, saleCode, amount, feeValue, installment, issuedAt, addedValue, discountValue }) => (
  <View
    style={{ flexDirection: 'row', height: 12, width: '100%' }}
    wrap={false}
  >
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>
        {format(addHours(new Date(dueDate), 3), 'dd/MM/yyyy')}
      </Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{code}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{saleCode}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{installment}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(amount)}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(feeValue)}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(addedValue)}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(discountValue)}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>{currency(amount + feeValue + addedValue - discountValue)}</Text>
    </View>
    <View style={{ width: '10%', textAlign: 'left', justifyContent: 'center' }}>
      <Text style={styles.span}>
        {format(addHours(new Date(issuedAt), 3), 'dd/MM/yyyy')}
      </Text>
    </View>
  </View>
)

export const FinancialStatementPDF = ({
  sales = [],
  customer = {},
  valuesByVehicle = [],
  initialDate,
  finalDate,
  titles = [],
  saleId,
}) => {
  const customerAddress = customer.Address.length ? customer.Address[0] : {}
  const customerPhone = customer.Phones.length
    ? customer.Phones[0].Number_Phone2
    : ''
  const currentDate = format(new Date(), 'dd/MM/yyyy HH:mm:ss')

  return (
    <Document
      title={`impresso-ficha-financeira-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`${customer.Company_Name}`}
    >
      <Page orientation={'landscape'} style={styles.page}>
        <View>
          <Text style={styles.title}>Ficha Financeira</Text>
          <View style={styles.line} />
          <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
            <Text style={{ fontWeight: 'bold', marginRight: 3 }}>
              Relatório gerado em:
            </Text>
            <Text>
              {currentDate}
            </Text>
          </View>
        </View>
        <View style={{ display: 'flex', width: '70%' }}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <ItemLabel
              label='CPF/CNPJ'
              value={
                customer.Cpf_Cnpj.length > 11
                  ? cnpjMask(customer.Cpf_Cnpj)
                  : cpfMask(customer.Cpf_Cnpj)
              }
            />
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <ItemLabel label='Razão Social/Nome' value={customer.Company_Name} />
            <ItemLabel label='Telefone' value={phoneMask(customerPhone)} />
            <ItemLabel label='Email' value={customer.Email} />
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <ItemLabel
              label='Endereço'
              value={
                !!customerAddress.Address
                  ? `${customerAddress.Address}, ${customerAddress.Address_Number}`
                  : ''
              }
            />
            <ItemLabel label='Bairro' value={customerAddress.Neighborhood} />
            <ItemLabel
              label='Cidade/UF'
              value={
                !!customerAddress.Address
                  ? `${customerAddress.City} / ${customerAddress.State}`
                  : ''
              }
            />
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <ItemLabel label='CEP' value={cepMask(customerAddress.Zipcode)} />
          </View>
        </View>
        <View style={[styles.line, { marginTop: 5 }]} />

        {!saleId
          ?
          <>
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 15,
                marginTop: 5,
                marginBottom: 5
              }}
            >
              Títulos Vencidos
            </Text>
            <View style={styles.line} />
            <TitlesHeader />
            <View style={styles.line} />
            {titles
              .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
              .filter(
                (title) =>
                  title.billStatusId === 1 && isPast(new Date(title.dueDate))
              )
              .map((values, index) => (
                <TitlesRow
                  key={index}
                  code={values.Code}
                  dueDate={values.dueDate}
                  saleCode={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.Code : values.Sales?.Code}
                  feeValue={values.feeValue}
                  amount={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.SubTotal : values.Sales?.SubTotal}
                  installment={values.installment}
                  issuedAt={values.issuedAt}
                  addedValue={values.addedValue}
                  discountValue={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.Discount_Value : values.Sales?.Discount_Value}
                />
              ))}
            <View style={[styles.line, { marginTop: 5, marginBottom: 2 }]} />
            <TotalHeader
              totalAmount={
                titles
                  .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
                  .filter(
                    (title) =>
                      title.billStatusId === 1 && isPast(new Date(title.dueDate))
                  )
                  .map(item => ((item.BillsToReceiveSales?.length ? item.BillsToReceiveSales[0].Sales?.SubTotal : item.Sales?.SubTotal) + item.feeValue + item.addedValue - (item.BillsToReceiveSales?.length ? item.BillsToReceiveSales[0].Sales?.Discount_Value : item.Sales?.Discount_Value)))
                  .reduce((prev, curr) => prev + curr, 0)
              }
            />
            <View style={[styles.line, { marginTop: 2 }]} />
            <Text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 15,
                marginTop: 5,
                marginBottom: 5
              }}
            >
              Títulos Abertos
            </Text>
            <View style={styles.line} />
            <TitlesHeader />
            <View style={styles.line} />
            {titles
              .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
              .filter(
                (title) =>
                  title.billStatusId === 1 && !isPast(new Date(title.dueDate))
              )
              .map((values, index) => (
                <TitlesRow
                  key={index}
                  code={values.Code}
                  dueDate={values.dueDate}
                  saleCode={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.Code : values.Sales?.Code}
                  feeValue={values.feeValue}
                  amount={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.SubTotal : values.Sales?.SubTotal}
                  installment={values.installment}
                  issuedAt={values.issuedAt}
                  addedValue={values.addedValue}
                  discountValue={values.BillsToReceiveSales?.length ? values.BillsToReceiveSales[0].Sales?.Discount_Value : values.Sales?.Discount_Value}
                />
              ))}
            <View style={[styles.line, { marginTop: 5, marginBottom: 2 }]} />
            <TotalHeader
              totalAmount={
                titles
                  .sort((a, b) => (parseInt(a.Code) > parseInt(b.Code) ? 1 : -1))
                  .filter(
                    (title) =>
                      title.billStatusId === 1 && !isPast(new Date(title.dueDate))
                  )
                  .map(item => ((item.BillsToReceiveSales?.length ? item.BillsToReceiveSales[0].Sales?.SubTotal : item.Sales?.SubTotal) + item.feeValue + item.addedValue - (item.BillsToReceiveSales?.length ? item.BillsToReceiveSales[0].Sales?.Discount_Value : item.Sales?.Discount_Value)))
                  .reduce((prev, curr) => prev + curr, 0)}
            />
            <View style={[styles.line, { marginTop: 2 }]} />
          </>

          : <View />}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
})
