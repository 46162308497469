import React from 'react'
import { withRouter } from 'react-router'

import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import NFSePatternMain from './components/Main'

import './styles.css'

const NFSePattern = ({ match, history }) => {
  return (
    <>
      <BreadCrumb
        data={[
          'Início',
          'Configurações NFS-e',
          match.params.id ? 'Editar Padrão' : 'Novo Padrão',
        ]}
        path={['home', 'nfse-settings', null]}
      />
      <span style={{ color: 'red', position: 'absolute', right: 10 }}>
        * Campos Obrigatórios
      </span>
      <NFSePatternMain
        patternId={history.location?.hash?.replace('#', '')}
        goToHomePage={() => history.push('nfse-settings')}
      />
    </>
  )
}

export default withRouter(NFSePattern)
