import React from 'react';
import { InputPercentageStyled, InputPercentageWrapper } from './styles';

export const InputPercentage = React.forwardRef(
  ({ width, height = 40, ...props }, ref) => {
    return (
      <InputPercentageWrapper width={width} height={height}>
        <InputPercentageStyled
          type={'text'}
          {...props}
          ref={ref}
          thousandSeparator="."
          decimalSeparator=","
          suffix="%"
          maxLength={7}
          onChangeEvent={(e, maskedValue, floatValue) => {
            props.onChange(floatValue);
          }}
        />
      </InputPercentageWrapper>
    );
  }
);

InputPercentage.displayName = 'InputPercentage';
