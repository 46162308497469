import React from 'react';
import { Col } from 'react-bootstrap';
import { change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';

import { description } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../contexts/auth';

import RenderField from 'components/RenderField';

const PrismaBoxData = ({ prismaboxId }) => {
  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const { status, descr, observation } = useSelector((state) => state).form
    .prismabox.values;

  return (
    <>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body"
        id="form-service"
      >
        <Col xs={12} sm={7} md={4} lg={6}>
          <Field
            label="Descrição:"
            name="descr"
            component={RenderField}
            normalize={(value) => description(value)}
            maxLength={50}
            required
          />
        </Col>
        <Col xs={12} sm={3} md={1} lg={1}>
          <label>Status</label>
          <div>
            {' '}
            <Toggle
              className="custom-toggle-discount"
              checked={!!status}
              onChange={(e) =>
                dispatch(change('prismabox', 'status', e.target.checked))
              }
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className="field-hiperlink">
          <Field
            label="Observações:"
            name="observation"
            component={RenderField}
            as="textarea"
            normalize={description}
            style={{
              resize: 'vertical',
              marginBottom: '8px',
            }}
          />
        </Col>
      </div>
    </>
  );
};

export default PrismaBoxData;
