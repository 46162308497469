import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { useForm, useWatch } from 'react-hook-form';

import { useAuth } from 'contexts/auth';
import { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';

import VehiclesRepository from 'repositories/Vehicles';
import VehicleHistoryRepository from 'repositories/VehicleHistory';
import customersRepository from 'repositories/Customers';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import PDFButton from 'client/components/PDFButton';
import CustomerHistory from 'repositories/CustomerHistory';

export default function VehicleHistoryMain() {
  const { companyId } = useAuth();

  const [valueSearchAux, setValueSearchAux] = useState(null);
  const [costumerIdAux, setCostumerIdAux] = useState(null);
  const [searchValue, setSearchValue] = useState('costumer');
  const [clients, setClients] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [clientsSuggestions, setClientsSuggestions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      vehicleId: clientsSuggestions === 'Cliente' ? vehicleId : '',
      initialDate: '',
      finalDate: '',
      saleType: 'ALL',
      companyId,
    },
  });

  const vehicleId = useWatch({
    control,
    name: 'vehicleId',
  });

  const initialDate = useWatch({
    control,
    name: 'initialDate',
  });

  const finalDate = useWatch({
    control,
    name: 'finalDate',
  });

  const getVehicleHistory = async (data) => {
    let result;
    try {
      if (searchValue === 'vehicle') {
        result = await VehicleHistoryRepository.getByVehicleId(vehicleId, data);
      } else {
        result = await CustomerHistory.getCompleteCustomerHistory(
          costumerIdAux,
          {
            initialDate: data.initialDate,
            finalDate: data.finalDate,
            saleType: data.saleType,
            companyId: data.companyId,
          }
        );
      }
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os dados do relatório. Por favor, tente novamente'
      );
    }
    return searchValue === 'vehicle' ? result : result?.data;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    if (!valueSearchAux) {
      if (searchValue === 'vehicle') {
        setIsLoading(false);
        return toastr.warning(
          'Selecione um veículo para gerar o relatório e tente novamente.'
        );
      } else {
        setIsLoading(false);
        return toastr.warning(
          'Selecione um cliente para gerar o relatório e tente novamente.'
        );
      }
    }

    if (!finalDate || !initialDate) {
      setIsLoading(false);
      return toastr.warning(
        'Selecione um período válido para gerar o relatório e tente novamente.'
      );
    }

    try {
      if (new Date(finalDate) && new Date(initialDate)) {
        if (new Date(finalDate) < new Date(initialDate)) {
          toastr.warning('A data final deve ser maior que a data inicial.');
          return false;
        }
      }
      const vehicleHistory = await getVehicleHistory(data);

      if (
        vehicleHistory?.sales?.length === 0 ||
        vehicleHistory?.data?.sales?.length === 0
      ) {
        return toastr.warning(
          'Não foi localizada nenhuma informação com os filtros informados. Verifique os filtros e tente novamente.'
        );
      }

      if (searchValue === 'vehicle') {
        window.open(
          window.location.origin +
            `/report/vehicle-history?vehicleId=${vehicleId}&initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyId}&saleType=${data.saleType}`,
          '_blank'
        );
      } else {
        const customerIdHash = encrypt(costumerIdAux, '@OS-dig:customerId');
        window.open(
          window.location.origin +
            `/report/customer-history?customerId=${customerIdHash}&initialDate=${initialDate}&finalDate=${finalDate}`,
          '_blank'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao buscar informações',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadVehiclesOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const vehicles = await VehiclesRepository.getAllByCompany(companyId);
      setVehicleOptions(
        vehicles.map((vehicle) => {
          return {
            label:
              vehicle.License_Plate.replace(
                /(\d{0})(\d)/,
                '$1-$2'
              ).toString() || vehicle.Maintenance_Object,
            value: vehicle.id,
          };
        })
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os veículos',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  const loadClients = async () => {
    setIsLoading(true);
    try {
      const clients = await customersRepository.getAllByCompany(companyId);

      setClients(clients);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os clientes. Por favor, tente novamente'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue === 'vehicle') {
      loadVehiclesOptions();
    } else {
      loadClients();
    }
  }, [searchValue]);

  useEffect(() => {
    const suggestions = clients.map((client) => {
      const clientName =
        client.Type === 'Fisica'
          ? `Nome: ${client.Company_Name}`
          : `Razão Social: ${client.Company_Name}, Nome Fantasia: ${client.Trading_Name}`;

      const clientDocument = client.Cpf_Cnpj
        ? client.Type === 'Fisica'
          ? `CPF: ${client.Cpf_Cnpj}`
          : `CNPJ: ${client.Cpf_Cnpj}`
        : '';

      return {
        value: client.id,
        label: `${clientName} ${clientDocument && `, ${clientDocument}`}`,
      };
    });

    setClientsSuggestions(suggestions);
  }, [clients]);

  return (
    <div className="vehicle-history-form">
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 200 }}>
          <strong>Pesquisar por:</strong>
        </span>
        <select
          className="form-control foco-input"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setValueSearchAux('');
          }}
        >
          <option value="costumer" selected>
            Cliente
          </option>
          <option value="vehicle">Veículo</option>
        </select>
      </div>
      <div
        style={{
          width: '465px',
          marginTop: '20px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center', width: 400 }}>
          <strong></strong>
        </span>
        <Select
          placeholder={
            searchValue === 'vehicle'
              ? 'Pesquisar por Placa/Objeto de Manutenção'
              : 'Pesquisar por CPF/CNPJ ou Nome/Razão Social'
          }
          options={
            searchValue === 'vehicle' ? vehicleOptions : clientsSuggestions
          }
          value={valueSearchAux}
          styles={{
            control: (base) => ({
              ...base,
              height: '35px',
              minHeight: '35px',
              borderColor: '#E3E3E3',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              marginTop: '-6px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              marginTop: '-6px',
            }),
          }}
          onChange={(e) => {
            setValueSearchAux(e);
            setValue('vehicleId', e.value);
            setCostumerIdAux(e.value);
          }}
          noOptionsMessage={() => 'Sem opções'}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Inicial:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          {...register('initialDate')}
          max={finalDate}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Data Final:</strong>
        </span>
        <input
          className="form-control foco-input"
          type="date"
          {...register('finalDate')}
          min={initialDate}
        />
      </div>
      <div>
        <span style={{ display: 'flex', alignItems: 'center', width: 150 }}>
          <strong>Tipo de Venda:</strong>
        </span>
        <select className="form-control foco-input" {...register('saleType')}>
          <option value="ALL">Todos</option>
          <option value="2">Venda</option>
          <option value="3">Ordem de Serviço</option>
          <option value="1">Orçamento</option>
        </select>
      </div>
      <div style={{ marginTop: '23px', marginRight: '5px' }}>
        <PDFButton isLoading={isLoading} onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
}
