import React from 'react';
import {
  Label,
  Option,
  OptionSelectorContainer,
  OptionsWrapper,
} from './OptionSelector.styles';

import useManagementIndicatorsStore from '../../store/management-indicators-store';

export function OptionSelector() {
  const { selectedOption, setSelectedOption } = useManagementIndicatorsStore();

  return (
    <OptionSelectorContainer>
      <Label>Selecione a opção desejada: </Label>
      <OptionsWrapper>
        <Option
          selected={selectedOption === 'Financeiro'}
          onClick={() => setSelectedOption('Financeiro')}
        >
          Financeiro
        </Option>
        <Option
          selected={selectedOption === 'Vendas'}
          onClick={() => setSelectedOption('Vendas')}
        >
          Vendas
        </Option>
      </OptionsWrapper>
    </OptionSelectorContainer>
  );
}
