import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #f4f4f4;
  position: relative;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 67px;
  background: ${({ theme }) => theme.colors.branding1000};
  position: fixed;
  box-sizing: border-box;
  padding: 10px;
  z-index: 1000;
`;

export const HeaderLogo = styled.img`
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: 70px;

  padding-bottom: 200px;
`;

export const Span = styled.span`
  font-size: 14px;
`;

export const Content = styled.div`
  width: 70%;
  overflow: auto;
`;

export const Separator = styled.hr`
  border-top: 1px solid #d6d6d6;
`;

export const CheckboxLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  color: #444 !important;
  margin: 0 !important;
  padding: 0 !important;
`;

export const PageTitle = styled.h1`
  font-size: 18px !important;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.branding1000};
  display: flex;
  gap: 10px;
  align-items: center;

  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 5px;
`;

export const Card = styled.div`
  padding: 10px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
`;

export const ExportButton = styled.button`
  height: 35px !important;
  display: flex;
  align-items: center;
`;

export const TableButton = styled.button`
  width: 145px;
  height: 35px;
  display: flex;
  align-items: center;
`;

export const CheckboxesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 15px;

  & input[type='checkbox'] {
    margin: 0 !important;
  }
`;

export const ExportTimeAdvice = styled.span`
  display: block;
  padding: 10px 30px;
  color: #d43f3a;
  font-size: 12px;
  text-align: center;
  font-weight: medium;
`;

export const DontCloseAdvice = styled.span`
  display: block;
  color: #d43f3a;
  font-size: 12px;
  text-align: center;
  font-weight: medium;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 1fr;
`;

export const HeaderItem = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.branding1000};
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr 1fr;
  padding: 15px 0px;
`;

export const InvoiceType = styled.span`
  color: ${({ theme }) => theme.colors.branding1000};
  font-weight: bold;
  font-size: 14px;
`;

export const InvoiceTypeDescription = styled.span`
  color: ${({ theme }) => theme.colors.branding1000};
  font-size: 12px;
  font-weight: light;
`;

export const InvoiceTotalizatorsList = styled.ul`
  list-style-type: none;
  margin: 0;

  & li {
    font-size: 12px;
    color: #444;
  }
`;

export const TotalizatorText = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #444;
`;
