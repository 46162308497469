import ActionTypes from "./types";
import productsRrepository from "../../../../repositories/Products";

export const handleChange = (type, value) => {
  return {
    type: ActionTypes.handleChange,
    payload: {
      type,
      value,
    },
  };
};

export const getProductsUnderStock = (companyId) => async (dispatch) => {
  try {
    const { data } = await productsRrepository.getProductsUnderMinStock(
      companyId
    );

    dispatch(handleChange("products", data));
  } catch (err) {
    const message =
      err?.response?.data?.message ||
      "Falha ao buscar produtos com estoque mínimo.";
    return {
      type: ActionTypes.failureGetProductsUnderStock,
      payload: {
        message,
      },
    };
  }
};

