import React from "react";
import { Col } from "react-bootstrap";
import { reduxForm, change, Field } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";

import TextArea from "../../../Clients/NewClient/FormClient/components/TextArea";

import {
  description,
  cnpjMask,
} from "../../../../components/ToNormalize/ToNormalize";
import Input from "../../../Clients/NewClient/FormClient/components/Input";

function DadosCadastrais({ edit }) {
  const dispatch = useDispatch();

  const { isActive } = useSelector(
    (state) => state.form.formIntermediary.values
  );

  const handleToggle = () => {
    dispatch(change("formIntermediary", "isActive", !isActive));
  };

  return (
    <div>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="CNPJ:"
          required
          name="cpfCnpj"
          component={Input}
          normalize={cnpjMask}
          placeholder=""
          maxLength="18"
          disabled={edit}
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Descrição:"
          required
          name="description"
          component={Input}
          normalize={description}
          maxLength="80"
        />
      </Col>
      <Col lg={1} md={2} sm={1} xs={12}>
        <label>
          Status:<span style={{ color: "red" }}>*</span>
        </label>
        <div id="toggle-status">
          <Toggle checked={isActive} onChange={handleToggle} />
        </div>
      </Col>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="form-client-dadosb-footer"
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <label>Observações:</label>

          <Field
            name="observations"
            component={TextArea}
            normalize={description}
            maxLength="200"
          />
        </Col>
      </Col>
    </div>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: "formIntermediary",
})(DadosCadastrais);
