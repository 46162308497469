import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import './styles.css'
import HomeRankingSales from './HomeRankingSales'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const SalesRanking = () => {
  localStorage.setItem('selectedPath', 'client/ranking-sales')
  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={[
              'Início',
              'Relatórios',
              'Vendas',
              'Ranking dos Produtos/Serviços Vendidos',
            ]}
            path={['home', null, null, null]}
          />
          <HomeRankingSales />
        </Row>
      </Grid>
    </div>
  )
}
export default SalesRanking
