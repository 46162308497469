import React, { useState } from 'react';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertModal from 'components/AlertModal';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import videosLinksRepository from '../../../../repositories/VideosLinks';
import { BrandingLabel } from 'v2/components/BrandingLabel';

const VideoTable = ({ loading, videoLink, loadVideoLinks }) => {
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(0);

  const handleDelete = (videoLink) => {
    setSelectedVideo(videoLink.id);
    setShowDeleteProductModal(true);
  };

  const deleteVideo = async () => {
    try {
      await videosLinksRepository.deleteVideosLinks(selectedVideo);

      toastr.success('Vídeo excluído', 'O vídeo foi excluído com sucesso.');

      await loadVideoLinks({});
    } catch (err) {
      toastr.error(err.message);
    } finally {
      setShowDeleteProductModal(false);
    }
  };

  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          maxWidth: '1000px',
        }}
        data={videoLink}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 130,
            Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
          },
          {
            Header: 'Produto',
            accessor: 'brandingId',
            width: 90,
            Cell: (props) => <BrandingLabel brandingId={props.value} />,
          },
          {
            Header: 'Página',
            accessor: 'route',
            width: 200,
          },
          {
            Header: 'Descrição',
            accessor: 'title',
            width: 280,
          },
          {
            Header: 'Vídeo',
            accessor: 'link',
            width: 220,
          },
          {
            Header: 'Ações',
            accessor: 'id',
            headerClassName: 'text-left',
            filterable: false,
            className: 'texto',
            width: 80,
            Cell: (props) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Link to={{ pathname: `videos-update#${props.value}` }}>
                  <FontAwesomeIcon
                    title="Editar videos"
                    cursor="pointer"
                    icon={faEdit}
                    style={{ width: '1.5em', height: '1.5em', color: '#000' }}
                  />
                </Link>
                <FontAwesomeIcon
                  title="Editar notícia"
                  cursor="pointer"
                  icon={faTrashAlt}
                  style={{ width: '1.5em', height: '1.5em', color: 'red' }}
                  onClick={() => handleDelete(props.original)}
                />
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        defaultSorted={[{ id: 'status', desc: true }]}
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum video encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      {
        <AlertModal
          show={showDeleteProductModal}
          title="OS Digital"
          subtitle="Você tem certeza que deseja excluir esse vídeo?"
          message="Ao excluir o vídeo, não será mais exibido toda vez que o cliente clicar em vídeo na tela informada, sendo necessário cadastrar novamente."
          onHide={() => setShowDeleteProductModal(false)}
          onCancel={() => setShowDeleteProductModal(false)}
          onSubmit={deleteVideo}
          loading={loading}
        />
      }
    </div>
  );
};

export default VideoTable;
