import styled from 'styled-components';

export const Container = styled.div`
  padding: ${(props) => props.theme.space[4]};
  width: 100%;

  & div.content__main {
    margin-top: 35px;
  }
`;

export const RequiredFieldSpan = styled.span`
  color: ${(props) => props.theme.colors.red400};
  position: absolute;
  right: 180px;
  top: 12px;
`;
