import React from 'react';

import {
  SidebarItemStyled,
  SidebarItemDescription,
  SidebarItemIcon,
} from './SidebarItem.styles';

import { SidebarSubMenu } from '../SidebarSubMenu';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';

// import { SidebarSubMenu } from '../SidebarSubMenu';

export const SidebarItem = ({
  title,
  active,
  currentActiveItem,
  path,
  subMenu,
  icon,
  ...props
}) => {
  const { handleMenuItemClick, validateFeatureAvailability } = useSidebar();
  const hasSubMenu = !!subMenu?.title;
  const isAvailable = validateFeatureAvailability({ title, ...props });

  return (
    <SidebarItemStyled
      active={currentActiveItem === active}
      onClick={() =>
        path ? handleMenuItemClick(path, { title, path, ...props }) : null
      }
      disabledStyle={!isAvailable}
    >
      <SidebarItemIcon icon={icon} disabledStyle={!isAvailable} />
      <SidebarItemDescription disabledStyle={!isAvailable}>
        {title}
      </SidebarItemDescription>

      {hasSubMenu && <SidebarSubMenu {...subMenu} />}
    </SidebarItemStyled>
  );
};
