import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import ReactTable from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import useFilters from 'hooks/useFilters'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'
import SearchInput from 'components/SearchInput'
import Button from 'client/components/CustomButton/Button.jsx'
import materaCompanyRepository from 'repositories/MateraCompany'

import './styles.css'

const Matera = ({ history }) => {
  const [companyLinks, setCompanyLinks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadMateraCompanies()
  }, [])

  const loadMateraCompanies = async () => {
    setLoading(true)
    try {
      const { data } = await materaCompanyRepository.index();
      const serializedCompanyLinks = data.map((companyLink) => ({
        id: companyLink.id,
        cpfCnpj: cpfOrCnpjMask(companyLink.materaCompany.Cpf_Cnpj),
        companyName: companyLink.materaCompany.Company_Name,
        tradingName: companyLink.materaCompany.Trading_Name,
        accountNumber: companyLink.accountNumber,
        integrationStatus: 
          companyLink.status === 'FINALIZADO' ? 'Finalizado' :
          companyLink.status === 'NÃO FINALIZADO' ? 'Não Finalizado' :
          companyLink.status === 'AGUARDANDO RETORNO' ? 'Aguardando Retorno' :
          'Erro na Criação'
      }))

      setCompanyLinks(serializedCompanyLinks)
    } catch (err) {
      console.log(err)
      return toastr.error(
        'Ocorreu um erro ao carregar as contas. Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  const { statusFilter, queryFilter, dateFilter } = useFilters()

  const { query, setQuery, filterByQuery } = queryFilter
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter
  const { status, setStatus, filterByStatus } = statusFilter

  function handleFilters(companyLink) {
    const querySearch = [companyLink?.cpfCnpj, companyLink?.companyName, companyLink?.accountNumber]

    return (
      filterByQuery(querySearch) &&
      filterByDate('createdAt', companyLink) &&
      filterByStatus('integrationStatus', companyLink)
    )
  }

  return (
    <main className='stone-link-wrapper'>
      <section className='stone-link-row'>
        <button
          className='btn btn-sucesso new-stone-link'
          onClick={() => history.push('matera-account')}
        >
          + Nova Conta Digital
        </button>
        <div className='search-input-wrapper'>
          <SearchInput
            placeholder='Pesquisa por CNPJ, Razão Social, Nome ou Nº da Conta'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <fieldset>
          <label style={{ width: '75px' }}>Status:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className='form-control foco-input'
          >
            <option value=''>Todos</option>
            <option value='Finalizado'>Finalizado</option>
            <option value='Não Finalizado'>Não Finalizado</option>
            <option value='Aguardando Retorno'>Aguardando Retorno</option>
            <option value='Erro na Criação'>Erro na Criação</option>
          </select>
        </fieldset>
      </section>     
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={companyLinks.filter(handleFilters)}
        columns={[        
          {
            Header: 'CNPJ',
            accessor: 'cpfCnpj',
            width: 175,
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName',
            width: 275,
          },
          {
            Header: 'Nome Fantasia',
            accessor: 'tradingName',
            width: 275,
          },
          {
            Header: 'Nº Conta',
            accessor: 'accountNumber',
            width: 275,
          },
          {
            Header: 'Status',
            accessor: 'integrationStatus',
            width: 200,
            Cell: (props) => (
              <Button
                id='botaoReactTable'
                style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor:
                    props.value === 'Finalizado' ? '#5CB85C' : '#D9534F',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 75,
            Cell: (props) => (
              <Link to={{ pathname: `matera-account#${props.value}` }}>
                <FontAwesomeIcon
                  cursor='pointer'
                  icon={faEdit}
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </Link>
            ),
          },          
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </main>
  )
}

export default withRouter(Matera)
