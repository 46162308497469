import React from 'react';
import { format } from 'date-fns';
import { Button } from 'v2/components/Button/DefaultButton';
import { currency } from 'client/components/ToNormalize/ToNormalize';

export const useGroupedInvoiceEmissionColumnsConfig = (getValues, setValue) => {
  const handleCheckboxChange = (invoice, isChecked) => {
    const selectedSaleInvoices = getValues('selectedSaleInvoices') || [];
    const isAlreadySelected = selectedSaleInvoices.some(
      (i) => i.id === invoice.id
    );

    const updatedInvoice = isChecked
      ? isAlreadySelected
        ? selectedSaleInvoices
        : [...selectedSaleInvoices, invoice]
      : selectedSaleInvoices.filter((i) => i.id !== invoice.id);

    setValue('selectedSaleInvoices', updatedInvoice);
  };

  function SaleTypeButton({ type }) {
    const typeMapping = {
      1: { name: 'Orçamento', color: 'rgb(54, 54, 54)' },
      2: { name: 'Venda', color: 'purple' },
      3: { name: 'Ordem de Serviço', color: 'rgb(0, 0, 139)' },
      4: { name: 'PDV', color: 'rgb(255, 168, 52)' },
    };
    const { name, color } = typeMapping[type] || {};

    return (
      <>
        <Button
          id="botaoReactTable"
          style={{
            zIndex: '0',
            minWidth: '110px',
            height: '100%',
            backgroundColor: color,
          }}
        >
          {name}
        </Button>
      </>
    );
  }
  const configColumnsInvoiceEmission = [
    {
      Header: 'Dt. Criação',
      accessor: 'createdAt',
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Dt. Fechamento',
      accessor: 'closingDate',
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Tipo',

      headerClassName: 'text-center',
      style: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto 0',
      },
      width: 160,
      className: 'texto',

      Cell: (props) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SaleTypeButton type={props.original.SalesType_id} />
            <span>{props.original?.EmployeeName}</span>
          </div>
        );
      },
    },
    {
      Header: 'N° Venda',
      accessor: 'Code',
    },
    {
      Header: 'Cliente',
      accessor: 'CustomerName',
    },
    {
      Header: 'Placa/Obj. Manut',
      width: 130,
      Cell: (props) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <small>{props.original.VehicleLicensePlate}</small>
            <strong style={{ fontSize: '12px' }}>
              {props.original.VehicleModel}
            </strong>
          </div>
        );
      },
    },
    {
      Header: 'Valor',
      accessor: 'Final_Value',
      Cell: (props) => currency(props.value),
    },
    {
      Header: '',
      accessor: 'checkbox',
      Cell: (props) => {
        const selectedSaleInvoices = getValues('selectedSaleInvoices') || [];

        const isChecked = selectedSaleInvoices.some(
          (i) => i.id === props.original.id
        );

        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => {
              handleCheckboxChange(props.original, e.target.checked);
            }}
            style={{ padding: '0px' }}
          />
        );
      },
      width: 50,
    },
  ];
  return {
    configColumnsInvoiceEmission,
  };
};
