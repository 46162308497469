import React, { useEffect, useState } from 'react'
import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import { PDFViewer } from '@react-pdf/renderer'

import { format, addDays } from 'date-fns'

import { QuotesSupplierPDF } from './QuotesSupplierPDF'
import QuotationRepository from 'repositories/Quotations'

export const QuotesSupplierReport = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [result, setResult] = useState([])
  const [totalsPerQuotationProvider, setTotalsPerQuotationProvider] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams) return loadQuery(searchParams)
  }, [location.search])

  const loadQuery = async searchParams => {
    try {
      const initialDate = searchParams.get('initialDate')
      const finalDate = searchParams.get('finalDate')
      const companyId = searchParams.get('companyId')
      const providerId = searchParams.get('providerId')

      setInitialDate(format(addDays(new Date(initialDate), 1), 'dd/MM/yyyy'))
      setFinalDate(format(addDays(new Date(finalDate), 1), 'dd/MM/yyyy'))

      setHasError(false)

      return loadReport(providerId, { companyId, initialDate, finalDate })
    } catch (err) {
      console.log(err)
      setHasError(true)
      setLoading(false)
    }
  }

  const loadReport = async (providerId, params) => {
    try {
      const provId = providerId == '' ? '' : providerId
      const data = await QuotationRepository.getAll({
        ...params,
        providerId: provId
      })

      setTotalsPerQuotationProvider(data.totalsPerQuotationProvider)
      setResult(data.quotationsList)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setHasError(true)
    }
  }

  if (loading) return <AppLoading />

  if (hasError) return <AppError message='Ocorreu um erro ao carregar o impresso. Por favor, tente novamente' />

  return (
    <div id='quotes-supplier-printing-sheet-page'>
      <main style={{ height: '100vh', width: '100vw' }}>
        <PDFViewer
          style={{ height: '100%', width: '100%' }}
        >
          <QuotesSupplierPDF
            result={result}
            totalsPerQuotationProvider={totalsPerQuotationProvider}
            initialDate={initialDate}
            finalDate={finalDate}
          />
        </PDFViewer>
      </main>
    </div>
  )
}
