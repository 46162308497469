import styled from 'styled-components'


export const BankConciliationDetailsContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`
export const HeaderBankConciliationDetailsContainer = styled.header`
display: flex;
flex-direction: column;
width: 100%;
gap: 2rem;

.header-information{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.bank-conciliation-header-card{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;

}
`
export const HeaderBankConciliationDetailsNavLink = styled.header`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 12px;
`
export const BankConciliationHeaderCardContainer = styled.div`
width:100%;
height: 100%;
display: flex;
align-items: center;

border: 1px solid #E1E5EE;
border-radius: 5px;
/* padding-left: 1rem;
padding-top: 1rem; */
padding: 10px;

`
export const BankConciliationHeaderCardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  strong {

    }

  p {
    font-weight: bold;
    font-size: 11px;
    color: #1B2E4B;
    text-transform: uppercase;
  }

  div {
    display: flex;
    flex-direction: row;

    strong {
      font-size: 13px;
    }
  }

  `
export const BankConciliationHeaderCardType = styled.div`
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  gap: .5rem;



`

/* ------------------Search-------------------- */

export const BankConciliationDetailsSearchContainer = styled.div`

display: flex;
flex-direction: column;

/* justify-content: space-between; */

.box-input {
  display: flex;
  flex-direction: column;
  width: 100%;

}
.search {
  display: flex;
  justify-content: end;
  align-items: start;

  input {
    min-width: 350px;
  }
}


`

export const BankConciliationDetailsSearchContent = styled.div`
display: flex;
flex-direction: row;
gap: 1rem;
width: 100%;
justify-content: space-between;

.box-input {
  display: flex;
  flex-direction: column;
  width: 100%;

}
.search {
  display: flex;
  justify-content: end;
  align-items: start;

  input {
    min-width: 350px;
  }
}
`

/* ------------------Financial Table-------------------- */

export const BankConciliationFinancialRegisterContainer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    width: 99%;
  }
`

/* ------------------Table-------------------- */



export const StyledTableContainer = styled.div`
  .rt-th, .rt-td {
    padding: 0px;
  }
`;
