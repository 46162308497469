import api from 'v2/services/api';

export const getSection = async (sectionId, companyId) => {
  const section = 'section/' + sectionId;
  try {
    const res = await api.get(section, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const listSection = async (params) => {
  try {
    const res = await api.get('section', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createSection = async (data) => {
  try {
    const res = await api.post('section', data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateSection = async (id, data) => {
  try {
    const res = await api.put('section/' + id, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteSection = async (id) => {
  try {
    await api.delete('section/' + id);
  } catch (err) {
    throw err;
  }
};

export const getSectionByDepartmentId = async (departmentId, companyId) => {
  try {
    const res = await api.get('section/department/' + departmentId, {
      params: {
        companyId,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};
