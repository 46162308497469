import React from 'react'
import { isDefaultCustomer } from 'utils/isDefaultCustomer'

const Customer = ({ customer }) => {
  const customerAddress = customer.Address[0]

  return (
    <article className='customer-content'>
      <div className='customer-data'>
        {
          isDefaultCustomer(customer)
            ? <span><strong>Cliente: Cliente Consumidor</strong></span>
            : <span><strong>Cliente: {customer.Company_Name}</strong></span>
        }
        {
          customerAddress && (
            <>
              <span>Endereço: {customerAddress.Address}, nº {customerAddress.Address_Number}</span>
              <span>Bairro: {customerAddress.Neighborhood}</span>
              <span>Cidade: {customerAddress.City} / {customerAddress.State}</span>
            </>
          )
        }
      </div>
    </article>
  )
}

export default Customer