import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

import {
  currency,
  percentage,
} from '../../../client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const ItemLabel = ({ label, value, width }) => (
  <View style={{ display: 'flex', flexDirection: 'row', width: width }}>
    <Text style={{ fontWeight: 'bold' }}>{label}: </Text>
    <Text>{value}</Text>
  </View>
);

const SaleTable = ({ data, type, total }) => {
  return (
    <View>
      {data.map((sale) => (
        <View key={sale.saleId}>
          <View
            style={{
              flexDirection: 'row',
              paddingTop: '3px',
              paddingBottom: '3px',
              width: '100%',
            }}
          >
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Data Criação: </Text>
                </Text>
                {format(new Date(sale.createdAt), 'dd/MM/yyyy')}
              </Text>
            </View>
            <View style={{ width: '25%', marginLeft: '5px' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>
                    Data de{' '}
                    {type === 'canceled' ? 'Cancelamento' : 'Fechamento'}:{' '}
                  </Text>
                </Text>
                {type === 'open'
                  ? ''
                  : format(new Date(sale.updatedAt), 'dd/MM/yyyy')}
              </Text>
            </View>
            <View style={{ width: '15%', marginLeft: '17px' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Tipo: </Text>
                </Text>
                {sale.type}{' '}
              </Text>
            </View>
            <View style={{ width: '12%' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Número: </Text>
                </Text>
                {sale.code}
              </Text>
            </View>
            <View style={{ width: '15%' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Valor: </Text>
                </Text>
                {currency(sale.valueTotal)}
              </Text>
            </View>
            <View style={{ width: '10%' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Frete: </Text>
                </Text>
                {currency(sale.freightValue)}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              paddingTop: '3px',
              paddingBottom: '3px',
              width: '100%',
            }}
          >
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Cliente: </Text>
                </Text>
                {sale.name}
              </Text>
            </View>
            <View style={{ width: '26.5%', marginLeft: '5px' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Veículo: </Text>
                </Text>
                {sale.plate === 'Não informado'
                  ? sale.plate
                  : `${sale.plate} - ${sale.modelVehicle}`}
              </Text>
            </View>
            <View style={{ width: '25%', marginLeft: '5px' }}>
              <Text style={styles.text}>
                <Text style={styles.titleTexts}>
                  <Text style={{ fontWeight: 'bold' }}>Vendedor: </Text>
                </Text>
                {sale.nameSeller}
              </Text>
            </View>
          </View>

          <View style={styles.line} />

          <View style={styles.containerRow}>
            <Text style={[styles.boldText, { width: '10%' }]}>Código</Text>
            <Text style={[styles.boldText, { width: '25%' }]}>Descrição</Text>
            <Text
              style={[styles.boldText, { width: '14%', textAlign: 'center' }]}
            >
              Quantidade
            </Text>
            <Text style={[styles.boldText, { width: '10%' }]}>Preço Unit</Text>
            <Text style={[styles.boldText, { width: '10%' }]}>Margem</Text>
            <Text style={[styles.boldText, { width: '10%' }]}>Desc. Unit.</Text>
            <Text style={[styles.boldText, { width: '10%' }]}>Total Venda</Text>
          </View>

          <View style={styles.line} />

          {sale.items.map((item) => (
            <View style={styles.containerRow}>
              <Text style={[styles.text, { width: '10%' }]}>{item.code}</Text>
              <Text style={[styles.text, { width: '25%' }]}>
                {item.description}
              </Text>
              <Text
                style={[styles.text, { width: '14%', textAlign: 'center' }]}
              >
                {item.quantity}
              </Text>
              <Text style={[styles.text, { width: '10%' }]}>
                {currency(item.unitValue)}
              </Text>
              <Text style={[styles.text, { width: '10%' }]}>
                {percentage(item.margin)}
              </Text>
              <Text style={[styles.text, { width: '10%' }]}>
                {currency(item.discountUnit)}
              </Text>
              <Text style={[styles.text, { width: '10%' }]}>
                {currency(item.amount)}
              </Text>
            </View>
          ))}

          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <View style={[styles.line, { width: '81%' }]} />
            <View style={{ width: '80%', marginTop: '2px' }}>
              {sale.payments?.length > 0 ? (
                <View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '35%',
                      }}
                    >
                      <Text style={[styles.strong, { fontSize: '8px' }]}>
                        <Text style={{ fontWeight: 'bold' }}>
                          Condição de Pagamento:{' '}
                        </Text>
                      </Text>
                      <Text style={{ fontSize: '8px' }}>
                        {sale.conditionDescription}
                      </Text>
                    </View>
                    <View style={{ width: '16%', alignItems: 'center' }}>
                      <Text style={[styles.strong, , { fontSize: '8px' }]}>
                        <Text style={{ fontWeight: 'bold' }}>Vencimento</Text>
                      </Text>
                    </View>
                    <View style={{ width: '23%', alignItems: 'center' }}>
                      <Text style={[styles.strong, , { fontSize: '8px' }]}>
                        <Text style={{ fontWeight: 'bold' }}>
                          Forma de Pagamento
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '18%',
                        alignItems: 'flex-start',
                        paddingLeft: '10px',
                      }}
                    >
                      <Text style={[styles.strong, , { fontSize: '8px' }]}>
                        <Text style={{ fontWeight: 'bold' }}>Valor</Text>
                      </Text>
                    </View>
                    <View style={{ width: '3%', alignItems: 'right' }}>
                      <Text style={[styles.strong, , { fontSize: '8px' }]}>
                        <Text style={{ fontWeight: 'bold' }}>Pago?</Text>
                      </Text>
                    </View>
                  </View>

                  {sale?.payments?.map((payment) => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                        key={payment.codeBill}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '35%',
                          }}
                        />
                        <View style={{ width: '16%', alignItems: 'center' }}>
                          <Text style={{ fontSize: '8px' }}>
                            {format(
                              getDateOnlyFromDate(new Date(payment?.dueDate)),
                              'dd/MM/yyyy'
                            )}
                          </Text>
                        </View>
                        <View style={{ width: '23%', alignItems: 'center' }}>
                          <Text
                            style={[{ fontSize: '8px', textAlign: 'center' }]}
                          >
                            {payment?.formPayment}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: '18%',
                            alignItems: 'flex-start',
                            paddingLeft: '10px',
                          }}
                        >
                          <Text style={[{ fontSize: '8px' }]}>
                            {currency(payment.amount)}
                          </Text>
                        </View>
                        <View style={{ width: '3%', alignItems: 'right' }}>
                          <Text style={{ fontSize: '8px', textAlign: 'right' }}>
                            {payment.statusId === 2 ? 'Sim' : 'Não'}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              ) : (
                <Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  Não há pagamentos informados
                </Text>
              )}
            </View>
          </View>
          <View style={[styles.line]} />
        </View>
      ))}

      <View
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          marginTop: '4px',
          marginBottom: '4px',
        }}
      >
        <Text style={[styles.boldText]}>Total: {currency(total)}</Text>
      </View>
    </View>
  );
};

const TotalsByStatus = ({ data }) => {
  return (
    <View>
      <View style={styles.containerRow}>
        <Text style={[styles.boldText, { width: '16%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '15%' }]}>Status</Text>
        <Text style={[styles.boldText, { width: '18%', textAlign: 'center' }]}>
          Atendimento
        </Text>
        <Text style={[styles.boldText, { width: '14%', textAlign: 'center' }]}>
          Quantidade
        </Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Total</Text>
      </View>

      <View style={styles.line} />

      {data.map((totalStatus, index) => (
        <View style={styles.containerRow} key={index}>
          <View style={{ width: '16%' }}>
            <Text style={styles.text}>{totalStatus.type}</Text>
          </View>
          <View style={{ width: '15%' }}>
            <Text style={styles.text}>{totalStatus.status}</Text>
          </View>
          <View style={{ width: '18%', textAlign: 'center' }}>
            <Text style={styles.text}>{totalStatus.attendance}</Text>
          </View>
          <View style={{ width: '14%', textAlign: 'center' }}>
            <Text style={styles.text}>{totalStatus.quantity}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(totalStatus.total)}</Text>
          </View>
        </View>
      ))}

      <View style={styles.line} />
    </View>
  );
};

export default function SalesByAttendanceAnalyticalPDF({
  initialDate,
  finalDate,
  reportData,
  seller,
}) {
  return (
    <Document title="listagem-de-clientes">
      <Page orientation="landscape" style={styles.page}>
        <View>
          <Text style={styles.title}>Vendas por Atendimento - Analítico</Text>
        </View>

        <View style={styles.containerRow}>
          <ItemLabel
            label="Data Inicial"
            value={format(new Date(initialDate), 'dd/MM/yyyy')}
            width="15%"
          />
          <ItemLabel
            label="Data Final"
            value={format(new Date(finalDate), 'dd/MM/yyyy')}
          />
        </View>

        <View style={styles.line} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Totais por Tipo/Status
          </Text>
        </View>

        <View style={styles.line} />

        <TotalsByStatus data={reportData.totalStatus} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Atendimentos Internos
          </Text>
        </View>

        {reportData.attendanceInternal.attendanceInternalFinished.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Finalizadas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="finished"
              data={reportData.attendanceInternal.attendanceInternalFinished}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Finalizado(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceInternal.attendanceInternalOpen.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Abertas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceInternal.attendanceInternalOpen}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Em Aberto(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceInternal.attendanceInternalCanceled.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Canceladas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceInternal.attendanceInternalCanceled}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Cancelado(a)' &&
                    item.attendance === 'Interno'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        <View style={styles.line} />

        <View>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Atendimentos Externos
          </Text>
        </View>

        {reportData.attendanceExternal.attendanceExternalFinished.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Finalizadas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="finished"
              data={reportData.attendanceExternal.attendanceExternalFinished}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Finalizado(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceExternal.attendanceExternalOpen.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Abertas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceExternal.attendanceExternalOpen}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Em Aberto(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}

        {reportData.attendanceExternal.attendanceExternalCanceled.length > 0 ? (
          <View>
            <View style={styles.line} />

            <View>
              <Text
                style={[
                  styles.title,
                  { textAlign: 'center', fontSize: '14px' },
                ]}
              >
                Canceladas
              </Text>
            </View>

            <View style={styles.line} />

            <SaleTable
              type="open"
              data={reportData.attendanceExternal.attendanceExternalCanceled}
              total={reportData.totalStatus
                ?.filter(
                  (item) =>
                    item.status === 'Cancelado(a)' &&
                    item.attendance === 'Externo'
                )
                ?.reduce((prev, curr) => prev + Number(curr.total), 0)}
            />
          </View>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'left',
  },
  bold: {
    fontWeight: 'bold',
  },
});
