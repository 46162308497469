import api from '../services/api'

const index = async (params) => {
  const { data } = await api.get(`customer-credit`, { params })
  return data
}

const show = async (id) => {
  const { data } = await api.get(`customer-credit/${id}`)
  return data
}

const create = async (params) => {
  const { data } = await api.post(`customer-credit`, params)
  return data
}

export default {
  index,
  show,
  create,
}
