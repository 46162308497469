import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currentBranding } from 'v2/helpers/brandingHelpers';
import { SidebarSubMenuContainer } from '../SidebarSubMenu/SidebarSubMenu.styles';

{
  /*TODO947: FONT COLOR BY WHITE LABEL */
}

const getActiveItemColor = () => {
  {
    /*TODO947: WHITE LABEL COLORS*/
  }
  switch (currentBranding) {
    case 'OSD':
      return 'midnightGreen';
    case 'WM':
      return 'japaneseIndigo200';
  }
};

export const SidebarItemStyled = styled.li`
  border: 0;
  background-color: transparent;

  cursor: pointer;
  padding: 3px 1.65px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  &:hover,
  &:hover svg {
    color: ${(props) =>
      !props.disabledStyle
        ? props.theme.colors.white
        : props.theme.colors.gray100};

  &:hover ${SidebarSubMenuContainer} {
    display: block;
  }

  ${(props) =>
    props.active
      ? `background-color: ${props.theme.colors[getActiveItemColor()]};`
      : ''}
`;

export const SidebarItemIcon = styled(FontAwesomeIcon)`
  color: #bbd5de;
  font-size: 24px;

  ${(props) =>
    props.disabledStyle
      ? `
     color: ${props.theme.colors.gray100} !important;
  `
      : ''}
`;

export const SidebarItemDescription = styled.span`
  text-align: center;
  line-height: ${(props) => props.theme.lineHeights.shorter};
  font-size: 9px;
  color: #bbd5de;

  ${(props) =>
    props.disabledStyle
      ? `
     color: ${props.theme.colors.gray100};
  `
      : ''}
`;
