import React from 'react'
import CurrencyInput from 'react-currency-input';

export default function Percent(props) {
  const formatValue = e => {
    e.target.value = e.target.value.toString().replace(',', '.').replace('%', '')

    props.onChangeEvent(e)
  }

  return(
    <CurrencyInput 
      {...props}
      suffix='%'
      decimalSeparator=","
      onChangeEvent={formatValue}
    />
  )
}