import React, { useEffect, useState } from 'react';
import { change, Field } from 'redux-form';
import { Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import CustomerRepository from '../../../../repositories/Customers';
import SelectCustomers from 'v2/client/components/SelectCustomers';

import './styles.css';

import {
  cnpjMask,
  cpfMask,
  chaveDeAcessoMask,
} from 'client/components/ToNormalize/ToNormalize';
import { NFeAccessKeyMask } from '../../../../utils/masks';
import {
  INVOICE_FINALITY,
  INVOICE_STATUS_DESCRIPTION,
} from '../../../../utils/constants';

import providersRepository from '../../../../repositories/Providers';
import nfesRepository from 'repositories/NFes';

import RenderField from 'components/RenderField';
import FormClient from '../../Clients/NewClient/FormClient';
import FormProvider from '../../Providers/NewProvider/FormNewProvider';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { InvoiceErrorSolutionModal } from 'v2/client/components/InvoiceErrorSolutionModal';
import AlertModal from 'components/AlertModal/AlertModal';
import { ViewNFeSales } from './components/ViewNFeSales';

const defaultSelectStyles = {
  control: (base) => ({
    ...base,
    height: '35px',
    minHeight: '35px',
    marginTop: '1.5px',
    borderColor: '#E3E3E3',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: '-7px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: '-7px',
  }),
};

const MainData = ({ setLoading }) => {
  const [CFOPsFiltered, setCFOPsFiltered] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectProviders, setSelectProviders] = useState([]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
  const [isStructureValid, setIsStructureValid] = useState(true);
  const [type, setType] = useState('customer');

  const [reloadCustomerOptionsTrigger, setReloadCustomerOptionsTrigger] =
    useState(false);

  const {
    saleCode,
    customerId,
    providerId,
    customerName,
    providerName,
    finality,
    status,
    providerUF,
    customerUF,
    companyUF,
    isInvoiceDenied,
    isInvoiceOpenedWithErrors,
    isInvoiceIssued,
    isInvoiceCanceled,
    isInvoiceIssuedInContingency,
    selectedCustomer,
    situationCode,
    CFOPs,
    defaultType,
    invoiceError,
    manySale,
    NFCeCodes,
  } = useSelector((state) => state.form.NFe.values);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const showErrorLink = !!invoiceError;

  const [openErrorSolutionModal, setOpenErrorSolutionModal] = useState(false);
  const [isOpenViewSalesNFeModal, setIsOpenViewSalesNFeModal] = useState(false);

  const dispatch = useDispatch();
  const isDevolution = finality === INVOICE_FINALITY.DEVOLUTION;

  const isOpen = status === INVOICE_STATUS_DESCRIPTION.OPEN;
  const isOpenOrOpenWithErros =
    status === INVOICE_STATUS_DESCRIPTION.OPEN ||
    status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS;

  useEffect(() => {
    setIsStructureValid(true);
  }, [type]);

  useEffect(() => {
    setType(defaultType);
  }, [defaultType]);

  useEffect(() => {
    loadProviders();
  }, []);

  useEffect(() => {
    if (providerId) {
      setType('provider');
    } else {
      setType('customer');
    }
  }, []);

  useEffect(() => {
    handleCFOPsFilter();
  });

  function handleCFOPsFilter() {
    const cfopMap = {};

    for (const item of CFOPs) {
      const { CFOP, companyId } = item;

      if (!cfopMap[CFOP]) {
        cfopMap[CFOP] = item;
      } else if (companyId) {
        cfopMap[CFOP] = item;
      }
    }

    setCFOPsFiltered(Object.values(cfopMap));
  }

  useEffect(() => {
    if (isDevolution) {
      dispatch(
        change(
          'NFe',
          'isEqualUF',
          companyUF === providerUF || companyUF === customerUF
        )
      );
    } else {
      dispatch(
        change(
          'NFe',
          'isEqualUF',
          companyUF === providerUF || companyUF === customerUF
        )
      );
    }
  }, [companyUF, customerUF, providerUF, finality]);

  const validateStructure = async (id, type) => {
    if (!id || !type) return;

    const { data: isStructureValid } = await nfesRepository.validateStructure(
      id,
      type
    );

    setIsStructureValid(isStructureValid);
  };

  async function loadProviders() {
    try {
      const providers = await providersRepository.getAllByCompany(companyId);

      const selectProviders = providers.map((provider) => ({
        value: provider.id,
        label: `${provider.companyName} -
            ${
              provider.type === 'Juridica'
                ? cnpjMask(provider.cpfCnpj)
                : cpfMask(provider.cpfCnpj)
            }`,
      }));

      setProviders(providers);
      setSelectProviders(selectProviders);

      if (providerId) {
        setProvider(providerId, providers);
      }
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os fornecedores. Por favor, tente novamente'
      );
    }
  }

  function setProvider(id, items) {
    let provider;

    provider = items.find((provider) => provider.id === id);

    if (provider) {
      dispatch([
        change('NFe', 'providerId', provider.id),
        change('NFe', 'providerName', provider.companyName),
        change('NFe', 'providerIE', provider.IE),
        change('NFe', 'providerCpfCnpj', cnpjMask(provider.cpfCnpj)),
        change('NFe', 'providerUF', provider.Address[0]?.State),
        change('NFe', 'customerId', null),
        change('NFe', 'customerName', null),
        change('NFe', 'customerIE', null),
        change('NFe', 'customerCpfCnpj', null),
        change('NFe', 'customerUF', null),
      ]);
    }

    validateStructure(id, type);
  }

  function handleRemoveClient() {
    dispatch([
      change('NFe', 'customerId', null),
      change('NFe', 'providerId', null),
      change('NFe', 'customerName', null),
      change('NFe', 'providerName', null),
      change('NFe', 'searchClient', null),
      change('NFe', 'customerIE', null),
      change('NFe', 'customerUF', null),
      change('NFe', 'providerUF', null),
      change('NFe', 'providerIE', null),
      change('NFe', 'customerCpfCnpj', null),
      change('NFe', 'providerCpfCnpj', null),
    ]);
  }

  async function handleSubmitCustomerModal(cpfCnpj, id, companyName) {
    const customersList = await indexCustomersWithVehicles({
      company_id: companyId,
      page: 1,
      limit: 10,
      query: companyName,
      show_label: false,
      show_vehicles: false,
    });

    const currentCustomer = customersList.rows.find(
      (item) => item.customer_id === id
    );

    const currentCustomerLabel = currentCustomer
      ? `${currentCustomer.customer_name} ${
          currentCustomer.customer_cpfcnpj &&
          `- ${cpfOrCnpjMask(currentCustomer.customer_cpfcnpj)}`
        }`
      : '';

    setReloadCustomerOptionsTrigger(true);
    handleChangeCustomer({
      value: { ...currentCustomer, label: currentCustomerLabel },
      label: currentCustomerLabel,
    });
    setIsCustomerModalOpen(false);
  }

  function handleSubmitProviderModal(provider) {
    setProviders([]);
    setIsProviderModalOpen(false);
    handleRemoveClient();
    loadProviders(provider.id);
  }

  function handleOpenClientModal() {
    if (isInvoiceProcessed) return;

    if (!!customerId) {
      return setIsCustomerModalOpen(true);
    } else if (!!providerId) {
      return setIsProviderModalOpen(true);
    }
  }

  const handleChangeFinality = () => {
    dispatch([
      change('NFe', 'presenceIndicator', '1'),
      change('NFe', 'intermediaryId', ''),
    ]);
  };

  const handleChangeCustomer = async (customer) => {
    const data = await CustomerRepository.getCustomer(
      customer.value.customer_id
    );

    dispatch([
      change('NFe', 'customerId', customer.value.customer_id),
      change('NFe', 'customerName', customer.value.customer_name),
      change('NFe', 'selectedCustomer', customer.value),
      change('NFe', 'customerIE', customer.value.customer_ie),
      change(
        'NFe',
        'customerCpfCnpj',
        customer.value.customer_type === 'Juridica'
          ? cnpjMask(customer.value.customer_cpfcnpj)
          : cpfMask(customer.value.customer_cpfcnpj)
      ),
      change('NFe', 'customerUF', data.Address[0]?.State),
      change('NFe', 'providerId', null),
      change('NFe', 'providerName', null),
      change('NFe', 'providerIE', null),
      change('NFe', 'providerCpfCnpj', null),
      change('NFe', 'providerUF', null),
    ]);

    validateStructure(customer.value.customer_id, type);
  };

  useEffect(() => {
    if (providerId) {
      setType('provider');
    } else {
      setType('customer');
    }
  }, []);

  const isInvoiceNotProcessed =
    !isInvoiceDenied &&
    !isInvoiceCanceled &&
    !isInvoiceIssuedInContingency &&
    !isInvoiceIssued;
  const isInvoiceProcessed =
    isInvoiceDenied ||
    isInvoiceIssuedInContingency ||
    isInvoiceIssued ||
    isInvoiceCanceled;

  const handleChangeIdCFOP = (e) => {
    let selectedCFOP = CFOPs.find((cfop) => cfop.CFOP === e.target.value);
    selectedCFOP = !!selectedCFOP
      ? selectedCFOP
      : CFOPs.find((cfop) => cfop.id === parseInt(e.target.value));
    const selectedCFOPId = selectedCFOP ? selectedCFOP.id : '';
    const isCalculateICMSWithOtherExpenses = selectedCFOP
      ? selectedCFOP.calculateICMSWithOtherExpenses
      : '';

    dispatch([
      change('NFe', 'idCFOP', selectedCFOPId),
      change(
        'NFe',
        'isCalculateICMSWithOtherExpenses',
        isCalculateICMSWithOtherExpenses
      ),
    ]);
  };

  return (
    <div id="NFe-main-data">
      <div>
        <Col
          xs={12}
          sm={12}
          md={1}
          lg={1}
          style={{ width: '120px', padding: '2px 2px' }}
        >
          <Field
            name="finality"
            component={RenderField}
            label="Finalidade:"
            as="select"
            disabled={
              (saleCode && !isOpenOrOpenWithErros) ||
              saleCode ||
              isInvoiceProcessed
            }
            onChange={handleChangeFinality}
          >
            <option value={INVOICE_FINALITY.NORMAL}>Normal</option>
            <option value={INVOICE_FINALITY.DEVOLUTION}>Devolução</option>
          </Field>
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} style={{ padding: '2px 2px' }}>
          <Field name="code" component={RenderField} label="Nota:" disabled />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} style={{ padding: '2px 2px' }}>
          <Field name="serie" component={RenderField} label="Série:" disabled />
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} style={{ padding: '2px 2px' }}>
          <Field
            name="status"
            component={RenderField}
            label={
              <span
                style={{ paddingLeft: '10px' }}
                className="flex row align-end between"
              >
                <span>
                  <strong>Status da NF-e</strong>
                </span>
                {showErrorLink && (
                  <a
                    href="#"
                    style={{ paddingLeft: '5px', color: '#428bca' }}
                    onClick={() => setOpenErrorSolutionModal(true)}
                  >
                    (Como resolver o erro?)
                  </a>
                )}
              </span>
            }
            disabled
            style={{
              color: isInvoiceIssued
                ? 'green'
                : isInvoiceOpenedWithErrors
                ? 'red'
                : '#888888',
            }}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ width: '450px', padding: '2px 2px' }}
        >
          <Field
            name="accessKey"
            component={RenderField}
            label="Chave de Acesso:"
            disabled
            {...NFeAccessKeyMask}
            normalize={chaveDeAcessoMask}
          />
        </Col>
      </div>
      <div>
        <Col xs={12} sm={12} md={5} lg={5} style={{ padding: '2px 2px' }}>
          <Field
            name="CFOP"
            component={RenderField}
            label="Natureza de Operação:"
            as="select"
            required
            disabled={isInvoiceProcessed}
            onChange={handleChangeIdCFOP}
          >
            <option value="">Selecione</option>
            {CFOPsFiltered.map((CFOP) => (
              <option key={CFOP.id} value={CFOP.CFOP}>
                {CFOP.CFOP} - {CFOP.description}
              </option>
            ))}
          </Field>
        </Col>
        {!manySale?.length && (
          <Col xs={12} sm={12} md={1} lg={1} style={{ padding: '2px 2px' }}>
            <Field
              name="NFCeCode"
              component={RenderField}
              label="NFC-e:"
              disabled
            />
          </Col>
        )}
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="date-field"
          style={{ padding: '2px 2px' }}
        >
          <Field
            name="date"
            component={RenderField}
            label="Data de Criação:"
            type="date"
            disabled={!isOpen}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className="date-time-field"
          style={{ padding: '2px 2px' }}
        >
          <Field
            name="issuedAt"
            component={RenderField}
            label="Data de Emissão:"
            type="datetime-local"
            disabled
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={!manySale?.length ? 1 : 2}
          lg={!manySale?.length ? 1 : 2}
          style={{ padding: '2px 2px' }}
        >
          <div>
            <Field
              component={RenderField}
              label="Venda:"
              name={manySale?.length ? 'salesIds' : 'saleCode'}
              readOnly
              disabled
            />
            {manySale?.length ? (
              <span
                className="field-hiperlink"
                onClick={() => setIsOpenViewSalesNFeModal(true)}
              >
                Visualizar Vendas
              </span>
            ) : null}
          </div>
        </Col>
      </div>
      <div>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ position: 'relative', padding: '2px 2px' }}
        >
          <span
            className="field-hiperlink"
            onClick={handleOpenClientModal}
            style={{
              cursor:
                (!!providerId ||
                  !!customerId ||
                  (saleCode && !isOpenOrOpenWithErros) ||
                  saleCode) &&
                isInvoiceNotProcessed
                  ? 'pointer'
                  : 'not-allowed',
            }}
          >
            {isDevolution
              ? 'Visualizar Dados'
              : `Visualizar ${type === 'customer' ? 'Cliente' : 'Fornecedor'}`}
          </span>

          <>
            <div style={{ display: 'flex' }}>
              <div
                style={{ display: 'flex', marginRight: '30px' }}
                className="custom-control custom-radio custom-control-inline"
              >
                <span
                  onChange={() => {
                    setType('customer');
                    handleRemoveClient();
                  }}
                  style={{
                    paddingTop: '12px',
                    paddingLeft: '10px',
                    cursor: 'pointer',
                    marginRight: '5px',
                  }}
                >
                  Cliente
                </span>
                <input
                  type="radio"
                  name="printing-sheet-type"
                  disabled={
                    isInvoiceProcessed ||
                    (saleCode && !isOpenOrOpenWithErros) ||
                    saleCode
                  }
                  onChange={() => {
                    setType('customer');
                    handleRemoveClient();
                  }}
                  style={{ backgroundColor: 'red', color: 'green' }}
                  checked={type === 'customer'}
                ></input>
              </div>

              <div style={{ display: 'flex' }}>
                <span
                  onChange={() => {
                    setType('provider');
                    handleRemoveClient();
                  }}
                  style={{
                    paddingTop: '12px',
                    paddingLeft: '10px',
                    cursor: 'pointer',
                    marginRight: '5px',
                  }}
                >
                  Fornecedor
                </span>
                <input
                  checked={type === 'provider'}
                  type="radio"
                  name="printing-sheet-type"
                  disabled={
                    isInvoiceProcessed ||
                    (saleCode && !isOpenOrOpenWithErros) ||
                    saleCode
                  }
                  onChange={() => {
                    setType('provider');
                    handleRemoveClient();
                  }}
                ></input>
              </div>
            </div>

            {type === 'customer' ? (
              <SelectCustomers
                disabled={
                  isInvoiceProcessed ||
                  (saleCode && !isOpenOrOpenWithErros) ||
                  saleCode
                }
                placeholder={`Pesquisar cliente`}
                showDefaultLabel={false}
                showVehicles={false}
                value={selectedCustomer}
                setReloadTrigger={setReloadCustomerOptionsTrigger}
                reloadTrigger={reloadCustomerOptionsTrigger}
                onChange={(customer) => {
                  handleChangeCustomer(customer);
                }}
                styles={defaultSelectStyles}
                status={'Ativo'}
              />
            ) : (
              <Select
                isSearchable
                options={selectProviders}
                placeholder={`Pesquisar fornecedor`}
                onChange={(option) => setProvider(option.value, providers)}
                value={
                  providerId ? { value: providerId, label: providerName } : null
                }
                noOptionsMessage={() => `Nenhum fornecedor encontrado`}
                isDisabled={
                  isInvoiceProcessed ||
                  (saleCode && !isOpenOrOpenWithErros) ||
                  saleCode
                }
                styles={defaultSelectStyles}
              />
            )}
            {!isStructureValid && (
              <p
                style={{
                  fontSize: '12px',
                  color: '#d9534f',
                  textAlign: 'end',
                }}
              >
                O cadastro do {type === 'customer' ? 'cliente ' : 'fornecedor '}
                pode estar incompleto. Verifique antes de emitir a nota.
              </p>
            )}
          </>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          style={{ width: '155px', padding: '2px 2px' }}
        >
          <Field
            name={!providerId ? 'customerCpfCnpj' : 'providerCpfCnpj'}
            component={RenderField}
            label="CNPJ/CPF:"
            disabled
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          style={{ width: '160px', padding: '2px 2px' }}
        >
          <Field
            name={!providerId ? 'customerIE' : 'providerIE'}
            component={RenderField}
            label="Inscrição Estadual:"
            disabled
          />
        </Col>
        {isDevolution && (
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            style={{ width: '450px', padding: '2px 2px' }}
          >
            <Field
              name="referenceAccessKey"
              component={RenderField}
              label="Chave de Acesso da NF-e"
              required
              {...NFeAccessKeyMask}
              disabled={isInvoiceProcessed}
            />
          </Col>
        )}
      </div>
      <Modal
        dialogClassName="modal-formProduto"
        show={isCustomerModalOpen}
        onHide={() => setIsCustomerModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Dados do Cliente</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'scroll' }}>
          <FormClient
            modal={true}
            hideModal={() => setIsCustomerModalOpen(false)}
            handleModal={handleSubmitCustomerModal}
            dispatch={dispatch}
            ClientId={customerId}
          />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="modal-formProduto"
        show={isProviderModalOpen}
        onHide={() => setIsProviderModalOpen(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Dados do Fornecedor</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'scroll' }}>
          <FormProvider
            providerId={providerId}
            onCancel={() => setIsProviderModalOpen(false)}
            onSubmit={handleSubmitProviderModal}
          />
        </Modal.Body>
      </Modal>
      {openErrorSolutionModal && (
        <InvoiceErrorSolutionModal
          open={true}
          onClose={() => setOpenErrorSolutionModal(false)}
          situationCode={situationCode}
          errorInformation={invoiceError}
        />
      )}
      <AlertModal
        dialogClassName="modal-30w"
        show={isOpenViewSalesNFeModal}
        title="Vendas Vinculadas"
        message={<ViewNFeSales sales={manySale} NFCeCodes={NFCeCodes} />}
        onCancel={() => setIsOpenViewSalesNFeModal(false)}
        onHide={() => setIsOpenViewSalesNFeModal(false)}
        isNotConfirmation
        onCancelLabel="Voltar"
      />
    </div>
  );
};

export default MainData;
