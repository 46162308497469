import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowLoopLeft } from '@fortawesome/free-solid-svg-icons';

import CustomersThatReturnedModal from './Modal';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import UnavailableFeatureModal from '../UnavailableFeatureModal';

const StyledButton = styled.button`
  height: 35px;
  background-color: #1565c0;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 4px;
  transition: background-color 0.5s;
  width: 200px;
  font-size: 12px;

  &:hover {
    background-color: #0f4c91;
    transition: background-color 0.5s;
    cursor: pointer;
  }
`;

export default function CustomersThatReturnedButton({ children, ...rest }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [
    isFeatureAvailableForPrimeOnlyModalOpen,
    setIsFeatureAvailableForPrimeOnlyModalOpen,
  ] = useState(false);

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

  const handleOpenModal = () => {
    switch (true) {
      case isPlanPrime:
        return setIsModalOpen(true);
      case isPlanFree:
        return setIsFeatureAvailableForPrimeOnlyModalOpen(true);
      default:
        setIsUpgradePlanModalOpen(true);
    }
  };

  return (
    <div {...rest}>
      <StyledButton onClick={() => handleOpenModal()}>
        <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} />
        Clientes que Retornaram
      </StyledButton>

      {isModalOpen && (
        <CustomersThatReturnedModal handleClose={() => setIsModalOpen(false)} />
      )}

      {isUpgradePlanModalOpen && (
        <UnavailableFeatureModal
          show={isUpgradePlanModalOpen}
          onHide={() => setIsUpgradePlanModalOpen(false)}
        />
      )}

      {isFeatureAvailableForPrimeOnlyModalOpen && (
        <UnavailableFeatureModal
          show={isFeatureAvailableForPrimeOnlyModalOpen}
          onHide={() => setIsFeatureAvailableForPrimeOnlyModalOpen(false)}
          unavailableFeatureText={'Função disponível apenas para o plano prime'}
        />
      )}
    </div>
  );
}
