import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toastr } from 'react-redux-toastr';

import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertModal from 'components/AlertModal';

export const NewsRegisterImage = ({
  setImageFile,
  setValue,
  imageFile,
  watch,
}) => {
  const [isConfirmRemoveImageModalOpen, setIsConfirmRemoveImageModalOpen] =
    useState(false);
  const MAX_WIDTH = 690;
  const MAX_HEIGHT = 350;

  const imageUrl = watch('imageUrl');

  const { getRootProps, getInputProps, isDragReject, isDragAccept } =
    useDropzone({
      accept: 'image/jpeg',
      multiple: false,
      onDrop: ([imageFile]) => {
        if (!imageFile) return;

        const image = new Image();
        image.src = URL.createObjectURL(imageFile);
        image.onload = () => {
          if (image.height > MAX_HEIGHT || image.width > MAX_WIDTH) {
            return toastr.warning(
              'O tamanho máximo da imagem é 690x350px. Verifique a imagem e tente novamente.'
            );
          }

          if (image.height < 200 || image.width < 200) {
            return toastr.warning(
              'O tamanho mínimo da imagem é 200x200px. Verifique a imagem e tente novamente.'
            );
          }

          const archive = Object.assign(imageFile, {
            preview: URL.createObjectURL(imageFile),
          });

          if (imageUrl) {
            const url = imageUrl;
            setValue('imageUrlToRemove', url);
            setValue('imageUrl', url);
          }

          setImageFile(archive);
        };
      },
    });

  function renderImageContainerMessage() {
    if (isDragReject) {
      return 'Arquivo não compatível !';
    }
    if (isDragAccept) {
      return 'Solte a imagem aqui';
    }
    return 'Adicionar Imagem';
  }

  function handleRemoveImageFile() {
    if (imageFile) {
      URL.revokeObjectURL(imageFile.preview);
      setImageFile(null);
    } else if (imageUrl) {
      setImageFile(null);
      setValue('imageUrl', null);
    }
    setIsConfirmRemoveImageModalOpen(false);
  }

  return (
    <>
      {!imageFile && !imageUrl ? (
        <div {...getRootProps()} className="news-image-dropzone">
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faPlusCircle} />
          <span>{renderImageContainerMessage()}</span>
        </div>
      ) : (
        <div className="news-image-container">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setIsConfirmRemoveImageModalOpen(true)}
          />
          <img
            src={!imageFile ? imageUrl : imageFile.preview}
            alt="Imagem Propaganda"
          />
          <label htmlFor="news-image-input">Alterar</label>
          <input {...getInputProps()} id="news-image-input" />
        </div>
      )}

      <AlertModal
        show={isConfirmRemoveImageModalOpen}
        title="Exclusão de imagem"
        message="Deseja excluir a imagem? Ao excluir a imagem, uma nova imagem deverá ser importada posteriormente."
        onHide={() => setIsConfirmRemoveImageModalOpen(false)}
        onCancel={() => setIsConfirmRemoveImageModalOpen(false)}
        onSubmit={handleRemoveImageFile}
      />
    </>
  );
};
