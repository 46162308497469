import React, { useEffect, useState } from 'react';
import CollapsibleSection from 'components/CollapsibleSection';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import Toggle from 'react-toggle';
import { InputTextArea } from 'v2/components/Input';
import { DefaultContentContainer } from 'v2/components/layout';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { InputContainer } from 'v2/components/Input';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer } from './FormCorrection.styles';
import { formatToLettersOnly } from 'v2/helpers/normalizeString';
import { formSchema, getBreadcrumbData } from './utils';
import { toastr } from 'react-redux-toastr';
import { SearchInput } from 'v2/components/form';
import { ActiveStatusLabel } from 'v2/components/ActiveStatusLabel';
import { Table } from 'v2/components/Table';
import { AddDefectsModal } from './AddDefectsModal';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useDebounce from 'hooks/useDebounce';
import { findCorrectionById } from 'v2/repositories/CorrectionsRepository';
import {
  updateCorrection,
  createCorrection,
} from 'v2/repositories/CorrectionsRepository';

export function FormCorrection({
  hideBreadCrumb,
  hideActionButtons,
  defectIdProp,
  onSubmitSuccess,
  onSubmitFail,
  triggerSubmit,
}) {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const { id } = useParams();
  const isEditing = !!id;
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDefectsModalOpen, setIsAddDefectsModalOpen] = useState(false);
  const [isDefectsExpanded, setIsDefectsExpanded] = useState(true);
  const [isBasicDataExpanded, setIsBasicDataExpanded] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    register,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      id: id ? id : null,
      description: '',
      details: '',
      isActive: true,
    },
  });

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [linkedCorrections, setLinkedCorrections] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 600);

  const { isActive } = useWatch({
    control,
  });

  const handleSwitchModal = () => {
    setIsAddDefectsModalOpen(!isAddDefectsModalOpen);
  };

  const handleChangeActiveToggle = () => {
    setValue('isActive', !isActive);
  };

  const handleDefectsSectionState = () => {
    setIsDefectsExpanded(!isDefectsExpanded);
  };
  const handleBasicDataSectionState = () => {
    setIsBasicDataExpanded(!isBasicDataExpanded);
  };

  const handleChangeDefectInput = (e) => {
    const value = e.target.value;

    if (value.length > 50) return;
    setValue('description', value);
  };

  const handleAddDefects = async (selectedCorrections) => {
    const newCorrections = selectedCorrections.map((item) => {
      return item;
    });

    setLinkedCorrections([...linkedCorrections, ...newCorrections]);
    setRows([...rows, ...newCorrections]);
    return;
  };

  const handleDeleteItem = (itemId) => {
    setRows(rows.filter((row) => row.id !== itemId));
    setLinkedCorrections(
      linkedCorrections.filter((correction) => correction.id !== itemId)
    );
  };

  const formatCorrectionDetails = (correctionAssociated) => {
    if (!correctionAssociated || correctionAssociated.length === 0) {
      return 'Nenhuma correção associada';
    }

    const formattedDetails = `${correctionAssociated.details}`;
    return formattedDetails;
  };

  const handleErrors = () => {
    if (
      (errors.description &&
        errors.description.message === 'description is a required field') ||
      (errors.details &&
        errors.details.message === 'details is a required field')
    ) {
      toastr.warning(
        'Campos obrigatórios não preenchidos',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
    }
  };

  const handleCreate = async (data) => {
    setIsLoading(true);
    try {
      const { details, id, isActive, description } = data;
      const request = {
        details,
        id,
        status: isActive,
        description,
        companyId,
        defectsAssociated: linkedCorrections.map((correction) => correction.id),
      };
      const res = await createCorrection(request);

      if (onSubmitSuccess) {
        return onSubmitSuccess(res);
      }

      navigateToClientPage('corrections', 'base');
    } catch (err) {
      if (onSubmitFail) {
        onSubmitFail();
      }
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const { description, details, isActive } = data;
      const requestData = {
        description,
        details,
        status: isActive,
        companyId,
        defectsAssociated: linkedCorrections,
      };

      await updateCorrection(id, requestData);
      toastr.success('Edição realizada', 'Correção alterado com sucesso');
      navigateToClientPage('corrections', 'base');
    } catch (err) {
      if (onSubmitFail) {
        onSubmitFail();
      }
      console.log(err);
      toastr.error(
        'Erro na operação',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    return isEditing ? handleUpdate(data) : handleCreate(data);
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const response = await findCorrectionById(id, { companyId });
      const { description, details, status, correctionsAssociated } = response;
      setValue('description', description);
      setValue('details', details);
      setValue('isActive', status);
      setValue('linkedCorrections', correctionsAssociated);
      const newCorrections = linkedCorrections.map((correction) => {
        return correction;
      });
      setCount(response.correctionsAssociated.length);
      setRows([...response.correctionsAssociated, ...newCorrections]);
      setLinkedCorrections(response.correctionsAssociated);
      setPages(Math.ceil(response.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 300,
    },
    {
      Header: 'Detalhes das correções',
      accessor: 'linkedCorrections',
      Cell: (props) => <div>{formatCorrectionDetails(props.original)}</div>,
    },
    {
      Header: 'Status',
      accessor: 'isActive',
      width: 120,
      Cell: (props) => {
        return <ActiveStatusLabel isActive={props.original.status} />;
      },
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        return (
          <button
            title="Excluir"
            className="button-icon"
            onClick={() => handleDeleteItem(props.original.id)}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-red" />
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    handleErrors();
  }, [errors]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit(submitForm)();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (!!id) {
      handleFetch();
    }
  }, [companyId]);

  return (
    <DefaultContentContainer showRequiredFieldsLabel={!hideBreadCrumb}>
      {!hideBreadCrumb && <BreadCrumb {...getBreadcrumbData(isEditing)} />}

      <div className="content__main">
        <CollapsibleSection
          title="Dados da Correção"
          isExpanded={isBasicDataExpanded}
          handleExpand={() => handleBasicDataSectionState()}
        >
          <form method="POST">
            <FormContainer>
              <div className="row-1">
                <InputContainer>
                  <InputLabel htmlFor="input__description" required>
                    Descrição:
                  </InputLabel>
                  <InputText
                    id="input__description"
                    maxLength={50}
                    width="400"
                    style={{ fontSize: '12px' }}
                    {...register('description')}
                    onChange={handleChangeDefectInput}
                  />
                </InputContainer>
                <InputContainer variant="column" align="center">
                  <InputLabel htmlFor="input__is-active">Status:</InputLabel>
                  <div className="flex center" style={{ height: '40px' }}>
                    <Toggle
                      id="input__is-active"
                      checked={isActive}
                      onChange={() => handleChangeActiveToggle()}
                    />
                  </div>
                </InputContainer>
              </div>

              <div className="row-2">
                <InputContainer className="input__container">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <InputLabel htmlFor="input__observations" required>
                      Detalhes da Correção:
                    </InputLabel>
                    <span
                      style={{
                        color: '#D9534F',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      }}
                    >
                      Essa informação poderá ser exibida no impresso de
                      checklist-manual
                    </span>
                  </div>
                  <InputTextArea
                    id="input__observations"
                    rows={4}
                    maxLength={500}
                    height="70px"
                    style={{ fontSize: '12px' }}
                    {...register('details')}
                  />
                  <p className="flex end" style={{ fontSize: '12px' }}>
                    {watch('details')?.length}/500
                  </p>
                </InputContainer>
              </div>
            </FormContainer>
          </form>
        </CollapsibleSection>
        <br />
        <CollapsibleSection
          title="Defeitos Vinculados"
          isExpanded={isDefectsExpanded}
          handleExpand={() => handleDefectsSectionState()}
        >
          <div className="flex gap-1" style={{ width: '100%' }}>
            <div
              className="flex gap-1"
              style={{
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <button
                className="button button-green button-h35"
                onClick={() => setIsAddDefectsModalOpen(!isAddDefectsModalOpen)}
              >
                <span>Adicionar Defeitos</span>
              </button>
              <SearchInput
                id="input__query"
                placeholder="Pesquisa por Descrição ou Detalhes"
                width={300}
                onChange={(e) => debounceQueryInput(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <strong>
                Total: {linkedCorrections.length} Defeitos vinculados
              </strong>
            </div>
          </div>
          <div className="mt-2" style={{ width: '100%' }}>
            <Table
              columns={columns}
              data={rows}
              pageSize={limit}
              page={page}
              onPageChange={setPage}
              manual
              onPageSizeChange={setLimit}
              pages={pages}
              loading={isFetching}
            />
          </div>
        </CollapsibleSection>
      </div>
      {!hideActionButtons && (
        <div className="flex align-center end gap-075 mt-2">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={() => navigateToClientPage('corrections', 'base')}
            disabled={isLoading}
          >
            <span>Cancelar</span>
          </button>
          <button
            className="button button-green button-h35 flex center"
            type="submit"
            style={{ width: '100px' }}
            onClick={handleSubmit(submitForm)}
            disabled={isLoading}
          >
            <span className="flex center">
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse" />
              ) : (
                'Salvar'
              )}
            </span>
          </button>
        </div>
      )}
      {isAddDefectsModalOpen && (
        <AddDefectsModal
          handleClose={handleSwitchModal}
          handleSubmit={handleAddDefects}
        />
      )}
    </DefaultContentContainer>
  );
}
