import React from 'react';

import {
  faMoneyBillAlt,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

import { Step } from 'react-step-progress-bar';

import ProgressBar from './components/ProgressBar';
import GroupTitlesStep from './components/Step';

export function GroupTitlesStepWizard({ percent }) {
  return (
    <ProgressBar percent={percent}>
      <Step transition="scale">
        {({ accomplished }) => (
          <GroupTitlesStep
            accomplished={accomplished}
            icon={faUserFriends}
            title={'Clientes'}
          />
        )}
      </Step>

      <Step transition="scale">
        {({ accomplished }) => (
          <GroupTitlesStep
            accomplished={accomplished}
            icon={faMoneyBillAlt}
            title={'Títulos'}
          />
        )}
      </Step>
    </ProgressBar>
  );
}
