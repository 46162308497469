import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col } from 'react-bootstrap'
import { Field, change } from 'redux-form'
import { withRouter } from 'react-router'
import Button from 'client/components/CustomButton/CustomButton.jsx'
import constants from '../../../../../utils/constants'
import RenderField from 'components/RenderField'
import { createNumberMask } from 'redux-form-input-masks'

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
})

const Footer = ({
  history,
  handleSubmit,
  loading,
  handleCancel,
  disableInputs,
}) => {
  const {
    code,
    purchaseStatusId,
    BCICMS,
    BCICMSST,
    PISValue,
    freightValue,
    IPIValue,
    FCPValue,
    productsValue,
    total,
    ICMSValue,
    ICMSSTValue,
    COFINSValue,
    inssuranceValue,
    otherExpenses,
    FCPSTValue,
    discountValue,
    type,
  } = useSelector((state) => state.form.purchases.values)

  const dispatch = useDispatch()

  const isClosedOrCanceled =
    purchaseStatusId === constants.PURCHASES_STATUS.CLOSED ||
    purchaseStatusId === constants.PURCHASES_STATUS.CANCELED
  const isClosed = purchaseStatusId === constants.PURCHASES_STATUS.CLOSED
  const isCanceled = purchaseStatusId === constants.PURCHASES_STATUS.CANCELED
  const isOpened = purchaseStatusId === constants.PURCHASES_STATUS.OPEN

  function handleCalcPurchaseTotal() {
    const newTotal =
      productsValue +
      ICMSSTValue +
      PISValue +
      COFINSValue +
      freightValue +
      inssuranceValue +
      IPIValue +
      otherExpenses +
      FCPValue -
      discountValue

    dispatch(change('purchases', 'total', newTotal))
  }

  useEffect(() => {
    if (!disableInputs && type === 'Manual') {
      handleCalcPurchaseTotal()
    }
  }, [
    BCICMS,
    BCICMSST,
    PISValue,
    freightValue,
    IPIValue,
    FCPValue,
    productsValue,
    ICMSValue,
    ICMSSTValue,
    COFINSValue,
    inssuranceValue,
    otherExpenses,
    FCPSTValue,
    discountValue,
  ])

  return (
    <div id="purchases-footer">
      <header>
        <strong>Compra: </strong>
        <strong>{code}</strong>
      </header>
      <main style={{ display: 'block' }}>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="BC ICMS:"
            name="BCICMS"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="BC ICMS ST:"
            name="BCICMSST"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor PIS:"
            name="PISValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor Frete:"
            name="freightValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor IPI:"
            name="IPIValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor FCP:"
            name="FCPValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor Produtos:"
            name="productsValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor Total:"
            name="total"
            component={RenderField}
            disabled
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
            }}
            {...currencyMask}
          />
        </Col>

        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor ICMS:"
            name="ICMSValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor ICMS ST:"
            name="ICMSSTValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor COFINS:"
            name="COFINSValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor Seguro:"
            name="inssuranceValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} style={{ maxWidth: '118px' }}>
          <Field
            label="Outras Despesas:"
            name="otherExpenses"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Valor FCP ST:"
            name="FCPSTValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="number-field">
          <Field
            label="Descontos:"
            name="discountValue"
            component={RenderField}
            disabled={disableInputs}
            {...currencyMask}
          />
        </Col>
      </main>
      <footer>
        <Button
          bsStyle="info"
          fill
          onClick={handleSubmit}
          id={isCanceled ? 'btn-title' : ''}
          disabled={loading || isClosedOrCanceled}
        >
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          {isClosed ? (
            <span>Compra Finalizada</span>
          ) : isCanceled ? (
            <span>Compra Cancelada</span>
          ) : (
            <span>Salvar</span>
          )}
        </Button>
        <Button
          bsStyle="danger"
          fill
          onClick={() =>
            isOpened ? handleCancel() : history.push(constants.ROUTES.PURCHASES)
          }
          disabled={loading}
        >
          <span>{isOpened ? 'Cancelar' : isClosed ? 'Voltar' : 'Voltar'}</span>
        </Button>
      </footer>
    </div>
  )
}

export default withRouter(Footer)
