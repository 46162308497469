import addressRepository from 'repositories/Addresses';

export const getCompanyAddress = async (companyId) => {
  try {
    const { data: companyAddress } = await addressRepository.getCompanyAddress(
      companyId
    );
    return companyAddress.data[0];
  } catch (err) {
    console.log(err);
    throw err;
  }
};
