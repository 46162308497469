import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import DownloadXlsButton from 'components/DownloadXlsButton'

import '../styles.css'
import { format } from 'date-fns'
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize'

function SuccessReportFilter({
  companySuggestions,
  loading,
  loadReportData,
  reportData,
  setReportData,
}) {
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)

  const handleSelectedCompany = (company) => {
    setReportData([])
    setSelectedCompany(company.value)
    setCompanyInfo(companySuggestions.find((c) => c.id === company.value))

    console.log(
      companyInfo?.Signature.reduce((prev, current) => {
        return prev.updatedAt > current.updatedAt ? prev : current
      })
    )
  }

  return (
    <>
      <div
        className="success-report-filter-row"
        style={{ marginBottom: '15px', alignItems: 'center' }}
      >
        <div style={{ width: '400px' }}>
          <Select
            isSearchable
            filterOption={(company, input) => {
              return (
                company.data.cpfCnpj?.includes(input) ||
                company.data.tradingName?.includes(input) ||
                company.data.companyName?.includes(input) ||
                company.data.label.includes(input)
              )
            }}
            options={companySuggestions.map((company) => {
              return {
                value: company.id,
                label: `${company.Company_Name} / ${
                  company.Trading_Name
                } / ${cpfOrCnpjMask(company.Cpf_Cnpj)}`,
                companyName: company.Company_Name,
                companyTradingName: company.Trading_Name,
                cpfCnpj: company.Cpf_Cnpj,
              }
            })}
            onChange={handleSelectedCompany}
            placeholder="Pesquisar por CNPJ/CPF ou Razão Social/Nome"
          />
        </div>
        <div>
          <button
            className="btn btn-sucesso"
            type="button"
            onClick={() => loadReportData(selectedCompany)}
            disabled={!selectedCompany}
          >
            <span
              className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
            />{' '}
            Processar relatório
          </button>
        </div>
        <div>
          <DownloadXlsButton
            archiveName={`${companyInfo?.Cpf_Cnpj} - ${companyInfo?.Trading_Name
              ? companyInfo?.Trading_Name
              : companyInfo?.Company_Name
              } - (Ponto de Sucesso)`}
            data={reportData.map((info) => {
              return {
                ...info,
                lastRecord: info.lastRecord
                  ? format(new Date(info.lastRecord), 'dd/MM/yyyy')
                  : '',
              }
            })}
            className="btn btn-export export-stone-link"
            disabled={reportData.length === 0}
            columns={[
              {
                name: 'Funcionalidade',
                acessor: 'functionality',
              },
              {
                name: 'Quantidade',
                acessor: 'count',
              },
              {
                name: 'Último Registro',
                acessor: 'lastRecord',
              },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
            Exportar .xls
          </DownloadXlsButton>
        </div>
      </div>
      <div
        className="success-report-company-info"
        style={{ marginBottom: '10px' }}
      >
        <div>
          <p>
            <strong>Empresa: </strong>{' '}
            {companyInfo ? companyInfo.Company_Name : ''}
            <br />
            <strong>Plano: </strong>
            {companyInfo
              ? companyInfo.Signature.reduce((prev, current) => {
                return prev.updatedAt > current.updatedAt ? prev : current
              }).Plans?.Description
              : ''}
            <br />
            <strong>Data de Cadastro: </strong>
            {companyInfo
              ? format(new Date(companyInfo.createdAt), 'dd/MM/yyyy')
              : 'dd/mm/aaaa'}
            <strong style={{ marginLeft: '5px' }}>Data de Assinatura: </strong>{' '}
            {companyInfo
              ? format(
                new Date(
                  companyInfo.Signature[
                    companyInfo.Signature.length - 1
                  ].Start_Date
                ),
                'dd/MM/yyyy'
              )
              : 'dd/mm/aaaa'}
          </p>
        </div>
      </div>
    </>
  )
}

export default SuccessReportFilter
