import api from '../services/api';

export const listDefects = async (params) => {
  try {
    const res = await api.get('/defects', {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateDefect = async (id, data) => {
  try {
    await api.put('/defects/' + id, data);
  } catch (err) {
    throw err;
  }
};

export const createDefect = async (body) => {
  const res = await api.post('/defects', body);
  return res.data;
};

export const deleteDefect = async (id) => {
  try {
    await api.delete('/defects/' + id);
  } catch (err) {
    throw err;
  }
};
export const deleteCorrection = async (id) => {
  try {
    await api.delete('/corrections/' + id);
  } catch (err) {
    throw err;
  }
};
export const findDefectById = async (id, params) => {
  try {
    const res = await api.get('/defects/' + id, {
      params,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const DefectRepository = {
  createDefect,
  deleteDefect,
  listDefects,
  updateDefect,
  findDefectById,
};
