import React from 'react';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/Button';

const CustomerListTable = ({
  data,
  type,
  isLoading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      <ReactTable
        style={{
          fontSize: '12px',
          marginTop: '30px',
          width: '95%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={data}
        columns={[
          {
            Header: 'Vencimento',
            accessor: 'dueDate',
            width: 110,
            className: 'texto',
          },
          {
            Header: 'Título',
            accessor: 'code',
            headerClassName: 'text-left',
            width: 80,
          },
          {
            Header: type === 'client' ? 'Venda' : 'Compra',
            accessor: type === 'client' ? 'saleCode' : 'purchaseCode',
            width: 80,
            className: 'texto',
          },
          {
            Header: type === 'client' ? 'Cliente' : 'Fornecedor',
            accessor: 'personName',
            headerClassName: 'text-left',
            width: 100,
          },
          {
            Header: 'Acréscimo',
            accessor: 'addedValue',
            width: 130,
            className: 'texto',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Desconto',
            accessor: 'discountValue',
            width: 130,
            className: 'texto',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Total',
            accessor: 'liquidValue',
            width: 130,
            className: 'texto',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Valor Pago',
            accessor: 'paidValue',
            width: 130,
            className: 'texto',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Valor em Aberto',
            accessor: 'openValue',
            width: 130,
            className: 'texto',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Status',
            accessor: 'isExpired',
            width: 100,
            Cell: (props) => {
              return (
                <Button
                  id="botaoReactTable"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: props.value ? '#ff0000' : 'green',
                  }}
                >
                  {props.value ? 'Vencida' : 'Em dia'}
                </Button>
              );
            },
          },
          {
            Header: 'Criação',
            accessor: 'issuedAt',
            width: 115,
            className: 'texto',
            Cell: (props) => (
              <span>{format(new Date(props.value), 'dd/MM/yyyy')}</span>
            ),
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        pages={totalPages}
        loading={isLoading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={
          type === 'client'
            ? 'Nenhum cliente encontrado.'
            : 'Nenhum fornecedor encontrado.'
        }
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </>
  );
};

export default CustomerListTable;
