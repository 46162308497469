import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';
import kitsRepository from 'repositories/Kit';
import pdvRepository from 'repositories/PDVs';
import brandsRepository from 'repositories/Brands';
import companyConfigRepository from 'repositories/CompanyConfig';
import ImagesModal from 'client/components/ProductsServicesTable/ImagesModal';
import productsRepository from 'repositories/Products';
import TableFipeRepository from 'repositories/TableFipe';
import familiesRepository from 'repositories/Families';
import { faCircle, faEquals } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchModalTableWithInfiniteScroll } from 'client/components/SearchModalTableWithInfiniteScroll';
import './styles.css';
import { FragaModal } from 'v2/client/components/FragaModal';
import { getProductPromotionalPrices } from 'v2/helpers/priceHelpers';
import PromotionalPriceTable from 'repositories/PromotionalPriceTable';

const SearchModal = ({
  handleClose,
  productQuery,
  setProductQuery,
  setVehicleModel,
  vehicleModel,
  handleChangeProductQuery,
  handleChangeVehicleQuery,
  allowModalOpen,
  setUpdateTotalPdvTrigger,
  priceType,
  priceTypes,
  handleSetType,
  allowPromotionalPrices,
  additionalInformationQuery,
}) => {
  const { companyId, loading: loadingAuth } = useAuth();
  const dispatch = useDispatch();

  const [productToVisualize, setProductToVisualize] = useState({});
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);

  const { cart } = useSelector((state) => state.form.pdvOnline.values);

  const [isOpen, setIsOpen] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalKits, setTotalKits] = useState(0);
  const [productQueryInput, setProductQueryInput] = useState('');
  const [vehicleModelInput, setVehicleModelInput] = useState('');
  const [yearVehicleInput, setYearVehicleInput] = useState('');
  const [additionalInformationInput, setAdditionalInformationInput] =
    useState('');

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [manufacturerDescription, setManufacturerDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [familyDescription, setFamilyDescription] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);

  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);
  const [searchType, setSearchType] = useState('Produtos');

  const [kits, setKits] = useState([]);
  const [pagesKits, setPagesKits] = useState(0);
  const [kitSearchQuery, setKitSearchQuery] = useState('');

  const [productQueryDebounced, setProductQueryDebounced] =
    useState(productQuery);
  const [vehicleModelDebounced, setVehicleModelDebounced] =
    useState(vehicleModel);
  const [brandDebounced, setBrandDebounced] = useState(brandId);
  const [manufacturerDebounced, setManufacturerDebounced] = useState(
    manufacturerDescription
  );
  const [familyDebounced, setFamilyDebounced] = useState(familyDescription);

  const [additionalInformationDebounced, setAdditionalInformationDebounced] =
    useState(additionalInformationQuery);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [kitSearchDebounced, setKitSearchDebounced] = useState('');
  const [yearVehicleDebounced, setYearVehicleDebounced] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const debouncedSaveBrand = useDebounce(setBrandDebounced, 1000);
  const debouncedSaveManufacturer = useDebounce(setManufacturerDebounced, 1000);
  const debouncedSaveFamily = useDebounce(setFamilyDebounced, 1000);
  const debouncedSaveQuery = useDebounce(setProductQueryDebounced, 1000);
  const debouncedSaveVehicleModel = useDebounce(setVehicleModelDebounced, 1000);
  const debouncedSaveAdditionalInformation = useDebounce(
    setAdditionalInformationDebounced,
    1000
  );
  const debouncedKitSearchQuery = useDebounce(setKitSearchDebounced, 1000);
  const debouncedSearchVehicleYear = useDebounce(setYearVehicleDebounced, 1000);

  let newArray = cart || [];

  const [brands, setBrands] = useState([]);
  const [manufactures, setManufactures] = useState([]);
  const [families, setFamilies] = useState([]);
  const [autoAddSingleItemToPDV, setAutoAddSingleItemToPDV] = useState(true);
  const [showFragaModalOpen, setShowFragaModalOpen] = useState(false);

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId);
      const serializedBrands = brands.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands]
      );

      serializedBrands.unshift({ value: '*', label: 'Todas' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadManufacturers = async () => {
    try {
      const manufactures = await TableFipeRepository.getBrands();

      const serializedManufactures = manufactures.data.map(
        (brand) => ({
          label: brand.Brand,
          value: brand.Brand,
        }),
        [manufactures]
      );

      serializedManufactures.unshift({ value: '*', label: 'Todas' });
      setManufactures(serializedManufactures);
      setManufacturerDescription(serializedManufactures);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadFamilies = async () => {
    try {
      const families =
        await familiesRepository.getAllProductsFamiliesActiveByCompany(
          companyId
        );

      const serializedFamilies = families.map(
        (family) => ({
          label: family.Description,
          value: family.id,
        }),
        [families]
      );

      serializedFamilies.unshift({ value: '*', label: 'Todas' });
      setFamilies(serializedFamilies);
      setFamilyDescription(serializedFamilies);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  const loadCompanyConfig = async () => {
    try {
      const companyConfig = await companyConfigRepository.show(companyId);
      setAutoAddSingleItemToPDV(companyConfig.AutoAddSingleItemToPDV);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as configurações da empresa. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    loadCompanyConfig();
    loadBrands();
    loadManufacturers();
    loadFamilies();
  }, []);

  const generateData = async (item, productSalePrice, type, priceType) => {
    const data = {
      id: item.productId || item.serviceId,
      Code: item.productCode || item.code,
      Description: item.productDescription || item.description,
      Unit_Type: item.productUnitType || 'UN',
      Bar_Code: item.productBarCode || '',
      Vehicle: item.vehicleModel || '',
      Quantity: 1,
      Sale_Price: productSalePrice,
      Value_Cost: item.productPurchasePrice || 0,
      Discount_Rate: 0,
      Total: productSalePrice,
      Product_id: item.productId || item.serviceId,
      Type: type || 'Produto',
      priceType: priceType || 'RETAIL',
      hasDiscountLimit: item.productHasDiscountLimit,
      discountLimit: item.productDiscountLimit,
    };

    return data;
  };

  const validateExistsProductInArray = async (array, id, type) => {
    const productAlreadyAddedIndex =
      type === 'Produto'
        ? array.findIndex((prod) => prod.id === id)
        : array.findIndex((service) => service.id === id);

    return productAlreadyAddedIndex;
  };

  const dispatchItem = async (index) => {
    if (index < 0) {
      return;
    }

    dispatch([
      change('pdvOnline', `cart[${index}].Quantity`, cart[index].Quantity + 1),
      change(
        'pdvOnline',
        `cart[${index}].Total`,
        (cart[index].Quantity + 1) *
          cart[index].Sale_Price *
          (1 - cart[index].Discount_Rate / 100)
      ),
    ]);
  };

  const getValueItem = async (
    promotionalPriceActive,
    pricePromotional,
    productSalePrice
  ) => {
    return promotionalPriceActive && pricePromotional
      ? pricePromotional
      : productSalePrice;
  };

  const productKit = async (products) => {
    products.map(async (product) => {
      const findAlreadyAdded = await validateExistsProductInArray(
        newArray,
        product.productId,
        'Produto'
      );

      if (findAlreadyAdded >= 0) {
        dispatchItem(findAlreadyAdded);
      } else {
        const data = await generateData(product, product.kitPrice);
        newArray.push(data);
        dispatch(change('pdvOnline', 'cart', newArray));
      }
    });
  };

  const servicesKit = async (services) => {
    if (!services?.length > 0) {
      return;
    }

    services.forEach(async (service) => {
      const index = await validateExistsProductInArray(
        newArray,
        service.serviceId,
        'Serviço'
      );

      if (index >= 0) {
        dispatchItem(index);
      } else {
        const data = await generateData(service, service.kitPrice, 'Serviço');
        newArray.push(data);
        dispatch(change('pdvOnline', 'cart', newArray));
      }
    });
  };

  const handleOpenModalOnlinePartsInquiry = async () => {
    setShowFragaModalOpen(true);
  };

  const handleOpenProductImageModal = async (product) => {
    try {
      const productsPhotos = await productsRepository.getPhotos(
        product.productId
      );

      if (!productsPhotos.length)
        return toastr.warning(
          'Produto sem foto',
          'Nenhuma foto foi identificada para esse produto'
        );

      setProductToVisualize({ ...product, ProductsPhotos: productsPhotos });
      setIsImagesModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as fotos do produto',
        'Por favor, tente novamente'
      );
    }
  };

  const handleAddItem = async (item) => {
    const activePromotionalTable =
      await PromotionalPriceTable.getActiveByCompanyId(companyId);
    const currentDate = new Date().toISOString().split('T')[0];

    const promotionalTableisValid =
      currentDate >= activePromotionalTable.initialDate &&
      currentDate <= activePromotionalTable.finalDate;

    const promotionalPrice = getProductPromotionalPrices(
      item.productSalePrice,
      item.productSalePriceWholesale,
      promotionalTableisValid ? allowPromotionalPrices : false,
      item.promotionalPrice,
      item.promotionalPriceWholesale,
      priceType.value
    );
    console.log(promotionalPrice);
    switch (searchType) {
      case 'Produtos':
        const data = await generateData(
          item,
          promotionalPrice.Sale_Price,
          'Produto',
          promotionalPrice.priceType
        );
        const productAlreadyAddedIndex = await validateExistsProductInArray(
          newArray,
          item.productId,
          'Produto'
        );

        if (productAlreadyAddedIndex >= 0) {
          await dispatchItem(productAlreadyAddedIndex);
          break;
        }

        newArray.push(data);
        dispatch(change('pdvOnline', 'cart', newArray));

        break;

      case 'Kit':
        await productKit(item.Products);
        await servicesKit(item.Services);

        break;

      default:
        break;
    }
    setUpdateTotalPdvTrigger(true);
    toastr.success('Item adicionado com sucesso');
    handleClose();
  };

  useEffect(() => {
    if (!loading) {
      if (autoAddSingleItemToPDV) {
        if (data.length === 1 && !isOpen) {
          handleAddItem(data[0]);
          return;
        }
      }

      if (data.length === 0 && productQuery) {
        toastr.warning(
          'Produto não localizado',
          'Não foi localizado nenhum produto com essa informação. Refaça a pesquisa e tente novamente'
        );

        if (!isOpen) {
          handleClose();
        }
        return;
      }

      setIsOpen(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (isLoadingMore) {
      if (searchType === 'Produtos') {
        handleSearchProducts().finally(() => setIsLoadingMore(false));
      } else {
        loadKits().finally(() => setIsLoadingMore(false));
      }
      setHasScrolled(false);
    }
  }, [isLoadingMore, searchType]);

  const handleFetchMoreData = () => {
    if (
      !isLoadingMore &&
      (currentPage < totalPages || currentPage < pagesKits)
    ) {
      setIsLoadingMore(true);

      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        return nextPage;
      });
    }
  };

  const handleSearchProducts = async () => {
    setLoading(true);
    try {
      const isAllBrandIdSelected = brandId.some((brand) => brand.value === '*');
      const brandIds = brandId
        .filter((brand) => brand.value !== '*')
        .map((brand) => brand.value)
        .join(',');

      const isAllManufacturerDescriptionSelected = manufacturerDescription.some(
        (manufacturer) => manufacturer.value === '*'
      );
      const manufacturesDescriptions = manufacturerDescription
        .filter((manufacturer) => manufacturer.value !== '*')
        .map((manufacturer) => manufacturer.value)
        .join(',');

      const isAllFamilyDescriptionSelected = familyDescription.some(
        (family) => family.value === '*'
      );
      const familiesDescriptions = familyDescription
        .filter((family) => family.value !== '*')
        .map((family) => family.value)
        .join(',');

      const params = {
        limit: pageLimit,
        page: currentPage,
        productQueryA: productQuery ? productQuery : productQueryDebounced,
        vehicleModelA: vehicleModel ? vehicleModel : vehicleModelDebounced,
        productQueryB: productQueryDebounced,
        vehicleModelB: vehicleModelDebounced,
        additionalInformation: additionalInformationDebounced,
      };

      if (!isAllBrandIdSelected && brandIds.length !== 0) {
        params.brandsIds = `[${brandIds}]`;
      }

      if (
        !isAllManufacturerDescriptionSelected &&
        manufacturesDescriptions.length !== 0
      ) {
        params.manufacturesDescriptions = `[${manufacturesDescriptions}]`;
      }

      if (
        !isAllFamilyDescriptionSelected &&
        familiesDescriptions.length !== 0
      ) {
        params.familiesDescriptions = `[${familiesDescriptions}]`;
      }

      if (!!yearVehicleDebounced) {
        params.yearVehicle = yearVehicleDebounced;
      }

      const response = await pdvRepository.getProducts(companyId, params);

      setTotalProducts(response.count);
      if (currentPage === 1) {
        setData(response.products);
      } else {
        setData([...data, ...response.products]);
      }
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível realizar a busca de produtos.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  async function loadKits() {
    setLoading(true);

    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: 1,
        page: currentPage,
        limit: pageLimit,
        query: kitSearchDebounced,
      });

      const serializedKits = response.kits.rows.map((kit) => ({
        Products: response.kitItemsProducts.filter(
          (product) => product.kitId === kit.id
        ),
        Services: response.kitItemsServices.filter(
          (service) => service.kitId === kit.id
        ),
        productDescription: kit.description,
        productSalePrice: kit.priceTotal,
        productCode: '---',
        productManufacturerRef: '---',
        productBarCode: kit.barCode,
        productQuantity: '---',
        brandDescription: '---',
        vehicleModel: '---',
        productLocation: '---',
      }));

      if (currentPage === 1) {
        setKits(serializedKits);
      } else {
        setKits([...kits, ...serializedKits]);
      }
      setTotalKits(response.kits.count);
      setPagesKits(Math.ceil(response.kits.count / pageLimit));
    } catch (err) {
      console.error(err);
      if (!kits) {
        toastr.warning(
          'Ocorreu um erro ao buscar os kits. Por favor, tente novamente.'
        );
      }
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }

  function handleChangeProductQuery(value) {
    setProductQueryInput(value);
    debouncedSaveQuery(value);
  }

  function handleChangeVehicleQuery(value) {
    setVehicleModelInput(value);
    debouncedSaveVehicleModel(value);
  }

  function handleChangeAdditionalInformationQuery(value) {
    setAdditionalInformationInput(value);
    debouncedSaveAdditionalInformation(value);
  }

  function handleChangeBrand(value) {
    setBrandId(value);
    debouncedSaveBrand(value);
  }

  function handleChangeManufacturer(value) {
    setManufacturerDescription(value);
    debouncedSaveManufacturer(value);
  }

  function handleChangeFamily(value) {
    setFamilyDescription(value);
    debouncedSaveFamily(value);
  }

  function handleChangeQuerySearchKit(value) {
    setKitSearchQuery(value);
    debouncedKitSearchQuery(value);
  }

  function handleChangeYear(value) {
    const valueOnlyNumbers = value.replace(/\D/g, '');

    if (value === '') {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');
      return;
    }

    const firstDigit = Number(valueOnlyNumbers[0]);

    if (firstDigit > 2 || firstDigit === 0) {
      setYearVehicleInput('');
      debouncedSearchVehicleYear('');

      return toastr.warning(
        'Ano inválido',
        'Insira um ano válido para aplicação e tente novamente'
      );
    }

    setYearVehicleInput(valueOnlyNumbers);
    debouncedSearchVehicleYear(valueOnlyNumbers);
  }

  const handleBackAndClear = () => {
    handleClose();

    setProductQueryInput('');
    setVehicleModelInput('');
    setAdditionalInformationInput('');
    setKitSearchQuery('');
    setYearVehicleInput('');
  };

  const handleResetSearch = () => {
    setKits([]);
    setKitSearchDebounced('');
    setProductQuery('');
    setProductQueryDebounced('');
    setVehicleModelDebounced('');
    setProductQueryInput('');
    setVehicleModelInput('');
    setAdditionalInformationInput('');
    setKitSearchQuery('');
    setYearVehicleInput('');
    setManufacturerDescription(manufactures);
    setManufacturerDebounced(manufactures);
    setFamilyDescription(families);
    setFamilyDebounced(families);
    setBrandId(brands);
    setBrandDebounced(brands);
  };

  const addSelectedItems = (products) => {
    products.map(async (product) => {
      const findAlreadyAdded = await validateExistsProductInArray(
        newArray,
        product.Product_id,
        'Produto'
      );

      if (findAlreadyAdded >= 0) {
        dispatchItem(findAlreadyAdded);
      } else {
        const serializedProduct = {
          id: product.Product_id,
          Code: product.Code,
          Description: product.Description,
          Unit_Type: product.Unit_Type,
          Bar_Code: '',
          Vehicle: '',
          Quantity: product.Quantity,
          Sale_Price: product.Sale_Price,
          Value_Cost: 0.01,
          Discount_Rate: product.Discount_Value || 0,
          Total: product.Total,
          Product_id: product.Product_id,
          Type: product.Type,
        };
        newArray.push(serializedProduct);
        dispatch(change('pdvOnline', 'cart', newArray));
      }
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    setIsLoadingMore(true);
  }, [
    productQueryDebounced,
    vehicleModelDebounced,
    additionalInformationDebounced,
    brandDebounced,
    manufacturerDebounced,
    familyDebounced,
    searchType,
    kitSearchDebounced,
    yearVehicleDebounced,
  ]);

  return (
    <>
      <Modal
        dialogClassName="modal-90w"
        show={isOpen}
        onHide={handleClose}
        animation
      >
        <Modal.Body>
          <SearchModalTableWithInfiniteScroll
            loading={loading}
            handleClose={handleClose}
            data={data}
            totalProducts={totalProducts}
            totalKits={totalKits}
            totalPages={totalPages}
            currentPage={currentPage}
            brandId={brandId}
            manufacturerDescription={manufacturerDescription}
            familyDescription={familyDescription}
            productQuery={productQueryInput}
            setProductQueryDebounced={setProductQueryDebounced}
            vehicleModel={vehicleModelInput}
            additionalInformation={additionalInformationInput}
            setVehicleModelDebounced={setVehicleModelDebounced}
            handleChangeProductQuery={handleChangeProductQuery}
            handleChangeVehicleQuery={handleChangeVehicleQuery}
            handleChangeAdditionalInformationQuery={
              handleChangeAdditionalInformationQuery
            }
            handleChangeBrand={handleChangeBrand}
            handleChangeManufacturer={handleChangeManufacturer}
            handleChangeFamily={handleChangeFamily}
            handleAddItem={handleAddItem}
            allowPromotionalPrices={allowPromotionalPrices}
            searchType={searchType}
            priceType={priceType}
            priceTypes={priceTypes}
            handleSetType={handleSetType}
            handleChangeQuerySearchKit={handleChangeQuerySearchKit}
            kits={kits}
            pagesKits={pagesKits}
            setSearchType={setSearchType}
            kitSearchQuery={kitSearchQuery}
            brands={brands}
            manufactures={manufactures}
            families={families}
            handleChangeYear={handleChangeYear}
            yearVehicle={yearVehicleInput}
            handleOpenProductImageModal={handleOpenProductImageModal}
            handleSearchProducts={handleSearchProducts}
            isLoadingMore={isLoadingMore}
            handleFetchMoreData={handleFetchMoreData}
            hasScrolled={hasScrolled}
            setHasScrolled={setHasScrolled}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  fontSize: '12px',
                  color: ' #d9534f',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{
                    width: 10,
                    height: 10,
                  }}
                  icon={faCircle}
                />
                Produtos com estoque zerado ou negativo
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  fontSize: '12px',
                  color: ' #4cae4c',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{
                    width: 10,
                    height: 10,
                  }}
                  icon={faCircle}
                />
                Produtos com preço promocional
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  fontSize: '12px',
                  color: ' #4cae4c',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  style={{
                    width: 10,
                    height: 10,
                  }}
                  icon={faEquals}
                />
                Produto com similaridade
              </span>
            </div>
            <div
              style={{
                width: '350px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className="button button-h35 flex center"
                style={{
                  width: '200px',
                  backgroundColor: '#5cb85c',
                  color: '#FFFFFF',
                  border: 'none',
                }}
                onClick={() => handleOpenModalOnlinePartsInquiry()}
              >
                <span>Consulta de Peças Online</span>
              </button>
              <button
                className="button button-h35 flex center"
                style={{
                  width: '127px',
                  backgroundColor: '#f0ad4e',
                  color: '#FFFFFF',
                  border: 'none',
                }}
                onClick={handleResetSearch}
                disabled={isLoadingMore}
              >
                <span className="flex center">Limpar Pesquisa</span>
              </button>
              <button
                className="button button-red button-h35 flex center"
                style={{
                  width: '93px',
                  backgroundColor: '#d9534f',
                  color: '#FFFFFF',
                  border: 'none',
                }}
                onClick={handleBackAndClear}
              >
                <span>Voltar(ESC)</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={productToVisualize.Description}
          productPhotos={productToVisualize.ProductsPhotos}
        />
      )}

      {showFragaModalOpen && (
        <FragaModal
          show={showFragaModalOpen}
          onSubmit={(registeredItems) => {
            addSelectedItems(registeredItems);
          }}
          setShowFragaModalOpen={setShowFragaModalOpen}
          isLocal={false}
          openedBy="pdv"
        />
      )}
    </>
  );
};

export default SearchModal;
