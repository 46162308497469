import { format } from 'date-fns';
import React from 'react';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import { Table } from 'v2/components/Table';

export default function TableCustomerApproach({
  reportData,
  loading,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const tableColumns = [
    {
      Header: 'Dt. Cadastro',
      accessor: 'createdAt',
      width: 100,
      Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
    },
    {
      Header: 'Nome Completo',
      accessor: 'fullName',
      width: 220,
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      width: 100,
    },
    {
      Header: 'Abordagem',
      accessor: 'approachDescription',
      width: 100,
    },
    {
      Header: 'Últ. Venda/OS',
      accessor: 'latestSaleCode',
      width: 100,
    },
    {
      Header: 'Ticket Médio',
      accessor: 'averageTicket',
      width: 100,
      Cell: (props) => currency(props.value),
    },
  ];

  return (
    <div>
      <Table
        defaultPageSize={10}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        sortable={true}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        data={reportData}
        columns={tableColumns}
        fontSize="12px"
        defaultSorted={[
          {
            id: 'Code',
            desc: false,
          },
        ]}
      />
    </div>
  );
}
