import React, { useEffect } from 'react';

import { useBonus } from 'hooks/useBonus';

import { CRMBonusConfirmationSaleWithoutBonusModal } from './CRMBonusConfirmationSaleWithoutBonusModal';
import { CRMBonusIncompleteCustomerModal } from './CRMBonusIncompleteCustomerModal';
import { CRMBonusSelectCampaignModal } from './CRMBonusSelectCampaignModal';
import { CRMBonusCancelUpdateCustomerModal } from './CRMBonusCancelUpdateCustomerModal';
import { CRMBonusFirstPurchaseModal } from './CRMBonusFirstPurchaseModal';
import { CRMBonusPINValidationModal } from './CRMBonusPINValidationModal';
import { CRMBonusAvailableModal } from './CRMBonusAvailableModal';
import { CRMBonusRescuedModal } from './CRMBonusRescuedModal';
import { CRMBonusUpdateCustomerModal } from './CRMBonusUpdateCustomerModal';
import { CRMBonusConfirmationCampaignModal } from './CRMBonusConfirmationCampaignModal';
import { CRMBonusPDVConfirmCPFModal } from './CRMBonusPDVConfirmCPFModal';
import { CRMBonusPDVInputCPFModal } from './CRMBonusPDVInputCPFModal';
import { CRMBonusCreateCustomerModal } from './CRMBonusCreateCustomerModal';
import { CRMBonusNotFoundCustomerModal } from './CRMBonusNotFoundCustomerModal';

export const CRMBonusModals = ({ sale }) => {
  const {
    openConfirmationSaleWithoutBonusModal,
    openIncompleteCustomerModal,
    openUpdateCustomerModal,
    openCancelUpdateCustomerModal,
    openSelectCampaignModal,
    openFirstPurchaseModal,
    openPINValidationModal,
    openBonusAvailableModal,
    openBonusRescuedModal,
    openPDVConfirmCPFModal,
    openPDVInputCPFModal,
    openCampaignConfirmationModal,
    openNotFoundCustomerModal,
    openCreateCustomerModal,

    handleOpenPDVConfirmCPFModal,
    handleOpenPDVInputCPFModal,
    handleOpenIncompleteCustomerModal,
    handleOpenCancelUpdateCustomerModal,
    handleOpenSelectCampaignModal,
    handleOpenCampaignConfirmationModal,
    handleOpenFirstPurchaseModal,

    handleCloseSaleWithoutBonusModal,
    handleCloseIncompleteCustomerModal,
    handleCloseCRMBonusUpdateCustomerModal,
    handleCloseCancelUpdateCustomerModal,
    handleCloseUpdateCustomerModal,
    handleCloseFirstPurchaseModal,
    handleClosePINValidationModal,
    handleCloseBonusAvailableModal,
    handleCloseSelectCampaignModal,
    handleCloseCampaignConfirmationModal,
    handleCloseBonusRescuedModal,
    handleClosePDVConfirmCPFModal,
    handleClosePDVInputCPFModal,
    handleCloseNotFoundCustomerModal,
    handeCloseCreateCustomerModal,

    handleProceedIncompleteCustomer,
    handleProceedPDVInputCPF,
    handleProceedPDVConfirmCPF,
    handleProceedCancelCreateOrUpdateCustomer,
    handleProceedCreateCustomer,
    handleProceedCancelCreateCustomer,
    handleProceedCreateOrUpdateCustomer,

    handleCancelUpdateCustomer,
    handleCancelProceedUpdateCustomer,
    handleCancelUpdateCustomerModal,

    handleCustomerFirstPurchaseProceed,
    handleBackPDVInputCPFModal,
    handleValidatePIN,
    handleUseBonusConfirmation,
    handleCampaignAvailable,
    handleInitCRMBonus,
    handleResentPIN,
    handleConsultedBonus,

    triggerContinueWithoutBonus,
    triggerSaveSale,
    triggerSaveOrcament,
    triggerContinueWithoutBonusPDV,
    triggerSavePDV,

    setCRMCustomerPIN,
    setSelectedCampaign,
    crmBonusCurrentCustomer,
    selectedCampaign,
    crmBonusAvailableValue,
    redeemedBonus,
    currentCustomerCPF,
    pdvSale,
    isPDV,
    crmBonusCurrentSaleValue,
    crmBonusCustomerId,
  } = useBonus();

  return (
    <div>
      {openIncompleteCustomerModal && (
        <CRMBonusIncompleteCustomerModal
          isOpen={openIncompleteCustomerModal}
          handleProceedIncompleteCustomer={handleProceedIncompleteCustomer}
          handleContinueWithoutBonus={
            isPDV ? triggerContinueWithoutBonusPDV : triggerContinueWithoutBonus
          }
          handleClose={handleCloseIncompleteCustomerModal}
        />
      )}

      {openUpdateCustomerModal && (
        <CRMBonusUpdateCustomerModal
          isOpen={openUpdateCustomerModal}
          handleProceedCancelCreateOrUpdateCustomer={
            handleProceedCancelCreateOrUpdateCustomer
          }
          handleCancelUpdate={handleOpenCancelUpdateCustomerModal}
          customer={crmBonusCurrentCustomer ? crmBonusCurrentCustomer : pdvSale}
          customerId={pdvSale ? pdvSale.id : crmBonusCurrentCustomer.id}
          handleProceedUpdateCustomer={handleProceedCreateOrUpdateCustomer}
        />
      )}

      {openCancelUpdateCustomerModal && (
        <CRMBonusCancelUpdateCustomerModal
          isOpen={openCancelUpdateCustomerModal}
          handleClose={handleCancelProceedUpdateCustomer}
          handleConfirmation={handleProceedCancelCreateOrUpdateCustomer}
        />
      )}

      {openFirstPurchaseModal && (
        <CRMBonusFirstPurchaseModal
          isOpen={openFirstPurchaseModal}
          handleProceedFirstPurchase={handleCustomerFirstPurchaseProceed}
        />
      )}

      {openPINValidationModal && (
        <CRMBonusPINValidationModal
          isOpen={openPINValidationModal}
          handleValidatePIN={handleValidatePIN}
          setCRMCustomerPIN={setCRMCustomerPIN}
          handleClose={handleClosePINValidationModal}
          handleContinueWithoutBonus={
            isPDV ? triggerContinueWithoutBonusPDV : triggerContinueWithoutBonus
          }
          customer={
            !!crmBonusCurrentCustomer.id ? crmBonusCurrentCustomer : pdvSale
          }
          handleResentPIN={handleResentPIN}
          sale={sale}
        />
      )}

      {openBonusAvailableModal && (
        <CRMBonusAvailableModal
          isOpen={openBonusAvailableModal}
          handleProceedBonusAVailable={() => handleUseBonusConfirmation()}
          handleContinueWithoutBonus={
            isPDV ? triggerContinueWithoutBonusPDV : triggerContinueWithoutBonus
          }
          handleClose={handleCloseBonusAvailableModal}
          crmBonusAvailableValue={crmBonusAvailableValue}
          handleConsultedBonus={handleConsultedBonus}
        />
      )}

      {openConfirmationSaleWithoutBonusModal && (
        <CRMBonusConfirmationSaleWithoutBonusModal
          isOpen={openConfirmationSaleWithoutBonusModal}
          handleCancel={handleCloseSaleWithoutBonusModal}
          handleProceed={() => {
            if (
              crmBonusCurrentCustomer?.id === sale.selectedClient.id &&
              !!crmBonusCustomerId
            ) {
              handleOpenSelectCampaignModal();
              return;
            }

            if (isPDV) return triggerSavePDV();
            return triggerSaveSale();
          }}
        />
      )}

      {openSelectCampaignModal && (
        <CRMBonusSelectCampaignModal
          isOpen={openSelectCampaignModal}
          handleClose={handleCloseSelectCampaignModal}
          sale={sale}
          customer={crmBonusCurrentCustomer ? crmBonusCurrentCustomer : pdvSale}
          crmBonusCurrentSaleValue={crmBonusCurrentSaleValue}
          handleInitCRMBonus={handleInitCRMBonus}
          setSelectedCampaign={setSelectedCampaign}
          handleCampaignAvailable={handleCampaignAvailable}
          handleOpenCampaignConfirmationModal={
            handleOpenCampaignConfirmationModal
          }
        />
      )}

      {openCampaignConfirmationModal && (
        <CRMBonusConfirmationCampaignModal
          isOpen={openCampaignConfirmationModal}
          campaign={selectedCampaign}
          handleClose={handleCloseCampaignConfirmationModal}
          handleSaleTrigger={isPDV ? triggerSavePDV : triggerSaveSale}
        />
      )}

      {openBonusRescuedModal && (
        <CRMBonusRescuedModal
          isOpen={openBonusRescuedModal}
          handleProceed={triggerSaveOrcament}
          handleClose={handleCloseBonusRescuedModal}
          redeemedBonus={redeemedBonus}
        />
      )}

      {openPDVConfirmCPFModal && (
        <CRMBonusPDVConfirmCPFModal
          isOpen={openPDVConfirmCPFModal}
          handleClose={handleClosePDVConfirmCPFModal}
          handleProceedPDVConfirmCPF={handleProceedPDVConfirmCPF}
          handleContinueWithoutBonus={triggerContinueWithoutBonusPDV}
        />
      )}

      {openPDVInputCPFModal && (
        <CRMBonusPDVInputCPFModal
          isOpen={openPDVInputCPFModal}
          handleBack={handleBackPDVInputCPFModal}
          handleProceedPDVInputCPF={handleProceedPDVInputCPF}
          handleClose={handleClosePDVInputCPFModal}
          customerCPF={currentCustomerCPF}
        />
      )}

      {openNotFoundCustomerModal && (
        <CRMBonusNotFoundCustomerModal
          isOpen={openNotFoundCustomerModal}
          handleProceedCreateCustomer={handleProceedCreateCustomer}
          handleContinueWithoutBonus={triggerContinueWithoutBonusPDV}
          handleClose={handleCloseNotFoundCustomerModal}
        />
      )}

      {openCreateCustomerModal && (
        <CRMBonusCreateCustomerModal
          isOpen={openCreateCustomerModal}
          handleProceedCancelCreateCustomer={handleProceedCancelCreateCustomer}
          handleCancelCreate={handleOpenCancelUpdateCustomerModal}
          customerCPF={currentCustomerCPF}
          handleClose={handeCloseCreateCustomerModal}
          handleProceed={handleProceedCreateOrUpdateCustomer}
        />
      )}
    </div>
  );
};
