import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import SearchInput from 'components/SearchInput'
import FormModalVehicle from '../FormModals/Vehicle'
import { useAuth } from 'contexts/auth'
import useFilters from '../../../hooks/useFilters'
import vehiclesRepository from '../../../repositories/Vehicles'
import AlertModal from 'components/AlertModal'

import './styles.css'
import VehiclesTypes from './components/VehiclesTypes'
import useDebounce from 'hooks/useDebounce'
import segmentsRepository from 'repositories/Segments'
import CarMotobikeTrucksTable from './components/CarMotobikeTrucksTable'
import GenericVehicleTable from './components/GenericVehicleTable'
import BikeTable from './components/BikeTable'

const VehiclesTable = ({ customerId }) => {
  const [loading, setLoading] = useState(false)
  const [isVehicleFormModalOpen, setIsVehicleFormModalOpen] = useState(false)
  const [isVehicleDeleteModalOpen, setIsVehicleDeleteModalOpen] =
    useState(false)
  const [vehicles, setVehicles] = useState([])
  const [vehicleId, setVehicleId] = useState(null)
  const [segments, setSegments] = useState([])
  const [totalVehiclesTypes, setTotalVehiclesTypes] = useState({})
  const [greaterVehicleQuantityId, setGreaterVehicleQuantityId] = useState(null)

  const [selectedVehicleType, setSelectedVehicleType] = useState(null)

  const [pageLimit, setPageLimit] = useState(5)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [queryDebounced, setQueryDebounced] = useState('')
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600)

  const { userId, companyId } = useAuth()
  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  useEffect(() => {
    if (!!customerId) {
      loadCustomerVehicles()
    }
  }, [currentPage, pageLimit, queryDebounced, selectedVehicleType])

  async function loadCustomerVehicles() {
    setLoading(true)
    try {
      const params = {
        page: currentPage + 1,
        limit: pageLimit,
        query: queryDebounced,
        customerId,
        selectedVehicleType: +selectedVehicleType,
      }

      const response = await vehiclesRepository.getVehiclesByCustomerPaginated(
        params
      )
      setTotalVehiclesTypes(response.totalVehiclesTypes)
      setVehicles(response.vehicles)
      setTotalPages(Math.ceil(response.count / pageLimit))

      if (!selectedVehicleType) {
        setSelectedVehicleType(response.greaterVehicleQuantityId)
      }
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar os veículos. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  function handleSubmitVehicleForm() {
    loadCustomerVehicles()
    setIsVehicleFormModalOpen(false)
    setVehicleId(null)
  }

  function handleOpenEditVehicleModal(id) {
    setVehicleId(id)
    setIsVehicleFormModalOpen(true)
  }

  function handleOpenDeleteVehicleModal(id) {
    setVehicleId(id)
    setIsVehicleDeleteModalOpen(true)
  }

  async function handleDeleteVehicle() {
    setIsVehicleDeleteModalOpen(false)
    setLoading(true)

    try {
      await vehiclesRepository.remove(vehicleId)
      const vehiclesFiltered = vehicles.filter(
        (vehicle) => vehicle.id !== vehicleId
      )
      setVehicles(vehiclesFiltered)
      setVehicleId(null)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao deletar o veículo. Por favor, tente novamente'
      )
    }
    setLoading(false)
  }

  const getSegmentsByUser = async () => {
    try {
      const response = await segmentsRepository.getByUser(userId)

      const serializedSegments = {
        carMotobikesTrucks: response.data.some(
          (segment) =>
            segment.Description === 'Carros' ||
            segment.Description === 'Motos' ||
            segment.Description === 'Pesados' ||
            segment.Description === 'Van'
        ),
        tractorsExcavators: response.data.some(
          (segment) =>
            segment.Description === 'Trator' ||
            segment.Description === 'Escavadeira'
        ),
        others: response.data.some(
          (segment) => segment.Description === 'Outros'
        ),
        harvester: response.data.some(
          (segment) => segment.Description === 'Colheitadeira'
        ),
        bike: response.data.some(
          (segment) => segment.Description === 'Bicicleta'
        ),
      }

      setSegments(serializedSegments)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao carregar os segmentos. Por favor, tente novamente'
      )
    }
  }

  const handleChangeQuery = (e) => {
    setQuery(e)
    debouncedSaveQuery(e)
  }

  function handleCancelVehicleForm() {
    setVehicleId(null)
    setIsVehicleFormModalOpen(false)
  }

  function handleCancelDeleteVehicleModal() {
    setVehicleId(null)
    setIsVehicleDeleteModalOpen(false)
  }

  useEffect(() => {
    getSegmentsByUser()
  }, [])

  return (
    <>
      <VehiclesTypes
        selectedVehicleType={selectedVehicleType}
        setSelectedVehicleType={setSelectedVehicleType}
        segments={segments}
        totalVehiclesTypes={totalVehiclesTypes}
        setCurrentPage={setCurrentPage}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          marginBottom: 20,
        }}
      >
        <div>
          <button
            className="btn btn-sucesso"
            onClick={() => setIsVehicleFormModalOpen(true)}
          >
            + Novo Veículo
          </button>
        </div>
        <div
          style={{
            marginLeft: '0px',
            width: '400px',
          }}
        >
          <SearchInput
            placeholder="Pesquisa por Placa/Obj. Manutenção e Modelo"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
          />
        </div>
      </div>
      <div>
        {selectedVehicleType === 1 && (
          <CarMotobikeTrucksTable
            vehicles={vehicles}
            handleOpenEditVehicleModal={handleOpenEditVehicleModal}
            handleOpenDeleteVehicleModal={handleOpenDeleteVehicleModal}
            pageLimit={pageLimit}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            setPageLimit={setPageLimit}
            loading={loading}
          />
        )}

        {(selectedVehicleType === 2 ||
          selectedVehicleType === 3 ||
          selectedVehicleType == 5) && (
          <GenericVehicleTable
            vehicles={vehicles}
            handleOpenEditVehicleModal={handleOpenEditVehicleModal}
            handleOpenDeleteVehicleModal={handleOpenDeleteVehicleModal}
            pageLimit={pageLimit}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            setPageLimit={setPageLimit}
            loading={loading}
          />
        )}

        {selectedVehicleType === 4 && (
          <BikeTable
            vehicles={vehicles}
            handleOpenEditVehicleModal={handleOpenEditVehicleModal}
            handleOpenDeleteVehicleModal={handleOpenDeleteVehicleModal}
            pageLimit={pageLimit}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            setPageLimit={setPageLimit}
            loading={loading}
          />
        )}

        {isVehicleFormModalOpen && (
          <FormModalVehicle
            show
            companyId={companyId}
            customerId={customerId}
            vehicleId={vehicleId}
            onCancel={handleCancelVehicleForm}
            onSubmit={handleSubmitVehicleForm}
            setSelectedVehicleType={setSelectedVehicleType}
          />
        )}

        <AlertModal
          show={isVehicleDeleteModalOpen}
          title="Exclusão de veículo"
          message="Deseja excluir o cadastro do veículo? Esse processo não poderá ser cancelado posteriormente, será necessário cadastrar o veículo"
          onHide={handleCancelDeleteVehicleModal}
          onCancel={handleCancelDeleteVehicleModal}
          onSubmit={handleDeleteVehicle}
          loading={loading}
        />
      </div>
    </>
  )
}

export default VehiclesTable
