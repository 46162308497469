import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';

import { getById } from 'repositories/Sales';
import { currency } from 'client/components/ToNormalize/ToNormalize';

import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { NOT_DIGITAL_PAYMENT_FORMS } from 'v2/utils/constants';
import { bulkUpdateByParcelId } from 'v2/repositories/PaymentsAuthorizationsRepository';

import {
  HeaderItem,
  InstallmentDescription,
  InstallmentRow,
  InstallmentsContainer,
  PaymentConditionWrapper,
  SaleInstallmentHeader,
  SaleInstallmentsWrapper,
} from './ModalPaymentAuthorizationCodeReview.styles';

export function ModalPaymentAuthorizationCodeReview({
  open,
  onClose,
  afterSave,
  saleId,
}) {
  const [paymentCondition, setPaymentCondition] = useState({});
  const [parcels, setParcels] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const hasDigitalPayments = parcels.some(
    (parcel) =>
      !NOT_DIGITAL_PAYMENT_FORMS.includes(parcel.FormOfPayment?.TypeOfPayment)
  );

  const handleClose = () => {
    if (isSaving) return;
    onClose();
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const serializedParcels = parcels.map((parcel) => ({
        parcelId: parcel.id,
        code: parcel.PaymentsAuthorizations?.code,
      }));

      await bulkUpdateByParcelId({ parcels: serializedParcels });

      toastr.success('Sucesso', 'As alterações foram salvas!');

      afterSave();
      handleClose();
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao salvar as informações',
        'Por favor, tente novamente!'
      );
    }
    setIsSaving(false);
  };

  const getInstallmentDescription = (index) => {
    if (
      paymentCondition.Condition === 'À vista' ||
      paymentCondition.Condition === '0'
    )
      return 'À Vista';

    if (!paymentCondition.hasEntry && index === 0) return 'Entrada';
    if (!paymentCondition.hasEntry) return `${index + 1}/${parcels.length}`;

    return `${index}/${parcels.length - 1}`;
  };

  const handleChangeAuthorizationCode = (index, value) => {
    setParcels(
      parcels.map((parcel, indexParcel) => {
        return {
          ...parcel,
          PaymentsAuthorizations: {
            code:
              indexParcel === index
                ? value
                : parcel.PaymentsAuthorizations?.code,
          },
        };
      })
    );
  };

  const loadSale = async () => {
    try {
      const sale = await getById(saleId);
      setParcels(sale.Payments?.Parcels);
      setPaymentCondition(sale.Payments?.CondPayment);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar informações',
        'Por favor, tente novamente!'
      );
      handleClose();
    }
  };

  useEffect(() => {
    if (!saleId) return handleClose();
    loadSale();
  }, []);

  return (
    <Modal show={open} onHide={handleClose} dialogClassName="modal-80w">
      <Modal.Header>
        <Modal.Title>
          <strong>Comprovante de Pagamento Eletrônico</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentConditionWrapper>
          <InputContainer variant="row">
            <InputLabel>Condição de Pagamento:</InputLabel>
            <InputText value={paymentCondition.Description} disabled readonly />
          </InputContainer>
        </PaymentConditionWrapper>
        <SaleInstallmentsWrapper>
          <SaleInstallmentHeader>
            <HeaderItem>Parcela</HeaderItem>
            <HeaderItem>Vencimento</HeaderItem>
            <HeaderItem>Valor</HeaderItem>
            <HeaderItem>Forma de Pagamento</HeaderItem>
            <HeaderItem>Valor Recebido?</HeaderItem>
            <HeaderItem>Cód. Autorização de Pagto</HeaderItem>
          </SaleInstallmentHeader>
          <InstallmentsContainer>
            {parcels.map((item, index) => (
              <React.Fragment key={item.id}>
                <InstallmentRow>
                  <div>
                    <InstallmentDescription>
                      {getInstallmentDescription(index)}
                    </InstallmentDescription>
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="date"
                      value={item.Due_Date}
                      style={{ fontSize: '13px' }}
                      disabled
                      readonly
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      value={currency(item.Amount)}
                      style={{ fontSize: '13px' }}
                      disabled
                      readonly
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      value={item.FormOfPayment?.Desciption}
                      style={{ fontSize: '13px' }}
                      disabled
                      readonly
                    />
                  </div>
                  <div className="flex center">
                    <input
                      type="checkbox"
                      value={item.amountReceived}
                      style={{ fontSize: '13px' }}
                      disabled
                      readonly
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      value={item.PaymentsAuthorizations?.code}
                      style={{ fontSize: '13px' }}
                      disabled={NOT_DIGITAL_PAYMENT_FORMS.includes(
                        item.FormOfPayment?.TypeOfPayment
                      )}
                      onChange={(e) =>
                        handleChangeAuthorizationCode(index, e.target.value)
                      }
                    />
                  </div>
                </InstallmentRow>
              </React.Fragment>
            ))}
          </InstallmentsContainer>
        </SaleInstallmentsWrapper>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 flex end gap-050">
          <button
            className="button button-red button-h35"
            onClick={handleClose}
            disabled={isSaving}
          >
            Cancelar
          </button>
          {hasDigitalPayments && (
            <button
              className="button button-green button-h35"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving && <span className="fa fa-spinner fa-pulse fa-1x" />}
              Salvar
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
