import { debounce } from '@material-ui/core'
import { useCallback, useEffect, useRef } from 'react'

const useDebounce = (callback, delay) => {
  const memoizedCallback = useCallback(callback, [])
  const debouncedFn = useRef(debounce(memoizedCallback, delay))

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay)
  }, [memoizedCallback, delay])

  return debouncedFn.current
}

export default useDebounce